/**
 * 백엔드 구성 시, label, msg 여부 파악, prefix 추가 후 Response
 */
import { VERSION_CM_10617_EVENT_TRIGGER_AUTO_CAPTIONS } from "@cores/version";

export default [
    {
        eventType: "INGEST",
        conditionCustomFields: [
            {
                name: "common::label::Input path",
                key: "inputPath",
                fieldType: "INPUT_FIELD",
                placeholder: "ex> mz-cm-transcoding-input/mz-cm/*",
                required: true,
                maxByte: 255,
            },
        ],
        actions: [
            {
                index: 0,
                key: "/stage/jobs/start-transcoding",
                name: "common::label::Transcode",
                customFields: [
                    {
                        name: "common::label::Pipeline",
                        key: "pipelineId",
                        fieldType: "PIPELINE_SELECT",
                        placeholder: "common::label::Select pipeline",
                        required: true,
                    },
                    {
                        name: "common::label::Save origin asset",
                        key: "isCreateOriginAsset",
                        fieldType: "SWITCH",
                        defaultValue: "false",
                        options: [
                            { label: "Yes", value: "true" },
                            { label: "No", value: "false" },
                        ],
                    },
                    {
                        name: "common::label::Auto caption",
                        key: "autoCaptions",
                        fieldType: "AUTO_CAPTION",
                        defaultValue: { useAutoCaption: "UNUSED" },
                        version: VERSION_CM_10617_EVENT_TRIGGER_AUTO_CAPTIONS,
                    },
                    {
                        name: "common::label::Categories",
                        key: "categories",
                        fieldType: "CATEGORY_SELECT_AREA",
                        categoryType: "Asset",
                    },
                    {
                        name: "common::label::Attributions",
                        key: "attributions",
                        fieldType: "ATTRIBUTION_INPUT_AREA",
                    },
                    {
                        name: "common::label::Tags",
                        key: "tags",
                        fieldType: "HASHTAG_INPUT_AREA",
                    },
                ],
                automaticValues: {
                    inputs: ["{{:jobInput:}}"],
                    jobId: "{{:jobId:}}",
                    projectId: "{{:projectId:}}",
                },
            },
            {
                index: 1,
                key: "/stage/assets/create",
                name: `common::label::Create MP4 asset`,
                customFields: [
                    {
                        name: "common::label::Name",
                        key: "name",
                        fieldType: "INPUT_FIELD",
                        placeholder: "common::label::Asset name",
                        required: true,
                        maxByte: 255,
                    },
                    {
                        name: "common::label::Status",
                        key: "status",
                        fieldType: "SWITCH",
                        defaultValue: "INACTIVE",
                        required: true,
                        options: [
                            { label: "Active", value: "ACTIVE" },
                            { label: "Inactive", value: "INACTIVE" },
                        ],
                    },
                    {
                        name: "common::label::Folder Path",
                        key: "virtualFolder",
                        fieldType: "FOLDER_PATH_SELECT_AREA",
                    },
                    {
                        name: "common::label::Auto caption",
                        key: "autoCaptions",
                        fieldType: "AUTO_CAPTION",
                        defaultValue: { useAutoCaption: "UNUSED" },
                        version: VERSION_CM_10617_EVENT_TRIGGER_AUTO_CAPTIONS,
                    },
                    {
                        name: "common::label::Categories",
                        key: "categories",
                        fieldType: "CATEGORY_SELECT_AREA",
                        categoryType: "Asset",
                    },
                    {
                        name: "common::label::Attributions",
                        key: "attributions",
                        fieldType: "ATTRIBUTION_INPUT_AREA",
                    },
                    {
                        name: "common::label::Tags",
                        key: "tags",
                        fieldType: "HASHTAG_INPUT_AREA",
                    },
                    {
                        name: "common::label::Archive after adding assets",
                        key: "archive",
                        fieldType: "ARCHIVE_SELECT",
                        options: [
                            { label: "S3 Glacier Deep Archive", value: "DEEP_ARCHIVE" },
                            { label: "S3 Glacier Flexible Retrieval", value: "GLACIER" },
                        ],
                    },
                ],
                automaticValues: {
                    ingestType: "DIRECT",
                    mediaType: "VIDEO",
                    type: "{{:sourceType:}}",
                    type_: "MP4",
                    mimeType: "{{:sourceMimeType:}}",
                    size: "{{:sourceSize:}}",
                    projectId: "{{:projectId:}}",
                    elements: [
                        {
                            urls: ["{{:sourceUrl:}}"],
                            locationType: "S3",
                        },
                    ],
                },
            },
            {
                index: 2,
                key: "/stage/assets/create",
                name: "common::label::Create MP3 asset",
                customFields: [
                    {
                        name: "common::label::Name",
                        key: "name",
                        fieldType: "INPUT_FIELD",
                        placeholder: "common::label::Asset name",
                        required: true,
                        maxByte: 255,
                    },
                    {
                        name: "common::label::Status",
                        key: "status",
                        fieldType: "SWITCH",
                        defaultValue: "INACTIVE",
                        required: true,
                        options: [
                            { label: "Active", value: "ACTIVE" },
                            { label: "Inactive", value: "INACTIVE" },
                        ],
                    },
                    {
                        name: "common::label::Folder Path",
                        key: "virtualFolder",
                        fieldType: "FOLDER_PATH_SELECT_AREA",
                    },
                    {
                        name: "common::label::Auto caption",
                        key: "autoCaptions",
                        fieldType: "AUTO_CAPTION",
                        defaultValue: { useAutoCaption: "UNUSED" },
                        version: VERSION_CM_10617_EVENT_TRIGGER_AUTO_CAPTIONS,
                    },
                    {
                        name: "common::label::Categories",
                        key: "categories",
                        fieldType: "CATEGORY_SELECT_AREA",
                        categoryType: "Asset",
                    },
                    {
                        name: "common::label::Attributions",
                        key: "attributions",
                        fieldType: "ATTRIBUTION_INPUT_AREA",
                    },
                    {
                        name: "common::label::Tags",
                        key: "tags",
                        fieldType: "HASHTAG_INPUT_AREA",
                    },
                    {
                        name: "common::label::Archive after adding assets",
                        key: "archive",
                        fieldType: "ARCHIVE_SELECT",
                        options: [
                            { label: "S3 Glacier Deep Archive", value: "DEEP_ARCHIVE" },
                            { label: "S3 Glacier Flexible Retrieval", value: "GLACIER" },
                        ],
                    },
                ],
                automaticValues: {
                    ingestType: "DIRECT",
                    mediaType: "AUDIO",
                    type: "{{:sourceType:}}",
                    type_: "MP3",
                    mimeType: "{{:sourceMimeType:}}",
                    size: "{{:sourceSize:}}",
                    projectId: "{{:projectId:}}",
                    elements: [
                        {
                            urls: ["{{:sourceUrl:}}"],
                            locationType: "S3",
                        },
                    ],
                },
            },
            {
                index: 3,
                key: "/stage/assets/create",
                name: `common::label::Create IMAGE asset`,
                customFields: [
                    {
                        name: "common::label::Name",
                        key: "name",
                        fieldType: "INPUT_FIELD",
                        placeholder: "common::label::Asset name",
                        required: true,
                        maxByte: 255,
                    },
                    {
                        name: "common::label::Status",
                        key: "status",
                        fieldType: "SWITCH",
                        defaultValue: "INACTIVE",
                        required: true,
                        options: [
                            { label: "Active", value: "ACTIVE" },
                            { label: "Inactive", value: "INACTIVE" },
                        ],
                    },
                    {
                        name: "common::label::Folder Path",
                        key: "virtualFolder",
                        fieldType: "FOLDER_PATH_SELECT_AREA",
                    },
                    {
                        name: "common::label::Categories",
                        key: "categories",
                        fieldType: "CATEGORY_SELECT_AREA",
                        categoryType: "Asset",
                    },
                    {
                        name: "common::label::Attributions",
                        key: "attributions",
                        fieldType: "ATTRIBUTION_INPUT_AREA",
                    },
                    {
                        name: "common::label::Tags",
                        key: "tags",
                        fieldType: "HASHTAG_INPUT_AREA",
                    },
                    {
                        name: "common::label::Archive after adding assets",
                        key: "archive",
                        fieldType: "ARCHIVE_SELECT",
                        options: [
                            { label: "S3 Glacier Deep Archive", value: "DEEP_ARCHIVE" },
                            { label: "S3 Glacier Flexible Retrieval", value: "GLACIER" },
                        ],
                    },
                ],
                automaticValues: {
                    ingestType: "DIRECT",
                    mediaType: "IMAGE",
                    type: "IMAGE",
                    type_: "IMAGE",
                    mimeType: "{{:sourceMimeType:}}",
                    size: "{{:sourceSize:}}",
                    projectId: "{{:projectId:}}",
                    elements: [
                        {
                            url: "{{:sourceUrl:}}",
                            locationType: "S3",
                        },
                    ],
                },
            },
            {
                index: 4,
                key: "/stage/assets/create",
                name: `common::label::Create CAPTION asset`,
                customFields: [
                    {
                        name: "common::label::Name",
                        key: "name",
                        fieldType: "INPUT_FIELD",
                        placeholder: "common::label::Asset name",
                        required: true,
                        maxByte: 255,
                    },
                    {
                        name: "common::label::Status",
                        key: "status",
                        fieldType: "SWITCH",
                        defaultValue: "INACTIVE",
                        required: true,
                        options: [
                            { label: "Active", value: "ACTIVE" },
                            { label: "Inactive", value: "INACTIVE" },
                        ],
                    },
                    {
                        name: "common::label::Kind",
                        key: "kind",
                        fieldType: "SINGLE_SELECT",
                        placeholder: "common::label::Select kind",
                        required: true,
                        options: [
                            { label: "Caption", value: "CAPTION" },
                            { label: "Subtitle", value: "SUBTITLE" },
                            { label: "Description", value: "DESCRIPTION" },
                            { label: "Chapter", value: "CHAPTER" },
                            { label: "Metadata", value: "METADATA" },
                        ],
                    },
                    {
                        name: "common::label::Label",
                        key: "label",
                        fieldType: "INPUT_FIELD",
                        placeholder: "common::label::Caption label",
                        required: true,
                        maxByte: 255,
                    },
                    {
                        name: "common::label::Language",
                        key: "language-country",
                        fieldType: "CAPTION_LANGUAGE_SELECT",
                        placeholder: "common::label::Select language",
                        required: true,
                    },
                    {
                        name: "common::label::Folder Path",
                        key: "virtualFolder",
                        fieldType: "FOLDER_PATH_SELECT_AREA",
                    },
                    {
                        name: "common::label::Categories",
                        key: "categories",
                        fieldType: "CATEGORY_SELECT_AREA",
                        categoryType: "Asset",
                    },
                    {
                        name: "common::label::Attributions",
                        key: "attributions",
                        fieldType: "ATTRIBUTION_INPUT_AREA",
                    },
                    {
                        name: "common::label::Tags",
                        key: "tags",
                        fieldType: "HASHTAG_INPUT_AREA",
                    },
                    {
                        name: "common::label::Archive after adding assets",
                        key: "archive",
                        fieldType: "ARCHIVE_SELECT",
                        options: [
                            { label: "S3 Glacier Deep Archive", value: "DEEP_ARCHIVE" },
                            { label: "S3 Glacier Flexible Retrieval", value: "GLACIER" },
                        ],
                    },
                ],
                automaticValues: {
                    ingestType: "DIRECT",
                    mediaType: "CAPTION",
                    type: "${kind}",
                    type_: "CAPTION",
                    mimeType: "text/vtt",
                    size: "{{:sourceSize:}}",
                    projectId: "{{:projectId:}}",
                    elements: [
                        {
                            label: "${label}",
                            kind: "${kind}",
                            language: "${language}",
                            country: "${country}",
                            url: "{{:sourceUrl:}}",
                            locationType: "S3",
                        },
                    ],
                },
            },
            {
                index: 5,
                key: "/stage/assets/create",
                name: `common::label::Create FILE asset`,
                customFields: [
                    {
                        name: "common::label::Name",
                        key: "name",
                        fieldType: "INPUT_FIELD",
                        placeholder: "common::label::Asset name",
                        required: true,
                        maxByte: 255,
                    },
                    {
                        name: "common::label::Status",
                        key: "status",
                        fieldType: "SWITCH",
                        defaultValue: "INACTIVE",
                        required: true,
                        options: [
                            { label: "Active", value: "ACTIVE" },
                            { label: "Inactive", value: "INACTIVE" },
                        ],
                    },
                    {
                        name: "common::label::Folder Path",
                        key: "virtualFolder",
                        fieldType: "FOLDER_PATH_SELECT_AREA",
                    },
                    {
                        name: "common::label::Categories",
                        key: "categories",
                        fieldType: "CATEGORY_SELECT_AREA",
                        categoryType: "Asset",
                    },
                    {
                        name: "common::label::Attributions",
                        key: "attributions",
                        fieldType: "ATTRIBUTION_INPUT_AREA",
                    },
                    {
                        name: "common::label::Tags",
                        key: "tags",
                        fieldType: "HASHTAG_INPUT_AREA",
                    },
                    {
                        name: "common::label::Archive after adding assets",
                        key: "archive",
                        fieldType: "ARCHIVE_SELECT",
                        options: [
                            { label: "S3 Glacier Deep Archive", value: "DEEP_ARCHIVE" },
                            { label: "S3 Glacier Flexible Retrieval", value: "GLACIER" },
                        ],
                    },
                ],
                automaticValues: {
                    ingestType: "DIRECT",
                    mediaType: "FILE",
                    type: "FILE",
                    type_: "FILE",
                    mimeType: "{{:sourceMimeType:}}",
                    size: "{{:sourceSize:}}",
                    projectId: "{{:projectId:}}",
                    elements: [
                        {
                            url: "{{:sourceUrl:}}",
                            locationType: "S3",
                        },
                    ],
                },
            },
        ],
    },
];
