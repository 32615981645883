import {handleActions, createAction} from 'redux-actions';
import PhotoApi from './apis/photo';
import { LIST_VIEW_TYPE } from '../constants';

//action type
const PHOTO_LIST_PENDING = "PHOTO_LIST_PENDING";

const PHOTO_LIST_GET_SUCCESS = "PHOTO_LIST_GET_SUCCESS";
const PHOTO_LIST_GET_FAILURE = "PHOTO_LIST_GET_FAILURE";
const PHOTO_LIST_UPDATE_STATE = "PHOTO_LIST_UPDATE_STATE";
const PHOTO_LIST_STATUS_UPDATE = "PHOTO_LIST_STATUS_UPDATE";
const PHOTO_LIST_DELETE_SUCCESS = 'PHOTO_LIST_DELETE_SUCCESS';
const PHOTO_LIST_DELETE_FAILURE = 'PHOTO_LIST_DELETE_FAILURE';

//reducer
const initialState = {
    pending: false,
    error: false,
    activeView: LIST_VIEW_TYPE.LIST,
    isCardView : true,
    isSearched: false,
    data: [],
    totalCount : 0,
    contextVersion: 0,
    validationErrors : { 
        name : 'required' 
    },
};

export default handleActions({
    // [CLEAR]: (state, action)=> {
    //     tempState = {
    //         ...initialState,
    //         contextVersion: tempState.contextVersion + 1
    //     };
    //     return tempState;
    // },
    // [VIDEO_LIST_HIERARCHY_CLEAR]: (state, action) => {
    //     tempState = {
    //         ...tempState,
    //     };
    //     return tempState;
    // },
    [PHOTO_LIST_PENDING]: (state) => {
        // console.log("PHOTO_LIST_PENDING ");

        return {
            ...state,
            pending : true,
        };
    },
    [PHOTO_LIST_GET_SUCCESS]: (state, action) => {
        const { data } = action.payload;
        return {
            ...state,
            pending : false,
            error : false,
            data : data.contents,
            totalCount : data.totalCount
        };
    },
    [PHOTO_LIST_GET_FAILURE]: (state, action) => {
        return {
            ...state,
            pending : false,
            error : true    
        };
    },
    [PHOTO_LIST_UPDATE_STATE]: (state, action) => {
        return {
            ...state,
            ...action.payload
        }
    },
    [PHOTO_LIST_DELETE_SUCCESS]: (state, action) => {
        return {
            ...state,
            pending : false,
            error : false   
        };
    },
    [PHOTO_LIST_DELETE_FAILURE]: (state, action) => {
        return {
            ...state,
            pending : false,
            error : true
        };
    }
}, initialState)

export const updateState = createAction(PHOTO_LIST_UPDATE_STATE);

export const getPhotosAsync = (search) => (dispatch, getState) =>{
    const {
        stage,
        project        
    } = getState();

    dispatch({
        type: PHOTO_LIST_PENDING
    })

    return new Promise((resolve, reject) => {
        PhotoApi.getPhotosAsync(stage.id, stage.endpoint, project.id, search)
            .then((response) => {
                dispatch({
                    type: PHOTO_LIST_GET_SUCCESS,
                    payload: response
                });

                // const Photos = response.data;
                // for (const music of Photos) {
                //     const shouldPollStatus = music.status === 'ACTIVATING' || music.status === 'INACTIVATING';
                //     if(!shouldPollStatus)
                //         continue;

                //     const targetStatus = music.status === 'ACTIVATING' ? 'ACTIVE' : 'INACTIVE';
                //     new Polling(async () => {
                //         return await VideoApi.getStatusAsync(stageId, apiEndpoint, targetProjectId, music.id);
                //     }, (response) => {                        
                //         return getState().Photos.contextVersion !== contextVersion || response.data.status === targetStatus;
                //     }, (response) => {
                //         dispatch({
                //             type: PHOTO_LIST_STATUS_UPDATE,
                //             payload : response
                //         })
                //     }).run();
                // }

                resolve(response.data);
            })
            .catch((error) => {
                dispatch({
                    type: PHOTO_LIST_GET_FAILURE,
                    payload: error
                });

                reject(error);
            });
    })
}

export const deleteAsync = (id) => (dispatch, getState) =>{
    const {
        stage,
        project        
    } = getState();
    return PhotoApi.deletePhotoAsync(stage.id, stage.endpoint, project.id, id)
    .then((response) => {
        dispatch({
            type: PHOTO_LIST_DELETE_SUCCESS,
            payload: {
                id
            }
        });
    })
    .catch((error) => {
        dispatch({
            type: PHOTO_LIST_DELETE_FAILURE,
            payload: error
        });
    });
}

export const updateBulkAsync = (payload) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    return PhotoApi.updateBulkAsync(stage.id, stage.endpoint, project.id, payload)
        .then((response) => {
            console.log(response);
        });
}
