import {handleActions, createAction} from 'redux-actions';
import {
    axios,
    axiosWithoutHeaders
} from "../cores/axiosWrapper";
import config from '../config';
import moment from 'moment';

function getLineupsAPI(stageId, apiEndpoint, projectId, {listingId, startAt, endAt}) {
    return axios.get(`${apiEndpoint}/listings/${listingId}/lineups?startAt=${startAt}&endAt=${endAt}`, {
        headers : {
            stageId,
            projectId
        }
    });
}
function postLineupAPI(stageId, apiEndpoint, projectId, listingId, params, version) {
    params.listing = {
        ...params.listing,
        version
    }
    return axios.post(
        `${apiEndpoint}/listings/${listingId}/lineups`,
        params,
        {
            headers: {
                stageId,
                projectId,
                'Content-Type': "application/json"                
            }
        });
}
function postLineupBulkAPI(stageId, apiEndpoint, projectId, listingId, version, lineups) {
    const params = {
        listing : {
            version
        },
        lineups
    }
    return axios.post(
        `${apiEndpoint}/listings/${listingId}/lineups/bulk`,
        params,
        {
            headers: {
                stageId,
                projectId,
                'Content-Type': "application/json"                
            }
        });
}
function getLineupAPI(stageId, apiEndpoint, projectId, listingId, lineupId) {
    return axios.get(`${apiEndpoint}/listings/${listingId}/lineups/${lineupId}`, {
        headers : {
            stageId,
            projectId
        }
    });
}

function deleteLineupAPI(stageId, apiEndpoint, projectId, listingId, lineupId, endDate, version) {
    return axios.delete(`${apiEndpoint}/listings/${listingId}/lineups/${lineupId}`,
    {
        data:{
            listing: {
                version: version,
                updateDate: endDate
            }
        },
        headers : {
            stageId,
            projectId
        }
    });
}

function bulkDeleteLineupAPI(stageId, apiEndpoint, projectId, listingId, data, version) {
    return axios.delete(`${apiEndpoint}/listings/${listingId}/lineups/bulk?version=${version}`,
    {
        data,
        headers : {
            stageId,
            projectId
        }
    });
}

function putLineupAPI(stageId, apiEndpoint, projectId, listingId, lineupId, params, version) {
    params.listing = {
        ...params.listing,
        version
    }

    return axios.patch(
        `${apiEndpoint}/listings/${listingId}/lineups/${lineupId}`,
        params,
        {
            headers: {
                stageId,
                projectId,
                'Content-Type': "application/json"                
            }
        });
}



function putProgramAPI(stageId, apiEndpoint, projectId, programId, params) {
    return axios.put(
        `${apiEndpoint}/programmes/${programId}`,
        params,
        {
            headers: {
                stageId,
                projectId,
                'Content-Type': "application/json"                
            }
        });
}

function getVideoAPI(stageId, apiEndpoint, projectId, videoId) {
    axios.get(`${apiEndpoint}/videos/${videoId}`, {
        headers: {
            stageId,
            projectId
        }
    });
}

function getProgramAPI(stageId, apiEndpoint, projectId, programId) {
    return axios.get(`${apiEndpoint}/programmes/${programId}`, {
        headers: {
            stageId,
            projectId
        }
    });
}

//action type
const STREAM_LINEUP_PENDING = 'STREAM_LINEUP_PENDING';
const STREAM_LINEUP_FAILURE = 'STREAM_LINEUP_FAILURE';
const STREAM_LINEUP_DETAIL_PENDING = 'STREAM_LINEUP_DETAIL_PENDING';
const STREAM_LINEUP_DETAIL_FAILURE = 'STREAM_LINEUP_DETAIL_FAILURE';
const STREAM_LINEUP_DAILY_COPY_PENDING = 'STREAM_LINEUP_DAILY_COPY_PENDING';
const STREAM_LINEUP_DAILY_COPY_FAILURE = 'STREAM_LINEUP_DAILY_COPY_FAILURE';
const STREAM_EXIST_LINEUP_PENDING = 'STREAM_EXIST_LINEUP_PENDING';
const STREAM_EXIST_LINEUP_FAILURE = 'STREAM_EXIST_LINEUP_FAILURE';
const GET_STREAM_LINEUP_SUCCESS = 'GET_STREAM_LINEUP_SUCCESS';
const GET_STREAM_LINEUPS_SUCCESS = 'GET_STREAM_LINEUPS_SUCCESS';
const SET_STREAM_LINEUP_INIT_DATA = 'SET_STREAM_LINEUP_INIT_DATA'; 
const POST_STREAM_LINEUP_SUCCESS = 'POST_STREAM_LINEUP_SUCCESS';
const POST_STREAM_LINEUP_BULK_SUCCESS = 'POST_STREAM_LINEUP_BULK_SUCCESS';
const PUT_STREAM_LINEUP_SUCCESS = 'PUT_STREAM_LINEUP_SUCCESS';
const GET_EXIST_LINEUP_SUCCESS = 'GET_EXIST_LINEUP_SUCCESS';
const SET_STREAM_LINEUP_SELECTED = 'SET_STREAM_LINEUP_SELECTED';
const DELETE_STREAM_LINEUP_SELECTED = 'DELETE_STREAM_LINEUP_SELECTED';
const UPDATE_STREAM_LINEUP = 'UPDATE_STREAM_LINEUP';
const UPDATE_STREAM_TEMP = 'UPDATE_STREAM_TEMP';
const UPDATE_STREAM_COPY_TO = 'UPDATE_STREAM_COPY_TO';
const DELETE_STREAM_LINEUP = 'DELETE_STREAM_LINEUP';
const GET_STREAM_LINEUP_PROGRAM_SUCCESS = 'GET_STREAM_LINEUP_PROGRAM_SUCCESS';
const GET_STREAM_LINEUP_VIDEO_SUCCESS = 'GET_STREAM_LINEUP_VIDEO_SUCCESS';
const SET_STREAM_LINEUP_INIT_ITEM = 'SET_STREAM_LINEUP_INIT_ITEM';
const SET_STREAM_LINEUP_COUNT = 'SET_STREAM_LINEUP_COUNT';
const SET_STREAM_LINEUP_CREATE_LINEUP_INFO = 'SET_STREAM_LINEUP_CREATE_LINEUP_INFO';
const DELETE_STREAM_LINEUP_CREATE_LINEUP_INFO = 'DELETE_STREAM_LINEUP_CREATE_LINEUP_INFO';
//reducer
const initialState = {
    pending: false,
    error: false,
    errorStatus : null,
    pendingDetail: false,
    errorDetail: false,
    errorDetailStatus : null,
    pendingDailyCopy: false,
    errorDailyCopy: false,
    errorDailyCopyStatus : null,
    pendingExistLineup: false,
    errorExistLineup: false,
    errorExistLineupStatus : null,
    standardDateTime : null,
    data: null,
    item : null,
    programInfos : null,
    videoInfos : null,
    selected : null,
    temp : null,
    copyTo : null,
    createLineupInfo : {
        data: null,
        start : null,
        end : null
    },
    counts : {
        detail : 0,
        list : 0
    }
};

export default handleActions({
    [STREAM_LINEUP_PENDING]: (state) => {
        return {
            ...state,
            pending: true,
            error: false,
            errorStatus : null
        };
    },
    [STREAM_LINEUP_FAILURE]: (state, action) => {
        return {
            ...state,
            pending: false,
            error: true,
            errorStatus:  action && action.payload && action.payload.response ? action.payload.response.status : 600
        };
    },
    [STREAM_LINEUP_DETAIL_PENDING]: (state) => {
        return {
            ...state,
            pendingDetail: true,
            errorDetail: false,
            errorDetailStatus : null
        };
    },
    [STREAM_LINEUP_DETAIL_FAILURE]: (state, action) => {
        return {
            ...state,
            pendingDetail: false,
            errorDetail: true,
            errorDetailStatus:  action && action.payload && action.payload.response ? action.payload.response.status : 600
        };
    },
    [STREAM_LINEUP_DAILY_COPY_PENDING]: (state) => {
        return {
            ...state,
            pendingDailyCopy: true,
            errorDailyCopy: false,
            errorDailyCopyStatus : null
        };
    },
    [STREAM_LINEUP_DAILY_COPY_FAILURE]: (state, action) => {
        return {
            ...state,
            pendingDailyCopy: false,
            errorDailyCopy: true,
            errorDailyCopyStatus:  action && action.payload && action.payload.response ? action.payload.response.status : 600
        };
    },
    [STREAM_EXIST_LINEUP_PENDING]: (state) => {
        return {
            ...state,
            pendingExistLineup: true,
            errorExistLineup: false,
            errorExistLineupStatus : null
        };
    },
    [STREAM_EXIST_LINEUP_FAILURE]: (state, action) => {
        return {
            ...state,
            pendingExistLineup: false,
            errorExistLineup: true,
            errorExistLineupStatus:  action && action.payload && action.payload.response ? action.payload.response.status : 600
        };
    },
    [GET_EXIST_LINEUP_SUCCESS]: (state) => {
        return {
            ...state,
            pendingExistLineup: false,
            errorExistLineup: false,
            errorExistLineupStatus : null
        };
    },
    [GET_STREAM_LINEUPS_SUCCESS]: (state, action) => {
        const {lineups} = action.payload.data;

        return {
            ...state,
            pending: false,
            error: false,
            errorStatus: null,
            counts: {
                ...state.counts,
                list: state.counts.list + 1
            },
            data: lineups,
            selected : action.selected || null
        };
    },
    [SET_STREAM_LINEUP_INIT_DATA] : (state) => {
        return {
            ...state,
            pending: false,
            error: false,
            errorStatus: null,
            standardDateTime : null,
            data: null,
            item : null,
        }
    },
    [SET_STREAM_LINEUP_INIT_ITEM] : (state) => {
        return {
            ...state,
            pendingDetail: true,
            errorDetail: false,
            errorDetailStatus : null,
            item : null,
            programInfos : null,
            videoInfos : null,
            temp : null,
            copyTo : null
        }
    },
    [POST_STREAM_LINEUP_SUCCESS]:(state, action) => {
        return {
            ...state,
            pending: false,
            error: false,
            errorStatus: null
        }
    },
    [POST_STREAM_LINEUP_BULK_SUCCESS]:(state, action) => {
        return {
            ...state,
            pendingDailyCopy: false,
            errorDailyCopy: false,
            errorDailyCopyStatus: null
        }
    },
    [SET_STREAM_LINEUP_SELECTED]:(state, action) => {        
        return {
            ...state,
            standardDateTime : action.payload.standardDateTime,
            selected: action.payload.lineup,
            counts : {
                ...state.counts,
                detail : state.counts.detail + 1
            }
        };
    },
    [DELETE_STREAM_LINEUP_SELECTED]:(state, action) => {
        return {
            ...state,
            selected: null
        };
    },
    [GET_STREAM_LINEUP_SUCCESS]:(state, action) => {
        const {data} = action.payload.response;
        let standardDateTime = action.payload.standardDateTime;

        const isOverTheDay = moment.utc(`${data.schedule.endAt}`).local().isAfter(moment.utc(`${data.schedule.startAt}`).local().endOf('day'));

        const recurringData = setDefaultRecurringType(data);
        
        return {
            ...state,
            pendingDetail: false,
            errorDetail: false,
            errorDetailStatus: null,
            standardDateTime,
            counts : {
                ...state.counts,
                detail : state.counts.detail + 1
            },
            item: {
                ...data,
                schedule: {
                    ...data.schedule,
                    recurringType : recurringData.recurringType,
                    recurringValue : recurringData.recurringValue,
                    recurringTypeMonth : recurringData.recurringTypeMonth,
                    recurrences: data.schedule && data.schedule.recurrences && data.schedule.recurrences.length > 0 ? data.schedule.recurrences : []
                }
            },
            temp : {
                ...data,
                beginDate: moment(standardDateTime).format('YYYY-MM-DD'),
                endDate: isOverTheDay ?  moment(standardDateTime).add(1, 'day').format('YYYY-MM-DD') : moment(standardDateTime).format('YYYY-MM-DD'),
                beginTime: moment(data.schedule.startAt).format('HH:mm:ss.SSS'),
                endTime: moment(data.schedule.endAt).format('HH:mm:ss.SSS'),
                schedule: {
                    ...data.schedule,
                    isRepeat: data.schedule && data.schedule.recurrences && data.schedule.recurrences.length > 0,
                    ...JSON.parse(JSON.stringify(recurringData)),
                    recurrences: data.schedule && data.schedule.recurrences && data.schedule.recurrences.length > 0 ? JSON.parse(JSON.stringify(data.schedule.recurrences)) : []
                }
            },
            copyTo : {
                ...data,
                beginDate: moment(standardDateTime).format('YYYY-MM-DD'),
                endDate: isOverTheDay ?  moment(standardDateTime).add(1, 'day').format('YYYY-MM-DD') : moment(standardDateTime).format('YYYY-MM-DD'),
                beginTime: moment(data.schedule.startAt).format('HH:mm:ss.SSS'),
                endTime: moment(data.schedule.endAt).format('HH:mm:ss.SSS'),
                schedule: {
                    ...data.schedule,
                    isRepeat: data.schedule && data.schedule.recurrences && data.schedule.recurrences.length > 0,
                    ...JSON.parse(JSON.stringify(recurringData)),
                    recurrences: data.schedule && data.schedule.recurrences && data.schedule.recurrences.length > 0 ? JSON.parse(JSON.stringify(data.schedule.recurrences)) : []
                }
            }
        };
    },
    [UPDATE_STREAM_LINEUP]:(state, action) => {
        return {
            ...state,
            item: {
                ...state.item,
                ...action.payload
            }
        };
    },
    [DELETE_STREAM_LINEUP]:(state) => {
        return {
            ...state,
            pending: false,
            error: false,
        }
    },
    [PUT_STREAM_LINEUP_SUCCESS]:(state, action) => {
        const {data} = action.payload.response;

        const recurringData = setDefaultRecurringType(data);
        
        return {
            ...state,
            pending: false,
            error :false,
            errorStatus: null,
            item: {
                ...data,
                schedule: {
                    ...data.schedule,
                    recurringType : recurringData.recurringType,
                    recurringValue : recurringData.recurringValue,
                    recurringTypeMonth : recurringData.recurringTypeMonth,
                    recurrences: data.schedule && data.schedule.recurrences && data.schedule.recurrences.length > 0 ? data.schedule.recurrences : []
                }
            },
            temp : {
                ...state.temp,
                ...data,
                schedule: {
                    ...data.schedule,
                    isRepeat: data.schedule && data.schedule.recurrences && data.schedule.recurrences.length > 0,
                    ...JSON.parse(JSON.stringify(recurringData)),
                    recurrences: data.schedule && data.schedule.recurrences && data.schedule.recurrences.length > 0 ? JSON.parse(JSON.stringify(data.schedule.recurrences)) : []
                }
            },
            copyTo : {
                ...state.copyTo,
                ...data,
                schedule: {
                    ...data.schedule,
                    isRepeat: data.schedule && data.schedule.recurrences && data.schedule.recurrences.length > 0,
                    ...JSON.parse(JSON.stringify(recurringData)),
                    recurrences: data.schedule && data.schedule.recurrences && data.schedule.recurrences.length > 0 ? JSON.parse(JSON.stringify(data.schedule.recurrences)) : []
                }
            }   
        };
    },
    [GET_STREAM_LINEUP_PROGRAM_SUCCESS]:(state, action) => {
        const {data} = action.payload;

        return {
            ...state,
            pendingDetail: false,
            errorDetail: false,
            errorDetailStatus : null,
            programInfos: data
        };
    },
    [GET_STREAM_LINEUP_VIDEO_SUCCESS]:(state, action) => {
        const {data} = action.payload;

        return {
            ...state,
            pending: false,
            error: false,
            videoInfos: data
        };
    },
    [UPDATE_STREAM_TEMP]:(state, action) => {    
        return {
            ...state,
            temp: {
                ...state.temp,                
                ...action.payload,
                schedule:{
                    ...state.temp.schedule, 
                    ...action.payload.schedule
                }
            }
        };
    },
    [UPDATE_STREAM_COPY_TO]:(state, action) => {    
        if(!state.copyTo)
            return {
                ...state
            }
        else
            return {
                ...state,
                copyTo: {
                    ...state.copyTo,                
                    ...action.payload,
                    schedule:{
                        ...state.copyTo.schedule, 
                        ...action.payload.schedule
                    }
                }
            };
    },
    [SET_STREAM_LINEUP_COUNT]:(state, action) => {
        return {
            ...state,
            counts: {
                ...state.counts,
                ...action.payload
            }
        };
    },
    [SET_STREAM_LINEUP_CREATE_LINEUP_INFO]:(state, action) => {        
        return {
            ...state,
            createLineupInfo: {
                data : action.payload.lineup,
                start : action.payload.start
            }
        };
    },
    [DELETE_STREAM_LINEUP_CREATE_LINEUP_INFO]:(state, action) => {        
        return {
            ...state,
            createLineupInfo: {
                data : null,
                start : null,
                end : null
            }
        };
    },
}, initialState);
//action creators
export const setInitData = createAction(SET_STREAM_LINEUP_INIT_DATA);
export const deleteLineupSelected = createAction(DELETE_STREAM_LINEUP_SELECTED);
export const updateLineup = createAction(UPDATE_STREAM_LINEUP);
export const updateTemp = createAction(UPDATE_STREAM_TEMP);
export const updateCopyTo = createAction(UPDATE_STREAM_COPY_TO);
export const setCreateLineupInfo = createAction(SET_STREAM_LINEUP_CREATE_LINEUP_INFO);
export const deleteCreateLineupInfo = createAction(DELETE_STREAM_LINEUP_CREATE_LINEUP_INFO);
export const setLineupSelected = (obj) => (dispatch, getState) => {
    // createAction(SET_STREAM_LINEUP_SELECTED);
    dispatch({
        type : DELETE_STREAM_LINEUP_SELECTED
    });
    dispatch({
        type: SET_STREAM_LINEUP_SELECTED,
        payload: obj
    });
}
export const setLineupUnselected = () => (dispatch, getState) => {
    dispatch({
        type : DELETE_STREAM_LINEUP_SELECTED
    });
}
export const getLineups = (obj) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    dispatch({type: STREAM_LINEUP_PENDING});

    return getLineupsAPI(stage.id, stage.endpoint, project.id, obj).then(
        (response) => {
            dispatch({
                type: GET_STREAM_LINEUPS_SUCCESS,
                payload: response,
                selected : obj.selected || {}
            });
        }
    ).catch(error => {
        dispatch({
            type: STREAM_LINEUP_FAILURE,
            payload: error
        });
    });
};
export const postLineup = (listingId, params, callback) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    dispatch({type: STREAM_LINEUP_PENDING});

    const queries = {
        program : {
            id: params.program.id
        },
        name: params.name,
        priority: params.priority,
        schedule : {
            startAt: params.schedule.startAt,
            endAt: params.schedule.endAt
        }
    };
    
    if(params.schedule && params.schedule.recurrences && params.schedule.recurrences.length > 0) {
        queries.schedule.recurrences = params.schedule.recurrences.map(v => {
            return {
                ...v,
                startAt: params.schedule.startAt
            }
        });
    }

    return postLineupAPI(stage.id, stage.endpoint, project.id, listingId, queries, params.version).then(
        (response) => {
            dispatch({
                type: POST_STREAM_LINEUP_SUCCESS,
                payload: response
            });

            callback && callback(response);
        }
    ).catch(error => {
        dispatch({
            type: STREAM_LINEUP_FAILURE,
            payload: error
        });
    });
}
export const postLineupBulk = (listingId, version, lineups) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    dispatch({type: STREAM_LINEUP_DAILY_COPY_PENDING});

    return new Promise((resolve, reject) => {
        postLineupBulkAPI(stage.id, stage.endpoint, project.id, listingId, version, lineups).then(
            (response) => {
                dispatch({
                    type: POST_STREAM_LINEUP_BULK_SUCCESS,
                    payload: response
                });

                resolve(response);
            }
        ).catch(error => {
            dispatch({
                type: STREAM_LINEUP_DAILY_COPY_FAILURE,
                payload: error
            });
            reject(error);
        });
    });

    return 
}
export const getLineup = (listingId, lineupId, standardDateTime) => (dispatch, getState) => {
    const {
        stage,
        project,
        listingsLineupList
    } = getState();

    dispatch({type: SET_STREAM_LINEUP_INIT_ITEM});

    return getLineupAPI(stage.id, stage.endpoint, project.id, listingId, lineupId).then(
        (response) => {
            dispatch({
                type: GET_STREAM_LINEUP_SUCCESS,
                payload: {
                    response,
                    currentDate : listingsLineupList.currentDate,
                    standardDateTime
                }
            });
        }
    ).catch(error => {
        dispatch({
            type: STREAM_LINEUP_DETAIL_FAILURE,
            payload: error
        });
    });
}
export const deleteLineup = (listingId, lineupId, version, endDate, callback = () => {}) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    if (stage.id === '') {
        return;
    }

    dispatch({type: STREAM_LINEUP_PENDING});

    return deleteLineupAPI(stage.id, stage.endpoint, project.id, listingId, lineupId, endDate, version).then(
        (response) => {
            dispatch({
                type: DELETE_STREAM_LINEUP,
                payload: response
            });

            callback();
        }
    ).catch(error => {
        dispatch({
            type: STREAM_LINEUP_FAILURE,
            payload: error
        });
    });
};

export const bulkDeleteLineup = (listingId, data, version, callback = () => {}) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    dispatch({type: STREAM_LINEUP_PENDING});

    return bulkDeleteLineupAPI(stage.id, stage.endpoint, project.id, listingId, data, version).then(
        (response) => {
            dispatch({
                type: DELETE_STREAM_LINEUP,
                payload: response
            });

            callback();
        }
    ).catch(error => {
        dispatch({
            type: STREAM_LINEUP_FAILURE,
            payload: error
        });
    });
};

export const putLineup = (listingId, lineupId, params, callback = () => {}) => (dispatch, getState) => {
    const {
        stage,
        project,
        listingsLineupList
    } = getState();
    
    dispatch({type: STREAM_LINEUP_PENDING});
    
    return putLineupAPI(stage.id, stage.endpoint, project.id, listingId, lineupId, params, params.listing.version).then(
        (response) => {
            dispatch({
                type: PUT_STREAM_LINEUP_SUCCESS,
                payload: {
                    response,
                    currentDate : listingsLineupList.currentDate
                }
            });

            callback(response);
        }
    ).catch(error => {
        dispatch({
            type: STREAM_LINEUP_FAILURE,
            payload: error
        });
    })
}

export const getProgram = (programId) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();
    
    dispatch({type: STREAM_LINEUP_DETAIL_PENDING});
    
    return getProgramAPI(stage.id, stage.endpoint, project.id, programId).then(
        (response) => {
            dispatch({
                type: GET_STREAM_LINEUP_PROGRAM_SUCCESS,
                payload: response
            });
        }
    ).catch(error => {
        dispatch({
            type: STREAM_LINEUP_DETAIL_FAILURE
        });
    })
};

export const putProgram = (programId, params) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();
    
    dispatch({type: STREAM_LINEUP_DETAIL_PENDING});
    
    return putProgramAPI(stage.id, stage.endpoint, project.id, programId, params).then(
        (response) => {
            dispatch({
                type: GET_STREAM_LINEUP_PROGRAM_SUCCESS,
                payload: response
            });
        }
    ).catch(error => {
        dispatch({
            type: STREAM_LINEUP_DETAIL_FAILURE
        });
    })
};

export const getVideo = (videoId) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    dispatch({type: STREAM_LINEUP_PENDING});

    return getVideoAPI(stage.id, project.id, videoId).then(
        (response) => {
            dispatch({
                type: GET_STREAM_LINEUP_VIDEO_SUCCESS,
                payload: response
            })
        }
    ).catch(error => {
        dispatch({
            type: STREAM_LINEUP_FAILURE
        });
    });
};

export const isExistLineup = (obj) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    dispatch({type: STREAM_EXIST_LINEUP_PENDING});

    return new Promise((resolve, reject) => {
        return getLineupsAPI(stage.id, stage.endpoint, project.id, obj).then(
            (response) => {
                dispatch({
                    type: GET_EXIST_LINEUP_SUCCESS
                })

                if (!response || !response.data || !response.data.lineups || response.data.lineups.length <= 0)
                    resolve(false);
                else
                    resolve(true);
            }
        ).catch(error => {
            dispatch({
                type: STREAM_EXIST_LINEUP_FAILURE
            });
            reject(false);
        });
    });

    
}

export const initLineupItem = createAction(SET_STREAM_LINEUP_INIT_ITEM);
export const setLineupCount = createAction(SET_STREAM_LINEUP_COUNT);

const countWeekdayOccurrencesInMonth = (date) => {
    let m = moment(date),
        weekDay = m.day(),
        yearDay = m.dayOfYear(),
        count = 0;

    m.startOf('month');
    while (m.dayOfYear() <= yearDay) {
        if (m.day() == weekDay) {
            count++;
        }
        m.add('days', 1);
    }

    return count;
};

const setDefaultRecurringType = (data) => {
    let recurringType = 'custom';
    let recurringValue = 'dailys';
    let recurringTypeMonth = 'dayOfMonth';

    if(data.schedule.recurrences && data.schedule.recurrences.length > 0) {
        if(data.schedule.recurrences.length === 1) {
            const {interval, dayOfMonth, weekOfMonth, dayOfWeek} = data.schedule.recurrences[0];

            if (interval !== null) {
                recurringType = 'custom';
                recurringValue = 'dailys';
            } else if(
                interval === null &&
                weekOfMonth === '*' &&
                (dayOfWeek !== '*' && dayOfWeek !== null)
            ) {
                recurringType = 'custom';
                recurringValue = 'weeks';
            } else if (interval === null
                && dayOfWeek === '*'
                && weekOfMonth === '*'
                && (dayOfMonth !== '*' && dayOfMonth !== null)) {

                recurringType = 'custom';
                recurringValue =  'months';
                recurringTypeMonth = 'dayOfMonth';

            } else if (interval === null
                && dayOfMonth === '*'
                && (weekOfMonth !== '*' && weekOfMonth !== null)
                && (dayOfWeek !== '*' && dayOfWeek !== null)) {

                recurringType = 'custom';
                recurringValue =  'months';
                recurringTypeMonth = 'dayOfWeek';
            }
        } else {
            if(data.schedule.recurrences.every(v => {
                return (v.dayOfWeek !== '*' && v.dayOfWeek !== null) && v.dayOfMonth === '*';
            })) {
                recurringType = 'custom';
                recurringValue = 'weeks';
                
            } else if(data.schedule.recurrences.every(v => {
                return v.dayOfMonth !== '*' && v.dayOfMonth !== null;
            })) {
                recurringType = 'custom';
                recurringValue = 'months';
                recurringTypeMonth = 'dayOfMonth';
            }
        }
    }

    return {
        recurringType,
        recurringValue,
        recurringTypeMonth,
        startAt : moment.utc(data.schedule.startAt).local().format('HH:mm:ss.SSS'),
        endAt : moment.utc(data.schedule.endAt).local().format('HH:mm:ss.SSS')
    }
}