import React, { FC, useState, useCallback, useRef } from "react";
import theme from "@styles/theme";
import { alpha } from "@mui/material";

import { StyledCard, Cover, Content, Meta, Name, Actions } from "./asset-card.styled";
import AssetTypeThumbnail from "@components_v2/icon-type/asset/asset-type-thumbnail";
import AssetTypeFlag from "@components_v2/icon-type/asset/asset-type-flag";
// import StatusLabel from '@components/status-label/status-label';
import { Link } from "react-router-dom";
import StatusIcon from "../status/status-icon";
import { IconAi, IconArchive, IconAudio, IconErrorCircle, IconDrm, IconLink, IconVoice } from "@mzc-cloudplex/icons";
import { ARCHIVED_ASSET_STATUS } from "@constants";
import { useTranslation } from "react-i18next";
import { Tooltip, Box, CircularProgress, Divider, Grid, Typography } from "@mzc-pdc/ui";

const AssetCard = ({
    loading,
    checked,
    selected,
    variant,
    checkbox,
    src,
    thumbnail,
    thumbnailSize,
    mimeType,
    //  thumbnailType,
    //  type,
    name,
    status,
    //  extension,
    size,
    actions,
    disableHover,
    id,
    projectId,
    url,
    hasDubbing,
    encryptions,
    isAnalyzed,
    archiveStatus,
}) => {
    const { t } = useTranslation();
    const [isHover, setIsHover] = useState(true);

    const getMediaType = useCallback(() => {
        if (!mimeType) return "";
        const mediaType = mimeType.substring(0, mimeType.indexOf("/"));

        return mediaType;
    }, []);

    const getAssetType = useCallback(() => {
        if (!url) return "";
        const assetType = url.substring(url.lastIndexOf(".") + 1);

        return `.${assetType}`;
    }, []);

    return (
        <>
            <StyledCard
                variant={variant}
                checked={checked}
                selected={selected}
                onMouseEnter={() => {
                    if (!disableHover) setIsHover(false);
                }} // 마우스엔터 이벤트이면 hide가 false가 된다.
                onMouseLeave={() => {
                    if (!disableHover) setIsHover(true);
                }}
            >
                <Cover>
                    {checkbox && checkbox}
                    <AssetTypeThumbnail
                        variant={variant}
                        src={src}
                        width={thumbnailSize}
                        height={thumbnailSize}
                        type={getMediaType()}
                        status={status}
                        margin={variant === "table" ? `0 16px 0 8px` : ``}
                        disableHover={disableHover}
                    />
                    {/* archived */}
                    <>
                        {archiveStatus === "ARCHIVED" && (
                            <Box sx={{ position: `absolute`, left: 36, top: 7 }}>
                                <IconArchive size={16} color={"greySecondary"} />
                            </Box>
                        )}

                        {archiveStatus === "ARCHIVE_ERROR" && (
                            <Box sx={{ position: `absolute`, left: 36, top: 7 }}>
                                <IconArchive size={16} color={"greySecondary"} />
                                <Tooltip
                                    title={t(`common::msg::${"An attempt to 'Archive' failed. Please try again."}`)}
                                >
                                    <span>
                                        <IconErrorCircle size={16} color={"error"} />
                                    </span>
                                </Tooltip>
                            </Box>
                        )}
                        {archiveStatus === "ARCHIVING" && (
                            <Box
                                sx={{
                                    position: `absolute`,
                                    left: 0,
                                    top: 0,
                                    width: `100%`,
                                    height: `100%`,
                                    display: `flex`,
                                    alignItems: `center`,
                                    justifyContent: `center`,
                                    backgroundColor: alpha(theme.palette.greySecondary[50], 0.9),
                                }}
                            >
                                <Tooltip
                                    title={
                                        <React.Fragment>
                                            <Typography
                                                sx={{
                                                    textTransform: `uppercase`,
                                                    fontWeight: `bold`,
                                                    lineHeight: 1,
                                                    marginTop: 0.5,
                                                }}
                                            >
                                                {t(`common::label::${"Archiving"}`)}...
                                            </Typography>
                                        </React.Fragment>
                                    }
                                    open={true}
                                    color="warning"
                                >
                                    <CircularProgress color={"warning"} size={16} thickness={5} />
                                </Tooltip>
                            </Box>
                        )}
                    </>
                </Cover>
                <Content>
                    <Name className={"name"}>
                        {mimeType && <AssetTypeFlag width={22} height={16} type={getMediaType()} />}
                        <Typography noWrap color={theme.palette.secondary[800]} sx={{ flex: 1 }}>
                            {name && name}
                            {/* <Link to={`/assets/${id}?projectId=${projectId}`}>{name && name}</Link> */}
                        </Typography>
                        <IconLink size={16} />
                    </Name>
                    <Meta className={"meta"}>
                        {status && <StatusIcon status={status} />}
                        {/*<StatusLabel type={status} isCompact={variant === 'card'} />*/}
                        <Typography component={`span`} color={theme.palette.secondary[600]} sx={{ marginLeft: `2px` }}>
                            {mimeType && getAssetType()}
                        </Typography>
                        {size && (
                            <>
                                <Divider flexItem orientation={`vertical`} sx={{ margin: `2px 0` }} />
                                <Typography component={`span`} color={theme.palette.secondary[600]} sx={{ flex: 1 }}>
                                    {size && size}
                                </Typography>
                            </>
                        )}
                        <Grid container gap={0.5} justifySelf={"flex-end"} sx={{ width: `auto` }}>
                            {hasDubbing && (
                                <Box
                                    sx={{
                                        display: `inline-flex`,
                                        alignItems: `center`,
                                        justifyContent: `center`,
                                        width: 20,
                                        height: 20,
                                        borderRadius: 20,
                                        backgroundColor: `#8660d9`,
                                    }}
                                >
                                    <IconVoice size={12} htmlColor={theme.palette.common.white} />
                                </Box>
                            )}
                            {encryptions && (
                                <Box
                                    sx={{
                                        display: `inline-flex`,
                                        alignItems: `center`,
                                        justifyContent: `center`,
                                        width: 20,
                                        height: 20,
                                        borderRadius: 20,
                                        backgroundColor: `#f57f00`,
                                    }}
                                >
                                    <IconDrm size={12} htmlColor={theme.palette.common.white} />
                                </Box>
                            )}
                            {isAnalyzed && (
                                <Box
                                    sx={{
                                        display: `inline-flex`,
                                        alignItems: `center`,
                                        justifyContent: `center`,
                                        width: 20,
                                        height: 20,
                                        borderRadius: 20,
                                        backgroundColor: `#88ba11`,
                                    }}
                                >
                                    <IconAi size={12} htmlColor={theme.palette.common.white} />
                                </Box>
                            )}
                        </Grid>
                    </Meta>
                </Content>
                {/* )} */}
                {actions && <Actions className={"actions"}>{!isHover && <>{actions}</>}</Actions>}
            </StyledCard>
        </>
    );
};

export default AssetCard;
