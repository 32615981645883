import React from "react";
import Loadable from "@react-loadable/revised";
import Loader, { EntryLoader } from "../components/loader";
import { Redirect } from "react-router";
import menuService from "@services/menuService";
import { MENU_LIST } from "@constants";

//TODO push, pop event 일때 초기화할 수 있는 공통 로직 필요
const LoadableMain = Loadable({
    loader: () => import("../routes/dashboard"),
    loading: Loader,
});

const LoadableAccessTokenBridge = Loadable({
    loader: () => import("../routes/oauth2/access-token-bridge/AccessTokenBridge"),
    loading: Loader,
});

const LoadableUpload = Loadable({
    loader: () => import("../routes/upload"),
    loading: Loader,
});

const LoadableJob = Loadable({
    loader: () => import("../routes/job"),
    loading: Loader,
    render(loaded, props) {
        let Component = loaded.default;

        return <Component historyAction={props.history.action} {...props} />;
    },
});

const LoadableJobDetail = Loadable({
    loader: () => import("../routes/job/Detail"),
    loading: Loader,
});

// TODO: Player 뷰가 왜 다섯개냐 ㅠㅜ vod / live 성격으로 두 개로 나눌 수 있는지 검토 필요함.

const LoadableJobPreview = Loadable({
    loader: () => import("../routes/job/Preview"),
    loading: Loader,
});

const LoadableLivePlayer = Loadable({
    loader: () => import("../routes/listings/Player"),
    loading: Loader,
});

const LoadableChannelBaseVideoAssetPreview = Loadable({
    loader: () => import("../routes/channels/ui/BaseVideoAssetPreview"),
    loading: Loader,
});

const LoadableProgramsPreview = Loadable({
    loader: () => import("../routes/programs/Preview"),
    loading: Loader,
});

const LoadablePlayer = Loadable({
    loader: () => import("../routes/players/Player"),
    loading: Loader,
});

// 여기까지 다 플레이어 뷰임..

const LoadableVideoList = Loadable({
    loader: () => import("../routes/video"),
    loading: Loader,
});

const LoadableVideoDetail = Loadable({
    loader: () => import("../routes/video/Detail").catch((error) => console.error(error)),
    loading: Loader,
});

const LoadableAssets = Loadable({
    loader: () => import("../routes/asset/List").catch((error) => console.error(error)),
    loading: Loader,
});

const LoadableAssetDetail = Loadable({
    loader: () => import("../routes/asset/Detail").catch((error) => console.error(error)),
    loading: Loader,
});

const LoadableArchivedAssets = Loadable({
    loader: () => import("../routes/archived-assets/List"),
    loading: Loader,
});

const LoadableArchivedAssetDetail = Loadable({
    loader: () => import("../routes/archived-assets/Detail"),
    loading: Loader,
});
const LoadableAssetsShareHistory = Loadable({
    loader: () => import("../routes/asset/SharedHistoryList"),
    loading: Loader,
});

const LoadableImagesViewer = Loadable({
    loader: () => import("../routes/images-viewer"),
    loading: Loader,
});

const LoadableShare = Loadable({
    loader: () => import("../routes/asset/SharedBoxList"),
    loading: Loader,
});

const LoadableSharedHistoriesList = Loadable({
    loader: () => import("../routes/shared/HistoriesList"),
    loading: Loader,
});

const LoadableSharedBoxList = Loadable({
    loader: () => import("../routes/shared/BoxList"),
    loading: Loader,
});

const LoadableShareDetail = Loadable({
    loader: () => import("../routes/share-old/Detail"),
    loading: Loader,
});

const LoadableSources = Loadable({
    loader: () => import("../routes/sources/List"),
    loading: Loader,
});
const LoadableStorageShareHistory = Loadable({
    loader: () => import("../routes/sources/SharedHistoryList"),
    loading: Loader,
});

const LoadableStorageSharebox = Loadable({
    loader: () => import("../routes/sources/SharedBoxList"),
    loading: Loader,
});

const LoadableResourceStatistics = Loadable({
    loader: () => import("../components/resource-statistics/ResourceStatistics"),
    loading: Loader,
});

const LoadableAssetCreate = Loadable({
    loader: () => import("../routes/asset/Create"),
    loading: Loader,
});

const LoadablePeople = Loadable({
    loader: () => import("../routes/people"),
    loading: Loader,
});

const LoadablePeopleDetail = Loadable({
    loader: () => import("../routes/people/Detail"),
    loading: Loader,
});

const LoadablePeopleCreate = Loadable({
    loader: () => import("../routes/people/Create"),
    loading: Loader,
});

const LoadableMusic = Loadable({
    loader: () => import("../routes/music/List"),
    loading: Loader,
});

const LoadableMusicDetail = Loadable({
    loader: () => import("../routes/music/Detail"),
    loading: Loader,
});

const LoadableMusicCreate = Loadable({
    loader: () => import("../routes/music/Create"),
    loading: Loader,
});

const LoadablePhoto = Loadable({
    loader: () => import("../routes/photo/List"),
    loading: Loader,
});

const LoadablePhotoDetail = Loadable({
    loader: () => import("../routes/photo/Detail"),
    loading: Loader,
});

const LoadablePhotoCreate = Loadable({
    loader: () => import("../routes/photo/Create"),
    loading: Loader,
});

const LoadableStreams = Loadable({
    loader: () => import("../routes/streams"),
    loading: Loader,
});

const LoadableStreamsDetail = Loadable({
    loader: () => import("../routes/streams/Detail"),
    loading: Loader,
});

const LoadableStreamsCreate = Loadable({
    loader: () => import("../routes/streams/Create"),
    loading: Loader,
});

const LoadablePrograms = Loadable({
    loader: () => import("../routes/programs"),
    loading: Loader,
});

const LoadableStage = Loadable({
    loader: () => import("../routes/stage/Stage"),
    loading: Loader,
});

const LoadableListings = Loadable({
    loader: () => import("../routes/listings"),
    loading: Loader,
});

const LoadableListingsDetail = Loadable({
    loader: () => import("../routes/listings/Detail"),
    loading: Loader,
});

const LoadableListingsLineups = Loadable({
    loader: () => import("../routes/listings/Lineups"),
    loading: Loader,
});

const LoadableListingsCreate = Loadable({
    loader: () => import("../routes/listings/Create"),
    loading: Loader,
});

const LoadableChannels = Loadable({
    loader: () => import("../routes/channels"),
    loading: Loader,
});

const LoadableChannelsCreate = Loadable({
    loader: () => import("../routes/channels/Create"),
    loading: Loader,
});

const LoadableChannel = Loadable({
    loader: () => import("../routes/channels/Detail"),
    loading: Loader,
});

const LoadableSearch = Loadable({
    loader: () => import("../routes/search"),
    loading: Loader,
});

const LoadableSearchWithSE = Loadable({
    loader: () => import("../routes/searchWithSE"),
    loading: Loader,
});

const LoadableAdminStatistics = Loadable({
    loader: () => import("../routes/admins/statistics"),
    loading: Loader,
});

const LoadableAdminChannels = Loadable({
    loader: () => import("../routes/admins/channels/List"),
    loading: Loader,
});

const LoadableAdminChannel = Loadable({
    loader: () => import("../routes/admins/channels/Detail"),
    loading: Loader,
});

const LoadableAdminRoles = Loadable({
    loader: () => import("../routes/admins/roles/list-role-container"),
    loading: Loader,
});
const LoadableAdminRole = Loadable({
    loader: () => import("../routes/admins/roles/detail-role-container"),
    loading: Loader,
});

const LoadableAdminCreateRoles = Loadable({
    loader: () => import("../routes/admins/roles/Create"),
    loading: Loader,
});

const LoadableAdminMetadataSchemaList = Loadable({
    loader: () => import("../routes/admins/metadata-schemas/List"),
    loading: Loader,
});
const LoadableAdminMetadataSchema = Loadable({
    loader: () => import("../routes/admins/metadata-schemas/Detail"),
    loading: Loader,
});

const LoadableAdminCreateMetadataSchemas = Loadable({
    loader: () => import("../routes/admins/metadata-schemas/Create"),
    loading: Loader,
});

const LoadableAdminAddRoles = Loadable({
    loader: () => import("../routes/admins/roles/ui/AddRoles"),
    loading: Loader,
});

const LoadableAdminEventTriggerList = Loadable({
    loader: () => import("../routes/admins/event-triggers/List"),
    loading: Loader,
});

const LoadableAdminEventTriggerDetail = Loadable({
    loader: () => import("../routes/admins/event-triggers/Detail"),
    loading: Loader,
});

const LoadableAdminEventTriggerCreate = Loadable({
    loader: () => import("../routes/admins/event-triggers/Create"),
    loading: Loader,
});

const LoadableAdminMetadataTemplateList = Loadable({
    loader: () => import("../routes/admins/metadata-template/List"),
    loading: Loader,
});

const LoadableAdminMetadataTemplateCreate = Loadable({
    loader: () => import("../routes/admins/metadata-template/Create"),
    loading: Loader,
});

const LoadableAdminMetadataTemplateDetail = Loadable({
    loader: () => import("../routes/admins/metadata-template/Detail"),
    loading: Loader,
});

const LoadableAdminSettings = Loadable({
    loader: () => import("../routes/admins/settings"),
    loading: Loader,
});

const LoadableAdminHistories = Loadable({
    loader: () => import("../routes/admins/histories"),
    loading: Loader,
});

const LoadableAdminMetadataExport = Loadable({
    loader: () => import("../routes/admins/metadata-export/list-metadata-export"),
    loading: Loader,
});

const LoadableAdminMetadataExportDetail = Loadable({
    loader: () => import("../routes/admins/metadata-export/detail-metadata-export"),
    loading: Loader,
});

const LoadableAdminSnsLogin = Loadable({
    loader: () => import("../routes/snsLogin/LoginPopup"),
    loading: Loader,
});

const LoadableAdminUsers = Loadable({
    loader: () => import("@routes/admins/users/list-users"),
    loading: Loader,
});

const LoadableAdminUser = Loadable({
    loader: () => import("../routes/admins/users/detail-users"),
    loading: Loader,
});

const LoadableAdminCategoryGroups = Loadable({
    loader: () => import("../routes/admins/category-groups"),
    loading: Loader,
});

const LoadableAdminCreateCategoryGroup = Loadable({
    loader: () => import("../routes/admins/category-groups/Create"),
    loading: Loader,
});

const LoadableAdminCategoryGroup = Loadable({
    loader: () => import("../routes/admins/category-groups/Detail"),
    loading: Loader,
});

const LoadableAdminAuthoritiesUsers = Loadable({
    loader: () => import("../routes/admins/authorities/list-users"),
    loading: Loader,
});
const LoadableAdminAuthoritiesTeams = Loadable({
    loader: () => import("../routes/admins/authorities/list-teams"),
    loading: Loader,
});

const LoadableAdminAuthorityForTeam = Loadable({
    loader: () => import("../routes/admins/authorities/detail-team"),
    loading: Loader,
});

const LoadableAdminAuthorityForUser = Loadable({
    loader: () => import("../routes/admins/authorities/detail-user"),
    loading: Loader,
});

const LoadableAdminTeams = Loadable({
    loader: () => import("../routes/admins/teams/list-teams"),
    loading: Loader,
});

const LoadableAdminTeam = Loadable({
    loader: () => import("../routes/admins/teams/detail-teams"),
    loading: Loader,
});

const LoadableAdminProjects = Loadable({
    loader: () => import("../routes/admins/projects/List"),
    loading: Loader,
});

const LoadableAdminProject = Loadable({
    loader: () => import("../routes/admins/projects/detail-projects"),
    loading: Loader,
});

const LoadableAdminProjectCreate = Loadable({
    loader: () => import("../routes/admins/projects/Create"),
    loading: Loader,
});

const LoadableAdminProjectAssignedUserList = Loadable({
    loader: () => import("../routes/admins/projects/list-assigned-user"),
    loading: Loader,
});

const LoadableAdminProjectAssignedTeamList = Loadable({
    loader: () => import("../routes/admins/projects/list-assigned-team"),
    loading: Loader,
});

const LoadableAdminTeamAssignableUserList = Loadable({
    loader: () => import("../routes/admins/teams/list-assignable-user"),
    loading: Loader,
});

const LoadableContentSchemas = Loadable({
    loader: () => import("../routes/admins/content-schemas/list-content-schemas"),
    loading: Loader,
});

const LoadableAdminContentSchemaDetail = Loadable({
    loader: () => import("../routes/admins/content-schemas/detail-content-schema"),
    loading: Loader,
});

const LoadableCaptionPreview = Loadable({
    loader: () => import("../routes/preview/CaptionPreview"),
    loading: Loader,
});

const LoadableImagePreview = Loadable({
    loader: () => import("../routes/preview/ImagePreview"),
    loading: Loader,
});

const LoadableVideoPreview = Loadable({
    loader: () => import("../routes/preview/VideoPreview"),
    loading: Loader,
});

const LoadableMeetingCreate = Loadable({
    loader: () => import("../routes/meetings_v2/Create"),
    loading: Loader,
});
const LoadableMeetingDetail = Loadable({
    loader: () => import("../routes/meetings_v2/Detail"),
    loading: Loader,
});

const LoadableMeetingCreate_Old = Loadable({
    loader: () => import("../routes/meetings/Create"),
    loading: Loader,
});
const LoadableMeetingDetail_Old = Loadable({
    loader: () => import("../routes/meetings/Detail"),
    loading: Loader,
});

const LoadableInteractiveLive = Loadable({
    loader: () => import("../routes/interactive-live/List"),
    loading: Loader,
});

const LoadableInteractiveLiveCreate = Loadable({
    loader: () => import("../routes/interactive-live/Create"),
    loading: Loader,
});

const LoadableInteractiveLiveDetail = Loadable({
    loader: () => import("../routes/interactive-live/Detail"),
    loading: Loader,
});

const LoadableInteractiveVideo = Loadable({
    loader: () => import("../routes/interactive-video/List"),
    loading: Loader,
});

const LoadableInteractiveVideoCreate = Loadable({
    loader: () => import("../routes/interactive-video/Create"),
    loading: Loader,
});

const LoadableInteractiveVideoDetail = Loadable({
    loader: () => import("../routes/interactive-video/Detail"),
    loading: Loader,
});

const LoadableMeetingList_Old = Loadable({
    loader: () => import("../routes/meetings"),
    loading: Loader,
});

const LoadableMeetingList = Loadable({
    loader: () => import("../routes/meetings_v2/List"),
    loading: Loader,
});

const LoadableChat = Loadable({
    loader: () => import("../routes/chat/List"),
    loading: Loader,
});

const LoadableRequestDenied = Loadable({
    loader: () => import("../routes/errors/RequestAccess"),
    loading: Loader,
});

const LoadableAssetDeleted = Loadable({
    loader: () => import("../routes/errors/DeletedAsset"),
    loading: Loader,
});

const LoadableAssetExistNot = Loadable({
    loader: () => import("../routes/errors/Error404"),
    loading: Loader,
});

const LoadableEntryConsole = Loadable({
    loader: () => import("../routes/entryConsole/entryConsole"),
    loading: EntryLoader,
});

const LoadableStoragePreview = Loadable({
    loader: () => import("../routes/preview/StoragePreview"),
    loading: Loader,
});

const LoadableCollections = Loadable({
    loader: () => import("@routes/collections/list-collections").catch((error) => console.error(error)),
    loading: Loader,
});

const LoadableCollectionsSearch = Loadable({
    loader: () => import("@routes/collections/list-search-collections").catch((error) => console.error(error)),
    loading: Loader,
});

const LoadableCollectionDetail = Loadable({
    loader: () => import("@routes/collections/detail-collection").catch((error) => console.error(error)),
    loading: Loader,
});

const LoadableCustomContents = Loadable({
    loader: () => import("@routes/custom-contents/list-custom-contents").catch((error) => console.error(error)),
    loading: Loader,
});

const LoadableCustomContentsSearch = Loadable({
    loader: () => import("@routes/custom-contents/list-search-custom-contents").catch((error) => console.error(error)),
    loading: Loader,
});

const LoadableCustomContentDetail = Loadable({
    loader: () => import("@routes/custom-contents/detail-custom-content").catch((error) => console.error(error)),
    loading: Loader,
});

const LoadableVocabularies = Loadable({
    loader: () => import("@routes/vocabulary/list-vocabularies").catch((error) => console.error(error)),
    loading: Loader,
});
const LoadableVocabularyDetail = Loadable({
    loader: () => import("@routes/vocabulary/detail-vocabularies").catch((error) => console.error(error)),
    loading: Loader,
});
const LoadableVocabularySearch = Loadable({
    loader: () => import("@routes/vocabulary/list-search-vocabularies").catch((error) => console.error(error)),
    loading: Loader,
});
export const defaultPath = "/spaces/:spaceId/projects/:projectId";
export const adminSpacePath = "/admin/spaces/:spaceId";
export const adminProjectPath = "/admin/spaces/:spaceId/projects/:projectId";

// TODO: 다국어 확인. name

const assetRoutes = [
    {
        name: "Assets",
        exact: true,
        path: `${defaultPath}/assets/assets`,
        component: LoadableAssets,
        redirectPath: `${defaultPath}/assets/assets`,
        connectedMenuId: MENU_LIST.ASSETS,
    },
    {
        name: "Asset Create",
        exact: true,
        path: `${defaultPath}/assets/assets/create`,
        component: LoadableAssetCreate,
        redirectPath: `${defaultPath}/assets/assets/create`,
        connectedMenuId: MENU_LIST.ASSETS,
    },
    {
        name: "Asset Detail",
        exact: true,
        path: `${defaultPath}/assets/assets/:id`,
        component: LoadableAssetDetail,
        redirectPath: `${defaultPath}/assets/assets`,
        connectedMenuId: MENU_LIST.ASSETS,
    },
    {
        name: "Asset Statistics",
        exact: true,
        path: `${defaultPath}/assets/assets/:id/statistics`,
        component: LoadableResourceStatistics,
        redirectPath: `${defaultPath}/assets/assets`,
        connectedMenuId: MENU_LIST.ASSETS,
    },
    {
        name: "Archived Assets",
        exact: true,
        path: `${defaultPath}/assets/archived`,
        component: LoadableArchivedAssets,
        connectedMenuId: MENU_LIST.ARCHIVED,
    },
    {
        name: "Archived Asset Detail",
        exact: true,
        path: `${defaultPath}/assets/archived/:id`,
        component: LoadableArchivedAssetDetail,
        connectedMenuId: MENU_LIST.ARCHIVED,
    },
    {
        name: "Share Box",
        exact: true,
        path: `${defaultPath}/assets/sharebox`,
        component: LoadableShare,
        redirectPath: `${defaultPath}/assets/sharebox`,
        connectedMenuId: MENU_LIST.ASSETS_SHARED_BOX,
    },
    {
        name: "Share Box Detail",
        exact: true,
        path: `${defaultPath}/assets/sharebox/:id`,
        component: LoadableShareDetail,
        redirectPath: `${defaultPath}/assets/sharebox`,
        connectedMenuId: MENU_LIST.ASSETS_SHARED_BOX,
    },
    {
        name: "Asset Collections",
        exact: true,
        path: `${defaultPath}/assets/collections`,
        component: LoadableCollections,
        connectedMenuId: MENU_LIST.COLLECTIONS,
    },
    {
        name: "Asset Collections Search",
        exact: true,
        path: `${defaultPath}/assets/collections/search`,
        component: LoadableCollectionsSearch,
        connectedMenuId: MENU_LIST.COLLECTIONS,
    },
    {
        name: "Asset Collection Detail",
        exact: true,
        path: `${defaultPath}/assets/collections/:id`,
        component: LoadableCollectionDetail,
        connectedMenuId: MENU_LIST.COLLECTIONS,
    },
    {
        name: "Images Viewer",
        exact: true,
        path: `${defaultPath}/images-viewer`,
        component: LoadableImagesViewer,
        redirectPath: `${defaultPath}/images-viewer`,
        connectedMenuId: MENU_LIST.ASSETS,
    },
    {
        name: "Assets",
        exact: true,
        path: `${defaultPath}/assets`,
        component: LoadableAssets,
        redirectPath: `${defaultPath}/assets`,
        connectedMenuId: MENU_LIST.ASSETS,
    },
    {
        name: "Asset Create",
        exact: true,
        path: `${defaultPath}/assets/create`,
        component: LoadableAssetCreate,
        redirectPath: `${defaultPath}/assets/create`,
        connectedMenuId: MENU_LIST.ASSETS,
    },
    {
        name: "Sources",
        exact: true,
        path: `${defaultPath}/assets/sources`,
        component: LoadableSources,
        connectedMenuId: MENU_LIST.STORAGES,
    },
    {
        name: "Asset Shared History",
        exact: true,
        path: `${defaultPath}/assets/sharehistory`,
        component: LoadableAssetsShareHistory,
        redirectPath: `${defaultPath}/assets/sharehistory`,
        connectedMenuId: MENU_LIST.ASSETS_SHARED_HISTORIES,
    },
    {
        name: "Asset Detail",
        exact: true,
        path: `${defaultPath}/assets/:id`,
        component: LoadableAssetDetail,
        redirectPath: `${defaultPath}/assets`,
        connectedMenuId: MENU_LIST.ASSETS,
    },
    {
        name: "Asset Statistics",
        exact: true,
        path: `${defaultPath}/assets/:id/statistics`,
        component: LoadableResourceStatistics,
        redirectPath: `${defaultPath}/assets`,
        connectedMenuId: MENU_LIST.ASSETS,
    },
    {
        name: "Asset Access Denied",
        exact: true,
        path: `/error/assets/accessdenied/:id`,
        component: LoadableRequestDenied,
        connectedMenuId: MENU_LIST.ASSETS,
    },
    {
        name: "Asset Deleted",
        exact: true,
        path: `/error/assets/deleted/:id`,
        component: LoadableAssetDeleted,
        connectedMenuId: MENU_LIST.ASSETS,
    },
    {
        name: "Asset Preview",
        exact: true,
        path: `${defaultPath}/assets/preview`,
        component: LoadableChannelBaseVideoAssetPreview,
        connectedMenuId: MENU_LIST.ASSETS,
    },
    {
        name: "Caption Asset Sharebox Preview",
        exact: true,
        isPreview: true,
        path: `${defaultPath}/assets/sharebox/:groupId/captions`,
        redirectPath: `${defaultPath}/sharebox/assets/:groupId/captions`,
        component: LoadableCaptionPreview,
        connectedMenuId: MENU_LIST.ASSETS_SHARED_BOX,
    },
    {
        name: "Image Asset Sharebox Preview",
        exact: true,
        path: `${defaultPath}/assets/sharebox/:groupId/images`,
        redirectPath: `${defaultPath}/sharebox/assets/:groupId/images`,
        component: LoadableImagePreview,
        connectedMenuId: MENU_LIST.ASSETS_SHARED_BOX,
    },
    {
        name: "Videos Asset Sharebox Preview",
        exact: true,
        path: `${defaultPath}/assets/sharebox/:groupId/videos`,
        redirectPath: `${defaultPath}/sharebox/assets/:groupId/videos`,
        component: LoadableVideoPreview,
        connectedMenuId: MENU_LIST.ASSETS_SHARED_BOX,
    },

    {
        name: "Caption Asset Preview",
        exact: true,
        isPreview: true,
        path: `${defaultPath}/assets/:groupId/captions`,
        redirectPath: `${defaultPath}/assets/:groupId/captions`,
        component: LoadableCaptionPreview,
        connectedMenuId: MENU_LIST.ASSETS,
    },
    {
        name: "Image Asset Preview",
        exact: true,
        path: `${defaultPath}/assets/:groupId/images`,
        redirectPath: `${defaultPath}/assets/:groupId/images`,
        component: LoadableImagePreview,
        connectedMenuId: MENU_LIST.ASSETS,
    },
    {
        name: "Video Asset Preview",
        exact: true,
        path: `${defaultPath}/assets/:groupId/videos`,
        redirectPath: `${defaultPath}/assets/:groupId/videos`,
        component: LoadableVideoPreview,
        connectedMenuId: MENU_LIST.ASSETS,
    },
    {
        name: "Caption Asset Preview",
        exact: true,
        isPreview: true,
        path: `${defaultPath}/assets/assets/:groupId/captions`,
        redirectPath: `${defaultPath}/assets/assets/:groupId/captions`,
        component: LoadableCaptionPreview,
        connectedMenuId: MENU_LIST.ASSETS,
    },
    {
        name: "Image Asset Preview",
        exact: true,
        path: `${defaultPath}/assets/assets/:groupId/images`,
        redirectPath: `${defaultPath}/assets/assets/:groupId/images`,
        component: LoadableImagePreview,
        connectedMenuId: MENU_LIST.ASSETS,
    },
    {
        name: "Video Asset Preview",
        exact: true,
        path: `${defaultPath}/assets/assets/:groupId/videos`,
        redirectPath: `${defaultPath}/assets/assets/:groupId/videos`,
        component: LoadableVideoPreview,
        connectedMenuId: MENU_LIST.ASSETS,
    },
];

const jobRoutes = [
    {
        name: "Jobs",
        exact: true,
        path: `${defaultPath}/jobs`,
        component: LoadableJob,
        redirectPath: `${defaultPath}/jobs`,
        connectedMenuId: MENU_LIST.JOBS,
    },
    {
        name: "Create Job",
        exact: true,
        path: `${defaultPath}/transcoding/upload`,
        component: LoadableUpload,
        redirectPath: `${defaultPath}/transcoding/upload`,
        connectedMenuId: MENU_LIST.VIDEOS,
    },
    {
        name: "Job Detail",
        exact: true,
        path: `${defaultPath}/jobs/:id`,
        component: LoadableJobDetail,
        redirectPath: `${defaultPath}/jobs`,
        connectedMenuId: MENU_LIST.JOBS,
    },
    {
        name: "Job Preview",
        exact: true,
        path: `${defaultPath}/jobs/:id/preview/:type/:idx`,
        component: LoadableJobPreview,
        redirectPath: "/errors/500",
        connectedMenuId: MENU_LIST.JOBS,
    },
    // {
    //     name: "Transcoding",
    //     exact: true,
    //     path: "/jobs",
    //     render: () => (<Redirect to={"/jobs"} />)
    // },
];

const storageRoutes = [
    {
        name: "Storage",
        exact: true,
        path: `${defaultPath}/storage`,
        component: Redirect,
        redirectPath: `${defaultPath}/storage/storage`,
        render: (props) => {
            const { spaceId, projectId } = props.match.params;
            const url = menuService.paths.storage(spaceId, projectId);
            return <Redirect to={url} />;
        },
        connectedMenuId: MENU_LIST.STORAGES,
    },
    {
        name: "Storage",
        exact: true,
        path: `${defaultPath}/storage/storage`,
        component: LoadableSources,
        connectedMenuId: MENU_LIST.STORAGES,
    },
    {
        name: "Storage Preview",
        exact: true,
        path: `${defaultPath}/storage/preview`,
        component: LoadableStoragePreview,
        connectedMenuId: MENU_LIST.STORAGES,
    },
    {
        name: "Storage Shared Histroy",
        exact: true,
        path: `${defaultPath}/storage/sharehistory`,
        component: LoadableStorageShareHistory,
        connectedMenuId: MENU_LIST.STORAGE_SHARED_HISTORIES,
    },
    {
        name: "Storage Shared box",
        exact: true,
        path: `${defaultPath}/storage/sharebox`,
        component: LoadableStorageSharebox,
        connectedMenuId: MENU_LIST.STORAGE_SHARED_BOX,
    },
];

const sharedRoutes = [
    {
        name: "Shared",
        exact: true,
        path: `${defaultPath}/shared/history`,
        component: LoadableSharedHistoriesList,
        connectedMenuId: MENU_LIST.SHARED_HISTORIES,
    },
    {
        name: "Shared",
        exact: true,
        path: `${defaultPath}/shared/box`,
        component: LoadableSharedBoxList,
        connectedMenuId: MENU_LIST.SHARED_BOX,
    },
];

const contentsRoutes = [
    {
        name: "Contents",
        exact: true,
        path: `${defaultPath}/contents`,
        component: Redirect,
        redirectPath: `${defaultPath}/contents/videos`,
        render: (props) => {
            const { spaceId, projectId } = props.match.params;
            const url = menuService.paths.contentsVideos(spaceId, projectId);
            return <Redirect to={url} />;
        },
    },
    {
        name: "Videos",
        exact: true,
        path: `${defaultPath}/contents/videos`,
        component: LoadableVideoList,
        redirectPath: `${defaultPath}/contents/videos`,
        connectedMenuId: MENU_LIST.VIDEOS,
    },
    {
        name: "Video Create",
        exact: true,
        path: `${defaultPath}/contents/videos/create`,
        component: LoadableUpload,
        redirectPath: `${defaultPath}/contents/videos`,
        connectedMenuId: MENU_LIST.VIDEOS,
    },
    {
        name: "Video Detail",
        exact: true,
        path: `${defaultPath}/contents/videos/:id`,
        component: LoadableVideoDetail,
        redirectPath: `${defaultPath}/contents/videos`,
        connectedMenuId: MENU_LIST.VIDEOS,
    },

    {
        name: "Video Statistics",
        exact: true,
        path: `${defaultPath}/contents/videos/:id/statistics`,
        component: LoadableResourceStatistics,
        redirectPath: `${defaultPath}/contents/videos`,
        connectedMenuId: MENU_LIST.VIDEOS,
    },
    {
        name: "People",
        exact: true,
        path: `${defaultPath}/contents/people`,
        component: LoadablePeople,
        redirectPath: `${defaultPath}/contents/people`,
        connectedMenuId: MENU_LIST.PEOPLE,
    },
    {
        name: "People Create",
        exact: true,
        path: `${defaultPath}/contents/people/create`,
        component: LoadablePeopleCreate,
        redirectPath: `${defaultPath}/contents/people/create`,
        connectedMenuId: MENU_LIST.PEOPLE,
    },
    {
        name: "People Detail",
        exact: true,
        path: `${defaultPath}/contents/people/:id`,
        component: LoadablePeopleDetail,
        redirectPath: `${defaultPath}/contents/people`,
        connectedMenuId: MENU_LIST.PEOPLE,
    },
    {
        name: "Music",
        exact: true,
        path: `${defaultPath}/contents/music`,
        component: LoadableMusic,
        redirectPath: `${defaultPath}/contents/music`,
        connectedMenuId: MENU_LIST.MUSIC,
    },
    {
        name: "Music Create",
        exact: true,
        path: `${defaultPath}/contents/music/create`,
        component: LoadableMusicCreate,
        redirectPath: `${defaultPath}/contents/music/create`,
        connectedMenuId: MENU_LIST.MUSIC,
    },
    {
        name: "Music Detail",
        exact: true,
        path: `${defaultPath}/contents/music/:id`,
        component: LoadableMusicDetail,
        redirectPath: `${defaultPath}/contents/music`,
        connectedMenuId: MENU_LIST.MUSIC,
    },
    {
        name: "Photos",
        exact: true,
        path: `${defaultPath}/contents/photos`,
        component: LoadablePhoto,
        connectedMenuId: MENU_LIST.PHOTO,
    },
    {
        name: "Photo Create",
        exact: true,
        path: `${defaultPath}/contents/photos/create`,
        component: LoadablePhotoCreate,
        connectedMenuId: MENU_LIST.PHOTO,
    },
    {
        name: "Photo Detail",
        exact: true,
        path: `${defaultPath}/contents/photos/:id`,
        component: LoadablePhotoDetail,
        connectedMenuId: MENU_LIST.PHOTO,
    },
    {
        name: "Photo list redirect",
        exact: true,
        path: `${defaultPath}/contents/photo`,
        render: (props) => {
            const { spaceId, projectId } = props.match.params;
            const url = menuService.paths.contentsPhoto(spaceId, projectId);
            return <Redirect to={url} />;
        },
    },
    {
        name: "Photo Detail redirect",
        exact: true,
        path: `${defaultPath}/contents/photo/:id`,
        render: (props) => {
            const { spaceId, projectId } = props.match.params;
            const url = menuService.paths.contentsPhotoDetail(spaceId, projectId, props.match.params.id);
            return <Redirect to={url} />;
        },
    },
    {
        name: "Programs",
        exact: true,
        path: `${defaultPath}/contents/programs`,
        component: LoadablePrograms,
    },
    {
        name: "Programs Detail",
        exact: true,
        path: `${defaultPath}/contents/programs/:id/preview`,
        component: LoadableProgramsPreview,
    },
    {
        name: "Custom Content list",
        exact: true,
        path: `${defaultPath}/contents/:schemaId`,
        component: LoadableCustomContents,
        connectedMenuId: MENU_LIST.CUSTOM_CONTENTS,
    },
    {
        name: "Custom Content search list",
        exact: true,
        path: `${defaultPath}/contents/:schemaId/search`,
        component: LoadableCustomContentsSearch,
        connectedMenuId: MENU_LIST.CUSTOM_CONTENTS,
    },
    {
        name: "Custom Content Detail",
        exact: true,
        path: `${defaultPath}/contents/:schemaId/:contentId`,
        component: LoadableCustomContentDetail,
        connectedMenuId: MENU_LIST.CUSTOM_CONTENTS,
    },
];

const liveRoutes = [
    {
        name: "Live",
        exact: true,
        path: `${defaultPath}/live`,
        component: Redirect,
        redirectPath: `${defaultPath}/live/streams`,
        render: (props) => {
            const { spaceId, projectId } = props.match.params;
            const url = menuService.paths.liveStream(spaceId, projectId);
            return <Redirect to={url} />;
        },
    },
    {
        name: "Stream",
        exact: true,
        path: `${defaultPath}/live/streams`,
        component: LoadableStreams,
        redirectPath: `${defaultPath}/live/streams`,
        connectedMenuId: MENU_LIST.STREAMS,
    },
    {
        name: "Create Streams",
        exact: true,
        path: `${defaultPath}/live/streams/create`,
        component: LoadableStreamsCreate,
        redirectPath: `${defaultPath}/live/streams`,
        connectedMenuId: MENU_LIST.STREAMS,
    },
    {
        name: "Event Stream",
        exact: true,
        path: `${defaultPath}/live/streams/:id`,
        component: LoadableStreamsDetail,
        redirectPath: `${defaultPath}/live/streams`,
        connectedMenuId: MENU_LIST.STREAMS,
    },
    {
        name: "Player",
        exact: true,
        path: `${defaultPath}/player`,
        component: LoadablePlayer,
    },
    {
        name: "Live Player Detail Play",
        exact: true,
        path: `${defaultPath}/live/:id/play`,
        component: LoadableLivePlayer,
    },
    {
        name: "Live Player Detail Preview",
        exact: true,
        path: `${defaultPath}/listing/:id/preview`,
        component: LoadableLivePlayer,
    },
    {
        name: "Listings",
        exact: true,
        path: `${defaultPath}/live/schedules`,
        component: LoadableListings,
        redirectPath: `${defaultPath}/live/schedules`,
        connectedMenuId: MENU_LIST.SCHEDULES,
    },
    {
        name: "Listings Create",
        exact: true,
        path: `${defaultPath}/live/schedules/create`,
        component: LoadableListingsCreate,
        redirectPath: `${defaultPath}/live/schedules`,
        connectedMenuId: MENU_LIST.SCHEDULES,
    },
    {
        name: "Listings Detail",
        exact: true,
        path: `${defaultPath}/live/schedules/:id`,
        component: LoadableListingsDetail,
        redirectPath: `${defaultPath}/live/schedules`,
        connectedMenuId: MENU_LIST.SCHEDULES,
    },
    {
        name: "Listings Lineups",
        exact: true,
        path: `${defaultPath}/live/schedules/:id/lineups`,
        component: LoadableListingsLineups,
        redirectPath: `${defaultPath}/live/schedules`,
        connectedMenuId: MENU_LIST.SCHEDULES,
    },
    {
        name: "Channels",
        exact: true,
        path: `${defaultPath}/live/channels`,
        component: LoadableChannels,
        redirectPath: `${defaultPath}/live/channels`,
        connectedMenuId: MENU_LIST.CHANNELS,
    },
    {
        name: "Channels Create",
        exact: true,
        path: `${defaultPath}/live/channels/create`,
        component: LoadableChannelsCreate,
        redirectPath: `${defaultPath}/live/channels`,
        connectedMenuId: MENU_LIST.CHANNELS,
    },
    {
        name: "Channels Detail",
        exact: true,
        path: `${defaultPath}/live/channels/:id`,
        component: LoadableChannel,
        redirectPath: `${defaultPath}/live/channels`,
        connectedMenuId: MENU_LIST.CHANNELS,
    },
    {
        name: "Channels Statistics",
        exact: true,
        path: `${defaultPath}/live/channels/:id/statistics`,
        component: LoadableResourceStatistics,
        redirectPath: `${defaultPath}/live/channels`,
        connectedMenuId: MENU_LIST.CHANNELS,
    },
];

const interactiveRoutes = [
    {
        name: "Interactive Videos",
        exact: true,
        path: `${defaultPath}/interactive-videos`,
        component: LoadableInteractiveVideo,
        redirectPath: `${defaultPath}/interactive-videos`,
        connectedMenuId: MENU_LIST.INTERACTIVE_VIDEOS,
    },
    {
        name: "Interactive Videos",
        exact: true,
        path: `${defaultPath}/interactive-videos/create`,
        component: LoadableInteractiveVideoCreate,
        redirectPath: `${defaultPath}/interactive-videos/create`,
        connectedMenuId: MENU_LIST.INTERACTIVE_VIDEOS,
    },
    {
        name: "Interactive Videos Detail",
        exact: true,
        path: `${defaultPath}/interactive-videos/:id`,
        component: LoadableInteractiveVideoDetail,
        redirectPath: `${defaultPath}/interactive-videos/detail`,
        connectedMenuId: MENU_LIST.INTERACTIVE_VIDEOS,
    },
    {
        name: "Interactive Live",
        exact: true,
        path: `${defaultPath}/interactive-live`,
        component: LoadableInteractiveLive,
        redirectPath: `${defaultPath}/interactive-live`,
    },
    {
        name: "Interactive Live Create",
        exact: true,
        path: `${defaultPath}/interactive-live/create`,
        component: LoadableInteractiveLiveCreate,
        redirectPath: `${defaultPath}/interactive-live/create`,
    },
    {
        name: "Interactive Live Detail",
        exact: true,
        path: `${defaultPath}/interactive-live/:id`,
        component: LoadableInteractiveLiveDetail,
        redirectPath: `${defaultPath}/interactive-live/detail`,
    },
];

const searchRoutes = [
    {
        name: "Search",
        exact: true,
        path: `${defaultPath}/search`,
        component: LoadableSearch,
        redirectPath: `${defaultPath}/search`,
    },
    {
        name: "Search with Search Engine",
        exact: true,
        path: `${defaultPath}/search/engine`,
        component: LoadableSearchWithSE,
    },
];

const etcRoutes = [
    {
        name: "Chat",
        exact: true,
        path: `${defaultPath}/chat`,
        component: LoadableChat,
        redirectPath: `${defaultPath}/chat`,
    },
    {
        name: "Add Account",
        exact: true,
        path: "/snsLogin/:snsKind",
        redirectPath: "/snsLogin/:snsKind",
        component: LoadableAdminSnsLogin,
    },
    {
        name: "Add Account",
        exact: true,
        path: "/snsLogin/:snsKind/callback",
        redirectPath: "/snsLogin/:snsKind/callback",
        component: LoadableAdminSnsLogin,
    },
    {
        name: "Access token bridge",
        exact: true,
        path: "/access-token-bridge",
        redirectPath: "/access-token-bridge",
        component: LoadableAccessTokenBridge,
    },
];

const adminSettingsRoutes = [
    {
        name: "Admin Statistics",
        exact: true,
        path: `${adminSpacePath}/statistics`,
        component: LoadableAdminStatistics,
    },
    {
        name: "Admin Channels",
        exact: true,
        path: `${adminSpacePath}/live/channels`,
        redirectPath: `${adminSpacePath}/live/channels`,
        component: LoadableAdminChannels,
    },
    {
        name: "Admin Space Channel Detail",
        exact: true,
        path: `${adminSpacePath}/live/channels/:id`,
        redirectPath: `${adminSpacePath}/live/channels`,
        component: LoadableAdminChannel,
    },
    {
        name: "Admin Space Roles",
        exact: true,
        path: `${adminSpacePath}/roles`,
        redirectPath: "/roles",
        component: LoadableAdminRoles,
    },
    {
        name: "Admin Space Create Roles",
        exact: true,
        path: `${adminSpacePath}/roles/create`,
        redirectPath: `${adminSpacePath}/roles`,
        component: LoadableAdminCreateRoles,
    },
    {
        name: "Admin Space Role",
        exact: true,
        path: `${adminSpacePath}/roles/:id`,
        redirectPath: `${adminSpacePath}/roles`,
        component: LoadableAdminRole,
    },
    {
        name: "Admin Space Roles",
        exact: true,
        path: `${adminSpacePath}/:type/roles`,
        redirectPath: `${adminSpacePath}/:type/roles`,
        component: LoadableAdminRoles,
    },
    {
        name: "Admin Space Create Roles",
        exact: true,
        path: `${adminSpacePath}/:type/roles/create`,
        redirectPath: `${adminSpacePath}/:type/roles`,
        component: LoadableAdminCreateRoles,
    },
    {
        name: "Admin Space Role",
        exact: true,
        path: `${adminSpacePath}/:type/roles/:id`,
        redirectPath: `${adminSpacePath}/:type/roles`,
        component: LoadableAdminRole,
    },
    {
        name: "Admin Space Metadata Export",
        exact: true,
        path: `${adminSpacePath}/metadata-export`,
        redirectPath: `${adminSpacePath}/metadata-export`,
        component: LoadableAdminMetadataExport,
    },
    {
        name: "Admin Space Detail Metadata Export",
        exact: true,
        path: `${adminSpacePath}/metadata-export/:id`,
        redirectPath: `${adminSpacePath}/metadata-export/:id`,
        component: LoadableAdminMetadataExportDetail,
    },
    {
        name: "Admin Space Metadata",
        exact: true,
        path: `${adminSpacePath}/metadata`,
        redirectPath: `${adminSpacePath}/metadata`,
        component: LoadableAdminMetadataSchemaList,
    },
    {
        name: "Admin Space Create Metadata",
        exact: false,
        path: `${adminSpacePath}/metadata/create`,
        redirectPath: `${adminSpacePath}/metadata`,
        component: LoadableAdminCreateMetadataSchemas,
    },
    {
        name: "Admin Space Metadata",
        exact: true,
        path: `${adminSpacePath}/metadata/:id`,
        redirectPath: `${adminSpacePath}/metadata`,
        component: LoadableAdminMetadataSchema,
    },
    {
        name: "Admin Content Schemas",
        exact: true,
        path: `${adminSpacePath}/content-schemas`,
        redirectPath: `${adminSpacePath}/content-schemas`,
        component: LoadableContentSchemas,
    },
    {
        name: "Admin Content Schemas Detail",
        exact: true,
        path: `${adminSpacePath}/content-schemas/:schemaId`,
        redirectPath: `${adminSpacePath}/content-schemas`,
        component: LoadableAdminContentSchemaDetail,
    },
    {
        name: "Admin Space Settings",
        exact: true,
        path: `${adminSpacePath}/settings`,
        redirectPath: `${adminSpacePath}/settings`,
        component: LoadableAdminSettings,
    },
    {
        name: "Admin Space Groups",
        exact: true,
        path: `${adminSpacePath}/groups`,
        redirectPath: `${adminSpacePath}/groups`,
        component: LoadableAdminTeams,
    },
    {
        name: "Admin Space Groups Detail",
        exact: true,
        path: `${adminSpacePath}/groups/:id`,
        redirectPath: `${adminSpacePath}/groups`,
        component: LoadableAdminTeam,
    },
    {
        name: "Admin Space Group Assignable",
        exact: true,
        path: `${adminSpacePath}/groups/:id/assignable-users`,
        redirectPath: `${adminSpacePath}/groups`,
        component: LoadableAdminTeamAssignableUserList,
    },
    {
        name: "Admin Space Users",
        exact: true,
        path: `${adminSpacePath}/users`,
        redirectPath: `${adminSpacePath}/users`,
        component: LoadableAdminUsers,
    },
    {
        name: "Admin Space User",
        exact: true,
        path: `${adminSpacePath}/users/:id`,
        redirectPath: `${adminSpacePath}/users`,
        component: LoadableAdminUser,
    },
    {
        name: "Admin Space Projects",
        exact: true,
        path: `${adminSpacePath}/project-list`,
        redirectPath: `${adminSpacePath}/project-list`,
        component: LoadableAdminProjects,
    },
    {
        name: "Admin Space Project Create",
        exact: true,
        path: `${adminSpacePath}/project-list/create`,
        redirectPath: `${adminSpacePath}/project-list`,
        component: LoadableAdminProjectCreate,
    },
    {
        name: "Admin Space Project Detail",
        exact: true,
        path: `${adminSpacePath}/project-list/:id`,
        redirectPath: `${adminSpacePath}/project-list`,
        component: LoadableAdminProject,
    },
    {
        name: "Admin Space Project Assign User",
        exact: true,
        path: `${adminSpacePath}/project-list/:id/assigned-users`,
        redirectPath: `${adminSpacePath}/project-list`,
        component: LoadableAdminProjectAssignedUserList,
    },
    {
        name: "Admin Space Project Assign Groups",
        exact: true,
        path: `${adminSpacePath}/project-list/:id/assigned-groups`,
        redirectPath: `${adminSpacePath}/project-list`,
        component: LoadableAdminProjectAssignedTeamList,
    },
    {
        name: "Admin Space History",
        exact: true,
        path: `${adminSpacePath}/histories`,
        redirectPath: `${adminSpacePath}/histories`,
        component: LoadableAdminHistories,
    },
];

const projectSettingsRoutes = [
    {
        name: "Admin Project Roles",
        exact: true,
        path: `${adminProjectPath}/roles`,
        redirectPath: `${adminProjectPath}/roles`,
        component: LoadableAdminRoles,
    },
    {
        name: "Admin Project Create Roles",
        exact: true,
        path: `${adminProjectPath}/roles/create`,
        redirectPath: `${adminProjectPath}/roles/create`,
        component: LoadableAdminCreateRoles,
    },
    {
        name: "Admin Project Role",
        exact: true,
        path: `${adminProjectPath}/roles/:id`,
        redirectPath: `${adminProjectPath}/roles`,
        component: LoadableAdminRole,
    },
    {
        name: "Admin Project Type Roles",
        exact: true,
        path: `${adminProjectPath}/:type/roles`,
        redirectPath: `${adminSpacePath}/:type/roles`,
        component: LoadableAdminRoles,
    },
    {
        name: "Admin Project Create Type Roles",
        exact: true,
        path: `${adminProjectPath}/:type/roles/create`,
        redirectPath: `${adminSpacePath}/:type/roles`,
        component: LoadableAdminCreateRoles,
    },
    {
        name: "Admin Project Type Role",
        exact: true,
        path: `${adminProjectPath}/:type/roles/:id`,
        redirectPath: `${adminSpacePath}/:type/roles`,
        component: LoadableAdminRole,
    },
    {
        name: "Admin Project Content Schemas",
        exact: true,
        path: `${adminProjectPath}/content-schemas`,
        redirectPath: `${adminProjectPath}/content-schemas`,
        component: LoadableContentSchemas,
    },
    {
        name: "Admin Project Content Schemas Detail",
        exact: true,
        path: `${adminProjectPath}/content-schemas/:schemaId`,
        redirectPath: `${adminProjectPath}/content-schemas`,
        component: LoadableAdminContentSchemaDetail,
    },
    {
        name: "Admin Project EventTriggers",
        exact: true,
        path: `${adminProjectPath}/event-triggers`,
        redirectPath: `${adminProjectPath}/event-triggers`,
        component: LoadableAdminEventTriggerList,
    },
    {
        name: "Admin Project EventTrigger Create",
        exact: true,
        path: `${adminProjectPath}/event-triggers/create`,
        redirectPath: `${adminProjectPath}/event-triggers/create`,
        component: LoadableAdminEventTriggerCreate,
    },
    {
        name: "Admin Project EventTrigger Detail",
        exact: true,
        path: `${adminProjectPath}/event-triggers/:id`,
        redirectPath: `${adminProjectPath}/event-triggers/:id`,
        component: LoadableAdminEventTriggerDetail,
    },
    {
        name: "Admin Project MetadataTemplate",
        exact: true,
        path: `${adminProjectPath}/metadata-template`,
        redirectPath: `${adminProjectPath}/metadata-template`,
        component: LoadableAdminMetadataTemplateList,
    },
    {
        name: "Admin Project MetadataTemplate Create",
        exact: true,
        path: `${adminProjectPath}/metadata-template/create`,
        redirectPath: `${adminProjectPath}/metadata-template/create`,
        component: LoadableAdminMetadataTemplateCreate,
    },
    {
        name: "Admin Project MetadataTemplate Detail",
        exact: true,
        path: `${adminProjectPath}/metadata-template/:templateId`,
        redirectPath: `${adminProjectPath}/metadata-template/:templateId`,
        component: LoadableAdminMetadataTemplateDetail,
    },
    {
        name: "Admin Project CategoryGroups",
        exact: true,
        path: `${adminProjectPath}/category-groups`,
        redirectPath: `${adminProjectPath}/category-groups`,
        component: LoadableAdminCategoryGroups,
    },
    {
        name: "Admin Project Create Categories",
        exact: true,
        path: `${adminProjectPath}/category-groups/create`,
        redirectPath: `${adminProjectPath}/category-groups`,
        component: LoadableAdminCreateCategoryGroup,
    },
    {
        name: "Admin Project Category Detail",
        exact: true,
        path: `${adminProjectPath}/category-groups/:id`,
        redirectPath: `${adminProjectPath}/category-groups`,
        component: LoadableAdminCategoryGroup,
    },
    {
        name: "Admin Project Settings",
        exact: true,
        path: `${adminProjectPath}/settings`,
        redirectPath: `${adminProjectPath}/settings`,
        component: LoadableAdminSettings,
    },
    {
        name: "Admin Project Authorities",
        exact: true,
        path: `${adminProjectPath}/groups`,
        redirectPath: `${adminProjectPath}/groups`,
        component: LoadableAdminAuthoritiesTeams,
    },
    {
        name: "Admin Project Authorities Detail",
        exact: true,
        path: `${adminProjectPath}/groups/:id`,
        redirectPath: `${adminProjectPath}/groups`,
        component: LoadableAdminAuthorityForTeam,
    },
    {
        name: "Admin Project Authorities",
        exact: true,
        path: `${adminProjectPath}/users`,
        redirectPath: `${adminProjectPath}/users`,
        component: LoadableAdminAuthoritiesUsers,
    },
    {
        name: "Admin Project Authority",
        exact: true,
        path: `${adminProjectPath}/users/:id`,
        redirectPath: `${adminProjectPath}/users`,
        component: LoadableAdminAuthorityForUser,
    },
];

const vocabularyRoutes = [
    {
        name: "Vocabulary list",
        exact: true,
        path: `${defaultPath}/vocabularies`,
        component: LoadableVocabularies,
    },
    {
        name: "Vocabulary list search",
        exact: true,
        path: `${defaultPath}/vocabularies/search`,
        component: LoadableVocabularySearch,
    },
    {
        name: "Vocabulary Detail",
        exact: true,
        path: `${defaultPath}/vocabularies/:vocabularyId`,
        component: LoadableVocabularyDetail,
        connectedMenuId: MENU_LIST.CUSTOM_CONTENTS,
    },
];

const cmsRoutes = [
    {
        name: "CMS",
        exact: true,
        path: `${defaultPath}/cms`,
        component: LoadableAssets,
        redirectPath: `${defaultPath}/cms/assets`,
        connectedMenuId: MENU_LIST.ASSETS,
    },
    {
        name: "Videos",
        exact: true,
        path: `${defaultPath}/cms/videos`,
        component: LoadableVideoList,
        redirectPath: `${defaultPath}/cms/videos`,
        connectedMenuId: MENU_LIST.VIDEOS,
    },
    {
        name: "Video Detail",
        exact: true,
        path: `${defaultPath}/cms/videos/:id`,
        component: LoadableVideoDetail,
        redirectPath: `${defaultPath}/cms/videos`,
        connectedMenuId: MENU_LIST.VIDEOS,
    },
    {
        name: "Assets",
        exact: true,
        path: `${defaultPath}/cms/assets`,
        component: LoadableAssets,
        redirectPath: `${defaultPath}/cms/assets`,
        connectedMenuId: MENU_LIST.ASSETS,
    },
    {
        name: "Asset Create",
        exact: true,
        path: `${defaultPath}/cms/assets/create`,
        component: LoadableAssetCreate,
        redirectPath: `${defaultPath}/cms/assets/create`,
        connectedMenuId: MENU_LIST.ASSETS,
    },
    {
        name: "Asset Detail",
        exact: true,
        path: `${defaultPath}/cms/assets/:id`,
        component: LoadableAssetDetail,
        redirectPath: `${defaultPath}/cms/assets`,
        connectedMenuId: MENU_LIST.ASSETS,
    },
    {
        name: "Caption Asset Preview",
        exact: true,
        isPreview: true,
        path: `${defaultPath}/cms/assets/:groupId/captions`,
        redirectPath: `${defaultPath}/cms/assets/:groupId/captions`,
        component: LoadableCaptionPreview,
        connectedMenuId: MENU_LIST.ASSETS,
    },
    {
        name: "CMS Image Asset Preview",
        exact: true,
        path: `${defaultPath}/cms/assets/:groupId/images`,
        redirectPath: `${defaultPath}/cms/assets/:groupId/images`,
        component: LoadableImagePreview,
        connectedMenuId: MENU_LIST.ASSETS,
    },
    {
        name: "CMS Video Asset Preview",
        exact: true,
        path: `${defaultPath}/cms/assets/:groupId/videos`,
        redirectPath: `${defaultPath}/cms/assets/:groupId/videos`,
        component: LoadableVideoPreview,
        connectedMenuId: MENU_LIST.ASSETS,
    },
    {
        name: "People",
        exact: true,
        path: `${defaultPath}/cms/people`,
        component: LoadablePeople,
        redirectPath: `${defaultPath}/cms/people`,
        connectedMenuId: MENU_LIST.PEOPLE,
    },
    {
        name: "People Create",
        exact: true,
        path: `${defaultPath}/cms/people/create`,
        component: LoadablePeopleCreate,
        redirectPath: `${defaultPath}/cms/people/create`,
        connectedMenuId: MENU_LIST.PEOPLE,
    },
    {
        name: "People Detail",
        exact: true,
        path: `${defaultPath}/cms/people/:id`,
        component: LoadablePeopleDetail,
        redirectPath: `${defaultPath}/cms/people`,
        connectedMenuId: MENU_LIST.PEOPLE,
    },
];

const meetingRoutes = [
    {
        name: "Meeting",
        exact: true,
        path: `${defaultPath}/meetings`,
        component: LoadableMeetingList,
        redirectPath: `${defaultPath}/meetings`,
        connectedMenuId: MENU_LIST.MEETINGS,
    },
    {
        name: "Meeting Create",
        exact: true,
        path: `${defaultPath}/meetings/create`,
        component: LoadableMeetingCreate,
        connectedMenuId: MENU_LIST.MEETINGS,
    },
    {
        name: "Meeting Detail",
        exact: true,
        path: `${defaultPath}/meetings/:id`,
        component: LoadableMeetingDetail,
        connectedMenuId: MENU_LIST.MEETINGS,
    },
    {
        name: "Meeting Create Old",
        exact: true,
        path: `${defaultPath}/meetings_old/create`,
        component: LoadableMeetingCreate_Old,
        connectedMenuId: MENU_LIST.MEETINGS,
    },
    {
        name: "Meeting Detail Old",
        exact: true,
        path: `${defaultPath}/meetings_old/:id`,
        component: LoadableMeetingDetail_Old,
        connectedMenuId: MENU_LIST.MEETINGS,
    },
    {
        name: "Meeting Old",
        exact: true,
        path: `${defaultPath}/meetings_old`,
        component: LoadableMeetingList_Old,
        redirectPath: `${defaultPath}/meetings_old`,
        connectedMenuId: MENU_LIST.MEETINGS,
    },
];

const routes = [
    {
        name: "Entry Console",
        exact: true,
        path: "/",
        component: LoadableEntryConsole,
    },
    {
        name: "Spaces",
        exact: true,
        path: "/spaces",
        component: LoadableStage,
    },
    {
        name: "Dashboard",
        exact: true,
        path: `${defaultPath}/dashboard`,
        component: LoadableMain,
        connectedMenuId: MENU_LIST.DASHBOARD,
    },
].concat(
    assetRoutes,
    storageRoutes,
    sharedRoutes,
    contentsRoutes,
    adminSettingsRoutes,
    projectSettingsRoutes,
    liveRoutes,
    interactiveRoutes,
    meetingRoutes,
    jobRoutes,
    searchRoutes,
    vocabularyRoutes,
    cmsRoutes,
    etcRoutes,
);

export default routes;
