import Logo from "./Logo";
import menuService from "@services/menuService";
import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import equal from "deep-equal";
import theme from "@styles/theme";
import { AppBar, Grid, Toolbar } from "@mzc-pdc/ui";
import ProfileMenu from "../../features/profile-menu/profile-menu";

const HeaderForNoting = () => {
    const { pathname, search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const { spaceId, projectId } = menuService.parse(pathname);

    const user = useSelector(({ user }) => user);

    const [headerTheme, setHeaderTheme] = useState(null);
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    useEffect(() => {
        const headerThemeJson = JSON.parse(localStorage.getItem("headerTheme"));
        if (!equal(headerTheme, headerThemeJson?.data)) {
            // const session = sessionKeeper.get();
            const currentStageId = spaceId;
            if (headerThemeJson.stageId === currentStageId) {
                setHeaderTheme(headerThemeJson.data);
            }
        }
    }, [user?.stages?.data]);

    const onClickToggleMobileMenu = () => {
        setShowMobileMenu(!showMobileMenu);

        document.documentElement.classList.toggle("utils-menu-open");
    };

    const onClickOutsideMobileMenu = () => {
        setShowMobileMenu(false);

        if (document.documentElement.classList.contains("utils-menu-open"))
            document.documentElement.classList.remove("utils-menu-open");
    };

    const isPopup = useMemo(() => {
        return Boolean(searchParams.get("popup"));
    }, [search]);

    return (
        <>
            {user.stages?.data && !isPopup && (
                <AppBar
                    position="fixed"
                    sx={{
                        backgroundColor: headerTheme ? headerTheme.color?.bgColor : theme.palette.background.default,
                        color: headerTheme ? headerTheme.color?.textColor : theme.palette.text.primary,
                        fill: headerTheme ? headerTheme.color?.textColor : theme.palette.text.primary,
                        boxShadow: `none`,
                        borderBottom: `1px solid ${theme.palette.secondary[100]}`,
                        zIndex: (props) => props.zIndex.drawer + 1,
                    }}
                >
                    <Toolbar sx={{ padding: `0 !important` }}>
                        <Grid container justifyContent={`space-between`} alignItems={`center`}>
                            <Grid item pl={2.5}>
                                <Logo to={"/spaces"} imgSrc={headerTheme?.ci} fill={headerTheme?.color?.textColor} />
                            </Grid>
                            <Grid item pr={1}>
                                <ProfileMenu headerTheme={headerTheme} />
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            )}
        </>
    );
};

export default HeaderForNoting;
