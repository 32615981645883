import React from "react";
import ReactDOM from "react-dom";
import {
    Badge,
    Button,
    Chip,
    Grid,
    Typography,
    Box,
    Divider,
    Popover,
    Popper,
    Paper,
    ClickAwayListener,
} from "@mzc-pdc/ui";
import { useTranslation } from "react-i18next";
import { IconArrowDropDown, IconArrowDropUp } from "@mzc-cloudplex/icons";
import theme from "@styles/theme";
import FilterChipExpandPopover from "./filter-chip-expand-popover";

export const FilterChipExpand = ({ isDisabled, component, onCancel, onSave, item, onDelete, filterDisabled }) => {
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);
    const id = open ? `filter-popper-${item.id}` : undefined;

    const handleClick = (event) => {
        event.stopPropagation();
        if (!anchorEl) setAnchorEl(event.currentTarget);
        else setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onHandleSave = (e) => {
        e.stopPropagation();
        onSave && onSave();
        handleClose();
    };

    const handleDelete = (event) => {
        event.stopPropagation();
        onDelete();
    };

    return (
        <Grid>
            {!item.value ? (
                <Chip
                    label={
                        <Grid alignItems="center" container gap={1}>
                            <Typography variant="body3_400">{t(`common::label::${item.title}`)}</Typography>
                            <Box>
                                {anchorEl ? (
                                    <IconArrowDropUp size={20} color={theme.palette.secondary[400]} />
                                ) : (
                                    <IconArrowDropDown size={20} color={theme.palette.secondary[400]} />
                                )}
                            </Box>
                        </Grid>
                    }
                    aria-describedby={id}
                    onClick={handleClick}
                    size="medium"
                    variant="filter"
                    rounded
                    disabled={filterDisabled}
                />
            ) : (
                <Chip
                    active
                    label={
                        <Grid alignItems="center" container gap={1}>
                            <Typography color="primary" variant="body3_400">
                                {t(`common::label::${item.title}`)}
                            </Typography>
                            <Typography>{item.label}</Typography>
                            {item.length > 0 && (
                                <Badge
                                    sx={{
                                        ".MuiBadge-badge ": {
                                            position: `static`,
                                            transform: `none`,
                                            marginLeft: `8px`,
                                            fontSize: `11px`,
                                            fontWeight: `bold`,
                                            lineHeight: `1.45`,
                                        },
                                    }}
                                    badgeContent={<>+{item.length}</>}
                                    color="primary"
                                />
                            )}
                            <Box>
                                {anchorEl ? (
                                    <IconArrowDropUp size={20} color={theme.palette.secondary[400]} />
                                ) : (
                                    <IconArrowDropDown size={20} color={theme.palette.secondary[400]} />
                                )}
                            </Box>
                        </Grid>
                    }
                    aria-describedby={id}
                    onClick={handleClick}
                    size="medium"
                    variant="filter"
                    rounded
                    disabled={filterDisabled}
                />
            )}
            {/*<ClickAwayListener onClickAway={handleClose}>*/}
            <Popper
                disablePortal={true}
                key={id}
                id={id}
                open={open}
                placement={"bottom-start"}
                // anchorOrigin={{
                //     vertical: "top",
                //     horizontal: "left",
                // }}
                // PaperProps={{
                //     style: {
                //         padding: "16px",
                //         marginTop: `5px`,
                //     },
                // }}
                anchorEl={anchorEl}
                onClose={(event, reason) => {
                    handleClose();
                }}
                sx={{ zIndex: 1300, ".MuiPopover-paper": { top: "0px", left: "0px" } }}
            >
                <Paper sx={{ padding: "16px", marginTop: `5px` }}>
                    <Box width={328}>
                        {component}
                        <Divider flexItem />
                        <Box mt={1} sx={{ textAlign: `right` }}>
                            <Button
                                type={"button"}
                                variant={"text"}
                                size={"small"}
                                color={"inherit"}
                                minWidth={1}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onCancel && onCancel();
                                    handleClose();
                                }}
                            >
                                {t("common::label::Cancel")}
                            </Button>
                            <Button
                                type={"button"}
                                variant={"contained"}
                                size={"small"}
                                color={"primary"}
                                minWidth={1}
                                disabled={isDisabled}
                                sx={{ marginLeft: `4px` }}
                                onClick={onHandleSave}
                            >
                                {t("common::label::Apply")}
                            </Button>
                        </Box>
                    </Box>
                </Paper>
            </Popper>
            {/*</ClickAwayListener>*/}
        </Grid>
    );
};

export default FilterChipExpand;
