import React from "react";

const Loading = () => {
    return (
        <div className={"loading"}>
            <span className={"loading-content"}></span>
        </div>
    );
};

export default Loading;
