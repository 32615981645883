import { styled, alpha } from "@mui/system";
import { Menu as MuiMenu, MenuItem as MuiMenuItem } from "@mui/material";

export const ButtonWrap = styled("div")(({}) => ({
    display: `inline-flex`,
    alignItems: `center`,
}));
//
// export const Button = styled(CloudPlexButton)(({theme}) => ({
//    '&.MuiButton-root': {
//        alignItems: `center`,
//        fontSize: `14px`,
//        fontWeight: `500`,
//        '&:hover, &:active': {
//            backgroundColor: `transparent`,
//            '.MuiButton-endIcon': {
//                '.MuiBox-root': {
//                    backgroundColor: alpha(theme.palette.secondary[800], 0.08)
//                }
//            }
//        },
//        '.MuiButton-endIcon': {
//            transition: `transform .2s linear`,
//            '.MuiBox-root': {
//                display: `inline-flex`,
//                alignItems: `center`,
//                justifyContent: `center`,
//                width: `24px`,
//                height: `24px`,
//                borderRadius: `24px`
//            }
//        },
//        '&[aria-expanded="true"] ': {
//            backgroundColor: `transparent`,
//             '.MuiButton-endIcon': {
//                 transform: `rotate(-180deg)`,
//             '.MuiBox-root': {
//                     backgroundColor: alpha(theme.palette.secondary[800], 0.08)
//                 }
//             }
//        }
//    }
// }))

export const Menu = styled(MuiMenu)(({ theme }) => ({
    borderRadius: `2px`,
    border: `1px solid ${theme.palette.grey[100]}`,
}));

export const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
    minWidth: `120px`,
    fontSize: `14px`,
    height: `32px`,
    fontWeight: `500`,
    "&:hover": {
        backgroundColor: alpha(theme.palette.greySecondary[50], 0.5),
    },
    "&.active": {
        textDecoration: `none`,
        color: theme.palette.primary[400],
        backgroundColor: alpha(theme.palette.greySecondary.A100, 0.4),
    },
}));
