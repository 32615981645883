import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import equal from "deep-equal";
import Loadable from "@react-loadable/revised";
import { getStageSettings } from "@modules/settings";
import { setSiteMode } from "@modules/siteMode";
import { sessionKeeper } from "@cores/sessionKeeper";
import config from "@config";
import { cancelTranscodeAllSync } from "@modules/upload";
import * as jobStatus from "@cores/jobStatus";
import { isLocal } from "@cores/enviromentExtension";
import { siteMode } from "@cores/siteMode";
import SelectStage from "@components/navigator/components/SelectStage";
import Logo from "./Logo";
import SwitchButton from "./SwitchButton";
import menuService from "@services/menuService";
import { AppBar, Divider, Grid, IconButton, Toolbar } from "@mzc-pdc/ui";
import theme from "@styles/theme";
import StartMenu from "../../features/start-menu/start-menu";
import ProfileMenu from "../../features/profile-menu/profile-menu";
import { alpha } from "@mui/material";
import { SIDEBAR_BUTTON_ID } from "@constants";
import loadingLottie from "@assets/lottie/my-jobs-loading.json";
import useMyJobs from "@hooks/useMyJobs";
import { ImageMyjobsOff, ImageMyjobsOn } from "@mzc-cloudplex/icons";
import Lottie from "lottie-react";
import compareVersions, { VERSION_CM_12123_METADATA_EXPORT } from "@cores/version";

const LoadableModelForAppDown = Loadable({
    loader: () => import("@components/modals/AppDownload"),
    loading: () => null,
});

const HeaderForAdmin = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslation();

    const { openMyJobs, isJobExisting, isJobProcessing } = useMyJobs({});

    const { spaceId, projectId } = menuService.parse(location.pathname);
    const settings = useSelector(({ settings }) => settings);
    const upload = useSelector(({ upload }) => upload);
    const stage = useSelector(({ stage }) => stage);

    const [activeAppDownModal, setActiveAppDownModal] = useState(false);
    const [headerTheme, setHeaderTheme] = useState(null);

    useEffect(() => {
        const theme = localStorage.getItem("headerTheme");
        if (theme) {
            const headerThemeJson = JSON.parse(theme);
            if (!equal(headerTheme, headerThemeJson?.data)) {
                const currentStageId = stage && stage.id;
                if (headerThemeJson.stageId === currentStageId) setHeaderTheme(headerThemeJson.data);
            }
        }

        window.addEventListener("beforeunload", handleBeforeunload);
        window.addEventListener("unload", handleUnload);

        return () => {
            window.removeEventListener("unload", handleUnload);
            window.removeEventListener("beforeunload", handleBeforeunload);
        };
    }, []);

    useEffect(async () => {
        if (settings.updatedDomain === "General") {
            await dispatch(
                getStageSettings({
                    stageId: stage.id,
                    apiEndpoint: stage.endpoint,
                    params: {
                        _: new Date().getTime(), // IE 자동 캐시 방지
                    },
                }),
            );
        }
    }, [settings]);

    useEffect(async () => {
        if (settings.updatedDomain === "General") {
            await dispatch(
                getStageSettings({
                    stageId: stage.id,
                    apiEndpoint: stage.endpoint,
                    params: {
                        _: new Date().getTime(), // IE 자동 캐시 방지
                    },
                }),
            );
        }
        if (settings.stage) {
            const headerTheme = settings.stage.General.headerTheme;
            if (headerTheme && headerTheme.value) {
                const headerThemeJSON = JSON.parse(settings.stage.General.headerTheme.value);
                if (!equal(headerThemeJSON, headerTheme)) {
                    localStorage.setItem(
                        "headerTheme",
                        JSON.stringify({
                            stageId: stage.id,
                            data: headerThemeJSON,
                        }),
                    );
                    setHeaderTheme(headerThemeJSON);
                }
            }
        }
    }, [settings]);

    const handleUnload = () => {
        const cancelTargetJobs = upload.jobs.data.filter(
            (v) => jobStatus.getValuesLowerThan(jobStatus.values.SUBMITTED).indexOf(v.status) > -1,
        );
        if (cancelTargetJobs.length > 0) {
            dispatch(cancelTranscodeAllSync(cancelTargetJobs));
        }
    };

    const handleBeforeunload = (e) => {
        const cancelTargetJobs = upload.jobs.data.filter(
            (v) => jobStatus.getValuesLowerThan(jobStatus.values.SUBMITTED).indexOf(v.status) > -1,
        );

        if (cancelTargetJobs.length > 0) {
            const message = t(`common::msg::${"There is work in progress. Would you really go out?"}`);
            (e || window.event).returnValue = message;

            return message;
        }
    };

    const closeAppDownModal = () => {
        setActiveAppDownModal(false);
    };

    const onCmsClick = () => {
        siteMode.set("CMS");
        dispatch(
            setSiteMode({
                mode: "CMS",
            }),
        );
        if (isLocal) {
            history.replace("/");
        } else {
            window.location.href = config.host.cms;
        }
    };

    return (
        <AppBar
            position="fixed"
            sx={{
                backgroundColor: headerTheme ? headerTheme.color?.bgColor : theme.palette.background.default,
                color: headerTheme ? headerTheme.color?.textColor : theme.palette.text.primary,
                fill: headerTheme ? headerTheme.color?.textColor : theme.palette.text.primary,
                boxShadow: `none`,
                borderBottom: `1px solid ${theme.palette.secondary[100]}`,
                zIndex: (props) => props.zIndex.drawer + 1,
            }}
        >
            <Toolbar sx={{ padding: `0 !important` }}>
                <Grid container justifyContent={`space-between`} alignItems={`center`}>
                    <Grid item>
                        <Grid container alignItems={"center"} height={theme.mixins.toolbar.minHeight}>
                            <Grid item px={1.5}>
                                <StartMenu headerTheme={headerTheme} />
                            </Grid>
                            <Divider
                                flexItem
                                orientation={"vertical"}
                                sx={{
                                    marginLeft: `-1px`,
                                    background: `none`,
                                    borderColor: alpha(theme.palette.secondary[500], 0.2),
                                }}
                            />
                            <Grid item pl={2.5}>
                                <Grid container alignItems={"center"}>
                                    <Logo
                                        to={
                                            location.pathname.indexOf(`/admin/spaces/${spaceId}`) !== -1 && !projectId
                                                ? `/admin/spaces/${spaceId}/users`
                                                : "/"
                                        }
                                        imgSrc={headerTheme?.ci}
                                        fill={headerTheme?.color?.textColor}
                                    />

                                    {siteMode.isCmsSupported() && (
                                        <>
                                            <Divider
                                                flexItem
                                                orientation={"vertical"}
                                                sx={{
                                                    margin: `4px 20px`,
                                                    background: `none`,
                                                    borderColor: alpha(theme.palette.secondary[500], 0.2),
                                                }}
                                            />
                                            <SwitchButton
                                                onClick={onCmsClick}
                                                headerTheme={headerTheme}
                                                label={t("common::label::CMS")}
                                            />
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container gap={1} pr={1} alignItems={"center"} height={theme.mixins.toolbar.minHeight}>
                            <Grid item>
                                <SelectStage />
                            </Grid>
                            <Divider
                                flexItem
                                orientation={"vertical"}
                                sx={{
                                    my: "18px",
                                    background: `none`,
                                    borderColor: alpha(theme.palette.secondary[500], 0.2),
                                }}
                            />
                            {!projectId && compareVersions(VERSION_CM_12123_METADATA_EXPORT, stage.version) <= 0 && (
                                <Grid item>
                                    <IconButton
                                        size={"large"}
                                        circled
                                        sx={{ p: 1, "& div svg": { marginBottom: "8px" } }}
                                        id={SIDEBAR_BUTTON_ID}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            openMyJobs();
                                        }}
                                    >
                                        {isJobExisting && isJobProcessing && (
                                            <Lottie
                                                animationData={loadingLottie}
                                                style={{
                                                    height: "24px",
                                                    minWidth: "24px",
                                                    margin: "auto",
                                                }}
                                            />
                                        )}
                                        {isJobExisting && !isJobProcessing && <ImageMyjobsOn width={24} height={24} />}
                                        {!isJobExisting && <ImageMyjobsOff width={24} height={24} />}
                                    </IconButton>
                                </Grid>
                            )}
                            <Grid item>
                                <ProfileMenu headerTheme={headerTheme} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>

            {activeAppDownModal &&
                ReactDOM.createPortal(<LoadableModelForAppDown onClose={closeAppDownModal} />, document.body)}
        </AppBar>
    );
};

export default HeaderForAdmin;
