"use strict";

import { handleActions, createAction } from "redux-actions";
import moment from "moment";
import { axios } from "../cores/axiosWrapper";

const getScheduleHistoriesAPI = (stageId, apiEndpoint, projectId, params) => {
  return axios.get(`${apiEndpoint}/schedules/histories`, {
    headers: { stageId, projectId },
    params,
  });
}

const GET_HISTORIES_SUCCESS = 'GET_HISTORIES_SUCCESS';
const HISTORIES_FAILURE = 'HISTORIES_FAILURE';
const HISTORIES_PENDING = 'HISTORIES_PENDING';
const UPDATE_STATE = 'UPDATE_STATE';

const initialState = {
  pending: false,
  error: false,
  totalCount: 0,
  histories: [],
  externalId: null,
  startAt: null,
  endAt: null,
  startDuration: null,
  endDuration: null,
  offset: 0,
  limit: 20,
};

export const updateState = createAction(UPDATE_STATE);

export default handleActions({
  [GET_HISTORIES_SUCCESS]: (state, action) => {
    const { data } = action.payload;
    
    return {
      ...state,
      pending: false,
      error: false,
      histories: data.rows.map((o) => {
        o.startAt = moment(o.startAt);
        o.endAt = moment(o.endAt);
        o.diff = moment.utc(o.startAt.diff(o.endAt));
        return o;
      }),
      totalCount: data.count,
    }
  },
  [HISTORIES_FAILURE]: (state) => {
    return {
      ...state,
      pending: false,
      error: true
    };
  },
  [UPDATE_STATE]: (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  },
  [HISTORIES_PENDING]: (state, action) => {
    return {
      ...state,
      pending: true,
    }
  }
}, initialState);

const convertHHmmStrToMinutes = (str) => {
  const arr = str.split(":").map((o) => Number(o));

  return arr[0] * 60 + arr[1];
}

const translateSearchDate = (date, formStr) => {
  if (date)
    return date.format(formStr);
  else 
    return null;
}

export const getScheduleHistories = () => (dispatch, getState) => {
  const { stage, project, scheduleHistories } = getState();
  const { externalId, startAt, endAt, startDuration, endDuration, offset, limit } = scheduleHistories;

  const params = { externalId, offset, limit };

  if (startAt) params.startAt = translateSearchDate(startAt, "YYYY-MM-DD 00:00:00");
  if (endAt) params.endAt = translateSearchDate(endAt, "YYYY-MM-DD 23:59:59");
  if (startDuration) params.startDuration = convertHHmmStrToMinutes(startDuration);
  if (endDuration) params.endDuration = convertHHmmStrToMinutes(endDuration);

  dispatch({ type: HISTORIES_PENDING });

  return getScheduleHistoriesAPI(stage.id, stage.endpoint, project.id, params).then(
    (response) => {
      dispatch({
        type: GET_HISTORIES_SUCCESS,
        payload: response,
      });
    }
  ).catch((error) => {
    dispatch({
      type: HISTORIES_FAILURE,
      payload: error,
    });
  })
}
