
function parseJwt (token) {
    try {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = Buffer.from(base64, 'base64').toString();
        return JSON.parse(jsonPayload);
    }
    catch(error) {
        // ignored
        console.error(error);
    }
    return null;
};

export default parseJwt;