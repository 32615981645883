import { LIMIT_BYTE, METADATA_FIELDS_TYPE } from "@constants";
import TextField from "@components_v2/text-field/text-field";
import { Box, Chip, Grid, MenuItem, Select, theme } from "@mzc-pdc/ui";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import TagsInput from "@features/tags-input/tags-input";
import AttributionInputArea from "@components/inputArea/AttributionInputArea";
import Categories from "@routes/categories";
import { BasicDatePicker } from "@features/datepicker/datepicker";
import NumberTextField from "@components_v2/number-text-field/number-text-field";
import getByteLength from "@cores/getByteLength";
import { IconCancelCircle } from "@mzc-cloudplex/icons";

const MetadataFieldEditMode = ({
    id,
    type,
    value,
    placeholder,
    options,
    validationRule,
    isEdit,
    onChangeField,
    disabled,
}) => {
    const { t } = useTranslation();
    const [fieldValidationError, setFieldValidationError] = useState({});
    const [categoryGroupTooltipDisabled, setCategoryGroupTooltipDisabled] = useState(true);
    const [date, setDate] = useState(
        [METADATA_FIELDS_TYPE.RANGE_DATE_PICKER].includes(type) ? value?.split("~") : null,
    );
    const [selectOptions, setSelectOptions] = useState(options);

    const onHandleChangeField = (value, validationError) => {
        Object.keys(validationError ?? {}).forEach((k) => validationError[k] == null && delete validationError[k]);

        onChangeField(id, value, Object.keys(validationError ?? {}).length > 0 ? validationError : null);
    };

    const changeValidationErrors = (key, error) => {
        if (error === null) {
            setFieldValidationError((errors) => {
                const { [key]: omit, ...rest } = errors;
                return { ...rest };
            });
        } else
            setFieldValidationError((errors) => ({
                ...errors,
                [key]: error,
            }));
    };

    const onChangeAttributionKey = (id, key, validationError) => {
        const newAttributions = value.map((attr) => ({ ...attr, key: attr.id === id ? key : attr.key }));
        changeValidationErrors(`attribution-${id}-key`, validationError);
        onHandleChangeField(newAttributions, { ...fieldValidationError, [`attribution-${id}-key`]: validationError });
    };

    const onChangeAttributionValue = (id, e, validationError) => {
        const newAttributions = value.map((attr) => ({ ...attr, value: attr.id === id ? e : attr.value }));

        changeValidationErrors(`attribution-${id}-value`, validationError);
        onHandleChangeField(newAttributions, { ...fieldValidationError, [`attribution-${id}-value`]: validationError });
    };

    const onClickAddAttribution = (id, validationError) => {
        const addedAttributions = value.concat({
            id: id,
            key: "",
            value: "",
        });

        changeValidationErrors(`attribution-${id}-key`, validationError);
        changeValidationErrors(`attribution-${id}-value`, validationError);
        onHandleChangeField(addedAttributions, {
            ...fieldValidationError,
            [`attribution-${id}-key`]: validationError,
            [`attribution-${id}-value`]: validationError,
        });
    };

    const onClickRemoveAttribution = (id) => {
        const newValidationErrors = { ...fieldValidationError };
        delete newValidationErrors[`attribution-${id}-key`];
        delete newValidationErrors[`attribution-${id}-value`];

        changeValidationErrors(`attribution-${id}-key`, null);
        changeValidationErrors(`attribution-${id}-value`, null);
        onHandleChangeField(
            value.filter((attr) => attr.id !== id),
            newValidationErrors,
        );
    };

    /* useCategoryGroup true인 경우 options에 categoryGroup 내용이 전달되며, false인 경우 두번째 파라미터로 전달됩니다.*/
    const onHandleChangeCategories = (options, categoryGroup) => {
        let categoryGroupTooltip = categoryGroupTooltipDisabled;

        if (value.categoryGroup?.id !== options.categoryGroup?.id) {
            categoryGroupTooltip = false;
        }

        if (options.categoryGroup) {
            setCategoryGroupTooltipDisabled(categoryGroupTooltip);
            onHandleChangeField({
                ...value,
                categoryGroup: { id: options.categoryGroup.id, name: options.categoryGroup.label },
                categories: options.categories,
            });
        } else {
            onHandleChangeField({
                ...value,
                categories: options,
                categoryGroup: categoryGroup ? { id: categoryGroup.id } : undefined,
            });
        }
    };

    const onHandleChangeFieldValue = async (val) => {
        try {
            if (validationRule) await validationRule.validate({ fieldValue: val }, { abortEarly: false });
            onHandleChangeField(val, null);
            setFieldValidationError(null);
        } catch (e) {
            console.log(e);
            const error = e.errors.map((e) => ({ level: "error", message: e }));
            onHandleChangeField(val, error);
            setFieldValidationError(error);
        }
    };

    const onHandleChangeEnumFieldValue = async (val) => {
        onHandleChangeField(val);
    };

    const categoryGroup = useMemo(() => {
        if (type !== METADATA_FIELDS_TYPE.CATEGORIES || !value?.categoryGroup) return null;
        //TODO: categoryGroup 정보 GET 해오기
        const result = { id: value.categoryGroup?.id, label: value.categoryGroup?.name };
        return result;
    }, [value?.categoryGroup]);

    const categories = useMemo(() => {
        if (type !== METADATA_FIELDS_TYPE.CATEGORIES || !value?.categories) return null;
        //TODO: category ids를 가지고 category 정보 GET 해오기
        const result =
            value.categories?.map((category) => ({
                name: category.name,
                label: category.name,
                value: category.id,
                id: category.id,
            })) || [];
        return result;
    }, [value?.categories]);

    return (
        <>
            {type === METADATA_FIELDS_TYPE.TAGS && (
                <TagsInput
                    tags={value?.map((tag) => {
                        return {
                            value: tag,
                        };
                    })}
                    isEdit={isEdit}
                    disabled={disabled}
                    onDeleteTag={(index) => {
                        onHandleChangeField(value.filter((t, idx) => idx !== index));
                    }}
                    onAddTag={(tag) => {
                        const newTag = tag.value;
                        onHandleChangeField([...value, newTag]);
                    }}
                />
            )}
            {type === METADATA_FIELDS_TYPE.ATTRIBUTIONS && (
                <Grid
                    item
                    sx={{
                        mt: 1,
                        border: isEdit ? `1px solid ${theme.palette.primary[400]}` : undefined,
                        borderRadius: "2px",
                        padding: isEdit ? "15px 20px" : undefined,
                    }}
                >
                    <AttributionInputArea
                        values={value} //here
                        onChangeKey={(id, value, validationError) => {
                            onChangeAttributionKey(id, value, validationError);
                        }}
                        onChangeValue={(id, e, validationError) => {
                            onChangeAttributionValue(id, e, validationError);
                        }}
                        onClickAddAttribution={(id, validationError) => {
                            onClickAddAttribution(id, validationError);
                        }}
                        onClickRemoveAttribution={(id) => {
                            onClickRemoveAttribution(id);
                        }}
                    />
                </Grid>
            )}
            {type === METADATA_FIELDS_TYPE.CATEGORIES && (
                <Categories
                    isEdit={isEdit}
                    id={value?.schemaId}
                    tooltipDisabled={false}
                    categoryGroups={value?.useCategoryGroup}
                    selectedCategoryGroup={{ id: value?.categoryGroup?.id, label: value?.categoryGroup?.name }}
                    placeholder={t(`common::label::${"Select category"}`)}
                    type={value?.categoryType}
                    selectedValues={categories}
                    onChange={(options, categoryGroup) => {
                        onHandleChangeCategories(options, categoryGroup);
                    }}
                />
            )}
            {[METADATA_FIELDS_TYPE.TEXT_FIELD, METADATA_FIELDS_TYPE.TEXT_AREA].includes(type) && (
                <TextField
                    isEdit={isEdit}
                    fullWidth
                    type={"text"}
                    disabled={disabled}
                    multiline={type === METADATA_FIELDS_TYPE.TEXT_AREA}
                    maxByte={type === METADATA_FIELDS_TYPE.TEXT_AREA ? LIMIT_BYTE.description : null}
                    bytes={type === METADATA_FIELDS_TYPE.TEXT_AREA ? getByteLength(value) : null}
                    defaultValue={value ?? ""} //Note: defaultValue 사용이유: value사용시 한글 입력하면 단어들이 분리되서 들어감
                    onChange={(e) => {
                        onHandleChangeFieldValue(e.target.value);
                    }}
                    placeholder={placeholder ?? t(`common::label::Enter text`)}
                    error={fieldValidationError?.[0]?.level}
                    helperText={
                        fieldValidationError
                            ? t(`common::msg::${fieldValidationError[0]?.message}`, fieldValidationError[0]?.message)
                            : ""
                    }
                />
            )}
            {[METADATA_FIELDS_TYPE.NUMBER].includes(type) && (
                <NumberTextField
                    isEdit={isEdit}
                    fullWidth
                    type={"number"}
                    disabled={disabled}
                    value={value} //Note: value 사용 이유: defaultValue 사용시 input에 한글이나 일본어가 입력이 됨(값에 들어가진 않음)
                    onChange={onHandleChangeFieldValue}
                    placeholder={t(`common::label::Enter number`)}
                    error={fieldValidationError?.[0]?.level}
                    helperText={
                        fieldValidationError
                            ? t(`common::msg::${fieldValidationError[0]?.message}`, fieldValidationError[0]?.message)
                            : ""
                    }
                />
            )}
            {type === METADATA_FIELDS_TYPE.SINGLE_SELECT && (
                <Grid container flexDirection={"row"} gap={0.5} sx={{ width: "100%" }}>
                    <Select
                        value={value}
                        disabled={disabled}
                        placeholder={placeholder}
                        sx={{
                            width: "100%",
                            ...(isEdit && { "> fieldset": { borderColor: theme.palette.primary[400] } }),
                        }}
                        onChange={(e) => onHandleChangeEnumFieldValue(e.target.value)}
                    >
                        {selectOptions?.map((v, index) => {
                            return (
                                <MenuItem value={v.value} key={v.id}>
                                    {v.value}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Grid>
            )}
            {type === METADATA_FIELDS_TYPE.MULTI_SELECT && (
                <Grid container flexDirection={"row"} gap={0.5} sx={{ width: "100%" }}>
                    <Select
                        multiple
                        value={value ?? []}
                        disabled={disabled}
                        sx={{
                            width: "100%",
                            ...(isEdit && { "> fieldset": { borderColor: theme.palette.primary[400] } }),
                        }}
                        onChange={(e) => onHandleChangeEnumFieldValue(e.target.value)}
                        placeholder={placeholder}
                        renderValue={(selected) => {
                            return (
                                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                    {selected.map((value, index) => (
                                        <Chip
                                            size={`small`}
                                            label={value}
                                            avatar={<></>}
                                            onClick={(e) => console.log("clicked")}
                                            onDelete={() => {
                                                onHandleChangeEnumFieldValue(
                                                    selected.filter((v, idx) => idx !== index),
                                                );
                                            }}
                                            deleteIcon={
                                                <IconCancelCircle
                                                    size={12}
                                                    onMouseDown={(e) => e.stopPropagation()}
                                                    onClick={(e) => console.log("clicked")}
                                                />
                                            }
                                            sx={{
                                                marginRight: "4px",
                                            }}
                                        />
                                    ))}
                                </Box>
                            );
                        }}
                    >
                        {selectOptions?.map((v, index) => {
                            return (
                                <MenuItem value={v.value} key={v.id}>
                                    {v.value}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Grid>
            )}
            {type === METADATA_FIELDS_TYPE.SINGLE_DATE_PICKER && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <BasicDatePicker
                        format="YYYY-MM-DD"
                        label="Date"
                        sx={{
                            width: "100%",
                            ...(isEdit && {
                                "& .MuiOutlinedInput-root": {
                                    "> fieldset": { borderColor: theme.palette.primary[400] },
                                },
                            }),
                        }}
                        defaultValue={value}
                        onChangeValue={(newDate) => {
                            onHandleChangeFieldValue(newDate ? dayjs(newDate).format("YYYY-MM-DD") : null);
                        }}
                        disabled={disabled}
                    />
                </LocalizationProvider>
            )}
            {type === METADATA_FIELDS_TYPE.RANGE_DATE_PICKER && (
                <Grid
                    item
                    sx={{
                        mt: 1,
                        border: isEdit ? `1px solid ${theme.palette.primary[400]}` : undefined,
                        borderRadius: "2px",
                        padding: isEdit ? "15px 20px" : undefined,
                    }}
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Grid container justifyContent={"space-between"} flexDirection={"row"}>
                            <BasicDatePicker
                                sx={{ width: "49%" }}
                                label={"After Date"}
                                defaultValue={date?.[0]}
                                maxDate={date?.[1]}
                                onChangeValue={(newDate) => {
                                    const startDate = newDate ? dayjs(newDate).format("YYYY-MM-DD") : null;
                                    const rangeDate = startDate || date?.[1] ? `${startDate}~${date?.[1] ?? ""}` : null;
                                    setDate([startDate, date?.[1] ?? null]);
                                    onHandleChangeFieldValue(rangeDate);
                                }}
                                disabled={disabled}
                            />
                            <BasicDatePicker
                                sx={{ width: "49%" }}
                                label={"Before Date"}
                                defaultValue={date?.[1]}
                                minDate={date?.[0]}
                                onChangeValue={(newDate) => {
                                    const endDate = newDate ? dayjs(newDate).format("YYYY-MM-DD") : null;
                                    const rangeDate = endDate || date?.[0] ? `${date?.[0] ?? ""}~${endDate}` : null;
                                    setDate([date?.[0] ?? null, endDate]);
                                    onHandleChangeFieldValue(rangeDate);
                                }}
                                disabled={disabled}
                            />
                        </Grid>
                    </LocalizationProvider>
                </Grid>
            )}
        </>
    );
};

export default MetadataFieldEditMode;
