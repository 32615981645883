import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { PUBLIC_URL, PROPERTY_PANEL_TYPE, MEDIA_TYPES, POPUP_MODAL_SIZE } from "@constants";
import compareVersions, { VERSION_CM_4721_SHARES } from "@cores/version";
import PanelHeader from "@components/property-panel/ui/PanelHeader";
import PanelHeaderTool from "@components/property-panel/ui/PanelHeaderTool";
import PanelHeaderTitle from "@components/property-panel/ui/PanelHeaderTitle";
import PanelPermissionError from "@components/property-panel/ui/PanelPermissionError";
import Loading from "@components/loader/Loading";
import PanelBody from "@components/property-panel/ui/PanelBody";
import PanelBodyMedia from "@components/property-panel/ui/PanelBodyMedia";
import PanelBodyInformation from "@components/property-panel/ui/PanelBodyInformation";
import ContentsView from "@components/property-panel/ui/ContentsView";
import FormatBadge from "../../formatbadge/FormatBadge";
import { DomainOverview } from "../../view/domainOverview";
import MetadataView from "../../view/MetadataView";
import AudioView from "../../view/AudioView";
import TabArrowButton from "./tab-arrow-button-panel";
import RelatedAssetTab from "./related-asset-tab";
import RelatedVideosTab from "./related-videos-tab";
import SharedWithTab from "./shared-with-tab";
import ScrollArea from "@components/ScrollArea";
import SpriteIcon from "@components/icons/SpriteIcon";
import { IconButton } from "@mzc-pdc/ui";
import { getDetailSharedResource } from "@modules/sharedBox";
import { presignObject, updateState } from "@modules/propertyPanel";
import menuService from "@services/menuService";

let TAB_TYPE = {
    OVERVIEW: "Overview",
    METADATA: "Metadata",
    RELATED_ASSETS: "Related Assets",
    AUDIO: "Multi Audio",
    RELATED_VIDEOS: "Related Videos",
    SHARED_WITH: "Shared with",
};

const CustomPanel = ({
    title,
    convertToCategoryType,
    handleShareRequestModal,
    onClickClose,
    activeTab,
    onClickTab,
    panelTabArrow,
    getHiddenFieldValue,
    relatedAssets,
    relatedVideos,
    parentPath,
    contentsViewData,
}) => {
    const { t } = useTranslation();
    const panelTabs = useRef();
    const dispatch = useDispatch();
    const location = useLocation();
    const { spaceId, projectId } = menuService.parse(location.pathname);
    const [propertyType, setPropertyType] = useState(null);

    const { propertyPanel, project, stage, assets, global } = useSelector(
        ({ propertyPanel, project, stage, assets, global }) => ({
            propertyPanel,
            project,
            stage,
            assets,
            global,
        }),
    );

    useEffect(() => {
        if (!propertyPanel.sharedResourceId) return;

        if (propertyPanel.type === PROPERTY_PANEL_TYPE.SHARED_ASSET_BY)
            dispatch(getDetailSharedResource(propertyPanel.sharedResourceId));
    }, [propertyPanel.sharedResourceId]);
    useEffect(() => {
        if (!propertyPanel) return;

        if (
            propertyPanel.type === PROPERTY_PANEL_TYPE.SHARED_ASSET_BY ||
            propertyPanel.type === PROPERTY_PANEL_TYPE.SHARED_ASSET_WITH
        )
            setPropertyType(PROPERTY_PANEL_TYPE.ASSET);
        else {
            setPropertyType(propertyPanel.type);
        }
    }, [propertyPanel]);

    const [tabArrowVisible, setTabArrowVisible] = useState(false);

    const onHandlePrevButton = () => {
        panelTabs.current.scrollLeft = 0;
    };

    const onHandleNextButton = () => {
        panelTabs.current.scrollLeft = panelTabs.current.scrollWidth;
    };

    const onHandleTabArrowButton = () => {
        if (panelTabs.current.scrollWidth > panelTabs.current.clientWidth) {
            setTabArrowVisible(true);
        }
    };

    const onHandleClickOpenPreviewPopup = useCallback(() => {
        let url;
        const type = propertyPanel.contents.type.toLowerCase();

        if (propertyPanel.type === PROPERTY_PANEL_TYPE.SHARED_ASSET) {
            url = `${window.location.origin}${menuService.paths.assetsAssetDetail(
                spaceId,
                projectId,
                propertyPanel.contents.id,
            )}/${type}s`;
        } else {
            const projectId = propertyPanel.projectId || project.id;
            const queryParam =
                propertyPanel.type.toUpperCase() === MEDIA_TYPES.VIDEO
                    ? `?projectId=${projectId}&c=${MEDIA_TYPES.VIDEO}`
                    : `?projectId=${projectId}`;
            url = `${window.location.origin}${menuService.paths.assetsAssetDetail(
                spaceId,
                projectId,
                propertyPanel.contents.id,
            )}/${["caption", "description"].includes(type) ? "caption" : type}s${queryParam}&popup=true`;
        }

        window.open(
            url,
            `${type}-asset-preview-popup`,
            `width=${POPUP_MODAL_SIZE.width},height=${POPUP_MODAL_SIZE.height},scrollbar=1`,
        );
    }, [propertyPanel]);

    const shouldHidePreviewButton = () => {
        return propertyPanel.pending
            ? true
            : (propertyPanel.contents && propertyPanel.contents.type === MEDIA_TYPES.PEOPLE) ||
              propertyPanel.type === PROPERTY_PANEL_TYPE.ARCHIVED_ASSET ||
              propertyPanel.type === PROPERTY_PANEL_TYPE.PHOTO
            ? true
            : false;
    };

    const filterDisplayTab = (panelType) => {
        return Object.keys(TAB_TYPE)
            .map((k) => {
                const tabType = TAB_TYPE[k];
                if (panelType === PROPERTY_PANEL_TYPE.PEOPLE) {
                    if (tabType === TAB_TYPE.AUDIO) {
                        return null;
                    }
                } else {
                    if (
                        tabType === TAB_TYPE.SHARED_STATUS &&
                        compareVersions(stage.version, VERSION_CM_4721_SHARES) < 0
                    ) {
                        return null;
                    }
                    if (tabType === TAB_TYPE.RELATED_VIDEOS) {
                        return null;
                    }
                    if (tabType === TAB_TYPE.AUDIO && (!propertyPanel.data || !propertyPanel.data.hasDubbing)) {
                        return null;
                    }
                    if (panelType === PROPERTY_PANEL_TYPE.ASSET) {
                        if (tabType === TAB_TYPE.RELATED_ASSETS) return null;
                        if (tabType === TAB_TYPE.SHARED_WITH) return null;
                    }
                    if (panelType === PROPERTY_PANEL_TYPE.ARCHIVED_ASSET) {
                        if (tabType === TAB_TYPE.RELATED_ASSETS) return null;
                        if (tabType === TAB_TYPE.SHARED_WITH) return null;
                    }
                    if (panelType === PROPERTY_PANEL_TYPE.MUSIC) {
                        if (tabType === TAB_TYPE.RELATED_ASSETS) return null;
                        if (tabType === TAB_TYPE.SHARED_WITH) return null;
                    }
                    if (panelType === PROPERTY_PANEL_TYPE.PHOTO) {
                        if (tabType === TAB_TYPE.RELATED_ASSETS) return null;
                        if (tabType === TAB_TYPE.SHARED_WITH) return null;
                    }
                    if (panelType === PROPERTY_PANEL_TYPE.SHARED_ASSET_WITH) {
                        if (tabType === TAB_TYPE.RELATED_ASSETS) return null;
                        if (tabType === TAB_TYPE.SHARED_WITH) return null;
                    }
                    if (panelType === PROPERTY_PANEL_TYPE.SHARED_ASSET_BY) {
                        if (tabType === TAB_TYPE.RELATED_ASSETS) return null;
                    }
                }

                return tabType;
            })
            .filter((v) => v);
    };

    const convertToUrlResource = useCallback((panelType) => {
        if (panelType === PROPERTY_PANEL_TYPE.VIDEO) {
            return "videos";
        } else if (panelType === PROPERTY_PANEL_TYPE.PEOPLE) {
            return "people";
        } else if (panelType === PROPERTY_PANEL_TYPE.ARCHIVED_ASSET) {
            return "archived";
        } else if (panelType === PROPERTY_PANEL_TYPE.SHARED_ASSET) {
            return "assets";
        } else if (panelType === PROPERTY_PANEL_TYPE.MUSIC) {
            return "music";
        } else if (panelType === PROPERTY_PANEL_TYPE.PHOTO) {
            return "photos";
        }
        return "assets";
    }, []);

    const getRepresentativeImageUrlFromVideo = useCallback((video) => {
        if (video.primaryPoster && video.primaryPoster.accessUrl) return video.primaryPoster.accessUrl;
        else if (video.posters && video.posters.length > 0) return video.posters[0].accessUrl;
        else if (video.thumbnails && video.thumbnails.length > 0) return video.thumbnails[0].accessUrl;

        return `${PUBLIC_URL}/images/thumbnail_no_image.png`;
    }, []);

    const getLinkToDetail = () => {
        if (!parentPath) return null;
        const domain = convertToUrlResource(propertyPanel.type);

        if (["assets", "archived"].includes(domain)) {
            const destinationPage =
                propertyPanel?.data?.archiveStatus === "ARCHIVED"
                    ? menuService.paths.archiveAssetDetail
                    : menuService.paths.assetsAssetDetail;

            return `${destinationPage(spaceId, projectId, propertyPanel.targetId)}?projectId=${
                propertyPanel.data?.project?.id
            }`;
        } else {
            return `${menuService.paths.project(spaceId, projectId)}/${parentPath.slice(
                parentPath.lastIndexOf("/") + 1,
            )}/${domain}/${propertyPanel.targetId}`;
        }
    };

    return (
        <React.Fragment>
            <PanelHeader>
                <PanelHeaderTitle>
                    <SpriteIcon
                        className={`sprite-${propertyType && convertToCategoryType(propertyType).toLowerCase()}`}
                    ></SpriteIcon>
                    {t(`common::label::${propertyType && convertToCategoryType(propertyType)}`)}
                    {propertyPanel.data?.encryptions?.length > 0 && <SpriteIcon className="sprite-drm"></SpriteIcon>}
                    {propertyPanel.data?.hasDubbing?.length > 0 && (
                        <SpriteIcon className={"sprite-dubbing"}></SpriteIcon>
                    )}
                </PanelHeaderTitle>
                <PanelHeaderTool>
                    {propertyPanel.type !== PROPERTY_PANEL_TYPE.ARCHIVED_ASSET && (
                        <IconButton
                            disabled={true}
                            hidden={true}
                            className={"btn-sm btn-dark"}
                            onClick={() => {
                                // this.onClickOpenPreviewPopup();
                            }}
                            icon={
                                <>
                                    <i className={"sprite sprite-publish "}></i>
                                    <span className={"hidden"}>{t(`common::label::${"publish button"}`)}</span>
                                </>
                            }
                        ></IconButton>
                    )}
                    <IconButton
                        hidden={shouldHidePreviewButton()}
                        disabled={
                            propertyPanel.error ||
                            (propertyPanel.contents && propertyPanel.contents.type === MEDIA_TYPES.FILE) ||
                            !propertyPanel.contents.id
                        }
                        className={"btn-sm btn-dark"}
                        onClick={onHandleClickOpenPreviewPopup}
                        icon={
                            <>
                                <i className={"sprite sprite-preview-inverse"}></i>
                                <span className={"hidden"}>{t(`common::label::${"Preview button"}`)}</span>
                            </>
                        }
                    ></IconButton>
                    <button type="button" className="btn btn-icon-solid btn-panel-close" onClick={onClickClose}>
                        <span>
                            <i className="sprite sprite-cancel-lg"></i>
                        </span>
                        <span className={"hidden"}>{t(`common::label::${"Close"}`)}</span>
                    </button>
                </PanelHeaderTool>
            </PanelHeader>
            {propertyPanel.pending ? (
                <Loading></Loading>
            ) : (
                <React.Fragment>
                    {propertyPanel.accessError || propertyPanel.error ? (
                        //접근 권한이 없는 경우
                        <PanelPermissionError
                            handleShareRequestModal={handleShareRequestModal}
                            panelType={propertyPanel.type}
                        ></PanelPermissionError>
                    ) : (
                        <>
                            <PanelBody>
                                {propertyPanel.isShow && propertyPanel.data ? (
                                    <React.Fragment>
                                        <PanelBodyMedia
                                            className={`type-${
                                                propertyPanel.data.mediaType
                                                    ? propertyPanel.data.mediaType.toLowerCase()
                                                    : ""
                                            }`}
                                        >
                                            <ContentsView
                                                type={
                                                    propertyPanel.contents && propertyPanel.contents.type
                                                        ? propertyPanel.contents.type
                                                        : contentsViewData.mediaType
                                                }
                                                isInStorageTab={false}
                                                isEncrypted={
                                                    contentsViewData &&
                                                    contentsViewData.encryptions &&
                                                    contentsViewData.encryptions.length > 0
                                                }
                                                jobId={
                                                    contentsViewData && contentsViewData.job && contentsViewData.job.id
                                                }
                                                data={contentsViewData}
                                                totalCount={
                                                    (contentsViewData &&
                                                        contentsViewData.elements &&
                                                        contentsViewData.elements.length) ||
                                                    0
                                                }
                                            />
                                        </PanelBodyMedia>

                                        <PanelBodyInformation>
                                            <div className="information-tools">
                                                <div className="tab-nav tab-nav-sm">
                                                    <nav className="nav" ref={panelTabs}>
                                                        {filterDisplayTab(propertyPanel.type).map((v, i) => (
                                                            <button
                                                                key={i}
                                                                className={`tab-item ${
                                                                    v === activeTab ? "active" : ""
                                                                }`}
                                                                onClick={() => {
                                                                    onClickTab(v);
                                                                }}
                                                            >
                                                                <span>
                                                                    {v === "Related Assets"
                                                                        ? t(`common::label::Related {{pascalK}}`, {
                                                                              pascalK: t(`common::label::Assets`),
                                                                          })
                                                                        : v === "Related Videos"
                                                                        ? t(`common::label::Related {{pascalK}}`, {
                                                                              pascalK: t(`common::label::Videos`),
                                                                          })
                                                                        : v === "Shared Status"
                                                                        ? t(`common::label::Shared {{name}}`, {
                                                                              name: t(`common::label::Status`),
                                                                          })
                                                                        : t(`common::label::${v}`)}
                                                                </span>
                                                            </button>
                                                        ))}
                                                    </nav>
                                                    {/* TODO: 한번에 마지막으로 이동되도록 되어 있음 => 다른 정의가 필요함 */}
                                                    <TabArrowButton
                                                        onHandlePrevButton={onHandlePrevButton}
                                                        onHandleNextButton={onHandleNextButton}
                                                        onHandleTabArrowButton={onHandleTabArrowButton}
                                                        tabArrowVisible={tabArrowVisible}
                                                    ></TabArrowButton>
                                                </div>
                                            </div>

                                            <div className={"information-contents"}>
                                                <ScrollArea>
                                                    {activeTab === TAB_TYPE.OVERVIEW && (
                                                        <DomainOverview
                                                            {...propertyPanel.data}
                                                            isSharedAssetView={
                                                                propertyPanel.type === PROPERTY_PANEL_TYPE.SHARED_ASSET
                                                            }
                                                            contentType={propertyPanel.type}
                                                            projectId={propertyPanel.projectId}
                                                        />
                                                    )}

                                                    {activeTab === TAB_TYPE.METADATA && (
                                                        <MetadataView
                                                            {...propertyPanel.data}
                                                            type={propertyPanel.type}
                                                            categoryType={convertToCategoryType(propertyPanel.type)}
                                                            onClickGetHiddenFieldValue={getHiddenFieldValue}
                                                            useCustomMetadata={
                                                                propertyPanel.type === PROPERTY_PANEL_TYPE.ASSET
                                                            }
                                                        />
                                                    )}

                                                    {activeTab == TAB_TYPE.RELATED_ASSETS && (
                                                        <RelatedAssetTab
                                                            assets={assets}
                                                            relatedAssets={relatedAssets}
                                                            propertyPanel={propertyPanel}
                                                        />
                                                    )}

                                                    {activeTab === TAB_TYPE.AUDIO && propertyPanel.data.hasDubbing && (
                                                        <AudioView {...propertyPanel.data} />
                                                    )}

                                                    {activeTab === TAB_TYPE.RELATED_VIDEOS && (
                                                        <RelatedVideosTab
                                                            getRepresentativeImageUrlFromVideo={
                                                                getRepresentativeImageUrlFromVideo
                                                            }
                                                            parentPath={parentPath}
                                                            assets={assets}
                                                            relatedVideos={relatedVideos}
                                                        />
                                                    )}

                                                    {activeTab === TAB_TYPE.SHARED_WITH && (
                                                        <SharedWithTab onClickClose={onClickClose} />
                                                    )}
                                                </ScrollArea>
                                            </div>
                                        </PanelBodyInformation>
                                    </React.Fragment>
                                ) : (
                                    <div>{t(`common::label::${"Not found"}`)}</div>
                                )}
                            </PanelBody>
                            <div className="panel-footer">
                                <Link to={getLinkToDetail}>
                                    <div>
                                        <strong>{t(`common::label::${"Detail"}`)}</strong>
                                        <span className={"icon"}>
                                            <i className="sprite"></i>
                                        </span>
                                    </div>
                                </Link>
                            </div>
                        </>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default CustomPanel;
