import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const PageTitle = (props) => {
    const { className, ...otherProps } = props;
    return <h2 {...otherProps} className={clsx("page-title", className)}/>;
};
PageTitle.propTypes = {
    className: PropTypes.string,
};

export default PageTitle;
