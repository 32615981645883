import * as React from "react";
import { useCallback } from "react";
import { TableCell } from "@mzc-pdc/ui";
import { Grid } from "@mui/material";
import Skeleton from "@components_v2/skeleton/skeleton";

export const SkeletonAssetsTable = ({ columns }) => {
    const getSkeletonByColumn = useCallback(
        (columnId) => {
            if (!columnId) return;
            if (columnId === "desiredStatus")
                return <Skeleton variant="rectangular" animation={"wave"} width={32} height={18} />;
            if (columnId === "name")
                return (
                    <Grid
                        container
                        direction={`row`}
                        justifyContent={`flex-start`}
                        alignItems={`center`}
                        flexWrap={"nowrap"}
                    >
                        <Grid item>
                            <Skeleton
                                variant="rectangular"
                                animation={"wave"}
                                width={52}
                                height={52}
                                style={{ marginLeft: `6px`, marginRight: `16px` }}
                            />
                        </Grid>
                        <Grid item>
                            <Skeleton variant="rectangular" animation={"wave"} width={300} height={21} />
                        </Grid>
                    </Grid>
                );
            if (columnId === "mediaType")
                return <Skeleton variant="rectangular" animation={"wave"} width={170} height={18} />;
            if (columnId === "size")
                return (
                    <Grid container direction={`column`} justifyContent={`flex-start`} alignItems={`center`}>
                        <Grid item>
                            <Skeleton variant="rectangular" animation={"wave"} width={170} height={18} />
                        </Grid>
                    </Grid>
                );
            if (columnId === "extensions")
                return <Skeleton variant="rectangular" animation={"wave"} width={170} height={18} />;
            if (columnId === "duration")
                return <Skeleton variant="rectangular" animation={"wave"} width={100} height={18} />;
            if (columnId === "type")
                return <Skeleton variant="rectangular" animation={"wave"} width={100} height={18} />;
            return null;
        },
        [columns],
    );

    return (
        <>
            <TableCell style={{ width: "16px" }} padding="checkbox">
                <Grid item>
                    <Skeleton
                        variant="rectangular"
                        animation={"wave"}
                        width={18}
                        height={18}
                        style={{ marginRight: "0px" }}
                    />
                </Grid>
            </TableCell>
            {columns &&
                columns.map((column) => (
                    <TableCell style={{ paddingLeft: "0px" }}>{getSkeletonByColumn(column.id)}</TableCell>
                ))}
        </>
    );
};

export default SkeletonAssetsTable;
