import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import config from "../../../config";

import {
    axiosWithoutHeaders,
} from "../../../cores/axiosWrapper";
import { parseQueryParameter } from "../../../cores";
import { ImageSymbolCloudpexMedia, ImageCredentialGet } from "@mzc-cloudplex/icons";
import { CircularProgress, Grid, Stack, Typography } from "@mzc-pdc/ui";
import theme from "@styles/theme";

const GetAccessTokenPopup = ({ t, match, location }) => {
    useEffect(async () => {
        const clientId = config.cloudplex.clientId;
        const queryParams = parseQueryParameter(location.search);
        const code = queryParams.get("code");
        if (!code) {
            const redirectUrl = encodeURIComponent(`${window.location.origin}/cloudplex/oauth2`);
            const authorizeUrl = `${config.mz_pops.oauth_host}/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}`;
            window.location = authorizeUrl;
        } else {
            const redirectUrl = `${window.location.origin}/cloudplex/oauth2`;
            const apiEndpoint = `${config.cloudplex.startApiEndpoint}/oauth/token`;

            const response = await axiosWithoutHeaders.post(apiEndpoint, {
                code,
                redirectUrl,
            });
            console.log("oauth token response : ", response.data);
            window.opener.CloudPlexOAuthTokenCallback(response.data);
            window.close();
        }

        return () => {

        };
    }, []);

    return (<Grid container sx={{height: `100%`}}>
        <Grid container alignItems={'center'} sx={{height: 32, px: 1.5, gap: 0.75, borderBottom: `1px solid ${theme.palette.divider}`}} >
            <ImageSymbolCloudpexMedia width={24} height={24} />
            <Typography variant={"body3_400"}>CloudPlex Media</Typography>
        </Grid>
        <Grid item xs>
            <Stack gap={2.5} alignItems={'center'}>
                <ImageCredentialGet width={76} height={70} />
                <Typography variant={"body1_500"}>{t(`common::msg::Getting Access Token...`, `Getting Access Token...`)}</Typography>
                <CircularProgress color={"primary"} size={32} />
            </Stack>
        </Grid>
    </Grid>);
};

export default withTranslation()(withRouter(GetAccessTokenPopup));