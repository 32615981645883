import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import * as bulkJobActions from "../../../modules/bulkJobs";
import { listJobs } from "../../../modules/bulkJobs";
import * as notificationActions from "../../../modules/notification";
import { setNotification } from "../../../modules/notification";
import { JOB_ACTIONS } from "@constants";
import CreateAssetsBulkJobMessage from "../../../components/modals/components/CreateAssetsBulkToast";
import menuService from "@services/menuService";
import { Divider, Grid, Stack } from "@mzc-pdc/ui";
import { SelectLimitTags } from "@components_v2/select";
import JobEmptyList from "@routes/rightsidebar/ui/job-empty-list";
import { copyAsset } from "@modules/asset";
import JobItem from "@routes/rightsidebar/ui/job-item";
import useMyJobs from "@hooks/useMyJobs";
import { updateMyJobsJob } from "@modules/myJobs";
import useVirtualFolderPath from "@hooks/useVirtualFolderPath";

let throttleHandler = false;

const CreateAssetsPanel = ({ filter, jobIcon, summaries, taskComponent }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const { spaceId, projectId } = menuService.parse(location.pathname);
    const { getVirtualFolderFullPath, getDriveName } = useVirtualFolderPath({ domain: "ASSET" });
    const { synchronize } = useMyJobs({ resource: "createAsset", store: "uploadQueue" });

    const [limit, setLimit] = useState(20);
    const [viewmoreEnable, setViewmoreEnable] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState({});

    const { bulkJobs } = useSelector((state) => state);
    const createAssetJobs = useSelector(({ myJobs }) => myJobs.createAsset);
    const stage = useSelector(({ stage }) => stage);
    const project = useSelector(({ project }) => project);

    const dispatch = useDispatch();

    const BulkJobActions = bindActionCreators(bulkJobActions, dispatch);
    const NotificationActions = bindActionCreators(notificationActions, dispatch);

    useEffect(() => {
        synchronize();
    }, []);

    useEffect(() => {
        if (bulkJobs.totalCounts > bulkJobs.data.length) {
            setViewmoreEnable(true);
        } else {
            setViewmoreEnable(false);
        }
    }, [bulkJobs.data]);

    useEffect(() => {
        BulkJobActions.updateSearch({ limit });
    }, [limit]);

    const onChangeLimit = useCallback(async (limit) => {
        setLimit(limit);
        await BulkJobActions.listJobs();
    }, []);

    const onSubmit = useCallback(async () => {
        await BulkJobActions.updateSearch({ limit: bulkJobs.search.limit + limit });
        await BulkJobActions.listJobs();
    }, []); //archive, restore(목록페이지에서 job 요청) / -> job 목록 요청 -> job 완료시 task에 일부 실패가 있으면 토스트(panel에서 담당)

    const toggleJobIsExpanded = useCallback(
        async (action, item) => {
            if (throttleHandler) {
                return;
            }
            if (!item.isFolded) {
                const job = {
                    ...item,
                    isFolded: !item.isFolded,
                };

                if (action !== "UPLOAD_ASSET") await BulkJobActions.updateJob({ job });
                else dispatch(updateMyJobsJob({ resource: "createAsset", jobId: item.jobId, data: job }));
            } else {
                if (action !== "UPLOAD_ASSET") {
                    throttleHandler = true;
                    try {
                        const { data } = await BulkJobActions.listTasks({ jobId: item.jobId });
                        const newTasks = data.results || [];
                        await Promise.all(
                            newTasks.map(async (task) => {
                                if (task.metadata.virtualFolder?.id) {
                                    task.folderFullPath = await getVirtualFolderFullPath({
                                        folderId: task.metadata.virtualFolder?.id,
                                    });
                                } else if (task.metadata.virtualFolder?.fullpath !== undefined) {
                                    const driveName = await getDriveName({
                                        driveId: task.metadata.virtualFolder.driveId,
                                    });
                                    task.folderFullPath = `${driveName}/${task.metadata.virtualFolder.fullpath}`;
                                }
                            }),
                        );
                        const job = {
                            ...item,
                            isFolded: item.isFolded === undefined ? false : !item.isFolded,
                            tasks: newTasks,
                        };
                        await BulkJobActions.updateJob({ job });
                        throttleHandler = false;
                    } catch (error) {
                        throttleHandler = false;
                    }
                } else {
                    const job = {
                        ...item,
                        isFolded: !item.isFolded,
                    };

                    dispatch(updateMyJobsJob({ resource: "createAsset", jobId: item.jobId, data: job }));
                }
            }
        },
        [getVirtualFolderFullPath, getDriveName],
    );

    const toastInfoMessage = useCallback((checkedAssets) => {
        NotificationActions.setNotification({
            type: "info",
            autoClose: false,
            contents: (
                <CreateAssetsBulkJobMessage
                    firstAssetMediaType={checkedAssets[0].mediaType}
                    count={checkedAssets.length}
                />
            ),
        });
    }, []);

    const onRetry = useCallback(async (job) => {
        if (job.action === JOB_ACTIONS.CREATE_ASSETS) {
            const { data } = await BulkJobActions.listTasks({ jobId: job.jobId });
            const tasks = data.results || [];
            const failedTasks = tasks.filter((t) => t.status === "FAILED");
            if (failedTasks.length > 0) {
                const assets = failedTasks.map((t) => {
                    return t.metadata;
                });
                const newJob = {
                    action: JOB_ACTIONS.CREATE_ASSETS,
                    params: {
                        items: assets,
                    },
                };
                await BulkJobActions.createJob(newJob);
                toastInfoMessage(assets);
                await BulkJobActions.listJobs();
            }
        } else if (job.action === JOB_ACTIONS.COPY_ASSETS) {
            const metadata = job.metadata;
            const params = {
                destination: {
                    ...metadata.destination,
                },
                source: {
                    assets: [...metadata.source.assets],
                },
            };

            await dispatch(copyAsset(params));
            await dispatch(listJobs());
            await dispatch(
                setNotification({
                    type: "success",
                    contents: t(`common::msg::${"Asset {{item}} duplication completed."}`, {
                        item: metadata.source.assets?.[0].name,
                    }),
                }),
            );
        }
    }, []);

    const onHandleClickFilter = useCallback(
        (id, value) => {
            setSelectedFilter({
                ...selectedFilter,
                [id]: value,
            });
        },
        [selectedFilter],
    );

    const getJobStatus = useCallback(
        (item) => {
            if (!item || !createAssetJobs.data) return "";
            const status = item.status;

            if (status === "FAILED" || item.summary?.detail?.failCount === item.taskCount) return "ERROR";
            else return status;
        },
        [createAssetJobs.data],
    );

    const jobsData = bulkJobs.data;

    const filteredJobsData = useMemo(() => {
        const createAssetJobData = createAssetJobs.data ?? [];

        const filteredJobsData = [...createAssetJobData, ...jobsData]
            .sort((prev, post) => new Date(post) - new Date(prev))
            .filter((job) =>
                Object.keys(selectedFilter)
                    .filter((key) => selectedFilter[key]?.length)
                    .every((key) => selectedFilter[key].includes(job[key])),
            );

        const addValueToSummaries = (job) => {
            return summaries.map(({ key, ...rest }) => {
                return {
                    ...rest,
                    value: job[key],
                };
            });
        };
        const getTaskDetailComponent = (job) => {
            const TaskDetailComponent = taskComponent?.[job.action];

            if (TaskDetailComponent) {
                return (
                    <TaskDetailComponent
                        tasks={job.tasks}
                        metadata={job.metadata}
                        job={{ status: job.status, action: job.action }}
                    />
                );
            }
            return null;
        };

        return filteredJobsData.map((job) => ({
            job: {
                id: job.jobId,
                action: job.action,
                icon: jobIcon[job.action],
                status: getJobStatus(job),
                isPartialFailure: job.partialFailure,
                exitCode: job.summary?.exitCode,
                summaryInfo: addValueToSummaries(job),
            },
            task: {
                taskCount: job.taskCount ?? job.tasks?.length,
                isTaskDetailFolded: job.isFolded === undefined || job.isFolded,
                taskDetailComponent: getTaskDetailComponent(job),
            },
            action: {
                toggle: () => toggleJobIsExpanded(job.action, job), //이걸 누르면 job이 바뀌면서 filteredJobsData가 바뀌고 rerender가 될텐데 괜찮나?
                retry: () => onRetry(job),
                // cancel: () => cancel(job) //Note: Asset 생성 중 업로드 중단이 가능하면 사용할 것(에셋 빈껍데기가 먼저 생성되고 업로드하는거라 되면 안될지도)
            },
        }));
    }, [jobsData, selectedFilter, filter, onRetry, toggleJobIsExpanded, createAssetJobs.data, getJobStatus]);

    return (
        <React.Fragment>
            <Grid container pt={3} px={3} pb={2} gap={1} justifyContent={"center"} alignItems={"center"}>
                {filter &&
                    Object.entries(filter)?.map(([key, options]) => (
                        <Grid item xs>
                            <SelectLimitTags
                                displayEmpty
                                notched
                                label={key}
                                labelId={`select-label-asset-jobs-${key}`}
                                multiple={true}
                                value={selectedFilter[key] ?? []}
                                options={options}
                                MenuProps={{
                                    disablePortal: true,
                                }}
                                onChange={(e) => {
                                    onHandleClickFilter(key, e.target.value);
                                }}
                                limitTags={1}
                            />
                        </Grid>
                    ))}
            </Grid>
            <Divider sx={{ mx: 3 }} />
            <Stack flex={1} px={3} py={2.5} gap={4} sx={{ overflowY: "auto" }}>
                {filteredJobsData.length > 0 ? (
                    <>
                        {filteredJobsData.map((data) => {
                            return <JobItem {...data} key={`asset-job-${data.job.id}`} />;
                        })}
                    </>
                ) : (
                    <JobEmptyList />
                )}
            </Stack>
            {jobsData.length > 0 && (
                <div className="panel-footer" style={{ flex: 0, paddingRight: "10px" }}>
                    {viewmoreEnable ? (
                        <button
                            type={"button"}
                            className={"btn btn-outline-default"}
                            onClick={(e) => {
                                e.preventDefault();
                                onSubmit();
                            }}
                        >
                            <span>{`View More`}</span>
                            <i className="sprite sprite-angle-down" />
                        </button>
                    ) : (
                        ""
                    )}
                </div>
            )}
        </React.Fragment>
    );
};

export default CreateAssetsPanel;
