import React from "react";
import { Box, Divider, Grid, Popover, Typography, Avatar as MuiAvatar, Button } from "@mzc-pdc/ui";
import theme from "@styles/theme";
import { styled } from "@mui/system";

const Avatar = styled(MuiAvatar)(({}) => ({
    "&.MuiAvatar-root": {
        svg: {
            marginRight: 0,
        },
    },
}));

const AvatarText = (value) => {
    const emojiRegex =
        /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/;

    return value?.match(emojiRegex) ? value.substring(0, 2) : value?.substring(0, value?.length === 2 ? 2 : 1);
};

const UserProfile = (props) => {
    return (
        <>
            {(props.profileImageUrl !== null && (
                <Avatar
                    style={{
                        width: props.size,
                        height: props.size,
                        fontSize: 18,
                        mt: "5px",
                    }}
                    src={props.profileImageUrl}
                />
            )) || (
                <Avatar
                    style={{
                        width: props.size,
                        height: props.size,
                        fontSize: 16,
                        lineHeight: `40px`,
                        color: `white`,
                        mt: "5px",
                    }}
                >
                    {AvatarText(props.firstName)}
                </Avatar>
            )}
        </>
    );
};

// NOTE:: 현우님! 말줄임 되는 버튼 여기 있습니다! maxWidth 값으로로 너비 조절 하시면 됩니다!
export const UserDetailButton = ({ userName, maxWidth, onClick }) => {
    return (
        <Button
            variant={"text"}
            size={"small"}
            color={"greySecondary"}
            onClick={onClick}
            sx={{
                maxWidth: maxWidth ?? 100,
                "> span[class^=css]": {
                    display: `inline-block`,
                    maxWidth: `inherit`,
                    overflow: `hidden`,
                    textOverflow: `ellipsis`,
                    whiteSpace: `nowrap`,
                },
            }}
        >
            {userName}
        </Button>
    );
};
const UserDetailPopper = ({ handleUserProfileClose, userProfileAnchorEl, openUserProfile, userProfileData, id }) => {
    return (
        <Popover
            id={id}
            open={openUserProfile}
            anchorEl={userProfileAnchorEl}
            onClose={handleUserProfileClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
        >
            <Box
                sx={{
                    p: 2,
                    width: `220px`,
                    border: `1px solid ${theme.palette.secondary[600]}`,
                    boxShadow: `0 2px 4px 0 rgba(0, 0, 0, 0.24)`,
                    borderRadius: `4px`,
                }}
            >
                <Grid container gap={`8px`}>
                    <Grid item>
                        <UserProfile size={18} />
                    </Grid>
                    <Grid item>
                        <Grid container flexDirection={`column`}>
                            <Grid item>
                                <Typography variant={"body1_700"}> {userProfileData?.name}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={"body1_400"} color={theme.palette.secondary[600]}>
                                    {userProfileData?.username}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
                <Grid
                    container
                    flexDirection={`column`}
                    wrap={`nowrap`}
                    gap={`16px`}
                    sx={{ maxHeight: `100px`, overflow: `overlay` }}
                >
                    <Grid container flexDirection={"column"}>
                        <Grid item>
                            <Typography variant={"body3_400"} color={theme.palette.secondary[600]}>
                                &middot; Display Name
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography sx={{ paddingLeft: `10px` }} color={theme.palette.secondary[800]}>
                                {(userProfileData?.name || userProfileData?.displayName) ?? "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container flexDirection={"column"}>
                        <Grid item>
                            <Typography sx={{ fontSize: `12px` }} color={theme.palette.secondary[600]}>
                                &middot; Phone Number
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography sx={{ paddingLeft: `10px` }} color={theme.palette.secondary[800]}>
                                {userProfileData?.phoneNumber ?? "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Popover>
    );
};

export default UserDetailPopper;
