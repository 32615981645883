export const getProjectRandomColor = (projectId) => {
    if (!projectId) return null;
    const randomColors = [
        "#ef4b4b",
        "#405fff",
        "#5d8fff",
        "#5f62e5",
        "#8660d9",
        "#7182a6",
        "#e86894",
        "#3f485a",
        "#5a4a3f",
        "#3f585a",
        "#ffaa31",
        "#009ea0",
    ];
    const randomIndex =
        (projectId.charCodeAt(0) * projectId.charCodeAt(1) * (projectId.length + 3)) % randomColors.length;

    return randomColors[randomIndex];
};
