import {
    Dialog,
    Divider,
    FormControl,
    Grid,
    Radio,
    RadioGroup,
    DialogContent,
    DialogActions,
    Button,
    Switch,
    DialogTitle,
    IconButton,
} from "@mzc-pdc/ui";
import ReactDOM from "react-dom";
import { useState, useEffect, useCallback, useMemo } from "react";
import { RadioFormControlLabel } from "@components_v2/radioGroup/radioGroup.styled";
import { useTranslation } from "react-i18next";
import AutocompleteTable from "@features/autocomplete-table/autocomplete-table";
import AutocompleteTableList from "@features/autocomplete-table/autocomplete-table-list";
import SkeletonAssetsTable from "@features/autocomplete-table/autocomplete-table-skeleton/autocomplete-table-skeleton-assets";
import Typography from "@components_v2/typography/typography";
import { StyledErrorAlert } from "@components_v2/alert/alert.styled";
import { clearList, getAssetsList } from "@modules/assets";
import { useDispatch, useSelector } from "react-redux";
import { Trans } from "react-i18next";
import { IconCancel, IconInfoCircle, IconOpenInNew, ImageFolderSmallOn } from "@mzc-cloudplex/icons";
import DialogAssetsColumn from "@routes/collections/ui/autocomplete-table-columns-dialog-assets";
import { LinkedAssetsColumn } from "@features/autocomplete-table/autocomplete-table-columns/autocomplete-table-columns-assets";
import AssetThumbnail from "../thumbnail/asset-thumbnail";
import AssetTypeFlag from "@components_v2/icon-type/asset/asset-type-flag";
import convertToByte from "@cores/convertToByte";
import theme from "@styles/theme";
import TableCard from "@components_v2/card/table-card";
import SelectFolderPathModal from "@components/modals/SelectFolderPath";
import Tooltip from "@components_v2/Tooltip/tooltip";

const MAX_ASSETS = 1000;

const DialogAddLinkedAssets = ({
    collectionId,
    actions,
    addedLinkedAssets,
    shouldUpdatePrimaryAsset,
    onClickClose,
    afterMutation,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const assets = useSelector(({ assets }) => assets);
    const project = useSelector(({ project }) => project);

    const [actionPending, setActionPending] = useState(null);
    const [assetFolderDialogOpen, setAssetFolderDialogOpen] = useState(false);
    const [selectedAssets, setSelectedAssets] = useState([]);
    const [assetsSelectType, setAssetsSelectType] = useState("search");
    const [assetVirtualFolder, setAssetVirtualFolder] = useState(null);
    const [searchValue, setSearchValue] = useState(null);
    const [debouncedValue, setDebouncedValue] = useState(null);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(20);

    const onHandleChangeAssetSelectType = (e) => {
        setAssetsSelectType(e.target.value);
    };

    const onHandleSearchAsset = (value) => {
        if (value !== searchValue) {
            setSearchValue(value);
        }
    };

    const onHandleFetchAsset = () => {
        if (assets?.data?.length >= assets?.totalCount || assets.pending) return;
        const newOffset = offset + limit;
        setOffset(newOffset);
        const params = { name: debouncedValue, limit, offset: newOffset };
        dispatch(getAssetsList(project.id, params));
    };

    const onHandleChangeAssets = (checkedList) => {
        setSelectedAssets([...selectedAssets, ...checkedList]);
    };

    const onHandleDeleteAsset = (id) => {
        const newAssets = selectedAssets.filter((asset) => asset.id !== id);
        setSelectedAssets(newAssets);
    };

    const onHandleClickSubmit = useCallback(async () => {
        try {
            setActionPending(true);

            await dispatch(
                actions.updateCollectionAction({
                    contentId: collectionId,
                    params: {
                        action: "ADD_ITEMS",
                        data: {
                            assetIds: selectedAssets.length > 0 ? selectedAssets.map((asset) => asset.id) : undefined,
                            virtualFolder: assetVirtualFolder
                                ? { id: assetVirtualFolder.id, recursive: assetVirtualFolder.recursive }
                                : undefined,
                        },
                    },
                }),
            );
            if (shouldUpdatePrimaryAsset && selectedAssets.length)
                await dispatch(
                    actions.updateCollection({
                        id: collectionId,
                        params: { primaryAsset: { id: selectedAssets[0].id } },
                    }),
                );
            if (afterMutation) afterMutation();
            onClickClose();
        } catch (error) {
            console.error(error);
        } finally {
            setActionPending(null);
        }
    }, [selectedAssets, assetVirtualFolder, collectionId, shouldUpdatePrimaryAsset, afterMutation]);

    const getTableValueByColumn = useCallback((columnId, option) => {
        if (columnId === "name")
            return (
                <TableCard
                    thumbnail={
                        <AssetThumbnail
                            variant={"table"}
                            src={option?.accessUrls?.[0]}
                            width={52}
                            height={52}
                            mediaType={option.mediaType}
                        />
                    }
                    name={{
                        name: (
                            <Typography variant={"body1"} component={"span"}>
                                {option?.name}
                            </Typography>
                        ),
                        startIcon: <AssetTypeFlag width={22} height={16} type={option.mediaType} />,
                    }}
                />
            );
        if (columnId === "mediaType") return option?.mediaType;
        if (columnId === "size") return option?.size ? convertToByte(option?.size) : "-";
        return null;
    }, []);

    const isAssetsCountError = useMemo(() => {
        return selectedAssets.length > MAX_ASSETS;
    }, [selectedAssets]);

    const assetOptions = useMemo(
        () =>
            assets?.data?.filter(
                (asset) => !addedLinkedAssets?.concat(selectedAssets)?.find((added) => added.id === asset.id),
            ),
        [assets, selectedAssets, addedLinkedAssets],
    );

    useEffect(() => {
        if (searchValue === null) return;
        const debounce = setTimeout(() => {
            return setDebouncedValue(searchValue);
        }, 300);
        return () => clearTimeout(debounce);
    }, [searchValue]);

    useEffect(() => {
        dispatch(clearList());
        setOffset(0);
        const params = { name: debouncedValue, limit, offset: 0 };
        dispatch(getAssetsList(project.id, params));
    }, [debouncedValue]);

    return (
        <>
            {assetFolderDialogOpen &&
                ReactDOM.createPortal(
                    <SelectFolderPathModal
                        open
                        domain={"Asset"}
                        submitText={`Select`}
                        width={"600px"}
                        title={"Select assets in a specific folder?"}
                        subFoldersOption={true}
                        useNewFolder={false}
                        onSubmit={({ folderId, folderName, includeSubFolders }) => {
                            setAssetVirtualFolder({ id: folderId, name: folderName, recursive: includeSubFolders });
                            setAssetFolderDialogOpen(false);
                        }}
                        onClose={() => setAssetFolderDialogOpen(false)}
                    />,
                    document.body,
                )}
            <Dialog variant={"basic"} open onClose={() => onClickClose(false)}>
                <DialogTitle>{t(`common::label::Add assets?`)}</DialogTitle>
                <DialogContent sx={{ width: "550px" }}>
                    <Grid container direction={"column"}>
                        <Grid item direction="row">
                            <FormControl sx={{ marginLeft: 0 }}>
                                <RadioGroup row value={assetsSelectType} onChange={onHandleChangeAssetSelectType}>
                                    <RadioFormControlLabel
                                        control={<Radio color="secondary" />}
                                        label={t(
                                            `common::label::Select assets through search`,
                                            `Select assets through search`,
                                        )}
                                        value="search"
                                        // disabled={isAutoLockDisabled}
                                    />
                                    <RadioFormControlLabel
                                        control={<Radio color="secondary" />}
                                        label={t(
                                            `common::label::Select assets in a specific folder`,
                                            `Select assets in a specific folder`,
                                        )}
                                        value="folder"
                                        // disabled={isAutoLockDisabled}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Divider sx={{ mt: 1.5, mb: 2.5, borderColor: `#dfdfee` }} />
                        {assetsSelectType === "search" && (
                            <>
                                <Grid item>
                                    <AutocompleteTable
                                        type={"table"}
                                        key={"autocomplete-asset-table"}
                                        nextOptions={assets?.data?.length < assets?.totalCount}
                                        placement={"bottom-end"}
                                        placeholder={t(`common::msg::Please search by asset name or ID.`)}
                                        onSubmit={onHandleChangeAssets}
                                        columns={LinkedAssetsColumn} //Note: 여기부터
                                        skeletonColumns={
                                            <SkeletonAssetsTable
                                                columns={LinkedAssetsColumn.filter((item) => item.useInAutocomplete)}
                                            />
                                        }
                                        multiSelect={true}
                                        options={assetOptions}
                                        isPending={assets?.pending}
                                        selectedItems={selectedAssets}
                                        optionsTotalCount={assets?.totalCount}
                                        getValueByColumn={getTableValueByColumn}
                                        onSearch={onHandleSearchAsset}
                                        onFetchData={onHandleFetchAsset}
                                    />
                                </Grid>

                                <Grid item mt={2} mb={1}>
                                    <Typography variant={"body1"} color={theme.palette.secondary[800]}>
                                        {t(`common::label::Selected Assets`)} (
                                        <Typography
                                            variant={"body1"}
                                            color={
                                                selectedAssets.length > MAX_ASSETS
                                                    ? "#ef4016"
                                                    : theme.palette.primary[400]
                                            }
                                        >
                                            {selectedAssets.length}
                                        </Typography>
                                        /{MAX_ASSETS} {t(`common::label::max`)})
                                    </Typography>
                                </Grid>

                                {isAssetsCountError && (
                                    <Grid item mb={1}>
                                        <StyledErrorAlert
                                            icon={<IconInfoCircle size={16} htmlColor={theme.palette.error[700]} />}
                                            color="error"
                                            variant="outlined"
                                        >
                                            <Typography
                                                variant={"body1_400"}
                                                sx={{ color: theme.palette.secondary[800] }}
                                            >
                                                <Trans
                                                    i18nKey="common::msg::Size of files Option : If you choose your own assets, you can have up to {{maxAssets}}. <NewLine/> Keep the number of selected assets under {{maxAssets}}."
                                                    values={{ maxAssets: MAX_ASSETS }}
                                                    components={{
                                                        NewLine: <Typography />,
                                                    }}
                                                />
                                            </Typography>
                                        </StyledErrorAlert>
                                    </Grid>
                                )}
                                <AutocompleteTableList
                                    type={"ASSET"}
                                    data={[{ value: selectedAssets }]}
                                    columns={DialogAssetsColumn}
                                    menuActions={(item) => (
                                        <Tooltip title={t(`common::label::Delete`)}>
                                            <IconButton
                                                size="medium"
                                                color="inherit"
                                                circled
                                                onClick={(e) => {
                                                    onHandleDeleteAsset(item.id);
                                                }}
                                            >
                                                <IconCancel size={12} htmlColor={theme.palette.grey[700]} />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    noDataContent={
                                        <Grid
                                            container
                                            sx={{
                                                height: "60px",
                                                p: 1.5,
                                                borderRadius: `4px`,
                                                border: `1px solid ${theme.palette.secondary[100]}`,
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography>
                                                {t(
                                                    `common::msg::Select assets through search to put them in your collection.`,
                                                    `Select assets through search to put them in your collection.`,
                                                )}
                                            </Typography>
                                        </Grid>
                                    }
                                    divider={true}
                                    gridStyle={{ maxHeight: "336px" }}
                                />
                            </>
                        )}
                        {assetsSelectType === "folder" && (
                            <>
                                <Grid item>
                                    <Button
                                        animation={"wave"}
                                        animationtarget="endIcon"
                                        animationtype="toRight"
                                        color="secondary"
                                        endIcon={<IconOpenInNew size={16} />}
                                        label="Button"
                                        onClick={() => setAssetFolderDialogOpen(true)}
                                        size="medium"
                                        type="button"
                                        variant="outlined"
                                    >
                                        {t(
                                            `common::label::Select assets in a specific folder`,
                                            `Select assets in a specific folder`,
                                        )}
                                    </Button>
                                </Grid>
                                <Grid item mt={2} mb={1}>
                                    <Typography>{t(`common::label::Selected a Folder`)}</Typography>
                                </Grid>
                                {assetVirtualFolder?.id ? (
                                    <Grid
                                        item
                                        sx={{
                                            p: 1.5,
                                            borderRadius: `4px`,
                                            border: `1px solid ${theme.palette.secondary[100]}`,
                                        }}
                                    >
                                        <Grid container alignItems={"center"}>
                                            <Grid item mr={1}>
                                                <ImageFolderSmallOn width={16} height={16} />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant={"body1_400"} color={theme.palette.secondary[800]}>
                                                    {t(
                                                        `common::label::${assetVirtualFolder?.name}`,
                                                        assetVirtualFolder?.name,
                                                    )}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container alignItems={"center"} ml={1.5}>
                                            <Grid item>
                                                <Switch
                                                    color="success"
                                                    checked={assetVirtualFolder?.recursive}
                                                    onChange={(e) => {
                                                        setAssetVirtualFolder({
                                                            ...assetVirtualFolder,
                                                            recursive: e.target.value === "on",
                                                        });
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body1_400" color={theme.palette.secondary[800]}>
                                                    {assetVirtualFolder?.recursive
                                                        ? t(
                                                              `common::label::${"Also include assets from subfolders"}`,
                                                              "Also include assets from subfolders",
                                                          )
                                                        : t(
                                                              `common::label::${"Only assets in that folder are selected"}`,
                                                              "Only assets in that folder are selected",
                                                          )}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid
                                        item
                                        sx={{
                                            height: "60px",
                                            p: 1.5,
                                            borderRadius: `4px`,
                                            border: `1px solid ${theme.palette.secondary[100]}`,
                                            textAlign: "center",
                                        }}
                                    >
                                        <Typography>
                                            {t(
                                                `common::msg::Select the folder containing the assets you want to put in your collection.`,
                                            )}
                                        </Typography>
                                    </Grid>
                                )}
                            </>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant={`text`} size={"medium"} onClick={() => onClickClose(false)} color={`grey`}>
                        {t(`common::label::Cancel`)}
                    </Button>
                    <Button
                        variant={`contained`}
                        loading={actionPending}
                        disabled={actionPending || (!selectedAssets.length && !assetVirtualFolder)}
                        onClick={(e) => {
                            e.stopPropagation();
                            onHandleClickSubmit();
                        }}
                        color={`primary`}
                        size={"medium"}
                    >
                        {t(`common::label::Add`)}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DialogAddLinkedAssets;
