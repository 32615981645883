import { ASSET_PREVIEW_STATUS, MEDIA_TYPES } from "@constants";
import React from "react";
import Tooltip from "@components_v2/Tooltip/tooltip";
import { Box, Button, CircularProgress, Grid, IconButton, Stack, Typography } from "@mzc-pdc/ui";
import { IconReply, ImageDefaultFileDark, ImageDefaultImageDark, ImageErrorInfo } from "@mzc-cloudplex/icons";
import theme from "@styles/theme";
import { Trans, useTranslation } from "react-i18next";

const AssetPreviewByStatus = ({ previewStatus, mediaType, width, height }) => {
    const { t } = useTranslation();
    const DefaultIcon = () => {
        let Component = null;

        if (mediaType === MEDIA_TYPES.FILE) {
            Component = ImageDefaultFileDark;
        } else if (mediaType === MEDIA_TYPES.IMAGE) {
            Component = ImageDefaultImageDark;
        } else return null;

        return (
            <Box sx={{ position: "static" }}>
                <Component width={width} height={height} />
            </Box>
        );
    };
    const PreviewProgressIcon = () => {
        return (
            <Box
                sx={{ backgroundColor: theme.palette.greySecondary[600] }}
                px={theme.spacing(1.5)}
                py={theme.spacing(0.25)}
            >
                <Typography color={theme.palette.common.white}>
                    {t(`common::msg::Preview image Creating...`)}
                </Typography>
            </Box>
        );
    };

    const PreviewErrorIcon = () => {
        return (
            <Stack direction={"column"} gap={theme.spacing(1.5)} alignItems={"center"}>
                <ImageErrorInfo width={60} height={60} />
                <Typography variant={"body1_500"}>
                    <Trans
                        i18nKey={`common::msg::Failed to load preview image. <NewLine/>please try again.`}
                        components={{
                            NewLine: <br />,
                        }}
                    />
                </Typography>
                {/*TODO: 백엔드 개발 후 반영 필요*/}
                {/*<Button*/}
                {/*    color={"secondary"}*/}
                {/*    variant={"outlined"}*/}
                {/*    startIcon={<IconReply size={16} />}*/}
                {/*    sx={{ backgroundColor: theme.palette.common.white }}*/}
                {/*>*/}
                {/*    {t(`common::label::Try again`)}*/}
                {/*</Button>*/}
            </Stack>
        );
    };

    switch (previewStatus) {
        case ASSET_PREVIEW_STATUS.IN_PROGRESS:
            return (
                <Stack
                    direction={"column"}
                    gap={theme.spacing(1)}
                    justifyContent={"center"}
                    alignItems={"center"}
                    height={"100%"}
                >
                    <DefaultIcon />
                    <PreviewProgressIcon />
                </Stack>
            );
        case ASSET_PREVIEW_STATUS.ERROR:
            return <PreviewErrorIcon />;
        case ASSET_PREVIEW_STATUS.NOT_SUPPORTED:
            return <DefaultIcon />;
        default:
            return <DefaultIcon />;
    }
};

export default AssetPreviewByStatus;
