import { useCallback, useEffect, useState } from "react";

const useActionPending = (id = null) => {
    const [pending, setPending] = useState(id ? false : {});

    const setActionPending = useCallback(
        (key = null) => {
            if (key) setPending({ ...pending, [key]: true });
            else setPending(true);
        },
        [pending],
    );

    const disableActionPending = useCallback(
        (key = null) => {
            if (key) setPending({ ...pending, [key]: false });
            else setPending(false);
        },
        [pending],
    );

    const getActionPending = useCallback(
        (key) => {
            return pending[key];
        },
        [pending],
    );

    useEffect(() => {
        if (id) {
            setPending(false);
        }
    }, []);

    return {
        getActionPending,
        pending,
        setActionPending,
        disableActionPending,
    };
};

export default useActionPending;
