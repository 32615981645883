import { isUndefined } from "lodash";
import { axios, axiosWithoutHeaders } from "./axiosWrapper";
import compareVersions, { VERSION_SAFARI_ENCODING_FOR_PRESIGN_AND_UPLOAD } from "@cores/version";
import { updateQueue, updateUploadFile, uploadFilesFinished } from "@modules/uploadQueue";
import { addPausedPollingApis } from "@modules/pausedPollingApis";
import { UPLOAD_STATUS } from "@constants";

const MINIMUM_CHUNK_SIZE = 1024 * 1000 * 20; // 20MB
const MAXIMUM_SINGLE_FILE_UPLOAD_SIZE = 1024 * 1000 * 1000; // 5 GB
const MAXIMUM_MULTIPART_COUNT = 10000; // AWS S3 Multipart 파트 개수는 1만개가 최대이다.

export const RESOURCE_TYPES = {
    SOURCES: "SOURCES",
};

// ###########
// # CAUTION #
// ##########
// 1.0.14 버전 이상의 Space만 사용할 수 있음.
// 호출하는 곳에서 분기 처리 필요함.
const presignUrl = async (
    apiEndpoint,
    stageVersion,
    { resourceType, resourceId, threadId, bucket, prefix, file, projectId },
) => {
    let requestBody = {
        resourceType,
        resourceId,
        threadId,
        bucket,
        prefix,
        fileName: file.name,
        contentType: file.type || "application/octet-stream",
    };
    let headers = {};
    if (projectId) headers.projectId = projectId;
    if (compareVersions(stageVersion, VERSION_SAFARI_ENCODING_FOR_PRESIGN_AND_UPLOAD) >= 0) {
        requestBody = Buffer.from(encodeURIComponent(JSON.stringify(requestBody))).toString("base64");
        headers = {
            ...headers,
            "x-mzc-content-encoding": "base64",
        };
    }
    const response = await axios.post(`${apiEndpoint}/aws/s3/uploads/presigned-url`, requestBody, {
        headers,
    });
    return response.data;
};

const createMultipartUpload = async (
    apiEndpoint,
    stageVersion,
    { resourceType, resourceId, threadId, projectId, bucket, prefix, file, partSize, localFilePath, fileSize },
) => {
    let requestBody = {
        resourceType,
        resourceId,
        threadId,
        bucket,
        prefix,
        fileName: file.name,
        contentType: file.type || "application/octet-stream",
        partSize,
        localFilePath,
        fileSize,
    };
    let headers = {};
    if (projectId) headers.projectId = projectId;

    if (compareVersions(stageVersion, VERSION_SAFARI_ENCODING_FOR_PRESIGN_AND_UPLOAD) >= 0) {
        requestBody = Buffer.from(encodeURIComponent(JSON.stringify(requestBody))).toString("base64");
        headers = {
            ...headers,
            "x-mzc-content-encoding": "base64",
        };
    }
    const response = await axios.post(`${apiEndpoint}/aws/s3/uploads/multipart`, requestBody, {
        headers,
    });
    return response.data;
};

const presignUploadPart = async (
    apiEndpoint,
    stageVersion,
    projectId,
    { bucket, key, uploadId, partNumber, cancelToken },
) => {
    let requestBody = {
        bucket,
        key,
        partNumber,
    };
    let headers = {};
    if (projectId) headers.projectId = projectId;

    if (compareVersions(stageVersion, VERSION_SAFARI_ENCODING_FOR_PRESIGN_AND_UPLOAD) >= 0) {
        requestBody = Buffer.from(encodeURIComponent(JSON.stringify(requestBody))).toString("base64");
        headers = {
            ...headers,
            "x-mzc-content-encoding": "base64",
        };
    }
    const response = await axios.post(`${apiEndpoint}/aws/s3/uploads/multipart/${uploadId}/parts`, requestBody, {
        headers,
        cancelToken,
    });
    return response.data;
};

const completeMultipartUpload = async (apiEndpoint, stageVersion, projectId, { bucket, key, uploadId, parts }) => {
    let requestBody = {
        bucket,
        key,
        parts,
    };
    let headers = {};
    if (projectId) headers.projectId = projectId;

    if (compareVersions(stageVersion, VERSION_SAFARI_ENCODING_FOR_PRESIGN_AND_UPLOAD) >= 0) {
        requestBody = Buffer.from(encodeURIComponent(JSON.stringify(requestBody))).toString("base64");
        headers = {
            ...headers,
            "x-mzc-content-encoding": "base64",
        };
    }
    const response = await axios.post(`${apiEndpoint}/aws/s3/uploads/multipart/${uploadId}`, requestBody, {
        headers,
    });
    return response.data;
};

const abortMultipartUpload = async (apiEndpoint, stageVersion, projectId, { bucket, key, uploadId }) => {
    let requestBody = {
        bucket,
        key,
        uploadId,
    };
    let headers = {};
    if (projectId) headers.projectId = projectId;

    if (compareVersions(stageVersion, VERSION_SAFARI_ENCODING_FOR_PRESIGN_AND_UPLOAD) >= 0) {
        requestBody = Buffer.from(encodeURIComponent(JSON.stringify(requestBody))).toString("base64");
        headers = {
            ...headers,
            "x-mzc-content-encoding": "base64",
        };
    }
    const response = await axios.delete(`${apiEndpoint}/aws/s3/uploads/multipart/${uploadId}`, {
        data: requestBody,
        headers,
    });
    return response.data;
};

const uploadPart = async (url, file, contentType, index, cancelToken, uploadProgress) => {
    return await axiosWithoutHeaders.put(url, file, {
        headers: { "Content-Type": contentType || "application/octet-stream" },
        onUploadProgress: (event) => {
            uploadProgress(event, index);
        },
        cancelToken,
    });
};

/**
 * 일반 업로드. 지정된 URL로 파일을 PUT 한다.
 */
const runUpload = async ({
    file,
    presignedUrl,
    apiEndpoint,
    stageVersion,
    resourceType,
    resourceId,
    threadId,
    projectId,
    bucket,
    prefix,
    cancelToken,
    onUploadProgress = () => {},
    onComplete = () => {},
    onCancel = () => {},
    onError = (err) => {},
}) => {
    try {
        if (!presignedUrl) {
            const presignResponse = await presignUrl(apiEndpoint, stageVersion, {
                resourceType,
                resourceId,
                threadId,
                projectId,
                file,
                bucket,
                prefix,
            });
            presignedUrl = presignResponse.uploadUrl;
        }
        file.uploadUrl = presignedUrl;
        axiosWithoutHeaders
            .put(presignedUrl, file, {
                headers: {
                    "Content-Type": file.type || "application/octet-stream",
                },
                onUploadProgress,
                cancelToken,
            })
            .then(({ data }) => {
                onComplete({
                    destinationUrl: presignedUrl.substring(0, presignedUrl.indexOf("?")),
                });
            })
            .catch((thrown) => {
                if (axios.isCancel(thrown)) {
                    onCancel();
                } else {
                    onError(thrown);
                }
            });
    } catch (e) {
        if (axios.isCancel(e)) {
            onCancel();
        } else {
            onError(e);
        }
    }
};

const uploadParts = async (apiEndpoint, stageVersion, projectId, count, uploadItems) => {
    try {
        const parts = uploadItems.splice(0, count);
        let copiedParts = [...parts];
        let uploadedFiles = [];

        do {
            const uploadFiles = await Promise.allSettled(
                copiedParts.map(async (part, index) => {
                    try {
                        const {
                            bucket,
                            key,
                            uploadId,
                            partNumber,
                            blob,
                            cancelToken,
                            partUploadProgress,
                            contentType,
                        } = part;
                        const presignResponse = await presignUploadPart(apiEndpoint, stageVersion, projectId, {
                            bucket,
                            key,
                            uploadId,
                            partNumber,
                            cancelToken,
                        });
                        const uploadResponse = await uploadPart(
                            presignResponse.uploadUrl,
                            blob,
                            contentType,
                            partNumber,
                            cancelToken,
                            partUploadProgress,
                        );
                        return {
                            ETag: uploadResponse.headers.etag,
                            PartNumber: partNumber,
                        };
                    } catch (error) {
                        throw error;
                    }
                }),
            );

            copiedParts = [];
            uploadFiles.forEach((uploadPromise, i) => {
                if (uploadPromise.status === "rejected") {
                    if (!navigator.onLine) {
                        throw uploadPromise.reason;
                    } else {
                        if (axios.isCancel(uploadPromise.reason)) {
                            throw uploadPromise.reason;
                        } else copiedParts.push(parts[i]);
                    }
                } else {
                    uploadedFiles.push(uploadPromise.value);
                }
            });
        } while (copiedParts.length > 0);

        if (uploadItems.length === 0) {
            return uploadedFiles;
        } else {
            const restList = await uploadParts(apiEndpoint, stageVersion, projectId, count, uploadItems);
            return uploadedFiles.concat(restList);
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const runMultipartUpload = async ({
    stageVersion,
    uploadId,
    resourceType,
    resourceId,
    threadId,
    projectId,
    bucket,
    prefix,
    file,
    apiEndpoint,
    cancelToken,
    onUploadProgress = () => {},
    onComplete = () => {},
    onCancel = () => {},
    onError = (err) => {},
    dispatch,
    progressKey,
}) => {
    let key = "";
    try {
        if (prefix && prefix.length > 0) {
            key = `${prefix}/${file.name}`;
        } else {
            key = file.name;
        }
        const fileSize = file.size;
        let chunkSize = fileSize / MAXIMUM_MULTIPART_COUNT;
        if (chunkSize < MINIMUM_CHUNK_SIZE) {
            chunkSize = MINIMUM_CHUNK_SIZE;
        }

        if (!uploadId) {
            const createMultipartUploadResponse = await createMultipartUpload(apiEndpoint, stageVersion, {
                resourceType,
                resourceId,
                threadId,
                projectId,
                file,
                bucket,
                prefix,
                partSize: chunkSize,
                localFilePath: file.id,
                fileSize: file.size,
            });
            uploadId = createMultipartUploadResponse.uploadId;
            bucket = createMultipartUploadResponse.bucket;
            key = createMultipartUploadResponse.key;
            file.uploadId = uploadId;
        }

        const chunkCount = Math.floor(fileSize / chunkSize) + 1;
        let total = [];
        const partUploadProgress = async (event, index) => {
            total[index] = event.loaded;
            const totalLoaded = total.reduce((a, c) => a + c);
            const newEvent = {
                loaded: totalLoaded,
                total: fileSize,
            };
            onUploadProgress(newEvent);
        };

        const uploadItems = [];
        for (let i = 1; i <= chunkCount; i++) {
            let start = (i - 1) * chunkSize;
            let end = i * chunkSize;
            let blob = i <= chunkCount ? file.slice(start, end) : file.slice(start);
            uploadItems.push({
                bucket,
                key,
                uploadId,
                partNumber: i,
                blob,
                cancelToken,
                partUploadProgress,
                contentType: file.type || "application/octet-stream",
            });
        }
        const uploadedParts = await uploadParts(apiEndpoint, stageVersion, projectId, 10, uploadItems); // 10개씩 끊어서 업로드
        if (uploadedParts.length !== chunkCount) {
            throw new Error(`uploaded parts count(${uploadedParts.length}) does not match chunkCount(${chunkCount})`);
        }
        const completeResponse = await completeMultipartUpload(apiEndpoint, stageVersion, projectId, {
            bucket,
            key,
            uploadId,
            parts: uploadedParts.sort(function (a, b) {
                return a.PartNumber - b.PartNumber;
            }),
        });
        onComplete({
            destinationUrl: completeResponse.destinationUrl,
        });
    } catch (error) {
        if (!navigator.onLine) {
            dispatch(
                addPausedPollingApis({
                    type: "UPLOAD_FILES",
                    params: {
                        file,
                        parameters: {
                            stageVersion,
                            apiEndpoint,
                            uploadId,
                            resourceType,
                            resourceId,
                            projectId,
                            bucket,
                            prefix,
                            progressKey,
                        },
                        cancelToken,
                    },
                }),
            );
        } else if (uploadId) {
            try {
                await abortMultipartUpload(apiEndpoint, stageVersion, projectId, {
                    bucket,
                    key,
                    uploadId,
                });
            } catch (innerException) {
                // ignored
            }
        }
        if (axios.isCancel(error)) {
            onCancel();
        } else {
            onError(error);
        }
    }
};

//맨 처음 RightSidebar 마운트 시 멀티파트 안된 잡 조회 -> 있으면 각각 abort => rightsidebar
//online 이벤트 연결 -> rightsidebar, network error에 대한 처리 -> create 이후 작업들에 추가

//안끝난 멀티파트 목록 조회(멀티파트 여러개일 수 있음. 파일을 따로 찾아오거나 uploadQueue에 있는 files를 다 파라미터로 내려보내야함) -> runUnfinishedMultipartUpload
//각각의 멀티파트에 대해 멀티파트 업로드 로직 -> uploadParts
//각각의 멀티파트에 대해 상세조회를 통해 완료된 파트에 대한 정보 조회 => uploadParts(굳이 조회 안하고 실패한 파트들을 따로 모아둠)
//필터처리한 미완료된 파트에 대해서 업로드 => uploadParts
//업로드 완료 후 종료
//오류 시 abort

//polling 저장시 파일데이터, 파트사이즈, 사이즈, 이름 저장해두기(혹시 모르니)
const runUnfinishedMultipartUpload = async ({
    stageVersion,
    uploadId,
    resourceType,
    resourceId,
    projectId,
    bucket,
    prefix,
    file,
    apiEndpoint,
    cancelToken,
    onUploadProgress = () => {},
    onComplete = () => {},
    onCancel = () => {},
    onError = (err) => {},
    dispatch,
    progressKey,
}) => {
    let key = "";
    try {
        //멀티파트 상세 요청
        const finishedParts = await getDetailUnfinishedMultipartUpload(apiEndpoint, projectId, uploadId);

        if (prefix && prefix.length > 0) {
            key = `${prefix}/${file.name}`;
        } else {
            key = file.name;
        }

        const fileSize = file.size;
        let chunkSize = finishedParts.partSize;
        const chunkCount = Math.floor(fileSize / chunkSize) + 1;

        //파일 경로, 사이즈 비교
        //지금 경로는 안되니까 이름, 파일 업로드시 생성되는 고유 아이디
        //if(file.id === unfinishedParts.localFilePath)
        if (file.id !== finishedParts.localFilePath) {
            throw new Error("not same file");
        }

        let total = [];
        const partUploadProgress = async (event, index) => {
            total[index] = event.loaded;
            const totalLoaded = total.reduce((a, c) => a + c);
            const newEvent = {
                loaded: totalLoaded,
                total: fileSize,
            };
            onUploadProgress(newEvent);
        };

        const uploadItems = [];
        for (let i = 1; i <= chunkCount; i++) {
            if (
                finishedParts.parts.some(({ PartNumber }) => {
                    return PartNumber === i;
                })
            )
                continue;

            let start = (i - 1) * chunkSize;
            let end = i * chunkSize;
            let blob = i <= chunkCount ? file.slice(start, end) : file.slice(start);
            uploadItems.push({
                bucket,
                key,
                uploadId,
                partNumber: i,
                blob,
                cancelToken,
                partUploadProgress,
                contentType: file.type || "application/octet-stream",
            });
        }

        const uploadedParts = await uploadParts(apiEndpoint, stageVersion, projectId, 10, uploadItems); // 10개씩 끊어서 업로드
        const completeResponse = await completeMultipartUpload(apiEndpoint, stageVersion, projectId, {
            bucket,
            key,
            uploadId,
            parts: uploadedParts.concat(finishedParts.parts).sort(function (a, b) {
                return a.PartNumber - b.PartNumber;
            }),
        });
        onComplete({
            destinationUrl: completeResponse.destinationUrl,
        });
    } catch (error) {
        if (!navigator.onLine) {
            dispatch(
                addPausedPollingApis({
                    type: "UPLOAD_FILES",
                    params: {
                        file,
                        parameters: {
                            stageVersion,
                            apiEndpoint,
                            uploadId,
                            resourceType,
                            resourceId,
                            projectId,
                            bucket,
                            prefix,
                            progressKey,
                        },
                        cancelToken,
                    },
                }),
            );
        } else if (uploadId) {
            try {
                await abortMultipartUpload(apiEndpoint, stageVersion, {
                    bucket,
                    key,
                    uploadId,
                });
            } catch (innerException) {
                // ignored
            }
        }
        if (axios.isCancel(error)) {
            onCancel();
        } else {
            onError(error);
        }
    }
};

export const getUnfinishedMultipartUploads = async (apiEndpoint, projectId) => {
    try {
        let queries = { nextToken: undefined, resourceType: undefined };
        let results = [];

        do {
            const response = await axios.get(`${apiEndpoint}/aws/s3/uploads/multipart`, {
                headers: { projectId },
                params: queries,
            });

            if (!response.data?.results) break;
            results = [...results, ...response.data?.results];
            queries.nextToken = response.data?.nextToken;
        } while (queries.nextToken);

        return results;
    } catch (error) {
        console.error("error", error);
    }
};

const getDetailUnfinishedMultipartUpload = async (apiEndpoint, projectId, uploadId) => {
    try {
        let queries = { nextToken: undefined };
        let results = {};

        do {
            const response = await axios.get(`${apiEndpoint}/aws/s3/uploads/multipart/${uploadId}`, {
                params: queries,
                headers: { projectId },
            });

            const { fileSize, localFilePath, partSize, parts } = response.data;

            results = {
                fileSize,
                localFilePath,
                partSize,
                parts: results.parts ? [...results.parts, ...parts] : parts,
            };
            queries.nextToken = response.data?.nextToken;
        } while (queries.nextToken);

        return results;
    } catch (error) {
        console.error("error", error);
    }
};

export const abortPreviousMultipartUploads = async (apiEndpoint, stageVersion, projectId) => {
    try {
        const unfinishedUploads = await getUnfinishedMultipartUploads(apiEndpoint, projectId);
        if (unfinishedUploads?.length)
            await Promise.allSettled(
                unfinishedUploads.forEach(async (upload) => {
                    try {
                        const { bucket, key, uploadId } = upload;

                        await abortMultipartUpload(apiEndpoint, stageVersion, {
                            bucket,
                            key,
                            uploadId,
                        });
                    } catch (innerException) {
                        // ignored
                    }
                }),
            );
    } catch (error) {
        console.error("error occured", error);
    }
};

/**
 * @param {string} uploadId: 다른 곳에서 Multipart upload ID를 획득해놓은 경우 기입
 * @param {string} presignedUrl: 다른 곳에서 Presigned URL을 획득해놓은 경우 기입
 * @param {string} resourceType: SOURCE - 추후 점진적으로 ASSET | JOB | RESOURCE 등으로 늘려가서 업로드는 하나의 API만 보게 할 것임.
 * @param {string} resourceId: 특정 리소스를 타겟팅하는 경우 ID. ex) Asset ID
 * @param {string} bucket: 버킷 이름
 * @param {string} prefix: 파일 이름 앞에 붙혀질 폴더 경로
 */
export const upload = async (
    file,
    {
        stageVersion,
        apiEndpoint,
        uploadId,
        presignedUrl,
        resourceType,
        resourceId,
        threadId,
        projectId,
        bucket,
        prefix,
        progressKey,
    },
    cancelToken,
    onUploadProgress = () => {},
    onComplete = () => {},
    onCancel = () => {},
    onError = () => {},
    dispatch,
) => {
    const params = {
        stageVersion,
        uploadId,
        presignedUrl,
        resourceType,
        resourceId,
        threadId,
        projectId,
        bucket,
        prefix,
        file,
        apiEndpoint,
        cancelToken,
        onUploadProgress,
        onComplete,
        onCancel,
        onError,
        dispatch,
        progressKey,
    };
    if (file.size > MAXIMUM_SINGLE_FILE_UPLOAD_SIZE) {
        await runMultipartUpload(params);
    } else {
        await runUpload(params);
    }
};

export const runUnfinishedUpload = async ({ dispatch, file, parameters, cancelToken }) => {
    const {
        stageVersion,
        apiEndpoint,
        uploadId,
        resourceType,
        resourceId,
        projectId,
        bucket,
        prefix,
        progressKey,
        fileIndex,
    } = parameters;

    const params = {
        stageVersion,
        uploadId,
        resourceType,
        resourceId,
        projectId,
        bucket,
        prefix,
        file,
        apiEndpoint,
        cancelToken,
        onUploadProgress: (event) => {
            if (event.loaded && event.total) {
                updateUploadFile("Local", progressKey, fileIndex, {
                    //Note: locationType이 S3이면?? ()
                    uploadedPercentage: Math.round((event.loaded * 100) / event.total),
                    status: "UPLOADING",
                })(dispatch);
            }
            dispatch(updateQueue("Local", progressKey, { status: UPLOAD_STATUS.UPLOADING, isStarted: true }));
        },
        onComplete: (data) => {
            // onComplete
            const result = {
                url: data.destinationUrl,
                status: "COMPLETE",
            };
            dispatch(uploadFilesFinished({ locationType: "Local", key: progressKey, status: UPLOAD_STATUS.COMPLETE }));
        },
        onCancel: () => {
            // onCancel
            const result = {
                status: "CANCELED",
            };
            dispatch(uploadFilesFinished({ locationType: "Local", key: progressKey, status: UPLOAD_STATUS.CANCELED }));
        },
        onError: () => {
            // onError
            updateUploadFile("Local", progressKey, fileIndex, {
                uploadedPercentage: 0,
                status: "ERROR",
            })(dispatch);
            const result = {
                status: "ERROR",
            };
            dispatch(uploadFilesFinished({ locationType: "Local", key: progressKey, status: UPLOAD_STATUS.ERROR }));
        },
        dispatch,
        progressKey,
    };

    await runUnfinishedMultipartUpload(params);
};

export default upload;
