import React, { useCallback, useEffect } from "react";
import { Badge, Divider, Grid, Avatar, List, ListItem, ListItemText, Link, CardMedia } from "@mzc-pdc/ui";
import convertToByte from "@cores/convertToByte";
import Typography from "@components_v2/typography/typography";
import theme from "@styles/theme";
import {
    IconArchive,
    IconOpenInNew,
    ImageDefaultAudioDark,
    ImageDefaultImageDark,
    ImageDefaultVideoDark,
} from "@mzc-cloudplex/icons";
import TableCard from "@components_v2/card/table-card";
import CollectionThumbnail from "@features/thumbnail/collection-thumbnail";
import AssetThumbnail from "@features/thumbnail/asset-thumbnail";
import { useInView } from "react-intersection-observer";
import StatusBadge from "@components_v2/status-badge/status-badge";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import { getProjectRandomColor } from "@utils/get-random-color";
import { Link as RouterLink } from "react-router-dom";
import formatDuration from "@cores/formatDuration";
import ContentThumbnail from "@features/thumbnail/content-thumbnail";
import AssetTypeFlag from "@components_v2/icon-type/asset/asset-type-flag";
import ContentTypeFlag from "@components_v2/icon-type/content/content-type-flag";
import { MEDIA_TYPES } from "@constants";

const AutocompleteSmallTableList = ({
    data,
    columns,
    type,
    deleteButton,
    onClickName,
    listStyle,
    onFetchData = () => {},
    noDataText,
    divider,
    height,
}) => {
    const { t } = useTranslation();
    const [targetRef, inView] = useInView();

    const getNameThumbnail = useCallback(
        (type, option, column) => {
            switch (type) {
                case "asset":
                    let src = option.previews?.[0]?.accessUrl;
                    if (!src) {
                        if (option?.mediaType === MEDIA_TYPES.IMAGE) {
                            src = option.accessUrls?.[0] ?? option.elements?.[0]?.accessUrl;
                        } else if (option.mediaType === MEDIA_TYPES.VIDEO) src = option.thumbnails?.[0]?.accessUrl;
                    }

                    return (
                        <AssetThumbnail
                            variant={"table"}
                            src={src}
                            mediaType={option.mediaType}
                            sx={{ ...column?.style?.thumbnail }}
                        />
                    );
                case "content":
                    return (
                        <ContentThumbnail
                            variant={"table"}
                            src={option?.primaryPoster?.accessUrl ?? option?.primaryImage?.accessUrl}
                            minWidth={40}
                            width={40}
                            height={40}
                            contentType={option.contentType}
                        />
                    );
                case "collection":
                    return <CollectionThumbnail collection={option} size={"xsmall"} variant={"table"} />;
            }
        },
        [data],
    );

    const getNameIcon = useCallback(
        (type, option) => {
            switch (type) {
                case "asset":
                    return <AssetTypeFlag minWidth={22} width={22} height={16} type={option.mediaType} />;
                case "content":
                    return <ContentTypeFlag minWidth={22} width={22} height={16} type={option.contentType} />;
            }
        },
        [data],
    );

    const getTableSubValueByColumn = useCallback(
        (id, option) => {
            switch (id) {
                case "extensions":
                    return (
                        <Typography variant={"body3_400"} component={"span"} color={theme.palette.secondary[600]}>
                            {`.${option?.extensions}`}
                        </Typography>
                    );
                case "duration":
                    return (
                        <Typography
                            variant={"body3_400"}
                            component={"span"}
                            color={theme.palette.secondary[600]}
                            sx={{ minWidth: "120px" }}
                        >
                            {formatDuration(option?.duration)}
                        </Typography>
                    );
                case "mediaType":
                    return (
                        <Typography
                            variant={"body3_400"}
                            component={"span"}
                            color={theme.palette.secondary[600]}
                            sx={{ minWidth: "120px" }}
                        >
                            {option?.mediaType ?? "-"}
                        </Typography>
                    );
                case "type":
                    return (
                        <Typography
                            variant={"body3_400"}
                            component={"span"}
                            color={theme.palette.secondary[600]}
                            sx={{ minWidth: "120px" }}
                        >
                            {option?.type ?? "-"}
                        </Typography>
                    );
                case "size":
                    return (
                        <Typography
                            variant={"body3_400"}
                            component={"span"}
                            color={theme.palette.secondary[600]}
                            sx={{ minWidth: "80px" }}
                        >
                            {option?.size ? convertToByte(option?.size) : "-"}
                        </Typography>
                    );
                default:
                    return null;
            }
        },
        [data],
    );

    const getTableValueByColumn = useCallback(
        (id, option, column) => {
            switch (id) {
                case "desiredStatus":
                case "status":
                    return (
                        <Grid container justifyContent={"center"}>
                            <StatusBadge status={option?.status?.toUpperCase()} />
                        </Grid>
                    );
                case "name":
                    return (
                        <TableCard
                            thumbnail={getNameThumbnail(type, option, column)}
                            name={{
                                name: onClickName ? (
                                    <Link to={onClickName} noWrap>
                                        {option.name}
                                    </Link>
                                ) : (
                                    <Typography variant={"body1_500"} component={"span"} noWrap>
                                        {option?.name}
                                    </Typography>
                                ),
                                startIcon: getNameIcon(type, option),
                                endIcon:
                                    option.archiveStatus === "ARCHIVED" ? (
                                        <Grid item ml={1}>
                                            <IconArchive size={16} />
                                        </Grid>
                                    ) : undefined,
                            }}
                            subName={{
                                name: (
                                    <Grid
                                        container
                                        flexDirection={"row"}
                                        sx={{
                                            "& span:not(:last-child)": {
                                                pr: 5,
                                                borderRight: `solid 1px ${theme.palette.secondary[100]}`,
                                            },
                                            "& span:not(:first-of-type)": {
                                                pl: 1.5,
                                            },
                                        }}
                                    >
                                        {columns
                                            .filter((c) => c.canSubColumn)
                                            .map((el) => getTableSubValueByColumn(el.id, option))}
                                    </Grid>
                                ),
                            }}
                        />
                    );
                case "projectName":
                    return (
                        <Grid container flexDirection={"row"} alignItems={"center"}>
                            <Avatar
                                sx={{
                                    width: 32,
                                    height: 32,
                                    fontSize: 16,
                                    fontWeight: `bold`,
                                    bgColor: getProjectRandomColor(option?.id),
                                    mr: 1,
                                }}
                            >
                                {option.name.charAt(0)}
                            </Avatar>

                            {/* 1.0.81 Note 프로젝트 누르면 어디로 이동? */}
                            <Link
                                target="_blank"
                                component={RouterLink}
                                sx={{
                                    color: theme.palette.secondary[800],
                                    fontWeight: 500,
                                    display: `inline-flex`,
                                    webkitLineClamp: `2`,
                                    textOverflow: `ellipsis`,
                                    webkitBoxOrient: `vertical`,
                                    maxHeight: 45,
                                    overflow: `hidden`,
                                    textDecoration: "none",
                                    "&:hover": {
                                        "#external-link-icon": {
                                            display: "block",
                                        },
                                    },
                                }}
                                noWrap
                                to={onClickName(option.id)}
                            >
                                {option.name}
                                <Grid item id={"external-link-icon"} sx={{ display: "none" }}>
                                    <IconOpenInNew size={16} />
                                </Grid>
                            </Link>
                        </Grid>
                    );
                case "size":
                    return option?.size ? convertToByte(option?.size) : "-";

                case "count":
                    return (
                        <Grid item sx={{ textAlign: `right` }}>
                            <Badge
                                sx={{
                                    ".MuiBadge-badge": {
                                        position: `static`,
                                        minWidth: "30px",
                                        height: 18,
                                        transform: `none`,
                                        backgroundColor: option?.assetTotalCount
                                            ? theme.palette.success[600]
                                            : theme.palette.secondary[500],
                                        color: theme.palette.common.white,
                                        fontSize: theme.typography.body3_700,
                                    },
                                }}
                                badgeContent={
                                    option.assetTotalCount > 99 ? "99+" : option?.assetTotalCount?.toString() ?? "0"
                                }
                            />
                        </Grid>
                    );
                case "duration":
                    return <Grid item>{formatDuration(option?.duration)}</Grid>;
                case "extensions":
                    return <Grid item>.{option?.[id]}</Grid>;
                default:
                    return option?.[id];
            }
        },
        [data],
    );

    useEffect(() => {
        if (inView) {
            onFetchData();
        }
    }, [inView, targetRef]);

    if (data?.length > 0)
        return (
            <Grid
                item
                sx={{
                    borderRadius: `4px`,
                    border: `1px solid ${theme.palette.secondary[100]}`,
                    // flexGrow: 1,
                    maxHeight: height ?? null,
                }}
            >
                <List sx={{ padding: "0", overflowY: "auto", maxHeight: "100%", ...listStyle }}>
                    {data?.map((item, index) => (
                        <>
                            {index > 0 && <Divider />}
                            <ListItem
                                style={{
                                    width: "100%",
                                    padding: `${deleteButton ? `4px 56px 4px 0px` : `4px 12px 4px 0px`}`,
                                }}
                                data-id={item.id}
                                secondaryAction={deleteButton && deleteButton}
                            >
                                <ListItemText
                                    mt={1}
                                    mb={1}
                                    primary={
                                        <Grid container alignItems={"center"} direction="row" flexWrap={"nowrap"}>
                                            {columns
                                                .filter((c) => !c.canSubColumn)
                                                .map((column, index) => (
                                                    <>
                                                        {/*{index > 0 && divider && (*/}
                                                        {/*    <Divider*/}
                                                        {/*        orientation="vertical"*/}
                                                        {/*        variant="middle"*/}
                                                        {/*        flexItem*/}
                                                        {/*        style={{ marginRight: "16px" }}*/}
                                                        {/*    />*/}
                                                        {/*)}*/}
                                                        <Grid
                                                            item
                                                            sx={{
                                                                maxWidth: column.id === "name" ? "376px" : "auto",
                                                                ...column.style?.custom,
                                                            }}
                                                        >
                                                            {getTableValueByColumn(column.id, item, column)}
                                                        </Grid>
                                                    </>
                                                ))}
                                        </Grid>
                                    }
                                />
                            </ListItem>
                        </>
                    ))}
                    {data && <div ref={targetRef} id={"loadMoreDiv"} />}
                </List>
            </Grid>
        );
    if (data?.length === 0 && noDataText)
        return (
            <Grid
                container
                sx={{
                    height: "60px",
                    p: 1.5,
                    borderRadius: `4px`,
                    border: `1px solid ${theme.palette.secondary[100]}`,
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Typography>{t(`common::msg::${noDataText}`, noDataText)}</Typography>
            </Grid>
        );
    return null;
};

export default AutocompleteSmallTableList;
