import { Grid, MenuItem, Select as MuiSelect } from "@mzc-pdc/ui";
import Typography from "@components_v2/typography/typography";

const SelectWithIcons = ({ items, itemProps, ...props }) => {
    return (
        <MuiSelect {...props} sx={{ "..MuiSelect-outlined": { borderColor: "red" } }}>
            {items &&
                items.map((el, i) => (
                    <MenuItem key={i} value={el.value}>
                        <Grid container flexDirection={"row"} gap={1}>
                            <Grid item>{el.startIcon}</Grid>
                            <Grid item>
                                <Typography variant={itemProps.variant} color={itemProps.color}>
                                    {el.label}
                                </Typography>
                            </Grid>
                        </Grid>
                    </MenuItem>
                ))}
        </MuiSelect>
    );
};

export default SelectWithIcons;
