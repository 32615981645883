const VideoContentColumn = [
    {
        id: "desiredStatus",
        label: "status",
        style: { custom: { padding: "0 12px", width: 32 } },
        disabled: true,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: false,
    },
    {
        id: "name",
        label: "Name",
        style: { custom: { flex: 1 } },
        disabled: true,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: false,
    },
    {
        id: "duration",
        label: "Duration",
        style: { custom: { width: 100 } },
        disabled: false,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: true,
    },
];

const MusicContentColumn = [
    {
        id: "desiredStatus",
        label: "status",
        style: { custom: { padding: "0 12px", width: 32 } },
        disabled: true,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: false,
    },
    {
        id: "name",
        label: "Name",
        style: { custom: { flex: 1 } },
        disabled: true,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: false,
    },
];

const PeopleContentColumn = [
    {
        id: "desiredStatus",
        label: "status",
        style: { custom: { padding: "0 12px", width: 32 } },
        disabled: true,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: false,
    },
    {
        id: "name",
        label: "Name",
        style: { custom: { flex: 1 } },
        disabled: true,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: false,
    },
];

const PhotoContentColumn = [
    {
        id: "desiredStatus",
        label: "status",
        style: { custom: { padding: "0 12px", width: 32 } },
        disabled: true,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: false,
    },
    {
        id: "name",
        label: "Name",
        style: { custom: { flex: 1 } },
        disabled: true,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: false,
    },
];

const CustomContentColumn = [
    {
        id: "desiredStatus",
        label: "status",
        style: { custom: { padding: "0 12px", width: 32 } },
        disabled: true,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: false,
    },
    {
        id: "name",
        label: "Name",
        style: { custom: { flex: 1 } },
        disabled: true,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: false,
    },
];

export { VideoContentColumn, CustomContentColumn, PhotoContentColumn, MusicContentColumn, PeopleContentColumn };
