import {handleActions, createAction} from 'redux-actions';
import {
    axios,
    axiosWithoutHeaders
} from "../cores/axiosWrapper";

function getScheduleAPI(stageId, apiEndpoint, projectId, channelId, scheduleId) {
    const params = {

    };
    return axios.get(`${apiEndpoint}/channels/${channelId}/schedules/${scheduleId}`, {
        params,
        headers : {
            stageId,
            projectId
        }
    });
}

function getSchedulesAPI(stageId,apiEndpoint, projectId, channelId) {
    const params = {

    };
    return axios.get(`${apiEndpoint}/channels/${channelId}/schedules`, {
        params,
        headers : {
            stageId,
            projectId
        }
    });
}

function putScheduleAPI(stageId, apiEndpoint, projectId, channelId, schedule) {
    return axios.put(`${apiEndpoint}/channels/${channelId}/schedules/${schedule.id}`, schedule, {
        headers : {
            stageId,
            projectId
        }
    });
}

function putSchedulesAPI(stageId, apiEndpoint, projectId, channelId, schedules) {
    return axios.put(`${apiEndpoint}/channels/${channelId}/schedules`, schedules, {
        headers : {
            stageId,
            projectId
        }
    });
}

function deleteScheduleAPI(stageId,apiEndpoint, projectId, channelId, schedule) {
    return axios.delete(`${apiEndpoint}/channels/${channelId}/schedules/${schedule.id}`, {
        data: schedule,
        headers : {
            stageId,
            projectId
        }
    });
}

function deleteSchedulesAPI(stageId,apiEndpoint, projectId, channelId, schedules) {
    return axios.delete(`${apiEndpoint}/channels/${channelId}/schedules/bulk`, {
        data: schedules,
        headers : {
            stageId,
            projectId
        }
    });
}

const CHANNEL_SCHEDULE_PENDING = 'CHANNEL_SCHEDULE_PENDING';
const CHANNEL_SCHEDULE_FAILURE = 'CHANNEL_SCHEDULE_FAILURE';

const GET_CHANNEL_SCHEDULE_SUCCESS= 'GET_CHANNEL_SCHEDULE_SUCCESS';
const GET_CHANNEL_SCHEDULES_SUCCESS = 'GET_CHANNEL_SCHEDULES_SUCCESS';
const PUT_CHANNEL_SCHEDULE_SUCCESS = 'PUT_CHANNEL_SCHEDULE_SUCCESS';
const PUT_CHANNEL_SCHEDULES_SUCCESS = 'PUT_CHANNEL_SCHEDULES_SUCCESS';
const DELETE_CHANNEL_SCHEDULE_SUCCESS = 'DELETE_CHANNEL_SCHEDULE_SUCCESS';
const DELETE_CHANNEL_SCHEDULES_SUCCESS = 'DELETE_CHANNEL_SCHEDULES_SUCCESS';

const RESET_CHANNEL_SCHEDULES = 'RESET_CHANNEL_SCHEDULES';

const initialState = {
    pending: false,
    error: false,
    errorStatus : null,
    schedule: null,
    schedules: null,
    count : 0
};

export default handleActions({
    [CHANNEL_SCHEDULE_PENDING]: (state) => {
        return {
            ...state,
            pending: true,
            error: false,
            errorStatus : null
        };
    },
    [CHANNEL_SCHEDULE_FAILURE]: (state, action) => {
        return {
            ...state,
            pending: false,
            error: true,
            errorStatus : action.payload.response.status
        };
    },
    [GET_CHANNEL_SCHEDULE_SUCCESS]: (state, action) => {
        const {data} = action.payload;

        return {
            ...state,
            schedule: data,
            count : state.count + 1,
            pending: false,
            error: false,
            errorStatus : null
        }
    },
    [GET_CHANNEL_SCHEDULES_SUCCESS]: (state, action) => {
        const {data} = action.payload;

        return {
            ...state,
            schedules: data,
            count : state.count + 1,
            pending: false,
            error: false,
            errorStatus : null
        }
    },
    [DELETE_CHANNEL_SCHEDULE_SUCCESS]: (state) => {
        return {
            ...state,
            pending: false,
            error: false,
            errorStatus : null
        };
    },
    [DELETE_CHANNEL_SCHEDULES_SUCCESS]: (state) => {
        return {
            ...state,
            pending: false,
            error: false,
            errorStatus : null
        };
    },
    [PUT_CHANNEL_SCHEDULE_SUCCESS]:(state, action) => {
        const {data} = action.payload;

        return {
            ...state,
            pending: false,
            error: false,
            schedule : data,
            count : state.count + 1,
            errorStatus : null
        }
    },
    [PUT_CHANNEL_SCHEDULES_SUCCESS]:(state, action) => {
        const {data} = action.payload;
        return {
            ...state,
            pending: false,
            error: false,
            schedules : data,
            count : state.count + 1,
            errorStatus : null
        }
    },
    [RESET_CHANNEL_SCHEDULES]:() => {
        return {
            pending: false,
            error: false,
            schedule: null,
            schedules: null,
            count : 0,
            errorStatus : null
        }
    }
}, initialState);

export const getSchedule = (channelId, scheduleId, callback) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();
    
    dispatch({type: CHANNEL_SCHEDULE_PENDING});

    return getScheduleAPI(stage.id, stage.endpoint, project.id, channelId, scheduleId).then(
        (response) => {
            dispatch({
                type: GET_CHANNEL_SCHEDULE_SUCCESS,
                payload: response
            });
            
            if(callback && typeof callback === "function") {
                callback();
            }
        }
    ).catch(error => {
        dispatch({
            type: CHANNEL_SCHEDULE_FAILURE,
            payload: error
        });
    });
}

export const getSchedules = (projectId, channelId, callback) => (dispatch, getState) => {
    const {
        stage
    } = getState();
    
    dispatch({type: CHANNEL_SCHEDULE_PENDING});

    return getSchedulesAPI(stage.id, stage.endpoint, projectId, channelId).then(
        (response) => {
            dispatch({
                type: GET_CHANNEL_SCHEDULES_SUCCESS,
                payload: response
            });
            
            if(callback && typeof callback === "function") {
                callback();
            }
        }
    ).catch(error => {
        dispatch({
            type: CHANNEL_SCHEDULE_FAILURE,
            payload: error
        });
    });
}

export const deleteSchedule = (channelId, projectId, scheduleId, callback = () => {}) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    dispatch({type: CHANNEL_SCHEDULE_PENDING});

    const _projectId = projectId || project.id;

    return deleteScheduleAPI(stage.id, stage.endpoint, _projectId, channelId, scheduleId).then(
        () => {
            dispatch({type: DELETE_CHANNEL_SCHEDULE_SUCCESS});
            callback();
        }
    ).catch(error => {
        dispatch({
            type: CHANNEL_SCHEDULE_FAILURE,
            payload: error
        });
    })
};

export const deleteSchedules = (projectId, channelId, schedules, callback = () => {}) => (dispatch, getState) => {
    const {
        stage
    } = getState();

    dispatch({type: CHANNEL_SCHEDULE_PENDING});

    return deleteSchedulesAPI(stage.id, stage.endpoint, projectId, channelId, schedules).then(
        () => {
            dispatch({type: DELETE_CHANNEL_SCHEDULES_SUCCESS});
            callback();
        }
    ).catch(error => {
        dispatch({
            type: CHANNEL_SCHEDULE_FAILURE,
            payload: error
        });
    })
};

export const putSchedule = (channelId, schedule) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    dispatch({type: CHANNEL_SCHEDULE_PENDING});
    
    return putScheduleAPI(stage.id, stage.endpoint, project.id, channelId, schedule).then(
        (response) => {
            dispatch({
                type: PUT_CHANNEL_SCHEDULE_SUCCESS,
                payload: response
            });
        }
    ).catch(error => {
        dispatch({
            type: CHANNEL_SCHEDULE_FAILURE,
            payload: error
        });
    })
};

export const putSchedules = (channelId, schedules) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    dispatch({type: CHANNEL_SCHEDULE_PENDING});
    
    return putSchedulesAPI(stage.id, stage.endpoint, project.id, channelId, schedules).then(
        (response) => {
            dispatch({
                type: PUT_CHANNEL_SCHEDULES_SUCCESS,
                payload: response
            });
        }
    ).catch(error => {
        dispatch({
            type: CHANNEL_SCHEDULE_FAILURE,
            payload: error
        });
    })
};
export const reset = createAction(RESET_CHANNEL_SCHEDULES);
