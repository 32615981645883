import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Popper } from "@mui/material";

const StoragePathTooltip = ({ type, children, bucket, folder }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const dispatch = useDispatch();

    const open = Boolean(anchorEl);
    const tooltipId = open ? "simple-popper" : undefined;

    const handleClick = (e) => {
        setAnchorEl(anchorEl ? null : e.currentTarget);
    };

    const handleClose = (event) => {
        setAnchorEl(null);
    };

    return (
        <div className="popper-tooltip" onMouseLeave={handleClose}>
            <span aria-describedby={tooltipId} onClick={(e) => handleClick(e)} onMouseEnter={(e) => handleClick(e)}>
                {children}
            </span>
            <Popper id={tooltipId} open={open} anchorEl={anchorEl} placement={type ? "bottom-start" : "bottom-start"}>
                <div className="tooltip-small">
                    <p>
                        <strong>{t(`common::label::Folder path`)}</strong>
                    </p>
                    - {`${bucket}/${folder}`}
                </div>
            </Popper>
        </div>
    );
};

export default StoragePathTooltip;
