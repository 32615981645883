import React from "react";
import fp from "lodash/fp";
import { useTranslation } from "react-i18next";

const InformationTools = (props) => {
    const { tabs, activeTab, handleOnChangeTab } = props;
    const { t } = useTranslation();

    return (
        <div className="information-tools">
            <div className="tab-nav tab-nav-sm">
                <div className="nav">
                    {fp.map(
                        (item) => (
                            <button
                                key={fp.get(`value`, item)}
                                type="button"
                                className={`tab-item ${fp.isEqual(activeTab, fp.get(`value`, item)) && `active`}`}
                                onClick={() => handleOnChangeTab(fp.get(`value`, item))}
                            >
                                <span>{t(fp.get(`label`, item))}</span>
                            </button>
                        ),
                        tabs,
                    )}
                </div>
            </div>
        </div>
    );
};

export default InformationTools;
