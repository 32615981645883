import {handleActions, createAction} from 'redux-actions';

const LINEUPLIST_PENDING = 'LINEUPLIST_PENDING';
const LINEUPLIST_FAILURE = 'LINEUPLIST_FAILURE';
// const GET_LINEUPLIST_SUCCESS = 'GET_LINEUPLIST_SUCCESS';
const POST_LINEUPLIST_SUCCESS = 'POST_LINEUPLIST_SUCCESS';
const PUT_LINEUPLIST_SUCCESS = 'PUT_LINEUPLIST_SUCCESS';
const UPDATE_LINEUPLIST_CURRENT_DATE = 'UPDATE_LINEUPLIST_CURRENT_DATE';
const REMOVE_LINEUPLIST_ITEM = 'REMOVE_LINEUPLIST_ITEM';
const REMOVE_CREATE_LINEUP_ITEM = 'REMOVE_CREATE_LINEUP_ITEM';
const INIT_LINEUPLIST_ITEM = 'INIT_LINEUPLIST_ITEM';
const SET_ALL_CHECK = 'SET_ALL_CHECK';
const UPDATE_CREATE_TARGET = 'UPDATE_CREATE_TARGET';
//reducer
const initialState = {
    pending: false,
    error: false,
    currentDate : null,
    lineupList : [],
    count : 0,
    createTarget : {
        lineupType : '',
        id : '',
        type : 'none'
    }
};

export default handleActions({
    [INIT_LINEUPLIST_ITEM]: (state) => {
        return {
            ...state,
            pending:true,
            error: false,
            count : 0,
            lineupList : []
        }
    },
    [LINEUPLIST_PENDING]: (state) => {
        return {
            ...state,
            pending: true,
            error: false
        };
    },
    [LINEUPLIST_FAILURE]: (state) => {
        return {
            ...state,
            pending: false,
            error: true
        };
    },
    [POST_LINEUPLIST_SUCCESS]:(state, action) => {
        return {
            ...state,
            pending:false,
            lineupList : action.payload,
            count : state.count + 1
        }
    },
    [PUT_LINEUPLIST_SUCCESS]:(state, action) => {
        let _lineupList = Object.assign([], state.lineupList);
        
        let data = _lineupList.filter(v => v.id !== '' && v.id === action.payload.id);

        if(data.length === 0) {
            return {
                ...state,
                lineupList: [
                    ...state.lineupList,
                    action.payload
                ]
            };
        } else {
            return {
                ...state,
                lineupList: _lineupList.map(v => {
                    if (v.id === action.payload.id) {
                        return {
                            ...v,
                            ...action.payload
                        }
                    } else {
                        return v;
                    }
                })
            };
        }
    },
    [UPDATE_LINEUPLIST_CURRENT_DATE]: (state, action) => {
        return {
            ...state,
            currentDate : action.payload
        }
    },
    [SET_ALL_CHECK]: (state, action) => {
        const {targetList, check} = action.payload;

        return {
            ...state,
            lineupList : state.lineupList.map(v => {
                return {
                    ...v,
                    isChecked : targetList.find(a => a.id === v.id) ? check : v.isChecked
                }
            })
        }
    },
    [REMOVE_LINEUPLIST_ITEM]:(state, action) => {
        const {id, type} = action.payload;
        let _lineupList = Object.assign([], state.lineupList);

        let index = 0;
        _lineupList.some((v, i) => {
            if(v.id === id && v.type === type){
                index = i;
                return true;
            }
            return false;
        });
        
        _lineupList.splice(index, 1);

        return {
            ...state,
            lineupList: _lineupList
        };
    },
    [REMOVE_CREATE_LINEUP_ITEM]:(state, action) => {
        const lineupList = state.lineupList.filter(v => v.type !== 'create');
        return {
            ...state,
            pending:false,
            lineupList,
            count : lineupList.length
        }
    },
    [UPDATE_CREATE_TARGET]:(state, action) => {
        return {
            ...state,
            createTarget : action.payload
        }
    }
}, initialState);

export const postLineupList = createAction(POST_LINEUPLIST_SUCCESS);
export const putLineupList = createAction(PUT_LINEUPLIST_SUCCESS);
export const updateCurrentDate = createAction(UPDATE_LINEUPLIST_CURRENT_DATE);
export const removeLineupListItem = createAction(REMOVE_LINEUPLIST_ITEM);
export const removeCreateLineupItem = createAction(REMOVE_CREATE_LINEUP_ITEM);
export const initLineupListItem = createAction(INIT_LINEUPLIST_ITEM);
export const setAllCheck = createAction(SET_ALL_CHECK);
export const updateCreateTarget = createAction(UPDATE_CREATE_TARGET);