import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    MenuItem,
    Select,
    Stack,
    TextField,
    Tooltip,
} from "@mzc-pdc/ui";
import Typography from "@components_v2/typography/typography";
import { useCallback, useEffect, useMemo, useState } from "react";
import theme from "@styles/theme";
import moment from "moment/moment";
import { IconAdd, IconBack, IconChevronDown, IconTime, IconWarningTriangle } from "@mzc-cloudplex/icons";
import { ASSET_SHARE_ACTIONS, STORAGE_SHARE_ACTIONS } from "@constants";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { UsersMultiInput, UsersMultiInputV2 } from "@features/users-input";
import compareVersions, { VERSION_CM_11376_SHARES_V2, VERSION_CM_11330_USERS_TEAMS_ACCESS_V3 } from "@cores/version";
import {
    addResourceGrantees,
    changeResourceGrantees,
    deleteResourceGrantees,
    getDetailSharedResource,
} from "@modules/sharedBox";
import { setNotification } from "@modules/notification";
import convertUtcToLocal from "@cores/convertUtcToLocal";
import ApprovedUserTeamItem from "@features/dialog/share/components/ApprovedUserTeamItem";
import ShareResourceInfo from "@features/dialog/share/components/share-resource-info";
import AddShareApprovedContainer from "@features/dialog/share/components/add-share-approved-container";
import { alpha } from "@mui/material";
import countDaysBetweentDates from "@cores/countDaysBetweentDates";

//목록/상세에서 내부공유시 asset/storage의 id를 이용해서 sharedResourceId를 알 수 있는 방법
const DialogManageShare = ({ domain, selectedItem, onClickClose }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const grantees = useSelector(({ sharedBox }) => sharedBox.grantees);
    const user = useSelector(({ stage }) => stage.user);
    const stage = useSelector(({ stage }) => stage);
    const sharedRecDetail = useSelector(({ sharedBox }) => sharedBox.sharedResourcesDetail?.results);

    //구조를 [{id, name, username, userType, action}]으로 가져가기
    const [actionPending, setActionPending] = useState(false);
    const [selectedUsersOrTeams, setSelectedUsersOrTeams] = useState([]);
    const [shareExpireDays, setShareExpireDays] = useState(5);
    const [shareMaximumDownloads, setShareMaximumDownloads] = useState(1);
    const [message, setMessage] = useState("");

    const [selectingUsersOrTeams, setSelectingUsersOrTeams] = useState([]);
    const [selectingAction, setSelectingAction] = useState("DOWNLOAD");

    const [approvedUsersOrTeams, setApprovedUsersOrTeams] = useState([]);
    const [deletedUsersOrTeams, setDeletedUsersOrTeams] = useState([]);

    const [step, setStep] = useState(1);
    const [accordionExpanded, setAccordionExpanded] = useState({});

    const actionOptions = useMemo(() => (domain === "STORAGE" ? STORAGE_SHARE_ACTIONS : ASSET_SHARE_ACTIONS), [domain]);

    const getAccordionSummaryExpandIcon = useCallback(
        (id) => {
            if (compareVersions(stage.version, VERSION_CM_11376_SHARES_V2) < 0) return null;
            if (accordionExpanded[id])
                return (
                    <Tooltip title={t("common::label::Fold")}>
                        <IconButton variant={"contained"} circled size={"medium"} color={"secondary"}>
                            <IconChevronDown size={20} />
                        </IconButton>
                    </Tooltip>
                );
            else
                return (
                    <Tooltip title={t("common::label::Expand")}>
                        <IconButton variant={"outlined"} circled size={"medium"}>
                            <IconChevronDown size={20} />
                        </IconButton>
                    </Tooltip>
                );
        },
        [accordionExpanded],
    );

    const getWarningSharingMessage = useCallback(
        (userOrTeam) => {
            let isDownloadLimit = false;
            let isExpired = false;
            if (userOrTeam.actions === "DOWNLOAD" && userOrTeam.maxDownloads >= userOrTeam.downloads) {
                isDownloadLimit = true;
            } else if (userOrTeam.expiresAt) {
                isExpired = moment(userOrTeam.expiresAt).isBefore(moment());
            }
            if (!(isDownloadLimit || isExpired)) return null;

            let warningMessage = "";
            if (isDownloadLimit && isExpired) warningMessage = "Sharing Period and Number of Downloads have";
            else if (isDownloadLimit) warningMessage = "Number of Downloads has";
            else if (isExpired) warningMessage = "Sharing Period has";

            return `${warningMessage} expired. You can reset it.`;
        },
        [approvedUsersOrTeams],
    );

    const onHandleChangeExpireDays = useCallback(
        (id, days) => {
            setApprovedUsersOrTeams(
                approvedUsersOrTeams.map((item) => (item.id === id ? { ...item, expireDays: days } : item)),
            );
        },
        [approvedUsersOrTeams],
    );

    const onHandleChangeMaximumDownloads = useCallback(
        (id, downloads) => {
            setApprovedUsersOrTeams(
                approvedUsersOrTeams.map((item) => (item.id === id ? { ...item, maxDownloads: downloads } : item)),
            );
        },
        [approvedUsersOrTeams],
    );

    const onHandleClickSubmit = useCallback(async () => {
        try {
            if (step === 1) {
                const deleteGranteesList = deletedUsersOrTeams.reduce((acc, current) => {
                    const payload = {
                        granteeId: current.granteeId,
                        id: selectedItem.id,
                    };
                    acc.push(payload);
                    return acc;
                }, []);

                const changeGranteesList = approvedUsersOrTeams.reduce((acc, current) => {
                    let payload = {
                        granteeId: current.granteeId,
                        id: selectedItem.id,
                        actions: actionOptions
                            .slice(
                                actionOptions.findIndex((p) => p.value === current.actions),
                                actionOptions.length,
                            )
                            .map((p) => p.value),
                    };

                    if (compareVersions(stage.version, VERSION_CM_11376_SHARES_V2) >= 0) {
                        payload.limits = {
                            maxDownloads: current.maxDownloads > 0 ? current.maxDownloads : undefined,
                            expiresAt:
                                current.expireDays > 0
                                    ? convertUtcToLocal(moment().add(current.expireDays, "days"), "YYYY-MM-DD 23:59:59")
                                    : undefined,
                        };
                    }

                    acc.push(payload);
                    return acc;
                }, []);

                const deletePromises = new Promise((resolve, reject) => {
                    deleteGranteesList.map(async (item) => {
                        try {
                            await dispatch(deleteResourceGrantees(item));
                        } catch (error) {
                            reject(error);
                        }
                    });
                    resolve();
                });

                const changePromises = new Promise((resolve, reject) => {
                    changeGranteesList.map(async (item) => {
                        try {
                            await dispatch(changeResourceGrantees(item));
                        } catch (error) {
                            reject(error);
                        }
                    });
                    resolve();
                });

                await Promise.all([changePromises, deletePromises]);
            } else if (step === 2) {
                setActionPending(true);

                const addGranteesList = selectedUsersOrTeams.reduce((acc, item) => {
                    let data = {
                        domain,
                        grantee: {
                            externalId: item.id,
                            type: "USER",
                        },
                        granted: {
                            by: {
                                id: user.id,
                                name: user.name,
                                username: user.username,
                            },
                        },
                        actions: actionOptions
                            .slice(
                                actionOptions.findIndex((p) => p.value === item.actions),
                                actionOptions.length,
                            )
                            .map((p) => p.value),
                        message,
                    };

                    if (compareVersions(stage.version, VERSION_CM_11376_SHARES_V2) >= 0) {
                        data.limits = {
                            maxDownloads: shareMaximumDownloads > 0 ? shareMaximumDownloads : undefined,
                            expiresAt:
                                shareExpireDays > 0
                                    ? convertUtcToLocal(moment().add(shareExpireDays, "days"), "YYYY-MM-DD 23:59:59")
                                    : undefined,
                        };
                    }

                    const payload = {
                        data: data,
                        id: selectedItem.id,
                    };

                    acc.push(payload);
                    return acc;
                }, []);

                await Promise.all(
                    addGranteesList.map(async (item) => {
                        await dispatch(addResourceGrantees(item));
                    }),
                );

                dispatch(
                    setNotification({
                        type: "success",
                        contents: t(
                            `common::msg::${
                                domain === "storage"
                                    ? `File {{item}} has been shared`
                                    : `Asset {{item}} has been shared`
                            }`,
                            `${
                                domain === "storage"
                                    ? `File {{item}} has been shared`
                                    : `Asset {{item}} has been shared`
                            }`,
                            {
                                item: `'${selectedItem.name}'`,
                            },
                        ),
                    }),
                );
            }
            onClickClose();
        } catch (error) {
            console.error(error);
            dispatch(
                setNotification({
                    type: "error",
                    contents: t(
                        `common::msg::There was an error sharing ${domain === "storage" ? "file" : "asset"} {{item}}`,
                        `There was an error sharing ${domain === "storage" ? "file" : "asset"} {{item}}`,
                        {
                            item: `'${selectedItem.name}'`,
                        },
                    ),
                }),
            );
        }
    }, [
        user,
        domain,
        step,
        selectedItem,
        selectedUsersOrTeams,
        approvedUsersOrTeams,
        deletedUsersOrTeams,
        actionOptions,
        shareExpireDays,
        shareMaximumDownloads,
        message,
    ]);

    useEffect(() => {
        if (!sharedRecDetail) return;
        setApprovedUsersOrTeams(
            sharedRecDetail.map(({ grantee, id, action, expiresAt, maxDownloads }) => {
                let expireDays =
                    expiresAt > 0
                        ? countDaysBetweentDates({
                              fromDate: grantee.granted?.at,
                              toDate: grantee.expiresAt,
                          })
                        : 0;
                if (expireDays < 0) expireDays = 0;

                return {
                    ...grantee,
                    id: grantee.externalId,
                    granteeId: id,
                    email: grantee.username,
                    actions: action[action.length - 1],
                    expireDays,
                    maxDownloads,
                };
            }),
        );
    }, [sharedRecDetail]);

    useEffect(() => {
        if (!selectedItem) return;
        dispatch(getDetailSharedResource(selectedItem.id));
    }, []);

    return (
        <Dialog variant={"basic"} open onClose={onClickClose}>
            <DialogTitle>
                <Grid container>
                    <Typography variant={"h3"}>{t(`common::label::Share`, `Share`)}</Typography>
                </Grid>
            </DialogTitle>
            <DialogContent sx={{ width: "600px" }}>
                <Grid container flexDirection={"column"} gap={theme.spacing(3)}>
                    {step === 1 && (
                        <>
                            <ShareResourceInfo domain={domain} resource={selectedItem.summary} />
                            <Grid container flexDirection={"column"} gap={theme.spacing(1)}>
                                <Grid item>
                                    <Typography variant={`body1_500`} required>
                                        {t(`common::label::Add Users or Groups`, `Add Users or Groups`)}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: theme.spacing(1),
                                        alignItems: "flex-end",
                                    }}
                                >
                                    {compareVersions(stage.version, VERSION_CM_11330_USERS_TEAMS_ACCESS_V3) >= 0 ? (
                                        <UsersMultiInputV2
                                            users={selectingUsersOrTeams}
                                            exceptUsers={selectingUsersOrTeams.concat(approvedUsersOrTeams)}
                                            filterSelectedOptions={true}
                                            sx={{
                                                flex: 3,
                                                "& .MuiOutlinedInput-root": {
                                                    paddingRight: "0px !important",
                                                },
                                            }}
                                            placeholder={t(
                                                `common::msg::Please enter user's name.`,
                                                `Please enter user's name.`,
                                            )}
                                            onChange={(users) => setSelectingUsersOrTeams(users)}
                                        />
                                    ) : (
                                        <UsersMultiInput
                                            users={selectingUsersOrTeams}
                                            exceptUsers={selectingUsersOrTeams.concat(approvedUsersOrTeams)}
                                            filterSelectedOptions={true}
                                            sx={{
                                                flex: 3,
                                                "& .MuiOutlinedInput-root": {
                                                    paddingRight: "0px !important",
                                                },
                                            }}
                                            placeholder={t(
                                                `common::msg::Please enter user's name.`,
                                                `Please enter user's name.`,
                                            )}
                                            onChange={(users) => setSelectingUsersOrTeams(users)}
                                        />
                                    )}
                                    <Select
                                        onChange={(e) => {
                                            setSelectingAction(e.target.value);
                                        }}
                                        value={selectingAction}
                                        sx={{ flex: `1 0 0%`, height: "100%" }}
                                        renderValue={(value) => {
                                            const foundOption = actionOptions.find((option) => option.value === value);

                                            return (
                                                <Typography variant={"body1_400"}>
                                                    {t(`common::label::${foundOption.label}`, `${foundOption.label}`)}
                                                </Typography>
                                            );
                                        }}
                                    >
                                        {actionOptions.map((option) => (
                                            <MenuItem value={option.value}>
                                                {t(`common::label::${option.label}`, `${option.label}`)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid
                                    item
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        my: theme.spacing(0.5),
                                    }}
                                >
                                    <Button
                                        size={"small"}
                                        variant={"text"}
                                        disabled={selectingUsersOrTeams.length === 0}
                                        onClick={() => {
                                            setSelectedUsersOrTeams([
                                                ...selectedUsersOrTeams,
                                                ...selectingUsersOrTeams.map((value) => ({
                                                    ...value,
                                                    actions: selectingAction,
                                                })),
                                            ]);
                                            setSelectingUsersOrTeams([]);
                                            setStep(2);
                                        }}
                                        startIcon={<IconAdd size={16} />}
                                    >
                                        {t(`common::label::Add to Added Users`, `Add to Added Users`)}
                                    </Button>
                                </Grid>
                                <Divider flexItem orientation={"horizontal"} />
                            </Grid>
                            <Grid container flexDirection={"column"} gap={theme.spacing(1)}>
                                <Grid item>
                                    <Typography variant={`body1_500`}>
                                        {t(`common::label::Share Settings`, `Share Settings`)}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                flexDirection={"row"}
                                sx={{
                                    borderRadius: "4px",
                                    maxHeight: "544px",
                                    overflowY: "auto",
                                    border: `1px solid ${theme.palette.greySecondary[100]}`,
                                }}
                            >
                                {approvedUsersOrTeams?.length === 0 ? (
                                    <Grid
                                        container
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                        p={theme.spacing(2)}
                                    >
                                        {t(`common::msg::There are no shared users.`, `There are no shared users.`)}
                                    </Grid>
                                ) : (
                                    <>
                                        {approvedUsersOrTeams?.map((userOrTeam) => (
                                            <Accordion
                                                sx={{
                                                    width: "100%",
                                                    border: "none",
                                                    borderBottom: `1px solid ${theme.palette.greySecondary[100]} !important`,
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setAccordionExpanded({
                                                        ...accordionExpanded,
                                                        [userOrTeam.id]: !accordionExpanded[userOrTeam.id],
                                                    });
                                                }}
                                            >
                                                <AccordionSummary
                                                    sx={{
                                                        width: "100%",
                                                        padding: `0 ${theme.spacing(1)} 0 0 !important`,
                                                        ".MuiAccordionSummary-content": {
                                                            margin: "0px",
                                                            "&.Mui-expanded": {
                                                                margin: "0px",
                                                            },
                                                        },
                                                        "&.Mui-expanded": {
                                                            backgroundColor: alpha(
                                                                theme.palette.greySecondary["A100"],
                                                                0.24,
                                                            ),
                                                            borderBottom: `1px solid ${theme.palette.greySecondary[100]}`,
                                                            minHeight: 56,
                                                            margin: "0px",
                                                        },
                                                        pointerEvents:
                                                            compareVersions(stage.version, VERSION_CM_11376_SHARES_V2) <
                                                            0
                                                                ? "none"
                                                                : "auto",
                                                    }}
                                                    expandIcon={getAccordionSummaryExpandIcon(userOrTeam.id)}
                                                >
                                                    <Box
                                                        onClick={(e) => {
                                                            if (
                                                                compareVersions(
                                                                    stage.version,
                                                                    VERSION_CM_11376_SHARES_V2,
                                                                ) < 0
                                                            )
                                                                e.stopPropagation();
                                                        }}
                                                        sx={{ width: "100%" }}
                                                    >
                                                        <ApprovedUserTeamItem
                                                            userOrTeam={userOrTeam}
                                                            onHandleChangeAction={(user, action) => {
                                                                setApprovedUsersOrTeams(
                                                                    approvedUsersOrTeams.map((item) =>
                                                                        item.granteeId === user.granteeId
                                                                            ? {
                                                                                  ...item,
                                                                                  actions: action,
                                                                              }
                                                                            : item,
                                                                    ),
                                                                );
                                                            }}
                                                            onHandleDeleteUserTeam={(user) => {
                                                                setApprovedUsersOrTeams(
                                                                    approvedUsersOrTeams.filter(
                                                                        (item) => item.granteeId !== user.granteeId,
                                                                    ),
                                                                );
                                                                setDeletedUsersOrTeams([
                                                                    ...deletedUsersOrTeams,
                                                                    {
                                                                        granteeId: user.granteeId,
                                                                    },
                                                                ]);
                                                            }}
                                                            options={actionOptions}
                                                        />
                                                    </Box>
                                                    <Divider
                                                        flexItem
                                                        orientation={"vertical"}
                                                        sx={{
                                                            my: theme.spacing(1),
                                                            mr: theme.spacing(1),
                                                        }}
                                                        hidden={
                                                            compareVersions(stage.version, VERSION_CM_11376_SHARES_V2) <
                                                            0
                                                        }
                                                    />
                                                </AccordionSummary>
                                                <AccordionDetails
                                                    sx={{
                                                        width: "100%",
                                                        padding: `${theme.spacing(4)} ${theme.spacing(2.5)}`,
                                                    }}
                                                    onClick={(e) => e.stopPropagation()}
                                                >
                                                    <Grid container flexDirection={"column"} gap={theme.spacing(1)}>
                                                        {getWarningSharingMessage(userOrTeam) && (
                                                            <Grid
                                                                item
                                                                sx={{
                                                                    mb: theme.spacing(2.5),
                                                                }}
                                                            >
                                                                <Alert
                                                                    color={"warning"}
                                                                    severity="warning"
                                                                    icon={
                                                                        <IconWarningTriangle
                                                                            fontSize="small"
                                                                            height={16}
                                                                        />
                                                                    }
                                                                >
                                                                    <Typography variant={"body1_400"}>
                                                                        {t(
                                                                            `common::msg::${getWarningSharingMessage(
                                                                                userOrTeam,
                                                                            )}`,
                                                                            getWarningSharingMessage(userOrTeam),
                                                                        )}
                                                                    </Typography>
                                                                </Alert>
                                                            </Grid>
                                                        )}
                                                        <Stack direction={"column"} gap={theme.spacing(2.5)}>
                                                            <Stack direction={"row"}>
                                                                <Grid
                                                                    item
                                                                    sx={{
                                                                        flexBasis: "120px",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "flex-start",
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        variant={`body1_400`}
                                                                        sx={{
                                                                            display: "inline-flex",
                                                                            alignItems: "center",
                                                                        }}
                                                                    >
                                                                        {t(
                                                                            `common::label::Sharing Period`,
                                                                            `Sharing Period`,
                                                                        )}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography
                                                                        variant={`body1_400`}
                                                                        sx={{
                                                                            display: "inline-flex",
                                                                            alignItems: "center",
                                                                            gap: theme.spacing(1),
                                                                        }}
                                                                    >
                                                                        {t(`common::label::Maximum`, `Maximum`)}
                                                                        <TextField
                                                                            type={"number"}
                                                                            size={"small"}
                                                                            inputProps={{
                                                                                min: 0,
                                                                            }}
                                                                            sx={{
                                                                                width: "80px",
                                                                                input: {
                                                                                    paddingLeft: theme.spacing(2),
                                                                                },
                                                                            }}
                                                                            defaultValue={userOrTeam?.expireDays ?? 0}
                                                                            onKeyDown={(e) => {
                                                                                if (e.code === "Minus") {
                                                                                    e.preventDefault();
                                                                                }
                                                                            }}
                                                                            onChange={(e) => {
                                                                                onHandleChangeExpireDays(
                                                                                    userOrTeam.id,
                                                                                    e.target.value > 0
                                                                                        ? e.target.value
                                                                                        : 0,
                                                                                );
                                                                            }}
                                                                        />
                                                                        {t(`common::label::days`, `days`)}
                                                                        <Divider flexItem orientation={"vertical"} />
                                                                        <IconTime size={16} htmlColor={`#999`} />
                                                                        {t(`common::label::Expires At`)} :
                                                                        <Typography
                                                                            variant={`body1_400`}
                                                                            color={theme.palette.warning.main}
                                                                        >
                                                                            {userOrTeam?.expireDays === 0
                                                                                ? userOrTeam.expiresAt
                                                                                    ? t(`common::label::Expired`)
                                                                                    : "-"
                                                                                : `${moment()
                                                                                      .add(userOrTeam?.expireDays, "d")
                                                                                      .format("YYYY-MM-DD")}`}
                                                                        </Typography>
                                                                    </Typography>
                                                                </Grid>
                                                            </Stack>
                                                            {userOrTeam.actions === "DOWNLOAD" && (
                                                                <Stack direction={"row"}>
                                                                    <Grid
                                                                        item
                                                                        sx={{
                                                                            flexBasis: "120px",
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            justifyContent: "flex-start",
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            variant={`body1_400`}
                                                                            sx={{
                                                                                display: "inline-flex",
                                                                                alignItems: "center",
                                                                            }}
                                                                        >
                                                                            {t(
                                                                                `common::label::Number of Downloads`,
                                                                                `Number of Downloads`,
                                                                            )}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography
                                                                            variant={`body1_400`}
                                                                            sx={{
                                                                                display: "inline-flex",
                                                                                alignItems: "center",
                                                                                gap: theme.spacing(1),
                                                                            }}
                                                                        >
                                                                            {t(`common::label::Maximum`, `Maximum`)}
                                                                            <TextField
                                                                                type={"number"}
                                                                                size={"small"}
                                                                                inputProps={{
                                                                                    min: 0,
                                                                                }}
                                                                                sx={{
                                                                                    width: "80px",
                                                                                    input: {
                                                                                        paddingLeft: theme.spacing(2),
                                                                                    },
                                                                                }}
                                                                                defaultValue={
                                                                                    userOrTeam?.maxDownloads ?? 0
                                                                                }
                                                                                onKeyDown={(e) => {
                                                                                    if (e.code === "Minus") {
                                                                                        e.preventDefault();
                                                                                    }
                                                                                }}
                                                                                onChange={(e) => {
                                                                                    onHandleChangeMaximumDownloads(
                                                                                        userOrTeam.id,
                                                                                        e.target.value,
                                                                                    );
                                                                                }}
                                                                            />
                                                                            {t(`common::label::times`, `times`)}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Stack>
                                                            )}
                                                        </Stack>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        ))}
                                    </>
                                )}
                            </Grid>
                        </>
                    )}
                    {step === 2 && (
                        <>
                            <Stack
                                direction={"row"}
                                gap={theme.spacing(1)}
                                sx={{
                                    borderBottom: `1px solid ${theme.palette.greySecondary[100]}`,
                                    pb: theme.spacing(2),
                                }}
                            >
                                <IconButton
                                    variant={"contained"}
                                    circled
                                    sx={{
                                        backgroundColor: theme.palette.primary.light,
                                    }}
                                    size={"small"}
                                    onClick={() => setStep(1)}
                                >
                                    <IconBack size={16} />
                                </IconButton>
                                <Typography variant={"subtitle1_500"}>
                                    {t(`common::label::Share to "{{name}}"`, `Share to "{{name}}"`, {
                                        name: selectedItem.summary.key ?? selectedItem.summary.name,
                                    })}
                                </Typography>
                            </Stack>
                            <AddShareApprovedContainer
                                domain={domain}
                                selected={selectedUsersOrTeams}
                                selecting={selectingUsersOrTeams}
                                defaultAction={selectingAction}
                                expireDays={shareExpireDays}
                                maximumDownloads={shareMaximumDownloads}
                                message={message}
                                onChangeSelected={setSelectedUsersOrTeams}
                                onChangeSelecting={setSelectingUsersOrTeams}
                                onChangeExpireDays={setShareExpireDays}
                                onChangeMaximumDownloads={setShareMaximumDownloads}
                                onChangeMessage={setMessage}
                            />
                        </>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={onClickClose} variant="text">
                    {t(`common::label::Cancel`, `Cancel`)}
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={onHandleClickSubmit}
                    // disabled={}
                >
                    {t(`common::label::${step === 1 ? "Adjust" : "Share"}`, step === 1 ? "Adjust" : "Share")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogManageShare;
