import { METADATA_FIELDS_TYPE } from "@constants";
import { Chip, Grid, List, ListItem, Typography } from "@mzc-pdc/ui";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import AutocompleteTableList from "@features/autocomplete-table/autocomplete-table-list";
import menuService from "@services/menuService";
import { useLocation } from "react-router-dom";
import { PeopleContentColumn } from "@features/autocomplete-table/autocomplete-table-columns/autocomplete-table-columns-contents";
import theme from "@styles/theme";

const MetadataFieldReadMode = ({ type, isHidden, value }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { spaceId, projectId } = menuService.parse(pathname);

    const getChips = useCallback(
        (type, chips) => {
            return (
                <Grid container gap={0.5}>
                    {!chips?.length && "-"}
                    {chips?.map((el, index) => (
                        <Grid item key={index}>
                            <Chip
                                label={type === METADATA_FIELDS_TYPE.TAGS ? `# ${el}` : el}
                                size="small"
                                variant="outlined"
                            />
                        </Grid>
                    ))}
                </Grid>
            );
        },
        [value],
    );

    if (isHidden)
        return (
            <Typography variant={"h2"} sx={{ letterSpacing: "-5px" }}>
                &middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;&middot;
            </Typography>
        );
    if ([METADATA_FIELDS_TYPE.TAGS, METADATA_FIELDS_TYPE.MULTI_SELECT].includes(type)) return getChips(type, value);
    if (type === METADATA_FIELDS_TYPE.CATEGORIES)
        return getChips(
            type,
            value.categories.map((el) => el.name),
        );
    if (type === METADATA_FIELDS_TYPE.ATTRIBUTIONS)
        return (
            <Grid container flexDirection={"column"}>
                <Grid item>
                    <List disablePadding>
                        {!value?.length && "-"}
                        {value?.map((el, index) => (
                            <ListItem key={index} disablePadding sx={{ mb: 1, "&:last-of-type": { mb: 0 } }}>
                                <Grid container flexDirection={"row"} alignItems="center">
                                    <Grid item pr={0.5}>
                                        <Typography variant={"body3_700"} component={"p"}>
                                            {`${el.key} : `}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant={"body3_400"} component={"p"}>
                                            {el.value}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
            </Grid>
        );
    if (type === METADATA_FIELDS_TYPE.PEOPLE)
        return value?.length ? (
            <AutocompleteTableList
                type={"CONTENT"}
                data={[{ value }]}
                openNewWindow={true}
                onClickName={(id) => menuService.paths.contentsPeopleDetail(spaceId, projectId, id)}
                columns={PeopleContentColumn.filter((el) => el.id !== "desiredStatus")}
                gridStyle={{ border: "none" }}
            />
        ) : (
            <Typography variant={"body3_400"} component={"p"}>
                -
            </Typography>
        );

    if (value === "") return "-"; //Note: 여기부터 아래는 문자열로만 출력되는 값들인데, value===""인 경우 ??로 걸러지지 않아 "-" 표시 출력을 위해 사용하였습니다.
    if (
        [
            METADATA_FIELDS_TYPE.TEXT_FIELD,
            METADATA_FIELDS_TYPE.TEXT_AREA,
            METADATA_FIELDS_TYPE.NUMBER,
            METADATA_FIELDS_TYPE.SINGLE_SELECT,
        ].includes(type)
    )
        return <Typography>{value ?? "-"}</Typography>;
    if (type === METADATA_FIELDS_TYPE.SINGLE_DATE_PICKER) return <Typography>{value ?? "-"}</Typography>;
    if (type === METADATA_FIELDS_TYPE.RANGE_DATE_PICKER) return <Typography>{value ?? "-"}</Typography>;

    return <></>;
};

export default MetadataFieldReadMode;
