import { useDispatch, useSelector } from "react-redux";
import "@features/comments/editor-comment.css";
import {
    createComment,
    createThread,
    deleteComment,
    postActions,
    resetComments,
    resetSelectedCommentId,
    updateComment,
    updateHasLinkThreadLogicExecuted,
    addCommentAttachments,
    addUploadingComments,
    deleteCommentAttachments,
    removeUploadingComments,
    updateCommentData,
} from "@modules/common-comments";
import { useCallback, useMemo, useState } from "react";
import queryParamsParse from "../components/params/queryParamsParse";
import { useTranslation } from "react-i18next";
import {
    makeUploadQueueJob,
    retryUploadQueueJob,
    uploadFilesFinished,
    uploadJobFiles,
    uploadJobFileSetStatus,
} from "@modules/uploadQueue";
import { UPLOAD_LOCATION, UPLOAD_STATUS } from "@constants";
import uniqueId from "@cores/uniqueId";

const useCommonComments = ({ domain, resourceId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { threadId, commentId, isSubThread } = queryParamsParse(location.search);
    const isLinkFromEmail = !!threadId;
    const parsedIsSubThread = isSubThread === "true";

    const [htmlComment, setHtmlComment] = useState("");
    const [isShowReplyView, setIsShowReplyView] = useState(false);
    const [attachments, setAttachments] = useState([]);

    const resourceData = useSelector(({ commonComments }) => commonComments.resourceData);
    const comments = useSelector(({ commonComments }) => commonComments.comment);
    const threads = useSelector(({ commonComments }) => commonComments.thread);
    const selectedThreadId = useSelector(({ commonComments }) => commonComments.selectedThreadId);
    const selectedCommentId = useSelector(({ commonComments }) => commonComments.selectedCommentId);
    const hasLinkThreadLogicExecuted = useSelector(({ commonComments }) => commonComments.hasLinkThreadLogicExecuted);
    const stage = useSelector(({ stage }) => stage);

    const onChangeAttachments = (files) => {
        setAttachments(
            files.map((file) => {
                if (!file.id) file.id = uniqueId();
                return file;
            }),
        );
    };

    const convertCommentToHTML = (comment, style, showEdited = false) => {
        const isCommentEdited = comment?.created?.at !== comment?.updated?.at;

        return comment?.parts
            ?.map((part, index) =>
                convertCommentPart(part, style, isCommentEdited && showEdited && index === comment?.parts?.length - 1),
            )
            .join("");
    };

    const convertCommentPart = (part, style, isCommentEdited) => {
        switch (part.type) {
            case "PARAGRAPH":
                return `<p class=${style}>${part.content
                    .map((content) => convertParagraphContentByType(content))
                    .join("")} ${
                    isCommentEdited
                        ? `<span style="color:#6F6F83">(${t("common::label::edited", "edited")})</span>`
                        : ""
                }</p>`;
            case "HEADING":
                return `<h${part.attrs?.level}>${part.content
                    .map((content) => convertParagraphContentByType(content))
                    .join("")}</h${part.attrs?.level}>`;
        }
    };

    const convertParagraphContentByType = (content) => {
        switch (content.type) {
            case "TEXT":
                return content.text ? adjustMarkupToText(content) : "";
            case "HARD_BREAK":
                return `<br>`;
            case "MENTION":
                return `<span class="mention" data-denotation-char="@" data-id=${content.attrs.id} data-username=${content.attrs.username} data-value=${content.attrs.name}><span contenteditable="false">@${content.attrs.name}</span></span>`;
        }
    };

    const adjustMarkupToText = (content) => {
        return content?.marks?.reduce((acc, mark) => {
            switch (mark.type) {
                case "STRIKE":
                    return `<s>${acc}</s>`;
                case "UNDERLINE":
                    return `<u>${acc}</u>`;
                case "STRONG":
                    return `<strong>${acc}</strong>`;
                case "TEXT_COLOR":
                    return `<span style=\"color: ${mark.attrs.color}\">${acc}</span>`;
            }
        }, content.text);
    };

    const changeThreadId = (id) => {
        // setThreadId(id);
    };

    const convertHtmlToContentMark = (node) => {
        switch (node.tagName) {
            case "S":
                return "STRIKE";
            case "U":
                return "UNDERLINE";
            case "STRONG":
                return "STRONG";
            default:
                return null;
        }
    };

    function convertHtmlToParts(html) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");
        const node = doc.body;

        let parts = [];
        const children = node.childNodes;
        children.forEach((child) => {
            const part = {};
            const elements = child.childNodes;
            let contents = [];
            elements.forEach((element) => {
                contents = [...contents, ...convertHtmlToContent(element)];
            });
            part["type"] = "PARAGRAPH";
            part["content"] = contents;
            parts.push(part);
        });

        return parts;
    }

    function convertHtmlToContentType(node) {
        if (node.tagName === "SPAN" && node.className === "mention") return "MENTION";
        else if (node.tagName === "BR") return "HARD_BREAK";
        else if ((node.nodeName === "#text" && node.data !== "") || node.nodeType === Node.TEXT_NODE) return "TEXT";
        else return "";
    }

    function convertHtmlToContent(node) {
        let contents = [];
        let content = { marks: [] };

        function convertTags(node, data) {
            const type = convertHtmlToContentType(node);

            if (type !== "") data["type"] = type;
            if (type === "MENTION") {
                data["attrs"] = {
                    id: node.getAttribute("data-id"),
                    name: node.getAttribute("data-value"),
                    username: node.getAttribute("data-username"),
                };
            } else if (type === "TEXT") {
                data["text"] = node.textContent;
                data["marks"] = data.marks;
                contents.push({ ...data });
            } else {
                const type = convertHtmlToContentMark(node);
                const children = node.childNodes;
                children.forEach((child) => {
                    const data_ = { marks: [...data.marks, { type }] };
                    convertTags(child, data_);
                });
            }
        }

        convertTags(node, content);

        return contents?.length > 0 ? contents : [content];
    }

    const onChangeComment = (value) => {
        setHtmlComment(value);
    };

    const getThreadInfo = useCallback(
        (commentId) => {
            const isReply = !selectedCommentId;
            const isReplyInThread = selectedCommentId === commentId;

            if (isReply || isReplyInThread) {
                const threadId = resourceData?.results?.find(
                    (el) =>
                        el.context.domain === domain &&
                        el.context.resourceId === resourceId &&
                        el.id === selectedThreadId,
                )?.id;
                return { isThread: false, threadId: threadId };
            } else {
                const threadId = comments.data?.results.find((el) => el.id === selectedCommentId)?.subThread?.id;
                return { isThread: true, threadId: threadId };
            }
        },
        [comments, resourceData, domain, resourceId, selectedCommentId, selectedThreadId],
    );

    const onDeleteComment = async (comment) => {
        const commentId = comment.id;
        const threadInfo = getThreadInfo(commentId);

        await dispatch(deleteComment(threadInfo, commentId));
    };

    const getParsedAttachments = (attachments) => {
        return attachments.map((attach) => {
            if (attach.accessUrl) {
                return {
                    id: attach.id,
                    type: attach.mimeType.startsWith("image/") ? "image" : "file",
                    name: attach.displayName,
                    url: attach.accessUrl,
                };
            }
            if (attach.type.startsWith("image/")) {
                return {
                    id: attach.id,
                    type: "image",
                    name: attach.name,
                    url: URL.createObjectURL(attach),
                };
            } else {
                return {
                    id: attach.id,
                    type: "file",
                    name: attach.name,
                    url: undefined,
                };
            }
        });
    };

    const makeNewDummyComment = (data) => {
        const { id, ...rest } = data;

        return {
            id,
            parts: convertHtmlToParts(htmlComment),
            attachments: getParsedAttachments(attachments),
            created: {
                at: new Date().toISOString(),
                by: {
                    id: stage.user.id,
                    name: stage.user.name,
                },
            },
            updated: {
                at: new Date().toISOString(),
                by: {
                    id: stage.user.id,
                    name: stage.user.name,
                },
            },
            isUploading: true,
            ...rest,
        };
    };

    const onUpdateComment = async (comment) => {
        const commentId = comment.id;
        const threadInfo = getThreadInfo(commentId);

        const addedAttachments = attachments.filter((attach) => !attach.accessUrl);
        const removedAttachments = comment.attachments?.filter(
            (attach) => attachments.find((item) => item.id === attach.id) === undefined,
        );

        // dispatch(
        //     updateCommentData({
        //         data: makeNewDummyComment({
        //             id: commentId,
        //             created: {
        //                 at: comment.created.at,
        //                 by: {
        //                     id: stage.user.id,
        //                     name: stage.user.name,
        //                 },
        //             },
        //         }),
        //     }),
        // );

        if (addedAttachments.length || removedAttachments.length) {
            if (removedAttachments.length)
                await dispatch(
                    deleteCommentAttachments(
                        threadInfo,
                        commentId,
                        removedAttachments?.map((attach) => attach.id),
                    ),
                );

            let attachmentInfos = [];
            if (addedAttachments.length) attachmentInfos = await uploadAttachments(selectedThreadId, addedAttachments);

            dispatch(
                addCommentAttachments(threadInfo, commentId, {
                    attachments: attachmentInfos,
                }),
            );
        }

        const data = {
            parts: convertHtmlToParts(htmlComment),
        };
        await dispatch(updateComment(threadInfo, commentId, data));
    };

    const onCreateActions = async (comment, value) => {
        const commentId = comment.id;
        const threadInfo = getThreadInfo(commentId);
        const params = {
            type: "CREATE_REACTION",
            data: {
                value: value,
            },
        };
        await dispatch(postActions(threadInfo, commentId, params));
    };

    const onDeleteActions = async (comment, value) => {
        const commentId = comment.id;
        const threadInfo = getThreadInfo(commentId);
        const params = {
            type: "DELETE_REACTION",
            data: {
                value: value,
            },
        };
        await dispatch(postActions(threadInfo, commentId, params));
    };

    const checkAndUploadFiles = async (uploadQueue, locationType, jobKey) => {
        const targets = uploadQueue[locationType].filter((item) => item.key === jobKey)[0];

        const selectedFiles = targets?.files;

        const filePut = async (selectedFile) => {
            return await dispatch(
                uploadJobFiles({
                    locationType,
                    id: selectedFile.id,
                    key: jobKey,
                    uploadCompleteCallback: () => {},
                }),
            );
        };

        const fileAlreadyUploaded = async (selectedFile) => {
            return await dispatch(
                uploadJobFileSetStatus({
                    locationType,
                    id: selectedFile.id,
                    key: jobKey,
                    status: "COMPLETE",
                    uploadCompleteCallback: () => {},
                }),
            );
        };

        return new Promise(async (resolve, reject) => {
            let status = "SUCCEED";
            let attachmentInfos = [];

            for (let i = 0; i < selectedFiles?.length; i++) {
                const selectedFile = selectedFiles[i];
                if (selectedFile.status === "COMPLETE") {
                    await fileAlreadyUploaded(selectedFile);
                    continue;
                }
                const filePutResponse = await filePut(selectedFile);
                console.log("completed", i);
                status = filePutResponse.status;

                if (["CANCELED", "ERROR"].includes(status)) {
                    resolve(filePutResponse);
                    return;
                }
                if (status === "COMPLETE") {
                    attachmentInfos.push({
                        url: filePutResponse.url,
                        fileName: selectedFile.name,
                    });
                }
            }
            resolve({ status, attachmentInfos });
        });
    };

    const retryUpload = async ({ key }) => {
        const locationType = UPLOAD_LOCATION.UPLOAD_COMMENT_ATTACHMENT;
        const { retryKey, uploadQueue } = await dispatch(retryUploadQueueJob({ locationType, key }));

        const results = await checkAndUploadFiles(uploadQueue, locationType, retryKey);
        const status = results.status;

        if (["SUCCEED", "COMPLETE"].includes(status)) {
            const checkTarget =
                uploadQueue[locationType]
                    .find((item) => item.key === retryKey)
                    .files?.filter((item) => item.status === "ERROR") || [];
            dispatch(
                uploadFilesFinished({
                    locationType,
                    key: retryKey,
                    status: UPLOAD_STATUS.COMPLETE,
                }),
            );
        } else if (status === "CANCELED") {
            dispatch(
                uploadFilesFinished({
                    locationType,
                    key: retryKey,
                    status: UPLOAD_STATUS.CANCELED,
                }),
            );
        } else if (status === "ERROR") {
            dispatch(
                uploadFilesFinished({
                    locationType,
                    key: retryKey,
                    status: UPLOAD_STATUS.ERROR,
                }),
            );
        }

        return results;
    };

    const uploadAttachments = async (threadId, files) => {
        const jobKey = await dispatch(
            makeUploadQueueJob({
                locationType: UPLOAD_LOCATION.UPLOAD_COMMENT_ATTACHMENT,
                files,
                data: {
                    threadId,
                },
            }),
        );

        const result = await retryUpload({
            key: jobKey,
        });

        if (["SUCCEED", "COMPLETE"].includes(result.status)) {
            return result.attachmentInfos;
        }
    };

    const onCreateComments = async () => {
        if (selectedCommentId) {
            // 대댓글 생성
            await onCreateThread(selectedCommentId);
        } else {
            // 댓글 생성
            await onCreateComment();
        }
    };

    const onCreateComment = async () => {
        let threadId = selectedThreadId;
        const dummyCommentId = uniqueId();

        try {
            const thread = resourceData?.results?.find(
                (el) => el.context?.domain === domain && el.context?.resourceId === resourceId && el.type === "PUBLIC",
            );
            const convertedParts = convertHtmlToParts(htmlComment);

            if (!thread && !selectedThreadId) {
                //전체공개 쓰레드 생성
                const threadInfo = { isThread: false, isEmptyThread: false };
                const data = {
                    type: "PUBLIC",
                    context: {
                        domain: domain,
                        resourceId: resourceId,
                    },
                    participants: [],
                };
                threadId = await dispatch(createThread(threadInfo, data));
            }

            dispatch(addUploadingComments({ threadId, data: makeNewDummyComment({ id: dummyCommentId }) }));

            setAttachments([]);

            const attachmentInfos = await uploadAttachments(threadId, attachments);

            const threadInfo = {
                isThread: false,
                threadId: threadId,
                isEmptyThread: comments.data?.results?.length === 0,
            };
            const data = {
                parts: convertedParts,
                attachments: attachments?.length > 0 ? attachmentInfos : [],
            };
            await dispatch(createComment(threadInfo, data));
        } catch (error) {
            console.log(error);
            setAttachments([]);
        } finally {
            dispatch(
                removeUploadingComments({
                    threadId: threadId,
                    id: dummyCommentId,
                }),
            );
        }
    };

    const onCreateThread = async (commentId) => {
        let threadId = null;
        const dummyCommentId = uniqueId();

        try {
            threadId = comments?.data?.results.find((el) => el.id === commentId)?.subThread?.id;
            const convertedParts = convertHtmlToParts(htmlComment);

            if (!threadId) {
                // 대댓글의 쓰레드 생성
                const threadInfo = { isThread: true, isEmptyThread: false };
                const data = {
                    context: {
                        domain: "COMMENT",
                        resourceId: commentId,
                        parentThreadId: selectedThreadId,
                    },
                    participants: [],
                };
                threadId = await dispatch(createThread(threadInfo, data));
            }
            dispatch(addUploadingComments({ threadId, data: makeNewDummyComment({ id: dummyCommentId }) }));

            setAttachments([]);

            const attachmentInfos = await uploadAttachments(threadId, attachments);

            const threadInfo = {
                isThread: true,
                threadId: threadId,
                isEmptyThread: threads.data?.results?.length === 0,
            };
            const data = {
                parts: convertHtmlToParts(htmlComment),
                attachments: attachments?.length > 0 ? attachmentInfos : [],
            };
            await dispatch(createComment(threadInfo, data));
        } catch (error) {
            console.log(error);
            setAttachments([]);
        } finally {
            dispatch(
                removeUploadingComments({
                    threadId: threadId,
                    id: dummyCommentId,
                }),
            );
        }
    };

    const selectedComment = useMemo(() => {
        if (!comments?.data?.results?.length || !selectedCommentId) return null;

        return comments.data.results.find((comment) => comment.id === selectedCommentId);
    }, [comments.data, selectedCommentId]);

    const onClickToggleExpander = (value) => {
        setIsShowReplyView(value);
    };

    const onClickCloseReplyContainer = () => {
        setIsShowReplyView(false);
    };

    const onClickBackThreadContainer = () => {
        if (isLinkFromEmail && parsedIsSubThread && !hasLinkThreadLogicExecuted) {
            dispatch(resetComments());
            dispatch(updateHasLinkThreadLogicExecuted(true));
        }
        dispatch(resetSelectedCommentId());
    };

    //Note: 메일 링크를 통해 대댓글창을 바로 연 경우, 대댓글창에서 댓글창으로 이동한 경우 대댓글의 원본 댓글을 위해 사용한 comments.data를 초기화해야한다.
    const onClickCloseThreadContainer = () => {
        dispatch(resetSelectedCommentId());
        setIsShowReplyView(false);
    };

    const onDeleteAttachments = async (commentId, target) => {
        const ids = target.length > 0 ? target.map(({ id }) => id) : [target.id];
        dispatch(deleteCommentAttachments(getThreadInfo(commentId), commentId, ids));
        setAttachments(attachments.filter((attach) => !ids.includes(attach.id)));
    };

    return {
        convertCommentToHTML,
        changeThreadId,
        onChangeComment,
        onCreateComments,
        onCreateThread,
        onDeleteComment,
        onUpdateComment,
        onCreateActions,
        onDeleteActions,
        onClickToggleExpander,
        onClickCloseReplyContainer,
        onClickBackThreadContainer,
        onClickCloseThreadContainer,
        onChangeAttachments,
        onDeleteAttachments,
        isShowReplyView,
        htmlComment,
        attachments,
    };
};

export default useCommonComments;
