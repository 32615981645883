import React, { useCallback, useEffect, useMemo, useState } from "react";

import { presignObject, updateState } from "@modules/propertyPanel";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { MEDIA_TYPES, PROPERTY_PANEL_TYPE, POPUP_MODAL_SIZE } from "@constants";
import FileView from "@components/view/FileView";
import SpriteIcon from "@components/icons/SpriteIcon";
import PanelHeader from "@components/property-panel/ui/PanelHeader";
import PanelHeaderTool from "@components/property-panel/ui/PanelHeaderTool";
import PanelHeaderTitle from "@components/property-panel/ui/PanelHeaderTitle";
import ContentsView from "@components/property-panel/ui/ContentsView";
import { IconButton } from "@mzc-pdc/ui";
import Loading from "@components/loader/Loading";
import PanelPermissionError from "@components/property-panel/ui/PanelPermissionError";
import PanelBody from "@components/property-panel/ui/PanelBody";
import PanelBodyMedia from "@components/property-panel/ui/PanelBodyMedia";
import PanelBodyInfo from "./components/PanelBodyInfo";
import { getDetailSharedResource } from "../../modules/sharedBox";
import VersionedComponent from "@components/VersionedComponent";
import { VERSION_CM_8260_SHARED_BOX_STORAGE_PROPERTY_PANEL } from "../../cores/version";
import menuService from "@services/menuService";

const Panel = ({
    title,
    convertToCategoryType,
    shouldHidePreviewButton,
    onClickClose,
    contentsViewData,
    handleShareRequestModal,
}) => {
    const { t } = useTranslation();
    const [storagePresignedUrl, setStoragePresignedUrl] = useState(null);

    const { propertyPanel } = useSelector(({ propertyPanel }) => ({
        propertyPanel,
    }));

    const dispatch = useDispatch();

    useEffect(() => {
        if (!propertyPanel.data) return;

        getPresignedUrl();
        if (propertyPanel.type === PROPERTY_PANEL_TYPE.SHARED_STORAGE_BY)
            dispatch(getDetailSharedResource(propertyPanel.data.id));
    }, [propertyPanel.data.id]);

    const getPresignedUrl = useCallback(async () => {
        setStoragePresignedUrl(null);

        if (!propertyPanel.data) return;

        const { prefix, bucket, name } = propertyPanel.data;
        const url = await dispatch(
            presignObject({
                bucket,
                prefix: prefix.slice(0, prefix.length - 1),
                fileName: name,
            }),
        );
        setStoragePresignedUrl(url);
    }, [propertyPanel.data]);

    const onClickOpenPreviewPopup = () => {
        const { location } = this.props;
        const { spaceId, projectId } = menuService.parse(location.pathname);

        const url = `${window.location.origin}${menuService.paths.storagePreview(spaceId, projectId)}?bucket=${
            propertyPanel.data.bucket
        }&prefix=${propertyPanel.data.prefix.slice(0, propertyPanel.data.prefix.length - 1)}&fileName=${
            propertyPanel.data.name
        }`;

        window.open(
            url,
            `storage-preview-popup`,
            `width=${POPUP_MODAL_SIZE.width},height=${POPUP_MODAL_SIZE.height},scrollbar=1`,
        );
    };

    const getContentViewData = () => {
        if (!propertyPanel.data || propertyPanel.accessError) return;

        if ([MEDIA_TYPES.AUDIO, MEDIA_TYPES.VIDEO].includes(propertyPanel.data.mediaType)) {
            return {
                ...propertyPanel.data,
                elements: [
                    {
                        accessUrl: undefined,
                        accessUrls: [storagePresignedUrl],
                    },
                ],
            };
        } else {
            return {
                ...propertyPanel.data,
                elements: [
                    {
                        accessUrl: storagePresignedUrl,
                        accessUrls: undefined,
                    },
                ],
            };
        }
    };

    return (
        <React.Fragment>
            <VersionedComponent version={VERSION_CM_8260_SHARED_BOX_STORAGE_PROPERTY_PANEL}>
                <PanelHeader>
                    <PanelHeaderTitle>
                        <SpriteIcon className={`sprite-${title && title.toLowerCase()}`}></SpriteIcon>
                        {t(`common::label::${title}`)}
                    </PanelHeaderTitle>
                    <PanelHeaderTool>
                        <IconButton
                            hidden={shouldHidePreviewButton}
                            disabled={propertyPanel.accessError || propertyPanel.data?.mediaType === MEDIA_TYPES.FILE}
                            className={"btn-sm btn-dark"}
                            onClick={onClickOpenPreviewPopup}
                            icon={
                                <>
                                    <SpriteIcon className={"sprite-preview-inverse"}></SpriteIcon>
                                    <span className={"hidden"}>{t(`common::label::${"Preview button"}`)}</span>
                                </>
                            }
                        ></IconButton>
                        <IconButton
                            type="button"
                            className="btn-icon-solid btn-panel-close"
                            onClick={onClickClose}
                            icon={
                                <>
                                    <span>
                                        <SpriteIcon className="sprite-cancel-lg"></SpriteIcon>
                                    </span>
                                    <span className={"hidden"}>{t(`common::label::${"Close"}`)}</span>
                                </>
                            }
                        ></IconButton>
                    </PanelHeaderTool>
                </PanelHeader>
                {propertyPanel.pending ? (
                    <Loading></Loading>
                ) : (
                    <React.Fragment>
                        {propertyPanel.accessError || propertyPanel.error ? ( //접근 권한이 없는 경우
                            <PanelPermissionError
                                handleShareRequestModal={handleShareRequestModal}
                                panelType={propertyPanel.type}
                            ></PanelPermissionError>
                        ) : (
                            <PanelBody>
                                {propertyPanel.isShow && propertyPanel.data ? (
                                    <React.Fragment>
                                        <PanelBodyMedia
                                            className={`type-${
                                                propertyPanel.data.mediaType
                                                    ? propertyPanel.data.mediaType.toLowerCase()
                                                    : ""
                                            }`}
                                        >
                                            <ContentsView
                                                type={getContentViewData().mediaType}
                                                isInStorageTab={true}
                                                data={getContentViewData()}
                                            />
                                        </PanelBodyMedia>
                                        <PanelBodyInfo
                                            propertyPanel={propertyPanel}
                                            onClickClose={onClickClose}
                                        ></PanelBodyInfo>
                                    </React.Fragment>
                                ) : (
                                    <div>{t(`common::label::${"Not found"}`)}</div>
                                )}
                            </PanelBody>
                        )}
                    </React.Fragment>
                )}
            </VersionedComponent>
        </React.Fragment>
    );
};

export default Panel;
