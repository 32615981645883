import { Autocomplete, Box, Chip, Grid, TextField } from "@mzc-pdc/ui";
import React, { useEffect } from "react";
import Typography from "@components_v2/typography/typography";
import theme from "@styles/theme";

const SelectWithSubLabel = ({ items, itemProps, subItemProps, ...props }) => {
    const [value, setValue] = React.useState([]);

    const onHandleChange = (newValue, reason) => {
        const { onDelete, onAdd, onChange } = props;

        if (reason === "clear") {
            setValue(newValue);
            props.onClear();
        } else {
            const deleteItem = value.filter((el) => newValue.find((v) => v.value === el.value) === undefined);
            const addItem = newValue.filter((el) => value.find((v) => v.value === el.value) === undefined);

            if (deleteItem.length > 0) {
                onDelete && onDelete(deleteItem[0]);
            }

            if (addItem.length > 0) {
                onAdd && onAdd(addItem[0]);
            }

            setValue(newValue);
            onChange && onChange(newValue);
        }
    };

    useEffect(() => {
        if (!props) return;
        setValue(props.value ?? []);
    }, [props.value]);

    return (
        <Autocomplete
            multiple
            value={value}
            id="tags-outlined"
            options={items ?? []}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderOption={(props, option) => (
                <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                    <Grid container flexDirection={"column"}>
                        <Typography variant={itemProps.variant} color={itemProps.color}>
                            {option.label}
                        </Typography>
                        {option.subLable && (
                            <Typography variant={subItemProps.variant} color={subItemProps.color}>
                                {option.subLable}
                            </Typography>
                        )}
                    </Grid>
                </Box>
            )}
            renderTags={(values, getTagProps) =>
                values.map((value, index) => (
                    <Chip
                        label={value.label}
                        size="small"
                        color={"primary"}
                        sx={{ color: theme.palette.secondary[800] }}
                        {...getTagProps({ index })}
                    />
                ))
            }
            onChange={(event, newValue, reason) => {
                onHandleChange(newValue, reason);
            }}
            renderInput={(params) => (
                <TextField {...params} placeholder={value.length === 0 ? props.placeholder : ""} />
            )}
        />
    );
};

export default SelectWithSubLabel;
