import { useSelector } from "react-redux";
import { useMemo } from "react";

const useCommentSelector = ({ domain }) => {
    if (domain === "PROJECT") {
        const resourceData = useSelector(({ commonComments }) => commonComments.resourceData);
        const comments = useSelector(({ commonComments }) => commonComments.comment);
        const threads = useSelector(({ commonComments }) => commonComments.thread);
        const selectedThreadId = useSelector(({ commonComments }) => commonComments.selectedThreadId);
        const selectedCommentId = useSelector(({ commonComments }) => commonComments.selectedCommentId);
        const hasLinkThreadLogicExecuted = useSelector(
            ({ commonComments }) => commonComments.hasLinkThreadLogicExecuted,
        );
        const isEditingCommentId = useSelector(({ commonComments }) => commonComments.isEditingCommentId);
        const uploadingComments = useSelector(({ commonComments }) => commonComments.uploadingComments);
        const isLinkCommentUsed = useSelector(({ commonComments }) => commonComments.isLinkCommentUsed);

        const selectedComment = useMemo(() => {
            if (!comments?.data?.results?.length || !selectedCommentId) return null;
            return comments.data.results.find((commonComments) => commonComments.id === selectedCommentId);
        }, [comments.data, selectedCommentId]);

        const selectedThread = useMemo(() => {
            if (!resourceData || !selectedThreadId) return null;
            return resourceData.results?.find((thread) => thread.id === selectedThreadId);
        }, [resourceData, selectedThreadId]);

        return {
            resourceData,
            comments,
            threads,
            selectedThreadId,
            selectedCommentId,
            hasLinkThreadLogicExecuted,
            isEditingCommentId,
            selectedComment,
            selectedThread,
            isLinkCommentUsed,
            uploadingComments,
        };
    } else {
        const resourceData = useSelector(({ comments }) => comments.resourceData);
        const comments = useSelector(({ comments }) => comments.comment);
        const threads = useSelector(({ comments }) => comments.thread);
        const selectedThreadId = useSelector(({ comments }) => comments.selectedThreadId);
        const selectedCommentId = useSelector(({ comments }) => comments.selectedCommentId);
        const hasLinkThreadLogicExecuted = useSelector(({ comments }) => comments.hasLinkThreadLogicExecuted);
        const isEditingCommentId = useSelector(({ comments }) => comments.isEditingCommentId);
        const isLinkCommentUsed = useSelector(({ comments }) => comments.isLinkCommentUsed);
        const uploadingComments = useSelector(({ comments }) => comments.uploadingComments);

        const selectedComment = useMemo(() => {
            if (!comments?.data?.results?.length || !selectedCommentId) return null;
            return comments.data.results.find((comment) => comment.id === selectedCommentId);
        }, [comments.data, selectedCommentId]);

        const selectedThread = useMemo(() => {
            if (!resourceData || !selectedThreadId) return null;
            return resourceData.results?.find((thread) => thread.id === selectedThreadId);
        }, [resourceData, selectedThreadId]);

        return {
            resourceData,
            comments,
            threads,
            selectedThreadId,
            selectedCommentId,
            hasLinkThreadLogicExecuted,
            isEditingCommentId,
            selectedComment,
            selectedThread,
            isLinkCommentUsed,
            uploadingComments,
        };
    }
};

export default useCommentSelector;
