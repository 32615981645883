import { styled, Grid as MuiGrid, alpha, Card as MuiCard } from "@mui/material";

export const StyledContentTypeThumbnail = styled("div", {
    shouldForwardProp: (prop) => !["variant", "status"].includes(String(prop)),
})(({ theme, variant, status, selected }) => ({
    position: `relative`,
    display: `inline-flex`,
    alignItems: `center`,
    justifyContent: `center`,
    backgroundColor: alpha(theme.palette.greySecondary[50], 0.5),
    borderRadius: `4px`,
    boxSizing: `border-box`,
    ...(variant === "card" && {
        padding: `12px`,
        borderRadius: `8px`,
        "&:hover": {
            backgroundColor: theme.palette.greySecondary[50],
        },
        ...(selected && {
            backgroundColor: `${alpha(theme.palette.greySecondary.A100, 0.6)} !important`,
        }),
        img: {
            maxWidth: `100%`,
            maxHeight: `100%`,
            objectFit: `cover`,
            borderRadius: `4px`,
        },
    }),
    ...(variant === "table" && {
        img: {
            width: `100%`,
            height: `100%`,
            objectFit: `cover`,
            borderRadius: `4px`,
        },
    }),
}));
