import { createAction, handleActions } from "redux-actions";
import { CONTENT_DETAIL_ACTION_TYPE, LIST_VIEW_TYPE, METADATA_FIELDS_TYPE } from "@constants";
import { parseTypeToDetailType, parseTypeToFieldType, parseEnumType } from "@modules/content-schema";
import { identical } from "lodash/fp";
import { getContentSchemaDetailAPI } from "@modules/apis/content-schema";
import {
    getCustomContentDetailAPI,
    getCustomContentFieldAPI,
    updateCustomContentActionsAPI,
    updateCustomContentAPI,
} from "@modules/apis/custom-content";
import { updateCollectionAPI } from "@modules/apis/collection";
import { getCategoryGroup } from "@modules/categoryGroupsManagement";

const initialState = {
    data: null,
    resources: null,
    pending: false,
    error: false,
};

const GET_CUSTOM_CONTENT_DETAIL_PENDING = "GET_CUSTOM_CONTENT_DETAIL_PENDING";
const GET_CUSTOM_CONTENT_DETAIL_SUCCESS = "GET_CUSTOM_CONTENT_DETAIL_SUCCESS";
const GET_CUSTOM_CONTENT_DETAIL_FAILURE = "GET_CUSTOM_CONTENT_DETAIL_FAILURE";
const SET_CUSTOM_CONTENT_RESOURCES = "SET_CUSTOM_CONTENT_RESOURCES";
const RESET_CUSTOM_CONTENT_RESOURCES = "RESET_CUSTOM_CONTENT_RESOURCES";
const UPDATE_CUSTOM_CONTENT_SUCCESS = "UPDATE_CUSTOM_CONTENT_SUCCESS";
const UPDATE_CUSTOM_CONTENT_ACTION_SUCCESS = "UPDATE_CUSTOM_CONTENT_ACTION_SUCCESS";
const GET_CUSTOM_CONTENT_FIELD_SUCCESS = "GET_CUSTOM_CONTENT_FIELD_SUCCESS";
const UPDATE_CUSTOM_CONTENT_FIELD = "UPDATE_CUSTOM_CONTENT_FIELD";
const UPDATE_CUSTOM_CONTENT_FIELD_HIDDEN = "UPDATE_CUSTOM_CONTENT_FIELD_HIDDEN";

export const updateCustomContentResources = createAction(SET_CUSTOM_CONTENT_RESOURCES);
export const updateCustomContentField = createAction(UPDATE_CUSTOM_CONTENT_FIELD);
export const resetCustomContentResources = createAction(RESET_CUSTOM_CONTENT_RESOURCES);
export const updateCustomContentFieldHidden = createAction(UPDATE_CUSTOM_CONTENT_FIELD_HIDDEN);

export default handleActions(
    {
        [GET_CUSTOM_CONTENT_DETAIL_PENDING]: (state, action) => {
            return {
                ...state,
                data: null,
                pending: true,
                error: null,
            };
        },
        [GET_CUSTOM_CONTENT_DETAIL_SUCCESS]: (state, action) => {
            return {
                ...state,
                data: action.payload,
                pending: false,
            };
        },
        [GET_CUSTOM_CONTENT_DETAIL_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: action.payload,
            };
        },
        [SET_CUSTOM_CONTENT_RESOURCES]: (state, action) => {
            const prevResource = state.resources?.findIndex((resource) => resource.id === action.payload.id);

            if (prevResource !== undefined && prevResource !== -1) {
                const newResources = [...(state.resources ?? [])];
                newResources[prevResource] = { id: action.payload.id, value: action.payload.value };

                return { ...state, resources: newResources };
            } else {
                return {
                    ...state,
                    resources: [...(state.resources ?? []), action.payload],
                };
            }
        },
        [RESET_CUSTOM_CONTENT_RESOURCES]: (state, action) => {
            return {
                ...initialState,
            };
        },
        [UPDATE_CUSTOM_CONTENT_SUCCESS]: (state, action) => {
            return {
                ...state,
                data: { ...state.data, ...action.payload },
            };
        },
        [UPDATE_CUSTOM_CONTENT_ACTION_SUCCESS]: (state, action) => {
            if (action.payload.action === CONTENT_DETAIL_ACTION_TYPE.OVERWRITE_FIELD_VALUES) {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        fields: state.data.fields.map((field) => {
                            if (field?.fieldType?.dataType?.isReference) return field;

                            if (field.id === action.payload.data.fields[0].id) {
                                if (
                                    [METADATA_FIELDS_TYPE.MULTI_SELECT, METADATA_FIELDS_TYPE.SINGLE_SELECT].includes(
                                        field.fieldType?.dataType?.detailType,
                                    )
                                ) {
                                    return {
                                        ...parseEnumType(
                                            { ...field, value: action.payload.data.fields[0].value },
                                            "detail",
                                        ),
                                        value: action.payload.data.fields[0].value,
                                    };
                                } else {
                                    return { ...field, value: action.payload.data.fields[0].value };
                                }
                            } else return field;
                        }),
                    },
                };
            }

            if (action.payload.action === CONTENT_DETAIL_ACTION_TYPE.OVERWRITE_CATEGORIES) {
                return {
                    ...state,
                    data: { ...state.data, categories: action.payload.data.categories },
                };
            }
            return {
                ...state,
                data: { ...state.data, ...action.payload.data },
            };
        },
        [UPDATE_CUSTOM_CONTENT_FIELD]: (state, action) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    fields: state.data.fields.map((field) => {
                        if (field.id === action.payload.id) return { ...field, value: action.payload.value }; //action.payload;
                        return field;
                    }),
                },
            };
        },
        [UPDATE_CUSTOM_CONTENT_FIELD_HIDDEN]: (state, action) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    fields: state.data.fields.map((field) => {
                        if (field.id === action.payload.id)
                            return {
                                ...field,
                                isCheckHiddenValue: action.payload.isCheckHiddenValue,
                                value: action.payload.value,
                            };
                        return field;
                    }),
                },
            };
        },
    },
    initialState,
);

export const getCustomContent =
    ({ pageType, schemaId, contentId }) =>
    async (dispatch, getState) => {
        const { stage, project } = getState();

        return new Promise(async (resolve, reject) => {
            try {
                dispatch({ type: GET_CUSTOM_CONTENT_DETAIL_PENDING });

                let response = null;
                if (pageType === "create")
                    response = await getContentSchemaDetailAPI(stage.id, stage.endpoint, project.id, schemaId);
                else {
                    response = await getCustomContentDetailAPI(stage.endpoint, project.id, contentId);

                    let categoryGroupId = response?.data?.categories?.[0]?.groupId;
                    if (categoryGroupId) {
                        const categoryGroupInfo = await dispatch(getCategoryGroup(project.id, categoryGroupId));

                        if (categoryGroupInfo) {
                            response.data.categories = response.data.categories.map((category) => ({
                                ...category,
                                groupName: categoryGroupInfo.name,
                            }));
                        }
                    }
                }

                const newData = response.data?.fields?.map((v) => {
                    const dataType = v.fieldType?.dataType;
                    const newDataType = parseTypeToFieldType(dataType);
                    const newField = { ...v, fieldType: { ...v.fieldType, dataType: { ...newDataType } } };
                    const parsedField = parseEnumType(newField, pageType);

                    return { ...parsedField };
                });

                dispatch({ type: GET_CUSTOM_CONTENT_DETAIL_SUCCESS, payload: { ...response.data, fields: newData } });
                resolve();
            } catch (error) {
                dispatch({ type: GET_CUSTOM_CONTENT_DETAIL_FAILURE, payload: error });
                reject(error);
            }
        });
    };

export const updateCustomContentActions =
    ({ contentId, params }) =>
    (dispatch, getState) => {
        const { stage, project } = getState();
        return new Promise(async (resolve, reject) => {
            try {
                const { key, value } = params;
                const action = `OVERWRITE_${key === "fields" ? "FIELD_VALUES" : key.toUpperCase()}`;

                const actionKey = key === "categories" ? "categoryIds" : key;
                const actionValue = key === "categories" ? value.map(({ id }) => id.toString()) : value;

                const response = await updateCustomContentActionsAPI(stage.endpoint, project.id, contentId, {
                    action,
                    data: { [actionKey]: actionValue },
                });

                dispatch({
                    type: UPDATE_CUSTOM_CONTENT_ACTION_SUCCESS,
                    payload: {
                        action,
                        data: { [key]: value },
                    },
                });
                resolve(response);
            } catch (e) {
                reject(e);
            }
        });
    };

export const updateCustomContent =
    ({ contentId, params }) =>
    (dispatch, getState) => {
        const { stage, project } = getState();

        return new Promise((resolve, reject) => {
            updateCustomContentAPI(stage.endpoint, project.id, contentId, params)
                .then(async (response) => {
                    let { fields, ...rest } = response.data;

                    dispatch({ type: UPDATE_CUSTOM_CONTENT_SUCCESS, payload: rest });
                    resolve({ fields, rest });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

export const getCustomContentField =
    ({ contentId, fieldId }) =>
    async (dispatch, getState) => {
        const { stage, project } = getState();

        return new Promise(async (resolve, reject) => {
            try {
                const response = await getCustomContentFieldAPI(stage.endpoint, project.id, contentId, fieldId);
                // dispatch({ type: GET_CUSTOM_CONTENT_FIELD_SUCCESS, payload: response.data });
                resolve(response.data);
            } catch (error) {
                reject(error);
            }
        });
    };
