import * as React from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Typography } from "@mzc-pdc/ui";
import theme from "@styles/theme";
import UserList from "@features/user-list/user-list";
import { GroupTitleWithIcon } from "@features/comments/groups";
import { UsersMultiInput, UsersMultiInputV2 } from "@features/users-input";
import { useCallback, useMemo, useState } from "react";
import validateInput from "@cores/validateInput";
import compareVersions, { VERSION_CM_11330_USERS_TEAMS_ACCESS_V3 } from "@cores/version";
import { useSelector } from "react-redux";

export const DialogEditGroupMembers = ({ data, isLoading, onSubmit, onClose }) => {
    const { t } = useTranslation();

    const stage = useSelector(({ stage }) => stage);

    const [editValue, setEditValue] = React.useState({});
    const [validationErrors, setValidationErrors] = useState({});

    const setNewValidationErrors = (key, value) => {
        const newValidationErrors = { ...validationErrors, [key]: value };
        Object.keys(newValidationErrors ?? {}).forEach(
            (k) => newValidationErrors[k] == null && delete newValidationErrors[k],
        );
        setValidationErrors(newValidationErrors);
    };

    const onHandleChangeEditValue = (key, value) => {
        setNewValidationErrors("members", validateInput(value, { required: true }));
        setEditValue({ ...editValue, [key]: value });
    };

    const onHandleSubmit = useCallback(() => {
        const members = editValue.members.map((member) => ({ type: member.type.toUpperCase(), id: member.id }));
        const params = {
            participants: members,
        };
        onSubmit(params);
    }, [editValue]);

    const isDisabled = useMemo(() => {
        return Object.keys(validationErrors).length > 0 || !editValue.members?.length;
    }, [validationErrors, editValue]);

    const allMembers = useMemo(() => data.members?.results?.concat(editValue.members ?? []), [data]);

    return (
        <Dialog
            variant={"basic"}
            open={true}
            onClose={() => onClose()}
            sx={{ ".MuiPaper-root": { width: "480px", maxWidth: "480px" } }}
        >
            <DialogTitle>
                <Stack direction={"row"} gap={1} alignItems={"center"}>
                    <GroupTitleWithIcon iconSize={24} fontVariant={"h4"} isPublic={data.isPublic} title={data.name} />
                    <Typography variant={"h4"} color={theme.palette.greySecondary.main}>
                        {t(`common::label::member`)}
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Grid container flexDirection={"column"} gap={2.5}>
                    {!data.isPublic && (
                        <Grid item>
                            <Stack gap={1}>
                                <Typography variant={"body1"} color={theme.palette.greySecondary.main}>
                                    {t(`common::label::Add member`)}
                                </Typography>

                                {compareVersions(stage.version, VERSION_CM_11330_USERS_TEAMS_ACCESS_V3) >= 0 ? (
                                    <UsersMultiInputV2
                                        exceptUsers={allMembers}
                                        filterSelectedOptions={true}
                                        placeholder={t(`common::msg::Add users to participate in private comments.`)}
                                        onChange={(users) => onHandleChangeEditValue("members", users)}
                                    />
                                ) : (
                                    <UsersMultiInput
                                        exceptUsers={allMembers}
                                        filterSelectedOptions={true}
                                        placeholder={t(`common::msg::Add users to participate in private comments.`)}
                                        onChange={(users) => onHandleChangeEditValue("members", users)}
                                    />
                                )}
                            </Stack>
                        </Grid>
                    )}
                    <Grid item>
                        <Stack gap={1}>
                            <Typography
                                variant={"subtitle2_500"}
                                color={theme.palette.greySecondary.main}
                                required={true}
                                requiredSize={"small"}
                            >
                                {t(`common::label::Member`)}
                                {` `}
                                <Typography variant={"subtitle2_700"} color={theme.palette.primary.main}>
                                    {data.members?.totalCount ?? 0}
                                </Typography>
                            </Typography>
                            <UserList isPublic={data.isPublic} data={data.members} />
                        </Stack>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <>
                    {data.isPublic ? (
                        <Button variant={`contained`} color={`primary`} onClick={() => onClose()}>
                            {t(`common::label::Check`)}
                        </Button>
                    ) : (
                        <>
                            <Button variant={`text`} onClick={() => onClose()} color={`greySecondary`}>
                                {t(`common::label::Cancel`)}
                            </Button>
                            <Button
                                variant={`contained`}
                                disabled={isLoading || isDisabled}
                                loading={isLoading}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onHandleSubmit();
                                }}
                                color={`primary`}
                            >
                                {t(`common::label::Add member`)}
                            </Button>
                        </>
                    )}
                </>
            </DialogActions>
        </Dialog>
    );
};
