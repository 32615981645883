import {handleActions, createAction} from 'redux-actions';

import {
    axios,
    axiosWithoutHeaders
} from "../cores/axiosWrapper";
import queryString from "../cores/queryString";
import moment from 'moment';

function getEventTriggersAPI(stageId, apiEndpoint, projectId, search) {
    const queries = {
        offset : search.offset,
        limit : search.limit
    };

    queries.eventType = search.eventType;
    queries.creator = search.creator;
    
    if(search.keyword) {
        switch(search.type) {
            case "name" : 
                queries.name = search.keyword;
                break;
            case "id" : 
                queries.id = search.keyword;
                break;
        }    
    }    

    if(search.startAt && search.endAt) {
        const startAt = `${moment(`${search.startAt.format('YYYY-MM-DD')} 00:00:00`).utc().format('YYYY-MM-DD')}T${moment(`${search.startAt.format('YYYY-MM-DD')} 00:00:00`).utc().format('HH:mm:ss')}Z`;
        const endAt = `${moment(`${search.endAt.format('YYYY-MM-DD')} 00:00:00`).utc().format('YYYY-MM-DD')}T${moment(`${search.endAt.format('YYYY-MM-DD')} 00:00:00`).utc().format('HH:mm:ss')}Z`;

        queries.createdAt = `${startAt}~${endAt}`;
    }    

    return axios.get(`${apiEndpoint}/triggers?${queryString.stringify(queries)}`, {
        headers: {
            stageId,
            projectId
        }
    });
}

function deleteEventTriggerAPI(stageId, apiEndpoint, projectId, id) {
    return axios.delete(`${apiEndpoint}/triggers/${id}`, {
        headers: {
            stageId,
            projectId
        }
    });
}

//action type
const EVENT_TRIGGER_MANAGEMENT_LIST_PENDING = 'EVENT_TRIGGER_MANAGEMENT_LIST_PENDING';
const EVENT_TRIGGER_MANAGEMENT_LIST_FAILURE = 'EVENT_TRIGGER_MANAGEMENT_LIST_FAILURE';

const EVENT_TRIGGER_MANAGEMENT_LIST_UPDATE_SEARCH = 'EVENT_TRIGGER_MANAGEMENT_LIST_UPDATE_SEARCH';
const EVENT_TRIGGER_MANAGEMENT_LIST_RESET_SEARCH = 'EVENT_TRIGGER_MANAGEMENT_LIST_RESET_SEARCH';

const EVENT_TRIGGER_MANAGEMENT_LIST_GET_EVENT_TRIGGER_LIST_SUCCESS = 'EVENT_TRIGGER_MANAGEMENT_LIST_GET_EVENT_TRIGGER_LIST_SUCCESS';
const EVENT_TRIGGER_MANAGEMENT_LIST_DELETE_EVENT_TRIGGER_SUCCESS = 'EVENT_TRIGGER_MANAGEMENT_LIST_DELETE_EVENT_TRIGGER_SUCCESS';

const EVENT_TRIGGER_MANAGEMENT_LIST_TOGGLE_ACTION_BUTTON = 'EVENT_TRIGGER_MANAGEMENT_LIST_TOGGLE_ACTION_BUTTON';

//reducer
const initialState = {
    pending: false,
    error: false,
    data : [],
    totalCount : 0,
    search : {
        offset : 0,    
        limit : 50,  
        keyword : '',
        type : 'name',
        eventType : '',
        creator : '',
        startAt : null,
        endAt : null,
    }
};

export default handleActions({
    [EVENT_TRIGGER_MANAGEMENT_LIST_PENDING] : (state, action) => {
        return {
            ...state,
            pending : true
        };
    },
    [EVENT_TRIGGER_MANAGEMENT_LIST_FAILURE] : (state, action) => {
        return {
            ...state,
            error : true,
            pending : false
        };
    },
    [EVENT_TRIGGER_MANAGEMENT_LIST_GET_EVENT_TRIGGER_LIST_SUCCESS] : (state, action) => {       
        const { data } = action.payload;

        return {
            ...state,
            data : data.eventTriggers,
            totalCount : data.totalCount,
            pending : false
        };
    },
    [EVENT_TRIGGER_MANAGEMENT_LIST_UPDATE_SEARCH] : (state, action) => {
        return {
            ...state,
            search : {
                ...state.search, 
                ...action.payload
            }
        };
    },
    [EVENT_TRIGGER_MANAGEMENT_LIST_RESET_SEARCH] : (state, action) => {
        return {
            ...state,
            search : {
                offset : 0,    
                limit : 50,  
                keyword : '',
                type : 'name',
                eventType : '',
                creator : '',
                startAt : null,
                endAt : null,
            }
        };
    },
    [EVENT_TRIGGER_MANAGEMENT_LIST_TOGGLE_ACTION_BUTTON] : (state, action) => {
        return {
            ...state,
            data : state.data.map(v => {
                return {
                    ...v,
                    isShowActionButton: v.id === action.payload ? !v.isShowActionButton : false
                };
            })
        }   
    },
    [EVENT_TRIGGER_MANAGEMENT_LIST_DELETE_EVENT_TRIGGER_SUCCESS] : (state, action) => {
        return {
            ...state,
            data : state.data.map(v => {
                return {
                    ...v,
                    isDeleted: v.id === action.payload
                };
            })
        };
    }
}, initialState);

export const updateSearch = createAction(EVENT_TRIGGER_MANAGEMENT_LIST_UPDATE_SEARCH);
export const resetSearch = createAction(EVENT_TRIGGER_MANAGEMENT_LIST_RESET_SEARCH);
export const toggleActionButton = createAction(EVENT_TRIGGER_MANAGEMENT_LIST_TOGGLE_ACTION_BUTTON);

export const getEventTriggers = (projectId) => (dispatch, getState) => {
    const {
        stage,
        eventTriggerManagements
    } = getState();

    dispatch({type: EVENT_TRIGGER_MANAGEMENT_LIST_PENDING});

    getEventTriggersAPI(stage.id, stage.endpoint, projectId, eventTriggerManagements.search)
        .then((response) => {
                dispatch({
                    type: EVENT_TRIGGER_MANAGEMENT_LIST_GET_EVENT_TRIGGER_LIST_SUCCESS,
                    payload: response
                });
            }
        ).catch(error => {
            dispatch({
                type: EVENT_TRIGGER_MANAGEMENT_LIST_FAILURE,
                payload: error
            });
        });
};

export const deleteEventTrigger = (projectId, id) => (dispatch, getState) => {
    const {
        stage
    } = getState();

    dispatch({type: EVENT_TRIGGER_MANAGEMENT_LIST_PENDING});

    deleteEventTriggerAPI(stage.id, stage.endpoint, projectId, id)
        .then(() => {
                dispatch({
                    type: EVENT_TRIGGER_MANAGEMENT_LIST_DELETE_EVENT_TRIGGER_SUCCESS,
                    payload: id
                });
            }
        ).catch(error => {
            dispatch({
                type: EVENT_TRIGGER_MANAGEMENT_LIST_FAILURE,
                payload: error
            });
        });
};