import React, { Component } from "react";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Loadable from "@react-loadable/revised";
import fp from "lodash/fp";
import { bindActionCreators } from "redux";
import { ImageCategoryGroupOn } from "@mzc-cloudplex/icons";
import MetadataUseStatusTooltip from "../../components/tooltip/MetadataUseStatusTooltip";
import { CATEGORY_TYPES } from "@constants";
import { Grid, Typography } from "@mzc-pdc/ui";

const LoadableSelectCategoriesModal = Loadable({
    loader: () => import("../../components/modals/SelectCategories"),
    loading: () => null,
});

const LoadableSelectCategoryGroupModal = Loadable({
    loader: () => import("../../components/modals/SelectCategoryGroup"),
    loading: () => null,
});

//TODO multi select 통합 필요 -> upload job cateogry
class Categories extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectCategoriesModal: false,
            selectCategoryGroupModal: false,
            selectedValues: [],
            categoryGroup: null,
        };
    }

    _componentUmounted = false;

    componentWillUnmount = () => {
        this._componentUmounted = true;
    };
    componentDidMount = async () => {
        this.setState({ ...this.state, selectedValues: this.props.selectedValues });
    };

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (this._componentUmounted) {
            return;
        }

        if (!fp.isEqual(prevProps.selectedValues, this.props.selectedValues)) {
            this.setState({ ...this.state, selectedValues: this.props.selectedValues });
        }
    };

    onDeleteSelectedCategory = (deleteItem) => {
        const categories = this.state.selectedValues.filter((v) => v.id != deleteItem.id);
        if (this.props.categoryGroups) {
            this.props.onChange({
                categories: categories,
                categoryGroup: this.props.selectedCategoryGroup,
                inUse: this.state.projectCategoryUseStatue,
            });
        } else {
            this.props.onChange(categories);
        }
    };

    onDeleteSelectedCategoryAll = (deleteItems) => {
        if (this.props.categoryGroups) {
            this.props.onChange({ categories: deleteItems, categoryGroup: this.props.selectedCategoryGroup });
        } else {
            this.props.onChange(deleteItems);
        }
    };

    openSelectCategories = () => {
        if (!this.state.selectCategoriesModal) {
            this.props.onChangeModalOpen(!this.state.selectCategoriesModal);
        }

        this.setState({
            ...this.state,
            selectCategoriesModal: !this.state.selectCategoriesModal,
        });
    };

    openSelectCategoriesGroup = () => {
        if (!this.state.selectCategoryGroupModal) {
            this.props.onChangeModalOpen(!this.state.selectCategoryGroupModal);
        }

        this.setState({
            ...this.state,
            selectCategoryGroupModal: !this.state.selectCategoryGroupModal,
        });
    };

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                {this.state.selectCategoriesModal &&
                    ReactDOM.createPortal(
                        <LoadableSelectCategoriesModal
                            initStateEvent={this.openSelectCategories}
                            type={this.props.type}
                            projectId={this.props.projectId}
                            selectedCategories={this.state.selectedValues}
                            selectedCategoryGroup={this.props.selectedCategoryGroup}
                            onSelectedCategories={(option, categoryGroup) => this.props.onChange(option, categoryGroup)}
                            onModalClose={(v) => {
                                this.props.onChangeModalOpen(v);
                            }}
                        />,
                        document.body,
                    )}

                {this.state.selectCategoryGroupModal &&
                    ReactDOM.createPortal(
                        <LoadableSelectCategoryGroupModal
                            initStateEvent={this.openSelectCategoriesGroup}
                            type={this.props.type}
                            projectId={this.props.projectId}
                            selectedCategories={this.props.selectedValues}
                            selectedCategoryGroup={this.props.selectedCategoryGroup}
                            onSelectedCategories={(option) => this.props.onChange(option)}
                            onModalClose={(v) => {
                                this.props.onChangeModalOpen(v);
                            }}
                        />,
                        document.body,
                    )}

                {this.props.categoryGroups &&
                    (this.props.selectedValues?.length > 0 && this.props.selectedCategoryGroup.id ? (
                        <Grid container alignItems={"center"} gap={0.75} mb={1}>
                            <ImageCategoryGroupOn width={16} height={16} />
                            <Typography variant={"body1_700"}>{this.props.selectedCategoryGroup?.label}</Typography>
                            {![CATEGORY_TYPES.METADATA_TEMPLATE, CATEGORY_TYPES.CUSTOM_CONTENTS].includes(
                                this.props.type,
                            ) && (
                                <MetadataUseStatusTooltip
                                    id={this.props.id}
                                    tooltipDisabled={this.props.tooltipDisabled}
                                    anchorOrigin={{
                                        vertical: "center",
                                        horizontal: "right",
                                    }}
                                    transformOrigin={{
                                        vertical: "center",
                                        horizontal: "left",
                                    }}
                                    categoryGroupId={this.props.selectedCategoryGroup.id}
                                    categoryType={this.props.type}
                                />
                            )}
                        </Grid>
                    ) : (
                        <Grid container alignItems={"center"} gap={0.75} mb={1}>
                            <ImageCategoryGroupOn width={16} height={16} />
                            <Typography variant={"body1_500"} color={"text.cancel"}>
                                {t(`common::label::Select Category Group`)}
                            </Typography>
                        </Grid>
                    ))}

                <div
                    className={`category-select ${this.props.isEdit && "is-edit"}`}
                    style={{
                        ...(this.props.isEdit && {
                            "& .MuiOutlinedInput-root": {
                                backgroundColor: `#fff`,
                                borderRadius: "2px",
                                "> fieldset": { borderColor: "#2763ea" },
                            },
                        }),
                    }}
                >
                    <div className={"category-group"}>
                        {!this.props.selectedValues || this.props.selectedValues.length <= 0 ? (
                            <div className={"category-label-empty"}>{this.props.placeholder}</div>
                        ) : (
                            <div className={"category-label-group"}>
                                {this.props.selectedValues &&
                                    this.props.selectedValues.map((category) => (
                                        <span
                                            className={"category-label"}
                                            data-tooltip={t(`${category.label}`)}
                                            key={category.id}
                                        >
                                            <span>{category.label}</span>
                                            <button
                                                type="button"
                                                onClick={(e) => {
                                                    this.onDeleteSelectedCategory(category);
                                                }}
                                            >
                                                <i className="sprite sprite-cancel-fade"></i>
                                                {t(`common::label::Remove`)}
                                            </button>
                                        </span>
                                    ))}
                            </div>
                        )}
                        <button
                            type={"button"}
                            className={"btn-delete"}
                            hidden={!this.props.selectedValues || this.props.selectedValues.length <= 0}
                            onClick={(e) => {
                                this.onDeleteSelectedCategoryAll([]);
                            }}
                        >
                            <i className={"sprite sprite-canceled"}></i>
                        </button>
                        {this.props.categoryGroups ? (
                            <button
                                type={"button"}
                                className={"btn-add"}
                                onClick={(e) => {
                                    e.preventDefault();

                                    this.openSelectCategoriesGroup();
                                }}
                            >
                                <i className={"sprite sprite-plus"}></i>
                            </button>
                        ) : (
                            <button
                                type={"button"}
                                className={"btn-add"}
                                onClick={(e) => {
                                    e.preventDefault();

                                    this.openSelectCategories();
                                }}
                            >
                                <i className={"sprite sprite-plus"}></i>
                            </button>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        stage: state.stage,
        project: state.project,
    };
};
let mapDispatchToProps = (dispatch) => {
    return {};
};

Categories = connect(mapStateToProps, mapDispatchToProps)(Categories);

Categories.defaultProps = {
    onChangeModalOpen: () => {},
    selectedCategoryGroup: {},
};
export default withTranslation()(Categories);
