import menuService from "@services/menuService";
import React, { useCallback, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import JobEmptyList from "@routes/rightsidebar/ui/job-empty-list";
import { Divider, Grid, IconButton, Stack, Tooltip } from "@mzc-pdc/ui";
import { useDispatch, useSelector } from "react-redux";
import { cancelTranscode } from "@modules/upload";
import { JOB_ACTIONS, JOB_ID_LCOAL_STORAGE_KEY } from "@constants";
import { GridNameEllipsis } from "@components_v2/grid/grid.styled";
import { SelectLimitTags } from "@components_v2/select";
import JobItem from "@routes/rightsidebar/ui/job-item";

const TranscodeJobList = ({ filter, jobIcon, summaries, taskComponent }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [isFolded, setIsFolded] = useState({});
    const [selectedFilter, setSelectedFilter] = useState({});

    const jobs = useSelector((state) => state.upload.jobs.data);
    const stage = useSelector(({ stage }) => stage);
    const project = useSelector(({ project }) => project);

    const onClickCancel = async (id) => {
        try {
            await dispatch(cancelTranscode(id));
            const storedTranscodeJobs = localStorage.getItem(JOB_ID_LCOAL_STORAGE_KEY).split(",");
            const newTranscodeJobs = storedTranscodeJobs.filter((jobId) => jobId !== id);
            localStorage.setItem(JOB_ID_LCOAL_STORAGE_KEY, newTranscodeJobs.join(","));
        } catch (e) {
            console.log(e);
        }
    };

    const toggleJobIsExpanded = useCallback(
        (job) => {
            setIsFolded((prev) => ({ ...prev, [job.id]: prev[job.id] === undefined ? false : !prev[job.id] }));
        },
        [isFolded],
    );

    const onHandleClickFilter = useCallback(
        (id, value) => {
            setSelectedFilter({
                ...selectedFilter,
                [id]: value,
            });
        },
        [selectedFilter],
    );

    const filteredJobsData = useMemo(() => {
        const filteredJobsData = jobs.filter((job) =>
            Object.keys(selectedFilter)
                .filter((key) => selectedFilter[key]?.length)
                .every((key) => selectedFilter[key].includes(job[key])),
        );

        const addValueToSummaries = (job) => {
            return summaries.map(({ key, ...rest }) => {
                if (rest?.id === "link") {
                    return {
                        ...rest,
                        value: job[key],
                        to: !["SUBMITTED", "RUNNING"].includes(job.status)
                            ? menuService.paths.jobDetail(stage.id, project.id, job.id)
                            : undefined,
                    };
                }
                return {
                    ...rest,
                    value: job[key],
                };
            });
        };

        const getTaskDetailComponent = (job) => {
            const TaskDetailComponent = taskComponent?.[job.action];

            if (TaskDetailComponent) {
                return <TaskDetailComponent job={{ status: job.status }} tasks={job.inputFiles} />;
            }
            return null;
        };

        return filteredJobsData.map((job) => {
            job.action = job.action ?? JOB_ACTIONS.TRANSCODE;

            return {
                job: {
                    id: job.id,
                    action: job.action,
                    icon: jobIcon[job.action],
                    status: job.status,
                    isPartialFailure: job.partialFailure,
                    exitCode: job.summary?.exitCode,
                    summaryInfo: addValueToSummaries(job),
                },
                task: {
                    taskCount: job.inputFiles.length,
                    isTaskDetailFolded: isFolded[job.id] === undefined || isFolded[job.id],
                    taskDetailComponent: getTaskDetailComponent(job),
                },
                action: {
                    toggle: () => toggleJobIsExpanded(job), //이걸 누르면 job이 바뀌면서 filteredJobsData가 바뀌고 rerender가 될텐데 괜찮나?
                    // retry: () => onRetry(job),
                    cancel: () => onClickCancel(job.id),
                },
            };
        });
    }, [stage, project, jobs, selectedFilter, filter, onClickCancel, toggleJobIsExpanded, isFolded]);

    return (
        <React.Fragment>
            <Grid container pt={3} px={3} pb={2} gap={1} justifyContent={"center"} alignItems={"center"}>
                {filter &&
                    Object.entries(filter)?.map(([key, options]) => (
                        <Grid item xs>
                            <SelectLimitTags
                                displayEmpty
                                notched
                                label={key}
                                labelId={`select-label-asset-jobs-${key}`}
                                multiple={true}
                                value={selectedFilter[key] ?? []}
                                options={options}
                                MenuProps={{
                                    disablePortal: true,
                                }}
                                onChange={(e) => {
                                    onHandleClickFilter(key, e.target.value);
                                }}
                                limitTags={1}
                            />
                        </Grid>
                    ))}
            </Grid>
            <Divider sx={{ mx: 3 }} />
            <Stack flex={1} px={3} py={2.5} gap={4} sx={{ overflowY: "auto" }}>
                {filteredJobsData.length > 0 ? (
                    <>
                        {filteredJobsData.map((data) => {
                            return <JobItem {...data} key={`transcode-job-${data.job.id}`} />;
                        })}
                    </>
                ) : (
                    <JobEmptyList />
                )}
            </Stack>
        </React.Fragment>
    );
};

export default TranscodeJobList;
