import React from "react";
import { Button } from "@mzc-pdc/ui";

const ButtonOutlineDark = (props) => (
    <Button
        {...props}
        type="button"
        variant={'outlined'}
        color={'greySecondary'}
    ></Button>
);

export default ButtonOutlineDark;
