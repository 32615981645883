import { handleActions, createAction } from "redux-actions";
import moment from "moment";
import { axios, axiosWithoutHeaders } from "../cores/axiosWrapper";
import { CONSTANTS, LIST_VIEW_TYPE } from "../constants";

function getChannelsAPI(stageId, apiEndpoint, projectId, isAdmin, params) {
    let queries = {
        q: params.keyword,
        channelId: params.channelId,
        name: params.name,
        description: params.description,
        status: params.status,
        sourceType: params.sourceType, //NONE, STREAM, LISTING
        state:
            params.state && params.state !== "" && params.state.length > 0
                ? params.state.reduce((a, v) => {
                      if (a === "") {
                          return v.value;
                      } else {
                          return a + "," + v.value;
                      }
                  }, "")
                : undefined, //STOPPING;STOPPED,
        approvalStatus:
            params.approvalStatus && params.approvalStatus !== "" && params.approvalStatus.length > 0
                ? params.approvalStatus.reduce((a, v) => {
                      if (a === "") {
                          return v.value;
                      } else {
                          return a + "," + v.value;
                      }
                  }, "")
                : isAdmin
                ? CONSTANTS("ADMIN_CHANNEL_STATUS").reduce((a, v) => {
                      if (a === "") {
                          return v.value;
                      } else {
                          return a + "," + v.value;
                      }
                  }, "")
                : undefined, //Draft;ApprovalRequested,
        owner: params.owner,
        type: params.type,
        beginDate: params.beginDate ? moment.utc(params.beginDate).format("YYYY-MM-DD") : "", //YYYY-MM-DD
        endDate: params.endDate ? moment.utc(params.endDate).format("YYYY-MM-DD") : "", //YYYY-MM-DD
        offset: params.offset * params.limit,
        limit: params.limit,
        projectId: params.projectId,
        isNotCategoryIds: params.isNotCategoryIds,
        categoryIds:
            params.categoryIds && params.categoryIds.length ? params.categoryIds.map((v) => v.id).join(",") : [],
    };

    Object.keys(queries).forEach((key) => !queries[key] && delete queries[key]);

    const headers = {
        stageId,
    };
    if (projectId) {
        headers.projectId = projectId;
    }
    return axios.get(`${apiEndpoint}/channels`, {
        params: queries,
        headers: headers,
    });
}

function stopChannelsAPI(stageId, apiEndpoint, projectId, channelId) {
    return axios.put(`${apiEndpoint}/channels/${channelId}/stop`, null, {
        headers: {
            stageId,
            projectId,
            "Content-Type": "application/json",
        },
    });
}

function startChannelsAPI(stageId, apiEndpoint, projectId, channelId) {
    return axios.put(`${apiEndpoint}/channels/${channelId}/start`, null, {
        headers: {
            stageId,
            projectId,
            "Content-Type": "application/json",
        },
    });
}

const CHANNELS_PENDING = "CHANNELS_PENDING";
const CHANNELS_FAILURE = "CHANNELS_FAILURE";

const GET_CHANNELS_SUCCESS = "GET_CHANNELS_SUCCESS";
const RESET_CHANNELS = "RESET_CHANNELS";
const CHANNELS_RESET_SEARCH = "CHANNELS_RESET_SEARCH";

const UPDATE_CHANNELS_OFFSET = "UPDATE_CHANNELS_OFFSET";
const UPDATE_CHANNELS_LIMIT = "UPDATE_CHANNELS_LIMIT";

const UPDATE_CHANNELS = "UPDATE_CHANNELS";
const UPDATE_CHANNELS_STATE = "UPDATE_CHANNELS_STATE";
const UPDATE_CHANNELS_OPEN_ACTION = "UPDATE_CHANNELS_OPEN_ACTION";
const UPDATE_CHANNELS_PARAMS = "UPDATE_CHANNELS_PARAMS";
const RESET_CHANNELS_PARAMS = "RESET_CHANNELS_PARAMS";
const INIT_CHANNELS = "INIT_CHANNELS";

const initialState = {
    pending: false,
    error: false,
    list: null,
    isActiveView: LIST_VIEW_TYPE.LIST,
    isSearched: false,
    contextVersion: 0,
    params: {
        isQuickSearch: true,
        isCategorySearch: false,
        keyword: "",
        description: "",
        owner: "",
        categoryIds: [],
        channelId: "",
        projectId: "",
        name: "",
        sourceType: "", //NONE, STREAM, LISTING
        state: "", //STOPPING;STOPPED,
        status: null,
        type: "",
        approvalStatus: [], //Draft;ApprovalRequested,
        username: "",
        beginDate: null, //YYYY-MM-DD
        endDate: null, //YYYY-MM-DD
        offset: 0,
        limit: 20,
    },

    totalCount: 0,
};

export default handleActions(
    {
        [CHANNELS_PENDING]: (state) => {
            return {
                ...state,
                contextVersion: state.contextVersion + 1,
                pending: true,
                error: false,
            };
        },
        [CHANNELS_FAILURE]: (state) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [GET_CHANNELS_SUCCESS]: (state, action) => {
            const { channels, totalCount } = action.payload.data;

            return {
                ...state,
                list: channels,
                totalCount,
                pending: false,
                error: false,
            };
        },
        [UPDATE_CHANNELS_STATE]: (state, action) => {
            return {
                ...state,
                list:
                    (state.list &&
                        state.list.map((channel) => {
                            if (channel.id === action.id) {
                                channel.state = action.state;
                            }
                            return channel;
                        })) ||
                    [],
            };
        },
        [RESET_CHANNELS]: (state) => {
            return {
                ...initialState,
                contextVersion: state.contextVersion + 1,
            };
        },
        [CHANNELS_RESET_SEARCH]: (state, action) => {
            const params = action.payload.isQuickSearch
                ? {
                      ...state.params,
                      keyword: initialState.params.keyword,
                  }
                : {
                      ...initialState.params,
                      isQuickSearch: state.params.isQuickSearch,
                  };
            return {
                ...state,
                params,
            };
        },
        [UPDATE_CHANNELS_LIMIT]: (state, action) => {
            const { offset, limit } = action.payload;

            return {
                ...state,
                pending: true,
                error: false,
                list: null,
                totalCount: 0,
                params: {
                    offset: offset,
                    limit: limit,
                },
            };
        },
        [UPDATE_CHANNELS_OFFSET]: (state, action) => {
            return {
                ...state,
                pending: true,
                error: false,
                list: null,
                totalCount: 0,
                params: {
                    ...state.params,
                    offset: action.payload,
                },
            };
        },
        [UPDATE_CHANNELS_OPEN_ACTION]: (state, action) => {
            return {
                ...state,
                list: state.list.map((v) => {
                    return {
                        ...v,
                        open: v.id === action.payload ? !v.open : false,
                    };
                }),
            };
        },
        [UPDATE_CHANNELS_PARAMS]: (state, action) => {
            return {
                ...state,
                params: {
                    ...state.params,
                    ...action.payload,
                },
            };
        },
        [UPDATE_CHANNELS]: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
        [RESET_CHANNELS_PARAMS]: (state) => {
            return {
                ...state,
                params: {
                    ...state.params,
                    isQuickSearch: true,
                    keywordType: "id", //id, name
                    keywordValue: "",
                    categoryIds: [],
                    channelId: "",
                    projectId: "",
                    name: "",
                    sourceType: "", //NONE, STREAM, LISTING
                    state: "", //STOPPING;STOPPED,
                    approvalStatus: "", //Draft;ApprovalRequested,
                    owner: "",
                    beginDate: null, //YYYY-MM-DD
                    endDate: null, //YYYY-MM-DD,
                    isNotCategoryIds: false,
                },
            };
        },
        [INIT_CHANNELS]: () => {
            return {
                ...initialState,
            };
        },
    },
    initialState,
);

export const resetChannels = createAction(RESET_CHANNELS);
export const resetSearch = createAction(CHANNELS_RESET_SEARCH);
export const open = createAction(UPDATE_CHANNELS_OPEN_ACTION);
export const updateChannels = createAction(UPDATE_CHANNELS);
export const updateParams = createAction(UPDATE_CHANNELS_PARAMS);
export const resetParams = createAction(RESET_CHANNELS_PARAMS);
export const initParams = createAction(INIT_CHANNELS);
export const getChannels = (isAdmin, projectId, params) => (dispatch, getState) => {
    const { stage, channels } = getState();
    const contextVersion = channels.contextVersion + 1;
    dispatch({ type: CHANNELS_PENDING });
    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const searchParams = params || channels.params;
    return getChannelsAPI(stageId, apiEndpoint, projectId, isAdmin, searchParams)
        .then((response) => {
            dispatch({
                type: GET_CHANNELS_SUCCESS,
                payload: response,
            });

            pollingAllByStatus(
                stageId,
                apiEndpoint,
                response.data && response.data.channels,
                contextVersion,
                dispatch,
                getState,
            );
        })
        .catch((error) => {
            dispatch({
                type: CHANNELS_FAILURE,
                payload: error,
            });
        });
};

export const updateChannelsOffset =
    ({ projectId, offset }, isAdmin = false) =>
    (dispatch, getState) => {
        const { stage, channels } = getState();
        dispatch({
            type: UPDATE_CHANNELS_OFFSET,
            payload: offset,
        });
        const params = {
            ...channels.params,
            offset,
        };
        const contextVersion = channels.contextVersion + 1;
        dispatch({ type: CHANNELS_PENDING });
        const stageId = stage.id;
        const apiEndpoint = stage.endpoint;
        return getChannelsAPI(stageId, apiEndpoint, projectId, isAdmin, params)
            .then((response) => {
                dispatch({
                    type: GET_CHANNELS_SUCCESS,
                    payload: response,
                });

                pollingAllByStatus(
                    stageId,
                    apiEndpoint,
                    response.data && response.data.channels,
                    contextVersion,
                    dispatch,
                    getState,
                );
            })
            .catch((error) => {
                dispatch({
                    type: CHANNELS_FAILURE,
                    payload: error,
                });
            });
    };

export const updateChannelsLimit =
    ({ projectId, offset, limit }, isAdmin = false) =>
    (dispatch, getState) => {
        const { stage, channels } = getState();
        const contextVersion = channels.contextVersion + 1;
        dispatch({
            type: UPDATE_CHANNELS_LIMIT,
            payload: {
                offset,
                limit,
            },
        });

        const params = {
            ...channels.params,
            offset,
            limit,
        };
        const apiEndpoint = stage.endpoint;
        dispatch({ type: CHANNELS_PENDING });
        return getChannelsAPI(stage.id, apiEndpoint, projectId, isAdmin, params)
            .then((response) => {
                dispatch({
                    type: GET_CHANNELS_SUCCESS,
                    payload: response,
                });
                pollingAllByStatus(
                    apiEndpoint,
                    response.data && response.data.channels,
                    contextVersion,
                    dispatch,
                    getState,
                );
            })
            .catch((error) => {
                dispatch({
                    type: CHANNELS_FAILURE,
                    payload: error,
                });
            });
    };

export const start = (projectId, channelId) => async (dispatch, getState) => {
    const { stage } = getState();

    dispatch({
        type: UPDATE_CHANNELS_STATE,
        id: channelId,
        state: "STARTING",
    });

    await startChannelsAPI(stage.id, stage.endpoint, projectId, channelId);

    const callback = (state) => {
        dispatch({
            type: UPDATE_CHANNELS_STATE,
            id: channelId,
            state: state,
        });
    };

    return ChannelPolling(stage.id, stage.endpoint, getState).runForStart(projectId, channelId, callback);
};

export const stop = (projectId, channelId) => async (dispatch, getState) => {
    const { stage } = getState();

    dispatch({
        type: UPDATE_CHANNELS_STATE,
        id: channelId,
        state: "STOPPING",
    });
    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;

    await stopChannelsAPI(stageId, apiEndpoint, projectId, channelId);

    const callback = (state) => {
        dispatch({
            type: UPDATE_CHANNELS_STATE,
            id: channelId,
            state: state,
        });
    };

    return ChannelPolling(stageId, apiEndpoint, getState).runForStop(projectId, channelId, callback);
};

const ChannelPolling = (stageId, apiEndpoint, getState) => {
    return {
        runAllByStatus(channels, contextVersion, callback) {
            if (!channels || channels.length <= 0) return;

            channels.forEach((v) => {
                switch (v.state) {
                    case "CREATING":
                    case "STOPPING":
                        this.runForStop(v.project.id, v.id, contextVersion, callback);
                        break;
                    case "STARTING":
                        this.runForStart(v.project.id, v.id, contextVersion, callback);
                        break;
                }
            });
        },
        runForStop(projectId, channelId, contextVersion, callback) {
            this.run(projectId, channelId, callback, (state) => {
                if (state === "STOPPED") return true;
                const channels = getState().channels;
                if (!channels || !channels.list) return true;
                if (channels.contextVersion !== contextVersion) return true;
                if (channels.list.filter((c) => c.id === channelId).length < 1) return true;

                return false;
            });
        },

        runForStart(projectId, channelId, contextVersion, callback) {
            this.run(projectId, channelId, callback, (state) => {
                if (state === "RUNNING") return true;

                const channels = getState().channels;
                if (!channels || !channels.list) return true;

                if (channels.contextVersion !== contextVersion) return true;

                if (channels.list.filter((c) => c.id === channelId).length < 1) return true;

                return false;
            });
        },

        run(projectId, channelId, callback, shouldStop) {
            try {
                const pollingStatus = pollGetChannelStatus(stageId, apiEndpoint, projectId, channelId).next();

                pollingStatus.value.then(({ data }) => {
                    const state = data;

                    if (callback) callback(state, channelId);

                    if (!shouldStop(state) && !this.timer) {
                        this.timer = setTimeout(() => {
                            this.run(projectId, channelId, callback, shouldStop);

                            this.timer = clearTimeout(this.timer);
                        }, 3000);
                    }
                });
            } catch (e) {
                console.error(e);
            }
        },
    };
};

function* pollGetChannelStatus(stageId, apiEndpoint, projectId, channelId) {
    while (true) {
        yield axios
            .get(`${apiEndpoint}/channels/${channelId}/state`, {
                headers: {
                    stageId,
                    projectId,
                },
            })
            .then((response) => {
                return response;
            });
    }
}

function pollingAllByStatus(stageId, apiEndpoint, channels, contextVersion, callback, getState) {
    const pollingTargetChannels = channels && channels.filter((v) => v.state && v.state.indexOf("ING") > -1);
    if (pollingTargetChannels && pollingTargetChannels.length > 0)
        ChannelPolling(stageId, apiEndpoint, getState).runAllByStatus(
            pollingTargetChannels,
            contextVersion,
            (state, channelId) => {
                callback({
                    type: UPDATE_CHANNELS_STATE,
                    id: channelId,
                    state: state,
                });
            },
        );
}
