import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import "@features/comments/editor-comment.css";
import EditorCommentCreateBox from "@features/comments/editor-comment-create-box";
import { Grid } from "@mzc-pdc/ui";
import ReplyContainer from "@features/comments/common/reply-container";
import ThreadContainer from "@features/comments/common/thread-container";
import queryParamsParse from "@components/params/queryParamsParse";
import { resetState } from "@modules/comments";
import useCommentSelector from "@hooks/useCommentSelector";
import useCommonComments from "@hooks/useCommonComments";
import theme from "@styles/theme";

const CommonCommentService = ({ domain, resourceId, isShowReplyView = true }) => {
    const dispatch = useDispatch();
    const {
        onChangeComment,
        onCreateComments,
        onClickToggleExpander,
        onClickCloseReplyContainer,
        onClickBackThreadContainer,
        onChangeAttachments,
        htmlComment,
        attachments,
    } = useCommonComments({
        domain,
        resourceId,
    });
    const { isLinkCommentUsed, selectedComment } = useCommentSelector({
        domain: "PROJECT",
    });
    const project = useSelector((state) => state.project);

    const { threadId, commentId, isSubThread } = queryParamsParse(location.search);
    const isLinkFromEmail = !!threadId;
    const parsedIsSubThread = isSubThread === "true";

    const isReplyContainerHidden = useMemo(() => {
        if (!isLinkFromEmail || isLinkCommentUsed) {
            return !!selectedComment;
        } else {
            return parsedIsSubThread;
        }
    }, [isLinkFromEmail, isLinkCommentUsed, selectedComment, parsedIsSubThread]);

    useEffect(() => {
        return () => dispatch(resetState());
    }, []);

    return (
        <>
            <Grid
                container
                width={1}
                flexDirection={"column"}
                hidden={!isShowReplyView}
                sx={{ height: "100%", overflow: "hidden", borderLeft: `1px solid ${theme.palette.greySecondary[100]}` }}
            >
                <Grid item width={1} sx={{ overflowY: `hidden`, height: `calc(100% - 48px)` }} flex={1}>
                    <ReplyContainer
                        domain={domain}
                        resourceId={resourceId}
                        hidden={!isShowReplyView || isReplyContainerHidden}
                        onClose={onClickCloseReplyContainer}
                    />
                    <ThreadContainer
                        domain={domain}
                        resourceId={resourceId}
                        onClickBack={onClickBackThreadContainer}
                        hidden={!isShowReplyView || !isReplyContainerHidden}
                    />
                </Grid>
                <Grid item width={1}>
                    <EditorCommentCreateBox
                        domain={domain}
                        onClickToggleExpander={onClickToggleExpander}
                        onChangeComment={onChangeComment}
                        onChangeAttachments={onChangeAttachments}
                        onCreateComment={onCreateComments}
                        htmlComment={htmlComment}
                        attachments={attachments}
                        isHideExpanderButton={isShowReplyView}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default CommonCommentService;
