import { IconAdd, IconArchive, IconCheckDefaultCircle } from "@mzc-cloudplex/icons";
import { Box, Grid, Typography } from "@mzc-pdc/ui";
import { useEffect, useState } from "react";
import { ImagePreviewButton, StyledAssetTypeThumbnail } from "./asset-thumbnail.styled";
import {
    ImageDefaultAudioDark,
    ImageDefaultAudioSmallDark,
    ImageDefaultFileDark,
    ImageDefaultFileSmallDark,
    ImageDefaultImageDark,
    ImageDefaultImageSmallDark,
    ImageDefaultTextDark,
    ImageDefaultTextSmallDark,
    ImageDefaultVideoDark,
    ImageDefaultVideoSmallDark,
} from "@mzc-cloudplex/icons";
import theme from "@styles/theme";
import { MEDIA_TYPES } from "@constants";

const AssetThumbnail = ({
    variant,
    minWidth = 40,
    width = 40,
    height = 40,
    mediaType,
    src,
    status,
    margin,
    disableHover = true,
    sx = {},
    isPreferred,
    archiveStatus,
    ...restProps
}) => {
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!src) return;
        setError(false);
    }, [src]);

    return (
        <StyledAssetTypeThumbnail
            className={"asset-thumbnail"}
            style={{
                minWidth,
                width,
                height,
                margin,
                ...sx,
            }}
            variant={variant}
            status={status}
            {...restProps}
        >
            {isPreferred && (
                <Grid
                    item
                    sx={{
                        position: "absolute",
                        left: variant === "card" ? "8px" : "-4px",
                        top: variant === "card" ? "8px" : "-4px",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        backgroundColor: theme.palette.common.white,
                        borderRadius: "50%",
                        width: "13px",
                        height: "13px",
                    }}
                >
                    <IconCheckDefaultCircle size={14} color={"success"} />
                </Grid>
            )}
            {variant === "table" && archiveStatus === "ARCHIVED" && (
                <Grid
                    item
                    sx={{
                        position: "absolute",
                        left: "-4px",
                        top: "30px",
                        zIndex: 1,
                        borderRadius: "2px",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        backgroundColor: theme.palette.common.white,
                    }}
                >
                    <IconArchive
                        size={16}
                        style={{
                            color: theme.palette.grey[800],
                        }}
                    />
                </Grid>
            )}
            {name && name}
            {src && !error ? (
                <>
                    <img
                        src={src}
                        alt=""
                        onError={(e) => {
                            setError(true);
                        }}
                        draggable={false}
                    />
                    {/* TODO: Image Preview 기능 추가 */}
                    {!disableHover && (
                        <ImagePreviewButton
                            className={"btn-preview"}
                            style={{
                                width: variant === "card" ? "100px" : "100%",
                                height: variant === "card" ? "100px" : "100%",
                            }}
                        >
                            <Box>
                                <IconAdd size={10} htmlColor={theme.palette.common.white} />
                            </Box>
                            {variant === "card" && (
                                <Typography color={theme.palette.common.white}>Image Preview</Typography>
                            )}
                        </ImagePreviewButton>
                    )}
                </>
            ) : (
                <>
                    {variant === "table" ? (
                        <>
                            {mediaType === MEDIA_TYPES.VIDEO && <ImageDefaultVideoSmallDark width={28} height={28} />}
                            {mediaType === MEDIA_TYPES.AUDIO && <ImageDefaultAudioSmallDark width={28} height={28} />}
                            {mediaType === MEDIA_TYPES.FILE && <ImageDefaultFileSmallDark width={28} height={28} />}
                            {mediaType === MEDIA_TYPES.IMAGE && <ImageDefaultImageSmallDark width={28} height={28} />}
                            {mediaType === MEDIA_TYPES.TEXT && <ImageDefaultTextSmallDark width={28} height={28} />}
                            {mediaType === MEDIA_TYPES.CAPTION && <ImageDefaultTextSmallDark width={28} height={28} />}
                        </>
                    ) : (
                        <>
                            {mediaType === MEDIA_TYPES.VIDEO && <ImageDefaultVideoDark width={45} height={60} />}
                            {mediaType === MEDIA_TYPES.AUDIO && <ImageDefaultAudioDark width={47} height={60} />}
                            {mediaType === MEDIA_TYPES.FILE && <ImageDefaultFileDark width={47} height={60} />}
                            {mediaType === MEDIA_TYPES.IMAGE && <ImageDefaultImageDark width={47} height={60} />}
                            {mediaType === MEDIA_TYPES.TEXT && <ImageDefaultTextDark width={47} height={60} />}
                            {mediaType === MEDIA_TYPES.CAPTION && <ImageDefaultTextDark width={47} height={60} />}
                        </>
                    )}
                </>
            )}
        </StyledAssetTypeThumbnail>
    );
};

export default AssetThumbnail;
