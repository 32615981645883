import { useCallback, useMemo, useRef, useState, useEffect } from "react";
import { getUsersAPI } from "@modules/apis/user-management";
import { useSelector } from "react-redux";
import { AutocompleteMultipleUsers } from "@components_v2/autocomplete/autocomplete-multiple-users";

export const UsersMultiInput = ({
    users,
    exceptUsers = [], //Note: options에서 특정 유저를 외부의 값으로 제외하고 싶을 때 사용합니다.
    filterSelectedOptions = false,
    isClear,
    disabled = false,
    onChange,
    placeholder,
    error,
    helperText,
    sx,
    textFieldSx,
}) => {
    const space = useSelector(({ stage }) => stage);
    const project = useSelector(({ project }) => project);

    const [value, setValue] = useState(users);
    const [open, setOpen] = useState(false);

    const [searchedValue, setSearchedValue] = useState(null); //현재 검색중인 이름
    const [userOptions, setUserOptions] = useState([]);
    const [selectedUser, setSelectedUser] = useState(users ?? []); //현재 선택한 유저(유저의 아이디만 popover로 넘겨주면 되서 자체력으로 autocomplete를 사용하도록 만들었습니다.)
    const [pendingUser, setPendingUser] = useState(false);
    const [debouncedValue, setDebouncedValue] = useState(null); //디바운싱된 검색어
    const userPage = useRef(0);
    const totalUser = useRef(0);
    const optionsList = useRef([]);
    const USER_LIMIT = 40;

    const loadUserList = useCallback(
        async (isInitValue = false) => {
            try {
                if (isInitValue) {
                    userPage.current = 0;
                    totalUser.current = 0;
                }

                if (pendingUser || (totalUser.current > 0 && totalUser.current <= userPage.current * USER_LIMIT))
                    return;
                setPendingUser(true);
                const response = await getUsersAPI(space.id, space.endpoint, project.id, {
                    name: debouncedValue,
                    shouldRequestDetail: false,
                    offset: userPage.current,
                    limit: USER_LIMIT,
                });

                let newData = [];
                if (isInitValue) newData = response.data.results;
                else {
                    newData = [...optionsList.current, ...response.data.results];
                }

                setUserOptions(newData);
                optionsList.current = response.data.results;
                userPage.current += 1;
                totalUser.current = response.data.totalCount;
            } catch (error) {
                console.log(error);
            } finally {
                setPendingUser(false);
            }
        },
        [pendingUser, space, searchedValue, userOptions, value, users, debouncedValue],
    );

    const onHandleSubmitUser = useCallback(
        (value) => {
            if (value !== searchedValue) {
                setSearchedValue(value);
            }
        },
        [searchedValue],
    );

    const onHandleFetchUser = useCallback(() => {
        loadUserList();
    }, []);

    const onHandleChange = useCallback(
        (values) => {
            setSelectedUser(values);
            onChange(values);
        },
        [userOptions, onChange],
    );

    const handleInputChange = (reason) => {
        if (reason === "clear") {
            setSelectedUser(null);
            setValue(null);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const options = useMemo(() => {
        return (
            userOptions?.map((user, idx) => ({
                id: user.id,
                name: user.name,
                email: user.username,
                type: user.type,
                lastOption: idx === userOptions.length - 1,
            })) || []
        );
    }, [userOptions, optionsList]);

    // useEffect(() => {
    //     //페이지 마운트 직후 쿼리를 통한 유저정보 유지
    //     if (!user) loadUserList(true); //최초 조회
    //     if (user?.id !== selectedUser?.id) setSelectedUser(user);
    //     if (user?.name) setValue(user?.name);
    // }, [user]);

    useEffect(() => {
        loadUserList(true);
    }, []);

    useEffect(() => {
        setSelectedUser(null);
        setValue(null);
    }, [isClear]);

    useEffect(() => {
        //실시간 검색 지연
        if (searchedValue === null) return;
        const debounce = setTimeout(() => {
            return setDebouncedValue(searchedValue);
        }, 300);
        return () => clearTimeout(debounce);
    }, [searchedValue]);

    useEffect(() => {
        if (debouncedValue === null) return;
        loadUserList(true);
    }, [debouncedValue]);

    return (
        <AutocompleteMultipleUsers
            value={users}
            open={open}
            disabled={disabled}
            placeholder={placeholder}
            exceptOptions={exceptUsers}
            filterSelectedOptions={filterSelectedOptions}
            options={options ?? []}
            error={error}
            helperText={helperText}
            sx={sx}
            textFieldSx={textFieldSx}
            setOpen={setOpen}
            handleClose={handleClose}
            onFetchData={onHandleFetchUser}
            onInputChange={handleInputChange}
            onChange={onHandleChange}
            onSubmit={onHandleSubmitUser}
        />
    );
};
