import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import WithSelect from "../../../components/select";
import * as downloadJobsActions from "../../../modules/downloadJobs";
import * as notificationActions from "../../../modules/notification";
import { CONSTANTS, JOB_ACTIONS } from "../../../constants";
import DownloadInfoMessage from "@routes/sources/components/DownloadInfoMessage";
import { IconChevronDown } from "@mzc-cloudplex/icons";
import { Divider, Grid, Stack } from "@mzc-pdc/ui";
import { SelectLimitTags } from "@components_v2/select";
import JobEmptyList from "@routes/rightsidebar/ui/job-empty-list";
import JobItem from "@routes/rightsidebar/ui/job-item";

let throttleHandler = false;

const DownloadJobPanel = ({ filter, jobIcon, summaries, taskComponent }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [limit, setLimit] = useState(20);
    const [viewmoreEnable, setViewmoreEnable] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState({});

    const { downloadJobs } = useSelector((state) => state);

    const DownloadJobsActions = bindActionCreators(downloadJobsActions, dispatch);
    const NotificationActions = bindActionCreators(notificationActions, dispatch);

    useEffect(() => {
        if (downloadJobs.totalCounts > downloadJobs.data.length) {
            setViewmoreEnable(true);
        } else {
            setViewmoreEnable(false);
        }
    }, [downloadJobs.data]);

    const onChangeLimit = useCallback(
        async (limit) => {
            await DownloadJobsActions.updateSearch({ limit });
            await DownloadJobsActions.getDownloadJobs();
            setLimit(limit);
        },
        [limit],
    );

    const onSubmit = useCallback(async () => {
        await DownloadJobsActions.updateSearch({ limit: downloadJobs.search.limit + limit });
        await DownloadJobsActions.getDownloadJobs();
    }, []);

    const onRetry = useCallback(
        async (jobId) => {
            await DownloadJobsActions.getDownloadJobTask({ jobId });
            const downloadJob = downloadJobs.data.find((v) => v.jobId === jobId);
            const jobProjectId = downloadJob.projectId;
            const domain = downloadJob?.metadata?.domain;
            const targets = downloadJob?.metadata?.targets;

            if (!downloadJob || !targets || targets.length <= 0) return;

            try {
                if (domain === "STORAGE") {
                    //TODO: 폴더 재시도의 경우 NotFound 파일을 찾아서 제외시켜야 함
                    const options = {
                        shouldZip: downloadJob?.metadata?.shouldZip,
                    };
                    const errorTargets = downloadJob?.metadata?.errorTargets;
                    const newTargets = targets
                        .map((item) => {
                            const bucket = item.bucket;
                            const errorKeys = errorTargets.filter((et) => et.bucket === bucket).map((et) => et.key);
                            const newKeys = item.keys.filter((k) => !errorKeys.includes(k));
                            return { ...item, keys: newKeys };
                        })
                        .filter((t) => t.keys.length > 0);

                    if (newTargets.length == 0) {
                        NotificationActions.setNotification({
                            type: "info",
                            contents: t(`common::msg::There is no file to retry.`, "There is no file to retry."),
                        });
                        return;
                    }

                    const response = await DownloadJobsActions.createDownloadJob(
                        domain,
                        newTargets,
                        options,
                        jobProjectId,
                    );
                    const newJob = response?.data;
                    const newJobStatus = response?.data?.status;
                    await DownloadJobsActions.getDownloadJobs();

                    let newFolders = [];
                    targets.forEach((target) => {
                        target.keys.forEach((key) => {
                            const splitPath = key.slice(0, key.lastIndexOf("/") + 1);
                            newFolders.push(target.bucket + splitPath);
                        });
                    });

                    const fileName = targets[0].keys[0].slice(targets[0].keys[0].lastIndexOf("/") + 1);

                    if (newJob && newJobStatus) {
                        const jobTasksResponse = await DownloadJobsActions.getDownloadJobTask({ jobId: newJobId });
                        const tasks = jobTasksResponse?.data?.results || [];
                        for (let i = 0; i < tasks.length; i++) {
                            const task = tasks[i];
                            if (task.status === downloadJobsActions.JMS_STATUS.SUCCEED)
                                await DownloadJobsActions.downloadCompressedFile(newJob, task.name);
                        }
                    }

                    NotificationActions.setNotification({
                        type: "info",
                        autoClose: false,
                        contents: (
                            <DownloadInfoMessage
                                folders={newFolders}
                                fileName={fileName}
                                totalCount={newFolders.length}
                                isDirectDownloadRequest={downloadJob?.metadata?.isDirectDownloadRequest}
                            />
                        ),
                    });
                } else {
                    const downloadRequestTargets = {
                        resourceId: downloadJob.metadata?.resourceId,
                        projectId: downloadJob.metadata?.projectId,
                        resourceName: targets[0]?.assets[0]?.name,
                    };

                    await DownloadJobsActions.createDownloadJob(domain, downloadRequestTargets, 1, jobProjectId);

                    await DownloadJobsActions.getDownloadJobs();
                    NotificationActions.setNotification({
                        type: "info",
                        autoClose: false,
                        contents: (
                            <DownloadInfoMessage
                                folders={""}
                                fileName={targets[0]?.assets[0]?.name}
                                totalCount={1}
                                false
                            />
                        ),
                    });
                }
            } catch (error) {
                console.log(error);
                // toastErrorMessage(error);
                // await MyJobsActions.getMyJobs();
            }
        },
        [downloadJobs.data],
    );

    const toggleJobIsExpanded = useCallback(async (item) => {
        if (throttleHandler) {
            return;
        }
        if (!item.isFolded) {
            const job = {
                ...item,
                isFolded: !item.isFolded,
            };
            await DownloadJobsActions.updateJob({ job });
        } else {
            throttleHandler = true;
            try {
                const { data } = await DownloadJobsActions.getDownloadJobTask({ jobId: item.jobId });
                // console.log('get my tasks', data);
                const newTasks = data.results || [];
                const job = {
                    ...item,
                    isFolded: item.isFold !== undefined ? false : !item.isFolded,
                    tasks: newTasks,
                };
                await DownloadJobsActions.updateJob({ job });
                throttleHandler = false;
            } catch (error) {
                throttleHandler = false;
            }
        }
    }, []);

    const getFileCount = useCallback(
        (item) => {
            if (!downloadJobs.data) return "";

            if (item.metadata.domain === "STORAGE") {
                const targets = item?.metadata?.targets;
                const target = targets[0];
                let fileCount = 0;

                targets.forEach((t) => (fileCount = fileCount + t.keys.length));

                if (item.metadata?.isDirectDownloadRequest === "ZIPPING") {
                    return fileCount;
                } else {
                    return item.taskCount;
                }
            } else {
                return item.taskCount;
            }
        },
        [downloadJobs.data],
    );

    const onClickDownload = async (job, taskName) => {
        await DownloadJobsActions.downloadCompressedFile(job, taskName);
    };

    const onClickJobDownload = useCallback(async (job) => {
        const response = await DownloadJobsActions.getDownloadJobTask(job);
        const tasks = response?.data?.results;

        for (let i = 0; i < tasks.length; i++) {
            const task = tasks[i];
            if (task.status === downloadJobsActions.JMS_STATUS.SUCCEED) await onClickDownload(job, task.name);
        }
    });

    const getJobStatus = useCallback(
        (item) => {
            if (!item || !downloadJobs.data) return "";
            const status = item.status;

            if (status === "FAILED" || item.summary?.detail?.failCount === item.taskCount) return "ERROR";
            else return status;
        },
        [downloadJobs.data],
    );

    const onHandleClickFilter = useCallback(
        (id, value) => {
            setSelectedFilter({
                ...selectedFilter,
                [id]: value,
            });
        },
        [selectedFilter],
    );

    const downloadJobType = useMemo(() => [{ label: "Download", value: "STORAGE" }], []);

    const filteredJobsData = useMemo(() => {
        const filteredJobsData = downloadJobs.data.filter((job) =>
            Object.keys(selectedFilter)
                .filter((key) => selectedFilter[key]?.length)
                .every((key) => selectedFilter[key].includes(job[key])),
        );

        const addValueToSummaries = (job) => {
            return summaries.map(({ key, ...rest }) => {
                if (key === "action") {
                    return {
                        ...rest,
                        value:
                            job.action === "STORAGE"
                                ? job.metadata.domain === "ASSET"
                                    ? JOB_ACTIONS.ASSET_DOWNLOAD
                                    : JOB_ACTIONS.STORAGE_DOWNLOAD
                                : job.action,
                    };
                }
                return {
                    ...rest,
                    value: job[key],
                };
            });
        };

        const getTaskDetailComponent = (job) => {
            const TaskDetailComponent = taskComponent?.[job.action];

            if (TaskDetailComponent) {
                return (
                    <TaskDetailComponent
                        tasks={job.tasks}
                        metadata={job.metadata}
                        action={{
                            downloadTask: (taskName) => onClickDownload(job, taskName),
                        }}
                    />
                );
            }
            return null;
        };

        const getStorageActionByDomain = (domain) => {
            switch (domain) {
                case "ASSET":
                    return JOB_ACTIONS.ASSET_DOWNLOAD;
                case "STORAGE":
                    return JOB_ACTIONS.STORAGE_DOWNLOAD;
                case "EXPORTED_METADATA":
                    return JOB_ACTIONS.METADATA_DOWNLOAD;
            }
        };

        return filteredJobsData.map((job) => {
            job.action = job.action === "STORAGE" ? getStorageActionByDomain(job.metadata.domain) : job.action;

            return {
                job: {
                    id: job.jobId,
                    action: job.action,
                    icon: jobIcon[job.action],
                    status: getJobStatus(job),
                    isPartialFailure: job.partialFailure,
                    exitCode: job.summary?.exitCode,
                    summaryInfo: addValueToSummaries(job),
                    showCdnIcon:
                        !(job.status === "SUCCEED" || job.status === "FAILED") &&
                        job.metadata?.isDirectDownloadRequest === "MISS_MATCH_REGION",
                    fileCount: getFileCount(job),
                },
                task: {
                    taskCount: job.taskCount,
                    isTaskDetailFolded: job.isFolded === undefined || job.isFolded,
                    taskDetailComponent: getTaskDetailComponent(job),
                },
                action: {
                    toggle: () => toggleJobIsExpanded(job), //이걸 누르면 job이 바뀌면서 filteredJobsData가 바뀌고 rerender가 될텐데 괜찮나?
                    retry: () => onRetry(job.jobId),
                    download: () => onClickJobDownload(job),
                },
            };
        });
    }, [downloadJobs.data, downloadJobType, getJobStatus, selectedFilter, getJobStatus, getFileCount]);

    return (
        <React.Fragment>
            <Grid container pt={3} px={3} pb={2} gap={1} justifyContent={"center"} alignItems={"center"}>
                {filter &&
                    Object.entries(filter)?.map(([key, options]) => (
                        <Grid item xs>
                            <SelectLimitTags
                                displayEmpty
                                notched
                                label={key}
                                labelId={`select-label-asset-jobs-${key}`}
                                multiple={true}
                                value={selectedFilter[key] ?? []}
                                options={options}
                                MenuProps={{
                                    disablePortal: true,
                                }}
                                onChange={(e) => {
                                    onHandleClickFilter(key, e.target.value);
                                }}
                                limitTags={1}
                            />
                        </Grid>
                    ))}
            </Grid>
            <Divider sx={{ mx: 3 }} />
            <Stack flex={1} px={3} py={2.5} gap={4} sx={{ overflowY: "auto" }}>
                {filteredJobsData.length > 0 ? (
                    <>
                        {filteredJobsData.map((data) => {
                            return <JobItem {...data} key={`download-job-${data.job.id}`} />;
                        })}
                    </>
                ) : (
                    <JobEmptyList />
                )}
            </Stack>
            {downloadJobs.data?.length > 0 && (
                <div className="panel-footer" style={{ flex: 0, paddingRight: "10px" }}>
                    <WithSelect
                        defaultValue={limit}
                        options={CONSTANTS("LIST_LIMITS")}
                        onChange={(option) => onChangeLimit(option.value)}
                    />
                    {viewmoreEnable ? (
                        <button
                            type={"button"}
                            className={"btn btn-outline-default"}
                            onClick={(e) => {
                                e.preventDefault();
                                onSubmit();
                            }}
                        >
                            <span>{`View More`}</span>
                            <IconChevronDown size={10} />
                        </button>
                    ) : (
                        ""
                    )}
                </div>
            )}
        </React.Fragment>
    );
};

export default DownloadJobPanel;
