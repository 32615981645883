import {styled, Grid as MuiGrid, Box as MuiBox, alpha} from '@mui/material';


export const StyledStatusLabel = styled(MuiBox)(({ theme }) => ({
    display: `inline-flex`,
    alignItems: `center`,
    justifyContent: `center`,
    '.MuiTypography-root': {
       marginLeft: `8px`
    },
    'svg': {
        verticalAlign: `middle`,
    }
}));


