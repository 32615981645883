import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import {connect} from 'react-redux';
import {withRouter} from "react-router";
import {bindActionCreators} from "redux";
import { JOB_ID_LCOAL_STORAGE_KEY } from "@/constants";
import * as tokenActions from "../../modules/token";
import * as uploadActions from "../../modules/upload";
import * as jobStatus from "../../cores/jobStatus";
import { sessionKeeper } from "@cores/sessionKeeper";
import config from '../../config/config';

class User extends Component {
    onClickLogout = () => {
        const {
            history,
            upload,
            TokenActions,
            UploadActions,
            t
        } = this.props;
        const cancelTargetJobs = upload.jobs.data.filter(v => v.status === jobStatus.values.READY || v.status === jobStatus.values.INGESTING || v.status === jobStatus.values.INGESTED);
        if(cancelTargetJobs && cancelTargetJobs.length > 0) {
            if(!window.confirm(t(`common::msg::${"There is work in progress. Would you really go out?"}`))) {
                return;
            }
            UploadActions.cancelTranscodeAllSync(cancelTargetJobs);
        } 
        if (document.documentElement.classList.contains('utils-menu-open'))
            document.documentElement.classList.remove('utils-menu-open');
        localStorage.removeItem(JOB_ID_LCOAL_STORAGE_KEY);
        TokenActions.deleteToken();
        if(window.confirm(t(`common::msg::${"Would you like to log out from the account service as well?"}`))) {
            this.logOutPops();
        } else {
            sessionKeeper.reset();
            history.push('/welcome');
        }
    };

    logOutPops = () => {
        sessionKeeper.reset();
        const redirectUrl = `${window.location.origin}/welcome`;
        window.location.href = `${config.mz_pops.oauth_host}/logout?next=${redirectUrl}`;
    }

    getIdpLink = () => {
        return `https://myaccount.megazone.com/info`;
    }

    render = () => {
        const {
            user,
            token,
            t,
        } = this.props;

        return (
            <React.Fragment>
                {user.data &&
                    <a
                        href={this.getIdpLink()}
                        target={"blank"}>
                        <span className="thumbnail">
                            {user.data && user.data.profileImageUrl ? (
                                <img src={user.data.profileImageUrl}/>
                            ) : (
                                <i className={"sprite sprite-user"}></i>
                            )}
                        </span>
                        <div>
                            <strong>{user.data.displayName && user.data.displayName? user.data.displayName : ""}</strong>
                            <small>{user.data && user.data.username ? user.data.username : t(`common::label::${"Unknown"}`)}</small>
                        </div>
                    </a>
                }
                {/* TODO: token
                <div className="token-count">
                    <span>03:00</span>
                    <button type='button'>Extension</button>
                </div>
                */}
                {token.value &&
                    <button
                        onClick={(e) => {
                            e.preventDefault();

                            this.onClickLogout();
                        }}
                        type={"button"}
                        className={"btn-icon-solid btn-logout "}>
                        <i className={"sprite sprite-logout "}></i>
                    </button>
                }
            </React.Fragment>
        );
    }
};

let mapStateToProps = (state) => {
    return {
        stage:state.stage,
        user: state.user,
        token: state.token,
        upload:state.upload
    };
};

let mapDispatchToProps = (dispatch) => {
    return {
        TokenActions: bindActionCreators(tokenActions, dispatch),
        UploadActions: bindActionCreators(uploadActions, dispatch)
    };
};

User = connect(mapStateToProps, mapDispatchToProps)(User);
export default withTranslation()(withRouter(User));