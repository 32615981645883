import { axios } from "../../cores/axiosWrapper";

export const getCustomContentDetailAPI = async (apiEndpoint, projectId, contentId, params) => {
    return axios.get(`${apiEndpoint}/v3/contents/${contentId}`, {
        params,
        headers: {
            projectId,
        },
    });
};

export const updateCustomContentAPI = async (apiEndpoint, projectId, contentId, data) => {
    return axios.patch(`${apiEndpoint}/v3/contents/${contentId}`, data, {
        headers: {
            projectId,
        },
    });
};

export const detailCustomContentAPI = async (apiEndpoint, projectId, contentId) => {
    return axios.delete(`${apiEndpoint}/v3/contents/${contentId}`, {
        headers: {
            projectId,
        },
    });
};

export const updateCustomContentActionsAPI = async (apiEndpoint, projectId, contentId, data) => {
    return axios.post(`${apiEndpoint}/v3/contents/${contentId}/actions`, data, {
        headers: {
            projectId,
        },
    });
};

export const getCustomContentFieldAPI = async (apiEndpoint, projectId, contentId, fieldId) => {
    return axios.get(`${apiEndpoint}/v3/contents/${contentId}/fields/${fieldId}`, {
        headers: {
            projectId,
        },
    });
};
