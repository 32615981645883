import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter, useHistory } from "react-router-dom";
import config from "../../config";
import { Stack, Typography } from "@mzc-pdc/ui";
import { setToken, deleteToken } from "@modules/token";

import { axiosWithoutHeaders } from "@cores/axiosWrapper";
import { parseQueryParameter } from "../../cores";
import { LayoutForNoting, LayoutForWelcome } from "@components/layout";
import Cookies from "js-cookie";
import menuService from "@services/menuService";
import { isLocal } from "@cores/enviromentExtension";
import { sessionKeeper } from "@cores/sessionKeeper";
import Lottie from "lottie-react";
import loadingLottie from "@assets/lottie/cpm-loading.json";
import InvalidInvitation from "../errors/InvalidInvitation";

const ConfirmUser = ({ t, match, location }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const queryParams = parseQueryParameter(location.search);
    const cookieDomain = isLocal ? "localhost" : "megazone.io";
    const [invitationError, setInvitationError] = useState(null);
    const requestAccessToken = async (code, clientId) => {
        sessionKeeper.set({ clientId });
        const redirectUrl = encodeURIComponent(`${window.location.origin}/confirmUser`);
        const apiEndpoint = `${config.host.api}/v3/oauth_token?code=${code}&clientId=${clientId}&redirectUrl=${redirectUrl}`;
        const oauthTokenResponse = await axiosWithoutHeaders.get(apiEndpoint, { headers: {} });
        return {
            authorization: oauthTokenResponse.headers["access-token"],
            data: oauthTokenResponse.data
        };
    };
    const requestActivation = async (spaceId, accessToken, activationCode) => {
        //console.log(`requesting activation`);
        const activationResponse = await axiosWithoutHeaders.post(
            `${config.host.api}/v2/spaces/${spaceId}/user-invitation/activate`,
            {
                activationCode,
            },
            {
                headers: {
                    Authorization: accessToken,
                },
            },
        );
        console.log(`### activationResponse`, activationResponse);
    };
    useEffect(async () => {
        const clientId = config.mz_pops.clientId;
        localStorage.setItem("CLIENT_ID", clientId);
        const code = queryParams.get("code");

        if (!code) {
            const activationCode = queryParams.get("activationCode");
            if (!activationCode) {
                setInvitationError(true);
                // TODO: error 표시?
                console.log(`NO activation code!!!...`);
                return;
            }
            Cookies.set("activationCode", activationCode, {
                expires: 1,
                path: "/",
                domain: cookieDomain,
            });
            const redirectUrl = encodeURIComponent(`${window.location.origin}/confirmUser`);
            window.location = `${config.mz_pops.oauth_host}/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}`;
        } else {
            const activationCode = Cookies.get("activationCode", {
                cookieDomain,
            });

            if (!activationCode) {
                setInvitationError(true);
                // TODO: error 표시?
                console.log(`NO activation code!!!...`);
                return;
            }
            const decodedActivationCode = Buffer.from(activationCode, "base64").toString("utf8");
            const spaceId = decodedActivationCode?.split(":")[0];
            if (!spaceId) {
                setInvitationError(true);
                // TODO: error 표시?
                console.log(`NO Space ID!!!...`);
                return;
            }
            try {
                const tokenResponse = await requestAccessToken(code, clientId);
                if (tokenResponse) {
                    const { authorization, data } = tokenResponse;
                    await requestActivation(spaceId, authorization, activationCode);
                    await dispatch(setToken(cookieDomain, data));
                    history.replace(`${menuService.paths.spaces()}`);
                }
            } catch (e) {
                await dispatch(deleteToken());
                // TODO: error 표시?
                history.replace("/welcome");
            } finally {
                Cookies.remove("activationCode", {
                    domain: cookieDomain,
                });
                Cookies.remove("beforeLogin", {
                    domain: cookieDomain,
                });
            }
        }
        return () => {};
    }, []);

    if (invitationError)
        return (
            <LayoutForNoting>
                <InvalidInvitation />
            </LayoutForNoting>
        );
    return (
        <LayoutForWelcome>
            <Stack direction={"column"} gap={2.5}>
                <Lottie
                    animationData={loadingLottie}
                    style={{
                        height: "80px",
                        minWidth: "80px",
                        margin: "auto",
                    }}
                />
                <Typography variant="subtitle2_500">
                    {t(`common::label::Moving to CloudPlex Media…`, `Moving to CloudPlex Media…`)}
                </Typography>
            </Stack>
        </LayoutForWelcome>
    );
};

export default withTranslation()(withRouter(ConfirmUser));
