import { useEffect, useMemo, useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Select } from "@mzc-pdc/ui";
import { Divider, Grid, Typography, MenuItem, alpha } from "@mui/material";
import theme from "@styles/theme";
import { useTranslation, Trans } from "react-i18next";
import { IconFolder, IconInfoCircle, ImageFolderSmallOn } from "@mzc-cloudplex/icons";
import { PROJECT_SETTINGS_ARCHIVE_OPTIONS } from "@constants";
import { useSelector } from "react-redux";
import InfoAlert from "@components_v2/alert/info-alert";
import * as React from "react";

export const DialogArchive = ({ active, onClose, onSubmit, actionPending, folder, isMulti }) => {
    const { t } = useTranslation();

    const settings = useSelector(({ settings }) => settings?.stage);
    const [option, setOption] = useState(settings?.Archive?.defaultArchiveClass?.value || "GLACIER");

    const onHandleChangeStorageClass = (e) => {
        setOption(e.target.value);
    };

    return (
        <Dialog variant={"basic"} open={active} onClose={() => onClose()}>
            <DialogTitle>{t(`common::label::Archive ${isMulti ? "items" : "the item"}?`)}</DialogTitle>
            <DialogContent sx={{ width: "480px" }}>
                <Grid container flexDirectoin={"column"} gap={theme.spacing(3.5)}>
                    {!settings?.Archive?.defaultArchiveClass?.isOverridable && (
                        <Grid item sx={{ width: "100%" }}>
                            <InfoAlert
                                content={{
                                    data: [
                                        {
                                            section: (
                                                <Trans
                                                    i18nKey="common::msg::You can choose a storage class to store your assets."
                                                    components={{
                                                        NewLine: <Typography />,
                                                    }}
                                                />
                                            ),
                                        },
                                    ],
                                }}
                            />
                        </Grid>
                    )}
                    {folder && (
                        <Grid
                            container
                            flexDirection={`row`}
                            alignItems={`center`}
                            sx={{
                                backgroundColor: alpha(theme.palette.primary[100], 0.2),
                                borderRadius: "4px",
                                padding: "8px 12px",
                                gap: theme.spacing(1.75),
                                minHeight: "48px",
                            }}
                        >
                            <Grid item flex={0} sx={{ display: "flex" }}>
                                <ImageFolderSmallOn width={16} height={16} />
                            </Grid>
                            <Grid item flex={1}>
                                <Typography variant={`body1_400`} color={theme.palette.greySecondary.main}>
                                    {folder?.name}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    <Grid container flexDirection={"column"} gap={theme.spacing(1)}>
                        <Grid item>
                            <Typography variant={`body3_700`} component={"span"} mr={3}>
                                {t(`common::label::Storage Class`, `Storage Class`)}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Select
                                size="medium"
                                value={option}
                                sx={{ width: "100%" }}
                                onChange={onHandleChangeStorageClass}
                                disabled={settings?.Archive?.defaultArchiveClass?.isOverridable}
                            >
                                {PROJECT_SETTINGS_ARCHIVE_OPTIONS.map((o, index) => {
                                    return (
                                        <MenuItem key={`menu_item_${index}`} value={o.value}>
                                            {o.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </Grid>
                    </Grid>

                    <Grid item sx={{ width: "100%" }}>
                        <Divider flexItem orientation={"horizontal"} />
                    </Grid>
                    <Grid item>
                        <Typography variant={"body1_400"} sx={{ color: "#3c3d4f" }}>
                            <Trans
                                i18nKey={`common::msg::If you archive the ${
                                    isMulti ? "assets" : "asset"
                                }, you will be charged the <0>{{n}}-day</0> archive<1/> amount immediately.`}
                                values={{ n: option === "DEEP_ARCHIVE" ? 180 : 90 }}
                                components={[
                                    <Typography
                                        variant={"body2_500"}
                                        color={theme.palette.primary[400]}
                                        component={"span"}
                                    />,
                                    <br />,
                                ]}
                            />
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <>
                    <Button variant={`text`} onClick={() => onClose()} color={`grey`}>
                        {t(`common::label::Cancel`)}
                    </Button>
                    <Button
                        variant={`contained`}
                        disabled={actionPending}
                        loading={actionPending}
                        onClick={(e) => {
                            e.stopPropagation();
                            onSubmit(!!folder, option);
                        }}
                        color={`primary`}
                    >
                        {t(`common::label::Archive`)}
                    </Button>
                </>
            </DialogActions>
        </Dialog>
    );
};

export default DialogArchive;
