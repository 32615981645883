import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { bindActionCreators } from "redux";

import * as sourcesAction from "@/modules/sources";
import ObjectIcon from "@routes/sources/components/ObjectIcon";
import convertToByte from "@cores/convertToByte";
import TooltipUserInfo from "../tooltip/userInfo";

const FileView = (props) => {
    const { t } = props;

    return (
        <React.Fragment>
            <div className={`list-overview`}>
                <ul>
                    <li>
                        <strong className="title">{t(`common::label::${"Name"}`)}</strong>
                        <div className="content">{props.name}</div>
                    </li>
                    <li>
                        <strong className="title">{t(`common::label::${"Path"}`)}</strong>
                        <div className="content">
                            {props.bucket}/{props.prefix}
                        </div>
                    </li>
                    <li>
                        <strong className="title">{t(`common::label::${"Size"}`)}</strong>
                        <div className="content">{convertToByte(props.size)}</div>
                    </li>
                    <li>
                        <strong className="title">{t(`common::label::${"Type"}`)}</strong>
                        <div className="content">
                            <ObjectIcon path={props.name} isFolder={props.isFolder} />
                            <span>{props.extension.toUpperCase()}</span>
                        </div>
                    </li>
                </ul>
                <hr />
                <ul>
                    <li>
                        <strong className="title">{t(`common::label::${"Service Provider"}`)}</strong>
                        <div className="content">
                            {props.serviceProvider ? (
                                props.servicePropvider
                            ) : (
                                <span>
                                    <i className="sprite sprite-vendor-cloudfront"></i>
                                    <span>Amazon S3</span>
                                </span>
                            )}
                        </div>
                    </li>
                    <li>
                        <strong className="title">{t(`common::label::${"StorageClass"}`)}</strong>
                        <div className="content">{props.storageClass}</div>
                    </li>
                </ul>
                <hr />
                <ul>
                    <li>
                        <strong className="title">{t(`common::label::${"Last Modified"}`)}</strong>
                        <div className="content">{props.lastModified}</div>
                    </li>
                </ul>
                {props.shared && (
                    <>
                        <hr />
                        <ul>
                            <li>
                                <strong className="title">{t(`common::label::${"Shared by"}`)}</strong>
                                <div className="content">
                                    {`${props.shared.by.name} (${props.shared.by.username})`}
                                    <TooltipUserInfo target={props.shared.by} id={props.shared.by.id} />
                                </div>
                            </li>
                            <li>
                                <strong className="title">{t(`common::label::${"Shared"}`)}</strong>
                                <div className="content">{props.shared.at}</div>
                            </li>
                        </ul>
                    </>
                )}
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(FileView);
