import React, { Component } from "react";
import { withTranslation, Trans } from "react-i18next";

class WarningCancelDiscard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false,
            style: {
                display: "none",
            },
        };
    }

    componentDidMount() {
        this.setState({
            ...this.state,
            style: {
                display: "block",
            },
        });

        document.documentElement.classList.add("modal-open");

        setTimeout(() => {
            this.setState({
                ...this.state,
                active: true,
            });
        }, 500);
    }

    onClose = (isConfirmed) => {
        this.setState({
            ...this.state,
            active: false,
        });

        document.documentElement.classList.remove("modal-open");

        this.props.onClose(isConfirmed);
    };

    render() {
        const { title, confirmButtonText, t, i18n } = this.props;
        return (
            <div
                className={`modal modal-message ${this.state.active ? "show" : ""}`}
                style={this.state.style}
                onTransitionEnd={() => {
                    if (!this.state.active) {
                        this.onClose();
                    }
                }}
            >
                <div className={"modal-dialog"}>
                    <div className={"modal-body"}>
                        <strong>
                            {this.props.renderMessage &&
                                typeof this.props.renderMessage === "function" &&
                                this.props.renderMessage(this.props)}
                        </strong>
                    </div>
                    <div className={"modal-footer"}>
                        <button
                            type={"button"}
                            className={"btn btn-text-default"}
                            onClick={(e) => {
                                this.onClose();
                            }}
                        >
                            {t(`common::label::${"Cancel"}`)}
                        </button>
                        <button
                            type={"button"}
                            className={"btn btn-text-primary"}
                            onClick={(e) => {
                                this.onClose(true);
                            }}
                        >
                            <span>{t(`common::label::Discard`)}</span>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(WarningCancelDiscard);
