import { Grid, Grid as MuiGrid } from "@mzc-pdc/ui";
import { styled } from "@mui/material";

export const StyledGrid = styled(MuiGrid)(({ theme, usePadding = true }) => ({
    width: `-webkit-fill-available`,
    padding: usePadding ? "20px" : "0",
    borderRadius: `4px`,
    border: `1px solid ${theme.palette.secondary[100]}`,
}));

export const GridNameEllipsis = styled(Grid)(({ theme, maxWidth }) => ({
    flex: maxWidth ? undefined : 1,
    maxWidth: maxWidth,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
}));
