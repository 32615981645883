import { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "@modules/userManagement";
import { getUser as getUserV2 } from "@modules/user-management-v2";
import compareVersions, { VERSION_CM_11330_USERS_TEAMS_ACCESS_V3 } from "@cores/version";

const useUserDetail = () => {
    const dispatch = useDispatch();

    const stage = useSelector(({ stage }) => stage);
    const project = useSelector(({ project }) => project);

    const [userProfileAnchorEl, setUserProfileAnchorEl] = useState(null);
    const [userId, setUserId] = useState(null);
    const [userProfileData, setUserProfileData] = useState(null);
    const [userProfilePending, setUserProfilePending] = useState(false);
    const [openUserProfile, setOpenUserProfile] = useState(false);

    const handleUserProfileClose = (event) => {
        event.stopPropagation();
        setUserProfileData(null);
        setUserProfileAnchorEl(null);
        setOpenUserProfile(false);
    };

    const getUserInfo = useCallback(async () => {
        try {
            setUserProfilePending(true);
            if (compareVersions(VERSION_CM_11330_USERS_TEAMS_ACCESS_V3, stage.version) <= 0) {
                const response = await dispatch(getUserV2(userId, project.id));
                setUserProfileData(response.idp?.userInfo);
            } else {
                const response = await dispatch(getUser(userId, project.id));
                setUserProfileData(response.megazoneUser);
            }
            setUserProfilePending(false);
            setOpenUserProfile(Boolean(userProfileAnchorEl));
        } catch (error) {
            console.log(error);
        }
    }, [userProfileAnchorEl, userId]);

    const handleUserProfileClick = (event, id) => {
        event.stopPropagation();
        setUserId(id);
        setUserProfileAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        if (!userProfileAnchorEl || !userId) return;
        getUserInfo();
    }, [userProfileAnchorEl, userId]);

    return {
        handleUserProfileClick,
        handleUserProfileClose,
        userProfileAnchorEl,
        openUserProfile,
        userProfileData,
        userProfilePending,
    };
};

export default useUserDetail;
