import React, { useMemo } from "react";
import { Autocomplete as MuiAutocomplete, TextField, Grid, Typography, Chip, Checkbox } from "@mzc-pdc/ui";
import { IconErrorCircle, IconCancelCircle, IconWarningTriangle } from "@mzc-cloudplex/icons";
import useIntersection from "@hooks/useIntersection";
import theme from "@styles/theme";
import { alpha } from "@mui/material";
import { useTranslation } from "react-i18next";

export const Autocomplete = ({
    id,
    multiple = true,
    value,
    options,
    onClickOption,
    placeholder,
    error,
    helperText,
    ...rest
}) => {
    const { t } = useTranslation();

    const defaultValue = multiple ? [] : null;
    const getEndAdornment = useMemo(() => {
        if (error) {
            if (!helperText) return <IconWarningTriangle color={"warning"} size={16} />;
            return <IconErrorCircle color={`error`} size={16} />;
        }
        return undefined;
    }, [error, helperText]);

    return (
        <MuiAutocomplete
            multiple={multiple}
            disableClearable={multiple}
            disableCloseOnSelect={multiple}
            value={value ?? defaultValue}
            options={options ?? []}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        placeholder={value ? undefined : placeholder}
                        inputProps={{
                            ...params.inputProps,
                            value: params.inputProps.value
                                ? t(`common::label::${params.inputProps.value}`, params.inputProps.value)
                                : undefined,
                            endAdornment: getEndAdornment,
                        }}
                    />
                );
            }}
            renderOption={(props, option) => (
                <li {...props}>
                    <>
                        {multiple && (
                            <Checkbox
                                color={"greySecondary"}
                                style={{ marginRight: 8 }}
                                checked={value?.find((x) => x === option.value) !== undefined}
                            />
                        )}
                        <>{option.label}</>
                    </>
                </li>
            )}
            isOptionEqualToValue={(option, value) => option.value === value}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        size={"small"}
                        label={t(`common::label::${option}`, option)}
                        {...getTagProps({
                            index,
                        })}
                        key={index}
                    />
                ))
            }
            onChange={(e, newValue) => {
                if (multiple) {
                    if (newValue?.length === 0) newValue = undefined;
                    onClickOption(
                        id,
                        newValue?.reduce((acc, cur) => (cur.value ? [...acc, cur.value] : [...acc, cur]), []),
                    );
                } else {
                    onClickOption(id, newValue?.value);
                }
            }}
            {...rest}
        />
    );
};
