import React, { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "../../components/loader";
import { LayoutForNoting } from "../../components/layout";
import { siteMode } from "../../cores/siteMode";

import Loadable from "@react-loadable/revised";
import compareVersions, { VERSION_CM_5112_PROJECT_SITE_ACCESS, VERSION_CM_5214_ADMIN } from "../../cores/version";
import menuService from "@services/menuService";
import Stage from "@routes/stage/Stage";

import { setStage } from "@modules/stage";
import { setProject } from "@modules/project";
import { getStagesProjects, getUser } from "@modules/user";
import { getProjectSettings, getStageSettings } from "@modules/settings";

import { sessionKeeper } from "../../cores/sessionKeeper";

const LoadableTokenExpiredModal = Loadable({
    loader: () => import("../../components/modals/TokenExpired"),
    loading: () => null,
});

const User = ({ children, ...props }) => {
    const { t, i18n } = useTranslation();

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { spaceId, projectId } = menuService.parse(location.pathname);

    const user = useSelector(({ user }) => user);
    const stage = useSelector(({ stage }) => stage);
    const project = useSelector(({ project }) => project);
    const token = useSelector(({ token }) => token);

    const [showNoSiteAccess, setShowNoSiteAccess] = useState(false);
    const [errorType, setErrorType] = useState("NO_SITE_ACCESS");

    const closeNoAccessPopup = () => {
        history.replace("/spaces");
    };

    const isAdminSetting = useMemo(() => {
        if (!location.pathname) return false;

        return location.pathname.indexOf(`/admin/spaces/${spaceId}`) !== -1 && !projectId;
    }, [location]);

    const updateSettings = async (stage) => {
        const settings = await dispatch(
            getStageSettings({
                stageId: stage.id,
                apiEndpoint: stage.endpoint,
            }),
        );
        const headerTheme = settings?.General?.headerTheme;
        if (headerTheme && headerTheme.value) {
            localStorage.setItem(
                "headerTheme",
                JSON.stringify({
                    stageId: stage.id,
                    data: JSON.parse(headerTheme.value),
                }),
            );
        }
        const consoleDefaultListLayout = settings?.General?.consoleDefaultListLayout?.value;
        const originGeneralSetting = JSON.parse(localStorage.getItem("generalSetting"));

        if (consoleDefaultListLayout) {
            localStorage.setItem(
                "generalSetting",
                JSON.stringify({ ...originGeneralSetting, consoleDefaultListLayout }),
            );
        }
    };

    const updateProjectSettings = (stage, project, resource) => {
        dispatch(
            getProjectSettings({
                stageId: stage.id,
                apiEndpoint: stage.endpoint,
                projectId: project.id,
                params: {
                    resource,
                },
            }),
        );
    };

    useEffect(() => {
        if (!token?.value) return;
        if (!user.data) {
            dispatch(getUser());
        }
        if (!user.stages.data) {
            dispatch(getStagesProjects());
        }
    }, [token.value]);

    //stage, project 설정
    useEffect(() => {
        const locationPathname = window.location.pathname;
        if (["/spaces", "/access-token-bridge"].indexOf(locationPathname) !== -1) return; //space목록에서는 space권한체크 불필요함
        if (!user.data || !user.stages?.data || user.stages.allProjectsPending) return;

        let spaceKey = spaceId;
        let projectKey = projectId;
        if (user.stages.data.length === 1 && user.stages.data[0]?.projects?.length === 1) {
            dispatch(setStage(user.stages.data[0]));
            dispatch(setProject(user.stages.data[0].projects[0]));
            updateSettings(user.stages.data[0]);
        } else if (user.stages.data.length >= 1) {
            if (location.pathname === `/`) {
                const currentSession = sessionKeeper.get();
                if (currentSession) {
                    if (
                        !currentSession.stageId ||
                        !currentSession.projectId ||
                        (user &&
                            user.stages &&
                            user.stages.data &&
                            user.stages.data.length > 0 &&
                            user.stages.data.filter((stg) => stg.id === currentSession.stageId).length === 0)
                    ) {
                        history.replace("/spaces");
                        return;
                    }

                    spaceKey = currentSession.stageId;
                    projectKey = currentSession.projectId;
                }
            }

            if ((!isAdminSetting && !(spaceKey && projectKey)) || (isAdminSetting && !spaceKey)) {
                setShowNoSiteAccess(true);
                setErrorType("NO_SITE_ACCESS");
                return;
            }

            const foundSpace = user.stages.data.find((stage) => stage.id === spaceKey);
            if (!foundSpace) {
                setShowNoSiteAccess(true);
                setErrorType("NO_SITE_ACCESS");
                return;
            }

            dispatch(setStage(foundSpace));
            updateSettings(foundSpace);

            if (!isAdminSetting) {
                const foundProject = foundSpace?.projects?.find((project) => project.id === projectKey);
                if (!foundProject) {
                    setShowNoSiteAccess(true);
                    setErrorType("NO_SITE_ACCESS");
                    return;
                }
                dispatch(setProject(foundProject));
            }
        } else {
            history.replace("/welcome");
            return;
        }
    }, [user.stages.allProjectsPending]);

    useEffect(async () => {
        if (!stage?.id || !project?.id) return;
        try {
            await updateProjectSettings(stage, project, "General");
            await updateProjectSettings(stage, project, "MenuName");
        } catch (e) {
            console.log(e);
        }
    }, [project]);

    useEffect(() => {
        if (!stage || !stage.version || !stage.user) return;
        const locationPathname = window.location.pathname;
        if (compareVersions(stage.version, VERSION_CM_5112_PROJECT_SITE_ACCESS) >= 0) {
            if (locationPathname !== "/spaces" && locationPathname !== "/" && !isAdminSetting) {
                const userData = stage.user;
                let hasNoSiteAccess = true;
                if (userData && userData.projects) {
                    const userProject = userData.projects.filter((p) => p.id === projectId)[0];
                    if (stage.id === spaceId && userProject) {
                        const siteAccess = userProject.siteAccess;
                        if (siteAccess) {
                            const mode = siteMode.get();
                            hasNoSiteAccess =
                                (mode === "CONSOLE" && siteAccess.indexOf("CONSOLE") === -1) ||
                                (mode === "CMS" && siteAccess.indexOf("CMS") === -1);
                        }
                    }
                    if (hasNoSiteAccess) {
                        setShowNoSiteAccess(true);
                        setErrorType("NO_SITE_ACCESS");
                    }
                }
            }
        }
        if (compareVersions(stage.version, VERSION_CM_5214_ADMIN) >= 0) {
            if (location.pathname.indexOf(`/admin/spaces/${spaceId}`) !== -1 && !projectId) {
                const userData = stage.user;
                if (userData && !userData.isSpaceAdmin) {
                    setShowNoSiteAccess(true);
                    setErrorType("NO_PERMISSION_TO_VIEW_PAGE");
                }
            } else if (location.pathname.indexOf(`/admin/spaces/${spaceId}/projects/${projectId}`) === 0) {
                const userData = stage.user;
                if (userData && userData.projects) {
                    const isAdmin =
                        userData.isSpaceAdmin ||
                        userData.projects.filter((p) => p.id === project.id && p.isAdmin).length > 0;
                    if (!isAdmin) {
                        setShowNoSiteAccess(true);
                        setErrorType("NO_PERMISSION_TO_VIEW_PAGE");
                    }
                }
            }
        }
    }, [stage.user]);

    if (showNoSiteAccess) {
        return <LoadableTokenExpiredModal error={{ type: errorType }} onConfirm={closeNoAccessPopup} />;
    }
    if(location.pathname.indexOf("/access-token-bridge") !== -1) {
        return <>{children}</>;
    }
    if (user.pending || user.stages.stagePending || user.stages.allProjectsPending) {
        return (
            <LayoutForNoting>
                <Loader />
            </LayoutForNoting>
        );
    } else if (user.data != null && user.stages.data !== null) {
        if ((!isAdminSetting && stage.id !== "" && project.id !== "") || (isAdminSetting && stage.id !== "")) {
            return <>{children}</>;
        } else {
            return <Stage />;
        }
    } else if (user.error || user.stages.error) {
        return (
            <LayoutForNoting>
                <div className={"row justify-content-center"}>
                    <div className={"col-5"}>
                        <div className={"gate-invalid"}>
                            <i className={"sprite sprite-error-big"}></i>
                            <h1>{t(`common::msg::${"There is no project available to access."}`)}</h1>
                            <div className={"card"}>
                                <div className={"card-body"}>
                                    <p className={"message"}>
                                        <Trans
                                            i18nKey="common::msg::If you have a problem with your account, or would like to use the cloud <0/>Platform, please contact our sales team. We'll assist you with more details."
                                            components={[<br />]}
                                        />
                                    </p>
                                    <div className={"row"}>
                                        <div className={"col"}>
                                            <strong className={"title"}>
                                                {t(`common::label::{{name}} inquiry`, {
                                                    name: t(`common::label::${"Cloud"}`),
                                                })}
                                            </strong>
                                            <ul>
                                                <li className={"dot"}>
                                                    {t(`common::label::${"24/7"}`)} : <strong>1644-2243</strong>
                                                </li>
                                                <li className={"dot"}>
                                                    {t(`common::label::${"Email"}`)} :{" "}
                                                    <a href="mailto:cloud@megazone.com">cloud@megazone.com</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className={"col"}>
                                            <strong className={"title"}>
                                                {t(`common::label::{{name}} inquiry`, { name: "Megazone" })}
                                            </strong>
                                            <p className={"dot"}>
                                                <a href="https://mz-cloudplex.zendesk.com">
                                                    {"https://mz-cloudplex.zendesk.com"}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutForNoting>
        );
    } else {
        return null;
    }
};

export default User;
