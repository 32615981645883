import { handleActions, createAction } from "redux-actions";
import { getCollectionsAPI } from "./apis/collections";
import { GROUPING_VIEW_TYPE, LIST_VIEW_TYPE } from "@constants";

const initialState = {
    data: null,
    pending: false,
    error: false,
    isCardView: true,
    activeView: LIST_VIEW_TYPE.LIST,
    hierarchyType: GROUPING_VIEW_TYPE.FOLDER,
    create: {
        data: null,
        nextToken: null,
        pending: false,
        error: false,
    },
};

const cloneState = { ...initialState };

const GET_COLLECTIONS_PENDING = "GET_COLLECTIONS_PENDING";
const GET_COLLECTIONS_SUCCESS = "GET_COLLECTIONS_SUCCESS";
const GET_COLLECTIONS_FAILURE = "GET_COLLECTIONS_FAILURE";
const GET_COLLECTIONS_MODAL_PENDING = "GET_COLLECTIONS_MODAL_PENDING";
const GET_COLLECTIONS_MODAL_SUCCESS = "GET_COLLECTIONS_MODAL_SUCCESS";
const GET_COLLECTIONS_MODAL_FAILURE = "GET_COLLECTIONS_MODAL_FAILURE";
const TOGGLE_CARD_VIEW = "TOGGLE_CARD_VIEW";
const COLLECTION_LIST_MODAL_CLEAR = "COLLECTION_LIST_MODAL_CLEAR";
const COLLECTIONS_UPDATE_COLLECTION_DATA = "COLLECTIONS_UPDATE_COLLECTION_DATA";
const COLLECTIONS_UPDATE_STATE = "COLLECTIONS_UPDATE_STATE";

export default handleActions(
    {
        [GET_COLLECTIONS_PENDING]: (state, action) => {
            return { ...state, pending: true, data: null, erorr: null };
        },
        [GET_COLLECTIONS_SUCCESS]: (state, action) => {
            return { ...state, pending: false, data: action.payload };
        },
        [GET_COLLECTIONS_FAILURE]: (state, action) => {
            return { ...state, pending: false, error: action.payload };
        },
        [TOGGLE_CARD_VIEW]: (state, action) => {
            return { ...state, isCardView: action.payload };
        },
        [TOGGLE_CARD_VIEW]: (state, action) => {
            return { ...state, isCardView: action.payload };
        },
        [GET_COLLECTIONS_MODAL_PENDING]: (state, action) => {
            return { ...state, create: { ...state.create, pending: true } };
        },
        [GET_COLLECTIONS_MODAL_SUCCESS]: (state, action) => {
            const { results, nextToken } = action.payload;
            return {
                ...state,
                create: {
                    peding: false,
                    data: state.create?.data ? [...state.create.data, ...results] : results,
                    nextToken,
                },
            };
        },
        [GET_COLLECTIONS_MODAL_FAILURE]: (state, action) => {
            return {
                ...state,
                create: {
                    ...state.create,
                    pending: false,
                    error: action.payload,
                },
            };
        },
        [COLLECTION_LIST_MODAL_CLEAR]: (state, action) => {
            return {
                ...state,
                create: {
                    ...state.create,
                    data: null,
                    nextToken: null,
                },
            };
        },
        [COLLECTIONS_UPDATE_COLLECTION_DATA]: (state, action) => {
            return {
                ...state,
                data: state.data.map((collection) => {
                    if (collection.id !== action.payload.id) return collection;
                    return {
                        ...collection,
                        [action.payload.type]: action.payload.value,
                    };
                }),
            };
        },
        [COLLECTIONS_UPDATE_STATE]: (state, action) => {
            return { ...state, ...action.payload };
        },
    },
    initialState,
);

export const clearModalList = createAction(COLLECTION_LIST_MODAL_CLEAR);

export const toggleCollectionCardView = createAction(TOGGLE_CARD_VIEW);

export const updateCollectionData = createAction(COLLECTIONS_UPDATE_COLLECTION_DATA);

export const updateCollectionState = createAction(COLLECTIONS_UPDATE_STATE);

export const getCollections = (params) => async (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: GET_COLLECTIONS_PENDING });

    try {
        const response = await getCollectionsAPI(stage.endpoint, project.id, params);
        dispatch({ type: GET_COLLECTIONS_SUCCESS, payload: response.data.results });
        return response.data.nextToken;
    } catch (error) {
        dispatch({ type: GET_COLLECTIONS_FAILURE, payload: error });
    }
};

export const getCollectionsModal = (params) => async (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: GET_COLLECTIONS_MODAL_PENDING });

    try {
        const response = await getCollectionsAPI(stage.endpoint, project.id, params);
        dispatch({ type: GET_COLLECTIONS_MODAL_SUCCESS, payload: response.data });
        return response.data.nextToken;
    } catch (error) {
        dispatch({ type: GET_COLLECTIONS_MODAL_FAILURE, payload: error });
    }
};
