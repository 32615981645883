import {handleActions, createAction} from 'redux-actions';
import {
    axios,
    axiosWithoutHeaders
} from "../cores/axiosWrapper";
import config from '../config/config';

const PLAYER_PROFILES_PENDING = 'PLAYER_PROFILES_PENDING';
const RESET_PLAYER_PROFILES = 'RESET_PLAYER_PROFILES';
const GET_PLAYER_PROFILES_SUCCESS = 'GET_PLAYER_PROFILES_SUCCESS';
const GET_PLAYER_PROFILES_FAILURE = 'GET_PLAYER_PROFILES_FAILURE';

const initialState = {
    pending:false,
    error:false,
    indexUrl: ""
};

function getPlayerProfilesAPI(stageId, apiEndpoint, projectId, isDefault)
{
    return axios.get(`${apiEndpoint}/resources/players/profiles`, {
        params: {
            isDefault
        },
        headers: {
            stageId,
            projectId
        }
    });
}

export const reset = createAction(RESET_PLAYER_PROFILES);
export default handleActions({
    [PLAYER_PROFILES_PENDING]: (state) => {
        return {
            ...state,
            pending : true,
            error : false
        }
    },

    [RESET_PLAYER_PROFILES]: (state, action ) => 
    {
        //console.log("RESET_PLAYER_PROFILES");

        return {
            ...initialState
        }
    },

    [GET_PLAYER_PROFILES_SUCCESS] : (state, action) => {
        //console.log("GET_PLAYER_PROFILES_SUCCESS state: ", JSON.stringify(state));
        const indexUrl = action.payload && action.payload.indexUrl;
        //console.log("indexUrl : ", indexUrl);

        // let params = {
        //     u:  "",//encodeURIComponent(this.props.url),
        //     t : "" ///this.state.mimeType
        // };

        // const playerUrl = `${indexUrl}?${Object.entries(params).map(e => e.join('=')).join('&')}`

        return {
            ...state,
            pending : false,
            indexUrl
        }
    },
    [GET_PLAYER_PROFILES_FAILURE] : (state) =>{
        //console.log("GET_PLAYER_PROFILES_FAILURE: ", JSON.stringify(state));
        return {
            ...state,
            pending : false,
            error : true    
        }
    },
}, initialState);

export const getDefaultPlayerProfiles = () => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    dispatch({type : PLAYER_PROFILES_PENDING});

    //return getPlayerProfilesAPI(stage.id, project.id, true);
    return getPlayerProfilesAPI(stage.id, stage.endpoint, project.id, true).then(
        (response) => {
            //console.log("then in ");
            dispatch({
                type : GET_PLAYER_PROFILES_SUCCESS,
                payload: response.data && response.data.profiles.length > 0 ? response.data.profiles[0] : null
            });

            // resolve(response.data);
        }
        );
    // return new Promise((resolve, reject) => {
    //     });
    // return getPlayerProfilesAPI(stageId, projectId, "", true).then(
    //     (response) => {
    //         dispatch({
    //             type: GET_PLAYER_PROFILES_SUCCESS,
    //             payload: response.data
    //         })
    //     }
    // ).catch(error => {
    //     dispatch({
    //         type: GET_PLAYER_PROFILES_FAILURE,
    //         payload: error
    //     });
    // });
}

export const getPlayerProfiles = () => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();


}