import { alpha } from "@mui/material";
import theme from "@styles/theme";

export const autocompleteTableHoverStyle = () => ({
    "& li": {
        "&:hover": {
            backgroundColor: alpha(theme.palette.greySecondary[50], 0.24),
        },
    },
});
