import { Box, Grid, IconButton, Stack, Tooltip } from "@mzc-pdc/ui";
import AssetTypeFlag from "@components_v2/icon-type/asset/asset-type-flag";
import { GridNameEllipsis } from "@components_v2/grid/grid.styled";
import Typography from "@components_v2/typography/typography";
import { IconDownload, IconErrorCircle, IconHierarchyFolded, IconPath, IconZip } from "@mzc-cloudplex/icons";
import theme from "@styles/theme";
import React, { useCallback } from "react";
import ObjectIcon from "@routes/sources/components/ObjectIcon";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const DOWNLOAD_TASK_DOMAIN = {
    STORAGE: "STORAGE",
    ASSET: "ASSET",
    COMMENT_ATTACHMENT: "COMMENT_ATTACHMENT",
};

const JobTaskDownload = ({ metadata, tasks, action }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const getFileCount = useCallback(() => {
        if (metadata?.domain !== DOWNLOAD_TASK_DOMAIN.ASSET) {
            const targets = metadata?.targets;
            const target = targets[0];
            let fileCount = 0;

            targets.forEach((t) => (fileCount = fileCount + t.keys.length));

            if (metadata?.isDirectDownloadRequest === "ZIPPING") {
                return fileCount;
            } else {
                return tasks.length;
            }
        } else {
            return tasks.length;
        }
    }, [metadata, tasks]);

    const getJobRepresentiveName = useCallback(() => {
        switch (metadata?.domain) {
            case DOWNLOAD_TASK_DOMAIN.ASSET:
                return metadata?.targets[0]?.assets[0]?.name;
            case DOWNLOAD_TASK_DOMAIN.COMMENT_ATTACHMENT:
                return metadata?.targets[0]?.keys[0]?.key;
            case DOWNLOAD_TASK_DOMAIN.STORAGE:
                return metadata?.targets[0]?.keys[0];
        }
    }, [metadata]);

    const getJobFileName = useCallback((task) => {
        if (task.status === "FAILED") {
            return `${t(`common::label::Download`, `Download`)} ${t(`common::label::error`, `error`)}`;
        } else {
            return task?.metadata?.archiveFileName
                ? task?.metadata?.archiveFileName.substring(
                      task?.metadata?.archiveFileName.lastIndexOf("/") + 1,
                      task?.metadata?.archiveFileName.length,
                  )
                : task?.metadata?.shouldZip
                ? "Compressing"
                : "Preparing";
        }
    }, []);

    const getJobFileIcon = useCallback(
        (task) => {
            if (metadata?.domain !== DOWNLOAD_TASK_DOMAIN.ASSET) {
                if (metadata?.shouldZip) {
                    return <IconZip size={16} htmlColor={`#9fa3b2`} />;
                }
                return (
                    <ObjectIcon
                        path={task?.metadata?.archiveFileName}
                        isFolder={task?.metadata && task?.metadata?.isFolder}
                        isFolderDisabled={task?.metadata && task?.metadata?.isFolder}
                    />
                );
            } else {
                return <AssetTypeFlag width={20} height={16} type={metadata?.targets[0]?.assets[0]?.mediaType} />;
            }
        },
        [metadata],
    );

    return (
        <>
            <Stack py={1.5} gap={2}>
                <Box>
                    {!!getJobRepresentiveName() && (
                        <Grid container flexWrap={"nowrap"}>
                            <Grid item>{getJobFileIcon(tasks[0])}</Grid>
                            <Grid item sx={{ display: "flex", flexDirection: "row" }}>
                                <GridNameEllipsis item maxWidth={"250px"}>
                                    <Typography variant="body3_400">{getJobRepresentiveName()}</Typography>
                                </GridNameEllipsis>
                                {getFileCount() > 1 && (
                                    <>
                                        <Typography variant="body3_400">
                                            <Trans
                                                i18nKey="common::label::and <emp>{{count}}</emp> others"
                                                components={{
                                                    emp: (
                                                        <Typography
                                                            variant="body3_700"
                                                            color="primary.main"
                                                        ></Typography>
                                                    ),
                                                }}
                                                values={{
                                                    count: getFileCount() - 1,
                                                }}
                                            />
                                        </Typography>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    )}
                    <Grid container flexWrap={"nowrap"} gap={1} mt={2} flexDirection={"column"}>
                        {tasks.map((task) => (
                            <Grid container justifyContent={"space-between"}>
                                <Grid item sx={{ display: "flex" }} gap={theme.spacing(1)}>
                                    <Grid item>
                                        <IconHierarchyFolded size={6} htmlColor={theme.palette.greySecondary[500]} />
                                    </Grid>
                                    <Grid item>
                                        <IconZip size={16} htmlColor={theme.palette.greySecondary[500]} />
                                    </Grid>
                                    <GridNameEllipsis item maxWidth={"300px"}>
                                        <Typography variant="body2_500">{getJobFileName(task)}</Typography>
                                    </GridNameEllipsis>
                                </Grid>
                                {task.status === "FAILED" && task.metadata?.error && task.metadata?.error?.code && (
                                    <Grid item>
                                        <Tooltip
                                            title={t(
                                                `common::code::${task.metadata?.error?.code}`,
                                                task.metadata?.error?.code,
                                            )}
                                        >
                                            <IconButton circled size="small">
                                                <IconErrorCircle size={16} color="error" />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                )}
                                {task?.status === "SUCCEED" && task?.metadata?.archiveFileName && (
                                    <Tooltip title={t(`common::label::Download`, `Download`)}>
                                        <IconButton
                                            size={"small"}
                                            circled
                                            onClick={(e) => {
                                                e.preventDefault();
                                                action.downloadTask && action.downloadTask(task.name);
                                            }}
                                        >
                                            <IconDownload size={16} />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Stack>
        </>
    );
};

export default JobTaskDownload;
