import moment from "moment";

import { axios } from "../../cores/axiosWrapper";
import queryString from "../../cores/queryString";

function getAssetsAPI(stageId, apiEndpoint, projectId, query) {
    let queries = {
        generateAccessUrl: true,
    };

    if (query.offset) queries.offset = query.offset;
    if (query.limit) queries.limit = query.limit;

    if (query.isQuickSearch) {
        queries.q = query.keyword;
        if (query.folderPath) {
            queries.folderPath = query.folderPath;
            if (query.isFolderSearch) {
                queries.isFolderSearch = query.isFolderSearch;
            }
        }

        if (query.categoryGroupId) {
            queries.categoryGroupId = query.categoryGroupId;
            if (query.isNotCategoryIds) {
                queries.isNotCategoryIds = query.isNotCategoryIds;
                queries.categoryIds = "-1";
            }
        } else {
            if (query.isNotCategoryIds) {
                queries.isNotCategoryIds = query.isNotCategoryIds;
                queries.categoryIds = "-1";
            }

            if (query.categoryIds && query.categoryIds.length > 0) {
                queries.categoryIds = query.categoryIds
                    .map((c) => {
                        if (typeof c === "object") return c.id;
                        return c;
                    })
                    .join(",");
            }

            if (query.isIncludeChildCategory) queries.isIncludeChildCategory = query.isIncludeChildCategory;
        }

        if (query.virtualFolderId) queries.virtualFolderId = query.virtualFolderId;
    } else {
        if (query.folderPath) {
            queries.folderPath = query.folderPath;
            if (query.isFolderSearch) {
                queries.isFolderSearch = query.isFolderSearch;
            }
        }
        if (query.name) queries.name = query.name;
        if (query.id) queries.id = query.id;
        if (query.ids) queries.ids = query.ids;
        if (query.description) queries.description = query.description;
        if (query.creator) queries.creator = query.creator;
        if (query.status) queries.status = query.status;
        if (query.jobId) queries.jobId = query.jobId;
        if (query.ingestType) queries.ingestType = query.ingestType;
        if (query.types) queries.types = query.types.join(",");
        if (query.extensions) queries.extensions = query.extensions && query.extensions.join(",");
        if (query.mediaTypes && query.mediaTypes.length > 0)
            queries.mediaTypes = query.mediaTypes && query.mediaTypes.join(",");
        if (query.categoryGroupId) {
            queries.categoryGroupId = query.categoryGroupId;
            if (query.isNotCategoryIds) {
                queries.isNotCategoryIds = query.isNotCategoryIds;
                queries.categoryIds = "-1";
            }
        } else {
            if (query.categoryIds && query.categoryIds.length > 0)
                queries.categoryIds = query.categoryIds
                    .map((c) => {
                        if (typeof c === "object") return c.id;
                        return c;
                    })
                    .join(",");
            if (query.isNotCategoryIds) {
                queries.isNotCategoryIds = query.isNotCategoryIds;
                queries.categoryIds = "-1";
            }
            if (query.isIncludeChildCategory) queries.isIncludeChildCategory = query.isIncludeChildCategory;
        }

        if (query.tags) queries.tags = "";
        if (query.tags !== undefined && query.tags.length > 0) {
            query.tags.forEach((tag, index) => {
                index === 0 ? tag : (tag = `,${tag}`);
                queries.tags += tag;
            });
        }

        if (query.beginDuration && query.endDuration) {
            queries.duration = `${moment.duration(query.beginDuration).asMilliseconds()}~${
                moment.duration(query.endDuration).asMilliseconds() + 999
            }`;
        }

        if (query.startAt && query.endAt) {
            const startAt = query.startAt;
            const endAt = query.endAt;

            queries.createdAt = `${startAt}~${endAt}`;
        }
        if (query.videoId) queries.videoId = query.videoId;
        if (query.peopleId) queries.peopleId = query.peopleId;
        if (query.hasAssociations) queries.hasAssociations = query.hasAssociations;
        if (query.fileMeta) {
            queries.fileMeta = query.fileMeta;
        }
        if (query.virtualFolderId) queries.virtualFolderId = query.virtualFolderId;
        if (query.contentSchemaId) queries.contentSchemaId = query.contentSchemaId;
        if (query.contentFields) queries.contentFields = query.contentFields;
        if (query.archiveStatus) queries.archiveStatus = query.archiveStatus;
    }
    return axios.get(`${apiEndpoint}/assets?${queryString.stringify(queries)}`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function deleteAssetAPI(stageId, apiEndpoint, projectId, id, version) {
    return axios.delete(`${apiEndpoint}/assets/${id}`, {
        params: {
            version,
            deleteAIJobs: true,
        },
        headers: {
            stageId,
            projectId,
        },
    });
}

function listAssetTypesAPI(stageId, apiEndpoint, projectId) {
    return axios.get(`${apiEndpoint}/assets/types`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function listAssetExtensionsAPI(stageId, apiEndpoint, projectId) {
    return axios.get(`${apiEndpoint}/assets/extensions`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

/**
 * Bulk Insert AssetCategoryMapper API
 * @param {string} stageId
 * @param {string} apiEndpoint
 * @param {string} projectId
 * @param {array} assets - asset list
 * @param {array} categories - category list
 */
function bulkAttachCategoriesToAssetAPI(stageId, apiEndpoint, projectId, assets, categories) {
    return axios.post(
        `${apiEndpoint}/assets/categories/bulk`,
        { assets, categories },
        {
            headers: {
                stageId,
                projectId,
            },
        },
    );
}

/**
 * Bulk Delete AssetCategoryMapper API
 * @param {string} stageId
 * @param {string} apiEndpoint
 * @param {string} projectId
 * @param {array} assets - asset list
 * @param {array} categories - category list
 */
function bulkDetachCategoriesToAssetAPI(stageId, apiEndpoint, projectId, assets, categories) {
    return axios.delete(`${apiEndpoint}/assets/categories/bulk`, {
        data: { assets, categories },
        headers: {
            stageId,
            projectId,
        },
    });
}

const getUsersInfoAPI = (apiEndpoint, projectId, userIds) => {
    return axios.get(`${apiEndpoint}/users/${userIds}`, {
        headers: { projectId },
    });
};

const moveAssetsFolderAPI = (stageId, apiEndpoint, projectId, data) => {
    return axios.post(`${apiEndpoint}/assets:move`, data, {
        headers: {
            stageId,
            projectId,
        },
    });
};

const archiveAssetAPI = ({ apiEndpoint, projectId, data }) => {
    return axios.post(`${apiEndpoint}/assets:archive`, data, {
        headers: {
            projectId,
        },
    });
};

const restoreAssetAPI = ({ apiEndpoint, projectId, data }) => {
    return axios.post(`${apiEndpoint}/assets:restore`, data, {
        headers: {
            projectId,
        },
    });
};

export {
    getAssetsAPI,
    deleteAssetAPI,
    listAssetTypesAPI,
    listAssetExtensionsAPI,
    bulkAttachCategoriesToAssetAPI,
    bulkDetachCategoriesToAssetAPI,
    getUsersInfoAPI,
    moveAssetsFolderAPI,
    archiveAssetAPI,
    restoreAssetAPI,
};

export default {
    getAssetsAPI,
    deleteAssetAPI,
    listAssetTypesAPI,
    listAssetExtensionsAPI,
    bulkAttachCategoriesToAssetAPI,
    bulkDetachCategoriesToAssetAPI,
    getUsersInfoAPI,
    moveAssetsFolderAPI,
    archiveAssetAPI,
    restoreAssetAPI,
};
