import { Badge, Checkbox, FormControl, Grid, InputLabel, MenuItem, Select } from "@mzc-pdc/ui";
import Typography from "@components_v2/typography/typography";
import theme from "@styles/theme";
import { useTranslation } from "react-i18next";
import React from "react";

const SelectLimitTags = ({ children, sx, limitTags, options, value, label, ...props }) => {
    const { t } = useTranslation();

    return (
        <FormControl fullWidth>
            <InputLabel id={`select-label-asset-jobs-${label}`} shrink={true}>
                {t(`common::label::${label}`, label)}
            </InputLabel>
            <Select
                value={value}
                label={label}
                renderValue={(renderVal) => {
                    if (!renderVal?.length) {
                        return t(`common::label::All`, `All`);
                    } else if (renderVal?.length > limitTags) {
                        return (
                            <Grid
                                item
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Grid item>
                                    <Typography>
                                        {renderVal
                                            .slice(0, limitTags)
                                            .map((v) => {
                                                const option = options.find((opt) => opt.value === v);
                                                return t(`common::label::${v}`, option?.label || v);
                                            })
                                            .join(", ")}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Badge
                                        sx={{
                                            "& .MuiBadge-badge": {
                                                backgroundColor: theme.palette.primary.light,
                                                color: theme.palette.common.white,
                                            },
                                        }}
                                        badgeContent={`+${renderVal.length - limitTags}`}
                                    />
                                </Grid>
                            </Grid>
                        );
                    } else
                        return renderVal
                            .slice(0, limitTags)
                            .map((v) => {
                                const option = options.find((opt) => opt.value === v);
                                return t(`common::label::${v}`, option?.label || v);
                            })
                            .join(", ");
                }}
                sx={{
                    "& .MuiInputBase-input": {
                        paddingRight: `${theme.spacing(5.5)} !important`,
                    },
                    ...sx,
                }}
                {...props}
            >
                {options.map(({ value: v, label: l }) => (
                    <MenuItem
                        key={`asset-job-type-${v}`}
                        value={v}
                        sx={{ borderRadius: `0 !important`, borderBottom: "none !important" }}
                    >
                        <Checkbox color={"greySecondary"} checked={value?.includes(v)} />
                        {t(`common::label::${l}`, l)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SelectLimitTags;
