import { archiveAssetAPI } from "@modules/apis/assets";
import { setNotification } from "@modules/notification";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const useArchive = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const stage = useSelector(({ stage }) => stage);
    const project = useSelector(({ project }) => project);

    const requestArchive = async (isMulti, data, afterMutation) => {
        try {
            await archiveAssetAPI({ apiEndpoint: stage.endpoint, projectId: project.id, data });

            dispatch(
                setNotification({
                    type: "success",
                    contents: t(
                        `common::msg::${
                            isMulti ? `The selected items have` : `The selected item has`
                        } been requested to be archived.`,
                        `${
                            isMulti ? `The selected items have` : `The selected item has`
                        } been requested to be archived.`,
                    ),
                }),
            );

            afterMutation && afterMutation();
        } catch (e) {
            console.log(e);
            throw e;
        }
    };

    return {
        requestArchive,
    };
};

export default useArchive;
