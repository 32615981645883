import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { CONSTANTS, JOB_PANEL_TABS } from "@constants";
import { bindActionCreators } from "redux";
import * as rightSideBarActions from "@modules/rightSideBar";
import { useDispatch } from "react-redux";
import useMyJobs from "@hooks/useMyJobs";

const CreateAssetsBulkJobMessage = ({ firstAssetMediaType, count }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const RightSideBarActions = bindActionCreators(rightSideBarActions, dispatch);

    const { openMyJobs } = useMyJobs({});

    const openSideBar = () => {
        openMyJobs(JOB_PANEL_TABS.ASSET_JOBS);
    };

    return (
        <div>
            <p className="title">
                <i className="sprite sprite-request" />
                <span>{t(`common::label::Request to Create Assets`)}</span>
            </p>
            <hr />
            <div className="file-process">
                <div className="file">
                    <div className="file-info">
                        <span className="file-type">
                            <i className={`sprite sprite-media-${firstAssetMediaType?.toLowerCase()}-white`}></i>
                        </span>
                        <span>
                            <Trans
                                i18nKey="common::msg::Creation for {{count}} assets requested."
                                values={{ count: count }}
                            />
                        </span>
                    </div>
                </div>
            </div>
            <hr />
            <div className="btns">
                <button
                    type="button"
                    className="btn btn-outline-white btn-round btn-sm"
                    onClick={(e) => {
                        e.preventDefault();
                        openSideBar();
                    }}
                >
                    <strong>{t("common::label::Detail", `Detail`)}</strong>
                    <i className="sprite sprite-arrow-right-primary-active"></i>
                </button>
            </div>
        </div>
    );
};
export default CreateAssetsBulkJobMessage;
