import React, { useCallback, Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import Loadable from "@react-loadable/revised";
import { MEDIA_TYPES, PROPERTY_PANEL_TYPE, POPUP_MODAL_SIZE } from "@constants";
import FileView from "@components/view/FileView";
import OutsideClickHandler from "react-outside-click-handler";
import { VERSION_CM_8075_SHARE_REQUEST } from "@cores/version";
import VersionedComponent from "@components/VersionedComponent";
import ContentsView from "@components/property-panel/ui/ContentsView";
import { useTranslation } from "react-i18next";
import PanelHeader from "@components/property-panel/ui/PanelHeader";
import PanelHeaderTool from "@components/property-panel/ui/PanelHeaderTool";
import PanelHeaderTitle from "@components/property-panel/ui/PanelHeaderTitle";
import { IconButton } from "@mzc-pdc/ui";
import Loading from "@components/loader/Loading";
import PanelPermissionError from "@components/property-panel/ui/PanelPermissionError";
import PanelBody from "@components/property-panel/ui/PanelBody";
import PanelBodyMedia from "@components/property-panel/ui/PanelBodyMedia";
import PanelBodyInformation from "@components/property-panel/ui/PanelBodyInformation";
import SpriteIcon from "@components/icons/SpriteIcon";
import PanelBodyInfoContents from "@components/property-panel/ui/PanelBodyInfoContents";
import ScrollArea from "@components/ScrollArea";
import menuService from "@services/menuService";

const LoadableHiddenMetadataPermissionConfirmModal = Loadable({
    loader: () => import("@components/modals/WarningConfirm"),
    loading: () => null,
});

const StoragePanel = (props) => {
    const location = useLocation();

    const { onClickClose, convertToCategoryType, handleShareRequestModal, contentsViewData } = props;

    const { propertyPanel, project } = useSelector(({ propertyPanel, project }) => ({
        propertyPanel,
        project,
    }));

    const { t } = useTranslation();

    const onHandleClickOpenPreviewPopup = useCallback(() => {
        const { spaceId, projectId } = menuService.parse(location.pathname);

        const url = `${window.location.origin}${menuService.paths.storagePreview(spaceId, projectId)}?bucket=${
            propertyPanel.data.bucket
        }&prefix=${propertyPanel.data.prefix.slice(0, propertyPanel.data.prefix.length - 1)}&fileName=${
            propertyPanel.data.name
        }`;

        window.open(
            url,
            `storage-preview-popup`,
            `width=${POPUP_MODAL_SIZE.width},height=${POPUP_MODAL_SIZE.height},scrollbar=1`,
        );
    }, [propertyPanel]);

    return (
        <React.Fragment>
            <PanelHeader>
                <PanelHeaderTitle>
                    <SpriteIcon
                        className={`sprite-${
                            propertyPanel.type && convertToCategoryType(propertyPanel.type).toLowerCase()
                        }`}
                    ></SpriteIcon>
                    {t(`common::label::${propertyPanel.type && convertToCategoryType(propertyPanel.type)}`)}
                    {propertyPanel.data?.encryptions?.length > 0 && <SpriteIcon className="sprite-drm"></SpriteIcon>}
                    {propertyPanel.data?.hasDubbing?.length > 0 && (
                        <SpriteIcon className={"sprite-dubbing"}></SpriteIcon>
                    )}
                </PanelHeaderTitle>
                <PanelHeaderTool>
                    <IconButton
                        disabled={
                            propertyPanel.error ||
                            (propertyPanel.contents && propertyPanel.contents.type === MEDIA_TYPES.FILE) ||
                            propertyPanel.data?.mediaType === MEDIA_TYPES.FILE
                        }
                        className={"btn-sm btn-dark"}
                        onClick={onHandleClickOpenPreviewPopup}
                        icon={
                            <>
                                <SpriteIcon className={"sprite-preview-inverse"}></SpriteIcon>
                                <span className={"hidden"}>{t(`common::label::${"Preview button"}`)}</span>
                            </>
                        }
                    ></IconButton>
                    <IconButton
                        type="button"
                        className="btn-icon-solid btn-panel-close"
                        onClick={onClickClose}
                        icon={
                            <>
                                <span>
                                    <SpriteIcon className="sprite-cancel-lg"></SpriteIcon>
                                </span>
                                <span className={"hidden"}>{t(`common::label::${"Close"}`)}</span>
                            </>
                        }
                    ></IconButton>
                </PanelHeaderTool>
            </PanelHeader>
            {propertyPanel.pending ? (
                <Loading></Loading>
            ) : (
                <React.Fragment>
                    {propertyPanel.accessError || propertyPanel.error ? ( //접근 권한이 없는 경우
                        <PanelPermissionError
                            handleShareRequestModal={handleShareRequestModal}
                            panelType={propertyPanel.type}
                        ></PanelPermissionError>
                    ) : (
                        <PanelBody>
                            {propertyPanel.isShow && propertyPanel.data ? (
                                <React.Fragment>
                                    <PanelBodyMedia
                                        className={`type-${
                                            propertyPanel.data.mediaType
                                                ? propertyPanel.data.mediaType.toLowerCase()
                                                : ""
                                        }`}
                                        style={{}}
                                    >
                                        <ContentsView
                                            type={
                                                propertyPanel.contents && propertyPanel.contents.type
                                                    ? propertyPanel.contents.type
                                                    : contentsViewData.mediaType
                                            }
                                            isInStorageTab={propertyPanel.type === PROPERTY_PANEL_TYPE.STORAGE}
                                            isEncrypted={
                                                contentsViewData &&
                                                contentsViewData.encryptions &&
                                                contentsViewData.encryptions.length > 0
                                            }
                                            jobId={contentsViewData && contentsViewData.job && contentsViewData.job.id}
                                            data={contentsViewData}
                                            totalCount={
                                                (contentsViewData &&
                                                    contentsViewData.elements &&
                                                    contentsViewData.elements.length) ||
                                                0
                                            }
                                        />
                                    </PanelBodyMedia>

                                    <PanelBodyInformation>
                                        <PanelBodyInfoContents>
                                            <ScrollArea>
                                                <FileView {...propertyPanel.data} />
                                            </ScrollArea>
                                        </PanelBodyInfoContents>
                                    </PanelBodyInformation>
                                </React.Fragment>
                            ) : (
                                <div>{t(`common::label::${"Not found"}`)}</div>
                            )}
                        </PanelBody>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default StoragePanel;
