import React, { useCallback, useMemo } from "react";
import {
    Box,
    Button,
    CircularProgress,
    Collapse,
    Divider,
    Grid,
    IconButton,
    LinearProgress,
    Stack,
    theme,
    Tooltip,
} from "@mzc-pdc/ui";
import Typography from "@components_v2/typography/typography";
import State from "@components/state";
import {
    IconArrowDown,
    IconArrowUp,
    IconCancelCircle,
    IconCircle,
    IconDownload,
    IconErrorCircle,
    IconOpenInNew,
    IconRetry,
    ImageLogoAmazon,
} from "@mzc-cloudplex/icons";
import { Trans, useTranslation } from "react-i18next";
import moment from "moment";
import { alpha } from "@mui/material";
import { capitalize } from "@utils/convert-string";
import * as jobStatus from "@cores/jobStatus";
import { Link } from "react-router-dom";
import { JOB_ACTIONS } from "@constants";

const JobItem = ({ job, task, action }) => {
    const { t } = useTranslation();

    const getSummaryValueById = useCallback(({ id, label, value, to }) => {
        switch (id) {
            case "service":
                const icon =
                    value.serviceComponent?.provider === "AWS" ? (
                        <ImageLogoAmazon style={{ transform: `scale(0.84)` }} />
                    ) : null;
                const provider = value.serviceComponent?.service === "MediaConvert" ? "AWS Elemental MediaConvert" : "";

                return (
                    <>
                        {icon}
                        {provider}
                    </>
                );
            case "text":
                return value ? t(`common::label::${value}`, value) : "-";
            case "link":
                return to ? (
                    <Typography color={"primary.main"}>
                        <Link href="#" target={"_blank"} to={to} underline="hover">
                            {value ?? "-"}
                            <IconOpenInNew size={16} />
                        </Link>
                    </Typography>
                ) : (
                    value ?? "-"
                );
            case "date":
                return `${moment(value).format("YYYY-MM-DD HH:mm:ss")}`;
            default:
                return value ?? "-";
        }
    }, []);

    const isJobFailed = useMemo(() => job.status === "FAILED" || job.isPartialFailure, [job]);

    const renderJobStatus = useCallback(() => {
        const action = job.action.toLowerCase().split("_").join(" ");

        const getJobRunningStatusByAction = (action) => {
            switch (action) {
                case JOB_ACTIONS.CREATE_ASSETS:
                    return "Creating asset";
                case JOB_ACTIONS.TRANSCODE:
                    return "Transcode in progress";
                case JOB_ACTIONS.UPLOAD:
                    return "Uploading files";
                default:
                    return "Preparing {{type}}";
            }
        };

        switch (job.status) {
            case "READY":
                return <Typography>{t(`common::label::Ready`, `Ready`)}</Typography>;
            case "INGESTING":
                return (
                    <>
                        <CircularProgress size={12} thickness={5} color="warning" />
                        <Typography color="warning.main">{t(`common::label::Ingesting`, `Ingesting`)}</Typography>
                    </>
                );
            case "INGESTED":
                return (
                    <>
                        <CircularProgress size={12} thickness={5} color="warning" />
                        <Typography color="warning.main">{t(`common::label::Ingested`, `Ingested`)}</Typography>
                    </>
                );
            case "SUBMITTED":
                return (
                    <>
                        <CircularProgress size={12} thickness={5} color="primary" />
                        <Typography color="primary.main">{t(`common::label::Submitted`, `Submitted`)}</Typography>
                    </>
                );
            case "UPLOADING":
            case "PROGRESSING":
            case "RUNNING":
                const status = getJobRunningStatusByAction(job.action);
                return (
                    <>
                        <CircularProgress size={12} thickness={5} color="primary" />
                        <Typography color="primary.main">
                            {t(`common::label::${status}`, status, { type: t(`common::label::${action}`, action) })}
                        </Typography>
                    </>
                );
            case "COMPLETE":
            case "SUCCEED":
                return (
                    <Typography color={job.isPartialFailure ? "error.main" : "success.main"}>
                        {job.isPartialFailure
                            ? t(`common::label::${"Partial {{type}} to completed."}`, {
                                  type: t(`common::label::${action}`, action),
                              })
                            : t(`common::label::${"{{type}} completed."}`, {
                                  type: t(`common::label::${capitalize(action, true)}`, capitalize(action, true)),
                              })}
                    </Typography>
                );
            case "ERROR":
            case "FAILED":
                return (
                    <Typography color="error.main">
                        {t(
                            `common::label::An error occurred while preparing {{type}}.`,
                            `An error occurred while preparing {{type}}.`,
                            {
                                type: t(`common::label::${action}`, action),
                            },
                        )}
                    </Typography>
                );
            case "CANCELED":
                return <Typography>{t(`common::label::${action} canceled`, `${action} canceled`)}</Typography>;
            default:
                return (
                    <Typography color="primary.main">
                        {action === JOB_ACTIONS.MOVE
                            ? `${t(`common::label::${"Moving"}`)}...`
                            : action === JOB_ACTIONS.COPY
                            ? `${t(`common::label::${"Copying to"}`)}...`
                            : action === JOB_ACTIONS.DELETE
                            ? `${t(`common::label::${"Deleting"}`)}...`
                            : action === JOB_ACTIONS.RENAME
                            ? `${t(`common::label::${"Renaming"}`)}...`
                            : ""}
                    </Typography>
                );
        }
    }, [job]);

    return (
        <Box>
            <Grid container pb={1} gap={1} alignItems={"center"}>
                <Grid item>
                    <Grid
                        container
                        alignItems={"center"}
                        justifyContent={"center"}
                        width={24}
                        height={20}
                        bgcolor={"greySecondary.main"}
                        color={"greySecondary.contrastText"}
                        borderRadius={0.7}
                    >
                        {job.icon}
                    </Grid>
                </Grid>
                <Divider flexItem orientation={"vertical"} sx={{ my: 1 }} />
                <Grid
                    item
                    xs
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Grid container gap={1} alignItems={"center"} flex={1}>
                        <State variant={"contained"} status={job.status} />
                        {isJobFailed && (
                            <Tooltip
                                title={
                                    job?.exitCode !== "SUCCESS"
                                        ? t(`common::code::${job?.exitCode}`, job?.exitCode)
                                        : t(
                                              `common::label::This operation was not entirely successful.`,
                                              "This operation was not entirely successful.",
                                          )
                                }
                            >
                                <IconButton circled size="small">
                                    <IconErrorCircle size={16} color="error" />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Grid>
                    <Grid item>
                        {action.cancel && !jobStatus.isComplete(job.status) && (
                            <Button
                                variant={"text"}
                                size={"medium"}
                                color={"greySecondary"}
                                startIcon={<IconCancelCircle size={16} />}
                                onClick={() => action.cancel()}
                            >
                                {t(`common::label::Cancel`, `Cancel`)}
                            </Button>
                        )}
                    </Grid>
                </Grid>
                <Grid item>
                    {isJobFailed && action.retry && (
                        <Button
                            variant={"text"}
                            size={"medium"}
                            color={"greySecondary"}
                            startIcon={<IconRetry size={16} />}
                            onClick={() => action.retry()}
                        >
                            {t(`common::label::Retry`, `Retry`)}
                        </Button>
                    )}
                </Grid>
            </Grid>
            <Divider />
            <Stack py={2.5} px={1.5} gap={1.5}>
                {job?.summaryInfo?.map((info, index) => (
                    <Grid container gap={1} key={`job-item-${job.id}-${index}`}>
                        {info.label && info.value && (
                            <>
                                <Grid item xs={4}>
                                    <Grid container gap={1} alignItems={"center"}>
                                        <IconCircle size={6} htmlColor={theme.palette.greySecondary[500]} />
                                        {t(`common::label::${info.label}`, info.label)}
                                    </Grid>
                                </Grid>
                                <Grid item>{getSummaryValueById(info)}</Grid>
                            </>
                        )}
                    </Grid>
                ))}
                <Grid item></Grid>
            </Stack>
            {/*isFolded를 상태로 관리하면? (transcode나 upload같은 경우도 굳이 리덕스에 저장 안해도 됨) */}
            <Collapse in={!task.isTaskDetailFolded} collapsedSize={75}>
                <Box
                    px={1}
                    py={1.5}
                    borderRadius={1}
                    bgcolor={alpha(theme.palette.greySecondary[50], 0.5)}
                    maxHeight={500}
                    overflow={"auto"}
                >
                    <Grid container gap={1.5}>
                        <Grid item>
                            <Tooltip
                                title={t(
                                    `common::label::${task.isTaskDetailFolded ? "Expand" : "Fold"}`,
                                    task.isTaskDetailFolded ? "Expand" : "Fold",
                                )}
                            >
                                <IconButton
                                    circled
                                    size="xxsmall"
                                    onClick={() => action?.toggle && action.toggle()}
                                    // disabled={item.tasks.length === 0}
                                    sx={{ width: 20, height: 20 }}
                                >
                                    {task.isTaskDetailFolded ? <IconArrowDown size={12} /> : <IconArrowUp size={12} />}
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item xs pr={1}>
                            <Stack gap={1.5}>
                                <Grid container>
                                    <Grid item xs>
                                        <Grid container mt={0.25} mb={1.5} alignItems={"baseline"}>
                                            <Trans
                                                i18nKey="common::msg::Detailed view of <0> {{number}} </0> tasks"
                                                values={{ number: task.taskCount || 0 }}
                                                components={[
                                                    <Typography
                                                        sx={{ ml: theme.spacing(0.5) }}
                                                        variant={"body3_700"}
                                                        color="primary.main"
                                                    ></Typography>,
                                                ]}
                                            />
                                        </Grid>
                                        <Grid container gap={0.75} alignItems={"center"}>
                                            {renderJobStatus()}
                                        </Grid>
                                    </Grid>
                                    {action.download && job.status === "SUCCEED" && (
                                        <Button
                                            size="small"
                                            color="greySecondary"
                                            startIcon={<IconDownload size="16" />}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                action.download();
                                            }}
                                        >
                                            {t(
                                                `common::label::Download${job.fileCount > 1 ? " All" : ""}`,
                                                `Download${job.fileCount > 1 ? " All" : ""}`,
                                            )}
                                        </Button>
                                    )}
                                </Grid>
                            </Stack>
                            {!task.isTaskDetailFolded && task.taskDetailComponent}
                            {/* 여기에 task 상세 내용 */}
                        </Grid>
                    </Grid>
                </Box>
            </Collapse>
        </Box>
    );
};

export default JobItem;
