import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProjectSettings, getSettings, getStageSettings } from "@modules/settings";
import { SHOW_MENU_OPTIONS } from "@constants";
import { useTranslation } from "react-i18next";
import compareVersions, { VERSION_CM_11777_PROJECT_SETTINGS_ADD_MENU_NAME } from "@cores/version";

const useSettings = ({ type = "", domain, id = "" }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [settingsPending, setSettingsPending] = useState({
        settingData: false,
        projectSettingData: false,
        stageSettingData: false,
    });
    const stage = useSelector(({ stage }) => stage);
    const project = useSelector(({ project }) => project);
    const settings = useSelector(({ settings }) => settings);

    const generalSetting = JSON.parse(localStorage.getItem(`generalSetting`));

    const isCardViewInfo =
        JSON.parse(localStorage.getItem(`${domain}ViewInfo${id ? `${id}` : ""}`))?.isCardView ??
        (generalSetting?.consoleDefaultListLayout ? generalSetting?.consoleDefaultListLayout === "GRID" : "LIST");

    const localeLanguage = localStorage.getItem(`i18nLng`) ?? generalSetting?.consoleDefaultLanguage;

    const [isCardView, setIsCardView] = useState(isCardViewInfo);
    const [i18nLng, setI18nLng] = useState(localeLanguage);

    const updateIsCardView = (value) => {
        const originData = JSON.parse(localStorage.getItem(`${domain}ViewInfo${id}`)) ?? {};
        localStorage.setItem(`${domain}ViewInfo${id}`, JSON.stringify({ ...originData, isCardView: value }));
        setIsCardView(value);
    };

    const updateLocaleLanguage = (value) => {
        localStorage.setItem(`i18nLng`, value);
        setI18nLng(value);
    };

    const updateGeneralSettings = (value) => {
        const originGeneralSetting = JSON.parse(localStorage.getItem("generalSetting")) ?? {};
        localStorage.setItem("generalSetting", JSON.stringify({ ...originGeneralSetting, ...value }));
    };

    const getSettingResourceData = useCallback(async (resource, key) => {
        setSettingsPending({ ...settingsPending, settingData: true });
        const response = await dispatch(
            getSettings({
                stageId: stage.id,
                apiEndpoint: stage.endpoint,
                projectId: project.id,
                params: {
                    resource: resource,
                    key: key,
                },
            }),
        );
        setSettingsPending({ ...settingsPending, settingData: false });
        return response;
    }, []);

    const getSettingData = useCallback(async (resource, key) => {
        setSettingsPending({ ...settingsPending, settingData: true });
        const response = await dispatch(
            getSettings({
                stageId: stage.id,
                apiEndpoint: stage.endpoint,
                projectId: project.id,
                params: {
                    resource: resource,
                    key: key,
                },
            }),
        );
        setSettingsPending({ ...settingsPending, settingData: false });
        return response;
    }, []);

    const getProjectSettingData = useCallback(async () => {
        setSettingsPending({ ...settingsPending, projectSettingData: true });
        const response = await dispatch(
            getProjectSettings({
                apiEndpoint: stage.endpoint,
                stageId: stage.id,
                projectId: project.id,
            }),
        );
        setSettingsPending({ ...settingsPending, projectSettingData: false });
        return response;
    }, [stage, project]);

    const getStageSettingData = useCallback(async () => {
        setSettingsPending({ ...settingsPending, stageSettingData: true });
        const response = await dispatch(getStageSettings({ stageId: stage.id, apiEndpoint: stage.endpoint }));
        setSettingsPending({ ...settingsPending, stageSettingData: false });

        return response;
    }, [stage]);

    const navigatorMenus = useMemo(() => {
        const menus = {};

        if (!settings?.project) return null;

        SHOW_MENU_OPTIONS.forEach((menu, index) => {
            const computeMenuFlag = () => {
                if (
                    !settings?.project?.General?.showMenuOptions ||
                    parseInt(settings?.project?.General?.showMenuOptions?.value) === 0
                )
                    return true;
                return parseInt(settings?.project?.General.showMenuOptions.value) & menu.value;
            };
            menus[menu.id] = {
                id: menu.id,
                name: menu.name,
                visible: computeMenuFlag() > 0,
            };
        });
        return menus;
    }, [settings?.project]);

    const navigatorMenusName = useMemo(() => {
        if (compareVersions(stage.version, VERSION_CM_11777_PROJECT_SETTINGS_ADD_MENU_NAME) >= 0)
            return settings?.project?.MenuName;
        return true;
    }, [stage, settings?.project]);

    const getNavigatorMenuName = useCallback(
        (key) => {
            if (!settings?.project || !settings?.project?.MenuName?.changeName?.value)
                return t(`common::label::${key}`);
            const menus = JSON.parse(settings?.project?.MenuName?.changeName?.value);
            return menus?.[key] ?? t(`common::label::${key}`);
        },
        [settings?.project],
    );

    const getReturnProps = (type) => {
        const props = { getSettingData, getProjectSettingData, getStageSettingData, settings, settingsPending };
        switch (type) {
            case "General":
                return {
                    ...props,
                    isCardView,
                    i18nLng,
                    navigatorMenus,
                    updateIsCardView,
                    updateLocaleLanguage,
                    updateGeneralSettings,
                };
            case "MenuName":
                return {
                    ...props,
                    navigatorMenusName,
                    getNavigatorMenuName,
                };
            default:
                return props;
        }
    };

    return getReturnProps(type);
};

export default useSettings;
