import { axios, axiosWithoutHeaders } from "../cores/axiosWrapper";
import Polling from "../cores/polling";
import { handleActions, createAction } from "redux-actions";

// ROOM
const IV_ROOM_RESET = "IV_ROOM_RESET";
const IV_ROOM_PENDING = "IV_ROOM_PENDING";
const IV_GET_ROOM_DETAIL_SUCCESS = "IV_GET_ROOM_DETAIL_SUCCESS";
const IV_GET_ROOM_DETAIL_FAILURE = "IV_GET_ROOM_DETAIL_FAILURE";
const IV_UPDATE_ROOM_SUCCESS = "IV_UPDATE_ROOM_SUCCESS";
const IV_UPDATE_ROOM_FAILURE = "IV_UPDATE_ROOM_FAILURE";
const IV_DELETE_ROOM_SUCCESS = "IV_DELETE_ROOM_SUCCESS";
const IV_DELETE_ROOM_FAILURE = "IV_DELETE_ROOM_FAILURE";
const IV_CREATE_ROOM_SUCCESS = "IV_CREATE_ROOM_SUCCESS";
const IV_CREATE_ROOM_FAILURE = "IV_CREATE_ROOM_FAILURE";
// SCHEDULE
const IV_ROOM_SCHEDULE_PENDING = "IV_ROOM_SCHEDULE_PENDING";
const IV_GET_ROOM_SCHEDULE_LIST_SUCCESS = "IV_GET_ROOM_SCHEDULE_LIST_SUCCESS";
const IV_GET_ROOM_SCHEDULE_LIST_FAILURE = "IV_GET_ROOM_SCHEDULE_LIST_FAILURE";
const IV_UPDATE_ROOM_SCHEDULE_SUCCESS = "IV_UPDATE_ROOM_SCHEDULE_SUCCESS";
const IV_UPDATE_ROOM_SCHEDULE_FAILURE = "IV_UPDATE_ROOM_SCHEDULE_FAILURE";
const IV_DELETE_ROOM_SCHEDULE_SUCCESS = "IV_DELETE_ROOM_SCHEDULE_SUCCESS";
const IV_DELETE_ROOM_SCHEDULE_FAILURE = "IV_DELETE_ROOM_SCHEDULE_FAILURE";
const IV_CREATE_ROOM_SCHEDULE_SUCCESS = "IV_CREATE_ROOM_SCHEDULE_SUCCESS";
const IV_CREATE_ROOM_SCHEDULE_FAILURE = "IV_CREATE_ROOM_SCHEDULE_FAILURE";
const IV_UPDATE_ROOM_SCHEDULE_PARAMS = "IV_UPDATE_ROOM_SCHEDULE_PARAMS";
// CHANNEL
const IV_UPDATE_CHANNEL_SUCCESS = 'IV_UPDATE_CHANNEL_SUCCESS';
const IV_UPDATE_CHANNEL_FAILURE = 'IV_UPDATE_CHANNEL_FAILURE';
// RECORDING
const IV_ROOM_RECORDING_PENDING = 'IV_ROOM_RECORDING_PENDING';
const IV_GET_ROOM_RECORDING_LIST_SUCCESS = "IV_GET_ROOM_RECORDING_LIST_SUCCESS";
const IV_GET_ROOM_RECORDING_LIST_FAILURE = "IV_GET_ROOM_RECORDING_LIST_FAILURE";
const IV_DELETE_ROOM_RECORDING_SUCCESS = "IV_DELETE_ROOM_RECORDING_SUCCESS";
const IV_DELETE_ROOM_RECORDING_FAILURE = "IV_DELETE_ROOM_RECORDING_FAILURE";
const IV_UPDATE_ROOM_RECORDING_PARAMS = "IV_UPDATE_ROOM_RECORDING_PARAMS";
// NEETINGS
const IV_UPDATE_MEETING_RECORDING = 'IV_UPDATE_MEETING_RECORDING';
const IV_UPDATE_MEETING_RECORDING_SUCCESS = 'IV_UPDATE_MEETING_RECORDING_SUCCESS';
const IV_UPDATE_MEETING_RECORDING_FAILURE = 'IV_UPDATE_MEETING_RECORDING_FAILURE';


const initialState = {
    pending: false,
    error: false,
    data: null,
    schedules:{
        pending: false,
        error: false,
        data:[],
        totalCount:0,
        params: {
            offset: 0,
            limit: 20
        }
    },
    recordings:{
        pending: false,
        error: false,
        data:[],
        totalCount:0,
        params: {
            offset: 0,
            limit: 20
        }
    }
};

export function getIVRoomDetailAPI(stageId, apiEndpoint, projectId, queries) {
    const { roomId, ...rest } = queries;
    return axios.get(`${apiEndpoint}/interactive-video/rooms/${roomId}`, {
        params: {
            ...rest,
        },
        headers: {
            stageId,
            projectId,
        },
    });
}

export function deleteIVRoomAPI(stageId, apiEndpoint, projectId, queries) {
    const { roomId, ...rest } = queries;
    return axios.delete(`${apiEndpoint}/interactive-video/rooms/${roomId}`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

export function editIVRoomAPI(stageId, apiEndpoint, projectId, queries) {  
    const { roomId, ...rest } = queries;   
    return axios.patch(`${apiEndpoint}/interactive-video/rooms/${roomId}`, 
        rest, {
        headers: {
            stageId,
            projectId
        }
    });
};

export function getIVRoomSchedulesAPI(stageId, apiEndpoint, projectId, queries) {
    const { roomId, ...rest } = queries;
    return axios.get(`${apiEndpoint}/interactive-video/rooms/${roomId}/schedules`, {
        params: {
            ...rest,
        },
        headers: {
            stageId,
            projectId,
        },
    });
}

export function editIVRoomScheduleAPI(stageId, apiEndpoint, projectId, queries) {
    const { roomId, scheduleId, ...rest } = queries;
    return axios.get(`${apiEndpoint}/interactive-video/rooms/${roomId}/schedules/${scheduleId}`, {
        params: {
            ...rest,
        },
        headers: {
            stageId,
            projectId,
        },
    });
}

export function deleteRoomScheduleAPI(stageId, apiEndpoint, projectId, queries) {
    const { roomId, scheduleId, ...rest } = queries;
    return axios.delete(`${apiEndpoint}/interactive-video/rooms/${roomId}/schedules/${scheduleId}`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

export function updateMeetingRecordingAPI(stageId, apiEndpoint, projectId, queries) {
    const { meetingId, ...rest } = queries;

    return axios.patch(`${apiEndpoint}/interactive-video/meetings/${meetingId}`, rest, {
        headers: {
            stageId,
            projectId
        }
    })
}


export default handleActions({
    [IV_ROOM_RESET]: (state, action) => {
        return {
            ...initialState
        }
    },

    [IV_ROOM_PENDING]: (state, action) => {
        return {
            ...state,
            pending : true,
            error : false
        }
    },

    [IV_GET_ROOM_DETAIL_SUCCESS]: (state, action) => {
        const data = action.payload;
        return {
            ...state,
            data : data,
            pending : false
        }
    },

    [IV_GET_ROOM_DETAIL_FAILURE]: (state, action) => {
        return {
            ...state,
            pending : false,
            error : true
        }
    },

    [IV_CREATE_ROOM_SUCCESS]: (state, action) => {
        const data = action.payload;
        return {
            ...state,
            data,
            pending : false,
            error : false,
        }
    },

    [IV_CREATE_ROOM_FAILURE]: (state, action) => {
        return {
            ...state,
            pending : false,
            error : true,
        }
    },

    [IV_DELETE_ROOM_SUCCESS]: (state, action) => {
        return {
            ...state,
            data:[],
            pending : false,
            error : false
        }
    },

    [IV_DELETE_ROOM_FAILURE]: (state, action) => {
        return {
            ...state,
            pending : false,
            error : true
        }
    },

    [IV_UPDATE_ROOM_SUCCESS]: (state, action) => {
        const data = action.payload;
        return {
            ...state,
            data : data,
            pending : false,
            error: false
        }
    },

    [IV_UPDATE_ROOM_FAILURE]: (state, action) => {
        return {
            ...state,
            pending : false,
            error: true
        }
    },

    [IV_UPDATE_CHANNEL_SUCCESS]: (state, action) => {
        const data = action.payload;
        return {
            ...state,
            data : {
                ...state.data,
                channels: {
                    ...state.data.channels,
                    results: state.data.channels.results.map(item => {
                        if(item.id === data.id){
                            return data;
                        }
                        return item;
                    })
                },
            },
            pending : false,
            error: false
        }
    },

    [IV_UPDATE_CHANNEL_FAILURE]: (state, action) => {
        return {
            ...state,
            pending : false,
            error: true
        }
    },

    [IV_ROOM_SCHEDULE_PENDING]: (state, action) => {
        
        return {
            ...state,
            schedules: {
                ...state.schedules,
                pending: true,
                error: false
            }
        }
    },

    [IV_GET_ROOM_SCHEDULE_LIST_SUCCESS]: (state, action) => {
        const data = action.payload;
        return {
            ...state,
            schedules: {
                ...state.schedules,
                data: data.results,
                totalCount: data.totalCount,
                pending : false,
                error: false
            }
        }
    },

    [IV_GET_ROOM_SCHEDULE_LIST_FAILURE]: (state, action) => {
        return {
            ...state,
            schedules: {
                ...state.schedules,
                pending : false,
                error: true
            }
        }
    },

    [IV_CREATE_ROOM_SCHEDULE_SUCCESS]: (state, action) => {
        const data = action.payload;
        const newSchedules = state?.schedules?.data?.concat(data).sort((a,b)=>{
            const date_a = new Date(`${a.startAt}`)
            const date_b = new Date(`${b.startAt}`)
            return Number(date_b) - Number(date_a);
        }) || [];
        const newTotalCount = (state?.schedules?.totalCount + (data.length || 0)) || 0;
        return {
            ...state,
            schedules: {
                ...state.schedules,
                data: newSchedules,
                totalCount: newTotalCount,
                pending : false,
                error: false
            }
        }
    },

    [IV_CREATE_ROOM_SCHEDULE_FAILURE]: (state, action) => {
        return {
            ...state,
            schedules: {
                ...state.schedules,
                pending : false,
                error: true
            }
        }
    },

    [IV_DELETE_ROOM_SCHEDULE_SUCCESS]: (state, action) => {
        const data = action.payload;
        const newSchedules = state?.schedules?.data?.filter(c => c.id !== data.scheduleId) || [];
        const newTotalCount = (state?.schedules?.data?.totalCount - 1) || 0
        return {
            ...state,
            schedules: {
                ...state.schedules,
                data: newSchedules,
                totalCount: newTotalCount,
                pending: false,
            }
        }
    },

    [IV_DELETE_ROOM_SCHEDULE_FAILURE]: (state, action) => {
        return {
            ...state,
            schedules: {
                ...state.schedules,
                pending: false,
                error: true
            }
        }
    },

    [IV_UPDATE_ROOM_SCHEDULE_SUCCESS]: (state, action) => {
        const data = action.payload;
        const newSchedules = state?.schedules?.data.map(item => {
            if(item.id === data.id){
                return data;
            }
            return item;
        }).sort((a,b)=>{
            const date_a = new Date(`${a.startAt}`)
            const date_b = new Date(`${b.startAt}`)
            return Number(date_b) - Number(date_a);
        })

        // console.log('newSchedules', newSchedules)
        return {
            ...state,
            schedules: {
                ...state.schedules,
                data: newSchedules,
                pending : false,
                error: false
            },
        }
    },

    [IV_UPDATE_ROOM_SCHEDULE_FAILURE]: (state, action) => {
        return {
            ...state,
            schedules: {
                ...state.schedules,
                pending : false,
                error: true
            },
        }
    },

    [IV_UPDATE_ROOM_SCHEDULE_PARAMS]: (state, action) => {
        const params = action.payload;
        return {
            ...state,
            schedules: {
                ...state.schedules,
                params: {
                    ...state.schedules.params,
                    ...params
                }
            },
        }
    },

    [IV_ROOM_RECORDING_PENDING]: (state, action) => {
        return {
            ...state,
            recordings: {
                ...state.recordings,
                pending: true,
                error: false
            }
        }
    },

    [IV_GET_ROOM_RECORDING_LIST_SUCCESS]: (state, action) => {
        const data = action.payload;
        return {
            ...state,
            recordings: {
                ...state.recordings,
                data: data.results,
                totalCount: data.totalCount,
                pending : false,
                error: false
            }
        }
    },

    [IV_GET_ROOM_RECORDING_LIST_FAILURE]: (state, action) => {
        return {
            ...state,
            recordings: {
                ...state.recordings,
                pending : false,
                error: true
            }
        }
    },

    [IV_DELETE_ROOM_RECORDING_SUCCESS]: (state, action) => {
        const data = action.payload;
        const newRecordings = state?.recordings?.data?.filter(c => c.id !== data.recordingId) || [];
        const newTotalCount = (state?.recordings?.totalCount - 1) || 0
        return {
            ...state,
            recordings: {
                ...state.recordings,
                data: newRecordings,
                totalCount: newTotalCount,
                pending: false,
            }
        }
    },

    [IV_DELETE_ROOM_RECORDING_FAILURE]: (state, action) => {
        return {
            ...state,
            recordings: {
                ...state.recordings,
                pending: false,
                error: true
            }
        }
    },

    [IV_UPDATE_ROOM_RECORDING_PARAMS]: (state, action) => {
        const params = action.payload;
        return {
            ...state,
            recordings: {
                ...state.recordings,
                params: {
                    ...state.recordings.params,
                    ...params
                }
            },
        }
    },

    [IV_UPDATE_MEETING_RECORDING]: (state) => {
        return {
            ...state,
            pending: true,
            error: false
        }
    },

    [IV_UPDATE_MEETING_RECORDING_SUCCESS]: (state, action) => {        
        return {
            ...state,
            pending: false,
            data: {
                ...state.data,
                meetings: [action.payload]
            }
        }
    },

    [IV_UPDATE_MEETING_RECORDING_FAILURE]: (state) => {
        return {
            ...state,
            pending: false,
            error: true
        }
    }

}, initialState);

export const reset = createAction(IV_ROOM_RESET);

function createIvRoomAPI(stageId, apiEndpoint, projectId, data) {    
    return axios.post(`${apiEndpoint}/interactive-video/rooms`, data, {
        headers: {
            stageId,
            projectId
        }
    });
}

export const createIvRoom = (data) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();
    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;

    dispatch({type: IV_ROOM_PENDING});
    return createIvRoomAPI(stageId, apiEndpoint, projectId, data)
        .then((response) => {
            dispatch({
                type: IV_CREATE_ROOM_SUCCESS,
                payload: response.data
            });
            return {
                result: 'success'
            }
        })
        .catch(error => {
            dispatch({
                type: IV_CREATE_ROOM_FAILURE,
                payload: error
            });
            return {
               result: 'failure',
               error: error
            }
        });
}

export function getIvRoomDetailAPI(stageId, apiEndpoint, projectId, id) {     
    return axios.get(`${apiEndpoint}/interactive-video/rooms/${id}`, {
        headers: {
            stageId,
            projectId
        }
    });
};

export const getIvRoomDetail = (id) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;

    dispatch({type: IV_ROOM_PENDING});
    return getIvRoomDetailAPI(stageId, apiEndpoint, projectId, id)
        .then((response) => {
            dispatch({                
                type: IV_GET_ROOM_DETAIL_SUCCESS,
                payload: response.data
            });
            return {
                result: 'success',
                data: response.data
            }
        })
        .catch(error => {
            dispatch({
                type: IV_GET_ROOM_DETAIL_FAILURE,
                payload: error
            });
            return {
                result: 'failure',
                error
            }
        });
};

function deleteIvRoomAPI(stageId, apiEndpoint, projectId, id) {    
    return axios.delete(`${apiEndpoint}/interactive-video/rooms/${id}`, {
        headers: {
            stageId,
            projectId
        }
    });
}

export const deleteIvRoom = (id) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;

    dispatch({type: IV_ROOM_PENDING});
    return deleteIvRoomAPI(stageId, apiEndpoint, projectId, id)
        .then((response) => {
            dispatch({                
                type: IV_DELETE_ROOM_SUCCESS,
            });
            return {
                result: 'success'
            }
        })
        .catch(error => {
            dispatch({
                type: IV_DELETE_ROOM_FAILURE,
                payload: error
            });
            return {
                result: 'failure',
                error
            }
        });
};

function updateIvRoomAPI(stageId, apiEndpoint, projectId, id, data) {     
    return axios.patch(`${apiEndpoint}/interactive-video/rooms/${id}`, data, {
        headers: {
            stageId,
            projectId
        }
    });
};

export const updateIvRoom = (id, data) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;

    dispatch({type: IV_ROOM_PENDING});
 
    return updateIvRoomAPI(stageId, apiEndpoint, projectId, id, data)
        .then((response) => {
            dispatch({                
                type: IV_UPDATE_ROOM_SUCCESS,
                payload: response.data
            });
            return {
                result: 'success',
            }
        })
        .catch(error => {
            dispatch({
                type: IV_UPDATE_ROOM_FAILURE,
                payload: error
            });
            return {
                result: 'failure',
                error
            }
        });
}

function updateIvChannelAPI(stageId, apiEndpoint, projectId, id, data) {     
    return axios.patch(`${apiEndpoint}/interactive-video/channels/${id}`, data, {
        headers: {
            stageId,
            projectId
        }
    });
};

export const updateIvChannel = (channelId, data) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;

    dispatch({type: IV_ROOM_PENDING});
 
    return updateIvChannelAPI(stageId, apiEndpoint, projectId, channelId, data)
        .then((response) => {
            dispatch({                
                type: IV_UPDATE_CHANNEL_SUCCESS,
                payload: response.data
            });
            return {
                result: 'success',
            }
        })
        .catch(error => {
            dispatch({
                type: IV_UPDATE_CHANNEL_FAILURE,
                payload: error
            });
            return {
                result: 'failure',
                error
            }
        });
}

export function getIvRoomScheduleListAPI(stageId, apiEndpoint, projectId, id, params) {     
    return axios.get(`${apiEndpoint}/interactive-video/rooms/${id}/schedules`, {
        headers: {
            stageId,
            projectId
        },
        params
    });
};

export const getIvRoomScheduleList = (id, givenParams = {}) => (dispatch, getState) => {
    const {
        stage,
        project,
        iv_room
    } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;
    const defaultParams = iv_room?.schedules?.params;

    dispatch({type: IV_ROOM_SCHEDULE_PENDING});
    const params = {
        ...defaultParams,
        ...givenParams
    }
    return getIvRoomScheduleListAPI(stageId, apiEndpoint, projectId, id, params)
        .then((response) => {
            dispatch({                
                type: IV_GET_ROOM_SCHEDULE_LIST_SUCCESS,
                payload: response.data
            });
            return {
                result: 'success'
            }
        })
        .catch(error => {
            dispatch({
                type: IV_GET_ROOM_SCHEDULE_LIST_FAILURE,
                payload: error
            });
            return {
                result: 'failure',
                error
            }
        });
};

function createIvRoomScheduleAPI(stageId, apiEndpoint, projectId, id, schedules) {    
    return axios.post(`${apiEndpoint}/interactive-video/rooms/${id}/schedules`, {
        schedules
    }, {
        headers: {
            stageId,
            projectId
        }
    });
}

export const createIvRoomSchedule = (id, schedules) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();
    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;

    dispatch({type: IV_ROOM_SCHEDULE_PENDING});
    return createIvRoomScheduleAPI(stageId, apiEndpoint, projectId, id, schedules)
        .then((response) => {
            dispatch({
                type: IV_CREATE_ROOM_SCHEDULE_SUCCESS,
                payload: response.data
            });
            return {
                result: 'success'
            }
        })
        .catch(error => {
            dispatch({
                type: IV_CREATE_ROOM_SCHEDULE_FAILURE,
                payload: error
            });
            return {
               result: 'failure',
               error: error
            }
        });
}

function deleteIvRoomScheduleAPI(stageId, apiEndpoint, projectId, id, scheduleId) {    
    return axios.delete(`${apiEndpoint}/interactive-video/rooms/${id}/schedules/${scheduleId}`, {
        headers: {
            stageId,
            projectId
        }
    });
}

export const deleteIvRoomSchedule = (id, scheduleId) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;

    dispatch({type: IV_ROOM_SCHEDULE_PENDING});
    return deleteIvRoomScheduleAPI(stageId, apiEndpoint, projectId, id, scheduleId)
        .then((response) => {
            dispatch({                
                type: IV_DELETE_ROOM_SCHEDULE_SUCCESS,
                payload: {
                    scheduleId
                }
            });
            return {
                result: 'success'
            }
        })
        .catch(error => {
            dispatch({
                type: IV_DELETE_ROOM_SCHEDULE_FAILURE,
                payload: error
            });
            return {
                result: 'failure',
                error
            }
        });
};

function updateIvRoomScheduleAPI(stageId, apiEndpoint, projectId, id, scheduleId, data) {     
    return axios.patch(`${apiEndpoint}/interactive-video/rooms/${id}/schedules/${scheduleId}`, data, {
        headers: {
            stageId,
            projectId
        }
    });
};

export const updateIvRoomSchedule = (id, scheduleId, data) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;

    dispatch({type: IV_ROOM_SCHEDULE_PENDING});
 
    return updateIvRoomScheduleAPI(stageId, apiEndpoint, projectId, id, scheduleId, data)
        .then((response) => {
            dispatch({                
                type: IV_UPDATE_ROOM_SCHEDULE_SUCCESS,
                payload: response.data
            });
            return {
                result: 'success',
            }
        })
        .catch(error => {
            dispatch({
                type: IV_UPDATE_ROOM_SCHEDULE_FAILURE,
                payload: error
            });
            return {
                result: 'failure',
                error
            }
        });
}

export const updateIvRoomScheduleParamsAndGetData = (id, params) => (dispatch, getState) => {
    dispatch({
        type: IV_UPDATE_ROOM_SCHEDULE_PARAMS,
        payload: params
    });
    return getIvRoomScheduleList(id)(dispatch, getState)
};

export const updateIvRoomScheduleParams = createAction(IV_UPDATE_ROOM_SCHEDULE_PARAMS);

export function getIvRoomRecordingListAPI(stageId, apiEndpoint, projectId, id, params) {     
    // return axios.get(`${apiEndpoint}/interactive-video/channels/${id}/recordings`, {
    return axios.get(`${apiEndpoint}/interactive-video/rooms/${id}/recordings`, {
        headers: {
            stageId,
            projectId
        },
        params
    });
};

export const getIvRoomRecordingList = (id, givenParams = {}) => (dispatch, getState) => {
    const {
        stage,
        project,
        iv_room
    } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;
    const defaultParams = iv_room?.recordings?.params;

    dispatch({type: IV_ROOM_RECORDING_PENDING});
    const params = {
        ...defaultParams,
        ...givenParams
    }
    return getIvRoomRecordingListAPI(stageId, apiEndpoint, projectId, id, params)
        .then((response) => {
            dispatch({                
                type: IV_GET_ROOM_RECORDING_LIST_SUCCESS,
                payload: response.data
            });
            return {
                result: 'success'
            }
        })
        .catch(error => {
            dispatch({
                type: IV_GET_ROOM_RECORDING_LIST_FAILURE,
                payload: error
            });
            return {
                result: 'failure',
                error
            }
        });
};

function deleteIvRoomRecordingAPI(stageId, apiEndpoint, projectId, id, recordingId) {    
    return axios.delete(`${apiEndpoint}/interactive-video/channels/${id}/recordings/${recordingId}`, {
        headers: {
            stageId,
            projectId
        }
    });
}

export const deleteIvRoomRecording = (id, recordingId) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;

    dispatch({type: IV_ROOM_RECORDING_PENDING});
    return deleteIvRoomRecordingAPI(stageId, apiEndpoint, projectId, id, recordingId)
        .then((response) => {
            dispatch({                
                type: IV_DELETE_ROOM_RECORDING_SUCCESS,
                payload: {
                    recordingId
                }
            });
            return {
                result: 'success'
            }
        })
        .catch(error => {
            dispatch({
                type: IV_DELETE_ROOM_RECORDING_FAILURE,
                payload: error
            });
            return {
                result: 'failure',
                error
            }
        });
};

export const updateIvRoomRecordingParamsAndGetData = (id, params) => (dispatch, getState) => {
    dispatch({
        type: IV_UPDATE_ROOM_RECORDING_PARAMS,
        payload: params
    });
    return getIvRoomRecordingList(id)(dispatch, getState)
};

export const updateIvRoomRecordingParams = createAction(IV_UPDATE_ROOM_RECORDING_PARAMS);

export const  updateIvMeetingRecording = (meetingId, params) => async (dispatch, getState) => {
    const { stage, project } = getState();
    
    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;
    const queries = {
        meetingId, 
        ...params
    }

    dispatch({ type: IV_UPDATE_MEETING_RECORDING });

    try {
        const result = await updateMeetingRecordingAPI(stageId, apiEndpoint, projectId, queries);
        const meeting = result.data;
        dispatch( { type: IV_UPDATE_MEETING_RECORDING_SUCCESS, payload: meeting })
    } catch(error) {
        console.error("updateIvMeetingRecording, 미팅 레코딩 변경 오류", error);
        dispatch( { type: IV_UPDATE_MEETING_RECORDING_FAILURE })
    }


}