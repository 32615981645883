import React from "react";
import { Grid, ToggleButton, ToggleButtonGroup, Tooltip } from "@mzc-pdc/ui";
import { IconGridView, IconListView } from "@mzc-cloudplex/icons";
import { alpha } from "@mui/material";
import theme from "@styles/theme";
import { useTranslation } from "react-i18next";

const ListToolViewToggleButton = ({ isCardView, onChange }) => {
    const { t } = useTranslation();
    return (
        <ToggleButtonGroup
            onChange={(e, value) => {
                if (value === null) return;
                onChange(value);
            }}
            exclusive={true}
            value={isCardView}
            variant="views mode"
            size={"small"}
            sx={{
                "& .MuiToggleButton-root": {
                    minWidth: 24,
                    minHeight: 24,
                },
            }}
        >
            <ToggleButton color="secondary" value={true}>
                <Tooltip title={t(`common::label::Grid Layout`, `Grid Layout`)}>
                    <Grid item sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <IconGridView size={16} />
                    </Grid>
                </Tooltip>
            </ToggleButton>
            <ToggleButton color="secondary" value={false}>
                <Tooltip title={t(`common::label::List Layout`, `List Layout`)}>
                    <Grid item sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <IconListView size={16} />
                    </Grid>
                </Tooltip>
            </ToggleButton>
        </ToggleButtonGroup>
    );
};
export default ListToolViewToggleButton;
