import React from "react";

const EntryLoader = () => {
    return (
        <div className={"loader loader-entry"}>
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="320" height="260">
                    <g id="img_entering" transform="translate(-800 -335)">
                        <g id="Image" transform="translate(677 253)">
                            <g id="Bg_Image" transform="translate(-677 -213)">
                                <path id="패스_2582" data-name="패스 2582" d="M4.5 18.021h6.436a4.509 4.509 0 0 0 0 9.013H63.07a4.509 4.509 0 0 0 0-9.013h-6.436a4.5 4.5 0 0 0 0-9.008h9.008a4.509 4.509 0 0 0 0-9.013h-52.13a4.509 4.509 0 0 0 0 9.013H4.5a4.5 4.5 0 0 0 0 9.008z" transform="translate(824.703 444.104)" fill="#f2f2f8"/>
                                <path id="패스_2584" data-name="패스 2584" className="cls-3" fill="#f5f5fa" d="M2.574 10.3h3.677a2.577 2.577 0 0 0 0 5.15H36.04a2.577 2.577 0 0 0 0-5.15h-3.678a2.574 2.574 0 0 0 0-5.147h5.148a2.577 2.577 0 0 0 0-5.15H7.721a2.577 2.577 0 0 0 0 5.15H2.574a2.574 2.574 0 1 0 0 5.147z" transform="translate(889.703 502.519)"/>
                                <path id="패스_2585" data-name="패스 2585" className="cls-3" fill="#f5f5fa" d="M3.217 12.872h4.6a3.221 3.221 0 0 0 0 6.438H45.05a3.221 3.221 0 0 0 0-6.438h-4.6a3.217 3.217 0 0 0 0-6.434h6.434a3.221 3.221 0 0 0 0-6.438H9.651a3.221 3.221 0 0 0 0 6.438H3.217a3.217 3.217 0 1 0 0 6.434z" transform="translate(1061.703 471.138)"/>
                                <path id="패스_2616" data-name="패스 2616" className="cls-4" fill="#e6e4ff" d="M447 259.45a2.84 2.84 0 0 0-.92-3.67 1.211 1.211 0 0 1-.24-.21 1.43 1.43 0 0 1 1.4-2.35 1.34 1.34 0 0 1 .3.12 2.85 2.85 0 0 0 3.68-.91 1.22 1.22 0 0 1 .2-.25 1.43 1.43 0 0 1 2.35 1.41 1.48 1.48 0 0 1-.12.29 2.85 2.85 0 0 0 .92 3.68 1.21 1.21 0 0 1 .24.21 1.42 1.42 0 0 1-1.4 2.34 1 1 0 0 1-.3-.12 2.86 2.86 0 0 0-3.68.92 1.27 1.27 0 0 1-.21.25 1.43 1.43 0 0 1-2.34-1.41 1.881 1.881 0 0 1 .12-.3z" transform="translate(474.514 200.957)"/>
                                <path id="패스_2617" data-name="패스 2617" className="cls-4" fill="#e6e4ff" d="M546.63 223.29a2.84 2.84 0 0 0-1.34-3.54 1.269 1.269 0 0 1-.27-.18 1.42 1.42 0 0 1 1.12-2.49 1.09 1.09 0 0 1 .31.08 2.86 2.86 0 0 0 3.55-1.34 1.586 1.586 0 0 1 .18-.27 1.43 1.43 0 0 1 2.49 1.13 1.808 1.808 0 0 1-.09.31 2.85 2.85 0 0 0 1.35 3.54 1.511 1.511 0 0 1 .26.18 1.43 1.43 0 0 1-1.12 2.49 1.573 1.573 0 0 1-.31-.08 2.84 2.84 0 0 0-3.54 1.34 2.111 2.111 0 0 1-.18.27 1.43 1.43 0 0 1-2.49-1.13 1.179 1.179 0 0 1 .08-.31z" transform="translate(437.727 187.305)"/>
                            </g>
                            <g id="Person" transform="translate(-27.008 44.078)">
                                <path id="패스_2472" data-name="패스 2472" d="M699.324 453.578c8.353-4.591 18.748-3.669 27.748-.531s17.155 8.281 25.895 12.084a94.785 94.785 0 0 0 84.072-4.895c2.381 5.539-.173 11.874-2.979 17.21s-6 11.12-4.849 17.037c1.478 7.572 9.417 12.112 12.634 19.124 3.054 6.657 1.415 14.474-.928 21.413s-5.383 13.93-4.985 21.242 5.711 15.045 13.035 15.051c-10.853 1.686-21.92 3.374-32.767 1.646s-21.636-7.465-26.673-17.226c-2.517-4.879-3.488-10.488-6.379-15.156-4.924-7.949-14.374-11.688-23.291-14.505s-18.458-5.531-24.736-12.462c-5.982-6.6-7.876-15.8-11.045-24.129a77.069 77.069 0 0 0-26.685-34.8" transform="translate(-403.109 -291.792)" fill="#d2d2fc"/>
                                <g id="그룹_9277" data-name="그룹 9277" transform="translate(266.712 112.922)">
                                    <path id="패스_2474" data-name="패스 2474" className="cls-6" fill="#febb9c" d="m535.522 592.584-4.878-1.466 3.332-19.514 7.2 2.163z" transform="translate(-515.958 -421.239)"/>
                                    <path id="패스_2476" data-name="패스 2476" className="cls-6" fill="#febb9c" d="M642.607 594.855h-5.094l-2.423-19.647h7.518z" transform="translate(-577.008 -423.345)"/>
                                    <path id="패스_2478" data-name="패스 2478" className="cls-7" fill="#11193f" d="M712.17 602.484a1.87 1.87 0 0 1-1.845-1.574l-7.563-47.18a1.454 1.454 0 0 0-2.779-.328l-19.595 47.172a1.876 1.876 0 0 1-2.318 1.057l-7.078-2.359a1.863 1.863 0 0 1-1.232-2.186l16.058-71.057a1.88 1.88 0 0 1 1.4-1.409l21.536-5a1.9 1.9 0 0 1 1.54.32c14.181 10.3 12.983 43.967 10.34 80.52a1.871 1.871 0 0 1-1.784 1.734l-6.6.287z" transform="translate(-653.643 -445.834)"/>
                                    <path id="패스_2479" data-name="패스 2479" className="cls-8" fill="#6a60ff" d="M706.1 486.06a16.577 16.577 0 0 1-7.073-1.635l-.076-.035-.03-.078-9.158-23.712-.174-4.12a9.877 9.877 0 0 1 10.425-10.28l9.717.547a9.883 9.883 0 0 1 9.322 9.81 6.671 6.671 0 0 1-1.468 7.838c-.131.811-1.36 9.243 3.277 13.88l.13.13-.114.145c-.078.093-6.028 7.509-14.778 7.51z" transform="translate(-665.255 -402.936)"/>
                                    <g id="왼팔" transform="rotate(13 4.474 .25)">
                                        <path id="패스_2612" data-name="패스 2612" className="cls-6" fill="#febb9c" d="M571.848 281.006a4.355 4.355 0 0 0 3.83 5.471l24.413 31.267 6.372-7.008-26-28.531a4.379 4.379 0 0 0-8.614-1.2z" transform="translate(-571.698 -277.738)"/>
                                        <path id="패스_2613" data-name="패스 2613" className="cls-8" fill="#6a60ff" d="M556.015 368.027a1.951 1.951 0 0 0 .572 1.522L570.44 384.1c1.786 2.4-1.713-4.739.691-6.525a5.424 5.424 0 0 0 1.12-7.588l-5.418-7.455a1.953 1.953 0 0 0-2.888-.637l-7.151 4.705a1.951 1.951 0 0 0-.779 1.427z" transform="translate(-533.096 -331.569)"/>
                                    </g>
                                    <g id="오른팔" transform="rotate(-14.98 203.6 -150.535)">
                                        <path id="합치기_279" data-name="합치기 279" className="cls-6" fill="#febb9c" d="M20821.6 4284.218a4.2 4.2 0 0 1 2.752-4.854l9.834-7.794-4.918-12.093 7.686-3.991 5.523 17.414a1.338 1.338 0 0 1-.434 1.031h.01l-.045.03a3.812 3.812 0 0 1-.965.655l-11.271 7.652a4.175 4.175 0 0 1-3.678 5.247 4.444 4.444 0 0 1-.4.019 4.194 4.194 0 0 1-4.094-3.316z" transform="translate(-20827.471 -4249.274)"/>
                                        <path id="패스_2615" data-name="패스 2615" className="cls-8" fill="#6a60ff" d="M483.176 402.695a1.783 1.783 0 0 0-.008-1.487l-3.78-8.126a4.959 4.959 0 1 0-9.518 2.79l1.263 8.9a1.786 1.786 0 0 0 2.275 1.461l8.647-2.562a1.784 1.784 0 0 0 1.121-.976z" transform="translate(-469.669 -389.517)"/>
                                    </g>
                                    <path id="사각형_3238" data-name="사각형 3238" className="cls-7" fill="#11193f" d="M1.2 0h5.6A1.2 1.2 0 0 1 8 1.2V13a4 4 0 0 1-4 4 4 4 0 0 1-4-4V1.2A1.2 1.2 0 0 1 1.2 0z" transform="rotate(11 -840.099 159.18)"/>
                                    <path id="사각형_3239" data-name="사각형 3239" className="cls-7" fill="#11193f" d="M4 0a4 4 0 0 1 4 4v11.8A1.2 1.2 0 0 1 6.8 17H1.2A1.2 1.2 0 0 1 0 15.8V4a4 4 0 0 1 4-4z" transform="scale(-1) rotate(-2.98 -3551.023 1209.067)"/>
                                    <g id="머리" transform="translate(27.092 -3.46)">
                                        <path id="패스_2586" data-name="패스 2586" className="cls-9" fill="#2f2e41" d="M476.287 297.6a12.075 12.075 0 0 1 15.994-10.2l-.875.089a16 16 0 0 1 9.778 6.246 13.852 13.852 0 0 1 2.195 11.3 12.228 12.228 0 0 1-7.67 8.436 22.516 22.516 0 0 1-6.523.871 14.1 14.1 0 0 1-4.261-.291 8.95 8.95 0 0 1-4.457-3.107 18.287 18.287 0 0 1-2.636-4.827 18.492 18.492 0 0 1-1.545-8.517z" transform="translate(-476.139 -270.064)"/>
                                        <circle id="타원_654" data-name="타원 654" className="cls-6" fill="#febb9c" cx="10.571" cy="10.571" transform="rotate(-106.909 20.383 21.073)" r="10.571"/>
                                        <path id="패스_2601" data-name="패스 2601" className="cls-9" fill="#2f2e41" d="m506.606 314.156-4.093-.852-.754-5.432-1.413 4.981-1.956-.407-.44-3.169-.824 2.906-14.537-3.025a11.19 11.19 0 0 1 13.235-8.676l2.107.438a11.19 11.19 0 0 1 8.675 13.236z" transform="translate(-481.47 -278.797)"/>
                                        <path id="패스_2609" data-name="패스 2609" className="cls-9" fill="#2f2e41" d="M356.737 257.667c-.987-3.945.925-8.176 3.884-10.965s6.821-4.391 10.643-5.779c3.048-1.107 6.618-2.069 9.377-.365 2.217 1.37 3.178 4.03 4.445 6.307a20.67 20.67 0 0 0 25.4 9.27c-1.636 4.749-7.271 7.072-12.284 6.75s-9.643-2.631-14.313-4.482-9.864-3.281-14.664-1.8c-2.026.626-3.868 1.742-5.874 2.43s-4.353.892-6.14-.248" transform="translate(-340.49 -239.632)"/>
                                    </g>
                                </g>
                            </g>
                            <g id="_" data-name="|||" transform="translate(-37.949 29)">
                                <rect id="사각형_3235" data-name="사각형 3235" className="cls-8" width="1" height="45" rx=".5" transform="translate(320.949 58)"/>
                                <rect id="사각형_3236" data-name="사각형 3236" className="cls-8" width="1" height="25" rx=".5" transform="rotate(-45 248.778 -281.573)"/>
                                <rect id="사각형_3237" data-name="사각형 3237" className="cls-8" width="1" height="25" rx=".5" transform="rotate(-135 199.64 -17.6)"/>
                            </g>
                        </g>
                    </g>
                </svg>
                <div className="dots">
                    <svg >
                        <circle className='dot' />
                        <circle className='dot' />
                        <circle className='dot' />
                        <circle className='dot' />
                        <circle className='dot' />
                        <circle className='dot' />
                    </svg>
                </div>
                <div className="text">
                    <p>Entering Console…</p>
                </div>
            </div>
        </div>
    );
};

export default EntryLoader;
