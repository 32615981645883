import {createAction, handleActions} from 'redux-actions';

const UPLOAD_METADATA_REMOVE_ATTRIBUTION = 'UPLOAD_METADATA_REMOVE_ATTRIBUTION';
const UPLOAD_METADATA_ADD_ATTRIBUTION = 'UPLOAD_METADATA_ADD_ATTRIBUTION';
const UPLOAD_METADATA_UPDATE_ATTRIBUTION = 'UPLOAD_METADATA_UPDATE_ATTRIBUTION';

const UPLOAD_METADATA_REMOVE_CATEGORY = 'UPLOAD_METADATA_REMOVE_CATEGORY';
const UPLOAD_METADATA_ADD_CATEGORY = 'UPLOAD_METADATA_ADD_CATEGORY';

const UPLOAD_METADATA_UPDATE_STATE = 'UPLOAD_METADATA_UPDATE_STATE';
const UPLOAD_METADATA_RESET = 'UPLOAD_METADATA_RESET';

const UPLOAD_METADATA_SET_VALIDATION_ERROR = 'UPLOAD_METADATA_SET_VALIDATION_ERROR';

const UPLOAD_METADATA_ADD_TAG = 'UPLOAD_METADATA_ADD_TAG';
const UPLOAD_METADATA_REMOVE_TAG = 'UPLOAD_METADATA_REMOVE_TAG';

const initialState = {
    categories : [],
    tags : [],
    attributions : [],
    metadata : [],
    name : '',
    description : '',
    validationErrors : {}
};

export default handleActions({
    [UPLOAD_METADATA_REMOVE_ATTRIBUTION]: (state, action) => {
        return {
            ...state,            
            attributions : state.attributions.filter(v => v.id !== action.payload)
        };
    },
    [UPLOAD_METADATA_ADD_ATTRIBUTION]: (state, action) => {
        return {
            ...state,            
            attributions : state.attributions.concat({
                id : action.payload,
                key : '',
                value : ''
            })
        };
    },
    [UPLOAD_METADATA_UPDATE_ATTRIBUTION]: (state, action) => {
        const data = action.payload;
        
        return {
            ...state,
            attributions : state.attributions.map((a, i) => {
                if(a.id === data.id) {
                    a.key = data.key === undefined ? a.key : data.key;
                    a.value = data.value === undefined ? a.value : data.value;
                }
                
                return a;
            })
        };
    },
    [UPLOAD_METADATA_RESET]: (state) => {
        const metadata = state.metadata && state.metadata.map(v => {
            return {
                ...v,
                value : ""
            }
        })
        return {
            categories : [],
            tags : [],
            attributions : [],
            name : '',
            description : '',
            validationErrors : {},
            metadata : metadata || []
        };
    },
    [UPLOAD_METADATA_UPDATE_STATE]: (state, action) => {
        return {
            ...state,
            ...action.payload
        }
    },
    [UPLOAD_METADATA_SET_VALIDATION_ERROR] : (state, action) => {
        const {
            key,
            error
        } = action.payload;

        if(error) {
            return {
                ...state,
                validationErrors : {
                    ...state.validationErrors,
                    [key] : error
                }
            };
        } 

        const validationErrors = state.validationErrors;
        if(validationErrors[key]) {
            delete validationErrors[key];
        }

        return {
            ...state,
            validationErrors
        };        
    },
    [UPLOAD_METADATA_ADD_TAG] : (state, action) => {
        return {
            ...state,
            tags : state.tags.concat([action.payload])
        };
    },
    [UPLOAD_METADATA_REMOVE_TAG] : (state, action) => {
        return {
            ...state,
            tags : state.tags.filter((v, i) => i !== action.payload)
        };
    }
}, initialState);

export const reset = createAction(UPLOAD_METADATA_RESET);
export const updateState = createAction(UPLOAD_METADATA_UPDATE_STATE);
export const addCategory = createAction(UPLOAD_METADATA_ADD_CATEGORY);
export const removeCategory = createAction(UPLOAD_METADATA_REMOVE_CATEGORY);
export const addAttribution = createAction(UPLOAD_METADATA_ADD_ATTRIBUTION);
export const removeAttribution = createAction(UPLOAD_METADATA_REMOVE_ATTRIBUTION);
export const updateAttribution = createAction(UPLOAD_METADATA_UPDATE_ATTRIBUTION);
export const addTag = createAction(UPLOAD_METADATA_ADD_TAG);
export const removeTag = createAction(UPLOAD_METADATA_REMOVE_TAG);
export const setValidationError = createAction(UPLOAD_METADATA_SET_VALIDATION_ERROR);