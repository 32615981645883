import { handleActions, createAction } from "redux-actions";

import { axios, axiosWithoutHeaders } from "../cores/axiosWrapper";

import queryString from "../cores/queryString";
import uniqid from "uniqid";
import { toggleConditionCustomFieldExpander } from "./createEventTrigger";
import { composeInitialProps } from "react-i18next";

function getListAllBucketsAPI(apiEndpoint, stageId) {
    return axios.get(`${apiEndpoint}/aws/s3/buckets`, {
        headers: {
            stageId,
        },
    });
}

function getSearchableBucketsForAdminAPI(apiEndpoint, stageId) {
    return axios.get(`${apiEndpoint}/admin/searchable-buckets`, {
        headers: {
            stageId,
        },
    });
}

function getSearchableBucketsInSpaceAPI(apiEndpoint, stageId) {
    return axios.get(`${apiEndpoint}/admin/searchable-buckets?isSearchable=true`, {
        headers: {
            stageId,
        },
    });
}

function getSearchableBucketsAPI(apiEndpoint, stageId, projectId) {
    return axios.get(`${apiEndpoint}/searchable-buckets`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function patchBulkSearchableBucketsAPI(apiEndpoint, stageId, data) {
    return axios.patch(`${apiEndpoint}/searchable-buckets/register`, data, {
        headers: {
            stageId,
        },
    });
}

function patchBulkSearchableBucketsLockAPI(apiEndpoint, stageId, projectId, status, data) {
    return axios.post(`${apiEndpoint}/searchable-buckets:${status}`, data, {
        headers: {
            stageId,
            projectId,
        },
    });
}

/**
 *
 * @param {string} apiEndpoint
 * @param {string} stageId
 * @param {object} data searchable bukcet item
 * @returns
 */
function patchSearchableBucketItemAPI(apiEndpoint, stageId, data) {
    return axios.patch(`${apiEndpoint}/searchable-buckets/${data.id}`, data, {
        headers: {
            stageId,
        },
    });
}

const SEARCHABLE_BUCKETS_PENDING = "SEARCHABLE_BUCKETS_PENDING";
const SEARCHABLE_BUCKETS_FAILURE = "SEARCHABLE_BUCKETS_FAILURE";
const SEARCHABLE_BUCKETS_GET_ALL_BUCKETS_PENDING = "SEARCHABLE_BUCKETS_GET_ALL_BUCKETS_PENDING";
const SEARCHABLE_BUCKETS_GET_ALL_BUCKETS_FAILURE = "SEARCHABLE_BUCKETS_GET_ALL_BUCKETS_FAILURE";
const SEARCHABLE_BUCKETS_GET_LIST_ALL_BUCKETS_SUCCESS = "SEARCHABLE_BUCKETS_GET_LIST_ALL_BUCKETS_SUCCESS";
const SEARCHABLE_BUCKETS_GET_SEARCHABLE_BUCKETS_SUCCESS = "SEARCHABLE_BUCKETS_GET_SEARCHABLE_BUCKETS_SUCCESS";
const SEARCHABLE_BUCKETS_RESET_SEARCHABLE_BUCKETS = "SEARCHABLE_BUCKETS_RESET_SEARCHABLE_BUCKETS";
const SEARCHABLE_BUCKETS_PATCH_SEARCHABLE_BUCKETS_ITEM_PENDING =
    "SEARCHABLE_BUCKETS_PATCH_SEARCHABLE_BUCKETS_ITEM_PENDING";
const SEARCHABLE_BUCKETS_PATCH_SEARCHABLE_BUCKETS_ITEM_FAILURE =
    "SEARCHABLE_BUCKETS_PATCH_SEARCHABLE_BUCKETS_ITEM_FAILURE";
const SEARCHABLE_BUCKETS_PATCH_SEARCHABLE_BUCKETS_ITEM_SUCCESS =
    "SEARCHABLE_BUCKETS_PATCH_SEARCHABLE_BUCKETS_ITEM_SUCCESS";
const SEARCHABLE_BUCKETS_UPDATE_SEARCHABLE_BUCKETS = "UPDATE_SEARCHABLE_BUCKETS";
const SEARCHABLE_BUCKETS_PATCH_LOCK_PENDING = "SEARCHABLE_BUCKETS_PATCH_LOCK_PENDING";
const SEARCHABLE_BUCKETS_PATCH_LOCK_COMPLATE = "SEARCHABLE_BUCKETS_PATCH_LOCK_COMPLATE";

//reducer
const initialState = {
    pending: false,
    error: false,
    listAllBucketsPending: false,
    listAllBucketsError: false,
    listAllBuckets: [],
    bucketsLockPendingList: [],
    data: [],
};

export default handleActions(
    {
        [SEARCHABLE_BUCKETS_PENDING]: (state, action) => {
            return {
                ...state,
                pending: true,
                error: false,
            };
        },
        [SEARCHABLE_BUCKETS_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [SEARCHABLE_BUCKETS_GET_ALL_BUCKETS_PENDING]: (state, action) => {
            return {
                ...state,
                listAllBucketsPending: true,
                listAllBucketsError: false,
            };
        },
        [SEARCHABLE_BUCKETS_GET_ALL_BUCKETS_FAILURE]: (state, action) => {
            return {
                ...state,
                listAllBucketsPending: false,
                listAllBucketsError: true,
            };
        },
        [SEARCHABLE_BUCKETS_GET_LIST_ALL_BUCKETS_SUCCESS]: (state, action) => {
            const data = action.payload.data;
            return {
                ...state,
                listAllBuckets: data.buckets,
                listAllBucketsPending: false,
                listAllBucketsError: false,
            };
        },
        [SEARCHABLE_BUCKETS_GET_SEARCHABLE_BUCKETS_SUCCESS]: (state, action) => {
            const data = action.payload.data;

            return {
                ...state,
                data: data.searchableBuckets.map((bucket) => ({
                    ...bucket,
                    pending: false,
                    error: false,
                })),
                pending: false,
                error: false,
            };
        },
        [SEARCHABLE_BUCKETS_RESET_SEARCHABLE_BUCKETS]: (state) => {
            return {
                ...state,
                listAllBuckets: [],
                data: [],
            };
        },
        [SEARCHABLE_BUCKETS_PATCH_SEARCHABLE_BUCKETS_ITEM_PENDING]: (state, action) => {
            const bucketItemId = action.payload.bucketItemId;
            const data = state.data.map((bucketItem) => {
                return bucketItem.id !== bucketItemId
                    ? bucketItem
                    : {
                          ...bucketItem,
                          pending: true,
                          error: false,
                      };
            });

            return {
                ...state,
                pending: true,
                error: false,
                data,
            };
        },
        [SEARCHABLE_BUCKETS_PATCH_SEARCHABLE_BUCKETS_ITEM_SUCCESS]: (state, action) => {
            // const bucketItemId = action.payload.bucketItemId;
            const updatedBucketItem = action.payload.updatedBucketItem;
            const bucketItemId = action.payload.updatedBucketItem.id;

            const data = state.data.map((bucketItem) => {
                return bucketItem.id !== bucketItemId
                    ? bucketItem
                    : {
                          ...updatedBucketItem,
                          pending: false,
                          error: false,
                      };
            });

            return {
                ...state,
                pending: false,
                error: false,
                data,
            };
        },
        [SEARCHABLE_BUCKETS_PATCH_SEARCHABLE_BUCKETS_ITEM_FAILURE]: (state, action) => {
            const bucketItemId = action.payload.bucketItemId;

            const data = state.data.map((bucketItem) => {
                return bucketItem.id !== bucketItemId
                    ? bucketItem
                    : {
                          ...bucketItem,
                          pending: false,
                          error: true,
                      };
            });

            return {
                ...state,
                pending: false,
                data,
            };
        },
        [SEARCHABLE_BUCKETS_UPDATE_SEARCHABLE_BUCKETS]: (state, action) => {
            const bucketId = action.payload.id;

            return {
                ...state,
                data: state.data.map((bucket) => {
                    if (bucket.id !== bucketId) return bucket;
                    return { ...bucket, ...action.payload.updatedData };
                }),
            };
        },
        [SEARCHABLE_BUCKETS_PATCH_LOCK_PENDING]: (state, action) => {
            const bucketId = action.payload.id;

            return {
                ...state,
                bucketsLockPendingList: [...state.bucketsLockPendingList, bucketId],
            };
        },
        [SEARCHABLE_BUCKETS_PATCH_LOCK_COMPLATE]: (state, action) => {
            const bucketId = action.payload.id;

            return {
                ...state,
                bucketsLockPendingList: state.bucketsLockPendingList.filter((v) => v !== bucketId),
            };
        },
    },

    initialState,
);

//action
export const resetSearchableBuckets = createAction(SEARCHABLE_BUCKETS_RESET_SEARCHABLE_BUCKETS);
export const updateSearchableBuckets = createAction(SEARCHABLE_BUCKETS_UPDATE_SEARCHABLE_BUCKETS);

export const getListAllBuckets = () => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: SEARCHABLE_BUCKETS_GET_ALL_BUCKETS_PENDING });

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;

    return getListAllBucketsAPI(apiEndpoint, stageId)
        .then((response) => {
            dispatch({
                type: SEARCHABLE_BUCKETS_GET_LIST_ALL_BUCKETS_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: SEARCHABLE_BUCKETS_GET_ALL_BUCKETS_FAILURE,
                payload: error,
            });
        });
};

export const getSearchableBucketsForAdmin = () => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: SEARCHABLE_BUCKETS_PENDING });

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;

    return getSearchableBucketsForAdminAPI(apiEndpoint, stageId)
        .then((response) => {
            dispatch({
                type: SEARCHABLE_BUCKETS_GET_SEARCHABLE_BUCKETS_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: SEARCHABLE_BUCKETS_FAILURE,
                payload: error,
            });
        });
};

export const getSearchableBuckets = (projectId) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: SEARCHABLE_BUCKETS_PENDING });

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;

    return getSearchableBucketsAPI(apiEndpoint, stageId, projectId)
        .then((response) => {
            dispatch({
                type: SEARCHABLE_BUCKETS_GET_SEARCHABLE_BUCKETS_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: SEARCHABLE_BUCKETS_FAILURE,
                payload: error,
            });
        });
};

export const registerSearchableBuckets = (data) => (dispatch, getState) => {
    const { stage } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;

    return new Promise((resolve, reject) => {
        return patchBulkSearchableBucketsAPI(apiEndpoint, stageId, data)
            .then((response) => {
                dispatch({
                    type: SEARCHABLE_BUCKETS_GET_SEARCHABLE_BUCKETS_SUCCESS,
                    payload: response,
                });
                resolve(response && response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const patchSearchableBucketItem = (data) => (dispatch, getState) => {
    const { stage } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const bucketItemId = data.id;

    dispatch({
        type: SEARCHABLE_BUCKETS_PATCH_SEARCHABLE_BUCKETS_ITEM_PENDING,
        payload: {
            bucketItemId,
        },
    });

    return new Promise((resolve, reject) => {
        // if(data.bucketName === "cloudplex.ap-northeast-2.dev-tfstat") {
        //     data = {
        //         ...data,
        //         id: data.id + "error",
        //     }
        // }

        return patchSearchableBucketItemAPI(apiEndpoint, stageId, data)
            .then((response) => {
                dispatch({
                    type: SEARCHABLE_BUCKETS_PATCH_SEARCHABLE_BUCKETS_ITEM_SUCCESS,
                    payload: {
                        bucketItemId,
                        updatedBucketItem: response && response.data,
                    },
                });
                resolve(response && response.data);
            })
            .catch((error) => {
                dispatch({
                    type: SEARCHABLE_BUCKETS_PATCH_SEARCHABLE_BUCKETS_ITEM_FAILURE,
                    payload: {
                        bucketItemId,
                    },
                });
                reject(error);
            });
    });
};

export const getSearchableBucketsInSpace = () => (dispatch, getState) => {
    const { stage } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;

    return new Promise((resolve, reject) => {
        return getSearchableBucketsInSpaceAPI(apiEndpoint, stageId)
            .then((response) => {
                resolve(response && response.data);
            })
            .catch((error) => reject(error));
    });
};

export const patchBulkSearchableBucketsLock = (status, data) => (dispatch, getState) => {
    const { stage, project } = getState();

    const stageId = stage.id;
    const projectId = project.id;
    const apiEndpoint = stage.endpoint;

    dispatch({ type: SEARCHABLE_BUCKETS_PATCH_LOCK_PENDING, payload: { id: data.searchableBucketId } });

    return new Promise((resolve, reject) => {
        return patchBulkSearchableBucketsLockAPI(apiEndpoint, stageId, projectId, status, data)
            .then((response) => {
                resolve(response && response.data);
            })
            .catch((error) => reject(error))
            .finally(() =>
                dispatch({ type: SEARCHABLE_BUCKETS_PATCH_LOCK_COMPLATE, payload: { id: data.searchableBucketId } }),
            );
    });
};
