import { Box, Button } from "@mzc-pdc/ui";
import React from "react";
import theme from "@styles/theme";

const SquareIcon = ({ width, height, color, padding = 0, children }) => {
    return (
        <Box
            sx={{
                display: `inline-flex`,
                alignItems: `center`,
                justifyContent: `center`,
                width: width,
                height: height,
                padding: padding,
                borderRadius: theme.spacing(0.25),
                backgroundColor: color,
            }}
        >
            {children}
        </Box>
    );
};

const SquareButtonIcon = ({ width, height, color, children, onClick = () => {} }) => {
    return (
        <button>
            <Box
                sx={{
                    display: `inline-flex`,
                    alignItems: `center`,
                    justifyContent: `center`,
                    width: width,
                    height: height,
                    borderRadius: theme.spacing(0.25),
                    backgroundColor: color,
                }}
                onClick={onClick}
            >
                {children}
            </Box>
        </button>
    );
};

export { SquareIcon, SquareButtonIcon };
