import { Box, Grid, Typography } from "@mzc-pdc/ui";
import { StyledWarningAlert } from "@components_v2/alert/alert.styled";
import { IconWarningTriangle } from "@mzc-cloudplex/icons";
import React from "react";
import theme from "@styles/theme";

export const WarningMessage = ({ message }) => {
    return (
        <Box
            sx={{
                width: "100%",
                border: `1px solid ${theme.palette.warning[200]}`,
                ".MuiPaper-root": { maxWidth: "100%" },
            }}
        >
            <StyledWarningAlert icon={<IconWarningTriangle size={16} />} color="warning" variant="standard">
                <Typography variant={"body1_400"} component={"span"}>
                    {message && message}
                </Typography>
            </StyledWarningAlert>
        </Box>
    );
};
