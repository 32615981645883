import { handleActions, createAction } from "redux-actions";

import { axios, axiosWithoutHeaders } from "../cores/axiosWrapper";

import config from "../config/config";

import queryString from "../cores/queryString";

const RESET = "RESET";

const GET_FACE_COLLECTION_PENDING = "GET_FACE_COLLECTION_PENDING";
const GET_FACE_COLLECTION_SUCCESS = "GET_FACE_COLLECTION_SUCCESS";
const GET_FACE_COLLECTION_FAILURE = "GET_FACE_COLLECTION_FAILURE";

const INDEX_FACE_REQUESTED = "INDEX_FACE_REQUESTED";
const INDEX_FACE_FAILURE = "INDEX_FACE_FAILURE";
const INDEX_FACE_SUCCESS = "INDEX_FACE_SUCCESS";

const DELETE_FACE_REQUESTED = "DELETE_FACE_REQUESTED";
const DELETE_FACE_FAILURE = "DELETE_FACE_FAILURE";
const DELETE_FACE_SUCCESS = "DELETE_FACE_SUCCESS";

//reducer
const initialState = {
    pending: false,
    error: false,
    faceCollection: null,
    totalCount: 0,
    indexFacePending: false,
    deleteFacePending: false,
    pendingFaceIndexImages: [],
    pendingDeleteFaceImages: [],
};

export default handleActions(
    {
        [RESET]: (state, action) => {
            return {
                ...initialState,
            };
        },
        [GET_FACE_COLLECTION_PENDING]: (state) => {
            return {
                ...state,
                pending: true,
                error: false,
            };
        },
        [GET_FACE_COLLECTION_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const collection = data.faceCollections && data.faceCollections.length > 0 ? data.faceCollections[0] : null;
            return {
                ...state,
                pending: false,
                error: false,
                faceCollection: collection,
            };
        },
        [GET_FACE_COLLECTION_FAILURE]: (state) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [INDEX_FACE_REQUESTED]: (state, action) => {
            const pendingFaceIndexImages = [].concat(state.pendingFaceIndexImages);
            pendingFaceIndexImages.push(action.image);
            return {
                ...state,
                pending: false,
                error: false,
                pendingFaceIndexImages,
                indexFacePending: true,
            };
        },
        [INDEX_FACE_SUCCESS]: (state, action) => {
            const pendingFaceIndexImages = state.pendingFaceIndexImages.filter((image) => image.id !== action.image.id);
            return {
                ...state,
                pending: false,
                error: false,
                indexFacePending: pendingFaceIndexImages.length > 0,
                pendingFaceIndexImages,
            };
        },
        [INDEX_FACE_FAILURE]: (state, action) => {
            const pendingFaceIndexImages = state.pendingFaceIndexImages.filter((image) => image.id !== action.image.id);
            return {
                ...state,
                pending: false,
                error: true,
                indexFacePending: pendingFaceIndexImages.length > 0,
                pendingFaceIndexImages,
            };
        },
        [DELETE_FACE_REQUESTED]: (state, action) => {
            const pendingDeleteFaceImages = [].concat(state.pendingDeleteFaceImages);
            pendingDeleteFaceImages.push(action.image);
            return {
                ...state,
                pending: false,
                error: false,
                pendingDeleteFaceImages,
                deleteFacePending: true,
            };
        },
        [DELETE_FACE_SUCCESS]: (state, action) => {
            const pendingDeleteFaceImages = state.pendingDeleteFaceImages.filter(
                (image) => image.id !== action.image.id,
            );
            return {
                ...state,
                pending: false,
                error: false,
                deleteFacePending: pendingDeleteFaceImages.length > 0,
                pendingDeleteFaceImages,
            };
        },
        [DELETE_FACE_FAILURE]: (state, action) => {
            const pendingDeleteFaceImages = state.pendingDeleteFaceImages.filter(
                (image) => image.id !== (action.image && action.image.id),
            );
            return {
                ...state,
                pending: false,
                error: true,
                deleteFacePending: pendingDeleteFaceImages.length > 0,
                pendingDeleteFaceImages,
            };
        },
    },
    initialState,
);

function indexFaceAPI(stageId, apiEndpoint, projectId, collectionId, assetId, peopleId, image) {
    const queries = {
        assetId,
        peopleId,
        faceLimit: 1,
    };
    return axios.put(
        `${apiEndpoint}/analysis/face-collections/${collectionId}?${queryString.stringify(queries)}`,
        { ...image },
        {
            headers: {
                stageId,
                projectId,
            },
        },
    );
}

function deleteFaceApi(stageId, apiEndpoint, projectId, collectionId, faceIds) {
    return axios.delete(`${apiEndpoint}/analysis/face-collections/${collectionId}`, {
        headers: {
            stageId,
            projectId,
        },
        data: {
            faceIds: faceIds,
        },
    });
}

export function getFaceCollectionAPI(stageId, apiEndpoint, projectId, params) {
    return axios.get(`${apiEndpoint}/analysis/face-collections`, {
        params,
        headers: {
            stageId,
            projectId,
        },
    });
}

export const reset = createAction(RESET);
export const indexFaceAsync = (collectionId, assetId, peopleId, image) => (dispatch, getState) => {
    let { stage, project } = getState();
    dispatch({
        type: INDEX_FACE_REQUESTED,
        image: image,
    });
    return new Promise((resolve, reject) => {
        indexFaceAPI(stage.id, stage.endpoint, project.id, collectionId, assetId, peopleId, image)
            .then((response) => {
                dispatch({
                    type: INDEX_FACE_SUCCESS,
                    payload: response,
                    image: image,
                });
                resolve(response);
            })
            .catch((error) => {
                dispatch({
                    type: INDEX_FACE_FAILURE,
                    payload: error,
                    image: image,
                });
                reject(error);
            });
    });
};

export const deleteFaceAsync = (collectionId, image) => (dispatch, getState) => {
    let { stage, project } = getState();

    const faceIds = image.faceIndex.map((faceIndex) => faceIndex.faceId);
    if (faceIds.length <= 0) {
        return Promise.resolve();
    }

    dispatch({
        type: DELETE_FACE_REQUESTED,
        image,
    });

    return new Promise((resolve, reject) => {
        deleteFaceApi(stage.id, stage.endpoint, project.id, collectionId, faceIds)
            .then((response) => {
                dispatch({
                    type: DELETE_FACE_SUCCESS,
                    payload: response,
                    image,
                });
                resolve(response);
            })
            .catch((error) => {
                dispatch({
                    type: DELETE_FACE_FAILURE,
                    payload: error,
                    image,
                });
                reject(error);
            });
    });
};

export const getFaceCollection = () => (dispatch, getState) => {
    let { stage, project } = getState();

    dispatch({ type: GET_FACE_COLLECTION_PENDING });

    return getFaceCollectionAPI(stage.id, stage.endpoint, project.id)
        .then((response) => {
            dispatch({
                type: GET_FACE_COLLECTION_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_FACE_COLLECTION_FAILURE,
                payload: error,
            });
        });
};
