import { METADATA_FIELDS_TYPE, REFERENCE_FIELDS_TYPE } from "@constants";
import {
    IconVideo,
    IconImage,
    IconAudio,
    IconCaption,
    IconFile,
    IconCollection,
    IconContentsDefault,
    IconContentsMusic,
    IconContentsPeople,
    IconContentsPhoto,
    IconContentsVideo,
    IconCustomfieldDatepicker,
    IconCustomfieldDatepickerRange,
    IconCustomfieldNumber,
    IconCustomfieldSelect,
    IconCustomfieldSelectMulti,
    IconCustomfieldTextarea,
    IconCustomfieldTextfield,
    IconReferenceFields,
    IconMetadataFields,
    IconSetting,
    IconAssets,
} from "@mzc-cloudplex/icons";
import { Box } from "@mzc-pdc/ui";
import theme from "@styles/theme";

export const getMetadataIcon = (type) => {
    switch (type) {
        case METADATA_FIELDS_TYPE.TEXT_FIELD:
            return <IconCustomfieldTextfield size={16} sx={{ color: "#fff" }} />;
        case METADATA_FIELDS_TYPE.TEXT_AREA:
            return <IconCustomfieldTextarea size={16} sx={{ color: "#fff" }} />;
        case METADATA_FIELDS_TYPE.NUMBER:
            return <IconCustomfieldNumber size={16} sx={{ color: "#fff" }} />;
        case METADATA_FIELDS_TYPE.SINGLE_SELECT:
            return <IconCustomfieldSelect size={16} sx={{ color: "#fff" }} />;
        case METADATA_FIELDS_TYPE.MULTI_SELECT:
            return <IconCustomfieldSelectMulti size={16} sx={{ color: "#fff" }} />;
        case METADATA_FIELDS_TYPE.SINGLE_DATE_PICKER:
            return <IconCustomfieldDatepicker size={16} sx={{ color: "#fff" }} />;
        case METADATA_FIELDS_TYPE.RANGE_DATE_PICKER:
            return <IconCustomfieldDatepickerRange size={16} sx={{ color: "#fff" }} />;
        default:
            return null;
    }
};

export const getReferenceIcon = (type) => {
    switch (type) {
        case REFERENCE_FIELDS_TYPE.IMAGE_ASSET:
            return <IconImage size={16} sx={{ color: "#fff" }} />;
        case REFERENCE_FIELDS_TYPE.AUDIO_ASSET:
            return <IconAudio size={16} sx={{ color: "#fff" }} />;
        case REFERENCE_FIELDS_TYPE.VIDEO_ASSET:
            return <IconVideo size={16} sx={{ color: "#fff" }} />;
        case REFERENCE_FIELDS_TYPE.CAPTION_ASSET:
            return <IconCaption size={16} sx={{ color: "#fff" }} />;
        case REFERENCE_FIELDS_TYPE.FILE_ASSET:
            return <IconFile size={16} sx={{ color: "#fff" }} />;
        case REFERENCE_FIELDS_TYPE.VIDEO_CONTENT:
            return <IconContentsVideo size={16} sx={{ color: "#fff" }} />;
        case REFERENCE_FIELDS_TYPE.MUSIC_CONTENT:
            return <IconContentsMusic size={16} sx={{ color: "#fff" }} />;
        case REFERENCE_FIELDS_TYPE.PEOPLE_CONTENT:
            return <IconContentsPeople size={16} sx={{ color: "#fff" }} />;
        case REFERENCE_FIELDS_TYPE.PHOTO_CONTENT:
            return <IconContentsPhoto size={16} sx={{ color: "#fff" }} />;
        case REFERENCE_FIELDS_TYPE.CUSTOM_CONTENT:
            return <IconContentsDefault size={16} sx={{ color: "#fff" }} />;
        case REFERENCE_FIELDS_TYPE.COLLECTION:
            return <IconCollection size={16} sx={{ color: "#fff" }} />;
        // multi

        case REFERENCE_FIELDS_TYPE.ASSETS:
            return <IconAssets size={16} sx={{ color: "#fff" }} />;
        case REFERENCE_FIELDS_TYPE.IMAGE_ASSETS:
            return <IconImage size={16} sx={{ color: "#fff" }} />;
        case REFERENCE_FIELDS_TYPE.AUDIO_ASSETS:
            return <IconAudio size={16} sx={{ color: "#fff" }} />;
        case REFERENCE_FIELDS_TYPE.VIDEO_ASSETS:
            return <IconVideo size={16} sx={{ color: "#fff" }} />;
        case REFERENCE_FIELDS_TYPE.CAPTION_ASSETS:
            return <IconCaption size={16} sx={{ color: "#fff" }} />;
        case REFERENCE_FIELDS_TYPE.FILE_ASSETS:
            return <IconFile size={16} sx={{ color: "#fff" }} />;
        case REFERENCE_FIELDS_TYPE.VIDEO_CONTENTS:
            return <IconContentsVideo size={16} sx={{ color: "#fff" }} />;
        case REFERENCE_FIELDS_TYPE.MUSIC_CONTENTS:
            return <IconContentsMusic size={16} sx={{ color: "#fff" }} />;
        case REFERENCE_FIELDS_TYPE.PEOPLE_CONTENTS:
            return <IconContentsPeople size={16} sx={{ color: "#fff" }} />;
        case REFERENCE_FIELDS_TYPE.PHOTO_CONTENTS:
            return <IconContentsPhoto size={16} sx={{ color: "#fff" }} />;
        case REFERENCE_FIELDS_TYPE.CUSTOM_CONTENTS:
            return <IconContentsDefault size={16} sx={{ color: "#fff" }} />;
        case REFERENCE_FIELDS_TYPE.COLLECTIONS:
            return <IconCollection size={16} sx={{ color: "#fff" }} />;
        default:
            return null;
    }
};

export const getMetadataTooltipTitle = (type) => {
    switch (type) {
        case METADATA_FIELDS_TYPE.TEXT_FIELD:
            return "Text field";
        case METADATA_FIELDS_TYPE.TEXT_AREA:
            return "Text area";
        case METADATA_FIELDS_TYPE.NUMBER:
            return "Single date picker";
        case METADATA_FIELDS_TYPE.SINGLE_SELECT:
            return "Single select";
        case METADATA_FIELDS_TYPE.MULTI_SELECT:
            return "Multiple select";
        case METADATA_FIELDS_TYPE.SINGLE_DATE_PICKER:
            return "Single date picker";
        case METADATA_FIELDS_TYPE.RANGE_DATE_PICKER:
            return "Range date picker";
        default:
            return null;
    }
};

export const getReferenceTooltipTitle = (type) => {
    switch (type) {
        case REFERENCE_FIELDS_TYPE.IMAGE_ASSET:
            return "Image asset";
        case REFERENCE_FIELDS_TYPE.AUDIO_ASSET:
            return "Audio asset";
        case REFERENCE_FIELDS_TYPE.VIDEO_ASSET:
            return "Video asset";
        case REFERENCE_FIELDS_TYPE.CAPTION_ASSET:
            return "Caption asset";
        case REFERENCE_FIELDS_TYPE.FILE_ASSET:
            return "File asset";
        case REFERENCE_FIELDS_TYPE.VIDEO_CONTENT:
            return "Video content";
        case REFERENCE_FIELDS_TYPE.MUSIC_CONTENT:
            return "Music content";
        case REFERENCE_FIELDS_TYPE.PEOPLE_CONTENT:
            return "People content";
        case REFERENCE_FIELDS_TYPE.PHOTO_CONTENT:
            return "Photo content";
        case REFERENCE_FIELDS_TYPE.CUSTOM_CONTENT:
            return "Custom content";
        case REFERENCE_FIELDS_TYPE.COLLECTION:
            return "Collection";
        // multi
        case REFERENCE_FIELDS_TYPE.ASSETS:
            return "Assets";
        case REFERENCE_FIELDS_TYPE.IMAGE_ASSETS:
            return "Image assets";
        case REFERENCE_FIELDS_TYPE.AUDIO_ASSETS:
            return "Audio assets";
        case REFERENCE_FIELDS_TYPE.VIDEO_ASSETS:
            return "Video assets";
        case REFERENCE_FIELDS_TYPE.CAPTION_ASSETS:
            return "Caption assets";
        case REFERENCE_FIELDS_TYPE.FILE_ASSETS:
            return "File assets";
        case REFERENCE_FIELDS_TYPE.VIDEO_CONTENTS:
            return "Video contents";
        case REFERENCE_FIELDS_TYPE.MUSIC_CONTENTS:
            return "Music contents";
        case REFERENCE_FIELDS_TYPE.PEOPLE_CONTENTS:
            return "People contents";
        case REFERENCE_FIELDS_TYPE.PHOTO_CONTENTS:
            return "Photo contents";
        case REFERENCE_FIELDS_TYPE.CUSTOM_CONTENTS:
            return "Custom contents";
        case REFERENCE_FIELDS_TYPE.COLLECTIONS:
            return "Collections";
        default:
            return null;
    }
};

export const getMetadataOrReferenceIcon = (isReference) => {
    return (
        <Box
            width={20}
            height={20}
            sx={{
                borderRadius: "50%",
                display: `flex`,
                alignItems: `center`,
                justifyContent: `center`,
                backgroundColor: isReference ? theme.palette.success[500] : theme.palette.primary[300],
            }}
        >
            {isReference ? (
                <IconReferenceFields size={10} sx={{ color: "#fff" }} />
            ) : (
                <IconMetadataFields size={10} sx={{ color: "#fff" }} />
            )}
        </Box>
    );
};

export const getSystemManagedIcon = () => {
    return (
        <Box
            width={16}
            height={16}
            sx={{
                borderRadius: "50%",
                display: `flex`,
                alignItems: `center`,
                justifyContent: `center`,
                backgroundColor: theme.palette.secondary[800],
            }}
        >
            <IconSetting size={10} htmlColor={"#fff"} />
        </Box>
    );
};
