import React, { forwardRef, useEffect, useState } from "react";

import { Grid, Typography, useMediaQuery } from "@mui/material";

/* styled */
import {
    StyledTreeViewContainer,
    StyledTreeViewWrap,
    StyledTreeViewContent,
    StyledTreeViewFoldButton,
    StyledTreeItemRoot,
    StyledTreeView,
} from "./styled";
import clsx from "clsx";
import {
    IconChevronLeft,
    IconChevronRight,
    IconHierarchyFolded,
    IconHierarchyOpened,
    IconMoreHoriz,
    IconRefresh,
} from "@mzc-cloudplex/icons";
import { useTreeItem } from "@mui/lab";
import { IconButton, Tooltip, TextField } from "@mzc-pdc/ui";
import { Menu } from "@components_v2/menu";
import { useTheme } from "@mui/styles";

const CustomContent = React.forwardRef(function CustomContent(props, ref) {
    const {
        classes,
        className,
        label,
        nodeId,
        icon: iconProp,
        expansionIcon,
        displayIcon,
        depth,
        menuList,
        isOverCurrent,
        foldDisabled,
        onClick,
        onClickRefresh,
        onClickExpand,
        onChangeField,
        onSubmitField,
    } = props;
    const { disabled, expanded, selected } = useTreeItem(nodeId);
    const [clicked, setClicked] = useState(false);
    const [folderName, setFolderName] = useState("");

    const icon = iconProp;

    const handleExpansionClick = (event) => {
        event.stopPropagation();
        // handleExpansion(event);
        console.log(event);
        if (onClickExpand) onClickExpand();
    };

    const handleSelectionClick = (event) => {
        event.stopPropagation();
        //if (!expanded) handleExpansion(event);
        //if (!selected) handleSelection(event);
        if (onClick) onClick(event);
    };

    return (
        <div
            className={clsx(className, classes.root, {
                [classes.expanded]: expanded,
                [classes.selected]: selected,
                clicked,
                [classes.disabled]: disabled,
                overCurrent: isOverCurrent,
            })}
            onMouseDown={(e) => {
                e.stopPropagation();
            }}
            onClick={handleSelectionClick}
            ref={ref}
        >
            {Array.from(Array(depth), () => (
                <span className={"spacing"} />
            ))}
            {!foldDisabled && (
                <span onClick={handleExpansionClick} className={classes.iconContainer}>
                    {expanded ? (
                        <IconHierarchyOpened size={12} htmlColor={"#6f6f83"} />
                    ) : (
                        <IconHierarchyFolded size={12} htmlColor={"#6f6f83"} />
                    )}
                </span>
            )}
            {icon && <span className={classes.iconContainer}>{icon}</span>}
            {label ? (
                <Typography component="span" className={classes.label}>
                    {label}
                </Typography>
            ) : (
                <TextField
                    autoFocus
                    size={"small"}
                    value={folderName}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => {
                        e.stopPropagation();
                        setFolderName(e.target.value);
                    }}
                    onKeyUp={(e) => {
                        e.stopPropagation();
                        if ((e.code === "Enter" || e.code === "NumpadEnter") && folderName && onSubmitField) {
                            onSubmitField(folderName);
                        }
                    }}
                    onBlur={(e) => {
                        e.stopPropagation();
                        if (onSubmitField) onSubmitField(folderName);
                    }}
                />
            )}
            {menuList && (
                <Grid
                    item
                    onClick={(e) => {
                        e.stopPropagation();
                        const target = e.target;
                        if (target.classList.contains("MuiSvgIcon-root")) setClicked(true);
                        else setClicked(false);
                    }}
                >
                    <Menu
                        menuWidth={160}
                        placement={"right"}
                        type={"icon"}
                        label={<IconMoreHoriz size={16} />}
                        color={"inherit"}
                        size={"small"}
                        listSize={"small"}
                        list={menuList}
                        preventPropagation={false}
                    />
                </Grid>
            )}
            {onClickRefresh && (
                <IconButton
                    size={"small"}
                    circled
                    onClick={(e) => {
                        e.stopPropagation();
                        if (onClickRefresh) onClickRefresh();
                    }}
                >
                    <IconRefresh size={16} color={"greySecondary"} />
                </IconButton>
            )}
        </div>
    );
});

export const TreeItem = forwardRef((props, ref) => {
    return (
        <StyledTreeItemRoot
            {...props}
            ContentComponent={CustomContent}
            ContentProps={{
                depth: props.depth,
                menuList: props.menuList,
                foldDisabled: props.foldDisabled,
                isOverCurrent: props.isOverCurrent,
                onClick: props.onClick,
                onClickRefresh: props.onClickRefresh,
                onClickExpand: props.onClickExpand,
                onSubmitField: props.onSubmitField,
            }}
            ref={ref}
        />
    );
});

export const TreeView = (props) => {
    // const renderTree = (nodes) => (
    //   <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name}>
    //     {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
    //   </TreeItem>
    // );

    return (
        <StyledTreeView
            defaultExpanded={props.expanded}
            defaultCollapseIcon={<IconHierarchyFolded size={12} />}
            defaultExpandIcon={<IconHierarchyOpened size={12} />}
            {...props}
        >
            {/*{props.data?.map((treeItem) => (*/}
            {/*  <TreeItem key={treeItem.id} nodeId={treeItem.id} label={treeItem.name}>*/}
            {/*    {Array.isArray(treeItem.children) ? treeItem.children.map((node) => renderTree(node)) : null}*/}
            {/*  </TreeItem>*/}
            {/*))}*/}
        </StyledTreeView>
    );
};

export const TreeViewWrap = (props) => {
    const theme = useTheme();
    const [isFold, setIsFold] = useState(false);
    const isDown1024 = useMediaQuery(theme.breakpoints.down("lg"));
    const isUp1024 = useMediaQuery(theme.breakpoints.up("lg"));

    useEffect(() => {
        if (isDown1024) {
            return setIsFold(true);
        }
    }, [isDown1024]);

    useEffect(() => {
        if (isUp1024) {
            return setIsFold(false);
        }
    }, [isUp1024]);

    return (
        <StyledTreeViewContainer>
            <StyledTreeViewWrap fold={props.fold} isFold={isFold}>
                <StyledTreeViewContent>{props.children}</StyledTreeViewContent>
                {props.fold && (
                    <StyledTreeViewFoldButton isFold={isFold}>
                        <Tooltip
                            title={props.lang === "en" ? (isFold ? "Expand" : "Fold") : isFold ? "펼침" : "접힘"}
                            placement={"right"}
                        >
                            <button onClick={() => setIsFold(!isFold)}>
                                {isFold ? <IconChevronRight size={12} /> : <IconChevronLeft size={12} />}
                            </button>
                        </Tooltip>
                    </StyledTreeViewFoldButton>
                )}
            </StyledTreeViewWrap>
        </StyledTreeViewContainer>
    );
};
