import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";

const TableCell = (props) => {
    const { component, className, ...otherProps } = props;
    const Component = component;

    return <Component {...otherProps} className={clsx(className)} />;
};
TableCell.propTypes = {
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    className: PropTypes.string,
};
TableCell.defaultProps = {
    component: "td",
};

export default TableCell;
