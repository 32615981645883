import { handleActions, createAction } from "redux-actions";
import { axios, axiosWithoutHeaders } from "../cores/axiosWrapper";

function getTimezonesAPI(stageId, apiEndpoint, params) {
    const headers = {
        stageId,
    };
    return axios.get(`${apiEndpoint}/timezones`, {
        headers,
    });
}

const TIMEZONES_PENDING = "TIMEZONES_PENDING";
const TIMEZONES_FAILURE = "TIMEZONES_FAILURE";
const GET_TIMEZONES_SUCCESS = "GET_TIMEZONES_SUCCESS";

const initialState = {
    pending: false,
    error: false,
    data: null,
};

export default handleActions(
    {
        [TIMEZONES_PENDING]: (state) => {
            return {
                ...state,
                pending: true,
                error: false,
            };
        },
        [TIMEZONES_FAILURE]: (state) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [GET_TIMEZONES_SUCCESS]: (state, action) => {
            let { data } = action.payload;

            const timezones = {};

            const peninsulas = Object.keys(data);
            const peninsulasLength = peninsulas.length;

            for (let i = 0; i < peninsulasLength; i++) {
                const peninsula = peninsulas[i];
                timezones[peninsula] = data[peninsula].sort(sortOffset);
            }

            return {
                ...state,
                pending: false,
                error: false,
                data: timezones,
            };

            function sortOffset(a, b) {
                return a.offset < b.offset ? -1 : a.offset > b.offset ? 1 : a.name.localeCompare(b.name);
            }
        },
    },
    initialState,
);

export const getTimezones =
    ({ stageId, apiEndpoint, params }) =>
    (dispatch) => {
        dispatch({ type: TIMEZONES_PENDING });

        return getTimezonesAPI(stageId, apiEndpoint, params)
            .then((response) => {
                dispatch({
                    type: GET_TIMEZONES_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: TIMEZONES_FAILURE,
                });
            });
    };
