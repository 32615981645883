import { axios } from "../../cores/axiosWrapper";

const CategoryGroupApi = {
    getCategoryGroupAPI
};

export function getCategoryGroupAPI(stageId, apiEndpoint, projectId, categoryGroupId) {
    const response = axios.get(
        `${apiEndpoint}/category-groups/${categoryGroupId}`,
        {
            headers: {
                stageId,
                projectId,
            }
        }
    );
    return response;
}

export default CategoryGroupApi;
