import React, { useCallback } from "react";
import TableCard from "@components_v2/card/table-card";
import AssetThumbnail from "@features/thumbnail/asset-thumbnail";
import { Badge, Grid, Typography } from "@mzc-pdc/ui";
import convertToByte from "@cores/convertToByte";
import StatusBadge from "@components_v2/status-badge/status-badge";
import formatDuration from "@cores/formatDuration";
import CollectionThumbnail from "@features/thumbnail/collection-thumbnail";
import theme from "@styles/theme";
import AssetTypeFlag from "@components_v2/icon-type/asset/asset-type-flag";
import ContentThumbnail from "@features/thumbnail/content-thumbnail";
import ContentTypeFlag from "@components_v2/icon-type/content/content-type-flag";
import { MEDIA_TYPES } from "@constants";

export const getTableValueByAssetColumn = (columnId, option) => {
    switch (columnId) {
        case "desiredStatus":
            return (
                <Grid container justifyContent={"center"}>
                    <StatusBadge status={option?.status} />
                </Grid>
            );
        case "name":
            let src = option.previews?.[0]?.accessUrl;
            if (!src) {
                if (option?.mediaType === MEDIA_TYPES.IMAGE) {
                    src = option.accessUrls?.[0] ?? option.elements?.[0]?.accessUrl;
                } else if (option.mediaType === MEDIA_TYPES.VIDEO) src = option.thumbnails?.[0]?.accessUrl;
            }

            return (
                <TableCard
                    thumbnail={
                        <AssetThumbnail
                            variant={"table"}
                            src={src}
                            width={52}
                            height={52}
                            mediaType={option.mediaType}
                        />
                    }
                    name={{
                        name: (
                            <Typography variant={"body1"} component={"span"}>
                                {option?.name ?? "-"}
                            </Typography>
                        ),
                        startIcon: <AssetTypeFlag width={22} height={16} type={option.mediaType} />,
                    }}
                    subName={{
                        name:
                            !option?.mediaType || ["IMAGE", "CAPTION"].includes(option?.mediaType)
                                ? null
                                : `.${option?.extensions ?? "-"} `,
                    }}
                />
            );
        case "type":
            return <Grid item>{option?.mediaType ?? "-"}</Grid>;
        case "size":
            return <Grid item>{option?.size ? convertToByte(option?.size) : "-"}</Grid>;
        case "duration":
            return <Grid item>{formatDuration(option?.duration)}</Grid>;
        default:
            return option?.[columnId];
    }
};

export const getTableValueByContentColumn = (columnId, option) => {
    switch (columnId) {
        case "desiredStatus":
            return (
                <Grid container justifyContent={"center"}>
                    <StatusBadge status={option?.status?.toUpperCase()} />
                </Grid>
            );
        case "name":
            return (
                <TableCard
                    thumbnail={
                        <ContentThumbnail
                            variant={"table"}
                            src={option?.primaryPoster?.accessUrl ?? option?.primaryImage?.accessUrl}
                            minWidth={40}
                            width={40}
                            height={40}
                            contentType={option.contentType}
                        />
                    }
                    name={{
                        name: (
                            <Typography variant={"body1"} component={"span"}>
                                {option?.name}
                            </Typography>
                        ),
                        startIcon: <ContentTypeFlag minWidth={22} width={22} height={16} type={option.contentType} />,
                    }}
                />
            );
        case "type":
            return <Grid item>{option?.mediaType}</Grid>;
        case "size":
            return <Grid item>{option?.size ? convertToByte(option?.size) : "-"}</Grid>;
        case "duration":
            return <Grid item>{formatDuration(option?.duration)}</Grid>;
        default:
            return option?.[columnId];
    }
};

export const getTableValueByCollectionColumn = (columnId, option) => {
    if (columnId === "name")
        return (
            <TableCard
                thumbnail={<CollectionThumbnail collection={option} size={"small"} variant={"table"} />}
                name={{
                    name: (
                        <Typography variant={"body1_500"} component={"span"}>
                            {option?.name}
                        </Typography>
                    ),
                }}
            />
        );
    if (columnId === "count")
        return (
            <Grid item>
                <Badge
                    sx={{
                        ".MuiBadge-badge": {
                            position: `static`,
                            minWidth: "30px",
                            height: 18,
                            transform: `none`,
                            backgroundColor: option?.assetTotalCount
                                ? theme.palette.success[600]
                                : theme.palette.secondary[500],
                            color: theme.palette.common.white,
                            fontSize: theme.typography.body3_700,
                        },
                    }}
                    badgeContent={option?.assetTotalCount}
                />
            </Grid>
        );
    return null;
};
