import { handleActions, createAction } from "redux-actions";

import { sessionKeeper } from "../cores/sessionKeeper";

const SET_STAGE = "SET_STAGE";
const STAGE_PENDING = "STAGE_PENDING";
const STAGE_FAILURE = "STAGE_FAILURE";
const STAGE_RESET = "STAGE_RESET";
//reducer
const initialState = {
    pending: false,
    error: false,
    id: "",
    name: "",
    endpoint: "",
    extensions: null,
    data: null,
};

export default handleActions(
    {
        [STAGE_RESET]: (state) => {
            return {
                ...initialState,
            };
        },
        [STAGE_PENDING]: (state) => {
            return {
                ...state,
                pending: true,
                error: false,
            };
        },
        [STAGE_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [SET_STAGE]: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
    },
    initialState,
);

export const setStage = (newStage) => (dispatch, getState) => {
    sessionKeeper.set({ stage: newStage });
    dispatch({
        type: SET_STAGE,
        payload: newStage,
    });
};

export const reset = createAction(STAGE_RESET);
