import React from 'react';

const LayoutForError = ({children}) => {
    return (
        <div className={"container-scroller error"}>
            <div className={"container-fluid page-body-wrapper full-page-wrapper"}>
                {children}
            </div>
        </div>
    );
};

export default LayoutForError;