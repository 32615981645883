import PropTypes from "prop-types";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { HeaderForLayout, HeaderForAdmin } from "@components/header";
import Navigator from "@components/navigator";
import Notification from "@components/notification";
import Loader from "@components/loader";
import PropertyPanel from "@components/property-panel";
import Footer from "@components/footer";
import RightSideBar from "@routes/rightsidebar";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import clsx from "clsx";
import React, { useMemo } from "react";
import { Grid, Toolbar } from "@mzc-pdc/ui";
import { Documents, Container, Main } from "@components_v2/layout/layout.styled";
import NavigatorAdmin from "@components/navigator/NavigatorAdmin";

const LayoutForAdminV2 = ({ children }) => {
    const globalLoading = useSelector((state) => state.global.loading);
    const stageId = useSelector((state) => state.stage.id);
    const isSidebarPushOpened = useSelector((state) => state.propertyPanel.isShow);
    const isSidebarCoverOpened = useSelector((state) => state.rightSideBar.active);
    const history = useHistory();
    const match = useRouteMatch();
    const location = useLocation();

    return (
        <DndProvider backend={HTML5Backend}>
            <Documents
                className={clsx({
                    "property-panel panel-push-open": isSidebarPushOpened,
                    "panel-cover-open": isSidebarCoverOpened,
                })}
            >
                {location.pathname.indexOf("/admin/spaces") !== -1 ? (
                    <HeaderForAdmin location={location} />
                ) : (
                    <HeaderForLayout location={location} />
                )}

                {stageId && <NavigatorAdmin location={location} match={match} history={history} />}

                <Container>
                    <Toolbar />
                    <Main>{children}</Main>
                    <Footer />
                </Container>

                {stageId && (
                    <>
                        <PropertyPanel location={location} />
                        <RightSideBar location={location} />
                    </>
                )}
                {globalLoading && <Loader />}
                <Notification />
            </Documents>
        </DndProvider>
    );
};

LayoutForAdminV2.propTypes = {
    children: PropTypes.element,
};

export default LayoutForAdminV2;
