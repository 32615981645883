import React, { useCallback } from "react";
import { alpha } from "@mui/material";
import theme from "@styles/theme";
import {
    Checkbox,
    Grid,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    ListItemIcon,
    Popper,
    Divider,
    Button,
    ClickAwayListener,
} from "@mzc-pdc/ui";
import { GroupTitleWithIcon } from "@features/comments/groups/groups.styled";
import { IconAdd } from "@mzc-cloudplex/icons";
import { useTranslation } from "react-i18next";

const PopperPrivateThreads = ({
    data,
    values,
    anchorEl,
    placement,
    open,
    width,
    noDataComments,
    onChange,
    onClose,
    onClickActionButton,
}) => {
    const { t } = useTranslation();

    const isChecked = useCallback(
        (id) => {
            return values?.find((el) => el === id) !== undefined;
        },
        [values],
    );

    return (
        <Popper
            id={"autocomplete-table-popper"}
            disablePortal={true}
            anchorEl={anchorEl}
            placement={placement}
            open={open}
            sx={{ width, maxHeight: "500px", backgroundColor: "#fff", zIndex: 900 }}
        >
            <ClickAwayListener
                mouseEvent={open ? "onClick" : false}
                onClickAway={() => {
                    onClose();
                }}
            >
                <List
                    sx={{
                        border: `1px solid ${theme.palette.greySecondary[100]}`,
                        pt: 1,
                        overflowY: "auto",
                        boxShadow: `2px 0px 6px 0px rgba(0, 0, 0, 0.20)`,
                    }}
                >
                    {noDataComments && data?.length === 0 && noDataComments}
                    {data?.map((item, index) => (
                        <React.Fragment key={`autocomplete-table-list-${item.id}`}>
                            <ListItem
                                sx={{
                                    padding: `0`,
                                    height: "48px",
                                    "&:hover": {
                                        backgroundColor: alpha(theme.palette.greySecondary[50], 0.24),
                                    },
                                    ...(isChecked(item.id) && {
                                        backgroundColor: `${alpha(theme.palette.greySecondary.A100, 0.36)} !important`,
                                    }),
                                }}
                                data-id={item.id}
                                onClick={() => {
                                    onChange(item.id);
                                }}
                            >
                                <ListItemButton sx={{ height: "100%" }}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge={"start"}
                                            color={"greySecondary"}
                                            checked={isChecked(item.id)}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onChange(item.id);
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Grid container alignItems={"center"} direction="row" flexWrap={"nowrap"}>
                                                <GroupTitleWithIcon
                                                    title={item.name}
                                                    iconSize={20}
                                                    isDisabled={!isChecked(item.id)}
                                                />
                                            </Grid>
                                        }
                                    />
                                </ListItemButton>
                            </ListItem>
                            {/*TODO: resourceData 목록 더 불러오기*/}
                            {/*{data && <div ref={targetRef} id={"loadMoreDiv"} />}*/}
                        </React.Fragment>
                    ))}
                    <Divider orientation={"horizontal"} flexItem color={theme.palette.greySecondary[100]} />
                    <ListItem sx={{ pt: 1.5, pb: 0.5, px: theme.spacing(1.5) }}>
                        <Button
                            fullWidth
                            color={"warning"}
                            startIcon={<IconAdd size={20} />}
                            variant={"contained"}
                            onClick={onClickActionButton}
                        >
                            {t(`common::label::Create a private comment`, "Create a private comment")}
                        </Button>
                    </ListItem>
                </List>
            </ClickAwayListener>
        </Popper>
    );
};

export default PopperPrivateThreads;
