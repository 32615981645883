import React, { useMemo } from "react";
import { Box, FormHelperText, Grid, InputAdornment, TextField as MuiTextField, Typography } from "@mzc-pdc/ui";
import { IconErrorCircle, IconSearch, IconWarningTriangle } from "@mzc-cloudplex/icons";
import theme from "@styles/theme";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

const TextField = React.forwardRef(
    (
        {
            type,
            className,
            id,
            size,
            placeholder,
            error,
            helperText,
            multiline,
            bytes,
            maxByte = 255,
            sx,
            inputProps,
            value,
            label,
            defaultValue,
            disabled,
            onChange,
            onKeyDown,
            maxRows,
            inputStyle,
            isEdit,
            variant,
            ...rest
        },
        ref,
    ) => {
        const { t } = useTranslation();

        const errorIcon = useMemo(() => {
            if (error === "warning") return <IconWarningTriangle color={"warning"} size={16} />;
            else if (error === "error") return <IconErrorCircle color={`error`} size={16} />;
            return undefined;
        }, [error]);

        const getEndAdornment = useMemo(() => {
            if (multiline) return undefined;
            return errorIcon;
        }, [error]);

        return (
            <Box
                sx={{
                    ...sx,
                    position: `relative`,
                }}
            >
                <MuiTextField
                    className={className}
                    variant={variant}
                    inputRef={ref}
                    type={type}
                    id={id}
                    label={label ? label : null}
                    size={size}
                    fullWidth
                    placeholder={placeholder}
                    error={error === "error"}
                    helperText={
                        error &&
                        helperText && (
                            <Grid container gap={1} alignItems={"center"}>
                                {/*{getEndAdornment}*/}
                                {helperText}
                            </Grid>
                        )
                    }
                    multiline={multiline}
                    defaultValue={defaultValue}
                    value={value}
                    disabled={disabled}
                    InputProps={{
                        ...inputProps,
                        ...{
                            endAdornment: getEndAdornment,
                        },
                    }}
                    InputLabelProps={{ shrink: true }}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    {...rest}
                    sx={{
                        "&.MuiInputBase-root": {
                            minHeight: "50px", // 원하는 최소 높이로 설정
                        },
                        ...(isEdit && {
                            "& .MuiOutlinedInput-root": {
                                backgroundColor: `#fff`,
                                borderRadius: "2px",
                                "> fieldset": { borderColor: theme.palette.primary[400] },
                            },
                        }),
                    }}
                />
                {multiline && bytes >= 0 ? (
                    <FormHelperText sx={{ mr: "14px" }}>
                        <Grid
                            component="span"
                            container
                            gap={0.5}
                            alignItems="center"
                            color={error ? theme.palette.error.main : null}
                        >
                            {error && errorIcon}
                            {bytes} / {maxByte}byte
                        </Grid>
                    </FormHelperText>
                ) : null}
            </Box>
        );
    },
);

export default TextField;
