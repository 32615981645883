export const OPEN_MENU = 'OPEN_MENU';
export const CLOSE_MENU = 'CLOSE_MENU';
export const SET_MENU = 'SET_MENU';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const OPEN_NAVIGATOR = 'OPEN_NAVIGATOR';
export const CLOSE_NAVIGATOR = 'CLOSE_NAVIGATOR';
export const FOLD_MENU = 'FOLD_MENU';

export function openMenu(id) {
    return {
        type: OPEN_MENU,
        id
    };
}

export function closeMenu(id) {
    return {
        type: CLOSE_MENU,
        id
    };
}

export function setMenu (obj) {
    return {
        type : SET_MENU,
        ...obj
    }
}

export function toggleMenu(id) {
    return {
        type: TOGGLE_MENU,
        id
    };
}

export function foldMenu(id) {
    return {
        type: FOLD_MENU,
        id
    };
}

export function openNavigator() {
    document.documentElement.classList.remove('nav-fold');
    return {
        type: OPEN_NAVIGATOR
    };
}

export function closeNavigator() {
    document.documentElement.classList.add('nav-fold');
    return {
        type: CLOSE_NAVIGATOR
    };
}
