import { handleActions, createAction } from "redux-actions";
import { axios, axiosWithoutHeaders } from "../cores/axiosWrapper";

function patchProjectTeamAPI(stageId, apiEndpoint, projectId, teamId, body) {
    const headers = {
        stageId,
        projectId,
    };

    return axios.patch(`${apiEndpoint}/projects/${projectId}/teams/${teamId}`, body, {
        headers,
    });
}

function getTeamsAPI(stageId, apiEndpoint, projectId, params) {
    const headers = {
        stageId,
    };

    const queries = {
        offset: params.offset * params.limit,
        limit: params.limit,
    };

    if (projectId) {
        headers.projectId = projectId;
    }

    if (params.keyword) {
        queries[params.keywordType] = params.keyword;
    }

    return axios.get(`${apiEndpoint}/teams`, {
        params: queries,
        headers,
    });
}

function getTeamAPI(stageId, apiEndpoint, projectId, id) {
    const headers = {
        stageId,
    };

    if (projectId) {
        headers.projectId = projectId;
    }

    return axios.get(`${apiEndpoint}/teams/${id}`, {
        headers,
    });
}

function getTeamMembersAPI(stageId, apiEndpoint, projectId, id, params) {
    const headers = {
        stageId,
    };

    const queries = {};

    if (projectId) {
        headers.projectId = projectId;
    }

    if (params.keyword) {
        queries[params.keywordType] = params.keyword;
    }

    return axios.get(`${apiEndpoint}/teams/${id}/members`, {
        params: queries,
        headers,
    });
}

function getTeamRolesAPI(stageId, apiEndpoint, projectId, id, params) {
    const headers = {
        stageId,
    };

    const queries = {};

    if (projectId) {
        headers.projectId = projectId;
    }

    if (params.keyword) {
        queries[params.keywordType] = params.keyword;
    }

    return axios.get(`${apiEndpoint}/teams/${id}/roles`, {
        params: queries,
        headers,
    });
}

function putTeamRoleAPI(stageId, apiEndpoint, projectId, id, roleId) {
    const headers = {
        stageId,
    };

    if (projectId) {
        headers.projectId = projectId;
    }
    return axios.put(`${apiEndpoint}/teams/${id}/roles/${roleId}`, null, {
        headers,
    });
}

function deleteTeamRoleAPI(stageId, apiEndpoint, id, roleId) {
    return axios.delete(`${apiEndpoint}/teams/${id}/roles/${roleId}`, {
        headers: {
            stageId,
        },
    });
}

const TEAM_MANAGEMENT_PENDING = "TEAM_MANAGEMENT_PENDING";
const TEAM_MANAGEMENT_FAILURE = "TEAM_MANAGEMENT_FAILURE";

const TEAM_MANAGEMENT_GET_TEAMS_SUCCESS = "TEAM_MANAGEMENT_GET_TEAMS_SUCCESS";
const TEAM_MANAGEMENT_UPDATE_TEAMS_PARAMS = "TEAM_MANAGEMENT_UPDATE_TEAMS_PARAMS";
const TEAM_MANAGEMENT_RESET_TEAMS_PARAMS_RESET = "TEAM_MANAGEMENT_RESET_TEAMS_PARAMS_RESET";
const TEAM_MANAGEMENT_INIT_TEAMS = "TEAM_MANAGEMENT_INIT_TEAMS";

const TEAM_MANAGEMENT_GET_TEAM_ROLES_SUCCESS = "TEAM_MANAGEMENT_GET_TEAM_ROLES_SUCCESS";
const TEAM_MANAGEMENT_GET_TEAM_SUCCESS = "TEAM_MANAGEMENT_GET_TEAM_SUCCESS";

const TEAM_MANAGEMENT_GET_TEAM_MEMBERS_SUCCESS = "TEAM_MANAGEMENT_GET_TEAM_MEMBERS_SUCCESS";
const TEAM_MANAGEMENT_UPDATE_ROLES_PARAMS = "TEAM_MANAGEMENT_UPDATE_ROLES_PARAMS";
const TEAM_MANAGEMENT_UPDATE_MEMBERS_PARAMS = "TEAM_MANAGEMENT_UPDATE_MEMBERS_PARAMS";

const TEAM_MANAGEMENT_UPDATE_PROJECT_TEAM_SUCCESS = "TEAM_MANAGEMENT_UPDATE_PROJECT_TEAM_SUCCESS";

const initialState = {
    pending: false,
    error: false,
    params: {
        keywordType: "name",
        keyword: "",
        offset: 0,
        limit: 50,
    },
    totalCount: 0,
    teams: [],
    team: null,
    members: {
        totalCount: 0,
        results: [],
        params: {
            keywordType: "name",
            keyword: "",
            offset: 0,
            limit: 50,
        },
    },
    roles: {
        totalCount: 0,
        results: [],
        params: {
            keywordType: "userId",
            keyword: "",
            offset: 0,
            limit: 50,
        },
    },
    permissions: {
        totalCount: 0,
        results: [],
        params: {
            keywordType: "userId",
            keyword: "",
            offset: 0,
            limit: 50,
        },
    },
};

export default handleActions(
    {
        [TEAM_MANAGEMENT_PENDING]: (state) => {
            return {
                ...state,
                pending: true,
                error: false,
            };
        },
        [TEAM_MANAGEMENT_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [TEAM_MANAGEMENT_GET_TEAMS_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                pending: false,
                error: false,
                totalCount: data.totalCount,
                teams: data.results,
            };
        },
        [TEAM_MANAGEMENT_UPDATE_TEAMS_PARAMS]: (state, action) => {
            return {
                ...state,
                params: {
                    ...state.params,
                    ...action.payload,
                },
            };
        },
        [TEAM_MANAGEMENT_RESET_TEAMS_PARAMS_RESET]: (state) => {
            return {
                ...state,
                params: {
                    keywordType: "name",
                    keyword: "",
                    offset: 0,
                    limit: 50,
                },
            };
        },
        [TEAM_MANAGEMENT_INIT_TEAMS]: (state) => {
            return {
                ...state,
                params: {
                    keywordType: "name",
                    keyword: "",
                    offset: 0,
                    limit: 50,
                },
                teams: [],
            };
        },
        [TEAM_MANAGEMENT_GET_TEAM_ROLES_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                pending: false,
                error: false,
                roles: {
                    ...state.roles,
                    totalCount: data.totalCount,
                    results: data.results,
                },
            };
        },
        [TEAM_MANAGEMENT_GET_TEAM_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                pending: false,
                error: false,
                team: data,
            };
        },
        [TEAM_MANAGEMENT_GET_TEAM_MEMBERS_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                pending: false,
                error: false,
                members: {
                    ...state.members,
                    totalCount: data.totalCount,
                    results: data.results,
                },
            };
        },
        [TEAM_MANAGEMENT_UPDATE_ROLES_PARAMS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
                roles: {
                    ...state.roles,
                    params: {
                        ...state.params,
                        ...action.payload,
                    },
                },
            };
        },
        [TEAM_MANAGEMENT_UPDATE_MEMBERS_PARAMS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
                members: {
                    ...state.members,
                    params: {
                        ...state.params,
                        ...action.payload,
                    },
                },
            };
        },
        [TEAM_MANAGEMENT_UPDATE_PROJECT_TEAM_SUCCESS]: (state, action) => {
            const payload = action.payload;
            const existingTeams = state.teams;
            const temp = {
                ...state,
                team: {
                    ...state.team,
                    ...payload,
                },
                pending: false,
                error: false,
            };
            if (existingTeams) {
                temp.teams = existingTeams.map((v) => {
                    if (v.id === payload.id) {
                        return {
                            ...v,
                            ...payload,
                        };
                    }
                    return v;
                });
            }
            return temp;
        },
    },
    initialState,
);

export const updateParams = createAction(TEAM_MANAGEMENT_UPDATE_TEAMS_PARAMS);
export const resetParams = createAction(TEAM_MANAGEMENT_RESET_TEAMS_PARAMS_RESET);
export const updateRoleParams = createAction(TEAM_MANAGEMENT_UPDATE_ROLES_PARAMS);
export const updateMemberParams = createAction(TEAM_MANAGEMENT_UPDATE_MEMBERS_PARAMS);

export const getTeams = (projectId) => (dispatch, getState) => {
    const { stage, teamManagement } = getState();

    dispatch({ type: TEAM_MANAGEMENT_PENDING });

    return getTeamsAPI(stage.id, stage.endpoint, projectId, teamManagement.params)
        .then((response) => {
            dispatch({
                type: TEAM_MANAGEMENT_GET_TEAMS_SUCCESS,
                payload: response,
            });

            return response && response.data;
        })
        .catch((error) => {
            dispatch({
                type: TEAM_MANAGEMENT_FAILURE,
                payload: error,
            });
        });
};
export const getTeamRoles =
    ({ projectId, id, params }) =>
    (dispatch, getState) => {
        const { stage } = getState();

        dispatch({ type: TEAM_MANAGEMENT_PENDING });

        return getTeamRolesAPI(stage.id, stage.endpoint, projectId, id, params)
            .then((response) => {
                dispatch({
                    type: TEAM_MANAGEMENT_GET_TEAM_ROLES_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: TEAM_MANAGEMENT_FAILURE,
                    payload: error,
                });
            });
    };
export const getTeam =
    ({ id, projectId }) =>
    (dispatch, getState) => {
        const { stage } = getState();

        dispatch({ type: TEAM_MANAGEMENT_PENDING });

        return getTeamAPI(stage.id, stage.endpoint, projectId, id)
            .then((response) => {
                dispatch({
                    type: TEAM_MANAGEMENT_GET_TEAM_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: TEAM_MANAGEMENT_FAILURE,
                    payload: error,
                });
            });
    };
export const getTeamMembers =
    ({ id, params, projectId }) =>
    (dispatch, getState) => {
        const { stage } = getState();

        dispatch({ type: TEAM_MANAGEMENT_PENDING });

        return getTeamMembersAPI(stage.id, stage.endpoint, projectId, id, params)
            .then((response) => {
                dispatch({
                    type: TEAM_MANAGEMENT_GET_TEAM_MEMBERS_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: TEAM_MANAGEMENT_FAILURE,
                    payload: error,
                });
            });
    };

export const updateProjectTeam = (projectId, teamId, body) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: TEAM_MANAGEMENT_PENDING });
    return patchProjectTeamAPI(stage.id, stage.endpoint, projectId, teamId, body)
        .then((response) => {
            dispatch({
                type: TEAM_MANAGEMENT_UPDATE_PROJECT_TEAM_SUCCESS,
                payload: {
                    ...body,
                    id: teamId,
                },
            });
        })
        .catch((error) => {
            dispatch({
                type: TEAM_MANAGEMENT_FAILURE,
                payload: error,
            });
        });
};
