import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';
import compareVersions from '../cores/version';
import * as stageActions from '../modules/stage';

class VersionedComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isVisible: false
        };
    }

    getVersion() {
        return this.props.version;
    }

    componentDidMount() {
        this.updateVisibility();
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        this.updateVisibility();
    }

    getIsVisible() {
        const {
            stage
        } = this.props;
        const version = this.props.version;
        // console.log(`VersionedComponent: name: ${this.props.name}, version: ${version}`);
        if(!version || version.length === 0) {
            return true;
        }
        //console.log(`component version: ${version}`);
        const spaceVersion = stage.version;
        // console.log(`VersionedComponent: spaceVersion: ${spaceVersion}`);
        const compareResult = compareVersions(spaceVersion, version);
        // console.log(`version compare result: ${compareResult}`);

        // TODO: maxVersion
        return compareResult >= 0;
    }

    updateVisibility() {
        const isVisible = this.getIsVisible();
        if(this.state.isVisible !== isVisible)
            this.setState({
                isVisible
            });
    }

    render() {
        if(!this.state.isVisible) 
            return null;
        return (<React.Fragment>
                    {this.props.children}
                </React.Fragment>);
    }
}
VersionedComponent.propTypes = {
    version: PropTypes.string
}

let mapStateToProps = (state) => {
    return {
        stage : state.stage
    };
};

let mapDispatchToProps = (dispatch) => {
    return {
        StageActions : bindActionCreators(stageActions, dispatch)
    }
};

VersionedComponent = connect(mapStateToProps, mapDispatchToProps)(VersionedComponent);
export default VersionedComponent;