import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getContentSchemaDetail } from "@modules/content-schema";
import { getMetadataIcon } from "@features/content-schema-fields/content-schema-type-icons";
import {
    Box,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    Stack,
    TextField,
} from "@mzc-pdc/ui";
import Typography from "@components_v2/typography/typography";
import theme from "@styles/theme";
import { IconCheckLarge, IconChevronRight, IconRefresh, IconSearch } from "@mzc-cloudplex/icons";
import { alpha } from "@mui/material";
import MetadataFieldReadMode from "@features/metadata-fields/metadata-field-read-mode";
import { getContentSchemasAPI } from "@modules/apis/content-schemas";
import { useInView } from "react-intersection-observer";
import useCustomContent from "@hooks/useCustomContent";
import { WarningMessage } from "@features/empty-list-view";

const CustomMetadataEdit = ({
    warningMessage = null,
    hidden = false,
    contentSchemaId,
    contentSchemaDetail,
    onSelectCustomMetadata,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [targetRef, inView] = useInView();
    const [checkedSchemaId, setCheckedSchemaId] = useState();
    const { getCustomMetadataByContentSchema } = useCustomContent();

    const [contentSchemas, setContentSchemas] = useState({ pending: false, data: null });
    const [searchParams, setSearchParams] = useState({});

    const { projectId } = useSelector(({ project }) => ({ projectId: project?.id }));
    const stage = useSelector(({ stage }) => stage);

    const onHandleChangeSearch = (name) => {
        setSearchParams({ ...searchParams, name: name });
    };

    const onExtendedMetadataFetch = async () => {
        try {
            if (contentSchemas.pending) return;

            const params = { ...searchParams, type: "EXTENDED_METADATA" };
            if (contentSchemas?.data?.nextToken) params.nextToken = contentSchemas?.data?.nextToken;
            const response = await getContentSchemasAPI(stage.id, stage.endpoint, projectId, params);
            const { results, nextToken } = response.data;
            setContentSchemas({
                ...contentSchemas,
                data: { results: [...contentSchemas.data.results, ...results], nextToken: nextToken },
            });
        } catch (e) {
            console.log(e);
        }
    };

    const getAllExtendedMetadataList = async () => {
        try {
            setContentSchemas({ ...contentSchemas, pending: true });
            const params = { ...searchParams, type: "EXTENDED_METADATA", status: "ACTIVE" };
            const response = await getContentSchemasAPI(stage.id, stage.endpoint, projectId, params);

            setContentSchemas({ ...contentSchemas, data: response.data, pending: false });
        } catch (e) {
            console.log(e);
        }
    };

    const onClear = () => {
        setCheckedSchemaId(null);
        onSelectCustomMetadata(null);
    };

    const initContentSchema = async () => {
        await onClear();
        await onHandleChangeSearch("");
        await getAllExtendedMetadataList();
    };

    const onSelectContentSchema = useCallback(
        async (schema) => {
            try {
                setCheckedSchemaId(schema?.id);
                const customMetadata = await dispatch(getContentSchemaDetail(schema?.id, projectId));
                const parsedCustomMetadata = getCustomMetadataByContentSchema(customMetadata);

                onSelectCustomMetadata(parsedCustomMetadata);
            } catch (error) {
                console.error(error);
            }
        },
        [checkedSchemaId],
    );

    const noChanges = useMemo(() => {
        return contentSchemaDetail?.id === contentSchemaId;
    }, [contentSchemaDetail, contentSchemaId]);

    useEffect(() => {
        if (contentSchemas.pending) return;
        if (inView) {
            onExtendedMetadataFetch();
        }
    }, [contentSchemas.pending, inView]);

    useEffect(() => {
        if (!contentSchemas.data?.results?.length || !!checkedSchemaId) return;
        onSelectContentSchema(contentSchemas.data?.results[0]); //검색 또는 첫 로드시에는 첫번째 아이템을 선택하도록
    }, [contentSchemas.data]);

    useEffect(() => {
        getAllExtendedMetadataList();
    }, []);

    if (hidden) return null;
    return (
        <Grid container flexDirection={"column"} gap={1} pb={2}>
            <Stack>{contentSchemaId && warningMessage && <WarningMessage message={warningMessage} />}</Stack>
            <Stack direction={"row"}>
                <Stack flex={1}>
                    <TextField
                        variant={"outlined"}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconSearch size={16} />
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => onHandleChangeSearch(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.code === "Enter") {
                                onClear();
                                getAllExtendedMetadataList();
                            }
                        }}
                        id="quick-search"
                        name="keyword"
                        placeholder={t(`common::label::Search by content schema name.`)}
                        sx={{ width: "445px" }}
                    />
                </Stack>
                <IconButton circled onClick={initContentSchema}>
                    <IconRefresh size={16} />
                </IconButton>
            </Stack>
            <Stack direction={"row"} sx={{ height: 450 }}>
                <List
                    sx={{
                        p: 0,
                        overflowY: "auto",
                        border: `1px solid ${theme.palette.greySecondary[100]}`,
                        borderRight: "none",
                        minWidth: "354px",
                    }}
                >
                    {!contentSchemas.pending && contentSchemas.data?.results ? (
                        contentSchemas.data?.results?.map((schema, index) => (
                            <ListItem
                                key={`${schema.id}-${index}`}
                                data-id={schema.id}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    onSelectContentSchema(
                                        contentSchemas.data?.results?.find((t) => t.id === e.currentTarget.dataset.id),
                                    );
                                }}
                                sx={{
                                    py: 2,
                                    px: 2.5,
                                    "&:hover": {
                                        backgroundColor: alpha(theme.palette.greySecondary[50], 0.24),
                                    },
                                    ...(schema.id === checkedSchemaId && {
                                        backgroundColor: `${alpha(theme.palette.greySecondary.A100, 0.36)} !important`,
                                    }),
                                }}
                            >
                                <ListItemText sx={{ m: 0 }}>
                                    <Stack direction={"row"} alignItems={"center"} gap={2.5}>
                                        <IconButton
                                            circled
                                            variant={"contained"}
                                            size={"small"}
                                            data-id={schema.id}
                                            sx={{
                                                backgroundColor:
                                                    schema.id === checkedSchemaId
                                                        ? theme.palette.primary.light
                                                        : theme.palette.greySecondary.light,
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                onSelectContentSchema(
                                                    contentSchemas.data.results?.find(
                                                        (t) => t.id === e.currentTarget.dataset.id,
                                                    ),
                                                );
                                            }}
                                        >
                                            <IconCheckLarge size={12} />
                                        </IconButton>
                                        <Stack direction={"column"} flex={1}>
                                            <Stack direction={"row"} gap={0.5}>
                                                <Typography variant={"body1_500"}>{schema.name} </Typography>
                                                <Typography
                                                    variant={"body1_400"}
                                                    color={theme.palette.greySecondary[600]}
                                                >
                                                    {`(${schema.id})`}
                                                </Typography>
                                            </Stack>
                                            <Typography variant={"body1_400"} color={theme.palette.greySecondary[500]}>
                                                {schema.description ? schema.description : "-"}
                                            </Typography>
                                        </Stack>
                                        <Stack visibility={schema.id === checkedSchemaId ? "visible" : "hidden"}>
                                            <IconChevronRight size={20} htmlColor={theme.palette.greySecondary[500]} />
                                        </Stack>
                                    </Stack>
                                </ListItemText>
                            </ListItem>
                        ))
                    ) : (
                        <div className={"loading-block"} style={{ height: `475px` }}>
                            <span className={"loading-content"} />
                        </div>
                    )}
                    {contentSchemas.data?.nextToken && <div ref={targetRef} />}
                </List>
                <List
                    sx={{
                        p: 2.5,
                        overflowY: "auto",
                        border: `1px solid ${theme.palette.greySecondary[100]}`,
                        minWidth: "360px",
                    }}
                >
                    {(contentSchemas.pending || contentSchemas.data?.results?.length > 0) &&
                        (contentSchemaDetail?.fields ? (
                            contentSchemaDetail?.fields?.map((field, index) => (
                                <Stack direction={"column"} gap={2} key={index}>
                                    <ListItem data-id={field.id} sx={{ p: 0 }}>
                                        <ListItemText sx={{ m: 0 }}>
                                            <Stack direction={"row"} alignItems={"center"} gap={2.5}>
                                                <Box
                                                    width={24}
                                                    height={24}
                                                    sx={{
                                                        display: `flex`,
                                                        alignItems: `center`,
                                                        justifyContent: `center`,
                                                        backgroundColor: theme.palette.secondary[800],
                                                    }}
                                                >
                                                    {getMetadataIcon(field.type)}
                                                </Box>
                                                <Stack direction={"column"} flex={1}>
                                                    <Typography
                                                        variant={"body1"}
                                                        color={theme.palette.greySecondary[600]}
                                                        required={field.isRequired}
                                                    >
                                                        {field.subtitle}
                                                    </Typography>
                                                    <Typography
                                                        variant={"body1"}
                                                        color={theme.palette.greySecondary.main}
                                                    >
                                                        <Grid container gap={1} alignItems={"center"} pt={1}>
                                                            <MetadataFieldReadMode
                                                                type={field.type}
                                                                isHidden={false}
                                                                value={field.defaultValue}
                                                            />
                                                        </Grid>
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </ListItemText>
                                    </ListItem>
                                    {index !== contentSchemaDetail?.fields?.length - 1 && (
                                        <Divider
                                            flexItem
                                            orientation={"horizontal"}
                                            sx={{ color: theme.palette.greySecondary[100], mb: 2 }}
                                        />
                                    )}
                                </Stack>
                            ))
                        ) : (
                            <div className={"loading-block"} style={{ height: `475px` }}>
                                <span className={"loading-content"} />
                            </div>
                        ))}
                </List>
            </Stack>
        </Grid>
    );
};
export default CustomMetadataEdit;
