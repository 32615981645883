import { Box, Grid, IconButton, Stack, Tooltip } from "@mzc-pdc/ui";
import AssetTypeFlag from "@components_v2/icon-type/asset/asset-type-flag";
import { GridNameEllipsis } from "@components_v2/grid/grid.styled";
import Typography from "@components_v2/typography/typography";
import { IconErrorCircle, IconPath } from "@mzc-cloudplex/icons";
import theme from "@styles/theme";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { JOB_ACTIONS } from "@constants";

const JobTaskCreateAssets = ({ job, tasks }) => {
    const { t } = useTranslation();

    const getMetadata = (task) => {
        return job.action === JOB_ACTIONS.ARCHIVE_ASSETS ? task.metadata?.asset : task.metadata;
    };

    return (
        <>
            {tasks?.map((task) => (
                <Stack py={1.5} gap={2}>
                    <Box>
                        <Grid container justifyContent={"space-between"}>
                            <Grid item sx={{ display: "flex", alignItems: "center" }}>
                                <AssetTypeFlag width={20} height={16} type={getMetadata(task)?.mediaType} />
                                <GridNameEllipsis item maxWidth={"300px"}>
                                    <Typography variant="body2_500">{getMetadata(task)?.name}</Typography>
                                </GridNameEllipsis>
                            </Grid>
                            {task.status === "FAILED" && (
                                <Grid>
                                    <Tooltip
                                        title={t(
                                            `common::code::${task.summary.exitMessage}`,
                                            task.summary.exitMessage ?? "",
                                        )}
                                    >
                                        <IconButton circled size="small">
                                            <IconErrorCircle size={16} color="error" />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            )}
                        </Grid>
                        {task.folderFullPath && (
                            <Grid container gap={1} mt={0.75} px={3.5} flexWrap={"nowrap"}>
                                <IconPath size={16} htmlColor={theme.palette.greySecondary[500]} />
                                <Typography variant="body3_400" color={"text.secondary"}>
                                    {task.folderFullPath ?? "-"}
                                </Typography>
                            </Grid>
                        )}
                    </Box>
                </Stack>
            ))}
        </>
    );
};

export default JobTaskCreateAssets;
