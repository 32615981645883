import React, { useState, useEffect, useCallback } from "react";
import { withRouter, matchPath } from "react-router";
import { isLocal } from "@cores/enviromentExtension";
import { useLocation, useHistory } from "react-router-dom";

import Loader from "../../components/loader/Loader";
import { NoProjectErrorMessageBox, CallMegazoneErrorMessageBox } from "./ErrorMessageBox";

import routes from "../../cores/routes";
import { PUBLIC_URL } from "../../constants";
import { parseQueryParameter } from "../../cores";

import equal from "deep-equal";

import { sessionKeeper } from "../../cores/sessionKeeper";
import compareVersions, { VERSION_CM_5112_PROJECT_SITE_ACCESS, VERSION_CM_5058_DASHBOARD } from "../../cores/version";

import Loadable from "@react-loadable/revised";
import menuService from "@services/menuService";
import { Badge, Grid, IconButton, InputAdornment, Tooltip } from "@mzc-pdc/ui";
import * as PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { IconSearch, IconTune } from "@mzc-cloudplex/icons";
import TextField from "@components_v2/text-field/text-field";
import theme from "@styles/theme";
import i18n from "@lib/i18n";
import { setStage } from "@modules/stage";
import { setProject } from "@modules/project";

const LoadableTokenExpiredModal = Loadable({
    loader: () => import("../../components/modals/TokenExpired"),
    loading: () => null,
});

const Stage = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const params = parseQueryParameter(location.search);
    const paramRedirectUrl = params.get("redirectUrl");
    const [activeActionButtonId, setActiveActionButtonId] = useState(-1);
    const [accessibleStages, setAccessibleStages] = useState(null);
    const [hasCheckedAllStages, setHasCheckedAllStages] = useState(false);
    const [showLoading, setShowLoading] = useState(true);
    const [showNoSiteAccess, setShowNoSiteAccess] = useState(false);
    const [componentUnmounted, setComponentUnmounted] = useState(false);
    const [projectFilterKeyword, setProjectFilterKeyword] = useState({});

    const user = useSelector(({ user }) => user);
    const stages = useSelector(({ user }) => user.stages.data);

    const checkStages = useCallback(
        (stages) => {
            if (componentUnmounted) {
                return;
            }
            const accessible = [];
            let stagesCount = stages ? stages.length : 0;
            let checkedCount = 0;
            for (let i = 0; i < stagesCount; i++) {
                const stage = stages[i];
                if (stage.checked) checkedCount++;
                if (stage.isAccessible) accessible.push(stage);
            }
            const hasCheckedAllStages = stagesCount === 0 || checkedCount === stagesCount;
            const showLoading = !stages || checkedCount === 0;
            const shouldUpdateState =
                showLoading !== showLoading ||
                hasCheckedAllStages !== hasCheckedAllStages ||
                !equal(accessible, accessibleStages);

            if (shouldUpdateState) {
                setShowLoading(showLoading);
                setHasCheckedAllStages(hasCheckedAllStages);
                setAccessibleStages(accessible);
            }
        },
        [componentUnmounted],
    );

    const onClickAdmin = async (stage) => {
        const project = stage.projects && stage.projects[0];
        if (project) {
            await enterToApp(stage, project, true);
        }
    };

    const onClickProject = async (stage, project) => {
        await enterToApp(stage, project);
    };

    const onClickToggleActionButton = (projectId) => {
        setActiveActionButtonId(activeActionButtonId === projectId ? -1 : projectId);
    };

    const onClickSetDefaultProject = (stageId, projectId) => {
        const key = `mz.mcm-${user.data.id}`;
        const userDataString = localStorage.getItem(key);
        let userDataJson = {};
        if (userDataString) {
            userDataJson = JSON.parse(userDataString);
        }

        localStorage.setItem(
            key,
            JSON.stringify({
                ...userDataJson,
                [stageId]: projectId,
            }),
        );

        setActiveActionButtonId(-1);
    };

    const onClickUnSetDefaultProject = (stageId) => {
        const key = `mz.mcm-${user.data.id}`;
        const userDataString = localStorage.getItem(key);
        let userDataJson = {};
        if (userDataString) {
            userDataJson = JSON.parse(userDataString);
        }
        delete userDataJson[stageId];

        if (Object.keys(userDataJson).length === 0) {
            localStorage.removeItem(key);
        } else {
            localStorage.setItem(
                key,
                JSON.stringify({
                    ...userDataJson,
                }),
            );
        }

        setActiveActionButtonId(-1);
    };

    const enterToApp = async (stage, project, isAdminApp) => {
        const cookieDomain = isLocal ? "localhost" : "megazone.io";
        const setStagePromise = dispatch(setStage(stage));
        const setProjectPromise = dispatch(setProject(project));

        await Promise.all([setStagePromise, setProjectPromise]);

        if (isAdminApp) {
            history.push(menuService.paths.admin.users(stage.id));
            return;
        }

        history.push("/");
    };

    const getRedirectPath = (stage, project, isAdminApp) => {
        if (isPreviousSelectedProject(stage, project, isAdminApp)) {
            let url = paramRedirectUrl || window.location.origin;

            let redirectPath = "/";
            routes.some((route) => {
                const match = matchPath(url.replace(window.location.origin, ""), {
                    path: route.path,
                    exact: true,
                });

                if (match && route.redirectPath) {
                    redirectPath = route.redirectPath;
                }

                return match;
            });

            return redirectPath;
        }

        return isAdminApp ? "/admin/space/users" : "/dashboard";
    };

    const getSessionCookie = (key) => {
        const sessionCookie = sessionKeeper.get();
        if (sessionCookie) {
            return key ? sessionCookie[key] : sessionCookie;
        }
        return null;
    };

    const getStoredUserData = (key) => {
        const storedUserData = localStorage.getItem(`mz.mcm-${user.data?.id}`);
        if (storedUserData) {
            const result = JSON.parse(storedUserData);
            return key ? result[key] : result;
        }

        return null;
    };

    const isPreviousSelectedProject = (stage, project, isAdminApp) => {
        const cookie = getSessionCookie();
        if (!cookie) {
            return false;
        }

        const redirectUrl = paramRedirectUrl;
        const isEqualPreviousAppMode =
            !redirectUrl ||
            (isAdminApp && redirectUrl.indexOf("/admin/") > -1) ||
            (!isAdminApp && redirectUrl.indexOf("/admin/") < 0);

        return cookie.stageId === stage.id && cookie.projectId === project.id && isEqualPreviousAppMode;
    };

    const filterActiveProject = useCallback(
        (stageId, projects) => {
            const key = `mz.mcm-${user.data.id}`;
            const userDataString = localStorage.getItem(key);

            const userDataJson = userDataString ? JSON.parse(userDataString) : {};
            const defaultProjectId = userDataJson[stageId];

            return (
                (projects &&
                    projects
                        .sort((prevPrj, nextPrj) => {
                            if (prevPrj.id === defaultProjectId) return -1;
                            if (nextPrj.id === defaultProjectId) return 1;
                            return 0;
                        })
                        .filter(
                            (v) =>
                                v.isActive &&
                                v.name?.toLowerCase().includes(projectFilterKeyword[stageId]?.toLowerCase() ?? ""),
                        )) ||
                []
            );
        },
        [user?.data?.id, projectFilterKeyword],
    );

    const showNoAccessPopup = () => {
        setShowNoSiteAccess(true);
    };

    const closeNoAccessPopup = () => {
        setShowNoSiteAccess(false);
    };

    const onHandleChangeFilterKeyword = (stageId, keyword) => {
        setProjectFilterKeyword({
            ...projectFilterKeyword,
            [stageId]: keyword,
        });
    };

    useEffect(() => {
        if (!stages) return;
        checkStages(stages);

        if (document.documentElement.classList.contains("utils-menu-open"))
            document.documentElement.classList.remove("utils-menu-open");

        return () => {
            setComponentUnmounted(true);
        };
    }, [stages]);

    useEffect(() => {
        //Note: 1.0.98 이전 defaultProject는 하나만 지정가능했는데 이걸 스페이스 별 1개로 구조를 변경하면서 기존 defaultStage/ProjectId가 있는 경우 구조를 변경해서 동일 기능을 제공하기 위해 추가했습니다.
        const key = `mz.mcm-${user.data.id}`;
        const userDataString = localStorage.getItem(key);
        let userDataJson = {};
        if (userDataString) {
            userDataJson = JSON.parse(userDataString);
        }
        if (!!userDataJson["defaultProjectId"] && !!userDataJson["defaultStageId"]) {
            const { defaultStageId, defaultProjectId, ...rest } = userDataJson;
            localStorage.setItem(key, JSON.stringify({ ...rest, [defaultStageId]: defaultProjectId }));
        }
    }, []);

    return (
        <React.Fragment>
            {showLoading && <Loader />}

            {showNoSiteAccess && (
                <LoadableTokenExpiredModal error={{ type: "NO_SITE_ACCESS" }} onConfirm={closeNoAccessPopup} />
            )}

            {accessibleStages && accessibleStages.length > 0 ? (
                <div className={"gate"}>
                    <div className={"message"}>
                        <h1>
                            {t(`common::msg::${"Hi, {{name}}"}`, {
                                name: user.data.name,
                            })}
                        </h1>
                        <p className={"lead"}>{t(`common::msg::${"Please select a project you want to access."}`)}</p>
                    </div>

                    <div className={"d-flex flex-column"}>
                        {accessibleStages.length > 1 && (
                            <div className={`total col-${4 * stages.length}`}>
                                <p>
                                    {t(`common::label::${"Total {{name}}"}`, {
                                        name: t(`common::label::${"Space"}`).toLowerCase(),
                                    })}{" "}
                                    <strong className="text-primary">{stages.length}</strong>
                                </p>
                            </div>
                        )}

                        <div className={"row justify-content-center"}>
                            {accessibleStages.map((stage) => (
                                <div className={`card`} key={stage.id}>
                                    <div className="card-header">
                                        <i className="thumbnail">
                                            <img src={`${PUBLIC_URL}/images/stage.png`} />
                                        </i>
                                        <div className="content">
                                            <h6 className="name">{stage.name}</h6>
                                        </div>
                                        {(compareVersions(stage.version, VERSION_CM_5112_PROJECT_SITE_ACCESS) < 0 ||
                                            (stage.user && stage.user.isSpaceAdmin)) && (
                                            <a
                                                className="link-admin"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    onClickAdmin(stage);
                                                }}
                                            >
                                                <i className={"sprite sprite-administration"}></i>
                                                <strong>{t(`common::label::${"Administration"}`)}</strong>
                                            </a>
                                        )}
                                    </div>
                                    <Grid mt={theme.spacing(3)} pr={theme.spacing(1)}>
                                        <TextField
                                            type={"search"}
                                            fullWidth
                                            sx={{
                                                [theme.breakpoints.down("xl")]: {
                                                    width: `100%`,
                                                },
                                                "& .Mui-focused:has(input[type='search']) .MuiInputAdornment-positionStart":
                                                    {
                                                        width: 16,
                                                        height: 16,
                                                    },
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment
                                                        position="start"
                                                        sx={{
                                                            width: `16px !important`,
                                                            height: `16px !important`,
                                                        }}
                                                    >
                                                        <IconSearch size={16} color={"greySecondary"} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            placeholder={i18n.t(`common::label::Search Projects`, `Search Projects`)}
                                            value={projectFilterKeyword[stage.id] ?? ""}
                                            onChange={(e) => {
                                                onHandleChangeFilterKeyword(stage.id, e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <div className={"card-body"}>
                                        {stage.pending ? (
                                            <div className={"loading"}>
                                                <i className={"loading-content"}></i>
                                            </div>
                                        ) : (
                                            <React.Fragment>
                                                {stage.error ? (
                                                    <CallMegazoneErrorMessageBox t={t} /> // 오류 - 메가존 문의 필요 화면
                                                ) : (
                                                    <React.Fragment>
                                                        {filterActiveProject(stage.id, stage.projects).length > 0 ? (
                                                            <React.Fragment>
                                                                <p className={"total"}>
                                                                    {t(`common::label::${"Total"}`)}{" "}
                                                                    <strong className="text-primary">
                                                                        {
                                                                            filterActiveProject(
                                                                                stage.id,
                                                                                stage.projects,
                                                                            ).length
                                                                        }
                                                                    </strong>
                                                                </p>
                                                                <div className="scroll-area">
                                                                    <ul className={"list"}>
                                                                        {filterActiveProject(
                                                                            stage.id,
                                                                            stage.projects,
                                                                        ).map((project) => (
                                                                            <li
                                                                                key={project.id}
                                                                                className={
                                                                                    isPreviousSelectedProject(
                                                                                        stage,
                                                                                        project,
                                                                                    )
                                                                                        ? "active"
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                <a
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        onClickProject(stage, project);
                                                                                    }}
                                                                                >
                                                                                    <div>
                                                                                        <div className="d-flex align-items-center">
                                                                                            <strong className="mr-1">
                                                                                                {project.name}
                                                                                            </strong>
                                                                                            {isPreviousSelectedProject(
                                                                                                stage,
                                                                                                project,
                                                                                            ) && (
                                                                                                <p>
                                                                                                    (
                                                                                                    {t(
                                                                                                        `common::label::${"Currently online"}`,
                                                                                                    )}
                                                                                                    )
                                                                                                </p>
                                                                                            )}
                                                                                            {getStoredUserData(
                                                                                                stage.id,
                                                                                            ) === project.id && (
                                                                                                <span
                                                                                                    className={
                                                                                                        "default"
                                                                                                    }
                                                                                                >
                                                                                                    <i
                                                                                                        className={
                                                                                                            "sprite sprite-check-primary"
                                                                                                        }
                                                                                                    ></i>
                                                                                                    {t(
                                                                                                        `common::label::${"Default"}`,
                                                                                                    )}
                                                                                                </span>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                                <div className={"tools"}>
                                                                                    <div className={"more"}>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="thumb-more-btn"
                                                                                            onClick={() => {
                                                                                                onClickToggleActionButton(
                                                                                                    project.id,
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            <i className="sprite sprite-etc"></i>
                                                                                            <span className={"a11y"}>
                                                                                                {t(
                                                                                                    `common::label::${"Etc"}`,
                                                                                                )}
                                                                                            </span>
                                                                                        </button>

                                                                                        <div
                                                                                            className={
                                                                                                "thumb-more-option fade show"
                                                                                            }
                                                                                            hidden={
                                                                                                project.id !==
                                                                                                activeActionButtonId
                                                                                            }
                                                                                        >
                                                                                            {getStoredUserData(
                                                                                                stage.id,
                                                                                            ) === project.id ? (
                                                                                                <button
                                                                                                    type={"button"}
                                                                                                    className={
                                                                                                        "btn dropdown-item"
                                                                                                    }
                                                                                                    onClick={() => {
                                                                                                        onClickUnSetDefaultProject(
                                                                                                            stage.id,
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    {t(
                                                                                                        `common::label::${"Unset default"}`,
                                                                                                    )}
                                                                                                </button>
                                                                                            ) : (
                                                                                                <button
                                                                                                    type={"button"}
                                                                                                    className={
                                                                                                        "btn dropdown-item"
                                                                                                    }
                                                                                                    onClick={() => {
                                                                                                        onClickSetDefaultProject(
                                                                                                            stage.id,
                                                                                                            project.id,
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    {t(
                                                                                                        `common::label::${"Set as default"}`,
                                                                                                    )}
                                                                                                </button>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            </React.Fragment>
                                                        ) : (
                                                            <NoProjectErrorMessageBox t={t} />
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    {hasCheckedAllStages && (
                        <div className={"gate-empty"}>
                            <i className={"sprite sprite-error-big"}></i>
                            <h1>{t(`common::msg::${"There is no project available to access."}`)}</h1>
                            <div className={"card"}>
                                <div className={"card-body"}>
                                    <p className={"message"}>
                                        <Trans
                                            i18nKey="common::msg::If you have a problem with your account, or would like to use the cloud <0/>Platform, please contact our sales team. We'll assist you with more details."
                                            components={[<br />]}
                                        />
                                    </p>
                                    <div className={"row"}>
                                        <div className={"col"}>
                                            <strong className={"title"}>
                                                {t(`common::label::{{name}} inquiry`, {
                                                    name: t(`common::label::${"Cloud"}`),
                                                })}
                                            </strong>
                                            <ul>
                                                <li className={"dot"}>
                                                    {t(`common::label::${"24 hour operation"}`)} :{" "}
                                                    <strong>1644-2243</strong>
                                                </li>
                                                <li className={"dot"}>
                                                    {t(`common::label::${"Email"}`)} :{" "}
                                                    <a href="mailto:cloud@megazone.com">cloud@megazone.com</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className={"col"}>
                                            <strong className={"title"}>
                                                {t(`common::label::{{name}} inquiry`, { name: "Megazone" })}
                                            </strong>
                                            <p className={"dot"}>
                                                <a href="https://mz-cloudplex.zendesk.com">
                                                    {"https://mz-cloudplex.zendesk.com"}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </React.Fragment>
    );
};

export default Stage;
