import local from "./local";
import development from "./development";
import stage from "./stage";
import production from "./production";
import qa from "./qa";
import local_prod from "./local_prod";

function getEnvironment() {
    const environment = process.env.REACT_APP_NOT_SECRET_CODE;
    if (!environment) return environment;
    return environment.trim();
}

function getConfig() {
    const environment = getEnvironment();
    switch (environment) {
        case "production":
            return production;
        case "stage":
            return stage;
        case "development":
            return development;
        case "qa":
            return qa;
        case "local-prod":
            return local_prod;
    }

    return local;
}

export default getConfig();
