const SAME = 0;
const HIGHER = 1;
const LOWER = -1;

const compareVersions = function (currentVersion, compareVersion) {
    try {
        const leftSplit = currentVersion.split(".");
        const rightSplit = compareVersion.split(".");
        const length = Math.max(leftSplit.length, rightSplit.length);
        for (let i = 0; i < length; i++) {
            if (
                (leftSplit[i] && !rightSplit[i] && parseInt(leftSplit[i]) > 0) ||
                parseInt(leftSplit[i]) > parseInt(rightSplit[i])
            ) {
                return HIGHER;
            } else if (
                (rightSplit[i] && !leftSplit[i] && parseInt(rightSplit[i]) > 0) ||
                parseInt(leftSplit[i]) < parseInt(rightSplit[i])
            ) {
                return LOWER;
            }
        }
    } catch (e) {
        return LOWER;
    }
    return SAME;
};
export default compareVersions;

export const VERSION_CM_3963_ADD_ASSET_TYPE = "1.0.7";
export const VERSION_CM_4214_ASSET_DOWNLOAD = "1.0.8";
export const VERSION_CM_3339_TIMELINE_ANALYSIS = "1.0.10";
export const VERSION_CM_4557_HIDDEN_METADATA = "1.0.11";
export const VERSION_CM_4604_SITE_ACCESS = "1.0.12"; // Sources (Storage)
export const VERSION_CM_2654_ARCHIVE_ASSET = "1.0.13";
export const VERSION_CM_4601_MUSIC_CONTENTS = "1.0.13";
export const VERSION_CM_4601_PHOTO_CONTENTS = "1.0.13";
export const VERSION_CM_4717_ASSET_DOWNLOAD_V2 = "1.0.14";
export const VERSION_CM_4733_CREATE_METADATA = "1.0.14";
export const VERSION_CM_4721_SHARES = "1.0.14";
export const VERSION_CM_4750_UPLOAD_FOLDERS = "1.0.14";
export const VERSION_CM_4718_IMAGE_VIEWER = "1.0.14";
export const VERSION_CM_4716_INTEGRATED_SEARCH = "1.0.14";
export const VERSION_CM_4923_FILE_META_SEARCH = "1.0.15";
export const VERSION_CM_4838_SHARE_MANAGEMENT = "1.0.15";
export const VERSION_CM_4869_ELEMENT_VERSION = "1.0.15";
export const VERSION_CM_4840_SHARED_ASSET_DETAIL = "1.0.15";
export const VERSION_CM_5068_ASSET_OWNER_CHANGE = "1.0.15";
export const VERSION_SHARED_ASSET_SEARCH = "1.0.15";
export const VERSION_CM_5058_DASHBOARD = "1.0.16";
export const VERSION_CM_5579_ALL_PROJECTS = "1.0.16";
export const VERSION_CM_5112_PROJECT_SITE_ACCESS = "1.0.16";
export const VERSION_CM_5162_PREVIEW_POPUP = "1.0.16";
export const VERSION_CM_5716_ELEMENT_UUID = "1.0.17";
export const VERSION_CM_5182_ASSET_DELETE_WITH_REFERENCE = "1.0.17";
export const VERSION_CM_5214_ADMIN = "1.0.17";
export const VERSION_SOURCE_VIEW_MUTATION = "1.0.17";
export const VERSION_MY_JOBS_PANEL = "1.0.17";
export const VERSION_SOURCE_VIEW_NEW_FOLDER = "1.0.17";
export const VERSION_SOURCE_DOWNLOAD = "1.0.19";
export const VERSION_CM_6169_SOURCE_MULTI_ASSET_CREATE = "1.0.20"; // SHARE_BOX 도입
export const VERSION_CM_5929_CHANNEL_LIVE_CAPTION = "1.0.21";
export const VERSION_CM_6155_CATEGORY_BULK = "1.0.22";
export const VERSION_CM_6286_METADATA_SELECT_FIELD = "1.0.22";
export const VERSION_CM_6345_CATEGORY_BULK_VIDEO = "1.0.23";
export const VERSION_CM_6351_LIVE_CAPTION_SYNC = "1.0.23";
export const VERSION_CM_6333_LISTINGS_VIDEO = "1.0.23";
export const VERSION_ACCOUNT_LAZY_SYNC = "1.0.23";
export const VERSION_ADMIN_STORAGE_UPLOADABLE_DOWNLOADABLE = "1.0.26";
export const VERSION_CM_6575_ASSET_CATEGORY_GROUP_SEARCH = "1.0.27";
export const VERSION_CM_6657_VIDEO_CATEGORY_GROUP_SEARCH = "1.0.28";
export const VERSION_CM_6707_CONTENTS_CATEGORY_GROUP_SEARCH = "1.0.29";
export const VERSION_CM_6839_STORAGE_SYNC_STATUS_CHECK = "1.0.31";
export const VERSION_CM_6932_STORAGE_PROJECT_MAPPING = "1.0.32";
export const VERSION_CM_7104_STORAGE_TOKEN_BASE_PAGINATION = "1.0.35";
export const VERSION_CM_7307_DOWNLOAD = "1.0.44";
export const VERSION_CM_7349_CREATE_ASSETS_BULK = "1.0.43"; //"1.0.42";
export const VERSION_CM_7441_STORAGE_PREVIEW = "1.0.43";
export const VERSION_CM_7510_SAFARI_ENCODING = "1.0.44";
export const VERSION_SAFARI_ENCODING_FOR_PRESIGN_AND_UPLOAD = "1.0.45";
export const VERSION_CM_7497_INTEGRATED_SEARCH_WITH_SEARCH_ENGINE = "1.0.46";
export const VERSION_CM_7564_SETTINGS_SEARCHABLE = "1.0.46";
export const VERSION_FMS_SAFARI_ENCODING = "1.0.46";
export const VERSION_CM_7791_CREATE_ASSET_MULTIPART = "1.0.47";
export const VERSION_CM_7794_STORAGE_DOWNLOAD_LINK = "1.0.48";
export const VERSION_CM_8075_SHARE_REQUEST = "1.0.48";
export const VERSION_CM_8260_SHARED_BOX_STORAGE_PROPERTY_PANEL = "1.0.48";
export const VERSION_CM_8339_FOLDER_VIEW = "1.0.53";
export const VERSION_CM_8606_OBJECT_UPDATE_STATUS = "1.0.53";
export const VERSION_CM_8558_METADATA_TEMPLATE = "1.0.53";
export const VERSION_CM_8667_STORAGE_V3 = "1.0.55";
export const VERSION_CM_8635_ASSET_BULK_METADATA_TEMPLATE = "1.0.55";
export const VERSION_CM_8782_ASSET_SHARE_LINK = "1.0.57";
export const VERSION_CM_8742_ASSET_SHARE_REQUEST = "1.0.57";
export const VERSION_CM_8731_ASSET_DOWNLOAD_JOBS = "1.0.57";
export const VERSION_CM_8998_ASSET_ARCHIVE = "1.0.58";
export const VERSION_CM_9213_ASSET_MULTI_DOWNLOAD = "1.0.62";
export const VERSION_CM_9213_ASSET_MULTI_EXTERNAL_SHARE = "1.0.62";
export const VERSION_VIDEO_PUBLISHING = "1.0.62";
export const VERSION_CM_9322_EXTERNAL_SHARE_HISTORY = "1.0.66";
export const VERSION_CM_9337_RECENT_ASSET_SHARED_ME = "1.0.66";
export const VERSION_CM_9369_EXTERNAL_SHARE_HISTORY_PROPERTY_PANEL = "1.0.66";
export const VERSION_CM_9436_EVENT_TRIGGER_VIRTUAL_FOLDER = "1.0.69";
export const VERSION_CM_9496_ARCHIVED_VIRTUAL_FOLDER = "1.0.71";
export const VERSION_CM_9545_STORAGE_LOCK = "1.0.72";
export const VERSION_CM_9596_UPLOAD_RESUME = "1.0.73";
export const VERSION_CM_9660_BULK_ARCHIVE_RESTORE = "1.0.74";
export const VERSION_CM_9751_CPD_INTEGRATIONS = "1.0.75";
export const VERSION_CM_9766_COLLECTIONS = "1.0.76";
export const VERSION_CM_10084_CREATE_ASSETS_WITH_COLLECTIONS = "1.0.77";
export const VERSION_CM_10137_CUSTOM_CONTENT_SCHEMAS = "1.0.80";
export const VERSION_CM_10226_VIDEO_MULTIPART_UPLOAD = "1.0.82";
export const VERSION_CM_10269_CUSTOM_CONTENTS = "1.0.82";
export const VERSION_CM_10347_DETAIL_CUSTOM_CONTENTS = "1.0.82";
export const VERSION_CM_10454_CREATE_AUTO_CAPTIONS = "1.0.84";
export const VERSION_CM_10454_VIDEO_DOWNLOAD = "1.0.84";
export const VERSION_CM_10567_VOCABULARY = "1.0.84";
export const VERSION_CM_10617_EVENT_TRIGGER_AUTO_CAPTIONS = "1.0.84";
export const VERSION_CM_10808_AI_ANALYSIS = "1.0.88";
export const VERSION_CM_10877_ASSET_CUSTOM_METADATA = "1.0.89";
export const VERSION_CM_10929_ASSET_REPLIES = "1.0.89";
export const VERSION_CM_11123_ROLES_V2 = "1.0.93";
export const VERSION_CM_11119_USER_INVITATION = "1.0.93";
export const VERSION_CM_11214_DASHBOARD_ADVANCEMENT = "1.0.93";
export const VERSION_CM_11164_COLLECTION_FOLDER_VIEW = "1.0.94";
export const VERSION_CM_11376_SHARES_V2 = "1.0.95";
export const VERSION_CM_11160_ASSET_LINKED_ASSETS = "1.0.93";
export const VERSION_CM_11210_ASSET_DUPLICATION = "1.0.94";
export const VERSION_CM_11345_PROJECT_COMMENT_SERVICE = "1.0.95";
export const VERSION_CM_11209_ADD_CUSTOM_METADATA = "1.0.95";
export const VERSION_CM_11330_USERS_TEAMS_ACCESS_V3 = "1.0.95";
export const VERSION_CM_11273_DEFAULT_CUSTOM_METADATA = "1.0.97";
export const VERSION_CM_11609_DEFAULT_ASSET_VIEW_MODE = "1.0.97";
export const VERSION_CM_11777_PROJECT_SETTINGS_ADD_MENU_NAME = "1.0.98";
export const VERSION_CM_12123_METADATA_EXPORT = "1.0.100";
export const VERSION_CM_12200_CREATE_COLLECTION_WITH_ASSET_FOLDER = "1.0.101";
export const VERSION_CM_12290_COLLECTION_ARCHIVE = "1.0.101";
