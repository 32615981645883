import React, { useCallback, useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";

import uniqid from "uniqid";
import { alpha, Popper } from "@mui/material";
import { Box } from "@mzc-pdc/ui";
import theme from "@styles/theme";

// let componentId = uniqid();
const PopperMenu = forwardRef((props, ref) => {
    const {
        menuButtons = [],
        ButtonComponent,
        insertButtonFunction,
        buttonComponentDisabled,
        placement = "bottom-end",
        injectWrapClassName,
        setPopperOpenStatus,
    } = props;
    /**
     * menuButtons = [{
     *      label: '버튼명',
     *      onClick: (),
     *      enable: true,
     *      seperate: false
     *      skipTranslationLabel: 't(버튼명)',
     *      buttonDisabled: undefined,
     *      Component: component 함수,
     *      buttonActive: undefined
     * }]
     * ButtonComponent 는 오직 button만 허용
     * placement: material-popper에 placement 전달
     * injectWrapClassName 은 버튼 컴포넌트 외부 div class가 다른경우
     * insertButtonFunction 은 추가로 토글버튼에 함수를 추가하고 싶은경우
     */

    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpen = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setPopperOpenStatus && setPopperOpenStatus(true);
    };

    const handleClose = (e) => {
        // const isClickButton =
        //     e.path &&
        //     e.path.length &&
        //     e.path.filter(
        //         (item) => item.className && item.className.includes(`btn-dropdown btn-dropdown-toolbar dropdown-open`),
        //     ).length > 0;
        setAnchorEl(null);
        setPopperOpenStatus && setPopperOpenStatus(false);
    };

    const reBuildComponent = (component) => {
        return React.cloneElement(component, {
            onClick: (e) => {
                handleClose(e);
            },
        });
    };

    useImperativeHandle(ref, () => ({
        triggerClose(e) {
            handleClose(e);
        },
    }));

    const open = Boolean(anchorEl);
    const tooltipId = open ? `simple-popper` : undefined;
    const {
        className: buttonClassName,
        children: buttonChildren,
        type: buttonType,
        disabled: buttonDisabled,
        "data-tooltip": dataToolTip,
    } = ButtonComponent && ButtonComponent.props;

    const isMenuShowNothing = menuButtons.filter((item) => item.enable).length === 0;

    // console.log('ButtonComponent.props', ButtonComponent.props)

    return (
        <React.Fragment>
            <Box
                className={
                    injectWrapClassName
                        ? injectWrapClassName
                        : `btn-dropdown btn-dropdown-toolbar ${open ? "dropdown-open" : ""}`
                }
                sx={{
                    "&.dropdown-open": {
                        ".MuiIconButton-root": {
                            backgroundColor: alpha(theme.palette.greySecondary.main, 0.08),
                        },
                    },
                }}
            >
                {buttonComponentDisabled === true ? (
                    <></>
                ) : (
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            insertButtonFunction && insertButtonFunction();
                            handleOpen(e);
                        }}
                        data-tooltip={dataToolTip}
                        className={`${buttonClassName}`}
                        type={buttonType}
                        disabled={buttonDisabled || isMenuShowNothing}
                    >
                        {buttonChildren}
                    </button>
                )}
                <Popper
                    id={tooltipId}
                    open={open}
                    anchorEl={anchorEl}
                    className={`dropdown-popper`}
                    placement={placement}
                    sx={{ zIndex: `1301 !important` }}
                >
                    <div className="btn-dropdown-layer">
                        <OutsideClickHandler
                            display={"inline-block"}
                            onOutsideClick={(e) => {
                                // e.stopPropagation();
                                e.preventDefault();
                                handleClose(e);
                            }}
                        >
                            <ul className="btn-dropdown-list">
                                {menuButtons.map((item, index) => {
                                    const Component = item.Component;
                                    return item.enable ? (
                                        Component ? (
                                            <React.Fragment key={index}>
                                                {reBuildComponent(
                                                    Component({
                                                        onClick: (e) => {
                                                            e.stopPropagation();
                                                            e.preventDefault();
                                                            handleClose(e);
                                                        },
                                                    }),
                                                )}
                                            </React.Fragment>
                                        ) : (
                                            <li
                                                className={`${item.separate ? "separate" : ""} ${
                                                    item.buttonActive ? "selected" : ""
                                                }`}
                                                key={index}
                                            >
                                                <button
                                                    type={"button"}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        item.onClick(e);
                                                        handleClose(e);
                                                    }}
                                                    disabled={item.buttonDisabled}
                                                >
                                                    {item.skipTranslationLabel
                                                        ? item.skipTranslationLabel
                                                        : t(`common::label::${item.label}`)}
                                                </button>
                                            </li>
                                        )
                                    ) : (
                                        ""
                                    );
                                })}
                            </ul>
                        </OutsideClickHandler>
                    </div>
                </Popper>
            </Box>
        </React.Fragment>
    );
});

export default PopperMenu;
