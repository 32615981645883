import { handleActions } from "redux-actions";
import {
    createMetadataExportAPI,
    getMetadataExportDetailAPI,
    updateMetadataExportAPI,
    deleteMetadataExportAPI,
} from "@modules/apis/metadata-export";

const initialState = {
    data: null,
    pending: false,
    error: false,
};

const GET_METADATA_EXPORT_PENDING = "GET_METADATA_EXPORT_PENDING";
const GET_METADATA_EXPORT_SUCCESS = "GET_METADATA_EXPORT_SUCCESS";
const GET_METADATA_EXPORT_FAILURE = "GET_METADATA_EXPORT_FAILURE";
const UPDATE_METADATA_EXPORT_SUCCESS = "UPDATE_METADATA_EXPORT_SUCCESS";

export default handleActions(
    {
        [GET_METADATA_EXPORT_PENDING]: (state) => {
            return {
                ...state,
                data: null,
                pending: true,
                error: null,
            };
        },
        [GET_METADATA_EXPORT_SUCCESS]: (state, action) => {
            return {
                ...state,
                data: action.payload,
                pending: false,
            };
        },
        [GET_METADATA_EXPORT_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: action.payload,
            };
        },
        [UPDATE_METADATA_EXPORT_SUCCESS]: (state, action) => {
            return {
                ...state,
                data: { ...state.data, ...action.payload },
            };
        },
    },
    initialState,
);

export const getMetadataExportDetail =
    ({ projectId, id }) =>
    async (dispatch, getState) => {
        const { stage } = getState();

        return new Promise(async (resolve, reject) => {
            try {
                dispatch({ type: GET_METADATA_EXPORT_PENDING });

                const response = await getMetadataExportDetailAPI(stage.endpoint, projectId, id);

                dispatch({ type: GET_METADATA_EXPORT_SUCCESS, payload: response.data });
                resolve(response.data);
            } catch (error) {
                dispatch({ type: GET_METADATA_EXPORT_FAILURE, payload: error });
                reject(null);
            }
        });
    };

export const updateMetadataExport =
    ({ projectId, id, data }) =>
    async (dispatch, getState) => {
        const { stage } = getState();

        return new Promise(async (resolve, reject) => {
            try {
                const response = await updateMetadataExportAPI(stage.endpoint, projectId, id, data);

                dispatch({ type: UPDATE_METADATA_EXPORT_SUCCESS, payload: data });
                resolve(response.data);
            } catch (error) {
                reject(null);
            }
        });
    };

export const createMetadataExport =
    ({ projectId, params }) =>
    (dispatch, getState) => {
        const { stage } = getState();
        return new Promise(async (resolve, reject) => {
            try {
                const response = await createMetadataExportAPI(stage.endpoint, projectId, params);
                resolve(response.data);
            } catch (error) {
                reject();
            }
        });
    };

export const deleteMetadataExport =
    ({ projectId, id }) =>
    (dispatch, getState) => {
        const { stage } = getState();
        return new Promise(async (resolve, reject) => {
            try {
                const response = await deleteMetadataExportAPI(stage.endpoint, projectId, id);
                resolve(response.data);
            } catch (error) {
                reject();
            }
        });
    };
