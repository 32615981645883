import React from "react";
import { ListItem, ListItemText } from "@mzc-pdc/ui";
import theme from "@styles/theme";
import Skeleton from "@components_v2/skeleton/skeleton";

export const NavigatorSubMenuSkeleton = ({}) => {
    return (
        <ListItem
            sx={{
                mt: theme.spacing(2),
                mx: theme.spacing(1.5),
                p: theme.spacing(1.5),
            }}
        >
            <ListItemText>
                <Skeleton variant={"rounded"} animation={"wave"} height={20} width={208} />
            </ListItemText>
        </ListItem>
    );
};

export const NavigatorMenuSkeleton = () => {
    return (
        <ListItem
            sx={{
                mt: theme.spacing(2),
                mx: theme.spacing(1.5),
                p: theme.spacing(1.5),
            }}
        >
            <ListItemText>
                <Skeleton variant={"rounded"} animation={"wave"} height={20} width={208} />
            </ListItemText>
        </ListItem>
    );
};
