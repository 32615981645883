import { handleActions, createAction } from "redux-actions";
import { axios, axiosWithoutHeaders } from "../cores/axiosWrapper";

function getStreamsAPI(stageId, apiEndpoint, projectId, data) {
    return axios.get(`${apiEndpoint}/streams`, {
        params: data.params,
        headers: {
            stageId,
            projectId,
        },
    });
}

function deleteStreamAPI(stageId, apiEndpoint, projectId, streamId) {
    return axios.delete(`${apiEndpoint}/streams/${streamId}`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function putStreamsAPI(stageId, apiEndpoint, projectId, streamId, params) {
    return axios.put(`${apiEndpoint}/streams/${streamId}`, params, {
        headers: {
            stageId,
            projectId,
        },
    });
}

//action type
const STREAMS_PENDING = "STREAMS_PENDING";
const STREAMS_FAILURE = "STREAMS_FAILURE";
const GET_STREAMS_SUCCESS = "GET_STREAMS_SUCCESS";
const SET_STREAMS_PARAMS = "SET_STREAMS_PARAMS";
const SET_STREAMS_KEYWORD_TYPE = "SET_STREAMS_KEYWORD_TYPE";
const SET_STREAMS_KEYWORD_TYPE_FOR_WRITER = "SET_STREAMS_KEYWORD_TYPE_FOR_WRITER";
const SET_STREAMS_SEARCH_VALUE = "SET_STREAMS_SEARCH_VALUE";
const SET_SEARCH_VALUE_FOR_WRITER = "SET_SEARCH_VALUE_FOR_WRITER";
const RESET_STREAMS_PARAMS = "RESET_STREAMS_PARAMS";
const ACTIVE_STREAMS_BTN_ETC = "ACTIVE_STREAMS_BTN_ETC";
const DELETE_STREAMS_SUCCESS = "DELETE_STREAMS_SUCCESS";
const PUT_STREAMS_SUCCESS = "PUT_STREAMS_SUCCESS";

//reducer
const initialState = {
    pending: false,
    error: false,
    keywordType: "id",
    searchValue: "",
    params: {
        id: "",
        title: "",
        type: "",
        status: "", //ATTACH, DISATTACH
        beginDateTime: null,
        endDateTime: null,
        username: "",
        offset: 0,
        limit: 20,
    },
    data: [],
    totalCount: 0,
};

export default handleActions(
    {
        [STREAMS_PENDING]: (state) => {
            return {
                ...state,
                pending: true,
                error: false,
            };
        },
        [STREAMS_FAILURE]: (state) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [GET_STREAMS_SUCCESS]: (state, action) => {
            const { streams, totalCount } = action.payload.data;

            return {
                ...state,
                pending: false,
                data: streams,
                totalCount,
            };
        },
        [SET_STREAMS_PARAMS]: (state, action) => {
            return {
                ...state,
                params: {
                    ...state.params,
                    ...action.payload,
                },
            };
        },
        [SET_STREAMS_KEYWORD_TYPE]: (state, action) => {
            return {
                ...state,
                keywordType: action.payload,
            };
        },
        [SET_STREAMS_SEARCH_VALUE]: (state, action) => {
            return {
                ...state,
                searchValue: action.payload,
            };
        },
        [SET_STREAMS_KEYWORD_TYPE_FOR_WRITER]: (state, action) => {
            return {
                ...state,
                keywordTypeForWriter: action.payload,
            };
        },
        [SET_SEARCH_VALUE_FOR_WRITER]: (state, action) => {
            return {
                ...state,
                keywordValueForWriter: action.payload,
            };
        },
        [RESET_STREAMS_PARAMS]: (state) => {
            return {
                ...state,
                pending: false,
                error: false,
                keywordType: "id",
                searchValue: "",
                params: {
                    id: "",
                    name: "",
                    title: "",
                    type: "",
                    status: "", //ATTACH, DISATTACH
                    beginDateTime: null,
                    endDateTime: null,
                    username: "",
                    offset: 0,
                    limit: 20,
                },
            };
        },
        [ACTIVE_STREAMS_BTN_ETC]: (state, action) => {
            let _clone = state.data.map((v) => {
                return {
                    ...v,
                    active: v.id === action.payload ? !v.active : false,
                };
            });

            return {
                ...state,
                data: _clone,
            };
        },
        [DELETE_STREAMS_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
                data:
                    state.data &&
                    state.data.map((v) => {
                        return {
                            ...v,
                            isDeleted: v.isDeleted === true ? true : v.id === action.payload,
                        };
                    }),
            };
        },
        [PUT_STREAMS_SUCCESS]: (state) => {
            return {
                ...state,
                pending: false,
                error: false,
            };
        },
    },
    initialState,
);

export const setParams = createAction(SET_STREAMS_PARAMS);
export const setSearchValue = createAction(SET_STREAMS_SEARCH_VALUE);
export const setSearchValueForWriter = createAction(SET_SEARCH_VALUE_FOR_WRITER);
export const activeStream = createAction(ACTIVE_STREAMS_BTN_ETC);

export const deleteStream = (streamId, projectId, callback) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: STREAMS_PENDING });

    return deleteStreamAPI(stage.id, stage.endpoint, projectId, streamId)
        .then((response) => {
            dispatch({
                type: DELETE_STREAMS_SUCCESS,
                payload: streamId,
            });

            callback && callback();
        })
        .catch((error) => {
            dispatch({
                type: STREAMS_FAILURE,
                payload: error,
            });
        });
};
export const getStreams = (params) => (dispatch, getState) => {
    const { stage, project } = getState();
    dispatch({ type: STREAMS_PENDING });

    return getStreamsAPI(stage.id, stage.endpoint, project.id, params)
        .then((response) => {
            dispatch({
                type: GET_STREAMS_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: STREAMS_FAILURE,
                payload: error,
            });
        });
};
export const setKeywordType = (type) => (dispatch) => {
    dispatch({
        type: SET_STREAMS_KEYWORD_TYPE,
        payload: type,
    });

    dispatch({
        type: SET_STREAMS_SEARCH_VALUE,
        payload: "",
    });
};

export const setKeywordTypeForWriter = (type) => (dispatch) => {
    dispatch({
        type: SET_STREAMS_KEYWORD_TYPE_FOR_WRITER,
        payload: type,
    });

    dispatch({
        type: SET_SEARCH_VALUE_FOR_WRITER,
        payload: "",
    });
};

export const resetParams = () => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: RESET_STREAMS_PARAMS });

    // return getStreamsAPI(stage.id, streams.params).then(
    //     (response) => {
    //         dispatch({
    //             type : GET_STREAMS_SUCCESS,
    //             payload : response
    //         })
    //     }
    // ).catch(error => {
    //     dispatch({
    //         type: STREAMS_FAILURE,
    //         payload: error
    //     });
    // });
};

export const putStreams = (streamId, params, callback) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: STREAMS_PENDING });

    return putStreamsAPI(stage.id, stage.endpoint, project.id, streamId, params)
        .then((response) => {
            dispatch({ type: PUT_STREAMS_SUCCESS });
            callback(response.data);
        })
        .catch((error) => {
            dispatch({
                type: STREAMS_FAILURE,
                payload: error,
            });
        });
};
