import { handleActions, createAction } from "redux-actions";
import fp from "lodash/fp";

import compareVersions, { VERSION_CM_4557_HIDDEN_METADATA } from "../cores/version";

import HiddenMetadataApi from "./apis/hiddenMetadata";

import { axios, axiosWithoutHeaders } from "../cores/axiosWrapper";
import moment from "moment";

import queryString from "../cores/queryString";
import { LIST_VIEW_TYPE } from "../constants";

const RESET = "RESET";

const PEOPLE_CREATE_SUCCESS = "PEOPLE_CREATE_SUCCESS";
const PEOPLE_CREATE_FAILURE = "PEOPLE_CREATE_FAILURE";

const PEOPLE_DETAIL_FAILURE = "PEOPLE_DETAIL_FAILURE";
const PEOPLE_DETAIL_UPDATE_SUCCESS = "PEOPLE_DETAIL_UPDATE_SUCCESS";
const PEOPLE_DETAIL_GET_SUCCESS = "PEOPLE_DETAIL_GET_SUCCESS";

const PEOPLE_PENDING = "PEOPLE_PENDING";
const PEOPLE_LIST_FAILURE = "PEOPLE_LIST_FAILURE";
const UPDATE_ITEM_CANDIDATE = "UPDATE_ITEM_CANDIDATE";

const PEOPLE_LIST_UPDATE_STATE = "PEOPLE_LIST_UPDATE_STATE";
const PEOPLE_LIST_UPDATE_SEARCH = "PEOPLE_LIST_UPDATE_SEARCH";
const PEOPLE_LIST_RESET_SEARCH = "PEOPLE_LIST_RESET_SEARCH";

const PEOPLE_LIST_GET_LIST_SUCCESS = "PEOPLE_LIST_GET_LIST_SUCCESS";
const PEOPLE_LIST_DELETE_SUCCESS = "PEOPLE_LIST_DELETE_SUCCESS";

const PEOPLE_LIST_TOGGLE_ACTION_BUTTON = "PEOPLE_LIST_TOGGLE_ACTION_BUTTON";
const UPDATE_VALIDATION_ERROR = "UPDATE_VALIDATION_ERROR";

const UPDATE_INDEXED_FACE = "UPDATE_INDEXED_FACE";
const PEOPLE_SET_VALIDATION_ERROR = "PEOPLE_SET_VALIDATION_ERROR";

const PEOPLE_DETAIL_GET_HIDDEN_METADATA_VALUE_PENDING = "PEOPLE_DETAIL_GET_HIDDEN_METADATA_VALUE_PENDING";
const PEOPLE_DETAIL_GET_HIDDEN_METADATA_SET_EMPTY = "PEOPLE_DETAIL_GET_HIDDEN_METADATA_SET_EMPTY";
const PEOPLE_DETAIL_GET_HIDDEN_METADATA_VALUE_SUCCESS = "PEOPLE_DETAIL_GET_HIDDEN_METADATA_VALUE_SUCCESS";
const PEOPLE_DETAIL_GET_HIDDEN_METADATA_VALUE_FAILURE = "PEOPLE_DETAIL_GET_HIDDEN_METADATA_VALUE_FAILURE";

//reducer
const initialState = {
    pending: false,
    error: false,
    isCardView: true,
    item: null,
    itemCandidate: {
        name: "",
        status: "ACTIVE",
        alternativeNames: [],
        description: "",
        images: [],
        tags: [],
        categories: [],
        attributions: [],
        metadata: [],
        validationErrors: {},
    },
    items: [],
    totalCount: 0,
    search: {
        metadataSchemaFields: [],
    },
    validationErrors: {
        name: "required",
    },
    activeView: LIST_VIEW_TYPE.LIST,
};

export default handleActions(
    {
        [RESET]: (state, action) => {
            return {
                ...initialState,
                isCardView: state.isCardView,
            };
        },
        [UPDATE_INDEXED_FACE]: (state, action) => {
            return {
                ...state,
                item: {
                    ...state.item,
                    images: action.images,
                },
            };
        },
        [UPDATE_VALIDATION_ERROR]: (state, action) => {
            const { key, error } = action.payload;

            if (error) {
                return {
                    ...state,
                    itemCandidate: {
                        ...state.itemCandidate,
                        validationErrors: {
                            ...state.itemCandidate.validationErrors,
                            [key]: error,
                        },
                    },
                };
            }

            const validationErrors = state.itemCandidate.validationErrors;
            if (validationErrors[key]) {
                delete validationErrors[key];
            }
            return {
                ...state,
                itemCandidate: {
                    ...state.itemCandidate,
                    validationErrors,
                },
            };
        },
        [UPDATE_ITEM_CANDIDATE]: (state, action) => {
            return {
                ...state,
                itemCandidate: {
                    ...state.itemCandidate,
                    ...action.payload,
                },
            };
        },
        [PEOPLE_LIST_UPDATE_STATE]: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
        [PEOPLE_PENDING]: (state) => {
            return {
                ...state,
                pending: true,
                error: false,
            };
        },
        [PEOPLE_DETAIL_GET_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            return {
                ...state,
                pending: false,
                error: false,
                item: data,
            };
        },
        [PEOPLE_CREATE_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            return {
                ...state,
                pending: false,
                error: false,
                item: data,
            };
        },
        [PEOPLE_DETAIL_UPDATE_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                pending: false,
                error: false,
                item: data,
            };
        },
        [PEOPLE_CREATE_FAILURE]: (state) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [PEOPLE_DETAIL_FAILURE]: (state) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [PEOPLE_LIST_FAILURE]: (state) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [PEOPLE_LIST_GET_LIST_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                pending: false,
                error: false,
                items: data.people,
                totalCount: data.totalCount,
            };
        },
        [PEOPLE_LIST_UPDATE_SEARCH]: (state, action) => {
            return {
                ...state,
                search: {
                    ...state.search,
                    ...action.payload,
                },
            };
        },
        [PEOPLE_LIST_RESET_SEARCH]: (state) => {
            const metadataFields = [];
            Object.keys(state.search.metadataSchemaFields).map((field) => {
                metadataFields[field] = {
                    type: state.search.metadataSchemaFields[field].type,
                    name: state.search.metadataSchemaFields[field].name,
                    value: "",
                };
            });
            const search = state.search.isQuickSearch
                ? {
                      ...state.search,
                      keyword: initialState.search.keyword,
                  }
                : {
                      ...initialState.search,
                      isQuickSearch: state.search.isQuickSearch,
                      keyword: state.search.keyword,
                      metadataSchemaFields: metadataFields,
                  };

            return {
                ...state,
                search,
            };
        },
        [PEOPLE_LIST_TOGGLE_ACTION_BUTTON]: (state, action) => {
            return {
                ...state,
                items:
                    state.items &&
                    state.items.map((v) => {
                        return {
                            ...v,
                            isShowActionButton: v.id === action.payload ? !v.isShowActionButton : false,
                        };
                    }),
            };
        },
        [PEOPLE_LIST_DELETE_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
                items: state.items.filter((item) => item.id !== action.payload),
            };
        },
        [PEOPLE_SET_VALIDATION_ERROR]: (state, action) => {
            const { key, error } = action.payload;

            if (error) {
                return {
                    ...state,
                    validationErrors: {
                        ...state.validationErrors,
                        [key]: error,
                    },
                };
            }

            const validationErrors = state.validationErrors;
            if (validationErrors[key]) {
                delete validationErrors[key];
            }

            return {
                ...state,
                validationErrors,
            };
        },
        [PEOPLE_DETAIL_GET_HIDDEN_METADATA_VALUE_PENDING]: (state, action) => {
            const { metadataSchemaId, metadataFieldId } = action.payload;

            const metadataSchemas = refineMetadata(state.item.metadataSchemas, metadataSchemaId, metadataFieldId, {
                pending: true,
                error: false,
            });

            return {
                ...state,
                item: {
                    ...state.item,
                    metadataSchemas,
                },
            };
        },
        [PEOPLE_DETAIL_GET_HIDDEN_METADATA_SET_EMPTY]: (state, action) => {
            const { metadataSchemaId, metadataFieldId } = action.payload;
            const metadataSchemas = refineMetadata(state.item.metadataSchemas, metadataSchemaId, metadataFieldId, {
                pending: false,
                error: false,
                value: "",
                values: [],
                isCheckHiddenValue: true,
            });

            return {
                ...state,
                item: {
                    ...state.item,
                    metadataSchemas,
                },
            };
        },
        [PEOPLE_DETAIL_GET_HIDDEN_METADATA_VALUE_SUCCESS]: (state, action) => {
            const { metadataSchemaId, metadataFieldId, response } = action.payload;

            const metadataSchemas = refineMetadata(state.item.metadataSchemas, metadataSchemaId, metadataFieldId, {
                pending: false,
                error: false,
                value: response.data.value,
                values: response.data.values,
                isCheckHiddenValue: true,
            });

            return {
                ...state,
                item: {
                    ...state.item,
                    metadataSchemas,
                },
            };
        },
        [PEOPLE_DETAIL_GET_HIDDEN_METADATA_VALUE_FAILURE]: (state, action) => {
            const { metadataSchemaId, metadataFieldId, error } = action.payload;

            const metadataSchemas = refineMetadata(state.item.metadataSchemas, metadataSchemaId, metadataFieldId, {
                pending: false,
                error,
                isCheckHiddenValue: false,
            });

            return {
                ...state,
                item: {
                    ...state.item,
                    metadataSchemas,
                },
            };
        },
    },
    initialState,
);

function createPeopleAPI(stageId, apiEndpoint, projectId, data) {
    const tags =
        data.tags &&
        data.tags.map((v) => {
            return { value: v };
        });

    return axios.post(
        `${apiEndpoint}/people`,
        { ...data, tags },
        {
            headers: {
                stageId,
                projectId,
            },
        },
    );
}

function getPeopleAPI(stageId, apiEndpoint, projectId, id) {
    return axios.get(`${apiEndpoint}/people/${id}`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function updatePeopleAPI(stageId, apiEndpoint, projectId, id, version, data) {
    return axios.patch(`${apiEndpoint}/people/${id}`, data, {
        params: {
            version,
        },
        headers: {
            stageId,
            projectId,
        },
    });
}

function updateBulkPeopleAPI(stageId, apiEndpoint, projectId, data) {
    return axios.patch(`${apiEndpoint}/people/bulk`, data, {
        params: {},
        headers: {
            stageId,
            projectId,
        },
    });
}

export function getPeopleListAPI(stageId, apiEndpoint, projectId, query) {
    const queries = {
        offset: query.offset || 0,
        limit: query.limit || 20,
    };

    // console.log("getPeopleListAPI", query);

    if (query.isQuickSearch) {
        if (query.keyword) queries.q = query.keyword;
        if (query?.activeView?.toLowerCase() === LIST_VIEW_TYPE.HIERARCHY) {
            if (query.categoryGroupId) {
                queries.categoryGroupId = query.categoryGroupId;
                if (query.isNotCategoryIds) {
                    queries.isNotCategoryIds = query.isNotCategoryIds;
                    queries.categoryIds = "-1";
                }
            } else {
                if (query.categoryIds && query.categoryIds.length > 0)
                    queries.categoryIds = query.categoryIds
                        .map((c) => {
                            if (typeof c === "object") return c.id;
                            return c;
                        })
                        .join(",");
                if (query.isNotCategoryIds) {
                    queries.isNotCategoryIds = query.isNotCategoryIds;
                    queries.categoryIds = "-1";
                }
                if (query.isIncludeChildCategory) queries.isIncludeChildCategory = query.isIncludeChildCategory;
            }
        }
    } else {
        if (query.name) queries.name = query.name;
        if (query.id) queries.id = query.id;
        if (query.ids) queries.ids = query.ids;
        if (query.description) queries.description = query.description;

        if (query.creator) queries.creator = query.creator;
        if (query.status) queries.status = query.status;
        if (query.alternativeName) queries.alternativeName = query.alternativeName;
        if (query.categoryGroupId) {
            queries.categoryGroupId = query.categoryGroupId;
        } else {
            if (query.categoryIds && query.categoryIds.length > 0)
                queries.categoryIds = query.categoryIds
                    .map((c) => {
                        if (typeof c === "object") return c.id;
                        return c;
                    })
                    .join(",");
            if (query.isNotCategoryIds) {
                queries.isNotCategoryIds = query.isNotCategoryIds;
                queries.categoryIds = "-1";
            }
            if (query.isIncludeChildCategory) queries.isIncludeChildCategory = query.isIncludeChildCategory;
        }

        if (query.tags) queries.tags = "";
        if (query.tags !== undefined && query.tags.length > 0) {
            query.tags.forEach((tag, index) => {
                index === 0 ? tag : (tag = `,${tag}`);
                queries.tags += tag;
            });
        }

        if (query.metadataSchemaFields) {
            const metadataFields = [];
            Object.keys(query.metadataSchemaFields).map((field) => {
                if (query.metadataSchemaFields[field].value) {
                    const fieldId = field.replace("metadata-schema-field-", "");
                    metadataFields.push({ id: fieldId, keyword: query.metadataSchemaFields[field].value });
                }
            });
            queries.metadataSchemaFields = JSON.stringify(metadataFields);
        }

        if (query.startAt && query.endAt) {
            queries.createdAt = `${query.startAt}~${query.endAt}`;
        }
    }

    return axios.get(`${apiEndpoint}/people?${queryString.stringify(queries)}`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function deletePeopleAPI(stageId, apiEndpoint, projectId, id, version) {
    return axios.delete(`${apiEndpoint}/people/${id}`, {
        params: {
            version,
        },
        headers: {
            stageId,
            projectId,
        },
    });
}
export const reset = createAction(RESET);
export const updateCandidate = createAction(UPDATE_ITEM_CANDIDATE);
export const updateState = createAction(PEOPLE_LIST_UPDATE_STATE);
export const updateSearch = createAction(PEOPLE_LIST_UPDATE_SEARCH);
export const resetSearch = createAction(PEOPLE_LIST_RESET_SEARCH);
export const toggleActionButton = createAction(PEOPLE_LIST_TOGGLE_ACTION_BUTTON);
export const updateValidationError = createAction(UPDATE_VALIDATION_ERROR);
export const setValidationError = createAction(PEOPLE_SET_VALIDATION_ERROR);

export const createPeople = (data) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: PEOPLE_PENDING });

    return createPeopleAPI(stage.id, stage.endpoint, project.id, data)
        .then((response) => {
            dispatch({
                type: PEOPLE_CREATE_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: PEOPLE_CREATE_FAILURE,
                payload: error,
            });
        });
};

export const getPeople = (id) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: PEOPLE_PENDING });

    return getPeopleAPI(stage.id, stage.endpoint, project.id, id)
        .then((response) => {
            dispatch({
                type: PEOPLE_DETAIL_GET_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: PEOPLE_DETAIL_FAILURE,
                payload: error,
            });
        });
};

export const updateMetadata = (id, version, metadatas) => (dispatch, getState) => {
    const { stage, project } = getState();

    let data;
    const compVersion = compareVersions(
        VERSION_CM_4557_HIDDEN_METADATA,
        // "1.0.11"
        stage.version,
    );

    if (compVersion <= 0) {
        const metadata = metadatas.find((v) => v.isModified);

        if (metadata) {
            data = {
                metadata: {
                    metadataFieldId: metadata.metadataFieldId,
                    value: metadata.value,
                    values: metadata.values,
                },
            };
        }
    } else {
        // 이전버전
        data = { metadatas };
    }

    if (!data) return;

    updatePeopleAPI(stage.id, stage.endpoint, project.id, id, version, data)
        .then((response) => {
            dispatch({
                type: PEOPLE_DETAIL_UPDATE_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: PEOPLE_DETAIL_FAILURE,
                payload: error,
            });
        });
};

export const updatePeople = (id, version, data) => (dispatch, getState) => {
    const { stage, project } = getState();

    // TODO : metaData 즉시 적용 시, 고려
    dispatch({ type: PEOPLE_PENDING });

    updatePeopleAPI(stage.id, stage.endpoint, project.id, id, version, data)
        .then((response) => {
            dispatch({
                type: PEOPLE_DETAIL_UPDATE_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: PEOPLE_DETAIL_FAILURE,
                payload: error,
            });
        });
};

export const updateBulkAsync = (data) => (dispatch, getState) => {
    const { stage, project } = getState();

    return updateBulkPeopleAPI(stage.id, stage.endpoint, project.id, data)
        .then((response) => {
            console.debug("updateBulkPeopleAPI response", response);
        })
        .catch((error) => {
            console.error("updateBulkPeopleAPI error", error);
        });
};

export const getPeopleList =
    (projectId = null, search = null) =>
    (dispatch, getState) => {
        const { stage, project, people } = getState();

        const projectId = projectId || project.id;
        const search_ = search || people.search;

        dispatch({ type: PEOPLE_PENDING });

        return getPeopleListAPI(stage.id, stage.endpoint, projectId, search_)
            .then((response) => {
                dispatch({
                    type: PEOPLE_LIST_GET_LIST_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: PEOPLE_LIST_FAILURE,
                    payload: error,
                });
            });
    };

export const deletePeople = (id, version) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: PEOPLE_PENDING });
    return new Promise((resolve, reject) => {
        deletePeopleAPI(stage.id, stage.endpoint, project.id, id, version)
            .then((response) => {
                dispatch({
                    type: PEOPLE_LIST_DELETE_SUCCESS,
                    payload: id,
                });
                resolve(response);
            })
            .catch((error) => {
                dispatch({
                    type: PEOPLE_LIST_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const updateIndexedFace = (image) => (dispatch, getState) => {
    const { people } = getState();
    if (!people.item || !people.item.images || people.item.images.length === 0) return;

    const imageToUpdate = people.item.images.find((i) => i.id === image.id);
    if (!imageToUpdate) return;

    imageToUpdate.faceIndex = image.faceIndex;

    dispatch({
        type: UPDATE_INDEXED_FACE,
        images: people.item.images,
    });
};

export const getHiddenMetadataValue = (id, metadataSchemaId, metadataFieldId) => (dispatch, getState) => {
    const { stage, project } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;

    dispatch({ type: PEOPLE_DETAIL_GET_HIDDEN_METADATA_VALUE_PENDING, payload: { metadataSchemaId, metadataFieldId } });

    return new Promise((resolve, reject) => {
        HiddenMetadataApi.getHiddenMetadata(stageId, apiEndpoint, projectId, "PEOPLE", id, metadataFieldId)
            .then((response) => {
                dispatch({
                    type: PEOPLE_DETAIL_GET_HIDDEN_METADATA_VALUE_SUCCESS,
                    payload: {
                        response,
                        metadataSchemaId,
                        metadataFieldId,
                    },
                });
                resolve(response);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    dispatch({
                        type: PEOPLE_DETAIL_GET_HIDDEN_METADATA_VALUE_FAILURE,
                        payload: {
                            error,
                            metadataSchemaId,
                            metadataFieldId,
                        },
                    });
                    reject(error);
                } else if (error.response.status === 404) {
                    dispatch({
                        type: PEOPLE_DETAIL_GET_HIDDEN_METADATA_SET_EMPTY,
                        payload: { metadataSchemaId, metadataFieldId },
                    });
                    resolve();
                }
            });
    });
};

const refineMetadata = (target, metadataSchemaId, metadataFieldId, data) => {
    return target.map((v) => {
        if (v.id === metadataSchemaId) {
            const fields = v.fields.map((f) => {
                if (f.id === metadataFieldId) {
                    return {
                        ...f,
                        ...data,
                    };
                } else {
                    return f;
                }
            });
            return {
                ...v,
                fields,
            };
        } else {
            return v;
        }
    });
};
