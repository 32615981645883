import { handleActions, createAction } from "redux-actions";
//action type
const INCREASE_REQUEST_COUNT = "INCREASE_REQUEST_COUNT";
const STAGE_POPUP_IS_ENABLED = "STAGE_POPUP_IS_ENABLED";
const STAGE_POPUP_IS_DEFAULT = "STAGE_POPUP_IS_DEFAULT";
const SET_PREV_PATH = "SET_PREV_PATH";
const SET_ERROR_STATUS = "SET_ERROR_STATUS";
const RESET_ERROR_STATUS = "RESET_ERROR_STATUS";
const ADD_PAUSED_POLLING_APIS = "ADD_PAUSED_POLLING_APIS";
const REMOVE_PAUSED_POLLING_APIS = "REMOVE_PAUSED_POLLING_APIS";

//reducer
const initialState = {
    count: 0,
    stagePopup: {
        enabled: true,
        isDefault: false,
    },
    prevPath: "",
    errorStatus: null,
    error: null,
    responseURL: "",
    settings: {},
};

export default handleActions(
    {
        [INCREASE_REQUEST_COUNT]: (state) => {
            return {
                ...state,
                count: state.count + 1,
            };
        },
        [STAGE_POPUP_IS_ENABLED]: (state, action) => {
            return {
                ...state,
                stagePopup: {
                    ...state.stagePopup,
                    enabled: action.payload,
                },
            };
        },
        [STAGE_POPUP_IS_DEFAULT]: (state, action) => {
            return {
                ...state,
                stagePopup: {
                    ...state.stagePopup,
                    isDefault: !state.stagePopup.isDefault,
                },
            };
        },
        [SET_PREV_PATH]: (state, action) => {
            return {
                ...state,
                prevPath: action.payload,
            };
        },
        [SET_ERROR_STATUS]: (state, action) => {
            let { status, responseURL, error } = action.payload;

            return {
                ...state,
                error,
                errorStatus: status,
                responseURL,
            };
        },
        [RESET_ERROR_STATUS]: (state) => {
            return {
                ...state,
                error: null,
                errorStatus: null,
                responseURL: "",
            };
        },
    },
    initialState,
);
export const resetErrorStatus = createAction(RESET_ERROR_STATUS);
export const IncreaseRequestCount = createAction(INCREASE_REQUEST_COUNT);
export const stagePopupIsEnabled = createAction(STAGE_POPUP_IS_ENABLED);
export const stagePopupIsDefault = createAction(STAGE_POPUP_IS_DEFAULT);
export const setErrorStatus = createAction(SET_ERROR_STATUS);
export const setPrevPath = createAction(SET_PREV_PATH);
