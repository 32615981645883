import { MENU_LIST as MENU_OPTIONS, SHOW_MENU_OPTIONS } from "@constants";
import compareVersions from "@cores/version";

export const ENTRY_SCREEN = {
    DASHBOARD: {
        label: "Dashboard",
        values: [{ value: "Dashboard", path: "/dashboard", label: "Dashboard" }],
    },
    STORAGE: {
        label: "Storage",
        values: [{ value: "Storage", path: "/storage/storage", label: "Storage" }],
    },
    CREATE_ASSETS: {
        label: "Create Assets",
        values: [{ value: "Create Assets", path: "/assets/assets/create", label: "Create Assets" }],
    },
    CONTENTS: {
        label: "Contents",
        values: [
            {
                value: "Create Videos",
                path: "/contents/videos/create",
                label: "Create Videos",
            },
            {
                value: "Create People",
                path: "/contents/people/create",
                label: "Create People",
            },
            {
                value: "Create Music",
                path: "/contents/music/create",
                label: "Create Music",
            },
            {
                value: "Create Photo",
                path: "/contents/photos/create",
                label: "Create Photo",
            },
        ],
    },
};

export const ENTRY_SCREEN_V2 = {
    DASHBOARD: {
        label: "Dashboard",
        values: [{ value: "Dashboard", path: "/dashboard", label: "Dashboard" }],
    },
    STORAGE: {
        label: "Storage",
        values: [{ value: "Storage", path: "/storage/storage", label: "Storage" }],
    },
    CREATE_ASSETS: {
        label: "Assets",
        values: [
            { value: "Create Assets", path: "/assets/assets", label: "Assets" },
            { value: "Collections", path: "/assets/collections", label: "Collections" },
        ],
    },
    CONTENTS: {
        label: "Contents",
        values: [
            {
                value: "Create Videos",
                path: "/contents/videos/create",
                label: "Create Videos",
            },
            {
                value: "Create People",
                path: "/contents/people/create",
                label: "Create People",
            },
            {
                value: "Create Music",
                path: "/contents/music/create",
                label: "Create Music",
            },
            {
                value: "Create Photo",
                path: "/contents/photos/create",
                label: "Create Photo",
            },
        ],
    },
};

export const MENU_OPTION_TO_ENTRY_SCREEN = {
    [MENU_OPTIONS.DASHBOARD]: "Dashboard",
    [MENU_OPTIONS.STORAGES]: "Storage",
    [MENU_OPTIONS.ASSETS]: "Create Assets",
    [MENU_OPTIONS.COLLECTIONS]: "Collections",
    [MENU_OPTIONS.VIDEOS]: "Create Videos",
    [MENU_OPTIONS.PEOPLE]: "Create People",
    [MENU_OPTIONS.MUSIC]: "Create Music",
    [MENU_OPTIONS.PHOTO]: "Create Photo",
};

export const generateMenuOptions = (stageVersion) => {
    const menuOptions = [];

    SHOW_MENU_OPTIONS.filter((option) => {
        if (option.minVersion && compareVersions(stageVersion, option.minVersion) < 0) return false;
        if (option.maxVersion && compareVersions(stageVersion, option.maxVersion) >= 0) return false;
        return true;
    }).forEach((option) => {
        if (option.mainMenuName) {
            const { mainMenuName, ...restMenuOptions } = option;
            const parsedRestMenuOptions = {
                id: restMenuOptions.id,
                label: restMenuOptions.name,
                value: restMenuOptions.value,
            };

            const existingOption = menuOptions.find((menuOption) => menuOption.id === mainMenuName.toUpperCase());

            if (existingOption) {
                existingOption.values.push(parsedRestMenuOptions);
            } else {
                const newOption = {
                    id: mainMenuName.toUpperCase(),
                    label: mainMenuName,
                    values: [parsedRestMenuOptions],
                };
                menuOptions.push(newOption);
            }
        } else {
            menuOptions.push({ id: option.id, label: option.name, value: option.value });
        }
    });

    return menuOptions;
};

export const MAX_ATTACHMENT_COUNT = 5;
export const MAX_ATTACHMENT_SIZE = 1024 * 1024 * 1024 * 1024 * 5;
