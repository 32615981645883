import {useState, useCallback, useRef, useEffect} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { deleteToken } from "@modules/token";
import { cancelTranscodeAllSync } from "@modules/upload";
import * as jobStatus from "@cores/jobStatus";
import { sessionKeeper } from "@cores/sessionKeeper";
import config from "@config/config";
import { JOB_ID_LCOAL_STORAGE_KEY } from "@constants";

import theme from "@styles/theme";
import { IconButton, Grid, Typography, Popover } from "@mzc-pdc/ui";
import { IconOpenInNew } from "@mzc-cloudplex/icons";

import { Email, Name, ProfileItem, ToggleDivider, ToggleItem, ToggleList } from "./profile-menu.styled";
import { UserProfile } from "@features/user-profile/user-profile";
import DialogCancelWarning from "@features/dialog/dialog-cancel-warning";
import {isLocal} from "@cores/enviromentExtension";

export const ProfileMenu = ({ headerTheme }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [modal, setModal] = useState("");
    const [warningMessage, setWarningMessage] = useState("");
    const [runningJobs, setRunningJobs] = useState([]);

    const user = useSelector(({ user }) => user);
    const upload = useSelector(({ upload }) => upload);

    const [profileModalActive, setProfileModalActive] = useState(false);

    const anchorRef = useRef(null);

    const handleToggle = useCallback(() => {
        setProfileModalActive((prevOpen) => !prevOpen);
    }, []);

    const handleClose = useCallback((event) => {
        setProfileModalActive(false);
    }, []);

    const onHandleClickProfile = useCallback(() => {
        window.open(`https://myaccount.megazone.com/info`);
    }, []);
    const isPopsUser = user?.data?.issuer === "megazone.com";
    const logOutPops = (value) => {
        setModal("");
        sessionKeeper.reset();
        if (value) {
            const redirectUrl = `${window.location.origin}/welcome`;
            window.location.href = `${config.mz_pops.oauth_host}/logout?next=${redirectUrl}`;
        }
        dispatch(deleteToken());
    };

    useEffect(() => {
        const jobs = (upload.jobs.data ?? []).filter(
            (v) =>
                v.status === jobStatus.values.READY ||
                v.status === jobStatus.values.INGESTING
        );
        setRunningJobs(jobs);
    }, [upload.jobs.data]);

    const proceedLogout = useCallback(() => {
        const domain = isLocal ? "localhost" : "megazone.io";
        Cookies.remove("beforeLoginUrl", { domain });
        localStorage.removeItem(JOB_ID_LCOAL_STORAGE_KEY);
        if (isPopsUser) {
            setModal("logout");
            setWarningMessage(t(`common::msg::${"Would you like to log out from the account service as well?"}`));
        } else {
            sessionKeeper.reset();
            dispatch(deleteToken());
        }
    }, [isPopsUser]);

    const onHandleClickLogout = useCallback(() => {
        if (runningJobs?.length > 0) {
            setModal("cancelJobs");
            setWarningMessage(t(`common::msg::${"There is work in progress. Would you really go out?"}`));
            return;
        }
        proceedLogout();
    }, [runningJobs, proceedLogout]);

    return (
        <Grid>
            <IconButton size={"large"} circled ref={anchorRef} onClick={handleToggle}>
                <UserProfile profileImageUrl={user?.data?.profileImageUrl} headerTheme={headerTheme} size={24} />
            </IconButton>
            <Popover
                open={profileModalActive}
                anchorEl={anchorRef.current}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                PaperProps={{
                    style: { width: "280px" },
                }}
            >
                <ToggleList>
                    <ProfileItem>
                        <Typography variant={"caption"} color={theme.palette.secondary[600]}>
                            <strong>{t(`common::label::Account`, `Account`)}</strong>
                        </Typography>
                        <Grid container alignItems={`center`} sx={{ marginTop: `12px` }} flexWrap={"nowrap"}>
                            <Grid item sx={{ marginRight: `12px` }}>
                                <UserProfile profileImageUrl={user?.data?.profileImageUrl} size={44} />
                            </Grid>
                            {isPopsUser ? (
                                <Grid item>
                                    <Name>{user?.data?.displayName}</Name>
                                    <Email>{user?.data?.username}</Email>
                                </Grid>
                            ) : (
                                <Grid item>
                                    <Name sx={{ whiteSpace: `normal` }}>{user?.data?.id}</Name>
                                </Grid>
                            )}
                        </Grid>
                    </ProfileItem>
                    {isPopsUser && (
                        <>
                            <ToggleDivider />
                            <ToggleItem onClick={onHandleClickProfile}>
                                <strong>{t(`common::label::Manage Account`, `Manage Account`)}</strong>
                                <IconOpenInNew size={16} />
                            </ToggleItem>
                        </>
                    )}
                    <ToggleDivider />
                    <ToggleItem onClick={onHandleClickLogout}>
                        {/*onClick={handleLogout}*/}
                        <strong> {t(`common::label::Log out`, `Log out`)}</strong>
                    </ToggleItem>
                </ToggleList>
            </Popover>
            {modal === "cancelJobs" && (
                <DialogCancelWarning
                    onClose={(value) => {
                        if (value) {
                            dispatch(cancelTranscodeAllSync(runningJobs));
                        }
                        setModal("");
                        proceedLogout();
                    }}
                    message={warningMessage}
                />
            )}
            {modal === "logout" && (
                <DialogCancelWarning onClose={(value) => logOutPops(value)} message={warningMessage} />
            )}
        </Grid>
    );
};
export default ProfileMenu;
