import { handleActions, createAction } from "redux-actions";
import { JOB_PANEL_TABS } from "@constants";

const RIGHT_SIDE_BAR_SET_INACTIVE = "RIGHT_SIDE_BAR_SET_INACTIVE";
const RIGHT_SIDE_BAR_TOGGLE_ACTIVE = "RIGHT_SIDE_BAR_TOGGLE_ACTIVE";
const CHANGE_RIGHT_SIDE_BAR_ACTIVE_TAB = "CHANGE_RIGHT_SIDE_BAR_ACTIVE_TAB";
const RIGHT_SIDE_BAR_IS_ADMIN_SETTING = "RIGHT_SIDE_BAR_IS_ADMIN_SETTING";

const initialState = {
    type: "",
    activeTab: JOB_PANEL_TABS.ASSET_JOBS,
    active: false,
};

export default handleActions(
    {
        [RIGHT_SIDE_BAR_SET_INACTIVE]: (state) => {
            if (state.active) {
                return {
                    ...state,
                    active: false,
                };
            }
            return state;
        },
        [RIGHT_SIDE_BAR_TOGGLE_ACTIVE]: (state, action) => {
            const { type, activeTab, activeFlag } = action.payload;
            const active = activeFlag ? activeFlag : state.active ? false : true;
            return {
                ...state,
                type,
                active,
                activeTab: activeTab ? activeTab : state.activeTab,
            };
        },
        [CHANGE_RIGHT_SIDE_BAR_ACTIVE_TAB]: (state, action) => {
            const { activeTab } = action.payload;
            return {
                ...state,
                activeTab,
            };
        },
        [RIGHT_SIDE_BAR_IS_ADMIN_SETTING]: (state, action) => {
            const { isAdminSetting } = action.payload;
            return {
                ...state,
                isAdminSetting,
                activeTab: isAdminSetting ? JOB_PANEL_TABS.DOWNLOAD : state.activeTab,
            };
        },
    },
    initialState,
);

export const inActive = createAction(RIGHT_SIDE_BAR_SET_INACTIVE);
export const toggleActive = createAction(RIGHT_SIDE_BAR_TOGGLE_ACTIVE);
export const changeActiveTab = createAction(CHANGE_RIGHT_SIDE_BAR_ACTIVE_TAB);
export const changeIsAdminSetting = createAction(RIGHT_SIDE_BAR_IS_ADMIN_SETTING);
