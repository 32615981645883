import { handleActions, createAction } from "redux-actions";
import { axios, axiosWithoutHeaders } from "../cores/axiosWrapper";
import Polling from "../cores/polling";

function patchProjectUserAPI(stageId, apiEndpoint, projectId, userId, body) {
    const headers = {
        stageId,
        projectId,
    };

    return axios.patch(`${apiEndpoint}/projects/${projectId}/members/${userId}`, body, {
        headers,
    });
}

function patchProjectTeamAPI(stageId, apiEndpoint, projectId, teamId, body) {
    const headers = {
        stageId,
        projectId,
    };

    return axios.patch(`${apiEndpoint}/projects/${projectId}/teams/${teamId}`, body, {
        headers,
    });
}

function assignUserAPI(stageId, apiEndpoint, projectId, userId) {
    return axios.post(`${apiEndpoint}/projects/${projectId}/members/${userId}/assign`, "", {
        headers: {
            stageId,
        },
    });
}

function assignTeamAPI(stageId, apiEndpoint, projectId, teamId) {
    return axios.post(`${apiEndpoint}/projects/${projectId}/teams/${teamId}/assign`, "", {
        headers: {
            stageId,
        },
    });
}

function unassignUserAPI(stageId, apiEndpoint, projectId, userId) {
    return axios.post(`${apiEndpoint}/projects/${projectId}/members/${userId}/unassign`, "", {
        headers: {
            stageId,
        },
    });
}

function unassignTeamV2API(stageId, apiEndpoint, projectId, teamId) {
    return axios.delete(`${apiEndpoint}/v3/managements/projects/${projectId}/teams/${teamId}`, "", {
        headers: {
            stageId,
        },
    });
}

function assignUserV2API(stageId, apiEndpoint, projectId, params) {
    return axios.post(`${apiEndpoint}/v3/managements/projects/${projectId}/members`, params, {
        headers: {
            stageId,
        },
    });
}

function assignTeamV2API(stageId, apiEndpoint, projectId, params) {
    return axios.post(`${apiEndpoint}/v3/managements/projects/${projectId}/teams`, params, {
        headers: {
            stageId,
        },
    });
}

function unassignUserV2API(stageId, apiEndpoint, projectId, userId) {
    return axios.delete(`${apiEndpoint}/v3/managements/projects/${projectId}/members/${userId}`, "", {
        headers: {
            stageId,
        },
    });
}

function unassignTeamAPI(stageId, apiEndpoint, projectId, teamId) {
    return axios.post(`${apiEndpoint}/projects/${projectId}/teams/${teamId}/unassign`, "", {
        headers: {
            stageId,
        },
    });
}

function createProjectAPI(stageId, apiEndpoint, params) {
    return axios.post(`${apiEndpoint}/projects`, params, {
        headers: {
            stageId,
        },
    });
}

export function getProjectsAPI(stageId, apiEndpoint, params) {
    const queries = {
        offset: params.offset * params.limit,
        limit: params.limit,
    };

    if (params.keyword) {
        queries[params.keywordType] = params.keyword;
    }

    if (params.scope) {
        queries.scope = params.scope;
    }

    if (params.isIntegratedSearchable) {
        queries.isIntegratedSearchable = params.isIntegratedSearchable;
    }

    return axios.get(`${apiEndpoint}/projects`, {
        params: queries,
        headers: {
            stageId,
        },
    });
}

function getProjectAPI(stageId, apiEndpoint, projectId, id) {
    return axios.get(`${apiEndpoint}/projects/${id}`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function getProjectV2API(stageId, apiEndpoint, projectId, id) {
    return axios.get(`${apiEndpoint}/v3/managements/projects/${id}`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function updateProjectAPI(stageId, apiEndpoint, id, data) {
    return axios.patch(`${apiEndpoint}/projects/${id}`, data, {
        headers: {
            stageId,
        },
    });
}

function deleteProjectAPI(stageId, apiEndpoint, id) {
    return axios.delete(`${apiEndpoint}/projects/${id}`, {
        headers: {
            stageId,
        },
    });
}

function getProjectUsersAPI(stageId, apiEndpoint, projectId, id, params, showAll) {
    const queries = {
        offset: params.offset * params.limit,
        limit: params.limit,
    };

    if (params.keyword) {
        queries[params.keywordType] = params.keyword;
    }

    const headers = {
        stageId,
    };

    if (showAll) {
        return axios.get(`${apiEndpoint}/projects/${id}/assignable-members`, {
            params: queries,
            headers,
        });
    } else {
        return axios.get(`${apiEndpoint}/projects/${id}/assigned-members`, {
            params: queries,
            headers,
        });
    }
}

function getProjectUsersV2API(stageId, apiEndpoint, projectId, id, params, showAll) {
    const queries = {
        limit: params.limit,
    };

    if (params.keyword) queries[params.keywordType] = params.keyword;
    if (params.nextToken) queries.nextToken = params.nextToken;

    const headers = {
        stageId,
    };

    if (showAll) {
        return axios.get(`${apiEndpoint}/v3/managements/projects/${id}/assignable-members`, {
            params: queries,
            headers,
        });
    } else {
        return axios.get(`${apiEndpoint}/v3/managements/projects/${id}/members`, {
            params: queries,
            headers,
        });
    }
}

function getProjectTeamsV2API(stageId, apiEndpoint, projectId, id, params, showAll) {
    const queries = {
        limit: params.limit,
    };

    if (params.keyword) queries[params.keywordType] = params.keyword;
    if (params.nextToken) queries.nextToken = params.nextToken;

    const headers = {
        stageId,
    };

    if (showAll) {
        return axios.get(`${apiEndpoint}/v3/managements/projects/${id}/assignable-teams`, {
            params: queries,
            headers,
        });
    } else {
        return axios.get(`${apiEndpoint}/v3/managements/projects/${id}/teams`, {
            params: queries,
            headers,
        });
    }
}

function getProjectTeamsAPI(stageId, apiEndpoint, projectId, id, params, showAll) {
    const queries = {
        limit: params.limit,
    };

    if (params.keyword) queries[params.keywordType] = params.keyword;
    if (params.nextToken) queries.nextToken = params.nextToken;

    const headers = {
        stageId,
    };
    if (projectId) headers.projectId = projectId;

    if (showAll) {
        return axios.get(`${apiEndpoint}/projects/${id}/assignable-teams`, {
            params: queries,
            headers,
        });
    } else {
        return axios.get(`${apiEndpoint}/projects/${id}/assigned-teams`, {
            params: queries,
            headers,
        });
    }
}

function getProjectStatusAPI(stageId, apiEndpoint, projectId) {
    return axios.get(`${apiEndpoint}/projects/${projectId}`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

const PROJECT_MANAGEMENT_PENDING = "PROJECT_MANAGEMENT_PENDING";
const PROJECT_MANAGEMENT_FAILURE = "PROJECT_MANAGEMENT_FAILURE";
const PROJECT_MANAGEMENT_UPDATE_PARAMS = "PROJECT_MANAGEMENT_UPDATE_PARAMS";
const PROJECT_MANAGEMENT_RESET_PARAMS = "PROJECT_MANAGEMENT_RESET_PARAMS";
const PROJECT_MANAGEMENT_GET_PROJECTS_SUCCESS = "PROJECT_MANAGEMENT_GET_PROJECTS_SUCCESS";
const PROJECT_MANAGEMENT_GET_PROJECT_SUCCESS = "PROJECT_MANAGEMENT_GET_PROJECT_SUCCESS";
const PROJECT_MANAGEMENT_CREATE_PROJECT_SUCCESS = "PROJECT_MANAGEMENT_CREATE_PROJECT_SUCCESS";
const PROJECT_MANAGEMENT_UPDATE_PROJECT_SUCCESS = "PROJECT_MANAGEMENT_UPDATE_PROJECT_SUCCESS";

const PROJECT_MANAGEMENT_PROJECT_USERS_PENDING = "PROJECT_MANAGEMENT_PROJECT_USERS_PENDING";
const PROJECT_MANAGEMENT_GET_PROJECT_USERS_SUCCESS = "PROJECT_MANAGEMENT_GET_PROJECT_USERS_SUCCESS";
const PROJECT_MANAGEMENT_PROJECT_USERS_FAILURE = "PROJECT_MANAGEMENT_PROJECT_USERS_FAILURE";

const PROJECT_MANAGEMENT_PROJECT_TEAMS_PENDING = "PROJECT_MANAGEMENT_PROJECT_TEAMS_PENDING";
const PROJECT_MANAGEMENT_GET_PROJECT_TEAMS_SUCCESS = "PROJECT_MANAGEMENT_GET_PROJECT_TEAMS_SUCCESS";
const PROJECT_MANAGEMENT_PROJECT_TEAMS_FAILURE = "PROJECT_MANAGEMENT_PROJECT_TEAMS_FAILURE";

const PROJECT_MANAGEMENT_UPDATE_PROJECT_TEAMS_PARAMS = "PROJECT_MANAGEMENT_UPDATE_PROJECT_TEAMS_PARAMS";
const PROJECT_MANAGEMENT_UPDATE_PROJECT_USERS_PARAMS = "PROJECT_MANAGEMENT_UPDATE_PROJECT_USERS_PARAMS";
const PROJECT_MANAGEMENT_DELETE_PROJECT_SUCCESS = "PROJECT_MANAGEMENT_DELETE_PROJECT_SUCCESS";
const PROJECT_MANAGEMENT_CLOSE_PROJECTS_ACTIONS = "PROJECT_MANAGEMENT_CLOSE_PROJECTS_ACTIONS";
const PROJECT_MANAGEMENT_TOGGLE_PROJECTS_ACTIONS = "PROJECT_MANAGEMENT_TOGGLE_PROJECTS_ACTIONS";

const PROJECT_MANAGEMENT_RESET_USERS_PARAMS = "PROJECT_MANAGEMENT_RESET_USERS_PARAMS";
const PROJECT_MANAGEMENT_RESET_TEAMS_PARAMS = "PROJECT_MANAGEMENT_RESET_TEAMS_PARAMS";
const PROJECT_MANAGEMENT_ASSIGN_USER_SUCCESS = "PROJECT_MANAGEMENT_ASSIGN_USER_SUCCESS";
const PROJECT_MANAGEMENT_ASSIGN_USER_V2_SUCCESS = "PROJECT_MANAGEMENT_ASSIGN_USER_V2_SUCCESS";
const PROJECT_MANAGEMENT_ASSIGN_TEAM_SUCCESS = "PROJECT_MANAGEMENT_ASSIGN_TEAM_SUCCESS";
const PROJECT_MANAGEMENT_ASSIGN_TEAM_V2_SUCCESS = "PROJECT_MANAGEMENT_ASSIGN_TEAM_V2_SUCCESS";
const PROJECT_MANAGEMENT_UNASSIGN_USER_SUCCESS = "PROJECT_MANAGEMENT_UNASSIGN_USER_SUCCESS";
const PROJECT_MANAGEMENT_UNASSIGN_TEAM_SUCCESS = "PROJECT_MANAGEMENT_UNASSIGN_TEAM_SUCCESS";

const PROJECT_MANAGEMENT_UPDATE_USER_SUCCESS = "PROJECT_MANAGEMENT_UPDATE_USER_SUCCESS";
const PROJECT_MANAGEMENT_UPDATE_TEAM_SUCCESS = "PROJECT_MANAGEMENT_UPDATE_TEAM_SUCCESS";

const PROJECT_MANAGEMENT_UPDATE_PROJECT_STATUS = "PROJECT_MANAGEMENT_UPDATE_PROJECT_STATUS";
const PROJECT_MANAGEMENT_RESET_PROJECT = "PROJECT_MANAGEMENT_RESET_PROJECT";
const PROJECT_MANAGEMENT_INIT_PROJECT = "PROJECT_MANAGEMENT_INIT_PROJECT";
const PROJECT_MANAGEMENT_GET_ALL_PROJECTS_SUCCESS = "PROJECT_MANAGEMENT_GET_ALL_PROJECTS_SUCCESS";
const PROJECT_MANAGEMENT_GET_ALL_PROJECTS_FAILURE = "PROJECT_MANAGEMENT_GET_ALL_PROJECTS_FAILURE";

const initialState = {
    pending: false,
    error: false,
    params: {
        keywordType: "name",
        keyword: "",
        offset: 0,
        limit: 50,
    },
    totalCount: 0,
    projects: [],
    project: null,
    userTotalCount: 0,
    teamTotalCount: 0,
    allProjects: [],
    users: {
        pending: false,
        error: false,
        totalCount: 0,
        results: [],
        params: {
            keywordType: "username",
            offset: 0,
            limit: 50,
        },
    },
    teams: {
        pending: false,
        error: false,
        totalCount: 0,
        results: [],
        params: {
            keywordType: "name",
            offset: 0,
            limit: 50,
        },
    },
    isCreateProjectSuccess: false,
    isCreateProjectComplete: false,
    isPolling: false,
    hasReset: false,
};

export default handleActions(
    {
        [PROJECT_MANAGEMENT_PENDING]: (state) => {
            return {
                ...state,
                pending: true,
                error: false,
            };
        },
        [PROJECT_MANAGEMENT_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [PROJECT_MANAGEMENT_GET_PROJECTS_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            return {
                ...state,
                pending: false,
                error: false,
                totalCount: data.totalCount,
                projects: data.results,
            };
        },
        [PROJECT_MANAGEMENT_UPDATE_PARAMS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
                params: {
                    ...state.params,
                    ...action.payload,
                },
            };
        },
        [PROJECT_MANAGEMENT_RESET_PARAMS]: (state) => {
            return {
                ...state,
                params: {
                    ...state.params,
                    keyword: "",
                    offset: 0,
                    limit: 50,
                },
            };
        },
        [PROJECT_MANAGEMENT_RESET_USERS_PARAMS]: (state) => {
            return {
                ...state,
                users: {
                    ...state.users,
                    params: {
                        ...state.params,
                    },
                },
            };
        },
        [PROJECT_MANAGEMENT_RESET_TEAMS_PARAMS]: (state) => {
            return {
                ...state,
                teams: {
                    ...state.teams,
                    params: {
                        ...state.params,
                    },
                },
            };
        },
        [PROJECT_MANAGEMENT_GET_PROJECT_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                pending: false,
                error: false,
                project: data,
            };
        },
        [PROJECT_MANAGEMENT_UPDATE_PROJECT_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                pending: false,
                error: false,
                project: data,
            };
        },
        [PROJECT_MANAGEMENT_CREATE_PROJECT_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                pending: false,
                error: false,
                errorStatus: null,
                isCreateProjectSuccess: true,
                projectId: data.id,
            };
        },
        [PROJECT_MANAGEMENT_PROJECT_USERS_PENDING]: (state) => {
            return {
                ...state,
                users: {
                    ...state.users,
                    pending: true,
                    error: false,
                },
            };
        },
        [PROJECT_MANAGEMENT_GET_PROJECT_USERS_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            return {
                ...state,
                users: {
                    ...state.users,
                    totalCount: data.totalCount,
                    results: data.results,
                    pending: false,
                    error: false,
                },
            };
        },
        [PROJECT_MANAGEMENT_PROJECT_USERS_FAILURE]: (state) => {
            return {
                ...state,
                users: {
                    ...state.users,
                    pending: false,
                    error: true,
                },
            };
        },
        [PROJECT_MANAGEMENT_PROJECT_TEAMS_PENDING]: (state) => {
            return {
                ...state,
                teams: {
                    ...state.teams,
                    pending: true,
                    error: false,
                },
            };
        },
        [PROJECT_MANAGEMENT_GET_PROJECT_TEAMS_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                teams: {
                    ...state.teams,
                    totalCount: data.totalCount,
                    results: data.results,
                    pending: false,
                    error: false,
                },
            };
        },
        [PROJECT_MANAGEMENT_PROJECT_TEAMS_FAILURE]: (state) => {
            return {
                ...state,
                teams: {
                    ...state.teams,
                    pending: false,
                    error: true,
                },
            };
        },
        [PROJECT_MANAGEMENT_UPDATE_PROJECT_TEAMS_PARAMS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
                teams: {
                    ...state.teams,
                    params: {
                        ...state.teams.params,
                        ...action.payload,
                    },
                },
            };
        },
        [PROJECT_MANAGEMENT_UPDATE_PROJECT_USERS_PARAMS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
                users: {
                    ...state.users,
                    params: {
                        ...state.users.params,
                        ...action.payload,
                    },
                },
            };
        },
        [PROJECT_MANAGEMENT_DELETE_PROJECT_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
            };
        },
        [PROJECT_MANAGEMENT_TOGGLE_PROJECTS_ACTIONS]: (state, action) => {
            return {
                ...state,
                projects: state.projects.map((v) => {
                    return {
                        ...v,
                        isOpen: v.id === action.payload ? !v.isOpen : false,
                    };
                }),
            };
        },
        [PROJECT_MANAGEMENT_CLOSE_PROJECTS_ACTIONS]: (state) => {
            return {
                ...state,
                projects: state.projects.map((v) => {
                    return {
                        ...v,
                        isOpen: false,
                    };
                }),
            };
        },
        [PROJECT_MANAGEMENT_ASSIGN_USER_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
                users: {
                    ...state.users,
                    results: state.users.results.map((v) => {
                        return {
                            ...v,
                            belongToProject: v.id === action.payload ? true : v.belongToProject,
                        };
                    }),
                },
            };
        },
        [PROJECT_MANAGEMENT_ASSIGN_USER_V2_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
            };
        },
        [PROJECT_MANAGEMENT_ASSIGN_TEAM_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
                teams: {
                    ...state.teams,
                    results: state.teams.results.map((v) => {
                        return {
                            ...v,
                            belongToProject: v.id === action.payload ? true : v.belongToProject,
                        };
                    }),
                },
            };
        },
        [PROJECT_MANAGEMENT_ASSIGN_TEAM_V2_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
            };
        },
        [PROJECT_MANAGEMENT_UNASSIGN_USER_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
                users: {
                    ...state.users,
                    results: state.users.results.map((v) => {
                        return {
                            ...v,
                            belongToProject: v.id === action.payload ? false : v.belongToProject,
                        };
                    }),
                },
            };
        },
        [PROJECT_MANAGEMENT_UNASSIGN_TEAM_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
                teams: {
                    ...state.teams,
                    results: state.teams.results.map((v) => {
                        return {
                            ...v,
                            belongToProject: v.id === action.payload ? false : v.belongToProject,
                        };
                    }),
                },
            };
        },

        [PROJECT_MANAGEMENT_UPDATE_PROJECT_STATUS]: (state, action) => {
            const { data } = action.payload;
            if (data.status && data.status !== "") {
                return {
                    ...state,
                    project: {
                        ...state.project,
                        status: data.status,
                    },
                    isCreateProjectComplete: data.status === "COMPLETE",
                };
            } else
                return {
                    ...state,
                };
        },
        [PROJECT_MANAGEMENT_RESET_PROJECT]: (state, action) => {
            return {
                ...initialState,
                hasReset: true,
            };
        },
        [PROJECT_MANAGEMENT_INIT_PROJECT]: (state, action) => {
            return {
                ...initialState,
            };
        },

        [PROJECT_MANAGEMENT_UPDATE_USER_SUCCESS]: (state, action) => {
            const payload = action.payload;
            return {
                ...state,
                users: {
                    ...state.users,
                    results: (state.users.results || []).map((v) => {
                        if (v.id === payload.id) {
                            return {
                                ...v,
                                ...payload,
                            };
                        }
                        return v;
                    }),
                    pending: false,
                    error: false,
                },
            };
        },
        [PROJECT_MANAGEMENT_UPDATE_TEAM_SUCCESS]: (state, action) => {
            const payload = action.payload;
            return {
                ...state,
                teams: {
                    ...state.teams,
                    results: (state.teams.results || []).map((v) => {
                        if (v.id === payload.id) {
                            return {
                                ...v,
                                ...payload,
                            };
                        }
                        return v;
                    }),
                    pending: false,
                    error: false,
                },
            };
        },
        [PROJECT_MANAGEMENT_GET_ALL_PROJECTS_SUCCESS]: (state, action) => {
            const payload = action.payload;
            return {
                ...state,
                pending: false,
                error: false,
                allProjects: payload.results,
            };
        },
        [PROJECT_MANAGEMENT_GET_ALL_PROJECTS_FAILURE]: (state, action) => {
            const payload = action.payload;
            return {
                ...state,
                pending: false,
                error: true,
                allProjects: [],
            };
        },
    },
    initialState,
);

function startPolling(projectId, dispatch, getState) {
    const { stage } = getState();

    Polling(
        async () => {
            return await getProjectStatusAPI(stage.id, stage.endpoint, projectId);
        },
        (response) => {
            // console.log("should stop predicate ", getState().projectManagement)
            if (getState().projectManagement.hasReset) return true;

            if (response.data.status === "COMPLETE" || response.data.status === "ERROR") return true;
        },
        (response) => {
            dispatch({
                type: PROJECT_MANAGEMENT_UPDATE_PROJECT_STATUS,
                payload: response,
            });
        },
        null,
        3000,
    ).run();
}

export const closeActions = createAction(PROJECT_MANAGEMENT_CLOSE_PROJECTS_ACTIONS);
export const toggleActions = createAction(PROJECT_MANAGEMENT_TOGGLE_PROJECTS_ACTIONS);
export const updateTeamParams = createAction(PROJECT_MANAGEMENT_UPDATE_PROJECT_TEAMS_PARAMS);
export const updateUserParams = createAction(PROJECT_MANAGEMENT_UPDATE_PROJECT_USERS_PARAMS);
export const resetParams = createAction(PROJECT_MANAGEMENT_RESET_PARAMS);
export const resetUserParams = createAction(PROJECT_MANAGEMENT_RESET_USERS_PARAMS);
export const resetTeamParams = createAction(PROJECT_MANAGEMENT_RESET_TEAMS_PARAMS);
export const reset = createAction(PROJECT_MANAGEMENT_RESET_PROJECT);
export const initialize = createAction(PROJECT_MANAGEMENT_INIT_PROJECT);
export const updateParams = createAction(PROJECT_MANAGEMENT_UPDATE_PARAMS);
export const assignUser = (projectId, userId) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: PROJECT_MANAGEMENT_PENDING });
    return assignUserAPI(stage.id, stage.endpoint, projectId, userId)
        .then((response) => {
            dispatch({
                type: PROJECT_MANAGEMENT_ASSIGN_USER_SUCCESS,
                payload: userId,
            });
        })
        .catch((error) => {
            dispatch({
                type: PROJECT_MANAGEMENT_FAILURE,
                payload: error,
            });
        });
};

export const assignUserV2 = (projectId, params) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: PROJECT_MANAGEMENT_PENDING });
    return assignUserV2API(stage.id, stage.endpoint, projectId, params)
        .then((response) => {
            dispatch({
                type: PROJECT_MANAGEMENT_ASSIGN_USER_V2_SUCCESS,
            });
        })
        .catch((error) => {
            dispatch({
                type: PROJECT_MANAGEMENT_FAILURE,
                payload: error,
            });
        });
};

export const assignTeam = (projectId, teamId) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: PROJECT_MANAGEMENT_PENDING });

    return assignTeamAPI(stage.id, stage.endpoint, projectId, teamId)
        .then((response) => {
            dispatch({
                type: PROJECT_MANAGEMENT_ASSIGN_TEAM_SUCCESS,
                payload: teamId,
            });
        })
        .catch((error) => {
            dispatch({
                type: PROJECT_MANAGEMENT_FAILURE,
                payload: error,
            });
        });
};

export const assignTeamV2 = (projectId, params) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: PROJECT_MANAGEMENT_PENDING });

    return assignTeamV2API(stage.id, stage.endpoint, projectId, params)
        .then((response) => {
            dispatch({
                type: PROJECT_MANAGEMENT_ASSIGN_TEAM_V2_SUCCESS,
                payload: params.teamId,
            });
        })
        .catch((error) => {
            dispatch({
                type: PROJECT_MANAGEMENT_FAILURE,
                payload: error,
            });
        });
};

export const unassignUser = (projectId, userId) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: PROJECT_MANAGEMENT_PENDING });

    return unassignUserAPI(stage.id, stage.endpoint, projectId, userId)
        .then((response) => {
            dispatch({
                type: PROJECT_MANAGEMENT_UNASSIGN_USER_SUCCESS,
                payload: userId,
            });
        })
        .catch((error) => {
            dispatch({
                type: PROJECT_MANAGEMENT_FAILURE,
                payload: error,
            });
        });
};

export const unassignUserV2 = (projectId, userId) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: PROJECT_MANAGEMENT_PENDING });

    return unassignUserV2API(stage.id, stage.endpoint, projectId, userId)
        .then((response) => {
            dispatch({
                type: PROJECT_MANAGEMENT_UNASSIGN_USER_SUCCESS,
                payload: userId,
            });
        })
        .catch((error) => {
            dispatch({
                type: PROJECT_MANAGEMENT_FAILURE,
                payload: error,
            });
        });
};
export const unassignTeam = (projectId, teamId) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: PROJECT_MANAGEMENT_PENDING });

    return unassignTeamAPI(stage.id, stage.endpoint, projectId, teamId)
        .then((response) => {
            dispatch({
                type: PROJECT_MANAGEMENT_UNASSIGN_TEAM_SUCCESS,
                payload: teamId,
            });
        })
        .catch((error) => {
            dispatch({
                type: PROJECT_MANAGEMENT_FAILURE,
                payload: error,
            });
        });
};

export const unassignTeamV2 = (projectId, teamId) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: PROJECT_MANAGEMENT_PENDING });

    return unassignTeamV2API(stage.id, stage.endpoint, projectId, teamId)
        .then((response) => {
            dispatch({
                type: PROJECT_MANAGEMENT_UNASSIGN_TEAM_SUCCESS,
                payload: teamId,
            });
        })
        .catch((error) => {
            dispatch({
                type: PROJECT_MANAGEMENT_FAILURE,
                payload: error,
            });
        });
};

export const createProject =
    (params, callback = (project) => {}) =>
    (dispatch, getState) => {
        const { stage } = getState();

        dispatch({ type: PROJECT_MANAGEMENT_PENDING });

        return createProjectAPI(stage.id, stage.endpoint, params)
            .then((response) => {
                dispatch({
                    type: PROJECT_MANAGEMENT_CREATE_PROJECT_SUCCESS,
                    payload: response,
                });

                if (callback) callback(response);
            })
            .catch((error) => {
                dispatch({
                    type: PROJECT_MANAGEMENT_FAILURE,
                    payload: error,
                });
            });
    };
export const getProjects = (params) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: PROJECT_MANAGEMENT_PENDING });

    return getProjectsAPI(stage.id, stage.endpoint, params)
        .then((response) => {
            dispatch({
                type: PROJECT_MANAGEMENT_GET_PROJECTS_SUCCESS,
                payload: response,
            });

            response.data.results.map((p) => {
                if (p.status === "READY" || p.status === "PREPARING") {
                    startPolling(p.id, dispatch, getState);
                }
            });
        })
        .catch((error) => {
            dispatch({
                type: PROJECT_MANAGEMENT_FAILURE,
                payload: error,
            });
        });
};

export const getProject = (id) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: PROJECT_MANAGEMENT_PENDING });

    return getProjectAPI(stage.id, stage.endpoint, project.id, id)
        .then((response) => {
            dispatch({
                type: PROJECT_MANAGEMENT_GET_PROJECT_SUCCESS,
                payload: response,
            });

            if (response.data.status === "READY" || response.data.status === "PREPARING") {
                startPolling(id, dispatch, getState);
            }
        })
        .catch((error) => {
            dispatch({
                type: PROJECT_MANAGEMENT_FAILURE,
                payload: error,
            });
        });
};

export const updateProject = (id, data) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: PROJECT_MANAGEMENT_PENDING });
    return updateProjectAPI(stage.id, stage.endpoint, id, data)
        .then((response) => {
            dispatch({
                type: PROJECT_MANAGEMENT_UPDATE_PROJECT_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: PROJECT_MANAGEMENT_FAILURE,
                payload: error,
            });
        });
};
export const getProjectUsers = (id, params, showAll) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: PROJECT_MANAGEMENT_PROJECT_USERS_PENDING });

    return getProjectUsersAPI(stage.id, stage.endpoint, project.id, id, params, showAll)
        .then((response) => {
            dispatch({
                type: PROJECT_MANAGEMENT_GET_PROJECT_USERS_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: PROJECT_MANAGEMENT_PROJECT_USERS_FAILURE,
                payload: error,
            });
        });
};

export const getProjectUsersV2 = (id, params, showAll) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: PROJECT_MANAGEMENT_PROJECT_USERS_PENDING });

    return getProjectUsersV2API(stage.id, stage.endpoint, project.id, id, params, showAll)
        .then((response) => {
            dispatch({
                type: PROJECT_MANAGEMENT_GET_PROJECT_USERS_SUCCESS,
                payload: response,
            });
            return response?.data;
        })
        .catch((error) => {
            dispatch({
                type: PROJECT_MANAGEMENT_PROJECT_USERS_FAILURE,
                payload: error,
            });
        });
};

export const getProjectTeams = (id, params, showAll) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: PROJECT_MANAGEMENT_PROJECT_TEAMS_PENDING });

    return getProjectTeamsAPI(stage.id, stage.endpoint, project.id, id, params, showAll)
        .then((response) => {
            dispatch({
                type: PROJECT_MANAGEMENT_GET_PROJECT_TEAMS_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: PROJECT_MANAGEMENT_PROJECT_TEAMS_FAILURE,
                payload: error,
            });
        });
};

export const getProjectTeamsV2 = (id, params, showAll) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: PROJECT_MANAGEMENT_PROJECT_TEAMS_PENDING });

    return getProjectTeamsV2API(stage.id, stage.endpoint, project.id, id, params, showAll)
        .then((response) => {
            dispatch({
                type: PROJECT_MANAGEMENT_GET_PROJECT_TEAMS_SUCCESS,
                payload: response,
            });
            return response?.data;
        })
        .catch((error) => {
            dispatch({
                type: PROJECT_MANAGEMENT_PROJECT_TEAMS_FAILURE,
                payload: error,
            });
        });
};

export const deleteProject = (id) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: PROJECT_MANAGEMENT_PENDING });

    return deleteProjectAPI(stage.id, stage.endpoint, id)
        .then((response) => {
            dispatch({
                type: PROJECT_MANAGEMENT_DELETE_PROJECT_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: PROJECT_MANAGEMENT_FAILURE,
                payload: error,
            });
        });
};

export const getAllProjects = (params) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: PROJECT_MANAGEMENT_PENDING });

    return new Promise(async (resolve, reject) => {
        try {
            let offset = 0;
            let data = [];
            let totalCount = 0;
            do {
                try {
                    const response = await getProjectsAPI(
                        stage.id,
                        stage.endpoint,
                        params ?? { offset, limit: 1000, scope: "all", isIntegratedSearchable: true },
                    );
                    offset += 1000;
                    data.push(...response.data.results);
                    totalCount = response.data.totalCount;
                } catch (error) {
                    dispatch({
                        type: PROJECT_MANAGEMENT_GET_ALL_PROJECTS_FAILURE,
                        payload: error,
                    });
                    reject(error);
                }
            } while (offset < totalCount);
            dispatch({
                type: PROJECT_MANAGEMENT_GET_ALL_PROJECTS_SUCCESS,
                payload: { results: data },
            });
            resolve({ results: data });
        } catch (e) {
            reject(e);
        }
    });
};

export const updateProjectUser = (projectId, userId, body) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: PROJECT_MANAGEMENT_PROJECT_USERS_PENDING });
    return patchProjectUserAPI(stage.id, stage.endpoint, projectId, userId, body)
        .then((response) => {
            dispatch({
                type: PROJECT_MANAGEMENT_UPDATE_USER_SUCCESS,
                payload: {
                    ...body,
                    id: userId,
                },
            });
        })
        .catch((error) => {
            dispatch({
                type: PROJECT_MANAGEMENT_PROJECT_USERS_FAILURE,
                payload: error,
            });
        });
};

export const updateProjectTeam = (projectId, teamId, body) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: PROJECT_MANAGEMENT_PROJECT_TEAMS_PENDING });
    return patchProjectTeamAPI(stage.id, stage.endpoint, projectId, teamId, body)
        .then((response) => {
            dispatch({
                type: PROJECT_MANAGEMENT_UPDATE_TEAM_SUCCESS,
                payload: {
                    ...body,
                    id: teamId,
                },
            });
        })
        .catch((error) => {
            dispatch({
                type: PROJECT_MANAGEMENT_PROJECT_TEAMS_FAILURE,
                payload: error,
            });
        });
};
