import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../components/loader";
import Error500 from "../errors/Error500";
import { useLocation } from "react-router-dom";
import menuService from "@services/menuService";
import useSettings from "@hooks/useSettings";
import Error404 from "@routes/errors/Error404";
import { getActionGroups, getMyProjectPermissions, resetProjectPermissions } from "@modules/rolesManagement_v2";
import compareVersions, { VERSION_CM_11123_ROLES_V2 } from "@cores/version";

const Roles = ({ children, ...props }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const stage = useSelector(({ stage }) => stage);
    const projectPermissions = useSelector(({ roleManagementV2 }) => roleManagementV2?.projectPermissions);

    const { navigatorMenus } = useSettings({ type: "General" });
    const [skipRoles, setSkipRoles] = useState(false);
    const { spaceId } = menuService.parse(location.pathname);

    const isSetting = useMemo(() => {
        if (!location.pathname) return false;
        return location.pathname.indexOf(`/admin/spaces/${spaceId}`) !== -1;
    }, [location]);

    useEffect(async () => {
        if (stage?.id) {
            const { extensions } = stage;
            const canManageRoles = extensions && extensions["ACTIVE_ROLE_MANAGEMENT"] === "true";
            setSkipRoles(!canManageRoles);
        }
    }, []);

    useEffect(async () => {
        if (projectPermissions.pending || compareVersions(stage.version, VERSION_CM_11123_ROLES_V2) < 0) return;
        await dispatch(getMyProjectPermissions());
    }, [location.pathname]);

    useEffect(() => {
        if (!stage?.id) return;

        const { extensions } = stage;
        const canManageRoles = extensions && extensions["ACTIVE_ROLE_MANAGEMENT"] === "true";
        setSkipRoles(!canManageRoles);
    }, [stage]);
    if(location.pathname.indexOf("/access-token-bridge") !== -1) {
        return <>{children}</>;
    }
    if ((!skipRoles && projectPermissions.pending) || (!isSetting && !navigatorMenus)) {
        return <Loader />;
    } else if (!skipRoles && !projectPermissions.pending && projectPermissions.error && global.errorStatus === 500) {
        return <Error500 />;
    } else if (navigatorMenus?.[props.connectedMenuId]?.visible === false) {
        return <Error404 />;
    } else {
        return <>{children}</>;
    }
};

export default Roles;
