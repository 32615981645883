const CollectionColumn = [
    {
        id: "name",
        label: "Name",
        style: { custom: { minWidth: 300, flex: 1, paddingLeft: "12px" } },
        disabled: true,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
    },
    {
        id: "count",
        label: "Assets Count",
        style: { custom: { minWidth: 40 } },
        disabled: false,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
    },
];

const CollectionContentSchemaColumn = [
    {
        id: "desiredStatus",
        label: "status",
        style: { custom: { padding: "0 12px", width: 32 } },
        disabled: true,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
    },
    {
        id: "name",
        label: "Name",
        style: { custom: { flex: 1 } },
        disabled: true,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
    },
    {
        id: "duration",
        label: "Duration",
        style: { custom: { width: 100 } },
        disabled: false,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
    },
];

export { CollectionColumn, CollectionContentSchemaColumn };
