import React from "react";

import { Box, Grid, Tab, Divider } from "@mzc-pdc/ui";
import { StyledTabs } from "./tabs.styled";
import Typography from "@components_v2/typography/typography";
import theme from "@styles/theme";

export const TabPanel = (props) => {
    const { children, value, index, sx, ...other } = props;
    return (
        <Box role="tabpanel" hidden={value !== index} sx={{ ...sx }} {...other}>
            {children}
        </Box>
    );
};
export const Tabs = ({
    items,
    value,
    type,
    orientation,
    textColor = "secondary",
    indicatorColor = "secondary",
    indicatorPosition = "bottom",
    TabIndicatorProps,
    isBordered = true,
    variant,
    onChangeTab,
    scrollButtons,
    scrollButtonPosition,
    setRef,
    actionButton,
    divider,
    sx,
}) => {
    return (
        <StyledTabs
            value={value}
            onChange={(e, v) => {
                onChangeTab(v);
            }}
            orientation={orientation}
            type={type}
            divider={divider}
            textColor={textColor}
            indicatorColor={indicatorColor}
            indicatorPosition={indicatorPosition ?? "top"}
            TabIndicatorProps={TabIndicatorProps}
            isBordered={isBordered}
            variant={variant}
            scrollButtons={scrollButtons}
            scrollButtonPosition={scrollButtonPosition}
            sx={sx}
        >
            {items?.map((item, index) => (
                <Tab
                    key={index}
                    iconPosition={item.iconPosition}
                    label={
                        <Grid container flexDirection={"row"} alignItems={"center"}>
                            {item.label}
                            {item.count !== undefined ? (
                                <Grid ml={1}>
                                    <Typography
                                        variant={"body1_700"}
                                        color={theme.palette.primary[800]}
                                        children={item.count}
                                    />
                                </Grid>
                            ) : (
                                ""
                            )}
                            {item.icon && <Grid ml={1}>{item.icon}</Grid>}
                        </Grid>
                    }
                    value={item.value}
                    disabled={item.disabled}
                    ref={(ref) => setRef && setRef(ref, item)}
                />
            ))}
            {actionButton && actionButton}
        </StyledTabs>
    );
};
