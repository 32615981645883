import {handleActions, createAction} from 'redux-actions';
import {
    axios,
    axiosWithoutHeaders
} from "../cores/axiosWrapper";

function postStreamsAPI(stageId, apiEndpoint, projectId, params) {
    let whitelistData = [];
    if(params.isWhitelistAnywhere == true)
        whitelistData.push("0.0.0.0/0");
    else
    {
        var whitelistArray = params.whitelist.value.split('\n');

        for (var i = 0; i < whitelistArray.length; i++) {
            whitelistData.push(whitelistArray[i].trim());
        }
    }
    return axios.post(`${apiEndpoint}/streams`, {
        name : params.name.value,
        type : params.type,
        project:{
            id:projectId
        },
        attachedInput: {
            id : params.input.id,
            name : params.input.name,
            inputA : params.input.inputA,
            inputB : params.input.inputB
        },
        whitelists:whitelistData
    }, {
        headers: {
            stageId,
            projectId,
            'Content-Type': 'application/json'            
        }
    });
}

//action type
const CREATE_STREAM_PENDING = 'CREATE_STREAM_PENDING';
const CREATE_STREAM_FAILURE = 'CREATE_STREAM_FAILURE';
const SET_CREATE_STREAM_INIT_DATA = 'SET_CREATE_STREAM_INIT_DATA';
const UPDATE_CREATE_STREAM_PARAM = 'UPDATE_CREATE_STREAM_PARAM';
const RESET_CREATE_STREAM_PARAMS = 'RESET_CREATE_STREAM_PARAMS';
const SET_CREATE_STREAM_EDITMODE = 'SET_CREATE_STREAM_EDITMODE';
const POST_CREATE_STREAM_SUCCESS = 'POST_CREATE_STREAM_SUCCESS';
const GET_INPUTS_SUCCESS = 'GET_INPUTS_SUCCESS';
const CREATE_STREAM_SET_VALIDATION_ERROR = 'CREATE_STREAM_SET_VALIDATION_ERROR';
//reducer
const initialState = {
    pending : false,
    error : false,
    inputs : [],
    selectWhitelistModal: false,
    validationErrors : {},
    params : {
        name : {
            value : '',
            validate: {
                validation : null,
                message: ''
            }
        },
        type : 'RTMP_PUSH',// // RTP, RTMP_PUSH, RTMP_PULL (LIVE 형태만 지원, RTMP_PULL은 차후에 지원.)
        whitelist : {
            value : '',
            validate: {
                validation : null,
                message: ''
            }
        },
        isWhitelistAnywhere : false,
        input : {
            id : '',
            name : '',
            inputA : '',
            inputB : '',
            isDeleted : false
        }
    }
};

export default handleActions({
    [CREATE_STREAM_PENDING]: (state) => {
        return {
            ...state,
            pending: true,
            error: false
        };
    },
    [POST_CREATE_STREAM_SUCCESS]: (state, action) => {
        let {data} = action.payload;
        
        return {
            ...state,
            pending: false,
            error : false,
            data
        }
    },
    [CREATE_STREAM_FAILURE]: (state, action) => {
        return {
            ...state,
            pending : false,
            error : true,
            message : action.payload
        }
    },
    [UPDATE_CREATE_STREAM_PARAM]: (state, action) => {
        return {
            ...state,
            params : {
                ...state.params,
                ...action.payload
            }
        }
    },
    [RESET_CREATE_STREAM_PARAMS]:(state) => {
        return {
            ...state,
            params: {
                name : {
                    value : '',
                    validate: {
                        validation : null,
                        message: ''
                    }
                },
                type : 'RTMP_PUSH',// // RTP, RTMP_PUSH, RTMP_PULL (LIVE 형태만 지원, RTMP_PULL은 차후에 지원.)
                input : {
                    id : '',
                    name : '',
                    inputA : '',
                    inputB : '',
                    isDeleted : false
                },
                whitelist : {
                    value : '',
                    validate: {
                        validation : null,
                        message: ''
                    }
                },
                isWhitelistAnywhere : false
            }
        };
    },
    [GET_INPUTS_SUCCESS]: (state, action) => {
        let {data} = action.payload;
        
        return {
            ...state,
            pending : false,
            error : false,
            inputs : data.inputs
        }
    },
    [SET_CREATE_STREAM_INIT_DATA]:()=> {
        return {
            pending : false,
            error : false,
            inputs : [],
            validationErrors : {},
            params : {
                name : {
                    value : '',
                    validate: {
                        validation : null,
                        message: ''
                    }
                },
                type : 'RTMP_PUSH',// // RTP, RTMP_PUSH, RTMP_PULL (LIVE 형태만 지원, RTMP_PULL은 차후에 지원.)
                input : {
                    id : '',
                    name : '',
                    inputA : '',
                    inputB : '',
                    isDeleted : false
                },
                whitelist : {
                    value : '',
                    validate: {
                        validation : null,
                        message: ''
                    }
                },
                isWhitelistAnywhere : false
            }
        }
    },
    [CREATE_STREAM_SET_VALIDATION_ERROR] : (state, action) => {
        const {
            key,
            error
        } = action.payload;

        if(error) {
            return {
                ...state,
                validationErrors : {
                    ...state.validationErrors,
                    [key] : error
                }
            };
        } 

        const validationErrors = state.validationErrors;
        if(validationErrors[key]) {
            delete validationErrors[key];
        }

        return {
            ...state,
            validationErrors
        };        
    }
}, initialState);
//action creators
export const updateParam = createAction(UPDATE_CREATE_STREAM_PARAM);
export const resetListingsParams = createAction(RESET_CREATE_STREAM_PARAMS);
export const setEditMode = createAction(SET_CREATE_STREAM_EDITMODE);
export const setInitData = createAction(SET_CREATE_STREAM_INIT_DATA);
export const setValidationError = createAction(CREATE_STREAM_SET_VALIDATION_ERROR);
export const postStreams = (params, callback = () => {}) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    if (stage.id === '') {
        return;
    }

    dispatch({type: CREATE_STREAM_PENDING});

    return postStreamsAPI(stage.id, stage.endpoint, project.id, params).then(
        (response) => {
            dispatch({
                type: POST_CREATE_STREAM_SUCCESS,
                payload: response
            });
            
            callback(response.data.id);
        }
    ).catch(error => {
        dispatch({
            type: CREATE_STREAM_FAILURE,
            payload: error
        });
    });
};