import { handleActions, createAction } from "redux-actions";
import moment from "moment";
import { axios, axiosWithoutHeaders } from "../cores/axiosWrapper";
import { CONSTANTS } from "../constants";

function getArchivedAssetAPI(stageId, apiEndpoint, projectId, id, data) {
    return axios.get(`${apiEndpoint}/archived-assets/${id}`, {
        headers: {
            stageId,
            projectId,
        },
        params: data,
    });
}

function getArchivedAssetRestoreAPI(stageId, apiEndpoint, projectId, id) {
    return axios.put(`${apiEndpoint}/archived-assets/${id}/restore`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function putRestoreAPI(stageId, apiEndpoint, projectId, archivedAssetId, data) {
    return axios.put(`${apiEndpoint}/archived-assets/${archivedAssetId}/restore`, data, {
        headers: {
            stageId,
            projectId,
            "Content-Type": "application/json",
        },
    });
}

const ARCHIVED_ASSET_PENDING = "ARCHIVED_ASSET_PENDING";
const ARCHIVED_ASSET_FAILURE = "ARCHIVED_ASSET_FAILURE";

const GET_ARCHIVED_ASSET_SUCCESS = "GET_ARCHIVED_ASSET_SUCCESS";

const INIT_ARCHIVED_ASSET = "INIT_ARCHIVED_ASSET";

const initialState = {
    pending: false,
    error: false,
    data: null,
};

export default handleActions(
    {
        [ARCHIVED_ASSET_PENDING]: (state) => {
            return {
                ...state,
                pending: true,
                error: false,
            };
        },
        [ARCHIVED_ASSET_FAILURE]: (state) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [GET_ARCHIVED_ASSET_SUCCESS]: (state, action) => {
            return {
                ...state,
                data: action.payload.data,
                pending: false,
                error: false,
            };
        },
        [INIT_ARCHIVED_ASSET]: () => {
            return {
                ...initialState,
            };
        },
    },
    initialState,
);

export const getArchivedAsset = (id) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: ARCHIVED_ASSET_PENDING });
    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;

    return new Promise((resolve, reject) => {
        getArchivedAssetAPI(stageId, apiEndpoint, projectId, id)
            .then((response) => {
                dispatch({
                    type: GET_ARCHIVED_ASSET_SUCCESS,
                    payload: response,
                });
                resolve(response);
            })
            .catch((error) => {
                dispatch({
                    type: ARCHIVED_ASSET_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const restoreArchivedAsset = (id) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: ARCHIVED_ASSET_PENDING });
    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;

    return new Promise((resolve, reject) => {
        getArchivedAssetRestoreAPI(stageId, apiEndpoint, projectId, id)
            .then((response) => {
                dispatch({
                    type: GET_ARCHIVED_ASSET_SUCCESS,
                    payload: response,
                });
                resolve(response);
            })
            .catch((error) => {
                dispatch({
                    type: ARCHIVED_ASSET_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const putRestore = (id, data) => (dispatch, getState) => {
    const { stage, project } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;
    dispatch({ type: ARCHIVED_ASSET_PENDING });

    return new Promise((resolve, reject) => {
        putRestoreAPI(stageId, apiEndpoint, projectId, id, data)
            .then((response) => {
                dispatch({
                    type: GET_ARCHIVED_ASSET_SUCCESS,
                    payload: response,
                });
                resolve(response);
            })
            .catch((error) => {
                dispatch({
                    type: ARCHIVED_ASSET_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};
