import { handleActions, createAction } from "redux-actions";

import { axios, axiosWithoutHeaders } from "../cores/axiosWrapper";
import uniqId from "uniqid";
import configurationTemplate from "./event-trigger-configuration-template";
import configurationTemplateV2 from "./event-trigger-configuration-template-v2";
import replaceCustomTags from "../cores/replaceCustomTags";
import compareVersions, { VERSION_CM_9436_EVENT_TRIGGER_VIRTUAL_FOLDER } from "@cores/version";
import { AUTO_CAPTION_ENGINES } from "@constants";

function createEventTriggerAPI(stageId, apiEndpoint, projectId, data) {
    return axios.post(`${apiEndpoint}/triggers`, data, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function correctSelectedAction(fieldAreaUniqKey, action, validationErrors = {}) {
    action.customFields.map((v) => {
        if (v.required && !v.defaultValue) {
            validationErrors[`actions-${fieldAreaUniqKey}-${v.key}`] = "required";
        }
    });

    return action;
}

function getStateByConfigurationTemplate(template, validationErrors) {
    Object.keys(validationErrors).forEach((v) => {
        if (v.indexOf("conditions") > -1 || v.indexOf("actions") > -1) {
            delete validationErrors[v];
        }
    });

    const conditionCustomFields = template.conditionCustomFields.map((v) => {
        v.uniqKey = uniqId();
        v.isFolded = !v.required;
        v.value = "";

        if (v.required) {
            validationErrors[`conditions-${v.uniqKey}`] = "required";
        }

        return v;
    });

    return {
        data: {
            eventType: template.eventType,
            conditions: conditionCustomFields.map((v) => {
                return {
                    key: v.key,
                    uniqKey: v.uniqKey,
                };
            }),
            actions: [],
        },
        validationErrors,
        selectedConfiguration: template,
        conditionCustomFields,
        actionCustomFieldAreas: [],
    };
}

function correctPayload(payload) {
    if (!payload) return payload;

    Object.keys(payload).forEach((k) => {
        if (k.indexOf("-") > -1) {
            const keys = k.split("-");
            const values = payload[k] && payload[k].split("-");

            keys.forEach((_k, i) => {
                payload[_k] = values[i];
            });
        }
    });

    if (payload.autoCaptions?.useAutoCaption === "USED") {
        const {
            engine,
            services,
            identifyLanguage,
            identifyMultipleLanguages,
            languageOptions,
            languageCode,
            speakerDiarization,
            dictationVocabularyId,
        } = payload.autoCaptions.data;

        payload.autoCaptions = services?.map((service) => ({
            engine: service.serviceType,
            identifyLanguage:
                service.serviceType === AUTO_CAPTION_ENGINES.AWS_TRANSCRIBE ? identifyLanguage : undefined,
            identifyMultipleLanguages:
                service.serviceType === AUTO_CAPTION_ENGINES.AWS_TRANSCRIBE ? identifyMultipleLanguages : undefined,
            languageOptions:
                service.serviceType === AUTO_CAPTION_ENGINES.AWS_TRANSCRIBE && identifyMultipleLanguages
                    ? languageOptions
                    : undefined,
            languageCode: [AUTO_CAPTION_ENGINES.AWS_TRANSCRIBE, AUTO_CAPTION_ENGINES.DAGLO_STT].includes(
                service.serviceType,
            )
                ? languageCode
                : undefined,
            model: service.serviceType === AUTO_CAPTION_ENGINES.DAGLO_STT ? "general" : undefined,
            speakerDiarization: service.serviceType === AUTO_CAPTION_ENGINES.DAGLO_STT ? speakerDiarization : undefined,
            dictationVocabularyId: [AUTO_CAPTION_ENGINES.AWS_TRANSCRIBE, AUTO_CAPTION_ENGINES.DAGLO_STT].includes(
                service.serviceType,
            )
                ? dictationVocabularyId
                : undefined,
        }));
    } else {
        delete payload.autoCaptions;
    }

    return payload;
}

const CREATE_EVENT_TRIGGER_PENDING = "CREATE_EVENT_TRIGGER_PENDING";
const CREATE_EVENT_TRIGGER_FAILURE = "CREATE_EVENT_TRIGGER_FAILURE";

const CREATE_EVENT_TRIGGER_UPDATE_DATA = "CREATE_EVENT_TRIGGER_UPDATE_DATA";

const CREATE_EVENT_TRIGGER_UPDATE_CONDITION_CUSTOM_FIELD = "CREATE_EVENT_TRIGGER_UPDATE_CONDITION_CUSTOM_FIELD";
const CREATE_EVENT_TRIGGER_TOGGLE_CONDITION_CUSTOM_FIELD_EXPANDER =
    "CREATE_EVENT_TRIGGER_TOGGLE_CONDITION_CUSTOM_FIELD_EXPANDER";

const CREATE_EVENT_TRIGGER_RESET = "CREATE_EVENT_TRIGGER_RESET";

const CREATE_EVENT_TRIGGER_SET_VALIDATION_ERROR = "CREATE_EVENT_TRIGGER_SET_VALIDATION_ERROR";

const CREATE_EVENT_TRIGGER_SET_CONFIGURATION_TEMPLATE = "CREATE_EVENT_TRIGGER_SET_CONFIGURATION_TEMPLATE";

const CREATE_EVENT_TRIGGER_UPDATE_ACTION_CUSTOM_FIELD_AREA = "CREATE_EVENT_TRIGGER_UPDATE_ACTION_CUSTOM_FIELD_AREA";
const CREATE_EVENT_TRIGGER_ADD_ACTION_CUSTOM_FIELD_AREA = "CREATE_EVENT_TRIGGER_ADD_ACTION_CUSTOM_FIELD_AREA";
const CREATE_EVENT_TRIGGER_REMOVE_ACTION_CUSTOM_FIELD_AREA = "CREATE_EVENT_TRIGGER_REMOVE_ACTION_CUSTOM_FIELD_AREA";
const CREATE_EVENT_TRIGGER_UPDATE_ACTION_CUSTOM_FIELD = "CREATE_EVENT_TRIGGER_UPDATE_ACTION_CUSTOM_FIELD";
const CREATE_EVENT_TRIGGER_TOGGLE_ACTION_CUSTOM_FIELD_EXPANDER =
    "CREATE_EVENT_TRIGGER_TOGGLE_ACTION_CUSTOM_FIELD_EXPANDER";

const initialState = {
    pending: false,
    error: false,
    version: null,
    ...getStateByConfigurationTemplate(configurationTemplate[0], {
        name: "required",
    }),
};

export default handleActions(
    {
        [CREATE_EVENT_TRIGGER_PENDING]: (state, action) => {
            return {
                ...state,
                pending: true,
            };
        },
        [CREATE_EVENT_TRIGGER_FAILURE]: (state, action) => {
            return {
                ...state,
                error: true,
            };
        },
        [CREATE_EVENT_TRIGGER_SET_VALIDATION_ERROR]: (state, action) => {
            const { key, error } = action.payload;

            if (error) {
                return {
                    ...state,
                    validationErrors: {
                        ...state.validationErrors,
                        [key]: error,
                    },
                };
            }

            const validationErrors = state.validationErrors;
            if (validationErrors[key]) {
                delete validationErrors[key];
            }

            return {
                ...state,
                validationErrors,
            };
        },
        [CREATE_EVENT_TRIGGER_UPDATE_DATA]: (state, action) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload,
                },
            };
        },
        [CREATE_EVENT_TRIGGER_RESET]: (state, action) => {
            const { stage } = action.payload;
            return {
                pending: false,
                error: false,
                ...getStateByConfigurationTemplate(
                    compareVersions(stage.version, VERSION_CM_9436_EVENT_TRIGGER_VIRTUAL_FOLDER) < 0
                        ? configurationTemplate[0]
                        : configurationTemplateV2[0],
                    {
                        name: "required",
                    },
                ),
            };
        },
        [CREATE_EVENT_TRIGGER_UPDATE_CONDITION_CUSTOM_FIELD]: (state, action) => {
            const { uniqKey, value } = action.payload;

            return {
                ...state,
                conditionCustomFields: state.conditionCustomFields.map((v) => {
                    if (v.uniqKey === uniqKey) {
                        v.value = value;
                    }

                    return v;
                }),
                data: {
                    ...state.data,
                    conditions: state.data.conditions.map((v, i) => {
                        if (v.uniqKey === uniqKey) {
                            v.value = value;
                        }

                        return v;
                    }),
                },
            };
        },
        [CREATE_EVENT_TRIGGER_TOGGLE_CONDITION_CUSTOM_FIELD_EXPANDER]: (state, action) => {
            const { uniqKey } = action.payload;

            return {
                ...state,
                conditionCustomFields: state.conditionCustomFields.map((c) => {
                    if (c.uniqKey === uniqKey) {
                        c.isFolded = !c.isFolded;
                    }

                    return c;
                }),
            };
        },
        [CREATE_EVENT_TRIGGER_SET_CONFIGURATION_TEMPLATE]: (state, action) => {
            const eventType = action.payload;
            const template =
                compareVersions(stage.version, VERSION_CM_9436_EVENT_TRIGGER_VIRTUAL_FOLDER) < 0
                    ? configurationTemplate[0]
                    : configurationTemplateV2[0];

            const selectedConfigurationTemplate = template
                .filter((v) => v.eventType === eventType)
                .reduce((acc, curr) => curr, {});

            return {
                ...state,
                ...getStateByConfigurationTemplate(selectedConfigurationTemplate, state.validationErrors),
            };
        },
        [CREATE_EVENT_TRIGGER_UPDATE_ACTION_CUSTOM_FIELD_AREA]: (state, action) => {
            const { uniqKey, index } = action.payload;

            const validationErrors = state.validationErrors;
            Object.keys(validationErrors).forEach((k) => {
                if (k.indexOf(`actions-${uniqKey}`) > -1) {
                    delete validationErrors[k];
                }
            });

            const selectedAction = correctSelectedAction(
                uniqKey,
                state.selectedConfiguration.actions.filter((v) => v.index === index)[0],
                validationErrors,
            );
            return {
                ...state,
                actionCustomFieldAreas: state.actionCustomFieldAreas.map((v) => {
                    if (v.uniqKey === uniqKey) {
                        v.selectedAction = selectedAction;
                    }

                    return v;
                }),
                data: {
                    ...state.data,
                    key: selectedAction.key,
                    actions: state.data.actions.map((a, i) => {
                        if (a.uniqKey === uniqKey) {
                            return {
                                uniqKey,
                                index: selectedAction.index,
                                key: selectedAction.key,
                                payload: selectedAction.customFields.reduce((acc, curr) => {
                                    if (curr.defaultValue) {
                                        acc[curr.key] = curr.defaultValue;
                                    }

                                    return acc;
                                }, {}),
                            };
                        }

                        return a;
                    }),
                },
                validationErrors,
            };
        },
        [CREATE_EVENT_TRIGGER_TOGGLE_ACTION_CUSTOM_FIELD_EXPANDER]: (state, action) => {
            const { fieldAreaUniqKey, key } = action.payload;

            return {
                ...state,
                actionCustomFieldAreas: state.actionCustomFieldAreas.map((v) => {
                    if (v.uniqKey === fieldAreaUniqKey) {
                        v.selectedAction.customFields = v.selectedAction.customFields.map((c) => {
                            if (c.key === key) {
                                c.isFolded = !c.isFolded;
                            }

                            return c;
                        });
                    }

                    return v;
                }),
            };
        },
        [CREATE_EVENT_TRIGGER_ADD_ACTION_CUSTOM_FIELD_AREA]: (state, action) => {
            const actions = state.selectedConfiguration.actions;
            const hideContents = action.hideContents;
            const filteredActions = actions.filter((a) => {
                if (hideContents) {
                    return a.key !== "/stage/jobs/start-transcoding";
                }
                return true;
            });
            const selectedAction = filteredActions[0];
            const selectedActionCustomFieldArea = {
                uniqKey: uniqId(),
                selectedAction: selectedAction,
                options: filteredActions,
            };

            const validationErrors = state.validationErrors;
            selectedActionCustomFieldArea.selectedAction = correctSelectedAction(
                selectedActionCustomFieldArea.uniqKey,
                selectedAction,
                validationErrors,
            );
            const actionCustomFieldAreas = state.actionCustomFieldAreas.concat([selectedActionCustomFieldArea]);
            return {
                ...state,
                data: {
                    ...state.data,
                    actions: state.data.actions.concat([
                        {
                            uniqKey: selectedActionCustomFieldArea.uniqKey,
                            index: selectedActionCustomFieldArea.selectedAction.index,
                            key: selectedActionCustomFieldArea.selectedAction.key,
                            payload: selectedActionCustomFieldArea.selectedAction.customFields.reduce((acc, curr) => {
                                if (curr.defaultValue) {
                                    acc[curr.key] = curr.defaultValue;
                                }

                                return acc;
                            }, {}),
                        },
                    ]),
                },
                actionCustomFieldAreas,
                validationErrors,
            };
        },
        [CREATE_EVENT_TRIGGER_REMOVE_ACTION_CUSTOM_FIELD_AREA]: (state, action) => {
            const { fieldAreaUniqKey } = action.payload;

            const validationErrors = state.validationErrors;
            Object.keys(validationErrors).forEach((v) => {
                if (v.indexOf(`actions-${fieldAreaUniqKey}`) > -1) {
                    delete validationErrors[v];
                }
            });

            return {
                ...state,
                data: {
                    ...state.data,
                    actions: state.data.actions.filter((v) => v.uniqKey !== fieldAreaUniqKey),
                },
                actionCustomFieldAreas: state.actionCustomFieldAreas.filter((v) => v.uniqKey !== fieldAreaUniqKey),
                validationErrors,
            };
        },
        [CREATE_EVENT_TRIGGER_UPDATE_ACTION_CUSTOM_FIELD]: (state, action) => {
            const { fieldAreaUniqKey, key, value } = action.payload;

            return {
                ...state,
                data: {
                    ...state.data,
                    actions: state.data.actions.map((v, i) => {
                        if (v.uniqKey === fieldAreaUniqKey) {
                            v.payload[key] = value;
                        }

                        return v;
                    }),
                },
            };
        },
    },
    initialState,
);

export const reset = createAction(CREATE_EVENT_TRIGGER_RESET);
export const setValidationError = createAction(CREATE_EVENT_TRIGGER_SET_VALIDATION_ERROR);
export const updateData = createAction(CREATE_EVENT_TRIGGER_UPDATE_DATA);
export const updateConditionCustomField = createAction(CREATE_EVENT_TRIGGER_UPDATE_CONDITION_CUSTOM_FIELD);
export const toggleConditionCustomFieldExpander = createAction(
    CREATE_EVENT_TRIGGER_TOGGLE_CONDITION_CUSTOM_FIELD_EXPANDER,
);
export const setConfigurationTemplate = createAction(CREATE_EVENT_TRIGGER_SET_CONFIGURATION_TEMPLATE);
export const updateActionCustomFieldArea = createAction(CREATE_EVENT_TRIGGER_UPDATE_ACTION_CUSTOM_FIELD_AREA);
export const toggleActionCustomFieldExpander = createAction(CREATE_EVENT_TRIGGER_TOGGLE_ACTION_CUSTOM_FIELD_EXPANDER);
export const removeActionCustomFieldArea = createAction(CREATE_EVENT_TRIGGER_REMOVE_ACTION_CUSTOM_FIELD_AREA);
export const updateActionCustomField = createAction(CREATE_EVENT_TRIGGER_UPDATE_ACTION_CUSTOM_FIELD);

export const addActionCustomFieldArea = () => (dispatch, getState) => {
    const { stage } = getState();
    const hideContents = stage && stage.extensions && stage.extensions["HIDE_CONTENTS"] === "true";
    dispatch({
        type: CREATE_EVENT_TRIGGER_ADD_ACTION_CUSTOM_FIELD_AREA,
        hideContents,
    });
};

export const createEventTrigger = (projectId, data) => async (dispatch, getState) => {
    const { stage, createEventTrigger } = getState();

    dispatch({ type: CREATE_EVENT_TRIGGER_PENDING });

    return new Promise((resolve, reject) => {
        const parsedData = JSON.parse(JSON.stringify(data));

        parsedData.actions = parsedData.actions.map((a) => {
            const matchedAction = createEventTrigger.selectedConfiguration.actions
                .filter((sa) => sa.index === a.index)
                .reduce((acc, curr) => curr, {});
            const payload = correctPayload(a.payload);

            return {
                ...a,
                payload: {
                    ...payload,
                    ...replaceCustomTags(matchedAction.automaticValues, payload),
                },
            };
        });

        createEventTriggerAPI(stage.id, stage.endpoint, projectId, parsedData)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                dispatch({
                    type: CREATE_EVENT_TRIGGER_FAILURE,
                    payload: error,
                });

                reject(error);
            });
    });
};
