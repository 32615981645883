import React from "react";
import { createDownloadJob, getDownloadJobs } from "@modules/downloadJobs";
import { setNotification } from "@modules/notification";
import DownloadInfoMessage from "@routes/share/ui/DownloadInfoMessage";
import { useDispatch } from "react-redux";

const useDownloadJobs = () => {
    const dispatch = useDispatch();

    const toastInfoMessage = (folders, fileName, totalCount, isDirectDownloadRequest = false) => {
        dispatch(
            setNotification({
                type: "info",
                autoClose: false,
                contents: (
                    <DownloadInfoMessage
                        folders={folders}
                        fileName={fileName}
                        totalCount={totalCount}
                        isDirectDownloadMissMatchRegion={isDirectDownloadRequest === "MISS_MATCH_REGION"}
                    />
                ),
            }),
        );
    };
    const requestDownloadJob = async (domain, obj, targetsCount, options) => {
        try {
            const response = await dispatch(createDownloadJob(domain, obj, options));

            const jobId = response?.data?.id;
            const jobStatus = response?.data?.status;
            await dispatch(getDownloadJobs([jobId]));

            toastInfoMessage("", obj.resourceName, 1, false);
        } catch (error) {
            console.trace(error);
        }
    };

    return {
        requestDownloadJob,
    };
};

export default useDownloadJobs;
