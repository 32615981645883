import React, { FC } from "react";
import { StyledEditName, StyledEditNameContent, StyledEditNameActions, MediaType } from "./edit-name.styled";
import { Grid, IconButton, Typography, Tooltip } from "@mzc-pdc/ui";
import { IconEditSmall } from "@mzc-cloudplex/icons";
import { useTranslation } from "react-i18next";
import TextField from "../text-field/text-field";

const EditName = ({
    type,
    name,
    isEdit,
    isEditable,
    mediaType,
    value,
    valueLength,
    onChange,
    onEditClick,
    actions,
}) => {
    const { t } = useTranslation();

    return (
        <StyledEditName type={type} valueLength={valueLength} isEdit={isEdit} isEditable={isEditable}>
            <StyledEditNameContent type={type} valueLength={valueLength}>
                {mediaType && (
                    <MediaType className={"media-type"} isEdit={isEdit} type={type}>
                        {mediaType}
                    </MediaType>
                )}
                {isEdit ? (
                    <TextField
                        type={"input"}
                        value={value}
                        onChange={onChange}
                        size={"medium"}
                        id=""
                        maxRows={4}
                        multiline
                        isEdit
                        inputStyle={{
                            fontSize: type === "panel" ? (valueLength > 107 ? "14px" : "16px") : `20px`,
                            fontWeight: `bold`,
                            height: `100%`,
                            lineHeight: type === "panel" ? "1.2" : "1.5",
                        }}
                        sx={{
                            "& textarea": {
                                minHeight: "0",
                            },
                        }}
                    />
                ) : (
                    <Typography variant={"h3"}>{name && name}</Typography>
                )}
                {name && isEditable && !isEdit && (
                    <Tooltip title={t(`common::label::${"Edit"}`)}>
                        <IconButton
                            className={"btn-edit"}
                            size={type === "panel" ? "xsmall" : "small"}
                            circled
                            variant={"dashed"}
                            onClick={onEditClick}
                        >
                            <IconEditSmall size={type === "panel" ? 12 : 16} />
                        </IconButton>
                    </Tooltip>
                )}
            </StyledEditNameContent>
            {isEdit && <StyledEditNameActions>{actions && actions}</StyledEditNameActions>}
        </StyledEditName>
    );
};

export default EditName;
