import charByteSize from './charByteSize';
import getByteLength from './getByteLength';

function cutByteLength(s, limit, length) {
    if (s == null || s.length == 0) {
        return "";
    }

    if(length === undefined) {
        length = getByteLength(s);
    }
    
    if(length <= limit) {
        return s;
    }

    let size = 0;
    let rIndex = s.length;
    
    for (var i = 0; i < s.length; i++) {
        size += charByteSize(s.charAt(i));
        if (size == limit) {
            rIndex = i + 1;
            break;
        } else if (size > limit) {
            rIndex = i;
            break;
        }
    }

    return s.substring(0, rIndex);
}

export default cutByteLength;