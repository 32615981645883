import { axios, axiosWithoutHeaders } from "../../cores/axiosWrapper";
import compareVersions, { VERSION_CM_7510_SAFARI_ENCODING } from "@cores/version";

export function listJobs(stageId, apiEndpoint, projectId, search, currentSession, types) {
    const { limit } = search;

    const params = {
        currentSession,
        types,
    };

    if (limit) {
        params.limit = limit;
    }

    return axios.get(`${apiEndpoint}/v2/jobs`, {
        params,
        headers: {
            stageId,
            projectId,
        },
    });
}

export function listTasks(stageId, apiEndpoint, projectId, jobId, currentSession) {
    const params = {
        currentSession,
    };

    return axios.get(`${apiEndpoint}/v2/jobs/${jobId}/tasks`, {
        params,
        headers: {
            stageId,
            projectId,
        },
    });
}

export function requestActions(apiEndpoint, stageId, projectId, actions) {
    return axios.post(`${apiEndpoint}/v2/jobs/actions`, actions, {
        headers: {
            stageId,
            projectId,
        },
    });
}

export function createJob(apiEndpoint, stageId, stageVersion, projectId, param, options) {
    let params = {
        ...param,
        ...options,
    };

    let headers = {
        stageId,
    };
    if (projectId) {
        headers.projectId = projectId;
    }

    if (compareVersions(stageVersion, VERSION_CM_7510_SAFARI_ENCODING) >= 0) {
        params = Buffer.from(encodeURIComponent(JSON.stringify(params))).toString("base64");
        headers = {
            ...headers,
            "x-mzc-content-encoding": "base64",
        };
    }

    return axios.post(`${apiEndpoint}/v2/downloads/jobs`, params, { headers });
}

export function postDownload(stageId, apiEndpoint, token, projectId, jobId, taskName) {
    const params = {
        taskName,
    };

    return axios.post(`${apiEndpoint}/v2/downloads/jobs/${jobId}/tasks:download-link`, params, {
        headers: {
            stageId,
            projectId,
        },
    });

    // axios로 redirect handling이 안됨.. fetch로는 가능해서 압축파일 다운로드에는 fetch 사용
    // return fetch(`${apiEndpoint}/v2/downloads/jobs/${jobId}/tasks:download-link`,
    //   {
    //       method: 'POST',
    //       headers: {
    //           stageId,
    //           projectId,
    //           'Content-Type': 'application/json',
    //           "authorization" : token
    //       },
    //       body: JSON.stringify(params),
    //       redirect: 'follow',
    //   }
    // )

    // return axios.post(`${apiEndpoint}/v2/downloads/jobs/${jobId}/tasks:download`, params, {
    //     headers: {
    //         stageId,
    //         projectId,
    //     }
    // });
}

export const JmsApi = {
    listTasks,
    listJobs,
    requestActions,
    createJob,
    postDownload,
};

export default JmsApi;
