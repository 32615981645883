import { isLocal } from "@cores/enviromentExtension";

export class Creator {
    static instance = null;
    static getInstance() {
        if (!this.instance) {
            this.instance = new SiteMode();
        }
        return this.instance;
    }
}

class SiteMode {
    mode = null;

    set(mode) {
        this.mode = mode;
    }

    get(renew = false) {
        if (!this.mode || renew) {
            this.mode =
                window.location.hostname.indexOf("cms.cloudplex.") !== -1 ||
                (process.env.REACT_APP_VIEW_MODE ? process.env.REACT_APP_VIEW_MODE.trim() === "CMS" : false)
                    ? "CMS"
                    : "CONSOLE";
        }
        return this.mode;
    }
    isCmsSupported() {
        return isLocal || window.location.hostname.indexOf("console.cloudplex.") !== -1 || window.location.hostname.indexOf("cms.cloudplex.") !== -1;
    }
}

export const siteMode = Creator.getInstance();
