import React, { Component } from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import { withTranslation, Trans } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Loadable from "@react-loadable/revised";
import * as assetsActions from "@modules/assets";
import * as videosActions from "@modules/videos";
import * as propertyPanelActions from "@modules/propertyPanel";
import * as StreamForLineupsActions from "@modules/streamForLineups";
import * as sharesActions from "@modules/shares";
import * as sharedActions from "@modules/shared";
import { CATEGORY_TYPES, ASSET_TYPES, PROPERTY_PANEL_TYPE, MEDIA_TYPES } from "@constants";
import OutsideClickHandler from "react-outside-click-handler";
import { isExternalElement } from "@cores/isExternalElement";
import compareVersions, { VERSION_CM_8075_SHARE_REQUEST, VERSION_CM_8742_ASSET_SHARE_REQUEST } from "@cores/version";
import * as sourcesActions from "@modules/sources";
import * as integratedSearchWithSEActions from "@modules/integratedSearchWithSE";
import LineupPage from "@components/property-panel/ui/LineupPage";
import StorageSharedBoxPanel from "@routes/sources/SharedBoxPanel";
import ExternalShareHistoryPanel from "@routes/sources/external-share-history-panel";
import StoragePanel from "@routes/sources/StoragePanel";
import CustomPanel from "@components/property-panel/components/custom-panel";
import ListingsItemListPanel from "@components/property-panel/components/listings-item-list-panel";
import ListingsLineupDetailPanel from "@components/property-panel/components/listings-lineup-detail-panel";
import menuService from "@services/menuService";
import PropertyPanelV2 from "@features/property-panel/property-panel-v2";
import { getPropertyPanelCollectionData } from "@features/property-panel/property-panel-collection-data";

const LoadableHiddenMetadataPermissionConfirmModal = Loadable({
    loader: () => import("../modals/WarningConfirm"),
    loading: () => null,
});

let TAB_TYPE = {
    OVERVIEW: "Overview",
    METADATA: "Metadata",
    RELATED_ASSETS: "Related Assets",
    AUDIO: "Multi Audio",
    RELATED_VIDEOS: "Related Videos",
};

class PropertyPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: TAB_TYPE.OVERVIEW,
            relatedAssets: [],
            relatedVideos: [],
            isShowHiddenMetataPermission: false,
            isTooltipSelectSourceGuideActive: false,
            mount: false,
            sharer: [],
            sharedUsers: [],
            sharedTeams: [],
            sharedStatusActiveTab: `users`,
            storagePresignedUrl: null,
            sharedResourceId: null,
        };
    }

    componentDidMount = async () => {
        const { location, stage } = this.props;
        const { spaceId, projectId } = menuService.parse(location.pathname);

        const prevState = {
            ...this.state,
        };

        location.pathname.indexOf("/cms") === 0
            ? this.setState({
                  ...prevState,
                  parentPath: menuService.paths.cms(spaceId, projectId),
                  parentPathName: "CMS",
              })
            : this.setState({
                  ...prevState,
                  parentPath: menuService.paths.contents(spaceId, projectId),
                  parentPathName: "Contents",
              });
    };

    componentDidUpdate = async (prevProps) => {
        const { shares, propertyPanel, PropertyPanelActions, SharedActions, stage } = this.props;

        if (propertyPanel.error || propertyPanel.pending || propertyPanel.accessError) return;

        if (this.props.location.pathname !== prevProps.location.pathname) {
            await PropertyPanelActions.updateState({
                targetId: null,
                isShow: false,
                type: null,
                contents: {
                    id: null,
                    type: null,
                },
            });
        }

        const prevTargetId = prevProps.propertyPanel.targetId;
        const curTargetId = propertyPanel.targetId;

        if (curTargetId && prevTargetId !== curTargetId) {
            //here : asset, contents부분. 하위 컴포넌트에서 각자 update?
            if (
                propertyPanel.type !== PROPERTY_PANEL_TYPE.LISTINGS_LINEUP_DETAIL &&
                propertyPanel.type !== PROPERTY_PANEL_TYPE.STORAGE &&
                propertyPanel.type !== PROPERTY_PANEL_TYPE.COLLECTION &&
                !this.isSharedStorageType(propertyPanel.type)
            ) {
                PropertyPanelActions.getData({
                    type: propertyPanel.type,
                    id: propertyPanel.targetId,
                    projectId: propertyPanel.projectId,
                });
                this.getRelatedAssetsAsync();
                if (propertyPanel.type === PROPERTY_PANEL_TYPE.PEOPLE) this.getRelatedVideosAsync();
                // }
                this.setState({
                    activeTab: TAB_TYPE.OVERVIEW,
                });
            } else if (propertyPanel.type === PROPERTY_PANEL_TYPE.STORAGE) {
                this.setState({ ...this.state, storagePresignedUrl: null });
                const { prefix, bucket, name } = propertyPanel.data;
                const url = await PropertyPanelActions.presignObject({
                    bucket,
                    prefix: prefix.slice(0, prefix.length - 1),
                    fileName: name,
                });
                this.setState({
                    activeTab: TAB_TYPE.OVERVIEW,
                    storagePresignedUrl: url,
                });
            }
        }

        if (this.panelTabs && this.panelTabArrow && this.panelTabs.scrollWidth > this.panelTabs.clientWidth) {
            this.panelTabArrow.style.display = "flex";
        }
    };

    getRelatedVideosAsync = async () => {
        const { project, propertyPanel, VideosActions } = this.props;
        try {
            const response = await VideosActions.getVideos(project.id, {
                peopleId: propertyPanel.targetId,
                offset: 0,
                limit: 99999,
            });
            this.setState({
                relatedVideos: response.videos || [],
            });
        } catch (error) {
            console.error(error);
        }
    };

    getRelatedAssetsAsync = async (jobId, projectId) => {
        //here: asset 하위 목록들의 연관 에셋 로딩
        //컴포넌트에 따라 분리 필요
        const { project, propertyPanel, AssetsActions, SharesActions } = this.props;
        // TODO: 연결된 asset이 엄청 많을 경우가 있으려나? 대책이 필요하군
        try {
            if (
                propertyPanel.type === PROPERTY_PANEL_TYPE.STORAGE ||
                this.isSharedStorageType(propertyPanel.type)
                // this.isSharedAssetType(propertyPanel.type)
            )
                return;
            if (propertyPanel.type === PROPERTY_PANEL_TYPE.PEOPLE) {
                const response = await AssetsActions.getAssets(project.id, {
                    peopleId: propertyPanel.targetId,
                    offset: 0,
                    limit: 99999,
                });
                this.setState({
                    relatedAssets: response.assets || [],
                });
            } else if (propertyPanel.type === PROPERTY_PANEL_TYPE.VIDEO) {
                const response = await AssetsActions.getAssets(project.id, {
                    videoId: propertyPanel.targetId,
                    offset: 0,
                    limit: 99999,
                });
                this.setState({
                    relatedAssets: response.assets || [],
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    convertToCategoryType = (panelType) => {
        if (Object.keys(ASSET_TYPES).indexOf(panelType) !== -1) {
            return CATEGORY_TYPES.ASSET;
        } else {
            return panelType;
        }
    };

    onClickClose = () => {
        //here : 이건 내려줘야 함
        const { StreamForLineupsActions, PropertyPanelActions, IntegratedSearchWithSEActions, propertyPanel, stage } =
            this.props;

        PropertyPanelActions.updateState({
            key: null,
            targetId: null,
            isShow: false,
        });

        switch (propertyPanel.type) {
            case PROPERTY_PANEL_TYPE.LISTINGS_LINEUP_DETAIL:
                StreamForLineupsActions.setLineupUnselected();
                break;
            case PROPERTY_PANEL_TYPE.STORAGE:
                this.setState({ ...this.state, storagePresignedUrl: null });
                if (compareVersions(stage.version, VERSION_CM_8075_SHARE_REQUEST) > 0)
                    IntegratedSearchWithSEActions.setShareRequestData(null);
                break;
            case PROPERTY_PANEL_TYPE.ASSET:
                if (compareVersions(stage.version, VERSION_CM_8742_ASSET_SHARE_REQUEST) > 0)
                    IntegratedSearchWithSEActions.setShareRequestData(null);
                break;
            default:
                break;
        }
    };

    onClickTab = (tab) => {
        this.setState({
            activeTab: tab,
        });
    };

    onClickLineupContents = () => {
        const { PropertyPanelActions } = this.props;

        PropertyPanelActions.openPropertyPanel({
            type: PROPERTY_PANEL_TYPE.LISTINGS_ITEM_LIST,
        });
    };

    onClickLineupDetail = () => {
        const { streamForLineups } = this.props;

        this.props.PropertyPanelActions.openPropertyPanel({
            targetId: streamForLineups.selected.id,
            type: PROPERTY_PANEL_TYPE.LISTINGS_LINEUP_DETAIL,
        });
    };

    disabledLineupDetail = () => {
        const { streamForLineups } = this.props;

        return !(streamForLineups.selected && streamForLineups.selected.id);
    };

    onInputToogleActive = () => {
        this.setState({
            isTooltipSelectSourceGuideActive: !this.state.isTooltipSelectSourceGuideActive,
        });
    };

    shouldPropertyPanelClose = (e) => {
        const { propertyPanel, rightSideBar } = this.props;

        return propertyPanel.isShow && !rightSideBar.active && !isExternalElement(e);
    };

    getHiddenFieldValue = (metadataSchemaId, metadataFieldId) => {
        const { propertyPanel, PropertyPanelActions } = this.props;

        PropertyPanelActions.getHiddenMetadataValue(
            propertyPanel.data.id,
            propertyPanel.type,
            metadataSchemaId,
            metadataFieldId,
        ).catch((e) => {
            this.setState({
                ...this.state,
                isShowHiddenMetataPermission: true,
            });
        });
    };

    onClickConfirmHiddenMetataPermissionModal = () => {
        this.setState({
            ...this.state,
            isShowHiddenMetataPermission: false,
        });
    };

    renderHiddenMetadataPremissionMessage = () => {
        return (
            <React.Fragment>
                <Trans i18nKey="common::msg::You do not have permission to Show Hidden Metadata." />
                <br />
                <Trans i18nKey="common::msg::If you to Show Hidden Metadata, please ask your administration." />
            </React.Fragment>
        );
    };

    handleChangeSharedStatusTab = (value) => {
        this.setState({
            sharedStatusActiveTab: value,
        });
    };

    handleShareRequestModal = () => {
        const { IntegratedSearchWithSEActions } = this.props;
        IntegratedSearchWithSEActions.toggleShareRequestModal();
    };

    getContentViewData = () => {
        const { propertyPanel, SourcesActions } = this.props;
        const { storagePresignedUrl } = this.state;

        if (!propertyPanel.data) return;

        if (propertyPanel.type === PROPERTY_PANEL_TYPE.MUSIC) {
            const sources = propertyPanel.data.sources;
            const isPreferredAsset = sources && sources.find((v) => v.isPreferred);
            return isPreferredAsset ? isPreferredAsset.item : sources && sources[0] && sources[0].item;
        } else if (propertyPanel.type === PROPERTY_PANEL_TYPE.PHOTO) {
            const sources = propertyPanel.data.sources;
            const elements =
                sources &&
                sources.map((source) => {
                    return {
                        isPreferred: source.isPreferred,
                        accessUrl: source.item.accessUrl,
                    };
                });

            return {
                elements,
            };
        } else if (propertyPanel.type === PROPERTY_PANEL_TYPE.STORAGE) {
            if ([MEDIA_TYPES.AUDIO, MEDIA_TYPES.VIDEO].includes(propertyPanel.data.mediaType)) {
                return {
                    ...propertyPanel.data,
                    elements: [
                        {
                            accessUrl: undefined,
                            accessUrls: [storagePresignedUrl],
                        },
                    ],
                };
            } else {
                return {
                    ...propertyPanel.data,
                    elements: [
                        {
                            accessUrl: storagePresignedUrl,
                            accessUrls: undefined,
                        },
                    ],
                };
            }
        } else {
            return propertyPanel.data;
        }
    };

    isSharedStorageType = (type) => {
        return [PROPERTY_PANEL_TYPE.SHARED_STORAGE_BY, PROPERTY_PANEL_TYPE.SHARED_STORAGE_WITH].includes(type);
    };

    // isSharedAssetType = (type) => {
    //     return [PROPERTY_PANEL_TYPE.SHARED_ASSET_BY, PROPERTY_PANEL_TYPE.SHARED_ASSET_WITH].includes(type);
    // };

    render() {
        const { propertyPanel } = this.props;

        const contentsViewData = this.getContentViewData();
        return (
            <>
                <div className={`panel panel-push property-panel ${propertyPanel.isShow ? "open" : ""}`}>
                    {this.state.isShowHiddenMetataPermission &&
                        ReactDOM.createPortal(
                            <LoadableHiddenMetadataPermissionConfirmModal
                                onClickConfirm={this.onClickConfirmHiddenMetataPermissionModal}
                                renderMessage={(props) => this.renderHiddenMetadataPremissionMessage()}
                            />,
                            document.body,
                        )}
                    <OutsideClickHandler
                        onOutsideClick={(e) => {
                            if (this.shouldPropertyPanelClose(e)) {
                                this.onClickClose();
                            }
                        }}
                    >
                        {this.props.isLineupPage && (
                            <LineupPage
                                onClickLineupContents={this.onClickLineupContents}
                                disabledLineupDetail={this.disabledLineupDetail}
                                onClickLineupDetail={this.onClickLineupDetail}
                                isShow={propertyPanel.isShow}
                                type={propertyPanel.type}
                            ></LineupPage>
                        )}

                        {propertyPanel.type == PROPERTY_PANEL_TYPE.LISTINGS_LINEUP_DETAIL && (
                            <ListingsLineupDetailPanel onClickClose={this.onClickClose}></ListingsLineupDetailPanel>
                        )}

                        {propertyPanel.type == PROPERTY_PANEL_TYPE.LISTINGS_ITEM_LIST && (
                            <ListingsItemListPanel
                                isTooltipSelectSourceGuideActive={this.state.isTooltipSelectSourceGuideActive}
                                onClickClose={this.onClickClose}
                                onInputToogleActive={this.onInputToogleActive}
                            ></ListingsItemListPanel>
                        )}

                        {this.isSharedStorageType(propertyPanel.type) && (
                            <StorageSharedBoxPanel
                                title={PROPERTY_PANEL_TYPE.STORAGE}
                                onClickClose={this.onClickClose}
                                handleShareRequestModal={this.handleShareRequestModal}
                            ></StorageSharedBoxPanel>
                        )}

                        {propertyPanel.type == PROPERTY_PANEL_TYPE.EXTERNAL_SHARED_HISTORY && (
                            <ExternalShareHistoryPanel
                                title={PROPERTY_PANEL_TYPE.EXTERNAL_SHARED_HISTORY}
                                onClickClose={this.onClickClose}
                                handleShareRequestModal={this.handleShareRequestModal}
                            ></ExternalShareHistoryPanel>
                        )}

                        {propertyPanel.type == PROPERTY_PANEL_TYPE.STORAGE && (
                            <StoragePanel
                                onClickClose={this.onClickClose}
                                convertToCategoryType={this.convertToCategoryType}
                                handleShareRequestModal={this.handleShareRequestModal}
                                contentsViewData={contentsViewData}
                            ></StoragePanel>
                        )}

                        {(propertyPanel.type == PROPERTY_PANEL_TYPE.ASSET ||
                            propertyPanel.type == PROPERTY_PANEL_TYPE.SHARED_ASSET_BY ||
                            propertyPanel.type == PROPERTY_PANEL_TYPE.SHARED_ASSET_WITH ||
                            propertyPanel.type == PROPERTY_PANEL_TYPE.ARCHIVED_ASSET ||
                            propertyPanel.type == PROPERTY_PANEL_TYPE.VIDEO ||
                            propertyPanel.type == PROPERTY_PANEL_TYPE.PEOPLE ||
                            propertyPanel.type == PROPERTY_PANEL_TYPE.MUSIC ||
                            propertyPanel.type == PROPERTY_PANEL_TYPE.PHOTO) && (
                            <CustomPanel
                                convertToCategoryType={this.convertToCategoryType}
                                handleShareRequestModal={this.handleShareRequestModal}
                                onClickClose={this.onClickClose}
                                activeTab={this.state.activeTab}
                                onClickTab={this.onClickTab}
                                panelTabArrow={this.panelTabArrow}
                                getHiddenFieldValue={this.getHiddenFieldValue}
                                relatedAssets={this.state.relatedAssets}
                                relatedVideos={this.state.relatedVideos}
                                parentPath={this.state.parentPath}
                                contentsViewData={contentsViewData}
                                location={location}
                            ></CustomPanel>
                        )}
                        {(propertyPanel.type === PROPERTY_PANEL_TYPE.COLLECTION ||
                            propertyPanel.type === PROPERTY_PANEL_TYPE.CUSTOM_CONTENT) && (
                            <PropertyPanelV2 onClickClose={this.onClickClose} />
                        )}
                    </OutsideClickHandler>
                </div>
            </>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        global: state.global,
        user: state.user,
        stage: state.stage,
        project: state.project,
        assets: state.assets,
        people: state.people,
        streamForLineups: state.streamForLineups,
        propertyPanel: state.propertyPanel,
        rightSideBar: state.rightSideBar,
        shares: state.shares,
        shared: state.shared,
    };
};

let mapDispatchToProps = (dispatch) => {
    return {
        AssetsActions: bindActionCreators(assetsActions, dispatch),
        VideosActions: bindActionCreators(videosActions, dispatch),
        PropertyPanelActions: bindActionCreators(propertyPanelActions, dispatch),
        StreamForLineupsActions: bindActionCreators(StreamForLineupsActions, dispatch),
        SharesActions: bindActionCreators(sharesActions, dispatch),
        SharedActions: bindActionCreators(sharedActions, dispatch),
        SourcesActions: bindActionCreators(sourcesActions, dispatch),
        IntegratedSearchWithSEActions: bindActionCreators(integratedSearchWithSEActions, dispatch),
    };
};

PropertyPanel = connect(mapStateToProps, mapDispatchToProps)(PropertyPanel);
export default withTranslation()(withRouter(PropertyPanel));
