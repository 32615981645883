import {createAction, handleActions} from 'redux-actions';
import {
    axios,
    axiosWithoutHeaders
} from "../cores/axiosWrapper";

function getAttachedStreamAPI(stageId, apiEndpoint, projectId, listingId) {
    return axios.get(`${apiEndpoint}/streams`, {
        params: {
            listingId
        },
        headers : {
            stageId,
            projectId
        }
    });
}

function getStreamAPI(stageId, apiEndpoint, projectId, id) {
    return axios.get(`${apiEndpoint}/streams/${id}`, {
        headers : {
            stageId,
            projectId
        }
    });
}

const LISTINGS_STREAMS_PENDING = 'LISTINGS_STREAMS_PENDING';
const LISTINGS_STREAMS_FAILURE = 'LISTINGS_STREAMS_FAILURE';
const GET_LISTINGS_STREAMS_SUCCESS = 'GET_LISTINGS_STREAMS_SUCCESS';
const GET_LISTINGS_STREAM_SUCCESS = 'GET_LISTINGS_STREAM_SUCCESS';
const UPDATE_LISTINGS_STREAM_SELECTED = 'UPDATE_LISTINGS_STREAM_SELECTED';
const UPDATE_LISTINGS_STREAM_ITEM = 'UPDATE_LISTINGS_STREAM_ITEM';
const DELETED_LISTINGS_STREAM_ITEM = 'DELETED_LISTINGS_STREAM_ITEM';
const SET_LISTINGS_STREAM_COUNT = 'SET_LISTINGS_STREAM_COUNT';

const initialState = {
    pending: false,
    error: false,
    data: null,
    item : null,
    selected : {
        id : ''
    },
    standardDateTime : null,
    counts : {
        detail : 0,
        list : 0
    }
};

export default handleActions({
    [LISTINGS_STREAMS_PENDING]: (state) => {
        return {
            ...state,
            pending: true,
            error: false
        }
    },
    [LISTINGS_STREAMS_FAILURE]: (state, action) => {
        return {
            ...state,
            pending: false,
            error: true,
            data: action.payload
        }
    },
    [GET_LISTINGS_STREAMS_SUCCESS]: (state, action) => {
        const {data} = action.payload;  
        return {
            ...state,
            pending: false,
            error: false,
            data: {
                streams : data.streams.map(v => {
                    return {
                        ...v,
                        format: v.type
                    }
                }),
                totalCount : data.totalCount
            }
        };
    },
    [GET_LISTINGS_STREAM_SUCCESS]:(state, action) => {
        const {response, standardDateTime} = action.payload;

        return {
            ...state,
            pending:false,
            error: false,
            item : response.data,
            standardDateTime,
            counts : {
                ...state.counts,
                detail : state.counts.detail + 1
            }
        }
    },
    [UPDATE_LISTINGS_STREAM_SELECTED]: (state, action) => {
        return {
            ...state,
            pending: false,
            error: false,
            selected: action.payload,
            counts : {
                ...state.counts,
                detail : state.counts.detail + 1
            }
        }
    },
    [UPDATE_LISTINGS_STREAM_ITEM] :(state, action) => {
        return {
            ...state,
            pending:false,
            error:false,
            item : {
                ...state.item,
                ...action.payload
            }
        }
    },
    [DELETED_LISTINGS_STREAM_ITEM] :(state) => {
        return {
            ...state,
            pending: false,
            error: false,
            item : null,
            selected : {
                id : ''
            }
        }
    },
    [SET_LISTINGS_STREAM_COUNT]:(state, action) => {
        return {
            ...state,
            count : {
                ...state.count,
                ...action.payload
            }
        }
    }
}, initialState);

export const getStreams = (listingId) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    dispatch({type: LISTINGS_STREAMS_PENDING});

    return getAttachedStreamAPI(stage.id, stage.endpoint, project.id, listingId).then(
        (response) => {
            dispatch({
                type: GET_LISTINGS_STREAMS_SUCCESS,
                payload: response
            })
        }
    ).catch(error => {
        dispatch({
            type: LISTINGS_STREAMS_FAILURE,
            data: error
        });
    })
};
export const updateSelected = createAction(UPDATE_LISTINGS_STREAM_SELECTED);
export const getStream = (projectId, standardDateTime) => (dispatch, getState) => {
    const {
        stage,
        listingsStreams
    } = getState();

    if (listingsStreams.selected.id === '') {
        return;
    }

    dispatch({type: LISTINGS_STREAMS_PENDING});

    return getStreamAPI(stage.id, stage.endpoint, projectId, listingsStreams.selected.id).then(
        (response) => {
            dispatch({
                type: GET_LISTINGS_STREAM_SUCCESS,
                payload: {
                    response,
                    standardDateTime
                }
            });
        }
    ).catch(error => {
        dispatch({
            type: LISTINGS_STREAMS_FAILURE,
            payload: error
        });
    })
};
export const deletedItem = createAction(DELETED_LISTINGS_STREAM_ITEM);
export const updateItem = createAction(UPDATE_LISTINGS_STREAM_ITEM);
export const setCount = createAction(SET_LISTINGS_STREAM_COUNT);