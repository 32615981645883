import React, { useEffect, useMemo, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mzc-pdc/ui";
import TooltipButton from "@components_v2/tooltip-button/tooltip-button";
import { IconCancel, IconClose } from "@mzc-cloudplex/icons";
import theme from "@styles/theme";
import { useTranslation } from "react-i18next";
import { AutocompleteTable } from "@components_v2/autocomplete";
import AutocompleteSmallTableList from "@features/autocomplete-table/autocomplete-small-table-list";
import SkeletonAssetsTable from "@features/autocomplete-table/autocomplete-table-skeleton/autocomplete-table-skeleton-assets";
import SkeletonCollectionsTable from "@features/autocomplete-table/autocomplete-table-skeleton/autocomplete-table-skeleton-collections";
import SkeletonContentsTable from "@features/autocomplete-table/autocomplete-table-skeleton/autocomplete-table-skeleton-contents";
import {
    getTableValueByAssetColumn,
    getTableValueByCollectionColumn,
    getTableValueByContentColumn,
} from "@features/autocomplete-table/autocomplete-table-columns-component/autocomplete-table-columns-component";
import { updateCustomContentActions } from "@modules/custom-content";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Tooltip from "@components_v2/Tooltip/tooltip";
import { setNotification } from "@modules/notification";
import { capitalize } from "@utils/convert-string";

const DialogAddCustomContentResources = ({
    resource,
    columns,
    onClose,
    onFetchData,
    onSearchData,
    pending,
    results,
    viewMore,
    hideCheckbox,
    onSubmitCallback,
}) => {
    const { t } = useTranslation();
    const MAX_RESOURCES = resource.type === "PREVIEW" ? 1 : 50;
    const dispatch = useDispatch();
    const { contentId } = useParams();
    const [selectedResources, setSelectedResources] = useState([]);
    const [actionPending, setActionPending] = useState(false);
    const [searchValue, setSearchValue] = useState({ searchedValue: null, debouncedValue: null });

    const titleType = useMemo(() => {
        switch (resource.type) {
            case "ASSET":
                return "assets";
            case "CONTENT":
                return "contents";
            case "COLLECTION":
                return "collections";
            case "PREVIEW":
                return "preview image";
            default:
                return "";
        }
    }, [resource]);

    const contentTitleType = useMemo(() => {
        switch (resource.type) {
            case "ASSET":
            case "PREVIEW":
                return "Asset";
            case "CONTENT":
                return "Content";
            case "COLLECTION":
                return "Collection";
            default:
                return "";
        }
    }, [resource]);

    // const onHandleSearchResources = (value) => {
    //     if (value !== searchValue.searchedValue) {
    //         setSearchValue({ ...searchValue, searchedValue: value });
    //     }
    // };

    const onHandleFetchResources = () => {
        onFetchData(resource.detailType);
    };

    const onHandleSubmitResources = async () => {
        try {
            setActionPending(true);
            const resourceId = resource.id;
            const ids = selectedResources?.map((v) => v.id);

            if (resource.type !== "PREVIEW") {
                const params = {
                    key: "fields",
                    value: [
                        { id: resource.id, value: Array.from(new Set(ids.concat(resource.originalResources ?? []))) },
                    ],
                };

                await dispatch(updateCustomContentActions({ contentId, params }));
                dispatch(
                    setNotification({
                        type: "success",
                        contents: t(
                            `common::msg::{{resourceName}} '{{name}}' has been added.`,
                            `{{resourceName}} '{{name}}' has been added.`,
                            {
                                resourceName: resource.name,
                                name: selectedResources?.[0].id,
                            },
                        ),
                    }),
                );
                setActionPending(false);
            }
            onClose();
            await onSubmitCallback(resourceId, ids);
        } catch (e) {
            console.log(e);
            dispatch(
                setNotification({
                    type: "error",
                    contents: e.response
                        ? `[Error:${e.response.status}] => ${e.response?.data?.message}`
                        : `Internal Error Occured`,
                }),
            );
            setActionPending(false);
        }
    };

    const onHandleSelectResources = async (checkedList) => {
        if (checkedList.length > 0) {
            setSelectedResources([...selectedResources, ...checkedList]);
        } else {
            setSelectedResources([checkedList]);
        }
    };

    const onHandleGetValueByColumns = (id, option) => {
        switch (resource.type) {
            case "ASSET":
            case "PREVIEW":
                return getTableValueByAssetColumn(id, option);
            case "CONTENT":
                return getTableValueByContentColumn(id, option);
            case "COLLECTION":
                return getTableValueByCollectionColumn(id, option);
            default:
                return null;
        }
    };

    const onHandleSkeleton = useMemo(() => {
        switch (resource.type) {
            case "ASSET":
            case "PREVIEW":
                return <SkeletonAssetsTable columns={columns.filter((item) => item.useInAutocomplete)} />;
            case "CONTENT":
                return <SkeletonContentsTable columns={columns.filter((item) => item.useInAutocomplete)} />;
            case "COLLECTION":
                return <SkeletonCollectionsTable columns={columns.filter((item) => item.useInAutocomplete)} />;
            default:
                return null;
        }
    }, [columns]);

    const getFilterList = useMemo(() => {
        const resourceDetailType = resource?.detailType?.split("_")?.[0] ?? null;
        switch (resource.type) {
            case "ASSET":
                return [
                    {
                        id: "mediaType",
                        label: resourceDetailType,
                        title: "Media type",
                        value: resourceDetailType,
                        type: "types",
                    },
                ];
            case "CONTENT":
                return [
                    {
                        id: "contentType",
                        label: resourceDetailType,
                        title: "Content type",
                        value: resourceDetailType,
                        type: "types",
                    },
                ];
            default:
                return [];
        }
    }, [columns]);

    // useEffect(() => {
    //     if (!resourceType.detailType) return;
    //     onFetchData(resourceType.detailType);
    // }, [resourceType]);

    const onHandleDeleteResources = (id) => {
        const newResources = selectedResources.filter((el) => el.id !== id);
        setSelectedResources(newResources);
    };

    const isFilterDisabled = useMemo(() => {
        // 모든 에셋 대상이 아닌 경우 filter 사용 안함
        return resource.detailType.split("_")?.length > 1;
    }, [resource]);

    // useEffect(() => {
    //     const searchedValue = searchValue.searchedValue;
    //     if (searchedValue === null) return;
    //     const debounce = setTimeout(() => {
    //         return setSearchValue({ ...searchValue, debouncedValue: searchedValue });
    //     }, 300);
    //     return () => clearTimeout(debounce);
    // }, [searchValue.searchedValue]);
    //
    // useEffect(() => {
    //     const search = searchValue.debouncedValue;
    //     console.log(search);
    //     onSearchData(resource.detailType, search);
    // }, [searchValue.debouncedValue]);

    return (
        <Dialog
            onClose={() => onClose()}
            open={true}
            maxWidth={"sm"}
            sx={{ ".MuiDialog-container >.MuiPaper-root": { minWidth: "600px" } }}
        >
            <DialogTitle>
                <Grid item>
                    <Typography variant="h3">
                        {t(`common::label::Add {{name}}?`, `Add {{name}}?`, {
                            name: t(`common::label::${titleType}`, titleType),
                        })}
                    </Typography>
                </Grid>
                <TooltipButton
                    title={"취소 및 닫기"}
                    onClickButton={onClose}
                    buttonIcon={<IconClose size={20} htmlColor={theme.palette.secondary[700]} />}
                />
            </DialogTitle>
            <DialogContent>
                <Grid flexDirection={"column"} container sx={{ width: "552px" }}>
                    <Grid item flex={1} width={"inherit"}>
                        {columns && (
                            <AutocompleteTable
                                domain={`customContent${capitalize(resource.type, true)}`}
                                type={"table"}
                                key={"autocomplete-add-custom-content-resource-table"}
                                placement={"bottom-end"}
                                placeholder={t(`common::msg::Please search by name or ID.`)}
                                onSubmit={onHandleSelectResources}
                                getValueByColumn={onHandleGetValueByColumns}
                                onDebounceSearch={(search) => onSearchData(resource.detailType, search)}
                                onFetchData={onHandleFetchResources}
                                columns={columns}
                                hideCheckbox={hideCheckbox}
                                skeletonColumns={onHandleSkeleton}
                                viewMore={viewMore}
                                options={results}
                                isPending={pending}
                                selectedItems={selectedResources}
                                filter={getFilterList}
                                filterDisabled={isFilterDisabled}
                            />
                        )}
                    </Grid>
                    <Grid item mt={2} mb={1} flex={1} width={"inherit"}>
                        <Typography variant={"body1"} color={theme.palette.secondary[800]}>
                            {t(`common::label::Selected {{referenceType}}s`, `Selected`, {
                                referenceType: t(`common::label::${titleType}`, titleType),
                            })}{" "}
                            {MAX_RESOURCES > 1 && (
                                <>
                                    <Typography
                                        variant={"body1"}
                                        color={
                                            selectedResources.length > MAX_RESOURCES
                                                ? "#ef4016"
                                                : theme.palette.primary[400]
                                        }
                                    >
                                        {selectedResources.length}
                                    </Typography>
                                    /{MAX_RESOURCES} {t(`common::label::max`)}
                                </>
                            )}
                        </Typography>
                    </Grid>
                    <Grid item flex={1} width={"inherit"}>
                        <AutocompleteSmallTableList
                            type={resource.type === "PREVIEW" ? "asset" : resource.type?.toLowerCase()}
                            data={selectedResources}
                            columns={columns}
                            deleteButton={
                                <Tooltip title={t(`common::label::Delete`)}>
                                    <IconButton
                                        size="medium"
                                        color={"inherit"}
                                        circled
                                        onClick={(e) => {
                                            onHandleDeleteResources(e.target.closest("li").dataset.id);
                                        }}
                                    >
                                        <IconCancel size={12} htmlColor={theme.palette.grey[700]} />
                                    </IconButton>
                                </Tooltip>
                            }
                            noDataText={t(
                                `common::msg::Select {{resourceType}} through search to put them in your custom content.`,
                                `Select {{resourceType}} through search to put them in your custom content.`,
                                {
                                    resourceType: t(`common::label::${titleType}`, titleType),
                                },
                            )}
                            divider={true}
                            height={"336px"}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    onClick={(e) => {
                        e.stopPropagation();
                        onClose();
                    }}
                    variant="text"
                >
                    {t(`common::label::${"Cancel"}`)}
                </Button>
                <Button
                    color="primary"
                    onClick={() => onHandleSubmitResources()}
                    disabled={selectedResources?.length === 0 || actionPending}
                    loading={actionPending}
                    variant="contained"
                >
                    {t(`common::label::${"Add"}`)}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogAddCustomContentResources;
