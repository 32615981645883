function replaceCustomTags (template, data, customTagFormat = /\${[(\w)]+}/g) {
    if (template === undefined || data === undefined) {
        return template;
    }

    if (typeof template === "string") {
        const matchedCustomTags = template.match(customTagFormat);   

        matchedCustomTags &&
            matchedCustomTags.forEach(customTag => {
                const customTagKey = customTag.match(/\w+/g)[0];

                const value = data[customTagKey];
                if (value === undefined) {
                    // empty
                } else if (typeof value !== "object") {
                    template = template.replace(customTag, value);
                } else {
                    template = value;
                }
            });
    } else if (Array.isArray(template)) {
        template = template.map(obj => {
            return replaceCustomTags(obj, data);
        });
    } else if (typeof template === "object") {
        Object.keys(template).forEach(k => {
            template[k] = replaceCustomTags(template[k], data);
        });
    }

    return template;
};

export default replaceCustomTags;