import React from "react";
import clsx from "clsx";
import {
    IconAudioType,
    IconCaptionType,
    IconFileType,
    IconPhotoMultiType,
    IconVideoType
} from "@mzc-cloudplex/icons";

const FormatBadge = ({format, label, style, hasIcon, mediaType}) => {
    if (!format) {
        return null;
    }
    let revisedLabel = label;
    let badgeClass = '';
    let iconClass = '';
    let _format = format.replace(/\s/gi, '');

    let convertedMediaType = String(mediaType).toLowerCase();
    if (convertedMediaType === "text") convertedMediaType = "caption";
    const mediaTypeFlag = mediaType ? `format-flag-${convertedMediaType}` : '';

    switch (_format.toUpperCase()) {
        case 'VIDEO':
            iconClass= <IconVideoType size={11} htmlColor={`#fff`}/>
            badgeClass = 'format-flag format-flag-mp4';
            break;
        case 'HLS' :
            badgeClass = 'format-flag format-flag-hls';
            break;
        case 'HLSVOD' :
            badgeClass = 'format-flag format-flag-hls';
            if(!revisedLabel)
                revisedLabel = 'HLS';
            break;
        case 'MP4':
            badgeClass = 'format-flag format-flag-mp4';
            break;
        case 'DASH':
            badgeClass = 'format-flag format-flag-dash';
        case 'MPD':
            badgeClass = 'format-flag format-flag-dash';
            break;
        case 'THUMBNAIL':
            badgeClass = 'format-flag format-flag-thumbnail';
            label = 'IMAGE';
            break;
        case 'THUMBNAILS':
            badgeClass = 'format-flag format-flag-thumbnail';
            label = 'IMAGE';
            break;
        case 'IMAGE' :
            iconClass= <IconPhotoMultiType size={11} htmlColor={`#fff`}/>
            badgeClass = 'format-flag format-flag-thumbnail';
            break;
        case 'FRAME_CAPTURE':
            badgeClass = 'format-flag format-flag-thumbnail';
            break;
        case 'AAC':
            badgeClass = 'format-flag format-flag-mp3';
            break;
        case 'MP3':
            badgeClass = 'format-flag format-flag-mp3';
            break;
        case 'AUDIO':
            iconClass= <IconAudioType size={11} htmlColor={`#fff`}/>
            badgeClass = 'format-flag format-flag-audio';
            break;
        case 'H_264':
            badgeClass = 'format-flag format-flag-hls';
            break;
        case 'MPD':
            badgeClass = 'format-flag format-flag-hls';
            break;
        case 'MSS' :
            badgeClass = 'format-flag format-flag-mss';
            break;
        case 'CMAF':
            badgeClass = 'format-flag format-flag-cmaf';
            break;
        case 'FILE':
            iconClass= <IconFileType size={11} htmlColor={`#fff`}/>
            badgeClass = 'format-flag format-flag-file';
            break;
        case 'RTP':
            badgeClass = 'format-flag format-flag-rtp';
            break;
        case 'RTP_PUSH':
            badgeClass = 'format-flag format-flag-rtp';
            if(!revisedLabel)
                revisedLabel = 'RTP(PUSH)';
            break;
        case 'RTMP_PUSH':
            badgeClass = 'format-flag format-flag-rtmp_push';
            if(!revisedLabel)
                revisedLabel = 'RTMP(PUSH)';
            break;
        case 'RTMP_PULL':
            badgeClass = 'format-flag format-flag-rtmp_pull';
            if(!revisedLabel)
                revisedLabel = 'RTMP(PULL)';
            break;
        case 'RTP(PUSH)':
            badgeClass = 'format-flag format-flag-rtp';
            if(!revisedLabel)
                revisedLabel = 'RTP(PUSH)';
            break;
        case 'RTMP(PUSH)':
            badgeClass = 'format-flag format-flag-rtmp_push';
            if(!revisedLabel)
                revisedLabel = 'RTMP(PUSH)';
            break;
        case 'RTMP(PULL)':
            badgeClass = 'format-flag format-flag-rtmp_pull';
            if(!revisedLabel)
                revisedLabel = 'RTMP(PULL)';
            break;
        case 'LIVE' :
            badgeClass = 'format-flag format-flag-live';
            break;
        case 'URL_PULL' :
            badgeClass = 'format-flag format-flag-hls';
            if(!revisedLabel)
                revisedLabel = 'HLS';
            break;
        case 'MP4_FILE' :
            badgeClass = 'format-flag format-flag-mp4';
            if(!revisedLabel)
                revisedLabel = 'MP4';
            break;
        case 'TEXT' :
        case 'CAPTION' :
            iconClass= <IconCaptionType size={11} htmlColor={`#fff`}/>
            badgeClass = 'format-flag format-flag-vtt';
            break;
        case 'VTT' :
            badgeClass = 'format-flag format-flag-vtt';
            break;
        case 'FOLDER' :
            badgeClass = 'format-flag format-flag-folder';
            break;
        default:
            badgeClass = 'format-flag';
            break;
    }
    return (
        <span className={clsx(badgeClass, mediaTypeFlag)} style={style || {}}>
            {hasIcon && iconClass}
            {revisedLabel ? revisedLabel : (_format === 'HLSVOD' ? 'HLS' : _format)}
        </span>
    )
};

export default FormatBadge;