import { Box, Button, Chip, Grid, IconButton, Popover } from "@mzc-pdc/ui";
import { alpha, Avatar } from "@mui/material";
import theme from "@styles/theme";
import {
    IconAddReaction,
    IconChevronRight,
    IconDelete,
    IconEditSmall,
    IconMoreHoriz,
    IconReply,
} from "@mzc-cloudplex/icons";
import EditorComment from "@features/comments/editor-comment";
import Typography from "@components_v2/typography/typography";
import TooltipButton from "@components_v2/tooltip-button/tooltip-button";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useUserDetail from "@hooks/useUserDetail";
import UserDetailPopper from "@features/user-detail-popper/user-detail-popper";
import { Trans, useTranslation } from "react-i18next";
import EmojiPickerPopper from "@features/comments/emoji-picker-popper";
import moment from "moment/moment";
import "moment/locale/ja";
import "moment/locale/ko";
import CloseConfirm from "@components/modals/WarningCancelDiscard";
import { RandomColoredAvatar } from "@components_v2/avatar/random-colored-avatar";
import CommentDate from "@features/comments/comment-date";
import useCommentSelector from "@hooks/useCommentSelector";
import CommentAttachment from "@features/comments/comment-attachment";

const CardCommentContent = ({
    isUploading,
    domain,
    onChangeComment,
    onChangeAttachments,
    onUpdateComment,
    onDeleteComment,
    onCreateActions,
    onDeleteActions,
    onDeleteAttachments,
    onInitComment,
    updateIsEditingCommentId,
    updateSelectedCommentId,
    data,
    editComment,
    editAttachments,
    comment,
    isThread,
    emojiButton,
    isEditable,
}) => {
    const {
        handleUserProfileClick,
        handleUserProfileClose,
        userProfileAnchorEl,
        openUserProfile,
        userProfileData,
        userProfilePending,
    } = useUserDetail();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [isShowActionButton, setIsShowActionButton] = useState(false);
    const [isOpenEmojiPicker, setIsOpenEmojiPicker] = useState(false);
    const [isShowEditor, setIsShowEditor] = useState(false);
    const [emojiPickerAnchorEl, setEmojiPickerAnchorEl] = useState(null);
    const [showCloseConfirm, setShowCloseConfirm] = useState(false);
    const [pendingEmojis, setPendingEmojis] = useState([]);

    const { isEditingCommentId, selectedThreadId, selectedComment } = useCommentSelector({ domain });
    const user = useSelector(({ stage }) => stage.user);

    const actionButtonRef = useRef(null);
    const reactionButtonRef = useRef(null);

    const isCommentDeleted = useMemo(() => data?.isDeleted, [data]);

    const emojiGroup = useMemo(() => {
        if (!data?.reactions?.length) return {};
        return data?.reactions?.reduce((acc, reaction) => {
            if (reaction.value in acc) {
                acc[reaction.value] = {
                    count: acc[reaction.value].count + 1,
                    selected: !acc[reaction.value].selected ? user.id === reaction.created?.by?.id : true,
                };
            } else {
                acc[reaction.value] = { count: 1, selected: user.id === reaction.created?.by?.id };
            }
            return acc;
        }, {});
    }, [data?.reactions, user]);

    const onCloseEmojiPicker = () => {
        setEmojiPickerAnchorEl(null);
        setIsOpenEmojiPicker(false);
    };

    const onHandleClickEmoji = async (emojiObject) => {
        try {
            if (pendingEmojis.includes(emojiObject.emoji)) return;

            const isExist = data?.reactions?.find(
                (reaction) => reaction.value === emojiObject.emoji && reaction.created?.by?.id === user.id,
            );
            setPendingEmojis([...pendingEmojis, emojiObject.emoji]);

            if (isExist) await onDeleteActions(data, emojiObject.emoji);
            else await onCreateActions(data, emojiObject.emoji);

            setPendingEmojis(pendingEmojis.filter((emoji) => emoji !== emojiObject.emoji));

            await onCloseEmojiPicker();
            if (isShowActionButton) setIsShowActionButton(false);
        } catch (error) {
            console.error(error);
        }
    };

    const onHandleClickReaction = (type) => {
        if (type === "reaction") setEmojiPickerAnchorEl(reactionButtonRef.current);
        else if (type === "action") setEmojiPickerAnchorEl(actionButtonRef.current);
        setIsOpenEmojiPicker(!isOpenEmojiPicker);
    };

    const onHandleClickUpdateComment = async (data) => {
        await onUpdateComment(data);
        onHandleClickShowEditor(false);
    };

    const onHandleClickThreadButton = () => {
        if (data) updateSelectedCommentId(data?.id);
    };

    const renderCloseWarningMessage = useCallback(() => {
        return (
            <React.Fragment>
                <Trans i18nKey="common::msg::Discard unsaved changes?" />
            </React.Fragment>
        );
    }, []);

    const onHandleClickDeleteComment = () => {
        setIsShowActionButton(false);
        onDeleteComment(data);
    };

    const canEditComment = useMemo(() => user.id === data?.created?.by?.id, [user, data]);

    useEffect(() => {
        if (isShowEditor) {
            setIsShowActionButton(false);
        }
    }, [isShowEditor]);

    useEffect(() => {
        if (isEditingCommentId && isEditingCommentId === data?.id) setIsShowEditor(true);
        else setIsShowEditor(false);
    }, [isEditingCommentId]);

    useEffect(() => {
        moment.locale(localStorage.getItem("i18nLng") || "en");
    }, []);

    const onHandleClickShowEditor = (value) => {
        if (value) {
            if (!isEditingCommentId) updateIsEditingCommentId(data?.id);
            if (isEditingCommentId && isEditingCommentId !== data.id) setShowCloseConfirm(true);
        } else {
            onInitComment();
            updateIsEditingCommentId(null);
        }
    };

    const onConfirmCancelEdit = (value) => {
        if (value) {
            updateIsEditingCommentId(data?.id);
        }
        setShowCloseConfirm(false);
    };

    return (
        <>
            {isOpenEmojiPicker && (
                <EmojiPickerPopper
                    open={isOpenEmojiPicker}
                    disablePortal={false}
                    placement={"bottom-end"}
                    anchorEl={emojiPickerAnchorEl}
                    onClose={onCloseEmojiPicker}
                    onEmojiClick={onHandleClickEmoji}
                />
            )}
            <Grid item sx={{ flex: "0 0 32px", mr: "12px" }}>
                <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
                    {isCommentDeleted ? (
                        <Avatar
                            sx={{
                                width: "32px",
                                height: "32px",
                                backgroundColor: alpha(theme.palette.greySecondary[50], 0.5),
                                color: theme.palette.greySecondary[500],
                            }}
                        >
                            <IconDelete size={20} />
                        </Avatar>
                    ) : (
                        <RandomColoredAvatar size={32} id={data?.created?.by?.id} name={data?.created?.by?.name} />
                    )}
                </Box>
            </Grid>
            <Grid item sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                {isShowEditor ? (
                    <Box
                        sx={{
                            borderRadius: "4px",
                            border: `1px solid ${theme.palette.primary.main}`,
                            boxShadow: `0px 2px 4px 0px ${alpha(theme.palette.common.black, 0.16)}`,
                        }}
                    >
                        <EditorComment
                            domain={domain}
                            id={`${isThread ? "thread" : "reply"}-update-editor`}
                            chat={editComment}
                            attachments={editAttachments}
                            isChanged={editComment !== comment}
                            onChange={onChangeComment}
                            onChangeAttachments={onChangeAttachments}
                            onSubmit={() => onHandleClickUpdateComment(data)}
                            initShowEditor={true}
                            onClose={() => onHandleClickShowEditor(false)}
                        />
                    </Box>
                ) : (
                    <>
                        <Grid
                            item
                            flexWrap={"nowrap"}
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                            sx={{ display: "flex", width: "100%", flex: `0 0 32px` }}
                        >
                            <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
                                {isCommentDeleted ? (
                                    <Typography
                                        variant={`body1_400`}
                                        color={theme.palette.greySecondary[600]}
                                        component={`span`}
                                    >
                                        {t(`common::msg::This message was deleted.`, `This message was deleted.`)}
                                    </Typography>
                                ) : (
                                    <>
                                        <Button
                                            variant={`solid`}
                                            color={"secondary"}
                                            onClick={(e) => {
                                                if (!isUploading) handleUserProfileClick(e, data?.created?.by?.id);
                                            }}
                                            sx={{ minWidth: 0, mr: 1 }}
                                        >
                                            <Typography variant={`body1_700`}>{data?.created?.by?.name}</Typography>
                                        </Button>
                                        <CommentDate date={data?.created?.at} />
                                    </>
                                )}
                            </Box>
                            <Box hidden={isCommentDeleted || !isEditable || isUploading}>
                                <Box ref={actionButtonRef}>
                                    <IconButton size={"small"} circled onClick={() => setIsShowActionButton(true)}>
                                        <IconMoreHoriz size={16} />
                                    </IconButton>
                                </Box>
                                <Popover
                                    open={isShowActionButton}
                                    anchorEl={actionButtonRef.current}
                                    anchorOrigin={{
                                        vertical: "center",
                                        horizontal: "right",
                                    }}
                                    transformOrigin={{
                                        vertical: "center",
                                        horizontal: "right",
                                    }}
                                    disablePortal={true}
                                    onClose={() => setIsShowActionButton(false)}
                                >
                                    <Box
                                        className={"action-button-popover-comment"}
                                        sx={{
                                            backgroundColor: theme.palette.greySecondary.main,
                                            color: theme.palette.common.white,
                                            justifyContent: "space-evenly",
                                            gap: "6px",
                                            padding: "4px 6px",
                                            display: "flex",
                                        }}
                                    >
                                        <TooltipButton
                                            title={t(`common::label::Reply to thread`, `Reply to thread`)}
                                            buttonIcon={<IconReply size={16} />}
                                            onClickButton={onHandleClickThreadButton}
                                            size={"small"}
                                            hidden={isThread}
                                        />
                                        <TooltipButton
                                            title={t(`common::label::Edit message`, `Edit message`)}
                                            buttonIcon={<IconEditSmall size={16} />}
                                            onClickButton={() => onHandleClickShowEditor(true)}
                                            hidden={!canEditComment}
                                            size={"small"}
                                        />
                                        <TooltipButton
                                            title={t(`common::label::Add reaction`, `Add reaction`)}
                                            buttonIcon={<IconAddReaction size={16} />}
                                            onClickButton={() => onHandleClickReaction("action")}
                                            size={"small"}
                                        />
                                        <TooltipButton
                                            title={t(`common::label::Delete message`, `Delete message`)}
                                            buttonIcon={<IconDelete size={16} />}
                                            onClickButton={onHandleClickDeleteComment}
                                            hidden={!canEditComment}
                                            size={"small"}
                                        />
                                    </Box>
                                </Popover>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            sx={{
                                width: "100%",
                                flex: 1,
                                ":has(.mention)": {
                                    lineHeight: theme.spacing(3.45),
                                },
                            }}
                            dangerouslySetInnerHTML={{ __html: comment }}
                            hidden={isCommentDeleted}
                        />
                        {data?.attachments?.length > 0 && (
                            <Grid item>
                                <CommentAttachment
                                    threadInfo={{
                                        threadId: isThread ? selectedComment?.subThread?.id : selectedThreadId,
                                        isThread,
                                    }}
                                    commentId={data?.id}
                                    attachments={data?.attachments ?? []}
                                    isUploading={isUploading}
                                    onClickDelete={onDeleteAttachments}
                                    canDelete={canEditComment}
                                />
                            </Grid>
                        )}
                        <Grid>
                            {emojiButton && !isCommentDeleted && isEditable && data?.reactions?.length > 0 && (
                                <Grid item sx={{ mt: 2 }}>
                                    <Grid container flexDirection={"row"} gap={0.5}>
                                        {Object.keys(emojiGroup).map((key) => (
                                            <>
                                                <Chip
                                                    size={"small"}
                                                    sx={{
                                                        backgroundColor: emojiGroup[key].selected
                                                            ? alpha(theme.palette.primary.main, 0.1)
                                                            : "transparent",
                                                        color: emojiGroup[key].selected
                                                            ? theme.palette.primary.main
                                                            : undefined,
                                                        cursor: "pointer",
                                                        "&:hover": {
                                                            backgroundColor: alpha(theme.palette.primary.main, 0.12),
                                                        },
                                                    }}
                                                    label={`${key} \u00A0 ${emojiGroup[key].count}`}
                                                    onClick={() => onHandleClickEmoji({ emoji: key })}
                                                />
                                            </>
                                        ))}
                                        <IconButton
                                            ref={reactionButtonRef}
                                            circled
                                            variant={"outlined"}
                                            size={"small"}
                                            sx={{ ml: 1.5 }}
                                            onClick={() => onHandleClickReaction("reaction")}
                                        >
                                            <IconAddReaction size={16} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            )}
                            {!isThread && isEditable && data?.subThread?.commentCount > 0 && (
                                <Grid item mt={2}>
                                    <Button
                                        variant={`solid`}
                                        color={"primary"}
                                        size="xsmall"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onHandleClickThreadButton();
                                        }}
                                        endIcon={<IconChevronRight size={16} />}
                                        sx={{
                                            ".MuiButton-endIcon": { ml: 0.5, mt: 0.25 },
                                            fontSize: theme.typography.body3.fontSize,
                                            fontWeight: theme.typography.body3_500.fontWeight,
                                        }}
                                    >
                                        {t(`common::label::{{commentCount}} replies`, `{{commentCount}} replies`, {
                                            commentCount: data?.subThread?.commentCount,
                                        })}
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </>
                )}
            </Grid>

            {!userProfilePending && (
                <UserDetailPopper
                    id={openUserProfile ? "user-profile-popover" : undefined}
                    handleUserProfileClose={handleUserProfileClose}
                    userProfileAnchorEl={userProfileAnchorEl}
                    openUserProfile={openUserProfile}
                    userProfileData={userProfileData}
                />
            )}
            {showCloseConfirm && (
                <CloseConfirm
                    title={""}
                    onClose={onConfirmCancelEdit}
                    renderMessage={(props) => renderCloseWarningMessage()}
                />
            )}
        </>
    );
};

export default CardCommentContent;
