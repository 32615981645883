import {handleActions, createAction} from 'redux-actions';
import {
    axios,
    axiosWithoutHeaders
} from "../cores/axiosWrapper";
import queryString from "../cores/queryString";

function getAssetsAPI (stageId, apiEndpoint, projectId, params) {
    const headers = {
        stageId
    };

    if(projectId) {
        headers.projectId = projectId;
    }
    return axios.get(`${apiEndpoint}/assets?${queryString.stringify(params)}`, {
        headers
    });
}

const ASSETS_PENDING = 'ASSETS_PENDING';
const ASSETS_FAILURE = 'ASSETS_FAILURE';
const GET_ASSETS_FOR_LIST_SUCCESS = 'GET_ASSETS_FOR_LIST_SUCCESS';
const RESET_ASSETS = 'RESET_ASSETS';
const UPDATE_PARAMS = 'UPDATE_PARAMS';
const RESET_PARAMS = 'RESET_PARAMS';

const initialState = {
    pending: false,
    error: false,
    list: null,
    totalCount: 0,
    item : null,
    selectedIndex: -1,
    params: {
        id: '',
        jobId: '',
        type: 'MP4',
        mediaType: 'VIDEO',
        keywordType: 'NAME',
        keyword: '',
        beginDuration : '',
        endDuration: '',
        offset: 0,
        limit: 50,
        hasAssociations: true
    }
};

export default handleActions({
    [UPDATE_PARAMS]: (state, action) => {
        const newState = {
            ...state,
            params: {
                ...state.params,
                ...action.payload
            }
        }
        return newState;
    },
    [RESET_PARAMS]: (state, action) => {
        return {
            ...state,
            params: {
                ...initialState.params
            }
        }
    },
    [ASSETS_PENDING]: (state) => {
        return {
            ...state,
            pending: true,
            error: false
        }
    },
    [ASSETS_FAILURE]: (state) => {
        return {
            ...state,
            pending: false,
            error: true
        }
    },
    [GET_ASSETS_FOR_LIST_SUCCESS]: (state, action) => {
        let {data} = action.payload;

        return {
            ...state,
            pending: false,
            error: false,
            list: data && data.assets || [],
            totalCount: data.totalCount
        };
    },
    [RESET_ASSETS] :() => {
        return {
            ...initialState
        }
    }
}, initialState);
export const resetParams = createAction(RESET_PARAMS);
export const updateParams = createAction(UPDATE_PARAMS);
export const resetAssets = createAction(RESET_ASSETS);
export const getAssets = (projectId, params) => (dispatch, getState) => {
    const {
        stage
    } = getState();

    dispatch({type: ASSETS_PENDING});

    return getAssetsAPI(stage.id, stage.endpoint, projectId, params).then(
        (response) => {
            dispatch({
                type: GET_ASSETS_FOR_LIST_SUCCESS,
                payload: response
            });
        }
    ).catch(error => {
        dispatch({
            type: ASSETS_FAILURE
        });
    })
};