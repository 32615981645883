import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import validateInput from "@cores/validateInput";
import { LIMIT_BYTE } from "@constants";

const MAX_COUNT = 10;
const EMPTY_SPACE_REG_EXP = /[\r?\n | ]/i;

class HashtagInputArea extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: "",
            validationError: null,
        };
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        if (nextProps.navigator.active !== this.props.active) {
            return true;
        }

        if (nextProps.values !== this.props.values) {
            return true;
        }

        if (nextState !== this.state) {
            return true;
        }

        return false;
    };

    componentDidMount = () => {
        if (this.element) {
            this.resizeTextarea();
        }
        window.addEventListener("resize", this.handleResize);
    };

    componentWillUnmount = () => {
        window.removeEventListener("resize", this.handleResize);
    };

    componentDidUpdate = (prevProps, pervState) => {
        if (prevProps.values !== this.props.values) {
            this.setState({
                value: "",
                validationError:
                    !this.props.values || this.props.values.length === 0 ? null : this.state.validationError,
            });

            if (this.element && this.props.values && this.props.values.length === MAX_COUNT - 1) {
                this.element.focus();
            }
        }

        if (this.element) {
            this.resizeTextarea();
        }
    };

    handleResize = () => {
        this.resizeTextarea();
    };

    onChangeValue = (event) => {
        const isContainEmptySpace = EMPTY_SPACE_REG_EXP.test(event.target.value);

        const value = event.target.value.split(EMPTY_SPACE_REG_EXP)[0];
        let validationError = this.validate(value);

        if (this.props.type === "addUsers" && validationError === null && event.target.value.length > 0) {
            const re = new RegExp(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                "i",
            );
            validationError = !re.test(value) && {
                level: "error",
                message: "You must enter an email.",
            };
        }

        if (!validationError && isContainEmptySpace && value.length > 0) {
            this.props.onInputAddTag(value);
        }

        this.setState({
            value,
            validationError,
        });

        if (this.props.onChangeInput) {
            this.props.onChangeInput(event, validationError);
        }
    };

    reset = () => {
        this.setState({
            value: "",
            validationError: null,
        });
        this.element.focus();
    };

    resizeTextarea = () => {
        const { fixedParent } = this.props;
        const wrapperWidth = document.querySelector(".tags-inputs").clientWidth - 70;
        const tags = document.getElementsByClassName("tag");
        let tagTotalWidth = 0;
        let tagTotalCount = 0;
        for (let i = 0; i < tags.length; i++) {
            tagTotalWidth += tags[i].clientWidth;
            tagTotalCount++;
            if (wrapperWidth - tagTotalWidth - tagTotalCount * 5 - 200 < 0) {
                tagTotalWidth = 0;
                tagTotalCount = 0;
            }
        }
        let newWidth = wrapperWidth - tagTotalWidth - tagTotalCount * 5 - 70;
        if (fixedParent) {
            newWidth = fixedParent.clientWidth - 195;
        }
        // console.log('newWidth', newWidth)
        if (this.element) {
            this.element.style.width = `${newWidth}px`;
            this.element.style.height = this.element.value
                ? `${this.element.scrollHeight}px`
                : `${this.element.style.minHeight}`;
        }
    };

    validate = (value) => {
        return validateInput(value, {
            maxByte: LIMIT_BYTE.tag,
            uniqueIn: [value].concat(this.props.values),
        });
    };

    isLessThanMaxCount = () => {
        return !this.props.values || this.props.values.length < MAX_COUNT;
    };

    render = () => {
        const { t } = this.props;

        return (
            <div className="d-flex flex-column">
                <div
                    className={`tags-inputs ${this.props.type === "addUsers" && "tags-input-add-users"} ${
                        this.props.isEdit && "is-edit"
                    }`}
                >
                    <div className={`tags ${this.isLessThanMaxCount() ? "" : "warning"}`}>
                        {this.props.values && this.props.values.length > 0 && (
                            <React.Fragment>
                                {this.props.values.map((value, i) => (
                                    <div key={i} className={"tag"}>
                                        <span>{value}</span>
                                        <button
                                            type={"button"}
                                            onClick={() => {
                                                this.props.onInputRemoveTag(i);
                                            }}
                                        >
                                            <i className={"sprite sprite-cancel-fade"}></i>
                                        </button>
                                    </div>
                                ))}
                            </React.Fragment>
                        )}

                        {this.isLessThanMaxCount() && (
                            <React.Fragment>
                                <div
                                    className={`input ${
                                        this.state.validationError && this.state.validationError.level
                                    }`}
                                >
                                    <div
                                        className={`form-input form-${
                                            this.state.validationError && this.state.validationError.level
                                        }`}
                                    >
                                        <div>
                                            <textarea
                                                value={this.state.value}
                                                placeholder={
                                                    this.props.placeholder
                                                        ? t(`common::label::${this.props.placeholder}`)
                                                        : t(`common::label::${"Tags"}`)
                                                }
                                                ref={(ref) => (this.element = ref)}
                                                onChange={(e) => {
                                                    this.onChangeValue(e);
                                                }}
                                                onBlur={(e) => {
                                                    this.onChangeValue(e);
                                                }}
                                            ></textarea>
                                            {this.state.validationError && this.state.validationError.level && (
                                                <button
                                                    type={"button"}
                                                    onClick={() => {
                                                        this.reset();
                                                    }}
                                                >
                                                    <i className={"sprite sprite-delete"}></i>
                                                </button>
                                            )}
                                        </div>
                                        {this.state.validationError && this.state.validationError.level && (
                                            <div className={`form-message ${this.state.validationError.level}`}>
                                                <span>{this.state.validationError.message}</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                    {this.props.type === "addUsers" && this.props.values.length >= 1 && (
                        <button
                            type="button"
                            className="btn-all-remove"
                            onClick={() => {
                                this.props.onInputRemoveAllTag();
                            }}
                        >
                            <i className={"sprite sprite-cancel-source"}></i>
                        </button>
                    )}
                </div>

                {!this.isLessThanMaxCount() && (
                    <div className={"form-message warning"}>
                        <i className={"sprite sprite-notification mr-1"}></i>
                        <span className={"text-default"}>
                            {t(`common::msg::${'"Tags" can be up to {{count}}.'}`, { count: MAX_COUNT })}
                        </span>
                    </div>
                )}
            </div>
        );
    };
}

let mapStateToProps = (state) => {
    return {
        navigator: state.navigator,
    };
};

HashtagInputArea = connect(mapStateToProps)(HashtagInputArea);

export default withTranslation()(HashtagInputArea);
