import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { withTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import WithModal from "../../cores/WithModal";
import { setNotification } from "@modules/notification";
import StorageSyncTooltip from "@/components/tooltip/StorageSyncTooltip";
import {
    createDriveFolder,
    getModalFoldersList,
    resetModalSelectedFolder,
    searchModalFolderData,
    toggleNewFolderInModal,
    updateModalFolderSearchState,
    updateModalFolderState,
    updateModalSelectedFolder,
    initModalFolderData,
    createModalDriveFolder,
} from "@modules/folderTreeViewModal";
import FolderTreeView from "@components/tree/folder-tree-view";
import { DRIVE_FOLDER_SEARCH_TYPE } from "../../constants";
import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Grid,
    IconButton,
    Stack,
    Switch,
    Typography,
    Box,
    Tooltip,
    Button,
} from "@mzc-pdc/ui";
import theme from "@styles/theme";
import useActionPending from "@hooks/useActionPending";
import { IconClose, IconRefresh, IconFolderAdd, ImageEmptySearch } from "@mzc-cloudplex/icons";

const SelectFolderPath = (props) => {
    const {
        t,
        title,
        modalStyle,
        onClose,
        submitText,
        onSubmit,
        subFoldersOption = false,
        width,
        open,
        domain,
        useNewFolder = true,
    } = props;

    const {
        pending: createPending,
        setActionPending: setCreatePending,
        disableActionPending: disableCreatePending,
    } = useActionPending("createFolder");

    const [searchKeyword, setSearchKeyword] = useState("");
    const [selectedFolder, setSelectedFolder] = useState({ id: null, path: "" });
    const [checkClickFolder, setCheckClickFolder] = useState(false);
    const [buttonPending, setButtonPending] = useState(false);
    const [submitPending, setSubmitPending] = useState(false);
    const [checkSubFoldersOption, setCheckSubFoldersOption] = useState(true);

    const { folderList, folderTreeViewModal, driveList, driveId, projectId } = useSelector(
        ({ folderTreeViewModal, project }) => ({
            folderList: folderTreeViewModal.folderList,
            folderTreeViewModal: folderTreeViewModal,
            driveList: folderTreeViewModal.driveList,
            driveId:
                folderTreeViewModal.driveList?.data?.find((drive) => drive.resourceType?.type === domain?.toUpperCase())
                    ?.id ?? null,
            projectId: project.id,
        }),
    );

    const dispatch = useDispatch();

    const handleCreateFolder = async (name) => {
        try {
            if (!driveId || createPending) return;
            setCreatePending();
            const params = { name, parent: { id: selectedFolder.id } };
            await dispatch(createModalDriveFolder(driveId, params));

            const queryParameters = {
                parentId: selectedFolder.id,
                searchType: DRIVE_FOLDER_SEARCH_TYPE.DIRECT_CHILDREN,
            };
            const headerParams = { projectId: projectId };
            await dispatch(
                getModalFoldersList({
                    driveId: selectedFolder?.driveId ?? driveId,
                    queryParameters,
                    headerParams,
                }),
            ); //TODO: 드라이브가 여러개일 때 driveId 구할 수 있는 방법을 찾아야함
            dispatch(toggleNewFolderInModal(null));
            dispatch(
                setNotification({
                    type: "success",
                    contents: t(`common::msg::Creation of new folder {{item}} is completed.`, {
                        item: name,
                    }),
                }),
            );
        } catch (error) {
            const errorData = error && error.response && error.response.data;
            const errorMessage = errorData && errorData.message;
            const errorStatus = (error && error.response && error.response.status) || "Unknown";
            const errorContents = `- ${errorMessage}`;

            dispatch(
                setNotification({
                    type: "error",
                    contents:
                        t(`common::label::There was an error creating the new folder {{item}}.`, {
                            item: name,
                        }) + errorContents,
                }),
            );
        } finally {
            dispatch(toggleNewFolderInModal(null));
            disableCreatePending();
        }
    };

    const init = async () => {
        try {
            const headerParams = { projectId: projectId };

            const rootFolder = await dispatch(initModalFolderData({ headerParams, domain }));
            setSelectedFolder(rootFolder);
            dispatch(updateModalSelectedFolder(rootFolder));
            setCheckClickFolder(true);
        } catch (error) {
            console.log(error);
            dispatch(
                setNotification({
                    type: "error",
                    contents: error.response
                        ? `[Error:${error.response.status}] => ${error.response?.data?.message}`
                        : `Internal Error Occured`,
                }),
            );
        }
    };

    const onClickForderTree = async (item, isClickArrow) => {
        try {
            // console.log(item);
            setSelectedFolder(item);
            setCheckClickFolder(true);
            if (!isClickArrow || folderTreeViewModal.folderList.data[item.id].isFold) {
                const queryParameters = { parentId: item.id, searchType: DRIVE_FOLDER_SEARCH_TYPE.DIRECT_CHILDREN };
                const headerParams = { projectId: projectId };

                if (!isClickArrow) {
                    dispatch(updateModalSelectedFolder(item));
                }
                await dispatch(
                    getModalFoldersList({
                        driveId: item.driveId,
                        queryParameters,
                        headerParams,
                    }),
                );
            } else {
                dispatch(
                    updateModalFolderState({
                        [item.id]: { ...folderList.data[item.id], isFold: true },
                    }),
                );
            }
        } catch (error) {
            console.log(error);
        }
    };

    const onClickFolderTreeFilter = async () => {
        try {
            const keyword = searchKeyword;

            if (!keyword) return;

            const queryParameters = {
                name: keyword,
                searchType: DRIVE_FOLDER_SEARCH_TYPE.NAME,
            };

            const headerParams = { projectId: projectId };
            await dispatch(searchModalFolderData({ headerParams, queryParameters, domain }));

            await dispatch(
                updateModalFolderSearchState({
                    isSearch: true,
                }),
            );
        } catch (error) {
            console.log(error);
        }
    };

    const onClickResetFolderTreeFilter = async () => {
        try {
            const headerParams = { projectId: projectId };

            const rootFolder = await dispatch(initModalFolderData({ headerParams, domain }));
            onClickForderTree(rootFolder, false);
            await dispatch(
                updateModalFolderSearchState({
                    isSearch: false,
                }),
            );
            setSearchKeyword("");
        } catch (error) {
            console.log(error);
        }
    };

    const onClickRefreshFolderTreeFilter = () => {
        if (searchKeyword) {
            onClickFolderTreeFilter();
        } else {
            init();
        }
    };

    const handleClickCreateFolder = (value) => {
        if (!value) dispatch(toggleNewFolderInModal(null));
        else handleCreateFolder(value);
    };

    const getFullFolderPath = useMemo(() => {
        let node = selectedFolder;
        let result = [selectedFolder];
        if (!node || !folderList?.data) return;

        while (node?.depth > 0) {
            node = folderList.data[node.parent];
            result = [node, ...result];
        }

        return result;
    }, [selectedFolder, folderList]);

    const onHandleChangeSubFoldersOption = (e) => {
        setCheckSubFoldersOption(e.target.checked);
    };

    const onHandleClickSubmit = useCallback(async () => {
        setSubmitPending(true);
        await onSubmit({
            driveId: selectedFolder.driveId,
            folderId: selectedFolder.id,
            folderName: selectedFolder.name,
            folderPath: getFullFolderPath,
            includeSubFolders: checkSubFoldersOption,
        });
        setSubmitPending(false);
    }, [submitPending, selectedFolder, checkSubFoldersOption]);

    useEffect(() => {
        if (open) {
            init();
        }
        return () => dispatch(resetModalSelectedFolder());
    }, [open]);

    if (open !== undefined && !open) return null;
    return (
        <>
            <Dialog
                open={true}
                variant="basic"
                PaperProps={{
                    style: {
                        width: width ? width : 600,
                    },
                }}
            >
                <DialogTitle>
                    {title ? t(`common::label::${title}`) : t(`common::label::${"Select Storage"}`)}
                    {/* <IconButton
                        circled
                        disabled={buttonPending}
                        onClick={(e) => {
                            e.preventDefault();
                            onClose();
                        }}
                    >
                        <IconClose size={16} />
                    </IconButton> */}
                </DialogTitle>
                <DialogContent sx={{ "&&": { scrollbarGutter: "auto", overflow: "hidden" } }}>
                    <Grid container alignItems={"center"} justifyContent={"space-between"} mb={2}>
                        <Grid item>
                            <div className="input-search">
                                <input
                                    type="text"
                                    className={"form-control"}
                                    placeholder={t(`common::label::${"Search"}`)}
                                    name="keyword"
                                    onChange={(e) => setSearchKeyword(e.target.value)}
                                    onKeyPress={(e) => {
                                        if (e.code === "Enter") onClickFolderTreeFilter();
                                    }}
                                    value={searchKeyword}
                                />
                                <i className="sprite sprite-search-dark" />
                                {/* input value 가 있을때만 노출 */}
                                {searchKeyword && (
                                    <button
                                        type={"button"}
                                        className={"btn btn-icon-solid"}
                                        onClick={() => {
                                            onClickResetFolderTreeFilter();
                                        }}
                                    >
                                        <i className={"sprite sprite-cancel-source"} />
                                    </button>
                                )}
                                {/* //input value 가 있을때만 노출 */}
                            </div>
                        </Grid>

                        <Grid item>
                            <Tooltip title={t(`common::label::${"Refresh"}`)}>
                                <IconButton
                                    circled
                                    onClick={(e) => {
                                        onClickRefreshFolderTreeFilter();
                                    }}
                                >
                                    <IconRefresh size={16} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>

                    <Box
                        height={426}
                        border={1}
                        borderColor={"divider"}
                        pt={1}
                        sx={{
                            "&&": {
                                ".MuiTreeItem-root ": {
                                    ".MuiTreeItem-content": {
                                        borderRadius: 0,
                                    },
                                },
                            },
                        }}
                    >
                        {/* TODO */}
                        {/* data가 없을때  */}
                        {/* {folderList?.data === null && (
                            <Stack
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                height="100%"
                                width="100%"
                            >
                                <Typography variant="body1_400" color={theme.palette.greySecondary[400]}>
                                    {t(`common::label::${"Try creating a new folder to place the assets."}`)}
                                </Typography>
                            </Stack>
                        )} */}
                        {/* 검색결과가 없을때  */}
                        {/* {folderList?.data && folderList?.data?.length === 0 && (
                            <Stack
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                height="100%"
                                width="100%"
                                gap={2.5}
                            >
                                <ImageEmptySearch width={76} height={70} />
                                <Typography variant="body1_400" color={theme.palette.greySecondary[400]}>
                                    {t(`common::label::${"None of folder matched this search. Try another search."}`)}
                                </Typography>
                            </Stack>
                        )} */}
                        <FolderTreeView
                            isModal={true}
                            domain={domain}
                            driveList={driveList}
                            folderList={folderList}
                            popperMenu={false}
                            selectedFolder={selectedFolder?.id}
                            onClickForderTree={onClickForderTree}
                            onHandleCreateFolder={handleClickCreateFolder}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent={"space-between"}>
                        <Grid item>
                            {subFoldersOption ? (
                                <Grid container alignItems={"center"} ml={"-9px"}>
                                    <Grid item>
                                        <Switch
                                            color="success"
                                            checked={checkSubFoldersOption}
                                            onChange={onHandleChangeSubFoldersOption}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1_400" color={theme.palette.secondary[800]}>
                                            {checkSubFoldersOption
                                                ? t(
                                                      `common::label::${"Also include assets from subfolders"}`,
                                                      "Also include assets from subfolders",
                                                  )
                                                : t(
                                                      `common::label::${"Only assets in that folder are selected"}`,
                                                      "Only assets in that folder are selected",
                                                  )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Button
                                    variant="outlined"
                                    color="greySecondary"
                                    disabled={
                                        folderTreeViewModal.folderList.creatingParentFolder ||
                                        !folderTreeViewModal.folderList?.data
                                    }
                                    onClick={(e) => {
                                        dispatch(
                                            toggleNewFolderInModal(
                                                folderTreeViewModal.folderList.selectedFolder.folder,
                                            ),
                                        );
                                    }}
                                    hidden={!useNewFolder}
                                    startIcon={<IconFolderAdd size={16} />}
                                >
                                    {t(`common::label::${"New Folder"}`)}
                                </Button>
                            )}
                        </Grid>
                        <Grid item>
                            <Grid container gap={0.75}>
                                <Button
                                    color="greySecondary"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onClose();
                                    }}
                                    disabled={buttonPending}
                                >
                                    {t(`common::label::${"Cancel"}`)}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    loading={submitPending}
                                    disabled={
                                        !checkClickFolder ||
                                        folderTreeViewModal.folderList.creatingParentFolder ||
                                        submitPending
                                    }
                                    onClick={async (e) => {
                                        e.preventDefault();

                                        onHandleClickSubmit();
                                    }}
                                >
                                    {submitText ? t(`common::label::${submitText}`) : t(`common::label::${"Select"}`)}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default withTranslation()(WithModal(SelectFolderPath));
