import { Theme, CSSObject, styled, alpha } from "@mui/material/styles";
import theme from "@styles/theme";
import { TextField as MuiTextField } from "@mui/material";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";

export const StyledDatePicker = styled(MuiDatePicker, {
    shouldForwardProp: (prop) => !["width"].includes(String(prop)),
})(({ width }) => ({
    width: width ? `${width}px` : `100%`,
    "&:hover": {
        ".MuiFormLabel-root": {
            color: theme.palette.primary.main,
        },
    },
    ".MuiFormLabel-root": {
        transform: `translate(14px, -9px) scale(1)`,
        fontSize: `12px`,
        color: theme.palette.greySecondary[600],
        "&.Mui-focused": {
            color: theme.palette.primary.main,
        },
        "&:hover": {
            "+ .MuiInputBase-root ": {
                ".MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.palette.primary.main,
                },
            },
        },
        "&[data-shrink=false] ": {
            "+ .MuiInputBase-root ": {
                ".MuiOutlinedInput-input": {
                    "&::placeholder": {
                        opacity: `.42 !important`,
                    },
                },
            },
        },
    },
    ".Mui-error": {
        "&:after": {
            content: '""',
            position: `absolute`,
            right: `48px`,
            display: `block`,
            width: `24px`,
            height: `24px`,
            transform: `scale(0.5835)`,
            backgroundImage: `url("data:image/svg+xml,%3Csvg id='icon_circle_error' xmlns='http://www.w3.org/2000/svg' width='24.001' height='24' viewBox='0 0 24.001 24'%3E%3Cpath id='icon' class='cls-2' d='M12,24A12,12,0,1,1,24,12,12.013,12.013,0,0,1,12,24ZM10.5,10.5V18h3V10.5Zm0-4.5V9h3V6Z' transform='translate(24.001 23.999) rotate(180)' fill='%23ef4116'/%3E%3C/svg%3E%0A")`,
        },
    },
    ".MuiInputBase-input": {
        border: `none`,
    },
    ".MuiInputAdornment-positionEnd": {
        paddingRight: 4,
        ".MuiSvgIcon-root.MzcCpIcon-root": {
            width: 16,
            height: 16,
            fill: theme.palette.greySecondary.main,
        },
    },
}));
//
// export const StyledTextField = styled(MuiTextField)(({ theme }) => ({
//     backgroundColor: `red`,
//     ".MuiInputBase-root": {
//         borderRadius: `2px`,
//     },
//     ".MuiInputAdornment-root": {
//         marginLeft: 0,
//         paddingRight: `8px`,
//         ".MuiSvgIcon-root": {
//             width: `16px`,
//             height: `16px`,
//         },
//     },
//     ".MuiOutlinedInput-notchedOutline": {
//         top: `0`,
//         borderWidth: `1px `,
//         borderColor: theme.palette.secondary.light,
//     },
//     ".MuiOutlinedInput-root": {
//         "&:hover": {
//             ".MuiOutlinedInput-notchedOutline": {
//                 borderColor: theme.palette.primary.main,
//             },
//         },
//         "&.Mui-focused": {
//             ".MuiOutlinedInput-notchedOutline": {
//                 borderWidth: `1px`,
//             },
//         },
//     },
//     fieldset: {
//         top: "0",
//         legend: {
//             height: 0,
//             maxWidth: `100%`,
//             span: {
//                 fontSize: `12px`,
//             },
//         },
//     },
//     ".MuiOutlinedInput-input": {
//         height: `40px`,
//         boxSizing: `border-box`,
//         fontSize: `14px`,
//         // textTransform: `uppercase`,
//     },
//     ".MuiFormHelperText-root": { fontSize: `14px`, color: theme.palette.error.main },
// }));
