import {handleActions, createAction} from 'redux-actions';
import {
    axios,
    axiosWithoutHeaders
} from "../cores/axiosWrapper";

function getInputAPI(stageId, apiEndpoint, projectId, channelId, includeWhitelist) {
    const params = {

    };
    if(includeWhitelist)
        params.includeWhitelist = true;
        
    return axios.get(`${apiEndpoint}/channels/${channelId}/inputs`, {
        params,
        headers : {
            stageId,
            projectId
        }
    });
}

function getChannelAPI(stageId, apiEndpoint, projectId, channelId, params) {
    return axios.get(`${apiEndpoint}/channels/${channelId}`, { 
        params,
        headers : {
            stageId,
            projectId
        }
    });
}

function putChannelAPI(stageId, apiEndpoint, projectId, channelId, params) {
    return axios.patch(`${apiEndpoint}/channels/${channelId}`, params, {
        headers : {
            stageId,
            projectId
        }
    });
}

function setChannelAPI(stageId, apiEndpoint, projectId, params) {
    return axios.post(`${apiEndpoint}/channels`, params, {
        headers : {
            stageId,
            projectId
        }
    });
}

function deleteChannelAPI(stageId, apiEndpoint, projectId, channelId) {
    return axios.delete(`${apiEndpoint}/channels/${channelId}`, {
        headers : {
            stageId,
            projectId
        }
    });
}

function reclaimChannelAPI(stageId, apiEndpoint, projectId, channelId, comment) {
    return axios.put(`${apiEndpoint}/channels/${channelId}/reclaim?comment=${encodeURIComponent(comment)}`, null, {
        headers : {
            stageId,
            projectId
        }
    });
}

function requestAPI(stageId, apiEndpoint, projectId, channelId, requestType, comment) {
    return axios.post(`${apiEndpoint}/channels/${channelId}/request?requestType=${requestType}&comment=${encodeURIComponent(comment)}`, null, {
        headers : {
            stageId,
            projectId
        }
    })
}

function handleCaseAPI(stageId, apiEndpoint, projectId, channelId, caseId, actionType, params, comment) {
    return axios.put(`${apiEndpoint}/channels/${channelId}/cases/${caseId}?actionType=${actionType}&comment=${encodeURIComponent(comment)}`, params, {
        headers : {
            stageId,
            projectId
        }
    })
}

function stopChannelsAPI(stageId, apiEndpoint, projectId, channelId) {
    return axios.put(`${apiEndpoint}/channels/${channelId}/stop`, null, {
        headers : {
            stageId,
            projectId,
            'Content-Type' : 'application/json'
        }
    });
}

function startChannelsAPI(stageId, apiEndpoint, projectId, channelId) {
    return axios.put(`${apiEndpoint}/channels/${channelId}/start`, null, {
        headers : {
            stageId,
            projectId,
            'Content-Type' : 'application/json'
        }
    });
}

function getLiveEncoderDetailAPI(stageId, apiEndpoint, projectId, channelId) {
    return axios.get(`${apiEndpoint}/channels/${channelId}/live-encoder-detail`, {
        headers : {
            stageId,
            projectId
        }
    });
}

function getChannelStateAPI(stageId, apiEndpoint, projectId, channelId) {
    return axios.get(`${apiEndpoint}/channels/${channelId}/state`, {
        headers : {
            stageId,
            projectId
        }
    });
}

const CHANNEL_PENDING = 'CHANNEL_PENDING';
const CHANNEL_FAILURE = 'CHANNEL_FAILURE';
const CHANNEL_INPUTS_FAILURE = "CHANNEL_INPUTS_FAILURE";
const CHANNEL_LIVE_ENCODER_DETAIL_FAILURE = "CHANNEL_LIVE_ENCODER_DETAIL_FAILURE";

const GET_CHANNEL_SUCCESS= 'GET_CHANNEL_SUCCESS';
const GET_CHANNEL_INPUTS_SUCCESS = 'GET_CHANNEL_INPUTS_SUCCESS';
const SET_CHANNEL_SUCCESS = 'SET_CHANNEL_SUCCESS';
const PUT_CHANNEL_SUCCESS = 'PUT_CHANNEL_SUCCESS';

const GET_CHANNEL_LIVE_ENCODER_DETAIL_SUCCESS = "GET_CHANNEL_LIVE_ENCODER_DETAIL_SUCCESS";

const DELETE_CHANNEL_SUCCESS = 'DELETE_CHANNEL_SUCCESS';
const PUT_CANCEL_CHANNEL_SUCCESS = 'PUT_CANCEL_CHANNEL_SUCCESS';
const PUT_RECLAIM_CHANNEL_SUCCESS = 'PUT_RECLAIM_CHANNEL_SUCCESS';
const PUT_RETURN_CHANNEL_SUCCESS = 'PUT_RETURN_CHANNEL_SUCCESS';
const PUT_APPROVAL_REQUEST_CHANNEL_SUCCESS = 'PUT_APPROVAL_REQUEST_CHANNEL_SUCCESS';
const PUT_ACCEPT_CHANNEL_SUCCESS = 'PUT_ACCEPT_CHANNEL_SUCCESS';
const PUT_REJECT_CHANNEL_SUCCESS = 'PUT_REJECT_CHANNEL_SUCCESS';
const POST_RETURN_CHANNEL_SUCCESS = 'POST_RETURN_CHANNEL_SUCCESS';

const INIT_CHANNEL = 'INIT_CHANNEL';
const RESET_CHANNEL = 'RESET_CHANNEL';
const UPDATE_CHANNEL = 'UPDATE_CHANNEL';
const UPDATE_CHANNEL_STATE = 'UPDATE_CHANNEL_STATE';

const initialState = {
    pending: false,
    error: false,
    errorStatus : null,
    redundancyLevel: 'DUAL',
    type: 'LINEAR',
    data: null,
    inputs : [],
    liveEncoderDetail: null,
    count : 0,
    contextVersion: 0
};

export default handleActions({
    [CHANNEL_PENDING]: (state) => {
        return {
            ...state,
            pending: true,
            error: false,
            errorStatus : null
        };
    },
    [CHANNEL_FAILURE]: (state, action) => {
        return {
            ...state,
            pending: false,
            error: true,
            errorStatus : action.payload && action.payload.response && action.payload.response.status
        };
    },
    [CHANNEL_INPUTS_FAILURE]: (state, action) => {
        return {
            ...state,
            pending: false
        };
    },
    [CHANNEL_LIVE_ENCODER_DETAIL_FAILURE]: (state, action) => {
        return {
            ...state,
            pending: false
        };
    },
    [GET_CHANNEL_SUCCESS]: (state, action) => {
        const {data} = action.payload;

        return {
            ...state,
            inputs : [],
            liveEncoderDetail: null,
            data: data,
            count : state.count + 1,
            pending: false,
            error: false,
            errorStatus : null
        }
    },
    [SET_CHANNEL_SUCCESS]: (state) => {
        return {
            ...state,
            pending: false,
            error: false,
            errorStatus : null
        }
    },
    [DELETE_CHANNEL_SUCCESS]: (state) => {
        return {
            ...state,
            pending: false,
            error: false,
            errorStatus : null
        };
    },
    [PUT_CANCEL_CHANNEL_SUCCESS]: (state) => {
        return {
            ...state,
            pending: false,
            error: false,
            errorStatus : null
        }
    },
    [PUT_RECLAIM_CHANNEL_SUCCESS]: (state) => {
        return {
            ...state,
            pending: false,
            error: false,
            errorStatus : null
        };
    },
    [PUT_RETURN_CHANNEL_SUCCESS]: (state) => {
        return {
            ...state,
            pending: false,
            error: false,
            errorStatus : null
        }
    },
    [PUT_APPROVAL_REQUEST_CHANNEL_SUCCESS]: (state) => {
        return {
            ...state,
            pending: false,
            error: false,
            errorStatus : null
        };
    },
    [PUT_ACCEPT_CHANNEL_SUCCESS]: (state) => {
        return {
            ...state,
            pending: false,
            error: false,
            errorStatus : null
        };
    },
    [POST_RETURN_CHANNEL_SUCCESS]: (state) => {
        return {
            ...state,
            pending: false,
            error: false,
            errorStatus : null
        };
    },
    [PUT_REJECT_CHANNEL_SUCCESS]: (state) => {
        return {
            ...state,
            pending: false,
            error: false,
            errorStatus : null
        };
    },
    [UPDATE_CHANNEL]: (state, action) => {
        
        return {
            ...state,
            pending: false,
            error: false,
            data: {
                ...state.data,
                ...action.payload
            },
            errorStatus : null
        }
    },
    [PUT_CHANNEL_SUCCESS]:(state, action) => {
        const {data} = action.payload;

        return {
            ...state,
            pending: false,
            error: false,
            data : {
                ...state.data,
                ...data
            },
            count : state.count + 1,
            errorStatus : null
        }
    },
    [INIT_CHANNEL]:() => {
        return {
            ...initialState
        }
    },
    [RESET_CHANNEL]:(state) => {
        return {
            ...initialState,
            contextVersion: state.contextVersion + 1
        }
    },
    [UPDATE_CHANNEL_STATE] :(state, action) => {
        return {
            ...state,
            data : {
                ...state.data,
                state : action.state
            }
        }
    },
    [GET_CHANNEL_INPUTS_SUCCESS]: (state, action) => {
        const {data} = action.payload;

        return {
            ...state,
            pending:false,
            error: false,
            inputs : data
        }
    },
    [GET_CHANNEL_LIVE_ENCODER_DETAIL_SUCCESS]: (state, action) => {
        const {data} = action.payload;

        return {
            ...state,
            pending:false,
            error: false,
            liveEncoderDetail : data
        }
    }
}, initialState);

export const getChannel = (projectId, channelId, callback) => (dispatch, getState) => {
    const {
        stage
    } = getState();
    
    dispatch({type: CHANNEL_PENDING});

    return new Promise((resolve, reject) => {
        getChannelAPI(stage.id, stage.endpoint, projectId, channelId).then(
            (response) => {
                dispatch({
                    type: GET_CHANNEL_SUCCESS,
                    payload: response
                });
                resolve(response.data);
                if(callback && typeof callback === "function") {
                    callback();
                }
            }
        ).catch(error => {
            dispatch({
                type: CHANNEL_FAILURE,
                payload: error
            });
            reject(error)
        });
    });
}

// TODO : Redux 반영할 시, 수정 (state 조회)
export const getChannelState = (projectId, channelId) => (dispatch, getState) => {
    const {
        stage
    } = getState();

    return new Promise((resolve, reject) => {
        getChannelStateAPI(stage.id, stage.endpoint, projectId, channelId)
            .then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            })
    }); 
}

export const setChannel = (params, callback = () => {}) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    dispatch({type: CHANNEL_PENDING});

    return setChannelAPI(stage.id, stage.endpoint, project.id, params).then(
        (response) => {
            dispatch({type: SET_CHANNEL_SUCCESS});
            callback(response.data);
        }
    ).catch(error => {
        dispatch({
            type: CHANNEL_FAILURE,
            payload: error
        });
    });
}

export const deleteChannel = (projectId, channelId, callback = () => {}) => (dispatch, getState) => {
    const {
        stage
    } = getState();

    dispatch({type: CHANNEL_PENDING});

    return deleteChannelAPI(stage.id, stage.endpoint, projectId, channelId).then(
        () => {
            dispatch({type: DELETE_CHANNEL_SUCCESS});
            callback();
        }
    ).catch(error => {
        dispatch({
            type: CHANNEL_FAILURE,
            payload: error
        });
    })
};

export const cancelChannel = (projectId, channelId, comment, callback = () => {}) => (dispatch, getState) => {
    const {
        stage
    } = getState();

    dispatch({type: CHANNEL_PENDING});
    
    return requestAPI(stage.id, stage.endpoint, projectId, channelId, "CANCEL", comment).then(
        (response) => {
            dispatch({type: PUT_CANCEL_CHANNEL_SUCCESS});
            callback(response);
        }
    ).catch(error => {
        dispatch({
            type: CHANNEL_FAILURE,
            payload: error
        });
    })
};

export const reclaimChannel = (projectId, channelId, comment, callback = () => {}) => (dispatch, getState) => {
    const {
        stage
    } = getState();

    dispatch({type: CHANNEL_PENDING});
    
    return reclaimChannelAPI(stage.id, stage.endpoint, projectId, channelId, comment).then(
        (response) => {
            dispatch({type: PUT_RECLAIM_CHANNEL_SUCCESS});
            callback(response);
        }
    ).catch(error => {
        dispatch({
            type: CHANNEL_FAILURE,
            payload: error
        });
    })
}

export const returnChannel = (projectId, channelId, caseId, comment, callback=() => {}) => (dispatch, getState) => {
    const {
        stage
    } = getState();

    dispatch({type: CHANNEL_PENDING});
    
    return handleCaseAPI(stage.id, stage.endpoint, projectId, channelId, caseId, "ACCEPT", null, comment).then(
        (response) => {
            dispatch({type: PUT_RETURN_CHANNEL_SUCCESS});
            callback(response);
        }
    ).catch(error => {
        dispatch({
            type: CHANNEL_FAILURE,
            payload: error
        });
    })
};

export const approvalRequestChannel = (projectId, channelId, comment, callback=()=>{}) => (dispatch, getState) => {
    const {
        stage
    } = getState();

    dispatch({type: CHANNEL_PENDING});
    
    return requestAPI(stage.id, stage.endpoint, projectId, channelId, "APPROVAL", comment).then(
        (response) => {
            dispatch({type: PUT_APPROVAL_REQUEST_CHANNEL_SUCCESS});
            callback(response);
        }
    ).catch(error => {
        dispatch({
            type: CHANNEL_FAILURE,
            payload: error
        });
        callback(null, error);
    });
}

export const acceptChannel = (projectId, channelId, caseId, comment, params, callback=()=>{}) => (dispatch, getState) => {
    const {
        stage
    } = getState();
    
    dispatch({type:CHANNEL_PENDING});
    
    return handleCaseAPI(stage.id, stage.endpoint, projectId, channelId, caseId, "ACCEPT", params, comment).then(
        (response) => {
            dispatch({type: PUT_ACCEPT_CHANNEL_SUCCESS});
            callback(response);
        }
    ).catch(error => {
        dispatch({
            type: CHANNEL_FAILURE,
            payload: error
        });
        callback(null, error);
    })
}

export const returnRequestChannel = (projectId, channelId, comment, callback=()=>{}) => (dispatch, getState) => {
    const {
        stage
    } = getState();

    dispatch({type:CHANNEL_PENDING});

    return requestAPI(stage.id, stage.endpoint, projectId, channelId, "RETURN", comment).then(
        (response) => {
            dispatch({type: POST_RETURN_CHANNEL_SUCCESS});
            callback(response);
        }
    ).catch(error => {
        dispatch({
            type: CHANNEL_FAILURE,
            payload: error
        });
    })
}

export const rejectChannel = (projectId, channelId, caseId, comment, callback=()=>{}) => (dispatch, getState) => {
    const {
        stage
    } = getState();

    dispatch({type:CHANNEL_PENDING});

    return handleCaseAPI(stage.id, stage.endpoint, projectId, channelId, caseId, "REJECT", null, comment).then(
        (response) => {
            dispatch({type: PUT_REJECT_CHANNEL_SUCCESS});
            callback(response);
        }
    ).catch(error => {
        dispatch({
            type: CHANNEL_FAILURE,
            payload: error
        });
    });
}

export const updateChannel = createAction(UPDATE_CHANNEL);
export const putChannel = (projectId, channelId, params) => (dispatch, getState) => {
    const {
        stage
    } = getState();

    dispatch({type: CHANNEL_PENDING});
    
    return putChannelAPI(stage.id, stage.endpoint, projectId, channelId, params).then(
        (response) => {
            dispatch({
                type: PUT_CHANNEL_SUCCESS,
                payload: response
            });
        }
    ).catch(error => {
        dispatch({
            type: CHANNEL_FAILURE,
            payload: error
        });
    })
};
export const initChannel = createAction(INIT_CHANNEL); 
export const reset = createAction(RESET_CHANNEL);
export const start = (projectId, channelId) => async (dispatch, getState) => {
    const {
        stage,
        channel
    } = getState();

    const contextVersion = channel.contextVersion;
    dispatch({
        type: UPDATE_CHANNEL_STATE,
        id: channelId,
        state: 'STARTING'
    });

    await startChannelsAPI(stage.id, stage.endpoint, projectId, channelId);

    const callback = state => {
        dispatch({
            type: UPDATE_CHANNEL_STATE,
            id : channelId,
            state : state
        })
    };

    return ChannelPolling(stage.id, stage.endpoint, projectId, getState).runForStart(channelId, contextVersion, callback);
};

export const startCheckingStatus = (projectId, channelId) => async (dispatch, getState) => {
    const {
        stage,
        channel
    } = getState();
    const contextVersion = channel.contextVersion;
    return ChannelPolling(stage.id, stage.endpoint, projectId, getState).runForStart(channelId, contextVersion);
};

export const stop = (projectId, channelId) => async (dispatch, getState) => {
    const {
        stage,
        channel
    } = getState();
    const contextVersion = channel.contextVersion;

    dispatch({
        type: UPDATE_CHANNEL_STATE,
        id: channelId,
        state: 'STOPPING'
    });

    await stopChannelsAPI(stage.id, stage.endpoint, projectId, channelId);

    const callback =  state => {
        dispatch({
            type: UPDATE_CHANNEL_STATE,
            id : channelId,
            state : state
        })
    };

    return ChannelPolling(stage.id, stage.endpoint, projectId, getState).runForStop(channelId, contextVersion, callback);
};

export const getInputs = (projectId, channelId, includeWhitelist) => (dispatch, getState) => {
    const {
        stage
    } = getState();

    dispatch({type: CHANNEL_PENDING});

    return getInputAPI(stage.id, stage.endpoint, projectId, channelId, includeWhitelist).then(
        (response) => {
            dispatch({
                type: GET_CHANNEL_INPUTS_SUCCESS,
                payload: response
            });
        }
    ).catch(error => {
        dispatch({
            type: CHANNEL_INPUTS_FAILURE,
            payload: error
        });
    });
}

export const getLiveEncoderDetail = (projectId, channelId) => (dispatch, getState) => {
    const {
        stage
    } = getState();

    dispatch({type: CHANNEL_PENDING});
    return getLiveEncoderDetailAPI(stage.id, stage.endpoint, projectId, channelId).then(
        (response) => {
            dispatch({
                type: GET_CHANNEL_LIVE_ENCODER_DETAIL_SUCCESS,
                payload: response
            });
        }
    ).catch(error => {
        dispatch({
            type: CHANNEL_LIVE_ENCODER_DETAIL_FAILURE,
            payload: error
        });
    });
}

const ChannelPolling = (stageId, apiEndpoint, projectId, getState) => {
    return {
        runAllByStatus(channels, callback) {
            if(!channels || channels.length <= 0)
                return;

            const contextVersion = getState().channel.contextVersion;
            channels.forEach(v => {
                switch (v.state) {
                    case "CREATING":
                    case "STOPPING":
                        this.runForStop(v.id, contextVersion, callback);
                        break;
                    case "STARTING" :
                        this.runForStart(v.id, contextVersion, callback);
                        break;
                }
            });
        },

        runForStop(channelId, contextVersion, callback) {
            this.run(channelId, callback, state => getState().channel.contextVersion !== contextVersion || state === 'STOPPED');
        },

        runForStart(channelId, contextVersion, callback) {
            this.run(channelId, callback, state =>  getState().channel.contextVersion !==  contextVersion || state === 'RUNNING');
        },

        async run (channelId, callback, shouldStop) {
            try {
                const channelState = await getChannelStateAsync(stageId, apiEndpoint, projectId, channelId);
                
                if(callback)
                    callback(channelState, channelId);

                if(!shouldStop(channelState) && !this.timer) {
                    this.timer = setTimeout(() => {
                        this.run(channelId, callback, shouldStop);
                        this.timer = clearTimeout(this.timer);
                    }, 1500);
                }
            } catch(e) {
                console.error(e);
            }
        }
    }
}

async function getChannelStateAsync(stageId, apiEndpoint, projectId, channelId) {
    const response = await axios.get(`${apiEndpoint}/channels/${channelId}/state`, {
        headers : {
            stageId,
            projectId
        }
    });
    return response.data;
}