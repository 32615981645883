import {handleActions, createAction} from 'redux-actions';
import {
    axios,
    axiosWithoutHeaders
} from "../cores/axiosWrapper";
import config from '../config';

function getMegazoneAccountAPI(stageId, apiEndpoint) {
    return axios.post(`${apiEndpoint}/megazone-account/sync`, { });
}

const MEGAZONE_ACCOUNT_PENDING = 'MEGAZONE_ACCOUNT_PENDING';
const MEGAZONE_ACCOUNT_FAILURE = 'MEGAZONE_ACCOUNT_FAILURE';
const GET_MEGAZONE_ACCOUNT_SUCCESS = 'GET_MEGAZONE_ACCOUNT_SUCCESS';

const initialState = {
    pending: false,
    error: false
}

export default handleActions({
    [MEGAZONE_ACCOUNT_PENDING]:(state) => {
        return {
            ...state,
            pending: true,
            error: false
        }
    },
    [MEGAZONE_ACCOUNT_FAILURE]:(state) => {
        return {
            ...state,
            pending: false,
            error: true
        }
    },
    [GET_MEGAZONE_ACCOUNT_SUCCESS]: (state) => {
        return {
            ...state,
            pending: false,
            error: false
        }
    }
}, initialState);


export const getSyncMegazoneAccount = (callback = () => {}) => (dispatch, getState) => {
    let {
        stage
    } = getState();

    dispatch({type: MEGAZONE_ACCOUNT_PENDING});

    return getMegazoneAccountAPI(stage.id, stage.endpoint).then(
        (response) => {
            dispatch({
                type: GET_MEGAZONE_ACCOUNT_SUCCESS,
                payload: response
            });

            callback();
        }
    ).catch(error => {
        dispatch({
            type: MEGAZONE_ACCOUNT_FAILURE,
            payload: error
        });
    });
}