import React from "react";
import { useTranslation } from "react-i18next";
import Loadable from "@react-loadable/revised";
import PanelHeader from "@components/property-panel/ui/PanelHeader";
import PanelHeaderTool from "@components/property-panel/ui/PanelHeaderTool";
import PanelHeaderTitle from "@components/property-panel/ui/PanelHeaderTitle";

const LoadableLineupDetail = Loadable({
    loader: () => import("@routes/listings/LineupDetail"),
    loading: () => null,
});

const ListingsLineupDetailPanel = ({ onClickClose }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <PanelHeader>
                <PanelHeaderTitle>{t(`common::label::${"Lineup"}`)}</PanelHeaderTitle>

                <PanelHeaderTool>
                    <button className="btn btn-icon-solid btn-panel-close" onClick={onClickClose}>
                        <span>
                            <i className="sprite sprite-cancel-lg"></i>
                        </span>
                        <span className={"hidden"}>{t(`common::label::${"Close"}`)}</span>
                    </button>
                </PanelHeaderTool>
            </PanelHeader>
            <React.Fragment>
                <LoadableLineupDetail />
            </React.Fragment>
            {/* </div> */}
        </React.Fragment>
    );
};

export default ListingsLineupDetailPanel;
