import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import getByteLength from "@cores/getByteLength";
import { LIMIT_BYTE } from "@constants";
import { AutocompleteTags } from "@components_v2/autocomplete";
import { useTranslation } from "react-i18next";

const TagsInput = ({ tags, disabled = false, onDeleteTag, onAddTag, isEdit, maxCount, isClear }) => {
    const { t } = useTranslation();
    const [value, setValue] = useState("");
    const inputRef = useRef(null);
    const MAX_COUNT = maxCount || 100;

    const onHandleDeleteTag = useCallback(
        (index) => {
            onDeleteTag(index);
        },
        [onDeleteTag],
    );

    const onHandleSubmitTag = useCallback(() => {
        if (!value || getByteLength(value) > LIMIT_BYTE.tag) return;
        onAddTag({ value });
        setValue("");
    }, [value, onAddTag]);

    const checkDuplicateValue = useMemo(() => {
        if (tags && tags.some((tag) => tag.value === value)) return true;
        return false;
    }, [value, tags]);

    const checkMaxCountValue = useMemo(() => {
        return value && tags?.length >= MAX_COUNT;
    }, [value, tags]);

    useEffect(() => {
        if (isClear) setValue("");
    }, [isClear]);

    return (
        <AutocompleteTags
            type={"tag"}
            value={value}
            placeholder={t(`common::msg::Enter after typing`)}
            checkDuplicateValue={checkDuplicateValue}
            checkMaxCountValue={checkMaxCountValue}
            tags={tags}
            disabled={disabled}
            setValue={setValue}
            onDelete={onHandleDeleteTag}
            onSubmit={onHandleSubmitTag}
            isEdit={isEdit}
            maxCount={MAX_COUNT}
        ></AutocompleteTags>
    );
};

export default TagsInput;
