import { createAction, handleActions } from "redux-actions";
import { axios, axiosWithoutHeaders } from "../cores/axiosWrapper";

import queryString from "../cores/queryString";

function getWidgetsAPI(stageId, apiEndpoint, projectId, resourceType) {
    let queries = {};

    queries.resourceType = resourceType;

    const headers = { stageId };
    if (projectId) {
        headers.projectId = projectId;
    }

    return axios.get(`${apiEndpoint}/statistics-widgets?${queryString.stringify(queries)}`, {
        headers,
    });
}

const CONFIGURE_WIDGETS_PENDING = "CONFIGURE_WIDGETS_PENDING";
const CONFIGURE_WIDGETS_FAILURE = "CONFIGURE_WIDGETS_FAILURE";
const GET_CONFIGURE_WIDGETS_SUCCESS = "GET_CONFIGURE_WIDGETS_SUCCESS";
const RESET_CONFIGURE_WIDGETS = "RESET_CONFIGURE_WIDGETS";

const initialState = {
    pending: false,
    error: false,
    widgets: [],
};

export default handleActions(
    {
        [CONFIGURE_WIDGETS_PENDING]: (state) => {
            return {
                ...state,
                pending: true,
                error: false,
            };
        },
        [CONFIGURE_WIDGETS_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [GET_CONFIGURE_WIDGETS_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                pending: false,
                error: false,
                widgets: data.statisticsWidgets,
            };
        },
        [RESET_CONFIGURE_WIDGETS]: () => {
            return {
                pending: false,
                error: false,
                widgets: [],
            };
        },
    },
    initialState,
);

export const resetWidgets = createAction(RESET_CONFIGURE_WIDGETS);

export const getWidgets = (projectId, resourceType) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: CONFIGURE_WIDGETS_PENDING });

    return new Promise((resolve, reject) => {
        getWidgetsAPI(stage.id, stage.endpoint, projectId, resourceType)
            .then((response) => {
                dispatch({
                    type: GET_CONFIGURE_WIDGETS_SUCCESS,
                    payload: response,
                });
                resolve(response.data);
            })
            .catch((error) => {
                dispatch({
                    type: CONFIGURE_WIDGETS_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};
