export const CollectionColumn = [
    {
        id: "name",
        label: "Name",
        style: { custom: { marginLeft: 12, minWidth: 120, flex: 1 } },
        disabled: true,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
    },
    {
        id: "count",
        label: "Assets Count",
        style: { custom: { minWidth: 70 } },
        disabled: false,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
    },
];

export default CollectionColumn;
