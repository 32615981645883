import React from "react";
import { IconButton } from "@mzc-pdc/ui";
import { IconFolderAdd } from "@mzc-cloudplex/icons";

const TreeViewActions = ({ onToggleModal }) => {
    return (
        <>
            <IconButton
                circled
                size={"medium"}
                onClick={(e) => {
                    e.stopPropagation();
                    onToggleModal("createFolder", true);
                }}
            >
                <IconFolderAdd size={16} color={"greySecondary"} />
            </IconButton>
        </>
    );
};

export default TreeViewActions;
