import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const PagePrev = (props) => {
    const { className } = props;
    return <div {...props} className={clsx("page-prev", className)} />;
};
PagePrev.propTypes = {
    className: PropTypes.string,
};

export default PagePrev;
