import moment from 'moment';

const getScheduleDateTime = (
    {
        currentDate = moment(),
        beginTime = '00:00:00.000',
        endTime = '00:00:00.000'
    }
) => {

    let _beginTime = beginTime.split(':');
    let _beginTimeSec = _beginTime[2].split('.')[0];
    let _beginTimeMS = _beginTime[2].split('.')[1];
    let _endTime = endTime.split(':');
    let _endTimeSec = _endTime[2].split('.')[0];
    let _endTimeMS = _endTime[2].split('.')[1];

    let beginCurrentDate = moment.isMoment(currentDate) ? currentDate : moment(currentDate);
    let endCurrentDate = beginCurrentDate.clone();

    let beginDateTime = moment(beginCurrentDate.format('YYYY-MM-DD'))
        .add({
            hours : Number(_beginTime[0]),
            minutes : Number(_beginTime[1]),
            seconds : Number(_beginTimeSec),
            milliseconds : Number(_beginTimeMS)
        });

    let endDateTime = moment(endCurrentDate.format('YYYY-MM-DD'))
        .add({
            hours: Number(_endTime[0]),
            minutes: Number(_endTime[1]),
            seconds: Number(_endTimeSec),
            milliseconds: Number(_endTimeMS)
        });
        
    return {
        beginDateTime,
        endDateTime
    }
};

export default getScheduleDateTime;