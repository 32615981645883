import { createAction, handleActions } from "redux-actions";
import Polling from "../cores/polling";
import VideoApi from "./apis/video";
import AssetApi from "./apis/asset";
import HiddenMetadataApi from "./apis/hiddenMetadata";

import compareVersions, { VERSION_CM_4557_HIDDEN_METADATA } from "../cores/version";
import { LIST_VIEW_TYPE } from "../constants";
import { axios } from "../cores/axiosWrapper";

function attachPeopleToVideo(stageId, apiEndpoint, projectId, id, people) {
    return axios.post(`${apiEndpoint}/videos/${id}/people`, people, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function getPeopleAPI(stageId, apiEndpoint, projectId, id) {
    return axios.get(`${apiEndpoint}/people/${id}`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function detachPeopleFromVideo(stageId, apiEndpoint, projectId, id, people) {
    return axios.delete(`${apiEndpoint}/videos/${id}/people/${people.id}`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function createVideoAPI(stageId, apiEndpoint, projectId, data) {
    return axios.post(`${apiEndpoint}/videos`, data, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function deleteVideoAPI(stageId, apiEndpoint, projectId, id, version) {
    return axios.delete(`${apiEndpoint}/videos/${id}`, {
        params: {
            version,
            deleteAIJobs: true,
        },
        headers: {
            stageId,
            projectId,
        },
    });
}

export function getVideoAPI(stageId, apiEndpoint, projectId, id) {
    return axios.get(`${apiEndpoint}/videos/${id}?generateAccessUrl=true`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function updateVideoAPI(stageId, apiEndpoint, projectId, id, version, data) {
    return axios.patch(`${apiEndpoint}/videos/${id}`, data, {
        params: {
            version,
        },
        headers: {
            stageId,
            projectId,
        },
    });
}

function createPostersAPI(stageId, apiEndpoint, projectId, id, version, data) {
    return axios.post(`${apiEndpoint}/videos/${id}/posters/bulk`, data, {
        params: {
            version,
        },
        headers: {
            stageId,
            projectId,
        },
    });
}

function deletePosterAPI(stageId, apiEndpoint, projectId, id, version, posterId) {
    return axios.delete(`${apiEndpoint}/videos/${id}/posters/${posterId}`, {
        params: {
            version,
        },
        headers: {
            stageId,
            projectId,
        },
    });
}

function createCaptionsAPI(stageId, apiEndpoint, projectId, id, version, data) {
    return axios.post(`${apiEndpoint}/videos/${id}/captions/bulk`, data, {
        params: {
            version,
        },
        headers: {
            stageId,
            projectId,
        },
    });
}

function deleteCaptionAPI(stageId, apiEndpoint, projectId, id, version, captionId) {
    return axios.delete(`${apiEndpoint}/videos/${id}/captions/${captionId}`, {
        params: {
            version,
        },
        headers: {
            stageId,
            projectId,
        },
    });
}

function updateCaptionAPI(stageId, apiEndpoint, projectId, id, version, captionId, data) {
    return axios.patch(`${apiEndpoint}/videos/${id}/captions/${captionId}`, data, {
        params: {
            version,
        },
        headers: {
            stageId,
            projectId,
        },
    });
}

function createConfigurationAPI(stageId, apiEndpoint, projectId, id, data) {
    return axios.post(`${apiEndpoint}/videos/${id}/shared/configurations`, data, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function updateConfigurationAPI(stageId, apiEndpoint, projectId, id, configurationId, data) {
    return axios.put(`${apiEndpoint}/videos/${id}/shared/configurations/${configurationId}`, data, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function getConfigurationsAPI(stageId, apiEndpoint, projectId, id, params) {
    return axios.get(`${apiEndpoint}/videos/${id}/shared/configurations`, {
        params,
        headers: {
            stageId,
            projectId,
        },
    });
}

function getConfigurationAPI(stageId, apiEndpoint, projectId, id, configurationId, params) {
    return axios.get(`${apiEndpoint}/videos/${id}/shared/configurations/${configurationId}`, {
        params,
        headers: {
            stageId,
            projectId,
        },
    });
}

function deleteConfigurationAPI(stageId, apiEndpoint, projectId, videoId, configurationId) {
    return axios.delete(`${apiEndpoint}/videos/${videoId}/shared/configurations/${configurationId}`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function attachContentsAPI(stageId, apiEndpoint, projectId, id, version, type, data) {
    const path = type === "teaserImages" ? "teaser-images" : "teaser-videos";

    return axios.post(`${apiEndpoint}/videos/${id}/${path}/bulk`, data, {
        params: {
            version,
        },
        headers: {
            stageId,
            projectId,
        },
    });
}

function detachContentsAPI(stageId, apiEndpoint, projectId, id, version, type, itemId, assetId) {
    const path = type === "teaserImages" ? "teaser-images" : "teaser-videos";

    return axios.delete(`${apiEndpoint}/videos/${id}/${path}/${itemId}`, {
        params: {
            version,
            assetId,
        },
        headers: {
            stageId,
            projectId,
        },
    });
}

//action type
const RESET = "RESET";
const RESET_PUBLISHING_CONFIGURARION = "RESET_PUBLISHING_CONFIGURARION";

const INIT_PUBLISHING_CONFIGURARION = "INIT_PUBLISHING_CONFIGURARION";

const VIDEO_DETAIL_PENDING = "VIDEO_DETAIL_PENDING";
const VIDEO_DETAIL_FAILURE = "VIDEO_DETAIL_FAILURE";

const VIDEO_DETAIL_UPDATE_STATE = "VIDEO_DETAIL_UPDATE_STATE";

const VIDEO_DETAIL_IS_CHANGING_DATA = "VIDEO_DETAIL_IS_CHANGING_DATA";

const VIDEO_DETAIL_GET_VIDEO_SUCCESS = "VIDEO_DETAIL_GET_VIDEO_SUCCESS";

const VIDEO_CREATE_VIDEO_SUCCESS = "VIDEO_CREATE_VIDEO_SUCCESS";
const VIDEO_CREATE_VIDEO_ERROR = "VIDEO_CREATE_VIDEO_ERROR";

const VIDEO_DETAIL_DELETE_VIDEO_SUCCESS = "VIDEO_DETAIL_DELETE_VIDEO_SUCCESS";
const VIDEO_DETAIL_UPDATE_VIDEO_SUCCESS = "VIDEO_DETAIL_UPDATE_VIDEO_SUCCESS";

const VIDEO_DETAIL_ADD_POSTERS_SUCCESS = "VIDEO_DETAIL_ADD_POSTERS_SUCCESS";
const VIDEO_DETAIL_DELETE_POSTER_SUCCESS = "VIDEO_DETAIL_DELETE_POSTER_SUCCESS";

const VIDEO_DETAIL_ADD_CAPTIONS_SUCCESS = "VIDEO_DETAIL_ADD_CAPTIONS_SUCCESS";
const VIDEO_DETAIL_DELETE_CAPTION_SUCCESS = "VIDEO_DETAIL_DELETE_CAPTION_SUCCESS";
const VIDEO_DETAIL_UPDATE_CAPTION_SUCCESS = "VIDEO_DETAIL_UPDATE_CAPTION_SUCCESS";
const VIDEO_DETAIL_UPDATE_TRANSCRIBE_CAPTION = "VIDEO_DETAIL_UPDATE_TRANSCRIBE_CAPTION";

const VIDEO_ATTACH_PEOPLE_SUCCESS = "VIDEO_ATTACH_PEOPLE_SUCCESS";
const VIDEO_ATTACH_PEOPLE_FAILURE = "VIDEO_ATTACH_PEOPLE_FAILURE";

const VIDEO_DETACH_PEOPLE_SUCCESS = "VIDEO_DETACH_PEOPLE_SUCCESS";
const VIDEO_DETACH_PEOPLE_FAILURE = "VIDEO_DETACH_PEOPLE_FAILURE";

const VIDEO_UPDATE_VIDEO_STATUS = "VIDEO_UPDATE_VIDEO_STATUS";
const VIDEO_UPDATE_ASSET_STATUS = "VIDEO_UPDATE_ASSET_STATUS";
const VIDEO_UPDATE_TRANSCRIBE_STATUS = "VIDEO_UPDATE_TRANSCRIBE_STATUS";

const VIDEO_SHARED_CONFIGURATION_CREATE_SUCCESS = "VIDEO_SHARED_CONFIGURATION_CREATE_SUCCESS";
const VIDEO_SHARED_CONFIGURATION_CREATE_FAILURE = "VIDEO_SHARED_CONFIGURATION_CREATE_FAILURE";

const VIDEO_SHARED_CONFIGURATION_UPDATE_SUCCESS = "VIDEO_SHARED_CONFIGURATION_UPDATE_SUCCESS";
const VIDEO_SHARED_CONFIGURATION_UPDATE_FAILURE = "VIDEO_SHARED_CONFIGURATION_UPDATE_FAILURE";

const VIDEO_SHARED_CONFIGURATIONS_GET_SUCCESS = "VIDEO_SHARED_CONFIGURATIONS_GET_SUCCESS";
const VIDEO_SHARED_CONFIGURATIONS_GET_FAILURE = "VIDEO_SHARED_CONFIGURATIONS_GET_FAILURE";

const VIDEO_SHARED_CONFIGURATION_GET_SUCCESS = "VIDEO_SHARED_CONFIGURATION_GET_SUCCESS";
const VIDEO_SHARED_CONFIGURATION_GET_FAILURE = "VIDEO_SHARED_CONFIGURATION_GET_FAILURE";

const VIDEO_SHARED_CONFIGURATION_GET_STATUS_SUCCESS = "VIDEO_SHARED_CONFIGURATION_GET_STATUS_SUCCESS";

const VIDEO_SHARED_CONFIGURATIONS_DELETE_SUCCESS = "VIDEO_SHARED_CONFIGURATIONS_DELETE_SUCCESS";
const VIDEO_SHARED_CONFIGURATIONS_DELETE_FAILURE = "VIDEO_SHARED_CONFIGURATIONS_DELETE_FAILURE";

const VIDEO_CREATE_AUTO_CAPTION_SUCCESS = "VIDEO_CREATE_AUTO_CAPTION_SUCCESS";

// const VIDEO_DETAIL_GET_HIDDEN_METADATA_VALUE_PENDING = "VIDEO_DETAIL_GET_HIDDEN_METADATA_VALUE_PENDING";
// const VIDEO_DETAIL_GET_HIDDEN_METADATA_SET_EMPTY = "VIDEO_DETAIL_GET_HIDDEN_METADATA_SET_EMPTY";
// const VIDEO_DETAIL_GET_HIDDEN_METADATA_VALUE_SUCCESS = "VIDEO_DETAIL_GET_HIDDEN_METADATA_VALUE_SUCCESS";
// const VIDEO_DETAIL_GET_HIDDEN_METADATA_VALUE_FAILURE = "VIDEO_DETAIL_GET_HIDDEN_METADATA_VALUE_FAILURE";

const VIDEO_DETAIL_UPDATE_CONTENTS_SUCCESS = "VIDEO_DETAIL_UPDATE_CONTENTS_SUCCESS";

const VIDEO_DETAIL_UPDATE_STATUS_FAILURE = "VIDEO_DETAIL_UPDATE_STATUS_FAILURE";
const UPDATE_VIDEO_TRANSCRIBE_STATUS = "UPDATE_VIDEO_TRANSCRIBE_STATUS";
const VIDEO_DETAIL_UPDATE_METADATA = "VIDEO_DETAIL_UPDATE_METADATA";

//reducer
const initialState = {
    data: null,
    pending: false,
    error: false,
    isDeleted: false,
    isChangingData: false,
    hasResetConfiguration: false,
    contextVersion: 0,
    activeView: LIST_VIEW_TYPE.LIST,
};

let tempState = {
    ...initialState,
};

export default handleActions(
    {
        [RESET]: (state) => {
            tempState = {
                ...initialState,
                contextVersion: tempState.contextVersion + 1,
            };
            return tempState;
        },
        [VIDEO_ATTACH_PEOPLE_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const temp = {
                ...tempState,
                isChangingData: false,
                data: {
                    ...tempState.data,
                    people: (tempState.data.people || []).concat([data]),
                },
            };
            tempState = temp;
            return tempState;
        },
        [VIDEO_DETACH_PEOPLE_SUCCESS]: (state, action) => {
            const temp = {
                ...tempState,
                isChangingData: false,
                data: {
                    ...tempState.data,
                    people: (tempState.data.people || []).filter((p) => p.id !== action.payload.id),
                },
            };
            tempState = temp;
            return tempState;
        },
        [VIDEO_ATTACH_PEOPLE_FAILURE]: (state) => {
            const temp = {
                ...tempState,
                pending: false,
                error: true,
            };
            tempState = temp;
            return tempState;
        },
        [VIDEO_DETACH_PEOPLE_FAILURE]: (state) => {
            const temp = {
                ...tempState,
                pending: false,
                error: true,
            };
            tempState = temp;
            return tempState;
        },
        [VIDEO_DETAIL_PENDING]: (state) => {
            const temp = {
                ...tempState,
                pending: true,
                error: false,
            };
            tempState = temp;
            return tempState;
        },
        [VIDEO_DETAIL_FAILURE]: (state) => {
            const temp = {
                ...tempState,
                pending: false,
                error: true,
            };
            tempState = temp;

            return tempState;
        },
        [VIDEO_DETAIL_IS_CHANGING_DATA]: (state) => {
            const temp = {
                ...tempState,
                isChangingData: true,
                contextVersion: tempState.contextVersion + 1,
            };
            tempState = temp;
            return tempState;
        },
        [VIDEO_DETAIL_GET_VIDEO_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const temp = {
                ...tempState,
                pending: false,
                error: false,
                data,
            };
            if (data.transcribe && data.transcribe.outputInfo) {
                temp.data.transcribe = {
                    ...data.transcribe,
                    outputInfo: JSON.parse(data.transcribe.outputInfo),
                };
            }
            tempState = temp;
            return tempState;
        },
        [VIDEO_DETAIL_DELETE_VIDEO_SUCCESS]: (state, action) => {
            const temp = {
                ...tempState,
                isDeleted: true,
                isChangingData: false,
            };
            tempState = temp;
            return tempState;
        },
        [VIDEO_DETAIL_UPDATE_VIDEO_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const temp = {
                ...tempState,
                isChangingData: false,
                data: {
                    ...tempState.data,
                    ...data,
                },
            };
            tempState = temp;
            return tempState;
        },
        [VIDEO_DETAIL_ADD_POSTERS_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const temp = {
                ...tempState,
                data: {
                    ...tempState.data,
                    posters: data,
                },
            };
            tempState = temp;
            return tempState;
        },
        [VIDEO_DETAIL_DELETE_POSTER_SUCCESS]: (state, action) => {
            const temp = {
                ...tempState,
                data: {
                    ...tempState.data,
                    posters: tempState.data.posters.filter((p) => p.id !== action.payload),
                },
            };
            tempState = temp;
            return tempState;
        },
        [VIDEO_DETAIL_ADD_CAPTIONS_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const temp = {
                ...tempState,
                data: {
                    ...tempState.data,
                    captions: data.concat(state.data.captions),
                },
            };
            tempState = temp;
            return tempState;
        },
        [VIDEO_DETAIL_DELETE_CAPTION_SUCCESS]: (state, action) => {
            const temp = {
                ...tempState,
                data: {
                    ...tempState.data,
                    captions: tempState.data.captions.filter((c) => c.id !== action.payload),
                },
            };
            tempState = temp;
            return tempState;
        },
        [VIDEO_DETAIL_UPDATE_CAPTION_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const temp = {
                ...tempState,
                data: {
                    ...tempState.data,
                    captions: tempState.data.captions.map((c) => {
                        if (c.id === data.id) {
                            return data;
                        }

                        return c;
                    }),
                },
            };
            tempState = temp;
            return tempState;
        },
        [VIDEO_DETAIL_UPDATE_TRANSCRIBE_CAPTION]: (state, action) => {
            const { data } = action.payload;
            const temp = {
                ...tempState,
                data: {
                    ...tempState.data,
                    captions: data.captions,
                    version: data.version,
                },
            };
            tempState = temp;
            return tempState;
        },
        [VIDEO_UPDATE_VIDEO_STATUS]: (state, action) => {
            const { data } = action.payload;
            const temp = {
                ...tempState,
                data: {
                    ...tempState.data,
                    status: data.status,
                },
            };
            tempState = temp;
            return tempState;
        },
        [VIDEO_UPDATE_ASSET_STATUS]: (state, action) => {
            // TODO: implementation
            const asset = action.payload.data;
            const assetId = asset.id;
            const temp = {
                ...tempState,
                data: {
                    ...tempState.data,
                },
            };
            let hasSet = false;
            if (temp.data.sources) {
                const a = temp.data.sources.find((s) => s.id === assetId);
                if (a) {
                    hasSet = true;
                    a.status = asset.status;
                }
            }
            if (!hasSet) {
                if (temp.data.thumbnails) {
                    const a = temp.data.thumbnails.find((s) => s.id === assetId);
                    if (a) {
                        hasSet = true;
                        a.status = asset.status;
                    }
                }
                if (temp.data.posters) {
                    const a = temp.data.posters.find((s) => s.asset && s.asset.id === assetId);
                    if (a) {
                        hasSet = true;
                        a.asset.status = asset.status;
                    }
                }
            }
            if (!hasSet && temp.data.captions) {
                const a = temp.data.captions.find((s) => s.id === assetId);
                if (a) {
                    hasSet = true;
                    a.status = asset.status;
                }
            }
            tempState = temp;
            return tempState;
        },
        [VIDEO_UPDATE_TRANSCRIBE_STATUS]: (state, action) => {
            const { data } = action.payload;
            const temp = {
                ...tempState,
                data: {
                    ...tempState.data,
                    transcribe: {
                        ...tempState.data.transcribe,
                        status: data.transcribeStatus,
                        outputInfo: data.outputInfo || tempState.data.transcribe.outputInfo,
                    },
                },
            };
            tempState = temp;
            return tempState;
        },
        [VIDEO_CREATE_AUTO_CAPTION_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const temp = {
                ...tempState,
                data: {
                    ...tempState.data,
                    transcribe: {
                        asset: {
                            id: data.asset.id,
                            url: data.asset.url,
                        },
                        status: data.status,
                        outputInfo: data.outputInfo,
                    },
                },
                contextVersion: tempState.contextVersion + 1,
            };
            tempState = temp;
            return tempState;
        },
        [RESET_PUBLISHING_CONFIGURARION]: (state, action) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    publishingConfiguration: undefined,
                },
                hasResetConfiguration: true,
            };
        },
        [INIT_PUBLISHING_CONFIGURARION]: (state, action) => {
            return {
                ...state,
                hasResetConfiguration: false,
            };
        },
        [VIDEO_SHARED_CONFIGURATION_CREATE_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            const temp = {
                ...tempState,
                data: {
                    ...tempState.data,
                    publishingConfiguration: data,
                },
            };
            tempState = temp;

            return tempState;
        },
        [VIDEO_SHARED_CONFIGURATION_UPDATE_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            const temp = {
                ...tempState,
                data: {
                    ...state.data,
                    publishingConfiguration: data,
                },
            };
            tempState = temp;

            return tempState;
        },
        [VIDEO_SHARED_CONFIGURATION_UPDATE_FAILURE]: (state, action) => {
            return tempState;
        },
        [VIDEO_SHARED_CONFIGURATION_GET_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const temp = {
                ...tempState,
                data: {
                    ...state.data,
                    publishingConfiguration: data,
                },
            };
            tempState = temp;

            return tempState;
        },
        [VIDEO_SHARED_CONFIGURATION_GET_STATUS_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            let publishingConfiguration_ = {};

            if (data)
                publishingConfiguration_ = {
                    ...tempState.data.publishingConfiguration,
                    status: data.status,
                };

            console.log("publishingConfiguration_:", publishingConfiguration_);

            const temp = {
                ...tempState,
                data: {
                    ...state.data,
                    publishingConfiguration: publishingConfiguration_,
                },
            };
            tempState = temp;

            return tempState;
        },
        [VIDEO_SHARED_CONFIGURATIONS_DELETE_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            const temp = {
                ...tempState,
                data: {
                    ...tempState.data,
                    publishingConfiguration: data,
                },
            };
            tempState = temp;

            return tempState;
        },
        [VIDEO_SHARED_CONFIGURATIONS_DELETE_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        // [VIDEO_DETAIL_GET_HIDDEN_METADATA_VALUE_PENDING]: (state, action) => {
        //     const { metadataSchemaId, metadataFieldId } = action.payload;
        //
        //     const metadataSchemas = refineMetadata(tempState.data.metadataSchemas, metadataSchemaId, metadataFieldId, {
        //         pending: true,
        //         error: false,
        //     });
        //
        //     tempState = {
        //         ...tempState,
        //         data: {
        //             ...tempState.data,
        //             metadataSchemas,
        //         },
        //     };
        //     return tempState;
        // },
        // [VIDEO_DETAIL_GET_HIDDEN_METADATA_SET_EMPTY]: (state, action) => {
        //     const { metadataSchemaId, metadataFieldId } = action.payload;
        //
        //     const metadataSchemas = refineMetadata(tempState.data.metadataSchemas, metadataSchemaId, metadataFieldId, {
        //         pending: false,
        //         error: false,
        //         value: "",
        //         values: [],
        //         isCheckHiddenValue: true,
        //     });
        //
        //     tempState = {
        //         ...tempState,
        //         data: {
        //             ...tempState.data,
        //             metadataSchemas,
        //         },
        //     };
        //     return tempState;
        // },
        // [VIDEO_DETAIL_GET_HIDDEN_METADATA_VALUE_SUCCESS]: (state, action) => {
        //     const { metadataSchemaId, metadataFieldId, response } = action.payload;
        //
        //     const metadataSchemas = refineMetadata(tempState.data.metadataSchemas, metadataSchemaId, metadataFieldId, {
        //         pending: false,
        //         error: false,
        //         value: response.data.value,
        //         values: response.data.values,
        //         isCheckHiddenValue: true,
        //     });
        //
        //     tempState = {
        //         ...tempState,
        //         data: {
        //             ...tempState.data,
        //             metadataSchemas,
        //         },
        //     };
        //     return tempState;
        // },
        // [VIDEO_DETAIL_GET_HIDDEN_METADATA_VALUE_FAILURE]: (state, action) => {
        //     const { metadataSchemaId, metadataFieldId, error } = action.payload;
        //
        //     const metadataSchemas = refineMetadata(tempState.data.metadataSchemas, metadataSchemaId, metadataFieldId, {
        //         pending: false,
        //         error,
        //         isCheckHiddenValue: false,
        //     });
        //
        //     tempState = {
        //         ...tempState,
        //         data: {
        //             ...tempState.data,
        //             metadataSchemas,
        //         },
        //     };
        //     return tempState;
        // },
        [VIDEO_DETAIL_UPDATE_CONTENTS_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const temp = {
                ...tempState,
            };
            tempState = temp;
            return tempState;
        },
        [VIDEO_DETAIL_UPDATE_STATUS_FAILURE]: (state) => {
            const temp = {
                ...tempState,
                data: {
                    ...state.data,
                    status: "ERROR",
                },
                pending: false,
                error: true,
            };
            tempState = temp;

            return tempState;
        },
        [UPDATE_VIDEO_TRANSCRIBE_STATUS]: (state, action) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    transcribe: {
                        ...state.data?.transcribe,
                        status: action.payload ? "IN_PROGRESS" : "",
                        outputInfo: { asset: { name: "" }, languages: [{ lage: "", code: "" }] },
                    },
                },
            };
        },
        [VIDEO_DETAIL_UPDATE_METADATA]: (state, action) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    metadataSchemas: action.payload,
                },
            };
        },
    },
    initialState,
);

export const reset = createAction(RESET);
export const resetPublishingConfiguratoin = createAction(RESET_PUBLISHING_CONFIGURARION);
export const initPublishingConfiguratoin = createAction(INIT_PUBLISHING_CONFIGURARION);
export const updateVideoTranscribeStatus = createAction(UPDATE_VIDEO_TRANSCRIBE_STATUS);
export const updateVideoMetadata = createAction(VIDEO_DETAIL_UPDATE_METADATA);

export const getVideo = (id) => (dispatch, getState) => {
    const { stage, project, video } = getState();

    dispatch({ type: VIDEO_DETAIL_PENDING });
    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;
    return getVideoAPI(stageId, apiEndpoint, projectId, id)
        .then((response) => {
            dispatch({
                type: VIDEO_DETAIL_GET_VIDEO_SUCCESS,
                payload: response,
            });
            const video = response.data;
            const targetStatus =
                video.status === "ACTIVATING" ? "ACTIVE" : video.status === "INACTIVATING" ? "INACTIVE" : video.status;
            checkStatus(stageId, apiEndpoint, projectId, video, targetStatus, dispatch, getState);
            if (
                video.transcribe &&
                video.transcribe.status === "IN_PROGRESS" &&
                video.transcribe.asset &&
                video.transcribe.asset.id
            ) {
                checkTranscribeStatus(
                    dispatch,
                    getState,
                    stageId,
                    apiEndpoint,
                    projectId,
                    video.id,
                    video.transcribe.asset.id,
                    targetStatus,
                );
            }
            return video;
        })
        .catch((error) => {
            dispatch({
                type: VIDEO_DETAIL_FAILURE,
                payload: error,
            });
        });
};

export const getVideoNotExecPending = (id) => (dispatch, getState) => {
    const { stage, project } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;
    return getVideoAPI(stageId, apiEndpoint, projectId, id)
        .then((response) => {
            dispatch({
                type: VIDEO_DETAIL_GET_VIDEO_SUCCESS,
                payload: response,
            });
            const video = response.data;
            const targetStatus =
                video.status === "ACTIVATING" ? "ACTIVE" : video.status === "INACTIVATING" ? "INACTIVE" : video.status;
            checkStatus(stageId, apiEndpoint, projectId, video, targetStatus, dispatch, getState);
        })
        .catch((error) => {
            dispatch({
                type: VIDEO_DETAIL_FAILURE,
                payload: error,
            });
        });
};

export const updateMetadata = (id, version, metadata) => (dispatch, getState) => {
    if (!metadata) return;
    const { stage, project } = getState();

    let data;
    if (compareVersions(VERSION_CM_4557_HIDDEN_METADATA, stage.version) <= 0) {
        data = {
            metadata: {
                metadataFieldId: metadata.metadataFieldId,
                value: metadata.value,
                values: metadata.values,
            },
        };
    } else data = metadata;

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;
    updateVideoAPI(stageId, apiEndpoint, projectId, id, version, data)
        .then((response) => {
            dispatch({
                type: VIDEO_DETAIL_UPDATE_VIDEO_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: VIDEO_DETAIL_FAILURE,
                payload: error,
            });
        });
};

export const updateVideo = (id, version, data) => (dispatch, getState) => {
    const { stage, project } = getState();
    dispatch({ type: VIDEO_DETAIL_IS_CHANGING_DATA });

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;
    return new Promise((resolve, reject) => {
        updateVideoAPI(stageId, apiEndpoint, projectId, id, version, data)
            .then((response) => {
                dispatch({
                    type: VIDEO_DETAIL_UPDATE_VIDEO_SUCCESS,
                    payload: response,
                });
                resolve(response.data);
            })
            .catch((error) => {
                dispatch({
                    type: VIDEO_DETAIL_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const addPosters = (id, version, data) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: VIDEO_DETAIL_IS_CHANGING_DATA });

    return new Promise((resolve, reject) => {
        createPostersAPI(stage.id, stage.endpoint, project.id, id, version, data)
            .then((response) => {
                dispatch({
                    type: VIDEO_DETAIL_ADD_POSTERS_SUCCESS,
                    payload: response,
                });
                resolve(response.data);
            })
            .catch((error) => {
                dispatch({
                    type: VIDEO_DETAIL_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const deletePoster = (id, version, posterId) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: VIDEO_DETAIL_IS_CHANGING_DATA });

    return new Promise((resolve, reject) => {
        deletePosterAPI(stage.id, stage.endpoint, project.id, id, version, posterId)
            .then((response) => {
                dispatch({
                    type: VIDEO_DETAIL_DELETE_POSTER_SUCCESS,
                    payload: posterId,
                });
                resolve(posterId);
            })
            .catch((error) => {
                dispatch({
                    type: VIDEO_DETAIL_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const addCaptions = (id, version, data) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: VIDEO_DETAIL_IS_CHANGING_DATA });

    return new Promise((resolve, reject) => {
        createCaptionsAPI(stage.id, stage.endpoint, project.id, id, version, data)
            .then((response) => {
                dispatch({
                    type: VIDEO_DETAIL_ADD_CAPTIONS_SUCCESS,
                    payload: response,
                });
                resolve(response.data);
            })
            .catch((error) => {
                dispatch({
                    type: VIDEO_DETAIL_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const deleteCaption = (id, version, captionId) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: VIDEO_DETAIL_IS_CHANGING_DATA });

    return new Promise((resolve, reject) => {
        deleteCaptionAPI(stage.id, stage.endpoint, project.id, id, version, captionId)
            .then((response) => {
                dispatch({
                    type: VIDEO_DETAIL_DELETE_CAPTION_SUCCESS,
                    payload: captionId,
                });
                resolve(captionId);
            })
            .catch((error) => {
                dispatch({
                    type: VIDEO_DETAIL_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const updateCaption = (id, version, captionId, data) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: VIDEO_DETAIL_IS_CHANGING_DATA });

    updateCaptionAPI(stage.id, stage.endpoint, project.id, id, version, captionId, data)
        .then((response) => {
            dispatch({
                type: VIDEO_DETAIL_UPDATE_CAPTION_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: VIDEO_DETAIL_FAILURE,
                payload: error,
            });
        });
};

export const createVideo = (data) => (dispatch, getState) => {
    const { stage, project } = getState();

    console.log("---- createVideo data: ", data);
    // dispatch({type:})

    return createVideoAPI(stage.id, stage.endpoint, project.id, data)
        .then(() => {
            dispatch({
                type: VIDEO_CREATE_VIDEO_SUCCESS,
            });
        })
        .catch((error) => {
            dispatch({
                type: VIDEO_CREATE_VIDEO_ERROR,
                payload: error,
            });
        });
};

export const deleteVideo = (id, version) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: VIDEO_DETAIL_IS_CHANGING_DATA });

    return deleteVideoAPI(stage.id, stage.endpoint, project.id, id, version)
        .then(() => {
            dispatch({
                type: VIDEO_DETAIL_DELETE_VIDEO_SUCCESS,
            });
        })
        .catch((error) => {
            dispatch({
                type: VIDEO_DETAIL_FAILURE,
                payload: error,
            });
        });
};

export const attachPeopleAsync = (id, people) => (dispatch, getState) => {
    const { stage, project } = getState();
    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    dispatch({ type: VIDEO_DETAIL_IS_CHANGING_DATA });

    return new Promise((resolve, reject) => {
        attachPeopleToVideo(stageId, apiEndpoint, project.id, id, people)
            .then(() => {
                getPeopleAsync(stageId, apiEndpoint, project.id, people.id)
                    .then((response) => {
                        dispatch({
                            type: VIDEO_ATTACH_PEOPLE_SUCCESS,
                            payload: response,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        dispatch({
                            type: VIDEO_ATTACH_PEOPLE_FAILURE,
                            payload: error,
                        });
                        reject(error);
                    });
            })
            .catch((error) => {
                dispatch({
                    type: VIDEO_ATTACH_PEOPLE_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const detachPeopleAsync = (id, people) => (dispatch, getState) => {
    const { stage, project } = getState();
    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    dispatch({ type: VIDEO_DETAIL_IS_CHANGING_DATA });

    return new Promise((resolve, reject) => {
        detachPeopleFromVideo(stageId, apiEndpoint, project.id, id, people)
            .then((response) => {
                dispatch({
                    type: VIDEO_DETACH_PEOPLE_SUCCESS,
                    payload: people,
                });
                resolve(response);
            })
            .catch((error) => {
                dispatch({
                    type: VIDEO_DETACH_PEOPLE_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

function checkStatus(stageId, apiEndpoint, projectId, video, targetStatus, dispatch, getState, contextVersion) {
    if (!video) return;

    const shouldRunStatusPoller = video && video.status !== targetStatus;
    if (!shouldRunStatusPoller) return;

    const videoId = video.id;

    Polling(
        async () => {
            return await VideoApi.getStatusAsync(stageId, apiEndpoint, projectId, videoId);
        },
        (response) => {
            return getState().video.contextVersion !== contextVersion || response.data.status === targetStatus;
        },
        (response) => {
            dispatch({
                type: VIDEO_UPDATE_VIDEO_STATUS,
                payload: response,
            });
        },
        null,
        3000,
    ).run();

    const posterIds = (video.posters && video.posters.map((p) => p.asset.id)) || [];
    const assetIds = ((video.sources && video.sources.map((a) => a.id)) || [])
        .concat((video.thumbnails && video.thumbnails.map((a) => a.id)) || [])
        .concat(posterIds)
        .concat((video.captions && video.captions.map((a) => a.id)) || [])
        .reduce((arr, a) => {
            if (arr.indexOf(a) < 0) arr.push(a);
            return arr;
        }, []);
    assetIds.forEach((assetId) => {
        Polling(
            async () => {
                return await AssetApi.getStatus(stageId, apiEndpoint, projectId, assetId);
            },
            (response) => {
                return getState().video.contextVersion !== contextVersion || response.data.status === "ACTIVE";
            },
            (response) => {
                dispatch({
                    type: VIDEO_UPDATE_ASSET_STATUS,
                    payload: response,
                });
            },
            null,
            3000,
        ).run();
    });
}

function checkTranscribeStatus(
    dispatch,
    getState,
    stageId,
    apiEndpoint,
    projectId,
    videoId,
    assetId,
    targetStatus,
    contextVersion,
) {
    Polling(
        async () => {
            return await AssetApi.getStatus(stageId, apiEndpoint, projectId, assetId);
        },
        (response) => {
            return response.data.transcribeStatus !== "IN_PROGRESS";
        },
        (response) => {
            dispatch({
                type: VIDEO_UPDATE_TRANSCRIBE_STATUS,
                payload: response,
            });
            if (response.data.transcribeStatus === "COMPLETED") {
                getVideoAPI(stageId, apiEndpoint, projectId, videoId).then((response) => {
                    dispatch({
                        type: VIDEO_DETAIL_UPDATE_TRANSCRIBE_CAPTION,
                        payload: response,
                    });
                    const video = response.data;
                    const captionAssetIds = (video.captions && video.captions.map((a) => a.id)) || [];
                    captionAssetIds.forEach((assetId) => {
                        Polling(
                            async () => {
                                return await AssetApi.getStatus(stageId, apiEndpoint, projectId, assetId);
                            },
                            (response) => {
                                const statusBool = response.data.status === targetStatus;
                                return contextVersion
                                    ? getState().video.contextVersion !== contextVersion || statusBool
                                    : statusBool;
                            },
                            (response) => {
                                dispatch({
                                    type: VIDEO_UPDATE_ASSET_STATUS,
                                    payload: response,
                                });
                            },
                            null,
                            3000,
                        ).run();
                    });
                });
            }
        },
    ).run();
}

function checkConfigurationStatus(dispatch, getState, stageId, apiEndpoint, projectId, videoId, configurationId) {
    Polling(
        async () => {
            return await getConfigurationAPI(stageId, apiEndpoint, projectId, videoId, configurationId);
        },
        (response) => {
            if (getState().video.hasResetConfiguration) return true;

            if (response.data === undefined || response.data === null) return true;

            return ["READY", "RECLAIMED", "PUBLISHED"].includes(response.data.status);
        },
        (response) => {
            dispatch({
                type: VIDEO_SHARED_CONFIGURATION_GET_STATUS_SUCCESS,
                payload: response,
            });
        },
        (e) => {
            console.log("----  ERROR", e);
        },
        3000,
    ).run();
}

// TODO : Attach, Detach 용도, 위치 바꿔야 하면 이동
const getPeopleAsync = (stageId, apiEndpoint, projectId, peopleId) => {
    return new Promise((resolve, reject) => {
        getPeopleAPI(stageId, apiEndpoint, projectId, peopleId)
            .then((response) => {
                const data = Object.assign({}, response.data);
                if (data && data.primaryImage) {
                    response.data = {
                        id: data.id,
                        name: data.name,
                        imageUrl: data.primaryImage.url,
                        accessUrl: data.primaryImage.accessUrl,
                    };
                } else if (data && data.images && data.images.length) {
                    response.data = {
                        id: data.id,
                        name: data.name,
                        imageUrl: data.images[0].url,
                        accessUrl: data.images[0].accessUrl,
                    };
                }
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const activate = (id, version) => (dispatch, getState) => {
    const { stage, project, video } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;
    const contextVersion = video.contextVersion + 1;

    dispatch({ type: VIDEO_DETAIL_IS_CHANGING_DATA });
    new Promise(async (resolve, reject) => {
        try {
            const response = await VideoApi.activateAsync(stageId, apiEndpoint, projectId, id, version);
            resolve(response);
        } catch (e) {
            reject(e);
        }
    })
        .then((response) => {
            dispatch({
                type: VIDEO_DETAIL_UPDATE_VIDEO_SUCCESS,
                payload: response,
            });
            checkStatus(stageId, apiEndpoint, projectId, video.data, "ACTIVE", dispatch, getState, contextVersion);
        })
        .catch((error) => {
            dispatch({
                type: VIDEO_DETAIL_UPDATE_STATUS_FAILURE,
                payload: error,
            });
        });
};

export const inactivate = (id, version) => (dispatch, getState) => {
    const { stage, project, video } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;
    const shouldRunStatusPoller = video.data && video.data.status !== "INACTIVE";
    const contextVersion = video.contextVersion + 1;

    dispatch({ type: VIDEO_DETAIL_IS_CHANGING_DATA });

    new Promise(async (resolve, reject) => {
        try {
            const response = await VideoApi.inactivateAsync(stageId, apiEndpoint, projectId, id, version);
            resolve(response);
        } catch (e) {
            reject(e);
        }
    })
        .then((response) => {
            dispatch({
                type: VIDEO_DETAIL_UPDATE_VIDEO_SUCCESS,
                payload: response,
            });
            if (shouldRunStatusPoller) {
                Polling(
                    async () => {
                        return await VideoApi.getStatusAsync(stageId, apiEndpoint, projectId, id);
                    },
                    (response) => {
                        return (
                            getState().video.contextVersion !== contextVersion || response.data.status === "INACTIVE"
                        );
                    },
                    (response) => {
                        dispatch({
                            type: VIDEO_UPDATE_VIDEO_STATUS,
                            payload: response,
                        });
                    },
                    null,
                    3000,
                ).run();

                const video = response.data;
                const posterIds = (video.posters && video.posters.map((p) => p.asset.id)) || [];
                const assetIds = ((video.sources && video.sources.map((a) => a.id)) || [])
                    .concat((video.thumbnails && video.thumbnails.map((a) => a.id)) || [])
                    .concat(posterIds)
                    .concat((video.captions && video.captions.map((a) => a.id)) || [])
                    .reduce((arr, a) => {
                        if (arr.indexOf(a) < 0) arr.push(a);
                        return arr;
                    }, []);
                assetIds.forEach((assetId) => {
                    Polling(
                        async () => {
                            return await AssetApi.getStatus(stageId, apiEndpoint, projectId, assetId);
                        },
                        (response) => {
                            return (
                                getState().video.contextVersion !== contextVersion ||
                                response.data.status === "INACTIVE"
                            );
                        },
                        (response) => {
                            dispatch({
                                type: VIDEO_UPDATE_ASSET_STATUS,
                                payload: response,
                            });
                        },
                        null,
                        3000,
                    ).run();
                });
            }
        })
        .catch((error) => {
            dispatch({
                type: VIDEO_DETAIL_UPDATE_STATUS_FAILURE,
                payload: error,
            });
        });
};

export const createAutoCaption = (videoId, assetId, data) => (dispatch, getState) => {
    const { stage, project, video } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;
    const shouldRunStatusPoller =
        video.data && !video.data.transcribe ? true : video.data.transcribe.status === "IN_PROGRESS";
    const contextVersion = video.contextVersion + 1;

    new Promise(async (resolve) => {
        const response = await AssetApi.createAutoCaption(stageId, apiEndpoint, projectId, assetId, data);
        resolve(response);
    })
        .then((response) => {
            dispatch({
                type: VIDEO_CREATE_AUTO_CAPTION_SUCCESS,
                payload: response,
            });
            if (shouldRunStatusPoller) {
                const targetStatus =
                    video.data.status === "ACTIVATING"
                        ? "ACTIVE"
                        : video.data.status === "INACTIVATING"
                        ? "INACTIVE"
                        : video.data.status;
                checkTranscribeStatus(
                    dispatch,
                    getState,
                    stageId,
                    apiEndpoint,
                    projectId,
                    videoId,
                    assetId,
                    targetStatus,
                    contextVersion,
                );
            }
        })
        .catch((error) => {
            dispatch({
                type: VIDEO_DETAIL_FAILURE,
                payload: error,
            });
        });
};

export const createConfiguration = (videoId, data) => (dispatch, getState) => {
    const { stage, project } = getState();

    return createConfigurationAPI(stage.id, stage.endpoint, project.id, videoId, data)
        .then((response) => {
            dispatch({
                type: VIDEO_SHARED_CONFIGURATION_CREATE_SUCCESS,
                payload: response,
            });

            const configuration = response.data;
            if (["PUBLISHING", "RECLAIMING"].includes(configuration.status))
                checkConfigurationStatus(
                    dispatch,
                    getState,
                    stage.id,
                    stage.endpoint,
                    project.id,
                    videoId,
                    configuration.id,
                );
        })
        .catch((error) => {
            dispatch({
                type: VIDEO_SHARED_CONFIGURATION_CREATE_FAILURE,
                payload: error,
            });
        });
};

export const updateConfiguration = (videoId, configurationId, data) => (dispatch, getState) => {
    const { stage, project } = getState();
    return updateConfigurationAPI(stage.id, stage.endpoint, project.id, videoId, configurationId, data)
        .then((response) => {
            dispatch({
                type: VIDEO_SHARED_CONFIGURATION_UPDATE_SUCCESS,
                payload: response,
            });

            const configuration = response.data;
            console.log("updateConfigurationAPI configuration:", configuration);
            if (["PUBLISHING", "RECLAIMING"].includes(configuration.status))
                checkConfigurationStatus(
                    dispatch,
                    getState,
                    stage.id,
                    stage.endpoint,
                    project.id,
                    videoId,
                    configuration.id,
                );
        })
        .catch((error) => {
            dispatch({
                type: VIDEO_SHARED_CONFIGURATION_UPDATE_FAILURE,
                payload: error,
            });
        });
};

export const getConfigurations = (videoId) => (dispatch, getState) => {
    const { stage, project, video } = getState();

    return getConfigurationsAPI(stage.id, stage.endpoint, project.id, videoId)
        .then((response) => {
            console.log(`getConfigurations: ${JSON.stringify(response)}`);
            dispatch({
                type: VIDEO_SHARED_CONFIGURATIONS_GET_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: VIDEO_SHARED_CONFIGURATIONS_GET_FAILURE,
                payload: error,
            });
        });
};

export const getLatestConfiguration = (videoId, params) => async (dispatch, getState) => {
    const { stage, project, video } = getState();

    const response = await getConfigurationsAPI(stage.id, stage.endpoint, project.id, videoId, params);

    const configurationId = response.data.length > 0 && response.data[0].id;

    if (!configurationId) {
        dispatch({
            type: VIDEO_SHARED_CONFIGURATION_GET_FAILURE,
        });

        return;
    }

    return getConfigurationAPI(stage.id, stage.endpoint, project.id, videoId, configurationId, { children: "full" })
        .then((response) => {
            dispatch({
                type: VIDEO_SHARED_CONFIGURATION_GET_SUCCESS,
                payload: response,
            });
            const configuration = response.data;
            if (["PUBLISHING", "RECLAIMING"].includes(configuration.status))
                checkConfigurationStatus(
                    dispatch,
                    getState,
                    stage.id,
                    stage.endpoint,
                    project.id,
                    videoId,
                    configuration.id,
                );
        })
        .catch((error) => {
            dispatch({
                type: VIDEO_SHARED_CONFIGURATION_GET_FAILURE,
                payload: error,
            });
        });
};

export const deleteConfiguration = (videoId, configurationId) => (dispatch, getState) => {
    const { stage, project, video } = getState();
    return deleteConfigurationAPI(stage.id, stage.endpoint, project.id, videoId, configurationId)
        .then((response) => {
            dispatch({
                type: VIDEO_SHARED_CONFIGURATIONS_DELETE_SUCCESS,
                payload: response,
            });

            checkConfigurationStatus(
                dispatch,
                getState,
                stage.id,
                stage.endpoint,
                project.id,
                videoId,
                configurationId,
            );
        })
        .catch((error) => {
            dispatch({
                type: VIDEO_SHARED_CONFIGURATIONS_DELETE_FAILURE,
                payload: error,
            });
        });
};
//
// export const getHiddenMetadataValue = (id, metadataSchemaId, metadataFieldId) => (dispatch, getState) => {
//     const { stage, project, video } = getState();
//
//     const stageId = stage.id;
//     const apiEndpoint = stage.endpoint;
//     const projectId = project.id;
//
//     dispatch({ type: VIDEO_DETAIL_GET_HIDDEN_METADATA_VALUE_PENDING, payload: { metadataSchemaId, metadataFieldId } });
//
//     return new Promise((resolve, reject) => {
//         HiddenMetadataApi.getHiddenMetadata(stageId, apiEndpoint, projectId, "VIDEO", id, metadataFieldId)
//             .then((response) => {
//                 dispatch({
//                     type: VIDEO_DETAIL_GET_HIDDEN_METADATA_VALUE_SUCCESS,
//                     payload: {
//                         response,
//                         metadataSchemaId,
//                         metadataFieldId,
//                     },
//                 });
//                 resolve(response);
//             })
//             .catch((error) => {
//                 if (error.response.status === 401) {
//                     dispatch({
//                         type: VIDEO_DETAIL_GET_HIDDEN_METADATA_VALUE_FAILURE,
//                         payload: {
//                             error,
//                             metadataSchemaId,
//                             metadataFieldId,
//                         },
//                     });
//
//                     reject(error);
//                 } else if (error.response.status === 404) {
//                     dispatch({
//                         type: VIDEO_DETAIL_GET_HIDDEN_METADATA_SET_EMPTY,
//                         payload: { metadataSchemaId, metadataFieldId },
//                     });
//                     resolve();
//                 }
//             });
//     });
// };

export const attachContents = (id, version, itemSchemaType, data) => (dispatch, getState) => {
    const { stage, project } = getState();

    console.log("attachContents data: ", data);

    dispatch({ type: VIDEO_DETAIL_IS_CHANGING_DATA });

    return new Promise((resolve, reject) => {
        attachContentsAPI(stage.id, stage.endpoint, project.id, id, version, itemSchemaType, data)
            .then((response) => {
                dispatch({
                    type: VIDEO_DETAIL_UPDATE_CONTENTS_SUCCESS,
                    payload: response,
                });
                resolve(response.data);
            })
            .catch((error) => {
                dispatch({
                    type: VIDEO_DETAIL_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const detachContents = (id, version, itemSchemaType, itemId, assetId) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: VIDEO_DETAIL_IS_CHANGING_DATA });

    return new Promise((resolve, reject) => {
        detachContentsAPI(stage.id, stage.endpoint, project.id, id, version, itemSchemaType, itemId, assetId)
            .then((response) => {
                dispatch({
                    type: VIDEO_DETAIL_UPDATE_CONTENTS_SUCCESS,
                    payload: response,
                });
                resolve(response.data);
            })
            .catch((error) => {
                dispatch({
                    type: VIDEO_DETAIL_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

const refineMetadata = (target, metadataSchemaId, metadataFieldId, data) => {
    return target.map((v) => {
        if (v.id === metadataSchemaId) {
            const fields = v.fields.map((f) => {
                if (f.id === metadataFieldId) {
                    return {
                        ...f,
                        ...data,
                    };
                } else {
                    return f;
                }
            });
            return {
                ...v,
                fields,
            };
        } else {
            return v;
        }
    });
};

// function canStartPolling(postingVideosStatus) {
//     const canPolling = postingVideosStatus.reduce((accumulator, currentValue) => {
//         const status = currentValue.status.toUpperCase();
//         let completeFalg = false;
//         if (status === 'PROGRESSING' || status === 'READY' || status === 'UPLOADING')
//             completeFalg = true;

//         return accumulator = accumulator || completeFalg;
//     }, false);

//     return canPolling;
// }

// function startPolling(snsKind, videoId, dispatch, getState) {
//     const {
//         stage,
//         project
//     } = getState();

//     new Polling (async () => {
//         return await getPostingVideosAPI(stage, stage.endpoint, project.id, snsKind, videoId, 'status');
//     }, (response) => {
//         if (getState().posting.hasReset)
//             return true;

//         const startFlag = canStartPolling(response.data);

//         return !startFlag;
//     }, (response) => {
//         dispatch(
//             {
//                 type: GET_YOUTUBE_POSTING_STAUTS_SUCCESS,
//                 payload : response.data
//             }
//         )
//     }).run();
// };
