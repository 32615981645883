import { alpha } from "@mui/material";
import { Grid, LinearProgress, theme, Typography } from "@mzc-pdc/ui";
import React from "react";

export const LinearProgressDeterminate = ({ sx, withLabel = false, ...rest }) => {
    return (
        <Grid item sx={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
            <LinearProgress
                variant={"determinate"}
                {...rest}
                sx={{
                    flex: 1,
                    height: "6px",
                    borderRadius: "3px",
                    backgroundColor: alpha(theme.palette.greySecondary[50], 0.5),
                    "& .MuiLinearProgress-bar": {
                        height: "6px",
                        borderRadius: "3px",
                    },
                    ...sx,
                }}
            />
            {withLabel && (
                <Typography variant="body2_400" color="text.secondary" sx={{ flexGrow: 0 }}>{`${Math.round(
                    rest?.value,
                )}%`}</Typography>
            )}
        </Grid>
    );
};
