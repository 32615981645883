import * as React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack } from "@mzc-pdc/ui";
import Typography from "@components_v2/typography/typography";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import theme from "@styles/theme";
import TextField from "@components_v2/text-field/text-field";
import { IconLock } from "@mzc-cloudplex/icons";
import { useCallback, useMemo, useState } from "react";
import { UsersMultiInput, UsersMultiInputV2 } from "@features/users-input";
import validateInput, { convertValidationMessage } from "@cores/validateInput";
import { COMMENT_GROUP_TITLE_MAX_LENGTH } from "@constants";
import compareVersions, { VERSION_CM_11330_USERS_TEAMS_ACCESS_V3 } from "@cores/version";

export const DialogCreateGroup = ({ isLoading, onClose, onSubmit }) => {
    const { t } = useTranslation();

    const stage = useSelector(({ stage }) => stage);

    const [editValue, setEditValue] = React.useState({});
    const [validationErrors, setValidationErrors] = useState({});

    const onHandleSubmit = useCallback(() => {
        const members = editValue.members.map((member) => ({ type: member.type.toUpperCase(), id: member.id }));
        const params = {
            name: editValue.name,
            type: "PRIVATE",
            participants: members,
        };
        onSubmit(params);
    }, [editValue]);

    const setNewValidationErrors = (key, value) => {
        const newValidationErrors = { ...validationErrors, [key]: value };
        Object.keys(newValidationErrors ?? {}).forEach(
            (k) => newValidationErrors[k] == null && delete newValidationErrors[k],
        );
        setValidationErrors(newValidationErrors);
    };

    const onHandleChangeGroupName = (value) => {
        setNewValidationErrors(
            "name",
            validateInput(value, {
                required: true,
                maxLength: COMMENT_GROUP_TITLE_MAX_LENGTH,
            }),
        );
        setEditValue({ ...editValue, name: value });
    };

    const onHandleChangeMembers = (users) => {
        setNewValidationErrors("members", validateInput(users, { required: true }));
        setEditValue({ ...editValue, members: users });
    };

    const isDisabled = useMemo(() => {
        return Object.keys(validationErrors).length > 0 || !editValue.name || !editValue.members?.length;
    }, [validationErrors, editValue]);

    return (
        <Dialog maxWidth="480px" variant="basic" open={true} onClose={() => onClose()}>
            <DialogTitle>{t(`common::msg::Would you like to make a private comment?`)}</DialogTitle>
            <DialogContent sx={{ scrollbarGutter: "auto !important" }}>
                <Grid container flexDirection={"column"} gap={2.5}>
                    <Grid item>
                        <Typography variant={"body1_400"} color={theme.palette.greySecondary.main}>
                            <Trans
                                i18nKey={`common::msg::you can add users of the project to communicate with them.`}
                                values={{
                                    count: 5,
                                }}
                                components={[<Typography variant={"body1_700"} color={theme.palette.primary.main} />]}
                            />
                        </Typography>
                    </Grid>
                    <Stack gap={2}>
                        <Stack gap={1}>
                            <Typography
                                variant={"body1_500"}
                                color={theme.palette.greySecondary.main}
                                required={true}
                                requiredSize={"small"}
                            >
                                <Trans
                                    i18nKey={`common::label::Private comment name (maximum <0>{{count}}</0> characters)`}
                                    values={{
                                        count: 20,
                                    }}
                                    components={[
                                        <Typography variant={"body1_500"} color={theme.palette.primary.main} />,
                                    ]}
                                />
                            </Typography>
                            <TextField
                                value={editValue.name ?? ""}
                                onChange={(e) => onHandleChangeGroupName(e.target.value)}
                                error={validationErrors?.name?.level}
                                helperText={
                                    validationErrors?.name?.message
                                        ? validationErrors.name.message
                                        : t(convertValidationMessage(validationErrors?.name?.type))
                                }
                                inputProps={{
                                    startAdornment: <IconLock htmlColor={theme.palette.warning.main} size={24} />,
                                }}
                                placeholder={t(`common::msg::Please enter a private comment name.`)}
                            />
                        </Stack>
                        <Stack gap={1}>
                            <Typography
                                variant={"body1"}
                                color={theme.palette.greySecondary.main}
                                required={true}
                                requiredSize={"small"}
                            >
                                {t(`common::label::Add member`)}
                            </Typography>
                            {compareVersions(stage.version, VERSION_CM_11330_USERS_TEAMS_ACCESS_V3) >= 0 ? (
                                <UsersMultiInputV2
                                    filterSelectedOptions={true}
                                    placeholder={t(`common::msg::Add users to participate in private comments.`)}
                                    onChange={(users) => onHandleChangeMembers(users)}
                                    error={validationErrors?.members?.level}
                                    helperText={convertValidationMessage(validationErrors?.members?.type) ?? ""}
                                />
                            ) : (
                                <UsersMultiInput
                                    filterSelectedOptions={true}
                                    placeholder={t(`common::msg::Add users to participate in private comments.`)}
                                    onChange={(users) => onHandleChangeMembers(users)}
                                    error={validationErrors?.members?.level}
                                    helperText={convertValidationMessage(validationErrors?.members?.type) ?? ""}
                                />
                            )}
                        </Stack>
                    </Stack>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={`text`} onClick={() => onClose()} color={`greySecondary`}>
                    {t(`common::label::Cancel`, `Cancel`)}
                </Button>
                <Button
                    variant={`contained`}
                    loading={isLoading}
                    disabled={isLoading || isDisabled}
                    onClick={(e) => {
                        e.stopPropagation();
                        onHandleSubmit();
                    }}
                    color={`primary`}
                >
                    {t(`common::label::Make`, `Make`)}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
