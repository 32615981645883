class Polling {
    constructor(executeAsync, shouldStopPredicate, successCallback, errorCallback, interval) {
        this.executeAsync = executeAsync;
        this.shouldStopPredicate =
            shouldStopPredicate ||
            ((d) => {
                return true;
            });
        this.successCallback = successCallback || (() => {});
        this.errorCallback =
            errorCallback ||
            ((e) => {
                console.error(`error while polling`, e);
            });
        this.interval = interval || 1000;
    }

    async run() {
        try {
            const response = await this.executeAsync();
            this.successCallback(response);
            if (!this.shouldStopPredicate(response) && !this.timer) {
                this.timer = setTimeout(() => {
                    this.run();
                    this.timer = clearTimeout(this.timer);
                }, this.interval);
            }
        } catch (e) {
            if (this.timer) {
                clearTimeout(this.timer);
            }
            this.errorCallback(e);
        }
    }

    async resetTimer() {
        console.log("reset timer");
        this.timer = await clearTimeout(this.timer);
    }
}

export default Polling;
