import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import * as CategoryActions from "@modules/categoryGroupsManagement";
import Categories from "../../routes/categories/Categories";
import { CATEGORY_TYPES, LIMIT_BYTE, PUBLIC_URL } from "@constants";
import RangePicker from "../datepicker/RangePicker";
import AttributionInputArea from "../inputArea/AttributionInputArea";
import HashtagInputArea from "../inputArea/HashtagInputArea";
import Textarea from "../input/Textarea";
import InputField from "../input/InputField";
import validateInput from "../../cores/validateInput";
import limitMaxLength from "../../cores/limitMaxLength";
import fieldTypeEnum from "../../cores/fieldTypeEnum";
import WithSelect from "../select/Select";
import MetadataUseStatusTooltip from "../tooltip/MetadataUseStatusTooltip";
import compareVersions, {
    VERSION_CM_10877_ASSET_CUSTOM_METADATA,
    VERSION_CM_11209_ADD_CUSTOM_METADATA,
} from "@cores/version";
import { Divider, Grid, IconButton, List, ListItem, ListItemText, Stack } from "@mzc-pdc/ui";
import { SingleDatePicker } from "react-dates";
import theme from "@styles/theme";
import Typography from "@components_v2/typography/typography";
import { IconSchema, IconVisibility, ImageCategoryGroupOn } from "@mzc-cloudplex/icons";
import useExtendedCustomMetadata from "@hooks/useExtendedCustomMetadata";
import MetadataFieldReadMode from "@features/metadata-fields/metadata-field-read-mode";
import { CircledIcon } from "@components_v2/icon";

const MetadataView = (props) => {
    const { t } = useTranslation();

    const { parseCustomMetadata, getHiddenFieldValue, customMetadata } = useExtendedCustomMetadata({
        extendedMetadata: props.extendedMetadata,
    });

    const [isShowEditBox, setIsShowEditBox] = useState(false);
    const [data, setData] = useState({});
    const [value, setValue] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const [metadataSchemaFieldsFocused, setMetadataSchemaFieldsFocused] = useState([]);
    const [categoryGroupTooltipDisabled, setCategoryGroupTooltipDisabled] = useState(true);
    const [isResourceOwner, setIsResourceOwner] = useState(false);

    const dispatch = useDispatch();
    const project = useSelector(({ project }) => project);
    const stage = useSelector(({ stage }) => stage);

    const setDataState = () => {
        const { stage, createdBy } = props;

        const loginUserId = stage?.user?.id;
        const resourceOwnerId = createdBy?.id;
        setIsResourceOwner(loginUserId ? loginUserId === resourceOwnerId : false);
        setValue((prevState) => ({
            ...prevState,
            ...props, //Note: 이 부분 무슨 값이 들어가길래 props가 다 들어가는지 확인 필요함
        }));
    };

    const onHandleChangeCategories = useCallback((categories) => {
        //Note: checked
        setData((prevState) => ({
            ...prevState,
            categories: categories.categories.map((c) => ({
                id: c.value,
                name: c.label,
                categoryGroup: { id: categories.categoryGroup.id },
            })),
            categoryGroup: categories.categoryGroup,
        }));
    }, []);

    const onHandleClickCancelEditField = (key) => {
        //Note: checked
        hiddenEditBox(key);
    };

    const onHandleClickSaveField = (key) => {
        let oldKey = key;
        let fieldValue = data[key];
        props.onClickSaveField(key, fieldValue);

        if (key.indexOf(`metadata-schema-field-`) > -1) {
            fieldValue = isMetaDataValue(key, fieldValue);
            key = "metadataSchemas";
        }

        let newValue = { ...value, [key]: fieldValue };

        let categoryGroupTooltip = value.tooltipDisabled;

        if (!categoryGroupTooltip) {
            if (newValue.categoryGroup?.id === data.categoryGroup?.id) {
                categoryGroupTooltip = !categoryGroupTooltip;
            }
        }

        if (key === "categories" && data.categoryGroup) {
            newValue.categoryGroup = data.categoryGroup;
            setCategoryGroupTooltipDisabled(categoryGroupTooltip);
        }

        setValue(newValue);
        if (key !== oldKey) key = oldKey;

        hiddenEditBox(key);
    };

    const onClickEditField = (key, v) => {
        let newData = { ...data, [key]: v };
        if (key === "categories") newData.categoryGroup = value.categoryGroup;
        setData(newData);
        setIsShowEditBox(true);
    };

    const onInputAddTag = (v) => {
        setData((prevState) => ({
            ...prevState,
            tags: (prevState.tags || []).concat({ value: v }),
        }));
    };

    const onInputRemoveTag = (index) => {
        setData((prevState) => ({
            ...prevState,
            tags: prevState.tags?.filter((v, i) => i !== index),
        }));
    };

    const onChangeAttributionValue = (id, v, validationError) => {
        updateAttribution(id, { value: v }, { [`attribution-${id}-value`]: validationError });
    };

    const onChangeAttributionKey = (id, key, validationError) => {
        updateAttribution(id, { key }, { [`attribution-${id}-key`]: validationError });
    };

    const onChangeData = (key, v, validationError) => {
        setData({ ...data, [key]: v });
        setValidationErrors({ ...validationErrors, [key]: validationError });
    };

    const onChangeDescription = (description) => {
        onChangeData("description", description, validateInput(description, { maxByte: LIMIT_BYTE.description }));
    };

    const onClickAddAttribution = (id, validationError) => {
        setData((prevState) => ({
            ...prevState,
            attributions: (prevState.attributions || []).concat([{ id, key: "", value: "" }]),
        }));
        setValidationErrors((prevState) => ({
            ...prevState,
            [`attribution-${id}-key`]: validationError,
            [`attribution-${id}-value`]: validationError,
        }));
    };

    const onClickRemoveAttribution = (id) => {
        setData((prevState) => ({
            ...prevState,
            attributions: prevState.attributions.filter((a) => a.id !== id),
        }));
        setValidationErrors((prevState) => ({
            ...prevState,
            [`attribution-${id}-key`]: undefined,
            [`attribution-${id}-value`]: undefined,
        }));
    };

    const updateAttribution = (id, data, validationError) => {
        setData((prevState) => ({
            ...prevState,
            attributions: prevState.attributions.map((a) => (a.id === id ? { ...a, ...data } : a)),
        }));
        setValidationErrors((prevState) => ({ ...prevState, ...validationError }));
    };

    const hiddenEditBox = (key) => {
        const newData = { ...data };
        delete newData[key];
        setData(newData);
        setIsShowEditBox(Object.keys(newData).length > 0);
    };

    const getIsShowEditBox = useCallback((key) => !!isShowEditBox && data.hasOwnProperty(key), [isShowEditBox, data]);

    const isMetaDataValue = (k, v) => {
        const fieldId = k.replace("metadata-schema-field-", "");
        const metadataSchemas = JSON.parse(JSON.stringify(value.metadataSchemas));

        metadataSchemas.forEach((schema) =>
            schema.fields.forEach((f) => {
                if (f.id === fieldId) f.value = v;
            }),
        );

        return metadataSchemas;
    };

    const onHandleClickGetHiddenFieldValue = (metadataSchemaId, metadataFieldId, metadataId) => {
        props.onClickGetHiddenFieldValue(metadataSchemaId, metadataFieldId, metadataId);
    };

    const getMetadataFieldValue = (f) => {
        if (f.type !== "SELECT_MULTI") {
            return f.value !== "" ? f.value : t(`common::label::${"None"}`);
        } else {
            return f.values && f.values.length > 0 ? f.values.join(", ") : t(`common::label::${"None"}`);
        }
    };

    const getCategoryGroup = async (categories) => {
        if (!categories || categories.length === 0) return;

        const categoryGroupId = categories[0]?.categoryGroup?.id || categories[0]?.categoryGroupId;
        const categoryGroupInfo = await dispatch(CategoryActions.getCategoryGroup(project.id, categoryGroupId));

        if (categoryGroupInfo) {
            setValue((prevState) => ({
                ...prevState,
                categoryGroup: { id: categoryGroupInfo.id, label: categoryGroupInfo.name },
            }));
        }
    };

    const onClickGetHiddenFieldValue = async (fieldId) => {
        try {
            await getHiddenFieldValue({ fieldId });
        } catch (e) {
            console.log(e);
        }
    };

    const getItemTemplate = (el) => {
        return (
            <React.Fragment>
                <MetadataFieldReadMode
                    type={el.type}
                    isHidden={el?.isHidden && !el.isCheckHiddenValue}
                    value={el.value}
                />
                {el?.isHidden &&
                    (el?.isPending ? (
                        <i className="sprite sprite sprite-loading"></i>
                    ) : (
                        <IconButton
                            circled
                            size={"small"}
                            disabled={el.isCheckHiddenValue}
                            onClick={() => {
                                onClickGetHiddenFieldValue(el?.id);
                            }}
                        >
                            {el.isCheckHiddenValue ? (
                                <IconVisibility size={16} />
                            ) : (
                                <CircledIcon size={"24px"} padding={"4px"} color={theme.palette.secondary[50]}>
                                    <IconVisibility size={16} />
                                </CircledIcon>
                            )}
                        </IconButton>
                    ))}
            </React.Fragment>
        );
    };

    const renderCustomField = (field) => {
        const key = `metadata-schema-field-${field.id}`;
        const fieldEnum = Object.freeze(fieldTypeEnum);
        const validationError = validateInput(data[key], {
            required: false,
            maxByte: fieldEnum[field.type],
        });
        switch (field.type) {
            case "TEXT_FIELD":
            case "NUMBER":
                return (
                    <InputField
                        isEdit={true}
                        type={field.type === "NUMBER" ? "number" : "text"}
                        wrapperClass="box box-in-input"
                        value={data[key]}
                        validationError={validationError}
                        onChange={(e) => {
                            limitMaxLength(e, data[key], LIMIT_BYTE.comment);
                            onChangeData(key, e.target.value);
                        }}
                    />
                );
            case "TEXT_AREA":
                return (
                    <Textarea
                        isEdit={true}
                        wrapperClass="textarea"
                        value={data[key]}
                        placeholder={field.name}
                        validationError={validationError}
                        onChange={(e) => {
                            limitMaxLength(e, data[key], LIMIT_BYTE.description);
                            onChangeData(key, e.target.value);
                        }}
                    />
                );
            case "DATE_PICKER_SINGLE":
                return (
                    <SingleDatePicker
                        isEdit={true}
                        date={data[key] ? moment(data[key]) : null}
                        onDateChange={(date) => {
                            onChangeData(key, date ? moment(date).format("YYYY-MM-DD") : "");
                        }}
                        displayFormat={"YYYY-MM-DD"}
                        isOutsideRange={() => false}
                        focused={metadataSchemaFieldsFocused[key]?.focused}
                        onFocusChange={({ focused }) => {
                            setMetadataSchemaFieldsFocused((prevState) => ({
                                ...prevState,
                                [key]: {
                                    ...prevState[key],
                                    focused,
                                },
                            }));
                        }}
                        numberOfMonths={1}
                        small={true}
                        showDefaultInputIcon={true}
                        inputIconPosition="before"
                        anchorDirection={"right"}
                    />
                );
            case "DATE_PICKER_RANGE":
                return (
                    <RangePicker
                        isEdit={true}
                        startDate={data?.[key]?.split("~")?.[0] ? moment(data[key].split("~")[0]) : null}
                        endDate={data?.[key]?.split("~")?.[1] ? moment(data[key].split("~")[1]) : null}
                        displayFormat={"YYYY-MM-DD"}
                        isOutsideRange={() => false}
                        onDatesChange={({ startDate, endDate }) => {
                            const dateRange = `${startDate ? moment(startDate).format("YYYY-MM-DD") : ""}~${
                                endDate ? moment(endDate).format("YYYY-MM-DD") : ""
                            }`;
                            onChangeData(key, dateRange === "~" ? "" : dateRange);
                        }}
                    />
                );
            case "SELECT_SINGLE":
            case "SELECT_MULTI":
                const dict = {};
                field.itemSet.map((v) => {
                    dict[v] = "";
                });

                if (field.type === "SELECT_SINGLE") dict[data[key]] = "";
                else {
                    data[key].map((v) => {
                        dict[v] = "";
                    });
                }

                const options = Object.keys(dict).map((v) => {
                    return {
                        value: v,
                        label: v,
                    };
                });

                return (
                    <div className={""}>
                        <WithSelect
                            defaultValue={
                                field.type === "SELECT_SINGLE"
                                    ? data[key]
                                    : data[key].map((v) => {
                                          return { label: v, value: v };
                                      })
                            }
                            options={options}
                            isMulti={field.type !== "SELECT_SINGLE"}
                            hideSelectedOptions={false}
                            onChange={(option) => {
                                if (field.type === "SELECT_SINGLE") onChangeData(key, option.value);
                                else onChangeData(key, option && option.length > 0 ? option.map((v) => v.value) : []);
                            }}
                        />
                    </div>
                );
            default:
                return <div></div>;
        }
    };

    const getMetadataListItem = useCallback(({ el, index }) => {
        return (
            <li>
                <div className="title">
                    <strong>{el.subtitle}</strong>
                </div>
                <div className="content">
                    {el.value ? (
                        <span> {getItemTemplate(el)}</span>
                    ) : (
                        <span className={"text-cancel"}>{t(`common::label::${"None"}`)}</span>
                    )}
                </div>
            </li>
        );
    }, []);

    useEffect(() => {
        getCategoryGroup(value.categories, "value");
    }, [value.categories]);

    useEffect(() => {
        setDataState();
    }, [props]);

    return (
        <div className={`list-overview ${value.isColumn && "column"}`}>
            <ul>
                {value.description !== undefined && (
                    <li>
                        <div className="title">
                            <strong>{t(`common::label::${"Description"}`)}</strong>
                            {value.isEditable && !getIsShowEditBox("description") && (
                                <button
                                    type={"button"}
                                    className={"btn btn-icon-solid btn-edit"}
                                    onClick={() => {
                                        onClickEditField("description", value.description);
                                    }}
                                >
                                    <i className={"sprite sprite-edit"}></i>
                                </button>
                            )}
                        </div>
                        <div className="content">
                            {getIsShowEditBox("description") ? (
                                <React.Fragment>
                                    <Textarea
                                        wrapperClass="box"
                                        isEdit={true}
                                        value={data.description}
                                        placeholder={t(`common::label::${"Description"}`)}
                                        maxByte={LIMIT_BYTE.description}
                                        validationError={validationErrors.description}
                                        onChange={(e) => {
                                            onChangeDescription(e.target.value);
                                        }}
                                    />
                                    <div className="btns-editable">
                                        <button
                                            type={"button"}
                                            className={"btn btn-icon btn-sm"}
                                            onClick={() => {
                                                onHandleClickCancelEditField("description");
                                            }}
                                        >
                                            <i className={"sprite sprite-edit-cancel"} />
                                        </button>
                                        <button
                                            type={"button"}
                                            className={"btn btn-icon btn-sm btn-outline-primary"}
                                            disabled={validationErrors.description}
                                            onClick={() => {
                                                onHandleClickSaveField("description");
                                            }}
                                        >
                                            <i className={"sprite sprite-edit-submit"} />
                                        </button>
                                    </div>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {value.description ? (
                                        <span>{value.description}</span>
                                    ) : (
                                        <span className={"text-cancel"}>{t(`common::label::${"None"}`)}</span>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                    </li>
                )}
                <li>
                    <div className="title">
                        <strong>{t(`common::label::${"Categories"}`)}</strong>
                        {value.isEditable && !getIsShowEditBox("categories") && (
                            <button
                                type={"button"}
                                className={"btn btn-icon-solid btn-edit"}
                                onClick={() => {
                                    onClickEditField("categories", value.categories);
                                }}
                            >
                                <i className={"sprite sprite-edit"}></i>
                            </button>
                        )}
                    </div>

                    <div className="content">
                        {getIsShowEditBox("categories") ? (
                            <React.Fragment>
                                <div className="form-stack box-border box-border-sm  box-border-radius is-edit">
                                    <Categories
                                        categoryGroups={true}
                                        selectedCategoryGroup={data.categoryGroup}
                                        type={value.categoryType || CATEGORY_TYPES.ASSET}
                                        placeholder={t(`common::label::${"Select category"}`)}
                                        selectedValues={data?.categories?.map((c) => {
                                            return {
                                                label: c.name,
                                                name: c.name,
                                                value: c.id,
                                                id: c.id,
                                            };
                                        })}
                                        onChange={(options) => {
                                            onHandleChangeCategories(options);
                                        }}
                                    />
                                </div>
                                <div className="btns-editable">
                                    <button
                                        type={"button"}
                                        className={"btn btn-icon btn-sm"}
                                        onClick={() => {
                                            onHandleClickCancelEditField("categories");
                                        }}
                                    >
                                        <i className={"sprite sprite-edit-cancel"} />
                                    </button>
                                    <button
                                        type={"button"}
                                        className={"btn btn-icon btn-sm btn-outline-primary"}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onHandleClickSaveField("categories");
                                        }}
                                    >
                                        <i className={"sprite sprite-edit-submit"} />
                                    </button>
                                </div>
                            </React.Fragment>
                        ) : (
                            <>
                                {value?.categories?.length > 0 ? (
                                    <>
                                        <div className="form-label d-flex align-items-center">
                                            <ImageCategoryGroupOn width={16} height={16} />
                                            <strong style={{ marginLeft: `8px` }}>{value.categoryGroup?.label}</strong>
                                            <MetadataUseStatusTooltip
                                                tooltipDisabled={categoryGroupTooltipDisabled}
                                                anchorOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "left",
                                                }}
                                                transformOrigin={{
                                                    vertical: "top",
                                                    horizontal: "left",
                                                }}
                                                type={"right-start"}
                                                activeTab={value.activeTab}
                                                categoryGroupId={value.categoryGroup?.id}
                                                categoryType={value.categoryType}
                                            />
                                        </div>
                                        <span>{value?.categories?.map((v) => v.name).join(", ")}</span>
                                    </>
                                ) : (
                                    <span className={"text-cancel"}>{t(`common::label::${"None"}`)}</span>
                                )}
                            </>
                        )}
                    </div>
                </li>

                <li>
                    <div className="title">
                        <strong>{t(`common::label::${"Attributions"}`)}</strong>
                        {value.isEditable && !getIsShowEditBox("attributions") && (
                            <button
                                type={"button"}
                                className={"btn btn-icon-solid btn-edit"}
                                onClick={() => {
                                    onClickEditField("attributions", value.attributions);
                                }}
                            >
                                <i className={"sprite sprite-edit"}></i>
                            </button>
                        )}
                    </div>
                    <div className="content">
                        {getIsShowEditBox("attributions") ? (
                            <React.Fragment>
                                <div className={"box-border box-border-sm box-border-radius is-edit"}>
                                    <AttributionInputArea
                                        values={data.attributions}
                                        onChangeKey={(id, v, validationError) => {
                                            onChangeAttributionKey(id, v, validationError);
                                        }}
                                        onChangeValue={(id, e, validationError) => {
                                            onChangeAttributionValue(id, e, validationError);
                                        }}
                                        onClickAddAttribution={(id, validationError) => {
                                            onClickAddAttribution(id, validationError);
                                        }}
                                        onClickRemoveAttribution={(id) => {
                                            onClickRemoveAttribution(id);
                                        }}
                                    />
                                </div>
                                <div className="btns-editable">
                                    <button
                                        type={"button"}
                                        className={"btn btn-icon btn-sm"}
                                        onClick={() => {
                                            onHandleClickCancelEditField("attributions");
                                        }}
                                    >
                                        <i className={"sprite sprite-edit-cancel"} />
                                    </button>
                                    <button
                                        type={"button"}
                                        className={"btn btn-icon btn-sm btn-outline-primary"}
                                        disabled={
                                            Object.keys(validationErrors).filter(
                                                (k) => k.indexOf("attribution") > -1 && validationErrors[k],
                                            ).length > 0
                                        }
                                        onClick={() => {
                                            onHandleClickSaveField("attributions");
                                        }}
                                    >
                                        <i className={"sprite sprite-edit-submit"} />
                                    </button>
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {value?.attributions?.length > 0 ? (
                                    <ul className="tags-info">
                                        {value.attributions.map((a, i) => (
                                            <li key={i}>
                                                <strong>{a.key}</strong> :{" "}
                                                <span className="text-secondary">{a.value}</span>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <span className={"text-cancel"}>{t(`common::label::${"None"}`)}</span>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                </li>

                <li>
                    <div className="title">
                        <strong>{t(`common::label::${"Tags"}`)}</strong>
                        {value.isEditable && !getIsShowEditBox("tags") && (
                            <button
                                type={"button"}
                                className={"btn btn-icon-solid btn-edit"}
                                onClick={() => {
                                    onClickEditField("tags", value.tags || []);
                                }}
                            >
                                <i className={"sprite sprite-edit"}></i>
                            </button>
                        )}
                    </div>

                    <div className="content">
                        {getIsShowEditBox("tags") ? (
                            <React.Fragment>
                                <HashtagInputArea
                                    isEdit={true}
                                    values={data.tags.map((v) => v.value)}
                                    onInputAddTag={onInputAddTag}
                                    onInputRemoveTag={onInputRemoveTag}
                                    onChangeInput={(e, validationError) => {
                                        // ToDO : validation
                                        // this.props.MetadataActions.setValidationError({
                                        //     key : 'hashtag',
                                        //     error : validationError
                                        // });
                                    }}
                                />

                                <div className="btns-editable">
                                    <button
                                        type={"button"}
                                        className={"btn btn-icon btn-sm"}
                                        onClick={() => {
                                            onHandleClickCancelEditField("tags");
                                        }}
                                    >
                                        <i className={"sprite sprite-edit-cancel"} />
                                    </button>
                                    <button
                                        type={"button"}
                                        className={"btn btn-icon btn-sm btn-outline-primary"}
                                        onClick={() => {
                                            onHandleClickSaveField("tags");
                                        }}
                                    >
                                        <i className={"sprite sprite-edit-submit"} />
                                    </button>
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {value?.tags?.length > 0 ? (
                                    <div className={"list-tags"}>
                                        {value.tags
                                            .sort((a, b) => a.value.localeCompare(b.value))
                                            .map((t, i) => (
                                                <span key={`${i}_${t.id}`} className={"tag"}>
                                                    {/* ToDO AI에서 추출된 경우는 class ai 추가 */}
                                                    {t.value}
                                                </span>
                                            ))}
                                    </div>
                                ) : (
                                    <span className={"text-cancel"}>{t(`common::label::${"None"}`)}</span>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                </li>
            </ul>
            <hr />
            {(value.categoryType === CATEGORY_TYPES.VIDEO ||
                (value.categoryType === CATEGORY_TYPES.ASSET &&
                    ["IMAGE", "MP4", "THUMBNAILS", "DASH"].indexOf(value.type) >= 0)) && (
                <React.Fragment>
                    <ul>
                        <li>
                            <div className="title">
                                <strong>{t(`common::label::${"People"}`)}</strong>
                            </div>

                            <div className="content">
                                {value?.people?.length > 0 ? (
                                    <React.Fragment>
                                        <div className={"list-image list-image-sm list-image-half"}>
                                            <ul>
                                                {value.people.map((p, i) => (
                                                    <li key={`people-${i}`}>
                                                        <div className="content">
                                                            <span className={"image"}>
                                                                <img
                                                                    src={
                                                                        p.accessUrl ||
                                                                        `${PUBLIC_URL}/images/thumbnail_no_image.png`
                                                                    }
                                                                    onError={(e) => {
                                                                        e.target.src = `${PUBLIC_URL}/images/thumbnail_no_image.png`;
                                                                    }}
                                                                />
                                                            </span>
                                                            <p>{p.name}</p>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <span className={"text-cancel"}>{t(`common::label::${"None"}`)}</span>
                                )}
                            </div>
                        </li>
                    </ul>
                    <hr />
                </React.Fragment>
            )}
            <ul>
                {value?.metadataSchemas?.map((v, i) =>
                    v?.fields?.map((f, k) => (
                        <li key={`${i}-${k}`}>
                            <div className="title">
                                <strong>{f.name}</strong>
                                {(!f.isHidden || isResourceOwner || f.isCheckHiddenValue) &&
                                    value.isEditable &&
                                    !getIsShowEditBox(`metadata-schema-field-${f.id}`) && (
                                        <button
                                            type={"button"}
                                            className={"btn btn-icon-solid btn-edit"}
                                            onClick={() => {
                                                onClickEditField(
                                                    `metadata-schema-field-${f.id}`,
                                                    f.type !== "SELECT_MULTI" ? f.value : f.values || [],
                                                );
                                            }}
                                            style={{ height: "auto", marginBottom: "2px" }}
                                        >
                                            <i className={"sprite sprite-edit"}></i>
                                        </button>
                                    )}
                            </div>

                            <div className="content">
                                {getIsShowEditBox(`metadata-schema-field-${f.id}`) ? (
                                    <React.Fragment>
                                        {compareVersions(stage.version, VERSION_CM_10877_ASSET_CUSTOM_METADATA) < 0 &&
                                        value.type === "Asset"
                                            ? null
                                            : renderCustomField(f)}
                                        <div className="btns-editable">
                                            <button
                                                type={"button"}
                                                className={"btn btn-icon btn-sm"}
                                                onClick={() => {
                                                    onHandleClickCancelEditField(`metadata-schema-field-${f.id}`);
                                                }}
                                            >
                                                <i className={"sprite sprite-edit-cancel"} />
                                            </button>
                                            <button
                                                type={"button"}
                                                className={"btn btn-icon btn-sm btn-outline-primary"}
                                                onClick={() => {
                                                    onHandleClickSaveField(`metadata-schema-field-${f.id}`);
                                                }}
                                            >
                                                <i className={"sprite sprite-edit-submit"} />
                                            </button>
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <span
                                            className={`${
                                                (!f.isHidden || f.isCheckHiddenValue) &&
                                                (f.value || (f.values && f.values.length > 0))
                                                    ? "vertical-middle"
                                                    : "text-cancel"
                                            }`}
                                        >
                                            {f.isHidden && !isResourceOwner && !f.isCheckHiddenValue ? (
                                                <React.Fragment>
                                                    <span className="text-hidden">
                                                        &middot;&middot;&middot;&middot;&middot;
                                                    </span>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>{getMetadataFieldValue(f)}</React.Fragment>
                                            )}
                                        </span>
                                        {f.isHidden && (
                                            <button
                                                type="button"
                                                className="btn btn-icon-solid btn-toggle-value"
                                                disabled={isResourceOwner || f.isCheckHiddenValue}
                                                onClick={(e) => {
                                                    onHandleClickGetHiddenFieldValue(v.id, f.id, f.metadataId);
                                                }}
                                            >
                                                {f.pending ? (
                                                    <i className="sprite sprite sprite-loading"></i>
                                                ) : (
                                                    <i className="sprite sprite-view-show"></i>
                                                )}
                                                {/* <i className="sprite sprite-view-show"></i> */}
                                                {/* <i className="sprite sprite-view-hide"></i> */}
                                            </button>
                                        )}
                                    </React.Fragment>
                                )}
                            </div>
                        </li>
                    )),
                )}
                {compareVersions(stage.version, VERSION_CM_11209_ADD_CUSTOM_METADATA) >= 0 &&
                    props.useCustomMetadata &&
                    customMetadata &&
                    customMetadata.length > 0 &&
                    parseCustomMetadata(customMetadata)?.map((el, index) => getMetadataListItem({ el, index }))}
            </ul>
        </div>
    );
};

export default MetadataView;
