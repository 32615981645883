import {handleActions, createAction} from 'redux-actions';
import {
    axios,
    axiosWithoutHeaders
} from "../cores/axiosWrapper";

function getStreamAPI(stageId, apiEndpoint, projectId, id) {
    return axios.get(`${apiEndpoint}/streams/${id}`, {
        headers : {
            stageId,
            projectId
        }
    });
}

function patchStreamAPI(stageId, apiEndpoint, projectId, id, params) {
    return axios.patch(`${apiEndpoint}/streams/${id}`, {
        ...params,
        project:{
            id:projectId
        }
    }, {
        headers: {
            stageId,
            projectId,
            'Content-Type': 'application/json'
        }
    })
}

function getInputAPI(stageId, apiEndpoint, projectId, channelId, inputId) {
    return axios.get(`${apiEndpoint}/channels/${channelId}/inputs/${inputId}`, {
        headers : {
            stageId,
            projectId
        }
    });
}

//action type
const STREAM_PENDING = 'STREAM_PENDING';
const STREAM_FAILURE = 'STREAM_FAILURE';
const GET_STREAM_SUCCESS = 'GET_STREAM_SUCCESS';
const UPDATE_STREAM = 'UPDATE_STREAM';
const PUT_STREAM_SUCCESS = 'PUT_STREAM_SUCCESS';
const INIT_STREAM = 'INIT_STREAM';
const GET_INPUT_SUCCESS = 'GET_INPUT_SUCCESS';
const RESET_STREAM = 'RESET_STREAM';

//reducer
const initialState = {
    pending: false,
    error: false,
    data: null
};

export default handleActions({
    [GET_INPUT_SUCCESS] : (state, action) => {
        return {
            ...state,
            pending:false,
            error:false,
            data : {
                ...state.data,
                attachedInput : {
                    ...state.data.attachedInput,
                    ...action.payload.data
                }                
            }
        }
    },
    [STREAM_PENDING]: (state) => {
        return {
            ...state,
            pending: true,
            error: false
        }
    },
    [STREAM_FAILURE]:(state, action) => {
        return {
            ...state,
            pending: false,
            error: true
        }
    },
    [GET_STREAM_SUCCESS]:(state, action) => {        
        return {
            ...state,
            pending:false,
            error:false,
            data : action.payload
        }
    },
    [UPDATE_STREAM]: (state, action) => {
        return {
            ...state,
            pending:false,
            error:false,
            data : {
                ...state.data,
                ...action.payload
            }
        }
    },
    [PUT_STREAM_SUCCESS]:(state, action) => {
        return {
            ...state,
            pending:false,
            error:false,
            data : {
                ...action.payload
            }
        }
    },
    [INIT_STREAM]:(state) => {
        return {
            ...state,
            data : null
        }
    },
    [RESET_STREAM]:(state) => {
        return {
            pending: false,
            error: false,
            data: null
        }
    }
}, initialState);

export const getStream = (projectId, id) => (dispatch, getState) => {
    const {
        stage
    } = getState();

    dispatch({type: STREAM_PENDING});
    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    return getStreamAPI(stageId, apiEndpoint, projectId, id).then(
        async (response) => {
            const data = await addAttachedInputAddtionalInfo(stageId, apiEndpoint, projectId, response.data);
            dispatch({
                type: GET_STREAM_SUCCESS,
                payload: data
            });
        }
    ).catch(error => {
        dispatch({
            type: STREAM_FAILURE,
            payload: error
        });
    });
};
export const updateStream = createAction(UPDATE_STREAM);
export const patchStream = (id, projectId, params) => (dispatch, getState) => {
    const {
        stage
    } = getState();
    const stageId = stage.id;
    return patchStreamAPI(stageId, stage.endpoint, projectId, id, params).then(
        async (response) => {
            const data = await addAttachedInputAddtionalInfo(stageId, stage.endpoint, projectId, response.data);
            dispatch({
                type: PUT_STREAM_SUCCESS,
                payload: data
            });
        }
    ).catch(error => {
        dispatch({
            type: STREAM_FAILURE,
            payload: error
        });
    });
};

export const getInput = (channelId, inputId) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();
    
    dispatch({type: STREAM_PENDING});

    return getInputAPI(stage.id, stage.endpoint, project.id, channelId, inputId).then(
        (response) => {
            dispatch({
                type: GET_INPUT_SUCCESS,
                payload: response
            });
        }
    ).catch(error => {
        dispatch({
            type: STREAM_FAILURE,
            payload: error
        });
    });
}

async function addAttachedInputAddtionalInfo(stageId, apiEndpoint, projectId, data) {
    if(data && data.channel && data.channel.id && data.channelInput && data.channelInput.inputId) {
        const _response = await getInputAPI(stageId, apiEndpoint, projectId, data.channel.id, data.channelInput.inputId);
        data.attachedInput = {
            ...data.attachedInput,
            ..._response.data
        };
    }

    return data;
}
export const pending = createAction(STREAM_PENDING);
export const initStramSource = createAction(INIT_STREAM);
export const reset = createAction(RESET_STREAM);
