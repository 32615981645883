import { handleActions, createAction } from "redux-actions";
import fp from "lodash/fp";
import { axios } from "../cores/axiosWrapper";

const getSharedAssetsUsersAPI = (stageId, apiEndpoint, projectId, queryString) => {
    return axios.get(`${apiEndpoint}/users${queryString}`, {
        headers: {
            stageId,
            projectId,
        },
    });
};

const getSharedAssetsTeamsAPI = (stageId, apiEndpoint, queryString) => {
    return axios.get(`${apiEndpoint}/teams${queryString}`, {
        headers: {
            stageId,
        },
    });
};

const GET_SHARED_ASSETS_USERS_PENDING = "GET_SHARED_ASSETS_USERS_PENDING";
const GET_SHARED_ASSETS_USERS_FAILURE = "GET_SHARED_ASSETS_USERS_FAILURE";
const GET_SHARED_ASSETS_USERS_SUCCESS = "GET_SHARED_ASSETS_USERS_SUCCESS";
const GET_SHARED_ASSETS_TEAMS_PENDING = "GET_SHARED_ASSETS_TEAMS_PENDING";
const GET_SHARED_ASSETS_TEAMS_FAILURE = "GET_SHARED_ASSETS_TEAMS_FAILURE";
const GET_SHARED_ASSETS_TEAMS_SUCCESS = "GET_SHARED_ASSETS_TEAMS_SUCCESS";
const UPDATE_STATE = "UPDATE_STATE";

const initialState = {
    users: {
        data: [],
        pending: false,
        error: false,
    },
    teams: {
        data: [],
        pending: false,
        error: false,
    },
};

export const updateSharedState = createAction(UPDATE_STATE);

export default handleActions(
    {
        [GET_SHARED_ASSETS_USERS_PENDING]: (state) => {
            return {
                ...state,
                users: {
                    ...fp.get(`shared.users`, state),
                    pending: true,
                    error: false,
                },
            };
        },
        [GET_SHARED_ASSETS_USERS_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                users: {
                    data,
                    pending: false,
                    error: false,
                },
            };
        },
        [GET_SHARED_ASSETS_USERS_FAILURE]: (state) => {
            return {
                ...state,
                users: {
                    ...fp.get(`shared.users`, state),
                    pending: false,
                    error: true,
                },
            };
        },
        [GET_SHARED_ASSETS_TEAMS_PENDING]: (state) => {
            return {
                ...state,
                teams: {
                    ...fp.get(`shared.teams`, state),
                    pending: true,
                    error: false,
                },
            };
        },
        [GET_SHARED_ASSETS_TEAMS_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                teams: {
                    data,
                    pending: false,
                    error: false,
                },
            };
        },
        [GET_SHARED_ASSETS_TEAMS_FAILURE]: (state) => {
            return {
                ...state,
                teams: {
                    ...fp.get(`shared.teams`, state),
                    pending: false,
                    error: true,
                },
            };
        },
        [UPDATE_STATE]: (state, action) => {
            return {
                ...state,
                ...fp.get(`payload`, action),
            };
        },
    },
    initialState,
);

export const getSharedAssetsUsers = (queryString) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: GET_SHARED_ASSETS_USERS_PENDING });

    return getSharedAssetsUsersAPI(stage.id, stage.endpoint, queryString)
        .then((response) => {
            dispatch({
                type: GET_SHARED_ASSETS_USERS_SUCCESS,
                payload: response,
            });
            return response && response.data;
        })
        .catch((error) => {
            dispatch({
                type: GET_SHARED_ASSETS_USERS_FAILURE,
                payload: error,
            });
        });
};

export const getSharedAssetsTeams = (queryString) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: GET_SHARED_ASSETS_TEAMS_PENDING });

    return getSharedAssetsTeamsAPI(stage.id, stage.endpoint, queryString)
        .then((response) => {
            dispatch({
                type: GET_SHARED_ASSETS_TEAMS_SUCCESS,
                payload: response,
            });
            return response && response.data;
        })
        .catch((error) => {
            dispatch({
                type: GET_SHARED_ASSETS_TEAMS_FAILURE,
                payload: error,
            });
        });
};
