import { axios } from "@cores/axiosWrapper";

export function patchProjectUserAPI(stageId, apiEndpoint, projectId, userId, body) {
    const headers = {
        stageId,
        projectId,
    };

    return axios.patch(`${apiEndpoint}/projects/${projectId}/members/${userId}`, body, {
        headers,
    });
}

export function getUsersAPI(stageId, apiEndpoint, projectId, params) {
    let queries = {
        shouldRequestDetail: params.shouldRequestDetail,
        offset: params.offset * params.limit,
        limit: params.limit,
    };

    if (params.name) queries = { ...queries, name: params.name };

    const headers = {
        stageId,
    };

    if (params.keyword) {
        queries[params.keywordType] = params.keyword;
    }

    if (projectId) {
        headers.projectId = projectId;
    }

    return axios.get(`${apiEndpoint}/users`, {
        params: queries,
        headers,
    });
}

export function getUserAPI(stageId, apiEndpoint, projectId, id) {
    const headers = {
        stageId,
    };
    if (projectId) {
        headers.projectId = projectId;
    }

    return axios.get(`${apiEndpoint}/users/${id}`, {
        headers,
    });
}

export function getUserRolesAPI(stageId, apiEndpoint, projectId, id, params) {
    const headers = {
        stageId,
    };

    if (projectId) {
        headers.projectId = projectId;
    }

    return axios.get(`${apiEndpoint}/users/${id}/roles`, {
        params,
        headers,
    });
}

export function putUserRoleAPI(stageId, apiEndpoint, id, roleId) {
    return axios.put(`${apiEndpoint}/users/${id}/roles/${roleId}`);
}

export function deleteUserRoleAPI(stageId, apiEndpoint, id, roleId) {
    return axios.put(`${apiEndpoint}/users/${id}/roles/${roleId}`);
}

export function getUserTeamsAPI(stageId, apiEndpoint, projectId, id, params) {
    const headers = {
        stageId,
    };

    const queries = params;

    if (projectId) {
        headers.projectId = projectId;
    }

    if (params.keyword) {
        queries[params.keywordType] = params.keyword;
    }

    return axios.get(`${apiEndpoint}/teams?userId=${id}`, {
        params: queries,
        headers,
    });
}

export function patchUserAPI(stageId, apiEndpoint, projectId, id, body) {
    const headers = {
        stageId,
    };

    if (projectId) {
        headers.projectId = projectId;
    }

    return axios.patch(`${apiEndpoint}/users/${id}`, body, {
        headers,
    });
}

export function deleteUserAPI(stageId, apiEndpoint, projectId, id, body) {
    const headers = {
        stageId,
    };

    if (projectId) {
        headers.projectId = projectId;
    }

    return axios.delete(`${apiEndpoint}/users/${id}`, {
        headers,
    });
}
