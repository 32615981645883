import { axios, axiosWithoutHeaders } from "../cores/axiosWrapper";
import { handleActions, createAction } from "redux-actions";

const IV_MEETING_LIST_RESET = "IV_MEETING_LIST_RESET";
const IV_MEETING_LIST_PENDING = "IV_MEETING_LIST_PENDING";
// MEETING LIST
const IV_GET_MEETING_LIST_SUCCESS = "IV_GET_MEETING_LIST_SUCCESS";
const IV_GET_MEETING_LIST_FAILURE = "IV_GET_MEETING_LIST_FAILURE";
const IV_DELETE_MEETING_ITEM_SUCCESS = "IV_DELETE_MEETING_ITEM_SUCCESS";
const IV_DELETE_MEETING_ITEM_FAILURE = "IV_DELETE_MEETING_ITEM_FAILURE";

const initialState = {
    pending: false,
    error: false,
    data: [],
    totalCount: 0,
};

export default handleActions(
    {
        [IV_MEETING_LIST_RESET]: (state, action) => {
            return {
                ...initialState,
            };
        },

        [IV_MEETING_LIST_PENDING]: (state, action) => {
            return {
                ...state,
                pending: true,
                error: false,
            };
        },

        [IV_GET_MEETING_LIST_SUCCESS]: (state, action) => {
            const data = action.payload;
            return {
                ...state,
                data: data.results,
                totalCount: data.totalCount,
                pending: false,
            };
        },

        [IV_GET_MEETING_LIST_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },

        [IV_DELETE_MEETING_ITEM_SUCCESS]: (state, action) => {
            const data = action.payload;
            const newData = state.data.filter((c) => c.id !== data.id);
            return {
                ...state,
                data: newData,
                pending: false,
                error: false,
            };
        },

        [IV_DELETE_MEETING_ITEM_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
    },
    initialState,
);

export function getIVMeetingListAPI(stageId, apiEndpoint, projectId, queries) {
    return axios.get(`${apiEndpoint}/interactive-video/meetings`, {
        params: {
            ...queries,
        },
        headers: {
            stageId,
            projectId,
        },
    });
}

export const getIvMeetingList = (queries) => (dispatch, getState) => {
    const { stage, project } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;

    dispatch({ type: IV_MEETING_LIST_PENDING });

    return getIVMeetingListAPI(stageId, apiEndpoint, projectId, queries)
        .then((response) => {
            dispatch({
                type: IV_GET_MEETING_LIST_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: IV_GET_MEETING_LIST_FAILURE,
                payload: error,
            });
        });
};

function deleteIvMeetingAPI(stageId, apiEndpoint, projectId, id) {
    return axios.delete(`${apiEndpoint}/interactive-video/meetings/${id}`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

export const deleteIvMeeting = (id) => (dispatch, getState) => {
    const { stage, project } = getState();
    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;

    dispatch({ type: IV_MEETING_LIST_PENDING });
    return deleteIvMeetingAPI(stageId, apiEndpoint, projectId, id)
        .then((response) => {
            dispatch({
                type: IV_DELETE_MEETING_ITEM_SUCCESS,
                payload: {
                    id,
                },
            });
        })
        .catch((error) => {
            dispatch({
                type: IV_DELETE_MEETING_ITEM_FAILURE,
                payload: error,
            });
        });
};

