import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import Loadable from "@react-loadable/revised";
import { IconAdd, IconCancelCircle, ImageCategoryGroupOn } from "@mzc-cloudplex/icons";
import MetadataUseStatusTooltip from "../../components/tooltip/MetadataUseStatusTooltip";
import { CATEGORY_TYPES } from "@constants";
import { Box, Chip, ClickAwayListener, Grid, IconButton, Typography } from "@mzc-pdc/ui";
import { useTranslation } from "react-i18next";
import SelectCategoryGroupPopper from "@features/categories-input/select-category-group-popper";
import { getSettingsAPI } from "@modules/apis/setting";

//TODO multi select 통합 필요 -> upload job category
const CategoriesInput = ({
    project,
    disablePortal = false,
    placement,
    selectedCategoryGroup = {},
    onChange,
    selectedValues,
    tooltipDisabled,
    placeholder,
    type,
    categoryGroupLabel = false,
}) => {
    const { t } = useTranslation();
    const [values, setValues] = useState(selectedValues ?? []);
    const [popperOpen, setPopperOpen] = React.useState(false);
    const [editProject, setEditProject] = useState(project);
    const [editCategoryGroup, setEditCategoryGroup] = useState();
    const popperPosRef = React.useRef(null);
    const currentProject = useSelector(({ project }) => project);
    const stage = useSelector(({ stage }) => stage);
    const onDeleteSelectedCategory = (deleteItem) => {
        const categories = values.filter((v) => v.id != deleteItem.id) ?? [];

        onChange({
            categories: categories,
            categoryGroup: selectedCategoryGroup,
        });
    };

    const onDeleteSelectedCategoryAll = (deleteItems) => {
        onChange({ categories: deleteItems, categoryGroup: selectedCategoryGroup });
    };

    const openSelectCategoriesGroup = () => {
        setPopperOpen(true);
    };

    const handleClose = () => {
        setPopperOpen(false);
    };

    const getDefaultCategoryGroup = async () => {
        try {
            const settingParams = {
                resource: type,
                key: "categoryGroupId",
            };
            const response = await getSettingsAPI(stage.id, stage.endpoint, project.id, settingParams);
            const categoryGroupId = response.data[type]?.categoryGroupId;
            setEditCategoryGroup({ id: categoryGroupId?.id, value: categoryGroupId?.value });
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        setValues(selectedValues);
    }, [selectedValues]);

    useEffect(() => {
        if (selectedValues.length > 0) setValues(selectedValues);
    }, []);

    useEffect(() => {
        setEditProject(project ?? currentProject);
        getDefaultCategoryGroup();
    }, [project]);

    return (
        <ClickAwayListener mouseEvent={popperOpen ? "onClick" : false} onClickAway={handleClose}>
            <Box>
                {popperOpen && (
                    <SelectCategoryGroupPopper
                        type={type}
                        disablePortal={disablePortal}
                        initStateEvent={openSelectCategoriesGroup}
                        defaultCategoryGroup={editCategoryGroup}
                        project={editProject}
                        selectedCategories={selectedValues}
                        selectedCategoryGroup={selectedCategoryGroup}
                        onChangeCategories={(option) => {
                            onChange(option);
                        }}
                        onModalClose={(v) => {
                            setPopperOpen(false);
                        }}
                        onClose={() => setPopperOpen(false)}
                        open={popperOpen}
                        placement={placement ?? "top-end"}
                        anchorEl={popperPosRef.current}
                    />
                )}
                <>
                    {categoryGroupLabel &&
                        (selectedValues?.length > 0 && selectedCategoryGroup.id ? (
                            <Grid container alignItems={"center"} gap={0.75} mb={1}>
                                <ImageCategoryGroupOn width={16} height={16} />
                                <Typography variant={"body1_700"}>{selectedCategoryGroup?.label}</Typography>
                            </Grid>
                        ) : (
                            <Grid container alignItems={"center"} gap={0.75} mb={1}>
                                <ImageCategoryGroupOn width={16} height={16} />
                                <Typography variant={"body1_500"} color={"text.cancel"}>
                                    {t(`common::label::Select Category Group`)}
                                </Typography>
                            </Grid>
                        ))}
                    <Grid
                        container
                        border={1}
                        borderColor={"greySecondary.light"}
                        borderRadius={1}
                        py={1}
                        pl={2}
                        pr={1.5}
                        gap={2.5}
                        flexWrap={"nowrap"}
                        alignItems={"center"}
                        ref={popperPosRef}
                    >
                        <Grid item xs>
                            {!selectedValues || selectedValues.length <= 0 ? (
                                <Typography variant={"body1"} color={"greySecondary.400"}>
                                    {placeholder}
                                </Typography>
                            ) : (
                                <Grid container gap={0.5}>
                                    {selectedValues &&
                                        selectedValues.map((category) => (
                                            <Chip
                                                size={"small"}
                                                key={category.id}
                                                label={category.label}
                                                onDelete={() => onDeleteSelectedCategory(category)}
                                                sx={{
                                                    maxWidth: 200,
                                                }}
                                            />
                                        ))}
                                </Grid>
                            )}
                        </Grid>
                        <Grid item zeroMinWidth>
                            <Grid container gap={0.75} flexWrap={"nowrap"}>
                                <IconButton
                                    circled
                                    size={"small"}
                                    hidden={!selectedValues || selectedValues.length <= 0}
                                    onClick={(e) => {
                                        onDeleteSelectedCategoryAll([]);
                                    }}
                                >
                                    <IconCancelCircle size={16} htmlColor={"#939BB7"} />
                                </IconButton>

                                <IconButton
                                    circled
                                    size={"small"}
                                    onClick={(e) => {
                                        e.preventDefault();

                                        openSelectCategoriesGroup();
                                    }}
                                >
                                    <IconAdd size={16} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            </Box>
        </ClickAwayListener>
    );
};

export default CategoriesInput;
