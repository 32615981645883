import { handleActions} from "redux-actions";

const DEV_SET_TEST_STAGE_VERSION = "SET_TEST_STAGE_VERSION";

const initialState = {
    testStageVersion: null,
};

export default handleActions({
    [DEV_SET_TEST_STAGE_VERSION]: (state, action) => {
        const temp = {
            testStageVersion: action.version
        };
        return temp;
    }
}, initialState);

export const setTestStageVersion = (version) => (dispatch, getState) => {
    dispatch({
        type: DEV_SET_TEST_STAGE_VERSION,
        version
    });
};

export const reset = () => (dispatch, getState) => {
    const {
        stage
    } = getState();
    dispatch({
        type: DEV_SET_TEST_STAGE_VERSION,
        version: stage.version
    });
};