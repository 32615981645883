import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Badge, Divider, Grid, Avatar, List, ListItem, ListItemText, CircularProgress } from "@mzc-pdc/ui";
import convertToByte from "@cores/convertToByte";
import Typography from "@components_v2/typography/typography";
import theme from "@styles/theme";
import { IconAi, IconArchive, IconDrm, IconOpenInNew } from "@mzc-cloudplex/icons";
import TableCard from "@components_v2/card/table-card";
import CollectionThumbnail from "@features/thumbnail/collection-thumbnail";
import AssetThumbnail from "@features/thumbnail/asset-thumbnail";
import AssetTypeFlag from "@components_v2/icon-type/asset/asset-type-flag";
import { useInView } from "react-intersection-observer";
import StatusBadge from "@components_v2/status-badge/status-badge";
import { useTranslation } from "react-i18next";

import { getProjectRandomColor } from "@utils/get-random-color";
import { Link } from "react-router-dom";
import formatDuration from "@cores/formatDuration";
import ContentThumbnail from "@features/thumbnail/content-thumbnail";
import ContentTypeFlag from "@components_v2/icon-type/content/content-type-flag";
import { CircledIcon } from "@components_v2/icon";
import { alpha } from "@mui/material";
import { useCommonTable } from "@hooks/useCommonTable";
import Skeleton from "@components_v2/skeleton/skeleton";
import Tooltip from "@components_v2/Tooltip/tooltip";

const AutocompleteTableList = ({
    data,
    columns,
    type,
    subType,
    menuActions,
    onClickName,
    openNewWindow,
    listStyle,
    noDataContent,
    divider,
    gridStyle,
    loadingTooltip,
    onFetchData = () => {},
    onClickRow = () => {},
}) => {
    const { t } = useTranslation();
    const [targetRef, inView] = useInView();

    const { getTableColumns } = useCommonTable();

    const [selected, setSelected] = useState([]);

    const getNameThumbnail = useCallback(
        (type, option, column) => {
            let accessUrl = "";
            switch (type.toUpperCase()) {
                case "ASSET":
                    if (option?.previews?.[0]?.accessUrl) accessUrl = option?.previews?.[0]?.accessUrl;
                    else if (option?.thumbnails?.[0]?.accessUrl) accessUrl = option?.thumbnails?.[0]?.accessUrl;
                    else if (option?.accessUrls?.[0]) accessUrl = option?.accessUrls?.[0];
                    else if (option?.posters?.[0]?.accessUrl) accessUrl = option?.posters?.[0]?.accessUrl;
                    else if (option?.primaryPoster?.accessUrl) accessUrl = option?.primaryPoster?.accessUrl;
                    else if (option?.elements?.[0]?.accessUrl) accessUrl = option?.elements?.[0]?.accessUrl;
                    else accessUrl = option?.accessUrl;

                    return (
                        <AssetThumbnail
                            variant={"table"}
                            src={accessUrl}
                            mediaType={option.mediaType}
                            sx={column?.style?.thumbnail}
                            isPreferred={option.isPreferred}
                            archiveStatus={option.archiveStatus}
                        />
                    );
                case "CONTENT":
                    if (option?.contentType === "VIDEO") accessUrl = option?.primaryPoster?.accessUrl;
                    else if (option?.primaryImage?.accessUrl) accessUrl = option?.primaryImage?.accessUrl;
                    else accessUrl = option?.accessUrl;

                    return (
                        <ContentThumbnail
                            variant={"table"}
                            src={accessUrl}
                            minWidth={40}
                            width={40}
                            height={40}
                            contentType={option.contentType ?? subType}
                            isPreferred={option.isPreferred}
                        />
                    );
                case "COLLECTION":
                    return <CollectionThumbnail collection={option} size={"xsmall"} variant={"table"} />;
            }
        },
        [data],
    );

    const getNameIcon = useCallback(
        (type, option) => {
            switch (type.toUpperCase()) {
                case "ASSET":
                    return <AssetTypeFlag minWidth={22} width={22} height={16} type={option.mediaType} />;
                case "CONTENT":
                    return (
                        <ContentTypeFlag minWidth={22} width={22} height={16} type={option.contentType ?? subType} />
                    );
            }
        },
        [data],
    );

    const getSkeletonByColumn = (columnId, item) => {
        switch (columnId) {
            case "desiredStatus":
            case "status":
                return <Skeleton variant="rounded" animation={"wave"} width={18} height={18} />;
            case "name":
                return (
                    <Grid
                        container
                        direction={`row`}
                        justifyContent={`flex-start`}
                        alignItems={`center`}
                        flexWrap={"nowrap"}
                    >
                        <Grid item>
                            <Skeleton
                                variant="rounded"
                                animation={"wave"}
                                width={40}
                                height={40}
                                style={{ marginRight: "4px" }}
                            >
                                {item.loading && (
                                    <Tooltip
                                        title={t(
                                            `common::label::${loadingTooltip ?? "Loading"}`,
                                            `${loadingTooltip ?? "Loading"}`,
                                        )}
                                    >
                                        <CircularProgress color="blue" />
                                    </Tooltip>
                                )}
                            </Skeleton>
                        </Grid>
                        <Grid container>
                            <Grid item ml={0.5} mr={0.5}>
                                {getNameIcon(type, item)}
                            </Grid>
                            <Skeleton variant="rounded" animation={"wave"} width={300} height={21} />
                        </Grid>
                    </Grid>
                );
            default:
                return <Skeleton variant="rounded" animation={"wave"} width={80} height={22} />;
        }
    };

    const getTableValueByColumn = useCallback(
        (id, option, column) => {
            switch (id) {
                case "desiredStatus":
                case "status":
                    return (
                        <Grid container justifyContent={"center"}>
                            <StatusBadge status={option?.status} />
                        </Grid>
                    );
                case "name":
                    return (
                        <TableCard
                            thumbnail={getNameThumbnail(type, option, column)}
                            name={{
                                name: onClickName ? (
                                    <Link
                                        to={onClickName(option)}
                                        style={{ color: theme.palette.secondary[800] }}
                                        target={openNewWindow ? "_blank" : null}
                                        noWrap
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        {option.name}
                                    </Link>
                                ) : (
                                    <Typography variant={"body1_500"} component={"span"} noWrap>
                                        {option?.name}
                                    </Typography>
                                ),
                                startIcon: getNameIcon(type, option),
                                endIcon: (
                                    <Grid container flexDirection={"row"} justifyContent={"space-between"}>
                                        <Grid item>
                                            <Grid container flexDirection={"row"} gap={0.25}>
                                                {option.encryptions && (
                                                    <CircledIcon size={20} color={theme.palette.warning.main}>
                                                        <IconDrm size={12} htmlColor={theme.palette.common.white} />
                                                    </CircledIcon>
                                                )}
                                                {option.isAnalyzed && (
                                                    <CircledIcon size={20} color={"#88ba11"}>
                                                        <IconAi size={12} htmlColor={theme.palette.common.white} />
                                                    </CircledIcon>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ),
                            }}
                        />
                    );
                case "nameWithSubName":
                    return (
                        <TableCard
                            thumbnail={getNameThumbnail(type, option, column)}
                            name={{
                                name: (
                                    <Typography variant={"body1_500"} component={"span"}>
                                        {option?.name}
                                    </Typography>
                                ),
                                startIcon: getNameIcon(type, option),
                            }}
                            subName={{
                                name: (
                                    <Grid
                                        container
                                        flexDirection={"row"}
                                        sx={{
                                            "& span:not(:last-child)": {
                                                pr: 5,
                                                borderRight: `solid 1px ${theme.palette.secondary[100]}`,
                                            },
                                            "& span:not(:first-of-type)": {
                                                pl: 1.5,
                                            },
                                        }}
                                    >
                                        <Typography
                                            variant={"body3_400"}
                                            component={"span"}
                                            color={theme.palette.secondary[600]}
                                        >
                                            {`.${option?.extensions}`}
                                        </Typography>
                                        <Typography
                                            variant={"body3_400"}
                                            component={"span"}
                                            color={theme.palette.secondary[600]}
                                        >
                                            {option?.mediaType ?? "-"}
                                        </Typography>
                                        <Typography
                                            variant={"body3_400"}
                                            component={"span"}
                                            color={theme.palette.secondary[600]}
                                        >
                                            {option?.size ? convertToByte(option?.size) : "-"}
                                        </Typography>
                                    </Grid>
                                ),
                            }}
                        />
                    );
                case "projectName":
                    return (
                        <Grid container flexDirection={"row"} alignItems={"center"}>
                            <Avatar
                                sx={{
                                    width: 32,
                                    height: 32,
                                    fontSize: 16,
                                    fontWeight: `bold`,
                                    bgcolor: getProjectRandomColor(option?.id),
                                    mr: 1,
                                }}
                            >
                                {option.name.charAt(0)}
                            </Avatar>

                            {/* 1.0.81 Note 프로젝트 누르면 어디로 이동? */}
                            <Link
                                target="_blank"
                                //component={RouterLink}
                                style={{
                                    color: theme.palette.secondary[800],
                                    fontWeight: 500,
                                    display: `inline-flex`,
                                    webkitLineClamp: `2`,
                                    textOverflow: `ellipsis`,
                                    webkitBoxOrient: `vertical`,
                                    maxHeight: 45,
                                    overflow: `hidden`,
                                    textDecoration: "none",
                                    "&:hover": {
                                        "#external-link-icon": {
                                            display: "block",
                                        },
                                    },
                                }}
                                noWrap
                                to={onClickName && onClickName(option)}
                                onClick={(e) => e.stopPropagation()}
                            >
                                {option.name}
                                <Grid item id={"external-link-icon"} sx={{ display: "none" }}>
                                    <IconOpenInNew size={16} />
                                </Grid>
                            </Link>
                        </Grid>
                    );
                case "size":
                    return option?.size ? convertToByte(option?.size) : "-";

                case "count":
                    return (
                        <Grid item sx={{ textAlign: `right` }}>
                            <Badge
                                sx={{
                                    ".MuiBadge-badge": {
                                        position: `static`,
                                        minWidth: "30px",
                                        height: 18,
                                        transform: `none`,
                                        backgroundColor: option?.assetTotalCount
                                            ? theme.palette.success[600]
                                            : theme.palette.secondary[500],
                                        color: theme.palette.common.white,
                                        fontSize: theme.typography.body3_700,
                                    },
                                }}
                                badgeContent={
                                    option.assetTotalCount > 99 ? "99+" : option?.assetTotalCount?.toString() ?? "0"
                                }
                            />
                        </Grid>
                    );
                case "duration":
                    return <Grid item>{formatDuration(option?.duration)}</Grid>;
                case "extensions":
                    return <Grid item>.{option?.[id]}</Grid>;
                default:
                    return option?.[id];
            }
        },
        [data, type],
    );

    const getColumns = useCallback(
        (item) => {
            if (columns) return columns;

            if (type === "ASSET") {
                return getTableColumns(type, item.mediaType);
            } else if (type === "CONTENT") {
                return getTableColumns(type, item.contentType ?? subType);
            } else if (type === "COLLECTION") {
                return getTableColumns(type);
            }
            return [];
        },
        [type, subType],
    );

    const checkNoData = useMemo(() => {
        return (
            !data || data?.length === 0 || data?.reduce((acc, cur) => acc.concat(cur?.value ?? []), [])?.length === 0
        );
    }, [data]);

    useEffect(() => {
        if (inView) {
            onFetchData();
        }
    }, [inView, targetRef]);

    if (checkNoData && noDataContent) return noDataContent;
    else if (data?.length > 0)
        return (
            <Grid
                item
                sx={{
                    borderRadius: `4px`,
                    border: `1px solid ${theme.palette.secondary[100]}`,
                    overflowY: "auto",
                    ...gridStyle,
                }}
            >
                {data?.map((v) => {
                    if (v.value?.length === 0) return null;
                    return (
                        <>
                            {v.subtitle && (
                                <>
                                    <Grid item ml={2.5} mt={2.5} mb={1}>
                                        <Typography variant={"body1_500"} color={theme.palette.secondary[800]}>
                                            {v.subtitle}
                                        </Typography>
                                    </Grid>
                                    <Divider />
                                </>
                            )}
                            <List sx={{ padding: "0", overflowY: "auto", ...listStyle }}>
                                {v.value?.map((item, index) => (
                                    <React.Fragment key={`autocomplete-table-list-${item.id}`}>
                                        <ListItem
                                            sx={{
                                                padding: `${menuActions ? `4px 56px 4px 8px` : `4px 12px 4px 8px`}`,
                                                ...(onClickRow && {
                                                    "&:hover": {
                                                        backgroundColor: alpha(theme.palette.greySecondary[50], 0.24),
                                                    },
                                                    ...(selected?.id === item.id && {
                                                        backgroundColor: `${alpha(
                                                            theme.palette.greySecondary.A100,
                                                            0.36,
                                                        )} !important`,
                                                    }),
                                                }),
                                            }}
                                            data-id={item.id}
                                            secondaryAction={menuActions && menuActions(item)}
                                            onClick={() => {
                                                if (onClickRow) {
                                                    setSelected(item);
                                                    onClickRow(item);
                                                }
                                            }}
                                        >
                                            <ListItemText
                                                mt={1}
                                                mb={1}
                                                primary={
                                                    <Grid
                                                        container
                                                        alignItems={"center"}
                                                        direction="row"
                                                        flexWrap={"nowrap"}
                                                    >
                                                        {getColumns(item)?.map((column, index) => (
                                                            <React.Fragment
                                                                key={`autocomplete-table-list-column-${column.id}`}
                                                            >
                                                                {index > 0 && divider && (
                                                                    <Divider
                                                                        orientation="vertical"
                                                                        variant="middle"
                                                                        flexItem
                                                                        style={{ marginRight: "16px" }}
                                                                    />
                                                                )}
                                                                <Grid item style={{ ...column.style.custom }}>
                                                                    {item.pending
                                                                        ? getSkeletonByColumn(column.id, item)
                                                                        : getTableValueByColumn(
                                                                              column.id,
                                                                              item,
                                                                              column,
                                                                          )}
                                                                </Grid>
                                                            </React.Fragment>
                                                        ))}
                                                    </Grid>
                                                }
                                            />
                                        </ListItem>
                                        {data && <div ref={targetRef} id={"loadMoreDiv"} />}
                                        {!(!gridStyle?.height && index === v?.value?.length - 1) && <Divider />}
                                    </React.Fragment>
                                ))}
                            </List>
                        </>
                    );
                })}
            </Grid>
        );
    return null;
};

export default AutocompleteTableList;
