import React, { useState, useEffect, useCallback, forwardRef, useImperativeHandle } from "react";
import { useTranslation, Trans } from "react-i18next";

import { UPLOAD_DUPLICATE_MODAL_ACTION, OPERATING_TYPE } from "../../constants";

const DuplicateCheckModal = forwardRef((props, ref) => {
    const { t } = useTranslation();

    const [operatingType, setOperatingType] = useState(OPERATING_TYPE.UPLOAD);
    const [displayName, setDisplayName] = useState("");
    const [distFolderName, setDistFolderName] = useState("");
    const [sourceFolderName, setSourceFolderName] = useState("abce");
    const [totalCount, setTotalCount] = useState(0);
    const [promiseInfo, setPromiseInfo] = useState(undefined);
    const [isApplyToAll, setIsApplyToAll] = useState(false);
    const [isShow, setIsShow] = useState(false);

    useEffect(() => {
        // if(ref && ref.current) {
        //     const currentRef = ref.current;
        //     currentRef.show();
        //   }
    }, []);

    useImperativeHandle(ref, () => ({
        show,
    }));

    const show = async (object) => {
        setTotalCount(object.totalCount);
        setDisplayName(object.displayName);
        setOperatingType(object.operatingType);
        setDistFolderName(object.distFolderName);

        return new Promise((resolve, reject) => {
            //   promiseInfo = {
            //     resolve,
            //     reject
            //   };
            setPromiseInfo({
                resolve,
                reject,
            });
            //   this.setState({
            //     show: true
            //   });
            setIsShow(true);
        });
    };

    const reset = () => {
        setPromiseInfo(undefined);
        setIsApplyToAll(false);
        setIsShow(false);
    };

    const handleClickedOverwrite = useCallback(() => {
        promiseInfo &&
            promiseInfo.resolve({
                action: UPLOAD_DUPLICATE_MODAL_ACTION.OVERWRITE,
                isApplyToAll,
            });
        reset();
    }, [isShow, promiseInfo, isApplyToAll]);

    const handleClickedSkip = useCallback(() => {
        promiseInfo &&
            promiseInfo.resolve({
                action: UPLOAD_DUPLICATE_MODAL_ACTION.SKIP,
                isApplyToAll,
            });
        reset();
    }, [isShow, promiseInfo, isApplyToAll]);

    const handleClickedStop = useCallback(() => {
        promiseInfo &&
            promiseInfo.resolve({
                action: UPLOAD_DUPLICATE_MODAL_ACTION.STOP,
            });
        reset();
    }, [isShow, promiseInfo, isApplyToAll]);

    const renderNotification = () => {
        switch (operatingType) {
            case OPERATING_TYPE.UPLOAD:
                return (
                    <div>
                        <span className="text-smaller">
                            {t(`common::label::Uploading`)} <strong className="text-primary">{totalCount}</strong>
                            <Trans
                                i18nKey="common::msg::files to the <0>{{name}}</0>"
                                defaults="files to the <0>{{name}}</0>"
                                values={{ name: distFolderName }}
                                components={[<strong className="text-primary"></strong>]}
                            />
                        </span>
                    </div>
                );

            case OPERATING_TYPE.COPY:
                return (
                    <div>
                        <span className="text-smaller">
                            <Trans
                                i18nKey="common::msg::Copying <0>{{totalCount}}</0> files from the <0>{{sourceFolderName}}</0> to the <0>{{distFolderName}}</0>"
                                default="Copying <0>{{totalCount}}</0> files from the <0>{{sourceFolderName}}</0> to the <0>{{distFolderName}}</0>"
                                values={{
                                    totalCount: totalCount,
                                    sourceFolderName: sourceFolderName,
                                    distFolderName: distFolderName,
                                }}
                                components={[<strong className="text-primary"></strong>]}
                            />
                        </span>
                    </div>
                );

            case OPERATING_TYPE.MOVE:
                return (
                    <div>
                        <span className="text-smaller">
                            <Trans
                                i18nKey="common::msg::Moving <0>{{totalCount}}</0> files from the <0>{{sourceFolderName}}</0> to the <0>{{distFolderName}}</0>"
                                values={{
                                    totalCount: totalCount,
                                    sourceFolderName: sourceFolderName,
                                    distFolderName: distFolderName,
                                }}
                                components={[<strong className="text-primary"></strong>]}
                            />
                        </span>
                    </div>
                );

            case OPERATING_TYPE.RENAME:
                return (
                    <div>
                        <span className="text-smaller">
                            {t(`common::label::Uploading`)} <strong className="text-primary">{totalCount}</strong>
                            <Trans
                                i18nKey="common::msg::files to the <0>{{name}}</0>"
                                values={{ name: distFolderName }}
                                components={[<strong className="text-primary"></strong>]}
                            />
                        </span>
                    </div>
                );
        }
    };

    return (
        <React.Fragment>
            {isShow && (
                <div className="modal modal-message modal-active">
                    <div className="modal-dialog">
                        <div className="modal-header">
                            <p className="modal-title">{t(`common::label::Overwrite or skip file`)}</p>
                        </div>
                        <div className="modal-body">
                            <div className="message-description">
                                <i className="sprite sprite-notification"></i>
                                {renderNotification()}
                            </div>
                            <hr className="transparent" />
                            <div className="message-contents">
                                <strong>
                                    <Trans
                                        i18nKey="common::msg::An item with the name '<0>{{name}}</0>' is already in this location."
                                        values={{ name: displayName }}
                                        components={[<strong className="text-primary"></strong>]}
                                    />
                                    <br />
                                    {t(`common::msg::Do you want to overwrite it with the target file?`)}
                                </strong>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div>
                                <label htmlFor="checkbox" className="form-checkbox">
                                    <input
                                        id="checkbox"
                                        type="checkbox"
                                        className={`form-check-input`}
                                        checked={isApplyToAll}
                                        onChange={(e) => {
                                            setIsApplyToAll(!isApplyToAll);
                                        }}
                                    />
                                    <i></i>
                                    <span>{t(`common::label::Apply to all`)}</span>
                                </label>
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    onClick={() => {
                                        handleClickedOverwrite();
                                        // onClickClose()
                                        // promiseInfo.resolve("--- test overwrite");
                                    }}
                                >
                                    <span>{t(`common::label::${"Overwrite"}`)}</span>
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    onClick={() => {
                                        handleClickedSkip();
                                    }}
                                >
                                    <span>{t(`common::label::${"Skip"}`)}</span>
                                </button>
                                <hr />
                                <button
                                    type="button"
                                    className="btn btn-outline-default"
                                    onClick={() => {
                                        handleClickedStop();
                                    }}
                                >
                                    <span>{t(`common::label::${"Stop"}`)}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
});

export default DuplicateCheckModal;
