import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const PanelHeader = (props) => {
    const { className, ...otherProps } = props;
    return <div {...otherProps} className={clsx("panel-header", className)} />;
};
PanelHeader.propTypes = {
    className: PropTypes.string,
};

export default PanelHeader;
