import React, { useCallback, useEffect, useMemo } from "react";
import { Box, Chip, Grid, IconButton, Stack, TextField, Typography } from "@mzc-pdc/ui";
import { useTranslation } from "react-i18next";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import SelectFolderPath from "./select-folder-popper";
import { IconAdd, IconCancelCircle, ImageFolderSmallOn } from "@mzc-cloudplex/icons";
import { getDrivesListAPI } from "@modules/apis/virtureFolder";
import { useDispatch, useSelector } from "react-redux";
import theme from "@styles/theme";
import { getDriveData } from "@modules/folderTreeView";

const FolderInput = ({ domain, useDefaultFolder, projectId, disablePortal, placement, value, onSubmit, ...props }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const stage = useSelector(({ stage }) => stage);
    const project = useSelector(({ project }) => project);
    const [popperOpen, setPopperOpen] = React.useState(false);

    const popperPosRef = React.useRef(null);
    const handleClose = () => {
        setPopperOpen(false);
    };
    const onClickSelectFolderPopperOpen = (e) => {
        setPopperOpen(true);
    };
    const onChangeFolderPath = (path) => {
        setPopperOpen(false);
        onSubmit(path);
    };

    const getRootFolderId = useCallback(async () => {
        const driveList = await dispatch(getDriveData({ headerParams: { projectId }, domain: "ASSET" }));
        return { defaultFolder: driveList[0] };
    }, [stage, projectId]);

    useEffect(async () => {
        // drive folder를 default로 설정
        if (!!value || !useDefaultFolder) return;
        const rootFolder = await getRootFolderId();
        onSubmit(rootFolder);
    }, []);

    useEffect(async () => {
        if (useDefaultFolder) {
            const rootFolder = await getRootFolderId();
            onSubmit(rootFolder);
        }
    }, [projectId]);

    return (
        <ClickAwayListener mouseEvent={popperOpen ? "onClick" : false} onClickAway={handleClose}>
            <Box>
                <Grid
                    container
                    border={1}
                    borderColor={"greySecondary.light"}
                    borderRadius={1}
                    py={1}
                    pl={2}
                    pr={1.5}
                    gap={2.5}
                    flexWrap={"nowrap"}
                    alignItems={"center"}
                    ref={popperPosRef}
                    ref={popperPosRef}
                >
                    <Grid item xs>
                        {!value?.folderName ? (
                            <Typography variant={"body1"} color={"greySecondary.400"}>
                                {t("common::label::Save to top path")}
                            </Typography>
                        ) : (
                            <Grid container flexDirection={"row"} gap={0.5} alignItems={"center"}>
                                <Grid item height={16}>
                                    <ImageFolderSmallOn size={16} />
                                </Grid>
                                <Typography>{value?.folderName}</Typography>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item zeroMinWidth>
                        <Grid container gap={0.75} flexWrap={"nowrap"}>
                            <IconButton
                                circled
                                size={"small"}
                                onClick={(e) => {
                                    e.preventDefault();

                                    onClickSelectFolderPopperOpen();
                                }}
                            >
                                <IconAdd size={16} />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>

                {popperOpen && (
                    <SelectFolderPath
                        domain={domain}
                        projectId={projectId}
                        open={popperOpen}
                        submitText={`Select`}
                        title={`Select Folder Path`}
                        onSubmit={onChangeFolderPath}
                        onClose={() => setPopperOpen(false)}
                        placement={placement ?? "top-end"}
                        subFoldersOption={false}
                        disablePortal={disablePortal}
                        anchorEl={popperPosRef.current}
                    />
                )}
            </Box>
        </ClickAwayListener>
    );
};

export default FolderInput;
