import { IconUp } from "@mzc-cloudplex/icons";
import React, { useState } from "react";
import { alpha } from "@mui/material";
import theme from "@styles/theme";
import { Box, InputAdornment, Stack, TextField } from "@mzc-pdc/ui";
import { useTranslation } from "react-i18next";
import EditorComment from "./editor-comment";
import { CircledIcon } from "@components_v2/icon";
import { useSelector } from "react-redux";
import useCommentSelector from "@hooks/useCommentSelector";

const EditorCommentCreateBox = ({
    domain,
    onClickClose,
    htmlComment,
    attachments,
    onChangeComment,
    onCreateComment,
    onChangeAttachments,
    isHideExpanderButton,
}) => {
    const { t } = useTranslation();

    const [showEditor, setShowEditor] = useState(false);
    const { selectedComment } = useCommentSelector({ domain });
    const user = useSelector(({ stage }) => stage.user);
    const onHandleShowEditor = () => {
        setShowEditor(true);
    };

    const onHandleCreateComment = () => {
        onCreateComment(selectedComment ? selectedComment.id : null);
    };
    const onHandleClickCancel = () => {
        if (onClickClose) {
            onClickClose();
        }
        onChangeComment();
        setShowEditor(false);
    };

    return (
        <Box
            sx={{
                borderTop: `1px solid ${theme.palette.greySecondary.A100}`,
                backgroundColor: alpha(theme.palette.greySecondary["A100"], 0.6),
                p: "12px 20px 12px 20px",
                position: "relative",
            }}
        >
            <Stack direction={"row"}>
                {/*<RandomColoredAvatar*/}
                {/*    size={32}*/}
                {/*    id={user.id}*/}
                {/*    name={user.name}*/}
                {/*    src={"/static/images/avatar/1.jpg"}*/}
                {/*    sx={{}}*/}
                {/*/>*/}
                <div
                    className={"text-editor"}
                    style={{ backgroundColor: theme.palette.common.white, width: "100%", borderRadius: "4px" }}
                >
                    {showEditor ? (
                        <EditorComment
                            domain={domain}
                            id={"reply-create-editor"}
                            chat={htmlComment}
                            attachments={attachments}
                            onChange={onChangeComment}
                            onChangeAttachments={onChangeAttachments}
                            onSubmit={onHandleCreateComment}
                            onClose={onHandleClickCancel}
                        />
                    ) : (
                        <TextField
                            fullWidth
                            placeholder={t("common::msg::Please enter your message.")}
                            onFocus={onHandleShowEditor}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <CircledIcon size={24} color={alpha(theme.palette.greySecondary.main, 0.16)}>
                                            <IconUp size={16} />
                                        </CircledIcon>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                </div>
            </Stack>
        </Box>
    );
};
export default EditorCommentCreateBox;
