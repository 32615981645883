import { Box, Grid, Stack } from "@mzc-pdc/ui";
import ObjectIcon from "@routes/sources/components/ObjectIcon";
import { GridNameEllipsis } from "@components_v2/grid/grid.styled";
import Typography from "@components_v2/typography/typography";
import { Trans } from "react-i18next";
import { IconFolder, IconHierarchyFolded, IconPath, IconPathInput, IconPathOutput } from "@mzc-cloudplex/icons";
import theme from "@styles/theme";
import convertToByte from "@cores/convertToByte";
import AssetTypeFlag from "@components_v2/icon-type/asset/asset-type-flag";
import React, { useCallback } from "react";
import { JOB_ACTIONS } from "@constants";

const JobTaskStorageJobs = ({ job, metadata, tasks }) => {
    const getTaskInformation = useCallback(
        (file) => {
            switch (job.action) {
                case JOB_ACTIONS.MOVE:
                case JOB_ACTIONS.COPY:
                case JOB_ACTIONS.DELETE:
                    return (
                        <>
                            <Grid container alignItems={"center"} gap={theme.spacing(1)}>
                                <ObjectIcon path={file.fromFileName} isShouldZip={file.isFolder} />
                                <GridNameEllipsis item maxWidth={"300px"}>
                                    <Typography variant="body3_400">{file.fromFileName}</Typography>
                                </GridNameEllipsis>
                            </Grid>
                            <Grid container flexDirection={"column"} mt={0.75} px={3.5} flexWrap={"nowrap"}>
                                <Grid>
                                    {job.action === JOB_ACTIONS.DELETE ? (
                                        <IconPath size={16} htmlColor={theme.palette.greySecondary[500]} />
                                    ) : (
                                        <IconPathInput size={16} htmlColor={theme.palette.greySecondary[500]} />
                                    )}
                                    <Typography
                                        variant="body3_400"
                                        color={"text.secondary"}
                                        sx={{ wordBreak: "break-all", ml: theme.spacing(1) }}
                                    >
                                        {file.fromKey
                                            ? `${metadata.fromBucket}${file.fromKey.slice(
                                                  0,
                                                  file.fromKey.lastIndexOf("/"),
                                              )}`
                                            : "-"}
                                    </Typography>
                                </Grid>
                                {job.action !== JOB_ACTIONS.DELETE && (
                                    <Grid>
                                        <IconPathOutput size={16} htmlColor={theme.palette.greySecondary[500]} />
                                        <Typography
                                            variant="body3_400"
                                            color={"text.secondary"}
                                            sx={{ wordBreak: "break-all", ml: theme.spacing(1) }}
                                        >
                                            {metadata.contentTo ?? "-"}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </>
                    );
                case JOB_ACTIONS.RENAME:
                    return (
                        <>
                            <Grid container alignItems={"center"} gap={theme.spacing(1)}>
                                <ObjectIcon path={file.fromFileName} isShouldZip={file.isFolder} />
                                <GridNameEllipsis item maxWidth={"300px"}>
                                    <Typography variant="body3_400">{file.fromFileName}</Typography>
                                </GridNameEllipsis>
                            </Grid>
                            <Grid container flexDirection={"column"} mt={0.75} px={3.5} flexWrap={"nowrap"}>
                                <Grid>
                                    <IconPath size={16} htmlColor={theme.palette.greySecondary[500]} />
                                    <Typography
                                        variant="body3_400"
                                        color={"text.secondary"}
                                        sx={{ wordBreak: "break-all", ml: theme.spacing(1) }}
                                    >
                                        {file.fromKey
                                            ? `${metadata.fromBucket}${file.fromKey.slice(
                                                  0,
                                                  file.fromKey.lastIndexOf("/"),
                                              )}`
                                            : "-"}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                mt={theme.spacing(2)}
                                sx={{ display: "flex", alignItems: "center" }}
                                gap={theme.spacing(1)}
                            >
                                <IconHierarchyFolded size={8} />
                                <ObjectIcon path={file.fromFileName} isShouldZip={tasks.length > 1} />
                                <Typography
                                    variant="body2_500"
                                    color={theme.palette.greySecondary.main}
                                    sx={{ wordBreak: "break-all" }}
                                >
                                    {metadata?.contentTo
                                        ? metadata.contentTo.slice(metadata.contentTo.lastIndexOf("/") + 1)
                                        : "-"}
                                </Typography>
                            </Grid>
                            {tasks.length > 1 && (
                                <Grid
                                    container
                                    flexDirection={"column"}
                                    mt={0.75}
                                    pl={3.5}
                                    gap={theme.spacing(1.5)}
                                    flexWrap={"nowrap"}
                                >
                                    {tasks
                                        .filter(
                                            //Note: tasks 안에 이름 변경하는 폴더의 경우 제외하고 UI에 표시
                                            (f) =>
                                                f.metadata.fromKey !==
                                                `${metadata.items[0].fromKey.slice(
                                                    metadata.items[0].fromKey.lastIndexOf("/") + 1,
                                                )}/`,
                                        )
                                        .map((f, i) => (
                                            <Grid
                                                item
                                                sx={{ display: "flex", alignItems: "center" }}
                                                gap={theme.spacing(1)}
                                            >
                                                <ObjectIcon
                                                    path={f.metadata?.toKey}
                                                    isShouldZip={f.metadata?.isFolder}
                                                />
                                                <GridNameEllipsis maxWidth={"200px"}>
                                                    <Typography
                                                        variant="body3_400"
                                                        color={theme.palette.greySecondary.main}
                                                        sx={{ wordBreak: "break-all" }}
                                                    >
                                                        {f.displayName}
                                                    </Typography>
                                                </GridNameEllipsis>
                                                {f.metadata?.isFolder
                                                    ? ""
                                                    : `- ${convertToByte(
                                                          (f.metadata && f.metadata.fromFileSize) || 0,
                                                      )}`}
                                            </Grid>
                                        ))}
                                </Grid>
                            )}
                        </>
                    );
                default:
                    break;
            }
        },
        [job, tasks],
    );

    return (
        <>
            {job.action === "RENAME" && !metadata.items
                ? // ? getTaskInformation(metadata)
                  null
                : metadata.items?.map((file) => (
                      <Stack py={1}>
                          <Box>{getTaskInformation(file)}</Box>
                      </Stack>
                  ))}
        </>
    );
};

export default JobTaskStorageJobs;
