import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    MenuItem,
    Select,
    Stack,
    TextField,
} from "@mzc-pdc/ui";
import Typography from "@components_v2/typography/typography";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import theme from "@styles/theme";
import fp from "lodash/fp";
import moment from "moment/moment";
import { ASSET_SHARE_ACTIONS, STORAGE_SHARE_ACTIONS } from "@constants";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import compareVersions, { VERSION_CM_11376_SHARES_V2 } from "@cores/version";
import { createShareResource } from "@modules/sharedBox";
import { setNotification } from "@modules/notification";
import convertUtcToLocal from "@cores/convertUtcToLocal";
import AddShareApprovedContainer from "@features/dialog/share/components/add-share-approved-container";
import ShareResourceInfo from "@features/dialog/share/components/share-resource-info";

//목록/상세에서 내부공유시 asset/storage의 id를 이용해서 sharedResourceId를 알 수 있는 방법
const DialogShare = ({ domain, selectedItem, onClickClose }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const user = useSelector(({ stage }) => stage.user);
    const stage = useSelector(({ stage }) => stage);

    const [actionPending, setActionPending] = useState(false);
    const [selectedUsersOrTeams, setSelectedUsersOrTeams] = useState([]);
    const [shareExpireDays, setShareExpireDays] = useState(5);
    const [shareMaximumDownloads, setShareMaximumDownloads] = useState(1);
    const [message, setMessage] = useState("");

    const [selectingUsersOrTeams, setSelectingUsersOrTeams] = useState([]);

    const actionOptions = useMemo(() => (domain === "STORAGE" ? STORAGE_SHARE_ACTIONS : ASSET_SHARE_ACTIONS), [domain]);

    const onHandleClickSubmit = useCallback(async () => {
        try {
            setActionPending(true);
            const data = {
                domain,
                metadata: {
                    projectId: selectedItem.project.id,
                    resourceId: selectedItem.id,
                },
                sharedResourceGrantees: selectedUsersOrTeams.map((item) => ({
                    grantee: { type: "USER", externalId: item.id },
                    granted: {
                        by: {
                            id: user.id,
                            name: user.name,
                            username: user.username,
                        },
                    },
                    actions: actionOptions
                        .slice(
                            actionOptions.findIndex((p) => p.value === item.actions),
                            actionOptions.length,
                        )
                        .map((p) => p.value),
                })),
                message,
                limits: {
                    expiresAt:
                        compareVersions(stage.version, VERSION_CM_11376_SHARES_V2) >= 0 && shareExpireDays > 0
                            ? convertUtcToLocal(moment().add(shareExpireDays, "days"), "YYYY-MM-DD 23:59:59")
                            : undefined,
                    maxDownloads:
                        compareVersions(stage.version, VERSION_CM_11376_SHARES_V2) >= 0 && shareMaximumDownloads > 0
                            ? shareMaximumDownloads
                            : undefined,
                },
            };

            await dispatch(createShareResource(data));

            dispatch(
                setNotification({
                    type: "success",
                    contents: t(
                        `common::msg::${
                            domain === "storage" ? `File {{item}} has been shared` : `Asset {{item}} has been shared`
                        }`,
                        `${domain === "storage" ? `File {{item}} has been shared` : `Asset {{item}} has been shared`}`,
                        {
                            item: `'${selectedItem.name}'`,
                        },
                    ),
                }),
            );
            setActionPending(false);
            onClickClose();
        } catch (error) {
            console.error(error);
            dispatch(
                setNotification({
                    type: "error",
                    contents: t(
                        `common::msg::There was an error sharing ${domain === "storage" ? "file" : "asset"} {{item}}`,
                        `There was an error sharing ${domain === "storage" ? "file" : "asset"} {{item}}`,
                        {
                            item: `'${selectedItem.name}'`,
                        },
                    ),
                }),
            );
            setActionPending(false);
        }
    }, [
        selectedItem,
        selectedUsersOrTeams,
        domain,
        message,
        shareExpireDays,
        shareMaximumDownloads,
        actionOptions,
        user,
    ]);

    return (
        <Dialog open variant={"basic"} onClose={onClickClose}>
            <DialogTitle>
                <Grid container>
                    <Typography variant={"h3"}>{t(`common::label::Share`, `Share`)}</Typography>
                </Grid>
            </DialogTitle>
            <DialogContent sx={{ width: "600px" }}>
                <Grid container flexDirection={"column"} gap={theme.spacing(3)}>
                    <ShareResourceInfo domain={domain} resource={selectedItem} />
                    <AddShareApprovedContainer
                        domain={domain}
                        selected={selectedUsersOrTeams}
                        selecting={selectingUsersOrTeams}
                        expireDays={shareExpireDays}
                        maximumDownloads={shareMaximumDownloads}
                        message={message}
                        onChangeSelected={setSelectedUsersOrTeams}
                        onChangeSelecting={setSelectingUsersOrTeams}
                        onChangeExpireDays={setShareExpireDays}
                        onChangeMaximumDownloads={setShareMaximumDownloads}
                        onChangeMessage={setMessage}
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={onClickClose} variant="text">
                    {t(`common::label::Cancel`, "Cancel")}
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={onHandleClickSubmit}
                    disabled={selectedUsersOrTeams.length === 0 || actionPending}
                >
                    {t(`common::label::Share`, "Share")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogShare;
