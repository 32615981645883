import contentTypeConverter from "./contentTypeConverter";

import { 
    UPLOAD_FILE_LOCATION_TYPE,
} from '../constants';

export default {
    convert: function (files) {
        return convertToJobInputs(files);
    },
};

function getMimeType(file) {
    let fileMimeType = file.mimeType;

    if (
        fileMimeType &&
        (fileMimeType.toLowerCase().indexOf("video") >= 0 ||
            fileMimeType.toLowerCase().indexOf("audio") >= 0)
    )
        return file.mimeType;

    return contentTypeConverter.getMimeType(file.url || file.name);
}

function convertToJobInputs(files) {
    return files.map(file => {
        console.log(`file: ${JSON.stringify(file)}`);
        const mimeType = getMimeType(file);
        let mediaType = mimeType
            .toUpperCase()
            .substring(0, mimeType.indexOf('/'));
        // mime type으로 video, audio를 알 수 없는 경우 우선 video로 본다
        // TODO: 명시적으로 사용하도록 수정 - video지만 audio만 사용 가능?
        if(mediaType.indexOf("VIDEO") < 0 && mediaType.indexOf("AUDIO") < 0)
            mediaType = "VIDEO";

        let input = {
            ingestType:
                file.locationType === UPLOAD_FILE_LOCATION_TYPE.LOCAL
                    ? 'DIRECT'
                    : 'REMOTE',
            locationType: file.locationType,
            fileName: file.displayName,
            size: file.size,
            hash: file.hash,
            mimeType,
            mediaType
        };
        if (file.url) input.urls = [file.url];

        if (mediaType === "AUDIO") {
            input = { audios: [input] };
        } else if (mediaType === "VIDEO") {
            input = { video: input };

            if (file.audios && file.audios.length > 0) {
                input.audios = file.audios.map(audio => {
                    const audioInput = {
                        ingestType:
                            audio.locationType ===
                            UPLOAD_FILE_LOCATION_TYPE.LOCAL
                                ? 'DIRECT'
                                : 'REMOTE',
                        locationType: audio.locationType,
                        size: audio.size,
                        hash: audio.hash,
                        mimeType: audio.mimeType,
                        mediaType: "AUDIO",
                        languageCode: audio.languageCode
                    };
                    if (audio.url) audioInput.urls = [audio.url];
                    return audioInput;
                })
            }
        }
        return input;
    });
}