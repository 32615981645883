import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mzc-pdc/ui";
import theme from "@styles/theme";
import { useCallback, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { setNotification } from "@modules/notification";
import { useDispatch } from "react-redux";
import { IconWarningTriangle } from "@mzc-cloudplex/icons";
import { MAX_ATTACHMENT_COUNT, MAX_ATTACHMENT_SIZE } from "@routes/admins/settings/constants";
import convertToByte from "@cores/convertToByte";

const DialogWarningAttachments = ({
    attachmentsCount,
    addingAttachmentsCount,
    isAddingAttachmentsExceedSize,
    onClickClose,
}) => {
    const { t } = useTranslation();

    const getWarningMessage = useCallback(() => {
        let messages = [];
        if (attachmentsCount + addingAttachmentsCount > MAX_ATTACHMENT_COUNT) {
            messages.push(
                <>
                    <Grid item>
                        <Trans
                            i18nKey="common::msg::Up to <strong>{{number}}</strong> attachments are allowed."
                            defaults="Up to <strong>{{number}}</strong> attachments are allowed."
                            values={{ number: MAX_ATTACHMENT_COUNT }}
                            components={{
                                strong: (
                                    <Typography variant={"body1_700"} color={theme.palette.primary.main}></Typography>
                                ),
                            }}
                        />
                    </Grid>
                </>,
            );

            if (attachmentsCount === 0) {
                messages.push(
                    <>
                        <Grid item>
                            <Trans
                                key="message2"
                                i18nKey="common::msg::Please select up to <strong>{{number}}</strong> files and try again."
                                defaults="Please select up to <strong>{{number}}</strong> files and try again."
                                values={{ number: MAX_ATTACHMENT_COUNT }}
                                components={{
                                    strong: (
                                        <Typography
                                            variant={"body1_700"}
                                            color={theme.palette.primary.main}
                                        ></Typography>
                                    ),
                                }}
                            />
                        </Grid>
                    </>,
                );
            } else if (attachmentsCount === MAX_ATTACHMENT_COUNT) {
                messages.push(
                    <>
                        <Grid item>
                            <Trans
                                key="message3"
                                i18nKey="common::msg::You are currently planning to upload <strong>{{added}}</strong> files. If you would like to attach another file, please remove the file and try again."
                                defaults="You are currently planning to upload <strong>{{added}}</strong> files. If you would like to attach another file, please remove the file and try again."
                                values={{ added: attachmentsCount }}
                                components={{
                                    strong: (
                                        <Typography
                                            variant={"body1_700"}
                                            color={theme.palette.primary.main}
                                        ></Typography>
                                    ),
                                }}
                            />
                        </Grid>
                    </>,
                );
            } else {
                messages.push(
                    <>
                        <Grid item>
                            <Trans
                                key="message4"
                                i18nKey="common::msg::You are currently planning to upload <strong>{{added}}</strong> files. Please select and attach up to <strong>{{remaining}}</strong> additional files."
                                defaults="You are currently planning to upload <strong>{{added}}</strong> files. Please select and attach up to <strong>{{remaining}}</strong> additional files."
                                values={{
                                    added: attachmentsCount,
                                    remaining: MAX_ATTACHMENT_COUNT - attachmentsCount,
                                }}
                                components={{
                                    strong: (
                                        <Typography
                                            variant={"body1_700"}
                                            color={theme.palette.primary.main}
                                        ></Typography>
                                    ),
                                }}
                            />
                        </Grid>
                    </>,
                );
            }
        } else if (isAddingAttachmentsExceedSize) {
            messages.push(
                <>
                    <Trans
                        i18nKey="common::msg::The maximum capacity per attachment is up to <strong>{{maxSize}}</strong>."
                        defaults="The maximum capacity per attachment is up to <strong>{{maxSize}}</strong>."
                        values={{
                            maxSize: convertToByte(MAX_ATTACHMENT_SIZE),
                        }}
                        components={{
                            strong: <Typography variant={"body1_700"} color={theme.palette.primary.main}></Typography>,
                        }}
                    />
                    <br />
                    <Trans
                        i18nKey="common::msg::The current file has exceeded its capacity. Please check the file to attach and try again."
                        defaults="The current file has exceeded its capacity. Please check the file to attach and try again."
                    />
                </>,
            );
        }
        return (
            <Grid item sx={{ display: "flex" }} flexDirection={"column"}>
                {messages}
            </Grid>
        );
    }, [attachmentsCount, addingAttachmentsCount, isAddingAttachmentsExceedSize]);

    return (
        <Dialog variant={"basic"} open={true}>
            <DialogTitle>{t(`common::label::Files cannot be attached.`, `Files cannot be attached.`)}</DialogTitle>
            <DialogContent
                children={
                    <Grid container>
                        <Grid container flexDirection={"row"} mb={1}>
                            <IconWarningTriangle size={20} color={"warning"} />
                            {getWarningMessage()}
                            <br />
                        </Grid>
                    </Grid>
                }
            />
            <DialogActions>
                <Grid container justifyContent={"flex-end"}>
                    <Button
                        variant={`contained`}
                        onClick={(e) => {
                            e.stopPropagation();
                            onClickClose();
                        }}
                        color={`primary`}
                    >
                        {t("common::label::Confirm", "Confirm")}
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default DialogWarningAttachments;
