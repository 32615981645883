import { useState, useMemo, useCallback, useEffect } from "react";
import { Select, Button, Dialog } from "@mzc-pdc/ui";
import { useSelector, useDispatch } from "react-redux";
import {
    Divider,
    Grid,
    List,
    Typography,
    ListItem,
    MenuItem,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import { useTranslation, Trans } from "react-i18next";
import moment from "moment";
import theme from "@styles/theme";
import InfoAlert from "@components_v2/alert/info-alert";
import { PROJECT_SETTINGS_ARCHIVE_OPTIONS, PROJECT_SETTINGS_ARCHIVE_RESTORE_OPTIONS } from "@constants";
import { restoreAssetAPI } from "@modules/apis/assets";
import { setNotification } from "@modules/notification";
import * as bulkJobs from "@modules/bulkJobs";

export const DialogArchiveRestore = ({ dataType, folders, onClose, onSubmitCallback, isMulti }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const restoreSettings = useSelector(({ settings }) => settings?.stage?.Archive);
    const stage = useSelector(({ stage }) => stage);
    const project = useSelector(({ project }) => project);
    const folderTreeView = useSelector(({ folderTreeView }) => folderTreeView);

    const [actionPending, setActionPending] = useState(false);
    const [selectedRestoreOption, setSelectedRestoreOption] = useState({
        DEEP_ARCHIVE: null,
        GLACIER: null,
    });

    const expiredDays = useMemo(() => {
        const expire = folders[0]?.storageClass === "DEEP_ARCHIVE" ? 180 : 90;
        let duration = parseInt(
            Math.ceil(moment.duration(moment.utc(folders[0]?.archiveAt).add(expire, "d").diff(moment())).asDays()),
        );
        if (duration < 0) duration = 0;

        return duration;
    }, [folders]);

    const restoreOptions = useCallback(
        (option) => {
            // retore option 예외처리
            if (option === "DEEP_ARCHIVE")
                return PROJECT_SETTINGS_ARCHIVE_RESTORE_OPTIONS.filter((option) => option.value !== "EXPEDITED");
            else return PROJECT_SETTINGS_ARCHIVE_RESTORE_OPTIONS;
        },
        [folders],
    );

    const onHandleChangeRestoreOption = useCallback(
        (e, option) => {
            setSelectedRestoreOption({ ...selectedRestoreOption, [option]: e.target.value });
        },
        [selectedRestoreOption],
    );

    const getRestoreOptions = useMemo(() => {
        let restoreClass = [];
        if (isMulti) {
            if (folders.find(({ storageClass }) => storageClass === "DEEP_ARCHIVE"))
                restoreClass.push({ ...restoreSettings?.deepArchiveRestoreClass, label: "DEEP_ARCHIVE" });
            if (folders.find(({ storageClass }) => storageClass === "GLACIER"))
                restoreClass.push({ ...restoreSettings?.glacierRestoreClass, label: "GLACIER" });
        } else {
            restoreClass.push(
                folders?.[0]?.storageClass === "DEEP_ARCHIVE"
                    ? { ...restoreSettings?.deepArchiveRestoreClass, label: "DEEP_ARCHIVE" }
                    : { ...restoreSettings?.glacierRestoreClass, label: "GLACIER" },
            );
        }
        return restoreClass;
    }, [restoreSettings, folders]);

    const onHandleSubmit = useCallback(
        async (options, items, itemType) => {
            try {
                if (!items) throw new Error();

                if (actionPending) return;
                setActionPending(true);
                let restoreClass = {};
                if (options.DEEP_ARCHIVE) restoreClass.deepArchive = options.DEEP_ARCHIVE;
                if (options.GLACIER) restoreClass.glacier = options.GLACIER;

                let data = {
                    restoreClass,
                };

                if (dataType === "folder")
                    data.targets = {
                        virtualFolder: {
                            id: items?.[0]?.id,
                            recursive: false,
                        },
                    };
                else data.targets = { ids: items.map(({ asset }) => asset.id) };

                const response = await restoreAssetAPI({ apiEndpoint: stage.endpoint, projectId: project.id, data });

                dispatch(
                    setNotification({
                        type: "success",
                        contents: "The selected item is restored",
                    }),
                );

                if (response) {
                    dispatch(bulkJobs.listJobs());
                    onSubmitCallback();
                }
            } catch (error) {
                console.log(error);
                dispatch(
                    setNotification({
                        type: "error",
                        contents: "Error occurs!",
                    }),
                );
            } finally {
                setActionPending(false);
                onClose(false);
            }
        },
        [stage, project, actionPending, folderTreeView],
    );

    useEffect(() => {
        if (!restoreSettings) return;
        setSelectedRestoreOption({
            DEEP_ARCHIVE: restoreSettings.deepArchiveRestoreClass.value,
            GLACIER: restoreSettings.glacierRestoreClass.value,
        });
    }, [restoreSettings]);

    return (
        <Dialog variant={"basic"} open={true} onClose={() => onClose(false)}>
            <DialogTitle>{t(`common::label::Restore ${isMulti ? "items" : "the item"}?`)}</DialogTitle>
            <DialogContent>
                {restoreSettings?.deepArchiveRestoreClass?.isOverridable && (
                    <Grid containner flexDirection={`row`} alignItems={`center`} mb={4}>
                        <InfoAlert
                            content={{
                                data: [
                                    {
                                        section: (
                                            <Trans
                                                i18nKey="common::msg::Selected assets can be restored by selecting a restore <NewLine/>option based on how they were archived."
                                                components={{
                                                    NewLine: <Typography />,
                                                }}
                                            />
                                        ),
                                    },
                                ],
                            }}
                        />
                    </Grid>
                )}
                {getRestoreOptions.map((option) => (
                    <>
                        <Grid container flexDirection={`row`} alignItems={`center`} mt={2}>
                            <Typography variant={`body3_700`} component={"span"} mr={3}>
                                {t(`common::label::Archive Option`, `Archive Option`)}
                            </Typography>
                            <Typography variant={"body1_700"} component={"p"}>
                                {PROJECT_SETTINGS_ARCHIVE_OPTIONS.find(({ value }) => value === option.label)?.label}
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            flexWrap={`nowrap`}
                            flexDirection={`column`}
                            sx={{
                                mt: 1.5,
                                borderRadius: `4px`,
                                border: `1px solid ${theme.palette.secondary[100]}`,
                                overflow: "hidden",
                            }}
                        >
                            <Grid item>
                                <List>
                                    <ListItem
                                        sx={{
                                            listStyleType: "disc",
                                            display: "list-item",
                                            ml: `33px`,
                                            pl: `8px`,
                                            pb: `0px`,
                                        }}
                                    >
                                        <Typography variant={"body3_700"} component={"p"} sx={{ mb: "4px" }}>
                                            {t(`common::label::Change Restore Option`, `Change Restore Option`)}
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Select
                                            size="medium"
                                            value={selectedRestoreOption[option.label]}
                                            defaultValue={option?.value?.toUpperCase()}
                                            sx={{ minWidth: "400px" }}
                                            onChange={(e) => {
                                                e.stopPropagation();
                                                onHandleChangeRestoreOption(e, option.label);
                                            }}
                                            disabled={restoreSettings?.deepArchiveRestoreClass?.isOverridable}
                                        >
                                            {restoreOptions(option.label).map((o) => {
                                                return <MenuItem value={o.value}>{o.label}</MenuItem>;
                                            })}
                                        </Select>
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    </>
                ))}
                <Divider flexItem sx={{ mt: 3, mb: 3 }} />
                <Typography variant={"body1_400"} sx={{ color: "#3c3d4f" }}>
                    {isMulti ? (
                        <Trans
                            i18nKey="common::msg::If you restore this item now, you will also be charged for the <NewLine/>remaining days per asset."
                            components={{
                                NewLine: <Typography variant={"subtitle1_700"} />,
                            }}
                        />
                    ) : (
                        <Trans
                            i18nKey="common::msg::If you restore this item now, you will also be charged for the <NewLine>remaining <Strong>{{n}} days.</Strong></NewLine>"
                            values={{ n: expiredDays }}
                            components={{
                                NewLine: <Typography variant={"subtitle1_700"} />,
                                Strong: (
                                    <Typography
                                        color={theme.palette.primary[400]}
                                        component={"strong"}
                                        fontWeight={"bold"}
                                    />
                                ),
                            }}
                        />
                    )}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant={`text`} size={"medium"} onClick={() => onClose(false)} color={`grey`}>
                    {t(`common::label::Cancel`)}
                </Button>
                <Button
                    variant={`contained`}
                    loading={actionPending}
                    onClick={(e) => {
                        e.stopPropagation();
                        onHandleSubmit(selectedRestoreOption, folders, dataType);
                    }}
                    color={`primary`}
                    size={"medium"}
                >
                    {t(`common::label::Restore`)}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogArchiveRestore;
