import { handleActions, createAction } from "redux-actions";
// import moment from 'moment';
import {
    axios,
    // axiosWithoutHeaders
} from "../cores/axiosWrapper";
// import {CONSTANTS} from '../constants';

import queryString from "../cores/queryString";

function getArchivedAssetsAPI(stageId, apiEndpoint, projectId, params) {
    return axios.get(`${apiEndpoint}/archived-assets`, {
        headers: {
            stageId,
            projectId,
        },
        params,
    });
}

const ARCHIVED_ASSETS_PENDING = "ARCHIVED_ASSETS_PENDING";
const ARCHIVED_ASSETS_FAILURE = "ARCHIVED_ASSETS_FAILURE";

const GET_ARCHIVED_ASSETS_SUCCESS = "GET_ARCHIVED_ASSETS_SUCCESS";
const RESET_ARCHIVED_ASSETS = "RESET_ARCHIVED_ASSETS";
const SET_CARDVIEW_ARCHIVED_ASSETS = "SET_CARDVIEW_ARCHIVED_ASSETS";

const UPDATE_ARCHIVED_ASSETS_OPEN_ACTION = "UPDATE_ARCHIVED_ASSETS_OPEN_ACTION";
const UPDATE_ARCHIVED_ASSETS_PARAMS = "UPDATE_ARCHIVED_ASSETS_PARAMS";
const RESET_ARCHIVED_ASSETS_PARAMS = "RESET_ARCHIVED_ASSETS_PARAMS";
const INIT_ARCHIVED_ASSETS = "INIT_ARCHIVED_ASSETS";

const initialState = {
    pending: false,
    error: false,
    list: null,
    isCardView: true,
};

export default handleActions(
    {
        [ARCHIVED_ASSETS_PENDING]: (state) => {
            return {
                ...state,
                pending: true,
                error: false,
            };
        },
        [ARCHIVED_ASSETS_FAILURE]: (state) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [GET_ARCHIVED_ASSETS_SUCCESS]: (state, action) => {
            return {
                ...state,
                list: action.payload,
                pending: false,
                error: false,
            };
        },
        [RESET_ARCHIVED_ASSETS]: () => {
            return {
                ...initialState,
            };
        },
        [SET_CARDVIEW_ARCHIVED_ASSETS]: (state, action) => {
            return {
                ...state,
                isCardView: action.payload,
            };
        },
        [UPDATE_ARCHIVED_ASSETS_OPEN_ACTION]: (state, action) => {
            return {
                ...state,
                list: state.list.map((v) => {
                    return {
                        ...v,
                        open: v.id === action.payload ? !v.open : false,
                    };
                }),
            };
        },
        [UPDATE_ARCHIVED_ASSETS_PARAMS]: (state, action) => {
            return {
                ...state,
                params: {
                    ...state.params,
                    ...action.payload,
                },
            };
        },
        [INIT_ARCHIVED_ASSETS]: () => {
            return {
                ...initialState,
            };
        },
    },
    initialState,
);

export const setCardView = createAction(SET_CARDVIEW_ARCHIVED_ASSETS);
export const resetArchivedAssets = createAction(RESET_ARCHIVED_ASSETS);
export const open = createAction(UPDATE_ARCHIVED_ASSETS_OPEN_ACTION);
export const initParams = createAction(INIT_ARCHIVED_ASSETS);
export const getArchivedAssets = (params) => (dispatch, getState) => {
    const { stage, project, archivedAssets } = getState();

    dispatch({ type: ARCHIVED_ASSETS_PENDING });
    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;
    return new Promise((resolve, reject) => {
        getArchivedAssetsAPI(stageId, apiEndpoint, projectId, params)
            .then((response) => {
                dispatch({
                    type: GET_ARCHIVED_ASSETS_SUCCESS,
                    payload: response.data.results,
                });
                resolve(response);
            })
            .catch((error) => {
                dispatch({
                    type: ARCHIVED_ASSETS_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};
