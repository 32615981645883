export const getEnvironment = () => {
    const environment = process.env.REACT_APP_NOT_SECRET_CODE;
    if (!environment) return environment;
    return environment.trim();
};

const validate = (code) => {
    const environment = getEnvironment();
    if (!environment || environment.trim().indexOf(code) === -1) return false;

    return true;
};

export const isLocal = validate("local");

export const isCms = (function () {
    if (
        window.location.hostname.indexOf("cms") !== -1 ||
        (process.env.REACT_APP_VIEW_MODE ? process.env.REACT_APP_VIEW_MODE.trim() === "CMS" : false)
    )
        return true;
    else return false;
})();

export const isDev = validate("development");
export const isStage = validate("stage");
export const isProd = validate("production");
export const isQA = validate("qa");
export default {
    getEnvironment,
    isDev,
    isStage,
    isProd,
    isQA,
    isLocal,
};
