import { axios } from "../../cores/axiosWrapper";
import fp from "lodash/fp";

import moment from "moment";
import queryString from "../../cores/queryString";

const PhotoApi = {
    createPhotoAsync: async function (stageId, apiEndpoint, projectId, data) {
        console.log("--- createPhotoAsync");
        return axios.post(`${apiEndpoint}/contents/photo`, data, {
            headers: {
                stageId,
                projectId,
            },
        });
    },

    getPhotoAsync: async function (stageId, apiEndpoint, projectId, id) {
        // return new Promise((resolve, reject) => {
        //     resolve({
        //         data: musicData
        //     });
        // });

        ////TODO MUSIC 작업 중
        return axios.get(`${apiEndpoint}/contents/photo/${id}?generateAccessUrl=true`, {
            headers: {
                stageId,
                projectId,
            },
        });
    },

    getPhotosAsync: async function (stageId, apiEndpoint, projectId, query) {
        let queries = {
            generateAccessUrl: true,
        };

        if (query) {
            if (query.offset) queries.offset = query.offset;
            if (query.limit) queries.limit = query.limit;

            if (query.isQuickSearch) {
                queries.q = query.keyword;
            } else {
                if (query.name) queries.name = query.name;
                if (query.id) queries.id = query.id;
                if (query.ids) queries.ids = query.ids;
                if (query.creator) queries.creator = query.creator;
            }

            if (query.categoryGroupId) {
                queries.categoryGroupId = query.categoryGroupId;
                if (query.isNotCategoryIds) {
                    queries.isNotCategoryIds = query.isNotCategoryIds;
                    queries.categoryIds = "-1";
                }
            } else {
                if (query.categoryIds && query.categoryIds.length > 0)
                    queries.categoryIds = query.categoryIds
                        .map((c) => {
                            if (typeof c === "object") return c.id;
                            return c;
                        })
                        .join(",");
                if (query.isNotCategoryIds) {
                    queries.isNotCategoryIds = query.isNotCategoryIds;
                    queries.categoryIds = "-1";
                }
                if (query.isIncludeChildCategory) queries.isIncludeChildCategory = query.isIncludeChildCategory;
            }

            if (query.tags) queries.tags = "";
            if (query.tags !== undefined && query.tags.length > 0) {
                query.tags.forEach((tag, index) => {
                    index === 0 ? tag : (tag = `,${tag}`);
                    queries.tags += tag;
                });
            }

            if (query.startAt && query.endAt) {
                // console.log("--test endAt:", endAt);
                queries.createdAt = `${query.startAt}~${query.endAt}`;
            }
        }

        return axios.get(`${apiEndpoint}/contents/photo?${queryString.stringify(queries)}`, {
            headers: {
                stageId,
                projectId,
            },
        });
    },

    deletePhotoAsync: async function (stageId, apiEndpoint, projectId, id) {
        console.log("--- deletePhotoAsync api");
        return axios.delete(`${apiEndpoint}/contents/photo/${id}`, {
            headers: {
                stageId,
                projectId,
            },
        });
    },

    updatePhotoAsync: async function (stageId, apiEndpoint, projectId, id, version, data) {
        return axios.patch(`${apiEndpoint}/contents/photo/${id}`, data, {
            params: {
                version,
                generateAccessUrl: true,
            },
            headers: {
                stageId,
                projectId,
            },
        });
    },

    updateBulkAsync: async function (stageId, apiEndpoint, projectId, data) {
        return axios.patch(`${apiEndpoint}/contents/photo/bulk`, data, {
            params: {
                generateAccessUrl: true,
            },
            headers: {
                stageId,
                projectId,
            },
        });
    },

    attachContentsAPI: function (stageId, apiEndpoint, projectId, id, version, itemSchemaType, data) {
        return axios.post(`${apiEndpoint}/contents/photo/${id}/items`, data, {
            params: {
                version,
                itemSchemaType,
            },
            headers: {
                stageId,
                projectId,
            },
        });
    },

    detachContentsAPI: async function (stageId, apiEndpoint, projectId, id, version, itemSchemaType, elementId) {
        return axios.delete(`${apiEndpoint}/contents/photo/${id}/items/${elementId}`, {
            params: {
                version,
                itemSchemaType,
            },
            headers: {
                stageId,
                projectId,
            },
        });
    },
};

export default PhotoApi;
