import React, { FC } from "react";

/* styled */
import { IconButton, Tooltip } from "@mzc-pdc/ui";
import { IconEditSmall } from "@mzc-cloudplex/icons";
import { useTranslation } from "react-i18next";

const EditButton = ({ onClick }) => {
    const { t } = useTranslation();

    return (
        <Tooltip title={t(`common::label::${"Edit"}`)}>
            <IconButton
                className={"btn-edit"}
                size={"xsmall"}
                circled
                variant={"dashed"}
                sx={{ ml: 1 }}
                onClick={onClick}
            >
                <IconEditSmall size={12} />
            </IconButton>
        </Tooltip>
    );
};

export default EditButton;
