import React, { useEffect, useRef, useState } from "react";
import { PUBLIC_URL, PROPERTY_PANEL_TYPE } from "@constants";
import Loading from "@components/loader/Loading";
import FormatBadge from "../../formatbadge/FormatBadge";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import menuService from "@services/menuService";

const RelatedAssetTab = ({ assets, relatedAssets, propertyPanel }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { spaceId, projectId } = menuService.parse(pathname);

    return (
        <React.Fragment>
            {assets.pending ? (
                <Loading></Loading>
            ) : (
                <div className="list-items">
                    {relatedAssets && relatedAssets.length ? (
                        <ul>
                            {relatedAssets.map((a, k) => (
                                <li key={k}>
                                    <div className={`thumbnail type-${a.mediaType.toLowerCase()}`}>
                                        {
                                            {
                                                IMAGE: (
                                                    <img
                                                        className={`list-thumbnail type-${a.mediaType.toLowerCase()}`}
                                                        src={
                                                            (a.accessUrls && a.accessUrls[0]) ||
                                                            `${PUBLIC_URL}/images/thumbnail_no_image_s.png`
                                                        }
                                                        style={{
                                                            width: "50px",
                                                            height: "50px",
                                                        }}
                                                        onError={(e) => {
                                                            e.target.src = `${PUBLIC_URL}/images/thumbnail_no_image_s.png`;
                                                        }}
                                                    />
                                                ),
                                                VIDEO: (
                                                    <span>
                                                        {a.thumbnails &&
                                                            a.thumbnails[0] &&
                                                            a.thumbnails[0].accessUrl && (
                                                                <img
                                                                    className={`list-thumbnail type-${a.mediaType.toLowerCase()}`}
                                                                    style={{
                                                                        width: "50px",
                                                                        height: "50px",
                                                                    }}
                                                                    src={
                                                                        a.thumbnails &&
                                                                        a.thumbnails[0] &&
                                                                        a.thumbnails[0].accessUrl
                                                                    }
                                                                />
                                                            )}
                                                        <i></i>
                                                    </span>
                                                ),
                                                default: (
                                                    <i
                                                        className={`sprite sprite-type-${a.mediaType.toLowerCase()}`}
                                                    ></i>
                                                ),
                                            }[a.mediaType]
                                        }
                                    </div>
                                    <div className="content">
                                        <FormatBadge format={a.type} mediaType={a.mediaType} />
                                        <p className={"ellipsis-flex ellipsis-flex-inline"}>
                                            <strong className="ellipsis">{a.name}</strong>
                                            {a.encryptions && a.encryptions.length > 0 && (
                                                <i className="nowrap sprite sprite-drm"></i>
                                            )}
                                        </p>
                                        {propertyPanel.type === PROPERTY_PANEL_TYPE.SHARED_ASSET ? (
                                            <span>{a.id}</span>
                                        ) : (
                                            <a
                                                href={`${menuService.paths.assetsAssetDetail(
                                                    spaceId,
                                                    projectId,
                                                    a.id,
                                                )}?projectId=${a.project.id}`}
                                                className="btn btn-link btn-link-id"
                                                target="_blank"
                                            >
                                                <span>{a.id}</span>
                                            </a>
                                        )}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div className="empty">
                            <i className="sprite sprite-nojob"></i>
                            <p>
                                {t(`common::label::${"Empty {{name}}"}`, {
                                    name: t(`common::label::Related {{pascalK}}`, {
                                        pascalK: t(`common::label::Assets`),
                                    }),
                                })}
                            </p>
                        </div>
                    )}
                </div>
            )}
        </React.Fragment>
    );
};

export default RelatedAssetTab;
