import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
    Checkbox,
    Divider,
    Grid,
    Breadcrumbs,
    Button,
    IconButton,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    TextField, Stack, Typography,
} from "@mzc-pdc/ui";
import {
    IconAdd,
    IconAllView, IconArrowBack,
    IconArrowDropDown,
    IconArrowDropUp, IconImage, IconCancel, IconCategory, IconEditMedium, IconFolder, IconHierarchy,
    IconMoreHoriz, IconPhotoMultiType, IconSave,
    IconSeparator,
    ImageCategoryGroupOn,
} from "@mzc-cloudplex/icons";
import theme from "@styles/theme";

import StatusText from "../../components_v2/status/status-text";


import AssetTypeFlag from "@components_v2/icon-type/asset/asset-type-flag";
import AssetCard from "../../components_v2/asset-card/asset-card";
import BreadCrumb from "../../components_v2/breadcrumb/breadcrumb";
import { Tabs } from "../../components_v2/tabs/tabs";

import Menu from "../../features/menu/menu";
import ListTools from "../../features/list-tools/list-tools";
import { PageHeader } from "../../features/page-header/page-header";
import { alpha } from "@mui/material";
import { PageTools } from "../../features/page-tools/page-tools";
// import { SearchTextField } from "../../features/list-search/search-textfield";

const Todos = ({ asset }) => {


    const [viewAll, setViewAll] = React.useState(true);
    const assets = useSelector((state) => state.assets);

    const [edit, setEdit] = useState(false);
    const [text, setText] = useState("TitleName01");
    const threshold = 255;
    const handleChange = (event: { target: { value: any; }; }) => {
        const newText = event.target.value;
        setText(newText);
    };

    return (
        <>
            <PageHeader
                breadcrumb={
                    <BreadCrumb
                        items={[
                            { label: "Admin", link: "--" },
                            { label: "Settings", link: "--" },
                            { label: "id" },
                        ]}
                    />
                }
                title={
                    <>
                        <Typography variant={"h3"} component={"strong"}>
                            Title
                        </Typography>
                    </>
                }
            />

            <Divider sx={{ mt: 5, mb: 5 }} />

            <PageHeader
                breadcrumb={
                    <BreadCrumb
                        items={[
                            { label: "Admin", link: "--" },
                            { label: "Settings", link: "--" },
                            { label: "id" },
                        ]}
                    />
                }
                prev={
                    <IconButton
                        circled
                        sx={{ mt: -1 }}
                    >
                        <IconArrowBack size={16} />
                    </IconButton>
                }
                title={
                    <>
                        <Grid item xs={edit}>
                            {edit ? (
                                <Stack>
                                    <TextField
                                        fullWidth
                                        multiline
                                        value={text}
                                        onChange={handleChange}
                                        sx={{
                                            ".MuiInputBase-root": {
                                                minHeight: `46px`,
                                                padding: 1,
                                                alignItems: `center`,
                                                lineHeight: `1.5`,
                                            },
                                            ".MuiOutlinedInput-notchedOutline ": {
                                                borderColor: theme.palette.primary.main,
                                            },
                                        }}
                                        inputProps={{
                                            sx: {
                                                fontSize: text.length > threshold ? "16px" : "20px",
                                            },
                                        }}
                                    />
                                    <Grid container gap={0.5} mt={0.5} justifyContent={"flex-end"}>
                                        <IconButton
                                            variant={"outlined"}
                                            color={"greySecondary"}
                                            onClick={() => {
                                                setEdit(false);
                                            }}
                                        >
                                            <IconCancel size={16} />
                                        </IconButton>
                                        <IconButton
                                            variant={"outlined"}
                                            color={"primary"}
                                            onClick={() => {
                                                setEdit(false);
                                            }}
                                        >
                                            <IconSave size={16} />
                                        </IconButton>
                                    </Grid>
                                </Stack>
                            ) : (
                                <Grid
                                    container
                                    flexWrap={"nowrap"}
                                    gap={1.5}
                                    p={1}
                                    sx={{
                                        borderRadius: `2px`,
                                        "&:hover": {
                                            backgroundColor: alpha(theme.palette.secondary.main, 0.08),
                                        },
                                    }}
                                >
                                    <Grid item>
                                        <Typography variant={text.length > threshold ? "subtitle1_700" : "h3"}
                                                    component={"p"}
                                                    sx={{ wordBreak: `break-all` }}>
                                            {text}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title={"Edit"}>
                                            <IconButton
                                                circled
                                                variant={"dashed"}
                                                size={"small"}
                                                color={"greySecondary"}
                                                onClick={() => {
                                                    setEdit(true);
                                                }}
                                            >
                                                <IconEditMedium size={16} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </>
                }
            />

            <PageTools
                // search={<><SearchTextField /></>}
                actions={<Grid container gap={1.5}>
                    <Button
                        variant={"outlined"}
                        startIcon={<IconPhotoMultiType size={16} />}
                        color={"greySecondary"}
                        size={"medium"}
                    >
                        Image Viewer
                    </Button>
                    <Divider flexItem orientation={'vertical'} />
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        size={"medium"}
                        startIcon={<IconAdd size={20} />}
                    >
                        <strong>Create Assets</strong>
                    </Button>
                </Grid>}
            />

            <Divider sx={{mt:1.5}}/>
            <ListTools
                onClickRefresh
                onClickMove
                onClickDownload
                onClickDelete
            />

            {/*BreadCrumb<br />*/}
            {/*<BreadCrumb*/}
            {/*    items={[*/}
            {/*        { label: "Admin", link: "--" },*/}
            {/*        { label: "Settings", link: "--" },*/}
            {/*        { label: "id" },*/}
            {/*    ]}*/}
            {/*/>*/}


            <Divider sx={{ mt: 5, mb: 5 }} />
            ToggleButton<br />

            <Tooltip title={viewAll ? "View All" : "Grouping View"}>
                <ToggleButton
                    value="view"
                    size={"small"}
                    color={"primary"}
                    sx={{
                        backgroundColor: theme.palette.primary.light,
                        "&:hover": {
                            backgroundColor: theme.palette.primary.light,
                        },
                        "&.Mui-selected": {
                            backgroundColor: theme.palette.primary.light,
                            "&:hover": {
                                backgroundColor: theme.palette.primary.light,
                            },
                        },
                    }}
                    selected={viewAll}
                    onChange={() => {
                        setViewAll(!viewAll);
                    }}
                >
                    {viewAll ? (
                        <IconAllView size={16} htmlColor={theme.palette.common.white} />
                    ) : (
                        <IconHierarchy size={16} htmlColor={theme.palette.common.white} />
                    )}

                </ToggleButton>
            </Tooltip>


            <Divider sx={{ mt: 5, mb: 5 }} />
            Tabs<br />
            <Tabs
                items={[
                    { label: "tab1" },
                    { label: "tab2" },
                ]}
            />

            <Divider sx={{ mt: 2, mb: 2 }} />
            <Grid container>
                <Grid item xs={3}>
                    <Tabs
                        orientation={"vertical"}
                        items={[
                            { label: "tab1" },
                            { label: "tab2" },
                        ]}
                    />
                </Grid>
            </Grid>
            <Divider sx={{ mt: 2, mb: 2 }} />

            <Tabs
                type={"filled"}
                size={"small"}
                items={[
                    { label: "tab1", icon: <IconFolder size={16} />, iconPosition: "start" },
                    { label: "tab2", icon: <IconCategory size={16} />, iconPosition: "start" },
                ]}
            />

            <Divider sx={{ mt: 5, mb: 5 }} />
            AssetTypeFlag<br />
            <AssetTypeFlag width={22} height={16} type={"VIDEO"} />
            <AssetTypeFlag width={22} height={16} type={"AUDIO"} />
            <AssetTypeFlag width={22} height={16} type={"FILE"} />
            <AssetTypeFlag width={22} height={16} type={"IMAGE"} />
            <AssetTypeFlag width={22} height={16} type={"TEXT"} />

            <Divider sx={{ mt: 5, mb: 5 }} />

            AssetCard<br />
            <Grid container gap={2.5}>
                <Grid item>
                    <AssetCard
                        checked={false}
                        selected={false}
                        variant={`card`}
                        checkbox={<Checkbox color={"greySecondary"} />}
                        src={`https://static9.depositphotos.com/1431107/1154/i/950/depositphotos_11542091-stock-photo-sample-stamp.jpg`}
                        thumbnailSize={202}
                        mimeType={`IMAGE`}
                        name={`data?.name`}
                        status={`inactive`}
                        size={`convertToByte(data?.element?.size)`}
                        url={`data.element?.url`}
                        archiveStatus={``}
                        actions={
                            <Menu
                                width={160}
                                placement={"right"}
                                type={"icon"}
                                label={<IconMoreHoriz size={16} />}
                                color={"inherit"}
                                size={"small"}
                                list={[
                                    {
                                        label: "Move",
                                        // value: { id: data?.id, name: data?.name },
                                        // onClick: onHandleClickMove,
                                        // disabled: onClickMove === undefined,
                                        clickClose: true,
                                    },
                                    {
                                        label: "Download",
                                        // value: data,
                                        // onClick: onClickDownload,
                                        clickClose: true,
                                        // divider: onClickMove !== undefined,
                                    },
                                    {
                                        label: "Delete",
                                        // value: { id: data?.id, name: data?.name },
                                        // onClick: onHandleClickDelete,
                                        clickClose: true,
                                        divider: true,
                                    },
                                ]}
                            />
                        }
                    />
                </Grid>
                <Grid item>
                    <AssetCard
                        checked={false}
                        selected={false}
                        variant={`card`}
                        checkbox={<Checkbox color={"greySecondary"} />}
                        src={`https://static9.depositphotos.com/1431107/1154/i/950/depositphotos_11542091-stock-photo-sample-stamp.jpg`}
                        thumbnailSize={202}
                        mimeType={`data?.element?.mimeType`}
                        name={`data?.name`}
                        status={`active`}
                        size={`convertToByte(data?.element?.size)`}
                        url={`data.element?.url`}
                        archiveStatus={``}
                    />
                </Grid>
                <Grid item>
                    <AssetCard
                        checked
                        selected={false}
                        variant={`card`}
                        checkbox={<Checkbox color={"greySecondary"} />}
                        src={`https://static9.depositphotos.com/1431107/1154/i/950/depositphotos_11542091-stock-photo-sample-stamp.jpg`}
                        thumbnailSize={202}
                        mimeType={`data?.element?.mimeType`}
                        name={`data?.name`}
                        status={`active`}
                        size={`convertToByte(data?.element?.size)`}
                        url={`data.element?.url`}
                        archiveStatus={``}
                    />
                </Grid>
                <Grid item>
                    <AssetCard
                        checked
                        selected
                        variant={`card`}
                        checkbox={<Checkbox color={"greySecondary"} />}
                        src={`https://static9.depositphotos.com/1431107/1154/i/950/depositphotos_11542091-stock-photo-sample-stamp.jpg`}
                        thumbnailSize={202}
                        mimeType={`data?.element?.mimeType`}
                        name={`data?.name`}
                        status={`active`}
                        size={`convertToByte(data?.element?.size)`}
                        url={`data.element?.url`}
                        archiveStatus={``}
                    />
                </Grid>
                <Grid item>
                    <AssetCard
                        checked
                        selected
                        variant={`card`}
                        checkbox={<Checkbox color={"greySecondary"} />}
                        src={`https://static9.depositphotos.com/1431107/1154/i/950/depositphotos_11542091-stock-photo-sample-stamp.jpg`}
                        thumbnailSize={202}
                        mimeType={`data?.element?.mimeType`}
                        name={`data?.name`}
                        status={`active`}
                        size={`convertToByte(data?.element?.size)`}
                        url={`data.element?.url`}
                        archiveStatus={`""`}
                    />
                </Grid>
            </Grid>

            AssetCard archive 상태별
            <Grid container gap={2.5}>

                <Grid item>
                    <AssetCard
                        checked={false}
                        selected={false}
                        variant={`card`}
                        checkbox={<Checkbox color={"greySecondary"} />}
                        src={`https://static9.depositphotos.com/1431107/1154/i/950/depositphotos_11542091-stock-photo-sample-stamp.jpg`}
                        thumbnailSize={202}
                        mimeType={`data?.element?.mimeType`}
                        name={`data?.name`}
                        status={`active`}
                        size={`convertToByte(data?.element?.size)`}
                        url={`data.element?.url`}
                        archiveStatus={`ARCHIVED`}
                    />
                </Grid>
                <Grid item>
                    <AssetCard
                        checked={false}
                        selected={false}
                        variant={`card`}
                        checkbox={<Checkbox color={"greySecondary"} />}
                        src={`https://static9.depositphotos.com/1431107/1154/i/950/depositphotos_11542091-stock-photo-sample-stamp.jpg`}
                        thumbnailSize={202}
                        mimeType={`data?.element?.mimeType`}
                        name={`data?.name`}
                        status={`active`}
                        size={`convertToByte(data?.element?.size)`}
                        url={`data.element?.url`}
                        archiveStatus={`ARCHIVE_ERROR`}
                    />
                </Grid>
                <Grid item>
                    <AssetCard
                        checked={false}
                        selected={false}
                        variant={`card`}
                        checkbox={<Checkbox color={"greySecondary"} />}
                        src={`https://static9.depositphotos.com/1431107/1154/i/950/depositphotos_11542091-stock-photo-sample-stamp.jpg`}
                        thumbnailSize={202}
                        mimeType={`data?.element?.mimeType`}
                        name={`data?.name`}
                        status={`active`}
                        size={`convertToByte(data?.element?.size)`}
                        url={`data.element?.url`}
                        archiveStatus={`ARCHIVING`}
                    />
                </Grid>
            </Grid>

            <Divider sx={{ mt: 5, mb: 5 }} />

            status<br />
            <Grid container gap={2}>


                {/*<StatusText status={"STOPPING"} label={"label"} infoIcon={<RunSpriteIcon />} />*/}
                <StatusText status={"ACTIVE"} />
                <StatusText status={"INACTIVE"} />
                <StatusText status={"PENDING"} />
                <StatusText status={"FAILED"} />

                <StatusText status={"COMPLETE"} />
                <StatusText status={"CANCELED"} />
                <StatusText status={"INGESTING"} />
                <StatusText status={"PROGRESSING"} />
                <StatusText status={"RUNNING"} />
                <StatusText status={"STOPPING"} />
                <StatusText status={"STARTING"} />
                <StatusText status={"UNKNOWN"} />


            </Grid>

            <Divider sx={{ mt: 5, mb: 5 }} />


        </>
    );

};


export default Todos;
