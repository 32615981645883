import { handleActions, createAction } from "redux-actions";
import {
    createThreadAPI,
    deleteCommentAPI,
    getCommentsAPI,
    getThreadsAPI,
    updateCommentAPI,
    getCommentAPI,
    createActionsAPI,
    createCommentAPI,
    getThreadAPI,
    deleteThreadAPI,
    getThreadMembersAPI,
    updateThreadAPI,
    updateThreadMembersAPI,
    deleteCommentAttachmentAPI,
    addCommentAttachmentsAPI,
} from "@modules/apis/comments";
import { getProjectUsersAPI } from "@modules/apis/user-management-v2";

const initialState = {
    comment: {
        data: {
            results: null,
            prevToken: null,
            nextToken: null,
        },
        prevPending: null,
        nextPending: null,
        error: null,
        createPending: null,
        firstLoading: true,
    },
    resourceData: null, //Note: domain에 연결된 스레드
    resourcePending: null,
    selectedThreadId: null, //Note: 선택된 스레드 id(댓글 용)
    selectedCommentId: null, //Note: 선택된 댓글 id(대댓글 용)
    thread: {
        data: {
            results: null,
            prevToken: null,
            nextToken: null,
        },
        prevPending: null,
        nextPending: null,
    }, //Note: 댓글에 연결된 스레드(대댓글)
    isEditingCommentId: null,
    isLinkCommentUsed: false,
    hasLinkThreadLogicExecuted: false, //Note: 메일 링크로 대댓글창을 직접 접근하는 경우, 댓글창으로 나갈 경우 1회만 threads, comments를 불러줘야 함
    uploadingComments: {},
};

const cloneState = { ...initialState };

const GET_RESOURCE_DATA_PENDING = "GET_RESOURCE_DATA_PENDING";
const GET_RESOURCE_DATA_SUCCESS = "GET_RESOURCE_DATA_SUCCESS";
const GET_RESOURCE_DATA_FAILURE = "GET_RESOURCE_DATA_FAILURE";
const UPDATE_RESOURCE_DATA_SUCCESS = "UPDATE_RESOURCE_DATA_SUCCESS";
const UPDATE_RESOURCE_DATA_MEMBERS_SUCCESS = "UPDATE_RESOURCE_DATA_MEMBERS_SUCCESS";
const GET_COMMENTS_PENDING = "GET_COMMENTS_PENDING";
const GET_PREV_COMMENTS_SUCCESS = "GET_PREV_COMMENTS_SUCCESS";
const GET_NEXT_COMMENTS_SUCCESS = "GET_NEXT_COMMENTS_SUCCESS";
const GET_COMMENTS_FAILURE = "GET_COMMENTS_FAILURE";
const RESET_COMMENTS = "RESET_COMMENTS";
const UPDATE_SELECTED_COMMENT_ID = "UPDATE_SELECTED_COMMENT_ID";
const UPDATE_SELECTED_THREAD_ID = "UPDATE_SELECTED_THREAD_ID";
const RESET_SELECTED_COMMENT_ID = "RESET_SELECTED_COMMENT_ID";
const UPDATE_IS_EDITING_COMMENT_ID = "UPDATE_IS_EDITING_COMMENT_ID";
const GET_THREAD_COMMENTS_PENDING = "GET_THREAD_COMMENTS_PENDING";
const GET_THREAD_PREV_COMMENTS_SUCCESS = "GET_THREAD_PREV_COMMENTS_SUCCESS";
const GET_THREAD_NEXT_COMMENTS_SUCCESS = "GET_THREAD_NEXT_COMMENTS_SUCCESS";
const GET_THREAD_COMMENTS_FAILURE = "GET_THREAD_COMMENTS_FAILURE";
const RESET_THREAD_COMMENTS = "RESET_THREAD_COMMENTS";
const GET_PARENT_COMMENT_SUCCESS = "GET_PARENT_COMMENT_SUCCESS";
const UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS";
const UPDATE_THREAD_SUCCESS = "UPDATE_THREAD_SUCCESS";
const ADD_THREAD_SUCCESS = "ADD_THREAD_SUCCESS";
const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";
const ADD_THREAD_ALL_SUCCESS = "ADD_THREAD_ALL_SUCCESS";
const CREATE_THREAD_SUCCESS = "CREATE_THREAD_SUCCESS";
const CREATE_THREAD_PENDING = "CREATE_THREAD_PENDING";
const UPDATE_IS_LINK_COMMENT_USED = "UPDATE_IS_LINK_COMMENT_USED";
const UPDATE_HAS_LINK_THREAD_LOGIC_EXECUTED = "UPDATE_HAS_LINK_THREAD_LOGIC_EXECUTED";
const RESET_STATE = "RESET_STATE";
const ADD_UPLOADING_COMMENTS = "ADD_UPLOADING_COMMENTS";
const REMOVE_UPLOADING_COMMENTS = "REMOVE_UPLOADING_COMMENTS";
const UPDATE_UPLOADING_COMMENTS = "UPDATE_UPLOADING_COMMENTS";

export default handleActions(
    {
        [GET_RESOURCE_DATA_PENDING]: (state, action) => {
            return {
                ...state,
                resourceData: { ...cloneState.resourceData },
                resourcePending: true,
            };
        },
        [GET_RESOURCE_DATA_SUCCESS]: (state, action) => {
            return {
                ...state,
                resourcePending: null,
                resourceData: action.payload,
            };
        },
        [GET_RESOURCE_DATA_FAILURE]: (state, action) => {
            return { ...state, resourcePending: null, error: action.payload };
        },
        [UPDATE_RESOURCE_DATA_SUCCESS]: (state, action) => {
            const resourceDataResults = state.resourceData.results;
            const targetIndex = resourceDataResults.findIndex((el) => el.id === action.payload.id);
            resourceDataResults[targetIndex] = {
                ...action.payload,
                members: resourceDataResults[targetIndex].members,
            };
            return {
                ...state,
                resourceData: {
                    ...state.resourceData,
                    results: resourceDataResults,
                },
            };
        },
        [UPDATE_RESOURCE_DATA_MEMBERS_SUCCESS]: (state, action) => {
            const resourceDataResults = state.resourceData.results;
            const targetIndex = resourceDataResults.findIndex((el) => el.id === action.payload.id);
            resourceDataResults[targetIndex] = {
                ...resourceDataResults[targetIndex],
                members: action.payload.members,
            };
            return {
                ...state,
                resourceData: {
                    ...state.resourceData,
                    results: resourceDataResults,
                },
            };
        },
        [GET_COMMENTS_PENDING]: (state, action) => {
            const pending = action.payload === "ASC" ? "nextPending" : "prevPending";

            return { ...state, comment: { ...state.comment, [pending]: true } };
        },
        [GET_NEXT_COMMENTS_SUCCESS]: (state, action) => {
            const { cursor, data } = action.payload;
            const results = state.comment?.data?.results?.filter((el) => el.id !== cursor) ?? [];
            let newDataResults = results ? [...data.results.reverse(), ...results] : [...data.results.reverse()];

            return {
                ...state,
                comment: {
                    ...state.comment,
                    nextPending: null,
                    data: {
                        ...state.comment.data,
                        results: newDataResults,
                        nextToken: action.payload.data.nextToken,
                    },
                },
            };
        },
        [GET_PREV_COMMENTS_SUCCESS]: (state, action) => {
            const data = state.comment?.data?.results ?? [];
            const newDataResults = data ? [...data, ...action.payload.data.results] : [...action.payload.data.results];
            return {
                ...state,
                comment: {
                    ...state.comment,
                    prevPending: null,
                    data: {
                        ...state.comment.data,
                        results: newDataResults,
                        prevToken: action.payload.data.nextToken,
                    },
                    firstLoading: false,
                },
            };
        },
        [GET_COMMENTS_FAILURE]: (state, action) => {
            return {
                ...state,
                comment: {
                    ...state.comment,
                    prevPending: false,
                    error: action.payload,
                },
            };
        },
        [RESET_COMMENTS]: (state, action) => {
            return { ...state, comment: { ...cloneState.comment } };
        },
        [UPDATE_SELECTED_THREAD_ID]: (state, action) => {
            return { ...state, selectedThreadId: action.payload };
        },
        [UPDATE_SELECTED_COMMENT_ID]: (state, action) => {
            return { ...state, selectedCommentId: action.payload };
        },
        [RESET_SELECTED_COMMENT_ID]: (state, action) => {
            return {
                ...state,
                selectedCommentId: null,
                thread: { ...initialState.thread },
            };
        },
        [GET_THREAD_COMMENTS_PENDING]: (state, action) => {
            const pending = action.payload === "ASC" ? "nextPending" : "prevPending";

            return { ...state, thread: { ...state.thread, [pending]: true } };
        },
        [GET_THREAD_PREV_COMMENTS_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const threadComments = [...(state.thread.data?.results ?? []), ...data.results];
            const commentData = state.comment.data;
            return {
                ...state,
                thread: {
                    ...state.thread,
                    data: {
                        results: threadComments,
                        prevToken: data.nextToken,
                    },
                    prevPending: false,
                    nextPending: false,
                },
            };
        },
        [GET_THREAD_NEXT_COMMENTS_SUCCESS]: (state, action) => {
            const { cursor, data } = action.payload;
            const results = state.thread?.data?.results?.filter((el) => el.id !== cursor) ?? [];
            const threadComments = results ? [...data.results.reverse(), ...results] : [...data.results.reverse()];

            return {
                ...state,
                thread: {
                    ...state.thread,
                    data: {
                        results: threadComments,
                        nextToken: data.nextToken,
                    },
                    nextPending: false,
                    prevPending: false,
                },
            };
        },
        [GET_THREAD_COMMENTS_FAILURE]: (state, action) => {
            return {
                ...state,
                thread: { ...state.thread, prevPending: false },
            };
        },
        [GET_PARENT_COMMENT_SUCCESS]: (state, action) => {
            return {
                ...state,
                comment: {
                    ...state.comment,
                    data: { ...state.comment.data, results: [action.payload] },
                },
                selectedCommentId: action.payload?.id,
            };
        },
        [RESET_THREAD_COMMENTS]: (state, action) => {
            return { ...state, thread: { ...cloneState.thread } };
        },
        [UPDATE_THREAD_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const { selectedCommentId } = state;

            if (data.isDeleted) {
                const removedThread = {
                    ...state.thread,
                    data: {
                        ...state.thread.data,
                        results: state.thread.data.results?.map((thread) => (thread.id !== data.id ? thread : data)),
                    },
                };
                const commentData = state.comment.data;
                const modifiedComments = {
                    comment: {
                        ...state.comment,
                        data: {
                            ...commentData,
                            results: commentData.results.map((comment) => {
                                if (comment.id === selectedCommentId) {
                                    return {
                                        ...comment,
                                        subThread: {
                                            ...comment.subThread,
                                            commentCount: comment.subThread.commentCount - 1,
                                        },
                                    };
                                }
                                return comment;
                            }),
                        },
                    },
                };

                return {
                    ...state,
                    ...modifiedComments,
                    thread: removedThread,
                };
            } else {
                const threads = [...state.thread.data.results];
                const threadIndex = threads.findIndex((el) => el.id === data.id);
                threads[threadIndex] = data;

                return {
                    ...state,
                    thread: {
                        ...state.thread,
                        data: { ...state.thread.data, results: threads },
                    },
                };
            }
        },
        [UPDATE_COMMENT_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const comment = state.comment;

            const isEmptyComment = data.isDeleted && !data.subThread?.commentCount;
            const commentData = state.comment.data;
            if (isEmptyComment) {
                return {
                    ...state,
                    comment: {
                        ...state.comment,
                        data: {
                            ...commentData,
                            results: commentData.results?.map((comment) => (comment.id === data.id ? data : comment)),
                        },
                    },
                    selectedCommentId: data.id === state.selectedCommentId ? null : state.selectedCommentId,
                };
            } else {
                const replies = [...state.comment.data?.results];
                const replyIndex = replies.findIndex((el) => el.id === data.id);
                replies[replyIndex] = data;

                return {
                    ...state,
                    comment: {
                        ...comment,
                        data: { ...comment.data, results: replies },
                    },
                };
            }
        },
        [ADD_THREAD_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const newReplies = [...(state.comment?.data?.results || [])];
            const replyIndex = newReplies.findIndex((el) => el.id === state.selectedCommentId);
            const subThread = newReplies.find((el) => el.id === state.selectedCommentId)?.subThread;
            if (subThread)
                newReplies[replyIndex] = {
                    ...newReplies[replyIndex],
                    subThread: {
                        ...subThread,
                        commentCount: subThread.commentCount + 1,
                    },
                };

            const threads = [data, ...(state.thread?.data?.results ?? [])];

            return {
                ...state,
                comment: {
                    ...state.comment,
                    data: { ...state.comment.data, results: newReplies },
                },
                thread: {
                    ...state.thread,
                    data: { ...state.thread.data, results: threads },
                },
            };
        },
        [ADD_COMMENT_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const comment = state.comment;
            const replies = [data, ...(comment.data?.results || [])];

            return {
                ...state,
                comment: {
                    ...state.comment,
                    data: { ...state.comment.data, results: replies },
                },
            };
        },
        [ADD_THREAD_ALL_SUCCESS]: (state, action) => {
            const { data, resourceId, subThreadId } = action.payload;
            const newThreads = [...state.comment.data?.results];
            const threadIndex = newThreads?.findIndex((el) => el.id === resourceId);
            newThreads[threadIndex].subThread = {
                id: subThreadId,
                commentCount: 1,
            };

            return {
                ...state,
                comment: {
                    ...state.comment,
                    data: { ...state.comment.data, results: newThreads },
                },
                thread: {
                    ...state.thread,
                    data: { ...state.thread.data, results: data?.results },
                    prevPending: null,
                },
            };
        },
        [UPDATE_IS_EDITING_COMMENT_ID]: (state, action) => {
            return { ...state, isEditingCommentId: action.payload };
        },
        [CREATE_THREAD_PENDING]: (state, action) => {
            return {
                ...state,
                comment: { ...state.comment, createPending: true },
            };
        },
        [CREATE_THREAD_SUCCESS]: (state, action) => {
            const { commentId, threadId, data } = action.payload;
            const comment = state.comment;

            return {
                ...state,
                resourceData: {
                    ...state.resourceData,
                    results: [data, ...(state.resourceData.results ?? [])],
                },
                comment: {
                    ...comment,
                    data: {
                        ...comment.data,
                        results: comment.data?.results?.map((comment) => {
                            if (comment.id === commentId) {
                                return {
                                    ...comment,
                                    subThread: {
                                        id: threadId,
                                        commentCount: 0,
                                    },
                                };
                            }
                            return comment;
                        }),
                    },
                    createPending: false,
                },
            };
        },
        [UPDATE_IS_LINK_COMMENT_USED]: (state, action) => {
            return { ...state, isLinkCommentUsed: action.payload };
        },
        [UPDATE_HAS_LINK_THREAD_LOGIC_EXECUTED]: (state, action) => {
            return { ...state, hasLinkThreadLogicExecuted: action.payload };
        },
        [RESET_STATE]: (state, action) => {
            return {
                ...cloneState,
                uploadingComments: state.uploadingComments,
            };
        },
        [ADD_UPLOADING_COMMENTS]: (state, action) => {
            const { threadId, data } = action.payload;

            const uploadingCommentThread = state.uploadingComments[threadId];
            if (!uploadingCommentThread) {
                return {
                    ...state,
                    uploadingComments: {
                        ...state.uploadingComments,
                        [threadId]: [data],
                    },
                };
            } else {
                return {
                    ...state,
                    uploadingComments: {
                        ...state.uploadingComments,
                        [threadId]: [...uploadingCommentThread, data],
                    },
                };
            }
        },
        [REMOVE_UPLOADING_COMMENTS]: (state, action) => {
            const { threadId, id } = action.payload;

            const uploadingCommentThread = state.uploadingComments[threadId];
            if (uploadingCommentThread) {
                return {
                    ...state,
                    uploadingComments: {
                        ...state.uploadingComments,
                        [threadId]: uploadingCommentThread.filter((comment) => comment.id !== id),
                    },
                };
            }
            return state;
        },
        [UPDATE_UPLOADING_COMMENTS]: (state, action) => {
            const { threadId, commentId, fileIndex, data } = action.payload;

            const uploadingCommentThread = state.uploadingComments[threadId];
            if (uploadingCommentThread) {
                return {
                    ...state,
                    uploadingComments: {
                        ...state.uploadingComments,
                        [threadId]: uploadingCommentThread.map((comment) =>
                            comment.id === commentId ? { ...comment } : comment,
                        ),
                    },
                };
            }
        },
    },
    initialState,
);

export const resetState = createAction(RESET_STATE);
export const resetComments = createAction(RESET_COMMENTS);
export const resetThreadComments = createAction(RESET_THREAD_COMMENTS);

export const updateSelectedThreadId = createAction(UPDATE_SELECTED_THREAD_ID);
export const updateSelectedCommentId = createAction(UPDATE_SELECTED_COMMENT_ID);
export const resetSelectedCommentId = createAction(RESET_SELECTED_COMMENT_ID);
export const updateIsEditingCommentId = createAction(UPDATE_IS_EDITING_COMMENT_ID);

export const updateIsLinkCommentUsed = createAction(UPDATE_IS_LINK_COMMENT_USED);
export const updateHasLinkThreadLogicExecuted = createAction(UPDATE_HAS_LINK_THREAD_LOGIC_EXECUTED);
export const addUploadingComments = createAction(ADD_UPLOADING_COMMENTS);
export const removeUploadingComments = createAction(REMOVE_UPLOADING_COMMENTS);
export const updateUploadingComments = createAction(UPDATE_UPLOADING_COMMENTS);

export const getThreads = (projectId, params) => async (dispatch, getState) => {
    const { stage } = getState();
    dispatch({ type: GET_RESOURCE_DATA_PENDING });
    try {
        const response = await getThreadsAPI(stage.endpoint, projectId, params);

        dispatch({
            type: GET_RESOURCE_DATA_SUCCESS,
            payload: { ...response.data },
        });
    } catch (error) {
        dispatch({ type: GET_RESOURCE_DATA_FAILURE, payload: error });
    }
};

export const getThreadMembers = (projectId, threadId) => async (dispatch, getState) => {
    const { stage } = getState();
    try {
        const response = await getThreadMembersAPI(stage.endpoint, projectId, threadId);

        dispatch({
            type: UPDATE_RESOURCE_DATA_MEMBERS_SUCCESS,
            payload: { id: threadId, members: response.data },
        });
    } catch (e) {}
};

export const getComments = (projectId, resourceId, params) => async (dispatch, getState) => {
    const { stage } = getState();
    dispatch({ type: GET_COMMENTS_PENDING, payload: params.orderType });

    const actionType =
        params.orderType && params.orderType === "ASC" ? GET_NEXT_COMMENTS_SUCCESS : GET_PREV_COMMENTS_SUCCESS;

    try {
        if (!!params.nextToken && !!params.orderType) delete params.orderType;

        const response = await getCommentsAPI(stage.endpoint, projectId, resourceId, { ...params, limit: 10 });

        dispatch({
            type: actionType,
            payload: { data: response.data, cursor: params.cursor },
        });
    } catch (error) {
        dispatch({ type: GET_COMMENTS_FAILURE, payload: error });
    }
};

export const getThreadComments = (projectId, resourceId, params) => async (dispatch, getState) => {
    const { stage, comments } = getState();
    dispatch({
        type: GET_THREAD_COMMENTS_PENDING,
        payload: params?.orderType,
    });

    const actionType =
        params?.orderType && params?.orderType === "ASC"
            ? GET_THREAD_NEXT_COMMENTS_SUCCESS
            : GET_THREAD_PREV_COMMENTS_SUCCESS;
    try {
        if (!comments.selectedCommentId) {
            const { data } = await getThreadAPI(stage.endpoint, projectId, resourceId);
            const thread = data.context;
            const comment = await getCommentAPI(stage.endpoint, projectId, thread.parentThread.id, thread.resourceId);
            await dispatch({
                type: GET_PARENT_COMMENT_SUCCESS,
                payload: comment.data,
            });
        }

        if (!!params?.nextToken && !!params?.orderType) delete params.orderType;

        const response = await getCommentsAPI(stage.endpoint, projectId, resourceId, { ...params, limit: 10 });
        dispatch({
            type: actionType,
            payload: { data: response.data, cursor: params?.cursor },
        });
    } catch (error) {
        dispatch({ type: GET_THREAD_COMMENTS_FAILURE, payload: error });
    }
};

export const createThread = (threadInfo, params) => (dispatch, getState) => {
    const { stage, project } = getState();
    const { isThread } = threadInfo;

    dispatch({ type: CREATE_THREAD_PENDING });
    return createThreadAPI(stage.endpoint, project.id, params)
        .then(async (thread) => {
            const response = await getThreadAPI(stage.endpoint, project.id, thread.data.id);
            let payload = { data: response.data };

            if (isThread) {
                payload = {
                    ...payload,
                    commentId: params.context.resourceId,
                    threadId: thread.data?.id,
                };
                dispatch({ type: CREATE_THREAD_SUCCESS, payload });
            } else {
                dispatch({ type: CREATE_THREAD_SUCCESS, payload });
                dispatch({
                    type: UPDATE_SELECTED_THREAD_ID,
                    payload: thread.data?.id,
                });
            }
            return thread.data?.id;
        })
        .catch((e) => {
            console.log(e);
        });
};

export const createPrivateThread = (params) => (dispatch, getState) => {
    const { stage, project } = getState();

    return createThreadAPI(stage.endpoint, project.id, params)
        .then(async (thread) => {
            await dispatch(getThreads(project.id, params.context));
            return thread;
        })
        .catch((e) => {
            console.log(e);
        });
};

export const createComment = (threadInfo, params) => (dispatch, getState) => {
    const { stage, project } = getState();
    const { isThread, isEmptyThread, threadId } = threadInfo;
    return createCommentAPI(stage.endpoint, project.id, threadId, params)
        .then(async (comment) => {
            const commentId = comment.data?.id;
            const response = await getCommentAPI(stage.endpoint, project.id, threadId, commentId);
            let payload = { data: response.data };

            if (isThread) {
                dispatch({ type: ADD_THREAD_SUCCESS, payload });
            } else {
                dispatch({ type: ADD_COMMENT_SUCCESS, payload });
            }
        })
        .catch((e) => {
            console.log(e);
        });
};

export const updateComment = (threadInfo, commentId, data) => (dispatch, getState) => {
    const { stage, project } = getState();
    const { isThread, threadId } = threadInfo;

    return updateCommentAPI(stage.endpoint, project.id, threadId, commentId, data)
        .then(async () => {
            const response = await getCommentAPI(stage.endpoint, project.id, threadId, commentId);
            let payload = { data: response.data };

            if (isThread) dispatch({ type: UPDATE_THREAD_SUCCESS, payload });
            else dispatch({ type: UPDATE_COMMENT_SUCCESS, payload });
        })
        .catch((e) => {
            console.log(e);
        });
};

export const deleteComment = (threadInfo, commentId) => (dispatch, getState) => {
    const { stage, project } = getState();
    const { isThread, threadId } = threadInfo;

    return deleteCommentAPI(stage.endpoint, project.id, threadId, commentId)
        .then(async () => {
            const response = await getCommentAPI(stage.endpoint, project.id, threadId, commentId);
            let payload = { data: response.data };

            if (isThread) dispatch({ type: UPDATE_THREAD_SUCCESS, payload });
            else dispatch({ type: UPDATE_COMMENT_SUCCESS, payload });
        })
        .catch((e) => {
            console.log(e);
        });
};

export const deleteThread = (threadId) => (dispatch, getState) => {
    const { stage, project } = getState();
    return deleteThreadAPI(stage.endpoint, project.id, threadId);
};

export const postActions = (threadInfo, commentId, params) => (dispatch, getState) => {
    const { stage, project } = getState();
    const { isThread, threadId } = threadInfo;
    return createActionsAPI(stage.endpoint, project.id, threadId, commentId, params)
        .then(async () => {
            const response = await getCommentAPI(stage.endpoint, project.id, threadId, commentId);
            let payload = { data: response.data };

            if (isThread) dispatch({ type: UPDATE_THREAD_SUCCESS, payload });
            else dispatch({ type: UPDATE_COMMENT_SUCCESS, payload });
        })
        .catch((e) => {
            console.log(e);
        });
};

export const updateThread = (threadId, data) => (dispatch, getState) => {
    try {
        const { stage, project } = getState();
        return updateThreadAPI(stage.endpoint, project.id, threadId, data)
            .then(async () => {
                const response = await getThreadAPI(stage.endpoint, project.id, threadId);
                dispatch({
                    type: UPDATE_RESOURCE_DATA_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((e) => {
                console.log(e);
            });
    } catch (e) {}
};

export const updateThreadMembers = (threadId, data) => (dispatch, getState) => {
    const { stage, project } = getState();

    return updateThreadMembersAPI(stage.endpoint, project.id, threadId, data)
        .then(async () => {
            dispatch(getThreadMembers(project.id, threadId));
        })
        .catch((e) => {});
};

export const deleteCommentAttachments = (threadInfo, commentId, attachmentIds) => async (dispatch, getState) => {
    const { stage, project } = getState();
    const { isThread, threadId } = threadInfo;

    try {
        //delete ids in attachmentIds with using deleteCommentAttachmentAPI
        await Promise.allSettled(
            attachmentIds.map(async (attachmentId) => {
                try {
                    return await deleteCommentAttachmentAPI(
                        stage.endpoint,
                        project.id,
                        threadId,
                        commentId,
                        attachmentId,
                    );
                } catch (e) {
                    console.log(e);
                }
            }),
        );

        const response = await getCommentAPI(stage.endpoint, project.id, threadId, commentId);
        let payload = { data: response.data };

        if (isThread) dispatch({ type: UPDATE_THREAD_SUCCESS, payload });
        else dispatch({ type: UPDATE_COMMENT_SUCCESS, payload });
    } catch (e) {
        console.log(e);
    }
};

export const addCommentAttachments = (threadInfo, commentId, data) => async (dispatch, getState) => {
    const { stage, project } = getState();
    const { isThread, threadId } = threadInfo;

    try {
        await addCommentAttachmentsAPI(stage.endpoint, project.id, threadId, commentId, data);

        const response = await getCommentAPI(stage.endpoint, project.id, threadId, commentId);
        let payload = { data: response.data };

        if (isThread) dispatch({ type: UPDATE_THREAD_SUCCESS, payload });
        else dispatch({ type: UPDATE_COMMENT_SUCCESS, payload });
    } catch (e) {
        console.log(e);
    }
};
