import React, { forwardRef, useRef } from "react";
import { alpha, margin } from "@mui/system";

/* types */

import { MenuList, ListItemIcon, ListItemText, Divider, Box } from "@mzc-pdc/ui";

/* styled */
import { Popover, MenuItem } from "./styled";
import { IconButton, Button, Tooltip } from "@mzc-pdc/ui";
import theme from "@styles/theme";
import { IconArrowDropDown, IconArrowDropUp } from "@mzc-cloudplex/icons";

const getTextWidth = (title) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (context) {
        context.font = "14px Noto Sans KR";
        const width = context.measureText(title).width;
        return width;
    }
    return 0;
};

export const Menu = forwardRef(
    (
        {
            target,
            menuWidth,
            menuMargin,
            placement,
            type,
            startIcon,
            endIcon,
            endOpenIcon,
            label,
            color,
            size,
            variant,
            list,
            listSize,
            actions,
            divider,
            preventPropagation = true,
            useTooltip = false,
            value,
            ...restProps
        },
        ref,
    ) => {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const testRef = useRef(null);
        const open = Boolean(anchorEl);
        const handleClick = (event) => {
            if (preventPropagation) event.stopPropagation();
            setAnchorEl(event.currentTarget);
        };
        const handleClose = (event) => {
            if (preventPropagation) event.stopPropagation();
            setAnchorEl(null);
        };

        if (!list?.length) return null;

        return (
            <>
                {type === "icon" ? (
                    <IconButton
                        ref={ref}
                        id="menu-button"
                        aria-controls={open ? "menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                        color={color}
                        size={size}
                        circled
                        active={open.toString()}
                        disabled={restProps.disabled || list?.filter((item) => !item.hidden)?.length === 0}
                        {...restProps}
                    >
                        {label}
                    </IconButton>
                ) : (
                    <Button
                        ref={ref}
                        id="menu-button"
                        aria-controls={open ? "menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                        color={color}
                        size={size}
                        variant={variant}
                        startIcon={startIcon}
                        endIcon={open ? endOpenIcon : endIcon}
                        disabled={restProps.disabled || list?.filter((item) => !item.hidden)?.length === 0}
                        {...restProps}
                    >
                        {label}
                    </Button>
                )}
                <Popover
                    id="menu-popover"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: placement ?? "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: placement ?? "left",
                    }}
                    PaperProps={{
                        style: {
                            width: `${menuWidth}px`,
                            margin: `${menuMargin}px`,
                        },
                    }}
                >
                    <MenuList id="menu-list">
                        {list
                            ?.filter((item) => !item.hidden)
                            .map((item, index) => {
                                const width = getTextWidth(item.label);
                                return [
                                    item.divider && <Divider />,
                                    <Tooltip title={useTooltip && width >= 274 ? item.label : ""}>
                                        <MenuItem
                                            ref={testRef}
                                            id="menu-item"
                                            key={item.label}
                                            disabled={item.disabled}
                                            disableGutters
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (item.onClick) item.onClick(item.value, value);
                                                if (item.clickClose) handleClose(e);
                                            }}
                                            sx={{
                                                height: listSize === "small" ? "32px" : "40px",
                                                "&:hover": {
                                                    backgroundColor: alpha(theme.palette.secondary[800], 0.08),
                                                },
                                                "&:active, &.Mui-selected": {
                                                    backgroundColor: alpha("#c9d4f4", 0.04),
                                                },
                                            }}
                                        >
                                            {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                                            {item.control && <>{item.control}</>}
                                            <ListItemText
                                                primary={item.label}
                                                primaryTypographyProps={{
                                                    variant: "body1_400",
                                                    component: "span",
                                                    color: item.color ?? theme.palette.greySecondary.main,
                                                }}
                                            />
                                        </MenuItem>
                                    </Tooltip>,
                                ];
                            })}
                    </MenuList>
                    {actions && (
                        <div id={"menu-item-actions"} role={"menuitem"}>
                            {actions}
                        </div>
                    )}
                </Popover>
            </>
        );
    },
);

export const ActionMenu = ({ list }) => {
    return (
        <Menu
            open
            menuWidth={160}
            endIcon={
                <Box
                    sx={{
                        width: `24px`,
                        height: `24px`,
                        borderRadius: `100%`,
                        display: `flex`,
                        alignItems: `center`,
                        justifyContent: `center`,
                        marginRight: `-10px`,
                    }}
                >
                    <IconArrowDropDown size={24} />
                </Box>
            }
            endOpenIcon={
                <Box
                    sx={{
                        width: `24px`,
                        height: `24px`,
                        borderRadius: `100%`,
                        display: `flex`,
                        alignItems: `center`,
                        justifyContent: `center`,
                        marginRight: `-10px`,
                        backgroundColor: alpha(theme.palette.secondary.light, 0.08),
                    }}
                >
                    <IconArrowDropUp size={24} />
                </Box>
            }
            label={"Actions"}
            size={"medium"}
            variant={"outlined"}
            color={"greySecondary"}
            placement={"right"}
            list={list}
        />
    );
};

export default Menu;
