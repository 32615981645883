import React from "react";
import compareVersions, {
    VERSION_CM_5058_DASHBOARD,
    VERSION_CM_5112_PROJECT_SITE_ACCESS,
} from "@cores/version";
import menuService from "@services/menuService";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory, Link } from "react-router-dom";
import equal from "deep-equal";
import { useTranslation } from "react-i18next";
import * as jobStatus from "@cores/jobStatus";
import { cancelTranscodeAllSync } from "@modules/upload";
import { siteMode } from "@cores/siteMode";
import { setSiteMode } from "@modules/siteMode";
import config from "@config/config";
import VersionedComponent from "@components/VersionedComponent";
import SelectProject from "@components/navigator/components/SelectProject";
import SelectStage from "@components/navigator/components/SelectStage";
import SwitchButton from "./SwitchButton";
import Logo from "./Logo";
import { checkIsMobile } from "@utils/checkmobile";
import { isLocal, isDev } from "@cores/enviromentExtension";
import { alpha } from "@mui/material";
import { AppBar, Box, Button, Divider, Grid, IconButton, Tab, Tabs, Toolbar, Tooltip, Typography } from "@mzc-pdc/ui";
import theme from "@styles/theme";
import StartMenu from "../../features/start-menu/start-menu";
import ProfileMenu from "../../features/profile-menu/profile-menu";
import useSettings from "@hooks/useSettings";
import { MENU_LIST } from "@constants";

const isMobile = checkIsMobile();

const TAB_TYPE = {
    DASHBOARD: "DASHBOARD",
    SOURCES: "SOURCES",
    ASSETS: "ASSETS",
    VIDEOS: "VIDEOS",
    PEOPLE: "PEOPLE",
    MUSIC: "MUSIC",
    PHOTO: "PHOTO",
};

const HeaderForCms = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { pathname } = useLocation();
    const { spaceId, projectId } = menuService.parse(pathname);
    const { navigatorMenus } = useSettings({ type: "General" });

    const [showSettingsLayer, setShowSettingsLayer] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [activeAppDownModal, setActiveAppDownModal] = useState(false);
    const [headerTheme, setHeaderTheme] = useState(null);
    const [hasConsoleAccess, setHasConsoleAccess] = useState(false);
    const [hideContents, setHideContents] = useState(false);
    const [activeStage, setActiveStage] = useState(undefined);
    const [activeProject, setActiveProject] = useState(undefined);

    const stage = useSelector(({ stage }) => stage);
    const project = useSelector(({ project }) => project);
    const user = useSelector(({ user }) => user);
    const settings = useSelector(({ settings }) => settings);
    const upload = useSelector(({ upload }) => upload);

    useEffect(() => {
        let _hasConsoleAccess = false;
        let _hideContents = false;

        if (stage) {
            if (stage.extensions?.["HIDE_CONTENTS"] === "true") {
                _hideContents = true;
            }
            if (compareVersions(stage?.version, VERSION_CM_5112_PROJECT_SITE_ACCESS) >= 0) {
                if (stage?.user?.projects) {
                    const userProject = stage.user.projects.filter((p) => p.id === projectId)[0];
                    if (userProject) {
                        const siteAccess = userProject.siteAccess;
                        _hasConsoleAccess = siteAccess?.indexOf("CONSOLE") !== -1;
                    }
                }
            }
        }

        if (localStorage.getItem("headerTheme")) {
            const headerThemeJson = JSON.parse(localStorage.getItem("headerTheme"));
            if (!equal(headerTheme, headerThemeJson?.data)) {
                setHeaderTheme(headerThemeJson.data);
            }
        }

        if (user?.stages?.data) {
            const activeStage = user.stages.data.filter((v) => v.id === stage.id)[0];
            if (activeStage) {
                const activeProject = activeStage.projects
                    ? activeStage.projects.filter((v) => v.id === project.id)[0]
                    : null;
                setActiveStage(activeStage);
                setActiveProject(activeProject);
            }
        }

        setHasConsoleAccess(_hasConsoleAccess);
        setHideContents(_hideContents);

        window.addEventListener("beforeunload", handleBeforeunload);
        window.addEventListener("unload", handleUnload);

        return () => {
            window.removeEventListener("unload", handleUnload);
            window.removeEventListener("beforeunload", handleBeforeunload);
        };
    }, []);

    useEffect(() => {
        if (!stage?.user) return;

        if (compareVersions(stage?.version, VERSION_CM_5112_PROJECT_SITE_ACCESS) >= 0) {
            let _hasConsoleAccess = false;
            if (stage?.user?.projects) {
                const userProject = stage.user.projects.filter((p) => p.id === projectId)[0];
                if (userProject) {
                    const siteAccess = userProject.siteAccess;
                    _hasConsoleAccess = siteAccess?.indexOf("CONSOLE") !== -1;
                }
            }
            setHasConsoleAccess(_hasConsoleAccess);
        }
    }, [stage?.user]);

    useEffect(() => {
        if (!settings?.stage?.General) return;

        const headerThemeOfSettings = settings.stage.General.headerTheme;
        if (headerThemeOfSettings?.value) {
            const headerThemeJSON = JSON.parse(headerThemeOfSettings.value);
            if (!equal(headerThemeJSON, headerTheme)) {
                localStorage.setItem(
                    "headerTheme",
                    JSON.stringify({
                        stageId: stage.id,
                        data: headerThemeJSON,
                    }),
                );
                setHeaderTheme(headerThemeJSON);
            }
        }
    }, [settings?.stage?.General]);

    const handleUnload = () => {
        const cancelTargetJobs = upload.jobs.data.filter(
            (v) => jobStatus.getValuesLowerThan(jobStatus.values.SUBMITTED).indexOf(v.status) > -1,
        );
        if (cancelTargetJobs.length > 0) {
            dispatch(cancelTranscodeAllSync(cancelTargetJobs));
        }
    };

    const handleBeforeunload = (e) => {
        const cancelTargetJobs = upload.jobs.data.filter(
            (v) => jobStatus.getValuesLowerThan(jobStatus.values.SUBMITTED).indexOf(v.status) > -1,
        );
        if (cancelTargetJobs.length > 0) {
            const message = t(`common::msg::${"There is work in progress. Would you really go out?"}`);
            (e || window.event).returnValue = message;

            return message;
        }
    };

    const onConsoleClick = () => {
        siteMode.set("CONSOLE");
        dispatch(
            setSiteMode({
                mode: "CONSOLE",
            }),
        );
        if (isLocal) {
            history.replace(
                compareVersions(VERSION_CM_5058_DASHBOARD, stage.version) <= 0
                    ? menuService.paths.dashboard(spaceId, projectId)
                    : `/`,
            );
        } else {
            if(siteMode.isCmsSupported()) {
                window.location.href =
                    compareVersions(VERSION_CM_5058_DASHBOARD, stage.version) <= 0
                        ? `${config.host.console}${menuService.paths.dashboard(spaceId, projectId)}`
                        : `${config.host.console}`;
            } else {
                window.location.href =
                    compareVersions(VERSION_CM_5058_DASHBOARD, stage.version) <= 0
                        ? `${menuService.paths.dashboard(spaceId, projectId)}`
                        : `/`;
            }
        }
    };

    const closeAppDownModal = () => {
        setActiveAppDownModal(false);
    };

    const selectedTab = useMemo(() => {
        const path = pathname;
        if (path.indexOf("/dashboard") >= 0) return TAB_TYPE.DASHBOARD;
        else if (path.indexOf("/storage") >= 0) return TAB_TYPE.SOURCES;
        else if (path.indexOf("/assets/assets") >= 0) return TAB_TYPE.ASSETS;
        else if (path.indexOf("/contents/videos") >= 0) return TAB_TYPE.VIDEOS;
        else if (path.indexOf("/contents/people") >= 0) return TAB_TYPE.PEOPLE;
        else if (path.indexOf("/contents/music") >= 0) return TAB_TYPE.MUSIC;
        else if (path.indexOf("/contents/photos") >= 0) return TAB_TYPE.PHOTO;
        return "";
    }, [pathname]);

    return (
        <React.Fragment>
            <AppBar
                position="fixed"
                sx={{
                    backgroundColor: headerTheme ? headerTheme.color?.bgColor : theme.palette.background.default,
                    color: headerTheme ? headerTheme.color?.textColor : theme.palette.text.primary,
                    fill: headerTheme ? headerTheme.color?.textColor : theme.palette.text.primary,
                    boxShadow: `none`,
                    borderBottom: `1px solid ${theme.palette.secondary[100]}`,
                    zIndex: (props) => props.zIndex.drawer + 1,
                }}
            >
                <Toolbar sx={{ padding: `0 !important`, flexDirection: `column` }}>
                    <Grid container justifyContent={`space-between`} alignItems={`center`}>
                        <Grid item>
                            <Grid container alignItems={"center"} height={theme.mixins.toolbar.minHeight}>
                                <Grid item px={1.25}>
                                    <StartMenu headerTheme={headerTheme} />
                                </Grid>
                                <Divider
                                    flexItem
                                    orientation={"vertical"}
                                    sx={{
                                        background: `none`,
                                        borderColor: alpha(theme.palette.secondary[500], 0.16),
                                    }}
                                />
                                <Grid item pl={2.5}>
                                    <Grid container alignItems={"center"}>
                                        <Logo
                                            to={
                                                compareVersions(VERSION_CM_5058_DASHBOARD, stage.version) <= 0
                                                    ? `${menuService.paths.dashboard(spaceId, projectId)}`
                                                    : `/`
                                            }
                                            imgSrc={headerTheme?.ci}
                                            fill={headerTheme?.color?.textColor}
                                        />

                                        {hasConsoleAccess && (
                                            <>
                                                <Divider
                                                    flexItem
                                                    orientation={"vertical"}
                                                    sx={{
                                                        m: `4px 20px`,
                                                        background: `none`,
                                                        borderColor: alpha(theme.palette.secondary[500], 0.16),
                                                    }}
                                                />
                                                <SwitchButton
                                                    onClick={onConsoleClick}
                                                    headerTheme={headerTheme}
                                                    label={t(`common::label::${"Console"}`)}
                                                />
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid
                                container
                                gap={1}
                                pr={1}
                                alignItems={"center"}
                                height={theme.mixins.toolbar.minHeight}
                            >
                                <Grid item>
                                    <SelectStage />
                                </Grid>
                                <Divider
                                    flexItem
                                    orientation={"vertical"}
                                    sx={{
                                        my: "18px",
                                        background: `none`,
                                        borderColor: alpha(theme.palette.secondary[500], 0.16),
                                    }}
                                />
                                <Grid item>
                                    <ProfileMenu />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider
                        flexItem
                        orientation={"horizontal"}
                        sx={{
                            background: `none`,
                            borderColor: alpha(theme.palette.secondary[500], 0.16),
                        }}
                    />
                    <Grid container flexWrap={"nowrap"}>
                        <Grid item sx={{ whiteSpace: `nowrap` }}>
                            <SelectProject open={true} type={"cms"} />
                        </Grid>
                        <Grid item sx={{ overflowX: "overlay", "&::-webkit-scrollbar": { width: 0, height: 0 } }}>
                            <Grid
                                container
                                gap={2}
                                height={`100%`}
                                flexWrap={"nowrap"}
                                sx={{
                                    a: {
                                        display: `inline-flex`,
                                        alignItems: `center`,
                                        height: `100%`,
                                        minHeight: 40,
                                        padding: `0 16px`,
                                        "&:hover": {
                                            textDecoration: `none`,
                                        },
                                        "&.active": {
                                            borderBottom: `3px solid ${theme.palette.primary.light}`,
                                        },
                                    },
                                }}
                            >
                                <VersionedComponent version={VERSION_CM_5058_DASHBOARD}>
                                    <Grid item sx={{ whiteSpace: `nowrap` }}>
                                        <Link
                                            to={`${menuService.paths.dashboard(spaceId, projectId)}`}
                                            className={selectedTab === TAB_TYPE.DASHBOARD ? "active" : ""}
                                        >
                                            <Typography
                                                color={headerTheme ? headerTheme.color?.textColor : "secondary"}
                                            >
                                                {t(`common::label::${"Dashboard"}`)}
                                            </Typography>
                                        </Link>
                                    </Grid>
                                </VersionedComponent>

                                {/* <Grid item sx={{ whiteSpace: `nowrap` }}>
                                    <Link
                                        to={`${menuService.paths.storageCms(spaceId, projectId)}`}
                                        className={selectedTab === TAB_TYPE.SOURCES ? "active" : ""}
                                    >
                                        <Typography color={headerTheme ? headerTheme.color?.textColor : "secondary"}>
                                            {t(`common::label::${"Storage"}`)}
                                        </Typography>
                                    </Link>

                                    <Link
                                        to={`${menuService.paths.assetsAssets(spaceId, projectId)}`}
                                        className={selectedTab === TAB_TYPE.ASSETS ? "active" : ""}
                                    >
                                        <Typography color={headerTheme ? headerTheme.color?.textColor : "secondary"}>
                                            {t(`common::label::${"Assets"}`)}
                                        </Typography>
                                    </Link>
                                </Grid> */}

                                {hasConsoleAccess && (
                                    <Grid item sx={{ whiteSpace: `nowrap` }}>
                                        <Link
                                            to={`${menuService.paths.storageCms(spaceId, projectId)}`}
                                            className={selectedTab === TAB_TYPE.SOURCES ? "active" : ""}
                                            hidden={navigatorMenus?.[MENU_LIST.STORAGES]?.visible === false}
                                        >
                                            <Typography
                                                color={headerTheme ? headerTheme.color?.textColor : "secondary"}
                                            >
                                                {t(`common::label::${"Storage"}`)}
                                            </Typography>
                                        </Link>

                                        <Link
                                            to={`${menuService.paths.assetsAssets(spaceId, projectId)}`}
                                            className={selectedTab === TAB_TYPE.ASSETS ? "active" : ""}
                                            hidden={navigatorMenus?.[MENU_LIST.ASSETS]?.visible === false}
                                        >
                                            <Typography
                                                color={headerTheme ? headerTheme.color?.textColor : "secondary"}
                                            >
                                                {t(`common::label::${"Assets"}`)}
                                            </Typography>
                                        </Link>
                                    </Grid>
                                )}

                                {/*{!hideContents && (*/}
                                {/*    <Grid item sx={{ whiteSpace: `nowrap` }}>*/}
                                {/*        <Link*/}
                                {/*            to={`${menuService.paths.contentsVideos(spaceId, projectId)}`}*/}
                                {/*            className={selectedTab === TAB_TYPE.VIDEOS ? "active" : ""}*/}
                                {/*        >*/}
                                {/*            <Typography*/}
                                {/*                color={headerTheme ? headerTheme.color?.textColor : "secondary"}*/}
                                {/*            >*/}
                                {/*                {t(`common::label::${"Videos"}`)}*/}
                                {/*            </Typography>*/}
                                {/*        </Link>*/}

                                {/*        <Link*/}
                                {/*            to={`${menuService.paths.contentsPeople(spaceId, projectId)}`}*/}
                                {/*            className={selectedTab === TAB_TYPE.PEOPLE ? "active" : ""}*/}
                                {/*        >*/}
                                {/*            <Typography*/}
                                {/*                color={headerTheme ? headerTheme.color?.textColor : "secondary"}*/}
                                {/*            >*/}
                                {/*                {t(`common::label::${"People"}`)}*/}
                                {/*            </Typography>*/}
                                {/*        </Link>*/}

                                {/*        <VersionedComponent version={VERSION_CM_4601_PHOTO_CONTENTS}>*/}
                                {/*            <Link*/}
                                {/*                to={`${menuService.paths.contentsMusic(spaceId, projectId)}`}*/}
                                {/*                className={selectedTab === TAB_TYPE.MUSIC ? "active" : ""}*/}
                                {/*            >*/}
                                {/*                <Typography*/}
                                {/*                    color={headerTheme ? headerTheme.color?.textColor : "secondary"}*/}
                                {/*                >*/}
                                {/*                    {t(`common::label::${"Music"}`)}*/}
                                {/*                </Typography>*/}
                                {/*            </Link>*/}
                                {/*        </VersionedComponent>*/}
                                {/*        <VersionedComponent version={VERSION_CM_4601_PHOTO_CONTENTS}>*/}
                                {/*            <Link*/}
                                {/*                to={`${menuService.paths.contentsPhoto(spaceId, projectId)}`}*/}
                                {/*                className={selectedTab === TAB_TYPE.PHOTO ? "active" : ""}*/}
                                {/*            >*/}
                                {/*                <Typography*/}
                                {/*                    color={headerTheme ? headerTheme.color?.textColor : "secondary"}*/}
                                {/*                >*/}
                                {/*                    {t(`common::label::${"Photo"}`)}*/}
                                {/*                </Typography>*/}
                                {/*            </Link>*/}
                                {/*        </VersionedComponent>*/}
                                {/*    </Grid>*/}
                                {/*)}*/}
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            {activeAppDownModal &&
                ReactDOM.createPortal(<LoadableModelForAppDown onClose={closeAppDownModal} />, document.body)}
        </React.Fragment>
    );
};

export default HeaderForCms;
