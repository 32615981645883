import React, { Component } from "react";
import { connect } from "react-redux";
import Token from "./token";
import User from "./user";
import Roles from "./roles";

const Authorization = ({ connectedMenuId, children }) => {
    return (
        // <>{children}</>
        <Token>
            <User>
                <Roles connectedMenuId={connectedMenuId}>{children}</Roles>
            </User>
        </Token>
    );
};

export default Authorization;
