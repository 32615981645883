import { styled, alpha } from '@mui/system';

/* components */
import { Popover as MuiPopover, MenuItem as MuiMenuItem } from '@mui/material';

export const Popover = styled(MuiPopover)(({ theme }) => ({
  '.MuiPaper-root ': {
    marginTop: `4px`,
    borderRadius: `2px`,
    boxShadow: `0px 4px 4px 0 rgba(0, 0, 0, 0.05)`,
    border: `1px solid #dfdfee`,
  },
}));

export const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  fontSize: `14px`,
  width: `100%`,
  minHeight: 0,
  padding: `5px 20px`,
  '.MuiListItemIcon-root ': {
    minWidth: `26px`,
  },
  '.MuiTypography-root': {
    textOverflow: `ellipsis`,
    overflow: `hidden`,
  },
  // '&:hover': {
  //   backgroundColor: theme.palette.primary.main
  // },
  //   '&:active': {
  //     backgroundColor:  alpha(theme.palette.secondary.A100, 0.04)
  // }
}));
