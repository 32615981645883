import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { alpha } from "@mui/material";
import theme from "@styles/theme";

const PanelBodyMedia = (props) => {
    const { className, ...otherProps } = props;
    return (
        <div
            {...otherProps}
            className={clsx("panel-body-media", className)}
            style={{ backgroundColor: alpha(theme.palette.greySecondary[50], 0.5) }}
        />
    );
};
PanelBodyMedia.propTypes = {
    className: PropTypes.string,
};

export default PanelBodyMedia;
