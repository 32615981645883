import React from "react";

import { Button, IconButton, Tooltip, MenuList, ListItemIcon, ListItemText, Divider } from "@mzc-pdc/ui";
import { Popover, MenuItem } from "./menu.styled";
import { useTranslation } from "react-i18next";

const Menu = ({
    target,
    title,
    width,
    placement,
    type,
    startIcon,
    endIcon,
    endOpenIcon,
    label,
    needTranslation = true,
    color,
    size,
    variant,
    list,
    actions,
    divider,
}) => {
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    return (
        <>
            {type === "icon" ? (
                <IconButton
                    title={title}
                    id="menu-button"
                    aria-controls={open ? "menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    color={color}
                    size={size}
                    circled
                    active={open}
                >
                    <Tooltip title={title}>
                        <span>{label}</span>
                    </Tooltip>
                </IconButton>
            ) : (
                <Button
                    id="menu-button"
                    aria-controls={open ? "menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    color={color}
                    size={size}
                    variant={variant}
                    startIcon={startIcon}
                    endIcon={open ? endOpenIcon : endIcon}
                >
                    {label}
                </Button>
            )}
            <Popover
                id="menu-popover"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: placement ?? "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: placement ?? "left",
                }}
                PaperProps={{
                    style: {
                        width: `${width}px`,
                    },
                }}
            >
                <MenuList id="menu-list">
                    {list
                        .filter((item) => !item.disabled)
                        .map((item, index) => [
                            item.divider && <Divider />,
                            <MenuItem
                                key={item.label}
                                disableGutters
                                disabled={item.buttonDisabled}
                                onClick={(e) => {
                                    if (item.onClick) item.onClick(item.value);
                                    if (item.clickClose) handleClose(e);
                                }}
                            >
                                {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                                {item.control && <>{item.control}</>}
                                <ListItemText
                                    primary={
                                        needTranslation ? t(`common::label::${item.label}`, item.label) : item.label
                                    }
                                    primaryTypographyProps={{ variant: "body1_400", component: "span" }}
                                />
                            </MenuItem>,
                        ])}
                </MenuList>
                {actions && (
                    <div id={"menu-item-actions"} role={"menuitem"}>
                        {actions}
                    </div>
                )}
            </Popover>
        </>
    );
};

export default Menu;
