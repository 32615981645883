import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const SpriteIcon = (props) => {
    const { className, ...otherProps } = props;
    return <i {...otherProps} className={clsx("sprite", className)} />;
};
SpriteIcon.propTypes = {
    className: PropTypes.string,
};

export default SpriteIcon;
