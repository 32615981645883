import { handleActions, createAction } from "redux-actions";
import { axios, axiosWithoutHeaders } from "../cores/axiosWrapper";

function getTeamsAPI(stageId, apiEndpoint, projectId, params) {
    const headers = {
        stageId,
    };

    let queries = {
        limit: params.limit,
    };

    if (params.nextToken) queries.nextToken = params.nextToken;
    if (params.keyword) queries[params.keywordType] = params.keyword;

    if (projectId) {
        headers.projectId = projectId;
    }

    return axios.get(`${apiEndpoint}/v3/managements/teams`, {
        params: queries,
        headers,
    });
}

function getTeamAPI(stageId, apiEndpoint, projectId, id) {
    const headers = {
        stageId,
    };

    if (projectId) {
        headers.projectId = projectId;
    }

    return axios.get(`${apiEndpoint}/v3/managements/teams/${id}`, {
        headers,
    });
}

function getTeamMembersAPI(stageId, apiEndpoint, projectId, id, params) {
    const headers = {
        stageId,
    };

    if (projectId) {
        headers.projectId = projectId;
    }

    return axios.get(`${apiEndpoint}/v3/managements/teams/${id}/members`, {
        params,
        headers,
    });
}

function getTeamRolesAPI(stageId, apiEndpoint, projectId, params) {
    const headers = {
        stageId,
    };

    if (projectId) {
        headers.projectId = projectId;
    }

    return axios.get(`${apiEndpoint}/role-managements/roles`, {
        params,
        headers,
    });
}

function getProjectTeamMembersAPI(stageId, apiEndpoint, projectId, id, params) {
    const headers = {
        stageId,
    };

    if (projectId) {
        headers.projectId = projectId;
    }

    return axios.get(`${apiEndpoint}/v3/managements/projects/${projectId}/teams/${id}/members`, {
        params,
        headers,
    });
}

function getProjectTeamAPI(stageId, apiEndpoint, projectId, id) {
    const headers = {
        stageId,
    };

    if (projectId) {
        headers.projectId = projectId;
    }

    return axios.get(`${apiEndpoint}/v3/managements/projects/${projectId}/teams/${id}`, {
        headers,
    });
}

function patchProjectTeamAPI(stageId, apiEndpoint, projectId, teamId, body) {
    const headers = {
        stageId,
        projectId,
    };

    return axios.patch(`${apiEndpoint}/v3/managements/projects/${projectId}/teams/${teamId}`, body, {
        headers,
    });
}

function getProjectTeamsAPI(stageId, apiEndpoint, projectId, params) {
    const headers = {
        stageId,
    };

    let queries = {
        limit: params.limit,
    };

    if (params.nextToken) queries.nextToken = params.nextToken;
    if (params.keyword) queries[params.keywordType] = params.keyword;

    return axios.get(`${apiEndpoint}/v3/managements/projects/${projectId}/teams`, {
        params: queries,
        headers,
    });
}

function getProjectTeamRolesAPI(stageId, apiEndpoint, projectId, id, params) {
    const headers = {
        stageId,
    };

    let queries = {
        limit: params.limit,
    };

    if (params.nextToken) queries.nextToken = params.nextToken;
    if (params.keyword) queries[params.keywordType] = params.keyword;

    return axios.get(`${apiEndpoint}/v3/managements/projects/${projectId}/teams/${id}/roles`, {
        params: queries,
        headers,
    });
}

function createNewTeamAPI(stageId, apiEndpoint, projectId, body) {
    let headers = {
        stageId,
    };
    if (projectId) headers.projectId = projectId;
    return axios.post(`${apiEndpoint}/v3/managements/teams`, body, {
        headers,
    });
}

function deleteTeamAPI(stageId, apiEndpoint, projectId, id) {
    const headers = {
        stageId,
    };
    if (projectId) headers.projectId = projectId;
    return axios.delete(`${apiEndpoint}/v3/managements/teams/${id}`, {
        headers,
    });
}

function updateTeamAPI(stageId, apiEndpoint, projectId, id, body) {
    const headers = {
        stageId,
    };
    if (projectId) headers.projectId = projectId;
    return axios.patch(`${apiEndpoint}/v3/managements/teams/${id}`, body, {
        headers,
    });
}

function getTeamUsersAPI(stageId, apiEndpoint, id, params) {
    const queries = {
        limit: params.limit,
    };

    if (params.keyword) queries[params.keywordType] = params.keyword;
    if (params.nextToken) queries.nextToken = params.nextToken;

    const headers = {
        stageId,
    };

    return axios.get(`${apiEndpoint}/v3/managements/teams/${id}/members`, {
        params: queries,
        headers,
    });
}

function getTeamAssignableUsersAPI(stageId, apiEndpoint, id, params) {
    const queries = {
        limit: params.limit,
    };

    if (params.keyword) queries[params.keywordType] = params.keyword;
    if (params.nextToken) queries.nextToken = params.nextToken;

    const headers = {
        stageId,
    };

    return axios.get(`${apiEndpoint}/v3/managements/teams/${id}/assignable-members`, {
        params: queries,
        headers,
    });
}

function assignUserAPI(stageId, apiEndpoint, id, params) {
    return axios.put(`${apiEndpoint}/v3/managements/teams/${id}/members`, params, {
        headers: {
            stageId,
        },
    });
}

function unassignUserAPI(stageId, apiEndpoint, id, memberId) {
    return axios.delete(`${apiEndpoint}/v3/managements/teams/${id}/members/${memberId}`, {
        headers: {
            stageId,
        },
    });
}

const TEAM_MANAGEMENT_RESET = "TEAM_MANAGEMENT_RESET";
const TEAM_MANAGEMENT_PENDING = "TEAM_MANAGEMENT_PENDING";
const TEAM_MANAGEMENT_FAILURE = "TEAM_MANAGEMENT_FAILURE";
const GET_TEAM_MANAGEMENT_PENDING = "GET_TEAM_MANAGEMENT_PENDING";
const GET_TEAM_MANAGEMENT_FAILURE = "GET_TEAM_MANAGEMENT_FAILURE";

const TEAM_MANAGEMENT_GET_TEAMS_SUCCESS = "TEAM_MANAGEMENT_GET_TEAMS_SUCCESS";
const TEAM_MANAGEMENT_UPDATE_TEAMS_PARAMS = "TEAM_MANAGEMENT_UPDATE_TEAMS_PARAMS";
const TEAM_MANAGEMENT_RESET_TEAMS_PARAMS_RESET = "TEAM_MANAGEMENT_RESET_TEAMS_PARAMS_RESET";
const TEAM_MANAGEMENT_RESET_MEMBERS_PARAMS_RESET = "TEAM_MANAGEMENT_RESET_MEMBERS_PARAMS_RESET";
const TEAM_MANAGEMENT_INIT_TEAMS = "TEAM_MANAGEMENT_INIT_TEAMS";

const TEAM_MANAGEMENT_GET_TEAM_ROLES_SUCCESS = "TEAM_MANAGEMENT_GET_TEAM_ROLES_SUCCESS";
const TEAM_MANAGEMENT_GET_TEAM_SUCCESS = "TEAM_MANAGEMENT_GET_TEAM_SUCCESS";
const TEAM_MANAGEMENT_GET_TEAM_USERS_SUCCESS = "TEAM_MANAGEMENT_GET_TEAM_USERS_SUCCESS";
const TEAM_MANAGEMENT_ASSIGN_USER_SUCCESS = "TEAM_MANAGEMENT_ASSIGN_USER_SUCCESS";
const TEAM_MANAGEMENT_UNASSIGN_USER_SUCCESS = "TEAM_MANAGEMENT_UNASSIGN_USER_SUCCESS";

const TEAM_MANAGEMENT_GET_TEAM_MEMBERS_SUCCESS = "TEAM_MANAGEMENT_GET_TEAM_MEMBERS_SUCCESS";
const TEAM_MANAGEMENT_UPDATE_ROLES_PARAMS = "TEAM_MANAGEMENT_UPDATE_ROLES_PARAMS";
const TEAM_MANAGEMENT_UPDATE_MEMBERS_PARAMS = "TEAM_MANAGEMENT_UPDATE_MEMBERS_PARAMS";
const TEAM_MANAGEMENT_UPDATE_DETAIL_SUCCESS = "TEAM_MANAGEMENT_UPDATE_DETAIL_SUCCESS";
const TEAM_MANAGEMENT_UPDATE_PROJECT_TEAM_SUCCESS = "TEAM_MANAGEMENT_UPDATE_PROJECT_TEAM_SUCCESS";

const initialState = {
    initPending: false,
    pending: false,
    error: false,
    params: {
        keywordType: "name",
        keyword: "",
        offset: 0,
        limit: 50,
    },
    totalCount: 0,
    teams: null,
    team: null,
    members: {
        totalCount: 0,
        results: [],
        params: {
            keywordType: "name",
            keyword: "",
            offset: 0,
            limit: 50,
        },
    },
    roles: {
        totalCount: 0,
        results: [],
        params: {
            keywordType: "userId",
            keyword: "",
            offset: 0,
            limit: 50,
        },
    },
    permissions: {
        totalCount: 0,
        results: [],
        params: {
            keywordType: "userId",
            keyword: "",
            offset: 0,
            limit: 50,
        },
    },
};

export default handleActions(
    {
        [TEAM_MANAGEMENT_RESET]: (state) => {
            return {
                ...initialState,
            };
        },
        [GET_TEAM_MANAGEMENT_PENDING]: (state) => {
            return {
                ...state,
                initPending: true,
                error: false,
            };
        },
        [GET_TEAM_MANAGEMENT_FAILURE]: (state, action) => {
            return {
                ...state,
                initPending: false,
                error: true,
            };
        },
        [TEAM_MANAGEMENT_PENDING]: (state, action) => {
            return {
                ...state,
                pending: true,
                error: false,
            };
        },
        [TEAM_MANAGEMENT_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [TEAM_MANAGEMENT_GET_TEAMS_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                initPending: false,
                error: false,
                totalCount: data.totalCount,
                teams: data.results,
            };
        },
        [TEAM_MANAGEMENT_UPDATE_TEAMS_PARAMS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
                params: {
                    ...state.params,
                    ...action.payload,
                },
            };
        },
        [TEAM_MANAGEMENT_RESET_TEAMS_PARAMS_RESET]: (state) => {
            return {
                ...state,
                params: {
                    keywordType: "name",
                    keyword: "",
                    offset: 0,
                    limit: 50,
                },
            };
        },
        [TEAM_MANAGEMENT_RESET_MEMBERS_PARAMS_RESET]: (state) => {
            return {
                ...state,
                members: {
                    ...state.members,
                    params: {
                        ...state.params,
                    },
                },
            };
        },
        [TEAM_MANAGEMENT_INIT_TEAMS]: (state) => {
            return {
                ...state,
                params: {
                    keywordType: "name",
                    keyword: "",
                    offset: 0,
                    limit: 50,
                },
                teams: [],
            };
        },
        [TEAM_MANAGEMENT_GET_TEAM_ROLES_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                pending: false,
                error: false,
                roles: {
                    ...state.roles,
                    totalCount: data.results?.length,
                    results: data.results,
                },
            };
        },
        [TEAM_MANAGEMENT_GET_TEAM_USERS_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                pending: false,
                error: false,
                members: {
                    ...state.members,
                    totalCount: data.totalCount,
                    results: data.results,
                },
            };
        },
        [TEAM_MANAGEMENT_ASSIGN_USER_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                pending: false,
                error: false,
            };
        },
        [TEAM_MANAGEMENT_UNASSIGN_USER_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
            };
        },
        [TEAM_MANAGEMENT_GET_TEAM_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                pending: false,
                error: false,
                team: data,
            };
        },
        [TEAM_MANAGEMENT_GET_TEAM_MEMBERS_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                pending: false,
                error: false,
                members: {
                    ...state.members,
                    totalCount: data.totalCount,
                    results: data.results,
                },
            };
        },
        [TEAM_MANAGEMENT_UPDATE_ROLES_PARAMS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
                roles: {
                    ...state.roles,
                    params: {
                        ...state.roles.params,
                        ...action.payload,
                    },
                },
            };
        },
        [TEAM_MANAGEMENT_UPDATE_MEMBERS_PARAMS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
                members: {
                    ...state.members,
                    params: {
                        ...state.members.params,
                        ...action.payload,
                    },
                },
            };
        },
        [TEAM_MANAGEMENT_UPDATE_PROJECT_TEAM_SUCCESS]: (state, action) => {
            const payload = action.payload;
            const existingTeams = state.teams;
            const temp = {
                ...state,
                team: {
                    ...state.team,
                    ...payload,
                },
                pending: false,
                error: false,
            };
            if (existingTeams) {
                temp.teams = existingTeams.map((v) => {
                    if (v.id === payload.id) {
                        return {
                            ...v,
                            ...payload,
                        };
                    }
                    return v;
                });
            }
            return temp;
        },
        [TEAM_MANAGEMENT_UPDATE_DETAIL_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
                team: {
                    ...state.team,
                    ...action.payload,
                },
            };
        },
    },
    initialState,
);

export const updateParams = createAction(TEAM_MANAGEMENT_UPDATE_TEAMS_PARAMS);
export const resetParams = createAction(TEAM_MANAGEMENT_RESET_TEAMS_PARAMS_RESET);
export const resetMemberParams = createAction(TEAM_MANAGEMENT_RESET_MEMBERS_PARAMS_RESET);
export const updateRoleParams = createAction(TEAM_MANAGEMENT_UPDATE_ROLES_PARAMS);
export const updateMemberParams = createAction(TEAM_MANAGEMENT_UPDATE_MEMBERS_PARAMS);
export const clear = createAction(TEAM_MANAGEMENT_RESET);

export const getProjectTeams = (params) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: GET_TEAM_MANAGEMENT_PENDING });

    return getProjectTeamsAPI(stage.id, stage.endpoint, project.id, params)
        .then((response) => {
            dispatch({
                type: TEAM_MANAGEMENT_GET_TEAMS_SUCCESS,
                payload: response,
            });

            return response && response.data;
        })
        .catch((error) => {
            dispatch({
                type: GET_TEAM_MANAGEMENT_FAILURE,
                payload: error,
            });
        });
};

export const getTeams = (params, projectId) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: GET_TEAM_MANAGEMENT_PENDING });

    return getTeamsAPI(stage.id, stage.endpoint, projectId, params)
        .then((response) => {
            dispatch({
                type: TEAM_MANAGEMENT_GET_TEAMS_SUCCESS,
                payload: response,
            });

            return response && response.data;
        })
        .catch((error) => {
            dispatch({
                type: GET_TEAM_MANAGEMENT_FAILURE,
                payload: error,
            });
        });
};

export const getTeamRoles =
    ({ projectId, id, params }) =>
    (dispatch, getState) => {
        const { stage } = getState();

        dispatch({ type: TEAM_MANAGEMENT_PENDING });

        return getTeamRolesAPI(stage.id, stage.endpoint, projectId, params)
            .then((response) => {
                dispatch({
                    type: TEAM_MANAGEMENT_GET_TEAM_ROLES_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: TEAM_MANAGEMENT_FAILURE,
                    payload: error,
                });
            });
    };
export const getTeam =
    ({ id, projectId }) =>
    (dispatch, getState) => {
        const { stage } = getState();

        dispatch({ type: TEAM_MANAGEMENT_PENDING });

        return getTeamAPI(stage.id, stage.endpoint, projectId, id)
            .then((response) => {
                dispatch({
                    type: TEAM_MANAGEMENT_GET_TEAM_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: TEAM_MANAGEMENT_FAILURE,
                    payload: error,
                });
            });
    };
export const getTeamMembers =
    ({ id, params, projectId }) =>
    (dispatch, getState) => {
        const { stage } = getState();

        dispatch({ type: TEAM_MANAGEMENT_PENDING });

        return getTeamMembersAPI(stage.id, stage.endpoint, projectId, id, params)
            .then((response) => {
                dispatch({
                    type: TEAM_MANAGEMENT_GET_TEAM_MEMBERS_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: TEAM_MANAGEMENT_FAILURE,
                    payload: error,
                });
            });
    };

export const updateProjectTeam = (projectId, teamId, body) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: TEAM_MANAGEMENT_PENDING });
    return patchProjectTeamAPI(stage.id, stage.endpoint, projectId, teamId, body)
        .then(async () => {
            const response = await getProjectTeamAPI(stage.id, stage.endpoint, projectId, teamId);
            dispatch({
                type: TEAM_MANAGEMENT_UPDATE_PROJECT_TEAM_SUCCESS,
                payload: {
                    ...response.data,
                    id: teamId,
                },
            });
        })
        .catch((error) => {
            dispatch({
                type: TEAM_MANAGEMENT_FAILURE,
                payload: error,
            });
        });
};

// Project Settings
export const getProjectTeamDetail =
    ({ id, projectId }) =>
    (dispatch, getState) => {
        const { stage } = getState();

        dispatch({ type: TEAM_MANAGEMENT_PENDING });

        return getProjectTeamAPI(stage.id, stage.endpoint, projectId, id)
            .then((response) => {
                dispatch({
                    type: TEAM_MANAGEMENT_GET_TEAM_SUCCESS,
                    payload: response,
                });
                return response?.data;
            })
            .catch((error) => {
                dispatch({
                    type: TEAM_MANAGEMENT_FAILURE,
                    payload: error,
                });
            });
    };
export const getProjectTeamMembers =
    ({ id, params, projectId }) =>
    (dispatch, getState) => {
        const { stage } = getState();

        dispatch({ type: TEAM_MANAGEMENT_PENDING });

        return getProjectTeamMembersAPI(stage.id, stage.endpoint, projectId, id, params)
            .then((response) => {
                dispatch({
                    type: TEAM_MANAGEMENT_GET_TEAM_MEMBERS_SUCCESS,
                    payload: response,
                });
                return response?.data;
            })
            .catch((error) => {
                dispatch({
                    type: TEAM_MANAGEMENT_FAILURE,
                    payload: error,
                });
            });
    };

export const getProjectTeamRoles =
    ({ projectId, id, params }) =>
    (dispatch, getState) => {
        const { stage } = getState();

        dispatch({ type: TEAM_MANAGEMENT_PENDING });

        return getProjectTeamRolesAPI(stage.id, stage.endpoint, projectId, id, params)
            .then((response) => {
                dispatch({
                    type: TEAM_MANAGEMENT_GET_TEAM_ROLES_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: TEAM_MANAGEMENT_FAILURE,
                    payload: error,
                });
            });
    };

export const createNewTeam =
    ({ projectId, params }) =>
    (dispatch, getState) => {
        const { stage } = getState();
        return createNewTeamAPI(stage.id, stage.endpoint, projectId, params);
    };

export const deleteTeam =
    ({ projectId, id }) =>
    (dispatch, getState) => {
        const { stage } = getState();
        return deleteTeamAPI(stage.id, stage.endpoint, projectId, id);
    };

export const updateTeam =
    ({ projectId, id, data }) =>
    (dispatch, getState) => {
        const { stage } = getState();

        dispatch({ type: TEAM_MANAGEMENT_PENDING });
        return updateTeamAPI(stage.id, stage.endpoint, projectId, id, data)
            .then((response) => {
                dispatch({
                    type: TEAM_MANAGEMENT_UPDATE_DETAIL_SUCCESS,
                    payload: data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: TEAM_MANAGEMENT_FAILURE,
                    payload: error,
                });
            });
    };

export const getTeamUsersV2 = (id, params) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: TEAM_MANAGEMENT_PENDING });

    return getTeamUsersAPI(stage.id, stage.endpoint, id, params)
        .then((response) => {
            dispatch({
                type: TEAM_MANAGEMENT_GET_TEAM_USERS_SUCCESS,
                payload: response,
            });
            return response?.data;
        })
        .catch((error) => {
            dispatch({
                type: TEAM_MANAGEMENT_FAILURE,
                payload: error,
            });
        });
};

export const getTeamAssignableUsers = (id, params) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: TEAM_MANAGEMENT_PENDING });

    return getTeamAssignableUsersAPI(stage.id, stage.endpoint, id, params)
        .then((response) => {
            dispatch({
                type: TEAM_MANAGEMENT_GET_TEAM_USERS_SUCCESS,
                payload: response,
            });
            return response?.data;
        })
        .catch((error) => {
            dispatch({
                type: TEAM_MANAGEMENT_FAILURE,
                payload: error,
            });
        });
};
export const assignUser = (id, params) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: TEAM_MANAGEMENT_PENDING });
    return assignUserAPI(stage.id, stage.endpoint, id, params)
        .then((response) => {
            dispatch({
                type: TEAM_MANAGEMENT_ASSIGN_USER_SUCCESS,
            });
        })
        .catch((error) => {
            dispatch({
                type: TEAM_MANAGEMENT_FAILURE,
                payload: error,
            });
        });
};

export const unassignUser = (id, memberId) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: TEAM_MANAGEMENT_PENDING });
    return unassignUserAPI(stage.id, stage.endpoint, id, memberId)
        .then((response) => {
            dispatch({
                type: TEAM_MANAGEMENT_UNASSIGN_USER_SUCCESS,
                payload: memberId,
            });
        })
        .catch((error) => {
            dispatch({
                type: TEAM_MANAGEMENT_FAILURE,
                payload: error,
            });
        });
};
