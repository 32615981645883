import { handleActions, createAction } from "redux-actions";
import fp from "lodash/fp";
import { axios, axiosWithoutHeaders } from "../cores/axiosWrapper";

import { UNCATEGORIZED_ID } from "../constants";

import { getCategoriesAPI } from "./apis/category";

import { getCategoryGroupAPI } from "./apis/categoryGroup";

import { getSettingsAPI } from "./apis/setting";

//action type
const CATEGORY_TREE_VIEW_PENDING = "CATEGORY_TREE_VIEW_PENDING";
const CATEGORY_TREE_VIEW_FAILURE = "CATEGORY_TREE_VIEW_FAILURE";
const GET_CATEGORY_TREE_VIEW_SUCCESS = "GET_CATEGORY_TREE_VIEW_SUCCESS";
const CATEGORY_TREE_UPDATE_FILTER = "CATEGORY_TREE_UPDATE_FILTER";
const CATEGORY_TREE_CHANGE_FOLD = "CATEGORY_TREE_CHANGE_FOLD";
const CATEGORY_TREE_CHANGE_SELECTED_ITEM = "CATEGORY_TREE_CHANGE_SELECTED_ITEM";
const CATEGORY_TREE_CHANGE_SELECTED_BY_LIST = "CATEGORY_TREE_CHANGE_SELECTED_BY_LIST";
const CATEGORY_TREE_CLEAR_SELECTED_BY_LIST = "CATEGORY_TREE_CLEAR_SELECTED_BY_LIST";
const CATEGORY_TREE_CLEAR = "CATEGORY_TREE_CLEAR";
const RESET_SELECTED_CATEGORY = "RESET_SELECTED_CATEGORY";
const UPDATE_SELECTED_CATEGORY = "UPDATE_SELECTED_CATEGORY";
const UPDATE_SELECTED_HISTORY = "UPDATE_SELECTED_HISTORY";

//reducer
const initialState = {
    pending: false,
    error: false,
    data: null,
    filter: {
        isFilter: false,
        data: [],
    },
    categoryMap: [
        {
            id: UNCATEGORIZED_ID,
            name: "Uncategorized Assets",
            isActive: false,
            isSelected: false,
            isEmpty: false,
            isGroup: true,
            isNotCategoryIds: true,
            type: "uncategorized",
            isRoot: true,
        },
    ],
    selectedByListTargetId: null,
    selectedCategory: { selected: null, history: [], index: -1 },
};

export default handleActions(
    {
        [CATEGORY_TREE_VIEW_PENDING]: (state) => {
            return {
                ...state,
                pending: true,
                error: false,
            };
        },
        [CATEGORY_TREE_VIEW_FAILURE]: (state) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [GET_CATEGORY_TREE_VIEW_SUCCESS]: (state, action) => {
            let { category } = action.payload;

            return {
                ...state,
                ...category,
                pending: false,
                error: false,
            };
        },
        [CATEGORY_TREE_CLEAR_SELECTED_BY_LIST]: (state) => {
            return {
                ...state,
                selectedByListTargetId: null,
            };
        },
        [CATEGORY_TREE_UPDATE_FILTER]: (state, action) => {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.payload,
                },
            };
        },
        [CATEGORY_TREE_CHANGE_FOLD]: (state, action) => {
            const temp = {
                ...state,
                data: [].concat(state.data),
            };
            return temp;
        },
        [CATEGORY_TREE_CHANGE_SELECTED_ITEM]: (state, action) => {
            const item =
                action.payload.id === "UCID"
                    ? { id: action.payload.id }
                    : fp.get(`categoryMap[${action.payload.id}]`, state);

            if (item?.parent) {
                setParentItemFold(item.parent, false);

                Object.keys(state.categoryMap).map((v) => {
                    const category = state.categoryMap[v];
                    if (category.isGroup) {
                        const groupId = item.isGroup ? item.id : item.groupId;
                        category.isActive = category.id === groupId ? true : false;
                    }
                });
            }

            let selectedCategory = state.selectedCategory;
            let newSelectedHistory = [...selectedCategory.history];
            let newSelectedIndex = selectedCategory.index;

            if (selectedCategory.index < selectedCategory.history.length) {
                //이전 히스토리 도중 새로운 폴더 탐색
                newSelectedHistory = selectedCategory.history.slice(0, newSelectedIndex + 1);
            }
            newSelectedHistory.push(item);
            newSelectedIndex = newSelectedIndex + 1;

            return {
                ...state,
                selectedCategory: { selected: item, history: newSelectedHistory, index: newSelectedIndex },
            };
        },
        [CATEGORY_TREE_CHANGE_SELECTED_BY_LIST]: (state, action) => {
            return {
                ...state,
                selectedByListTargetId: action.payload,
            };
        },
        [CATEGORY_TREE_CLEAR]: (state, action) => {
            return {
                ...initialState,
            };
        },
        [RESET_SELECTED_CATEGORY]: (state, action) => {
            return {
                ...state,
                selectedCategory: { selected: null, history: [], index: -1 },
            };
        },
        [UPDATE_SELECTED_CATEGORY]: (state, action) => {
            return {
                ...state,
                selectedCategory: {
                    ...state.selectedCategory,
                    selected: state.selectedCategory.history[state.selectedCategory.index + action.payload],
                    index: state.selectedCategory.index + action.payload,
                },
            };
        },
    },
    initialState,
);

const setParentItemFold = (item, isFold) => {
    if (!item) return;

    item.isFold = isFold;

    if (item.parent) setParentItemFold(item.parent, isFold);
};

export const updateFilter = createAction(CATEGORY_TREE_UPDATE_FILTER);
export const changeFold = createAction(CATEGORY_TREE_CHANGE_FOLD);
export const changeSelectedItem = createAction(CATEGORY_TREE_CHANGE_SELECTED_ITEM);
export const changeSelectedByList = createAction(CATEGORY_TREE_CHANGE_SELECTED_BY_LIST);
export const resetSelectedCategory = createAction(RESET_SELECTED_CATEGORY);
export const updateSelectedHistory = createAction(UPDATE_SELECTED_CATEGORY);
export const clearSelectedByList = createAction(CATEGORY_TREE_CLEAR_SELECTED_BY_LIST);
export const clear = createAction(CATEGORY_TREE_CLEAR);

export const initCategoryData = (projectId, resourceType) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: CATEGORY_TREE_VIEW_PENDING });

    const settingParams = {
        resource: resourceType,
        key: "categoryGroupId",
    };

    return new Promise((resolve, reject) => {
        getSettingsAPI(stage.id, stage.endpoint, projectId, settingParams)
            .then((response) => {
                const categoryGroupId = response.data[resourceType].categoryGroupId.value;
                getCategoryGroupAPI(stage.id, stage.endpoint, projectId, categoryGroupId)
                    .then((response) => {
                        const categoryGroupResponse = response.data;
                        getCategoriesAPI(stage.id, stage.endpoint, projectId, categoryGroupId)
                            .then((response) => {
                                const categories = response.data;

                                let category = {
                                    isFilter: false,
                                    data: [],
                                    selected: {},
                                };

                                const categoryGroup = initCategoryGroup(
                                    categoryGroupResponse,
                                    categories,
                                    fp.getOr(UNCATEGORIZED_ID, "categoryTreeView.selected.id", getState()),
                                );

                                category = {
                                    ...category,
                                    ...categoryGroup,
                                };

                                dispatch({
                                    type: GET_CATEGORY_TREE_VIEW_SUCCESS,
                                    payload: {
                                        category,
                                    },
                                });

                                resolve(category);
                            })
                            .catch((error) => {
                                dispatch({
                                    type: CATEGORY_TREE_VIEW_FAILURE,
                                    payload: error,
                                });
                                reject(null);
                            });
                    })
                    .catch((error) => {
                        dispatch({
                            type: CATEGORY_TREE_VIEW_FAILURE,
                            payload: error,
                        });
                        reject(null);
                    });
            })
            .catch((error) => {
                dispatch({
                    type: CATEGORY_TREE_VIEW_FAILURE,
                    error,
                });
                reject(null);
            });
    });
};

function initCategoryGroup(categoryGroupResponse, categories, selectedCategoryId = UNCATEGORIZED_ID) {
    const category = {
        data: [],
        selected: {},
    };

    let categoryGroup = {
        id: `${categoryGroupResponse.id}`,
        code: `${categoryGroupResponse.id}`,
        children: [],
        name: categoryGroupResponse.name,
        isActive: false,
        isSelected: false,
        isEmpty: false,
        isFold: false,
        isGroup: true,
        type: "categoryGroup",
        isRoot: true,
    };

    let uncategorized = {
        id: UNCATEGORIZED_ID,
        name: "Uncategorized Assets",
        isActive: false,
        isSelected: false,
        isEmpty: false,
        isGroup: true,
        isNotCategoryIds: true,
        type: "uncategorized",
        isRoot: true,
    };

    category.data.push(categoryGroup);
    category.data.push(uncategorized);

    const itemMap = { [categoryGroup.id]: categoryGroup, [uncategorized.id]: uncategorized };
    category.categoryMap = itemMap;
    for (let i = 0; i < categories.categories.length; i++) {
        let item = categories.categories[i];
        const code = item.code.split("_");

        item = {
            ...item,
            parent: code.length >= 3 ? { id: code[code.length - 3] } : { id: categoryGroup.id },
            id: `${item.id}`,
            code: `${categoryGroup.id}_${item.code.replace(/_$/g, "")}`,
            children: [],
            isGroup: false,
            isEmpty: true,
            isFold: false,
            isSelected: false,
            type: "category",
            // root: categoryGroup,
            isRoot: false,
            groupId: categoryGroup.id,
        };

        itemMap[item.id] = item;

        if (selectedCategoryId === item.id) {
            // item.root.isActive = true;
            item.isSelected = true;
            categoryGroup.isActive = true;
            category.selected = item;
        }

        if (item.depth === 1) {
            categoryGroup.children.push(item);
        } else {
            if (item.hasOwnProperty("parent")) {
                const code = item.code.split("_");
                createCategories(code[0], category.data, item);

                function createCategories(ele, category, item) {
                    let node = category.find((obj) => obj.id == ele);

                    if (!node) return;

                    if (ele == item.parent.id) {
                        node.isEmpty = false;
                        node.children.push(item);
                    } else {
                        if (code && code.length > 1) {
                            code.splice(0, 1);
                            createCategories(code[0], node.children, item);
                        }
                    }
                }
            }
        }
    }

    if (selectedCategoryId === categoryGroup.id) {
        categoryGroup.isActive = true;
        if (!fp.isEmpty(categoryGroup.children)) {
            category.selected = categoryGroup && categoryGroup.item && categoryGroup.item[0];
        } else {
            category.selected = categoryGroup;
        }
        category.isSelected = true;
    }
    if (selectedCategoryId === uncategorized.id) {
        uncategorized.isSelected = true;
        uncategorized.isActive = true;
        category.selected = uncategorized;
    }

    return category;
}

// function convertToNavView (item) {
//     const reversedNavView = [];
//     const genNav = (acc, item_) => {
//         acc.push(item_);
//         if (item_.parent) genNav(acc, item_.parent);
//     };
//     genNav(reversedNavView, item);
//     const navView = reversedNavView.reverse();
//     return navView;
// }
