// import styled, {css} from 'styled-components';
import {styled, alpha} from '@mui/system';

/* components */
import {
    Popover as MuiPopover,
    MenuItem as MuiMenuItem,
} from '@mui/material';



export const Popover = styled(MuiPopover)`
    margin-top: 4px;
`;

export const MenuItem = styled(MuiMenuItem)`
  font-size: 14px;
  width: 100%;
  padding: 6px 20px;
  &:hover {
    background-color: ${(props) => props.theme.palette.secondary[800]}14;
  }
  &:active {
    background-color: ${(props) => props.theme.palette.secondary.A100}80;
  }
`;
