import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const PageHeaderInfo = (props) => {
    const { className, ...otherProps } = props;
    return <div {...otherProps} className={clsx("page-header-info", className)} />;
};
PageHeaderInfo.propTypes = {
    className: PropTypes.string,
};

export default PageHeaderInfo;
