import React from "react";
import { VERSION_CM_8075_SHARE_REQUEST, VERSION_CM_8742_ASSET_SHARE_REQUEST } from "@cores/version";
import ButtonOutlineDark from "@components/buttons/ButtonOutlineDark";
import { Trans, useTranslation } from "react-i18next";
import VersionedComponent from "@components/VersionedComponent";
import { PROPERTY_PANEL_TYPE } from "@constants";
import { useSelector } from "react-redux";

const PanelPermissionError = (props) => {
    const { t } = useTranslation();
    const { handleShareRequestModal } = props;

    const { propertyPanel } = useSelector(({ propertyPanel }) => ({
        propertyPanel,
    }));

    return (
        <div className={"box-permission"}>
            <div>
                <i className="sprite sprite-error-permission" />
            </div>
            <div>
                <strong className={"text-large"}>{t(`common::msg::You need access`)}</strong>
                <p>
                    <span>
                        {propertyPanel.type === PROPERTY_PANEL_TYPE.SHARED_STORAGE_WITH ? (
                            <Trans
                                i18nKey="common::msg::The sharing period for this file has expired. <0/> If you want to check the file you have selected, <0/> Please ask the sharer to share."
                                components={[<br />]}
                            />
                        ) : (
                            <Trans
                                i18nKey="common::msg::If you want to check the assets you have selected, <0/> please ask for access."
                                components={[<br />]}
                            />
                        )}
                    </span>
                </p>
            </div>
            {propertyPanel.type === PROPERTY_PANEL_TYPE.STORAGE && (
                <VersionedComponent version={VERSION_CM_8075_SHARE_REQUEST}>
                    <ButtonOutlineDark onClick={handleShareRequestModal}>
                        <span>{t(`common::label::${"Share Request"}`)}</span>
                        <i className={"sprite sprite-link"} />
                    </ButtonOutlineDark>
                </VersionedComponent>
            )}
            {propertyPanel.type === PROPERTY_PANEL_TYPE.ASSET && (
                <VersionedComponent version={VERSION_CM_8742_ASSET_SHARE_REQUEST}>
                    <ButtonOutlineDark onClick={handleShareRequestModal}>
                        <span>{t(`common::label::${"Share Request"}`)}</span>
                        <i className={"sprite sprite-link"} />
                    </ButtonOutlineDark>
                </VersionedComponent>
            )}
        </div>
    );
};
export default PanelPermissionError;
