import {styled, Grid as MuiGrid, alpha, Card as MuiCard} from '@mui/material';
import theme from "@styles/theme";


export const StyledAssetTypeThumbnail = styled('div', {
    shouldForwardProp: prop => !['variant', 'status'].includes(String(prop)),
})(({ theme, variant, status }) => ({
    position: `relative`,
    display: `inline-flex`,
    alignItems: `center`,
    justifyContent: `center`,
    backgroundColor: alpha(theme.palette.greySecondary[50], 0.5),
    borderRadius: `4px`,
    boxSizing: `border-box`,
    ...(variant === 'card' && {
        padding:  `12px`,
        borderRadius: `8px`,
        ...(status === 'INACTIVE' || status === 'inactive' && {
            border: `1px dashed ${theme.palette.secondary[600]}80`,
        }),
        '&:hover': {
            backgroundColor:  theme.palette.greySecondary[50],
        },
    }),
    'img': {
        maxWidth: `100%`,
        maxHeight: `100%`,
        objectFit: `cover`,
    }
}));

export const ImagePreviewButton = styled('button')(({ theme }) => ({
    display:`flex`,
    flexDirection: `column`,
    justifyContent: `center`,
    alignItems:`center`,
    position: `absolute`,
    border:`none`,
    borderRadius: `100%`,
    backgroundColor: alpha(theme.palette.common.black, 0.3),
    '.MuiBox-root': {
        display: `flex`,
        alignItems:`center`,
        justifyContent:`center`,
        width: `16px`,
        height: `16px`,
        padding:` 3px`,
        borderRadius: `16px`,
        border: `1px solid ${theme.palette.common.white}`,
        backgroundColor:  alpha(theme.palette.common.black, 0.6),
    },
    '.MuiTypography-root': {
        margin: `2px 0 0`,
        fontSize: `11px`,
        fontWeight: `bold`,
        lineHeight: `1.45`,
    }
}));

export const StyledAssetTypeFlag = styled('div')(({ theme }) => ({
    display: `inline-flex`,
    alignItems: `center`,
    justifyContent: `center`,
    borderRadius: `2px`,
    marginRight: `8px`,
}));

