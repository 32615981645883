import React from "react";
import { withTranslation } from "react-i18next";
import InactiveByErrorTooltip from "../tooltip/InactiveByErrorTooltip";

class StatusBadge extends React.Component {
    constructor(props) {
        super(props);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.status !== this.props.status;
    }

    toDisplayName(status) {
        const { t, i18n } = this.props;

        switch (status) {
            case "INACTIVE_BY_ERROR":
                return t(`common::label::INACTIVE`);
            case "PREPARING":
                return t(`common::label::PREPARING`);
            case "UPLOADING":
                return t(`common::label::UPLOADING`, "UPLOADING");
            case "COMPLETED":
                return t(`common::label::COMPLETE`, "COMPLETE");
            case "SUBMITED":
                return t(`common::label::SUBMITED`, "SUBMITED");
            default:
                return t(`common::label::${status}`, status);
        }
    }

    // TODO : 기존 Message 필요한 이슈 등록 시, 분기 처리해서 사용
    getStatusErrorMessage(desiredStatus) {
        const { t, i18n } = this.props;
        /*
            if(desiredStatus === 'ACTIVE') {
                return t(`common::msg::${"Error while activating"}`);
            }
            else if(desiredStatus === 'INACTIVE') {
                return t(`common::msg::${"Error while inactivating"}`);
            }
            return t(`common::msg::${"Inactivated by error"}`);
        */
        return t(`common::msg::An attempts to change the status from 'Active' to 'Inactive' failed. Please try again.`);
    }

    render() {
        const { t, i18n, desiredStatus } = this.props;

        let badgeClass = "";
        let spriteClass = "";

        let status = this.props.status;

        if (status === "SUCCEED") {
            status = "COMPLETE";
        } else if (status === "FAILED") {
            status = "ERROR";
        } else if (status === "RUNNING") {
            status = "PROGRESSING";
        }

        switch (status) {
            case "COMPLETED":
            case "COMPLETE":
            case "SUCCEED":
            case "YES":
                badgeClass = "status-text status-text-complete";
                spriteClass = "sprite sprite-complete";
                break;

            case "Create":
                badgeClass = "status-text status-text-create";
                spriteClass = "sprite sprite-create";
                break;

            case "ACTIVATING":
            case "PROCESSING":
            case "PROGRESSING":
                badgeClass = "status-text status-text-progressing";
                spriteClass = "sprite sprite-progressing";
                break;

            case "RUNNING":
                badgeClass = "status-text status-text-progressing";
                spriteClass = "sprite sprite-progressing";
                break;

            case "UPLOADING":
                badgeClass = "status-text status-text-progressing";
                spriteClass = "sprite sprite-progressing";
                break;
            case "PREPARING":
                badgeClass = "status-text status-text-starting";
                spriteClass = "sprite sprite-ingesting";
                break;
            case "READY":
                badgeClass = "status-text status-text-ready";
                spriteClass = "sprite sprite-ready";
                break;
            case "ERROR":
            case "FAILED":

            // TODO : 상태 변경 가능
            case "NOT_READY":
                badgeClass = "status-text status-text-error";
                spriteClass = "sprite sprite-error";
                break;
            case "NOMEDIA":
                badgeClass = "status-text status-text-no-media";
                spriteClass = "sprite sprite-nomedia";
                break;
            case "ACTIVE":
                badgeClass = "status-text status-text-active";
                spriteClass = "sprite sprite-active";
                break;
            case "INACTIVE":
            case "INACTIVE_BY_ERROR":
                badgeClass = "status-text status-text-inactive";
                spriteClass = "sprite sprite-inactive";
                break;
            case "INACTIVATING":
            case "INGESTING":
                badgeClass = "status-text status-text-ingesting";
                spriteClass = "sprite sprite-ingesting";
                break;
            case "PREPARING":
                badgeClass = "status-text status-text-ingested";
                spriteClass = "sprite sprite-ingested";
                break;
            case "INGESTED":
                badgeClass = "status-text status-text-ingested";
                spriteClass = "sprite sprite-ingested";
                break;
            case "SUBMITED":
            case "SUBMITTED":
                badgeClass = "status-text status-text-submitted";
                spriteClass = "sprite sprite-progressing";
                break;
            case "CANCELED":
            case "NO":
                badgeClass = "status-text status-text-canceled";
                spriteClass = "sprite sprite-canceled";
                break;
            case "UNUSED":
                badgeClass = "status-text status-text-canceled";
                spriteClass = "sprite sprite-canceled";
                break;
            case "USED":
                badgeClass = "status-text status-text-complete";
                spriteClass = "sprite sprite-complete";
                break;
            default:
                badgeClass = "status-text status-text-default";
                break;
        }

        return (
            <div className={`${badgeClass}`}>
                <i className={`${spriteClass}`}></i>
                <span className={"text"}>{this.toDisplayName(status)}</span>
                {this.props.status === "INACTIVE_BY_ERROR" && (
                    <InactiveByErrorTooltip message={this.getStatusErrorMessage(desiredStatus)} />
                )}
            </div>
        );
    }
}

export default withTranslation()(StatusBadge);
