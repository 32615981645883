import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { NavLink as NavLinkBase, useHistory, useLocation } from "react-router-dom";
import { IncreaseRequestCount, setPrevPath } from "@modules/global";
import { initParams } from "@modules/channels";
import { closeModal } from "@modules/createAssetsBulk";
import config from "@config";
import compareVersions, {
    VERSION_CM_10137_CUSTOM_CONTENT_SCHEMAS,
    VERSION_CM_10269_CUSTOM_CONTENTS,
    VERSION_CM_11209_ADD_CUSTOM_METADATA,
    VERSION_CM_11376_SHARES_V2,
    VERSION_CM_4601_MUSIC_CONTENTS,
    VERSION_CM_4601_PHOTO_CONTENTS,
    VERSION_CM_4604_SITE_ACCESS,
    VERSION_CM_5058_DASHBOARD,
    VERSION_CM_5112_PROJECT_SITE_ACCESS,
    VERSION_CM_6839_STORAGE_SYNC_STATUS_CHECK,
    VERSION_CM_8075_SHARE_REQUEST,
    VERSION_CM_8558_METADATA_TEMPLATE,
    VERSION_CM_8742_ASSET_SHARE_REQUEST,
    VERSION_CM_8998_ASSET_ARCHIVE,
    VERSION_CM_9766_COLLECTIONS,
} from "@cores/version";
import equal from "deep-equal";
import { closeNavigator, openMenu, openNavigator, setMenu, toggleMenu } from "@modules/navigator";
import {
    IconArrowBack,
    IconArrowCircleRight,
    IconArrowForward,
    IconAssets,
    IconBack,
    IconCategory,
    IconChevronDown,
    IconChevronUp,
    IconCircle,
    IconContents,
    IconDashboard,
    IconEventtriggers,
    IconGroup,
    IconInteractiveVideo,
    IconJobs,
    IconLive,
    IconMeetings,
    IconMenu,
    IconMetadata,
    IconSchema,
    IconSettingAdmin,
    IconSettingProject,
    IconShared,
    IconStorage,
    IconUser,
} from "@mzc-cloudplex/icons";

import { isDev, isLocal, isQA, isStage } from "@cores/enviromentExtension";
import SelectProject from "./components/SelectProject";
import menuService from "@services/menuService";
import {
    Box,
    Collapse,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Tooltip,
    Typography,
} from "@mzc-pdc/ui";
import { BackToConsoleButton, Drawer, ProjectSettingButton } from "./nav.styled";
import theme from "@styles/theme";
import { alpha, useMediaQuery } from "@mui/material";
import { getContentSchemaDetail, getContentSchemas } from "@modules/navigator/navigator";
import { NavigatorMenuSkeleton, NavigatorSubMenuSkeleton } from "@components/navigator/navigator-skeleton";
import { useInView } from "react-intersection-observer";
import useSettings from "@hooks/useSettings";
import { MENU_LIST } from "@constants";

const NavLink = React.forwardRef((props, ref) => (
    <NavLinkBase
        ref={ref}
        {...props}
        className={({ isActive }) => `${props.className} ${isActive ? props.activeClassName : ""}`}
    />
));
const Navigator = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslation();
    const { navigatorMenus } = useSettings({ type: "General" });
    const { getNavigatorMenuName, navigatorMenusName } = useSettings({ type: "MenuName" });
    const LIMIT = 20;
    const isContentSchemaIdChecked = useRef();

    const [menuType, setMenuType] = useState("console");
    const { spaceId, projectId } = menuService.parse(location.pathname);
    const [navSwitch, setNavSwitch] = useState(null);
    const [activeStage, setActiveStage] = useState(null);
    const [activeProject, setActiveProject] = useState(null);
    const [navOpen, setNavOpen] = useState(true);
    const [isShown, setIsShown] = useState(false);
    const [nextToken, setNextToken] = useState(null);

    const [targetRef, inView] = useInView();

    const global = useSelector(({ global }) => global);
    const user = useSelector(({ user }) => user);
    const project = useSelector(({ project }) => project);
    const menus = useSelector(({ navigator }) => navigator.menus);
    const projectMenus = useSelector(({ navigator }) => navigator.projectMenus);
    const active = useSelector(({ navigator }) => navigator.active);
    const stage = useSelector(({ stage }) => stage);
    const contentSchemas = useSelector(({ navigator }) => navigator.contents);

    const currentMenu = menus.find((item) => location.pathname.indexOf(item.path) !== -1);
    const [selectedMenu, setSelectedMenu] = useState(currentMenu?.id);

    const upMatches = useMediaQuery(theme.breakpoints.up("xl"));

    const loadContentSchemas = async (token) => {
        try {
            if (contentSchemas.pending) return;

            const params = {
                limit: LIMIT,
                status: "ACTIVE",
                nextToken: token,
            };
            if (compareVersions(stage.version, VERSION_CM_11209_ADD_CUSTOM_METADATA) >= 0) params.type = "CONTENTS";
            const nextToken = await dispatch(getContentSchemas({ projectId: project.id, params }));

            setNextToken(nextToken);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (stage && stage.version && compareVersions(stage.version, VERSION_CM_10269_CUSTOM_CONTENTS) < 0) return;
        if (inView) {
            loadContentSchemas(nextToken);
        }
    }, [inView, targetRef]);

    useEffect(() => {
        const { spaceId, projectId } = menuService.parse(location.pathname);

        if (user && user.stages.data) {
            const activeStage = user.stages.data.filter((v) => v.id === stage.id)[0];
            if (activeStage) {
                const activeProject = activeStage.projects
                    ? activeStage.projects.filter((v) => v.id === project.id)[0]
                    : null;
                setActiveStage(activeStage);
                setActiveProject(activeProject);
            }
        }

        // loadContentSchemas();
        bindMenus();

        window.addEventListener("resize", resizingHandler);

        return () => {
            window.removeEventListener("resize", resizingHandler);
        };
    }, []);

    useEffect(() => {
        bindMenus();
    }, [navigatorMenus]);

    useEffect(() => {
        if (user && user.stages.data) {
            const activeStage = user.stages.data.filter((v) => v.id === stage.id)[0];
            if (activeStage) {
                const activeProject = activeStage.projects
                    ? activeStage.projects.filter((v) => v.id === project.id)[0]
                    : null;

                setActiveStage(activeStage);
                setActiveProject(activeProject);
                // setShowStageLayer(false);
            }

            if (
                menuType !== "admin" &&
                stage?.version &&
                compareVersions(stage.version, VERSION_CM_10269_CUSTOM_CONTENTS) >= 0
            ) {
                loadContentSchemas();
                setNextToken(null);
            }
        }
    }, [user, project]);

    useEffect(() => {
        const newMenuType = location.pathname.split("/").filter((s) => s !== "")[0]; //ADMIN 페이지 여부

        //content schema 상세 조회 해서 404이면 not found/ 아니면, 목록에 추가
        if (!equal(menuType, newMenuType)) {
            setMenuType(newMenuType);
            dispatch(toggleMenu(menuType));
        }

        if (
            newMenuType !== "admin" &&
            stage &&
            stage.version &&
            compareVersions(stage.version, VERSION_CM_10269_CUSTOM_CONTENTS) >= 0
        ) {
            loadContentSchemas(nextToken);
        }
    }, [navSwitch]);

    useEffect(() => {
        if (location.pathname.indexOf(`/contents`) === -1) return;
        if (!contentSchemas.data || isContentSchemaIdChecked.current) return;

        const schemaId = location.pathname.split("/")?.[6];
        if (["videos", "people", "music", "photos"].includes(schemaId)) return;

        const isExisted = contentSchemas.data?.find((el) => el.id === schemaId);

        if (stage && stage.version && compareVersions(stage.version, VERSION_CM_10269_CUSTOM_CONTENTS) >= 0) {
            if (!isExisted) dispatch(getContentSchemaDetail({ projectId, schemaId }));
        }

        isContentSchemaIdChecked.current = true;
    }, [contentSchemas.data]);

    useEffect(() => {
        setSelectedMenu(currentMenu?.id);
    }, [location]);

    const navSwitchMenu = () => {
        setNavSwitch(!navSwitch);
    };

    const bindMenus = () => {
        const hideLive = stage && stage.extensions && stage.extensions["HIDE_LIVE"] === "true";
        const hideContents = stage && stage.extensions && stage.extensions["HIDE_CONTENTS"] === "true";
        const firstMenu = location.pathname.split("/")[1];
        if (!firstMenu) {
            history.push("/");
        }
        const projectMenus = buildProjectMenus();
        dispatch(
            setMenu({
                projectMenus,
                active: true,
            }),
        );

        let menus = buildMenus({ hideLive, hideContents });
        if (isLocal || isDev || isStage || isQA) {
            menus = menus.concat(buildDemoMenus());
        }
        if (!hideContents) {
            menus.push({
                id: "JOBS",
                name: getNavigatorMenuName("Jobs"),
                icon: <IconJobs size={16} htmlColor={theme.palette.greySecondary.main} />,
                iconClass: "sprite sprite-menu-jobs",
                path: menuService.paths.jobs(spaceId, projectId),
                active: false,
                isOpen: true,
                visible: navigatorMenus?.[MENU_LIST.JOBS]?.visible,
            });
        }
        dispatch(
            setMenu({
                menus: menus.map((v) => {
                    v.active = firstMenu ? v.path.indexOf(firstMenu) !== -1 : v.id === "transcoding";
                    return v;
                }),
                active: true,
            }),
        );
    };

    const buildMenus = ({ hideLive, hideContents }) => {
        // const { location, stage, t } = this.props;
        const stageVersion = stage.version;
        const { spaceId, projectId } = menuService.parse(location.pathname);

        const menus = [];
        if (compareVersions(VERSION_CM_5058_DASHBOARD, stageVersion) <= 0) {
            menus.push({
                id: "DASHBOARD",
                name: getNavigatorMenuName("Dashboard"),
                path: menuService.paths.dashboard(spaceId, projectId),
                icon: <IconDashboard size={16} htmlColor={theme.palette.greySecondary.main} />,
                iconClass: "sprite sprite-menu-dashboard",
                visible: navigatorMenus?.[MENU_LIST.DASHBOARD]?.visible,
            });
        }
        if (compareVersions(VERSION_CM_4604_SITE_ACCESS, stageVersion) <= 0) {
            if (compareVersions(VERSION_CM_11376_SHARES_V2, stageVersion) <= 0) {
                menus.push({
                    id: "STORAGE_MENUS",
                    name: getNavigatorMenuName("Storage"),
                    path: menuService.paths.storage(spaceId, projectId),
                    icon: <IconStorage size={16} htmlColor={theme.palette.greySecondary.main} />,
                    iconClass: "sprite sprite-menu-storage",
                    isOpen: true,
                    visible: navigatorMenus?.[MENU_LIST.STORAGES]?.visible,
                });
            } else {
                menus.push({
                    id: "STORAGE_MENUS",
                    name: getNavigatorMenuName("Storage"),
                    path: menuService.paths.storage(spaceId, projectId),
                    icon: <IconStorage size={16} htmlColor={theme.palette.greySecondary.main} />,
                    iconClass: "sprite sprite-menu-storage",
                    isOpen: true,
                    visible: navigatorMenus?.[MENU_LIST.STORAGES]?.visible,
                    subMenus: [
                        {
                            id: "STORAGE",
                            name: t(`common::label::${"Storage"}`),
                            path: menuService.paths.storage(spaceId, projectId),
                            visible: navigatorMenus?.[MENU_LIST.STORAGES]?.visible,
                        },
                        {
                            id: "STORAGE_SHARED_HISTORIES",
                            name: t(`common::label::${"Shared Histories"}`),
                            path: menuService.paths.storageSharedHistory(spaceId, projectId),
                            disabled: !(compareVersions(VERSION_CM_8075_SHARE_REQUEST, stageVersion) <= 0),
                            visible: navigatorMenus?.[MENU_LIST.STORAGE_SHARED_HISTORIES]?.visible,
                        },
                        {
                            id: "STORAGE_SHARED_BOX",
                            name: t(`common::label::${"Shared Box"}`),
                            path: menuService.paths.storageSharedBox(spaceId, projectId),
                            disabled: !(compareVersions(VERSION_CM_8075_SHARE_REQUEST, stage.version) <= 0),
                            visible: navigatorMenus?.[MENU_LIST.STORAGE_SHARED_BOX]?.visible,
                        },
                    ],
                });
            }
        }

        const assetMenu_v1 = {
            id: "ASSETS_MENU",
            name: getNavigatorMenuName("Assets"),
            icon: <IconAssets size={16} htmlColor={theme.palette.greySecondary.main} />,
            iconClass: "sprite sprite-menu-assets",
            path: menuService.paths.assetsAssets(spaceId, projectId),
            isOpen: true,
            subMenus: [
                {
                    id: "ASSETS",
                    name: t(`common::label::${"Assets"}`),
                    path: menuService.paths.assetsAssets(spaceId, projectId),
                    visible: navigatorMenus?.[MENU_LIST.ASSETS]?.visible,
                },
                {
                    id: "COLLECTIONS",
                    name: t(`common::label::${"Collections"}`, "Collections"),
                    path: menuService.paths.assetsCollections(spaceId, projectId),
                    disabled: !(compareVersions(VERSION_CM_9766_COLLECTIONS, stageVersion) <= 0),
                    visible: navigatorMenus?.[MENU_LIST.COLLECTIONS]?.visible,
                },
                {
                    id: "ASSETS_SHARED_HISTORIES",
                    name: t(`common::label::${"Shared Histories"}`),
                    path: menuService.paths.assetSharedHistory(spaceId, projectId),
                    disabled: !(compareVersions(VERSION_CM_8742_ASSET_SHARE_REQUEST, stageVersion) <= 0),
                    visible: navigatorMenus?.[MENU_LIST.ASSETS_SHARED_HISTORIES]?.visible,
                },
                {
                    id: "ASSETS_SHARED_BOX",
                    name: t(`common::label::${"Shared Box"}`),
                    path: menuService.paths.assetSharedBox(spaceId, projectId),
                    disabled: !(compareVersions(VERSION_CM_8742_ASSET_SHARE_REQUEST, stageVersion) <= 0),
                    visible: navigatorMenus?.[MENU_LIST.ASSETS_SHARED_BOX]?.visible,
                },
                {
                    id: "ARCHIVED",
                    name: t(`common::label::${"Archived"}`),
                    path: menuService.paths.archiveAssets(spaceId, projectId),
                    disabled: !(compareVersions(VERSION_CM_8998_ASSET_ARCHIVE, stageVersion) <= 0),
                    visible: navigatorMenus?.[MENU_LIST.ARCHIVED]?.visible,
                },
            ],
        };
        const assetMenu_v2 = {
            id: "ASSETS_MENU",
            name: getNavigatorMenuName("Assets"),
            icon: <IconAssets size={16} htmlColor={theme.palette.greySecondary.main} />,
            iconClass: "sprite sprite-menu-assets",
            path: menuService.paths.assetsAssets(spaceId, projectId),
            isOpen: true,
            subMenus: [
                {
                    id: "ASSETS",
                    name: t(`common::label::${"Assets"}`),
                    path: menuService.paths.assetsAssets(spaceId, projectId),
                    visible: navigatorMenus?.[MENU_LIST.ASSETS]?.visible,
                },
                {
                    id: "COLLECTIONS",
                    name: t(`common::label::${"Collections"}`, "Collections"),
                    path: menuService.paths.assetsCollections(spaceId, projectId),
                    disabled: !(compareVersions(VERSION_CM_9766_COLLECTIONS, stageVersion) <= 0),
                    visible: navigatorMenus?.[MENU_LIST.COLLECTIONS]?.visible,
                },
                {
                    id: "ARCHIVED",
                    name: t(`common::label::${"Archived"}`),
                    path: menuService.paths.archiveAssets(spaceId, projectId),
                    disabled: !(compareVersions(VERSION_CM_8998_ASSET_ARCHIVE, stageVersion) <= 0),
                    visible: navigatorMenus?.[MENU_LIST.ARCHIVED]?.visible,
                },
            ],
        };
        if (compareVersions(VERSION_CM_11376_SHARES_V2, stageVersion) <= 0) menus.push(assetMenu_v2);
        else menus.push(assetMenu_v1);

        if (compareVersions(VERSION_CM_11376_SHARES_V2, stageVersion) <= 0) {
            if (compareVersions(VERSION_CM_11376_SHARES_V2, stageVersion) <= 0) {
                menus.push({
                    id: "SHARED",
                    name: getNavigatorMenuName("Shared"),
                    path: menuService.paths.shared(spaceId, projectId),
                    icon: <IconShared size={16} htmlColor={theme.palette.greySecondary.main} />,
                    iconClass: "sprite sprite-menu-shared",
                    isOpen: true,
                    subMenus: [
                        {
                            id: "SHARED_HISTORIES",
                            name: t(`common::label::${"Shared Histories"}`),
                            path: menuService.paths.sharedHistory(spaceId, projectId),
                            disabled: !(compareVersions(VERSION_CM_8075_SHARE_REQUEST, stageVersion) <= 0),
                            visible: navigatorMenus?.[MENU_LIST.SHARED_HISTORIES]?.visible,
                        },
                        {
                            id: "SHARED_BOX",
                            name: t(`common::label::${"Shared Box"}`),
                            path: menuService.paths.sharedBox(spaceId, projectId),
                            disabled: !(compareVersions(VERSION_CM_8075_SHARE_REQUEST, stage.version) <= 0),
                            visible: navigatorMenus?.[MENU_LIST.SHARED_BOX]?.visible,
                        },
                    ],
                });
            }
        }
        if (!hideContents) {
            const customContents =
                contentSchemas.data?.map((el) => ({
                    id: el.id,
                    name: el.name,
                    path: menuService.paths.contentsCustomContents(spaceId, projectId, el.id),
                    visible: navigatorMenus?.[MENU_LIST.CUSTOM_CONTENTS]?.visible,
                })) || [];
            menus.push({
                id: "CONTENTS_MENUS",
                name: getNavigatorMenuName("Contents"),
                icon: <IconContents size={16} htmlColor={theme.palette.greySecondary.main} />,
                iconClass: "sprite sprite-menu-media",
                path: menuService.paths.contentsVideos(spaceId, projectId),
                isOpen: true,
                subMenus: [
                    {
                        id: "VIDEOS",
                        name: t(`common::label::${"Videos"}`),
                        path: menuService.paths.contentsVideos(spaceId, projectId),
                        visible: navigatorMenus?.[MENU_LIST.VIDEOS]?.visible,
                    },
                    {
                        id: "PEOPLE",
                        name: t(`common::label::${"People"}`),
                        path: menuService.paths.contentsPeople(spaceId, projectId),
                        visible: navigatorMenus?.[MENU_LIST.PEOPLE]?.visible,
                    },
                    {
                        id: "MUSIC",
                        name: t(`common::label::${"Music"}`),
                        path: menuService.paths.contentsMusic(spaceId, projectId),
                        disabled: !(compareVersions(VERSION_CM_4601_MUSIC_CONTENTS, stageVersion) <= 0),
                        visible: navigatorMenus?.[MENU_LIST.MUSIC]?.visible,
                    },
                    {
                        id: "PHOTO",
                        name: t(`common::label::${"Photo"}`),
                        path: menuService.paths.contentsPhoto(spaceId, projectId),
                        disabled: !(compareVersions(VERSION_CM_4601_PHOTO_CONTENTS, stageVersion) <= 0),
                        visible: navigatorMenus?.[MENU_LIST.PHOTO]?.visible,
                    },
                    ...customContents,
                ],
            });
        }
        if (!hideLive) {
            menus.push({
                id: "LIVE",
                name: getNavigatorMenuName("Live"),
                icon: <IconLive size={16} htmlColor={theme.palette.greySecondary.main} />,
                iconClass: "sprite sprite-menu-live",
                path: menuService.paths.liveStream(spaceId, projectId),
                isOpen: true,
                subMenus: [
                    {
                        id: "STREAMS",
                        name: t(`common::label::${"Streams"}`),
                        path: menuService.paths.liveStream(spaceId, projectId),
                        visible: navigatorMenus?.[MENU_LIST.STREAMS]?.visible,
                    },
                    {
                        id: "SCHEDULES",
                        name: t(`common::label::${"Schedules"}`),
                        path: menuService.paths.liveSchedules(spaceId, projectId),
                        visible: navigatorMenus?.[MENU_LIST.SCHEDULES]?.visible,
                    },
                    {
                        id: "CHANNELS",
                        name: t(`common::label::${"Channels"}`),
                        path: menuService.paths.channels(spaceId, projectId),
                        visible: navigatorMenus?.[MENU_LIST.CHANNELS]?.visible,
                    },
                ],
            });
            if (compareVersions(VERSION_CM_6839_STORAGE_SYNC_STATUS_CHECK, stageVersion) <= 0) {
                menus.push({
                    id: "INTERACTIVE_VIDEOS",
                    name: getNavigatorMenuName("InteractiveVideos"),
                    icon: <IconInteractiveVideo size={16} htmlColor={theme.palette.greySecondary.main} />,
                    iconClass: "sprite sprite-menu-interactivelive",
                    path: menuService.paths.interactiveVideos(spaceId, projectId),
                    visible: navigatorMenus?.[MENU_LIST.INTERACTIVE_VIDEOS]?.visible,
                });
                menus.push({
                    id: "MEETINGS",
                    name: getNavigatorMenuName("Meetings"),
                    icon: <IconMeetings size={16} htmlColor={theme.palette.greySecondary.main} />,
                    iconClass: "sprite sprite-menu-onlinemeeting",
                    path: menuService.paths.meetings(spaceId, projectId),
                    visible: navigatorMenus?.[MENU_LIST.MEETINGS]?.visible,
                });
            }
        }
        return menus;
    };

    // TODO : 콘솔 구현 후, 정합
    const buildDemoMenus = () => {
        // const { t, stage } = this.props;
        return [
            // {
            //     id: "chat",
            //     name: "Chat",
            //     iconClass: "sprite sprite-menu-chat",
            //     path: "/chat",
            //     active: false,
            //     disabled: false,
            // },
        ];
    };

    const buildProjectMenus = () => {
        // const { t, stage } = this.props;
        // const { spaceId, projectId } = this.state;
        const menus = [
            {
                id: "Access",
                name: t(`common::label::${"Access"}`),
                active: false,
                isOpen: true,
                subMenus: [
                    {
                        id: "authorities",
                        name: t(`common::label::${"Users"}`),
                        path: menuService.paths.admin.authoritiesUser(spaceId, projectId),
                        icon: <IconUser size={16} htmlColor={theme.palette.greySecondary.main} />,
                        iconClass: "sprite sprite-menu-user",
                        disabled: false,
                        active: true,
                    },
                    {
                        id: "authorities",
                        name: t(`common::label::${"Groups"}`),
                        icon: <IconGroup size={16} htmlColor={theme.palette.greySecondary.main} />,
                        path: menuService.paths.admin.authoritiesGroup(spaceId, projectId),
                        iconClass: "sprite sprite-menu-team",
                        disabled: false,
                        active: true,
                    },
                ],
            },
            {
                id: "Manage",
                name: t(`common::label::${"Manage"}`),
                active: false,
                isOpen: true,
                subMenus: [
                    {
                        id: "category",
                        name: t(`common::label::${"Categories"}`),
                        path: menuService.paths.admin.categoryGroups(spaceId, projectId),
                        icon: <IconCategory size={16} htmlColor={theme.palette.greySecondary.main} />,
                        iconClass: "sprite sprite-menu-category",
                        disabled: false,
                        active: true,
                    },
                    {
                        id: "eventTriggers",
                        name: t(`common::label::${"Event triggers"}`),
                        path: menuService.paths.admin.eventTriggers(spaceId, projectId),
                        icon: <IconEventtriggers size={16} htmlColor={theme.palette.greySecondary.main} />,
                        iconClass: "sprite sprite-menu-eventtrigger",
                        disabled: false,
                        active: true,
                    },
                ],
            },
            {
                id: "settings",
                name: t(`common::label::${"Settings"}`),
                path: menuService.paths.admin.projectSettings(spaceId, projectId),
                icon: <IconSettingAdmin size={16} htmlColor={theme.palette.greySecondary.main} />,
                iconClass: "sprite sprite-menu-setting",
                disabled: false,
                active: true,
            },
        ];

        const manageSubMenu = menus.find((v) => v.id === "Manage")?.subMenus;
        if (compareVersions(stage.version, VERSION_CM_8558_METADATA_TEMPLATE) >= 0) {
            manageSubMenu.push({
                id: "metadataTemplate",
                name: t(`common::label::${"Metadata Template"}`),
                path: menuService.paths.admin.metadataTemplate(spaceId, projectId),
                icon: <IconMetadata size={16} htmlColor={theme.palette.greySecondary.main} />,
                iconClass: "sprite sprite-menu-eventtrigger",
                disabled: false,
                active: true,
            });
        }

        if (compareVersions(stage.version, VERSION_CM_10137_CUSTOM_CONTENT_SCHEMAS) >= 0) {
            manageSubMenu.push({
                id: "contentSchemas",
                name: t(`common::label::${"Content Schemas"}`),
                path: menuService.paths.admin.contentSchemas(spaceId, projectId),
                icon: <IconSchema size={16} htmlColor={theme.palette.greySecondary.main} />,
                iconClass: "sprite sprite-menu-metadata",
                disabled: false,
                active: true,
            });
        }
        return menus;
    };

    const resizingHandler = () => {
        if (window.innerWidth <= 1650) {
            dispatch(closeNavigator());
        } else {
            dispatch(openNavigator());
        }
    };

    const handleNavToggle = () => {
        setNavOpen(!navOpen);
    };

    const getNavToggleIcon = () => {
        if (navOpen) return <IconArrowBack size={12} htmlColor={`#fff`} />;
        if (isShown) return <IconArrowForward size={12} htmlColor={`#fff`} />;

        return <IconMenu size={12} htmlColor={`#fff`} />;
    };

    const leave = () => {
        setNavSwitch(true);

        navSwitchMenu();
        let prevUrl = global.prevPath === "" ? "/" : global.prevPath;
        history.replace(prevUrl);

        dispatch(setPrevPath("/"));
    };

    const TransIconComponent = useCallback((props) => {
        const Icon = props.icon;
        return <Icon {...props} />;
    }, []);

    const navMenus = useMemo(() => {
        return menus.map((item) => {
            item.hasSubmenu = item?.subMenus?.length > 0;
            return item;
        });
    }, [menus]);

    useEffect(() => {
        setNavOpen(upMatches);
    }, [upMatches]);

    useEffect(() => {
        if (!contentSchemas.data) return;
        bindMenus();
    }, [contentSchemas.data]);

    return (
        <>
            <Drawer
                variant="permanent"
                open={navOpen}
                sx={{
                    backgroundColor: alpha(theme.palette.greySecondary[50], 0.5),
                    ".MuiDrawer-paper": {
                        top: "56px",
                        height: `calc(100% - 56px )`,
                        overflowY: "hidden",
                        backgroundColor: "transparent",
                    },
                }}
            >
                {/*<Toolbar />*/}

                <SelectProject open={navOpen} />
                {menuType === "admin" && (
                    <>
                        {navOpen ? (
                            <Box
                                sx={{
                                    margin: `16px 24px`,
                                }}
                            >
                                <Typography
                                    variant={"subtitle2_700"}
                                    sx={{
                                        height: `22px`,
                                        textTransform: `uppercase`,
                                    }}
                                >
                                    {t(`common::label::${"Project Settings"}`)}
                                </Typography>
                            </Box>
                        ) : (
                            <Box
                                sx={{
                                    display: `inline-flex`,
                                    alignItems: `center`,
                                    justifyContent: `center`,
                                    width: `40px`,
                                    height: `22px`,
                                    margin: `28px 8px 28px 12px`,
                                    backgroundColor: theme.palette.greySecondary.main,
                                    borderRadius: `4px`,
                                }}
                            >
                                <IconSettingProject size={16} htmlColor={`#fff`} />
                            </Box>
                        )}
                    </>
                )}
                {menuType === "admin" ? (
                    <>
                        <List>
                            {projectMenus &&
                                projectMenus.map((menu, i) => {
                                    let menuActive = location.pathname.includes(menu.path);
                                    return (
                                        <ListItem key={i} divider>
                                            {menu.path ? (
                                                <ListItemButton
                                                    selected={menuActive}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        if (menu.path === "/cms") {
                                                            window.location.href = config.host.cms;
                                                        } else {
                                                            // console.log('menu.path', menu.path)
                                                            history.push(menu.path);
                                                            dispatch(IncreaseRequestCount());
                                                            dispatch(toggleMenu(menu.id));
                                                            if (menu.path.indexOf("admin") !== -1) {
                                                                dispatch(initParams());
                                                                dispatch(
                                                                    setPrevPath(
                                                                        `${location.pathname}${
                                                                            location.search ? location.search : ""
                                                                        }`,
                                                                    ),
                                                                );
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <ListItemIcon>{menu.icon && menu.icon}</ListItemIcon>

                                                    <ListItemText
                                                        primary={menu.name}
                                                        primaryTypographyProps={{
                                                            variant: "body1_500",
                                                            component: "strong",
                                                        }}
                                                    />
                                                </ListItemButton>
                                            ) : (
                                                <>
                                                    <ListSubheader component={"div"}>
                                                        {navOpen ? (
                                                            <Typography
                                                                variant={"body4_700"}
                                                                color={theme.palette.text.secondary}
                                                            >
                                                                {t(`${menu.name}`)}
                                                            </Typography>
                                                        ) : (
                                                            <Box
                                                                sx={{
                                                                    width: `8px`,
                                                                    height: `2px`,
                                                                    borderRadius: `1px`,
                                                                    margin: `7px auto`,
                                                                    backgroundColor: theme.palette.secondary[600],
                                                                }}
                                                            />
                                                        )}
                                                    </ListSubheader>
                                                    {menu.subMenus && (
                                                        <List>
                                                            {menu.subMenus
                                                                .filter((v) => !v.disabled)
                                                                .map((subMenu, i) => (
                                                                    <ListItem key={i}>
                                                                        {/*<NavLink*/}
                                                                        {/*    to={{*/}
                                                                        {/*        pathname: subMenu.path,*/}
                                                                        {/*        state: "flush",*/}
                                                                        {/*    }}*/}
                                                                        {/*    activeClassName={"active"}*/}
                                                                        {/*    className={`nav-link`}*/}
                                                                        {/*    onClick={() => {*/}
                                                                        {/*        dispatch(openMenu(menu.id));*/}
                                                                        {/*        dispatch(*/}
                                                                        {/*            IncreaseRequestCount(),*/}
                                                                        {/*        );*/}
                                                                        {/*    }}*/}
                                                                        {/*>*/}
                                                                        <ListItemButton
                                                                            component={NavLink}
                                                                            selected={
                                                                                location.pathname === subMenu.path
                                                                            }
                                                                            to={{
                                                                                pathname: subMenu.path,
                                                                                search:
                                                                                    location.pathname === subMenu.path
                                                                                        ? location.search
                                                                                        : "",
                                                                                state: "flush",
                                                                            }}
                                                                            onClick={() => {
                                                                                dispatch(openMenu(menu.id));
                                                                                dispatch(IncreaseRequestCount());
                                                                            }}
                                                                        >
                                                                            <ListItemIcon>
                                                                                {subMenu.icon && subMenu.icon}
                                                                            </ListItemIcon>

                                                                            <ListItemText
                                                                                primary={subMenu.name}
                                                                                primaryTypographyProps={{
                                                                                    variant: "body1_500",
                                                                                    component: "strong",
                                                                                }}
                                                                            />
                                                                        </ListItemButton>
                                                                        {/*</NavLink>*/}
                                                                    </ListItem>
                                                                ))}
                                                        </List>
                                                    )}
                                                </>
                                            )}
                                        </ListItem>
                                    );
                                })}
                        </List>
                    </>
                ) : (
                    <>
                        {!navigatorMenus || !navigatorMenusName ? (
                            <List sx={{ mt: theme.spacing(1) }}>
                                {new Array(5).fill("").map((_, i) => (
                                    <NavigatorMenuSkeleton key={i} />
                                ))}
                            </List>
                        ) : (
                            <List>
                                {navMenus &&
                                    navMenus.map((menu, i) => {
                                        let menuActive = location.pathname.includes(menu.path);
                                        if (menu.hasSubmenu === true && menuActive === false) {
                                            menuActive = !!menu.subMenus.find((sm) =>
                                                location.pathname.includes(sm.path),
                                            );
                                        }
                                        const menuHidden =
                                            menu.subMenus?.every(
                                                (sub) => sub.visible !== undefined && sub.visible === false,
                                            ) ||
                                            (!menu.hasSubmenu && menu.visible === false);
                                        return (
                                            <Tooltip key={i} title={navOpen ? "" : menu.name} placement={"right"}>
                                                <ListItem hidden={menuHidden}>
                                                    {menu.path ? (
                                                        <>
                                                            {menu.subMenus ? (
                                                                <ListItemButton
                                                                    onClick={(e) => {
                                                                        if (navOpen) dispatch(toggleMenu(menu.id));
                                                                        else {
                                                                            const firstSubMenu = menu.subMenus.filter(
                                                                                (v) => !v.disabled,
                                                                            )?.[0];
                                                                            if (!firstSubMenu) return;

                                                                            dispatch(openMenu(firstSubMenu.id));
                                                                            dispatch(IncreaseRequestCount());
                                                                            history.push(firstSubMenu.path);
                                                                        }
                                                                    }}
                                                                    selected={menuActive}
                                                                >
                                                                    {menu.icon && (
                                                                        <ListItemIcon>{menu.icon}</ListItemIcon>
                                                                    )}
                                                                    <ListItemText
                                                                        primary={menu.name}
                                                                        primaryTypographyProps={{
                                                                            variant: "body1_500",
                                                                            component: "strong",
                                                                        }}
                                                                    />
                                                                    {navOpen && (
                                                                        <>
                                                                            {menu.isOpen ? (
                                                                                <IconChevronUp
                                                                                    size={20}
                                                                                    color={
                                                                                        menuActive
                                                                                            ? "primary"
                                                                                            : "secondary"
                                                                                    }
                                                                                    style={{
                                                                                        opacity: 0.48,
                                                                                        transition:
                                                                                            "transform 300ms cubic-bezier(0.4, 0, 0.2, 1)",
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <IconChevronDown
                                                                                    size={20}
                                                                                    color={
                                                                                        menuActive
                                                                                            ? "primary"
                                                                                            : "secondary"
                                                                                    }
                                                                                    style={{
                                                                                        opacity: 0.48,
                                                                                        transition:
                                                                                            "transform 300ms cubic-bezier(0.4, 0, 0.2, 1)",
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </ListItemButton>
                                                            ) : (
                                                                // <Link
                                                                //
                                                                // >
                                                                <ListItemButton
                                                                    selected={menuActive}
                                                                    // onClick={() => setSelectedMenu(menu.id)}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setSelectedMenu(menu.id);
                                                                        if (menu.path === "/cms") {
                                                                            window.location.href = config.host.cms;
                                                                        } else {
                                                                            history.push(menu.path);
                                                                            dispatch(IncreaseRequestCount());
                                                                            // dispatch(onFoldMenu(menu.id));

                                                                            if (menu.path.indexOf("admin") !== -1) {
                                                                                dispatch(initParams());
                                                                                dispatch(
                                                                                    setPrevPath(
                                                                                        `${location.pathname}${
                                                                                            location.search
                                                                                                ? location.search
                                                                                                : ""
                                                                                        }`,
                                                                                    ),
                                                                                );
                                                                            }
                                                                        }
                                                                    }}
                                                                >
                                                                    {menu.icon && (
                                                                        <ListItemIcon>{menu.icon}</ListItemIcon>
                                                                    )}
                                                                    <ListItemText
                                                                        primary={menu.name}
                                                                        primaryTypographyProps={{
                                                                            variant: "body1_500",
                                                                            component: "strong",
                                                                        }}
                                                                    />
                                                                </ListItemButton>
                                                                // </Link>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <ListItemButton>
                                                            <ListItemText
                                                                primary={menu.name}
                                                                primaryTypographyProps={{
                                                                    variant: "body1_500",
                                                                    component: "strong",
                                                                }}
                                                            />
                                                        </ListItemButton>
                                                    )}
                                                    {menu.subMenus && navOpen && (
                                                        <Collapse in={menu.isOpen}>
                                                            <List>
                                                                {navOpen && contentSchemas.pending
                                                                    ? new Array(5)
                                                                          .fill("")
                                                                          .map((_, i) => (
                                                                              <NavigatorSubMenuSkeleton key={i} />
                                                                          ))
                                                                    : menu.subMenus
                                                                          .filter(
                                                                              (v) =>
                                                                                  !v.disabled &&
                                                                                  (v.visible === undefined ||
                                                                                      v.visible),
                                                                          )
                                                                          .map((subMenu, i) => (
                                                                              <ListItem key={i}>
                                                                                  <ListItemButton
                                                                                      component={NavLink}
                                                                                      to={{
                                                                                          pathname: subMenu.path,
                                                                                          search:
                                                                                              location.pathname ===
                                                                                              subMenu.path
                                                                                                  ? location.search
                                                                                                  : "",
                                                                                          state: "flush",
                                                                                      }}
                                                                                      onClick={() => {
                                                                                          dispatch(openMenu(menu.id));
                                                                                          dispatch(
                                                                                              IncreaseRequestCount(),
                                                                                          );
                                                                                      }}
                                                                                      activeClassName={"Mui-selected"}
                                                                                      sx={{
                                                                                          "&.Mui-selected": {
                                                                                              "& .MuiListItemIcon-root":
                                                                                                  {
                                                                                                      color: `${theme.palette.primary.main} !important`,
                                                                                                  },
                                                                                              "& .MuiTypography-root": {
                                                                                                  color: theme.palette
                                                                                                      .primary.main,
                                                                                              },
                                                                                          },
                                                                                          "&:hover": {
                                                                                              backgroundColor: `${alpha(
                                                                                                  theme.palette
                                                                                                      .primary[100],
                                                                                                  0.72,
                                                                                              )} !important`,
                                                                                              "& .MuiListItemIcon-root":
                                                                                                  {
                                                                                                      color: `${theme.palette.primary.main} !important`,
                                                                                                  },
                                                                                              "& .MuiTypography-root": {
                                                                                                  color: theme.palette
                                                                                                      .primary.main,
                                                                                              },
                                                                                          },
                                                                                      }}
                                                                                  >
                                                                                      <ListItemIcon
                                                                                          sx={{
                                                                                              opacity: `1 !important`,
                                                                                              color: `${theme.palette.greySecondary.light} !important`,
                                                                                          }}
                                                                                      >
                                                                                          <IconCircle size={6} />
                                                                                      </ListItemIcon>
                                                                                      <ListItemText
                                                                                          primary={subMenu.name}
                                                                                          primaryTypographyProps={{
                                                                                              variant: "body1_400",
                                                                                          }}
                                                                                      />
                                                                                  </ListItemButton>
                                                                                  {/*</NavLink>*/}
                                                                              </ListItem>
                                                                          ))}
                                                                {nextToken && <Grid item ref={targetRef}></Grid>}
                                                            </List>
                                                        </Collapse>
                                                    )}
                                                </ListItem>
                                            </Tooltip>
                                        );
                                    })}
                            </List>
                        )}
                    </>
                )}

                {activeStage &&
                    (compareVersions(activeStage.version, VERSION_CM_5112_PROJECT_SITE_ACCESS) < 0 ||
                        (activeStage.user &&
                            (activeStage.user.isSpaceAdmin ||
                                (activeProject &&
                                    activeStage.user.projects &&
                                    activeStage.user.projects.filter((p) => p.id === activeProject.id && p.isAdmin)
                                        .length > 0)))) && (
                        <Box sx={{ position: `relative`, height: "64px", bottom: "0", backgroundColor: `#f2f3f6` }}>
                            <Divider sx={{ mb: 1.5, mx: 1.5 }} />
                            {menuType === "admin" ? (
                                <Tooltip
                                    title={navOpen ? "" : t(`common::label::${"Back to Project"}`, `Back to Project`)}
                                    placement={"right"}
                                >
                                    <BackToConsoleButton
                                        color="inherit"
                                        className={"back-to-console-button"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            leave();
                                        }}
                                        startIcon={
                                            <Box width={22} height={22} borderRadius={5}>
                                                <IconBack size={12} htmlColor={`#fff`} />
                                            </Box>
                                        }
                                    >
                                        {t(`common::label::${"Back to Project"}`, `Back to Project`)}
                                    </BackToConsoleButton>
                                </Tooltip>
                            ) : (
                                <>
                                    <Tooltip title={navOpen ? "" : "Project Settings"} placement={"right"}>
                                        <ProjectSettingButton
                                            color="inherit"
                                            className={"project-setting-button"}
                                            onClick={() => {
                                                dispatch(closeModal());
                                                navSwitchMenu();
                                                project.id
                                                    ? history.push(
                                                          menuService.paths.admin.projectSettings(spaceId, projectId),
                                                      )
                                                    : "";
                                            }}
                                            startIcon={
                                                <IconSettingProject
                                                    size={16}
                                                    htmlColor={theme.palette.greySecondary.main}
                                                />
                                            }
                                            endIcon={
                                                <Box borderRadius={5}>
                                                    <IconArrowCircleRight size={22} />
                                                </Box>
                                            }
                                        >
                                            {t(`common::label::${"Project Settings"}`)}
                                        </ProjectSettingButton>
                                    </Tooltip>
                                </>
                            )}
                        </Box>
                    )}
                <Tooltip title={navOpen ? "Folding" : "Open"} placement={"right"} leaveDelay={-200}>
                    <IconButton
                        className={"nav-toggle-button"}
                        color={navOpen ? "primary" : "inherit"}
                        variant={"contained"}
                        circled
                        onClick={handleNavToggle}
                        edge="start"
                        onMouseEnter={() => setIsShown(true)}
                        onMouseLeave={() => setIsShown(false)}
                    >
                        {getNavToggleIcon()}
                    </IconButton>
                </Tooltip>
            </Drawer>
        </>
    );
};

export default Navigator;
