import React from "react";
import { Route } from "react-router-dom";
import Authorization from "../../routes/providers";

const RouteWithSubRoutes = (route) => {
    return (
        <React.Fragment>
            {route.render ? (
                <Route path={route.path} exact={route.exact} render={route.render} />
            ) : (
                <Route
                    path={route.path}
                    exact={route.exact}
                    render={
                        (props) => (
                            <Authorization connectedMenuId={route.connectedMenuId}>
                                <route.component {...props} routes={route.routes}></route.component>
                            </Authorization>
                        )
                        // <route.component {...props} routes={route.routes} />
                    }
                />
            )}
        </React.Fragment>
    );
};

export default RouteWithSubRoutes;
