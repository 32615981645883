import { Avatar } from './user-profile.styled';
import { IconAvata } from "@mzc-cloudplex/icons";
import theme from "@styles/theme";

export const AvatarText = (value: string) => {
    const emojiRegex =
        /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/;

    return value?.match(emojiRegex) ? value.substring(0, 2) : value?.substring(0, value?.length === 2 ? 2 : 1);
};

export const UserProfile = (props) => {
    return (
        <>
            {(props.profileImageUrl !== null && (
                <Avatar
                    style={{
                        width: props.size,
                        height: props.size,
                        fontSize: 18,
                    }}
                    src={props.profileImageUrl}
                />
            )) || (
                <Avatar
                    style={{
                        width: props.size,
                        height: props.size,
                    }}
                >
                    <IconAvata size={props.size} htmlColor={props.headerTheme?.color?.textColor ? props.headerTheme?.color?.textColor : theme.palette.secondary[500]} sx={{opacity: .48}}/>
                </Avatar>
            )}
        </>
    );
};
