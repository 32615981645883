import { axios } from "@cores/axiosWrapper";

export const getProjectUserAPI = (stageId, apiEndpoint, projectId, id) => {
    const headers = {
        stageId,
    };
    if (projectId) {
        headers.projectId = projectId;
    }
    return axios.get(`${apiEndpoint}/v3/managements/projects/${projectId}/members/${id}`, {
        headers,
    });
};

export const getProjectUsersAPI = (stageId, apiEndpoint, projectId, params) => {
    let queries = {
        shouldRequestDetail: params.shouldRequestDetail,
        limit: params.limit,
    };
    const headers = {
        stageId,
    };

    if (params.nextToken) queries = { ...queries, nextToken: params.nextToken };
    if (params.name) queries = { ...queries, name: params.name };
    if (params.keyword) {
        queries[params.keywordType] = params.keyword;
    }

    if (projectId) {
        headers.projectId = projectId;
    }
    return axios.get(`${apiEndpoint}/v3/managements/projects/${projectId}/members`, {
        headers,
        params: queries,
    });
};

export function getUsersAPI(stageId, apiEndpoint, projectId, params) {
    let queries = {
        isDeleted: false,
        isWithdrawal: false,
        limit: params.limit,
    };
    const headers = {
        stageId,
    };

    if (params.nextToken) queries = { ...queries, nextToken: params.nextToken };
    if (params.name) queries = { ...queries, name: params.name };
    if (params.username) queries = { ...queries, username: params.username };
    if (params.keyword) {
        queries[params.keywordType] = params.keyword;
    }

    if (projectId) {
        headers.projectId = projectId;
    }

    return axios.get(`${apiEndpoint}/v3/managements/users`, {
        params: queries,
        headers,
    });
}

export function getUserAPI(stageId, apiEndpoint, projectId, id) {
    const headers = {
        stageId,
    };

    if (projectId) {
        headers.projectId = projectId;
    }

    return axios.get(`${apiEndpoint}/v3/managements/users/${id}`, {
        headers,
    });
}

export function getUserTeamsAPI(stageId, apiEndpoint, projectId, params) {
    const headers = {
        stageId,
    };

    if (projectId) {
        headers.projectId = projectId;
    }

    return axios.get(`${apiEndpoint}/v3/managements/teams`, {
        params,
        headers,
    });
}

export function getProjectTeamsAPI(stageId, apiEndpoint, projectId, params) {
    const headers = {
        stageId,
    };

    return axios.get(`${apiEndpoint}/v3/managements/projects/${projectId}/teams`, {
        params,
        headers,
    });
}

export function patchUserAPI(stageId, apiEndpoint, projectId, id, body) {
    const headers = {
        stageId,
    };

    if (projectId) {
        headers.projectId = projectId;
    }

    return axios.patch(`${apiEndpoint}/v3/managements/users/${id}`, body, {
        headers,
    });
}

export function deleteUserAPI(stageId, apiEndpoint, projectId, id, body) {
    const headers = {
        stageId,
    };

    if (projectId) {
        headers.projectId = projectId;
    }

    return axios.delete(`${apiEndpoint}/v3/managements/users/${id}`, {
        headers,
    });
}

export function getUserRolesAPI(stageId, apiEndpoint, projectId, params) {
    const headers = {
        stageId,
    };

    if (projectId) {
        headers.projectId = projectId;
    }

    return axios.get(`${apiEndpoint}/role-managements/roles`, {
        params,
        headers,
    });
}

export function getProjectUserRolesAPI(stageId, apiEndpoint, projectId, userId, params) {
    const headers = {
        stageId,
    };

    if (projectId) {
        headers.projectId = projectId;
    }

    return axios.get(`${apiEndpoint}/v3/managements/projects/${projectId}/members/${userId}/roles`, {
        params,
        headers,
    });
}

export function patchProjectUserAPI(stageId, apiEndpoint, projectId, userId, body) {
    const headers = {
        stageId,
        projectId,
    };

    return axios.patch(`${apiEndpoint}/v3/managements/projects/${projectId}/members/${userId}`, body, {
        headers,
    });
}

export function inviteSpacePendingUserAPI(stageId, apiEndpoint, body) {
    const headers = { stageId };
    return axios.post(`${apiEndpoint}/user-invitation/space-pending-users/actions`, body, {
        headers,
    });
}

export function inviteProjectPendingUserAPI(stageId, apiEndpoint, projectId, body) {
    const headers = { stageId, projectId };
    return axios.post(`${apiEndpoint}/user-invitation/project-pending-users/actions`, body, {
        headers,
    });
}

export function getSpacePendingUsersAPI(stageId, apiEndpoint, params) {
    const headers = { stageId };
    return axios.get(`${apiEndpoint}/user-invitation/space-pending-users`, {
        params,
        headers,
    });
}

export function getProjectPendingUsersAPI(stageId, apiEndpoint, projectId, params) {
    const headers = { stageId, projectId };
    return axios.get(`${apiEndpoint}/user-invitation/project-pending-users`, {
        params,
        headers,
    });
}

export function getProjectUserTeamsAPI(stageId, apiEndpoint, projectId, userId, params) {
    const headers = {
        stageId,
        projectId,
    };

    return axios.get(`${apiEndpoint}/v3/managements/projects/${projectId}/members/${userId}/teams`, {
        params,
        headers,
    });
}
