import React, { useState, useCallback } from "react";
import { Badge, Checkbox, Grid, Typography } from "@mzc-pdc/ui";

const TableCard = ({ name, subName, thumbnail }) => {
    // name : {
    //   startIcon:
    //   name:
    //   endIcon:
    // }
    // subName: {
    //   name:
    //   icons:
    // }
    return (
        <Grid item>
            <Grid
                container
                flexDirection={"row"}
                alignItems={"center"}
                flexWrap={"nowrap"}
                sx={{
                    position: "relative",
                    justifyContent: "start",
                }}
            >
                {thumbnail && thumbnail}
                <Grid item sx={{ width: `100%` }}>
                    <Grid container flexDirection={"column"} pl={1} pr={1} className={"row"}>
                        <Grid
                            container
                            flexDirection={"row"}
                            className={"column"}
                            alignItems={"center"}
                            flexWrap={"nowrap"}
                        >
                            {name?.startIcon && name?.startIcon}
                            <Grid container>{name?.name && name?.name}</Grid>
                            {name?.endIcon && name?.endIcon}
                        </Grid>
                        {subName && (
                            <Grid container flexDirection={"row"}>
                                {subName.name && subName.name}
                                {subName.icons && subName.icons}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
TableCard.propTypes = {};

export default TableCard;
