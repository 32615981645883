import { Grid, theme, Typography } from "@mzc-pdc/ui";
import StoragePathTooltip from "@components/tooltip/StoragePathTooltip";
import {
    REGEXP_AUDIO_EXTENSION,
    REGEXP_CAPTION_EXTENSION,
    REGEXP_IMAGE_EXTENSION,
    REGEXP_VIDEO_EXTENSION,
} from "@constants";
import {
    IconAudioType,
    IconCaptionType,
    IconFileType,
    IconFolder,
    IconPhotoType,
    IconVideoType,
} from "@mzc-cloudplex/icons";
import React from "react";
import { getMediaTypeFromExtension } from "@lib/mime/mime";
import convertToByte from "@cores/convertToByte";
import AssetTypeFlag from "@components_v2/icon-type/asset/asset-type-flag";

const ShareResourceInfo = ({ domain, resource }) => {
    const getStorageIconByExtension = (extension) => {
        if (REGEXP_VIDEO_EXTENSION.test(extension)) return <IconVideoType htmlColor={"#9fa3b2"} size={16} />;
        else if (REGEXP_AUDIO_EXTENSION.test(extension)) return <IconAudioType htmlColor={"#9fa3b2"} size={16} />;
        else if (REGEXP_IMAGE_EXTENSION.test(extension)) return <IconPhotoType htmlColor={"#9fa3b2"} size={16} />;
        else if (REGEXP_CAPTION_EXTENSION.test(extension)) return <IconCaptionType htmlColor={"#9fa3b2"} size={16} />;
        else return <IconFileType htmlColor={"#9fa3b2"} size={16} />;
    };
    return (
        <Grid
            container
            flexDirection={"column"}
            sx={{
                padding: theme.spacing(1.5),
                borderRadius: "4px",
                border: `1px solid ${theme.palette.greySecondary[100]}`,
            }}
        >
            {domain === "STORAGE" && (
                <Grid item>
                    <StoragePathTooltip
                        folder={resource.key.slice(1, resource.key.lastIndexOf("/") + 1)}
                        bucket={resource.bucket}
                    >
                        {resource.key[resource.key.length - 1] === "/" ? (
                            <IconFolder size={20} />
                        ) : (
                            getStorageIconByExtension(
                                getMediaTypeFromExtension(resource.key.slice(resource.key.lastIndexOf("."))),
                            )
                        )}
                    </StoragePathTooltip>
                    <Typography
                        variant={`body2_400`}
                        sx={{
                            ml: theme.spacing(1.5),
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            maxWidth: "300px",
                            textWrap: "nowrap",
                        }}
                    >
                        {resource.key}
                    </Typography>
                    <Typography variant={`body2_400`}>- {convertToByte(resource.size)}</Typography>
                </Grid>
            )}
            {domain === "ASSET" && (
                <Grid item>
                    <AssetTypeFlag minWidth={22} width={22} height={16} type={resource.mediaType} />
                    <Typography
                        variant={`body2_400`}
                        sx={{
                            display: "inline-block",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            maxWidth: "300px",
                            textWrap: "nowrap",
                            verticalAlign: "bottom",
                        }}
                    >
                        {resource.name}
                    </Typography>
                    &nbsp;
                    <Typography variant={`body2_400`}>({resource.id})</Typography>
                </Grid>
            )}
        </Grid>
    );
};

export default ShareResourceInfo;
