import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import equal from "deep-equal";
import Loadable from "@react-loadable/revised";
import compareVersions, {
    VERSION_CM_11345_PROJECT_COMMENT_SERVICE,
    VERSION_CM_4716_INTEGRATED_SEARCH,
    VERSION_CM_4733_CREATE_METADATA,
    VERSION_CM_5058_DASHBOARD,
    VERSION_CM_7497_INTEGRATED_SEARCH_WITH_SEARCH_ENGINE,
} from "@cores/version";
import { setSiteMode } from "@modules/siteMode";
import { useTranslation } from "react-i18next";
import config from "@/config";
import { CONSTANTS, JOB_PANEL_TABS, SIDEBAR_BUTTON_ID } from "@constants";
import { isDev, isLocal } from "@cores/enviromentExtension";
import { siteMode } from "@cores/siteMode";
import VersionedComponent from "@components/VersionedComponent";
import SelectStage from "@components/navigator/components/SelectStage";
import SwitchButton from "./SwitchButton";
import Logo from "./Logo";
import StartMenu from "../../features/start-menu/start-menu";
import ProfileMenu from "../../features/profile-menu/profile-menu";
import menuService from "@services/menuService";
import theme from "@styles/theme";
import { AppBar, Collapse, Divider, Grid, IconButton, InputAdornment, TextField, Toolbar, Tooltip } from "@mzc-pdc/ui";
import { IconClose, IconForward, IconReply, IconSearch, ImageMyjobsOff, ImageMyjobsOn } from "@mzc-cloudplex/icons";
import { alpha } from "@mui/material";
import Lottie from "lottie-react";
import loadingLottie from "@assets/lottie/my-jobs-loading.json";
import queryParamsParse from "../params/queryParamsParse";
import useMyJobs from "@hooks/useMyJobs";
import { toggleActive } from "@modules/rightSideBar";

const LoadableModelForAppDown = Loadable({
    loader: () => import("@components/modals/AppDownload"),
    loading: () => null,
});

const HeaderForLayout = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { pathname } = useLocation();
    const { spaceId, projectId } = menuService.parse(pathname);

    const { openMyJobs, isJobExisting, isJobProcessing } = useMyJobs({});
    const user = useSelector(({ user }) => user);
    const stage = useSelector(({ stage }) => stage);
    const settings = useSelector(({ settings }) => settings);
    const rightSideBar = useSelector(({ rightSideBar }) => rightSideBar);

    const [activeAppDownModal, setActiveAppDownModal] = useState(false);
    const [headerTheme, setHeaderTheme] = useState(null);
    const [isSearch, setIsSearch] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState(``);
    const [hasCmsAccess, setHasCmsAccess] = useState(false);

    const closeAppDownModal = () => {
        setActiveAppDownModal(false);
    };

    const onCmsClick = () => {
        siteMode.set("CMS");
        dispatch(
            setSiteMode({
                mode: "CMS",
            }),
        );
        if (isLocal) {
            history.replace(
                compareVersions(VERSION_CM_5058_DASHBOARD, stage.version) <= 0
                    ? `${menuService.paths.dashboard(spaceId, projectId)}`
                    : `/`,
            );
        } else {
            window.location.href =
                compareVersions(VERSION_CM_5058_DASHBOARD, stage.version) <= 0
                    ? `${config.host.cms}${menuService.paths.dashboard(spaceId, projectId)}`
                    : `${config.host.cms}`;
        }
    };

    const onSubmit = () => {
        setIsSearch(false);
        if (
            compareVersions(stage.version, VERSION_CM_7497_INTEGRATED_SEARCH_WITH_SEARCH_ENGINE) >= 0 &&
            stage?.extensions?.["SEARCH_ENGINE_ENABLED"] === "true"
        )
            history.push(
                `${menuService.paths.integratedSearch(spaceId, projectId)}?q=${encodeURIComponent(
                    searchKeyword,
                )}&page=0&size=20`,
            );
        else
            history.push(
                `${menuService.paths.search(spaceId, projectId)}q=${encodeURIComponent(
                    searchKeyword,
                )}&offset=0&limit=200`,
            );
    };

    const openComments = () => {
        const type = CONSTANTS("RIGHT_SIDE_BAR_TYPES").COMMENT;
        dispatch(toggleActive({ type }));
    };

    useEffect(() => {
        const headerThemeOfSettings = localStorage.getItem("headerTheme");
        let _hasCmsAccess = false;

        if (headerThemeOfSettings) {
            const headerThemeJson = JSON.parse(headerThemeOfSettings);
            if (!equal(headerTheme, headerThemeJson.data)) {
                // const session = sessionKeeper.get();
                const currentStageId = spaceId;
                if (headerThemeJson.stageId === currentStageId) {
                    setHeaderTheme(headerThemeJson.data);
                }
            }
        }

        if (user?.stages?.data) {
            const activeStage = user.stages.data.filter((v) => v.id === spaceId)[0];
            if (activeStage) {
                const activeProject = activeStage.projects
                    ? activeStage.projects.filter((v) => v.id === projectId)[0]
                    : null;
                const siteAccess = activeStage.user.projects
                    ? activeStage.user.projects.filter((v) => v.id === projectId)[0]?.siteAccess
                    : null;

                if (siteAccess?.find((v) => v === "CMS") !== undefined) {
                    _hasCmsAccess = siteMode.isCmsSupported();
                }

                setHasCmsAccess(_hasCmsAccess);
            }
        }

        const { threadId, commentId } = queryParamsParse(location.search);
        const isLinkFromEmail = !!threadId;
        if (location.pathname.includes("/dashboard") && isLinkFromEmail) {
            openComments();
        }
    }, []);

    useEffect(() => {
        if (!settings?.stage) return;

        const headerThemeOfSettings = settings.stage.General.headerTheme;
        if (headerThemeOfSettings?.value) {
            const headerThemeJSON = JSON.parse(headerThemeOfSettings.value);
            if (!equal(headerThemeJSON, headerTheme)) {
                localStorage.setItem(
                    "headerTheme",
                    JSON.stringify({
                        stageId: stage.id,
                        data: headerThemeJSON,
                    }),
                );

                setHeaderTheme(headerThemeJSON);
            }
        }
    }, [settings?.stage?.General]);

    useEffect(() => {
        if (!user?.stages?.data || !projectId) return;

        const activeStage = user.stages.data.filter((v) => v.id === spaceId)[0];
        if (activeStage) {
            const activeProject = activeStage.projects
                ? activeStage.projects.filter((v) => v.id === projectId)[0]
                : null;

            let _hasCmsAccess = false;

            if (activeStage.user) {
                const siteAccess = activeStage.user?.projects
                    ? activeStage.user.projects.filter((v) => v.id === projectId)[0]?.siteAccess
                    : null;

                if (siteAccess?.find((v) => v === "CMS") !== undefined) {
                    _hasCmsAccess = siteMode.isCmsSupported();
                }
            }
            setHasCmsAccess(_hasCmsAccess);
        }
    }, [user, projectId]);

    return (
        <AppBar
            position="fixed"
            sx={{
                backgroundColor: headerTheme ? headerTheme.color?.bgColor : theme.palette.background.default,
                color: headerTheme ? headerTheme.color?.textColor : theme.palette.text.primary,
                fill: headerTheme ? headerTheme.color?.textColor : theme.palette.text.primary,
                boxShadow: `none`,
                borderBottom: `1px solid ${theme.palette.secondary[100]}`,
                zIndex: (props) => props.zIndex.drawer + 1,
            }}
        >
            <Toolbar sx={{ padding: `0 !important` }}>
                <Grid container justifyContent={`space-between`} alignItems={`center`}>
                    <Grid item>
                        <Grid container alignItems={"center"} height={theme.mixins.toolbar.minHeight}>
                            <Grid item px={1.5}>
                                <StartMenu headerTheme={headerTheme} />
                            </Grid>
                            <Divider
                                flexItem
                                orientation={"vertical"}
                                sx={{
                                    marginLeft: `-1px`,
                                    background: `none`,
                                    borderColor: alpha(theme.palette.secondary[500], 0.2),
                                }}
                            />
                            <Grid item pl={2.5}>
                                <Grid container alignItems={"center"}>
                                    <Logo
                                        to={
                                            compareVersions(VERSION_CM_5058_DASHBOARD, stage.version) <= 0
                                                ? "/"
                                                : `${menuService.paths.dashboard(spaceId, projectId)}/dashboard`
                                        }
                                        imgSrc={headerTheme?.ci}
                                        fill={headerTheme?.color?.textColor}
                                    />

                                    {/*<Tooltip title={activeStage?.name}>*/}
                                    {/*    <IconButton size={"medium"} circled sx={{ ml: 1 }}>*/}
                                    {/*        <ImageSpace width={20} height={20} />*/}
                                    {/*    </IconButton>*/}
                                    {/*</Tooltip>*/}

                                    {hasCmsAccess && (
                                        <VersionedComponent version={VERSION_CM_4733_CREATE_METADATA}>
                                            <>
                                                <Divider
                                                    flexItem
                                                    orientation={"vertical"}
                                                    sx={{
                                                        m: `4px 20px`,
                                                        background: `none`,
                                                        borderColor: alpha(theme.palette.secondary[500], 0.2),
                                                    }}
                                                />
                                                {/*TODO: switch 버튼 클릭시 링크가 이상한 것 같아요! 확인 필요!*/}
                                                <SwitchButton
                                                    onClick={onCmsClick}
                                                    headerTheme={headerTheme}
                                                    label={t("common::label::CMS")}
                                                />
                                            </>
                                        </VersionedComponent>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container gap={1} pr={1} alignItems={"center"} height={theme.mixins.toolbar.minHeight}>
                            <Grid item sx={{ position: `relative` }}>
                                {compareVersions(VERSION_CM_4716_INTEGRATED_SEARCH, stage.version) <= 0 && (
                                    <>
                                        <IconButton
                                            circled
                                            size={"large"}
                                            onClick={() => {
                                                setIsSearch(true);
                                                setSearchKeyword(``);
                                                // setTimeout(() => searchRef && searchRef.current.focus(), 100);
                                            }}
                                        >
                                            <IconSearch
                                                size={20}
                                                htmlColor={
                                                    headerTheme?.color?.textColor
                                                        ? headerTheme.color?.textColor
                                                        : theme.palette.secondary[500]
                                                }
                                                sx={{ opacity: 0.48 }}
                                            />
                                        </IconButton>

                                        <Collapse
                                            orientation="horizontal"
                                            in={isSearch}
                                            sx={{
                                                position: `absolute`,
                                                right: 0,
                                                top: 0,
                                                bottom: 0,
                                                backgroundColor: theme.palette.greySecondary[50],
                                                borderRadius: 1,
                                            }}
                                        >
                                            <Grid container flexWrap={"nowrap"} alignItems={"center"}>
                                                <form
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        onSubmit();
                                                    }}
                                                >
                                                    <TextField
                                                        variant={"filled"}
                                                        type="search"
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    {/*<IconButton*/}
                                                                    {/*    circled*/}
                                                                    {/*    onClick={(e) => {*/}
                                                                    {/*        setSearchKeyword(``);*/}
                                                                    {/*    }}*/}
                                                                    {/*>*/}
                                                                    {/*    <IconCancelCircle*/}
                                                                    {/*        size={12}*/}
                                                                    {/*        htmlColor={theme.palette.secondary[500]}*/}
                                                                    {/*    />*/}
                                                                    {/*</IconButton>*/}
                                                                    <Tooltip
                                                                        title={t(
                                                                            `common::label::Go to Search Results`,
                                                                            `Go to Search Results`,
                                                                        )}
                                                                    >
                                                                        <IconButton
                                                                            circled
                                                                            size={"medium"}
                                                                            onClick={() => {
                                                                                setIsSearch(false);
                                                                                setSearchKeyword(``);
                                                                                onSubmit();
                                                                            }}
                                                                        >
                                                                            <IconForward
                                                                                size={16}
                                                                                htmlColor={theme.palette.secondary[500]}
                                                                            />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Divider
                                                                        flexItem
                                                                        orientation={"vertical"}
                                                                        sx={{
                                                                            height: 24,
                                                                            alignSelf: `center`,
                                                                            mx: 1,
                                                                            borderColor: alpha(
                                                                                theme.palette.secondary[500],
                                                                                0.2,
                                                                            ),
                                                                        }}
                                                                    />
                                                                    <Tooltip title={t(`common::label::Close`, `Close`)}>
                                                                        <IconButton
                                                                            circled
                                                                            size={"small"}
                                                                            onClick={() => {
                                                                                setIsSearch(false);
                                                                                setSearchKeyword(``);
                                                                            }}
                                                                        >
                                                                            <IconClose
                                                                                size={12}
                                                                                htmlColor={theme.palette.secondary[500]}
                                                                            />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </InputAdornment>
                                                            ),
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <IconSearch size={16} color={"greySecondary"} />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        fullWidth
                                                        value={searchKeyword}
                                                        onChange={(e) => {
                                                            setSearchKeyword(e.target.value);
                                                        }}
                                                        placeholder={t(
                                                            `common::label::Search for storage and assets by all projects.`,
                                                        )}
                                                        sx={{
                                                            minWidth: 400,
                                                            ".MuiInputBase-root": {
                                                                backgroundColor: `transparent`,
                                                                paddingRight: 0,
                                                                "&:has(input[type='search']):hover , &:has(input[type='search']):focus, &:has(input[type='search']).Mui-focused":
                                                                    {
                                                                        backgroundColor: `transparent`,
                                                                    },
                                                            },
                                                        }}
                                                    />
                                                </form>
                                            </Grid>
                                        </Collapse>
                                    </>
                                )}
                            </Grid>
                            {(isLocal || isDev) && (
                                <Grid item>
                                    <SelectStage />
                                </Grid>
                            )}
                            {compareVersions(stage.version, VERSION_CM_11345_PROJECT_COMMENT_SERVICE) >= 0 && (
                                <Grid item>
                                    <Tooltip title={t(`common::label::Comment`, `Comment`)}>
                                        <IconButton
                                            size={"large"}
                                            circled
                                            sx={{
                                                p: 1,
                                                backgroundColor:
                                                    rightSideBar.active &&
                                                    rightSideBar.type === CONSTANTS("RIGHT_SIDE_BAR_TYPES").COMMENT
                                                        ? alpha(theme.palette.common.black, 0.08)
                                                        : `transparent`,
                                                "& div svg": { marginBottom: "8px" },
                                            }}
                                            id={SIDEBAR_BUTTON_ID}
                                            onClick={(e) => {
                                                // e.stopPropagation();
                                                openComments();
                                            }}
                                        >
                                            <IconReply
                                                size={20}
                                                htmlColor={
                                                    headerTheme?.color?.textColor
                                                        ? headerTheme.color?.textColor
                                                        : theme.palette.secondary[500]
                                                }
                                                sx={{ opacity: 0.48 }}
                                            />
                                        </IconButton>{" "}
                                    </Tooltip>
                                </Grid>
                            )}
                            <Divider
                                flexItem
                                orientation={"vertical"}
                                sx={{
                                    my: "18px",
                                    background: `none`,
                                    borderColor: alpha(theme.palette.secondary[500], 0.2),
                                }}
                            />
                            <Grid item>
                                <IconButton
                                    size={"large"}
                                    circled
                                    sx={{ p: 1, "& div svg": { marginBottom: "8px" } }}
                                    id={SIDEBAR_BUTTON_ID}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openMyJobs();
                                    }}
                                >
                                    {isJobExisting && isJobProcessing && (
                                        <Lottie
                                            animationData={loadingLottie}
                                            style={{
                                                height: "24px",
                                                minWidth: "24px",
                                                margin: "auto",
                                            }}
                                        />
                                    )}
                                    {isJobExisting && !isJobProcessing && <ImageMyjobsOn width={24} height={24} />}
                                    {!isJobExisting && <ImageMyjobsOff width={24} height={24} />}
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <ProfileMenu headerTheme={headerTheme} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>
            {activeAppDownModal &&
                ReactDOM.createPortal(<LoadableModelForAppDown onClose={closeAppDownModal} />, document.body)}
        </AppBar>
    );
};

export default HeaderForLayout;
