import React, { useMemo } from "react";
import moment from "moment";
import Tooltip from "@components_v2/Tooltip/tooltip";
import { Grid } from "@mzc-pdc/ui";
import Typography from "@components_v2/typography/typography";
import { useTranslation } from "react-i18next";

const CommentDate = ({ date }) => {
    const { t } = useTranslation();
    if (!date) return "";

    const tooltipDate = moment(date).calendar(null, {
        sameDay: `[${t(`common::label::Today`, "Today")},] LTS`,
        lastDay: `[${t(`common::label::Yesterday`, "Yesterday")},] LTS`,
        lastWeek: "MMMM Do[,] LTS",
        sameElse: `MMMM Do[,] LTS`,
    });

    const simplifiedDate = moment(date).format("LT");

    return (
        <Tooltip
            title={tooltipDate}
            placement={"top"}
            slotProps={{
                popper: {
                    modifiers: [
                        {
                            name: "offset",
                            options: {
                                offset: [0, -14],
                            },
                        },
                    ],
                },
            }}
        >
            <Grid item>
                <Typography variant={`body3_400`} color="text.secondary">
                    {simplifiedDate}
                </Typography>
            </Grid>
        </Tooltip>
    );
};

export default CommentDate;
