// import styled from 'styled-components';
import { styled, alpha } from "@mui/material";

/* components */
import {
    Avatar as MuiAvatar,
    Popper as MuiPopper,
    MenuList as MuiMenuList,
    MenuItem as MuiMenuItem,
} from "@mzc-pdc/ui";
export const Avatar = styled(MuiAvatar)(({}) => ({
    "&.MuiAvatar-root": {
        svg: {
            marginRight: 0,
        },
    },
}));

export const Popper = styled(MuiPopper)(({}) => ({
    ".MuiPaper-root": {
        marginTop: `11px`,
        marginRight: `-9px`,
    },
}));

export const ToggleList = styled(MuiMenuList)(({}) => ({}));

export const ProfileItem = styled(MuiMenuItem)(({}) => ({
    cursor: `default`,
    display: `flex`,
    flexDirection: `column`,
    alignItems: `flex-start`,
    padding: `12px 20px 8px 20px`,
    textAlign: `left`,
    "&:hover": {
        backgroundColor: `transparent`,
    },
    "&:active": {
        backgroundColor: `transparent`,
    },
    ".MuiTouchRippleRoot": {
        visibility: `hidden`,
    },
}));

export const ToggleItem = styled(MuiMenuItem)(({ theme }) => ({
    display: `flex`,
    alignItems: `center`,
    padding: `9.5px 20px`,
    svg: {
        marginLeft: `17px`,
        marginTop: `-3px`,
    },
    strong: {
        fontWeight: `500`,
    },
    "&:hover": {
        backgroundColor: alpha(theme.palette.secondary[800], 0.08),
    },
}));

export const ToggleDivider = styled(MuiMenuList)(({ theme }) => ({
    padding: `0`,
    width: `100%`,
    height: `1px`,
    margin: `8px 0`,
    backgroundColor: theme.palette.secondary[100],
}));

export const Name = styled("p")(({ theme }) => ({
    fontSize: `14px`,
    letterSpacing: `-0.24px`,
    fontWeight: `500`,
    color: theme.palette.secondary[800],
    textOverflow: `ellipsis`,
    whiteSpace: `nowrap`,
    overflow: `hidden`,
}));

export const Email = styled("p")(({ theme }) => ({
    margin: `0`,
    width: `100%`,
    fontSize: `12px`,
    letterSpacing: `-0.2px`,
    color: theme.palette.secondary[600],
}));
