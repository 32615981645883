import moment from "moment";

const countDaysBetweenDates = ({ toDate, fromDate, format = "YYYY-MM-DD" }) => {
    let tDate, fDate;

    if (!toDate) {
        tDate = moment();
        return tDate.diff(fromDate, "days");
    } else if (!fromDate) {
        fDate = moment().format(format);
        return toDate.diff(fDate, "days");
    }
};

export default countDaysBetweenDates;
