import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { bindActionCreators } from "redux";
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

import * as projectManagementActions from '../../../../modules/projectManagement';

import compareVersions, {
    VERSION_CM_5579_ALL_PROJECTS
} from '../../../../cores/version';

const ProjectInfo = ({projectName, projectId}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const boundedProjectManagementActions = bindActionCreators(projectManagementActions, dispatch);

    const projectManagement = useSelector((state) => state.projectManagement);
    const user = useSelector((state) => state.user);
    const stage = useSelector((state) => state.stage);

    const [name, setName] = useState(projectName);
    const [id, setId] = useState(projectId);

    useEffect(() => {
        if (compareVersions(stage.version, VERSION_CM_5579_ALL_PROJECTS) >= 0) {
            if (projectManagement.allProjects && projectManagement.allProjects.length > 0) {
                const project = projectManagement.allProjects.filter((v) => v.id === projectId)[0];
                if (project) {
                    setName(project.name);
                    setId(project.id);
                }
            }
            else {
                boundedProjectManagementActions.getAllProjects().then((response) => {
                    const project = response.results.filter((v) => v.id === projectId)[0];
                    if (project) {
                        setName(project.name);
                        setId(project.id);
                    }
                });
            }
        } else {
            const activeStage = user.stages.data.filter((v) => v.id === stage.id)[0];
            if (activeStage) {
                const activeProject = activeStage.projects
                    ? activeStage.projects.filter((v) => v.id === projectId)[0]
                    : null;
    
                if (activeProject) {
                    setName(activeProject.name);
                    setId(activeProject.id);
                }
            }
        }
        
    }, []);

    return (
        <li>
            <strong className="title">{t(`common::label::${"Project"}`)}</strong>
            {name ? (
                <>
                    <div className="content">
                        <p>{name}</p>
                        <span className="text-secondary">{id}</span>
                    </div>
                </>
            ) : (
                <div className="content">{id}</div>
            )}
        </li>
    )
};
ProjectInfo.propTypes = {
    projectId: PropTypes.string
};

export default ProjectInfo;