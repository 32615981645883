import { axios } from "@cores/axiosWrapper";

export const getVocabularyAPI = async (stageId, apiEndpoint, projectId, vocabularyId) => {
    return axios.get(`${apiEndpoint}/dictation-vocabularies/${vocabularyId}`, {
        headers: { stageId, projectId },
    });
};

export const getVocabularyPhrasesAPI = async (stageId, apiEndpoint, projectId, vocabularyId) => {
    return axios.get(`${apiEndpoint}/dictation-vocabularies/${vocabularyId}/phrases`, {
        headers: { stageId, projectId },
    });
};

export const updateVocabularyAPI = async (stageId, apiEndpoint, projectId, vocabularyId, data) => {
    return axios.patch(`${apiEndpoint}/dictation-vocabularies/${vocabularyId}`, data, {
        headers: { stageId, projectId },
    });
};

export const createVocabularyAPI = async (stageId, apiEndpoint, projectId, data) => {
    return axios.post(`${apiEndpoint}/dictation-vocabularies`, data, {
        headers: { stageId, projectId },
    });
};

export const requestPhrasesActionsAPI = async (stageId, apiEndpoint, projectId, vocabularyId, data) => {
    return axios.post(`${apiEndpoint}/dictation-vocabularies/${vocabularyId}/phrases/actions`, data, {
        headers: { stageId, projectId },
    });
};
