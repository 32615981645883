import clsx from "clsx";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import uniqid from "uniqid";

import i18n from "../../lib/i18n";

const WithSelect = (props) => {
    const {
        isMulti,
        defaultValue,
        options,
        placeholder,
        disabled,
        hidden,
        size,
        isError,
        isTextWrap,
        hideSelectedOptions,
        closeMenuOnSelect,
        onChange,
        onFocus,
        onBlur,
        noOptionsMessage,
        ...otherProps
    } = props;

    const [id] = useState(uniqid());
    const [Components] = useState(makeAnimated());

    const [defaultValue_, setDefaultValue_] = useState("");
    const [value, setValue] = useState("");

    useEffect(() => {
        if (isMulti) {
            setDefaultValue_(defaultValue);
            setValue(defaultValue);
        } else if (options) {
            setDefaultValue_(options.filter((v) => v.value === defaultValue));
            setValue(options.filter((v) => v.value === defaultValue));
        }
    }, [isMulti, defaultValue, options]);

    return (
        <Select
            id={id}
            className={clsx("select2-container", {
                hidden,
                "select2-container-sm": size === "sm",
                "select2-container-text-wrap": isTextWrap,
                "is-error": isError,
            })}
            classNamePrefix={"select2-selection"}
            components={Components}
            isDisabled={disabled}
            defaultValue={defaultValue_}
            value={value}
            onChange={onChange}
            onFocus={(e) => {
                onFocus && onFocus(e);
                e.target.closest(".select2-container").classList.add("select2-focus");
            }}
            onBlur={(e) => {
                if (onBlur) onBlur(e);
                e.target.closest(".select2-container").classList.remove("select2-focus");
            }}
            placeholder={placeholder === undefined ? i18n.t(`common::label::Select...`) : placeholder}
            options={options}
            isOptionDisabled={(option) => option.disabled}
            isMulti={isMulti}
            hideSelectedOptions={hideSelectedOptions !== undefined ? hideSelectedOptions : false}
            closeMenuOnSelect={closeMenuOnSelect !== undefined ? closeMenuOnSelect : true}
            noOptionsMessage={() => {
                return noOptionsMessage ? noOptionsMessage : i18n.t(`common::label::No options`);
            }}
            menuPlacement={"auto"}
            {...otherProps}
            style={{
                '&': {
                    minWidth: 120
                }
            }}
        />
    );
};

const withCheckbox = (props) => {
    let { data, innerProps, getValue } = props;
    let selectedOptions = getValue();
    let _id = uniqid();

    return (
        <div {...innerProps}>
            <div className={"form-check mt-1 mb-1 ml-2 "}>
                <label htmlFor={_id} className={"form-check-label"}>
                    <input
                        id={_id}
                        type={"checkbox"}
                        className={"form-check-input"}
                        checked={selectedOptions.filter((v) => v.value === data.value).length > 0}
                        value={data.value}
                        readOnly={true}
                    />
                    <i className={"input-helper"}></i>
                    <span>{data.label}</span>
                </label>
            </div>
        </div>
    );
};

WithSelect.defaultProps = {
    isMulti: false,
};

export default WithSelect;
