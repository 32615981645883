import { handleActions } from "redux-actions";

const NAVIGATOR_ADMIN_PENDING = "NAVIGATOR_ADMIN_PENDING";
const NAVIGATOR_ADMIN_FAILURE = "NAVIGATOR_ADMIN_FAILURE";
const SET_NAVIGATOR_ADMIN_PROJECT = "SET_NAVIGATOR_ADMIN_PROJECT";

import { isLocal, isDev } from "@cores/enviromentExtension";
import compareVersions, {
    VERSION_CM_10137_CUSTOM_CONTENT_SCHEMAS,
    VERSION_CM_12123_METADATA_EXPORT,
    VERSION_CM_9322_EXTERNAL_SHARE_HISTORY,
} from "@cores/version";
import {
    IconStatistics,
    IconUser,
    IconGroup,
    IconRole,
    IconProjects,
    IconChannels,
    IconMetadata,
    IconSettingAdmin,
    IconHistory,
    IconSetting,
    IconSchema,
    IconExport,
} from "@mzc-cloudplex/icons";

const SPACE_MENU = {
    id: "spaces",
    name: `common::label::${"Administration"}`,
    iconClass: "sprite sprite-menu-channel",
    disabled: false,
    active: true,
    menus: [
        {
            id: "Statistics",
            name: `common::label::${"Statistics"}`,
            path: "/admin/space/statistics",
            icon: <IconStatistics size={16} />,
            iconClass: "sprite sprite-menu-statistics",
            disabled: false,
            active: true,
        },
        {
            id: "Access",
            name: `common::label::${"Access"}`,
            active: false,
            subMenus: [
                {
                    id: "users",
                    name: `common::label::${"Users"}`,
                    path: "/admin/space/users",
                    icon: <IconUser size={16} />,
                    iconClass: "sprite sprite-menu-user-inverse",
                    disabled: false,
                    active: true,
                },
                {
                    id: "groups",
                    name: `common::label::${"Groups"}`,
                    path: "/admin/space/groups",
                    icon: <IconGroup size={16} />,
                    iconClass: "sprite sprite-menu-team-inverse",
                    disabled: false,
                    active: true,
                },
                {
                    id: "roles",
                    name: `common::label::${"Roles"}`,
                    icon: <IconRole size={16} />,
                    path: "/admin/space/roles",
                    iconClass: "sprite sprite-menu-role",
                    disabled: false,
                    active: true,
                },
            ],
        },
        {
            id: "Manage",
            name: `common::label::${"Manage"}`,
            active: false,
            subMenus: [
                {
                    id: "projects",
                    name: `common::label::${"Projects"}`,
                    path: "/admin/space/projects",
                    icon: <IconProjects size={16} />,
                    iconClass: "sprite sprite-menu-project",
                    disabled: false,
                    active: true,
                },
                {
                    id: "channels",
                    name: `common::label::${"Channels"}`,
                    path: "/admin/space/live/channels",
                    icon: <IconChannels size={16} />,
                    iconClass: "sprite sprite-menu-channel",
                    disabled: false,
                    active: true,
                },
                {
                    id: "metadataSchemas",
                    name: `common::label::${"Metadata"}`,
                    path: "/admin/space/metadata",
                    icon: <IconMetadata size={16} />,
                    iconClass: "sprite sprite-menu-metadata",
                    disabled: false,
                    active: true,
                },
                {
                    id: "contentSchemas",
                    name: `common::label::${"Content Schemas"}`,
                    path: "/admin/space/content-schemas",
                    icon: <IconSchema size={16} />,
                    iconClass: "sprite sprite-menu-metadata",
                    disabled: false,
                    active: true,
                },
            ],
        },
        {
            id: "settings",
            name: `common::label::${"Settings"}`,
            path: "/admin/space/settings",
            icon: <IconSettingAdmin size={16} />,
            iconClass: "sprite sprite-menu-setting-inverse",
            disabled: false,
            active: true,
        },
    ],
};
const PROJECTS_MENU = {
    id: "projects",
    name: `common::label::${"Project settings"}`,
    iconClass: "sprite sprite-menu-channel",
    disabled: false,
    active: false,
    menus: [
        {
            id: "Access",
            name: `common::label::${"Access"}`,
            active: false,
            subMenus: [
                {
                    id: "authorities",
                    name: `common::label::${"Users"}`,
                    path: "/admin/projects/projectId/users",
                    iconClass: "sprite sprite-menu-user",
                    disabled: false,
                    active: true,
                },
                {
                    id: "authorities",
                    name: `common::label::${"Groups"}`,
                    path: "/admin/projects/projectId/groups",
                    iconClass: "sprite sprite-menu-team",
                    disabled: false,
                    active: true,
                },
                {
                    id: "roles",
                    name: `common::label::${"Roles"}`,
                    path: "/admin/projects/projectId/roles",
                    iconClass: "sprite sprite-menu-role",
                    disabled: false,
                    active: true,
                },
            ],
        },
        {
            id: "Manage",
            name: `common::label::${"Manage"}`,
            active: false,
            subMenus: [
                {
                    id: "category",
                    name: `common::label::${"Categories"}`,
                    path: "/admin/projects/projectId/category-groups",
                    iconClass: "sprite sprite-menu-category",
                    disabled: false,
                    active: true,
                },
                {
                    id: "eventTriggers",
                    name: `common::label::${"Event triggers"}`,
                    path: "/admin/projects/projectId/event-triggers",
                    iconClass: "sprite sprite-menu-eventtrigger",
                    disabled: false,
                    active: true,
                },
            ],
        },
        {
            id: "settings",
            name: `common::label::${"Settings"}`,
            path: "/admin/projects/projectId/settings",
            icn: <IconSetting size={16} />,
            iconClass: "sprite sprite-menu-setting",
            disabled: false,
            active: true,
        },
    ],
    // subMenus: [{
    //     id: 'authorities',
    //     name: `common::label::${"Users"}`,
    //     path: '/admin/projects/projectId/authorities/users',
    //     iconClass: 'sprite sprite-menu-user',
    //     disabled: false,
    //     active: true
    // }, {
    //     id: 'authorities',
    //     name: `common::label::${"Teams"}`,
    //     path: '/admin/projects/projectId/authorities/teams',
    //     iconClass: 'sprite sprite-menu-team',
    //     disabled: false,
    //     active: true
    // }, {
    //     id: 'roles',
    //     name: `common::label::${"Roles"}`,
    //     path: '/admin/projects/projectId/roles',
    //     iconClass: 'sprite sprite-menu-role',
    //     disabled: false,
    //     active: true
    // }, {
    //     id: 'category',
    //     name: `common::label::${"Categories"}`,
    //     path: '/admin/projects/projectId/category-groups',
    //     iconClass: 'sprite sprite-menu-category',
    //     disabled: false,
    //     active: true
    // }, {
    //     id: 'eventTriggers',
    //     name: `common::label::${"Event triggers"}`,
    //     path: '/admin/projects/projectId/event-triggers',
    //     iconClass: 'sprite sprite-menu-eventtrigger',
    //     disabled: false,
    //     active: true
    // }, {
    //     id: 'settings',
    //     name: `common::label::${"Settings"}`,
    //     path: '/admin/projects/projectId/settings',
    //     iconClass: 'sprite sprite-menu-setting',
    //     disabled: false,
    //     active: true
    // }]
};

function getSpaceMenus(space) {
    const { extensions } = space;
    const canManageRoles = extensions && extensions["ACTIVE_ROLE_MANAGEMENT"] === "true";
    const hideLive = extensions && extensions["HIDE_LIVE"] === "true";
    const manageSubMenus = [
        {
            id: "projects",
            name: `common::label::${"Projects"}`,
            path: `/admin/spaces/${space.id}/project-list`,
            icon: <IconProjects size={16} />,
            iconClass: "sprite sprite-menu-project",
            disabled: false,
            active: true,
        },
    ];
    if (!hideLive) {
        manageSubMenus.push({
            id: "channels",
            name: `common::label::${"Channels"}`,
            path: `/admin/spaces/${space.id}/live/channels`,
            icon: <IconChannels size={16} />,
            iconClass: "sprite sprite-menu-channel",
            disabled: false,
            active: true,
        });
    }
    manageSubMenus.push(
        ...[
            {
                id: "metadataSchemas",
                name: `common::label::${"Metadata"}`,
                path: `/admin/spaces/${space.id}/metadata`,
                icon: <IconMetadata size={16} />,
                iconClass: "sprite sprite-menu-metadata",
                disabled: false,
                active: true,
            },
            {
                id: "contentSchemas",
                name: `common::label::${"Content Schemas"}`,
                path: `/admin/spaces/${space.id}/content-schemas`,
                icon: <IconSchema size={16} />,
                iconClass: "sprite sprite-menu-metadata",
                disabled: compareVersions(space.version, VERSION_CM_10137_CUSTOM_CONTENT_SCHEMAS) < 0,
                active: true,
            },
        ],
    );
    let menus = [];
    if (isLocal || isDev) {
        menus.push({
            id: "Statistics",
            name: `common::label::${"Statistics"}`,
            path: `/admin/spaces/${space.id}/statistics`,
            icon: <IconStatistics size={16} />,
            iconClass: "sprite sprite-menu-statistics",
            disabled: false,
            active: true,
        });
    }
    menus = menus.concat([
        {
            id: "Access",
            name: `common::label::${"Access"}`,
            active: false,
            subMenus: [
                {
                    id: "users",
                    name: `common::label::${"Users"}`,
                    path: `/admin/spaces/${space.id}/users`,
                    icon: <IconUser size={16} />,
                    iconClass: "sprite sprite-menu-user-inverse",
                    disabled: false,
                    active: true,
                },
                {
                    id: "groups",
                    name: `common::label::${"Groups"}`,
                    path: `/admin/spaces/${space.id}/groups`,
                    icon: <IconGroup size={16} />,
                    iconClass: "sprite sprite-menu-team-inverse",
                    disabled: false,
                    active: true,
                },
                {
                    id: "roles",
                    name: `common::label::${"Roles"}`,
                    path: `/admin/spaces/${space.id}/roles`,
                    icon: <IconRole size={16} />,
                    iconClass: "sprite sprite-menu-role",
                    disabled: false,
                    active: true,
                },
            ],
        },
        {
            id: "Manage",
            name: `common::label::${"Manage"}`,
            active: false,
            subMenus: manageSubMenus,
        },
        {
            id: "histories",
            name: `common::label::${"Histories"}`,
            path: `/admin/spaces/${space.id}/histories`,
            icon: <IconHistory size={16} />,
            iconClass: "sprite sprite-menu-setting-inverse",
            disabled: compareVersions(space.version, VERSION_CM_9322_EXTERNAL_SHARE_HISTORY) < 0,
            active: true,
        },
        {
            id: "metadataExport",
            name: `common::label::${"Metadata Export"}`,
            path: `/admin/spaces/${space.id}/metadata-export`,
            icon: <IconExport size={16} />,
            iconClass: "sprite sprite-menu-setting-inverse",
            disabled: compareVersions(space.version, VERSION_CM_12123_METADATA_EXPORT) < 0,
            active: true,
        },
        {
            id: "settings",
            name: `common::label::${"Settings"}`,
            path: `/admin/spaces/${space.id}/settings`,
            icon: <IconSettingAdmin size={16} />,
            iconClass: "sprite sprite-menu-setting-inverse",
            disabled: false,
            active: true,
        },
    ]);

    if (!canManageRoles) {
        const access = menus.find((v) => v.id === "Access");
        access.subMenus = access.subMenus.filter((s) => s.id !== "roles");
    }

    return {
        id: "spaces",
        name: `common::label::${"Administration"}`,
        iconClass: "sprite sprite-menu-channel",
        disabled: false,
        active: true,
        menus,
    };
}

function getProjectMenus(space, project) {
    const { extensions } = space;
    const canManageRoles = extensions && extensions["ACTIVE_ROLE_MANAGEMENT"] === "true";
    const menus = [
        {
            id: "Access",
            name: `common::label::${"Access"}`,
            active: false,
            subMenus: [
                {
                    id: "authorities",
                    name: `common::label::${"Users"}`,
                    path: `/admin/spaces/${space.id}/projects/${project.id}/users`,
                    iconClass: "sprite sprite-menu-user",
                    disabled: false,
                    active: true,
                },
                {
                    id: "authorities",
                    name: `common::label::${"Groups"}`,
                    path: `/admin/spaces/${space.id}/projects/${project.id}/groups`,
                    iconClass: "sprite sprite-menu-team",
                    disabled: false,
                    active: true,
                },
                {
                    id: "roles",
                    name: `common::label::${"Roles"}`,
                    path: `/admin/spaces/${space.id}/projects/${project.id}/roles`,
                    iconClass: "sprite sprite-menu-role",
                    disabled: false,
                    active: true,
                },
            ],
        },
        {
            id: "Manage",
            name: `common::label::${"Manage"}`,
            active: false,
            subMenus: [
                {
                    id: "category",
                    name: `common::label::${"Categories"}`,
                    path: `/admin/spaces/${space.id}/projects/${project.id}/category-groups`,
                    iconClass: "sprite sprite-menu-category",
                    disabled: false,
                    active: true,
                },
                {
                    id: "eventTriggers",
                    name: `common::label::${"Event triggers"}`,
                    path: `/admin/spaces/${space.id}/projects/${project.id}/event-triggers`,
                    iconClass: "sprite sprite-menu-eventtrigger",
                    disabled: false,
                    active: true,
                },
            ],
        },
        {
            id: "settings",
            name: `common::label::${"Settings"}`,
            path: `/admin/spaces/${space.id}/projects/${project.id}/settings`,
            iconClass: "sprite sprite-menu-setting",
            icon: <IconSetting size={16} />,
            disabled: false,
            active: true,
        },
    ];

    if (!canManageRoles) {
        const access = menus.find((v) => v.id === "Access");
        access.subMenus = access.subMenus.filter((s) => s.id !== "roles");
    }

    return {
        id: "projects",
        name: `common::label::${"Project settings"}`,
        iconClass: "sprite sprite-menu-channel",
        disabled: false,
        active: false,
        menus,
    };
}

const initialState = {
    pending: false,
    error: false,
    activeProject: {
        id: undefined,
        name: undefined,
    },
    data: {
        space: Object.assign(SPACE_MENU),
        projects: Object.assign(PROJECTS_MENU),
    },
};

export const setProject = (data) => async (dispatch, getState) => {
    dispatch({
        type: SET_NAVIGATOR_ADMIN_PROJECT,
        payload: {
            data,
        },
    });
};

export default handleActions(
    {
        [NAVIGATOR_ADMIN_PENDING]: (state) => {
            return {
                ...state,
                pending: true,
                error: false,
            };
        },
        [NAVIGATOR_ADMIN_FAILURE]: (state) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [SET_NAVIGATOR_ADMIN_PROJECT]: (state, action) => {
            const { data } = action.payload;
            const { space, project } = data;

            return {
                ...state,
                activeProject: {
                    id: project.id,
                    name: project.name,
                },
                data: {
                    space: getSpaceMenus(space),
                    projects: getProjectMenus(space, project),
                },
            };
        },
    },
    initialState,
);
