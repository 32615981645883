import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import App from "./App";

const Root = ({ store }) => (
    <Provider store={store}>
        <BrowserRouter basename={"/"}>
            <App />
        </BrowserRouter>
    </Provider>
);

export default Root;
