import { handleActions, createAction } from "redux-actions";
import fp from "lodash/fp";

import { axios, axiosWithoutHeaders } from "../cores/axiosWrapper";
import StoragesApi from "./apis/storages";
import config from "../config";
import queryString from "../cores/queryString";
import uniqid from "uniqid";
import StateManager from "react-select";
import moment from "moment";
import compareVersions from "@cores/version";
import { VERSION_CM_8667_STORAGE_V3 } from "../cores/version";

function getResourceBucketsAPI(apiEndpoint, stageId, projectId) {
    return axios.get(`${apiEndpoint}/resource-buckets`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function putBulkResourceBucketsAPI(apiEndpoint, stageId, projectId, data) {
    return axios.put(`${apiEndpoint}/resource-buckets/bulk`, data, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function getFoldersAPI(apiEndpoint, projectId, bucket, key) {
    const queries = {
        prefix: key || "",
    };
    return axios.get(`${apiEndpoint}/directories/${bucket}/folders?${queryString.stringify(queries)}`, {
        headers: { projectId },
    });
}

function getObjectsAPI(apiEndpoint, bucket, key, condition) {
    const queries = condition.nextToken
        ? {
              nextToken: condition.nextToken,
          }
        : {
              prefix: key || "",
              isFolderSearch:
                  condition.keyword || condition.storageClass || (condition.beginDate && condition.endDate)
                      ? false
                      : true,
              key: condition.keyword,
              storageClass: condition.storageClass,
              offset: condition.offset,
              limit: condition.limit,
              maxKeys: condition.defaultViewCountByToken,
          };

    if (condition.beginDate && condition.endDate) {
        const startAt = `${moment(`${condition.beginDate.format("YYYY-MM-DD")} 00:00:00`)
            .utc()
            .format("YYYY-MM-DD")}T${moment(`${condition.beginDate.format("YYYY-MM-DD")} 00:00:00`)
            .utc()
            .format("HH:mm:ss")}Z`;
        const endAt = `${moment(`${condition.endDate.format("YYYY-MM-DD")} 00:00:00`)
            .utc()
            .format("YYYY-MM-DD")}T${moment(`${condition.endDate.format("YYYY-MM-DD")} 00:00:00`)
            .utc()
            .format("HH:mm:ss")}Z`;

        queries.lastModifiedAt = `${startAt}~${endAt}`;
    }

    return axios.get(`${apiEndpoint}/directories/${bucket}/objects?${queryString.stringify(queries)}`);
}

const SOURCES_CHANGE_BUCEKT_PENDING = "SOURCES_CHANGE_BUCEKT_PENDING";
const SOURCES_CHANGE_BUCEKT_FAILURE = "SOURCES_CHANGE_BUCEKT_FAILURE";
const GET_RESOUECE_BUCKETS_SUCCESS = "GET_RESOUECE_BUCKETS_SUCCESS";
const GET_RESOUECE_BUCKETS_FAILURE = "GET_RESOUECE_BUCKETS_FAILURE";
const SOURCES_SET_BUCKET = "SOURCES_SET_BUCKET";
const GET_SOURCES_FOLDER_SUCCESS = "GET_SOURCES_FOLDER_SUCCESS";
const GET_SOURCES_FOLDER_FAILURE = "GET_SOURCES_FOLDER_FAILURE";
const GET_SOURCES_OBJECTS_PENDING = "GET_SOURCES_OBJECTS_PENDING";
const GET_SOURCES_OBJECTS_SUCCESS = "GET_SOURCES_OBJECTS_SUCCESS";
const GET_SOURCES_OBJECTS_FAILURE = "GET_SOURCES_OBJECTS_FAILURE";
const SOURCES_UPDATE_STATE = "SOURCES_UPDATE_STATE";
const SOURCES_UPDATE_SEARCH = "SOURCES_UPDATE_SEARCH";
const SOURCES_RESET_SEARCH = "SOURCES_RESET_SEARCH";
const SOURCES_TOGGLE_ACTION_BUTTON = "SOURCES_TOGGLE_ACTION_BUTTON";
const SOURCES_CLEAR = "SOURCES_CLEAR";
const SOURCES_PENDING_HIERARCHY = "SOURCES_PENDING_HIERARCHY";
const SOURCES_INIT_SEARCH = "SOURCES_INIT_SEARCH";
const SOURCE_RESET_RESOURCE_BUCKETS = "SOURCE_RESET_RESOURCE_BUCKETS";
const RECOVERY_SOURCES_DATA = "RECOVERY_SOURCES_DATA";
const SOURCE_VIEW_RELOAD = "SOURCE_VIEW_RELOAD";
const REFRESH_RESOURCE_BUCKET_STATUS = "REFRESH_RESOURCE_BUCKET_STATUS";

//reducer
const initialState = {
    pending: false,
    error: false,
    resourceBuckets: [],
    search: {
        isFolderSearch: true,
        keyword: "",
        storageClass: "",
        beginDate: null,
        endDate: null,
        offset: 0,
        limit: 200,
        defaultViewCountByToken: 200,
    },
    hierarchy: {
        pending: false,
        isActiveView: "folder",
        folder: {},
    },
    objects: [],
    objectTotalCount: 0,
    objectsCancelTokenSource: null,
    forceReload: false,
};

export default handleActions(
    {
        [SOURCES_CHANGE_BUCEKT_PENDING]: (state, action) => {
            return {
                ...state,
                selectedBucket: action.payload,
                pending: true,
                error: false,
            };
        },
        [SOURCES_CHANGE_BUCEKT_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [GET_RESOUECE_BUCKETS_SUCCESS]: (state, action) => {
            return {
                ...state,
                resourceBuckets: action.payload || [],
                hierarchy: {
                    ...state.hierarchy,
                    pending: false,
                    error: false,
                    folder: {
                        ...state.hierarchy.folder,
                        data: action.payload || {},
                        history: {
                            index: 0,
                            items: action.payload && action.payload.length > 0 ? [action.payload[0]] : [],
                            navView: action.payload && action.payload.length > 0 ? [action.payload[0]] : [],
                        },
                    },
                },
            };
        },
        [GET_RESOUECE_BUCKETS_FAILURE]: (state, action) => {
            return {
                ...state,
                hierarchy: {
                    ...state.hierarchy,
                    pending: false,
                    error: action.payload,
                },
            };
        },
        [SOURCES_SET_BUCKET]: (state, action) => {
            return {
                ...state,
                hierarchy: {
                    ...state.hierarchy,
                    folder: {
                        ...state.hierarchy.folder,
                        data: action.payload,
                        history: {
                            index: 0,
                            items: [action.payload[0]],
                            navView: [action.payload[0]],
                        },
                    },
                },
            };
        },
        [GET_SOURCES_FOLDER_SUCCESS]: (state, action) => {
            return {
                ...state,
                hierarchy: {
                    ...state.hierarchy,
                    pending: false,
                    error: false,
                },
            };
        },
        [GET_SOURCES_FOLDER_FAILURE]: (state, action) => {
            return {
                ...state,
                hierarchy: {
                    ...state.hierarchy,
                    pending: false,
                    error: true,
                },
            };
        },
        [GET_SOURCES_OBJECTS_PENDING]: (state, action) => {
            return {
                ...state,
                pending: true,
                error: false,
                objects: [],
                ...action.payload,
            };
        },
        [GET_SOURCES_OBJECTS_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            return {
                ...state,
                pending: false,
                error: false,
                objects: data.items,
                objectTotalCount: data.totalCount,
                objectsCancelTokenSource: null,
            };
        },
        [GET_SOURCES_OBJECTS_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: true,
                obejcts: [],
                objectsCancelTokenSource: null,
            };
        },
        [SOURCES_UPDATE_STATE]: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
        [SOURCES_UPDATE_SEARCH]: (state, action) => {
            return {
                ...state,
                search: {
                    ...state.search,
                    ...action.payload,
                },
            };
        },
        [SOURCES_RESET_SEARCH]: (state) => {
            return {
                ...state,
                search: {
                    ...initialState.search,
                },
            };
        },
        [SOURCES_TOGGLE_ACTION_BUTTON]: (state, action) => {
            return {
                ...state,
                objects:
                    state.objects &&
                    state.objects.map((v) => {
                        return {
                            ...v,
                            isShowActionButton: v.path === action.payload.path ? !v.isShowActionButton : false,
                            disabledCreateJob: action.payload.disabledCreateJob,
                        };
                    }),
            };
        },
        [SOURCES_CLEAR]: (state) => {
            return {
                ...initialState,
            };
        },
        [SOURCES_PENDING_HIERARCHY]: (state, action) => {
            return {
                ...state,
                hierarchy: {
                    ...state.hierarchy,
                    pending: action.payload,
                    error: false,
                },
            };
        },
        [SOURCES_INIT_SEARCH]: (state) => {
            return {
                ...state,
                search: {
                    ...state.search,
                    keyword: "",
                },
            };
        },
        [SOURCE_RESET_RESOURCE_BUCKETS]: (state) => {
            return {
                ...state,
                resourceBuckets: [],
            };
        },
        [RECOVERY_SOURCES_DATA]: (state, action) => {
            const oldData = action.payload;
            return {
                ...state,
                ...oldData,
            };
        },
        [SOURCE_VIEW_RELOAD]: (state, action) => {
            const forceReload = action.payload;
            return {
                ...state,
                forceReload,
            };
        },
        [REFRESH_RESOURCE_BUCKET_STATUS]: (state, action) => {
            const { id, status } = action.payload;

            console.debug("gyu idm", id, status);
            return {
                ...state,
                hierarchy: {
                    ...state.hierarchy,
                    folder: {
                        ...state.hierarchy.folder,
                        data: state.hierarchy.folder.data.map((bucket) =>
                            bucket.id === id
                                ? {
                                      ...bucket,
                                      indexingState: status,
                                  }
                                : bucket,
                        ),
                    },
                },
            };
        },
    },
    initialState,
);
//action

export const setBuckets = createAction(SOURCES_SET_BUCKET);
export const updateState = createAction(SOURCES_UPDATE_STATE);
export const updateSearch = createAction(SOURCES_UPDATE_SEARCH);
export const resetSearch = createAction(SOURCES_RESET_SEARCH);
export const toggleActionButton = createAction(SOURCES_TOGGLE_ACTION_BUTTON);
export const clear = createAction(SOURCES_CLEAR);
export const pendingHierarchy = createAction(SOURCES_PENDING_HIERARCHY);
export const resetResourceBuckets = createAction(SOURCE_RESET_RESOURCE_BUCKETS);
export const recoverySourcesData = createAction(RECOVERY_SOURCES_DATA);
export const sourceViewReload = createAction(SOURCE_VIEW_RELOAD);

export const getResourceBuckets = (projectId) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: SOURCES_PENDING_HIERARCHY, payload: true });

    return new Promise((resolve, reject) => {
        return StoragesApi.listResourceBuckets(stage.endpoint, stage.id, projectId)
            .then((response) => {
                const responseBuckets = response.data && response.data.buckets;

                const convertBuckets =
                    responseBuckets &&
                    responseBuckets.map((v) => {
                        const searchableBucket = v.searchableBucket;
                        const searchableuBucketId = searchableBucket && searchableBucket.id;

                        return {
                            id: searchableuBucketId,
                            code: searchableuBucketId,
                            children: [],
                            name: searchableBucket && searchableBucket.bucketName,
                            order: v.order,
                            indexingState: searchableBucket && searchableBucket.indexingState,
                            indexingRequestedAt: searchableBucket && searchableBucket.indexingRequestedAt,
                            indexingCompletedAt: searchableBucket && searchableBucket.indexingCompletedAt,
                            description: searchableBucket && searchableBucket.description,
                            prefix: ``,
                            isEmpty: false,
                            isFold: false,
                            isSelected: false,
                            type: "bucket",
                            uploadable: fp.getOr(false, "isUploadable", searchableBucket),
                            downloadable: fp.getOr(false, "isDownloadable", searchableBucket),
                            searchable: searchableBucket.isSearchable,
                            isLocked: searchableBucket.isLocked,
                            lockedAt: searchableBucket.lockedAt,
                            lockedBy: searchableBucket.lockedBy,
                        };
                    });
                dispatch({
                    type: GET_RESOUECE_BUCKETS_SUCCESS,
                    payload: convertBuckets,
                });
                resolve(convertBuckets && convertBuckets[0]);
            })
            .catch((error) => {
                dispatch({
                    type: GET_RESOUECE_BUCKETS_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const refreshBucketStatus = (bucketId) => (dispatch, getState) => {
    const { stage, project } = getState();
    const projectId = project.id;

    return new Promise((resolve, reject) => {
        return StoragesApi.listResourceBuckets(stage.endpoint, stage.id, projectId)
            .then((response) => {
                const responseBuckets = response.data && response.data.buckets;
                const refreshedBucket = responseBuckets.filter((bucket) => bucket.searchableBucket.id === bucketId);

                if (!refreshedBucket || refreshedBucket.length <= 0) {
                    reject("[ERROR] RefreshBucketStatus refresedBucket", refresedBucket);
                }

                const searchableBucket = refreshedBucket[0].searchableBucket;

                dispatch({
                    type: REFRESH_RESOURCE_BUCKET_STATUS,
                    payload: {
                        id: searchableBucket.id,
                        status: searchableBucket.indexingState,
                    },
                });

                resolve();
            })
            .catch((error) => {
                reject("[ERROR] RefreshBucketStatus refresedBucket", error);
            });
    });
};

export const getBucketsData = () => (dispatch, getState) => {
    const { stage, project } = getState();
    const apiEndpoint = stage.endpoint;
    const stageId = stage.id;
    const projectId = project.id;

    return new Promise((resolve, reject) => {
        return StoragesApi.listResourceBuckets(apiEndpoint, stageId, projectId)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const registerResourceBuckets = (projectId, data) => (dispatch, getState) => {
    const { stage } = getState();

    return new Promise((resolve, reject) => {
        return StoragesApi.putBulkResourceBuckets(stage.endpoint, stage.id, projectId, data)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getFolders = (bucket, key) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: SOURCES_INIT_SEARCH });

    //version나누기(v3/추가)
    return new Promise((resolve, reject) => {
        return StoragesApi.listFolders(
            stage.endpoint,
            project.id,
            compareVersions(stage.version, VERSION_CM_8667_STORAGE_V3) >= 0,
            bucket,
            key,
        )
            .then((response) => {
                dispatch({
                    type: GET_SOURCES_FOLDER_SUCCESS,
                    payload: response,
                });
                resolve(response);
            })
            .catch((error) => {
                dispatch({
                    type: GET_SOURCES_FOLDER_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const getObjectsData = (bucket, key) => (dispatch, getState) => {
    const { sources, stage, project } = getState();
    if (sources.objectsCancelTokenSource) {
        sources.objectsCancelTokenSource.cancel();
    }

    return new Promise((resolve, reject) => {
        const listObjectsFunction =
            compareVersions(stage.version, VERSION_CM_8667_STORAGE_V3) >= 0
                ? StoragesApi.listObjectsV3
                : StoragesApi.listObjects;
        return listObjectsFunction(stage.endpoint, project.id, bucket, key, sources.search)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getObjects = (bucket, key, nextToken) => (dispatch, getState) => {
    const { sources, stage, project } = getState();

    if (sources.objectsCancelTokenSource) {
        sources.objectsCancelTokenSource.cancel();
    }

    const cancelTokenSource = axios._cancelToken.source();

    dispatch({
        type: GET_SOURCES_OBJECTS_PENDING,
        payload: {
            objectsCancelTokenSource: cancelTokenSource,
        },
    });

    const searchCondition = nextToken ? { nextToken } : sources.search;

    return new Promise((resolve, reject) => {
        const listObjectsFunction =
            compareVersions(stage.version, VERSION_CM_8667_STORAGE_V3) >= 0
                ? StoragesApi.listObjectsV3
                : StoragesApi.listObjects;
        return listObjectsFunction(stage.endpoint, project.id, bucket, key, searchCondition)
            .then((response) => {
                dispatch({
                    type: GET_SOURCES_OBJECTS_SUCCESS,
                    payload: response,
                });
                resolve(response);
            })
            .catch((error) => {
                dispatch({
                    type: GET_SOURCES_OBJECTS_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const getBucketFolders = (bucket, key) => (dispatch, getState) => {
    const { sources, stage, project } = getState();

    if (sources.objectsCancelTokenSource) {
        sources.objectsCancelTokenSource.cancel();
    }

    const cancelTokenSource = axios._cancelToken.source();
    return new Promise((resolve, reject) => {
        return StoragesApi.listFolders(
            stage.endpoint,
            project.id,
            compareVersions(stage.version, VERSION_CM_8667_STORAGE_V3) >= 0,
            bucket,
            key,
        )
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const deleteSources = (bucketName, items) => (dispatch, getState) => {
    const { stage, project } = getState();
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;
    const stageId = stage.id;
    const actions = {
        action: "DELETE",
        content: {
            from: {
                items,
            },
        },
    };
    return new Promise((resolve, reject) => {
        return StoragesApi.mutate(apiEndpoint, stageId, stage.version, projectId, bucketName, actions)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const renameSources = (bucketName, to, items) => (dispatch, getState) => {
    const { stage, project } = getState();
    // const cancelTokenSource = axios._cancelToken.source();
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;
    const stageId = stage.id;
    const actions = {
        action: "RENAME",
        content: {
            to,
            from: {
                items,
            },
        },
    };
    return new Promise((resolve, reject) => {
        return StoragesApi.mutate(apiEndpoint, stageId, stage.version, projectId, bucketName, actions)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const moveSources = (bucketName, to, items, option, validated) => (dispatch, getState) => {
    const { stage, project } = getState();
    // const cancelTokenSource = axios._cancelToken.source();
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;
    const stageId = stage.id;
    const actions = {
        action: "MOVE",
        content: {
            validated: validated || false,
            to,
            from: {
                items: items.map((item) => {
                    if (option) {
                        item.option = option;
                    }
                    return item;
                }),
            },
        },
    };
    return new Promise((resolve, reject) => {
        return StoragesApi.mutate(apiEndpoint, stageId, stage.version, projectId, bucketName, actions)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                // TODO: 여기
                console.log(`kyokyokyo - move`);
                reject(error);
            });
    });
};

export const copySources = (bucketName, to, items, option, validated) => (dispatch, getState) => {
    const { stage, project } = getState();
    // const cancelTokenSource = axios._cancelToken.source();
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;
    const stageId = stage.id;
    const actions = {
        action: "COPY",
        content: {
            to,
            validated: validated || false,
            from: {
                items: items.map((item) => {
                    if (option) {
                        item.option = option;
                    }
                    return item;
                }),
            },
        },
    };
    return new Promise((resolve, reject) => {
        return StoragesApi.mutate(apiEndpoint, stageId, stage.version, projectId, bucketName, actions)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                // TODO: 여기
                console.log(`kyokyokyo - copy`);
                reject(error);
            });
    });
};

export const validationSources = (bucketName, action, to, items) => (dispatch, getState) => {
    const { stage, project } = getState();
    // const cancelTokenSource = axios._cancelToken.source();
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;
    const stageId = stage.id;
    const actions = {
        action: "VALIDATE",
        content: {
            action,
            to,
            from: {
                items,
            },
        },
    };

    console.log(`validate: actions ${JSON.stringify(actions)}`);
    return new Promise((resolve, reject) => {
        return StoragesApi.mutate(apiEndpoint, stageId, stage.version, projectId, bucketName, actions)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                // TODO: 여기
                console.log(`kyokyokyo - validate`);
                reject(error);
            });
    });
};

export const createFolder = (bucketName, items) => (dispatch, getState) => {
    const { stage, project } = getState();
    // const cancelTokenSource = axios._cancelToken.source();
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;
    const stageId = stage.id;
    const actions = {
        action: "NEW_FOLDER",
        content: {
            from: {
                items,
            },
        },
    };
    return new Promise((resolve, reject) => {
        return StoragesApi.mutate(apiEndpoint, stageId, stage.version, projectId, bucketName, actions)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

function getJobStatusActions(apiEndpoint, stageId, projectId, bucketName, actions) {
    return axios.post(`${apiEndpoint}/v2/jobs/actions`, actions, {
        headers: {
            stageId,
            projectId,
        },
    });
}

export const getJobStatus = (bucketName, jobId) => (dispatch, getState) => {
    const { sources, stage, project } = getState();
    // const cancelTokenSource = axios._cancelToken.source();
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;
    const stageId = stage.id;
    const actions = {
        action: "GET_JOB_STATUS",
        content: {
            jobIds: [jobId],
        },
    };
    return new Promise((resolve, reject) => {
        return getJobStatusActions(apiEndpoint, stageId, projectId, bucketName, actions)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
