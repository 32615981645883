import { createAction, handleActions } from "redux-actions";
import { getCustomContentsAPI } from "@modules/apis/custom-contents";
import { LIST_VIEW_TYPE } from "@constants";
import { deleteVocabularyAPI, getVocabulariesAPI } from "@modules/apis/vocabularies";

const initialState = {
    data: null,
    pending: false,
    error: false,
    isCardView: true,
};

const GET_VOCABULARIES_PENDING = "GET_VOCABULARIES_PENDING";
const GET_VOCABULARIES_SUCCESS = "GET_VOCABULARIES_SUCCESS";
const GET_VOCABULARIES_FAILURE = "GET_VOCABULARIES_FAILURE";
const DELETE_VOCABULARY_SUCCESS = "DELETE_VOCABULARIES_SUCCESS";
const RESET_VOCABULARIES = "RESET_VOCABULARIES";

export default handleActions(
    {
        [GET_VOCABULARIES_PENDING]: (state) => {
            return { ...state, data: null, pending: true, error: false };
        },
        [GET_VOCABULARIES_SUCCESS]: (state, action) => {
            return { ...state, data: action.payload, pending: false };
        },
        [GET_VOCABULARIES_FAILURE]: (state, action) => {
            return { ...state, pending: false, error: action.payload };
        },
        [RESET_VOCABULARIES]: (state) => {
            return { ...state, data: null, pending: false, error: false };
        },
    },
    initialState,
);

export const resetVocabularies = createAction(RESET_VOCABULARIES);

export const getVocabularies = (params) => (dispatch, getState) => {
    const { stage, project, vocabularies } = getState();

    return new Promise(async (resolve, reject) => {
        try {
            dispatch({
                type: GET_VOCABULARIES_PENDING,
            });
            const { data } = await getVocabulariesAPI(stage.id, stage.endpoint, project.id, params);

            dispatch({
                type: GET_VOCABULARIES_SUCCESS,
                payload: params.nextToken ? [...vocabularies.data, ...data.results] : data.results,
            });

            resolve(data.nextToken);
        } catch (error) {
            dispatch({ type: GET_VOCABULARIES_FAILURE, payload: error });
            reject(null);
        }
    });
};

export const deleteVocabulary = (vocabularyId) => (dispatch, getState) => {
    const { stage, project } = getState();

    return new Promise(async (resolve, reject) => {
        try {
            const { data } = await deleteVocabularyAPI(stage.id, stage.endpoint, project.id, vocabularyId);

            dispatch({
                type: DELETE_VOCABULARY_SUCCESS,
                payload: vocabularyId,
            });

            resolve();
        } catch (error) {
            reject(error);
        }
    });
};
