import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const PageHeader = (props) => {
    const { className, ...otherProps } = props;
    return <div {...otherProps} className={clsx("page-header", className)} />;
};
PageHeader.propTypes = {
    className: PropTypes.string,
    renderPageInfo: PropTypes.func,
    renderPageTools: PropTypes.func,
};

export default PageHeader;
