import { Theme, CSSObject, styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { alpha, keyframes } from "@mui/material";

import { Link as MuiLink, Button as MuiButton } from "@mzc-pdc/ui";
import theme from "@styles/theme";

const drawerWidth = 256;

export const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    // padding: `12px`,
    overflowX: "hidden",
});

export const closedMixin = (theme) => ({
    transition: theme.transitions.create("width ", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    width: `64px`,
    overflowX: "hidden",
});

export const ProjectSettingButton = styled(MuiButton)(({ theme }) => ({
    display: `inline-flex`,
    width: `-webkit-fill-available`,
    height: `40px`,
    padding: `0 6px 0 16px`,
    justifyContent: `flex-start`,
    alignItems: `center`,
    boxSizing: `border-box`,
    borderRadius: `3px`,
    fontWeight: 500,
    backgroundColor: theme.palette.greySecondary[50],
    margin: "0 12px 12px 12px",
    "> div": {
        display: `inline-flex`,
        alignItems: `center`,
    },
    ".MuiButton-startIcon": {
        marginLeft: 0,
    },
    ".MuiBox-root": {
        display: `inline-flex`,
        alignItems: `center`,
        justifyContent: `center`,
        position: `absolute`,
        right: "12px",
        top: 9,
        transition: `all .2s linear`,
        ".MuiSvgIcon-root": {
            color: theme.palette.greySecondary[500],
            margin: `0 auto`,
        },
    },
    "&:hover": {
        backgroundColor: `${alpha(theme.palette.primary[100], 0.7)} !important`,
        ".MuiSvgIcon-root": {
            color: theme.palette.primary.main,
        },
        ".MuiBox-root": {
            right: 6,
        },
    },
}));

export const BackToConsoleButton = styled(MuiButton)(({ theme }) => ({
    display: `inline-flex`,
    minWidth: `40px`,
    height: `40px`,
    width: `-webkit-fill-available`,
    padding: `0 6px 0 53px`,
    alignItems: `center`,
    justifyContent: `flex-start`,
    borderRadius: `3px`,
    fontWeight: 500,
    backgroundColor: theme.palette.greySecondary[50],
    margin: "0 12px 12px 12px",
    "> div": {
        display: `inline-flex`,
        alignItems: `center`,
    },
    ".MuiBox-root": {
        display: `inline-flex`,
        alignItems: `center`,
        justifyContent: `center`,
        position: `absolute`,
        left: `15px`,
        top: 9,
        transition: `all .2s linear`,
        minWidth: `22px`,
        backgroundColor: theme.palette.secondary[800],
        ".MuiSvgIcon-root": {
            margin: `0 auto`,
        },
    },
    "&:hover": {
        backgroundColor: `${alpha(theme.palette.primary[100], 0.7)} !important`,
        ".MuiSvgIcon-root": {
            color: theme.palette.primary.main,
        },
        ".MuiBox-root": {
            left: `6px`,
            backgroundColor: theme.palette.primary[300],
            ".MuiSvgIcon-root": {
                color: theme.palette.primary.contrastText,
            },
        },
    },
}));

export const BackToProjectButton = styled(MuiButton)(({ theme }) => ({
    position: `relative`,
    display: `inline-flex`,
    minWidth: `40px`,
    height: `40px`,
    padding: `0 6px 0 53px`,
    alignItems: `center`,
    boxSizing: `border-box`,
    justifyContent: `flex-start`,
    borderRadius: `3px`,
    fontWeight: 500,
    color: theme.palette.primary.contrastText,
    backgroundColor: alpha(theme.palette.greySecondary[50], 0.12),
    margin: "0 12px 12px 12px",
    "> div": {
        display: `inline-flex`,
        alignItems: `center`,
    },
    ".MuiBox-root": {
        display: `inline-flex`,
        alignItems: `center`,
        justifyContent: `center`,
        position: `absolute`,
        left: `15px`,
        top: 9,
        transition: `all .2s linear`,
        minWidth: `22px`,
        backgroundColor: theme.palette.secondary[800],
        ".MuiSvgIcon-root": {
            margin: `0 auto`,
        },
    },
    "&:hover": {
        backgroundColor: `${alpha(theme.palette.greySecondary[50], 0.2)} !important`,
        ".MuiSvgIcon-root": {
            color: theme.palette.primary.main,
        },
        ".MuiBox-root": {
            left: `6px`,
            backgroundColor: theme.palette.primary[300],
            ".MuiSvgIcon-root": {
                color: theme.palette.primary.contrastText,
            },
        },
    },
}));

const toleft = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;
const toright = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;
export const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== ["open", "type"],
})(({ theme, open, type }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    "& .MuiPaper-root ": {
        boxSizing: "border-box",
        backgroundColor: `#f2f3f6`, //theme.palette.greySecondary[50],
        borderRight: `1px solid ${theme.palette.greySecondary[100]}`,
        zIndex: theme.zIndex.appBar - 100,
        ...(type === "administration" && {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
        }),
    },
    "& .MuiList-root": {
        flex: 1,
        padding: 0,
        overflowY: `hidden`,
        overflowX: `hidden`,
        scrollbarGutter: `stable `,
        "&:hover": {
            overflowY: `overlay`,
        },
        "&::-webkit-scrollbar": {
            width: 6,
            height: 12,
        },
        "&::-webkit-scrollbar-track": {
            background: `transparent`,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: type === "administration" ? `#696b6c` : alpha(theme.palette.greySecondary.main, 0.16),
            borderRadius: 8,
            backgroundClip: `padding-box`,
            border: 0,
        },
        ".MuiListItem-root": {
            width: `auto`,
            marginRight: -6,
            padding: `4px 12px`,
            display: `block`,
            ".MuiList-root": {
                marginTop: 8,
                overflow: `visible`,
                ".MuiListItem-root": {
                    padding: 0,
                    marginRight: 0,
                    ".MuiListItemButton-root": {
                        "&.Mui-selected": {
                            ".MuiListItemIcon-root ": {
                                color:
                                    type === "administration"
                                        ? theme.palette.primary.contrastText
                                        : theme.palette.primary.main,
                            },
                            "&:hover": {
                                backgroundColor:
                                    type === "administration"
                                        ? alpha(theme.palette.primary[300], 0.7)
                                        : alpha(theme.palette.primary[100], 0.3),
                            },
                        },
                    },
                },
            },
            "&.MuiListItem-divider": {
                border: 0,
                "&:after": {
                    content: "''",
                    display: `block`,
                    width: `100%`,
                    height: 1,
                    margin: `12px 0 6px`,
                    backgroundColor:
                        type === "administration" ? alpha(theme.palette.divider, 0.12) : theme.palette.divider,
                },
                "&:last-of-type": {
                    "&:after": {
                        content: `none`,
                    },
                },
            },
            ".MuiListItemButton-root": {
                minWidth: 40,
                height: 40,
                borderRadius: 3,
                paddingLeft: 12,
                paddingRight: 12,
                ".MuiListItemIcon-root ": {
                    minWidth: 16,
                    alignItems: `center`,
                    justifyContent: `center`,
                    marginRight: 12,
                    color:
                        type === "administration" ? theme.palette.secondary.contrastText : theme.palette.secondary.main,
                    ".MuiSvgIcon-root": {
                        fontSize: 16,
                    },
                },
                ".MuiListItemText-root": {
                    overflow: `hidden`,
                },
                "&:hover": {
                    backgroundColor:
                        type === "administration"
                            ? alpha(theme.palette.greySecondary[50], 0.12)
                            : theme.palette.greySecondary[50],
                },
                "&.Mui-selected": {
                    backgroundColor:
                        type === "administration"
                            ? alpha(theme.palette.primary[300], 0.5)
                            : alpha(theme.palette.primary[100], 0.5),
                    ".MuiListItemIcon-root ": {
                        color:
                            type === "administration" ? theme.palette.primary.contrastText : theme.palette.primary.main,
                    },
                    "&:hover": {
                        backgroundColor:
                            type === "administration"
                                ? alpha(theme.palette.primary[300], 0.7)
                                : alpha(theme.palette.primary[100], 0.7),
                    },
                },
            },
        },
        ".MuiCollapse-root": {
            ".MuiList-root": {
                marginTop: 8,
                overflow: `visible`,
                ".MuiListItem-root": {
                    padding: 0,
                    marginRight: 0,
                    ".MuiListItemButton-root": {
                        ".MuiListItemIcon-root ": {
                            opacity: 0.2,
                            ".MuiSvgIcon-root": {
                                fontSize: 6,
                            },
                        },
                        "&.Mui-selected": {
                            backgroundColor: `transparent`,
                            ".MuiListItemIcon-root ": {
                                opacity: 1,
                                color: theme.palette.primary.main,
                            },
                            "&:hover": {
                                backgroundColor:
                                    type === "administration"
                                        ? alpha(theme.palette.primary[300], 0.5)
                                        : alpha(theme.palette.primary[100], 0.3),
                            },
                        },
                    },
                },
            },
        },
        ".MuiListSubheader-root": {
            position: `static`,
            display: `flex`,
            alignItems: `center`,
            minHeight: 16,
            marginBottom: 8,
            paddingLeft: 14,
            backgroundColor: `transparent`,
            lineHeight: `initial`,
            // fontSize: theme.typography.body4.fontSize,
            fontWeight: `bold`,
            color: type === "administration" ? theme.palette.secondary[400] : theme.palette.text.secondary,
        },
    },

    "& .nav-toggle-button": {
        position: `fixed`,
        left: drawerWidth,
        bottom: `71px`,
        zIndex: (theme) => theme.zIndex.drawer + 1,
        width: `26px`,
        height: `26px`,
        boxShadow: `0 3px 3px 0 rgba(0, 0, 0, 0.12)`,
        transition: theme.transitions.create("left ", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
        // "& .MuiListItemButton-root": {
        //     justifyContent: "initial",
        // },
        // "& .MuiListItemIcon-root": {
        //     marginRight: `10px`,
        // },
        // "& .MuiListItemText-root": {
        //     opacity: 1,
        // },
        "& .project-setting-button ": {
            strong: {
                opacity: 1,
            },
        },
        "& .back-to-console-button ": {
            strong: {
                opacity: 1,
            },
        },
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
        // "& .MuiListItemButton-root": {
        //     justifyContent: "center",
        //     overflow: `hidden`,
        // },
        // "& .MuiListItemIcon-root": {
        //     marginRight: `0`,
        // },
        // "& .MuiListItemText-root": {
        //     opacity: 0,
        //     width: 0,
        // },
        "& .project-setting-button ": {
            minWidth: 40,
            width: 40,
            // margin: `0 auto 12px`,
            padding: 12,
            "span.css-0": {
                width: 0,
                opacity: 0,
                margin: 0,
                overflow: `hidden`,
            },
            ".MuiBox-root": {
                width: 0,
                opacity: 0,
            },
        },
        "& .back-to-console-button ": {
            width: `40px`,
            minWidth: `40px`,
            padding: `9px`,
            // margin: `0 auto 12px`,
            "span.css-0": {
                width: 0,
                opacity: 0,
                margin: 0,
            },
            ".MuiBox-root": {
                left: `9px`,
            },
        },
        "& .nav-toggle-button": {
            position: `fixed`,
            left: `60px`,
            bottom: `71px`,
            width: `26px`,
            height: `26px`,
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: `${theme.palette.secondary[500]} !important`,
            transition: theme.transitions.create("left ", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
    }),
}));
