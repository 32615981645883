import { createAction, handleActions } from "redux-actions";
import { axios, axiosWithoutHeaders } from "../cores/axiosWrapper";
import moment from "moment";

function getListingsAPI(stageId, apiEndpoint, projectId, params) {
    let beginDate = params.beginDate ? moment(params.beginDate).format("YYYY-MM-DD") : "";
    let endDate = params.endDate ? moment(params.endDate).format("YYYY-MM-DD") : "";

    return axios.get(`${apiEndpoint}/listings`, {
        params: {
            ...params,
            beginDate,
            endDate,
        },
        headers: {
            stageId,
            projectId,
        },
    });
}

function deleteListingsAPI(stageId, apiEndpoint, projectId, listingId) {
    return axios.delete(`${apiEndpoint}/listings/${listingId}`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function getListingAPI(stageId, apiEndpoint, projectId, listingId) {
    return axios.get(`${apiEndpoint}/listings/${listingId}`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function putListingAPI(stageId, apiEndpoint, projectId, userId, listingId, data) {
    return axios.put(
        `${apiEndpoint}/listings/${listingId}`,
        {
            ...data,
            userId,
        },
        {
            headers: {
                stageId,
                projectId,
                "Content-Type": "application/json",
            },
        },
    );
}

//action type
const LISTINGS_PENDING = "LISTINGS_PENDING";
const LISTINGS_FAILURE = "LISTINGS_FAILURE";
//listings
const GET_LISTINGS_SUCCESS = "GET_LISTINGS_SUCCESS";
const TOGGLE_LISTINGS_ETC_BUTTON = "TOGGLE_LISTINGS_ETC_BUTTON";
const UPDATE_LISTINGS_PARAMS = "UPDATE_LISTINGS_PARAMS";
const UPDATE_LISTINGS_KEYWORD_TYPE = "UPDATE_LISTINGS_KEYWORD_TYPE";
const RESET_LISTINGS = "RESET_LISTINGS";
const UPDATE_LISTINGS_OFFSET = "UPDATE_LISTINGS_OFFSET";
const UPDATE_LISTINGS_LIMIT = "UPDATE_LISTINGS_LIMIT";
const DELETE_LISTINGS = "DELETE_LISTINGS";
const UPDATE_LISTINGS_SEARCH_VALUE = "UPDATE_LISTINGS_SEARCH_VALUE";
//detail
//lineups
const GET_LISTING_SUCCESS = "GET_LISTING_SUCCESS";
const PUT_LISTING_SUCCESS = "PUT_LISTING_SUCCESS";
const SET_LISTING_INIT_DATA = "SET_LISTING_INIT_DATA";
const SET_LISTING_PARAM = "SET_LISTING_PARAM";
const RESET_LISTING_PARAMS = "RESET_LISTING_PARAMS";
const SET_LISTING_EDITMODE = "SET_LISTING_EDITMODE";
//reducer
const initialState = {
    pending: false,
    error: false,
    editMode: false,
    item: {
        createDateTime: "",
        destinationA: "",
        destinationB: "",
        id: "",
        modifiedDateTime: "",
        registeredLineupCount: 0,
        title: "",
    },
    list: {
        keywordType: "id",
        searchValue: "",
        params: {
            id: "",
            name: "",
            isActive: "", //active, Inactive
            userType: "CREATOR", //Creator, Modifier
            username: "",
            dateType: "CREATED", //CreatedDateTime, ModifiedDateTime
            beginDate: null,
            endDate: null,
            offset: 0,
            limit: 20,
        },
        data: null,
        totalCount: 0,
    },
    detail: {},
    lineups: {},
    params: {
        title: "",
        type: "listings",
        startDate: null,
        endDate: null,
        dateType: "2w",
        isDirectDateRange: false,
    },
};

export default handleActions(
    {
        [LISTINGS_PENDING]: (state, action) => {
            return {
                ...state,
                pending: true,
                error: false,
            };
        },
        [GET_LISTING_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            return {
                ...state,
                pending: false,
                item: {
                    ...data,
                    baseVideoUrl: data.baseVideoUrl === null ? "" : data.baseVideoUrl,
                },
            };
        },
        [PUT_LISTING_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
                item: action.payload.data,
            };
        },
        [LISTINGS_FAILURE]: (state) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [SET_LISTING_INIT_DATA]: (state) => {
            return {
                ...state,
                item: {
                    createDateTime: "",
                    destinationA: "",
                    destinationB: "",
                    id: "",
                    modifiedDateTime: "",
                    registeredLineupCount: 0,
                    title: "",
                },
                params: {
                    title: "",
                    type: "listings",
                    startDate: null,
                    endDate: null,
                    dateType: "2w",
                    isDirectDateRange: false,
                },
            };
        },
        [SET_LISTING_PARAM]: (state, action) => {
            return {
                ...state,
                params: {
                    ...state.params,
                    ...action.payload,
                },
            };
        },
        [RESET_LISTING_PARAMS]: (state) => {
            return {
                ...state,
                params: {
                    title: "",
                    type: "listings",
                    startDate: null,
                    endDate: null,
                    dateType: "2w",
                    isDirectDateRange: false,
                },
            };
        },
        [SET_LISTING_EDITMODE]: (state, action) => {
            return {
                ...state,
                editMode: action.payload,
            };
        },
        [GET_LISTINGS_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                pending: false,
                error: false,
                list: {
                    ...state.list,
                    data: data.listings,
                    totalCount: data.totalCount,
                },
            };
        },
        [TOGGLE_LISTINGS_ETC_BUTTON]: (state, action) => {
            return {
                ...state,
                list: {
                    ...state.list,
                    data:
                        state.list &&
                        state.list.data &&
                        state.list.data.map((v) => {
                            return {
                                ...v,
                                isShowActionButton: v.id === action.payload ? !v.isShowActionButton : false,
                            };
                        }),
                },
            };
        },
        [UPDATE_LISTINGS_PARAMS]: (state, action) => {
            return {
                ...state,
                list: {
                    ...state.list,
                    params: {
                        ...state.list.params,
                        ...action.payload,
                    },
                },
            };
        },
        [UPDATE_LISTINGS_SEARCH_VALUE]: (state, action) => {
            return {
                ...state,
                list: {
                    ...state.list,
                    searchValue: action.payload,
                },
            };
        },
        [UPDATE_LISTINGS_KEYWORD_TYPE]: (state, action) => {
            return {
                ...state,
                list: {
                    ...state.list,
                    keywordType: action.payload,
                    params: {
                        ...state.list.params,
                        id: "",
                        name: "",
                    },
                },
            };
        },
        [RESET_LISTINGS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
                list: {
                    ...state.list,
                    keywordType: "id",
                    searchValue: "",
                    params: {
                        id: "",
                        name: "",
                        status: "", //active, Inactive
                        userType: "CREATOR", //Creator, Modifier
                        username: "",
                        dateType: "CREATED", //CreatedDateTime, ModifiedDateTime
                        beginDate: null,
                        endDate: null,
                        offset: 0,
                        limit: 20,
                    },
                },
            };
        },
        [UPDATE_LISTINGS_OFFSET]: (state, action) => {
            return {
                ...state,
                pending: true,
                error: false,
                list: {
                    ...state.list,
                    params: {
                        ...state.list.params,
                        offset: action.payload,
                    },
                },
            };
        },
        [UPDATE_LISTINGS_LIMIT]: (state, action) => {
            const { offset, limit } = action.payload;

            return {
                ...state,
                pending: true,
                error: false,
                list: {
                    ...state.list,
                    params: {
                        ...state.list.params,
                        offset,
                        limit,
                    },
                },
            };
        },
        [DELETE_LISTINGS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
                list: {
                    ...state.list,
                    data:
                        state.list &&
                        state.list.data &&
                        state.list.data.map((v) => {
                            return {
                                ...v,
                                isDeleted: v.isDeleted === true ? true : v.id === action.payload,
                            };
                        }),
                    totalCount: state.list.totalCount - 1,
                },
            };
        },
    },
    initialState,
);
//action creators
export const setListingsParam = createAction(SET_LISTING_PARAM);
export const resetListingsParams = createAction(RESET_LISTING_PARAMS);
export const setEditMode = createAction(SET_LISTING_EDITMODE);
export const setInitData = createAction(SET_LISTING_INIT_DATA);
export const getListings =
    (projectId, params = {}) =>
    (dispatch, getState) => {
        const { stage, listings } = getState();

        dispatch({ type: LISTINGS_PENDING });

        let queries = Object.assign({}, listings.list.params, params);

        return getListingsAPI(stage.id, stage.endpoint, projectId, queries)
            .then((response) => {
                dispatch({
                    type: GET_LISTINGS_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: LISTINGS_FAILURE,
                    payload: error,
                });
            });
    };
export const getListing = (listingId) => (dispatch, getState) => {
    const { stage, project } = getState();

    if (!listingId || stage.id === "") {
        return;
    }

    dispatch({ type: LISTINGS_PENDING });

    return getListingAPI(stage.id, stage.endpoint, project.id, listingId)
        .then((response) => {
            dispatch({
                type: GET_LISTING_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: LISTINGS_FAILURE,
                payload: error,
            });
        });
};
export const putListing =
    (listingId, params, callback = () => {}) =>
    (dispatch, getState) => {
        const { stage, project, user } = getState();

        if (stage.id === "" || user.info.id === "") {
            return;
        }

        dispatch({ type: LISTINGS_PENDING });

        return putListingAPI(stage.id, stage.endpoint, project.id, user.info.id, listingId, params)
            .then((response) => {
                dispatch({
                    type: PUT_LISTING_SUCCESS,
                    payload: response,
                });
                callback(response.data.Id);
            })
            .catch((error) => {
                dispatch({
                    type: LISTINGS_FAILURE,
                    payload: error,
                });
            });
    };
export const toggleListingsEtcButton = createAction(TOGGLE_LISTINGS_ETC_BUTTON);
export const updateListingsParams = createAction(UPDATE_LISTINGS_PARAMS);
export const updateListingsSearchValue = createAction(UPDATE_LISTINGS_SEARCH_VALUE);
export const updateListingsKeywordType = createAction(UPDATE_LISTINGS_KEYWORD_TYPE);
export const resetListings = () => (dispatch, getState) => {
    dispatch({ type: RESET_LISTINGS });
};
export const updateListingsOffset = (offset) => (dispatch, getState) => {
    const { stage, project, listings } = getState();

    dispatch({
        type: UPDATE_LISTINGS_OFFSET,
        payload: offset,
    });

    let params = Object.assign({}, listings.list.params, {
        offset: (offset < 0 ? 0 : offset) * listings.list.params.limit,
    });

    return getListingsAPI(stage.id, stage.endpoint, project.id, params)
        .then((response) => {
            dispatch({
                type: GET_LISTINGS_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: LISTINGS_FAILURE,
                payload: error,
            });
        });
};

export const updateListingsLimit =
    ({ offset, limit }) =>
    (dispatch, getState) => {
        const { stage, project, listings } = getState();

        dispatch({
            type: UPDATE_LISTINGS_LIMIT,
            payload: {
                offset,
                limit,
            },
        });

        let params = Object.assign({}, listings.list.params, {
            offset,
            limit,
        });

        return getListingsAPI(stage.id, stage.endpoint, project.id, params)
            .then((response) => {
                dispatch({
                    type: GET_LISTINGS_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: LISTINGS_FAILURE,
                    payload: error,
                });
            });
    };
export const deleteListings = (listingId, isDeletedAllRelated) => async (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: LISTINGS_PENDING });

    return deleteListingsAPI(stage.id, stage.endpoint, project.id, listingId)
        .then(() => {
            dispatch({
                type: DELETE_LISTINGS,
                payload: listingId,
            });
        })
        .catch((error) => {
            dispatch({
                type: LISTINGS_FAILURE,
                payload: error,
            });
        });
};
