import { Box, Button, Divider, Grid, IconButton, LinearProgress, Stack, Tooltip } from "@mzc-pdc/ui";
import {
    IconAllView,
    IconArrowDropDown,
    IconClose,
    IconMoreVert,
    IconRefresh,
    IconReply,
    IconCancel,
} from "@mzc-cloudplex/icons";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CardComments from "@features/comments/common/card-comments";
import { alpha, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import theme from "@styles/theme";
import {
    createPrivateThread,
    deleteThread,
    getComments,
    getThreads,
    resetComments,
    resetState,
    updateIsLinkCommentUsed,
    updateSelectedThreadId,
    updateThread,
    updateThreadMembers,
    getThreadMembers,
} from "@modules/common-comments";
import { useInView } from "react-intersection-observer";
import SkeletonComment from "@features/comments/skeleton-comment";
import moment from "moment";
import "moment/locale/ko";
import "moment/locale/ja";
import queryParamsParse from "@components/params/queryParamsParse";
import {
    DialogCreateGroup,
    DialogDeleteGroup,
    DialogEditGroupMembers,
    DialogEditGroupName,
    GroupDescription,
    GroupedAvatar,
    GroupTitleWithIcon,
} from "@features/comments/groups";
import useDialog from "@hooks/useDialog";
import { Tabs } from "@components_v2/tabs/tabs";
import PopperMenu from "@components/tooltip/PopperMenu";
import useActionPending from "@hooks/useActionPending";
import PopperPrivateThreads from "@features/comments/groups/popper-private-threads";
import { useToggle } from "@mzc-pdc/hooks";
import useCommentSelector from "@hooks/useCommentSelector";
import { UPLOAD_LOCATION } from "@constants";
import { setNotification } from "@modules/notification";
import { inActive } from "@modules/rightSideBar";
import { getProjectUsersAPI } from "@modules/apis/user-management-v2";

const ReplyContainer = ({ domain, resourceId, hidden }) => {
    const { t } = useTranslation();
    const LIMIT = 20;
    const dispatch = useDispatch();
    const location = useLocation();
    const [prevTargetRef, prevInView] = useInView();
    const [nextTargetRef, nextInView] = useInView();
    const { dialog, openDialog, closeDialog } = useDialog();
    const { active: threadPopperActive, toggle: threadPopperToggle } = useToggle();
    const { getActionPending, setActionPending, disableActionPending } = useActionPending();

    const [mountPending, setMountPending] = useState(true);
    const [initPending, setInitPending] = useState(false);
    const [scrollYPosition, setScrollYPosition] = useState(0);
    const [prevScrollHeight, setPrevScrollHeight] = useState(0);
    const [hasScrolledToLinkComment, setHasScrolledToLinkComment] = useState(false);
    const [selectedTab, setSelectedTab] = useState("");
    const [selectedPrivateThreads, setSelectedPrivateThreads] = useState([]);
    const [defaultMembers, setDefaultMembers] = useState([]);

    const dateDividerRef = useRef(0);
    const timeOffsetRef = useRef(null);
    const scrollDivRef = useRef(null);
    const linkCommentRef = useRef(null);
    const groupsViewRef = useRef(null);

    const {
        comments,
        resourceData,
        isLinkCommentUsed,
        selectedThreadId,
        uploadingComments,
        hasLinkThreadLogicExecuted,
    } = useCommentSelector({
        domain,
    });
    const stage = useSelector(({ stage }) => stage);
    const project = useSelector(({ project }) => project);
    const uploadQueue = useSelector(({ uploadQueue }) => uploadQueue);

    const compareDates = (first, second) => {
        // 문자열을 Date 객체로 변환
        const firstDate = new Date(new Date(first).getTime() - timeOffsetRef.current);
        const secondDate = new Date(new Date(second).getTime() - timeOffsetRef.current);

        // 년, 월, 일 부분만 추출하여 문자열로 변환
        const formattedFirstDate = firstDate.toISOString().slice(0, 10);
        const formattedSecondDate = secondDate.toISOString().slice(0, 10);
        // 날짜 부분만 비교
        return formattedFirstDate !== formattedSecondDate;
    };

    const onHandleRefresh = useCallback(async () => {
        if (!resourceData?.results?.length || comments?.prevPending) return;
        await dispatch(resetComments());
        const threadId = getSelectedTabInfo(selectedTab)?.id;
        if (!threadId) return;
        await setInitPending(true);
        await dispatch(getThreadMembers(project.id, threadId));
        await dispatch(
            getComments(project.id, threadId, {
                limit: LIMIT,
                domain,
                resourceId,
            }),
        );
        await setInitPending(false);
    }, [resourceData, domain, comments, resourceId, selectedTab, initPending]);

    const makeDateDivider = useCallback((comment, index = false) => {
        let language = localStorage.getItem("i18nLng");
        if (language === "ko") language = "ko-KR";
        else if (language === "ja") language = "ja-JP";
        else language = "en-US";

        const date = moment(comment.created.at).calendar(null, {
            sameDay: `[${t(`common::label::Today`, "Today")}]`,
            lastDay: `[${t(`common::label::Yesterday`, "Yesterday")}]`,
            lastWeek: "dddd[,] MMMM Do",
            sameElse: `dddd[,] MMMM Do`,
        });

        return (
            <Grid
                item
                sx={{
                    display: "flex",
                    alignItems: "center",
                    position: "sticky",
                    left: 0,
                    top: 0,
                    right: 0,
                    backgroundColor: "#e5ebf9",
                    zIndex: 100 - index,
                    py: 0.5,
                }}
            >
                <Box sx={{ flex: 1, mr: 2 }}>
                    <Divider
                        flexItem
                        orientation={"horizontal"}
                        sx={{
                            borderColor: theme.palette.greySecondary["A100"],
                        }}
                    />
                </Box>
                <Grid item>
                    <Button
                        variant={"outlined"}
                        size={"small"}
                        rounded
                        endIcon={<IconArrowDropDown size={16} />}
                        sx={{
                            height: "24px",
                            px: "12px",
                            color: theme.palette.greySecondary["A400"],
                            borderColor: theme.palette.greySecondary["A400"],
                            "& .MuiButton-endIcon": {
                                marginLeft: "4px",
                            },
                        }}
                    >
                        <Typography variant={"body3_400"}>{date}</Typography>
                    </Button>
                </Grid>
                <Box sx={{ flex: 1, ml: 2 }}>
                    <Divider
                        flexItem
                        orientation={"horizontal"}
                        sx={{
                            borderColor: theme.palette.greySecondary["A100"],
                        }}
                    />
                </Box>
            </Grid>
        );
    }, []);

    const linkParams = useMemo(() => {
        const { threadId, commentId, domain } = queryParamsParse(location.search);
        const isLinkFromEmail = threadId;

        return { isLinkFromEmail, threadId, commentId, domain };
    }, [location.search]);

    const initComments = useCallback(async () => {
        const { isLinkFromEmail, threadId: threadIdFromLink, commentId, domain: linkDomain } = linkParams;
        const threadDataFromLink = resourceData?.results?.find((thread) => thread.id === threadIdFromLink);

        if (!isLinkCommentUsed && isLinkFromEmail && domain === linkDomain) {
            if (!threadDataFromLink) {
                setSelectedTab("");
                dispatch(
                    setNotification({ type: "info", contents: t("common::msg::The private comment is deleted.") }),
                );

                if (publicThreadId)
                    await dispatch(
                        getComments(project.id, publicThreadId, {
                            limit: LIMIT,
                            domain,
                            resourceId,
                        }),
                    );
            } else {
                setSelectedTab(threadDataFromLink.type === "PUBLIC" ? "" : threadDataFromLink.id);
                if (threadDataFromLink.type !== "PUBLIC") await onHandleCheckPrivateThreads(threadDataFromLink.id);
                await dispatch(
                    getComments(project.id, threadIdFromLink, {
                        limit: LIMIT,
                        domain,
                        resourceId,
                        cursor: commentId,
                        orderType: "DESC",
                    }),
                );
                if (commentId)
                    //Note: 스레드만 있는 상태에서 링크에 들어갈 경우 댓글은 이전 댓글 목록조회 1회만 하면된다.
                    await dispatch(
                        getComments(project.id, threadIdFromLink, {
                            limit: LIMIT,
                            domain,
                            resourceId,
                            cursor: commentId,
                            orderType: "ASC",
                        }),
                    );
            }
            dispatch(updateIsLinkCommentUsed(true));
        } else {
            await dispatch(
                getComments(project.id, publicThreadId, {
                    limit: LIMIT,
                    domain,
                    resourceId,
                }),
            );
        }
        setMountPending(false);
    }, [resourceData, project, linkParams]);

    const getLocalStoragePrivateThreads = useCallback(
        (resourceData) => {
            const data = resourceData?.results?.[0]?.context;
            if (!data) return { domainResourceIds: {} };

            const { resourceId, domain, parentThread } = data;
            const domainResourceIds = JSON.parse(localStorage.getItem("privateThreads")) ?? {};
            const domainResourceId =
                domain === "COMMENT" ? `${parentThread.domain}_${parentThread.resourceId}` : `${domain}_${resourceId}`;
            const privateThread = Object.keys(domainResourceIds).find((key) => key === domainResourceId);
            const privateThreadIds = domainResourceIds[privateThread] ?? [];

            return { domainResourceIds, domainResourceId, privateThreadIds };
        },
        [resourceData, selectedPrivateThreads],
    );

    const setLocalStoragePrivateThreads = useCallback(
        (privateThreadsArray, newThreadId = null) => {
            const { domainResourceIds, domainResourceId } = getLocalStoragePrivateThreads(resourceData);
            const id = newThreadId ?? domainResourceId;
            localStorage.setItem(
                "privateThreads",
                JSON.stringify({
                    ...domainResourceIds,
                    [id]: privateThreadsArray,
                }),
            );
        },
        [resourceData, selectedPrivateThreads],
    );

    const tabs = useMemo(() => {
        if (!resourceData || !resourceData?.results) return [];
        const threads = resourceData.results;
        const { privateThreadIds } = getLocalStoragePrivateThreads(resourceData);

        const defaultThread = {
            isPublic: true,
            value: "",
            name: "",
            label: <GroupTitleWithIcon isPublic={true} title={""} iconSize={16} fontVariant={"body3_500"} />,
        };

        const publicThreads = threads
            .filter((el) => el.type === "PUBLIC" && el.context?.domain !== "COMMENT")
            ?.map((el) => ({
                id: el.id,
                isPublic: true,
                value: el.id,
                name: el.name,
                label: <GroupTitleWithIcon isPublic={true} title={el.name} iconSize={16} fontVariant={"body3_500"} />,
            }))
            .reduce((acc, current) => {
                const existedTab = acc.find((item) => item.id === current.id);
                if (!existedTab) {
                    return acc.concat([current]);
                } else {
                    return acc;
                }
            }, []);

        const privateThreads = threads
            .filter((el) => privateThreadIds?.includes(el.id))
            ?.map((el) => ({
                id: el.id,
                isPublic: false,
                value: el.id,
                name: el.name,
                label: <GroupTitleWithIcon isPublic={false} title={el.name} iconSize={16} fontVariant={"body3_500"} />,
                icon: (
                    <IconButton
                        circled
                        size={"small"}
                        onClick={(e) => {
                            e.stopPropagation();
                            onHandleCheckPrivateThreads(el.id);
                        }}
                    >
                        <IconCancel size={12} htmlColor={theme.palette.greySecondary[500]} />
                    </IconButton>
                ),
            }));

        if (!publicThreads?.length) return [defaultThread, ...privateThreads];
        else return [...publicThreads, ...privateThreads];
    }, [selectedPrivateThreads, resourceData, selectedTab, getLocalStoragePrivateThreads]);

    const getSelectedTabInfo = useCallback(
        (tab) => {
            if (!tabs) return null;
            if (tab === "") return tabs.find((el) => el.isPublic);
            return tabs.find((el) => el.id === tab);
        },
        [selectedTab, tabs],
    );

    const publicThreadId = useMemo(() => {
        if (!resourceData) return null;
        return resourceData.results?.find((el) => el.type === "PUBLIC")?.id;
    }, [resourceData]);

    const threadInfoWithMembers = useMemo(() => {
        const selectedThread = getSelectedTabInfo(selectedTab);
        let threadInfo = { ...selectedThread };
        if (!selectedThread?.id || selectedThread.isPublic) threadInfo.members = defaultMembers ?? [];
        else threadInfo.members = resourceData?.results?.find((el) => selectedThread?.id === el.id)?.members ?? [];
        return threadInfo;
    }, [selectedTab, resourceData, defaultMembers]);

    const onHandleChangeTab = (tab) => {
        setSelectedTab(tab);
    };

    const onHandleCheckPrivateThreads = useCallback(
        (id) => {
            const { privateThreadIds } = getLocalStoragePrivateThreads(resourceData);
            const selectedThread = getSelectedTabInfo(selectedTab);
            const isExist = privateThreadIds?.find((el) => el === id) !== undefined;

            let privateThreadsArray;
            if (isExist) {
                if (id === selectedThread?.id) setSelectedTab("");
                privateThreadsArray = privateThreadIds?.filter((el) => el !== id);
            } else privateThreadsArray = [...privateThreadIds, id];

            setSelectedPrivateThreads(privateThreadsArray);
            setLocalStoragePrivateThreads(privateThreadsArray);
        },
        [selectedPrivateThreads, resourceData, selectedTab],
    );

    const onCreatePrivateThread = async (props) => {
        try {
            setActionPending("createGroup");
            const data = {
                ...props,
                context: {
                    domain: domain,
                    resourceId,
                },
            };
            const response = await dispatch(createPrivateThread(data));
            threadPopperToggle();
            const { privateThreadIds } = getLocalStoragePrivateThreads(resourceData);
            const newSelectedPrivateThreads = [...privateThreadIds, response?.data?.id];
            const domainResourceId = `${domain}_${resourceId}`;
            setLocalStoragePrivateThreads(newSelectedPrivateThreads, domainResourceId);
            setSelectedPrivateThreads(newSelectedPrivateThreads);
            setSelectedTab(response?.data?.id);
        } catch (e) {
        } finally {
            disableActionPending("createGroup");
            closeDialog();
        }
    };

    const onUpdateThreadMembers = async (params) => {
        try {
            setActionPending("editGroupMembers");
            const threadId = getSelectedTabInfo(selectedTab)?.id;
            await dispatch(updateThreadMembers(threadId, params));
            closeDialog();
        } catch (e) {
        } finally {
            disableActionPending("editGroupMembers");
        }
    };
    const onDeletePrivateThread = async () => {
        try {
            setActionPending("deleteGroup");
            const threadId = getSelectedTabInfo(selectedTab)?.id;
            await dispatch(deleteThread(threadId));
            await dispatch(getThreads(project.id, { domain, resourceId }));
            const newSelectedPrivateThreads = selectedPrivateThreads.filter((el) => el.id !== threadId);
            setLocalStoragePrivateThreads(newSelectedPrivateThreads);
            closeDialog();
            setSelectedTab(publicThreadId ?? "");
        } catch (e) {
            console.error(e);
        } finally {
            disableActionPending("deleteGroup");
        }
    };

    const onUpdatePrivateThread = async (threadId, props) => {
        try {
            setActionPending("editGroupName");
            await dispatch(updateThread(threadId, props));
            setSelectedTab(threadId);
            closeDialog();
        } catch (e) {
            console.error(e);
        } finally {
            disableActionPending("editGroupName");
        }
    };

    const EmptyPrivateThreadComments = () => {
        return (
            <Box
                height={146}
                sx={{
                    px: 1.5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Stack direction={"column"} gap={1}>
                    <Typography variant={"body1_700"}>{t("common::msg::There are no private comments.")}</Typography>
                    <Typography variant={"body3_500"} color={theme.palette.greySecondary[600]}>
                        {t(`common::msg::Use the "Create private comment" button Make it.`)}
                    </Typography>
                </Stack>
            </Box>
        );
    };

    const totalUploadingFilesCount = useMemo(() => {
        return uploadQueue[UPLOAD_LOCATION.UPLOAD_COMMENT_ATTACHMENT]
            .filter((queue) => queue.status === "UPLOADING")
            .reduce((acc, queue) => {
                if (queue.data.threadId === selectedThreadId) {
                    return acc + queue.files?.length;
                }
                return acc;
            }, 0);
    }, [uploadQueue, selectedThreadId]);

    const uploadedFilesCount = useMemo(() => {
        return uploadQueue[UPLOAD_LOCATION.UPLOAD_COMMENT_ATTACHMENT]
            .filter((queue) => queue.status === "UPLOADING")
            .reduce((acc, queue) => {
                if (queue.data.threadId === selectedThreadId) {
                    return queue.files?.filter((file) => file?.status?.status === "COMPLETE")?.length + acc;
                }
                return acc;
            }, 0);
    }, [uploadQueue, selectedThreadId]);

    const getCardComments = useCallback(() => {
        let dividerCount = 0;

        return comments.data?.results?.map((comment, index) => (
            <>
                {index > 0 &&
                    compareDates(comments.data?.results[index - 1].created.at, comment.created.at) &&
                    makeDateDivider(comments.data?.results[index - 1], dividerCount++)}
                <CardComments
                    key={`common-comment-${comment?.id}`}
                    domain={domain}
                    data={comment}
                    ref={comment.id === linkParams?.commentId ? linkCommentRef : null}
                    isUploading={comment.isUploading}
                    resourceId={resourceId}
                />
                {index === comments.data?.results?.length - 1 && makeDateDivider(comment, dividerCount++)}
            </>
        ));
    }, [comments.data?.results, linkParams, makeDateDivider, resourceId, domain]);

    const onHandleClose = useCallback(() => {
        dispatch(inActive());
    }, []);

    useEffect(async () => {
        const threadId = getSelectedTabInfo(selectedTab)?.id;
        dispatch(updateSelectedThreadId(threadId));
        if (!threadId) {
            const params = { limit: 10 }; //get total members count
            const response = await getProjectUsersAPI(stage.id, stage.endpoint, project.id, params);
            setDefaultMembers(response.data);
        }
        if (linkParams.isLinkFromEmail && !isLinkCommentUsed) return;
        onHandleRefresh();
    }, [selectedTab]);

    useEffect(() => {
        let date = new Date();
        timeOffsetRef.current = date.getTimezoneOffset() * 60000;
    }, []);

    useEffect(() => {
        if (!resourceData?.results || !mountPending) {
            return;
        } else if (
            !resourceData.results.length ||
            (!linkParams.isLinkFromEmail && !isLinkCommentUsed && !publicThreadId)
        ) {
            setMountPending(false);
            return;
        }
        if (publicThreadId) setSelectedTab(publicThreadId);
        initComments();
    }, [resourceData]);

    useEffect(() => {
        if (!comments.data?.results) return;
        const selectedThreadInfo = getSelectedTabInfo(selectedTab);
        if (!selectedThreadId && selectedThreadInfo) {
            dispatch(updateSelectedThreadId(selectedThreadInfo.id));
        }

        dateDividerRef.current = 0;
    }, [comments]);

    useEffect(() => {
        if (comments?.prevPending) return;
        if (prevScrollHeight > 0)
            scrollDivRef.current?.scrollTo(0, scrollDivRef.current?.scrollHeight - prevScrollHeight);
    }, [comments.prevPending]);

    useEffect(() => {
        if (isLinkCommentUsed && !hasLinkThreadLogicExecuted) return;

        dispatch(resetState());
        dispatch(getThreads(project.id, { domain, resourceId }));
        setSelectedTab("");
        setMountPending(true);
        setInitPending(false);
    }, [project.id, hasLinkThreadLogicExecuted]);

    useEffect(() => {
        if (
            !comments?.data?.prevToken ||
            comments?.prevPending ||
            initPending ||
            comments?.firstLoading ||
            !resourceData?.results
        )
            return;
        if (prevInView) {
            setPrevScrollHeight(scrollDivRef.current?.scrollHeight);
            dispatch(
                getComments(project.id, publicThreadId, {
                    nextToken: comments?.data?.prevToken,
                    orderType: "DESC",
                }),
            );
        }
    }, [prevInView]);

    useEffect(() => {
        if (!comments?.data?.nextToken || comments?.nextPending || !resourceData?.results) return;
        if (nextInView) {
            dispatch(
                getComments(project.id, publicThreadId, {
                    nextToken: comments?.data?.nextToken,
                    orderType: "ASC",
                }),
            );
        }
    }, [nextInView]);

    useEffect(() => {
        const handleScroll = (e) => {
            setScrollYPosition(e.target.scrollTop);
        };

        if (!hidden) {
            scrollDivRef.current?.scrollTo(0, scrollYPosition);
            scrollDivRef.current?.addEventListener("scroll", handleScroll);
            dateDividerRef.current = 0;
        }

        return () => {
            if (hidden) {
                scrollDivRef.current?.removeEventListener("scroll", handleScroll);
                setScrollYPosition(0);
            }
        };
    }, [hidden]);

    useEffect(() => {
        // NOTE: 댓글 새로고침 시 스크롤을 맨 아래로 이동
        if (initPending || !comments?.data?.results?.length || linkParams.isLinkFromEmail) return;
        scrollDivRef.current?.scrollTo(0, scrollDivRef.current?.scrollHeight);
    }, [initPending]);

    useEffect(() => {
        if (mountPending || !comments?.data?.results?.length || linkParams.isLinkFromEmail) return;
        scrollDivRef.current?.scrollTo(0, scrollDivRef.current?.scrollHeight);
    }, [mountPending]);

    useEffect(() => {
        if (!linkParams.isLinkFromEmail || hasScrolledToLinkComment) return;
        if (!comments?.data?.results?.length) return;
        linkCommentRef?.current?.scrollIntoView({
            behavior: "instant",
            block: "center",
        });
        setHasScrolledToLinkComment(true);
    }, [mountPending]);

    return (
        <Grid hidden={hidden} container flexDirection={"column"} sx={{ height: "100%" }}>
            <Grid
                container
                direction={"row"}
                p={"6px 12px"}
                sx={{
                    borderBottom: `1px solid ${theme.palette.greySecondary.A100}`,
                    backgroundColor:
                        domain !== "PROJECT"
                            ? theme.palette.greySecondary[100]
                            : alpha(theme.palette.common.white, 0.6),
                    height: domain !== "PROJECT" ? theme.spacing(4) : theme.spacing(7),
                    alignItems: "center",
                }}
            >
                <Grid item flex={1} gap={1.5} alignItems={"center"} sx={{ display: "flex" }}>
                    <Grid item>
                        <IconReply size={20} htmlColor={theme.palette.success.main} />
                    </Grid>
                    <Grid item flex={1}>
                        <Typography variant={`subtitle1_500`} color={theme.palette.greySecondary.main}>
                            {t("common::label::Comment", "Comment")}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item flex={0} gap={theme.spacing(1)} sx={{ display: "flex", alignItems: "center" }}>
                    <Tooltip title={t("common::label::Refresh", "Refresh")}>
                        <IconButton circled onClick={onHandleRefresh}>
                            <IconRefresh size={16} />
                        </IconButton>
                    </Tooltip>
                    {domain === "PROJECT" && (
                        <Tooltip title={t("common::label::Close", "Close")}>
                            <IconButton circled onClick={onHandleClose}>
                                <IconCancel size={16} />
                            </IconButton>
                        </Tooltip>
                    )}
                </Grid>
            </Grid>

            {/* Tabs View */}
            <Grid
                container
                flexDirection={"row"}
                alignItems={"center"}
                flexWrap={"nowrap"}
                sx={{
                    borderBottom: `1px solid ${theme.palette.greySecondary.A100}`,
                }}
            >
                <Grid item xs zeroMinWidth>
                    <Tabs
                        value={getSelectedTabInfo(selectedTab)?.id}
                        items={tabs}
                        onChangeTab={onHandleChangeTab}
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: getSelectedTabInfo(selectedTab)?.isPublic
                                    ? theme.palette.primary.main
                                    : theme.palette.warning.main,
                            },
                        }}
                        indicatorPosition={"bottom"}
                        isBordered={false}
                        textColor={"secondary"}
                        variant={"scrollable"}
                        scrollButtons={"auto"}
                        scrollButtonPosition={"right"}
                        divider={
                            <Divider
                                orientation={"vertical"}
                                color={theme.palette.greySecondary.A100}
                                sx={{ height: "20px", mx: 1 }}
                            />
                        }
                        sx={{
                            ".MuiTab-root": {
                                height: theme.spacing(5),
                            },
                            ".MuiTabScrollButton-root.MuiTabScrollButton-horizontal": {
                                border: 0,
                            },
                        }}
                    />
                </Grid>
                <Divider flexItem orientation={"vertical"} sx={{ borderColor: theme.palette.greySecondary.A100 }} />
                <Grid
                    item
                    sx={{
                        p: 0.5,
                        backgroundColor: threadPopperActive
                            ? theme.palette.greySecondary.main
                            : theme.palette.common.white,
                    }}
                    ref={groupsViewRef}
                >
                    <Tooltip
                        title={t(
                            `common::label::${threadPopperActive ? "Close private comments" : "View private comments"}`,
                            threadPopperActive ? "Close private comments" : "View private comments",
                        )}
                    >
                        <IconButton
                            circled
                            onClick={threadPopperToggle}
                            sx={{
                                "&:hover": {
                                    backgroundColor: threadPopperActive
                                        ? alpha(theme.palette.common.white, 0.08)
                                        : undefined,
                                },
                            }}
                        >
                            {threadPopperActive ? (
                                <IconClose size={16} htmlColor={theme.palette.common.white} />
                            ) : (
                                <IconAllView size={16} />
                            )}
                        </IconButton>
                    </Tooltip>
                </Grid>
                <PopperPrivateThreads
                    open={threadPopperActive}
                    width={314}
                    data={resourceData?.results?.filter((el) => el.type === "PRIVATE")}
                    values={getLocalStoragePrivateThreads(resourceData)?.privateThreadIds ?? []}
                    onChange={onHandleCheckPrivateThreads}
                    onClickActionButton={() => openDialog("createGroup")}
                    onClose={() => threadPopperToggle()}
                    anchorEl={groupsViewRef.current}
                    placement={"bottom-start"}
                    noDataComments={<EmptyPrivateThreadComments />}
                />
            </Grid>

            {/*Members View */}
            <Grid
                container
                p={"6px 8px"}
                flexDirection={"row"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                sx={{
                    borderBottom: `1px solid ${theme.palette.greySecondary.A100}`,
                }}
            >
                <Grid item p={"0 12px"}>
                    <Typography variant={"body3_500"} color={theme.palette.greySecondary[500]}>
                        {t("common::label::member of comment", "member of comment")}
                    </Typography>
                </Grid>
                <Grid item>
                    <GroupedAvatar members={threadInfoWithMembers.members?.results?.slice(0, 4)} />
                </Grid>
                <Grid item p={"0 8px"} onClick={() => openDialog("editGroupMembers")}>
                    <Button
                        color={"primary"}
                        variant={"text"}
                        sx={{
                            minWidth: "24px",
                            height: "24px",
                            padding: "0px",
                        }}
                    >
                        <Typography variant={"body4_700"} color={theme.palette.primary.main}>
                            {threadInfoWithMembers.members?.totalCount ?? 0}
                        </Typography>
                    </Button>
                </Grid>
                {!getSelectedTabInfo(selectedTab)?.isPublic && (
                    <Grid item>
                        <PopperMenu
                            ButtonComponent={
                                <span>
                                    <IconButton circled size={"small"} component={"span"}>
                                        <IconMoreVert size={16} />
                                    </IconButton>
                                </span>
                            }
                            menuButtons={[
                                {
                                    skipTranslationLabel: t(`common::label::Change name`, "Change name"),
                                    onClick: (e) => {
                                        openDialog("editGroupName");
                                    },
                                    enable: true,
                                    buttonDisabled: false,
                                },
                                {
                                    skipTranslationLabel: (
                                        <Typography color={theme.palette.error.main}>
                                            {t(`common::label::Delete private comment`, "Delete private comment")}
                                        </Typography>
                                    ),
                                    onClick: (e) => {
                                        openDialog("deleteGroup");
                                    },
                                    enable: true,
                                    buttonDisabled: false,
                                    separate: true,
                                },
                            ]}
                        />
                    </Grid>
                )}
            </Grid>
            {totalUploadingFilesCount > 0 && (
                <Box
                    width={400}
                    backgroundColor={"secondary.main"}
                    color={"secondary.contrastText"}
                    borderRadius={1.125}
                    sx={{
                        position: `absolute`,
                        top: "140px",
                        left: `50%`,
                        ml: `-200px`,
                        // visibility: isShow ? `visible`: `hidden`,
                        zIndex: 100,
                    }}
                >
                    <Stack px={2.5} py={1}>
                        <Typography>
                            {/*{queue.length}*/}
                            {t(`common::label::Uploading files`, `Uploading files`)} ({uploadedFilesCount} /{" "}
                            {totalUploadingFilesCount}
                            )...
                        </Typography>
                        <Grid container alignItems={"center"} flexWrap={"nowrap"} gap={1} mt={0.5}>
                            <Grid item xs>
                                <LinearProgress
                                    gradient
                                    variant="determinate"
                                    value={(uploadedFilesCount / totalUploadingFilesCount) * 100}
                                />
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    color: theme.palette.secondary.contrastText,
                                }}
                            >
                                <Tooltip title={"업데이트 취소"}>
                                    <IconButton color={"inherit"} circled onClick={() => {}}>
                                        <IconCancel size={12} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Stack>
                </Box>
            )}
            {/* Comments View */}
            <Grid
                item
                flex={1}
                sx={{
                    position: "relative",
                    overflowY: "auto",
                    overscrollBehavior: "contain",
                    scrollbarGutter: "stable",
                    backgroundColor: alpha(theme.palette.greySecondary["A100"], 0.48),
                }}
                ref={scrollDivRef}
            >
                <Grid
                    container
                    flexDirection={"column-reverse"}
                    sx={{
                        minHeight: 1,
                        gap: 0.75,
                        py: 0.75,
                        pl: 2.5,
                        pr: 0.75,
                        flexWrap: "nowrap",
                    }}
                >
                    {/*column-reverse를 사용하여 실제 렌더링은 제일 아래의 CardComments->Badge->map 순서로 렌더링됩니다.*/}
                    {!mountPending && !initPending && (
                        <>
                            {uploadingComments?.[selectedThreadId]?.length > 0 && (
                                <>
                                    {uploadingComments?.[selectedThreadId].map((comment) => (
                                        <CardComments
                                            domain={domain}
                                            key={`domain-comment-${comment?.id}`}
                                            data={comment}
                                            isUploading={true}
                                        />
                                    ))}
                                </>
                            )}
                            {comments?.nextPending ? (
                                <>
                                    <SkeletonComment />
                                </>
                            ) : (
                                <Grid ref={nextTargetRef} hidden={!comments?.data?.nextToken} />
                            )}
                            {getCardComments()}
                            {!comments?.createPending &&
                                !comments?.prevPending &&
                                (!comments.data?.results || comments.data?.results?.length === 0) &&
                                !uploadingComments?.[selectedThreadId]?.length && (
                                    <Stack direction={"column"} sx={{ p: "20px 0" }} gap={1.5}>
                                        <GroupTitleWithIcon
                                            isPublic={getSelectedTabInfo(selectedTab)?.isPublic}
                                            title={getSelectedTabInfo(selectedTab)?.name}
                                            iconSize={24}
                                            fontVariant={"subtitle1_700"}
                                        />
                                        <GroupDescription isPublic={getSelectedTabInfo(selectedTab)?.isPublic} />
                                    </Stack>
                                )}
                        </>
                    )}

                    {mountPending || initPending || comments?.prevPending ? (
                        <>
                            {new Array(!mountPending ? 1 : 5).fill("").map((value, index) => (
                                <SkeletonComment key={`reply-container-${index}`} />
                            ))}
                        </>
                    ) : (
                        <Grid ref={prevTargetRef} hidden={!comments?.data?.prevToken} />
                    )}
                </Grid>
            </Grid>
            {dialog === "deleteGroup" && (
                <DialogDeleteGroup
                    data={getSelectedTabInfo(selectedTab)}
                    isLoading={getActionPending("deleteGroup")}
                    onSubmit={onDeletePrivateThread}
                    onClose={closeDialog}
                ></DialogDeleteGroup>
            )}
            {dialog === "createGroup" && (
                <DialogCreateGroup
                    isLoading={getActionPending("createGroup")}
                    onSubmit={onCreatePrivateThread}
                    onClose={closeDialog}
                ></DialogCreateGroup>
            )}
            {dialog === "editGroupMembers" && (
                <DialogEditGroupMembers
                    data={threadInfoWithMembers}
                    onSubmit={onUpdateThreadMembers}
                    isLoading={getActionPending("editGroupMembers")}
                    onClose={closeDialog}
                ></DialogEditGroupMembers>
            )}
            {dialog === "editGroupName" && (
                <DialogEditGroupName
                    data={getSelectedTabInfo(selectedTab)}
                    isLoading={getActionPending("editGroupName")}
                    onSubmit={onUpdatePrivateThread}
                    onClose={closeDialog}
                ></DialogEditGroupName>
            )}
        </Grid>
    );
};

export default React.memo(ReplyContainer);
