import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ImageNoAccessInfo } from "@mzc-cloudplex/icons";
import { Box, Grid, Typography } from "@mzc-pdc/ui";
import theme from "@styles/theme";

const InvalidInvitation = () => {
    const { t } = useTranslation();

    return (
        <div className={"content-wrapper  error-page"}>
            <div className={"card card-error "}>
                <div className={"card-body"}>
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} mb={4}>
                        <ImageNoAccessInfo sx={{ width: 60, height: 60 }} />
                    </Box>
                    <Grid container flexDirection={"column"} alignItems={"center"} gap={theme.spacing(1.5)}>
                        <Box>
                            <Typography variant="h3" component={"p"}>
                                {t(
                                    `common::msg::Invalid invitation code or Your invitation link may have expired.`,
                                    `Invalid invitation code or Your invitation link may have expired.`,
                                )}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="body1_700" color={theme.palette.greySecondary[600]} pt={1.5}>
                                {t(
                                    `common::msg::Please contact your administrator to request a new invitation link.`,
                                    `Please contact your administrator to request a new invitation link.`,
                                )}
                            </Typography>
                        </Box>
                    </Grid>
                </div>
                {/*<div className={"card-footer justify-content-end"}>*/}
                {/*    <Link className={"btn btn-primary"} to={"/welcome"} replace>*/}
                {/*        {t(`common::label::Main`)}*/}
                {/*    </Link>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default InvalidInvitation;
