import { handleActions, createAction } from "redux-actions";
import fp from "lodash/fp";
import Polling from "../cores/polling";
import VideoApi from "./apis/video";

import {
    axios,
    // axiosWithoutHeaders
} from "../cores/axiosWrapper";
import moment from "moment";

import queryString from "../cores/queryString";
import { LIST_VIEW_TYPE } from "../constants";

export function getVideosAPI(stageId, apiEndpoint, projectId, query) {
    const queries = {
        generateAccessUrl: true,
    };
    if (query.offset) queries.offset = query.offset;
    if (query.limit) queries.limit = query.limit;

    if (query.isQuickSearch) {
        queries.q = query.keyword;

        if (query.categoryGroupId) {
            queries.categoryGroupId = query.categoryGroupId;
            if (query.isNotCategoryIds) {
                queries.isNotCategoryIds = query.isNotCategoryIds;
                queries.categoryIds = "-1";
            }
        } else {
            if (query.categoryIds && query.categoryIds.length > 0)
                queries.categoryIds = query.categoryIds
                    .map((c) => {
                        if (typeof c === "object") return c.id;
                        return c;
                    })
                    .join(",");
            if (query.isNotCategoryIds) {
                queries.isNotCategoryIds = query.isNotCategoryIds;
                queries.categoryIds = "-1";
            }
            if (query.isIncludeChildCategory) queries.isIncludeChildCategory = query.isIncludeChildCategory;
            if (query.categoryGroup) queries.categoryGroupId = query.categoryGroupId;
        }
    } else {
        if (query.name) queries.name = query.name;

        if (query.id) queries.id = query.id;

        if (query.ids) queries.ids = query.ids;

        if (query.description) queries.description = query.description;

        if (query.creator) queries.creator = query.creator;

        if (query.status) queries.status = query.status;

        if (query.categoryGroupId) {
            queries.categoryGroupId = query.categoryGroupId;
        } else {
            if (query.categoryIds && query.categoryIds.length > 0)
                queries.categoryIds = query.categoryIds
                    .map((c) => {
                        if (typeof c === "object") return c.id;
                        return c;
                    })
                    .join(",");
            if (query.isNotCategoryIds) {
                queries.isNotCategoryIds = query.isNotCategoryIds;
                queries.categoryIds = "-1";
            }
            if (query.isIncludeChildCategory) queries.isIncludeChildCategory = query.isIncludeChildCategory;
        }

        if (query.tags) queries.tags = fp.getOr([], "tags", query).join(",");

        if (query.metadataSchemaFields) {
            const metadataFields = [];
            Object.keys(query.metadataSchemaFields).map((field) => {
                if (query.metadataSchemaFields[field].value) {
                    const fieldId = field.replace("metadata-schema-field-", "");
                    metadataFields.push({ id: fieldId, keyword: query.metadataSchemaFields[field].value });
                }
            });
            queries.metadataSchemaFields = JSON.stringify(metadataFields);
        }

        if (query.beginDuration && query.endDuration) {
            queries.duration = `${moment.duration(query.beginDuration).asMilliseconds()}~${
                moment.duration(query.endDuration).asMilliseconds() + 999
            }`;
        }

        if (query.startAt && query.endAt) queries.createdAt = `${query.startAt}~${query.endAt}`;

        if (query.peopleId) {
            queries.peopleId = query.peopleId;
        }
    }
    return axios.get(`${apiEndpoint}/videos?${queryString.stringify(queries)}`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function deleteVideoAPI(stageId, apiEndpoint, projectId, id, version) {
    return axios.delete(`${apiEndpoint}/videos/${id}`, {
        params: {
            version,
            deleteAIJobs: true,
        },
        headers: {
            stageId,
            projectId,
        },
    });
}

function updateBulkAPI(stageId, apiEndpoint, projectId, data) {
    return axios.patch(`${apiEndpoint}/videos/bulk`, data, {
        params: {},
        headers: {
            stageId,
            projectId,
        },
    });
}

//action type
const VIDEO_LIST_PENDING = "VIDEO_LIST_PENDING";
const VIDEO_LIST_FAILURE = "VIDEO_LIST_FAILURE";

const UPDATE_VIEW_KIND = "UPDATE_VIEW_KIND";

const VIDEO_LIST_GET_VIDEO_LIST_SUCCESS = "VIDEO_LIST_GET_VIDEO_LIST_SUCCESS";
const VIDEO_LIST_DELETE_VIDEO_SUCCESS = "VIDEO_LIST_DELETE_VIDEO_SUCCESS";

const VIDEO_SET_VALIDATION_ERROR = "VIDEO_SET_VALIDATION_ERROR";

const UPDATE_VIDEO_STATUS = "UPDATE_VIDEO_STATUS";
const UPDATE_VIDEO_METADATA_SCHEMA = "UPDATE_VIDEO_METADATA_SCHEMA";

const CLEAR = "CLEAR";

//reducer
const initialState = {
    pending: false,
    error: false,
    isActiveView: LIST_VIEW_TYPE.LIST,
    isCardView: true,
    isSearched: false,
    data: [],
    totalCount: 0,
    contextVersion: 0,
    validationErrors: {
        name: "required",
    },
    metadataSchemas: {},
};

let tempState = {
    ...initialState,
};

export default handleActions(
    {
        [CLEAR]: (state, action) => {
            tempState = {
                ...initialState,
                contextVersion: tempState.contextVersion + 1,
            };
            return tempState;
        },
        [UPDATE_VIEW_KIND]: (state, action) => {
            const temp = {
                ...tempState,
                ...action.payload,
            };
            tempState = temp;
            return tempState;
        },
        [VIDEO_LIST_PENDING]: (state) => {
            const temp = {
                ...tempState,
                pending: true,
                error: false,
                contextVersion: tempState.contextVersion + 1,
            };
            tempState = temp;
            return tempState;
        },
        [VIDEO_LIST_FAILURE]: (state) => {
            const temp = {
                ...tempState,
                pending: false,
                error: true,
            };
            tempState = temp;
            return tempState;
        },
        [VIDEO_LIST_GET_VIDEO_LIST_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const temp = {
                ...tempState,
                pending: false,
                error: false,
                data: data.videos,
                totalCount: data.totalCount,
            };
            tempState = temp;
            return tempState;
        },
        [VIDEO_LIST_DELETE_VIDEO_SUCCESS]: (state, action) => {
            const temp = {
                ...tempState,
                pending: false,
                // data: state.data.filter( video => video.id !== action.payload)
                data: state.data.map((v) => {
                    if (v.id === action.payload) {
                        v.isDeleted = true;
                    }

                    return v;
                }),
            };
            tempState = temp;
            return tempState;
        },
        [VIDEO_SET_VALIDATION_ERROR]: (state, action) => {
            const { key, error } = action.payload;

            if (error) {
                const temp = {
                    ...tempState,
                    validationErrors: {
                        ...state.validationErrors,
                        [key]: error,
                    },
                };
                tempState = temp;
                return tempState;
            }

            const validationErrors = state.validationErrors;
            if (validationErrors[key]) {
                delete validationErrors[key];
            }
            const temp = {
                ...tempState,
                validationErrors,
            };
            tempState = temp;
            return tempState;
        },
        [UPDATE_VIDEO_STATUS]: (state, action) => {
            const { data } = action.payload;
            const temp = {
                ...tempState,
                data: tempState.data.map((v) => {
                    if (v.id === data.id) {
                        v.status = data.status;
                    }
                    return v;
                }),
            };
            tempState = temp;
            return tempState;
        },
        [UPDATE_VIDEO_METADATA_SCHEMA]: (state, action) => {
            const { metadataSchemaFields } = action.payload;
            const temp = {
                ...tempState,
                metadataSchemas: {
                    ...metadataSchemaFields,
                },
            };
            tempState = temp;
            return tempState;
        },
    },
    initialState,
);

export const clear = createAction(CLEAR);
// export const clearHierarchy = createAction(VIDEO_LIST_HIERARCHY_CLEAR);
export const updateViewKind = createAction(UPDATE_VIEW_KIND);
export const setValidationError = createAction(VIDEO_SET_VALIDATION_ERROR);
export const updateMetadataschema = createAction(UPDATE_VIDEO_METADATA_SCHEMA);

export const getVideos =
    (projectId, search = {}) =>
    (dispatch, getState) => {
        const { stage, project, videos } = getState();

        const targetProjectId = projectId || project.id;
        const searchParams = search;
        const contextVersion = videos.contextVersion + 1;
        dispatch({ type: VIDEO_LIST_PENDING });
        const stageId = stage.id;
        const apiEndpoint = stage.endpoint;

        return new Promise((resolve, reject) => {
            getVideosAPI(stageId, apiEndpoint, targetProjectId, searchParams)
                .then((response) => {
                    dispatch({
                        type: VIDEO_LIST_GET_VIDEO_LIST_SUCCESS,
                        payload: response,
                    });
                    const videos = response.data.videos;
                    for (const video of videos) {
                        const shouldPollStatus = video.status === "ACTIVATING" || video.status === "INACTIVATING";
                        if (!shouldPollStatus) continue;

                        const targetStatus = video.status === "ACTIVATING" ? "ACTIVE" : "INACTIVE";
                        Polling(
                            async () => {
                                return await VideoApi.getStatusAsync(stageId, apiEndpoint, targetProjectId, video.id);
                            },
                            (response) => {
                                return (
                                    getState().videos.contextVersion !== contextVersion ||
                                    response.data.status === targetStatus
                                );
                            },
                            (response) => {
                                dispatch({
                                    type: UPDATE_VIDEO_STATUS,
                                    payload: response,
                                });
                            },
                            null,
                            3000,
                        ).run();
                    }
                    resolve(response.data);
                })
                .catch((error) => {
                    dispatch({
                        type: VIDEO_LIST_FAILURE,
                        payload: error,
                    });
                    reject(error);
                });
        });
    };

export const deleteVideo = (id, version) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: VIDEO_LIST_PENDING });

    return deleteVideoAPI(stage.id, stage.endpoint, project.id, id, version)
        .then((response) => {
            dispatch({
                type: VIDEO_LIST_DELETE_VIDEO_SUCCESS,
                payload: id,
            });
        })
        .catch((error) => {
            dispatch({
                type: VIDEO_LIST_FAILURE,
                payload: error,
            });
        });
};

export const updateBulkAsync = (data) => (dispatch, getState) => {
    const { stage, project } = getState();

    return updateBulkAPI(stage.id, stage.endpoint, project.id, data)
        .then((response) => {
            console.debug("updateBulkAPI response", response);
        })
        .catch((error) => {
            console.error("updateBulkAPI error", error);
        });
};
