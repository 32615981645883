import * as React from "react";
import { Button, Dialog, DialogContent, DialogContentText, DialogActions } from "@mzc-pdc/ui";
import { Typography } from "@mui/material";
import { useTranslation, Trans } from "react-i18next";

export const DialogCancelWarning = (props) => {
    const { t } = useTranslation();
    return (
        <Dialog open={true} onClose={() => props.onClose(false)} variant="alert">
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">{props.message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    onClick={(e) => {
                        e.stopPropagation();
                        props.onClose(false);
                    }}
                    size="small"
                    variant="text"
                >
                    {t(`common::label::Cancel`)}
                </Button>
                <Button
                    color="primary"
                    onClick={(e) => {
                        e.stopPropagation();
                        props.onClose(true);
                    }}
                    size="small"
                    variant="text"
                >
                    {t(`common::label::Confirm`)}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogCancelWarning;
