import React from "react";
import { useTranslation } from "react-i18next";
import { CONSTANTS, JOB_PANEL_TABS } from "@constants";
import { bindActionCreators } from "redux";
import * as rightSideBarActions from "../../../modules/rightSideBar";
import { useDispatch } from "react-redux";
import { getUrlInfo } from "../../../lib/mime/mime";
import useMyJobs from "@hooks/useMyJobs";

const DownloadInfoMessage = ({ folders, fileName, totalCount, isDirectDownloadMissMatchRegion }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const RightSideBarActions = bindActionCreators(rightSideBarActions, dispatch);

    const { openMyJobs } = useMyJobs({});

    const urlInfo = getUrlInfo(fileName);

    const openDownloadRightSideBar = () => {
        openMyJobs(JOB_PANEL_TABS.DOWNLOAD);
    };

    return (
        <div>
            <p className="title">
                <i className="sprite sprite-request" />
                <span>
                    {t("common::label::Download", `Download`)} {t("common::label::requested", `requested`)}.
                </span>
            </p>
            {isDirectDownloadMissMatchRegion && (
                <>
                    <>
                        <hr />
                        <div className={"d-flex"}>
                            <i className={"sprite sprite-cdn"} />
                            <span className={"text-warning"}>
                                {t(
                                    `common::msg::${"It may take some time for stable processing because it is downloading a file from a location far away from the current location (overseas) or a large file."}`,
                                )}
                            </span>
                        </div>
                    </>
                    <hr />
                </>
            )}

            <div className="file-process">
                <div className="file">
                    <div className="file-info">
                        <span className="file-type">
                            {urlInfo.extension ? (
                                <i className={`sprite sprite-asset-${urlInfo.mediaType.toLowerCase()}-white`} />
                            ) : (
                                <i className="sprite sprite-folder-open-white" />
                            )}
                        </span>
                        {totalCount === 1 ? (
                            <span className="file-name">{fileName}</span>
                        ) : (
                            <>
                                <span className="file-name">
                                    <span>{fileName} </span>
                                </span>
                                <span className="file-size">
                                    {t("common::label::and", `and`)} <strong>{totalCount - 1}</strong>{" "}
                                    {t("common::label::Other", `Other`)}
                                </span>
                            </>
                        )}
                    </div>

                    {folders &&
                        folders.slice(0, 5).map((data) => {
                            return (
                                <div className="file-path" key={data}>
                                    <div>
                                        <i className="sprite sprite-path-white" />
                                        <span>{data}</span>
                                    </div>
                                </div>
                            );
                        })}
                    {folders.length > 5 && (
                        <div className={"text-center"}>
                            <i className={"sprite sprite-etc-vertical"} />
                        </div>
                    )}
                </div>
            </div>
            <hr />
            <div className="btns">
                <button
                    type="button"
                    className="btn btn-outline-white btn-round btn-sm"
                    onClick={(e) => {
                        e.preventDefault();
                        openDownloadRightSideBar();
                        // this.onClickDownloadDetail();
                    }}
                >
                    <strong>{t("common::label::Detail", `Detail`)}</strong>
                    <i className="sprite sprite-arrow-right-primary-active"></i>
                </button>
            </div>
        </div>
    );
};
export default DownloadInfoMessage;
