import { Box, Grid, styled } from "@mzc-pdc/ui";
import { alpha, Avatar } from "@mui/material";
import theme from "@styles/theme";
import React from "react";
import Skeleton from "@components_v2/skeleton/skeleton";

const StyledCommentGrid = styled(Grid)(({ theme, isThread }) => ({
    padding: "8px 16px",
    borderRadius: isThread ? 0 : 4,
    gap: "12px",
    backgroundColor: theme.palette.common.white,
}));

const SkeletonComment = ({ isThread }) => {
    return (
        <StyledCommentGrid container flexDirection={"row"} isThread={isThread}>
            <Grid item sx={{ flex: "0 0 32px" }}>
                <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
                    <Skeleton variant="circular" animation={"wave"} width={32} height={32} />
                </Box>
            </Grid>
            <Grid item sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                <Grid
                    item
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    sx={{ display: "flex", width: "100%", flex: `0 0 32px`, pb: 1 }}
                >
                    <Box sx={{ display: "flex", width: "100%", alignItems: "end", gap: 1 }}>
                        <Skeleton variant="rounded" animation={"wave"} width={40} height={22} />
                        <Skeleton variant="rounded" animation={"wave"} width={40} height={18} />
                    </Box>
                    <Box>
                        <Skeleton variant="circular" animation={"wave"} width={24} height={24} />
                    </Box>
                </Grid>
                <Grid
                    item
                    sx={{
                        width: "100%",
                        flex: 1,
                        gap: 0.25,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Skeleton variant="rounded" animation={"wave"} width={"80%"} height={22} />
                    <Skeleton variant="rounded" animation={"wave"} width={"50%"} height={22} />
                </Grid>
            </Grid>
        </StyledCommentGrid>
    );
};

export default SkeletonComment;
