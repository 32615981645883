import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setPrevPath, IncreaseRequestCount } from "../../modules/global";
import { initParams } from "../../modules/channels";
import { setProject } from "../../modules/navigatorForAdmin";
import { sessionKeeper } from "../../cores/sessionKeeper";
import { IconArrowBack, IconArrowForward, IconBack, IconMenu, IconSettingProject } from "@mzc-cloudplex/icons";
import menuService from "@services/menuService";
import {
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Tooltip,
    Typography,
    Box,
    ListSubheader,
    Divider,
} from "@mzc-pdc/ui";
import { Drawer, BackToProjectButton } from "./nav.styled";
import theme from "@styles/theme";

import { NavLink as NavLinkBase } from "react-router-dom";

const NavLink = React.forwardRef((props, ref) => (
    <NavLinkBase
        ref={ref}
        {...props}
        className={({ isActive }) => `${props.className} ${isActive ? props.activeClassName : ""}`}
    />
));
const NavigatorAdmin = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const location = useLocation();
    const project = useSelector(({ project }) => project);
    const global = useSelector(({ global }) => global);
    const stage = useSelector(({ stage }) => stage);
    const menus = useSelector(({ navigatorForAdmin }) => navigatorForAdmin.data);
    const { spaceId, projectId } = menuService.parse(location.pathname);
    const [menuType, setMenuType] = useState("space");

    const [navOpen, setNavOpen] = useState(true);
    const [isShown, setIsShown] = useState(false);

    useEffect(() => {
        setActiveProject();
        const menuType = projectId ? "projects" : "space";
        setMenuType(menuType);
    }, []);

    useEffect(() => {
        setActiveProject();
    }, [project, stage]);

    useEffect(() => {
        const menuType = projectId ? "projects" : "space";
        setMenuType(menuType);
    }, [menuType]);

    const setActiveProject = () => {
        const sessionCookie = sessionKeeper.get();
        if (!sessionCookie) return;
        dispatch(
            setProject(
                {
                    space: stage,
                    project,
                },
                sessionCookie.authProvider,
            ),
        );
    };

    const onToggleNavigator = () => {
        if (document.documentElement.classList.contains("nav-fold")) {
            document.documentElement.classList.remove("nav-fold");
        } else {
            document.documentElement.classList.add("nav-fold");
        }
    };

    const leave = () => {
        let prevUrl = global.prevPath === "" ? "/" : global.prevPath;
        dispatch(initParams());

        history.replace(prevUrl);
        dispatch(setPrevPath("/"));
    };
    const handleNavToggle = () => {
        setNavOpen(!navOpen);
    };

    const getNavToggleIcon = () => {
        if (navOpen) return <IconArrowBack size={12} htmlColor={`#fff`} />;
        if (isShown) return <IconArrowForward size={12} htmlColor={`#fff`} />;

        return <IconMenu size={12} htmlColor={`#fff`} />;
    };

    return (
        <>
            <Drawer variant="permanent" open={navOpen} type={"administration"}>
                <Toolbar />
                <Box sx={{ flex: 1, display: `flex`, overflowY: "overlay" }} id={"animate"}>
                    <Box
                        sx={{ flex: 1, display: `flex`, flexDirection: `column`, overflow: "hidden", width: "100%" }}
                        id="nav"
                    >
                        {navOpen ? (
                            <Typography
                                variant={"subtitle2_700"}
                                sx={{
                                    height: `22px`,
                                    margin: `28px 24px 8px`,
                                    textTransform: `uppercase`,
                                }}
                            >
                                {t(`common::label::${"Administration"}`)}
                            </Typography>
                        ) : (
                            <Box
                                sx={{
                                    display: `inline-flex`,
                                    alignItems: `center`,
                                    justifyContent: `center`,
                                    width: 40,
                                    height: `22px`,
                                    margin: `28px 12px 8px`,
                                    backgroundColor: theme.palette.secondary.contrastText,
                                    borderRadius: `4px`,
                                }}
                            >
                                <IconSettingProject size={12} color={"greySecondary"} />
                            </Box>
                        )}
                        {menus && menus[menuType] && (
                            <List>
                                {menus[menuType].menus
                                    .filter((v) => !v.disabled)
                                    .map((menus, i) => (
                                        <ListItem key={`submenu-${i}`} divider>
                                            {menus.path ? (
                                                <Tooltip title={navOpen ? "" : t(`${menus.name}`)} placement={"right"}>
                                                    <ListItemButton
                                                        selected={
                                                            location.pathname === menus.path ||
                                                            location.pathname.startsWith(`${menus.path}/`)
                                                        }
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            history.push(menus.path);
                                                            dispatch(IncreaseRequestCount());
                                                        }}
                                                    >
                                                        {/*<NavLink*/}
                                                        {/*    to={{ pathname: menus.path, state: "flush" }}*/}
                                                        {/*    activeClassName={"active"}*/}
                                                        {/*    className={"nav-link"}*/}
                                                        {/*    onClick={() => {*/}
                                                        {/*        dispatch(IncreaseRequestCount());*/}
                                                        {/*    }}*/}
                                                        {/*>*/}
                                                        <ListItemIcon>{menus.icon && menus.icon}</ListItemIcon>

                                                        <ListItemText
                                                            primary={t(`${menus.name}`)}
                                                            primaryTypographyProps={{
                                                                variant: "body1_500",
                                                                component: "strong",
                                                            }}
                                                        />
                                                    </ListItemButton>
                                                </Tooltip>
                                            ) : (
                                                <>
                                                    <ListSubheader component={"div"}>
                                                        {navOpen ? (
                                                            <Typography
                                                                variant={"body4_700"}
                                                                color={theme.palette.secondary[400]}
                                                            >
                                                                {t(`${menus.name}`)}
                                                            </Typography>
                                                        ) : (
                                                            <Box
                                                                sx={{
                                                                    width: `8px`,
                                                                    height: `2px`,
                                                                    borderRadius: `1px`,
                                                                    margin: `7px auto`,
                                                                    backgroundColor: theme.palette.secondary[400],
                                                                }}
                                                            />
                                                        )}
                                                    </ListSubheader>
                                                    {menus.subMenus && (
                                                        <List>
                                                            {menus.subMenus
                                                                .filter((v) => !v.disabled)
                                                                .map((subMenus, i) => (
                                                                    <Tooltip
                                                                        key={i}
                                                                        title={navOpen ? "" : t(`${subMenus.name}`)}
                                                                        placement={"right"}
                                                                    >
                                                                        <ListItem>
                                                                            <ListItemButton
                                                                                selected={
                                                                                    location.pathname ===
                                                                                        subMenus.path ||
                                                                                    location.pathname.startsWith(
                                                                                        `${subMenus.path}/`,
                                                                                    )
                                                                                }
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    history.push(subMenus.path);
                                                                                    dispatch(IncreaseRequestCount());
                                                                                }}
                                                                            >
                                                                                <ListItemIcon>
                                                                                    {subMenus.icon && subMenus.icon}
                                                                                </ListItemIcon>

                                                                                <ListItemText
                                                                                    primary={t(`${subMenus.name}`)}
                                                                                    primaryTypographyProps={{
                                                                                        variant: "body1_500",
                                                                                        component: "strong",
                                                                                    }}
                                                                                />
                                                                            </ListItemButton>
                                                                        </ListItem>
                                                                    </Tooltip>
                                                                ))}
                                                        </List>
                                                    )}
                                                </>
                                            )}
                                        </ListItem>
                                    ))}
                            </List>
                        )}
                        <Divider sx={{ mb: 1.5, opacity: 0.12 }} />
                        <Tooltip title={navOpen ? "" : "Back to Project"} placement={"right"}>
                            <BackToProjectButton
                                color="inherit"
                                className={"back-to-console-button"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    leave();
                                }}
                                startIcon={
                                    <Box width={22} height={22} borderRadius={5}>
                                        <IconBack size={12} htmlColor={`#fff`} />
                                    </Box>
                                }
                            >
                                {t(`common::label::${"Back to Project"}`)}
                            </BackToProjectButton>
                        </Tooltip>
                        <Tooltip title={navOpen ? "Folding" : "Open"} placement={"right"} leaveDelay={-200}>
                            <IconButton
                                className={"nav-toggle-button"}
                                color={navOpen ? "primary" : "inherit"}
                                variant={"contained"}
                                circled
                                onClick={handleNavToggle}
                                edge="start"
                                onMouseEnter={() => setIsShown(true)}
                                onMouseLeave={() => setIsShown(false)}
                            >
                                {getNavToggleIcon()}
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
            </Drawer>
        </>
    );
};

export default NavigatorAdmin;
