import { handleActions, createAction } from "redux-actions";
import ContentSchemasOldApi from "@modules/apis/content-schemas-old";
import PhotoApi from "./apis/photo";
import HiddenMetadataApi from "./apis/hiddenMetadata";

//action type
const PHOTO_DETAIL_RESET = "PHOTO_DETAIL_RESET";
const PHOTO_DETAIL_PENDING = "PHOTO_DETAIL_PENDING";

const PHOTO_DETAIL_GET_SUCCESS = "PHOTO_DETAIL_GET_SUCCESS";
const PHOTO_DETAIL_GET_FAILURE = "PHOTO_DETAIL_GET_FAILURE";

const PHOTO_DETAIL_UPDATE_SUCCESS = "PHOTO_DETAIL_UPDATE_SUCCESS";
const PHOTO_DETAIL_UPDATE_FAILURE = "PHOTO_DETAIL_UPDATE_FAILURE";

const PHOTO_DETAIL_DELETE_SUCCESS = "PHOTO_DETAIL_DELETE_SUCCESS";
const PHOTO_DETAIL_DELETE_FAILURE = "PHOTO_DETAIL_DELETE_FAILURE";

const PHOTO_DETAIL_CONTENT_SCHEMAS_GET_SUCCESS = "PHOTO_DETAIL_CONTENT_SCHEMAS_GET_SUCCESS";
const PHOTO_DETAIL_CONTENT_SCHEMAS_GET_FAILURE = "PHOTO_DETAIL_CONTENT_SCHEMAS_GET_FAILURE";

const PHOTO_DETAIL_IS_CHANGING_DATA = "PHOTO_DETAIL_IS_CHANGING_DATA";

const PHOTO_DETAIL_UPDATE_CONTENTS_SUCCESS = "PHOTO_DETAIL_UPDATE_CONTENTS_SUCCESS";
const PHOTO_DETAIL_UPDATE_CONTENTS_FAILURE = "PHOTO_DETAIL_UPDATE_CONTENTS_FAILURE";

const PHOTO_DETAIL_GET_HIDDEN_METADATA_VALUE_PENDING = "PHOTO_DETAIL_GET_HIDDEN_METADATA_VALUE_PENDING";
const PHOTO_DETAIL_GET_HIDDEN_METADATA_SET_EMPTY = "PHOTO_DETAIL_GET_HIDDEN_METADATA_SET_EMPTY";
const PHOTO_DETAIL_GET_HIDDEN_METADATA_VALUE_SUCCESS = "PHOTO_DETAIL_GET_HIDDEN_METADATA_VALUE_SUCCESS";
const PHOTO_DETAIL_GET_HIDDEN_METADATA_VALUE_FAILURE = "PHOTO_DETAIL_GET_HIDDEN_METADATA_VALUE_FAILURE";

const initialState = {
    data: null,
    contentSchema: [],
    pending: false,
    error: false,
    isDeleted: false,
    isChangingData: false,
    contextVersion: 0,
};

let tempState = {
    ...initialState,
};

export default handleActions(
    {
        [PHOTO_DETAIL_PENDING]: (state) => {
            const temp = {
                ...tempState,
                pending: true,
                contextVersion: tempState.contextVersion + 1,
            };

            tempState = temp;

            return tempState;
        },

        [PHOTO_DETAIL_RESET]: () => {
            tempState = {
                ...initialState,
            };
            return tempState;
        },

        [PHOTO_DETAIL_CONTENT_SCHEMAS_GET_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            const schema = JSON.parse(data.schema);

            const temp = {
                ...tempState,
                contentSchema: schema,
                pending: false,
                contextVersion: tempState.contextVersion + 1,
            };

            tempState = temp;

            return tempState;
        },

        [PHOTO_DETAIL_GET_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            console.log("PHOTO_DETAIL_GET_SUCCESS data:", data);

            const temp = {
                ...tempState,
                data: data,
                pending: false,
                contextVersion: tempState.contextVersion + 1,
            };

            tempState = temp;

            return tempState;
        },

        [PHOTO_DETAIL_GET_FAILURE]: (state, action) => {
            console.log("PHOTO_DETAIL_GET_FAILURE");

            return {
                ...state,
                pending: false,
                error: true,
                contextVersion: state.contextVersion + 1,
            };
        },

        [PHOTO_DETAIL_DELETE_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            console.log("PHOTO_DETAIL_DELETE_SUCCESS data:", data);

            // const temp = {
            //     ...tempState,
            //     data: data,
            //     contextVersion: tempState.contextVersion + 1
            // };

            // tempState = temp;

            // return tempState;
            return tempState;
        },

        [PHOTO_DETAIL_DELETE_FAILURE]: (state, action) => {
            const { data } = action.payload;

            console.log("PHOTO_DETAIL_DELETE_FAILURE data:", data);

            // const temp = {
            //     ...tempState,
            //     data: data,
            //     contextVersion: tempState.contextVersion + 1
            // };

            // tempState = temp;

            // return tempState;
            return {
                ...state,
                pending: false,
                error: true,
            };
        },

        [PHOTO_DETAIL_IS_CHANGING_DATA]: (state, action) => {
            const temp = {
                ...tempState,
                isChangingData: true,
                contextVersion: tempState.contextVersion + 1,
            };
            tempState = temp;
            return tempState;
        },

        [PHOTO_DETAIL_UPDATE_CONTENTS_SUCCESS]: (state, action) => {
            const temp = {
                ...tempState,
            };
            tempState = temp;
            return tempState;
        },

        [PHOTO_DETAIL_UPDATE_CONTENTS_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },

        [PHOTO_DETAIL_UPDATE_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const temp = {
                ...tempState,
                isChangingData: false,
                pending: false,
                error: false,
                data: {
                    ...tempState.data,
                    ...data,
                },
            };
            tempState = temp;
            return tempState;
        },

        [PHOTO_DETAIL_GET_HIDDEN_METADATA_VALUE_PENDING]: (state, action) => {
            const { metadataSchemaId, metadataFieldId } = action.payload;

            let metadataSchemas = refineMetadata(tempState.data.metadataSchemas, metadataSchemaId, metadataFieldId, {
                pending: true,
                error: false,
            });

            tempState = {
                ...tempState,
                data: {
                    ...tempState.data,
                    metadataSchemas,
                },
            };
            return tempState;
        },
        [PHOTO_DETAIL_GET_HIDDEN_METADATA_SET_EMPTY]: (state, action) => {
            const { metadataSchemaId, metadataFieldId } = action.payload;

            let metadataSchemas = refineMetadata(tempState.data.metadataSchemas, metadataSchemaId, metadataFieldId, {
                pending: false,
                error: false,
                value: "",
                values: [],
                isCheckHiddenValue: true,
            });

            tempState = {
                ...tempState,
                data: {
                    ...tempState.data,
                    metadataSchemas,
                },
            };
            return tempState;
        },
        [PHOTO_DETAIL_GET_HIDDEN_METADATA_VALUE_SUCCESS]: (state, action) => {
            const { metadataSchemaId, metadataFieldId, response } = action.payload;

            const metadataSchemas = refineMetadata(tempState.data.metadataSchemas, metadataSchemaId, metadataFieldId, {
                pending: false,
                error: false,
                value: response.data.value,
                values: response.data.values,
                isCheckHiddenValue: true,
            });

            tempState = {
                ...tempState,
                data: {
                    ...tempState.data,
                    metadataSchemas,
                },
            };
            return tempState;
        },
        [PHOTO_DETAIL_GET_HIDDEN_METADATA_VALUE_FAILURE]: (state, action) => {
            const { metadataSchemaId, metadataFieldId, error } = action.payload;

            const metadataSchemas = refineMetadata(tempState.data.metadataSchemas, metadataSchemaId, metadataFieldId, {
                pending: false,
                error,
                isCheckHiddenValue: false,
            });

            tempState = {
                ...tempState,
                data: {
                    ...tempState.data,
                    metadataSchemas,
                },
            };
            return tempState;
        },
    },
    initialState,
);

export const reset = createAction(PHOTO_DETAIL_RESET);

export const getContentSchemasAsync = (params) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({
        type: PHOTO_DETAIL_PENDING,
    });

    return ContentSchemasOldApi.getContentSchemasAsync(stage.id, stage.endpoint, project.id, params)
        .then((response) => {
            dispatch({
                type: PHOTO_DETAIL_CONTENT_SCHEMAS_GET_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: PHOTO_DETAIL_CONTENT_SCHEMAS_GET_FAILURE,
                payload: error,
            });
        });
};

export const getAsync = (id) => (dispatch, getState) => {
    const { stage, project } = getState();
    console.log("getPhotoAsync id:", id);

    dispatch({
        type: PHOTO_DETAIL_PENDING,
    });

    return PhotoApi.getPhotoAsync(stage.id, stage.endpoint, project.id, id)
        .then((response) => {
            dispatch({
                type: PHOTO_DETAIL_GET_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            console.log("--error : ", error);
            dispatch({
                type: PHOTO_DETAIL_GET_FAILURE,
                payload: error,
            });
        });
};

export const updateAsync = (id, version, data) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({
        type: PHOTO_DETAIL_PENDING,
    });

    return PhotoApi.updatePhotoAsync(stage.id, stage.endpoint, project.id, id, version, data)
        .then((response) => {
            dispatch({
                type: PHOTO_DETAIL_UPDATE_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: PHOTO_DETAIL_UPDATE_FAILURE,
                payload: error,
            });
        });
};

export const deleteAsync = (id) => (dispatch, getState) => {
    const { stage, project } = getState();
    return PhotoApi.deletePhotoAsync(stage.id, stage.endpoint, project.id, id)
        .then((response) => {
            dispatch({
                type: PHOTO_DETAIL_DELETE_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: PHOTO_DETAIL_DELETE_FAILURE,
                payload: error,
            });
        });
};

export const attachContents = (id, version, itemSchemaType, data) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: PHOTO_DETAIL_IS_CHANGING_DATA });

    return new Promise((resolve, reject) => {
        PhotoApi.attachContentsAPI(stage.id, stage.endpoint, project.id, id, version, itemSchemaType, data)
            .then((response) => {
                dispatch({
                    type: PHOTO_DETAIL_UPDATE_CONTENTS_SUCCESS,
                    payload: response,
                });
                resolve(response.data);
            })
            .catch((error) => {
                dispatch({
                    type: PHOTO_DETAIL_UPDATE_CONTENTS_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const detachContents = (id, version, itemSchemaType, elementId) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: PHOTO_DETAIL_IS_CHANGING_DATA });

    return new Promise((resolve, reject) => {
        PhotoApi.detachContentsAPI(stage.id, stage.endpoint, project.id, id, version, itemSchemaType, elementId)
            .then((response) => {
                dispatch({
                    type: PHOTO_DETAIL_UPDATE_CONTENTS_SUCCESS,
                    payload: response,
                });
                resolve(response.data);
            })
            .catch((error) => {
                dispatch({
                    type: PHOTO_DETAIL_UPDATE_CONTENTS_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const getNotExecPending = (id) => (dispatch, getState) => {
    const { stage, project } = getState();

    return PhotoApi.getPhotoAsync(stage.id, stage.endpoint, project.id, id)
        .then((response) => {
            dispatch({
                type: PHOTO_DETAIL_GET_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            console.log("error : ", error);
            dispatch({
                type: PHOTO_DETAIL_GET_FAILURE,
                payload: error,
            });
        });
};

export const getHiddenMetadataValue = (id, metadataSchemaId, metadataFieldId) => (dispatch, getState) => {
    const { stage, project, video } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;

    dispatch({ type: PHOTO_DETAIL_GET_HIDDEN_METADATA_VALUE_PENDING, payload: { metadataSchemaId, metadataFieldId } });

    return new Promise((resolve, reject) => {
        HiddenMetadataApi.getHiddenMetadata(stageId, apiEndpoint, projectId, "PHOTO", id, metadataFieldId)
            .then((response) => {
                dispatch({
                    type: PHOTO_DETAIL_GET_HIDDEN_METADATA_VALUE_SUCCESS,
                    payload: {
                        response,
                        metadataSchemaId,
                        metadataFieldId,
                    },
                });
                resolve(response);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    dispatch({
                        type: PHOTO_DETAIL_GET_HIDDEN_METADATA_VALUE_FAILURE,
                        payload: {
                            error,
                            metadataSchemaId,
                            metadataFieldId,
                        },
                    });

                    reject(error);
                } else if (error.response.status === 404) {
                    dispatch({
                        type: PHOTO_DETAIL_GET_HIDDEN_METADATA_SET_EMPTY,
                        payload: { metadataSchemaId, metadataFieldId },
                    });
                    resolve();
                }
            });
    });
};

const refineMetadata = (target, metadataSchemaId, metadataFieldId, data) => {
    return target.map((v) => {
        if (v.id === metadataSchemaId) {
            const fields = v.fields.map((f) => {
                if (f.id === metadataFieldId) {
                    return {
                        ...f,
                        ...data,
                    };
                } else {
                    return f;
                }
            });
            return {
                ...v,
                fields,
            };
        } else {
            return v;
        }
    });
};
