import { useLocation } from "react-router-dom";
import menuService from "@services/menuService";
import {
    AudioAssetColumn,
    CaptionAssetColumn,
    FileAssetColumn,
    ImageAssetColumn,
    PreviewAssetColumn,
    VideoAssetColumn,
} from "@features/autocomplete-table/autocomplete-table-columns/autocomplete-table-columns-assets";
import {
    CustomContentColumn,
    MusicContentColumn,
    PeopleContentColumn,
    PhotoContentColumn,
    VideoContentColumn,
} from "@features/autocomplete-table/autocomplete-table-columns/autocomplete-table-columns-contents";
import { CollectionColumn } from "@features/autocomplete-table/autocomplete-table-columns/autocomplete-table-columns-collections";
import Skeleton from "@components_v2/skeleton/skeleton";
import * as React from "react";
import { Grid } from "@mui/material";

export const useCommonTable = () => {
    const getResourceType = (type) => {
        if (type.indexOf("CONTENT") >= 0) return "CONTENT";
        else if (type.indexOf("ASSET") >= 0) return "ASSET";
        else if (type.indexOf("COLLECTION") >= 0) return "COLLECTION";
    };
    const getTableColumns = (type, subType) => {
        if (type === "ASSET") {
            switch (subType) {
                case "VIDEO":
                    return VideoAssetColumn;
                case "AUDIO":
                    return AudioAssetColumn;
                case "IMAGE":
                    return ImageAssetColumn;
                case "CAPTION":
                    return CaptionAssetColumn;
                case "PREVIEW":
                    return PreviewAssetColumn;
                case "FILE":
                default:
                    return FileAssetColumn;
            }
        } else if (type === "CONTENT") {
            switch (subType) {
                case "VIDEO":
                    return VideoContentColumn;
                case "MUSIC":
                    return MusicContentColumn;
                case "PEOPLE":
                    return PeopleContentColumn;
                case "PHOTO":
                    return PhotoContentColumn;
                case "CUSTOM":
                    return CustomContentColumn;
            }
        } else if (type === "COLLECTION") {
            return CollectionColumn;
        }
    };

    return {
        getResourceType,
        getTableColumns,
    };
};
