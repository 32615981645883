import {
    getUsersAPI,
    getUserAPI,
    getUserTeamsAPI,
    getUserRolesAPI,
    patchUserAPI,
    deleteUserAPI,
    getProjectUserAPI,
    getProjectUsersAPI,
    getProjectUserTeamsAPI,
    getProjectTeamsAPI,
    getProjectUserRolesAPI,
    patchProjectUserAPI,
    inviteProjectPendingUserAPI,
    inviteSpacePendingUserAPI,
} from "@modules/apis/user-management-v2";
import { createAction, handleActions } from "redux-actions";

const initialState = {
    initPending: false,
    pending: false,
    error: false,
    params: {
        keywordType: "username",
        keyword: "",
        offset: 0,
        limit: 50,
    },
    totalCount: 0,
    users: null,
    shareUsers: { totalCount: 0, results: [], version: 0 }, //version: API 중첩 회피
    user: null,
    roles: {
        totalCount: 0,
        results: [],
        params: {
            teamId: "",
            name: "",
            offset: 0,
            limit: 50,
        },
    },
    shareTeams: {
        totalCount: 0,
        results: [],
        params: {
            offset: 0,
            limit: 50,
        },
        version: 0,
    },
    teams: {
        totalCount: 0,
        results: [],
        params: {
            offset: 0,
            limit: 50,
        },
    },
    permissions: [],
    userRequestVersion: 0,
};

const USER_MANAGEMENT_CLEAR = "USER_MANAGEMENT_CLEAR";

const USER_MANAGEMENT_PENDING = "USER_MANAGEMENT_PENDING";
const USER_MANAGEMENT_ACTION_PENDING = "USER_MANAGEMENT_ACTION_PENDING";
const USER_MANAGEMENT_FAILURE = "USER_MANAGEMENT_FAILURE";

const GET_USER_MANAGEMENT_PENDING = "GET_USER_MANAGEMENT_PENDING";
const GET_USER_MANAGEMENT_FAILURE = "GET_USER_MANAGEMENT_FAILURE";

const USER_MANAGEMENT_UPDATE_USER_PARAMS = "USER_MANAGEMENT_UPDATE_USER_PARAMS";
const USER_MANAGEMENT_RESET_USER_PARAMS = "USER_MANAGEMENT_RESET_USER_PARAMS";

const USER_MANAGEMENT_GET_USERS_SUCCESS = "USER_MANAGEMENT_GET_USERS_SUCCESS";
const USER_MANAGEMENT_GET_SHARE_USERS_INIT = "USER_MANAGEMENT_GET_SHARE_USERS_INIT";
const USER_MANAGEMENT_GET_SHARE_USERS_PENDING = "USER_MANAGEMENT_GET_SHARE_USERS_PENDING";
const USER_MANAGEMENT_GET_SHARE_USERS_SUCCESS = "USER_MANAGEMENT_GET_SHARE_USERS_SUCCESS";
const USER_MANAGEMENT_GET_USER_SUCCESS = "USER_MANAGEMENT_GET_USER_SUCCESS";
const USER_MANAGEMENT_GET_USER_ROLES_SUCCESS = "USER_MANAGEMENT_GET_USER_ROLES_SUCCESS";
const USER_MANAGEMENT_GET_TEAMS_PENDING = "USER_MANAGEMENT_GET_TEAMS_PENDING";
const USER_MANAGEMENT_GET_TEAMS_SUCCESS = "USER_MANAGEMENT_GET_TEAMS_SUCCESS";
const USER_MANAGEMENT_GET_SHARE_TEAMS_INIT = "USER_MANAGEMENT_GET_SHARE_TEAMS_INIT";
const USER_MANAGEMENT_GET_SHARE_TEAMS_PENDING = "USER_MANAGEMENT_GET_SHARE_TEAMS_PENDING";
const USER_MANAGEMENT_GET_SHARE_TEAMS_SUCCESS = "USER_MANAGEMENT_GET_SHARE_TEAMS_SUCCESS";
const USER_MANAGEMENT_UPDATE_TEAMS_PARAMS = "USER_MANAGEMENT_UPDATE_TEAMS_PARAMS";
const USER_MANAGEMENT_UPDATE_USER_ROLES_PARAMS = "USER_MANAGEMENT_UPDATE_USER_ROLES_PARAMS";

const USER_MANAGEMENT_UPDATE_USER_FAILURE = "USER_MANAGEMENT_UPDATE_USER_FAILURE";
const USER_MANAGEMENT_UPDATE_USER_SUCCESS = "USER_MANAGEMENT_UPDATE_USER_SUCCESS";

const USER_MANAGEMENT_GET_PROJECT_USERS_SUCCESS = "USER_MANAGEMENT_GET_PROJECT_USERS_SUCCESS";
const USER_MANAGEMENT_GET_PROJECT_USERS_FAILURE = "USER_MANAGEMENT_GET_PROJECT_USERS_FAILURE";
const USER_MANAGEMENT_GET_PROJECT_USER_FAILURE = "USER_MANAGEMENT_GET_PROJECT_USER_FAILURE";
const USER_MANAGEMENT_GET_PROJECT_USER_SUCCESS = "USER_MANAGEMENT_GET_PROJECT_USER_SUCCESS";

const USER_MANAGEMENT_DELETE_USER_FAILURE = "USER_MANAGEMENT_DELETE_USER_FAILURE";
const USER_MANAGEMENT_DELETE_USER_SUCCESS = "USER_MANAGEMENT_DELETE_USER_SUCCESS";

const USER_MANAGEMENT_UPDATE_PROJECT_USER_SUCCESS = "USER_MANAGEMENT_UPDATE_PROJECT_USER_SUCCESS";

export default handleActions(
    {
        [USER_MANAGEMENT_CLEAR]: (state) => {
            return {
                ...initialState,
            };
        },
        [USER_MANAGEMENT_PENDING]: (state) => {
            return {
                ...state,
                pending: true,
                error: null,
            };
        },
        [GET_USER_MANAGEMENT_PENDING]: (state) => {
            return {
                ...state,
                initPending: true,
                error: null,
            };
        },
        [USER_MANAGEMENT_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [GET_USER_MANAGEMENT_FAILURE]: (state, action) => {
            return {
                ...state,
                initPending: false,
                error: true,
            };
        },
        [USER_MANAGEMENT_UPDATE_USER_PARAMS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
                params: {
                    ...state.params,
                    ...action.payload,
                },
            };
        },
        [USER_MANAGEMENT_GET_PROJECT_USER_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            return {
                ...state,
                user: {
                    ...data,
                },
                pending: false,
            };
        },
        [USER_MANAGEMENT_GET_PROJECT_USER_FAILURE]: (state, action) => {
            return {
                ...state,
                user: null,
                pending: false,
                error: action.payload,
            };
        },
        [USER_MANAGEMENT_RESET_USER_PARAMS]: (state) => {
            return {
                ...state,
                params: {
                    ...state.params,
                    keywordType: "username",
                    keyword: "",
                },
            };
        },
        [USER_MANAGEMENT_GET_USERS_SUCCESS]: (state, action) => {
            const data = action.payload.data;
            return {
                ...state,
                initPending: false,
                pending: false,
                error: false,
                totalCount: data.totalCount,
                users: data.results,
            };
        },
        [USER_MANAGEMENT_GET_USER_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                initPending: false,
                error: false,
                user: data,
            };
        },
        [USER_MANAGEMENT_UPDATE_USER_FAILURE]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                pending: false,
                error: false,
                updateUserError: data,
            };
        },
        [USER_MANAGEMENT_UPDATE_USER_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const temp = {
                ...state,
                user: {
                    ...data,
                },
                pending: false,
                error: false,
                updateUserError: null,
            };
            const existingUsers = state.users;
            if (existingUsers) {
                temp.users = existingUsers.map((v) => {
                    if (v.id === data.id) {
                        return {
                            ...data,
                        };
                    }
                    return v;
                });
            }
            return temp;
        },
        [USER_MANAGEMENT_UPDATE_PROJECT_USER_SUCCESS]: (state, action) => {
            const payload = action.payload;
            const existingUsers = state.users;
            const temp = {
                ...state,
                user: {
                    ...state.user,
                    ...payload,
                },
                pending: false,
                error: false,
            };
            if (existingUsers) {
                temp.users = existingUsers.map((v) => {
                    if (v.id === payload.id) {
                        return {
                            ...v,
                            ...payload,
                        };
                    }
                    return v;
                });
            }
            return temp;
        },
        [USER_MANAGEMENT_UPDATE_USER_ROLES_PARAMS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
                roles: {
                    ...state.roles,
                    params: {
                        ...state.roles.params,
                        ...action.payload,
                    },
                },
            };
        },
        [USER_MANAGEMENT_UPDATE_TEAMS_PARAMS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
                teams: {
                    ...state.teams,
                    params: {
                        ...state.teams.params,
                        ...action.payload,
                    },
                },
            };
        },
        [USER_MANAGEMENT_GET_USER_ROLES_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                pending: false,
                error: false,
                roles: {
                    ...state.roles,
                    totalCount: data.results?.length,
                    results: data.results,
                },
            };
        },
        [USER_MANAGEMENT_GET_TEAMS_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            return {
                ...state,
                pending: false,
                error: false,
                teams: {
                    ...state.teams,
                    totalCount: data.totalCount,
                    results: data.results,
                },
            };
        },
        [USER_MANAGEMENT_GET_SHARE_USERS_INIT]: (state) => {
            return {
                ...state,
                pending: true,
                error: false,
                shareUsers: { totalCount: 0, results: [], version: state.shareUsers.version + 1 },
            };
        },
        [USER_MANAGEMENT_GET_SHARE_USERS_PENDING]: (state, action) => {
            const { totalCount, results } = action.payload;

            return {
                ...state,
                error: false,
                shareUsers: {
                    totalCount: totalCount,
                    results: results,
                },
            };
        },
        [USER_MANAGEMENT_GET_SHARE_USERS_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
            };
        },
    },
    initialState,
);

export const clear = createAction(USER_MANAGEMENT_CLEAR);
export const updateParams = createAction(USER_MANAGEMENT_UPDATE_USER_PARAMS);
export const resetParams = createAction(USER_MANAGEMENT_RESET_USER_PARAMS);
export const updateRoleParams = createAction(USER_MANAGEMENT_UPDATE_USER_ROLES_PARAMS);
export const updateTeamParams = createAction(USER_MANAGEMENT_UPDATE_TEAMS_PARAMS);

export const getUsers = (params, projectId) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: GET_USER_MANAGEMENT_PENDING });
    return getUsersAPI(stage.id, stage.endpoint, projectId, params)
        .then((response) => {
            dispatch({
                type: USER_MANAGEMENT_GET_USERS_SUCCESS,
                payload: response,
            });
            return response && response.data;
        })
        .catch((error) => {
            dispatch({
                type: GET_USER_MANAGEMENT_FAILURE,
                payload: error,
            });
        });
};

export const updateUser =
    ({ id, projectId, data }) =>
    (dispatch, getState) => {
        const { stage } = getState();

        dispatch({ type: USER_MANAGEMENT_ACTION_PENDING });

        return patchUserAPI(stage.id, stage.endpoint, projectId, id, data)
            .then(async () => {
                const response = await getUserAPI(stage.id, stage.endpoint, projectId, id);
                dispatch({
                    type: USER_MANAGEMENT_UPDATE_USER_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: USER_MANAGEMENT_UPDATE_USER_FAILURE,
                    payload: error,
                });
            });
    };

export const getUser = (id, projectId) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: GET_USER_MANAGEMENT_PENDING });
    return getUserAPI(stage.id, stage.endpoint, projectId, id)
        .then((response) => {
            dispatch({
                type: USER_MANAGEMENT_GET_USER_SUCCESS,
                payload: response,
            });
            return response && response.data;
        })
        .catch((error) => {
            dispatch({
                type: GET_USER_MANAGEMENT_FAILURE,
                payload: error,
            });
        });
};

export const deleteUser =
    ({ id, projectId }, callback) =>
    (dispatch, getState) => {
        const { stage } = getState();

        dispatch({ type: USER_MANAGEMENT_PENDING });

        return deleteUserAPI(stage.id, stage.endpoint, projectId, id)
            .then((response) => {
                dispatch({
                    type: USER_MANAGEMENT_DELETE_USER_SUCCESS,
                    payload: response,
                });
                if (callback) {
                    callback(null, response);
                }
            })
            .catch((error) => {
                dispatch({
                    type: USER_MANAGEMENT_DELETE_USER_FAILURE,
                    payload: error,
                });
                if (callback) {
                    callback(error);
                }
            });
    };

export const getUserRoles =
    ({ projectId, params }) =>
    (dispatch, getState) => {
        const { stage } = getState();

        dispatch({ type: USER_MANAGEMENT_PENDING });

        return getUserRolesAPI(stage.id, stage.endpoint, projectId, params)
            .then((response) => {
                dispatch({
                    type: USER_MANAGEMENT_GET_USER_ROLES_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: USER_MANAGEMENT_FAILURE,
                    payload: error,
                });
            });
    };

export const getUserTeams =
    ({ projectId, params }) =>
    (dispatch, getState) => {
        const { stage } = getState();
        dispatch({ type: USER_MANAGEMENT_PENDING });
        return getUserTeamsAPI(stage.id, stage.endpoint, projectId, params)
            .then((response) => {
                dispatch({
                    type: USER_MANAGEMENT_GET_TEAMS_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: USER_MANAGEMENT_FAILURE,
                    payload: error,
                });
            });
    };

// Project Settings Users
export const getProjectUserTeams =
    ({ params, projectId, userId }) =>
    (dispatch, getState) => {
        const { stage, project } = getState();
        const _projectId = projectId || project.id;

        dispatch({ type: USER_MANAGEMENT_PENDING });
        return getProjectUserTeamsAPI(stage.id, stage.endpoint, _projectId, userId, params)
            .then((response) => {
                dispatch({
                    type: USER_MANAGEMENT_GET_TEAMS_SUCCESS,
                    payload: response,
                });
                return response?.data;
            })
            .catch((error) => {
                dispatch({
                    type: USER_MANAGEMENT_FAILURE,
                    payload: error,
                });
            });
    };

export const getProjectUsers = (params, projectId) => (dispatch, getState) => {
    const { stage, project } = getState();
    const _projectId = projectId || project.id;

    dispatch({ type: GET_USER_MANAGEMENT_PENDING });
    return getProjectUsersAPI(stage.id, stage.endpoint, _projectId, params)
        .then((response) => {
            dispatch({
                type: USER_MANAGEMENT_GET_USERS_SUCCESS,
                payload: response,
            });
            return response?.data;
        })
        .catch((error) => {
            dispatch({
                type: GET_USER_MANAGEMENT_FAILURE,
                payload: error,
            });
        });
};

export const getProjectUser = (id, projectId) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: USER_MANAGEMENT_PENDING });
    return getProjectUserAPI(stage.id, stage.endpoint, projectId, id)
        .then((response) => {
            dispatch({
                type: USER_MANAGEMENT_GET_PROJECT_USER_SUCCESS,
                payload: response,
            });
            return response?.data;
        })
        .catch((error) => {
            dispatch({
                type: USER_MANAGEMENT_GET_PROJECT_USER_FAILURE,
                payload: error,
            });
        });
};

export const getProjectUserRoles =
    ({ params, projectId, userId }) =>
    (dispatch, getState) => {
        const { stage, project } = getState();
        const _projectId = projectId || project.id;

        dispatch({ type: USER_MANAGEMENT_PENDING });
        return getProjectUserRolesAPI(stage.id, stage.endpoint, _projectId, userId, params)
            .then((response) => {
                dispatch({
                    type: USER_MANAGEMENT_GET_USER_ROLES_SUCCESS,
                    payload: response,
                });
                return response?.data;
            })
            .catch((error) => {
                dispatch({
                    type: USER_MANAGEMENT_FAILURE,
                    payload: error,
                });
            });
    };

export const getProjectTeams = (projectId, params) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: USER_MANAGEMENT_PENDING });

    return getProjectTeamsAPI(stage.id, stage.endpoint, projectId, params)
        .then((response) => {
            dispatch({
                type: USER_MANAGEMENT_GET_TEAMS_SUCCESS,
                payload: response,
            });

            return response && response.data;
        })
        .catch((error) => {
            dispatch({
                type: USER_MANAGEMENT_FAILURE,
                payload: error,
            });
        });
};

export const updateProjectUser = (projectId, userId, body) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: USER_MANAGEMENT_PENDING });
    return patchProjectUserAPI(stage.id, stage.endpoint, projectId, userId, body)
        .then((response) => {
            const payload = {
                id: userId,
            };
            if (body.siteAccess) {
                payload.siteAccess = body.siteAccess;
            }
            if (body.isAdmin != null && body.isAdmin != undefined) {
                payload.isProjectAdmin = body.isAdmin;
            }
            dispatch({
                type: USER_MANAGEMENT_UPDATE_PROJECT_USER_SUCCESS,
                payload,
            });
        })
        .catch((error) => {
            dispatch({
                type: USER_MANAGEMENT_UPDATE_USER_FAILURE,
                payload: error,
            });
        });
};

export const inviteSpacePendingUser = (body) => (dispatch, getState) => {
    const { stage } = getState();
    return new Promise((resolve, reject) => {
        inviteSpacePendingUserAPI(stage.id, stage.endpoint, body)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const inviteProjectPendingUser = (body) => (dispatch, getState) => {
    const { stage, project } = getState();
    return new Promise((resolve, reject) => {
        inviteProjectPendingUserAPI(stage.id, stage.endpoint, project.id, body)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
