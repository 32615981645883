import { FormControlLabel } from "@mzc-pdc/ui";
import { styled } from "@mui/material";

export const RadioFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    ".MuiFormControlLabel-label": {
        paddingLeft: `8px`,
    },
    "&": {
        marginLeft: 0,
    },
}));
