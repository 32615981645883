import { handleActions, createAction } from "redux-actions";

import { axios, axiosWithoutHeaders } from "../cores/axiosWrapper";
import moment from "moment";

import queryString from "../cores/queryString";
import convertUtcToLocal from "../cores/convertUtcToLocal";
import addJobStatusPolling from "../cores/addJobStatusPolling";

import * as jobStatus from "../cores/jobStatus";

function getJobsAPI(stageId, apiEndpoint, projectId, queries) {
    return axios.get(`${apiEndpoint}/jobs?${queryString.stringify(queries)}`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function deleteJobAPI(stageId, apiEndpoint, projectId, id) {
    return axios.delete(`${apiEndpoint}/jobs/${id}`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function correctJobStatusAPI(stageId, apiEndpoint, projectId, id) {
    return axios.put(`${apiEndpoint}/jobs/${id}/status/correct`, null, {
        headers: {
            stageId,
            projectId,
        },
    });
}

//action type
const JOB_LIST_PENDING = "JOB_LIST_PENDING";
const JOB_LIST_FAILURE = "JOB_LIST_FAILURE";

const JOB_LIST_UPDATE_STATE = "JOB_LIST_UPDATE_STATE";
const JOB_LIST_UPDATE_SEARCH = "JOB_LIST_UPDATE_SEARCH";
const JOB_LIST_RESET_SEARCH = "JOB_LIST_RESET_SEARCH";

const JOB_LIST_GET_JOB_LIST_SUCCESS = "JOB_LIST_GET_JOB_LIST_SUCCESS";
const JOB_LIST_DELETE_JOB_SUCCESS = "JOB_LIST_DELETE_JOB_SUCCESS";
const JOB_LIST_UPDATE_JOB_DATA = "JOB_LIST_UPDATE_JOB_DATA";
const JOB_LIST_UPDATE_JOB_DATA_BY_POLLING = "JOB_LIST_UPDATE_JOB_DATA_BY_POLLING";

const JOB_LIST_TOGGLE_ACTION_BUTTON = "JOB_LIST_TOGGLE_ACTION_BUTTON";

//reducer
const initialState = {
    pending: false,
    error: false,
    data: [],
    totalCount: 0,
    pollingCancelTokenSource: null,
    search: {
        offset: 0,
        status: "",
        creator: "",
        keywordType: "name",
        keyword: "",
        name: "",
        id: "",
        startAt: null,
        endAt: null,
        limit: 20,
        pipelineId: "",
    },
};

export default handleActions(
    {
        [JOB_LIST_UPDATE_STATE]: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
        [JOB_LIST_PENDING]: (state) => {
            return {
                ...state,
                pending: true,
                error: false,
            };
        },
        [JOB_LIST_FAILURE]: (state) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [JOB_LIST_GET_JOB_LIST_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                pending: false,
                error: false,
                data: data.jobs,
                totalCount: data.totalCount,
            };
        },
        [JOB_LIST_UPDATE_SEARCH]: (state, action) => {
            return {
                ...state,
                search: {
                    ...state.search,
                    ...action.payload,
                },
            };
        },
        [JOB_LIST_RESET_SEARCH]: (state) => {
            return {
                ...state,
                pending: false,
                error: false,
                search: {
                    offset: 0,
                    status: "",
                    creator: "",
                    keywordType: "name",
                    keyword: "",
                    name: "",
                    id: "",
                    startAt: null,
                    endAt: null,
                    limit: 20,
                    pipelineId: "",
                },
            };
        },
        [JOB_LIST_DELETE_JOB_SUCCESS]: (state, action) => {
            return {
                ...state,
                data: state.data.map((v) => {
                    return {
                        ...v,
                        isDeleted: v.id === action.payload,
                    };
                }),
            };
        },
        [JOB_LIST_TOGGLE_ACTION_BUTTON]: (state, action) => {
            return {
                ...state,
                data:
                    state.data &&
                    state.data.map((v) => {
                        return {
                            ...v,
                            isShowActionButton: v.id === action.payload ? !v.isShowActionButton : false,
                        };
                    }),
            };
        },
        [JOB_LIST_UPDATE_JOB_DATA]: (state, action) => {
            const data = action.payload;

            return {
                ...state,
                pending: false,
                error: false,
                data:
                    state.data &&
                    state.data.map((v) => {
                        if (v.id === data.id) {
                            return {
                                ...v,
                                ...data,
                                isShowActionButton: false,
                            };
                        }

                        return v;
                    }),
            };
        },
        [JOB_LIST_UPDATE_JOB_DATA_BY_POLLING]: (state, action) => {
            const jobs = action.payload;

            return {
                ...state,
                data:
                    state.data &&
                    state.data.map((j) => {
                        const targets = jobs.filter((v) => v.id === j.id);

                        const target = targets[0];
                        if (target && jobStatus.getValuesLowerThan(j.status).indexOf(target.status) < 0) {
                            return { ...j, ...target };
                        }

                        return j;
                    }),
            };
        },
    },
    initialState,
);

export const pending = createAction(JOB_LIST_PENDING);
export const failure = createAction(JOB_LIST_FAILURE);
export const updateSearch = createAction(JOB_LIST_UPDATE_SEARCH);
export const resetSearch = createAction(JOB_LIST_RESET_SEARCH);
export const toggleActionButton = createAction(JOB_LIST_TOGGLE_ACTION_BUTTON);
export const updateJobData = createAction(JOB_LIST_UPDATE_JOB_DATA);

export const getJobs = (queries) => (dispatch, getState) => {
    const { stage, project, jobs } = getState();

    dispatch({ type: JOB_LIST_PENDING });

    return getJobsAPI(stage.id, stage.endpoint, project.id, queries)
        .then((response) => {
            dispatch({
                type: JOB_LIST_GET_JOB_LIST_SUCCESS,
                payload: response,
            });

            if (jobs.pollingCancelTokenSource) {
                jobs.pollingCancelTokenSource.cancel();
            }

            const cancelTokenSource = axios._cancelToken.source();
            dispatch({
                type: JOB_LIST_UPDATE_STATE,
                payload: {
                    pollingCancelTokenSource: cancelTokenSource,
                },
            });

            if (response.data.totalCount > 0) {
                addJobStatusPolling({
                    params: {
                        apiEndpoint: stage.endpoint,
                        stageId: stage.id,
                        projectId: project.id,
                        jobs: response.data.jobs.filter(
                            (v) => !moment(convertUtcToLocal(v.updatedAt)).isSameOrBefore(moment().add(-1, "day")),
                        ),
                        cancelToken: cancelTokenSource.token,
                    },
                    successCallback: (data) => {
                        if (data.jobs) {
                            dispatch({
                                type: JOB_LIST_UPDATE_JOB_DATA_BY_POLLING,
                                payload: data.jobs,
                            });
                        }
                    },
                });
            }
        })
        .catch((error) => {
            dispatch({
                type: JOB_LIST_FAILURE,
                payload: error,
            });
        });
};

export const deleteJob = (id) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: JOB_LIST_PENDING });

    return deleteJobAPI(stage.id, stage.endpoint, project.id, id)
        .then(() => {
            dispatch({
                type: JOB_LIST_DELETE_JOB_SUCCESS,
                payload: id,
            });
        })
        .catch(() => {
            dispatch({
                type: JOB_LIST_FAILURE,
            });
        });
};

export const correctJobStatus = (id) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: JOB_LIST_PENDING });

    return correctJobStatusAPI(stage.id, stage.endpoint, project.id, id)
        .then((response) => {
            dispatch({
                type: JOB_LIST_UPDATE_JOB_DATA,
                payload: {
                    ...response.data,
                    id,
                },
            });
        })
        .catch((error) => {
            dispatch({
                type: JOB_LIST_FAILURE,
                payload: error,
            });
        });
};
