import React, { useEffect, useMemo } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import HeaderForCms from "@components/header/HeaderForCms";
import Notification from "@components/notification/Notification";
import Loader from "@components/loader";
import PropertyPanel from "@components/property-panel";
import Footer from "@components/footer";
import RightSideBar from "@routes/rightsidebar";
import * as uploadActions from "@modules/upload";
import { JOB_ID_LCOAL_STORAGE_KEY } from "@/constants";
import { checkIsMobile } from "@/utils/checkmobile";
import { Toolbar } from "@mzc-pdc/ui";
import clsx from "clsx";
import { HeaderForAdmin, HeaderForLayout } from "@components/header";
import Navigator from "@components/navigator";
import { Container, Documents, Main } from "@components_v2/layout/layout.styled";
import ReactDOM from "react-dom";

const LayoutForCms = ({ children }) => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    const isMobile = checkIsMobile();

    const globalLoading = useSelector((state) => state.global.loading);
    const isSidebarPushOpened = useSelector((state) => state.propertyPanel.isShow);
    const stageId = useSelector((state) => state.stage.id);
    const isSidebarCoverOpened = useSelector((state) => state.rightSideBar.active);
    const isLineupPage = useMemo(
        () => location.pathname.indexOf("/schedules") > 0 && location.pathname.indexOf("/lineups") > 0,
        [location.pathname],
    );

    useEffect(() => {
        const storedJobIds = localStorage.getItem(JOB_ID_LCOAL_STORAGE_KEY);
        if (storedJobIds?.split(",").length > 0) {
            dispatch(
                uploadActions.addStoredJobs({
                    ids: storedJobIds,
                }),
            );
        }
    }, [stageId]);

    return (
        <Documents
            className={clsx({
                "wrap wrap-cms": true,
                "property-panel panel-push-open": isSidebarPushOpened,
                "panel-cover-open": isSidebarCoverOpened,
                "in-fixed-tab": isLineupPage,
                "is-mobile": isMobile,
            })}
        >
            <HeaderForCms location={location} history={history} />

            <Container>
                <Toolbar />
                <Toolbar  sx={{mb: `10px`}}/>
                <Main>{children}</Main>
                <Footer />
            </Container>

            {stageId && (
                <>
                    <PropertyPanel location={location} isLineupPage={isLineupPage} />
                    <RightSideBar location={location} />
                </>
            )}
            {globalLoading && <Loader />}
            <Notification />
        </Documents>
    );
};

export default LayoutForCms;
