import React, { useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress, Grid, Typography } from "@mzc-pdc/ui";
import InactiveByErrorTooltip from "../tooltip/InactiveByErrorTooltip";
import {
    IconCancelCircle,
    IconCircle,
    IconCircleOutlined,
    IconCompleteCircle,
    IconErrorCircle,
} from "@mzc-cloudplex/icons";

const State = ({ variant, status, desiredStatus }) => {
    const { t } = useTranslation();

    const toDisplayName = (status) => {
        switch (status) {
            case "INACTIVE_BY_ERROR":
                return t(`common::label::INACTIVE`);
            case "PREPARING":
                return t(`common::label::PREPARING`);
            case "UPLOADING":
                return t(`common::label::UPLOADING`, "UPLOADING");
            case "COMPLETED":
                return t(`common::label::COMPLETE`, "COMPLETE");
            case "SUBMITED":
                return t(`common::label::SUBMITED`, "SUBMITED");
            default:
                return t(`common::label::${status}`, status);
        }
    };

    const getStatusErrorMessage = (desiredStatus) => {
        /*
            if(desiredStatus === 'ACTIVE') {
                return t(`common::msg::${"Error while activating"}`);
            }
            else if(desiredStatus === 'INACTIVE') {
                return t(`common::msg::${"Error while inactivating"}`);
            }
            return t(`common::msg::${"Inactivated by error"}`);
        */
        return t(`common::msg::An attempts to change the status from 'Active' to 'Inactive' failed. Please try again.`);
    };

    let color = "";
    let icon = "";

    if (status === "SUCCEED") {
        status = "COMPLETE";
    } else if (status === "FAILED") {
        status = "ERROR";
    } else if (status === "RUNNING") {
        status = "PROGRESSING";
    }

    switch (status) {
        case "COMPLETED":
        case "COMPLETE":
        case "SUCCEED":
        case "YES":
            color = "success";
            icon = <IconCompleteCircle size={16} />;
            break;

        case "Create":
            color = "status-text status-text-create";
            break;

        case "ACTIVATING":
        case "PROGRESSING":
        case "RUNNING":
        case "UPLOADING":
            color = "primary";
            // icon = <CircularProgress size={12} thickness={5} color="inherit" />;
            break;
        case "PREPARING":
            color = "warning";
            // icon = <CircularProgress size={12} thickness={5} color="inherit" />;
            break;
        case "READY":
            color = "warning";
            icon = <IconCircle size={12} />;
            break;
        case "ERROR":
        case "FAILED":
        case "NOT_READY":
            color = "error";
            icon = <IconErrorCircle size={12} />;
            break;
        case "NOMEDIA":
            color = "greySecondary";
            icon = <IconCancelCircle size={12} />;
            break;
        case "ACTIVE":
            color = "success";
            icon = <IconCircle size={12} />;
            break;
        case "INACTIVE":
        case "INACTIVE_BY_ERROR":
            color = "greySecondary";
            icon = <IconCircleOutlined size={12} />;
            break;
        case "INACTIVATING":
        case "INGESTING":
        case "INGESTED":
            color = "warning";
            // icon = <CircularProgress size={12} thickness={5} color="inherit" />;
            break;
        case "SUBMITED":
        case "SUBMITTED":
            color = "primary";
            break;
        case "CANCELED":
        case "NO":
        case "UNUSED":
            color = "greySecondary";
            icon = <IconCancelCircle size={12} />;
            break;
        case "USED":
            color = "success";
            icon = <IconCompleteCircle size={16} />;
            break;
        default:
            color = "greySecondary";
            break;
    }

    return (
        <Grid
            container
            width={"auto"}
            alignItems={"center"}
            bgcolor={
                variant === "contained"
                    ? color === "greySecondary"
                        ? `greySecondary.500`
                        : `${color}.main`
                    : "transparent"
            }
            color={
                variant !== "contained"
                    ? color === "greySecondary"
                        ? `greySecondary.500`
                        : `${color}.main`
                    : "common.white"
            }
            gap={0.75}
            px={variant === "contained" ? 1 : 0}
            py={variant === "contained" ? 0.25 : 0}
            borderRadius={variant === "contained" ? 0.7 : 0}
        >
            {icon && icon}
            <Typography variant="body1">{toDisplayName(status)}</Typography>
            {status === "INACTIVE_BY_ERROR" && (
                <InactiveByErrorTooltip message={getStatusErrorMessage(desiredStatus)} />
            )}
        </Grid>
    );
};

export default memo(State, (prevProps, nextProps) => prevProps.status === nextProps.status);
