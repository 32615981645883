import React, { useCallback } from "react";
import { Grid } from "@mui/material";
import { IconHierarchyFolded } from "@mzc-cloudplex/icons";
import Skeleton from "@components_v2/skeleton/skeleton";

const FolderTreeViewSkeleton = ({ type, depth }) => {
    const skeletonItem = useCallback(
        (depth = 0) => (
            <Grid
                container
                sx={{
                    minWidth: 318,
                    height: "38px",
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: `${(depth + 1) * 16}px`,
                }}
            >
                <IconHierarchyFolded size={12} htmlColor={"#6f6f83"} sx={{ marginRight: "8px" }} />
                <Skeleton
                    variant={"rectangular"}
                    animation={"wave"}
                    height={20}
                    sx={{ width: `${depth === 0 ? "200px" : `${291 - depth * 16}px`}`, borderRadius: "3px" }}
                />
            </Grid>
        ),
        [],
    );

    return (
        <>
            {type === "root" ? (
                <>
                    {skeletonItem()}
                    {skeletonItem(1)}
                </>
            ) : (
                <>{skeletonItem(depth)}</>
            )}
        </>
    );
};

export default FolderTreeViewSkeleton;
