import React from "react";
import { connect } from "react-redux";
import config from "../../config/config";
import { withRouter } from "react-router";
import { EntryLoader } from "../../components/loader";
import { LayoutForNoting } from "../../components/layout";
import Cookies from "js-cookie";
import {isLocal} from "@cores/enviromentExtension";

// Pops launcher에서 앱을 클릭해서 들어오게 될 때 호출되는 페이지

class LoginRedirection extends React.Component {
    componentDidMount() {
        const { location } = this.props;
        const params = location.search.replace("?", "").split("&");

        const domain = isLocal ? "localhost" : "megazone.io";
        Cookies.remove("beforeLogin", {
            domain
        });

        let clientId;
        if (params) {
            const length = params.length;
            for (let i = 0; i < length; i++) {
                const param = params[i];
                const keyAndValues = param.split("=");
                const key = keyAndValues[0];
                if (key === "client_id" && keyAndValues.length == 2) {
                    clientId = keyAndValues[1];
                    localStorage.setItem("CLIENT_ID", clientId);
                    break;
                }
            }
        }
        const redirectUrl = `${window.location.origin}${config.host.loginRedirectUri}`;
        window.location.href = `${config.mz_pops.oauth_host}/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}`;
    }

    render() {
        return (
            <LayoutForNoting>
                <EntryLoader />
            </LayoutForNoting>
        );
    }
}
let mapStateToProps = (state) => {
    return {};
};
let mapDispatchToProps = (dispatch) => {
    return {};
};

LoginRedirection = connect(mapStateToProps, mapDispatchToProps)(LoginRedirection);
export default withRouter(LoginRedirection);
