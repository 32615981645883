import { styled } from "@mui/material";

export const StyledStatusIcon = styled('div')(({ theme }) => ({
    display: `inline-flex`,
    alignItems: `center`,
    justifyContent: `center`,
}));

export const StyledStatusText = styled('div')(({ theme }) => ({
    display: `inline-flex`,
    alignItems: `center`,
    justifyContent: `center`,
    gap: 8
}));

