import { axios, axiosWithoutHeaders } from "../cores/axiosWrapper";

import Polling from "./polling_v2";

import * as jobStatus from "../cores/jobStatus";

const addJobStatusPolling = ({
    params,
    successCallback,
    errorCallback = undefined,
    targetStatus = jobStatus.values,
}) => {
    const targets = params.jobs ? params.jobs.filter((v) => targetStatus[v.status]) : [];
    if (targets.length <= 0) {
        return;
    }
    if (params.hasAssociations) {
        targets.forEach((t) => {
            new Polling(
                async () => {
                    const response = await axios.get(`${params.apiEndpoint}/jobs/${t.id}`, {
                        cancelToken: params.cancelToken,
                        headers: {
                            stageId: params.stageId,
                            projectId: params.projectId,
                        },
                    });
                    return response && response.data;
                },
                (response) => {
                    return !response || !jobStatus.isComplete(response.status);
                },
                successCallback,
                errorCallback ?? null,
            ).run();
        });
    } else {
        const ids = targets.map((t) => t.id).join(",");
        new Polling(
            async () => {
                const response = await axios.get(`${params.apiEndpoint}/jobs`, {
                    cancelToken: params.cancelToken,
                    headers: {
                        stageId: params.stageId,
                        projectId: params.projectId,
                    },
                    params: {
                        ids,
                    },
                });
                return response && response.data;
            },
            (response) => {
                if (!response || !response.jobs) return true;
                const completeCount = response.jobs.filter((v) => jobStatus.isComplete(v.status)).length;
                if (completeCount === response.totalCount) return true;

                return false;
            },
            successCallback,
            errorCallback ?? null,
            5000,
        ).run();
    }
};

export default addJobStatusPolling;
