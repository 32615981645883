import React, { useCallback, useMemo } from "react";
import { Divider, Grid } from "@mzc-pdc/ui";
import TreeViewNav from "./tree-view-nav";
import TreeViewBreadcrumb from "./tree-view-breadcrumb";
import TreeViewActions from "./tree-view-actions";
import { GROUPING_VIEW_TYPE, TABLE_LIST_TYPE } from "@constants";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedHistory } from "@modules/folderTreeView";
import { updateSelectedHistory as updateSelectedCategoryHistory } from "@modules/categoryTreeView";
import { siteMode } from "@cores/siteMode";

const TreeViewTools = ({
    domain,
    hierarchyType,
    breadcrumbMenu,
    onClickFolderTree,
    onClickTreeItem,
    onToggleModal,
}) => {
    const dispatch = useDispatch();
    const folderTreeView = useSelector(({ folderTreeView }) => folderTreeView.folderList);
    const categoryTreeView = useSelector(({ categoryTreeView }) => categoryTreeView);

    const onHandleClickPrevButton = useCallback(() => {
        const { history, index } = treeViewHistory;
        if (hierarchyType === GROUPING_VIEW_TYPE.FOLDER) {
            onClickFolderTree(history[index - 1], false, false, true);
            dispatch(updateSelectedHistory(-1));
        } else {
            onClickTreeItem(history[index - 1]);
            dispatch(updateSelectedCategoryHistory(-1));
        }
    }, [hierarchyType, treeViewHistory, onClickFolderTree]);

    const onHandleClickNextButton = useCallback(() => {
        const { history, index } = treeViewHistory;

        if (hierarchyType === GROUPING_VIEW_TYPE.FOLDER) {
            onClickFolderTree(history[index + 1], false, false, true);
            dispatch(updateSelectedHistory(1));
        } else {
            onClickTreeItem(history[index + 1]);
            dispatch(updateSelectedCategoryHistory(1));
        }
    }, [hierarchyType, treeViewHistory, onClickFolderTree]);

    const onHandleClickUpButton = useCallback(() => {
        if (hierarchyType === GROUPING_VIEW_TYPE.FOLDER) {
            onClickFolderTree(selectedParent, false, false);
        } else {
            onClickTreeItem(selectedParent, false, false);
        }
    }, [treeViewHistory, onClickFolderTree]);

    const treeViewHistory = useMemo(() => {
        if (hierarchyType === GROUPING_VIEW_TYPE.FOLDER)
            return {
                selected: folderTreeView.selectedFolder?.folder,
                history: folderTreeView.selectedFolder?.history,
                index: folderTreeView.selectedFolder?.index,
            };
        else
            return {
                selected: categoryTreeView.selectedCategory?.selected,
                history: categoryTreeView.selectedCategory?.history,
                index: categoryTreeView.selectedCategory?.index,
            }; //수정필요함
    }, [folderTreeView, categoryTreeView, hierarchyType]);

    const selectedParent = useMemo(() => {
        if (!treeViewHistory || !treeViewHistory.selected) return undefined;

        if (hierarchyType === GROUPING_VIEW_TYPE.FOLDER) return folderTreeView.data?.[treeViewHistory.selected?.parent];
        else return categoryTreeView.categoryMap[treeViewHistory.selected?.parent?.id]; //수정필요함
    }, [folderTreeView, categoryTreeView, hierarchyType, treeViewHistory]);

    return (
        <Grid item>
            <Grid container alignItems={"center"} gap={1.5}>
                <Grid item xs>
                    <Grid container alignItems={"center"} flexWrap={"nowrap"} gap={1.5}>
                        <Grid item sx={{ display: `flex`, alignItems: `center` }}>
                            <TreeViewNav
                                treeViewHistory={treeViewHistory}
                                onClickPrev={onHandleClickPrevButton}
                                onClickNext={onHandleClickNextButton}
                                onClickUp={onHandleClickUpButton}
                            />
                        </Grid>
                        <Divider flexItem orientation={"vertical"} sx={{}} />
                        <TreeViewBreadcrumb
                            hierarchyType={hierarchyType}
                            treeViewHistory={treeViewHistory}
                            breadcrumbMenu={breadcrumbMenu}
                            onClickFolderTree={onClickFolderTree}
                            onClickTreeItem={onClickTreeItem}
                        />
                    </Grid>
                </Grid>
                {siteMode.get() !== "CMS" &&
                    hierarchyType === GROUPING_VIEW_TYPE.FOLDER &&
                    [TABLE_LIST_TYPE.ASSET, TABLE_LIST_TYPE.COLLECTION].includes(domain) && (
                        <Grid item>
                            <TreeViewActions onToggleModal={onToggleModal} />
                        </Grid>
                    )}
            </Grid>
        </Grid>
    );
};

export default TreeViewTools;
