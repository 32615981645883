import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ReactComponent as ImgLogo } from "@assets/svg/logo.svg";

const Logo = ({ to, imgSrc, className, fill }) => {
    return (
        <h1>
            <Link className={className} to={to}>
                {imgSrc ? <img src={imgSrc} alt="logo" style={{ maxHeight: 24 }} /> : <ImgLogo fill={fill} />}
            </Link>
        </h1>
    );
};

Logo.propTypes = {
    to: PropTypes.string,
    imgSrc: PropTypes.string,
    className: PropTypes.string,
};

Logo.defaultProps = {};

export default Logo;
