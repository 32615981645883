import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from "@mzc-pdc/ui";
import Typography from "@components_v2/typography/typography";
import { IconClose } from "@mzc-cloudplex/icons";
import useActionPending from "@hooks/useActionPending";
import CustomMetadataRead from "@features/dialog/custom-metadata/custom-metadata-read";
import CustomMetadataEdit from "@features/dialog/custom-metadata/custom-metadata-edit";

const DialogSelectValidatedCustomMetadata = ({ contentSchemaId, onClickClose, onClickSubmit }) => {
    const { t } = useTranslation();
    const [viewMode, setViewMode] = useState("read");
    const [contentSchemaDetail, setContentSchemaDetail] = useState();

    const {
        pending: isSubmitPending,
        setActionPending: submitPending,
        disableActionPending: disableSubmitPending,
    } = useActionPending("updateCustomMetadata");

    const handleSelectedMetadata = async (customMetadata) => {
        try {
            setContentSchemaDetail(customMetadata);
        } catch (e) {
            console.error(e);
        }
    };

    const onHandleClickSubmit = async () => {
        try {
            submitPending();
            await onClickSubmit(contentSchemaDetail);
            disableSubmitPending();
            onClickClose();
        } catch (e) {
            console.error(e);
        }
    };

    const onChangeFieldValue = (id, value, validationError) => {
        // NOTE: 필수 값 필드에 defaultValue 설정
        setContentSchemaDetail({
            ...contentSchemaDetail,
            fields: contentSchemaDetail?.fields?.map((field) => {
                return {
                    ...field,
                    defaultValue: field.id === id ? value : field.defaultValue,
                    validationError: field.id === id ? validationError : field.validationError,
                };
            }),
        });
    };

    const isValidationError = useMemo(() => {
        return contentSchemaDetail?.fields?.some((field) => !!field.validationError);
    }, [contentSchemaDetail]);

    const isRequiredFields = useMemo(() => {
        return contentSchemaDetail?.fields?.filter((field) => field.isRequired && field.isEditable) ?? [];
    }, [contentSchemaDetail]);

    return (
        <Dialog variant="expandable" onClose={() => onClickClose()} open={true} minwidth={768} maxWidth={"fit-content"}>
            <DialogTitle>
                <Typography variant={"subtitle1_700"}>{t(`common::label::Select Custom Metadata`)}</Typography>
                <IconButton circled onClick={onClickClose}>
                    <IconClose size={16} />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <CustomMetadataRead
                    hidden={viewMode === "edit"}
                    contentSchemaId={contentSchemaId}
                    contentSchemaDetail={contentSchemaDetail}
                    onSelectCustomMetadata={handleSelectedMetadata}
                />
                <CustomMetadataEdit
                    hidden={viewMode === "read"}
                    contentSchemaId={contentSchemaId}
                    contentSchemaName={contentSchemaDetail?.name}
                    isRequiredFields={isRequiredFields}
                    onChangeField={onChangeFieldValue}
                    onChangeViewMode={() => setViewMode("read")}
                />
            </DialogContent>
            <Divider orientation="horizontal" flexItem pt={2} />
            <DialogActions>
                <>
                    <Button variant={`text`} onClick={() => onClickClose()} color={`grey`}>
                        {t(`common::label::Cancel`)}
                    </Button>
                    {viewMode === "read" && isRequiredFields.length > 0 ? (
                        <Button
                            variant={`contained`}
                            onClick={(e) => {
                                e.stopPropagation();
                                setViewMode("edit");
                            }}
                            color={`primary`}
                        >
                            {t(`common::label::${"Enter required values"}`)}
                        </Button>
                    ) : (
                        <Button
                            variant={`contained`}
                            loading={isSubmitPending}
                            disabled={!contentSchemaDetail || isValidationError || isSubmitPending}
                            onClick={(e) => {
                                e.stopPropagation();
                                onHandleClickSubmit();
                            }}
                            color={`primary`}
                        >
                            {viewMode === "read" ? t(`common::label::${"Select"}`) : t(`common::label::${"Save"}`)}
                        </Button>
                    )}
                </>
            </DialogActions>
        </Dialog>
    );
};
export default DialogSelectValidatedCustomMetadata;
