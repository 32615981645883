import {
    Grid as MuiGrid,
    Card as MuiCard,
    styled, alpha
} from "@mui/material";


export const Cover = styled(MuiGrid)(({theme}) => ({
    position: `relative`,
    padding: 0,
}));
export const Content = styled(MuiGrid)(({ theme }) => ({
    flex: `1`,
    display: `flex`,
    flexDirection: `column`,
    width: `100%`,
    padding: `16px 8px`,
    alignItems: `flex-start`,
    justifyContent: `flex-start`,
}));


export const Name = styled(MuiGrid)(({ theme }) => ({
    display: `flex`,
    alignItems: `center`,
    width: `100%`
}));


export const Meta = styled(MuiGrid)(({ theme }) => ({
    display: `flex`,
    alignItems: `center`,
    width: `100%`,
    gap: 4,
    marginTop: `8px`,
    '.MuiTypography-root': {
        marginTop: `4px`,
        lineHeight: `normal`
    }
}));

export const Actions = styled(MuiGrid)(({ theme }) => ({
    position: `absolute`,
    right: 8,
    top: 8,
    width: `auto`,
    padding: `0`,
}));


export const StyledCard = styled(MuiCard, {
    shouldForwardProp: prop => !['variant', 'checked', 'selected'].includes(String(prop)),
})(({ theme, variant, checked, selected }) => ({
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
    backgroundColor: `transparent`,
    border: `none`,
    boxShadow: `none`,
    width: 202,
    '.name': {
        '.MuiTypography-root': {
            maxWidth:` 140px`
        }
    },

    '.btn-preview' :{
        opacity: 0,
    },
    '&:hover': {
        '.btn-preview' :{
            opacity: 1,
        },
    },
    ...(variant === 'card' && {
        position: `relative`,
        flexDirection: `column`,
        '.MuiCheckbox-root': {
          position: `absolute`,
          left: `0`,
          top: `0`,
          zIndex: 1,
        },
        // '> .MuiGrid-root': {
        //     '&:not(.actions) ': {
        //         width: `100%`,
        //         padding: `16px 8px`,
        //         alignItems: `flex-start`,
        //         justifyContent: `flex-start`,
        //     }
        // },
        '.name': {
            '.MuiTypography-root': {
                maxWidth:` 150px`
            }
        },

        ...(checked && {
            '.asset-thumbnail': {
                backgroundColor: alpha(theme.palette.greySecondary.A100, 0.4),
            },
        }),
        ...(selected && {
            '.asset-thumbnail': {
                backgroundColor: alpha(theme.palette.greySecondary.A100, 0.6),
            },
        }),
    })
}));

