import { handleActions, createAction } from "redux-actions";
import fp from "lodash/fp";
import Polling from "../cores/polling";
import AssetApi, {
    createAnalysisJobAPI,
    getAiAnalysisResultsAPI,
    getAiAnalysisAPI,
    getAiCaptionsAPI,
    getAiAnalysisSummariesAPI,
    getDownloadAiAnalysisLinksAPI,
    getAiOriginalResultsAPI,
    getAiOriginalResultAPI,
    copyAssetAPI,
    getAssetLinkedAssetsAPI,
} from "./apis/asset";
import {
    anlayzeAssetAPI,
    attachPeopleToAsset,
    bulkCreateAssetElementsAPI,
    createAssetElementAPI,
    createAssetTagAPI,
    deleteAssetAPI,
    deleteAssetElementAPI,
    deleteAssetTagAPI,
    detachPeopleFromAsset,
    getAssetAnalysisObjectDetailsAPI,
    getAssetAnalysisObjectsAPI,
    getAssetAnalysisObjectsFromSharedAPI,
    getAssetAnalysisStatusAPI,
    getAssetAPI,
    getAssetsAPI,
    getPeopleAPI,
    getRelatedVideosAPI,
    getStatus,
    getUploadUrlAPI,
    putArchiveAPI,
    requestAssetElementsDownloadUrlApi,
    requestAssetsDownloadUrlApi,
    updateAssetAPI,
    updateAssetOwnerAPI,
    updateAssetElementAPI,
    getAssetElementVersionsAPI,
    getAssetRelatedContentsAPI,
} from "./apis/asset";

import "../cores/stringExtension";

import { ANALYSIS_DETAIL_KEY, CONSTANTS } from "../constants";
import { getDetailDriveFolder } from "./folderTreeView";

//action type
const ASSET_DETAIL_PENDING = "ASSET_DETAIL_PENDING";
const ASSET_DETAIL_FAILURE = "ASSET_DETAIL_FAILURE";

const ASSET_DETAIL_UPDATE_STATE = "ASSET_DETAIL_UPDATE_STATE";

const ASSET_DETAIL_IS_CHANGING_DATA = "ASSET_DETAIL_IS_CHANGING_DATA";
const ASSET_DETAIL_GET_ASSET_SUCCESS = "ASSET_DETAIL_GET_ASSET_SUCCESS";

const ASSET_DETAIL_GET_RELATED_ASSET_LIST_PENDING = "ASSET_DETAIL_GET_RELATED_ASSET_LIST_PENDING";
const ASSET_DETAIL_GET_RELATED_ASSET_LIST_SUCCESS = "ASSET_DETAIL_GET_RELATED_ASSET_LIST_SUCCESS";
const ASSET_DETAIL_GET_RELATED_ASSET_LIST_FAILURE = "ASSET_DETAIL_GET_RELATED_ASSET_LIST_FAILURE";
const ASSET_DETAIL_GET_RELATED_VIDEO_LIST_PENDING = "ASSET_DETAIL_GET_RELATED_VIDEO_LIST_PENDING";
const ASSET_DETAIL_GET_RELATED_VIDEO_LIST_SUCCESS = "ASSET_DETAIL_GET_RELATED_VIDEO_LIST_SUCCESS";

const ASSET_DETAIL_UPDATE_ASSET_SUCCESS = "ASSET_DETAIL_UPDATE_ASSET_SUCCESS";
const ASSET_DETAIL_DELETE_ASSET_SUCCESS = "ASSET_DETAIL_DELETE_ASSET_SUCCESS";

const ASSET_DETAIL_DELETE_ASSET_ELEMENT_SUCCESS = "ASSET_DETAIL_DELETE_ASSET_ELEMENT_SUCCESS";
const ASSET_DETAIL_CREATE_ASSET_ELEMENT_SUCCESS = "ASSET_DETAIL_CREATE_ASSET_ELEMENT_SUCCESS";
const ASSET_DETAIL_UPDATE_ASSET_ELEMENT_SUCCESS = "ASSET_DETAIL_UPDATE_ASSET_ELEMENT_SUCCESS";

const ASSET_GET_ANALYSIS_STATUS_SUCCESS = "ASSET_GET_ANALYSIS_STATUS_SUCCESS";
const ASSET_ANALYSIS_STATUS_RESET = "ASSET_ANALYSIS_STATUS_RESET";

const ASSET_START_ANALYSIS_PENDING = "ASSET_START_ANALYSIS_PENDING";
const ASSET_START_ANALYSIS_SUCCESS = "ASSET_START_ANALYSIS_SUCCESS";
const ASSET_START_ANALYSIS_FAILURE = "ASSET_START_ANALYSIS_FAILURE";

const ASSET_GET_ANALYSIS_LABEL_DETECTION_OBJECTS_PENDING = "ASSET_GET_ANALYSIS_LABEL_DETECTION_OBJECTS_PENDING";
const ASSET_GET_ANALYSIS_LABEL_DETECTION_OBJECTS_SUCCESS = "ASSET_GET_ANALYSIS_LABEL_DETECTION_OBJECTS_SUCCESS";
const ASSET_GET_ANALYSIS_LABEL_DETECTION_OBJECTS_FAILURE = "ASSET_GET_ANALYSIS_LABEL_DETECTION_OBJECTS_FAILURE";

const ASSET_GET_ANALYSIS_OBJECT_DETAILS_PENDING = "ASSET_GET_ANALYSIS_OBJECT_DETAILS_PENDING";
const ASSET_GET_ANALYSIS_OBJECT_DETAILS_NEXT = "ASSET_GET_ANALYSIS_OBJECT_DETAILS_NEXT";
const ASSET_GET_ANALYSIS_OBJECT_DETAILS_SUCCESS = "ASSET_GET_ANALYSIS_OBJECT_DETAILS_SUCCESS";
const ASSET_GET_ANALYSIS_OBJECT_DETAILS_FAILURE = "ASSET_GET_ANALYSIS_OBJECT_DETAILS_FAILURE";

const ASSET_GET_ANALYSIS_FACE_SEARCH_OBJECTS_PENDING = "ASSET_GET_ANALYSIS_FACE_SEARCH_OBJECTS_PENDING";
const ASSET_GET_ANALYSIS_FACE_SEARCH_OBJECTS_SUCCESS = "ASSET_GET_ANALYSIS_FACE_SEARCH_OBJECTS_SUCCESS";
const ASSET_GET_ANALYSIS_FACE_SEARCH_OBJECTS_FAILURE = "ASSET_GET_ANALYSIS_FACE_SEARCH_OBJECTS_FAILURE";

const ASSET_GET_ANALYSIS_CELEBRITY_RECOGNITION_OBJECTS_PENDING =
    "ASSET_GET_ANALYSIS_CELEBRITY_RECOGNITION_OBJECTS_PENDING";
const ASSET_GET_ANALYSIS_CELEBRITY_RECOGNITION_OBJECTS_SUCCESS =
    "ASSET_GET_ANALYSIS_CELEBRITY_RECOGNITION_OBJECTS_SUCCESS";
const ASSET_GET_ANALYSIS_CELEBRITY_RECOGNITION_OBJECTS_FAILURE =
    "ASSET_GET_ANALYSIS_CELEBRITY_RECOGNITION_OBJECTS_FAILURE";

const ASSET_GET_ANALYSIS_COTENT_MODERATION_OBJECTS_PENDING = "ASSET_GET_ANALYSIS_COTENT_MODERATION_OBJECTS_PENDING";
const ASSET_GET_ANALYSIS_COTENT_MODERATION_OBJECTS_SUCCESS = "ASSET_GET_ANALYSIS_COTENT_MODERATION_OBJECTS_SUCCESS";
const ASSET_GET_ANALYSIS_COTENT_MODERATION_OBJECTS_FAILURE = "ASSET_GET_ANALYSIS_COTENT_MODERATION_OBJECTS_FAILURE";

const ASSET_CREATE_TAG_PENDING = "ASSET_CREATE_TAG_PENDING";
const ASSET_CREATE_TAG_SUCCESS = "ASSET_CREATE_TAG_SUCCESS";
const ASSET_CREATE_TAG_FAILURE = "ASSET_CREATE_TAG_FAILURE";

const ASSET_DELETE_TAG_PENDING = "ASSET_DELETE_TAG_PENDING";
const ASSET_DELETE_TAG_SUCCESS = "ASSET_DELETE_TAG_SUCCESS";
const ASSET_DELETE_TAG_FAILURE = "ASSET_DELETE_TAG_FAILURE";

const ASSET_ATTACH_PEOPLE_SUCCESS = "ASSET_ATTACH_PEOPLE_SUCCESS";
const ASSET_ATTACH_PEOPLE_FAILURE = "ASSET_ATTACH_PEOPLE_FAILURE";

const ASSET_DETACH_PEOPLE_SUCCESS = "ASSET_DETACH_PEOPLE_SUCCESS";
const ASSET_DETACH_PEOPLE_FAILURE = "ASSET_DETACH_PEOPLE_FAILURE";

const ASSET_DETAIL_ELEMENT_VERSIONS_GET_SUCCESS = "ASSET_DETAIL_ELEMENT_VERSIONS_GET_SUCCESS";
const ASSET_DETAIL_ELEMENT_VERSIONS_GET_FAILURE = "ASSET_DETAIL_ELEMENT_VERSIONS_GET_FAILURE";

const ASSET_DETAIL_RELATED_CONTENT_GET_PENDING = "ASSET_DETAIL_RELATED_CONTENT_GET_PENDING";
const ASSET_DETAIL_RELATED_CONTENT_GET_SUCCESS = "ASSET_DETAIL_RELATED_CONTENT_GET_SUCCESS";
const ASSET_DETAIL_RELATED_CONTENT_GET_FAILURE = "ASSET_DETAIL_RELATED_CONTENT_GET_FAILURE";

const UPDATE_ASSET_STATUS = "UPDATE_ASSET_STATUS";

const ASSET_CELAR = "ASSET_CELAR";

const ASSET_DETAIL_UPDATE_ASSET_OWNER_SUCCESS = "ASSET_DETAIL_UPDATE_ASSET_OWNER_SUCCESS";

const ASSET_DETAIL_RESET_RELATED_ASSETS = "ASSET_DETAIL_RESET_RELATED_ASSETS";

const ASSET_DETAIL_UPDATE_STATUS_FAILURE = "ASSET_DETAIL_UPDATE_STATUS_FAILURE";

const ASSET_ERROR_RESET = "ASSET_ERROR_RESET";

const UPDATE_AUTO_CAPTION_STATUS = "UPDATE_AUTO_CAPTION_STATUS";

const UPDATE_AI_ANALYSIS_STATUS = "UPDATE_AI_ANALYSIS_STATUS";

const UPDATE_AI_ANALYSIS = "UPDATE_AI_ANALYSIS";

// ai analysis
const ASSET_DETAIL_AI_ANALYSIS_GET_PENDING = "ASSET_DETAIL_AI_ANALYSIS_GET_PENDING";

const ASSET_DETAIL_AI_ANALYSIS_RESULTS_GET_PENDING = "ASSET_DETAIL_AI_ANALYSIS_RESULTS_GET_PENDING";
const ASSET_DETAIL_AI_ANALYSIS_RESULTS_GET_SUCCESS = "ASSET_DETAIL_AI_ANALYSIS_RESULTS_GET_SUCCESS";
const ASSET_DETAIL_AI_ANALYSIS_RESULTS_GET_FAILURE = "ASSET_DETAIL_AI_ANALYSIS_RESULTS_GET_FAILURE";

const ASSET_DETAIL_AI_ANALYSIS_SUMMARIES_GET_PENDING = "ASSET_DETAIL_AI_ANALYSIS_SUMMARIES_GET_PENDING";
const ASSET_DETAIL_AI_ANALYSIS_SUMMARIES_GET_SUCCESS = "ASSET_DETAIL_AI_ANALYSIS_SUMMARIES_GET_SUCCESS";
const ASSET_DETAIL_AI_ANALYSIS_SUMMARIES_GET_FAILURE = "ASSET_DETAIL_AI_ANALYSIS_SUMMARIES_GET_FAILURE";

const ASSET_DETAIL_AI_ANALYSIS_RESULTS_DETAIL_GET_SUCCESS = "ASSET_DETAIL_AI_ANALYSIS_RESULTS_DETAIL_GET_SUCCESS";
const ASSET_DETAIL_AI_ANALYSIS_RESULTS_ORDER_DETAIL_GET_SUCCESS =
    "ASSET_DETAIL_AI_ANALYSIS_RESULTS_ORDER_DETAIL_GET_SUCCESS";

const ASSET_DETAIL_AI_ORIGINAL_RESULTS_GET_SUCCESS = "ASSET_DETAIL_AI_ORIGINAL_RESULTS_GET_SUCCESS";
const ASSET_DETAIL_AI_ORIGINAL_RESULTS_GET_FAILURE = "ASSET_DETAIL_AI_ORIGINAL_RESULTS_GET_FAILURE";
const ASSET_DETAIL_AI_ORIGINAL_RESULTS_GET_PENDING = "ASSET_DETAIL_AI_ORIGINAL_RESULTS_GET_PENDING";

const ASSET_DETAIL_AI_ANALYSIS_UPDATE = "ASSET_DETAIL_AI_ANALYSIS_UPDATE";
const ASSET_DETAIL_RESET_AI_ANALYSIS = "ASSET_DETAIL_RESET_AI_ANALYSIS";

const ASSET_DETAIL_UPDATE_METADATA = "ASSET_DETAIL_UPDATE_METADATA";

const ASSET_DETAIL_GET_LINKED_ASSETS_PENDING = "ASSET_DETAIL_GET_LINKED_ASSETS_PENDING";
const ASSET_DETAIL_GET_LINKED_ASSETS_SUCCESS = "ASSET_DETAIL_GET_LINKED_ASSETS_SUCCESS";
const ASSET_DETAIL_GET_LINKED_ASSETS_FAILURE = "ASSET_DETAIL_GET_LINKED_ASSETS_FAILURE";

//reducer
const initialState = {
    data: null,
    pending: false,
    error: false,
    isDeleted: false,
    isChangingData: false,
    contextVersion: 0,
    relatedAssets: {
        pending: false,
        data: null,
        autoCaptionPending: false,
    },
    //TODO relatedContetns가 생겼으니 없어져도 될 거 같은데...
    relatedVideos: {
        pending: false,
        data: null,
    },
    relatedContents: {
        pending: false,
        data: null,
    },
    analysisDetails: {
        labelDetection: {},
        faceSearch: {},
        contentModeration: {},
        celebrityRecognition: {},
    },
    analysis: {
        pending: false,
        error: false,
        data: null,

        labelDetection: {
            pending: false,
            error: false,
            data: null,
        },
        faceSearch: {
            pending: false,
            error: false,
            data: null,
        },
        celebrityRecognition: {
            pending: false,
            error: false,
            data: null,
        },
        contentModeration: {
            pending: false,
            error: false,
            data: null,
        },
    },
    ai: {
        analysis: {
            pending: null,
            error: false,
            data: null,
        },
        summaries: {
            pending: false,
            error: false,
            data: null,
        },
        results: {
            pending: false,
            error: false,
            data: null,
        },
        originals: {
            pending: false,
            error: false,
            data: null,
        },
        downloadLinks: null,
    },
    aiDetail: null,
    asset_editable: true,
    resourceToken: null,
    folderPath: null,
    linkedAssets: {
        pending: false,
        data: null,
        error: null,
    },
};

export default handleActions(
    {
        [ASSET_CELAR]: (state) => {
            return {
                ...initialState,
                contextVersion: state.contextVersion + 1,
            };
        },
        [ASSET_DETAIL_PENDING]: (state) => {
            return {
                ...state,
                contextVersion: state.contextVersion + 1,
                pending: true,
                error: false,
            };
        },
        [ASSET_DETAIL_FAILURE]: (state) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [ASSET_ATTACH_PEOPLE_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            return {
                ...state,
                data: {
                    ...state.data,
                    people: (state.data.people || []).concat([data]),
                },
            };
        },
        [ASSET_DETACH_PEOPLE_SUCCESS]: (state, action) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    people: (state.data.people || []).filter((p) => p.id !== action.payload.id),
                },
            };
        },
        [ASSET_ATTACH_PEOPLE_FAILURE]: (state) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [ASSET_DETACH_PEOPLE_FAILURE]: (state) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [ASSET_DETAIL_IS_CHANGING_DATA]: (state) => {
            return {
                ...state,
                contextVersion: state.contextVersion + 1,
                isChangingData: true,
            };
        },
        [ASSET_DETAIL_UPDATE_STATE]: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
        [ASSET_DETAIL_GET_ASSET_SUCCESS]: (state, action) => {
            const { data, headers } = action.payload;
            const resourceToken = headers["x-mzc-cp-resource-token"] || null;
            const asset_editable = headers["x-mzc-cp-resource-permission"] === "VIEWER" ? false : true;
            // console.log('check resourceToken:', resourceToken)
            // console.log('check asset_editable:', asset_editable)
            return {
                ...state,
                pending: false,
                error: false,
                data,
                resourceToken,
                asset_editable,
            };
        },
        [ASSET_DETAIL_DELETE_ASSET_SUCCESS]: (state, action) => {
            return {
                ...state,
                isDeleted: true,
                isChangingData: false,
            };
        },
        [ASSET_DETAIL_DELETE_ASSET_ELEMENT_SUCCESS]: (state, action) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    elements: state.data.elements.filter((e) => e.id !== action.payload),
                },
                isChangingData: false,
            };
        },
        [ASSET_DETAIL_CREATE_ASSET_ELEMENT_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            return {
                ...state,
                data: {
                    ...state.data,
                    elements: state && state.data && state.data.elements ? state.data.elements.concat([data]) : [data],
                },
            };
        },
        [ASSET_DETAIL_UPDATE_ASSET_ELEMENT_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                data: {
                    ...state.data,
                    elements: state.data.elements.map((ele) => {
                        if (ele.id === data.id) {
                            return data;
                        }

                        if (data.isPreferred === true) {
                            ele.isPreferred = false;
                        }

                        return ele;
                    }),
                },
            };
        },
        [ASSET_DETAIL_UPDATE_ASSET_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            return {
                ...state,
                data,
                pending: false,
                error: false,
                isChangingData: false,
                analysis: {
                    ...state.analysis,
                    labelDetection: {
                        ...state.analysis.labelDetection,
                        data: {
                            ...state.analysis.labelDetection.data,
                            objects:
                                state.analysis.labelDetection.data &&
                                state.analysis.labelDetection.data.objects &&
                                state.analysis.labelDetection.data.objects.map((v) => {
                                    return {
                                        ...v,
                                        isExistedTag:
                                            data.tags.filter((t) => t.value === v.name).length > 0 ? true : false,
                                    };
                                }),
                        },
                    },
                    celebrityRecognition: {
                        ...state.analysis.celebrityRecognition,
                        data: {
                            ...state.analysis.celebrityRecognition.data,
                            objects:
                                state.analysis.celebrityRecognition.data &&
                                state.analysis.celebrityRecognition.data.objects &&
                                state.analysis.celebrityRecognition.data.objects.map((v) => {
                                    return {
                                        ...v,
                                        isExistedTag:
                                            data.tags.filter((t) => t.value === v.name).length > 0 ? true : false,
                                    };
                                }),
                        },
                    },
                    faceSearch: {
                        ...state.analysis.faceSearch,
                        data: {
                            ...state.analysis.faceSearch.data,
                            objects:
                                state.analysis.faceSearch.data &&
                                state.analysis.faceSearch.data.objects &&
                                state.analysis.faceSearch.data.objects.map((v) => {
                                    return {
                                        ...v,
                                        isExistedTag:
                                            data.tags.filter((t) => t.value === v.name).length > 0 ? true : false,
                                    };
                                }),
                        },
                    },
                    contentModeration: {
                        ...state.analysis.contentModeration,
                        data: {
                            ...state.analysis.contentModeration.data,
                            objects:
                                state.analysis.contentModeration.data &&
                                state.analysis.contentModeration.data.objects &&
                                state.analysis.contentModeration.data.objects.map((v) => {
                                    return {
                                        ...v,
                                        isExistedTag:
                                            data.tags.filter((t) => t.value === v.name).length > 0 ? true : false,
                                    };
                                }),
                        },
                    },
                },
            };
        },
        [UPDATE_ASSET_STATUS]: (state, action) => {
            const { data } = action.payload;
            return {
                ...state,
                data: {
                    ...state.data,
                    status: data.status,
                },
            };
        },
        [ASSET_DETAIL_GET_RELATED_ASSET_LIST_PENDING]: (state) => {
            return {
                ...state,
                relatedAssets: {
                    ...state.relatedAssets,
                    pending: true,
                },
            };
        },
        [ASSET_DETAIL_GET_RELATED_ASSET_LIST_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                relatedAssets: {
                    ...state.relatedAssets,
                    pending: false,
                    data: data.assets && data.assets.filter((a) => a.id !== state.data.id),
                },
            };
        },
        [ASSET_DETAIL_GET_RELATED_ASSET_LIST_FAILURE]: (state, action) => {
            const { data } = action.payload;
            return {
                ...state,
                relatedAssets: {
                    ...state.relatedAssets,
                    pending: false,
                    error: true,
                },
            };
        },
        [ASSET_DETAIL_GET_RELATED_VIDEO_LIST_PENDING]: (state) => {
            return {
                ...state,
                relatedVideos: {
                    ...state.relatedVideos,
                    pending: true,
                },
            };
        },
        [ASSET_DETAIL_GET_RELATED_VIDEO_LIST_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                relatedVideos: {
                    pending: false,
                    data: data.videos,
                },
            };
        },
        [ASSET_ANALYSIS_STATUS_RESET]: (state, action) => {
            return {
                ...state,
                analysis: {
                    // ...state.analysis,
                    pending: false,
                    error: false,
                    data: null,
                    labelDetection: {
                        pending: false,
                        error: false,
                        data: null,
                    },
                    faceSearch: {
                        pending: false,
                        error: false,
                        data: null,
                    },
                    celebrityRecognition: {
                        pending: false,
                        error: false,
                        data: null,
                    },
                    contentModeration: {
                        pending: false,
                        error: false,
                        data: null,
                    },
                },
            };
        },
        [ASSET_GET_ANALYSIS_STATUS_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                analysis: {
                    ...state.analysis,
                    data: data,
                },
            };
        },
        [ASSET_START_ANALYSIS_PENDING]: (state) => {
            return {
                ...state,
                analysis: {
                    ...state.analysis,
                    pending: true,
                    error: false,
                },
            };
        },
        [ASSET_START_ANALYSIS_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const dataType = data.map((d) => d.type);

            let data_;
            if (fp.get("analysis.data", state))
                data_ = fp.get("analysis.data", state).filter((d) => !dataType.includes(d.type));
            data_ = data_.concat(data);

            return {
                ...state,
                analysis: {
                    ...state.analysis,
                    pending: false,
                    error: false,
                    data: data_,
                },
            };
        },
        [ASSET_START_ANALYSIS_FAILURE]: (state) => {
            return {
                ...state,
                analysis: {
                    ...state.analysis,
                    pending: false,
                    error: true,
                },
            };
        },
        [ASSET_GET_ANALYSIS_LABEL_DETECTION_OBJECTS_PENDING]: (state) => {
            return {
                ...state,
                analysis: {
                    ...state.analysis,
                    labelDetection: {
                        ...state.analysis.labelDetection,
                        pending: true,
                        error: false,
                    },
                },
            };
        },
        [ASSET_GET_ANALYSIS_LABEL_DETECTION_OBJECTS_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            const tmp = state.analysis.labelDetection;
            let objects = data.objects;

            if (tmp.data && tmp.data.objects && data.objects) {
                objects = tmp.data.objects.concat(objects);
            }

            return {
                ...state,
                analysis: {
                    ...state.analysis,
                    labelDetection: {
                        ...state.analysis.labelDetection,
                        pending: false,
                        error: false,
                        ready: true,
                        data: {
                            ...state.analysis.labelDetection.data,
                            objects,
                            totalCount: data.totalCount,
                        },
                    },
                },
            };
        },
        [ASSET_GET_ANALYSIS_LABEL_DETECTION_OBJECTS_FAILURE]: (state) => {
            return {
                ...state,
                analysis: {
                    ...state.analysis,
                    labelDetection: {
                        ...state.analysis.labelDetection,
                        pending: false,
                        error: true,
                    },
                },
            };
        },
        [ASSET_GET_ANALYSIS_OBJECT_DETAILS_PENDING]: (state, action) => {
            const { value, analysisType, groupBy } = action.payload;

            return {
                ...state,
                analysisDetails: {
                    ...state.analysisDetails,
                    [analysisType]: {
                        ...state.analysisDetails[analysisType],
                        [value]: {
                            groupBy,
                            pending: true,
                            error: false,
                            totalCount: 0,
                            data: [],
                        },
                    },
                },
            };
        },
        [ASSET_GET_ANALYSIS_OBJECT_DETAILS_NEXT]: (state, action) => {
            const { analysisType, value, nextToken, data } = action.payload;

            const detail = state.analysisDetails[analysisType][value];
            detail.totalCount += data.count;
            detail.data = detail.data.concat(data.details);
            detail.nextToken = nextToken;

            return {
                ...state,
                analysisDetails: {
                    ...state.analysisDetails,
                    [analysisType]: {
                        ...state.analysisDetails[analysisType],
                        [value]: {
                            ...detail,
                        },
                    },
                },
            };
        },
        [ASSET_GET_ANALYSIS_OBJECT_DETAILS_SUCCESS]: (state, action) => {
            const { analysisType, value, data } = action.payload;
            const detail = state.analysisDetails[analysisType][value];
            detail.pending = false;
            detail.totalCount += data.count;
            detail.data = detail.data.concat(data.details);
            delete detail.nextToken;
            // console.log("success", data);
            if (data.key === "peopleId") detail.label = data.name;
            else detail.label = data.key;

            return {
                ...state,
                analysisDetails: {
                    ...state.analysisDetails,
                    [analysisType]: {
                        ...state.analysisDetails[analysisType],
                        [value]: {
                            ...detail,
                        },
                    },
                },
            };
        },
        [ASSET_GET_ANALYSIS_OBJECT_DETAILS_FAILURE]: (state, action) => {
            const { analysisType, value } = action.payload;
            const detail = state.analysisDetails[analysisType][value] || {};
            detail.pending = false;
            detail.error = true;

            return {
                ...state,
                analysisDetails: {
                    ...state.analysisDetails,
                    [analysisType]: {
                        ...state.analysisDetails[analysisType],
                        [value]: {
                            ...detail,
                        },
                    },
                },
            };
        },
        [ASSET_GET_ANALYSIS_FACE_SEARCH_OBJECTS_PENDING]: (state) => {
            return {
                ...state,
                analysis: {
                    ...state.analysis,
                    faceSearch: {
                        ...state.analysis.faceSearch,
                        pending: true,
                        error: false,
                    },
                },
            };
        },
        [ASSET_GET_ANALYSIS_FACE_SEARCH_OBJECTS_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            const tmp = state.analysis.faceSearch;
            let objects = data.objects;

            if (tmp.data && tmp.data.objects && data.objects) {
                objects = tmp.data.objects.concat(objects);
            }
            return {
                ...state,
                analysis: {
                    ...state.analysis,
                    faceSearch: {
                        ...state.analysis.faceSearch,
                        pending: false,
                        error: false,
                        ready: true,
                        data: {
                            ...state.analysis.faceSearch.data,
                            objects,
                            totalCount: data.totalCount,
                        },
                    },
                },
            };
        },
        [ASSET_GET_ANALYSIS_FACE_SEARCH_OBJECTS_FAILURE]: (state) => {
            return {
                ...state,
                analysis: {
                    ...state.analysis,
                    faceSearch: {
                        ...state.analysis.faceSearch,
                        pending: false,
                        error: true,
                    },
                },
            };
        },
        [ASSET_GET_ANALYSIS_CELEBRITY_RECOGNITION_OBJECTS_PENDING]: (state) => {
            return {
                ...state,
                analysis: {
                    ...state.analysis,
                    celebrityRecognition: {
                        ...state.analysis.celebrityRecognition,
                        pending: true,
                        error: false,
                    },
                },
            };
        },
        [ASSET_GET_ANALYSIS_CELEBRITY_RECOGNITION_OBJECTS_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            const tmp = state.analysis.celebrityRecognition;
            let objects = data.objects;

            if (tmp.data && tmp.data.objects && data.objects) {
                objects = tmp.data.objects.concat(objects);
            }

            return {
                ...state,
                analysis: {
                    ...state.analysis,
                    celebrityRecognition: {
                        ...state.analysis.celebrityRecognition,
                        pending: false,
                        error: false,
                        ready: true,
                        data: {
                            ...state.analysis.celebrityRecognition.data,
                            objects,
                            totalCount: data.totalCount,
                        },
                    },
                },
            };
        },
        [ASSET_GET_ANALYSIS_CELEBRITY_RECOGNITION_OBJECTS_FAILURE]: (state) => {
            return {
                ...state,
                analysis: {
                    ...state.analysis,
                    celebrityRecognition: {
                        ...state.analysis.celebrityRecognition,
                        pending: false,
                        error: true,
                    },
                },
            };
        },
        [ASSET_GET_ANALYSIS_COTENT_MODERATION_OBJECTS_PENDING]: (state) => {
            return {
                ...state,
                analysis: {
                    ...state.analysis,
                    contentModeration: {
                        ...state.analysis.contentModeration,
                        pending: true,
                        error: false,
                    },
                },
            };
        },
        [ASSET_GET_ANALYSIS_COTENT_MODERATION_OBJECTS_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            const tmp = state.analysis.contentModeration;
            let objects = data.objects;

            if (tmp.data && tmp.data.objects && data.objects) {
                objects = tmp.data.objects.concat(objects);
            }

            return {
                ...state,
                analysis: {
                    ...state.analysis,
                    contentModeration: {
                        ...state.analysis.contentModeration,
                        pending: false,
                        error: false,
                        ready: true,
                        data: {
                            ...state.analysis.contentModeration.data,
                            objects,
                            totalCount: data.totalCount,
                        },
                    },
                },
            };
        },
        [ASSET_GET_ANALYSIS_COTENT_MODERATION_OBJECTS_FAILURE]: (state) => {
            return {
                ...state,
                analysis: {
                    ...state.analysis,
                    contentModeration: {
                        ...state.analysis.contentModeration,
                        pending: false,
                        error: true,
                    },
                },
            };
        },
        [ASSET_CREATE_TAG_PENDING]: (state, action) => {
            const value = action.value;

            return {
                ...state,
                analysis: {
                    ...state.analysis,
                    labelDetection: {
                        ...state.analysis.labelDetection,
                        data: {
                            ...state.analysis.labelDetection.data,
                            objects:
                                state.analysis.labelDetection.data &&
                                state.analysis.labelDetection.data.objects &&
                                state.analysis.labelDetection.data.objects.map((v) => {
                                    if (v.name === value) {
                                        return {
                                            ...v,
                                            pending: true,
                                        };
                                    }
                                    return v;
                                }),
                        },
                    },
                    celebrityRecognition: {
                        ...state.analysis.celebrityRecognition,
                        data: {
                            ...state.analysis.celebrityRecognition.data,
                            objects:
                                state.analysis.celebrityRecognition.data &&
                                state.analysis.celebrityRecognition.data.objects &&
                                state.analysis.celebrityRecognition.data.objects.map((v) => {
                                    if (v.name === value) {
                                        return {
                                            ...v,
                                            pending: true,
                                        };
                                    }
                                    return v;
                                }),
                        },
                    },
                    faceSearch: {
                        ...state.analysis.faceSearch,
                        data: {
                            ...state.analysis.faceSearch.data,
                            objects:
                                state.analysis.faceSearch.data &&
                                state.analysis.faceSearch.data.objects &&
                                state.analysis.faceSearch.data.objects.map((v) => {
                                    if (v.name === value) {
                                        return {
                                            ...v,
                                            pending: true,
                                        };
                                    }
                                    return v;
                                }),
                        },
                    },
                    contentModeration: {
                        ...state.analysis.contentModeration,
                        data: {
                            ...state.analysis.contentModeration.data,
                            objects:
                                state.analysis.contentModeration.data &&
                                state.analysis.contentModeration.data.objects &&
                                state.analysis.contentModeration.data.objects.map((v) => {
                                    if (v.name === value) {
                                        return {
                                            ...v,
                                            pending: true,
                                        };
                                    }
                                    return v;
                                }),
                        },
                    },
                },
            };
        },
        [ASSET_CREATE_TAG_SUCCESS]: (state, action) => {
            const value = action.value;
            let tags;
            if (state.data && state.data.tags) {
                tags = state.data.tags;
                tags.push({ value });
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    tags,
                },
                analysis: {
                    ...state.analysis,
                    labelDetection: {
                        ...state.analysis.labelDetection,
                        data: {
                            ...state.analysis.labelDetection.data,
                            objects:
                                state.analysis.labelDetection.data &&
                                state.analysis.labelDetection.data.objects &&
                                state.analysis.labelDetection.data.objects.map((v) => {
                                    if (v.name === value) {
                                        return {
                                            ...v,
                                            isExistedTag: true,
                                            pending: false,
                                        };
                                    }
                                    return v;
                                }),
                        },
                    },
                    celebrityRecognition: {
                        ...state.analysis.celebrityRecognition,
                        data: {
                            ...state.analysis.celebrityRecognition.data,
                            objects:
                                state.analysis.celebrityRecognition.data &&
                                state.analysis.celebrityRecognition.data.objects &&
                                state.analysis.celebrityRecognition.data.objects.map((v) => {
                                    if (v.name === value) {
                                        return {
                                            ...v,
                                            isExistedTag: true,
                                            pending: false,
                                        };
                                    }
                                    return v;
                                }),
                        },
                    },
                    faceSearch: {
                        ...state.analysis.faceSearch,
                        data: {
                            ...state.analysis.faceSearch.data,
                            objects:
                                state.analysis.faceSearch.data &&
                                state.analysis.faceSearch.data.objects &&
                                state.analysis.faceSearch.data.objects.map((v) => {
                                    if (v.name === value) {
                                        return {
                                            ...v,
                                            isExistedTag: true,
                                            pending: false,
                                        };
                                    }
                                    return v;
                                }),
                        },
                    },
                    contentModeration: {
                        ...state.analysis.contentModeration,
                        data: {
                            ...state.analysis.contentModeration.data,
                            objects:
                                state.analysis.contentModeration.data &&
                                state.analysis.contentModeration.data.objects &&
                                state.analysis.contentModeration.data.objects.map((v) => {
                                    if (v.name === value) {
                                        return {
                                            ...v,
                                            isExistedTag: true,
                                            pending: false,
                                        };
                                    }
                                    return v;
                                }),
                        },
                    },
                },
            };
        },
        [ASSET_CREATE_TAG_FAILURE]: (state, action) => {
            const value = action.value;

            return {
                ...state,
                analysis: {
                    ...state.analysis,
                    labelDetection: {
                        ...state.analysis.labelDetection,
                        data: {
                            ...state.analysis.labelDetection.data,
                            objects:
                                state.analysis.labelDetection.data &&
                                state.analysis.labelDetection.data.objects &&
                                state.analysis.labelDetection.data.objects.map((v) => {
                                    if (v.name === value) {
                                        return {
                                            ...v,
                                            pending: false,
                                        };
                                    }
                                    return v;
                                }),
                        },
                    },
                    celebrityRecognition: {
                        ...state.analysis.celebrityRecognition,
                        data: {
                            ...state.analysis.celebrityRecognition.data,
                            objects:
                                state.analysis.celebrityRecognition.data &&
                                state.analysis.celebrityRecognition.data.objects &&
                                state.analysis.celebrityRecognition.data.objects.map((v) => {
                                    if (v.name === value) {
                                        return {
                                            ...v,
                                            pending: false,
                                        };
                                    }
                                    return v;
                                }),
                        },
                    },
                    faceSearch: {
                        ...state.analysis.faceSearch,
                        data: {
                            ...state.analysis.faceSearch.data,
                            objects:
                                state.analysis.faceSearch.data &&
                                state.analysis.faceSearch.data.objects &&
                                state.analysis.faceSearch.data.objects.map((v) => {
                                    if (v.name === value) {
                                        return {
                                            ...v,
                                            pending: false,
                                        };
                                    }
                                    return v;
                                }),
                        },
                    },
                    contentModeration: {
                        ...state.analysis.contentModeration,
                        data: {
                            ...state.analysis.contentModeration.data,
                            objects:
                                state.analysis.contentModeration.data &&
                                state.analysis.contentModeration.data.objects &&
                                state.analysis.contentModeration.data.objects.map((v) => {
                                    if (v.name === value) {
                                        return {
                                            ...v,
                                            pending: false,
                                        };
                                    }
                                    return v;
                                }),
                        },
                    },
                },
            };
        },
        [ASSET_DELETE_TAG_PENDING]: (state, action) => {
            const value = action.value;

            return {
                ...state,
                analysis: {
                    ...state.analysis,
                    labelDetection: {
                        ...state.analysis.labelDetection,
                        data: {
                            ...state.analysis.labelDetection.data,
                            objects:
                                state.analysis.labelDetection.data &&
                                state.analysis.labelDetection.data.objects &&
                                state.analysis.labelDetection.data.objects.map((v) => {
                                    if (v.name === value) {
                                        return {
                                            ...v,
                                            pending: true,
                                        };
                                    }
                                    return v;
                                }),
                        },
                    },
                    celebrityRecognition: {
                        ...state.analysis.celebrityRecognition,
                        data: {
                            ...state.analysis.celebrityRecognition.data,
                            objects:
                                state.analysis.celebrityRecognition.data &&
                                state.analysis.celebrityRecognition.data.objects &&
                                state.analysis.celebrityRecognition.data.objects.map((v) => {
                                    if (v.name === value) {
                                        return {
                                            ...v,
                                            pending: true,
                                        };
                                    }
                                    return v;
                                }),
                        },
                    },
                    faceSearch: {
                        ...state.analysis.faceSearch,
                        data: {
                            ...state.analysis.faceSearch.data,
                            objects:
                                state.analysis.faceSearch.data &&
                                state.analysis.faceSearch.data.objects &&
                                state.analysis.faceSearch.data.objects.map((v) => {
                                    if (v.name === value) {
                                        return {
                                            ...v,
                                            pending: true,
                                        };
                                    }
                                    return v;
                                }),
                        },
                    },
                    contentModeration: {
                        ...state.analysis.contentModeration,
                        data: {
                            ...state.analysis.contentModeration.data,
                            objects:
                                state.analysis.contentModeration.data &&
                                state.analysis.contentModeration.data.objects &&
                                state.analysis.contentModeration.data.objects.map((v) => {
                                    if (v.name === value) {
                                        return {
                                            ...v,
                                            pending: true,
                                        };
                                    }
                                    return v;
                                }),
                        },
                    },
                },
            };
        },
        [ASSET_DELETE_TAG_SUCCESS]: (state, action) => {
            const value = action.value;

            let tags;
            if (state.data && state.data.tags) {
                tags = state.data.tags.filter((v) => v.value !== value);
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    tags,
                },
                analysis: {
                    ...state.analysis,
                    labelDetection: {
                        ...state.analysis.labelDetection,
                        data: {
                            ...state.analysis.labelDetection.data,
                            objects:
                                state.analysis.labelDetection.data &&
                                state.analysis.labelDetection.data.objects &&
                                state.analysis.labelDetection.data.objects.map((v) => {
                                    if (v.name === value) {
                                        return {
                                            ...v,
                                            isExistedTag: false,
                                            pending: false,
                                        };
                                    }
                                    return v;
                                }),
                        },
                    },
                    celebrityRecognition: {
                        ...state.analysis.celebrityRecognition,
                        data: {
                            ...state.analysis.celebrityRecognition.data,
                            objects:
                                state.analysis.celebrityRecognition.data &&
                                state.analysis.celebrityRecognition.data.objects &&
                                state.analysis.celebrityRecognition.data.objects.map((v) => {
                                    if (v.name === value) {
                                        return {
                                            ...v,
                                            isExistedTag: false,
                                            pending: false,
                                        };
                                    }
                                    return v;
                                }),
                        },
                    },
                    faceSearch: {
                        ...state.analysis.faceSearch,
                        data: {
                            ...state.analysis.faceSearch.data,
                            objects:
                                state.analysis.faceSearch.data &&
                                state.analysis.faceSearch.data.objects &&
                                state.analysis.faceSearch.data.objects.map((v) => {
                                    if (v.name === value) {
                                        return {
                                            ...v,
                                            isExistedTag: false,
                                            pending: false,
                                        };
                                    }
                                    return v;
                                }),
                        },
                    },
                    contentModeration: {
                        ...state.analysis.contentModeration,
                        data: {
                            ...state.analysis.contentModeration.data,
                            objects:
                                state.analysis.contentModeration.data &&
                                state.analysis.contentModeration.data.objects &&
                                state.analysis.contentModeration.data.objects.map((v) => {
                                    if (v.name === value) {
                                        return {
                                            ...v,
                                            isExistedTag: false,
                                            pending: false,
                                        };
                                    }
                                    return v;
                                }),
                        },
                    },
                },
            };
        },
        [ASSET_DELETE_TAG_FAILURE]: (state, action) => {
            const value = action.value;

            return {
                ...state,
                analysis: {
                    ...state.analysis,
                    labelDetection: {
                        ...state.analysis.labelDetection,
                        data: {
                            ...state.analysis.labelDetection.data,
                            objects:
                                state.analysis.labelDetection.data &&
                                state.analysis.labelDetection.data.objects &&
                                state.analysis.labelDetection.data.objects.map((v) => {
                                    if (v.name === value) {
                                        return {
                                            ...v,
                                            pending: false,
                                        };
                                    }
                                    return v;
                                }),
                        },
                    },
                    celebrityRecognition: {
                        ...state.analysis.celebrityRecognition,
                        data: {
                            ...state.analysis.celebrityRecognition.data,
                            objects:
                                state.analysis.celebrityRecognition.data &&
                                state.analysis.celebrityRecognition.data.objects &&
                                state.analysis.celebrityRecognition.data.objects.map((v) => {
                                    if (v.name === value) {
                                        return {
                                            ...v,
                                            pending: false,
                                        };
                                    }
                                    return v;
                                }),
                        },
                    },
                    faceSearch: {
                        ...state.analysis.faceSearch,
                        data: {
                            ...state.analysis.faceSearch.data,
                            objects:
                                state.analysis.faceSearch.data &&
                                state.analysis.faceSearch.data.objects &&
                                state.analysis.faceSearch.data.objects.map((v) => {
                                    if (v.name === value) {
                                        return {
                                            ...v,
                                            pending: false,
                                        };
                                    }
                                    return v;
                                }),
                        },
                    },
                    contentModeration: {
                        ...state.analysis.contentModeration,
                        data: {
                            ...state.analysis.contentModeration.data,
                            objects:
                                state.analysis.contentModeration.data &&
                                state.analysis.contentModeration.data.objects &&
                                state.analysis.contentModeration.data.objects.map((v) => {
                                    if (v.name === value) {
                                        return {
                                            ...v,
                                            pending: false,
                                        };
                                    }
                                    return v;
                                }),
                        },
                    },
                },
            };
        },
        [ASSET_DETAIL_ELEMENT_VERSIONS_GET_SUCCESS]: (state, action) => {
            const { elementId, data } = action.payload;
            // console.log("ASSET_DETAIL_ELEMENT_VERSIONS_GET_SUCCESS state: ", state);
            // console.log("ASSET_DETAIL_ELEMENT_VERSIONS_GET_SUCCESS elementId: ", elementId, " data: ", data);

            const newData = state.data.elements.map((e) => {
                if (e.id === elementId) {
                    const versionId = data.versions[data.versions.length - 1].id;
                    e.versionId = versionId;
                }

                return e;
            });

            // console.log("ASSET_DETAIL_ELEMENT_VERSIONS_GET_SUCCESS newData: ", newData);

            return {
                ...state,
                pending: false,
                error: false,
                data: {
                    ...state.data,
                },
            };
        },
        [ASSET_DETAIL_UPDATE_ASSET_OWNER_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            return {
                ...state,
                data: {
                    ...state.data,
                    version: data.version,
                    createdBy: data.createdBy,
                },
                isChangingData: false,
            };
        },
        [ASSET_DETAIL_RESET_RELATED_ASSETS]: (state, action) => {
            return {
                ...state,
                relatedAssets: {
                    pending: false,
                    error: false,
                    data: null,
                    autoCaptionPending: false,
                },
            };
        },
        [ASSET_DETAIL_RELATED_CONTENT_GET_PENDING]: (state, action) => {
            // console.log("ASSET_DETAIL_RELATED_CONTENT_GET_PENDING");
            return {
                ...state,
                relatedContents: {
                    ...state.relatedContents,
                    pending: true,
                    error: false,
                },
            };
        },
        [ASSET_DETAIL_RELATED_CONTENT_GET_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            // console.log("ASSET_DETAIL_RELATED_CONTENT_GET_SUCCESS data: ", data);

            return {
                ...state,
                relatedContents: {
                    ...state.relatedContents,
                    data: data,
                    error: false,
                    pending: false,
                },
            };
        },
        [ASSET_DETAIL_RELATED_CONTENT_GET_FAILURE]: (state, action) => {
            const error = action.payload;
            // console.log("ASSET_DETAIL_RELATED_CONTENT_GET_FAILURE error: ", error);

            return {
                ...state,
                error: true,
                pending: false,
            };
        },
        [ASSET_DETAIL_UPDATE_STATUS_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: true,
                errorDetail: action.payload,
            };
        },
        [ASSET_ERROR_RESET]: (state) => {
            return {
                ...state,
                error: false,
                errorDetail: null,
            };
        },
        [UPDATE_AUTO_CAPTION_STATUS]: (state, action) => {
            return {
                ...state,
                relatedAssets: {
                    ...state.relatedAssets,
                    autoCaptionPending: action.payload,
                },
            };
        },

        [UPDATE_AI_ANALYSIS]: (state, action) => {
            return {
                ...state,
                ai: {
                    ...state.ai,
                    analysis: {
                        ...state.ai.analysis,
                        data: action.payload,
                    },
                },
            };
        },
        [ASSET_DETAIL_AI_ANALYSIS_RESULTS_GET_PENDING]: (state, action) => {
            return {
                ...state,
                ai: {
                    ...state.ai,
                    results: {
                        ...state.ai.results,
                        pending: true,
                    },
                },
            };
        },

        [ASSET_DETAIL_AI_ANALYSIS_RESULTS_GET_SUCCESS]: (state, action) => {
            const engine = state.ai.analysis?.data?.find((el) => el.id === action.payload.analysisId)?.engine;

            return {
                ...state,
                ai: {
                    ...state.ai,
                    results: {
                        ...state.ai.results,
                        pending: false,
                        data: { ...state.ai.results.data, [engine]: action.payload.data },
                    },
                },
            };
        },
        [ASSET_DETAIL_AI_ANALYSIS_RESULTS_GET_FAILURE]: (state, action) => {
            return {
                ...state,
                ai: {
                    ...state.ai,
                    results: {
                        ...state.ai.results,
                        pending: false,
                        error: true,
                    },
                },
            };
        },
        [ASSET_DETAIL_AI_ANALYSIS_SUMMARIES_GET_PENDING]: (state, action) => {
            return {
                ...state,
                ai: {
                    ...state.ai,
                    summaries: {
                        ...state.ai.summaries,
                        pending: true,
                    },
                },
            };
        },
        [ASSET_DETAIL_AI_ANALYSIS_SUMMARIES_GET_SUCCESS]: (state, action) => {
            const { data, analysisId } = action.payload;

            const isFaceSearch =
                state.ai.analysis.data.find((el) => el.id === analysisId)?.engine === "AWS_REKOGNITION_FACE_SEARCH";

            const newResults = isFaceSearch
                ? data?.results.map((el) => ({ ...el, value: el.people?.[0]?.name, originValue: el.value }))
                : data.results;

            const engine = state.ai.analysis?.data?.find((el) => el.id === analysisId)?.engine;
            const summaries = !!state.ai.summaries.data?.[engine] ? { ...state.ai.summaries.data?.[engine] } : {};
            const summariesObj = !!summaries?.results
                ? { results: [...summaries.results, ...newResults], nextToken: data.nextToken }
                : { ...data, results: newResults };

            return {
                ...state,
                ai: {
                    ...state.ai,
                    summaries: {
                        ...state.ai.summaries,
                        pending: false,
                        data: { ...state.ai.summaries.data, [engine]: summariesObj },
                    },
                },
            };
        },
        [ASSET_DETAIL_AI_ANALYSIS_SUMMARIES_GET_FAILURE]: (state, action) => {
            return {
                ...state,
                ai: {
                    ...state.ai,
                    summaries: {
                        ...state.ai.summaries,
                        pending: false,
                        error: true,
                    },
                },
            };
        },
        [ASSET_DETAIL_AI_ANALYSIS_RESULTS_DETAIL_GET_SUCCESS]: (state, action) => {
            const { engine, label, data } = action.payload;

            const isFaceSearch = engine === "AWS_REKOGNITION_FACE_SEARCH";

            const newResults = isFaceSearch
                ? data?.results.map((el) => ({ ...el, value: el.people?.[0]?.name, originValue: el.value }))
                : data.results;

            const engineObj = !!state.aiDetail?.[engine] ? { ...state.aiDetail[engine] } : {};
            let engineLabelObj = !!engineObj?.[label]?.results
                ? { results: [...engineObj[label].results, ...newResults], nextToken: data.nextToken }
                : { ...data, results: newResults };
            const confidenceArray = engineLabelObj.results?.map((el) => el.confidence) ?? [];
            const maxConfidence = Math.max(...confidenceArray);
            const minConfidenceItem = engineLabelObj.results?.find((el) => el.confidence === maxConfidence);
            engineLabelObj.maxConfidence = minConfidenceItem;
            return {
                ...state,
                aiDetail: {
                    ...state.aiDetail,
                    [engine]: {
                        ...engineObj,
                        [label]: engineLabelObj,
                    },
                },
            };
        },
        [ASSET_DETAIL_AI_ANALYSIS_RESULTS_ORDER_DETAIL_GET_SUCCESS]: (state, action) => {
            return {
                ...state,
                aiDetail: {
                    ...state.aiDetail,
                    MAX_COUNT_LABELS: action.payload,
                },
            };
        },
        [ASSET_DETAIL_AI_ANALYSIS_GET_PENDING]: (state, action) => {
            return {
                ...state,
                ai: {
                    ...state.ai,
                    analysis: {
                        ...state.ai.analysis,
                        pending: action.payload,
                    },
                },
            };
        },
        [ASSET_DETAIL_AI_ANALYSIS_UPDATE]: (state, action) => {
            return {
                ...state,
                ai: {
                    ...state.ai,
                    downloadLinks: action.payload,
                },
            };
        },
        [ASSET_DETAIL_AI_ORIGINAL_RESULTS_GET_SUCCESS]: (state, action) => {
            const { results, nextToken, ...rest } = action.payload;
            return {
                ...state,
                ai: {
                    ...state.ai,
                    originals: {
                        ...state.ai.originals,
                        pending: false,
                        data: state.ai.originals.data?.results
                            ? {
                                  results: [...state.ai.originals.data?.results, ...results],
                                  nextToken: nextToken,
                                  ...rest,
                              }
                            : action.payload,
                    },
                },
            };
        },
        [ASSET_DETAIL_AI_ORIGINAL_RESULTS_GET_PENDING]: (state, action) => {
            return {
                ...state,
                ai: {
                    ...state.ai,
                    originals: {
                        ...state.ai.originals,
                        pending: action.payload,
                    },
                },
            };
        },
        [ASSET_DETAIL_AI_ORIGINAL_RESULTS_GET_FAILURE]: (state, action) => {
            return {
                ...state,
                ai: {
                    ...state.ai,
                    originals: {
                        ...state.ai.originals,
                        pending: false,
                        error: action.payload,
                    },
                },
            };
        },
        [ASSET_DETAIL_RESET_AI_ANALYSIS]: (state, action) => {
            return {
                ...state,
                ai: {
                    ...state.ai,
                    summaries: {
                        pending: false,
                        error: false,
                        data: null,
                    },
                    results: {
                        pending: false,
                        error: false,
                        data: null,
                    },
                    originals: {
                        pending: false,
                        error: false,
                        data: null,
                    },
                    downloadLinks: null,
                },
                aiDetail: null,
            };
        },
        [ASSET_DETAIL_UPDATE_METADATA]: (state, action) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    metadataSchemas: action.payload,
                },
            };
        },
        [ASSET_DETAIL_GET_LINKED_ASSETS_PENDING]: (state) => {
            return {
                ...state,
                linkedAssets: {
                    ...state.linkedAssets,
                    pending: true,
                    error: null,
                },
            };
        },
        [ASSET_DETAIL_GET_LINKED_ASSETS_SUCCESS]: (state, action) => {
            return {
                ...state,
                linkedAssets: {
                    data: action.payload,
                    pending: false,
                    error: null,
                },
            };
        },
        [ASSET_DETAIL_GET_LINKED_ASSETS_FAILURE]: (state, action) => {
            return {
                ...state,
                linkedAssets: {
                    data: null,
                    pending: false,
                    error: action.payload,
                },
            };
        },
    },
    initialState,
);

export const updateState = createAction(ASSET_DETAIL_UPDATE_STATE);

export const clear = createAction(ASSET_CELAR);

export const getAnalysisObjectDetailsPending = createAction(ASSET_GET_ANALYSIS_OBJECT_DETAILS_PENDING);
export const getAnalysisObjectDetailsNext = createAction(ASSET_GET_ANALYSIS_OBJECT_DETAILS_NEXT);
export const getAnalysisObjectDetailsSuccess = createAction(ASSET_GET_ANALYSIS_OBJECT_DETAILS_SUCCESS);
export const getAnalysisObjectDetailsFailure = createAction(ASSET_GET_ANALYSIS_OBJECT_DETAILS_FAILURE);
export const resetRelatedAssets = createAction(ASSET_DETAIL_RESET_RELATED_ASSETS);
export const resetAssetAnalysisStatus = createAction(ASSET_ANALYSIS_STATUS_RESET);
export const assetErrorReset = createAction(ASSET_ERROR_RESET);
export const assetAnalysisPending = createAction(ASSET_DETAIL_AI_ANALYSIS_GET_PENDING);
export const updateAiAnalysis = createAction(ASSET_DETAIL_AI_ANALYSIS_UPDATE);
export const resetAiAnalysis = createAction(ASSET_DETAIL_RESET_AI_ANALYSIS);

export const updateAssetMetadata = createAction(ASSET_DETAIL_UPDATE_METADATA);

export const getRelatedVideoAssets = (videoId) => (dispatch, getState) => {
    const { stage, project } = getState();
    getAssetsAPI(stage.id, stage.endpoint, project.id, { videoId })
        .then((response) => {
            // ToDO
        })
        .catch((err) => {});
};

export const createAssetTag = (id, value) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: ASSET_CREATE_TAG_PENDING, value });

    return createAssetTagAPI(stage.id, stage.endpoint, project.id, id, value)
        .then((response) => {
            dispatch({
                type: ASSET_CREATE_TAG_SUCCESS,
                payload: response,
                value,
            });
        })
        .catch((error) => {
            dispatch({
                type: ASSET_CREATE_TAG_FAILURE,
                payload: error,
                value,
            });
        });
};

export const deleteAssetTag = (id, value) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: ASSET_DELETE_TAG_PENDING, value });

    return deleteAssetTagAPI(stage.id, stage.endpoint, project.id, id, value)
        .then((response) => {
            dispatch({
                type: ASSET_DELETE_TAG_SUCCESS,
                payload: response,
                value,
            });
        })
        .catch((error) => {
            dispatch({
                type: ASSET_DELETE_TAG_FAILURE,
                payload: error,
                value,
            });
        });
};

export const anlayzeAssetAsync = (id, input, types) => (dispatch, getState) => {
    const { stage, project, asset } = getState();
    const resourceToken = asset.resourceToken;

    dispatch({ type: ASSET_START_ANALYSIS_PENDING });
    return new Promise((resolve, reject) => {
        anlayzeAssetAPI(stage.id, stage.endpoint, project.id, id, input, types, resourceToken)
            .then((response) => {
                dispatch({
                    type: ASSET_START_ANALYSIS_SUCCESS,
                    payload: response,
                });
                resolve(response);
            })
            .catch((error) => {
                dispatch({
                    type: ASSET_START_ANALYSIS_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const createAnalysisJob = (params) => (dispatch, getState) => {
    const { stage, project, asset } = getState();

    return new Promise(async (resolve, reject) => {
        const analysisPromises = params?.map(async (param) => {
            await createAnalysisJobAPI(stage.endpoint, project.id, param);
        });

        await Promise.all(analysisPromises)
            .then(() => {
                resolve();
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getAssetAnalysisLabelDetectionObjects = (id, offset, limit) => (dispatch, getState) => {
    const { stage, project, asset } = getState();
    const resourceToken = asset.resourceToken;

    dispatch({ type: ASSET_GET_ANALYSIS_LABEL_DETECTION_OBJECTS_PENDING });
    return getAssetAnalysisObjectsAPI(
        stage.id,
        stage.endpoint,
        project.id,
        id,
        CONSTANTS("ANALYSIS_TYPES").LABEL_DETECTION,
        offset,
        limit,
        resourceToken,
    )
        .then((response) => {
            dispatch({
                type: ASSET_GET_ANALYSIS_LABEL_DETECTION_OBJECTS_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: ASSET_GET_ANALYSIS_LABEL_DETECTION_OBJECTS_FAILURE,
                payload: error,
            });
        });
};

export const getAssetAnalysisLabelDetectionObjectsFromShared =
    (id, offset, limit, resourceToken) => (dispatch, getState) => {
        const { stage, project } = getState();

        dispatch({ type: ASSET_GET_ANALYSIS_LABEL_DETECTION_OBJECTS_PENDING });

        return getAssetAnalysisObjectsFromSharedAPI(
            stage.id,
            stage.endpoint,
            project.id,
            id,
            CONSTANTS("ANALYSIS_TYPES").LABEL_DETECTION,
            offset,
            limit,
            resourceToken,
        )
            .then((response) => {
                dispatch({
                    type: ASSET_GET_ANALYSIS_LABEL_DETECTION_OBJECTS_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: ASSET_GET_ANALYSIS_LABEL_DETECTION_OBJECTS_FAILURE,
                    payload: error,
                });
            });
    };

export const getAssetAnalysisObjectDetails = (id, value, analysisType) => (dispatch, getState) => {
    const { stage, project, asset } = getState();
    const resourceToken = asset.resourceToken;
    let key = ANALYSIS_DETAIL_KEY[analysisType];

    dispatch(getAnalysisObjectDetailsPending({ key, value, analysisType }));
    return new Promise((resolve, reject) => {
        function responseCb(response) {
            if (response.nextToken) {
                dispatch(getAnalysisObjectDetailsNext({ ...response, analysisType, value }));
                return getAssetAnalysisObjectDetailsAPI({
                    stageId: stage.id,
                    apiEndpoint: stage.endpoint,
                    projectId: project.id,
                    assetId: id,
                    type: analysisType,
                    key,
                    value,
                    resourceToken,
                })
                    .then(responseCb)
                    .catch(errorCb);
            } else {
                dispatch(getAnalysisObjectDetailsSuccess({ ...response, analysisType, value }));
                resolve();
            }
        }
        function errorCb(error) {
            console.error(error);
            dispatch(getAnalysisObjectDetailsFailure({ ...error, analysisType, value }));
            reject();
        }
        getAssetAnalysisObjectDetailsAPI({
            stageId: stage.id,
            apiEndpoint: stage.endpoint,
            projectId: project.id,
            assetId: id,
            type: analysisType,
            key,
            value,
        })
            .then(responseCb)
            .catch(errorCb);
    });
};

export const getDetailObject = (analysisType, key) => (dispatch, getState) => {
    const { asset } = getState();
    const detail = asset.analysisDetails[analysisType][key];
    return detail;
};

export const getAssetAnalysisFaceSearchObjects = (id, offset, limit) => (dispatch, getState) => {
    const { stage, project, asset } = getState();
    const resourceToken = asset.resourceToken;

    dispatch({ type: ASSET_GET_ANALYSIS_FACE_SEARCH_OBJECTS_PENDING });
    return getAssetAnalysisObjectsAPI(
        stage.id,
        stage.endpoint,
        project.id,
        id,
        CONSTANTS("ANALYSIS_TYPES").FACE_SEARCH,
        offset,
        limit,
        resourceToken,
    )
        .then((response) => {
            dispatch({
                type: ASSET_GET_ANALYSIS_FACE_SEARCH_OBJECTS_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: ASSET_GET_ANALYSIS_FACE_SEARCH_OBJECTS_FAILURE,
                payload: error,
            });
        });
};

export const getAssetAnalysisFaceSearchObjectsFromShared =
    (id, offset, limit, resourceToken) => (dispatch, getState) => {
        const { stage, project } = getState();

        dispatch({ type: ASSET_GET_ANALYSIS_FACE_SEARCH_OBJECTS_PENDING });

        return getAssetAnalysisObjectsFromSharedAPI(
            stage.id,
            stage.endpoint,
            project.id,
            id,
            CONSTANTS("ANALYSIS_TYPES").FACE_SEARCH,
            offset,
            limit,
            resourceToken,
        )
            .then((response) => {
                dispatch({
                    type: ASSET_GET_ANALYSIS_FACE_SEARCH_OBJECTS_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: ASSET_GET_ANALYSIS_FACE_SEARCH_OBJECTS_FAILURE,
                    payload: error,
                });
            });
    };

export const getAssetAnalysisCelebrityRecognitionObjects = (id, offset, limit) => (dispatch, getState) => {
    const { stage, project, asset } = getState();
    const resourceToken = asset.resourceToken;

    dispatch({ type: ASSET_GET_ANALYSIS_CELEBRITY_RECOGNITION_OBJECTS_PENDING });
    return getAssetAnalysisObjectsAPI(
        stage.id,
        stage.endpoint,
        project.id,
        id,
        CONSTANTS("ANALYSIS_TYPES").CELEBRITY_RECOGNITION,
        offset,
        limit,
        resourceToken,
    )
        .then((response) => {
            dispatch({
                type: ASSET_GET_ANALYSIS_CELEBRITY_RECOGNITION_OBJECTS_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: ASSET_GET_ANALYSIS_CELEBRITY_RECOGNITION_OBJECTS_FAILURE,
                payload: error,
            });
        });
};

export const getAssetAnalysisCelebrityRecognitionObjectsFromShared =
    (id, offset, limit, resourceToken) => (dispatch, getState) => {
        const { stage, project } = getState();

        dispatch({ type: ASSET_GET_ANALYSIS_CELEBRITY_RECOGNITION_OBJECTS_PENDING });

        return getAssetAnalysisObjectsFromSharedAPI(
            stage.id,
            stage.endpoint,
            project.id,
            id,
            CONSTANTS("ANALYSIS_TYPES").CELEBRITY_RECOGNITION,
            offset,
            limit,
            resourceToken,
        )
            .then((response) => {
                dispatch({
                    type: ASSET_GET_ANALYSIS_CELEBRITY_RECOGNITION_OBJECTS_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: ASSET_GET_ANALYSIS_CELEBRITY_RECOGNITION_OBJECTS_FAILURE,
                    payload: error,
                });
            });
    };

export const getAssetAnalysisContentModerationObjects = (id, offset, limit) => (dispatch, getState) => {
    const { stage, project, asset } = getState();
    const resourceToken = asset.resourceToken;
    dispatch({ type: ASSET_GET_ANALYSIS_COTENT_MODERATION_OBJECTS_PENDING });
    return getAssetAnalysisObjectsAPI(
        stage.id,
        stage.endpoint,
        project.id,
        id,
        CONSTANTS("ANALYSIS_TYPES").CONTENT_MODERATION,
        offset,
        limit,
        resourceToken,
    )
        .then((response) => {
            dispatch({
                type: ASSET_GET_ANALYSIS_COTENT_MODERATION_OBJECTS_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: ASSET_GET_ANALYSIS_COTENT_MODERATION_OBJECTS_FAILURE,
                payload: error,
            });
        });
};

export const getAssetAnalysisContentModerationObjectsFromShared =
    (id, offset, limit, resourceToken) => (dispatch, getState) => {
        const { stage, project } = getState();

        dispatch({ type: ASSET_GET_ANALYSIS_COTENT_MODERATION_OBJECTS_PENDING });

        return getAssetAnalysisObjectsFromSharedAPI(
            stage.id,
            stage.endpoint,
            project.id,
            id,
            CONSTANTS("ANALYSIS_TYPES").CONTENT_MODERATION,
            offset,
            limit,
            resourceToken,
        )
            .then((response) => {
                dispatch({
                    type: ASSET_GET_ANALYSIS_COTENT_MODERATION_OBJECTS_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: ASSET_GET_ANALYSIS_COTENT_MODERATION_OBJECTS_FAILURE,
                    payload: error,
                });
            });
    };

export const getAssetAnalysisStatus = (id) => (dispatch, getState) => {
    const { stage, project, asset } = getState();
    const resourceToken = asset.resourceToken;
    return getAssetAnalysisStatusAPI(stage.id, stage.endpoint, project.id, id, resourceToken)
        .then((response) => {
            dispatch({
                type: ASSET_GET_ANALYSIS_STATUS_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            console.error("get status error", error);
        });
};

export const getAsset =
    (id, projectId, ignorePending = false) =>
    (dispatch, getState) => {
        const { stage, project, asset } = getState();
        const contextVersion = asset.contextVersion + 1;

        if (!ignorePending)
            dispatch({
                type: ASSET_DETAIL_PENDING,
            });
        return new Promise((resolve, reject) => {
            getAssetAPI(stage.id, stage.endpoint, projectId || project.id, id)
                .then(async (response) => {
                    let assetData = response;
                    if (response.data.virtualFolder !== undefined && response.data.virtualDrive !== undefined) {
                        //virtualFolder 존재시 전체경로 획득
                        const folderPath = await dispatch(
                            getDetailDriveFolder({
                                driveId: response.data.virtualDrive.id,
                                folderId: response.data.virtualFolder.id,
                            }),
                        );
                        // console.log(folderPath);
                        assetData = {
                            ...assetData,
                            data: {
                                ...assetData.data,
                                virtualFolderPath: folderPath.parents.reverse().reduce(
                                    (acc, cur) => {
                                        if (cur.depth === 0) return `/${acc}`;
                                        return `${cur.name}/${acc}`;
                                    },
                                    `${folderPath.depth === 0 ? "/" : folderPath.name}`,
                                ),
                            },
                        };
                    }
                    dispatch({
                        type: ASSET_DETAIL_GET_ASSET_SUCCESS,
                        payload: assetData,
                    });
                    const stageId = stage.id;
                    const apiEndpoint = stage.endpoint;
                    const data = response.data;
                    const shouldRunStatusPoller =
                        (data && data.status && data.status === "ACTIVATING") || data.status === "INACTIVATING";
                    if (shouldRunStatusPoller) {
                        Polling(
                            async () => {
                                return await getStatus(stageId, apiEndpoint, projectId, id);
                            },
                            (response) => {
                                const asset = getState().asset;
                                return (
                                    (asset.data && asset.data.id !== response.data.id) ||
                                    asset.contextVersion !== contextVersion ||
                                    response.data.status === "ACTIVE" ||
                                    response.data.status === "INACTIVE"
                                );
                            },
                            (response) => {
                                dispatch({
                                    type: UPDATE_ASSET_STATUS,
                                    payload: response,
                                });
                            },
                            null,
                            3000,
                        ).run();
                    }

                    resolve(response);
                })
                .catch((error) => {
                    dispatch({
                        type: ASSET_DETAIL_FAILURE,
                        payload: error,
                    });
                    reject(error);
                });
        });
    };

export const deleteAsset = (id, version) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: ASSET_DETAIL_IS_CHANGING_DATA });

    return new Promise((resolve, reject) => {
        deleteAssetAPI(stage.id, stage.endpoint, project.id, id, version)
            .then(() => {
                dispatch({
                    type: ASSET_DETAIL_DELETE_ASSET_SUCCESS,
                });
            })
            .catch((error) => {
                dispatch({
                    type: ASSET_DETAIL_FAILURE,
                    payload: error,
                });
            });
    });
};

export const deleteAssetElement = (id, version, elementId) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: ASSET_DETAIL_IS_CHANGING_DATA });

    return new Promise((resolve, reject) => {
        deleteAssetElementAPI(stage.id, stage.endpoint, project.id, id, version, elementId)
            .then((response) => {
                dispatch({
                    type: ASSET_DETAIL_DELETE_ASSET_ELEMENT_SUCCESS,
                    payload: elementId,
                });
                resolve(response);
            })
            .catch((error) => {
                dispatch({
                    type: ASSET_DETAIL_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const createAssetElement = (id, version, data) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: ASSET_DETAIL_IS_CHANGING_DATA });

    return new Promise((resolve, reject) => {
        createAssetElementAPI(stage.id, stage.endpoint, project.id, id, version, data)
            .then((response) => {
                dispatch({
                    type: ASSET_DETAIL_CREATE_ASSET_ELEMENT_SUCCESS,
                    payload: response,
                });
                resolve(response);
            })
            .catch((error) => {
                dispatch({
                    type: ASSET_DETAIL_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const bulkCreateAssetElements = (id, version, data) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: ASSET_DETAIL_IS_CHANGING_DATA });

    return new Promise((resolve, reject) => {
        bulkCreateAssetElementsAPI(stage.id, stage.version, stage.endpoint, project.id, id, version, data)
            .then((response) => {
                dispatch({
                    type: ASSET_DETAIL_GET_ASSET_SUCCESS,
                    payload: response,
                });
                resolve(response);
            })
            .catch((error) => {
                dispatch({
                    type: ASSET_DETAIL_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const updateAssetElement = (id, version, elementId, data) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: ASSET_DETAIL_IS_CHANGING_DATA });

    return new Promise((resolve, reject) => {
        updateAssetElementAPI(stage.id, stage.endpoint, project.id, id, version, elementId, data)
            .then((response) => {
                dispatch({
                    type: ASSET_DETAIL_UPDATE_ASSET_ELEMENT_SUCCESS,
                    payload: response,
                });
                resolve(response);
            })
            .catch((error) => {
                dispatch({
                    type: ASSET_DETAIL_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const updateAsset =
    (id, version, data, updateTotalSize = false) =>
    (dispatch, getState) => {
        const { stage, project, asset } = getState();

        const stageId = stage.id;
        const apiEndpoint = stage.endpoint;
        const projectId = project.id;

        const shouldRunStatusPoller = data.status && asset.data && asset.data.status !== data.status;
        const contextVersion = asset.contextVersion + 1;
        dispatch({ type: ASSET_DETAIL_IS_CHANGING_DATA });

        return updateAssetAPI(stageId, apiEndpoint, projectId, id, version, updateTotalSize, data)
            .then(async (response) => {
                let assetData = response;
                if (data.virtualFolder?.id) {
                    //virtualFolder 수정시 전체경로 획득
                    const folderPath = await dispatch(
                        getDetailDriveFolder({
                            driveId: response.data.virtualDrive.id,
                            folderId: response.data.virtualFolder.id,
                        }),
                    );
                    assetData = {
                        ...assetData,
                        data: {
                            ...assetData.data,
                            virtualFolderPath: folderPath.parents.reverse().reduce((acc, cur) => {
                                if (cur.depth === 0) return `/${acc}`;
                                return `${cur.name}/${acc}`;
                            }, `${folderPath.name}`),
                        },
                    };
                }
                dispatch({
                    type: ASSET_DETAIL_UPDATE_ASSET_SUCCESS,
                    payload: assetData,
                });
                if (shouldRunStatusPoller) {
                    await Polling(
                        async () => {
                            return await getStatus(stageId, apiEndpoint, projectId, id);
                        },
                        (response) => {
                            const asset = getState().asset;
                            return (
                                (asset.data && asset.data.id !== response.data.id) ||
                                asset.contextVersion !== contextVersion ||
                                response.data.status === data.status
                            );
                        },
                        (response) => {
                            dispatch({
                                type: UPDATE_ASSET_STATUS,
                                payload: response,
                            });
                        },
                        null,
                        3000,
                    ).run();
                }
                Promise.resolve();
            })
            .catch((error) => {
                dispatch({
                    type: ASSET_DETAIL_FAILURE,
                    payload: error,
                });
                Promise.reject(error);
            });
    };

export const updateOwner = (id, version, data) => (dispatch, getState) => {
    const { stage, project, asset } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;

    dispatch({ type: ASSET_DETAIL_IS_CHANGING_DATA });

    return updateAssetOwnerAPI(stageId, apiEndpoint, projectId, id, version, data)
        .then((response) => {
            dispatch({
                type: ASSET_DETAIL_UPDATE_ASSET_OWNER_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: ASSET_DETAIL_FAILURE,
                payload: error,
            });
        });
};

export const getRelatedAssets =
    (jobId, resourceToken, ignorePending = false) =>
    (dispatch, getState) => {
        const { stage, project, asset } = getState();

        const projectId = (asset.data.project && asset.data.project.id) || project.id;

        if (!ignorePending)
            dispatch({
                type: ASSET_DETAIL_GET_RELATED_ASSET_LIST_PENDING,
            });

        return getAssetsAPI(stage.id, stage.endpoint, projectId, { jobId, hasAssociations: true }, resourceToken)
            .then((response) => {
                dispatch({
                    type: ASSET_DETAIL_GET_RELATED_ASSET_LIST_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                // ToDO
                dispatch({
                    type: ASSET_DETAIL_GET_RELATED_ASSET_LIST_FAILURE,
                    payload: response,
                });
            });
    };

export const getRelatedResourcesAsset =
    (ids, ignorePending = false) =>
    (dispatch, getState) => {
        const { stage, project, asset } = getState();

        const projectId = (asset.data.project && asset.data.project.id) || project.id;

        if (!ignorePending)
            dispatch({
                type: ASSET_DETAIL_GET_RELATED_ASSET_LIST_PENDING,
            });

        return getAssetsAPI(stage.id, stage.endpoint, projectId, { ids, hasAssociations: true })
            .then((response) => {
                dispatch({
                    type: ASSET_DETAIL_GET_RELATED_ASSET_LIST_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                // ToDO
                dispatch({
                    type: ASSET_DETAIL_GET_RELATED_ASSET_LIST_FAILURE,
                    payload: response,
                });
            });
    };

export const getRelatedVideos = (id, projectId, resourceToken) => (dispatch, getState) => {
    const { stage, project } = getState();

    getRelatedVideosAPI(stage.id, stage.endpoint, projectId, id, resourceToken)
        .then((response) => {
            dispatch({
                type: ASSET_DETAIL_GET_RELATED_VIDEO_LIST_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            // ToDO
        });
};

export const attachPeopleAsync = (id, people) => (dispatch, getState) => {
    const { stage, project } = getState();

    return new Promise((resolve, reject) => {
        attachPeopleToAsset(stage.id, stage.endpoint, project.id, id, people)
            .then(() => {
                getPeopleAsync(stage.id, stage.endpoint, project.id, people.id)
                    .then((response) => {
                        dispatch({
                            type: ASSET_ATTACH_PEOPLE_SUCCESS,
                            payload: response,
                        });
                        resolve(response);
                    })
                    .catch((error) => {
                        dispatch({
                            type: ASSET_ATTACH_PEOPLE_FAILURE,
                            payload: error,
                        });
                        reject(error);
                    });
            })
            .catch((error) => {
                dispatch({
                    type: ASSET_ATTACH_PEOPLE_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const detachPeopleAsync = (id, people) => (dispatch, getState) => {
    const { stage, project } = getState();

    return new Promise((resolve, reject) => {
        detachPeopleFromAsset(stage.id, stage.endpoint, project.id, id, people)
            .then((response) => {
                dispatch({
                    type: ASSET_DETACH_PEOPLE_SUCCESS,
                    payload: people,
                });
                resolve(response);
            })
            .catch((error) => {
                dispatch({
                    type: ASSET_DETACH_PEOPLE_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const getUploadUrl = (assetId, params) => (dispatch, getState) => {
    const { stage, project } = getState();

    return new Promise((resolve, reject) => {
        getUploadUrlAPI(stage.id, stage.endpoint, project.id, assetId, params)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

// TODO : Attach, Detach 용도, 위치 바꿔야 하면 이동
const getPeopleAsync = (stageId, apiEndpoint, projectId, peopleId) => {
    return new Promise((resolve, reject) => {
        getPeopleAPI(stageId, apiEndpoint, projectId, peopleId)
            .then((response) => {
                const data = Object.assign({}, response.data);
                if (data && data.primaryImage) {
                    response.data = {
                        id: data.id,
                        name: data.name,
                        imageUrl: data.primaryImage.url,
                        accessUrl: data.primaryImage.accessUrl,
                    };
                } else if (data && data.images && data.images.length) {
                    response.data = {
                        id: data.id,
                        name: data.name,
                        imageUrl: data.images[0].url,
                        accessUrl: data.images[0].accessUrl,
                    };
                }
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const activate = (id, version, customErrorMessage) => (dispatch, getState) => {
    const { stage, project, asset } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;
    const shouldRunStatusPoller = asset.data && asset.data.status !== "ACTIVE";
    const contextVersion = asset.contextVersion + 1;
    dispatch({ type: ASSET_DETAIL_IS_CHANGING_DATA });

    return new Promise(async (resolve, reject) => {
        try {
            const response = await AssetApi.activate(stageId, apiEndpoint, projectId, id, version, customErrorMessage);
            resolve(response);
        } catch (e) {
            reject(e);
        }
    })
        .then((response) => {
            dispatch({
                type: ASSET_DETAIL_UPDATE_ASSET_SUCCESS,
                payload: response,
            });
            if (shouldRunStatusPoller) {
                Polling(
                    async () => {
                        return await getStatus(stageId, apiEndpoint, projectId, id);
                    },
                    (response) => {
                        const asset = getState().asset;
                        return (
                            (asset.data && asset.data.id !== response.data.id) ||
                            asset.contextVersion !== contextVersion ||
                            response.data.status === "ACTIVE"
                        );
                    },
                    (response) => {
                        dispatch({
                            type: UPDATE_ASSET_STATUS,
                            payload: response,
                        });
                    },
                    null,
                    3000,
                ).run();
            }
        })
        .catch((error) => {
            dispatch({
                type: ASSET_DETAIL_UPDATE_STATUS_FAILURE,
                payload: error?.response.data || error,
            });
        });
};

export const inactivate = (id, version, customErrorMessage) => (dispatch, getState) => {
    const { stage, project, asset } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;
    const shouldRunStatusPoller = asset.data && asset.data.status !== "INACTIVE";
    const contextVersion = asset.contextVersion + 1;
    dispatch({ type: ASSET_DETAIL_IS_CHANGING_DATA });

    return new Promise(async (resolve, reject) => {
        try {
            const response = await AssetApi.inactivate(
                stageId,
                apiEndpoint,
                projectId,
                id,
                version,
                customErrorMessage,
            );
            resolve(response);
        } catch (e) {
            reject(e);
        }
    })
        .then((response) => {
            dispatch({
                type: ASSET_DETAIL_UPDATE_ASSET_SUCCESS,
                payload: response,
            });
            if (shouldRunStatusPoller) {
                Polling(
                    async () => {
                        return await getStatus(stageId, apiEndpoint, projectId, id);
                    },
                    (response) => {
                        const asset = getState().asset;
                        return (
                            (asset.data && asset.data.id !== response.data.id) ||
                            asset.contextVersion !== contextVersion ||
                            response.data.status === "INACTIVE"
                        );
                    },
                    (response) => {
                        dispatch({
                            type: UPDATE_ASSET_STATUS,
                            payload: response,
                        });
                    },
                    null,
                    3000,
                ).run();
            }
        })
        .catch((error) => {
            dispatch({
                type: ASSET_DETAIL_UPDATE_STATUS_FAILURE,
                payload: error?.response.data || error,
            });
        });
};

export const putArchive = (id, version, option) => (dispatch, getState) => {
    const { stage, project } = getState();
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;
    const storageClass = option;
    const data = {
        storageClass,
        version,
    };
    dispatch({ type: ASSET_DETAIL_IS_CHANGING_DATA });

    return new Promise((resolve, reject) => {
        putArchiveAPI(apiEndpoint, projectId, id, data)
            .then((response) => {
                dispatch({
                    type: ASSET_DETAIL_UPDATE_ASSET_SUCCESS,
                    payload: response,
                });
                resolve(response);
            })
            .catch((error) => {
                dispatch({
                    type: ASSET_DETAIL_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const requestAssetElementsDownloadUrl =
    ({ assetId, elementIds, projectId }) =>
    (dispatch, getState) => {
        const { stage, asset } = getState();
        const resourceToken = asset.resourceToken;
        return requestAssetElementsDownloadUrlApi(
            stage.id,
            stage.endpoint,
            projectId,
            assetId,
            elementIds,
            resourceToken,
        );
    };

export const requestAssetsDownloadUrl =
    ({ assetIds, projectId }) =>
    (dispatch, getState) => {
        const { stage, asset } = getState();
        const resourceToken = asset.resourceToken;
        return requestAssetsDownloadUrlApi(stage.id, stage.endpoint, projectId, assetIds, resourceToken);
    };

export const getAssetElementVersions = (assetId, version, elementId) => (dispatch, getState) => {
    const { stage, project, asset } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;
    const resourceToken = asset.resourceToken;
    // dispatch({type: ASSET_DETAIL_IS_CHANGING_DATA});

    return new Promise((resolve, reject) => {
        getAssetElementVersionsAPI(stageId, apiEndpoint, projectId, assetId, version, elementId, resourceToken)
            .then((response) => {
                dispatch({
                    type: ASSET_DETAIL_ELEMENT_VERSIONS_GET_SUCCESS,
                    payload: {
                        elementId,
                        data: response.data,
                    },
                });
                resolve(response);
            })
            .catch((error) => {
                dispatch({
                    type: ASSET_DETAIL_ELEMENT_VERSIONS_GET_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const getRelatedContents =
    (assetId, version, ignorePending = false) =>
    (dispatch, getState) => {
        const { stage, project, asset } = getState();

        const stageId = stage.id;
        const apiEndpoint = stage.endpoint;
        const projectId = project.id;
        const resourceToken = asset.resourceToken;

        if (!ignorePending) dispatch({ type: ASSET_DETAIL_RELATED_CONTENT_GET_PENDING });

        return new Promise((resolve, reject) => {
            getAssetRelatedContentsAPI(stageId, apiEndpoint, projectId, assetId, version, resourceToken)
                .then((response) => {
                    dispatch({
                        type: ASSET_DETAIL_RELATED_CONTENT_GET_SUCCESS,
                        payload: {
                            data: response.data,
                        },
                    });
                    resolve(response);
                })
                .catch((error) => {
                    dispatch({
                        type: ASSET_DETAIL_RELATED_CONTENT_GET_FAILURE,
                        payload: error,
                    });
                    reject(error);
                });
        });
    };

export const checkStatusPolling =
    ({ resourceId, params }) =>
    async (dispatch, getState) => {
        const { stage, project, asset, video } = getState();

        return new Promise((resolve, reject) => {
            try {
                Polling(
                    async () => {
                        return await getAiCaptionsAPI(stage.id, stage.endpoint, project.id, params);
                    },
                    (response) => {
                        const data = response.data.results?.filter(
                            (el) => !["COMPLETED", "FAILED"].includes(el.status),
                        );
                        return data?.length === 0 || !resourceId;
                    },
                    (response) => {
                        const data = response.data.results?.filter(
                            (el) => !["COMPLETED", "FAILED"].includes(el.status),
                        );
                        dispatch({
                            type: UPDATE_AUTO_CAPTION_STATUS,
                            payload: data.length > 0,
                        });
                    },
                    null,
                    4000,
                ).run();
                resolve();
            } catch (e) {
                reject(e);
            }
        });
    };

export const checkAIJobStatusPolling =
    ({ resourceId, params }) =>
    async (dispatch, getState) => {
        const { stage, project, asset, video } = getState();

        return new Promise((resolve, reject) => {
            try {
                Polling(
                    async () => {
                        return await getAiAnalysisAPI(stage.id, stage.endpoint, project.id, params);
                    },
                    (response) => {
                        const data = response.data.results?.filter(
                            (el) => !["COMPLETED", "FAILED"].includes(el.status),
                        );
                        return data?.length === 0 || !resourceId;
                    },
                    (response) => {
                        const data = response.data.results;
                        dispatch({
                            type: UPDATE_AI_ANALYSIS,
                            payload: data,
                        });
                    },
                    null,
                    4000,
                ).run();
                resolve();
            } catch (e) {
                reject(e);
            }
        });
    };

export const getAssetAiAnalysisResults = (analysisId) => (dispatch, getState) => {
    const { stage, project, asset } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;

    dispatch({ type: ASSET_DETAIL_AI_ANALYSIS_RESULTS_GET_PENDING });

    return new Promise((resolve, reject) => {
        getAiAnalysisResultsAPI(stageId, apiEndpoint, projectId, analysisId)
            .then((response) => {
                dispatch({
                    type: ASSET_DETAIL_AI_ANALYSIS_RESULTS_GET_SUCCESS,
                    payload: {
                        analysisId,
                        data: response.data,
                    },
                });
                resolve(response);
            })
            .catch((error) => {
                dispatch({
                    type: ASSET_DETAIL_AI_ANALYSIS_RESULTS_GET_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const getAssetAiAnalysisResultsDetail =
    ({ analysisId, engine, params, label }) =>
    (dispatch, getState) => {
        const { stage, project } = getState();

        const stageId = stage.id;
        const apiEndpoint = stage.endpoint;
        const projectId = project.id;

        // dispatch({ type: ASSET_DETAIL_AI_ANALYSIS_RESULTS_DETAIL_GET_PENDING });

        return new Promise((resolve, reject) => {
            getAiAnalysisResultsAPI(stageId, apiEndpoint, projectId, analysisId, params)
                .then((response) => {
                    dispatch({
                        type: ASSET_DETAIL_AI_ANALYSIS_RESULTS_DETAIL_GET_SUCCESS,
                        payload: {
                            analysisId,
                            engine,
                            label,
                            data: response.data,
                        },
                    });

                    resolve(response);
                })
                .catch((error) => {
                    // dispatch({
                    //     type: ASSET_DETAIL_AI_ANALYSIS_RESULTS_DETAIL_GET_FAILURE,
                    //     payload: error,
                    // });
                    reject(error);
                });
        });
    };

export const getDownloadAiAnalysisLinks = (params) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const { stage, project } = getState();

        const stageId = stage.id;
        const apiEndpoint = stage.endpoint;
        const projectId = project.id;

        getDownloadAiAnalysisLinksAPI(stageId, apiEndpoint, projectId, params)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getAssetAiAnalysisSummaries = (analysisId, params) => (dispatch, getState) => {
    const { stage, project, asset } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;

    dispatch({ type: ASSET_DETAIL_AI_ANALYSIS_SUMMARIES_GET_PENDING });

    return new Promise((resolve, reject) => {
        getAiAnalysisSummariesAPI(stageId, apiEndpoint, projectId, analysisId, params)
            .then((response) => {
                if (params && params.orderField) {
                    dispatch({
                        type: ASSET_DETAIL_AI_ANALYSIS_RESULTS_ORDER_DETAIL_GET_SUCCESS,
                        payload: response.data?.results,
                    });
                } else {
                    dispatch({
                        type: ASSET_DETAIL_AI_ANALYSIS_SUMMARIES_GET_SUCCESS,
                        payload: {
                            analysisId,
                            data: response.data,
                        },
                    });
                }
                resolve(response);
            })
            .catch((error) => {
                dispatch({
                    type: ASSET_DETAIL_AI_ANALYSIS_SUMMARIES_GET_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const checkAIAnalysisDownloadLinkPolling = (params) => async (dispatch, getState) => {
    const { asset } = getState();
    return new Promise((resolve, reject) => {
        try {
            Polling(
                async () => {
                    return await dispatch(getDownloadAiAnalysisLinks(params));
                },
                (response) => {
                    return response?.status === "READY";
                },
                (response) => {
                    const downloadLinks = asset.ai.downloadLinks ?? [];
                    const payload = [...downloadLinks, { analysisId: params.id, ...response }];
                    dispatch({
                        type: ASSET_DETAIL_AI_ANALYSIS_UPDATE,
                        payload: payload,
                    });
                },
                null,
                4000,
            ).run();
            resolve();
        } catch (e) {
            reject(e);
        }
    });
};

export const getAiOriginalResults = (analysisId, params) => (dispatch, getState) => {
    const { stage, project, asset } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;

    dispatch({
        type: ASSET_DETAIL_AI_ORIGINAL_RESULTS_GET_PENDING,
        payload: true,
    });
    return new Promise((resolve, reject) => {
        getAiOriginalResultsAPI(stageId, apiEndpoint, projectId, analysisId, params)
            .then(async (response) => {
                let newDetail = [...(asset.ai.originals.data?.detail ?? [])];
                const detailPromises = response.data?.results?.map(async (el) => {
                    const resultResponse = await getAiOriginalResultAPI(
                        stageId,
                        apiEndpoint,
                        projectId,
                        analysisId,
                        el.id,
                    );
                    newDetail.push(resultResponse.data);
                });

                await Promise.all(detailPromises).then(() => {
                    dispatch({
                        type: ASSET_DETAIL_AI_ORIGINAL_RESULTS_GET_SUCCESS,
                        payload: { ...response.data, detail: newDetail },
                    });
                });

                resolve(response);
            })
            .catch((error) => {
                dispatch({
                    type: ASSET_DETAIL_AI_ORIGINAL_RESULTS_GET_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const copyAsset = (params) => async (dispatch, getState) => {
    const { stage, project } = getState();
    return new Promise(async (resolve, reject) => {
        try {
            const response = await copyAssetAPI(stage.id, stage.endpoint, project.id, params);
            resolve(response.data);
        } catch (e) {
            reject(e);
        }
    });
};

export const getAssetLinkedAssets = (projectId, assetId, params) => async (dispatch, getState) => {
    const { stage, project } = getState();
    try {
        let nextToken = null;
        let results = [];

        dispatch({ type: ASSET_DETAIL_GET_LINKED_ASSETS_PENDING });
        do {
            const response = await getAssetLinkedAssetsAPI(stage.endpoint, projectId ?? project.id, assetId, {
                ...params,
                nextToken,
                generateAccessUrl: true,
            });
            nextToken = response.data.nextToken;
            results = results.concat(response.data.results);
        } while (!!nextToken);
        dispatch({ type: ASSET_DETAIL_GET_LINKED_ASSETS_SUCCESS, payload: results });
    } catch (error) {
        dispatch({ type: ASSET_DETAIL_GET_LINKED_ASSETS_FAILURE, payload: error });
    }
};
