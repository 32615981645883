import { axios } from "../../cores/axiosWrapper";

export function createCollectionAPI(apiEndpoint, projectId, data) {
    return axios.post(`${apiEndpoint}/collections`, data, {
        headers: {
            projectId,
        },
    });
}

export function getCollectionAPI(apiEndpoint, projectId, collectionId) {
    return axios.get(`${apiEndpoint}/collections/${collectionId}`, {
        headers: {
            projectId,
        },
    });
}

export function createLinkedCollectionAPI(apiEndpoint, projectId, collectionId, data) {
    return axios.post(`${apiEndpoint}/collections/${collectionId}/linked-collections`, data, {
        headers: {
            projectId,
        },
    });
}

export function getLinkedCollectionsAPI(apiEndpoint, projectId, collectionId, params) {
    return axios.get(`${apiEndpoint}/collections/${collectionId}/linked-collections`, {
        params,
        headers: {
            projectId,
        },
    });
}

export function getLinkedAssetsAPI(apiEndpoint, projectId, collectionId, params) {
    return axios.get(`${apiEndpoint}/collections/${collectionId}/items`, {
        params,
        headers: {
            projectId,
        },
    });
}

export const deleteCollectionAPI = (apiEndpoint, projectId, collectionId) => {
    return axios.delete(`${apiEndpoint}/collections/${collectionId}`, { headers: { projectId } });
};

/**
 * 컬렉션의 일반 값(이름, 설명, primaryAsset)의 수정 시 사용
 * @param {*} apiEndpoint
 * @param {*} projectId
 * @param {*} collectionId
 * @param {*} params
 * @returns
 */
export const updateCollectionAPI = (apiEndpoint, projectId, collectionId, params) => {
    return axios.patch(`${apiEndpoint}/collections/${collectionId}`, params, {
        headers: {
            projectId,
        },
    });
};

/**
 * https://megazone.stoplight.io/docs/cloudplex-media/rjgkxl0yvzdvp-
 * 컬렉션의 태그, 에셋, linked Collection의 수정 시 사용
 * @param {*} apiEndpoint
 * @param {*} projectId
 * @param {*} collectionId
 * @param {*} params
 * @returns
 */
export const updateCollectionActionAPI = (apiEndpoint, projectId, collectionId, params) => {
    return axios.post(`${apiEndpoint}/collections/${collectionId}/actions`, params, {
        headers: {
            projectId,
        },
    });
};
