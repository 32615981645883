import React, { useEffect, useMemo } from "react";
import {
    IconArrowDropDown,
    IconArrowDropUp,
    IconMoreHoriz,
    IconSeparator,
    ImageCategoryGroupOn,
    ImageDriveOn,
} from "@mzc-cloudplex/icons";
import { Breadcrumbs, Button, Grid } from "@mzc-pdc/ui";
import Menu from "../menu/menu";
import { useSelector } from "react-redux";
import { GROUPING_VIEW_TYPE } from "@constants";

const TreeViewBreadcrumb = ({ hierarchyType, breadcrumbMenu, onClickFolderTree, onClickTreeItem }) => {
    const folderTreeView = useSelector(({ folderTreeView }) => folderTreeView);
    const categoryTreeView = useSelector(({ categoryTreeView }) => categoryTreeView);

    const displayFolderPathList = (path) => {
        // const { folderTreeView } = this.props;
        const array = [];

        if (!folderTreeView.folderList.data || !path) return <></>;

        let selectedFolder = folderTreeView.folderList.data[path];
        while (selectedFolder) {
            const copy = { ...selectedFolder };
            array.push(copy); //폴더와 searchqueryparams 둘다 변경이 필요함
            selectedFolder = folderTreeView.folderList.data[selectedFolder.parent];
        }

        return array.reverse().map((breadcrumb) => ({
            ...breadcrumb,
            label: breadcrumb.name,
            onClick: () => onClickFolderTree(breadcrumb, false, false, true),
        }));
    };

    const displayCategoryPathList = (path) => {
        const array = [];

        if (!categoryTreeView.data || !path) return <></>;

        let selectedCategory = categoryTreeView.categoryMap[path];
        while (selectedCategory) {
            const copy = { ...selectedCategory };
            array.push(copy); //폴더와 searchqueryparams 둘다 변경이 필요함
            selectedCategory = categoryTreeView.categoryMap[selectedCategory.parent?.id];
        }

        return array.reverse().map((breadcrumb) => ({
            ...breadcrumb,
            label: breadcrumb.name,
            onClick: () => onClickTreeItem(breadcrumb),
        }));
    };

    const breadcrumbList = useMemo(() => {
        if (hierarchyType === GROUPING_VIEW_TYPE.FOLDER) {
            if (folderTreeView.folderList?.selectedFolder?.folder?.id) {
                return displayFolderPathList(folderTreeView.folderList.selectedFolder.folder.id);
            }
        } else {
            if (categoryTreeView.selectedCategory?.selected?.id) {
                return displayCategoryPathList(categoryTreeView.selectedCategory.selected.id);
            }
        }
        return [];
    }, [hierarchyType, folderTreeView, categoryTreeView]);

    const rootTreeView = useMemo(() => {
        if (hierarchyType === GROUPING_VIEW_TYPE.FOLDER) {
            const { selectedFolder, data } = folderTreeView.folderList;

            if (!folderTreeView?.folderList?.data) return null;

            if (selectedFolder?.folder?.id) {
                const rootDrive = folderTreeView?.driveList?.data?.find(
                    (drive) => drive.id === selectedFolder.folder.driveId,
                );

                if (!rootDrive) return null;
                return folderTreeView.folderList?.data?.[rootDrive.rootFolder?.id] ?? null;
            }
        } else {
            const { selectedCategory, categoryMap } = categoryTreeView;
            if (selectedCategory?.selected?.id) {
                const selected = categoryMap[selectedCategory?.selected?.id];

                if (!selected) return null;
                if (selected.type === "uncategorized") return selected;
                return categoryMap[selected.groupId];
            }
        }
        return null;
    }, [hierarchyType, folderTreeView, categoryTreeView]);

    return (
        <>
            <Grid container alignItems={"center"}>
                {hierarchyType === GROUPING_VIEW_TYPE.FOLDER ? (
                    <ImageDriveOn width={16} height={16}></ImageDriveOn>
                ) : (
                    <ImageCategoryGroupOn width={16} height={16} />
                )}
                {breadcrumbList?.length > 0 && (
                    <Breadcrumbs aria-label="breadcrumb" separator={<IconSeparator size={8} />}>
                        {breadcrumbList.length >= 2 && (
                            <Button
                                variant={"text"}
                                size={"small"}
                                color={"greySecondary"}
                                sx={{ minWidth: 0 }}
                                onClick={() => {
                                    if (hierarchyType === GROUPING_VIEW_TYPE.FOLDER) {
                                        onClickFolderTree(rootTreeView, false, false, true);
                                    } else {
                                        onClickTreeItem(rootTreeView);
                                    }
                                }}
                            >
                                {rootTreeView?.name}
                            </Button>
                        )}
                        {breadcrumbList.length >= 3 && (
                            <Menu
                                width={160}
                                placement={"right"}
                                type={"icon"}
                                label={<IconMoreHoriz size={16} />}
                                color={"inherit"}
                                size={"small"}
                                list={breadcrumbList.slice(1, breadcrumbList.length - 1)}
                                needTranslation={false}
                            />
                        )}
                        {breadcrumbMenu?.length >= 1 ? (
                            <Menu
                                width={160}
                                placement={"right"}
                                label={breadcrumbList[breadcrumbList.length - 1]?.name}
                                color={"inherit"}
                                size={"small"}
                                endIcon={<IconArrowDropDown size={20} />}
                                endOpenIcon={<IconArrowDropUp size={20} />}
                                list={breadcrumbMenu ?? undefined}
                            />
                        ) : (
                            <Button
                                variant={"text"}
                                size={"small"}
                                color={"greySecondary"}
                                sx={{ minWidth: 0 }}
                                onClick={() => {
                                    if (hierarchyType === GROUPING_VIEW_TYPE.FOLDER) {
                                        onClickFolderTree(rootTreeView, false, false, true);
                                    } else {
                                        onClickTreeItem(rootTreeView);
                                    }
                                }}
                            >
                                {breadcrumbList[breadcrumbList.length - 1]?.name}
                            </Button>
                        )}
                    </Breadcrumbs>
                )}
            </Grid>
        </>
    );
};

export default TreeViewBreadcrumb;
