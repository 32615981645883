import { Trans, useTranslation } from "react-i18next";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack } from "@mzc-pdc/ui";
import theme from "@styles/theme";
import TextField from "@components_v2/text-field/text-field";
import Typography from "@components_v2/typography/typography";
import * as React from "react";
import { GroupIcon } from "@features/comments/groups/groups.styled";
import { useMemo, useState } from "react";
import validateInput from "@cores/validateInput";
import { COMMENT_GROUP_TITLE_MAX_LENGTH } from "@constants";

export const DialogDeleteGroup = ({ data, isLoading, onSubmit, onClose }) => {
    const { t } = useTranslation();
    const [editValue, setEditValue] = React.useState({ name: null });

    const onHandleChangeEditValue = (key, value) => {
        setEditValue({ ...editValue, [key]: value });
    };

    const onHandleSubmit = () => {
        onSubmit(editValue);
    };

    const isDisabled = useMemo(() => editValue?.name !== data?.name, [editValue, data]);

    return (
        <Dialog
            variant={"basic"}
            open={true}
            onClose={() => onClose()}
            sx={{ ".MuiPaper-root": { maxWidth: "480px" } }}
        >
            <DialogTitle>
                <Stack direction={"row"} gap={0.5} alignItems={"center"}>
                    {t(
                        `common::msg::Are you sure you want to delete the private comment?`,
                        "Are you sure you want to delete the private comment?",
                    )}
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Grid container flexDirection={"column"} gap={2.5}>
                    <Grid item>
                        <Typography variant={"body1"}>
                            <Trans
                                i18nKey={`common::msg::If you delete <0>"{{name}}"</0>, all messages will be deleted and cannot be restored after deletion.`}
                                values={{
                                    name: data?.name,
                                }}
                                components={[<Typography variant={"body1_500"} color={theme.palette.warning.main} />]}
                            />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Stack direction={"column"} gap={1}>
                            <Box>
                                <Typography variant={"body1_500"} required={true} requiredSize={"small"}>
                                    {t(`common::label::Private comment name`)}
                                </Typography>
                            </Box>
                            <Box>
                                <TextField
                                    value={editValue.name}
                                    onChange={(e) => onHandleChangeEditValue("name", e.target.value)}
                                    inputProps={{
                                        startAdornment: <GroupIcon isPublic={false} iconSize={24} />,
                                    }}
                                    placeholder={t(`common::msg::Please enter a private comment name.`)}
                                />
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <>
                    <Button variant={`text`} onClick={() => onClose()} color={`greySecondary`}>
                        {t(`common::label::Cancel`)}
                    </Button>
                    <Button
                        variant={`contained`}
                        disabled={isLoading || isDisabled}
                        loading={isLoading}
                        onClick={(e) => {
                            e.stopPropagation();
                            onHandleSubmit();
                        }}
                        color={`error`}
                    >
                        {t(`common::label::Delete private comment`)}
                    </Button>
                </>
            </DialogActions>
        </Dialog>
    );
};
