import React, {FC, useCallback} from "react";
import {StyledAssetTypeFlag} from "./asset-type.styled";
import {Grid} from "@mui/material";

import {
  IconAudio,
  IconCaption,
  IconFile,
  IconImage,
  IconVideo,
  IconPhotoType,
  IconContentsVideo,
} from "@mzc-cloudplex/icons";
import {MEDIA_TYPES} from "@constants";

const AssetTypeFlag = ({minWidth, width, height, type}) => {
  const getBackgroundColor = useCallback((type) => {
    switch (type) {
      case MEDIA_TYPES.VIDEO:
        return "#405fff";
      case MEDIA_TYPES.AUDIO:
        return "#e86894";
      case MEDIA_TYPES.FILE:
        return "#3f585a";
      case MEDIA_TYPES.IMAGE:
        return "#009ea0";
      default:
        return "#e07f2d";
    }
  }, []);

  return (
    <StyledAssetTypeFlag
      sx={{
        minWidth,
        width,
        height,
        color: `#fff`,
        backgroundColor: getBackgroundColor(type),
      }}
    >
      {type === "VIDEO" && <IconContentsVideo size={12}/>}
      {type === "AUDIO" && <IconAudio size={12}/>}
      {type === "FILE" && <IconFile size={12}/>}
      {type === "IMAGE" && <IconPhotoType size={12}/>}
      {type === "CAPTION" && <IconCaption size={12}/>}
      {type === "TEXT" && <IconCaption size={12}/>}
      {type === undefined && false && <IconFile size={12}/>}
    </StyledAssetTypeFlag>
  );
};

export default AssetTypeFlag;
