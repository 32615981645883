import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import thunk from "redux-thunk";

import megazoneCloudMediaApp from "./reducers";

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });

const middleware =
    process.env.REACT_APP_NOT_SECRET_CODE &&
    (process.env.REACT_APP_NOT_SECRET_CODE.trim() === "development" ||
        process.env.REACT_APP_NOT_SECRET_CODE.trim() === "local" ||
        process.env.REACT_APP_NOT_SECRET_CODE.trim() === "qa")
        ? composeEnhancers(applyMiddleware(thunk))
        : applyMiddleware(thunk);

const store = createStore(megazoneCloudMediaApp, middleware);

export default store;
