import moment from "moment";

const formatDuration = (milliseconds, timeFormat) => {
    if(!milliseconds) {
        return '00:00:00';
    }

    if (!timeFormat) {
        timeFormat = 'HH:mm:ss';
    }
    
    return moment.utc(milliseconds).format(timeFormat);
};

export default formatDuration;