import menuService from "@services/menuService";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import equal from "deep-equal";
import * as jobStatus from "@cores/jobStatus";
import { cancelTranscodeAllSync } from "@modules/upload";
import { useTranslation } from "react-i18next";
import Logo from "@components/header/Logo";
import SelectStage from "@components/navigator/components/SelectStage";
import { User } from "@components/user";

const HeaderForViewer = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { spaceId, projectId } = menuService.parse(pathname);

    const user = useSelector(({ user }) => user);
    const settings = useSelector(({ settings }) => settings);
    const upload = useSelector(({ upload }) => upload);
    const stage = useSelector(({ stage }) => stage);

    const [showStageLayer, setShowStageLayer] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [headerTheme, setHeaderTheme] = useState(false);
    const [activeStage, setActiveStage] = useState(undefined);
    const [activeProject, setActiveProject] = useState(undefined);

    const handleUnload = () => {
        const cancelTargetJobs = upload.jobs.data.filter(
            (v) => jobStatus.getValuesLowerThan(jobStatus.values.SUBMITTED).indexOf(v.status) > -1,
        );
        if (cancelTargetJobs.length > 0) {
            dispatch(cancelTranscodeAllSync(cancelTargetJobs));
        }
    };

    const handleBeforeunload = (e) => {
        const cancelTargetJobs = upload.jobs.data.filter(
            (v) => jobStatus.getValuesLowerThan(jobStatus.values.SUBMITTED).indexOf(v.status) > -1,
        );

        if (cancelTargetJobs.length > 0) {
            const message = t(`common::msg::${"There is work in progress. Would you really go out?"}`);
            (e || window.event).returnValue = message;

            return message;
        }
    };

    useEffect(() => {
        window.addEventListener("beforeunload", handleBeforeunload);
        window.addEventListener("unload", handleUnload);
    }, []);

    useEffect(() => {
        if (!settings?.stage) return;

        const headerThemeOfSettings = settings.stage.General.headerTheme;
        if (headerThemeOfSettings?.value) {
            const headerThemeJSON = JSON.parse(headerThemeOfSettings.value);
            if (!equal(headerThemeJSON, headerTheme)) {
                localStorage.setItem(
                    "headerTheme",
                    JSON.stringify({
                        stageId: spaceId,
                        data: headerThemeJSON,
                    }),
                );
                setHeaderTheme(headerThemeJSON);
            }
        }
    }, [settings?.stage?.General]);

    useEffect(() => {
        if (!user?.stages?.data || !projectId) return;
        const activeStage = user.stages.data.filter((v) => v.id === spaceId)[0];
        if (activeStage) {
            const activeProject = activeStage.projects
                ? activeStage.projects.filter((v) => v.id === projectId)[0]
                : null;

            setActiveStage(activeStage);
            setActiveProject(activeProject);
            setShowStageLayer(false);
        }
    }, [user, projectId]);

    return (
        <React.Fragment>
            <header
                className={"header"}
                style={
                    headerTheme
                        ? {
                              backgroundColor: headerTheme.color?.bgColor,
                              color: headerTheme.color?.textColor,
                              fill: headerTheme.color?.textColor,
                          }
                        : {}
                }
            >
                <div>
                    <Logo
                        to={`${menuService.paths.project(spaceId, projectId)}/dashboard`}
                        imgSrc={headerTheme?.ci}
                        fill={headerTheme?.color?.textColor}
                    />
                </div>
                <div className={`utils ${showMobileMenu ? "utils-open" : ""}`}>
                    <SelectStage />
                    <div className="utils-menu utils-profile">
                        <User />
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
};

export default HeaderForViewer;
