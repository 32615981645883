import React from 'react';
import { withTranslation } from 'react-i18next';

class InactiveByErrorTooltip extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive : false
        };
    }

    onInputToogleActive = () => {
        this.setState({
            isActive : !this.state.isActive
        });
    }
    render = () => {
        return (
            <span className={"message-error-tooltip"} data-tooltip={this.props.message} >
                <i className={"sprite sprite-error"}></i>
            </span>
        );
    }
};

export default withTranslation()(InactiveByErrorTooltip);