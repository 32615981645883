import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as downloadApi from "./apis/download";

export const requestDownload = createAsyncThunk("download/requestDownload", async (arg, thunkApi) => {
    const { stage, project } = thunkApi.getState();
    return downloadApi.requestDownload(stage.id, stage.endpoint, project.id, arg);
});

export const requestDownloadFromShared = createAsyncThunk("download/requestDownload", async (arg, thunkApi) => {
    const { stage, project } = thunkApi.getState();
    const { resourceToken } = arg;
    return downloadApi.requestDownloadFromShared(stage.id, stage.endpoint, project.id, arg, resourceToken);
});

export const getDownloadStatus = createAsyncThunk("download/getDownloadStatus", async (arg, thunkApi) => {
    const { downloadId } = arg;
    const { stage, project } = thunkApi.getState();
    return downloadApi.getDownloadStatus(stage.id, stage.endpoint, project.id, downloadId);
});

const downloadSlice = createSlice({
    name: "download",
    initialState: {
        requestDownload: {
            pending: false,
            error: false,
            data: null,
        },
    },
    reducers: {
        requestDownloadReceived(state, action) {
            return {};
        },
        getDownloadStatusReceived(state, action) {
            return {};
        },
    },
    extraReducers: {
        [requestDownload.fulfilled]: (state, action) => {
            // requestDownload success
            const { data } = action.payload;
            return {
                requestDownload: {
                    pending: false,
                    error: false,
                    data,
                },
            };
        },
        [requestDownload.rejected]: (state, action) => {
            // requestDownload fail
            return {
                requestDownload: {
                    pending: false,
                    error: true,
                    data: null,
                },
            };
        },
        [requestDownload.pending]: (state, action) => {
            // requestDownload pending
            return {
                requestDownload: {
                    pending: true,
                    error: false,
                    data: null,
                },
            };
        },
        [getDownloadStatus.fulfilled]: (state, action) => {
            // getDownloadState success
            const { data } = action.payload;
            return {
                getDownloadStatus: {
                    pending: false,
                    error: false,
                    data,
                },
            };
        },
        [getDownloadStatus.rejected]: (state, action) => {
            // getDownloadState fail
            return {
                getDownloadStatus: {
                    pending: false,
                    error: true,
                    data: null,
                },
            };
        },
        [getDownloadStatus.pending]: (state, action) => {
            // getDownloadState fail
            return {
                getDownloadStatus: {
                    pending: true,
                    error: false,
                    data: null,
                },
            };
        },
    },
});

export const downloadActions = downloadSlice.actions;
const downloadReducer = downloadSlice.reducer;

export const requestDownloadReceived = downloadActions.requestDownloadReceived;
export const getDownloadStatusReceived = downloadActions.getDownloadStatusReceived;

export default downloadReducer;
