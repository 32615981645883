import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import fp from "lodash/fp";

/* modules */
import { getUser } from "../../modules/userManagement";
import { IconInfoCircle } from "@mzc-cloudplex/icons";
import { Popper } from "@mui/material";

const TooltipUserInfo = ({ target, id, type }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const stateSelector = createSelector(
        (state) => state.userManagement,
        (userManagement) => ({
            pending: fp.get(`pending`, userManagement),
            error: fp.get(`error`, userManagement),
        }),
    );
    const state = useSelector(stateSelector);
    const [selectedUser, setSelectedUser] = useState(null);
    const [layerUserInfo, setLayerUserInfo] = useState(null);
    const [layerUserInfoDetail, setLayerUserInfoDetail] = useState([]);

    const handleGetUserInfo = useCallback(
        (e, object) => {
            e.stopPropagation();
            e.preventDefault();
            setAnchorEl(anchorEl ? null : e.currentTarget);
            setSelectedUser(object);
            console.log("here");
            dispatch(getUser(id)).then((res) => {
                const megazoneUser = fp.getOr({}, `megazoneUser`, res);
                setLayerUserInfo(megazoneUser);
                // console.log('??', megazoneUser.details)
                const detailKeys = Object.keys(megazoneUser.details || []);
                if (detailKeys && detailKeys.length > 0) {
                    const details = [];
                    detailKeys.map((v) => {
                        details.push({
                            name: v,
                            value: megazoneUser.details[v],
                        });
                    });
                    setLayerUserInfoDetail(details);
                }
            });
        },
        [dispatch, id],
    );

    useEffect(
        () => () => {
            setSelectedUser(null);
            setLayerUserInfo(null);
            setLayerUserInfoDetail(null);
        },
        [],
    );

    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);
    const tooltipId = open ? "simple-popper" : undefined;

    const handleClose = () => {
        setSelectedUser(null);
        setAnchorEl(null);
    };

    return (
        <div className="popper-tooltip">
            <button
                aria-describedby={tooltipId}
                type="button"
                className="btn btn-icon-solid"
                hidden={!id || id.toUpperCase() === "SYSTEM"}
                onClick={(e) => handleGetUserInfo(e, target)}
            >
                {tooltipId ? (
                    <IconInfoCircle size={16} htmlColor={`#11193f`} />
                ) : (
                    <IconInfoCircle size={16} htmlColor={`#6f6f83`} />
                )}
            </button>

            <OutsideClickHandler display={"inline-block"} onOutsideClick={handleClose}>
                <Popper id={tooltipId} open={open} anchorEl={anchorEl} placement={type ? "bottom-end" : "bottom"}>
                    <div className={"popper-tooltip-content"}>
                        {(fp.get(`error`, state) && (
                            <div className="d-flex align-items-center">
                                <i className={"sprite"}>
                                    <IconInfoCircle size={16} htmlColor={`#ef4016`} />
                                </i>
                                <p>This user does not exist.</p>
                            </div>
                        )) || (
                            <>
                                {(fp.get(`pending`, state) && (
                                    <div className="d-flex align-items-center justify-content-center">
                                        <span className="loading-inline-light" />
                                    </div>
                                )) || (
                                    <ul className={"list-dot"}>
                                        <li className="dot">
                                            <p className={"text-secondary"}>{t(`common::label::${"Display Name"}`)}</p>
                                            <div className="text-default">
                                                {fp.get(`name.length`, layerUserInfo)
                                                    ? fp.get(`name`, layerUserInfo)
                                                    : `-`}
                                            </div>
                                        </li>

                                        <li className="dot">
                                            <p className={"text-secondary"}>{t(`common::label::${"Department"}`)}</p>
                                            <div className="text-default">
                                                {fp.get(`dept.length`, layerUserInfo)
                                                    ? fp.get(`dept`, layerUserInfo)
                                                    : `-`}
                                            </div>
                                        </li>

                                        <li className="dot">
                                            <p className={"text-secondary"}>{t(`common::label::${"Phone Number"}`)}</p>
                                            <div className="text-default">
                                                {fp.get(`phoneNumber.length`, layerUserInfo)
                                                    ? fp.get(`phoneNumber`, layerUserInfo)
                                                    : `-`}
                                            </div>
                                        </li>

                                        {layerUserInfoDetail &&
                                            layerUserInfoDetail.map((v, i) => (
                                                <li className="dot" key={`key${i}`}>
                                                    <p className={"text-secondary"}>{v.name}</p>
                                                    <div className="text-default">{v.value || "-"}</div>
                                                </li>
                                            ))}
                                    </ul>
                                )}
                            </>
                        )}
                    </div>
                </Popper>
            </OutsideClickHandler>
        </div>
    );
};
TooltipUserInfo.propTypes = {
    target: PropTypes.object,
    id: PropTypes.string,
    type: PropTypes.string,
};

export default TooltipUserInfo;
