import {
    axios
} from "../../cores/axiosWrapper";

const VideoApi = {
    getStatusAsync: async function(stageId, apiEndpoint, projectId, id) {
        const response = await axios.get(`${apiEndpoint}/videos/${id}/status`, {
            headers : {
                stageId,
                projectId
            }
        });    
        return response;
    },
    activateAsync: async function(stageId, apiEndpoint, projectId, id, version) {
        return await axios.put(`${apiEndpoint}/videos/${id}/activate`, null, {
            params : {
                version
            },
            headers : {
                stageId,
                projectId,
                'Content-Type' : 'application/json'
            }
        });    
    },
    inactivateAsync: async function(stageId, apiEndpoint, projectId, id, version) {
        return await axios.put(`${apiEndpoint}/videos/${id}/inactivate`, null, {
            params : {
                version
            },
            headers : {
                stageId,
                projectId,
                'Content-Type' : 'application/json'
            }
        });    
    }
};

export default VideoApi;