import { styled } from "@mui/material/styles";
import { Popper } from "@mzc-pdc/ui";
import InputBase from "@mui/material/InputBase";
import ButtonBase from "@mui/material/ButtonBase";

export const StyledAutocompletePopper = styled("div")(({ theme }) => ({
    margin: `0 auto`,
    ".MuiPaper-root ": {
        boxShadow: `none`,
    },
}));

export const StyledPopper = styled(Popper)(({ theme }) => ({
    width: `230px`,
    zIndex: theme.zIndex.modal,
    backgroundColor: "#fff",
    paddingTop: `5px`,
    borderRadius: `0 0 3px 3px`,
    boxShadow: ` 0 3px 6px 0 rgba(0, 0, 0, 0.16)`,
    "&:after": {
        content: '""',
        display: `block`,
        position: `absolute`,
        left: `0`,
        top: `-5px`,
        right: `0`,
        height: `10px`,
        backgroundColor: theme.palette.common.white,
    },
    ".MuiAutocomplete-root": {
        padding: `0 8px 8px`,
    },
    ".MuiAutocomplete-listbox": {
        maxHeight: `160px`,
        fontSize: `13px`,
        fontWeight: `normal`,
        overflow: `overlay`,
        li: {
            display: `flex`,
            height: `36px`,
            padding: `8px`,
            color: theme.palette.secondary[800],
            boxSizing: `border-box`,
            "&:hover": {
                backgroundColor: `${theme.palette.greySecondary[50]}80`,
            },
            "&.selected": {
                backgroundColor: `${theme.palette.greySecondary.A100}66`,
            },
        },
    },
}));

export const StyledInput = styled(InputBase)(({ theme }) => ({
    width: "100%",
    height: `40px`,
    padding: `0 16px`,
    borderRadius: `3px`,
    border: `1px solid ${theme.palette.secondary.main}`,
    ".MuiSvgIcon-root ": {
        marginRight: `16px`,
    },
}));

export const Button = styled(ButtonBase, {
    shouldForwardProp: (prop) => !["isOpen", "navOpen"].includes(String(prop)),
})(({ theme, open, navOpen }) => ({
    width: "100%",
    textAlign: "left",
    padding: `12px`,
    borderRadius: `3px`,
    transition: theme.transitions.create("background-color ", {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.shortest,
    }),
    ".MuiBox-root": {
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
    },
    "&:hover": {
        boxShadow: ` 0 3px 6px 0 rgba(0, 0, 0, 0.16)`,
        backgroundColor: theme.palette.common.white,
        ".MuiBox-root": {
            backgroundColor: `${theme.palette.secondary[800]}14`,
        },
    },
    ...(open && {
        borderRadius: `3px 3px 0 0`,
        boxShadow: `none`,
        backgroundColor: theme.palette.common.white,
    }),
    ...(!navOpen && {
        marginTop: `4px`,
        marginBottom: `4px`,
        padding: `8px`,
        ".MuiGrid-root": {
            flexWrap: `nowrap`,
            ".MuiGrid-item": {
                "&:not(:has(.MuiAvatar-root))": {
                    width: 0,
                    opacity: 0,
                },
            },
        },
    }),
    ...(navOpen && {
        ".MuiGrid-root": {
            flexWrap: `nowrap`,
            ".MuiGrid-item": {
                "&:not(:has(.MuiAvatar-root))": {
                    width: `auto`,
                    opacity: 1,
                },
            },
        },
    }),
}));
