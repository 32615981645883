import {handleActions, createAction} from 'redux-actions';

import {
    axios,
    axiosWithoutHeaders
} from "../cores/axiosWrapper";

function getProjectRolesInStageAPI(apiEndpoint, projectId) {
    if(!apiEndpoint)
        return;
    return axios.get(`${apiEndpoint}/me/project-actions`, {
        headers: {
            projectId
        }
    });
}

function getStageRolesInStageAPI(stageId, apiEndpoint) {
    return axios.get(`${apiEndpoint}/me/stage-actions`, {
        headers: {
            stageId
        }
    });
}

const RESET = 'RESET';

const GET_STAGE_ROLES_SUCCESS = 'GET_STAGE_ROLES_SUCCESS';
const GET_STAGE_ROLES_PENDING = 'GET_STAGE_ROLES_PENDING';
const GET_STAGE_ROLES_FAILURE = 'GET_STAGE_ROLES_FAILURE';

const GET_PROJECT_ROLES_SUCCESS = 'GET_PROJECT_ROLES_SUCCESS';
const GET_PROJECT_ROLES_PENDING = 'GET_PROJECT_ROLES_PENDING';
const GET_PROJECT_ROLES_FAILURE = 'GET_PROJECT_ROLES_FAILURE';

//reducer
const initialState = {
    stages : null,
    projects : null
};

export default handleActions({
    [GET_STAGE_ROLES_PENDING]: (state, action) => {
        const stages = {
            ...state.stages
        };
        const existingStage = state.stages && state.stages[action.id] || {};
        const stage = {
            ...existingStage,
            error: false,
            pending: true
        };
        stages[action.id] = stage;
        return {
            ...state,
            stages: {
                ...stages
            }
        };
    },
    [GET_STAGE_ROLES_SUCCESS]:(state, action) => {
        let { id, data} = action.payload;
        const stages = {
            ...state.stages
        };
        const existingStage = state.stages && state.stages[id] || {};
        const stage = {
            ...existingStage,
            data: data,
            error: false,
            pending: false
        };
        stages[id] = stage;
        return {
            ...state,
            stages: {
                ...stages
            }
        };
    },
    [GET_STAGE_ROLES_FAILURE]: (state, action) => {
        const stages = {
            ...state.stages
        };
        const existingStage = state.stages && state.stages[action.id] || {};
        const stage = {
            ...existingStage,
            error: true,
            pending: false
        };
        stages[action.id] = stage;
        return {
            ...state,
            stages: {
                ...stages
            }
        };
    },
    [GET_PROJECT_ROLES_PENDING]: (state, action) => {
        const projects = {
            ...state.projects
        };
        const existingProject = state.projects && state.projects[action.id] || {};
        const project = {
            ...existingProject,
            error: false,
            pending: true
        };
        projects[action.id] = project;
        return {
            ...state,
            projects: {
                ...projects
            }
        };
    },
    [GET_PROJECT_ROLES_SUCCESS]:(state, action) => {
        let { id, data} = action.payload;
        const projects = {
            ...state.projects
        };
        const existingProject = state.projects &&state.projects[id] || {};
        const project = {
            ...existingProject,
            data: data,
            error: false,
            pending: false
        };
        projects[id] = project;
        return {
            ...state,
            projects: {
                ...projects
            }
        };
    },
    [GET_PROJECT_ROLES_FAILURE]: (state, action) => {
        const projects = {
            ...state.projects
        };
        const existingProject = state.projects &&state.projects[action.id] || {};
        const project = {
            ...existingProject,
            error: true,
            pending: false
        };
        projects[action.id] = project;
        return {
            ...state,
            projects: {
                ...projects
            }
        };
    }
}, initialState);

export const getProjectRoles = (stageId, stageEndpoint, projectId) => (dispatch) => {
    if(!stageEndpoint)
        return;
        
    dispatch({
        type: GET_PROJECT_ROLES_PENDING,
        payload: {
            id: projectId
        }
    });
    return getProjectRolesInStageAPI(stageEndpoint, projectId).then(
        (response) => {
            dispatch({
                type: GET_PROJECT_ROLES_SUCCESS,
                payload: {
                    ...response,
                    id: projectId                 
                }
            });
        }
    ).catch(error => {
        dispatch({
            type: GET_PROJECT_ROLES_FAILURE,
            payload: {
                ...error,
                id: projectId
            }
        });
    });
};

export const getStageRoles = (stageId, stageEndpoint) => (dispatch) => {
    dispatch({
        type: GET_STAGE_ROLES_PENDING,
        payload: {
            id: stageId
        }});
    return getStageRolesInStageAPI(stageId, stageEndpoint).then(
        (response) => {
            dispatch({
                type: GET_STAGE_ROLES_SUCCESS,
                payload: {
                    ...response,
                    id: stageId
                }
            });
        }
    ).catch(error => {
        dispatch({
            type: GET_STAGE_ROLES_FAILURE,
            payload: {                
                ...error,
                id: stageId
            }
        });
    });
};