import { styled, CSSObject, alpha } from "@mui/material/styles";
import {
    TreeView as MuiTreeView,
    TreeItem as MuiTreeItem,
    TreeViewProps as MuiTreeViewProps,
    treeItemClasses,
} from "@mui/lab";

export const StyledTreeViewContainer = styled("div")(({ theme, fold, isFold }) => ({
    position: `relative`,
    height: `100%`,
}));
export const StyledTreeViewWrap = styled("div")(({ theme, fold, isFold }) => ({
    display: `inline-flex`,
    height: `100%`,
    position: `relative`,
    boxSizing: `border-box`,

    ...(fold
        ? {
              transition: `width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
              overflowY: `hidden`,
              paddingRight: `45px`,
              ...(isFold
                  ? {
                        width: `78px`,
                        ".MuiTreeView-root": {
                            width: 0,
                            whiteSpace: `nowrap`,
                            opacity: 0,
                            overflowY: `hidden`,
                            transition: `opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 200ms`,
                        },
                        ".TreeAnimationButton": {
                            minWidth: `40px`,
                            padding: `0 10px`,
                            // transition: `min-width 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
                            ".text": {
                                display: `none`,
                            },
                            ".MuiButton-startIcon": {
                                margin: 0,
                            },
                        },
                        ".TreeAnimationTextField": {
                            opacity: 0,
                            transition: `opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, width 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
                        },
                    }
                  : {
                        width: 385,
                        transition: `width 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
                        ".MuiTreeView-root": {
                            maxWidth: `340px`,
                            width: `100%`,
                            opacity: 1,
                            overflowY: `auto`,
                            transition: `opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 200ms`,
                        },
                        ".TreeAnimationTextField": {
                            opacity: 1,
                            transition: `opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, width 200ms cubic-bezier(0.4, 0, 0.2, 1) 200ms`,
                        },
                        ".TreeAnimationButton": {
                            // minWidth: `132px`,
                            // transition: `min-width 200ms cubic-bezier(0.4, 0, 0.2, 1) 300ms`,
                            ".text": {
                                display: `block`,
                            },
                        },
                        [theme.breakpoints.down("lg")]: {
                            position: `absolute`,
                            left: 0,
                            top: 0,
                            bottom: 0,
                            right: 0,
                            zIndex: 2,
                        },
                    }),
          }
        : {
              width: `100%`,
              flex: 1,
          }),
}));

export const StyledTreeViewContent = styled("div")(({ theme, isFold }) => ({
    display: `flex`,
    flexDirection: `column`,
    flexWrap: `nowrap`,
    width: `100%`,
    backgroundColor: theme.palette.common.white,
}));

export const StyledTreeViewFoldButton = styled("div")(({ theme, isFold }) => ({
    display: `inline-flex`,
    position: `absolute`,
    right: `0`,
    top: 0,
    bottom: 0,
    alignItems: `center`,
    justifyContent: `center`,
    "&:before,&:after": {
        content: '""',
        display: `block`,
        position: `absolute`,
        top: `0`,
        bottom: `0`,
        opacity: 0,
        transition: `opacity 200ms cubic-bezier(0.4, 0, 0.2, 1)`,
    },
    "&:before": {
        right: `13px`,
        width: 32,
        backgroundColor: theme.palette.common.white,
    },
    "&:after": {
        left: `50%`,
        width: 12,
        boxShadow: `-5px 0 6px 0 rgba(0, 0, 0, 0.1)`,
    },
    "&:hover": {
        "&:before,&:after": {
            opacity: 1,
        },
        button: {
            opacity: 1,
        },
    },
    [theme.breakpoints.down("lg")]: {
        "&:before,&:after": {
            opacity: 1,
        },
    },
    ...(isFold == true && {
        "&:before,&:after": {
            content: '""',
            display: `block`,
            position: `absolute`,
            top: `0`,
            bottom: `0`,
            transition: `opacity 200ms cubic-bezier(0.4, 0, 0.2, 1)`,
        },
        "&:before": {
            left: `0`,
            width: 13,
            backgroundColor: theme.palette.common.white,
        },
        "&:after": {
            left: `50%`,
            width: 12,
            boxShadow: `-5px 0 6px 0 rgba(0, 0, 0, 0.1)`,
        },
    }),
    button: {
        position: `relative`,
        zIndex: `1`,
        display: `inline-flex`,
        alignItems: `center`,
        justifyContent: `center`,
        margin: 0,
        padding: 0,
        border: 0,
        width: `26px`,
        height: `26px`,
        boxShadow: `0 3px 3px 0 rgba(0, 0, 0, 0.12)`,
        borderRadius: `100%`,
        transition: `opacity 200ms cubic-bezier(0.4, 0, 0.2, 1)`,
        ...(isFold
            ? {
                  opacity: 1,
                  backgroundColor: theme.palette.secondary[600],
                  color: theme.palette.common.white,
                  "&:hover": {
                      backgroundColor: theme.palette.greySecondary[700],
                  },
                  "&:active": {
                      backgroundColor: theme.palette.greySecondary[300],
                  },
              }
            : {
                  opacity: 0,
                  backgroundColor: theme.palette.common.white,
                  border: `1px solid ${theme.palette.greySecondary[100]}`,
                  "&:hover": {
                      backgroundColor: theme.palette.greySecondary[50],
                  },
                  [theme.breakpoints.down("lg")]: {
                      opacity: 1,
                  },
              }),
    },
}));

export const StyledTreeView = styled(MuiTreeView, {
    shouldForwardProp: (prop) => !["fold"].includes(String(prop)),
})(({ theme, fold }) => ({
    // height: 110,
    flexGrow: 1,
    width: `100%`,
    height: `100%`,
}));

export const StyledTreeItemRoot = styled(MuiTreeItem, {
    shouldForwardProp: (prop) => !["labelIcon"].includes(String(prop)),
})(({ theme }) => ({
    color: theme.palette.text.primary,
    [`& .${treeItemClasses.content}`]: {
        color: theme.palette.text.primary,
        borderTopRightRadius: theme.spacing(2.5),
        borderBottomRightRadius: theme.spacing(2.5),
        paddingRight: theme.spacing(1),
        border: "1px solid transparent",
        fontWeight: theme.typography.fontWeightRegular,
        boxSizing: `border-box`,
        paddingTop: `7px`,
        paddingBottom: `7px`,
        paddingLeft: `16px`,
        "&:hover, &.clicked": {
            backgroundColor: alpha(theme.palette.secondary.main, 0.04),
            [`& .${treeItemClasses.label}`]: {
                textDecoration: "underline",
            },
            "#menu-button": {
                opacity: 1,
                "&:hover": {
                    backgroundColor: alpha(theme.palette.secondary[800], 0.08),
                },
            },
        },
        "&.overCurrent": {
            backgroundColor: alpha(theme.palette.greySecondary.A100, 0.48),
            border: `dashed 1px ${theme.palette.greySecondary.A200}`,
        },
        "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
            backgroundColor: alpha(theme.palette.primary[100], 0.2),
            [`& .${treeItemClasses.label}`]: {
                fontWeight: "normal",
                color: theme.palette.primary.main,
            },
        },
        "&.Mui-focused": {
            "#menu-button": {
                "&:hover": {
                    backgroundColor: alpha(theme.palette.secondary[800], 0.12),
                },
            },
        },
        "&.Mui-selected": {
            "#menu-button": {
                "&:hover": {
                    backgroundColor: alpha(theme.palette.secondary[800], 0.16),
                },
            },
        },
        [".spacing"]: {
            minWidth: theme.spacing(2),
            width: theme.spacing(2),
        },
    },
    [`& .${treeItemClasses.label}`]: {
        paddingLeft: `0`,
        whiteSpace: `nowrap`,
        overflow: `hidden`,
        textOverflow: `ellipsis`,
        paddingRight: `12px`,
    },
    ".MuiTreeItem-content > .MuiBox-root ": {
        padding: `0 12px 0 4px`,
    },
    [`& .${treeItemClasses.iconContainer}`]: {
        marginRight: `8px`,
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 0,
        // [`& .${treeItemClasses.content}`]: {
        //   paddingLeft: theme.spacing(2),
        // },
    },
    "#menu-button": {
        opacity: 0,
        '&[aria-expanded="true"]': {
            opacity: 1,
        },
    },
}));
