import { Avatar } from "@mzc-pdc/ui";
import { IconAvata, IconAvatar } from "@mzc-cloudplex/icons";
import theme from "@styles/theme";
import * as React from "react";

export const RandomColoredAvatar = React.memo(({ id, name, size }) => {
    const randomColors = [
        "#ef4b4b",
        "#405fff",
        "#5d8fff",
        "#5f62e5",
        "#7182a6",
        "#e86894",
        "#3f485a",
        "#3f585a",
        "#ffaa31",
        "#009ea0",
        "#7047f3",
        "#ee266b",
        "#e07f2d",
        "#65a93b",
        "#397a11",
    ];

    const randomColor =
        id
            ?.toString()
            ?.split("")
            ?.reduce((acc, cur) => acc + cur.charCodeAt(0), 0) % randomColors.length;

    return (
        <Avatar sx={{ width: size, height: size, backgroundColor: "white" }}>
            <IconAvatar size={size} htmlColor={randomColors[randomColor] ?? theme.palette.secondary[500]} />
        </Avatar>
    );
});
