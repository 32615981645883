import {
    IconCheckDefaultCircle,
    IconContents,
    IconContentsMusic,
    IconContentsPeople,
    IconContentsPhoto,
    IconContentsVideo,
} from "@mzc-cloudplex/icons";
import { useState } from "react";
import { CONTENT_TYPE } from "@constants";
import { StyledContentTypeThumbnail } from "@features/thumbnail/content-thumbnail.styled";
import { Grid } from "@mzc-pdc/ui";
import theme from "@styles/theme";

const ContentThumbnail = ({
    variant,
    minWidth,
    width,
    height,
    contentType,
    src,
    status,
    margin,
    disableHover = true,
    isPreferred,
    ...restProps
}) => {
    const [error, setError] = useState(false);

    return (
        <StyledContentTypeThumbnail
            className={"content-thumbnail"}
            style={{
                minWidth,
                width,
                height,
                margin,
            }}
            variant={variant}
            status={status}
            {...restProps}
        >
            {isPreferred && (
                <Grid
                    item
                    sx={{
                        position: "absolute",
                        left: variant === "card" ? "8px" : "-4px",
                        top: variant === "card" ? "8px" : "-4px",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        backgroundColor: theme.palette.common.white,
                        borderRadius: "50%",
                        width: "13px",
                        height: "13px",
                    }}
                >
                    <IconCheckDefaultCircle size={14} color={"success"} />
                </Grid>
            )}
            {src && !error ? (
                <>
                    <img src={src} alt="" onError={() => setError(true)} draggable={false} />
                </>
            ) : (
                <>
                    <>
                        {contentType === CONTENT_TYPE.VIDEO && <IconContentsVideo width={28} height={28} />}
                        {contentType === CONTENT_TYPE.MUSIC && <IconContentsMusic width={28} height={28} />}
                        {contentType === CONTENT_TYPE.PEOPLE && <IconContentsPeople width={28} height={28} />}
                        {contentType === CONTENT_TYPE.PHOTO && <IconContentsPhoto width={28} height={28} />}
                        {contentType === CONTENT_TYPE.CUSTOM && <IconContents width={28} height={28} />}
                    </>
                </>
            )}
        </StyledContentTypeThumbnail>
    );
};

export default ContentThumbnail;
