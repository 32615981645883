const convertToBPS = (bit, decimalDigit = 2) => {
    const s = ['bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps', 'Pbps'];
    const e = Math.floor(Math.log(bit) / Math.log(1000));
    if(!s[e]) {
        return '0bps';
    }

    const bps = (bit / Math.pow(1000, e)).toFixed(decimalDigit);    
    return Number(bps) + "" + s[e];
};

export default convertToBPS;