import { ASSET_PREVIEW_STATUS, MEDIA_TYPES } from "@constants";
import React from "react";
import Tooltip from "@components_v2/Tooltip/tooltip";
import { Box, CircularProgress, Grid, IconButton, Stack, Typography } from "@mzc-pdc/ui";
import {
    IconCheckDefaultCircle,
    IconCheckLarge,
    IconErrorCircle,
    ImageDefaultFileDark,
    ImageDefaultImageDark,
    ImageDefaultImageSmallDark,
    ImageErrorInfo,
} from "@mzc-cloudplex/icons";
import theme from "@styles/theme";
import { Trans, useTranslation } from "react-i18next";
import { CircledIcon } from "@components_v2/icon";
import formatDuration from "@cores/formatDuration";

const AssetElementPreviewByStatus = ({
    previewStatus,
    mediaType,
    type,
    isPreferred,
    width,
    height,
    thumbnailProps,
    thumbnailTime,
}) => {
    const { t } = useTranslation();
    const DefaultIcon = () => {
        if (mediaType === MEDIA_TYPES.IMAGE) {
            return <ImageDefaultImageSmallDark width={width} height={height} />;
        } else return null;
    };
    const PreviewProgressIcon = () => {
        return (
            <Tooltip title={t(`common::msg::Preview image Creating...`)} maxWidth={90}>
                <button>
                    <DefaultIcon />
                </button>
            </Tooltip>
        );
    };

    const PreviewErrorIcon = () => {
        return (
            <Tooltip title={t(`common::msg::Failed to load preview image. Please again.`)} maxWidth={201}>
                <IconButton size={"small"} circled onClick={() => {}}>
                    <IconErrorCircle size={12} color={"error"} />
                </IconButton>
            </Tooltip>
        );
    };

    const IconByPreviewStatus = () => {
        switch (previewStatus) {
            case ASSET_PREVIEW_STATUS.IN_PROGRESS:
                return <PreviewProgressIcon />;
            case ASSET_PREVIEW_STATUS.ERROR:
                return (
                    <Stack direction={"column"} alignItems={"center"}>
                        <PreviewErrorIcon />
                        <DefaultIcon />
                    </Stack>
                );
            case ASSET_PREVIEW_STATUS.NOT_SUPPORTED:
                return <DefaultIcon />;
            default:
                return <DefaultIcon />;
        }
    };

    return (
        <Box
            sx={{
                width: `100%`,
                height: `100%`,
                display: "flex",
                position: "static",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "2px",
            }}
        >
            {isPreferred && (
                <Grid
                    container
                    justifyContent={"space-between"}
                    sx={{
                        position: `absolute`,
                        left: 0,
                        top: 0,
                        right: 0,
                    }}
                    p={0.5}
                >
                    <IconCheckDefaultCircle size={16} htmlColor={theme.palette.success.main} />
                </Grid>
            )}
            <IconByPreviewStatus />
            {type === "THUMBNAILS" && <span className="time">{formatDuration(thumbnailTime * 1000)}</span>}
        </Box>
    );
};

export default AssetElementPreviewByStatus;
