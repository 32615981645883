import React, { useEffect } from "react";
import { Route, Switch, useLocation, withRouter } from "react-router-dom";
import routes from "./cores/routes";
import routesForCMS from "./cores/routesForCMS";
import RouteWithSubRoutes from "./components/routeWithSubRoutes";
import Login from "./routes/providers/Login";
import ExchangeCode from "./routes/saml/exchangeCode";

import LoginRedirection from "./routes/login/LoginRedirection";
import InitiateLXHausys from "./routes/login/InitiateLXHausys";
import ConfirmUser from "./routes/confirm-user/ConfirmUser";
import Error404 from "./routes/errors/Error404";
import Error500 from "./routes/errors/Error500";
import InvalidUser from "./routes/errors/InvalidUser";
import Todos from "./routes/todos";
import Welcome from "./routes/welcome/Welcome";
import WelcomeLxHausys from "@routes/saml/welcome-lx-hausys";
import theme from "./styles/theme";
import GetAccessTokenPopup from "./routes/oauth2/cpd/GetAccessTokenPopup";

import {
    LayoutForCms,
    LayoutForError,
    LayoutForLxWelcome,
    LayoutForNoting,
    LayoutForPreview,
    LayoutForViewer,
    LayoutForWelcome,
} from "./components/layout";
import CacheBuster from "./CacheBuster";
import config from "./config/config";
import "url-search-params-polyfill"; //new URLSearchParams polyfill
import { CssBaseline, PDCThemeProvider } from "@mzc-pdc/ui";
import { useMediaQuery } from "@mui/material";
import "./extensions/array-extensions";

import { siteMode } from "./cores/siteMode";
import { checkIsMobile } from "./utils/checkmobile";
import menuService from "@services/menuService";
import GlobalStyle from "@styles/global";
import LayoutForComponentV2 from "@components_v2/layout/LayoutForComponentV2";
import { LayoutForAdminV2 } from "@components_v2/layout";
import LayoutForComponentWithoutNav from "@components_v2/layout/LayoutForComponentWithoutNav";
import i18n from "@lib/i18n";
import { useSelector } from "react-redux";

const App = ({ location }) => {
    const isCms = siteMode.get() === "CMS";
    const isMobile = checkIsMobile();
    const { pathname } = useLocation();
    const { spaceId, projectId } = menuService.parse(location.pathname);

    const monitorUnloadBeforeUploadingRef = React.useRef(null);

    const commonComments = useSelector((state) => state.commonComments);
    const comments = useSelector((state) => state.comments);

    let Layout = LayoutForComponentV2;

    if (window.opener && window.name && window.name.indexOf("popup") > -1) {
        Layout = LayoutForPreview;
    } else if (location.pathname.indexOf(`/admin/spaces/${spaceId}`) !== -1 && !projectId) {
        Layout = LayoutForAdminV2;
    } else if (
        location.pathname.indexOf("/welcome-saml-test") !== -1 ||
        (location.pathname.indexOf("/welcome") !== -1 && window.location.hostname.indexOf("lxhausys.cloudplex.") !== -1)
    ) {
        Layout = LayoutForLxWelcome;
    } else if (location.pathname.indexOf("/welcome") !== -1 || location.pathname.indexOf("/auth-login") !== -1) {
        Layout = LayoutForWelcome;
    } else if (
        location.pathname.indexOf("/megazone/login") !== -1 ||
        location.pathname === "/spaces" ||
        location.pathname === "/" ||
        location.pathname.indexOf("/error/invaliduser") !== -1 ||
        location.pathname.indexOf("/confirmUser") !== -1 ||
        location.pathname.indexOf("/jwt/exchange") !== -1 ||
        location.pathname.indexOf("/initiate-lxhausys") !== -1
    ) {
        Layout = LayoutForNoting;
    } else if (location.pathname.indexOf("/error/") !== -1) {
        if (location.pathname.indexOf("/accessdenied") !== -1 || location.pathname.indexOf("/deleted") !== -1) {
            Layout = LayoutForViewer;
        } else {
            Layout = LayoutForError;
        }
    } else if (
        location.pathname.indexOf("preview") !== -1 ||
        location.pathname.indexOf("/player") !== -1 ||
        location.pathname.indexOf("/play") !== -1
    ) {
        Layout = LayoutForPreview;
    } else if (location.pathname.indexOf("/snsLogin") !== -1 || location.pathname.indexOf("/cloudplex/oauth2") !== -1) {
        Layout = LayoutForPreview;
    } else if (location.pathname.indexOf("/images-viewer") !== -1) {
        Layout = LayoutForViewer;
    } else if (isCms) {
        Layout = LayoutForCms;
    } else if (
        ["/collections", "/assets/", "/archived/", "/contents/", "/storage/"].findIndex(
            (path) => location.pathname.indexOf(path) !== -1,
        ) >= 0
    ) {
        // Note: container 100vh height를 제거한 layout 적용
        Layout = LayoutForComponentV2;
    } else if (location.pathname.indexOf("/vocabularies") !== -1) {
        Layout = LayoutForComponentWithoutNav;
    } else if (location.pathname.indexOf("/event-triggers") !== -1) {
        Layout = LayoutForComponentV2;
    }

    const loadThirdPartyLib = (elementType, params) => {
        const element = document.createElement(elementType);
        Object.keys(params).some((key) => {
            element[key] = params[key];
        });
        document.body.appendChild(element);
    };

    const handleWindow = () => {
        let body = document.querySelector("body");
        if (window.innerWidth > body.clientWidth + 5) {
            body.classList.add("has-scrollbar");
            body.setAttribute("style", "--scroll-bar-width: " + (window.innerWidth - body.clientWidth) + "px");
        } else {
            body.classList.remove("has-scrollbar");
        }
    };

    useEffect(() => {
        // Import media player libraries
        // loadThirdPartyLib('link', {
        //     href: `${config.PLAYER_URL}/videoJs/resources/styles/default.css`,
        //     rel: 'stylesheet'
        // });

        // handleWindow();
        // cssVars();
        loadThirdPartyLib("script", {
            src: `${config.PLAYER_URL}/videoJs/resources/scripts/cloudplex.media.player.js`,
        });

        window.addEventListener("resize", handleWindow);
        return () => {
            window.removeEventListener("resize", handleWindow);
        };
    }, []);

    useEffect(() => {
        if (isMobile) {
            window.scrollTo(0, 0);
        }
    }, [pathname]);

    function getRoutesBySiteMode() {
        const isCms = siteMode.get() === "CMS";
        return isCms ? routesForCMS : routes;
    }

    const maches = useMediaQuery(theme.breakpoints.down("md"));
    if (maches) theme.spacing = (factor) => `${factor * 7}px`;


    useEffect(() => {
        const uploadingDomainComments = Object.values(comments.uploadingComments).flat();
        const uploadingCommonComments = Object.values(commonComments.uploadingComments).flat();
        const isUploadingCommentsExisted = uploadingDomainComments.length > 0 || uploadingCommonComments.length > 0;

        if (monitorUnloadBeforeUploadingRef.current === null && isUploadingCommentsExisted) {
            monitorUnloadBeforeUploadingRef.current = (e) => {
                e.preventDefault();
                const message = i18n.t(`common::msg::${"There is work in progress. Would you really leave the page?"}`);
                (e || window.event).returnValue = message;

                return message;
            };
            window.addEventListener("beforeunload", monitorUnloadBeforeUploadingRef.current);
        } else if (monitorUnloadBeforeUploadingRef.current && !isUploadingCommentsExisted) {
            window.removeEventListener("beforeunload", monitorUnloadBeforeUploadingRef.current);
            monitorUnloadBeforeUploadingRef.current = null;
        }
    }, [commonComments.uploadingComments, comments.uploadingComments]);

    const welcomeComponent = window.location.hostname.indexOf("lxhausys.cloudplex.") !== -1 ? WelcomeLxHausys : Welcome;
    return (
        <PDCThemeProvider theme={theme}>
            <CssBaseline />
            <GlobalStyle />
            <CacheBuster>
                {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        refreshCacheAndReload();
                    }
                    return (
                        <Layout>
                            <Switch>
                                {getRoutesBySiteMode().map((route, i) => (
                                    <RouteWithSubRoutes key={i} {...route} />
                                ))}
                                <Route exact={true} path={"/welcome"} component={welcomeComponent} />
                                <Route exact={true} path={"/welcome-saml-test"} component={WelcomeLxHausys} />
                                <Route exact={true} path={"/megazone/login"} component={Login} />
                                <Route exact={true} path={"/jwt/exchange"} component={ExchangeCode} />
                                <Route exact={true} path={"/confirmUser"} component={ConfirmUser} />
                                <Route exact={true} path={"/cloudplex/oauth2"} component={GetAccessTokenPopup} />
                                <Route exact={true} path={"/login"} component={LoginRedirection} />
                                <Route exact={true} path={"/initiate-lxhausys"} component={InitiateLXHausys} />
                                <Route exact={true} path={"/error/404"} component={Error404} />
                                <Route exact={true} path={"/error/500"} component={Error500} />
                                <Route exact={true} path={"/error/invaliduser"} component={InvalidUser} />
                                <Route exact={true} path={"/todos"} component={Todos} />
                                <Route exact={true} path={"*"} component={Error404} />
                            </Switch>
                        </Layout>
                    );
                }}
            </CacheBuster>
        </PDCThemeProvider>
    );
};

export default withRouter(App);
