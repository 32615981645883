import { handleActions, createAction } from "redux-actions";

const UPDATE_FILTER = "UPDATE_FILTER";
const RESET_FILTER = "RESET_FILTER";

const initialState = {
    value: {},
    editValue: {},
};

export default handleActions(
    {
        [UPDATE_FILTER]: (state, action) => {
            const newState = {
                value: action.payload.value ?? state.value,
                editValue: action.payload.editValue ?? state.editValue,
            };
            return {
                ...newState,
            };
        },
        [RESET_FILTER]: (state) => {
            return {
                ...initialState,
            };
        },
    },
    initialState,
);

export const updateFilter = createAction(UPDATE_FILTER);
export const resetFilter = createAction(RESET_FILTER);
