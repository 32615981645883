import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const CardBody = (props) => {
    const { className, ...otherProps } = props;
    return <div {...otherProps} className={clsx("card-body", className)} />;
};
CardBody.propTypes = {
    className: PropTypes.string,
};

export default CardBody;