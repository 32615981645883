import React from "react";
import { PROPERTY_PANEL_TYPE } from "@constants";

const LineupPage = (props) => {
    const { onClickLineupContents, disabledLineupDetail, onClickLineupDetail, isShow, type } = props;

    return (
        <div className={"panel-fixed-tabs"}>
            <ul>
                <li>
                    <button type={"button"} className="btn btn-icon btn-transparent" onClick={onClickLineupContents}>
                        {isShow && type === PROPERTY_PANEL_TYPE.LISTINGS_ITEM_LIST ? (
                            <i className={"sprite sprite-source-selected"}></i>
                        ) : (
                            <i className={"sprite sprite-source"}></i>
                        )}
                    </button>
                </li>
                <li>
                    <button
                        type={"button"}
                        className="btn btn-icon btn-transparent"
                        disabled={disabledLineupDetail}
                        onClick={onClickLineupDetail}
                    >
                        {isShow && type === PROPERTY_PANEL_TYPE.LISTINGS_LINEUP_DETAIL ? (
                            <i className={"sprite sprite-info-selected"}></i>
                        ) : (
                            <i className={"sprite sprite-info-gray"}></i>
                        )}
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default LineupPage;
