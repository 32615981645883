import * as React from "react";
import { useCallback } from "react";
import { TableCell } from "@mzc-pdc/ui";
import { Grid } from "@mui/material";
import Skeleton from "@components_v2/skeleton/skeleton";

export const SkeletonCollectionsTable = ({ columns }) => {
    const getSkeletonByColumn = useCallback(
        (column) => {
            const { id, style } = column;

            if (!id) return;
            if (id === "name")
                return (
                    <Grid
                        container
                        direction={`row`}
                        justifyContent={`flex-start`}
                        alignItems={`center`}
                        flexWrap={"nowrap"}
                    >
                        <Grid item>
                            <Skeleton
                                variant="rectangular"
                                animation={"wave"}
                                width={52}
                                height={52}
                                style={{ marginLeft: `6px`, marginRight: `16px` }}
                            />
                        </Grid>
                        <Grid item>
                            <Grid container flexDirection={`column`}>
                                <Grid item>
                                    <Skeleton variant="rectangular" animation={"wave"} width={300} height={21} />
                                </Grid>
                                <Grid item>
                                    <Skeleton
                                        variant="rectangular"
                                        animation={"wave"}
                                        width={170}
                                        height={19}
                                        style={{ marginTop: `8px` }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            if (id === "type") return <Skeleton variant="rectangular" animation={"wave"} width={170} height={18} />;
            if (id === "count") return <Skeleton variant="rectangular" animation={"wave"} width={70} height={18} />;
            if (id === "size")
                return (
                    <Grid container direction={`column`} justifyContent={`flex-start`} alignItems={`center`}>
                        <Grid item>
                            <Skeleton
                                variant="rectangular"
                                animation={"wave"}
                                width={column?.style?.minWidth ?? 170}
                                height={18}
                            />
                        </Grid>
                    </Grid>
                );
            if (id === "jobId")
                return (
                    <Grid container direction={`column`} justifyContent={`flex-start`} alignItems={`center`}>
                        <Grid item>
                            <Skeleton variant="rectangular" animation={"wave"} width={170} height={18} />
                        </Grid>
                    </Grid>
                );
            if (id === "access")
                return (
                    <Grid container direction={`column`} justifyContent={`flex-start`} alignItems={`center`}>
                        <Grid item>
                            <Skeleton variant="rectangular" animation={"wave"} width={110} height={18} />
                        </Grid>
                    </Grid>
                );
            if (id === "owner")
                return (
                    <Grid container direction={`column`} justifyContent={`flex-start`} alignItems={`center`}>
                        <Grid item>
                            <Skeleton variant="rectangular" animation={"wave"} width={180} height={18} />
                        </Grid>
                    </Grid>
                );
            if (id === "createdAt")
                return (
                    <Grid container direction={`column`} justifyContent={`flex-start`} alignItems={`center`}>
                        <Grid item>
                            <Skeleton
                                variant="rectangular"
                                animation={"wave"}
                                width={column?.style?.minWidth ?? 180}
                                height={18}
                            />
                        </Grid>
                    </Grid>
                );
            if (id === "updatedAt")
                return (
                    <Grid container direction={`column`} justifyContent={`flex-start`} alignItems={`center`}>
                        <Grid item>
                            <Skeleton
                                variant="rectangular"
                                animation={"wave"}
                                width={column?.style?.minWidth ?? 180}
                                height={18}
                            />
                        </Grid>
                    </Grid>
                );
            return null;
        },
        [columns],
    );

    return (
        <>
            <TableCell style={{ width: "16px", verticalAlign: "middle" }} padding="checkbox">
                <Grid item>
                    <Skeleton
                        variant="rectangular"
                        animation={"wave"}
                        width={18}
                        height={18}
                        style={{ marginRight: "0px" }}
                    />
                </Grid>
            </TableCell>
            {columns &&
                columns.map((column) => (
                    <TableCell
                        style={{
                            paddingLeft: "0px",
                            verticalAlign: "middle",
                            width: column.id === "name" ? "60%" : undefined,
                        }}
                    >
                        {getSkeletonByColumn(column)}
                    </TableCell>
                ))}

            <TableCell align="center" sx={{ verticalAlign: "middle" }}>
                <Grid container direction={`column`} justifyContent={`flex-start`} alignItems={`center`}>
                    <Grid item>
                        <Skeleton variant="rectangular" animation={"wave"} width={40} height={18} />
                    </Grid>
                </Grid>
            </TableCell>
        </>
    );
};

export default SkeletonCollectionsTable;
