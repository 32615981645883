import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SpriteIcon from "@components/icons/SpriteIcon";
import PanelHeader from "@components/property-panel/ui/PanelHeader";
import PanelHeaderTool from "@components/property-panel/ui/PanelHeaderTool";
import PanelHeaderTitle from "@components/property-panel/ui/PanelHeaderTitle";
import { IconButton } from "@mzc-pdc/ui";
import Loading from "@components/loader/Loading";
import PanelPermissionError from "@components/property-panel/ui/PanelPermissionError";
import PanelBody from "@components/property-panel/ui/PanelBody";
import ExternalShareOverview from "@components/view/external-share-overview";
import ItemsSharedView from "@components/view/items-shared-view";
import SharedWithView from "@components/view/shared-with-view";
import PanelBodyInformation from "@components/property-panel/ui/PanelBodyInformation";
import PanelBodyInfoContents from "@components/property-panel/ui/PanelBodyInfoContents";
import InformationTools from "@components/tabs/InformationTools";
import ScrollArea from "@components/ScrollArea";
import VersionedComponent from "@components/VersionedComponent";
import { VERSION_CM_8260_SHARED_BOX_STORAGE_PROPERTY_PANEL } from "../../cores/version";
import { EXTERNAL_SHARE_HISTORY_PANEL_TABS } from "@constants";
import { IconShareLink } from "@mzc-cloudplex/icons";

const Panel = ({
    title,
    convertToCategoryType,
    shouldHidePreviewButton,
    onClickClose,
    contentsViewData,
    handleShareRequestModal,
}) => {
    const { t } = useTranslation();

    const [activeTab, setActiveTab] = useState(EXTERNAL_SHARE_HISTORY_PANEL_TABS[0].value);

    const onHandelChangedTab = (tab) => {
        setActiveTab(tab);
    };

    const { propertyPanel } = useSelector(({ propertyPanel }) => ({
        propertyPanel,
    }));

    return (
        <React.Fragment>
            <VersionedComponent version={VERSION_CM_8260_SHARED_BOX_STORAGE_PROPERTY_PANEL}>
                <PanelHeader>
                    <PanelHeaderTitle>
                        <div className={"format-img format-img-link"}>
                            <IconShareLink size={12} htmlColor={"#fff"} />
                        </div>
                        <SpriteIcon className={`sprite-${title && title.toLowerCase()}`}></SpriteIcon>
                        {t(`common::label::${title}`)}
                    </PanelHeaderTitle>
                    <PanelHeaderTool>
                        <IconButton
                            type="button"
                            className="btn-icon-solid btn-panel-close"
                            onClick={onClickClose}
                            icon={
                                <>
                                    <span>
                                        <SpriteIcon className="sprite-cancel-lg"></SpriteIcon>
                                    </span>
                                    <span className={"hidden"}>{t(`common::label::${"Close"}`)}</span>
                                </>
                            }
                        ></IconButton>
                    </PanelHeaderTool>
                </PanelHeader>
                {propertyPanel.pending ? (
                    <Loading></Loading>
                ) : (
                    <React.Fragment>
                        {propertyPanel.accessError || propertyPanel.error ? ( //접근 권한이 없는 경우
                            <PanelPermissionError
                                handleShareRequestModal={handleShareRequestModal}
                                panelType={propertyPanel.type}
                            ></PanelPermissionError>
                        ) : (
                            <PanelBody>
                                {propertyPanel.data ? (
                                    <React.Fragment>
                                        <PanelBodyInformation>
                                            <InformationTools
                                                tabs={EXTERNAL_SHARE_HISTORY_PANEL_TABS}
                                                activeTab={activeTab}
                                                handleOnChangeTab={onHandelChangedTab}
                                            ></InformationTools>
                                            <PanelBodyInfoContents>
                                                {activeTab == EXTERNAL_SHARE_HISTORY_PANEL_TABS[0].value && (
                                                    <ScrollArea>
                                                        <ExternalShareOverview {...propertyPanel.data} />
                                                    </ScrollArea>
                                                )}
                                                {activeTab == EXTERNAL_SHARE_HISTORY_PANEL_TABS[1].value && (
                                                    <ItemsSharedView item={propertyPanel.data} />
                                                )}
                                                {activeTab == EXTERNAL_SHARE_HISTORY_PANEL_TABS[2].value && (
                                                    <SharedWithView item={propertyPanel.data} />
                                                )}
                                            </PanelBodyInfoContents>
                                        </PanelBodyInformation>
                                    </React.Fragment>
                                ) : (
                                    <div>{t(`common::label::${"Not found"}`)}</div>
                                )}
                            </PanelBody>
                        )}
                    </React.Fragment>
                )}
            </VersionedComponent>
        </React.Fragment>
    );
};

export default Panel;
