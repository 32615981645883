import React from "react";
import { IconButton } from "@mzc-pdc/ui";
import { IconArrowBack, IconArrowForward, IconUp } from "@mzc-cloudplex/icons";

const TreeViewNav = ({ treeViewHistory, onClickPrev, onClickNext, onClickUp }) => {
    return (
        <>
            <>
                <IconButton
                    circled
                    size={"medium"}
                    disabled={!treeViewHistory || treeViewHistory?.index === 0}
                    onClick={(e) => {
                        e.stopPropagation();
                        onClickPrev();
                    }}
                >
                    <IconArrowBack size={16} />
                </IconButton>
                <IconButton
                    circled
                    size={"medium"}
                    disabled={!treeViewHistory || treeViewHistory?.index === treeViewHistory?.history?.length - 1}
                    onClick={(e) => {
                        e.stopPropagation();
                        onClickNext();
                    }}
                >
                    <IconArrowForward size={16} />
                </IconButton>
                <IconButton
                    circled
                    size={"medium"}
                    disabled={!treeViewHistory || !treeViewHistory?.selected?.parent}
                    onClick={(e) => {
                        e.stopPropagation();
                        onClickUp();
                    }}
                >
                    <IconUp size={16} />
                </IconButton>
            </>
        </>
    );
};

export default TreeViewNav;
