import theme from "@styles/theme";

import { styled } from "@mui/system";

export const headerHeight = `56px`;
export const footerHeight = `48px`;
export const footerTabletHeight = `102px`;
export const footerMobileHeight = `165px`;
export const panelWidth = `240px`;

export const Documents = styled("div")(() => ({
    minHeight: `100%`,
    height: `100%`,
    overflow: `hidden`,
    display: `flex`,
}));

export const Main = styled("main")(({ theme }) => ({
    flex: 1,
    height: `100%`,
    minWidth: 0,
    padding: theme.spacing(2, 5, 0),
    overflow: `auto`,
    display: `flex`,
    flexDirection: `column`,
    transition: `linear padding 0.2s`,
    [theme.breakpoints.down("xl")]: {
        padding: theme.spacing(2, 3, 0),
        transition: `linear padding 0.2s`,
    },
}));

export const Container = styled("div")(() => ({
    flex: 1,
    boxSizing: `border-box`,
    height: `100%`,
    minWidth: 0,
    overflow: `hidden`,
    display: `flex`,
    flexDirection: `column`,
    width: `calc(100vh - ${panelWidth})`,
}));

export const EmptyContent = styled("div")(() => ({
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `center`,
    alignItems: `center`,
    flexWrap: `wrap`,
    maxWidth: `1280px`,
    height: `calc(100vh - ${headerHeight}px - ${footerHeight})`,
    marginTop: `${headerHeight}px`,
    textAlign: `center`,
    [theme.breakpoints.down("xl")]: {
        minHeight: `calc(100vh - ${footerTabletHeight})`,
        margin: `0 auto -${footerTabletHeight}`,
        padding: `32px 0 ${footerTabletHeight}`,
    },
    [theme.breakpoints.down("md")]: {
        minHeight: `calc(100vh - ${footerMobileHeight})`,
        margin: `0 auto -${footerMobileHeight}`,
        padding: `32px 0 ${footerMobileHeight}`,
    },
}));
