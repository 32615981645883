import {createAction, handleActions} from 'redux-actions';
import {
    axios,
    axiosWithoutHeaders
} from "../cores/axiosWrapper";

function getListingAPI(stageId, apiEndpoint, projectId, listingId) {
    return axios.get(`${apiEndpoint}/listings/${listingId}`, {
        headers : {
            stageId,
            projectId
        }
    });
}

function patchListingAPI(stageId, apiEndpoint, projectId, listingId, version, params) {
    const postData = {
        ...params,
        id:listingId,
        version:version,
        project:{
            "id": projectId
        }
    }

    return axios.patch(`${apiEndpoint}/listings/${listingId}`, postData, {
        headers : {
            stageId,
            projectId
        }
    });
}

function deleteListingAPI(stageId, apiEndpoint, projectId, listingId) {
    return axios.delete(`${apiEndpoint}/listings/${listingId}`, {
        headers : {
            stageId,
            projectId
        }
    });
}

const LISTING_PENDING = 'LISTING_PENDING';
const LISTING_FAILURE = 'LISTING_FAILURE';
const GET_LISTING_SUCCESS = 'GET_LISTING_SUCCESS';
const PUT_LISTING_SUCCESS = 'PUT_LISTING_SUCCESS';
const UPDATE_LISTING = 'UPDATE_LISTING';
const DELETE_LISTING = 'DELETE_LISTING';
const SET_LISTINGS_STREAM = 'SET_LISTINGS_STREAM';
const SET_LISTINGS_REMOVE_TARGET_STREAMS = 'SET_LISTINGS_REMOVE_TARGET_STREAMS';
const SET_LISTINGS_REMOVE_TARGET_FILE_SOURCES = 'SET_LISTINGS_REMOVE_TARGET_FILE_SOURCES';
const RESET_LISTING = 'RESET_LISTING';
const RESET_LISTING_STREAM = 'RESET_LISTING_STREAM';

const initialState = {
    pending: false,
    error: false,
    data: null,
    streams: [],
    isIndividualSettings : false,
    removeTargetStreams : [],
    removeTargetFileSources : [],
    isDeleted:false
};

export default handleActions({
    [LISTING_PENDING]: (state) => {
        return {
            ...state,
            pending: true,
            error: false
        };
    },
    [LISTING_FAILURE]: (state, action) => {
        return {
            ...state,
            pending: false,
            error: true
        }
    },
    [UPDATE_LISTING]:(state, action) => {
        const data = action.payload;

        return {
            ...state,
            data: {
                ...state.data,
                ...data
            }
        };
    },
    [GET_LISTING_SUCCESS]: (state, action) => {
        const {data} = action.payload;
       
        return {
            ...state,
            isIndividualSettings: data.settings.isLoopToFillLineupDuration.layer === 3 ? true : false,
            pending: false,
            error: false,
            data : data
        }
    },
    [PUT_LISTING_SUCCESS]:(state, action) => {
        const {data} = action.payload;

        return {
            ...state,
            pending: false,
            error: false,
            data
        };
    },
    [DELETE_LISTING]: (state) => {
        return {
            ...state,
            pending:true,
            error: false,
            isDeleted:true
        }
    },
    [SET_LISTINGS_STREAM]:(state, action) => {
        return {
            ...state,
            streams : action.payload
        }
    },
    [SET_LISTINGS_REMOVE_TARGET_STREAMS]:(state, action) => {
        return {
            ...state,
            removeTargetStreams : action.payload
        }
    },
    [SET_LISTINGS_REMOVE_TARGET_FILE_SOURCES]:(state, action) => {
        return {
            ...state,
            removeTargetFileSources : action.payload
        }
    },
    [RESET_LISTING]:() => {
        return {
            pending: false,
            error: false,
            data: null,
            streams: [],
            isDeleted:false,
            removeTargetInputs : []
        }
    },
    [RESET_LISTING_STREAM]:(state, action) => {
        return {
            ...state,
            streams : state.streams.map(v => {
                if(v.id === action.payload) {
                    return {
                        ...v,
                        id : ''
                    }
                } else {
                    return v;
                }
            })
        }
    }
}, initialState);
export const updateListing = createAction(UPDATE_LISTING);
export const setStream = createAction(SET_LISTINGS_STREAM);
export const setRemoveTargetStreams = createAction(SET_LISTINGS_REMOVE_TARGET_STREAMS);
export const setRemoveTargetFileSources = createAction(SET_LISTINGS_REMOVE_TARGET_FILE_SOURCES);
export const resetListing = createAction(RESET_LISTING);
export const resetStream = createAction(RESET_LISTING_STREAM);

export const getListing = (projectId, listingId) => (dispatch, getState) => {
    const {
        stage
    } = getState();

    dispatch({type: LISTING_PENDING});
    
    return getListingAPI(stage.id, stage.endpoint, projectId, listingId).then(
        (response) => {
            dispatch({
                type: GET_LISTING_SUCCESS,
                payload: response
            });
        }
    ).catch(error => {
        dispatch({
            type: LISTING_FAILURE,
            payload: error
        });
    });
};

export const patchListing = (params, afterCallback ) => (dispatch, getState) => {
    const {
        stage,
        project,
        listing,
    } = getState();

    return patchListingAPI(stage.id, stage.endpoint, project.id, listing.data.id, listing.data.version, params).then(
        (response) => {
            if(afterCallback && typeof afterCallback) {
                afterCallback();
            }

            dispatch({
                type: PUT_LISTING_SUCCESS,
                payload: response
            });
        }
    ).catch(error => {
        dispatch({
            type: LISTING_FAILURE,
            payload: error
        });
    });
};

export const deleteListing = () => async (dispatch, getState) => {
    const {
        stage,
        project,
        listing
    } = getState();

    dispatch({type: LISTING_PENDING});

    const listingId = listing.data && listing.data.id;    

    return deleteListingAPI(stage.id, stage.endpoint, project.id, listingId).then(() => {
            dispatch({type: DELETE_LISTING});
        }).catch(error => {
            dispatch({
                type: LISTING_FAILURE,
                payload: error
            });
        });    
};