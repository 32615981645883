import {handleActions, createAction} from 'redux-actions';
import {
    axios,
    axiosWithoutHeaders
} from "../cores/axiosWrapper";

function getPipelinesAPI(stageId, apiEndpoint, projectId) {
    return axios.get(`${apiEndpoint}/pipelines`,{
        headers : {
            stageId,
            projectId
        }
    });
}
//action type
const PIPELINES_PENDING = 'PIPELINES_PENDING';
const PIPELINES_FAILURE = 'PIPELINES_FAILURE';
const GET_PIPELINES_SUCCESS = 'GET_PIPELINES_SUCCESS';
const UPDATE_PIPELINE = 'UPDATE_PIPELINE';
//reducer
const initialState = {
    active: {},
    data: null,
    pending: false,
    error: false
};
export default handleActions({
    [PIPELINES_PENDING]: (state) => {
        console.log(`PIPELINES_PENDING..`);
        return {
            ...state,
            pending: true,
            error: false
        };
    },
    [PIPELINES_FAILURE]: (state) => {
        console.log(`PIPELINES_FAILURE handler called..`);
        return {
            ...state,
            pending: false,
            error: true
        };
    },
    [GET_PIPELINES_SUCCESS]: (state, action) => {
        let {data} = action.payload;

        console.log(`GET_PIPELINES_SUCCESS data: `, data);
        
        return {
            pending:false,
            error:false,
            data : data.pipelines,
            active: data.pipelines[0]
        }
    },
    [UPDATE_PIPELINE]: (state, action) => {
        return {
            ...this.state,
            active : state.data.filter(v => v.id === action.payload)[0]
        }
    }
}, initialState);
export const getPipelines = (callback) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();
    
    if(stage.id === '') {
        return;
    }

    dispatch({type: PIPELINES_PENDING});
    getPipelinesAPI(stage.id, stage.endpoint, project.id).then(
        (response) => {
            dispatch({
                type: GET_PIPELINES_SUCCESS,
                payload: response
            });
            if(callback) {
                callback(response.data);
            }
        }
    ).catch(error => {
        dispatch({
            type: PIPELINES_FAILURE
        });
        if(callback) {
            callback(null);
        }
    });
};

export const updatePipeline = createAction(UPDATE_PIPELINE);