import { handleActions } from "redux-actions";
import { getMetadataExportsAPI } from "@modules/apis/metadata-exports";
import { deleteMetadataExportAPI } from "@modules/apis/metadata-export";

const initialState = {
    data: null,
    pending: false,
    error: false,
    create: { data: null, pending: false, error: false },
};

const CREATE_METADATA_EXPORTS_SUCCESS = "CREATE_METADATA_EXPORTS_SUCCESS";
const GET_METADATA_EXPORTS_SUCCESS = "GET_METADATA_EXPORTS_SUCCESS";
const GET_METADATA_EXPORTS_FAILURE = "GET_METADATA_EXPORTS_FAILURE";
const GET_METADATA_EXPORTS_PENDING = "GET_METADATA_EXPORTS_PENDING";
const DELETE_METADATA_EXPORTS_SUCCESS = "DELETE_METADATA_EXPORTS_SUCCESS";

export default handleActions(
    {
        [CREATE_METADATA_EXPORTS_SUCCESS]: (state, action) => {
            return { ...state, pending: false };
        },
        [GET_METADATA_EXPORTS_PENDING]: (state, action) => {
            return { ...state, pending: true, data: action.payload ? state.data : null };
        },
        [GET_METADATA_EXPORTS_FAILURE]: (state, action) => {
            return { ...state, pending: false, error: action.payload };
        },
        [GET_METADATA_EXPORTS_SUCCESS]: (state, action) => {
            return { ...state, data: action.payload, pending: false };
        },
        [DELETE_METADATA_EXPORTS_SUCCESS]: (state, action) => {
            return { ...state, data: state.data.filter((item) => item.id !== action.payload) };
        },
    },
    initialState,
);

export const createMetadataExport = () => (dispatch, getState) => {
    const { stage, project, vocabulary } = getState();

    return new Promise(async (resolve, reject) => {
        try {
            dispatch({ type: CREATE_METADATA_EXPORTS_SUCCESS });
        } catch (error) {
            reject(null);
        }
    });
};

export const getMetadataExports = (projectId, params) => (dispatch, getState) => {
    const { stage, metadataExports } = getState();

    return new Promise(async (resolve, reject) => {
        try {
            dispatch({ type: GET_METADATA_EXPORTS_PENDING, payload: !!params.nextToken });

            const response = await getMetadataExportsAPI(stage.endpoint, projectId, params);

            await new Promise((resolve) => setTimeout(resolve, 1000));

            dispatch({
                type: GET_METADATA_EXPORTS_SUCCESS,
                payload: params.nextToken ? [...metadataExports.data, ...response.data.results] : response.data.results,
            });
            resolve(response.data);
        } catch (error) {
            dispatch({ type: GET_METADATA_EXPORTS_FAILURE, payload: error });
            reject(null);
        }
    });
};

export const deleteMetadataExports =
    ({ projectId, id }) =>
    (dispatch, getState) => {
        const { stage } = getState();
        return new Promise(async (resolve, reject) => {
            try {
                const response = await deleteMetadataExportAPI(stage.endpoint, projectId, id);
                dispatch({ type: DELETE_METADATA_EXPORTS_SUCCESS, payload: id });
                resolve(response.data);
            } catch (error) {
                reject();
            }
        });
    };
