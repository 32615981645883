import {handleActions, createAction} from 'redux-actions';
import {
    axios,
    axiosWithoutHeaders
} from "../cores/axiosWrapper";

function getCategoriesAPI(stageId, apiEndpoint, projectId) {
    return axios.get(`${apiEndpoint}/categories`, {
        headers : {
            stageId,
            projectId
        }
    });
}

//action type
const CATEGORIES_PENDING = 'CATEGORIES_PENDING';
const CATEGORIES_FAILURE = 'CATEGORIES_FAILURE';
const CATEGORIES_COMPLETE = 'CATEGORIES_COMPLETE';
const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
//reducer
const initialState = {
    pending: false,
    error: false,
    data: null
};

export default handleActions({
    [CATEGORIES_PENDING]: (state) => {
        return {
            ...state,
            pending: true,
            error: false
        };
    },
    [CATEGORIES_FAILURE]: (state) => {
        return {
            ...state,
            pending: false,
            error: true
        };
    },
    [CATEGORIES_COMPLETE]: (state) => {
        return {
            ...state,
            pending: false,
            error: false
        };
    },
    [GET_CATEGORIES_SUCCESS]: (state, action) => {
        let {categories} = action.payload.data;
        
        return {
            ...state,
            pending:false,
            error:false,
            data: categories.map(v => {
                return {
                    value: v.id,
                    label: v.name,
                    id : v.id
                }
            })
        };
    }
}, initialState);

export const getCategories = () => (dispatch, getState) => {
    const {
        stage,
        project,
        categories
    } = getState();
    
    if(stage.id === '') {
        return;
    }

    dispatch({type: CATEGORIES_PENDING});
    
    if(categories.data !== null) {
        dispatch({type: CATEGORIES_COMPLETE});
        return;
    }

    return getCategoriesAPI(stage.id, stage.endpoint, project.id).then(
        (response) => {
            dispatch({
                type: GET_CATEGORIES_SUCCESS,
                payload: response
            });
        }
    ).catch(error => {
        dispatch({
            type: CATEGORIES_FAILURE,
            payload: error
        });
    });
}