const queryString = {
    stringify: (obj) => {
        var query = "";
        for (var key in obj) {
            if (query) {
                query += "&";
            }

            query += `${key}=` + queryString.translateDollarSign(`${encodeURIComponent(obj[key])}`);
        }

        return query;
    },
    translateDollarSign: (str) => {
        let endKeyword = str.substring(str.length - 1); // 검색어 마지막 문자

        // 마지막 문자가 특수기호 '$'일 경우 $를 하나 더 추가하여 검색 에러가 발생하지 않도록 한다.
        return endKeyword === "$" 
            ? str += endKeyword
            : str;
    }
};

export default queryString;