import {handleActions, createAction} from 'redux-actions';
import {
    axios,
    axiosWithoutHeaders
} from "../cores/axiosWrapper";

function getWhitelistsAPI (stageId, apiEndpoint, projectId) {
    const headers = {
        stageId
    };

    if(projectId) {
        headers.projectId = projectId;
    }

    return axios.get(`${apiEndpoint}/streams/whitelists`, {
        headers
    });
}

const WHITELISTS_PENDING = 'WHITELISTS_PENDING';
const WHITELISTS_FAILURE = 'WHITELISTS_FAILURE';
const GET_WHITELISTS_FOR_LIST_SUCCESS = 'GET_WHITELISTS_FOR_LIST_SUCCESS';
const RESET_WHITELISTS = 'RESET_WHITELISTS';

const initialState = {
    pending: false,
    error: false,
    list: null
};

export default handleActions({
    [WHITELISTS_PENDING]: (state) => {
        return {
            ...state,
            pending: true,
            error: false
        }
    },
    [WHITELISTS_FAILURE]: (state) => {
        return {
            ...state,
            pending: false,
            error: true
        }
    },
    [GET_WHITELISTS_FOR_LIST_SUCCESS]: (state, action) => {
        let {data} = action.payload;
        
        return {
            ...state,
            pending: false,
            error: false,
            list: data || []
        };
    },
    [RESET_WHITELISTS] :() => {
        return {
            ...initialState
        }
    }
}, initialState);
export const resetWhitelists = createAction(RESET_WHITELISTS);
export const getWhitelists = ({projectId}) => (dispatch, getState) => {
    const {
        stage
    } = getState();

    dispatch({type: WHITELISTS_PENDING});

    return getWhitelistsAPI(stage.id, stage.endpoint, projectId).then(
        (response) => {
            dispatch({
                type: GET_WHITELISTS_FOR_LIST_SUCCESS,
                payload: response
            });
        }
    ).catch(error => {
        dispatch({
            type: WHITELISTS_FAILURE
        });
    })
};