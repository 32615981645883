import React, { Component, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as notificationActions from "../../modules/notification";
import LayoutForError from "../../components/layout/LayoutForError";

const Error404 = () => {
    const location = useLocation();
    const { t } = useTranslation();

    return (
        <div className={"content-wrapper  error-page"}>
            <div className={"card card-error "}>
                <div className={"card-header"}>
                    <h2>{t(`common::msg::${"Page not found"}`)}</h2>
                </div>
                <div className={"card-body"}>
                    <div className={"d-flex align-items-center "}>
                        <div>
                            <i className={"sprite sprite-error-big"}></i>
                        </div>
                        <div>
                            <p className={"text-danger"}>{t(`common::msg::${"We're sorry!"}`)}</p>
                            <p>{t(`common::msg::${"The page you requested could not be found."}`)}</p>
                            <p>{t(`common::msg::${"Please go back to the main with the button below."}`)}</p>
                        </div>
                    </div>
                </div>
                <div className={"card-footer justify-content-end"}>
                    <Link className={"btn btn-primary"} to={"/spaces"} replace>
                        {t(`common::label::Main`)}
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Error404;
