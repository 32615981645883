import { useCallback, useMemo, useState } from "react";
import { Button, Divider, Grid, MenuItem, Select, TextField, theme, Typography } from "@mzc-pdc/ui";
import { UsersMultiInput, UsersMultiInputV2 } from "@features/users-input";
import { IconAdd, IconTime } from "@mzc-cloudplex/icons";
import ApprovedUserTeamItem from "@features/dialog/share/components/ApprovedUserTeamItem";
import compareVersions, { VERSION_CM_11376_SHARES_V2, VERSION_CM_11330_USERS_TEAMS_ACCESS_V3 } from "@cores/version";
import moment from "moment";
import getByteLength from "@cores/getByteLength";
import { useTranslation } from "react-i18next";
import { ASSET_SHARE_ACTIONS, LIMIT_BYTE, STORAGE_SHARE_ACTIONS } from "@constants";
import { useSelector } from "react-redux";

const AddShareApprovedContainer = ({
    domain,
    selected,
    selecting,
    defaultAction = "DOWNLOAD",
    expireDays,
    maximumDownloads,
    message,
    onChangeSelected,
    onChangeSelecting,
    onChangeExpireDays,
    onChangeMaximumDownloads,
    onChangeMessage,
}) => {
    const { t } = useTranslation();
    const [action, setAction] = useState(defaultAction);
    const stage = useSelector(({ stage }) => stage);

    const actionOptions = useMemo(() => (domain === "STORAGE" ? STORAGE_SHARE_ACTIONS : ASSET_SHARE_ACTIONS), [domain]);

    const onHandleChangeAction = useCallback(
        (user, action) => {
            onChangeSelected(
                selected.map((sel) => {
                    if (sel.id === user.id) {
                        return {
                            ...sel,
                            actions: action,
                        };
                    }
                    return sel;
                }),
            );
        },
        [selected, actionOptions],
    );

    const onHandleDeleteUserTeam = useCallback(
        (user) => {
            onChangeSelected(selected.filter((sel) => sel.id !== user.id));
        },
        [selected],
    );

    const onHandleClickAddToSelected = useCallback(() => {
        onChangeSelecting([]);
        onChangeSelected([...selected, ...selecting.map((user) => ({ ...user, actions: action }))]);
    }, [selecting, selected, action]);

    return (
        <>
            <Grid container flexDirection={"column"} gap={theme.spacing(1)}>
                <Grid item>
                    <Typography variant={`body1_500`} required>
                        {t(`common::label::Add Users or Groups`, `Add Users or Groups`)}
                    </Typography>
                </Grid>
                <Grid
                    item
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: theme.spacing(1),
                        alignItems: "flex-end",
                    }}
                >
                    {compareVersions(stage.version, VERSION_CM_11330_USERS_TEAMS_ACCESS_V3) >= 0 ? (
                        <UsersMultiInputV2
                            users={selecting}
                            exceptUsers={selected.concat(selecting)}
                            sx={{
                                flex: 3,
                                "& .MuiOutlinedInput-root": {
                                    paddingRight: "0px !important",
                                },
                            }}
                            placeholder={t(`common::msg::Please enter user's name.`, `Please enter user's name.`)}
                            onChange={onChangeSelecting}
                        />
                    ) : (
                        <UsersMultiInput
                            users={selecting}
                            exceptUsers={selected.concat(selecting)}
                            sx={{
                                flex: 3,
                                "& .MuiOutlinedInput-root": {
                                    paddingRight: "0px !important",
                                },
                            }}
                            placeholder={t(`common::msg::Please enter user's name.`, `Please enter user's name.`)}
                            onChange={onChangeSelecting}
                        />
                    )}
                    <Select
                        onChange={(e) => {
                            setAction(e.target.value);
                        }}
                        value={action}
                        sx={{ flex: `1 0 0%`, height: "100%" }}
                    >
                        {actionOptions.map((option) => (
                            <MenuItem value={option.value}>
                                {t(`common::label::${option.label}`, `${option.label}`)}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid
                    item
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        my: theme.spacing(0.5),
                    }}
                >
                    <Button
                        size={"small"}
                        variant={"text"}
                        disabled={selecting.length === 0}
                        onClick={onHandleClickAddToSelected}
                        startIcon={<IconAdd size={16} />}
                    >
                        {t(`common::label::Add to Added Users`, `Add to Added Users`)}
                    </Button>
                </Grid>
                <Divider flexItem orientation={"horizontal"} />
            </Grid>
            {selected.length > 0 && (
                <Grid container flexDirection={"column"} gap={theme.spacing(1)}>
                    <Grid item>
                        <Typography variant={`body1_500`}>{t(`common::label::Added Users`, `Added Users`)}</Typography>
                        &nbsp;
                        <Typography variant={`body1_500`} color={theme.palette.primary.main} required>
                            {selected?.length}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        sx={{
                            borderRadius: "4px",
                            border: `1px solid ${theme.palette.greySecondary[100]}`,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        {/* 여기 아래부분은 관리 모달에서 우측 아코디언 부분이 살짝 다르고 나머지는 동일해서 공통으로 가져가도 될듯? */}
                        {selected.map((userOrTeam, index) => (
                            <ApprovedUserTeamItem
                                key={index}
                                drawBorderBottom={index !== selected.length - 1}
                                userOrTeam={userOrTeam}
                                onHandleChangeAction={onHandleChangeAction}
                                onHandleDeleteUserTeam={onHandleDeleteUserTeam}
                                options={actionOptions}
                            />
                        ))}
                    </Grid>
                </Grid>
            )}
            {compareVersions(stage.version, VERSION_CM_11376_SHARES_V2) >= 0 && (
                <>
                    <Grid container flexDirection={"column"} gap={theme.spacing(1)}>
                        <Grid item>
                            <Typography variant={`body1_500`}>
                                {t(`common::label::Share Settings`, `Share Settings`)}
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            flexDirection={"row"}
                            sx={{
                                padding: theme.spacing(1.5),
                                borderRadius: "4px",
                                border: `1px solid ${theme.palette.greySecondary[100]}`,
                            }}
                        >
                            <Grid
                                item
                                sx={{
                                    flexBasis: "120px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <Typography
                                    variant={`body1_400`}
                                    sx={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                    }}
                                >
                                    {t(`common::label::Sharing Period`, `Sharing Period`)}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant={`body1_400`}
                                    sx={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                        gap: theme.spacing(1),
                                    }}
                                >
                                    {t(`common::label::Maximum`, `Maximum`)}
                                    <TextField
                                        type={"number"}
                                        size={"small"}
                                        inputProps={{ min: 0 }}
                                        sx={{
                                            width: "80px",
                                            input: {
                                                paddingLeft: theme.spacing(2),
                                            },
                                        }}
                                        defaultValue={expireDays}
                                        onKeyDown={(e) => {
                                            if (e.code === "Minus") {
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => {
                                            onChangeExpireDays(e.target.value > 0 ? e.target.value : 0);
                                        }}
                                    />
                                    {t(`common::label::days`, `days`)}
                                    <Divider flexItem orientation={"vertical"} />
                                    <IconTime size={16} htmlColor={`#999`} />
                                    {t(`common::label::Expires At`)} :
                                    <Typography variant={`body1_400`} color={theme.palette.warning.main}>
                                        {expireDays === 0
                                            ? "-"
                                            : ` ${moment().add(expireDays, "d").format("YYYY-MM-DD")}`}
                                    </Typography>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container flexDirection={"column"} gap={theme.spacing(1)}>
                        <Grid item>
                            <Typography variant={`body1_500`}>
                                {t(`common::label::Downloader Permission Settings`, `Downloader Permission Settings`)}
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            flexDirection={"row"}
                            sx={{
                                padding: theme.spacing(1.5),
                                borderRadius: "4px",
                                border: `1px solid ${theme.palette.greySecondary[100]}`,
                            }}
                        >
                            <Grid
                                item
                                sx={{
                                    flexBasis: "120px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <Typography
                                    variant={`body1_400`}
                                    sx={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                    }}
                                >
                                    {t(`common::label::Number of Downloads`, `Number of Downloads`)}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant={`body1_400`}
                                    sx={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                        gap: theme.spacing(1),
                                    }}
                                >
                                    {t(`common::label::Maximum`, `Maximum`)}
                                    <TextField
                                        type={"number"}
                                        size={"small"}
                                        inputProps={{ min: 0 }}
                                        sx={{
                                            width: "80px",
                                            input: {
                                                paddingLeft: theme.spacing(2),
                                            },
                                        }}
                                        defaultValue={maximumDownloads}
                                        onKeyDown={(e) => {
                                            if (e.code === "Minus") {
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={(e) =>
                                            onChangeMaximumDownloads(e.target.value > 0 ? e.target.value : 0)
                                        }
                                    />
                                    {t(`common::label::times`, `times`)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
            <Grid container flexDirection={"column"} gap={theme.spacing(1)}>
                <Grid item>
                    <Typography variant={`body1_500`}>{t(`common::label::Message`, `Message`)}</Typography>
                </Grid>
                <Grid item>
                    <TextField
                        fullWidth
                        multiline
                        type={`textarea`}
                        placeholder={t(
                            `common::msg::Please enter a message. (Optional)`,
                            `Please enter a message. (Optional)`,
                        )}
                        value={message}
                        maxByte={LIMIT_BYTE.description}
                        bytes={getByteLength(message)}
                        onChange={(e) => {
                            onChangeMessage(e.target.value);
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default AddShareApprovedContainer;
