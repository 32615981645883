import { createAction, handleActions } from "redux-actions";
import downloadLink from "@cores/downloadLink";

import JmsApi from "./apis/jobManagementService";
import { addPausedPollingApis } from "./pausedPollingApis";

const initialState = {
    pending: false,
    error: false,
    data: [],
    search: {
        limit: 20,
    },
    totalCounts: 0,
    beforeUnloadCheck: 0,
};

const DOWNLOAD_JOBS_PENDING = "DOWNLOAD_JOBS_PENDING";
const DOWNLOAD_JOBS_FAILURE = "DOWNLOAD_JOBS_FAILURE";
const GET_DOWNLOAD_JOBS_LIST_SUCCESS = "GET_DOWNLOAD_JOBS_LIST_SUCCESS";
const GET_DOWNLOAD_JOBS_LIST_FAILURE = "GET_DOWNLOAD_JOBS_LIST_FAILURE";
const UPDATE_DOWNLOAD_JOBS_SEARCH = "UPDATE_DOWNLOAD_JOBS_SEARCH";
const UPDATE_SINGLE_JOB = "UPDATE_SINGLE_JOB";
const UPDATE_MULTIPLE_JOBS = "UPDATE_MULTIPLE_JOBS";
const CREATE_DOWNLOAD_JOB_SUCCESS = "CREATE_DOWNLOAD_JOB_SUCCESS";
const CREATE_DOWNLOAD_JOB_FAILURE = "CREATE_DOWNLOAD_JOB_FAILURE";
const UPDATE_DOWNLOAD_STATE = "UPDATE_DOWNLOAD_STATE";
const UPDATE_JOB_STATUS = "UPDATE_JOB_STATUS";

let polling = [];
let runningTasks = {};
const jobStatusMap = new Map();

export const JMS_STATUS = {
    FAILED: "FAILED",
    SUCCEED: "SUCCEED",
    CANCELED: "CANCELED",
    SUBMITTED: "SUBMITTED",
};

export default handleActions(
    {
        [DOWNLOAD_JOBS_PENDING]: (state, action) => {
            const newState = {
                ...state,
                pending: true,
                error: false,
            };
            return newState;
        },
        [DOWNLOAD_JOBS_FAILURE]: (state, action) => {
            const newState = {
                ...state,
                pending: false,
                error: true,
            };
            return newState;
        },
        [UPDATE_DOWNLOAD_JOBS_SEARCH]: (state, action) => {
            const payload = action.payload;
            const newState = {
                ...state,
                search: {
                    ...state.search,
                    ...payload,
                },
            };
            return newState;
        },
        [GET_DOWNLOAD_JOBS_LIST_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const { results, totalCount } = data;

            const addInitValue = results.map((item) => {
                item.isFolded = true;
                item.tasks = [];
                return item;
            });

            const newState = {
                ...state,
                error: false,
                data: addInitValue || [],
                totalCounts: totalCount || 0,
            };
            return newState;
        },
        [UPDATE_SINGLE_JOB]: (state, action) => {
            const { job } = action.payload;
            const newData = state.data.map((item) => {
                if (item.jobId === job.jobId) {
                    item = {
                        ...item,
                        ...job,
                    };
                }
                return item;
            });
            const newState = {
                ...state,
                error: false,
                data: newData,
            };
            return newState;
        },
        [UPDATE_MULTIPLE_JOBS]: (state, action) => {
            const { data } = action.payload;
            const { results } = data;
            let newData = JSON.parse(JSON.stringify(state.data));
            results.forEach((job) => {
                newData = newData.map((item) => {
                    if (job.jobId === item.jobId) {
                        const newItem = {
                            ...job,
                            isFolded: item.isFolded,
                            tasks: item.tasks,
                        };
                        item = newItem;
                    }
                    return item;
                });
            });
            const newState = {
                ...state,
                error: false,
                data: newData,
            };
            return newState;
        },
        [GET_DOWNLOAD_JOBS_LIST_FAILURE]: (state, action) => {
            const newState = {
                ...state,
                data: [],
                error: true,
            };
            return newState;
        },
        [CREATE_DOWNLOAD_JOB_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const { id } = data;
            const newState = {
                ...state,
                error: false,
            };

            return newState;
        },
        [CREATE_DOWNLOAD_JOB_FAILURE]: (state, action) => {
            const { response } = action.payload;
            const newState = {
                ...state,
                data: [],
                error: true,
                errorStatus: response && response.status,
            };

            return newState;
        },
        [UPDATE_DOWNLOAD_STATE]: (state, action) => {
            return {
                ...state,
                beforeUnloadCheck: state.beforeUnloadCheck + action.payload.beforeUnloadCheck,
            };
        },
        [UPDATE_JOB_STATUS]: (state, action) => {
            return {
                ...state,
                data: action.payload,
            };
        },
    },
    initialState,
);

export const getDownloadJobTask =
    ({ jobId }) =>
    (dispatch, getState) => {
        const { stage, project, downloadJobs } = getState();
        const stageId = stage.id;
        const projectId = project.id;
        const apiEndpoint = stage.endpoint;

        return new Promise((resolve, reject) =>
            JmsApi.listTasks(stageId, apiEndpoint, projectId, jobId, true)
                .then(async (response) => {
                    const errorTask = response.data?.results
                        .filter((job) => job.metadata?.error?.code === "RESOURCE_NOT_FOUND")
                        .map((item) => item.metadata.error);

                    if (response.data?.results.length > 0 && errorTask.length > 0) {
                        downloadJobs.data.map((item) => {
                            if (item.jobId === jobId) {
                                item.metadata.errorTargets = errorTask;
                                item.summary.exitCode = errorTask[0].code;

                                // if (
                                //     item.metadata.isDirectDownloadRequest === "ZIPPING" ||
                                //     response.data?.results.length === errorTask.length
                                // )
                                //     item.status = JMS_STATUS.FAILED;
                            }
                        });

                        dispatch({
                            type: UPDATE_JOB_STATUS,
                            payload: downloadJobs.data,
                        });
                    }
                    resolve(response);
                })
                .catch((error) => {
                    // ToDO
                    reject(error);
                }),
        );
    };

const checkPollingStatus = (jobs, submittedJobIds = []) => {
    return jobs.filter((item) => {
        return (
            submittedJobIds.includes(item.jobId) ||
            !(
                item.status === JMS_STATUS.CANCELED ||
                item.status === JMS_STATUS.FAILED ||
                item.status === JMS_STATUS.SUCCEED
            )
        );
    });
};

const doPolling = (stageId, apiEndpoint, projectId, search, dispatch, submittedJobIds = []) => {
    if (polling) {
        clearTimeout(polling);
    }

    polling = setTimeout(() => {
        JmsApi.listJobs(stageId, apiEndpoint, projectId, search, true, "DOWNLOAD_JOB")
            .then((response) => {
                dispatch({
                    type: UPDATE_MULTIPLE_JOBS,
                    payload: response,
                });
                const results = response.data.results;
                const monitoringTargets = checkPollingStatus(results, submittedJobIds);
                const reusedJobs = findReusedJobs(results);
                const monitoringTasks = checkTaskPollingStatus(stageId, apiEndpoint, projectId, reusedJobs);

                monitoringTargets.push(...monitoringTasks);

                if (monitoringTargets.length > 0) {
                    doPolling(stageId, apiEndpoint, projectId, search, dispatch);
                }

                if (monitoringTargets && monitoringTargets.length > 0) {
                    monitoringTargets.map((item) => {
                        const jobId = item.jobId;
                        const prevRunningJob = Object.keys(runningTasks).find((v) => v.split("_")[0] === jobId);
                        if (!prevRunningJob) pickupRunningTasks(stageId, apiEndpoint, projectId, monitoringTargets);
                    });
                }
                const deduplicatePrevRunningJobs = [];
                Object.keys(runningTasks).map((task) => {
                    const split = task.split("_");
                    const jobId = split[0];
                    const downloadJob = results.find((v) => v.jobId === jobId);
                    if (downloadJob && !deduplicatePrevRunningJobs.find((v) => v.jobId === downloadJob.jobId))
                        deduplicatePrevRunningJobs.push(downloadJob);
                });
                onDownloadTaskSucceeded(stageId, apiEndpoint, projectId, deduplicatePrevRunningJobs, dispatch);
            })
            .catch((error) => {
                if (!navigator.onLine) {
                    dispatch(
                        addPausedPollingApis({
                            type: "DOWNLOAD_JOB",
                            params: { stageId, apiEndpoint, projectId, search },
                        }),
                    );
                }
            });
    }, 4000);
};

const checkTaskPollingStatus = (stageId, apiEndpoint, projectId, jobs) => {
    let pollingJobs = [];
    Promise.all(
        jobs.map(async (job) => {
            const response = await JmsApi.listTasks(stageId, apiEndpoint, projectId, job.jobId, true);
            const tasks = response?.data?.results;

            pollingJobs.push(
                tasks.filter((item) => {
                    return !(item.status === JMS_STATUS.FAILED || item.status === JMS_STATUS.SUCCEED);
                }),
            );
        }),
    );
    return pollingJobs;
};

const findReusedJobs = (jobs = []) => {
    const results = [];

    for (const job of jobs) {
        const statuses = jobStatusMap.get(job.jobId);
        statuses.add(job.status);
        if (isReused(statuses)) results.push({ ...job, reused: true });
    }

    return results;

    function isReused(statuses) {
        return statuses.size === 2 && statuses.has(JMS_STATUS.SUCCEED) && statuses.has(JMS_STATUS.SUBMITTED);
    }
};

export const getDownloadJobs = (submittedJobIds) => (dispatch, getState) => {
    const { stage, project, downloadJobs } = getState();
    const stageId = stage.id;
    const projectId = project.id;
    const apiEndpoint = stage.endpoint;
    const search = downloadJobs.search;

    dispatch({
        type: DOWNLOAD_JOBS_PENDING,
    });

    if (polling) {
        clearTimeout(polling);
        runningTasks = {};
    }

    return new Promise((resolve, reject) =>
        JmsApi.listJobs(stageId, apiEndpoint, projectId, search, true, "DOWNLOAD_JOB")
            .then(async (response) => {
                dispatch({
                    type: GET_DOWNLOAD_JOBS_LIST_SUCCESS,
                    payload: response,
                });

                if (response.data) {
                    const results = response.data.results;
                    results.forEach(({ jobId, status }) => jobStatusMap.set(jobId, new Set([status])));

                    const monitoringTargets = checkPollingStatus(results, submittedJobIds);

                    if (monitoringTargets.length > 0) {
                        doPolling(stageId, apiEndpoint, projectId, search, dispatch, submittedJobIds);
                    }

                    pickupRunningTasks(stageId, apiEndpoint, projectId, monitoringTargets);
                }

                resolve(response);
            })
            .catch((error) => {
                // ToDO
                if (!navigator.onLine) {
                    dispatch(
                        addPausedPollingApis({
                            type: "DOWNLOAD_JOB",
                            params: { stageId, apiEndpoint, projectId, search },
                        }),
                    );
                }
                dispatch({
                    type: GET_DOWNLOAD_JOBS_LIST_FAILURE,
                    payload: error.response,
                });
                resolve(error);
            }),
    );
};

const pickupRunningTasks = (stageId, apiEndpoint, projectId, jobs) => {
    Promise.all(
        jobs.map(async (item) => {
            const tasks = await JmsApi.listTasks(stageId, apiEndpoint, projectId, item.jobId, true);
            const taskResults = tasks?.data?.results;
            const runningTaskList = taskResults && taskResults.length > 0 ? [...taskResults] : [];

            if (item.reused === true) {
                runningTaskList.push(...taskResults);
            }

            runningTaskList.map((task) => {
                runningTasks[`${item.jobId}_${task.taskId}`] = task.status;
            });
        }),
    );
};

const onDownloadTaskSucceeded = (stageId, apiEndpoint, projectId, jobs, dispatch) => {
    Promise.all(
        jobs.map(async (job) => {
            const tasks = await JmsApi.listTasks(stageId, apiEndpoint, projectId, job.jobId, true);
            const taskResults = tasks?.data?.results;
            const item = {
                ...job,
                tasks: taskResults,
            };
            dispatch({
                type: UPDATE_SINGLE_JOB,
                payload: { job: item },
            });

            const runningTaskList =
                taskResults &&
                taskResults.length > 0 &&
                taskResults.filter((v) => v.status === JMS_STATUS.SUCCEED || v.status === JMS_STATUS.FAILED);

            runningTaskList &&
                runningTaskList.map((task) => {
                    const key = `${job.jobId}_${task.taskId}`;
                    const prevStatus = runningTasks[key];

                    if (!prevStatus) return;
                    if (task.status === JMS_STATUS.SUCCEED) {
                        const statuses = jobStatusMap.get(job.jobId);
                        statuses.clear();
                        statuses.add(JMS_STATUS.SUCCEED);

                        dispatch(downloadCompressedFile(job, task.name));
                    }

                    delete runningTasks[key];
                });
        }),
    );
};

export const retryJob = (jobId) => (dispatch, getState) => {
    const { stage, project } = getState();
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;
    const stageId = stage.id;
    const actions = {
        action: "RETRY_JOB",
        content: {
            jobId: jobId,
        },
    };
    return new Promise((resolve, reject) => {
        return JmsApi.requestActions(apiEndpoint, stageId, projectId, actions)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getJobStatus = (jobId) => (dispatch, getState) => {
    const { stage, project } = getState();
    // const cancelTokenSource = axios._cancelToken.source();
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;
    const stageId = stage.id;
    const actions = {
        action: "GET_JOB_STATUS",
        content: {
            jobIds: [jobId],
        },
    };
    return new Promise((resolve, reject) => {
        return JmsApi.requestActions(apiEndpoint, stageId, projectId, actions)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const createDownloadJob = (domain, obj, options, jobProjectId) => (dispatch, getState) => {
    const { stage, project } = getState();
    const apiEndpoint = stage.endpoint;
    const projectId = jobProjectId ?? project.id;
    const stageId = stage.id;
    const stageVersion = stage.version;

    let param = {
        domain,
    };
    if (domain === "STORAGE" || domain === "PROJECT_RESOURCES") param = { ...param, targets: obj };
    else if (domain === "ASSET")
        param = { ...param, resourceId: obj.resourceId, resourceIds: obj.resourceIds, projectId: obj.projectId };
    else if (domain === "COMMENT_ATTACHMENT")
        param = {
            ...param,
            resourceId: obj.resourceId,
            projectId: obj.projectId,
            threadId: obj.threadId,
            targets: obj.targets,
        };
    else if (domain === "EXPORTED_METADATA") param = { ...param, resourceId: obj.resourceId };

    return new Promise((resolve, reject) => {
        return JmsApi.createJob(apiEndpoint, stageId, stageVersion, projectId, param, options)
            .then((response) => {
                dispatch({
                    type: CREATE_DOWNLOAD_JOB_SUCCESS,
                    payload: response,
                });

                resolve(response);
            })
            .catch((error) => {
                dispatch({
                    type: CREATE_DOWNLOAD_JOB_FAILURE,
                    payload: error,
                });

                reject(error);
            });
    });
};

export const downloadCompressedFile = (job, taskName) => (dispatch, getState) => {
    const { stage, token } = getState();
    const apiEndpoint = stage.endpoint;
    const jobId = job.jobId;
    const projectId = job.projectId;
    const stageId = stage.id;

    dispatch({ type: UPDATE_DOWNLOAD_STATE, payload: { beforeUnloadCheck: 1 } });
    return new Promise((resolve, reject) => {
        return JmsApi.postDownload(stageId, apiEndpoint, token.value, projectId, jobId, taskName)
            .then((response) => {
                downloadLink(response.data.link);

                dispatch({ type: UPDATE_DOWNLOAD_STATE, payload: { beforeUnloadCheck: -1 } });
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const updateSearch = createAction(UPDATE_DOWNLOAD_JOBS_SEARCH);
export const updateJob = createAction(UPDATE_SINGLE_JOB);
export const updateState = createAction(UPDATE_DOWNLOAD_STATE);
export const updateJobStatus = createAction(UPDATE_JOB_STATUS);
