import { axios, axiosWithoutHeaders } from "../cores/axiosWrapper";
import config from "../config";

import en from "../_translation/en/translation.json";
import ja from "../_translation/ja/translation.json";
import ko from "../_translation/ko/translation.json";

export function getLocalResource(value) {
    switch (value) {
        case "ko":
            return ko;
        case "en":
            return en;
        case "ja":
            return ja;
        default:
            return en;
    }
}

export function getLocalLngList(defaultLanguageCode, callback) {
    const locales = [
        {
            id: 1,
            label: "English",
            value: "en",
            path: "mzc-localization-store/en/translation.json",
            isDefault: 1,
        },
        {
            id: 2,
            label: "한국어",
            value: "ko",
            path: "mzc-localization-store/ko/translation.json",
            isDefault: 0,
        },
        {
            id: 3,
            label: "日本語",
            value: "ja",
            path: "mzc-localization-store/ja/translation.json",
            isDefault: 0,
        },
    ];

    getLocalLng(defaultLanguageCode, callback);

    return {
        locales,
        translation: getLocalResource(defaultLanguageCode),
    };

    /*
    return await axios.get(`${config.host.api}/v1/spaces/mz-cm-v1/localization/languages`)
        .then((res) => {
            return res.data;
        })
    */
}

export function getDefaultLanguage() {
    return "en";
}

export function getLocalLng(code, callback) {
    if (code === "en" || code === "ja" || code === "ko") {
        axios.get(`https://d4nteyimjwvut.cloudfront.net/${code.split("-")?.[0]}/translation.json`).then((res) => {
            console.log("translation");
            callback(code, res.data);
        });
    }

    return getLocalResource(code);

    /*
    return await axios.get(`${config.host.api}/v1/spaces/mz-cm-v1/localization/languages/${languageId}`)
        .then((res) => {
            return res.data;
        })
    */
}
