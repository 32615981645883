import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { bindActionCreators } from "redux";

import * as sourcesAction from "@/modules/sources";
import ObjectIcon from "@routes/sources/components/ObjectIcon";
import convertToByte from "@cores/convertToByte";
import TooltipUserInfo from "../tooltip/userInfo";
import ProjectInfo from "./domainOverview/ui/ProjectInfo";
import StatusLabel from "@components/status-label";
import { SHARE_MAIL_LANGUAGE, PROPERTY_PANEL_TYPE } from "@constants";
import { IconAssets, IconEmptyStorage, IconShareLink } from "@mzc-cloudplex/icons";
import moment from "moment";

const ExternalShareOverview = (props) => {
    const { t } = props;

    const getIsExpired = useMemo(() => {
        const expires = moment(props.expiresAt).add(9, "h").format("YYYY-MM-DD");
        const today = moment().format("YYYY-MM-DD");

        if (today >= expires)
            return (
                <span>
                    {expires}
                    {` `}
                    <span className={"text-danger"}>(Expired)</span>
                </span>
            );
        else return expires;
    }, [props]);

    return (
        <React.Fragment>
            <div className={`list-overview`}>
                <ul>
                    <li>
                        <strong className="title">{t(`common::label::${"ID"}`)}</strong>
                        <div className="content">{props.id}</div>
                    </li>

                    <li>
                        <strong className="title">{t(`common::label::${"Type"}`)}</strong>
                        <div className="content">
                            <div className={"d-flex align-items-center"}>
                                {props.domain === "STORAGE" ? (
                                    <>
                                        <div className={"format-img format-img-storage"}>
                                            <IconEmptyStorage size={12} htmlColor={"#fff"} />
                                        </div>
                                        {PROPERTY_PANEL_TYPE.STORAGE}
                                    </>
                                ) : (
                                    <>
                                        <div className={"format-img format-img-asset"}>
                                            <IconAssets size={12} htmlColor={"#fff"} />
                                        </div>
                                        {PROPERTY_PANEL_TYPE.ASSET}
                                    </>
                                )}
                            </div>
                        </div>
                    </li>
                    <li>
                        <strong className="title">{t(`common::label::${"Status of ready"}`)}</strong>
                        <div className="content">
                            <StatusLabel type={props.readyStatus} />
                        </div>
                    </li>
                </ul>
                <hr />
                <ul>
                    <li>
                        <strong className="title">{t(`common::label::${"Expiration Date"}`)}</strong>
                        <div className="content">{getIsExpired}</div>
                    </li>
                    <li>
                        <strong className="title">{t(`common::label::${"Number of Downloads"}`)}</strong>
                        <div className="content">{`Max ${props.maxDownloads} Times`}</div>
                    </li>
                    <li>
                        <strong className="title">{t(`common::label::${"Send email with password"}`)}</strong>
                        <div className="content">{props.isPwIncluded ? "Include" : "Not Include"}</div>
                    </li>
                    <li>
                        <strong className="title">{t(`common::label::${"Mail Language Settings"}`)}</strong>
                        <div className="content">
                            {SHARE_MAIL_LANGUAGE.find((v) => v.value === props.recipientSummary?.mailLanguage)?.label ||
                                "English"}
                        </div>
                    </li>
                </ul>
                <hr />
                <ul>
                    {props.project && props.project.id && <ProjectInfo projectId={props.project.id} />}
                    <li>
                        <strong className="title">{t(`common::label::${"Created by"}`)}</strong>
                        <div className="content">
                            {`${props.createdBy.name} (${props.createdBy.username})`}
                            <TooltipUserInfo target={props.createdBy} id={props.createdBy.id} />
                        </div>
                    </li>
                    <li>
                        <strong className="title">{t(`common::label::${"Created"}`)}</strong>
                        <div className="content">{props.createdAt}</div>
                    </li>
                </ul>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(ExternalShareOverview);
