import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "core-js/stable";
import "regenerator-runtime/runtime";
import "promise-polyfill/src/polyfill";

import React from "react";
import ReactDOM from "react-dom";

import "./lib";
import { i18init } from "./lib/i18n";

import Root from "./Root";
// import registerServiceWorker from "./registerServiceWorker";
import { store } from "./store";
import { hotjar } from "react-hotjar";
import getConfig from "./config";

async function run() {
    await i18init();
    ReactDOM.render(<Root store={store} />, document.getElementById("root"));
    const { hotjar_tracking_code } = getConfig;

    if (hotjar_tracking_code) hotjar.initialize(hotjar_tracking_code.hjid, hotjar_tracking_code.hjsv);
    // registerServiceWorker();
}

const loadedStates = ["complete", "loaded", "interactive"];

if (loadedStates.indexOf(document.readyState) !== -1 && document.body) {
    run();
} else {
    window.addEventListener("DOMContentLoaded", run, false);
}
