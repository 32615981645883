import { handleActions, createAction } from "redux-actions";
import { axios, axiosWithoutHeaders } from "../cores/axiosWrapper";
import config from "../config";

import moment from "moment";
import pad from "../cores/string.pad";

import currentViewers from "../template/analysis/currentViewers";
import cumulativeViewers from "../template/analysis/cumulativeViewers";
import cumulativeViewCount from "../template/analysis/cumulativeViewCount";
import cumulativeViewingTime from "../template/analysis/cumulativeViewingTime";
import upperRegion from "../template/analysis/upperRegion";
import upperDevice from "../template/analysis/upperDevice";

function getStatisticsAPI(
    stageId,
    apiEndpoint,
    projectId,
    resourceType,
    statisticsType,
    data,
    resourceToken,
    dispatch,
) {
    if (
        statisticsType === "cumulativeViewingTime" ||
        statisticsType === "cumulativeViewers" ||
        statisticsType === "cumulativeViewCount" ||
        statisticsType === "currentViewers" ||
        statisticsType === "upperDevice" ||
        statisticsType === "upperRegion"
    ) {
        const templateString = JSON.stringify(STATISTICS_TYPE.find((v) => v.type === statisticsType).template);

        const template = templateString
            .replace(/{{:resourceType:}}/gi, getResourceTypeResourceApi(resourceType))
            .replace(/{{:id:}}/gi, data.id)
            .replace(
                /{{:startDateTime:}}/gi,
                statisticsType !== "currentViewers"
                    ? data.startDateTime
                    : moment.utc(moment().add(-5, "minute")).format("YYYY-MM-DDTHH:mm:ss"),
            )
            .replace(
                /{{:endDateTime:}}/gi,
                statisticsType !== "currentViewers"
                    ? data.endDateTime
                    : moment.utc(moment()).format("YYYY-MM-DDTHH:mm:ss"),
            );

        axios
            .post(`${apiEndpoint}/statistics/resource`, template, {
                headers: {
                    "Content-Type": "application/json",
                    projectId,
                    "x-mzc-cp-resource-token": resourceToken,
                },
            })
            .then((response) => {
                dispatch({
                    type: GET_STATISTICS_SUCCESS,
                    payload: {
                        type: statisticsType,
                        data: response.data,
                    },
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_STATISTICS_FAILURE,
                    payload: {
                        type: statisticsType,
                        error,
                    },
                });
            });
    } else {
        throw new Error("Unknown statistics type");
    }
}

function getResourceTypeQuery(resourceType) {
    switch (resourceType) {
        case "VIDEO":
            return "video-id";
        case "ASSET":
            return "asset-id";
        case "CHANNEL":
            return "channel-id";
    }
}

function getResourceTypeResourceApi(resourceType) {
    switch (resourceType) {
        case "VIDEO":
            return "VIDEO_ID";
        case "ASSET":
            return "ASSET_ID";
        case "CHANNEL":
            return "CHANNEL_ID";
    }
}

//action type
const STATISTICS_ALL_PENDING = "STATISTICS_ALL_PENDING";
const GET_STATISTICS_SUCCESS = "GET_STATISTICS_SUCCESS";
const GET_STATISTICS_FAILURE = "GET_STATISTICS_FAILURE";
const INIT_STATISTICS = "INIT_STATISTICS";

const STATISTICS_TYPE = [
    {
        type: "currentViewers",
        template: currentViewers,
    },
    {
        type: "cumulativeViewers",
        template: cumulativeViewers,
    },
    {
        type: "cumulativeViewCount",
        template: cumulativeViewCount,
    },
    {
        type: "cumulativeViewingTime",
        template: cumulativeViewingTime,
    },
    {
        type: "upperRegion",
        template: upperRegion,
    },
    {
        type: "upperDevice",
        template: upperDevice,
    },
];

const initialState = {
    data: {
        // 통계 데이터
        currentViewers: {
            pending: false,
            error: false,
            displayName: "common::label::현재 시청자 수",
            value: "",
        },
        cumulativeViewers: {
            pending: false,
            error: false,
            displayName: "common::label::누적 시청자 수",
            value: "",
        },
        cumulativeViewCount: {
            pending: false,
            error: false,
            displayName: "common::label::누적 시청 횟수",
            value: "",
        },
        cumulativeViewingTime: {
            pending: false,
            error: false,
            displayName: "common::label::누적 시청 시간",
            value: "",
        },
        upperRegion: {
            pending: false,
            error: false,
            displayName: "common::label::상위 지역",
            value: "",
        },
        upperDevice: {
            pending: false,
            error: false,
            displayName: "common::label::상위 디바이스",
            value: "",
        },
    },
};

export default handleActions(
    {
        [STATISTICS_ALL_PENDING]: (state, action) => {
            return {
                ...state,
                data: {
                    currentViewers: {
                        ...state.data.currentViewers,
                        pending: true,
                        error: false,
                    },
                    cumulativeViewers: {
                        ...state.data.cumulativeViewers,
                        pending: true,
                        error: false,
                    },
                    cumulativeViewCount: {
                        ...state.data.cumulativeViewCount,
                        pending: true,
                        error: false,
                    },
                    cumulativeViewingTime: {
                        ...state.data.cumulativeViewingTime,
                        pending: true,
                        error: false,
                    },
                    upperRegion: {
                        ...state.data.upperRegion,
                        pending: true,
                        error: false,
                    },
                    upperDevice: {
                        ...state.data.upperDevice,
                        pending: true,
                        error: false,
                    },
                },
            };
        },
        [GET_STATISTICS_FAILURE]: (state, action) => {
            const { type, error } = action.payload;

            return {
                ...state,
                data: {
                    ...state.data,
                    [type]: {
                        ...state.data[type],
                        pending: false,
                        error: true,
                        value: "",
                    },
                },
            };
        },
        [GET_STATISTICS_SUCCESS]: (state, action) => {
            const { type, data } = action.payload;
            return {
                ...state,
                data: {
                    ...state.data,
                    [type]: {
                        ...state.data[type],
                        pending: false,
                        error: false,
                        value: parseStatisticsData(type, data),
                    },
                },
            };
        },
        [INIT_STATISTICS]: (state) => {
            return {
                ...initialState,
            };
        },
    },
    initialState,
);

function parseStatisticsData(type, data) {
    switch (type) {
        case "currentViewers": // 현재 시청자 수
        case "cumulativeViewers": // 누적 시청자 수
        case "cumulativeViewCount": {
            // 누적 시청 횟수
            let count = 0;
            if (data && data.value && data.value.value) count = data.value.value;

            return count;
        }
        case "cumulativeViewingTime": {
            // 누적 시청 시간
            let duration = 0;
            if (data && data.value && data.value.value) duration = data.value.value;

            if (!duration || duration < 0) return "00:00:00";

            let hour = moment.duration(duration, "second").hours() + moment.duration(duration, "second").days() * 24;
            let min = moment.duration(duration, "second").minutes();
            let sec = moment.duration(duration, "second").seconds();

            return `${pad(hour, 2)}:${pad(min, 2)}:${pad(sec, 2)}`;
        }
        case "upperRegion":
        case "upperDevice": {
            if (!data.value) return "";
            if (data.value.value.length === 0) return "";
            return data.value.value[0].key;
        }
    }
}

export const getStatistics = (projectId, resourceType, data, resourceToken) => (dispatch, getState) => {
    let { stage, project } = getState();
    dispatch({ type: STATISTICS_ALL_PENDING, payload: data });
    // console.log('hhhhhh', resourceToken)
    STATISTICS_TYPE.map((v) => {
        getStatisticsAPI(
            stage.id,
            stage.endpoint,
            projectId ?? project.id,
            resourceType,
            v.type,
            data,
            resourceToken,
            dispatch,
        );
    });
};

function getStatisticsFromSharedAPI(
    stageId,
    apiEndpoint,
    projectId,
    resourceType,
    statisticsType,
    data,
    resourceToken,
    dispatch,
) {
    if (
        statisticsType === "cumulativeViewingTime" ||
        statisticsType === "cumulativeViewers" ||
        statisticsType === "cumulativeViewCount" ||
        statisticsType === "currentViewers" ||
        statisticsType === "upperDevice" ||
        statisticsType === "upperRegion"
    ) {
        const templateString = JSON.stringify(STATISTICS_TYPE.find((v) => v.type === statisticsType).template);

        const template = templateString
            .replace(/{{:resourceType:}}/gi, getResourceTypeResourceApi(resourceType))
            .replace(/{{:id:}}/gi, data.id)
            .replace(
                /{{:startDateTime:}}/gi,
                statisticsType !== "currentViewers"
                    ? data.startDateTime
                    : moment.utc(moment().add(-5, "minute")).format("YYYY-MM-DDTHH:mm:ss"),
            )
            .replace(
                /{{:endDateTime:}}/gi,
                statisticsType !== "currentViewers"
                    ? data.endDateTime
                    : moment.utc(moment()).format("YYYY-MM-DDTHH:mm:ss"),
            );

        axios
            .post(`${apiEndpoint}/v2/shared-assets/proxy/statistics/resource`, template, {
                headers: {
                    "Content-Type": "application/json",
                    projectId,
                    "x-mzc-cp-resource-token": resourceToken,
                },
            })
            .then((response) => {
                dispatch({
                    type: GET_STATISTICS_SUCCESS,
                    payload: {
                        type: statisticsType,
                        data: response.data,
                    },
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_STATISTICS_FAILURE,
                    payload: {
                        type: statisticsType,
                        error,
                    },
                });
            });
    } else {
        throw new Error("Unknown statistics type");
    }
}

export const getStatisticsFromShared = (resourceType, data, resourceToken) => (dispatch, getState) => {
    let { stage, project } = getState();

    dispatch({ type: STATISTICS_ALL_PENDING, payload: data });

    STATISTICS_TYPE.map((v) => {
        getStatisticsFromSharedAPI(
            stage.id,
            stage.endpoint,
            project.id,
            resourceType,
            v.type,
            data,
            resourceToken,
            dispatch,
        );
    });
};

export const initStatisticsSummary = createAction(INIT_STATISTICS);
