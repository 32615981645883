import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    OutlinedInput,
    palette,
    Select,
    TextField,
    InputAdornment,
    Avatar,
    Stack,
    FormControlLabel,
    Checkbox,
} from "@mzc-pdc/ui";
import Typography from "@components_v2/typography/typography";
import theme from "@styles/theme";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconForward, ImageFolderSmallOn } from "@mzc-cloudplex/icons";
import CategoriesInput from "@features/categories-input/categories-input";
import FolderInput from "@features/folder-input/folder-input";
import ProjectSingleInput from "@features/projects-input/project-single-input";
import { copyAsset } from "@modules/asset";
import { useDispatch, useSelector } from "react-redux";
import { getProjectRandomColor } from "@utils/get-random-color";
import { setNotification } from "@modules/notification";
import useActionPending from "@hooks/useActionPending";
import { listJobs } from "@modules/bulkJobs";
import i18n from "@lib/i18n";

const DialogAssetFolderDuplicate = ({ folder, onClickClose, ...props }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        pending: createPending,
        setActionPending: setCreatePending,
        disableActionPending: disableCreatePending,
    } = useActionPending("create");

    const [categoryGroupTooltipDisabled, setCategoryGroupTooltipDisabled] = useState(true);
    const [editValue, setEditValue] = useState({ recursive: false });
    const projects = useSelector(({ stage }) => stage?.projects);
    const projectId = useSelector(({ stage }) => stage?.project?.id);

    const getProjectName = useCallback(
        (id) => {
            return projects?.find((el) => el.id === id)?.name;
        },
        [projects],
    );
    const onHandleEditValue = useCallback(
        (key, value) => {
            if (key === "project" && value.id !== projectId) {
                setEditValue({ ...editValue, [key]: value, categories: [], categoryGroup: {} });
            } else {
                setEditValue({ ...editValue, [key]: value });
            }
        },
        [editValue],
    );

    const onHandleChangeCategories = useCallback(
        (options, categoryGroup) => {
            let categoryGroupTooltip = categoryGroupTooltipDisabled;

            if (editValue.categoryGroup?.id !== options.categoryGroup?.id) {
                categoryGroupTooltip = false;
            }
            if (options.categoryGroup) {
                setCategoryGroupTooltipDisabled(categoryGroupTooltip);
                setEditValue({
                    ...editValue,
                    categoryGroup: { id: options.categoryGroup.id, name: options.categoryGroup.label },
                    categories: options.categories,
                });
            } else {
                setEditValue({
                    ...editValue,
                    categories: options,
                    categoryGroup: categoryGroup ? { id: categoryGroup.id } : undefined,
                });
            }
        },
        [editValue, categoryGroupTooltipDisabled],
    );

    const onHandleClickSubmit = async () => {
        try {
            setCreatePending();
            const { project, categories, categoryGroup, virtualFolder, recursive } = editValue;
            const { id, driveId } = folder;

            const params = {
                destination: { nameMap: [] },
                source: {
                    virtualFolder: { id, driveId, recursive },
                },
            };
            if (project) params.destination.project = { id: project?.id };
            if (categories)
                params.destination.categories = categories?.map((el) => ({
                    id: el.id,
                    groupId: categoryGroup?.id,
                }));

            if (virtualFolder.driveId && virtualFolder.folderId)
                params.destination.virtualFolder = { id: virtualFolder?.folderId, driveId: virtualFolder?.driveId };
            else
                params.destination.virtualFolder = {
                    id: virtualFolder?.defaultFolder?.rootFolder?.id,
                    driveId: virtualFolder?.defaultFolder?.id,
                };

            await dispatch(copyAsset(params));
            await dispatch(listJobs());

            await dispatch(
                setNotification({
                    type: "success",
                    contents: t(`common::msg::${"Asset {{item}} duplication completed."}`, {
                        item: folder.name,
                    }),
                }),
            );
            onClickClose();
        } catch (e) {
            console.log(e);
        } finally {
            disableCreatePending();
        }
    };

    const currentFolderProject = useMemo(() => {
        return { id: folder?.driveInfo?.project?.id, name: getProjectName(folder.driveInfo?.project?.id) };
    }, [folder, projects]);

    useEffect(() => {
        setEditValue({
            ...editValue,
            project: currentFolderProject,
        });
    }, [folder, projects, projectId]);

    return (
        <Dialog
            variant="basic"
            onClose={() => onClickClose()}
            open={true}
            sx={{
                ".MuiDialog-container >.MuiPaper-root": { minWidth: "768px", gap: 0 },
                ".MuiDialogContent-root": { scrollbarGutter: "unset" },
            }}
        >
            <DialogTitle
                sx={{
                    zIndex: 1,
                }}
            >
                <Grid container>
                    <Typography variant={"h3"}>
                        {t(`common::label::Folder Duplication`, "Folder Duplication")}
                    </Typography>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container flexDirection={"column"} pb={3} gap={3} flexWrap={"nowrap"}>
                    <Grid item sx={{ zIndex: 0 }}>
                        <Box
                            component="fieldset"
                            sx={{
                                px: theme.spacing(1.5),
                                py: theme.spacing(1),
                                borderRadius: theme.spacing(0.5),
                                border: `1px solid ${theme.palette.greySecondary[100]}`,
                            }}
                        >
                            <legend>
                                <Typography
                                    variant="body3_400"
                                    sx={{ px: 0.5 }}
                                    color={theme.palette.greySecondary[600]}
                                >
                                    {t(`common::label::Duplication target folder`, "Duplication target folder")}
                                </Typography>
                            </legend>
                            <Stack direction={"column"} gap={theme.spacing(1.5)}>
                                <Stack direction={"row"} gap={theme.spacing(1)}>
                                    <Stack>
                                        {folder.parentInfo.name ? <ImageFolderSmallOn width={16} height={16} /> : null}
                                    </Stack>
                                    <Stack>{folder.name}</Stack>
                                </Stack>
                                <Stack ml={theme.spacing(3.5)}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color={"greySecondary"}
                                                value={editValue.recursive}
                                                onChange={(e) => {
                                                    onHandleEditValue("recursive", e.target.checked);
                                                }}
                                                sx={{ "& .MuiSvgIcon-root": { fontSize: 16 } }}
                                            />
                                        }
                                        label={i18n.t(`common::label::Duplicate within a subfolder`)}
                                    />
                                </Stack>
                            </Stack>
                        </Box>
                    </Grid>

                    <Grid item>
                        <Grid
                            container
                            flexDirection={"column"}
                            gap={3}
                            p={2}
                            sx={{
                                borderRadius: "4px",
                                border: `solid 1px ${palette.greySecondary[100]}`,
                            }}
                        >
                            <Grid item sx={{ width: "100%" }}>
                                <Stack direction={"column"}>
                                    <Grid container flexDirection={"row"} pb={1.5} gap={7}>
                                        <Grid item xs={5.5}>
                                            <Typography variant={"body3_500"} color={palette.greySecondary[600]}>
                                                {t(`common::label::Current location`)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={5.5}>
                                            <Typography variant={"body3_500"} color={palette.greySecondary[600]}>
                                                {t(`common::label::Where to clone`)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider flexItem orientation={"horizontal"} />
                                </Stack>
                            </Grid>

                            {/*프로젝트 선택 영역*/}
                            <Grid item>
                                <Grid container gap={2.5} alignItems={"center"} flexWrap={"nowrap"}>
                                    <Grid item xs={5.5}>
                                        <TextField
                                            fullWidth
                                            value={getProjectName(folder.driveInfo?.project?.id)}
                                            label={t(`common::label::Project`)}
                                            InputProps={{
                                                readOnly: true,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Avatar
                                                            sx={{
                                                                width: 20,
                                                                height: 20,
                                                                fontSize: 11,
                                                                fontWeight: `bold`,
                                                                bgcolor: getProjectRandomColor(
                                                                    folder.driveInfo?.project?.id,
                                                                ),
                                                            }}
                                                        >
                                                            {`${getProjectName(folder.driveInfo?.project?.id)?.charAt(
                                                                0,
                                                            )}`}
                                                        </Avatar>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            sx={{
                                                "&.MuiTextField-root": {
                                                    ".MuiInputBase-readOnly": {
                                                        backgroundColor: `#f8f8f8`,
                                                    },
                                                },
                                            }}
                                        ></TextField>
                                    </Grid>
                                    <Grid item>
                                        <IconForward
                                            size={16}
                                            htmlColor={theme.palette.greySecondary[500]}
                                        ></IconForward>
                                    </Grid>
                                    <Grid item xs={5.5}>
                                        <ProjectSingleInput
                                            data={editValue.project ?? currentFolderProject}
                                            defaultValue={currentFolderProject}
                                            onChange={(v) =>
                                                onHandleEditValue(
                                                    "project",
                                                    v ?? {
                                                        id: folder.driveInfo?.project?.id,
                                                        name: getProjectName(folder.driveInfo?.project?.id),
                                                    },
                                                )
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/*상위 폴더 위치*/}
                            <Grid item>
                                <Grid container gap={2.5} alignItems={"center"} flexWrap={"nowrap"}>
                                    <Grid item xs={5.5}>
                                        <TextField
                                            fullWidth
                                            value={folder.parentInfo.name}
                                            label={t(`common::label::Parent folder location`)}
                                            InputProps={{
                                                readOnly: true,
                                                startAdornment: (
                                                    <InputAdornment position="start" sx={{ p: 0.25 }}>
                                                        {folder.parentInfo.name ? (
                                                            <ImageFolderSmallOn width={16} height={16} />
                                                        ) : null}
                                                    </InputAdornment>
                                                ),
                                            }}
                                            sx={{
                                                "&.MuiTextField-root": {
                                                    ".MuiInputBase-readOnly": {
                                                        backgroundColor: `#f8f8f8`,
                                                    },
                                                },
                                            }}
                                        ></TextField>
                                    </Grid>
                                    <Grid item>
                                        <IconForward
                                            size={16}
                                            htmlColor={theme.palette.greySecondary[500]}
                                        ></IconForward>
                                    </Grid>
                                    <Grid item xs={5.5}>
                                        <FolderInput
                                            fullWidth
                                            domain={"ASSET"}
                                            projectId={editValue.project?.id ?? projectId}
                                            placement={"bottom-end"}
                                            disablePortal={false}
                                            useDefaultFolder={true}
                                            value={editValue.virtualFolder}
                                            onSubmit={(v) => onHandleEditValue("virtualFolder", v)}
                                            onClose={() => onClickClose()}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/*카테고리 선택 영역*/}
                            <Grid item>
                                <Grid container gap={2.5} alignItems={"center"} flexWrap={"nowrap"}>
                                    <Grid item xs={5.5}>
                                        <TextField
                                            fullWidth
                                            disabled
                                            value={"-"}
                                            label={t(`common::label::Category`)}
                                            sx={{
                                                "&.MuiTextField-root": {
                                                    ".MuiInputBase-readOnly": {
                                                        backgroundColor: `#f8f8f8`,
                                                    },
                                                },
                                            }}
                                        ></TextField>
                                    </Grid>
                                    <Grid item>
                                        <IconForward
                                            size={16}
                                            htmlColor={theme.palette.greySecondary[500]}
                                        ></IconForward>
                                    </Grid>
                                    <Grid item xs={5.5}>
                                        <CategoriesInput
                                            type={"Asset"}
                                            disablePortal={false}
                                            project={editValue.project}
                                            placement={"bottom-end"}
                                            tooltipDisabled={categoryGroupTooltipDisabled}
                                            selectedCategoryGroup={{
                                                id: editValue.categoryGroup?.id,
                                                label: editValue.categoryGroup?.name,
                                            }}
                                            placeholder={t(`common::label::${"Select category"}`)}
                                            selectedValues={editValue.categories ?? []}
                                            onChange={(options, categoryGroup) => {
                                                onHandleChangeCategories(options, categoryGroup);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <Divider flexItem orientation={"horizontal"} />
            <DialogActions>
                <Button color="secondary" onClick={onClickClose} variant="text">
                    {t(`common::label::Cancel`, "Cancel")}
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={onHandleClickSubmit}
                    loading={createPending}
                    disabled={createPending}
                >
                    {t(`common::label::Duplicate`, "Duplicate")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogAssetFolderDuplicate;
