import { OPEN_MENU, CLOSE_MENU, TOGGLE_MENU, SET_MENU, OPEN_NAVIGATOR, CLOSE_NAVIGATOR, FOLD_MENU } from "./actions";
import { getContentSchemasAPI } from "@modules/apis/content-schemas";
import { getContentSchemaDetailAPI } from "@modules/apis/content-schema";

const GET_CONTENT_SCHEMAS_PENDING = "GET_CONTENT_SCHEMAS_PENDING";
const GET_CONTENT_SCHEMAS_SUCCESS = "GET_CONTENT_SCHEMAS_SUCCESS";
const GET_CONTENT_SCHEMAS_FAILURE = "GET_CONTENT_SCHEMAS_FAILURE";

const navigatorInitialState = {
    contents: {
        data: null,
        pending: false,
        error: false,
    },
    menus: [
        {
            id: "dashboard",
            name: "DashBoard",
            path: "/dashboard",
            iconClass: "fa fa-home menu-icon",
            disabled: true,
            active: false,
        },
        {
            id: "workflow",
            name: "Workflow",
            path: "/workflow",
            iconClass: "fa fa-cogs menu-icon",
            disabled: true,
            active: false,
        },
        {
            id: "profile",
            name: "Profile",
            path: "/profile",
            iconClass: "fa fa-cogs menu-icon",
            disabled: true,
            active: false,
        },
        {
            id: "transcoding",
            name: "Transcoding",
            iconClass: "sprite sprite-menu-transcoding",
            path: "/transcoding/jobs",
            disabled: true,
            active: false,
            subMenus: [
                {
                    name: "Jobs",
                    path: "/transcoding/jobs",
                    disabled: true,
                },
                {
                    name: "Create Job",
                    path: "/transcoding/upload",
                    disabled: true,
                },
            ],
        },
        {
            id: "contents",
            name: "Contents",
            iconClass: "sprite sprite-menu-media",
            path: "/contents/assets",
            disabled: true,
            subMenus: [
                {
                    id: "sources",
                    name: "Sources",
                    iconClass: "sprite sprite-menu-media",
                    disabled: true,
                    path: "/contents/sources",
                },
                {
                    id: "assets",
                    name: "Assets",
                    iconClass: "sprite sprite-menu-media",
                    disabled: true,
                    path: "/contents/assets",
                },
                {
                    id: "videos",
                    name: "Videos",
                    iconClass: "sprite sprite-menu-media",
                    disabled: true,
                    path: "/contents/videos",
                },
                {
                    id: "people",
                    name: "People",
                    iconClass: "sprite sprite-menu-media",
                    disabled: true,
                    path: "/contents/people",
                },
                {
                    id: "programs",
                    name: "Programs",
                    iconClass: "sprite sprite-menu-media",
                    disabled: true,
                    path: "/contents/programs",
                },
            ],
        },
        {
            id: "live",
            name: "Live",
            iconClass: "sprite sprite-menu-live",
            path: "/live/streams",
            disabled: true,
            active: false,
            subMenus: [
                {
                    name: "Streams",
                    path: "/live/streams",
                    disabled: true,
                },
                {
                    name: "Schedules",
                    path: "/live/schedules",
                    disabled: true,
                },
                {
                    id: "channels",
                    name: "Channels",
                    path: "/live/channels",
                    disabled: true,
                },
            ],
        },
        {
            id: "admin",
            name: "Admin",
            path: "/admin/space/users",
            iconClass: "sprite sprite-menu-admin",
            disabled: true,
            active: false,
        },
    ],
    projectMenus: [],
    active: true,
};

const navigator = (state = navigatorInitialState, action) => {
    switch (action.type) {
        case OPEN_MENU: {
            let menus = state.menus.map((menu) => {
                menu.active = menu.id === action.id;
                return menu;
            });

            return Object.assign({}, state, {
                menus,
            });
        }
        case CLOSE_MENU: {
            let menus = state.menus.map((menu) => {
                if (menu.id === action.id) {
                    menu.active = false;
                }
                return menu;
            });

            return Object.assign({}, state, {
                menus,
            });
        }
        case TOGGLE_MENU: {
            let menus = state.menus.map((menu) => {
                if (menu.id === action.id) {
                    menu.isOpen = !menu.isOpen;
                }

                return menu;
            });
            return Object.assign({}, state, {
                menus,
            });
        }
        case FOLD_MENU: {
            let menus = state.menus.map((menu) => {
                if (menu.id === action.id) {
                    menu.isOpen = true;
                }

                return menu;
            });
            return Object.assign({}, state, {
                menus,
            });
        }
        case SET_MENU:
            return Object.assign({}, state, {
                ...action,
            });
        case OPEN_NAVIGATOR:
            return Object.assign({}, state, {
                active: true,
            });
        case CLOSE_NAVIGATOR:
            return Object.assign({}, state, {
                active: false,
            });
        case GET_CONTENT_SCHEMAS_PENDING:
            return { ...state, contents: { ...state.contents, pending: true } };
        case GET_CONTENT_SCHEMAS_SUCCESS:
            return { ...state, contents: { ...state.contents, pending: false, data: action.payload } };
        case GET_CONTENT_SCHEMAS_FAILURE:
            return { ...state, contents: { ...state.contents, pending: false, error: action.payload } };
        default:
            return state;
    }
};

export const getContentSchemas =
    ({ projectId, params }) =>
    (dispatch, getState) => {
        const { stage, contentSchemas } = getState();

        return new Promise(async (resolve, reject) => {
            try {
                dispatch({ type: GET_CONTENT_SCHEMAS_PENDING });
                const response = await getContentSchemasAPI(stage.id, stage.endpoint, projectId, params);

                dispatch({
                    type: GET_CONTENT_SCHEMAS_SUCCESS,
                    payload: params.nextToken
                        ? [...new Set([...contentSchemas.data, ...response.data.results])]
                        : response.data.results,
                });
                resolve(response.data.nextToken);
            } catch (error) {
                dispatch({ type: GET_CONTENT_SCHEMAS_FAILURE });
                reject(error);
            }
        });
    };

export const getContentSchemaDetail =
    ({ projectId, schemaId }) =>
    (dispatch, getState) => {
        const { stage, contentSchemas } = getState();

        return new Promise(async (resolve, reject) => {
            try {
                dispatch({ type: GET_CONTENT_SCHEMAS_PENDING });
                const response = await getContentSchemaDetailAPI(stage.id, stage.endpoint, projectId, schemaId);
                const { id, name, status, isSystemManaged } = response.data;

                dispatch({
                    type: GET_CONTENT_SCHEMAS_SUCCESS,
                    payload:
                        status === "ACTIVE"
                            ? [...contentSchemas.data, { id, name, status, isSystemManaged }]
                            : [...contentSchemas.data],
                });
                resolve(response.data);
            } catch (error) {
                dispatch({ type: GET_CONTENT_SCHEMAS_FAILURE });
                reject(error);
            }
        });
    };

export default navigator;
