import { Grid, Stack } from "@mzc-pdc/ui";
import theme from "@styles/theme";
import { ImageEmptyItem } from "@mzc-cloudplex/icons";
import Typography from "@components_v2/typography/typography";
import { useTranslation } from "react-i18next";

const JobEmptyList = () => {
    const { t } = useTranslation();

    return (
        <Stack py={5} gap={1.5} alignItems={"center"} justifyContent={"center"}>
            <ImageEmptyItem width={80} height={72} />
            <Typography variant="body_400" color={theme.palette.greySecondary.main}>
                {t(`common::msg::There are no jobs yet.`, `There are no jobs yet.`)}
            </Typography>
        </Stack>
    );
};

export default JobEmptyList;
