import { createAction, handleActions } from "redux-actions";
import { createContentSchemaAPI } from "@modules/apis/content-schema";
import { getContentSchemasAPI } from "@modules/apis/content-schemas";

const initialState = {
    data: null,
    pending: false,
    error: false,
    isCardView: true,
    create: {
        data: null,
        nextToken: null,
        pending: false,
        error: false,
    },
};

const CREATE_CONTENT_SCHEMA_PENDING = "CREATE_CONTENT_SCHEMA_PENDING";
const CREATE_CONTENT_SCHEMA_SUCCESS = "CREATE_CONTENT_SCHEMA_SUCCESS";
const CREATE_CONTENT_SCHEMA_FAILURE = "CREATE_CONTENT_SCHEMA_FAILURE";
const GET_CONTENT_SCHEMAS_PENDING = "GET_CONTENT_SCHEMAS_PENDING";
const GET_CONTENT_SCHEMAS_SUCCESS = "GET_CONTENT_SCHEMAS_SUCCESS";
const GET_CONTENT_SCHEMAS_FAILURE = "GET_CONTENT_SCHEMAS_FAILURE";
const RESET_CONTENT_SCHEMAS = "RESET_CONTENT_SCHEMAS";

export default handleActions(
    {
        [CREATE_CONTENT_SCHEMA_PENDING]: (state) => {
            return {
                ...state,
                create: {
                    data: null,
                    nextToken: null,
                    pending: true,
                    error: false,
                },
            };
        },
        [CREATE_CONTENT_SCHEMA_SUCCESS]: (state) => {
            return {
                ...state,
                create: {
                    ...state.create,
                    pending: false,
                },
            };
        },
        [CREATE_CONTENT_SCHEMA_FAILURE]: (state) => {
            return {
                ...state,
                create: {
                    ...state.create,
                    pending: false,
                    error: true,
                },
            };
        },
        [GET_CONTENT_SCHEMAS_PENDING]: (state) => {
            return {
                ...state,
                pending: true,
                error: false,
            };
        },
        [GET_CONTENT_SCHEMAS_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
                data: action.payload,
            };
        },
        [GET_CONTENT_SCHEMAS_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: action.payload,
            };
        },
        [RESET_CONTENT_SCHEMAS]: (state) => {
            return {
                ...state,
                data: null,
            };
        },
    },
    initialState,
);

export const resetContentSchemas = createAction(RESET_CONTENT_SCHEMAS);

export const createContentSchema = (projectId, requestBody, customErrorMessage) => (dispatch, getState) => {
    const { stage } = getState();

    return new Promise(async (resolve, reject) => {
        try {
            dispatch({ type: CREATE_CONTENT_SCHEMA_PENDING });
            const response = await createContentSchemaAPI(stage.endpoint, projectId, requestBody, customErrorMessage);
            dispatch({ type: CREATE_CONTENT_SCHEMA_SUCCESS });
            resolve(response.data);
        } catch (error) {
            dispatch({ type: CREATE_CONTENT_SCHEMA_FAILURE });
            reject(error);
        }
    });
};

export const getContentSchemas =
    ({ projectId, params }) =>
    (dispatch, getState) => {
        const { stage, contentSchemas } = getState();

        return new Promise(async (resolve, reject) => {
            try {
                dispatch({ type: GET_CONTENT_SCHEMAS_PENDING });
                const response = await getContentSchemasAPI(stage.id, stage.endpoint, projectId, params);
                // console.log(response);
                dispatch({
                    type: GET_CONTENT_SCHEMAS_SUCCESS,
                    payload: params.nextToken
                        ? [...contentSchemas.data, ...response.data.results]
                        : response.data.results,
                });

                resolve(response.data);
            } catch (error) {
                dispatch({ type: GET_CONTENT_SCHEMAS_FAILURE });
                reject(error);
            }
        });
    };
