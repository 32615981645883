import { IconAddCircle, IconAvata, IconLock, IconPublic } from "@mzc-cloudplex/icons";
import theme from "@styles/theme";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Avatar, AvatarGroup, Divider, Grid, Stack, Typography } from "@mzc-pdc/ui";
import { RandomColoredAvatar } from "@components_v2/avatar/random-colored-avatar";

export const GroupTitleWithIcon = ({ isPublic, title, iconSize, fontVariant = "", isDisabled = false }) => {
    const { t } = useTranslation();
    const name = isPublic ? t(`common::label::All public`, "All public") : title;

    return (
        <Stack direction={"row"} gap={0.5}>
            <GroupIcon isPublic={isPublic} iconSize={iconSize} isDisabled={isDisabled} />
            <Typography
                variant={fontVariant}
                color={
                    isDisabled
                        ? theme.palette.greySecondary.main
                        : isPublic
                        ? theme.palette.primary.main
                        : theme.palette.warning.main
                }
            >
                {name}
            </Typography>
        </Stack>
    );
};

export const GroupIcon = ({ isPublic, iconSize, isDisabled = false }) => {
    const color = isDisabled
        ? theme.palette.greySecondary.main
        : isPublic
        ? theme.palette.primary.main
        : theme.palette.warning.main;
    return isPublic ? <IconPublic size={iconSize} htmlColor={color} /> : <IconLock size={iconSize} htmlColor={color} />;
};

export const GroupedAvatar = ({ members }) => {
    return (
        <AvatarGroup max={4} sx={{ "& .MuiAvatar-root": { border: "1px solid #fff" } }}>
            {members?.map((member, index) => (
                <RandomColoredAvatar key={`group-avatar-${index}`} id={member.id} size={24} />
            ))}
        </AvatarGroup>
    );
};

export const GroupDescription = ({ isPublic }) => {
    const message = isPublic
        ? `This is a message window where all the basics of the project can be seen.`
        : `This is a message window that only added members can see.`;

    return (
        <Typography variant={"subtitle2_500"}>
            <Trans
                i18nKey={`common::msg::${message}`}
                components={{
                    NewLine: <br />,
                }}
            />
            <br />
            <Trans
                i18nKey={`common::msg::By entering @mention, you can send a comment to the designated member.`}
                components={{
                    NewLine: <br />,
                }}
            />
        </Typography>
    );
};
