import { Component } from "react";
import { withTranslation, Trans } from "react-i18next";
import config from "@/config";
import { PUBLIC_URL } from "@constants";

import { IconLogoMzc } from "@mzc-cloudplex/icons";
import { Box, Typography } from "@mui/material";
import theme from "@styles/theme";
import { StyledWelcome } from "@routes/welcome/styled";
import { Button } from "@mzc-pdc/ui";

class Welcome extends Component {
    constructor(props) {
        super(props);
    }

    onLoginClick = () => {
        localStorage.setItem("CLIENT_ID", config.mz_pops.clientId);
        window.location.href = this.getLoginLink();
    };

    getLoginLink = () => {
        const redirectUrl = encodeURIComponent(`${window.location.origin}${config.host.loginRedirectUri}`);
        return `${config.mz_pops.oauth_host}/oauth/authorize?response_type=code&client_id=${config.mz_pops.clientId}&redirect_uri=${redirectUrl}`;
    };

    renderWelcomeMessage = (t) => {
        return (
            <Trans
                i18nKey="common::msg::You need to sign in a {{Megazone Accounts}} {{or}} {{Megazone Login}} account to get started."
                values={{ "Megazone Accounts": "", "Megazone Login": t(`common::label::${"Megazone Login"}`), or: "" }}
            />
        );
    };

    render() {
        const { t, i18n } = this.props;
        return (
            <StyledWelcome>
                <img src={`${PUBLIC_URL}/images/logo-cloudplexmedia.png`} alt={"logo"} style={{ width: `335px` }} />
                <Box
                    sx={{
                        margin: `40px 0 32px`,
                        padding: `60px 80px`,
                        borderTop: `1px solid ${theme.palette.secondary[800]}`,
                        borderBottom: `1px solid ${theme.palette.secondary[100]}`,
                    }}
                >
                    <Typography
                        variant={`subtitle1_700`}
                        component={`p`}
                        sx={{ textAlign: `left`, lineHeight: `1.63` }}
                    >
                        {t(`common::label::${"Welcome"}`, `Welcome`)}!
                        <br />
                        {t(
                            `common::msg::${"Megazone Login users, please sign in through the button below."}`,
                            `Megazone Login users, please sign in through the button below.`,
                        )}
                    </Typography>
                    <Button
                        size={"large"}
                        variant={"contained"}
                        color={"grey"}
                        fullWidth
                        startIcon={<IconLogoMzc size={24} htmlColor={"#fff"} style={{ marginRight: `16px` }} />}
                        onClick={(e) => {
                            e.preventDefault();
                            this.onLoginClick();
                        }}
                        sx={{
                            mt: 6,
                            backgroundColor: theme.palette.grey.dark,
                            "&:hover, &:active": {
                                backgroundColor: theme.palette.grey.dark,
                            },
                        }}
                    >
                        <strong>
                            {t(`common::label::${"Sign in with Megazone Login"}`, `Sign in with Megazone Login`)}
                        </strong>
                    </Button>
                </Box>
                <Typography variant={"body3_500"} color={theme.palette.text.secondary}>
                    {t(
                        `common::msg::${"If you don’t have an account yet, please ask your Organization Administrator."}`,
                        `If you don’t have an account yet, please ask your Organization Administrator.`,
                    )}
                </Typography>
            </StyledWelcome>
        );
    }
}

export default withTranslation()(Welcome);
