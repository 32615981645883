import { handleActions, createAction } from "redux-actions";
import { axios, axiosWithoutHeaders } from "../cores/axiosWrapper";
import { addPausedPollingApis } from "./pausedPollingApis";

const initialState = {
    pending: false,
    error: false,
    data: [],
    search: {
        limit: 20,
        actions: "ALL",
    },
    totalCounts: 0,
    createAsset: {
        data: [],
        runningJobIds: [],
    },
};

const MY_JOBS_PENDING = "MY_JOBS_PENDING";
const MY_JOBS_FAILURE = "MY_JOBS_FAILURE";
const GET_MY_JOBS_LIST_SUCCESS = "GET_MY_JOBS_LIST_SUCCESS";
const GET_MY_JOBS_LIST_FAILURE = "GET_MY_JOBS_LIST_FAILURE";
const UPDATE_MY_JOBS_SEARCH = "UPDATE_MY_JOBS_SEARCH";
const UPDATE_SINGLE_JOB = "UPDATE_SINGLE_JOB";
const UPDATE_MULTIPLE_JOBS = "UPDATE_MULTIPLE_JOBS";
const TOGGLE_ACTIVE_TAB = "TOGGLE_MY_JOBS_ACTIVE_TAB";
const ADD_MY_JOBS_JOB = "ADD_MY_JOBS_JOB";
const UPDATE_MY_JOBS_JOB = "UPDATE_MY_JOBS_JOB";
const DELETE_MY_JOBS_JOB = "DELETE_MY_JOBS_JOB";
const UPDATE_RUNNING_JOB_IDS = "UPDATE_RUNNING_JOB_IDS";
const UPDATE_MY_JOBS_TASK = "UPDATE_MY_JOBS_TASK";

let polling = [];

export default handleActions(
    {
        [MY_JOBS_PENDING]: (state, action) => {
            const newState = {
                ...state,
                pending: true,
                error: false,
            };
            return newState;
        },
        [MY_JOBS_FAILURE]: (state, action) => {
            const newState = {
                ...state,
                pending: false,
                error: true,
            };
            return newState;
        },
        [UPDATE_MY_JOBS_SEARCH]: (state, action) => {
            const payload = action.payload;
            const newState = {
                ...state,
                search: {
                    ...state.search,
                    ...payload,
                },
            };
            return newState;
        },
        [GET_MY_JOBS_LIST_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const { results, totalCount } = data;
            const addInitValue = results.map((item) => {
                item.isFolded = true;
                item.tasks = [];
                return item;
            });
            // console.log('results', results)
            // console.log('totalCount', totalCount)
            // console.log('addInitValue', addInitValue)
            const newState = {
                ...state,
                error: false,
                data: addInitValue || [],
                totalCounts: totalCount || 0,
            };
            return newState;
        },
        [UPDATE_SINGLE_JOB]: (state, action) => {
            const { job } = action.payload;
            const newData = state.data.map((item) => {
                if (item.jobId === job.jobId) {
                    // console.log('job', job)
                    item = job;
                }
                return item;
            });
            const newState = {
                ...state,
                error: false,
                data: newData,
            };
            return newState;
        },
        [UPDATE_MULTIPLE_JOBS]: (state, action) => {
            const { data } = action.payload;
            const { results } = data;
            let newData = JSON.parse(JSON.stringify(state.data));
            results.forEach((job) => {
                newData = newData.map((item) => {
                    if (job.jobId === item.jobId) {
                        const newItem = {
                            ...job,
                            isFolded: item.isFolded,
                            tasks: item.tasks,
                        };
                        // console.log('newItem', newItem)
                        item = newItem;
                    }
                    return item;
                });
            });
            const newState = {
                ...state,
                error: false,
                data: newData,
            };
            return newState;
        },
        [GET_MY_JOBS_LIST_FAILURE]: (state, action) => {
            const newState = {
                ...state,
                data: [],
                error: true,
            };
            return newState;
        },

        [UPDATE_MY_JOBS_JOB]: (state, action) => {
            const { resource, jobId, data } = action.payload;

            const jobData = state[resource]?.data;
            let foundJob = jobData?.find((item) => item.jobId === jobId);
            if (foundJob) {
                const newJobData = jobData.map((job) => (job.jobId === jobId ? { ...job, ...data } : job));
                return { ...state, [resource]: { ...state[resource], data: newJobData } };
            } else {
                return {
                    ...state,
                    [resource]: {
                        data: [data, ...(state[resource]?.data ?? [])],
                        runningJobIds: [...state[resource].runningJobIds, jobId],
                    },
                };
            }
        },
        [DELETE_MY_JOBS_JOB]: (state, action) => {
            const { resource, data } = action.payload;
            const newData = state[resource].data.filter((item) => item.jobId !== data.jobId);
            return { ...state, [resource]: { data: newData } };
        },
        [UPDATE_RUNNING_JOB_IDS]: (state, action) => {
            const { resource, runningJobIds } = action.payload;
            return { ...state, [resource]: { ...state[resource], runningJobIds } };
        },
        [UPDATE_MY_JOBS_TASK]: (state, action) => {
            const { resource, jobId, taskIndex, data } = action.payload;
            const jobData = state[resource]?.data;
            let foundJob = jobData?.find((item) => item.jobId === jobId);
            if (foundJob) {
                const newJobData = jobData.map((job) =>
                    job.jobId === jobId
                        ? {
                              ...job,
                              tasks: job.tasks.map((task, index) =>
                                  index === taskIndex ? { ...task, ...data } : task,
                              ),
                          }
                        : job,
                );
                return { ...state, [resource]: { ...state[resource], data: newJobData } };
            } else {
                return state;
            }
        },
    },
    initialState,
);

export function getMyJobsAPI(stageId, apiEndpoint, projectId, search) {
    const { actions, limit } = search;

    const params = {
        currentSession: true,
        types: "FILE_JOBS",
    };

    if (actions && actions !== "ALL") {
        params.actions = actions;
    }

    if (limit) {
        params.limit = limit;
    }

    return axios.get(`${apiEndpoint}/v2/jobs`, {
        params,
        headers: {
            stageId,
            projectId,
        },
    });
}

export function getMyJobTaskAPI(stageId, apiEndpoint, projectId, jobId) {
    const params = {
        currentSession: true,
    };

    return axios.get(`${apiEndpoint}/v2/jobs/${jobId}/tasks`, {
        params,
        headers: {
            stageId,
            projectId,
        },
    });
}

export const getMyJobTask =
    ({ jobId }) =>
    (dispatch, getState) => {
        const { stage, project, myJobs } = getState();
        const stageId = stage.id;
        const projectId = project.id;
        const apiEndpoint = stage.endpoint;

        return new Promise((resolve, reject) =>
            getMyJobTaskAPI(stageId, apiEndpoint, projectId, jobId)
                .then(async (response) => {
                    resolve(response);
                })
                .catch((error) => {
                    // ToDO
                    reject(error);
                }),
        );
    };

const checkPollingStatus = (jobs) => {
    return jobs.filter((item) => {
        return !(item.status === "CANCELED" || item.status === "FAILED" || item.status === "SUCCEED");
    });
};

const doPolling = (stageId, apiEndpoint, projectId, search, dispatch) => {
    if (polling) {
        clearTimeout(polling);
    }
    polling = setTimeout(() => {
        getMyJobsAPI(stageId, apiEndpoint, projectId, search)
            .then((response) => {
                dispatch({
                    type: UPDATE_MULTIPLE_JOBS,
                    payload: response,
                });
                const results = response.data.results;
                const check = checkPollingStatus(results);
                if (check.length > 0) {
                    doPolling(stageId, apiEndpoint, projectId, search, dispatch);
                }
            })
            .catch((error) => {
                if (!navigator.onLine) {
                    dispatch(
                        addPausedPollingApis({
                            type: "MY_JOB",
                            params: { stageId, apiEndpoint, projectId, search },
                        }),
                    );
                }
            });
    }, 4000);
};

export const getMyJobs = (actions) => (dispatch, getState) => {
    const { stage, project, myJobs } = getState();
    const stageId = stage.id;
    const projectId = project.id;
    const apiEndpoint = stage.endpoint;
    const search = myJobs.search;

    dispatch({
        type: MY_JOBS_PENDING,
    });

    if (polling) {
        // console.log('초기화')
        clearTimeout(polling);
    }

    if (actions) {
        search.actions = actions;
    }

    return new Promise((resolve, reject) =>
        getMyJobsAPI(stageId, apiEndpoint, projectId, search)
            .then(async (response) => {
                dispatch({
                    type: GET_MY_JOBS_LIST_SUCCESS,
                    payload: response,
                });

                if (response.data) {
                    // Promise.all(response.data.results.map(async item => {
                    //     const tasks = await getMyJobsTasksAPI(stageId, apiEndpoint, projectId, item.jobId);
                    //     if(tasks.data){
                    //         item.tasks = tasks.data.results;
                    //     }
                    //     return item;
                    // }));
                    const results = response.data.results;
                    const check = checkPollingStatus(results);
                    // console.log('check', check);
                    if (check.length > 0) {
                        doPolling(stageId, apiEndpoint, projectId, search, dispatch);
                    }
                }

                resolve(response);
            })
            .catch((error) => {
                if (!navigator.onLine) {
                    dispatch(
                        addPausedPollingApis({
                            type: "MY_JOB",
                            params: { stageId, apiEndpoint, projectId, search },
                        }),
                    );
                }
                // ToDO
                dispatch({
                    type: GET_MY_JOBS_LIST_FAILURE,
                    payload: error.response,
                });
                resolve(error);
            }),
    );
};

export const retryJob = (jobId) => (dispatch, getState) => {
    const { stage, project } = getState();
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;
    const stageId = stage.id;
    const actions = {
        action: "RETRY_JOB",
        content: {
            jobId: jobId,
        },
    };
    return new Promise((resolve, reject) => {
        return triggerJobAction(apiEndpoint, stageId, projectId, actions)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

function triggerJobAction(apiEndpoint, stageId, projectId, actions) {
    return axios.post(`${apiEndpoint}/v2/jobs/actions`, actions, {
        headers: {
            stageId,
            projectId,
        },
    });
}

export const getJobStatus = (jobId) => (dispatch, getState) => {
    const { stage, project } = getState();
    // const cancelTokenSource = axios._cancelToken.source();
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;
    const stageId = stage.id;
    const actions = {
        action: "GET_JOB_STATUS",
        content: {
            jobIds: [jobId],
        },
    };
    return new Promise((resolve, reject) => {
        return triggerJobAction(apiEndpoint, stageId, projectId, actions)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const updateSearch = createAction(UPDATE_MY_JOBS_SEARCH);
export const updateJob = createAction(UPDATE_SINGLE_JOB);

export const addMyJobsJob = createAction(ADD_MY_JOBS_JOB);
export const updateMyJobsJob = createAction(UPDATE_MY_JOBS_JOB); //Note: 여기서 각 에셋잡마다 파일 retryKey를 사용해서 uploadQueue와 합치는 작업을?
export const deleteMyJobsJob = createAction(DELETE_MY_JOBS_JOB);

export const updateRunningJobIds = createAction(UPDATE_RUNNING_JOB_IDS);
export const updateMyJobsTask = createAction(UPDATE_MY_JOBS_TASK);
