//TODO error, saving state 정리필요
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import equal from "deep-equal";
import moment from "moment";
import * as rightSideBarActions from "../../modules/rightSideBar";
import * as listingsLineupListActions from "../../modules/listingsLineupList";
import * as listingActions from "../../modules/listing";
import * as streamForLineupsActions from "../../modules/streamForLineups";
import * as notificationActions from "../../modules/notification";
import Loader from "../../components/loader";
import FormatBadge from "../../components/formatbadge";
import getImageUrlOfS3 from "../../cores/getImageUrlOfS3";
import Recurring from "./ui/Recurring";
import pad from "../../cores/string.pad";
import { CONSTANTS, LIMIT_BYTE } from "@constants";
import getScheduleDateTime from "../../cores/getScheduleDateTime";
import ScheduleDuration from "./ui/ScheduleDuration";
import WithSelect from "../../components/select";
import InputField from "../../components/input/InputField";
import validateInput from "../../cores/validateInput";

class CopyLineup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saving: false,
            focused: null,
            error: false,
            isEdit: false,
            amount: "00:00:00.000",
            displayStartDateTime: moment().startOf("day").format("HH:mm:ss.SSS"),
            displayEndDateTime: moment().format("HH:mm:ss.SSS"),
            isAttachedChannel: false,
            priority: 50,
            duration: null,
            validationErrors: {},
            enableSchedule: true,
        };
    }
    componentDidMount() {
        const { listing, listingsLineupList, streamForLineups, StreamForLineupsActions } = this.props;

        StreamForLineupsActions.getLineup(
            listing.data.id,
            streamForLineups.selected.id,
            listingsLineupList.currentDate,
        );

        StreamForLineupsActions.getProgram(this.props.streamForLineups.selected.program.id);

        this.setState({
            ...this.state,
            isAttachedChannel:
                listing.data.channel && listing.data.channel.id && listing.data.channel.state !== "STOPPED",
        });
    }

    refresh() {
        const { listing, streamForLineups, StreamForLineupsActions } = this.props;

        StreamForLineupsActions.getLineup(
            listing.data.id,
            streamForLineups.selected.id,
            streamForLineups.standardDateTime,
        );

        StreamForLineupsActions.getProgram(this.props.streamForLineups.selected.program.id);
    }

    componentWillUnmount() {
        const { StreamForLineupsActions } = this.props;

        StreamForLineupsActions.setLineupCount({ detail: 0 });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.streamForLineups.selected &&
            !equal(prevProps.streamForLineups.selected, this.props.streamForLineups.selected)
        ) {
            this.setState({
                ...this.state,
                focused: null,
                error: false,
                isEdit: false,
            });
            this.props.StreamForLineupsActions.getLineup(
                this.props.listing.data.id,
                this.props.streamForLineups.selected.id,
                this.props.listingsLineupList.currentDate,
            );

            this.props.StreamForLineupsActions.getProgram(this.props.streamForLineups.selected.program.id);
        }

        if (
            this.props.streamForLineups.selected &&
            prevProps.streamForLineups.selected &&
            (!equal(prevProps.streamForLineups.selected.start, this.props.streamForLineups.selected.start) ||
                !equal(prevProps.streamForLineups.selected.end, this.props.streamForLineups.selected.end))
        ) {
            this.props.StreamForLineupsActions.updateLineup({
                schedule: {
                    startAt: moment.utc(this.props.streamForLineups.selected.start).format(),
                    endAt: moment.utc(this.props.streamForLineups.selected.end).format(),
                },
                displayEndDateTime: moment(this.props.streamForLineups.selected.start)
                    .add(moment.duration(this.props.streamForLineups.selected.duration))
                    .format("HH:mm:ss.SSS"),
            });
        }

        if (
            this.props.streamForLineups.item &&
            !equal(prevProps.streamForLineups.counts.detail, this.props.streamForLineups.counts.detail)
        ) {
            let displayStartDateTime = moment
                .utc(`${this.props.streamForLineups.item.schedule.startAt}`)
                .local()
                .format("HH:mm:ss.SSS");
            let endDateTime = moment.utc(`${this.props.streamForLineups.item.schedule.endAt}`).local();
            let duration = moment(endDateTime).diff(
                moment.utc(`${this.props.streamForLineups.item.schedule.startAt}`).local().startOf("day"),
            );
            let endH = Math.floor(duration / 60 / 60 / 1000);
            let endM = Math.floor((duration % (60 * 60 * 1000)) / 60 / 1000);
            let endS = Math.floor((duration % (60 * 1000)) / 1000);
            let endMs = duration % 1000;

            let amounts = moment.duration(
                endDateTime.diff(moment.utc(`${this.props.streamForLineups.item.schedule.startAt}`).local()),
            );

            const hours =
                amounts.get("days") && amounts.get("days") > 0
                    ? amounts.get("days") * 24 + amounts.get("hours")
                    : amounts.get("hours");

            const d = `${pad(hours, 2)}:${pad(amounts.get("minutes"), 2)}:${pad(amounts.get("seconds"), 2)}.${pad(
                amounts.get("milliseconds"),
                3,
            )}`;
            this.setState({
                ...this.state,
                amount: d,
                duration: moment.duration(d)._milliseconds,
                displayStartDateTime,
                displayEndDateTime: `${pad(endH, 2)}:${pad(endM, 2)}:${pad(endS, 2)}.${pad(endMs, 3)}`,
            });

            this.props.StreamForLineupsActions.updateCopyTo({
                beginDate: moment(this.props.listingsLineupList.currentDate).format("YYYY-MM-DD"),
            });
        }

        if (!equal(prevProps.streamForLineups.error, this.props.streamForLineups.error)) {
            this.setState({
                ...this.state,
                saving: false,
            });
        }
    }

    onCancel = () => {
        const { RightSideBarActions } = this.props;

        RightSideBarActions.toggleActive(CONSTANTS("RIGHT_SIDE_BAR_TYPES").LISTINGS_COPY_TO);
    };

    onSave = () => {
        const {
            listing,
            listingsLineupList,
            ListingActions,
            streamForLineups,
            StreamForLineupsActions,
            RightSideBarActions,
        } = this.props;

        let { beginDateTime, endDateTime } = getScheduleDateTime({
            currentDate: moment(streamForLineups.copyTo.beginDate),
            beginTime: moment.isMoment(streamForLineups.copyTo.schedule.startAt)
                ? streamForLineups.copyTo.schedule.startAt.format("HH:mm:ss.SSS")
                : streamForLineups.copyTo.schedule.startAt,
            endTime: this.state.displayEndDateTime,
        });

        const utcBeginDateTime = moment.utc(beginDateTime);
        const utcEndDateTime = moment.utc(endDateTime);

        let params = {
            ...streamForLineups.copyTo,
            program: {
                id: streamForLineups.item.program.id,
            },
            schedule: {
                ...streamForLineups.copyTo.schedule,
                recurrences: this.state.enableSchedule ? streamForLineups.copyTo.schedule.recurrences : [],
                startAt: utcBeginDateTime.format("YYYY-MM-DD HH:mm:ss.SSS"),
                endAt: utcEndDateTime.format("YYYY-MM-DD HH:mm:ss.SSS"),
            },
            beginDate: utcBeginDateTime.format("YYYY-MM-DD"),
            beginTime: utcBeginDateTime.format("HH:mm:ss.SSS"),
            endDate: utcEndDateTime.format("YYYY-MM-DD"),
            endTime: utcEndDateTime.format("HH:mm:ss.SSS"),
            version: listing.data.version,
        };

        this.setState({
            ...this.state,
            saving: true,
        });

        StreamForLineupsActions.postLineup(listing.data.id, params, (lineup) => {
            this.setState({
                ...this.state,
                saving: false,
            });

            ListingActions.updateListing({
                version: Number(listing.data.version) + 1,
            });

            RightSideBarActions.toggleActive(CONSTANTS("RIGHT_SIDE_BAR_TYPES").LISTINGS_COPY_TO);

            StreamForLineupsActions.setLineupSelected({
                lineup: lineup.data,
                standardDateTime: listingsLineupList.currentDate,
            });

            if (
                moment(moment(streamForLineups.standardDateTime).format("YYYY-MM-DD")).isSame(
                    moment(moment(listingsLineupList.currentDate).format("YYYY-MM-DD")),
                )
            ) {
                StreamForLineupsActions.getLineups({
                    listingId: listing.data.id,
                    startAt: moment(`${moment(streamForLineups.standardDateTime).format("YYYY-MM-DD")} 00:00:00.000`)
                        .utc()
                        .format("YYYY-MM-DD HH:mm:ss.SSS"),
                    endAt: moment(`${moment(streamForLineups.standardDateTime).format("YYYY-MM-DD")} 23:59:59.999`)
                        .utc()
                        .format("YYYY-MM-DD HH:mm:ss.SSS"),
                });
            }
        });
    };

    changeItem = (obj) => {
        const { streamForLineups, StreamForLineupsActions, NotificationActions } = this.props;

        let validate = this.state.validationErrors;

        if ("name" in obj) {
            validate = this.setValidationError(validate, {
                key: "name",
                error: validateInput(obj.name, {
                    required: true,
                    maxByte: LIMIT_BYTE.name,
                }),
            });
        }

        this.setState({
            ...this.state,
            error: false,
            validationErrors: validate,
        });

        StreamForLineupsActions.updateCopyTo(obj);
    };

    setValidationError(validation, data) {
        const { key, error } = data;

        if (error) {
            validation = {
                ...validation,
                [key]: error,
            };
        } else {
            if (validation[key]) {
                delete validation[key];
            }
        }

        return validation;
    }

    changeScheduleBeginDate = (date) => {
        const { StreamForLineupsActions } = this.props;

        StreamForLineupsActions.updateCopyTo({
            beginDate: moment(date).format("YYYY-MM-DD"),
        });
    };

    changeScheduleBeginTime = ({ startAt, endAt }, t) => {
        const { streamForLineups, StreamForLineupsActions } = this.props;

        let { beginDateTime } = getScheduleDateTime({
            currentDate: moment(streamForLineups.copyTo.beginDate),
            beginTime: startAt,
            endTime: endAt,
        });
        const end = moment().add(moment.duration(this.state.displayEndDateTime));
        const start = moment().add(moment.duration(this.state.displayStartDateTime));
        const duration = end.diff(start);

        let todayDuration = beginDateTime.diff(moment(streamForLineups.copyTo.beginDate).startOf("day"));
        let endDurationTime = todayDuration + duration;

        const newEndTime = moment.utc(endDurationTime).format(`HH:mm:ss.SSS`);

        this.setState({
            ...this.state,
            displayStartDateTime: startAt,
            displayEndDateTime: newEndTime,
            error: false,
        });

        this.changeScheduleEndTime({ startAt, endAt: newEndTime }, t);
    };

    changeScheduleEndTime = ({ startAt, endAt }, t) => {
        const { streamForLineups, StreamForLineupsActions } = this.props;

        let { beginDateTime, endDateTime } = getScheduleDateTime({
            currentDate: moment(streamForLineups.copyTo.beginDate),
            beginTime: startAt,
            endTime: endAt,
        });

        let todayTimes = moment(streamForLineups.standardDateTime).diff(
            moment(streamForLineups.standardDateTime).startOf("day"),
        );
        let duration = todayTimes + moment(endDateTime).diff(moment(streamForLineups.standardDateTime));
        let endH = Math.floor(duration / 60 / 60 / 1000);
        let endM = Math.floor((duration % (60 * 60 * 1000)) / 60 / 1000);
        let endS = Math.floor((duration % (60 * 1000)) / 1000);
        let endMs = duration % 1000;
        let displayStartDateTime = startAt;
        let displayEndDateTime = `${pad(endH, 2)}:${pad(endM, 2)}:${pad(endS, 2)}.${pad(endMs, 3)}`;

        let validate = this.state.validationErrors;

        validate = this.setValidationError(validate, {
            key: "schedule",
            error: this.validateSchedule({ beginDateTime, endDateTime }, t),
        });

        this.setState({
            ...this.state,
            displayStartDateTime,
            displayEndDateTime,
            error: false,
            validationErrors: validate,
            enableSchedule: validate.schedule && validate.schedule.type === "OVER_AMOUNT_24H" ? false : true,
        });

        StreamForLineupsActions.updateCopyTo({
            schedule: {
                startAt: startAt,
                endAt: endAt,
            },
        });
    };

    validateSchedule = ({ beginDateTime, endDateTime }, t) => {
        if (!beginDateTime || !endDateTime) {
            return null;
        }

        const duration = endDateTime.diff(beginDateTime);

        if (this.state.isAttachedChannel) {
            if (moment(beginDateTime).isSameOrBefore(moment().add(2, "minutes"))) {
                return {
                    type: "BEGIN_PAST_TIME",
                    level: "error",
                    message: t(
                        `common::msg::${"The begin date time must be registered after 2 minutes on the current time basis."}`,
                    ),
                };
            }
        } else {
            if (moment(beginDateTime).isSameOrBefore(moment())) {
                return {
                    type: "BEGIN_PAST_TIME",
                    level: "error",
                    message: t(`common::msg::${"The begin date time can not be past time."}`),
                };
            }
        }

        if (duration <= 0) {
            return {
                type: "UNDER_ENDTIME",
                level: "error",
                message: t(`common::msg::${'The "End time" must be later than "Start time"'}`),
            };
        }

        if (moment.duration(endDateTime.diff(beginDateTime)).asMilliseconds() > 24 * 60 * 60 * 1000) {
            return {
                type: "OVER_AMOUNT_24H",
                level: "warning",
                message: t(
                    `common::msg::${'If "Program Duration" is more than "24 hours", you can not repeat the schedule.'}`,
                ),
            };
        }

        return null;
    };

    getMediaItems() {
        const { streamForLineups } = this.props;

        if (streamForLineups.programInfos.source) {
            if (streamForLineups.programInfos.source.asset) {
                return [{ type: "MP4" }];
            } else if (streamForLineups.programInfos.source.stream) {
                return [{ type: streamForLineups.programInfos.source.stream.type }];
            }
        }
        return [];
    }

    getDuration() {
        const { streamForLineups } = this.props;

        let { beginDateTime, endDateTime } = getScheduleDateTime({
            currentDate: moment(streamForLineups.standardDateTime),
            beginTime: streamForLineups.copyTo.schedule.startAt,
            endTime: streamForLineups.copyTo.schedule.endAt,
        });

        const duration = endDateTime.diff(beginDateTime);
        return duration || 0;
    }

    render() {
        const { streamForLineups, t, i18n } = this.props;

        let isOverTheDay = false;
        let isPrevEvent = false;

        if (streamForLineups.item && streamForLineups.item.schedule && streamForLineups.item.program) {
            let endAt = moment
                .utc(`${this.props.streamForLineups.item.schedule.startAt}`)
                .local()
                .add(moment.duration(this.props.streamForLineups.item.duration));
            isOverTheDay = endAt.isAfter(
                moment.utc(`${this.props.streamForLineups.item.schedule.startAt}`).local().endOf("day"),
            );
            isPrevEvent = this.state.isAttachedChannel
                ? moment(
                      `${moment(this.props.streamForLineups.standardDateTime).format("YYYY-MM-DD")} ${moment
                          .utc(this.props.streamForLineups.item.schedule.startAt)
                          .local()
                          .format("HH:mm:ss.SSS")}`,
                  ).isSameOrBefore(moment().add(10, "minutes"))
                : moment(
                      `${moment(this.props.streamForLineups.standardDateTime).format("YYYY-MM-DD")} ${moment
                          .utc(this.props.streamForLineups.item.schedule.startAt)
                          .local()
                          .format("HH:mm:ss.SSS")}`,
                  ).isSameOrBefore(moment());
        }
        return (
            <React.Fragment>
                <div className="panel-header">
                    <div className={"panel-header-title"}>
                        <h3>{t(`common::label::My jobs`)}</h3>
                    </div>
                </div>
                {streamForLineups.pending && <Loader />}
                <React.Fragment>
                    <div className={"panel-header"}>
                        <h4 className="card-title">{t(`common::label::${"Lineup copy"}`)}</h4>
                        <div className={"btns"}>
                            <button
                                type={"button"}
                                className={"btn btn-outline-dark"}
                                onClick={(e) => {
                                    this.onCancel();
                                }}
                            >
                                {t(`common::label::${"Cancel"}`)}
                            </button>
                            <button
                                type={"button"}
                                className={"btn btn-outline-primary"}
                                onClick={(e) => {
                                    this.onSave();
                                }}
                                disabled={
                                    Object.keys(this.state.validationErrors).filter(
                                        (k) =>
                                            this.state.validationErrors[k] &&
                                            this.state.validationErrors[k].level !== "warning",
                                    ).length > 0
                                        ? true
                                        : false
                                }
                            >
                                <strong>{t(`common::label::${"Save"}`)}</strong>
                            </button>
                        </div>
                    </div>
                    <div className={"panel-body"}>
                        <div className="box-scroll">
                            <div className={"form-group"}>
                                <h5>
                                    {" "}
                                    {t(`common::label::${"Name"}`)} <span className={"required"}>*</span>
                                </h5>
                                {streamForLineups.copyTo && (
                                    <React.Fragment>
                                        <InputField
                                            value={streamForLineups.copyTo.name}
                                            validationError={this.state.validationErrors.name}
                                            onChange={(e) => {
                                                this.changeItem({ name: e.target.value });
                                            }}
                                        />
                                    </React.Fragment>
                                )}
                            </div>
                            <div className={"schedule"}>
                                <h5>{t(`common::label::${"Schedule"}`)}</h5>
                                <div className={"box"}>
                                    {streamForLineups.copyTo && (
                                        <React.Fragment>
                                            <label htmlFor={"Schedule"}>
                                                <strong>{t(`common::label::${"Time"}`)}</strong>
                                            </label>
                                            <ScheduleDuration
                                                currentDate={streamForLineups.copyTo.beginDate}
                                                validationErrors={this.state.validationErrors}
                                                beginTime={streamForLineups.copyTo.schedule.startAt}
                                                endTime={streamForLineups.copyTo.schedule.endAt}
                                                duration={this.state.duration}
                                                displayEndDateTime={this.state.displayEndDateTime}
                                                onChangeBeginDate={(times) => {
                                                    this.changeScheduleBeginDate(times);
                                                }}
                                                onChangeBeginTime={({ beginTime, endTime }) => {
                                                    this.changeScheduleBeginTime(
                                                        {
                                                            startAt: beginTime,
                                                            endAt: endTime,
                                                        },
                                                        t,
                                                    );
                                                }}
                                                onChangeEndTime={({ beginTime, endTime }) => {
                                                    this.changeScheduleEndTime(
                                                        {
                                                            startAt: beginTime,
                                                            endAt: endTime,
                                                        },
                                                        t,
                                                    );
                                                }}
                                            />
                                        </React.Fragment>
                                    )}
                                    {streamForLineups.copyTo && (
                                        <React.Fragment>
                                            <Recurring
                                                isEnabled={this.state.enableSchedule}
                                                displayRepeatPeriod={true}
                                                currentDate={moment(
                                                    `${streamForLineups.copyTo.beginDate} ${streamForLineups.copyTo.schedule.startAt}`,
                                                ).format("YYYY-MM-DD HH:mm:ss.SSS")}
                                                schedule={streamForLineups.copyTo.schedule}
                                                onChange={this.changeItem}
                                            />
                                        </React.Fragment>
                                    )}
                                    <hr />
                                    <div className={"form-group priority"}>
                                        <label htmlFor={"priority"} className={"dot"}>
                                            <strong>{t(`common::label::${"Priority"}`)}</strong>
                                        </label>
                                        <div className={"contents d-flex flex-column"}>
                                            {streamForLineups.copyTo && (
                                                <React.Fragment>
                                                    <div className={"form-control-input"}>
                                                        <WithSelect
                                                            defaultValue={streamForLineups.copyTo.priority}
                                                            onChange={(option) => {
                                                                this.changeItem({
                                                                    priority: Number(option.value),
                                                                });
                                                            }}
                                                            options={CONSTANTS("LINEUP_PRIORITY_TYPE")}
                                                        />
                                                    </div>
                                                    <div className={"mt-2"}>
                                                        <i className={"sprite sprite-info-inverse mr-2"}></i>
                                                        <span className={"text-secondary"}>
                                                            {t(
                                                                `common::msg::${"If the value is the same, next event has the highest priority"}`,
                                                            )}
                                                        </span>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {streamForLineups.programInfos && (
                                <div className={"program"}>
                                    <h5>{t(`common::label::${"Program"}`)}</h5>
                                    <div className={"box"}>
                                        <div className={"form-group"}>
                                            <label htmlFor={"programTitle"} className={"dot"}>
                                                <strong>{t(`common::label::${"Name"}`)}</strong>
                                            </label>
                                            <div className={"contents"}>
                                                {streamForLineups.programInfos.name || "-"}
                                            </div>
                                        </div>
                                        <div className={"form-group"}>
                                            <label htmlFor={"description"} className={"dot"}>
                                                <strong>{t(`common::label::${"Description"}`)}</strong>
                                            </label>
                                            <div className={"contents"}>
                                                {streamForLineups.programInfos.description || "-"}
                                            </div>
                                        </div>
                                        <div className={"form-group"}>
                                            <label htmlFor={"tags"} className={"dot"}>
                                                <strong>{t(`common::label::${"Attributions"}`)}</strong>
                                            </label>
                                            <div className={"contents"}>
                                                {streamForLineups.programInfos.attributions &&
                                                streamForLineups.programInfos.attributions &&
                                                streamForLineups.programInfos.attributions &&
                                                streamForLineups.programInfos.attributions.length > 0 ? (
                                                    <ul className={"tags"}>
                                                        {streamForLineups.programInfos.attributions.map((v, i) => (
                                                            <li key={i}>
                                                                <span>{v.key}</span> : <span>{v.value}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                ) : (
                                                    <React.Fragment>-</React.Fragment>
                                                )}
                                            </div>
                                        </div>

                                        {streamForLineups &&
                                            streamForLineups.programInfos &&
                                            this.getMediaItems().length > 0 && (
                                                <div className={"form-group"}>
                                                    <h6 className={"dot"}>{t(`common::label::${"Media"}`)}</h6>
                                                    {this.getMediaItems().map((v, i) => (
                                                        <div
                                                            className={"contents"}
                                                            key={i}
                                                            className={"form-check form-check-flat form-check-primary"}
                                                        >
                                                            <FormatBadge format={v.type} />
                                                        </div>
                                                    ))}
                                                </div>
                                            )}

                                        {streamForLineups.programInfos &&
                                            streamForLineups.programInfos.source &&
                                            streamForLineups.programInfos.source.video && (
                                                <div className={"form-group"}>
                                                    <h6 className={"dot"}>{t(`common::label::${"Posters"}`)}</h6>
                                                    <div className={"contents"}>
                                                        {streamForLineups.programInfos.poster ? (
                                                            <div className={"thumb-nail"}>
                                                                <img
                                                                    src={getImageUrlOfS3({
                                                                        src: streamForLineups.programInfos.poster,
                                                                        stageId: this.props.stage.id,
                                                                    })}
                                                                />
                                                            </div>
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </React.Fragment>
            </React.Fragment>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        stage: state.stage,
        listing: state.listing,
        listingsLineupList: state.listingsLineupList,
        streamForLineups: state.streamForLineups,
        createStream: state.createStream,
    };
};

let mapDispatchToProps = (dispatch) => {
    return {
        RightSideBarActions: bindActionCreators(rightSideBarActions, dispatch),
        ListingsLineupListActions: bindActionCreators(listingsLineupListActions, dispatch),
        StreamForLineupsActions: bindActionCreators(streamForLineupsActions, dispatch),
        NotificationActions: bindActionCreators(notificationActions, dispatch),
        ListingActions: bindActionCreators(listingActions, dispatch),
    };
};

CopyLineup = connect(mapStateToProps, mapDispatchToProps)(CopyLineup);
export default withTranslation()(CopyLineup);
