import React, { FC, useCallback, useState } from "react";

import { Tooltip as StyledTooltip } from "@mzc-pdc/ui";
import theme from "@styles/theme";

const Tooltip = React.forwardRef((props, ref) => {
    const maxWidth = props.maxWidth ? { maxWidth: props.maxWidth } : {};
    return (
        <StyledTooltip
            color={props.color}
            componentsProps={{
                tooltip: {
                    sx: {
                        backgroundColor: props.color ?? theme.palette.greySecondary[600],
                        "& .MuiTooltip-arrow": {
                            color: props.color ?? theme.palette.greySecondary[600],
                        },
                        ...maxWidth,
                    },
                },
            }}
            {...props}
            ref={ref}
        >
            {props.children}
        </StyledTooltip>
    );
});

export default Tooltip;
