import { axios, axiosWithoutHeaders } from "../../cores/axiosWrapper";
import queryString from "../../cores/queryString";
import moment from "moment";

import compareVersions, { VERSION_FMS_SAFARI_ENCODING, VERSION_CM_9545_STORAGE_LOCK } from "@cores/version";

export function listResourceBuckets(apiEndpoint, stageId, projectId) {
    return axios.get(`${apiEndpoint}/resource-buckets`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

export function putBulkResourceBuckets(apiEndpoint, stageId, projectId, data) {
    return axios.put(`${apiEndpoint}/resource-buckets/bulk`, data, {
        headers: {
            stageId,
            projectId,
        },
    });
}

export function listFolders(apiEndpoint, projectId, useV3, bucket, key) {
    const queries = {
        prefix: key || "",
    };
    return axios.get(
        `${apiEndpoint}${useV3 ? "/v3" : ""}/directories/${bucket}/folders?${queryString.stringify(queries)}`,
        {
            headers: { projectId },
        },
    );
}

export function listObjects(apiEndpoint, projectId, bucket, key, condition) {
    const queries = condition.nextToken
        ? {
              nextToken: condition.nextToken,
          }
        : {
              prefix: key || "",
              isFolderSearch:
                  condition.keyword || condition.storageClass || (condition.beginDate && condition.endDate)
                      ? false
                      : true,
              key: condition.keyword,
              storageClass: condition.storageClass,
              offset: condition.offset,
              limit: condition.limit || 200,
              maxKeys: condition.defaultViewCountByToken,
          };

    if (condition.beginDate && condition.endDate) {
        const startAt = `${moment(`${condition.beginDate.format("YYYY-MM-DD")} 00:00:00`)
            .utc()
            .format("YYYY-MM-DD")}T${moment(`${condition.beginDate.format("YYYY-MM-DD")} 00:00:00`)
            .utc()
            .format("HH:mm:ss")}Z`;
        const endAt = `${moment(`${condition.endDate.format("YYYY-MM-DD")} 00:00:00`)
            .utc()
            .format("YYYY-MM-DD")}T${moment(`${condition.endDate.format("YYYY-MM-DD")} 00:00:00`)
            .utc()
            .format("HH:mm:ss")}Z`;

        queries.lastModifiedAt = `${startAt}~${endAt}`;
    }

    return axios.get(`${apiEndpoint}/directories/${bucket}/objects?${queryString.stringify(queries)}`, {
        headers: { projectId },
    });
}

export function listObjectsV3(apiEndpoint, projectId, bucket, key, condition) {
    const queries = condition.nextToken
        ? {
              prefix: key || "",
              nextToken: condition.nextToken,
          }
        : {
              prefix: key || "",
              key: condition.keyword,
              limit: condition.defaultViewCountByToken || 200,
          };

    return axios.get(`${apiEndpoint}/v3/directories/${bucket}/objects?${queryString.stringify(queries)}`, {
        headers: { projectId },
    });
}

export function mutate(apiEndpoint, stageId, stageVersion, projectId, bucketName, actions) {
    let requestBody = {
        ...actions,
    };
    let headers = {
        stageId,
        projectId,
    };
    let url = "";
    if (compareVersions(stageVersion, VERSION_FMS_SAFARI_ENCODING) >= 0) {
        requestBody = Buffer.from(encodeURIComponent(JSON.stringify(requestBody))).toString("base64");

    }

    if (compareVersions(stageVersion, VERSION_CM_9545_STORAGE_LOCK) >= 0) {
        headers = {
            ...headers,
            "x-mzc-content-encoding": "base64",
        };
        url = `${apiEndpoint}/v3/storages/buckets/${bucketName}/actions`;
    } else {
        headers = {
            ...headers,
            "content-type": "text/plain", // axios 상위 버젼 부터는 content-type이 application/json이면 body에 string이 쌍따옴표로 묶여서 요청되어서 분기 처리함.
            "x-mzc-content-encoding": "base64",
        };
        url = `${apiEndpoint}/v2/storages/buckets/${bucketName}/actions`;
    }
    return axios.post(url, requestBody, {
        headers,
    });
}

export const StoragesApi = {
    listResourceBuckets,
    putBulkResourceBuckets,
    listFolders,
    listObjects,
    listObjectsV3,
    mutate,
};

export default StoragesApi;
