import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import {
    CONSTANTS
} from "../../../constants";
import 'react-dates/initialize';
import {SingleDatePicker} from "react-dates";
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import equal from 'deep-equal';
import WithSelect from "../../../components/select";

class Recurring extends Component {
    constructor(props) {
        super(props);
        //TODO 변수명들 정리필요

        this.state = {
            schedule:{
                recurringType : 'custom',
                recurringTypeMonth : 'dayOfMonth',
                recurringValue : 'dailys'
            },
            endType : 'never',
            defaultDay : '1',
            defaultWeek : [CONSTANTS("RECURRING_DAY_OF_THE_WEEK").filter(v => v.value === (moment().day() + ''))[0]],
            defaultMonth : [],
            defaultDayOfMonth : '1',
            defaultWeekOfMonth : CONSTANTS("RECURRING_WEEKS").filter(v => v.value === (countWeekdayOccurrencesInMonth(moment()) + ''))[0].value,
            defaultDayWeekOfMonth : CONSTANTS("RECURRING_DAY_OF_THE_WEEK").filter(v => v.value === moment().day() + '')[0].value,
            defaultDayOfInterval : '1',
            defaultDateOfInterval : moment(),
            endAt: null,
            focused : false,
            focusedOfCalendar : false
        };
    }

    componentDidMount() {
        this.setRecurringData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let endAt = this.utcEndAt();

        if(!equal(prevProps.schedule.recurringValue, this.props.schedule.recurringValue)) {
            this.setRecurringData();
        }

        if(!equal(prevProps.currentDate, this.props.currentDate)) {
            this.changeEndDate({
                endType: this.state.endType,
                endAt: this.state.endAt ? moment.utc(moment(this.props.currentDate).endOf('day')).format('YYYY-MM-DD HH:mm:ss.SSS') : this.state.endAt
            });
        }

        if(!equal(prevProps.schedule.isRepeat, this.props.schedule.isRepeat)) {
            if(this.props.schedule.isRepeat) {
                if(this.props.schedule.recurrences.length === undefined || this.props.schedule.recurrences.length == 0) {
                    this.props.onChange({
                        schedule:{
                            recurrences : [{
                                interval: 1,
                                dayOfMonth: null,
                                weekOfMonth: null,
                                dayOfWeek: null,
                                endAt : null
                            }]
                        }
                    });
                } else {
                    this.setRecurringData();
                }
            } else {
                this.props.onChange({
                    schedule:{
                        recurrences: []
                    }
                });
            }
        }
    }

    setRecurringData() {
        const {
            currentDate,
            schedule
        } = this.props;

        let endAt = this.state.endAt;
        let endType = this.state.endType;
        let defaultDay = this.state.defaultDay;
        let defaultDateOfInterval = moment(currentDate);
        let defaultWeek = [CONSTANTS("RECURRING_DAY_OF_THE_WEEK").filter(v => v.value === (moment(currentDate).day() + ''))[0]];
        let defaultDayOfMonth = moment(currentDate).format('D');
        let defaultWeekOfMonth = CONSTANTS("RECURRING_WEEKS").filter(v => v.value === (countWeekdayOccurrencesInMonth(moment(currentDate)) + ''))[0].value;
        let defaultDayWeekOfMonth = CONSTANTS("RECURRING_DAY_OF_THE_WEEK").filter(v => v.value === moment(currentDate).day() + '')[0].value;

        for(let i = 0, len = schedule.recurrences.length; i < len; i++) {
            if(schedule.recurrences[i].endAt) {
                endType = 'custom';
                endAt = schedule.recurrences[i].endAt;
            }
        }

        switch (schedule.recurringValue) {
            case 'dailys':
                defaultDay = schedule.recurrences.length > 0 && schedule.recurrences[0].interval ? schedule.recurrences[0].interval + '' : this.state.defaultDay + '';
                break;
            case 'weeks':
                defaultWeek = schedule.recurrences.map(v => {
                    return CONSTANTS("RECURRING_DAY_OF_THE_WEEK").filter(j => j.value === v.dayOfWeek)[0];
                });
                break;
            case 'months':
                if (schedule.recurringTypeMonth === 'dayOfMonth') {
                    defaultDayOfMonth = schedule.recurrences[0].dayOfMonth;
                } else {
                    let months = schedule.recurrences.map(v => {
                        return {
                            defaultWeekOfMonth :CONSTANTS("RECURRING_WEEKS").filter(j => j.value === v.weekOfMonth)[0].value,
                            defaultDayWeekOfMonth : CONSTANTS("RECURRING_DAY_OF_THE_WEEK").filter(j => j.value === v.dayOfWeek)[0].value
                        }
                    }).reduce(v => v);

                    defaultWeekOfMonth = months.defaultWeekOfMonth;
                    defaultDayWeekOfMonth = months.defaultDayWeekOfMonth;
                }
                break;
        }

        this.changeState({
            schedule,
            defaultWeek,
            defaultDayOfMonth ,
            defaultWeekOfMonth,
            defaultDay,
            defaultDayWeekOfMonth,
            defaultDateOfInterval,
            endAt,
            endType
        });
    }

    changeState = (obj) => {
        this.setState({
            ...this.state,
            ...obj,
            schedule:{
                ...this.state.schedule,
                ...obj.schedule
            }
        });
    };

    getSelectedForDay = () => {
        let day = [];
        for (let i = 1, len = 31; i <= len; i++) {
            day.push({
                label: i + '', value: i + ''
            });
        }

        return day;
    };

    onChangeRecurrencesDaliys = (value) => {
        const maxInterval = 99999;
        let defaultDay = value;
        if(value !== '' && Number(value) <= 0) {
            defaultDay = 1;
        } else if (Number(value) > maxInterval) {
            defaultDay = maxInterval;
        }

        //let endAt = this.utcEndAt();
        const endAt = this.props.schedule.recurrences && this.props.schedule.recurrences.length > 0
        ? this.props.schedule.recurrences[0].endAt : null;
        this.changeState({defaultDay});
        this.props.onChange({
            schedule: {
                recurringType:'custom',
                recurringValue : 'dailys',
                recurrences : [{
                    interval: defaultDay,
                    dayOfMonth: null,
                    weekOfMonth: null,
                    dayOfWeek: null,
                    endAt
                }]
            }
        });
    };

    onChangeRecurrencesDayOfTheWeek = (options) => {
        if(!options || options.length === 0)
            return;

        this.changeState({
            defaultWeek : options
        });

        //let endAt = this.utcEndAt();
        let recurrences = [];

        const endAt = this.props.schedule.recurrences && this.props.schedule.recurrences.length > 0
        ? this.props.schedule.recurrences[0].endAt : null;

        for(let i = 0, len = options.length; i < len; i++) {
            recurrences.push({
                interval: null,
                dayOfMonth: '*',
                weekOfMonth: '*',
                dayOfWeek: options[i].value,
                endAt
            });
        }

        this.props.onChange({
            schedule: {
                recurringType:'custom',
                recurringValue : 'weeks',
                recurrences
            }
        });
    };

    onChangeRecurrencesDayOfMonth = (value) => {
        if(!value || value === '')
            value = 1;
        let day = Number(value);
        if(day <= 0) {
            day = 1;
        }
        else if(day > 31)
            day = 31;
        this.changeState({
            schedule:{
                recurringTypeMonth: 'dayOfMonth'
            },
            defaultDayOfMonth: day
        });

        let endAt = this.utcEndAt();

        const firstRecurrence = this.props.schedule.recurrences && this.props.schedule.recurrences.length > 0
        ? this.props.schedule.recurrences[0]
        : {
            interval: null,
            dayOfMonth: day,
            weekOfMonth: '*',
            dayOfWeek: '*',
            endAt: null
        };
        if(firstRecurrence) {
            firstRecurrence.interval = null;
            firstRecurrence.dayOfMonth = day;
            firstRecurrence.weekOfMonth = '*';
            firstRecurrence.dayOfWeek = '*';
        }
        let recurrences = [firstRecurrence];
        this.props.onChange({
            schedule: {
                recurringType:'custom',
                recurringValue : 'months',
                recurringTypeMonth: 'dayOfMonth',
                recurrences
            }
        });
    };

    onChangeRecurrencesWeekOfMonth = (value) => {
        this.changeState({
            schedule:{
                recurringTypeMonth : 'dayOfWeek'
            },
            defaultWeekOfMonth: value
        });

        let endAt = this.utcEndAt();
        let dayOfWeek = this.state.defaultDayWeekOfMonth;

        const firstRecurrence = this.props.schedule.recurrences && this.props.schedule.recurrences.length > 0
        ? this.props.schedule.recurrences[0]
        : {
            interval: null,
            dayOfMonth: '*',
            weekOfMonth: value,
            dayOfWeek: dayOfWeek,
            endAt: null
        };
        if(firstRecurrence) {
            firstRecurrence.interval = null;
            firstRecurrence.dayOfMonth = '*';
            firstRecurrence.weekOfMonth = value;
            firstRecurrence.dayOfWeek = dayOfWeek;
        }

        let recurrences = [firstRecurrence];

        this.props.onChange({
            schedule: {
                recurringType:'custom',
                recurringValue : 'months',
                recurringTypeMonth : 'dayOfWeek',
                recurrences
            }
        });
    };

    onChangeRecurrencesDayWeekOfMonth = ({value}) => {
        this.changeState({
            defaultDayWeekOfMonth: value
        });

        let endAt = this.utcEndAt();
        let weekOfMonth = this.state.defaultWeekOfMonth;

        const firstRecurrence = this.props.schedule.recurrences && this.props.schedule.recurrences.length > 0
        ? this.props.schedule.recurrences[0]
        : {
            interval: null,
            dayOfMonth: '*',
            weekOfMonth: weekOfMonth,
            dayOfWeek: value,
            endAt: null
        };
        if(firstRecurrence) {
            firstRecurrence.interval = null;
            firstRecurrence.dayOfMonth = '*';
            firstRecurrence.weekOfMonth = weekOfMonth;
            firstRecurrence.dayOfWeek = value;
        }
        let recurrences = [firstRecurrence];

        this.props.onChange({
            schedule: {
                recurringType:'custom',
                recurringValue : 'months',
                recurrences
            }
        });
    };

    onSelectFocus = (e) => {
        e.target.closest('.select2-container').classList.add('select2-focus');
    };

    onSelectBlur = (e) => {
        e.target.closest('.select2-container').classList.remove('select2-focus');
    };

    setDefaultCustomRecurrences = (type) => {
        switch (type) {
            case 'dailys' :
                return this.onChangeRecurrencesDaliys(this.state.defaultDay);
            case 'weeks':
                return this.onChangeRecurrencesDayOfTheWeek(this.state.defaultWeek);
            case 'months' :
                return this.onChangeRecurrencesDayOfMonth(this.state.defaultDayOfMonth);
        }
    }

    changeEndDate = ({endType, endAt}) => {
        this.changeState({
            endType,
            endAt: endAt ? moment(endAt) : moment(this.props.currentDate)
        });
        let end = endAt;
        if(endType === 'custom' && (end === undefined || end === null))
            end = this.props.currentDate;

        if (endType === 'endNow')
            end = moment.utc(moment()).format('YYYY-MM-DD HH:mm:ss.SSS');
        else if(endType === 'never')
            end = null;
        else
            end = moment.utc(moment(end).endOf('day')).format('YYYY-MM-DD HH:mm:ss.SSS');

        this.props.onChange({
            schedule:{
                recurrences: this.props.schedule.recurrences.map(v => {
                    return {
                        ...v,
                        endAt: end
                    };
                })
            }
        });
    };

    utcEndAt = () => {
        const {
            endAt
        } = this.state;

        return endAt ? moment.utc(moment(endAt).endOf('day')).format('YYYY-MM-DD HH:mm:ss.SSS'): endAt;
    };

    utcStartAt = (startAt) => {
        return moment.utc(startAt).format('YYYY-MM-DD HH:mm:ss.SSS');
    };

    render() {
        const {
            isRepeat
        } = this.props.schedule;

        const { t, i18n } = this.props;

        return(
            <div className={`repeat-schedule ${!this.props.isEnabled ? 'disabled' : ''}`} >
                {this.props.displayRepeatPeriod &&
                    <label className={"form-checkbox"}>
                        <input
                            type="checkbox"
                            name={'Recurrence'}
                            value={isRepeat}
                            checked={isRepeat}
                            disabled={!this.props.isEnabled}
                            className={"form-check-input"}
                            onChange={(e) => {
                                this.props.onChange(
                                {
                                    schedule:{
                                        isRepeat: e.target.checked
                                    }
                                });
                            }}
                            />
                        <i className={"input-helper"}></i>
                        <strong>{t(`common::label::${"Repeat"}`)}</strong>
                    </label>
                }
                {/* period 위치 변경 */}
                <div hidden={!isRepeat} className="form-block">
                    <strong className={"form-label"}>{t(`common::label::${"Period"}`)}</strong>
                    <div className={"form-block"}>
                        <label className={"form-checkbox"}>
                            <input
                                type="radio"
                                name={'recurring-end-type'}
                                className={"form-check-input"}
                                value={'never'}
                                checked={this.state.endType === 'never'}
                                onChange={() => {
                                    this.changeEndDate({
                                        endType: 'never',
                                        endAt : null
                                    });
                                }}
                                disabled={!this.props.isEnabled}/>
                            <i className={"input-helper"}></i>
                            <span>{t(`common::label::${"Never"}`)}</span>
                        </label>
                        <label className={"form-checkbox"}>
                            <input
                                type="radio"
                                name={'recurring-end-type'}
                                className={"form-check-input"}
                                value={'custom'}
                                checked={this.state.endType === 'custom'}
                                onChange={() => {
                                    this.changeEndDate({
                                        endType: 'custom',
                                        endAt : moment(this.props.currentDate)
                                    });
                                }}
                                disabled={!this.props.isEnabled}/>
                            <i className={"input-helper"}></i>
                            <span>{t(`common::label::${"Period setting"}`)}</span>
                        </label>
                        <label
                            className={"form-checkbox"}
                            hidden={this.props.displayRepeatPeriod}
                            >
                            <input
                                type="radio"
                                name={'recurring-end-type'}
                                className={"form-check-input"}
                                value={'endNow'}
                                checked={this.state.endType === 'endNow'}
                                onChange={() => {
                                    this.changeEndDate({
                                        endType: 'endNow',
                                        endAt : moment().format('YYYY-MM-DD HH:mm:ss.SSS')
                                    });
                                }}/>
                            <i className={"input-helper"}></i>
                            <span>{t(`common::label::${"End now"}`)}</span>
                        </label>
                    </div>
                    <div className="">
                        <div className={"form-block form-input-group"}>
                            <div className={"form-input-group-title disabled"}>
                                <span className={`input-group-text `} >
                                {t(`common::label::${"Start date"}`)}
                                </span>
                            </div>
                            <div className={"flex-grow-1"}>
                                <SingleDatePicker
                                    date={this.props.schedule && this.props.schedule.recurrences && this.props.schedule.recurrences.length > 0 ? moment.utc(this.props.schedule.recurrences[0].startAt).local() : moment()}
                                    onDateChange={date => {}}
                                    onFocusChange={({focused}) => this.setState({focused})}
                                    displayFormat={"YYYY-MM-DD"}
                                    numberOfMonths={1}
                                    small={true}
                                    showDefaultInputIcon={true}
                                    inputIconPosition="before"
                                    disabled={true}
                                    anchorDirection={"right"}
                                />
                            </div>
                        </div>
                        <div className={"form-block form-input-group"}>
                            <div className={`form-input-group-title ${!this.props.isEnabled || this.state.endType === 'never'  || this.state.endType === 'endNow' ? 'disabled' : ''}`}>
                                <span className={`input-group-text`} >
                                {t(`common::label::${"End date"}`)}
                                </span>
                            </div>
                            <div className={"flex-grow-1"}>
                                {this.state.endType === 'custom' ? (
                                    <SingleDatePicker
                                    date={this.state.endAt ? moment(this.state.endAt) : moment(this.props.currentDate)}
                                    onDateChange={date => {
                                        this.changeEndDate({
                                            endType: 'custom',
                                            endAt: date
                                        });
                                    }}
                                    focused={this.state.focused}
                                    onFocusChange={({focused}) => this.setState({focused})}
                                    displayFormat={"YYYY-MM-DD"}
                                    numberOfMonths={1}
                                    small={true}
                                    showDefaultInputIcon={true}
                                    inputIconPosition="before"
                                    disabled={!this.props.isEnabled || this.state.endType === 'never' || this.state.endType === 'endNow'}
                                    anchorDirection={"right"}
                                    />
                                ) : this.state.endType === 'never' ? (
                                    <div className='form-input-fake'>
                                        <i className="sprite sprite-calendar-select"></i>
                                        <span className="text-cancel">
                                            {t(`common::label::Never`)}
                                        </span>
                                    </div>
                                ) : (
                                    <div className='form-input-fake'>
                                        <i className="sprite sprite-calendar-select"></i>
                                        <span>
                                            {this.state.endAt ? moment(this.state.endAt).format("YYYY-MM-DD") : moment(this.props.currentDate).format("YYYY-MM-DD")} {`(${t('common::label::Now')})`}
                                        </span>
                                    </div>
                                )
                            }
                            </div>
                        </div>
                    </div>
                </div>

                {this.props.displayRepeatPeriod &&
                    <React.Fragment>
                        <div className={"form-block"} hidden={!isRepeat}>
                            <strong className={"form-label"}>{t(`common::label::${"Cycle"}`)}</strong>
                            <div className={"form-block"}>
                                <div className={"radio-group radio-group-fill"}>
                                    {CONSTANTS("LISTINGS_RECURRING_CUSTOM_TYPES").map((v,i) =>
                                        <label key={i} className={"form-radio"}>
                                            <input
                                                name={"recurring-custom-type"}
                                                className={"form-check-input"}
                                                type={"radio"}
                                                value={v.value}
                                                checked={v.value === this.state.schedule.recurringValue}
                                                onChange={() => {
                                                    this.props.onChange({
                                                        schedule:{
                                                            recurringType: 'custom',
                                                            recurringValue: v.value,
                                                            recurringTypeMonth : 'dayOfMonth'
                                                        }
                                                    });

                                                    this.setDefaultCustomRecurrences(v.value);
                                                }}
                                                disabled={!this.props.isEnabled || this.state.schedule.recurringType === 'default'}
                                            />
                                            <i className={"input-helper"}></i>
                                            <span>{v.label}</span>
                                        </label>
                                    )}
                                </div>
                                {this.state.schedule.recurringType === 'custom' &&
                                <div className={"repeat-custom"}>
                                    {this.state.schedule.recurringValue === 'dailys' &&
                                    <div className={"form-block"}>
                                        <div className={"d-flex align-items-center"}>
                                            <div className={"text-prepend"}>{t(`common::label::${"Every"}`)}</div>
                                            <div className={" flex-grow-1"}>
                                                <input
                                                    type={"number"}
                                                    className={"form-control"}
                                                    min={1}
                                                    value={this.state.defaultDay}
                                                    onChange={(e) => this.onChangeRecurrencesDaliys(e.target.value)}
                                                    onBlur={(e) => {
                                                        if(e.target.value === '') {
                                                            this.onChangeRecurrencesDaliys(1);
                                                        }
                                                    }}
                                                    onFocus={(e) => {
                                                        if(e.target.value === '') {
                                                            this.onChangeRecurrencesDaliys(1);
                                                        }
                                                    }}
                                                    disabled={!this.props.isEnabled}/>
                                            </div>
                                            <div className={"text-append"}>{t(`common::label::${"Day"}`)}</div>
                                        </div>
                                    </div>
                                    }
                                    {this.state.schedule.recurringValue === 'weeks' &&
                                    <div className={"form-block"}>
                                        <div className={"d-flex align-items-center"}>
                                            <div className={"text-prepend"}>{t(`common::label::${"Every"}`)}</div>
                                            <div className={"flex-grow-1 d-flex"}>
                                                <div className={"flex-grow-1"}>
                                                    <div className={"select-week"}>
                                                    {CONSTANTS("RECURRING_DAY_OF_THE_WEEK").map((v, i) =>
                                                        <label key={i} className={"form-checkbox"}>
                                                            <input
                                                                name={"select-day"}
                                                                className={"form-check-input"}
                                                                type={"checkbox"}
                                                                value={v.value}
                                                                checked={this.state.defaultWeek.filter(d => d.value === v.value).length > 0}
                                                                onChange={(e) => {
                                                                    let _defaultWeek = Object.assign([], this.state.defaultWeek);
                                                                    let index = 0;

                                                                    if(!e.target.checked) {
                                                                        this.state.defaultWeek.filter((d, idx) => {
                                                                            if (d.value === v.value) {
                                                                                index = idx;
                                                                            }
                                                                            return d.value === v.value;
                                                                        });

                                                                        _defaultWeek.splice(index, 1);
                                                                    } else {
                                                                        _defaultWeek.push(v);
                                                                    }

                                                                    this.onChangeRecurrencesDayOfTheWeek(_defaultWeek);
                                                                }}
                                                                disabled={!this.props.isEnabled}
                                                            />
                                                            <i className={"input-helper"}></i>
                                                            <span>{v.label}</span>
                                                        </label>
                                                    )}
                                                    </div>

                                                    {/*<WithSelect*/}
                                                    {/*    placeholder={"Select..."}*/}
                                                    {/*    defaultValue={this.state.defaultWeek}*/}
                                                    {/*    onChange={(e) => this.onChangeRecurrencesDayOfTheWeek(e)}*/}
                                                    {/*    onFocus={(e) => this.onSelectFocus(e)}*/}
                                                    {/*    onBlur={(e) => this.onSelectBlur(e)}*/}
                                                    {/*    options={CONSTANTS("RECURRING_DAY_OF_THE_WEEK")}*/}
                                                    {/*    isMulti={true}*/}
                                                    {/*    isDisabled={!this.props.isEnabled}/>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    {this.state.schedule.recurringValue === 'months' &&
                                    <div className={"form-block"}>
                                        <div className={"d-flex"}>
                                            <div className={"text-prepend"}>{t(`common::label::${"Every"}`)}</div>
                                            <div className={"flex-grow-1"}>
                                                <div className={"d-flex"}>
                                                    <div className={"flex-grow-1"}>
                                                        <div className={"d-flex align-items-center mt-1"}>
                                                            <label
                                                                className={"form-radio"}>
                                                                <input
                                                                    type="radio"
                                                                    name={"recurring-month-type"}
                                                                    className={"form-check-input"}
                                                                    checked={this.state.schedule.recurringTypeMonth === 'dayOfMonth'}
                                                                    value={"dayOfMonth"}
                                                                    onChange={(e) => {
                                                                        this.onChangeRecurrencesDayOfMonth(this.state.defaultDayOfMonth);
                                                                    }}
                                                                    disabled={!this.props.isEnabled}/>
                                                                <i className={"input-helper"}></i>
                                                            </label>
                                                            <input
                                                                type={"number"}
                                                                className={"form-control"}
                                                                min={1}
                                                                max={31}
                                                                value={this.state.defaultDayOfMonth}
                                                                onChange={(e) => this.onChangeRecurrencesDayOfMonth(e.target.value)}
                                                                onBlur={(e) => {
                                                                    if(e.target.value === '') {
                                                                        this.onChangeRecurrencesDayOfMonth(1);
                                                                    }
                                                                }}
                                                                onFocus={(e) => {
                                                                    if(e.target.value === '') {
                                                                        this.onChangeRecurrencesDayOfMonth(1);
                                                                    }
                                                                }}
                                                                disabled={!this.props.isEnabled || this.state.schedule.recurringTypeMonth === 'dayOfWeek'}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={"text-append inherit-width"}>{t(`common::label::${"Day"}`)}
                                                    </div>
                                                </div>

                                                <div className={"d-flex"}>
                                                    <div className={"flex-grow-1"}>
                                                        <div
                                                            className={"d-flex"}>
                                                                <label
                                                                className={"form-radio"}>
                                                                <input
                                                                    type="radio"
                                                                    name={"recurring-months-type"}
                                                                    checked={this.state.schedule.recurringTypeMonth === 'dayOfWeek'}
                                                                    className={"form-check-input"}
                                                                    value={"dayOfWeek"}
                                                                    onChange={(e) => {
                                                                        this.onChangeRecurrencesWeekOfMonth(this.state.defaultWeekOfMonth);
                                                                    }}
                                                                    disabled={!this.props.isEnabled}/>
                                                                <i className={"input-helper"}></i>
                                                            </label>
                                                            <div className={"flex-grow-1"}>
                                                                <div className="form-block">
                                                                    <WithSelect
                                                                        placeholder={t(`common::label::${"Select..."}`)}
                                                                        defaultValue={this.state.defaultWeekOfMonth}
                                                                        onChange={({value}) => this.onChangeRecurrencesWeekOfMonth(value)}
                                                                        onFocus={(e) => this.onSelectFocus(e)}
                                                                        onBlur={(e) => this.onSelectBlur(e)}
                                                                        options={CONSTANTS("RECURRING_WEEKS")}
                                                                        isDisabled={!this.props.isEnabled || this.state.schedule.recurringTypeMonth === 'dayOfMonth'}/>
                                                                </div>
                                                                <div className="form-block">
                                                                    <div className={"select-week"}>
                                                                    {CONSTANTS("RECURRING_DAY_OF_THE_WEEK").map((v, i) =>
                                                                        <label
                                                                        key={i} className={"form-checkbox"}>
                                                                            <input
                                                                                name={"select-day"}
                                                                                className={"form-check-input"}
                                                                                type={"checkbox"}
                                                                                value={v.value}
                                                                                checked={this.state.defaultDayWeekOfMonth === v.value}
                                                                                onChange={() => {
                                                                                    this.onChangeRecurrencesDayWeekOfMonth(v);
                                                                                }}
                                                                                disabled={!this.props.isEnabled || this.state.schedule.recurringTypeMonth === 'dayOfMonth'}
                                                                            />
                                                                            <i className={"input-helper"}></i>
                                                                            <span>{v.label}</span>
                                                                        </label>
                                                                    )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={"text-append inherit-width"}> </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    }
                                </div>
                                }
                            </div>
                        </div>
                    </React.Fragment>
                }

            </div>
        )
    }
}

const countWeekdayOccurrencesInMonth = (currentDate) => {
    const date = new Date(moment.utc(currentDate).local().format("YYYY-MM-DD"));
    var selectedDayOfMonth = date.getDate();

    var first = new Date(date.getFullYear() + '/' + (date.getMonth() + 1) + '/01');
    var monthFirstDateDay = first.getDay();

    return Math.ceil((selectedDayOfMonth + monthFirstDateDay) / 7);
};


export default withTranslation()(Recurring);