import { handleActions, createAction } from "redux-actions";
import { axios, axiosWithoutHeaders } from "../cores/axiosWrapper";

function getRolesAPI(stageId, apiEndpoint, projectId, params) {
    const headers = {
        stageId,
    };

    if (projectId) {
        headers.projectId = projectId;
    }

    let queries = {
        offset: params.offset * params.limit,
        limit: params.limit,
    };

    if (params.keyword !== "") {
        queries[params.keywordType] = params.keyword;
    }

    return axios.get(`${apiEndpoint}/roles`, {
        headers,
        params: queries,
    });
}
function postRolesAPI(stageId, apiEndpoint, projectId, params) {
    const headers = {
        stageId,
    };

    if (projectId) {
        headers.projectId = projectId;
    }
    return axios.post(`${apiEndpoint}/roles`, params, {
        headers,
    });
}
function getRoleAPI(stageId, apiEndpoint, projectId, id) {
    const headers = {
        stageId,
    };

    if (projectId) {
        headers.projectId = projectId;
    }
    return axios.get(`${apiEndpoint}/roles/${id}`, {
        headers,
    });
}
function deleteRoleAPI(stageId, apiEndpoint, id) {
    return axios.delete(`${apiEndpoint}/roles/${id}`, {
        headers: {
            stageId,
        },
    });
}
function patchRoleAPI(stageId, apiEndpoint, projectId, id, role) {
    return axios.patch(
        `${apiEndpoint}/roles/${id}`,
        { ...role },
        {
            headers: {
                stageId,
                "Content-Type": "application/json",
            },
        },
    );
}
function getPermissionsAPI(stageId, apiEndpoint, projectId, id, params) {
    const headers = {
        stageId,
    };

    if (projectId) {
        headers.projectId = projectId;
    }

    return axios.get(`${apiEndpoint}/roles/${id}/permissions`, {
        params,
        headers,
    });
}
function postPermissionsAPI(stageId, apiEndpoint, id, permission) {
    return axios.post(
        `${apiEndpoint}/roles/${id}/permissions`,
        { permission },
        {
            headers: {
                stageId,
            },
        },
    );
}
function deletePermissionsAPI(stageId, apiEndpoint, id, permissionId) {
    return axios.delete(`${apiEndpoint}/roles/${id}/permissions/${permissionId}`, {
        headers: {
            stageId,
        },
    });
}
function getActionsAPI(stageId, apiEndpoint, projectId, params) {
    const headers = {
        stageId,
    };

    if (projectId) {
        headers.projectId = projectId;
    }

    return axios.get(`${apiEndpoint}/actions`, {
        params,
        headers,
    });
}
function getActionGroupsAPI(stageId, apiEndpoint, params) {
    return axios.get(`${apiEndpoint}/action-groups`, {
        params,
        headers: {
            stageId,
        },
    });
}

const ROLES_MANAGEMENT_PENDING = "ROLES_MANAGEMENT_PENDING";
const ROLES_MANAGEMENT_FAILURE = "ROLES_MANAGEMENT_FAILURE";
const GET_ROLES_MANAGEMENT_ROLES_SUCCESS = "GET_ROLES_MANAGEMENT_ROLES_SUCCESS";

const SET_ROLES_MANAGEMENT_ROLES_ROLE_SUCCESS = "SET_ROLES_MANAGEMENT_ROLES_ROLE_SUCCESS";
const GET_ROLES_MANAGEMENT_ROLES_PERMISSIONS_SUCCESS = "GET_ROLES_MANAGEMENT_ROLES_PERMISSIONS_SUCCESS";

const UPDATE_ROLES_PARAMS = "UPDATE_ROLES_PARAMS";
const TOGGLE_GET_ROLES_MANAGEMENT_ACTIONS_ROLES = "TOGGLE_GET_ROLES_MANAGEMENT_ACTIONS_ROLES";
const RESET_ROLES_MANAGEMENT_ROLES = "RESET_ROLES_MANAGEMENT_ROLES";
const RESET_ROLES_MANAGEMENT_FOR_ROLES_PARAMS = "RESET_ROLES_MANAGEMENT_FOR_ROLES_PARAMS";

const GET_ROLES_MANAGEMENT_ROLE_SUCCESS = "GET_ROLES_MANAGEMENT_ROLE_SUCCESS";

const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";

const GET_ROLES_MANAGEMENT_ACTIONS_SUCCESS = "GET_ROLES_MANAGEMENT_ACTIONS_SUCCESS";
const GET_ROLES_MANAGEMENT_ACTION_GROUPS_SUCCESS = "GET_ROLES_MANAGEMENT_ACTION_GROUPS_SUCCESS";
const UPDATE_ROLES_MANAGEMENT_ACTIONS_PARAMS = "UPDATE_ROLES_MANAGEMENT_ACTIONS_PARAMS";
const RESET_ROLES_MANAGEMENT_ACTIONS_PARAMS = "RESET_ROLES_MANAGEMENT_ACTIONS_PARAMS";

const COLLAPSED_ROLES_MANAGEMENT_ACTION_GROUPS = "COLLAPSED_ROLES_MANAGEMENT_ACTION_GROUPS";
const CHECKED_ROLES_MANAGEMENT_ACTION_GROUPS = "CHECKED_ROLES_MANAGEMENT_ACTION_GROUPS";
const CHECKED_ROLES_MANAGEMENT_ACTIONS_PERMISSIONS = "CHECKED_ROLES_MANAGEMENT_ACTIONS_PERMISSIONS";
const INIT_ROLES_MANAGEMENT_ACTIONS = "INIT_ROLES_MANAGEMENT_ACTIONS";
const RESET_ROLES_MANAGEMENT_ACTIONS = "RESET_ROLES_MANAGEMENT_ACTIONS";

const initialState = {
    pending: false,
    error: false,
    roles: {
        totalCount: 0,
        params: {
            keywordType: "name",
            keyword: "",
            offset: 0,
            limit: 50,
        },
        item: null,
        list: [],
    },
    permissions: {
        params: {
            offset: 0,
            limit: 50,
        },
        list: [],
        item: null,
        totalCount: 0,
    },
    actions: {
        params: {
            scope: "",
            resource: "",
            name: "",
            offset: 0,
            limit: 50,
        },
        list: [],
    },
    users: {
        params: {
            offset: 0,
            limit: 50,
        },
        list: [],
        totalCount: 0,
    },
};

export default handleActions(
    {
        [ROLES_MANAGEMENT_PENDING]: (state) => {
            return {
                ...state,
                pending: true,
                error: false,
            };
        },
        [ROLES_MANAGEMENT_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [GET_ROLES_MANAGEMENT_ROLES_SUCCESS]: (state, action) => {
            const { totalCount, results } = action.payload.data;

            return {
                ...state,
                pending: false,
                error: false,
                roles: {
                    ...state.roles,
                    list: results,
                    totalCount,
                },
            };
        },
        [SET_ROLES_MANAGEMENT_ROLES_ROLE_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
            };
        },
        [GET_ROLES_MANAGEMENT_ROLE_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                pending: false,
                error: false,
                roles: {
                    ...state.roles,
                    item: data,
                },
            };
        },
        [UPDATE_ROLE_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                pending: false,
                error: false,
                roles: {
                    ...state.roles,
                    item: data,
                },
            };
        },
        [DELETE_ROLE_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
            };
        },
        [UPDATE_ROLES_PARAMS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
                roles: {
                    ...state.roles,
                    params: {
                        ...state.roles.params,
                        ...action.payload,
                    },
                },
            };
        },
        [TOGGLE_GET_ROLES_MANAGEMENT_ACTIONS_ROLES]: (state, action) => {
            return {
                ...state,
                roles: {
                    ...state.roles,
                    list: state.roles.list.map((v) => {
                        return {
                            ...v,
                            open: v.id === action.payload ? !v.open : false,
                        };
                    }),
                },
            };
        },
        [RESET_ROLES_MANAGEMENT_ROLES]: (state) => {
            return {
                ...state,
                roles: {
                    ...state.roles,
                    list: [],
                    params: {
                        offset: 0,
                        limit: 50,
                    },
                    totalCount: 0,
                },
            };
        },
        [GET_ROLES_MANAGEMENT_ROLES_PERMISSIONS_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                pending: false,
                error: false,
                permissions: {
                    ...state.permissions,
                    totalCount: data.totalCount,
                    list: data.results,
                },
            };
        },
        [GET_ROLES_MANAGEMENT_ACTIONS_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                pending: false,
                error: false,
                actions: {
                    ...state.actions,
                    list: getNodes(data, "init"),
                },
            };
        },
        [GET_ROLES_MANAGEMENT_ACTION_GROUPS_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                pending: false,
                error: false,
                actions: {
                    ...state.actions,
                    totalCount: data.totalCount,
                    list: getNodes(data, "init"),
                },
            };
        },
        [COLLAPSED_ROLES_MANAGEMENT_ACTION_GROUPS]: (state, action) => {
            return {
                ...state,
                actions: {
                    ...state.actions,
                    list: state.actions.list.map((v) => {
                        return {
                            ...v,
                            isCollapsed: v.id === action.payload ? !v.isCollapsed : true,
                        };
                    }),
                },
            };
        },
        [CHECKED_ROLES_MANAGEMENT_ACTION_GROUPS]: (state, action) => {
            const { groupId, actionId, actionName } = action.payload;

            return {
                ...state,
                actions: {
                    ...state.actions,
                    list: state.actions.list.map((v) => {
                        if (v.id === groupId) {
                            if (actionId) {
                                return {
                                    ...v,
                                    actions: v.actions.map((z) => {
                                        return {
                                            ...z,
                                            selected: z.id === actionId ? !z.selected : z.selected,
                                        };
                                    }),
                                };
                            } else if (!actionId && actionName) {
                                return {
                                    ...v,
                                    actions: v.actions.map((z) => {
                                        return {
                                            ...z,
                                            selected: z.target === actionName ? !z.selected : z.selected,
                                        };
                                    }),
                                };
                            }
                        } else {
                            return v;
                        }
                    }),
                },
            };
        },
        [RESET_ROLES_MANAGEMENT_FOR_ROLES_PARAMS]: (state) => {
            return {
                ...state,
                roles: {
                    ...state.roles,
                    params: {
                        keywordType: "name",
                        keyword: "",
                        offset: 0,
                        limit: 50,
                    },
                },
            };
        },
        [UPDATE_ROLES_MANAGEMENT_ACTIONS_PARAMS]: (state, action) => {
            return {
                ...state,
                actions: {
                    ...state.actions,
                    params: {
                        ...state.actions.params,
                        ...action.payload,
                    },
                },
            };
        },
        [RESET_ROLES_MANAGEMENT_ACTIONS_PARAMS]: (state) => {
            return {
                ...state,
                actions: {
                    ...state.actions,
                    params: {
                        ...state.actions.params,
                        scope: "",
                        resource: "",
                        name: "",
                    },
                },
            };
        },
        [CHECKED_ROLES_MANAGEMENT_ACTIONS_PERMISSIONS]: (state, action) => {
            const { permission } = action.payload;

            return {
                ...state,
                actions: {
                    ...state.actions,
                    list: getNodes(state.actions.list, permission.id),
                },
            };
        },
        [INIT_ROLES_MANAGEMENT_ACTIONS]: (state) => {
            return {
                ...state,
                actions: {
                    params: {
                        scope: "",
                        resource: "",
                        name: "",
                        offset: 0,
                        limit: 50,
                    },
                    list: [],
                },
            };
        },
        [RESET_ROLES_MANAGEMENT_ACTIONS]: (state) => {
            let list = getNodes(state.actions.list, "init");
            let _list = state.permissions.list
                .map((v) => {
                    return getNodes(list, v.action.id);
                })
                .reduce((v) => v);

            return {
                ...state,
                actions: {
                    ...state.actions,
                    list: _list,
                },
            };
        },
    },
    initialState,
);

const getNodes = (list, id, disabled = false) => {
    return list.map((v) => {
        if (v.id === id) {
            if (v.children) {
                return {
                    ...v,
                    isChecked: !v.isChecked,
                    disabled: false,
                    children: getNodes(v.children, id, !v.isChecked),
                };
            } else {
                return {
                    ...v,
                    disabled: false,
                    isChecked: !v.isChecked,
                };
            }
        } else {
            if (v.children) {
                return {
                    ...v,
                    disabled,
                    isChecked: disabled ? false : v.isChecked || false,
                    children: getNodes(v.children, id, disabled),
                };
            } else {
                return {
                    ...v,
                    disabled,
                    isChecked: disabled ? false : v.isChecked || false,
                };
            }
        }
    });
};

export const resetRolesActions = createAction(RESET_ROLES_MANAGEMENT_ACTIONS);
export const initRolesActions = createAction(INIT_ROLES_MANAGEMENT_ACTIONS);
export const checkedPermissions = createAction(CHECKED_ROLES_MANAGEMENT_ACTIONS_PERMISSIONS);
export const resetActionsParams = createAction(RESET_ROLES_MANAGEMENT_ACTIONS_PARAMS);
export const updateActionsParams = createAction(UPDATE_ROLES_MANAGEMENT_ACTIONS_PARAMS);
export const resetRoles = createAction(RESET_ROLES_MANAGEMENT_ROLES);
export const resetRolesParams = createAction(RESET_ROLES_MANAGEMENT_FOR_ROLES_PARAMS);
export const toggleAction = createAction(TOGGLE_GET_ROLES_MANAGEMENT_ACTIONS_ROLES);
export const collapsedActionGroup = createAction(COLLAPSED_ROLES_MANAGEMENT_ACTION_GROUPS);
export const updateParams = createAction(UPDATE_ROLES_PARAMS);
export const getRoles =
    (params = {}, projectId) =>
    (dispatch, getState) => {
        const { stage } = getState();

        dispatch({ type: ROLES_MANAGEMENT_PENDING });

        return getRolesAPI(stage.id, stage.endpoint, projectId, params)
            .then((response) => {
                dispatch({
                    type: GET_ROLES_MANAGEMENT_ROLES_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: ROLES_MANAGEMENT_FAILURE,
                    payload: error,
                });
            });
    };
export const getRole =
    ({ id, projectId }) =>
    (dispatch, getState) => {
        const { stage } = getState();

        dispatch({ type: ROLES_MANAGEMENT_PENDING });

        return getRoleAPI(stage.id, stage.endpoint, projectId, id)
            .then((response) => {
                dispatch({
                    type: GET_ROLES_MANAGEMENT_ROLE_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: ROLES_MANAGEMENT_FAILURE,
                    payload: error,
                });
            });
    };
export const deleteRole =
    (roleId, callback = () => {}) =>
    (dispatch, getState) => {
        const { stage } = getState();

        dispatch({ type: ROLES_MANAGEMENT_PENDING });

        return deleteRoleAPI(stage.id, stage.endpoint, roleId)
            .then((response) => {
                dispatch({
                    type: DELETE_ROLE_SUCCESS,
                    payload: response,
                });
                callback();
            })
            .catch((error) => {
                dispatch({
                    type: ROLES_MANAGEMENT_FAILURE,
                    payload: error,
                });
            });
    };
export const updateRole =
    (roleId, projectId, params, callback = () => {}) =>
    (dispatch, getState) => {
        const { stage } = getState();

        return patchRoleAPI(stage.id, stage.endpoint, projectId, roleId, params)
            .then((response) => {
                dispatch({
                    type: UPDATE_ROLE_SUCCESS,
                    payload: response,
                });

                callback();
            })
            .catch((error) => {
                dispatch({
                    type: ROLES_MANAGEMENT_FAILURE,
                    payload: error,
                });
            });
    };
export const getPermissions =
    ({ projectId, id, params }) =>
    (dispatch, getState) => {
        const { stage } = getState();

        dispatch({ type: ROLES_MANAGEMENT_PENDING });

        return getPermissionsAPI(stage.id, stage.endpoint, projectId, id, params)
            .then((response) => {
                dispatch({
                    type: GET_ROLES_MANAGEMENT_ROLES_PERMISSIONS_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: ROLES_MANAGEMENT_FAILURE,
                    payload: error,
                });
            });
    };
export const getActions =
    ({ projectId, params }) =>
    (dispatch, getState) => {
        const { stage } = getState();

        dispatch({ type: ROLES_MANAGEMENT_PENDING });

        return getActionsAPI(stage.id, stage.endpoint, projectId, params)
            .then((response) => {
                dispatch({
                    type: GET_ROLES_MANAGEMENT_ACTIONS_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: ROLES_MANAGEMENT_FAILURE,
                    payload: error,
                });
            });
    };
export const getActionGroups = (params) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: ROLES_MANAGEMENT_PENDING });

    return getActionGroupsAPI(stage.id, stage.endpoint, params)
        .then((response) => {
            dispatch({
                type: GET_ROLES_MANAGEMENT_ACTION_GROUPS_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: ROLES_MANAGEMENT_FAILURE,
                payload: error,
            });
        });
};
export const checkedActions = (groupId, actionName, actionId) => (dispatch) => {
    dispatch({
        type: CHECKED_ROLES_MANAGEMENT_ACTION_GROUPS,
        payload: {
            groupId,
            actionId,
            actionName,
        },
    });
};
