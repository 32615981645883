import { useMemo } from "react";
import { Autocomplete as MuiAutocomplete, Chip, TextField } from "@mzc-pdc/ui";
import { IconCancelCircle, IconErrorCircle } from "@mzc-cloudplex/icons";
import i18n from "@lib/i18n";

export const AutocompleteTagsNoStarticon = ({
    type,
    open,
    tags,
    value,
    disabled,
    inputDisabled,
    checkDuplicateValue,
    checkMaxCountValue,
    checkRequiredValue,
    setValue,
    onDelete,
    onSubmit,
    validationError,
    isEdit,
    placeholder,
    helperText,
    popperComponent,
    noOptionsText,
    maxCount,
}) => {
    const getHelperText = useMemo(() => {
        if (checkDuplicateValue) return i18n.t(`common::msg::Duplicate values. This field must be unique.`);
        if (checkMaxCountValue)
            return i18n.t(`common::msg::The maximum number of tags, {{maxCount}}, has been exceeded.`, {
                maxCount,
            });
        if (checkRequiredValue) return i18n.t(`common::msg::Input required`);
        return helperText;
    }, [checkMaxCountValue, checkDuplicateValue, checkRequiredValue, validationError]);

    const isError = useMemo(() => {
        return !!checkDuplicateValue || !!checkMaxCountValue || !!checkRequiredValue || !!validationError;
    }, [checkMaxCountValue, checkDuplicateValue, checkRequiredValue, validationError]);

    return (
        <MuiAutocomplete
            freeSolo={true} //Note : freesolo를 사용시 옵션과는 상관없는 값을 사용할 수 있게 되서 noOptionsText를 사용불가능(옵션 사용하는 경우 false 사용해야함)
            multiple
            open={open}
            value={tags ? tags.map((tag) => tag.value) : []}
            options={[]}
            PopperComponent={popperComponent ?? null}
            disableCloseOnSelect={!!tags}
            disabled={disabled}
            getOptionLabel={(option) => {
                if (typeof option === "string") return option;
                else return option?.title;
            }}
            noOptionsText={noOptionsText} //Note : Chip 개선에 들어가는 검색 결과에 따른 옵션이 없을 경우 안내가 들어감
            renderTags={(v, getTagProps) => {
                return v.map((option, index) => (
                    <Chip
                        {...getTagProps({ index })}
                        size={`small`}
                        label={option}
                        avatar={<></>}
                        color={option === value || (value && index === maxCount - 1) ? "error" : undefined}
                        onDelete={() => onDelete(index)}
                        deleteIcon={<IconCancelCircle size={12} />}
                        sx={{
                            marginRight: "4px",
                        }}
                    />
                ));
            }}
            renderInput={(params) => {
                return (
                    <>
                        <TextField
                            {...params}
                            inputProps={{ ...params.inputProps, value: value, readOnly: inputDisabled }}
                            hiddenLabel
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: isError ? <IconErrorCircle color={`error`} size={16} /> : undefined,
                            }}
                            error={isError}
                            placeholder={tags?.length > 0 ? "" : placeholder}
                            helperText={getHelperText}
                            onChange={(e) => {
                                const replacedString = e.target.value.replace(/[\n\s\r\t]*/g, "");
                                setValue(replacedString);
                            }}
                            onKeyDown={(e) => {
                                if (tags?.length > 0 && e.code === "Backspace" && value === "") {
                                    onDelete(tags.length - 1);
                                }
                            }}
                            onKeyUp={(e) => {
                                if ((e.code === "Enter" || e.code === "NumpadEnter") && !isError) {
                                    onSubmit();
                                }
                            }}
                            // isEdit={isEdit}
                        />
                    </>
                );
            }}
            // open={false} //option dropdown 비활성화
        />
    );
};
