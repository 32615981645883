import React from "react";
import PropTypes from 'prop-types';

// TODO: 에러 메시지 박스 UI 추상화 및 공통 컴포넌트로 분리

export function NoProjectErrorMessageBox({ t = (f) => f }) {
    return (
        <div className={"gate-invalid"}>
            <div className={"card"}>
                <div className={"card-body"}>
                    <div className={"message"}>
                        <i className={"sprite sprite-error"}></i>
                        <p>
                            <strong className={"text-danger"}>
                                {t(`common::msg::${"There is no project available to access."}`)}
                            </strong>
                            <br />
                            <span>
                                {t(`common::msg::${"Please contact your stage administrator to access the project."}`)}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function CallMegazoneErrorMessageBox({ t = (f) => f }) {
    return (
        <div className={"gate-invalid"}>
            <div className={"card"}>
                <div className={"card-body"}>
                    <div className={"message"}>
                        <i className={"sprite sprite-error"}></i>
                        <p>
                            <strong className={"text-danger"}>
                                {t(`common::msg::${"You can't access the space."}`)}
                            </strong>
                            <span>
                                {t(
                                    `common::msg::${"The space is inaccessible because your account has expired or has been deleted."}`,
                                )}
                            </span>
                            <br />
                            <span>{t(`common::msg::${"Please contact your space manager."}`)}</span>
                        </p>
                    </div>
                    <hr />
                    <div className={"message"}>
                        <i className="sprite sprite-info-inverse"></i>
                        <p>
                            {t(
                                `common::msg::${"If you have issues with Space approval, please contact Megazone Cloud. "}`,
                            )}
                        </p>
                    </div>
                    <div className={"row"}>
                        <div className={"col"}>
                            <strong className={"title"}>
                                {t(`common::label::{{name}} inquiry`, { name: "Megazone" })}
                            </strong>
                            <p className={"dot"}>
                                <a href="https://mz-cloudplex.zendesk.com">https://mz-cloudplex.zendesk.com</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

NoProjectErrorMessageBox.propTypes = {
    t: PropTypes.func
}

CallMegazoneErrorMessageBox.propTypes = {
    t: PropTypes.func
}