import { axios } from "@cores/axiosWrapper";

export const getMetadataExportsAPI = async (apiEndpoint, projectId, params) => {
    return axios.get(`${apiEndpoint}/exported-metadata`, {
        params,
        headers: {
            projectId,
        },
    });
};
