import React, { useCallback, useEffect, useState } from "react";
import { Badge, Box, CircularProgress, Collapse, Grid, IconButton, Stack } from "@mzc-pdc/ui";
import { alpha } from "@mui/material";
import theme from "@styles/theme";
import Typography from "@components_v2/typography/typography";
import Tooltip from "@components_v2/Tooltip/tooltip";
import { IconDelete, IconDownload, IconFile, IconHierarchyFolded, IconHierarchyOpened } from "@mzc-cloudplex/icons";
import { useTranslation } from "react-i18next";
import { deleteCommentAttachments } from "@modules/comments";
import useDownloadJobs from "@hooks/useDownloadJobs";
import { useDispatch, useSelector } from "react-redux";
import { UPLOAD_LOCATION } from "@constants";
import { getMediaTypeFromMimeType } from "@lib/mime/mime";

const CommentAttachment = ({ threadInfo, commentId, attachments, isUploading, canDelete, onClickDelete }) => {
    // TODO: 첨부파일 컴포넌트 구현
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { requestDownloadJob } = useDownloadJobs();

    const [isOpenFileView, setIsOpenFileView] = useState(false);
    const [parsedAttachments, setParsedAttachments] = useState([]);

    const project = useSelector(({ project }) => project);
    const uploadQueue = useSelector(({ uploadQueue }) => uploadQueue[UPLOAD_LOCATION.UPLOAD_COMMENT_ATTACHMENT]);

    const onHandleClickDownload = (target) => {
        const downloadRequestTargets = {
            threadId: threadInfo.threadId,
            resourceId: commentId,
            targets: target.length > 0 ? target.map(({ id }) => ({ id })) : [{ id: target.id }],
            projectId: project.id,
            resourceName: attachments[0].displayName,
        };
        requestDownloadJob("COMMENT_ATTACHMENT", downloadRequestTargets, attachments.length, false);
    };

    const getFileUploadStatus = useCallback(
        (file) => {
            if (!isUploading) return undefined;
            let foundFile = null;

            for (const item of uploadQueue) {
                foundFile = item.files.find((f) => f.id === file.id);
                if (foundFile) break; // 파일을 찾았으면 더 이상 순회하지 않음
            }

            return foundFile?.status?.status;
        },
        [uploadQueue, isUploading],
    );

    const getEllipsisText = useCallback((name) => {
        if (name.length > 20) {
            const extension = name.slice(name.lastIndexOf("."));
            return name.substring(0, 20 - extension?.length) + "..." + extension;
        }
        return name;
    }, []);

    useEffect(() => {
        if (!attachments) return;
        const newAttachments = attachments.map((attach) => {
            const isUploadedAttachment = attach.accessUrl;
            const isFileObject = attach instanceof File;

            if (isUploadedAttachment) {
                return {
                    id: attach.id,
                    type: getMediaTypeFromMimeType(attach.mimeType) === "IMAGE" ? "image" : "file",
                    name: attach.displayName,
                    url: attach.accessUrl,
                };
            } else if (getMediaTypeFromMimeType(attach.type) === "IMAGE") {
                // 파일 객체이거나 이미 업로드중이어서 url이 Blob 객체를 갖는 객체일 수도 있음
                if (isFileObject) {
                    return {
                        id: attach.id,
                        type: "image",
                        name: attach.name,
                        url: URL.createObjectURL(attach),
                    };
                } else {
                    return {
                        id: attach.id,
                        type: "image",
                        name: attach.name,
                        url: attach.url,
                    };
                }
            } else {
                return {
                    id: attach.id,
                    type: "file",
                    name: attach.name,
                    url: undefined,
                };
            }
        });

        setParsedAttachments(newAttachments);
    }, [attachments]);

    return (
        <Stack>
            <Grid
                container
                alignItems={"center"}
                height={40}
                mt={2}
                gap={2.5}
                p={0.75}
                borderRadius={1}
                sx={{
                    ".hover-view": {
                        opacity: 0,
                        pointerEvents: "none",
                        transition: "opacity 0.3s",
                    },
                    "&:hover": {
                        bgcolor: alpha(theme.palette.greySecondary[50], 0.5),
                        ".hover-view": {
                            opacity: 1,
                            pointerEvents: "auto",
                        },
                    },
                }}
            >
                <Grid item xs>
                    <Grid container gap={0.5}>
                        <Typography component={"span"} color={"primary.main"}>
                            {parsedAttachments.length}
                        </Typography>
                        <Typography component={"span"}>{t(`common::label::files`, `files`)}</Typography>
                        <Tooltip
                            title={t(`common::label::View Files`, `View Files`)}
                            onClick={() => setIsOpenFileView(!isOpenFileView)}
                        >
                            <IconButton sx={{ width: 20, height: 20 }}>
                                {isOpenFileView ? (
                                    <IconHierarchyOpened size={12} htmlColor={theme.palette.greySecondary[500]} />
                                ) : (
                                    <IconHierarchyFolded size={12} htmlColor={theme.palette.greySecondary[500]} />
                                )}
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid
                        className={"hover-view"}
                        container
                        gap={0.75}
                        px={0.75}
                        py={0.5}
                        bgcolor={"greySecondary.main"}
                        color={"greySecondary.contrastText"}
                        borderRadius={`2px`}
                        boxShadow={`0px 2px 4px 0px ${alpha(theme.palette.common.black, 0.16)}`}
                        hidden={isUploading}
                    >
                        <Tooltip title={t("common::label::Download")}>
                            <IconButton
                                color={"inherit"}
                                sx={{ width: 20, height: 20 }}
                                onClick={() => onHandleClickDownload(parsedAttachments)}
                            >
                                <IconDownload size={16} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t("common::label::Delete")}>
                            <IconButton
                                color={"inherit"}
                                sx={{ width: 20, height: 20 }}
                                onClick={() => onClickDelete(commentId, parsedAttachments)}
                                hidden={!canDelete}
                            >
                                <IconDelete size={16} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Collapse in={isOpenFileView}>
                <Box px={0.75} pt={1}>
                    <Grid container spacing={2}>
                        {parsedAttachments.map((item) => (
                            <Grid
                                item
                                xs={6}
                                key={item.img}
                                sx={{
                                    ".hover-view": {
                                        opacity: 0,
                                        pointerEvents: "none",
                                        transition: "opacity 0.3s",
                                    },
                                    "&:hover": {
                                        ".hover-view": {
                                            opacity: 1,
                                            pointerEvents: "auto",
                                        },
                                    },
                                }}
                            >
                                <Badge
                                    badgeContent={<CircularProgress color={"inherit"} size={12} thickness={4} />}
                                    sx={{
                                        ".MuiBadge-badge": {
                                            width: 20,
                                            height: 20,
                                            px: 0,
                                            color: "white",
                                            backgroundColor: theme.palette.greySecondary[500],
                                        },
                                        width: "170px",
                                    }}
                                    invisible={!isUploading || getFileUploadStatus(item) === "COMPLETE"}
                                >
                                    <Tooltip title={item.name} followCursor>
                                        {item.type === "image" ? (
                                            <Box sx={{ aspectRatio: 1 }}>
                                                <img
                                                    srcSet={item.url}
                                                    src={item.url}
                                                    alt={item.name}
                                                    loading="lazy"
                                                    style={{
                                                        width: `170px`,
                                                        height: `170px`,
                                                        objectFit: `cover`,
                                                        borderRadius: `4px`,
                                                        overflow: "hidden",
                                                    }}
                                                />
                                            </Box>
                                        ) : (
                                            <Box
                                                width={1}
                                                height={72}
                                                pl={1.25}
                                                pt={1.75}
                                                pr={1.5}
                                                pb={2}
                                                border={1}
                                                borderColor={"divider"}
                                                bgcolor={alpha(theme.palette.greySecondary[50], 0.5)}
                                                borderRadius={`4px`}
                                            >
                                                <Grid container gap={0.5} flexWrap={"nowrap"}>
                                                    <Grid item>
                                                        <IconFile
                                                            size={16}
                                                            htmlColor={theme.palette.greySecondary[500]}
                                                        />
                                                    </Grid>
                                                    <Typography
                                                        variant={"body1_500"}
                                                        sx={{
                                                            display: `-webkit-box`,
                                                            WebkitBoxOrient: `vertical`,
                                                            overflow: `hidden`,
                                                            WebkitLineClamp: 2,
                                                        }}
                                                    >
                                                        {getEllipsisText(item.name)}
                                                    </Typography>
                                                </Grid>
                                            </Box>
                                        )}
                                    </Tooltip>
                                    <Grid
                                        className={"hover-view"}
                                        container
                                        display={"inline-flex"}
                                        width={"auto"}
                                        gap={0.75}
                                        px={0.75}
                                        py={0.5}
                                        bgcolor={"greySecondary.main"}
                                        color={"greySecondary.contrastText"}
                                        borderRadius={`2px`}
                                        boxShadow={`0px 2px 4px 0px ${alpha(theme.palette.common.black, 0.16)}`}
                                        position={"absolute"}
                                        right={-10}
                                        top={-10}
                                        hidden={isUploading}
                                    >
                                        <Tooltip title={t("common::label::Download")}>
                                            <IconButton
                                                color={"inherit"}
                                                sx={{ width: 20, height: 20 }}
                                                onClick={() => onHandleClickDownload(item)}
                                            >
                                                <IconDownload size={16} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={t("common::label::Delete")}>
                                            <IconButton
                                                color={"inherit"}
                                                sx={{ width: 20, height: 20 }}
                                                onClick={() => onClickDelete(commentId, item)}
                                                hidden={!canDelete}
                                            >
                                                <IconDelete size={16} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Badge>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Collapse>
        </Stack>
    );
};

export default React.memo(CommentAttachment);
