import { MEDIA_TYPES } from "@constants";
import { getMediaTypeFromExtension } from "../../../lib/mime/mime";
import {
    IconCaption,
    IconVideo,
    IconAudio,
    IconFile,
    IconImage,
    IconZip,
    ImageFolderSmallOn,
    ImageFolderSmallOff,
    IconFolder,
} from "@mzc-cloudplex/icons";

const ObjectIcon = (props) => {
    const { path, extendedMetadata, isFolder, isFolderDisabled, isShouldZip, onMouseEnter } = props;

    if (!path && !extendedMetadata) return <IconFile size={16} htmlColor={`#9fa3b2`} />;
    let mediaType = extendedMetadata;
    let pathExtension;

    if (path) {
        pathExtension = path.substring(path.lastIndexOf(".") + 1, path.length).toUpperCase();
        mediaType = getMediaTypeFromExtension(pathExtension);
    }

    if (isFolder && !isFolderDisabled)
        return (
            <i onMouseEnter={onMouseEnter} style={{ display: "inline-flex" }}>
                <ImageFolderSmallOn width={16} height={16} />
            </i>
        );

    if (isFolder && isFolderDisabled)
        return (
            <i onMouseEnter={onMouseEnter} style={{ display: "inline-flex" }}>
                <ImageFolderSmallOff width={16} height={16} />
            </i>
        );

    if (isShouldZip)
        return (
            <i onMouseEnter={onMouseEnter} style={{ display: "inline-flex" }}>
                <IconFolder size={16} htmlColor={`#9fa3b2`} />
            </i>
        );

    if (pathExtension === "MP4")
        return (
            <i onMouseEnter={onMouseEnter}>
                <IconVideo size={16} htmlColor={`#9fa3b2`} />
            </i>
        );

    if (mediaType === MEDIA_TYPES.VIDEO)
        return (
            <i onMouseEnter={onMouseEnter}>
                <IconVideo size={16} htmlColor={`#9fa3b2`} />
            </i>
        );

    if (mediaType === MEDIA_TYPES.AUDIO)
        return (
            <i onMouseEnter={onMouseEnter}>
                <IconAudio size={16} htmlColor={`#9fa3b2`} />
            </i>
        );

    if (mediaType === MEDIA_TYPES.IMAGE)
        return (
            <i onMouseEnter={onMouseEnter}>
                <IconImage size={16} htmlColor={`#9fa3b2`} />
            </i>
        );

    if (mediaType === MEDIA_TYPES.CAPTION)
        return (
            <i onMouseEnter={onMouseEnter}>
                <IconCaption size={16} htmlColor={`#9fa3b2`} />
            </i>
        ); //vtt

    if (pathExtension === "ZIP" || pathExtension === "GZ")
        return (
            <i onMouseEnter={onMouseEnter}>
                <IconZip size={16} htmlColor={`#9fa3b2`} />
            </i>
        );

    return (
        <i onMouseEnter={onMouseEnter}>
            <IconFile size={16} htmlColor={`#9fa3b2`} />
        </i>
    );
};

export default ObjectIcon;
