import "core-js/stable";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { getLocalLngList, getLocalLng, getLocalResource, getDefaultLanguage } from "../../modules/localization";

i18n.use(initReactI18next).init({
    resources: {},
    debug: false,

    fallbackLng: ["en", "ja", "ko"],

    nsSeparator: "::",
    keySeparator: "::",

    react: {
        useSuspense: false,
        transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p", "span"],
    },

    interpolation: {
        escapeValue: false, // react already safes from xss
    },
});

export function i18init() {
    let languages = null;

    let i18nLng = localStorage.getItem("i18nLng");

    if (i18nLng && localStorage.getItem("i18nResources")) {
        languages = getLocalLng(i18nLng, changeLocalizationResource);
    } else {
        const defaultLanguageCode = getDefaultLanguage();
        languages = getLocalLngList(defaultLanguageCode, changeLocalizationResource);
        // localStorage.setItem("i18nLng", defaultLanguageCode);
        localStorage.setItem("i18nResources", JSON.stringify(languages.locales));

        i18nLng = defaultLanguageCode;
        languages = getLocalResource(defaultLanguageCode);
    }

    i18n.addResourceBundle(i18nLng, "translation", languages, true, true);
}

export function changeLocalizationResource(value, resource) {
    i18n.addResourceBundle(value, "translation", resource, true, true);
    i18n.changeLanguage(value);
}

export default i18n;
