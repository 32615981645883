import { handleActions, createAction } from "redux-actions";

import config from "../config";
import queryString from "../cores/queryString";

import { axios, axiosWithoutHeaders } from "../cores/axiosWrapper";

import { sessionKeeper } from "../cores/sessionKeeper";
import fp, { T } from "lodash/fp";

function getUserAPI() {
    return axios.get(`${config.mz_pops.api_host}/userinfo/v1/me`);
}

function getStagesAPI(authorization) {
    return axiosWithoutHeaders.get(`${config.host.api}/v2/spaces?serviceName=media`, {
        headers: {
            authorization,
        },
    });
}

function getUserInStageAPI(apiEndpoint) {
    return axios.get(`${apiEndpoint}/v3/managements/me`);
}

function getProjectsInStage(apiEndpoint, queryParams) {
    if (queryParams) {
        return axios.get(`${apiEndpoint}/projects?${queryString.stringify(queryParams)}`);
    }
    return axios.get(`${apiEndpoint}/projects`);
}

//action type
const USER_V2_PENDING = "USER_V2_PENDING";
const USER_V2_FAILURE = "USER_V2_FAILURE";
const USER_V2_GET_USER_SUCCESS = "USER_V2_GET_USER_SUCCESS";
const USER_V2_PROJECTS_FAILURE = "USER_V2_PROJECTS_FAILURE";
const USER_V2_PROJECTS_PENDING = "USER_V2_PROJECTS_PENDING";
const USER_V2_GET_PROJECTS_SUCCESS = "USER_V2_GET_PROJECTS_SUCCESS";
const USER_V2_GET_USER_IN_STAGE_SUCCESS = "USER_V2_GET_USER_IN_STAGE_SUCCESS";
const USER_V2_SET_UNAUTHORIZE_USER = "USER_V2_SET_UNAUTHORIZE_USER";
const USER_V2_STAGES_PENDING = "USER_V2_STAGES_PENDING";
const USER_V2_STAGES_FAILURE = "USER_V2_STAGES_FAILURE";
const USER_V2_GET_STAGES_SUCCESS = "USER_V2_GET_STAGES_SUCCESS";
const USER_V2_GET_ALL_PROJECTS_PENDING = "USER_V2_GET_ALL_PROJECTS_PENDING";
const USER_V2_GET_ALL_PROJECTS_COMPLETE = "USER_V2_GET_ALL_PROJECTS_COMPLETE";

//reducer
const initialState = {
    pending: false,
    error: false,
    data: null,
    stages: {
        stagePending: false,
        allProjectsPending: false,
        error: false,
        data: null,
    },
};

export default handleActions(
    {
        [USER_V2_PENDING]: (state) => {
            return {
                ...state,
                pending: true,
                error: false,
            };
        },
        [USER_V2_FAILURE]: (state) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [USER_V2_GET_USER_SUCCESS]: (state, action) => {
            let { data } = action.payload;

            return {
                ...state,
                pending: false,
                error: false,
                data,
            };
        },
        [USER_V2_STAGES_PENDING]: (state) => {
            return {
                ...state,
                stages: {
                    ...state.stages,
                    stagePending: true,
                    error: false,
                },
            };
        },
        [USER_V2_STAGES_FAILURE]: (state) => {
            return {
                ...state,
                stages: {
                    ...state.stages,
                    stagePending: false,
                    error: true,
                },
            };
        },
        [USER_V2_GET_STAGES_SUCCESS]: (state, action) => {
            let { data } = action.payload;
            return {
                ...state,
                stages: {
                    ...state.stages,
                    stagePending: false,
                    error: false,
                    data,
                },
            };
        },
        [USER_V2_PROJECTS_PENDING]: (state, action) => {
            const result = {
                ...state,
                stages: {
                    ...state.stages,
                    data: state.stages.data.map((stage) => {
                        if (stage.id === action.stageId) {
                            return {
                                ...stage,
                                pending: true,
                                error: null,
                            };
                        }
                        return {
                            ...stage,
                        };
                    }),
                },
            };

            return result;
        },
        [USER_V2_PROJECTS_FAILURE]: (state, action) => {
            const { error, isLastStage } = action.payload;

            const result = {
                ...state,
                stages: {
                    ...state.stages,
                    data:
                        state.stages.data &&
                        state.stages.data.map((stage) => {
                            if (stage.id === action.stageId) {
                                return {
                                    ...stage,
                                    pending: false,
                                    error: error,
                                    checked: true,
                                };
                            }
                            return {
                                ...stage,
                            };
                        }),
                },
            };

            return result;
        },
        [USER_V2_GET_PROJECTS_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            const stages =
                state.stages.data &&
                state.stages.data.map((stage) => {
                    if (stage.id === action.stageId) {
                        return {
                            ...stage,
                            pending: false,
                            error: null,
                            projects: data.results,
                            isAccessible: data.results && data.results.length > 0,
                            checked: true,
                        };
                    }
                    return {
                        ...stage,
                    };
                });
            const result = {
                ...state,
                stages: {
                    ...state.stages,
                    data: stages,
                },
            };
            return result;
        },
        [USER_V2_GET_USER_IN_STAGE_SUCCESS]: (state, action) => {
            let { data } = action.payload;
            const result = {
                ...state,
                stages: {
                    ...state.stages,
                    data:
                        state.stages.data &&
                        state.stages.data.map((stage) => {
                            if (stage.id === action.stageId) {
                                return {
                                    ...stage,
                                    user: {
                                        ...data,
                                    },
                                    pending: false,
                                    error: null,
                                    isOwner: data.isOwner,
                                };
                            }
                            return {
                                ...stage,
                            };
                        }),
                },
            };

            return result;
        },
        [USER_V2_SET_UNAUTHORIZE_USER]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: true,
                data: action.payload,
            };
        },
        [USER_V2_GET_ALL_PROJECTS_PENDING]: (state, action) => {
            return {
                ...state,
                stages: {
                    ...state.stages,
                    allProjectsPending: true,
                },
            };
        },
        [USER_V2_GET_ALL_PROJECTS_COMPLETE]: (state, action) => {
            return {
                ...state,
                stages: {
                    ...state.stages,
                    allProjectsPending: false,
                },
            };
        },
    },
    initialState,
);

export const getStagesProjects = () => (dispatch, getState) => {
    const { token } = getState();

    dispatch({ type: USER_V2_STAGES_PENDING });
    return getStagesAPI(token.value)
        .then(async (response) => {
            dispatch({ type: USER_V2_GET_ALL_PROJECTS_PENDING });
            dispatch({
                type: USER_V2_GET_STAGES_SUCCESS,
                payload: response,
            });
            const stages = response.data;

            const stagePromises = stages.map(async (stage) => {
                const stageId = stage.id;

                try {
                    const userResponse = await getUserInStageAPI(stage.endpoint);
                    dispatch({
                        type: USER_V2_GET_USER_IN_STAGE_SUCCESS,
                        payload: userResponse,
                        stageId,
                    });
                } catch (userError) {}

                await dispatch(getProjects(stage));
            });

            return Promise.all(stagePromises)
                .then(() => {
                    dispatch({ type: USER_V2_GET_ALL_PROJECTS_COMPLETE });
                })
                .catch((error) => {
                    // Handle error if needed
                });
        })
        .catch((error) => {
            dispatch({ type: USER_V2_GET_ALL_PROJECTS_COMPLETE });
            dispatch({
                type: USER_V2_STAGES_FAILURE,
                payload: error,
            });
        });
};

export const getProjects = (stageInfo) => async (dispatch, getState) => {
    const { stage } = getState();
    const stageId = stageInfo?.id ?? fp.get("id", stage);
    const stageEndpoint = stageInfo?.endpoint ?? fp.get("endpoint", stage);

    try {
        dispatch({
            type: USER_V2_PROJECTS_PENDING,
            stageId,
        });

        let projects = [];
        let totalCount = 0;

        do {
            const projectsResponse = await getProjectsInStage(stageEndpoint, {
                isActive: true,
                offset: projects.length,
            });
            projects = projects.concat(projectsResponse.data.results);
            totalCount = projectsResponse.data.totalCount;
        } while (projects.length < totalCount);

        dispatch({
            type: USER_V2_GET_PROJECTS_SUCCESS,
            payload: projects,
            stageId,
        });
    } catch (projectsError) {
        dispatch({
            type: USER_V2_PROJECTS_FAILURE,
            payload: projectsError,
            stageId,
        });
    }
};

function parseJwt(token) {
    try {
        var base64Url = token.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = Buffer.from(base64, "base64").toString();
        return JSON.parse(jsonPayload);
    } catch (error) {
        // ignored
        console.error(error);
    }
    return null;
}

export const getUser = () => async (dispatch, getState) => {
    const { token, stage } = getState();
    if (!token.value) {
        return;
    }
    dispatch({ type: USER_V2_PENDING });
    const decodedJwt = parseJwt(token.value);
    let orgAlias;
    if (decodedJwt) {
        if (decodedJwt.orgAlias) {
            orgAlias = decodedJwt.orgAlias;
        }
    }
    return getUserAPI()
        .then((response) => {
            const data = response.data;
            const name = data.name || `${data.firstName} ${data.lastName}`;
            const user = {
                ...data,
                name,
            };
            if (orgAlias) {
                user.organization = {
                    alias: orgAlias,
                };
            }
            dispatch({
                type: USER_GET_USER_SUCCESS,
                payload: {
                    data: user,
                },
            });
        })
        .catch((error) => {
            dispatch({
                type: USER_FAILURE,
                payload: error,
            });
        });
};
export const setAuthorizeUser = createAction(USER_V2_SET_UNAUTHORIZE_USER);
