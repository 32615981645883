import { DRIVE_FOLDER_SEARCH_TYPE } from "@constants";
import { fromPairs, reduce } from "lodash";
import { handleActions, createAction } from "redux-actions";
import axios from "axios";
import {
    createDriveFolderAPI,
    getDrivesListAPI,
    getFoldersListAPI,
    getDetailDriveFolderAPI,
    setFolderInfoAPI,
    deleteFolderAPI,
    moveFolderAPI,
} from "./apis/virtureFolder";

//action type
const GET_FOLDERS_LIST_PENDING = "GET_FOLDERS_LIST_PENDING";
const GET_FOLDERS_LIST_PARTIAL_PENDING = "GET_FOLDERS_LIST_PARTIAL_PENDING";
const GET_FOLDERS_LIST_PARTIAL_SUCCESS = "GET_FOLDERS_LIST_PARTIAL_SUCCESS";
const GET_FOLDERS_LIST_SUCCESS = "GET_FOLDERS_LIST_SUCCESS";
const GET_FOLDERS_LIST_FAILURE = "GET_FOLDERS_LIST_FAILURE";
const GET_FOLDER_TREE_VIEW_PENDING = "GET_FOLDER_TREE_VIEW_PENDING";
const GET_FOLDER_TREE_VIEW_PARTIAL_PENDING = "GET_FOLDER_TREE_VIEW_PARTIAL_PENDING";
const GET_FOLDER_TREE_VIEW_PARTIAL_SUCCESS = "GET_FOLDER_TREE_VIEW_PARTIAL_SUCCESS";
const GET_FOLDER_TREE_VIEW_SUCCESS = "GET_FOLDER_TREE_VIEW_SUCCESS";
const GET_FOLDER_TREE_VIEW_FAILURE = "GET_FOLDER_TREE_VIEW_FAILURE";
const GET_DETAIL_FOLDER_PENDING = "GET_DETAIL_FOLDER_PENDING";
const GET_DETAIL_FOLDER_SUCCESS = "GET_DETAIL_FOLDER_SUCCESS";
const GET_DETAIL_FOLDER_FAILURE = "GET_DETAIL_FOLDER_FAILURE";
const GET_DRIVE_LIST_SUCCESS = "GET_DRIVE_LIST_SUCCESS";
const FOLDERS_SEARCH_STATE = "FOLDERS_SEARCH_STATE";
const FOLDERS_UPDATE_STATE = "FOLDERS_UPDATE_STATE";
const UPDATE_SELECTED_FOLDER = "UPDATE_SELECTED_FOLDER";
const UPDATE_SELECTED_HISTORY = "UPDATE_SELECTED_HISTORY";
const RESET_SELECTED_FOLDER = "RESET_SELECTED_FOLDER";

//reducer
const initialState = {
    pending: false,
    error: false,
    folderList: {
        childrenPending: false,
        loadingPosition: null,
        pending: false,
        error: null,
        data: null,
        selectedFolder: { folder: null, history: [], index: -1 },
    },
    driveList: null,
    createFolder: {
        pending: false,
        error: null,
    },
    detailFolder: {
        pending: false,
        error: null,
    },
    moveFolder: {
        folderId: null,
        pending: false,
        error: null,
    },
    isSearch: false,
};

export default handleActions(
    {
        [GET_FOLDER_TREE_VIEW_PENDING]: (state) => {
            return {
                ...state,
                folderList: {
                    ...state.folderList,
                    pending: true,
                    error: null,
                },
            };
        },
        [GET_FOLDER_TREE_VIEW_PARTIAL_PENDING]: (state, action) => {
            return {
                ...state,
                folderList: { ...state.folderList, childrenPending: true },
            };
        },
        [GET_FOLDER_TREE_VIEW_PARTIAL_SUCCESS]: (state, action) => {
            return {
                ...state,
                folderList: { ...state.folderList, childrenPending: false, data: action.payload },
            };
        },
        [GET_FOLDER_TREE_VIEW_SUCCESS]: (state, action) => {
            return {
                ...state,
                folderList: { ...state.folderList, pending: false }, //partial에서 이미 pending false처리 됨
            };
        },
        [GET_FOLDER_TREE_VIEW_FAILURE]: (state, action) => {
            return {
                ...state,
                folderList: { ...state.folderList, pending: false, error: action.payload },
            };
        },
        [GET_DRIVE_LIST_SUCCESS]: (state, action) => {
            return {
                ...state,
                driveList: action.payload,
            };
        },
        [GET_FOLDERS_LIST_PENDING]: (state) => {
            return {
                ...state,
                folderList: { ...state.folderList, error: null },
            };
        },
        [GET_FOLDERS_LIST_PARTIAL_PENDING]: (state, action) => {
            return {
                ...state,
                folderList: { ...state.folderList, childrenPending: true, ...action.payload },
            };
        },
        [GET_FOLDERS_LIST_PARTIAL_SUCCESS]: (state, action) => {
            return {
                ...state,
                folderList: { ...state.folderList, childrenPending: false, data: action.payload },
            };
        },
        [GET_FOLDERS_LIST_SUCCESS]: (state, action) => {
            return {
                ...state,
                folderList: { ...state.folderList, pending: false },
            };
        },
        [GET_FOLDERS_LIST_FAILURE]: (state, action) => {
            return {
                ...state,
                folderList: { ...state.folderList, pending: false, error: action.payload },
            };
        },
        [FOLDERS_UPDATE_STATE]: (state, action) => {
            return {
                ...state,
                folderList: {
                    ...state.folderList,
                    data: { ...state.folderList.data, ...action.payload },
                },
            };
        },
        [UPDATE_SELECTED_FOLDER]: (state, action) => {
            const selectedFolder = state.folderList.selectedFolder;
            let newSelectedHistory = [...selectedFolder.history];
            let newSelectedIndex = selectedFolder.index;

            if (selectedFolder.index < selectedFolder.history.length) {
                //이전 히스토리 도중 새로운 폴더 탐색
                newSelectedHistory = selectedFolder.history.slice(0, newSelectedIndex + 1);
            }
            newSelectedHistory.push(action.payload);
            newSelectedIndex = newSelectedIndex + 1;

            return {
                ...state,
                folderList: {
                    ...state.folderList,
                    selectedFolder: {
                        folder: action.payload,
                        history: newSelectedHistory,
                        index: newSelectedIndex,
                    },
                },
            };
        },
        [RESET_SELECTED_FOLDER]: (state, action) => {
            return {
                ...state,
                folderList: { ...state.folderList, selectedFolder: { folder: null, history: [], index: -1 } },
            };
        },
        [UPDATE_SELECTED_HISTORY]: (state, action) => {
            return {
                ...state,
                folderList: {
                    ...state.folderList,
                    selectedFolder: {
                        ...state.folderList.selectedFolder,
                        folder: state.folderList.selectedFolder.history[
                            state.folderList.selectedFolder.index + action.payload
                        ],
                        index: state.folderList.selectedFolder.index + action.payload,
                    },
                },
            };
        },
        [GET_DETAIL_FOLDER_PENDING]: (state, action) => {
            return {
                ...state,
                detailFolder: {
                    pending: true,
                    error: null,
                },
            };
        },
        [GET_DETAIL_FOLDER_SUCCESS]: (state, action) => {
            return {
                ...state,
                detailFolder: {
                    pending: false,
                    error: null,
                },
            };
        },
        [GET_DETAIL_FOLDER_FAILURE]: (state, action) => {
            return {
                ...state,
                detailFolder: {
                    pending: false,
                    error: action.payload,
                },
            };
        },
        [FOLDERS_SEARCH_STATE]: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
    },
    initialState,
);

export const updateFolderState = createAction(FOLDERS_UPDATE_STATE);
export const updateFolderSearchState = createAction(FOLDERS_SEARCH_STATE);
export const updateSelectedFolder = createAction(UPDATE_SELECTED_FOLDER);
export const updateSelectedHistory = createAction(UPDATE_SELECTED_HISTORY);
export const resetSelectedFolder = createAction(RESET_SELECTED_FOLDER);

// prevFolderMap : nextToken이 존재할 경우 폴더를 나눠서 처리할 때 이전에 이미 처리한 폴더맵
export const createFolderTree = (folderList, searchType, searchFolder, prevFolderMap) => {
    try {
        let folderMap = prevFolderMap ?? {};

        folderList.folders.map((folder, index) => {
            //if (folder.name === "test_folder_3") console.trace("found");
            const parentId = folder.parent.id;
            const folderInfo = {
                isRoot: false,
                children: [],
                name: folder.name,
                type: "folder",
                isFold: true, //서치 조건
                id: folder.id,
                driveId: folderList.driveId,
                depth: folderMap[parentId] ? folderMap[parentId]?.depth + 1 : 0,
                parent: parentId ? parentId : folderList.id,
                pending: false,
            };
            folderMap[folder.id] = folderInfo;
            if (parentId && folderMap[parentId]) {
                folderMap[parentId].children = Array.from(new Set(folderMap[parentId].children.concat([folder.id])));
            }
        });

        if (searchType === "search") {
            const foundFolderList =
                [folderMap[searchFolder]] ?? Object.values(folderMap).filter((obj) => obj.name.includes(searchFolder));
            foundFolderList.forEach((foundFolder) => {
                if (foundFolder?.depth > 0) {
                    let parents = [foundFolder.parent]; //루트폴더

                    while (parents.length > 0) {
                        const id = parents.shift();

                        folderMap[id].isFold = false;
                        if (folderMap[id].depth > 0) parents.push(folderMap[id].parent);
                    }
                }
            });
        }

        return folderMap;
    } catch (error) {
        console.log(error);
    }
};

export const initFolderData =
    ({ headerParams, domain }) =>
    (dispatch, getState) => {
        const { stage } = getState();

        dispatch({ type: GET_FOLDER_TREE_VIEW_PENDING });

        return new Promise((resolve, reject) => {
            getDrivesListAPI(stage.endpoint, { headerParams })
                .then(async (driveResponse) => {
                    dispatch({ type: GET_DRIVE_LIST_SUCCESS, payload: driveResponse.data.results });

                    //드라이브 별로 폴더 목록 가져오기
                    const entireFolderMap = await driveResponse.data?.results
                        ?.filter((drive) => drive.resourceType?.type === domain.toUpperCase())
                        ?.reduce(async (prevFolderMap, drive) => {
                            try {
                                let folderMap = {
                                    //이전까지의 folderMap에 현재 드라이브 정보 추가
                                    ...prevFolderMap,
                                    [drive.rootFolder.id]: {
                                        isRoot: true,
                                        children: [],
                                        name: drive.name,
                                        type: "drive",
                                        isFold: false,
                                        depth: 0,
                                        id: drive.rootFolder.id,
                                        driveId: drive.id,
                                        parent: null,
                                        pending: false,
                                    },
                                };

                                const driveId = drive.id;
                                const queryParameters = {
                                    parentId: drive.rootFolder.id,
                                    searchType: "DIRECT_CHILDREN",
                                };
                                let driveInfo = {
                                    rootFolder: drive.rootFolder,
                                    driveId: driveId,
                                    folders: [],
                                };
                                let nextToken;

                                dispatch({ type: GET_FOLDERS_LIST_PENDING });
                                do {
                                    const folderList = await getFoldersListAPI(stage.endpoint, {
                                        driveId,
                                        queryParameters,
                                        headerParams,
                                        nextToken,
                                    });
                                    driveInfo.folders = [...driveInfo.folders, ...folderList.data.results];
                                    nextToken = folderList.data.nextToken;

                                    folderMap = createFolderTree(driveInfo, "init", null, folderMap);
                                    dispatch({ type: GET_FOLDER_TREE_VIEW_PARTIAL_SUCCESS, payload: folderMap });
                                } while (nextToken !== undefined);
                                dispatch({ type: GET_FOLDERS_LIST_SUCCESS });

                                return folderMap;
                            } catch (error) {
                                throw new Error(error);
                            }
                        }, {});
                    dispatch({ type: GET_FOLDER_TREE_VIEW_SUCCESS });
                    resolve(
                        driveResponse.data.results.length > 0
                            ? entireFolderMap[driveResponse.data.results[0].rootFolder.id]
                            : null,
                    ); //확인
                })
                .catch((error) => {
                    console.log(error);
                    dispatch({ type: GET_FOLDER_TREE_VIEW_FAILURE, payload: error });
                    reject(error);
                });
        });
    };

export const getFoldersList =
    ({ driveId, queryParameters, headerParams, abortToken = undefined }) =>
    (dispatch, getState) => {
        const { stage, folderTreeView } = getState();
        const { parentId } = queryParameters;

        //클릭한 폴더를 folderMap에서 가져와서 pending이면 isFold만 바꿔서 return
        //만약 아니면 클릭한 폴더의 pending을 true로 바꾸고 아래 과정 진행
        //끝나고 pending false로 원상복구

        let folderMap = Object.fromEntries(
            //기존 folderTreeView.folderList.data 그대로 사용시 삭제한 폴더의 정보가 사라지지 않음
            Object.entries(folderTreeView.folderList.data).filter(([key, value]) => value.parent !== parentId),
        );

        dispatch({ type: GET_FOLDERS_LIST_PENDING });
        return new Promise(async (resolve, reject) => {
            try {
                let parentFolder = folderMap[parentId];
                parentFolder.children = [];
                parentFolder.pending = true;

                let folders = [];
                let nextToken;
                let loadingPos = parentId;
                let firstAPICall = true;
                do {
                    dispatch({ type: GET_FOLDERS_LIST_PARTIAL_PENDING, payload: { loadingPosition: loadingPos } });
                    try {
                        const response = await getFoldersListAPI(
                            stage.endpoint,
                            {
                                driveId,
                                queryParameters,
                                nextToken,
                                headerParams,
                            },
                            abortToken,
                        );

                        folders = response.data.results; //init은 맵을 새로 생성하지만 getList는 기존의 맵에서 속성만 추가해주는거라 folders를 누적할 필요는 없을듯
                        nextToken = response.data.nextToken;
                    } catch (error) {
                        if (axios.isCancel(error)) {
                            console.log("aborted");
                            nextToken = undefined;
                        } else {
                            throw new Error(error);
                        }
                    }
                    const allFolderIds = folders.map((folder) => {
                        folderMap[folder.id] = {
                            isRoot: false,
                            children: [],
                            name: folder.name,
                            type: "folder",
                            isFold: true,
                            id: folder.id,
                            driveId: driveId,
                            depth: folder.parent.id ? parentFolder.depth + 1 : 0,
                            parent: parentId,
                        };

                        return folder.id;
                    });

                    folderMap[parentId].children = folderMap[parentId].children.concat(allFolderIds);

                    if (nextToken) loadingPos = folders.at(-1).id;
                    parentFolder.isFold = firstAPICall
                        ? false
                        : getState().folderTreeView.folderList.data[parentId].isFold;
                    parentFolder.isEmpty = folders.length === 0;
                    parentFolder.pending = nextToken !== undefined;

                    dispatch({ type: GET_FOLDERS_LIST_PARTIAL_SUCCESS, payload: folderMap });
                    firstAPICall = false;
                } while (nextToken !== undefined);
                dispatch({ type: GET_FOLDERS_LIST_SUCCESS });
                resolve();
            } catch (error) {
                console.log(error);
                dispatch({ type: GET_FOLDERS_LIST_FAILURE, payload: error });
            }
        });
    };

export const searchFolderData =
    ({ headerParams, queryParameters, abortToken = undefined, domain }) =>
    (dispatch, getState) => {
        const { stage } = getState();

        dispatch({ type: GET_FOLDER_TREE_VIEW_PENDING });

        return new Promise((resolve, reject) => {
            getDrivesListAPI(stage.endpoint, { headerParams })
                .then(async (driveResponse) => {
                    dispatch({ type: GET_DRIVE_LIST_SUCCESS, payload: driveResponse.data.results });

                    //드라이브 별로 폴더 목록 가져오기
                    const entireFolderMap = await driveResponse.data?.results
                        ?.filter((drive) => drive.resourceType?.type === domain.toUpperCase())
                        ?.reduce(async (prevFolderMap, drive) => {
                            try {
                                let folderMap = {
                                    //이전까지의 folderMap에 현재 드라이브 정보 추가
                                    ...prevFolderMap,
                                    [drive.rootFolder.id]: {
                                        isRoot: true,
                                        children: [],
                                        name: drive.name,
                                        type: "drive",
                                        isFold: false,
                                        depth: 0,
                                        id: drive.rootFolder.id,
                                        driveId: drive.id,
                                        parent: null,
                                    },
                                };
                                const driveId = drive.id;
                                let nextToken;
                                let driveInfo = {
                                    driveId: drive.id,
                                    rootFolder: drive.rootFolder,
                                    folders: [],
                                };

                                do {
                                    try {
                                        const folderList = await getFoldersListAPI(
                                            stage.endpoint,
                                            {
                                                driveId,
                                                queryParameters,
                                                headerParams,
                                                nextToken,
                                            },
                                            abortToken,
                                        );
                                        driveInfo.folders = [
                                            ...driveInfo.folders,
                                            ...folderList.data.results.filter((folder) => folder.depth > 0),
                                        ];
                                        nextToken = folderList.data.nextToken;
                                    } catch (error) {
                                        console.error("abort");
                                        return folderMap;
                                    }

                                    folderMap = createFolderTree(
                                        driveInfo,
                                        "search",
                                        queryParameters.name ?? queryParameters.id,
                                        folderMap,
                                    );
                                    dispatch({ type: GET_FOLDER_TREE_VIEW_PARTIAL_SUCCESS, payload: folderMap });
                                } while (nextToken !== undefined);

                                return folderMap;
                            } catch (error) {
                                throw new Error(error);
                            }
                        }, {});

                    dispatch({ type: GET_FOLDER_TREE_VIEW_SUCCESS });
                    resolve(
                        queryParameters.searchType === DRIVE_FOLDER_SEARCH_TYPE.PARENT_FOLDERS
                            ? entireFolderMap[queryParameters.id]
                            : undefined,
                    );
                })
                .catch((error) => {
                    console.log(error);
                    dispatch({ type: GET_FOLDER_TREE_VIEW_FAILURE, payload: error });
                });
        });
    };

export const getDetailDriveFolder =
    ({ driveId, folderId }) =>
    (dispatch, getState) => {
        const { stage, project } = getState();

        dispatch({ type: GET_DETAIL_FOLDER_PENDING });
        return new Promise((resolve, reject) => {
            getDetailDriveFolderAPI(stage.endpoint, { driveId, folderId, projectId: project.id })
                .then((response) => {
                    dispatch({ type: GET_DETAIL_FOLDER_SUCCESS, payload: response.data });
                    resolve(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    dispatch({ type: GET_DETAIL_FOLDER_FAILURE, payload: error });
                    reject(error);
                });
        });
    };
