import React, { useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { Chip, Grid, ListItem, Typography, TextField, Checkbox, Popper, Button, Box } from "@mzc-pdc/ui";
import { Autocomplete as MuiAutocomplete } from "@mzc-pdc/ui";
import { IconAvata, IconErrorCircle, IconWarningTriangle } from "@mzc-cloudplex/icons";
import useIntersection from "@hooks/useIntersection";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import theme from "@styles/theme";
import { RandomColoredAvatar } from "@components_v2/avatar/random-colored-avatar";
import { StyledAutocompletePopper } from "@features/chip-popper-components/chip-types-input/project-menu.styled";

export const AutocompleteMultipleProjects = ({
    value,
    disabled,
    onFetchData,
    onInputChange,
    placeholder,
    options,
    error,
    helperText,
    sx,
    handleClose,
    onChange,
}) => {
    const { setTarget } = useIntersection({
        onIntersect: onFetchData,
    });

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <MuiAutocomplete
                clearOnBlur
                multiple
                value={value}
                limitTags={2}
                onInputChange={(e, value, reason) => {
                    onInputChange(reason);
                }}
                options={options}
                size={`small`}
                disabled={disabled}
                getOptionLabel={(option) => option.name}
                onChange={(e, newValue) => {
                    if (newValue && onChange) onChange(newValue);
                }}
                disableCloseOnSelect
                renderTags={(v, getTagProps) => {
                    return value?.map((option, index) => {
                        return (
                            <Chip
                                size={"small"}
                                label={option.name}
                                {...getTagProps({
                                    index,
                                })}
                            />
                        );
                    });
                }}
                renderOption={(props, option, { selected }) => {
                    return (
                        <ListItem {...props} key={option.id} ref={onFetchData && option.lastOption ? setTarget : null}>
                            <Grid
                                container
                                flexDirection={"row"}
                                flexWrap={"nowrap"}
                                alignItems={"center"}
                                gap={1}
                                py={`1px`}
                            >
                                <Checkbox style={{ marginRight: 8 }} checked={selected} color={"greySecondary"} />
                                <Typography
                                    variant={"body1"}
                                    color={theme.palette.secondary[800]}
                                    sx={{ wordWrap: `break-word`, wordBreak: `keep-all` }}
                                >
                                    {`${option.name} (${option.id})`}
                                </Typography>
                            </Grid>
                        </ListItem>
                    );
                }}
                renderInput={(params) => {
                    const getEndAdornment = () => {
                        if (error === "warning") return <IconWarningTriangle color={"warning"} size={16} />;
                        else if (error === "error") return <IconErrorCircle color={`error`} size={16} />;

                        return undefined;
                    };
                    return (
                        <>
                            <TextField
                                {...params}
                                placeholder={placeholder}
                                error={error}
                                helperText={
                                    helperText && (
                                        <Grid container gap={1} alignItems={"center"}>
                                            {getEndAdornment()}
                                            {helperText}
                                        </Grid>
                                    )
                                }
                                sx={{
                                    ".MuiInputBase-root": {
                                        paddingTop: `3px !important`,
                                        minHeight: `40px`,
                                    },
                                    ".MuiInputBase-input": {
                                        border: "none",
                                    },
                                    "&::placeholder": {
                                        color: theme.palette.secondary[400],
                                        fontWeight: "normal",
                                    },
                                    ...sx,
                                }}
                            />
                        </>
                    );
                }}
            />
        </ClickAwayListener>
    );
};
