import {handleActions, createAction} from 'redux-actions';
import {
    axios,
    axiosWithoutHeaders
} from "../cores/axiosWrapper";
import queryString from "../cores/queryString";

function getAuditsAPI (stageId, apiEndpoint, projectId, params) {
    const headers = {
        stageId
    };

    if(projectId) {
        headers.projectId = projectId;
    }
    if(params.exclusiveStartKey) {
        params.exclusiveStartKey = encodeURIComponent(JSON.stringify(params.exclusiveStartKey));
    }    
    return axios.get(`${apiEndpoint}/audits?${queryString.stringify(params)}`, {
        headers
    });
}

function getAuditAPI (stageId, apiEndpoint, projectId, id, district) {
    const headers = {
        stageId
    };

    if(projectId) {
        headers.projectId = projectId;
    }
    return axios.get(`${apiEndpoint}/audits/${id}?district=${district}`, {
        headers
    });
}

const AUDITS_PENDING = 'AUDITS_PENDING';
const AUDITS_FAILURE = 'AUDITS_FAILURE';
const GET_AUDITS_FOR_LIST_SUCCESS = 'GET_AUDITS_FOR_LIST_SUCCESS';
const GET_AUDITS_FOR_LIST_SUCCESS_TO_APPEND = 'GET_AUDITS_FOR_LIST_SUCCESS_TO_APPEND';
const GET_AUDITS_FOR_DETAIL_SUCCESS = 'GET_AUDITS_FOR_DETAIL_SUCCESS';

const initialState = {
    pending: false,
    error: false,
    list: [],
    exclusiveStartKey : '',
    item : null
};

export default handleActions({
    [AUDITS_PENDING]: (state) => {
        return {
            ...state,
            pending: true,
            error: false
        }
    },
    [AUDITS_FAILURE]: (state) => {
        return {
            ...state,
            pending: false,
            error: true
        }
    },
    [GET_AUDITS_FOR_LIST_SUCCESS]: (state, action) => {
        let {data} = action.payload;

        return {
            ...state,
            pending: false,
            error: false,
            list: data.auditLogs,
            exclusiveStartKey : data.exclusiveStartKey || ''
        };
    },
    [GET_AUDITS_FOR_DETAIL_SUCCESS]: (state, action) => {
        let {data} = action.payload;

        return {
            ...state,
            pending: false,
            error: false,
            item: data
        };
    },
    [GET_AUDITS_FOR_LIST_SUCCESS_TO_APPEND]:(state, action) => {
        let {data} = action.payload;

        return {
            ...state,
            pending: false,
            error: false,
            list: state.list.concat(data.auditLogs),
            exclusiveStartKey : data.exclusiveStartKey
        };
    }
}, initialState);

export const getAudits = ({projectId, params}) => (dispatch, getState) => {
    let {
        stage
    } = getState();

    if(params.username === '') {
        return;
    }
    dispatch({type: AUDITS_PENDING});

    return getAuditsAPI(stage.id, stage.endpoint, projectId, params).then(
        (response) => {
            if(params.exclusiveStartKey) {
                dispatch({
                    type: GET_AUDITS_FOR_LIST_SUCCESS_TO_APPEND,
                    payload: response
                });
            } else {
                dispatch({
                    type: GET_AUDITS_FOR_LIST_SUCCESS,
                    payload: response
                });
            }
        }
    ).catch(error => {
        dispatch({
            type: AUDITS_FAILURE
        });
    })
};
export const getAudit = ({projectId, id, district}) => (dispatch, getState) => {
    let {
        stage
    } = getState();

    dispatch({type: AUDITS_PENDING});

    return getAuditAPI(stage.id, stage.endpoint, projectId, id, district).then(
        (response) => {
            dispatch({
                type: GET_AUDITS_FOR_DETAIL_SUCCESS,
                payload: response
            });
        }
    ).catch(error => {
        dispatch({
            type: AUDITS_FAILURE
        });
    })
}