import { axios } from "../../cores/axiosWrapper";

export const getCustomContentsAPI = async (stageId, apiEndpoint, projectId, params) => {
    return axios.get(`${apiEndpoint}/v3/contents`, {
        params,
        headers: {
            projectId,
        },
    });
};

export const createCustomContentAPI = async (apiEndpoint, projectId, data) => {
    return axios.post(`${apiEndpoint}/v3/contents`, data, {
        headers: {
            projectId,
        },
    });
};

export const deleteCustomContentAPI = async (apiEndpoint, projectId, contentId) => {
    return axios.delete(`${apiEndpoint}/v3/contents/${contentId}`, {
        headers: {
            projectId,
        },
    });
};
