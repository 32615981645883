import { Box, Button } from "@mzc-pdc/ui";
import React from "react";

const CircledIcon = ({ size, color, padding = 0, children }) => {
    return (
        <Box
            sx={{
                display: `inline-flex`,
                alignItems: `center`,
                justifyContent: `center`,
                width: size,
                height: size,
                padding: padding,
                borderRadius: 20,
                backgroundColor: color,
            }}
        >
            {children}
        </Box>
    );
};

const CircledButtonIcon = ({ size, color, children, onClick = () => {} }) => {
    return (
        <button>
            <Box
                sx={{
                    display: `inline-flex`,
                    alignItems: `center`,
                    justifyContent: `center`,
                    width: size,
                    height: size,
                    borderRadius: 20,
                    backgroundColor: color,
                }}
                onClick={onClick}
            >
                {children}
            </Box>
        </button>
    );
};
export { CircledIcon, CircledButtonIcon };
