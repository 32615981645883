import { axios } from "@cores/axiosWrapper";

export const getMetadataExportDetailAPI = (apiEndpoint, projectId, id) => {
    let headers = {};
    if (projectId) headers.projectId = projectId;

    return axios.get(`${apiEndpoint}/exported-metadata/${id}`, {
        headers,
    });
};

export const updateMetadataExportAPI = (apiEndpoint, projectId, id, data) => {
    let headers = {};
    if (projectId) headers.projectId = projectId;

    return axios.patch(`${apiEndpoint}/exported-metadata/${id}`, data, {
        headers,
    });
};

export const createMetadataExportAPI = (apiEndpoint, projectId, data) => {
    let headers = {};
    if (projectId) headers.projectId = projectId;

    return axios.post(`${apiEndpoint}/exported-metadata`, data, {
        headers,
    });
};

export const deleteMetadataExportAPI = (apiEndpoint, projectId, id) => {
    let headers = {};
    if (projectId) headers.projectId = projectId;

    return axios.delete(`${apiEndpoint}/exported-metadata/${id}`, {
        headers,
    });
};
