import {handleActions, createAction} from 'redux-actions';
import fp from 'lodash/fp';
import {axios} from "../cores/axiosWrapper";

import {
    getAssetElementVersionsAPI
} from "./apis/asset";


//action type
const ASSET_ELEMENT_VERSIONS_RESET = 'ASSET_ELEMENT_VERSIONS_RESET';
const ASSET_ELEMENT_VERSIONS_PENDING = 'IMAGE_VIEWER_PENDING';
const ASSET_ELEMENT_VERSIONS_GET_SUCCESS = 'ASSET_ELEMENT_VERSIONS_GET_SUCCESS';
const ASSET_ELEMENT_VERSIONS_GET_FAILURE = 'ASSET_ELEMENT_VERSIONS_GET_FAILURE';

const initialState = {
    pending: false,
    error: false,
    versions: [],
    nextToken: undefined
};

const cloneInitialState = fp.cloneDeep(initialState);

export default handleActions({
    [ASSET_ELEMENT_VERSIONS_RESET] : () => {
        console.log("ASSET_ELEMENT_VERSIONS_RESET boundedAssetElementVersions.reset();")
        return cloneInitialState;
    },
    [ASSET_ELEMENT_VERSIONS_PENDING]: (state) => {
        return {
            ...state,
            pending : true,
        };
    },
    [ASSET_ELEMENT_VERSIONS_GET_SUCCESS]: (state, action) => {
        console.log("ASSET_ELEMENT_VERSIONS_GET_SUCCESS action:", action);

        const { data } = action.payload;

        return {
            ...state,
            pending : false,
            error : false,
            versions: data.versions,
            nextToken: data.nextToken
        };
    },
    [ASSET_ELEMENT_VERSIONS_GET_FAILURE]: (state) => {
        return {
            ...state,
            pending : false,
            error : true
        };
    },
}, initialState)

export const reset = createAction(ASSET_ELEMENT_VERSIONS_RESET);

export const getListAsync = (assetId, elementId, params) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    dispatch({type: ASSET_ELEMENT_VERSIONS_PENDING});

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;
    // dispatch({type: ASSET_DETAIL_IS_CHANGING_DATA});

    return new Promise((resolve, reject) => {
        getAssetElementVersionsAPI(stageId, apiEndpoint, projectId, assetId, elementId, params)
            .then((response) => {
                dispatch({
                    type: ASSET_ELEMENT_VERSIONS_GET_SUCCESS,
                    payload: response
                });
                resolve(response);
            })
            .catch(error => {
                dispatch({
                    type: ASSET_ELEMENT_VERSIONS_GET_FAILURE,
                    payload: error
                });
                reject(error);
            });
    });
};