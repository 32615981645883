import { axios } from "../../cores/axiosWrapper";

import queryString from "../../cores/queryString";

const MetadataFieldItemSetsApi = {
    createAsync: async function (stageId, apiEndpoint, data) {
        return axios.post(`${apiEndpoint}/v2/item-sets`, data, {
            headers: {
                stageId,
            },
        });
    },

    getListAsync: async function (stageId, apiEndpoint, projectId, query) {
        const queries = {
            limit: query.limit || 1000,
        };

        if (query.name) queries.name = query.name;

        if (query.nextToken) queries.nextToken = query.nextToken;

        const headers = { stageId };
        if (projectId) headers.projectId = projectId;

        return axios.get(`${apiEndpoint}/v2/item-sets?${queryString.stringify(queries)}`, {
            headers,
        });

        // return new Promise(((resolve, reject) => {
        //     const data = {
        //         "results": [
        //           {
        //             "id": 1,
        //             "name": "Artist"
        //           },
        //           {
        //             "id": 2,
        //             "name": "Platform"
        //           },
        //           {
        //             "id": 3,
        //             "name": "Genre"
        //           }
        //         ],
        //         "nextToken": null,
        //         "totalCount": 3
        //       }

        //     resolve(data);
        // }))
    },

    updateAsync: async function (stageId, apiEndpoint, id, data) {
        return axios.put(`${apiEndpoint}/v2/item-sets/${id}`, data, {
            headers: {
                stageId,
            },
        });
    },
    deleteAsync: async function (stageId, apiEndpoint, id) {
        return axios.delete(`${apiEndpoint}/v2/item-sets/${id}`, {
            headers: {
                stageId,
            },
        });
    },
};

export default MetadataFieldItemSetsApi;
