/* eslint-disable */
function spliceByByteLength(s, limitByte, b, i, c) {
    if (!s) return "";
    let result = "";
    for (b = i = 0; (c = s.charCodeAt(i++)); b += c >> 11 ? 3 : c >> 7 ? 2 : 1) {
        if (b > limitByte) break;
        result = `${result}${s[i - 1]}`;
    }
    return result;
}

export default spliceByByteLength;
