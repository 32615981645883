import { LinkPopupIcon } from "@components/icons";
import PropTypes from "prop-types";

const ConsoleButton = ({ onClick, headerTheme, label }) => {
    return (
        <button
            type="button"
            className="btn btn-round btn-sm btn-header-switch"
            style={
                headerTheme
                    ? {
                          borderColor: headerTheme?.color?.textColor,
                          color: headerTheme?.color?.textColor,
                      }
                    : {}
            }
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (onClick) onClick();
            }}
        >
            <strong>{label}</strong>
            <LinkPopupIcon />
        </button>
    );
};

ConsoleButton.propTypes = {
    onClick: PropTypes.func,
    label: PropTypes.string,
    headerTheme: PropTypes.object,
};

ConsoleButton.defaultProps = {};

export default ConsoleButton;
