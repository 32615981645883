import { axios } from "@cores/axiosWrapper";

export const getRolesAPI = (apiEndpoint, projectId, params) => {
    const headers = projectId ? { projectId } : {};
    return axios.get(`${apiEndpoint}/role-managements/roles`, {
        headers,
        params,
    });
};

export const createRoleAPI = (apiEndpoint, projectId, data) => {
    const headers = projectId ? { projectId } : {};
    return axios.post(`${apiEndpoint}/role-managements/roles`, data, { headers });
};

export const getRoleAPI = (apiEndpoint, projectId, id) => {
    const headers = projectId ? { projectId } : {};
    return axios.get(`${apiEndpoint}/role-managements/roles/${id}`, { headers });
};

export const deleteRoleAPI = (apiEndpoint, projectId, id) => {
    const headers = projectId ? { projectId } : {};
    return axios.delete(`${apiEndpoint}/role-managements/roles/${id}`, { headers });
};

export const updateRoleAPI = (apiEndpoint, projectId, id, data) => {
    const headers = projectId ? { projectId } : {};
    return axios.patch(`${apiEndpoint}/role-managements/roles/${id}`, data, { headers });
};

export const updateRoleActionsAPI = (apiEndpoint, projectId, id, data) => {
    const headers = projectId ? { projectId } : {};
    return axios.post(`${apiEndpoint}/role-managements/roles/${id}/actions`, data, { headers });
};

export const getActionGroupsAPI = (apiEndpoint, projectId, params) => {
    const headers = projectId ? { projectId } : {};
    return axios.get(`${apiEndpoint}/role-managements/action-groups`, {
        headers,
        params,
    });
};

export const getMyProjectPermissionsAPI = (apiEndpoint, projectId) => {
    return axios.get(`${apiEndpoint}/role-managements/my-project-permissions`, { headers: { projectId } });
};

export const createRoleEntitiesAPI = (apiEndpoint, projectId, id, data) => {
    const headers = projectId ? { projectId } : {};
    return axios.post(`${apiEndpoint}/role-managements/roles/${id}/entities`, data, {
        headers,
    });
};

export const deleteRoleEntitiesAPI = (apiEndpoint, projectId, id, entityId) => {
    const headers = projectId ? { projectId } : {};
    return axios.delete(`${apiEndpoint}/role-managements/roles/${id}/entities/${entityId}`, {
        headers,
    });
};
