import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const PanelBodyInfoContents = (props) => {
    const { className, ...otherProps } = props;
    return <div {...otherProps} className={clsx("information-contents", className)} />;
};
PanelBodyInfoContents.propTypes = {
    className: PropTypes.string,
};

export default PanelBodyInfoContents;
