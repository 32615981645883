import React, { FC, useState, useCallback, useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StyledDatePicker } from "./datepicker.styled";
import { IconCalender } from "@mzc-cloudplex/icons";
import { TextField } from "@mzc-pdc/ui";
import dayjs from "dayjs";
export const BasicDatePicker = ({
    label,
    width,
    defaultValue,
    value,
    minDate,
    maxDate,
    sx,
    onError,
    helperText,
    onChangeValue,
}) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StyledDatePicker
                sx={sx}
                label={label && label}
                defaultValue={defaultValue ? dayjs(defaultValue) : undefined}
                value={value ? dayjs(value) : null}
                minDate={minDate ? dayjs(minDate) : undefined}
                maxDate={maxDate ? dayjs(maxDate) : undefined}
                width={width}
                format={"YYYY-MM-DD"}
                dateFormat={"YYYY-MM-DD"}
                mask={"____-__-__"}
                components={{
                    OpenPickerIcon: IconCalender,
                }}
                onChange={(newValue) => {
                    if (onChangeValue) onChangeValue(newValue);
                }}
                onError={(error) => {
                    if (onError) onError(error);
                }}
                PopperProps={{
                    placement: "bottom-end",
                    sx: {
                        marginRight: `-40px !important`,
                    },
                }}
                slotProps={{
                    textField: {
                        InputLabelProps: {
                            shrink: true,
                        },
                        helperText: helperText,
                    },
                }}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                placeholder: "YYYY-MM-DD",
                            }}
                        />
                    );
                }}
            />
        </LocalizationProvider>
    );
};
