import { handleActions, createAction } from "redux-actions";
import uniqId from "uniqid";
import getFilesInS3 from "@cores/getFilesInS3";
import {
    ASSET_TYPES,
    UPLOAD_FILE_LOCATION_TYPE,
    MEDIA_TYPES,
    INGEST_TYPE,
    COMMON_JOB_STATUS,
    UPLOAD_STATUS,
    UPLOAD_LOCATION,
} from "@constants";
import { axios } from "@cores/axiosWrapper";
import { getUrlInfo } from "@lib/mime/mime";
import compareVersions, {
    VERSION_CM_10084_CREATE_ASSETS_WITH_COLLECTIONS,
    VERSION_CM_7791_CREATE_ASSET_MULTIPART,
    VERSION_SAFARI_ENCODING_FOR_PRESIGN_AND_UPLOAD,
} from "@cores/version";
import { bulkCreateAssetElements, getUploadUrl } from "@modules/asset";
import uniqid from "uniqid";
import {
    makeUploadQueueJob,
    put,
    retryUploadQueueJob,
    uploadFilesFinished,
    uploadJobFiles,
    uploadJobFileSetStatus,
} from "@modules/uploadQueue";
import { updateMyJobsJob, updateMyJobsTask } from "@modules/myJobs";

function createAssetAPI(stageId, apiEndpoint, stageVersion, projectId, data, updateTotalSize) {
    const tags =
        data.tags &&
        data.tags.map((v) => {
            return { value: v };
        });
    let requestBody = {
        ...data,
        tags,
    };
    let headers = {
        stageId,
        projectId,
    };
    if (compareVersions(stageVersion, VERSION_SAFARI_ENCODING_FOR_PRESIGN_AND_UPLOAD) >= 0) {
        requestBody = Buffer.from(encodeURIComponent(JSON.stringify(requestBody))).toString("base64");
        headers = {
            ...headers,
            "x-mzc-content-encoding": "base64",
        };
    }
    return axios.post(`${apiEndpoint}/assets`, requestBody, {
        params: {
            updateTotalSize,
        },
        headers,
    });
}

function getLanguagesAPI(stageId, apiEndpoint, projectId) {
    return axios.get(`${apiEndpoint}/languages `, {
        headers: {
            stageId,
            projectId,
        },
    });
}

const CREATE_ASSET_RESET = "CREATE_ASSET_RESET";
const CREATE_ASSET_UPDATE_STATE = "CREATE_ASSET_UPDATE_STATE";

const CREATE_ASSET_PENDING = "CREATE_ASSET_PENDING";
const CREATE_ASSET_FAILURE = "CREATE_ASSET_FAILURE";

const CREATE_ASSET_GET_LANGUAGE_PENDING = "CREATE_ASSET_GET_LANGUAGE_PENDING";
const CREATE_ASSET_GET_LANGUAGE_SUCCESS = "CREATE_ASSET_GET_LANGUAGE_SUCCESS";
const CREATE_ASSET_GET_LANGUAGE_FAILURE = "CREATE_ASSET_GET_LANGUAGE_FAILURE";

const CREATE_ASSET_UPDATE_DATA = "CREATE_ASSET_UPDATE_DATA";
const CREATE_ASSET_UPDATE_DATA_ELEMENT_DETAIL = "CREATE_ASSET_UPDATE_DATA_ELEMENTS";
const CREATE_ASSET_UPDATE_DATA_ELEMENT_DETAIL_OLD = "CREATE_ASSET_UPDATE_DATA_ELEMENTS_OLD";
const CREATE_ASSET_UPDATE_ELEMENTS = "CREATE_ASSET_UPDATE_ELEMENTS";
const CREATE_ASSET_SET_VALIDATION_ERROR = "CREATE_ASSET_SET_VALIDATION_ERROR";
const CREATE_ASSET_RESET_ELEMENTS_VALIDATION_ERROR = "CREATE_ASSET_RESET_ELEMENTS_VALIDATION_ERROR";

const CREATE_ASSET_UPDATE_ATTRIBUTION = "CREATE_ASSET_UPDATE_ATTRIBUTION";
const CREATE_ASSET_REMOVE_ATTRIBUTION = "CREATE_ASSET_REMOVE_ATTRIBUTION";
const CREATE_ASSET_ADD_ATTRIBUTION = "CREATE_ASSET_ADD_ATTRIBUTION";

const CREATE_ASSET_SOURCE_URL_PENDING = "CREATE_ASSET_SOURCE_URL_PENDING";
const CREATE_ASSET_SOURCE_URL_SUCCESS = "CREATE_ASSET_SOURCE_URL_SUCCESS";
const CREATE_ASSET_SOURCE_URL_FAILURE = "CREATE_ASSET_SOURCE_URL_FAILURE";

const initialState = {
    pending: false,
    error: false,
    sourceUrlPending: false,
    sourceUrlError: false,
    selectedFiles: {},
    elements: {},
    data: {
        tags: [],
        categories: [],
        attributions: [],
        metadata: [],
    },
    elementsByMediaType: [],
    originFiles: [],
    validationErrors: {},
    languages: {
        data: [],
        pending: false,
        error: false,
    },
};

export default handleActions(
    {
        [CREATE_ASSET_UPDATE_STATE]: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
        [CREATE_ASSET_PENDING]: (state) => {
            return {
                ...state,
                pending: true,
            };
        },
        [CREATE_ASSET_FAILURE]: (state) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [CREATE_ASSET_SET_VALIDATION_ERROR]: (state, action) => {
            const { key, error } = action.payload;

            if (error) {
                return {
                    ...state,
                    validationErrors: {
                        ...state.validationErrors,
                        [key]: error,
                    },
                };
            }

            const validationErrors = state.validationErrors;
            if (validationErrors[key]) {
                delete validationErrors[key];
            }

            return {
                ...state,
                validationErrors,
            };
        },
        [CREATE_ASSET_RESET_ELEMENTS_VALIDATION_ERROR]: (state, action) => {
            const validationErrors = { ...state.validationErrors };
            const validationErrorKeys = Object.keys(validationErrors);

            validationErrorKeys.forEach((key) => {
                if (key.startsWith("elements-")) {
                    delete validationErrors[key];
                }
            });

            return {
                ...state,
                validationErrors,
            };
        },
        [CREATE_ASSET_UPDATE_DATA]: (state, action) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload,
                },
            };
        },
        [CREATE_ASSET_UPDATE_ELEMENTS]: (state, action) => {
            return {
                ...state,
                data: {
                    ...state.data,
                },
                ...action.payload,
            };
        },
        [CREATE_ASSET_UPDATE_DATA_ELEMENT_DETAIL_OLD]: (state, action) => {
            const { key, data } = action.payload;
            return {
                ...state,
                data: {
                    ...state.data,
                },
                elements: state.elements.map((ele) => {
                    if (!key || ele.key === key) {
                        //Note: key가 없을 때도 덮어씌워주는 이유 -> caption일 경우 kind 변경시 아래 모든 파일에 변경된 kind를 적용해야해서 key가 null로 보내진다.
                        //새로운 UI에서는 kind 부분이 없어져서 지우신 것 같은데 UI가 바뀌어야 할 것 같다.
                        return {
                            ...ele,
                            ...data,
                        };
                    }

                    return ele;
                }),
            };
        },
        [CREATE_ASSET_UPDATE_DATA_ELEMENT_DETAIL]: (state, action) => {
            const { key, index, data } = action.payload;
            const newEl = state.elements[key]?.map((ele, idx) => {
                if (idx === index) {
                    return {
                        ...ele,
                        ...data,
                    };
                }
                return ele;
            });

            return {
                ...state,
                data: {
                    ...state.data,
                },
                elements: { ...state.elements, [key]: newEl },
            };
        },
        [CREATE_ASSET_RESET]: (state) => {
            return {
                ...initialState,
            };
        },
        [CREATE_ASSET_REMOVE_ATTRIBUTION]: (state, action) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    attributions: state.data.attributions.filter((v) => v.id !== action.payload),
                },
            };
        },
        [CREATE_ASSET_ADD_ATTRIBUTION]: (state, action) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    attributions: state.data.attributions.concat({
                        id: action.payload,
                        key: "",
                        value: "",
                    }),
                },
            };
        },
        [CREATE_ASSET_UPDATE_ATTRIBUTION]: (state, action) => {
            const data = action.payload;

            return {
                ...state,
                data: {
                    ...state.data,
                    attributions: state.data.attributions.map((a, i) => {
                        if (a.id === data.id) {
                            a.key = data.key === undefined ? a.key : data.key;
                            a.value = data.value === undefined ? a.value : data.value;
                        }

                        return a;
                    }),
                },
            };
        },
        [CREATE_ASSET_GET_LANGUAGE_PENDING]: (state, action) => {
            return {
                ...state,
                languages: {
                    ...state.languages,
                    pending: true,
                },
            };
        },
        [CREATE_ASSET_GET_LANGUAGE_FAILURE]: (state, action) => {
            return {
                ...state,
                languages: {
                    ...state.languages,
                    pending: false,
                    error: true,
                },
            };
        },
        [CREATE_ASSET_GET_LANGUAGE_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            return {
                ...state,
                languages: {
                    data: data,
                    pending: false,
                    error: false,
                },
            };
        },
        [CREATE_ASSET_SOURCE_URL_PENDING]: (state, action) => {
            return {
                ...state,
                sourceUrlPending: true,
                sourceUrlError: false,
            };
        },
        [CREATE_ASSET_SOURCE_URL_SUCCESS]: (state, action) => {
            const payload = action.payload;

            return {
                ...state,
                ...payload,
            };
        },
        [CREATE_ASSET_SOURCE_URL_FAILURE]: (state, action) => {
            return {
                ...state,
                sourceUrlPending: false,
                sourceUrlError: true,
            };
        },
    },
    initialState,
);

export const reset = createAction(CREATE_ASSET_RESET);
export const setValidationError = createAction(CREATE_ASSET_SET_VALIDATION_ERROR);

export const resetElementsValidationError = createAction(CREATE_ASSET_RESET_ELEMENTS_VALIDATION_ERROR);
export const updateData = createAction(CREATE_ASSET_UPDATE_DATA);
export const updateState = createAction(CREATE_ASSET_UPDATE_STATE);
export const updateAttribution = createAction(CREATE_ASSET_UPDATE_ATTRIBUTION);
export const addAttribution = createAction(CREATE_ASSET_ADD_ATTRIBUTION);
export const removeAttribution = createAction(CREATE_ASSET_REMOVE_ATTRIBUTION);
export const updateElements = createAction(CREATE_ASSET_UPDATE_ELEMENTS);

export const updateElementDetail = (key, data, index) => (dispatch, getState) => {
    const { stage } = getState();

    if (compareVersions(stage.version, VERSION_CM_10084_CREATE_ASSETS_WITH_COLLECTIONS) < 0) {
        dispatch({
            type: CREATE_ASSET_UPDATE_DATA_ELEMENT_DETAIL_OLD,
            payload: {
                key,
                data,
            },
        });
    } else {
        dispatch({
            type: CREATE_ASSET_UPDATE_DATA_ELEMENT_DETAIL,
            payload: {
                key,
                index,
                data,
            },
        });
    }
};

export const getLanguages = () => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: CREATE_ASSET_GET_LANGUAGE_PENDING });

    getLanguagesAPI(stage.id, stage.endpoint, project.id)
        .then((response) => {
            dispatch({
                type: CREATE_ASSET_GET_LANGUAGE_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: CREATE_ASSET_GET_LANGUAGE_FAILURE,
                payload: error,
            });
        });
};

export const createAsset =
    (data, updateTotalSize = false) =>
    (dispatch, getState) => {
        const { stage, project } = getState();
        dispatch({ type: CREATE_ASSET_PENDING });
        return new Promise((resolve, reject) => {
            createAssetAPI(stage.id, stage.endpoint, stage.version, project.id, data, updateTotalSize)
                .then((response) => {
                    // TODO:  assetService.createAsync에서 간소화된 데이터만 내려주도록 개선
                    // TODO: response로 { id: xxx, version: x } 정도만 줘도 되는지 확인
                    resolve(response);
                })
                .catch((error) => {
                    dispatch({
                        type: CREATE_ASSET_FAILURE,
                        payload: error,
                    });
                    reject(error);
                });
        });
    };

export const setCreateAssetBySourceUrl = (sourceUrl) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: CREATE_ASSET_SOURCE_URL_PENDING });

    getFilesInS3(stage.id, stage.endpoint, sourceUrl, project.id)
        .then((response) => {
            const decodeSourceUrl = decodeURIComponent(sourceUrl);

            const url = response.request["responseURL"];
            const { type, mediaType, mimeType } = getUrlInfo(url);

            if (!type && !mediaType) return;

            const data = {
                type,
                mediaType,
                tags: [],
                categories: [],
                attributions: [],
                mimeType,
                ingestType: INGEST_TYPE.REMOTE,
            };

            const selectedFile = {
                key: uniqId(),
                displayName: decodeSourceUrl,
                name: decodeSourceUrl.substring(decodeSourceUrl.lastIndexOf("/") + 1, decodeSourceUrl.length),
                url: decodeSourceUrl,
                locationType: UPLOAD_FILE_LOCATION_TYPE.S3,
                size: Number(response.headers["s3-object-content-length"]),
                type,
                index: 0,
                mimeType,
                uploadedPercentage: 0,
                remoteUrl: decodeSourceUrl,
            };

            const elements = [
                {
                    key: selectedFile.key,
                    size: selectedFile.size,
                    mimeType: type !== ASSET_TYPES.CAPTION ? mimeType : "text/vtt",
                    locationType: selectedFile.locationType,
                },
            ];

            if (mediaType === MEDIA_TYPES.VIDEO || mediaType === MEDIA_TYPES.AUDIO)
                elements[0].urls = [selectedFile.url];
            else elements[0].url = selectedFile.url;

            const payload = {
                data,
                selectedFiles: [selectedFile],
                elements,
            };

            dispatch({
                type: CREATE_ASSET_SOURCE_URL_SUCCESS,
                payload,
            });
        })
        .catch((error) => {
            console.error(error);
            dispatch({
                type: CREATE_ASSET_SOURCE_URL_FAILURE,
                payload: error,
            });
        });
};
