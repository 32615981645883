import styled from "styled-components";

/* styled */
export const StyledSpinner = styled("span")`
    ${(props) =>
        props.type === "dot" &&
        `svg {
            width: 26px;
            height: 6px;
            text-align: center;
            vertical-align: middle;
            @keyframes blink {
                50% {
                    fill: transparent;
                }
            }
            .dot {
                animation: 1s blink infinite;
                cx: 4;
                cy: 3;
                r: 2;
                fill: ${
                    props.color
                        ? props.color === "inherit"
                            ? "#fff"
                            : props.theme.palette[props.color].light
                        : "#636363"
                };
            }
            .dot:nth-child(2) {
                cx: 12;
                animation-delay: 250ms;
            }
            .dot:nth-child(3) {
                cx: 20;
                animation-delay: 500ms;
            }
        }`}
    ${(props) =>
        props.type === "circle" &&
        `svg {
            .dot {
                fill : ${
                    props.color
                        ? props.color === "inherit"
                            ? "#fff"
                            : props.theme.palette[props.color].main
                        : "#636363"
                }
            }   
        }
    `}
    ${(props) =>
        props.type === "circle" &&
        props.size === "small" &&
        `svg {
            .dot {
                cx: 0;
                cy: 0;
                r: 6;
            }   
        }
    `}
    ${(props) =>
        props.type === "circle" &&
        props.size === "medium" &&
        `svg {
            .dot {
                cx: 0;
                cy: 0;
                r: 6;
            }   
        }
    `}
`;
