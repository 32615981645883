import React, { useCallback, useEffect, useMemo, useState } from "react";

import PanelPermissionError from "@components/property-panel/ui/PanelPermissionError";
import Loading from "@components/loader/Loading";
import PanelBody from "@components/property-panel/ui/PanelBody";
import VersionedComponent from "@components/VersionedComponent";
import { Box, Button, Divider, Grid, IconButton, Tooltip, Typography } from "@mzc-pdc/ui";
import { IconForward, IconLeave, ImageDefaultCustomContentDark } from "@mzc-cloudplex/icons";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import theme from "@styles/theme";
import EditName from "@components_v2/edit-name/edit-name";
import styled from "@emotion/styled";
import EditSubmitButtonGroup from "@components_v2/edit-submit-button-group/edit-submit-button-group";
import CollectionThumbnail from "@features/thumbnail/collection-thumbnail";
import { VERSION_CM_9766_COLLECTIONS } from "@cores/version";
import { TabPanel, Tabs } from "@components_v2/tabs/tabs";
import PanelDetailsView from "@components/view/panel-details-view/panel-details-view";
import * as PropertyPanelActions from "@modules/propertyPanel";
import {
    clearPropertyPanelLinkedAssets,
    getData,
    updateCollection,
    updatePropertyPanelData,
    updateCustomContent,
    updateCustomContentActions,
} from "@modules/propertyPanel";
import { FOLDER_VIEW_DOMAINS, MEDIA_TYPES, PROPERTY_PANEL_TYPE } from "@constants";
import PanelAssetsView from "@components/view/panel-assets-view/panel-assets-view";
import { setNotification } from "@modules/notification";
import { updateCollectionData } from "@modules/collections";
import { updateCustomContentsData } from "@modules/custom-contents";
import { getPropertyPanelCollectionData } from "@features/property-panel/property-panel-collection-data";
import {
    getPropertyPanelCustomContentMetadata,
    getPropertyPanelCustomContentOverview,
} from "@features/property-panel/property-panel-custom-content-data";
import PanelOverview from "@components/view/panel-overview/panel-overview";
import PanelMetadata from "@components/view/panel-metadata/panel-metadata";
import CustomContentThumbnail from "@features/thumbnail/custom-content-thumbnail";
import menuService from "@services/menuService";
import { useHistory } from "react-router";
import useCustomContent from "@hooks/useCustomContent";

export const PanelHeader = styled(Grid)(({ theme }) => ({
    display: `flex`,
    alignItems: `center`,
    justifyContent: `space-between`,
    padding: `10px 8px 10px 12px`,
    borderBottom: `1px solid ${theme.palette.divider}`,
}));

const TAB_TYPE = {
    DETAILS: "Details",
    ASSETS: "Assets",
    OVERVIEW: "Overview",
    METADATA: "Metadata",
};
const PropertyPanelV2 = ({ onClickClose }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { spaceId, projectId } = menuService.parse(location.pathname);
    const { createDefaultValueSchema } = useCustomContent();

    const [editName, setEditName] = useState("");
    const [editNameActive, setEditNameActive] = useState(false);
    const [actionPending, setActionPending] = useState({});
    const [activeTab, setActiveTab] = useState();

    const propertyPanel = useSelector(({ propertyPanel }) => propertyPanel);

    const onHandleChangeTab = (tab) => {
        if (tab !== TAB_TYPE.ASSETS) dispatch(clearPropertyPanelLinkedAssets());
        setActiveTab(tab);
    };

    const moveToDetails = useCallback(() => {
        if (!propertyPanel.data) return;
        if (propertyPanel.type === PROPERTY_PANEL_TYPE.COLLECTION)
            return history.push(menuService.paths.assetsCollectionDetail(spaceId, projectId, propertyPanel.targetId));
        if (propertyPanel.type === PROPERTY_PANEL_TYPE.CUSTOM_CONTENT)
            return history.push(
                menuService.paths.contentsCustomContentDetail(
                    spaceId,
                    projectId,
                    propertyPanel.data?.contentSchema?.id,
                    propertyPanel.targetId,
                ),
            );
    }, [propertyPanel.data]);

    const onHandleSubmitUpdateContent = useCallback(
        async (key, value) => {
            try {
                setActionPending({ ...actionPending, [key]: true });
                await dispatch(updateCustomContent({ contentId: propertyPanel.targetId, params: { [key]: value } }));

                if (key === "name") {
                    setEditNameActive(false);
                    setActionPending({ ...actionPending, editName: undefined });
                }
            } catch (error) {
                console.log(error);
                dispatch(
                    setNotification({
                        type: "error",
                        contents: t(
                            `common::msg::An error occured while changing collection {{type}}`,
                            `common::msg::An error occured while changing collection ${key}`,
                            { type: t(`common::label::${key}`, key) },
                        ),
                    }),
                );
            }
        },
        [propertyPanel.targetId],
    );

    const onHandleSubmitUpdateContentAction = useCallback(
        async (key, value) => {
            try {
                setActionPending({ ...actionPending, [key]: true });

                await dispatch(
                    updateCustomContentActions({
                        contentId: propertyPanel.targetId,
                        params: { key, value },
                    }),
                );
            } catch (error) {
                console.log(error);
                dispatch(
                    setNotification({
                        type: "error",
                        contents: t(
                            `common::msg::An error occured while changing collection {{type}}`,
                            `common::msg::An error occured while changing collection ${key}`,
                            { type: t(`common::label::${key}`, key) },
                        ),
                    }),
                );
            }
        },
        [propertyPanel.data],
    );

    const tabOptions = useMemo(() => {
        if (!propertyPanel.data) return [];
        switch (propertyPanel.type) {
            case PROPERTY_PANEL_TYPE.COLLECTION:
                return [
                    {
                        label: TAB_TYPE.DETAILS,
                        value: TAB_TYPE.DETAILS,
                        data: getPropertyPanelCollectionData(propertyPanel.data),
                    },
                    {
                        label: TAB_TYPE.ASSETS,
                        value: TAB_TYPE.ASSETS,
                        data: null,
                    },
                ];
            case PROPERTY_PANEL_TYPE.CUSTOM_CONTENT:
                return [
                    {
                        label: TAB_TYPE.OVERVIEW,
                        value: TAB_TYPE.OVERVIEW,
                        data: getPropertyPanelCustomContentOverview(propertyPanel.data, onHandleSubmitUpdateContent),
                    },
                    {
                        label: TAB_TYPE.METADATA,
                        value: TAB_TYPE.METADATA,
                        data: getPropertyPanelCustomContentMetadata(
                            propertyPanel.data,
                            createDefaultValueSchema,
                            onHandleSubmitUpdateContent,
                            onHandleSubmitUpdateContentAction,
                        ),
                    },
                ];
            default:
                return [];
        }
    }, [propertyPanel.data]);

    const thumbnail = useMemo(() => {
        if (!propertyPanel.data) return null;

        //Note: Asset 타입 사용시
        // let src = propertyPanel.data?.previews?.[0]?.accessUrl;
        // if (!src) {
        //     if (propertyPanel.data?.mediaType === MEDIA_TYPES.IMAGE) {
        //         src = propertyPanel.data?.accessUrls?.[0] ?? propertyPanel.data?.elements?.[0]?.accessUrl;
        //     } else if (propertyPanel.data?.mediaType === MEDIA_TYPES.VIDEO)
        //         src = propertyPanel.data?.thumbnails?.[0]?.accessUrl;
        // }

        switch (propertyPanel.type) {
            case PROPERTY_PANEL_TYPE.COLLECTION:
                return <CollectionThumbnail variant={"panel"} collection={propertyPanel.data} />;
            case PROPERTY_PANEL_TYPE.CUSTOM_CONTENT:
                return <CustomContentThumbnail variant={"panel"} content={propertyPanel.data} />;
        }
    }, [propertyPanel.data]);

    const nameType = useMemo(() => {
        if (!propertyPanel.data) return "";

        switch (propertyPanel.type) {
            case PROPERTY_PANEL_TYPE.COLLECTION:
                return `collection`;
            case PROPERTY_PANEL_TYPE.CUSTOM_CONTENT:
                return `content`;
        }
    }, [propertyPanel.data]);

    const folderViewDomain = useMemo(() => {
        switch (propertyPanel.type) {
            case PROPERTY_PANEL_TYPE.ASSET:
                return FOLDER_VIEW_DOMAINS.ASSETS;
            case PROPERTY_PANEL_TYPE.ARCHIVED_ASSET:
                return FOLDER_VIEW_DOMAINS.ARCHIVED_ASSETS;
            default:
                return undefined;
        }
    });

    const onHandleClickEditName = async () => {
        try {
            setActionPending({ ...actionPending, editName: true });

            if (propertyPanel.type === PROPERTY_PANEL_TYPE.COLLECTION) {
                await dispatch(updateCollection({ id: propertyPanel.targetId, params: { name: editName } }));
                dispatch(updateCollectionData({ id: propertyPanel.targetId, type: "name", value: editName }));
            } else if (propertyPanel.type === PROPERTY_PANEL_TYPE.CUSTOM_CONTENT) {
                await onHandleSubmitUpdateContent("name", editName);
            }

            setEditNameActive(false);
        } catch (error) {
            console.log(error);

            dispatch(
                setNotification({
                    type: "error",
                    contents: t(`common::msg::An error occured while changing ${nameType} name`),
                }),
            );
        } finally {
            setActionPending({ ...actionPending, editName: undefined });
        }
    };

    const getTabPanelContents = (tabType) => {
        switch (tabType) {
            case TAB_TYPE.DETAILS:
                return (
                    <PanelDetailsView
                        id={propertyPanel.targetId}
                        data={tabOptions.find((el) => el.label === TAB_TYPE.DETAILS)?.data}
                        actions={PropertyPanelActions}
                        afterMutation={(params) => dispatch(updatePropertyPanelData(params))}
                    />
                );
            case TAB_TYPE.ASSETS:
                return <PanelAssetsView />;
            case TAB_TYPE.OVERVIEW:
                return (
                    <PanelOverview
                        id={propertyPanel.data?.id}
                        data={tabOptions.find((el) => el.label === TAB_TYPE.OVERVIEW)?.data}
                        afterMutation={(params) => {}}
                        domain={folderViewDomain}
                    />
                );
            case TAB_TYPE.METADATA:
                return (
                    <PanelMetadata
                        id={propertyPanel.data?.id}
                        data={tabOptions.find((el) => el.label === TAB_TYPE.METADATA)?.data}
                        afterMutation={(params) => {}}
                    />
                );
            default:
                return <></>;
        }
    };

    useEffect(() => {
        if (!propertyPanel.targetId || !propertyPanel.type) return;

        if (propertyPanel.type === PROPERTY_PANEL_TYPE.COLLECTION) setActiveTab(TAB_TYPE.DETAILS);
        if (propertyPanel.type === PROPERTY_PANEL_TYPE.CUSTOM_CONTENT) setActiveTab(TAB_TYPE.OVERVIEW);

        dispatch(getData({ id: propertyPanel.targetId, type: propertyPanel.type }));
    }, [propertyPanel.targetId, propertyPanel.type]);

    return (
        <VersionedComponent version={VERSION_CM_9766_COLLECTIONS}>
            <PanelHeader>
                {/* CPA구조 가져오기 */}
                <Grid container flexDirection={"row"}>
                    <Grid item flex={1}>
                        <EditName
                            type={"panel"}
                            isEditable={true}
                            isEdit={editNameActive}
                            name={propertyPanel.data?.name}
                            value={editName}
                            valueLength={editName?.length}
                            onChange={(e) => {
                                e.stopPropagation();
                                setEditName(e.target.value);
                            }}
                            onEditClick={(e) => {
                                setEditNameActive(true);
                                setEditName(propertyPanel.data?.name);
                            }}
                            actions={
                                <EditSubmitButtonGroup
                                    submitDisabled={editName === propertyPanel.data?.name || actionPending.editName}
                                    submitPending={actionPending.editName}
                                    onClickSubmit={onHandleClickEditName}
                                    onClickClose={() => {
                                        setEditNameActive(false);
                                    }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item>
                        <Tooltip title={t("common::label::Close")}>
                            <IconButton circled size={`medium`} onClick={onClickClose} sx={{ marginTop: 1 }}>
                                <IconLeave size={16} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </PanelHeader>
            {propertyPanel.pending ? (
                <Loading></Loading>
            ) : (
                <React.Fragment>
                    {propertyPanel.accessError || propertyPanel.error ? (
                        //접근 권한이 없는 경우
                        <PanelPermissionError
                            // handleShareRequestModal={handleShareRequestModal}
                            panelType={propertyPanel.type}
                        ></PanelPermissionError>
                    ) : (
                        <>
                            <PanelBody>
                                {propertyPanel.isShow && propertyPanel.data ? (
                                    <React.Fragment>
                                        {thumbnail}
                                        <Box sx={{ marginLeft: `-1px` }}>
                                            <Tabs
                                                indicatorColor="secondary"
                                                indicatorPosition={"top"}
                                                isBordered
                                                textColor={"secondary"}
                                                value={activeTab}
                                                onChangeTab={onHandleChangeTab}
                                                items={tabOptions}
                                            />
                                            {/*<Divider sx={{ marginTop: `-5px` }} />*/}
                                        </Box>
                                        <Divider sx={{ mt: `-1px` }} />
                                        {tabOptions.map((el) => (
                                            <TabPanel value={activeTab} index={el.value}>
                                                {getTabPanelContents(el.value)}
                                            </TabPanel>
                                        ))}
                                    </React.Fragment>
                                ) : (
                                    <div>{t(`common::label::${"Not found"}`)}</div>
                                )}
                            </PanelBody>
                            <Box>
                                <Button
                                    fullWidth
                                    size={"large"}
                                    variant={"contained"}
                                    color={"greySecondary"}
                                    sx={{
                                        height: `44px`,
                                        borderRadius: 0,
                                        justifyContent: `flex-start`,
                                        pl: 3,
                                        ".MuiButton-endIcon": {
                                            ml: 1.5,
                                        },
                                        "&:hover": {
                                            ".MuiButton-endIcon": {
                                                ".MuiBox-root": {
                                                    left: `12px`,
                                                },
                                            },
                                        },
                                    }}
                                    endIcon={
                                        <Box
                                            sx={{
                                                position: `relative`,
                                                left: `0`,
                                                display: `inline-flex`,
                                                alignItems: `center`,
                                                justifyContent: `center`,
                                                width: `22px`,
                                                height: `22px`,
                                                borderRadius: `50%`,
                                                backgroundColor: theme.palette.primary.light,
                                                transition: `all .2s linear`,
                                            }}
                                        >
                                            <IconForward size={12} />
                                        </Box>
                                    }
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        moveToDetails();
                                    }}
                                >
                                    <Typography variant={"body1_400"}>Go to details</Typography>
                                </Button>
                            </Box>
                        </>
                    )}
                </React.Fragment>
            )}
        </VersionedComponent>
    );
};

export default PropertyPanelV2;
