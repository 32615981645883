import React, { Children } from "react";

const ScrollArea = (props) => {
    return (
        <div
            className={`box-scroll ${props.className}`}
            children={props.children}
        />
    );
};

export default ScrollArea;
