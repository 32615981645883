const menuService = {
    paths: {
        dashboard: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/dashboard`,
        createJob: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/transcoding/upload`,
        jobs: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/jobs`,
        jobDetail: (spaceId, projectId, jobId) => `/spaces/${spaceId}/projects/${projectId}/jobs/${jobId}`,
        jobPreview: (spaceId, projectId, jobId, type, idx) =>
            `/spaces/${spaceId}/projects/${projectId}/jobs/${jobId}/preview/${type}/${idx}`,
        archiveAssets: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/assets/archived`,
        archiveAssetDetail: (spaceId, projectId, archiveId) =>
            `/spaces/${spaceId}/projects/${projectId}/assets/archived/${archiveId}`,
        contents: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/contents`,
        videos: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/contents/videos`,
        videoCreate: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/contents/videos/create`,
        videoDetail: (spaceId, projectId, videoId) =>
            `/spaces/${spaceId}/projects/${projectId}/contents/videos/${videoId}`,
        videoStatistics: (spaceId, projectId, videoId) =>
            `/spaces/${spaceId}/projects/${projectId}/contents/videos/${videoId}/statistics`,
        assetsAssets: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/assets/assets`,
        assetsAssetCreate: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/assets/assets/create`,
        assetSharedHistory: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/assets/sharehistory`,
        assetSharedBox: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/assets/sharebox`,
        assetSharedBoxDetail: (spaceId, projectId, shareBoxId) =>
            `/spaces/${spaceId}/projects/${projectId}/assets/sharebox/${shareBoxId}`,
        storageCms: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/storage`,
        storage: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/storage/storage`,
        storageSharedHistory: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/storage/sharehistory`,
        storageSharedBox: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/storage/sharebox`,
        storagePreview: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/storage/preview`,
        assetsAssetDetail: (spaceId, projectId, assetId) =>
            `/spaces/${spaceId}/projects/${projectId}/assets/assets/${assetId}`,
        assetsAssetDetailStatistics: (spaceId, projectId, assetId) =>
            `/spaces/${spaceId}/projects/${projectId}/assets/assets/${assetId}/statistics`,
        assetsCollections: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/assets/collections`,
        assetsCollectionsSearch: (spaceId, projectId) =>
            `/spaces/${spaceId}/projects/${projectId}/assets/collections/search`,
        assetsCollectionDetail: (spaceId, projectId, collectionId) =>
            `/spaces/${spaceId}/projects/${projectId}/assets/collections/${collectionId}`,
        shared: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/shared`,
        sharedHistory: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/shared/history`,
        sharedBox: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/shared/box`,
        imagesViewer: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/images-viewer`,
        contentsPrograms: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/contents/programs`,
        contentsProgramsDetail: (spaceId, projectId) =>
            `/spaces/${spaceId}/projects/${projectId}/contents/programs/:id/preview`,
        player: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/player`,
        live: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/live`,
        liveStream: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/live/streams`,
        liveStreamCreate: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/live/streams/create`,
        livePlayer: (spaceId, projectId, liveId) => `/spaces/${spaceId}/projects/${projectId}/live/${liveId}/play`,
        assetPreview: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/assets/preview`,
        liveStreamDetail: (spaceId, projectId, streamId) =>
            `/spaces/${spaceId}/projects/${projectId}/live/streams/${streamId}`,
        liveSchedules: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/live/schedules`,
        liveSchedulesCreate: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/live/schedules/create`,
        liveScheduleDetail: (spaceId, projectId, scheduleId) =>
            `/spaces/${spaceId}/projects/${projectId}/live/schedules/${scheduleId}`,
        liveScheduleDetailLineups: (spaceId, projectId, scheduleId) =>
            `/spaces/${spaceId}/projects/${projectId}/live/schedules/${scheduleId}/lineups`,
        channels: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/live/channels`,
        channelCreate: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/live/channels/create`,
        channelDetail: (spaceId, projectId, channelId) =>
            `/spaces/${spaceId}/projects/${projectId}/live/channels/${channelId}`,
        channelStatistics: (spaceId, projectId, channelId) =>
            `/spaces/${spaceId}/projects/${projectId}/live/channels/${channelId}/statistics`,
        interactiveVideos: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/interactive-videos`,
        interactiveVideosDetail: (spaceId, projectId, videoId) =>
            `/spaces/${spaceId}/projects/${projectId}/interactive-videos/${videoId}`,
        interactiveVideoCreate: (spaceId, projectId) =>
            `/spaces/${spaceId}/projects/${projectId}/interactive-videos/create`,
        interactiveLive: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/interactive-live`,
        interactiveLiveCreate: (spaceId, projectId) =>
            `/spaces/${spaceId}/projects/${projectId}/interactive-live/create`,
        interactiveLiveDetail: (spaceId, projectId, liveId) =>
            `/spaces/${spaceId}/projects/${projectId}/interactive-live/${liveId}`,
        interactiveLiveChat: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/chat`,
        search: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/search`,
        integratedSearch: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/search/engine`,
        captionAssetPreview: (spaceId, projectId, groupId) =>
            `/spaces/${spaceId}/projects/${projectId}/assets/assets/${groupId}/captions`,
        imageAssetPreview: (spaceId, projectId, groupId) =>
            `/spaces/${spaceId}/projects/${projectId}/assets/assets/${groupId}/images`,
        videoAssetPreview: (spaceId, projectId, groupId) =>
            `/spaces/${spaceId}/projects/${projectId}/assets/assets/${groupId}/videos`,
        contentsPeople: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/contents/people`,
        contentsPeopleCreate: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/contents/people/create`,
        contentsPeopleDetail: (spaceId, projectId, peopleId) =>
            `/spaces/${spaceId}/projects/${projectId}/contents/people/${peopleId}`,
        contentsVideos: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/contents/videos`,
        contentsVideoDetail: (spaceId, projectId, videoId) =>
            `/spaces/${spaceId}/projects/${projectId}/contents/videos/${videoId}`,
        contentsVideoCreate: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/contents/videos/create`,
        contentsVideoDetailStatistics: (spaceId, projectId, videoId) =>
            `/spaces/${spaceId}/projects/${projectId}/contents/videos/${videoId}/statistics`,
        assets: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/assets`,
        assetCreate: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/assets/create`,
        assetSources: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/assets/sources`,
        assetDetail: (spaceId, projectId, assetId) => `/spaces/${spaceId}/projects/${projectId}/assets/${assetId}`,
        assetDetailStatistics: (spaceId, projectId, assetId) =>
            `/spaces/${spaceId}/projects/${projectId}/assets/${assetId}/statistics`,
        listingDetailPreview: (spaceId, projectId, listingId) =>
            `/spaces/${spaceId}/projects/${projectId}/listing/${listingId}/preview`,
        meetings: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/meetings`,
        meetings_old: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/meetings_old`,
        captionSharedBoxPreview: (spaceId, projectId, groupId) =>
            `/spaces/${spaceId}/projects/${projectId}/assets/sharebox/${groupId}/captions`,
        imageSharedBoxPreview: (spaceId, projectId, groupId) =>
            `/spaces/${spaceId}/projects/${projectId}/assets/sharebox/${groupId}/images`,
        videoSharedBoxPreview: (spaceId, projectId, groupId) =>
            `/spaces/${spaceId}/projects/${projectId}/assets/sharebox/${groupId}/videos`,
        cms: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/cms`,
        cmsVideos: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/cms/videos`,
        cmsVideosDetail: (spaceId, projectId, videoId) =>
            `/spaces/${spaceId}/projects/${projectId}/cms/videos/${videoId}`,
        cmsAssets: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/cms/assets`,
        cmsAssetsCreate: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/cms/assets/create`,
        cmsAssetsDetail: (spaceId, projectId, assetId) =>
            `/spaces/${spaceId}/projects/${projectId}/cms/assets/${assetId}`,
        cmsCaptionAssetPreview: (spaceId, projectId, groupId) =>
            `/spaces/${spaceId}/projects/${projectId}/cms/assets/${groupId}/captions`,
        cmsImageAssetPreview: (spaceId, projectId, groupId) =>
            `/spaces/${spaceId}/projects/${projectId}/cms/assets/${groupId}/images`,
        cmsVideosAssetPreview: (spaceId, projectId, groupId) =>
            `/spaces/${spaceId}/projects/${projectId}/cms/assets/${groupId}/videos`,
        cmsPeople: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/cms/people`,
        cmsPeopleCreate: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/cms/people/create`,
        cmsPeopleDetail: (spaceId, projectId, peopleId) =>
            `/spaces/${spaceId}/projects/${projectId}/cms/people/${peopleId}`,
        meetingsCreate: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/meetings/create`,
        meetingsDetail: (spaceId, projectId, meetingId) =>
            `/spaces/${spaceId}/projects/${projectId}/meetings/${meetingId}`,
        meetingsCreateOld: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/meetings_old/create`,
        meetingsOldDetail: (spaceId, projectId, meetingId) =>
            `/spaces/${spaceId}/projects/${projectId}/meetings_old/${meetingId}`,
        contentsMusic: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/contents/music`,
        contentsMusicCreate: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/contents/music/create`,
        contentsMusicDetail: (spaceId, projectId, musicId) =>
            `/spaces/${spaceId}/projects/${projectId}/contents/music/${musicId}`,
        contentsPhoto: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/contents/photos`,
        contentsPhotoCreate: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/contents/photos/create`,
        contentsPhotoDetail: (spaceId, projectId, photoId) =>
            `/spaces/${spaceId}/projects/${projectId}/contents/photos/${photoId}`,
        contentsCustomContents: (spaceId, projectId, schemaId) =>
            `/spaces/${spaceId}/projects/${projectId}/contents/${schemaId}`,
        contentsCustomContentsSearch: (spaceId, projectId, schemaId) =>
            `/spaces/${spaceId}/projects/${projectId}/contents/${schemaId}/search`,
        contentsCustomContentDetail: (spaceId, projectId, schemaId, contentId) =>
            `/spaces/${spaceId}/projects/${projectId}/contents/${schemaId}/${contentId}`,
        assetAccessDenied: (assetId) => `/error/assets/accessdenied/${assetId}`,
        vocabularies: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/vocabularies`,
        vocabulariesSearch: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}/vocabularies/search`,
        vocabularyDetail: (spaceId, projectId, vocabularyId) =>
            `/spaces/${spaceId}/projects/${projectId}/vocabularies/${vocabularyId}`,
        assetDeleted: (assetId) => `/error/assets/deleted/${assetId}`,
        spaces: () => `/spaces`,
        project: (spaceId, projectId) => `/spaces/${spaceId}/projects/${projectId}`,
        admin: {
            //space
            statistics: (spaceId) => `/admin/spaces/${spaceId}/statistics`,
            liveChannels: (spaceId) => `/admin/spaces/${spaceId}/live/channels`,
            liveChannelDetail: (spaceId, channelId) => `/admin/spaces/${spaceId}/live/channels/${channelId}`,
            liveChannelCreate: (spaceId, channelId) => `/admin/spaces/${spaceId}/live/channels/create`,
            spaceRoles: (spaceId) => `/admin/spaces/${spaceId}/roles`,
            spaceRoleCreate: (spaceId) => `/admin/spaces/${spaceId}/roles/create`,
            spaceRoleDetail: (spaceId, roleId) => `/admin/spaces/${spaceId}/roles/${roleId}`,
            spaceMetadata: (spaceId) => `/admin/spaces/${spaceId}/metadata`,
            spaceMetadataCreate: (spaceId) => `/admin/spaces/${spaceId}/metadata/create`,
            spaceMetadataDetail: (spaceId, metadataId) => `/admin/spaces/${spaceId}/metadata/${metadataId}`,
            settings: (spaceId) => `/admin/spaces/${spaceId}/settings`,
            users: (spaceId) => `/admin/spaces/${spaceId}/users`,
            userDetail: (spaceId, userId) => `/admin/spaces/${spaceId}/users/${userId}`,
            groups: (spaceId) => `/admin/spaces/${spaceId}/groups`,
            groupDetail: (spaceId, groupId) => `/admin/spaces/${spaceId}/groups/${groupId}`,
            projects: (spaceId) => `/admin/spaces/${spaceId}/project-list`,
            projectCreate: (spaceId) => `/admin/spaces/${spaceId}/project-list/create`,
            histories: (spaceId) => `/admin/spaces/${spaceId}/histories`,
            spaceContentSchemas: (spaceId) => `/admin/spaces/${spaceId}/content-schemas`,
            spaceContentSchemaDetail: (spaceId, contentSchemaId) =>
                `/admin/spaces/${spaceId}/content-schemas/${contentSchemaId}`,
            projectAssignedUsers: (spaceId, projectId) =>
                `/admin/spaces/${spaceId}/project-list/${projectId}/assigned-users`,
            projectAssignedGroups: (spaceId, projectId) =>
                `/admin/spaces/${spaceId}/project-list/${projectId}/assigned-groups`,
            groupAssignableUsers: (spaceId, groupId) => `/admin/spaces/${spaceId}/groups/${groupId}/assignable-users`,
            //project
            eventTriggers: (spaceId, projectId) => `/admin/spaces/${spaceId}/projects/${projectId}/event-triggers`,
            eventTriggerCreate: (spaceId, projectId) =>
                `/admin/spaces/${spaceId}/projects/${projectId}/event-triggers/create`,
            eventTriggerDetail: (spaceId, projectId, eventTriggerId) =>
                `/admin/spaces/${spaceId}/projects/${projectId}/event-triggers/${eventTriggerId}`,
            metadataTemplate: (spaceId, projectId) =>
                `/admin/spaces/${spaceId}/projects/${projectId}/metadata-template`,
            metadataTemplateCreate: (spaceId, projectId) =>
                `/admin/spaces/${spaceId}/projects/${projectId}/metadata-template/create`,
            metadataTemplateDetail: (spaceId, projectId, templateId) =>
                `/admin/spaces/${spaceId}/projects/${projectId}/metadata-template/${templateId}`,
            projectSettings: (spaceId, projectId) => `/admin/spaces/${spaceId}/projects/${projectId}/settings`,
            authorities: (spaceId, projectId, type) =>
                `/admin/spaces/${spaceId}/projects/${projectId}/authorities/${type}`,
            authoritiesGroup: (spaceId, projectId) => `/admin/spaces/${spaceId}/projects/${projectId}/groups`,
            authoritiesGroupDetail: (spaceId, projectId, groupId) =>
                `/admin/spaces/${spaceId}/projects/${projectId}/groups/${groupId}`,
            authoritiesUser: (spaceId, projectId) => `/admin/spaces/${spaceId}/projects/${projectId}/users`,
            authoritiesUserDetail: (spaceId, projectId, userId) =>
                `/admin/spaces/${spaceId}/projects/${projectId}/users/${userId}`,
            categoryGroups: (spaceId, projectId) => `/admin/spaces/${spaceId}/projects/${projectId}/category-groups`,
            categoryGroupCreate: (spaceId, projectId) =>
                `/admin/spaces/${spaceId}/projects/${projectId}/category-groups/create`,
            categoryGroupDetail: (spaceId, projectId, groupId) =>
                `/admin/spaces/${spaceId}/projects/${projectId}/category-groups/${groupId}`,
            roles: (spaceId, projectId) => `/admin/spaces/${spaceId}/projects/${projectId}/roles`,
            projectRoles: (spaceId, projectId) => `/admin/spaces/${spaceId}/projects/${projectId}/roles`,
            roleCreate: (spaceId, projectId) => `/admin/spaces/${spaceId}/projects/${projectId}/roles/create`,
            roleDetail: (spaceId, projectId, roleId) =>
                `/admin/spaces/${spaceId}/projects/${projectId}/roles/${roleId}`,
            project: (spaceId, projectId) => `/admin/spaces/${spaceId}/project-list/${projectId}`,
            contentSchemas: (spaceId, projectId) => `/admin/spaces/${spaceId}/projects/${projectId}/content-schemas`,
            contentSchemaDetail: (spaceId, projectId, contentSchemaId) =>
                `/admin/spaces/${spaceId}/projects/${projectId}/content-schemas/${contentSchemaId}`,
            metadataExports: (spaceId) => `/admin/spaces/${spaceId}/metadata-export`,
            metadataExportDetail: (spaceId, metadataExportId) =>
                `/admin/spaces/${spaceId}/metadata-export/${metadataExportId}`,
        },
    },
    parse: (pathname) => {
        const paths = pathname.split("/"); // /spaces/123/projects/456 or /admin/spaces/123/projects/456
        if (paths.length > 1 && paths[1] === "admin") {
            paths.splice(1, 1);
        }
        const spaceId = paths.length > 2 && paths[0] === "" && paths[1] === "spaces" ? paths[2] : null;
        const projectId = spaceId != null && paths.length > 4 && paths[3] === "projects" ? paths[4] : null;
        return { spaceId, projectId };
    },
    breadcrumbs: (pathname) => {
        const { spaceId, projectId } = menuService.parse(pathname);
        if (spaceId && projectId) {
            return pathname.slice(pathname.indexOf(projectId) + projectId.length);
        } else if (spaceId && !projectId) {
            return pathname.slice(pathname.indexOf(spaceId) + spaceId.length);
        } else if (!spaceId && !projectId) {
            return pathname;
        }
    },
};

export default menuService;
