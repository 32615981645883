import { handleActions, createAction } from "redux-actions";
import { axios, axiosWithoutHeaders } from "../cores/axiosWrapper";

import { STATISTICS_WIDGET_RESOURCE_TYPE } from "../constants";

import moment from "moment";
import pad from "../cores/string.pad";
import queryString from "../cores/queryString";

function deleteResourceStatisticsWidgetGroupAPI(stageId, apiEndpoint, projectId, widgetGroupId) {
    return axios.delete(`${apiEndpoint}/statistics-widget-groups/${widgetGroupId}`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function getResourceStatisticsWidgetsAPI(stageId, apiEndpoint, projectId, params) {
    let queries = {};

    queries.projectId = projectId;

    if (params.teamId) queries.teamId = params.teamId;
    if (params.userId) queries.userId = params.userId;
    if (params.resourceType) queries.resourceType = params.resourceType;

    const headers = { stageId };
    if (projectId) {
        headers.projectId = projectId;
    }

    return axios.get(`${apiEndpoint}/statistics-widget-groups?${queryString.stringify(queries)}`, {
        headers,
    });
}

function postResourceStatisticsWidgetGroupAPI(stageId, apiEndpoint, projectId, params) {
    return axios.post(
        `${apiEndpoint}/statistics-widget-groups`,
        {
            projectId: projectId,
            ...params,
        },
        {
            headers: {
                stageId,
                projectId,
            },
        },
    );
}

function putResourceStatisticsWidgetGroupWidgetsAPI(stageId, apiEndpoint, projectId, widgetGroupId, params) {
    return axios.put(`${apiEndpoint}/statistics-widget-groups/${widgetGroupId}/statistics-widgets`, params, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function getResourceStatisticsAPI(
    stageId,
    apiEndpoint,
    projectId,
    resourceType,
    resourceId,
    widgetItem,
    widgetIdx,
    widgetItemIdx,
    dispatch,
) {
    let requestData = widgetItem.requestData;
    requestData = requestData
        .replace(getResourceTypeQuery(resourceType), `\"${resourceId}\"`)
        .replace(/{{:startDate:}}/gi, `\"${moment(widgetItem.params.startDate).format("YYYY-MM-DDTHH:mm:ss.SSSZ")}\"`)
        .replace(/{{:endDate:}}/gi, `\"${moment(widgetItem.params.endDate).format("YYYY-MM-DDTHH:mm:ss.SSSZ")}\"`)
        .replace(
            /{{:interval:}}/gi,
            moment.duration(widgetItem.params.endDate.diff(widgetItem.params.startDate)).asDays() >= 1
                ? `\"day\"`
                : `\"hour\"`,
        );

    let endpoint = `${apiEndpoint}/statistics/resource`;

    axios
        .post(endpoint, JSON.parse(requestData), {
            headers: {
                stageId,
                projectId,
            },
        })
        .then((response) => {
            dispatch({
                type: GET_RESOURCE_STATISTICS_SUCCESS,
                payload: {
                    widgetIdx,
                    widgetItemIdx,
                    data: response.data,
                },
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_RESOURCE_STATISTICS_FAILURE,
                payload: {
                    widgetIdx,
                    widgetItemIdx,
                    error,
                },
            });
        });
}

function getResourceTypeQuery(resourceType) {
    switch (resourceType) {
        case "VIDEO":
            return /{{:videoId:}}/gi;
        case "ASSET":
            return /{{:assetId:}}/gi;
        case "CHANNEL":
            return /{{:channelId:}}/gi;
    }
}

//action type
const GET_WIDGETS_PENDING = "GET_WIDGETS_PENDING";
const GET_WIDGETS_SUCCESS = "GET_WIDGETS_SUCCESS";
const GET_WIDGETS_FAILURE = "GET_WIDGETS_FAILURE";
const GET_WIDGETS_BY_PROJECT_PENDING = "GET_WIDGETS_BY_PROJECT_PENDING";
const GET_WIDGETS_BY_PROJECT_SUCCESS = "GET_WIDGETS_BY_PROJECT_SUCCESS";
const GET_WIDGETS_BY_PROJECT_FAILURE = "GET_WIDGETS_BY_PROJECT_FAILURE";
const POST_WIDGETS_SUCCESS = "POST_WIDGETS_SUCCESS";
const POST_WIDGETS_FAILURE = "POST_WIDGETS_FAILURE";
const RESOURCE_STATISTICS_ALL_PENDING = "RESOURCE_STATISTICS_ALL_PENDING";
const RESOURCE_STATISTICS_PENDING = "RESOURCE_STATISTICS_PENDING";
const GET_RESOURCE_STATISTICS_SUCCESS = "GET_RESOURCE_STATISTICS_SUCCESS";
const GET_RESOURCE_STATISTICS_FAILURE = "GET_RESOURCE_STATISTICS_FAILURE";
const INIT_RESOURCE_STATISTICS = "INIT_RESOURCE_STATISTICS";
const UPDATE_WIDGET_ITEM_PARAMS = "UPDATE_WIDGET_ITEM_PARAMS";
const UPDATE_WIDGET_ITEM_TAB = "UPDATE_WIDGET_ITEM_TAB";
const TOGGLE_WIDGET_DROPDOWN_MENU = "TOGGLE_WIDGET_DROPDOWN_MENU";
const DELETE_WIDGET_GROUP_SUCCESS = "DELETE_WIDGET_GROUP_SUCCESS";

const initialState = {
    resourceType: "", // ASSET, VIDEO, CHANNEL
    widgetError: false,
    widgetPending: false,
    projectId: "",
    teamId: "",
    userId: "",
    widgetGroupId: "",
    widgets: [],

    widgetsByProjectError: false,
    widgetsByProjectPending: false,
    widgetsByProject: [],
};

export default handleActions(
    {
        [GET_WIDGETS_PENDING]: (state, action) => {
            return {
                ...state,
                widgetError: false,
                widgetPending: true,
                widgets: [],
            };
        },
        [GET_WIDGETS_SUCCESS]: (state, action) => {
            const { data, initParams } = action.payload;

            return {
                ...state,
                widgetError: false,
                widgetPending: false,
                projectId: data.projectId,
                teamId: data.teamId,
                userId: data.userId,
                widgetGroupId: data.id,
                widgets:
                    data &&
                    data.widgets.map((widget, idx) => {
                        const widgetItems = widget.widgetItems.map((widgetItem, widgetItemIdx) => {
                            return {
                                ...widgetItem,
                                widgetItemError: false,
                                widgetItemPending: false,
                                isWidgetSelected: widgetItemIdx === 0 ? true : false,
                                data: {},
                                params: {
                                    ...widgetItem.params,
                                    ...initParams,
                                    chartType: "",
                                },
                            };
                        });
                        return {
                            ...widget,
                            widgetItems,
                        };
                    }),
            };
        },
        [GET_WIDGETS_FAILURE]: (state, action) => {
            return {
                ...state,
                widgetError: true,
                widgetPending: false,
                widgets: [],
            };
        },
        [GET_WIDGETS_BY_PROJECT_PENDING]: (state, action) => {
            return {
                ...state,
                widgetsByProjectError: false,
                widgetsByProjectPending: true,
                widgetsByProject: [],
            };
        },
        [GET_WIDGETS_BY_PROJECT_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                widgetsByProjectError: false,
                widgetsByProjectPending: false,
                widgetsByProject: data && data.widgets,
            };
        },
        [GET_WIDGETS_BY_PROJECT_FAILURE]: (state, action) => {
            return {
                ...state,
                widgetsByProjectError: true,
                widgetsByProjectPending: false,
                widgetsByProject: [],
            };
        },
        [POST_WIDGETS_SUCCESS]: (state, action) => {
            const { data, initParams } = action.payload;

            return {
                ...state,
                widgetError: false,
                widgetPending: false,
                widgets:
                    data &&
                    data.widgets.map((widget, idx) => {
                        const widgetItems = widget.widgetItems.map((widgetItem, widgetItemIdx) => {
                            return {
                                ...widgetItem,
                                widgetItemError: false,
                                widgetItemPending: false,
                                isWidgetSelected: widgetItemIdx === 0 ? true : false,
                                data: {},
                                params: {
                                    ...widgetItem.params,
                                    ...initParams,
                                    chartType: "",
                                },
                            };
                        });
                        return {
                            ...widget,
                            isDropdownMenu: false,
                            widgetItems,
                        };
                    }),
            };
        },
        [POST_WIDGETS_FAILURE]: (state, action) => {
            return {
                ...state,
                widgetError: true,
                widgetPending: false,
                widgets: [],
            };
        },
        [RESOURCE_STATISTICS_ALL_PENDING]: (state, action) => {
            return {
                ...state,
                widgets: state.widgets.map((widget) => {
                    const widgetItems = widget.widgetItems.map((widgetItem) => {
                        return {
                            ...widgetItem,
                            widgetItemError: false,
                            widgetItemPending: true,
                        };
                    });
                    return {
                        ...widget,
                        widgetItems,
                    };
                }),
            };
        },
        [RESOURCE_STATISTICS_PENDING]: (state, action) => {
            const { widgetIdx, widgetItemIdx } = action.payload;

            const widgets = state.widgets;
            const widget = widgets[widgetIdx];

            const widgetItem = {
                ...widget.widgetItems[widgetItemIdx],
                data: {},
                widgetItemError: false,
                widgetItemPending: true,
            };

            widget.widgetItems.splice(widgetItemIdx, 1, widgetItem);
            widgets.splice(widgetIdx, 1, widget);

            return {
                ...state,
                widgets,
            };
        },
        [GET_RESOURCE_STATISTICS_FAILURE]: (state, action) => {
            const { widgetIdx, widgetItemIdx, data } = action.payload;

            const widgets = state.widgets;
            const widget = widgets[widgetIdx];
            const widgetItem = {
                ...widget.widgetItems[widgetItemIdx],
                widgetItemError: true,
                widgetItemPending: false,
                data: {},
            };

            widget.widgetItems.splice(widgetItemIdx, 1, widgetItem);
            widgets.splice(widgetIdx, 1, widget);

            return {
                ...state,
                widgets,
            };
        },
        [GET_RESOURCE_STATISTICS_SUCCESS]: (state, action) => {
            const { widgetIdx, widgetItemIdx, data } = action.payload;

            const widgets = state.widgets;
            const widget = widgets[widgetIdx];
            const widgetItem = {
                ...widget.widgetItems[widgetItemIdx],
                widgetItemError: false,
                widgetItemPending: false,
                data,
            };

            widget.widgetItems.splice(widgetItemIdx, 1, widgetItem);
            widgets.splice(widgetIdx, 1, widget);

            return {
                ...state,
                widgets,
            };
        },
        [INIT_RESOURCE_STATISTICS]: (state) => {
            return {
                ...initialState,
            };
        },
        [UPDATE_WIDGET_ITEM_PARAMS]: (state, action) => {
            const { widgetIdx, widgetItemIdx, params } = action.payload;

            const widgets = state.widgets;
            const widget = widgets[widgetIdx];

            const widgetItem = {
                ...widget.widgetItems[widgetItemIdx],
                params: {
                    ...widget.widgetItems[widgetItemIdx].params,
                    ...params,
                },
            };

            widget.widgetItems.splice(widgetItemIdx, 1, widgetItem);
            widgets.splice(widgetIdx, 1, widget);

            return {
                ...state,
                widgets,
            };
        },
        [UPDATE_WIDGET_ITEM_TAB]: (state, action) => {
            const { widgetIdx, widgetItemIdx } = action.payload;

            const widgets = state.widgets;
            const widget = widgets[widgetIdx];

            widget.widgetItems = widget.widgetItems.map((widgetItem, idx) => {
                return {
                    ...widgetItem,
                    isWidgetSelected: widgetItemIdx === idx,
                };
            });

            widgets.splice(widgetIdx, 1, widget);

            return {
                ...state,
                widgets,
            };
        },
        [TOGGLE_WIDGET_DROPDOWN_MENU]: (state, action) => {
            const { widgetIdx, value } = action.payload;

            let widgets = state.widgets;

            widgets = widgets.map((widget, widgetIndex) => {
                if (widgetIdx === widgetIndex) {
                    if (value) widget.isDropdownMenu = value;
                    else widget.isDropdownMenu = !widget.isDropdownMenu;
                }
                return {
                    ...widget,
                };
            });

            return {
                ...state,
                widgets,
            };
        },
        [DELETE_WIDGET_GROUP_SUCCESS]: (state, action) => {
            return {
                ...state,
                resourceType: "",
                widgetError: false,
                widgetPending: false,
                projectId: "",
                teamId: "",
                userId: "",
                widgetGroupId: "",
                widgets: [],
            };
        },
    },
    initialState,
);

export const getResourceStatisticsWidgets = (projectId, params, initParams) => (dispatch, getState) => {
    let { stage, project } = getState();

    dispatch({ type: GET_WIDGETS_PENDING });

    return new Promise((resolve, reject) => {
        getResourceStatisticsWidgetsAPI(stage.id, stage.endpoint, projectId ?? project.id, params)
            .then((response) => {
                dispatch({
                    type: GET_WIDGETS_SUCCESS,
                    payload: {
                        data:
                            response.data &&
                            response.data.statisticsWidgetGroups &&
                            response.data.statisticsWidgetGroups.length > 0 &&
                            response.data.statisticsWidgetGroups[0],
                        initParams,
                    },
                });
                resolve(response.data);
            })
            .catch((error) => {
                dispatch({
                    type: GET_WIDGETS_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const getResourceStatisticsWidgetsByProject = (projectId, resourceType) => (dispatch, getState) => {
    let { stage, project } = getState();

    dispatch({ type: GET_WIDGETS_BY_PROJECT_PENDING });

    return new Promise((resolve, reject) => {
        getResourceStatisticsWidgetsAPI(stage.id, stage.endpoint, projectId ?? project.id, { resourceType })
            .then((response) => {
                dispatch({
                    type: GET_WIDGETS_BY_PROJECT_SUCCESS,
                    payload: {
                        data:
                            response.data &&
                            response.data.statisticsWidgetGroups &&
                            response.data.statisticsWidgetGroups.length > 0 &&
                            response.data.statisticsWidgetGroups[0],
                    },
                });
                resolve(response.data);
            })
            .catch((error) => {
                dispatch({
                    type: GET_WIDGETS_BY_PROJECT_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const postResourceStatisticsWidgetGroup = (projectId, params) => (dispatch, getState) => {
    let { stage, project } = getState();

    dispatch({ type: GET_WIDGETS_PENDING });

    return new Promise((resolve, reject) => {
        postResourceStatisticsWidgetGroupAPI(stage.id, stage.endpoint, projectId ?? project.id, params)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const putResourceStatisticsWidgetGroupWidgets = (projectId, widgetGroupId, params) => (dispatch, getState) => {
    let { stage } = getState();

    dispatch({ type: GET_WIDGETS_PENDING });

    return new Promise((resolve, reject) => {
        putResourceStatisticsWidgetGroupWidgetsAPI(stage.id, stage.endpoint, projectId, widgetGroupId, params)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getAllStatistics = (projectId, resourceType, resourceId) => (dispatch, getState) => {
    let { stage, project, resourceStatistics } = getState();

    dispatch({ type: RESOURCE_STATISTICS_ALL_PENDING });

    resourceStatistics.widgets.map((widget, widgetIdx) => {
        widget.widgetItems.map((widgetItem, widgetItemIdx) => {
            getResourceStatisticsAPI(
                stage.id,
                stage.endpoint,
                projectId ?? project.id,
                resourceType,
                resourceId,
                widgetItem,
                widgetIdx,
                widgetItemIdx,
                dispatch,
            );
        });
    });
};

export const getStatistics =
    (projectId, resourceType, resourceId, widgetIdx, widgetItemIdx) => (dispatch, getState) => {
        let { stage, project, resourceStatistics } = getState();

        dispatch({
            type: RESOURCE_STATISTICS_PENDING,
            payload: {
                widgetIdx,
                widgetItemIdx,
            },
        });

        getResourceStatisticsAPI(
            stage.id,
            stage.endpoint,
            projectId ?? project.id,
            resourceType,
            resourceId,
            resourceStatistics.widgets[widgetIdx].widgetItems[widgetItemIdx],
            widgetIdx,
            widgetItemIdx,
            dispatch,
        );
    };

export const updateAllWidgetItemParams = (params) => (dispatch, getState) => {
    let { resourceStatistics } = getState();

    resourceStatistics.widgets.map((widget, widgetIdx) => {
        widget.widgetItems.map((widgetItem, widgetItemIdx) => {
            dispatch({
                type: UPDATE_WIDGET_ITEM_PARAMS,
                payload: {
                    widgetIdx,
                    widgetItemIdx,
                    params,
                },
            });
        });
    });
};

export const updateWidgetItemParams = (widgetIdx, widgetItemIdx, params) => (dispatch, getState) => {
    let {} = getState();

    dispatch({
        type: UPDATE_WIDGET_ITEM_PARAMS,
        payload: {
            widgetIdx,
            widgetItemIdx,
            params,
        },
    });
};

export const changeTab = (widgetIdx, widgetItemIdx, params) => (dispatch, getState) => {
    let {} = getState();

    dispatch({
        type: UPDATE_WIDGET_ITEM_TAB,
        payload: {
            widgetIdx,
            widgetItemIdx,
            params,
        },
    });
};

export const toggleDropdownMenu = (widgetIdx, value) => (dispatch, getState) => {
    let {} = getState();

    dispatch({
        type: TOGGLE_WIDGET_DROPDOWN_MENU,
        payload: {
            widgetIdx,
            value,
        },
    });
};

export const deleteResourceStatisticsWidgetGroupWidget = (widgetGroupId) => (dispatch, getState) => {
    let { stage, project } = getState();

    dispatch({ type: GET_WIDGETS_PENDING });

    return new Promise((resolve, reject) => {
        deleteResourceStatisticsWidgetGroupAPI(stage.id, stage.endpoint, project.id, widgetGroupId)
            .then((response) => {
                dispatch({
                    type: DELETE_WIDGET_GROUP_SUCCESS,
                    payload: {},
                });
                resolve(response.data);
            })
            .catch((error) => {
                dispatch({
                    type: GET_WIDGETS_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const initresourceStatistics = createAction(INIT_RESOURCE_STATISTICS);
