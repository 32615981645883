import {handleActions, createAction} from 'redux-actions';
import {
    axios,
    axiosWithoutHeaders
} from "../cores/axiosWrapper";

export function getProfileAPI(stageId, id, apiEndpoint, projectId) {
    return axios.get(`${apiEndpoint}/profiles/${id}`, {
        headers : {
            stageId,
            projectId
        }
    });
}

//action type
const PROFILE_PENDING = 'PROFILES_PENDING';
const PROFILE_FAILURE = 'PROFILES_FAILURE';
const GET_PROFILE_SUCCESS = 'GET_PROFILES_SUCCESS';

//reducer
const initialState = {
    pending:false,
    error:false,
    data: null
};

export default handleActions({
    [PROFILE_PENDING]: (state) => {
        return {
            ...state,
            pending: true,
            error: false
        }
    },
    [PROFILE_FAILURE]: (state) => {
        return {
            ...state,
            pending: false,
            error: true
        };
    },
    [GET_PROFILE_SUCCESS]: (state, action) => {
        let {data} = action.payload;
        
        return {
            pending:false,
            error:false,
            data
        }
    }
}, initialState);

export const getProfile = (id, callback) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    if (stage.id === '') {
        return;
    }

    dispatch({type: PROFILE_PENDING});

    return getProfileAPI(stage.id, id, stage.endpoint, project.id).then(
        (response) => {
            if(callback && typeof callback === "function") {
                callback(response);
            }

            dispatch({
                type: GET_PROFILE_SUCCESS,
                payload: response
            });
        }
    ).catch(error => {
        dispatch({
            type: PROFILE_FAILURE,
            payload: error
        });
    });
};