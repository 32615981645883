import { ClickAwayListener, Popper } from "@mzc-pdc/ui";
import { styled } from "@mzc-pdc/ui";
import EmojiPicker from "emoji-picker-react";
import React from "react";

const StyledPopper = styled(Popper)(({ theme }) => ({
    zIndex: "9999",
    borderRadius: "8px",
    border: `1px solid ${theme.palette.greySecondary[600]}`,
    boxShadow: "0px 3px 6px 0px rgba(0,0,0,0.32)",
}));
const EmojiPickerPopper = ({ placement, disablePortal, onClose, open, anchorEl, onEmojiClick }) => {
    return (
        <ClickAwayListener onClickAway={onClose}>
            <StyledPopper open={open} anchorEl={anchorEl} placement={placement} disablePortal={disablePortal}>
                <EmojiPicker onEmojiClick={onEmojiClick} height={450} />
            </StyledPopper>
        </ClickAwayListener>
    );
};

export default EmojiPickerPopper;
