import { AxiosWrapper } from "../cores/axiosWrapper";
import { handleActions, createAction } from "redux-actions";
import Cookies from "js-cookie";
import { isLocal } from "../cores/enviromentExtension";
//action type
const SET_TOKEN = "SET_TOKEN";
const DELETE_TOKEN = "DELETE_TOKEN";

//reducer
const initialState = {
    value: null,
    clientId: null,
    data: null
};

export default handleActions(
    {
        [SET_TOKEN]: (state, action) => {
            return {
                ...state,
                value: action.payload.tokenResponse?.accessToken ? `Bearer ${action.payload.tokenResponse.accessToken}` : null,
                data: action.payload.tokenResponse
            };
        },
        [DELETE_TOKEN]: () => {
            const domain = isLocal ? "localhost" : "megazone.io";
            Cookies.remove("token", {
                path: "/",
                domain,
            });
            Cookies.remove("refresh.token", {
                path: "/",
                domain,
            });
            Cookies.remove("token.expires.in", {
                path: "/",
                domain,
            });
            return {
                ...initialState,
            };
        },
    },
    initialState,
);

export const getToken = (domain) => (dispatch, getState) => {
    let { token } = getState();

    if (token?.data) {
        return token.data;
    }
    const tokenFromCookie = Cookies.get("token", { domain });
    const refreshToken = Cookies.get("refresh.token", { domain });
    const expiresIn = Cookies.get("token.expires.in", { domain });
    return {
        accessToken: tokenFromCookie?.startsWith("Bearer ") ? tokenFromCookie.substring("Bearer ".length) : tokenFromCookie,
        refreshToken,
        expiresIn
    }
}

export const setToken = (domain, tokenResponse) => (dispatch, getState) => {
    let { token } = getState();

    if (token.data?.accessToken === tokenResponse?.accessToken) {
        return;
    }
    Cookies.set("token", tokenResponse?.accessToken, {
        expires: 1,
        path: "/",
        domain: domain,
    });
    Cookies.set("refresh.token", tokenResponse?.refreshToken, {
        expires: 1,
        path: "/",
        domain: domain,
    });
    Cookies.set("token.expires.in", tokenResponse?.expiresIn, {
        expires: 1,
        path: "/",
        domain: domain,
    });
    AxiosWrapper.setDefaultHeaders({
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenResponse?.accessToken}`,
    });

    dispatch({
        type: SET_TOKEN,
        payload: {
            tokenResponse
        },
    });
};
export const deleteToken = createAction(DELETE_TOKEN);
