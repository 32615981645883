import { Chip, Grid, Typography, TextField, ListItemButton } from "@mzc-pdc/ui";
import { Autocomplete as MuiAutocomplete } from "@mzc-pdc/ui";
import { IconErrorCircle, IconWarningTriangle } from "@mzc-cloudplex/icons";
import useIntersection from "@hooks/useIntersection";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import theme from "@styles/theme";
import { RandomColoredAvatar } from "@components_v2/avatar/random-colored-avatar";

export const AutocompleteMultipleUsers = (props) => {
    const {
        value,
        open,
        disabled,
        onSubmit,
        onFetchData,
        onChange,
        onInputChange,
        placeholder,
        exceptOptions,
        filterSelectedOptions = false,
        options,
        setOpen,
        error,
        helperText,
        textFieldSx,
        sx,
        handleClose,
    } = props;
    const { setTarget } = useIntersection({
        onIntersect: onFetchData,
    });

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <MuiAutocomplete
                clearOnBlur
                multiple
                freeSolo
                open={open}
                value={value}
                onInputChange={(e, value, reason) => {
                    onInputChange(reason);
                }}
                options={
                    options
                        .filter((option) => !exceptOptions?.find((op) => op.id === option.id))
                        ?.map((option, idx) => ({
                            ...option,
                            lastOption: idx === options.length - 1,
                        })) || []
                }
                filterSelectedOptions={filterSelectedOptions}
                size={`small`}
                disabled={disabled}
                getOptionLabel={(option) => option.name}
                onChange={(e, newValue) => {
                    //option을 선택하고 엔터 입력 이벤트
                    if (newValue && onChange) onChange(newValue);
                }}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                        return (
                            <Chip
                                variant={"outlined"}
                                size={"small"}
                                label={option.name}
                                icon={<RandomColoredAvatar id={option.id} name={option.name} size={20} />}
                                {...getTagProps({
                                    index,
                                })}
                            />
                        );
                    })
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, option, rest, ownerState) => {
                    return (
                        <ListItemButton
                            {...props}
                            key={option.id}
                            // onClick={() => {
                            //     if (onSubmit) onClickOption(option);
                            // }}
                            ref={onFetchData && option.lastOption ? setTarget : null}
                            disabled={rest.selected}
                        >
                            <Grid container gap={"8px"} flexWrap={"nowrap"} pt={`1px`} pb={`1px`}>
                                <Grid item>
                                    <RandomColoredAvatar id={option.id} name={option.name} size={18} />
                                </Grid>
                                <Grid container flexDirection={"column"} sx={{ overflow: `hidden` }}>
                                    <Typography
                                        variant={"body1_400"}
                                        color={theme.palette.secondary[800]}
                                        sx={{ wordWrap: `break-word`, wordBreak: `keep-all` }}
                                    >
                                        {option.name}
                                    </Typography>
                                    <Typography
                                        variant={"body3_400"}
                                        color={theme.palette.secondary[600]}
                                        sx={{ mt: `1px`, wordWrap: `break-word`, wordBreak: `keep-all` }}
                                    >
                                        {option.email}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ListItemButton>
                    );
                }}
                sx={sx}
                renderInput={(params) => {
                    const getEndAdornment = () => {
                        if (error === "warning") return <IconWarningTriangle color={"warning"} size={16} />;
                        else if (error === "error") return <IconErrorCircle color={`error`} size={16} />;

                        return undefined;
                    };

                    return (
                        <>
                            <TextField
                                {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    ...{
                                        endAdornment: getEndAdornment(),
                                    },
                                }}
                                placeholder={placeholder}
                                onClick={(e) => {
                                    setOpen(true);
                                }}
                                onChange={(e) => {
                                    e.stopPropagation();
                                    // const replacedString = e.target.value.replace(/[\n\s\r\t]*/g, "");
                                    // onSubmit(replacedString);
                                    onSubmit(e.target.value); //Note: 유저 이름에 space가 들어가는 경우 검색이 되지 않아 주석처리했습니다. ex) 유 현우
                                    setOpen(true);
                                }}
                                error={error}
                                helperText={
                                    helperText && (
                                        <Grid container gap={1} alignItems={"center"}>
                                            {getEndAdornment()}
                                            {helperText}
                                        </Grid>
                                    )
                                }
                                sx={{
                                    ".MuiInputBase-root": {
                                        paddingTop: `3px !important`,
                                        minHeight: `40px`,
                                    },
                                    ".MuiInputBase-input": {
                                        border: "none",
                                    },
                                    "&::placeholder": {
                                        color: theme.palette.secondary[400],
                                        fontWeight: "normal",
                                    },
                                    ...textFieldSx,
                                }}
                            />
                        </>
                    );
                }}
            />
        </ClickAwayListener>
    );
};
