import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Cookies from "js-cookie";
import { parseQueryParameter } from "../../cores";
import { withRouter } from "react-router";
import Loader from "../../components/loader";
import { LayoutForNoting } from "../../components/layout";
import { isLocal, isDev, isStage } from "@cores/enviromentExtension";
import * as tokenActions from "../../modules/token";

import { axiosWithoutHeaders } from "@cores/axiosWrapper";

import menuService from "@services/menuService";

// SAML Assertion확인 후 받은 code를 JWT로 교환 요청하는 페이지
// 현재는 LX-Hausys 전용인데, 추후 공용으로 빠질 수 있음.

class ExchangeCode extends React.Component {
    componentDidMount() {
        const { history, location, TokenActions, token } = this.props;
        const { search } = location;
        const queryParams = parseQueryParameter(search);
        const code =  queryParams.get("code");
        if (!code) {
            history.replace("/welcome");
            return;
        }
        const cookieDomain = isLocal ? "localhost" : "megazone.io";
        const apiEndpoint = `https://6bwtugkol6.execute-api.ap-northeast-2.amazonaws.com/lxhausys/jwt/exchange`;
        axiosWithoutHeaders
            .post(apiEndpoint, {
                code
            }, {
                headers: {},
            })
            .then((response) => {
                const { token } = response.data;
                if (token) {
                    TokenActions.setToken(cookieDomain, {
                        accessToken: token.substring("Bearer ".length)
                    });
                }

                const beforeLoginUrl = Cookies.get("beforeLogin", {
                    cookieDomain,
                });

                if (beforeLoginUrl) {
                    console.log(beforeLoginUrl);
                    history.replace(beforeLoginUrl);
                } else history.replace(`${menuService.paths.spaces()}`);
            })
            .catch((e) => {
                console.log(`failed to get access token`, e.message);
                TokenActions.deleteToken();
                history.replace("/welcome");
            })
            .finally(() => {
                Cookies.remove("beforeLogin", {
                    domain: cookieDomain,
                });
            });
    }

    render() {
        const { token } = this.props;
        return (
            <LayoutForNoting>
                <Loader />
            </LayoutForNoting>
        );
    }
}
let mapStateToProps = (state) => {
    return {
        token: state.token,
    };
};
let mapDispatchToProps = (dispatch) => {
    return {
        TokenActions: bindActionCreators(tokenActions, dispatch),
    };
};

ExchangeCode = connect(mapStateToProps, mapDispatchToProps)(ExchangeCode);
export default withRouter(ExchangeCode);
