import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import config from "../../config";
import Cookies from "js-cookie";
import { withRouter } from "react-router";
import Loader from "../../components/loader";
import { LayoutForNoting } from "../../components/layout";
import { isLocal, isDev, isStage } from "../../cores/enviromentExtension";
import * as tokenActions from "../../modules/token";

import { axiosWithoutHeaders } from "../../cores/axiosWrapper";

import { sessionKeeper } from "../../cores/sessionKeeper";
import menuService from "@services/menuService";

// OAuth 2.0 Code 플로우 방식으로 코드 요청했을 때 redirect 되어서 들어오게 되는 페이지

class Login extends React.Component {
    componentDidMount() {
        const { history, location, TokenActions, token } = this.props;
        const { search } = location;
        const codes = search.replace("?", "").split("=");
        if (codes[1] === "") {
            history.replace("/welcome");
            return;
        }
        const cookieDomain = isLocal ? "localhost" : "megazone.io";
        const clientId = localStorage.getItem("CLIENT_ID");

        sessionKeeper.set({ clientId });

        const redirectUrl = `&redirectUrl=${window.location.origin}/megazone/login`;
        const apiEndpoint = `${config.host.api}/v3/oauth_token?code=${codes[1]}&clientId=${clientId}${redirectUrl}`;

        axiosWithoutHeaders
            .get(apiEndpoint, {
                headers: {},
            })
            .then((response) => {
                const accessToken = response.headers["access-token"];
                if (accessToken) {
                    TokenActions.setToken(cookieDomain, response.data);
                }

                const beforeLoginUrl = Cookies.get("beforeLogin", {
                    cookieDomain,
                });

                if (beforeLoginUrl) {
                    console.log(beforeLoginUrl);
                    history.replace(beforeLoginUrl);
                } else history.replace(`${menuService.paths.spaces()}`);
            })
            .catch((e) => {
                console.log(`failed to get access token`, e.message);
                TokenActions.deleteToken();
                history.replace("/welcome");
            })
            .finally(() => {
                Cookies.remove("beforeLogin", {
                    domain: cookieDomain,
                });
            });
    }

    render() {
        return (
            <LayoutForNoting>
                <Loader />
            </LayoutForNoting>
        );
    }
}
let mapStateToProps = (state) => {
    return {
        token: state.token,
    };
};
let mapDispatchToProps = (dispatch) => {
    return {
        TokenActions: bindActionCreators(tokenActions, dispatch),
    };
};

Login = connect(mapStateToProps, mapDispatchToProps)(Login);
export default withRouter(Login);
