import { IconButton, MenuItem, Select, Stack, theme, Typography } from "@mzc-pdc/ui";
import { RandomColoredAvatar } from "@components_v2/avatar/random-colored-avatar";
import { useTranslation } from "react-i18next";
import { IconCancel } from "@mzc-cloudplex/icons";

const ApprovedUserTeamItem = ({
    drawBorderBottom,
    userOrTeam,
    onHandleChangeAction,
    onHandleDeleteUserTeam,
    options,
}) => {
    const { t } = useTranslation();
    return (
        <Stack
            direction={"row"}
            container
            justifyContent={"space-between"}
            sx={{
                p: theme.spacing(1),
                borderBottom: drawBorderBottom ? `1px solid ${theme.palette.divider}` : undefined,
                width: "100%",
                pointerEvents: "auto",
            }}
        >
            <Stack direction={"row"} alignItems={"center"} flex={2.2} gap={theme.spacing(1)}>
                <RandomColoredAvatar id={userOrTeam.id} name={userOrTeam.name} size={18} />
                <Typography
                    variant={`body1_500`}
                    sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        maxWidth: "100px",
                        textWrap: "nowrap",
                    }}
                >
                    {userOrTeam.name}
                </Typography>
                <Typography variant={`body3_400`} color={theme.palette.greySecondary[600]}>
                    {userOrTeam.email}
                </Typography>
                {/*<Typography variant={`body3_400`} color={theme.palette.warning.main}>*/}
                {/*    {t(`common::label::Expired`, `Expired`)}*/}
                {/*</Typography>*/}
            </Stack>
            <Stack direction={"row"} alignItems={"center"} flex={1} gap={theme.spacing(1)}>
                <Select
                    fullWidth
                    value={userOrTeam.actions}
                    onChange={(e) => {
                        e.stopPropagation();
                        onHandleChangeAction(userOrTeam, e.target.value);
                    }}
                    onClick={(e) => e.stopPropagation()}
                    size={"small"}
                    renderValue={(value) => {
                        const foundOption = options.find((option) => option.value === value);

                        return (
                            <Typography variant={"body1_400"}>
                                {t(`common::label::${foundOption.label}`, `${foundOption.label}`)}
                            </Typography>
                        );
                    }}
                >
                    {options.map((option) => (
                        <MenuItem value={option.value}>
                            {t(`common::label::${option.label}`, `${option.label}`)}
                        </MenuItem>
                    ))}
                </Select>
                <IconButton
                    circled
                    size={"small"}
                    onClick={(e) => {
                        e.stopPropagation();
                        onHandleDeleteUserTeam(userOrTeam);
                    }}
                >
                    <IconCancel size={12} />
                </IconButton>
            </Stack>
        </Stack>
    );
};

export default ApprovedUserTeamItem;
