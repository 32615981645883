import React, { useEffect, useState, useCallback } from "react";
import { withTranslation } from "react-i18next";
import { Avatar } from "@mui/material";
import { IconAvata, IconUser } from "@mzc-cloudplex/icons";
import TooltipUserInfo from "../tooltip/userInfo";
import ScrollArea from "../ScrollArea";

const SharedWithView = (props) => {
    const { t, item } = props;

    return (
        <React.Fragment>
            <div className="control">
                <div className="justify-content-end">
                    <div>
                        {t(`common::label::${"Total"}`)}{" "}
                        <strong className="text-primary">{item?.recipientSummary.totalCount}</strong>
                    </div>
                </div>
            </div>
            <ScrollArea>
                <div className="list-shared-with">
                    <ul>
                        {item?.sharedTo?.map((recipient) => {
                            return (
                                <li key={recipient.externalId}>
                                    <div className="info">
                                        <span className="thumbnail">
                                            <IconAvata size={18} color={"success"} />
                                        </span>
                                        <div>
                                            {recipient.externalId ? (
                                                <>
                                                    <p>{recipient.name || "-"}</p>
                                                    <div className="d-flex align-items-center">
                                                        <small className="text-secondary">
                                                            {recipient.email || "-"}
                                                        </small>
                                                        <TooltipUserInfo target={recipient} id={recipient.externalId} />
                                                    </div>
                                                </>
                                            ) : (
                                                <small>{recipient.email}</small>
                                            )}
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </ScrollArea>
        </React.Fragment>
    );
};
export default withTranslation()(SharedWithView);
