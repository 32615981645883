import { axios } from "../../cores/axiosWrapper";

const ContentSchemasOldApi = {
    getContentSchemasAsync: async function (stageId, apiEndpoint, projectId, params) {
        ////TODO MUSIC 작업 중
        return axios.get(`${apiEndpoint}/content-schemas`, {
            params,
            headers: {
                stageId,
                projectId,
            },
        });
    },
};

export default ContentSchemasOldApi;
