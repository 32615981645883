import { useState, useCallback, useRef, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loadable from "@react-loadable/revised";
import { useDispatch, useSelector } from "react-redux";

/* icons */
import { IconApps, IconOpenInNew, IconSpaceAdmin, ImageLogoHyperBrowser } from "@mzc-cloudplex/icons";

/* types */
import { IconButton, ListItemIcon, Grid, Typography, Divider, List, Popover } from "@mzc-pdc/ui";

import theme from "@styles/theme";

/* styled */
import { MenuButton, ToggleList, SvgWrap, ListItemButton, ListSubheader, ListItemText } from "./start-menu.styled";
import ReactDOM from "react-dom";
import menuService from "@services/menuService";
import { siteMode } from "@cores/siteMode";
import { resetProjectPermissions } from "@modules/rolesManagement_v2";

const LoadableModelForAppDown = Loadable({
    loader: () => import("@components/modals/AppDownload"),
    loading: () => null,
});

const StartMenu = ({ headerTheme }) => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { pathname } = useLocation();

    const user = useSelector(({ user }) => user);

    const [startModalActive, setStartModalActive] = useState(false);
    const [activeAppDownModal, setActiveAppDownModal] = useState(false);
    const [activeStages, setActiveStages] = useState(null);

    const anchorRef = useRef(null);

    useEffect(() => {
        if (user?.stages?.data) {
            const activeStages = user.stages.data.filter((v) => v.user?.isSpaceAdmin);
            if (activeStages) {
                setActiveStages(activeStages);
            }
        }
    }, [user]);

    const handleToggle = useCallback(() => {
        setStartModalActive((prevOpen) => !prevOpen);
    }, []);

    const handleClose = useCallback((event) => {
        setStartModalActive(false);
    }, []);

    const onHandleKeydownModal = useCallback((event) => {
        const { key } = event;
        if (key === "Tab") {
            event.preventDefault();
            setStartModalActive(false);
        }
    }, []);

    const onHandleClickStartPage = useCallback(() => {
        history.replace("/spaces");
        setStartModalActive(false);
        dispatch(resetProjectPermissions());
    }, []);

    const closeAppDownModal = () => {
        setActiveAppDownModal(false);
    };

    const onClickAdminMenu = (spaceId) => {
        history.push(menuService.paths.admin.settings(spaceId));
    };

    return (
        <>
            <IconButton
                size={"large"}
                circled
                ref={anchorRef}
                onClick={handleToggle}
                disabled={location.pathname === "/spaces"}
            >
                <IconApps
                    size={20}
                    htmlColor={
                        headerTheme?.color?.textColor ? headerTheme?.color?.textColor : theme.palette.secondary[500]
                    }
                    sx={{ opacity: 0.48 }}
                />
            </IconButton>
            <Popover
                open={startModalActive}
                anchorEl={anchorRef.current}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                PaperProps={{
                    style: { width: "280px", marginLeft: -16 },
                }}
            >
                <>
                    <ToggleList autoFocusItem={startModalActive} id="menu-list-grow" onKeyDown={onHandleKeydownModal}>
                        {location.pathname !== "/spaces" && (
                            <MenuButton
                                fullWidth
                                color={`inherit`}
                                endIcon={
                                    <SvgWrap>
                                        <IconOpenInNew size={16} />
                                    </SvgWrap>
                                }
                                onClick={onHandleClickStartPage}
                            >
                                {t(`common::label::Space List`, `Space List`)}
                            </MenuButton>
                        )}
                    </ToggleList>
                    <Divider />

                    {siteMode.get() !== "CMS" &&
                        activeStages?.map((stage, index) => (
                            <List
                                key={index}
                                subheader={
                                    <ListSubheader component="div">
                                        <Typography variant={"body4_700"}>
                                            {t(`common::label::Switch Admin`)}
                                        </Typography>
                                    </ListSubheader>
                                }
                            >
                                <ListItemButton
                                    onClick={(e) => {
                                        onClickAdminMenu(stage.id);
                                    }}
                                >
                                    <ListItemIcon>
                                        <Grid
                                            container
                                            alignItems={"center"}
                                            justifyContent={"center"}
                                            sx={{
                                                width: 40,
                                                height: 40,
                                                backgroundColor: theme.palette.secondary.dark,
                                                borderRadius: 1,
                                            }}
                                        >
                                            <IconSpaceAdmin
                                                size={20}
                                                htmlColor={theme.palette.secondary.contrastText}
                                            />
                                        </Grid>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={t(`common::label::Administration`)}
                                        secondary={stage.name}
                                        primaryTypographyProps={{
                                            variant: "body1_500",
                                            component: "strong",
                                        }}
                                    />
                                </ListItemButton>
                            </List>
                        ))}

                    <List
                        subheader={
                            <ListSubheader component="div">
                                <Typography variant={"body4_700"}>{t(`common::label::App Download`)}</Typography>
                            </ListSubheader>
                        }
                    >
                        <ListItemButton
                            onClick={() => {
                                setActiveAppDownModal(!activeAppDownModal);
                                handleClose();
                            }}
                        >
                            <ListItemIcon>
                                <ImageLogoHyperBrowser width={40} height={40} />
                            </ListItemIcon>
                            <ListItemText primary={t(`common::label::Hyper Browser`)} secondary={"1.3"} />
                        </ListItemButton>
                    </List>
                </>
            </Popover>

            {activeAppDownModal &&
                ReactDOM.createPortal(<LoadableModelForAppDown onClose={closeAppDownModal} />, document.body)}
        </>
    );
};

export default StartMenu;
