import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

const ContentWrapper = React.forwardRef((props, ref) => {
    const { className, ...otherProps } = props;
    return (
        <div
            {...otherProps}
            ref={ref}
            className={clsx("content-wrapper", className)}
        />
    );
});
ContentWrapper.propTypes = {
    className: PropTypes.string,
};

export default ContentWrapper;
