//TODO file name 변경필요
//TODO listingsCreate -> listingCreate
//TODO active 사용하지 않음.
import {createAction, handleActions} from 'redux-actions';
import {
    axios,
    axiosWithoutHeaders
} from "../cores/axiosWrapper";
import tryParse from '../modules/settingParser';

function postListingsAPI(stageId, apiEndpoint, projectId, params) {
    const postData = {
        ...params,
        project:{
            "id": projectId
        }
    }
    return axios.post(`${apiEndpoint}/listings`, postData, {
        headers : {
            stageId,
            projectId
        }
    });
}

function getListingDefaultSettingAPI(stageId, apiEndpoint, projectId) {
    return axios.get(`${apiEndpoint}/settings`, {
        headers : {
            stageId,
            projectId
        }
    });
}

//action type
const LISTINGS_PENDING = 'LISTINGS_CREATE_PENDING';
const LISTINGS_FAILURE = 'LISTINGS_CREATE_FAILURE';
//create
const UPDATE_CREATE_LISTINGS = 'UPDATE_CREATE_LISTINGS';
const TOGGLE_CREATE_LISTINGS_STREAM = 'TOGGLE_CREATE_LISTINGS_STREAM';
const ADD_CREATE_LISTINGS_STREAM = 'ADD_CREATE_LISTINGS_STREAM';
const UPDATE_CREATE_LISTINGS_STREAM = 'UPDATE_CREATE_LISTINGS_STREAM';
const GET_CREATE_LISTINGS_STREAM_SUCCESS = 'GET_CREATE_LISTINGS_STREAM_SUCCESS';
const DELETE_CREATE_LISTINGS_STREAM = 'DELETE_CREATE_LISTINGS_STREAM';
const CLOSE_CREATE_LISTINGS_STREAM_LAYERS = 'CLOSE_CREATE_LISTINGS_STREAM_LAYERS';
const UPDATE_CREATE_LISTINGS_FILE_SOURCE_FLAGS = 'UPDATE_CREATE_LISTINGS_FILE_SOURCE_FLAGS';
const UPDATE_CREATE_LISTINGS_BASEVIDEOURL = 'UPDATE_CREATE_LISTINGS_BASEVIDEOURL';
const UPDATE_CREATE_LISTINGS_TIMEREMAININGOPTION = 'UPDATE_CREATE_LISTINGS_TIMEREMAININGOPTION';
const UPDATE_CREATE_LISTINGS_RESET = 'UPDATE_CREATE_LISTINGS_RESET';
const UPDATE_CREATE_LISTINGS_IS_SAVEABLE = 'UPDATE_CREATE_LISTINGS_IS_SAVEABLE';
const POST_CREATE_LISTINGS_SUCCESS = 'POST_CREATE_LISTINGS_SUCCESS';
const RESET_CREATE_LISTINGS_STREAM = 'RESET_CREATE_LISTINGS_STREAM';
const GET_LISTING_DEFAULT_SETTING = 'GET_LISTING_DEFAULT_SETTING';
const LISTING_SET_VALIDATION_ERROR = 'LISTING_SET_VALIDATION_ERROR';

const initialState = {
    id : '',
    pending: false,
    error: false,
    name : '',
    baseVideoUrl: '',
    timeRemainingOption : 'NONE',
    data : null,
    fileSourceFlags : [],
    streams : [],
    isSaveable : false,
    isIndividualSettings : false,
    isLoopToFillLineupDuration : true,
    defaultSetting: null,
    validationErrors : {}
};

export default handleActions({
    [LISTINGS_PENDING]:(state, action) => {
        return {
            ...state,
            pending:true,
            error:false
        }
    },
    [LISTINGS_FAILURE]:(state, action) => {
        return {
            ...state,
            pending:false,
            error:true
        }
    },
    [UPDATE_CREATE_LISTINGS]:(state, action) => {
        return {
            ...state,
            ...action.payload
        }
    },
    [TOGGLE_CREATE_LISTINGS_STREAM] :(state, action) => {
        return {
            ...state,
            streams: state.streams.map((v, i) => {
                return {
                    ...v,
                    active: i === action.payload ? !v.active : v.active
                }
            })
        };
    },
    [ADD_CREATE_LISTINGS_STREAM]:(state) => {
        return {
            ...state,
            streams: [
                ...state.streams,
                {
                    active : false
                }
            ]
        }
    },
    [UPDATE_CREATE_LISTINGS_STREAM]:(state, action) => {
        const {stream, index} = action.payload;

        return {
            ...state,
            streams: state.streams.map((v, i) => {
                if (i === index) {
                    return {
                        ...v,
                        ...stream,
                        selected:true,
                        active : false
                    }
                } else {
                    return {
                        ...v,
                        selected:false,
                        active : false
                    };
                }
            })
        }
    },
    [GET_CREATE_LISTINGS_STREAM_SUCCESS]: (state, action) => {
        const {streams} = action.payload.data;
        return {
            ...state,
            pending: false,
            error:false,
            data: streams
        }
    },
    [GET_LISTING_DEFAULT_SETTING]: (state, action) => {
        const data = { 
            isLoopToFillLineupDuration:  tryParse(action.payload.data, "Listing", "isLoopToFillLineupDuration", 3),
            timezone: tryParse(action.payload.data, "Listing", "timezone", 3)
        };
        
        return {
            ...state,
            pending: false,
            error: false,
            defaultSetting: data
        }
    },
    [DELETE_CREATE_LISTINGS_STREAM]: (state, action) => {
        let _streams = Object.assign([], state.streams);

        _streams.splice(action.payload, 1);

        return {
            ...state,
            streams : _streams
        }
    },
    [CLOSE_CREATE_LISTINGS_STREAM_LAYERS]:(state) => {
        return {
            ...state,
            streams: state.streams.map(v => {
                return {
                    ...v,
                    active: false
                }
            })
        };
    },
    [UPDATE_CREATE_LISTINGS_FILE_SOURCE_FLAGS]:(state, action) => {
        return {
            ...state,
            fileSourceFlags : action.payload
        }
    },
    [UPDATE_CREATE_LISTINGS_BASEVIDEOURL]:(state, action) => {
        return {
            ...state,
            baseVideoUrl: action.payload
        };
    },
    [UPDATE_CREATE_LISTINGS_TIMEREMAININGOPTION] :(state, action) => {
        return {
            ...state,
            timeRemainingOption: action.payload
        };
    },
    [UPDATE_CREATE_LISTINGS_IS_SAVEABLE] : (state) => {
        const hasInputSources = (state.streams.length > 0 && state.streams.filter(v => v.id).length > 0) || state.fileSourceFlags.length > 0;

        return {
            ...state,
            isSaveable: state.name && state.name.length > 0 && hasInputSources
        };
    },  
    [UPDATE_CREATE_LISTINGS_RESET]:(state) => {
        return {
            id : '',
            pending: false,
            error: false,
            name : '',
            baseVideoUrl: '',
            timeRemainingOption : 'NONE',
            data : null,
            fileSourceFlags : [],
            streams : [],
            isSaveable : false,
            isIndividualSettings : false,
            isLoopToFillLineupDuration : true,
            defaultSetting: null,
            validationErrors : {}
        }
    },
    [POST_CREATE_LISTINGS_SUCCESS]:(state) => {
        return {
            ...state,
            pending:false,
            error:false
        }
    },
    [RESET_CREATE_LISTINGS_STREAM]:(state, action) => {
        return {
            ...state,
            streams : state.streams.map(v => {
                if(v.id === action.payload) {
                    return {
                        ...v,
                        id : ''
                    }
                } else {
                    return v;
                }
            })
        }
    },
    [LISTING_SET_VALIDATION_ERROR] : (state, action) => {
        const {
            key,
            error
        } = action.payload;

        if(error) {
            return {
                ...state,
                validationErrors : {
                    ...state.validationErrors,
                    [key] : error
                }
            };
        } 

        const validationErrors = state.validationErrors;
        if(validationErrors[key]) {
            delete validationErrors[key];
        }

        return {
            ...state,
            validationErrors
        };        
    }
    
}, initialState);
export const updateCreateListings = createAction(UPDATE_CREATE_LISTINGS);
export const addCreateListingsStream = createAction(ADD_CREATE_LISTINGS_STREAM);
export const updateCreateListingsStream = createAction(UPDATE_CREATE_LISTINGS_STREAM);
export const toggleCreateListingsStream = createAction(TOGGLE_CREATE_LISTINGS_STREAM);
export const deleteCreateListingsStream = createAction(DELETE_CREATE_LISTINGS_STREAM);
export const closeCreateListingsStreamLayers = createAction(CLOSE_CREATE_LISTINGS_STREAM_LAYERS);
export const updateCreateListingsFileSourceFlages = createAction(UPDATE_CREATE_LISTINGS_FILE_SOURCE_FLAGS);
export const updateCreateListingsBaseVideoUrl = createAction(UPDATE_CREATE_LISTINGS_BASEVIDEOURL);
export const updateCreateListingsTimeRemainingOption = createAction(UPDATE_CREATE_LISTINGS_TIMEREMAININGOPTION);
export const updateCreateListingsReset = createAction(UPDATE_CREATE_LISTINGS_RESET);
export const updateCreateListingsIsSaveable = createAction(UPDATE_CREATE_LISTINGS_IS_SAVEABLE);
export const resetStream = createAction(RESET_CREATE_LISTINGS_STREAM);
export const setValidationError = createAction(LISTING_SET_VALIDATION_ERROR);
export const postCreateListings = (params, callback) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    dispatch({type: LISTINGS_PENDING});
    
    return postListingsAPI(stage.id, stage.endpoint, project.id, params).then(
        (response) => {
            dispatch({type: POST_CREATE_LISTINGS_SUCCESS});
            callback(response.data);
        }
    ).catch(error => {
        dispatch({
            type: LISTINGS_FAILURE,
            payload: error
        });
    });
};

export const getListingDefaultSetting = () => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();
    
    if(stage.id === '') {
        return;
    }

    dispatch({type: LISTINGS_PENDING});
    
    return getListingDefaultSettingAPI(stage.id, stage.endpoint, project.id).then(
        (response) => {
            dispatch({
                type : GET_LISTING_DEFAULT_SETTING,
                payload : response
            })
        }
    ).catch(error => {
        dispatch({
            type: LISTINGS_FAILURE,
            payload: error
        });
    });
};