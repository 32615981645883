import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mzc-pdc/ui";
import theme from "@styles/theme";
import { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { setNotification } from "@modules/notification";
import { useDispatch } from "react-redux";
import { IconWarningTriangle } from "@mzc-cloudplex/icons";

const DialogDeleteV2 = ({
    items,
    title,
    contents,
    bulkDelete = false,
    loopDelete = false,
    onClickSubmit,
    onClickClose,
    afterMutation,
    cannotDelete,
    deleteButtonLabel,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [currentIndex, setCurrentIndex] = useState(0);
    const [skipNotify, setSkipNotify] = useState(loopDelete);
    const [actionPending, setActionPending] = useState(false);

    const onHandleClickDelete = async () => {
        try {
            setActionPending(true);

            if (bulkDelete) {
                await onClickSubmit(items);
                afterMutation && afterMutation(items);
                onClickClose();
            } else {
                if (skipNotify) {
                    await Promise.all(
                        items.slice(currentIndex).map(async (item) => {
                            try {
                                await onClickSubmit(item);
                            } catch (error) {
                                Promise.reject(error);
                            }
                        }),
                    );
                    //너무 많으면 promise.all()이 뻗을수있으므로 너무 많은 경우 잘라서 보내게 처리할 것
                    afterMutation && afterMutation(items);
                    onClickClose();
                } else {
                    await onClickSubmit(items[currentIndex]);

                    if (currentIndex + 1 === items.length) {
                        afterMutation && afterMutation(items);
                        onClickClose();
                    } else {
                        setCurrentIndex(currentIndex + 1);
                    }
                }
            }

            setActionPending(false);
        } catch (error) {
            dispatch(
                setNotification({
                    type: "error",
                    contents: error.response
                        ? `[Error:${error.response.status}] => ${
                              error.response?.data?.message ?? "There was an error deleting custom contents"
                          }`
                        : `Internal Error Occured`,
                }),
            );
            console.error(error);
            setActionPending(false);
        }
    };

    const checkIndividualDelete = useMemo(() => {
        return !bulkDelete && !loopDelete;
    }, [loopDelete, bulkDelete]);

    return (
        <Dialog variant={"basic"} open={true}>
            <DialogTitle>
                {title
                    ? title
                    : t(
                          `common::label::Delete ${items.length > 1 ? "these items" : "the item"}?`,
                          `Delete ${items.length > 1 ? "these items" : "the item"}?`,
                      )}
            </DialogTitle>
            <DialogContent
                children={
                    <Grid container>
                        {checkIndividualDelete && items.length > 1 && (
                            <Grid container flexDirection={"row"} mb={1}>
                                <IconWarningTriangle size={20} color={"warning"} />
                                <Typography variant={"body1_400"} color={theme.palette.secondary[800]}>
                                    <Trans
                                        i18nKey={`common::msg::Deleting {{currentIndex}} of {{deleteItemLength}}`}
                                        values={{
                                            currentIndex: currentIndex + 1,
                                            deleteItemLength: items.length,
                                        }}
                                    />
                                </Typography>
                                <br />
                            </Grid>
                        )}
                        {currentIndex < items.length && contents(items[currentIndex], items.length)}
                    </Grid>
                }
                sx={{
                    "&&": {
                        scrollbarGutter: "auto",
                    },
                }}
            />
            <DialogActions>
                <Grid container sx={{ flexGrow: 1, flexDirection: "row", alignItems: "center" }}>
                    {checkIndividualDelete && items.length > 1 && currentIndex + 1 < items.length && (
                        <>
                            <Checkbox
                                color={"greySecondary"}
                                disabled={actionPending}
                                checked={skipNotify}
                                onChange={(e) => setSkipNotify(!skipNotify)}
                            />
                            <Typography variant={"span"}>{t(`common::msg::Don’t ask again`)}</Typography>
                        </>
                    )}
                </Grid>
                <Grid container justifyContent={"flex-end"} gap={0.75}>
                    <Button variant={"text"} color={`grey`} onClick={() => onClickClose()}>
                        {t("common::label::Cancel")}
                    </Button>
                    {cannotDelete ? (
                        <Button
                            variant={`contained`}
                            onClick={(e) => {
                                e.stopPropagation();
                                onClickClose();
                            }}
                            color={`primary`}
                        >
                            {t("common::label::OK")}
                        </Button>
                    ) : (
                        <Button
                            variant={`contained`}
                            disabled={actionPending}
                            loading={actionPending}
                            onClick={(e) => {
                                e.stopPropagation();
                                onHandleClickDelete();
                            }}
                            color={`error`}
                        >
                            {deleteButtonLabel ? deleteButtonLabel : t("common::label::Delete")}
                        </Button>
                    )}
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default DialogDeleteV2;
