import { Button, Grid } from "@mzc-pdc/ui";

export const PageHeader = ({ breadcrumb, prev, title, hideInfo, info, actions, sx }) => {
    return (
        <>
            {breadcrumb}
            <Grid container flexDirection={"row"} sx={sx}>
                <Grid item xs mr={1}>
                    <Grid container mt={0.75} mb={1.5} flexWrap={"nowrap"} alignItems={"stretch"}>
                        {prev && (
                            <Grid item sx={{ maxHeight: "50px", mt: 1 }}>
                                {prev}
                            </Grid>
                        )}
                        <Grid container alignItems={"center"}>
                            {title && title}
                            {prev && !hideInfo && (
                                <Button variant={"solid"} color={"primary"} sx={{ minWidth: 0, ml: 1.5 }}>
                                    Info
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sx={{ mt: 0.75 }}>
                    {actions && <Grid item>{actions}</Grid>}
                </Grid>
            </Grid>
        </>
    );
};
