import React, { useMemo } from "react";
import { Grid, ListItem, TextField, Typography } from "@mzc-pdc/ui";
import { Autocomplete as MuiAutocomplete } from "@mui/material";
import { IconAvata } from "@mzc-cloudplex/icons";
import useIntersection from "@hooks/useIntersection";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import theme from "@styles/theme";

export const AutocompleteUsers = (props) => {
    const {
        value,
        open,
        disabled,
        setValue = () => {},
        onSubmit = () => {},
        onFetchData,
        onClickOption,
        onInputChange = () => {},
        placeholder,
        options,
        setOpen,
        sx,
        isEdit,
        handleClose = () => {},
        setSelectedUser = () => {},
        isAvatarIconRequired,
    } = props;
    const { setTarget } = useIntersection({
        onIntersect: onFetchData,
    });

    const getStartAdornment = useMemo(() => {
        if (!isAvatarIconRequired && !value) return undefined;
        return (
            <Grid item ml={theme.spacing(1)}>
                <IconAvata size={20} htmlColor={"#83839a"} />
            </Grid>
        );
    }, [isAvatarIconRequired, value]);

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <MuiAutocomplete
                key={options.length}
                freeSolo //Note : freesolo를 사용시 옵션과는 상관없는 값을 사용할 수 있게 되서 noOptionsText를 사용불가능(옵션 사용하는 경우 false 사용해야함)
                clearOnBlur
                open={open}
                value={value}
                onInputChange={(e, value, reason) => {
                    onInputChange(reason);
                }}
                options={
                    options?.map((option, idx) => ({
                        ...option,
                        id: option.id ?? option.title,
                        lastOption: idx === options.length - 1,
                    })) || []
                }
                //page와 size에 따른 스크롤 무한로딩
                PopperComponent={null}
                // ListboxComponent={Listbox}
                size={`small`}
                disabled={disabled}
                getOptionLabel={(option) => {
                    if (typeof option === "string") return option;
                    return option && (option?.name ?? "");
                }}
                onChange={(e, newValue) => {
                    //option을 선택하고 엔터 입력 이벤트
                    if (newValue && onSubmit) onClickOption(newValue);
                }}
                renderOption={(props, option) => {
                    return (
                        <ListItem
                            {...props}
                            key={option.id}
                            onClick={() => {
                                if (onSubmit) onClickOption(option);
                            }}
                            ref={onFetchData && option.lastOption ? setTarget : null}
                        >
                            <Grid container gap={"8px"} flexWrap={"nowrap"} pt={`1px`} pb={`1px`}>
                                <Grid item>
                                    <IconAvata size={18} htmlColor={"#83839a"} />
                                </Grid>
                                <Grid container flexDirection={"column"} sx={{ overflow: `hidden` }}>
                                    <Typography
                                        variant={"body1_400"}
                                        color={theme.palette.secondary[800]}
                                        sx={{ wordWrap: `break-word`, wordBreak: `keep-all` }}
                                    >
                                        {option.name}
                                    </Typography>
                                    <Typography
                                        variant={"body3_400"}
                                        color={theme.palette.secondary[600]}
                                        sx={{ mt: `1px`, wordWrap: `break-word`, wordBreak: `keep-all` }}
                                    >
                                        {option.email}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ListItem>
                    );
                }}
                renderInput={(params) => {
                    return (
                        <>
                            <TextField
                                {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: getStartAdornment,
                                }}
                                placeholder={placeholder}
                                onClick={(e) => {
                                    setOpen(true);
                                }}
                                onChange={(e) => {
                                    e.stopPropagation();
                                    // const replacedString = e.target.value.replace(/[\n\s\r\t]*/g, "");
                                    setSelectedUser(null);
                                    setValue(e.target.value);
                                    onSubmit(e.target.value);
                                    setOpen(true);
                                }}
                                isEdit={isEdit}
                                sx={{
                                    ".MuiInputBase-root": {
                                        paddingTop: `3px !important`,
                                        minHeight: `40px`,
                                    },
                                    ".MuiInputBase-input": {
                                        border: "none",
                                    },
                                    "&::placeholder": {
                                        color: theme.palette.secondary[400],
                                        fontWeight: "normal",
                                    },
                                    ...sx,
                                }}
                            />
                        </>
                    );
                }}
                // open={false} //option dropdown 비활성화
            />
        </ClickAwayListener>
    );
};
