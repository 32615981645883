import {
    Button,
    Chip,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    Box,
    IconButton,
    Tooltip,
    Stack,
    PDCAlpha as alpha,
} from "@mzc-pdc/ui";
import { useState } from "react";
import Typography from "@components_v2/typography/typography";
import { useTranslation } from "react-i18next";
import theme from "@styles/theme";
import { useDispatch, useSelector } from "react-redux";
import convertUtcToLocal from "@cores/convertUtcToLocal";
import useUserDetail from "@hooks/useUserDetail";
import UserDetailPopper from "src/features/user-detail-popper/user-detail-popper";
import AutocompleteTableList from "@features/autocomplete-table/autocomplete-table-list";
import getByteLength from "@cores/getByteLength";
import EditSubmitButtonGroup from "@components_v2/edit-submit-button-group/edit-submit-button-group";
import { IconEditSmall, ImageFolderSmallOn } from "@mzc-cloudplex/icons";
import TextField from "@components_v2/text-field/text-field";
import spliceByByteLength from "@cores/spliceByByteLength";
import TagsInput from "@features/tags-input/tags-input";
import equal from "deep-equal";
import menuService from "@services/menuService";
import { FOLDER_VIEW_DOMAINS, LIMIT_BYTE } from "@constants";
import SelectFolderPathModal from "@components/modals/SelectFolderPath";
import { capitalize } from "@utils/convert-string";
import useDialog from "@hooks/useDialog";
import EditButton from "@components_v2/edit-button/edit-button";
import { useHistory } from "react-router-dom";

//panel type
// text: edit 안할 시 일반 Typography + edit시 input
// textarea: edit 안할 시 일반 Typography + edit시 textarea
// date: edit안할 시 일반 Typography와 유저 조회 버튼 + edit 불가
// tags: 태그 전용 컴포넌트
// listView: edit안할 시 컬렉션 목록 + edit시 collection autocomplete

const PanelDetailsView = ({ id, domain, data, actions, afterMutation }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const {
        handleUserProfileClick,
        handleUserProfileClose,
        userProfileAnchorEl,
        openUserProfile,
        userProfileData,
        userProfilePending,
    } = useUserDetail();

    const { dialog, openDialog, closeDialog } = useDialog();

    const [editValue, setEditValue] = useState({});
    const [editValueActive, setEditValueActive] = useState({});
    const [actionPending, setActionPending] = useState({});

    const folderList = useSelector(({ folderTreeView }) => folderTreeView.folderList?.data);
    const project = useSelector(({ project }) => project);
    const stage = useSelector(({ stage }) => stage);

    const onHandleClickEditCollection = async (type) => {
        try {
            setActionPending({ ...actionPending, [type]: true });
            await dispatch(actions.updateCollection({ id: id, params: { [type]: editValue[type] } }));

            setEditValueActive({ ...actionPending, [type]: undefined });
        } catch (error) {
            console.log(error);
        } finally {
            setActionPending({ ...actionPending, editName: undefined });
        }
    };

    const onHandleClickEditCollectionAction = async (type, originalValue) => {
        try {
            let updateData = {};

            setActionPending({ ...actionPending, [type]: true });

            //추가, 제거 따로 2번 호출해야함
            if (type === "tags") {
                const editTags = editValue.tags.map((tag) => tag.value);
                const addedTags = editTags.filter((tag) => !originalValue.includes(tag));
                const removedTags = originalValue.filter((tag) => !editTags.includes(tag));

                if (addedTags.length > 0)
                    await dispatch(
                        actions.updateCollectionAction({
                            contentId: id,
                            params: { action: "ADD_TAGS", data: { tags: addedTags } },
                        }),
                    );
                if (removedTags.length > 0)
                    await dispatch(
                        actions.updateCollectionAction({
                            contentId: id,
                            params: { action: "REMOVE_TAGS", data: { tags: removedTags } },
                        }),
                    );
                updateData["tags"] = editTags;
            } else if (type === "collection") {
                const editCollections = editValue.collection.map((collection) => collection.id);
                const originalCollections = originalValue.map((collection) => collection.id);
                const addedCollections = editCollections.filter(
                    (collection) => !originalCollections?.find((value) => value.id === collection.id),
                );
                const removedCollections = originalCollections?.filter(
                    (collectionId) => !editCollections?.find((value) => value.id === collectionId),
                );

                if (addedCollections.length > 0)
                    await dispatch(
                        actions.updateCollectionAction({
                            contentId: id,
                            params: { action: "ADD_LINKED_COLLECTION", data: { collectionIds: addedCollections } },
                        }),
                    );
                if (removedCollections.length > 0)
                    await dispatch(
                        actions.updateCollectionAction({
                            contentId: id,
                            params: { action: "REMOVE_LINKED_COLLECTION", data: { collectionIds: removedCollections } },
                        }),
                    );
                updateData["linkedCollections"] = editValue.collection;
            }
            setEditValueActive({ ...actionPending, [type]: undefined });
            afterMutation && afterMutation(updateData);
        } catch (error) {
            console.log(error);
        } finally {
            setActionPending({ ...actionPending, [type]: undefined });
        }
    };

    const getFolderLocation = (value) => {
        if (!value || !folderList) return;
        let newFolderLocation = [];
        let currentFolder = folderList[value.virtualFolder.id];

        while (!!currentFolder) {
            newFolderLocation.push(currentFolder);
            currentFolder = folderList[currentFolder.parent];
        }
        return newFolderLocation
            .reverse()
            .map((folder) => folder.name)
            .join("/");
    };

    const getFolderName = (value) => {
        if (!value?.virtualFolder?.id || !folderList) return;
        let newFolderLocation = [];
        let currentFolder = folderList[value.virtualFolder.id];

        return currentFolder?.name ?? "";
    };

    const onHandleClickFolderButton = (value) => {
        if (!stage || !project || !value || !domain) return;

        let endpoint = "";
        switch (domain) {
            case FOLDER_VIEW_DOMAINS.COLLECTIONS:
                endpoint = menuService.paths.assetsCollections(stage.id, project.id);
        }

        history.push(`${endpoint}?virtualDriveId=${value.virtualDrive.id}&virtualFolderId=${value.virtualFolder.id}`);
    };

    const getComponentByType = ({ type, subtitle, isEditable, value, onSubmitEditValue }) => {
        if (type === "text") {
            return (
                <>
                    <ListItemText
                        disableTypography
                        primary={
                            <Typography variant={"body2"} color={theme.palette.greySecondary[600]} gutterBottom>
                                {t(`common::label::${subtitle}`)}
                            </Typography>
                        }
                        secondary={
                            <Grid item sx={{ mt: 1 }}>
                                <Typography
                                    variant={"body1_400"}
                                    component={"span"}
                                    sx={{ color: theme.palette.secondary[800] }}
                                >
                                    {value}
                                </Typography>
                            </Grid>
                        }
                    />
                </>
            );
        } else if (type === "date") {
            return (
                <ListItemText
                    disableTypography
                    primary={
                        <Typography variant={"body2"} color={theme.palette.greySecondary[600]} gutterBottom>
                            {t(`common::label::${subtitle}`)}
                        </Typography>
                    }
                    secondary={
                        <Grid container alignItems={`center`}>
                            {`${value.at ? convertUtcToLocal(value.at) : "-"} by `}
                            {value.by?.name ? (
                                <Button
                                    variant={"text"}
                                    color={`inherit`}
                                    size={"small"}
                                    sx={{
                                        minWidth: 0,
                                        height: `auto`,
                                        textAlign: `left`,
                                    }}
                                    onClick={(e) => handleUserProfileClick(e, value.by?.id)}
                                >
                                    {value.by?.name}
                                </Button>
                            ) : (
                                "-"
                            )}
                        </Grid>
                    }
                />
            );
        } else if (type === "date") {
            return (
                <ListItemText
                    disableTypography
                    primary={
                        <Typography
                            variant={"body2"}
                            color={theme.palette.greySecondary[600]}
                            gutterBottom
                            sx={{ mr: 0.5 }}
                        >
                            {t(`common::label::${subtitle}`)}
                        </Typography>
                    }
                    secondary={
                        <Grid container alignItems={`center`}>
                            {`${value.at ? convertUtcToLocal(value.at) : "-"} by `}
                            {value.by?.name ? (
                                <Button
                                    variant={"text"}
                                    color={`inherit`}
                                    size={"small"}
                                    sx={{
                                        minWidth: 0,
                                        height: `auto`,
                                        textAlign: `left`,
                                    }}
                                    onClick={(e) => handleUserProfileClick(e, value.by?.id)}
                                >
                                    {value.by?.name}
                                </Button>
                            ) : (
                                "-"
                            )}
                        </Grid>
                    }
                />
            );
        } else if (type === "textarea") {
            return (
                <ListItemText
                    disableTypography
                    primary={
                        <>
                            <Typography
                                variant={"body2"}
                                color={theme.palette.greySecondary[600]}
                                gutterBottom
                                sx={{ mr: 0.5 }}
                            >
                                {t(`common::label::${subtitle}`)}
                            </Typography>
                            {!editValueActive.description && (
                                <Tooltip title={t(`common::label::${"Edit"}`)}>
                                    <IconButton
                                        className={"btn-edit"}
                                        size={"xsmall"}
                                        circled
                                        variant={"dashed"}
                                        onClick={() => {
                                            setEditValueActive({ ...editValueActive, description: true });
                                            setEditValue({ ...editValue, description: value ?? "" });
                                        }}
                                    >
                                        <IconEditSmall size={12} />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </>
                    }
                    secondary={
                        <List disablePadding>
                            {!editValueActive.description ? (
                                <Typography variant={"body1_400"} sx={{ color: theme.palette.secondary[800] }}>
                                    {value ?? "-"}
                                </Typography>
                            ) : (
                                <>
                                    <TextField
                                        isEdit={true}
                                        multiline
                                        type={`textarea`}
                                        value={editValue.description}
                                        maxByte={LIMIT_BYTE.description}
                                        bytes={getByteLength(editValue.description)}
                                        onChange={(e) =>
                                            setEditValue({
                                                ...editValue,
                                                description: spliceByByteLength(e.target.value, 2000),
                                            })
                                        }
                                    />
                                    <EditSubmitButtonGroup
                                        submitDisabled={
                                            editValue.description === data.description || actionPending.description
                                        }
                                        submitPending={actionPending.description}
                                        onClickSubmit={() => onHandleClickEditCollection("description")}
                                        onClickClose={() => {
                                            setEditValueActive({ ...editValueActive, description: undefined });
                                        }}
                                    />
                                </>
                            )}
                        </List>
                    }
                />
            );
        } else if (type === "tags") {
            return (
                <ListItemText
                    disableTypography
                    primary={
                        <>
                            <Typography
                                variant={"body2"}
                                color={theme.palette.greySecondary[600]}
                                gutterBottom
                                sx={{ mr: 0.5 }}
                            >
                                {t(`common::label::${subtitle}`)}
                            </Typography>
                            {!editValueActive.tags && (
                                <Tooltip title={t(`common::label::${"Edit"}`)}>
                                    <IconButton
                                        className={"btn-edit"}
                                        size={"xsmall"}
                                        circled
                                        variant={"dashed"}
                                        onClick={() => {
                                            setEditValueActive({ ...editValueActive, tags: true });
                                            setEditValue({
                                                ...editValue,
                                                tags: value.map((tag) => ({ value: tag })) ?? [],
                                            });
                                        }}
                                    >
                                        <IconEditSmall size={12} />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </>
                    }
                    secondary={
                        <Grid item sx={{ mt: 1 }}>
                            {!editValueActive.tags ? (
                                <>
                                    {value.length === 0
                                        ? "-"
                                        : value.map((tag, index) => (
                                              <Chip
                                                  key={`metadata-hashtag-chip-${index}`}
                                                  label={`# ${tag}`}
                                                  size="small"
                                                  variant="outlined"
                                                  sx={{ mr: 0.5 }}
                                              />
                                          ))}
                                </>
                            ) : (
                                <>
                                    <TagsInput
                                        tags={editValue.tags}
                                        isEdit={true}
                                        disabled={actionPending.tags}
                                        onDeleteTag={(index) => {
                                            // setTags(tags.filter((t, idx) => idx !== index));
                                            setEditValue({
                                                ...editValue,
                                                tags: editValue.tags?.filter((t, idx) => idx !== index),
                                            });
                                        }}
                                        onAddTag={(tag) => {
                                            // setTags([...tags, tag]);
                                            setEditValue({
                                                ...editValue,
                                                tags: [...editValue.tags, tag],
                                            });
                                        }}
                                    />
                                    <EditSubmitButtonGroup
                                        submitDisabled={
                                            equal(
                                                editValue.tags.map((tag) => tag.value),
                                                value,
                                            ) || actionPending.tags
                                        }
                                        submitPending={actionPending.tags}
                                        onClickSubmit={() => onHandleClickEditCollectionAction("tags", value)}
                                        onClickClose={() => {
                                            setEditValueActive({ ...editValueActive, tags: undefined });
                                        }}
                                    />
                                </>
                            )}
                        </Grid>
                    }
                />
            );
        } else if (type === "listView") {
            return (
                <>
                    {!editValueActive[value.type] ? (
                        <AutocompleteTableList
                            type={value.type?.toUpperCase()}
                            data={[{ value: value.data }]}
                            columns={value.columns}
                            actionButtons={value.actionButtons}
                            onFetchData={value.onFetchData}
                            noDataContent={
                                <Grid
                                    container
                                    sx={{
                                        height: "60px",
                                        p: 1.5,
                                        borderRadius: `4px`,
                                        border: `1px solid ${theme.palette.secondary[100]}`,
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography>
                                        {t(`common::msg::No linked collection yet.`, `No linked collection yet.`)}
                                    </Typography>
                                </Grid>
                            }
                        />
                    ) : (
                        <>
                            <value.component
                                id={id}
                                value={value}
                                editValue={editValue[value.type]}
                                onChange={(checkedList) => {
                                    setEditValue({ ...editValue, [value.type]: checkedList });
                                }}
                            />
                            <EditSubmitButtonGroup
                                submitDisabled={equal(editValue[value.type], value.data) || actionPending[value.type]}
                                submitPending={actionPending[value.type]}
                                onClickSubmit={() => onHandleClickEditCollectionAction(value.type, value.data)}
                                onClickClose={() => {
                                    setEditValueActive({ ...editValueActive, [value.type]: undefined });
                                }}
                            />
                        </>
                    )}
                </>
            );
        } else if (type === "folder") {
            const folderLocation = getFolderLocation(value);
            return (
                <>
                    <ListItemText
                        disableTypography
                        primary={
                            <>
                                <SelectFolderPathModal
                                    domain={capitalize(domain, true).slice(0, -1)}
                                    submitText={`Select`}
                                    width={"600px"}
                                    title={"Select assets in a specific folder?"}
                                    subFoldersOption={false}
                                    onSubmit={async (value) => {
                                        await onSubmitEditValue({ type, editValue: value });
                                        closeDialog();
                                    }}
                                    onClose={() => closeDialog()}
                                    open={dialog === "selectFolderPath"}
                                />
                                <Typography variant={"body2"} color={theme.palette.greySecondary[600]} gutterBottom>
                                    {t(`common::label::${subtitle}`, subtitle)}
                                </Typography>
                                {isEditable && !editValueActive[id] && (
                                    <EditButton
                                        onClick={() => {
                                            openDialog("selectFolderPath");
                                        }}
                                    />
                                )}
                            </>
                        }
                        secondary={
                            <Grid item sx={{ mt: 1 }}>
                                <Grid container flexDirection={"row"} alignItems={"center"}>
                                    <Grid item mr={0.5}>
                                        <ImageFolderSmallOn height={16} width={16} />
                                    </Grid>
                                    <Tooltip title={folderLocation}>
                                        <Button
                                            variant={"text"}
                                            size={"small"}
                                            color={"secondary"}
                                            onClick={() => onHandleClickFolderButton(value)}
                                        >
                                            {getFolderName(value)}
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        }
                    />
                </>
            );
        }
    };

    return (
        <Box pt={2.5} pl={2.5} pr={1} pb={2.5}>
            {data.map((group, index) => (
                <>
                    <Stack>
                        <Grid container justifyContent={"space-between"}>
                            <Typography variant={"body1_700"} component={"span"} mb={2}>
                                {t(`common::label::${group.title}`) ?? ""}
                            </Typography>
                            {group.value && !editValueActive[group.value?.type] && (
                                <Tooltip title={t(`common::label::${"Edit"}`)}>
                                    <IconButton
                                        className={"btn-edit"}
                                        size={"small"}
                                        circled
                                        variant={"dashed"}
                                        onClick={() => {
                                            setEditValueActive({
                                                ...editValueActive,
                                                [group.value?.type]: true,
                                            });
                                            setEditValue({
                                                ...editValue,
                                                [group.value?.type]: group.value.data ?? [],
                                            });
                                        }}
                                    >
                                        <IconEditSmall size={16} />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Grid>
                        {group?.attributions?.length > 0 ? (
                            <Grid container>
                                <List
                                    disablePadding
                                    sx={{ p: 0, width: "100%", display: "flex", flexDirection: "column", gap: 1.5 }}
                                >
                                    {group.attributions.map((attribution, index) => (
                                        <ListItem
                                            sx={{
                                                pt: index > 0 ? 1 : 0,
                                                px: 1.5,
                                                py: 0.25,
                                                borderRadius: 0.5,
                                                transition: "background-color 0.3s",

                                                "&:not(:has(.MuiSwitch-root, .MuiAutocomplete-root, .category-select, .form-input, .MuiTextField-root, .MuiSelect-select))":
                                                    {
                                                        "&:hover": {
                                                            backgroundColor: alpha(
                                                                theme.palette.greySecondary[50],
                                                                0.5,
                                                            ),
                                                        },
                                                    },
                                            }}
                                        >
                                            {getComponentByType(attribution)}
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                        ) : (
                            <Grid item pt={"10px"} width={`100%`}>
                                {getComponentByType(group)}
                            </Grid> //테이블
                        )}
                    </Stack>
                    {index < data.length - 1 && (
                        <Divider flexItem orientation={"horizontal"} sx={{ mt: 2.5, mb: 2.5 }} />
                    )}
                </>
            ))}
            {!userProfilePending && (
                <UserDetailPopper
                    id={openUserProfile ? "user-profile-popover" : undefined}
                    handleUserProfileClose={handleUserProfileClose}
                    userProfileAnchorEl={userProfileAnchorEl}
                    openUserProfile={openUserProfile}
                    userProfileData={userProfileData}
                />
            )}
        </Box>
    );
};

export default PanelDetailsView;
