import { Badge, Box, Divider, Grid, IconButton, LinearProgress, Stack } from "@mzc-pdc/ui";
import { IconBack, IconCancel, IconRefresh, IconReply } from "@mzc-cloudplex/icons";
import Typography from "@components_v2/typography/typography";
import theme from "@styles/theme";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { alpha } from "@mui/material";
import CardComments from "@features/comments/common/card-comments";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getThreadComments, resetThreadComments, updateIsLinkCommentUsed } from "@modules/common-comments";
import { useInView } from "react-intersection-observer";
import SkeletonComment from "@features/comments/skeleton-comment";
import queryParamsParse from "@components/params/queryParamsParse";
import useCommentSelector from "@hooks/useCommentSelector";
import { GroupTitleWithIcon } from "@features/comments/groups";
import { UPLOAD_LOCATION } from "@constants";
import { inActive } from "@modules/rightSideBar";
import Tooltip from "@components_v2/Tooltip/tooltip";

const ThreadContainer = ({ domain, resourceId, hidden, onClickBack, tabs }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [prevTargetRef, prevInView] = useInView();
    const [nextTargetRef, nextInView] = useInView();

    const [mountPending, setMountPending] = useState(true);
    const [hasScrolledToLinkComment, setHasScrolledToLinkComment] = useState(false);
    const [scrollYPosition, setScrollYPosition] = useState(0);

    const linkCommentRef = useRef(null);
    const scrollDivRef = useRef(null);

    const { threads, selectedComment, isLinkCommentUsed, selectedThread, uploadingComments } = useCommentSelector({
        domain,
    });

    const threadComments = threads?.data?.results;
    const thread = threads;
    const project = useSelector(({ project }) => project);
    const uploadQueue = useSelector(({ uploadQueue }) => uploadQueue);

    const onHandleClickRefresh = useCallback(() => {
        if (!selectedComment) return;

        dispatch(resetThreadComments());
        dispatch(getThreadComments(project.id, selectedComment.subThread?.id));
    }, [selectedComment?.id]);

    const linkParams = useMemo(() => {
        const { threadId, commentId, domain } = queryParamsParse(location.search);
        const isLinkFromEmail = threadId;

        return { isLinkFromEmail, threadId, commentId, domain };
    }, [location.search]);

    const initThreadComments = useCallback(async () => {
        const { isLinkFromEmail, threadId, commentId, domain: linkDomain } = linkParams;

        if (!isLinkCommentUsed && isLinkFromEmail && domain === linkDomain) {
            if (selectedComment) return;
            await dispatch(
                getThreadComments(project.id, threadId, {
                    cursor: commentId,
                    orderType: "DESC",
                }),
            );
            if (commentId)
                await dispatch(
                    getThreadComments(project.id, threadId, {
                        cursor: commentId,
                        orderType: "ASC",
                    }),
                );
            dispatch(updateIsLinkCommentUsed(true));
        } else {
            if (!selectedComment?.subThread) return;
            await dispatch(getThreadComments(project.id, selectedComment.subThread?.id));
        }
        setMountPending(false);
    }, [selectedComment?.id, isLinkCommentUsed, linkParams, selectedComment]);

    const onHandleClose = useCallback(() => {
        dispatch(inActive());
    }, []);

    const totalUploadingFilesCount = useMemo(() => {
        //이거 useComment쪽으로 올릴 수 있나?
        if (!selectedComment?.subThread?.id) return 0;
        return uploadQueue[UPLOAD_LOCATION.UPLOAD_COMMENT_ATTACHMENT]
            .filter((queue) => queue.status === "UPLOADING")
            .reduce((acc, queue) => {
                if (queue.data.threadId === selectedComment.subThread?.id) {
                    return acc + queue.files?.length;
                }
                return acc;
            }, 0);
    }, [uploadQueue, selectedComment]);

    const uploadedFilesCount = useMemo(() => {
        if (!selectedComment?.subThread?.id) return 0;
        return uploadQueue[UPLOAD_LOCATION.UPLOAD_COMMENT_ATTACHMENT]
            .filter((queue) => queue.status === "UPLOADING")
            .reduce((acc, queue) => {
                if (queue.data.threadId === selectedComment.subThread?.id) {
                    return queue.files?.filter((file) => file?.status?.status === "COMPLETE")?.length + acc;
                }
                return acc;
            }, 0);
    }, [uploadQueue, selectedComment]);

    useEffect(() => {
        if (!selectedComment) return;
        else if (!selectedComment?.subThread?.id) {
            setMountPending(false);
            return;
        }
        initThreadComments();
    }, [selectedComment?.id]);

    useEffect(() => {
        if (hidden || !linkParams.isLinkFromEmail || isLinkCommentUsed) return;
        initThreadComments();
    }, [hidden]);

    useEffect(() => {
        if (!thread?.data?.prevToken || thread?.prevPending) return;
        if (prevInView) {
            dispatch(
                getThreadComments(project.id, selectedComment.subThread?.id, {
                    nextToken: thread?.data?.prevToken,
                    orderType: "DESC",
                }),
            );
        }
    }, [prevInView]);

    useEffect(() => {
        if (!thread?.data?.nextToken || thread?.nextPending) return;
        if (nextInView) {
            dispatch(
                getThreadComments(project.id, selectedComment.subThread?.id, {
                    nextToken: thread?.data?.nextToken,
                    orderType: "ASC",
                }),
            );
        }
    }, [nextInView]);

    useEffect(() => {
        const handleScroll = (e) => {
            setScrollYPosition(e.target.scrollTop);
        };

        if (!hidden) {
            scrollDivRef.current?.scrollTo(0, scrollYPosition);
            scrollDivRef.current?.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (hidden) {
                scrollDivRef.current?.removeEventListener("scroll", handleScroll);
                setScrollYPosition(0);
            }
        };
    }, [hidden]);

    useEffect(() => {
        if (!linkParams.isLinkFromEmail || hasScrolledToLinkComment) return;
        if (!threadComments?.length || !isLinkCommentUsed) return;
        linkCommentRef?.current?.scrollIntoView({
            behavior: "instant",
            block: "center",
        });
        setHasScrolledToLinkComment(true);
    }, [mountPending]);

    return (
        <Stack hidden={hidden} height={1} sx={{ overflow: "hidden" }}>
            <Box sx={{ backgroundColor: theme.palette.greySecondary[100] }}>
                <Grid
                    container
                    direction={"row"}
                    p={"6px 12px"}
                    sx={{
                        backgroundColor: theme.palette.common.white,
                        height: "56px",
                        borderBottom: `1px solid ${theme.palette.greySecondary.A100}`,
                    }}
                >
                    <Grid item flex={1} gap={1.5} alignItems={"center"} sx={{ display: "flex" }}>
                        <Grid item>
                            <IconReply size={20} htmlColor={theme.palette.success.main} />
                        </Grid>
                        <Grid item flex={1}>
                            <Typography variant={`subtitle1_500`} color={theme.palette.greySecondary.main}>
                                {t("common::label::Comment", "Comment")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item flex={0} gap={theme.spacing(1)} sx={{ display: "flex", alignItems: "center" }}>
                        <Tooltip title={t("common::label::Refresh", "Refresh")}>
                            <IconButton
                                circled
                                onClick={() => {
                                    onHandleClickRefresh();
                                }}
                            >
                                <IconRefresh size={16} />
                            </IconButton>
                        </Tooltip>
                        {domain === "PROJECT" && (
                            <Tooltip title={t("common::label::Close", "Close")}>
                                <IconButton circled onClick={onHandleClose}>
                                    <IconCancel size={16} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ backgroundColor: theme.palette.greySecondary[100] }}>
                <Grid
                    container
                    direction={"row"}
                    sx={{
                        p: "8px 12px 8px 12px",
                        backgroundColor: alpha(theme.palette.common.white, 0.6),
                        height: "48px",
                    }}
                >
                    <Grid item flex={1} gap={1.5} alignItems={"center"} sx={{ display: "flex" }}>
                        <Grid item>
                            <Tooltip title={t(`common::label::Back to comments`, `Back to comments`)}>
                                <IconButton
                                    circled
                                    variant={"contained"}
                                    size={"small"}
                                    sx={{
                                        backgroundColor: theme.palette.primary.light,
                                        color: theme.palette.common.white,
                                        boxShadow: `0px 3px 6px 0px rgba(0, 0, 0, 0.32)`,
                                    }}
                                    onClick={onClickBack}
                                >
                                    <IconBack size={16} color={"inherit"} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item flex={1}>
                            <Typography variant={`subtitle1_500`} color={theme.palette.greySecondary.main}>
                                <GroupTitleWithIcon
                                    isPublic={selectedThread?.type === "PUBLIC"}
                                    title={selectedThread?.name}
                                    iconSize={16}
                                    fontVariant={"body3_500"}
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            {totalUploadingFilesCount > 0 && (
                <Box
                    width={400}
                    backgroundColor={"secondary.main"}
                    color={"secondary.contrastText"}
                    borderRadius={1.125}
                    sx={{
                        position: `absolute`,
                        top: "60px",
                        left: `50%`,
                        ml: `-200px`,
                        // visibility: isShow ? `visible`: `hidden`,
                        zIndex: 100,
                    }}
                >
                    <Stack px={2.5} py={1}>
                        <Typography>
                            {/*{queue.length}*/}
                            {t(`common::label::Uploading files`, `Uploading files`)} ({uploadedFilesCount} /{" "}
                            {totalUploadingFilesCount}
                            )...
                        </Typography>
                        <Grid container alignItems={"center"} flexWrap={"nowrap"} gap={1} mt={0.5}>
                            <Grid item xs>
                                <LinearProgress
                                    gradient
                                    variant="determinate"
                                    value={(uploadedFilesCount / totalUploadingFilesCount) * 100}
                                />
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    color: theme.palette.secondary.contrastText,
                                }}
                            >
                                <Tooltip title={"업데이트 취소"}>
                                    <IconButton color={"inherit"} circled onClick={() => {}}>
                                        <IconCancel size={12} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Stack>
                </Box>
            )}
            <Grid
                container
                flexDirection={"column-reverse"}
                sx={{
                    flex: 1,
                    padding: `8px 16px 8px 16px`,

                    overflowY: "auto",
                    backgroundColor: alpha(theme.palette.greySecondary["A100"], 0.48),
                    flexWrap: "nowrap",
                    "& .MuiGrid-root:last-of-type": {
                        borderRadius: `4px 4px 0 0 !important`,
                    },
                    "& .MuiGrid-root:first-of-type": {
                        borderRadius: "0 0 4px 4px !important",
                    },
                }}
                ref={scrollDivRef}
            >
                {/*column-reverse를 사용하여 실제 렌더링은 제일 아래의 CardComments->Badge->map 순서로 렌더링됩니다.*/}
                {!mountPending && (
                    <>
                        {uploadingComments?.[selectedComment?.subThread?.id]?.length > 0 && (
                            <>
                                {uploadingComments?.[selectedComment?.subThread?.id].map((comment) => (
                                    <CardComments
                                        domain={domain}
                                        key={`domain-comment-${comment?.id}`}
                                        data={comment}
                                        isUploading={true}
                                        isThread={true}
                                    />
                                ))}
                            </>
                        )}
                        {thread?.nextPending ? (
                            <SkeletonComment isThread />
                        ) : (
                            <Grid ref={nextTargetRef} hidden={!thread?.data?.nextToken} />
                        )}
                        {threadComments?.map((comment, index) => (
                            <>
                                <CardComments
                                    domain={domain}
                                    key={`common-thread-${comment?.id}`}
                                    data={comment}
                                    isThread
                                    ref={comment.id === linkParams?.commentId ? linkCommentRef : null}
                                    isUploading={comment.isUploading}
                                    domain={"COMMENT"}
                                    resourceId={selectedComment?.id}
                                />
                            </>
                        ))}
                    </>
                )}
                {mountPending || thread?.prevPending ? (
                    <>
                        {new Array(!mountPending ? 1 : 5).fill("").map((value, index) => (
                            <SkeletonComment isThread key={`thread-container-${index}`} />
                        ))}
                    </>
                ) : (
                    <Grid ref={prevTargetRef} hidden={!thread?.data?.prevToken} />
                )}
                <Grid
                    item
                    sx={{
                        backgroundColor: theme.palette.common.white,
                        display: "flex",
                        alignItems: "center",
                        px: "16px",
                        pb: selectedComment?.subThread?.commentCount > 0 ? 0 : 2,
                    }}
                >
                    <Badge
                        sx={{
                            ".MuiBadge-badge": {
                                position: `static`,
                                minWidth: "30px",
                                transform: `none`,
                                backgroundColor: theme.palette.success.main,
                                color: theme.palette.common.white,
                                fontSize: theme.typography["body3_500"],
                                borderRadius: "2px",
                            },
                        }}
                        badgeContent={t(`common::label::{{commentCount}} replies`, `{{commentCount}} replies`, {
                            commentCount: selectedComment?.subThread?.commentCount ?? 0,
                        })}
                    />
                    <Box sx={{ width: "100%", ml: 1.5 }}>
                        <Divider flexItem orientation={"horizontal"} />
                    </Box>
                </Grid>
                <CardComments data={selectedComment} isThread domain={domain} resourceId={resourceId} />
            </Grid>
        </Stack>
    );
};

export default ThreadContainer;
