import React from "react";
import { StyledStatusIcon } from "./status.styled";
import { IconCircle, IconCircleOutlined } from "@mzc-cloudplex/icons";
import theme from "@styles/theme";

const StatusIcon = ({ status }) => {
    return (
        <StyledStatusIcon>
            {status === "active" ? (
                <IconCircle color={"success"} size={12} />
            ) : (
                <IconCircleOutlined size={12} htmlColor={theme.palette.greySecondary[500]} />
            )}
        </StyledStatusIcon>
    );
};

export default StatusIcon;
