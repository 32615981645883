import { Skeleton as MuiSkeleton } from "@mzc-pdc/ui";

const Skeleton = ({ sx, ...props }) => {
    return (
        <MuiSkeleton
            sx={{
                ...sx,
                "&::after": {
                    background: `linear-gradient(
              90deg,
              transparent,
              rgba(255, 255, 255, 0.35),
              transparent
            )`,
                },
            }}
            {...props}
        />
    );
};

export default Skeleton;
