import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustomContentDetailAPI, updateCustomContentActionsAPI } from "@modules/apis/custom-content";
import { parseEnumType, parseTypeToFieldType } from "@modules/content-schema";
import { setNotification } from "@modules/notification";
import { useTranslation } from "react-i18next";
import useCustomContent from "@hooks/useCustomContent";
import { getContentSchemaDetailAPI } from "@modules/apis/content-schema";

const useExtendedCustomMetadata = ({ extendedMetadata }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const stage = useSelector(({ stage }) => stage);
    const project = useSelector(({ project }) => project);
    const { getCustomMetadataOptions, createDefaultValueSchema, checkIsNull } = useCustomContent();

    const [customMetadata, setCustomMetadata] = useState([]);
    const [customMetadataInfo, setCustomMetadataInfo] = useState({});

    const getCustomMetadata = async ({ contentId }) => {
        try {
            const response = await getCustomContentDetailAPI(stage.endpoint, project.id, contentId);
            const data = response.data;

            const fields = data?.fields?.map((v) => {
                const dataType = v.fieldType?.dataType;
                const newDataType = parseTypeToFieldType(dataType);
                const newField = { ...v, fieldType: { ...v.fieldType, dataType: { ...newDataType } } };
                const parsedField = parseEnumType(newField, "detail");

                return { ...parsedField };
            });
            return fields;
        } catch (error) {}
    };

    const updateCustomMetadata = useCallback(async ({ id, fields }) => {
        try {
            const action = `OVERWRITE_FIELD_VALUES`;
            await updateCustomContentActionsAPI(stage.endpoint, project.id, id, {
                action,
                data: { fields },
            });
        } catch (error) {
            dispatch(
                setNotification({
                    type: "error",
                    contents: t(
                        `common::msg::An error occured while changing Custom Metadata {type}`,
                        `An error occured while changing Custom Metadata {type}`,
                        {
                            type: fields?.[0]?.id,
                        },
                    ),
                }),
            );
        }
    }, []);

    const parseCustomMetadata = useCallback(
        (fields) => {
            return (
                fields?.map((el) => ({
                    id: el.id,
                    subtitle: el.name,
                    type: el.fieldType.dataType.detailType,
                    value: el.value,
                    options: getCustomMetadataOptions(el),
                    placeholder: el.placeholder,
                    validationRule: createDefaultValueSchema(
                        el?.fieldType?.dataType?.type,
                        el?.fieldType?.dataType?.properties?.[0]?.type,
                        el?.fieldType?.dataType?.validation || el?.fieldType?.dataType?.properties?.[0]?.validation,
                        el?.isRequired,
                    ),
                    isEditable: el.isEditable,
                    isHidden: el.isHidden,
                    isRequired: el.isRequired,
                    onSubmitEditValue: (value) => onHandleUpdateCustomMetadata(el.id, value),
                })) ?? []
            );
        },
        [extendedMetadata, customMetadata],
    );

    const onHandleUpdateCustomMetadata = useCallback(
        async (id, value) => {
            if (!extendedMetadata) return null;
            await updateCustomMetadata({ id: extendedMetadata.contentId, fields: [{ id: id, value: value }] });

            const fieldIndex = customMetadata?.findIndex((el) => el.id === id);
            if (fieldIndex >= 0) {
                let customMetadata_ = [...customMetadata];
                customMetadata_[fieldIndex].value = value;
                setCustomMetadata(customMetadata_);
            }
        },
        [extendedMetadata, customMetadata],
    );

    const getCustomMetadataDetail = async (extendedMetadata) => {
        if (!extendedMetadata?.contentSchemaId) return;
        try {
            const response = await getContentSchemaDetailAPI(
                stage.id,
                stage.endpoint,
                project.id,
                extendedMetadata.contentSchemaId,
            );
            return { name: response?.data?.name };
        } catch (e) {}
    };

    const clearCustomMetadata = () => {
        setCustomMetadata([]);
        setCustomMetadataInfo({});
    };

    useEffect(async () => {
        if (!extendedMetadata) return;
        const response = await getCustomMetadataDetail(extendedMetadata);
        setCustomMetadataInfo(response);
    }, [extendedMetadata]);

    useEffect(async () => {
        if (!extendedMetadata) {
            if (customMetadata?.length > 0) setCustomMetadata([]);
            return;
        }
        const { contentId } = extendedMetadata;
        if (!contentId) return;

        const fields = await getCustomMetadata({ contentId });
        setCustomMetadata(fields);
    }, [extendedMetadata]);

    return {
        getCustomMetadata,
        getCustomMetadataDetail,
        clearCustomMetadata,
        parseCustomMetadata,
        updateCustomMetadata,
        customMetadata,
        customMetadataInfo,
    };
};

export default useExtendedCustomMetadata;
