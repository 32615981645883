import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const PageHeaderTools = (props) => {
    const { className, ...otherProps } = props;
    return <div {...otherProps} className={clsx("page-header-tools", className)} />;
};
PageHeaderTools.propTypes = {
    className: PropTypes.string,
};

export default PageHeaderTools;
