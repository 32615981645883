import { handleActions, createAction } from "redux-actions";

import {
    axios,
    // axiosWithoutHeaders
} from "../cores/axiosWrapper";
import uniqid from "uniqid";

import configurationTemplate from "./event-trigger-configuration-template";
import configurationTemplateV2 from "./event-trigger-configuration-template-v2";

import replaceCustomTags from "../cores/replaceCustomTags";
import { getDetailDriveFolder } from "./folderTreeView";
import { getCategoryGroup } from "@modules/categoryGroupsManagement";
import compareVersions, { VERSION_CM_9436_EVENT_TRIGGER_VIRTUAL_FOLDER } from "@cores/version";
import { getVocabularyAPI } from "@modules/apis/vocabulary";
import { AUTO_CAPTION_ENGINES } from "@constants";

function getEventTriggerAPI(stageId, apiEndpoint, projectId, id) {
    // console.log(stageId, apiEndpoint, projectId, id);
    return axios.get(`${apiEndpoint}/triggers/${id}`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function deleteEventTriggerAPI(stageId, apiEndpoint, projectId, id) {
    return axios.delete(`${apiEndpoint}/triggers/${id}`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function updateEventTriggerAPI(stageId, apiEndpoint, projectId, id, data) {
    return axios.put(`${apiEndpoint}/triggers/${id}`, data, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function defineFields(data, stage) {
    const template =
        compareVersions(stage.version, VERSION_CM_9436_EVENT_TRIGGER_VIRTUAL_FOLDER) < 0
            ? configurationTemplate
            : configurationTemplateV2;

    const selectedConfigurationTemplate = template.filter((template) => template.eventType === data.eventType)[0];

    data.conditions = data.conditions.map((condition) => {
        const matchedConditionCustomField = selectedConfigurationTemplate.conditionCustomFields.filter(
            (customField) => customField.key === condition.key,
        )[0];
        return {
            ...matchedConditionCustomField,
            ...condition,
            uniqKey: uniqid(),
            isFolded: !matchedConditionCustomField.required,
        };
    });

    data.actions = data.actions.map((action) => {
        const matchedActionCustomFieldAreas = selectedConfigurationTemplate.actions.filter(
            (templateAction) => templateAction.key === action.key,
        );
        const matchedActionCustomFieldArea =
            matchedActionCustomFieldAreas.length === 1
                ? matchedActionCustomFieldAreas[0]
                : matchedActionCustomFieldAreas.filter((templateAction) => {
                      // 구버전 호환
                      if (!action.payload.type_) return action.payload.type === templateAction.automaticValues.type_;
                      else return action.payload.mediaType === templateAction.automaticValues.mediaType;
                  })[0];

        return {
            ...matchedActionCustomFieldArea,
            ...action,
            options: selectedConfigurationTemplate.actions,
            uniqKey: uniqid(),
            customFields:
                matchedActionCustomFieldArea &&
                matchedActionCustomFieldArea.customFields &&
                matchedActionCustomFieldArea.customFields.map((customField) => {
                    const value = action.payload && action.payload[customField.key];
                    return {
                        ...customField,
                        defaultValue: value,
                        uniqKey: uniqid(),
                        isFolded: !customField.required,
                        value: value,
                    };
                }),
        };
    });

    return data;
}

function correctPayload(payload) {
    if (!payload) return payload;

    Object.keys(payload).forEach((k) => {
        if (k.indexOf("-") > -1) {
            const keys = k.split("-");
            const values = payload[k] && payload[k].split("-");

            keys.forEach((_k, i) => {
                payload[_k] = values[i];
            });
        }
    });

    if (payload.autoCaptions?.useAutoCaption === "USED") {
        const {
            engine,
            services,
            identifyLanguage,
            identifyMultipleLanguages,
            languageOptions,
            languageCode,
            speakerDiarization,
            dictationVocabularyId,
        } = payload.autoCaptions.data;

        payload.autoCaptions = services?.map((service) => ({
            engine: service.serviceType,
            identifyLanguage:
                service.serviceType === AUTO_CAPTION_ENGINES.AWS_TRANSCRIBE ? identifyLanguage : undefined,
            identifyMultipleLanguages:
                service.serviceType === AUTO_CAPTION_ENGINES.AWS_TRANSCRIBE ? identifyMultipleLanguages : undefined,
            languageOptions:
                service.serviceType === AUTO_CAPTION_ENGINES.AWS_TRANSCRIBE && identifyMultipleLanguages
                    ? languageOptions
                    : undefined,
            languageCode: [AUTO_CAPTION_ENGINES.AWS_TRANSCRIBE, AUTO_CAPTION_ENGINES.DAGLO_STT].includes(
                service.serviceType,
            )
                ? languageCode
                : undefined,
            model: service.serviceType === AUTO_CAPTION_ENGINES.DAGLO_STT ? "general" : undefined,
            speakerDiarization: service.serviceType === AUTO_CAPTION_ENGINES.DAGLO_STT ? speakerDiarization : undefined,
            dictationVocabularyId: [AUTO_CAPTION_ENGINES.AWS_TRANSCRIBE, AUTO_CAPTION_ENGINES.DAGLO_STT].includes(
                service.serviceType,
            )
                ? dictationVocabularyId
                : undefined,
        }));
    } else {
        delete payload.autoCaptions;
    }

    return payload;
}

//action type
const RESET = "RESET";

const EVENT_TRIGGER_MANAGEMENT_DETAIL_PENDING = "EVENT_TRIGGER_MANAGEMENT_DETAIL_PENDING";
const EVENT_TRIGGER_MANAGEMENT_DETAIL_FAILURE = "EVENT_TRIGGER_MANAGEMENT_DETAIL_FAILURE";

const EVENT_TRIGGER_MANAGEMENT_DETAIL_IS_CHANGING_DATA = "EVENT_TRIGGER_MANAGEMENT_DETAIL_IS_CHANGING_DATA";

const EVENT_TRIGGER_MANAGEMENT_DETAIL_GET_EVENT_TRIGGER_SUCCESS =
    "EVENT_TRIGGER_MANAGEMENT_DETAIL_GET_EVENT_TRIGGER_SUCCESS";
const EVENT_TRIGGER_MANAGEMENT_DETAIL_UPDATE_EVENT_TRIGGER_SUCCESS =
    "EVENT_TRIGGER_MANAGEMENT_DETAIL_UPDATE_EVENT_TRIGGER_SUCCESS";
const EVENT_TRIGGER_MANAGEMENT_DETAIL_DELETE_EVENT_TRIGGER_SUCCESS =
    "EVENT_TRIGGER_MANAGEMENT_DETAIL_DELETE_EVENT_TRIGGER_SUCCESS";

//reducer
const initialState = {
    data: null,
    pending: false,
    error: false,
    isDeleted: false,
    isChangingData: false,
    stage: null,
    selectedConfigurationTemplate: configurationTemplate[0],
};

export const reset = createAction(RESET);

export default handleActions(
    {
        [RESET]: (state, action) => {
            if (!action.payload) return { ...initialState };
            const { stage } = action.payload;

            const template =
                compareVersions(stage.version, VERSION_CM_9436_EVENT_TRIGGER_VIRTUAL_FOLDER) < 0
                    ? configurationTemplate[0]
                    : configurationTemplateV2[0];

            return {
                ...initialState,
                stage,
                selectedConfigurationTemplate: template,
            };
        },
        [EVENT_TRIGGER_MANAGEMENT_DETAIL_PENDING]: (state, action) => {
            return {
                ...state,
                pending: true,
            };
        },
        [EVENT_TRIGGER_MANAGEMENT_DETAIL_FAILURE]: (state, action) => {
            return {
                ...state,
                error: true,
                pending: false,
            };
        },
        [EVENT_TRIGGER_MANAGEMENT_DETAIL_IS_CHANGING_DATA]: (state, action) => {
            return {
                ...state,
                isChangingData: true,
            };
        },
        [EVENT_TRIGGER_MANAGEMENT_DETAIL_GET_EVENT_TRIGGER_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const { stage } = state;

            return {
                ...state,
                data: defineFields(data, stage),
                pending: false,
            };
        },
        [EVENT_TRIGGER_MANAGEMENT_DETAIL_DELETE_EVENT_TRIGGER_SUCCESS]: (state, action) => {
            return {
                ...state,
                isDeleted: true,
                isChangingData: false,
            };
        },
        [EVENT_TRIGGER_MANAGEMENT_DETAIL_UPDATE_EVENT_TRIGGER_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const { stage } = state;
            return {
                ...state,
                data: defineFields(data, stage),
                isChangingData: false,
            };
        },
    },
    initialState,
);

export const getEventTrigger = (projectId, id) => async (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: EVENT_TRIGGER_MANAGEMENT_DETAIL_PENDING });

    getEventTriggerAPI(stage.id, stage.endpoint, projectId, id)
        .then(async (response) => {
            if (response.data) {
                const actions = response.data.actions;
                const newActions = await Promise.all(
                    actions?.map(async (action) => {
                        try {
                            let payload = action.payload;

                            // Virtual Folder ID가 있는 경우 전체 Folder Path 생성
                            if (payload.virtualFolder) {
                                const folderPath = await dispatch(
                                    getDetailDriveFolder({
                                        driveId: payload.virtualFolder.virtualDrive.id,
                                        folderId: payload.virtualFolder.id,
                                    }),
                                );

                                payload = {
                                    ...payload,
                                    virtualFolder: {
                                        ...payload.virtualFolder,
                                        virtualFolderPath: folderPath?.parents?.reverse()?.reduce(
                                            (acc, cur) => {
                                                if (cur.depth === 0) return `/${acc}`;
                                                return `${cur.name}/${acc}`;
                                            },
                                            `${folderPath.depth === 0 ? "/" : folderPath.name}`,
                                        ),
                                    },
                                };
                            }

                            // 카테고리 그룹ID가 있을 경우 카테고리 그룹명 생성
                            if (payload.categories?.length > 0 && payload.categories[0].categoryGroup?.id) {
                                const categoryGroup = await dispatch(
                                    getCategoryGroup(projectId, payload.categories[0].categoryGroup.id),
                                );

                                payload = {
                                    ...payload,
                                    categories: action.payload.categories?.map((category) => ({
                                        ...category,
                                        categoryGroup: { ...category.categoryGroup, name: categoryGroup.name },
                                    })),
                                };
                            }

                            if (payload.autoCaptions) {
                                const { engine, dictationVocabularyId, ...rest } =
                                    payload.autoCaptions.find(
                                        (caption) => caption.engine === AUTO_CAPTION_ENGINES.DAGLO_STT,
                                    ) ?? payload.autoCaptions[0];

                                const data = {
                                    engine: engine.split("_")[0],
                                    ...rest,
                                    services: payload.autoCaptions.map((item) => {
                                        return { serviceType: item.engine };
                                    }),
                                };

                                if (dictationVocabularyId) {
                                    let { data: vocabularyData } = await getVocabularyAPI(
                                        stage.id,
                                        stage.endpoint,
                                        projectId,
                                        dictationVocabularyId,
                                    );
                                    data.dictationVocabularyName = vocabularyData.name;
                                    data.dictationVocabularyId = dictationVocabularyId;
                                }

                                payload.autoCaptions = { useAutoCaption: "USED", data };
                            }

                            return { ...action, payload };
                        } catch (e) {
                            console.error(e);
                            return e;
                        }
                    }),
                );

                dispatch({
                    type: EVENT_TRIGGER_MANAGEMENT_DETAIL_GET_EVENT_TRIGGER_SUCCESS,
                    payload: { ...response, data: { ...response.data, actions: newActions } },
                });
            }
        })
        .catch((error) => {
            dispatch({
                type: EVENT_TRIGGER_MANAGEMENT_DETAIL_FAILURE,
                payload: error,
            });
        });
};

export const deleteEventTrigger = (projectId, id) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: EVENT_TRIGGER_MANAGEMENT_DETAIL_IS_CHANGING_DATA });

    deleteEventTriggerAPI(stage.id, stage.endpoint, projectId, id)
        .then((response) => {
            dispatch({
                type: EVENT_TRIGGER_MANAGEMENT_DETAIL_DELETE_EVENT_TRIGGER_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: EVENT_TRIGGER_MANAGEMENT_DETAIL_FAILURE,
                payload: error,
            });
        });
};

export const updateEventTrigger = (projectId, id, data) => (dispatch, getState) => {
    const { stage } = getState();
    const parsedData = JSON.parse(JSON.stringify(data));

    dispatch({ type: EVENT_TRIGGER_MANAGEMENT_DETAIL_IS_CHANGING_DATA });

    if (parsedData.actions && parsedData.actions.length > 0) {
        parsedData.actions = parsedData.actions.map((action) => {
            const payload = correctPayload(
                action.customFields.reduce((acc, curr) => {
                    acc[curr.key] = curr.value;
                    return acc;
                }, {}),
            );

            return {
                key: action.key,
                payload: {
                    ...payload,
                    ...replaceCustomTags(action.automaticValues, payload),
                },
            };
        });
    }

    updateEventTriggerAPI(stage.id, stage.endpoint, projectId, id, parsedData)
        .then(async (response) => {
            const actions = response.data.actions;
            const newActions = await Promise.all(
                actions?.map(async (action) => {
                    let payload = action.payload;

                    // Virtual Folder ID가 있는 경우 전체 Folder Path 생성
                    if (payload.virtualFolder) {
                        const folderPath = await dispatch(
                            getDetailDriveFolder({
                                driveId: payload.virtualFolder.virtualDrive.id,
                                folderId: payload.virtualFolder.id,
                            }),
                        );

                        payload = {
                            ...payload,
                            virtualFolder: {
                                ...payload.virtualFolder,
                                virtualFolderPath: folderPath?.parents?.reverse()?.reduce(
                                    (acc, cur) => {
                                        if (cur.depth === 0) return `/${acc}`;
                                        return `${cur.name}/${acc}`;
                                    },
                                    `${folderPath.depth === 0 ? "/" : folderPath.name}`,
                                ),
                            },
                        };
                    }

                    // 카테고리 그룹ID가 있을 경우 카테고리 그룹명 생성
                    if (payload.categories?.length > 0 && payload.categories[0].categoryGroup?.id) {
                        const categoryGroup = await dispatch(
                            getCategoryGroup(projectId, payload.categories[0].categoryGroup.id),
                        );

                        payload = {
                            ...payload,
                            categories: action.payload.categories?.map((category) => ({
                                ...category,
                                categoryGroup: { ...category.categoryGroup, name: categoryGroup.name },
                            })),
                        };
                    }

                    if (payload.autoCaptions) {
                        const { engine, dictationVocabularyId, ...rest } = payload.autoCaptions[0];

                        const data = {
                            engine: payload.autoCaptions[0].engine.split("_")[0],
                            ...rest,
                            services: payload.autoCaptions.map((item) => {
                                return { serviceType: item.engine };
                            }),
                        };

                        if (dictationVocabularyId) {
                            let { data: vocabularyData } = await getVocabularyAPI(
                                stage.id,
                                stage.endpoint,
                                projectId,
                                dictationVocabularyId,
                            );
                            data.dictationVocabularyName = vocabularyData.name;
                            data.dictationVocabularyId = dictationVocabularyId;
                        }

                        payload.autoCaptions = { useAutoCaption: "USED", data };
                    }

                    return { ...action, payload };
                }),
            );

            dispatch({
                type: EVENT_TRIGGER_MANAGEMENT_DETAIL_UPDATE_EVENT_TRIGGER_SUCCESS,
                payload: { ...response, data: { ...response.data, actions: newActions } },
            });
        })
        .catch((error) => {
            dispatch({
                type: EVENT_TRIGGER_MANAGEMENT_DETAIL_FAILURE,
                payload: error,
            });
        });
};
