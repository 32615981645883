import { CONSTANTS } from "../constants";

export const values = CONSTANTS("JOB_STATUS").reduce((acc, curr) => {
    acc[curr.value] = curr.value;

    return acc;
}, {});

export const getValuesLowerThan = (status) => {
    const index = Object.keys(values).indexOf(status);

    return Object.keys(values).filter((k, j) => j < index);
};

export const isComplete = (status) => {
    return status === values.CANCELED || status === values.COMPLETE || status === values.ERROR;
};

export default values;
