import React, { useCallback, useEffect, useState } from "react";
import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Stack,
    Tooltip,
    PDCAlpha as alpha,
} from "@mzc-pdc/ui";
import theme from "@styles/theme";
import FieldEditBox from "@features/field-by-type/field-edit-box";
import MetadataFieldEditMode from "@features/metadata-fields/metadata-field-edit-mode";
import { useTranslation } from "react-i18next";
import Typography from "@components_v2/typography/typography";
import MetadataFieldReadMode from "@features/metadata-fields/metadata-field-read-mode";
import {
    IconCircleOutlined,
    IconCompleteCircle,
    IconEditSmall,
    IconMoreVert,
    IconReturnCircle,
    IconSchema,
    IconVisibility,
    IconWarningTriangle,
} from "@mzc-cloudplex/icons";
import { CircledIcon } from "@components_v2/icon/circled-icon";
import PopperMenu from "@components/tooltip/PopperMenu";
import useDialog from "@hooks/useDialog";
import useExtendedCustomMetadata from "@hooks/useExtendedCustomMetadata";
import useCustomContent from "@hooks/useCustomContent";
import DialogDeleteV2 from "@features/dialog/dialog-delete-v2";
import compareVersions, { VERSION_CM_11209_ADD_CUSTOM_METADATA } from "@cores/version";
import { useSelector } from "react-redux";
import { isLocal } from "@cores/enviromentExtension";
import DialogSelectValidatedCustomMetadata from "@features/dialog/custom-metadata/dialog-select-validated-custom-metadata";

const PanelMetadata = ({
    id,
    useCustomMetadata = false,
    data,
    extendedMetadata = {},
    getHiddenFieldValue,
    updateExtendedMetadata = () => {},
}) => {
    const { t } = useTranslation();
    const { dialog, openDialog, closeDialog } = useDialog();
    const { parseCustomMetadata, clearCustomMetadata, customMetadata, customMetadataInfo } = useExtendedCustomMetadata({
        extendedMetadata,
    });
    const { checkIsNull } = useCustomContent();

    const stage = useSelector(({ stage }) => stage);
    const project = useSelector(({ project }) => project);

    const [editValue, setEditValue] = useState({});
    const [actionPending, setActionPending] = useState({});
    const [isEditModeById, setIsEditModeById] = useState();
    const [validationErrors, setValidationErrors] = useState({});

    const onHandleClickClose = (id) => {
        setIsEditModeById({ ...isEditModeById, [id]: false });
    };

    const onHandleClickSubmit = async (el) => {
        setActionPending({ ...actionPending, [el.id]: true });
        await el.onSubmitEditValue(editValue[el.id]);
        onHandleClickClose(el.id);
        setActionPending({ ...actionPending, [el.id]: false });
    };

    const onHandleChangeField = (id, value, validationError) => {
        setEditValue({
            ...editValue,
            [id]: value,
        });

        setValidationErrors({ ...validationErrors, [id]: validationError });
    };

    const onClickGetHiddenFieldValue = async (fieldId) => {
        try {
            await getHiddenFieldValue({ fieldId });
        } catch (e) {
            console.log(e);
        }
    };

    const getItemTemplate = (el) => {
        if (isLocal || window.location.hostname.indexOf("lxhausys.cloudplex.") !== -1) {
            if (el.id === "Lqa574J3evOMxvnjd2g8ZoxX") {
                if (el.value === "APPROVED") {
                    return (
                        <Grid container alignItems="center" gap={0.75}>
                            <IconCompleteCircle size={16} color="success" />
                            <Typography variant={"body1"} color={"success.main"}>
                                결재 완료
                            </Typography>
                        </Grid>
                    );
                } else if (el.value === "SUBMITTED") {
                    return (
                        <Grid container alignItems="center" gap={0.75}>
                            <IconCircleOutlined size={12} color="primary" />
                            <Typography variant={"body1"} color={"primary.main"}>
                                기안 완료
                            </Typography>
                        </Grid>
                    );
                } else if (el.value === "REJECTED") {
                    return (
                        <Grid container alignItems="center" gap={0.75}>
                            <IconReturnCircle size={16} color="warning" />
                            <Typography variant={"body1"} color={"warning.main"}>
                                반려 됨
                            </Typography>
                        </Grid>
                    );
                } else if (el.value === "CANCELED") {
                    return (
                        <Grid container alignItems="center" gap={0.75}>
                            <IconCircleOutlined size={12} htmlColor={theme.palette.greySecondary[500]} />
                            <Typography variant={"body1"} color={theme.palette.greySecondary[500]}>
                                결재 취소
                            </Typography>
                        </Grid>
                    );
                } else if (el.value === "NONE") {
                    return (
                        <Grid container alignItems="center" gap={0.75}>
                            <IconCircleOutlined size={12} htmlColor={theme.palette.greySecondary[500]} />
                            <Typography variant={"body1"} color={theme.palette.greySecondary[500]}>
                                기안 전
                            </Typography>
                        </Grid>
                    );
                }
            }
        }
        return (
            <React.Fragment>
                <MetadataFieldReadMode
                    type={el.type}
                    isHidden={el?.isHidden && !el.isCheckHiddenValue}
                    value={el.value}
                />
                {el?.isHidden &&
                    (el?.isPending ? (
                        <i className="sprite sprite sprite-loading"></i>
                    ) : (
                        <IconButton
                            circled
                            size={"small"}
                            disabled={el.isCheckHiddenValue}
                            onClick={() => {
                                onClickGetHiddenFieldValue(el?.id);
                            }}
                        >
                            {el.isCheckHiddenValue ? (
                                <IconVisibility size={16} />
                            ) : (
                                <CircledIcon size={"24px"} padding={"4px"} color={theme.palette.secondary[50]}>
                                    <IconVisibility size={16} />
                                </CircledIcon>
                            )}
                        </IconButton>
                    ))}
            </React.Fragment>
        );
    };
    const getMetadataListItem = useCallback(
        ({ el, index }) => {
            return (
                <ListItem
                    key={index}
                    sx={{
                        pt: index > 0 ? 1 : 0,
                        px: 1.5,
                        py: 0.25,
                        borderRadius: 0.5,
                        transition: "background-color 0.3s",

                        "&:not(:has(.MuiSwitch-root, .MuiAutocomplete-root, .category-select, .form-input, .MuiTextField-root, .MuiSelect-select))":
                            {
                                "&:hover": {
                                    backgroundColor: alpha(theme.palette.greySecondary[50], 0.5),
                                },
                            },
                    }}
                >
                    <ListItemText
                        disableTypography
                        primary={
                            <>
                                <Typography
                                    required={el.isRequired}
                                    variant={"body2"}
                                    color={theme.palette.greySecondary[600]}
                                    gutterBottom
                                >
                                    {el.subtitle}
                                </Typography>
                                {el.isEditable &&
                                    !isEditModeById?.[el.id] &&
                                    (!el.isHidden || (el.isHidden && el.isCheckHiddenValue)) && (
                                        <Tooltip title={t(`common::label::${"Edit"}`)} sx={{ ml: 1 }}>
                                            <IconButton
                                                className={"btn-edit"}
                                                size={"xsmall"}
                                                circled
                                                variant={"dashed"}
                                                onClick={() => {
                                                    setIsEditModeById({
                                                        ...isEditModeById,
                                                        [el?.id]: true,
                                                    });
                                                }}
                                            >
                                                <IconEditSmall size={12} />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                            </>
                        }
                        secondary={
                            isEditModeById && !isEditModeById?.[el.id] ? (
                                <Grid container gap={1} alignItems={"center"} pt={1}>
                                    {getItemTemplate(el)}
                                </Grid>
                            ) : (
                                <FieldEditBox
                                    submitDisabled={
                                        editValue[el.id] === el.value || actionPending[el.id] || validationErrors[el.id]
                                    }
                                    submitPending={actionPending[el.id]}
                                    onClickSubmit={() => onHandleClickSubmit(el)}
                                    onClickClose={() => onHandleClickClose(el.id)}
                                >
                                    <MetadataFieldEditMode
                                        id={el.id}
                                        type={el.type}
                                        value={editValue[el.id]}
                                        options={el.options}
                                        placeholder={el.placeholder}
                                        disabled={actionPending[el.id] || !el.isEditable}
                                        isEdit={true}
                                        onChangeField={onHandleChangeField}
                                        validationRule={el.validationRule}
                                    />
                                </FieldEditBox>
                            )
                        }
                    />
                </ListItem>
            );
        },
        [editValue, actionPending, isEditModeById, validationErrors],
    );

    const onHandleChangeCustomMetadata = async (customMetadata) => {
        const extendedMetadata = {
            contentSchemaId: customMetadata.id,
            data: {
                fields: customMetadata?.fields
                    ?.filter((el) => el.isEditable && !checkIsNull(el.value))
                    .map(({ id, defaultValue }) => ({ id, value: defaultValue }))
                    .flat(),
            },
        };
        await updateExtendedMetadata(extendedMetadata);
    };

    const onHandleDeleteCustomMetadata = async () => {
        await updateExtendedMetadata({ extendedMetadata: null });
        clearCustomMetadata();
    };

    const initEditValue = useCallback(() => {
        const updatedEditValue = {};
        if (data) data?.forEach((group) => group?.attributions?.forEach((el) => (updatedEditValue[el.id] = el.value)));
        if (customMetadata) customMetadata.forEach((el) => (updatedEditValue[el.id] = el.value));

        setEditValue({ ...editValue, ...updatedEditValue });
    }, [data, customMetadata, editValue]);

    const initEditMode = useCallback(() => {
        let editMode = {};
        if (data) {
            data.forEach((el) => {
                el.attributions?.forEach((el) => {
                    editMode[el.id] = false;
                });
            });
        }
        if (customMetadata) {
            customMetadata.forEach((el) => {
                editMode[el.id] = false;
            });
        }
        setIsEditModeById({ ...isEditModeById, ...editMode });
    }, [data, customMetadata, isEditModeById]);

    useEffect(() => {
        if (!data && !customMetadata) return;
        initEditMode();
        initEditValue();
    }, [data, customMetadata]);

    if (!editValue) return <></>;
    return (
        <Box pt={2.5} pl={2.5} pr={1} pb={2.5}>
            {data?.map((group, index) => (
                <div key={index}>
                    <Stack>
                        <Grid container>
                            <List
                                disablePadding
                                sx={{ p: 0, width: "100%", display: "flex", flexDirection: "column", gap: 1.5 }}
                            >
                                {group?.attributions?.map((el, index) => getMetadataListItem({ el, index }))}
                            </List>
                        </Grid>
                    </Stack>
                    {index < data.length - 1 && (
                        <Divider flexItem orientation={"horizontal"} sx={{ mt: 2.5, mb: 2.5 }} />
                    )}
                </div>
            ))}

            {compareVersions(stage.version, VERSION_CM_11209_ADD_CUSTOM_METADATA) >= 0 && useCustomMetadata && (
                <>
                    <Divider flexItem orientation={"horizontal"} sx={{ mt: 2.5, mb: 2.5 }} />
                    <Stack>
                        <Grid container flexDirection={"row"} sx={{ px: 1.5, pt: 1, pb: 3 }} alignItems={"center"}>
                            <Grid item flex={1}>
                                <Button
                                    color="secondary"
                                    startIcon={<IconSchema size={20} />}
                                    label="Button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openDialog("selectCustomMetadata");
                                    }}
                                    size="medium"
                                    type="button"
                                    variant="outlined"
                                >
                                    {t(`common::label::Custom Metadata`, `Custom Metadata`)}
                                </Button>
                            </Grid>
                            <Grid item>
                                <PopperMenu
                                    ButtonComponent={
                                        <span>
                                            <IconButton component={"span"} circled>
                                                <IconMoreVert size={16} />
                                            </IconButton>
                                        </span>
                                    }
                                    menuButtons={[
                                        {
                                            skipTranslationLabel: t(`common::label::Unapply`, "Unapply"),
                                            onClick: (e) => {
                                                openDialog("deleteCustomMetadata");
                                            },
                                            enable: true,
                                            buttonDisabled: Object.keys(extendedMetadata).length === 0,
                                        },
                                    ]}
                                />
                            </Grid>
                        </Grid>
                    </Stack>
                    {customMetadataInfo?.name && (
                        <Grid container sx={{ pl: 1.5, pr: 1.5, pt: 1, pb: 1.5 }} alignItems={"center"} gap={0.75}>
                            <IconSchema size={16} htmlColor={theme.palette.success.main} />
                            <Typography variant={"body1_500"}>{customMetadataInfo?.name}</Typography>
                        </Grid>
                    )}
                    {customMetadata &&
                        (customMetadata.length > 0 ? (
                            <Stack>
                                <Grid container>
                                    <List
                                        disablePadding
                                        sx={{ p: 0, width: "100%", display: "flex", flexDirection: "column", gap: 1.5 }}
                                    >
                                        {parseCustomMetadata(customMetadata)?.map((el, index) =>
                                            getMetadataListItem({ el, index }),
                                        )}
                                    </List>
                                </Grid>
                            </Stack>
                        ) : (
                            <Grid
                                container
                                flexDirection={"column"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                gap={1}
                                sx={{
                                    border: `1px solid ${theme.palette.secondary[100]}`,
                                    borderRadius: "4px",
                                }}
                            >
                                <Grid item mt={5}>
                                    <Typography variant={"body1_500"} component={"span"}>
                                        {t(
                                            `common::msg::There is no custom metadata yet.`,
                                            `There is no custom metadata yet.`,
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item mb={5}>
                                    <Typography
                                        variant={"body1_400"}
                                        component={"span"}
                                        color={theme.palette.greySecondary[600]}
                                    >
                                        {t(
                                            `common::msg::To enter metadata, select one of the custom metadata options.`,
                                            `To enter metadata, select one of the custom metadata options.`,
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        ))}
                </>
            )}
            {dialog === "selectCustomMetadata" && (
                <DialogSelectValidatedCustomMetadata
                    contentSchemaId={extendedMetadata?.contentSchemaId}
                    onClickSubmit={onHandleChangeCustomMetadata}
                    onClickClose={closeDialog}
                />
            )}
            {dialog === "deleteCustomMetadata" && (
                <DialogDeleteV2
                    title={t(
                        `common::msg::Are you sure you want to turn off applying custom metadata?`,
                        "Are you sure you want to turn off applying custom metadata?",
                    )}
                    items={[extendedMetadata?.contentSchemaId]}
                    contents={(item, count) => (
                        <Stack direction={"row"}>
                            <Stack pr={1}>
                                <IconWarningTriangle size={16} color={"warning"} />
                            </Stack>
                            <Stack>
                                <Typography>
                                    {t(
                                        `common::msg::If you turn off Custom Apply All Data, your input data is gone and cannot be recovered.`,
                                        `If you turn off Custom Apply All Data, your input data is gone and cannot be recovered.`,
                                    )}
                                </Typography>
                            </Stack>
                        </Stack>
                    )}
                    deleteButtonLabel={t(`common::label::Unapply`, "Unapply")}
                    bulkDelete={false}
                    onClickSubmit={onHandleDeleteCustomMetadata}
                    afterMutation={() => setActionPending()}
                    onClickClose={closeDialog}
                />
            )}
        </Box>
    );
};

export default PanelMetadata;
