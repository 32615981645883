import React, { FC, useCallback } from "react";
import { StyledAssetTypeFlag } from "./content-type.styled";
import { Grid } from "@mui/material";

import {
    IconAudio,
    IconCaption,
    IconFile,
    IconImage,
    IconVideo,
    IconPhotoType,
    IconContentsVideo,
    IconVideoType,
    IconAudioType,
    IconPeopleType,
    IconContents,
} from "@mzc-cloudplex/icons";
import { CONTENT_TYPE } from "@constants";

const ContentTypeFlag = ({ minWidth, width, height, type }) => {
    const getBackgroundColor = useCallback((type) => {
        switch (type) {
            case CONTENT_TYPE.VIDEO:
            case CONTENT_TYPE.CUSTOM:
                return "#405fff";
            case CONTENT_TYPE.PHOTO:
                return "#e86894";
            case CONTENT_TYPE.MUSIC:
                return "#e9739b";
            case CONTENT_TYPE.PEOPLE:
                return "#5f62e5";
            default:
                return "";
        }
    }, []);

    return (
        <StyledAssetTypeFlag
            sx={{
                minWidth,
                width,
                height,
                color: `#fff`,
                backgroundColor: getBackgroundColor(type),
            }}
        >
            {type === CONTENT_TYPE.VIDEO && <IconVideoType size={16} color={"inherit"} />}
            {type === CONTENT_TYPE.MUSIC && <IconAudioType size={16} color={"inherit"} />}
            {type === CONTENT_TYPE.PHOTO && <IconPhotoType size={16} color={"inherit"} />}
            {type === CONTENT_TYPE.PEOPLE && <IconPeopleType size={16} color={"inherit"} />}
            {type === CONTENT_TYPE.CUSTOM && <IconContents size={16} color={"inherit"} />}
        </StyledAssetTypeFlag>
    );
};

export default ContentTypeFlag;
