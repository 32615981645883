import { handleActions, createAction } from "redux-actions";

import { axios, axiosWithoutHeaders } from "../cores/axiosWrapper";
import HiddenMetadataApi from "@modules/apis/hiddenMetadata";

function postMetadataFieldAPI(stageId, apiEndpoint, data) {
    return axios.post(`${apiEndpoint}/metadata-fields`, data, {
        headers: {
            stageId,
        },
    });
}

function patchMetadataFieldAPI(stageId, apiEndpoint, id, data) {
    return axios.patch(
        `${apiEndpoint}/metadata-fields/${id}`,
        {
            ...data,
        },
        {
            headers: {
                stageId,
            },
        },
    );
}

//action type
const METADATA_FIELD_MANAGEMENT_PENDING = "METADATA_FIELD_MANAGEMENT_PENDING";
const METADATA_FIELD_MANAGEMENT_FAILURE = "METADATA_FIELD_MANAGEMENT_FAILURE";

const METADATA_FIELD_MANAGEMENT_PATCH_METADATA_FIELD_SUCCESS = "METADATA_FIELD_MANAGEMENT_PATCH_METADATA_FIELD_SUCCESS";

const METADATA_FIELD_MANAGEMENT_CTEATE_METADATA_FIELD_SUCCESS =
    "METADATA_FIELD_MANAGEMENT_CTEATE_METADATA_FIELD_SUCCESS";

const GET_HIDDEN_METADATA_VALUE_PENDING = "GET_HIDDEN_METADATA_VALUE_PENDING";
const GET_HIDDEN_METADATA_SET_EMPTY = "GET_HIDDEN_METADATA_SET_EMPTY";
const GET_HIDDEN_METADATA_VALUE_SUCCESS = "GET_HIDDEN_METADATA_VALUE_SUCCESS";
const GET_HIDDEN_METADATA_VALUE_FAILURE = "GET_HIDDEN_METADATA_VALUE_FAILURE";

//reducer
const initialState = {
    pending: false,
    error: false,
    metadataField: {},
};

export default handleActions(
    {
        [METADATA_FIELD_MANAGEMENT_PENDING]: (state, action) => {
            return {
                ...state,
                pending: true,
            };
        },
        [METADATA_FIELD_MANAGEMENT_FAILURE]: (state, action) => {
            return {
                ...state,
                error: true,
                pending: false,
            };
        },
        [METADATA_FIELD_MANAGEMENT_CTEATE_METADATA_FIELD_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
            };
        },
        [METADATA_FIELD_MANAGEMENT_PATCH_METADATA_FIELD_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                metadataField: data,
                pending: false,
            };
        },
    },
    initialState,
);

export const postMetadataField = (metadataField, callback) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: METADATA_FIELD_MANAGEMENT_PENDING });

    return postMetadataFieldAPI(stage.id, stage.endpoint, metadataField)
        .then((response) => {
            dispatch({
                type: METADATA_FIELD_MANAGEMENT_CTEATE_METADATA_FIELD_SUCCESS,
                payload: response,
            });
            callback(response.data);
        })
        .catch((error) => {
            dispatch({
                type: METADATA_FIELD_MANAGEMENT_FAILURE,
                payload: error,
            });
        });
};

export const patchMetadataField = (id, data) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: METADATA_FIELD_MANAGEMENT_PENDING });

    patchMetadataFieldAPI(stage.id, stage.endpoint, id, data)
        .then((response) => {
            dispatch({
                type: METADATA_FIELD_MANAGEMENT_PATCH_METADATA_FIELD_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: METADATA_FIELD_MANAGEMENT_FAILURE,
                payload: error,
            });
        });
};

export const getHiddenMetadataValue = (resourceType, resourceId, metadataFieldId) => (dispatch, getState) => {
    const { stage, project } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;

    return new Promise((resolve, reject) => {
        HiddenMetadataApi.getHiddenMetadata(stageId, apiEndpoint, projectId, resourceType, resourceId, metadataFieldId)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
