import i18n from "../lib/i18n";
import getByteLength from "./getByteLength";

function validateInput(val, rules) {
    const value = !!val ? val + "" : null;
    if (!rules) {
        return null;
    }

    if (rules.required === true) {
        const isEmpty = !value || value.trim().length === 0;

        if (isEmpty) {
            return {
                type: "REQUIRED",
                level: "error",
            };
        }
    }

    if (rules.sameName === true) {
        return {
            type: "ALREADY_EXIST",
            level: "error",
            message: i18n.t(
                `common::msg::There is already a {{target}} with the same name. Please change it to a different name.`,
                `There is already a {{target}} with the same name. Please change it to a different name.`,
                { target: rules.target },
            ),
        };
    }

    if (rules.isEmail === true) {
        const isEmailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!isEmailPattern.test(val)) {
            return {
                type: "IS_NOT_EMAIL",
                level: "error",
                message: i18n.t(
                    `common::msg::${"This field must be in email address format."}`,
                    `This field must be in email address format.`,
                ),
            };
        }
    }

    if (rules.sameEmail === true) {
        return {
            type: "ALREADY_EXIST",
            level: "error",
            message: i18n.t(
                `common::msg::There is already a {{target}} with the same email. Please change it to a different email.`,
                `There is already a {{target}} with the same email. Please change it to a different email.`,
                { target: rules.target },
            ),
        };
    }

    if (rules.longKey === true) {
        return {
            type: "S3_PATH_BLOCKED",
            level: "error",
            message: i18n.t(
                `common::msg::Your folder name is too long including parent folders, you can shorten the folder name and try again.`,
                `Your folder name is too long including parent folders, you can shorten the folder name and try again.`,
            ),
        };
    }

    if (rules.containSlash === true) {
        const isContainedSlash = value.includes("/");
        if (isContainedSlash) {
            return {
                type: "NAME_ERROR",
                level: "error",
                message: i18n.t(
                    `common::msg::${"{{target}} names can’t contain “/”."}`,
                    `{{target}} names can’t contain “/”.`,
                    { target: rules.target },
                ),
            };
        }
    }

    if (rules.maxByte && rules.maxByte > -1) {
        const isGreatherThanLimit = getByteLength(value, rules.maxByte) > rules.maxByte;
        if (isGreatherThanLimit) {
            return {
                type: "MAX_BYTE",
                level: "error",
                message: i18n.t(
                    `common::msg::${"This field can be up to {{maxByte}} bytes."}`,
                    `This field can be up to {{maxByte}} bytes.`,
                    {
                        maxByte: rules.maxByte,
                    },
                ),
            };
        }
    }

    if (rules.minByte) {
        const isSmallerThanLimit = getByteLength(value, rules.minByte) < rules.minByte;
        if (isSmallerThanLimit) {
            return {
                type: "MIN_BYTE",
                level: "error",
                message: i18n.t(
                    `common::msg::${"This field can be at least {{minByte}} bytes."}`,
                    `This field can be at least {{minByte}} bytes.`,
                    {
                        maxByte: rules.minByte,
                    },
                ),
            };
        }
    }

    if (rules.maxLength) {
        const isGreatherThanLimit = value.length > rules.maxLength;
        if (isGreatherThanLimit) {
            return {
                type: "MAX_LENGTH",
                level: "error",
                message: i18n.t(
                    `common::msg::${"This field can be up to {{maxLength}} characters."}`,
                    `This field can be up to {{maxLength}} characters.`,
                    {
                        maxLength: rules.maxLength,
                    },
                ),
            };
        }
    }

    if (rules.minLength) {
        const isSmallerThanLimit = value.length < rules.minLength;
        if (isSmallerThanLimit) {
            return {
                type: "MIN_LENGTH",
                level: "error",
                message: i18n.t(
                    `common::msg::${"This field can be at least {{minLength}} characters."}`,
                    `This field can be at least {{minLength}} characters.`,
                    {
                        minLength: rules.minLength,
                    },
                ),
            };
        }
    }

    if (rules.isBlockedSpecialChar == true) {
        const isContainSpecialChar = /[~!@#$%^&*()_+|<>?:{}'\\]/gi.test(value);
        if (isContainSpecialChar) {
            return {
                type: "IS_CONTAIN_SPECIAL_CHAR",
                level: "error",
                message: i18n.t(
                    `common::msg::${"Special chacters can not be entered."}`,
                    `Special chacters can not be entered.`,
                ),
            };
        }
    }

    if (rules.uniqueIn) {
        if (rules.uniqueIn.length > 1 && rules.uniqueIn.filter((v) => v && v === value).length > 1) {
            return {
                type: "IS_EXIST_KEY",
                level: "error",
                message: i18n.t(
                    `common::msg::${"Duplicate values. This field must be unique."}`,
                    `Duplicate values. This field must be unique.`,
                ),
            };
        }
    }

    if (rules.isS3SpecialChar === true) {
        const isContainSpecialChar = /[~@#$%^&+|<>?:{}]/gi.test(value);
        if (isContainSpecialChar) {
            return {
                type: "IS_S3_CONTAIN_SPECIAL_CHAR",
                level: "error",
                message: i18n.t(
                    `common::msg::${"Special chacters can not be entered."}`,
                    `Special chacters can not be entered.`,
                ),
            };
        }
    }

    if (rules.pattern) {
        const customPattern = new RegExp(rules.pattern);
        if (!customPattern.test(value)) {
            return {
                type: "CUSTOM_CONTENT_RULE_VIOLATION",
                level: "error",
            };
        }
    }

    if (rules.numberPattern) {
        const isKoreanPattern = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(value);
        const numberPattern = /\d/.test(value);
        if (isKoreanPattern && numberPattern) {
            return {
                type: "IS_KOREAN_NUMBER",
                level: "error",
            };
        }
    }
    // ToDO : validate pattern

    return null;
}

const VALIDATION_MESSAGE_MAP = {
    REQUIRED: `common::msg::${"This field is required."}`,
    MAX_BYTE: `common::msg::${"This field can be up to {{maxByte}} bytes."}`,
    IS_CONTAIN_SPECIAL_CHAR: `common::msg::${"Special chacters can not be entered."}`,
    IS_EXIST_KEY: `common::msg::${"Duplicate values. This field must be unique."}`,
    IS_S3_CONTAIN_SPECIAL_CHAR: `common::msg::${"Special chacters can not be entered."}`,
    CUSTOM_CONTENT_RULE_VIOLATION: `common::msg::The name starts with English, contains at least 2 letters, and can only contain English, spaces, and numbers.`,
    IS_KOREAN_NUMBER: `common::msg::${"Korean can not contain numbers."}`,
};

export function convertValidationMessage(type, options) {
    const d = VALIDATION_MESSAGE_MAP[type];
    if (d) return i18n.t(d, options);

    return null;
}

export default validateInput;
