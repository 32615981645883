import {
  theme as pdcTheme,
  overrideComponents,
  PDCThemeOptions,
  PDCCreateTheme,
} from '@mzc-pdc/ui';

export const projectTheme = {
  ...pdcTheme,
  palette: {
    ...pdcTheme?.palette,
    etc: {
      purple400: '#8660D9',
      purple500: `#5F62E5`

    }
  },
  typography: {
    ...pdcTheme.typography,
  },
  mixins: {
    toolbar: {
      minHeight: `56px`,
      '@media (min-width: 600px)': {
        minHeight: '56px',
      },
    },
  },
  breakpoints: {
    ...pdcTheme.breakpoints,
    values: {
      ...pdcTheme.breakpoints.values,
      xxxl: 1650,
    },
  },
  zIndex: {
    appBar: 500,
    drawer: 500,
    modal: 900,
  },
  // spacing: (factor) => `${factor*0.5}rem`
};

export const projectOverrideTheme: PDCThemeOptions = {
  ...projectTheme, // pdc/ui에 있는 theme
  components: {
    ...overrideComponents(projectTheme),
    MuiCssBaseline: {
      styleOverrides: `
      html {
        overflow: hidden;
        font-size: 16px;
    }
    html,body,#root {
      height: 100%;
    }
    .container {
        height: 100%;
    }
    body, button, input, select, textarea {
        font-family: 'Noto Sans KR', sans-serif;
        color: #3c3d4f;
    }
    a {
      color: inherit;
      text-decoration: none;
    }
    a:focus {
      //outline: thin dotted;
      outline: 0;
    }
    a:hover,
    a:active {
      outline: 0;
    }
    main::-webkit-scrollbar,
    div::-webkit-scrollbar ,
    ul::-webkit-scrollbar {
          width: 14px;
          height: 14px;
        }
    main::-webkit-scrollbar-track,
    div::-webkit-scrollbar-track ,
    ul::-webkit-scrollbar-track {
          background: transparent;
        }
    main::-webkit-scrollbar-thumb,
    div::-webkit-scrollbar-thumb ,
    ul::-webkit-scrollbar-thumb {
          background-color: #dce1e5;
          border-radius: 8px;
          background-clip: padding-box;
          border: 4px solid transparent;
        }
        .MuiList-root:has(.MuiDivider-root)::-webkit-scrollbar-track {
            border-left: 1px solid #dfdfee;
        }
     .MuiButtonBase-root[aria-expanded="true"] {
        background-color: rgba(0, 0, 0, 0.04);
     }
     .MuiInputBase-root .MuiInputBase-input::-webkit-input-placeholder {
      opacity: 1;
      color: #9898AD;
     }
     button {
         margin: 0;
         padding: 0;
         border: none;
         background-color: transparent;
     }
     address {
      font-style: normal;
     }
      .modal-footer {
        button {
          margin-left: 6px;
        }
      }
    `,
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          marginTop: `4px !important`,
        },
      },
    },
    MuiSwitch: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        width: 52,
        height: 34,
        '.MuiSwitch-thumb': {
          backgroundColor:
            ownerState.checked === true
              ? theme.palette[ownerState.color ? ownerState.color : 'success']
                .main
              : theme.palette.greySecondary[300],
        },
        '.Mui-checked': {
          '.MuiSwitch-thumb': {
            backgroundColor:
            theme.palette[ownerState.color ? ownerState.color : 'success'].main,
          },
          '&.Mui-disabled': {
            '.MuiSwitch-thumb': {
              boxShadow: `none`,
              backgroundColor: theme.palette.success[100],
            },
            '+ .MuiSwitch-track': {
              backgroundColor: theme.palette.success[50],
            },
          },
        },
        '.Mui-disabled': {
          '.MuiSwitch-thumb': {
            boxShadow: `none`,
            backgroundColor: theme.palette.greySecondary[200],
          },
          '+ .MuiSwitch-track': {
            opacity: `1 !important`,
            backgroundColor: theme.palette.greySecondary[50],
          },
        },
      }),
      switchBase: {
        left: 3,
        '&.Mui-checked': {
          transform: `translateX(12px)`,
        },
      },
      thumb: {
        width: 16,
        height: 16,
      },
      track:({  theme }) => ({
        opacity: 1,
        backgroundColor: theme.palette.greySecondary[100],
      }),
    }
    },
    
  }

};


const theme = PDCCreateTheme(projectOverrideTheme);
console.log(theme)
export default theme;
