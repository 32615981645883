import React, { useCallback, useState } from "react";
import PropTypes from 'prop-types';
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import { useTranslation } from "react-i18next";
import { uniqueId } from "lodash";
import moment from "moment";

const RangePicker = ({
    id: id_,
    disabled,
    startDate,
    endDate,
    displayFormat,
    showDefaultInputIcon,
    small,
    numberOfMonths,
    minimumNights,
    isOutsideRange,
    inputIconPosition,
    changeDate,
    startDatePlaceholderText,
    endDatePlaceholderText,
    ...otherProps
}) => {
    const [focusedInput, setFocusedInput] = useState(null);
    const [id] = useState(uniqueId());
    const [startDateId] = useState(id_ || `range_date_input_start_${id}`);
    const [endDateId] = useState(id_ || `range_date_input_end_${id}`);
    const { t } = useTranslation();

    const onFocusChange = useCallback((focusedInput) => {
        setFocusedInput(focusedInput);

        let body = document.querySelector("body");
        if (focusedInput === startDateId || focusedInput === endDateId) {
            body.classList.add("datepicker-open");
        } else {
            body.classList.remove("datepicker-open");
        }
    }, [startDateId, endDateId]);

    return (
        <DateRangePicker
            startDate={startDate && moment(startDate)}
            endDate={endDate && moment(endDate)}
            startDateId={startDateId}
            endDateId={endDateId}
            onDatesChange={({ startDate, endDate }) => {
                if (changeDate) changeDate({ startDate, endDate });
            }}
            displayFormat={displayFormat}
            showDefaultInputIcon={showDefaultInputIcon}
            small={small}
            onFocusChange={onFocusChange}
            focusedInput={focusedInput}
            numberOfMonths={numberOfMonths}
            minimumNights={minimumNights}
            inputIconPosition={inputIconPosition}
            startDatePlaceholderText={startDatePlaceholderText || `${t(`common::label::Start date`)}`}
            endDatePlaceholderText={endDatePlaceholderText || `${t(`common::label::End date`)}`}
            isOutsideRange={isOutsideRange}
            {...otherProps}
            disabled={disabled}
        />
    );
};

RangePicker.propTypes = {
    id: PropTypes.string,
    disabled: PropTypes.bool,
    displayFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    showDefaultInputIcon: PropTypes.bool,
    small: PropTypes.bool,
    numberOfMonths: PropTypes.number,
    minimumNights: PropTypes.number,
    isOutsideRange: PropTypes.func,
    inputIconPosition: PropTypes.string,
    changeDate: PropTypes.func,
    startDatePlaceholderText: PropTypes.string,
    endDatePlaceholderText: PropTypes.string,
};

RangePicker.defaultProps = {
    displayFormat: "YYYY-MM-DD",
    showDefaultInputIcon: true,
    small: true,
    numberOfMonths: 1,
    minimumNights: 0,
    isOutsideRange: () => false,
    inputIconPosition: "before",
};

export default RangePicker;
