import { axios, axiosWithoutHeaders } from "../cores/axiosWrapper";
import Polling from "../cores/polling";
import { handleActions, createAction } from "redux-actions";

const IV_CHANNEL_RESET = "IV_CHANNEL_RESET";
const IV_CHANNEL_PENDING = "IV_CHANNEL_PENDING";
// RECORDING
const IV_GET_CHANNEL_RECORDING_SUCCESS = "IV_GET_CHANNEL_RECORDING_SUCCESS";
const IV_GET_CHANNEL_RECORDING_FAILURE = "IV_GET_CHANNEL_RECORDING_FAILURE";

const initialState = {
    pending: false,
    error: false,
    data: [],
    recordings:{
        pending: false,
        error: false,
        data: [],
        totalCount: 0
    }
};

export function getIVChannelsRecordingListAPI(stageId, apiEndpoint, projectId, queries) {
    const { channelId, ...rest } = queries;
    return axios.get(`${apiEndpoint}/interactive-video/channels/${channelId}/recordings`, {
        params: {
            ...rest,
        },
        headers: {
            stageId,
            projectId,
        },
    });
}

export default handleActions({
    [IV_CHANNEL_RESET]: (state, action) => {
        return {
            ...initialState
        }
    },

    [IV_CHANNEL_PENDING]: (state, action) => {
        return {
            ...state,
            pending : true,
            error : false
        }
    },

    [IV_GET_CHANNEL_RECORDING_SUCCESS]: (state, action) => {
        const data = action.payload;
        return {
            ...state,
            recordings:{
                ...state.recordings,
                totalCount: data.totalCount,
                data: data.results,
                pending: false
            }
        }
    },

    [IV_GET_CHANNEL_RECORDING_FAILURE]: (state, action) => {
        return {
            ...state,
            recordings:{
                ...state.recordings,
                pending: false,
                error: true
            }
        }
    },
}, initialState);
