import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const PanelBody = (props) => {
    const { className, ...otherProps } = props;
    return <div {...otherProps} className={clsx("panel-body", className)} />;
};
PanelBody.propTypes = {
    className: PropTypes.string,
};

export default PanelBody;
