import { StyledStatusText } from "./status.styled";
import { useTranslation } from "react-i18next";
import { Typography, CircularProgress } from "@mzc-pdc/ui";
import theme from "@styles/theme";
import {
    IconCircle,
    IconCancelCircle, IconCompleteCircle,
    IconErrorCircle,
    IconCircleOutlined,
    IconPreviewVideo,
} from "@mzc-cloudplex/icons";


const StatusText = ({ status }) => {
    const { t } = useTranslation();

    return (
        <>
            {status === "ACTIVE" && (
                <StyledStatusText>
                    <IconCircle size={10} htmlColor={theme.palette.success[600]} />
                    <Typography variant={"body1_500"} color={theme.palette.success[600]}>
                        {t(`common::label::ACTIVE`, "ACTIVE")}
                    </Typography>
                </StyledStatusText>
            )}

            {status === "INACTIVE" && (
                <StyledStatusText>
                    <IconCircleOutlined
                        size={10}
                        htmlColor={theme.palette.greySecondary[300]}
                    />
                    <Typography variant={"body1_500"} color={theme.palette.greySecondary[300]}>
                        {t(`common::label::INACTIVE`, "INACTIVE")}
                    </Typography>
                </StyledStatusText>
            )}

            {status === "PENDING" && (
                <StyledStatusText>
                    <IconCircle size={10} htmlColor={theme.palette.secondary.light} />
                    <Typography variant={"body1_500"} color={theme.palette.secondary.main}>
                        {t(`common::label::PENDING`, "PENDING")}
                    </Typography>
                </StyledStatusText>
            )}

            {status === "FAILED" && (
                <StyledStatusText>
                    <IconErrorCircle size={16} htmlColor={theme.palette.error.main} />
                    <Typography variant={"body1_500"} color={theme.palette.error.main}>
                        {t("common::label::FAILED", "FAILED")}
                    </Typography>
                </StyledStatusText>
            )}

            {status === "COMPLETE" && (
                <StyledStatusText>
                    <IconCompleteCircle size={16} htmlColor={theme.palette.success.main} />
                    <Typography variant={"body1_500"} color={theme.palette.success.main}>
                        {t("common::label::COMPLETE", "COMPLETE")}
                    </Typography>
                </StyledStatusText>
            )}

            {status === "CANCELED" && (
                <StyledStatusText>
                    <IconCancelCircle size={16} htmlColor={theme.palette.secondary.light} />
                    <Typography variant={"body1_500"} color={theme.palette.secondary.main}>
                        {t("common::label::CANCELED", "CANCELED")}
                    </Typography>
                </StyledStatusText>
            )}

            {status === "INGESTING" && (
                <StyledStatusText>

                    <CircularProgress size={16} thickness={5} color={"warning"} />
                    <Typography variant={"body1_500"} color={theme.palette.warning.main}>
                        {t("common::label::INGESTING", "INGESTING")}
                    </Typography>
                </StyledStatusText>
            )}

            {status === "PROGRESSING" && (
                <StyledStatusText>

                    <CircularProgress size={16} thickness={5} color={"primary"} />
                    <Typography variant={"body1_500"} color={theme.palette.primary.main}>
                        {t("common::label::PROGRESSING", "PROGRESSING")}
                    </Typography>
                </StyledStatusText>
            )}

            {status === "RUNNING" && (
                <StyledStatusText>
                    <IconPreviewVideo size={16} color={"success"} />
                </StyledStatusText>
            )}

            {status === "STOPPING" && (
                <StyledStatusText>
                    <CircularProgress size={16} thickness={5} color={"primary"} />
                    <Typography variant={"body1_500"} color={theme.palette.primary.main}>
                        {t("common::label::STOPPING", "STOPPING")}
                    </Typography>
                </StyledStatusText>
            )}

            {status === "STARTING" && (
                <StyledStatusText>
                    <CircularProgress size={16} thickness={5} color={"warning"} />
                    <Typography variant={"body1_500"} color={theme.palette.warning.main}>
                        {t("common::label::STARTING", "STARTING")}
                    </Typography>
                </StyledStatusText>
            )}

            {status === "UNKNOWN" && (
                <StyledStatusText>
                    <Typography
                        variant={"body1_500"}
                        component={"span"}
                        noWrap
                        color={theme.palette.text.disabled}
                    >
                        {t("common::label::UNKNOWN", "UNKNOWN")}
                    </Typography>
                </StyledStatusText>
            )}


        </>
    );
};

export default StatusText;
