import {
    // axios,
    axiosWithoutHeaders
} from "../cores/axiosWrapper";
import contentTypeConverter from "../cores/contentTypeConverter";

const sendFileDataForPreSigned = ({
    file,
    cancelToken,
    hashedStr,
    onUploadProgress = () => { },
    onComplete = () => { },
    onCancel = () => { },
    onError = (err) => { } }
) => {
    // hash 값을 저장하는 곳에서 사용하기 위함
    file.hash = hashedStr;
    const contentType = contentTypeConverter.getContentTypeFromUrl(file.uploadUrl);
    axiosWithoutHeaders.put(file.uploadUrl, file, {
        headers: {
            "Content-Type": contentType
        },
        onUploadProgress,
        cancelToken
    }).then(({ data }) => {
        onComplete(data);
    }).catch(thrown => {
        if (axiosWithoutHeaders.isCancel(thrown)) {
            onCancel();
        } else {
            onError(thrown);
        }
    });
}

export const sendFileData = ({
    file,
    stageId,
    projectId,
    cancelToken,
    hashedStr,
    onUploadProgress = () => { },
    onComplete = () => { },
    onCancel = () => { },
    onError = () => { } }
) => {
    let params = {
        file, 
        stageId, 
        projectId,
        cancelToken,
        hashedStr,
        onUploadProgress,
        onComplete,
        onCancel,
        onError
    }

    if(!file.uploadUrl) {
        throw new Error("upload url is not exist.");
    }

    sendFileDataForPreSigned(params);
}

export default sendFileData