import { axios } from "../../cores/axiosWrapper";

export function createContentSchemaAPI(apiEndpoint, projectId, data, customErrorMessage) {
    let headers = {};
    if (projectId) headers.projectId = projectId;

    return axios.post(`${apiEndpoint}/v3/content-schemas`, data, {
        headers,
        customErrorMessage,
    });
}

export function getContentSchemaDetailAPI(stageId, apiEndpoint, projectId, schemaId) {
    let headers = {};
    if (projectId) headers.projectId = projectId;

    return axios.get(`${apiEndpoint}/v3/content-schemas/${schemaId}`, {
        headers,
    });
}

export async function getContentFieldTypesAPI(stageId, apiEndpoint, projectId, params) {
    let headers = {};
    if (projectId) headers.projectId = projectId;

    return axios.get(`${apiEndpoint}/v3/content-field-types`, {
        params,
        headers,
    });
}

export async function getContentSchemaUseInProjectsAPI(stageId, apiEndpoint, projectId, schemaId, params) {
    let headers = {};
    if (projectId) headers.projectId = projectId;

    return axios.get(`${apiEndpoint}/v3/content-schemas/${schemaId}/projects`, {
        params,
        headers,
    });
}

export const updateContentSchemaAPI = (apiEndpoint, projectId, schemaId, data) => {
    let headers = {};
    if (projectId) headers.projectId = projectId;

    return axios.patch(`${apiEndpoint}/v3/content-schemas/${schemaId}`, data, {
        headers,
    });
};

export const updateContentSchemaActionAPI = (apiEndpoint, projectId, schemaId, data) => {
    let headers = {};
    if (projectId) headers.projectId = projectId;

    return axios.post(`${apiEndpoint}/v3/content-schemas/${schemaId}/actions`, data, {
        headers,
    });
};

export const deleteContentSchemaAPI = (apiEndpoint, projectId, schemaId) => {
    let headers = {};
    if (projectId) headers.projectId = projectId;

    return axios.delete(`${apiEndpoint}/v3/content-schemas/${schemaId}`, {
        headers,
    });
};

export function updateContentSchemaFieldsAPI(apiEndpoint, projectId, schemaId, data) {
    let headers = {};
    if (projectId) headers.projectId = projectId;

    return axios.post(`${apiEndpoint}/v3/content-schemas/${schemaId}/fields/actions`, data, {
        headers,
    });
}

export function updateContentSchemaFieldAPI(stageId, apiEndpoint, projectId, schemaId, fieldId, data) {
    return axios.post(`${apiEndpoint}/v3/content-schemas/${schemaId}/fields/${fieldId}/actions`, data, {
        headers: {
            projectId,
        },
    });
}

export function updateContentFieldTypeAPI(apiEndpoint, projectId, fieldTypeId, data) {
    let headers = {};
    if (projectId) headers.projectId = projectId;

    return axios.patch(`${apiEndpoint}/v3/content-field-types/${fieldTypeId}`, data, {
        headers: {
            projectId,
        },
    });
}
