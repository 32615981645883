import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const PanelHeaderTitle = (props) => {
    const { className, ...otherProps } = props;
    return <h3 {...otherProps} className={clsx("panel-header-title", className)} />;
};
PanelHeaderTitle.propTypes = {
    className: PropTypes.string,
};

export default PanelHeaderTitle;
