import { handleActions, createAction } from "redux-actions";

const initialState = {
    showModal: false,
    isMinimized: false,
    initialFiles: [], // 선택되어 들어온 대상. (폴더 & 파일)
};

const CREATE_BULK_ASSETS_TOGGLE_MINIMIZE = "CREATE_BULK_ASSETS_TOGGLE_MINIMIZE";
const CREATE_BULK_ASSETS_SHOW = "CREATE_BULK_ASSETS_SHOW";
const CREATE_BULK_ASSETS_RESET = "CREATE_BULK_ASSETS_RESET";

export default handleActions(
    {
        [CREATE_BULK_ASSETS_TOGGLE_MINIMIZE]: (state, action) => {
            const { isMinimized } = action.payload;
            return {
                ...state,
                isMinimized
            };
        },
        [CREATE_BULK_ASSETS_SHOW]: (state, action) => {
            const { initialItems } = action.payload;
            return {
                showModal: true,
                isMinimized: false,
                initialFiles: initialItems
            };
        },
        [CREATE_BULK_ASSETS_RESET]: (state, action) => {
            return initialState;
        },
    },
    initialState,
);

export const toggleMinimize = (isMinimized) => (dispatch, getState) => {
    dispatch({
        type: CREATE_BULK_ASSETS_TOGGLE_MINIMIZE,
        payload: {
            isMinimized
        },
    });
};

export const showModal = (initialItems) => (dispatch, getState) => {
    dispatch({
        type: CREATE_BULK_ASSETS_SHOW,
        payload: {
            initialItems
        },
    });
};

export const closeModal = createAction(CREATE_BULK_ASSETS_RESET);