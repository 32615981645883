import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { getMetadataIcon } from "@features/content-schema-fields/content-schema-type-icons";
import { Box, Divider, Grid, Stack } from "@mzc-pdc/ui";
import Typography from "@components_v2/typography/typography";
import theme from "@styles/theme";
import { IconBack } from "@mzc-cloudplex/icons";
import { WarningMessage } from "@features/empty-list-view";
import { CircledButtonIcon } from "@components_v2/icon";
import MetadataFieldEditMode from "@features/metadata-fields/metadata-field-edit-mode";

const CustomMetadataEdit = ({
    hidden = false,
    contentSchemaId,
    contentSchemaName,
    isRequiredFields,
    onChangeField,
    onChangeViewMode,
}) => {
    const { t } = useTranslation();

    const totalCount = useMemo(() => {
        return isRequiredFields?.length;
    }, [isRequiredFields]);

    const isCompletedFieldsCount = useMemo(() => {
        return isRequiredFields?.filter((field) => !field.validationError).length;
    }, [isRequiredFields]);

    if (hidden) return null;
    return (
        <Grid container flexDirection={"column"} gap={1} pb={2}>
            <Stack>
                <Stack direction={"row"} gap={theme.spacing(1)}>
                    <CircledButtonIcon size={24} color={theme.palette.primary.light} onClick={onChangeViewMode}>
                        <IconBack size={16} htmlColor={theme.palette.common.white} />
                    </CircledButtonIcon>
                    <Typography variant={"subtitle2_700"}>
                        <Trans
                            i18nKey={`common::msg::Enter required fields for “{{name}}” <0>{{count}}</0>/{{total}}`}
                            values={{ name: contentSchemaName, count: isCompletedFieldsCount, total: totalCount }}
                            components={[<Typography variant={"subtitle2_700"} color={theme.palette.success.main} />]}
                        />
                    </Typography>
                </Stack>
            </Stack>
            <Stack>
                {contentSchemaId && (
                    <WarningMessage
                        message={t(
                            `common::msg::If you change the applied custom metadata, all data entered will be lost.`,
                        )}
                    />
                )}
            </Stack>
            <Stack
                direction={"column"}
                sx={{ border: `1px solid ${theme.palette.greySecondary[100]}` }}
                p={theme.spacing(2.5)}
            >
                {isRequiredFields?.map((field, index) => (
                    <React.Fragment key={index}>
                        <Grid>
                            {index > 0 && <Divider orientation={"horizontal"} flexItem sx={{ my: theme.spacing(2) }} />}
                        </Grid>
                        <Grid
                            key={`metadata-field-${field.id}-wrapper`}
                            container
                            flexDirection={"row"}
                            alignItems="flex-start"
                            gap={theme.spacing(1.5)}
                        >
                            <Grid item pt={theme.spacing(0.5)}>
                                <Box
                                    width={20}
                                    height={20}
                                    sx={{
                                        display: `flex`,
                                        alignItems: `center`,
                                        justifyContent: `center`,
                                        backgroundColor: theme.palette.secondary[800],
                                    }}
                                >
                                    {getMetadataIcon(field.type)}
                                </Box>
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    flex: 1,
                                    alignSelf: "flex-start",
                                }}
                            >
                                <Grid container flexDirection={"column"} gap={theme.spacing(1)}>
                                    <Typography variant={"body1"} component={"p"} required={field.isRequired}>
                                        {field.subtitle}
                                    </Typography>
                                    <MetadataFieldEditMode
                                        id={field.id}
                                        type={field.type}
                                        value={field.defaultValue}
                                        disabled={!field.isEditable}
                                        options={field.options}
                                        isEdit={true}
                                        validationRule={field.validationRule}
                                        onChangeField={onChangeField}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                ))}
            </Stack>
        </Grid>
    );
};
export default CustomMetadataEdit;
