"use strict";

import { axios } from "../cores/axiosWrapper";

const SCHEDULES_RESET = 'SCHEDULES_RESET';
const SCHEDULES_PENDING = 'SCHEDULES_PENDING';

const SCHEDULES_GET_SUCCESS = 'SCHEDULES_GET_SUCCESS';
const SCHEDULES_GET_FAILURE = 'SCHEDULES_GET_FAILURE';

export const getSchedulesAPI = async (
  stageId, 
  apiEndpoint, 
  projectId, 
  params, 
) => {
  console.log("params :", params);
  return await axios.get(`${apiEndpoint}/schedules`, {
    headers: { stageId, projectId },
    params,
  });
};

export const createScheduleBulkAPI = (
  stageId, 
  apiEndpoint, 
  projectId, 
  data,
) => {
  // const params = {
  //   type,
  //   schedule: { status },
  // };
  return axios.post(`${apiEndpoint}/schedules/bulk`, data,
    {
      headers: { stageId, projectId },
    },
  );
};

export const deleteScheduleAPI = (
  stageId, 
  apiEndpoint, 
  projectId, 
  id,
  type, 
) => {
  return axios.delete(`${apiEndpoint}/schedules/${id}`, {
    headers: { stageId, projectId },
    params: { type },
  });
};

export const updateScheduleStatusAPI = (
  stageId, 
  apiEndpoint, 
  projectId, 
  id, 
  status,
  type,
) => {
  const params = {
    type,
    schedule: { status },
  };
  return axios.patch(`${apiEndpoint}/schedules/${id}`, params,
    {
      headers: { stageId, projectId },
    },
  );
};

export const startScheduleAPI = (
  stageId, 
  apiEndpoint, 
  projectId, 
  id
) => {
  console.log
  return axios.put(`${apiEndpoint}/schedules/${id}/start`, null,
    {
      headers: { stageId, projectId },
    },
  );
}

export const stopScheduleAPI = (
  stageId, 
  apiEndpoint, 
  projectId, 
  id
) => {
  return axios.put(`${apiEndpoint}/schedules/${id}/stop`, null,
    {
      headers: { stageId, projectId },
    },
  );
}

export const forceEndScheduleAPI = (
  stageId, 
  apiEndpoint, 
  projectId, 
  id
) => {
  return axios.put(`${apiEndpoint}/schedules/${id}/forceEnd`, null,
    {
      headers: { stageId, projectId },
    },
  );
}

export const updateScheduleAPI = (
  stageId,
  apiEndpoint,
  projectId,
  id,
  schedule,
  type,
) => {
  const params = {
    type,
    schedule,
  };
  return axios.patch(`${apiEndpoint}/schedules/${id}`, params,
    { headers: { stageId, projectId }, },
  );
}


