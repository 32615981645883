import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";
import { inActive } from "@modules/rightSideBar";
import { addStoredJobs, startJobStatusPolling } from "@modules/upload";
import { getMyJobs } from "@modules/myJobs";
import { getDownloadJobs, getDownloadJobTask } from "@modules/downloadJobs";
import { listJobs } from "@modules/bulkJobs";
import { abortPreviousMultipartUploads, runUnfinishedUpload } from "@cores/cpmUploadService";
import { getPausedPollingApis, removePausedPollingApis } from "@modules/pausedPollingApis";
import { CONSTANTS, JOB_ID_LCOAL_STORAGE_KEY, JOB_PANEL_TABS, SIDEBAR_BUTTON_ID } from "@constants";
import { isExternalElement } from "@cores/isExternalElement";
import compareVersions, {
    VERSION_CM_11345_PROJECT_COMMENT_SERVICE,
    VERSION_CM_7307_DOWNLOAD,
    VERSION_CM_7349_CREATE_ASSETS_BULK,
    VERSION_CM_9596_UPLOAD_RESUME,
    VERSION_MY_JOBS_PANEL,
} from "@cores/version";
import CopyLineup from "@routes/listings/CopyLineup";
import CommonComment from "./ui/common-comment-service";
import { MyJobListV2 } from "@routes/rightsidebar/ui/MyJobList-v2";
import useMyJobs from "@hooks/useMyJobs";

export const pollingApiMap = {
    ["DOWNLOAD_JOB"]: getDownloadJobs,
    ["MY_JOB"]: getMyJobs,
    ["UPLOAD_FILES"]: runUnfinishedUpload,
    ["TRANSCODE"]: startJobStatusPolling,
    ["BULK_JOB"]: listJobs,
};

const rightSideBarPollingApis = ["DOWNLOAD_JOB", "TRANSCODE", "UPLOAD_FILES", "MY_JOB", "CREATE_ASSET_BULK"];

const RightSideBar = () => {
    const dispatch = useDispatch();

    const { changeMyJobsActiveTab } = useMyJobs({});

    const stage = useSelector((state) => state.stage);
    const project = useSelector((state) => state.project);
    const rightSideBar = useSelector((state) => state.rightSideBar);
    const jobs = useSelector((state) => state.upload.jobs.data);
    const uploadQueue = useSelector((state) => state.uploadQueue);
    const downloadJobs = useSelector((state) => state.downloadJobs);

    const [title, setTitle] = useState("");
    const [getStoredJob, setGetStoredJob] = useState(false);

    const checkJobPolling = async () => {
        rightSideBarPollingApis.forEach((API_KEY) => {
            const apis = dispatch(getPausedPollingApis(API_KEY));

            if (pollingApiMap[API_KEY]) {
                apis.forEach((api) => {
                    if (
                        API_KEY === "UPLOAD_FILES" &&
                        compareVersions(stage.version, VERSION_CM_9596_UPLOAD_RESUME) > 0
                    ) {
                        const keyIndex = uploadQueue["Local"].findIndex(
                            (item) => item.key === api.params.parameters.progressKey,
                        );
                        const targets = uploadQueue["Local"][keyIndex];
                        const files = targets.files;
                        const fileIndex = files.findIndex((item) => item.id === api.params.id);

                        api.params.parameters.fileIndex = fileIndex;

                        pollingApiMap[API_KEY]({ dispatch, ...api.params });
                    } else dispatch(pollingApiMap[API_KEY]());
                });
                dispatch(removePausedPollingApis(API_KEY));
            }
        });
    };

    const makeDownloadJobs = async () => {
        try {
            await dispatch(getDownloadJobs());

            downloadJobs.data &&
                downloadJobs.data.map(async (job) => {
                    const jobId = job.jobId;

                    if (jobId) {
                        await dispatch(getDownloadJobTask({ jobId }));
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };

    const setInitialState = () => {
        setTitle(rightSideBar.type);

        if (compareVersions(stage.version, VERSION_MY_JOBS_PANEL) >= 0) {
            dispatch(getMyJobs());
        }

        if (compareVersions(stage.version, VERSION_CM_7307_DOWNLOAD) >= 0) {
            makeDownloadJobs();
        }

        if (compareVersions(stage.version, VERSION_CM_7349_CREATE_ASSETS_BULK) >= 0) {
            dispatch(listJobs());
        }

        let initialTab = null;
        if (compareVersions(stage.version, VERSION_CM_7349_CREATE_ASSETS_BULK) >= 0) {
            initialTab = JOB_PANEL_TABS.ASSET_JOBS;
        } else if (compareVersions(stage.version, VERSION_CM_7307_DOWNLOAD) >= 0) {
            initialTab = JOB_PANEL_TABS.DOWNLOAD;
        } else {
            initialTab = JOB_PANEL_TABS.TRANSCODE;
        }
        changeMyJobsActiveTab(initialTab);
    };

    const onClickOutside = () => {
        dispatch(inActive());
    };

    const shouldRightSidebarClose = (e) => {
        const isSidebarButton = (e) => e.target.id === SIDEBAR_BUTTON_ID;

        return !isSidebarButton(e) && rightSideBar.active && !isExternalElement(e);
    };

    const handleSelectTab = (title) => {
        setTitle(title);
    };

    useEffect(() => {
        setInitialState();
        if (compareVersions(stage.version, VERSION_CM_9596_UPLOAD_RESUME) >= 0)
            abortPreviousMultipartUploads(stage.endpoint, stage.version, project.id);
        window.addEventListener("online", checkJobPolling);
        return () => {
            window.removeEventListener("online", checkJobPolling);
        };
    }, []);

    useEffect(() => {
        if (!stage || getStoredJob) return;

        // NOTE:  && this.state.title === "My Jobs" 조건이 있었는데 이럴 경우 새로고침 이후 패널을 열지 않으면 헤더의 로딩아이콘이 돌지 않는다.
        const storedJobIds = localStorage.getItem(JOB_ID_LCOAL_STORAGE_KEY);

        if (storedJobIds && storedJobIds.split(",").length > 0) {
            dispatch(
                addStoredJobs({
                    ids: storedJobIds,
                }),
            );
            setGetStoredJob(true);
        }
    }, [stage, getStoredJob]);

    // useEffect(() => {
    //     if (rightSideBar.type === CONSTANTS("RIGHT_SIDE_BAR_TYPES").COMMENT) {
    //         dispatch(inActive());
    //
    //     }
    // }, [project.id]);

    return (
        <div
            id={"right-sidebar"}
            className={`panel panel-cover ${rightSideBar.active ? "open" : ""} ${
                rightSideBar.type === CONSTANTS("RIGHT_SIDE_BAR_TYPES").MY_JOB ? "jobs-panel" : "live-register"
            }`}
            style={{ boxShadow: "-2px 0px 6px 0px rgba(0, 0, 0, 0.20) !important" }}
        >
            <OutsideClickHandler
                onOutsideClick={(e) => {
                    if (shouldRightSidebarClose(e)) {
                        onClickOutside();
                    }
                }}
            >
                <div className="panel-inner">
                    {rightSideBar.type === CONSTANTS("RIGHT_SIDE_BAR_TYPES").MY_JOB && (
                        // <MyJobList jobs={jobs} location={location} />
                        <MyJobListV2 location={location} />
                    )}
                    {rightSideBar.type === CONSTANTS("RIGHT_SIDE_BAR_TYPES").LISTINGS_COPY_TO && <CopyLineup />}
                    {compareVersions(stage.version, VERSION_CM_11345_PROJECT_COMMENT_SERVICE) >= 0 &&
                        rightSideBar.type === CONSTANTS("RIGHT_SIDE_BAR_TYPES").COMMENT && (
                            <CommonComment domain={"PROJECT"} resourceId={project.id} />
                        )}
                </div>
            </OutsideClickHandler>
        </div>
    );
};

export default RightSideBar;
