import React, { useCallback, useMemo, useRef, useState } from "react";
import { Autocomplete_old } from "@components_v2/autocomplete/autocomplete_old";
import { Grid, Typography } from "@mzc-pdc/ui";
import { ImageEmptySearchMini } from "@mzc-cloudplex/icons";
import theme from "@styles/theme";
import { useTranslation } from "react-i18next";
import { StyledAutocompletePopper } from "./project-menu.styled";

const ChipTypesInput = ({ types, options, placeholder, disabled = false, onDeleteType, onAddType, ...rest }) => {
    const { t } = useTranslation();
    const [value, setValue] = useState("");

    const onHandleDeleteType = useCallback(
        (index) => {
            onDeleteType(index);
        },
        [onDeleteType],
    );

    const onHandleSubmitType = useCallback(() => {
        if (!value) return;
        onAddType({ value });
        setValue("");
    }, [value, onAddType]);

    function PopperComponent(props) {
        const { disablePortal, anchorEl, open, ...other } = props;
        return <StyledAutocompletePopper {...other}></StyledAutocompletePopper>;
    }

    return (
        <Autocomplete_old
            open
            type={"options"}
            value={value}
            checkDuplicateValue={false}
            tags={types}
            options={options}
            placeholder={placeholder}
            disabled={disabled}
            setValue={setValue}
            onDelete={onHandleDeleteType}
            onSubmit={onHandleSubmitType}
            onClickOption={({ title, id }) => {
                const index = types?.findIndex((x) => x.value === id);

                if (index !== undefined && index !== -1) onHandleDeleteType(index);
                else onAddType({ value: id, label: title });
            }}
            popperComponent={PopperComponent}
            isEdit={false}
            noOptionsText={
                <Grid
                    container
                    justifyContent={"center"}
                    alignItems={`center`}
                    flexDirection={"column"}
                    sx={{ height: "200px" }}
                >
                    <ImageEmptySearchMini width={24} height={24} />
                    <Typography component={"span"} color={theme.palette.secondary[800]}>
                        {t(`common::msg::No search results found.`)}
                    </Typography>
                </Grid>
            }
        ></Autocomplete_old>
    );
};

export default ChipTypesInput;
