import { styled } from "@mui/material/styles";
import { Grid as MuiGrid } from "@mui/material";
export const FooterWrapper = styled(MuiGrid)(({ theme }) => ({
    position: `relative`,
    minHeight: `48px`,
    margin: `0 auto`,
    padding: `8px 40px 0 40px`,
    width: `100%`,

    " > .MuiDivider-root": {
        position: `absolute`,
        left: `40px`,
        right: `40px`,
        top: 0,
    },

    ".MuiButtonBase-root": {
        whiteSpace: `nowrap`,
    },
    address: {
        display: `flex`,
        alignItems: `center`,
        gap: `4px`,
        fontSize: `12px`,
        color: theme.palette.secondary[600],
    },
    ".MuiGrid-root": {
        ".MuiDivider-root": {
            height: `16px`,
            margin: `0 10px`,
        },
    },

    [theme.breakpoints.down(1200)]: {
        alignItems: `flex-start`,
        ".MuiDivider-root": {
            width: `auto`,
            margin: `0 8px`,
        },
        "&-text.MuiButtonBase-root": {
            minWidth: `auto`,
            paddingLeft: `8px`,
            paddingRight: `8px`,
        },
        ".divider": {
            display: `none`,
        },
        ".address": {
            marginTop: `10px`,
            width: `100%`,
        },
    },
    [theme.breakpoints.down(960)]: {
        flexDirection: `column`,
        alignItems: `flex-start`,
        paddingRight: `88px`,
        " > .MuiDivider-root": {
            left: `40px`,
            right: `88px`,
        },
        "> .MuiGrid-root.MuiGrid-item:last-of-type": {
            marginTop: `32px`,
            flex: `1`,
            alignSelf: `flex-end`,
        },
        ".divider": {
            display: `none`,
        },
    },

    [theme.breakpoints.down(840)]: {
        flexDirection: `column`,
        alignItems: `flex-start`,
        padding: 12,
        " > .MuiDivider-root": {
            left: `12px`,
            right: `12px`,
            margin: 0,
        },
        "> .MuiGrid-root.MuiGrid-item:last-of-type": {
            marginTop: `32px`,
            flex: `1`,
            alignSelf: `flex-end`,
        },
        ".divider": {
            display: `none`,
        },
        ".address": {
            marginTop: `0`,
            width: `100%`,
        },
    },
}));
