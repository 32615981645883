import React from "react";

const DarkButton = (props) => (
    <button
        {...props}
        type="button"
        className={`btn btn-dark ${props.className}`}
    />
);

export default DarkButton;