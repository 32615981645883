import React, { useCallback, useMemo } from "react";
import { Grid } from "@mzc-pdc/ui";
import theme from "@styles/theme";
import { alpha, styled } from "@mui/system";
import {
    IconContents,
    ImageDefaultAudioDark,
    ImageDefaultAudioSmallDark,
    ImageDefaultCollectionDark,
    ImageDefaultCollectionSmallDark,
    ImageDefaultCustomContentDark,
    ImageDefaultFileDark,
    ImageDefaultFileSmallDark,
    ImageDefaultImageDark,
    ImageDefaultImageSmallDark,
    ImageDefaultTextDark,
    ImageDefaultTextSmallDark,
    ImageDefaultVideoDark,
    ImageDefaultVideoSmallDark,
} from "@mzc-cloudplex/icons";

const CardThumbnail = styled(Grid)(({ index }) => ({
    width: `100%`,
    height: `100%`,
    position: `relative`,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
}));

const TableThumbnail = styled(Grid)(() => ({
    minWidth: `100%`,
    width: `100%`,
    height: `100%`,
    position: `relative`,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    borderRadius: 4,
}));

const CustomContentThumbnail = ({ content, size, variant, selected }) => {
    const getThumbnails = useMemo(() => {
        if (!content) return <></>;

        const previews = content?.previewAsset?.previews;

        let parsedPreviews = new Array(4)
            .fill()
            .map((value, index) => (index < previews?.length ? previews[index] : null));

        const preview = previews?.[0];

        if (variant === "table") {
            return (
                <>
                    <TableThumbnail item>
                        {preview?.accessUrl ? (
                            <img
                                src={preview.accessUrl}
                                alt={`collection-table-preview-${content.id}`}
                                onError={(e) => (e.target.hidden = true)}
                            />
                        ) : (
                            <IconContents size={22} />
                        )}
                    </TableThumbnail>
                </>
            );
        } else {
            return (
                <>
                    <CardThumbnail item>
                        {preview?.accessUrl ? (
                            <img src={preview.accessUrl} alt={`collection-card-preview-${content.id}`} />
                        ) : (
                            <ImageDefaultCustomContentDark width={46} height={56} />
                        )}
                    </CardThumbnail>
                </>
            );
        }
    }, [content, variant]);

    const getContainerStyle = useMemo(() => {
        switch (variant) {
            case "table":
                return {
                    minWidth: size === "xsmall" ? "40px" : "52px",
                    width: size === "xsmall" ? "40px" : "52px",
                    height: size === "xsmall" ? "40px" : "52px",
                    padding: 0,
                    borderRadius: "4px",
                    marginRight: `14px`,
                    "&:hover": {
                        backgroundColor: theme.palette.greySecondary[50],
                    },
                };
            case "card":
                return {
                    minWidth: "100%",
                    width: "202px",
                    height: "202px",
                    padding: `26px 0 26px 0`,
                    borderRadius: "8px",
                    marginRight: 0,
                    "&:hover": {
                        backgroundColor: theme.palette.greySecondary[50],
                        border: content.status === "ACTIVE" ? undefined : `1px dashed ${theme.palette.grey[600]}`,
                        ".MuiGrid-item": {
                            outlineColor: theme.palette.greySecondary[50],
                        },
                    },
                    border:
                        content.status === "ACTIVE" ? undefined : `1px dashed ${alpha(theme.palette.grey[600], 0.5)}`,
                };
            case "panel":
                return {
                    minWidth: "100%",
                    width: "100%",
                    height: "225px",
                    padding: `12px 54px 12px 24px`,
                };
            default:
                return {};
        }
    }, [variant]);

    return (
        <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            flexWrap={"nowrap"}
            sx={{
                ...getContainerStyle,
                position: `relative`,
                backgroundColor: selected
                    ? alpha(theme.palette.greySecondary.A100, 0.4)
                    : alpha(theme.palette.greySecondary[50], 0.5),

                "& img": {
                    objectFit: `cover`,
                    maxWidth: `100%`,
                    maxHeight: `100%`,
                    borderRadius: `4px`,
                },
                ".MzcCpIcon-root + * ": {
                    marginLeft: 0,
                },
            }}
        >
            {getThumbnails}
        </Grid>
    );
};

export default CustomContentThumbnail;
