import theme from "@styles/theme";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CollectionColumn from "@components/view/panel-details-view/panel-table-columns-collections";
import { Badge, Grid, IconButton } from "@mzc-pdc/ui";
import Typography from "@components_v2/typography/typography";
import CollectionThumbnail from "@features/thumbnail/collection-thumbnail";
import AutocompleteTable from "@features/autocomplete-table/autocomplete-table";
import AutocompleteTableList from "@features/autocomplete-table/autocomplete-table-list";
import SkeletonCollectionsTable from "@features/autocomplete-table/autocomplete-table-skeleton/autocomplete-table-skeleton-collections";
import { getCollectionsAPI } from "@modules/apis/collections";
import { useSelector } from "react-redux";
import TableCard from "@components_v2/card/table-card";
import { IconCancel } from "@mzc-cloudplex/icons";
import Tooltip from "@components_v2/Tooltip/tooltip";

const LinkedCollectionTableInput = ({ id, value, editValue, onChange }) => {
    const { t } = useTranslation();
    const stage = useSelector(({ stage }) => stage);
    const project = useSelector(({ project }) => project);

    const [nextToken, setNextToken] = useState();
    const [pending, setPending] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [debouncedValue, setDebouncedValue] = useState("");
    const [collections, setCollections] = useState([]);

    const onHandleSearchCollection = (val) => {
        if (val !== searchValue) {
            setSearchValue(val);
        }
    };

    const onHandleFetchCollection = async () => {
        try {
            if (nextToken === null || pending) return;
            if (!nextToken) {
                setCollections([]);
            }
            setPending(true);

            const params = { q: debouncedValue, nextToken: nextToken, limit: 20 };

            const response = await getCollectionsAPI(stage.endpoint, project.id, params);
            setCollections((prev) => [...prev, ...response.data.results?.filter((collection) => collection.id !== id)]);
            setNextToken(response.data.nextToken ?? null);
        } catch (error) {
            console.error(error);
        } finally {
            setPending(false);
        }
    };

    const onHandleClickDeleteRow = (id) => {
        if (!editValue) return;
        onChange(editValue.filter((value) => value.id !== id));
    };

    const getCollectionTableValueByColumn = useCallback((columnId, option) => {
        if (columnId === "name")
            return (
                <TableCard
                    thumbnail={<CollectionThumbnail collection={option} size={"xsmall"} variant={"table"} />}
                    name={{
                        name: (
                            <Typography variant={"body1_500"} component={"span"}>
                                {option?.name}
                            </Typography>
                        ),
                    }}
                />
            );
        if (columnId === "count")
            return (
                <Grid item>
                    <Badge
                        sx={{
                            ".MuiBadge-badge": {
                                position: `static`,
                                minWidth: "30px",
                                height: 18,
                                transform: `none`,
                                backgroundColor: option?.assetTotalCount
                                    ? theme.palette.success[600]
                                    : theme.palette.secondary[500],
                                color: theme.palette.common.white,
                                fontSize: theme.typography.body3_700,
                            },
                        }}
                        badgeContent={option.assetTotalCount > 99 ? "99+" : option?.assetTotalCount?.toString() ?? 0}
                    />
                </Grid>
            );
        return null;
    }, []);

    useEffect(() => {
        if (searchValue === null) return;
        const debounce = setTimeout(() => {
            setNextToken();
            setDebouncedValue(searchValue);
        }, 300);
        return () => clearTimeout(debounce);
    }, [searchValue]);

    useEffect(() => {
        onHandleFetchCollection();
    }, [debouncedValue]);

    return (
        <Grid
            item
            sx={{
                width: `-webkit-fill-available`,
                p: 2.5,
                borderRadius: `4px`,
                border: `1px solid ${theme.palette.secondary[100]}`,
            }}
        >
            <Grid item>
                <AutocompleteTable
                    type={"table"}
                    nextOptions={false}
                    placement={"top-end"}
                    placeholder={t(`common::msg::Please search by collection name or ID.`)}
                    onSubmit={(checkedList) => onChange([...editValue, ...checkedList])}
                    columns={CollectionColumn}
                    skeletonColumns={<SkeletonCollectionsTable columns={CollectionColumn} />}
                    multiSelect={true}
                    options={collections.filter(({ id }) => !editValue.find((edit) => edit.id === id))}
                    isPending={pending}
                    getValueByColumn={getCollectionTableValueByColumn}
                    onSearch={onHandleSearchCollection}
                    onFetchData={onHandleFetchCollection}
                    disablePortal={true}
                />
            </Grid>
            <Grid item mt={2} mb={1}>
                <Typography variant={"body1"} color={theme.palette.secondary[800]}>
                    {t(`common::label::Selected Collections`)} (
                    <Typography variant={"body1"} color={theme.palette.primary[400]}>
                        {editValue?.length ?? 0}
                    </Typography>
                    )
                </Typography>
            </Grid>

            <AutocompleteTableList
                type={value.type?.toUpperCase()}
                data={editValue ? [{ value: editValue }] : []}
                columns={CollectionColumn}
                onFetchData={value.onFetchData}
                noDataContent={
                    <Grid
                        container
                        sx={{
                            height: "60px",
                            p: 1.5,
                            borderRadius: `4px`,
                            border: `1px solid ${theme.palette.secondary[100]}`,
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Typography>
                            {t(
                                `common::msg::Select collections through search to put them in your collection.`,
                                `Select collections through search to put them in your collection.`,
                            )}
                        </Typography>
                    </Grid>
                }
                gridStyle={{ maxHeight: "336px" }}
                menuActions={(item) => (
                    // 버튼 onClick에 대한 수정 필요함
                    <Tooltip title={t(`common::label::Delete`)}>
                        <IconButton
                            size="small"
                            circled
                            onClick={(e) => {
                                onHandleClickDeleteRow(item.id);
                            }}
                        >
                            <IconCancel size={12} htmlColor={theme.palette.grey[700]} />
                        </IconButton>
                    </Tooltip>
                )}
            />
        </Grid>
    );
};

export default LinkedCollectionTableInput;
