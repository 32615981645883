import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { MEDIA_TYPES, POPUP_MODAL_SIZE, PUBLIC_URL } from "@constants";
import formatDuration from "@cores/formatDuration";
import LoadableVideoPlayer from "../../videoPlayer/LoadableVideoPlayer";
import { Box, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mzc-pdc/ui";
import { IconPreviewCaption } from "@mzc-cloudplex/icons";
import menuService from "@services/menuService";
import theme from "@styles/theme";
import Typography from "@components_v2/typography/typography";
import Tooltip from "@components_v2/Tooltip/tooltip";
import { useLocation } from "react-router-dom";
import AssetPreviewByStatus from "@components/property-panel/components/asset-preview-by-status";
import { alpha } from "@mui/material";
import AssetElementPreviewByStatus from "@components/property-panel/components/asset-element-preview-by-status";
import { MediaTypeImage } from "@components/mediaTypeImage";
import MediaImageWrapper from "@routes/asset/components/media-image-wrapper";

const ContentsView = ({ type, data, totalCount, isEncrypted, jobId, isInStorageTab }) => {
    const user = useSelector(({ user }) => user);
    const stage = useSelector(({ stage }) => stage);
    const project = useSelector(({ project }) => project);

    const location = useLocation();
    const { t } = useTranslation();

    const onClickOpenPreviewPopup = (elementId) => {
        const { spaceId, projectId } = menuService.parse(location.pathname);

        let url = `${window.location.origin}${menuService.paths.assetsAssetDetail(
            spaceId,
            projectId,
            data.id,
        )}/captions?s=${elementId}&projectId=${project.id}&popup=true`;

        window.open(
            url,
            `caption-asset-preview-popup`,
            `width=${POPUP_MODAL_SIZE.width},height=${POPUP_MODAL_SIZE.height},scrollbar=1`,
        );
    };

    const assetType = useMemo(() => {
        if (["CAPTION", "DESCRIPTION"].includes(type)) return "CAPTION";
        else return type;
    }, [type]);

    const previewStatus = useMemo(() => {
        return data?.previews?.[0]?.status;
    }, [data, type]);

    const previewElementStatus = useCallback(
        (el) => {
            return el?.previews?.[0]?.status ?? "COMPLETE";
        },
        [data, type],
    );

    const getAssetElementUrl = useCallback((element) => {
        if (!element?.previews?.[0]?.accessUrl && !element?.accessUrl)
            return `${PUBLIC_URL}/images/thumbnail_no_image_l.png`;
        return element.previews?.[0]?.accessUrl ?? element?.accessUrl;
    }, []);

    const filePreview = useMemo(() => {
        const previewUrl = data.previews?.[0]?.accessUrl ?? "COMPLETE";
        if (previewStatus !== "COMPLETE")
            return (
                <MediaImageWrapper
                    status={previewStatus}
                    variant={"rectangular"}
                    sx={{
                        width: `100%`,
                        height: `100%`,
                        display: "flex",
                        position: "static",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: alpha(theme.palette.greySecondary[50], 0.5),
                    }}
                >
                    <AssetPreviewByStatus previewStatus={previewStatus} mediaType={"FILE"} width={46} height={56} />
                </MediaImageWrapper>
            );
        if (!!previewUrl)
            return (
                <div className={`file`} style={{ backgroundColor: "#fff" }}>
                    <img src={previewUrl} style={{ height: "inherit" }} />
                </div>
            );
        return (
            <div className={"file"}>
                <i className="sprite sprite-thumb-file"></i>
            </div>
        );
    }, [data.previews, type]);

    if (type !== MEDIA_TYPES.PEOPLE && (!data || !data.elements || data.elements.length === 0)) {
        return (
            <React.Fragment>
                <div className="empty d-flex flex-column align-items-center justify-content-center">
                    <i className="sprite sprite-nojob"></i>
                    <p>{t(`common::label::${"No asset contents"}`)}.</p>
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {
                {
                    IMAGE: (
                        <>
                            {isInStorageTab ? (
                                !data.elements[0].accessUrl ? (
                                    <div className="thumbnail">
                                        <i className="sprite sprite-thumb-img" />
                                    </div>
                                ) : (
                                    <div className="thumbnail">
                                        <img
                                            src={
                                                data.elements[0].accessUrl ||
                                                `${PUBLIC_URL}/images/thumbnail_no_image.png`
                                            }
                                            onError={(e) =>
                                                (e.target.src = `${PUBLIC_URL}/images/thumbnail_default_image.png`)
                                            }
                                        />
                                    </div>
                                )
                            ) : (
                                <div className={"image"}>
                                    <div className="list-image list-image-md">
                                        <div className="control">
                                            <div className="control-inner justify-content-end">
                                                <div>
                                                    {t(`common::label::${"Total"}`)}{" "}
                                                    <strong className="text-primary">{totalCount}</strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box-scroll">
                                            <ul>
                                                {data &&
                                                    data.elements &&
                                                    data.elements.map((ele, k) => (
                                                        <li key={k}>
                                                            {previewElementStatus(ele) !== "COMPLETE" ? (
                                                                <div className="content">
                                                                    <MediaImageWrapper
                                                                        status={previewElementStatus(ele)}
                                                                        variant={"rectangular"}
                                                                        sx={{
                                                                            width: 72,
                                                                            height: 72,
                                                                            position: `relative`,
                                                                            backgroundColor: theme.palette.common.white,
                                                                        }}
                                                                    >
                                                                        <AssetElementPreviewByStatus
                                                                            previewStatus={previewElementStatus(ele)}
                                                                            thumbnailProps={{
                                                                                padding: 0,
                                                                                width: 47,
                                                                                height: 17,
                                                                            }}
                                                                            thumbnailTime={ele.time}
                                                                            mediaType={data.mediaType}
                                                                            type={data.type}
                                                                            isPreferred={ele.isPreferred}
                                                                            width={28}
                                                                            height={28}
                                                                        />
                                                                    </MediaImageWrapper>
                                                                </div>
                                                            ) : (
                                                                <div className="content">
                                                                    {ele.isPreferred && (
                                                                        <span className="status">
                                                                            <i className="sprite sprite-default"></i>
                                                                        </span>
                                                                    )}
                                                                    <span className="image">
                                                                        <img
                                                                            src={getAssetElementUrl(ele)}
                                                                            style={{
                                                                                width: "72px",
                                                                                height: "72px",
                                                                            }}
                                                                            onError={(e) =>
                                                                                (e.target.src = `${PUBLIC_URL}/images/thumbnail_no_image.png`)
                                                                            }
                                                                        />
                                                                    </span>
                                                                    {ele.time !== undefined && (
                                                                        <span className="time">
                                                                            {formatDuration(ele.time * 1000)}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </li>
                                                    ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    ),
                    VIDEO: (
                        <div className={"video"}>
                            {isInStorageTab &&
                            data.elements &&
                            data.elements[0].accessUrls &&
                            data.elements[0]?.accessUrls[0] === null ? (
                                ""
                            ) : (
                                <LoadableVideoPlayer
                                    stageId={stage.id}
                                    projectId={project.id}
                                    mediaFormat={type}
                                    userId={user.data.id}
                                    jobId={jobId}
                                    vod={{
                                        id: jobId,
                                        Url:
                                            data &&
                                            data.elements &&
                                            data.elements.length > 0 &&
                                            data.elements[0].accessUrls &&
                                            data.elements[0].accessUrls[0],
                                        isEncrypted: isEncrypted,
                                    }}
                                    onLoaded={() => {}}
                                />
                            )}
                        </div>
                    ),
                    AUDIO: (
                        <div className={"audio"}>
                            {data.elements &&
                            data.elements[0]?.accessUrls &&
                            data.elements[0]?.accessUrls[0] === null ? (
                                ""
                            ) : (
                                <LoadableVideoPlayer
                                    stageId={stage.id}
                                    projectId={project.id}
                                    mediaFormat={type}
                                    userId={user.data.id}
                                    jobId={jobId}
                                    vod={{
                                        id: jobId,
                                        Url:
                                            data &&
                                            data.elements &&
                                            data.elements.length > 0 &&
                                            data.elements[0].accessUrls &&
                                            data.elements[0].accessUrls[0],
                                        isEncrypted: isEncrypted,
                                    }}
                                    onLoaded={() => {}}
                                />
                            )}
                        </div>
                    ),
                    PEOPLE: (
                        <div className="people">
                            {data && data.primaryImage && data.primaryImage.accessUrl ? (
                                <div className="thumbnail">
                                    <img
                                        src={
                                            data.primaryImage.accessUrl || `${PUBLIC_URL}/images/thumbnail_no_image.png`
                                        }
                                        onError={(e) => (e.target.src = `${PUBLIC_URL}/images/thumbnail_no_image.png`)}
                                    />
                                </div>
                            ) : (
                                <i className="sprite sprite-thumb-img"></i>
                            )}
                        </div>
                    ),
                    CAPTION: (
                        <Grid
                            item
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                backgroundColor: theme.palette.secondary[50],
                            }}
                            pl={2}
                            pr={2}
                        >
                            <Grid
                                container
                                sx={{
                                    mb: 1,
                                    mt: "10px",
                                    justifyContent: "end",
                                }}
                            >
                                <Typography variant={"body3_500"} sx={{ mr: 0.5 }}>
                                    {t(`common::label::${"Total"}`)}{" "}
                                </Typography>
                                <Typography variant={"body3_700"} color={theme.palette.primary[400]}>
                                    {totalCount}
                                </Typography>
                            </Grid>
                            <Grid
                                sx={{
                                    backgroundColor: theme.palette.common.white,
                                    padding: "2px 2px 10px 2px",
                                    marginBottom: "5px",
                                    borderRadius: "4px",
                                    border: `1px solid ${theme.palette.secondary[100]}`,
                                    height: "150px",
                                    flex: 1,
                                    overflowY: "auto",
                                    overscrollBehavior: "contain",
                                    overflowX: "hidden",
                                }}
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell size={"small"} sx={{ lineHeight: "1rem" }}>
                                                <Typography variant={`body3_500`} color={theme.palette.secondary[600]}>
                                                    {t(`common::label::${"Label"}`)}
                                                </Typography>
                                            </TableCell>
                                            <TableCell colSpan={2} size={"small"} sx={{ lineHeight: "1rem" }}>
                                                <Typography variant={`body3_500`} color={theme.palette.secondary[600]}>
                                                    {t(`common::label::${"Language"}`)}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody
                                        sx={{
                                            "tr td": {
                                                lineHeight: "1rem",
                                            },
                                        }}
                                    >
                                        {data &&
                                            data.elements &&
                                            data.elements.map((c, i) => (
                                                <TableRow key={i}>
                                                    <TableCell size={"small"}>
                                                        {c.language}-{c.country}
                                                    </TableCell>
                                                    <TableCell size={"small"}>
                                                        {c.label}
                                                        {c.isPreferred && <i className="ico-default-cc ml-2"></i>}
                                                    </TableCell>
                                                    <TableCell
                                                        align="right"
                                                        size={"small"}
                                                        sx={{ paddingRight: "0 !important" }}
                                                    >
                                                        <Tooltip title={t("common::label::Preview")}>
                                                            <IconButton
                                                                circled
                                                                size={"medium"}
                                                                onClick={() => onClickOpenPreviewPopup(c.id)}
                                                            >
                                                                <IconPreviewCaption width={16} height={13} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    ),
                    FILE: filePreview,
                }[assetType]
            }
        </React.Fragment>
    );
};

// let mapStateToProps = (state) => {
//     return {
//         global: state.global,
//         user: state.user,
//         stage: state.stage,
//         project: state.project,
//         sources: state.sources,
//     };
// };

// let mapDispatchToProps = (dispatch) => {
//     return {};
// };

export default ContentsView;

// ContentsView = connect(mapStateToProps, mapDispatchToProps)(ContentsView);
// export default withTranslation()(withRouter(ContentsView));
