import {handleActions, createAction} from 'redux-actions';

import {
    axios,
    axiosWithoutHeaders
} from "../cores/axiosWrapper";

import addJobStatusPolling from '../cores/addJobStatusPolling';

import * as jobStatus from '../cores/jobStatus';

function getJobAPI(stageId, apiEndpoint, projectId, id) {
    return axios.get(`${apiEndpoint}/jobs/${id}`, {
        headers : {
            stageId,
            projectId
        }
    });
}

function deleteJobAPI(stageId, apiEndpoint, projectId, id) {
    return axios.delete(`${apiEndpoint}/jobs/${id}`, {
        headers : {
            stageId,
            projectId
        }
    });
}

function correctJobStatusAPI(stageId, apiEndpoint, projectId, id) {
    return axios.put(`${apiEndpoint}/jobs/${id}/status/correct`, null, {
        headers : {
            stageId,
            projectId
        }
    });
}

const RESET = 'RESET';
const JOB_DETAIL_PENDING = 'JOB_DETAIL_PENDING';
const JOB_DETAIL_FAILURE = 'JOB_DETAIL_FAILURE';

const JOB_DETAIL_UPDATE_STATE = 'JOB_DETAIL_UPDATE_STATE';
const JOB_DETAIL_IS_CHANGING_STATUS = 'JOB_DETAIL_IS_CHANGING_STATUS';

const JOB_DETAIL_GET_JOB_SUCCESS = 'JOB_DETAIL_GET_JOB_SUCCESS';
const JOB_DETAIL_DELETE_JOB_SUCCESS = 'JOB_DETAIL_DELETE_JOB_SUCCESS';
const JOB_DETAIL_UPDATE_JOB_DATA = 'JOB_DETAIL_UPDATE_JOB_DATA';

const initialState = {
    data : null,
    pending : false,
    error : false,
    isDeleted : false,
    isChangingStatus : false,
    pollingCancelTokenSource : null
};

export default handleActions({
    [RESET] : (state, action) => {
        return {
            ...initialState
        }
    },
    [JOB_DETAIL_UPDATE_STATE] : (state, action) => {
        return {
            ...state,
            ...action.payload
        };
    },
    [JOB_DETAIL_GET_JOB_SUCCESS] : (state, action) => {
        const { data } = action.payload;

        return {
            ...state,
            data : data,
            pending : false
        };
    },
    [JOB_DETAIL_PENDING] : (state) => {
        return {
            ...state,
            pending : true
        };
    },
    [JOB_DETAIL_IS_CHANGING_STATUS] : (state) => {
        return {
            ...state,
            isChangingStatus : true
        };
    },
    [JOB_DETAIL_FAILURE] : (state) => {
        return {
            ...state,
            pending : false,
            error : true
        };
    },
    [JOB_DETAIL_DELETE_JOB_SUCCESS] : (state) => {
        return {
            ...state,
            pending : false,
            isDeleted : true
        };
    },
    [JOB_DETAIL_UPDATE_JOB_DATA] : (state, action) => {
        const data = action.payload;
        if (
            data &&
            data.status &&
            state.data.status &&
            jobStatus
                .getValuesLowerThan(state.data.status)
                .indexOf(data.status) > -1
        ) {
            delete data.status;
        }

        return {
            ...state,
            pending : false,
            error : false,
            isChangingStatus : false,
            data: {
                ...state.data,
                ...data
            }
        };
    }
}, initialState);

export const reset = createAction(RESET);
export const failure = createAction(JOB_DETAIL_FAILURE);
export const isChangingStatus = createAction(JOB_DETAIL_IS_CHANGING_STATUS);

export const updateState = createAction(JOB_DETAIL_UPDATE_STATE);
export const updateJobData = createAction(JOB_DETAIL_UPDATE_JOB_DATA);

export const getJob = (id) => (dispatch, getState) => {
    const {
        stage,
        project,
        job
    } = getState();

    dispatch({
        type : JOB_DETAIL_PENDING
    });

    getJobAPI(stage.id, stage.endpoint, project.id, id) 
        .then(response => {
            dispatch({
                type : JOB_DETAIL_GET_JOB_SUCCESS,
                payload : response
            });            
            
            if(response.data && !jobStatus.isComplete(response.data.status)) {
                if(job.pollingCancelTokenSource) {                    
                    job.pollingCancelTokenSource.cancel();
                }
            
                const cancelTokenSource = axios._cancelToken.source();
                dispatch({
                    type : JOB_DETAIL_UPDATE_STATE,
                    payload : {
                        pollingCancelTokenSource : cancelTokenSource
                    }
                });
                
                addJobStatusPolling({
                    params : {
                        apiEndpoint: stage.endpoint,
                        stageId : stage.id,
                        projectId : project.id,
                        jobs : [response.data],
                        cancelToken : cancelTokenSource.token,
                        hasAssociations : true
                    },
                    successCallback : (data) => {
                        if(data) {
                            dispatch({
                                type : JOB_DETAIL_UPDATE_JOB_DATA,
                                payload : data
                            });
                        }
                    }
                })
            };
        })
        .catch(error => {
            dispatch({
                type : JOB_DETAIL_FAILURE
            });
        });
};

export const deleteJob = (id) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    dispatch({
        type : JOB_DETAIL_IS_CHANGING_STATUS
    });

    deleteJobAPI(stage.id, stage.endpoint, project.id, id) 
        .then(response => {
            dispatch({
                type : JOB_DETAIL_DELETE_JOB_SUCCESS,
                payload : response
            });
        })
        .catch(() => {
            dispatch({
                type : JOB_DETAIL_FAILURE
            });
        });
};

export const correctJobStatus = (id) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    dispatch({type: JOB_DETAIL_IS_CHANGING_STATUS});

    correctJobStatusAPI(stage.id, stage.endpoint, project.id, id)
        .then((response) => {
                dispatch({
                    type: JOB_DETAIL_UPDATE_JOB_DATA,
                    payload: response.data
                });
            }
        ).catch(error => {
            dispatch({
                type: JOB_DETAIL_FAILURE,
                payload: error
            });
        });
}