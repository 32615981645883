import React, { useEffect, useMemo, useState, useRef, useCallback } from "react";
import { METADATA_FIELDS_TYPE, REFERENCE_FIELDS_TYPE } from "@constants";
import { useDispatch, useSelector } from "react-redux";
import { getAssetsAPI } from "@modules/apis/assets";
import { getCollectionsAPI } from "@modules/apis/collections";
import { getVideosAPI } from "@modules/videos";
import MusicApi from "@modules/apis/music";
import PhotoApi from "@modules/apis/photo";
import { getPeopleListAPI } from "@modules/people";
import { getCustomContentsAPI } from "@modules/apis/custom-contents";
import { getCustomContent } from "@modules/custom-content";
import { useParams } from "react-router-dom";
import {
    CustomContentColumn,
    MusicContentColumn,
    PeopleContentColumn,
    PhotoContentColumn,
    VideoContentColumn,
} from "@features/autocomplete-table/autocomplete-table-columns/autocomplete-table-columns-contents";
import {
    AudioAssetColumn,
    CaptionAssetColumn,
    FileAssetColumn,
    ImageAssetColumn,
    PreviewAssetColumn,
    VideoAssetColumn,
} from "@features/autocomplete-table/autocomplete-table-columns/autocomplete-table-columns-assets";
import {
    CollectionColumn,
    CollectionContentSchemaColumn,
} from "@features/autocomplete-table/autocomplete-table-columns/autocomplete-table-columns-collections";
import * as yup from "yup";
import { useCommonTable } from "@hooks/useCommonTable";

// Note: createDefaultValueSchema 함수에서 사용될 array 검증에 unique value에 대한 조건을 추가하기 위한 함수입니다.
yup.addMethod(yup.array, "unique", function (message, mapper = (a) => a) {
    return this.test("unique", message, function (list) {
        const mappedArray = list.map(mapper);
        const set = new Set(mappedArray);
        if (set.size !== mappedArray.length) {
            const duplicateIndexes = [];
            const valueCount = {};
            mappedArray.forEach((value, index) => {
                if (!value) return;

                if (!valueCount[value]) {
                    valueCount[value] = { count: 1, indexes: [index] };
                } else {
                    valueCount[value].count += 1;
                    valueCount[value].indexes.push(index);
                }
            });

            for (const value in valueCount) {
                if (valueCount[value].count > 1) {
                    duplicateIndexes.push(...valueCount[value].indexes);
                }
            }
            // 중복된 값의 인덱스를 반환합니다.
            return this.createError({
                path: `${duplicateIndexes}`,
                message: `Already exists. Try something else.`,
            });
        }
        return true;
    });
});

const useCustomContent = () => {
    const dispatch = useDispatch();
    const LIMIT = 20;
    const offsetRef = useRef(0);
    const { schemaId, contentId } = useParams();

    const { getResourceType, getTableColumns } = useCommonTable();

    const stage = useSelector(({ stage }) => stage);
    const project = useSelector(({ project }) => project);

    const [results, setResults] = useState([]);
    const [pending, setPending] = useState(false);
    const [nextToken, setNextToken] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [viewMore, setViewMore] = useState(false);
    // const [offset, setOffset] = useState(0);

    useEffect(() => {
        setViewMore(results.length < totalCount || !!nextToken);
    }, [results, totalCount, nextToken]);

    const resetParams = () => {
        setNextToken(null);
        setTotalCount(0);
        setViewMore(false);
        setResults([]);
        offsetRef.current = 0;
    };

    const onResourceFetch = async ({ detailType, ids, searchKeyword }) => {
        if (!detailType) return;

        const type = getResourceType(detailType);
        let params = {
            stageId: stage.id,
            stageEndpoint: stage.endpoint,
            projectId: project.id,
        };
        if (ids) {
            params = { ...params, search: { ids } };
        }

        switch (type) {
            case "ASSET":
                params = {
                    ...params,
                    search: {
                        ...params.search,
                        generateAccessUrl: true,
                        mediaTypes: [getAssetType(detailType)],
                        limit: LIMIT,
                        offset: offsetRef.current,
                        archiveStatus: "ALL", //NOTE: 에셋 필드에 추가 후 아카이브된 에셋도 커스텀 컨텐츠에서 노출시키기 위해 추가
                    },
                };
                if (searchKeyword) params.search = { ...params.search, name: searchKeyword, offset: 0 };
                return await getResourceAssetData(type, params);
            case "CONTENT":
                const contentType = getContentType(detailType);

                if (contentType === "CUSTOM")
                    params = {
                        ...params,
                        search: { ids, limit: LIMIT, contentSchemaId: schemaId, nextToken: nextToken },
                    };
                else
                    params = {
                        ...params,
                        search: {
                            ...params.search,
                            generateAccessUrl: true,
                            limit: LIMIT,
                            offset: offsetRef.current,
                        },
                    };

                if (searchKeyword) params.search = { ...params.search, name: searchKeyword, offset: 0 };
                return await getResourceAssetData(contentType, params);
            case "COLLECTION":
                params = { ...params, search: { ids: params.search?.ids?.join(","), nextToken: nextToken } };
                if (searchKeyword) params.search = { ...params.search, name: searchKeyword, offset: 0 };
                return await getResourceAssetData(type, params);
        }
    };
    const getResourceAssetData = async (type, params) => {
        try {
            let response = null;
            if (pending) return;
            setPending(true);
            const { stageId, stageEndpoint, projectId, search } = params;

            const preResults = offsetRef.current > 0 ? results : [];

            if (type === "ASSET") {
                response = await getAssetsAPI(stageId, stageEndpoint, projectId, search);
                offsetRef.current = offsetRef.current + LIMIT;
                setResults([...preResults, ...response.data?.assets]);
                setTotalCount(response.data?.totalCount);
                return [...preResults, ...response.data?.assets];
            } else if (type === "COLLECTION") {
                response = await getCollectionsAPI(stageEndpoint, projectId, search);
                setResults([...preResults, ...response.data?.results]);
                setNextToken(nextToken);
                return [...preResults, ...response.data?.results];
            } else if (type === "VIDEO") {
                response = await getVideosAPI(stageId, stageEndpoint, projectId, search);
                offsetRef.current = offsetRef.current + LIMIT;
                const newResponse = response.data?.videos.map((el) => ({ ...el, contentType: type }));
                setResults([...preResults, ...newResponse]);
                setTotalCount(response.data?.totalCount);
                return [...preResults, ...newResponse];
            } else if (type === "MUSIC") {
                response = await MusicApi.getMusicsAsync(stageId, stageEndpoint, projectId, search);
                const newResponse = response.data?.contents.map((el) => ({ ...el, contentType: type }));
                offsetRef.current = offsetRef.current + LIMIT;
                setResults([...preResults, ...newResponse]);
                setTotalCount(response.data?.totalCount);
                return [...preResults, ...newResponse];
            } else if (type === "PHOTO") {
                response = await PhotoApi.getPhotosAsync(stageId, stageEndpoint, projectId, search);
                const newResponse = response.data?.contents.map((el) => ({ ...el, contentType: type }));
                offsetRef.current = offsetRef.current + LIMIT;
                setResults([...preResults, ...newResponse]);
                setTotalCount(response.data?.totalCount);
                return [...preResults, ...newResponse];
            } else if (type === "PEOPLE") {
                response = await getPeopleListAPI(stageId, stageEndpoint, projectId, search);
                const newResponse = response.data?.people.map((el) => ({ ...el, contentType: type }));
                offsetRef.current = offsetRef.current + LIMIT;
                setResults([...preResults, ...newResponse]);
                setTotalCount(response.data?.totalCount);
                return [...preResults, ...newResponse];
            } else if (type === "CUSTOM") {
                response = await getCustomContentsAPI(stageId, stageEndpoint, projectId, search);
                const newResponse = response.data?.results.map((el) => ({ ...el, contentType: type }));
                setNextToken(response.data?.nextToken);
                setResults([...preResults, ...newResponse]);
                return [...preResults, ...newResponse];
            }
            return [];
        } catch (e) {
            console.error(e);
        } finally {
            setPending(false);
        }
    };

    const getResourceTitle = (isReference, detailType) => {
        const detailTypeArray = detailType.split("_");
        let title = detailTypeArray.map((el) => el.charAt(0) + el.slice(1).toLowerCase()).join(" ");
        return `${isReference ? "Reference" : "Metadata"} ${title}`;
    };

    const getOptionsBySelectType = (field, fieldType) => {
        const isSingleEnum = fieldType === METADATA_FIELDS_TYPE.SINGLE_SELECT;
        if (isSingleEnum) {
            return field?.fieldType?.dataType?.enum;
        } else {
            const properties = field?.fieldType?.dataType?.properties;
            return properties?.length > 0 ? properties[0].enum : [];
        }
    };

    const getCustomMetadataOptions = (field) => {
        const fieldType = field.fieldType.dataType.detailType;
        const isSingleEnum = fieldType === METADATA_FIELDS_TYPE.SINGLE_SELECT;
        const isMultiEnum = fieldType === METADATA_FIELDS_TYPE.MULTI_SELECT;
        if (isSingleEnum) return field?.fieldType?.dataType?.enum;
        if (isMultiEnum) {
            const properties = field?.fieldType?.dataType?.properties;
            return properties?.length > 0 ? properties[0].enum : [];
        }
        return [];
    };

    const getContentType = (detailType) => {
        if ([REFERENCE_FIELDS_TYPE.MUSIC_CONTENT, REFERENCE_FIELDS_TYPE.MUSIC_CONTENTS].includes(detailType))
            return "MUSIC";
        else if ([REFERENCE_FIELDS_TYPE.PEOPLE_CONTENT, REFERENCE_FIELDS_TYPE.PEOPLE_CONTENTS].includes(detailType))
            return "PEOPLE";
        else if ([REFERENCE_FIELDS_TYPE.PHOTO_CONTENT, REFERENCE_FIELDS_TYPE.PHOTO_CONTENTS].includes(detailType))
            return "PHOTO";
        else if ([REFERENCE_FIELDS_TYPE.VIDEO_CONTENT, REFERENCE_FIELDS_TYPE.VIDEO_CONTENTS].includes(detailType))
            return "VIDEO";
        else if ([REFERENCE_FIELDS_TYPE.CUSTOM_CONTENT, REFERENCE_FIELDS_TYPE.CUSTOM_CONTENTS].includes(detailType)) {
            return "CUSTOM";
        }
        return "";
    };

    const getAssetType = (detailType) => {
        if ([REFERENCE_FIELDS_TYPE.IMAGE_ASSET, REFERENCE_FIELDS_TYPE.IMAGE_ASSETS].includes(detailType))
            return "IMAGE";
        else if ([REFERENCE_FIELDS_TYPE.AUDIO_ASSET, REFERENCE_FIELDS_TYPE.AUDIO_ASSETS].includes(detailType))
            return "AUDIO";
        else if ([REFERENCE_FIELDS_TYPE.VIDEO_ASSET, REFERENCE_FIELDS_TYPE.VIDEO_ASSETS].includes(detailType))
            return "VIDEO";
        else if ([REFERENCE_FIELDS_TYPE.CAPTION_ASSET, REFERENCE_FIELDS_TYPE.CAPTION_ASSETS].includes(detailType))
            return "CAPTION";
        else if ([REFERENCE_FIELDS_TYPE.FILE_ASSET, REFERENCE_FIELDS_TYPE.FILE_ASSETS].includes(detailType))
            return "FILE";
        else if (detailType.indexOf("PREVIEW") >= 0) return "PREVIEW";
        return "";
    };

    const getResourceTableColumns = (detailType) => {
        const type = getResourceType(detailType);
        let subType = "";

        if (type === "ASSET") {
            subType = getAssetType(detailType);
        } else if (type === "CONTENT") {
            subType = getContentType(detailType);
        }

        return getTableColumns(type, subType);
    };

    const createDefaultValueSchema = (type, subType, rules, addRequired) => {
        let schema;

        if ((type === "array" && !subType) || (type !== "array" && subType) || !type) {
            return null;
        }

        const fieldType = type === "array" ? subType : type;

        if (fieldType === "string") {
            schema = yup
                .string()
                .nullable(true)
                .when([], {
                    is: () => addRequired,
                    then: yup.string().required(),
                });
            if (rules.min) {
                schema = schema.min(rules?.min, `This field can be up to ${rules?.min} characters.`);
            }
            if (rules.max) {
                schema = schema.max(rules?.max, `This field can be up to ${rules?.max} characters.`);
            }
            if (rules.pattern) {
                schema = schema.matches(rules?.pattern, `Invalid pattern.`);
            }
        } else if (fieldType === "number") {
            schema = yup
                .number(`This field should be a number.`)
                .transform((value, originalValue) => {
                    // 원래 값이 NaN인 경우 null로 변환
                    return originalValue === "" || isNaN(originalValue) ? null : value;
                })
                .nullable(true)
                .test("is-required", "This field is required.", function (value) {
                    const { path, createError } = this;
                    // addRequired가 true이고 value가 null이 아니면 required 검사
                    if (addRequired && value === null) {
                        return createError({ path, message: "This field is required." });
                    }
                    return true;
                });
            if (rules.min) {
                schema = schema.min(rules?.min, `This field can be up to ${rules?.min}.`);
            }
            if (rules.max) {
                schema = schema.max(rules?.max, `This field can be up to ${rules?.max}.`);
            }
        }
        if (fieldType === "object") {
            schema = yup
                .object()
                .when([], {
                    is: () => addRequired,
                    then: yup.object().required(),
                })
                .nullable(true);
        }
        if (fieldType === "enum") {
            //Note: enum의 경우 default value가 아닌 옵션의 값을 검증합니다.
            schema = yup
                .array()
                .of(
                    yup
                        .string()
                        .min(rules?.min ?? 0, `This field can be from ${rules?.min ?? 0} characters.`)
                        .max(rules?.max ?? 255, `This field can be up to ${rules?.max ?? 255} characters.`)
                        .when([], {
                            is: () => rules?.pattern,
                            then: yup.string().matches(rules?.pattern, "Invalid pattern."),
                        })
                        .when([], {
                            is: () => addRequired,
                            then: yup.string().required(),
                        }),
                )
                .unique("Duplicate value.", (v) => v);
        }

        return yup.object().shape({ fieldValue: schema });
    };

    const checkIsNull = (el) => {
        return el === null || el === "" || el === undefined;
    };

    const getCustomMetadataByContentSchema = (customMetadata) => {
        return {
            id: customMetadata.id,
            name: customMetadata.name,
            description: customMetadata.description,
            fields: customMetadata?.fields
                ?.filter((el) => !el.fieldType?.dataType?.isReference)
                ?.map((el) => ({
                    id: el.id,
                    subtitle: el.name,
                    type: el.fieldType.dataType.detailType,
                    value: el.defaultValue,
                    defaultValue: el.defaultValue,
                    options: getCustomMetadataOptions(el),
                    isRequired: el.isRequired,
                    validationRule: createDefaultValueSchema(
                        el?.fieldType?.dataType?.type,
                        el?.fieldType?.dataType?.properties?.[0]?.type,
                        el?.fieldType?.dataType?.validation || el?.fieldType?.dataType?.properties?.[0]?.validation,
                        el?.isRequired && el?.isEditable,
                    ),
                    validationError: el?.isRequired && el?.isEditable && checkIsNull(el.defaultValue),
                    isEditable: el.isEditable,
                })),
        };
    };

    return {
        results,
        pending,
        viewMore,
        resetParams,
        getResourceType,
        getAssetType,
        getContentType,
        getResourceTitle,
        onResourceFetch,
        getResourceAssetData,
        getResourceTableColumns,
        getOptionsBySelectType,
        getCustomMetadataOptions,
        getCustomMetadataByContentSchema,
        checkIsNull,
        createDefaultValueSchema,
    };
};

export default useCustomContent;
