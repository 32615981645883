import { useState } from "react";

const useDialog = () => {
    const [dialog, setDialog] = useState("");

    const openDialog = (key) => {
        setDialog(key);
    };

    const closeDialog = () => {
        setDialog("");
    };

    return {
        dialog,
        openDialog,
        closeDialog,
    };
};

export default useDialog;
