import { Box, Grid, LinearProgress, Stack } from "@mzc-pdc/ui";
import AssetTypeFlag from "@components_v2/icon-type/asset/asset-type-flag";
import { GridNameEllipsis } from "@components_v2/grid/grid.styled";
import Typography from "@components_v2/typography/typography";
import { IconHierarchyFolded, IconPath } from "@mzc-cloudplex/icons";
import theme from "@styles/theme";
import { Trans } from "react-i18next";
import ObjectIcon from "@routes/sources/components/ObjectIcon";
import convertToByte from "@cores/convertToByte";
import { COMMON_JOB_STATUS } from "@constants";
import { LinearProgressDeterminate } from "@components_v2/progressbar/progressbar";

const JobTaskTranscode = ({ tasks, job }) => {
    return (
        <>
            <Stack py={1.5} gap={2}>
                <Box>
                    <Grid container flexWrap={"nowrap"}>
                        <Grid item>
                            <ObjectIcon path={tasks[0].displayName} />
                        </Grid>
                        <Grid item>
                            <GridNameEllipsis item maxWidth={"300px"}>
                                <Typography variant="body3_400">{tasks[0].displayName ?? tasks[0].name}</Typography>
                            </GridNameEllipsis>
                            {tasks.length > 1 && (
                                <>
                                    <Typography variant="body3_400">
                                        <Trans
                                            i18nKey="common::label::and <emp>{{count}}</emp> others"
                                            components={{
                                                emp: <Typography variant="body3_700" color="primary.main"></Typography>,
                                            }}
                                            values={{
                                                count: tasks.length - 1,
                                            }}
                                        />
                                    </Typography>
                                </>
                            )}
                        </Grid>
                    </Grid>
                    <Box mt={2}>
                        {tasks.map((file, i) => (
                            <Grid container flexDirection={"column"} flexWrap={"nowrap"} gap={1}>
                                <Grid item sx={{ display: "flex", flexDirection: "row" }} gap={theme.spacing(1)}>
                                    <Grid item>
                                        <IconHierarchyFolded size={6} htmlColor={theme.palette.greySecondary[500]} />
                                    </Grid>
                                    <Grid item>
                                        <ObjectIcon path={file.displayName} />
                                    </Grid>
                                    <GridNameEllipsis item maxWidth={"300px"}>
                                        <Typography variant="body2_500">
                                            {file.displayName ?? file.name} {" - " + convertToByte(file.size)}
                                        </Typography>
                                    </GridNameEllipsis>
                                </Grid>
                                {job.status === "INGESTING" && (
                                    <Grid item pl={theme.spacing(2)}>
                                        <LinearProgressDeterminate
                                            value={file.uploadFinished ? 100 : file.uploadedPercentage ?? 0}
                                            withLabel={true}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        ))}
                    </Box>
                </Box>
            </Stack>
        </>
    );
};

export default JobTaskTranscode;
