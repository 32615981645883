import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";

import { refreshBucketStatus } from "../../modules/sources";
import { Popper } from "@mui/material";

const StorageSyncTooltip = ({ target, id, type, data, indexingState, indexingRequestedAt, indexingCompletedAt }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const dispatch = useDispatch();

    const open = Boolean(anchorEl);
    const tooltipId = open ? "simple-popper" : undefined;

    const handleClick = (e) => {
        setAnchorEl(anchorEl ? null : e.currentTarget);
    };

    const handleClose = (event) => {
        setAnchorEl(null);
    };

    const handleRefreshBucketStatus = useCallback((event) => {
        event.stopPropagation();
        dispatch(refreshBucketStatus(id)).then(() => {
            handleClose();
        });
    }, []);

    return (
        <>
            {indexingState === "FAILED" ||
                (indexingState === "PROCESSING" && (
                    <div className="popper-tooltip" onMouseLeave={handleClose}>
                        <span
                            aria-describedby={tooltipId}
                            onClick={(e) => handleClick(e)}
                            onMouseEnter={(e) => handleClick(e)}
                        >
                            {indexingState === "FAILED" && <i className="sprite sprite-error"></i>}
                            {/* {indexingState === "COMPLETED" && <i className="sprite sprite-sync-complete"></i>} */}
                            {indexingState === "PROCESSING" && <i className="sprite sprite-pending-warning"></i>}
                        </span>

                        {/* <OutsideClickHandler display={"inline-block"} onOutsideClick={handleClose}> */}
                        <Popper
                            id={tooltipId}
                            open={open}
                            anchorEl={anchorEl}
                            placement={type ? "bottom-start" : "bottom"}
                        >
                            {/* {indexingState === "COMPLETED" && (
                            <div className={"tooltip-sync"}>
                                <div className={"tooltip"}>
                                    <p>{t(`common::label::${"Sync complete"}`)}.</p>
                                </div>
                            </div>
                        )} */}
                            {indexingState === "FAILED" && (
                                <div className={"tooltip-sync"}>
                                    <div className={"tooltip"}>
                                        <p>{t(`common::label::${"Sync failed"}`)}.</p>
                                        <ul>
                                            <li>
                                                {t(`common::label::${"Start"}`)} :{" "}
                                                {moment.utc(indexingRequestedAt).local().format("YYYY-MM-DD HH:mm:ss")}
                                            </li>
                                            <li>
                                                {t(`common::label::${"End"}`)} :{" "}
                                                {moment.utc(indexingCompletedAt).local().format("YYYY-MM-DD HH:mm:ss")}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            )}
                            {indexingState === "PROCESSING" && (
                                <div className={"popper-tooltip-content "}>
                                    <div className={"popper-tooltip-body"}>
                                        <p>
                                            <i className="sprite sprite-uploading"></i>
                                            <span className="text-warning">
                                                {t(`common::label::${"Synchronizing"}`)}…
                                            </span>
                                        </p>
                                        <ul className={"list-dot"}>
                                            <li>
                                                <div className="dot">
                                                    {t(`common::label::${"Start"}`)} :{" "}
                                                    {moment
                                                        .utc(indexingRequestedAt)
                                                        .local()
                                                        .format("YYYY-MM-DD HH:mm:ss")}
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className={"popper-tooltip-footer text-right"}>
                                        <button
                                            type="button"
                                            className="btn btn-transparent"
                                            onClick={handleRefreshBucketStatus}
                                        >
                                            <span>{t(`common::label::${"Sync refresh"}`)}</span>
                                            <i className="sprite sprite-refresh-default"></i>
                                        </button>
                                    </div>
                                </div>
                            )}
                        </Popper>
                        {/* </OutsideClickHandler> */}
                    </div>
                ))}
        </>
    );
};
StorageSyncTooltip.propTypes = {
    target: PropTypes.object,
    id: PropTypes.string,
    type: PropTypes.string,
};

export default StorageSyncTooltip;
