import mime from "mime";
import {
    ASSET_TYPES,
    MEDIA_TYPES,
    REGEXP_AUDIO_EXTENSION,
    REGEXP_CAPTION_EXTENSION,
    REGEXP_IMAGE_EXTENSION,
    REGEXP_VIDEO_EXTENSION,
} from "../../constants";

mime.define(
    {
        "video/vnd.dlna.mpeg-tts": ["mts", "m2ts"],
        "text/x-diff": ["diff"],
        "application/x-bzip-compressed-tar": ["tbz"],
        "audio/mp3": ["mp3", "*mp3"],
        "audio/mp4": ["m4a", "mp4a"],
        "audio/mpeg": ["mpga", "mp2", "mp2a", "m2a", "m3a"],
        "video/x-dv": ["dv"],
        "video/quicktime": ["mov", "qt"],
    },
    true,
);

export const getMimeTypeFromUrl = (url) => {
    return mime.getType(url);
};

export const getExtensionFromMimeType = (mimeType) => {
    return mime.getExtension(mimeType);
};

export const getMediaTypeFromExtension = (extension) => {
    if (REGEXP_VIDEO_EXTENSION.test(extension)) return MEDIA_TYPES.VIDEO;
    else if (REGEXP_AUDIO_EXTENSION.test(extension)) return MEDIA_TYPES.AUDIO;
    else if (REGEXP_IMAGE_EXTENSION.test(extension)) return MEDIA_TYPES.IMAGE;
    else if (REGEXP_CAPTION_EXTENSION.test(extension)) return MEDIA_TYPES.CAPTION;
    else return MEDIA_TYPES.FILE;
};
export const getExtensionFromName = (name) => {
    return name.slice(name.lastIndexOf("."));
};

export const hasFileExtension = (name) => {
    return name.lastIndexOf(".") !== -1;
};

export const getMediaTypeFromMimeType = (mimeType) => {
    return getMediaTypeFromExtension(getExtensionFromMimeType(mimeType));
};

/**
 * @param {MEDIA_TYPES} mediaType
 * @param {string} extension
 */
export const getAssetType = (mediaType, extension) => {
    if (mediaType === MEDIA_TYPES.VIDEO) {
        if (extension === "m3u8") return ASSET_TYPES.HLS;
        else if (extension === "mpd") return ASSET_TYPES.DASH;
        else return extension.toUpperCase();
    } else if (mediaType === MEDIA_TYPES.AUDIO) {
        return extension.toUpperCase();
    } else if (mediaType === MEDIA_TYPES.IMAGE) {
        return ASSET_TYPES.IMAGE;
    } else if (mediaType === MEDIA_TYPES.CAPTION) {
        return ASSET_TYPES.CAPTION;
    } else if (mediaType === MEDIA_TYPES.TEXT) {
        return ASSET_TYPES.CAPTION;
    } else if (mediaType === MEDIA_TYPES.FILE) {
        return ASSET_TYPES.FILE;
    } else return null;
};

export function getUrlInfo(url) {
    const mimeType = getMimeTypeFromUrl(url);
    const extension = getExtensionFromMimeType(mimeType);
    const mediaType = getMediaTypeFromExtension(extension);
    const type = getAssetType(mediaType, extension);

    return {
        mimeType,
        extension,
        mediaType,
        type,
    };
}

export default {
    getMimeType: mime.getType,
    getMimeTypeFromUrl,
    getExtensionFromMimeType,
    getMediaTypeFromExtension,
    getMediaTypeFromMimeType,
    getAssetType,
    getUrlInfo,
    getExtensionFromName,
    hasFileExtension,
};
