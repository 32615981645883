import { handleActions, createAction } from "redux-actions";
import fp from "lodash/fp";
import { MEDIA_TYPES } from "../constants";
import { axios, axiosWithoutHeaders } from "../cores/axiosWrapper";
import queryString from "../cores/queryString";
import moment from "moment";
import compareVersions, { VERSION_CM_3963_ADD_ASSET_TYPE } from "../cores/version";
import { listAssetTypesAPI, listAssetExtensionsAPI } from "./apis/assets";
import i18n from "../lib/i18n";

import { isLocal } from "@cores/enviromentExtension";

function getAssetsAPI(stageId, apiEndpoint, projectId, query, activeTab, userIds) {
    let queries = {
        scope: "ShareBox",
        generateAccessUrl: true,
    };

    if (activeTab === "SHARED_TO_ME") {
        queries.recipientIds = userIds.join(",");
    } else if (activeTab === "SHARED_BY_ME") {
        queries.sharerId = userIds[0];
    }

    if (query.projectId) queries.projectId = query.projectId;
    if (query.offset) queries.offset = query.offset;
    if (query.limit) queries.limit = query.limit;

    if (query.isQuickSearch) {
        queries.q = query.keyword;
        if (query.folderPath) {
            queries.folderPath = query.folderPath;
            if (query.isFolderSearch) {
                queries.isFolderSearch = query.isFolderSearch;
            }
        }

        if (query.isNotCategoryIds) queries.isNotCategoryIds = query.isNotCategoryIds;

        if (query.categoryIds && query.categoryIds.length > 0) {
            queries.categoryIds = query.categoryIds.map((v) => v.id).join(",");
        }
    } else {
        if (query.folderPath) {
            queries.folderPath = query.folderPath;
            if (query.isFolderSearch) {
                queries.isFolderSearch = query.isFolderSearch;
            }
        }
        if (query.name) queries.name = query.name;
        if (query.id) queries.id = query.id;
        if (query.description) queries.description = query.description;
        if (query.creator) queries.creator = query.creator;
        if (query.status) queries.status = query.status;
        if (query.jobId) queries.jobId = query.jobId;
        if (query.ingestType) queries.ingestType = query.ingestType;
        if (query.types) queries.types = query.types.map((v) => v.value).join(",");
        if (query.extensions) queries.extensions = query.extensions.map((v) => v.value).join(",");
        if (query.mediaTypes && query.mediaTypes.length > 0)
            queries.mediaTypes = query.mediaTypes.map((v) => v.value).join(",");
        if (query.categoryIds && query.categoryIds.length > 0)
            queries.categoryIds = query.categoryIds.map((v) => v.id).join(",");
        if (query.isNotCategoryIds) queries.isNotCategoryIds = query.isNotCategoryIds;
        if (query.tags) queries.tags = "";
        if (query.tags !== undefined && query.tags.length > 0) {
            query.tags.forEach((tag, index) => {
                index === 0 ? tag : (tag = `,${tag}`);
                queries.tags += tag;
            });
        }

        if (query.beginDuration && query.endDuration) {
            queries.duration = `${moment.duration(query.beginDuration).asMilliseconds()}~${
                moment.duration(query.endDuration).asMilliseconds() + 999
            }`;
        }

        if (query.startAt && query.endAt) {
            const startAt = `${moment(`${query.startAt.format("YYYY-MM-DD")} 00:00:00`)
                .utc()
                .format("YYYY-MM-DD")}T${moment(`${query.startAt.format("YYYY-MM-DD")} 00:00:00`)
                .utc()
                .format("HH:mm:ss")}Z`;
            const endAt = `${moment(`${query.endAt.format("YYYY-MM-DD")} 00:00:00`)
                .utc()
                .format("YYYY-MM-DD")}T${moment(`${query.endAt.format("YYYY-MM-DD")} 00:00:00`)
                .utc()
                .format("HH:mm:ss")}Z`;

            queries.createdAt = `${startAt}~${endAt}`;
        }
        if (query.videoId) queries.videoId = query.videoId;
        if (query.peopleId) queries.peopleId = query.peopleId;
        if (query.hasAssociations) queries.hasAssociations = query.hasAssociations;
        if (query.fileMeta) {
            queries.fileMeta = query.fileMeta;
        }
    }
    // TODO: dummy
    if (isLocal)
        return new Promise((resolve) => {
            resolve({
                data: {
                    assets: [
                        {
                            id: "90499011mhXsqn",
                            version: 2,
                            name: "Yenne Lee 01",
                            state: "COMPLETE",
                            status: "ACTIVE",
                            desiredStatus: "ACTIVE",
                            archiveStatus: "NONE",
                            type: "IMAGE",
                            mediaType: "IMAGE",
                            ingestType: "DIRECT",
                            folderPath:
                                "https://mzc-cm-dev-demo-output.s3.ap-northeast-2.amazonaws.com/assets/90499011mhXsqn",
                            size: 1047103,
                            createdAt: "2021-06-18 04:28:20",
                            hasDubbing: false,
                            isLocked: false,
                            isShared: false,
                            extensions: "png",
                            originalUrls: [
                                "https://mzc-cm-dev-demo-output.s3.ap-northeast-2.amazonaws.com/assets/90499011mhXsqn/90501706WUrwM2.png",
                            ],
                            isConnected: 1,
                            project: { id: "79255375p29X2Q", name: "Dev Demo" },
                            tags: [],
                            thumbnails: [],
                            attributions: [],
                            createdBy: { id: "1622440528hsKU", name: "정 진호", username: "jhjung@mz.co.kr" },
                            references: [],
                            elements: [],
                            people: [],
                            accessUrls: [
                                "https://mzc-cm-dev-demo-output.s3.ap-northeast-2.amazonaws.com/assets/90499011mhXsqn/90501706WUrwM2.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIASTPAQYHNMN7VU5XD%2F20220311%2Fap-northeast-2%2Fs3%2Faws4_request&X-Amz-Date=20220311T032048Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEJP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaDmFwLW5vcnRoZWFzdC0yIkcwRQIhALtsH%2B3zigpM9SOFCsJsuHd8gWQ1WyQOn3MHCzrlZ8wXAiBibVlcetLyp38J%2F1SHpCC6lO80noHyvr6id9jS4Wu6liqaAgj8%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAQaDDE3OTI0ODg3Mzk0NiIMloBKj4eGOpp8tl9qKu4BBBogb8P6RgR8eXcVjiwbw4ugs%2FWzPaHV%2BEl4xK2n4obYUW8Kk5Xq9Cb31KLgCfo9k19WKZsfhVDHNm75M%2FriFdFUw%2F747Ojx6fiyHVb9WKuKDzZIe3p3OGp%2FB%2B0HBW17CEa%2BK5SDRcmyGIrR3gLPAyq37vDVehZe4IItWu3ecdavEVGpv3y3SNsrJFKaU3yTcWvS0D8343azo9zw73%2FOAWAp9RLKd4W3fun05d6T%2BKMq1%2B0tYQiDCeyiuyjL0VzW%2B9PmSgnyUIZMOPi8sE2odUP4gxaruS25wvPfyD2xUV2xpKQ6ySYnjilSS5OT3DDx8aqRBjqaAbkHSz21QKWnoH%2FEtGSGaOgBf1B3DH77MHlqazMht3id3qPc8wIEGRI2xN0oQpAwyzSB%2Bibi8yVcasEaQNMvpzayELY2O%2BOKMOi6g4WhEAPypUYBbPEAoDGpMj4E2yXR3EFtWocGxZDeuAoOVGw6jTCRkkZt9d%2FlCs575gp5ZCATIVAHmrmfcGtbYyC%2Fmx5TOW0tkRO0oKRaRhs%3D&X-Amz-Signature=7574676cce78622e979d8ec086bdea455e24232a8e66225ef9234d84b2b9baf6",
                            ],
                            sharedAt: "2021-12-01 06:53:25.0",
                            sharedBy: {
                                id: "1622440527DWym",
                                name: "hyeseung(메가존클라우드 Product Development Center) Jung",
                                username: "hyeseung@mz.co.kr",
                            },
                        },
                    ],
                    totalCount: 1,
                },
            });
        });

    return axios.get(`${apiEndpoint}/v2/shared-assets?${queryString.stringify(queries)}`, {
        headers: {
            stageId,
            // projectId,
        },
    });
}

const getPermissionAPI = (apiEndpoint, stageId, projectId) => {
    // return axios.get(`${apiEndpoint}/dam/v1/permissions`, {
    //TODO: url 3팀 미디어쪽에 배포시 변경해야함
    return axios.get(`${apiEndpoint}/v2/permissions`, {
        headers: {
            stageId,
            projectId,
        },
    });
};

const postSharesAPI = (payload, apiEndpoint, stageId, projectId) => {
    // return axios.post(`${apiEndpoint}/v2/shares`,
    return axios.post(`${apiEndpoint}/v2/shared-assets`, payload, {
        headers: {
            stageId,
            projectId,
        },
    });
};

const patchSharesAPI = (payload, apiEndpoint, stageId, projectId) => {
    // return axios.post(`${apiEndpoint}/v2/shares`,
    const id = payload.assetIds[0];
    const { recipients } = payload;
    return axios.patch(
        `${apiEndpoint}/v2/shared-assets/${id}`,
        {
            recipients,
        },
        {
            headers: {
                stageId,
                projectId,
            },
        },
    );
};

const getSharesAPI = (queryString, apiEndpoint, stageId, projectId) => {
    const search = queryString || `?include=${fp.get(`activeTab`, initialState)}&direction=DESC`;
    return axios.get(`${apiEndpoint}/v2/shared-assets${search}`, {
        headers: {
            stageId,
            projectId,
        },
    });
};

const getSharedUsersAPI = (assetId, apiEndpoint, stageId, projectId) => {
    return axios.get(`${apiEndpoint}/v2/shared-assets/${assetId}/users?type=RECIPIENT`, {
        headers: {
            stageId,
            projectId,
        },
    });
};

const getSharerAPI = (assetId, apiEndpoint, stageId, projectId) => {
    return axios.get(`${apiEndpoint}/v2/shared-assets/${assetId}/users?type=SHARER`, {
        headers: {
            stageId,
            projectId,
        },
    });
};

const getSharedTeamsAPI = (assetId, apiEndpoint, stageId, projectId) => {
    return axios.get(`${apiEndpoint}/v2/shared-assets/${assetId}/teams`, {
        headers: {
            stageId,
        },
    });
};

const getSharedAssetUsersAPI = (assetId, apiEndpoint, stageId, projectId, queries) => {
    return axios.get(`${apiEndpoint}/v2/shared-assets/${assetId}/users`, {
        params: {
            ...queries,
        },
        headers: {
            stageId,
        },
    });
};

const getSharedUsersDataAPI = (ids, apiEndpoint, stageId, projectId) => {
    return axios.get(`${apiEndpoint}/users`, {
        params: {
            ids,
        },
        headers: {
            stageId,
            projectId,
        },
    });
};

const getSharedTeamsDataAPI = (ids, apiEndpoint, stageId, projectId) => {
    return axios.get(`${apiEndpoint}/teams`, {
        params: {
            ids,
        },
        headers: {
            stageId,
        },
    });
};

const getSharedAssetTeamsAPI = (assetId, apiEndpoint, stageId, projectId, queries) => {
    return axios.get(`${apiEndpoint}/v2/shared-assets/${assetId}/teams`, {
        params: {
            ...queries,
        },
        headers: {
            stageId,
        },
    });
};

const getMyId = (apiEndpoint, stageId, projectId) => {
    return axios.get(`${apiEndpoint}/me`, {
        params: {},
        headers: {
            stageId,
            projectId,
        },
    });
};

const getMyTeams = (userId, apiEndpoint, stageId, projectId) => {
    return axios.get(`${apiEndpoint}/teams`, {
        params: {
            userId,
        },
        headers: {
            stageId,
        },
    });
};

const GET_PERMISSIONS_CLEAR = "GET_PERMISSIONS_CLEAR";
const GET_PERMISSIONS_PENDING = "GET_PERMISSIONS_PENDING";
const GET_PERMISSIONS_FAILURE = "GET_PERMISSIONS_FAILURE";
const GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS";
const RESET_GET_SHARES_PARAMS = "RESET_GET_SHARES_PARAMS";
const UPDATE_GET_SHARES_PARAMS = "UPDATE_GET_SHARES_PARAMS";

const POST_SHARES_PENDING = "POST_SHARES_PENDING";
const POST_SHARES_FAILURE = "POST_SHARES_FAILURE";
const POST_SHARES_SUCCESS = "POST_SHARES_SUCCESS";

const PATCH_SHARES_PENDING = "PATCH_SHARES_PENDING";
const PATCH_SHARES_FAILURE = "PATCH_SHARES_FAILURE";
const PATCH_SHARES_SUCCESS = "PATCH_SHARES_SUCCESS";

const GET_SHARES_PENDING = "GET_SHARES_PENDING";
const GET_SHARES_FAILURE = "GET_SHARES_FAILURE";
const GET_SHARES_SUCCESS = "GET_SHARES_SUCCESS";
const UPDATE_LIST_STATE = "UPDATE_LIST_STATE";
const GET_SHARED_USERS_PENDING = "GET_SHARED_USERS_PENDING";
const GET_SHARED_USERS_FAILURE = "GET_SHARED_USERS_FAILURE";
const GET_SHARED_USERS_SUCCESS = "GET_SHARED_USERS_SUCCESS";
const GET_SHARER_PENDING = "GET_SHARER_PENDING";
const GET_SHARER_FAILURE = "GET_SHARER_FAILURE";
const GET_SHARER_SUCCESS = "GET_SHARER_SUCCESS";
const GET_SHARED_TEAMS_PENDING = "GET_SHARED_TEAMS_PENDING";
const GET_SHARED_TEAMS_FAILURE = "GET_SHARED_TEAMS_FAILURE";
const GET_SHARED_TEAMS_SUCCESS = "GET_SHARED_TEAMS_SUCCESS";

const GET_SHARED_ASSET_LIST_UPDATE_SEARCH = "GET_SHARED_ASSET_LIST_UPDATE_SEARCH";
const GET_SHARED_ASSET_LIST_RESET_SEARCH = "GET_SHARED_ASSET_LIST_RESET_SEARCH";

const GET_SHARED_ASSET_SUCCESS = "GET_SHARED_ASSET_SUCCESS";
const GET_SHARED_ASSET_FAILURE = "GET_SHARED_ASSET_FAILURE";
const UPDATE_SHARED_ASSET_STATUS = "UPDATE_SHARED_ASSET_STATUS";

const GET_RELATED_SHARED_ASSET_LIST_PENDING = "GET_RELATED_SHARED_ASSET_LIST_PENDING";
const GET_RELATED_SHARED_ASSET_LIST_SUCCESS = "GET_RELATED_SHARED_ASSET_LIST_SUCCESS";
const GET_RELATED_SHARED_VIDEO_LIST_SUCCESS = "GET_RELATED_SHARED_VIDEO_LIST_SUCCESS";

const SHARED_ASSET_LIST_UPDATE_STATE = "SHARED_ASSET_LIST_UPDATE_STATE";
const SHARED_ASSET_LIST_UPDATE_SEARCH = "SHARED_ASSET_LIST_UPDATE_SEARCH";
const SHARED_ASSET_LIST_RESET_SEARCH = "SHARED_ASSET_LIST_RESET_SEARCH";

const SHARED_ASSET_LIST_PENDING = "SHARED_ASSET_LIST_PENDING";
const GET_SHARED_ASSET_LIST_SUCCESS = "GET_SHARED_ASSET_LIST_SUCCESS";
const GET_SHARED_ASSET_LIST_FAILURE = "GET_SHARED_ASSET_LIST_FAILURE";

const GET_ASSET_TYPES_LIST_PENDING = "GET_ASSET_TYPES_LIST_PENDING";
const GET_ASSET_TYPES_LIST_SUCCESS = "GET_ASSET_TYPES_LIST_SUCCESS";
const GET_ASSET_TYPES_LIST_FAIL = "GET_ASSET_TYPES_LIST_FAIL";

const GET_ASSET_EXTENSIONS_LIST_PENDING = "GET_ASSET_EXTENSIONS_LIST_PENDING";
const GET_ASSET_EXTENSIONS_LIST_SUCCESS = "GET_ASSET_EXTENSIONS_LIST_SUCCESS";
const GET_ASSET_EXTENSIONS_LIST_FAIL = "GET_ASSET_EXTENSIONS_LIST_FAIL";

const GET_MY_IDS_PENDING = "GET_MY_IDS_PENDING";
const GET_MY_IDS_SUCCESS = "GET_MY_IDS_SUCCESS";

const initialState = {
    pending: false,
    error: false,
    data: {
        assets: [],
        selected_asset: {
            relatedAssets: {
                pending: false,
                data: null,
            },
        },
        resourceToken: null,
    },
    activeTab: `SHARED_TO_ME`,
    isCardView: true,
    totalCount: 0,
    contextVersion: 0,
    search: {
        isQuickSearch: true,
        isFolderSearch: false,
        isCategorySearch: false,
        isNotCategoryIds: false,
        status: "",
        creator: "",
        folderPath: "",
        keyword: "",
        types: [],
        extensions: [],
        ingestType: "",
        mediaTypes: [],
        categoryIds: [],
        tags: [],
        name: "",
        id: "",
        beginDuration: "",
        endDuration: "",
        startAt: null,
        endAt: null,
        jobId: "",
        offset: 0,
        limit: 20,
        projectId: null,
    },
    permissions: {
        data: [],
        error: false,
        pending: false,
    },
    postShares: {
        data: null,
        pending: false,
        error: false,
    },
    sharer: {
        data: [],
        pending: false,
        error: false,
    },
    shared: {
        users: {
            data: [],
            pending: false,
            error: false,
        },
        teams: {
            data: [],
            pending: false,
            error: false,
        },
    },
    assetTypes: {
        pending: false,
        error: false,
        data: {},
    },
    assetExtensions: {
        pending: false,
        error: false,
        data: [],
    },
    myIds: [],
};

const cloneInitialState = fp.cloneDeep(initialState);

export default handleActions(
    {
        [GET_PERMISSIONS_CLEAR]: () => {
            return {
                ...cloneInitialState,
            };
        },
        [GET_PERMISSIONS_PENDING]: (state) => {
            return {
                ...state,
                permissions: {
                    ...fp.get(`permissions`, state),
                    pending: true,
                    error: false,
                },
            };
        },
        [GET_PERMISSIONS_FAILURE]: (state) => {
            return {
                ...state,
                permissions: {
                    ...fp.get(`permissions`, state),
                    pending: false,
                    error: true,
                },
            };
        },
        [GET_PERMISSIONS_SUCCESS]: (state, action) => {
            const data = fp.getOr({}, `payload.data.permissions`, action);

            return {
                ...state,
                permissions: {
                    ...fp.get(`permissions`, state),
                    pending: false,
                    error: false,
                    data,
                },
            };
        },
        [RESET_GET_SHARES_PARAMS]: (state) => {
            return {
                ...state,
                ...fp.get(`params`, cloneInitialState),
            };
        },
        [UPDATE_GET_SHARES_PARAMS]: (state, action) => {
            return {
                ...state,
                params: {
                    ...state.params,
                    ...action.payload,
                },
            };
        },
        [POST_SHARES_PENDING]: (state) => {
            return {
                ...state,
                postShares: {
                    ...fp.get(`postShares`, state),
                    pending: true,
                    error: false,
                },
            };
        },
        [POST_SHARES_FAILURE]: (state) => {
            return {
                ...state,
                postShares: {
                    ...fp.get(`postShares`, state),
                    pending: false,
                    error: true,
                },
            };
        },
        [POST_SHARES_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                postShares: {
                    ...fp.get(`postShares`, state),
                    pending: false,
                    error: false,
                    data,
                },
            };
        },
        [PATCH_SHARES_PENDING]: (state) => {
            return {
                ...state,
                postShares: {
                    ...fp.get(`postShares`, state),
                    pending: true,
                    error: false,
                },
            };
        },
        [PATCH_SHARES_FAILURE]: (state) => {
            return {
                ...state,
                postShares: {
                    ...fp.get(`postShares`, state),
                    pending: false,
                    error: true,
                },
            };
        },
        [PATCH_SHARES_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                postShares: {
                    ...fp.get(`postShares`, state),
                    pending: false,
                    error: false,
                    data,
                },
            };
        },
        [GET_SHARES_PENDING]: (state) => {
            return {
                ...state,
                pending: true,
                error: false,
            };
        },
        [GET_SHARES_FAILURE]: (state) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [GET_SHARES_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            return {
                ...state,
                pending: false,
                error: false,
                data: {
                    ...state.data,
                    assets: (data && data.assets) || [],
                },
            };
        },
        [UPDATE_LIST_STATE]: (state, action) => {
            return {
                ...state,
                ...fp.get(`payload`, action),
            };
        },
        [GET_SHARED_USERS_PENDING]: (state) => {
            return {
                ...state,
                shared: {
                    ...fp.get(`shared`, state),
                    users: {
                        ...fp.get(`shared.users`, state),
                        pending: true,
                        error: false,
                    },
                },
            };
        },
        [GET_SHARED_USERS_SUCCESS]: (state, action) => {
            const payload = action.payload;

            return {
                ...state,
                shared: {
                    ...fp.get(`shared`, state),
                    users: {
                        data: payload,
                        pending: false,
                        error: false,
                    },
                },
            };
        },
        [GET_SHARED_USERS_FAILURE]: (state) => {
            return {
                ...state,
                shared: {
                    ...fp.get(`shared`, state),
                    users: {
                        ...fp.get(`shared.users`, state),
                        pending: false,
                        error: true,
                    },
                },
            };
        },
        [GET_SHARER_PENDING]: (state) => {
            return {
                ...state,
                sharer: {
                    ...fp.get(`sharer`, state),
                    pending: true,
                    error: false,
                },
            };
        },
        [GET_SHARER_SUCCESS]: (state, action) => {
            const payload = action.payload;

            return {
                ...state,
                sharer: {
                    data: payload,
                    pending: false,
                    error: false,
                },
            };
        },
        [GET_SHARER_FAILURE]: (state) => {
            return {
                ...state,
                sharer: {
                    ...fp.get(`sharer`, state),
                    pending: false,
                    error: true,
                },
            };
        },
        [GET_SHARED_TEAMS_PENDING]: (state) => {
            return {
                ...state,
                shared: {
                    ...fp.get(`shared`, state),
                    teams: {
                        ...fp.get(`shared.teams`, state),
                        pending: true,
                        error: false,
                    },
                },
            };
        },
        [GET_SHARED_TEAMS_SUCCESS]: (state, action) => {
            const payload = action.payload;

            return {
                ...state,
                shared: {
                    ...fp.get(`shared`, state),
                    teams: {
                        data: payload,
                        pending: false,
                        error: false,
                    },
                },
            };
        },
        [GET_SHARED_TEAMS_FAILURE]: (state) => {
            return {
                ...state,
                shared: {
                    ...fp.get(`shared`, state),
                    teams: {
                        ...fp.get(`shared.teams`, state),
                        pending: false,
                        error: true,
                    },
                },
            };
        },
        [GET_SHARED_ASSET_LIST_UPDATE_SEARCH]: (state, action) => {
            const temp = {
                ...state,
                search: {
                    ...state.search,
                    ...action.payload,
                },
            };
            tempState = temp;
            return tempState;
        },
        [GET_SHARED_ASSET_LIST_RESET_SEARCH]: (state) => {
            const search = state.search.isQuickSearch
                ? {
                      ...state.search,
                      keyword: initialState.search.keyword,
                  }
                : {
                      ...initialState.search,
                      isQuickSearch: state.search.isQuickSearch,
                  };
            const temp = {
                ...tempState,
                search,
            };
            tempState = temp;
            return tempState;
        },
        [GET_SHARED_ASSET_SUCCESS]: (state, action) => {
            const { data, headers } = action.payload;
            // console.log("get asset", action.payload);
            // console.log("responseheader", action.payload.headers);
            const resourceToken = headers["x-mzc-cp-resource-token"] || null;
            return {
                ...state,
                pending: false,
                error: false,
                data: {
                    ...state.data,
                    selected_asset: data,
                    resourceToken,
                },
            };
        },
        [GET_SHARED_ASSET_FAILURE]: (state) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [UPDATE_SHARED_ASSET_STATUS]: (state, action) => {
            const { data } = action.payload;
            return {
                ...state,
                data: {
                    ...state.data,
                    selected_asset: {
                        ...selected_asset,
                        status: data.status,
                    },
                },
            };
        },
        [GET_RELATED_SHARED_ASSET_LIST_PENDING]: (state) => {
            // console.log("check", state);
            return {
                ...state,
                data: {
                    ...state.data,
                    selected_asset: {
                        ...state.data.selected_asset,
                        relatedAssets: {
                            ...state.data.selected_asset.relatedAssets,
                            pending: true,
                        },
                    },
                },
            };
        },
        [GET_RELATED_SHARED_ASSET_LIST_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            return {
                ...state,
                data: {
                    ...state.data,
                    selected_asset: {
                        ...state.data.selected_asset,
                        relatedAssets: {
                            ...state.data.selected_asset.relatedAssets,
                            pending: false,
                            data: data.assets && data.assets.filter((a) => a.id !== state.data.id),
                        },
                    },
                },
            };
        },
        [SHARED_ASSET_LIST_UPDATE_STATE]: (state, action) => {
            const temp = {
                ...state,
                ...action.payload,
            };
            return temp;
        },
        [SHARED_ASSET_LIST_UPDATE_SEARCH]: (state, action) => {
            const temp = {
                ...state,
                search: {
                    ...state.search,
                    ...action.payload,
                },
            };
            return temp;
        },
        [SHARED_ASSET_LIST_RESET_SEARCH]: (state) => {
            const search = state.search.isQuickSearch
                ? {
                      ...state.search,
                      keyword: cloneInitialState.search.keyword,
                  }
                : {
                      ...cloneInitialState.search,
                      isQuickSearch: state.search.isQuickSearch,
                  };
            const temp = {
                ...state,
                search,
            };
            return temp;
        },
        [SHARED_ASSET_LIST_PENDING]: (state) => {
            const temp = {
                ...state,
                pending: true,
                error: false,
            };
            return temp;
        },
        [GET_SHARED_ASSET_LIST_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const temp = {
                ...state,
                pending: false,
                error: false,
                data: {
                    ...state.data,
                    assets: (data && data.assets) || [],
                },
                totalCount: data.totalCount || 0,
            };
            return temp;
        },
        [GET_SHARED_ASSET_LIST_FAILURE]: (state) => {
            const temp = {
                ...state,
                pending: false,
                error: true,
            };
            return temp;
        },
        [GET_ASSET_TYPES_LIST_PENDING]: (state) => {
            return {
                ...state,
                assetTypes: {
                    ...state.assetTypes,
                    pending: true,
                    error: false,
                },
            };
        },
        [GET_ASSET_TYPES_LIST_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            // console.log("get types", data);
            const assetTypes = { [MEDIA_TYPES.NONE]: [] };
            const allTypes = new Map();
            if (!data)
                return {
                    ...state,
                    assetTypes: {
                        ...state.assetTypes,
                        pending: false,
                        data: {},
                    },
                };

            data.map((type) => {
                if (!assetTypes[type.mediaType]) assetTypes[type.mediaType] = [];
                assetTypes[type.mediaType].push({
                    type: type.type,
                    value: type.type,
                    label: type.type,
                });
                allTypes.set(type.type, {
                    type: type.type,
                    value: type.type,
                    label: type.type,
                });
            });
            allTypes.forEach((type) => assetTypes[MEDIA_TYPES.NONE].push(type));
            return {
                ...state,
                assetTypes: {
                    ...state.assetTypes,
                    pending: false,
                    data: assetTypes,
                },
            };
        },
        [GET_ASSET_TYPES_LIST_FAIL]: (state) => {
            return {
                ...state,
                assetTypes: {
                    ...state.assetTypes,
                    pending: false,
                    error: true,
                },
            };
        },
        [GET_ASSET_EXTENSIONS_LIST_PENDING]: (state) => {
            return {
                ...state,
                assetExtensions: {
                    ...state.assetExtensions,
                    pending: true,
                    error: false,
                },
            };
        },
        [GET_ASSET_EXTENSIONS_LIST_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            if (!data)
                return {
                    ...state,
                    assetExtensions: {
                        ...state.assetExtensions,
                        pending: false,
                        data: [],
                    },
                };

            return {
                ...state,
                assetExtensions: {
                    ...state.assetExtensions,
                    pending: false,
                    data: data.map((d) => ({
                        extension: d.extension,
                        value: d.extension,
                        label: d.extension,
                    })),
                },
            };
        },
        [GET_ASSET_EXTENSIONS_LIST_FAIL]: (state) => {
            return {
                ...state,
                assetExtensions: {
                    ...state.assetExtensions,
                    pending: false,
                    error: true,
                },
            };
        },
        [GET_MY_IDS_SUCCESS]: (state, action) => {
            const { myIds } = action.payload;
            return {
                ...state,
                myIds,
            };
        },
    },
    initialState,
);

export const clear = createAction(GET_PERMISSIONS_CLEAR);
export const resetParams = createAction(RESET_GET_SHARES_PARAMS);
export const updateParams = createAction(UPDATE_GET_SHARES_PARAMS);
export const updateListState = createAction(UPDATE_LIST_STATE);

export const getPermissions = () => (dispatch, getState) => {
    const { stage, project } = getState();
    const apiEndpoint = stage.endpoint;
    const stageId = stage.id;
    const projectId = project.id;
    dispatch({ type: GET_PERMISSIONS_PENDING });

    return getPermissionAPI(apiEndpoint, stageId, projectId)
        .then((response) => {
            const data = fp.getOr({}, `data`, response);
            dispatch({
                type: GET_PERMISSIONS_SUCCESS,
                payload: response,
            });
            return data;
        })
        .catch((error) => {
            dispatch({
                type: GET_PERMISSIONS_FAILURE,
                payload: error,
            });
        });
};

export const postShares = (payload) => (dispatch, getState) => {
    const { stage, project } = getState();
    const apiEndpoint = stage.endpoint;
    const stageId = stage.id;
    const projectId = project.id;
    dispatch({ type: POST_SHARES_PENDING });

    return new Promise((resolve, reject) => {
        postSharesAPI(payload, apiEndpoint, stageId, projectId)
            .then((response) => {
                const data = fp.getOr({}, `data`, response);
                dispatch({
                    type: POST_SHARES_SUCCESS,
                    payload: response,
                });
                resolve(data);
            })
            .catch((error) => {
                dispatch({
                    type: POST_SHARES_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const patchShares = (payload) => (dispatch, getState) => {
    const { stage, project } = getState();
    const apiEndpoint = stage.endpoint;
    const stageId = stage.id;
    const projectId = project.id;
    dispatch({ type: PATCH_SHARES_PENDING });

    return new Promise((resolve, reject) => {
        patchSharesAPI(payload, apiEndpoint, stageId, projectId)
            .then((response) => {
                const data = fp.getOr({}, `data`, response);
                dispatch({
                    type: PATCH_SHARES_SUCCESS,
                    payload: response,
                });
                resolve(data);
            })
            .catch((error) => {
                dispatch({
                    type: PATCH_SHARES_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const getShares = (queryString) => (dispatch, getState) => {
    const { stage, project } = getState();
    const apiEndpoint = stage.endpoint;
    const stageId = stage.id;
    const projectId = project.id;
    dispatch({ type: GET_SHARES_PENDING });

    return getSharesAPI(queryString, apiEndpoint, stageId, projectId)
        .then((response) => {
            const data = fp.getOr({}, `data`, response);
            dispatch({
                type: GET_SHARES_SUCCESS,
                payload: response,
            });
            return data;
        })
        .catch((error) => {
            dispatch({
                type: GET_SHARES_FAILURE,
                payload: error,
            });
        });
};

export const getMyIds = (projectId, search) => async (dispatch, getState) => {
    const { stage, project } = getState();
    const stageId = stage.id;
    const projectId = project.id;
    const apiEndpoint = stage.endpoint;
    dispatch({ type: SHARED_ASSET_LIST_PENDING });

    try {
        const getIdResponse = await getMyId(apiEndpoint, stageId, projectId);
        const ids = [];
        const { id: userId } = getIdResponse.data;
        // dispatch({ type: GET_MY_IDS_PENDING });
        if (userId) {
            ids.push(userId);
            const { data } = await getMyTeams(userId, apiEndpoint, stageId, projectId);
            if (data.results) {
                for (let item of data.results) {
                    ids.push(item.id);
                }
            }
        }
        dispatch({
            type: GET_MY_IDS_SUCCESS,
            payload: {
                myIds: ids,
            },
        });
        return ids;
    } catch (error) {
        dispatch({
            type: GET_SHARED_ASSET_LIST_FAILURE,
            payload: error,
        });
    }
};

export const getAssets = (projectId, search) => async (dispatch, getState) => {
    const { stage, project, shares, user } = getState();
    const stageId = stage.id;
    const projectId = project.id;
    const apiEndpoint = stage.endpoint;
    const targetProjectId = projectId || project.id;
    const searchParams = search || shares.search;
    const activeTab = shares.activeTab;
    const ids = shares.myIds;

    // console.log('ids', ids)
    if (ids.length < 1) {
        return;
    }
    dispatch({ type: SHARED_ASSET_LIST_PENDING });
    // const getIdResponse = await getMyId(apiEndpoint, stageId, projectId);
    // const ids = [];
    // const { id: userId } = getIdResponse.data;
    // if (userId) {
    //     ids.push(userId);
    //     const { data } = await getMyTeams(userId, apiEndpoint, stageId, projectId);
    //     if(data.results){
    //         for(let item of data.results){
    //             ids.push(item.id);
    //         }
    //     }
    // }
    return new Promise((resolve, reject) => {
        getAssetsAPI(stageId, apiEndpoint, targetProjectId, searchParams, activeTab, ids)
            .then((response) => {
                dispatch({
                    type: GET_SHARED_ASSET_LIST_SUCCESS,
                    payload: response,
                });
                resolve(response.data);
            })
            .catch((error) => {
                dispatch({
                    type: GET_SHARED_ASSET_LIST_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const getSharedUsers = (assetId) => (dispatch, getState) => {
    const { stage, project } = getState();
    const apiEndpoint = stage.endpoint;
    const stageId = stage.id;
    const projectId = project.id;
    dispatch({ type: GET_SHARED_USERS_PENDING });

    return getSharedUsersAPI(assetId, apiEndpoint, stageId, projectId)
        .then((response) => {
            const data = fp.getOr({}, `data.users`, response);
            dispatch({
                type: GET_SHARED_USERS_SUCCESS,
                payload: data,
            });
            return data;
        })
        .catch((error) => {
            dispatch({
                type: GET_SHARED_USERS_FAILURE,
                payload: error,
            });
        });
};

export const getSharer = (assetId) => (dispatch, getState) => {
    const { stage, project } = getState();
    const apiEndpoint = stage.endpoint;
    const stageId = stage.id;
    const projectId = project.id;
    dispatch({ type: GET_SHARER_PENDING });

    return getSharerAPI(assetId, apiEndpoint, stageId, projectId)
        .then((response) => {
            const data = fp.getOr({}, `data.users`, response);
            dispatch({
                type: GET_SHARER_SUCCESS,
                payload: data,
            });
            return data;
        })
        .catch((error) => {
            dispatch({
                type: GET_SHARER_FAILURE,
                payload: error,
            });
        });
};

export const getSharedTeams = (assetId) => (dispatch, getState) => {
    const { stage, project } = getState();
    const apiEndpoint = stage.endpoint;
    const stageId = stage.id;
    const projectId = project.id;
    dispatch({ type: GET_SHARED_TEAMS_PENDING });

    return getSharedTeamsAPI(assetId, apiEndpoint, stageId, projectId)
        .then((response) => {
            const data = fp.getOr({}, `data.teams`, response);
            dispatch({
                type: GET_SHARED_TEAMS_SUCCESS,
                payload: data,
            });
            return data;
        })
        .catch((error) => {
            dispatch({
                type: GET_SHARED_TEAMS_FAILURE,
                payload: error,
            });
        });
};

// export const getSharedAssets = (projectId, search) => (dispatch, getState) => {
//     const { stage, project, shares } = getState();
//     const stageId = stage.id;
//     const apiEndpoint = stage.endpoint;
//     const targetProjectId = projectId || project.id;
//     const searchParams = search || shares.search;
//     const contextVersion = shares.contextVersion + 1;

//     dispatch({ type: GET_SHARES_PENDING });

//     return new Promise((resolve, reject) => {
//         getSharedAssetsAPI(stageId, apiEndpoint, targetProjectId, searchParams)
//             .then((response) => {
//                 dispatch({
//                     type: GET_SHARED_ASSET_LIST_SUCCESS,
//                     payload: response,
//                 });
//                 //console.log(`response.data.assets: ${JSON.stringify(response.data.assets)}`);
//                 const assets = response.data.assets;
//                 if (assets) {
//                     for (const asset of assets) {
//                         const shouldPollStatus = asset.status === "ACTIVATING" || asset.status === "INACTIVATING";
//                         if (!shouldPollStatus) continue;
//                         const targetStatus = asset.status === "ACTIVATING" ? "ACTIVE" : "INACTIVE";
//                         new Polling(
//                             async () => {
//                                 return await AssetApi.getStatus(stageId, apiEndpoint, targetProjectId, asset.id);
//                             },
//                             (response) => {
//                                 return (
//                                     getState().assets.contextVersion !== contextVersion ||
//                                     response.data.status === targetStatus
//                                 );
//                             },
//                             (response) => {
//                                 dispatch({
//                                     type: UPDATE_SHARED_ASSET_STATUS,
//                                     payload: response,
//                                 });
//                             },
//                         ).run();
//                     }
//                 }
//                 resolve(response.data);
//             })
//             .catch((error) => {
//                 dispatch({
//                     type: GET_SHARED_ASSET_LIST_FAILURE,
//                     payload: error,
//                 });
//                 reject(error);
//             });
//     });
// };

function getSharedAssetAPI(stageId, apiEndpoint, projectId, id) {
    return axios.get(`${apiEndpoint}/v2/shared-assets/proxy/assets/${id}?generateAccessUrl=true`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

export const getSharedAsset = (id, projectId) => (dispatch, getState) => {
    const { stage, shares } = getState();

    dispatch({
        type: GET_SHARES_PENDING,
    });
    return new Promise((resolve, reject) => {
        getSharedAssetAPI(stage.id, stage.endpoint, projectId, id)
            .then((response) => {
                dispatch({
                    type: GET_SHARED_ASSET_SUCCESS,
                    payload: response,
                });
                // const stageId = stage.id;
                // const apiEndpoint = stage.endpoint;
                // const data = response.data;
                // const shouldRunStatusPoller =
                //     (data && data.status && data.status === "ACTIVATING") || data.status === "INACTIVATING";
                // if (shouldRunStatusPoller) {
                //     const contextVersion = shares.contextVersion + 1;
                //     new Polling(
                //         async () => {
                //             return await getStatus(stageId, apiEndpoint, projectId, id);
                //         },
                //         (response) => {
                //             const asset = getState().shares.data.selected_asset;
                //             return (
                //                 (asset.data && asset.data.id !== response.data.id) ||
                //                 asset.contextVersion !== contextVersion ||
                //                 response.data.status === "ACTIVE" ||
                //                 response.data.status === "INACTIVE"
                //             );
                //         },
                //         (response) => {
                //             dispatch({
                //                 type: UPDATE_SHARED_ASSET_STATUS,
                //                 payload: response,
                //             });
                //         },
                //     ).run();
                // }
                resolve(response);
            })
            .catch((error) => {
                dispatch({
                    type: GET_SHARED_ASSET_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export function getRelatedAssetsAPI(stageId, apiEndpoint, projectId, query, resourceToken) {
    let queries = {
        ...query,
        offset: 0,
        limit: 99999,
        generateAccessUrl: true,
    };
    return axios.get(`${apiEndpoint}/v2/shared-assets/proxy/assets`, {
        params: queries,
        headers: {
            stageId,
            projectId,
            "x-mzc-cp-resource-token": resourceToken,
        },
    });
}

export const getRelatedAssets = (jobId, projectId, query, resourceToken) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({
        type: GET_RELATED_SHARED_ASSET_LIST_PENDING,
    });
    return getRelatedAssetsAPI(stage.id, stage.endpoint, projectId, { jobId }, resourceToken)
        .then((response) => {
            dispatch({
                type: GET_RELATED_SHARED_ASSET_LIST_SUCCESS,
                payload: response,
            });
            return response;
        })
        .catch((error) => {
            // ToDO
        });
};

function getRelatedVideosAPI(stageId, apiEndpoint, projectId, id) {
    return axios.get(`${apiEndpoint}/v2/shared-assets/proxy/videos`, {
        params: {
            assetId: id,
        },
        headers: {
            stageId,
            projectId,
        },
    });
}

export const getRelatedVideos = (id, projectId) => (dispatch, getState) => {
    const { stage } = getState();
    getRelatedVideosAPI(stage.id, stage.endpoint, projectId, id)
        .then((response) => {
            dispatch({
                type: GET_RELATED_SHARED_VIDEO_LIST_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            // ToDO
        });
};

function requestAssetElementsDownloadUrlApi(stageId, apiEndpoint, projectId, assetId, elementIds, resourceToken) {
    return axios.post(
        download`${apiEndpoint}/v2/shared-assets/proxy/assets/${assetId}/elements/`,
        {
            elementIds,
        },
        {
            headers: {
                stageId,
                projectId,
                "x-mzc-cp-resource-token": resourceToken,
            },
        },
    );
}

export const requestAssetElementsDownloadUrl =
    (assetId, elementIds, projectId, resourceToken) => (dispatch, getState) => {
        const { stage } = getState();
        return requestAssetElementsDownloadUrlApi(
            stage.id,
            stage.endpoint,
            projectId,
            assetId,
            elementIds,
            resourceToken,
        );
    };

function removeAllSharedAssetAPI(stageId, apiEndpoint, projectId, queries) {
    return axios.delete(`${apiEndpoint}/v2/shared-assets`, {
        params: {
            ...queries,
        },
        headers: {
            stageId,
            projectId,
        },
    });
}

export const removeAllSharedAsset = (queries) => (dispatch, getState) => {
    const { stage, shares, project } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;

    return new Promise((resolve, reject) => {
        removeAllSharedAssetAPI(stageId, apiEndpoint, projectId, queries)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getSharedAssetUsers = (assetId, queries) => (dispatch, getState) => {
    const { stage, project } = getState();
    const stageId = stage.id;
    const projectId = project.id;
    const apiEndpoint = stage.endpoint;
    return new Promise(async (resolve, reject) => {
        getSharedAssetUsersAPI(assetId, apiEndpoint, stageId, projectId, queries)
            .then(async (response) => {
                const { users } = response && response.data;
                if (users) {
                    const ids = users.map((item) => item.id).join(",");
                    if (ids) {
                        const response = await getSharedUsersDataAPI(ids, apiEndpoint, stageId, projectId);
                        let usersData = (response && response.data && response.data.results) || [];
                        usersData = usersData.map((user) => {
                            for (let item of users) {
                                if (item.id === user.id) {
                                    user.permissionEditable = item.permissionEditable;
                                    user.role = item.role;
                                    break;
                                }
                            }
                            return user;
                        });
                        resolve(usersData);
                    } else {
                        resolve([]);
                    }
                } else {
                    resolve([]);
                }
            })
            .catch((error) => {
                // ToDO
                reject(error);
            });
    });
};

export const getSharedAssetTeams = (assetId, queries) => (dispatch, getState) => {
    const { stage, project } = getState();
    const stageId = stage.id;
    const projectId = project.id;
    const apiEndpoint = stage.endpoint;
    return new Promise(async (resolve, reject) => {
        getSharedAssetTeamsAPI(assetId, apiEndpoint, stageId, projectId, queries)
            .then(async (response) => {
                const { teams } = response && response.data;
                if (teams) {
                    const ids = teams.map((item) => item.id).join(",");
                    if (ids) {
                        const response = await getSharedTeamsDataAPI(ids, apiEndpoint, stageId, projectId);
                        let teamsData = (response && response.data && response.data.results) || [];
                        teamsData = teamsData.map((team) => {
                            for (let item of teams) {
                                if (item.id === team.id) {
                                    team.permissionEditable = item.permissionEditable;
                                    team.role = item.role;
                                    break;
                                }
                            }
                            return team;
                        });
                        resolve(teamsData);
                    } else {
                        resolve([]);
                    }
                } else {
                    resolve([]);
                }
            })
            .catch((error) => {
                // ToDO
                reject(error);
            });
    });
};

export const listAssetTypes = () => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: GET_ASSET_TYPES_LIST_PENDING });
    return new Promise((resolve, reject) => {
        listAssetTypesAPI(stage.id, stage.endpoint, project.id)
            .then((response) => {
                dispatch({
                    type: GET_ASSET_TYPES_LIST_SUCCESS,
                    payload: response,
                });
                resolve(response);
            })
            .catch((error) => {
                dispatch({ type: GET_ASSET_TYPES_LIST_FAIL });
                reject(error);
            });
    });
};

export const listAssetExtensions = () => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: GET_ASSET_EXTENSIONS_LIST_PENDING });
    return new Promise((resolve, reject) => {
        listAssetExtensionsAPI(stage.id, stage.endpoint, project.id)
            .then((response) => {
                dispatch({
                    type: GET_ASSET_EXTENSIONS_LIST_SUCCESS,
                    payload: response,
                });
                resolve(response);
            })
            .catch((error) => {
                dispatch({ type: GET_ASSET_EXTENSIONS_LIST_FAIL });
                reject(error);
            });
    });
};

export const getAssetTypeSelectList =
    (mediaTypes = []) =>
    (dispatch, getState) => {
        const { stage, shares } = getState();
        const compVersion = compareVersions(VERSION_CM_3963_ADD_ASSET_TYPE, stage.version);
        // console.log("mediaTypes", mediaTypes);
        if (compVersion <= 0) {
            if (mediaTypes.length > 0) {
                mediaTypes.map((mt) => {
                    assets.assetTypes.data[mt];
                });
            }
            const allTypes = [
                {
                    value: "",
                    label: i18n.t(`common::label::${"All types"}`),
                },
            ].concat((shares.assetTypes && shares.assetTypes.data[MEDIA_TYPES.NONE]) || []);
            // console.log("allTypes", allTypes);
            return allTypes;
        } else {
            return CONSTANTS("ASSET_TYPES_SELECT");
        }
    };

function sendShareRequestAPI(stageId, apiEndpoint, projectId, queries) {
    return axios.post(`${apiEndpoint}/v2/shares`, queries, {
        headers: {
            stageId,
            projectId,
        },
    });
}

export const sendShareRequest = (queries) => (dispatch, getState) => {
    const { stage, project } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;

    return new Promise((resolve, reject) => {
        sendShareRequestAPI(stageId, apiEndpoint, projectId, queries)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

function approveShareRequestAPI(stageId, apiEndpoint, projectId, queries) {
    const { id, action, denyMessage } = queries;
    return axios.post(
        `${apiEndpoint}/v2/shares/actions`,
        {
            action,
            content: {
                requestId: id,
                denyMessage,
            },
        },
        {
            headers: {
                stageId,
                projectId,
            },
        },
    );
}

export const approveShareRequest = (queries) => (dispatch, getState) => {
    const { stage, project } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;

    return new Promise((resolve, reject) => {
        approveShareRequestAPI(stageId, apiEndpoint, projectId, queries)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

function getShareRequestListAPI(stageId, apiEndpoint, projectId, queries) {
    // TODO: dummy
    if (isLocal)
        return new Promise((resolve) => {
            resolve({
                data: {
                    results: [
                        {
                            resource: {
                                id: "90499011mhXsqn",
                                version: 2,
                                name: "Yenne Lee 01",
                                state: "COMPLETE",
                                status: "ACTIVE",
                                desiredStatus: "ACTIVE",
                                archiveStatus: "NONE",
                                type: "IMAGE",
                                mediaType: "IMAGE",
                                ingestType: "DIRECT",
                                folderPath:
                                    "https://mzc-cm-dev-demo-output.s3.ap-northeast-2.amazonaws.com/assets/90499011mhXsqn",
                                size: 1047103,
                                createdAt: "2021-06-18 04:28:20",
                                hasDubbing: false,
                                isLocked: false,
                                isShared: false,
                                extensions: "png",
                                originalUrls: [
                                    "https://mzc-cm-dev-demo-output.s3.ap-northeast-2.amazonaws.com/assets/90499011mhXsqn/90501706WUrwM2.png",
                                ],
                                isConnected: 1,
                                project: { id: "79255375p29X2Q", name: "Dev Demo" },
                                tags: [],
                                thumbnails: [],
                                attributions: [],
                                createdBy: { id: "1622440528hsKU", name: "정 진호", username: "jhjung@mz.co.kr" },
                                references: [],
                                elements: [],
                                people: [],
                                accessUrls: [
                                    "https://mzc-cm-dev-demo-output.s3.ap-northeast-2.amazonaws.com/assets/90499011mhXsqn/90501706WUrwM2.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIASTPAQYHNMN7VU5XD%2F20220311%2Fap-northeast-2%2Fs3%2Faws4_request&X-Amz-Date=20220311T032048Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEJP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaDmFwLW5vcnRoZWFzdC0yIkcwRQIhALtsH%2B3zigpM9SOFCsJsuHd8gWQ1WyQOn3MHCzrlZ8wXAiBibVlcetLyp38J%2F1SHpCC6lO80noHyvr6id9jS4Wu6liqaAgj8%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAQaDDE3OTI0ODg3Mzk0NiIMloBKj4eGOpp8tl9qKu4BBBogb8P6RgR8eXcVjiwbw4ugs%2FWzPaHV%2BEl4xK2n4obYUW8Kk5Xq9Cb31KLgCfo9k19WKZsfhVDHNm75M%2FriFdFUw%2F747Ojx6fiyHVb9WKuKDzZIe3p3OGp%2FB%2B0HBW17CEa%2BK5SDRcmyGIrR3gLPAyq37vDVehZe4IItWu3ecdavEVGpv3y3SNsrJFKaU3yTcWvS0D8343azo9zw73%2FOAWAp9RLKd4W3fun05d6T%2BKMq1%2B0tYQiDCeyiuyjL0VzW%2B9PmSgnyUIZMOPi8sE2odUP4gxaruS25wvPfyD2xUV2xpKQ6ySYnjilSS5OT3DDx8aqRBjqaAbkHSz21QKWnoH%2FEtGSGaOgBf1B3DH77MHlqazMht3id3qPc8wIEGRI2xN0oQpAwyzSB%2Bibi8yVcasEaQNMvpzayELY2O%2BOKMOi6g4WhEAPypUYBbPEAoDGpMj4E2yXR3EFtWocGxZDeuAoOVGw6jTCRkkZt9d%2FlCs575gp5ZCATIVAHmrmfcGtbYyC%2Fmx5TOW0tkRO0oKRaRhs%3D&X-Amz-Signature=7574676cce78622e979d8ec086bdea455e24232a8e66225ef9234d84b2b9baf6",
                                ],
                                sharedAt: "2021-12-01 06:53:25.0",
                                sharedBy: {
                                    id: "1622440527DWym",
                                    name: "hyeseung(메가존클라우드 Product Development Center) Jung",
                                    username: "hyeseung@mz.co.kr",
                                },
                            },
                        },
                    ],
                    totalCount: 1,
                },
            });
        });
    return axios.get(`${apiEndpoint}/v2/shares`, {
        params: queries,
        headers: {
            stageId,
            projectId,
        },
    });
}

export const getShareRequestList = (queries) => (dispatch, getState) => {
    const { stage, project } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;

    return new Promise((resolve, reject) => {
        getShareRequestListAPI(stageId, apiEndpoint, projectId, queries)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

function getShareRequestDetailAPI(stageId, apiEndpoint, projectId, id) {
    return axios.get(`${apiEndpoint}/v2/shares/${id}`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

export const getShareRequestDetail = (id) => (dispatch, getState) => {
    const { stage, project } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;

    return new Promise((resolve, reject) => {
        getShareRequestDetailAPI(stageId, apiEndpoint, projectId, id)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const updateState = createAction(SHARED_ASSET_LIST_UPDATE_STATE);
export const updateSearch = createAction(SHARED_ASSET_LIST_UPDATE_SEARCH);
export const resetSearch = createAction(SHARED_ASSET_LIST_RESET_SEARCH);
