import { axios, axiosWithoutHeaders } from "../cores/axiosWrapper";
import { handleActions, createAction } from "redux-actions";
import { isNull } from "lodash/fp";

const IV_ROOM_LIST_RESET = "IV_ROOM_LIST_RESET";
const IV_ROOM_LIST_PENDING = "IV_ROOM_LIST_PENDING";
// ROOMS
const IV_GET_ROOM_LIST_SUCCESS = "IV_GET_ROOM_LIST_SUCCESS";
const IV_GET_ROOM_LIST_FAILURE = "IV_GET_ROOM_LIST_FAILURE";
const IV_DELETE_ROOM_ITEM_SUCCESS = "IV_DELETE_ROOM_ITEM_SUCCESS";
const IV_DELETE_ROOM_ITEM_FAILURE = "IV_DELETE_ROOM_ITEM_FAILURE";
// CHANNELS
const IV_REFRESH_CHANNEL_KEY_SUCCESS = "IV_REFRESH_CHANNEL_KEY_SUCCESS";
const IV_REFRESH_CHANNEL_KEY_FAILURE = "IV_REFRESH_CHANNEL_KEY_FAILURE";

const initialState = {
    pending: false,
    error: false,
    data: [],
    totalCount: 0,
};

export default handleActions(
    {
        [IV_ROOM_LIST_RESET]: (state, action) => {
            return {
                ...initialState,
            };
        },

        [IV_ROOM_LIST_PENDING]: (state, action) => {
            return {
                ...state,
                pending: true,
                error: false,
            };
        },

        [IV_GET_ROOM_LIST_SUCCESS]: (state, action) => {
            const data = action.payload;
            return {
                ...state,
                data: data.results,
                totalCount: data.totalCount,
                pending: false,
            };
        },

        [IV_GET_ROOM_LIST_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },

        [IV_DELETE_ROOM_ITEM_SUCCESS]: (state, action) => {
            const data = action.payload;
            const newData = state.data.filter((c) => c.id !== data.id);
            return {
                ...state,
                data: newData,
                pending: false,
                error: false,
            };
        },

        [IV_DELETE_ROOM_ITEM_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
    },
    initialState,
);

export function getIVRoomListAPI(stageId, apiEndpoint, projectId, queries) {
    return axios.get(`${apiEndpoint}/interactive-video/rooms`, {
        params: {
            ...queries,
        },
        headers: {
            stageId,
            projectId,
        },
    });
}

export const getIvRoomList = (queries) => (dispatch, getState) => {
    const { stage, project } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;

    dispatch({ type: IV_ROOM_LIST_PENDING });

    return getIVRoomListAPI(stageId, apiEndpoint, projectId, queries)
        .then((response) => {
            dispatch({
                type: IV_GET_ROOM_LIST_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: IV_GET_ROOM_LIST_FAILURE,
                payload: error,
            });
        });
};

function deleteIvRoomAPI(stageId, apiEndpoint, projectId, id) {
    return axios.delete(`${apiEndpoint}/interactive-video/rooms/${id}`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

export const deleteIvRoom = (id) => (dispatch, getState) => {
    const { stage, project } = getState();
    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;

    dispatch({ type: IV_ROOM_LIST_PENDING });
    return deleteIvRoomAPI(stageId, apiEndpoint, projectId, id)
        .then((response) => {
            dispatch({
                type: IV_DELETE_ROOM_ITEM_SUCCESS,
                payload: {
                    id,
                },
            });
        })
        .catch((error) => {
            dispatch({
                type: IV_DELETE_ROOM_ITEM_FAILURE,
                payload: error,
            });
        });
};

export function refreshChannelKeyAPI(stageId, apiEndpoint, projectId, channelId) {
    return axios.put(
        `${apiEndpoint}/interactive-video/channels/${channelId}/refresh-key`,
        {},
        {
            headers: {
                stageId,
                projectId,
            },
        },
    );
}

export const refreshChannelKey = (id) => (dispatch, getState) => {
    const { stage, project } = getState();
    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;

    // dispatch({ type: IV_ROOM_LIST_PENDING });
    return refreshChannelKeyAPI(stageId, apiEndpoint, projectId, id)
        .then((response) => {
            // dispatch({
            //     type: IV_REFRESH_CHANNEL_KEY_SUCCESS,
            //     payload: {
            //         id,
            //     },
            // });
            return {
                result: "success",
            };
        })
        .catch((error) => {
            // dispatch({
            //     type: IV_REFRESH_CHANNEL_KEY_FAILURE,
            //     payload: error,
            // });
            return {
                result: "failure",
                error,
            };
        });
};
