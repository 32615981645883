import { useCallback, useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";
import { Button, Divider, Grid, Typography } from "@mzc-pdc/ui";
import LanguageMenu from "../../features/language-menu/language-menu";
import theme from "@styles/theme";
import { FooterWrapper } from "./footer.styled";
import useSettings from "@hooks/useSettings";
import { getDefaultLanguage } from "@modules/localization";

const Footer = () => {
    const { t, i18n } = useTranslation();

    const { i18nLng, updateLocaleLanguage } = useSettings({ type: "General" });

    const [i18nResources] = useState(JSON.parse(localStorage.getItem("i18nResources")));
    const [initLanguage] = useState(i18nLng ?? getDefaultLanguage());
    const [language] = useState(i18nResources?.find((element) => element.value === initLanguage)?.label);

    const handleChangeLocale = useCallback(
        (lng) => {
            i18n.changeLanguage(lng).then(() => {
                updateLocaleLanguage(lng);
                window.location.reload();
            });
        },
        [i18n],
    );

    const openTermsPrivacy = useCallback(() => {
        const url = `https://cloudplex.megazone.com/${initLanguage !== "ko" ? `${initLanguage}/` : ""}privacy/latest`;
        window.open(url);
    }, []);

    const openTermsService = useCallback(() => {
        const url = `https://cloudplex.megazone.com/${initLanguage !== "ko" ? `${initLanguage}/` : ""}terms/latest`;
        window.open(url);
    }, []);

    const openCustomerService = useCallback(() => {
        window.open(`https://help.cloudplex.megazone.io/hc/ko`);
    }, []);

    const openMegazoneCorp = useCallback(() => {
        window.open(`https://www.megazone.com/`);
    }, []);

    return (
        <footer>
            <FooterWrapper>
                <Divider sx={{ margin: `0`, width: `auto`, height: `0` }} />
                <Grid container justifyContent={"space-between"}>
                    <Grid item xs>
                        <Grid container direction={`row`} alignItems={`center`}>
                            <Grid item>
                                <Button
                                    type={`button`}
                                    size={`small`}
                                    variant={`text`}
                                    color={"greySecondary"}
                                    onClick={openTermsService}
                                >
                                    {t(`common::label::Terms & Conditions`)}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Divider orientation="vertical" flexItem />
                            </Grid>
                            <Grid item>
                                <Button
                                    className={"privacy"}
                                    type={`button`}
                                    size={`small`}
                                    variant={`text`}
                                    color={"greySecondary"}
                                    onClick={openTermsPrivacy}
                                    sx={{
                                        fontWeight: 500,
                                    }}
                                >
                                    <Typography color={theme.palette.greySecondary.A400}>
                                        {t(`common::label::Privacy policy`)}
                                    </Typography>
                                </Button>
                            </Grid>
                            <Grid item className={"divider"}>
                                <Divider orientation="vertical" flexItem />
                            </Grid>
                            <Grid item className={"address"}>
                                <address>
                                    ©
                                    <Button
                                        variant={"solid"}
                                        sx={{ height: 32, fontSize: `12px` }}
                                        size="small"
                                        onClick={openMegazoneCorp}
                                    >
                                        MegazoneCloud Corp.
                                    </Button>{" "}
                                    All rights reserved.
                                </address>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs>
                        <Grid
                            container
                            direction={`row`}
                            alignItems={`center`}
                            flexWrap={`nowrap`}
                            justifyContent={"flex-end"}
                        >
                            <Grid item>
                                <Button
                                    type={`button`}
                                    size={`small`}
                                    variant={`text`}
                                    color={"greySecondary"}
                                    onClick={openCustomerService}
                                >
                                    {t(`common::label::Customer Service`)}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Divider orientation="vertical" flexItem />
                            </Grid>
                            <LanguageMenu
                                locales={i18nResources}
                                locale={i18nLng}
                                label={language}
                                onClick={handleChangeLocale}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </FooterWrapper>
        </footer>
    );
};

export default Footer;
