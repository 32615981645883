import {handleActions, createAction} from 'redux-actions';
import {
    axios,
    axiosWithoutHeaders
} from "../cores/axiosWrapper";

function getCategoriesAPI(stageId, apiEndpoint, projectId, categoryGroupId, params) {
    return axios.get(`${apiEndpoint}/category-groups/${categoryGroupId}/categories`, {
        headers: {
            stageId,
            projectId
        },
        params
    });
}

function postCategoriesAPI(stageId, apiEndpoint, projectId, categoryGroupId, data) {
    return axios.post(`${apiEndpoint}/category-groups/${categoryGroupId}/categories`, data, {
        headers: {
            stageId,
            projectId
        }
    });
}

function patchCategoriesAPI(stageId, apiEndpoint, projectId, categoryGroupId, categoryId, data) {
    return axios.patch(`${apiEndpoint}/category-groups/${categoryGroupId}/categories/${categoryId}`, data, {
        headers: {
            stageId,
            projectId
        }
    });
}

function deleteCategoriesAPI(stageId,apiEndpoint, projectId, categoryGroupId, categoryId) {
    return axios.delete(`${apiEndpoint}/category-groups/${categoryGroupId}/categories/${categoryId}`, {
        headers: {
            stageId,
            projectId
        }
    });
}

function deleteAllCategoriesAPI(stageId,apiEndpoint, projectId, categoryGroupId, data) {
    return axios.delete(`${apiEndpoint}/category-groups/${categoryGroupId}/categories/bulk`, {
        data,
        headers: {
            stageId,
            projectId
        }
    });
}

function getCategoryAPI(stageId, apiEndpoint, projectId, categoryGroupId, categoryId) {
    return axios.get(`${apiEndpoint}/category-groups/${categoryGroupId}/categories/${categoryId}`, {
        headers: {
            stageId,
            projectId
        }
    });
}

const RESET_CATEGORIES = 'RESET_CATEGORIES';
const CATEGORIES_MANAGEMENT_PENDING = 'CATEGORIES_MANAGEMENT_PENDING';
const CATEGORIES_MANAGEMENT_FAILURE = 'CATEGORIES_MANAGEMENT_FAILURE';
const GET_CATEGORIES_MANAGEMENT_FOR_CATEGORIES_SUCCESS = 'GET_CATEGORIES_MANAGEMENT_FOR_CATEGORIES_SUCCESS';
const PUT_CATEGORIES_MANAGEMENT_FOR_CATEGORIES_SUCCESS = 'PUT_CATEGORIES_MANAGEMENT_FOR_CATEGORIES_SUCCESS';
const POST_CATEGORIES_MANAGEMENT_FOR_CATEGORIES_SUCCESS = 'POST_CATEGORIES_MANAGEMENT_FOR_CATEGORIES_SUCCESS';
const DELETE_CATEGORIES_MANAGEMENT_FOR_CATEGORIES_SUCCESS = 'DELETE_CATEGORIES_MANAGEMENT_FOR_CATEGORIES_SUCCESS';

const GET_CATEGORIES_MANAGEMENT_FOR_CATEGORY_SUCCESS = 'GET_CATEGORIES_MANAGEMENT_FOR_CATEGORY_SUCCESS';

const UPDATE_CATEGORIES_MANAGEMENT_FOR_CATEGORIES_PARAMS = 'UPDATE_CATEGORIES_MANAGEMENT_FOR_CATEGORIES_PARAMS';
const RESET_CATEGORIES_MANAGEMENT_FOR_CATEGORIES_PARAMS = 'RESET_CATEGORIES_MANAGEMENT_FOR_CATEGORIES_PARAMS';
const TOGGLE_CATEGORIES_MANAGEMENT_FOR_CATEGORIES_ACTIONS = 'TOGGLE_CATEGORIES_MANAGEMENT_FOR_CATEGORIES_ACTIONS';
const CLOSE_CATEGORIES_MANAGEMENT_FOR_CATEGORIES_ACTIONS = 'CLOSE_CATEGORIES_MANAGEMENT_FOR_CATEGORIES_ACTIONS';

const initialState = {
    pending: false,
    error: false,
    totalCount: 0,
    params: {
        parentId : null,
        name: '',
        status: ''
    },
    isEnabledOrder : true,
    categories: [],
    category: null
};

export default handleActions({
    [RESET_CATEGORIES]:() => {
        return {
            ...initialState
        }
    },
    [CATEGORIES_MANAGEMENT_PENDING]: (state, action) => {
        return {
            ...state,
            pending: true,
            error: false
        }
    },
    [CATEGORIES_MANAGEMENT_FAILURE]: (state, action) => {
        return {
            ...state,
            pending: false,
            error: true
        };
    },
    [GET_CATEGORIES_MANAGEMENT_FOR_CATEGORIES_SUCCESS]: (state, action) => {
        let {data} = action.payload;

        const categoryList = data.categories.map(category => {
            category.depth = category.parent? category.parent.code.split('_').length : 1;
            category.childCount = data.categories.filter(v => v.code.startsWith(category.code) === true).length -1;
            return {
                id: category.id,
                code: category.code,
                name: category.name,
                order: category.order,
                depth: category.depth,
                status: category.status,
                childCount: category.childCount,
                parentId:category.parent? category.parent.id : null,
                parentCode:category.parent? category.parent.code : ''
            }
        });

        const tree = categoryListToTree(categoryList);
        // const tree = null;

        return {
            ...state,
            pending: false,
            error: false,
            isEnabledOrder : state.params.name === '' && state.params.status === '',
            totalCount: data.totalCount,
            categories: categoryList,
            originData: data.categories,
            categoriesTree: tree
        }
    },
    [PUT_CATEGORIES_MANAGEMENT_FOR_CATEGORIES_SUCCESS]: (state, action) => {
        let {data} = action.payload;

        return {
            ...state,
            pending: false,
            error: false,
            category: data
        }
    },
    [POST_CATEGORIES_MANAGEMENT_FOR_CATEGORIES_SUCCESS]: (state, action) => {
        return {
            ...state,
            pending: false,
            error: false
        };
    },
    [DELETE_CATEGORIES_MANAGEMENT_FOR_CATEGORIES_SUCCESS]: (state, action) => {
        return {
            ...state,
            pending: false,
            error: false
        }
    },
    [UPDATE_CATEGORIES_MANAGEMENT_FOR_CATEGORIES_PARAMS]: (state, action) => {
        return {
            ...state,
            params : {
                ...state.params,
                ...action.payload
            }
        }
    },
    [RESET_CATEGORIES_MANAGEMENT_FOR_CATEGORIES_PARAMS]:(state) => {
        return {
            ...state,
            params : {
                name: '',
                status: ''
            }
        }
    },
    [TOGGLE_CATEGORIES_MANAGEMENT_FOR_CATEGORIES_ACTIONS]:(state, action) => {
        return {
            ...state,
            categories: state.categories.map(v => {
                return {
                    ...v,
                    isOpen: v.id === action.payload ? !v.isOpen : false
                }
            })
        }
    },
    [CLOSE_CATEGORIES_MANAGEMENT_FOR_CATEGORIES_ACTIONS]:(state) => {
        return {
            ...state,
            categories: state.categories.map(v => {
                return {
                    ...v,
                    isOpen: false
                }
            })
        }
    },
    [GET_CATEGORIES_MANAGEMENT_FOR_CATEGORY_SUCCESS]:(state, action) => {
        let {data} = action.payload;

        return {
            ...state,
            pending : false,
            error : false,
            category : data
        }
    }
}, initialState);

const categoryListToTree = (categoryList) => {
    let arrayList = JSON.parse(JSON.stringify(categoryList));
    var rootNodes = [];
    var traverse = function (nodes, item, index) {
        if (nodes instanceof Array) {
            return nodes.some(function (node) {
                if (node.id === item.parentId) {
                    node.children = node.children || [];
                    node.children.push(arrayList.splice(index, 1)[0])
                    node.children.sort(function(a, b)
                    { 
                        return a.order < b.order ? -1 : a.order > b.order ? 1 : 0;  
                    });
                    return node;
                }

                return traverse(node.children, item, index);
            });
        }
    };

    while (arrayList.length > 0) {
        arrayList.some(function (item, index) {
            if (item.parentId === null) {
                rootNodes.push(arrayList.splice(index, 1)[0]);
                rootNodes.sort(function(a, b)
                { 
                    return a.order < b.order ? -1 : a.order > b.order ? 1 : 0;  
                });
                return rootNodes;
            }

            return traverse(rootNodes, item, index);
        });
    }

    return rootNodes;
};

export const reset = createAction(RESET_CATEGORIES);
export const closeActions = createAction(CLOSE_CATEGORIES_MANAGEMENT_FOR_CATEGORIES_ACTIONS);
export const toggleActions = createAction(TOGGLE_CATEGORIES_MANAGEMENT_FOR_CATEGORIES_ACTIONS);
export const updateParams = createAction(UPDATE_CATEGORIES_MANAGEMENT_FOR_CATEGORIES_PARAMS);
export const resetParams = createAction(RESET_CATEGORIES_MANAGEMENT_FOR_CATEGORIES_PARAMS);
export const getCategories = (projectId, categoryGroupId, params) => (dispatch, getState) => {
    const {
        stage
    } = getState();

    dispatch({type: CATEGORIES_MANAGEMENT_PENDING});

    return new Promise((resolve, reject) => {
        getCategoriesAPI(stage.id, stage.endpoint, projectId, categoryGroupId, params).then(
            (response) => {
                dispatch({
                    type: GET_CATEGORIES_MANAGEMENT_FOR_CATEGORIES_SUCCESS,
                    payload: response
                });
                resolve(response.data);
            }
        ).catch(error => {
            dispatch({
                type: CATEGORIES_MANAGEMENT_FAILURE,
                payload: error
            });
            reject(error);
        });
    })
};

export const postCategories = (projectId, categoryGroupId, data, callback) => (dispatch, getState) => {
    const {
        stage
    } = getState();

    dispatch({type: CATEGORIES_MANAGEMENT_PENDING});

    return postCategoriesAPI(stage.id, stage.endpoint, projectId, categoryGroupId, data).then(
        (response) => {
            dispatch({
                type: POST_CATEGORIES_MANAGEMENT_FOR_CATEGORIES_SUCCESS,
                payload: response
            });
            callback(response.data);
        }
    ).catch(error => {
        dispatch({
            type: CATEGORIES_MANAGEMENT_FAILURE,
            payload: error
        });
    });
};

export const patchCategories = (projectId, categoryId, categoryGroupId, data) => (dispatch, getState) => {
    const {
        stage
    } = getState();

    dispatch({type: CATEGORIES_MANAGEMENT_PENDING});

    return patchCategoriesAPI(stage.id, stage.endpoint, projectId, categoryGroupId, categoryId, data).then(
        (response) => {
            dispatch({
                type: PUT_CATEGORIES_MANAGEMENT_FOR_CATEGORIES_SUCCESS,
                payload: response
            });
        }
    ).catch(error => {
        dispatch({
            type: CATEGORIES_MANAGEMENT_FAILURE,
            payload: error
        });
    });
};

export const deleteCategories = (projectId, categoryGroupId, categoryId) => (dispatch, getState) => {
    const {
        stage
    } = getState();

    dispatch({type: CATEGORIES_MANAGEMENT_PENDING});

    return deleteCategoriesAPI(stage.id, stage.endpoint, projectId, categoryGroupId, categoryId).then(
        (response) => {
            dispatch({
                type: DELETE_CATEGORIES_MANAGEMENT_FOR_CATEGORIES_SUCCESS,
                payload: response
            });
        }
    ).catch(error => {
        dispatch({
            type: CATEGORIES_MANAGEMENT_FAILURE,
            payload: error
        });
    });
};

export const deleteAllCategories = (projectId, categoryGroupId, parentCode) => (dispatch, getState) => {
    const { stage } = getState();
    const data = { parentCode };

    dispatch({type: CATEGORIES_MANAGEMENT_PENDING});

    return deleteAllCategoriesAPI(stage.id, stage.endpoint, projectId, categoryGroupId, data).then(
        (response) => {
            dispatch({
                type: DELETE_CATEGORIES_MANAGEMENT_FOR_CATEGORIES_SUCCESS,
                payload: response
            });
        }
    ).catch(error => {
        dispatch({
            type: CATEGORIES_MANAGEMENT_FAILURE,
            payload: error
        })
    })
}

export const getCategory = (projectId, categoryGroupId, categoryId) => (dispatch, getState) => {
    const {
        stage
    } = getState();

    dispatch({type: CATEGORIES_MANAGEMENT_PENDING});

    return getCategoryAPI(stage.id, stage.endpoint, projectId, categoryGroupId, categoryId).then(
        (response) => {
            dispatch({
                type: GET_CATEGORIES_MANAGEMENT_FOR_CATEGORY_SUCCESS,
                payload: response
            });
        }
    ).catch(error => {
        dispatch({
            type: CATEGORIES_MANAGEMENT_FAILURE,
            payload: error
        });
    });
}