import { handleActions, createAction } from "redux-actions";
import { axios } from "../cores/axiosWrapper";
import queryString from "../cores/queryString";

function getSettingsAPI(stageId, apiEndpoint, projectId, params) {
    const headers = {
        stageId,
    };
    if (projectId) {
        headers.projectId = projectId;
    }
    return axios.get(`${apiEndpoint}/settings?${queryString.stringify(params)}`, {
        headers,
    });
}

function createOrUpdateStageSettingsAPI(stageId, apiEndpoint, settings) {
    const headers = {
        stageId,
    };

    return axios.put(
        `${apiEndpoint}/settings/stage`,
        {
            settings,
        },
        {
            headers,
        },
    );
}

function createOrUpdateProjectSettingsAPI(stageId, apiEndpoint, projectId, settings) {
    const headers = {
        stageId,
    };
    if (projectId) {
        headers.projectId = projectId;
    }
    return axios.put(
        `${apiEndpoint}/settings/project`,
        {
            settings,
        },
        {
            headers,
        },
    );
}

function deleteStageSettingsAPI(stageId, apiEndpoint, settings) {
    const headers = {
        stageId,
    };
    return axios.delete(`${apiEndpoint}/settings/stage`, {
        data: { settings },
        headers,
    });
}

function deleteProjectSettingsAPI(stageId, apiEndpoint, projectId, settings) {
    const headers = {
        stageId,
    };
    if (projectId) {
        headers.projectId = projectId;
    }
    return axios.delete(`${apiEndpoint}/settings/project`, {
        data: { settings },
        headers,
    });
}

const RESET_SETTINGS = "RESET_SETTINGS";
const SETTINGS_PENDING = "SETTINGS_PENDING";
const SETTINGS_FAILURE = "SETTINGS_FAILURE";
const GET_PROJECT_SETTINGS_PENDING_SUCCESS = "GET_PROJECT_SETTINGS_PENDING_SUCCESS";
const SETTINGS_ERROR_RESET = "SETTINGS_ERROR_RESET";
const GET_STAGE_SETTINGS_SUCCESS = "GET_STAGE_SETTINGS_SUCCESS";
const GET_PROJECT_SETTINGS_SUCCESS = "GET_PROJECT_SETTINGS_SUCCESS";
const UPDATE_STAGE_SETTINGS_SUCCESS = "UPDATE_STAGE_SETTINGS_SUCCESS";
const UPDATE_PROJECT_SETTINGS_SUCCESS = "UPDATE_PROJECT_SETTINGS_SUCCESS";
const GET_RESOURCE_SETTINGS_SUCCESS = "GET_RESOURCE_SETTINGS_SUCCESS";

const DELETE_STAGE_SETTINGS_SUCCESS = "DELETE_STAGE_SETTINGS_SUCCESS";
const DELETE_PROJECT_SETTINGS_SUCCESS = "DELETE_PROJECT_SETTINGS_SUCCESS";

const initialState = {
    pending: false,
    error: false,
    stage: null,
    project: null,
    updatedDomain: null,
    resourceSettings: null,
    statusCode: null,
};

export default handleActions(
    {
        [RESET_SETTINGS]: () => {
            return {
                ...initialState,
            };
        },
        [SETTINGS_PENDING]: (state) => {
            return {
                ...state,
                pending: true,
                error: false,
                updatedDomain: null,
            };
        },
        [GET_PROJECT_SETTINGS_PENDING_SUCCESS]: (state, action) => {
            const { resource } = action.payload;
            return {
                ...state,
                pending: false,
                error: false,
                project: {
                    ...state.project,
                    [resource]: {},
                },
            };
        },
        [SETTINGS_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: true,
                updatedDomain: null,
                statusCode: action.statusCode ? action.statusCode : null,
            };
        },
        [GET_STAGE_SETTINGS_SUCCESS]: (state, action) => {
            let { data } = action.payload;

            return {
                ...state,
                pending: false,
                error: false,
                stage: data,
                updatedDomain: null,
            };
        },
        [GET_PROJECT_SETTINGS_SUCCESS]: (state, action) => {
            const { data, resource } = action.payload;
            if (resource) {
                const projectSettings = {
                    ...state.project,
                };
                projectSettings[resource] = data[resource];
                return {
                    ...state,
                    pending: false,
                    error: false,
                    project: projectSettings,
                    updatedDomain: null,
                };
            }
            return {
                ...state,
                pending: false,
                error: false,
                project: {
                    ...state.project,
                    ...data,
                },
                updatedDomain: null,
            };
        },
        [GET_RESOURCE_SETTINGS_SUCCESS]: (state, action) => {
            let { data } = action.payload;

            return {
                ...state,
                pending: false,
                error: false,
                resourceSettings: {
                    resource: action.resource,
                    settings: data,
                },
            };
        },
        [UPDATE_STAGE_SETTINGS_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
                updatedDomain: action.domain,
            };
        },
        [UPDATE_PROJECT_SETTINGS_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
                updatedDomain: action.domain,
            };
        },
        [DELETE_STAGE_SETTINGS_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
                updatedDomain: action.domain,
            };
        },
        [DELETE_PROJECT_SETTINGS_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
                updatedDomain: action.domain,
            };
        },
        [SETTINGS_ERROR_RESET]: (state, action) => {
            return {
                ...state,
                error: false,
            };
        },
    },
    initialState,
);

export const getSettings =
    ({ stageId: stageId_, apiEndpoint: apiEndpoint_, projectId: projectId_, params }) =>
    (dispatch, getState) => {
        const { stage, project } = getState();
        const stageId = stageId_ || stage.id;
        const apiEndpoint = apiEndpoint_ || stage.endpoint;
        const projectId = projectId_;

        dispatch({ type: SETTINGS_PENDING });
        return new Promise((resolve, reject) => {
            getSettingsAPI(stageId, apiEndpoint, projectId, params)
                .then((response) => {
                    dispatch({
                        type: GET_RESOURCE_SETTINGS_SUCCESS,
                        payload: response,
                        resource: params.resource,
                        statusCode: null,
                    });
                    resolve(response.data);
                })
                .catch((error) => {
                    dispatch({
                        type: SETTINGS_FAILURE,
                        statusCode: error.response.status,
                    });
                    reject(error);
                });
        });
    };

export const getStageSettings =
    ({ stageId, apiEndpoint, params }) =>
    (dispatch) => {
        dispatch({ type: SETTINGS_PENDING });
        return new Promise((resolve, reject) => {
            getSettingsAPI(stageId, apiEndpoint, null, params)
                .then((response) => {
                    dispatch({
                        type: GET_STAGE_SETTINGS_SUCCESS,
                        payload: response,
                    });
                    resolve(response.data);
                })
                .catch((error) => {
                    dispatch({
                        type: SETTINGS_FAILURE,
                    });
                    reject(error);
                });
        });
    };

export const getProjectSettings =
    ({ stageId, apiEndpoint, projectId, params }, callback, callbackErr) =>
    (dispatch) => {
        dispatch({ type: SETTINGS_PENDING });
        return new Promise((resolve, reject) => {
            getSettingsAPI(stageId, apiEndpoint, projectId, params)
                .then((response) => {
                    dispatch({
                        type: GET_PROJECT_SETTINGS_SUCCESS,
                        payload: {
                            data: response.data,
                            resource: params && params.resource,
                        },
                    });
                    resolve(response.data);
                    if (callback) callback(response.data);
                })
                .catch((error) => {
                    if (error?.response?.data?.status === 404)
                        dispatch({
                            type: GET_PROJECT_SETTINGS_PENDING_SUCCESS,
                            payload: {
                                resource: params && params.resource,
                            },
                        });
                    else
                        dispatch({
                            type: SETTINGS_FAILURE,
                        });

                    reject(error);
                    if (callbackErr) callbackErr(error);
                });
        });
    };
export const reset = createAction(RESET_SETTINGS);
export const createOrUpdateStageSettings =
    ({ stageId, apiEndpoint, domain, settings }) =>
    (dispatch) => {
        dispatch({ type: SETTINGS_PENDING });
        return createOrUpdateStageSettingsAPI(stageId, apiEndpoint, settings)
            .then((response) => {
                dispatch({
                    type: UPDATE_STAGE_SETTINGS_SUCCESS,
                    payload: response,
                    domain: domain,
                });
            })
            .catch((error) => {
                dispatch({
                    type: SETTINGS_FAILURE,
                });
            });
    };

export const createOrUpdateProjectSettings =
    ({ stageId, apiEndpoint, projectId, domain, settings }) =>
    (dispatch) => {
        dispatch({ type: SETTINGS_PENDING });
        console.log(settings);
        return createOrUpdateProjectSettingsAPI(stageId, apiEndpoint, projectId, settings)
            .then((response) => {
                dispatch({
                    type: UPDATE_PROJECT_SETTINGS_SUCCESS,
                    payload: response,
                    domain: domain,
                });
            })
            .catch((error) => {
                dispatch({
                    type: SETTINGS_FAILURE,
                });
            });
    };

export const deleteProjectSettings =
    ({ stageId, apiEndpoint, projectId, domain, settings }) =>
    (dispatch) => {
        dispatch({ type: SETTINGS_PENDING });
        return deleteProjectSettingsAPI(stageId, apiEndpoint, projectId, settings)
            .then((response) => {
                dispatch({
                    type: DELETE_PROJECT_SETTINGS_SUCCESS,
                    payload: response,
                    domain: domain,
                });
            })
            .catch((error) => {
                dispatch({
                    type: SETTINGS_FAILURE,
                });
            });
    };

export const deleteStageSettings =
    ({ stageId, apiEndpoint, domain, settings }) =>
    (dispatch) => {
        dispatch({ type: SETTINGS_PENDING });
        return deleteStageSettingsAPI(stageId, apiEndpoint, settings)
            .then((response) => {
                dispatch({
                    type: DELETE_STAGE_SETTINGS_SUCCESS,
                    payload: response,
                    domain: domain,
                });
            })
            .catch((error) => {
                dispatch({
                    type: SETTINGS_FAILURE,
                });
            });
    };

export const settingsErrorReset = () => (dispatch, action) => dispatch({ type: SETTINGS_ERROR_RESET });
