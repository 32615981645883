import {handleActions, createAction} from 'redux-actions';
import {
    axios,
    axiosWithoutHeaders
} from "../cores/axiosWrapper";
import Polling from '../cores/polling';

const POSTING_PENDING = 'POSTING_PENDING';
const POSTING_RESET = 'POSTING_RESET';
const POSTING_SET_HAS_RESET_TO_FALSE = 'POSTING_SET_HAS_RESET_TO_FALSE';
const POSTING_INIT = 'POSTING_INIT';
const GET_SHARED_URL_SUCCESS = 'GET_SHARED_URL_SUCCESS';
const GET_SHARED_URL_FAILURE = 'GET_SHARED_URL_FAILURE';
const GET_EMBED_URL_SUCCESS = 'GET_EMBED_URL_SUCCESS';
const GET_EMBED_URL_FAILURE = 'GET_EMBED_URL_FAILURE';
const GET_LOGIN_URL_SUCCESS = 'GET_LOGIN_URL_SUCCESS';
const GET_LOGIN_URL_FAILURE = 'GET_LOGIN_URL_FAILURE';
const GET_ALL_ACCOUNTS_SUCCESS = 'GET_ALL_ACCOUNTS_SUCCESS';
const GET_ALL_ACCOUNTS_FAILURE = 'GET_ALL_ACCOUNTS_FAILURE';

const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
const DELETE__ACCOUNT_FAILURE = 'DELETE__ACCOUNT_FAILURE';

const GET_POSTING_HISTORY_SUCCESS = 'GET_POSTING_HISTORY_SUCCESS';
const GET_POSTING_HISTORY_FAILURE = 'GET_POSTING_HISTORY_FAILURE';

const GET_YOUTUBE_ACCOUNTS_SUCCESS = 'GET_YOUTUBE_ACCOUNTS_SUCCESS';
const GET_YOUTUBE_ACCOUNTS_FAILURE = 'GET_YOUTUBE_ACCOUNTS_FAILURE';

const GET_INSERT_ACCOUNTS_SUCCESS = 'GET_INSERT_ACCOUNTS_SUCCESS';
const GET_INSERT_ACCOUNTS_FAILURE = 'GET_INSERT_ACCOUNTS_FAILURE';

const POST_YOUTUBE_SUCCESS = 'POST_YOUTUBE_SUCCESS';
const POST_YOUTUBE_FAILURE = 'POST_YOUTUBE_FAILURE';
const GET_YOUTUBE_POSTING_VIDEO_SUCCESS = 'GET_YOUTUBE_POSTING_VIDEO_SUCCESS';
const GET_YOUTUBE_POSTING_VIDEO_FAILURE = 'GET_YOUTUBE_POSTING_VIDEO_FAILURE';
const GET_YOUTUBE_POSTING_STAUTS_SUCCESS = 'GET_YOUTUBE_POSTING_STAUTS_SUCCESS';
const GET_YOUTUBE_POSTING_STAUTS_FAILURE = 'GET_YOUTUBE_POSTING_STAUTS_FAILURE';

const initialState = {
    pending:false,
    error:false,
    sharedUrl: "",
    embedUrl: "",
    youtube:{
        isLoadPublishInfo : false
    },
    history:[],
    hasReset : false
};

function getSharedUrlAPI(stageId, apiEndpoint, projectId, resourceType, contentId)
{
    return axios.get(`${apiEndpoint}/posting/shared-url/${resourceType}/${contentId}`, {       
        headers: {
            stageId,
            projectId
        }
    });
}

function getEmbedUrlAPI(stageId, apiEndpoint, projectId, resourceType, contentId, playerProfileId) 
{ 
    return axios.get(`${apiEndpoint}/posting/embed-url/${resourceType}/${contentId}`, {
        // params: {
        //     playerProfileId
        // },
        headers: {
            stageId,
            projectId
        }
    });
}

function getLoginUrlAPI(stageId, apiEndpoint, projectId, snsKind, redirectUrl) {
    return axios.get(`${apiEndpoint}/posting/sns/${snsKind}/login`, {
        params: {
            redirectUrl
        },
        headers: {
            stageId,
            projectId
        }
    });
}

function insertAccountAPI(stageId, apiEndpoint, projectId, snsKind, account) {
    return axios.post(`${apiEndpoint}/posting/sns/${snsKind}/accounts`, account, {
        headers: {
            stageId,
            projectId
        }
    });
}

function getAccountsAPI(stageId, apiEndpoint, projectId, snsKind) {
    return axios.get(`${apiEndpoint}/posting/sns/${snsKind}/accounts`, {        
        headers: {
            stageId,
            projectId
        }
    });
}

function deleteAccountAPI(stageId, apiEndpoint, projectId, snsKind, accountId) {
    return axios.delete(`${apiEndpoint}/posting/sns/${snsKind}/accounts/${accountId}`, {        
        headers: {
            stageId,
            projectId
        }
    });
}

function postVideoAPI(stageId, apiEndpoint, projectId, snsKind, data) {
    return axios.post(`${apiEndpoint}/posting/sns/${snsKind}/videos`, data, {        
        headers: {
            stageId,
            projectId
        }
    });
}

function getPostingVideosAPI(stageId, apiEndpoint, projectId, snsKind, videoId, children) {
    return axios.get(`${apiEndpoint}/posting/sns/${snsKind}/videos`, {
        params : {
            videoId,
            children
        },
        headers: {
            stageId,
            projectId
        }
    });
}

function getPostingHisotryAPI(stageId, apiEndpoint, projectId, snsKind, videoId, status) {
    return axios.get(`${apiEndpoint}/posting/sns/${snsKind}/history`, {
        params : {         
            videoId,
            status           
        },
        headers: {
            stageId,
            projectId
        }
    });
}

export default handleActions({
    [POSTING_PENDING]: (state) => {
        console.log("POSTING_PENDING");
        return {
            ...state,
            pending : true,
            error : false
        }
    },
    [POSTING_INIT] : (state, action) => {
        console.log("POSTING_INIT ");
        return {
            ...initialState            
        }
    },
    [POSTING_RESET]: (state, action ) => 
    {
        console.log("POSTING_RESET ");
        return {
            ...initialState,
            hasReset: true
        }
    },
    [POSTING_SET_HAS_RESET_TO_FALSE]: (state, action ) =>
    {
        return {
            ...state,
            hasReset: false
        }
    },
    [GET_SHARED_URL_SUCCESS] : (state, action) => {
        const sharedUrl = action.payload && action.payload.sharedUrl;
        console.log("GET_SHARED_URL_SUCCESS sharedUrl: ", sharedUrl);
        return {
            ...state,
            sharedUrl,
            pending : false
        }
    },
    [GET_SHARED_URL_FAILURE] : (state) =>{
        //console.log("GET_PLAYER_PROFILES_FAILURE: ", JSON.stringify(state));
        return {
            ...state,
            pending : false,
            error : true    
        }
    },
    [GET_EMBED_URL_SUCCESS] : (state, action) => {
        const embedUrl = action.payload && action.payload.embedUrl;        
        // console.log("GET_PLAYER_PROFILES_SUCCESS embedUrl: ", embedUrl);
        return {
            ...state,
            pending : false,
            embedUrl
        }
    },
    [GET_EMBED_URL_FAILURE] : (state) => {
        //console.log("GET_PLAYER_PROFILES_FAILURE: ", JSON.stringify(state));
        return {
            ...state,
            pending : false,
            error : true    
        }
    },
    [GET_YOUTUBE_ACCOUNTS_SUCCESS] : (state, action) => {
        const accounts = action.payload;
        
        accounts.map(account => {
            account.isSelected = false
        });

        return {
            ...state,
            pending : false,            
            youtube : {
                accounts
            }            
        }
    },
    [GET_YOUTUBE_ACCOUNTS_FAILURE] : (state) => {
        return {
            ...state,
            pending : false,
            error : true
        }
    },
    [GET_INSERT_ACCOUNTS_FAILURE] : (state) => {
        return {
            ...state,        
            error : true
        }
    },
    [GET_YOUTUBE_POSTING_VIDEO_SUCCESS] : (state, action) => {
        let postingData = action.payload;
        state.youtube.accounts && state.youtube.accounts.map(a => {
            postingData.map(c => {
                if (a.id === c.accountId) {
                    a.status = c.status.toLowerCase();
                    a.url = c.url;
                    return;
                }
            })
        });

        return {
            ...state,
            pending : false,
            youtube : {
                ...state.youtube,
                isLoadPublishInfo : true
            }            
        }
    },
    [GET_YOUTUBE_POSTING_STAUTS_SUCCESS] : (state, action) => {
        let postingStatusData = action.payload;
                
        state.youtube.accounts && state.youtube.accounts.map(a => {
            postingStatusData.map(c => {
                if (a.id === c.accountId) {
                    a.status = c.status.toLowerCase();
                    a.url = c.url;
                    return;
                }
            })
        });

        return {
            ...state,
            pending : false,
            youtube : {
                ...state.youtube,
                isLoadPublishInfo : true
            }            
        }
    },

    [POST_YOUTUBE_SUCCESS] : (state, action) => {
        let postingData = action.payload;
                
        state.youtube.accounts.map(a => {
            postingData.map(c => {
                if (a.id === c.accountId) {
                    a.status = c.status.toLowerCase();                    
                    return;
                }
            })
        });

        return {
            ...state,
            pending : false,
            youtube : {
                ...state.youtube,
                isLoadPublishInfo : true
            }
        }
    },  
    [GET_POSTING_HISTORY_SUCCESS] : (state, action) => {
        return {
            ...state,
            pending: false,
            history : action.payload.history
        }
    }      
}, initialState);

function canStartPolling(postingVideosStatus) {
    const canPolling = postingVideosStatus.reduce((accumulator, currentValue) => {
        const status = currentValue.status.toUpperCase();
        let completeFalg = false; 
        if (status === 'PROGRESSING' || status === 'READY' || status === 'UPLOADING')
            completeFalg = true;

        return accumulator = accumulator || completeFalg;
    }, false);
    return canPolling;
}

function startPolling(snsKind, videoId, dispatch, getState) {
    const {
        stage,
        project
    } = getState();

    Polling (async () => {
        return await getPostingVideosAPI(stage, stage.endpoint, project.id, snsKind, videoId, 'status');
    }, (response) => {
        if (getState().posting.hasReset) {
            return true;
        }
               
        const startFlag = canStartPolling(response.data);
        return !startFlag;
    }, (response) => {
        dispatch(
            {
                type: GET_YOUTUBE_POSTING_STAUTS_SUCCESS,
                payload : response.data
            }
        )
    }, null, 5000).run();
};

export const reset = createAction(POSTING_RESET);
export const initialize = createAction(POSTING_INIT);

export const getSharedUrl = (resourceType, contentId) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();
    
    return getSharedUrlAPI(stage, stage.endpoint, project.id, resourceType, contentId).then(
        (response) => {
            dispatch({
                type : GET_SHARED_URL_SUCCESS,
                payload: response.data
            });
        }
    ).catch(error => {
        dispatch({
            type: GET_SHARED_URL_FAILURE,
            payload: error
        });
    });
}

export const getEmbedUrl = (resourceType, contentId, playerProfileId) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    dispatch({type : POSTING_PENDING});

    return getEmbedUrlAPI(stage, stage.endpoint, project.id, resourceType, contentId, playerProfileId).then(
        (response) => {
            dispatch({
                type : GET_EMBED_URL_SUCCESS,
                payload: response.data
            });
        }
    ).catch(error => {
        dispatch({
            type: GET_EMBED_URL_FAILURE,
            payload: error
        });
    });
}

export const getLoginUrl = (snsKind, redirectUrl) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    dispatch({type : POSTING_PENDING});

    return getLoginUrlAPI(stage, stage.endpoint, project.id, snsKind, redirectUrl);
}

export const insertAccount = (snsKind, account) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    return insertAccountAPI(stage, stage.endpoint, project.id, snsKind, account). then(
        (response) => {
            dispatch({
                type: GET_INSERT_ACCOUNTS_SUCCESS,
                payload: response.data
            })
        }
    ).catch(error => {
            dispatch({
                type: GET_INSERT_ACCOUNTS_FAILURE,
                payload: error
            });  
    });
}

export const getAccounts = (snsKind) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();
    return getAccountsAPI(stage, stage.endpoint, project.id, snsKind).then(
        (response) => {

            let type = GET_ALL_ACCOUNTS_SUCCESS;
            switch (snsKind){
                case 'youtube' :
                    type = GET_YOUTUBE_ACCOUNTS_SUCCESS;
                    break;
                // case 'facebook' :
                // case 'twitter' :
            }

            dispatch({
                type,
                payload: response.data
            });
            
        }).catch(error => {
            dispatch({
                type: GET_YOUTUBE_ACCOUNTS_FAILURE,
                payload: error
            });
        });
}

export const deleteAccount = (snsKind, accountId) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();
    return deleteAccountAPI(stage, stage.endpoint, project.id, snsKind, accountId).then(
        (response) => {

            // let type = GET_ALL_ACCOUNTS_SUCCESS;
            // switch (snsKind){
            //     case 'youtube' :
            //         type = GET_YOUTUBE_ACCOUNTS_SUCCESS;
            //         break;
            //     // case 'facebook' :
            //     // case 'twitter' :
            // }

            dispatch({
                type : DELETE_ACCOUNT_SUCCESS,
                payload: response.data
            });
            
        }).catch(error => {
            dispatch({
                type: DELETE__ACCOUNT_FAILURE,
                payload: error
            });
        });
}

export const postVideo = (snsKind, data) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();
    dispatch({
        type: POSTING_SET_HAS_RESET_TO_FALSE
    });
    return postVideoAPI(stage, stage.endpoint, project.id, snsKind, data).then(
        (response) => {
            dispatch({
                type: POST_YOUTUBE_SUCCESS,
                payload: response.data
            });
            const startFlag = canStartPolling(response.data);
            if (startFlag)
                startPolling(snsKind, data.videoId, dispatch, getState);
        }
    ).catch(error => {
        dispatch({
            type: POST_YOUTUBE_FAILURE,
            payload: error
        });
    })
}

export const getPostingVideo = (snsKind, videoId, children) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();
    return getPostingVideosAPI(stage, stage.endpoint, project.id, snsKind, videoId, children).then(
        (response) => {
            dispatch({
                type: GET_YOUTUBE_POSTING_VIDEO_SUCCESS,
                payload: response.data
            });
            const startFlag = canStartPolling(response.data);
            if (startFlag)
                startPolling(snsKind, videoId, dispatch, getState);
            
        }).catch(error => {
            dispatch({
                type: GET_YOUTUBE_POSTING_VIDEO_FAILURE,
                payload: error
            });
        });
}

export const getPostingHistory = (snsKind, videoId, status) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();
    dispatch({type : POSTING_PENDING});

    return getPostingHisotryAPI(stage, stage.endpoint, project.id, snsKind, videoId, status).then(
        (response) => {
            dispatch ( {
                type: GET_POSTING_HISTORY_SUCCESS,
                payload: response.data
            })
        }
    ). catch(error => {
        dispatch({
            type: GET_POSTING_HISTORY_FAILURE,
            payload: error
        })
    });
}