import { useCallback, useMemo, useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { AutocompleteMultipleUsers } from "@components_v2/autocomplete/autocomplete-multiple-users";
import { getProjectUsersAPI } from "@modules/apis/user-management-v2";

export const UsersMultiInputV2 = ({
    users,
    exceptUsers = [], //Note: options에서 특정 유저를 외부의 값으로 제외하고 싶을 때 사용합니다.
    filterSelectedOptions = false,
    isClear,
    disabled = false,
    onChange,
    placeholder,
    error,
    helperText,
    sx,
    textFieldSx,
}) => {
    const space = useSelector(({ stage }) => stage);
    const project = useSelector(({ project }) => project);

    const [value, setValue] = useState(users);
    const [open, setOpen] = useState(false);

    const [searchedValue, setSearchedValue] = useState(null); //현재 검색중인 이름
    const [userOptions, setUserOptions] = useState([]);
    const [selectedUser, setSelectedUser] = useState(users ?? []); //현재 선택한 유저(유저의 아이디만 popover로 넘겨주면 되서 자체력으로 autocomplete를 사용하도록 만들었습니다.)
    const [pendingUser, setPendingUser] = useState(false);
    const [debouncedValue, setDebouncedValue] = useState(null); //디바운싱된 검색어

    const nextToken = useRef(null);
    const optionsList = useRef([]);
    const USER_LIMIT = 40;

    const loadUserList = useCallback(
        async (isInitValue = false) => {
            try {
                if (pendingUser || (!isInitValue && !nextToken.current)) return;
                setPendingUser(true);
                let params = {
                    name: debouncedValue,
                    limit: USER_LIMIT,
                };
                if (nextToken.current) params.nextToken = nextToken.current;
                const response = await getProjectUsersAPI(space.id, space.endpoint, project.id, params);

                let newData = [];
                if (isInitValue) newData = response.data.results;
                else {
                    newData = [...optionsList.current, ...response.data.results];
                }

                setUserOptions(newData);
                nextToken.current = response.data.nextToken;
                optionsList.current = response.data.results;
            } catch (error) {
                console.log(error);
            } finally {
                setPendingUser(false);
            }
        },
        [pendingUser, space, searchedValue, userOptions, value, users, debouncedValue, nextToken],
    );

    const onHandleSubmitUser = useCallback(
        (value) => {
            if (value !== searchedValue) {
                setSearchedValue(value);
            }
        },
        [searchedValue],
    );

    const onHandleFetchUser = useCallback(() => {
        loadUserList();
    }, []);

    const onHandleChange = useCallback(
        (values) => {
            setSelectedUser(values);
            onChange(values);
        },
        [userOptions, onChange],
    );

    const handleInputChange = (reason) => {
        if (reason === "clear") {
            setSelectedUser(null);
            setValue(null);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const options = useMemo(() => {
        return (
            userOptions?.map((user, idx) => ({
                id: user.id,
                name: user.name,
                email: user.username,
                type: "USER",
                lastOption: idx === userOptions.length - 1,
            })) || []
        );
    }, [userOptions, optionsList]);

    useEffect(() => {
        loadUserList(true);
    }, []);

    useEffect(() => {
        setSelectedUser(null);
        setValue(null);
    }, [isClear]);

    useEffect(() => {
        //실시간 검색 지연
        if (searchedValue === null) return;
        const debounce = setTimeout(() => {
            return setDebouncedValue(searchedValue);
        }, 300);
        return () => clearTimeout(debounce);
    }, [searchedValue]);

    useEffect(() => {
        if (debouncedValue === null) return;
        loadUserList(true);
    }, [debouncedValue]);

    return (
        <AutocompleteMultipleUsers
            value={users}
            open={open}
            disabled={disabled}
            placeholder={placeholder}
            exceptOptions={exceptUsers}
            filterSelectedOptions={filterSelectedOptions}
            options={options ?? []}
            error={error}
            helperText={helperText}
            sx={sx}
            textFieldSx={textFieldSx}
            setOpen={setOpen}
            handleClose={handleClose}
            onFetchData={onHandleFetchUser}
            onInputChange={handleInputChange}
            onChange={onHandleChange}
            onSubmit={onHandleSubmitUser}
        />
    );
};
