import { useMemo } from "react";
import { Autocomplete as MuiAutocomplete, TextField, Chip, Checkbox } from "@mzc-pdc/ui";
import { IconErrorCircle, IconCancelCircle, IconWarningTriangle } from "@mzc-cloudplex/icons";
import useIntersection from "@hooks/useIntersection";

export const Autocomplete_old = ({
    type,
    open,
    multiple = true,
    tags,
    value,
    disabled,
    onDelete,
    onSubmit,
    onScroll,
    onClickOption,
    placeholder,
    popperComponent,
    noOptionsText,
    options,
    error,
    helperText,
}) => {
    const { setTarget } = useIntersection({
        onIntersect: onSubmit,
    });

    const getEndAdornment = useMemo(() => {
        if (error) {
            if (!helperText) return <IconWarningTriangle color={"warning"} size={16} />;
            return <IconErrorCircle color={`error`} size={16} />;
        }
        return undefined;
    }, [error, helperText]);

    return (
        <MuiAutocomplete
            freeSolo={type === "tag"} //Note : freesolo를 사용시 옵션과는 상관없는 값을 사용할 수 있게 되서 noOptionsText를 사용불가능(옵션 사용하는 경우 false 사용해야함)
            multiple={multiple}
            disableClearable
            open={open}
            value={multiple ? tags?.map((tag) => tag.label) : tags}
            options={
                options
                    ? options.map((option, idx) => ({
                          ...option,
                          id: option.id ?? option.title,
                          lastOption: idx === options.length - 1,
                      }))
                    : []
            }
            PopperComponent={popperComponent ?? null}
            // disableCloseOnSelect={!!options}
            size={`small`}
            disabled={disabled}
            getOptionLabel={(option) => {
                if (typeof option === "string") return option;
                else return option?.title;
            }}
            noOptionsText={noOptionsText} //Note : Chip 개선에 들어가는 검색 결과에 따른 옵션이 없을 경우 안내가 들어감
            renderOption={(props, option) => (
                <li
                    {...props}
                    onClick={() => {
                        if (onClickOption) onClickOption(option);
                    }}
                    ref={onScroll && option.lastOption ? setTarget : null}
                >
                    <>
                        {multiple && (
                            <Checkbox
                                color={"greySecondary"}
                                style={{ marginRight: 8 }}
                                checked={tags ? tags.find((x) => x.value === option.id) !== undefined : false}
                            />
                        )}
                        {option.component ? <>{option.component}</> : <>{option.title}</>}
                    </>
                </li>
            )}
            renderTags={(v, getTagProps) => {
                return v.map((option, index) => {
                    return (
                        <Chip
                            {...getTagProps({ index })}
                            sx={{ marginRight: "4px" }}
                            size={`small`}
                            label={option}
                            avatar={<></>}
                            color={option === value ? "error" : undefined}
                            onDelete={() => onDelete(index)}
                            deleteIcon={<IconCancelCircle size={12} />}
                        />
                    );
                });
            }}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        size={"small"}
                        placeholder={tags?.length > 0 ? "" : placeholder}
                        inputProps={{ ...params.inputProps, endAdornment: getEndAdornment }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                backgroundColor: `#fff`,
                                borderRadius: "2px",
                                "&:hover": {
                                    "& > fieldset": { borderColor: "#2763ea" },
                                },
                            },
                            "&.Mui-focused": {
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderWidth: `1px`,
                                },
                            },
                            ".MuiInputBase-root": {
                                border: `none`,
                                minHeight: `40px`,
                            },
                            ".MuiInputBase-input": {
                                border: `none`,
                                "&::placeholder": {
                                    color: "#9898ad",
                                    opacity: 1,
                                },
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                                top: `0`,
                                borderColor: `#b6b6ca`,
                                borderWidth: `1px !important`,
                            },
                            ".MuiFormHelperText-root": {
                                marginLeft: 0,
                                marginTop: 1,
                                fontSize: `14px`,
                            },
                            ".MuiAutocomplete-popperDisablePortal": {
                                disablePortal: true,
                            },
                        }}
                    />
                );
            }}
            // open={false} //option dropdown 비활성화
        />
    );
};
