import React, { useCallback, useMemo } from "react";
import { Grid } from "@mzc-pdc/ui";
import theme from "@styles/theme";
import { alpha, styled } from "@mui/system";
import {
    ImageDefaultAudioDark,
    ImageDefaultAudioSmallDark,
    ImageDefaultCollectionDark,
    ImageDefaultCollectionSmallDark,
    ImageDefaultFileDark,
    ImageDefaultFileSmallDark,
    ImageDefaultImageDark,
    ImageDefaultImageSmallDark,
    ImageDefaultTextDark,
    ImageDefaultTextSmallDark,
    ImageDefaultVideoDark,
    ImageDefaultVideoSmallDark,
} from "@mzc-cloudplex/icons";

const CardThumbnail = styled(Grid)(({ index }) => ({
    width: `100%`,
    height: `100%`,
    position: `relative`,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    borderRadius: 4,
    backgroundColor: alpha(theme.palette.greySecondary[100], 0.2),
}));

const TableThumbnail = styled(Grid)(() => ({
    minWidth: `100%`,
    width: `100%`,
    height: `100%`,
    position: `relative`,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    borderRadius: 4,
}));

const ThumbnailShadow = styled("span")(({ variant }) => ({
    position: `absolute`,
    left: `100%`,
    display: `block`,
    width: variant === "table" ? 0 : 6,
    height: `-webkit-fill-available`,
    margin: variant === "table" ? `0` : variant === "panel" ? `24px 2px 0` : `10px 2px`,
    backgroundColor: alpha(theme.palette.greySecondary[100], 0.4),
    borderRadius: `0 6px 6px 0`,
    "&:before, &:after": {
        content: '""',
        display: `block`,
        position: `absolute`,
        left: `100%`,
        top: 0,
        bottom: 0,
        width: variant === "table" ? 2 : 6,
        margin: variant === "table" ? `2px 1px` : variant === "panel" ? `24px 2px 0` : `12px 2px`,
        borderRadius: `0 6px 6px 0`,
        opacity: 0.6,
        backgroundColor: theme.palette.greySecondary[100],
    },
    "&:before": {
        margin: variant === "table" ? `4px` : variant === "panel" ? `48px 10px 0` : `20px 10px`,
        opacity: 1,
    },
}));

const CollectionThumbnail = ({ collection, size, variant, selected }) => {
    const getDefaultImageFromExtension = useCallback(
        (width, height, marginLeft) => {
            const mediaType = collection?.primaryAsset?.mediaType;

            if (variant === "table") {
                switch (mediaType) {
                    case "VIDEO":
                        return (
                            <ImageDefaultVideoSmallDark width={width} height={height} sx={{ marginLeft: marginLeft }} />
                        );
                    case "AUDIO":
                        return (
                            <ImageDefaultAudioSmallDark width={width} height={height} sx={{ marginLeft: marginLeft }} />
                        );
                    case "IMAGE":
                        return (
                            <ImageDefaultImageSmallDark width={width} height={height} sx={{ marginLeft: marginLeft }} />
                        );
                    case "TEXT":
                        return (
                            <ImageDefaultTextSmallDark width={width} height={height} sx={{ marginLeft: marginLeft }} />
                        );
                    case "FILE":
                        return (
                            <ImageDefaultFileSmallDark width={width} height={height} sx={{ marginLeft: marginLeft }} />
                        );
                    default:
                        return (
                            <ImageDefaultCollectionSmallDark
                                width={width}
                                height={height}
                                sx={{ marginLeft: marginLeft }}
                            />
                        );
                }
            } else {
                switch (mediaType) {
                    case "VIDEO":
                        return <ImageDefaultVideoDark width={width} height={height} sx={{ marginLeft: marginLeft }} />;
                    case "AUDIO":
                        return <ImageDefaultAudioDark width={width} height={height} sx={{ marginLeft: marginLeft }} />;
                    case "IMAGE":
                        return <ImageDefaultImageDark width={width} height={height} sx={{ marginLeft: marginLeft }} />;
                    case "TEXT":
                        return <ImageDefaultTextDark width={width} height={height} sx={{ marginLeft: marginLeft }} />;
                    case "FILE":
                        return <ImageDefaultFileDark width={width} height={height} sx={{ marginLeft: marginLeft }} />;
                    default:
                        return (
                            <ImageDefaultCollectionDark width={width} height={height} sx={{ marginLeft: marginLeft }} />
                        );
                }
            }
        },
        [collection],
    );

    const getThumbnails = useMemo(() => {
        if (!collection) return <></>;

        const previews = collection?.primaryAsset?.previews;

        let parsedPreviews = new Array(4)
            .fill()
            .map((value, index) => (index < previews?.length ? previews[index] : null));

        const preview = previews?.[0];

        if (variant === "table") {
            return (
                <>
                    <TableThumbnail item>
                        {preview?.accessUrl ? (
                            <img src={preview.accessUrl} alt={`collection-table-preview-${collection.id}`} />
                        ) : (
                            getDefaultImageFromExtension(28, 28)
                        )}
                        <ThumbnailShadow variant={"table"} />
                    </TableThumbnail>
                </>
            );
        } else {
            return (
                <>
                    <CardThumbnail item>
                        {preview?.accessUrl ? (
                            <img src={preview.accessUrl} alt={`collection-card-preview-${collection.id}`} />
                        ) : (
                            getDefaultImageFromExtension(46, 60)
                        )}
                        <ThumbnailShadow variant={variant === "card" ? "card" : "panel"} />
                    </CardThumbnail>
                </>
            );
        }
    }, [collection, variant]);

    const getContainerStyle = useMemo(() => {
        switch (variant) {
            case "table":
                return {
                    minWidth: size === "xsmall" ? "40px" : "52px",
                    width: size === "xsmall" ? "40px" : "52px",
                    height: size === "xsmall" ? "40px" : "52px",
                    padding: 0,
                    borderRadius: "4px",
                    marginRight: `14px`,
                };
            case "card":
                return {
                    minWidth: "100%",
                    width: "202px",
                    height: "202px",
                    padding: `32px 36px 16px 12px`,
                    borderRadius: "8px",
                    marginRight: 0,
                    "&:hover": {
                        backgroundColor: theme.palette.greySecondary[50],
                        ".MuiGrid-item": {
                            outlineColor: theme.palette.greySecondary[50],
                        },
                    },
                };
            case "panel":
                return {
                    minWidth: "100%",
                    width: "100%",
                    height: "223px",
                    padding: `12px 54px 12px 24px`,
                };
            default:
                return {};
        }
    }, [variant]);

    return (
        <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            flexWrap={"nowrap"}
            sx={{
                ...getContainerStyle,
                position: `relative`,
                backgroundColor: selected
                    ? alpha(theme.palette.greySecondary.A100, 0.4)
                    : alpha(theme.palette.greySecondary[50], 0.5),
                "&:hover": {
                    backgroundColor: theme.palette.greySecondary[50],
                },
                "& img": {
                    objectFit: `cover`,
                    maxWidth: `100%`,
                    maxHeight: `100%`,
                    borderRadius: `4px`,
                },
                ".MzcCpIcon-root + * ": {
                    marginLeft: 0,
                },
            }}
        >
            {getThumbnails}
        </Grid>
    );
};

export default CollectionThumbnail;
