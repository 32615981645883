import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetFilter, updateFilter } from "@modules/filter";
import { updateFilteredAssets } from "@modules/assets";
import { AUTOCOMPLETE_TABLE_LIST_TYPE } from "@constants";

const useFilter = (type) => {
    // NOTE: type을 지정해 주는 경우만 localStorage에 저장함
    const dispatch = useDispatch();
    const filterValue = useSelector(({ filter }) => filter.value);
    const editFilterValue = useSelector(({ filter }) => filter.editValue);
    const assets = useSelector(({ assets }) => assets);

    const updateEditFilter = (id, value) => {
        dispatch(updateFilter({ editValue: { ...editFilterValue, [id]: value } }));
    };

    const deleteFilter = (id, callback) => {
        const copiedEditFilterValue = { ...editFilterValue };
        const copiedFilterValue = { ...filterValue };

        delete copiedEditFilterValue[id];
        delete copiedFilterValue[id];

        dispatch(updateFilter({ value: copiedFilterValue, editValue: copiedEditFilterValue }));
        if (type) localStorage.setItem(`${type}Filter`, JSON.stringify(copiedFilterValue));
        if (callback) callback(copiedEditFilterValue);
    };

    const resetEditFilter = () => {
        dispatch(updateFilter({ editValue: { ...filterValue } }));
    };

    const removeFilter = (type) => {
        if (type) localStorage.removeItem(`${type}Filter`);
        dispatch(resetFilter());
    };

    const saveFilter = (id, callback) => {
        if (!id) return;
        const newEditObj = new Object();
        let copiedEditFilterValue = { ...editFilterValue };
        Object.keys(editFilterValue).forEach((key) => {
            if (editFilterValue[key]?.length) {
                newEditObj[key] = editFilterValue[key];
                copiedEditFilterValue = { ...copiedEditFilterValue, ...newEditObj };
            } else {
                delete copiedEditFilterValue[key];
            }
        });

        dispatch(updateFilter({ value: copiedEditFilterValue }));
        if (type) localStorage.setItem(`${type}Filter`, JSON.stringify(copiedEditFilterValue));
        if (callback) callback(copiedEditFilterValue);
    };

    const onUpdateFilterParams = (params) => {
        if (params.constructor === Object && Object.keys(params).length === 0) {
            dispatch(updateFilteredAssets(assets.originData));
        } else {
            if (type === AUTOCOMPLETE_TABLE_LIST_TYPE.AUTOCOMPLETE_TABLE_ASSET) onFilterAssetList(params);
        }
    };

    const onFilterAssetList = (params) => {
        if (!params) return;
        Object.entries(params)
            .filter(([key, value]) => value?.length)
            .map(([key, value]) => {
                const values = value?.map(({ value }) => value);
                const filteredData = assets.originData.filter((item) => values.includes(item[key]));
                dispatch(updateFilteredAssets(filteredData));
            });
    };

    useEffect(() => {
        const filters = type ? JSON.parse(localStorage.getItem(`${type}Filter`)) : {};
        dispatch(updateFilter({ value: filters, editValue: filters }));

        return () => {
            dispatch(resetFilter());
        };
    }, []);

    return {
        filterValue,
        editFilterValue,
        updateEditFilter,
        resetEditFilter,
        saveFilter,
        deleteFilter,
        onUpdateFilterParams,
        removeFilter,
    };
};

export default useFilter;
