import { axios } from "@cores/axiosWrapper";

export const getThreadsAPI = (apiEndpoint, projectId, params) => {
    return axios.get(`${apiEndpoint}/threads`, {
        params,
        headers: {
            projectId,
        },
    });
};

export const getThreadAPI = (apiEndpoint, projectId, threadId) => {
    return axios.get(`${apiEndpoint}/threads/${threadId}`, {
        headers: {
            projectId,
        },
    });
};

export const getCommentsAPI = (apiEndpoint, projectId, threadId, params) => {
    return axios.get(`${apiEndpoint}/threads/${threadId}/comments`, {
        params,
        headers: {
            projectId,
        },
    });
};

export const getCommentAPI = (apiEndpoint, projectId, threadId, commentId) => {
    return axios.get(`${apiEndpoint}/threads/${threadId}/comments/${commentId}`, {
        headers: {
            projectId,
        },
    });
};

export const createThreadAPI = (apiEndpoint, projectId, data) => {
    return axios.post(`${apiEndpoint}/threads`, data, {
        headers: {
            projectId,
        },
    });
};

export const updateThreadAPI = (apiEndpoint, projectId, threadId, data) => {
    return axios.patch(`${apiEndpoint}/threads/${threadId}`, data, {
        headers: {
            projectId,
        },
    });
};

export const createCommentAPI = (apiEndpoint, projectId, threadId, data) => {
    return axios.post(`${apiEndpoint}/threads/${threadId}/comments`, data, {
        headers: {
            projectId,
        },
    });
};

export const updateCommentAPI = (apiEndpoint, projectId, threadId, commentId, data) => {
    return axios.patch(`${apiEndpoint}/threads/${threadId}/comments/${commentId}`, data, {
        headers: {
            projectId,
        },
    });
};

export const deleteThreadAPI = (apiEndpoint, projectId, threadId) => {
    return axios.delete(`${apiEndpoint}/threads/${threadId}`, {
        headers: {
            projectId,
        },
    });
};

export const deleteCommentAPI = (apiEndpoint, projectId, threadId, commentId) => {
    return axios.delete(`${apiEndpoint}/threads/${threadId}/comments/${commentId}`, {
        headers: {
            projectId,
        },
    });
};

export const createActionsAPI = (apiEndpoint, projectId, threadId, commentId, data) => {
    return axios.post(`${apiEndpoint}/threads/${threadId}/comments/${commentId}/actions`, data, {
        headers: {
            projectId,
        },
    });
};

export const getThreadMembersAPI = (apiEndpoint, projectId, threadId) => {
    return axios.get(`${apiEndpoint}/threads/${threadId}/entities`, {
        headers: {
            projectId,
        },
    });
};

export const updateThreadMembersAPI = (apiEndpoint, projectId, threadId, data) => {
    return axios.post(`${apiEndpoint}/threads/${threadId}/entities`, data, {
        headers: {
            projectId,
        },
    });
};

export const deleteCommentAttachmentAPI = (apiEndpoint, projectId, threadId, commentId, attachmentId) => {
    return axios.delete(`${apiEndpoint}/threads/${threadId}/comments/${commentId}/attachments/${attachmentId}`, {
        headers: {
            projectId,
        },
    });
};

export const addCommentAttachmentsAPI = (apiEndpoint, projectId, threadId, commentId, data) => {
    return axios.post(`${apiEndpoint}/threads/${threadId}/comments/${commentId}/attachments`, data, {
        headers: {
            projectId,
        },
    });
};
