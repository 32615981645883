import React from "react";
import fp from "lodash/fp";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import { useLocation } from "react-router-dom";
import compareVersions, { VERSION_CM_5112_PROJECT_SITE_ACCESS } from "../../../cores/version";

const ContentsRedirect = (props) => {
    const stage = useSelector((state) => state.stage);
    const project = useSelector((state) => state.project);
    const location = useLocation();
    const { spaceId, projectId } = menuService.parse(location.pathname);

    let hasConsoleAccess = false;
    if (compareVersions(fp.get("version", stage), VERSION_CM_5112_PROJECT_SITE_ACCESS) >= 0) {
        if (stage.user && stage.user.projects) {
            const userProject = stage.user.projects.filter((p) => p.id === project.id)[0];
            if (userProject) {
                const siteAccess = userProject.siteAccess;
                hasConsoleAccess = siteAccess && siteAccess.indexOf("CONSOLE") !== -1;
            }
        }
    }
    console.log("hasConsoleAccess", hasConsoleAccess);

    return (
        <>
            {hasConsoleAccess ? (
                <Redirect to={`${menuService.paths.contentsVideos(spaceId, projectId)}`} />
            ) : (
                <Redirect to={`${menuService.paths.contentsVideos(spaceId, projectId)}`} />
            )}
        </>
    );
};

export default ContentsRedirect;
