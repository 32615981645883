"use strict";

if (!String.prototype.toEpochTime) {
    String.prototype.toEpochTime = function() {
        const dateString = this;

        const date = new Date(dateString.replace(/\s/, 'T')+'Z');
        const epochTime = Math.floor(date.getTime() / 1000);

        if (isNaN(epochTime)) {
            throw new Error("Invalid date format");
        }

        return epochTime;
    };
}

if (!String.prototype.toCamelCase) {
    String.prototype.toCamelCase = function() {
        const str = this;

        return str.replace(new RegExp(/[-_]+/, "g"), " ")
            .replace(new RegExp(/[A-Z]+/g, "g"), ($1, $2, $3) => ` ${$1}`)
            .replace(new RegExp(/[^\w\s]/, "g"), "")
            .replace(
                new RegExp(/(\w)(\w*)/, "g"),
                ($1, $2, $3) => {
                    return `${$2.toUpperCase() + $3.toLowerCase()}`;
                }
            )
            .replace(new RegExp(/\s/, "g"), "")
            .replace(new RegExp(/\w/), s => s.toLowerCase());
    };
}

if (!String.prototype.toPascalCase) {
    String.prototype.toPascalCase = function() {
        const str = this;

        return str.replace(new RegExp(/[-_]+/, "g"), " ")
            .replace(new RegExp(/[^\w\s]/, "g"), "")
            .replace(
                new RegExp(/(\w)(\w*)/, "g"),
                ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`
            )
            .replace(new RegExp(/\s/, "g"), "")
            .replace(new RegExp(/\w/), s => s.toUpperCase());
    };
}

if (!String.subdivideUrl) {
    String.subdivideUrl = function (url) {
        const schemeDelimeter = "://";
        if (!url || url.indexOf(schemeDelimeter) < 0) {
            return null;
        }
        const lastIndexOfSlash = url.lastIndexOf('/');
        const lastIndexOfDot = url.lastIndexOf('.');
        if (lastIndexOfDot > 0 && lastIndexOfDot > lastIndexOfSlash) {
            // 파일명이 있는 주소임
            const extension = url.substring(lastIndexOfDot + 1, url.length);
            const scheme = url.substring(0, url.indexOf(schemeDelimeter));
            const excludeSchemeUrl = url.replace(`${scheme}${schemeDelimeter}`, "");

            const host = excludeSchemeUrl.substring(0, excludeSchemeUrl.indexOf("/"));
            const relativePath = excludeSchemeUrl.substring(host.length + 1, excludeSchemeUrl.lastIndexOf("/"));

            const result = {
                host: host,
                scheme: scheme,
                relativePath: relativePath === "/" ? "" : relativePath,
                fileName: url.substring(url.lastIndexOf("/") + 1, url.length - extension.length - 1),
                extension
            };

            return result;
        }
        else {
            // 폴더만 있는 주소임
            const scheme = url.substring(0, url.indexOf(schemeDelimeter));
            const excludeSchemeUrl = url.replace(`${scheme}${schemeDelimeter}`, "");
            const indexOfFirstSlash = excludeSchemeUrl.indexOf("/");
            const host = indexOfFirstSlash < 0 ? excludeSchemeUrl : excludeSchemeUrl.substring(0, indexOfFirstSlash);
            const relativePath = excludeSchemeUrl.length > host.length ? excludeSchemeUrl.substring(host.length + 1) : '';

            const result = {
                host,
                scheme,
                relativePath
            };
            return result;
        }
    };
}

if (!String.prototype.toS3Object) {
    String.prototype.toS3Object = function () {
        const url = this;

        if (url.indexOf("s3") < 0) {
            return null;
        }

        const structure = String.subdivideUrl(url);
        if (structure.scheme.toLowerCase() === "s3") {
            return {
                bucket: structure.host,
                key: createKeyBy(structure.relativePath, structure.fileName, structure.extension)
            };
        }

        if (structure.host.indexOf(".s3.") > 0 || structure.host.indexOf(".s3-") > 0) {
            return {
                bucket: structure.host.substring(0, structure.host.indexOf(".")),
                key: createKeyBy(structure.relativePath, structure.fileName, structure.extension)
            };
        }

        const bucket = structure.relativePath.indexOf("/") > 0
            ? structure.relativePath.substring(0, structure.relativePath.indexOf("/"))
            : structure.relativePath.substring(0, structure.relativePath.length);

        return {
            bucket: bucket,
            key: createKeyBy(structure.relativePath.replace(bucket, "").replace("/", ""),
                structure.fileName,
                structure.extension)
        };

        function createKeyBy(relativePath, fileName, extension) {
            if (fileName && extension) {
                if (!relativePath || relativePath === "/") {
                    return `${fileName}.${extension}`;
                }

                return `${relativePath}/${fileName}.${extension}`;
            }
            return relativePath;
        }
    };
}

if(!String.prototype.convertS3Url) {
    String.prototype.convertS3Url = function() {
        const url = this;

        if (url.indexOf('s3://') !== 0) 
            return null;

        const s3Object = url.toS3Object();
        return `https://${s3Object.bucket}.s3.amazonaws.com/${s3Object.key}`;
    }
}


if (!String.prototype.removeBeginningSlash) {
    String.prototype.removeBeginningSlash = function () {
        let temp = this;
        if (!temp || temp.length === 0) {
            return temp;
        }

        let indexOfSlash = temp.indexOf('/');
        while (indexOfSlash === 0) {
            temp = temp.substring(1);
            indexOfSlash = temp.indexOf('/');
        }
        return temp;
    };
}

if (!String.prototype.removeEndingSlash) {
    String.prototype.removeEndingSlash = function () {
        let temp = this;
        if (!temp || temp.length === 0) {
            return temp;
        }
        let indexOfSlash = temp.lastIndexOf('/');
        while (indexOfSlash >= 0 && indexOfSlash === temp.length - 1) {
            temp = temp.substring(0, temp.length - 1);
            indexOfSlash = temp.lastIndexOf('/');
        }
        return temp;
    };
}