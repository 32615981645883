import React from "react";
import { useTranslation } from "react-i18next";
import Loadable from "@react-loadable/revised";
import PanelHeader from "@components/property-panel/ui/PanelHeader";
import PanelHeaderTool from "@components/property-panel/ui/PanelHeaderTool";
import PanelHeaderTitle from "@components/property-panel/ui/PanelHeaderTitle";

const LoadableLineupVideos = Loadable({
    loader: () => import("@routes/listings/ContentList"),
    loading: () => null,
});

const ListingsLineupDetailPanel = ({ onClickClose, isTooltipSelectSourceGuideActive, onInputToogleActive }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <PanelHeader>
                <PanelHeaderTitle>
                    <span>
                        {t(`common::label::Select {{name}}`, {
                            name: t(`common::label::Sources`),
                        })}
                    </span>
                    <div
                        className={
                            "tooltip-guide tooltip-guide-file-source " +
                            (isTooltipSelectSourceGuideActive ? "active" : "")
                        }
                        onMouseEnter={onInputToogleActive}
                    >
                        <i className={"sprite sprite-info"}></i>
                        <div className={"tooltip"}>
                            <div className={"tooltip-content"}>
                                <div className={"tooltip-header"}>
                                    <p className={"title"}>
                                        <strong>
                                            <span>
                                                {t(`common::label::Select {{name}}`, {
                                                    name: t(`common::label::Sources`),
                                                })}
                                            </span>
                                        </strong>
                                    </p>

                                    <button type={"button"} onClick={onInputToogleActive} className={"close"}>
                                        <span>
                                            <i className={"sprite sprite-cancel-lg"}></i>
                                        </span>
                                    </button>
                                </div>
                                <div className={"tooltip-body"}>
                                    <div className={"list-dot"}>
                                        <ul>
                                            <li className="dot">
                                                <p>
                                                    <span>
                                                        {t(`common::label::Create {{name}}`, {
                                                            name: t(`common::label::Lineup`),
                                                        })}
                                                    </span>
                                                </p>
                                                <div className={"text-secondary text-normal"}>
                                                    {t(
                                                        `common::msg::Add file by drag and drop or double-clicking in the select sources.`,
                                                    )}
                                                </div>
                                            </li>
                                            <li className="dot">
                                                <p>
                                                    {t(`common::label::Assets {{name}}`, {
                                                        name: t(`common::label::Constraints`),
                                                    })}
                                                </p>
                                                <div className={"text-secondary text-normal"}>
                                                    {t(`common::msg::Assets must only be in DRM-free MP4 video type.`)}
                                                </div>
                                            </li>
                                            <li className="dot">
                                                <p>
                                                    {t(`common::label::Videos`)} {t(`common::label::Constraints`)}
                                                </p>
                                                <div className={"text-secondary text-normal"}>
                                                    {t(
                                                        `common::msg::Videos must only be in DRM-free MP4 video type among the sources.`,
                                                    )}
                                                    <br />

                                                    {t(
                                                        `common::msg::The language of the captions to be applied to the video must not be duplicated.`,
                                                    )}
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </PanelHeaderTitle>

                <PanelHeaderTool>
                    <button className="btn btn-icon-solid btn-panel-close" onClick={onClickClose}>
                        <span>
                            <i className="sprite sprite-cancel-lg"></i>
                        </span>
                        <span className={"hidden"}>{t(`common::label::${"Close"}`)}</span>
                    </button>
                </PanelHeaderTool>
            </PanelHeader>
            <React.Fragment>
                <LoadableLineupVideos />
            </React.Fragment>
        </React.Fragment>
    );
};

export default ListingsLineupDetailPanel;
