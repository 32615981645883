import { handleActions, createAction } from "redux-actions";

import { axios, axiosWithoutHeaders } from "../cores/axiosWrapper";
import queryString from "../cores/queryString";
import moment from "moment";

function getMetadataSchemasAPI(stageId, apiEndpoint, projectId, search) {
    const queries = {
        offset: search.offset,
        limit: search.limit,
    };
    queries.projectId = search.projectId || "";
    queries.domain = search.domain || "";
    queries.creator = search.creator || "";
    queries.name = search.name || "";

    if (search.startAt && search.endAt) {
        const startAt = `${moment(`${search.startAt.format("YYYY-MM-DD")} 00:00:00`)
            .utc()
            .format("YYYY-MM-DD")}T${moment(`${search.startAt.format("YYYY-MM-DD")} 00:00:00`)
            .utc()
            .format("HH:mm:ss")}Z`;
        const endAt = `${moment(`${search.endAt.format("YYYY-MM-DD")} 00:00:00`)
            .utc()
            .format("YYYY-MM-DD")}T${moment(`${search.endAt.format("YYYY-MM-DD")} 00:00:00`)
            .utc()
            .format("HH:mm:ss")}Z`;

        queries.createdAt = `${startAt}~${endAt}`;
    }

    const headers = { stageId };
    if (projectId) headers.projectId = projectId;

    return axios.get(`${apiEndpoint}/metadata-schemas?${queryString.stringify(queries)}`, {
        headers,
    });
}

function getMetadataSchemaAPI(stageId, apiEndpoint, projectId, id) {
    const headers = { stageId };
    if (projectId) headers.projectId = projectId;

    return axios.get(`${apiEndpoint}/metadata-schemas/${id}`, {
        headers,
    });
}

function postMetadataSchemaAPI(stageId, apiEndpoint, data) {
    return axios.post(`${apiEndpoint}/metadata-schemas`, data, {
        headers: {
            stageId,
        },
    });
}

function registerMetadataSchemaAPI(stageId, projectId, apiEndpoint, domain, data) {
    const queries = {
        projectId: projectId ? projectId : "",
        domain,
    };
    return axios.put(`${apiEndpoint}/metadata-schemas/register?${queryString.stringify(queries)}`, data, {
        headers: {
            stageId,
        },
    });
}

function patchMetadataSchemaAPI(stageId, apiEndpoint, id, data) {
    return axios.patch(
        `${apiEndpoint}/metadata-schemas/${id}`,
        {
            ...data,
        },
        {
            headers: {
                stageId,
            },
        },
    );
}

async function deleteMetadataSchemaAPI(stageId, apiEndpoint, id) {
    return axios.delete(`${apiEndpoint}/metadata-schemas/${id}`, null, {
        headers: {
            stageId,
        },
    });
}

//action type
const METADATA_SCHEMA_MANAGEMENT_RESET_METADATAS = "METADATA_SCHEMA_MANAGEMENT_RESET_METADATAS";
const METADATA_SCHEMA_MANAGEMENT_LIST_PENDING = "METADATA_SCHEMA_MANAGEMENT_LIST_PENDING";
const METADATA_SCHEMA_MANAGEMENT_LIST_FAILURE = "METADATA_SCHEMA_MANAGEMENT_LIST_FAILURE";

const METADATA_SCHEMA_MANAGEMENT_LIST_UPDATE_SEARCH = "METADATA_SCHEMA_MANAGEMENT_LIST_UPDATE_SEARCH";
const METADATA_SCHEMA_MANAGEMENT_LIST_RESET_SEARCH = "METADATA_SCHEMA_MANAGEMENT_LIST_RESET_SEARCH";

const METADATA_SCHEMA_MANAGEMENT_LIST_GET_METADATA_SCHEMA_LIST_SUCCESS =
    "METADATA_SCHEMA_MANAGEMENT_LIST_GET_METADATA_SCHEMA_LIST_SUCCESS";

const METADATA_SCHEMA_MANAGEMENT_LIST_DELETE_METADATA_SCHEMA_FAILURE =
    "METADATA_SCHEMA_MANAGEMENT_LIST_DELETE_METADATA_SCHEMA_FAILURE";
const METADATA_SCHEMA_MANAGEMENT_LIST_DELETE_METADATA_SCHEMA_SUCCESS =
    "METADATA_SCHEMA_MANAGEMENT_LIST_DELETE_METADATA_SCHEMA_SUCCESS";

const METADATA_SCHEMA_MANAGEMENT_DETAIL_GET_METADATA_SCHEMA_DETAIL_SUCCESS =
    "METADATA_SCHEMA_MANAGEMENT_DETAIL_GET_METADATA_SCHEMA_DETAIL_SUCCESS";
const METADATA_SCHEMA_MANAGEMENT_DETAIL_PATCH_METADATA_SCHEMA_SUCCESS =
    "METADATA_SCHEMA_MANAGEMENT_DETAIL_PATCH_METADATA_SCHEMA_SUCCESS";

const METADATA_SCHEMA_MANAGEMENT_DETAIL_RESET_FIELD = "METADATA_SCHEMA_MANAGEMENT_DETAIL_RESET_FIELD";
const METADATA_SCHEMA_MANAGEMENT_LIST_TOGGLE_ACTION_BUTTON = "METADATA_SCHEMA_MANAGEMENT_LIST_TOGGLE_ACTION_BUTTON";
const METADATA_SCHEMA_MANAGEMENT_LIST_TOGGLE_BUTTON = "METADATA_SCHEMA_MANAGEMENT_LIST_TOGGLE_BUTTON";
const METADATA_SCHEMA_MANAGEMENT_CTEATE_METADATA_SCHEMA_SUCCESS =
    "METADATA_SCHEMA_MANAGEMENT_CTEATE_METADATA_SCHEMA_SUCCESS";
const METADATA_SCHEMA_MANAGEMENT_CTEATE_METADATA_SCHEMA_FAILURE =
    "METADATA_SCHEMA_MANAGEMENT_CTEATE_METADATA_SCHEMA_FAILURE";
const METADATA_SCHEMA_MANAGEMENT_REGISTER_METADATA_SCHEMA_SUCCESS =
    "METADATA_SCHEMA_MANAGEMENT_REGISTER_METADATA_SCHEMA_SUCCESS";

const METADATA_SCHEMA_MANAGEMENT_RESET_ERROR = "METADATA_SCHEMA_MANAGEMENT_RESET";

//reducer
const initialState = {
    pending: false,
    error: false,
    metadataSchemas: [],
    metadataSchema: {},
    totalCount: 0,
    search: {
        offset: 0,
        limit: 50,
        name: "",
        creator: "",
        startAt: null,
        endAt: null,
    },
};

export default handleActions(
    {
        [METADATA_SCHEMA_MANAGEMENT_RESET_METADATAS]: (state, action) => {
            return {
                ...initialState,
            };
        },
        [METADATA_SCHEMA_MANAGEMENT_LIST_PENDING]: (state, action) => {
            return {
                ...state,
                pending: true,
            };
        },
        [METADATA_SCHEMA_MANAGEMENT_LIST_FAILURE]: (state, action) => {
            return {
                ...state,
                error: true,
                pending: false,
            };
        },
        [METADATA_SCHEMA_MANAGEMENT_LIST_GET_METADATA_SCHEMA_LIST_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                metadataSchemas: data.metadataSchemas.map((metadata) => {
                    return {
                        ...metadata,
                        isShowList: false,
                    };
                }),
                totalCount: data.totalCount,
                pending: false,
            };
        },
        [METADATA_SCHEMA_MANAGEMENT_LIST_UPDATE_SEARCH]: (state, action) => {
            return {
                ...state,
                search: {
                    ...state.search,
                    ...action.payload,
                },
            };
        },
        [METADATA_SCHEMA_MANAGEMENT_LIST_RESET_SEARCH]: (state, action) => {
            return {
                ...state,
                search: initialState.search,
            };
        },
        [METADATA_SCHEMA_MANAGEMENT_DETAIL_RESET_FIELD]: (state, action) => {
            return {
                ...state,
                metadataSchema: {
                    ...state.metadataSchema,
                    fields: [],
                },
            };
        },
        [METADATA_SCHEMA_MANAGEMENT_LIST_TOGGLE_ACTION_BUTTON]: (state, action) => {
            return {
                ...state,
                metadataSchemas: state.metadataSchemas.map((v) => {
                    return {
                        ...v,
                        isShowActionButton: v.id === action.payload ? !v.isShowActionButton : false,
                    };
                }),
            };
        },
        [METADATA_SCHEMA_MANAGEMENT_LIST_TOGGLE_BUTTON]: (state, action) => {
            return {
                ...state,
                metadataSchemas: state.metadataSchemas.map((v) => {
                    return {
                        ...v,
                        isShowList: v.id === action.payload ? !v.isShowList : false,
                    };
                }),
            };
        },
        [METADATA_SCHEMA_MANAGEMENT_LIST_DELETE_METADATA_SCHEMA_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
            };
        },
        [METADATA_SCHEMA_MANAGEMENT_LIST_DELETE_METADATA_SCHEMA_SUCCESS]: (state, action) => {
            return {
                ...state,
                metadataSchemas: state.metadataSchemas.map((v) => {
                    return {
                        ...v,
                        isDeleted: v.id === action.payload,
                    };
                }),
            };
        },
        [METADATA_SCHEMA_MANAGEMENT_CTEATE_METADATA_SCHEMA_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
            };
        },
        [METADATA_SCHEMA_MANAGEMENT_REGISTER_METADATA_SCHEMA_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
            };
        },
        [METADATA_SCHEMA_MANAGEMENT_CTEATE_METADATA_SCHEMA_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [METADATA_SCHEMA_MANAGEMENT_DETAIL_GET_METADATA_SCHEMA_DETAIL_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                metadataSchema: data,
                pending: false,
            };
        },
        [METADATA_SCHEMA_MANAGEMENT_DETAIL_PATCH_METADATA_SCHEMA_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                metadataSchema: data,
                pending: false,
            };
        },
        [METADATA_SCHEMA_MANAGEMENT_RESET_ERROR]: (state, action) => {
            return {
                ...state,
                error: false,
            };
        },
    },
    initialState,
);

export const reset = createAction(METADATA_SCHEMA_MANAGEMENT_RESET_METADATAS);
export const updateSearch = createAction(METADATA_SCHEMA_MANAGEMENT_LIST_UPDATE_SEARCH);
export const resetSearch = createAction(METADATA_SCHEMA_MANAGEMENT_LIST_RESET_SEARCH);
export const toggleActionButton = createAction(METADATA_SCHEMA_MANAGEMENT_LIST_TOGGLE_ACTION_BUTTON);
export const toggleButton = createAction(METADATA_SCHEMA_MANAGEMENT_LIST_TOGGLE_BUTTON);
export const resetFields = createAction(METADATA_SCHEMA_MANAGEMENT_DETAIL_RESET_FIELD);

export const getMetadataSchemas = (projectId, search) => (dispatch, getState) => {
    const { stage, metadataSchemaManagements } = getState();

    dispatch({ type: METADATA_SCHEMA_MANAGEMENT_LIST_PENDING });

    return getMetadataSchemasAPI(stage.id, stage.endpoint, projectId, search || metadataSchemaManagements.search)
        .then((response) => {
            dispatch({
                type: METADATA_SCHEMA_MANAGEMENT_LIST_GET_METADATA_SCHEMA_LIST_SUCCESS,
                payload: response,
            });
            return response.data;
        })
        .catch((error) => {
            dispatch({
                type: METADATA_SCHEMA_MANAGEMENT_LIST_FAILURE,
                payload: error,
            });
        });
};

export const getMetadataSchema = (projectId, id) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: METADATA_SCHEMA_MANAGEMENT_LIST_PENDING });

    getMetadataSchemaAPI(stage.id, stage.endpoint, projectId, id)
        .then((response) => {
            dispatch({
                type: METADATA_SCHEMA_MANAGEMENT_DETAIL_GET_METADATA_SCHEMA_DETAIL_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: METADATA_SCHEMA_MANAGEMENT_LIST_FAILURE,
                payload: error,
            });
        });
};

export const postMetadataSchema = (metadataSchema, callback) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: METADATA_SCHEMA_MANAGEMENT_LIST_PENDING });

    return postMetadataSchemaAPI(stage.id, stage.endpoint, metadataSchema)
        .then((response) => {
            dispatch({
                type: METADATA_SCHEMA_MANAGEMENT_CTEATE_METADATA_SCHEMA_SUCCESS,
                payload: response,
            });
            callback(response.data);
        })
        .catch((error) => {
            dispatch({
                type: METADATA_SCHEMA_MANAGEMENT_CTEATE_METADATA_SCHEMA_FAILURE,
                payload: error,
            });
        });
};

export const registerMetadataSchema = (projectId, domain, metadataSchema) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: METADATA_SCHEMA_MANAGEMENT_LIST_PENDING });

    return registerMetadataSchemaAPI(stage.id, projectId, stage.endpoint, domain, metadataSchema)
        .then((response) => {
            dispatch({
                type: METADATA_SCHEMA_MANAGEMENT_REGISTER_METADATA_SCHEMA_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: METADATA_SCHEMA_MANAGEMENT_LIST_FAILURE,
                payload: error,
            });
        });
};

export const patchMetadataSchema = (id, data) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: METADATA_SCHEMA_MANAGEMENT_LIST_PENDING });

    patchMetadataSchemaAPI(stage.id, stage.endpoint, id, data)
        .then((response) => {
            dispatch({
                type: METADATA_SCHEMA_MANAGEMENT_DETAIL_PATCH_METADATA_SCHEMA_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: METADATA_SCHEMA_MANAGEMENT_LIST_FAILURE,
                payload: error,
            });
        });
};

export const deleteMetadataSchema = (id) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: METADATA_SCHEMA_MANAGEMENT_LIST_PENDING });

    return deleteMetadataSchemaAPI(stage.id, stage.endpoint, id)
        .then(() => {
            dispatch({
                type: METADATA_SCHEMA_MANAGEMENT_LIST_DELETE_METADATA_SCHEMA_SUCCESS,
                payload: id,
            });
        })
        .catch((error) => {
            dispatch({
                type: METADATA_SCHEMA_MANAGEMENT_LIST_DELETE_METADATA_SCHEMA_FAILURE,
            });
            throw error;
        });
};

export const metadataSchemaErrorReset = () => (dispatch, action) =>
    dispatch({ type: METADATA_SCHEMA_MANAGEMENT_RESET_ERROR });
