import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import TypesInput from "@features/types-input/types-input";
import { MEDIA_TYPES } from "@constants";
import { Box, Grid, IconButton, Tooltip } from "@mzc-pdc/ui";
import Loading from "@components/loader/Loading";
import Typography from "@components_v2/typography/typography";
import theme from "@styles/theme";
import { IconAdd, IconCancel, IconRefresh, ImageEmptyItem } from "@mzc-cloudplex/icons";
import AutocompleteTableList from "@features/autocomplete-table/autocomplete-table-list";
import AssetColumns from "./panel-table-columns-assets";
import { clearPropertyPanelLinkedAssets, getLinkedAssets, updateCollectionAction } from "@modules/propertyPanel";
import DialogAddLinkedAssets from "@features/dialog/dialog-add-linked-assets";
import menuService from "@services/menuService";
import { autocompleteTableHoverStyle } from "@features/autocomplete-table/autocomplete-table-list.styled";
import * as propertyPanelActions from "@modules/propertyPanel";

//panel type
// normal: edit 안할 시 일반 Typography + edit시 input
// textarea: edit 안할 시 일반 Typography + edit시 textarea
// date: edit안할 시 일반 Typography와 유저 조회 버튼 + edit 불가
// tags: 태그 전용 컴포넌트
// folder: 폴더 전용 컴포넌트
// listView: edit안할 시 컬렉션 목록 + edit시 collection autocomplete

const PanelAssetsView = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const propertyPanel = useSelector(({ propertyPanel }) => propertyPanel);

    const [pending, setPending] = useState(false);
    const [dialogAddLinkedAssetsActive, setDialogAddLinkedAssetsActive] = useState(false);
    const [actionPending, setActionPending] = useState(false);
    const [filterAssetType, setFilterAssetType] = useState([]);

    const getCollectionLinkedAssets = async (refresh = false) => {
        if (
            (!refresh &&
                propertyPanel.data?.linkedAssets?.results?.length > 0 &&
                !propertyPanel.data?.linkedAssets?.nextToken) ||
            actionPending
        )
            return;
        setPending(true);
        await dispatch(
            getLinkedAssets({ id: propertyPanel.targetId, nextToken: propertyPanel.data?.linkedAssets?.nextToken }),
        );
        setPending(false);
    };

    useEffect(() => {
        if (!propertyPanel.targetId) return;
        getCollectionLinkedAssets();
    }, [propertyPanel.targetId]);

    const onFetchLinkedCollections = () => {
        if (!propertyPanel?.data?.nextToken || propertyPanel.pending) return;
        const params = { nextToken: propertyPanel.data?.nextToken };
        dispatch(getLinkedAssets({ id: propertyPanel.targetId, params }));
    };

    const onHandleClickRefresh = async () => {
        await dispatch(clearPropertyPanelLinkedAssets());
        getCollectionLinkedAssets(true);
    };

    const onHandleClickDeleteLinkedAsset = useCallback(
        async (id) => {
            try {
                if (actionPending) return;
                setActionPending(true);

                await dispatch(
                    updateCollectionAction({
                        contentId: propertyPanel.targetId,
                        params: {
                            action: "REMOVE_ITEMS",
                            data: {
                                assetIds: [id],
                            },
                        },
                    }),
                );

                onHandleClickRefresh(true);
            } catch (error) {
                console.error(error);
            } finally {
                setActionPending(null);
            }
        },
        [propertyPanel],
    );

    const filteredLinkedAssets = useMemo(() => {
        if (!propertyPanel.data?.linkedAssets?.results?.length) return [];

        if (!filterAssetType.length) return propertyPanel.data?.linkedAssets?.results;
        return propertyPanel.data?.linkedAssets?.results?.filter((asset) =>
            filterAssetType.find(({ value }) => value === asset.mediaType),
        );
    }, [propertyPanel.data.linkedAssets, filterAssetType]);

    return (
        <>
            {dialogAddLinkedAssetsActive && (
                <DialogAddLinkedAssets
                    collectionId={propertyPanel.targetId}
                    addedLinkedAssets={propertyPanel.data?.linkedAssets?.results ?? []}
                    shouldUpdatePrimaryAsset={propertyPanel.data.linkedAssets?.results?.length === 0}
                    onClickClose={() => setDialogAddLinkedAssetsActive(false)}
                    actions={propertyPanelActions}
                    afterMutation={() => {
                        onHandleClickRefresh();
                    }}
                />
            )}
            <Box sx={{ padding: "12px 16px" }}>
                <Grid container flexDirection={"column"}>
                    <TypesInput
                        types={filterAssetType}
                        placeholder={t(`common::label::All Media Types`)}
                        options={Object.values(MEDIA_TYPES).map((type) => ({ title: type, id: type }))}
                        onAddType={(ext) => {
                            setFilterAssetType([...filterAssetType, ext]);
                        }}
                        onDeleteType={(index) => {
                            setFilterAssetType(filterAssetType.filter((t, idx) => idx !== index));
                        }}
                    />
                    <Grid container justifyContent={"center"}>
                        <Grid
                            container
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            sx={{ mt: 1 }}
                        >
                            <Grid item>
                                <Typography
                                    variant={"body1_400"}
                                    component={"span"}
                                    color={theme.palette.secondary[800]}
                                >
                                    Total
                                </Typography>
                                <Typography
                                    variant={"body1_400"}
                                    component={"span"}
                                    color={theme.palette.primary[400]}
                                    sx={{ ml: 0.5 }}
                                >
                                    {propertyPanel.data?.assetTotalCount ?? 0}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Tooltip title={t(`common::label::Refresh`)} sx={{ mr: 1.5 }}>
                                    <IconButton circled size={"small"} onClick={onHandleClickRefresh}>
                                        <IconRefresh size={16} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={t(`common::label::Add Assets`)}>
                                    <IconButton
                                        size={"medium"}
                                        variant={"contained"}
                                        color={"primary"}
                                        onClick={() => setDialogAddLinkedAssetsActive(true)}
                                    >
                                        <IconAdd size={16} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        {pending ? (
                            <Loading></Loading>
                        ) : (
                            <Grid item sx={{ width: "100%", mt: 0.5 }}>
                                {filteredLinkedAssets.length > 0 ? (
                                    <AutocompleteTableList
                                        type={"ASSET"}
                                        data={[{ value: filteredLinkedAssets }]}
                                        columns={AssetColumns}
                                        onFetchData={getCollectionLinkedAssets}
                                        menuActions={(item) => (
                                            <Tooltip title={t(`common::label::Delete`)}>
                                                <IconButton
                                                    size="small"
                                                    circled
                                                    onClick={(e) => onHandleClickDeleteLinkedAsset(item.id)}
                                                >
                                                    <IconCancel size={12} htmlColor={theme.palette.grey[700]} />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        listStyle={autocompleteTableHoverStyle()}
                                    />
                                ) : (
                                    <Grid
                                        container
                                        sx={{ width: "100%", mt: 15 }}
                                        flexDirection={"column"}
                                        justifyContent={"center"}
                                    >
                                        <Grid container justifyContent={"center"} mb={2.5}>
                                            <ImageEmptyItem width={76} height={70} />
                                        </Grid>

                                        <Grid container justifyContent={"center"} flexDirection={"column"}>
                                            <Typography
                                                variant={"body1_500"}
                                                component={"span"}
                                                mb={0.5}
                                                color={theme.palette.secondary[800]}
                                                sx={{ textAlign: "center" }}
                                            >
                                                {t(`common::msg::No assets yet.`)}
                                            </Typography>
                                            <Typography
                                                variant={"body1_500"}
                                                component={"span"}
                                                color={theme.palette.secondary[600]}
                                                sx={{ textAlign: "center" }}
                                            >
                                                {t(`common::msg::Add it using the “+” button.`)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default PanelAssetsView;
