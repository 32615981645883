import {
    axios,
    axiosWithoutHeaders
} from "../cores/axiosWrapper";

import Polling from '../cores/polling';

import { 
    getSchedulesAPI,
    startScheduleAPI,
    stopScheduleAPI,
    createScheduleBulkAPI,
    deleteScheduleAPI,
    updateScheduleAPI
} from "./schedules";

import {handleActions, createAction} from 'redux-actions';

const INTERACTIVE_LIVE_CHANNEL_RESET = 'INTERACTIVE_LIVE_CHANNEL_RESET';
const INTERACTIVE_LIVE_CHANNEL_PENDING = 'INTERACTIVE_LIVE_CHANNEL_PENDING';

const INTERACTIVE_LIVE_REFRESH_FLAG_RESET = 'INTERACTIVE_LIVE_REFRESH_FLAG_RESET';

const INTERACTIVE_LIVE_CHANNEL_SCHEDULES_PENDING = 'INTERACTIVE_LIVE_CHANNEL_SCHEDULES_PENDING';

const INTERACTIVE_LIVE_CHANNEL_CREATE_SUCCESS = "INTERACTIVE_LIVE_CHANNEL_CREATE_SUCCESS";
const INTERACTIVE_LIVE_CHANNEL_CREATE_FAILURE = "INTERACTIVE_LIVE_CHANNEL_CREATE_FAILURE";

const INTERACTIVE_LIVE_CHANNEL_UPDATE_SUCCESS = 'INTERACTIVE_LIVE_CHANNEL_UPDATE_SUCCESS';
const INTERACTIVE_LIVE_CHANNEL_UPDATE_FAILURE = 'INTERACTIVE_LIVE_CHANNEL_UPDATE_FAILURE';

const INTERACTIVE_LIVE_CHANNEL_GET_SUCCESS = 'INTERACTIVE_LIVE_CHANNEL_GET_SUCCESS';
const INTERACTIVE_LIVE_CHANNEL_GET_FAILURE = 'INTERACTIVE_LIVE_CHANNEL_GET_FAILURE';

const INTERACTIVE_LIVE_SCHEDULE_PENDING = 'INTERACTIVE_LIVE_SCHEDULE_PENDING';

const INTERACTIVE_LIVE_STREAM_GET_SUCCESS = 'INTERACTIVE_LIVE_STREAM_GET_SUCCESS';
const INTERACTIVE_LIVE_STREAM_GET_FAILURE = 'INTERACTIVE_LIVE_STREAM_GET_FAILURE';

const INTERACTIVE_LIVE_STREAM_STATUS_POLLING_SUCCESS = 'INTERACTIVE_LIVE_STREAM_STATUS_POLLING_SUCCESS';
const INTERACTIVE_LIVE_STREAM_STATUS_START_POLLING = 'INTERACTIVE_LIVE_STREAM_STATUS_START_POLLING';
const INTERACTIVE_LIVE_STREAM_STATUS_STOP_POLLING = 'INTERACTIVE_LIVE_STREAM_STATUS_STOP_POLLING';

const INTERACTIVE_LIVE_SCHEDULES_GET_SUCCESS = 'INTERACTIVE_LIVE_SCHEDULES_GET_SUCCESS';
const INTERACTIVE_LIVE_SCHEDULES_GET_FAILURE = 'INTERACTIVE_LIVE_SCHEDULES_GET_FAILURE';

const INTERACTIVE_LIVE_SCHEDULES_STATUS_UPDATE = 'INTERACTIVE_LIVE_SCHEDULES_STATUS_UPDATE';
const INTERACTIVE_LIVE_SCHEDULES_START_STOP_FAILURE = 'INTERACTIVE_LIVE_SCHEDULES_START_STOP_FAILURE';

const INTERACTIVE_LIVE_SCHEDULES_CREATE_SUCCESS = 'INTERACTIVE_LIVE_SCHEDULES_CREATE_SUCCESS';
const INTERACTIVE_LIVE_SCHEDULES_CREATE_FAILURE = 'INTERACTIVE_LIVE_SCHEDULES_CREATE_FAILURE';

const INTERACTIVE_LIVE_SCHEDULES_DELETE_SUCCESS = 'INTERACTIVE_LIVE_SCHEDULES_DELETE_SUCCESS';
const INTERACTIVE_LIVE_SCHEDULES_DELETE_FAILURE = 'INTERACTIVE_LIVE_SCHEDULES_DELETE_FAILURE';

const INTERACTIVE_LIVE_SCHEDULES_UPDATE_SUCCESS = 'INTERACTIVE_LIVE_SCHEDULES_UPDATE_SUCCESS';
const INTERACTIVE_LIVE_SCHEDULES_UPDATE_FAILURE = 'INTERACTIVE_LIVE_SCHEDULES_UPDATE_FAILURE';

//reducer
const initialState = {
    pending: false,
    error: false,
    error404: false,
    schedulesRefresh: false,
    isCreateSuccess: false,
    hasReset: false,
    data: {
        channel: undefined
    },
    schedules: {
        data : undefined,
        pending: false,
        error: false
    }
};

export const refreshFlagReset = createAction(INTERACTIVE_LIVE_REFRESH_FLAG_RESET);
export const stopStatusPolling = createAction(INTERACTIVE_LIVE_STREAM_STATUS_STOP_POLLING);

export default handleActions({
    [INTERACTIVE_LIVE_CHANNEL_RESET]: (state, action) => {
        console.log("INTERACTIVE_LIVE_CHANNEL_RESET");
        return {
            ...initialState
        }
    },

    [INTERACTIVE_LIVE_CHANNEL_PENDING]: (state, action) => {
        return {
            ...state,
            pending : true,
            error : false
        }
    },

    [INTERACTIVE_LIVE_CHANNEL_SCHEDULES_PENDING]: (state, action) => {
        return {
            ...state,
            schedules: {
                ...state.schedules,
                pending:true
            }
        }
    },

    [INTERACTIVE_LIVE_CHANNEL_CREATE_SUCCESS]: (state, action) => {
        console.log("INTERACTIVE_LIVE_CHANNEL_CREATE_SUCCESS action: ", action);

        const data = action.payload;

        return {
            ...state,
            data : {
                channel: data
            },
            pending : false,
            error : false,
            isCreateSuccess: true
        }
    },

    [INTERACTIVE_LIVE_CHANNEL_UPDATE_SUCCESS]: (state, action) => {
        console.log("INTERACTIVE_LIVE_CHANNEL_UPDATE_SUCCESS data : ", action.payload);
        
        const data = action.payload;
        return {
            ...state,
            data : {
                channel: data
            },
            pending : false,
            error: false
        }
    },

    [INTERACTIVE_LIVE_CHANNEL_UPDATE_FAILURE]: (state, action) => {
        console.log("INTERACTIVE_LIVE_CHANNEL_UPDATE_FAILURE data : ", action.payload);
 
        return {
            ...state,
            pending : false,
            error: true
        }
    },

    [INTERACTIVE_LIVE_CHANNEL_GET_SUCCESS]: (state, action) => {
        console.log("INTERACTIVE_LIVE_CHANNEL_GET_SUCCESS data : ", action.payload);
        const data = action.payload;
        return {
            ...state,
            data : {
                ...state.data,
                channel: data
            },
            pending : false,
            error: false
        }
    },

    [INTERACTIVE_LIVE_CHANNEL_GET_FAILURE]: (state, action) => {
        console.log("INTERACTIVE_LIVE_CHANNEL_GET_FAILURE action:", action);
        const { response } = action.payload;

        console.log("INTERACTIVE_LIVE_CHANNEL_GET_FAILURE response:", response);

        let error404 = false;
        if (response.status === 404)
            error404 = true;

        return {
            ...state,
            pending : false,
            error : true,
            error404
        }
    },

    [INTERACTIVE_LIVE_STREAM_GET_SUCCESS]: (state, action) => {
        console.log("INTERACTIVE_LIVE_STREAM_GET_SUCCESS data : ", action.payload);
        
        const data = action.payload;

        state.schedules.data.map(s => {
            console.log("INTERACTIVE_LIVE_STREAM_GET_SUCCESS for  s: ", s);
            if (s.id === data.scheduleId) {
                s.stream = data;
                s.pending = false;
                return s;
            }

            return s;
        });

        console.log("INTERACTIVE_LIVE_STREAM_GET_SUCCESS state.schedules : ", state.schedules);

        return {
            ...state,
            pending : false,
            error: false
        }
    },

    [INTERACTIVE_LIVE_STREAM_STATUS_POLLING_SUCCESS]: (state, action) => {
        console.log("INTERACTIVE_LIVE_STREAM_STATUS_LIVE data : ", action.payload);
        
        const stream = action.payload;

        console.log("INTERACTIVE_LIVE_STREAM_STATUS_LIVE stream : ", stream);

        state.schedules.data.map(s => {
            if (s.stream && s.stream.id === stream.id) {
                console.log("INTERACTIVE_LIVE_STREAM_STATUS_LIVE for before s: ", s);
                s.stream.live.state = stream.state;
                console.log("INTERACTIVE_LIVE_STREAM_STATUS_LIVE for  s: ", s);
                return s;
            }

            return s;
        });

        console.log("INTERACTIVE_LIVE_STREAM_STATUS_LIVE state.schedules : ", state.schedules);

        return {
            ...state,
            pending : false,
            error: false
        }
    },

    [INTERACTIVE_LIVE_STREAM_STATUS_STOP_POLLING]: (state, action) => {
        console.log("INTERACTIVE_LIVE_STREAM_STATUS_POLLING_STOP data : ", action.payload);
        
        const scheduleId = action.payload;

        // console.log("INTERACTIVE_LIVE_STREAM_STATUS_LIVE stream : ", stream);


        scheduleId && state.schedules.data && state.schedules.data.map(s => {
            console.log("INTERACTIVE_LIVE_STREAM_STATUS_LIVE s : ", s);
            if (s.stream && (s.id === scheduleId)) {
                console.log("INTERACTIVE_LIVE_STREAM_STATUS_LIVE s.stream : ", s.stream);
                // s.stream.isStopPolling = true;
                Object.assign(s.stream, {isStopPolling: true});
                return s;
            }

            return s;
        });

        console.log("INTERACTIVE_LIVE_STREAM_STATUS_LIVE state.schedules : ", state.schedules);

        return {
            ...state,
            pending : false,
            error: false
        }
    },


    [INTERACTIVE_LIVE_STREAM_GET_FAILURE]: (state, action) => {
        console.log("INTERACTIVE_LIVE_STREAM_GET_FAILURE data : ", action.payload);

        const data = action.payload;

        state.schedules.data && state.schedules.data.map(s => {
            console.log("INTERACTIVE_LIVE_STREAM_GET_FAILURE for  s: ", s);
            if (s.id === data.id) {
                s.pending = false;
                delete s.stream;
                return s;
            }

            return s;
        });
        
        return {
            ...state,
            pending : false,
            error: false
        }
    },

    [INTERACTIVE_LIVE_SCHEDULE_PENDING]: (state, action) => {
        console.log("INTERACTIVE_LIVE_SCHEDULE_PENDING data : ", action.payload);
        
        const data = action.payload;

        state.schedules.data.map(s => {
            console.log("INTERACTIVE_LIVE_STREAM_GET_SUCCESS for  s: ", s);
            if (s.id === data.scheduleId) 
                return s.pending = true;

            return s;
        });

        console.log("INTERACTIVE_LIVE_STREAM_GET_SUCCESS state.schedules : ", state.schedules);

        return {
            ...state,
            pending : false,
            error: false
        }
    },

    [INTERACTIVE_LIVE_SCHEDULES_GET_SUCCESS]: (state, action) => {
        console.log("INTERACTIVE_LIVE_SCHEDULES_GET_SUCCESS action.payload : ", action.payload);
        const data = action.payload;
        console.log("INTERACTIVE_LIVE_SCHEDULES_GET_SUCCESS data : ", data);

        return {
            ...state,
            schedules: {
                data: data.schedules,
                totalCount: data.totalCount,
                pending: false,
                error: false
            },
            pending : false,
            error: false
        }
    },

    [INTERACTIVE_LIVE_SCHEDULES_START_STOP_FAILURE]: (state, action) => {
        console.log("INTERACTIVE_LIVE_SCHEDULES_START_FAILURE action.payload : ", action.payload);
        return {
            ...state
        }
    },

    [INTERACTIVE_LIVE_SCHEDULES_STATUS_UPDATE]: (state, action) => {
        console.log("INTERACTIVE_LIVE_SCHEDULES_STATUS_UPDATE action.payload : ", action.payload);
        const data = action.payload;

        state.schedules.data.map(s => {
            if(s.id === data.id)
                s.status = data.status;

            return s;
        });

        return {
            ...state
        }
    },

    [INTERACTIVE_LIVE_SCHEDULES_CREATE_SUCCESS]: (state, action) => {
        console.log("INTERACTIVE_LIVE_SCHEDULES_CREATE_SUCCESS action.payload : ", action.payload);
        const data = action.payload;
        
        return {
            ...state,
            schedulesRefresh:true
        }
    },

    [INTERACTIVE_LIVE_SCHEDULES_UPDATE_SUCCESS]: (state, action) => {
        console.log("INTERACTIVE_LIVE_SCHEDULES_UPDATE_SUCCESS action.payload : ", action.payload);
        const data = action.payload;

        state.schedules.data.map(s => {
            if(s.id === data.id) {
                console.log("INTERACTIVE_LIVE_SCHEDULES_UPDATE_SUCCESS eqaul id ");
                s.advancedTime = data.advancedTime;
                s.startAt = data.startAt;
                s.endAt = data.endAt;
                s.status = data.status;
            }

            return s;
        });

        console.log("INTERACTIVE_LIVE_SCHEDULES_UPDATE_SUCCESS state.schedules : ", state.schedules);

        return {
            ...state,
            pending: false,
            error: false
        }
    },

    [INTERACTIVE_LIVE_SCHEDULES_UPDATE_FAILURE]: (state, action) => {
        console.log("INTERACTIVE_LIVE_SCHEDULES_UPDATE_FAILURE action.payload : ", action.payload);
        const data = action.payload;

        return {
            ...state,
            pending : false,
            error: false
        }
    },

    [INTERACTIVE_LIVE_SCHEDULES_DELETE_SUCCESS]: (state, action) => {
        console.log("INTERACTIVE_LIVE_SCHEDULES_DELETE_SUCCESS action.payload : ", action.payload);
        const data = action.payload;

        return {
            ...state,
            schedulesRefresh:true
        }
    },

    [INTERACTIVE_LIVE_REFRESH_FLAG_RESET]: (state, action) => {
        console.log("INTERACTIVE_LIVE_REFRESH_FLAG_RESET action.payload : ", action.payload);
        
        return {
            ...state,
            schedulesRefresh:false
        }
    },
}, initialState);

function createInteractiveLiveChannelAPI(stageId, apiEndpoint, projectId, data) {    
    console.log("createInteractiveLiveChannelAPI start");

    return axios.post(`${apiEndpoint}/interactive-live`, data, {
        headers: {
            stageId,
            projectId
        }
    });
}

export function updateInteractiveLiveChannelAPI(stageId, apiEndpoint, projectId, id, data) {     
    return axios.patch(`${apiEndpoint}/interactive-live/${id}`, data, {
        headers: {
            stageId,
            projectId
        }
    });
};


export function getInteractiveLiveChannelAPI(stageId, apiEndpoint, projectId, id) {     
    return axios.get(`${apiEndpoint}/interactive-live/${id}`, {
        headers: {
            stageId,
            projectId
        }
    });
};

export function getInteractiveLiveStreamsAPI(stageId, apiEndpoint, projectId, channelId, queries) {     
    console.log("getInteractiveLiveStreamsAPI ", queries);
    return axios.get(`${apiEndpoint}/interactive-live/${channelId}/streams`, {
        params: queries,
        headers: {
            stageId,
            projectId
        }
    });
};

export function getInteractiveLiveStreamAPI(stageId, apiEndpoint, projectId, channelId, streamId) {     
    return axios.get(`${apiEndpoint}/interactive-live/${channelId}/streams/${streamId}`, {
        headers: {
            stageId,
            projectId
        }
    });
};

export function getInteractiveLiveStreamStatusAPI(stageId, apiEndpoint, projectId, channelId, streamId) {    
    console.log("in getInteractiveLiveStreamStatusAPI"); 
    return axios.get(`${apiEndpoint}/interactive-live/${channelId}/streams/${streamId}`, {
        params: {
            children:'status'
        },
        headers: {
            stageId,
            projectId
        }
    });
};


export const reset = createAction(INTERACTIVE_LIVE_CHANNEL_RESET);

export const createInteractiveLiveChannel = (data) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    dispatch({type: INTERACTIVE_LIVE_CHANNEL_PENDING});

    return createInteractiveLiveChannelAPI(stage.id, stage.endpoint, project.id, data)
        .then((response) => {
            dispatch({
                type: INTERACTIVE_LIVE_CHANNEL_CREATE_SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            dispatch({
                type: INTERACTIVE_LIVE_CHANNEL_CREATE_FAILURE,
                payload: error
            });
        });

}

export const updateInteractiveLiveChannel = (id, data) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    console.log("updateInteractiveLiveChannel data: ", data);

    dispatch({type: INTERACTIVE_LIVE_CHANNEL_PENDING});
 
    return updateInteractiveLiveChannelAPI(stage.id, stage.endpoint, project.id, id, data)
        .then((response) => {
            console.log("updateInteractiveLiveChannel response: ", response);
            dispatch({                
                type: INTERACTIVE_LIVE_CHANNEL_UPDATE_SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            dispatch({
                type: INTERACTIVE_LIVE_CHANNEL_UPDATE_FAILURE,
                payload: error
            });
        });
}

export const getInteractiveLiveChannel = (id) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();
    console.log("getInteractiveLiveChannel id: ", id);

    dispatch({type: INTERACTIVE_LIVE_CHANNEL_PENDING});

    return getInteractiveLiveChannelAPI(stage.id, stage.endpoint, project.id, id)
        .then((response) => {
            console.log("getInteractiveLiveChannels response: ", response);
            dispatch({                
                type: INTERACTIVE_LIVE_CHANNEL_GET_SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            dispatch({
                type: INTERACTIVE_LIVE_CHANNEL_GET_FAILURE,
                payload: error
            });
        });
};



export const getInteractiveLiveStreams = (channelId) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();
    console.log("getInteractiveLiveStreams channelId: ", channelId);

    dispatch({type: INTERACTIVE_LIVE_CHANNEL_PENDING});

    return getInteractiveLiveStreamsAPI(stage.id, stage.endpoint, project.id, channelId)
        .then((response) => {
            console.log("getInteractiveLiveStreams response: ", response);
            dispatch({                
                type: INTERACTIVE_LIVE_CHANNEL_GET_SUCCESS,
                payload: response.data
            });

        })
        .catch(error => {
            dispatch({
                type: INTERACTIVE_LIVE_CHANNEL_GET_FAILURE,
                payload: error
            });
        });
};


export const getInteractiveLiveStream = (channelId, streamId) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    console.log(`getInteractiveLiveStream channelId:${channelId}, streamId:${streamId}`);

    dispatch({type: INTERACTIVE_LIVE_CHANNEL_PENDING});

    return getInteractiveLiveStreamAPI(stage.id, stage.endpoint, project.id, channelId, streamId)
        .then((response) => {
            console.log("getInteractiveLiveStream response: ", response);
            dispatch({                
                type: INTERACTIVE_LIVE_CHANNEL_GET_SUCCESS,
                payload: response.data
            });

        })
        .catch(error => {
            dispatch({
                type: INTERACTIVE_LIVE_CHANNEL_GET_FAILURE,
                payload: error
            });
        });
};


export const getInteractiveLiveStreamByScheduleId = (channelId, scheduleId, isStatusPoliing = false) => async (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    dispatch({
        type: INTERACTIVE_LIVE_SCHEDULE_PENDING,
        payload : {
            scheduleId
        }
    });

    const queries = {
        scheduleId
    }
    const response = await getInteractiveLiveStreamsAPI(stage.id, stage.endpoint, project.id, channelId, queries);

    console.log("getInteractiveLiveStreamByScheduleId response : ", response);

    const streamId = response.data.length > 0 ? response.data[0].id : undefined;

    console.log("getInteractiveLiveStreamByScheduleId streamId: ", streamId);

    if (!streamId) {
        console.log("getInteractiveLiveStreamByScheduleId FAIL: ", streamId);
        dispatch({
            type: INTERACTIVE_LIVE_STREAM_GET_FAILURE,
            payload: {
                id: scheduleId
            }
        });

        return;
    }

    console.log("getInteractiveLiveStreamByScheduleId2 FAIL: ", streamId);

    getInteractiveLiveStreamAPI(stage.id, stage.endpoint, project.id, channelId, streamId)
        .then((response) => {
            console.log("getInteractiveLiveStreamByScheduleId2 response: ", response);
            dispatch({                
                type: INTERACTIVE_LIVE_STREAM_GET_SUCCESS,
                payload: response.data
            });

            if (isStatusPoliing)
                startPolling(channelId, streamId, dispatch, getState);
        })
        .catch(error => {
            dispatch({
                type: INTERACTIVE_LIVE_STREAM_GET_FAILURE,
                payload: error
            });
        });

};

export const getInteractiveLiveSchedules = (queries) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();
    console.log("getInteractiveLiveSchedules queries: ", queries);

    dispatch({type: INTERACTIVE_LIVE_CHANNEL_SCHEDULES_PENDING});

    return getSchedulesAPI(stage.id, stage.endpoint, project.id, queries)
        .then((response) => {
            console.log("getInteractiveLiveSchedules response: ", response);
            dispatch({                
                type: INTERACTIVE_LIVE_SCHEDULES_GET_SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            dispatch({
                type: INTERACTIVE_LIVE_SCHEDULES_GET_FAILURE,
                payload: error
            });
        });
}

export const startInteractiveLiveSchedule = (scheduleId) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();
    console.log("startInteractiveLiveSchedule scheduleId: ", scheduleId, " project:", project);

    dispatch({
        type: INTERACTIVE_LIVE_SCHEDULES_STATUS_UPDATE,
        payload: {
            id: scheduleId,
            status:"STARTING"
        }
    });

    return startScheduleAPI(stage.id, stage.endpoint, project.id, scheduleId)
        .then((response) => {
            console.log("startInteractiveLiveSchedule response: ", response);
            dispatch({                
                type: INTERACTIVE_LIVE_SCHEDULES_STATUS_UPDATE,
                payload: response.data
            });
        })
        .catch(error => {
            dispatch({
                type: INTERACTIVE_LIVE_SCHEDULES_START_STOP_FAILURE,
                payload: error
            });
        });
}

export const stopInteractiveLiveSchedule = (scheduleId) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();
    console.log("stopInteractiveLiveSchedule scheduleId: ", scheduleId);

    dispatch({
        type: INTERACTIVE_LIVE_SCHEDULES_STATUS_UPDATE,
        payload: {
            id: scheduleId,
            status:"STOPPING"
        }
    });

    return stopScheduleAPI(stage.id, stage.endpoint, project.id, scheduleId)
        .then((response) => {
            console.log("stopInteractiveLiveSchedule response: ", response);
            dispatch({                
                type: INTERACTIVE_LIVE_SCHEDULES_STATUS_UPDATE,
                payload: response.data
            });
        })
        .catch(error => {
            dispatch({
                type: INTERACTIVE_LIVE_SCHEDULES_START_STOP_FAILURE,
                payload: error
            });
        });
}

export const createInteractiveLiveScheduleBulk = (schedules) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();
    console.log("createInteractiveLiveScheduleBulk schedules: ", schedules);

    return createScheduleBulkAPI(stage.id, stage.endpoint, project.id, schedules)
        .then((response) => {
            console.log("createInteractiveLiveScheduleBulk response: ", response);

            dispatch({                
                type: INTERACTIVE_LIVE_SCHEDULES_CREATE_SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            dispatch({
                type: INTERACTIVE_LIVE_SCHEDULES_CREATE_FAILURE,
                payload: error
            });
        });
}

export const updateInteractiveLiveSchedule= (id, data) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();
    console.log("updateInteractiveLiveSchedule id: ", id, ", data: ", data);

    dispatch({type: INTERACTIVE_LIVE_CHANNEL_PENDING});
    // dispatch({
    //     type: INTERACTIVE_LIVE_SCHEDULES_STATUS_UPDATE,
    //     payload: {
    //         id: scheduleId,
    //         status:"STOPPING"
    //     }
    // });

    return updateScheduleAPI(stage.id, stage.endpoint, project.id, id, data, data.type)
        .then((response) => {
            console.log("updateInteractiveLiveSchedule response: ", response);

            dispatch({                
                type: INTERACTIVE_LIVE_SCHEDULES_UPDATE_SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            dispatch({
                type: INTERACTIVE_LIVE_SCHEDULES_UPDATE_FAILURE,
                payload: error
            });
        });
}

export const deleteInteractiveLiveSchedule = (id) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();
    console.log("deleteInteractiveLiveSchedule id: ", id);

    // dispatch({
    //     type: INTERACTIVE_LIVE_SCHEDULES_STATUS_UPDATE,
    //     payload: {
    //         id: scheduleId,
    //         status:"STOPPING"
    //     }
    // });

    return deleteScheduleAPI(stage.id, stage.endpoint, project.id, id)
        .then((response) => {
            console.log("deleteInteractiveLiveSchedule response: ", response);

            dispatch({                
                type: INTERACTIVE_LIVE_SCHEDULES_DELETE_SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            dispatch({
                type: INTERACTIVE_LIVE_SCHEDULES_DELETE_FAILURE,
                payload: error
            });
        });
}

function canStartPolling(stream) {
    return stream && stream.state === 'LIVE';
}

function startPolling (channelId, streamId, dispatch, getState) {
    const {
        stage,
        project
    } = getState();
    console.log("in startPolling");

    new Promise((resolve, reject) => {
        Polling (async () => {
            console.log("in startPolling excute");
            return await getInteractiveLiveStreamStatusAPI(stage, stage.endpoint, project.id, channelId, streamId);
        }, (response) => {
            const schedules = getState().interactiveLiveChannel.schedules;
            let stopFlag = false;

            console.log("response.data:", response.data);
            //stop
            stopFlag = schedules.data.some((s) => s.stream && (s.stream.id === streamId) && s.stream.isStopPolling === true);
            if (stopFlag)
                return stopFlag;

            stopFlag = canStartPolling(response.data);

            if (stopFlag) {
                dispatch(
                    {
                        type: INTERACTIVE_LIVE_STREAM_STATUS_POLLING_SUCCESS,
                        payload : response.data
                    }
                )
            };
                
            return stopFlag;
        }, (response) => {
            // console.log("startPolling success response: ", response);
        }).run(); 
    });   
};