import React, { useCallback, useMemo } from "react";
import theme from "@styles/theme";
import EditSubmitButtonGroup from "@components_v2/edit-submit-button-group/edit-submit-button-group";
import { Grid } from "@mzc-pdc/ui";

const FieldEditBox = ({ id, submitDisabled, submitPending, onClickSubmit, onClickClose, children }) => {
    return (
        <>
            <Grid item mt={theme.spacing(1)}>
                {children}
            </Grid>
            <EditSubmitButtonGroup
                submitDisabled={submitDisabled}
                submitPending={submitPending}
                onClickSubmit={onClickSubmit}
                onClickClose={() => onClickClose(id)}
            />
        </>
    );
};

export default FieldEditBox;
