import { useTranslation } from "react-i18next";
import { StyledInfoAlert } from "./alert.styled";
import { Box, Stack, Typography, palette } from "@mzc-pdc/ui";
import { IconInfoCircle } from "@mzc-cloudplex/icons";

export const PhraseTooltipText = {
    title: "Things to keep in mind when entering words",
    data: [
        {
            subTitle: "en-US",
            section: [
                {
                    sectionTitle: "DisplayAs",
                    content:
                        "You can enter English, numbers, spaces, and special characters (~!@#$%^&*()-_=+[{]}|;:’”,<.>/?).",
                },
                { sectionTitle: "SoundsLike", content: "Only English, -(Hyphen) can be entered." },
            ],
        },
        {
            subTitle: "ko-KR",
            section: [
                {
                    sectionTitle: "DisplayAs",
                    content:
                        "You can enter Korean, English, numbers, spaces, and special characters (~!@#$%^&*()-_=+[{]}|;:’”,<.>/?).",
                },
                { sectionTitle: "SoundsLike", content: "Only Korean can be entered." },
            ],
        },
    ],
};

const InfoAlert = ({ content }) => {
    const { t } = useTranslation();

    if (!content) return null;
    return (
        <StyledInfoAlert
            severity={"info"}
            color={"info"}
            variant={"outlined"}
            icon={<IconInfoCircle size={16} htmlColor={palette.info[700]} />}
        >
            <Stack container direction={"column"} spacing={1}>
                {content.title && (
                    <Typography variant={"body1_500"} color={palette.greySecondary[800]}>
                        {content.title}
                    </Typography>
                )}
                <Typography variant={"body1_400"} color={palette.greySecondary[800]} gap={1}>
                    {content.data?.map((item) => (
                        <>
                            {item.subTitle && <Box component={"span"}>{item.subTitle}</Box>}
                            {item.section}
                        </>
                    ))}
                </Typography>
            </Stack>
        </StyledInfoAlert>
    );
};

export default InfoAlert;
