import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const CardFooter = (props) => {
    const { className, ...otherProps } = props;
    return <div {...otherProps} className={clsx("card-footer", className)} />;
};
CardFooter.propTypes = {
    className: PropTypes.string,
};

export default CardFooter;
