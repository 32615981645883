import { DRIVE_FOLDER_SEARCH_TYPE } from "@constants";
import { fromPairs, reduce } from "lodash";
import { handleActions, createAction } from "redux-actions";
import axios from "axios";
import {
    createDriveFolderAPI,
    getDrivesListAPI,
    getFoldersListAPI,
    getDetailDriveFolderAPI,
    setFolderInfoAPI,
    deleteFolderAPI,
    moveFolderAPI,
} from "./apis/virtureFolder";
import compareVersions, { VERSION_CM_11164_COLLECTION_FOLDER_VIEW } from "@cores/version";

//action type
const GET_FOLDERS_LIST_PENDING = "GET_FOLDERS_LIST_PENDING";
const GET_FOLDERS_LIST_PARTIAL_PENDING = "GET_FOLDERS_LIST_PARTIAL_PENDING";
const GET_FOLDERS_LIST_PARTIAL_SUCCESS = "GET_FOLDERS_LIST_PARTIAL_SUCCESS";
const GET_FOLDERS_LIST_SUCCESS = "GET_FOLDERS_LIST_SUCCESS";
const GET_FOLDERS_LIST_FAILURE = "GET_FOLDERS_LIST_FAILURE";
const GET_FOLDER_TREE_VIEW_PENDING = "GET_FOLDER_TREE_VIEW_PENDING";
const GET_FOLDER_TREE_VIEW_PARTIAL_PENDING = "GET_FOLDER_TREE_VIEW_PARTIAL_PENDING";
const GET_FOLDER_TREE_VIEW_PARTIAL_SUCCESS = "GET_FOLDER_TREE_VIEW_PARTIAL_SUCCESS";
const GET_FOLDER_TREE_VIEW_SUCCESS = "GET_FOLDER_TREE_VIEW_SUCCESS";
const GET_FOLDER_TREE_VIEW_FAILURE = "GET_FOLDER_TREE_VIEW_FAILURE";
const GET_DETAIL_FOLDER_PENDING = "GET_DETAIL_FOLDER_PENDING";
const GET_DETAIL_FOLDER_SUCCESS = "GET_DETAIL_FOLDER_SUCCESS";
const GET_DETAIL_FOLDER_FAILURE = "GET_DETAIL_FOLDER_FAILURE";
const GET_DRIVE_LIST_PENDING = "GET_DRIVE_LIST_PENDING";
const GET_DRIVE_LIST_SUCCESS = "GET_DRIVE_LIST_SUCCESS";
const GET_DRIVE_LIST_FAILURE = "GET_DRIVE_LIST_FAILURE";
const CREATE_FOLDER_PENDING = "CREATE_FOLDER_PENDING";
const CREATE_FOLDER_SUCCESS = "CREATE_FOLDER_SUCCESS";
const CREATE_FOLDER_FAILURE = "CREATE_FOLDER_FAILURE";
const FOLDERS_SEARCH_STATE = "FOLDERS_SEARCH_STATE";
const FOLDERS_UPDATE_STATE = "FOLDERS_UPDATE_STATE";
const UPDATE_SELECTED_FOLDER = "UPDATE_SELECTED_FOLDER";
const UPDATE_SELECTED_HISTORY = "UPDATE_SELECTED_HISTORY";
const UPDATE_FORDER_INFO_SUCCESS = "UPDATE_FORDER_INFO_SUCCESS";
const UPDATE_FORDER_INFO_PENDING = "UPDATE_FORDER_INFO_PENDING";
const UPDATE_FORDER_INFO_FAILURE = "UPDATE_FORDER_INFO_FAILURE";
const RESET_SELECTED_FOLDER = "RESET_SELECTED_FOLDER";
const DELETE_FOLDER_SUCCESS = "DELETE_FOLDER_SUCCESS";
const DELETE_FOLDER_PENDING = "DELETE_FOLDER_PENDING";
const DELETE_FOLDER_FAILURE = "DELETE_FOLDER_FAILURE";
const MOVE_FOLDER_PENDING = "MOVE_FOLDER_PENDING";
const MOVE_FOLDER_SUCCESS = "MOVE_FOLDER_SUCCESS";
const MOVE_FOLDER_FAILURE = "MOVE_FOLDER_FAILURE";
const UPDATE_MOVED_FOLDER = "UPDATE_MOVED_FOLDER";
const RESET_FOLDER_TREE_VIEW = "RESET_FOLDER_TREE_VIEW";

//reducer
const initialState = {
    pending: false,
    error: false,
    folderList: {
        childrenPending: false,
        loadingPosition: null,
        pending: false,
        error: null,
        data: null,
        selectedFolder: { folder: null, history: [], index: -1 },
    },
    driveList: { pending: false, data: null, error: null },
    createFolder: {
        pending: false,
        error: null,
    },
    detailFolder: {
        pending: false,
        error: null,
    },
    moveFolder: {
        folderId: null,
        pending: false,
        error: null,
    },
    isSearch: false,
};

export default handleActions(
    {
        [RESET_FOLDER_TREE_VIEW]: (state) => {
            return { ...initialState };
        },
        [GET_FOLDER_TREE_VIEW_PENDING]: (state) => {
            return {
                ...state,
                folderList: {
                    ...state.folderList,
                    pending: true,
                    error: null,
                },
                driveList: { ...state.driveList, pending: true },
            };
        },
        [GET_FOLDER_TREE_VIEW_PARTIAL_PENDING]: (state, action) => {
            return {
                ...state,
                folderList: { ...state.folderList, childrenPending: true },
            };
        },
        [GET_FOLDER_TREE_VIEW_PARTIAL_SUCCESS]: (state, action) => {
            return {
                ...state,
                folderList: { ...state.folderList, childrenPending: false, data: action.payload },
            };
        },
        [GET_FOLDER_TREE_VIEW_SUCCESS]: (state, action) => {
            return {
                ...state,
                folderList: { ...state.folderList, pending: false }, //partial에서 이미 pending false처리 됨
            };
        },
        [GET_FOLDER_TREE_VIEW_FAILURE]: (state, action) => {
            return {
                ...state,
                folderList: { ...state.folderList, pending: false, error: action.payload },
            };
        },
        [GET_DRIVE_LIST_PENDING]: (state, action) => {
            return {
                ...state,
                driveList: { ...initialState.driveList },
            };
        },
        [GET_DRIVE_LIST_SUCCESS]: (state, action) => {
            return {
                ...state,
                driveList: { ...state.driveList, data: action.payload, pending: false },
            };
        },
        [GET_DRIVE_LIST_FAILURE]: (state, action) => {
            return {
                ...state,
                driveList: { ...state.driveList, error: action.payload, pending: false },
            };
        },
        [GET_FOLDERS_LIST_PENDING]: (state) => {
            return {
                ...state,
                folderList: { ...state.folderList, error: null },
            };
        },
        [GET_FOLDERS_LIST_PARTIAL_PENDING]: (state, action) => {
            const { parentId, nextToken } = action.payload;
            return {
                ...state,
                folderList: {
                    ...state.folderList,
                    childrenPending: true,
                    data: {
                        ...state.folderList.data,
                        [parentId]: {
                            ...state.folderList.data[parentId],
                            children: nextToken ? [...state.folderList.data[parentId].children] : [],
                            pending: true,
                            isFold: false,
                        },
                    },
                },
            };
        },
        [GET_FOLDERS_LIST_PARTIAL_SUCCESS]: (state, action) => {
            return {
                ...state,
                folderList: { ...state.folderList, childrenPending: false, data: action.payload },
            };
        },
        [GET_FOLDERS_LIST_SUCCESS]: (state, action) => {
            return {
                ...state,
                folderList: { ...state.folderList, pending: false },
            };
        },
        [GET_FOLDERS_LIST_FAILURE]: (state, action) => {
            return {
                ...state,
                folderList: { ...state.folderList, pending: false, error: action.payload },
            };
        },
        [FOLDERS_UPDATE_STATE]: (state, action) => {
            return {
                ...state,
                folderList: {
                    ...state.folderList,
                    data: { ...state.folderList.data, ...action.payload },
                },
            };
        },
        [UPDATE_SELECTED_FOLDER]: (state, action) => {
            const selectedFolder = state.folderList.selectedFolder;
            let newSelectedHistory = [...selectedFolder.history];
            let newSelectedIndex = selectedFolder.index;

            if (selectedFolder.index < selectedFolder.history.length) {
                //이전 히스토리 도중 새로운 폴더 탐색
                newSelectedHistory = selectedFolder.history.slice(0, newSelectedIndex + 1);
            }
            newSelectedHistory.push(action.payload);
            newSelectedIndex = newSelectedIndex + 1;

            return {
                ...state,
                folderList: {
                    ...state.folderList,
                    selectedFolder: {
                        folder: action.payload,
                        history: newSelectedHistory,
                        index: newSelectedIndex,
                    },
                },
            };
        },
        [RESET_SELECTED_FOLDER]: (state, action) => {
            return {
                ...state,
                folderList: { ...state.folderList, selectedFolder: { folder: null, history: [], index: -1 } },
            };
        },
        [UPDATE_SELECTED_HISTORY]: (state, action) => {
            return {
                ...state,
                folderList: {
                    ...state.folderList,
                    selectedFolder: {
                        ...state.folderList.selectedFolder,
                        folder: state.folderList.selectedFolder.history[
                            state.folderList.selectedFolder.index + action.payload
                        ],
                        index: state.folderList.selectedFolder.index + action.payload,
                    },
                },
            };
        },
        [GET_DETAIL_FOLDER_PENDING]: (state, action) => {
            return {
                ...state,
                detailFolder: {
                    pending: true,
                    error: null,
                },
            };
        },
        [GET_DETAIL_FOLDER_SUCCESS]: (state, action) => {
            return {
                ...state,
                detailFolder: {
                    pending: false,
                    error: null,
                },
            };
        },
        [GET_DETAIL_FOLDER_FAILURE]: (state, action) => {
            return {
                ...state,
                detailFolder: {
                    pending: false,
                    error: action.payload,
                },
            };
        },
        [FOLDERS_SEARCH_STATE]: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
        [UPDATE_FORDER_INFO_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
            };
        },
        [UPDATE_FORDER_INFO_PENDING]: (state, action) => {
            return {
                ...state,
                pending: true,
            };
        },
        [UPDATE_FORDER_INFO_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: action.payload,
            };
        },
        [DELETE_FOLDER_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
            };
        },
        [DELETE_FOLDER_PENDING]: (state, action) => {
            return {
                ...state,
                pending: true,
            };
        },
        [DELETE_FOLDER_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: action.payload,
            };
        },
        [MOVE_FOLDER_SUCCESS]: (state, action) => {
            return {
                ...state,
                moveFolder: {
                    folderId: null,
                    pending: false,
                },
            };
        },
        [MOVE_FOLDER_PENDING]: (state, action) => {
            return {
                ...state,
                moveFolder: {
                    folderId: action.payload,
                    pending: true,
                },
            };
        },
        [MOVE_FOLDER_FAILURE]: (state, action) => {
            return {
                ...state,
                moveFolder: {
                    error: action.payload,
                    pending: false,
                },
                pending: false,
            };
        },
        [UPDATE_MOVED_FOLDER]: (state, action) => {
            return {
                ...state,
                folderList: { ...state.folderList, data: action.payload },
            };
        },
    },
    initialState,
);

export const updateFolderState = createAction(FOLDERS_UPDATE_STATE);
export const updateFolderSearchState = createAction(FOLDERS_SEARCH_STATE);
export const updateSelectedFolder = createAction(UPDATE_SELECTED_FOLDER);
export const updateSelectedHistory = createAction(UPDATE_SELECTED_HISTORY);
export const resetSelectedFolder = createAction(RESET_SELECTED_FOLDER);
export const pendingFolder = createAction(GET_FOLDERS_LIST_PARTIAL_PENDING);
export const resetFolderTreeView = createAction(RESET_FOLDER_TREE_VIEW);

// prevFolderMap : nextToken이 존재할 경우 폴더를 나눠서 처리할 때 이전에 이미 처리한 폴더맵
export const createFolderTree = (folderList, searchType, searchFolder, prevFolderMap) => {
    try {
        let folderMap = prevFolderMap ?? {};

        folderList.folders.map((folder, index) => {
            const parentId = folder.parent.id;
            const folderInfo = {
                isRoot: false,
                children: [],
                name: folder.name,
                type: "folder",
                isFold: true, //서치 조건
                id: folder.id,
                driveId: folderList.driveId,
                depth: folderMap?.[parentId] ? folderMap[parentId]?.depth + 1 : 0,
                parent: parentId ? parentId : folderList.rootFolder?.id,
                pending: false,
            };
            folderMap[folder.id] = folderInfo;
            if (parentId && folderMap[parentId]) {
                folderMap[parentId].children = Array.from(new Set(folderMap[parentId]?.children.concat([folder.id])));
            }
        });

        if (searchType === "search") {
            const foundFolderList = Object.values(folderMap).filter(
                (obj) => obj.isFold && (obj.name.includes(searchFolder) || obj.id === searchFolder),
            );

            foundFolderList.forEach((foundFolder) => {
                if (foundFolder?.depth > 0) {
                    let parents = [foundFolder.parent]; //루트폴더

                    folderMap[foundFolder.id].isFold = false;

                    while (parents.length > 0) {
                        const id = parents.shift();

                        folderMap[id].isFold = false;
                        if (folderMap[id].depth > 0) parents.push(folderMap[id].parent);
                    }
                }
            });
        }

        return folderMap;
    } catch (error) {
        console.log(error);
    }
};

export const getDriveData =
    ({ headerParams, domain }) =>
    async (dispatch, getState) => {
        const { stage } = getState();

        dispatch({ type: GET_DRIVE_LIST_PENDING });

        return new Promise((resolve, reject) => {
            getDrivesListAPI(stage.endpoint, { headerParams })
                .then(async (driveResponse) => {
                    dispatch({ type: GET_DRIVE_LIST_SUCCESS, payload: driveResponse.data.results });

                    let filteredDriveByDomain = driveResponse.data.results;
                    if (compareVersions(stage.version, VERSION_CM_11164_COLLECTION_FOLDER_VIEW) >= 0) {
                        filteredDriveByDomain = driveResponse.data.results?.filter(
                            (drive) => drive.resourceType.type === domain.toUpperCase(),
                        );
                    }

                    resolve(filteredDriveByDomain);
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });
    };

export const initFolderData =
    ({ headerParams, domain }) =>
    (dispatch, getState) => {
        const { stage, project } = getState();

        dispatch({ type: GET_FOLDER_TREE_VIEW_PENDING });

        return new Promise((resolve, reject) => {
            dispatch(getDriveData({ headerParams, domain }))
                .then(async (filteredDrive) => {
                    //드라이브 별로 폴더 목록 가져오기
                    const entireFolderMap = await filteredDrive?.reduce(async (prevFolderMap, drive) => {
                        try {
                            const { data: rootFolderData } = await getDetailDriveFolderAPI(stage.endpoint, {
                                driveId: drive.id,
                                folderId: drive.rootFolder.id,
                                projectId: project.id,
                            });

                            let folderMap = {
                                //이전까지의 folderMap에 현재 드라이브 정보 추가
                                ...prevFolderMap,
                                [rootFolderData.id]: {
                                    isRoot: true,
                                    children: [],
                                    name: rootFolderData.name,
                                    type: "drive",
                                    isFold: false,
                                    depth: 0,
                                    id: rootFolderData.id,
                                    driveId: drive.id,
                                    parent: null,
                                    pending: false,
                                },
                            };

                            const driveId = drive.id;
                            const queryParameters = {
                                parentId: drive.rootFolder.id,
                                searchType: "DIRECT_CHILDREN",
                            };
                            let driveInfo = {
                                rootFolder: drive.rootFolder,
                                driveId: driveId,
                                folders: [],
                            };
                            let nextToken;

                            dispatch({ type: GET_FOLDERS_LIST_PENDING });
                            do {
                                const folderList = await getFoldersListAPI(stage.endpoint, {
                                    driveId,
                                    queryParameters,
                                    headerParams,
                                    nextToken: nextToken,
                                });
                                driveInfo.folders = [...driveInfo.folders, ...folderList.data.results];
                                nextToken = folderList.data.nextToken;

                                folderMap = createFolderTree(driveInfo, "init", null, folderMap);
                                dispatch({ type: GET_FOLDER_TREE_VIEW_PARTIAL_SUCCESS, payload: folderMap });
                            } while (nextToken !== undefined);
                            dispatch({ type: GET_FOLDERS_LIST_SUCCESS });

                            return folderMap;
                        } catch (error) {
                            throw new Error(error);
                        }
                    }, {});
                    dispatch({ type: GET_FOLDER_TREE_VIEW_SUCCESS });
                    resolve(filteredDrive.length > 0 ? entireFolderMap[filteredDrive[0].rootFolder.id] : null); //확인
                })
                .catch((error) => {
                    console.log(error);
                    dispatch({ type: GET_FOLDER_TREE_VIEW_FAILURE, payload: error });
                    reject(error);
                });
        });
    };
export const getFoldersList =
    ({ driveId, queryParameters, headerParams, nextToken, abortToken = undefined }) =>
    (dispatch, getState) => {
        const { stage } = getState();
        const { parentId } = queryParameters;

        //클릭한 폴더를 folderMap에서 가져와서 pending이면 isFold만 바꿔서 return
        //만약 아니면 클릭한 폴더의 pending을 true로 바꾸고 아래 과정 진행
        //끝나고 pending false로 원상복구

        if (getState().folderTreeView.folderList.childrenPending) return;

        dispatch({ type: GET_FOLDERS_LIST_PENDING });
        return new Promise(async (resolve, reject) => {
            try {
                let folders = [];

                dispatch({ type: GET_FOLDERS_LIST_PARTIAL_PENDING, payload: { parentId, nextToken } });

                const response = await getFoldersListAPI(
                    stage.endpoint,
                    {
                        driveId,
                        queryParameters,
                        nextToken,
                        headerParams,
                    },
                    abortToken,
                );

                let folderMap = Object.fromEntries(
                    //기존 folderTreeView.folderList.data 그대로 사용시 삭제한 폴더의 정보가 사라지지 않음
                    Object.entries(getState().folderTreeView.folderList.data),
                );

                folders = response.data.results; //init은 맵을 새로 생성하지만 getList는 기존의 맵에서 속성만 추가해주는거라 folders를 누적할 필요는 없을듯

                const allFolderIds = folders.map((folder) => {
                    folderMap[folder.id] = {
                        isRoot: false,
                        children: [],
                        name: folder.name,
                        type: "folder",
                        isFold: true,
                        id: folder.id,
                        driveId: driveId,
                        depth: folder.parent.id ? folderMap[parentId].depth + 1 : 0,
                        parent: parentId,
                        pending: false,
                    };

                    return folder.id;
                });

                const parentFolder = folderMap[parentId];

                parentFolder.children = parentFolder.children.concat(allFolderIds);
                parentFolder.isFold = false;
                parentFolder.isEmpty = folders.length === 0;
                parentFolder.pending = false;
                parentFolder.nextToken = response.data.nextToken;

                dispatch({ type: GET_FOLDERS_LIST_PARTIAL_SUCCESS, payload: folderMap });

                dispatch({ type: GET_FOLDERS_LIST_SUCCESS });
                resolve();
            } catch (error) {
                console.log(error);
                dispatch({ type: GET_FOLDERS_LIST_FAILURE, payload: error });
            }
        });
    };

export const recoverFolderData =
    ({ headerParams, driveId, queryParameters, domain }) =>
    (dispatch, getState) => {
        const { stage } = getState();

        dispatch({ type: GET_FOLDER_TREE_VIEW_PENDING });

        return new Promise((resolve, reject) => {
            dispatch(getDriveData({ headerParams, domain }))
                .then(async (filteredDrive) => {
                    let folderMap = {};
                    filteredDrive?.forEach((drive) => {
                        //이전까지의 folderMap에 현재 드라이브 정보 추가
                        folderMap[drive.rootFolder.id] = {
                            isRoot: true,
                            children: [],
                            name: drive.name,
                            type: "drive",
                            isFold: false,
                            depth: 0,
                            id: drive.rootFolder.id,
                            driveId: drive.id,
                            parent: null,
                            pending: false,
                        };
                    });

                    const response = await getFoldersListAPI(stage.endpoint, {
                        driveId,
                        queryParameters,
                        headerParams,
                    });

                    const childrenResponse = await getFoldersListAPI(stage.endpoint, {
                        driveId,
                        queryParameters: {
                            parentId: queryParameters.id,
                            searchType: DRIVE_FOLDER_SEARCH_TYPE.DIRECT_CHILDREN,
                        },
                        headerParams,
                    });

                    const allFolders = response.data.results.concat(childrenResponse.data.results);

                    //Note: 현재 선택한 폴더의 루트폴더의 이름을 변경(기존에는 루트폴더의 id를 이름으로 가지고 있음)
                    const selectedFolderDrive = filteredDrive.find((drive) => drive.id === driveId);
                    const selectedFolderDriveRootFolder = allFolders.find(
                        (folder) => folder.id === selectedFolderDrive.rootFolder.id,
                    );
                    folderMap[selectedFolderDriveRootFolder.id].name = selectedFolderDriveRootFolder.name;

                    let driveInfo = {
                        rootFolder: {
                            id: selectedFolderDrive?.rootFolder?.id,
                        },
                        driveId: driveId,
                        folders: allFolders.filter((folder) => folder.depth > 0),
                    };

                    folderMap = createFolderTree(
                        driveInfo,
                        "search",
                        queryParameters.name ?? queryParameters.id,
                        folderMap,
                    );

                    await dispatch({ type: GET_FOLDER_TREE_VIEW_PARTIAL_SUCCESS, payload: folderMap });
                    await dispatch({ type: GET_FOLDER_TREE_VIEW_SUCCESS });

                    resolve(folderMap[queryParameters.id]);
                })
                .catch((error) => {
                    console.log(error);
                    dispatch({ type: GET_FOLDER_TREE_VIEW_FAILURE, payload: error });
                    reject(error);
                });
        });
    };

export const searchFolderData =
    ({ headerParams, queryParameters, abortToken = undefined, domain }) =>
    (dispatch, getState) => {
        const { stage, project } = getState();

        dispatch({ type: GET_FOLDER_TREE_VIEW_PENDING });

        return new Promise((resolve, reject) => {
            dispatch(getDriveData({ headerParams, domain }))
                .then(async (filteredDrive) => {
                    //드라이브 별로 폴더 목록 가져오기
                    const entireFolderMap = await filteredDrive?.reduce(async (prevFolderMap, drive) => {
                        try {
                            const { data: rootFolderData } = await getDetailDriveFolderAPI(stage.endpoint, {
                                driveId: drive.id,
                                folderId: drive.rootFolder.id,
                                projectId: project.id,
                            });

                            let folderMap = {
                                //이전까지의 folderMap에 현재 드라이브 정보 추가
                                ...prevFolderMap,
                                [rootFolderData.id]: {
                                    isRoot: true,
                                    children: [],
                                    name: rootFolderData.name,
                                    type: "drive",
                                    isFold: false,
                                    depth: 0,
                                    id: rootFolderData.id,
                                    driveId: drive.id,
                                    parent: null,
                                    pending: false,
                                },
                            };

                            const driveId = drive.id;
                            let nextToken;
                            let driveInfo = {
                                driveId: drive.id,
                                rootFolder: drive.rootFolder,
                                folders: [],
                            };

                            do {
                                try {
                                    const folderList = await getFoldersListAPI(
                                        stage.endpoint,
                                        {
                                            driveId,
                                            queryParameters,
                                            headerParams,
                                            nextToken,
                                        },
                                        abortToken,
                                    );
                                    driveInfo.folders = [
                                        ...driveInfo.folders,
                                        ...folderList.data.results.filter((folder) => folder.depth > 0),
                                    ];
                                    nextToken = folderList.data.nextToken;
                                } catch (error) {
                                    console.error("abort");
                                    return folderMap;
                                }

                                folderMap = createFolderTree(
                                    driveInfo,
                                    "search",
                                    queryParameters.name ?? queryParameters.id,
                                    folderMap,
                                );
                                dispatch({ type: GET_FOLDER_TREE_VIEW_PARTIAL_SUCCESS, payload: folderMap });
                            } while (nextToken !== undefined);

                            return folderMap;
                        } catch (error) {
                            throw new Error(error);
                        }
                    }, {});

                    dispatch({ type: GET_FOLDER_TREE_VIEW_SUCCESS });
                    resolve(
                        queryParameters.searchType === DRIVE_FOLDER_SEARCH_TYPE.PARENT_FOLDERS
                            ? entireFolderMap[queryParameters.id]
                            : undefined,
                    );
                })
                .catch((error) => {
                    console.log(error);
                    dispatch({ type: GET_FOLDER_TREE_VIEW_FAILURE, payload: error });
                });
        });
    };

export const createDriveFolder = (driveId, params) => (dispatch, getState) => {
    const { stage, project } = getState();
    dispatch({ type: CREATE_FOLDER_PENDING });
    return new Promise((resolve, reject) => {
        createDriveFolderAPI(stage.endpoint, project.id, driveId, params)
            .then((response) => {
                dispatch({ type: CREATE_FOLDER_SUCCESS, payload: response.data });
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                dispatch({ type: CREATE_FOLDER_FAILURE, payload: error });
                reject(error);
            });
    });
};

export const getDetailDriveFolder =
    ({ driveId, folderId }) =>
    (dispatch, getState) => {
        const { stage, project } = getState();

        dispatch({ type: GET_DETAIL_FOLDER_PENDING });
        return new Promise((resolve, reject) => {
            getDetailDriveFolderAPI(stage.endpoint, { driveId, folderId, projectId: project.id })
                .then((response) => {
                    dispatch({ type: GET_DETAIL_FOLDER_SUCCESS, payload: response.data });
                    resolve(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    dispatch({ type: GET_DETAIL_FOLDER_FAILURE, payload: error });
                    reject(error);
                });
        });
    };

export const updateFolderInfo =
    ({ driveId, folderId }, params) =>
    (dispatch, getState) => {
        const { stage, project } = getState();

        dispatch({ type: UPDATE_FORDER_INFO_PENDING });
        return new Promise((resolve, reject) => {
            setFolderInfoAPI(stage.endpoint, { driveId, folderId, projectId: project.id }, params)
                .then((response) => {
                    dispatch({ type: UPDATE_FORDER_INFO_SUCCESS });
                    resolve(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    dispatch({ type: UPDATE_FORDER_INFO_FAILURE, payload: error });
                    reject(error);
                });
        });
    };

export const deleteFolder =
    ({ driveId, folderId }) =>
    (dispatch, getState) => {
        const { stage, project } = getState();

        dispatch({ type: DELETE_FOLDER_PENDING });
        return new Promise((resolve, reject) => {
            deleteFolderAPI(stage.endpoint, { driveId, folderId, projectId: project.id })
                .then((response) => {
                    dispatch({ type: DELETE_FOLDER_SUCCESS });
                    resolve(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    dispatch({ type: DELETE_FOLDER_FAILURE, payload: error });
                    reject(error);
                });
        });
    };

export const moveFolder =
    ({ driveId }, params) =>
    (dispatch, getState) => {
        const { stage, project } = getState();

        dispatch({ type: MOVE_FOLDER_PENDING, payload: params.from.id });
        return new Promise((resolve, reject) => {
            moveFolderAPI(stage.endpoint, { driveId, projectId: project.id }, params)
                .then((response) => {
                    dispatch({ type: MOVE_FOLDER_SUCCESS });
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    dispatch({ type: MOVE_FOLDER_FAILURE, payload: error });
                    reject(error);
                });
        });
    };

export const updateMovedFolder =
    ({ fromPosition, toPosition }) =>
    (dispatch, getState) => {
        const { folderTreeView } = getState();

        let folderMap = {
            ...folderTreeView.folderList.data,
        };

        let fromObj = {
            ...folderMap[fromPosition.parent.id],
            children: folderMap[fromPosition.parent.id].children?.filter((id) => id !== fromPosition.id),
            브,
        };

        folderMap[fromPosition.parent.id] = fromObj;
        folderMap[fromPosition.id].parent = toPosition.id;
        folderMap[fromPosition.id].depth = folderMap[toPosition.id].depth + 1;
        let children = [...folderMap[fromPosition.id].children];
        while (children?.length) {
            const child = folderMap[children.shift()];

            child.depth = folderMap[child.parent].depth + 1;
            if (!child.isFold && child.children?.length) {
                children = children.concat([...child.children]);
            }
        }
        folderMap[toPosition.id].children.push(fromPosition.id);

        dispatch({ type: UPDATE_MOVED_FOLDER, payload: folderMap });
    };
