import { useCallback, useEffect, useState } from "react";

const useIntersection = ({ root, rootMargin = "0px", threshold = 0.5, onIntersect }) => {
    const [target, setTarget] = useState(null);
    const [skeletonLoading, setSkeletonLoading] = useState(false);

    const ioCallback = async ([entry], io) => {
        if (entry.isIntersecting) {
            setSkeletonLoading(true);
            setTimeout(async () => {
                document.body.style.overflow = "hidden";
                await onIntersect();
                setSkeletonLoading(false);
                document.body.style.overflow = "auto";
            }, 1000);
        }
    };

    useEffect(() => {
        if (!target) return;
        const io = new IntersectionObserver(ioCallback, { root, rootMargin, threshold });
        io.observe(target);
        // eslint-disable-next-line consistent-return
        return () => io.disconnect();
    }, [target, onIntersect]);

    return { setTarget, skeletonLoading };
};

export default useIntersection;
