import { axios } from "../../cores/axiosWrapper";

const CategoryApi = {
    getCategoriesAPI
};

export function getCategoriesAPI(stageId, apiEndpoint, projectId, categoryGroupId, params) {
    const response = axios.get(
        `${apiEndpoint}/category-groups/${categoryGroupId}/categories`,
        {
            headers: {
                stageId,
                projectId,
            },
            params
        }
    );
    return response;
}

export default CategoryApi;
