import { createAction, handleActions } from "redux-actions";
import { axios } from "../cores/axiosWrapper";

const getMeetingAPI = (stageId, apiEndpoint, projectId, id) => {
    return axios.get(`${apiEndpoint}/meetings/${id}`, {
        headers: {
            stageId,
            projectId,
        },
    });
};

const getMeetingRoomAPI = (stageId, apiEndpoint, projectId, meetingId, meetingRoomId) => {
    return axios.get(`${apiEndpoint}/meetings/${meetingId}/rooms/${meetingRoomId}`, {
        headers: { stageId, projectId },
    });
};

const deleteMeetingAPI = (stageId, apiEndpoint, projectId, id) => {
    return axios.delete(`${apiEndpoint}/meetings/${id}`, {
        headers: {
            stageId,
            projectId,
        },
    });
};

const createMeetingAPI = (stageId, apiEndpoint, projectId, params) => {
    return axios.post(`${apiEndpoint}/meetings`, params, {
        headers: {
            stageId,
            projectId,
        },
    });
};

const updateMeetingAPI = (stageId, apiEndpoint, projectId, id, meeting) => {
    const params = {
        id,
        meeting: meeting,
    };
    
    return axios.put(`${apiEndpoint}/meetings/${id}`, params, {
        headers: { stageId, projectId },
    });
};

const updateCategoriesAPI = (stageId, apiEndpoint, projectId, id, categories) => {
    return axios.put(`${apiEndpoint}/meetings/${id}/categories`, categories, {
        headers: { stageId, projectId },
    });
};

const updateHashtagsAPI = (stageId, apiEndpoint, projectId, id, hashtags) => {
    const params = { hashtags };

    return axios.put(`${apiEndpoint}/meetings/${id}/hashtags`, params, {
        headers: { stageId, projectId },
    });
}

const getCategoryListAPI = (stageId, apiEndpoint, projectId, id) => {
    return axios.get(`${apiEndpoint}/meetings/${id}/categories`, {
        headers: { stageId, projectId },
    });
};

const getHashtagListAPI = (stageId, apiEndpoint, projectId, id) => {
    return axios.get(`${apiEndpoint}/meetings/${id}/hashtags`, {
        headers: { stageId, projectId },
    });
};

const PENDING_MEETING = 'PENDING_MEETING';
const GET_MEETING_SUCCESS = 'GET_MEETING_SUCCESS';
const GET_MEETING_ROOM_SUCCESS = 'GET_MEETING_ROOM_SUCCESS';
const DELETE_MEETING_SUCCESS = 'DELETE_MEETING_SUCCESS';
const CREATE_MEETING_SUCCESS = 'CREATE_MEETING_SUCCESS';
const UPDATE_MEETING_SUCCESS = 'UPDATE_MEETING_SUCCESS';
const UPDATE_METADATA_SUCCESS = 'UPDATE_METADATA_SUCCESS';
const GET_CATEGORY_LIST_SUCCESS = 'GET_CATEGORY_LIST_SUCCESS';
const GET_HASHTAGS_LIST_SUCCESS = 'GET_HASHTAGS_LIST_SUCCESS';
const MEETING_FAILURE = 'MEETING_FAILURE';
const UPDATE_DATA_STATE = 'UPDATE_DATA_STATE';

const initialState = {
    data: {},
    pending: false,
    error: false,
    isDeleted: false,
    isChangingData: false,
};

export default handleActions({
    [PENDING_MEETING]: (state) => {
        return {
            ...state,
            pending: true,
        };
    },
    [MEETING_FAILURE]: (state) => {
        return {
            ...state,
            pending: false,
        };
    },
    [GET_MEETING_SUCCESS]: (state, action) => {
        return {
            ...state,
            pending: false,
            error: false,
            data: action.payload.data,
        };
    },
    [GET_MEETING_ROOM_SUCCESS]: (state, action) => {
        return {
            ...state,
            pending: false,
            error: false,
            rooms: {
                ...state.rooms,
                [action.payload.data.id]: action.payload.data
            },
        };
    },
    [DELETE_MEETING_SUCCESS]: (state, action) => {
        return {
            ...state,
            pending: false,
            error: false,
            isDeleted: true,
            isChangingData: false
        };
    },
    [CREATE_MEETING_SUCCESS]: (state, action) => {
        return {
            ...state,
            pending: false,
            error: false,
        };
    },
    [UPDATE_MEETING_SUCCESS]: (state, action) => {
        return {
            ...state,
            data: action.payload.data,
            pending: false,
            error: false,
        };
    },
    [UPDATE_METADATA_SUCCESS]: (state, action) => {
        return {
            ...state,
            pending: false,
            error: false,
        };
    },
    [GET_CATEGORY_LIST_SUCCESS]: (state, action) => {
        return {
            ...state,
            data: {
                ...state.data,
                categories: action.payload.data,
            },
            pending: false,
            error: false,
        };
    },
    [GET_HASHTAGS_LIST_SUCCESS]: (state, action) => {
        return {
            ...state,
            data: {
                ...state.data,
                hashtags: action.payload.data,
            },
            pending: false,
            error: false,
        };
    },
    [UPDATE_DATA_STATE]: (state, action) => {
        return {
            ...state,
            data: {
                ...state.data,
                ...action.payload,
            },
        }
    },
}, initialState);

export const updateDataState = createAction(UPDATE_DATA_STATE)

export const getMeeting = (id) => (dispatch, getState) => {
    const {
        stage,
        project,
    } = getState();

    dispatch({ type: PENDING_MEETING });

    return getMeetingAPI(stage.id, stage.endpoint, project.id, id).then(
        (response) => {
            dispatch({
                type: GET_MEETING_SUCCESS,
                payload: response,
            });
        }
    ).catch((error) => {
        dispatch({
            type: MEETING_FAILURE,
            payload: error,
        });
    });
};   

export const getMeetingRoom = (meetingId, meetingRoomId) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: PENDING_MEETING });

    return getMeetingRoomAPI(stage.id, stage.endpoint, project.id, meetingId, meetingRoomId).then(
        (response) => {
            dispatch({
                type: GET_MEETING_ROOM_SUCCESS,
                payload: response,                
            });
        }
    ).catch((error) => {
        dispatch({
            type: MEETING_FAILURE,
            payload: error,
        });
    });
};

export const deleteMeeting = (id, callback = () => {}) => (dispatch, getState) => {
    const {
        stage,
        project,
    } = getState();

    dispatch({ type: PENDING_MEETING });

    return deleteMeetingAPI(stage.id, stage.endpoint, project.id, id).then(
        (response) => {
            dispatch({
                type: DELETE_MEETING_SUCCESS,
                payload: response,
            });

            callback();
        }
    ).catch((error) => {
        dispatch({
            type: MEETING_FAILURE,
            payload: error,
        });
    });
};

export const createMeeting = (params, callback = () => {}) => (dispatch, getState) => {
    const {
        stage,
        project,
    } = getState();

    dispatch({ type: PENDING_MEETING });

    return createMeetingAPI(stage.id, stage.endpoint, project.id, params).then(
        (response) => {
            dispatch({
                type: CREATE_MEETING_SUCCESS,
                payload: response,
            });

            callback();
        }
    ).catch((error) => {
        dispatch({
            type: MEETING_FAILURE,
            payload: error,
        });
    });
};

export const updateMeeting = (id, params) => (dispatch, getState) => {
    const { stage, project, meeting } = getState();

    dispatch({ type: PENDING_MEETING });

    const composedParams = {
        ...meeting.data,
        ...params,
        attributions: null,
        categories: null,
        meetingRooms: null,
        hashtags: null,
    };

    return updateMeetingAPI(stage.id, stage.endpoint, project.id, id, composedParams).then(
        (response) => {
            dispatch({
                type: UPDATE_MEETING_SUCCESS,
                payload: response,
            });
        }
    ).catch((error) => {
        dispatch({
            type: MEETING_FAILURE,
            payload: error,
        });
    });
};

export const updateCategories = (id, categories) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: PENDING_MEETING });

    return updateCategoriesAPI(stage.id, stage.endpoint, project.id, id, categories).then(
        (response) => {
            dispatch({
                type: UPDATE_METADATA_SUCCESS,
                payload: response,
            });
        }
    ).catch((error) => {
        dispatch({ type: MEETING_FAILURE, payload: error });
    })
};

export const updateHashtags = (id, hashtags) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: PENDING_MEETING });

    return updateHashtagsAPI(stage.id, stage.endpoint, project.id, id, hashtags).then(
        (response) => {
            dispatch({
                type: UPDATE_METADATA_SUCCESS,
                payload: response,
            });
        }
    ).catch((error) => {
        dispatch({ type: MEETING_FAILURE, payload: error });
    });
};

export const getCategoryList = (id) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: PENDING_MEETING });

    return getCategoryListAPI(stage.id, stage.endpoint, project.id, id).then(
        (response) => {
            dispatch({
                type: GET_CATEGORY_LIST_SUCCESS,
                payload: response,
            });
        }
    ).catch((error) => {
        dispatch({ type: MEETING_FAILURE, payload: error });
    });
};

export const getHashtagList = (id) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: PENDING_MEETING });

    return getHashtagListAPI(stage.id, stage.endpoint, project.id, id).then(
        (response) => {
            dispatch({
                type: GET_HASHTAGS_LIST_SUCCESS,
                payload: response,
            })
        }
    ).catch((error) => {
        dispatch({ type: MEETING_FAILURE, payload: error });
    });
};

export const updateMeetingStatus = (id, status, callback = () => {}) => (dispatch, getState) => {
    const {
        stage,
        project,
    } = getState();

    dispatch({ type: PENDING_MEETING });

    const meeting = { status };

    return updateMeetingAPI(stage.id, stage.endpoint, project.id, id, meeting).then(
        (response) => {
            dispatch({
                type: UPDATE_MEETING_SUCCESS,
                payload: response,
            });

            callback();
        }
    ).catch((error) => {
        dispatch({
            type: MEETING_FAILURE,
            payload: error,
        });
    });
}
