import { combineReducers } from "redux";

import navigator from "@modules/navigator";
import filter from "@modules/filter";
import upload from "@modules/upload";
import assets from "@modules/assets";
import asset from "@modules/asset";
import createAsset from "@modules/createAsset";
import analysis from "@modules/analysis";
import people from "@modules/people";
import videos from "@modules/videos";
import video from "@modules/video";
import uploadQueue from "@modules/uploadQueue";
import streamForLineups from "@modules/streamForLineups";
import streamForProgrammes from "@modules/streamForProgrammes";
import streams from "@modules/streams";
import createStream from "@modules/createStream";
import stream from "@modules/stream";
import categories from "@modules/categories";
import channels from "@modules/channels";
import channel from "@modules/channel";
import channelSchedules from "@modules/channelSchedules";
import token from "@modules/token";
import stage from "@modules/stage";
import user from "@modules/user";
import project from "@modules/project";
import notification from "@modules/notification";
import pipelines from "@modules/pipelines";
import profile from "@modules/profile";
import global from "@modules/global";
import propertyPanel from "@modules/propertyPanel";
import job from "@modules/job";
import jobs from "@modules/jobs";
import listings from "@modules/listings";
import listing from "@modules/listing";
import listingsAssets from "@modules/listingsAssets";
import listingsVideos from "@modules/listingsVideos";
import listingsLineupList from "@modules/listingsLineupList";
import listingsCreate from "@modules/listingsCreate";
import listingsStreams from "@modules/listingsStreams";
import uploadMetadata from "@modules/uploadMetadata";
import rightSideBar from "@modules/rightSideBar";
import roles from "@modules/roles";
import navigatorForAdmin from "@modules/navigatorForAdmin";
import rolesManagement from "@modules/rolesManagement";
import userManagement from "@modules/userManagement";
import teamManagement from "@modules/teamManagement";
import metadataSchemaManagements from "@modules/metadataSchemaManagements";
import metadataFieldManagements from "@modules/metadataFieldManagements";
import projectManagement from "@modules/projectManagement";
import eventTriggerManagements from "@modules/eventTriggerManagements";
import eventTriggerManagement from "@modules/eventTriggerManagement";
import createEventTrigger from "@modules/createEventTrigger";
import categoryGroupsManagement from "@modules/categoryGroupsManagement";
import categoriesManagement from "@modules/categoriesManagement";
import audits from "@modules/audits";
import selectBaseVideoAsset from "@modules/selectBaseVideoAsset";
import selectWhitelists from "@modules/selectWhitelists";
import settings from "@modules/settings";
import timezones from "@modules/timezones";
import megazoneAccount from "@modules/megazoneAccount";
import playerProfiles from "@modules/playerProfiles";
import statisticsSummary from "@modules/statisticsSummary";
import resourceStatistics from "@modules/resourceStatistics";
import configureWidgets from "@modules/configureWidgets";
import archivedAssets from "@modules/archivedAssets";
import archivedAsset from "@modules/archivedAsset";
import posting from "@modules/posting";
import drm from "@modules/drm";
import meetings from "@modules/meetings";
import meeting from "@modules/meeting";
import servicePrice from "@modules/servicePrice";
import sources from "@modules/sources";
import searchableBuckets from "@modules/searchableBuckets";
import interactiveLiveChannel from "@modules/interactiveLiveChannel";
import interactiveLiveChannels from "@modules/interactiveLiveChannels";
import scheduleHistories from "@modules/scheduleHistories";
import musics from "@modules/musics";
import music from "@modules/music";
import photos from "@modules/photos";
import photo from "@modules/photo";
import createMusic from "@modules/createMusic";
import integratedSearch from "@modules/integratedSearch";
import integratedSearchWithSE from "@modules/integratedSearchWithSE";
import download from "@modules/download";
import shares from "@modules/shares";
import shared from "@modules/shared";
import assetElementsViewer from "@modules/assetElementsViewer";
import assetElementVersionModal from "@modules/assetElementVersionModal";
import dashboard from "@modules/dashboard";
import attributions from "@modules/attributions";
import fileMetaAttributions from "@modules/fileMetaAttributions";
import assetDeleteModal from "@modules/assetDeleteModal";
import myJobs from "@modules/myJobs";
import categoryTreeView from "@modules/categoryTreeView";
import categoryModalTreeView from "@modules/categoryModalTreeView";
import siteMode from "@modules/siteMode";
import metadataFieldItemSets from "@modules/metadataFieldItemSets";
import iv_channel from "@modules/interactiveVideo_channel";
import iv_rooms from "@modules/interactiveVideo_rooms";
import iv_room from "@modules/interactiveVideo_room";
import meetings_v2 from "@modules/meetings_v2";
import downloadJobs from "@modules/downloadJobs";
import bulkJobs from "@modules/bulkJobs";
import createAssetsBulk from "@modules/createAssetsBulk";
import shareRequest from "@modules/shareRequest";
import sharedBox from "@modules/sharedBox";
import folderTreeView from "@modules/folderTreeView";
import archivedFolderTreeView from "@modules/archivedFolderTreeView";
import folderTreeViewModal from "@modules/folderTreeViewModal";
import metadataTemplate from "@modules/metadataTemplate";
import pausedPollingApis from "@modules/pausedPollingApis";
import collections from "@modules/collections";
import collection from "@modules/collection";
import contentSchema from "@modules/content-schema";
import contentSchemas from "@modules/contentSchemas";
import customContents from "@modules/custom-contents";
import customContent from "@modules/custom-content";
import vocabularies from "@modules/vocabularies";
import vocabulary from "@modules/vocabulary";
import comments from "@modules/comments";
import commonComments from "@modules/common-comments";
import userV2 from "@modules/user-v2";
import roleManagementV2 from "@modules/rolesManagement_v2";
import userManagementV2 from "@modules/user-management-v2";
import teamManagementV2 from "@modules/team-management-v2";
import metadataExport from "@modules/metadata-export";
import metadataExports from "@modules/metadata-exports";
import dev from "@modules/dev";

const appReducers = combineReducers({
    pausedPollingApis,
    navigator,
    filter,
    upload,
    assets,
    asset,
    collections,
    collection,
    createAsset,
    uploadQueue,
    videos,
    video,
    token,
    stage,
    user,
    project,
    streamForLineups,
    streamForProgrammes,
    streams,
    createStream,
    stream,
    categories,
    channels,
    channel,
    notification,
    pipelines,
    profile,
    analysis,
    people,
    global,
    job,
    jobs,
    listings,
    listing,
    listingsCreate,
    listingsAssets,
    listingsVideos,
    listingsLineupList,
    listingsStreams,
    uploadMetadata,
    rightSideBar,
    roles,
    navigatorForAdmin,
    rolesManagement,
    userManagement,
    projectManagement,
    teamManagement,
    categoryGroupsManagement,
    categoriesManagement,
    audits,
    megazoneAccount,
    selectBaseVideoAsset,
    metadataSchemaManagements,
    metadataFieldManagements,
    eventTriggerManagements,
    eventTriggerManagement,
    createEventTrigger,
    selectWhitelists,
    settings,
    timezones,
    channelSchedules,
    propertyPanel,
    playerProfiles,
    statisticsSummary,
    resourceStatistics,
    configureWidgets,
    archivedAssets,
    archivedAsset,
    posting,
    drm,
    meetings,
    meeting,
    servicePrice,
    sources,
    searchableBuckets,
    interactiveLiveChannel,
    interactiveLiveChannels,
    scheduleHistories,
    musics,
    music,
    photos,
    photo,
    createMusic,
    download,
    shares,
    shared,
    integratedSearch,
    integratedSearchWithSE,
    assetElementsViewer,
    attributions,
    fileMetaAttributions,
    assetElementVersionModal,
    dashboard,
    assetDeleteModal,
    myJobs,
    categoryTreeView,
    categoryModalTreeView,
    siteMode,
    metadataFieldItemSets,
    iv_channel,
    iv_room,
    iv_rooms,
    meetings_v2,
    downloadJobs,
    bulkJobs,
    createAssetsBulk,
    shareRequest,
    sharedBox,
    folderTreeView,
    // archivedFolderTreeView,
    folderTreeViewModal,
    metadataTemplate,
    contentSchema,
    contentSchemas,
    dev,
    customContents,
    customContent,
    vocabularies,
    vocabulary,
    comments,
    commonComments,
    userV2,
    roleManagementV2,
    userManagementV2,
    teamManagementV2,
    metadataExports,
    metadataExport,
});

const megazoneCloudMediaApp = (state, action) => {
    if (action.type === "GLOBAL_RESET_ALL") {
        state = undefined;
    }
    return appReducers(state, action);
};

export default megazoneCloudMediaApp;
