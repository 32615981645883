import { axios } from "../../cores/axiosWrapper";
import queryString from "../../cores/queryString";

const HiddenMetadataApi = {
    getHiddenMetadata
};

export function getHiddenMetadata(stageId, apiEndpoint, projectId, resourceType, resourceId, metadataFieldId) {
    let queries = {
        resourceType,
        resourceId, 
        metadataFieldId
    };
    const response = axios.get(
        `${apiEndpoint}/hidden-metadata?${queryString.stringify(queries)}`,
        {
            headers: {
                stageId,
                projectId,
            }
        }
    );
    return response;
}

export default HiddenMetadataApi;
