import { handleActions, createAction } from "redux-actions";
import { toast } from "react-toastify";
import uniqid from "uniqid";
//action type
const SET_NOTIFICATION = "SET_NOTIFICATION";
const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
const REMOVE_ALL_NOTIFICATION = "REMOVE_ALL_NOTIFICATION";
//reducer
const initialState = {
    data: [],
};
const toastTypes = Object.keys(toast.TYPE).map((type) => type.toLowerCase());

export default handleActions(
    {
        [SET_NOTIFICATION]: (state, action) => {
            const data = state.data;
            //console.log(`notification added: ${JSON.stringify(action.payload)}`);
            data.push(action.payload);
            return {
                data,
            };
        },
        [DELETE_NOTIFICATION]: (state, action) => {
            return {
                data: state.data.filter((v) => v._id !== action.payload),
            };
        },
        [REMOVE_ALL_NOTIFICATION]: (state) => {
            return {
                ...state,
                data: [],
            };
        },
    },
    initialState,
);
export const setNotification =
    ({ _id = uniqid(), type, contents }) =>
    (dispatch) => {
        if (!toastTypes.includes(type)) throw new Error("unknown notification type");

        const payload = {
            _id,
            type,
            contents,
        };
        dispatch({
            type: SET_NOTIFICATION,
            payload,
        });

        toast[type](contents, {
            position: toast.POSITION.TOP_RIGHT,
            onClose: () => {
                dispatch(deleteNotification(payload._id));
            },
        });
    };
export const deleteNotification = createAction(DELETE_NOTIFICATION);
export const removeAllNotification = createAction(REMOVE_ALL_NOTIFICATION);
