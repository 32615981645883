import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';

import moment from 'moment';
import formatDuration from "../../../cores/formatDuration";
import getScheduleDateTime from "../../../cores/getScheduleDateTime";
import cutByteLength from '../../../cores/cutByteLength';
import WithSelect from "../../../components/select";
import pad from '../../../cores/string.pad';
import {SingleDatePicker} from "react-dates";

class ScheduleDuration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentDate : moment().format('YYYY-MM-DD'),
            duration : '',
            durationState: 'consonance', //exceed, under
            displayBeginTimeMilliseconds : undefined,
            displayEndTimeMilliseconds : undefined,
            hour : [],
            min : [],
            sec : []
        };
    }

    UNSAFE_componentWillMount() {
        let hour = [];
        let min = [];
        let sec = [];

        for(let i = 0, len = 100; i < len; i++) {
            hour.push({
                label : i < 10 ? '0' + i : i + '',
                value : i < 10 ? '0' + i : i + ''
            });
            if(i < 60) {
                min.push({
                    label : i < 10 ? '0' + i : i + '',
                    value : i < 10 ? '0' + i : i + ''
                });
                sec.push({
                    label : i < 10 ? '0' + i : i + '',
                    value : i < 10 ? '0' + i : i + ''
                });
            }
        }

        this.setState({
            ...this.state,
            hour,
            min,
            sec
        });
    }

    componentDidMount() {
        this.getDuration();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.beginTime !== this.props.beginTime) {
            this.getDuration();
        }

        if(prevProps.displayEndDateTime !== this.props.displayEndDateTime) {
            this.getDuration();
        }
    }

    getDuration() {
        let {beginDateTime, endDateTime} = getScheduleDateTime({
            currentDate : this.state.currentDate,
            beginTime: this.props.beginTime,
            endTime: this.props.displayEndDateTime
        });

        const duration = endDateTime.diff(beginDateTime);
        const durationState = moment.duration(this.props.duration)._milliseconds - duration === 0 ? 'consonance'
            : (duration <= 0 ? 'exceed' : 'under');

        if(Number.isNaN(duration)) {

        } else if(duration < 0) {
            const _duration = this.separateDurationByTimeUnit(duration * -1);

            this.setState({
                ...this.state,
                duration: `-${pad(_duration.hours, 2)}:${pad(_duration.minutes, 2)}:${pad(_duration.seconds,2)}.${pad(_duration.milliseconds,3)}`,
                durationState
            });
        } else if(duration > 0) {
            const _duration = this.separateDurationByTimeUnit(duration);

            this.setState({
                ...this.state,
                duration: `${pad(_duration.hours, 2)}:${pad(_duration.minutes, 2)}:${pad(_duration.seconds,2)}.${pad(_duration.milliseconds,3)}`,
                durationState
            });
        } else {
            const _duration = this.separateDurationByTimeUnit(duration);

            this.setState({
                ...this.state,
                duration: `${pad(_duration.hours, 2)}:${pad(_duration.minutes, 2)}:${pad(_duration.seconds,2)}.${pad(_duration.milliseconds,3)}`,
                durationState
            });
        }
    };

    separateDurationByTimeUnit =  (duration) => {
        return {
            hours : Math.floor(duration / 60 / 60 / 1000).toString(),
            minutes : Math.floor((duration % (60 * 60 * 1000)) / 60 / 1000).toString(),
            seconds : Math.floor((duration % (60 * 1000)) / 1000).toString(),
            milliseconds : (duration % 1000).toString()
        }
    }

    filledZeroToEmptyTimeProject = (time) => {
        const filledTime = time.replace(/[:|.|_]/g, '').padEnd(9, 0);

        const hours = filledTime.substring(0, 2);
        const minutes = filledTime.substring(2, 4);
        const seconds = filledTime.substring(4, 6);
        const milliseconds = filledTime.substring(6, filledTime.length);

        return `${hours}:${minutes}:${seconds}.${milliseconds}`;
    }

    correctEndTime = (bt) => {
        let {beginDateTime} = getScheduleDateTime({
            currentDate : this.state.currentDate,
            beginTime: bt,
            endTime: this.props.endTime
        });

        const endDateTime = this.addDuration(beginDateTime);
        return endDateTime.format("HH:mm:ss.SSS");
    }

    addDuration = (dateTime) => {
        if(!this.props.duration) {
            dateTime.add(30, 'minutes');
            return dateTime;
        }

        const duration = moment(`${this.state.currentDate} ${this.props.duration}`);

        dateTime.add(`${duration.format("SSS")}`, 'millisecond');
        dateTime.add(`${duration.format("ss")}`, 'seconds');
        dateTime.add(`${duration.format("mm")}`, 'minutes');
        dateTime.add(`${duration.format("HH")}`, 'hours');

        return dateTime;
    }

	onSelectFocus = (e) => {
        e.target.closest('.select2-container').classList.add('select2-focus');
    };

    onSelectBlur = (e) => {
        e.target.closest('.select2-container').classList.remove('select2-focus');
    };

    updateDisplayBeginTimeMilliseconds = (beginTimeMilliseconds) => {
        this.setState({
            displayBeginTimeMilliseconds : beginTimeMilliseconds
        });
    }

    updateDisplayEndTimeMilliseconds = (endTimeMilliseconds) => {
        this.setState({
            displayEndTimeMilliseconds : endTimeMilliseconds
        });
    }

    render() {
        const { t, i18n } = this.props;

        let splitBeginTimeMillisecond = this.props.beginTime.split('.');
        let splitEndTimeMillisecond = this.props.displayEndDateTime.split('.');
        let splitBeginTime = splitBeginTimeMillisecond[0].split(':');
        let splitEndTime = splitEndTimeMillisecond[0].split(':');

        const markIn = this.props.markIn || '00:00:00.000';
        const markOut = this.props.markOut || '00:00:00.000';

        let splitMarkInMillisecond = markIn.split('.');
        let splitMarkIn = splitMarkInMillisecond[0].split(':');

        let splitMarkOutMillisecond = markOut.split('.');
        let splitMarkOut = splitMarkOutMillisecond[0].split(':');

        return (
            <React.Fragment>
                <div className={"form-block form-input-group"}>
                    <div className={"form-input-group-title"}>
                        <strong className={"input-group-text"}>{t(`common::label::${"Date"}`)}</strong>
                    </div>
                    <div className={"flex-grow-1"}>
                        <SingleDatePicker
                            date={moment(this.props.currentDate)}
                            onDateChange={date => {
                                this.props.onChangeBeginDate(date);
                            }}
                            focused={this.state.focused}
                            onFocusChange={({focused}) => this.setState({focused})}
                            displayFormat={"YYYY-MM-DD"}
                            numberOfMonths={1}
                            small={true}
                            showDefaultInputIcon={true}
                            inputIconPosition="before"
                            // disabled={!this.props.isEnabled || this.state.endType === 'never'}
                            anchorDirection={"right"}
                        />
                    </div>
                </div>
                <div className="form-timepicker">
                    <div className="form-block">
                        <label className="form-label"><i className="sprite sprite-clock-fade"></i><strong>{t(`common::label::${"Start time"}`)}</strong><i className={"required"}>* </i> ~ </label>
                        <div className={`date-time ${(this.props.validationErrors && this.props.validationErrors.schedule && this.props.validationErrors.schedule.level === 'error'
                        && this.props.validationErrors.schedule.type === 'BEGIN_PAST_TIME') ? 'error' : '' }`}>
							<WithSelect
								options={this.state.hour}
								defaultValue={splitBeginTime[0]}
								onFocus={(e) => this.onSelectFocus(e)}
								onBlur={(e) => this.onSelectBlur(e)}
								onChange={(option) => {
									let beginTime = `${option.value}:${splitBeginTime[1]}:${splitBeginTime[2]}.${splitBeginTimeMillisecond[1]}`;

									this.props.onChangeBeginTime({
										beginTime,
										endTime: this.correctEndTime(beginTime)
									});
								}}/>
							<WithSelect
								options={this.state.min}
								defaultValue={splitBeginTime[1]}
								onFocus={(e) => this.onSelectFocus(e)}
								onBlur={(e) => this.onSelectBlur(e)}
								onChange={(option) => {
									let beginTime = `${splitBeginTime[0]}:${option.value}:${splitBeginTime[2]}.${splitBeginTimeMillisecond[1]}`;

									this.props.onChangeBeginTime({
										beginTime,
										endTime: this.correctEndTime(beginTime)
									});
								}}/>
							<WithSelect
								options={this.state.sec}
								defaultValue={splitBeginTime[2]}
								onFocus={(e) => this.onSelectFocus(e)}
								onBlur={(e) => this.onSelectBlur(e)}
								onChange={(option) => {
									let beginTime = `${splitBeginTime[0]}:${splitBeginTime[1]}:${option.value}.${splitBeginTimeMillisecond[1]}`;

									this.props.onChangeBeginTime({
										beginTime,
										endTime: this.correctEndTime(beginTime)
									});
								}}/>
                            <div>
                                <input
                                    type={"number"}
                                    className={"form-control"}
                                    min={0}
                                    value={`${this.state.displayBeginTimeMilliseconds || splitBeginTimeMillisecond[1]}`}
                                    onBlur={(e) => {
                                        const beginTimeMilliseconds = pad(cutByteLength(e.target.value, 3), 3);
                                        let beginTime = `${splitBeginTime[0]}:${splitBeginTime[1]}:${splitBeginTime[2]}.${beginTimeMilliseconds}`;

                                        this.props.onChangeBeginTime({
                                            beginTime,
                                            endTime: this.correctEndTime(beginTime)
                                        });

                                        this.updateDisplayBeginTimeMilliseconds(beginTimeMilliseconds);
                                    }}
                                    onChange={(e) => this.updateDisplayBeginTimeMilliseconds(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-block">
                        <strong className="form-label">{t(`common::label::${"Mark in"}`)}<i className={"required"}>*</i></strong>
                        <div className={`date-time disabled`}>
                            <WithSelect
                                options={this.state.hour}
                                defaultValue={splitMarkIn[0]}
                                onFocus={(e) => this.onSelectFocus(e)}
                                onBlur={(e) => this.onSelectBlur(e)}
                                onChange={(option) => {
                                    let endTime = `${option.value}:${splitEndTime[1]}:${splitEndTime[2]}.${splitEndTimeMillisecond[1]}`;

                                    this.props.onChangeEndTime({
                                        beginTime : this.props.beginTime,
                                        endTime
                                    });
                                }}/>

                            <WithSelect
                                options={this.state.min}
                                defaultValue={splitMarkIn[1]}
                                onFocus={(e) => this.onSelectFocus(e)}
                                onBlur={(e) => this.onSelectBlur(e)}
                                onChange={(option) => {
                                    let endTime = `${splitEndTime[0]}:${option.value}:${splitEndTime[2]}.${splitEndTimeMillisecond[1]}`;
                                    this.props.onChangeEndTime({
                                        beginTime : this.props.beginTime,
                                        endTime
                                    });
                                }}/>

                            <WithSelect
                                options={this.state.sec}
                                defaultValue={splitMarkIn[2]}
                                onFocus={(e) => this.onSelectFocus(e)}
                                onBlur={(e) => this.onSelectBlur(e)}
                                onChange={(option) => {
                                    let endTime = `${splitEndTime[0]}:${splitEndTime[1]}:${option.value}.${splitEndTimeMillisecond[1]}`;

                                    this.props.onChangeEndTime({
                                        beginTime : this.props.beginTime,
                                        endTime
                                    });
                                }}/>
                            <div>
                                <input
                                    type={"number"}
                                    className={"form-control"}
                                    min={0}
                                    value={`${splitMarkInMillisecond[1]}`}
                                    onBlur={(e) => {
                                        const endTimeMilliseconds = pad(cutByteLength(e.target.value, 3), 3);
                                        let endTime = `${splitEndTime[0]}:${splitEndTime[1]}:${splitEndTime[2]}.${endTimeMilliseconds}`;

                                        this.props.onChangeEndTime({
                                            beginTime : this.props.beginTime,
                                            endTime
                                        });

                                        this.updateDisplayEndTimeMilliseconds(endTimeMilliseconds);
                                    }}
                                    onChange={(e) => this.updateDisplayEndTimeMilliseconds(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-block">
                        <strong className="form-label">{t(`common::label::${"Mark out"}`)}<i className={"required"}>*</i></strong>
                        <div className={`date-time disabled`}>
                            <WithSelect
                                options={this.state.hour}
                                defaultValue={splitMarkOut[0]}
                                onFocus={(e) => this.onSelectFocus(e)}
                                onBlur={(e) => this.onSelectBlur(e)}
                                onChange={(option) => {
                                    let endTime = `${option.value}:${splitEndTime[1]}:${splitEndTime[2]}.${splitEndTimeMillisecond[1]}`;

                                    this.props.onChangeEndTime({
                                        beginTime : this.props.beginTime,
                                        endTime
                                    });
                                }}/>

                            <WithSelect
                                options={this.state.min}
                                defaultValue={splitMarkOut[1]}
                                onFocus={(e) => this.onSelectFocus(e)}
                                onBlur={(e) => this.onSelectBlur(e)}
                                onChange={(option) => {
                                    let endTime = `${splitEndTime[0]}:${option.value}:${splitEndTime[2]}.${splitEndTimeMillisecond[1]}`;
                                    this.props.onChangeEndTime({
                                        beginTime : this.props.beginTime,
                                        endTime
                                    });
                                }}/>

                            <WithSelect
                                options={this.state.sec}
                                defaultValue={splitMarkOut[2]}
                                onFocus={(e) => this.onSelectFocus(e)}
                                onBlur={(e) => this.onSelectBlur(e)}
                                onChange={(option) => {
                                    let endTime = `${splitEndTime[0]}:${splitEndTime[1]}:${option.value}.${splitEndTimeMillisecond[1]}`;

                                    this.props.onChangeEndTime({
                                        beginTime : this.props.beginTime,
                                        endTime
                                    });
                                }}/>
                            <div>
                                <input
                                    type={"number"}
                                    className={"form-control"}
                                    min={0}
                                    value={`${splitMarkOutMillisecond[1]}`}
                                    onBlur={(e) => {
                                        const endTimeMilliseconds = pad(cutByteLength(e.target.value, 3), 3);
                                        let endTime = `${splitEndTime[0]}:${splitEndTime[1]}:${splitEndTime[2]}.${endTimeMilliseconds}`;

                                        this.props.onChangeEndTime({
                                            beginTime : this.props.beginTime,
                                            endTime
                                        });

                                        this.updateDisplayEndTimeMilliseconds(endTimeMilliseconds);
                                    }}
                                    onChange={(e) => this.updateDisplayEndTimeMilliseconds(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-block">
                        <label className="form-label"><i className="sprite sprite-clock-fade"></i><strong>{t(`common::label::${"End time"}`)}</strong><i className={"required"}>*</i></label>
                        <div className={`date-time ${(this.props.validationErrors && this.props.validationErrors.schedule && this.props.validationErrors.schedule.level === 'error'
                        && this.props.validationErrors.schedule.type !== 'BEGIN_PAST_TIME') ? 'error' : '' }`}>
							<WithSelect
								options={this.state.hour}
								defaultValue={splitEndTime[0]}
								onFocus={(e) => this.onSelectFocus(e)}
								onBlur={(e) => this.onSelectBlur(e)}
								onChange={(option) => {
									let endTime = `${option.value}:${splitEndTime[1]}:${splitEndTime[2]}.${splitEndTimeMillisecond[1]}`;

									this.props.onChangeEndTime({
										beginTime : this.props.beginTime,
										endTime
									});
								}}/>

							<WithSelect
								options={this.state.min}
								defaultValue={splitEndTime[1]}
								onFocus={(e) => this.onSelectFocus(e)}
								onBlur={(e) => this.onSelectBlur(e)}
								onChange={(option) => {
									let endTime = `${splitEndTime[0]}:${option.value}:${splitEndTime[2]}.${splitEndTimeMillisecond[1]}`;
									this.props.onChangeEndTime({
										beginTime : this.props.beginTime,
										endTime
									});
								}}/>

							<WithSelect
								options={this.state.sec}
								defaultValue={splitEndTime[2]}
								onFocus={(e) => this.onSelectFocus(e)}
								onBlur={(e) => this.onSelectBlur(e)}
								onChange={(option) => {
									let endTime = `${splitEndTime[0]}:${splitEndTime[1]}:${option.value}.${splitEndTimeMillisecond[1]}`;

									this.props.onChangeEndTime({
										beginTime : this.props.beginTime,
										endTime
									});
								}}/>
                            <div>
                                <input
                                    type={"number"}
                                    className={"form-control"}
                                    min={0}
                                    value={`${this.state.displayEndTimeMilliseconds || splitEndTimeMillisecond[1]}`}
                                    onBlur={(e) => {
                                        const endTimeMilliseconds = pad(cutByteLength(e.target.value, 3), 3);
                                        let endTime = `${splitEndTime[0]}:${splitEndTime[1]}:${splitEndTime[2]}.${endTimeMilliseconds}`;

                                        this.props.onChangeEndTime({
                                            beginTime : this.props.beginTime,
                                            endTime
                                        });

                                        this.updateDisplayEndTimeMilliseconds(endTimeMilliseconds);
                                    }}
                                    onChange={(e) => this.updateDisplayEndTimeMilliseconds(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {this.props.validationErrors && this.props.validationErrors.schedule && (this.props.validationErrors.schedule.level === 'error') &&
                    <div className={"text-danger"}>
                        {this.props.validationErrors.schedule.message}
                    </div>
                }

                <div className={"date-time-guide"}>
                    <div className={`status-${this.state.durationState}`}>
                        <ul>
                            <li><span>{t(`common::label::${"Program duration"}`)}</span>-<strong>{this.state.duration}</strong></li>
                            <li><span>{t(`common::label::${"Contents duration"}`)}</span>-<strong>{formatDuration(this.props.duration, 'HH:mm:ss.SSS')}</strong></li>
                        </ul>
                    </div>
                </div>
                {this.props.validationErrors && this.props.validationErrors.schedule && this.props.validationErrors.schedule.level === 'warning' &&
                    <div className={"notification notification-box"}>
                        <i className={"sprite sprite-notification mr-2"}></i>
                        <span>{this.props.validationErrors.schedule.message}</span>
                    </div>
                }

            </React.Fragment>
        );
    }
}

export default withTranslation()(ScheduleDuration);