import { axios } from "../../cores/axiosWrapper";

//api
export const getDrivesListAPI = (endpoint, { headerParams }) => {
    return axios.get(`${endpoint}/v3/drives`, {
        headers: {
            ...headerParams,
        },
    });
};

export const getDriveDetailAPI = (endpoint, { driveId, headerParams }) => {
    return axios.get(`${endpoint}/v3/drives/${driveId}`, {
        headers: {
            ...headerParams,
        },
    });
};

export const getFoldersListAPI = (
    endpoint,
    { driveId, queryParameters, nextToken, headerParams },
    abortToken = undefined,
) => {
    return axios.get(`${endpoint}/v3/drives/${driveId}/folders`, {
        cancelToken: abortToken,
        params: { ...queryParameters, nextToken },
        headers: {
            ...headerParams,
        },
    });
};

export const createDriveFolderAPI = (endpoint, projectId, driveId, params) => {
    return axios.post(`${endpoint}/v3/drives/${driveId}/folders`, params, { headers: { projectId } });
};

export const getDetailDriveFolderAPI = (endpoint, { driveId, folderId, projectId }) => {
    return axios.get(`${endpoint}/v3/drives/${driveId}/folders/${folderId}`, {
        headers: { projectId },
    });
};

export const setFolderInfoAPI = (endpoint, { driveId, folderId, projectId }, params) => {
    return axios.patch(`${endpoint}/v3/drives/${driveId}/folders/${folderId}`, params, {
        headers: { projectId },
    });
};

export const deleteFolderAPI = (endpoint, { driveId, folderId, projectId }) => {
    return axios.delete(`${endpoint}/v3/drives/${driveId}/folders/${folderId}`, {
        headers: { projectId },
    });
};

export const moveFolderAPI = (endpoint, { driveId, projectId }, params) => {
    return axios.post(`${endpoint}/v3/drives/${driveId}/folders:move`, params, {
        headers: { projectId },
    });
};
