const Polling = (
    executeAsync,
    shouldStopPredicate = (d) => { return true; },
    successCallback = () => {},    
    errorCallback = (e) => { console.error(`error while polling`, e); },    
    interval = 2000
) => {
    return {
        async run() {                        
            try {
                const response = await executeAsync();                
                successCallback(response);
                if(!shouldStopPredicate(response) && !this.timer) {
                    this.timer = setTimeout(() => {
                        this.run();
                        this.timer = clearTimeout(this.timer);
                    }, interval);
                }
            } catch(e) {
                if(this.timer) {
                    clearTimeout(this.timer);
                }
                if(errorCallback && typeof(errorCallback) === "function")
                    errorCallback(e);
            }
        }
    }
};

export default Polling;