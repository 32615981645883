import PropTypes from "prop-types";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { HeaderForLayout, HeaderForAdmin } from "@components/header";
import Navigator from "@components/navigator";
import Notification from "@components/notification";
import Loader from "@components/loader";
import PropertyPanel from "@components/property-panel";
import Footer from "@components/footer";
import RightSideBar from "@routes/rightsidebar";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import clsx from "clsx";
import React, { useMemo } from "react";
import ReactDOM from "react-dom";
import Loadable from "@react-loadable/revised";
import * as createAssetsBulkActions from "@modules/createAssetsBulk";
import { Grid, Toolbar } from "@mzc-pdc/ui";

const LoadableCreateAssetsModal = Loadable({
    loader: () => import("@/components/modals/CreateAssetsBulk"),
    loading: () => null,
});

const LayoutForComponent = ({ children }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const history = useHistory();

    const globalLoading = useSelector((state) => state.global.loading);
    const isSidebarPushOpened = useSelector((state) => state.propertyPanel.isShow);
    const stageId = useSelector((state) => state.stage.id);
    const isSidebarCoverOpened = useSelector((state) => state.rightSideBar.active);
    const isLineupPage = useMemo(
        () => location.pathname.indexOf("/schedules") > 0 && location.pathname.indexOf("/lineups") > 0,
        [location.pathname],
    );
    const createAssetsBulk = useSelector((state) => state.createAssetsBulk);
    const CreateAssetsBulkActions = bindActionCreators(createAssetsBulkActions, dispatch);

    return (
        <DndProvider backend={HTML5Backend}>
            <Grid
                container
                flexWrap={"nowrap"}
                className={clsx({
                    wrap: true, //TODO: 반응형을 위해서는 이 클래스를 제거해야함(최소 너비가 적용되어 있어서 작은 해상도일 경우 페이지가 우측으로 튀어나옴)
                    // "in-sidebar": Boolean(stageId),
                    "property-panel panel-push-open": isSidebarPushOpened,
                    "panel-cover-open": isSidebarCoverOpened,
                    "in-fixed-tab": isLineupPage,
                })}
            >
                {location.pathname.indexOf("/admin/spaces") !== -1 ? (
                    <HeaderForAdmin location={location} />
                ) : (
                    <HeaderForLayout location={location} />
                )}

                {stageId && <Navigator location={location} match={match} history={history} />}

                <div style={{ flex: 1 }}>
                    <Toolbar />
                    <section className={"container"}>
                        <div className={"main"} style={{ paddingLeft: "0px" }}>
                            <div className="contents">{children}</div>
                            <Footer />
                        </div>
                        {stageId && (
                            <>
                                <PropertyPanel location={location} isLineupPage={isLineupPage} />
                                <RightSideBar location={location} />
                            </>
                        )}
                    </section>
                </div>
                {globalLoading && <Loader />}
                <Notification />

                {createAssetsBulk.showModal &&
                    ReactDOM.createPortal(
                        <LoadableCreateAssetsModal
                            submitText={`Create Assets Bulk`}
                            title={`Create Assets Bulk`}
                            onClose={() => {
                                CreateAssetsBulkActions.closeModal();
                            }}
                            initialSelectedFiles={createAssetsBulk.initialFiles}
                        />,
                        document.body,
                    )}
            </Grid>
        </DndProvider>
    );
};

LayoutForComponent.propTypes = {
    children: PropTypes.element,
};

export default LayoutForComponent;
