import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import {
    Badge,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogPanel,
    DialogTitle,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    Link,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField,
} from "@mzc-pdc/ui";
import convertToByte from "@cores/convertToByte";
import { RadioFormControlLabel } from "@components_v2/radioGroup/radioGroup.styled";
import Typography from "@components_v2/typography/typography";
import theme from "@styles/theme";
import {
    IconCancel,
    IconInfoCircle,
    IconClose,
    IconOpenInNew,
    ImageFolderSmallOn,
    ImageDriveOn,
    IconHistory,
} from "@mzc-cloudplex/icons";
import TagsInput from "@features/tags-input/tags-input";
// import CollectionColumn from "@components/view/panel-details-view/panel-table-columns-collections";
import SelectFolderPathModal from "@components/modals/SelectFolderPath";
import { clearModalList, getCollectionsModal } from "@modules/collections";
import TableCard from "src/components_v2/card/table-card";
import CollectionThumbnail from "@features/thumbnail/collection-thumbnail";
import { clearAssetList, createCollection, getAssetsList } from "@modules/collection";
import { setNotification } from "@modules/notification";
import AssetThumbnail from "@features/thumbnail/asset-thumbnail";
import AssetTypeFlag from "@components_v2/icon-type/asset/asset-type-flag";
import { AutocompleteTable } from "@components_v2/autocomplete";
import AutocompleteTableList from "@features/autocomplete-table/autocomplete-table-list";
import validateInput, { convertValidationMessage } from "@cores/validateInput";
import { AUTOCOMPLETE_TABLE_LIST_TYPE, LIMIT_BYTE, MEDIA_TYPES } from "@constants";
import getByteLength from "@cores/getByteLength";
import { CollectionColumn } from "@features/autocomplete-table/autocomplete-table-columns/autocomplete-table-columns-collections";
import { LinkedAssetsColumn } from "@features/autocomplete-table/autocomplete-table-columns/autocomplete-table-columns-assets";
import TooltipButton from "../../components_v2/tooltip-button/tooltip-button";
import SkeletonAssetsTable from "@features/autocomplete-table/autocomplete-table-skeleton/autocomplete-table-skeleton-assets";
import SkeletonCollectionsTable from "@features/autocomplete-table/autocomplete-table-skeleton/autocomplete-table-skeleton-collections";
import { ErrorAlert, StyledErrorAlert } from "../../components_v2/alert/alert.styled";
import Tooltip from "@components_v2/Tooltip/tooltip";
import { StyledGrid } from "@components_v2/grid/grid.styled";
import { Breadcrumbs } from "@mui/material";
import useVirtualFolderPath from "@hooks/useVirtualFolderPath";
import compareVersions, { VERSION_CM_11164_COLLECTION_FOLDER_VIEW } from "@cores/version";

export const DialogCreateCollection = ({ open, assetItems, onClose, onRefresh, assetFolder }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const MAX_ASSETS = 1000;
    const FILTER_LIST = [{ id: "mediaType", label: null, title: "Media type", value: null, type: "types" }];
    const { getLocalStorageRecentLocation, setLocalStorageRecentLocation } = useVirtualFolderPath({
        domain: "COLLECTION",
    });

    const [limit, setLimit] = useState(20);
    const [offset, setOffset] = useState(0);
    const [assetFolderDialogOpen, setAssetFolderDialogOpen] = useState(false);
    const [tags, setTags] = useState([]);
    const [pendingCreate, setPendingCreate] = useState(false);
    const [selectedCollections, setSelectedCollections] = useState([]);
    const [selectedAssets, setSelectedAssets] = useState(assetItems ?? []);
    const [assetsSelectType, setAssetsSelectType] = useState(assetFolder ? "folder" : "search");
    const [assetVirtualFolder, setAssetVirtualFolder] = useState(
        assetFolder ? { id: assetFolder.id, name: assetFolder.name, recursive: true } : null,
    );
    const [description, setDescription] = useState({ validationErrors: null, value: null });
    const [name, setName] = useState({ validationErrors: null, value: null });
    const [editValue, setEditValue] = useState(
        assetFolder
            ? {
                  items: {
                      virtualFolder: { id: assetFolder.id, recursive: true },
                  },
              }
            : null,
    );
    const [assetSearchValue, setAssetSearchValue] = useState({ searchedValue: null, debouncedValue: null });
    const [collectionSearchValue, setCollectionSearchValue] = useState({ searchedValue: null, debouncedValue: null });
    const [folderLocation, setFolderLocation] = useState([]);
    const [recentLocation, setRecentLocation] = useState([]);
    const [modal, setModal] = useState("");

    const assets = useSelector(({ collection }) => collection.assets);
    const project = useSelector(({ project }) => project);
    const collections = useSelector(({ collections }) => collections.create);
    const collection = useSelector(({ collection }) => collection);
    const stage = useSelector(({ stage }) => stage);

    const onHandleChangeAssets = (checkedList) => {
        const removeDuplicateAssets = checkedList.filter(
            (asset) => selectedAssets.findIndex((selected) => selected.id === asset.id) < 0,
        );

        setSelectedAssets(selectedAssets.concat(removeDuplicateAssets));
    };

    const onHandleChangeAssetSelectType = (e) => {
        setAssetsSelectType(e.target.value);
    };

    const isAssetsCountError = useMemo(() => {
        return selectedAssets.length > MAX_ASSETS;
    }, [selectedAssets]);
    const onHandleChangeCollections = (checkedList) => {
        setSelectedCollections(checkedList);
    };

    const getAssetTableValueByColumn = useCallback((columnId, option) => {
        if (columnId === "name") {
            let src = option.previews?.[0]?.accessUrl;
            if (!src) {
                if (option?.mediaType === MEDIA_TYPES.IMAGE) {
                    src = option.accessUrls?.[0] ?? option.elements?.[0]?.accessUrl;
                } else if (option.mediaType === MEDIA_TYPES.VIDEO) src = option.thumbnails?.[0]?.accessUrl;
            }

            return (
                <TableCard
                    thumbnail={
                        <AssetThumbnail
                            variant={"table"}
                            src={src}
                            width={52}
                            height={52}
                            mediaType={option.mediaType}
                        />
                    }
                    name={{
                        name: (
                            <Typography variant={"body1_500"} component={"span"}>
                                {option?.name}
                            </Typography>
                        ),
                        startIcon: <AssetTypeFlag width={22} height={16} type={option.mediaType} />,
                    }}
                    subName={{
                        name: (
                            <Typography variant={"body3_400"} component={"span"} color={theme.palette.secondary[600]}>
                                {option?.extensions && `.${option?.extensions}`}
                            </Typography>
                        ),
                    }}
                />
            );
        }
        if (columnId === "mediaType") return option?.mediaType;
        if (columnId === "size") return option?.size ? convertToByte(option?.size) : "-";
        return null;
    }, []);

    const getCollectionTableValueByColumn = useCallback((columnId, option) => {
        if (columnId === "name")
            return (
                <TableCard
                    thumbnail={<CollectionThumbnail collection={option} size={"small"} variant={"table"} />}
                    name={{
                        name: (
                            <Typography variant={"body1_500"} component={"span"}>
                                {option?.name}
                            </Typography>
                        ),
                    }}
                />
            );
        if (columnId === "count")
            return (
                <Grid item>
                    <Badge
                        sx={{
                            ".MuiBadge-badge": {
                                position: `static`,
                                minWidth: "30px",
                                height: 18,
                                transform: `none`,
                                backgroundColor: option?.assetTotalCount
                                    ? theme.palette.success[600]
                                    : theme.palette.secondary[500],
                                color: theme.palette.common.white,
                                fontSize: theme.typography.body3_700,
                            },
                        }}
                        badgeContent={option?.assetTotalCount}
                    />
                </Grid>
            );
        return null;
    }, []);

    const onHandleCreateCollection = async () => {
        const response = await dispatch(createCollection(editValue));
        try {
            dispatch(
                setNotification({
                    type: "success",
                    contents: t(
                        `common::msg::Collection '{{name}}' has been added.`,
                        `Collection '{{name}}' has been added.`,
                        {
                            name: response?.data?.name,
                        },
                    ),
                }),
            );
            updateRecentLocation();
            onRefresh && onRefresh(); //refresh collections
            onClose();
        } catch (error) {
            dispatch(
                setNotification({
                    type: "error",
                    contents: t(
                        `common::msg::Error adding collection '{{name}}'. {{line}} Please refresh the page and try again.`,
                        `Error adding collection '{{name}}'. {{line}} Please refresh the page and try again.`,
                        {
                            name: response?.data?.name,
                            line: "\n",
                        },
                    ),
                }),
            );
        }
    };

    const onHandleSearchCollection = (value) => {
        if (value !== collectionSearchValue.searchedValue) {
            setCollectionSearchValue({ ...collectionSearchValue, searchedValue: value });
        }
    };

    const onHandleSearchAsset = (value) => {
        if (value !== assetSearchValue.searchedValue) {
            setAssetSearchValue({ ...assetSearchValue, searchedValue: value });
        }
    };

    const onHandleDeleteAsset = (id) => {
        const newAssets = selectedAssets.filter((asset) => asset.id !== id);
        setSelectedAssets(newAssets);
    };

    const onHandleDeleteCollection = (id) => {
        const newCollections = selectedCollections.filter((collection) => collection.id !== id);
        setSelectedCollections(newCollections);
    };

    const onHandleFetchCollection = () => {
        if (!collections?.nextToken || collections.pending) return;
        const params = { name: assetSearchValue.debouncedValue, nextToken: collections.nextToken };
        dispatch(getCollectionsModal(params));
    };

    const onHandleFetchAsset = () => {
        if (assets?.data?.length >= assets?.totalCount || assets.pending) return;
        const newOffset = offset + limit;
        setOffset(newOffset);
        const params = { name: assetSearchValue.debouncedValue, limit, offset: newOffset };
        dispatch(getAssetsList(project.id, params));
    };

    // const onUpdateFilterParams = (params) => {
    //     if (params.constructor === Object && Object.keys(params).length === 0) {
    //         dispatch(updateFilteredAssets(assets.originData));
    //     } else {
    //         onFilterAssetList(params);
    //     }
    // };
    //
    // const onFilterAssetList = (params) => {
    //     if (!params) return;
    //     Object.entries(params)
    //         .filter(([key, value]) => value?.length)
    //         .map(([key, value]) => {
    //             const values = value?.map(({ value }) => value);
    //             const filteredData = assets.originData.filter((item) => values.includes(item[key]));
    //             dispatch(updateFilteredAssets(filteredData));
    //         });
    // };

    const onHandleChangeRecentLocation = (e) => {
        const locationId = e.target.value;
        const locationInfo = recentLocation?.find((el) => el.value === locationId);
        const locations = locationInfo?.label?.split("/").map((el) => ({ name: el }));
        setEditValue({ ...editValue, virtualFolder: { id: locationId } });
        setFolderLocation(locations);
    };

    const updateRecentLocation = useCallback(() => {
        const folderId = editValue.virtualFolder?.id;
        setLocalStorageRecentLocation({ folderId });
    }, [recentLocation, collections, folderLocation]);

    const onHandleSubmitFolderPath = ({ folderId, folderPath }) => {
        const fLocation = folderPath?.map((item) => {
            return { name: item.name };
        });
        setEditValue({ ...editValue, virtualFolder: { id: folderId } });
        setFolderLocation(fLocation);
        onToggleModal();
    };

    const onToggleModal = (key, value) => {
        if (value) {
            setModal(key);
        } else {
            setModal("");
        }
    };

    const getFolderLocation = useMemo(() => {
        return folderLocation?.map((item, index) => {
            if (index === 0) return { ...item, icon: <ImageDriveOn width={16} height={16} /> };
            else if (index === folderLocation?.length - 1)
                return { ...item, icon: <ImageFolderSmallOn height={16} width={16} /> };
            return item;
        });
    }, [folderLocation]);

    const onHandleFolderPath = ({ folderId, folderName, includeSubFolders }) => {
        setAssetVirtualFolder({ id: folderId, name: folderName, recursive: includeSubFolders });
        setEditValue({
            ...editValue,
            items: {
                virtualFolder: { id: folderId, recursive: includeSubFolders },
            },
        });
        setAssetFolderDialogOpen(false);
    };

    const getRecentLocations = async () => {
        const recentLocations = await getLocalStorageRecentLocation();
        setRecentLocation(recentLocations);
    };

    useEffect(() => {
        if (!open) return;
        getRecentLocations();
    }, []);

    useEffect(() => {
        const searchedValue = collectionSearchValue.searchedValue;
        if (searchedValue === null) return;
        const debounce = setTimeout(() => {
            return setCollectionSearchValue({ ...collectionSearchValue, debouncedValue: searchedValue });
        }, 300);
        return () => clearTimeout(debounce);
    }, [collectionSearchValue.searchedValue]);

    useEffect(() => {
        const searchedValue = assetSearchValue.searchedValue;
        if (searchedValue === null) return;
        const debounce = setTimeout(() => {
            return setAssetSearchValue({ ...assetSearchValue, debouncedValue: searchedValue });
        }, 300);
        return () => clearTimeout(debounce);
    }, [assetSearchValue.searchedValue]);

    useEffect(() => {
        dispatch(clearAssetList());
        setOffset(0);
        const params = { name: assetSearchValue.debouncedValue, limit, offset: 0 };
        dispatch(getAssetsList(project.id, params));
    }, [assetSearchValue.debouncedValue]);

    useEffect(() => {
        dispatch(clearModalList());
        const params = { name: collectionSearchValue.debouncedValue };
        dispatch(getCollectionsModal(params));
    }, [collectionSearchValue.debouncedValue]);

    useEffect(() => {
        if (name.validationErrors === null) return;
        if (!description.validationErrors) setEditValue({ ...editValue, description: description.value });
    }, [description]);

    useEffect(() => {
        if (!name.validationErrors) setEditValue({ ...editValue, name: name.value });
    }, [name]);

    useEffect(() => {
        if (selectedCollections.length === 0) return;

        setEditValue({
            ...editValue,
            linkedCollections: selectedCollections.map((item) => item.id),
        });
    }, [selectedCollections]);

    useEffect(() => {
        if (selectedAssets.length === 0) return;

        setEditValue({
            ...editValue,
            primaryAsset: { id: selectedAssets[0].id },
            items: {
                assetIds: selectedAssets.map((asset) => asset.id),
            },
        });
    }, [selectedAssets]);

    return (
        <>
            {assetFolderDialogOpen && (
                <SelectFolderPathModal
                    open
                    domain={"Asset"}
                    submitText={`Select`}
                    width={"600px"}
                    title={"Select assets in a specific folder?"}
                    subFoldersOption={true}
                    useNewFolder={false}
                    onSubmit={onHandleFolderPath}
                    onClose={() => setAssetFolderDialogOpen(false)}
                />
            )}
            {modal === "selectFolderPath" && (
                <SelectFolderPathModal
                    open
                    domain={"Collection"}
                    submitText={`Select`}
                    width={"600px"}
                    title={"Select collections in a specific folder?"}
                    subFoldersOption={false}
                    useNewFolder={false}
                    onSubmit={onHandleSubmitFolderPath}
                    onClose={() => onToggleModal()}
                />
            )}
            <Dialog
                onClose={onClose}
                open={open}
                variant="expandable"
                sx={{ ".MuiPaper-root": { maxWidth: "1240px", minWidth: "1095px" } }}
            >
                <DialogTitle>
                    <Grid container>
                        <Typography variant="h3">{t(`common::label::Add Collection`)}</Typography>
                        <Link
                            underline="hover"
                            fontSize={14}
                            fontWeight={400}
                            ml={theme.spacing(1.5)}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                        >
                            {t(`common::label::info`, `info`)}
                        </Link>
                    </Grid>
                    <TooltipButton
                        title={t(`common::label::Close`, "Close")}
                        onClickButton={onClose}
                        buttonIcon={<IconClose size={20} htmlColor={theme.palette.secondary[700]} />}
                    />
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ mb: 3 }}>
                        <Grid container gap={3}>
                            <Grid container alignItems="start" flexDirection={`column`} gap={1}>
                                <Typography
                                    variant={`body1_500`}
                                    component={"p"}
                                    color={theme.palette.secondary[800]}
                                    required={true}
                                >
                                    {t(`common::label::Name`)}
                                </Typography>
                                <Grid item sx={{ width: `-webkit-fill-available` }}>
                                    <TextField
                                        fullWidth
                                        helperText={
                                            name.validationErrors?.type
                                                ? t(convertValidationMessage(name.validationErrors?.type))
                                                : ""
                                        }
                                        error={name.validationErrors}
                                        placeholder={t("common::msg::Please enter the name.", "Please enter the name.")}
                                        variant="outlined"
                                        onChange={(e) => {
                                            e.stopPropagation();
                                            setName({
                                                value: e.target.value,
                                                validationErrors: validateInput(e.target.value, {
                                                    required: true,
                                                }),
                                            });
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container alignItems="start" flexDirection={`column`} gap={1}>
                                <Typography variant={`body1_500`} component={"p"} color={theme.palette.secondary[800]}>
                                    {t(`common::label::Description`)}
                                </Typography>
                                <Box>
                                    <FormControl fullWidth variant="outlined">
                                        <TextField
                                            sx={{
                                                minWidth: "584px",
                                            }}
                                            rows={1}
                                            helperText={description.validationErrors?.message}
                                            multiline
                                            placeholder={t(
                                                "common::msg::Please enter the description.",
                                                "Please enter the description.",
                                            )}
                                            variant="outlined"
                                            error={description.validationErrors}
                                            onChange={(e) => {
                                                e.stopPropagation();
                                                setDescription({
                                                    value: e.target.value,
                                                    validationErrors: validateInput(e.target.value, {
                                                        maxByte: LIMIT_BYTE.name,
                                                    }),
                                                });
                                            }}
                                        />
                                        <FormHelperText>
                                            <Grid alignItems="center" component="span" container gap={1}>
                                                {getByteLength(description.value)} / 255 bytes
                                            </Grid>
                                        </FormHelperText>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid container alignItems="start" flexDirection={`column`} gap={1}>
                                <Typography variant={`body1_500`} component={"p"} color={theme.palette.secondary[800]}>
                                    {t(`common::label::Assets`)}
                                </Typography>
                                <Grid
                                    item
                                    sx={{
                                        width: `-webkit-fill-available`,
                                        p: 2.5,
                                        borderRadius: `4px`,
                                        border: `1px solid ${theme.palette.secondary[100]}`,
                                    }}
                                >
                                    <Grid container direction={"column"}>
                                        <Grid item>
                                            <FormControl>
                                                <RadioGroup
                                                    row
                                                    value={assetsSelectType}
                                                    onChange={onHandleChangeAssetSelectType}
                                                >
                                                    <RadioFormControlLabel
                                                        control={<Radio color="secondary" />}
                                                        label={t(
                                                            `common::label::Select assets through search`,
                                                            `Select assets through search`,
                                                        )}
                                                        value="search"
                                                        // disabled={isAutoLockDisabled}
                                                    />
                                                    <RadioFormControlLabel
                                                        control={<Radio color="secondary" />}
                                                        label={t(
                                                            `common::label::Select assets in a specific folder`,
                                                            `Select assets in a specific folder`,
                                                        )}
                                                        value="folder"
                                                        // disabled={isAutoLockDisabled}
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        <Divider sx={{ mt: 1.5, mb: 2.5, borderColor: `#dfdfee` }} />
                                        {assetsSelectType === "search" && (
                                            <>
                                                <Grid item>
                                                    <AutocompleteTable
                                                        domain={AUTOCOMPLETE_TABLE_LIST_TYPE.AUTOCOMPLETE_TABLE_ASSET}
                                                        type={"table"}
                                                        key={"autocomplete-asset-table"}
                                                        nextOptions={assets?.data?.length < assets?.totalCount}
                                                        placement={"bottom-end"}
                                                        placeholder={t(
                                                            `common::msg::Please search by asset name or ID.`,
                                                        )}
                                                        onSubmit={onHandleChangeAssets}
                                                        columns={LinkedAssetsColumn}
                                                        skeletonColumns={
                                                            <SkeletonAssetsTable
                                                                columns={LinkedAssetsColumn.filter(
                                                                    (item) => item.useInAutocomplete,
                                                                )}
                                                            />
                                                        }
                                                        multiSelect={true}
                                                        options={assets?.data ?? []}
                                                        isPending={assets?.pending}
                                                        selectedItems={selectedAssets}
                                                        optionsTotalCount={assets?.totalCount}
                                                        getValueByColumn={getAssetTableValueByColumn}
                                                        onSearch={onHandleSearchAsset}
                                                        onFetchData={onHandleFetchAsset}
                                                        filter={FILTER_LIST}
                                                    />
                                                </Grid>

                                                <Grid item mt={2} mb={1}>
                                                    <Typography
                                                        variant={"body1_500"}
                                                        color={theme.palette.secondary[800]}
                                                    >
                                                        {t(`common::label::Selected Assets`)} (
                                                        <Typography
                                                            variant={"body1_500"}
                                                            color={
                                                                selectedAssets.length > MAX_ASSETS
                                                                    ? "#ef4016"
                                                                    : theme.palette.primary[400]
                                                            }
                                                        >
                                                            {selectedAssets.length}
                                                        </Typography>
                                                        /{MAX_ASSETS} {t(`common::label::max`)})
                                                    </Typography>
                                                </Grid>

                                                {isAssetsCountError && (
                                                    <Grid item mb={1}>
                                                        <StyledErrorAlert
                                                            icon={
                                                                <IconInfoCircle
                                                                    size={16}
                                                                    htmlColor={theme.palette.error[700]}
                                                                />
                                                            }
                                                            color="error"
                                                            variant="outlined"
                                                        >
                                                            <Typography
                                                                variant={"body1_400"}
                                                                sx={{ color: theme.palette.secondary[800] }}
                                                            >
                                                                <Trans
                                                                    i18nKey="common::msg::Size of files Option : If you choose your own assets, you can have up to {{maxAssets}}. <NewLine/> Keep the number of selected assets under {{maxAssets}}."
                                                                    values={{ maxAssets: MAX_ASSETS }}
                                                                    components={{
                                                                        NewLine: <Typography />,
                                                                    }}
                                                                />
                                                            </Typography>
                                                        </StyledErrorAlert>
                                                    </Grid>
                                                )}
                                                <AutocompleteTableList
                                                    type={"ASSET"}
                                                    data={[{ value: selectedAssets }]}
                                                    columns={LinkedAssetsColumn}
                                                    menuActions={(item) => (
                                                        <Tooltip title={t(`common::label::Delete`)}>
                                                            <IconButton
                                                                size="medium"
                                                                circled
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    onHandleDeleteAsset(item.id);
                                                                }}
                                                            >
                                                                <IconCancel
                                                                    size={12}
                                                                    htmlColor={theme.palette.grey[700]}
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                    noDataContent={
                                                        <Grid
                                                            container
                                                            sx={{
                                                                height: "60px",
                                                                p: 1.5,
                                                                borderRadius: `4px`,
                                                                border: `1px solid ${theme.palette.secondary[100]}`,
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <Typography>
                                                                {t(
                                                                    `common::msg::Select assets through search to put them in your collection.`,
                                                                    `Select assets through search to put them in your collection.`,
                                                                )}
                                                            </Typography>
                                                        </Grid>
                                                    }
                                                    divider={true}
                                                    gridStyle={{ maxHeight: "336px" }}
                                                />
                                            </>
                                        )}
                                        {assetsSelectType === "folder" && (
                                            <>
                                                <Grid item>
                                                    <Button
                                                        animation={"wave"}
                                                        animationtarget="endIcon"
                                                        animationtype="toRight"
                                                        color="secondary"
                                                        endIcon={<IconOpenInNew size={16} />}
                                                        label="Button"
                                                        onClick={() => setAssetFolderDialogOpen(true)}
                                                        size="medium"
                                                        type="button"
                                                        variant="outlined"
                                                    >
                                                        {t(
                                                            `common::label::Select assets in a specific folder`,
                                                            `Select assets in a specific folder`,
                                                        )}
                                                    </Button>
                                                </Grid>
                                                <Grid item mt={2} mb={1}>
                                                    <Typography>{t(`common::label::Selected a Folder`)}</Typography>
                                                </Grid>
                                                {assetVirtualFolder?.id ? (
                                                    <Grid
                                                        item
                                                        sx={{
                                                            p: 1.5,
                                                            borderRadius: `4px`,
                                                            border: `1px solid ${theme.palette.secondary[100]}`,
                                                        }}
                                                    >
                                                        <Grid container alignItems={"center"}>
                                                            <Grid item mr={1}>
                                                                <ImageFolderSmallOn width={16} height={16} />
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography
                                                                    variant={"body1_400"}
                                                                    color={theme.palette.secondary[800]}
                                                                >
                                                                    {t(
                                                                        `common::label::${assetVirtualFolder?.name}`,
                                                                        assetVirtualFolder?.name,
                                                                    )}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container alignItems={"center"} ml={1.5}>
                                                            <Grid item>
                                                                <Switch
                                                                    color="success"
                                                                    checked={assetVirtualFolder?.recursive}
                                                                    onChange={(e) => {
                                                                        e.stopPropagation();
                                                                        setEditValue({
                                                                            ...editValue,
                                                                            items: {
                                                                                virtualFolder: {
                                                                                    id: assetVirtualFolder?.id,
                                                                                    recursive: e.target.checked,
                                                                                },
                                                                            },
                                                                        });
                                                                        setAssetVirtualFolder({
                                                                            ...assetVirtualFolder,
                                                                            recursive: e.target.checked,
                                                                        });
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography
                                                                    variant="body1_400"
                                                                    color={theme.palette.secondary[800]}
                                                                >
                                                                    {assetVirtualFolder?.recursive
                                                                        ? t(
                                                                              `common::label::${"Also include assets from subfolders"}`,
                                                                              "Also include assets from subfolders",
                                                                          )
                                                                        : t(
                                                                              `common::label::${"Only assets in that folder are selected"}`,
                                                                              "Only assets in that folder are selected",
                                                                          )}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                ) : (
                                                    <Grid
                                                        item
                                                        sx={{
                                                            height: "60px",
                                                            p: 1.5,
                                                            borderRadius: `4px`,
                                                            border: `1px solid ${theme.palette.secondary[100]}`,
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        <Typography>
                                                            {t(
                                                                `common::msg::Select the folder containing the assets you want to put in your collection.`,
                                                            )}
                                                        </Typography>
                                                    </Grid>
                                                )}
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            {compareVersions(stage.version, VERSION_CM_11164_COLLECTION_FOLDER_VIEW) >= 0 && (
                                <Grid container alignItems="start" flexDirection={`column`} gap={1}>
                                    <Typography
                                        variant={`body1`}
                                        component={"p"}
                                        color={theme.palette.secondary[800]}
                                        required={true}
                                    >
                                        {t(`common::label::Location`)}
                                    </Typography>
                                    <Divider orientation="vertical" flexItem />
                                    <StyledGrid item theme={theme}>
                                        <Grid container flexDirection={"row"} gap={1.5}>
                                            <Grid item>
                                                <Button
                                                    animation={"wave"}
                                                    animationtarget="endIcon"
                                                    animationtype="toRight"
                                                    color="secondary"
                                                    endIcon={<IconOpenInNew size={16} />}
                                                    label="Button"
                                                    onClick={() => setModal("selectFolderPath")}
                                                    size="medium"
                                                    type="button"
                                                    variant="outlined"
                                                >
                                                    {t(
                                                        `common::label::Specify Folder Location`,
                                                        `Specify Folder Location`,
                                                    )}
                                                </Button>
                                            </Grid>
                                            <Divider orientation="vertical" flexItem />
                                            <Grid item>
                                                <Select
                                                    displayEmpty
                                                    onChange={(e) => onHandleChangeRecentLocation(e)}
                                                    size="medium"
                                                    sx={{
                                                        width: 235,
                                                    }}
                                                    value=""
                                                >
                                                    <MenuItem
                                                        disabled
                                                        sx={{
                                                            display: "none",
                                                        }}
                                                        value=""
                                                    >
                                                        <Typography
                                                            variant={"body1"}
                                                            color={theme.palette.secondary[800]}
                                                        >
                                                            {t(
                                                                `common::label::Select by Recent Location`,
                                                                `Select by Recent Location`,
                                                            )}
                                                        </Typography>
                                                    </MenuItem>
                                                    {recentLocation?.map((option, index) => (
                                                        <MenuItem key={`recent-location-${index}`} value={option.value}>
                                                            <IconHistory color="grey" size={16} />
                                                            <Typography component="span" variant="subtitle2">
                                                                {option.label}
                                                            </Typography>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </Grid>
                                        </Grid>
                                        <Divider sx={{ mt: 1.5, mb: 2.5 }} />
                                        <Grid container alignItems="center">
                                            {folderLocation?.length > 0 ? (
                                                <Grid
                                                    item
                                                    sx={{
                                                        alignSelf: "flex-start",
                                                    }}
                                                >
                                                    <Breadcrumbs separator="›">
                                                        {getFolderLocation?.map((folder, index) => (
                                                            <Grid
                                                                key={`folder-location-${index}`}
                                                                container
                                                                flexDirection={"row"}
                                                                alignItems={"center"}
                                                                gap={0.5}
                                                            >
                                                                {folder.icon}
                                                                <Typography color="text.secondary" variant="body3">
                                                                    {folder.name}
                                                                </Typography>
                                                            </Grid>
                                                        ))}
                                                    </Breadcrumbs>
                                                </Grid>
                                            ) : (
                                                <Grid
                                                    container
                                                    alignItems="center"
                                                    flexDirection={"row"}
                                                    gap={1}
                                                    sx={{ flexWrap: "nowrap" }}
                                                >
                                                    <Grid
                                                        container
                                                        alignItems="center"
                                                        flexDirection={"row"}
                                                        gap={1}
                                                        sx={{
                                                            width: "fit-content",
                                                            flexWrap: "nowrap",
                                                            alignSelf: "flex-start",
                                                        }}
                                                    >
                                                        <Grid
                                                            item
                                                            sx={{ marginBottom: "3px", alignSelf: "flex-start" }}
                                                        >
                                                            <ImageDriveOn width={16} height={16} />
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography
                                                                variant={"body1"}
                                                                color={theme.palette.secondary[800]}
                                                            >
                                                                {t(`common::label::Drive`, `Drive`)}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography
                                                                variant={"body1"}
                                                                color={theme.palette.secondary[400]}
                                                            >
                                                                &gt;
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item sx={{ minWidth: "420px" }}>
                                                        <Typography
                                                            variant={"body1"}
                                                            color={theme.palette.secondary[400]}
                                                        >
                                                            {t(
                                                                `common::label::Specify the folder where the assets will be located. If not specified, it will be saved in the top path.`,
                                                            )}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </StyledGrid>
                                </Grid>
                            )}
                            <Grid container alignItems="start" flexDirection={`column`} gap={1}>
                                <Typography variant={`body1_500`} component={"p"} color={theme.palette.secondary[800]}>
                                    {t(`common::label::Metadata`)}
                                </Typography>
                                <Grid
                                    item
                                    sx={{
                                        width: `-webkit-fill-available`,
                                        padding: "20px",
                                        borderRadius: `4px`,
                                        border: `1px solid ${theme.palette.secondary[100]}`,
                                    }}
                                >
                                    <Grid container alignItems={"center"}>
                                        <Grid
                                            item
                                            sx={{ alignSelf: "flex-start", minWidth: "244px" }}
                                            alignItems={"center"}
                                        >
                                            <Typography variant={"body1_500"} component={"p"}>
                                                {t(`common::label::Tags`, `Tags`)}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            sx={{
                                                flex: 1,
                                                alignSelf: "flex-start",
                                            }}
                                        >
                                            <TagsInput
                                                tags={tags}
                                                isEdit={false}
                                                disabled={pendingCreate}
                                                onDeleteTag={(index) => {
                                                    setTags(tags.filter((t, idx) => idx !== index));
                                                    setEditValue({
                                                        ...editValue,
                                                        tags: tags
                                                            .filter((t, idx) => idx !== index)
                                                            .map((item) => item.value),
                                                    });
                                                }}
                                                onAddTag={(tag) => {
                                                    setTags([...tags, tag]);
                                                    setEditValue({
                                                        ...editValue,
                                                        tags: [...tags, tag].map((item) => item.value),
                                                    });
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container alignItems="start" flexDirection={`column`} gap={1}>
                                <Typography variant={`body1_500`} component={"p"} color={theme.palette.secondary[800]}>
                                    {t(`common::label::Linked Collections`)}
                                </Typography>
                                <Grid
                                    item
                                    sx={{
                                        width: `-webkit-fill-available`,
                                        p: 2.5,
                                        borderRadius: `4px`,
                                        border: `1px solid ${theme.palette.secondary[100]}`,
                                    }}
                                >
                                    <Grid item>
                                        <AutocompleteTable
                                            domain={AUTOCOMPLETE_TABLE_LIST_TYPE.AUTOCOMPLETE_TABLE_COLLECTION}
                                            type={"table"}
                                            nextOptions={false}
                                            key={"autocomplete-collection-table"}
                                            placement={"top-end"}
                                            placeholder={t(`common::msg::Please search by collection name.`)}
                                            onSubmit={onHandleChangeCollections}
                                            columns={CollectionColumn}
                                            skeletonColumns={
                                                <SkeletonCollectionsTable
                                                    columns={CollectionColumn.filter((item) => item.useInAutocomplete)}
                                                />
                                            }
                                            multiSelect={true}
                                            options={collections?.data}
                                            isPending={collections?.pending}
                                            selectedItems={selectedCollections}
                                            getValueByColumn={getCollectionTableValueByColumn}
                                            onSearch={onHandleSearchCollection}
                                            onFetchData={onHandleFetchCollection}
                                        />
                                    </Grid>
                                    <Grid item mt={2} mb={1}>
                                        <Typography variant={"body1_500"} color={theme.palette.secondary[800]}>
                                            {t(`common::label::Selected Collections`)} (
                                            <Typography variant={"body1_500"} color={theme.palette.primary[400]}>
                                                {selectedCollections.length}
                                            </Typography>
                                            )
                                        </Typography>
                                    </Grid>

                                    <AutocompleteTableList
                                        type={"COLLECTION"}
                                        data={[{ value: selectedCollections }]}
                                        columns={CollectionColumn}
                                        menuActions={(item) => (
                                            <Tooltip title={t(`common::label::Delete`)}>
                                                <IconButton
                                                    size="medium"
                                                    circled
                                                    onClick={(e) => {
                                                        onHandleDeleteCollection(item.id);
                                                    }}
                                                >
                                                    <IconCancel size={12} htmlColor={theme.palette.grey[700]} />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        noDataContent={
                                            <Grid
                                                container
                                                sx={{
                                                    height: "60px",
                                                    p: 1.5,
                                                    borderRadius: `4px`,
                                                    border: `1px solid ${theme.palette.secondary[100]}`,
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Typography>
                                                    {t(
                                                        `common::msg::Select collections through search to put them in your collection.`,
                                                        `Select collections through search to put them in your collection.`,
                                                    )}
                                                </Typography>
                                            </Grid>
                                        }
                                        onDeleteRow={onHandleDeleteCollection}
                                        gridStyle={{ maxHeight: "336px" }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button color="secondary" onClick={onClose} variant="text">
                        {t(`common::label::Cancel`, `Cancel`)}
                    </Button>
                    <Button
                        color="primary"
                        onClick={onHandleCreateCollection}
                        variant="contained"
                        loading={collection.pending}
                        disabled={
                            name.validationErrors ||
                            !name.value ||
                            description.validationErrors ||
                            isAssetsCountError ||
                            collection.pending
                        }
                    >
                        {t(`common::label::Add`, `Add`)}
                    </Button>
                </DialogActions>
                <DialogPanel anchor="right" variant="permanent">
                    <IconButton size="medium" circled onClick={() => {}} sx={{ my: 1.5, mx: "auto" }}>
                        <IconInfoCircle size={16} />
                    </IconButton>
                </DialogPanel>
            </Dialog>
        </>
    );
};

export default DialogCreateCollection;
