import { styled, Grid as MuiGrid, alpha, Card as MuiCard } from "@mui/material";
import theme from "@styles/theme";

export const StyledAssetTypeFlag = styled("div")(({ theme }) => ({
    display: `inline-flex`,
    alignItems: `center`,
    justifyContent: `center`,
    borderRadius: `2px`,
    marginRight: `8px`,
}));
