import { handleActions, createAction } from "redux-actions";

import { sessionKeeper } from "../cores/sessionKeeper";

import { axios, axiosWithoutHeaders } from "../cores/axiosWrapper";

function createStorageAPI(stageId, apiEndpoint, projectId, body) {
    return axios.post(`${apiEndpoint}/projects/${projectId}/storages`, body, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function updateStorageAPI(stageId, apiEndpoint, projectId, body) {
    return axios.patch(`${apiEndpoint}/projects/${projectId}/storages`, body, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function getProjectAPI(stageId, apiEndpoint, id) {
    return axios.get(`${apiEndpoint}/projects/${id}`, {
        headers: {
            stageId,
            projectId: id,
        },
    });
}

function updateProjectAPI(stageId, apiEndpoint, id, data) {
    return axios.patch(`${apiEndpoint}/projects/${id}`, data, {
        headers: {
            stageId,
        },
    });
}

const PROJECT_RESET_STORAGES = "PROJECT_RESET_STORAGES";
const PROJECT_SET_PROJECT = "PROJECT_SET_PROJECT";
const PROJECT_PENDING = "PROJECT_PENDING";
const PROJECT_INPUT_STORAGE_PENDING = "PROJECT_INPUT_STORAGE_PENDING";
const PROJECT_OUTPUT_STORAGE_PENDING = "PROJECT_OUTPUT_STORAGE_PENDING";
const PROJECT_CREATE_OR_UPDATE_INPUT_STORAGE_SUCCESS = "PROJECT_CREATE_OR_UPDATE_INPUT_STORAGE_SUCCESS";
const PROJECT_CREATE_OR_UPDATE_OUTPUT_STORAGE_SUCCESS = "PROJECT_CREATE_OR_UPDATE_OUTPUT_STORAGE_SUCCESS";
const PROJECT_CREATE_OR_UPDATE_INPUT_STORAGE_FAILURE = "PROJECT_CREATE_OR_UPDATE_INPUT_STORAGE_FAILURE";
const PROJECT_CREATE_OR_UPDATE_OUTPUT_STORAGE_FAILURE = "PROJECT_CREATE_OR_UPDATE_OUTPUT_STORAGE_FAILURE";
const PROJECT_GET_SUCCESS = "PROJECT_GET_SUCCESS";
const PROJECT_GET_FAILURE = "PROJECT_GET_FAILURE";
const PROJECT_UPDATE_SUCCESS = "PROJECT_UPDATE_SUCCESS";
const PROJECT_UPDATE_FAILURE = "PROJECT_UPDATE_FAILURE";
const PROJECT_CREATE_PROJECT_PENDING = "PROJECT_CREATE_PROJECT_PENDING";
const PROEJECT_ERROR_RESET = "PROEJECT_ERROR_RESET";

//reducer
const initialState = {
    id: "",
    name: "",
    pending: false,
    error: false,
    totalCount: 0,
    list: [],
    params: {
        offset: 0,
        limit: 50,
    },
    inputStorage: null,
    outputStorage: null,
    inputStoragePending: false,
    outputStoragePending: false,
    isProjectResourceSuccess: false,
    isIntegratedSearchable: null,
};

export default handleActions(
    {
        [PROJECT_RESET_STORAGES]: (state) => {
            return {
                ...state,
                inputStorage: null,
                outputStorage: null,
                inputStoragePending: false,
                outputStoragePending: false,
            };
        },
        [PROJECT_PENDING]: (state) => {
            return {
                ...state,
                pending: true,
                error: false,
            };
        },
        [PROJECT_INPUT_STORAGE_PENDING]: (state) => {
            return {
                ...state,
                inputStoragePending: true,
                inputStorage: {
                    data: null,
                    error: "",
                },
            };
        },
        [PROJECT_CREATE_PROJECT_PENDING]: (state) => {
            return {
                ...state,
                createProejctPending: true,
                inputStorage: null,
                outputStorage: null,
            };
        },
        [PROJECT_OUTPUT_STORAGE_PENDING]: (state) => {
            return {
                ...state,
                outputStoragePending: true,
                outputStorage: {
                    data: null,
                    error: "",
                },
            };
        },
        [PROJECT_SET_PROJECT]: (state, action) => {
            const project = action.payload;
            return {
                ...state,
                pending: false,
                id: project.id,
                name: project.name,
            };
        },
        [PROJECT_CREATE_OR_UPDATE_INPUT_STORAGE_SUCCESS]: (state, action) => {
            let { data } = action.payload;

            return {
                ...state,
                inputStoragePending: false,
                inputStorage: {
                    data,
                    error: "",
                },
            };
        },
        [PROJECT_CREATE_OR_UPDATE_OUTPUT_STORAGE_SUCCESS]: (state, action) => {
            let { data } = action.payload;

            return {
                ...state,
                pending: false,
                outputStoragePending: false,
                outputStorage: {
                    data,
                    error: "",
                },
            };
        },
        [PROJECT_CREATE_OR_UPDATE_INPUT_STORAGE_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                inputStoragePending: false,
                inputStorage: {
                    data: null,
                    error: action.payload,
                },
                error: true,
            };
        },
        [PROJECT_CREATE_OR_UPDATE_OUTPUT_STORAGE_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                outputStoragePending: false,
                outputStorage: {
                    data: null,
                    error: action.payload,
                },
                error: true,
            };
        },
        [PROJECT_GET_SUCCESS]: (state, action) => {
            let { data } = action.payload;
            return {
                ...state,
                isIntegratedSearchable: data.isIntegratedSearchable,
            };
        },
        [PROJECT_UPDATE_SUCCESS]: (state, action) => {
            let { data } = action.payload;
            return {
                ...state,
                isIntegratedSearchable: data.isIntegratedSearchable,
                error: false,
                pending: false,
            };
        },
        [PROJECT_UPDATE_FAILURE]: (state, action) => {
            let { data } = action.payload;
            return {
                ...state,
                // isIntegratedSearchable : data.isIntegratedSearchable,
                error: true,
                pending: false,
            };
        },
        [PROEJECT_ERROR_RESET]: (state, action) => {
            return {
                ...state,
                error: false,
            };
        },
    },
    initialState,
);

export const resetStorages = createAction(PROJECT_RESET_STORAGES);

export const createInputStorage = (stageId, apiEndpoint, projectId, body) => (dispatch, getState) => {
    dispatch({ type: PROJECT_INPUT_STORAGE_PENDING });
    return createStorageAPI(stageId, apiEndpoint, projectId, body)
        .then((response) => {
            dispatch({
                type: PROJECT_CREATE_OR_UPDATE_INPUT_STORAGE_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: PROJECT_CREATE_OR_UPDATE_INPUT_STORAGE_FAILURE,
                payload: error,
            });
        });
};

export const createOutputStorage = (stageId, apiEndpoint, projectId, body) => (dispatch, getState) => {
    dispatch({ type: PROJECT_OUTPUT_STORAGE_PENDING });
    return createStorageAPI(stageId, apiEndpoint, projectId, body)
        .then((response) => {
            dispatch({
                type: PROJECT_CREATE_OR_UPDATE_OUTPUT_STORAGE_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: PROJECT_CREATE_OR_UPDATE_OUTPUT_STORAGE_FAILURE,
                payload: error,
            });
        });
};

export const updateInputStorage = (stageId, apiEndpoint, projectId, body) => (dispatch, getState) => {
    dispatch({ type: PROJECT_INPUT_STORAGE_PENDING });
    return updateStorageAPI(stageId, apiEndpoint, projectId, body)
        .then((response) => {
            dispatch({
                type: PROJECT_CREATE_OR_UPDATE_INPUT_STORAGE_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: PROJECT_CREATE_OR_UPDATE_INPUT_STORAGE_FAILURE,
                payload: error,
            });
        });
};

export const updateOutputStorage = (stageId, apiEndpoint, projectId, body) => (dispatch, getState) => {
    dispatch({ type: PROJECT_OUTPUT_STORAGE_PENDING });
    return updateStorageAPI(stageId, apiEndpoint, projectId, body)
        .then((response) => {
            dispatch({
                type: PROJECT_CREATE_OR_UPDATE_OUTPUT_STORAGE_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: PROJECT_CREATE_OR_UPDATE_OUTPUT_STORAGE_FAILURE,
                payload: error,
            });
        });
};

export const setProject = (newProject) => (dispatch, getState) => {
    const { project } = getState();

    if (project.id === newProject.id) {
        return;
    }
    sessionKeeper.set({ project: newProject });
    dispatch({
        type: PROJECT_SET_PROJECT,
        payload: newProject,
    });
};

export const getProject = (stageId, apiEndpoint, projectId) => (dispatch, getState) => {
    const { project } = getState();

    return getProjectAPI(stageId, apiEndpoint, projectId)
        .then((response) => {
            dispatch({
                type: PROJECT_GET_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: PROJECT_GET_FAILURE,
                payload: error,
            });
        });
};

export const updateProject = (stageId, apiEndpoint, projectId, data) => (dispatch, getState) => {
    const { project } = getState();

    // dispatch({type: PROJECT_PENDING});

    return updateProjectAPI(stageId, apiEndpoint, projectId, data)
        .then((response) => {
            dispatch({
                type: PROJECT_UPDATE_SUCCESS,
                payload: response,
            });

            return response;
        })
        .catch((error) => {
            dispatch({
                type: PROJECT_UPDATE_FAILURE,
                payload: error,
            });
            return error;
        });
};

export const projectErrorReset = () => (dispatch, action) => dispatch({ type: PROEJECT_ERROR_RESET });
