import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";
import { bindActionCreators } from "redux";
import moment from "moment";

import convertToByte from "../../../cores/convertToByte";
import formatDuration from "../../../cores/formatDuration";
import { siteMode } from "../../../cores/siteMode";

import FormatBadge from "../../formatbadge/FormatBadge";
import StatusBadge from "../../statusbadge/StatusBadge";
import convertToBPS from "../../../cores/convertToBPS";
import convertToHZ from "../../../cores/convertToHZ";
import convertUtcToLocal from "../../../cores/convertUtcToLocal";
import * as assetsActions from "../../../modules/assets";
import * as userManagementActions from "../../../modules/userManagement";
import * as peopleActions from "../../../modules/people";

import { ARCHIVED_ASSET_STATUS, PROPERTY_PANEL_TYPE } from "@constants";

import compareVersions, {
    VERSION_CM_4721_SHARES,
    VERSION_CM_5068_ASSET_OWNER_CHANGE,
    VERSION_CM_8339_FOLDER_VIEW,
} from "../../../cores/version";
import VersionedComponent from "../../../components/VersionedComponent";
import TooltipUserInfo from "../../../components/tooltip/userInfo";
import WithSelect from "../../../components/select/Select";
import InputField from "../../../components/input/InputField";
import ProjectInfo from "./ui/ProjectInfo";
import { result } from "lodash";
import ReactDOM from "react-dom";
import Loadable from "@react-loadable/revised";
import menuService from "@services/menuService";

const REGEXP_HTTP_PROTOCOL = new RegExp(/(http|https):\/\//, "g");
const REGEXP_S3_PROTOCOL = new RegExp(/s3:\/\//, "g");

const LoadableSelectFolderPathModal = Loadable({
    loader: () => import("../../../components/modals/SelectFolderPath"),
    loading: () => null,
});

class DomainOverview extends Component {
    constructor(props) {
        super(props);
        const owner = props.createdBy && {
            value: props.createdBy.id,
            label: props.createdBy.username,
            id: props.createdBy.id,
            name: props.createdBy.name,
            username: props.createdBy.username,
        };
        this.state = {
            phoneInfo: {
                active: false,
                phoneNumber: "-",
            },
            selectedOwner: null,
            edit: {
                owner,
            },
            isOwnerEdit: false,
            isFolderPathEdit: false,
            selectFolderModal: false,
            selectedFolder: { id: "", path: "" },
            isAlternativeNameEdit: false,
            userOptions: [],
            usersPending: false,
            alternativeNameCandidates: [],
        };
    }

    async componentDidMount() {
        if (this.props.resourceType === "ASSET") {
            this.setState({
                ...this.state,
                usersPending: true,
            });
            const userOptions = await this.getUsers();
            // console.log('userOptions :>> ', userOptions);
            this.setState({
                ...this.state,
                usersPending: false,
                userOptions,
            });
        }
    }

    getUsers = async () => {
        let offset = 0;
        const limit = 1000;
        let userOptions = [];

        const response = await this.getUserByPagination(offset, limit);
        offset++;

        const totalCount = (response && response.totalCount) || 0;
        userOptions = response && response.results;

        while (totalCount > userOptions.length) {
            const { results } = await this.getUserByPagination(offset, limit);
            offset++;
            userOptions = userOptions.concat(results);
        }
        return userOptions
            .map((v) => {
                return {
                    id: v.id,
                    name: v.name,
                    username: v.username,
                    value: v.id,
                    label: v.username,
                };
            })
            .sort(function (a, b) {
                const aUsername = a.username.toLowerCase();
                const bUsername = b.username.toLowerCase();
                return aUsername < bUsername ? -1 : aUsername > bUsername ? 1 : 0;
            });
    };

    getUserByPagination = async (offset, limit) => {
        const { project, UserManagementActions } = this.props;

        return await UserManagementActions.getUsers(
            {
                shouldRequestDetail: true,
                offset,
                limit,
            },
            project.id,
        );
    };

    getArchivedStatus = (status, t) => {
        if (status === ARCHIVED_ASSET_STATUS.ARCHIVED || status === ARCHIVED_ASSET_STATUS.ARCHIVE_ERROR) {
            return (
                <React.Fragment>
                    <div className="archive-status archive-status-archived">
                        <i className={"sprite sprite-archive-primary"}></i>
                        <span className={"text-uppercase"}>{t(`common::label::${"Archived"}`)}</span>
                        {status === ARCHIVED_ASSET_STATUS.ARCHIVE_ERROR && (
                            <span
                                className={"message-error-tooltip"}
                                data-tooltip={t(`common::msg::${"An attempt to 'Archive' failed. Please try again."}`)}
                            >
                                <i className={"sprite sprite-error"}></i>
                            </span>
                        )}
                    </div>
                </React.Fragment>
            );
        } else if (status === ARCHIVED_ASSET_STATUS.REQUEST_ARCHIVE || status === ARCHIVED_ASSET_STATUS.ARCHIVING) {
            return (
                <React.Fragment>
                    <div className={"archive-status archive-status-archiving"}>
                        <div>
                            <i className={"sprite sprite-ingesting"}></i>
                            <p>{t(`common::label::${"Archving..."}`)}</p>
                        </div>
                    </div>
                </React.Fragment>
            );
        } else if (status === ARCHIVED_ASSET_STATUS.REQUEST_RESTORE || status === ARCHIVED_ASSET_STATUS.RESTORING) {
            return (
                <div className={"archive-status archive-status-restoring"}>
                    <div>
                        <i className={"sprite sprite-progressing"}></i>
                        <p>{t(`common::label::${"Restoring"}`)}...</p>
                    </div>
                </div>
            );
        } else if (status === ARCHIVED_ASSET_STATUS.RESTORED) {
            return (
                <React.Fragment>
                    <div className="archive-status archive-status-restore">
                        <i className={"sprite sprite-restore"}></i>
                        <span className={"text-uppercase"}>{t(`common::label::${"Restored"}`)}</span>
                    </div>
                </React.Fragment>
            );
        } else if (status === ARCHIVED_ASSET_STATUS.RESTORE_ERROR) {
            return (
                <React.Fragment>
                    <div className="archive-status archive-status-restore">
                        <i className={"sprite sprite-restore"}></i>
                        <span className={"text-uppercase"}>{t(`common::label::${"Archived"}`)}</span>
                        <span
                            className={"message-error-tooltip"}
                            data-tooltip={t(`common::msg::${"An attempt to 'Restore' failed. Please try again."}`)}
                        >
                            <i className={"sprite sprite-error"}></i>
                        </span>
                    </div>
                </React.Fragment>
            );
        }
    };

    getDurationArchivedByCurrent = (archivedBy, t) => {
        const archivedTime = moment.utc(archivedBy);
        const duration = parseInt(moment.duration(moment().diff(archivedTime)).asDays());

        return (
            <React.Fragment>
                <span>
                    <strong className="text-warning">{duration > 0 ? duration : "1"}</strong>{" "}
                    {t(`common::label::${"days"}`)}
                </span>
            </React.Fragment>
        );
    };

    // NOTE: feature/1.0.15_CM-5240 사용자 정보 tooltip 공통 컴포넌트로 분리
    // togglePhoneInfoActive = async () => {
    //     const { AssetsActions, createdBy, stage } = this.props;
    //     if (compareVersions(stage.version, VERSION_CM_4721_SHARES) >= 0) {
    //         const active = !this.state.phoneInfo.active;
    //         this.setState({
    //             ...this.state,
    //             phoneInfo: {
    //                 ...this.state.phoneInfo,
    //                 phoneNumber: null,
    //                 active,
    //             },
    //         });
    //         if (createdBy && createdBy.id.toLowerCase() !== "system") {
    //             try {
    //                 if (active) {
    //                     const { megazoneUser } = await AssetsActions.getOwnerNumber(createdBy.id);
    //                     if (megazoneUser) {
    //                         this.setState({
    //                             ...this.state,
    //                             phoneInfo: {
    //                                 ...this.state.phoneInfo,
    //                                 phoneNumber: megazoneUser.phoneNumber || "-",
    //                             },
    //                         });
    //                     }
    //                 }
    //             } catch (error) {
    //                 this.setState({
    //                     ...this.state,
    //                     phoneInfo: {
    //                         ...this.state.phoneInfo,
    //                         phoneNumber: "-",
    //                     },
    //                 });
    //             }
    //         }
    //     }
    // };

    onChangeUser = (user) => {
        this.setState({
            ...this.state,
            selectedOwner: user,
        });
    };

    onClickChangeOwner = () => {
        const owner = this.state.edit.owner;
        this.props.onChangeOwner({
            id: owner.id,
            name: owner.name,
            username: owner.username,
        });
        this.setState({
            ...this.state,
            isOwnerEdit: false,
        });
    };

    onClickSaveFolderPath = async () => {
        await this.props.onClickSaveField("virtualFolder", {
            id: this.state.selectedFolder.id,
        });
        this.setState({
            ...this.state,
            isFolderPathEdit: false,
            selectedFolder: { id: "", path: "" },
        });
    };

    customFilter = (option, inputValue) => {
        const user = option && option.data;
        if (!user) return false;

        return (
            (user.name && user.name.indexOf(inputValue) >= 0) ||
            (user.username && user.username.indexOf(inputValue) >= 0)
        );
    };

    formatOptionLabel = (option) => {
        return (
            <div className="custom-option">
                <p>{option.name || "-"}</p>
                <small className="text-small text-cancel">{option.username || "-"}</small>
            </div>
        );
    };

    updateAlternateNamesEditMode = (isEditMode) => {
        if (isEditMode) {
            this.setState({
                ...this.state,
                isAlternativeNameEdit: isEditMode,
                alternativeNameCandidates: [].concat(this.props.alternativeNames || []),
            });
        } else {
            this.setState({
                ...this.state,
                isAlternativeNameEdit: isEditMode,
            });
        }
    };
    addAlternativeNameCandidate = () => {
        const candidates = this.state.alternativeNameCandidates;
        candidates.push({
            name: "",
        });
        this.setState({
            ...this.state,
            alternativeNameCandidates: candidates,
        });
    };

    removeAlternativeNameCandidate = (index) => {
        const candidates = this.state.alternativeNameCandidates;
        candidates.splice(index, 1);
        this.setState({
            ...this.state,
            alternativeNameCandidates: candidates,
        });
    };

    saveAlternativeNames = () => {
        const alternativeNames = this.state.alternativeNameCandidates.filter((a) => a.name && a.name.length > 0);
        this.props.onSaveAlternativeNames(alternativeNames);
        this.updateAlternateNamesEditMode(false);
    };

    render() {
        const { t, i18n, isEditable, showOwnerInfo, location } = this.props;
        const { spaceId, projectId } = menuService.parse(location.pathname);

        return (
            <>
                {this.state.selectFolderModal &&
                    ReactDOM.createPortal(
                        <LoadableSelectFolderPathModal
                            open={true}
                            domain={this.props.contentType}
                            submitText={`Select`}
                            title={`Select Folder Path`}
                            onSubmit={({ folderId, folderPath }) =>
                                this.setState({
                                    ...this.state,
                                    selectedFolder: { id: folderId, path: folderPath },
                                    selectFolderModal: false,
                                })
                            }
                            onClose={() => {
                                this.setState({ ...this.state, selectFolderModal: false });
                            }}
                        />,
                        document.body,
                    )}
                <div className={`list-overview ${this.props.isColumn && "column"}`}>
                    <ul>
                        {this.props.name && (
                            <li>
                                <strong className="title">{t(`common::label::${"Name"}`)}</strong>
                                <div className="content">{this.props.name}</div>
                            </li>
                        )}
                        {this.props.contentType === PROPERTY_PANEL_TYPE.PEOPLE && (
                            <React.Fragment>
                                <li>
                                    <div className="title">
                                        <strong>{t(`common::label::${"Alternative names"}`)}</strong>
                                        {this.props.isEditable && !this.state.isAlternativeNameEdit && (
                                            <button
                                                type={"button"}
                                                className={"btn btn-icon-solid btn-edit"}
                                                onClick={() => {
                                                    this.updateAlternateNamesEditMode(true);
                                                }}
                                            >
                                                <i className={"sprite sprite-edit"}></i>
                                            </button>
                                        )}
                                    </div>
                                    {this.state.isAlternativeNameEdit ? (
                                        <div className="content">
                                            <div className={"box-border box-border-sm box-border-radius is-edit"}>
                                                {this.state.alternativeNameCandidates.map((a, index) => (
                                                    <React.Fragment key={`alternativeName-${index}`}>
                                                        <div className={"row row-column"}>
                                                            <div className={"col-11"}>
                                                                <InputField
                                                                    value={a.name || ""}
                                                                    placeholder={t(
                                                                        `common::label::${"Alternative name"}`,
                                                                    )}
                                                                    onChange={(e) => {
                                                                        a.name = e.target.value;
                                                                        this.setState({
                                                                            ...this.state,
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className={"col-1 col separate"}>
                                                                <button
                                                                    type="button"
                                                                    className={"btn btn-icon-solid"}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.removeAlternativeNameCandidate(index);
                                                                    }}
                                                                >
                                                                    <i className={"sprite sprite-cancel-fade"}></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                ))}
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-default btn-alternative-name-add"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.addAlternativeNameCandidate();
                                                    }}
                                                >
                                                    <span>{t(`common::label::${"Add"}`)}</span>
                                                </button>
                                            </div>
                                            <div className="btns-editable">
                                                <button
                                                    type={"button"}
                                                    className={"btn btn-icon btn-sm"}
                                                    onClick={() => {
                                                        this.updateAlternateNamesEditMode(false);
                                                    }}
                                                >
                                                    <i className={"sprite sprite-edit-cancel"} />
                                                </button>
                                                <button
                                                    type={"button"}
                                                    className={"btn btn-icon btn-sm btn-outline-primary"}
                                                    // disabled={!this.state.edit.owner}
                                                    onClick={() => {
                                                        this.saveAlternativeNames();
                                                    }}
                                                >
                                                    <i className={"sprite sprite-edit-submit"} />
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="content">
                                            {this.props.alternativeNames && this.props.alternativeNames.length > 0 ? (
                                                <>{this.props.alternativeNames.map((r) => r.name).join(", ")}</>
                                            ) : (
                                                <span className="text-cancel">{t(`common::label::${"None"}`)}</span>
                                            )}
                                        </div>
                                    )}
                                </li>
                            </React.Fragment>
                        )}

                        {this.props.id && (
                            <li>
                                <strong className="title">{t(`common::label::${"ID"}`)}</strong>
                                <div className="content">{this.props.id}</div>
                            </li>
                        )}

                        {this.props.status && (
                            <li>
                                <strong className="title">{t(`common::label::${"Status"}`)}</strong>
                                <div className="content">
                                    <StatusBadge status={this.props.status} desiredStatus={this.props.desiredStatus} />
                                </div>
                            </li>
                        )}

                        {this.props.archivedAssetId && (
                            <li>
                                <strong className="title">{t(`common::label::${"Archive ID"}`)}</strong>
                                <div className="content">{this.props.archivedAssetId}</div>
                            </li>
                        )}

                        {this.props.assetId && (
                            <li>
                                <strong className="title">{t(`common::label::${"Asset ID"}`)}</strong>
                                <div className="content">{this.props.assetId}</div>
                            </li>
                        )}

                        {this.props.archivedAssetStatus && (
                            <li>
                                <strong className="title">{t(`common::label::${"Archive"}`)}</strong>
                                <div className="content">
                                    {this.getArchivedStatus(this.props.archivedAssetStatus, t)}
                                    {/* <div className="archive-status archive-status-restore">
                                    <i className={"sprite sprite-restore"}></i>
                                    <span className={"text-uppercase"}>Archived</span>
                                    <span className={"message-error-tooltip"} data-tooltip="An attempt to 'Archive' failed. Please try again.">
                                        <i className={"sprite sprite-error"}></i>
                                    </span>
                                </div>

                                <div className={"archive-status archive-status-archving"}>
                                    <div>
                                        <i className={"sprite sprite-ingesting"}></i>
                                        <p>Archving...</p>
                                    </div>
                                </div>

                                <div className={"archive-status archive-status-restoring"}>
                                    <div>
                                        <i className={"sprite sprite-progressing"}></i>
                                        <p>Restoring...</p>
                                    </div>
                                </div> */}
                                </div>
                            </li>
                        )}
                    </ul>
                    <hr />
                    {compareVersions(this.props.stage.version, VERSION_CM_8339_FOLDER_VIEW) >= 0 &&
                        ["Asset", "Archived Assets"].includes(this.props.contentType) && (
                            <>
                                <ul>
                                    <li>
                                        <div className="title">
                                            <strong>{t(`common::label::${"Folder Path"}`)}</strong>
                                            {this.props.resourceType === "ASSET" &&
                                                !this.state.isFolderPathEdit &&
                                                isEditable && (
                                                    <button
                                                        type={"button"}
                                                        className={"btn btn-icon-solid btn-edit"}
                                                        onClick={() => {
                                                            this.setState({
                                                                ...this.state,
                                                                isFolderPathEdit: true,
                                                            });
                                                        }}
                                                    >
                                                        <i className={"sprite sprite-edit"} />
                                                    </button>
                                                )}
                                        </div>
                                        {this.state.isFolderPathEdit ? (
                                            <div className="content">
                                                <div className={"box-border box-border-sm box-border-radius is-edit"}>
                                                    <div className={"form-stack"}>
                                                        <div className={"d-flex align-items-start"}>
                                                            <div className={"d-flex align-items-center sprite"}>
                                                                <i className="sprite sprite-drive" />
                                                                <span className="sprite text-nowrap">
                                                                    {t(`common::label::${"Assets"}`)}
                                                                </span>
                                                                <i className="sprite sprite-caret-right-gray" />
                                                                <i className={"sprite sprite-folder"} />
                                                            </div>
                                                            {!this.state.selectedFolder.path &&
                                                            !this.props.virtualFolderPath ? (
                                                                <span className="text-cancel">{`ex) drama/video`}</span>
                                                            ) : (
                                                                <span>
                                                                    {this.state.selectedFolder.path
                                                                        ? this.state.selectedFolder.path
                                                                        : this.props.virtualFolderPath}
                                                                </span>
                                                            )}
                                                        </div>
                                                        <hr className={`sm`} />
                                                        <button
                                                            className={"btn btn-outline-default"}
                                                            type={"button"}
                                                            onClick={() => {
                                                                this.setState({
                                                                    ...this.state,
                                                                    selectFolderModal: true,
                                                                });
                                                            }}
                                                        >
                                                            <span>{t(`common::label::${"Select Folder Path"}`)}</span>
                                                        </button>
                                                    </div>

                                                    <div className={"message-guide d-flex"}>
                                                        <i className="sprite sprite-info-inverse" />
                                                        <span className={"text-secondary"}>
                                                            {t(
                                                                `common::msg::${"Select the folder path. If not specified, it is stored in the top-level path."}`,
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="btns-editable">
                                                    <button
                                                        type={"button"}
                                                        className={"btn btn-icon btn-sm"}
                                                        onClick={() => {
                                                            this.setState({
                                                                ...this.state,
                                                                isFolderPathEdit: false,
                                                                selectedFolder: { id: "", path: "" },
                                                            });
                                                        }}
                                                    >
                                                        <i className={"sprite sprite-edit-cancel"} />
                                                    </button>
                                                    <button
                                                        type={"button"}
                                                        className={"btn btn-icon btn-sm btn-outline-primary"}
                                                        // disabled={!this.state.edit}
                                                        onClick={this.onClickSaveFolderPath}
                                                    >
                                                        <i className={"sprite sprite-edit-submit"} />
                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="content">
                                                <div className="d-flex align-items-center">
                                                    <i className={`sprite sprite-folder-disabled`} />
                                                    <span>{this.props.virtualFolderPath ?? "/"}</span>
                                                </div>
                                            </div>
                                        )}
                                    </li>
                                </ul>
                                <hr />
                            </>
                        )}
                    <ul>
                        {this.props.mediaType && (
                            <li>
                                <strong className="title">{t(`common::label::${"Media type"}`)}</strong>
                                <div className="content">
                                    <i className={`sprite sprite-media-${this.props.mediaType.toLowerCase()}`}></i>
                                    <span>{this.props.mediaType}</span>
                                </div>
                            </li>
                        )}
                        {this.props.type && (
                            <li>
                                <strong className="title">{t(`common::label::${"Type"}`)}</strong>
                                <div className="content">
                                    <FormatBadge format={this.props.type} mediaType={this.props.mediaType} />
                                </div>
                            </li>
                        )}
                        {this.props.duration !== undefined && (
                            <li>
                                <strong className="title">{t(`common::label::${"Duration"}`)}</strong>
                                <div className="content">{formatDuration(this.props.duration)}</div>
                            </li>
                        )}
                        {this.props.size !== undefined && (
                            <li>
                                <strong className="title">{t(`common::label::${"Size"}`)}</strong>
                                <div className="content">{convertToByte(this.props.size)}</div>
                            </li>
                        )}
                        {this.props.renditions && this.props.renditions.length > 0 && (
                            <li>
                                <strong className="title">{t(`common::label::${"Renditions"}`)}</strong>
                                <div className="content">
                                    {this.props.renditions.map((r, k) => (
                                        <div key={k}>
                                            {r.codec}
                                            {r.width !== undefined && r.height !== undefined
                                                ? `, ${r.width}x${r.height}`
                                                : ""}
                                            {r.ratingControlMode && `, ${r.ratingControlMode}`},{" "}
                                            {convertToBPS(r.bitrate)}
                                            {r.sampleRate !== undefined && `, ${convertToHZ(r.sampleRate)}`}
                                        </div>
                                    ))}
                                </div>
                            </li>
                        )}
                        {this.props.resolution && (
                            <li>
                                <strong className="title">{t(`common::label::${"Resolution"}`)}</strong>
                                <div className="content">
                                    {this.props.resolution.width || 0}x{this.props.resolution.height || 0}
                                </div>
                            </li>
                        )}
                    </ul>
                    <hr />
                    <ul>
                        {this.props.ingestType && (
                            <li>
                                <strong className="title">{t(`common::label::${"Ingest type"}`)}</strong>
                                <div className="content">{this.props.ingestType}</div>
                            </li>
                        )}
                        {this.props.job && this.props.job.id && (
                            <li>
                                <strong className="title">{t(`common::label::${"Job ID"}`)}</strong>
                                <div className="content">
                                    {!isEditable || siteMode.get() === "CMS" ? (
                                        <span>{this.props.job.id}</span>
                                    ) : (
                                        <Link
                                            to={menuService.paths.jobDetail(spaceId, projectId, this.props.job.id)}
                                            className="btn btn-link"
                                        >
                                            <span>{this.props.job.id}</span>
                                            <i className="sprite sprite-link-primary"></i>
                                        </Link>
                                    )}
                                </div>
                            </li>
                        )}
                        {this.props.project && this.props.project.id && (
                            <ProjectInfo projectId={this.props.project.id} />
                        )}
                        {this.props.archiveBy && (
                            <li>
                                <strong className="title">{t(`common::label::${"Archived by"}`)}</strong>
                                <div className="content">
                                    {(this.props.createdBy && this.props.createdBy.name) || "-"}
                                    {this.props.createdBy && (
                                        <span className="text-secondary">({this.props.createdBy.username})</span>
                                    )}
                                </div>
                            </li>
                        )}
                        {this.props.archivedAt && (
                            <React.Fragment>
                                <li>
                                    <strong className="title">{t(`common::label::${"Archived"}`)}</strong>
                                    <div className="content">
                                        {convertUtcToLocal(this.props.archivedAt, "YYYY-MM-DD HH:mm:ss")}
                                    </div>
                                </li>

                                <li>
                                    <strong className="title">{t(`common::label::${"Archived Period"}`)}</strong>
                                    <div className="content">
                                        <i className={"sprite sprite-clock-fade"}></i>{" "}
                                        {this.getDurationArchivedByCurrent(this.props.archivedAt, t)}
                                    </div>
                                </li>
                            </React.Fragment>
                        )}

                        <li>
                            <div className="title">
                                <strong>{t(`common::label::${"Owner"}`)}</strong>
                                <VersionedComponent version={VERSION_CM_5068_ASSET_OWNER_CHANGE}>
                                    {this.props.resourceType === "ASSET" && !this.state.isOwnerEdit && isEditable && (
                                        <button
                                            type={"button"}
                                            className={"btn btn-icon-solid btn-edit"}
                                            onClick={() => {
                                                this.setState({
                                                    ...this.state,
                                                    isOwnerEdit: true,
                                                });
                                            }}
                                        >
                                            <i className={"sprite sprite-edit"}></i>
                                        </button>
                                    )}
                                </VersionedComponent>
                            </div>
                            {this.state.isOwnerEdit ? (
                                <div className="content">
                                    <div className={"box-border box-border-sm box-border-radius is-edit"}>
                                        <div className="input-group">
                                            <div className="form-select form-select-with-icon">
                                                <span className="icon ">
                                                    <i className={`sprite sprite-users-box`} />
                                                </span>
                                                <WithSelect
                                                    placeholder={t(`common::msg::${"Enter Display Name or Username"}`)}
                                                    defaultValue={
                                                        this.state.selectedOwner && this.state.selectedOwner.value
                                                    }
                                                    isDisabled={this.state.edit.owner ? true : false}
                                                    options={this.state.userOptions || []}
                                                    filterOption={this.customFilter}
                                                    onChange={(option) => this.onChangeUser(option)}
                                                    formatOptionLabel={this.formatOptionLabel}
                                                    menuPortalTarget={document.body}
                                                    isClearable
                                                    isTextWrap
                                                />
                                            </div>
                                            <button
                                                type="button"
                                                className="btn btn-outline-default"
                                                disabled={
                                                    (this.state.edit.owner ? true : false) || !this.state.selectedOwner
                                                }
                                                onClick={(e) => {
                                                    if (!this.state.selectedOwner) return;
                                                    this.setState({
                                                        ...this.state,
                                                        selectedOwner: null,
                                                        edit: {
                                                            ...this.state.edit,
                                                            owner: this.state.selectedOwner,
                                                        },
                                                    });
                                                }}
                                            >
                                                <span>Add</span>
                                            </button>
                                        </div>
                                        <p className="form-label">
                                            <strong>
                                                {t(`common::label::${"Selected {{name}}"}`, {
                                                    name: t(`common::label::${"Owner"}`),
                                                })}
                                            </strong>
                                        </p>
                                        <div className="box-scroll">
                                            <div className="list-selected-owner">
                                                {this.state.edit.owner ? (
                                                    <ul>
                                                        <li>
                                                            <div className="owner">
                                                                <div className="owner-info">
                                                                    <span className="owner-name">
                                                                        <p>{this.state.edit.owner.name || "-"}</p>
                                                                        <small className="text-small text-cancel">
                                                                            {this.state.edit.owner.username || "-"}
                                                                        </small>
                                                                    </span>
                                                                </div>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-icon-solid file-delete"
                                                                    onClick={(e) => {
                                                                        this.setState({
                                                                            ...this.state,
                                                                            edit: {
                                                                                ...this.state.edit,
                                                                                owner: null,
                                                                            },
                                                                        });
                                                                    }}
                                                                >
                                                                    <i className="sprite sprite-cancel-fade" />
                                                                    <span className="hidden">delete</span>
                                                                </button>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                ) : (
                                                    <div className="empty">
                                                        {t(`common::label::${"Please add {{name}}."}`, {
                                                            name: t(`common::label::${"User"}`),
                                                        })}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btns-editable">
                                        <button
                                            type={"button"}
                                            className={"btn btn-icon btn-sm"}
                                            onClick={() => {
                                                const owner = this.props.createdBy && {
                                                    value: this.props.createdBy.id,
                                                    label: this.props.createdBy.username,
                                                    id: this.props.createdBy.id,
                                                    name: this.props.createdBy.name,
                                                    username: this.props.createdBy.username,
                                                };
                                                this.setState({
                                                    ...this.state,
                                                    isOwnerEdit: false,
                                                    selectedOwner: null,
                                                    edit: {
                                                        ...this.state.edit,
                                                        owner,
                                                    },
                                                });
                                            }}
                                        >
                                            <i className={"sprite sprite-edit-cancel"} />
                                        </button>
                                        <button
                                            type={"button"}
                                            className={"btn btn-icon btn-sm btn-outline-primary"}
                                            disabled={!this.state.edit.owner}
                                            onClick={() => {
                                                this.onClickChangeOwner();
                                            }}
                                        >
                                            <i className={"sprite sprite-edit-submit"} />
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className="content">
                                    {(this.props.createdBy && this.props.createdBy.name) || "-"}
                                    {this.props.createdBy && (
                                        <span className="text-secondary">({this.props.createdBy.username})</span>
                                    )}
                                    <VersionedComponent version={VERSION_CM_4721_SHARES}>
                                        {(!isEditable || showOwnerInfo) && (
                                            <TooltipUserInfo
                                                target={this.props.createdBy}
                                                id={this.props.createdBy && this.props.createdBy.id}
                                                type={"overview"}
                                            />
                                        )}
                                    </VersionedComponent>
                                </div>
                            )}
                        </li>
                        <li>
                            <strong className="title">{t(`common::label::${"Created"}`)}</strong>
                            <div className="content">
                                {this.props.createdAt ? convertUtcToLocal(this.props.createdAt) : "-"}
                            </div>
                        </li>
                    </ul>
                </div>
            </>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        stage: state.stage,
        people: state.people,
        userManagement: state.userManagement,
    };
};

let mapDispatchToProps = (dispatch) => {
    return {
        PeopleActions: bindActionCreators(peopleActions, dispatch),
        AssetsActions: bindActionCreators(assetsActions, dispatch),
        UserManagementActions: bindActionCreators(userManagementActions, dispatch),
    };
};

DomainOverview = connect(mapStateToProps, mapDispatchToProps)(DomainOverview);
export default withTranslation()(withRouter(DomainOverview));
