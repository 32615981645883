import { handleActions, createAction } from "redux-actions";
import {
    createCollectionAPI,
    getCollectionAPI,
    getLinkedCollectionsAPI,
    getLinkedAssetsAPI,
    updateCollectionAPI,
    updateCollectionActionAPI,
} from "./apis/collection";
import { getAssetsAPI } from "@modules/apis/assets";

const initialState = {
    data: null,
    assets: { data: null, pending: false, error: false },
    pending: false,
    error: false,
};

const COLLECTION_CREATE_PENDING = "COLLECTION_CREATE_PENDING";
const COLLECTION_CREATE_SUCCESS = "COLLECTION_CREATE_SUCCESS";
const COLLECTION_CREATE_FAILURE = "COLLECTION_CREATE_FAILURE";
const GET_COLLECTION_PENDING = "GET_COLLECTION_PENDING";
const GET_COLLECTION_SUCCESS = "GET_COLLECTION_SUCCESS";
const GET_COLLECTION_FAILURE = "GET_COLLECTION_FAILURE";
const GET_COLLECTION_ASSETS_PENDING = "GET_COLLECTION_ASSETS_PENDING";
const GET_COLLECTION_ASSETS_SUCCESS = "GET_COLLECTION_ASSETS_SUCCESS";
const GET_COLLECTION_ASSETS_FAILURE = "GET_COLLECTION_ASSETS_FAILURE";
const COLLECTION_ASSETS_CLEAR = "COLLECTION_ASSETS_CLEAR";
const GET_LINKED_COLLECTIONS_PENDING = "GET_LINKED_COLLECTIONS_PENDING";
const GET_LINKED_COLLECTIONS_SUCCESS = "GET_LINKED_COLLECTIONS_SUCCESS";
const GET_LINKED_COLLECTIONS_FAILURE = "GET_LINKED_COLLECTIONS_FAILURE";
const GET_LINKED_ASSETS_PENDING = "GET_LINKED_ASSETS_PENDING";
const GET_LINKED_ASSETS_SUCCESS = "GET_LINKED_ASSETS_SUCCESS";
const GET_LINKED_ASSETS_FAILURE = "GET_LINKED_ASSETS_FAILURE";
const UPDATE_COLLECTION_DATA = "UPDATE_COLLECTION_DATA";

export default handleActions(
    {
        [COLLECTION_CREATE_PENDING]: (state, action) => {
            return {
                ...initialState,
                pending: true,
            };
        },
        [COLLECTION_CREATE_SUCCESS]: (state, action) => {
            return {
                ...initialState,
                data: action.payload,
                pending: false,
            };
        },
        [COLLECTION_CREATE_FAILURE]: (state, action) => {
            return {
                ...initialState,
                pending: false,
                error: true,
            };
        },
        [GET_COLLECTION_PENDING]: (state, action) => {
            return {
                ...initialState,
                pending: true,
                data: null,
            };
        },
        [GET_COLLECTION_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
                data: action.payload,
            };
        },
        [GET_COLLECTION_FAILURE]: (state, action) => {
            return {
                ...initialState,
                pending: false,
                error: action.payload,
            };
        },
        [GET_COLLECTION_ASSETS_PENDING]: (state, action) => {
            return {
                ...state,
                assets: {
                    pending: true,
                    data: null,
                    error: null,
                },
            };
        },
        [GET_COLLECTION_ASSETS_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                assets: {
                    pending: false,
                    data: state.assets?.data ? [...state.assets.data, ...data?.assets] : data.assets,
                    totalCount: data?.totalCount ?? 0,
                    error: null,
                },
            };
        },
        [GET_COLLECTION_ASSETS_FAILURE]: (state, action) => {
            return {
                ...state,
                assets: {
                    pending: false,
                    data: null,
                    error: action.payload,
                },
            };
        },
        [COLLECTION_ASSETS_CLEAR]: (state, action) => {
            return { ...state, assets: { ...initialState.assets } };
        },
        [GET_LINKED_COLLECTIONS_PENDING]: (state, action) => {
            return {
                ...initialState,
                data: { ...(state.data ?? {}), linkedCollections: { pending: true } },
            };
        },
        [GET_LINKED_COLLECTIONS_SUCCESS]: (state, action) => {
            return {
                ...initialState,
                data: {
                    ...state.data,
                    linkedCollections: { ...state.data.linkedCollections, pending: false, ...action.payload },
                },
            };
        },
        [GET_LINKED_COLLECTIONS_FAILURE]: (state, action) => {
            return {
                ...initialState,
                data: {
                    ...state.data,
                    linkedCollections: { ...state.data.linkedCollections, pending: false, error: action.payload },
                },
            };
        },
        [GET_LINKED_ASSETS_PENDING]: (state, action) => {
            return {
                ...initialState,
                data: { ...(state.data ?? {}), linkedAssets: { pending: true } },
            };
        },
        [GET_LINKED_ASSETS_SUCCESS]: (state, action) => {
            return {
                ...initialState,
                data: {
                    ...state.data,
                    linkedAssets: { ...state.data.linkedAssets, pending: false, ...action.payload },
                },
            };
        },
        [GET_LINKED_ASSETS_FAILURE]: (state, action) => {
            return {
                ...initialState,
                data: {
                    ...state.data,
                    linkedAssets: { ...state.data.linkedAssets, pending: false, error: action.payload },
                },
            };
        },
        [UPDATE_COLLECTION_DATA]: (state, action) => {
            return { ...state, data: { ...state.data, ...action.payload } };
        },
    },
    initialState,
);

export const updateCollectionData = createAction(UPDATE_COLLECTION_DATA);
export const clearAssetList = createAction(COLLECTION_ASSETS_CLEAR);

export const createCollection = (collectionParams, selectedCollections) => (dispatch, getState) => {
    const { stage, project } = getState();
    dispatch({ type: COLLECTION_CREATE_PENDING });

    return new Promise((resolve, reject) => {
        createCollectionAPI(stage.endpoint, project.id, collectionParams)
            .then((response) => {
                dispatch({ type: COLLECTION_CREATE_SUCCESS, payload: response.data });

                resolve(response);
            })
            .catch((error) => {
                reject(error);
                dispatch({ type: COLLECTION_CREATE_FAILURE });
            });
    });
};

export const getCollection =
    ({ id }) =>
    (dispatch, getState) => {
        const { stage, project } = getState();

        dispatch({ type: GET_COLLECTION_PENDING });

        return new Promise((resolve, reject) => {
            getCollectionAPI(stage.endpoint, project.id, id)
                .then(async (response) => {
                    let data = response.data;

                    getLinkedCollectionsAPI(stage.endpoint, project.id, id)
                        .then(async (response) => {
                            dispatch({
                                type: GET_COLLECTION_SUCCESS,
                                payload: { ...data, linkedCollections: response.data.results },
                            });
                            resolve(data);
                        })
                        .catch((error) => {
                            dispatch({ type: GET_COLLECTION_FAILURE, payload: error });
                            reject(error);
                        });
                    resolve(response.data);
                })
                .catch((error) => {
                    dispatch({ type: GET_COLLECTION_FAILURE, payload: error });
                    reject(error);
                });
        });
    };

export const getAssetsList = (projectId, search) => (dispatch, getState) => {
    const { stage, project, assets } = getState();
    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const targetProjectId = projectId || project.id;
    const searchParams = search || assets.search;
    const contextVersion = assets.contextVersion + 1;

    dispatch({ type: GET_COLLECTION_ASSETS_PENDING });

    return new Promise((resolve, reject) => {
        getAssetsAPI(stageId, apiEndpoint, targetProjectId, searchParams)
            .then((response) => {
                const assets = response.data.assets;
                if (!assets) {
                    throw new Error("Get assets API server responsed wrong data spec.");
                }
                dispatch({
                    type: GET_COLLECTION_ASSETS_SUCCESS,
                    payload: response,
                });
                resolve(response.data);
            })
            .catch((error) => {
                dispatch({
                    type: GET_COLLECTION_ASSETS_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const getLinkedCollections =
    ({ id, params }) =>
    (dispatch, getState) => {
        const { stage, project } = getState();

        dispatch({ type: GET_LINKED_COLLECTIONS_PENDING });

        return new Promise((resolve, reject) => {
            getLinkedCollectionsAPI(stage.endpoint, project.id, id, params)
                .then(async (response) => {
                    dispatch({
                        type: GET_LINKED_COLLECTIONS_SUCCESS,
                        payload: response.data,
                    });
                    resolve(response.data);
                })
                .catch((error) => {
                    dispatch({ type: GET_LINKED_COLLECTIONS_FAILURE, payload: error });
                    reject(error);
                });
        });
    };

export const getLinkedAssets =
    ({ id, params }) =>
    (dispatch, getState) => {
        const { stage, project } = getState();

        dispatch({ type: GET_LINKED_ASSETS_PENDING });

        return new Promise((resolve, reject) => {
            getLinkedAssetsAPI(stage.endpoint, project.id, id, params)
                .then(async (response) => {
                    dispatch({
                        type: GET_LINKED_ASSETS_SUCCESS,
                        payload: response.data,
                    });
                    resolve(response.data.nextToken);
                })
                .catch((error) => {
                    dispatch({ type: GET_LINKED_ASSETS_FAILURE, payload: error });
                    reject(error);
                });
        });
    };

export const updateCollection =
    ({ id, params }) =>
    (dispatch, getState) => {
        const { stage, project, collection } = getState();
        const { linkedCollections, linkedAssets } = collection.data;

        return new Promise((resolve, reject) => {
            updateCollectionAPI(stage.endpoint, project.id, id, params)
                .then(async (response) => {
                    let data = response.data;
                    dispatch({
                        type: UPDATE_COLLECTION_DATA,
                        payload: { ...data, linkedCollections, linkedAssets },
                    });
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

export const updateCollectionAction =
    ({ contentId, params }) =>
    (dispatch, getState) => {
        const { stage, project, collection } = getState();
        const data = collection.data;

        return new Promise((resolve, reject) => {
            updateCollectionActionAPI(stage.endpoint, project.id, contentId, params)
                .then(async (response) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };
