import { handleActions, createAction } from "redux-actions";
import { CONSTANTS, LIST_VIEW_TYPE, MEDIA_TYPES, GROUPING_VIEW_TYPE } from "../constants";
import isNil from "lodash/isNil";

import Polling from "../cores/polling";
import AssetApi from "./apis/asset";

import assets, {
    deleteAssetAPI,
    getAssetsAPI,
    listAssetTypesAPI,
    listAssetExtensionsAPI,
    bulkAttachCategoriesToAssetAPI,
    bulkDetachCategoriesToAssetAPI,
} from "./apis/assets";

import i18n from "../lib/i18n";
import compareVersions, { VERSION_CM_3963_ADD_ASSET_TYPE } from "../cores/version";

//action type
const ASSETS_UPDATE = "ASSETS_UPDATE";

const ASSET_LIST_PENDING = "ASSET_LIST_PENDING";
const ASSET_LIST_FAILURE = "ASSET_LIST_FAILURE";

const ASSET_LIST_UPDATE_STATE = "ASSET_LIST_UPDATE_STATE";
const ASSET_LIST_UPDATE_SEARCH = "ASSET_LIST_UPDATE_SEARCH";
const ASSET_LIST_RESET_SEARCH = "ASSET_LIST_RESET_SEARCH";

const ASSET_LIST_GET_ASSET_LIST_SUCCESS = "ASSET_LIST_GET_ASSET_LIST_SUCCESS";
const ASSET_LIST_GET_ASSET_LIST_ALL_SUCCESS = "ASSET_LIST_GET_ASSET_LIST_ALL_SUCCESS";
const ASSET_LIST_DELETE_ASSET_SUCCESS = "ASSET_LIST_DELETE_ASSET_SUCCESS";

const ASSET_LIST_TOGGLE_ACTION_BUTTON = "ASSET_LIST_TOGGLE_ACTION_BUTTON";
const ASSET_LIST_ASSET_SET_VALIDATION_ERROR = "ASSET_SET_VALIDATION_ERROR";
const ASSET_LIST_UPDATE_ASSET_STATUS = "ASSET_LIST_UPDATE_ASSET_STATUS";

const ASSET_LIST_CLEAR = "ASSET_LIST_CLEAR";
const ASSET_LIST_LIST_CLEAR = "ASSET_LIST_LIST_CLEAR";

const ASSET_TYPES_LIST_PENDING = "ASSET_TYPES_LIST_PENDING";
const ASSET_TYPES_LIST_SUCCESS = "ASSET_TYPES_LIST_SUCCESS";
const ASSET_TYPES_LIST_FAIL = "ASSET_TYPES_LIST_FAIL";

const ASSET_EXTENSIONS_LIST_PENDING = "ASSET_EXTENSIONS_LIST_PENDING";
const ASSET_EXTENSIONS_LIST_SUCCESS = "ASSET_EXTENSIONS_LIST_SUCCESS";
const ASSET_EXTENSIONS_LIST_FAIL = "ASSET_EXTENSIONS_LIST_FAIL";

const UPDATE_FILTERED_ASSETS = "UPDATE_FILTERED_ASSETS";

//reducer
const initialState = {
    pending: false,
    error: false,
    isActiveView: LIST_VIEW_TYPE.HIERARCHY,
    hierarchyType: GROUPING_VIEW_TYPE.FOLDER,
    isSearched: false,
    data: null,
    originData: [],
    totalCount: 0,
    contextVersion: 0,
    search: {
        isQuickSearch: true,
        isFolderSearch: null,
        isCategorySearch: null,
        isNotCategoryIds: false,
        isIncludeChildCategory: false,
        status: "",
        creator: "",
        folderPath: "",
        keyword: "",
        types: [],
        extensions: [],
        ingestType: "",
        mediaTypes: [],
        categoryIds: [],
        tags: [],
        name: "",
        id: "",
        beginDuration: "",
        endDuration: "",
        startAt: null,
        endAt: null,
        jobId: "",
        offset: 0,
        limit: 20,
    },
    validationErrors: {
        name: "required",
    },
    assetTypes: {
        pending: false,
        error: false,
        data: {},
    },
    assetExtensions: {
        pending: false,
        error: false,
        data: [],
    },
};

let tempState = {
    ...initialState,
};

export default handleActions(
    {
        [ASSETS_UPDATE]: (state, action) => {
            tempState = {
                ...tempState,
                ...action.payload,
            };
            return tempState;
        },
        [ASSET_LIST_CLEAR]: (state, action) => {
            tempState = {
                ...initialState,
                assetTypes: state.assetTypes,
                assetExtensions: state.assetExtensions,
                contextVersion: tempState.contextVersion + 1,
                hasReset: true,
            };
            return tempState;
        },
        [ASSET_LIST_LIST_CLEAR]: (state, action) => {
            tempState = {
                ...tempState,
                data: [],
                totalCount: 0,
            };
            return tempState;
        },
        [ASSET_LIST_UPDATE_STATE]: (state, action) => {
            const temp = {
                ...tempState,
                assetTypes: state.assetTypes,
                assetExtensions: state.assetExtensions,
                ...action.payload,
            };
            tempState = temp;
            return tempState;
        },
        [ASSET_LIST_PENDING]: (state) => {
            const temp = {
                ...tempState,
                assetTypes: state.assetTypes,
                assetExtensions: state.assetExtensions,
                contextVersion: tempState.contextVersion + 1,
                pending: true,
                error: false,
            };
            tempState = temp;
            return tempState;
        },
        [ASSET_LIST_FAILURE]: (state) => {
            const temp = {
                ...state,
                pending: false,
                error: true,
            };
            tempState = temp;
            return tempState;
        },
        [ASSET_LIST_GET_ASSET_LIST_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const temp = {
                ...tempState,
                assetTypes: state.assetTypes,
                assetExtensions: state.assetExtensions,
                pending: false,
                error: false,
                data: data && data.assets,
                totalCount: (data && data.totalCount) || 0,
            };
            tempState = temp;
            return tempState;
        },
        [ASSET_LIST_GET_ASSET_LIST_ALL_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const temp = {
                ...tempState,
                assetTypes: state.assetTypes,
                assetExtensions: state.assetExtensions,
                pending: false,
                error: false,
                data: state.data ? [...state.data, ...data?.assets] : data.assets,
                originData: state.data ? [...state.data, ...data?.assets] : data.assets,
                totalCount: (data && data.totalCount) || 0,
            };
            tempState = temp;
            return tempState;
        },
        [ASSET_LIST_UPDATE_SEARCH]: (state, action) => {
            const temp = {
                ...tempState,
                assetTypes: state.assetTypes,
                assetExtensions: state.assetExtensions,
                search: {
                    ...state.search,
                },
            };

            temp.search = {
                ...tempState.search,
                ...state.search,
            };

            if (action.payload.isQuickSearch === true) {
                temp.search = {
                    ...temp.search,
                    isQuickSearch: true,
                    keyword: action.payload.keyword || "",
                    isNotCategoryIds: action.payload.isNotCategoryIds,
                    categoryIds:
                        action.payload.activeView?.toLowerCase() === LIST_VIEW_TYPE.HIERARCHY
                            ? action.payload.categoryIds || state.search.categoryIds
                            : null,
                    selectedCategoryId:
                        action.payload.activeView?.toLowerCase() === LIST_VIEW_TYPE.HIERARCHY
                            ? action.payload.selectedCategoryId || state.search.selectedCategoryId
                            : null,
                    offset: isNil(action.payload.offset) ? state.search.offset : action.payload.offset,
                    limit: isNil(action.payload.limit) ? state.search.limit : action.payload.limit,
                };
            } else if (action.payload.isQuickSearch === false)
                temp.search = {
                    ...temp.search,
                    ...action.payload,
                    isQuickSearch: false,
                    keyword: "",
                };
            else {
                temp.search = {
                    ...temp.search,
                    ...action.payload,
                };
            }

            tempState = temp;
            return tempState;
        },
        [ASSET_LIST_RESET_SEARCH]: (state) => {
            const search = {
                ...initialState.search,
                isQuickSearch: Boolean(state.search.isQuickSearch),
            };
            const temp = {
                ...tempState,
                assetTypes: state.assetTypes,
                assetExtensions: state.assetExtensions,
                search,
            };
            tempState = temp;
            return tempState;
        },
        [ASSET_LIST_TOGGLE_ACTION_BUTTON]: (state, action) => {
            const temp = {
                ...tempState,
                assetTypes: state.assetTypes,
                assetExtensions: state.assetExtensions,
                data:
                    state.data &&
                    state.data.map((v) => {
                        if (v.id === action.payload.id) {
                            return {
                                ...v,
                                isShowActionButton: !v.isShowActionButton,
                                disabledCreateJob: action.payload.disabledCreateJob,
                            };
                        } else {
                            return {
                                ...v,
                                isShowActionButton: false,
                            };
                        }
                    }),
            };
            tempState = temp;
            return tempState;
        },
        [ASSET_LIST_DELETE_ASSET_SUCCESS]: (state, action) => {
            const temp = {
                ...tempState,
                assetTypes: state.assetTypes,
                assetExtensions: state.assetExtensions,
                pending: false,
                data: state.data?.map((v) => {
                    if (v.id === action.payload) {
                        v.isDeleted = true;
                    }

                    return v;
                }),
            };
            tempState = temp;
            return tempState;
        },
        [ASSET_LIST_UPDATE_ASSET_STATUS]: (state, action) => {
            const { data } = action.payload;
            const temp = {
                ...tempState,
                assetTypes: state.assetTypes,
                assetExtensions: state.assetExtensions,
                data:
                    tempState.data &&
                    tempState.data.map((v) => {
                        if (v.id === data.id) {
                            v.status = data.status;
                        }
                        return v;
                    }),
            };
            tempState = temp;
            return tempState;
        },
        [ASSET_LIST_ASSET_SET_VALIDATION_ERROR]: (state, action) => {
            const { key, error } = action.payload;

            if (error) {
                return {
                    ...state,
                    validationErrors: {
                        ...state.validationErrors,
                        [key]: error,
                    },
                };
            }

            const validationErrors = state.validationErrors;
            if (validationErrors[key]) {
                delete validationErrors[key];
            }

            return {
                ...state,
                validationErrors,
            };
        },
        [ASSET_TYPES_LIST_PENDING]: (state) => {
            return {
                ...state,
                assetTypes: {
                    ...state.assetTypes,
                    pending: true,
                    error: false,
                },
            };
        },
        [ASSET_TYPES_LIST_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const assetTypes = { [MEDIA_TYPES.NONE]: [] };
            const allTypes = new Map();
            if (!data)
                return {
                    ...state,
                    assetTypes: {
                        ...state.assetTypes,
                        pending: false,
                        data: {},
                    },
                };

            data?.map((type) => {
                if (!assetTypes[type.mediaType]) assetTypes[type.mediaType] = [];
                assetTypes[type.mediaType].push({
                    type: type.type,
                    value: type.type,
                    label: type.type,
                });
                allTypes.set(type.type, {
                    type: type.type,
                    value: type.type,
                    label: type.type,
                });
            });
            allTypes.forEach((type) => assetTypes[MEDIA_TYPES.NONE].push(type));
            return {
                ...state,
                assetTypes: {
                    ...state.assetTypes,
                    pending: false,
                    data: assetTypes,
                },
            };
        },
        [ASSET_TYPES_LIST_FAIL]: (state) => {
            return {
                ...state,
                assetTypes: {
                    ...state.assetTypes,
                    pending: false,
                    error: true,
                },
            };
        },
        [ASSET_EXTENSIONS_LIST_PENDING]: (state) => {
            return {
                ...state,
                assetExtensions: {
                    ...state.assetExtensions,
                    pending: true,
                    error: false,
                },
            };
        },
        [ASSET_EXTENSIONS_LIST_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            if (!data)
                return {
                    ...state,
                    assetExtensions: {
                        ...state.assetExtensions,
                        pending: false,
                        data: [],
                    },
                };

            return {
                ...state,
                assetExtensions: {
                    ...state.assetExtensions,
                    pending: false,
                    data: data?.map((d) => ({
                        extension: d.extension,
                        value: d.extension,
                        label: d.extension,
                    })),
                },
            };
        },
        [ASSET_EXTENSIONS_LIST_FAIL]: (state) => {
            return {
                ...state,
                assetExtensions: {
                    ...state.assetExtensions,
                    pending: false,
                    error: true,
                },
            };
        },
        [UPDATE_FILTERED_ASSETS]: (state, action) => {
            return {
                ...state,
                data: action.payload,
            };
        },
    },
    initialState,
);

export const update = createAction(ASSETS_UPDATE);
export const clear = createAction(ASSET_LIST_CLEAR);
export const clearList = createAction(ASSET_LIST_LIST_CLEAR);
export const updateState = createAction(ASSET_LIST_UPDATE_STATE);
export const updateSearch = createAction(ASSET_LIST_UPDATE_SEARCH);
export const resetSearch = createAction(ASSET_LIST_RESET_SEARCH);
export const toggleActionButton = createAction(ASSET_LIST_TOGGLE_ACTION_BUTTON);
export const setValidationError = createAction(ASSET_LIST_ASSET_SET_VALIDATION_ERROR);

export const updateFilteredAssets = createAction(UPDATE_FILTERED_ASSETS);

export const getAssetsList = (projectId, search) => (dispatch, getState) => {
    // console.trace("load trace");
    const { stage, project, assets } = getState();
    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const targetProjectId = projectId || project.id;
    const searchParams = search || assets.search;
    const contextVersion = assets.contextVersion + 1;

    dispatch({ type: ASSET_LIST_PENDING });

    return new Promise((resolve, reject) => {
        getAssetsAPI(stageId, apiEndpoint, targetProjectId, searchParams)
            .then((response) => {
                const assets = response.data.assets;
                if (!assets) {
                    throw new Error("Get assets API server responsed wrong data spec.");
                }
                dispatch({
                    type: ASSET_LIST_GET_ASSET_LIST_ALL_SUCCESS,
                    payload: response,
                });
                resolve(response.data);
            })
            .catch((error) => {
                dispatch({
                    type: ASSET_LIST_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const getAssets = (projectId, search) => (dispatch, getState) => {
    // console.trace("load trace");
    const { stage, project, assets } = getState();
    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const targetProjectId = projectId || project.id;
    const searchParams = search || assets.search;
    const contextVersion = assets.contextVersion + 1;

    dispatch({ type: ASSET_LIST_PENDING });

    return new Promise((resolve, reject) => {
        getAssetsAPI(stageId, apiEndpoint, targetProjectId, searchParams)
            .then((response) => {
                const assets = response.data.assets;
                if (!assets) {
                    throw new Error("Get assets API server responsed wrong data spec.");
                }
                dispatch({
                    type: ASSET_LIST_GET_ASSET_LIST_SUCCESS,
                    payload: response,
                });
                if (assets) {
                    for (const asset of assets) {
                        const shouldPollStatus = asset.status === "ACTIVATING" || asset.status === "INACTIVATING";
                        if (!shouldPollStatus) continue;
                        const targetStatus = asset.status === "ACTIVATING" ? "ACTIVE" : "INACTIVE";
                        Polling(
                            async () => {
                                return await AssetApi.getStatus(stageId, apiEndpoint, targetProjectId, asset.id);
                            },
                            (response) => {
                                return (
                                    getState().assets.contextVersion !== contextVersion ||
                                    response.data.status === targetStatus
                                );
                            },
                            (response) => {
                                dispatch({
                                    type: ASSET_LIST_UPDATE_ASSET_STATUS,
                                    payload: response,
                                });
                            },
                            null,
                            3000,
                        ).run();
                    }
                }
                resolve(response.data);
            })
            .catch((error) => {
                dispatch({
                    type: ASSET_LIST_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const deleteAsset = (id, version) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: ASSET_LIST_PENDING });

    return new Promise((resolve, reject) => {
        deleteAssetAPI(stage.id, stage.endpoint, project.id, id, version)
            .then((response) => {
                dispatch({
                    type: ASSET_LIST_DELETE_ASSET_SUCCESS,
                    payload: id,
                });
                resolve(response);
            })
            .catch((error) => {
                dispatch({
                    type: ASSET_LIST_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

/**
 * Bulk Insert AssetCategoryMapper
 * @param {array} assets - asset list
 * @param {array} categories  - category list
 */
export const bulkAttachCategoriesAsync = (assets, categories) => (dispatch, getState) => {
    const { stage, project } = getState();

    return new Promise((resolve, reject) => {
        bulkAttachCategoriesToAssetAPI(stage.id, stage.endpoint, project.id, assets, categories)
            .then((response) => {
                resolve(response.data);
            })
            .catch((e) => {
                reject(e);
            });
    });
};

/**
 * Bulk Delete AssetCategoryMapper
 * @param {array} assets - asset list
 * @param {array} categories - category list
 */
export const bulkDetachCategoriesAsync = (assets, categories) => (dispatch, getState) => {
    const { stage, project } = getState();

    return new Promise((resolve, reject) => {
        bulkDetachCategoriesToAssetAPI(stage.id, stage.endpoint, project.id, assets, categories)
            .then((response) => {
                resolve(response.data);
            })
            .catch((e) => {
                reject(e);
            });
    });
};

export const disabledCreateJobButton = (asset) => {
    if (asset.status === "INACTIVE" || asset.mediaType !== "VIDEO") return true;

    return false;
};

export const listAssetTypes = () => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: ASSET_TYPES_LIST_PENDING });
    return new Promise((resolve, reject) => {
        listAssetTypesAPI(stage.id, stage.endpoint, project.id)
            .then((response) => {
                dispatch({
                    type: ASSET_TYPES_LIST_SUCCESS,
                    payload: response,
                });
                resolve(response);
            })
            .catch((error) => {
                dispatch({ type: ASSET_TYPES_LIST_FAIL });
                reject(error);
            });
    });
};

export const listAssetExtensions = () => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: ASSET_EXTENSIONS_LIST_PENDING });
    return new Promise((resolve, reject) => {
        listAssetExtensionsAPI(stage.id, stage.endpoint, project.id)
            .then((response) => {
                dispatch({
                    type: ASSET_EXTENSIONS_LIST_SUCCESS,
                    payload: response,
                });
                resolve(response);
            })
            .catch((error) => {
                dispatch({ type: ASSET_EXTENSIONS_LIST_FAIL });
                reject(error);
            });
    });
};

/**
 *
 * @param {object} pagination
 * @param {number} pagination.offset
 * @param {number} pagination.limit
 */
export const search =
    ({ search, pagination: { offset, limit } }) =>
    (dispatch, getState) => {};

export const getAllMediaTypes = () => (dispatch, getState) => {
    return CONSTANTS("ASSET_SEARCH_MEDIA_TYPES");
};

export const getAssetTypeSelectList =
    (mediaTypes = []) =>
    (dispatch, getState) => {
        const { stage, assets } = getState();
        const compVersion = compareVersions(VERSION_CM_3963_ADD_ASSET_TYPE, stage.version);
        if (compVersion <= 0) {
            if (mediaTypes.length > 0) {
                mediaTypes.map((mt) => {
                    assets.assetTypes.data[mt];
                });
            }
            const allTypes = [
                {
                    value: "",
                    label: i18n.t(`common::label::${"All types"}`),
                },
            ].concat((assets.assetTypes && assets.assetTypes.data[MEDIA_TYPES.NONE]) || []);
            return allTypes;
        } else {
            return CONSTANTS("ASSET_TYPES_SELECT");
        }
    };

export const getExtensionSelectList = () => (dispatch, getState) => {
    const { assets } = getState();
    return [
        {
            value: "",
            label: i18n.t(`common::label::${"All extensions"}`),
        },
    ].concat(
        ((assets.assetExtensions && assets.assetExtensions.data) || []).map((extension) => {
            return {
                value: extension.extension,
                label: extension.extension,
            };
        }),
    );
};

// export const getOwnerNumber = (ownerId) => (dispatch, getState) => {
//     const { stage, project } = getState();
//     return new Promise((resolve, reject) => {
//         getUsersInfoAPI(stage.endpoint, project.id, ownerId)
//             .then((response) => {
//                 resolve(response.data);
//             })
//             .catch((error) => {
//                 reject(error);
//             });
//     });
// };

// export const archivedAssets =
//     ({ projectId, archiveClass, targets }) =>
//     (dispatch, getState) => {
//         const { stage, project } = getState();
//         return new Promise((resolve, reject) => {
//             archivedAssetsAPI(projectId, archiveClass, targets);
//         });
//     };
