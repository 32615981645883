import Footer from "@components/footer";
import { checkIsMobile } from "@/utils/checkmobile";
import { Grid } from "@mzc-pdc/ui";

const LayoutForLxWelcome = ({ children }) => {
    const isMobile = checkIsMobile();
    return (
        <Grid container flexDirection={"column"} height={1}>
            <Grid item xs>
                {children}
            </Grid>
            <Grid item height={48}>
                <Footer />
            </Grid>
        </Grid>
    );
};

export default LayoutForLxWelcome;
