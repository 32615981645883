import React from "react";

/* styled */
import { StyledSpinner } from "./styled";
import theme from "@styles/theme";

export const Spinner = ({ type, color, size, baseColor, loadingColor, ...restProps }) => {
    return (
        <StyledSpinner className="spinner" {...restProps} type={type} color={color} size={size} theme={theme}>
            {type === "circle" && baseColor && loadingColor ? (
                <svg width="56px" height="56px" viewBox="0 0 100 100">
                    <g transform="translate(80,50)">
                        <g transform="rotate(0)">
                            <circle className="dot">
                                <animate
                                    attributeName="fill"
                                    values={`${baseColor};${baseColor};${loadingColor};${baseColor};${baseColor};`}
                                    repeatCount="indefinite"
                                    dur="1s"
                                    begin="-0.7333333333333333s"
                                />
                            </circle>
                        </g>
                    </g>
                    <g transform="translate(75.98076211353316,65)">
                        <g transform="rotate(29.999999999999996)">
                            <circle className="dot">
                                <animate
                                    attributeName="fill"
                                    values={`${baseColor};${baseColor};${loadingColor};${baseColor};${baseColor};`}
                                    repeatCount="indefinite"
                                    dur="1s"
                                    begin="-0.6666666666666666s"
                                />
                            </circle>
                        </g>
                    </g>
                    <g transform="translate(65,75.98076211353316)">
                        <g transform="rotate(59.99999999999999)">
                            <circle className="dot">
                                <animate
                                    attributeName="fill"
                                    values={`${baseColor};${baseColor};${loadingColor};${baseColor};${baseColor};`}
                                    repeatCount="indefinite"
                                    dur="1s"
                                    begin="-0.6s"
                                />
                            </circle>
                        </g>
                    </g>
                    <g transform="translate(50,80)">
                        <g transform="rotate(90)">
                            <circle className="dot">
                                <animate
                                    attributeName="fill"
                                    values={`${baseColor};${baseColor};${loadingColor};${baseColor};${baseColor};`}
                                    repeatCount="indefinite"
                                    dur="1s"
                                    begin="-0.5333333333333333s"
                                />
                            </circle>
                        </g>
                    </g>
                    <g transform="translate(35.00000000000001,75.98076211353316)">
                        <g transform="rotate(119.99999999999999)">
                            <circle className="dot">
                                <animate
                                    attributeName="fill"
                                    values={`${baseColor};${baseColor};${loadingColor};${baseColor};${baseColor};`}
                                    repeatCount="indefinite"
                                    dur="1s"
                                    begin="-0.4666666666666667s"
                                />
                            </circle>
                        </g>
                    </g>
                    <g transform="translate(24.01923788646684,65)">
                        <g transform="rotate(150.00000000000003)">
                            <circle className="dot">
                                <animate
                                    attributeName="fill"
                                    values={`${baseColor};${baseColor};${loadingColor};${baseColor};${baseColor};`}
                                    repeatCount="indefinite"
                                    dur="1s"
                                    begin="-0.4s"
                                />
                            </circle>
                        </g>
                    </g>
                    <g transform="translate(20,50.00000000000001)">
                        <g transform="rotate(180)">
                            <circle className="dot">
                                <animate
                                    attributeName="fill"
                                    values={`${baseColor};${baseColor};${loadingColor};${baseColor};${baseColor};`}
                                    repeatCount="indefinite"
                                    dur="1s"
                                    begin="-0.3333333333333333s"
                                />
                            </circle>
                        </g>
                    </g>
                    <g transform="translate(24.019237886466836,35.00000000000001)">
                        <g transform="rotate(209.99999999999997)">
                            <circle className="dot">
                                <animate
                                    attributeName="fill"
                                    values={`${baseColor};${baseColor};${loadingColor};${baseColor};${baseColor};`}
                                    repeatCount="indefinite"
                                    dur="1s"
                                    begin="-0.26666666666666666s"
                                />
                            </circle>
                        </g>
                    </g>
                    <g transform="translate(34.999999999999986,24.019237886466847)">
                        <g transform="rotate(239.99999999999997)">
                            <circle className="dot">
                                <animate
                                    attributeName="fill"
                                    values={`${baseColor};${baseColor};${loadingColor};${baseColor};${baseColor};`}
                                    repeatCount="indefinite"
                                    dur="1s"
                                    begin="-0.2s"
                                />
                            </circle>
                        </g>
                    </g>
                    <g transform="translate(49.99999999999999,20)">
                        <g transform="rotate(270)">
                            <circle className="dot">
                                <animate
                                    attributeName="fill"
                                    values={`${baseColor};${baseColor};${loadingColor};${baseColor};${baseColor};`}
                                    repeatCount="indefinite"
                                    dur="1s"
                                    begin="-0.13333333333333333s"
                                />
                            </circle>
                        </g>
                    </g>
                    <g transform="translate(65,24.019237886466843)">
                        <g transform="rotate(300.00000000000006)">
                            <circle className="dot">
                                <animate
                                    attributeName="fill"
                                    values={`${baseColor};${baseColor};${loadingColor};${baseColor};${baseColor};`}
                                    repeatCount="indefinite"
                                    dur="1s"
                                    begin="-0.06666666666666667s"
                                />
                            </circle>
                        </g>
                    </g>
                    <g transform="translate(75.98076211353316,34.999999999999986)">
                        <g transform="rotate(329.99999999999994)">
                            <circle className="dot">
                                <animate
                                    attributeName="fill"
                                    values={`${baseColor};${baseColor};${loadingColor};${baseColor};${baseColor};`}
                                    repeatCount="indefinite"
                                    dur="1s"
                                    begin="0s"
                                />
                            </circle>
                        </g>
                    </g>
                </svg>
            ) : (
                <>
                    {type === "dot" && (
                        <svg className="loader">
                            <circle className="dot" />
                            <circle className="dot" />
                            <circle className="dot" />
                        </svg>
                    )}
                    {type === "circle" && (
                        <>
                            {size === "medium" && (
                                <svg viewBox="0 0 100 100" height="42px" width="42px">
                                    <g transform="translate(80,50)">
                                        <g transform="rotate(0)">
                                            <circle className="dot" fillOpacity="1">
                                                <animate
                                                    attributeName="fill-opacity"
                                                    keyTimes="0;1"
                                                    dur="0.8s"
                                                    repeatCount="indefinite"
                                                    values="1;0"
                                                    begin="-0.7333333333333333s"
                                                />
                                            </circle>
                                        </g>
                                    </g>
                                    <g transform="translate(75.98076211353316,65)">
                                        <g transform="rotate(29.999999999999996)">
                                            <circle className="dot" fillOpacity="0.9166666666666666">
                                                <animate
                                                    attributeName="fill-opacity"
                                                    keyTimes="0;1"
                                                    dur="0.8s"
                                                    repeatCount="indefinite"
                                                    values="1;0"
                                                    begin="-0.6666666666666666s"
                                                />
                                            </circle>
                                        </g>
                                    </g>
                                    <g transform="translate(65,75.98076211353316)">
                                        <g transform="rotate(59.99999999999999)">
                                            <circle className="dot" fillOpacity="0.8333333333333334">
                                                <animate
                                                    attributeName="fill-opacity"
                                                    keyTimes="0;1"
                                                    dur="0.8s"
                                                    repeatCount="indefinite"
                                                    values="1;0"
                                                    begin="-0.6s"
                                                />
                                            </circle>
                                        </g>
                                    </g>
                                    <g transform="translate(50,80)">
                                        <g transform="rotate(90)">
                                            <circle className="dot" fillOpacity="0.75">
                                                <animate
                                                    attributeName="fill-opacity"
                                                    keyTimes="0;1"
                                                    dur="0.8s"
                                                    repeatCount="indefinite"
                                                    values="1;0"
                                                    begin="-0.5333333333333333s"
                                                />
                                            </circle>
                                        </g>
                                    </g>
                                    <g transform="translate(35.00000000000001,75.98076211353316)">
                                        <g transform="rotate(119.99999999999999)">
                                            <circle className="dot" fillOpacity="0.6666666666666666">
                                                <animate
                                                    attributeName="fill-opacity"
                                                    keyTimes="0;1"
                                                    dur="0.8s"
                                                    repeatCount="indefinite"
                                                    values="1;0"
                                                    begin="-0.4666666666666667s"
                                                />
                                            </circle>
                                        </g>
                                    </g>
                                    <g transform="translate(24.01923788646684,65)">
                                        <g transform="rotate(150.00000000000003)">
                                            <circle className="dot" fillOpacity="0.5833333333333334">
                                                <animate
                                                    attributeName="fill-opacity"
                                                    keyTimes="0;1"
                                                    dur="0.8s"
                                                    repeatCount="indefinite"
                                                    values="1;0"
                                                    begin="-0.4s"
                                                />
                                            </circle>
                                        </g>
                                    </g>
                                    <g transform="translate(20,50.00000000000001)">
                                        <g transform="rotate(180)">
                                            <circle className="dot" fillOpacity="0.5">
                                                <animate
                                                    attributeName="fill-opacity"
                                                    keyTimes="0;1"
                                                    dur="0.8s"
                                                    repeatCount="indefinite"
                                                    values="1;0"
                                                    begin="-0.3333333333333333s"
                                                />
                                            </circle>
                                        </g>
                                    </g>
                                    <g transform="translate(24.019237886466836,35.00000000000001)">
                                        <g transform="rotate(209.99999999999997)">
                                            <circle className="dot" fillOpacity="0.4166666666666667">
                                                <animate
                                                    attributeName="fill-opacity"
                                                    keyTimes="0;1"
                                                    dur="0.8s"
                                                    repeatCount="indefinite"
                                                    values="1;0"
                                                    begin="-0.26666666666666666s"
                                                />
                                            </circle>
                                        </g>
                                    </g>
                                    <g transform="translate(34.999999999999986,24.019237886466847)">
                                        <g transform="rotate(239.99999999999997)">
                                            <circle className="dot" fillOpacity="0.3333333333333333">
                                                <animate
                                                    attributeName="fill-opacity"
                                                    keyTimes="0;1"
                                                    dur="0.8s"
                                                    repeatCount="indefinite"
                                                    values="1;0"
                                                    begin="-0.2s"
                                                />
                                            </circle>
                                        </g>
                                    </g>
                                    <g transform="translate(49.99999999999999,20)">
                                        <g transform="rotate(270)">
                                            <circle className="dot" fillOpacity="0.25">
                                                <animate
                                                    attributeName="fill-opacity"
                                                    keyTimes="0;1"
                                                    dur="0.8s"
                                                    repeatCount="indefinite"
                                                    values="1;0"
                                                    begin="-0.13333333333333333s"
                                                />
                                            </circle>
                                        </g>
                                    </g>
                                    <g transform="translate(65,24.019237886466843)">
                                        <g transform="rotate(300.00000000000006)">
                                            <circle className="dot" fillOpacity="0.16666666666666666">
                                                <animate
                                                    attributeName="fill-opacity"
                                                    keyTimes="0;1"
                                                    dur="0.8s"
                                                    repeatCount="indefinite"
                                                    values="1;0"
                                                    begin="-0.06666666666666667s"
                                                />
                                            </circle>
                                        </g>
                                    </g>
                                    <g transform="translate(75.98076211353316,34.999999999999986)">
                                        <g transform="rotate(329.99999999999994)">
                                            <circle className="dot" fillOpacity="0.08333333333333333">
                                                <animate
                                                    attributeName="fill-opacity"
                                                    keyTimes="0;1"
                                                    dur="0.8s"
                                                    repeatCount="indefinite"
                                                    values="1;0"
                                                    begin="0s"
                                                />
                                            </circle>
                                        </g>
                                    </g>
                                </svg>
                            )}
                            {size === "small" && (
                                <svg width="15px" height="15px" viewBox="0 0 100 100">
                                    <g transform="translate(80,50)">
                                        <g transform="rotate(0)">
                                            <circle className="dot" fillOpacity="1">
                                                <animate
                                                    attributeName="fill-opacity"
                                                    keyTimes="0;1"
                                                    dur="1s"
                                                    repeatCount="indefinite"
                                                    values="1;0"
                                                    begin="-0.875s"
                                                />
                                            </circle>
                                        </g>
                                    </g>
                                    <g transform="translate(71.21320343559643,71.21320343559643)">
                                        <g transform="rotate(45)">
                                            <circle className="dot" fillOpacity="0.875">
                                                <animate
                                                    attributeName="fill-opacity"
                                                    keyTimes="0;1"
                                                    dur="1s"
                                                    repeatCount="indefinite"
                                                    values="1;0"
                                                    begin="-0.75s"
                                                />
                                            </circle>
                                        </g>
                                    </g>
                                    <g transform="translate(50,80)">
                                        <g transform="rotate(90)">
                                            <circle className="dot" fillOpacity="0.75">
                                                <animate
                                                    attributeName="fill-opacity"
                                                    keyTimes="0;1"
                                                    dur="1s"
                                                    repeatCount="indefinite"
                                                    values="1;0"
                                                    begin="-0.625s"
                                                />
                                            </circle>
                                        </g>
                                    </g>
                                    <g transform="translate(28.786796564403577,71.21320343559643)">
                                        <g transform="rotate(135)">
                                            <circle className="dot" fillOpacity="0.625">
                                                <animate
                                                    attributeName="fill-opacity"
                                                    keyTimes="0;1"
                                                    dur="1s"
                                                    repeatCount="indefinite"
                                                    values="1;0"
                                                    begin="-0.5s"
                                                />
                                            </circle>
                                        </g>
                                    </g>
                                    <g transform="translate(20,50.00000000000001)">
                                        <g transform="rotate(180)">
                                            <circle className="dot" fillOpacity="0.5">
                                                <animate
                                                    attributeName="fill-opacity"
                                                    keyTimes="0;1"
                                                    dur="1s"
                                                    repeatCount="indefinite"
                                                    values="1;0"
                                                    begin="-0.375s"
                                                />
                                            </circle>
                                        </g>
                                    </g>
                                    <g transform="translate(28.78679656440357,28.786796564403577)">
                                        <g transform="rotate(225)">
                                            <circle className="dot" fillOpacity="0.375">
                                                <animate
                                                    attributeName="fill-opacity"
                                                    keyTimes="0;1"
                                                    dur="1s"
                                                    repeatCount="indefinite"
                                                    values="1;0"
                                                    begin="-0.25s"
                                                />
                                            </circle>
                                        </g>
                                    </g>
                                    <g transform="translate(49.99999999999999,20)">
                                        <g transform="rotate(270)">
                                            <circle className="dot" fillOpacity="0.25">
                                                <animate
                                                    attributeName="fill-opacity"
                                                    keyTimes="0;1"
                                                    dur="1s"
                                                    repeatCount="indefinite"
                                                    values="1;0"
                                                    begin="-0.125s"
                                                />
                                            </circle>
                                        </g>
                                    </g>
                                    <g transform="translate(71.21320343559643,28.78679656440357)">
                                        <g transform="rotate(315)">
                                            <circle className="dot" fillOpacity="0.125">
                                                <animate
                                                    attributeName="fill-opacity"
                                                    keyTimes="0;1"
                                                    dur="1s"
                                                    repeatCount="indefinite"
                                                    values="1;0"
                                                    begin="0s"
                                                />
                                            </circle>
                                        </g>
                                    </g>
                                </svg>
                            )}
                        </>
                    )}
                </>
            )}
        </StyledSpinner>
    );
};
