import React, { useEffect, useRef } from "react";

const TabArrowButton = ({ onHandlePrevButton, onHandleNextButton, onHandleTabArrowButton, tabArrowVisible }) => {
    const panelTabArrow = useRef();

    useEffect(() => {
        onHandleTabArrowButton();
    }, []);

    useEffect(() => {
        if (tabArrowVisible) panelTabArrow.current.style.display = "flex";
    }, [tabArrowVisible]);

    return (
        <span className={"nav-arrows"} ref={panelTabArrow}>
            <button
                type="button"
                className={"nav-arrows-prev disabled"}
                onClick={(e) => {
                    onHandlePrevButton();
                    e.currentTarget.classList.add("disabled");
                    e.currentTarget.nextSibling.classList.remove("disabled");
                }}
            >
                <i className="sprite sprite-angle-left-large"></i>
            </button>
            <button
                type="button"
                className={"nav-arrows-next"}
                onClick={(e) => {
                    onHandleNextButton();
                    e.currentTarget.classList.add("disabled");
                    e.currentTarget.previousSibling.classList.remove("disabled");
                }}
            >
                <i className="sprite sprite-angle-right-large"></i>
            </button>
        </span>
    );
};

export default TabArrowButton;
