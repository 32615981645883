import { styled, alpha } from "@mui/material";
import { Avatar as MuiAvatar } from "@mzc-pdc/ui";

export const Avatar = styled(MuiAvatar)(({}) => ({
    "&.MuiAvatar-root": {
        svg: {
            marginRight: 0,
        },
    },
}));
