import React from 'react';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Notification = (props) => {
    return <ToastContainer {...props} />;
};
Notification.propTypes = {
    autoClose: PropTypes.number,
    hideProgressBar: PropTypes.bool,
    newestOnTop: PropTypes.bool,
    closeOnClick: PropTypes.bool,
    rtl: PropTypes.bool,
    pauseOnVisibilityChange: PropTypes.bool,
    draggable: PropTypes.bool,
    pauseOnHover: PropTypes.bool,
};
Notification.defaultProps = {
    autoClose: 3000,
    hideProgressBar: false,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnVisibilityChange: true,
    draggable: true,
    pauseOnHover: true,
};

export default Notification;