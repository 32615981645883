import { axios, axiosWithoutHeaders } from "@cores/axiosWrapper";
import { handleActions, createAction } from "redux-actions";
import compareVersions, { VERSION_CM_7510_SAFARI_ENCODING } from "@cores/version";
import { addPausedPollingApis } from "./pausedPollingApis";
import { JOB_ACTIONS } from "@constants";

export function getJobApi(stageId, apiEndpoint, projectId, jobId) {
    return axios.get(`${apiEndpoint}/batch-jobs/${jobId}`, {
        params,
        headers: {
            stageId,
            projectId,
        },
    });
}

export function listJobsApi(stageId, apiEndpoint, projectId, search, currentSession, types) {
    const { limit } = search;

    const params = {
        currentSession,
        types,
    };

    if (limit) {
        params.limit = limit;
    }

    return axios.get(`${apiEndpoint}/batch-jobs`, {
        params,
        headers: {
            stageId,
            projectId,
        },
    });
}

export function listTasksApi(stageId, apiEndpoint, projectId, jobId, currentSession) {
    const params = {
        currentSession,
    };

    return axios.get(`${apiEndpoint}/batch-jobs/${jobId}/tasks`, {
        params,
        headers: {
            stageId,
            projectId,
        },
    });
}

export function requestActionsApi(apiEndpoint, stageId, projectId, actions) {
    return axios.post(`${apiEndpoint}/v2/jobs/actions`, actions, {
        headers: {
            stageId,
            projectId,
        },
    });
}

export function createJobApi(apiEndpoint, stageId, stageVersion, projectId, param) {
    let headers = {
        stageId,
        projectId,
    };

    if (compareVersions(stageVersion, VERSION_CM_7510_SAFARI_ENCODING) >= 0) {
        param = Buffer.from(encodeURIComponent(JSON.stringify(param))).toString("base64");
        headers = {
            ...headers,
            "x-mzc-content-encoding": "base64",
        };
    }
    return axios.post(`${apiEndpoint}/batch-jobs`, param, { headers });
}

const initialState = {
    pending: false,
    error: false,
    data: [],
    search: {
        limit: 20,
    },
    totalCounts: 0,
};

const BULK_JOBS_PENDING = "BULK_JOBS_PENDING";
const BULK_JOBS_FAILURE = "BULK_JOBS_FAILURE";
const BULK_JOBS_GET_JOBS_LIST_SUCCESS = "BULK_JOBS_GET_JOBS_LIST_SUCCESS";
const BULK_JOBS_GET_JOBS_LIST_FAILURE = "BULK_JOBS_GET_JOBS_LIST_FAILURE";
const BULK_JOBS_UPDATE_SEARCH = "BULK_JOBS_UPDATE_SEARCH";
const BULK_JOBS_UPDATE_SINGLE_JOB = "BULK_JOBS_UPDATE_SINGLE_JOB";
const BULK_JOBS_UPDATE_MULTIPLE_JOBS = "BULK_JOBS_UPDATE_MULTIPLE_JOBS";
const BULK_JOBS_CREATE_JOB_SUCCESS = "BULK_JOBS_CREATE_JOB_SUCCESS";
const BULK_JOBS_CREATE_JOB_FAILURE = "BULK_JOBS_CREATE_JOB_FAILURE";

let polling = [];
let runningTasks = {};

export default handleActions(
    {
        [BULK_JOBS_PENDING]: (state, action) => {
            const newState = {
                ...state,
                pending: true,
                error: false,
            };
            return newState;
        },
        [BULK_JOBS_FAILURE]: (state, action) => {
            const newState = {
                ...state,
                pending: false,
                error: true,
            };
            return newState;
        },
        [BULK_JOBS_UPDATE_SEARCH]: (state, action) => {
            const payload = action.payload;
            const newState = {
                ...state,
                search: {
                    ...state.search,
                    ...payload,
                },
            };
            return newState;
        },
        [BULK_JOBS_GET_JOBS_LIST_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const { results, totalCount } = data;

            const addInitValue = results.map((item) => {
                item.isFolded = true;
                item.tasks = [];
                return item;
            });

            const newState = {
                ...state,
                error: false,
                data: addInitValue || [],
                totalCounts: totalCount || 0,
            };
            return newState;
        },
        [BULK_JOBS_UPDATE_SINGLE_JOB]: (state, action) => {
            const { job } = action.payload;
            const newData = state.data.map((item) => {
                if (item.jobId === job.jobId) {
                    item = {
                        ...item,
                        ...job,
                    };
                }
                return item;
            });
            const newState = {
                ...state,
                error: false,
                data: newData,
            };
            return newState;
        },
        [BULK_JOBS_UPDATE_MULTIPLE_JOBS]: (state, action) => {
            const { data } = action.payload;
            const { results } = data;
            let newData = JSON.parse(JSON.stringify(state.data));
            results.forEach((job) => {
                newData = newData.map((item) => {
                    if (job.jobId === item.jobId) {
                        const newItem = {
                            ...job,
                            isFolded: item.isFolded,
                            tasks: item.tasks,
                        };
                        item = newItem;
                    }
                    return item;
                });
            });
            const newState = {
                ...state,
                error: false,
                data: newData,
            };
            return newState;
        },
        [BULK_JOBS_GET_JOBS_LIST_FAILURE]: (state, action) => {
            const newState = {
                ...state,
                data: [],
                error: true,
            };
            return newState;
        },
        [BULK_JOBS_CREATE_JOB_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const { id } = data;
            const newState = {
                ...state,
                error: false,
            };

            return newState;
        },
        [BULK_JOBS_CREATE_JOB_FAILURE]: (state, action) => {
            const { response } = action.payload;
            const newState = {
                ...state,
                data: [],
                error: true,
                errorStatus: response && response.status,
            };

            return newState;
        },
    },
    initialState,
);

export const listTasks =
    ({ jobId }) =>
    (dispatch, getState) => {
        const { stage, project } = getState();
        const stageId = stage.id;
        const projectId = project.id;
        const apiEndpoint = stage.endpoint;

        return new Promise((resolve, reject) =>
            listTasksApi(stageId, apiEndpoint, projectId, jobId, true)
                .then(async (response) => {
                    resolve(response);
                })
                .catch((error) => {
                    // ToDO
                    reject(error);
                }),
        );
    };

const checkPollingStatus = (jobs) => {
    return jobs.filter((item) => {
        return !(item.status === "CANCELED" || item.status === "FAILED" || item.status === "SUCCEED");
    });
};

const doPolling = (stageId, apiEndpoint, projectId, search, dispatch) => {
    if (polling) {
        clearTimeout(polling);
    }
    polling = setTimeout(() => {
        listJobsApi(stageId, apiEndpoint, projectId, search, true, "BULK_JOB")
            .then((response) => {
                dispatch({
                    type: BULK_JOBS_UPDATE_MULTIPLE_JOBS,
                    payload: response,
                });
                const results = response.data.results;
                const pollingTargets = checkPollingStatus(results);
                if (pollingTargets.length > 0) {
                    doPolling(stageId, apiEndpoint, projectId, search, dispatch);
                }

                if (pollingTargets && pollingTargets.length > 0) {
                    pollingTargets.map((item) => {
                        const jobId = item.jobId;

                        const prevRunningJob = Object.keys(runningTasks).find((v) => v.split("_")[0] === jobId);
                        if (!prevRunningJob) pickupRunningTasks(stageId, apiEndpoint, projectId, pollingTargets);
                    });
                }
                const deduplicatePrevRunningJobs = [];
                Object.keys(runningTasks).map((task) => {
                    const split = task.split("_");
                    const jobId = split[0];
                    const job = results.find((v) => v.jobId === jobId);
                    if (job && !deduplicatePrevRunningJobs.find((v) => v.jobId === job.jobId))
                        deduplicatePrevRunningJobs.push(job);
                });

                onTaskSucceeded(stageId, apiEndpoint, projectId, deduplicatePrevRunningJobs, dispatch);
            })
            .catch((error) => {
                if (!navigator.onLine) {
                    dispatch(
                        addPausedPollingApis({
                            type: "BULK_JOB",
                            params: { stageId, apiEndpoint, projectId, search },
                        }),
                    );
                }
            });
    }, 4000);
};

export const listJobs = () => (dispatch, getState) => {
    const { stage, project, bulkJobs } = getState();
    const stageId = stage.id;
    const projectId = project.id;
    const apiEndpoint = stage.endpoint;
    const search = bulkJobs.search;
    dispatch({
        type: BULK_JOBS_PENDING,
    });

    if (polling) {
        clearTimeout(polling);
        runningTasks = {};
    }

    return new Promise((resolve, reject) =>
        listJobsApi(stageId, apiEndpoint, projectId, search, true, "BULK_JOB")
            .then(async (response) => {
                dispatch({
                    type: BULK_JOBS_GET_JOBS_LIST_SUCCESS,
                    payload: response,
                });
                if (response.data) {
                    const results = response.data.results;
                    const pollingTargets = checkPollingStatus(results);
                    if (pollingTargets.length > 0) {
                        doPolling(stageId, apiEndpoint, projectId, search, dispatch);
                    }

                    pickupRunningTasks(stageId, apiEndpoint, projectId, pollingTargets);
                }

                resolve(response);
            })
            .catch((error) => {
                if (!navigator.onLine) {
                    dispatch(
                        addPausedPollingApis({
                            type: "DOWNLOAD_JOB",
                            params: { stageId, apiEndpoint, projectId, search },
                        }),
                    );
                }
                // ToDO
                dispatch({
                    type: BULK_JOBS_GET_JOBS_LIST_FAILURE,
                    payload: error.response,
                });
                resolve(error);
            }),
    );
};

const pickupRunningTasks = (stageId, apiEndpoint, projectId, jobs) => {
    Promise.all(
        jobs.map(async (item) => {
            const tasks = await listTasksApi(stageId, apiEndpoint, projectId, item.jobId, true);
            const taskResults = tasks?.data?.results;
            const runningTaskList = taskResults && taskResults.length > 0 ? checkPollingStatus(taskResults) : [];
            runningTaskList.map((task) => {
                runningTasks[`${item.jobId}_${task.taskId}`] = task.status;
            });
        }),
    );
};

const onTaskSucceeded = (stageId, apiEndpoint, projectId, jobs, dispatch) => {
    Promise.all(
        jobs.map(async (job) => {
            const tasks = await listTasksApi(stageId, apiEndpoint, projectId, job.jobId, true);
            const taskResults = tasks?.data?.results;
            const item = {
                ...job,
                tasks: taskResults,
            };
            dispatch({
                type: BULK_JOBS_UPDATE_SINGLE_JOB,
                payload: { job: item },
            });

            const runningTaskList =
                taskResults &&
                taskResults.length > 0 &&
                taskResults.filter((v) => v.status === "SUCCEED" || v.status === "FAILED");

            runningTaskList &&
                runningTaskList.map((task) => {
                    const key = `${job.jobId}_${task.taskId}`;
                    const prevStatus = runningTasks[key];

                    if (!prevStatus) return;

                    delete runningTasks[key];
                });
        }),
    );
};

export const getJobStatus = (jobId) => (dispatch, getState) => {
    const { stage, project } = getState();
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;
    const stageId = stage.id;
    const actions = {
        action: JOB_ACTIONS.CREATE_ASSETS,
        content: {
            jobIds: [jobId],
        },
    };
    return new Promise((resolve, reject) => {
        return requestActionsApi(apiEndpoint, stageId, projectId, actions)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const createJob = (request) => (dispatch, getState) => {
    const { stage, project } = getState();
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;
    const stageId = stage.id;
    const stageVersion = stage.version;

    return new Promise((resolve, reject) => {
        return createJobApi(apiEndpoint, stageId, stageVersion, projectId, request)
            .then((response) => {
                dispatch({
                    type: BULK_JOBS_CREATE_JOB_SUCCESS,
                    payload: response,
                });

                resolve(response);
            })
            .catch((error) => {
                dispatch({
                    type: BULK_JOBS_CREATE_JOB_FAILURE,
                    payload: error,
                });

                reject(error);
            });
    });
};

export const updateSearch = createAction(BULK_JOBS_UPDATE_SEARCH);
export const updateJob = createAction(BULK_JOBS_UPDATE_SINGLE_JOB);
