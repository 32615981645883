import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import ObjectIcon from "@routes/sources/components/ObjectIcon";
import Tooltip from "@mui/material/Tooltip";
import ScrollArea from "../ScrollArea";
import { Spinner } from "@components_v2/spinner";
import {
    IconCaption,
    IconFile,
    IconImage,
    IconAudio,
    IconPath,
    IconVideo,
} from "@mzc-cloudplex/icons";
import { MEDIA_TYPES } from "@constants";
import convertToByte from "@cores/convertToByte";
import { getDetailDriveFolder } from "@modules/folderTreeView";

const ItemsSharedView = (props) => {
    const { t, item } = props;
    const dispatch = useDispatch();
    const [virtualFolderPath, setVirtualFolderPath] = useState(null);
    const [assetTooltip, setAssetTooltip] = useState(null);

    const displayFileName = useCallback((path) => {
        const fileName = path.split("/");
        if (path.lastIndexOf("/") === path.length - 1) return fileName[fileName.length - 2];
        return fileName[fileName.length - 1];
    }, []);

    const displayFilePath = useCallback((key) => {
        const path = key.substring(0, key.lastIndexOf("/"));
        if (key.lastIndexOf("/") === key.length - 1) {
            const newPath = path.substring(0, path.lastIndexOf("/"));
            return newPath;
        } else {
            return path;
        }
    }, []);

    const totalCount = useMemo(() => {
        if (!item) return "";

        if (item.job?.metadata?.domain === "STORAGE") {
            return (
                <div>
                    {t(`common::label::${"Total"}`)}{" "}
                    <strong className="text-primary">{item?.resourceSummary?.totalCount}</strong>
                    {item.readyStatus === "PREPARING" ? (
                        <>
                            {` - `}
                            <Spinner color="primary" size="small" type="circle" />
                            <span className="text-primary">
                                {` `} {t(`common::label::${"Calulating..."}`)}
                            </span>
                        </>
                    ) : (
                        <span>{` - ${convertToByte(item.totalSize)}`}</span>
                    )}
                </div>
            );
        }
        return (
            <div>
                {t(`common::label::${"Total"}`)}{" "}
                <strong className="text-primary">{item?.resourceSummary?.totalCount}</strong>
            </div>
        );
    }, [item]);

    useEffect(async () => {
        if (!assetTooltip) return;
        const asset = assetTooltip;

        if (asset.virtualFolder !== undefined && asset.virtualDrive !== undefined)
            //virtualFolder 존재시 전체경로 획득
            try {
                const folderPath = await dispatch(
                    getDetailDriveFolder({
                        driveId: asset.virtualDrive.id,
                        folderId: asset.virtualFolder.id,
                    }),
                );

                const virtualFolderPath = folderPath.parents.reverse().reduce((acc, cur) => {
                    if (cur.depth === 0) return `/${acc}`;
                    return `${cur.name}/${acc}`;
                }, `${folderPath.depth === 0 ? "/" : folderPath.name}`);

                setVirtualFolderPath(virtualFolderPath);
            } catch (e) {
                console.log(e);
            }
    }, [assetTooltip]);

    return (
        <React.Fragment>
            <div className="control">
                <div className="justify-content-end">{totalCount}</div>
            </div>

            <ScrollArea>
                <div className="list-items">
                    <ul>
                        {item?.job?.metadata?.targets?.map((target) => {
                            return item?.job?.metadata?.domain === "STORAGE"
                                ? target?.keys?.map((key) => {
                                      return (
                                          <li className={"no-border"}>
                                              <div className="file">
                                                  <div className="file-info">
                                                      <div className={"d-flex gap-1"}>
                                                          <span className="file-type">
                                                              <ObjectIcon
                                                                  path={key}
                                                                  isFolder={key.lastIndexOf("/") === key.length - 1}
                                                                  isFolderDisabled={true}
                                                              />
                                                          </span>
                                                          <span>
                                                              <strong className="file-name">
                                                                  {displayFileName(key)}
                                                              </strong>
                                                          </span>
                                                      </div>
                                                      {item.job?.metadata?.domain === "STORAGE" && (
                                                          <div className="nested d-flex">
                                                              <IconPath size={16} htmlColor={"#939bb7"} />
                                                              <small className={"text-secondary"}>
                                                                  {target.bucket + displayFilePath(key)}
                                                              </small>
                                                          </div>
                                                      )}
                                                  </div>
                                              </div>
                                          </li>
                                      );
                                  })
                                : target?.assets?.map((asset) => {
                                      return (
                                          <li className={"no-border"}>
                                              <div className="file">
                                                  <div className="file-info">
                                                      <div className={"d-flex gap-1"}>
                                                          <span className="file-type">
                                                              <div
                                                                  className={`format-img format-img-${asset.mediaType.toLowerCase()}`}
                                                              >
                                                                  {asset.mediaType === MEDIA_TYPES.VIDEO && (
                                                                      <IconVideo size={12} htmlColor={"#fff"} />
                                                                  )}
                                                                  {asset.mediaType === MEDIA_TYPES.AUDIO && (
                                                                      <IconAudio size={12} htmlColor={"#fff"} />
                                                                  )}
                                                                  {asset.mediaType === MEDIA_TYPES.IMAGE && (
                                                                      <IconImage size={12} htmlColor={"#fff"} />
                                                                  )}
                                                                  {asset.mediaType === MEDIA_TYPES.CAPTION && (
                                                                      <IconCaption size={12} htmlColor={"#fff"} />
                                                                  )}
                                                                  {asset.mediaType === MEDIA_TYPES.FILE && (
                                                                      <IconFile size={12} htmlColor={"#fff"} />
                                                                  )}
                                                              </div>
                                                          </span>
                                                          <span>
                                                              <strong className="file-name">{asset.name}</strong>
                                                              <span className={"file-count"}>
                                                                  {" "}
                                                                  {asset.elementsCount > 0 && (
                                                                      <>
                                                                          -{" "}
                                                                          <span className={"text-primary"}>
                                                                              {asset.elementsCount}
                                                                          </span>
                                                                      </>
                                                                  )}
                                                              </span>
                                                          </span>
                                                      </div>
                                                      {item.job?.metadata?.domain === "ASSET" &&
                                                          asset.virtualDrive &&
                                                          asset.virtualFolder && (
                                                              <div className="nested d-flex align-items-baseline gap-1">
                                                                  <Tooltip
                                                                      color={"primary"}
                                                                      title={
                                                                          virtualFolderPath
                                                                              ? `${asset.virtualDrive?.name}${virtualFolderPath}`
                                                                              : null
                                                                      }
                                                                      onOpen={() => setAssetTooltip(asset)}
                                                                      onClose={() => {
                                                                          setVirtualFolderPath(null);
                                                                          setAssetTooltip(null);
                                                                      }}
                                                                  >
                                                                      <span>
                                                                          <IconPath size={16} htmlColor={"#939bb7"} />
                                                                      </span>
                                                                  </Tooltip>
                                                                  <small className={"text-secondary"}>
                                                                      {asset.virtualFolder?.name}
                                                                  </small>
                                                              </div>
                                                          )}
                                                  </div>
                                              </div>
                                          </li>
                                      );
                                  });
                        })}
                    </ul>
                </div>
            </ScrollArea>
        </React.Fragment>
    );
};
export default withTranslation()(ItemsSharedView);
