import { handleActions, createAction } from "redux-actions";
import fp from "lodash/fp";
import { axios } from "../cores/axiosWrapper";
import queryString from "../cores/queryString";
import { getAsset } from "./asset";
import { getAssetAPI } from "./apis/asset";

/**
 * Search domain 목록 조회
 * @param {*} apiEndpoint
 * @param {*} params : ex) ?domain=storage&externalId=bucketName
 * @returns
 */
export const listRegisteredDomainsAPI = (apiEndpoint, projectId, params) => {
    return axios.get(`${apiEndpoint}/integrated-search/domains`, { params, headers: { projectId } });
};

/**
 * Search domain 등록
 * @param {} apiEndpoint
 * @param {*} domain : ex) storage | asset
 * @param {*} externalId  ex) storage인 경우 bucket 이름, asset인 경우 projectId
 * @returns
 */
const registerDomainAPI = (apiEndpoint, domain, externalId) => {
    return axios.post(`${apiEndpoint}/integrated-search/domains`, {
        domain,
        externalId,
    });
};

/**
 * Search domain 등록 해제
 * @param {} apiEndpoint
 * @param {*} domainId
 * @returns
 */
const unregisterDomainAPI = (apiEndpoint, domainId) => {
    return axios.delete(`${apiEndpoint}/integrated-search/domains/${domainId}`);
};

const getSearchAPI = (apiEndpoint, projectId, params) => {
    return axios.get(`${apiEndpoint}/integrated-search`, { params, headers: { projectId } });
};

const getAccessibleBucketsAPI = (apiEndpoint, params) => {
    return axios.get(`${apiEndpoint}/me/resource-buckets`);
};

const SET_DOMAIN = "SET_DOMAIN";
const SET_VALIDATION_ERROR = "SET_VALIDATION_ERROR";
const RESET_ADVANCED_SEARCH = "RESET_ADVANCED_SEARCH";
const RESET_SEARCH_FILTER = "RESET_SEARCH_FILTER";
const UPDATE_ADVANCED_SEARCH = "UPDATE_ADVANCED_SEARCH";
const GET_ACCESSIBLE_BUCKETS_PENDING = "GET_ACCESSIBLE_BUCKETS_PENDING";
const GET_ACCESSIBLE_BUCKETS_SUCCESS = "GET_ACCESSIBLE_BUCKETS_SUCCESS";
const GET_ACCESSIBLE_BUCKETS_FAILURE = "GET_ACCESSIBLE_BUCKETS_FAILURE";
const GET_SEARCH_PENDING = "GET_SEARCH_PENDING";
const GET_SEARCH_SUCCESS = "GET_SEARCH_SUCCESS";
const GET_SEARCH_FAILURE = "GET_SEARCH_FAILURE";
const UPDATE_SEARCH_QUERY = "UPDATE_SEARCH_QUERY";
const UPDATE_SEARCH_PAGINATION = "UPDATE_SEARCH_PAGINATION"; //pagination 관련 값 전체(offset, limit)에 사용
const UPDATE_ASSETS_PAGE = "UPDATE_ASSETS_PAGE";
const UPDATE_ASSETS_SIZE = "UPDATE_ASSETS_SIZE";
const UPDATE_FILTERED_DATA = "UPDATE_FILTERED_DATA";
const UPDATE_DETAIL_FILTER = "UPDATE_DETAIL_FILTER"; //STORAGE, ASSET 필터링 갱신
const RESET_SEARCH_DATA = "RESET_SEARCH_DATA";

const GET_LIST_REGISTERED_DOMAIN_PENDING = "GET_LIST_REGISTERED_DOMAIN_PENDING";
const GET_LIST_REGISTERED_DOMAIN_SUCCESS = "GET_LIST_REGISTERED_DOMAIN_SUCCESS";
const GET_LIST_REGISTERED_DOMAIN_FAILURE = "GET_LIST_REGISTERED_DOMAIN_FAILURE";
const ADD_LIST_REGISTERED_DOMAIN_SUCCESS = "ADD_LIST_REGISTERED_DOMAIN_SUCCESS";
const ADD_LIST_REGISTERED_DOMAIN_FAILURE = "ADD_LIST_REGISTERED_DOMAIN_FAILURE";
const DELETE_LIST_REGISTERED_DOMAIN_SUCCESS = "DELETE_LIST_REGISTERED_DOMAIN_SUCCESS";
const DELETE_LIST_REGISTERED_DOMAIN_FAILURE = "DELETE_LIST_REGISTERED_DOMAIN_FAILURE";

const TOGGLE_SHARE_REQUEST_MODAL = "TOGGLE_SHARE_REQUEST_MODAL";
const SET_SHARE_REQUEST_DATA = "SET_SHARE_REQUEST_DATA";

const initialState = {
    error: false,
    filter: {
        asset: {
            project: [],
            name: "", // name
            createdBy: "", // owner
            id: "",
            status: "",
            mediaType: [],
            createdAt: [], // createdAt ex) startAt ~ endAt
            type: [],
            duration: [], // duration ex) beginDuration~endDuration
            extensions: [],
            tags: [],
            fileMeta: [],
        },
        storage: {
            project: [],
            storageClass: "",
            lastModifiedDate: [], //lastModified ex)startDuration~endDuration
        },
    },
    search: {
        q: "",
        domain: "all",
        version: 0,
    },
    validationErrors: {
        name: "required",
    },
    searchData: {
        error: null,
        pending: false,
        data: [], //전체 데이터
        filteredData: [], //전체 데이터 중 선택된 탭, 필터링 조건에 맞는 데이터
        totalCount: 0,
        page: 0,
        size: 20,
    },
    accessibleBuckets: {
        error: null,
        pending: false,
        data: [],
    },
    registeredDomain: {
        asset: { domains: [], pending: false, error: null },
        storage: { domains: [], pending: false, error: null },
    },
    shareRequest: {
        isShowModal: false,
        data: null,
    },
};

const cloneInitialState = fp.cloneDeep(initialState);

export default handleActions(
    {
        [SET_VALIDATION_ERROR]: (state, action) => {
            return { ...state };
        },
        [RESET_SEARCH_FILTER]: (state, action) => {
            return {
                ...state,
                filter: {
                    ...cloneInitialState.filter,
                },
            };
        },
        [RESET_ADVANCED_SEARCH]: (state, action) => {
            return { ...initialState };
        },
        [SET_DOMAIN]: (state, action) => {
            return {
                ...state,
                search: { ...state.search, domain: action.payload },
            };
        },
        [GET_ACCESSIBLE_BUCKETS_PENDING]: (state) => {
            return { ...state, accessibleBuckets: { ...cloneInitialState.accessibleBuckets, pending: true } };
        },
        [GET_ACCESSIBLE_BUCKETS_SUCCESS]: (state, action) => {
            return {
                ...state,
                accessibleBuckets: { ...state.accessibleBuckets, pending: false, data: action.payload },
            };
        },
        [GET_ACCESSIBLE_BUCKETS_FAILURE]: (state, action) => {
            return {
                ...state,
                accessibleBuckets: { ...state.accessibleBuckets, pending: false, error: action.payload },
            };
        },
        [GET_SEARCH_PENDING]: (state, action) => {
            return {
                ...state,
                searchData: {
                    ...cloneInitialState.searchData,
                    totalCount: state.searchData?.totalCount,
                    pending: true,
                    limit: state.searchData.limit,
                },
                //filter: { ...cloneInitialState.filter },
                search: { ...state.search, version: state.search.version + 1 },
            };
        },
        [GET_SEARCH_FAILURE]: (state) => {
            return {
                ...state,
                searchData: { ...state.searchData, pending: false, error: true },
            };
        },
        [GET_SEARCH_SUCCESS]: (state, action) => {
            return {
                ...state,
                searchData: {
                    ...state.searchData,
                    pending: false,
                    data: action.payload.results,
                    filteredData: action.payload.results,
                    totalCount: action.payload.totalCount,
                },
            };
        },
        [RESET_SEARCH_DATA]: (state, { payload }) => {
            return {
                ...state,
                searchData: {
                    ...cloneInitialState.searchData,
                    totalCount: payload.preventDeleteTotalCount ? state.searchData.totalCount : 0,
                },
                search: { ...state.search, q: "" },
            };
        },
        [UPDATE_ASSETS_SIZE]: (state, action) => {
            return {
                ...state,
                searchData: {
                    ...state.searchData,
                    size: action.payload,
                },
            };
        },
        [UPDATE_ASSETS_PAGE]: (state, action) => {
            return {
                ...state,
                searchData: {
                    ...state.searchData,
                    page: action.payload,
                },
            };
        },
        [UPDATE_SEARCH_QUERY]: (state, action) => {
            return {
                ...state,
                search: {
                    ...state.search,
                    q: action.payload,
                },
            };
        },
        [UPDATE_FILTERED_DATA]: (state, action) => {
            return {
                ...state,
                searchData: {
                    ...state.searchData,
                    filteredData: action.payload,
                },
            };
        },
        [UPDATE_DETAIL_FILTER]: (state, action) => {
            if (action.payload.type === "storage") {
                return {
                    ...state,
                    filter: { ...state.filter, storage: { ...state.filter.storage, ...action.payload.data } },
                };
            } else if (action.payload.type === "asset") {
                return {
                    ...state,
                    filter: { ...state.filter, asset: { ...state.filter.asset, ...action.payload.data } },
                };
            }
            return state;
        },
        [GET_LIST_REGISTERED_DOMAIN_PENDING]: (state, action) => {
            return {
                ...state,
                registeredDomain: {
                    ...cloneInitialState.registeredDomain,
                    [action.payload.domain]: {
                        ...cloneInitialState.registeredDomain[action.payload.domain],
                        pending: true,
                    },
                },
            };
        },
        [GET_LIST_REGISTERED_DOMAIN_SUCCESS]: (state, action) => {
            return {
                ...state,
                registeredDomain: {
                    ...state.registeredDomain,
                    [action.payload.domain]: { domains: action.payload.data, pending: false },
                },
            };
        },
        [GET_LIST_REGISTERED_DOMAIN_FAILURE]: (state, action) => {
            return {
                ...state,
                registeredDomain: {
                    ...state.registeredDomain,
                    [action.payload.domain]: { error: action.payload.data, pending: false },
                    pending: false,
                },
            };
        },
        [ADD_LIST_REGISTERED_DOMAIN_SUCCESS]: (state, action) => {
            return {
                ...state,
                registeredDomain: {
                    ...state.registeredDomain,
                    [action.payload.domain]: {
                        domains: [...state.registeredDomain[action.payload.domain].domains, action.payload.data],
                        pending: false,
                    },
                },
            };
        },
        [ADD_LIST_REGISTERED_DOMAIN_FAILURE]: (state, action) => {
            return {
                ...state,
                registeredDomain: {
                    ...state.registeredDomain,
                    [action.payload.domain]: {
                        error: action.payload.data,
                        pending: false,
                    },
                },
            };
        },
        [DELETE_LIST_REGISTERED_DOMAIN_SUCCESS]: (state, action) => {
            return {
                ...state,
                registeredDomain: {
                    ...state.registeredDomain,
                    [action.payload.domain]: {
                        domains: state.registeredDomain[action.payload.domain]?.domains.filter(
                            (domain) => domain.id !== action.payload.data,
                        ),
                        pending: false,
                    },
                },
            };
        },
        [DELETE_LIST_REGISTERED_DOMAIN_FAILURE]: (state, action) => {
            return {
                ...state,
                registeredDomain: {
                    ...state.registeredDomain,
                    [action.payload.domain]: {
                        ...state.registeredDomain[action.payload.domain],
                        //error: action.payload.data,
                        pending: false,
                    },
                },
            };
        },
        [TOGGLE_SHARE_REQUEST_MODAL]: (state) => {
            const { shareRequest } = state;

            return {
                ...state,
                shareRequest: {
                    ...state.shareRequest,
                    isShowModal: !state.shareRequest.isShowModal,
                    data: state.shareRequest.isShowModal ? null : state.shareRequest.data,
                },
            };
        },
        [SET_SHARE_REQUEST_DATA]: (state, action) => {
            return { ...state, shareRequest: { ...state.shareRequest, data: action.payload } };
        },
    },
    initialState,
);

export const setDomain = createAction(SET_DOMAIN);
export const setValidationError = createAction(SET_VALIDATION_ERROR);
export const resetAdvancedSearch = createAction(RESET_ADVANCED_SEARCH);
export const resetSearchFilter = createAction(RESET_SEARCH_FILTER);
export const updateAdvancedSearch = createAction(UPDATE_ADVANCED_SEARCH);
export const updateSearchQuery = createAction(UPDATE_SEARCH_QUERY);
export const updateAssetsSize = createAction(UPDATE_ASSETS_SIZE);
export const updateFilteredData = createAction(UPDATE_FILTERED_DATA);
export const updateAssetsPage = createAction(UPDATE_ASSETS_PAGE);
export const updateDetailFilter = createAction(UPDATE_DETAIL_FILTER);
export const resetSearchData = createAction(RESET_SEARCH_DATA);
export const toggleShareRequestModal = createAction(TOGGLE_SHARE_REQUEST_MODAL);
export const setShareRequestData = createAction(SET_SHARE_REQUEST_DATA);

export const getSearch = (query) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: GET_SEARCH_PENDING });
    dispatch(updateSearchQuery(query.q));
    const searchVersion = getState().integratedSearchWithSE.search.version;

    return new Promise(async (resolve, reject) => {
        getSearchAPI(stage.endpoint, project.id, query)
            .then(async (response) => {
                if (searchVersion !== getState().integratedSearchWithSE.search.version) return;

                dispatch({ type: GET_SEARCH_SUCCESS, payload: response.data });

                //response.data.results에 대해 promise.all을 사용하여 상세를 요청한 뒤 403이 아닌 경우 asset.data에 덮어쓰기(통합검색 내용하고 다른가?)
                //403오류가 나는 경우 그냥 기존 asset.data써야함
                let changeDataList = { totalCount: response.data.totalCount, results: [] };
                if (response.data?.results?.length > 0)
                    changeDataList.results = await Promise.all(
                        response.data?.results?.map(async (data) => {
                            //TODO: async/await 없이 사용해도 되는가?
                            try {
                                if (data.domain === "storage") return data;

                                const detailData = await getAssetAPI(
                                    stage.id,
                                    stage.endpoint,
                                    data.metadata.project.id,
                                    data.metadata.id,
                                );
                                const newData = { ...data };
                                newData.metadata = detailData.data;
                                return newData;
                            } catch (error) {
                                console.log(error);
                                return data;
                            }
                        }),
                    );
                dispatch({ type: GET_SEARCH_SUCCESS, payload: changeDataList });
                resolve(response.data || {});
            })
            .catch((error) => {
                dispatch({ type: GET_SEARCH_FAILURE, payload: error });
                reject(error);
            });
    });
};

export const getAccessibleBuckets = (query) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: GET_ACCESSIBLE_BUCKETS_PENDING });

    return new Promise((resolve, reject) => {
        getAccessibleBucketsAPI(stage.endpoint, query)
            .then((response) => {
                dispatch({ type: GET_ACCESSIBLE_BUCKETS_SUCCESS, payload: response.data.buckets || {} });
                resolve(response.data || {});
            })
            .catch((error) => {
                dispatch({ type: GET_ACCESSIBLE_BUCKETS_FAILURE, payload: error });
                reject(error);
            });
    });
};
export const listRegisteredDomains = (domain, externalId) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: GET_LIST_REGISTERED_DOMAIN_PENDING, payload: { domain } });

    return new Promise((resolve, reject) => {
        listRegisteredDomainsAPI(stage.endpoint, project.id, { domain, externalId })
            .then((response) => {
                dispatch({
                    type: GET_LIST_REGISTERED_DOMAIN_SUCCESS,
                    payload: { domain, data: response.data.results },
                });
                resolve(response.data.results);
            })
            .catch((error) => {
                dispatch({
                    type: GET_LIST_REGISTERED_DOMAIN_FAILURE,
                    payload: { domain, data: error },
                });
                reject(error);
            });
    });
};

export const addRegisteredDomains = (domain, externalId) => (dispatch, getState) => {
    const { stage } = getState();

    return new Promise((resolve, reject) => {
        registerDomainAPI(stage.endpoint, domain, externalId)
            .then((response) => {
                dispatch({
                    type: ADD_LIST_REGISTERED_DOMAIN_SUCCESS,
                    payload: { domain, data: response.data },
                });
                resolve(response.data);
            })
            .catch((error) => {
                dispatch({
                    type: DELETE_LIST_REGISTERED_DOMAIN_FAILURE,
                    payload: { domain, data: error },
                });
                reject(error);
            });
    });
};

export const deleteRegisteredDomains = (domain, projectId, externalId) => (dispatch, getState) => {
    const { stage, project } = getState();

    return new Promise((resolve, reject) => {
        unregisterDomainAPI(stage.endpoint, projectId ?? project.id, externalId)
            .then((response) => {
                dispatch({
                    type: DELETE_LIST_REGISTERED_DOMAIN_SUCCESS,
                    payload: { domain, data: externalId },
                });
                resolve(response.data);
            })
            .catch((error) => {
                console.error(error);
                dispatch({
                    type: DELETE_LIST_REGISTERED_DOMAIN_FAILURE,
                    payload: { domain, data: error },
                });
                reject(error);
            });
    });
};
