import React, { useState, useEffect, useMemo, useCallback } from "react";
import { withTranslation, Trans, useTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import WithSelect from "@components/select";
import { UPLOAD_FILE_LOCATION_TYPE, CONSTANTS, JOB_ACTIONS } from "@constants";
import { getMyJobs, getMyJobTask, updateJob, updateSearch, getJobStatus } from "@modules/myJobs";
import { retryJob } from "@modules/downloadJobs";
import { setNotification } from "@modules/notification";
import { SelectLimitTags } from "@components_v2/select";
import { Divider, Grid, Stack } from "@mzc-pdc/ui";
import JobEmptyList from "@routes/rightsidebar/ui/job-empty-list";
import JobItem from "@routes/rightsidebar/ui/job-item";

let throttleHandler = false;

const FileJobPanel = ({ filter, jobIcon, summaries, taskComponent }) => {
    const { t } = useTranslation();

    const [limit, setLimit] = useState(20);
    const [viewmoreEnable, setViewmoreEnable] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState({});

    const { myJobs } = useSelector((state) => state);
    const dispatch = useDispatch();

    useEffect(() => {
        if (myJobs.totalCounts > myJobs.data.length) {
            setViewmoreEnable(true);
        } else {
            setViewmoreEnable(false);
        }
    }, [myJobs.data]);

    useEffect(() => {
        dispatch(updateSearch({ limit }));
    }, [limit]);

    const onChangeLimit = (limit) => {
        setLimit(limit);
    };

    const onHandleClickViewMore = async () => {
        await dispatch(updateSearch({ limit: myJobs.search.limit + limit }));
        await dispatch(getMyJobs());
    };

    const checkJobStatus = (jobId) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await dispatch(getJobStatus(jobId));
                const { results } = response.data;
                // console.log('results', results[0]);
                resolve(results[0].status);
            } catch (error) {
                reject("FAILED");
            }
        });
    };

    const startPollingJobStatus = async (jobId) => {
        const doSomething = (jobId) => {
            return new Promise((resolve) =>
                setTimeout(async () => {
                    const results = await checkJobStatus(jobId);
                    resolve(results);
                }, 2000),
            );
        };
        const loop = (jobId) =>
            doSomething(jobId).then((results) => {
                if (results === "SUCCEED" || results === "FAILED") {
                    return results;
                } else {
                    return loop(jobId);
                }
            });
        return new Promise((resolve, reject) => {
            loop(jobId)
                .then((results) => {
                    // console.log('all done!', results)
                    resolve(results);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

    const onRetry = async (jobId) => {
        try {
            await dispatch(retryJob(jobId));
            await dispatch(getMyJobs());
            const status = await startPollingJobStatus(jobId);
            const _response = await dispatch(getJobStatus(jobId));
            const { results } = _response.data;
            if (results && results[0].status === "FAILED") {
                const action = results[0].name;
                toastJustMessage("error", `${action} error.`);
            } else {
                let partialComplete = "";
                const action = results[0].name;
                if (results && results[0].partialFailure) {
                    partialComplete = "Partial ";
                }
                toastJustMessage("success", `${partialComplete}${action} completed.`);
            }
            await dispatch(getMyJobs());
        } catch (error) {
            toastErrorMessage(error);
            await dispatch(getMyJobs());
        }
    };

    const toastJustMessage = (type, message) => {
        dispatch(
            setNotification({
                type,
                contents: `${message}`,
            }),
        );
    };

    const toastErrorMessage = (error) => {
        const errorStatus = error.response && error.response.status;
        if (errorStatus === 400) {
            const errorMessage = error.response.data && error.response.data.message;
            dispatch(
                setNotification({
                    type: "error",
                    contents: `[Error:${errorStatus}] => ${errorMessage}`,
                }),
            );
        }
    };

    const onClickToggleFileListExpander = async (item) => {
        if (throttleHandler) {
            return;
        }
        if (item.isFolded !== undefined && !item.isFolded) {
            const job = {
                ...item,
                isFolded: !item.isFolded,
            };
            await dispatch(updateJob({ job }));
        } else {
            throttleHandler = true;
            try {
                const { data } = await dispatch(getMyJobTask({ jobId: item.jobId }));
                // console.log('get my tasks', data);
                const newTasks = data.results || [];
                const job = {
                    ...item,
                    isFolded: item.isFolded === undefined ? false : !item.isFolded,
                    tasks: newTasks,
                };
                await dispatch(updateJob({ job }));
                throttleHandler = false;
            } catch (error) {
                throttleHandler = false;
            }
        }
    };

    const onHandleClickFilter = useCallback(
        (id, value) => {
            setSelectedFilter({
                ...selectedFilter,
                [id]: value,
            });
        },
        [selectedFilter],
    );

    const filteredJobsData = useMemo(() => {
        const filteredJobsData = myJobs.data.filter((job) =>
            Object.keys(selectedFilter)
                .filter((key) => selectedFilter[key]?.length)
                .every((key) => selectedFilter[key].includes(job[key])),
        );

        const addValueToSummaries = (job) => {
            return summaries.map(({ key, ...rest }) => {
                return {
                    ...rest,
                    value: job[key],
                };
            });
        };

        const getTaskDetailComponent = (job) => {
            const TaskDetailComponent = taskComponent?.[job.action];

            if (TaskDetailComponent) {
                return <TaskDetailComponent metadata={job.metadata} job={{ action: job.action }} tasks={job.tasks} />;
            }
            return null;
        };

        return filteredJobsData.map((job) => {
            job.action = job.action ?? JOB_ACTIONS.UPLOAD;

            return {
                job: {
                    id: job.jobId,
                    action: job.action,
                    icon: jobIcon[job.action],
                    status: job.status,
                    summaryInfo: addValueToSummaries(job),
                },
                task: {
                    taskCount: job.taskCount,
                    isTaskDetailFolded: job.isFolded === undefined || job.isFolded,
                    taskDetailComponent: getTaskDetailComponent(job),
                },
                action: {
                    toggle: () => onClickToggleFileListExpander(job), //이걸 누르면 job이 바뀌면서 filteredJobsData가 바뀌고 rerender가 될텐데 괜찮나?
                    retry: () => onRetry(job.jobId),
                },
            };
        });
    }, [myJobs.data, selectedFilter, filter]);

    return (
        <React.Fragment>
            <Grid container pt={3} px={3} pb={2} gap={1} justifyContent={"center"} alignItems={"center"}>
                {filter &&
                    Object.entries(filter)?.map(([key, options]) => (
                        <Grid item xs>
                            <SelectLimitTags
                                displayEmpty
                                notched
                                label={key}
                                labelId={`select-label-asset-jobs-${key}`}
                                multiple={true}
                                value={selectedFilter[key] ?? []}
                                options={options}
                                MenuProps={{
                                    disablePortal: true,
                                }}
                                onChange={(e) => {
                                    onHandleClickFilter(key, e.target.value);
                                }}
                                limitTags={1}
                            />
                        </Grid>
                    ))}
            </Grid>
            <Divider sx={{ mx: 3 }} />
            <Stack flex={1} px={3} py={2.5} gap={4} sx={{ overflowY: "auto" }}>
                {filteredJobsData.length > 0 ? (
                    <>
                        {filteredJobsData.map((data) => {
                            return <JobItem {...data} key={`file-job-${data.job.id}`} />;
                        })}
                    </>
                ) : (
                    <JobEmptyList />
                )}
            </Stack>
            {myJobs.data?.length > 0 && (
                <div className="panel-footer">
                    <WithSelect
                        defaultValue={limit}
                        options={CONSTANTS("LIST_LIMITS")}
                        onChange={(option) => onChangeLimit(option.value)}
                    />
                    {viewmoreEnable ? (
                        <button
                            type={"button"}
                            className={"btn btn-outline-default"}
                            onClick={(e) => {
                                e.preventDefault();
                                onHandleClickViewMore();
                            }}
                        >
                            <span>{`View More`}</span>
                            <i className="sprite sprite-angle-down"></i>
                        </button>
                    ) : (
                        ""
                    )}
                </div>
            )}
        </React.Fragment>
    );
};

export default FileJobPanel;
