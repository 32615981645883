import { Box, Chip, Divider, Grid, IconButton, Stack, Tooltip } from "@mzc-pdc/ui";
import AssetTypeFlag from "@components_v2/icon-type/asset/asset-type-flag";
import { GridNameEllipsis } from "@components_v2/grid/grid.styled";
import Typography from "@components_v2/typography/typography";
import {
    IconCategory,
    IconErrorCircle,
    IconHierarchyFolded,
    IconOpenInNew,
    IconPath,
    IconProjects,
} from "@mzc-cloudplex/icons";
import theme from "@styles/theme";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import menuService from "@services/menuService";

const JobTaskCopyAssets = ({ metadata, tasks }) => {
    const { t } = useTranslation();

    const stage = useSelector(({ stage }) => stage);

    return (
        <>
            <Stack py={1.5} gap={2}>
                <Box>
                    <Typography variant="body3_700" color="text.secondary">
                        {t(`common::label::Source`, `Source`)}
                    </Typography>
                    <Stack mt={1} mb={2} px={1} gap={1}>
                        <Grid container gap={1} alignItems={"center"} flexWrap={"nowrap"}>
                            <IconProjects size={16} htmlColor={theme.palette.greySecondary[500]} />
                            <Typography variant="body3_400">
                                {stage.projects.find(({ id }) => id === metadata.projectId)?.name ?? "-"}
                            </Typography>
                        </Grid>
                    </Stack>
                    <Grid container alignItems={"center"}>
                        <AssetTypeFlag width={20} height={16} type={tasks[0]?.metadata?.mediaType} />
                        <GridNameEllipsis item maxWidth={"130px"}>
                            <Typography variant="body2_500">{tasks[0]?.metadata?.name}</Typography>
                        </GridNameEllipsis>
                        <Grid item sx={{ ml: 1 }}>
                            {tasks?.length > 1 ? (
                                <>
                                    <Typography variant="body3_400">
                                        <Trans
                                            i18nKey="common::label::and <emp>{{count}}</emp> others"
                                            components={{
                                                emp: <Typography variant="body3_700" color="primary.main"></Typography>,
                                            }}
                                            values={{
                                                count: tasks.length - 1,
                                            }}
                                        />
                                    </Typography>
                                </>
                            ) : (
                                <Grid item>
                                    <Typography variant="body3_400" color={"primary.main"}>
                                        (
                                        <Link
                                            href="#"
                                            target={"_blank"}
                                            to={menuService.paths.assetsAssetDetail(
                                                stage.id,
                                                metadata.projectId,
                                                tasks[0]?.metadata?.id,
                                            )}
                                            underline="hover"
                                        >
                                            {tasks[0]?.metadata?.id}
                                            <IconOpenInNew size={12} />
                                        </Link>
                                        )
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="body3_700" color="text.secondary">
                        {t(`common::label::Destination`, `Destination`)}
                    </Typography>
                    <Stack mt={1} px={1} gap={1}>
                        <Grid container gap={1} alignItems={"center"} flexWrap={"nowrap"}>
                            <IconProjects size={16} htmlColor={theme.palette.greySecondary[500]} />
                            <Typography variant="body3_400">
                                {stage.projects.find(({ id }) => id === metadata.destination.project?.id)?.name ?? "-"}
                            </Typography>
                        </Grid>
                        <Grid container gap={1} alignItems={"center"} flexWrap={"nowrap"}>
                            <IconPath size={16} htmlColor={theme.palette.greySecondary[500]} />
                            <Typography variant="body3_400">
                                {metadata.destination?.virtualFolder?.name ?? "-"}
                            </Typography>
                        </Grid>
                        <Grid container gap={1} flexWrap={"nowrap"}>
                            <Grid item>
                                <IconCategory size={16} htmlColor={theme.palette.greySecondary[500]} />
                            </Grid>
                            <Grid item>
                                <Grid container gap={0.5}>
                                    {metadata.destination?.categories?.length === 0 ? (
                                        "-"
                                    ) : (
                                        <>
                                            {metadata.destination?.categories?.map((category) => (
                                                <Chip label={category.name} size="small" variant="outlined" />
                                            ))}
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Stack>
                </Box>
                <Box>
                    {tasks?.map((task) => (
                        <>
                            <Grid container alignItems={"center"} gap={1}>
                                <Grid item sx={{ flexGrow: 0 }}>
                                    <IconHierarchyFolded
                                        size={6}
                                        htmlColor={theme.palette.greySecondary[500]}
                                        sx={{ mr: theme.spacing(1) }}
                                    />
                                    <AssetTypeFlag width={20} height={16} type={task.metadata?.mediaType} />
                                </Grid>
                                <Grid item sx={{ display: "flex" }}>
                                    <GridNameEllipsis item maxWidth={"110px"}>
                                        <Typography variant="body2_500">
                                            {task.metadata?.desiredName ?? task.metadata?.name}
                                        </Typography>
                                    </GridNameEllipsis>
                                    {task.status === "SUCCEED" && task.metadata?.desiredId && (
                                        <Grid item>
                                            <Typography variant="body3_400" color={"primary.main"} sx={{ ml: 1 }}>
                                                (
                                                <Link
                                                    href="#"
                                                    target={"_blank"}
                                                    to={menuService.paths.assetsAssetDetail(
                                                        stage.id,
                                                        metadata.destination.project?.id,
                                                        task.metadata.desiredId,
                                                    )}
                                                    underline="hover"
                                                >
                                                    {task.metadata?.desiredId}
                                                    <IconOpenInNew size={12} />
                                                </Link>
                                                )
                                            </Typography>
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item>
                                    {task.status === "FAILED" && (
                                        <Tooltip
                                            title={t(
                                                `common::code::${task.summary.exitMessage}`,
                                                task.summary.exitMessage ?? "",
                                            )}
                                        >
                                            <IconButton circled size="small">
                                                <IconErrorCircle size={16} color="error" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container mt={0.75} pl={2} alignItems="center">
                                <Typography variant="body3_400" color={"text.secondary"} sx={{ mr: theme.spacing(1) }}>
                                    {t(`common::label::Source`, `Source`)}
                                </Typography>
                                <GridNameEllipsis item maxWidth={"90px"}>
                                    <Typography variant="body3_400">{task.metadata?.name}</Typography>
                                </GridNameEllipsis>
                                <Grid item>
                                    <Typography variant="body3_400" color={"primary.main"} sx={{ ml: 1 }}>
                                        (
                                        <Link
                                            href="#"
                                            target={"_blank"}
                                            to={menuService.paths.assetsAssetDetail(
                                                stage.id,
                                                metadata.destination.project?.id,
                                                task.metadata.id,
                                            )}
                                            underline="hover"
                                        >
                                            {task.metadata?.id}
                                            <IconOpenInNew size={12} />
                                        </Link>
                                        )
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    ))}
                </Box>
            </Stack>
        </>
    );
};

export default JobTaskCopyAssets;
