import { IconCircle, IconCircleOutlined, IconCompleteCircle, IconErrorCircle } from "@mzc-cloudplex/icons";
import { Grid, Tooltip, IconButton, CircularProgress } from "@mzc-pdc/ui";
import theme from "@styles/theme";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@components_v2/typography/typography";

const StatusBadge = ({ status, showText }) => {
    const { t } = useTranslation();

    const parsedStatus = useMemo(() => status?.toUpperCase(), [status]);

    const toDisplayName = (parsedStatus) => {
        switch (parsedStatus) {
            case "INACTIVE_BY_ERROR":
                return t(`common::label::INACTIVE`);
            case "PREPARING":
                return t(`common::label::PREPARING`);
            case "UPLOADING":
                return t(`common::label::UPLOADING`, "UPLOADING");
            case "COMPLETED":
                return t(`common::label::COMPLETE`, "COMPLETE");
            case "SUBMITED":
                return t(`common::label::SUBMITED`, "SUBMITED");
            default:
                return t(`common::label::${parsedStatus}`, parsedStatus);
        }
    };

    const getStatusColor = () => {
        switch (parsedStatus) {
            case "INACTIVE_BY_ERROR":
            case "PROCESSING":
                return theme.palette.warning.main;
            case "ERROR":
                return theme.palette.error.main;
            case "PREPARING":
            case "UPLOADING":
            case "PROGRESSING":
            case "SUBMITTED":
                return theme.palette.primary.main;
            case "COMPLETED":
            case "READY":
            case "ACTIVATING":
            case "ACTIVE":
                return theme.palette.success.main;
            case "DRAFT":
            case "INACTIVING":
            case "INACTIVE":
                return theme.palette.secondary[500];
            default:
                return undefined;
        }
    };

    const getIconColor = () => {
        switch (parsedStatus) {
            case "INACTIVE_BY_ERROR":
            case "PROCESSING":
                return "warning";
            case "ERROR":
                return "error";
            case "PREPARING":
            case "UPLOADING":
            case "PROGRESSING":
            case "SUBMITTED":
                return "primary";
            case "COMPLETED":
            case "READY":
            case "ACTIVATING":
            case "ACTIVE":
                return "success";
            case "DRAFT":
            case "INACTIVING":
            case "INACTIVE":
                return "grey";
            default:
                return undefined;
        }
    };

    return (
        <>
            <Tooltip
                title={!showText ? t(`common::label::${parsedStatus}`, parsedStatus) : ""}
                sx={{ display: `inline-flex` }}
            >
                <Grid item sx={{ mr: theme.spacing(0.75) }}>
                    {["ACTIVATING", "ACTIVE"].includes(parsedStatus) && <IconCircle size={12} color={"success"} />}
                    {["INACTIVATING", "INACTIVE", "DRAFT"].includes(parsedStatus) && (
                        <IconCircleOutlined size={12} color={getIconColor()} />
                    )}
                    {["PROGRESSING", "PROCESSING", "SUBMITTED"].includes(parsedStatus) && (
                        <CircularProgress size={12} color={getIconColor()} />
                    )}
                    {parsedStatus === "READY" && <IconCompleteCircle size={12} htmlColor={"inherit"} />}
                    {parsedStatus === "ERROR" && <IconErrorCircle size={12} color={"error"} />}
                    {parsedStatus === "COMPLETED" && <IconCompleteCircle size={12} color={"success"} />}
                </Grid>
            </Tooltip>
            {showText && (
                <Typography variant={"body1_400"} color={getStatusColor()}>
                    {toDisplayName(parsedStatus)}
                </Typography>
            )}
        </>
    );
};

export default StatusBadge;
