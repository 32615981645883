import { Toolbar } from "@mzc-pdc/ui";
import Footer from "@components/footer";
import PropertyPanel from "@components/property-panel";
import RightSideBar from "@routes/rightsidebar";
import React from "react";

const LayoutForPreview = ({ children }) => {
    // const innerHeight = window.innerHeight;

    return (
        <div style={{ flex: 1 }}>
            <section className={"container"}>
                <div className="contents" style={{ overflowY: "auto" }}>
                    {children}
                </div>
            </section>
        </div>
    );
};

export default LayoutForPreview;
