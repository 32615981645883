const LinkPopupIcon = () => {
    return (
        <i className="sprite">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                <path d="M11,5V1.666L4.669,8,4,7.331,10.334,1H7V0h5V5H11ZM1,11H9V7h1v4a1,1,0,0,1-1,1H1a1,1,0,0,1-1-1V3A1,1,0,0,1,1,2H5V3H1v8Z" />
            </svg>
        </i>
    );
};

export default LinkPopupIcon;
