import {
    axios,
    // axiosWithoutHeaders
} from "../cores/axiosWrapper";

const getFilesInS3 = (stageId, apiEndpoint, url, projectId) => {
    return axios.head(`${apiEndpoint}/aws/s3?path=${encodeURIComponent(url)}`, {
        headers: {
            stageId,
            projectId
        }
    });
};

export default getFilesInS3;