import { handleActions, createAction } from "redux-actions";
import { axios } from "../cores/axiosWrapper";
import { getCategoryGroupAPI } from "./apis/categoryGroup";
import fp from "lodash/fp";

function getMetadataTemplateAPI(apiEndpoint, projectId, params) {
    return axios.get(`${apiEndpoint}/v3/metadata/templates`, {
        params: params,
        headers: {
            projectId,
        },
    });
}

const getDetailMetadataTemplateAPI = ({ apiEndpoint, projectId, templateId }) =>
    axios.get(`${apiEndpoint}/v3/metadata/templates/${templateId}`, { headers: { projectId } });

const updateDetailMetadataTemplateAPI = ({ apiEndpoint, projectId, templateId, params }) =>
    axios.patch(`${apiEndpoint}/v3/metadata/templates/${templateId}`, params, { headers: { projectId } });

const deleteMetadataTemplateAPI = (apiEndpoint, projectId, templateId) => {
    return axios.delete(`${apiEndpoint}/v3/metadata/templates/${templateId}`, {
        headers: {
            projectId,
        },
    });
};

const createMetadataTemplateAPI = ({ apiEndpoint, projectId, params }) =>
    axios.post(`${apiEndpoint}/v3/metadata/templates`, params, { headers: { projectId } });

const registerMetadataTemplateDomainAPI = ({ apiEndpoint, projectId, templateId, params }) =>
    axios.put(`${apiEndpoint}/v3/metadata/templates/${templateId}/registrations`, params, { headers: { projectId } });

//action type
const METADATA_TEMPLATE_LIST_PENDING = "METADATA_TEMPLATE_LIST_PENDING";
const METADATA_TEMPLATE_LIST_SUCCESS = "METADATA_TEMPLATE_LIST_SUCCESS";
const METADATA_TEMPLATE_LIST_FAILURE = "METADATA_TEMPLATE_LIST_FAILURE";
const ALL_METADATA_TEMPLATE_LIST_PARTIAL_SUCCESS = "ALL_METADATA_TEMPLATE_LIST_PARTIAL_SUCCESS";
const ALL_METADATA_TEMPLATE_LIST_SUCCESS = "ALL_METADATA_TEMPLATE_LIST_SUCCESS";
const METADATA_TEMPLATE_LIST_UPDATE_SEARCH = "METADATA_TEMPLATE_LIST_UPDATE_SEARCH";
const METADATA_TEMPLATE_LIST_UPDATE_DATEFILTER = "METADATA_TEMPLATE_LIST_UPDATE_DATEFILTER";
const METADATA_TEMPLATE_LIST_RESET_SEARCH = "METADATA_TEMPLATE_LIST_RESET_SEARCH";
const GET_DETAIL_METADATA_TEMPLATE_PENDING = "GET_DETAIL_METADATA_TEMPLATE_PENDING";
const GET_DETAIL_METADATA_TEMPLATE_SUCCESS = "GET_DETAIL_METADATA_TEMPLATE_SUCCESS";
const GET_DETAIL_METADATA_TEMPLATE_FAILURE = "GET_DETAIL_METADATA_TEMPLATE_FAILURE";
const UPDATE_DETAIL_METADATA_TEMPLATE_PENDING = "UPDATE_DETAIL_METADATA_TEMPLATE_PENDING";
const UPDATE_DETAIL_METADATA_TEMPLATE_SUCCESS = "UPDATE_DETAIL_METADATA_TEMPLATE_SUCCESS";
const UPDATE_DETAIL_METADATA_TEMPLATE_FAILURE = "UPDATE_DETAIL_METADATA_TEMPLATE_FAILURE";
const TEMPLATE_SET_VALIDATION_ERROR = "TEMPLATE_SET_VALIDATION_ERROR";
const METADATA_TEMPLATE_ADD_ATTRIBUTION = "METADATA_TEMPLATE_ADD_ATTRIBUTION";
const METADATA_TEMPLATE_UPDATE_ATTRIBUTION = "METADATA_TEMPLATE_UPDATE_ATTRIBUTION";
const METADATA_TEMPLATE_UPDATE_PRESET = "METADATA_TEMPLATE_UPDATE_PRESET";
const METADATA_TEMPLATE_REMOVE_ATTRIBUTION = "METADATA_TEMPLATE_REMOVE_ATTRIBUTION";
const METADATA_TEMPLATE_UPDATE_DATA = "METADATA_TEMPLATE_UPDATE_DATA";
const CREATE_METADATA_TEMPLATE_PENDING = "CREATE_METADATA_TEMPLATE_PENDING";
const CREATE_METADATA_TEMPLATE_SUCCESS = "CREATE_METADATA_TEMPLATE_SUCCESS";
const CREATE_METADATA_TEMPLATE_FAILURE = "CREATE_METADATA_TEMPLATE_FAILURE";
const REGISTER_METADATA_TEMPLATE_DOMAIN_PENDING = "REGISTER_METADATA_TEMPLATE_DOMAIN_PENDING";
const REGISTER_METADATA_TEMPLATE_DOMAIN_SUCCESS = "REGISTER_METADATA_TEMPLATE_DOMAIN_SUCCESS";
const REGISTER_METADATA_TEMPLATE_DOMAIN_FAILURE = "REGISTER_METADATA_TEMPLATE_DOMAIN_FAILURE";
const METADATA_TEMPLATE_CLEAR = "METADATA_TEMPLATE_CLEAR";

//reducer
const initialState = {
    metadataTemplateList: {
        pending: false,
        error: false,
        data: null,
    },
    search: {
        createdById: null,
        name: null,
        createdAt: null,
    },
    dateFilter: {
        startAt: null,
        endAt: null,
    },
    metadataTemplateAction: {
        pending: false,
        error: false,
        data: null,
    },
    detailMetadataTemplate: {
        pending: false,
        error: null,
        data: null,
    },
    updateMetadataTemplate: {
        pending: false,
        error: null,
    },
    metadataTemplateItems: {
        name: "",
        description: "",
        preset: {
            namePrefix: "",
            tags: [],
            categoryGroup: "",
            categories: [],
            attributions: [],
        },
        validationErrors: {
            name: "required",
            preset: "required",
        },
    },
    registerDomain: { pending: false, error: null },
};

export default handleActions(
    {
        [METADATA_TEMPLATE_LIST_PENDING]: (state, action) => {
            return {
                ...state,
                metadataTemplateList: {
                    ...state.metadataTemplateList,
                    pending: true,
                    data: null,
                    error: null,
                },
                detailMetadataTemplate: {
                    ...initialState.detailMetadataTemplate,
                },
            };
        },
        [METADATA_TEMPLATE_LIST_SUCCESS]: (state, action) => {
            return {
                ...state,
                metadataTemplateAction: {
                    ...state.metadataTemplateAction,
                    pending: false,
                    data: null,
                },
                metadataTemplateList: {
                    ...state.metadataTemplateList,
                    data: action.payload,
                    pending: false,
                },
            };
        },
        [METADATA_TEMPLATE_LIST_FAILURE]: (state, action) => {
            return {
                ...state,
                metadataTemplateList: {
                    ...state.metadataTemplateList,
                    error: action.payload,
                    pending: false,
                },
            };
        },
        [ALL_METADATA_TEMPLATE_LIST_PARTIAL_SUCCESS]: (state, action) => {
            return {
                ...state,
                metadataTemplateList: {
                    ...state.metadataTemplateList,
                    data: [...(state.metadataTemplateList.data || []), ...action.payload],
                    pending: false,
                },
            };
        },
        [ALL_METADATA_TEMPLATE_LIST_SUCCESS]: (state, action) => {
            return {
                ...state,
                metadataTemplateList: {
                    ...state.metadataTemplateList,
                    pending: false,
                },
            };
        },
        [METADATA_TEMPLATE_LIST_UPDATE_SEARCH]: (state, action) => {
            return {
                ...state,
                search: {
                    ...state.search,
                    ...action.payload,
                },
            };
        },
        [METADATA_TEMPLATE_LIST_UPDATE_DATEFILTER]: (state, action) => {
            return {
                ...state,
                dateFilter: action.payload,
            };
        },
        [METADATA_TEMPLATE_LIST_RESET_SEARCH]: (state, action) => {
            return {
                ...state,
                search: {
                    createdById: null,
                    name: null,
                    createdAt: null,
                },
                dateFilter: {
                    startAt: null,
                    endAt: null,
                },
            };
        },
        [GET_DETAIL_METADATA_TEMPLATE_PENDING]: (state, action) => {
            return {
                ...state,
                detailMetadataTemplate: {
                    pending: true,
                    error: null,
                    data: null,
                },
            };
        },
        [GET_DETAIL_METADATA_TEMPLATE_SUCCESS]: (state, action) => {
            return {
                ...state,
                detailMetadataTemplate: {
                    ...state.detailMetadataTemplate,
                    pending: false,
                    data: action.payload,
                },
            };
        },
        [GET_DETAIL_METADATA_TEMPLATE_FAILURE]: (state, action) => {
            return {
                ...state,
                detailMetadataTemplate: {
                    ...state.detailMetadataTemplate,
                    pending: false,
                    error: action.payload,
                },
            };
        },
        [UPDATE_DETAIL_METADATA_TEMPLATE_PENDING]: (state, action) => {
            return {
                ...state,
                updateMetadataTemplate: {
                    pending: true,
                    error: null,
                },
            };
        },
        [UPDATE_DETAIL_METADATA_TEMPLATE_SUCCESS]: (state, action) => {
            return {
                ...state,
                detailMetadataTemplate: {
                    ...state.detailMetadataTemplate,
                    data: action.payload,
                },
                updateMetadataTemplate: {
                    pending: false,
                    error: null,
                },
            };
        },
        [UPDATE_DETAIL_METADATA_TEMPLATE_FAILURE]: (state, action) => {
            return {
                ...state,
                updateMetadataTemplate: {
                    pending: false,
                    error: action.payload,
                },
            };
        },
        [METADATA_TEMPLATE_ADD_ATTRIBUTION]: (state, action) => {
            return {
                ...state,
                metadataTemplateItems: {
                    ...state.metadataTemplateItems,
                    preset: {
                        ...state.metadataTemplateItems.preset,
                        attributions: state.metadataTemplateItems.preset.attributions.concat({
                            id: action.payload,
                            key: "",
                            value: "",
                        }),
                    },
                },
            };
        },
        [TEMPLATE_SET_VALIDATION_ERROR]: (state, action) => {
            const { key, error } = action.payload;

            if (error) {
                return {
                    ...state,
                    metadataTemplateItems: {
                        ...state.metadataTemplateItems,
                        validationErrors: {
                            ...state.metadataTemplateItems.validationErrors,
                            [key]: error,
                        },
                    },
                };
            }

            const validationErrors = state.metadataTemplateItems.validationErrors;
            if (validationErrors[key]) {
                delete validationErrors[key];
            }

            return {
                ...state,
                metadataTemplateItems: {
                    ...state.metadataTemplateItems,
                    validationErrors,
                },
            };
        },
        [METADATA_TEMPLATE_UPDATE_ATTRIBUTION]: (state, action) => {
            const data = action.payload;

            return {
                ...state,
                metadataTemplateItems: {
                    ...state.metadataTemplateItems,
                    preset: {
                        ...state.metadataTemplateItems.preset,
                        attributions: state.metadataTemplateItems.preset.attributions.map((a, i) => {
                            if (a.id === data.id) {
                                a.key = data.key === undefined ? a.key : data.key;
                                a.value = data.value === undefined ? a.value : data.value;
                            }

                            return a;
                        }),
                    },
                },
            };
        },
        [METADATA_TEMPLATE_UPDATE_PRESET]: (state, action) => {
            return {
                ...state,
                metadataTemplateItems: {
                    ...state.metadataTemplateItems,
                    preset: {
                        ...state.metadataTemplateItems.preset,
                        ...action.payload,
                    },
                },
            };
        },
        [METADATA_TEMPLATE_REMOVE_ATTRIBUTION]: (state, action) => {
            return {
                ...state,
                metadataTemplateItems: {
                    ...state.metadataTemplateItems,
                    preset: {
                        ...state.metadataTemplateItems.preset,
                        attributions: state.metadataTemplateItems.preset.attributions.filter(
                            (v) => v.id !== action.payload,
                        ),
                    },
                },
            };
        },
        [METADATA_TEMPLATE_UPDATE_DATA]: (state, action) => {
            return {
                ...state,
                metadataTemplateItems: {
                    ...state.metadataTemplateItems,
                    ...action.payload,
                },
            };
        },
        [CREATE_METADATA_TEMPLATE_PENDING]: (state, action) => {
            return {
                ...state,
                metadataTemplateAction: {
                    ...state.metadataTemplateAction,
                    pending: true,
                },
            };
        },
        [CREATE_METADATA_TEMPLATE_SUCCESS]: (state, action) => {
            return {
                ...state,
                metadataTemplateAction: {
                    ...state.metadataTemplateAction,
                    pending: false,
                    data: action.payload,
                },
            };
        },
        [CREATE_METADATA_TEMPLATE_FAILURE]: (state, action) => {
            return {
                ...state,
                metadataTemplateAction: {
                    ...state.metadataTemplateAction,
                    pending: false,
                    error: action.payload,
                },
            };
        },
        [REGISTER_METADATA_TEMPLATE_DOMAIN_PENDING]: (state) => {
            return {
                ...state,
                registerDomain: {
                    ...state.registerDomain,
                    pending: true,
                    error: null,
                },
            };
        },
        [REGISTER_METADATA_TEMPLATE_DOMAIN_SUCCESS]: (state) => {
            return {
                ...state,
                registerDomain: {
                    ...state.registerDomain,
                    pending: false,
                },
            };
        },
        [REGISTER_METADATA_TEMPLATE_DOMAIN_FAILURE]: (state, action) => {
            return {
                ...state,
                registerDomain: {
                    ...state.registerDomain,
                    pending: false,
                    error: action.payload,
                },
            };
        },
        [METADATA_TEMPLATE_CLEAR]: (state, action) => {
            return { ...initialState };
        },
    },
    initialState,
);

export const updateSearch = createAction(METADATA_TEMPLATE_LIST_UPDATE_SEARCH);
export const updateDateFilter = createAction(METADATA_TEMPLATE_LIST_UPDATE_DATEFILTER);
export const resetSearch = createAction(METADATA_TEMPLATE_LIST_RESET_SEARCH);
export const setValidationError = createAction(TEMPLATE_SET_VALIDATION_ERROR);
export const addAttribution = createAction(METADATA_TEMPLATE_ADD_ATTRIBUTION);
export const updateAttribution = createAction(METADATA_TEMPLATE_UPDATE_ATTRIBUTION);
export const removeAttribution = createAction(METADATA_TEMPLATE_REMOVE_ATTRIBUTION);
export const updatePreset = createAction(METADATA_TEMPLATE_UPDATE_PRESET);
export const updateData = createAction(METADATA_TEMPLATE_UPDATE_DATA);
export const clearMetadataTemplate = createAction(METADATA_TEMPLATE_CLEAR);

export const getMetadataTemplate =
    ({ searchParams }) =>
    async (dispatch, getState) => {
        const { stage, project } = getState();

        dispatch({ type: METADATA_TEMPLATE_LIST_PENDING });
        return new Promise((resolve, reject) => {
            const projectId = project.id;
            getMetadataTemplateAPI(stage.endpoint, projectId, searchParams)
                .then((response) => {
                    dispatch({
                        type: METADATA_TEMPLATE_LIST_SUCCESS,
                        payload: response.data?.results,
                    });
                    resolve(response.data?.results);
                })
                .catch((error) => {
                    dispatch({
                        type: METADATA_TEMPLATE_LIST_FAILURE,
                        payload: error,
                    });
                    reject(error);
                });
        });
    };

export const getAllMetadataTemplate =
    ({ searchParams }) =>
    (dispatch, getState) => {
        const { stage, project } = getState();

        dispatch({ type: METADATA_TEMPLATE_LIST_PENDING });
        return new Promise(async (resolve, reject) => {
            const projectId = project.id;
            let nextToken = undefined;

            do {
                try {
                    const response = await getMetadataTemplateAPI(stage.endpoint, projectId, {
                        ...searchParams,
                        nextToken,
                    });
                    nextToken = response.data.nextToken;
                    dispatch({
                        type: ALL_METADATA_TEMPLATE_LIST_PARTIAL_SUCCESS,
                        payload: response.data.results,
                    });
                } catch (error) {
                    dispatch({
                        type: METADATA_TEMPLATE_LIST_FAILURE,
                        payload: error,
                    });
                    reject(error);
                }
            } while (nextToken != null);

            dispatch({
                type: ALL_METADATA_TEMPLATE_LIST_SUCCESS,
            });
            resolve();
        });
    };

export const deleteMetadataTemplate = (templateId) => (dispatch, getState) => {
    const { stage, project } = getState();

    return new Promise((resolve, reject) => {
        const projectId = project.id;
        deleteMetadataTemplateAPI(stage.endpoint, projectId, templateId)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getDetailMetadataTemplate =
    ({ templateId }) =>
    (dispatch, getState) => {
        const { stage, project } = getState();

        dispatch({ type: GET_DETAIL_METADATA_TEMPLATE_PENDING });
        return new Promise((resolve, reject) => {
            getDetailMetadataTemplateAPI({ apiEndpoint: stage.endpoint, projectId: project.id, templateId })
                .then(async (response) => {
                    let categoryGroup;
                    let categoryGroupId =
                        response.data &&
                        response.data.template?.categories?.length > 0 &&
                        response.data.template.categories[0].categoryGroup?.id;

                    if (categoryGroupId) {
                        categoryGroup = await getCategoryGroupAPI(
                            stage.id,
                            stage.endpoint,
                            project.id,
                            categoryGroupId,
                        );
                    }

                    dispatch({
                        type: GET_DETAIL_METADATA_TEMPLATE_SUCCESS,
                        payload: {
                            ...response.data,
                            categoryGroup: { id: categoryGroupId, name: categoryGroup?.data?.name },
                        },
                    });

                    resolve({
                        ...response.data,
                        template: {
                            ...response.data.template,
                            categoryGroup: { id: categoryGroupId, name: categoryGroup?.data?.name },
                        },
                    });
                })
                .catch((error) => {
                    console.error(error);
                    dispatch({ type: GET_DETAIL_METADATA_TEMPLATE_FAILURE, payload: error });
                    reject(error);
                });
        });
    };

export const updateDetailMetadataTemplate =
    ({ templateId, params }) =>
    (dispatch, getState) => {
        const { stage, project } = getState();
        dispatch({ type: UPDATE_DETAIL_METADATA_TEMPLATE_PENDING });
        return new Promise((resolve, reject) => {
            updateDetailMetadataTemplateAPI({
                apiEndpoint: stage.endpoint,
                projectId: project.id,
                templateId,
                params,
            })
                .then((response) => {
                    dispatch({ type: UPDATE_DETAIL_METADATA_TEMPLATE_SUCCESS, payload: response.data });
                    resolve();
                })
                .catch((error) => {
                    console.error(error);
                    dispatch({ type: UPDATE_DETAIL_METADATA_TEMPLATE_FAILURE, payload: error });
                    reject(error);
                });
        });
    };

export const createMetadataTemplate =
    ({ params }) =>
    (dispatch, getState) => {
        const { stage, project } = getState();

        dispatch({ type: CREATE_METADATA_TEMPLATE_PENDING });
        createMetadataTemplateAPI({ apiEndpoint: stage.endpoint, projectId: project.id, params })
            .then((response) => {
                dispatch({ type: CREATE_METADATA_TEMPLATE_SUCCESS, payload: response.data });
            })
            .catch((error) => {
                console.error(error);
                dispatch({ type: CREATE_METADATA_TEMPLATE_FAILURE });
            });
    };

export const registerMetadataTemplateDomain =
    ({ templateId, params }) =>
    (dispatch, getState) => {
        const { stage, project } = getState();

        dispatch({ type: REGISTER_METADATA_TEMPLATE_DOMAIN_PENDING });
        return new Promise((resolve, reject) => {
            registerMetadataTemplateDomainAPI({
                apiEndpoint: stage.endpoint,
                projectId: project.id,
                templateId,
                params,
            })
                .then((response) => {
                    dispatch({ type: REGISTER_METADATA_TEMPLATE_DOMAIN_SUCCESS });
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error(error);
                    dispatch({ type: REGISTER_METADATA_TEMPLATE_DOMAIN_FAILURE, payload: error });
                    reject(error);
                });
        });
    };
