import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import WithModal from "../../cores/WithModal";
import { setNotification } from "@modules/notification";

import {
    createDriveFolder,
    getModalFoldersList,
    resetModalSelectedFolder,
    searchModalFolderData,
    toggleNewFolderInModal,
    updateModalFolderSearchState,
    updateModalFolderState,
    updateModalSelectedFolder,
    initModalFolderData,
    createModalDriveFolder,
} from "@modules/folderTreeViewModal";
import FolderTreeView from "@components/tree/folder-tree-view";
import { DRIVE_FOLDER_SEARCH_TYPE } from "../../constants";
import {
    Grid,
    Stack,
    Switch,
    Typography,
    Popper,
    Paper,
    Divider,
    ClickAwayListener,
    Button,
    IconButton,
} from "@mzc-pdc/ui";
import theme from "@styles/theme";
import { IconFolderAdd, IconRefresh } from "@mzc-cloudplex/icons";
import Tooltip from "@components_v2/Tooltip/tooltip";
import useActionPending from "@hooks/useActionPending";

const SelectFolderPopper = (props) => {
    const { t } = useTranslation();
    const { disablePortal, domain, projectId, submitText, onSubmit, open, placement, anchorEl } = props;

    const {
        pending: createPending,
        setActionPending: setCreatePending,
        disableActionPending: disableCreatePending,
    } = useActionPending("createFolder");

    const [searchKeyword, setSearchKeyword] = useState("");
    const [selectedFolder, setSelectedFolder] = useState({ id: null, path: "" });
    const [checkClickFolder, setCheckClickFolder] = useState(false);
    const [buttonPending, setButtonPending] = useState(false);
    const [submitPending, setSubmitPending] = useState(false);
    const [checkSubFoldersOption, setCheckSubFoldersOption] = useState(true);

    const { folderList, folderTreeViewModal, driveList, driveId, defaultProjectId } = useSelector(
        ({ folderTreeViewModal, project }) => ({
            folderList: folderTreeViewModal.folderList,
            folderTreeViewModal: folderTreeViewModal,
            driveList: folderTreeViewModal.driveList,
            driveId:
                folderTreeViewModal.driveList?.data?.find((drive) => drive.resourceType?.type === domain)?.id ?? null,
            defaultProjectId: project.id,
        }),
    );

    const dispatch = useDispatch();

    const handleCreateFolder = async (name) => {
        try {
            if (!driveId || createPending) return;
            setCreatePending();
            const params = { name, parent: { id: selectedFolder.id } };
            await dispatch(createModalDriveFolder(driveId, params));

            const queryParameters = {
                parentId: selectedFolder.id,
                searchType: DRIVE_FOLDER_SEARCH_TYPE.DIRECT_CHILDREN,
            };
            const headerParams = { projectId: projectId };
            await dispatch(
                getModalFoldersList({
                    driveId: selectedFolder?.driveId ?? driveId,
                    queryParameters,
                    headerParams,
                }),
            ); //TODO: 드라이브가 여러개일 때 driveId 구할 수 있는 방법을 찾아야함
            dispatch(toggleNewFolderInModal(null));
            dispatch(
                setNotification({
                    type: "success",
                    contents: t(`common::msg::Creation of new folder {{item}} is completed.`, {
                        item: name,
                    }),
                }),
            );
        } catch (error) {
            const errorData = error && error.response && error.response.data;
            const errorMessage = errorData && errorData.message;
            const errorStatus = (error && error.response && error.response.status) || "Unknown";
            const errorContents = `- ${errorMessage}`;

            dispatch(
                setNotification({
                    type: "error",
                    contents:
                        t(`common::label::There was an error creating the new folder {{item}}.`, {
                            item: name,
                        }) + errorContents,
                }),
            );
        } finally {
            dispatch(toggleNewFolderInModal(null));
            disableCreatePending();
        }
    };

    const init = async () => {
        try {
            const headerParams = { projectId: projectId };

            const rootFolder = await dispatch(initModalFolderData({ headerParams, domain }));
            setSelectedFolder({ id: rootFolder.id, name: rootFolder.name, path: "" });
            dispatch(updateModalSelectedFolder(rootFolder));
            setCheckClickFolder(true);
        } catch (error) {
            console.log(error);
            dispatch(
                setNotification({
                    type: "error",
                    contents: error.response
                        ? `[Error:${error.response.status}] => ${error.response?.data?.message}`
                        : `Internal Error Occured`,
                }),
            );
        }
    };

    const onClickForderTree = async (item, isClickArrow) => {
        try {
            // console.log(item);
            setSelectedFolder(item);
            setCheckClickFolder(true);
            if (!isClickArrow || folderTreeViewModal.folderList.data[item.id].isFold) {
                const queryParameters = { parentId: item.id, searchType: DRIVE_FOLDER_SEARCH_TYPE.DIRECT_CHILDREN };
                const headerParams = { projectId: projectId ?? defaultProjectId };

                if (!isClickArrow) {
                    dispatch(updateModalSelectedFolder(item));
                }
                await dispatch(
                    getModalFoldersList({
                        driveId: item.driveId,
                        queryParameters,
                        headerParams,
                    }),
                );
            } else {
                dispatch(
                    updateModalFolderState({
                        [item.id]: { ...folderList.data[item.id], isFold: true },
                    }),
                );
            }
        } catch (error) {
            console.log(error);
        }
    };

    const onClickFolderTreeFilter = async () => {
        try {
            const keyword = searchKeyword;

            if (!keyword) return;

            const queryParameters = {
                name: keyword,
                searchType: DRIVE_FOLDER_SEARCH_TYPE.NAME,
            };

            const headerParams = { projectId: projectId ?? defaultProjectId };
            await dispatch(searchModalFolderData({ headerParams, domain: "Asset", queryParameters }));

            await dispatch(
                updateModalFolderSearchState({
                    isSearch: true,
                }),
            );
        } catch (error) {
            console.log(error);
        }
    };

    const onClickResetFolderTreeFilter = async () => {
        try {
            const headerParams = { projectId: projectId ?? defaultProjectId };

            const rootFolder = await dispatch(initModalFolderData({ headerParams }));
            onClickForderTree(rootFolder, false);
            await dispatch(
                updateModalFolderSearchState({
                    isSearch: false,
                }),
            );
            setSearchKeyword("");
        } catch (error) {
            console.log(error);
        }
    };

    const onClickRefreshFolderTreeFilter = () => {
        if (searchKeyword) {
            onClickFolderTreeFilter();
        } else {
            init();
        }
    };

    const handleClickCreateFolder = (value) => {
        if (!value) dispatch(toggleNewFolderInModal(null));
        else handleCreateFolder(value);
    };

    const getFullFolderPath = useMemo(() => {
        let node = selectedFolder;
        let result = [];
        if (!node) return;

        while (node.depth > 0) {
            result = [node, ...result];
            node = folderList.data[node.parent];

            if (node.depth === 0) {
                result = [node, ...result];
            }
        }

        return result;
    }, [selectedFolder, folderList]);

    const onHandleChangeSubFoldersOption = (e) => {
        setCheckSubFoldersOption(e.target.checked);
    };

    const onHandleClickSubmit = useCallback(async () => {
        setSubmitPending(true);
        await onSubmit({
            driveId: selectedFolder.driveId,
            folderId: selectedFolder.id,
            folderName: selectedFolder.name,
            folderPath: getFullFolderPath,
            includeSubFolders: checkSubFoldersOption,
        });
        setSubmitPending(false);
    }, [submitPending, selectedFolder, checkSubFoldersOption]);

    useEffect(() => {
        init();
        return () => dispatch(resetModalSelectedFolder());
    }, []);

    if (open !== undefined && !open) return null;
    return (
        <Popper
            id={"select-folder-popper"}
            anchorEl={anchorEl}
            placement={placement}
            open={open}
            disablePortal={disablePortal}
            sx={{
                borderRadius: "4px",
                border: `solid 1px ${theme.palette.greySecondary[100]}`,
                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.16)",
                zIndex: 1499,
            }}
        >
            <Paper sx={{ width: "688px", height: "600px", overflowX: "hidden", overflowY: "hidden" }}>
                <Grid container flexDirection={"column"} sx={{ width: "688px", height: "inherit" }}>
                    <Grid item p={1.5} sx={{ borderBottom: `1px solid ${theme.palette.greySecondary[100]}` }}>
                        <Grid container flexDirection={"row"} justifyContent={"space-between"}>
                            <div className="input-search" style={{ flex: 1, maxWidth: "50%" }}>
                                <input
                                    type="text"
                                    className={"form-control"}
                                    placeholder={t(
                                        `common::label::Please search by folder name`,
                                        `Please search by folder name`,
                                    )}
                                    name="keyword"
                                    onChange={(e) => setSearchKeyword(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.code === "Enter") onClickFolderTreeFilter();
                                    }}
                                    value={searchKeyword}
                                    style={{ width: "100%" }}
                                />
                                <i className="sprite sprite-search-dark" />
                                {/* input value 가 있을때만 노출 */}
                                {searchKeyword && (
                                    <button
                                        type={"button"}
                                        className={"btn btn-icon-solid"}
                                        onClick={() => {
                                            onClickResetFolderTreeFilter();
                                        }}
                                    >
                                        <i className={"sprite sprite-cancel-source"} />
                                    </button>
                                )}
                                {/* //input value 가 있을때만 노출 */}
                            </div>
                            <Grid item sx={{ display: "flex", flexDirection: "row", gap: theme.spacing(1) }}>
                                <Grid item>
                                    <Tooltip
                                        title={t(`common::label::Create {{name}}`, `Create {{name}}`, {
                                            name: "Folder",
                                        })}
                                    >
                                        <IconButton
                                            circled
                                            size={"medium"}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                dispatch(
                                                    toggleNewFolderInModal(
                                                        folderTreeViewModal.folderList.selectedFolder.folder,
                                                    ),
                                                );
                                            }}
                                        >
                                            <IconFolderAdd size={16} color={"greySecondary"} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Divider orientation={"vertical"} flexItem sx={{ marginY: theme.spacing(1) }} />
                                <Grid item>
                                    <Tooltip title={t(`common::label::Refresh`, `Refresh`)}>
                                        <IconButton
                                            circled
                                            onClick={() => {
                                                onClickRefreshFolderTreeFilter();
                                            }}
                                        >
                                            <IconRefresh size={16} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item flex={1} sx={{ width: "688px", overflowX: "scroll", overflowY: "scroll" }}>
                        <FolderTreeView
                            domain={"Asset"}
                            isModal={true}
                            driveList={driveList}
                            folderList={folderList}
                            popperMenu={false}
                            selectedFolder={selectedFolder?.id}
                            onClickForderTree={onClickForderTree}
                            onHandleCreateFolder={handleClickCreateFolder}
                        />
                    </Grid>
                    <Grid item>
                        <Divider flexItem />
                    </Grid>
                    <Grid item>
                        <Stack direction={"row"} justifyContent={"flex-end"} p={2}>
                            <Button
                                variant={"contained"}
                                size={"small"}
                                color={"greySecondary"}
                                loading={submitPending}
                                disabled={
                                    !checkClickFolder ||
                                    folderTreeViewModal.folderList.creatingParentFolder ||
                                    submitPending
                                }
                                onClick={async (e) => {
                                    e.preventDefault();
                                    onHandleClickSubmit();
                                }}
                            >
                                {submitText ? t(`common::label::${submitText}`) : t(`common::label::${"Select"}`)}
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Paper>
        </Popper>
    );
};

export default withTranslation()(WithModal(SelectFolderPopper));
