import {handleActions, createAction} from 'redux-actions';

import {
    axios,
    axiosWithoutHeaders
} from "../cores/axiosWrapper";

import MusicApi from './apis/music';

//action type
const MUSIC_CREATE_RESET = 'MUSIC_CREATE_RESET'
const MUSIC_CREATE_SUCCESS = 'MUSIC_CREATE_SUCCESS';
const MUSIC_CREATE_FAILURE = 'MUSIC_CREATE_FAILURE';

const initialState = {
    pending : false,
    error : false,
}

export default handleActions({
    [MUSIC_CREATE_RESET] : (state, action) => {
        return { 
            ...initialState, 
        }; 
    },

    [MUSIC_CREATE_SUCCESS] : (state, action) => {
        const { data } = action.payload;

        console.log("MUSIC_CREATE_SUCCESS data:", data);
        
        // const temp = {
        //     ...tempState,
        //     data: data,
        //     contextVersion: tempState.contextVersion + 1
        // };

        // tempState = temp;

        // return tempState;
    },

    [MUSIC_CREATE_FAILURE] : (state, action) => {
        const { data } = action.payload;

        console.log("MUSIC_CREATE_FAILURE data:", data);
        
        // const temp = {
        //     ...tempState,
        //     data: data,
        //     contextVersion: tempState.contextVersion + 1
        // };

        // tempState = temp;

        // return tempState;
        return {
            pending : false,
            error : true,
        }
    }

}, initialState);

export const reset = createAction(MUSIC_CREATE_RESET);
export const createMusicAsync = (data) => (dispatch, getState) =>{
    const {
        stage,
        project        
    } = getState();

    return new Promise((resolve, reject) => {
        MusicApi.createMusicAsync(stage.id, stage.endpoint, project.id, data)
            .then((response) => {
                dispatch({
                    type: MUSIC_CREATE_SUCCESS,
                    payload: response
                });
                resolve(response);
            })
            .catch((error) => {
                console.log("errir :", error);
                dispatch({
                    type: MUSIC_CREATE_FAILURE,
                    payload: error
                }); 
                reject(error);
        }); 
    });
}
