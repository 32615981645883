import { axios } from "../cores/axiosWrapper";
import { handleActions } from "redux-actions";

const DRM_CREATE_SUCCESS = "DRM_CREATE_SUCCESS";
const DRM_CREATE_FAILURE = "DRM_CREATE_FAILURE";

const initialState = {
    error: false,
};

export default handleActions({
    [DRM_CREATE_SUCCESS]: (state) => {
        const temp = {
            error: false,
        };

        return temp;
    },
    [DRM_CREATE_FAILURE]: (state) => {
        const temp = {
            error: true,
        };

        return temp;
    },
}, initialState);

export const createDrmToken = (jobId, drmType, fn) => (dispatch, getState) => {
    const {
        stage,
        project,
    } = getState();

    const apiEndpoint = stage.endpoint;
    const params = {
        jobId,
        drmType
    };

    createDrmTokenAPI(apiEndpoint, project.id, params).then((response) => {
        dispatch({
            type: DRM_CREATE_SUCCESS,
        });

        fn(response.data);
    }).catch((error) => {
        dispatch({
            type: DRM_CREATE_FAILURE,
            payload: error,
        });
    });
}

const createDrmTokenAPI = (apiEndpoint, projectId, data) => {
    return axios.post(`${apiEndpoint}/drm/token`, data, {
        headers: {
            projectId
        }
    });
}