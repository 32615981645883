import { Trans, useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Typography } from "@mzc-pdc/ui";
import theme from "@styles/theme";
import TextField from "@components_v2/text-field/text-field";
import { IconLock } from "@mzc-cloudplex/icons";
import * as React from "react";
import validateInput, { convertValidationMessage } from "@cores/validateInput";
import { COMMENT_GROUP_TITLE_MAX_LENGTH } from "@constants";
import { useMemo, useState } from "react";

export const DialogEditGroupName = ({ data, isLoading, onSubmit, onClose }) => {
    const { t } = useTranslation();
    const [editValue, setEditValue] = React.useState({ name: data?.name });
    const [validationErrors, setValidationErrors] = useState({});
    const setNewValidationErrors = (key, value) => {
        const newValidationErrors = { ...validationErrors, [key]: value };
        Object.keys(newValidationErrors ?? {}).forEach(
            (k) => newValidationErrors[k] == null && delete newValidationErrors[k],
        );
        setValidationErrors(newValidationErrors);
    };

    const onHandleChangeEditValue = (key, value) => {
        setNewValidationErrors(
            "name",
            validateInput(value, {
                required: true,
                maxLength: COMMENT_GROUP_TITLE_MAX_LENGTH,
            }),
        );
        setEditValue({ ...editValue, [key]: value });
    };

    const onHandleSubmit = () => {
        onSubmit(data?.id, editValue);
    };

    const isDisabled = useMemo(() => {
        return Object.keys(validationErrors).length > 0 || editValue.name === data?.name;
    }, [validationErrors, editValue]);

    return (
        <Dialog
            variant={"basic"}
            open={true}
            onClose={() => onClose()}
            sx={{ ".MuiPaper-root": { maxWidth: "480px" } }}
        >
            <DialogTitle>{t(`common::msg::Would you like to change your private comment name?`)}</DialogTitle>
            <DialogContent>
                <Grid container flexDirection={"column"} gap={1}>
                    <Stack direction={"column"}>
                        <Typography
                            variant={"body1_500"}
                            color={theme.palette.greySecondary.main}
                            required={true}
                            requiredSize={"small"}
                        >
                            <Trans
                                i18nKey={`common::label::Private comment name (maximum <0>{{count}}</0> characters)`}
                                values={{
                                    count: 20,
                                }}
                                components={[<Typography variant={"body1_500"} color={theme.palette.primary.main} />]}
                            />
                        </Typography>
                    </Stack>
                    <Stack>
                        <TextField
                            value={editValue.name}
                            onChange={(e) => onHandleChangeEditValue("name", e.target.value)}
                            error={validationErrors?.name?.level}
                            helperText={
                                validationErrors?.name?.message
                                    ? validationErrors.name.message
                                    : t(convertValidationMessage(validationErrors?.name?.type))
                            }
                            inputProps={{
                                startAdornment: <IconLock htmlColor={theme.palette.warning.main} size={24} />,
                            }}
                            placeholder={t(`common::msg::Please enter a private comment name.`)}
                        />
                    </Stack>
                </Grid>
            </DialogContent>
            <DialogActions>
                <>
                    <Button variant={`text`} onClick={() => onClose()} color={"greySecondary"}>
                        {t(`common::label::Cancel`)}
                    </Button>
                    <Button
                        variant={`contained`}
                        disabled={isLoading || isDisabled}
                        loading={isLoading}
                        onClick={(e) => {
                            e.stopPropagation();
                            onHandleSubmit();
                        }}
                        color={`primary`}
                    >
                        {t(`common::label::Change name`)}
                    </Button>
                </>
            </DialogActions>
        </Dialog>
    );
};
