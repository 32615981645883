import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const CardStatus = (props) => {
    const { className, ...otherProps } = props;
    return <div {...otherProps} className={clsx("card-status", className)} />;
};
CardStatus.propTypes = {
    className: PropTypes.string,
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};
CardStatus.defaultProps = {
    component: "div",
};

export default CardStatus;
