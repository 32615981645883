import React from 'react';

import getByteLength from '../../cores/getByteLength';
import resizeTextareaHeight from '../../cores/resizeTextareaHeight';

class Textarea extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        if(this.element) {
            resizeTextareaHeight(this.element);
        }
    }

    shouldComponentUpdate = (nextProps) => {
        if(nextProps !== this.props) {
            return true;
        }

        return false;
    }

    componentDidUpdate = () => {
        if(this.element) {
            resizeTextareaHeight(this.element);
        }
    }

    getValidationClass = () => {
        return this.props.validationError && this.props.validationError.level;
    }

    render = () => {
        return (
            <React.Fragment>
                <div className={`form-textarea  ${this.props.isEdit && 'is-edit'} ${this.props.wrapperClass ? `${this.props.wrapperClass} form-${this.getValidationClass()}` : `form-${this.getValidationClass()}`}`}>
                    <textarea
                        id={this.props.id}
                        name={this.props.name}
                        value={this.props.value || ''}
                        placeholder={this.props.placeholder}
                        className={"form-control"}
                        ref={ref => this.element = ref}
                        onChange={e => this.props.onChange(e)}
                        onBlur={e => this.props.onChange(e)}
                        style={this.props.value != '' ? {
                            position:"relative",
                            opacity:1,
                            height : "auto"
                        } : {
                            position:"relative",
                            opacity:1
                        }}>
                    </textarea>
                    {this.props.maxByte &&
                        <div className={"byte"}>
                            <span>{getByteLength(this.props.value)}</span>/ {this.props.maxByte}byte
                        </div>
                    }
                    {this.props.validationError && this.props.validationError.level &&
                        <div className={`form-message ${this.props.validationErrorMessageClass || this.getValidationClass()}`}>
                            <span>{this.props.validationError.message}</span>
                        </div>
                    }
                </div>
            </React.Fragment>
        );
    }
};

export default Textarea;