import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as notificationActions from "../../modules/notification";
import LayoutForError from "../../components/layout/LayoutForError";
import menuService from "@services/menuService";
class Error500 extends Component {
    componentDidMount() {
        this.props.NotificationActions.removeAllNotification();
    }

    render() {
        const { t, i18n, location } = this.props;
        const { spaceId, projectId } = menuService.parse(location.pathname);

        return (
            <div className={"content-wrapper  error-page"}>
                <div className={"card"}>
                    <div className={"card-header"}>
                        <h2>{t(`common::msg::${"Internal server error"}`)}</h2>
                    </div>
                    <div className={"card-body"}>
                        <div className={"d-flex align-items-center "}>
                            <div>
                                <i className={"sprite sprite-error-big"}></i>
                            </div>
                            <div>
                                <p className={"text-danger"}>{t(`common::msg::${"We're sorry!"}`)}</p>
                                {/* <p>The page you requested could be found.</p> */}
                                <p>{t(`common::msg::${"Please go back to the main with the button below."}`)}</p>
                            </div>
                        </div>
                    </div>
                    <div className={"card-footer justify-content-end"}>
                        <Link
                            className={"btn btn-primary"}
                            to={`${menuService.paths.dashboard(spaceId, projectId)}`}
                            replace
                        >
                            {t(`common::label::Main`)}
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        NotificationActions: bindActionCreators(notificationActions, dispatch),
    };
};

Error500 = connect(null, mapDispatchToProps)(Error500);
export default withTranslation()(Error500);
