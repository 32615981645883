import { FC, useState, useCallback, useMemo } from 'react';
import theme from "@styles/theme";

import { IconArrowDropDown, IconLanguage } from '@mzc-cloudplex/icons';
import { Menu, MenuItem, ButtonWrap,  } from './language-menu.styled';
import { Box,Button } from '@mzc-pdc/ui';

const LanguageMenu = ({ locales, onClick, label, locale }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = useMemo(() => {
        return Boolean(anchorEl);
    }, [anchorEl]);

    const handleClick = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleChange = useCallback(
        (value: any) => {
            onClick(value);
            handleClose();
        },
        [onClick, handleClose],
    );

    return (
        <ButtonWrap>
            <Button
                variant={`text`}
                size={`small`}
                color={'secondary'}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                startIcon={<IconLanguage size={16} htmlColor={theme.palette.secondary[800]} />}
                endIcon={
                    <Box>
                        <IconArrowDropDown size={20} htmlColor={theme.palette.secondary[600]} />
                    </Box>
                }
            >
                {label}
            </Button>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                {locales.map((item) => (
                    <MenuItem
                        key={item.id}
                        className={locale === item.value ? `active` : ``}
                        onClick={() => handleChange(item.value)}
                    >
                        {item.label}
                    </MenuItem>
                ))}
            </Menu>
        </ButtonWrap>
    );
};

export default LanguageMenu;
