import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ClickAwayListener, InputAdornment, TextField, Box, Tooltip, IconButton } from "@mzc-pdc/ui";
import { IconAddCircle, IconErrorCircle, IconSearch, IconWarningTriangle, IconDelete } from "@mzc-cloudplex/icons";
import { useTranslation } from "react-i18next";
import theme from "@styles/theme";
import AutocompleteTablePopper from "./autocomplete-table-popper";
import * as domain from "domain";
import TooltipButton from "@components_v2/tooltip-button/tooltip-button";

export const AutocompleteTable = ({
    domain,
    onSubmit,
    onSearch,
    columns,
    skeletonColumns,
    placeholder,
    options,
    isPending,
    error,
    helperText,
    filter,
    placement,
    getValueByColumn,
    onFetchData,
    selectedItems,
    disablePortal,
    viewMore,
    onClose,
    onDebounceSearch, //TODO: 기존 onSearch를 사용하던 컴포넌트에서는 이 함수를 사용하도록 처리해야함
    hideCheckbox,
    filterDisabled = false,
}) => {
    let textFieldRef = useRef();
    const { t } = useTranslation();
    const [toolSize, setToolSize] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [popperWidth, setPopperWidth] = useState(null);
    const [checkedList, setCheckedList] = useState([]);
    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState({ searchedValue: "", debouncedValue: "" });

    const isItemChecked = useCallback(
        (row) => {
            if (!checkedList || checkedList.length === 0) return false;

            const isChecked = checkedList.filter((item) => item.id === row.id).length > 0;

            return isChecked;
        },
        [checkedList],
    );

    const onHandleCheckClick = (checked, option) => {
        let newChecked = [];

        if (hideCheckbox) {
            onSubmit(option);
            setOpen(false);
            return;
        }

        if (checked) {
            newChecked = [...checkedList];
            newChecked.push(option);
        } else {
            newChecked = checkedList?.filter((item) => item.id !== option.id);
        }
        setCheckedList(newChecked);
    };

    const isCollapsed = useMemo(() => checkedList.length > 0, [checkedList]);

    const onChangeNumSelected = (checkAll) => {
        if (checkAll) {
            setCheckedList([].concat(options));
        } else {
            setCheckedList([]);
        }
    };

    const handleClearCheckAll = () => {
        onChangeNumSelected(false);
    };

    const handleChangeCheckAll = () => {
        onChangeNumSelected(options?.length !== checkedList.length);
    };

    const getListToolsButtons = useCallback(
        (isResponsive) => {
            return [
                <TooltipButton
                    title={t("common::label::Select", "Select")}
                    onClickButton={() => {
                        onSubmit(checkedList);
                        setOpen(false);
                    }}
                    buttonIcon={<IconAddCircle size={16} htmlColor={theme.palette.secondary.contrastText} />}
                    isDivided={false}
                    buttonText={t("common::label::Select", "Select")}
                    isResponsive={isResponsive}
                />,
            ];
        },
        [checkedList],
    );

    const getEndAdornment = useMemo(() => {
        if (error) {
            if (!helperText) return <IconWarningTriangle color={"warning"} size={16} />;
            return <IconErrorCircle color={`error`} size={16} />;
        }
        return undefined;
    }, [error, helperText]);

    const handleClose = (e) => {
        e.stopPropagation();
        setOpen(false);
        onClose && onClose();
    };

    const onHandleChangeSearch = (value) => {
        if (value !== searchValue.searchedValue) {
            setSearchValue({ ...searchValue, searchedValue: value });
        }
    };

    useEffect(() => {
        setCheckedList(selectedItems ?? []);
    }, [selectedItems]);

    //Note: 검색 결과가 초기화되고 다시 진행된 경우 이전 체크한 항목들 제거
    useEffect(() => {
        if (checkedList.length > 0 && !options.length) setCheckedList([]);
    }, [options]);

    useEffect(() => {
        setCheckedList([]);
    }, [open]);

    useEffect(() => {
        const searchedValue = searchValue.searchedValue;
        if (searchedValue === null) return;
        const debounce = setTimeout(() => {
            return setSearchValue({ ...searchValue, debouncedValue: searchedValue });
        }, 300);
        return () => clearTimeout(debounce);
    }, [searchValue.searchedValue]);

    useEffect(() => {
        if (!open || !onDebounceSearch) return;
        const search = searchValue.debouncedValue;
        onDebounceSearch(search);
    }, [open, searchValue.debouncedValue]);

    return (
        <>
            <ClickAwayListener mouseEvent={open ? "onClick" : false} onClickAway={handleClose}>
                <Box>
                    <TextField
                        ref={textFieldRef}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconSearch size={12} />
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                        type="search"
                        variant="outlined"
                        placeholder={placeholder}
                        autoComplete={"off"}
                        onFocus={(e) => {
                            setAnchorEl(e.currentTarget);
                            setPopperWidth(e.target.clientWidth + 37);
                            setOpen(true);
                        }}
                        onChange={(e) => {
                            e.stopPropagation();
                            const replacedString = e.target.value.replace(/[\n\s\r\t]*/g, "");
                            if (onDebounceSearch) onHandleChangeSearch(replacedString);
                            else onSearch(replacedString);
                        }}
                    />

                    <AutocompleteTablePopper
                        domain={domain}
                        open={open}
                        anchorEl={anchorEl}
                        width={popperWidth}
                        placement={placement}
                        onFetchData={onFetchData}
                        isCollapsed={isCollapsed}
                        handleClearCheckAll={handleClearCheckAll}
                        handleChangeCheckAll={handleChangeCheckAll}
                        onHandleCheckClick={onHandleCheckClick}
                        toolSize={toolSize}
                        checkedList={checkedList}
                        options={options}
                        isPending={isPending}
                        listToolsButtons={getListToolsButtons}
                        filter={filter}
                        getValueByColumn={getValueByColumn}
                        columns={columns}
                        skeletonColumns={skeletonColumns}
                        disablePortal={disablePortal}
                        viewMore={viewMore}
                        hideCheckbox={hideCheckbox}
                        filterDisabled={filterDisabled}
                    />
                </Box>
            </ClickAwayListener>
        </>
    );
};

export default AutocompleteTable;
