import React, { useCallback, useEffect, useMemo } from "react";
import { TreeItem } from "@components_v2/tree-view";
import { ImageDriveOn, ImageFolderSmallOn } from "@mzc-cloudplex/icons";
import FolderTreeViewSkeleton from "./folder-tree-view-skeleton";

const FolderTreeItem = ({
    folder,
    menuList,
    creatingParentFolder,
    foldDisabled,
    onClickFolder,
    onClickCreateFolder,
    onToggleFolderFold,
    onRefreshDrive,
    renderTree,
}) => {
    if (!folder?.id) return null;

    const onHandleClickRefresh = useCallback(() => {
        onRefreshDrive(folder.driveId, folder);
    }, [onRefreshDrive]);

    const nodeId = useMemo(() => String(folder.id), [folder]);

    return (
        <TreeItem
            key={folder.id}
            depth={folder.depth}
            icon={
                folder.depth === 0 ? (
                    <ImageDriveOn height={16} width={16} />
                ) : (
                    <ImageFolderSmallOn height={16} width={16} />
                )
            }
            foldDisabled={foldDisabled}
            nodeId={nodeId}
            label={`${folder?.name}`}
            onClick={(e) => {
                e.stopPropagation();
                onClickFolder(folder, false);
            }}
            onClickExpand={() => {
                // 설명: 열기/닫기 아이콘 전용
                onToggleFolderFold(folder);
            }}
            onClickRefresh={folder.type === "drive" && onRefreshDrive ? onHandleClickRefresh : undefined}
            menuList={menuList}
            sx={{ ".MuiTypography-root": { fontSize: "14px", fontWeight: "normal" } }}
        >
            {creatingParentFolder?.id === folder.id && (
                <TreeItem
                    key={`inner_${folder.id}`}
                    depth={folder.depth + 1}
                    icon={<ImageFolderSmallOn height={16} width={16} />}
                    nodeId={"create"}
                    onSubmitField={(folderName) => {
                        onClickCreateFolder(folderName, folder.id);
                    }}
                ></TreeItem>
            )}
            {folder.children?.length > 0 && renderTree(folder?.children)}
            {folder.pending && <FolderTreeViewSkeleton type={"child"} depth={folder.depth + 1} />}
        </TreeItem>
    );
};

export default FolderTreeItem;
