import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import {
    Chip,
    Grid,
    ListItem,
    Typography,
    TextField,
    Checkbox,
    Popper,
    Button,
    Box,
    Avatar,
    InputAdornment,
} from "@mzc-pdc/ui";
import { Autocomplete as MuiAutocomplete } from "@mzc-pdc/ui";
import { IconAvata, IconErrorCircle, IconWarningTriangle } from "@mzc-cloudplex/icons";
import useIntersection from "@hooks/useIntersection";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import theme from "@styles/theme";
import { RandomColoredAvatar } from "@components_v2/avatar/random-colored-avatar";
import { StyledAutocompletePopper } from "@features/chip-popper-components/chip-types-input/project-menu.styled";
import { getProjectRandomColor } from "@utils/get-random-color";

export const AutocompleteProject = (props) => {
    const {
        value,
        disabled,
        onFetchData,
        onInputChange,
        placeholder,
        options,
        error,
        helperText,
        sx,
        handleClose,
        onChange,
    } = props;
    const { setTarget } = useIntersection({
        onIntersect: onFetchData,
    });

    const getAvatar = useCallback((option) => {
        if (!option) return null;
        return (
            <Avatar
                sx={{
                    width: 20,
                    height: 20,
                    fontSize: 11,
                    fontWeight: `bold`,
                    bgcolor: getProjectRandomColor(option.id),
                }}
            >
                {`${option.name?.charAt(0)}`}
            </Avatar>
        );
    }, []);

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <MuiAutocomplete
                clearOnBlur
                value={value}
                limitTags={2}
                onInputChange={(e, value, reason) => {
                    onInputChange(reason);
                }}
                options={options}
                disabled={disabled}
                getOptionLabel={(option) => option.name}
                onChange={(e, newValue) => {
                    if (newValue && onChange) onChange(newValue);
                }}
                renderOption={(props, option, { selected }) => {
                    return (
                        <ListItem {...props} key={option.id} ref={onFetchData && option.lastOption ? setTarget : null}>
                            <Grid
                                container
                                flexDirection={"row"}
                                flexWrap={"nowrap"}
                                alignItems={"center"}
                                gap={1}
                                py={`1px`}
                            >
                                {getAvatar(option)}
                                <Typography
                                    variant={"body1"}
                                    color={theme.palette.secondary[800]}
                                    sx={{ wordWrap: `break-word`, wordBreak: `keep-all` }}
                                >
                                    {`${option.name}`}
                                </Typography>
                            </Grid>
                        </ListItem>
                    );
                }}
                renderInput={(params) => {
                    const getEndAdornment = () => {
                        if (error === "warning") return <IconWarningTriangle color={"warning"} size={16} />;
                        else if (error === "error") return <IconErrorCircle color={`error`} size={16} />;

                        return undefined;
                    };
                    return (
                        <>
                            <TextField
                                {...params}
                                placeholder={placeholder}
                                error={error}
                                helperText={
                                    helperText && (
                                        <Grid container gap={1} alignItems={"center"}>
                                            {getEndAdornment()}
                                            {helperText}
                                        </Grid>
                                    )
                                }
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: value ? (
                                        <InputAdornment position="start">
                                            <Avatar
                                                sx={{
                                                    width: 20,
                                                    height: 20,
                                                    fontSize: 11,
                                                    fontWeight: `bold`,
                                                    bgcolor: getProjectRandomColor(value.id),
                                                }}
                                            >
                                                {`${value.name?.charAt(0)}`}
                                            </Avatar>
                                        </InputAdornment>
                                    ) : null,
                                }}
                                sx={{
                                    ".MuiInputBase-root": {
                                        paddingLeft: `14px`,
                                    },
                                    ...sx,
                                }}
                            />
                        </>
                    );
                }}
            />
        </ClickAwayListener>
    );
};
