import {handleActions, createAction} from 'redux-actions';
import moment from 'moment';
import {
    axios,
    axiosWithoutHeaders
} from "../cores/axiosWrapper";
import {CONSTANTS} from '../constants';

function getServicePricesAPI(stageId, apiEndpoint) {
    return axios.get(`${apiEndpoint}/service-prices`);
}

const SERVICE_PRICE_PENDING = 'SERVICE_PRICE_PENDING';
const SERVICE_PRICE_FAILURE = 'SERVICE_PRICE_FAILURE';

const GET_SERVICE_PRICE_SUCCESS = 'GET_SERVICE_PRICE_SUCCESS';

const INIT_SERVICE_PRICE = 'INIT_SERVICE_PRICE';

const initialState = {
    pending: false,
    error: false,
    data : null
};

export default handleActions({
    [SERVICE_PRICE_PENDING]: (state) => {
        return {
            ...state,
            pending: true,
            error: false
        };
    },
    [SERVICE_PRICE_FAILURE]: (state) => {
        return {
            ...state,
            pending: false,
            error: true
        };
    },
    [GET_SERVICE_PRICE_SUCCESS]: (state, action) => {
        const {data} = action.payload;
        const mediaConvert = data.find(v => v.provider === 'AWS' && v.service === 'MediaConvert');
        const audio = mediaConvert.priceList.find(v => v.key === "MEDIACONVERT_AUDIO_BASIC");
        const sd = mediaConvert.priceList.find(v => v.key === "MEDIACONVERT_SD_SINGLEPASS_BASIC");
        const hd = mediaConvert.priceList.find(v => v.key === "MEDIACONVERT_HD_SINGLEPASS_BASIC");
        const uhd = mediaConvert.priceList.find(v => v.key === "MEDIACONVERT_UHD_SINGLEPASS_BASIC");
        const fuhd = mediaConvert.priceList.find(v => v.key === "MEDIACONVERT_FUHD_SINGLEPASS_PRO");
        return {
            ...state,
            data : [{ ...mediaConvert }],
            pending: false,
            error: false
        }
    },
    [INIT_SERVICE_PRICE] : () => {
        return {
            ...initialState
        }
    }
}, initialState);

const parseMediaConvertPrice = (priceList) => {

}

export const getServicePrices = () => (dispatch, getState) => {
    const {
        stage
    } = getState();
    
    dispatch({type: SERVICE_PRICE_PENDING});

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;

    return new Promise((resolve, reject) => {
        getServicePricesAPI(stageId, apiEndpoint)
            .then((response) => {
                dispatch({
                    type: GET_SERVICE_PRICE_SUCCESS,
                    payload: response
                });
                resolve(response.data);
            })
            .catch(error => {
                dispatch({
                    type: SERVICE_PRICE_FAILURE,
                    payload: error
                });
                reject(error);
            });
    })

}
