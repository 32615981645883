import React from "react";
import { IconButton, Tooltip, Divider, Button } from "@mzc-pdc/ui";
import { Typography } from "@mui/material";
import theme from "@styles/theme";

const TooltipButton = ({
    title,
    buttonText,
    hidden,
    disabled = false,
    onClickButton,
    buttonIcon,
    isDivided = false,
    isResponsive = true,
    size = "medium",
    sx,
}) => {
    return (
        <>
            {!hidden && (
                <>
                    {isResponsive ? (
                        <Tooltip title={title}>
                            <IconButton
                                circled
                                disabled={disabled}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onClickButton();
                                }}
                                type="button"
                                variant="text"
                                size={size}
                                color={"inherit"}
                                sx={sx}
                            >
                                {buttonIcon}
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Button
                            startIcon={buttonIcon}
                            disabled={disabled}
                            variant={"text"}
                            size={"medium"}
                            onClick={(e) => {
                                e.stopPropagation();
                                onClickButton();
                            }}
                            color={"inherit"}
                            sx={sx}
                        >
                            <>{buttonText && <Typography variant={"body1_400"}>{buttonText}</Typography>}</>
                        </Button>
                    )}
                    {isDivided && <Divider flexItem orientation={"vertical"} sx={{ my: 0.5, opacity: 0.12 }} />}
                </>
            )}
        </>
    );
};

export default TooltipButton;
