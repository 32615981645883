const tryParse = (settings, resource, key, currentLayer, parseJson = true) => {
    try {
        if (settings) {
            const resourceObject = settings[resource];
            if (resourceObject) {
                let settingItem = resourceObject[key];
                if (settingItem) {
                    const parsedObject = {
                        id: settingItem.id,
                        resource: resource,
                        key: key,
                        layer: settingItem.layer,
                        isInherited: settingItem.layer < currentLayer,
                        isOverridable: settingItem.isOverridable,
                    };
                    if (settingItem.value) {
                        parsedObject.value = parseJson ? JSON.parse(settingItem.value) : settingItem.value;
                    }
                    if (settingItem.parentValue) {
                        parsedObject.parentValue = parseJson
                            ? JSON.parse(settingItem.parentValue)
                            : settingItem.parentValue;
                    }
                    return parsedObject;
                }
            }
        }
    } catch (e) {
        // ignored
        console.log("tryParse", e);
    }

    return {
        resource: resource,
        key: key,
        value: null,
        parentValue: null,
        isInherited: false,
        isOverridable: true,
        layer: currentLayer,
    };
};

export default tryParse;
