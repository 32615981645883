import React from "react";
import { withTranslation, Trans } from "react-i18next";
import validateInput from "@cores/validateInput";
import { LIMIT_BYTE } from "@constants";
import deepEqual from "deep-equal";
import Typography from "@components_v2/typography/typography";
import { start } from "../../modules/channel";
import { IconAdd, IconCancel } from "@mzc-cloudplex/icons";
import { Grid, Button, Divider, IconButton } from "@mzc-pdc/ui";
import theme from "@styles/theme";
class AttributionInputArea extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            validationErrors: {},
        };
    }

    componentDidMount = () => {
        if (!this.props.values || this.props.values.length === 0) {
            this.setState({
                validationErrors: {},
            });
        } else {
            let newValidationErrors = {
                ...this.state.validationErrors,
            };
            this.props.values.forEach((value, index) => {
                const id = index;

                newValidationErrors[`attribution-${id}-key`] = this.validate(
                    value.key,
                    this.props.values.map(({ key }) => key),
                    true,
                );
                newValidationErrors[`attribution-${id}-value`] =
                    this.props.type !== "METADATA_TEMPLATE" ? this.validate(value.value) : undefined;

                this.props.onChangeValue(id, value.value, this.validate(value.value));
            });

            this.setState({
                validationErrors: newValidationErrors,
            });
        }
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (!deepEqual(prevProps.values, this.props.values)) {
            if (!this.props.values || this.props.values.length === 0) {
                this.setState({
                    validationErrors: {},
                });
            }
        }
    };

    getOrder(keyClasses, id) {
        let orders = [];

        Array.prototype.forEach.call(keyClasses, (keyObj) => {
            const order = Number(keyObj.getAttribute("order"));
            orders.push(order);
        });

        orders.sort();

        let count = 0;
        let index = 0;
        orders.forEach((order) => {
            if (Number(id) === order) index = count;
            count++;
        });

        return index;
    }

    updateOtherKeys = (id, key, values) => {
        let updateTarget = {};

        Array.prototype.forEach.call(key, (keyObj) => {
            const keyId = Number(keyObj.getAttribute("order"));

            if (id !== keyId) {
                const keyValue = keyObj.getAttribute("value");
                const validationError = this.validate(keyValue, values, true);
                Object.assign(updateTarget, { [`attribution-${keyId}-key`]: validationError });
            }
        });

        return updateTarget;
    };

    onChangeKey = (id, e) => {
        const key = e.target.value;
        const keyClasses = document.getElementsByClassName(e.target.className);
        const values = this.props.values.map((v) => v.key);
        values[this.getOrder(keyClasses, id)] = key;
        const originalValidationError = this.validate(key, values, true);

        let updateTarget = this.updateOtherKeys(id, keyClasses, values);

        Object.assign(updateTarget, { [`attribution-${id}-key`]: originalValidationError });

        this.setState({
            validationErrors: {
                ...this.state.validationErrors,
                ...updateTarget,
            },
        });

        this.props.onChangeKey(id, key, originalValidationError);
    };

    onChangeValue = (id, e) => {
        const validationError = this.props.type !== "METADATA_TEMPLATE" ? this.validate(e.target.value) : undefined;

        this.setState({
            validationErrors: {
                ...this.state.validationErrors,
                [`attribution-${id}-value`]: validationError,
            },
        });
        this.props.onChangeValue(id, e.target.value, validationError);
    };

    onClickAddAttribution = () => {
        const id =
            !this.props.values || this.props.values.length === 0
                ? 0
                : this.props.values[this.props.values.length - 1].id + 1;
        const validationError = this.validate(null);

        this.setState({
            validationErrors: {
                ...this.state.validationErrors,
                [`attribution-${id}-key`]: validationError,
                [`attribution-${id}-value`]: this.props.type !== "METADATA_TEMPLATE" ? this.validate(null) : undefined,
            },
        });

        this.props.onClickAddAttribution(id, validationError);
    };

    onClickRemoveAttribution = (id) => {
        const validationErrors = this.state.validationErrors;

        const validationErrorKeys = Object.keys(this.state.validationErrors);
        if (validationErrorKeys.length > 0) {
            validationErrorKeys.forEach((k) => {
                if (k.indexOf(`attribution-${id}`) > -1) {
                    delete validationErrors[k];
                }
            });
        }

        this.props.onClickRemoveAttribution(id);
    };

    validate = (str, values, isKey) => {
        const validationError = validateInput(str, {
            required: true,
            maxByte: LIMIT_BYTE.attribution,
            uniqueIn: isKey && values,
        });

        if (validationError && validationError.type === "REQUIRED") {
            validationError.level = "warning";
        }

        return validationError;
    };

    getValidationError = (key) => {
        return this.state.validationErrors[key] || {};
    };

    render = () => {
        const { t } = this.props;
        return (
            <React.Fragment>
                {this.props.values &&
                    this.props.values.map((v, i) => (
                        <Grid container key={i} flexWrap={"nowrap"} gap={1} my={0.75} alignItems={"center"}>
                            <Grid item>
                                <div
                                    className={`form-input form-${
                                        this.getValidationError(`attribution-${v.id}-key`).level
                                    }`}
                                >
                                    <input
                                        type="text"
                                        value={v.key}
                                        order={v.id}
                                        placeholder={t(`common::label::${"Key"}`)}
                                        id={"key" + v.id}
                                        className={"form-control key"}
                                        onChange={(e) => {
                                            this.onChangeKey(v.id, e);
                                        }}
                                        style={{
                                            position: "relative",
                                            opacity: 1,
                                        }}
                                    />

                                    {this.getValidationError(`attribution-${v.id}-key`).message && (
                                        <div
                                            className={`form-message ${
                                                this.getValidationError(`attribution-${v.id}-key`).level
                                            }`}
                                        >
                                            <span>{this.getValidationError(`attribution-${v.id}-key`).message}</span>
                                        </div>
                                    )}
                                </div>
                            </Grid>

                            <Grid item>
                                <div
                                    className={`form-input form-${
                                        this.getValidationError(`attribution-${v.id}-value`).level
                                    }`}
                                >
                                    <input
                                        type="text"
                                        value={v.value}
                                        placeholder={t(`common::label::${"Value"}`)}
                                        className={"form-control"}
                                        onChange={(e) => {
                                            this.onChangeValue(v.id, e);
                                        }}
                                        style={{
                                            position: "relative",
                                            opacity: 1,
                                        }}
                                    />
                                    {this.getValidationError(`attribution-${v.id}-value`).message && (
                                        <div
                                            className={`form-message ${
                                                this.getValidationError(`attribution-${v.id}-value`).level
                                            }`}
                                        >
                                            <span>{this.getValidationError(`attribution-${v.id}-value`).message}</span>
                                        </div>
                                    )}
                                </div>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    size="small"
                                    circled
                                    onClick={() => {
                                        this.onClickRemoveAttribution(v.id);
                                    }}
                                >
                                    <IconCancel size={16} htmlColor={theme.palette.greySecondary[500]} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    ))}

                {Object.keys(this.state.validationErrors).filter(
                    (k) => this.state.validationErrors[k] && this.state.validationErrors[k].level === "warning",
                ).length > 0 && (
                    <div className={"form-warning form-warning-attributions"}>
                        <div className="form-message">
                            <p>
                                <Typography variant={`body3_500`}>
                                    {t(
                                        "common::msg::Attribution cannot be empty. Please enter a valid attribution.",
                                        "Attribution cannot be empty. Please enter a valid attribution.",
                                    )}
                                </Typography>
                            </p>
                        </div>
                    </div>
                )}
                <Divider sx={{ my: 1.5 }} />
                <Grid container justifyContent={"flex-end"}>
                    <Button
                        size="small"
                        variant="text"
                        color="primary"
                        startIcon={<IconAdd size={16} />}
                        disabled={Object.values(this.state.validationErrors).filter((v) => v).length > 0}
                        onClick={(e) => {
                            this.onClickAddAttribution();
                        }}
                    >
                        {t(`common::label::${"Add"}`)}
                    </Button>
                </Grid>
            </React.Fragment>
        );
    };
}

export default withTranslation()(AttributionInputArea);
