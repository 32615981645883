import { Tabs as PDCTabs, Tab } from "@mzc-pdc/ui";
import { alpha, styled } from "@mui/material";

import theme from "@styles/theme";

export const StyledTabs = styled(PDCTabs)(({ theme, type, orientation, divider, sx }) => ({
    ".MuiTabs-flexContainer": {
        ...sx,
        position: `relative`,
        marginLeft: `-1px`,
        "&:before": {
            content: '""',
            position: `absolute`,
            left: 0,
            bottom: 0,
            right: 0,
        },
        ".MuiTab-root": {
            position: `relative`,
            "&:hover": {
                borderBottomColor: `#fff`,
            },
        },
    },
    ".MuiButtonBase-root.MuiTab-root": {
        fontSize: theme.typography.body1_500,
    },
    ...(orientation === "vertical" && {
        "& .MuiTabs-indicator": {
            width: `4px`,
            left: `0`,
            backgroundColor: theme.palette.primary.main,
        },
        "& .MuiTab-root": {
            border: `none`,
            minHeight: `40px`,
            alignItems: `baseline`,
            padding: `0 16px`,
            borderRadius: `3px`,
            fontWeight: `500`,
            "& + &": {
                marginTop: `8px`,
            },
            "&:hover": {
                backgroundColor: alpha(theme.palette.greySecondary[50], 0.5),
            },
            "&.Mui-selected": {
                color: theme.palette.primary.main,
                backgroundColor: alpha(theme.palette.greySecondary[50], 0.5),
                "&:hover": {
                    backgroundColor: alpha(theme.palette.greySecondary[50], 0.8),
                },
            },
        },
    }),
    ...(orientation !== "vertical" &&
        divider && {
            ".MuiTab-root": {
                position: "relative",
                "&:not(:last-of-type)::after": {
                    content: '""',
                    position: "absolute",
                    right: 0,
                    top: "50%",
                    transform: "translateY(-50%)",
                    height: "50%",
                    borderRight: `1px solid ${theme.palette.greySecondary[100]}`,
                    marginLeft: "8px",
                },
            },
        }),
    ...(type === "filled" && {
        ".MuiTabs-indicator": {
            display: `none`,
        },
        ".MuiTab-root": {
            padding: `6.25px 16px`,
            backgroundColor: theme.palette.common.white,
            "&:first-of-type": {
                borderRadius: `3px 0 0 3px`,
            },
            "&:last-of-type": {
                borderRadius: `0 3px 3px 0`,
            },
            "&.Mui-selected ": {
                color: theme.palette.secondary.contrastText,
                backgroundColor: theme.palette.secondary.main,
                borderColor: theme.palette.secondary.main,
                "&:hover": {
                    color: theme.palette.secondary.contrastText,
                    backgroundColor: theme.palette.secondary.main,
                    borderColor: theme.palette.secondary.main,
                },
            },
        },
    }),
}));
