import AssetThumbnail from "@features/thumbnail/asset-thumbnail";

const LinkedAssetsColumn = [
    {
        id: "name",
        label: "Name",
        style: { custom: { paddingLeft: "12px", flex: 1 } },
        disabled: true,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: false,
    },
    {
        id: "extensions",
        label: "Extensions",
        style: { custom: { maxWidth: 170, minWidth: 80, flex: 0.3 } },
        disabled: false,
        checked: true,
        sortable: false,
        canSubColumn: true,
    },
    {
        id: "mediaType",
        label: "Media type",
        style: { custom: { maxWidth: 170, minWidth: 80, flex: 0.3 } },
        disabled: false,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: true,
    },
    {
        id: "size",
        label: "Size",
        style: { custom: { maxWidth: 170, minWidth: 80, flex: 0.3 } },
        disabled: false,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: true,
    },
];

const VideoAssetColumn = [
    {
        id: "desiredStatus",
        label: "status",
        style: { custom: { padding: "0 12px", width: 32 } },
        disabled: true,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: false,
    },
    {
        id: "name",
        label: "Name",
        style: { custom: { flex: 1 } },
        disabled: true,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: false,
    },
    {
        id: "extensions",
        label: "Extensions",
        style: { custom: { width: 100 } },
        disabled: false,
        checked: true,
        sortable: false,
        useInAutocomplete: false,
        canSubColumn: true,
    },
    {
        id: "duration",
        label: "Duration",
        style: { custom: { width: 100 } },
        disabled: false,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: true,
    },
    {
        id: "size",
        label: "Size",
        style: { custom: { width: 100 } },
        disabled: false,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: true,
    },
];

const AudioAssetColumn = [
    {
        id: "desiredStatus",
        label: "status",
        style: { custom: { padding: "0 12px", width: 32 } },
        disabled: true,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: false,
    },
    {
        id: "name",
        label: "Name",
        style: { custom: { maxWidth: "500px", flex: 1 } },
        disabled: true,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: false,
    },
    {
        id: "extensions",
        label: "Extensions",
        style: { custom: { width: 100 } },
        disabled: false,
        checked: true,
        sortable: false,
        useInAutocomplete: false,
        canSubColumn: true,
    },
    {
        id: "duration",
        label: "Duration",
        style: { custom: { width: 100 } },
        disabled: false,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: true,
    },
    {
        id: "size",
        label: "Size",
        style: { custom: { width: 100 } },
        disabled: false,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: true,
    },
];

const ImageAssetColumn = [
    {
        id: "desiredStatus",
        label: "status",
        style: { custom: { padding: "0 12px", width: 32 } },
        disabled: true,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: false,
    },
    {
        id: "name",
        label: "Name",
        style: { custom: { flex: 1 } },
        disabled: true,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: false,
    },
    {
        id: "type",
        label: "Type",
        style: { custom: { width: 100 } },
        disabled: false,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: true,
    },
    {
        id: "size",
        label: "Size",
        style: { custom: { width: 100 } },
        disabled: false,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: true,
    },
];

const CaptionAssetColumn = [
    {
        id: "desiredStatus",
        label: "status",
        style: { custom: { width: 32, display: "flex", justifyContent: "center" } },
        disabled: true,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: false,
    },
    {
        id: "name",
        label: "Name",
        style: { custom: { flex: 1 } },
        disabled: true,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: false,
    },
    {
        id: "type",
        label: "Type",
        style: { custom: { width: 100 } },
        disabled: false,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: true,
    },
    {
        id: "size",
        label: "Size",
        style: { custom: { width: 100 } },
        disabled: false,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: true,
    },
];

const FileAssetColumn = [
    {
        id: "desiredStatus",
        label: "status",
        style: { custom: { padding: "0 12px", width: 32 } },
        disabled: true,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: false,
    },
    {
        id: "name",
        label: "Name",
        style: { custom: { flex: 1 } },
        disabled: true,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: false,
    },
    {
        id: "extensions",
        label: "Extensions",
        style: { custom: { width: 100 } },
        disabled: false,
        checked: true,
        sortable: false,
        useInAutocomplete: false,
        canSubColumn: true,
    },
    {
        id: "size",
        label: "Size",
        style: { custom: { width: 100 } },
        disabled: false,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: true,
    },
];

const DialogAssetsColumn = [
    {
        id: "nameWithSubName",
        label: "Name",
        style: { paddingLeft: "12px", flex: 1 },
    },
];

const PreviewAssetColumn = [
    {
        id: "name",
        label: "Name",
        style: { custom: { flex: 1, margin: "8px 8px 12px 12px" }, thumbnail: { width: 88, height: 88, minWidth: 88 } },
        disabled: true,
        checked: true,
        sortable: false,
        useInAutocomplete: true,
        canSubColumn: false,
    },
    {
        id: "extensions",
        label: "Extensions",
        style: { custom: { width: 50 } },
        disabled: false,
        checked: true,
        sortable: false,
        canSubColumn: true,
    },
    {
        id: "width",
        label: "Width",
        style: { custom: { width: 100 } },
        disabled: false,
        checked: true,
        sortable: false,
        canSubColumn: true,
    },
];

export {
    LinkedAssetsColumn,
    AudioAssetColumn,
    CaptionAssetColumn,
    FileAssetColumn,
    ImageAssetColumn,
    VideoAssetColumn,
    DialogAssetsColumn,
    PreviewAssetColumn,
};
