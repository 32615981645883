import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { EntryLoader } from "../../components/loader";
import { LayoutForNoting } from "../../components/layout";
import { isLocal, isDev } from "@cores/enviromentExtension";

// LX Hausys Tiptop에서 앱을 클릭해서 들어오게 될 때 호출되는 페이지
class InitiateLXHausys extends React.Component {
    componentDidMount() {
        if(isLocal || isDev) {
            window.location.href = "https://sx7bqjcob9.execute-api.ap-northeast-2.amazonaws.com/dev/saml/lx-hausys-saml/login";
        } else {
            window.location.href = "https://6bwtugkol6.execute-api.ap-northeast-2.amazonaws.com/lxhausys/saml/lx-hausys-saml/login"
        }
    }

    render() {
        return (
            <LayoutForNoting>
                <EntryLoader />
            </LayoutForNoting>
        );
    }
}
let mapStateToProps = (state) => {
    return {};
};
let mapDispatchToProps = (dispatch) => {
    return {};
};

InitiateLXHausys = connect(mapStateToProps, mapDispatchToProps)(InitiateLXHausys);
export default withRouter(InitiateLXHausys);
