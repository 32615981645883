import { Box, Grid, IconButton, LinearProgress, Stack, Tooltip } from "@mzc-pdc/ui";
import AssetTypeFlag from "@components_v2/icon-type/asset/asset-type-flag";
import { GridNameEllipsis } from "@components_v2/grid/grid.styled";
import Typography from "@components_v2/typography/typography";
import { IconErrorCircle, IconHierarchyFolded, IconPath } from "@mzc-cloudplex/icons";
import theme from "@styles/theme";
import ObjectIcon from "@routes/sources/components/ObjectIcon";
import { Trans } from "react-i18next";
import { LinearProgressDeterminate } from "@components_v2/progressbar/progressbar";

const JobTaskUpload = ({ job, tasks }) => {
    return (
        <>
            <Stack py={1.5} gap={2}>
                <Box>
                    <Grid container flexWrap={"nowrap"}>
                        <Grid item sx={{ display: "flex", alignItems: "center" }}>
                            <Grid item>
                                <ObjectIcon path={tasks[0].name} />
                            </Grid>
                            <GridNameEllipsis item maxWidth={"300px"} sx={{ ml: theme.spacing(1) }}>
                                <Typography variant="body3_400">{tasks[0].name}</Typography>
                            </GridNameEllipsis>
                            {tasks.length > 1 && (
                                <>
                                    <Typography variant="body3_400">
                                        <Trans
                                            i18nKey="common::label::and <emp>{{count}}</emp> others"
                                            components={{
                                                emp: <Typography variant="body3_700" color="primary.main"></Typography>,
                                            }}
                                            values={{
                                                count: tasks.length - 1,
                                            }}
                                        />
                                    </Typography>
                                </>
                            )}
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        sx={{ display: "flex", gap: theme.spacing(1), ml: theme.spacing(3.5), mt: theme.spacing(1) }}
                    >
                        <Grid item>
                            <IconPath size={16} htmlColor={theme.palette.greySecondary[500]} />
                        </Grid>
                        <Grid item>
                            <Typography variant="body3_400" color={theme.palette.greySecondary[600]}>
                                {job.bucketPath ? `${job.bucketPath?.bucket}/${job.bucketPath?.folderPath}` : ""}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Grid container flexWrap={"nowrap"} gap={1} flexDirection={"column"}>
                    {tasks.map((task) => (
                        <Grid container flexDirection={"column"} gap={1}>
                            <Grid container justifyContent={"space-between"}>
                                <Grid item sx={{ display: "flex" }} gap={theme.spacing(1)}>
                                    <Grid item>
                                        <IconHierarchyFolded size={6} htmlColor={theme.palette.greySecondary[500]} />
                                    </Grid>
                                    <Grid item>
                                        <ObjectIcon path={task.name} />
                                    </Grid>
                                    <GridNameEllipsis item maxWidth={"300px"}>
                                        <Typography variant="body2_500">{task.name}</Typography>
                                    </GridNameEllipsis>
                                </Grid>
                                {task.status === "ERROR" && (
                                    <Grid item>
                                        <IconButton circled size="small">
                                            <IconErrorCircle size={16} color="error" />
                                        </IconButton>
                                    </Grid>
                                )}
                            </Grid>
                            {job.status === "UPLOADING" && (
                                <Grid item ml={theme.spacing(2)}>
                                    <LinearProgressDeterminate
                                        value={task.uploadFinished ? 100 : task.uploadedPercentage ?? 0}
                                        withLabel={true}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    ))}
                </Grid>
            </Stack>
        </>
    );
};

export default JobTaskUpload;
