import { Checkbox, Divider, Grid, List, ListItem, ListItemText } from "@mzc-pdc/ui";
import theme from "@styles/theme";
import React, { useEffect, useState } from "react";
import Typography from "@components_v2/typography/typography";
import UserDetailPopper from "@features/user-detail-popper/user-detail-popper";
import { IconAvatar } from "@mzc-cloudplex/icons";
import { RandomColoredAvatar } from "@components_v2/avatar/random-colored-avatar";
import { getProjectUsersAPI } from "@modules/apis/user-management-v2";
import { useSelector } from "react-redux";
import { alpha } from "@mui/material";

const UserList = ({ data, isPublic }) => {
    const stage = useSelector(({ stage }) => stage);
    const project = useSelector(({ project }) => project);

    const [members, setMembers] = useState([]);
    const getMembers = async () => {
        try {
            let nextToken = null;
            let members = [];
            do {
                const params = { limit: 100 };
                if (nextToken) params.nextToken = nextToken;
                const response = await getProjectUsersAPI(stage.id, stage.endpoint, project.id, params);
                nextToken = response.data?.nextToken;
                members = [...members, ...response.data?.results];
            } while (!!nextToken);
            return members;
        } catch (e) {}
    };

    useEffect(async () => {
        if (isPublic) {
            const response = await getMembers();
            setMembers(response);
        } else {
            setMembers(data?.results);
        }
    }, []);

    if (members?.length > 0)
        return (
            <Grid
                item
                sx={{
                    borderRadius: `4px`,
                    border: `1px solid ${theme.palette.divider}`,
                }}
            >
                <List sx={{ padding: "0", overflowY: "auto", maxHeight: 384 }}>
                    {members?.map((item, index) => (
                        <>
                            {index > 0 && <Divider />}
                            <ListItem
                                data-id={item.id}
                                sx={{ "&:hover": { bgcolor: alpha(theme.palette.greySecondary[50], 0.24) } }}
                            >
                                <ListItemText
                                    p={1.5}
                                    primary={
                                        <Grid
                                            container
                                            alignItems={"flex-start"}
                                            direction="row"
                                            flexWrap={"nowrap"}
                                            gap={1}
                                        >
                                            <RandomColoredAvatar id={item.id} name={item.name} size={24} />
                                            <Typography variant={"body1Bold"}>{item.name}</Typography>
                                        </Grid>
                                    }
                                    disableTypography
                                />
                            </ListItem>
                        </>
                    ))}
                </List>
            </Grid>
        );

    return null;
};

export default UserList;
