import {handleActions, createAction} from 'redux-actions';
import {
    axios,
} from "../cores/axiosWrapper";
import queryString from "../cores/queryString";

const getAttributionKeysAPI = (apiEndpoint, projectId, queryParams) => {
    const search = queryParams || ``;
    if(projectId) {
        return axios.get(`${apiEndpoint}/attribution-keys?${queryString.stringify(search)}`, {
            headers: {
                projectId
            }
        });
    }
    return axios.get(`${apiEndpoint}/attribution-keys?${queryString.stringify(search)}`);
};

const ATTRIBUTIONS_GET_KEYS_PENDING = 'ATTRIBUTIONS_GET_KEYS_PENDING';
const ATTRIBUTIONS_GET_KEYS_SUCCESS = 'ATTRIBUTIONS_GET_KEYS_SUCCESS';
const ATTRIBUTIONS_GET_KEYS_FAILURE = 'ATTRIBUTIONS_GET_KEYS_FAILURE';

const initialState = {
    error: false,
    keys: {
        pending: false,
        error: false,
        items: []
    }
};

export default handleActions({
    [ATTRIBUTIONS_GET_KEYS_PENDING]: (state) => {
        return {
            ...state,
            keys: {
                ...state.keys,
                pending: true,
                error: false,
            }
        };
    },
    [ATTRIBUTIONS_GET_KEYS_SUCCESS]:(state, action) => {
        const { data } = action.payload;
        return {
            ...state,
            keys: {
                items: data.keys,
                totalCount: data.totalCount,
                pending: false,
                error: false
            }
        };
    },
    [ATTRIBUTIONS_GET_KEYS_FAILURE]: (state) => {
        return {
            ...state,
            keys: {
                ...state.keys,
                pending: false,
                error: true,
            }
        };
    }
}, initialState);

export const getAttributionKeys = (projectId, query) => (dispatch, getState) => {
    const {
        stage,
    } = getState();

    dispatch({type: ATTRIBUTIONS_GET_KEYS_PENDING});

    return new Promise((resolve, reject) => {
        getAttributionKeysAPI(stage.endpoint, projectId, query)
          .then((response) => {
              dispatch({
                  type: ATTRIBUTIONS_GET_KEYS_SUCCESS,
                  payload: response
              });
              resolve(response.data);
          })
          .catch(error => {
              dispatch({
                  type: ATTRIBUTIONS_GET_KEYS_FAILURE,
                  payload: error
              });
              reject(error);
          });
    })
};