import React, { FC } from "react";
import i18n from "@lib/i18n";
import { StyledStatusLabel } from "./status-label.styled";
import { IconCompleteCircle, IconErrorCircle } from "@mzc-cloudplex/icons";
import { Typography } from "@mui/material";
import theme from "@styles/theme";
import { CircularProgress } from "@mzc-pdc/ui";

const StatusLabel = ({ type, isCompact }, ...rest) => (
    <StyledStatusLabel {...rest}>
        {["PREPARING"].includes(type) && (
            <>
                <CircularProgress size={16} color={"primary"} />
                {!isCompact && (
                    <Typography variant={"body1_400"} color={theme.palette.primary.main}>
                        {type}
                    </Typography>
                )}
            </>
        )}
        {type === "READY" && (
            <>
                <IconCompleteCircle size={16} htmlColor={theme.palette.success[600]} />
                {!isCompact && (
                    <Typography variant={"body1_400"} color={theme.palette.success[600]}>
                        {i18n.t(`common::label::READY TO DOWNLOAD`, `READY TO DOWNLOAD`)}
                    </Typography>
                )}
            </>
        )}
        {type === "SUBMITTED" && (
            <>
                {!isCompact && (
                    <Typography variant={"body1_400"} color={theme.palette.primary[600]}>
                        {type}
                    </Typography>
                )}
            </>
        )}
        {["ERROR"].includes(type) && (
            <>
                <IconErrorCircle size={16} htmlColor={theme.palette.error[700]} />
                {!isCompact && (
                    <Typography variant={"body1_400"} color={theme.palette.error[700]}>
                        {type}
                    </Typography>
                )}
            </>
        )}
    </StyledStatusLabel>
);

export default StatusLabel;
