export const AssetsColumn = [
    { id: "desiredStatus", label: "desiredStatus", style: { custom: { minWidth: 40 } } },
    {
        id: "name",
        label: "Name",
        style: { custom: { minWidth: 200, maxWidth: 200 } },
        disabled: true,
        checked: true,
        sortable: false,
    },
];

export default AssetsColumn;
