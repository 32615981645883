import sendFileData from './sendFileData';

const fileUpload = ({
    file,
    stageId,
    projectId,
    cancelToken,
    onUploadProgress = () => { },
    onComplete = () => { },
    onCancel = () => { },
    onError = () => { } }
) => {
    if (!file) return;
    let params = {
        file,
        stageId,
        projectId,
        cancelToken,
        onUploadProgress,
        onComplete,
        onCancel,
        onError
    }
    sendFileData(params);
};

export default fileUpload;

// export const fileUploadForPreSigned = ({
//     file, 
//     stageId, 
//     projectId,
//     cancelToken, 
//     onUploadProgress = () => {},
//     onComplete = () => {}, 
//     onCancel = () => {}, 
//     onError = () => {}}
// ) => {    
//     if (!file) return;

//     let params = {
//         file, 
//         stageId, 
//         projectId,
//         cancelToken,
//         onUploadProgress,
//         onComplete,
//         onCancel,
//     }

//     if (window.Worker) {
//         const worker = new work(require.resolve('./getSha256Worker'));

//         worker.onmessage = (e) => {
//             params.hashedStr = e.data;

//             sendFileDataActions.sendFileDataForPreSigned(params);
//         }

//         worker.postMessage(file);
//     } else {
//         getSha256(file, undefined, params);
//     }
// };