function charByteSize(ch) {

    if (ch == null || ch.length == 0) {
        return 0;
    }

    var charCode = ch.charCodeAt(0);

    if (charCode <= 0x00007F) {
        return 1;
    } else if (charCode <= 0x0007FF) {
        return 2;
    } else if (charCode <= 0x00FFFF) {
        return 3;
    } else {
        return 4;
    }
}

export default charByteSize;