import { Grid, styled } from "@mzc-pdc/ui";
import React, { forwardRef, useCallback, useEffect, useState } from "react";
import "@features/comments/editor-comment.css";
import Typography from "@components_v2/typography/typography";
import theme from "@styles/theme";
import { Trans, useTranslation } from "react-i18next";
import useComments from "@hooks/useComments";
import DialogDeleteV2 from "@features/dialog/dialog-delete-v2";
import CardCommentContent from "@features/comments/card-comment-content";
import useCommonComments from "@hooks/useCommonComments";
import { updateIsEditingCommentId, updateSelectedCommentId } from "@modules/common-comments";
import { useDispatch } from "react-redux";

const StyledCommentGrid = styled(Grid)(({ theme, isThread }) => ({
    padding: `${isThread ? "8px" : "16px"} 16px`,
    borderRadius: isThread ? 0 : 4,
    backgroundColor: theme.palette.common.white,
}));

const CardComments = forwardRef(({ data, resourceId, domain, isThread, isUploading }, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        convertCommentToHTML,
        onChangeComment,
        onDeleteComment,
        onUpdateComment,
        onCreateActions,
        onDeleteActions,
        onChangeAttachments,
        onDeleteAttachments,
        htmlComment,
        attachments,
    } = useCommonComments({ domain, resourceId });

    const [modal, setModal] = useState(null);

    const onToggleModal = (key, value, actionTarget = null) => {
        if (value) {
            setModal(key);
        } else {
            setModal("");
        }
    };

    const onHandleDeleteComment = () => {
        onToggleModal("delete", true);
    };
    const onInitComment = useCallback(() => {
        onChangeComment(convertCommentToHTML(data));
        if (data.attachments?.length > 0) {
            onChangeAttachments(data.attachments);
        }
    }, [data]);

    const updateSelectedComment = (id) => {
        dispatch(updateSelectedCommentId(id));
    };

    const updateIsEditingComment = (id) => {
        dispatch(updateIsEditingCommentId(id));
    };

    useEffect(() => {
        if (!data) return;
        onInitComment();
    }, [data]);

    return (
        <>
            <StyledCommentGrid container flexDirection={"row"} flexWrap={"nowrap"} isThread={isThread} ref={ref}>
                <CardCommentContent
                    isUploading={isUploading}
                    domain={domain}
                    data={data}
                    editComment={htmlComment}
                    editAttachments={attachments}
                    comment={convertCommentToHTML(data, null, true)}
                    isThread={isThread}
                    emojiButton={true}
                    isEditable={true}
                    onChangeComment={onChangeComment}
                    onChangeAttachments={onChangeAttachments}
                    onUpdateComment={onUpdateComment}
                    onDeleteComment={onHandleDeleteComment}
                    onCreateActions={onCreateActions}
                    onDeleteActions={onDeleteActions}
                    onInitComment={onInitComment}
                    updateIsEditingCommentId={updateIsEditingComment}
                    updateSelectedCommentId={updateSelectedComment}
                    onDeleteAttachments={onDeleteAttachments}
                />
            </StyledCommentGrid>
            {modal === "delete" && (
                <DialogDeleteV2
                    title={t(`common::msg::Delete message?`)}
                    items={[data]}
                    bulkDelete={false}
                    loopDelete={false}
                    contents={(item) => (
                        <Grid container maxWidth={"432px"} gap={2.5}>
                            <Typography variant={"body1_400"}>
                                {t(`common::msg::Deleting a message cannot be canceled.`)}
                            </Typography>
                            <Grid
                                item
                                sx={{
                                    borderRadius: "4px",
                                    border: `1px solid ${theme.palette.greySecondary[100]}`,
                                    width: "100%",
                                }}
                            >
                                <StyledCommentGrid
                                    container
                                    flexDirection={"row"}
                                    flexWrap={"nowrap"}
                                    isThread={isThread}
                                >
                                    <CardCommentContent
                                        domain={domain}
                                        data={data}
                                        comment={htmlComment}
                                        threadButton={false}
                                        emojiButton={false}
                                        isEditable={false}
                                        updateIsEditingCommentId={updateIsEditingComment}
                                        updateSelectedCommentId={updateSelectedComment}
                                    />
                                </StyledCommentGrid>
                            </Grid>
                        </Grid>
                    )}
                    onClickSubmit={onDeleteComment}
                    onClickClose={() => onToggleModal()}
                />
            )}
        </>
    );
});

export default CardComments;
