// local에서 운영환경 붙을 때 사용하는 config
const local_prod = {
    host: {
        console: "http://localhost:60301",
        cms: "http://localhost:60301",
        api: "https://api.cloudplex.megazone.io",
        analysis: "https://8l37gc13a3.execute-api.ap-northeast-2.amazonaws.com",
        loginRedirectUri: "/megazone/login",
    },
    mz_pops: {
        oauth_host: "https://login.megazone.com",
        api_host: "https://www.megazoneapis.com",
        clientId: "d1J99hKQAdWH1C9lKM70QqeCDJI3zu",
    },
    profileId: "1536304085jekLFQq1",
    PLAYER_URL: "https://d1c0zwuyn5xls7.cloudfront.net",
    cloudplex: {
        startApiEndpoint: "https://1kk99cdimk.execute-api.ap-northeast-2.amazonaws.com/prod",
        clientId: "p77NsOvO70Efsd2Lcmsx8MXg0GoTix",
    },
    // hotjar_tracking_code: null,
};

export default local_prod;

//console.media.megazone.com
