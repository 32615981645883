import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import deepClone from "@utils/deepClone";
import { CATEGORY_TYPES, UNCATEGORIZED_ID } from "../../constants";
import { useTranslation } from "react-i18next";
import { clear, initCategoryData, updateFilter, changeFold } from "@modules/categoryModalTreeView";
import { getCategoryGroups } from "@modules/categoryGroupsManagement";
import WithSelect from "@components/select";
import fp from "lodash/fp";
import {
    IconCancelCircle,
    IconHierarchyFolded,
    IconHierarchyOpened,
    IconInfoLinear,
    IconRefresh,
    IconResetSmall,
    IconSearch,
    ImageCategoryGroupOn,
    ImageCategorySelect,
} from "@mzc-cloudplex/icons";
import theme from "@styles/theme";
import {
    Button,
    Chip,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    Popper,
    Stack,
    TextField,
    Typography,
} from "@mzc-pdc/ui";

const SelectCategoryGroupPopper = ({
    project,
    selectedCategoryGroup,
    selectedCategories,
    type,
    defaultCategoryGroup,
    onChangeCategories,
    onModalClose,
    onClose,
    submitText,
    anchorEl,
    placement,
    open,
    disablePortal,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchKeyword, setSearchKeyword] = useState("");
    const [selectedCategories_, setSelectedCategories_] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [categoryGroup, setCategoryGroup] = useState({ value: "", label: "" });

    const categoryGroups = useSelector(({ categoryGroupsManagement }) => categoryGroupsManagement.categoryGroups);
    const categoryModalTreeView = useSelector(({ categoryModalTreeView }) => categoryModalTreeView);

    const onRefresh = async () => {
        await dispatch(clear());

        setSearchKeyword("");
        setSelectedCategories_([]); // 선택된 카테고리
        if (categoryGroup.value) dispatch(initCategoryData(project.id, type, categoryGroup.value));
    };

    const initData = async () => {
        await dispatch(clear());

        setSearchKeyword("");
        if (categoryGroup.value) dispatch(initCategoryData(project.id, type, categoryGroup.value));

        await dispatch(
            getCategoryGroups(project.id, {
                name: "",
                creator: "",
                status: "ACTIVE",
            }),
        );
    };

    useEffect(() => {
        if (selectedCategories.length > 0) {
            setSelectedCategories_(selectedCategories);
            if (selectedCategoryGroup) {
                setCategoryGroup({
                    label: selectedCategoryGroup.label,
                    value: selectedCategoryGroup.id,
                });
            }
        }
    }, []);

    useEffect(() => {
        if (defaultCategoryGroup) {
            setCategoryGroup({ value: defaultCategoryGroup.value });
        }
    }, [defaultCategoryGroup]);

    useEffect(() => {
        const categories = selectedCategories.map((v) => {
            return {
                ...v,
                id: `${v.id}`,
            };
        });

        setSelectedCategories_(categories);
    }, [selectedCategories]);

    useEffect(() => {
        dispatch(clear());
    }, [project]);

    useEffect(() => {
        initData();
    }, [categoryGroup?.value]);

    const onSelectedCategories = () => {
        let selectedCategoryGroupInfo = {
            categoryGroup: { id: categoryGroup.value, label: categoryGroup.label },
            categories: selectedCategories_.map((v) => {
                return {
                    value: v.id,
                    label: v.name,
                    name: v.name,
                    id: v.id,
                };
            }),
        };
        onChangeCategories(selectedCategoryGroupInfo);
        onClickClose();
    };

    const onClickClose = () => {
        dispatch(clear());

        onModalClose(false);
        onClose();
    };

    const onChangeSearchKeyword = (keyword) => {
        setSearchKeyword(keyword);
    };

    const getCategoryGroupLabel = useCallback(
        (id) => {
            return categoryGroups.find((el) => el.id === id)?.name;
        },
        [categoryGroup, categoryGroups],
    );

    function makeMultipleDiv(item) {
        if (!item?.code) {
            return "";
        }
        const makeArray = item.code.split("_").splice(1) || [];
        if (makeArray.length < 1) {
            return "";
        }
        return makeArray.map((item, index) => {
            return <span key={index} />;
        });
    }

    const ChildItem = useCallback(
        (child) => {
            const childItems = child.map((item) => {
                return (
                    <React.Fragment key={item.id}>
                        <ul>
                            <li
                                className={`${selectedCategories_.find((v) => v.id == item.id) ? "tree-selected" : ""}`}
                            >
                                <div
                                    className={`tree-item ${
                                        selectedCategories_.find((v) => v.id == item.id) ? "tree-item-selected" : ""
                                    }`}
                                >
                                    {" "}
                                    {item && makeMultipleDiv(item)}
                                    <button
                                        type="button"
                                        className={`btn btn-fold btn-icon-solid ${!item.isFold ? "active" : ""} ${
                                            item.children.length === 0 ? "disabled" : ""
                                        }`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onClickFoldButton(item);
                                        }}
                                    >
                                        {" "}
                                        {item.isFold ? (
                                            <IconHierarchyFolded
                                                size={12}
                                                htmlColor={item.children.length === 0 ? `#6f6f8366` : `#6f6f83`}
                                            />
                                        ) : (
                                            <IconHierarchyOpened
                                                size={12}
                                                htmlColor={item.children.length === 0 ? `#6f6f8366` : `#6f6f83`}
                                            />
                                        )}
                                    </button>
                                    <label className={"form-checkbox name"}>
                                        <input
                                            type="checkbox"
                                            className={`form-check-input ${
                                                item.checkState === "Indeterminate" ? "half" : ""
                                            }`}
                                            checked={
                                                selectedCategories_.length > 0 &&
                                                selectedCategories_.find((v) => v.id == item.id) !== undefined
                                            }
                                            onChange={(e) => {
                                                e.stopPropagation();
                                                onChangeCategoryChecked(item, e.target.checked);
                                            }}
                                        />{" "}
                                        <i className={"input-helper"} />
                                        <span>{item.name}</span>
                                        <span className={"count"}>
                                            {item.children && item.children.length === 0
                                                ? ""
                                                : `${item.children.length}`}
                                        </span>{" "}
                                    </label>
                                </div>
                                {item && !item.isFold && true && item.children.length > 0
                                    ? ChildItem(item.children)
                                    : ""}
                            </li>
                        </ul>
                    </React.Fragment>
                );
            });
            return childItems;
        },
        [categoryModalTreeView, selectedCategories_],
    );

    const treeData = useMemo(() => {
        const isFilter = categoryModalTreeView?.filter?.isFilter;
        const categoryGroup_ = isFilter
            ? categoryModalTreeView?.filter?.data?.find((v) => v.id !== UNCATEGORIZED_ID)
            : categoryModalTreeView?.data?.find((v) => v.id !== UNCATEGORIZED_ID);
        return categoryGroup_ && categoryGroup_.children;
    }, [categoryModalTreeView, selectedCategories_]);

    const renderTree = useCallback(() => {
        const treeData_ = treeData;
        if (!treeData_) return <React.Fragment />;
        const listItems = treeData_?.map((tree) => (
            <React.Fragment key={tree.id}>
                <ul>
                    <li className={`${selectedCategories_.find((v) => v.id == tree.id) ? "tree-selected" : ""}`}>
                        <div
                            className={`tree-item ${
                                selectedCategories_.find((v) => v.id == tree.id) ? "tree-item-selected" : ""
                            }`}
                        >
                            {" "}
                            {tree && makeMultipleDiv(tree)}
                            <button
                                type="button"
                                className={`btn btn-icon-solid btn-fold ${!tree.isFold ? "active" : ""} ${
                                    tree.children.length === 0 ? "disabled" : ""
                                }`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    onClickFoldButton(tree);
                                }}
                            >
                                {" "}
                                {tree.isFold ? (
                                    <IconHierarchyFolded
                                        size={12}
                                        htmlColor={tree.children.length === 0 ? `#6f6f8366` : `#6f6f83`}
                                    />
                                ) : (
                                    <IconHierarchyOpened
                                        size={12}
                                        htmlColor={tree.children.length === 0 ? `#6f6f8366` : `#6f6f83`}
                                    />
                                )}
                            </button>
                            <label className={"form-checkbox name"}>
                                <input
                                    type="checkbox"
                                    className={`form-check-input ${tree.checkState === "Indeterminate" ? "half" : ""}`}
                                    checked={
                                        selectedCategories_.length > 0 &&
                                        selectedCategories_.find((v) => v.id == tree.id)
                                    }
                                    onChange={(e) => {
                                        e.stopPropagation();
                                        onChangeCategoryChecked(tree, e.target.checked);
                                    }}
                                />{" "}
                                <i className={"input-helper"} />
                                <span>{tree.name}</span>
                                <span className={"count"}>
                                    {tree.children && tree.children.length === 0 ? "" : `${tree.children.length}`}
                                </span>{" "}
                            </label>
                        </div>
                        {tree && !tree.isFold && true && tree.children.length > 0 ? ChildItem(tree.children) : ""}
                    </li>
                </ul>
            </React.Fragment>
        ));

        return listItems;
    }, [categoryModalTreeView, selectedCategories_]);

    const onClickFoldButton = (item) => {
        if (item.isGroup) return;

        item.isFold = !item.isFold;

        if (item.children && !item.children.length) {
            item.isEmpty = true;
        }

        dispatch(changeFold(item));
    };

    const onChangeCategoryChecked = useCallback(
        (category, checkValue) => {
            let categories = [];
            if (checkValue === true) categories = [...selectedCategories_, { ...category, label: category.name }];
            else categories = selectedCategories_.filter((v) => v.id != category.id);

            setSelectedCategories_(categories);
        },
        [selectedCategories_],
    );

    const onApplyCategoryGroup = (option) => {
        onClickResetCategoryTreeFilter();

        setSelectedCategories_([]);
        setCategoryGroup(option || { value: "", label: "" });
    };

    const onClearCategories = () => {
        setSelectedCategories_([]);
    };

    const onClickResetCategoryTreeFilter = () => {
        dispatch(
            updateFilter({
                isFilter: false,
                data: [],
            }),
        );

        setSearchKeyword("");
    };

    const onClickCategoryTreeFilter = useCallback(() => {
        const keyword = searchKeyword;

        if (!keyword) onClickResetCategoryTreeFilter();

        const filteredData = deepClone(categoryModalTreeView.data).filter(function f(o) {
            o.isFold = false;
            o.isEmpty = true;

            if (o.name.toUpperCase().includes(keyword.toUpperCase())) {
                o.isEmpty = false;
                o.isFold = true;
                return true;
            }
            return o.children && (o.children = o.children.filter(f)).length > 0;
        });

        dispatch(
            updateFilter({
                isFilter: true,
                data: filteredData || [],
            }),
        );
    }, [categoryModalTreeView, searchKeyword]);

    return (
        <Popper
            anchorEl={anchorEl}
            placement={placement}
            open={open}
            disablePortal={disablePortal}
            sx={{
                borderRadius: "4px",
                border: `solid 1px ${theme.palette.greySecondary[100]}`,
                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.16)",
                zIndex: 999999,
            }}
        >
            {" "}
            <Paper sx={{ width: "688px", height: "100%", overflowX: "hidden", overflowY: "hidden" }}>
                <Grid container flexDirection={"column"}>
                    <Grid item>
                        {defaultCategoryGroup?.id ? (
                            <Grid container flexDirection={"row"} p={2} gap={1} alignItems={"center"}>
                                <ImageCategoryGroupOn width={16} height={16} />
                                <Typography variant={"body1_500"}>
                                    {getCategoryGroupLabel(categoryGroup?.value)}
                                </Typography>{" "}
                            </Grid>
                        ) : (
                            <Grid container flexDirection={"row"} p={2} gap={1} alignItems={"center"}>
                                <ImageCategoryGroupOn width={16} height={16} />
                                <WithSelect
                                    options={categoryGroups.map((category) => {
                                        return {
                                            label: category.name,
                                            value: category.id,
                                        };
                                    })}
                                    disabled={categoryModalTreeView.pending}
                                    isSearchable={false}
                                    isClearable={true}
                                    placeholder={t(`common::label::${"Search Category Group"}`)}
                                    defaultValue={categoryGroup?.value || ""}
                                    onChange={(option) => {
                                        onApplyCategoryGroup(option);
                                    }}
                                />{" "}
                            </Grid>
                        )}
                    </Grid>{" "}
                    <Divider flexItem orientation={"horizontal"} sx={{ mx: 2 }} />
                    <Grid
                        item
                        flex={1}
                        className={`tree ${fp.isEmpty(categoryGroup.value) && "tree-guide"}`}
                        style={{ minHeight: "356px", flex: 1 }}
                    >
                        {" "}
                        <Stack>
                            {" "}
                            {categoryGroup.value && (
                                <Grid
                                    container
                                    width={1}
                                    alignItems={"center"}
                                    py={1.5}
                                    px={2}
                                    gap={2.5}
                                    borderBottom={1}
                                    borderColor={"divider"}
                                >
                                    {" "}
                                    <Grid item xs>
                                        <form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                onClickCategoryTreeFilter();
                                            }}
                                        >
                                            <TextField
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <IconSearch size={16} />
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                circled
                                                                onClick={(e) => {
                                                                    onClickResetCategoryTreeFilter();
                                                                    setSearchKeyword("");
                                                                }}
                                                                sx={{
                                                                    opacity: searchKeyword ? 1 : 0,
                                                                }}
                                                            >
                                                                {" "}
                                                                <IconCancelCircle
                                                                    size={16}
                                                                    color={theme.palette.greySecondary[400]}
                                                                />{" "}
                                                            </IconButton>{" "}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                disabled={fp.isEmpty(categoryGroup)}
                                                onChange={(e) => {
                                                    onChangeSearchKeyword(e.target.value);
                                                }}
                                                onBlur={(e) => {
                                                    onChangeSearchKeyword(e.target.value);
                                                }}
                                            />
                                        </form>
                                    </Grid>{" "}
                                    <IconButton
                                        circled
                                        onClick={(e) => {
                                            onRefresh();
                                        }}
                                    >
                                        {" "}
                                        <IconRefresh size={16} />
                                    </IconButton>{" "}
                                </Grid>
                            )}
                            {categoryModalTreeView.pending ? (
                                <div className="tree-view">
                                    <div className={"loading-block"}>
                                        <span className={"loading-content text-primary"} />
                                    </div>
                                </div>
                            ) : fp.isEmpty(categoryGroup.value) ? (
                                <Stack p={"30px"}>
                                    <i className="sprite">
                                        <ImageCategorySelect width={76} height={60} />
                                    </i>{" "}
                                    <p className={`text-center`}>카테고리 그룹의 카테고리를 선택하세요.</p>
                                    <hr className={`secondary`} />
                                    <div className={`d-flex`}>
                                        <IconInfoLinear size={16} htmlColor={`#83839a`} />
                                        {type === CATEGORY_TYPES.METADATA_TEMPLATE && (
                                            <div>
                                                <p>카테고리 그룹 설정</p>
                                                <p className={`text-secondary`}>
                                                    각 메뉴(예 : 자산)은 1개의 카테고리 그룹을 설정하여 아이템들을
                                                    분류할 수 있습니다.
                                                </p>
                                                <p>
                                                    <span className={`text-secondary`}>설정 위치</span>
                                                    <br />{" "}
                                                    <span className={`text-primary`}>
                                                        {`프로젝트 설정 > 설정 > 메뉴 (예 : 자산) > 카테고리 그룹`}
                                                    </span>
                                                </p>
                                            </div>
                                        )}
                                        {type !== CATEGORY_TYPES.METADATA_TEMPLATE && (
                                            <div>
                                                <p>
                                                    {" "}
                                                    사용중
                                                    <span className="badge-in-use">
                                                        {t(`common::label::${"In Use"}`)}
                                                    </span>{" "}
                                                    인 카테고리 그룹이 맞는지 확인하세요.
                                                </p>
                                                <p className={`text-secondary`}>
                                                    만약, 다른 카테고리 그룹 적용할 경우, 해당 아이템들은 카테고리
                                                    보기의 '
                                                    <strong className={`text-default`}>
                                                        자산에 분류되지 않은 카테고리
                                                    </strong>
                                                    '에서 확인할 수 있습니다.
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </Stack>
                            ) : treeData && treeData.length > 0 ? (
                                <div className="tree-view" style={{ maxHeight: 347 }}>
                                    <div className="tree-select">{renderTree()}</div>
                                </div>
                            ) : !categoryModalTreeView.pending && searchKeyword && !fp.isEmpty(categoryGroup.value) ? (
                                <div className={"empty"}>
                                    {t(`common::msg::${"No {{name}} searched."}`, {
                                        name: t(`common::label::${"Categories"}`),
                                    })}
                                </div>
                            ) : (
                                <div className={"empty"} />
                            )}
                        </Stack>{" "}
                    </Grid>{" "}
                    <Divider />
                    <Stack px={2} py={1.5} gap={1.5}>
                        <Grid container gap={4} alignItems={"center"} flexWrap={"nowrap"}>
                            <Grid item>
                                <Typography variant={"body3_700"} color={"primary.main"} sx={{ mr: 0.5 }}>
                                    {selectedCategories_ && selectedCategories_.length}
                                </Typography>{" "}
                                <Typography variant={"body3"}>
                                    {t(`common::label::${"Categories selected"}`)}
                                </Typography>{" "}
                            </Grid>{" "}
                            <Grid item xs>
                                <Grid container gap={0.5}>
                                    {selectedCategories_ &&
                                        selectedCategories_.map((category, index) => (
                                            <Chip
                                                size={"small"}
                                                key={category.id}
                                                label={category.label}
                                                onDelete={(e) => {
                                                    e.stopPropagation();
                                                    onChangeCategoryChecked(category, false);
                                                }}
                                                sx={{
                                                    maxWidth: 200,
                                                }}
                                            />
                                        ))}
                                </Grid>{" "}
                            </Grid>{" "}
                            <Grid item>
                                <Button
                                    size={"xsmall"}
                                    variant={"text"}
                                    disabled={selectedCategories_.length === 0}
                                    startIcon={<IconResetSmall size={16} />}
                                    onClick={onClearCategories}
                                >
                                    {t(`common::label::${"Clear All"}`)}
                                </Button>{" "}
                            </Grid>{" "}
                        </Grid>{" "}
                        <Divider />{" "}
                        <Grid container mt={0.5} justifyContent={"flex-end"}>
                            <Button
                                variant={"contained"}
                                size={"small"}
                                color={"greySecondary"}
                                loading={isSubmitting}
                                disabled={selectedCategories_ && selectedCategories_.length <= 0}
                                onClick={async (e) => {
                                    e.preventDefault();
                                    setIsSubmitting(true);
                                    await onSelectedCategories();
                                }}
                            >
                                {" "}
                                {submitText ? t(`common::label::${submitText}`) : t(`common::label::${"Select"}`)}
                            </Button>{" "}
                        </Grid>{" "}
                    </Stack>{" "}
                </Grid>{" "}
            </Paper>{" "}
        </Popper>
    );
};

export default SelectCategoryGroupPopper;
