import React from "react";
import Loadable from "@react-loadable/revised";
import Loader, { EntryLoader } from "../components/loader";
import { Redirect } from "react-router";
import ContentsRedirect from "../routes/cms/contents/ContentsRedirect";
import menuService from "@services/menuService";

const LoadableStage = Loadable({
    loader: () => import("../routes/stage/Stage"),
    loading: Loader,
});

const LoadableVideoList = Loadable({
    loader: () => import("../routes/cms/video"),
    loading: Loader,
});

const LoadableVideoDetail = Loadable({
    loader: () => import("../routes/cms/video/Detail"),
    loading: Loader,
});

const LoadableAssets = Loadable({
    loader: () => import("../routes/cms/asset"),
    loading: Loader,
});

const LoadableAssetDetail = Loadable({
    loader: () => import("../routes/cms/asset/Detail"),
    loading: Loader,
});

const LoadableAssetCreate = Loadable({
    loader: () => import("../routes/cms/asset/Create"),
    loading: Loader,
});

const LoadableImagesViewer = Loadable({
    loader: () => import("../routes/images-viewer"),
    loading: Loader,
});

const LoadableSources = Loadable({
    loader: () => import("../routes/cms/sources/List"),
    loading: Loader,
});

const LoadablePeople = Loadable({
    loader: () => import("../routes/cms/people"),
    loading: Loader,
});

const LoadablePeopleDetail = Loadable({
    loader: () => import("../routes/cms/people/Detail").catch(error=>console.log(error)),
    loading: Loader,
});

const LoadablePeopleCreate = Loadable({
    loader: () => import("../routes/cms/people/Create"),
    loading: Loader,
});

const LoadableCaptionPreview = Loadable({
    loader: () => import("../routes/preview/CaptionPreview"),
    loading: Loader,
});

const LoadableImagePreview = Loadable({
    loader: () => import("../routes/preview/ImagePreview"),
    loading: Loader,
});

const LoadableVideoPreview = Loadable({
    loader: () => import("../routes/preview/VideoPreview"),
    loading: Loader,
});

const LoadableMusic = Loadable({
    loader: () => import("../routes/cms/music/List"),
    loading: Loader,
});

const LoadableMusicDetail = Loadable({
    loader: () => import("../routes/cms/music/Detail"),
    loading: Loader,
});

const LoadablePhoto = Loadable({
    loader: () => import("../routes/cms/photo/List"),
    loading: Loader,
});

const LoadablePhotoDetail = Loadable({
    loader: () => import("../routes/cms/photo/Detail"),
    loading: Loader,
});

const LoadableDashboard = Loadable({
    loader: () => import("../routes/dashboard"),
    loading: Loader,
});

const LoadableEntryConsole = Loadable({
    loader: () => import("../routes/entryConsole/entryConsole"),
    loading: EntryLoader,
});

export const defaultPath = "/spaces/:spaceId/projects/:projectId";
export const adminSpacePath = "/admin/spaces/:spaceId";
export const adminProjectPath = "/admin/spaces/:spaceId/projects/:projectId";

// TODO: 다국어 확인. name
const routes = [
    {
        name: "Spaces",
        exact: true,
        path: "/spaces",
        component: LoadableStage,
    },
    {
        name: "CMS Root Redirect",
        exact: true,
        path: "/",
        component: LoadableEntryConsole,
    },
    {
        name: "Contents Redirect",
        exact: true,
        path: `${defaultPath}/contents`,
        component: ContentsRedirect,
    },
    {
        name: "Storage",
        exact: true,
        path: `${defaultPath}/storage`,
        component: LoadableSources,
        redirectPath: `${defaultPath}/storage`,
    },
    {
        name: "Videos",
        exact: true,
        path: `${defaultPath}/contents/videos`,
        component: LoadableVideoList,
        redirectPath: `${defaultPath}/contents/videos`,
    },
    {
        name: "Video Detail",
        exact: true,
        path: `${defaultPath}/contents/videos/:id`,
        component: LoadableVideoDetail,
        redirectPath: `${defaultPath}/contents/videos`,
    },
    {
        name: "Assets",
        exact: true,
        path: `${defaultPath}/assets/assets`,
        component: LoadableAssets,
        redirectPath: `${defaultPath}/assets/assets`,
    },
    {
        name: "Asset Create",
        exact: true,
        path: `${defaultPath}/assets/assets/create`,
        component: LoadableAssetCreate,
        redirectPath: `${defaultPath}/assets/assets/create`,
    },
    {
        name: "Asset Detail",
        exact: true,
        path: `${defaultPath}/assets/assets/:id`,
        component: LoadableAssetDetail,
        redirectPath: `${defaultPath}/assets/assets/:id`,
    },
    {
        name: "Images Viewer",
        exact: true,
        path: `${defaultPath}/images-viewer`,
        component: LoadableImagesViewer,
        redirectPath: `${defaultPath}/images-viewer`,
    },
    {
        name: "Caption Asset Preview",
        exact: true,
        isPreview: true,
        path: `${defaultPath}/assets/assets/:groupId/captions`,
        redirectPath: `${defaultPath}/assets/assets/:groupId/captions`,
        component: LoadableCaptionPreview,
    },
    {
        name: "Image Asset Preview",
        exact: true,
        path: `${defaultPath}/assets/assets/:groupId/images`,
        redirectPath: `${defaultPath}/assets/assets/:groupId/images`,
        component: LoadableImagePreview,
    },
    {
        name: "Image Asset Preview",
        exact: true,
        path: `${defaultPath}/assets/assets/:groupId/videos`,
        redirectPath: `${defaultPath}/assets/assets/:groupId/videos`,
        component: LoadableVideoPreview,
    },
    {
        name: "People",
        exact: true,
        path: `${defaultPath}/contents/people`,
        component: LoadablePeople,
        redirectPath: `${defaultPath}/contents/people`,
    },
    {
        name: "People Create",
        exact: true,
        path: `${defaultPath}/contents/people/create`,
        component: LoadablePeopleCreate,
        redirectPath: `${defaultPath}/contents/people/create`,
    },
    {
        name: "People Detail",
        exact: true,
        path: `${defaultPath}/contents/people/:id`,
        component: LoadablePeopleDetail,
        redirectPath: `${defaultPath}/contents/people/:id`,
    },
    {
        name: "Music",
        exact: true,
        path: `${defaultPath}/contents/music`,
        component: LoadableMusic,
        redirectPath: `${defaultPath}/contents/music`,
    },
    {
        name: "Music Detail",
        exact: true,
        path: `${defaultPath}/contents/music/:id`,
        component: LoadableMusicDetail,
        redirectPath: `${defaultPath}/contents/music/:id`,
    },
    {
        name: "Photo",
        exact: true,
        path: `${defaultPath}/contents/photos`,
        component: LoadablePhoto,
    },
    {
        name: "Photo Detail",
        exact: true,
        path: `${defaultPath}/contents/photos/:id`,
        component: LoadablePhotoDetail,
    },
    {
        name: "Dashboard",
        exact: true,
        path: `${defaultPath}/dashboard`,
        component: LoadableDashboard,
        redirectPath: `${defaultPath}/dashboard`,
    },
];

export default routes;
