import packageJson from "../package.json";
import React from "react";
global.appVersion = packageJson.version;

const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);
    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift());
        const b = Number(versionsB.shift());
        if (a === b) continue;
        return a > b || isNaN(b);
    }
    return false;
};

export default class CacheBuster extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isLatestVersion: false,
            refreshCacheAndReload: () => {
                console.log("Invalidating cache and reloading...");
                if (caches) {
                    caches.keys().then(function (names) {
                        for (let name of names) caches.delete(name);
                    });
                }
                window.location.reload();
            },
        };
    }

    _componentUmounted = false;

    componentWillUnmount = () => {
        this._componentUmounted = true;
    };

    componentDidMount() {
        fetch("/meta.json", { cache: "no-cache" })
            .then((response) => response.json())
            .then((meta) => {
                if (this._componentUmounted) {
                    return;
                }
                const latestVersion = meta.version;
                const currentVersion = global.appVersion;
                const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
                if (shouldForceRefresh) {
                    console.log(`Cache invalidation is required. Force refreshing!`);
                    this.setState({ loading: false, isLatestVersion: false });
                } else {
                    console.log(`Is current version`);
                    this.setState({ loading: false, isLatestVersion: true });
                }
            })
            .catch((err) => {
                console.log(`failed to fetch meta.json`);
                if (this._componentUmounted) {
                    return;
                }
                this.setState({ loading: false, isLatestVersion: true });
            });
    }

    render() {
        const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
        return this.props.children({ loading, isLatestVersion, refreshCacheAndReload });
    }
}
