const convertToByte = (byte) => {
    const s = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    const e = Math.floor(Math.log(byte) / Math.log(1024));
    
    const result = (byte / Math.pow(1024, e)).toFixed(2);
    if(isNaN(result)) {
        return 0 + s[1];
    }
    
    return result + " " + s[e];
};

export default convertToByte;