import React, { useEffect, useRef, useState } from "react";
import { PUBLIC_URL, PROPERTY_PANEL_TYPE } from "@constants";
import Loading from "@components/loader/Loading";
import FormatBadge from "../../formatbadge/FormatBadge";
import { useTranslation } from "react-i18next";

const RelatedVideosTab = ({ assets, relatedVideos, getRepresentativeImageUrlFromVideo, parentPath }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {assets.pending ? (
                <Loading></Loading>
            ) : (
                <div className="list-items">
                    {relatedVideos && relatedVideos.length ? (
                        <ul>
                            {relatedVideos.map((a, k) => (
                                <li key={k}>
                                    <div className={`thumbnail type-video`}>
                                        <span>
                                            <img
                                                className={`list-thumbnail type-video`}
                                                style={{
                                                    width: "50px",
                                                    height: "50px",
                                                }}
                                                src={getRepresentativeImageUrlFromVideo(a)}
                                                onError={(e) => {
                                                    e.target.src = `${PUBLIC_URL}/images/thumbnail_no_image_s.png`;
                                                }}
                                            />
                                            <i></i>
                                        </span>
                                    </div>
                                    <div className="content">
                                        <i className={`sprite sprite-video`}></i>
                                        <div>
                                            <p className={"ellipsis-flex ellipsis-flex-inline"}>
                                                <strong className="ellipsis">{a.name}</strong>
                                            </p>
                                            <a
                                                href={`${parentPath}/videos/${a.id}`}
                                                className="external-link"
                                                target="_blank"
                                            >
                                                (<span>{a.id}</span>)
                                            </a>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div className="empty">
                            <i className="sprite sprite-nojob"></i>
                            <p>
                                {t(`common::label::${"Empty {{name}}"}`, {
                                    name: t(`common::label::Related {{pascalK}}`, {
                                        pascalK: t(`common::label::Videos`),
                                    }),
                                })}
                            </p>
                        </div>
                    )}
                </div>
            )}
        </React.Fragment>
    );
};

export default RelatedVideosTab;
