import React from "react";

const LoadingBlock = () => {
    return (
        <div className={"loading-block"}>
            <span className={"loading-content"}></span>
        </div>
    );
};

export default LoadingBlock;
