import React, { FC } from "react";
import { StyledAssetTypeThumbnail, ImagePreviewButton } from "./asset-type.styled";
import { Box, Grid, Typography } from "@mui/material";

/* types */
import {
    IconAdd,
    ImageDefaultAudioLight,
    ImageDefaultAudioSmallLight,
    ImageDefaultFileLight,
    ImageDefaultFileSmallLight,
    ImageDefaultImageLight,
    ImageDefaultImageSmallLight,
    ImageDefaultTextLight,
    ImageDefaultTextSmallLight,
    ImageDefaultVideoLight,
    ImageDefaultVideoSmallLight,
} from "@mzc-cloudplex/icons";
import theme from "@styles/theme";

const AssetTypeThumbnail = ({
                                variant,
                                width,
                                height,
                                type,
                                src,
                                status,
                                margin,
                                preview,
                                disableHover,
                                ...restProps
                            }) => (
    <StyledAssetTypeThumbnail
        className={"asset-thumbnail"}
        style={{
            width,
            height,
            margin,
        }}
        variant={variant}
        status={status}
        {...restProps}
    >
        {src ? (
            <>
                <img src={src} alt="" />
                {!disableHover && (
                    <ImagePreviewButton
                        className={"btn-preview"}
                        style={{
                            width: variant === "card" ? "100px" : "100%",
                            height: variant === "card" ? "100px" : "100%",
                        }}
                    >
                        <Box>
                            <IconAdd size={10} htmlColor={theme.palette.common.white} />
                        </Box>
                        {variant === "card" && (
                            <Typography color={theme.palette.common.white}>Image zoom in</Typography>
                        )}
                    </ImagePreviewButton>
                )}
            </>
        ) : (
            <>
                {variant === "table" ? (
                    <>
                        {type === "video" && <ImageDefaultVideoSmallLight width={28} height={28} />}
                        {type === "audio" && <ImageDefaultAudioSmallLight width={28} height={28} />}
                        {type === "file" && <ImageDefaultFileSmallLight width={28} height={28} />}
                        {type === "image" && <ImageDefaultImageSmallLight width={28} height={28} />}
                        {type === "text" && <ImageDefaultTextSmallLight width={28} height={28} />}
                    </>
                ) : (
                    <>
                        {type === "video" && <ImageDefaultVideoLight width={47} height={60} />}
                        {type === "audio" && <ImageDefaultAudioLight width={47} height={60} />}
                        {type === "file" && <ImageDefaultFileLight width={47} height={60} />}
                        {type === "image" && <ImageDefaultImageLight width={47} height={60} />}
                        {type === "text" && <ImageDefaultTextLight width={47} height={60} />}
                    </>
                )}
            </>
        )}
    </StyledAssetTypeThumbnail>
);

export default AssetTypeThumbnail;
