export const checkIsMobile = () => {
    const UserAgent = navigator.userAgent;
    if (
        UserAgent.match(
            /iPhone|iPod|iPad|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i,
        ) != null ||
        UserAgent.match(/LG|SAMSUNG|Samsung/) != null
    ) {
        return true;
    } else {
        return false;
    }
};
