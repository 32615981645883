import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Loadable from "@react-loadable/revised";
import WithSelect from "@components/select";
import {
    UPLOAD_FILE_LOCATION_TYPE,
    CONSTANTS,
    UPLDOAD_DUPLICATE_ACTION,
    OPERATING_TYPE,
    UPLOAD_DUPLICATE_MODAL_ACTION,
    UPLOAD_STATUS,
    JOB_ACTIONS,
} from "@constants";
import getFilesInS3 from "@cores/getFilesInS3";
import DuplicateCheckModal from "@components/modals/DuplicateCheckModal";
import equal from "deep-equal";
import {
    cancelQueue,
    changeFilesOffset,
    retryUploadQueueJob,
    toggleQueueExpander,
    updateQueue,
    uploadFilesFinished,
    uploadJobFiles,
    uploadJobFileSetStatus,
} from "@modules/uploadQueue";
import { setNotification } from "@modules/notification";
import { sourceViewReload } from "@modules/sources";
import { Divider, Grid, Stack } from "@mzc-pdc/ui";
import { SelectLimitTags } from "@components_v2/select";
import JobItem from "@routes/rightsidebar/ui/job-item";
import JobEmptyList from "@routes/rightsidebar/ui/job-empty-list";

const duplicateModalRef = React.createRef();
const locationType = UPLOAD_FILE_LOCATION_TYPE.LOCAL || "Local";

const LoadableDeleteConfirmModal = Loadable({
    loader: () => import("@routes/sources/components/DeleteConfirm"),
    loading: () => null,
});

const UploadFilesPanel = ({ filter, jobIcon, summaries, taskComponent }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [confirmModalIsOpened, setConfirmModalIsOpened] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState({});
    const [localFilesOffset, setLocalFilesOffset] = useState(0);
    const [deleteActive, setDeleteActive] = useState(false);
    const [cancelKey, setCancelKey] = useState(null);

    const uploadQueue = useSelector((state) => state.uploadQueue);
    const sources = useSelector((state) => state.sources);
    const stage = useSelector((state) => state.stage);
    const project = useSelector((state) => state.project);

    const toastJustMessage = useCallback((type, message) => {
        dispatch(
            setNotification({
                type,
                contents: `${message}`,
            }),
        );
    }, []);

    const refreshSourceView = useCallback(async (time) => {
        time = time || 500;
        await dispatch(sourceViewReload(true));
        setTimeout(() => {
            dispatch(sourceViewReload(false));
        }, time);
    }, []);

    const onClickCreateNewFolder = useCallback(
        (folderFullPath, folderName, jobKey) => {
            const targets = uploadQueue[locationType].filter((item) => item.key === jobKey)[0];
            const bucketPath = targets.bucketPath;
            const bucketName = bucketPath.bucket;
            const folderPath = bucketPath.folderPath ? `/${bucketPath.folderPath}` : "";

            let makePath = `${folderPath}/${
                folderFullPath === "/"
                    ? folderName
                    : folderFullPath[0] === "/"
                    ? `${folderFullPath.slice(1)}`
                    : `${folderFullPath}`
            }`;
            makePath = makePath.slice(-folderName.length) !== folderName ? makePath + folderName : makePath;

            const items = [
                {
                    path: makePath,
                    isFolder: true,
                },
            ];
            return new Promise(async (resolve, reject) => {
                try {
                    const response = await dispatch(createFolder(bucketName, items));
                    resolve("COMPLETE");
                } catch (error) {
                    resolve("ERROR");
                }
            });
        },
        [uploadQueue],
    );

    const checkExisitFileToS3 = useCallback(
        async (checkUrl) => {
            try {
                await getFilesInS3(stage.id, stage.endpoint, checkUrl, project.id);
                return "EXIST";
            } catch (e) {
                if (e?.response?.status === 404) {
                    return false;
                } else {
                    return "ERROR";
                }
            }
        },
        [stage, project],
    );

    const cancelUpload = useCallback(async (key) => {
        const result = await dispatch(cancelQueue(locationType, key));
        return result;
    }, []);

    const checkAndUploadFiles = useCallback(
        async (locationType, jobKey, uploadDuplicateAction) => {
            const targets = uploadQueue[locationType].filter((item) => item.key === jobKey)[0];
            const selectedFiles = targets.files;
            const bucketPath = targets.bucketPath;

            const filePut = async (selectedFile) => {
                if (selectedFile.isFolder) {
                    try {
                        await onClickCreateNewFolder(selectedFile.fullPath, selectedFile.name, jobKey);
                        return await fileAlreadyUploaded(selectedFile);
                    } catch (error) {
                        return await fileError(selectedFile);
                    }
                } else {
                    return await dispatch(
                        uploadJobFiles({
                            locationType,
                            id: selectedFile.id,
                            key: jobKey,
                            uploadCompleteCallback: () => {},
                        }),
                    );
                }
            };

            const fileCancel = async (selectedFile) => {
                return await dispatch(
                    uploadJobFileSetStatus({
                        locationType,
                        id: selectedFile.id,
                        key: jobKey,
                        status: "CANCELED",
                        uploadCompleteCallback: () => {},
                    }),
                );
            };

            const fileSkip = async (selectedFile) => {
                return await dispatch(
                    uploadJobFileSetStatus({
                        locationType,
                        id: selectedFile.id,
                        key: jobKey,
                        status: "CANCELED",
                        uploadCompleteCallback: () => {},
                    }),
                );
            };

            const fileError = async (selectedFile) => {
                return await dispatch(
                    uploadJobFileSetStatus({
                        locationType,
                        id: selectedFile.id,
                        key: jobKey,
                        status: "ERROR",
                        uploadCompleteCallback: () => {},
                    }),
                );
            };

            const fileAlreadyUploaded = async (selectedFile) => {
                return await dispatch(
                    uploadJobFileSetStatus({
                        locationType,
                        id: selectedFile.id,
                        key: jobKey,
                        status: "COMPLETE",
                        uploadCompleteCallback: () => {},
                    }),
                );
            };

            return new Promise(async (resolve, reject) => {
                let isAllSkip = uploadDuplicateAction === UPLDOAD_DUPLICATE_ACTION.ALWAYS_SKIP;
                let isAllOverwrite = uploadDuplicateAction === UPLDOAD_DUPLICATE_ACTION.ALWAYS_OVERWRITE;
                const s3UrlBase = `https://${bucketPath.bucket}.s3.amazonaws.com${
                    bucketPath.folderPath !== "" ? "/" + bucketPath.folderPath : ""
                }`;
                let status = "SUCCEED";
                let errorOccured = false;

                for await (const selectedFile of selectedFiles) {
                    if (selectedFile.status === "COMPLETE") {
                        fileAlreadyUploaded(selectedFile);
                        continue;
                    }

                    if (isAllOverwrite) {
                        const filePutResponse = await filePut(selectedFile);
                        status = filePutResponse.status;
                        if (status === "CANCELED") {
                            resolve("CANCELED");
                            return;
                        } else if (status === "ERROR") {
                            errorOccured = true;
                        }
                        continue;
                    }

                    let checkUrl;
                    let filePath = `${selectedFile.fullPath}${selectedFile.name}`;

                    checkUrl = `${s3UrlBase}${filePath}`;

                    const isExistFileToS3 = await checkExisitFileToS3(checkUrl);

                    if (isAllSkip && !isExistFileToS3) {
                        const filePutResponse = await filePut(selectedFile);
                        status = filePutResponse.status;
                        if (status === "CANCELED") {
                            resolve("CANCELED");
                            return;
                        } else if (status === "ERROR") {
                            errorOccured = true;
                        }
                        continue;
                    } else if (isAllSkip && isExistFileToS3) {
                        await fileSkip(selectedFile);
                        continue;
                    }

                    if (isExistFileToS3 === "EXIST") {
                        const duplicateModal = duplicateModalRef.current;

                        if (confirmModalIsOpened) {
                            await pollingForConfirmModalCheck();
                        }

                        setConfirmModalIsOpened(true);
                        const result = await duplicateModal.show({
                            operatingType: OPERATING_TYPE.UPLOAD,
                            totalCount: selectedFiles.length,
                            distFolderName:
                                bucketPath.folderPath === ""
                                    ? bucketPath.bucket
                                    : `${bucketPath.bucket}/${bucketPath.folderPath}`,
                            displayName: selectedFile.name,
                        });
                        setConfirmModalIsOpened(false);

                        switch (result.action) {
                            case UPLOAD_DUPLICATE_MODAL_ACTION.OVERWRITE:
                                const filePutResponse = await filePut(selectedFile);
                                status = filePutResponse.status;
                                if (status === "CANCELED") {
                                    resolve("CANCELED");
                                    return;
                                } else if (status === "ERROR") {
                                    errorOccured = true;
                                    if (selectedFiles.length === 1) {
                                        resolve("ERROR");
                                    }
                                }
                                isAllOverwrite = result.isApplyToAll;
                                break;
                            case UPLOAD_DUPLICATE_MODAL_ACTION.SKIP:
                                isAllSkip = result.isApplyToAll;
                                await fileSkip(selectedFile);
                                break;
                            case UPLOAD_DUPLICATE_MODAL_ACTION.STOP:
                                await fileCancel(selectedFile);
                                resolve("CANCELED");
                                return;
                        }
                    } else if (isExistFileToS3 === "ERROR") {
                        await fileError(selectedFile);
                        resolve("ERROR");
                        return;
                    } else {
                        const filePutResponse = await filePut(selectedFile);
                        status = filePutResponse.status;
                        if (status === "CANCELED") {
                            resolve("CANCELED");
                            return;
                        } else if (status === "ERROR") {
                            errorOccured = true;
                            if (selectedFiles.length === 1) {
                                resolve("ERROR");
                            }
                        }
                    }
                }
                resolve("COMPLETE");
            });
        },
        [uploadQueue, onClickCreateNewFolder, confirmModalIsOpened, duplicateModalRef],
    );

    const retryUpload = useCallback(
        async (key, uploadDuplicateAction = UPLDOAD_DUPLICATE_ACTION.ASK) => {
            const hierarchy = sources.hierarchy;
            const hierarchyActive = "folder";
            const { retryKey } = await dispatch(retryUploadQueueJob({ locationType, key }));
            const results = await checkAndUploadFiles(locationType, retryKey, uploadDuplicateAction);

            if (results === "SUCCEED" || results === "COMPLETE") {
                const checkTarget =
                    uploadQueue[locationType]
                        .find((item) => item.key === retryKey)
                        .files.filter((item) => item.status === "ERROR") || [];
                const partialComplete = checkTarget.length > 0;
                dispatch(uploadFilesFinished({ locationType, key: retryKey, status: UPLOAD_STATUS.COMPLETE }));
                toastJustMessage("success", `${partialComplete ? `Partial ` : ``}Upload completed.`);
            } else if (results === "CANCELED") {
                dispatch(uploadFilesFinished({ locationType, key: retryKey, status: UPLOAD_STATUS.CANCELED }));
                toastJustMessage("success", `Upload canceled.`);
            } else if (results === "ERROR") {
                dispatch(uploadFilesFinished({ locationType, key: retryKey, status: UPLOAD_STATUS.ERROR }));
                toastJustMessage("error", `Upload error.`);
            }

            setTimeout(() => {
                const stillHere = equal(
                    JSON.stringify(hierarchy[hierarchyActive].selected),
                    JSON.stringify(sources.hierarchy["folder"].selected),
                );
                if (stillHere) {
                    refreshSourceView();
                }
            }, 1000);
        },
        [uploadQueue, sources, checkAndUploadFiles, refreshSourceView, toastJustMessage],
    );

    const startUpload = useCallback(
        (key) => {
            retryUpload(key);
        },
        [retryUpload],
    );

    const checkAndUploadStart = useCallback(() => {
        if (uploadQueue[locationType].length > 0) {
            uploadQueue[locationType].map(async (item) => {
                if (item.status === UPLOAD_STATUS.UPLOADING && !item.isStarted) {
                    startUpload(item.key);
                    await dispatch(
                        updateQueue(locationType, item.key, {
                            isStarted: true,
                        }),
                    );
                }
                return item;
            });
        }
    }, [uploadQueue, startUpload]);

    const pollingForConfirmModalCheck = useCallback(async () => {
        const doSomething = () => {
            return new Promise((resolve) =>
                setTimeout(async () => {
                    try {
                        if (confirmModalIsOpened === true) {
                            resolve("OPENED");
                        } else {
                            resolve("CLOSED");
                        }
                    } catch (error) {
                        resolve("ERROR");
                    }
                }, 1000),
            );
        };

        const loop = () => {
            return doSomething().then((results) => {
                if (results === "CLOSED" || results === "ERROR") {
                    return results;
                } else {
                    return loop();
                }
            });
        };

        return new Promise((resolve, reject) => {
            loop()
                .then((results) => {
                    resolve(results);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }, [confirmModalIsOpened]);

    const openCancelModal = (key) => {
        setDeleteActive(true);
        setCancelKey(key);
    };

    const onConfirmCancelModal = () => {
        cancelUpload(cancelKey);
        setCancelKey(null);
    };

    const onCloseCancelModal = () => {
        setDeleteActive(false);
        setCancelKey(null);
    };

    const toggleJobIsExpanded = useCallback((key) => {
        dispatch(toggleQueueExpander(locationType, key));
    }, []);

    const onChangeLimit = (offset) => {
        setLocalFilesOffset(offset);
        dispatch(changeFilesOffset(offset));
    };

    const onHandleClickViewMore = useCallback(() => {
        const offset = uploadQueue.local_files_offset + localFilesOffset;
        dispatch(changeFilesOffset(offset));
    }, [uploadQueue, localFilesOffset]);

    const onHandleClickFilter = useCallback(
        (id, value) => {
            setSelectedFilter({
                ...selectedFilter,
                [id]: value,
            });
        },
        [selectedFilter],
    );

    const uploadQueueList = useMemo(
        () => uploadQueue[locationType].slice(0, localFilesOffset),
        [uploadQueue, localFilesOffset],
    );
    const viewmoreEnable = useMemo(
        () => uploadQueue[locationType].length > localFilesOffset,
        [uploadQueue, localFilesOffset],
    );

    const filteredJobsData = useMemo(() => {
        const filteredJobsData = uploadQueueList.filter((job) =>
            Object.keys(selectedFilter)
                .filter((key) => selectedFilter[key]?.length)
                .every((key) => selectedFilter[key].includes(job[key])),
        );

        const addValueToSummaries = (job) => {
            return summaries.map(({ key, ...rest }) => {
                return {
                    ...rest,
                    value: job[key],
                };
            });
        };

        const getTaskDetailComponent = (job) => {
            const TaskDetailComponent = taskComponent?.[job.action];
            if (TaskDetailComponent) {
                return (
                    <TaskDetailComponent
                        job={{ isFolder: job.isFolder, status: job.status, bucketPath: job.bucketPath }}
                        tasks={job.files}
                    />
                );
            }
            return null;
        };

        return filteredJobsData.map((job) => {
            job.action = job.action ?? JOB_ACTIONS.UPLOAD;

            return {
                job: {
                    id: job.jobId,
                    action: job.action,
                    icon: jobIcon[job.action],
                    status: job.status,
                    summaryInfo: addValueToSummaries(job),
                    needCancelModal: true,
                },
                task: {
                    taskCount: job.files?.length ?? 0,
                    isTaskDetailFolded: job.isFolded === undefined || job.isFolded,
                    taskDetailComponent: getTaskDetailComponent(job),
                },
                action: {
                    toggle: () => toggleJobIsExpanded(job.key), //이걸 누르면 job이 바뀌면서 filteredJobsData가 바뀌고 rerender가 될텐데 괜찮나?
                    retry: () => retryUpload(job.key),
                    cancel: () => openCancelModal(job.key),
                },
            };
        });
    }, [uploadQueueList, selectedFilter, filter]);

    useEffect(() => {
        for (let item of uploadQueueList) {
            // 업로드 행위에 대한 폴더와 파일갯수를 표현하기 위한 로직
            if (item.isFolder) {
                let folders = new Map();
                let totalFileSize = 0;
                let otherFilesLength = 0;
                item.files.forEach((file) => {
                    let reg = new RegExp(`^\/[^\/\"\x80-\xff\^\}\%\`\'\"\>\[\~\<\#\|]+\/`);
                    let findRootFolder =
                        file.fullPath.match(reg) || (file.fullPath === "/" && file.isFolder && [file.name]) || [];
                    let foundFolder = folders.get(findRootFolder[0]);
                    // console.log('findRootFolder', findRootFolder[0])
                    if (findRootFolder.length > 0 && !foundFolder) {
                        folders.set(findRootFolder[0].split("/")[1], 1);
                    } else {
                        if (!foundFolder) {
                            otherFilesLength += 1;
                        }
                        // console.log('other hand')
                    }
                    totalFileSize += file.size;
                });
                folders = Array.from(folders.keys());
                item.mainFile = folders[0];
                item.fileLength = folders.length + otherFilesLength;
                item.totalSize = totalFileSize;
            } else {
                item.mainFile = item.files[0].name;
                item.fileLength = item.files.length;
                item.totalSize = item.files[0].size;
            }
        }

        checkAndUploadStart();
    }, [uploadQueue]);

    useEffect(() => {
        const defaultOffset = uploadQueue.local_files_offset !== 50 ? 50 : uploadQueue.local_files_offset;
        setLocalFilesOffset(defaultOffset);
    }, [uploadQueue.local_files_offset]);

    return (
        <React.Fragment>
            {deleteActive && (
                <LoadableDeleteConfirmModal
                    title={`Upload Cancel`}
                    messages={[`Upload did not complete.`, `Are you sure you want to cancel all uploads in progress?`]}
                    confirmButtonText={`Upload Cancel`}
                    cancelButtonText={`Upload Continue`}
                    onClickClose={onCloseCancelModal}
                    onClickDelete={onConfirmCancelModal}
                />
            )}
            <DuplicateCheckModal ref={duplicateModalRef} />
            <Grid container pt={3} px={3} pb={2} gap={1} justifyContent={"center"} alignItems={"center"}>
                {filter &&
                    Object.entries(filter)?.map(([key, options]) => (
                        <Grid item xs>
                            <SelectLimitTags
                                displayEmpty
                                notched
                                label={key}
                                labelId={`select-label-asset-jobs-${key}`}
                                multiple={true}
                                value={selectedFilter[key] ?? []}
                                options={options}
                                MenuProps={{
                                    disablePortal: true,
                                }}
                                onChange={(e) => {
                                    onHandleClickFilter(key, e.target.value);
                                }}
                                limitTags={1}
                            />
                        </Grid>
                    ))}
            </Grid>
            <Divider sx={{ mx: 3 }} />
            <Stack flex={1} px={3} py={2.5} gap={4} sx={{ overflowY: "auto" }}>
                {filteredJobsData.length > 0 ? (
                    <>
                        {filteredJobsData.map((data) => {
                            return <JobItem {...data} key={`upload-job-${data.job.id}`} />;
                        })}
                    </>
                ) : (
                    <JobEmptyList />
                )}
            </Stack>
            {uploadQueueList.length > 0 && (
                <div className="panel-footer">
                    <WithSelect
                        defaultValue={localFilesOffset}
                        options={CONSTANTS("LIST_LIMITS")}
                        onChange={(option) => onChangeLimit(option.value)}
                    />
                    {viewmoreEnable ? (
                        <button
                            type={"button"}
                            className={"btn btn-outline-default"}
                            onClick={(e) => {
                                e.preventDefault();
                                onHandleClickViewMore();
                            }}
                        >
                            <span>{`View More`}</span>
                            <i className="sprite sprite-angle-down"></i>
                        </button>
                    ) : (
                        ""
                    )}
                </div>
            )}
        </React.Fragment>
    );
};

export default UploadFilesPanel;
