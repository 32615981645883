import React from "react";

import equal from "deep-equal";

class InputField extends React.Component {
    constructor(props) {
        super(props);
    }
    shouldComponentUpdate = (nextProps) => {
        const filteredProps = Object.fromEntries(
            Object.entries(this.props).filter(([key]) => key !== "prependElement"),
        );
        const filteredNextProps = Object.fromEntries(
            Object.entries(nextProps).filter(([key]) => key !== "prependElement"),
        );
        if (!equal(filteredProps, filteredNextProps)) {
            return true;
        }

        return false;
    };

    getValidationClass = () => {
        return this.props.validationError && this.props.validationError.level;
    };

    render = () => {
        return (
            <React.Fragment>
                <div
                    className={`form-input ${this.props.prependElement ? "d-flex" : ""} ${
                        this.props.isEdit && "is-edit"
                    } ${
                        this.props.wrapperClass
                            ? `${this.props.wrapperClass} form-${this.getValidationClass()}`
                            : `form-${this.getValidationClass()}`
                    }`}
                >
                    {this.props.prependElement ? (
                        <div className={"form-input-prepend"}>{this.props.prependElement}</div>
                    ) : (
                        ""
                    )}
                    <input
                        id={this.props.id}
                        name={this.props.name}
                        autoFocus={this.props.autoFocus}
                        type={this.props.type || "text"}
                        className={"form-control"}
                        value={this.props.value}
                        placeholder={this.props.placeholder}
                        onKeyPress={this.props.onKeyPress}
                        disabled={this.props.disabled ? "disabled" : ""}
                        ref={this.props.useRef}
                        onChange={(e) => {
                            this.props.onChange(e);
                        }}
                        onBlur={(e) => {
                            this.props.onChange(e);
                        }}
                        readOnly={this.props.readOnly}
                    />
                    {this.props.validationError && this.props.validationError.level && (
                        <div className={`form-message ${this.getValidationClass()}`}>
                            <p>{this.props.validationError.message}</p>
                        </div>
                    )}
                </div>
            </React.Fragment>
        );
    };
}

export default InputField;
