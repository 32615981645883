import { handleActions, createAction } from "redux-actions";
import fp from "lodash/fp";
import { axios } from "../cores/axiosWrapper";

const postDashboardQueryListAPI = (stageId, apiEndpoint, projectId, payload) => {
    return axios.post(`${apiEndpoint}/dashboards/query/list`, payload, {
        headers: {
            stageId,
            projectId,
        },
    });
};

const getDashboardWidgetListAPI = ({ apiEndpoint, projectId }) =>
    axios.get(`${apiEndpoint}/v3/dashboards/widgets`, { headers: { projectId } });

const getDetailDashboardWidgetAPI = ({ apiEndpoint, projectId, widgetId }) =>
    axios.get(`${apiEndpoint}/v3/dashboards/widgets/${widgetId}`, { headers: { projectId } });

const createDashboardWidgetAPI = ({ apiEndpoint, projectId, params }) =>
    axios.post(`${apiEndpoint}/v3/dashboards/widgets`, params, { headers: { projectId } });

const deleteDashboardWidgetAPI = ({ apiEndpoint, projectId, widgetId }) =>
    axios.delete(`${apiEndpoint}/v3/dashboards/widgets/${widgetId}`, { headers: { projectId } });

const updateDashboardWidgetAPI = ({ apiEndpoint, projectId, widgetId, params }) =>
    axios.patch(`${apiEndpoint}/v3/dashboards/widgets/${widgetId}`, params, { headers: { projectId } });

const getWidgetDataAPI = ({ apiEndpoint, projectId, widgetId, params }) =>
    axios.get(`${apiEndpoint}/v3/dashboards/widgets/${widgetId}/data`, { headers: { projectId }, params });

const SHARED_ASSET_TO_ME_PENDING = "SHARED_ASSET_TO_ME_PENDING";
const SHARED_ASSET_TO_ME_SUCCESS = "SHARED_ASSET_TO_ME_SUCCESS";
const SHARED_ASSET_TO_ME_FAILURE = "SHARED_ASSET_TO_ME_FAILURE";
const LATEST_VIDEOS_PENDING = "LATEST_VIDEOS_PENDING";
const LATEST_VIDEOS_SUCCESS = "LATEST_VIDEOS_SUCCESS";
const LATEST_VIDEOS_FAILURE = "LATEST_VIDEOS_FAILURE";
const LATEST_PEOPLE_PENDING = "LATEST_PEOPLE_PENDING";
const LATEST_PEOPLE_SUCCESS = "LATEST_PEOPLE_SUCCESS";
const LATEST_PEOPLE_FAILURE = "LATEST_PEOPLE_FAILURE";
const LATEST_MUSIC_PENDING = "LATEST_MUSIC_PENDING";
const LATEST_MUSIC_SUCCESS = "LATEST_MUSIC_SUCCESS";
const LATEST_MUSIC_FAILURE = "LATEST_MUSIC_FAILURE";
const LATEST_PHOTOS_PENDING = "LATEST_PHOTOS_PENDING";
const LATEST_PHOTOS_SUCCESS = "LATEST_PHOTOS_SUCCESS";
const LATEST_PHOTOS_FAILURE = "LATEST_PHOTOS_FAILURE";
const GET_DASHBOARD_WIDGET_LIST_PENDING = "GET_DASHBOARD_WIDGET_LIST_PENDING";
const GET_DASHBOARD_WIDGET_LIST_SUCCESS = "GET_DASHBOARD_WIDGET_LIST_SUCCESS";
const GET_DASHBOARD_WIDGET_LIST_FAILURE = "GET_DASHBOARD_WIDGET_LIST_FAILURE";
const GET_DETAIL_DASHBOARD_WIDGET_PENDING = "GET_DETAIL_DASHBOARD_WIDGET_PENDING";
const GET_DETAIL_DASHBOARD_WIDGET_SUCCESS = "GET_DETAIL_DASHBOARD_WIDGET_SUCCESS";
const GET_DETAIL_DASHBOARD_WIDGET_FAILURE = "GET_DETAIL_DASHBOARD_WIDGET_FAILURE";
const CREATE_DASHBOARD_WIDGET_PENDING = "CREATE_DASHBOARD_WIDGET_PENDING";
const CREATE_DASHBOARD_WIDGET_SUCCESS = "CREATE_DASHBOARD_WIDGET_SUCCESS";
const CREATE_DASHBOARD_WIDGET_FAILURE = "CREATE_DASHBOARD_WIDGET_FAILURE";
const DELETE_DASHBOARD_WIDGET_PENDING = "DELETE_DASHBOARD_WIDGET_PENDING";
const DELETE_DASHBOARD_WIDGET_SUCCESS = "DELETE_DASHBOARD_WIDGET_SUCCESS";
const DELETE_DASHBOARD_WIDGET_FAILURE = "DELETE_DASHBOARD_WIDGET_FAILURE";
const UPDATE_DASHBOARD_WIDGET_PENDING = "UPDATE_DASHBOARD_WIDGET_PENDING";
const UPDATE_DASHBOARD_WIDGET_SUCCESS = "UPDATE_DASHBOARD_WIDGET_SUCCESS";
const UPDATE_DASHBOARD_WIDGET_FAILURE = "UPDATE_DASHBOARD_WIDGET_FAILURE";
const GET_WIDGET_DATA_PENDING = "GET_WIDGET_DATA_PENDING";
const GET_WIDGET_DATA_SUCCESS = "GET_WIDGET_DATA_SUCCESS";
const GET_WIDGET_DATA_FAILURE = "GET_WIDGET_DATA_FAILURE";
const CLEAR_DASHBOARD_WIDGET = "CLEAR_DASHBOARD_WIDGET";

// const dummy = [
//     {
//         id: "TEST_ID",
//         type: "ZENDESK_SEARCH",
//         name: "test-widget",
//         logoUrl:
//             "https://image.shutterstock.com/image-vector/autumn-seamless-pattern-different-leaves-600w-1795162348.jpg",
//         viewAllUrl: "https://mzc-sm.zendesk.com/hc/ko/sections/10645050450585-%EA%B3%B5%EC%A7%80%EC%82%AC%ED%95%AD",
//         isExposed: false,
//         isExposeEditable: true,
//         isFullWidth: true,
//         project: {
//             id: "mz-cm-v1",
//         },
//         params: {
//             domain: "mzc-sm.zendesk.com",
//             queryParams: { locales: "ko-kr", topicId: "10645985208473" },
//             authentication: {
//                 password: "abW7WGHUmEhyXgq7Mlt9GUtfGbFrI5uDjwGnUllH",
//                 username: "mz.zendesk@mz.co.kr/token",
//             },
//         },
//     },
// ];

const initialState = {
    sharedAssetsToMe: {
        data: null,
        pending: false,
        error: false,
    },
    latestVideos: {
        data: null,
        pending: false,
        error: false,
    },
    latestPeople: {
        data: null,
        pending: false,
        error: false,
    },
    latestMusic: {
        data: null,
        pending: false,
        error: false,
    },
    latestPhotos: {
        data: null,
        pending: false,
        error: false,
    },
    dashboardWidgetList: { pending: false, data: null, nextToken: null, error: null },
    dashboardWidgetData: [],
    modifyDashboardWidget: {
        deleteWidgetPending: false,
        updateWidgetPending: false,
        createWidgetPending: false,
        error: null,
    },
};

export default handleActions(
    {
        [SHARED_ASSET_TO_ME_PENDING]: (state) => {
            return {
                ...state,
                sharedAssetsToMe: {
                    ...fp.get(`sharedAssetsToMe`, state),
                    pending: true,
                },
            };
        },
        [SHARED_ASSET_TO_ME_SUCCESS]: (state, action) => {
            return {
                ...state,
                sharedAssetsToMe: {
                    pending: false,
                    error: false,
                    data: fp.getOr([], `payload`, action),
                },
            };
        },
        [SHARED_ASSET_TO_ME_FAILURE]: (state) => {
            return {
                ...state,
                sharedAssetsToMe: {
                    ...fp.get(`sharedAssetsToMe`, state),
                    pending: false,
                    error: true,
                },
            };
        },
        [LATEST_VIDEOS_PENDING]: (state) => {
            return {
                ...state,
                latestVideos: {
                    ...fp.get(`latestVideos`, state),
                    pending: true,
                },
            };
        },
        [LATEST_VIDEOS_SUCCESS]: (state, action) => {
            return {
                ...state,
                latestVideos: {
                    pending: false,
                    error: false,
                    data: fp.getOr([], `payload`, action),
                },
            };
        },
        [LATEST_VIDEOS_FAILURE]: (state) => {
            return {
                ...state,
                latestVideos: {
                    ...fp.get(`latestVideos`, state),
                    pending: false,
                    error: true,
                },
            };
        },
        [LATEST_PEOPLE_PENDING]: (state) => {
            return {
                ...state,
                latestPeople: {
                    ...fp.get(`latestPeople`, state),
                    pending: true,
                },
            };
        },
        [LATEST_PEOPLE_SUCCESS]: (state, action) => {
            return {
                ...state,
                latestPeople: {
                    pending: false,
                    error: false,
                    data: fp.getOr([], `payload`, action),
                },
            };
        },
        [LATEST_PEOPLE_FAILURE]: (state) => {
            return {
                ...state,
                latestPeople: {
                    ...fp.get(`latestPeople`, state),
                    pending: false,
                    error: true,
                },
            };
        },
        [LATEST_MUSIC_PENDING]: (state) => {
            return {
                ...state,
                latestMusic: {
                    ...fp.get(`latestMusic`, state),
                    pending: true,
                },
            };
        },
        [LATEST_MUSIC_SUCCESS]: (state, action) => {
            return {
                ...state,
                latestMusic: {
                    pending: false,
                    error: false,
                    data: fp.getOr([], `payload`, action),
                },
            };
        },
        [LATEST_MUSIC_FAILURE]: (state) => {
            return {
                ...state,
                latestMusic: {
                    ...fp.get(`latestMusic`, state),
                    pending: false,
                    error: true,
                },
            };
        },
        [LATEST_PHOTOS_PENDING]: (state) => {
            return {
                ...state,
                latestPhotos: {
                    ...fp.get(`latestPhotos`, state),
                    pending: true,
                },
            };
        },
        [LATEST_PHOTOS_SUCCESS]: (state, action) => {
            return {
                ...state,
                latestPhotos: {
                    pending: false,
                    error: false,
                    data: fp.getOr([], `payload`, action),
                },
            };
        },
        [LATEST_PHOTOS_FAILURE]: (state) => {
            return {
                ...state,
                latestPhotos: {
                    ...fp.get(`latestPhotos`, state),
                    pending: false,
                    error: true,
                },
            };
        },
        [GET_DASHBOARD_WIDGET_LIST_PENDING]: (state, action) => {
            return { ...state, dashboardWidgetList: { pending: true, data: null, nextToken: null, error: null } };
        },
        [GET_DASHBOARD_WIDGET_LIST_SUCCESS]: (state, action) => {
            return {
                ...state,
                dashboardWidgetList: {
                    ...state.dashboardWidgetList,
                    pending: false,
                    data: action.payload.data,
                    nextToken: action.payload.nextToken,
                },
            };
        },
        [GET_DASHBOARD_WIDGET_LIST_FAILURE]: (state, action) => {
            return {
                ...state,
                dashboardWidgetList: { ...state.dashboardWidgetList, pending: false, error: action.payload },
            };
        },
        [GET_DETAIL_DASHBOARD_WIDGET_PENDING]: (state, action) => {
            return { ...state, detailDashboardWidget: { pending: true, data: null, nextToken: null, error: null } };
        },
        [GET_DETAIL_DASHBOARD_WIDGET_SUCCESS]: (state, action) => {
            return {
                ...state,
                detailDashboardWidget: {
                    ...state.detailDashboardWidget,
                    pending: true,
                    data: action.payload.data,
                    nextToken: action.payload.nextToken,
                },
            };
        },
        [GET_DETAIL_DASHBOARD_WIDGET_FAILURE]: (state, action) => {
            return {
                ...state,
                detailDashboardWidget: { ...state.detailDashboardWidget, pending: true, error: action.payload },
            };
        },
        [DELETE_DASHBOARD_WIDGET_PENDING]: (state) => {
            return { ...state, modifyDashboardWidget: { deleteWidgetPending: true, error: null } };
        },
        [DELETE_DASHBOARD_WIDGET_SUCCESS]: (state) => {
            return { ...state, modifyDashboardWidget: { ...state.modifyDashboardWidget, deleteWidgetPending: false } };
        },
        [DELETE_DASHBOARD_WIDGET_FAILURE]: (state) => {
            return {
                ...state,
                modifyDashboardWidget: {
                    ...state.modifyDashboardWidget,
                    deleteWidgetPending: false,
                    error: action.payload,
                },
            };
        },
        [CREATE_DASHBOARD_WIDGET_PENDING]: (state) => {
            return { ...state, modifyDashboardWidget: { createWidgetPending: true, error: null } };
        },
        [CREATE_DASHBOARD_WIDGET_SUCCESS]: (state) => {
            return { ...state, modifyDashboardWidget: { ...state.modifyDashboardWidget, createWidgetPending: false } };
        },
        [CREATE_DASHBOARD_WIDGET_FAILURE]: (state) => {
            return {
                ...state,
                modifyDashboardWidget: {
                    ...state.modifyDashboardWidget,
                    createWidgetPending: false,
                    error: action.payload,
                },
            };
        },
        [UPDATE_DASHBOARD_WIDGET_PENDING]: (state) => {
            return { ...state, modifyDashboardWidget: { updateWidgetPending: true, error: null } };
        },
        [UPDATE_DASHBOARD_WIDGET_SUCCESS]: (state) => {
            return { ...state, modifyDashboardWidget: { ...state.modifyDashboardWidget, updateWidgetPending: false } };
        },
        [UPDATE_DASHBOARD_WIDGET_FAILURE]: (state) => {
            return {
                ...state,
                modifyDashboardWidget: {
                    ...state.modifyDashboardWidget,
                    updateWidgetPending: false,
                    error: action.payload,
                },
            };
        },
        [GET_WIDGET_DATA_PENDING]: (state, action) => {
            let dashboardList = state.dashboardWidgetData;
            const pendingIndex = dashboardList.findIndex((dashboard) => dashboard.id === action.payload);
            if (pendingIndex !== -1) dashboardList[pendingIndex] = { id: action.payload, pending: true, data: null };
            else dashboardList.push({ id: action.payload, pending: true, data: null });
            return { ...state, dashboardWidgetData: dashboardList };
        },
        [GET_WIDGET_DATA_SUCCESS]: (state, action) => {
            let dashboardList = state.dashboardWidgetData;
            const pendingIndex = dashboardList.findIndex((dashboard) => dashboard.id === action.payload.id);
            if (pendingIndex !== -1)
                dashboardList[pendingIndex] = { id: action.payload.id, pending: false, data: action.payload.data };
            return {
                ...state,
                dashboardWidgetData: dashboardList,
            };
        },
        [GET_WIDGET_DATA_FAILURE]: (state, action) => {
            let dashboardList = state.dashboardWidgetData;
            const pendingIndex = dashboardList.findIndex((dashboard) => dashboard.id === action.payload.id);
            if (pendingIndex !== -1) dashboardList[pendingIndex] = { id: action.payload, pending: false, data: null };
            return {
                ...state,
                dashboardWidgetData: dashboardList,
            };
        },
        [CLEAR_DASHBOARD_WIDGET]: (state) => {
            return {
                ...state,
                dashboardWidgetData: [],
            };
        },
    },
    initialState,
);

export const postDashboardQueryList = (payload) => (dispatch, getState) => {
    const { stage, project } = getState();

    //Dashboard Query List 목록
    const resourceTypes = [
        {
            name: `Shared Asset`,
            pending: SHARED_ASSET_TO_ME_PENDING,
            success: SHARED_ASSET_TO_ME_SUCCESS,
            failure: SHARED_ASSET_TO_ME_FAILURE,
        },
        {
            name: `latestVideos`,
            pending: LATEST_VIDEOS_PENDING,
            success: LATEST_VIDEOS_SUCCESS,
            failure: LATEST_VIDEOS_FAILURE,
        },
        {
            name: `latestPeople`,
            pending: LATEST_PEOPLE_PENDING,
            success: LATEST_PEOPLE_SUCCESS,
            failure: LATEST_PEOPLE_FAILURE,
        },
        {
            name: `latestMusic`,
            pending: LATEST_MUSIC_PENDING,
            success: LATEST_MUSIC_SUCCESS,
            failure: LATEST_MUSIC_FAILURE,
        },
        {
            name: `latestPhotos`,
            pending: LATEST_PHOTOS_PENDING,
            success: LATEST_PHOTOS_SUCCESS,
            failure: LATEST_PHOTOS_FAILURE,
        },
    ];

    //api 요청시 어떤 List를 요청 했는지 판단
    const selectedResource = fp.reduce(
        (acc, current) => {
            const payloadResource = fp.get(`name`, payload);
            const resource = fp.get(`name`, current);
            if (fp.isEqual(payloadResource, resource)) {
                return current;
            }
            return acc;
        },
        {},
        resourceTypes,
    );

    dispatch({ type: fp.get(`pending`, selectedResource) });

    return new Promise((resolve, reject) => {
        postDashboardQueryListAPI(stage.id, stage.endpoint, project.id, payload)
            .then((response) => {
                const payload = fp.getOr({}, `data`, response);
                dispatch({
                    type: fp.get(`success`, selectedResource),
                    payload,
                });
                resolve(payload);
            })
            .catch((error) => {
                dispatch({
                    type: fp.get(`failure`, selectedResource),
                    payload: error,
                });
                // reject(error); TODO: api 목업 받으면 주석 풀어야함
            });
    });
};

export const getDashboardWidgetList = () => (dispatch, getState) => {
    const { stage, project } = getState();
    // console.log("get 시작");
    dispatch({ type: GET_DASHBOARD_WIDGET_LIST_PENDING });
    return new Promise((resolve, reject) => {
        getDashboardWidgetListAPI({ apiEndpoint: stage.endpoint, projectId: project.id })
            .then((response) => {
                // dispatch({
                //     type: GET_DASHBOARD_WIDGET_LIST_SUCCESS,
                //     payload: { data: response.data.results, nextToken: response.data.nextToken },
                // });
                const newData = response.data;
                newData?.results.map((el) => ({ ...el }));
                dispatch({
                    type: GET_DASHBOARD_WIDGET_LIST_SUCCESS,
                    payload: {
                        data: newData?.results,
                        nextToken: response.data.nextToken,
                    },
                });

                resolve(newData);
            })
            .catch((error) => {
                console.error(error);
                dispatch({ type: GET_DASHBOARD_WIDGET_LIST_FAILURE, payload: error });
                reject(error);
            });
    });
};

export const getDetailDashboardWidget =
    ({ widgetId }) =>
    (dispatch, getState) => {
        const { stage, project } = getState();

        dispatch({ type: GET_DETAIL_DASHBOARD_WIDGET_PENDING });
        return new Promise((resolve, reject) => {
            getDetailDashboardWidgetAPI({ apiEndpoint: stage.endpoint, projectId: project.id, widgetId })
                .then((response) => {
                    dispatch({
                        type: GET_DETAIL_DASHBOARD_WIDGET_SUCCESS,
                        payload: {
                            data: response.data.results,
                        },
                    });
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error(error);
                    dispatch({ type: GET_DETAIL_DASHBOARD_WIDGET_FAILURE, payload: error });
                    reject(error);
                });
        });
    };

export const initDashboardWidget = () => async (dispatch, getState) => {
    const { stage, project } = getState();
    try {
        dispatch({ type: GET_DASHBOARD_WIDGET_LIST_PENDING });
        const response = await getDashboardWidgetListAPI({ apiEndpoint: stage.endpoint, projectId: project.id });

        const newData = response.data?.results;

        const detailWidgets = await Promise.all(
            newData?.map(async (widg) => {
                try {
                    const widgetDetail = await getDetailDashboardWidgetAPI({
                        apiEndpoint: stage.endpoint,
                        projectId: project.id,
                        widgetId: widg.id,
                    });
                    return widgetDetail.data;
                } catch (error) {
                    Promise.reject(error);
                }
            }),
        );
        dispatch({
            type: GET_DASHBOARD_WIDGET_LIST_SUCCESS,
            payload: {
                data: newData.map((widg) => ({ ...widg, data: { pending: false, data: null } })),
                nextToken: response.data.nextToken,
            },
        });
        // dispatch({
        //     type: GET_DASHBOARD_WIDGET_LIST_SUCCESS,
        //     payload: {
        //         data: dummy,
        //         nextToken: response.data.nextToken,
        //     },
        // });
        Promise.resolve(response.data);
    } catch (error) {
        console.error(error);
        dispatch({ type: GET_DASHBOARD_WIDGET_LIST_FAILURE, payload: error });
        Promise.reject(error);
    }
};

export const getWidgetData =
    ({ id, params }) =>
    async (dispatch, getState) => {
        const { stage, project } = getState();
        dispatch({ type: GET_WIDGET_DATA_PENDING, payload: id });
        try {
            const response = await getWidgetDataAPI({
                apiEndpoint: stage.endpoint,
                projectId: project.id,
                widgetId: id,
                params,
            });
            dispatch({ type: GET_WIDGET_DATA_SUCCESS, payload: { id, data: response.data } });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_WIDGET_DATA_FAILURE, payload: { id } });
            Promise.reject(error);
        }
    };

export const updateDashboardWidget = (widgetId, params) => (dispatch, getState) => {
    const { stage, project } = getState();

    return new Promise((resolve, reject) => {
        updateDashboardWidgetAPI({ apiEndpoint: stage.endpoint, projectId: project.id, widgetId, params })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            });
    });
};

export const clearDashboardWidget = createAction(CLEAR_DASHBOARD_WIDGET);
