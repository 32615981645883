import { handleActions, createAction } from "redux-actions";
import fp from "lodash/fp";
import { axios } from "../cores/axiosWrapper";
import queryString from "../cores/queryString";

const getSearchAssetsAPI = (apiEndpoint, stageId, projectId, queryString) => {
    const search = queryString || ``;
    return axios.get(`${apiEndpoint}/integrated-search/assets${search}`, { headers: { stageId, projectId } });
};

const SET_VALIDATION_ERROR = "SET_VALIDATION_ERROR";
const RESET_ADVANCED_SEARCH = "RESET_ADVANCED_SEARCH";
const UPDATE_ADVANCED_SEARCH = "UPDATE_ADVANCED_SEARCH";
const GET_SEARCH_ASSETS_PENDING = "GET_SEARCH_ASSETS_PENDING";
const GET_SEARCH_ASSETS_SUCCESS = "GET_SEARCH_ASSETS_SUCCESS";
const GET_SEARCH_ASSETS_FAILURE = "GET_SEARCH_ASSETS_FAILURE";
const UPDATE_ASSETS_SEARCH = "UPDATE_ASSETS_SEARCH";
const UPDATE_ASSETS_OFFSET = "UPDATE_ASSETS_OFFSET";
const UPDATE_ASSETS_LIMIT = "UPDATE_ASSETS_LIMIT";

const initialState = {
    error: false,
    search: {
        projectId: "",
        name: "", // name
        creator: "", // owner
        id: "",
        status: "",
        mediaTypes: [],
        beginDuration: "",
        endDuration: "", // duration ex) beginDuration ~ endDuration
        types: [],
        startAt: null,
        endAt: null, // createdAt ex) startAt ~ endAt
        extensions: [],
        tags: [],
        q: "",
        isQuickSearch: true,
    },
    validationErrors: {
        name: "required",
    },
    assets: {
        error: null,
        pending: false,
        data: [],
        totalCount: 0,
        offset: 0,
        limit: 20,
    },
};

const cloneInitialState = fp.cloneDeep(initialState);

export default handleActions(
    {
        [SET_VALIDATION_ERROR]: (state, action) => {
            // const {
            //     key,
            //     error
            // } = fp.get(`payload`, action);
            // const validationErrors = fp.get(`validationErrors`, state);

            // if(error) {
            //     return {
            //         ...state,
            //         validationErrors : {
            //             ...fp.get(`validationErrors`, state),
            //             [key] : error
            //         }
            //     };
            // }

            // if(validationErrors[key]) {
            //     delete validationErrors[key];
            // }
            return {
                ...state,
            };
        },
        [RESET_ADVANCED_SEARCH]: (state) => {
            return {
                ...state,
                search: {
                    ...fp.get(`search`, cloneInitialState),
                    isQuickSearch: fp.get(`search.isQuickSearch`, state),
                },
                assets: {
                    ...fp.get(`assets`, state),
                    offset: fp.get(`assets.offset`, cloneInitialState),
                    limit: fp.get(`assets.limit`, cloneInitialState),
                },
            };
        },
        [UPDATE_ADVANCED_SEARCH]: (state, action) => {
            return {
                ...state,
                search: {
                    ...fp.get(`search`, state),
                    ...fp.get(`payload`, action),
                },
            };
        },
        [UPDATE_ASSETS_LIMIT]: (state, action) => {
            return {
                ...state,
                assets: {
                    ...fp.get(`assets`, state),
                    limit: fp.get(`payload`, action),
                },
            };
        },
        [UPDATE_ASSETS_OFFSET]: (state, action) => {
            return {
                ...state,
                assets: {
                    ...fp.get(`assets`, state),
                    offset: fp.get(`payload`, action),
                },
            };
        },
        [GET_SEARCH_ASSETS_PENDING]: (state) => {
            return {
                ...state,
                assets: {
                    ...fp.get(`assets`, state),
                    pending: true,
                    error: false,
                },
            };
        },
        [GET_SEARCH_ASSETS_SUCCESS]: (state, action) => {
            return {
                ...state,
                assets: {
                    ...fp.get(`assets`, state),
                    pending: false,
                    error: false,
                    data: fp.getOr([], `payload.assets`, action),
                    totalCount: fp.getOr(0, `payload.totalCount`, action),
                },
            };
        },
        [GET_SEARCH_ASSETS_FAILURE]: (state) => {
            return {
                ...state,
                assets: {
                    ...fp.get(`assets`, state),
                    pending: false,
                    error: true,
                },
            };
        },
        [UPDATE_ASSETS_SEARCH]: (state, action) => {
            return {
                ...state,
                assets: {
                    ...fp.get(`assets`, state),
                    ...fp.get(`payload`, action),
                },
            };
        },
    },
    initialState,
);

export const setValidationError = createAction(SET_VALIDATION_ERROR);
export const resetAdvancedSearch = createAction(RESET_ADVANCED_SEARCH);
export const updateAdvancedSearch = createAction(UPDATE_ADVANCED_SEARCH);
export const updateAssetsSearch = createAction(UPDATE_ASSETS_SEARCH);
export const updateAssetsLimit = createAction(UPDATE_ASSETS_LIMIT);
export const updateAssetsOffSet = createAction(UPDATE_ASSETS_OFFSET);

export const getSearchAssets = (query) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: GET_SEARCH_ASSETS_PENDING });

    return new Promise((resolve, reject) => {
        getSearchAssetsAPI(stage.endpoint, stage.id, project.id, query)
            .then((response) => {
                const payload = fp.getOr({}, `data`, response);
                dispatch({
                    type: GET_SEARCH_ASSETS_SUCCESS,
                    payload,
                });
                resolve(payload);
            })
            .catch((error) => {
                dispatch({
                    type: GET_SEARCH_ASSETS_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};
