import React, { useMemo } from "react";
import { HeaderForViewer } from "@components/header";
import Footer from "@components/footer";
import Notification from "@components/notification";
import { useLocation } from "react-router-dom";

//Note: 아래 스타일은 은경님이 현재 스타일 작업이 불가능하여 인라인으로 작업한 값으로 추후 수정이 필요합니다.(스크롤이 페이지 우측에서 떨어져서 출력되는 현상 수정을 위해 children에서 padding을 가져가도록)
const contentsStyle = {
    padding: "0",
};

const LayoutForViewer = ({ children }) => {
    const location = useLocation();
    const isErrorPage = useMemo(() => location.pathname.includes("/error/"), [location.pathname]);

    return (
        <div className={`wrap wrap-viewer ${isErrorPage ? "wrap-error" : ""}`}>
            <HeaderForViewer />
            <div className={"container"}>
                <div className={"main"} style={{ overflow: "auto" }}>
                    <div className={"contents"} style={contentsStyle}>
                        {children}
                    </div>
                    <Footer />
                </div>
            </div>
            <Notification />
        </div>
    );
};

export default LayoutForViewer;
