import React from "react";
import { Grid, Typography } from "@mzc-pdc/ui";
import { ImageEmptySearchMini } from "@mzc-cloudplex/icons";
import theme from "@styles/theme";
import { useTranslation } from "react-i18next";

export const NoSearchResultsIcon = ({ icon, title }) => {
    const { t } = useTranslation();
    return (
        <Grid
            container
            justifyContent={"center"}
            alignItems={`center`}
            flexDirection={"column"}
            sx={{ height: "100%" }}
        >
            {icon ? icon : <ImageEmptySearchMini width={24} height={24} />}
            <Typography component={"span"} color={theme.palette.secondary[800]}>
                {t(`common::msg::${title ? title : "No search results found."}`)}
            </Typography>
        </Grid>
    );
};
