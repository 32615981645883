import {handleActions, createAction} from 'redux-actions';
import {
    axios,
    axiosWithoutHeaders
} from "../cores/axiosWrapper";

function getProgrammesAPI(stageId, apiEndpoint, projectId, params) {
    return axios.get(`${apiEndpoint}/programmes`, {
        params,
        headers : {
            stageId,
            projectId
        }
    });
}
function getProgramAPI(stageId, apiEndpoint, projectId, programId) {
    return axios.get(`${apiEndpoint}/programmes/${programId}`, {
        headers : {
            stageId,
            projectId
        }
    });
}
function postProgramAPI(stageId, apiEndpoint, projectId, params) {
    return axios.post(`${apiEndpoint}/programmes`, params, {
        headers: {
            stageId,
            projectId
        }
    });
}
//action type
const PROGRAMMES_PENDING = 'PROGRAMMES_PENDING';
const PROGRAMMES_FAILURE = 'PROGRAMMES_FAILURE';
const GET_PROGRAMMES_SUCCESS = 'GET_PROGRAMMES_SUCCESS';
const SET_PROGRAM_DROP = 'SET_PROGRAM_DROP';
const SET_PROGRAM_SELECTED = 'SET_PROGRAM_SELECTED';
const GET_PROGRAM_SUCCESS = 'GET_PROGRAM_SUCCESS';
const POST_PROGRAM_SUCCESS = 'POST_PROGRAM_SUCCESS';
const DELETE_PROGRAM_SELECTED = 'DELETE_PROGRAM_SELECTED';
const SET_PROGRAM_COUNT = 'SET_PROGRAM_COUNT';
//reducer
const initialState = {
    pending: false,
    error: false,
    data: [],
    totalCount: 0,
    selected: {
        id : '',
        createdDateTime : '',
        duration : '',
        name : '',
        poster : ''
    },
    item : null,
    counts : {
        detail : 0,
        list : 0
    },
    standardDateTime : null
};

export default handleActions({
    [PROGRAMMES_PENDING]: (state) => {
        return {
            ...state,
            pending: true,
            error: false
        };
    },
    [PROGRAMMES_FAILURE]: (state) => {
        return {
            ...state,
            pending: false,
            error: true
        };
    },
    [GET_PROGRAMMES_SUCCESS]: (state, action) => {
        const {programmes, totalCount} = action.payload.data;
        
        return {
            ...state,
            pending: false,
            data: programmes.map(v => {
                return {
                    type: 'program',
                    isDropped: false,
                    ...v
                }
            }),
            totalCount
        };
    },
    [SET_PROGRAM_DROP]: (state, action) => {
        const {programId} = action.payload;

        let _data = Object.assign([], state.data);

        _data.map(v => {
            return {
                ...v,
                isDropped : v.id === programId
            };
        });

        return {
            ...state,
            data : _data
        }

    },
    [SET_PROGRAM_SELECTED]:(state, action) => {
        return {
            ...state,
            selected: action.payload
        };
    },
    [POST_PROGRAM_SUCCESS]:(state) => {
        return {
            ...state,
            pending: false,
            error: false
        }
    },
    [DELETE_PROGRAM_SELECTED]:(state) => {
        return {
            ...state,
            selected: {
                id : '',
                createdDateTime : '',
                duration : '',
                name : '',
                poster : ''
            }
        }
    },
    [GET_PROGRAM_SUCCESS]:(state, action) => {
        const {response, standardDateTime} = action.payload;
        
        return {
            ...state,
            item : response.data,
            pending: false,
            error: false,
            standardDateTime,
            counts : {
                ...state.counts,
                detail : state.counts.detail + 1
            }
        }
    },
    [SET_PROGRAM_COUNT]:(state, action) => {
        return {
            ...state,
            counts : {
                ...state.counts,
                ...action.payload
            }
        }
    }
}, initialState);

export const setProgramSelected = createAction(SET_PROGRAM_SELECTED);
export const setProgramDrop = createAction(SET_PROGRAM_DROP);
export const deletedProgramSelected = createAction(DELETE_PROGRAM_SELECTED);
export const getProgrammes = (params) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    dispatch({type: PROGRAMMES_PENDING});

    return getProgrammesAPI(stage.id, stage.endpoint, project.id, params).then(
        (response) => {
            dispatch({
                type: GET_PROGRAMMES_SUCCESS,
                payload: response
            });
        }
    ).catch(error => {
        dispatch({
            type: PROGRAMMES_FAILURE,
            payload: error
        });
    });
};
export const getProgram = (programId, standardDateTime) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    dispatch({type: PROGRAMMES_PENDING});

    return getProgramAPI(stage.id, stage.endpoint, project.id, programId).then(
        (response) => {
            dispatch({
                type: GET_PROGRAM_SUCCESS,
                payload: {
                    response,
                    standardDateTime
                }
            });
        }
    ).catch(error => {
        dispatch({
            type: PROGRAMMES_FAILURE,
            payload: error
        });
    });
};
export const postProgram = (params, callback) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    dispatch({type: PROGRAMMES_PENDING});

    return postProgramAPI(stage.id, stage.endpoint, project.id, params).then(
        (response) => {
            dispatch({
                type: POST_PROGRAM_SUCCESS,
                payload: response
            });
            callback && callback(response);
        }
    ).catch(error => {
        dispatch({
            type: PROGRAMMES_FAILURE,
            payload: error
        });
    });
};
export const setCount = createAction(SET_PROGRAM_COUNT);