import React, { useCallback } from "react";
import { IconAlternateEmail, IconFormatBold, IconImage, IconSmileEmoji } from "@mzc-cloudplex/icons";
import { Divider, Grid, IconButton } from "@mzc-pdc/ui";
import theme from "@styles/theme";
import Tooltip from "@components_v2/Tooltip/tooltip";
import { useTranslation } from "react-i18next";

export const EditorToolbar = ({ id, onClickAttachments, onClickEmoji, onClickMention }) => {
    const { t } = useTranslation();

    return (
        <Grid
            id={id}
            className={"editor-toolbar"}
            sx={{
                "& .ql-formats button:hover": {
                    borderRadius: "4px !important",
                },
            }}
        >
            <span className="ql-formats">
                <Tooltip title={t(`common::label::Bold`, `Bold`)}>
                    <button className={"ql-bold"} />
                </Tooltip>
                <Tooltip title={t(`common::label::Underline`, `Underline`)}>
                    <button className={"ql-underline"} />
                </Tooltip>
                <Tooltip title={t(`common::label::Strikethrough`, `Strikethrough`)}>
                    <button className={"ql-strike"} />
                </Tooltip>
                {/*<button className="ql-italic" />*/}
            </span>
            {/*<span className="ql-formats">*/}
            {/*    <button className="ql-list" value="bullet" />*/}
            {/*    <button className="ql-list" value="ordered" />*/}
            {/*</span>*/}
            <span className="ql-formats">
                <Divider
                    orientation={"horizontal"}
                    sx={{ border: `1px solid ${theme.palette.greySecondary[100]}`, height: "20px" }}
                />
            </span>
            <span className="ql-formats">
                {/*<button className="ql-link" />*/}
                {/*<hr style={{ borderLeft: "1px solid black", height: "100px" }} />*/}
                <Tooltip title={t(`common::label::Attach Files`, `Attach Files`)}>
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            onClickAttachments();
                        }}
                    >
                        <IconImage size={16} />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t(`common::label::Mention`, `Mention`)}>
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            onClickMention();
                        }}
                    >
                        <IconAlternateEmail size={16} />
                    </button>
                </Tooltip>
                <Tooltip title={t(`common::label::Attach Emoji`, `Attach Emoji`)}>
                    <button
                        id={"custom-emoji-button"}
                        className="ql-custom"
                        onClick={(e) => {
                            e.stopPropagation();
                            onClickEmoji();
                        }}
                    >
                        <IconSmileEmoji size={16} />
                    </button>
                </Tooltip>
            </span>
        </Grid>
    );
};
export default EditorToolbar;
