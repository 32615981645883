import { Breadcrumbs, Link, Typography } from "@mzc-pdc/ui";
// import { useNavigate } from 'react-router';
import Skeleton from "@components_v2/skeleton/skeleton";

const BreadCrumb = ({ items, isLoading }) => {
    // const navigate = useNavigate();
    return (
        <div className={"breadcrumb"}>
            {isLoading ? (
                <Skeleton height={20} width={`100%`} />
            ) : (
                <Breadcrumbs separator="/">
                    {items.map((item, index) => (
                        <span key={index}>
                            {item.link ? (
                                <Link
                                    color={"primary"}
                                    // href={navigate}
                                    underline={"hover"}
                                    variant="body3_400"
                                >
                                    {item.label}
                                </Link>
                            ) : (
                                <Typography variant="body3_400">{item.label}</Typography>
                            )}
                        </span>
                    ))}
                </Breadcrumbs>
            )}
        </div>
    );
};

export default BreadCrumb;
