import { COMMON_JOB_STATUS, RIGHT_SIDE_BAR_TYPES, UPLOAD_FILE_LOCATION_TYPE } from "@constants";
import { changeActiveTab, changeIsAdminSetting, toggleActive } from "@modules/rightSideBar";
import { useDispatch, useSelector } from "react-redux";
import { deleteMyJobsJob, updateMyJobsJob, updateMyJobsTask, updateRunningJobIds } from "@modules/myJobs";
import { useEffect, useMemo, useState } from "react";
import uniqid from "uniqid";
import * as jobStatus from "@cores/jobStatus";
import { cancelActions } from "@modules/uploadQueue";
import { cancelTranscodeAllSync } from "@modules/upload";

const useMyJobs = ({ resource = null, store = "uploadQueue" }) => {
    const dispatch = useDispatch();

    const myJobs = useSelector(({ myJobs }) => myJobs);
    const upload = useSelector(({ upload }) => upload);
    const uploadQueue = useSelector(({ uploadQueue }) => uploadQueue);
    const downloadJobs = useSelector(({ downloadJobs }) => downloadJobs);
    const bulkJobs = useSelector(({ bulkJobs }) => bulkJobs);

    const fileData = useSelector((state) => state[store]);
    const [runningJobIds, setRunningJobIds] = useState([]);
    const [isSynchronization, setIsSynchronization] = useState(false);

    //change active tab 기능 옮기기
    const changeMyJobsActiveTab = (activeTab) => {
        dispatch(changeActiveTab({ activeTab }));
    };

    const openMyJobs = (activeTab) => {
        dispatch(
            toggleActive({
                type: RIGHT_SIDE_BAR_TYPES.MY_JOB,
                activeTab,
                activeFlag: true,
            }),
        );
    };

    // const updateIsAdminSetting = (value) => {
    //     dispatch(changeIsAdminSetting({ isAdminSetting: value }));
    // };

    const isJobExisting = useMemo(() => {
        return (
            upload.jobs.data.length > 0 ||
            uploadQueue[UPLOAD_FILE_LOCATION_TYPE.LOCAL || "Local"].length > 0 ||
            myJobs.data.length > 0 ||
            downloadJobs.data.length > 0 ||
            bulkJobs.data.length > 0
        );
    }, [upload, uploadQueue, myJobs, downloadJobs, bulkJobs]);

    const isJobProcessing = useMemo(() => {
        return (
            upload.jobs.data.filter((v) => !jobStatus.isComplete(v.status)).length > 0 ||
            uploadQueue[UPLOAD_FILE_LOCATION_TYPE.LOCAL || "Local"].filter((v) => !jobStatus.isComplete(v.status))
                .length > 0 ||
            myJobs.data.filter((v) => {
                return !(v.status === "CANCELED" || v.status === "FAILED" || v.status === "SUCCEED");
            }).length > 0 ||
            downloadJobs.data.filter((v) => {
                return !(v.status === "CANCELED" || v.status === "FAILED" || v.status === "SUCCEED");
            }).length > 0 ||
            bulkJobs.data.filter((v) => {
                return !(v.status === "CANCELED" || v.status === "FAILED" || v.status === "SUCCEED");
            }).length > 0
        );
    }, [upload, uploadQueue, myJobs, downloadJobs, bulkJobs]);

    const updateMyJobsTaskData = ({ jobId, taskIndex, data }) => {
        dispatch(updateMyJobsTask({ resource, jobId, taskIndex, data }));
    };

    const updateMyJobsData = ({ jobId, data }) => {
        dispatch(updateMyJobsJob({ resource, jobId, data }));
    };

    const deleteMyJobsData = (jobId) => {
        dispatch(deleteMyJobsJob({ resource, jobId }));
    };

    useEffect(() => {
        if (!isSynchronization) return;
        if (!store) return;
        const myJobsData = myJobs[resource]?.data;
        if (runningJobIds.length === 0) return;

        runningJobIds.map((jobId) => {
            let newDataFromMyJobs = myJobsData?.find((item) => item.jobId === jobId);
            if (!newDataFromMyJobs) return;

            let tasksFromFileData = fileData[resource]?.filter((item) => item.data.job.jobId === jobId);
            const jobTasks = tasksFromFileData.map(({ status, data, files }) => ({
                status,
                metadata: data?.metadata,
                name: data?.name,
                files,
            }));
            if (jobTasks) {
                newDataFromMyJobs.tasks = jobTasks;
                updateMyJobsData({ jobId, data: newDataFromMyJobs });

                if (newDataFromMyJobs.status === COMMON_JOB_STATUS.SUCCEED) {
                    const filteredJobIds = runningJobIds.filter((id) => id !== jobId);
                    dispatch(updateRunningJobIds({ resource, runningJobIds: filteredJobIds }));
                }
            }
        });
    }, [fileData?.[resource], isSynchronization]);

    const synchronize = () => {
        setIsSynchronization(true);
    };

    const createJobData = (customData) => {
        const jobId = uniqid();
        const data = {
            jobId: jobId, //Note: API 순서가 밀려있어서 retryKey를 넣을 수 있지는 않아보임.
            status: COMMON_JOB_STATUS.SUBMITTED,
            startAt: new Date().toISOString(),
            ...customData,
        };
        // setRunningJobIds([...runningJobIds, jobId]);
        updateMyJobsData({ jobId, data });
        return data;
    };

    const handleBeforeunload = (e) => {
        const locationType = UPLOAD_FILE_LOCATION_TYPE.LOCAL || "Local";
        const cancelTranscodingJobs = upload.jobs.data.filter(
            (v) => jobStatus.getValuesLowerThan(jobStatus.values.SUBMITTED).indexOf(v.status) > -1,
        );
        const cancelUploadJobs = uploadQueue[locationType].filter((v) => !jobStatus.isComplete(v.status));
        const cancelJobs = cancelTranscodingJobs.concat(cancelUploadJobs);

        // console.log('checking:', cancelJobs)
        if (downloadJobs.beforeUnloadCheck > 0) return;
        if (cancelJobs.length > 0) {
            const message = t(`common::msg::${"There is work in progress. Would you really go out?"}`);
            (e || window.event).returnValue = message;
            return message;
        }
    };

    const handleUnload = async () => {
        cancelUploadingJobs();
        cancelTranscodingJobs();
    };

    const cancelTranscodingJobs = () => {
        const cancelTargetJobs = upload.jobs.data.filter(
            (v) => jobStatus.getValuesLowerThan(jobStatus.values.SUBMITTED).indexOf(v.status) > -1,
        );
        if (cancelTargetJobs.length > 0) {
            dispatch(cancelTranscodeAllSync(cancelTargetJobs));
        }
    };

    const cancelUploadingJobs = () => {
        const locationType = UPLOAD_FILE_LOCATION_TYPE.LOCAL || "Local";
        const cancelUploadJobs = uploadQueue[locationType].filter((v) => !jobStatus.isComplete(v.status));
        if (cancelUploadJobs.length > 0) {
            cancelUploadJobs.forEach((item) => {
                const key = item.key;
                dispatch(cancelActions(locationType, key));
            });
        }
    };

    useEffect(() => {
        window.addEventListener("beforeunload", handleBeforeunload);
        window.addEventListener("unload", handleUnload);
        return () => {
            window.removeEventListener("unload", handleUnload);
            window.removeEventListener("beforeunload", handleBeforeunload);
        };
    }, []);

    useEffect(() => {
        if (!myJobs[resource]?.runningJobIds) return;
        setRunningJobIds(myJobs[resource]?.runningJobIds);
    }, [myJobs[resource]?.runningJobIds]);

    return {
        openMyJobs,
        changeMyJobsActiveTab,
        updateMyJobsData,
        updateMyJobsTaskData,
        deleteMyJobsData,
        createJobData,
        synchronize,
        isJobExisting,
        isJobProcessing,
    };
};

export default useMyJobs;
