const development = {
    host: {
        console: "https://console.cloudplex.dev.megazone.io",
        cms: "https://cms.cloudplex.dev.megazone.io",
        //api: "http://localhost:60302"
        //api: "http://mz-cm-api-load-balancer-1319778791.ap-northeast-2.elb.amazonaws.com"
        api: "https://api.cloudplex.dev.megazone.io",
        // api: "https://api.media.stg.continuum.co.kr"
        analysis: "https://6qmtbkh830.execute-api.ap-northeast-2.amazonaws.com",
        loginRedirectUri: "/megazone/login",
    },
    mz_pops: {
        oauth_host: "https://login.megazone.com",
        api_host: "https://www.megazoneapis.com",
        clientId: "BOgo3m3uS37ZJu5UuQrSwpl45IDchp", // 'd1J99hKQAdWH1C9lKM70QqeCDJI3zu'//
    },
    profileId: "1536304085jekLFQq1",
    PLAYER_URL: "https://d7kpk9cyykhsu.cloudfront.net",
    hotjar_tracking_code: {
        hjid: "3070897",
        hjsv: "6",
    },
    cloudplex: {
        startApiEndpoint: "https://j1wlqpg62b.execute-api.ap-northeast-2.amazonaws.com/dev",
        clientId: "peImggg1k585Mb1OU8ouXNwCOQTUjw"
    }
};

export default development;

//console.media.megazone.com
