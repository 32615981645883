import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as assetApi from "./apis/asset";
import fp from "lodash/fp";

export const requestAssetRelatedContent = createAsyncThunk(
    "assetDeleteModal/assetRelatedContentsRequest",
    async (arg, thunkApi) => {
        const { stage, project } = thunkApi.getState();
        return assetApi.getAssetRelatedContentsAPI(
            stage.id,
            stage.endpoint,
            project.id,
            fp.get("assetId", arg),
            fp.get("version", arg),
            null,
            fp.get("elementIds", arg),
        );
    },
);

const deleteAssetModalSlice = createSlice({
    name: "deleteAssetModal",
    initialState: {
        relatedContents: {
            // [assetId]: {
            //     pending: false,
            //     error: false,
            //     data: {
            //         totalCount: 0,
            //         videos: [],
            //         people: [],
            //         photos: [],
            //         music: [],
            //     }
            // }
        },
    },
    reducers: {
        clearRelatedContents(state) {
            return {
                ...state,
                relatedContents: {},
            };
        }
    },
    extraReducers: {
        [requestAssetRelatedContent.fulfilled]: (state, action) => {
            // success
            const { payload } = action;
            const data = { ...payload.data };
            data.totalCount =
                fp.getOr([], "videos", data).length +
                fp.getOr([], "people", data).length +
                fp.getOr([], "music", data).length +
                fp.getOr([], "photos", data).length;
            return {
                ...state,
                relatedContents: {
                    ...state.relatedContents,
                    [action.meta.arg.assetId]: {
                        pending: false,
                        error: false,
                        data,
                    },
                },
            };
        },
        [requestAssetRelatedContent.rejected]: (state, action) => {
            // fail
            return {
                ...state,
                relatedContents: {
                    ...state.relatedContents,
                    [action.meta.arg.assetId]: {
                        pending: false,
                        error: true,
                        errorMessage: fp.get("payload.data", action),
                        data: null,
                    },
                },
            };
        },
        [requestAssetRelatedContent.pending]: (state, action) => {
            // pending
            return {
                ...state,
                relatedContents: {
                    ...state.relatedContents,
                    [action.meta.arg.assetId]: {
                        pending: true,
                        error: false,
                        data: null,
                    },
                },
            };
        },
    },
});

export const deleteAssetModalActions = deleteAssetModalSlice.actions;
const deleteAssetModalSliceReducer = deleteAssetModalSlice.reducer;

export const clearRelatedContents = deleteAssetModalSlice.actions.clearRelatedContents;

export default deleteAssetModalSliceReducer;
