import { axios } from "../../cores/axiosWrapper";

export const getContentSchemasAPI = async (stageId, apiEndpoint, projectId, params) => {
    return axios.get(`${apiEndpoint}/v3/content-schemas`, {
        params,
        headers: {
            stageId,
            ...(projectId && { projectId }),
        },
    });
};
