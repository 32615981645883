import { Alert } from "@mzc-pdc/ui";
import { alpha, styled } from "@mui/material";
import theme from "@styles/theme";
import { palette } from "@mzc-pdc/ui";

export const StyledInfoAlert = styled(Alert)(({ color, variant }) => ({
    ".MuiAlert-message": {
        padding: `4px 0px`,
    },
    ".MuiAlert-icon": { alignItems: "flex-start", marginTop: "6px" },
    ...(color === "info" &&
        variant === "outlined" && {
            "&.MuiAlert-outlinedInfo": {
                border: `solid 1px ${palette.info[200]}`,
                backgroundColor: "rgba(226, 242, 255, 0.5)",
            },
        }),
}));

export const StyledErrorAlert = styled(Alert)(({ color, variant }) => ({
    ".MuiAlert-message": {
        padding: `4px 0px`,
    },
    ".MuiAlert-icon": { alignItems: "flex-start", marginTop: "4px" },
    ...(color === "error" &&
        variant === "outlined" && {
            "&.MuiAlert-outlinedError": {
                border: `solid 1px ${theme.palette.error[200]}`,
                backgroundColor: `rgba(253, 233, 231, 0.5)`,
            },
        }),
}));

export const StyledWarningAlert = styled(Alert)(({ color, variant }) => ({
    ".MuiAlert-message": {
        padding: `4px 0px`,
    },
    ".MuiAlert-icon": { alignItems: "flex-start", marginTop: "4px" },
    ...(color === "warning" &&
        variant === "outlined" && {
            "&.MuiAlert-outlinedError": {
                border: `solid 1px ${theme.palette.warning[100]}`,
                backgroundColor: theme.palette.warning[600],
            },
        }),
}));
