import i18n from "./lib/i18n";
import {
    VERSION_CM_11376_SHARES_V2,
    VERSION_CM_6286_METADATA_SELECT_FIELD,
    VERSION_CM_8075_SHARE_REQUEST,
} from "./cores/version";

export function CONSTANTS(name) {
    switch (name) {
        case "CREATE_JOB_AUDIO_LANGUAGE":
            return [
                {
                    value: "ENG",
                    label: "English",
                },
                {
                    value: "SPA",
                    label: "Spanish; Castilian",
                },
                {
                    value: "FRA",
                    label: "French",
                },
                {
                    value: "DEU",
                    label: "German (DEU)",
                },
                {
                    value: "GER",
                    label: "German (GER)",
                },
                {
                    value: "ZHO",
                    label: "Chinese",
                },
                {
                    value: "ARA",
                    label: "Arabic",
                },
                {
                    value: "HIN",
                    label: "Hindi",
                },
                {
                    value: "JPN",
                    label: "Japanese",
                },
                {
                    value: "RUS",
                    label: "Russian",
                },
                {
                    value: "POR",
                    label: "Portuguese",
                },
                {
                    value: "ITA",
                    label: "Italian",
                },
                {
                    value: "URD",
                    label: "Urdu",
                },
                {
                    value: "VIE",
                    label: "Vietnamese",
                },
                {
                    value: "KOR",
                    label: "Korean",
                },
                {
                    value: "PAN",
                    label: "Panjabi; Punjabi",
                },
                {
                    value: "ABK",
                    label: "Abkhazian",
                },
                {
                    value: "AAR",
                    label: "Afar",
                },
                {
                    value: "AFR",
                    label: "Afrikaans",
                },
                {
                    value: "AKA",
                    label: "Akan",
                },
                {
                    value: "SQI",
                    label: "Albanian",
                },
                {
                    value: "AMH",
                    label: "Amharic",
                },
                {
                    value: "ARG",
                    label: "Aragonese",
                },
                {
                    value: "HYE",
                    label: "Armenian",
                },
                {
                    value: "ASM",
                    label: "Assamese",
                },
                {
                    value: "AVA",
                    label: "Avaric",
                },
                {
                    value: "AVE",
                    label: "Avestan",
                },
                {
                    value: "AYM",
                    label: "Aymara",
                },
                {
                    value: "AZE",
                    label: "Azerbaijani",
                },
                {
                    value: "BAM",
                    label: "Bambara",
                },
                {
                    value: "BAK",
                    label: "Bashkir",
                },
                {
                    value: "EUS",
                    label: "Basque",
                },
                {
                    value: "BEL",
                    label: "Belarusian",
                },
                {
                    value: "BEN",
                    label: "Bengali",
                },
                {
                    value: "BIH",
                    label: "Bihari languages",
                },
                {
                    value: "BIS",
                    label: "Bislama",
                },
                {
                    value: "BOS",
                    label: "Bosnian",
                },
                {
                    value: "BRE",
                    label: "Breton",
                },
                {
                    value: "BUL",
                    label: "Bulgarian",
                },
                {
                    value: "MYA",
                    label: "Burmese",
                },
                {
                    value: "CAT",
                    label: "Catalan; Valencian",
                },
                {
                    value: "KHM",
                    label: "Central Khmer",
                },
                {
                    value: "CHA",
                    label: "Chamorro",
                },
                {
                    value: "CHE",
                    label: "Chechen",
                },
                {
                    value: "NYA",
                    label: "Chichewa; Chewa; Nyanja",
                },
                {
                    value: "CHU",
                    label: "Church Slavic; Old Bulgarian",
                },
                {
                    value: "CHV",
                    label: "Chuvash",
                },
                {
                    value: "COR",
                    label: "Cornish",
                },
                {
                    value: "COS",
                    label: "Corsican",
                },
                {
                    value: "CRE",
                    label: "Cree",
                },
                {
                    value: "HRV",
                    label: "Croatian",
                },
                {
                    value: "CES",
                    label: "Czech",
                },
                {
                    value: "DAN",
                    label: "Danish",
                },
                {
                    value: "DIV",
                    label: "Divehi; Dhivehi; Maldivian",
                },
                {
                    value: "NLD",
                    label: "Dutch; Flemish",
                },
                {
                    value: "DZO",
                    label: "Dzongkha",
                },
                {
                    value: "ENM",
                    label: "English, Middle (1100-1500)",
                },
                {
                    value: "EPO",
                    label: "Esperanto",
                },
                {
                    value: "EST",
                    label: "Estonian",
                },
                {
                    value: "EWE",
                    label: "Ewe",
                },
                {
                    value: "FAO",
                    label: "Faroese",
                },
                {
                    value: "FIJ",
                    label: "Fijian",
                },
                {
                    value: "FIN",
                    label: "Finnish",
                },
                {
                    value: "FRM",
                    label: "French, Middle (1400-1600)",
                },
                {
                    value: "FUL",
                    label: "Fulah",
                },
                {
                    value: "GLA",
                    label: "Gaelic; Scottish Gaelic",
                },
                {
                    value: "GLG",
                    label: "Galician",
                },
                {
                    value: "LUG",
                    label: "Ganda",
                },
                {
                    value: "KAT",
                    label: "Georgian",
                },
                {
                    value: "ELL",
                    label: "Greek, Modern (1453-)",
                },
                {
                    value: "GRN",
                    label: "Guarani",
                },
                {
                    value: "GUJ",
                    label: "Gujarati",
                },
                {
                    value: "HAT",
                    label: "Haitian; Haitian Cr",
                },
                {
                    value: "HAU",
                    label: "Hausa",
                },
                {
                    value: "HEB",
                    label: "Hebrew",
                },
                {
                    value: "HER",
                    label: "Herero",
                },
                {
                    value: "HMO",
                    label: "Hiri Motu",
                },
                {
                    value: "HUN",
                    label: "Hungarian",
                },
                {
                    value: "ISL",
                    label: "Icelandic",
                },
                {
                    value: "IDO",
                    label: "Ido",
                },
                {
                    value: "IBO",
                    label: "Igbo",
                },
                {
                    value: "IND",
                    label: "Indonesian",
                },
                {
                    value: "INA",
                    label: "Interlingua (IALA)",
                },
                {
                    value: "ILE",
                    label: "Interlingue; Occidental",
                },
                {
                    value: "IKU",
                    label: "Inuktitut",
                },
                {
                    value: "IPK",
                    label: "Inupiaq",
                },
                {
                    value: "GLE",
                    label: "Irish",
                },
                {
                    value: "JAV",
                    label: "Javanese",
                },
                {
                    value: "KAL",
                    label: "Kalaallisut; Greenlandic",
                },
                {
                    value: "KAN",
                    label: "Kannada",
                },
                {
                    value: "KAU",
                    label: "Kanuri",
                },
                {
                    value: "KAS",
                    label: "Kashmiri",
                },
                {
                    value: "KAZ",
                    label: "Kazakh",
                },
                {
                    value: "KIK",
                    label: "Kikuyu; Gikuyu",
                },
                {
                    value: "KIN",
                    label: "Kinyarwanda",
                },
                {
                    value: "KIR",
                    label: "Kirghiz; Kyrgyz",
                },
                {
                    value: "KOM",
                    label: "Komi",
                },
                {
                    value: "KON",
                    label: "Kongo",
                },
                {
                    value: "KUA",
                    label: "Kuanyama; Kwanyama",
                },
                {
                    value: "KUR",
                    label: "Kurdish",
                },
                {
                    value: "LAO",
                    label: "Lao",
                },
                {
                    value: "LAT",
                    label: "Latin",
                },
                {
                    value: "LAV",
                    label: "Latvian",
                },
                {
                    value: "LIM",
                    label: "Limburgan; Limburger; Limburgish",
                },
                {
                    value: "LIN",
                    label: "Lingala",
                },
                {
                    value: "LIT",
                    label: "Lithuanian",
                },
                {
                    value: "LUB",
                    label: "Luba-Katanga",
                },
                {
                    value: "LTZ",
                    label: "Luxembourgish; Letzeburgesch",
                },
                {
                    value: "MKD",
                    label: "Macedonian",
                },
                {
                    value: "MLG",
                    label: "Malagasy",
                },
                {
                    value: "MSA",
                    label: "Malay",
                },
                {
                    value: "MAL",
                    label: "Malayalam",
                },
                {
                    value: "MLT",
                    label: "Malte",
                },
                {
                    value: "GLV",
                    label: "Manx",
                },
                {
                    value: "MRI",
                    label: "Maori",
                },
                {
                    value: "MAR",
                    label: "Marathi",
                },
                {
                    value: "MAH",
                    label: "Marshallese",
                },
                {
                    value: "MON",
                    label: "Mongolian",
                },
                {
                    value: "NAU",
                    label: "Nauru",
                },
                {
                    value: "NAV",
                    label: "Navajo; Navaho",
                },
                {
                    value: "NDE",
                    label: "Ndebele, North; No",
                },
                {
                    value: "NBL",
                    label: "Ndebele, South; South Ndebe",
                },
                {
                    value: "NDO",
                    label: "Ndonga",
                },
                {
                    value: "NEP",
                    label: "Nepali",
                },
                {
                    value: "SME",
                    label: "Northern Sami",
                },
                {
                    value: "NOR",
                    label: "Norwegian",
                },
                {
                    value: "NOB",
                    label: "Norwegian Bokmål",
                },
                {
                    value: "NNO",
                    label: "Norwegian Nynorsk; Nyn",
                },
                {
                    value: "OCI",
                    label: "Occitan (post 1500); Provençal",
                },
                {
                    value: "OJI",
                    label: "Ojibwa",
                },
                {
                    value: "ORI",
                    label: "Oriya",
                },
                {
                    value: "ORM",
                    label: "Oromo",
                },
                {
                    value: "OSS",
                    label: "Ossetian; Ossetic",
                },
                {
                    value: "PLI",
                    label: "Pali",
                },
                {
                    value: "FAS",
                    label: "Persian",
                },
                {
                    value: "POL",
                    label: "Polish",
                },
                {
                    value: "PUS",
                    label: "Pushto; Pashto",
                },
                {
                    value: "QUE",
                    label: "Quechua",
                },
                {
                    value: "QAA",
                    label: "Reserved",
                },
                {
                    value: "RON",
                    label: "Romanian; Moldavian; Moldovan",
                },
                {
                    value: "ROH",
                    label: "Romansh",
                },
                {
                    value: "RUN",
                    label: "Rundi",
                },
                {
                    value: "SMO",
                    label: "Samoan",
                },
                {
                    value: "SAG",
                    label: "Sango",
                },
                {
                    value: "SAN",
                    label: "Sanskrit",
                },
                {
                    value: "SRD",
                    label: "Sardinian",
                },
                {
                    value: "SRB",
                    label: "Serbian",
                },
                {
                    value: "SNA",
                    label: "Shona",
                },
                {
                    value: "III",
                    label: "Sichuan Yi; Nuosu",
                },
                {
                    value: "SND",
                    label: "Sindhi",
                },
                {
                    value: "SIN",
                    label: "Sinhala; Sinhalese",
                },
                {
                    value: "SLK",
                    label: "Slovak",
                },
                {
                    value: "SLV",
                    label: "Slovenian",
                },
                {
                    value: "SOM",
                    label: "Somali",
                },
                {
                    value: "SOT",
                    label: "Sotho, Southern",
                },
                {
                    value: "SUN",
                    label: "Sundanese",
                },
                {
                    value: "SWA",
                    label: "Swahili",
                },
                {
                    value: "SSW",
                    label: "Swati",
                },
                {
                    value: "SWE",
                    label: "Swedish",
                },
                {
                    value: "TGL",
                    label: "Tagalog",
                },
                {
                    value: "TAH",
                    label: "Tahitian",
                },
                {
                    value: "TGK",
                    label: "Tajik",
                },
                {
                    value: "TAM",
                    label: "Tamil",
                },
                {
                    value: "TAT",
                    label: "Tatar",
                },
                {
                    value: "TEL",
                    label: "Telugu",
                },
                {
                    value: "THA",
                    label: "Thai",
                },
                {
                    value: "BOD",
                    label: "Tibetan",
                },
                {
                    value: "TIR",
                    label: "Tigrinya",
                },
                {
                    value: "TON",
                    label: "Tonga (Tonga Islands)",
                },
                {
                    value: "TSO",
                    label: "Tsonga",
                },
                {
                    value: "TSN",
                    label: "Tswana",
                },
                {
                    value: "TUR",
                    label: "Turkish",
                },
                {
                    value: "TUK",
                    label: "Turkmen",
                },
                {
                    value: "TWI",
                    label: "Twi",
                },
                {
                    value: "UIG",
                    label: "Uighur; Uyghur",
                },
                {
                    value: "UKR",
                    label: "Ukrainian",
                },
                {
                    value: "UZB",
                    label: "Uzbek",
                },
                {
                    value: "VEN",
                    label: "Venda",
                },
                {
                    value: "VOL",
                    label: "Volapük",
                },
                {
                    value: "WLN",
                    label: "Walloon",
                },
                {
                    value: "CYM",
                    label: "Welsh",
                },
                {
                    value: "FRY",
                    label: "Western Frisian",
                },
                {
                    value: "WOL",
                    label: "Wolof",
                },
                {
                    value: "XHO",
                    label: "Xhosa",
                },
                {
                    value: "YID",
                    label: "Yiddish",
                },
                {
                    value: "YOR",
                    label: "Yoruba",
                },
                {
                    value: "ZHA",
                    label: "Zhuang; Chuang",
                },
                {
                    value: "ZUL",
                    label: "Zulu",
                },
                {
                    value: "CUSTOM",
                    label: "Custom",
                },
            ];

        case "MEETING_ROOM_KEYWORD_TYPES":
            return [
                { label: i18n.t(`common::label::${"ID"}`), value: "id" },
                { label: i18n.t(`common::label::${"Name"}`), value: "title" },
            ];

        case "MEETING_STATE_TYPES":
            return [
                { label: i18n.t("common::label::All state"), value: "" },
                { label: i18n.t("common::label::READY"), value: "READY" },
                { label: i18n.t("common::label::Running"), value: "RUNNING" },
                { label: i18n.t("common::label::Stopped"), value: "STOPPED" },
                { label: i18n.t("common::label::Locked"), value: "LOCKED" },
            ];

        case "IMAGE_ASSET_SELECT_MODAL_TYPES":
            return {
                ADD_POSTERS: i18n.t(`common::label::Add {{name}}`, { name: i18n.t(`common::label::Posters`) }),
            };

        case "CAPTION_ASSET_SELECT_MODAL_TYPES":
            return {
                ADD_CAPTIONS: i18n.t(`common::label::Add {{name}}`, { name: i18n.t(`common::label::Captions`) }),
            };

        case "ASSET_SEARCH_MEDIA_TYPES":
            return [
                { value: "", label: i18n.t(`common::label::${"All media type"}`) },
                { value: "VIDEO", label: i18n.t(`common::label::${"Video"}`) },
                { value: "AUDIO", label: i18n.t(`common::label::${"Audio"}`) },
                { value: "IMAGE", label: i18n.t(`common::label::${"Image"}`) },
                { value: "CAPTION", label: i18n.t(`common::label::${"Caption"}`) },
                { value: "FILE", label: i18n.t(`common::label::${"File"}`) },
                // Deprecated
                { value: "TEXT", label: i18n.t(`common::label::${"Text"}`) },
            ];

        case "ASSET_INGEST_TYPES":
            return [
                { value: "", label: i18n.t(`common::label::${"All ingest type"}`) },
                { value: "TRANSCODED", label: `Transcoded` },
                { value: "DIRECT", label: `Direct` },
                { value: "REMOTE", label: `Remote` },
                { value: "RECORDED", label: `Recorded` },
            ];

        case "ASSET_TYPES_SELECT":
            return [
                { value: "", label: i18n.t(`common::label::${"All types"}`) },
                { value: "MP4", label: "MP4" },
                { value: "HLS", label: "HLS" },
                { value: "DASH", label: "DASH" },
                { value: "MP3", label: "MP3" },
                { value: "THUMBNAILS", label: "THUMBNAILS" },
                { value: "IMAGE", label: "IMAGE" },
                { value: "CAPTION", label: "CAPTION" },
            ];

        case "CAPTION_KINDS":
            return [
                { value: "CAPTION", label: i18n.t(`common::label::${"Caption"}`) },
                { value: "SUBTITLE", label: i18n.t(`common::label::${"Subtitle"}`) },
                { value: "DESCRIPTION", label: i18n.t(`common::label::${"Description"}`) },
                { value: "CHAPTER", label: i18n.t(`common::label::${"Chapter"}`) },
                { value: "METADATA", label: i18n.t(`common::label::${"Metadata"}`) },
            ];

        case "ASSET_KEYWORDS":
            return [
                { value: "id", label: i18n.t(`common::label::${"ID"}`) },
                { value: "name", label: i18n.t(`common::label::${"Name"}`) },
            ];

        case "SEARCH_MP4_ASSET_KEYWORDS":
            return [
                { value: "ID", label: i18n.t(`common::label::${"Asset ID"}`) },
                { value: "NAME", label: i18n.t(`common::label::${"Name"}`) },
            ];

        case "ACTIVE_STATUS":
            return [
                { value: "", label: i18n.t(`common::label::${"All status"}`) },
                { value: "ACTIVE", label: i18n.t(`common::label::${"Active"}`) },
                { value: "INACTIVE", label: i18n.t(`common::label::${"Inactive"}`) },
            ];

        case "ARCHIVED_ASSET_STATUS":
            return [
                { value: "ARCHIVING", label: i18n.t(`common::label::ARCHIVING`) },
                { value: "ARCHIVED", label: i18n.t(`common::label::ARCHIVED`) },
                { value: "RESTORING", label: i18n.t(`common::label::RESTORING`) },
                { value: "RESTORED", label: i18n.t(`common::label::RESTORED`) },
                { value: "ARCHIVE_ERROR", label: i18n.t(`common::label::ARCHIVE_ERROR`) },
                { value: "RESTORE_ERROR", label: i18n.t(`common::label::RESTORE_ERROR`) },
            ];

        case "VIDEO_KEYWORDS":
            return [
                { value: "videoId", label: i18n.t(`common::label::${"Video ID"}`) },
                { value: "tags", label: i18n.t(`common::label::${"Tag"}`) },
            ];

        case "LIST_LIMITS":
            return [
                { value: 20, label: i18n.t("common::label::{{count}} views", { count: 20 }) },
                { value: 50, label: i18n.t("common::label::{{count}} views", { count: 50 }) },
                { value: 100, label: i18n.t("common::label::{{count}} views", { count: 100 }) },
                { value: 150, label: i18n.t("common::label::{{count}} views", { count: 150 }) },
                { value: 200, label: i18n.t("common::label::{{count}} views", { count: 200 }) },
            ];

        case "INTEGRATED_SEARCH_LIST_LIMITS":
            return [
                { value: 10, label: i18n.t("common::label::{{count}} views", { count: 10 }) },
                { value: 20, label: i18n.t("common::label::{{count}} views", { count: 20 }) },
                { value: 50, label: i18n.t("common::label::{{count}} views", { count: 50 }) },
            ];

        case "UPLOAD_FILE_LOCATION_TEXT":
            return {
                LOCAL: i18n.t(`common::label::${"Local"}`),
                S3: "S3",
            };

        case "JOB_STATUS":
            return [
                { value: "READY", label: i18n.t(`common::label::${"READY"}`) },
                { value: "INGESTING", label: i18n.t(`common::label::${"INGESTING"}`) },
                { value: "INGESTED", label: i18n.t(`common::label::${"INGESTED"}`) },
                { value: "SUBMITTED", label: i18n.t(`common::label::${"SUBMITTED"}`) },
                { value: "PROGRESSING", label: i18n.t(`common::label::${"PROGRESSING"}`) },
                { value: "ERROR", label: i18n.t(`common::label::${"ERROR"}`) },
                { value: "COMPLETE", label: i18n.t(`common::label::${"COMPLETE"}`) },
                { value: "CANCELED", label: i18n.t(`common::label::${"CANCELED"}`) },
            ];

        case "UPLOAD_STORAGE_JOB_STATUS":
            return [
                { value: "UPLOADING", label: i18n.t(`common::label::${"UPLOADING"}`) },
                { value: "CANCELED", label: i18n.t(`common::label::${"CANCELED"}`) },
                { value: "COMPLETE", label: i18n.t(`common::label::${"COMPLETE"}`) },
                { value: "ERROR", label: i18n.t(`common::label::${"ERROR"}`) },
            ];

        case "JOB_KEYWORDS":
            return [
                { value: "id", label: i18n.t(`common::label::${"Job ID"}`) },
                { value: "name", label: i18n.t(`common::label::${"Name"}`) },
            ];

        case "LISTINGS_DATES":
            return [
                { label: i18n.t(`common::label::${"Created"}`), value: 1 },
                { label: i18n.t(`common::label::${"Updated"}`), value: 2 },
            ];

        case "LISTINGS_KEYWORD":
            return [
                { label: i18n.t(`common::label::${"ID"}`), value: "id" },
                { label: i18n.t(`common::label::${"Name"}`), value: "name" },
            ];

        case "LISTINGS_STATUS":
            return [
                { label: i18n.t(`common::label::${"ALL"}`), value: "" },
                { label: i18n.t(`common::label::${"Active"}`), value: true },
                { label: i18n.t(`common::label::${"INACTIVE"}`), value: false },
            ];

        case "LISTINGS_RECURRING_CUSTOM_TYPES":
            return [
                { label: i18n.t(`common::label::${"Dailys"}`), value: "dailys" },
                { label: i18n.t(`common::label::${"Weeks"}`), value: "weeks" },
                { label: i18n.t(`common::label::${"Months"}`), value: "months" },
                // {label: 'Interval', value: 'interval'},
            ];

        case "LISTINGS_KEYWORD_FOR_WRITER":
            return [
                { label: i18n.t(`common::label::${"Created"}`), value: "creator" },
                { label: i18n.t(`common::label::${"Updated"}`), value: "modifier" },
            ];

        case "FILE_SOURCE_FLAGS":
            return [{ label: "HLS", value: "HLSVOD" }];

        case "TIME_REMAINING_OPTIONS":
            return [
                { label: i18n.t(`common::label::${"None"}`), value: "NONE" },
                { label: i18n.t(`common::label::${"Loop"}`), value: "REPEAT" },
                { label: i18n.t(`common::label::${"Base video"}`), value: "BASEVIDEO" },
            ];

        case "STREAMS_TYPE":
            return [
                { label: i18n.t(`common::label::${"All type"}`), value: "" },
                { label: "RTP(PUSH)", value: "RTP_PUSH" },
                { label: "RTMP(PUSH)", value: "RTMP_PUSH" },
                { label: "RTMP(PULL)", value: "RTMP_PULL" },
            ];

        case "STREAMS_TYPE_CREATE":
            return [
                { label: "RTMP(PUSH)", value: "RTMP_PUSH" },
                { label: "RTP(PUSH)", value: "RTP_PUSH" },
            ];

        case "STREAMS_KEYWORD_TYPE":
            return [
                { label: i18n.t(`common::label::${"ID"}`), value: "id" },
                { label: i18n.t(`common::label::${"Name"}`), value: "name" },
            ];

        case "STREAMS_STATUS":
            return [
                { label: i18n.t(`common::label::${"All status"}`), value: "" },
                { label: i18n.t(`common::label::${"Active"}`), value: "ACTIVE" },
                { label: i18n.t(`common::label::${"Inactive"}`), value: "INACTIVE" },
            ];

        case "LINEUP_REPEAT_CYCLE_PERIOD":
            return [{ value: i18n.t(`common::label::${"weekly"}`), label: "weekly" }];

        case "LINEUP_REPEAT_CYCLE_TERM":
            return [{ value: 1, label: "1" }];

        case "RECURRING_WEEKS":
            return [
                { value: "1", label: i18n.t(`common::label::${"1st"}`) },
                { value: "2", label: i18n.t(`common::label::${"2nd"}`) },
                { value: "3", label: i18n.t(`common::label::${"3rd"}`) },
                { value: "4", label: i18n.t(`common::label::${"4th"}`) },
                { value: "5", label: i18n.t(`common::label::${"5th"}`) },
                { value: "6", label: i18n.t(`common::label::${"6th"}`) },
            ];

        case "RECURRING_DAY_OF_THE_WEEK":
            return [
                {
                    value: "0",
                    label: i18n.t(`common::label::${"Sun"}`),
                    displayName: i18n.t(`common::label::${"Sunday"}`),
                },
                {
                    value: "1",
                    label: i18n.t(`common::label::${"Mon"}`),
                    displayName: i18n.t(`common::label::${"Monday"}`),
                },
                {
                    value: "2",
                    label: i18n.t(`common::label::${"Tue"}`),
                    displayName: i18n.t(`common::label::${"Tuesday"}`),
                },
                {
                    value: "3",
                    label: i18n.t(`common::label::${"Wed"}`),
                    displayName: i18n.t(`common::label::${"Wednesday"}`),
                },
                {
                    value: "4",
                    label: i18n.t(`common::label::${"Thur"}`),
                    displayName: i18n.t(`common::label::${"Thursday"}`),
                },
                {
                    value: "5",
                    label: i18n.t(`common::label::${"Fri"}`),
                    displayName: i18n.t(`common::label::${"Friday"}`),
                },
                {
                    value: "6",
                    label: i18n.t(`common::label::${"Sat"}`),
                    displayName: i18n.t(`common::label::${"Saturday"}`),
                },
            ];

        case "RECURRING_MONTHS":
            return [
                { value: "1", label: "1" },
                { value: "2", label: "2" },
                { value: "3", label: "3" },
                { value: "4", label: "4" },
                { value: "5", label: "5" },
                { value: "6", label: "6" },
                { value: "7", label: "7" },
                { value: "8", label: "8" },
                { value: "9", label: "9" },
                { value: "10", label: "10" },
                { value: "11", label: "11" },
                { value: "12", label: "12" },
            ];

        case "PROGRAMMES_KEYWORD":
            return [
                { label: i18n.t(`common::label::${"Title"}`), value: "title" },
                { label: i18n.t(`common::label::${"ID"}`), value: "id" },
                { label: i18n.t(`common::label::${"Created"}`), value: "1" },
                { label: i18n.t(`common::label::${"Updated"}`), value: "2" },
            ];

        case "ERRORS":
            return [{ code: "001", value: "Bad access path." }];

        case "CHANNEL_TYPES":
            return [
                {
                    label: i18n.t(`common::label::${"All type"}`),
                    value: "",
                },
                {
                    label: i18n.t(`common::label::${"Linear"}`),
                    value: "LINEAR",
                },
                {
                    label: i18n.t(`common::label::${"Event"}`),
                    value: "EVENT",
                },
            ];

        case "CHANNEL_REDUNDANCY_LEVEL":
            return [
                {
                    label: i18n.t(`common::label::${"Single"}`),
                    value: "SINGLE",
                },
                {
                    label: i18n.t(`common::label::${"Dual"}`),
                    value: "DUAL",
                },
            ];

        case "CHANNEL_STATUS":
            return [
                {
                    label: i18n.t(`common::label::${"Draft"}`),
                    value: "DRAFT",
                },
                {
                    label: i18n.t(`common::label::${"Approval requested"}`),
                    value: "APPROVAL_REQUESTED",
                },
                {
                    label: i18n.t(`common::label::${"Approval in progress"}`),
                    value: "APPROVAL_IN_PROGRESS",
                },
                {
                    label: i18n.t(`common::label::${"Approved"}`),
                    value: "APPROVED",
                },
                {
                    label: i18n.t(`common::label::${"Rejected"}`),
                    value: "REJECTED",
                },
                {
                    label: i18n.t(`common::label::${"Return requested"}`),
                    value: "RETURN_REQUESTED",
                },
                {
                    label: i18n.t(`common::label::${"Return in progress"}`),
                    value: "RETURN_IN_PROGRESS",
                },
                {
                    label: i18n.t(`common::label::${"Returned"}`),
                    value: "RETURNED",
                },
                {
                    label: i18n.t(`common::label::${"Reclaim in progress"}`),
                    value: "RECLAIM_IN_PROGRESS",
                },
                {
                    label: i18n.t(`common::label::${"Reclaimed"}`),
                    value: "RECLAIMED",
                },
            ];

        case "ADMIN_CHANNEL_STATUS":
            return [
                {
                    label: i18n.t(`common::label::${"All approval status"}`),
                    value: "",
                },
                {
                    label: i18n.t(`common::label::${"Approval requested"}`),
                    value: "APPROVAL_REQUESTED",
                },
                {
                    label: i18n.t(`common::label::${"Approval in progress"}`),
                    value: "APPROVAL_IN_PROGRESS",
                },
                {
                    label: i18n.t(`common::label::${"Approved"}`),
                    value: "APPROVED",
                },
                {
                    label: i18n.t(`common::label::${"Rejected"}`),
                    value: "REJECTED",
                },
                {
                    label: i18n.t(`common::label::${"Return requested"}`),
                    value: "RETURN_REQUESTED",
                },
                {
                    label: i18n.t(`common::label::${"Return in progress"}`),
                    value: "RETURN_IN_PROGRESS",
                },
                {
                    label: i18n.t(`common::label::${"Returned"}`),
                    value: "RETURNED",
                },
                {
                    label: i18n.t(`common::label::${"Reclaim in progress"}`),
                    value: "RECLAIM_IN_PROGRESS",
                },
                {
                    label: i18n.t(`common::label::${"Reclaimed"}`),
                    value: "RECLAIMED",
                },
                {
                    label: i18n.t(`common::label::${"Draft"}`),
                    value: "DRAFT",
                },
            ];

        case "CHANNEL_STATES":
            return [
                {
                    label: i18n.t(`common::label::${"All state"}`),
                    value: "",
                },
                {
                    label: i18n.t(`common::label::${"Stopping"}`),
                    value: "STOPPING",
                },
                {
                    label: i18n.t(`common::label::${"Stopped"}`),
                    value: "STOPPED",
                },
                {
                    label: i18n.t(`common::label::${"Starting"}`),
                    value: "STARTING",
                },
                {
                    label: i18n.t(`common::label::${"Running"}`),
                    value: "RUNNING",
                },
                {
                    label: i18n.t(`common::label::${"Deleted"}`),
                    value: "DELETED",
                },
                {
                    label: i18n.t(`common::label::${"Unknown"}`),
                    value: "UNKNOWN",
                },
            ];

        case "RECORDING_STATES":
            return [
                {
                    label: i18n.t(`common::label::${"All state"}`, "All state"),
                    value: "",
                },
                {
                    label: i18n.t(`common::label::${"Submitted"}`, "Submitted"),
                    value: "SUBMITTED",
                },
                {
                    label: i18n.t(`common::label::${"Progressing"}`, "Progressing"),
                    value: "PROGRESSING",
                },
                {
                    label: i18n.t(`common::label::${"Completed"}`, "Completed"),
                    value: "COMPLETED",
                },
                {
                    label: i18n.t(`common::label::${"Failed"}`, "Failed"),
                    value: "FAILED",
                },
            ];

        case "CHANNEL_SOURCES":
            return [
                {
                    label: i18n.t(`common::label::${"All source"}`),
                    value: "",
                },
                {
                    label: i18n.t(`common::label::${"None"}`),
                    value: "NONE",
                },
                {
                    label: i18n.t(`common::label::${"Listing"}`),
                    value: "LISTING",
                },
                {
                    label: i18n.t(`common::label::${"Stream"}`),
                    value: "STREAM",
                },
            ];

        case "ADMIN_USERS_KEYWORD_TYPE":
            return [
                {
                    label: i18n.t(`common::label::${"Name"}`),
                    value: "name",
                },
                {
                    label: i18n.t(`common::label::${"Username"}`),
                    value: "username",
                },
                {
                    label: i18n.t(`common::label::${"Megazone ID"}`),
                    value: "megazoneId",
                },
            ];

        case "ADMIN_USERS_KEYWORD_TYPE_V2":
            return [
                {
                    label: i18n.t(`common::label::${"Name"}`),
                    value: "name",
                },
                {
                    label: i18n.t(`common::label::${"Username"}`),
                    value: "username",
                },
                {
                    label: i18n.t(`common::label::${"Megazone ID"}`),
                    value: "externalId",
                },
            ];

        case "ADMIN_EVENT_TRIGGER_KEYWORD_TYPE":
            return [
                {
                    label: i18n.t(`common::label::${"Name"}`),
                    value: "name",
                },
                {
                    label: i18n.t(`common::label::${"ID"}`),
                    value: "id",
                },
            ];

        case "ADMIN_EVENT_TRIGGER_EVENT_TYPE":
            return [
                {
                    label: i18n.t(`common::label::${"All event type"}`),
                    value: "",
                },
                {
                    label: i18n.t(`common::label::${"Ingest"}`),
                    value: "INGEST",
                },
            ];

        case "ADMIN_TEAMS_KEYWORD_TYPE":
            return [
                {
                    label: i18n.t(`common::label::${"Name"}`),
                    value: "name",
                },
            ];
        case "ADMIN_PROJECTS_KEYWORD_TYPE":
            return [
                {
                    label: i18n.t(`common::label::${"Name"}`),
                    value: "name",
                },
            ];

        case "ADMIN_ROLES_KEYWORD_TYPE":
            return [
                {
                    label: i18n.t(`common::label::${"Name"}`),
                    value: "name",
                },
                {
                    label: i18n.t(`common::label::${"User ID"}`),
                    value: "userId",
                },
                {
                    label: i18n.t(`common::label::${"Group ID"}`),
                    value: "teamId",
                },
            ];

        case "ADMIN_CATEGORIES_STATUS":
            return [
                {
                    label: i18n.t(`common::label::${"All status"}`),
                    value: "",
                },
                {
                    label: i18n.t(`common::label::${"Active"}`),
                    value: "ACTIVE",
                },
                {
                    label: i18n.t(`common::label::${"INACTIVE"}`),
                    value: "INACTIVE",
                },
            ];

        case "ADMIN_USER_TABS":
            return [
                {
                    label: i18n.t(`common::label::${"Roles"}`),
                    value: "roles",
                },
                {
                    label: i18n.t(`common::label::${"Groups"}`),
                    value: "teams",
                },
                {
                    label: i18n.t(`common::label::${"Audit-Logs"}`),
                    value: "audits",
                },
            ];

        case "ADMIN_PROJECTS_TABS":
            return [
                {
                    label: i18n.t(`common::label::${"Groups"}`),
                    value: "teams",
                },
                {
                    label: i18n.t(`common::label::${"Users"}`),
                    value: "users",
                },
            ];

        case "ADMIN_TEAMS_TABS":
            return [
                {
                    label: i18n.t(`common::label::${"Roles"}`),
                    value: "roles",
                },
                {
                    label: i18n.t(`common::label::${"Members"}`),
                    value: "members",
                },
            ];

        case "ADMIN_ROLES_SCOPE_TYPE":
            return [
                {
                    label: i18n.t(`common::label::${"Hub scope"}`),
                    value: "station",
                },
                {
                    label: i18n.t(`common::label::${"Project scope"}`),
                    value: "stage",
                },
            ];

        case "ADMIN_ROLE_TABS":
            return [
                {
                    label: i18n.t(`common::label::${"Users"}`),
                    value: "users",
                    active: false,
                },
                {
                    label: i18n.t(`common::label::${"Groups"}`),
                    value: "teams",
                    active: false,
                },
                {
                    label: i18n.t(`common::label::${"Permissions"}`),
                    value: "permissions",
                },
            ];

        case "CATEGORY_TYPES":
            return {
                ASSET: "Asset",
                VIDEO: "Video",
                PEOPLE: "People",
                CHANNEL: "Channel",
                MEETING: "Meeting",
                MUSIC: "Music",
                PHOTO: "Photo",
                METADATA_TEMPLATE: "MetadataTemplate",
            };

        case "LINEUP_PRIORITY_TYPE":
            return [
                { label: i18n.t(`common::label::Highest`), value: 99 },
                { label: i18n.t(`common::label::High`), value: 75 },
                { label: i18n.t(`common::label::Medium`), value: 50 },
                { label: i18n.t(`common::label::Low`), value: 25 },
                { label: i18n.t(`common::label::Lowest`), value: 0 },
            ];

        case "METADATA_CUSTOM_FIELD_TYPE":
            return [
                {
                    label: i18n.t(`common::label::${"Text field"}`),
                    value: "TEXT_FIELD",
                },
                {
                    label: i18n.t(`common::label::${"Text area"}`),
                    value: "TEXT_AREA",
                },
                {
                    label: `${i18n.t(`common::label::${"Date picker"}`)} (${i18n.t(`common::label::${"Single"}`)})`,
                    value: "DATE_PICKER_SINGLE",
                },
                {
                    label: `${i18n.t(`common::label::${"Date picker"}`)} (${i18n.t(`common::label::${"Range"}`)})`,
                    value: "DATE_PICKER_RANGE",
                },
                {
                    label: i18n.t(`common::label::${"Number"}`),
                    value: "NUMBER",
                },
                {
                    label: `${i18n.t(`common::label::${"Select"}`)} (${i18n.t(`common::label::${"Single"}`)})`,
                    value: "SELECT_SINGLE",
                    version: VERSION_CM_6286_METADATA_SELECT_FIELD,
                },
                {
                    label: `${i18n.t(`common::label::${"Select"}`)} (${i18n.t(`common::label::${"Multiple"}`)})`,
                    value: "SELECT_MULTI",
                    version: VERSION_CM_6286_METADATA_SELECT_FIELD,
                },
            ];

        case "FILE_VALIDATE_ERROR_MESSAGE":
            return {
                INVALID_FILE: i18n.t(`common::msg::${"The file is not valid."}`),
                NOT_ALLOWED_URL_PATTERN: i18n.t(`common::msg::${"This is not a valid URL pattern."}`),
                NOT_ALLOWED_EXTENSION: i18n.t(`common::msg::${"This file format is not supported."}`),
                UNAUTHORIZED: i18n.t(`common::msg::${"Access denied."}`),
                INVALID_UPLOAD_TARGET_PATH: i18n.t(
                    `common::msg::${"Ingest path setting for pipeline is required. Please contact your manager."}`,
                ),
                NOT_FOUND_FILE: i18n.t(`common::msg::${"The same file could not be found"}`),
            };

        case "JSON_VIEW_TYPES":
            return {
                TREE: "Tree",
                TEXT: "Text",
            };

        case "PATH":
            return {
                HOME: "/dashboard",
                ADMIN: "/admin/space/users",
            };

        case "ANALYSIS_TYPES":
            return {
                CONTENT_MODERATION: "ContentModeration",
                CELEBRITY_RECOGNITION: "CelebrityRecognition",
                FACE_SEARCH: "FaceSearch",
                LABEL_DETECTION: "LabelDetection",
            };

        case "RIGHT_SIDE_BAR_TYPES":
            return {
                MY_JOB: "My Jobs",
                LISTINGS_COPY_TO: "Copy To",
                LISTINGS_CREATE_LINEUP_ASSET: "Listings Create Lineup Asset",
                LISTINGS_CREATE_LINEUP_STREAM: "Listings Create Lineup Stream",
                COMMENT: "Comment",
            };

        case "SUPPORTED_AUDIO_EXTENSIONS":
            // TODO: 지원 가능한 음성 파일 타입 추가
            return [".mp3", ".webm", ".wma", ".act"];

        case "ACCEPT_FILE_EXTENSIONS_IMAGE":
            return [".png", ".gif", ".jpg", ".jpeg"];
        case "S3_STORAGE_CLASS":
            return [
                { label: i18n.t(`common::label::All Storage Class`), value: "" },
                { label: i18n.t(`common::label::Standard`), value: "STANDARD" },
                { label: i18n.t(`common::label::Reduced Redundancy`), value: "REDUCED_REDUNDANCY" },
                { label: i18n.t(`common::label::Stanard-IA`), value: "STANDARD_IA" },
                { label: i18n.t(`common::label::One Zone-IA`), value: "ONEZONE_IA" },
                { label: i18n.t(`common::label::Intelligent Tiering`), value: "INTELLIGENT_TIERING" },
                { label: i18n.t(`common::label::Glacier`), value: "GLACIER" },
                { label: i18n.t(`common::label::Deep Archive`), value: "DEEP_ARCHIVE" },
                { label: i18n.t(`common::label::Outposts`), value: "OUTPOSTS" },
            ];
        case "S3_STORAGE_CLASS_SELECT":
            return [
                { label: i18n.t(`common::label::Standard`), value: "STANDARD" },
                { label: i18n.t(`common::label::Reduced Redundancy`), value: "REDUCED_REDUNDANCY" },
                { label: i18n.t(`common::label::Stanard-IA`), value: "STANDARD_IA" },
                { label: i18n.t(`common::label::One Zone-IA`), value: "ONEZONE_IA" },
                { label: i18n.t(`common::label::Intelligent Tiering`), value: "INTELLIGENT_TIERING" },
                { label: i18n.t(`common::label::Glacier`), value: "GLACIER" },
                { label: i18n.t(`common::label::Deep Archive`), value: "DEEP_ARCHIVE" },
                { label: i18n.t(`common::label::Outposts`), value: "OUTPOSTS" },
            ];
        case "ARCHIVE_S3_STORAGE_CLASS_SELECT":
            return [
                {
                    label: i18n.t(`common::label::${"All Storage Class"}`),
                    value: "",
                },
                { label: i18n.t(`common::label::Glacier`), value: "GLACIER" },
                { label: i18n.t(`common::label::Deep Archive`), value: "DEEP_ARCHIVE" },
            ];
        case "VIDEO_SEARCH_TYPES":
            return [
                { value: "id", label: i18n.t(`common::label::${"ID"}`) },
                { value: "name", label: i18n.t(`common::label::${"Name"}`) },
            ];
        case "VALUE_OPERATOR":
            return [
                { label: i18n.t(`common::label::Contains`), value: "CONTAINS" },
                { label: i18n.t(`common::label::Range`), value: "RANGE" },
                { label: i18n.t(`common::label::Duration range`), value: "RANGE_DURATION" },
                { label: i18n.t(`common::label::Date range`), value: "RANGE_DATE" },
                { label: i18n.t(`common::label::Greater than`), value: "GREATER_THAN" },
                { label: i18n.t(`common::label::Less than`), value: "LESS_THAN" },
            ];
        case "SHARE_REQUEST_STATUS":
            return {
                REQUESTED: "APPROVAL_REQUESTED",
                RECLAIMED: "RE_APPROVAL_REQUESTED",
                APPROVED: "APPROVED",
                REJECTED: "REJECTED",
                CANCELED: "CANCELED",
                EXPIRED: "REQUEST_EXPIRED",
            };
        case "VOCABULARY_LANGUAGE":
            return [
                { value: "en-US", label: i18n.t(`common::label::English (en-US)`, `English (en-US)`) },
                { value: "ko-KR", label: i18n.t(`common::label::Korean (ko-KR)`, `Korean (ko-KR)`) },
            ];
        case "VOCABULARY_STATUS":
            return [
                { value: "DRAFT", label: i18n.t(`common::label::DRAFT`, `DRAFT`) },
                { value: "PROGRESSING", label: i18n.t(`common::label::PROGRESSING`, `PROGRESSING`) },
                { value: "READY", label: i18n.t(`common::label::READY`, `READY`) },
                { value: "ERROR", label: i18n.t(`common::label::ERROR`, `ERROR`) },
            ];
        case "METADATA_EXPORT_STATUS":
            return {
                SUBMITTED: "SUBMITTED",
                PROCESSING: "PROCESSING",
                COMPLETED: "COMPLETED",
                ERROR: "ERROR",
            };

        case "METADATA_EXPORT_STATUS_OPTIONS":
            return [
                {
                    label: i18n.t("common::label::SUBMITTED", `SUBMITTED`),
                    value: "SUBMITTED",
                },
                {
                    label: i18n.t("common::label::PROGRESSING", `PROGRESSING`),
                    value: "PROCESSING",
                },
                {
                    label: i18n.t("common::label::COMPLETED", `COMPLETED`),
                    value: "COMPLETED",
                },
                {
                    label: i18n.t("common::label::ERROR", `ERROR`),
                    value: "ERROR",
                },
            ];

        default:
            break;
    }
}

export const CREATE_JOB_AUDIO_LANGUAGE = [
    // undefined 제거
    // {
    //   "value": "UNDEFINED",
    //   "label": "Undefined"
    // },
    {
        value: "ENG",
        label: "English",
    },
    {
        value: "SPA",
        label: "Spanish; Castilian",
    },
    {
        value: "FRA",
        label: "French",
    },
    {
        value: "DEU",
        label: "German (DEU)",
    },
    {
        value: "GER",
        label: "German (GER)",
    },
    {
        value: "ZHO",
        label: "Chinese",
    },
    {
        value: "ARA",
        label: "Arabic",
    },
    {
        value: "HIN",
        label: "Hindi",
    },
    {
        value: "JPN",
        label: "Japanese",
    },
    {
        value: "RUS",
        label: "Russian",
    },
    {
        value: "POR",
        label: "Portuguese",
    },
    {
        value: "ITA",
        label: "Italian",
    },
    {
        value: "URD",
        label: "Urdu",
    },
    {
        value: "VIE",
        label: "Vietnamese",
    },
    {
        value: "KOR",
        label: "Korean",
    },
    {
        value: "PAN",
        label: "Panjabi; Punjabi",
    },
    {
        value: "ABK",
        label: "Abkhazian",
    },
    {
        value: "AAR",
        label: "Afar",
    },
    {
        value: "AFR",
        label: "Afrikaans",
    },
    {
        value: "AKA",
        label: "Akan",
    },
    {
        value: "SQI",
        label: "Albanian",
    },
    {
        value: "AMH",
        label: "Amharic",
    },
    {
        value: "ARG",
        label: "Aragonese",
    },
    {
        value: "HYE",
        label: "Armenian",
    },
    {
        value: "ASM",
        label: "Assamese",
    },
    {
        value: "AVA",
        label: "Avaric",
    },
    {
        value: "AVE",
        label: "Avestan",
    },
    {
        value: "AYM",
        label: "Aymara",
    },
    {
        value: "AZE",
        label: "Azerbaijani",
    },
    {
        value: "BAM",
        label: "Bambara",
    },
    {
        value: "BAK",
        label: "Bashkir",
    },
    {
        value: "EUS",
        label: "Basque",
    },
    {
        value: "BEL",
        label: "Belarusian",
    },
    {
        value: "BEN",
        label: "Bengali",
    },
    {
        value: "BIH",
        label: "Bihari languages",
    },
    {
        value: "BIS",
        label: "Bislama",
    },
    {
        value: "BOS",
        label: "Bosnian",
    },
    {
        value: "BRE",
        label: "Breton",
    },
    {
        value: "BUL",
        label: "Bulgarian",
    },
    {
        value: "MYA",
        label: "Burmese",
    },
    {
        value: "CAT",
        label: "Catalan; Valencian",
    },
    {
        value: "KHM",
        label: "Central Khmer",
    },
    {
        value: "CHA",
        label: "Chamorro",
    },
    {
        value: "CHE",
        label: "Chechen",
    },
    {
        value: "NYA",
        label: "Chichewa; Chewa; Nyanja",
    },
    {
        value: "CHU",
        label: "Church Slavic; Old Bulgarian",
    },
    {
        value: "CHV",
        label: "Chuvash",
    },
    {
        value: "COR",
        label: "Cornish",
    },
    {
        value: "COS",
        label: "Corsican",
    },
    {
        value: "CRE",
        label: "Cree",
    },
    {
        value: "HRV",
        label: "Croatian",
    },
    {
        value: "CES",
        label: "Czech",
    },
    {
        value: "DAN",
        label: "Danish",
    },
    {
        value: "DIV",
        label: "Divehi; Dhivehi; Maldivian",
    },
    {
        value: "NLD",
        label: "Dutch; Flemish",
    },
    {
        value: "DZO",
        label: "Dzongkha",
    },
    {
        value: "ENM",
        label: "English, Middle (1100-1500)",
    },
    {
        value: "EPO",
        label: "Esperanto",
    },
    {
        value: "EST",
        label: "Estonian",
    },
    {
        value: "EWE",
        label: "Ewe",
    },
    {
        value: "FAO",
        label: "Faroese",
    },
    {
        value: "FIJ",
        label: "Fijian",
    },
    {
        value: "FIN",
        label: "Finnish",
    },
    {
        value: "FRM",
        label: "French, Middle (1400-1600)",
    },
    {
        value: "FUL",
        label: "Fulah",
    },
    {
        value: "GLA",
        label: "Gaelic; Scottish Gaelic",
    },
    {
        value: "GLG",
        label: "Galician",
    },
    {
        value: "LUG",
        label: "Ganda",
    },
    {
        value: "KAT",
        label: "Georgian",
    },
    {
        value: "ELL",
        label: "Greek, Modern (1453-)",
    },
    {
        value: "GRN",
        label: "Guarani",
    },
    {
        value: "GUJ",
        label: "Gujarati",
    },
    {
        value: "HAT",
        label: "Haitian; Haitian Cr",
    },
    {
        value: "HAU",
        label: "Hausa",
    },
    {
        value: "HEB",
        label: "Hebrew",
    },
    {
        value: "HER",
        label: "Herero",
    },
    {
        value: "HMO",
        label: "Hiri Motu",
    },
    {
        value: "HUN",
        label: "Hungarian",
    },
    {
        value: "ISL",
        label: "Icelandic",
    },
    {
        value: "IDO",
        label: "Ido",
    },
    {
        value: "IBO",
        label: "Igbo",
    },
    {
        value: "IND",
        label: "Indonesian",
    },
    {
        value: "INA",
        label: "Interlingua (IALA)",
    },
    {
        value: "ILE",
        label: "Interlingue; Occidental",
    },
    {
        value: "IKU",
        label: "Inuktitut",
    },
    {
        value: "IPK",
        label: "Inupiaq",
    },
    {
        value: "GLE",
        label: "Irish",
    },
    {
        value: "JAV",
        label: "Javanese",
    },
    {
        value: "KAL",
        label: "Kalaallisut; Greenlandic",
    },
    {
        value: "KAN",
        label: "Kannada",
    },
    {
        value: "KAU",
        label: "Kanuri",
    },
    {
        value: "KAS",
        label: "Kashmiri",
    },
    {
        value: "KAZ",
        label: "Kazakh",
    },
    {
        value: "KIK",
        label: "Kikuyu; Gikuyu",
    },
    {
        value: "KIN",
        label: "Kinyarwanda",
    },
    {
        value: "KIR",
        label: "Kirghiz; Kyrgyz",
    },
    {
        value: "KOM",
        label: "Komi",
    },
    {
        value: "KON",
        label: "Kongo",
    },
    {
        value: "KUA",
        label: "Kuanyama; Kwanyama",
    },
    {
        value: "KUR",
        label: "Kurdish",
    },
    {
        value: "LAO",
        label: "Lao",
    },
    {
        value: "LAT",
        label: "Latin",
    },
    {
        value: "LAV",
        label: "Latvian",
    },
    {
        value: "LIM",
        label: "Limburgan; Limburger; Limburgish",
    },
    {
        value: "LIN",
        label: "Lingala",
    },
    {
        value: "LIT",
        label: "Lithuanian",
    },
    {
        value: "LUB",
        label: "Luba-Katanga",
    },
    {
        value: "LTZ",
        label: "Luxembourgish; Letzeburgesch",
    },
    {
        value: "MKD",
        label: "Macedonian",
    },
    {
        value: "MLG",
        label: "Malagasy",
    },
    {
        value: "MSA",
        label: "Malay",
    },
    {
        value: "MAL",
        label: "Malayalam",
    },
    {
        value: "MLT",
        label: "Malte",
    },
    {
        value: "GLV",
        label: "Manx",
    },
    {
        value: "MRI",
        label: "Maori",
    },
    {
        value: "MAR",
        label: "Marathi",
    },
    {
        value: "MAH",
        label: "Marshallese",
    },
    {
        value: "MON",
        label: "Mongolian",
    },
    {
        value: "NAU",
        label: "Nauru",
    },
    {
        value: "NAV",
        label: "Navajo; Navaho",
    },
    {
        value: "NDE",
        label: "Ndebele, North; No",
    },
    {
        value: "NBL",
        label: "Ndebele, South; South Ndebe",
    },
    {
        value: "NDO",
        label: "Ndonga",
    },
    {
        value: "NEP",
        label: "Nepali",
    },
    {
        value: "SME",
        label: "Northern Sami",
    },
    {
        value: "NOR",
        label: "Norwegian",
    },
    {
        value: "NOB",
        label: "Norwegian Bokmål",
    },
    {
        value: "NNO",
        label: "Norwegian Nynorsk; Nyn",
    },
    {
        value: "OCI",
        label: "Occitan (post 1500); Provençal",
    },
    {
        value: "OJI",
        label: "Ojibwa",
    },
    {
        value: "ORI",
        label: "Oriya",
    },
    {
        value: "ORM",
        label: "Oromo",
    },
    {
        value: "OSS",
        label: "Ossetian; Ossetic",
    },
    {
        value: "PLI",
        label: "Pali",
    },
    {
        value: "FAS",
        label: "Persian",
    },
    {
        value: "POL",
        label: "Polish",
    },
    {
        value: "PUS",
        label: "Pushto; Pashto",
    },
    {
        value: "QUE",
        label: "Quechua",
    },
    {
        value: "QAA",
        label: "Reserved",
    },
    {
        value: "RON",
        label: "Romanian; Moldavian; Moldovan",
    },
    {
        value: "ROH",
        label: "Romansh",
    },
    {
        value: "RUN",
        label: "Rundi",
    },
    {
        value: "SMO",
        label: "Samoan",
    },
    {
        value: "SAG",
        label: "Sango",
    },
    {
        value: "SAN",
        label: "Sanskrit",
    },
    {
        value: "SRD",
        label: "Sardinian",
    },
    {
        value: "SRB",
        label: "Serbian",
    },
    {
        value: "SNA",
        label: "Shona",
    },
    {
        value: "III",
        label: "Sichuan Yi; Nuosu",
    },
    {
        value: "SND",
        label: "Sindhi",
    },
    {
        value: "SIN",
        label: "Sinhala; Sinhalese",
    },
    {
        value: "SLK",
        label: "Slovak",
    },
    {
        value: "SLV",
        label: "Slovenian",
    },
    {
        value: "SOM",
        label: "Somali",
    },
    {
        value: "SOT",
        label: "Sotho, Southern",
    },
    {
        value: "SUN",
        label: "Sundanese",
    },
    {
        value: "SWA",
        label: "Swahili",
    },
    {
        value: "SSW",
        label: "Swati",
    },
    {
        value: "SWE",
        label: "Swedish",
    },
    {
        value: "TGL",
        label: "Tagalog",
    },
    {
        value: "TAH",
        label: "Tahitian",
    },
    {
        value: "TGK",
        label: "Tajik",
    },
    {
        value: "TAM",
        label: "Tamil",
    },
    {
        value: "TAT",
        label: "Tatar",
    },
    {
        value: "TEL",
        label: "Telugu",
    },
    {
        value: "THA",
        label: "Thai",
    },
    {
        value: "BOD",
        label: "Tibetan",
    },
    {
        value: "TIR",
        label: "Tigrinya",
    },
    {
        value: "TON",
        label: "Tonga (Tonga Islands)",
    },
    {
        value: "TSO",
        label: "Tsonga",
    },
    {
        value: "TSN",
        label: "Tswana",
    },
    {
        value: "TUR",
        label: "Turkish",
    },
    {
        value: "TUK",
        label: "Turkmen",
    },
    {
        value: "TWI",
        label: "Twi",
    },
    {
        value: "UIG",
        label: "Uighur; Uyghur",
    },
    {
        value: "UKR",
        label: "Ukrainian",
    },
    {
        value: "UZB",
        label: "Uzbek",
    },
    {
        value: "VEN",
        label: "Venda",
    },
    {
        value: "VOL",
        label: "Volapük",
    },
    {
        value: "WLN",
        label: "Walloon",
    },
    {
        value: "CYM",
        label: "Welsh",
    },
    {
        value: "FRY",
        label: "Western Frisian",
    },
    {
        value: "WOL",
        label: "Wolof",
    },
    {
        value: "XHO",
        label: "Xhosa",
    },
    {
        value: "YID",
        label: "Yiddish",
    },
    {
        value: "YOR",
        label: "Yoruba",
    },
    {
        value: "ZHA",
        label: "Zhuang; Chuang",
    },
    {
        value: "ZUL",
        label: "Zulu",
    },
    {
        value: "CUSTOM",
        label: "Custom",
    },
];

export const IMAGE_ASSET_SELECT_MODAL_TYPES = {
    ADD_POSTERS: "Add Posters",
};

export const CAPTION_ASSET_SELECT_MODAL_TYPES = {
    ADD_CAPTIONS: "Add Captions",
};

export const UPLOAD_LOCATION = {
    CREATE_ASSET: "createAsset",
    ASSET_DETAIL: "assetDetail",
    UPLOAD_COMMENT_ATTACHMENT: "uploadCommentAttachment",
};

export const COMMON_STATUS = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
};

export const SELECT_STATUS = {
    ContentSchema: {
        ACTIVE: "ACTIVE",
        INACTIVE: "INACTIVE",
    },
    Vocabulary: { DRAFT: "DRAFT", PROCESSING: "PROCESSING", READY: "READY", ERROR: "ERROR" },
};

export const CAPTION_KINDS = [
    { value: "CAPTION", label: i18n.t(`common::label::${"Caption"}`) },
    { value: "SUBTITLE", label: i18n.t(`common::label::${"Subtitle"}`) },
    { value: "DESCRIPTION", label: i18n.t(`common::label::${"Description"}`) },
    { value: "CHAPTER", label: i18n.t(`common::label::${"Chapter"}`) },
    { value: "METADATA", label: i18n.t(`common::label::${"Metadata"}`) },
];

export const ASSET_TYPES = {
    // Video
    MP4: "MP4",
    HLS: "HLS",
    DASH: "DASH",
    // Audio
    MP3: "MP3",
    // Image
    THUMBNAILS: "THUMBNAILS",
    IMAGE: "IMAGE",
    // Caption
    CAPTION: "CAPTION",
    SUBTITLE: "SUBTITLE",
    CHAPTER: "CHAPTER",
    DESCRIPTION: "DESCRIPTION",
    METADATA: "METADATA",
    // File
    FILE: "FILE",
};

export const SEARCH_MP4_ASSET_KEYWORDS = [
    { value: "ID", label: i18n.t(`common::label::${"Asset ID"}`) },
    //{value: 'JOB_ID', label: 'Job ID'},
    { value: "NAME", label: i18n.t(`common::label::${"Name"}`) },
    //,{value: 'TAGS', label: 'Tags'}
];

export const VIDEO_KEYWORDS = [
    { value: "videoId", label: i18n.t(`common::label::${"Video ID"}`) },
    { value: "tags", label: i18n.t(`common::label::${"Tag"}`) },
];

export const LIST_LIMITS = [
    { value: 20, label: i18n.t("common::label::{{count}} views", { count: 20, returnObjects: true }) },
    { value: 50, label: `${i18n.t(`common::label::${"{{count}} views"}`, { count: 50 })})}` },
    { value: 100, label: `${i18n.t(`common::label::${"{{count}} views"}`, { count: 100 })})}` },
    { value: 150, label: `${i18n.t(`common::label::${"{{count}} views"}`, { count: 150 })})}` },
    { value: 200, label: `${i18n.t(`common::label::${"{{count}} views"}`, { count: 200 })})}` },
];

export const UPLOAD_FILE_LOCATION_TYPE = {
    LOCAL: "Local",
    S3: "S3",
    // ASSETS: "Assets"
};

export const INGEST_TYPE = {
    TRANSCODED: "TRANSCODED",
    DIRECT: "DIRECT",
    REMOTE: "REMOTE",
};

export const UPLOAD_STATUS = {
    UPLOADING: "UPLOADING",
    // PROGRESSING: "PROGRESSING",
    COMPLETE: "COMPLETE",
    ERROR: "ERROR",
    CANCELED: "CANCELED",
};

export const COMMON_JOB_STATUS_OPTIONS = [
    { value: "SUBMITTED", label: "SUBMITTED" },
    { value: "RUNNING", label: "PROGRESSING" },
    { value: "CANCELED", label: "CANCELED" },
    { value: "SUCCEED", label: "COMPLETE" },
    { value: "ERROR", label: "ERROR" },
];

export const COMMON_JOB_STATUS = {
    SUBMITTED: "SUBMITTED",
    RUNNING: "RUNNING",
    CANCELED: "CANCELED",
    SUCCEED: "SUCCEED",
    ERROR: "ERROR",
};

export const JOB_KEYWORDS = [
    { value: "id", label: i18n.t(`common::label::${"Job ID"}`) },
    { value: "name", label: i18n.t(`common::label::${"Name"}`) },
];

export const LISTINGS_DATES = [
    { label: i18n.t(`common::label::${"Created"}`), value: 1 },
    { label: i18n.t(`common::label::${"Updated"}`), value: 2 },
];

export const LISTINGS_KEYWORD = [
    { label: i18n.t(`common::label::${"ID"}`), value: "id" },
    { label: i18n.t(`common::label::${"Name"}`), value: "name" },
];

export const LISTINGS_STATUS = [
    { label: i18n.t(`common::label::${"ALL"}`), value: "" },
    { label: i18n.t(`common::label::${"Active"}`), value: true },
    { label: i18n.t(`common::label::${"INACTIVE"}`), value: false },
];

export const LISTINGS_RECURRING_CUSTOM_TYPES = [
    { label: i18n.t(`common::label::${"Dailys"}`), value: "dailys" },
    { label: i18n.t(`common::label::${"Weeks"}`), value: "weeks" },
    { label: i18n.t(`common::label::${"Months"}`), value: "months" },
    // {label: 'Interval', value: 'interval'},
];

export const LISTINGS_KEYWORD_FOR_WRITER = [
    { label: i18n.t(`common::label::${"Created"}`), value: "creator" },
    { label: i18n.t(`common::label::${"Updated"}`), value: "modifier" },
];

export const FILE_SOURCE_FLAGS = [{ label: "HLS", value: "HLSVOD" }];

export const TIME_REMAINING_OPTIONS = [
    { label: i18n.t(`common::label::${"None"}`), value: "NONE" },
    { label: i18n.t(`common::label::${"Loop"}`), value: "REPEAT" },
    { label: i18n.t(`common::label::${"Base video"}`), value: "BASEVIDEO" },
];

// export const LINEUP_PRIORITY = [
//     {label: 'Next lineup', value : 'NEXT'},
//     {label: 'Previous lineup', value : 'PREVIOUS'}
// ]

export const STREAMS_TYPE = [
    { label: i18n.t(`common::label::${"All type"}`), value: "" },
    { label: "RTP(PUSH)", value: "RTP_PUSH" },
    { label: "RTMP(PUSH)", value: "RTMP_PUSH" },
    { label: "RTMP(PULL)", value: "RTMP_PULL" },
];

export const STREAMS_TYPE_CREATE = [
    { label: "RTMP(PUSH)", value: "RTMP_PUSH" },
    { label: "RTP(PUSH)", value: "RTP_PUSH" },
];

export const STREAMS_KEYWORD_TYPE = [
    { label: i18n.t(`common::label::${"ID"}`), value: "id" },
    { label: i18n.t(`common::label::${"Name"}`), value: "name" },
];

export const STREAMS_STATUS = [
    { label: i18n.t(`common::label::${"All status"}`), value: "" },
    { label: i18n.t(`common::label::${"Active"}`), value: "ACTIVE" },
    { label: i18n.t(`common::label::${"Inactive"}`), value: "INACTIVE" },
];

export const VIDEO_CODEC = [
    { label: "H.264", value: "H.264" },
    { label: "H.265", value: "H.265" },
    { label: "MPEG-2", value: "MPEG-2" },
    { label: "Apple Prores 422", value: "Apple Prores 422" },
];

export const LINEUP_REPEAT_CYCLE_PERIOD = [{ value: i18n.t(`common::label::${"weekly"}`), label: "weekly" }];

export const LINEUP_REPEAT_CYCLE_TERM = [{ value: 1, label: "1" }];

export const RECURRING_WEEKS = [
    { value: "1", label: "1st" },
    { value: "2", label: "2nd" },
    { value: "3", label: "3rd" },
    { value: "4", label: "4th" },
    { value: "5", label: "5th" },
    { value: "6", label: "6th" },
];

// TODO : Channel 추가
export const PROPERTY_PANEL_TYPE = {
    ASSET: "Asset",
    ARCHIVED_ASSET: "Archived Assets",
    VIDEO: "Video",
    PEOPLE: "People",
    LISTINGS_LINEUP_DETAIL: "ListingsLineupDetail",
    LISTINGS_ITEM_LIST: "ListingsItemList",
    MUSIC: "Music",
    PHOTO: "Photo",
    STORAGE: "Storage",
    SHARED_STORAGE_WITH: "Storage_with",
    SHARED_STORAGE_BY: "Storage_by",
    SHARED_STORAGE: "Storage Share Box",
    SHARED_ASSET: "Asset Share Box",
    SHARED_ASSET_WITH: "Asset_with",
    SHARED_ASSET_BY: "Asset_by",
    EXTERNAL_SHARED_HISTORY: "External Share",
    COLLECTION: "Collection",
    CUSTOM_CONTENT: "CUSTOM_CONTENT",
};

export const TABLE_LIST_TYPE = {
    ASSET: "Asset",
    ARCHIVED_ASSET: "ArchivedAsset",
    COLLECTION: "Collection",
    VIDEO: "Video",
    CONTENT_SCHEMA: "ContentSchema",
    CUSTOM_CONTENT: "CustomContent",
    VOCABULARY: "Vocabulary",
    ROLE_MANAGEMENT: "RoleManagement",
    USER_MANAGEMENT: "UserManagement",
    TEAM_MANAGEMENT: "TeamManagement",
    METADATA_EXPORTS: "MetadataExports",
};

export const AUTOCOMPLETE_TABLE_LIST_TYPE = {
    AUTOCOMPLETE_TABLE_ASSET: "AutocompleteAsset",
    AUTOCOMPLETE_TABLE_ARCHIVED_ASSET: "AutocompleteArchivedAsset",
    AUTOCOMPLETE_TABLE_COLLECTION: "AutocompleteCollection",
};

export const RECURRING_DAY_OF_THE_WEEK = [
    { value: "0", label: i18n.t(`common::label::${"Sun"}`), displayName: i18n.t(`common::label::${"Sunday"}`) },
    { value: "1", label: i18n.t(`common::label::${"Mon"}`), displayName: i18n.t(`common::label::${"Monday"}`) },
    { value: "2", label: i18n.t(`common::label::${"Tue"}`), displayName: i18n.t(`common::label::${"Tuesday"}`) },
    { value: "3", label: i18n.t(`common::label::${"Wed"}`), displayName: i18n.t(`common::label::${"Wednesday"}`) },
    { value: "4", label: i18n.t(`common::label::${"Thur"}`), displayName: i18n.t(`common::label::${"Thursday"}`) },
    { value: "5", label: i18n.t(`common::label::${"Fri"}`), displayName: i18n.t(`common::label::${"Friday"}`) },
    { value: "6", label: i18n.t(`common::label::${"Sat"}`), displayName: i18n.t(`common::label::${"Saturday"}`) },
];

export const RECURRING_MONTHS = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
];

export const PROGRAMMES_KEYWORD = [
    { label: i18n.t(`common::label::${"Title"}`), value: "title" },
    { label: i18n.t(`common::label::${"ID"}`), value: "id" },
    { label: i18n.t(`common::label::${"Created"}`), value: "1" },
    { label: i18n.t(`common::label::${"Updated"}`), value: "2" },
];

export const ERRORS = [{ code: "001", value: "Bad access path." }];

export const VOD_MIMETYPE = [
    // VIDEO
    ".3gp",
    ".3g2",
    ".3gpp",
    ".asf",
    ".divx",
    ".flv",
    ".f4v",
    ".m2ts",
    ".ts",
    ".mts",
    ".m2t",
    ".mp4",
    ".avi",
    ".m1v",
    ".m2v",
    ".m4v",
    ".mpeg",
    ".vob",
    ".mpg",
    ".trp",
    ".264",
    ".h264",
    ".mov",
    ".mkv",
    ".wmv",
    ".mxf",
    ".gxf",
    ".dv",
    ".xvid",
    ".gxf_mpeg2",
    ".mxf_mpeg2",
    ".y4m",
    ".mpt",
    ".mxfhd",
    ".vmf",
    // '.m3u8',
    // AUDIO
    ".mp3",
    ".mp2a",
    ".mp4a",
    ".mpga",
    ".aac",
    ".m2a",
    ".m3a",
    ".m4a",
    ".oga",
    ".wav",
    ".raw",
    ".lch",
    ".qt",
];

export const VOD_MIMETYPE_ELASTIC_TRANSCODER = [].concat(VOD_MIMETYPE);
export const VOD_MIMETYPE_MEDIACONVERT = [].concat(VOD_MIMETYPE);

export const LIMIT_BYTE = {
    name: 255,
    title: 255,
    description: 2000,
    attribution: 200,
    tag: 150,
    comment: 255,
    descriptionChannel: 2000,
    roleTitle: 50,
    roleDescription: 512,
};

export const POPUP_MODAL_SIZE = {
    width: 600,
    height: 400,
};

export const PUBLIC_URL = process.env.PUBLIC_URL;
// todo:
export const CHANNEL_TYPES = [
    {
        label: i18n.t(`common::label::${"Linear"}`),
        value: "LINEAR",
    },
    {
        label: i18n.t(`common::label::${"Event"}`),
        value: "EVENT",
    },
];

export const CHANNEL_REDUNDANCY_LEVEL = [
    {
        label: i18n.t(`common::label::${"Single"}`),
        value: "SINGLE",
    },
    {
        label: i18n.t(`common::label::${"Dual"}`),
        value: "DUAL",
    },
];

export const IMAGE_FORMAT = [".jpg", ".jpeg", ".png", ".gif"];

export const CHANNEL_STATUS = [
    {
        label: i18n.t(`common::label::${"Draft"}`),
        value: "DRAFT",
    },
    {
        label: i18n.t(`common::label::${"Approval requested"}`),
        value: "APPROVAL_REQUESTED",
    },
    {
        label: i18n.t(`common::label::${"Approval in progress"}`),
        value: "APPROVAL_IN_PROGRESS",
    },
    {
        label: i18n.t(`common::label::${"Approved"}`),
        value: "APPROVED",
    },
    {
        label: i18n.t(`common::label::${"Rejected"}`),
        value: "REJECTED",
    },
    {
        label: i18n.t(`common::label::${"Return requested"}`),
        value: "RETURN_REQUESTED",
    },
    {
        label: i18n.t(`common::label::${"Return in progress"}`),
        value: "RETURN_IN_PROGRESS",
    },
    {
        label: i18n.t(`common::label::${"Returned"}`),
        value: "RETURNED",
    },
    {
        label: i18n.t(`common::label::${"Reclaim in progress"}`),
        value: "RECLAIM_IN_PROGRESS",
    },
    {
        label: i18n.t(`common::label::${"Reclaimed"}`),
        value: "RECLAIMED",
    },
];

export const ADMIN_CHANNEL_STATUS = [
    {
        label: i18n.t(`common::label::${"Approval requested"}`),
        value: "APPROVAL_REQUESTED",
    },
    {
        label: i18n.t(`common::label::${"Approval in progress"}`),
        value: "APPROVAL_IN_PROGRESS",
    },
    {
        label: i18n.t(`common::label::${"Approved"}`),
        value: "APPROVED",
    },
    {
        label: i18n.t(`common::label::${"Rejected"}`),
        value: "REJECTED",
    },
    {
        label: i18n.t(`common::label::${"Return requested"}`),
        value: "RETURN_REQUESTED",
    },
    {
        label: i18n.t(`common::label::${"Return in progress"}`),
        value: "RETURN_IN_PROGRESS",
    },
    {
        label: i18n.t(`common::label::${"Returned"}`),
        value: "RETURNED",
    },
    {
        label: i18n.t(`common::label::${"Reclaim in progress"}`),
        value: "RECLAIM_IN_PROGRESS",
    },
    {
        label: i18n.t(`common::label::${"Reclaimed"}`),
        value: "RECLAIMED",
    },
];

export const CHANNEL_STATES = [
    {
        label: i18n.t(`common::label::${"Stopping"}`),
        value: "STOPPING",
    },
    {
        label: i18n.t(`common::label::${"Stopped"}`),
        value: "STOPPED",
    },
    {
        label: i18n.t(`common::label::${"Starting"}`),
        value: "STARTING",
    },
    {
        label: i18n.t(`common::label::${"Running"}`),
        value: "RUNNING",
    },
    {
        label: i18n.t(`common::label::${"Deleted"}`),
        value: "DELETED",
    },
    {
        label: i18n.t(`common::label::${"Unknown"}`),
        value: "UNKNOWN",
    },
];

export const CHANNEL_SOURCES = [
    {
        label: i18n.t(`common::label::${"All source"}`),
        value: "",
    },
    {
        label: i18n.t(`common::label::${"Listing"}`),
        value: "LISTING",
    },
    {
        label: i18n.t(`common::label::${"Stream"}`),
        value: "STREAM",
    },
];

export const ADMIN_USERS_KEYWORD_TYPE = [
    {
        label: i18n.t(`common::label::${"Name"}`),
        value: "name",
    },
    {
        label: i18n.t(`common::label::${"Username"}`),
        value: "username",
    },
    {
        label: i18n.t(`common::label::${"Megazone ID"}`),
        value: "megazoneId",
    },
];

export const ADMIN_EVENT_TRIGGER_KEYWORD_TYPE = [
    {
        label: i18n.t(`common::label::${"Name"}`),
        value: "name",
    },
    {
        label: i18n.t(`common::label::${"Id"}`),
        value: "id",
    },
];

export const ADMIN_EVENT_TRIGGER_EVENT_TYPE = [
    {
        label: i18n.t(`common::label::${"All event type"}`),
        value: "",
    },
    {
        label: i18n.t(`common::label::${"Ingest"}`),
        value: "INGEST",
    },
];

export const ADMIN_AUTHORITIES_TEAMS_KEYWORD_TYPE = [
    {
        label: i18n.t(`common::label::${"Name"}`),
        value: "name",
    },
];

export const ADMIN_ROLES_KEYWORD_TYPE = [
    {
        label: i18n.t(`common::label::${"Name"}`),
        value: "name",
    },
    {
        label: i18n.t(`common::label::${"User ID"}`),
        value: "userId",
    },
    {
        label: i18n.t(`common::label::${"Group ID"}`),
        value: "teamId",
    },
];

export const ADMIN_CATEGORIES_STATUS = [
    {
        label: i18n.t(`common::label::${"All status"}`),
        value: "",
    },
    {
        label: i18n.t(`common::label::${"Active"}`),
        value: "ACTIVE",
    },
    {
        label: i18n.t(`common::label::${"INACTIVE"}`),
        value: "INACTIVE",
    },
];

export const ADMIN_USER_TABS = [
    {
        label: i18n.t(`common::label::${"Roles"}`),
        value: "roles",
    },
    {
        label: i18n.t(`common::label::${"Groups"}`),
        value: "teams",
    },
    {
        label: i18n.t(`common::label::${"Audit-Logs"}`),
        value: "audits",
    },
];

export const ADMIN_PROJECTS_TABS = [
    {
        label: i18n.t(`common::label::${"Groups"}`),
        value: "teams",
    },
    {
        label: i18n.t(`common::label::${"Users"}`),
        value: "users",
    },
];

export const ADMIN_TEAMS_TABS = [
    {
        label: i18n.t(`common::label::${"Roles"}`),
        value: "roles",
    },
    {
        label: i18n.t(`common::label::${"Members"}`),
        value: "members",
    },
];

export const ADMIN_ROLES_SCOPE_TYPE = [
    {
        label: i18n.t(`common::label::${"Hub scope"}`),
        value: "station",
    },
    {
        label: i18n.t(`common::label::${"Project scope"}`),
        value: "stage",
    },
];

export const ADMIN_ROLE_TABS = [
    {
        label: i18n.t(`common::label::${"Users"}`),
        value: "users",
        active: false,
    },
    {
        label: i18n.t(`common::label::${"Groups"}`),
        value: "teams",
        active: false,
    },
    {
        label: i18n.t(`common::label::${"Permissions"}`),
        value: "permissions",
    },
];

export const RIGHT_SIDE_BAR_TYPES = {
    MY_JOB: "My Jobs",
    LISTINGS_COPY_TO: "Copy To",
    LISTINGS_CREATE_LINEUP_ASSET: "Listings Create Lineup Asset",
    LISTINGS_CREATE_LINEUP_STREAM: "Listings Create Lineup Stream",
    COMMENT: "Comment",
};

export const MEDIA_TYPES = {
    VIDEO: "VIDEO",
    AUDIO: "AUDIO",
    IMAGE: "IMAGE",
    PEOPLE: "PEOPLE",
    TEXT: "TEXT",
    CAPTION: "CAPTION",
    FILE: "FILE",
    // NONE: "NONE",
};

// TODO:Custom Schema 추가
export const CATEGORY_TYPES = {
    ASSET: "Asset",
    VIDEO: "Video",
    PEOPLE: "People",
    MEETING: "Meeting",
    CHANNEL: "Channel",
    MUSIC: "Music",
    PHOTO: "Photo",
    METADATA_TEMPLATE: "MetadataTemplate",
    CUSTOM_CONTENTS: "CustomContents",
};

export const LINEUP_PRIORITY_TYPE = [
    { label: "Highest", value: 99 },
    { label: "High", value: 75 },
    { label: "Medium", value: 50 },
    { label: "Low", value: 25 },
    { label: "Lowest", value: 0 },
];

export const METADATA_INPUT_TYPE = {
    TEXT_FIELD: "TEXT_FIELD",
    TEXT_AREA: "TEXT_AREA",
    DATE_PICKER_SINGLE: "DATE_PICKER_SINGLE",
    DATE_PICKER_RANGE: "DATE_PICKER_RANGE",
    NUMBER: "NUMBER",
    SELECT_SINGLE: "SELECT_SINGLE",
    SELECT_MULTI: "SELECT_MULTI",
};

export const LISTINGS_VIEW_TYPE = {
    LIST: "LIST",
    CALENDAR: "CALENDAR",
};

export const DEFAULT_LANGUAGE = "English";
export const SUPPORT_LOCALES = [
    {
        name: "English",
        value: "en-us",
        label: "English",
    },
    {
        name: "한국어",
        value: "ko-kr",
        label: "Korean",
    },
];

// ToDO : 하위호환
export const ASSET_MEIDA_TYPES = MEDIA_TYPES;

export const JOB_ID_LCOAL_STORAGE_KEY = "mz.mcm.uploading.job-ids";

export const FILE_VALIDATE_ERROR_MESSAGE = {
    INVALID_FILE: i18n.t(`common::msg::${"The file is not valid."}`),
    NOT_ALLOWED_URL_PATTERN: i18n.t(`common::msg::${"This is not a valid URL pattern"}`),
    NOT_ALLOWED_EXTENSION: i18n.t(`common::msg::${"This file format is not supported."}`),
    UNAUTHORIZED: i18n.t(`common::msg::${"Access denied."}`),
    INVALID_UPLOAD_TARGET_PATH: i18n.t(
        `common::msg::${"Ingest path setting for pipeline is required. Please contact your manager."}`,
    ),
    NOT_FOUND_FILE: i18n.t(`common::msg::${"The same file could not be found"}`),
};

export const JSON_VIEW_TYPES = {
    TREE: "Tree",
    TEXT: "Text",
};

export const PATH = {
    HOME: "/dashboard",
    ADMIN: "/admin/space/users",
};

export const ANALYSIS_TYPES = {
    CONTENT_MODERATION: "ContentModeration",
    CELEBRITY_RECOGNITION: "CelebrityRecognition",
    FACE_SEARCH: "FaceSearch",
    LABEL_DETECTION: "LabelDetection",
};

export const ANALYSIS_DETAIL_KEY = {
    contentModeration: "name",
    celebrityRecognition: "name",
    faceSearch: "peopleId",
    labelDetection: "name",
};

export const STATISTICS_WIDGET_RESOURCE_TYPE = {
    CHANNEL: "CHANNEL",
    ASSET: "ASSET",
    VIDEO: "VIDEO",
    MUSIC: "MUSIC",
    PHOTO: "PHOTO",
};

export const ARCHIVED_ASSET_STATUS = {
    REQUEST_ARCHIVE: "REQUEST_ARCHIVE",
    ARCHIVING: "ARCHIVING",
    ARCHIVED: "ARCHIVED",
    REQUEST_RESTORE: "REQUEST_RESTORE",
    RESTORING: "RESTORING",
    RESTORED: "RESTORED",
    ARCHIVE_ERROR: "ARCHIVE_ERROR",
    RESTORE_ERROR: "RESTORE_ERROR",
};

export const SIDEBAR_BUTTON_ID = "sidebar-button";

export const BROWSER_TYPES = {
    CHR: "CHROME",
    IE: "TRIDENT",
    SAF: "SAFARI",
};

export const SECOND_CONVERT_TYPES = {
    DAY: 86400,
    HOUR: 3600,
    MIN: 60,
    SEC: 1,
};

export const STATUS_TYPES = {
    READY: "READY",
    RUNNING: "RUNNING",
    STOPPED: "STOPPED",
    LOCKED: "LOCKED",
    ENDED: "ENDED",
};

export const SCHEDULE_BEHAVIORS = {
    START: "start",
    STOP: "stop",
    LOCK: "lock",
    END: "end",
};

export const MEDIA_TYPE_EXTENSIONS = {
    VIDEO: [
        ".3gp",
        ".3g2",
        ".mp4",
        ".mts",
        ".m2ts",
        ".m2t",
        ".ogv",
        ".avi",
        ".webm",
        ".mpeg",
        ".mxf",
        ".ts",
        ".wmv",
        ".divx",
        ".flv",
        ".f4v",
        ".m4v",
        ".mpg",
        ".trp",
        ".264",
        ".h264",
        ".mov",
        ".vob",
        ".mkv",
        ".gxf",
        ".dv",
        ".xvid",
        ".gxf_mpeg2",
        ".mxf_mpeg2",
        ".y4m",
        ".mpt",
        ".mxfhd",
        ".vmf",
        ".asf",
        ".qt",
    ],
    AUDIO: [
        ".mp3",
        ".oga",
        ".aac",
        ".wav",
        ".weba",
        ".3gp",
        ".3g2",
        ".mpga",
        ".mp2",
        ".mp2a",
        ".m2a",
        ".m3a",
        ".m4a",
        ".mp4a",
        ".wma",
    ],
    IMAGE: [".png", ".gif", ".jpg", ".jpeg", ".bmp", ".ico", ".svg", ".webp"],
    TEXT: [".vtt"],
    CAPTION: [".vtt", ".srt", ".smi"],
    FILE: [".*"],
};

export const MEDIA_TYPE_EXTENSIONS_REGEXP = {
    VIDEO: MEDIA_TYPE_EXTENSIONS.VIDEO.map((ext) => ext.slice(1)),
    AUDIO: MEDIA_TYPE_EXTENSIONS.AUDIO.map((ext) => ext.slice(1)),
    IMAGE: MEDIA_TYPE_EXTENSIONS.IMAGE.map((ext) => ext.slice(1)),
    TEXT: MEDIA_TYPE_EXTENSIONS.CAPTION.map((ext) => ext.slice(1)),
    CAPTION: MEDIA_TYPE_EXTENSIONS.CAPTION.map((ext) => ext.slice(1)),
};

const VOD_MIMETYPE_REGEXP = VOD_MIMETYPE.map((ext) => ext.slice(1));
export const REGEXP_TRANSCODING_EXTENSION = new RegExp(`(${VOD_MIMETYPE_REGEXP.join("|")})$`, "i");
export const REGEXP_TRANSCODING_FILENAME = new RegExp(`\.(${VOD_MIMETYPE_REGEXP.join("|")})$`, "i");

const VOD_MIMETYPE_ONLY_VIDEO_REGEXP = VOD_MIMETYPE.filter(
    (type) => ![".mp3", ".aac", ".wav", ".m3a", ".m2a", "m4a", "wma"].includes(type),
).map((ext) => ext.slice(1));
export const REGEXP_TRANSCODING_ONLY_VIDEO_EXTENSION = new RegExp(
    `(${VOD_MIMETYPE_ONLY_VIDEO_REGEXP.join("|")})$`,
    "i",
);
export const REGEXP_TRANSCODING_ONLY_VIDEO_FILENAME = new RegExp(
    `\.(${VOD_MIMETYPE_ONLY_VIDEO_REGEXP.join("|")})$`,
    "i",
);

const VOD_MIMETYPE_ELASTIC_TRANSCODER_REGEXP_LIST = VOD_MIMETYPE_ELASTIC_TRANSCODER.map((ext) => ext.slice(1));
export const REGEXP_ELASTIC_TRANSCODER_TRANSCODING_EXTENSION = new RegExp(
    `(${VOD_MIMETYPE_ELASTIC_TRANSCODER_REGEXP_LIST.join("|")})$`,
    "i",
);
export const REGEXP_ELASTIC_TRANSCODER_TRANSCODING_FILENAME = new RegExp(
    `\.(${VOD_MIMETYPE_ELASTIC_TRANSCODER_REGEXP_LIST.join("|")})$`,
    "i",
);

const VOD_MIMETYPE_MEDIACONVERT_REGEXP_LIST = VOD_MIMETYPE_MEDIACONVERT.map((ext) => ext.slice(1));
export const REGEXP_MEDIACONVERT_TRANSCODING_EXTENSION = new RegExp(
    `(${VOD_MIMETYPE_MEDIACONVERT_REGEXP_LIST.join("|")})$`,
    "i",
);
export const REGEXP_MEDIACONVERT_TRANSCODING_FILENAME = new RegExp(
    `\.(${VOD_MIMETYPE_MEDIACONVERT_REGEXP_LIST.join("|")})$`,
    "i",
);

export const REGEXP_VIDEO_EXTENSION = new RegExp(`(${MEDIA_TYPE_EXTENSIONS_REGEXP.VIDEO.join("|")})$`, "i");
export const REGEXP_AUDIO_EXTENSION = new RegExp(`(${MEDIA_TYPE_EXTENSIONS_REGEXP.AUDIO.join("|")})$`, "i");
export const REGEXP_IMAGE_EXTENSION = new RegExp(`(${MEDIA_TYPE_EXTENSIONS_REGEXP.IMAGE.join("|")})$`, "i");
export const REGEXP_CAPTION_EXTENSION = new RegExp(`(${MEDIA_TYPE_EXTENSIONS_REGEXP.CAPTION.join("|")})$`, "i");

export const REGEXP_VIDEO_FILENAME = new RegExp(`(\.${MEDIA_TYPE_EXTENSIONS_REGEXP.VIDEO.join("|")})$`, "i");
export const REGEXP_AUDIO_FILENAME = new RegExp(`(\.${MEDIA_TYPE_EXTENSIONS_REGEXP.AUDIO.join("|")})$`, "i");
export const REGEXP_IMAGE_FILENAME = new RegExp(`(\.${MEDIA_TYPE_EXTENSIONS_REGEXP.IMAGE.join("|")})$`, "i");
export const REGEXP_CAPTION_FILENAME = new RegExp(`(\.${MEDIA_TYPE_EXTENSIONS_REGEXP.CAPTION.join("|")})$`, "i");

export const S3_STORAGE_CLASS = [
    { label: i18n.t(`common::label::Standard`), value: "STANDARD" },
    { label: i18n.t(`common::label::Reduced Redundancy`), value: "REDUCED_REDUNDANCY" },
    { label: i18n.t(`common::label::Stanard-IA`), value: "STANDARD_IA" },
    { label: i18n.t(`common::label::One Zone-IA`), value: "ONEZONE_IA" },
    { label: i18n.t(`common::label::Intelligent Tiering`), value: "INTELLIGENT_TIERING" },
    { label: i18n.t(`common::label::Glacier`), value: "GLACIER" },
    { label: i18n.t(`common::label::Deep Archive`), value: "DEEP_ARCHIVE" },
    { label: i18n.t(`common::label::Outposts`), value: "OUTPOSTS" },
];

export const LIST_VIEW_TYPE = {
    LIST: "list",
    HIERARCHY: "hierarchy",
};

export const GROUPING_VIEW_TYPE = {
    FOLDER: "folder",
    CATEGORY: "category",
};

export const PULBLISHING_STATUS = {
    READY: "READY",
    PUBLISHING: "PUBLISHING",
    RECLAIMING: "RECLAIMING",
    PUBLISHED: "PUBLISHED",
    RECLAIMED: "RECLAIMED",
    RECLAIMED_BY_ERROR: "RECLAIMED_BY_ERROR",
};

export const UPLOAD_DUPLICATE_MODAL_ACTION = {
    OVERWRITE: "OVERWRITE",
    STOP: "STOP",
    SKIP: "SKIP",
};

export const UPLDOAD_DUPLICATE_ACTION = {
    ASK: "ASK",
    ALWAYS_OVERWRITE: "ALWAYS_OVERWRITE",
    ALWAYS_SKIP: "ALWAYS_SKIP",
};

export const OPERATING_TYPE = {
    UPLOAD: "UPLOAD",
    COPY: "COPY",
    MOVE: "MOVE",
    RENAME: "RENAME",
};
export const SHARE_REQUEST_ACTION = {
    APPROVE: "REQUEST_APPROVE",
    DENY: "REQUEST_DENY",
};

export function getConstantValues(key) {
    const constant = CONSTANTS(key);
    if (!constant) return [];
    return constant && constant.map((c) => c && c.value);
}

export const UNCATEGORIZED_ID = "UCID";

export const CONTENT_TYPE = {
    VIDEO: "VIDEO",
    MUSIC: "MUSIC",
    PHOTO: "PHOTO",
    PEOPLE: "PEOPLE",
    CUSTOM: "CUSTOM",
};

export const TRANSCODE_CONTAINER_TYPE_FLAG = {
    MP4: "MP4",
    M3U8: "M3U8",
    MPD: "MPD",
    CMFC: "CMFC",
    RAW: "RAW",
};
export const INTEGRATED_SEARCH_TYPE = {
    BOTH: "BOTH",
    STORAGE: "STORAGE",
    ASSET: "ASSET",
};

export const DRIVE_FOLDER_SEARCH_TYPE = {
    NAME: "NAME",
    PARENT_FOLDERS: "PARENT_FOLDERS",
    DIRECT_CHILDREN: "DIRECT_CHILDREN",
};

export const STORAGE_SHAREDBOX_PANEL_TABS = [
    {
        label: i18n.t(`common::label::${"Overview"}`),
        value: "overview",
    },
    {
        label: i18n.t(`common::label::${"Shared with"}`),
        value: "sharedwith",
    },
];

export const EXTERNAL_SHARE_HISTORY_PANEL_TABS = [
    {
        label: i18n.t(`common::label::${"Overview"}`),
        value: "overview",
    },
    {
        label: i18n.t(`common::label::${"Items Shared"}`),
        value: "itemsshared",
    },
    {
        label: i18n.t(`common::label::${"Shared with"}`),
        value: "sharedwith",
    },
];

export const ASSET_BULK_FOLDER_PATH = {
    ROOT_FOLDER: "Save assets to the top-level path on the drive",
    OTHER_FOLDER: "Save assets according to the storage folder path",
};

export const SHARE_APPROVE_LEVEL = [
    {
        label: i18n.t("common::label::Viewer", `Viewer`),
        value: "VIEWER",
    },
    {
        label: i18n.t("common::label::Downloader", `Downloader`),
        value: "DOWNLOAD",
    },
];

export const SHARE_MAIL_LANGUAGE = [
    { label: "English", value: "EN" },
    { label: "한국어", value: "KR" },
    { label: "日本語", value: "JP" },
];

export function getMailLanguage(value) {
    switch (value) {
        case "ko":
            return "KR";
        case "en":
            return "EN";
        case "ja":
            return "JP";
        default:
            return "EN";
    }
}

export const PROJECT_SETTINGS_ARCHIVE_OPTIONS = [
    { label: `S3 Glacier Deep Archive`, value: "DEEP_ARCHIVE" },
    { label: `S3 Glacier Flexible Retrieval`, value: "GLACIER" },
];

export const PROJECT_SETTINGS_ARCHIVE_RESTORE_OPTIONS = [
    { label: `Expedited`, value: `EXPEDITED` },
    { label: `Standard`, value: `STANDARD` },
    { label: `Bulk`, value: `BULK` },
];

export const PROJECT_SETTINGS_ARCHIVE_DEFAULT_OPTIONS = [
    { key: "archiveOption", value: "GLACIER" },
    { key: "restoreOption", value: `STANDARD` },
];

export const CONTENT_SCHEMA_TYPES_OPTIONS = [
    {
        label: "Custom",
        value: 0,
    },
    {
        label: "System Managed",
        value: 1,
    },
];

export const METADATA_FIELDS_TYPE = {
    TEXT_FIELD: "TEXT_FIELD",
    TEXT_AREA: "TEXT_AREA",
    NUMBER: "NUMBER",
    SINGLE_SELECT: "SINGLE_SELECT",
    MULTI_SELECT: "MULTI_SELECT",
    SINGLE_TIME_PICKER: "SINGLE_TIME_PICKER",
    RANGE_TIME_PICKER: "RANGE_TIME_PICKER",
    SINGLE_DATE_PICKER: "SINGLE_DATE_PICKER",
    RANGE_DATE_PICKER: "RANGE_DATE_PICKER",
    TAGS: "TAGS",
    ATTRIBUTIONS: "ATTRIBUTIONS",
    CATEGORIES: "CATEGORIES",
    PEOPLE: "PEOPLE",
};

export const REFERENCE_FIELDS_TYPE = {
    ASSETS: "ASSETS",
    VIDEO_ASSETS: "VIDEO_ASSETS",
    AUDIO_ASSETS: "AUDIO_ASSETS",
    IMAGE_ASSETS: "IMAGE_ASSETS",
    CAPTION_ASSETS: "CAPTION_ASSETS",
    FILE_ASSETS: "FILE_ASSETS",
    VIDEO_CONTENTS: "VIDEO_CONTENTS",
    MUSIC_CONTENTS: "MUSIC_CONTENTS",
    PEOPLE_CONTENTS: "PEOPLE_CONTENTS",
    PHOTO_CONTENTS: "PHOTO_CONTENTS",
    CUSTOM_CONTENTS: "CUSTOM_CONTENTS",
    IMAGE_ASSET: "IMAGE_ASSET",
    AUDIO_ASSET: "AUDIO_ASSET",
    VIDEO_ASSET: "VIDEO_ASSET",
    FILE_ASSET: "FILE_ASSET",
    CAPTION_ASSET: "CAPTION_ASSET",
    VIDEO_CONTENT: "VIDEO_CONTENT",
    MUSIC_CONTENT: "MUSIC_CONTENT",
    PEOPLE_CONTENT: "PEOPLE_CONTENT",
    PHOTO_CONTENT: "PHOTO_CONTENT",
    CUSTOM_CONTENT: "CUSTOM_CONTENT",
    COLLECTION: "COLLECTION",
    COLLECTIONS: "COLLECTIONS",
};

export const CUSTOM_CONTENT_FIELDS_TYPE = {
    ASSET_IMAGE: "ASSET_IMAGE",
    ASSET_VIDEO: "ASSET_VIDEO",
    ASSET_CAPTION: "ASSET_CAPTION",
    ASSET_FILE: "ASSET_FILE",
    CONTENT_VIDEO: "CONTENT_VIDEO",
    CONTENT_MUSIC: "CONTENT_MUSIC",
    CONTENT_PEOPLE: "CONTENT_PEOPLE",
    CONTENT_PHOTO: "CONTENT_PHOTO",
    CONTENT_CUSTOM: "CONTENT_CUSTOM",
};

export const CONTENT_SCHEMA_DND_TYPE = {
    SCHEMA_FIELD: "SCHEMA_FIELD",
    SCHEMA_TYPE: "SCHEMA_TYPE",
    SELECT_OPTION: "SELECT_OPTION",
};

export const CONTENT_SCHEMA_FIELD_ACTIONS = {
    CREATE: "CREATE",
    DELETE: "DELETE",
    UPDATE: "UPDATE",
    CHANGE_ORDER: "CHANGE_ORDER",
    VALIDATION: "VALIDATION",
};

export const CONTENTS_SCHEMA_TYPE = {
    EXTENDED_METADATA: "EXTENDED_METADATA",
    CONTENTS: "CONTENTS",
};

export const getMetadataFieldType = (type) => {
    if (type === "TEXT_FIELD") return METADATA_FIELDS_TYPE.TEXT_FIELD;
    if (type === "TEXT_AREA") return METADATA_FIELDS_TYPE.TEXT_AREA;
    if (type === "NUMBER") return METADATA_FIELDS_TYPE.NUMBER;
    if (type === "SELECT_SINGLE") return METADATA_FIELDS_TYPE.SINGLE_SELECT;
    if (type === "SELECT_MULTI") return METADATA_FIELDS_TYPE.MULTI_SELECT;
    if (type === "DATE_PICKER_SINGLE") return METADATA_FIELDS_TYPE.SINGLE_DATE_PICKER;
    if (type === "DATE_PICKER_RANGE") return METADATA_FIELDS_TYPE.RANGE_DATE_PICKER;
};

export const recoverToPreviousMetadataFieldType = (type) => {
    if (type === METADATA_FIELDS_TYPE.TEXT_FIELD) return "TEXT_FIELD";
    if (type === METADATA_FIELDS_TYPE.TEXT_AREA) return "TEXT_AREA";
    if (type === METADATA_FIELDS_TYPE.NUMBER) return "NUMBER";
    if (type === METADATA_FIELDS_TYPE.SINGLE_SELECT) return "SELECT_SINGLE";
    if (type === METADATA_FIELDS_TYPE.MULTI_SELECT) return "SELECT_MULTI";
    if (type === METADATA_FIELDS_TYPE.SINGLE_DATE_PICKER) return "DATE_PICKER_SINGLE";
    if (type === METADATA_FIELDS_TYPE.RANGE_DATE_PICKER) return "DATE_PICKER_RANGE";
};

export const CONTENT_SCHEMA_TYPES = {
    CUSTOM: "CUSTOM",
    SYSTEM_MANAGED: "SYSTEM_MANAGED",
};

export const CONTENT_DETAIL_ACTION_TYPE = {
    OVERWRITE_ATTRIBUTIONS: "OVERWRITE_ATTRIBUTIONS",
    OVERWRITE_TAGS: "OVERWRITE_TAGS",
    OVERWRITE_CATEGORIES: "OVERWRITE_CATEGORIES",
    OVERWRITE_FIELD_VALUES: "OVERWRITE_FIELD_VALUES",
};

export const AUTO_CAPTION_ENGINES = {
    AWS_TRANSCRIBE: "AWS_TRANSCRIBE",
    DAGLO_STT: "DAGLO_STT",
    DAGLO_VIDEO_OCR: "DAGLO_VIDEO_OCR",
    DAGLO_VIDEO_ANNOTATION: "DAGLO_VIDEO_ANNOTATION",
};

export const AUTO_CAPTION_ENGINE_TYPE = {
    AWS: "AWS",
    DAGLO: "DAGLO",
};

export const FIELD_DISPLAY_TYPE = {
    RELATED_ASSETS: "RELATED_ASSETS",
};

export const MEDIA_TYPE_EXTENSIONS_FOR_ANALYSIS = {
    AWS_TRANSCRIBE: ["arm", "flac", "m4a", "mp3", "mp4", "ogg", "webm", "wav"],
    DAGLO_STT: [
        "3gp",
        "3gpp",
        "ac3",
        "aac",
        "aiff",
        "amr",
        "au",
        "flac",
        "m4a",
        "mp3",
        "mxf",
        "opus",
        "ra",
        "wav",
        "weba",
        "asx",
        "avi",
        "ogm",
        "ogv",
        "m4v",
        "mov",
        "mp4",
        "mpeg",
        "mpg",
        "wmv",
    ],
};

export const PANEL_FOLD_DIRECTION = {
    NONE: "None",
    LEFT: "Left",
    RIGHT: "Right",
};

export const FOLDER_VIEW_DOMAINS = {
    ASSETS: "ASSETS",
    ARCHIVED_ASSETS: "ARCHIVED ASSETS",
    COLLECTIONS: "COLLECTIONS",
};

export const MENU_LIST = {
    DASHBOARD: "DASHBOARD",
    STORAGES: "STORAGES",
    STORAGE_SHARED_HISTORIES: "STORAGE_SHARED_HISTORIES", //SHARED_HISTORIES 통합 전 사용
    ASSETS_SHARED_HISTORIES: "ASSETS_SHARED_HISTORIES", //SHARED_HISTORIES 통합 전 사용
    STORAGE_SHARED_BOX: "STORAGE_SHARED_BOX", //SHARED_BOX 통합 전 사용
    ASSETS_SHARED_BOX: "ASSETS_SHARED_BOX", //SHARED_BOX 통합 전 사용
    ASSETS: "ASSETS",
    COLLECTIONS: "COLLECTIONS",
    ARCHIVED: "ARCHIVED",
    SHARED_HISTORIES: "SHARED_HISTORIES",
    SHARED_BOX: "SHARED_BOX",
    VIDEOS: "VIDEOS",
    PEOPLE: "PEOPLE",
    MUSIC: "MUSIC",
    PHOTO: "PHOTO",
    CUSTOM_CONTENTS: "CUSTOM_CONTENTS",
    STREAMS: "STREAMS",
    SCHEDULES: "SCHEDULES",
    CHANNELS: "CHANNELS",
    INTERACTIVE_VIDEOS: "INTERACTIVE_VIDEOS",
    MEETINGS: "MEETINGS",
    JOBS: "JOBS",
};

//Note: 해당 메뉴의 값은 수정하거나 제거하면 안됩니다.
//새로운 메뉴가 들어오거나 메뉴를 병합하는 경우 새로운 값을 뒤에 추가하는 방식으로 진행해야 합니다.
export const SHOW_MENU_OPTIONS = [
    {
        id: MENU_LIST.DASHBOARD,
        name: "Dashboard",
        value: 1,
    },
    {
        id: MENU_LIST.STORAGES,
        name: "Storage",
        value: 1 << 1,
        mainMenuName: "Storages",
    },
    {
        id: MENU_LIST.STORAGE_SHARED_HISTORIES,
        name: "Shared Histories",
        value: 1 << 2,
        mainMenuName: "Storages",
        minVersion: VERSION_CM_8075_SHARE_REQUEST,
        maxVersion: VERSION_CM_11376_SHARES_V2,
    },
    {
        id: MENU_LIST.STORAGE_SHARED_BOX,
        name: "Shared Box",
        value: 1 << 3,
        mainMenuName: "Storages",
        minVersion: VERSION_CM_8075_SHARE_REQUEST,
        maxVersion: VERSION_CM_11376_SHARES_V2,
    },
    {
        id: MENU_LIST.ASSETS,
        name: "Assets",
        value: 1 << 4,
        mainMenuName: "Assets",
    },
    {
        id: MENU_LIST.COLLECTIONS,
        name: "Collections",
        value: 1 << 5,
        mainMenuName: "Assets",
    },
    {
        id: MENU_LIST.ASSETS_SHARED_HISTORIES,
        name: "Shared Histories",
        value: 1 << 6,
        mainMenuName: "Assets",
        minVersion: VERSION_CM_8075_SHARE_REQUEST,
        maxVersion: VERSION_CM_11376_SHARES_V2,
    },
    {
        id: MENU_LIST.ASSETS_SHARED_BOX,
        name: "Shared Box",
        value: 1 << 7,
        mainMenuName: "Assets",
        minVersion: VERSION_CM_8075_SHARE_REQUEST,
        maxVersion: VERSION_CM_11376_SHARES_V2,
    },
    {
        id: MENU_LIST.ARCHIVED,
        name: "Archived",
        value: 1 << 8,
        mainMenuName: "Assets",
    },
    {
        id: MENU_LIST.VIDEOS,
        name: "Videos",
        value: 1 << 9,
        mainMenuName: "Contents",
    },
    {
        id: MENU_LIST.PEOPLE,
        name: "People",
        value: 1 << 10,
        mainMenuName: "Contents",
    },
    {
        id: MENU_LIST.MUSIC,
        name: "Music",
        value: 1 << 11,
        mainMenuName: "Contents",
    },
    {
        id: MENU_LIST.PHOTO,
        name: "Photo",
        value: 1 << 12,
        mainMenuName: "Contents",
    },
    {
        id: MENU_LIST.CUSTOM_CONTENTS,
        name: "Custom Contents",
        value: 1 << 13,
        mainMenuName: "Contents",
    },
    {
        id: MENU_LIST.SHARED_BOX,
        name: "Shared Box",
        value: 1 << 20,
        mainMenuName: "Shared",
        minVersion: VERSION_CM_11376_SHARES_V2,
    },
    {
        id: MENU_LIST.SHARED_HISTORIES,
        name: "Shared Histories",
        value: 1 << 21,
        mainMenuName: "Shared",
        minVersion: VERSION_CM_11376_SHARES_V2,
    },
    {
        id: MENU_LIST.STREAMS,
        name: "Streams",
        value: 1 << 14,
        mainMenuName: "Live",
    },
    {
        id: MENU_LIST.SCHEDULES,
        name: "Schedules",
        value: 1 << 15,
        mainMenuName: "Live",
    },
    {
        id: MENU_LIST.CHANNELS,
        name: "Channels",
        value: 1 << 16,
        mainMenuName: "Live",
    },
    {
        id: MENU_LIST.INTERACTIVE_VIDEOS,
        name: "Interactive Videos",
        value: 1 << 17,
    },
    {
        id: MENU_LIST.MEETINGS,
        name: "Meetings",
        value: 1 << 18,
    },
    {
        id: MENU_LIST.JOBS,
        name: "Jobs",
        value: 1 << 19,
    },
];

export const COMMENT_GROUP_TITLE_MAX_LENGTH = 20;

export const STORAGE_SHARE_ACTIONS = [{ label: "Downloader", value: "DOWNLOAD" }];

export const ASSET_SHARE_ACTIONS = [
    { label: "Downloader", value: "DOWNLOAD" },
    { label: "Viewer", value: "VIEWER" },
];

export const JOB_PANEL_TABS = {
    ASSET_JOBS: "Asset Jobs",
    DOWNLOAD: "Download",
    TRANSCODE: "Transcode",
    SOURCE_UPLOAD: "Source Upload",
    SOURCE_JOBS: "Source Jobs",
};

export const ASSET_PREVIEW_STATUS = {
    IN_PROGRESS: "IN_PROGRESS",
    ERROR: "ERROR",
    COMPLETE: "COMPLETE",
    NOT_SUPPORTED: "NOT_SUPPORTED",
};

export const JOB_ACTIONS = {
    CREATE_ASSETS: "CREATE_ASSETS",
    COPY_ASSETS: "COPY_ASSETS",
    UPLOAD_ASSET: "UPLOAD_ASSET",
    ARCHIVE_ASSETS: "ARCHIVE_ASSETS",
    ASSET_DOWNLOAD: "ASSET_DOWNLOAD",
    STORAGE_DOWNLOAD: "STORAGE_DOWNLOAD",
    METADATA_DOWNLOAD: "METADATA_DOWNLOAD",
    TRANSCODE: "TRANSCODE",
    UPLOAD: "UPLOAD",
    MOVE: "MOVE",
    COPY: "COPY",
    DELETE: "DELETE",
    RENAME: "RENAME",
};
