import convertToByte from "@cores/convertToByte";
import EditLinkedCollectionTable from "@components/view/panel-details-view/edit-linked-collection-table";
import CollectionColumn from "@components/view/panel-details-view/panel-table-columns-collections";
import { CATEGORY_TYPES, METADATA_FIELDS_TYPE } from "@constants";
import { useCallback } from "react";
import { updateContentSchemaAction } from "@modules/content-schema";

export function getPropertyPanelCustomContentOverview(data, onHandleSubmitUpdateContent) {
    return [
        {
            attributions: [
                { id: "id", type: "text", subtitle: "ID", value: data.id, isEditable: false },
                {
                    id: "status",
                    type: "status",
                    subtitle: "Status",
                    value: data.status,
                    isEditable: true,
                    onSubmitEditValue: async ({ type, editValue }) => {
                        await onHandleSubmitUpdateContent(type, editValue);
                    },
                },
            ],
        },
        {
            attributions: [
                {
                    id: "created",
                    type: "date",
                    subtitle: "Created",
                    value: { ...data.created, hideOwner: true },
                    isEditable: false,
                },
                {
                    id: "updated",
                    type: "date",
                    subtitle: "Updated",
                    value: { ...data.updated, hideOwner: false },
                    isEditable: false,
                },
            ],
        },
    ];
}

export function getPropertyPanelCustomContentMetadata(
    data,
    createDefaultValueSchema,
    onHandleSubmitUpdateContent,
    onHandleSubmitUpdateContentAction,
) {
    const metadataFields = data?.fields?.filter((f) => !f.fieldType.dataType.isReference);

    const customMetadataOptions = (field) => {
        const fieldType = field.fieldType.dataType.detailType;
        const isSingleEnum = fieldType === METADATA_FIELDS_TYPE.SINGLE_SELECT;
        const isMultiEnum = fieldType === METADATA_FIELDS_TYPE.MULTI_SELECT;
        if (isSingleEnum) return field?.fieldType?.dataType?.enum;
        if (isMultiEnum) {
            const properties = field?.fieldType?.dataType?.properties;
            return properties?.length > 0 ? properties[0].enum : [];
        }
        return [];
    };

    const newCustomMetadata = metadataFields?.map((el) => ({
        id: el.id,
        subtitle: el.name,
        type: el.fieldType.dataType.detailType,
        value: el.value,
        options: customMetadataOptions(el),
        validationRule: createDefaultValueSchema(
            el?.fieldType?.dataType?.type,
            el?.fieldType?.dataType?.properties?.[0]?.type,
            el?.fieldType?.dataType?.validation || el?.fieldType?.dataType?.properties?.[0]?.validation,
            el?.isRequired,
        ),
        isEditable: el.isEditable,
        isHidden: el.isHidden,
        onSubmitEditValue: async (value) => {
            await onHandleSubmitUpdateContentAction("fields", [{ id: el.id, value }]);
        },
    }));

    return [
        {
            attributions: [
                {
                    id: "description",
                    subtitle: "Description",
                    type: METADATA_FIELDS_TYPE.TEXT_AREA,
                    value: data.description,
                    isEditable: true,
                    onSubmitEditValue: async (value) => {
                        await onHandleSubmitUpdateContent("description", value);
                    },
                },
                {
                    id: "tags",
                    subtitle: "Tags",
                    type: METADATA_FIELDS_TYPE.TAGS,
                    value: data.tags,
                    isEditable: true,
                    onSubmitEditValue: async (value) => {
                        await onHandleSubmitUpdateContentAction("tags", value);
                    },
                },
                {
                    id: "attributions",
                    subtitle: "Attributions",
                    type: METADATA_FIELDS_TYPE.ATTRIBUTIONS,
                    value: data.attributions,
                    isEditable: true,
                    onSubmitEditValue: async (value) => {
                        await onHandleSubmitUpdateContentAction("attributions", value);
                    },
                },
                {
                    id: "categories",
                    subtitle: "Categories",
                    type: METADATA_FIELDS_TYPE.CATEGORIES,
                    value: {
                        schemaId: data.contentSchema.id,
                        categoryGroup:
                            data.categories?.length > 0
                                ? { id: data.categories[0].groupId, name: data.categories[0].groupName }
                                : null,
                        categories: data?.categories?.map(({ id, name }) => ({ id, name })),
                        categoryType: CATEGORY_TYPES.CUSTOM_CONTENTS,
                        useCategoryGroup: true,
                    },
                    isEditable: true,
                    onSubmitEditValue: async (value) => {
                        const { categories, ...rest } = value;
                        await onHandleSubmitUpdateContentAction(
                            "categories",
                            categories?.map(({ id }) => id),
                        );
                    },
                },
            ],
        },
        {
            attributions: newCustomMetadata,
        },
    ];
}
