import { Box, Skeleton } from "@mzc-pdc/ui";
import React from "react";
import theme from "@styles/theme";

const MediaImageWrapper = ({ children, status, variant = "rounded", sx }) => {
    if (status === "IN_PROGRESS") {
        const { width, height, ml, mr } = sx;
        return (
            <Box
                sx={{
                    width,
                    height,
                    ml,
                    mr,
                    position: "relative",
                    display: "inline-block",
                    backgroundColor: theme.palette.common.white,
                }}
            >
                <Skeleton variant={variant} animation={"wave"} width={width} height={height} />
                <Box
                    sx={{
                        position: "absolute",
                        left: "0",
                        top: "0",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    {children}
                </Box>
            </Box>
        );
    }
    return <Box sx={sx}>{children}</Box>;
};

export default MediaImageWrapper;
