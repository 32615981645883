export const isExternalElement = (e) => {
    // TODO : 효과적으로 관리할 수 있는 방안 모색
    return e.target.localName === "html" || checkSearchForm(e);
};

export const checkElementClassName = (item) => {
    const externalElementList = [
        "search-total",
        "modal-dialog", // right pannel의 my activty에서 취소할 경우 닫히는 경우때문에 추가함
        "modal-share-request", // 내부 공유 모달을 띄운 상태로 바깥영역 클릭시 닫히는 경우가 생김
        "btn-panel-cover-open", //right pannel의 버튼닫기 클릭했을때 이중클릭되는 문제로 추가
        "select2-selection__option", // right pannel의 more views selectbox 클릭했을때 생기는 문제
        "page-header-tools", // property panel 이 열렸을때 list 페이지의 우상단 뷰타입 변경
        "control", // property panel 이 열렸을때 컨텐츠 상단의 뷰타입 변경
        "area-search", // search  영역의 input
        "modal-active", // search  영역의 input
        "btn-dropdown", // search  영역의 input
        "popper-tooltip-content",
        "popper-tooltip",
        "MuiAutocomplete-option", //mui autocomplete의 옵션
        "MuiBackdrop-root", //mui popover가 뜨는 경우 뒷배경으로 뜨는 투명 div
        "PrivateSwitchBase-input", //mui autocomplete내 input(linked collection 수정 시 사용)
        "MuiDialog-container", //mui dialog
        "MuiPopper-root", //mui autocomplete popper
        "EmojiPickerReact", //emoji picker
        "base-Popper-root", //mui popper
    ];

    if (!item || typeof item.className === "object") return false;

    const isExternalElement = externalElementList.filter((e) => item.className?.includes(e)).length > 0;

    return isExternalElement;
};

// Case 1 : List > 검색 영역
function checkSearchForm(e) {
    // console.log("e", e);
    const path = e.composedPath();

    return path && path.length && path.filter((item) => checkElementClassName(item)).length > 0;
}
