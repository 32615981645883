import { styled } from "@mui/material";

import {
    Button as MuiButton,
    Avatar as MuiAvatar,
    Popper as MuiPopper,
    Box as MuiBox,
    MenuList as MuiMenuList,
    MenuItem as MuiMenuItem,
    Menu as MuiMenu,
    List,
    ListSubheader as MuiListSubheader,
    ListItem,
    ListItemButton as MuiListItemButton,
    ListItemText as MuiListItemText,
} from "@mzc-pdc/ui";

export const Popper = styled(MuiPopper)`
    .MuiPaper-root {
        margin-top: 11px;
        margin-left: -9px;
    }
`;

export const ToggleList = styled(MuiMenuList)``;

export const Avatar = styled(MuiAvatar)`
    &.MuiAvatar-root {
        svg {
            margin-right: 0;
        }
    }
`;

export const SvgWrap = styled(MuiBox)`
    width: 32px;
    height: 32px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;
export const MenuButton = styled(MuiButton)`
    padding: 10px 20px;
    height: 40px;
    font-size: 14px;
    justify-content: flex-start;
    font-weight: 500;
    border-radius: 0;
    color: ${(props) => props.theme.palette.secondary[800]};
    &:hover {
        background-color: ${(props) => props.theme.palette.secondary[800]}14;
        .SvgWrap {
            background-color: ${(props) => props.theme.palette.secondary[800]}14;
        }
    }
`;

export const ListItemButton = styled(MuiListItemButton)`
    padding-top: 8px;
    padding-bottom: 8px;
    gap: 12px;
`;
export const ListSubheader = styled(MuiListSubheader)`
    line-height: 1;
    font-size: 0.688rem;
    margin-top: 20px;
    margin-bottom: 10px;
`;
export const ListItemText = styled(MuiListItemText)`
    margin: 0;
    .MuiListItemText-secondary {
        font-size: 12px;
        font-weight: 400;
    }
`;
