import { isLocal } from "../cores/enviromentExtension";
import Cookies from "js-cookie";

export class SessionKeeperCreator {
    static instance = null;

    static getInstance() {
        if (!this.instance) {
            this.instance = new SessionKeeper();
        }
        return this.instance;
    }
}

class SessionKeeper {
    SESSION_COOKIE_NAME = "mz.mcm.session";
    DOMAIN = isLocal ? "localhost" : "megazone.io";

    data = null;

    set(param) {
        const existingData = this.get();
        const { clientId, stage, project } = param;
        const temp = { ...existingData };

        if (stage) {
            temp.stageId = stage.id;
            temp.stageName = stage.name;
            temp.stageEndpoint = stage.endpoint;
            temp.stageVersion = stage.version;
            temp.stageExtensions = stage.extensions;
        }
        if (project) {
            temp.projectId = project.id;
            temp.projectName = project.name;
        }

        if (clientId) {
            temp.clientId = clientId;
        }
        this.data = temp;
        Cookies.set(this.SESSION_COOKIE_NAME, JSON.stringify(temp), {
            domain: this.DOMAIN,
        });
    }

    get() {
        if (!this.data) {
            const cookie = Cookies.get(this.SESSION_COOKIE_NAME);
            if (cookie) {
                this.data = JSON.parse(cookie);
            }
        }
        return this.data;
    }

    reset() {
        Cookies.remove(this.SESSION_COOKIE_NAME, {
            domain: this.DOMAIN,
        });
        this.data = null;
    }
}

export const sessionKeeper = SessionKeeperCreator.getInstance();
