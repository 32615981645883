import { sessionKeeper } from "@cores/sessionKeeper";
import menuService from "@services/menuService";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import theme from "@styles/theme";
import { IconLogoCloudpex } from "@mzc-cloudplex/icons";
import equal from "deep-equal";
import { AppBar, Grid, Toolbar } from "@mzc-pdc/ui";
import Logo from "./Logo";
import ProfileMenu from "../../features/profile-menu/profile-menu";

const HeaderForWelcome = () => {
    const { pathname } = useLocation();
    const { spaceId } = menuService.parse(pathname);

    const user = useSelector(({ user }) => user);

    const [headerTheme, setHeaderTheme] = useState(null);
    const [mountState, setMountState] = useState(null);

    useEffect(() => {
        let mountState = {};
        const headerThemeOfSettings = localStorage.getItem("headerTheme");
        if (headerThemeOfSettings) {
            const headerThemeJson = JSON.parse(headerThemeOfSettings);
            if (!equal(headerTheme, headerThemeJson.data)) {
                const session = sessionKeeper.get();
                const currentStageId = spaceId || session?.stageId;
                if (headerThemeJson.stageId === currentStageId) {
                    mountState.headerTheme = headerThemeJson.data;
                }
            }
        }

        setMountState(mountState);
    }, []);

    useEffect(() => {
        if (!user?.stage?.data) return;

        const headerThemeJson = JSON.parse(localStorage.getItem("headerTheme"));
        if (!equal(headerTheme, headerThemeJson.data)) {
            const session = sessionKeeper.get();
            const currentStageId = spaceId || session?.stageId;
            if (headerThemeJson.stageId === currentStageId) {
                setHeaderTheme(headerThemeJson.data);
            }
        }
    }, [user?.stage?.data]);

    return (
        <>
            <AppBar
                position="fixed"
                sx={{
                    backgroundColor: headerTheme ? headerTheme.color?.bgColor : theme.palette.background.default,
                    color: headerTheme ? headerTheme.color?.textColor : theme.palette.text.primary,
                    fill: headerTheme ? headerTheme.color?.textColor : theme.palette.text.primary,
                    boxShadow: `none`,
                    borderBottom: `1px solid ${theme.palette.secondary[100]}`,
                    zIndex: (props) => props.zIndex.drawer + 1,
                }}
            >
                <Toolbar sx={{ padding: `0 !important` }}>
                    <Grid container justifyContent={`space-between`} alignItems={`center`}>
                        <Grid item pl={2.5}>
                            <Logo to={"/"} imgSrc={headerTheme?.ci} fill={headerTheme?.color?.textColor} />
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </>
    );
};

export default HeaderForWelcome;
