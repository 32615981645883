import { Theme, CSSObject, styled } from '@mui/material/styles';

import {Container as MuiContainer} from '@mui/material';
import theme from '../../styles/theme';

export const StyledWelcome = styled("div")`
    min-height: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
`;

export const PopsLoginButton = styled("button")(({ theme}) => ({
    width: `480px`,
    height: `56px`,
    marginTop: `48px`,
    borderRadius: `3px`,
    border: `0`,
    padding: `0`,
    color: theme.palette.grey.contrastText,
    backgroundColor: theme.palette.grey.dark,
    svg: {
        marginTop: `-3px`
    },
    strong: {
        display: `inline-block`,
        marginLeft: `24px`,
        fontWeight: `normal`
    }
}))
