import { handleActions, createAction } from "redux-actions";

const CHANGE_CONSOLE_MODE = "CHANGE_CONSOLE_MODE";

//reducer
const initialState = {
    pending: false,
    error: false,
    mode: "CONSOLE",
};

export default handleActions(
    {
        [CHANGE_CONSOLE_MODE]: (state, action) => {
            const { mode } = action.payload;
            return {
                ...state,
                mode,
            };
        },
    },
    initialState,
);

export const setSiteMode = createAction(CHANGE_CONSOLE_MODE);
