import React from "react";
import {CircularProgress, Grid} from "@mzc-pdc/ui";

const Loader = () => {
    return (
        <Grid
            container
            alignItems={'center'}
            justifyContent={'center'}
            sx={{
                position: `fixed`,
                left: 0,
                top: 0,
                width: `100%`,
                height: `100%`,
                zIndex: 9999
            }}
        >
            <CircularProgress color="grey"/>
        </Grid>
    );
};

export default Loader;
