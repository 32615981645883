import { DRIVE_FOLDER_SEARCH_TYPE } from "@constants";
import { fromPairs, reduce } from "lodash";
import { handleActions, createAction } from "redux-actions";
import { createFolderTree } from "./folderTreeView";
import {
    createDriveFolderAPI,
    getDrivesListAPI,
    getFoldersListAPI,
    getDetailDriveFolderAPI,
    setFolderInfoAPI,
    deleteFolderAPI,
    moveFolderAPI,
} from "./apis/virtureFolder";
import compareVersions, { VERSION_CM_11164_COLLECTION_FOLDER_VIEW } from "@cores/version";

//action type
const GET_MODAL_FOLDERS_LIST_PENDING = "GET_MODAL_FOLDERS_LIST_PENDING";
const GET_MODAL_FOLDERS_LIST_PARTIAL_PENDING = "GET_MODAL_FOLDERS_LIST_PARTIAL_PENDING";
const GET_MODAL_FOLDERS_LIST_PARTIAL_SUCCESS = "GET_MODAL_FOLDERS_LIST_PARTIAL_SUCCESS";
const GET_MODAL_FOLDERS_LIST_SUCCESS = "GET_MODAL_FOLDERS_LIST_SUCCESS";
const GET_MODAL_FOLDERS_LIST_FAILURE = "GET_MODAL_FOLDERS_LIST_FAILURE";
const GET_MODAL_FOLDER_TREE_VIEW_PENDING = "GET_MODAL_FOLDER_TREE_VIEW_PENDING";
const GET_MODAL_FOLDER_TREE_VIEW_PARTIAL_PENDING = "GET_MODAL_FOLDER_TREE_VIEW_PARTIAL_PENDING";
const GET_MODAL_FOLDER_TREE_VIEW_PARTIAL_SUCCESS = "GET_MODAL_FOLDER_TREE_VIEW_PARTIAL_SUCCESS";
const GET_MODAL_FOLDER_TREE_VIEW_SUCCESS = "GET_MODAL_FOLDER_TREE_VIEW_SUCCESS";
const GET_MODAL_FOLDER_TREE_VIEW_FAILURE = "GET_MODAL_FOLDER_TREE_VIEW_FAILURE";
const GET_DETAIL_MODAL_FOLDER_PENDING = "GET_DETAIL_MODAL_FOLDER_PENDING";
const GET_DETAIL_MODAL_FOLDER_SUCCESS = "GET_DETAIL_MODAL_FOLDER_SUCCESS";
const GET_DETAIL_MODAL_FOLDER_FAILURE = "GET_DETAIL_MODAL_FOLDER_FAILURE";
const GET_MODAL_DRIVE_LIST_PENDING = "GET_MODAL_DRIVE_LIST_PENDING";
const GET_MODAL_DRIVE_LIST_SUCCESS = "GET_MODAL_DRIVE_LIST_SUCCESS";
const GET_MODAL_DRIVE_LIST_FAILURE = "GET_MODAL_DRIVE_LIST_FAILURE";
const CREATE_MODAL_FOLDER_PENDING = "CREATE_MODAL_FOLDER_PENDING";
const CREATE_MODAL_FOLDER_SUCCESS = "CREATE_MODAL_FOLDER_SUCCESS";
const CREATE_MODAL_FOLDER_FAILURE = "CREATE_MODAL_FOLDER_FAILURE";
const MODAL_FOLDERS_SEARCH_STATE = "MODAL_FOLDERS_SEARCH_STATE";
const MODAL_FOLDERS_UPDATE_STATE = "MODAL_FOLDERS_UPDATE_STATE";
const UPDATE_MODAL_SELECTED_FOLDER = "UPDATE_MODAL_SELECTED_FOLDER";
const UPDATE_MODAL_SELECTED_HISTORY = "UPDATE_MODAL_SELECTED_HISTORY";
const UPDATE_MODAL_FOLDER_INFO_SUCCESS = "UPDATE_MODAL_FOLDER_INFO_SUCCESS";
const UPDATE_MODAL_FOLDER_INFO_PENDING = "UPDATE_MODAL_FOLDER_INFO_PENDING";
const UPDATE_MODAL_FOLDER_INFO_FAILURE = "UPDATE_MODAL_FOLDER_INFO_FAILURE";
const RESET_MODAL_SELECTED_FOLDER = "RESET_MODAL_SELECTED_FOLDER";
const DELETE_MODAL_FOLDER_SUCCESS = "DELETE_MODAL_FOLDER_SUCCESS";
const DELETE_MODAL_FOLDER_PENDING = "DELETE_MODAL_FOLDER_PENDING";
const DELETE_MODAL_FOLDER_FAILURE = "DELETE_MODAL_FOLDER_FAILURE";
const MOVE_MODAL_FOLDER_PENDING = "MOVE_MODAL_FOLDER_PENDING";
const MOVE_MODAL_FOLDER_SUCCESS = "MOVE_MODAL_FOLDER_SUCCESS";
const MOVE_MODAL_FOLDER_FAILURE = "MOVE_MODAL_FOLDER_FAILURE";
const TOGGLE_NEW_FOLDER_IN_MODAL = "TOGGLE_NEW_FOLDER_IN_MODAL";

//reducer
const initialState = {
    pending: false,
    error: false,
    folderList: {
        childrenPending: false,
        loadingPosition: null,
        creatingParentFolder: false,
        pending: false,
        error: null,
        data: null,
        selectedFolder: { folder: null, history: [], index: -1 },
    },
    driveList: { pending: false, data: null, error: null },
    createFolder: {
        pending: false,
        error: null,
    },
    detailFolder: {
        pending: false,
        error: null,
    },
    isSearch: false,
};

export default handleActions(
    {
        [GET_MODAL_FOLDER_TREE_VIEW_PENDING]: (state) => {
            return {
                ...state,
                folderList: {
                    ...state.folderList,
                    pending: true,
                    error: null,
                },
                driveList: { ...state.driveList, pending: true },
            };
        },
        [GET_MODAL_FOLDER_TREE_VIEW_PARTIAL_PENDING]: (state, action) => {
            return {
                ...state,
                folderList: { ...state.folderList, childrenPending: true },
            };
        },
        [GET_MODAL_FOLDER_TREE_VIEW_PARTIAL_SUCCESS]: (state, action) => {
            return {
                ...state,
                folderList: { ...state.folderList, childrenPending: false, data: action.payload },
            };
        },
        [GET_MODAL_FOLDER_TREE_VIEW_SUCCESS]: (state, action) => {
            return {
                ...state,
                folderList: { ...state.folderList, pending: false }, //partial에서 이미 pending false처리 됨
            };
        },
        [GET_MODAL_FOLDER_TREE_VIEW_FAILURE]: (state, action) => {
            return {
                ...state,
                folderList: { ...state.folderList, pending: false, error: action.payload },
            };
        },
        [GET_MODAL_DRIVE_LIST_PENDING]: (state, action) => {
            return {
                ...state,
                driveList: { ...initialState.driveList },
            };
        },
        [GET_MODAL_DRIVE_LIST_SUCCESS]: (state, action) => {
            return {
                ...state,
                driveList: { ...state.driveList, data: action.payload, pending: false },
            };
        },
        [GET_MODAL_DRIVE_LIST_FAILURE]: (state, action) => {
            return {
                ...state,
                driveList: { ...state.driveList, error: action.payload, pending: false },
            };
        },
        [GET_MODAL_FOLDERS_LIST_PENDING]: (state) => {
            return {
                ...state,
                folderList: { ...state.folderList, error: null },
            };
        },
        [GET_MODAL_FOLDERS_LIST_PARTIAL_PENDING]: (state, action) => {
            const { parentId, nextToken } = action.payload;
            return {
                ...state,
                folderList: {
                    ...state.folderList,
                    childrenPending: true,
                    data: {
                        ...state.folderList.data,
                        [parentId]: {
                            ...state.folderList.data[parentId],
                            children: nextToken ? [...state.folderList.data[parentId].children] : [],
                            pending: true,
                            isFold: false,
                        },
                    },
                },
            };
        },
        [GET_MODAL_FOLDERS_LIST_PARTIAL_SUCCESS]: (state, action) => {
            return {
                ...state,
                folderList: { ...state.folderList, childrenPending: false, data: action.payload },
            };
        },
        [GET_MODAL_FOLDERS_LIST_SUCCESS]: (state, action) => {
            return {
                ...state,
                folderList: { ...state.folderList, pending: false },
            };
        },
        [GET_MODAL_FOLDERS_LIST_FAILURE]: (state, action) => {
            return {
                ...state,
                folderList: { ...state.folderList, pending: false, error: action.payload },
            };
        },
        [MODAL_FOLDERS_UPDATE_STATE]: (state, action) => {
            return {
                ...state,
                folderList: {
                    ...state.folderList,
                    data: { ...state.folderList.data, ...action.payload },
                },
            };
        },
        [UPDATE_MODAL_SELECTED_FOLDER]: (state, action) => {
            const selectedFolder = state.folderList.selectedFolder;
            let newSelectedHistory = [...selectedFolder.history];
            let newSelectedIndex = selectedFolder.index;

            if (selectedFolder.index < selectedFolder.history.length) {
                //이전 히스토리 도중 새로운 폴더 탐색
                newSelectedHistory = selectedFolder.history.slice(0, newSelectedIndex + 1);
            }
            newSelectedHistory.push(action.payload);
            newSelectedIndex = newSelectedIndex + 1;

            return {
                ...state,
                folderList: {
                    ...state.folderList,
                    selectedFolder: {
                        folder: action.payload,
                        history: newSelectedHistory,
                        index: newSelectedIndex,
                    },
                },
            };
        },
        [RESET_MODAL_SELECTED_FOLDER]: (state, action) => {
            return {
                ...state,
                folderList: { ...state.folderList, selectedFolder: { folder: null, history: [], index: -1 } },
            };
        },
        [UPDATE_MODAL_SELECTED_HISTORY]: (state, action) => {
            return {
                ...state,
                folderList: {
                    ...state.folderList,
                    selectedFolder: {
                        ...state.folderList.selectedFolder,
                        folder: state.folderList.selectedFolder.history[
                            state.folderList.selectedFolder.index + action.payload
                        ],
                        index: state.folderList.selectedFolder.index + action.payload,
                    },
                },
            };
        },
        [GET_DETAIL_MODAL_FOLDER_PENDING]: (state, action) => {
            return {
                ...state,
                detailFolder: {
                    pending: true,
                    error: null,
                },
            };
        },
        [GET_DETAIL_MODAL_FOLDER_SUCCESS]: (state, action) => {
            return {
                ...state,
                detailFolder: {
                    pending: false,
                    error: null,
                },
            };
        },
        [GET_DETAIL_MODAL_FOLDER_FAILURE]: (state, action) => {
            return {
                ...state,
                detailFolder: {
                    pending: false,
                    error: action.payload,
                },
            };
        },
        [MODAL_FOLDERS_SEARCH_STATE]: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
        [UPDATE_MODAL_FOLDER_INFO_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
            };
        },
        [UPDATE_MODAL_FOLDER_INFO_PENDING]: (state, action) => {
            return {
                ...state,
                pending: true,
            };
        },
        [UPDATE_MODAL_FOLDER_INFO_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: action.payload,
            };
        },
        [DELETE_MODAL_FOLDER_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
            };
        },
        [DELETE_MODAL_FOLDER_PENDING]: (state, action) => {
            return {
                ...state,
                pending: true,
            };
        },
        [DELETE_MODAL_FOLDER_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: action.payload,
            };
        },
        [MOVE_MODAL_FOLDER_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
            };
        },
        [MOVE_MODAL_FOLDER_PENDING]: (state, action) => {
            return {
                ...state,
                pending: true,
            };
        },
        [MOVE_MODAL_FOLDER_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: action.payload,
            };
        },
        [TOGGLE_NEW_FOLDER_IN_MODAL]: (state, action) => {
            return {
                ...state,
                folderList: {
                    ...state.folderList,
                    creatingParentFolder: action.payload,
                },
            };
        },
    },
    initialState,
);

export const updateModalFolderState = createAction(MODAL_FOLDERS_UPDATE_STATE);
export const updateModalFolderSearchState = createAction(MODAL_FOLDERS_SEARCH_STATE);
export const updateModalSelectedFolder = createAction(UPDATE_MODAL_SELECTED_FOLDER);
export const updateModalSelectedHistory = createAction(UPDATE_MODAL_SELECTED_HISTORY);
export const resetModalSelectedFolder = createAction(RESET_MODAL_SELECTED_FOLDER);
export const toggleNewFolderInModal = createAction(TOGGLE_NEW_FOLDER_IN_MODAL);

export const getDriveData =
    ({ headerParams, domain }) =>
    (dispatch, getState) => {
        const { stage } = getState();

        dispatch({ type: GET_MODAL_DRIVE_LIST_PENDING });

        return new Promise((resolve, reject) => {
            getDrivesListAPI(stage.endpoint, { headerParams })
                .then(async (driveResponse) => {
                    dispatch({ type: GET_MODAL_DRIVE_LIST_SUCCESS, payload: driveResponse.data.results });

                    let filteredDriveByDomain = driveResponse.data.results;
                    if (compareVersions(stage.version, VERSION_CM_11164_COLLECTION_FOLDER_VIEW) >= 0) {
                        filteredDriveByDomain = driveResponse.data.results?.filter(
                            (drive) => drive.resourceType.type === domain.toUpperCase(),
                        );
                    }

                    resolve(filteredDriveByDomain);
                })
                .catch((error) => {
                    dispatch({ type: GET_MODAL_DRIVE_LIST_FAILURE, payload: error });
                    reject(error);
                });
        });
    };

export const initModalFolderData =
    ({ headerParams, domain }) =>
    (dispatch, getState) => {
        const { stage, project } = getState();

        dispatch({ type: GET_MODAL_FOLDER_TREE_VIEW_PENDING });

        return new Promise((resolve, reject) => {
            dispatch(getDriveData({ headerParams, domain }))
                .then(async (filteredDrive) => {
                    //드라이브 별로 폴더 목록 가져오기
                    const entireFolderMap = await filteredDrive?.reduce(async (prevFolderMap, drive) => {
                        try {
                            const { data: rootFolderData } = await getDetailDriveFolderAPI(stage.endpoint, {
                                driveId: drive.id,
                                folderId: drive.rootFolder.id,
                                projectId: project.id,
                            });

                            let folderMap = {
                                //이전까지의 folderMap에 현재 드라이브 정보 추가
                                ...prevFolderMap,
                                [rootFolderData.id]: {
                                    isRoot: true,
                                    children: [],
                                    name: rootFolderData.name,
                                    type: "drive",
                                    isFold: false,
                                    depth: 0,
                                    id: rootFolderData.id,
                                    driveId: drive.id,
                                    parent: null,
                                },
                            };

                            const driveId = drive.id;
                            const queryParameters = {
                                parentId: drive.rootFolder.id,
                                searchType: "DIRECT_CHILDREN",
                            };
                            let driveInfo = {
                                rootFolder: drive.rootFolder,
                                driveId: driveId,
                                folders: [],
                            };
                            let nextToken;

                            dispatch({ type: GET_MODAL_FOLDERS_LIST_PENDING });
                            do {
                                const folderList = await getFoldersListAPI(stage.endpoint, {
                                    driveId,
                                    queryParameters,
                                    headerParams,
                                    nextToken: nextToken,
                                });
                                driveInfo.folders = [...driveInfo.folders, ...folderList.data.results];
                                nextToken = folderList.data.nextToken;

                                folderMap = createFolderTree(driveInfo, "init", null, folderMap);
                                dispatch({ type: GET_MODAL_FOLDER_TREE_VIEW_PARTIAL_SUCCESS, payload: folderMap });
                            } while (nextToken !== undefined);
                            dispatch({ type: GET_MODAL_FOLDERS_LIST_SUCCESS });

                            return folderMap;
                        } catch (error) {
                            throw new Error(error);
                        }
                    }, {});
                    dispatch({ type: GET_MODAL_FOLDER_TREE_VIEW_SUCCESS });
                    resolve(filteredDrive.length > 0 ? entireFolderMap[filteredDrive[0].rootFolder.id] : null); //확인
                })
                .catch((error) => {
                    console.log(error);
                    dispatch({ type: GET_MODAL_FOLDER_TREE_VIEW_FAILURE, payload: error });
                });
        });
    };

export const getModalFoldersList =
    ({ driveId, queryParameters, headerParams, nextToken }) =>
    (dispatch, getState) => {
        const { stage } = getState();
        const { parentId } = queryParameters;

        dispatch({ type: GET_MODAL_FOLDERS_LIST_PENDING });
        return new Promise(async (resolve, reject) => {
            try {
                let folders = [];

                dispatch({
                    type: GET_MODAL_FOLDERS_LIST_PARTIAL_PENDING,
                    payload: { parentId, nextToken },
                });

                const response = await getFoldersListAPI(stage.endpoint, {
                    driveId,
                    queryParameters,
                    nextToken,
                    headerParams,
                });

                let folderMap = Object.fromEntries(
                    //기존 folderTreeView.folderList.data 그대로 사용시 삭제한 폴더의 정보가 사라지지 않음
                    Object.entries(getState().folderTreeViewModal.folderList.data),
                );

                folders = response.data.results; //init은 맵을 새로 생성하지만 getList는 기존의 맵에서 속성만 추가해주는거라 folders를 누적할 필요는 없을듯

                const allFolderIds = folders.map((folder) => {
                    folderMap[folder.id] = {
                        isRoot: false,
                        children: [],
                        name: folder.name,
                        type: "folder",
                        isFold: true,
                        id: folder.id,
                        driveId: driveId,
                        depth: folder.parent.id ? folderMap[parentId].depth + 1 : 0,
                        parent: parentId,
                        pending: false,
                    };

                    return folder.id;
                });

                const parentFolder = folderMap[parentId];
                parentFolder.children = parentFolder.children.concat(allFolderIds);
                parentFolder.isFold = false;
                parentFolder.isEmpty = folders.length === 0;
                parentFolder.pending = false;
                parentFolder.nextToken = response.data.nextToken;

                dispatch({ type: GET_MODAL_FOLDERS_LIST_PARTIAL_SUCCESS, payload: folderMap });

                dispatch({ type: GET_MODAL_FOLDERS_LIST_SUCCESS });
                resolve();
            } catch (error) {
                console.log(error);
                dispatch({ type: GET_MODAL_FOLDERS_LIST_FAILURE, payload: error });
            }
        });
    };

export const searchModalFolderData =
    ({ headerParams, queryParameters, domain }) =>
    (dispatch, getState) => {
        const { stage, project } = getState();

        dispatch({ type: GET_MODAL_FOLDER_TREE_VIEW_PENDING });
        return new Promise((resolve, reject) => {
            dispatch(getDriveData({ headerParams, domain }))
                .then(async (filteredDrive) => {
                    //드라이브 별로 폴더 목록 가져오기
                    const entireFolderMap = await filteredDrive?.reduce(async (prevFolderMap, drive) => {
                        try {
                            const { data: rootFolderData } = await getDetailDriveFolderAPI(stage.endpoint, {
                                driveId: drive.id,
                                folderId: drive.rootFolder.id,
                                projectId: project.id,
                            });

                            let folderMap = {
                                //이전까지의 folderMap에 현재 드라이브 정보 추가
                                ...prevFolderMap,
                                [rootFolderData.id]: {
                                    isRoot: true,
                                    children: [],
                                    name: rootFolderData.name,
                                    type: "drive",
                                    isFold: false,
                                    depth: 0,
                                    id: rootFolderData.id,
                                    driveId: drive.id,
                                    parent: null,
                                },
                            };
                            const driveId = drive.id;
                            let nextToken;
                            let driveInfo = {
                                driveId: drive.id,
                                rootFolder: drive.rootFolder,
                                folders: [],
                            };

                            do {
                                const folderList = await getFoldersListAPI(stage.endpoint, {
                                    driveId,
                                    queryParameters,
                                    headerParams,
                                    nextToken,
                                });
                                driveInfo.folders = [
                                    ...driveInfo.folders,
                                    ...folderList.data.results.filter((folder) => folder.depth > 0),
                                ];
                                nextToken = folderList.data.nextToken;

                                folderMap = createFolderTree(
                                    driveInfo,
                                    "search",
                                    queryParameters.name ?? queryParameters.id,
                                    folderMap,
                                );
                                dispatch({ type: GET_MODAL_FOLDER_TREE_VIEW_PARTIAL_SUCCESS, payload: folderMap });
                            } while (nextToken !== undefined);

                            return folderMap;
                        } catch (error) {
                            throw new Error(error);
                        }
                    }, {});

                    dispatch({ type: GET_MODAL_FOLDER_TREE_VIEW_SUCCESS });
                    resolve(
                        queryParameters.searchType === DRIVE_FOLDER_SEARCH_TYPE.PARENT_FOLDERS
                            ? entireFolderMap[queryParameters.id]
                            : undefined,
                    );
                })
                .catch((error) => {
                    console.log(error);
                    dispatch({ type: GET_MODAL_FOLDER_TREE_VIEW_FAILURE, payload: error });
                });
        });
    };

export const createModalDriveFolder = (driveId, params) => (dispatch, getState) => {
    const { stage, project } = getState();
    dispatch({ type: CREATE_MODAL_FOLDER_PENDING });
    return new Promise((resolve, reject) => {
        createDriveFolderAPI(stage.endpoint, project.id, driveId, params)
            .then((response) => {
                dispatch({ type: CREATE_MODAL_FOLDER_SUCCESS, payload: response.data });
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                dispatch({ type: CREATE_MODAL_FOLDER_FAILURE, payload: error });
                reject(error);
            });
    });
};

export const getDetailModalDriveFolder =
    ({ driveId, folderId }) =>
    (dispatch, getState) => {
        const { stage, project } = getState();

        dispatch({ type: GET_DETAIL_MODAL_FOLDER_PENDING });
        return new Promise((resolve, reject) => {
            getDetailDriveFolderAPI(stage.endpoint, { driveId, folderId, projectId: project.id })
                .then((response) => {
                    dispatch({ type: GET_DETAIL_MODAL_FOLDER_SUCCESS, payload: response.data });
                    resolve(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    dispatch({ type: GET_DETAIL_MODAL_FOLDER_FAILURE, payload: error });
                    reject(error);
                });
        });
    };

export const updateModalFolderInfo =
    ({ driveId, folderId }, params) =>
    (dispatch, getState) => {
        const { stage, project } = getState();

        dispatch({ type: UPDATE_MODAL_FOLDER_INFO_PENDING });
        return new Promise((resolve, reject) => {
            setFolderInfoAPI(stage.endpoint, { driveId, folderId, projectId: project.id }, params)
                .then((response) => {
                    dispatch({ type: UPDATE_MODAL_FOLDER_INFO_SUCCESS });
                    resolve(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    dispatch({ type: UPDATE_MODAL_FOLDER_INFO_FAILURE, payload: error });
                    reject(error);
                });
        });
    };

export const deleteModalFolder =
    ({ driveId, folderId }) =>
    (dispatch, getState) => {
        const { stage, project } = getState();

        dispatch({ type: DELETE_MODAL_FOLDER_PENDING });
        return new Promise((resolve, reject) => {
            deleteFolderAPI(stage.endpoint, { driveId, folderId, projectId: project.id })
                .then((response) => {
                    dispatch({ type: DELETE_MODAL_FOLDER_SUCCESS });
                    resolve(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    dispatch({ type: DELETE_MODAL_FOLDER_FAILURE, payload: error });
                    reject(error);
                });
        });
    };

export const moveModalFolder =
    ({ driveId }, params) =>
    (dispatch, getState) => {
        const { stage, project } = getState();

        dispatch({ type: MOVE_MODAL_FOLDER_PENDING });
        return new Promise((resolve, reject) => {
            moveFolderAPI(stage.endpoint, { driveId, projectId: project.id }, params)
                .then((response) => {
                    dispatch({ type: MOVE_MODAL_FOLDER_SUCCESS });
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    dispatch({ type: MOVE_MODAL_FOLDER_FAILURE, payload: error });
                    reject(error);
                });
        });
    };
