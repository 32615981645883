import { axios } from "../../cores/axiosWrapper";
import compareVersions, { VERSION_CM_7510_SAFARI_ENCODING } from "@cores/version";

const AssetApi = {
    getStatus,
    activate,
    inactivate,
    createAutoCaption,
    attachPeopleToAsset,
    getPeopleAPI,
    detachPeopleFromAsset,
    createAssetTagAPI,
    deleteAssetTagAPI,
    putArchiveAPI,
    anlayzeAssetAPI,
    getAssetAnalysisObjectsAPI,
    getAssetAnalysisObjectsFromSharedAPI,
    getAssetAnalysisObjectDetailsAPI,
    getAssetAnalysisStatusAPI,
    getAssetAPI,
    getAssetsAPI,
    deleteAssetAPI,
    createAssetElementAPI,
    bulkCreateAssetElementsAPI,
    updateAssetElementAPI,
    deleteAssetElementAPI,
    updateAssetAPI,
    updateAssetOwnerAPI,
    getRelatedVideosAPI,
    getUploadUrlAPI,
    requestAssetElementsDownloadUrlApi,
    getAssetElementsDownloadUrlStatusApi,
    requestAssetsDownloadUrlApi,
    getAssetsDownloadUrlStatusApi,
    getAssetRelatedContentsAPI,
};

export function getStatus(stageId, apiEndpoint, projectId, assetId) {
    const response = axios.get(`${apiEndpoint}/assets/${assetId}/status`, {
        headers: {
            stageId,
            projectId,
        },
    });
    return response;
}

export function activate(stageId, apiEndpoint, projectId, id, version, customErrorMessage) {
    return axios.put(`${apiEndpoint}/assets/${id}/activate`, null, {
        params: {
            version,
        },
        headers: {
            stageId,
            projectId,
            "Content-Type": "application/json",
        },
        customErrorMessage,
    });
}

export function inactivate(stageId, apiEndpoint, projectId, id, version, customErrorMessage) {
    return axios.put(`${apiEndpoint}/assets/${id}/inactivate`, null, {
        params: {
            version,
        },
        headers: {
            stageId,
            projectId,
            "Content-Type": "application/json",
        },
        customErrorMessage,
    });
}

export function createAutoCaption(stageId, apiEndpoint, projectId, id, data) {
    return axios.put(`${apiEndpoint}/assets/${id}/transcribe`, data, {
        headers: {
            stageId,
            projectId,
            "Content-Type": "application/json",
        },
    });
}

export function attachPeopleToAsset(stageId, apiEndpoint, projectId, id, people) {
    return axios.post(`${apiEndpoint}/assets/${id}/people`, people, {
        headers: {
            stageId,
            projectId,
            "Content-Type": "application/json",
        },
    });
}

export function getPeopleAPI(stageId, apiEndpoint, projectId, id) {
    return axios.get(`${apiEndpoint}/people/${id}`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

export function detachPeopleFromAsset(stageId, apiEndpoint, projectId, id, people) {
    return axios.delete(`${apiEndpoint}/assets/${id}/people/${people.id}`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

export function createAssetTagAPI(stageId, apiEndpoint, projectId, assetId, value) {
    const data = {
        value,
    };
    return axios.put(`${apiEndpoint}/assets/${assetId}/hashtag`, data, {
        headers: {
            stageId,
            projectId,
        },
    });
}

export function deleteAssetTagAPI(stageId, apiEndpoint, projectId, assetId, value) {
    return axios.delete(`${apiEndpoint}/assets/${assetId}/hashtag`, {
        headers: {
            stageId,
            projectId,
        },
        params: {
            value,
        },
    });
}

export function putArchiveAPI(apiEndpoint, projectId, assetId, data) {
    return axios.put(`${apiEndpoint}/assets/${assetId}/archive`, data, {
        headers: {
            projectId,
            "Content-Type": "application/json",
        },
    });
}

export async function anlayzeAssetAPI(stageId, apiEndpoint, projectId, assetId, input, types, resourceToken) {
    // mp4여야만 제대로 동작 한다.
    // 필요하면 mp4인지 유효성 체크를 한다.
    // 현재는 API 콜은 성공하고 Status 가져오는 부분에서 실패 처리 된다.

    const s3Object = input.toS3Object();
    const video = {
        s3Object: {
            bucket: s3Object.bucket,
            name: s3Object.key,
        },
    };
    let faceSearch = {};
    if (types.filter((t) => t === "FaceSearch").length > 0) {
        const faceCollectionsResult = await axios.get(`${apiEndpoint}/analysis/face-collections`, {
            headers: {
                stageId,
                projectId,
            },
            params: {
                offset: 0,
                limit: 1,
            },
        });
        if (
            faceCollectionsResult &&
            faceCollectionsResult.data &&
            faceCollectionsResult.data.faceCollections &&
            faceCollectionsResult.data.faceCollections.length > 0
        ) {
            faceSearch = {
                collectionId: faceCollectionsResult.data.faceCollections[0].name,
            };
        } else {
            throw new Error("Collection is not registered. Please contact to admin.");
        }
    }
    const data = {
        confidence: 80,
        faceSearch,
        types,
        video,
    };
    return axios.post(`${apiEndpoint}/assets/${assetId}/analysis`, data, {
        headers: {
            stageId,
            projectId,
        },
    });
}

export function createAnalysisJobAPI(apiEndpoint, projectId, data) {
    return axios.post(`${apiEndpoint}/ai/analysis`, data, {
        headers: {
            projectId,
        },
    });
}

export function getAssetAnalysisObjectsAPI(
    stageId,
    apiEndpoint,
    projectId,
    assetId,
    type,
    offset,
    limit,
    resourceToken,
) {
    return axios.get(`${apiEndpoint}/assets/${assetId}/analysis/objects`, {
        headers: {
            stageId,
            projectId,
            "x-mzc-cp-resource-token": resourceToken,
        },
        params: {
            offset,
            limit,
            type: type,
        },
    });
}

export function getAssetAnalysisObjectsFromSharedAPI(
    stageId,
    apiEndpoint,
    projectId,
    assetId,
    type,
    offset,
    limit,
    resourceToken,
) {
    return axios.get(`${apiEndpoint}/v2/shared-assets/proxy/assets/${assetId}/analysis/objects`, {
        headers: {
            stageId,
            projectId,
            "x-mzc-cp-resource-token": resourceToken,
        },
        params: {
            offset,
            limit,
            type: type,
        },
    });
}

export function getAssetAnalysisObjectDetailsAPI({
    stageId,
    apiEndpoint,
    projectId,
    assetId,
    type,
    key,
    value,
    nextToken,
}) {
    const params = {
        type,
        key,
        value,
        groupBy: "TIMESTAMP",
    };
    if (nextToken) params.nextToken = nextToken;

    return axios.get(`${apiEndpoint}/assets/${assetId}/analysis/object-details`, {
        headers: {
            stageId,
            projectId,
        },
        params,
    });
}

export function getAssetAnalysisStatusAPI(stageId, apiEndpoint, projectId, assetId, resourceToken) {
    return axios.get(`${apiEndpoint}/assets/${assetId}/analysis/status`, {
        headers: {
            stageId,
            projectId,
            "x-mzc-cp-resource-token": resourceToken,
        },
    });
}

export function getAssetAPI(stageId, apiEndpoint, projectId, id) {
    return axios.get(`${apiEndpoint}/assets/${id}?generateAccessUrl=true`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

export function getAssetsAPI(stageId, apiEndpoint, projectId, query, resourceToken) {
    let queries = {
        ...query,
        offset: 0,
        limit: 99999,
        generateAccessUrl: true,
    };
    return axios.get(`${apiEndpoint}/assets`, {
        params: queries,
        headers: {
            stageId,
            projectId,
            "x-mzc-cp-resource-token": resourceToken,
        },
    });
}

export function deleteAssetAPI(stageId, apiEndpoint, projectId, id, version) {
    return axios.delete(`${apiEndpoint}/assets/${id}`, {
        params: {
            version,
            deleteAIJobs: true,
        },
        headers: {
            stageId,
            projectId,
        },
    });
}

export function createAssetElementAPI(stageId, apiEndpoint, projectId, id, version, data) {
    return axios.post(`${apiEndpoint}/assets/${id}/elements`, data, {
        params: {
            version,
        },
        headers: {
            stageId,
            projectId,
        },
    });
}

export function bulkCreateAssetElementsAPI(stageId, stageVersion, apiEndpoint, projectId, id, version, data) {
    let headers = {
        stageId,
        projectId,
    };

    if (compareVersions(stageVersion, VERSION_CM_7510_SAFARI_ENCODING) >= 0) {
        data = Buffer.from(encodeURIComponent(JSON.stringify(data))).toString("base64");
        headers = {
            ...headers,
            "x-mzc-content-encoding": "base64",
        };
    }

    return axios.post(`${apiEndpoint}/assets/${id}/elements/bulk`, data, {
        params: {
            version,
        },
        headers,
    });
}

export function updateAssetElementAPI(stageId, apiEndpoint, projectId, id, version, elementId, data) {
    return axios.patch(`${apiEndpoint}/assets/${id}/elements/${elementId}`, data, {
        params: {
            version,
            generateAccessUrl: true,
        },
        headers: {
            stageId,
            projectId,
        },
    });
}

export function deleteAssetElementAPI(stageId, apiEndpoint, projectId, id, version, elementId) {
    return axios.delete(`${apiEndpoint}/assets/${id}/elements/${elementId}`, {
        params: {
            version,
        },
        headers: {
            stageId,
            projectId,
        },
    });
}

// TODO : https://mzdevs.atlassian.net/browse/CM-3857
export function updateAssetAPI(stageId, apiEndpoint, projectId, id, version, updateTotalSize, data) {
    if (data.categories && data.categories.length === 0) {
        data.categories = [{}];
    }

    if (data.attributions && data.attributions.length === 0) {
        data.attributions = [{}];
    }

    if (data.tags && data.tags.length === 0) {
        data.tags = [{}];
    }

    return axios.patch(`${apiEndpoint}/assets/${id}`, data, {
        params: {
            version,
            updateTotalSize,
            generateAccessUrl: true,
        },
        headers: {
            stageId,
            projectId,
        },
    });
}

export function updateAssetOwnerAPI(stageId, apiEndpoint, projectId, id, version, data) {
    return axios.put(`${apiEndpoint}/assets/${id}/owner`, data, {
        params: {
            version,
            generateAccessUrl: true,
        },
        headers: {
            stageId,
            projectId,
        },
    });
}

export function getRelatedVideosAPI(stageId, apiEndpoint, projectId, id, resourceToken) {
    return axios.get(`${apiEndpoint}/videos`, {
        params: {
            assetId: id,
        },
        headers: {
            stageId,
            projectId,
            "x-mzc-cp-resource-token": resourceToken,
        },
    });
}

export function getUploadUrlAPI(stageId, apiEndpoint, projectId, assetId, params) {
    return axios.get(`${apiEndpoint}/assets/${assetId}/upload-url`, {
        headers: {
            stageId,
            projectId,
        },
        params,
    });
}

export function requestAssetElementsDownloadUrlApi(
    stageId,
    apiEndpoint,
    projectId,
    assetId,
    elementIds,
    resourceToken,
) {
    return axios.post(
        `${apiEndpoint}/assets/${assetId}/elements/download`,
        {
            elementIds,
        },
        {
            headers: {
                stageId,
                projectId,
                "x-mzc-cp-resource-token": resourceToken,
            },
        },
    );
}

export function getAssetElementsDownloadUrlStatusApi(
    stageId,
    apiEndpoint,
    projectId,
    assetId,
    downloadId,
    resourceToken,
) {
    return axios.get(`${apiEndpoint}/assets/${assetId}/elements/download`, {
        headers: {
            stageId,
            projectId,
            "x-mzc-cp-resource-token": resourceToken,
        },
        params: {
            downloadId,
        },
    });
}

export function requestAssetsDownloadUrlApi(stageId, apiEndpoint, projectId, assetIds, resourceToken) {
    return axios.post(
        `${apiEndpoint}/assets/download`,
        {
            assetIds,
        },
        {
            headers: {
                stageId,
                projectId,
                "x-mzc-cp-resource-token": resourceToken,
            },
        },
    );
}

export function getAssetsDownloadUrlStatusApi(stageId, apiEndpoint, projectId, downloadId, resourceToken) {
    return axios.get(`${apiEndpoint}/assets/download`, {
        headers: {
            stageId,
            projectId,
            "x-mzc-cp-resource-token": resourceToken,
        },
        params: {
            downloadId,
        },
    });
}

export function getAssetElementVersionsAPI(stageId, apiEndpoint, projectId, id, elementId, params, resourceToken) {
    return axios.get(`${apiEndpoint}/assets/${id}/elements/${elementId}/versions`, {
        params,
        headers: {
            stageId,
            projectId,
            "x-mzc-cp-resource-token": resourceToken,
        },
    });
}

export function getDictationVocabulariesAPI(stageId, apiEndpoint, projectId, params) {
    return axios.get(`${apiEndpoint}/dictation-vocabularies`, {
        params,
        headers: {
            stageId,
            projectId,
        },
    });
}

export function createAutoCaptionsAPI(stageId, apiEndpoint, projectId, data) {
    return axios.post(`${apiEndpoint}/ai/captions`, data, {
        headers: {
            stageId,
            projectId,
        },
    });
}

export function getAiCaptionsAPI(stageId, apiEndpoint, projectId, params) {
    return axios.get(`${apiEndpoint}/ai/captions`, {
        params,
        headers: {
            stageId,
            projectId,
        },
    });
}

export function getAiAnalysisAPI(stageId, apiEndpoint, projectId, params) {
    return axios.get(`${apiEndpoint}/ai/analysis`, {
        params,
        headers: {
            stageId,
            projectId,
        },
    });
}

export function getAiAnalysisResultsAPI(stageId, apiEndpoint, projectId, analysisId, params) {
    return axios.get(`${apiEndpoint}/ai/analysis/${analysisId}/results`, {
        params,
        headers: {
            stageId,
            projectId,
        },
    });
}

export function getAiAnalysisSummariesAPI(stageId, apiEndpoint, projectId, analysisId, params) {
    return axios.get(`${apiEndpoint}/ai/analysis/${analysisId}/summaries`, {
        params,
        headers: {
            stageId,
            projectId,
        },
    });
}

export function getDownloadAiAnalysisLinksAPI(stageId, apiEndpoint, projectId, data) {
    return axios.put(`${apiEndpoint}/ai/analysis:download-links`, data, {
        headers: {
            stageId,
            projectId,
        },
    });
}

export function getAiOriginalResultsAPI(stageId, apiEndpoint, projectId, analysisId, params) {
    return axios.get(`${apiEndpoint}/ai/analysis/${analysisId}/original-results`, {
        params,
        headers: {
            stageId,
            projectId,
        },
    });
}

export function getAiOriginalResultAPI(stageId, apiEndpoint, projectId, analysisId, resultId) {
    return axios.get(`${apiEndpoint}/ai/analysis/${analysisId}/original-results/${resultId}`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

export function copyAssetAPI(stageId, apiEndpoint, projectId, data) {
    return axios.post(`${apiEndpoint}/assets:copy`, data, {
        headers: {
            stageId,
            projectId,
        },
    });
}

/**
 *
 * @param {string} stageId stage id
 * @param {string} apiEndpoint media api endpoint
 * @param {string} projectId project id
 * @param {string} id asset id
 * @param {string} version asset version
 * @param {string} [resourceToken] shared asset resource token
 * @param {array} [elementids] elementIds
 * @returns
 */
export function getAssetRelatedContentsAPI(stageId, apiEndpoint, projectId, id, version, resourceToken, elementIds) {
    const headers = {
        stageId,
        projectId,
    };
    if (resourceToken) headers["x-mzc-cp-resource-token"] = resourceToken;
    const params = { elementIds: elementIds && elementIds.join(",") };
    return axios.get(`${apiEndpoint}/assets/${id}/related-contents`, {
        params,
        headers,
    });
}

export const getAssetLinkedAssetsAPI = async (apiEndpoint, projectId, assetId, params) => {
    return axios.get(`${apiEndpoint}/assets/${assetId}/linked-assets`, {
        params,
        headers: {
            projectId,
        },
    });
};

export const addAssetLinkedAssetsAPI = async (apiEndpoint, projectId, assetId, data) => {
    return axios.post(`${apiEndpoint}/assets/${assetId}/linked-assets`, data, { headers: { projectId } });
};

export const removeAssetLinkedAssetsAPI = async (apiEndpoint, projectId, assetId, linkedAssetId) => {
    return axios.delete(`${apiEndpoint}/assets/${assetId}/linked-assets/${linkedAssetId}`, { headers: { projectId } });
};

export default AssetApi;
