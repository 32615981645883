import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDetailDriveFolder } from "@modules/folderTreeView";
import { getDriveDetailAPI, getDrivesListAPI } from "@modules/apis/virtureFolder";
import moment from "moment";

const useVirtualFolderPath = ({ domain }) => {
    const dispatch = useDispatch();

    const stage = useSelector(({ stage }) => stage);
    const project = useSelector(({ project }) => project);

    const getVirtualFolder = async ({ folderId }) => {
        const headerParams = {
            projectId: project.id,
        };
        const drives = await getDrivesListAPI(stage.endpoint, { headerParams });
        const drive = drives.data?.results?.find((el) => el.resourceType?.type === domain);
        const driveId = drive.id;

        const folderPath = await dispatch(
            getDetailDriveFolder({
                driveId,
                folderId,
            }),
        );

        return { drive, folderPath };
    };

    const getVirtualFolderFullPath = async ({ folderId }) => {
        if (!folderId) return undefined;
        try {
            const { drive, folderPath } = await getVirtualFolder({ folderId });
            const driveName = drive.name;

            if (folderPath.parents.length > 0) {
                const fullPath = folderPath.parents.reverse().reduce(
                    (acc, cur) => {
                        if (cur.depth === 0) return `/${acc}`;
                        return `${cur.name}/${acc}`;
                    },
                    `${folderPath.depth === 0 ? "/" : folderPath.name}`,
                );

                return `${driveName}${fullPath}`;
            } else {
                return driveName;
            }
        } catch (e) {
            console.log(e);
            return undefined;
        }
    };

    const getVirtualFolderFullPathList = async ({ folderId }) => {
        const { drive, folderPath } = await getVirtualFolder({ folderId });

        if (folderPath.parents.length > 0) {
            folderPath.parents[0].name = drive.name;
            return folderPath.parents.concat(folderPath);
        } else {
            return [drive];
        }
    };

    const getLocalStorageRecentLocation = async () => {
        const key = domain.toLowerCase();
        const recentLocation = JSON.parse(localStorage.getItem(`${key}RecentLocation`)) || [];
        let newLocations = [];
        try {
            const filteredRecentLocation = recentLocation?.filter((el) => el.projectId && el.stageId);
            localStorage.setItem(`${key}RecentLocation`, JSON.stringify(filteredRecentLocation));

            const detailPromises = recentLocation
                .filter((el) => el.stageId === stage.id && el.projectId === project.id)
                .sort((a, b) => b.createdAt - a.createdAt)
                ?.map(async ({ value }) => {
                    const folderId = value;
                    const label = await getVirtualFolderFullPath({ folderId });
                    if (label) newLocations.push({ value, label });
                });
            await Promise.all(detailPromises);
            return newLocations;
        } catch (e) {}
    };

    const setLocalStorageRecentLocation = ({ folderId }) => {
        const key = domain.toLowerCase();
        const recentLocation = JSON.parse(localStorage.getItem(`${key}RecentLocation`)) || [];
        if (!recentLocation.find((item) => item.value === folderId)) {
            const current = moment().format("YYYY-MM-DD HH:mm:ss");
            const newObj = { stageId: stage.id, projectId: project.id, value: folderId, createdAt: current };
            const newRecentLocation = [newObj].concat(recentLocation);
            localStorage.setItem(`${key}RecentLocation`, JSON.stringify(newRecentLocation));
        }
    };

    const getDriveName = useCallback(
        async ({ driveId }) => {
            const driveInfo = await getDriveDetailAPI(stage.endpoint, {
                driveId,
                headerParams: { projectId: project.id },
            });
            return driveInfo.data.name;
        },
        [stage, project],
    );

    return {
        getVirtualFolderFullPath,
        getVirtualFolderFullPathList,
        getLocalStorageRecentLocation,
        setLocalStorageRecentLocation,
        getDriveName,
    };
};

export default useVirtualFolderPath;
