import { createAction, handleActions } from "redux-actions";
import { getCustomContentsAPI } from "@modules/apis/custom-contents";
import { LIST_VIEW_TYPE } from "@constants";
import { deleteVocabularyAPI, getVocabulariesAPI } from "@modules/apis/vocabularies";
import {
    getVocabularyAPI,
    getVocabularyPhrasesAPI,
    updateVocabularyAPI,
    createVocabularyAPI,
    requestPhrasesActionsAPI,
} from "@modules/apis/vocabulary";

const initialState = {
    data: null,
    phrases: { data: null, pending: false, error: false },
    pending: false,
    error: false,
    isCardView: true,
    create: { data: null, pending: false, error: false },
};

const GET_VOCABULARY_PENDING = "GET_VOCABULARY_PENDING";
const GET_VOCABULARY_SUCCESS = "GET_VOCABULARY_SUCCESS";
const GET_VOCABULARY_FAILURE = "GET_VOCABULARY_FAILURE";
const GET_VOCABULARY_PHRASES_PENDING = "GET_VOCABULARY_PHRASES_PENDING";
const GET_VOCABULARY_PHRASES_SUCCESS = "GET_VOCABULARY_PHRASES_SUCCESS";
const GET_VOCABULARY_PHRASES_FAILURE = "GET_VOCABULARY_PHRASES_FAILURE";
const UPDATE_VOCABULARY_SUCCESS = "UPDATE_VOCABULARY_SUCCESS";
const CREATE_VOCABULARY_SUCCESS = "CREATE_VOCABULARY_SUCCESS";
const CREATE_PHRASES_SUCCESS = "CREATE_PHRASES_SUCCESS";
const CREATE_PHRASES_FAILURE = "CREATE_PHRASES_FAILURE";
const UPDATE_VOCABULARY_PHRASES_SUCCESS = "UPDATE_VOCABULARY_PHRASES_SUCCESS";

export default handleActions(
    {
        [GET_VOCABULARY_PENDING]: (state) => {
            return { ...state, pending: true, data: null, error: false };
        },
        [GET_VOCABULARY_SUCCESS]: (state, action) => {
            return { ...state, data: action.payload, pending: false };
        },
        [GET_VOCABULARY_FAILURE]: (state, action) => {
            return { ...state, pending: false, error: action.payload };
        },
        [GET_VOCABULARY_PHRASES_PENDING]: (state) => {
            return { ...state, phrases: { pending: true, data: null, error: null } };
        },
        [GET_VOCABULARY_PHRASES_SUCCESS]: (state, action) => {
            return { ...state, phrases: { ...state.phrases, pending: false, data: action.payload } };
        },
        [GET_VOCABULARY_PHRASES_FAILURE]: (state, action) => {
            return { ...state, phrases: { ...state.phrases, pending: false, error: action.payload } };
        },
        [UPDATE_VOCABULARY_SUCCESS]: (state, action) => {
            return { ...state, data: { ...state.data, ...action.payload } };
        },
        [CREATE_VOCABULARY_SUCCESS]: (state, action) => {
            return {
                ...state,
                create: { ...state.create, data: action.payload },
            };
        },
        [CREATE_PHRASES_SUCCESS]: (state, action) => {
            return {
                ...state,
                create: { data: { ...state.create.data, ...action.payload }, error: null },
            };
        },
        [CREATE_PHRASES_FAILURE]: (state, action) => {
            return {
                ...state,
                create: { ...state.create, error: action.payload },
            };
        },
        [UPDATE_VOCABULARY_PHRASES_SUCCESS]: (state, action) => {
            return {
                ...state,
                phrases: { ...state.phrases, data: action.payload },
            };
        },
    },
    initialState,
);

export const makePhraseGroupByDisplayAs = (phrases) => {
    const phraseGroup = {};

    phrases.forEach((phrase) => {
        phraseGroup[phrase.displayAs] = [...(phraseGroup[phrase.displayAs] ?? []), phrase];
    });
    return Object.entries(phraseGroup).map(([displayAs, phrase], index) => ({ id: index, displayAs, phrase }));
};

export const getVocabulary = (vocabularyId) => (dispatch, getState) => {
    const { stage, project, vocabularies } = getState();

    return new Promise(async (resolve, reject) => {
        try {
            dispatch({
                type: GET_VOCABULARY_PENDING,
            });
            const { data } = await getVocabularyAPI(stage.id, stage.endpoint, project.id, vocabularyId);

            dispatch({
                type: GET_VOCABULARY_SUCCESS,
                payload: { ...data },
            });

            dispatch(getVocabularyPhrases(vocabularyId));

            resolve();
        } catch (error) {
            dispatch({ type: GET_VOCABULARY_FAILURE, payload: error });
            reject(null);
        }
    });
};

export const getVocabularyPhrases = (vocabularyId, params) => (dispatch, getState) => {
    const { stage, project, vocabularies } = getState();

    return new Promise(async (resolve, reject) => {
        try {
            dispatch({
                type: GET_VOCABULARY_PHRASES_PENDING,
            });
            let results = [];
            let nextToken = null;

            do {
                const { data } = await getVocabularyPhrasesAPI(stage.id, stage.endpoint, project.id, vocabularyId, {
                    ...params,
                    nextToken,
                });
                results = [...results, ...data.results];
                nextToken = data.nextToken;
            } while (!!nextToken);

            const phraseGroup = makePhraseGroupByDisplayAs(results);

            dispatch({
                type: GET_VOCABULARY_PHRASES_SUCCESS,
                payload: phraseGroup,
            });

            resolve();
        } catch (error) {
            dispatch({ type: GET_VOCABULARY_PHRASES_FAILURE, payload: error });
            console.log(error);
            reject(null);
        }
    });
};

export const updateVocabulary = (vocabularyId, data) => (dispatch, getState) => {
    const { stage, project, vocabularies } = getState();

    return new Promise(async (resolve, reject) => {
        try {
            await updateVocabularyAPI(stage.id, stage.endpoint, project.id, vocabularyId, data);

            dispatch({
                type: UPDATE_VOCABULARY_SUCCESS,
                payload: data,
            });

            resolve();
        } catch (error) {
            dispatch({ type: GET_VOCABULARY_FAILURE, payload: error });
            reject(null);
        }
    });
};

export const createVocabulary = (vocabularyData, phrasesData) => (dispatch, getState) => {
    const { stage, project, vocabulary } = getState();

    return new Promise(async (resolve, reject) => {
        try {
            const createPhrases = async (vocabularyId) => {
                await requestPhrasesActionsAPI(stage.id, stage.endpoint, project.id, vocabularyId, phrasesData)
                    .then((response) => {
                        dispatch({ type: CREATE_PHRASES_SUCCESS });
                    })
                    .catch((e) => {
                        dispatch({ type: CREATE_PHRASES_FAILURE, payload: e.response?.data });
                        reject();
                    });
            };

            if (vocabulary.create.data?.id && vocabulary.create.error && phrasesData)
                await createPhrases(vocabulary.create.data?.id)
                    .then(() => resolve())
                    .catch(() => reject());
            else {
                await createVocabularyAPI(stage.id, stage.endpoint, project.id, vocabularyData).then(
                    async (response) => {
                        if (phrasesData) {
                            const vocabularyId = response.data?.id;
                            dispatch({ type: CREATE_VOCABULARY_SUCCESS, payload: response.data });
                            await createPhrases(vocabularyId)
                                .then(() => resolve())
                                .catch(() => reject());
                        }
                    },
                );
            }
        } catch (error) {
            reject(null);
        }
    });
};

export const updatePhrases = (vocabularyId, data) => (dispatch, getState) => {
    const { stage, project, vocabulary } = getState();

    return new Promise(async (resolve, reject) => {
        try {
            const phrasesActionsPromises = data.map((el) => {
                return new Promise(async (resolve, reject) => {
                    try {
                        await requestPhrasesActionsAPI(stage.id, stage.endpoint, project.id, vocabularyId, el)
                            .then((response) => {
                                const originData = vocabulary.phrases.data;
                                const ids = response.data?.data?.ids;
                                let newData = [...originData];

                                if (el.type === "CREATE") {
                                    el.data.phrases.forEach((el, index) => {
                                        const displayAs = newData.find((v) => v.displayAs === el.displayAs);
                                        if (displayAs) {
                                            el.id = ids[index];
                                            displayAs.phrase = displayAs.phrase.concat([el]);
                                        }
                                    });
                                    dispatch({
                                        type: UPDATE_VOCABULARY_PHRASES_SUCCESS,
                                        payload: newData,
                                    });
                                } else if (el.type === "DELETE") {
                                    let data = [];
                                    ids.forEach((id) => {
                                        data = newData.map((v) => ({
                                            ...v,
                                            phrase: v.phrase.filter((p) => p.id !== id),
                                        }));
                                    });
                                    dispatch({
                                        type: UPDATE_VOCABULARY_PHRASES_SUCCESS,
                                        payload: data,
                                    });
                                } else if (el.type === "UPDATE") {
                                    let newPhrase = [];
                                    const phrases = el.data?.phrases;
                                    newPhrase = newData.map((el) => ({
                                        ...el,
                                        phrase: el.phrase.map((v) => {
                                            const id = ids?.find((i) => i === v.id);
                                            const data = phrases.find((p) => p.id === id);
                                            return data ?? v;
                                        }),
                                        displayAs: el.phrase.map((v) => {
                                            const id = ids?.find((i) => i === v.id);
                                            const data = phrases.find((p) => p.id === id);
                                            return data ? data.displayAs : v.displayAs;
                                        })?.[0],
                                    }));
                                    // console.log(newPhrase);
                                    dispatch({
                                        type: UPDATE_VOCABULARY_PHRASES_SUCCESS,
                                        payload: newPhrase,
                                    });
                                }

                                resolve();
                            })
                            .catch((e) => {
                                reject(e);
                            });
                    } catch (e) {
                        reject();
                    }
                });
            });

            await Promise.all(phrasesActionsPromises).then((response) => {
                resolve(response);
            });
        } catch (error) {
            reject(null);
        }
    });
};
