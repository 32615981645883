import React, { useState } from "react";
import { useSelector } from "react-redux";
import FileView from "@components/view/FileView";
import PanelBodyInformation from "@components/property-panel/ui/PanelBodyInformation";
import PanelBodyInfoContents from "@components/property-panel/ui/PanelBodyInfoContents";
import InformationTools from "@components/tabs/InformationTools";
import { STORAGE_SHAREDBOX_PANEL_TABS } from "@constants";
import ScrollArea from "@components/ScrollArea";
import { PROPERTY_PANEL_TYPE } from "@constants";
import SharedWithTab from "@components/property-panel/components/shared-with-tab";

const PanelBodyInfo = ({ onClickClose }) => {
    const [activeTab, setActiveTab] = useState(STORAGE_SHAREDBOX_PANEL_TABS[0].value);

    const onHandelChangedTab = (tab) => {
        setActiveTab(tab);
    };

    const { propertyPanel } = useSelector(({ propertyPanel }) => ({
        propertyPanel,
    }));

    return (
        <PanelBodyInformation>
            <InformationTools
                tabs={
                    propertyPanel.type === PROPERTY_PANEL_TYPE.SHARED_STORAGE_WITH
                        ? [STORAGE_SHAREDBOX_PANEL_TABS[0]]
                        : STORAGE_SHAREDBOX_PANEL_TABS
                }
                activeTab={activeTab}
                handleOnChangeTab={onHandelChangedTab}
            ></InformationTools>
            <PanelBodyInfoContents>
                {activeTab == STORAGE_SHAREDBOX_PANEL_TABS[0].value ? (
                    <ScrollArea>
                        <FileView {...propertyPanel.data} />
                    </ScrollArea>
                ) : (
                    <SharedWithTab onClickClose={onClickClose} />
                )}
            </PanelBodyInfoContents>
        </PanelBodyInformation>
    );
};

export default PanelBodyInfo;
