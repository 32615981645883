import { Box, Chip, Grid, IconButton, ListItemIcon, ListItemText, MenuItem, palette, Select } from "@mzc-pdc/ui";
import { IconRefresh, IconRequired, ImageCdnAmazon, ImageCdnCloudfront } from "@mzc-cloudplex/icons";
import theme from "@styles/theme";
import React, { useCallback } from "react";
import Typography from "@components_v2/typography/typography";

const SelectWithRefreshButton = ({ items, itemProps, onClickRefresh, ...props }) => {
    return (
        <Grid container alignItems={"flex-end"} flexDirection={"column"}>
            <Grid container flexDirection={"row"}>
                <Grid item flex={1}>
                    <Select {...props} displayEmpty>
                        <MenuItem disabled value="" hidden>
                            <em>{props.placeholder}</em>
                        </MenuItem>
                        {items &&
                            items.map((el) => (
                                <MenuItem value={el.value} key={`select-with-refresh-${el.value}`}>
                                    <Grid container flexDirection={"row"} gap={1}>
                                        <Grid item>
                                            <Typography variant={itemProps.variant} color={itemProps.color}>
                                                {el.label}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </MenuItem>
                            ))}
                    </Select>
                </Grid>
                <Grid
                    item
                    height={"40px"}
                    borderTop={`solid 1px ${palette.greySecondary[300]}`}
                    borderRight={`solid 1px ${palette.greySecondary[300]}`}
                    borderBottom={`solid 1px ${palette.greySecondary[300]}`}
                    borderRadius={"0px 3px 3px 0px"}
                >
                    <IconButton
                        disabled={props.disabled}
                        color={"inherit"}
                        variant={"outlined"}
                        size={"large"}
                        sx={{ borderRadius: "0px", border: "none" }}
                        onClick={onClickRefresh}
                    >
                        <IconRefresh size={17} color={"inherit"} />
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SelectWithRefreshButton;
