import { Box, Grid, IconButton, LinearProgress, Stack, Tooltip } from "@mzc-pdc/ui";
import AssetTypeFlag from "@components_v2/icon-type/asset/asset-type-flag";
import { GridNameEllipsis } from "@components_v2/grid/grid.styled";
import Typography from "@components_v2/typography/typography";
import { IconErrorCircle, IconHierarchyFolded, IconPath } from "@mzc-cloudplex/icons";
import theme from "@styles/theme";
import ObjectIcon from "@routes/sources/components/ObjectIcon";
import { Trans } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { COMMON_JOB_STATUS, UPLOAD_STATUS } from "@constants";
import useVirtualFolderPath from "@hooks/useVirtualFolderPath";
import { LinearProgressDeterminate } from "@components_v2/progressbar/progressbar";

const JobTaskCreateAsset = ({ job, tasks, metadata }) => {
    const { getVirtualFolderFullPath } = useVirtualFolderPath({ domain: "ASSET" });

    const [folderPath, setFolderPath] = useState(undefined);

    const mainAsset = useMemo(() => {
        return tasks?.[0] ?? "";
    }, [tasks]);

    useEffect(async () => {
        const path = await getVirtualFolderFullPath({ folderId: metadata.folderId });
        setFolderPath(path);
    }, [metadata.folderId]);

    return (
        <>
            <Stack py={1.5} gap={2}>
                <Box>
                    <Grid container flexWrap={"nowrap"}>
                        <Grid item sx={{ display: "flex", alignItems: "center" }}>
                            <Grid item>
                                <AssetTypeFlag width={20} height={16} type={mainAsset?.metadata?.mediaType} />
                            </Grid>
                            <GridNameEllipsis item maxWidth={"250px"}>
                                <Typography variant="body3_400">{mainAsset?.name}</Typography>
                            </GridNameEllipsis>
                            {tasks?.length > 1 && (
                                <>
                                    <Typography variant="body3_400">
                                        <Trans
                                            i18nKey="common::label::and <emp>{{count}}</emp> others"
                                            components={{
                                                emp: <Typography variant="body3_700" color="primary.main"></Typography>,
                                            }}
                                            values={{
                                                count: tasks?.length - 1,
                                            }}
                                        />
                                    </Typography>
                                </>
                            )}
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        sx={{ display: "flex", ml: theme.spacing(3.5), mt: theme.spacing(1), gap: theme.spacing(1) }}
                    >
                        <Grid item>
                            <IconPath size={16} htmlColor={theme.palette.greySecondary[500]} />
                        </Grid>
                        <Grid item>
                            <Typography variant="body3_400" color={theme.palette.greySecondary[600]}>
                                {folderPath ?? "-"}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Grid container flexWrap={"nowrap"} gap={1} ml={theme.spacing(1)} flexDirection={"column"}>
                    {tasks?.map((task) => (
                        <Grid container flexDirection={"column"} gap={1}>
                            {tasks.length > 1 && (
                                <Grid container justifyContent={"space-between"}>
                                    <Grid item sx={{ display: "flex" }}>
                                        <Grid item mr={theme.spacing(1)}>
                                            <IconHierarchyFolded
                                                size={6}
                                                htmlColor={theme.palette.greySecondary[500]}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <AssetTypeFlag width={20} height={16} type={task.metadata.mediaType} />
                                        </Grid>
                                        <GridNameEllipsis item maxWidth={"240px"}>
                                            <Typography variant="body2_500">{task.name}</Typography>
                                        </GridNameEllipsis>
                                    </Grid>
                                    {task.status === UPLOAD_STATUS.ERROR && (
                                        <Grid item>
                                            <IconButton circled size="small">
                                                <IconErrorCircle size={16} color="error" />
                                            </IconButton>
                                        </Grid>
                                    )}
                                </Grid>
                            )}
                            {task.files?.map((file) => (
                                <Grid
                                    container
                                    flexDirection={"column"}
                                    gap={1}
                                    pl={theme.spacing(tasks.length > 1 ? 2 : 1)}
                                    pr={theme.spacing(1)}
                                >
                                    <Grid container justifyContent={"space-between"}>
                                        <Grid item sx={{ display: "flex", gap: theme.spacing(1) }}>
                                            <Grid item>
                                                <ObjectIcon path={file.name} />
                                            </Grid>
                                            <GridNameEllipsis item maxWidth={"250px"}>
                                                <Typography variant="body2_500">{file.name}</Typography>
                                            </GridNameEllipsis>
                                        </Grid>
                                        {file.status === UPLOAD_STATUS.ERROR && (
                                            <Grid item>
                                                <IconButton circled size="small">
                                                    <IconErrorCircle size={16} color="error" />
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Grid>
                                    {job.status === COMMON_JOB_STATUS.RUNNING && (
                                        <Grid item pl={theme.spacing(2)}>
                                            <LinearProgressDeterminate
                                                value={file.uploadFinished ? 100 : file.uploadedPercentage ?? 0}
                                                withLabel={true}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            ))}
                        </Grid>
                    ))}
                </Grid>
            </Stack>
        </>
    );
};

export default JobTaskCreateAsset;
