import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";
import { withRouter, useLocation } from "react-router-dom";
import WithSelect from "../../../components/select";
import * as projectActions from "../../../modules/project";
import * as createAssetsBulkActions from "@modules/createAssetsBulk";
import compareVersions, { VERSION_CM_5058_DASHBOARD } from "../../../cores/version";
import config from "../../../config";
import { isLocal } from "../../../cores/enviromentExtension";
import { siteMode } from "../../../cores/siteMode";
import menuService from "@services/menuService";
import { Avatar, Box, Grid, IconButton, ListItem, Typography } from "@mzc-pdc/ui";
import { IconArrowDropDown, IconArrowDropUp, IconSearch } from "@mzc-cloudplex/icons";
import { getProjectRandomColor } from "@utils/get-random-color";

const isCms = siteMode.get() === "CMS";
import theme from "@styles/theme";
import { components } from "react-select";
import { resetProjectPermissions } from "@modules/rolesManagement_v2";

const Menu = (props) => {
    return (
        <Box
            sx={{
                position: `absolute`,
                left: 0,
                right: 0,
                top: `100%`,
                backgroundColor: theme.palette.background.default,
            }}
            {...props}
        />
    );
};
const Blanket = (props) => <div {...props} />;

const Dropdown = ({ children, isOpen, target, onClose, navOpen, type }) => (
    <>
        <Box
            sx={{
                position: `relative`,
                zIndex: 1,
                backgroundColor: isOpen ? theme.palette.background.default : "",
                margin: type === "cms" ? 0 : navOpen ? "12px 12px 0 6px" : "12px 0 0",
            }}
        >
            {target}
            {navOpen && (
                <>
                    {isOpen ? <Menu>{children}</Menu> : null}
                    {isOpen ? (
                        <Blanket
                            onClick={onClose}
                            style={{
                                bottom: 0,
                                left: 0,
                                top: 0,
                                right: 0,
                                position: "fixed",
                                zIndex: 1,
                            }}
                        />
                    ) : null}
                </>
            )}
        </Box>
    </>
);

const DropdownIndicator = () => <></>;

const ValueContainer = ({ children, ...props }) => {
    return (
        components.ValueContainer && (
            <components.ValueContainer {...props}>
                <Grid container gap={2} sx={{ position: `relative`, pl: 4, fontSize: 14 }}>
                    {!!children && <IconSearch size={16} sx={{ position: `absolute`, left: 0, top: 6 }} />}
                    {children}
                </Grid>
            </components.ValueContainer>
        )
    );
};
const SelectProject = (props) => {
    const { t, history, location } = props;
    const { pathname } = useLocation();
    const { spaceId } = menuService.parse(pathname);

    const [isOpen, setIsOpen] = useState(false);
    const [activeProject, setActiveProject] = useState(null);
    const [activeStage, setActiveStage] = useState(null);
    const { user, stage, project } = useSelector((store) => store);
    const dispatch = useDispatch();
    const ProjectActions = bindActionCreators(projectActions, dispatch);
    const CreateAssetsBulkActions = bindActionCreators(createAssetsBulkActions, dispatch);

    useEffect(() => {
        if (user && user.stages.data) {
            const _activeStage = user.stages.data.filter((v) => v.id === stage.id)[0];
            if (_activeStage) {
                const activeProject = _activeStage.projects
                    ? _activeStage.projects.filter((v) => v.id === project.id)[0]
                    : null;

                setActiveStage(_activeStage);
                setActiveProject(activeProject);
            }
        }
    }, [stage, project, user]);

    const toggleOpen = () => {
        if (props.open) setIsOpen(!isOpen);
    };

    const onSelectChange = (project) => {
        setIsOpen(false);
        setActiveProject(project);
        switchProject(project);
        dispatch(resetProjectPermissions());
    };

    const switchProject = async (project) => {
        await ProjectActions.setProject(project);
        const isAdmin = location.pathname.includes("/admin/");
        CreateAssetsBulkActions.closeModal();
        if (isAdmin) {
            history.replace(`${menuService.paths.admin.projectSettings(spaceId, project.id)}`);
        } else {
            if (isLocal) {
                history.replace(
                    compareVersions(VERSION_CM_5058_DASHBOARD, stage.version) <= 0
                        ? `${menuService.paths.dashboard(spaceId, project.id)}`
                        : `/`,
                );
            } else {
                if (siteMode.isCmsSupported() && isCms) {
                    window.location.href =
                        compareVersions(VERSION_CM_5058_DASHBOARD, stage.version) <= 0
                            ? `${config.host.cms}${menuService.paths.dashboard(spaceId, project.id)}`
                            : config.host.cms;
                } else {
                    window.location.href = "/";
                    // window.location.href = compareVersions(VERSION_CM_5058_DASHBOARD, stage.version) <= 0
                    // ? `${config.host.console}/dashboard`
                    // : config.host.console;
                }
            }
        }
    };

    const projectList =
        (activeStage &&
            activeStage.projects &&
            activeStage.projects.map((item) => {
                item.value = item.id;
                item.label = item.name;
                item.color = getProjectRandomColor(item.id);
                return item;
            })) ||
        [];

    return (
        <Dropdown
            isOpen={isOpen}
            onClose={toggleOpen}
            navOpen={props.open}
            type={props.type}
            target={
                <Grid
                    container
                    gap={1}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    flexWrap={"nowrap"}
                    onClick={toggleOpen}
                    className={` ${isOpen ? "active" : ""}`}
                    sx={{
                        minHeight: 64,
                        p: 1.5,
                        borderRadius: 1,
                        transition: `all linear 350ms`,
                        ...(props.open && {
                            "&:hover": {
                                boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.16)`,
                                backgroundColor: theme.palette.background.default,
                            },
                        }),
                        ...(isOpen && {
                            backgroundColor: theme.palette.background.default,
                        }),
                    }}
                >
                    <Avatar
                        sx={{
                            width: 32,
                            height: 32,
                            fontSize: 16,
                            fontWeight: `bold`,
                            margin: `0 auto`,
                            bgcolor: getProjectRandomColor(activeProject?.id),
                        }}
                    >
                        {activeProject && activeProject.name && activeProject.name.charAt(0)}
                    </Avatar>
                    {props.open && (
                        <>
                            <Grid item xs sx={{ overflow: `hidden` }}>
                                <Typography variant={"body1_700"} component={"p"} noWrap>
                                    {activeProject ? activeProject.name : "-"}
                                </Typography>
                                <Typography variant={"body4_500"} color={theme.palette.text.secondary} noWrap>
                                    {activeStage ? activeStage.name : "-"}
                                </Typography>
                            </Grid>
                            <IconButton size={"small"} color={"grey"} circled>
                                {isOpen ? <IconArrowDropUp size={20} /> : <IconArrowDropDown size={20} />}
                            </IconButton>
                        </>
                    )}
                </Grid>
            }
        >
            <WithSelect
                backspaceRemovesValue={false}
                components={{ DropdownIndicator, IndicatorSeparator: null, ValueContainer }}
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                isClearable={false}
                menuIsOpen
                onChange={onSelectChange}
                options={projectList}
                placeholder="Search"
                tabSelectsValue={false}
                defaultOptions={activeProject}
                formatOptionLabel={(props) => {
                    const selected = props.id === activeProject?.id;
                    return (
                        <ListItem selected={selected}>
                            <Avatar
                                sx={{
                                    width: 20,
                                    height: 20,
                                    mr: 1,
                                    fontSize: 11,
                                    fontWeight: `bold`,
                                    bgcolor: props.color,
                                }}
                            >
                                {`${props.name.charAt(0)}`}
                            </Avatar>
                            <Typography variant={"body2_500"}>{props.name}</Typography>
                        </ListItem>
                    );
                }}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        margin: 8,
                    }),
                    menu: (baseStyles, state) => ({
                        ...baseStyles,
                        boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.16)`,
                        ".select2-selection__option": {
                            padding: 0,
                        },
                        ".select2-selection__menu-list": {
                            overflowY: `overlay`,
                        },
                    }),
                }}
            />
        </Dropdown>
    );
};

export default withTranslation()(withRouter(SelectProject));
