import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";
import { Link, withRouter, useLocation } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import * as stageActions from "@modules/stage";
import * as projectActions from "@modules/project";
import * as devActions from "@modules/dev";
import * as createAssetsBulkActions from "@modules/createAssetsBulk";
import compareVersions, { VERSION_CM_5112_PROJECT_SITE_ACCESS, VERSION_CM_5058_DASHBOARD } from "@cores/version";
import config from "@config/config";
import { isLocal, isDev } from "@cores/enviromentExtension";
import { siteMode } from "@cores/siteMode";
import Loadable from "@react-loadable/revised";
import { checkIsMobile } from "@utils/checkmobile";
import menuService from "@services/menuService";
import {
    Box,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Popover,
    Stack,
    Tooltip,
    Typography,
} from "@mzc-pdc/ui";
import { IconReset, IconSynchronization, IconTransform, IconVersion } from "@mzc-cloudplex/icons";
import theme from "@styles/theme";
import { resetProjectPermissions } from "@modules/rolesManagement_v2";

const LoadableTokenExpiredModal = Loadable({
    loader: () => import("../../../components/modals/TokenExpired"),
    loading: () => null,
});

const isMobile = checkIsMobile();

const SelectStage = (props) => {
    const { t, history } = props;
    const { pathname } = useLocation();
    const { spaceId, projectId } = useLocation(pathname);

    const [isOpen, setIsOpen] = useState(false);
    const [activeStage, setActiveStage] = useState(null);
    const { user, stage, project, dev } = useSelector((store) => store);
    const [showNoSiteAccess, setShowNoSiteAccess] = useState(false);
    const dispatch = useDispatch();
    const DevActions = bindActionCreators(devActions, dispatch);
    const StageActions = bindActionCreators(stageActions, dispatch);
    const ProjectActions = bindActionCreators(projectActions, dispatch);
    const [pendingGetStages, setPendingGetStages] = useState(false);
    const CreateAssetsBulkActions = bindActionCreators(createAssetsBulkActions, dispatch);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    useEffect(() => {
        if (user && user.stages.pending === true) {
            setPendingGetStages(true);
        } else if (user && user.stages.pending === false) {
            setPendingGetStages(false);
        }
        if (user && user.stages.data) {
            const _activeStage = user.stages.data.filter((v) => v.id === stage.id)[0];
            setActiveStage(_activeStage);
        }
    }, [stage, project, user]);

    const showNoAccessPopup = () => {
        setShowNoSiteAccess(true);
    };

    const closeNoAccessPopup = () => {
        setShowNoSiteAccess(false);
    };

    const switchStage = async (stage) => {
        const project = stage.projects && stage.projects[0];

        const setStagePromise = StageActions.setStage(stage);
        const setProjectPromise = ProjectActions.setProject(project);

        await Promise.all([setStagePromise, setProjectPromise]);

        CreateAssetsBulkActions.closeModal();
        if (stage.version && compareVersions(stage.version, VERSION_CM_5112_PROJECT_SITE_ACCESS) >= 0) {
            if (stage.user && stage.user.projects) {
                const userProject = stage.user.projects.filter((p) => p.id === project.id)[0];
                if (!userProject) {
                    showNoAccessPopup();
                    return;
                }
                const currentSiteMode = siteMode.get();
                const isConsole = currentSiteMode === "CONSOLE";
                const siteAccess = userProject.siteAccess;
                const hasConsoleAccess = siteAccess.indexOf("CONSOLE") !== -1;
                const hasCmsAccess = siteAccess.indexOf("CMS") !== -1;
                const targetSite =
                    (isConsole && hasConsoleAccess) || (!isConsole && !hasCmsAccess && hasConsoleAccess)
                        ? "CONSOLE"
                        : hasCmsAccess
                        ? "CMS"
                        : null;
                if (targetSite === "CONSOLE") {
                    siteMode.set("CONSOLE");
                    const redirectPath =
                        compareVersions(VERSION_CM_5058_DASHBOARD, stage.version) <= 0
                            ? `${menuService.paths.dashboard(stage.id, project.id)}`
                            : `${menuService.paths.contentsVideoCreate(stage.id, project.id)}`;
                    history.replace(redirectPath);
                } else if (targetSite === "CMS") {
                    siteMode.set("CMS");
                    if (isLocal) {
                        history.replace(
                            compareVersions(VERSION_CM_5058_DASHBOARD, stage.version) <= 0
                                ? `${menuService.paths.dashboard(stage.id, project.id)}`
                                : `/`,
                        );
                    } else {
                        window.location.href =
                            compareVersions(VERSION_CM_5058_DASHBOARD, stage.version) <= 0
                                ? `${config.host.cms}${menuService.paths.dashboard(stage.id, project.id)}`
                                : config.host.cms;
                    }
                } else {
                    showNoAccessPopup();
                }
            }
        } else {
            if (siteMode.get(true) === "CMS") {
                siteMode.set("CMS");
                history.replace("/");
            } else {
                siteMode.set("CONSOLE");
                history.replace(`${menuService.paths.dashboard(stage.id, project.id)}`);
            }
        }
    };

    const stages = (user.stages && user.stages.data) || [];
    const stageList =
        (stages &&
            stages.length > 0 &&
            stages
                .filter((item) => {
                    return item.isAccessible;
                })
                .map((item) => {
                    item.value = item.id;
                    item.label = item.name;
                    return item;
                })) ||
        [];

    return (
        <React.Fragment>
            {showNoSiteAccess && (
                <LoadableTokenExpiredModal error={{ type: "NO_SITE_ACCESS" }} onConfirm={closeNoAccessPopup} />
            )}
            <IconButton circled onClick={handleClick}>
                <IconVersion size={16} color={"success"} />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                PaperProps={{
                    style: { width: "280px" },
                }}
            >
                <Box p={1}>
                    <Grid container alignItems={"center"} justifyContent={"space-between"}>
                        <Grid item>
                            <Stack gap={1}>
                                <Typography variant={"body4_400"} color={theme.palette.text.secondary}>
                                    Current Space
                                </Typography>
                                <Typography>{activeStage ? activeStage.name : "-"}</Typography>
                            </Stack>
                        </Grid>

                        <Tooltip title={t(`common::label::Space List`, `Space List`)}>
                            <IconButton circled href={"/spaces"}>
                                <IconTransform size={16} color={"greySecondary"} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    {(isLocal || isDev) && (
                        <>
                            <Divider sx={{ mt: 1.5, mb: 1.5 }} />
                            <Grid container alignItems={"center"} flexWrap={"nowrap"} gap={0.5}>
                                <Grid item xs>
                                    <input
                                        type="text"
                                        value={dev.testStageVersion ?? stage?.version ?? ""}
                                        onChange={(e) => {
                                            DevActions.setTestStageVersion(e.target.value);
                                        }}
                                    />
                                </Grid>

                                <Tooltip title={t(`common::label::Apply`, `Apply`)}>
                                    <IconButton
                                        circled
                                        onClick={(e) => {
                                            StageActions.setStage({
                                                ...stage,
                                                version: dev.testStageVersion,
                                            });
                                        }}
                                    >
                                        <IconSynchronization size={16} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={t(`common::label::Reset`, `Reset`)}>
                                    <IconButton
                                        circled
                                        onClick={(e) => {
                                            window.location.reload();
                                        }}
                                    >
                                        <IconReset size={16} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </>
                    )}

                    <Divider sx={{ mt: 1.5, mb: 1 }} />
                    <Typography variant={"body4_400"} color={theme.palette.text.secondary}>
                        Stage List
                    </Typography>
                    <List>
                        {stageList.map((v, i) => {
                            return (
                                <ListItemButton
                                    key={i}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        switchStage(v);
                                        dispatch(resetProjectPermissions());
                                    }}
                                    selected={activeStage && activeStage.id === v.value}
                                >
                                    <ListItemText primary={v.label} />
                                </ListItemButton>
                            );
                        })}
                    </List>
                </Box>
            </Popover>
        </React.Fragment>
    );
};

export default withTranslation()(withRouter(SelectStage));
