import { handleActions, createAction } from "redux-actions";
import { UPLOAD_FILE_LOCATION_TYPE, UPLOAD_LOCATION, UPLOAD_STATUS } from "@constants";
import { axios } from "@cores/axiosWrapper";
import uniqid from "uniqid";
import fileUpload from "@cores/fileUpload";
import queryString from "@cores/queryString";
import { upload } from "@cores/cpmUploadService";
import storagesApi from "./apis/storages";
import compareVersions, {
    VERSION_CM_8606_OBJECT_UPDATE_STATUS,
    VERSION_CM_8634_OBJECT_UPDATE_STATUS,
} from "@cores/version";

const UPLOAOD_QUEUE_ADD_QUEUE = "UPLOAOD_QUEUE_ADD_QUEUE";
const UPLOAOD_QUEUE_REMOVE_QUEUE = "UPLOAOD_QUEUE_REMOVE_QUEUE";
const UPLOAOD_QUEUE_UPDATE_QUEUE = "UPLOAOD_QUEUE_UPDATE_QUEUE";
const UPLOAOD_QUEUE_UPDATE_FILE = "UPLOAOD_QUEUE_UPDATE_FILE";
const UPLOAOD_QUEUE_TOGGLE_QUEUE_EXPANDER = "UPLOAOD_QUEUE_TOGGLE_QUEUE_EXPANDER";
const UPLOAOD_QUEUE_CANCEL_QUEUE = "UPLOAOD_QUEUE_CANCEL_QUEUE";
const UPLOAOD_QUEUE_CLEAR = "UPLOAOD_QUEUE_CLEAR";
const UPDATE_UPLOADLIST_OFFSET = "UPDATE_UPLOADLIST_OFFSET";

const initialState = {
    createAsset: [],
    assetDetail: [],
    Local: [],
    uploadCommentAttachment: [],
    local_files_offset: 50,
};

export default handleActions(
    {
        [UPLOAOD_QUEUE_ADD_QUEUE]: (state, action) => {
            const { locationType, resourceId, key, data, status, files, cancelTokenSource, bucketPath, isFolder } =
                action.payload;

            return {
                ...state,
                [locationType]: [
                    {
                        isFolded: true,
                        cancelTokenSource,
                        key,
                        status,
                        bucketPath,
                        data,
                        isFolder,
                        resourceId,
                        files: files.map((f) => {
                            f.uploadedPercentage = f.locationType === UPLOAD_FILE_LOCATION_TYPE.S3 ? 100 : 0;
                            return f;
                        }),
                        created: new Date(),
                    },
                ].concat(state[locationType]),
            };
        },
        [UPLOAOD_QUEUE_REMOVE_QUEUE]: (state, action) => {
            const { locationType, key } = action.payload;

            return {
                ...state,
                [locationType]: state[locationType].filter((q) => q.key !== key),
            };
        },
        [UPLOAOD_QUEUE_UPDATE_QUEUE]: (state, action) => {
            const { locationType, ...rest } = action.payload;

            return {
                ...state,
                [locationType]: state[locationType].map((q) => {
                    if (q.key === rest.key) {
                        return {
                            ...q,
                            ...rest,
                        };
                    }

                    return q;
                }),
            };
        },
        [UPLOAOD_QUEUE_UPDATE_FILE]: (state, action) => {
            const { locationType, key, fileIndex, fileId, data } = action.payload;

            return {
                ...state,
                [locationType]: state[locationType].map((q) => {
                    if (q.key === key) {
                        q.files = q.files.map((f, i) => {
                            if (i === fileIndex) {
                                const { uploadedPercentage, uploadedSize, uploadTotalSize, status } = data;
                                f.uploadedPercentage = uploadedPercentage;
                                f.uploadFinished = uploadedPercentage === 100;
                                if (status) {
                                    f.status = status;
                                }
                                return f;
                            }

                            return f;
                        });
                    }

                    return q;
                }),
            };
        },
        [UPLOAOD_QUEUE_TOGGLE_QUEUE_EXPANDER]: (state, action) => {
            const { locationType, key } = action.payload;
            // console.log("key", key);
            return {
                ...state,
                [locationType]: state[locationType].map((q) => {
                    if (q.key === key) {
                        q.isFolded = q.isFolded === undefined ? false : !q.isFolded;
                    }

                    return q;
                }),
            };
        },
        [UPLOAOD_QUEUE_CANCEL_QUEUE]: (state, action) => {
            const { locationType, key } = action.payload;
            return {
                ...state,
                [locationType]: state[locationType].map((q) => {
                    if (q.key === key) {
                        q.status = UPLOAD_STATUS.CANCELED;
                        q.files = q.files.map((item) => {
                            if (item.status === undefined || item.status !== "COMPLETE") {
                                item.status = "CANCELED";
                            }
                            return item;
                        });
                        q.uploaded = new Date();
                        // console.log('this is it', q)
                    }
                    return q;
                }),
            };
        },
        [UPLOAOD_QUEUE_CLEAR]: (state, action) => {
            return {
                ...initialState,
            };
        },
        [UPDATE_UPLOADLIST_OFFSET]: (state, action) => {
            const { offset } = action.payload;
            return {
                ...state,
                local_files_offset: offset,
            };
        },
    },
    initialState,
);

export const changeFilesOffset = (offset) => (dispatch) => {
    dispatch({
        type: UPDATE_UPLOADLIST_OFFSET,
        payload: {
            offset,
        },
    });
};

export const addQueue = (locationType, resourceId, data) => (dispatch) => {
    dispatch({
        type: UPLOAOD_QUEUE_ADD_QUEUE,
        payload: {
            locationType,
            resourceId,
            ...data,
        },
    });
};

export const cancelQueue = (locationType, key) => (dispatch, getState) => {
    const { uploadQueue } = getState();
    return new Promise(async (resolve, reject) => {
        const targets = uploadQueue[locationType].filter((item) => item.key === key);
        if (targets && targets[0].status !== "COMPLETE") {
            (await targets) && targets[0].cancelTokenSource.cancel();
            // console.log('put files canceled')
            dispatch({
                type: UPLOAOD_QUEUE_CANCEL_QUEUE,
                payload: {
                    locationType,
                    key,
                },
            });
        }
        resolve(true);
    });
};

export const updateQueue = (locationType, key, data) => (dispatch) => {
    if (data.status === UPLOAD_STATUS.COMPLETE || data.status === UPLOAD_STATUS.ERROR) {
        data.uploaded = new Date();
    }
    dispatch({
        type: UPLOAOD_QUEUE_UPDATE_QUEUE,
        payload: {
            locationType,
            key,
            ...data,
        },
    });
};

export const removeQueue = (locationType, key) => (dispatch) => {
    setTimeout(() => {
        dispatch({
            type: UPLOAOD_QUEUE_REMOVE_QUEUE,
            payload: {
                locationType,
                key,
            },
        });
    }, 1500);
};

export const updateUploadFile = (locationType, key, fileIndex, data) => (dispatch) => {
    dispatch({
        type: UPLOAOD_QUEUE_UPDATE_FILE,
        payload: {
            locationType,
            key,
            fileIndex,
            data,
        },
    });
};

export const toggleQueueExpander = (locationType, key) => (dispatch) => {
    dispatch({
        type: UPLOAOD_QUEUE_TOGGLE_QUEUE_EXPANDER,
        payload: {
            locationType,
            key,
        },
    });
};

export const put =
    (locationType, { keyPrefix, key, files, data, uploadCompleteCallback = () => {} }, isRetain = true) =>
    (dispatch, getState) => {
        if (typeof data === "object") translateDataObj(data);

        const cancelTokenSource = axios._cancelToken.source();
        key = saveQueue(locationType, { keyPrefix, key, data, files, cancelTokenSource })(dispatch, getState);

        const uploadTargetFiles = files.filter((v) => {
            return v.locationType === UPLOAD_FILE_LOCATION_TYPE.LOCAL;
        });

        const promises =
            uploadTargetFiles.length === 0
                ? []
                : createUploadPromises(
                      cancelTokenSource.token,
                      uploadTargetFiles,
                      uploadCompleteCallback,
                      (event, fileIndex) => {
                          updateUploadFile(locationType, key, fileIndex, {
                              uploadedPercentage: Math.round((event.loaded * 100) / event.total),
                              uploadedSize: event.loaded,
                              uploadTotalSize: event.total,
                          })(dispatch);
                      },
                  );
        return new Promise(async (resolve, reject) => {
            await Promise.all(promises)
                .then(() => {
                    const callback = (response, err) => {
                        if (err) {
                            getCallback(UPLOAD_STATUS.ERROR);
                            return;
                        }
                        getCallback(UPLOAD_STATUS.COMPLETE, response && response.data);
                    };
                    resolve(callback);
                })
                .catch((err) => {
                    getCallback(UPLOAD_STATUS[err]);
                    reject(err);
                });
        });

        function getCallback(status, data) {
            if (isRetain) {
                const _data = {
                    status,
                };

                if (data) {
                    _data.data = data;
                }

                updateQueue(locationType, key, _data)(dispatch, getState);
                return;
            }

            removeQueue(locationType, key)(dispatch, getState);
        }

        function translateDataObj(obj) {
            Object.entries(obj).forEach(([k, v]) => {
                if (typeof v === "string") obj[k] = queryString.translateDollarSign(v);
            });
        }
    };

function createUploadPromises(cancelToken, files, uploadCompleteCallback, uploadProgressCallback) {
    return files.map((file, i) => {
        return new Promise((resolve, reject) => {
            fileUpload({
                file,
                cancelToken,
                onUploadProgress: (event) => {
                    if (event.loaded && event.total) {
                        uploadProgressCallback(event, i);
                    }
                },
                onComplete: () => {
                    resolve(i);
                },
                onCancel: () => {
                    reject("CANCELED");
                },
                onError: () => {
                    reject("ERROR");
                },
            });
        }).then(async (fileIndex) => {
            await uploadCompleteCallback(fileIndex);
        });
    });
}

export const saveQueue =
    (locationType, { keyPrefix, key, data, files, cancelTokenSource, bucketPath, isFolder, resourceId }) =>
    (dispatch, getState) => {
        if (key) {
            updateQueue(locationType, key, {
                status: UPLOAD_STATUS.UPLOADING,
                cancelTokenSource,
                files: files.map((v) => {
                    v.uploadedPercentage = 0;
                    return v;
                }),
            })(dispatch, getState);
            return key;
        }

        key = keyPrefix ? `${keyPrefix}-${uniqid()}` : uniqid();
        addQueue(locationType, resourceId, {
            cancelTokenSource,
            key,
            status: UPLOAD_STATUS.UPLOADING,
            data,
            files,
            bucketPath,
            isFolder,
        })(dispatch, getState);

        return key;
    };

export const makeUploadQueueJob =
    ({
        locationType,
        data,
        files,
        isFolder,
        bucketPath,
        key: retryKey,
        resourceId,
        uploadCompleteCallback = () => {},
    }) =>
    async (dispatch, getState) => {
        const { stage } = getState();
        const cancelTokenSource = axios._cancelToken.source();
        let keyPrefix;
        if (locationType === UPLOAD_LOCATION.CREATE_ASSET) keyPrefix = "create-asset-upload";
        else if (locationType === UPLOAD_FILE_LOCATION_TYPE.LOCAL) keyPrefix = "sources-upload";

        const key = saveQueue(locationType, {
            keyPrefix,
            data,
            files,
            cancelTokenSource,
            key: retryKey,
            bucketPath,
            isFolder,
            resourceId,
        })(dispatch, getState);
        return key;
    };

export const uploadJobFiles =
    ({ locationType, id, key, uploadCompleteCallback = () => {} }) =>
    async (dispatch, getState) => {
        const { stage, uploadQueue, project } = getState();
        const apiEndpoint = stage.endpoint;
        const keyIndex = uploadQueue[locationType].findIndex((item) => item.key === key);
        const targets = uploadQueue[locationType][keyIndex];
        const bucketPath = targets.bucketPath;
        const resourceId = targets.resourceId;
        const threadId = targets?.data?.threadId; //Note: comment attachment upload시 사용
        const files = targets.files;
        const fileIndex = files.findIndex((item) => item.id === id);

        try {
            const file = files[fileIndex];
            let bucket, prefix;
            let resourceType = "";
            if (locationType === UPLOAD_LOCATION.CREATE_ASSET) resourceType = "ASSETS";
            else if (locationType === UPLOAD_LOCATION.UPLOAD_COMMENT_ATTACHMENT) resourceType = "COMMENT_ATTACHMENT";
            else resourceType = "SOURCES";

            if (bucketPath) {
                bucket = bucketPath.bucket;
                let filePath = file.fullPath; //Note: react-dropzone 사용시 path 사용. 업로드 버튼 사용시 fullPath 사용
                const fullPath = filePath.replace(`/${file.name}`, "");
                prefix = `${bucketPath.folderPath}${fullPath}`;
            }

            if (prefix && prefix[0] === "/") {
                prefix = prefix.slice(1);
            }

            // console.log(`prefix: ${prefix}`);
            const response = await new Promise((resolve, reject) => {
                upload(
                    file,
                    {
                        stageVersion: stage.version,
                        bucket,
                        prefix,
                        apiEndpoint,
                        resourceType,
                        resourceId,
                        threadId,
                        projectId: project.id,
                        progressKey: key,
                    },
                    targets.cancelTokenSource.token,
                    (event) => {
                        if (event.loaded && event.total) {
                            updateUploadFile(locationType, key, fileIndex, {
                                uploadedPercentage: Math.round((event.loaded * 100) / event.total),
                                status: "UPLOADING",
                            })(dispatch);
                        }
                    },
                    (data) => {
                        // onComplete

                        const result = {
                            url: data.destinationUrl,
                            status: "COMPLETE",
                        };
                        resolve(result);
                    },
                    () => {
                        // onCancel
                        const result = {
                            status: "CANCELED",
                        };
                        reject(result);
                    },
                    () => {
                        // onError
                        updateUploadFile(locationType, key, fileIndex, {
                            uploadedPercentage: 0,
                            status: "ERROR",
                        })(dispatch);
                        const result = {
                            status: "ERROR",
                        };
                        reject(result);
                    },
                    dispatch,
                );
            });
            updateUploadFile(locationType, key, fileIndex, {
                uploadedPercentage: 100,
                status: response,
            })(dispatch);
            console.log("completed");
            await updateObjectStatus(stage, bucket, prefix, file.name, "SYNCED");

            return response;
            // uploadCompleteCallback(response);
        } catch (e) {
            console.log(`upload failed`, e);
            // getCallback(UPLOAD_STATUS.ERROR);
            //getCallback(UPLOAD_STATUS[e]);
            return e;
        }
    };

export const retryUploadQueueJob =
    ({ locationType, key, uploadCompleteCallback = () => {} }) =>
    async (dispatch, getState) => {
        const { stage, uploadQueue } = getState();
        const cancelTokenSource = axios._cancelToken.source();
        const keyIndex = uploadQueue[locationType].findIndex((item) => item.key === key);
        const targets = uploadQueue[locationType][keyIndex];
        const files = targets.files;
        const bucketPath = targets.bucketPath;
        const isFolder = targets.isFolder;
        const retryKey = saveQueue(locationType, {
            keyPrefix: "sources-upload",
            files,
            cancelTokenSource,
            key,
            bucketPath,
            isFolder,
        })(dispatch, getState);
        return { retryKey, uploadQueue };
    };

export const uploadJobFileSetStatus =
    ({ locationType, id, key, status, uploadCompleteCallback = () => {} }) =>
    async (dispatch, getState) => {
        const { stage, uploadQueue } = getState();
        const keyIndex = uploadQueue[locationType].findIndex((item) => item.key === key);
        const targets = uploadQueue[locationType][keyIndex];
        const files = targets.files;
        const fileIndex = files.findIndex((item) => item.id === id);

        return new Promise((resolve, reject) => {
            updateUploadFile(locationType, key, fileIndex, {
                uploadedPercentage: status === "COMPLETE" ? 100 : 0,
                status,
            })(dispatch);
            resolve(true);
        });
    };

export const folderPut =
    ({ locationType, files, isFolder, bucketPath, key: retryKey, uploadCompleteCallback = () => {} }) =>
    async (dispatch, getState) => {
        const { stage } = getState();
        const cancelTokenSource = axios._cancelToken.source();
        const key = saveQueue(locationType, {
            keyPrefix: "sources-upload",
            files,
            cancelTokenSource,
            key: retryKey,
            bucketPath,
            isFolder,
        })(dispatch, getState);
        const isRetain = true;
        const apiEndpoint = stage.endpoint;
        const { bucket, folderPath } = bucketPath;
        const length = files.length;
        for (let i = 0; i < length; i++) {
            try {
                const file = files[i];
                const fullPath = (file.path ?? file.fullPath).replace(`/${file.name}`, "");
                let prefix = `${folderPath}${fullPath}`;
                if (prefix[0] === "/") {
                    prefix = prefix.slice(1);
                }
                const response = await new Promise((resolve, reject) => {
                    upload(
                        file,
                        {
                            bucket,
                            prefix,
                            apiEndpoint,
                            resourceType: "SOURCES",
                            progressKey,
                        },
                        cancelTokenSource.token,
                        (event) => {
                            if (event.loaded && event.total) {
                                updateUploadFile(locationType, key, i, {
                                    uploadedPercentage: Math.round((event.loaded * 100) / event.total),
                                })(dispatch);
                            }
                        },
                        () => {
                            // onComplete
                            resolve(i);
                        },
                        () => {
                            // onCancel
                            reject("CANCELED");
                        },
                        () => {
                            // onError
                            reject("ERROR");
                        },
                    );
                });
                // console.log(`prefix: ${prefix}`);
                await updateObjectStatus(stage, bucket, prefix, file.name, "SYNCED");
                uploadCompleteCallback(response);
            } catch (e) {
                console.log(`upload failed`, e);
                getCallback(UPLOAD_STATUS.ERROR);
                //getCallback(UPLOAD_STATUS[e]);
                return;
            }
        }
        getCallback(UPLOAD_STATUS.COMPLETE);

        function getCallback(status, data) {
            if (isRetain) {
                const _data = {
                    status,
                };

                if (data) {
                    _data.data = data;
                }

                updateQueue(locationType, key, _data)(dispatch, getState);
                return;
            }

            removeQueue(locationType, key)(dispatch, getState);
        }
    };

async function updateObjectStatus(stage, bucket, prefix, fileName, status) {
    if (compareVersions(stage.version, VERSION_CM_8606_OBJECT_UPDATE_STATUS) !== 0) {
        return;
    }
    const refinedPrefix = prefix ? prefix.removeEndingSlash() : "";
    const key = refinedPrefix.length > 0 ? `${refinedPrefix}/${fileName}` : fileName;
    const requestPayload = {
        objects: [
            {
                key,
                status,
            },
        ],
    };
    return await storagesApi.updateObjectStatus(stage.endpoint, bucket, requestPayload);
}

export const uploadFilesFinished =
    ({ locationType, key, status }) =>
    async (dispatch, getState) => {
        const { uploadQueue } = getState();
        if (status === UPLOAD_STATUS.COMPLETE) {
            const keyIndex = uploadQueue[locationType].findIndex((item) => item.key === key);
            const targets = uploadQueue[locationType][keyIndex];
            const files = targets.files;
            updateQueue(locationType, key, {
                status,
            })(dispatch, getState);
            if (files.filter((item) => item.status === "ERROR").length > 0) {
                if (locationType !== UPLOAD_LOCATION.CREATE_ASSET)
                    await cancelActions(locationType, key)(dispatch, getState);
            }
        } else if (status === UPLOAD_STATUS.CANCELED) {
            await cancelQueue(locationType, key)(dispatch, getState);
            if (locationType !== UPLOAD_LOCATION.CREATE_ASSET)
                await cancelActions(locationType, key)(dispatch, getState);
        } else if (status === UPLOAD_STATUS.ERROR) {
            // console.log('canceled');
            updateQueue(locationType, key, {
                status,
            })(dispatch, getState);
            if (locationType !== UPLOAD_LOCATION.CREATE_ASSET)
                await cancelActions(locationType, key)(dispatch, getState);
        }
    };

export const cancelActions = (locationType, key, fromLocalStorageData) => (dispatch, getState) => {
    // TODO: Storage 구조 개선으로 호출 필요 없을 듯. 확인 후 버져닝 분기 처리.
    const { stage, project, uploadQueue } = getState();
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;
    const stageId = stage.id;

    let find;
    if (fromLocalStorageData) {
        find = fromLocalStorageData;
    } else {
        find = uploadQueue[locationType].filter((item) => item.key === key)[0];
    }

    const bucketName = find.bucketPath && find.bucketPath.bucket;
    const folderPath = find.bucketPath.folderPath ? `/${find.bucketPath.folderPath}` : "";
    const markedFiles = find.files.filter((item) => {
        return item.uploadUrl || item.uploadId;
    });
    const items = markedFiles.map((item) => {
        return {
            path: `${folderPath}${item.fullPath === "/" ? `${item.fullPath}${item.name}` : item.fullPath}`,
            isFolder: false,
        };
    });

    const actions = {
        action: "CANCEL",
        content: {
            action: "UPLOAD",
            from: {
                items,
            },
        },
    };

    return new Promise((resolve, reject) => {
        return storagesApi
            .mutate(apiEndpoint, stageId, stage.version, projectId, bucketName, actions)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const clear = createAction(UPLOAOD_QUEUE_CLEAR);
