import clsx from "clsx";
import React from "react";
import PropTypes from "prop-types";

const TableContainer = (props) => {
    const { className, direction, ...otherProps } = props;
    let direction_;
    if (direction === "x" || direction === "y") direction_ = `table-responsive-${direction}`;

    return <div {...otherProps} className={clsx("table", "table-responsive", direction_, className)} />;
};

TableContainer.propTypes = {
    direction: PropTypes.string,
};
TableContainer.defaultProps = {

};

export default TableContainer;
