import { handleActions, createAction } from "redux-actions";
import {
    patchProjectUserAPI,
    getUsersAPI,
    getUserAPI,
    getUserTeamsAPI,
    patchUserAPI,
    deleteUserAPI,
    getUserRolesAPI,
    putUserRoleAPI,
    deleteUserRoleAPI,
} from "@modules/apis/user-management";
import { getProjectUsersAPI } from "@modules/apis/user-management-v2";

const USER_MANAGEMENT_CLEAR = "USER_MANAGEMENT_CLEAR";

const USER_MANAGEMENT_PENDING = "USER_MANAGEMENT_PENDING";
const USER_MANAGEMENT_FAILURE = "USER_MANAGEMENT_FAILURE";

const USER_MANAGEMENT_UPDATE_USER_PARAMS = "USER_MANAGEMENT_UPDATE_USER_PARAMS";
const USER_MANAGEMENT_RESET_USER_PARAMS = "USER_MANAGEMENT_RESET_USER_PARAMS";

const USER_MANAGEMENT_GET_USERS_SUCCESS = "USER_MANAGEMENT_GET_USERS_SUCCESS";
const USER_MANAGEMENT_GET_SHARE_USERS_INIT = "USER_MANAGEMENT_GET_SHARE_USERS_INIT";
const USER_MANAGEMENT_GET_SHARE_USERS_PENDING = "USER_MANAGEMENT_GET_SHARE_USERS_PENDING";
const USER_MANAGEMENT_GET_SHARE_USERS_SUCCESS = "USER_MANAGEMENT_GET_SHARE_USERS_SUCCESS";
const USER_MANAGEMENT_GET_USER_SUCCESS = "USER_MANAGEMENT_GET_USER_SUCCESS";
const USER_MANAGEMENT_GET_USER_ROLES_SUCCESS = "USER_MANAGEMENT_GET_USER_ROLES_SUCCESS";
const USER_MANAGEMENT_GET_TEAMS_PENDING = "USER_MANAGEMENT_GET_TEAMS_PENDING";
const USER_MANAGEMENT_GET_TEAMS_SUCCESS = "USER_MANAGEMENT_GET_TEAMS_SUCCESS";
const USER_MANAGEMENT_GET_SHARE_TEAMS_INIT = "USER_MANAGEMENT_GET_SHARE_TEAMS_INIT";
const USER_MANAGEMENT_GET_SHARE_TEAMS_PENDING = "USER_MANAGEMENT_GET_SHARE_TEAMS_PENDING";
const USER_MANAGEMENT_GET_SHARE_TEAMS_SUCCESS = "USER_MANAGEMENT_GET_SHARE_TEAMS_SUCCESS";
const USER_MANAGEMENT_UPDATE_TEAMS_PARAMS = "USER_MANAGEMENT_UPDATE_TEAMS_PARAMS";
const USER_MANAGEMENT_UPDATE_USER_ROLES_PARAMS = "USER_MANAGEMENT_UPDATE_USER_ROLES_PARAMS";

const USER_MANAGEMENT_UPDATE_USER_FAILURE = "USER_MANAGEMENT_UPDATE_USER_FAILURE";
const USER_MANAGEMENT_UPDATE_USER_SUCCESS = "USER_MANAGEMENT_UPDATE_USER_SUCCESS";

const USER_MANAGEMENT_DELETE_USER_FAILURE = "USER_MANAGEMENT_DELETE_USER_FAILURE";
const USER_MANAGEMENT_DELETE_USER_SUCCESS = "USER_MANAGEMENT_DELETE_USER_SUCCESS";

const USER_MANAGEMENT_UPDATE_PROJECT_USER_SUCCESS = "USER_MANAGEMENT_UPDATE_PROJECT_USER_SUCCESS";
// const GET_USER_MANAGEMENT_FOR_USERS_SUCCESS = 'GET_USER_MANAGEMENT_FOR_USERS_SUCCESS';
const initialState = {
    pending: false,
    error: false,
    params: {
        shouldRequestDetail: true,
        keywordType: "username",
        keyword: "",
        offset: 0,
        limit: 50,
    },
    totalCount: 0,
    users: [],
    shareUsers: { totalCount: 0, results: [], version: 0 }, //version: API 중첩 회피
    user: null,
    roles: {
        totalCount: 0,
        results: [],
        params: {
            teamId: "",
            name: "",
            offset: 0,
            limit: 50,
        },
    },
    shareTeams: {
        totalCount: 0,
        results: [],
        params: {
            offset: 0,
            limit: 50,
        },
        version: 0,
    },
    teams: {
        totalCount: 0,
        results: [],
        params: {
            offset: 0,
            limit: 50,
        },
    },
    permissions: [],
    userRequestVersion: 0,
};

export default handleActions(
    {
        [USER_MANAGEMENT_CLEAR]: (state) => {
            return {
                ...initialState,
            };
        },
        [USER_MANAGEMENT_PENDING]: (state) => {
            return {
                ...state,
                pending: true,
                error: false,
            };
        },
        [USER_MANAGEMENT_GET_SHARE_USERS_INIT]: (state) => {
            return {
                ...state,
                pending: true,
                error: false,
                shareUsers: { totalCount: 0, results: [], version: state.shareUsers.version + 1 },
            };
        },
        [USER_MANAGEMENT_GET_SHARE_TEAMS_INIT]: (state) => {
            return {
                ...state,
                pending: true,
                error: false,
                shareTeams: { ...state.shareTeams, totalCount: 0, results: [], version: state.shareTeams.version + 1 },
            };
        },
        [USER_MANAGEMENT_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [USER_MANAGEMENT_GET_USERS_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                pending: false,
                error: false,
                totalCount: data.totalCount,
                users: data.results,
            };
        },
        [USER_MANAGEMENT_GET_SHARE_USERS_PENDING]: (state, action) => {
            const { totalCount, results } = action.payload;

            return {
                ...state,
                error: false,
                shareUsers: {
                    totalCount: totalCount,
                    results: results,
                },
            };
        },
        [USER_MANAGEMENT_GET_SHARE_USERS_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
            };
        },
        [USER_MANAGEMENT_UPDATE_USER_PARAMS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
                params: {
                    ...state.params,
                    ...action.payload,
                },
            };
        },
        [USER_MANAGEMENT_RESET_USER_PARAMS]: (state) => {
            return {
                ...state,
                params: {
                    ...state.params,
                    keywordType: "username",
                    keyword: "",
                },
            };
        },
        [USER_MANAGEMENT_GET_USER_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                pending: false,
                error: false,
                user: data,
            };
        },
        [USER_MANAGEMENT_GET_USER_ROLES_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                pending: false,
                error: false,
                roles: {
                    ...state.roles,
                    totalCount: data.totalCount,
                    results: data.results,
                },
            };
        },
        [USER_MANAGEMENT_GET_SHARE_TEAMS_PENDING]: (state, action) => {
            const { totalCount, results } = action.payload;

            return {
                ...state,
                error: false,
                shareTeams: {
                    ...state.teams,
                    totalCount: totalCount,
                    results: results,
                },
            };
        },
        [USER_MANAGEMENT_GET_SHARE_TEAMS_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
            };
        },
        [USER_MANAGEMENT_GET_TEAMS_PENDING]: (state, action) => {
            const { totalCount, results } = action.payload;

            return {
                ...state,
                error: false,
                teams: {
                    ...state.teams,
                    totalCount: totalCount,
                    results: results,
                },
            };
        },
        [USER_MANAGEMENT_GET_TEAMS_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            return {
                ...state,
                pending: false,
                error: false,
                teams: {
                    ...state.teams,
                    totalCount: data.totalCount,
                    results: data.results,
                },
            };
        },
        [USER_MANAGEMENT_UPDATE_TEAMS_PARAMS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
                teams: {
                    ...state.teams,
                    params: {
                        ...state.params,
                        ...action.payload,
                    },
                },
            };
        },
        [USER_MANAGEMENT_UPDATE_USER_ROLES_PARAMS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
                roles: {
                    ...state.roles,
                    params: {
                        ...state.params,
                        ...action.payload,
                    },
                },
            };
        },
        [USER_MANAGEMENT_UPDATE_USER_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const temp = {
                ...state,
                user: {
                    ...data,
                },
                pending: false,
                error: false,
                updateUserError: null,
            };
            const existingUsers = state.users;
            if (existingUsers) {
                temp.users = existingUsers.map((v) => {
                    if (v.id === data.id) {
                        return {
                            ...data,
                        };
                    }
                    return v;
                });
            }
            return temp;
        },
        [USER_MANAGEMENT_UPDATE_USER_FAILURE]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                pending: false,
                error: false,
                updateUserError: data,
            };
        },
        [USER_MANAGEMENT_DELETE_USER_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: false,
                user: null,
            };
        },
        [USER_MANAGEMENT_DELETE_USER_FAILURE]: (state, action) => {
            const { data } = action.payload;
            return {
                ...state,
                pending: false,
                error: false,
                deleteUserError: data,
            };
        },
        [USER_MANAGEMENT_UPDATE_PROJECT_USER_SUCCESS]: (state, action) => {
            const payload = action.payload;
            const existingUsers = state.users;
            const temp = {
                ...state,
                user: {
                    ...state.user,
                    ...payload,
                },
                pending: false,
                error: false,
            };
            if (existingUsers) {
                temp.users = existingUsers.map((v) => {
                    if (v.id === payload.id) {
                        return {
                            ...v,
                            ...payload,
                        };
                    }
                    return v;
                });
            }
            return temp;
        },
    },
    initialState,
);

export const updateTeamParams = createAction(USER_MANAGEMENT_UPDATE_TEAMS_PARAMS);
export const resetParams = createAction(USER_MANAGEMENT_RESET_USER_PARAMS);
export const updateParams = createAction(USER_MANAGEMENT_UPDATE_USER_PARAMS);
export const updateRoleParams = createAction(USER_MANAGEMENT_UPDATE_USER_ROLES_PARAMS);
export const clear = createAction(USER_MANAGEMENT_CLEAR);

export const getUserAsync = (id, projectId) => (dispatch, getState) => {
    const { stage } = getState();
    return getUserAPI(stage.id, stage.endpoint, projectId, id)
        .then((response) => {
            return response && response.data;
        })
        .catch((error) => {
            console.error(`failed to get user`, error);
            return null;
        });
};

export const getUsers = (params, projectId) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: USER_MANAGEMENT_PENDING });
    return getUsersAPI(stage.id, stage.endpoint, projectId, params)
        .then((response) => {
            dispatch({
                type: USER_MANAGEMENT_GET_USERS_SUCCESS,
                payload: response,
            });
            return response && response.data;
        })
        .catch((error) => {
            dispatch({
                type: USER_MANAGEMENT_FAILURE,
                payload: error,
            });
        });
};

export const getUsers_v2 = (params, projectId) => (dispatch, getState) => {
    const { stage } = getState();
    dispatch({ type: USER_MANAGEMENT_PENDING });
    return new Promise(async (resolve, reject) => {
        const { keyword } = params;
        const getUsersResponse = await getUsersAPI(stage.id, stage.endpoint, projectId, {
            keywordType: "q",
            keyword,
            shouldRequestDetail: true,
            offset: 0,
            limit: 50,
        });
        const response = {
            data: {
                totalCount: (getUsersResponse && getUsersResponse.data && getUsersResponse.data.totalCount) || 0,
                results: (getUsersResponse && getUsersResponse.data && getUsersResponse.data.results) || [],
            },
        };
        // console.log('response', response)
        dispatch({
            type: USER_MANAGEMENT_GET_USERS_SUCCESS,
            payload: response,
        });
        resolve(response && response.data);
    });
};

export const getAllofUsers = () => (dispatch, getState) => {
    const { stage, project, userManagement } = getState();
    dispatch({ type: USER_MANAGEMENT_GET_SHARE_USERS_INIT });
    const version = userManagement.shareUsers.version;
    // TODO: Recursive로 페이지네이션 없을 때까지 요청해서 합치도록 개선. 1만 넘으면 못 가져옴.
    return new Promise(async (resolve, reject) => {
        try {
            let users = [];
            const limit = 100;
            let responseLen = 0;
            let count = 0;
            do {
                const response = await getUsersAPI(stage.id, stage.endpoint, project.id, {
                    shouldRequestDetail: false,
                    offset: count,
                    limit,
                });

                if (version !== userManagement.shareUsers.version) resolve([]);

                users = users.concat(response.data.results);
                dispatch({
                    type: USER_MANAGEMENT_GET_SHARE_USERS_PENDING,
                    payload: { totalCount: response.data.totalCount, results: users },
                });

                responseLen = response.data.results.length;
                count++;
            } while (responseLen === limit);
            dispatch({
                type: USER_MANAGEMENT_GET_SHARE_USERS_SUCCESS,
            });
            resolve(users);
        } catch {
            (err) => {
                console.error(err);
                dispatch({ type: USER_MANAGEMENT_FAILURE });
                reject(err);
            };
        }
    });
};

export const getAllofUsersV2 = () => (dispatch, getState) => {
    const { stage, project, userManagement } = getState();
    dispatch({ type: USER_MANAGEMENT_GET_SHARE_USERS_INIT });
    const version = userManagement.shareUsers.version;
    // TODO: Recursive로 페이지네이션 없을 때까지 요청해서 합치도록 개선. 1만 넘으면 못 가져옴.
    return new Promise(async (resolve, reject) => {
        try {
            let users = [];
            let limit = 100;
            let responseLen = 0;
            let count = 0;
            do {
                const response = await getProjectUsersAPI(stage.id, stage.endpoint, project.id, {
                    shouldRequestDetail: false,
                    limit,
                });

                if (version !== userManagement.shareUsers.version) resolve([]);

                users = users.concat(response.data.results?.map((v) => ({ ...v, type: "USER" })));

                dispatch({
                    type: USER_MANAGEMENT_GET_SHARE_USERS_PENDING,
                    payload: { totalCount: response.data.totalCount, results: users },
                });

                responseLen = response.data.results.length;
                count++;
            } while (responseLen === limit);
            dispatch({
                type: USER_MANAGEMENT_GET_SHARE_USERS_SUCCESS,
            });
            resolve(users);
        } catch {
            (err) => {
                console.error(err);
                dispatch({ type: USER_MANAGEMENT_FAILURE });
                reject(err);
            };
        }
    });
};

export const getAllofTeams = () => (dispatch, getState) => {
    const { stage, userManagement } = getState();
    dispatch({ type: USER_MANAGEMENT_GET_SHARE_TEAMS_INIT });
    const version = userManagement.shareUsers.version;
    // TODO: Recursive로 페이지네이션 없을 때까지 요청해서 합치도록 개선. 1만 넘으면 못 가져옴.
    return new Promise(async (resolve, reject) => {
        try {
            let teams = [];
            const limit = 1000;
            let responseLen = 0;
            let count = 0;
            do {
                const response = await getUserTeamsAPI(stage.id, stage.endpoint, null, "", {
                    shouldRequestDetail: true,
                    offset: count,
                    limit,
                });
                if (version !== userManagement.shareUsers.version) resolve([]);

                teams = teams.concat(response.data.results);
                dispatch({
                    type: USER_MANAGEMENT_GET_SHARE_TEAMS_PENDING,
                    payload: { totalCount: response.data.totalCount, results: teams },
                });

                responseLen = response.data.results.length;
                count++;
            } while (responseLen === limit);
            dispatch({
                type: USER_MANAGEMENT_GET_SHARE_TEAMS_SUCCESS,
            });
            resolve(teams);
        } catch {
            (err) => {
                console.error(err);
                dispatch({ type: USER_MANAGEMENT_FAILURE });
                reject(err);
            };
        }
    });
};

export const getUser = (id, projectId) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: USER_MANAGEMENT_PENDING });
    return getUserAPI(stage.id, stage.endpoint, projectId, id)
        .then((response) => {
            dispatch({
                type: USER_MANAGEMENT_GET_USER_SUCCESS,
                payload: response,
            });
            return response && response.data;
        })
        .catch((error) => {
            dispatch({
                type: USER_MANAGEMENT_FAILURE,
                payload: error,
            });
        });
};

export const getRoles =
    ({ id, projectId, params }) =>
    (dispatch, getState) => {
        const { stage } = getState();

        dispatch({ type: USER_MANAGEMENT_PENDING });

        return getUserRolesAPI(stage.id, stage.endpoint, projectId, id, params)
            .then((response) => {
                dispatch({
                    type: USER_MANAGEMENT_GET_USER_ROLES_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: USER_MANAGEMENT_FAILURE,
                    payload: error,
                });
            });
    };

export const getTeams =
    ({ id, projectId, params }) =>
    (dispatch, getState) => {
        const { stage } = getState();

        dispatch({ type: USER_MANAGEMENT_PENDING });
        return getUserTeamsAPI(stage.id, stage.endpoint, projectId, id, params)
            .then((response) => {
                dispatch({
                    type: USER_MANAGEMENT_GET_TEAMS_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: USER_MANAGEMENT_FAILURE,
                    payload: error,
                });
            });
    };

export const updateUser =
    ({ id, projectId, data }) =>
    (dispatch, getState) => {
        const { stage } = getState();

        dispatch({ type: USER_MANAGEMENT_PENDING });

        return patchUserAPI(stage.id, stage.endpoint, projectId, id, data)
            .then((response) => {
                dispatch({
                    type: USER_MANAGEMENT_UPDATE_USER_SUCCESS,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: USER_MANAGEMENT_UPDATE_USER_FAILURE,
                    payload: error,
                });
            });
    };

export const deleteUser =
    ({ id, projectId }, callback) =>
    (dispatch, getState) => {
        const { stage } = getState();

        dispatch({ type: USER_MANAGEMENT_PENDING });

        return deleteUserAPI(stage.id, stage.endpoint, projectId, id)
            .then((response) => {
                dispatch({
                    type: USER_MANAGEMENT_DELETE_USER_SUCCESS,
                    payload: response,
                });
                if (callback) {
                    callback(null, response);
                }
            })
            .catch((error) => {
                dispatch({
                    type: USER_MANAGEMENT_DELETE_USER_FAILURE,
                    payload: error,
                });
                if (callback) {
                    callback(error);
                }
            });
    };

export const updateProjectUser = (projectId, userId, body) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: USER_MANAGEMENT_PENDING });
    return patchProjectUserAPI(stage.id, stage.endpoint, projectId, userId, body)
        .then((response) => {
            const payload = {
                id: userId,
            };
            if (body.siteAccess) {
                payload.siteAccess = body.siteAccess;
            }
            if (body.isAdmin != null && body.isAdmin != undefined) {
                payload.isProjectAdmin = body.isAdmin;
            }
            dispatch({
                type: USER_MANAGEMENT_UPDATE_PROJECT_USER_SUCCESS,
                payload,
            });
        })
        .catch((error) => {
            dispatch({
                type: USER_MANAGEMENT_UPDATE_USER_FAILURE,
                payload: error,
            });
        });
};
