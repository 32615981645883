import { Autocomplete_old } from "@components_v2/autocomplete";
import React, { useCallback, useMemo, useRef, useState } from "react";

const TypesInput = ({ types, options, placeholder, disabled = false, onDeleteType, onAddType }) => {
    const [value, setValue] = useState();

    const onHandleDeleteType = useCallback(
        (index) => {
            onDeleteType(index);
        },
        [onDeleteType],
    );

    const onHandleSubmitType = useCallback(() => {
        if (!value) return;
        onAddType({ value });
        setValue("");
    }, [value, onAddType]);

    const checkDuplicateValue = useMemo(() => {
        if (types && types.some((tag) => tag.value === value)) return true;
        return false;
    }, [value, types]);

    return (
        <Autocomplete_old
            type={"options"}
            value={value}
            checkDuplicateValue={checkDuplicateValue}
            tags={types}
            options={options}
            placeholder={placeholder}
            disabled={disabled}
            inputDisabled={true}
            setValue={setValue}
            onDelete={onHandleDeleteType}
            onSubmit={onHandleSubmitType}
            onClickOption={({ title }) => {
                const index = types?.findIndex((x) => x.value === title);

                if (index !== undefined && index !== -1) onHandleDeleteType(index);
                else onAddType({ value: title });
            }}
            isEdit={false}
        />
    );
};

export default TypesInput;
