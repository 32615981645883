import navigator from './navigator';
export {
    closeMenu,
    closeNavigator,
    openMenu,
    openNavigator,
    setMenu,
    toggleMenu,
    foldMenu
} from "./actions";

export default navigator;
