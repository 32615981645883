const stage = {
    host: {
        console: "https://console.cloudplex.qa.megazone.io",
        cms: "https://cms.cloudplex.qa.megazone.io",
        api: "https://api.cloudplex.qa.megazone.io",
        analysis: "https://jr5q8zq2lb.execute-api.ap-northeast-2.amazonaws.com",
        loginRedirectUri: "/megazone/login",
    },
    mz_pops: {
        oauth_host: "https://login.megazone.com",
        api_host: "https://www.megazoneapis.com",
        clientId: "p4Eo6DRG9TV0ClWQJEE181nRm7uAzd",
    },
    profileId: "1536304085jekLFQq1",
    PLAYER_URL: "https://dt995gh15mefu.cloudfront.net",
    cloudplex: {
        startApiEndpoint: "https://p8q1rq01bg.execute-api.ap-northeast-2.amazonaws.com/qa",
        clientId: "LpwRoHZWUB86yi0vLWzddNqCHT7fTM",
    },
    // hotjar_tracking_code: {
    //     hjid: "3070898",
    //     hjsv: "6",
    // },
};

export default stage;
