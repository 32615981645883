import { axios } from "@cores/axiosWrapper";

export const getVocabulariesAPI = async (stageId, apiEndpoint, projectId, params) => {
    return axios.get(`${apiEndpoint}/dictation-vocabularies`, {
        params,
        headers: { stageId, projectId },
    });
};

export const deleteVocabularyAPI = async (stageId, apiEndpoint, projectId, vocabularyId) => {
    return axios.delete(`${apiEndpoint}/dictation-vocabularies/${vocabularyId}`, {
        headers: { stageId, projectId },
    });
};
