import { handleActions, createAction } from "redux-actions";

import {
    METADATA_FIELDS_TYPE,
    REFERENCE_FIELDS_TYPE,
    CONTENT_SCHEMA_FIELD_ACTIONS,
    CONTENTS_SCHEMA_TYPE,
} from "@constants";
import {
    getContentSchemaDetailAPI,
    getContentFieldTypesAPI,
    updateContentSchemaAPI,
    updateContentSchemaActionAPI,
    deleteContentSchemaAPI,
    updateContentFieldTypeAPI,
    getContentSchemaUseInProjectsAPI,
    updateContentSchemaFieldsAPI,
} from "./apis/content-schema";
import { useMemo } from "react";

const CONTENT_SCHEMA_DETAIL_PENDING = "CONTENT_SCHEMA_DETAIL_PENDING";
const CONTENT_SCHEMA_DETAIL_FAILURE = "CONTENT_SCHEMA_DETAIL_FAILURE";
const CONTENT_SCHEMA_DETAIL_SUCCESS = "CONTENT_SCHEMA_DETAIL_SUCCESS";
const CONTENT_SCHEMA_GET_FIELD_TYPE_LIST_PENDING = "CONTENT_SCHEMA_GET_FIELD_TYPE_LIST_PENDING";
const CONTENT_SCHEMA_GET_FIELD_TYPE_LIST_FAILURE = "CONTENT_SCHEMA_GET_FIELD_TYPE_LIST_FAILURE";
const CONTENT_SCHEMA_GET_FIELD_TYPE_LIST_SUCCESS = "CONTENT_SCHEMA_GET_FIELD_TYPE_LIST_SUCCESS";
const CONTENT_SCHEMA_RESET_EDIT_DATA = "CONTENT_SCHEMA_RESET_EDIT_DATA";
const CONTENT_SCHEMA_RESET_EDIT_FIELDS = "CONTENT_SCHEMA_RESET_EDIT_FIELDS";
const CONTENT_SCHEMA_UPDATE_ORIGINAL_DATA = "CONTENT_SCHEMA_UPDATE_ORIGINAL_DATA";
const CONTENT_SCHEMA_UPDATE_EDIT_DATA = "CONTENT_SCHEMA_UPDATE_EDIT_DATA";
const CONTENT_SCHEMA_GET_PROJECTS_IN_USE_PENDING = "CONTENT_SCHEMA_GET_PROJECTS_IN_USE_PENDING";
const CONTENT_SCHEMA_GET_PROJECTS_IN_USE_SUCCESS = "CONTENT_SCHEMA_GET_PROJECTS_IN_USE_SUCCESS";
const CONTENT_SCHEMA_GET_PROJECTS_IN_USE_FAILURE = "CONTENT_SCHEMA_GET_PROJECTS_IN_USE_FAILURE";
const CONTENT_SCHEMA_RESET_PROJECTS_IN_USE = "CONTENT_SCHEMA_RESET_PROJECTS_IN_USE";
const CONTENT_SCHEMA_UPDATE_FIELDS_FAILURE = "CONTENT_SCHEMA_UPDATE_FIELDS_FAILURE";
const CONTENT_SCHEMA_UPDATE_EDIT_FIELD = "CONTENT_SCHEMA_UPDATE_EDIT_FIELD";
const CONTENT_SCHEMA_UPDATE_EDIT_FIELDS = "CONTENT_SCHEMA_UPDATE_EDIT_FIELDS";
const CONTENT_SCHEMA_UPDATE_EDIT_FIELDS_BY_TYPE = "CONTENT_SCHEMA_UPDATE_EDIT_FIELDS_BY_TYPE";
const CONTENT_SCHEMA_UPDATE_DATA_PENDING = "CONTENT_SCHEMA_UPDATE_DATA_PENDING";
const CONTENT_SCHEMA_UPDATE_DATA_FAILURE = "CONTENT_SCHEMA_UPDATE_DATA_FAILURE";
const CONTENT_SCHEMA_UPDATE_DATA_SUCCESS = "CONTENT_SCHEMA_UPDATE_DATA_SUCCESS";
const CONTENT_SCHEMA_CLEAR = "CONTENT_SCHEMA_CLEAR";

const initialState = {
    data: null,
    editData: null,
    editFields: {
        metadata: [],
        reference: [],
    },
    editPending: false,
    pending: false,
    error: null,
    fieldTypeList: {
        pending: false,
        data: null,
        error: false,
    },
    projectsInUse: {
        pending: false,
        error: false,
    },
};

export default handleActions(
    {
        [CONTENT_SCHEMA_DETAIL_PENDING]: (state, action) => {
            return {
                ...state,
                data: null,
                editData: null,
                pending: true,
                error: null,
            };
        },
        [CONTENT_SCHEMA_DETAIL_SUCCESS]: (state, action) => {
            const { fields } = action.payload;
            const newFields = fields?.map((f, idx) => ({ key: idx, ...f }));
            const metadataFields = newFields?.filter((el) => !el.fieldType?.dataType?.isReference);
            const referenceFields = newFields?.filter((el) => el.fieldType?.dataType?.isReference);
            const allFields = [...metadataFields, ...referenceFields];

            return {
                ...state,
                pending: false,
                editPending: false,
                data: { ...action.payload, fields: allFields },
                editData: { ...action.payload, fields: allFields },
                editFields: { metadata: metadataFields, reference: referenceFields },
            };
        },
        [CONTENT_SCHEMA_DETAIL_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: action.payload,
            };
        },
        [CONTENT_SCHEMA_GET_FIELD_TYPE_LIST_PENDING]: (state, action) => {
            return {
                ...state,
                fieldTypeList: {
                    pending: true,
                    error: null,
                },
            };
        },
        [CONTENT_SCHEMA_GET_FIELD_TYPE_LIST_SUCCESS]: (state, action) => {
            return {
                ...state,
                fieldTypeList: {
                    pending: false,
                    data: action.payload,
                },
            };
        },
        [CONTENT_SCHEMA_GET_FIELD_TYPE_LIST_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: action.payload,
            };
        },
        [CONTENT_SCHEMA_RESET_EDIT_FIELDS]: (state, action) => {
            const reference = state.data.fields.filter((el) => el.fieldType?.dataType?.isReference);
            const metadata = state.data.fields.filter((el) => !el.fieldType?.dataType?.isReference);

            return {
                ...state,
                editFields: {
                    metadata,
                    reference,
                },
            };
        },
        [CONTENT_SCHEMA_RESET_EDIT_DATA]: (state, action) => {
            return { ...state, editData: state.data };
        },
        [CONTENT_SCHEMA_CLEAR]: () => {
            return { ...initialState };
        },
        [CONTENT_SCHEMA_UPDATE_ORIGINAL_DATA]: (state, action) => {
            return { ...state, data: { ...state.data, ...action.payload } };
        },
        [CONTENT_SCHEMA_UPDATE_EDIT_DATA]: (state, action) => {
            return { ...state, editData: { ...state.editData, ...action.payload } };
        },
        [CONTENT_SCHEMA_UPDATE_EDIT_FIELDS]: (state, action) => {
            return { ...state, editFields: { ...state.editFields, ...action.payload } };
        },
        [CONTENT_SCHEMA_UPDATE_EDIT_FIELDS_BY_TYPE]: (state, action) => {
            const { type, fields } = action.payload;
            return { ...state, editFields: { ...state.editFields, [type]: fields } };
        },
        [CONTENT_SCHEMA_UPDATE_EDIT_FIELD]: (state, action) => {
            const { field, type } = action.payload;
            let fields = [...state.editFields[type]];
            let fieldIndex = fields.findIndex((f) => f.key === field.key);
            if (fieldIndex < 0) fieldIndex = 0;
            fields[fieldIndex] = field;

            return { ...state, editFields: { ...state.editFields, [type]: fields } };
        },
        [CONTENT_SCHEMA_GET_PROJECTS_IN_USE_PENDING]: (state, action) => {
            return { ...state, projectsInUse: { ...state.projectsInUse, pending: true, error: null } };
        },
        [CONTENT_SCHEMA_GET_PROJECTS_IN_USE_SUCCESS]: (state, action) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    projectsInUse: action.payload,
                },
                projectsInUse: { ...state.projectsInUse, pending: false },
            };
        },
        [CONTENT_SCHEMA_GET_PROJECTS_IN_USE_FAILURE]: (state, action) => {
            return { ...state, projectsInUse: { ...state.projectsInUse, pending: false, error: action.payload } };
        },
        [CONTENT_SCHEMA_RESET_PROJECTS_IN_USE]: (state, action) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    projectsInUse: [],
                },
            };
        },
        [CONTENT_SCHEMA_UPDATE_FIELDS_FAILURE]: (state, action) => {
            return {
                ...state,
                error: action.payload,
            };
        },
        [CONTENT_SCHEMA_UPDATE_DATA_SUCCESS]: (state, action) => {
            const { fields } = action.payload;
            const newFields = fields?.map((f, idx) => ({ key: idx, ...f }));
            const originReferenceFields = state.editFields?.reference;
            const originMetadataFields = state.editFields?.metadata;

            const metadataFields = newFields
                ?.filter((el) => !el.fieldType?.dataType?.isReference)
                .map((el) => {
                    if (originMetadataFields.length > 0) {
                        const originField = originMetadataFields.find((f) => f.id === el.id);
                        return { ...el, key: originField.key };
                    }
                    return el;
                });

            const referenceFields = newFields
                ?.filter((el) => el.fieldType?.dataType?.isReference)
                .map((el) => {
                    if (originReferenceFields.length > 0) {
                        const originField = originReferenceFields.find((f) => f.id === el.id);
                        return { ...el, key: originField.key };
                    }
                    return el;
                });

            const allFields = [...metadataFields, ...referenceFields];

            return {
                ...state,
                pending: false,
                editPending: false,
                data: { ...action.payload, fields: allFields },
                editData: { ...action.payload, fields: allFields },
                editFields: { metadata: metadataFields, reference: referenceFields },
            };
        },
        [CONTENT_SCHEMA_UPDATE_DATA_PENDING]: (state, action) => {
            return {
                ...state,
                editPending: true,
            };
        },
        [CONTENT_SCHEMA_UPDATE_DATA_FAILURE]: (state, action) => {
            return {
                ...state,
                editPending: false,
            };
        },
    },
    initialState,
);
export const resetContentSchemaEditFields = createAction(CONTENT_SCHEMA_RESET_EDIT_FIELDS);
export const updateContentSchemaEditData = createAction(CONTENT_SCHEMA_UPDATE_EDIT_DATA);
export const updateContentSchemaEditField = createAction(CONTENT_SCHEMA_UPDATE_EDIT_FIELD);
export const updateContentSchemaEditFieldsByType = createAction(CONTENT_SCHEMA_UPDATE_EDIT_FIELDS_BY_TYPE);
export const updateContentSchemaEditFields = createAction(CONTENT_SCHEMA_UPDATE_EDIT_FIELDS);
export const clearContentSchema = createAction(CONTENT_SCHEMA_CLEAR);

export const getContentSchemaDetail = (schemaId, projectId) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: CONTENT_SCHEMA_DETAIL_PENDING });
    try {
        return dispatch(getContentSchemaData(schemaId, projectId))
            .then((response) => {
                dispatch({
                    type: CONTENT_SCHEMA_DETAIL_SUCCESS,
                    payload: response,
                });

                dispatch(getContentSchemaUseInProjects({ schemaId, projectId }));
                return response;
            })
            .catch((error) => {
                console.log(error);
            });
    } catch (error) {
        dispatch({
            type: CONTENT_SCHEMA_DETAIL_FAILURE,
            payload: error,
        });
    }
};

const getContentSchemaData = (schemaId, projectId) => (dispatch, getState) => {
    const { stage } = getState();
    return getContentSchemaDetailAPI(stage.id, stage.endpoint, projectId, schemaId)
        .then((response) => {
            const newData = response.data?.fields?.map((v) => {
                const dataType = v.fieldType?.dataType;
                const newDataType = parseTypeToFieldType(dataType);
                const newField = { ...v, fieldType: { ...v.fieldType, dataType: { ...newDataType } } };
                const parsedField = parseEnumType(newField);

                return { ...parsedField };
            });
            return { ...response.data, fields: newData };
        })
        .catch((error) => {
            return error;
        });
};

export const parseEnumType = (data, pageType = "create") => {
    const detailDataType = data.fieldType.dataType.detailType;
    const selectValue = pageType === "create" ? data?.defaultValue : data?.value;

    if (detailDataType === "SINGLE_SELECT") {
        const newEnum = data.fieldType?.dataType?.enum.map((el, index) => {
            const enumValue = el.value ?? el; //Note: 컨텐츠 수정 후 enum selected 재정립시 enum 값의 초기화가 필요하여 추가하였습니다.

            return { id: index, value: enumValue, error: null, selected: selectValue === enumValue };
        });

        return { ...data, fieldType: { ...data.fieldType, dataType: { ...data.fieldType.dataType, enum: newEnum } } };
    } else if (detailDataType === "MULTI_SELECT") {
        const properties = data?.fieldType?.dataType?.properties;

        const newEnum =
            properties?.length > 0
                ? properties[0].enum?.map((el, index) => {
                      const enumValue = el.value ?? el; //Note: 컨텐츠 수정 후 enum selected 재정립시 enum 값의 초기화가 필요하여 추가하였습니다.
                      return {
                          id: index,
                          value: enumValue,
                          error: null,
                          selected: selectValue?.findIndex((f) => f === enumValue) >= 0,
                      };
                  })
                : [];
        const newProperties = properties.length > 0 ? properties[0] : null;
        if (newProperties) newProperties.enum = newEnum;

        return {
            ...data,
            fieldType: {
                ...data.fieldType,
                dataType: {
                    ...data.fieldType.dataType,
                    properties: [newProperties],
                },
            },
        };
    } else return data;
};
export const updateContentSchema =
    ({ id, params, projectId }) =>
    (dispatch, getState) => {
        const { stage, contentSchema } = getState();

        return new Promise((resolve, reject) => {
            updateContentSchemaAPI(stage.endpoint, projectId, id, params)
                .then(async (response) => {
                    let data = response.data;
                    dispatch({
                        type: CONTENT_SCHEMA_UPDATE_ORIGINAL_DATA,
                        payload: { ...contentSchema.data, ...params },
                    });
                    dispatch({
                        type: CONTENT_SCHEMA_UPDATE_EDIT_DATA,
                        payload: { ...contentSchema.editData, ...params },
                    });
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

export const updateContentSchemaAction =
    ({ id, params, type, projectId }) =>
    (dispatch, getState) => {
        const { stage, contentSchema } = getState();

        return new Promise((resolve, reject) => {
            updateContentSchemaActionAPI(stage.endpoint, projectId, id, params)
                .then(async (response) => {
                    let data = response.data;

                    const updatedData = await getContentSchemaDetailAPI(stage.id, stage.endpoint, projectId, id);

                    dispatch({
                        type: CONTENT_SCHEMA_UPDATE_ORIGINAL_DATA,
                        payload: { ...contentSchema.data, ...updatedData.data },
                    });
                    dispatch({
                        type: CONTENT_SCHEMA_UPDATE_EDIT_DATA,
                        payload: { ...contentSchema.editData, [type]: params.data[type] },
                    });
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

export const deleteContentSchema =
    ({ projectId, schemaId }) =>
    (dispatch, getState) => {
        const { stage } = getState();

        return new Promise((resolve, reject) => {
            deleteContentSchemaAPI(stage.endpoint, projectId, schemaId)
                .then(async (response) => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

const getParsedEnumDataByCreate = ({ name, fieldType, isRequired, isHidden, defaultValue }) => {
    let newFieldType = { id: fieldType.id };
    if (fieldType.dataType.detailType === METADATA_FIELDS_TYPE.SINGLE_SELECT) {
        newFieldType = {
            ...newFieldType,
            enum: fieldType.dataType.enum?.map((d) => d.value),
        };
    } else if (fieldType.dataType.detailType === METADATA_FIELDS_TYPE.MULTI_SELECT) {
        newFieldType = {
            ...newFieldType,
            properties: [
                {
                    type: "enum",
                    enum: fieldType.dataType.properties[0].enum?.map((d) => d.value),
                },
            ],
        };
    }
    return {
        name,
        fieldType: newFieldType,
        isRequired,
        isHidden,
        defaultValue,
    };
};

const getParsedEnumDataByUpdate = ({ fieldType }) => {
    let newDataType = { id: fieldType?.dataType?.id };
    if (fieldType.dataType.detailType === METADATA_FIELDS_TYPE.SINGLE_SELECT) {
        newDataType = {
            ...newDataType,
            enum: fieldType.dataType.enum?.map((d) => d.value),
        };
    } else if (fieldType.dataType.detailType === METADATA_FIELDS_TYPE.MULTI_SELECT) {
        newDataType = {
            ...newDataType,
            properties: [
                {
                    type: "enum",
                    enum: fieldType.dataType.properties[0].enum?.map((d) => d.value),
                },
            ],
        };
    }
    return {
        dataType: { ...newDataType },
    };
};
const getParamsByActionCreate = (data, actionType) => {
    const fieldTargets = data.map((el) => getParsedEnumDataByCreate(el));

    const fieldParams = fieldTargets.length > 0 ? { type: actionType, data: { fields: fieldTargets } } : null;
    return { fieldParams };
};

const getParamsByActions = (data, actionType) => {
    const newData = [...data];
    const ids = newData.map((v) => v.id);
    const fieldParams = ids.length > 0 ? { type: actionType, data: { fieldIds: ids } } : null;
    return { fieldParams };
};

const getParamsByActionUpdate = (data, actionType) => {
    let fieldTypeTargets = [];
    const fieldTargets = data.map((el) => {
        const { id, name, fieldType, isRequired, isHidden, defaultValue } = el;
        if (
            actionType === CONTENT_SCHEMA_FIELD_ACTIONS.UPDATE &&
            [METADATA_FIELDS_TYPE.SINGLE_SELECT, METADATA_FIELDS_TYPE.MULTI_SELECT].includes(
                fieldType.dataType.detailType,
            )
        ) {
            const params = getParsedEnumDataByUpdate(el);
            fieldTypeTargets.push({ fieldTypeId: fieldType.id, params });
        }
        return {
            id,
            name,
            isRequired,
            isHidden,
            defaultValue,
        };
    });
    const fieldParams = fieldTargets.length > 0 ? { type: actionType, data: { fields: fieldTargets } } : null;
    return { fieldParams, fieldTypeTargets };
};

export const updateContentFieldsChangeOrder = (id, fields, projectId) => (dispatch, getState) => {
    const { stage } = getState();

    return new Promise(async (resolve, reject) => {
        try {
            const originFields = await getContentSchemaDetailAPI(stage.id, stage.endpoint, projectId, id);
            const isOrderChanged = fields.some((v, idx) => {
                return v.id !== originFields.data.fields[idx].id;
            });

            if (isOrderChanged) {
                const action = CONTENT_SCHEMA_FIELD_ACTIONS.CHANGE_ORDER;
                const params = getParamsByActions(fields, action);
                await updateContentSchemaFieldsAPI(stage.endpoint, projectId, id, params.fieldParams);
            }

            resolve();
        } catch (e) {
            dispatch({
                type: CONTENT_SCHEMA_UPDATE_DATA_FAILURE,
            });
            reject(e);
        }
    });
};

export const updateContentSchemaFields = (id, projectId) => (dispatch, getState) => {
    const { stage, contentSchema } = getState();

    const ACTION_TYPES = [
        CONTENT_SCHEMA_FIELD_ACTIONS.CREATE,
        CONTENT_SCHEMA_FIELD_ACTIONS.DELETE,
        CONTENT_SCHEMA_FIELD_ACTIONS.UPDATE,
    ];

    let errorActions = [];
    const targets = contentSchema.error ?? ACTION_TYPES;

    const updateFieldsByActions = async () => {
        try {
            let allFields = [...contentSchema.editFields.metadata, ...contentSchema.editFields.reference];

            for (const [index, el] of targets.entries()) {
                try {
                    const response = await updateFieldsType(el, index, allFields);

                    if (response?.params?.fieldParams) {
                        const newFields = await updateFields(response);
                        allFields = newFields;
                    } else {
                        allFields = response?.allFields;
                    }
                } catch (e) {}
            }
            return allFields;
        } catch (error) {
            return error;
        }
    };

    const updateFieldsType = (action, index, allFields) => {
        return new Promise((resolve, reject) => {
            try {
                const data = allFields?.filter((v) => v.actionType === action);
                if (data?.length === 0) resolve({ allFields });

                let params = {};
                if (action === CONTENT_SCHEMA_FIELD_ACTIONS.CREATE) {
                    params = getParamsByActionCreate(data, action);
                }
                if (action === CONTENT_SCHEMA_FIELD_ACTIONS.DELETE) params = getParamsByActions(data, action);
                if (action === CONTENT_SCHEMA_FIELD_ACTIONS.UPDATE) params = getParamsByActionUpdate(data, action);

                const updateFieldTypeTargets = params?.fieldTypeTargets?.map(({ fieldTypeId, params }) => {
                    return new Promise(async (resolve, reject) => {
                        try {
                            const response = await updateContentFieldTypeAPI(
                                stage.endpoint,
                                projectId,
                                fieldTypeId,
                                params,
                            );
                            resolve(response);
                        } catch (e) {
                            reject(e);
                        }
                    });
                });

                if (updateFieldTypeTargets) {
                    Promise.all([updateFieldTypeTargets])
                        .then((response) => {
                            const res = { action, params, data, allFields };
                            resolve(res);
                        })
                        .catch((e) => reject(e));
                } else {
                    const res = { action, params, data, allFields };
                    resolve(res);
                }
            } catch (e) {
                errorActions.push(action);
                reject(e);
            }
        });
    };

    const updateFields = ({ action, params, data, allFields }) => {
        let newFields = [...allFields];
        return updateContentSchemaFieldsAPI(stage.endpoint, projectId, id, params.fieldParams)
            .then((response) => {
                if (action === CONTENT_SCHEMA_FIELD_ACTIONS.CREATE) {
                    const responseData = response?.data;
                    if (data.length === responseData?.affectedCount) {
                        data.forEach((d, index) => {
                            const fieldIndex = newFields?.findIndex(
                                (el) =>
                                    el.key === d.key &&
                                    el.fieldType.dataType.isReference === d.fieldType.dataType.isReference &&
                                    !el.id,
                            );
                            const target = newFields[fieldIndex];
                            target.id = responseData?.ids[index];
                        });
                    }
                } else if (action === CONTENT_SCHEMA_FIELD_ACTIONS.DELETE) {
                    newFields = allFields.filter((v) => v.actionType !== action);
                }
                return newFields;
            })
            .catch((error) => {
                return error;
            });
    };

    dispatch({
        type: CONTENT_SCHEMA_UPDATE_DATA_PENDING,
    });
    return new Promise((resolve, reject) => {
        updateFieldsByActions()
            .then(async (updatedFields) => {
                await dispatch(updateContentFieldsChangeOrder(id, updatedFields, projectId))
                    .then(async () => {
                        const response = await dispatch(getContentSchemaData(id, projectId));
                        dispatch({
                            type: CONTENT_SCHEMA_UPDATE_DATA_SUCCESS,
                            payload: response,
                        });
                        resolve();
                    })
                    .catch((e) => {
                        errorActions.push(CONTENT_SCHEMA_FIELD_ACTIONS.CHANGE_ORDER);
                        reject(e);
                    });
            })
            .catch((e) => {
                reject(e);
            });
    });
};

export const updateContentFieldType = (id) => (dispatch, getState) => {
    const { stage, project, contentSchema } = getState();
    //TODO: 수정된 필드 구조 업데이트
    const params = {};

    return new Promise((resolve, reject) => {
        updateContentFieldTypeAPI(stage.endpoint, project.id, id, params)
            .then(async (response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getContentFieldTypes = (schemaType, params) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: CONTENT_SCHEMA_GET_FIELD_TYPE_LIST_PENDING });
    return getContentFieldTypesAPI(stage.id, stage.endpoint, project.id, params)
        .then((response) => {
            const newData = response.data?.items?.map((v) => {
                const newDataType = parseTypeToFieldType(v.dataType);
                const detailDataType = parseTypeToDetailType(v.dataType);
                if (detailDataType) v.dataType.detailType = detailDataType;

                return { ...v, dataType: { ...newDataType } };
            });

            const filteredData =
                schemaType === CONTENTS_SCHEMA_TYPE.EXTENDED_METADATA
                    ? newData?.filter((el) => !el?.dataType?.isReference)
                    : newData;

            dispatch({ type: CONTENT_SCHEMA_GET_FIELD_TYPE_LIST_SUCCESS, payload: filteredData });
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: CONTENT_SCHEMA_GET_FIELD_TYPE_LIST_FAILURE,
                payload: error,
            });
        });
};

export const parseTypeToFieldType = (dataType) => {
    const newDataType = { ...dataType };

    if (
        dataType?.resourceType ||
        (dataType?.type === "array" && dataType?.properties?.findIndex((v) => v.resourceType) >= 0)
    )
        newDataType.isReference = true;
    else newDataType.isReference = false;

    newDataType.detailType = parseTypeToDetailType(dataType);

    return newDataType;
};

export const parseTypeToDetailType = (dataType) => {
    //TODO: object type 추가
    if (dataType.type === "string") {
        //NOTE: 컨텐트 필드 타입 validation이 변경된다면 그에 맞게 수정해줘야 함. 컨텐트 필드 타입을 전체 조회해올수 없기 때문에 서버 데이터로 매핑할 수 없는 이슈.
        const SINGLE_DATE_FORMAT = "^\\d{4}-\\d{2}-\\d{2}$";
        const RANGE_DATE_FORMAT = "^\\d{4}-\\d{2}-\\d{2}~\\d{4}-\\d{2}-\\d{2}$";

        if (dataType.validation?.pattern === SINGLE_DATE_FORMAT) return METADATA_FIELDS_TYPE.SINGLE_DATE_PICKER;
        else if (dataType.validation?.pattern === RANGE_DATE_FORMAT) return METADATA_FIELDS_TYPE.RANGE_DATE_PICKER;

        if (dataType.validation?.max === 100) {
            return METADATA_FIELDS_TYPE.TEXT_FIELD;
        } else return METADATA_FIELDS_TYPE.TEXT_AREA;
    }

    if (dataType.type === "number") return METADATA_FIELDS_TYPE.NUMBER;

    if (dataType.type === "enum") return METADATA_FIELDS_TYPE.SINGLE_SELECT;

    // if (dataType.type === "object") {
    //     const resourceType = dataType.resourceType;
    //
    //     if (!resourceType) return null;
    //     if (resourceType.type === "COLLECTION") return REFERENCE_FIELDS_TYPE.COLLECTION;
    //
    //     if (["ASSET", "CONTENT"].includes(resourceType.type)) {
    //         if (resourceType.subType === "ASSET_IMAGE") return REFERENCE_FIELDS_TYPE.IMAGE_ASSET;
    //         if (resourceType.subType === "ASSET_VIDEO") return REFERENCE_FIELDS_TYPE.VIDEO_ASSET;
    //         if (resourceType.subType === "ASSET_CAPTION") return REFERENCE_FIELDS_TYPE.CAPTION_ASSET;
    //         if (resourceType.subType === "ASSET_FILE") return REFERENCE_FIELDS_TYPE.FILE_ASSET;
    //         if (resourceType.subType === "CONTENT_VIDEO") return REFERENCE_FIELDS_TYPE.VIDEO_CONTENT;
    //         if (resourceType.subType === "CONTENT_MUSIC") return REFERENCE_FIELDS_TYPE.MUSIC_CONTENT;
    //         if (resourceType.subType === "CONTENT_PEOPLE") return REFERENCE_FIELDS_TYPE.PEOPLE_CONTENT;
    //         if (resourceType.subType === "CONTENT_PHOTO") return REFERENCE_FIELDS_TYPE.PHOTO_CONTENT;
    //         if (resourceType.subType === "CONTENT_CUSTOM") return REFERENCE_FIELDS_TYPE.CUSTOM_CONTENT;
    //     }
    // }

    if (dataType.type === "array") {
        const property = dataType.properties?.length > 0 ? dataType.properties[0] : null;

        if (property.type === "enum") return METADATA_FIELDS_TYPE.MULTI_SELECT;
        // if (property.type === "enum" && property?.enum) return;

        const resourceType = property.resourceType;
        if (!resourceType) return null;

        if (resourceType.type === "COLLECTION") return REFERENCE_FIELDS_TYPE.COLLECTIONS;
        if (["ASSET", "CONTENT"].includes(resourceType.type)) {
            if (resourceType.type === "ASSET" && !resourceType.subType) return REFERENCE_FIELDS_TYPE.ASSETS;
            if (resourceType.subType === "ASSET_IMAGE") return REFERENCE_FIELDS_TYPE.IMAGE_ASSETS;
            if (resourceType.subType === "ASSET_AUDIO") return REFERENCE_FIELDS_TYPE.AUDIO_ASSETS;
            if (resourceType.subType === "ASSET_VIDEO") return REFERENCE_FIELDS_TYPE.VIDEO_ASSETS;
            if (resourceType.subType === "ASSET_CAPTION") return REFERENCE_FIELDS_TYPE.CAPTION_ASSETS;
            if (resourceType.subType === "ASSET_FILE") return REFERENCE_FIELDS_TYPE.FILE_ASSETS;
            if (resourceType.subType === "CONTENT_VIDEO") return REFERENCE_FIELDS_TYPE.VIDEO_CONTENTS;
            if (resourceType.subType === "CONTENT_MUSIC") return REFERENCE_FIELDS_TYPE.MUSIC_CONTENTS;
            if (resourceType.subType === "CONTENT_PEOPLE") return REFERENCE_FIELDS_TYPE.PEOPLE_CONTENTS;
            if (resourceType.subType === "CONTENT_PHOTO") return REFERENCE_FIELDS_TYPE.PHOTO_CONTENTS;
            if (resourceType.subType === "CONTENT_CUSTOM") return REFERENCE_FIELDS_TYPE.CUSTOM_CONTENTS;
        }
    }

    return null;
};

export const getContentSchemaUseInProjects =
    ({ schemaId, name, nextToken, projectId }) =>
    (dispatch, getState) => {
        const { stage, contentSchema } = getState();
        const params = { limit: 20, name, nextToken };

        return new Promise((resolve, reject) => {
            dispatch({ type: CONTENT_SCHEMA_GET_PROJECTS_IN_USE_PENDING });
            if (!nextToken) dispatch({ type: CONTENT_SCHEMA_RESET_PROJECTS_IN_USE });

            getContentSchemaUseInProjectsAPI(stage.id, stage.endpoint, projectId, schemaId, params)
                .then(async (response) => {
                    const projectsInUse = nextToken
                        ? contentSchema.data.projectsInUse.results.concat(response.data.results)
                        : response.data.results;
                    dispatch({
                        type: CONTENT_SCHEMA_GET_PROJECTS_IN_USE_SUCCESS,
                        payload: {
                            totalCount: response.data.totalCount,
                            results: projectsInUse,
                            nextToken: response.data.nextToken,
                        },
                    });

                    resolve(response.data.nextToken);
                })
                .catch((error) => {
                    dispatch({ type: CONTENT_SCHEMA_GET_PROJECTS_IN_USE_FAILURE, payload: error });
                    reject(error);
                });
        });
    };
