import { handleActions } from "redux-actions";
import fp from "lodash/fp";
import { axios } from "../cores/axiosWrapper";
import { inRange } from "lodash";

const requestShareAPI = (endpoint, projectId, params) => {
    return axios.post(`${endpoint}/v3/shares`, params, { headers: { projectId } });
};

const getShareRequestListAPI = (endpoint, params) => {
    return axios.get(`${endpoint}/v3/shares`, { params });
};

const getDetailShareRequestAPI = (endpoint, id) => {
    return axios.get(`${endpoint}/v3/shares/${id}`);
};

const getApproveListAPI = (endpoint, params) => {
    return axios.get(`${endpoint}/v3/shares`, { params });
};

const getApproversListAPI = (endpoint, params) => {
    return axios.get(`${endpoint}/v3/shares/approver-candidates`, { params });
};

const approveShareAPI = (apiEndpoint, data, params) => {
    return axios.put(`${apiEndpoint}/v3/shares/${params.approveId}/approver-actions/${params.status}`, data);
};

const getExternalShareListAPI = (endpoint, projectId, params) => {
    return axios.get(`${endpoint}/download-links`, { params, headers: { projectId } });
};

export const getExternalShareDetailAPI = (endpoint, projectId, id) => {
    return axios.get(`${endpoint}/download-links/${id}`, { headers: { projectId } });
};

const REQUEST_SHARE_PENDING = "REQUEST_SHARE_PENDING";
const REQUEST_SHARE_SUCCESS = "REQUEST_SHARE_SUCCESS";
const REQUEST_SHARE_FAILURE = "REQUEST_SHARE_FAILURE";
const PUT_APPROVE_SHARE_PENDING = "PUT_APPROVE_SHARE_PENDING";
const PUT_APPROVE_SHARE_FAILURE = "PUT_APPROVE_SHARE_FAILURE";
const PUT_APPROVE_SHARE_SUCCESS = "PUT_APPROVE_SHARE_SUCCESS";
const GET_APPROVE_SHARE_LIST_PENDING = "GET_APPROVE_SHARE_LIST_PENDING";
const GET_APPROVE_SHARE_LIST_SUCCESS = "GET_APPROVE_SHARE_LIST_SUCCESS";
const GET_APPROVE_SHARE_LIST_FAILURE = "GET_APPROVE_SHARE_LIST_FAILURE";
const GET_SHARE_REQUEST_LIST_PENDING = "GET_SHARE_REQUEST_LIST_PENDING";
const GET_SHARE_REQUEST_LIST_SUCCESS = "GET_SHARE_REQUEST_LIST_SUCCESS";
const GET_SHARE_REQUEST_LIST_FAILURE = "GET_SHARE_REQUEST_LIST_FAILURE";
const GET_DETAIL_SHARE_REQUEST_PENDING = "GET_DETAIL_SHARE_REQUEST_LIST_PENDING";
const GET_DETAIL_SHARE_REQUEST_SUCCESS = "GET_DETAIL_SHARE_REQUEST_LIST_SUCCESS";
const GET_DETAIL_SHARE_REQUEST_FAILURE = "GET_DETAIL_SHARE_REQUEST_LIST_FAILURE";
const GET_LINKED_SHARE_REQUESTS_IN_LIST_PENDING = "GET_LINKED_SHARE_REQUESTS_IN_LIST_PENDING";
const GET_LINKED_SHARE_REQUESTS_IN_LIST_SUCCESS = "GET_LINKED_SHARE_REQUESTS_IN_LIST_SUCCESS";
const GET_LINKED_SHARE_REQUESTS_IN_LIST_FAILURE = "GET_LINKED_SHARE_REQUESTS_IN_LIST_FAILURE";
const GET_SHARE_REQUEST_APPROVERS_PENDING = "GET_SHARE_REQUEST_APPROVERS_PENDING";
const GET_SHARE_REQUEST_APPROVERS_SUCCESS = "GET_SHARE_REQUEST_APPROVERS_SUCCESS";
const GET_SHARE_REQUEST_APPROVERS_FAILURE = "GET_SHARE_REQUEST_APPROVERS_FAILURE";
const GET_EXTERNAL_SHARE_LIST_PENDING = "GET_EXTERNAL_SHARE_LIST_PENDING";
const GET_EXTERNAL_SHARE_LIST_SUCCESS = "GET_EXTERNAL_SHARE_LIST_SUCCESS";
const GET_EXTERNAL_SHARE_LIST_FAILURE = "GET_EXTERNAL_SHARE_LIST_FAILURE";
const GET_EXTERNAL_SHARE_DETAIL_PENDING = "GET_EXTERNAL_SHARE_DETAIL_PENDING";
const GET_EXTERNAL_SHARE_DETAIL_SUCCESS = "GET_EXTERNAL_SHARE_DETAIL_SUCCESS";
const GET_EXTERNAL_SHARE_DETAIL_FAILURE = "GET_EXTERNAL_SHARE_DETAIL_FAILURE";

const initialState = {
    shareRequest: {
        pending: false,
        error: null,
        approvers: null,
    },
    requestList: {
        pending: false,
        error: null,
        results: null,
        approvers: null,
    },
    approveList: {
        pending: false,
        error: null,
        results: null,
    },
    shareApproval: {
        pending: false,
        error: false,
    },
    linkedRequestList: {
        pending: false,
        error: false,
        results: null,
    },
    requestResourceList: {
        pending: false,
        error: false,
        results: null,
    },
    externalShareList: {
        pending: false,
        error: null,
        results: null,
    },
};

let polling = [];

const cloneInitialState = fp.cloneDeep(initialState);

export default handleActions(
    {
        [REQUEST_SHARE_PENDING]: (state, action) => {
            return {
                ...state,
                shareRequest: { ...state.shareRequest, pending: true, error: null },
            };
        },
        [REQUEST_SHARE_SUCCESS]: (state, action) => {
            return {
                ...state,
                shareRequest: { ...state.shareRequest, pending: false },
            };
        },
        [REQUEST_SHARE_FAILURE]: (state, action) => {
            return {
                ...state,
                shareRequest: { ...state.shareRequest, pending: false, error: action.payload },
            };
        },
        [GET_SHARE_REQUEST_LIST_PENDING]: (state, action) => {
            return {
                ...state,
                requestList: { ...cloneInitialState.requestList, pending: true },
            };
        },
        [GET_SHARE_REQUEST_LIST_SUCCESS]: (state, action) => {
            return {
                ...state,
                requestList: { ...state.requestList, ...action.payload, pending: false },
            };
        },
        [GET_SHARE_REQUEST_LIST_FAILURE]: (state, action) => {
            return {
                ...state,
                requestList: { ...state.requestList, pending: false, error: action.payload },
            };
        },
        [GET_LINKED_SHARE_REQUESTS_IN_LIST_PENDING]: (state, action) => {
            return {
                ...state,
                linkedRequestList: { ...state.linkedRequestList, pending: true },
            };
        },
        [GET_LINKED_SHARE_REQUESTS_IN_LIST_SUCCESS]: (state, action) => {
            return {
                ...state,
                linkedRequestList: { ...state.linkedRequestList, results: action.payload, pending: false },
            };
        },
        [GET_LINKED_SHARE_REQUESTS_IN_LIST_FAILURE]: (state, action) => {
            return {
                ...state,
                linkedRequestList: { ...state.linkedRequestList, pending: false, error: action.payload },
            };
        },
        [GET_SHARE_REQUEST_APPROVERS_PENDING]: (state, action) => {
            return {
                ...state,
                shareRequest: { ...cloneInitialState.shareRequest, pending: true },
            };
        },
        [GET_SHARE_REQUEST_APPROVERS_SUCCESS]: (state, action) => {
            return {
                ...state,
                shareRequest: { ...state.shareRequest, pending: false, approvers: action.payload },
            };
        },
        [GET_SHARE_REQUEST_APPROVERS_FAILURE]: (state, action) => {
            return {
                ...state,
                shareRequest: { ...state.shareRequest, pending: false, error: action.payload },
            };
        },
        [PUT_APPROVE_SHARE_PENDING]: (state, action) => {
            return {
                ...state,
                shareApproval: { ...initialState.shareApproval, pending: true },
            };
        },
        [PUT_APPROVE_SHARE_SUCCESS]: (state, action) => {
            return {
                ...state,
                shareApproval: { ...state.shareApproval, pending: false },
            };
        },
        [PUT_APPROVE_SHARE_FAILURE]: (state, action) => {
            return {
                ...state,
                shareApproval: { ...state.shareApproval, pending: false, error: action.payload },
            };
        },
        [GET_APPROVE_SHARE_LIST_PENDING]: (state, action) => {
            return {
                ...state,
                approveList: { ...cloneInitialState.approveList, pending: true },
            };
        },
        [GET_APPROVE_SHARE_LIST_SUCCESS]: (state, action) => {
            return {
                ...state,
                approveList: { ...state.approveList, pending: false, results: action.payload.results },
            };
        },
        [GET_APPROVE_SHARE_LIST_FAILURE]: (state, action) => {
            return {
                ...state,
                approveList: { ...state.approveList, pending: false, error: action.payload },
            };
        },
        [GET_EXTERNAL_SHARE_LIST_PENDING]: (state, action) => {
            return {
                ...state,
                externalShareList: { ...state.externalShareList, pending: true },
            };
        },
        [GET_EXTERNAL_SHARE_LIST_SUCCESS]: (state, action) => {
            return {
                ...state,
                externalShareList: { ...state.externalShareList, pending: false, results: action.payload.results },
            };
        },
        [GET_EXTERNAL_SHARE_LIST_FAILURE]: (state, action) => {
            return {
                ...state,
                externalShareList: { ...state.externalShareList, pending: false, error: action.payload },
            };
        },
    },
    initialState,
);

export const requestShare = (params) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: REQUEST_SHARE_PENDING });

    return new Promise((resolve, reject) => {
        requestShareAPI(stage.endpoint, project.id, params)
            .then((response) => {
                dispatch({ type: REQUEST_SHARE_SUCCESS });
                resolve(response.data);
            })
            .catch((error) => {
                dispatch({ type: REQUEST_SHARE_FAILURE, payload: error });
                reject(error);
            });
    });
};

export const getShareRequestList = (params) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: GET_SHARE_REQUEST_LIST_PENDING });
    return new Promise((resolve, reject) => {
        getShareRequestListAPI(stage.endpoint, params)
            .then((response) => {
                dispatch({ type: GET_SHARE_REQUEST_LIST_SUCCESS, payload: response.data });
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                dispatch({ type: GET_SHARE_REQUEST_LIST_FAILURE, payload: error });
            });
    });
};

export const getApproversList = (params) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: GET_SHARE_REQUEST_APPROVERS_PENDING });

    return new Promise((resolve, reject) => {
        getApproversListAPI(stage.endpoint, params)
            .then((response) => {
                const approverOptions = response.data.map((approver) => ({
                    ...approver,
                    label: approver.name,
                    value: approver.id,
                }));
                dispatch({ type: GET_SHARE_REQUEST_APPROVERS_SUCCESS, payload: approverOptions });
                resolve(approverOptions);
            })
            .catch((error) => {
                dispatch({ type: GET_SHARE_REQUEST_APPROVERS_FAILURE, payload: error });
                reject(error);
            });
    });
};

export const getLinkedRequestList = (params) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: GET_LINKED_SHARE_REQUESTS_IN_LIST_PENDING });

    return new Promise((resolve, reject) => {
        Promise.all(params.map((id) => getDetailShareRequestAPI(stage.endpoint, id)))
            .then((responses) => {
                dispatch({
                    type: GET_LINKED_SHARE_REQUESTS_IN_LIST_SUCCESS,
                    payload: responses.map((response) => ({ id: response.data.id, status: response.data.status })),
                });
                resolve();
            })
            .catch((error) => {
                dispatch({ type: GET_LINKED_SHARE_REQUESTS_IN_LIST_FAILURE, payload: error });
                reject(error);
            });
    });
};

export const getApproveList = (params) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: GET_APPROVE_SHARE_LIST_PENDING });
    return new Promise((resolve, reject) => {
        getApproveListAPI(stage.endpoint, params)
            .then((response) => {
                dispatch({ type: GET_APPROVE_SHARE_LIST_SUCCESS, payload: response.data });
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                dispatch({ type: GET_APPROVE_SHARE_LIST_FAILURE, payload: error });
                reject(error);
            });
    });
};

export const setApproveShare =
    ({ data, params }) =>
    (dispatch, getState) => {
        const { stage } = getState();

        dispatch({ type: PUT_APPROVE_SHARE_PENDING });
        return new Promise((resolve, reject) => {
            approveShareAPI(stage.endpoint, data, params)
                .then((response) => {
                    dispatch({
                        type: PUT_APPROVE_SHARE_SUCCESS,
                        payload: response.data,
                    });
                    resolve(response.data);
                })
                .catch((error) => {
                    dispatch({
                        type: PUT_APPROVE_SHARE_FAILURE,
                        payload: error,
                    });
                    reject(error);
                });
        });
    };

export const getExternalShareList = (params) => (dispatch, getState) => {
    const { stage, project } = getState();
    dispatch({ type: GET_EXTERNAL_SHARE_LIST_PENDING });

    if (polling) {
        clearTimeout(polling);
    }

    const doPolling = () => {
        return new Promise((resolve, reject) => {
            getExternalShareListAPI(stage.endpoint, project.id, params)
                .then((response) => {
                    dispatch({ type: GET_EXTERNAL_SHARE_LIST_SUCCESS, payload: response.data });

                    const preparingItems = response.data.results.filter((item) => item.readyStatus === "PREPARING");
                    if (preparingItems.length > 0) {
                        polling = setTimeout(() => {
                            doPolling();
                        }, [4000]);
                    }
                    resolve(response.data);
                })
                .catch((error) => {
                    dispatch({ type: GET_EXTERNAL_SHARE_LIST_FAILURE, payload: error });
                    reject(error);
                });
        });
    };

    return doPolling();
};
