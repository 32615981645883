import { handleActions, createAction } from "redux-actions";
import { axios } from "../cores/axiosWrapper";
import queryString from "../cores/queryString";

const getKeysAPI = (apiEndpoint, projectId, queryParams) => {
    const search = queryParams || {};
    // console.log(`queryParams: ${JSON.stringify(queryParams)}`);
    return axios.get(`${apiEndpoint}/file-meta-keys?${queryString.stringify(search)}`, { headers: { projectId } });
};

const FILE_META_GET_KEYS_PENDING = "ATTRIBUTIONS_GET_KEYS_PENDING";
const FILE_META_GET_KEYS_SUCCESS = "ATTRIBUTIONS_GET_KEYS_SUCCESS";
const FILE_META_GET_KEYS_FAILURE = "ATTRIBUTIONS_GET_KEYS_FAILURE";

const initialState = {
    error: false,
    keys: {
        pending: false,
        error: false,
        items: [],
    },
};

export default handleActions(
    {
        [FILE_META_GET_KEYS_PENDING]: (state) => {
            return {
                ...state,
                keys: {
                    ...state.keys,
                    pending: true,
                    error: false,
                },
            };
        },
        [FILE_META_GET_KEYS_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            return {
                ...state,
                keys: {
                    items: data.keys,
                    totalCount: data.totalCount,
                    pending: false,
                    error: false,
                },
            };
        },
        [FILE_META_GET_KEYS_FAILURE]: (state) => {
            return {
                ...state,
                keys: {
                    ...state.keys,
                    pending: false,
                    error: true,
                },
            };
        },
    },
    initialState,
);

export const getKeys = (query) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: FILE_META_GET_KEYS_PENDING });

    return new Promise((resolve, reject) => {
        getKeysAPI(stage.endpoint, project.id, query)
            .then((response) => {
                dispatch({
                    type: FILE_META_GET_KEYS_SUCCESS,
                    payload: response,
                });
                resolve(response.data);
            })
            .catch((error) => {
                dispatch({
                    type: FILE_META_GET_KEYS_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};
