import { createGlobalStyle } from "styled-components";

import theme from "@styles/theme";

const GlobalStyle = createGlobalStyle`
  body, button, input, select, textarea {
    font-family: 'Noto Sans KR', sans-serif;
    color: #3c3d4f;
  }

  table,
  .control,
  .breadcrumb,
  .additional,
  .form-search,
  .card,
  .list-items,
  .list-captions,
  .list-format,
  .tab-nav,
  .btn-dropdown-list,
  .list-schedule,
  .Toastify,
  .list-notice,
  .list-sources,
  .modal,
  .panel,
  .preview,
  .category-select,
  .d-flex,
  .metadata-search,
  .select2-container .select2-selection__menu {
    font-size: 14px;
  }
  .dashboard-head {
    margin-top: -16px;
    margin-left: -40px;
    margin-right: -40px;
  }
  //.MzcCpIcon-root + * {
  //  margin-left: 4px;
  //}



  div::-webkit-scrollbar,
  ul::-webkit-scrollbar {
    width: 14px;
    height: 14px;
  }

  main::-webkit-scrollbar-track,
  div::-webkit-scrollbar-track,
  ul::-webkit-scrollbar-track {
    background: transparent;
  }

  main::-webkit-scrollbar-thumb,
  div::-webkit-scrollbar-thumb,
  ul::-webkit-scrollbar-thumb {
    background-color: #dce1e5;
    border-radius: 8px;
    background-clip: padding-box;
    border: 4px solid transparent;
  }


  .reflex-container.vertical > .reflex-splitter {
    &:hover,
    &.active {
      border-color: #2763ea !important;
    }
  }

  @media screen and (max-width: 768px) {

    .nav-fold {
      .main {
        padding: 0;
      }

      .page-header {
        left: 0;
      }
    }

    .wrap-cms {
      .contents {
        max-width: 100vw
      }

      .page-header-tools {
        display: none
      }

      .card-folder .view .card-search .form-search {
        position: relative;

        .area-type {
          position: absolute;
          right: 0;
          top: 0;

          strong {
            display: none;
          }

          .btn {
            padding: 10px
          }
        }

        .area-expand {
          top: 0;
          left: 0;
          right: 0;
          margin-top: 5px;

          .area-search {
            max-height: none;
            height: auto;
          }
        }
      }

      .dashboard-body .widgets .widget .body .inner {
        max-width: calc(100vw - 50px);
      }
      .tree {
        position: absolute;
        top: 100px;
        width: auto;
        min-width: 0;
        max-width: none;
        flex: auto;
        &.toggle-active {
          left: 0;
          z-index: 1030;
          width: 66vw;
        }
      }
      .card-folder {
          > .tree {
            position: absolute;
            left: inherit;
            .btn-tree-fold {
              display: none;
            }
          }
      }
    }
  }
`;

export default GlobalStyle;
