import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const PanelBodyInformation = (props) => {
    const { className, ...otherProps } = props;
    return <div {...otherProps} className={clsx("panel-body-information", className)} />;
};
PanelBodyInformation.propTypes = {
    className: PropTypes.string,
};

export default PanelBodyInformation;
