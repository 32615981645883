import React, { useCallback, useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { getProjectSettings } from "@modules/settings";
import { IconCancel, IconWarningTriangle } from "@mzc-cloudplex/icons";
import fp from "lodash/fp";
import { Popper } from "@mui/material";
import { CATEGORY_TYPES } from "@constants";
import { useLocation, useParams } from "react-router-dom";

const MetadataUseStatusTooltip = ({
    target,
    id,
    type,
    categoryGroupId,
    categoryType,
    activeTab,
    tooltipDisabled = true,
    ...props
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [status, setStatus] = useState(null);
    const [open, setOpen] = useState(false);
    const ref = useRef();
    const tooltipId = open ? "simple-popper" : undefined;
    const { schemaId } = useParams();

    const { stage, project, settings } = useSelector(({ stage, project, settings }) => ({
        stage: stage,
        project,
        settings,
    }));

    const defaultAnchor = {
        anchorOrigin: {
            vertical: "top",
            horizontal: "right",
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "left",
        },
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    const getTypeName = useMemo(() => {
        switch (categoryType) {
            case CATEGORY_TYPES.ASSET:
            case CATEGORY_TYPES.MUSIC:
            case CATEGORY_TYPES.CHANNEL:
            case CATEGORY_TYPES.MEETING:
            case CATEGORY_TYPES.PEOPLE:
            case CATEGORY_TYPES.PHOTO:
            case CATEGORY_TYPES.VIDEO:
                return categoryType.toLowerCase();
            case CATEGORY_TYPES.METADATA_TEMPLATE:
                return "metadata template";
            case CATEGORY_TYPES.CUSTOM_CONTENTS:
                return "custom content";
        }
    }, [categoryType]);

    useEffect(() => {
        if (!settings.project[categoryType])
            dispatch(
                getProjectSettings({
                    //settings->category group
                    stage: stage.id,
                    apiEndpoint: stage.endpoint,
                    projectId: project.id,
                    params: {
                        resource: categoryType,
                    },
                }),
            );
    }, []);

    useEffect(() => {
        let compareCategoryGroupId = fp.get(categoryType, settings.project)?.categoryGroupId?.value;

        if (!compareCategoryGroupId || !categoryGroupId || !categoryType) return;

        if (categoryGroupId === compareCategoryGroupId) {
            setStatus("InUse");
            setOpen(false);
        } else {
            setStatus("NotUsed");
            if (!tooltipDisabled) setAnchorEl(ref.current);
        }
    }, [settings, categoryGroupId, categoryType, settings.project]);

    useEffect(() => {
        if (anchorEl) setOpen(true);
    }, [anchorEl]);

    return (
        <>
            <div className="popper-tooltip" ref={ref}>
                {status &&
                    ((status === "InUse" && <span className="badge-in-use">{t(`common::label::${"In Use"}`)}</span>) ||
                        (status === "NotUsed" && (
                            <span
                                id={`badge-not-used-${categoryGroupId}`}
                                className="badge-not-used"
                                aria-describedby={tooltipId}
                                width={"20"}
                                height={"20"}
                            >
                                {t(`common::label::${"Not Used"}`)}
                            </span>
                        )))}
                {(!activeTab || activeTab === "Metadata") && anchorEl && (
                    <Popper
                        disabled={tooltipDisabled}
                        id={tooltipId}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={props.anchorOrigin || defaultAnchor.anchorOrigin}
                        transformOrigin={props.transformOrigin || defaultAnchor.transformOrigin}
                    >
                        <div className={"tooltip-always-open"}>
                            <div className={"tooltip"} style={{ margin: "0px" }}>
                                <IconWarningTriangle color={"warning"} size={16} />
                                <p>
                                    {t(
                                        `common::msg::${"Since this is a category group that is not used in {{name}}s, you can check the created {{name}} in 'categories not classified in {{name}}s'."}`,
                                        `Since this is a category group that is not used in ${getTypeName}s, you can check the created ${getTypeName} in 'categories not classified in ${getTypeName}s'.`,
                                        { name: getTypeName },
                                    )}
                                </p>
                                <button type={"button"} onClick={() => handleClose()}>
                                    <IconCancel size={12} htmlColor={`#fff`} />
                                </button>
                            </div>
                        </div>
                    </Popper>
                )}
            </div>
        </>
    );
};
MetadataUseStatusTooltip.propTypes = {
    target: PropTypes.object,
    id: PropTypes.string,
    type: PropTypes.string,
    activeTab: PropTypes.string,
};

export default MetadataUseStatusTooltip;
