import { styled, Grid as MuiGrid, alpha, Box as MuiBox } from "@mui/material";
// import { styled, alpha} from '@mui/system';

// import {Grid as MuiGrid, } from '@mui/material';
import theme from "@styles/theme";

export const MediaType = styled("div", {
    shouldForwardProp: (prop) => !["type", "isEdit"].includes(String(prop)),
})(({ type, isEdit }) => ({
    display: `inline-flex`,
    height: `100%`,
    ...(type === "panel" && {
        paddingTop: `3px`,
    }),
    ...(type === "detail" && {
        paddingTop: `7px`,
        "> div": {
            marginRight: `12px`,
        },
    }),
    ...(isEdit && {
        position: `absolute`,
        zIndex: `1`,
        left: `10px`,
        top: `10px`,
    }),
}));

export const StyledEditName = styled(MuiGrid, {
    shouldForwardProp: (prop) => !["type", "mediaType", "isEdit", "isEditable", "valueLength"].includes(String(prop)),
})(({ theme, type, isEdit, isEditable }) => ({
    position: `relative`,
    display: `flex`,
    flexWrap: `nowrap`,
    alignItems: `stretch`,
    flexDirection: `column`,
    padding: `10px`,
    borderRadius: `2px`,
    gap: `4px`,
    ...(type === "panel" && {
        minHeight: `44px`,
        marginRight: `27px`,
    }),
    ...(type === "detail" && {
        minHeight: `49px`,
    }),

    ...(isEdit && {
        padding: 0,
        flex: 1,
        "&:hover": {
            backgroundColor: `transparent`,
        },
        ".MuiInputBase-root": {
            padding: `12px 14px 8px 10px !important`,
            minHeight: `44px`,
        },
    }),
}));

export const StyledEditNameContent = styled("div", {
    shouldForwardProp: (prop) => !["type", "valueLength"].includes(String(prop)),
})(({ theme, type, valueLength }) => ({
    display: `flex`,
    width: `100%`,
    ".MuiTypography-root": {
        textAlign: `left`,
        wordBreak: `break-all`,
        fontWeight: `bold`,
        ...(type === "panel" && {
            fontSize: valueLength > 107 ? 14 : 16,
            lineHeight: 1.2,
            paddingTop: `2px`,
        }),
        ...(type === "detail" && {
            fontSize: 20,
            lineHeight: 1.5,
        }),
    },
    ">.MuiBox-root ": {
        flex: 1,
    },
    ".btn-edit": {
        marginLeft: `12px`,
        marginTop: `3px`,
        ...(type === "detail" && {
            marginTop: `3px`,
        }),
    },
}));

export const StyledEditNameActions = styled("div")(({}) => ({}));
