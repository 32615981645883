import { handleActions, createAction } from "redux-actions";

const ADD_PAUSED_POLLING_APIS = "ADD_PAUSED_POLLING_APIS";
const REMOVE_PAUSED_POLLING_APIS = "REMOVE_PAUSED_POLLING_APIS";

//reducer
const initialState = {
    apis: [],
};

export default handleActions(
    {
        [ADD_PAUSED_POLLING_APIS]: (state, action) => {
            return { ...state, apis: [...state.apis, action.payload] };
        },
        [REMOVE_PAUSED_POLLING_APIS]: (state, action) => {
            return {
                ...state,
                apis: state.apis?.filter((api) => action.payload !== api.type),
            };
        },
    },
    initialState,
);

export const addPausedPollingApis = createAction(ADD_PAUSED_POLLING_APIS);
export const removePausedPollingApis = createAction(REMOVE_PAUSED_POLLING_APIS);

export const getPausedPollingApis = (type) => (dispatch, getState) => {
    const { pausedPollingApis } = getState();

    return pausedPollingApis.apis.filter((api) => api.type === type);
};
