import {handleActions, createAction} from 'redux-actions';
import {
    axios,
    axiosWithoutHeaders
} from "../cores/axiosWrapper";

function getAssetsAPI(stageId, apiEndpoint, projectId, params) {
    return axios.get(`${apiEndpoint}/assets`, {
        params,
        headers : {
            stageId,
            projectId
        }
    });
}

function getAssetAPI(stageId,apiEndpoint, projectId, assetId, params) {
    // TODO: Module쪽을 호출해서 사용하도록 수정
    return axios.get(`${apiEndpoint}/assets/${assetId}`, {
        params, 
        headers : {
            stageId,
            projectId
        }
    });
}
//action type
const LISTING_ASSETS_PENDING = 'LISTINGS_ASSETS_PENDING';
const LISTING_ASSETS_FAILURE = 'LISTING_ASSETS_FAILURE';
const GET_LISTING_ASSETS_SUCCESS = 'GET_LISTING_ASSETS_SUCCESS';
const SET_LISTING_DROP = 'SET_LISTING_DROP';
const RESET_ASSET_LIST_DATA = 'RESET_ASSET_LIST_DATA';
const SET_LISTING_ASSET_SELECTED = 'SET_LISTING_ASSET_SELECTED';
const GET_LISTING_ASSET_SUCCESS = 'GET_LISTING_ASSET_SUCCESS';
const DELETE_LISTING_ASSET_SELECTED = 'DELETE_LISTING_ASSET_SELECTED';
const UPDATE_LISTING_ASSET_ITEM = 'UPDATE_LISTING_ASSET_ITEM';
const SET_LISTING_ASSET_INIT_ITEM = 'SET_LISTING_ASSET_INIT_ITEM';
const SET_LISTING_ASSET_COUNT = 'SET_LISTING_ASSET_COUNT';
//reduceer
const initialState = {
    pending: false,
    error: false,
    data: [],
    selected : {
        id : '',
        name : '',
        start : '',
        end : '',
        duration : '',
        thumbnails : '',
        job : {},
        type : '',
        size : 0,
        tags : [],
        username : '',
        categories : []
    },
    standardDateTime : null,
    counts : {
        detail : 0,
        list : 0
    },
    item : null,
    totalCount: 0
};
//TODO initialstate 가 형태가 같은 타입일 경우 동일하다..이를 구조화하는 방법을 찾아보자.

export default handleActions({
    [LISTING_ASSETS_PENDING]: (state) => {
        return {
            ...state,
            pending: true,
            error: false
        };
    },
    [LISTING_ASSETS_FAILURE]: (state) => {
        return {
            ...state,
            pending: false,
            error: true
        };
    },
    [GET_LISTING_ASSETS_SUCCESS]: (state, action) => {
        const {assets, totalCount} = action.payload.data;
        
        return {
            ...state,
            pending: false,
            data: assets ? assets.map(v => {
                return {
                    type : 'asset',
                    isDropped : false,
                    ...v
                }
            }) : [],
            counts : {
                ...state.counts,
                list : state.counts + 1
            },
            totalCount
        };
    },
    [SET_LISTING_DROP]: (state, action) => {
        const {ids} = action.payload;

        return {
            ...state,
            data : state.data.map(v => {
                return {
                    ...v,
                    isDropped: ids.indexOf(v.id) !== -1
                }
            })
        }
    },
    [RESET_ASSET_LIST_DATA]: (state) => {
        return {
            ...state,
            data : [],
            counts : {
                detail : 0,
                list : 0
            },
            totalCount : 0
        }
    },
    [SET_LISTING_ASSET_SELECTED]:(state, action) => {
        return {
            ...state,
            selected: action.payload,
            counts : {
                ...state.counts,
                detail : state.counts.detail + 1
            }
        };
    },
    [GET_LISTING_ASSET_SUCCESS]:(state, action) => {
        const {response, standardDateTime} = action.payload;

        return {
            ...state,
            pending: false,
            item: response.data,
            standardDateTime,
            counts : {
                ...state.counts,
                detail : state.counts.detail + 1
            }
        };
    },
    [DELETE_LISTING_ASSET_SELECTED]: (state) => {
        return {
            ...state,
            selected : {
                id : '',
                name : '',
                start : '',
                end : '',
                format : '',
                duration : '',
                isEncrypted: 0,
                poster : '',
                size : 0,
                description : '',
                username : '',
                categories : []
            },
            item : null
        }
    },
    [UPDATE_LISTING_ASSET_ITEM]:(state, action) => {
        return {
            ...state,
            item : {
                ...state.item,
                ...action.payload
            }
        }
    },
    [SET_LISTING_ASSET_INIT_ITEM]:(state) => {
        return {
            ...state,
            pending:true,
            item : null
        }
    },
    [SET_LISTING_ASSET_COUNT]:(state, action) => {
        return {
            ...state,
            counts : {
                ...state.counts,
                ...action.payload
            }
        }
    }
}, initialState);

export const resetAssetListData = createAction(RESET_ASSET_LIST_DATA);
export const setAssetSelected = createAction(SET_LISTING_ASSET_SELECTED);
export const setDrop = createAction(SET_LISTING_DROP);
export const deletedAssetSelected = createAction(DELETE_LISTING_ASSET_SELECTED);
export const getAssets = (params) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    if (stage.id === '') {
        return;
    }

    dispatch({type: LISTING_ASSETS_PENDING});

    return getAssetsAPI(stage.id, stage.endpoint, project.id, params).then(
        (response) => {
            dispatch({
                type: GET_LISTING_ASSETS_SUCCESS,
                payload: response
            });
        }
    ).catch(error => {
        dispatch({
            type: LISTING_ASSETS_FAILURE,
            payload: error
        });
    });
};
export const getAsset = (assetId, standardDateTime) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    if (stage.id === '') {
        return;
    }

    dispatch({type: SET_LISTING_ASSET_INIT_ITEM});

    return getAssetAPI(stage.id, stage.endpoint, project.id, assetId, { 
        hasAssociations : true,
        generateAccessUrl: true 
    }).then(
        (response) => {
            dispatch({
                type: GET_LISTING_ASSET_SUCCESS,
                payload: {
                    response,
                    standardDateTime
                }
            });
        }
    ).catch(error => {
        dispatch({
            type: LISTING_ASSETS_FAILURE,
            payload: error
        });
    });
};
export const updateItem = createAction(UPDATE_LISTING_ASSET_ITEM);
export const setAssetCount = createAction(SET_LISTING_ASSET_COUNT);