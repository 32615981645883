import { Box, Chip, Grid, TextField, List, InputAdornment, Popper, Avatar } from "@mzc-pdc/ui";
import React, {
    forwardRef,
    useCallback,
    useMemo,
    useRef,
    useState,
    ForwardedRef,
    useImperativeHandle,
    useEffect,
} from "react";
import { getProjectsAPI } from "@modules/projectManagement";
import { AutocompleteProject } from "@components_v2/autocomplete";
import { useDispatch, useSelector } from "react-redux";

const ProjectSingleInput = ({
    data,
    project,
    disabled = false,
    onChange,
    placeholder,
    isEdit,
    required = false,
    error,
    helperText,
    sx,
}) => {
    const space = useSelector(({ stage }) => stage);

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [selectedProject, setSelectedProject] = useState(data); //현재 선택한 유저(유저의 아이디만 popover로 넘겨주면 되서 자체력으로 autocomplete를 사용하도록 만들었습니다.)
    const [pendingProjects, setPendingProjects] = useState(false);

    const projectPage = useRef(0);
    const totalProject = useRef(0);

    const LIMIT = 40;

    const loadProjectList = useCallback(
        async (isInitValue = false) => {
            try {
                if (isInitValue) {
                    projectPage.current = 0;
                    totalProject.current = 0;
                }

                if (
                    pendingProjects ||
                    (totalProject.current > 0 && totalProject.current <= projectPage.current * LIMIT)
                )
                    return;
                setPendingProjects(true);
                const response = await getProjectsAPI(space.id, space.endpoint, {
                    offset: projectPage.current,
                    limit: LIMIT,
                });

                let newData = [];
                if (isInitValue) newData = response.data.results;
                else {
                    newData = [...options, ...response.data.results];
                }

                setOptions(newData);
                projectPage.current += 1;
                totalProject.current = response.data.totalCount;
            } catch (error) {
                console.error(error);
            } finally {
                setPendingProjects(false);
            }
        },
        [pendingProjects, space, data],
    );

    const onHandleDeleteUser = useCallback((index) => {
        setSelectedProject(null);
    }, []);

    const onHandleFetchUser = useCallback(() => {
        loadProjectList();
    }, []);

    const handleInputChange = (reason) => {
        if (reason === "clear") {
            setSelectedProject(null);
            onChange(null);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const projectOptions = useMemo(() => {
        return (
            options?.map((project, idx) => ({
                name: project.name,
                id: project.id,
                lastOption: options.length > 1 && idx === options.length - 1,
            })) || []
        );
    }, [options]);

    useEffect(() => {
        setSelectedProject(data);
    }, [data]);

    useEffect(() => {
        if (project) {
            setOptions([{ id: project.id, name: project.name }]);
        } else {
            loadProjectList(true);
        }
    }, []);

    return (
        <AutocompleteProject
            value={selectedProject}
            disabled={disabled}
            isEdit={isEdit}
            placeholder={placeholder}
            options={projectOptions}
            sx={sx}
            handleClose={handleClose}
            onFetchData={onHandleFetchUser}
            onInputChange={handleInputChange}
            onChange={onChange}
            onHandleDeleteUser={onHandleDeleteUser}
            onSubmit={() => {}}
            error={error}
            helperText={helperText}
        />
    );
};

export default ProjectSingleInput;
