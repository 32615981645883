"use strict";

import { handleActions, createAction } from "redux-actions";
import moment from 'moment';
import { axios } from "../cores/axiosWrapper";

const getMeetingsAPI = (stageId, apiEndpoint, projectId, params) => {
    return axios.get(`${apiEndpoint}/meetings`, {
        headers: {
            stageId,
            projectId,
        },
        params,
    });
};

const deleteMeetingAPI = (stageId, apiEndpoint, projectId, id) => {
    return axios.delete(`${apiEndpoint}/meetings/${id}`, {
        headers: { stageId, projectId, }
    });
};

const INIT_SEARCH_STATE = 'INIT_SEARCH_STATE';
const RESET_MEETINGS = 'RESET_MEETINGS';
const MEETINGS_PENDING = 'MEETINGS_PENDING';
const MEETINGS_FAILURE = 'MEETINGS_FAILURE';
const GET_MEETINGS_SUCCESS = 'GET_MEETINGS_SUCCESS';
const DELETE_MEETINGS_SUCCESS = 'DELETE_MEETINGS_SUCCESS';
const TOGGLE_DROPDOWN_MENU = 'TOGGLE_DROPDOWN_MENU';
const UPDATE_MEETING_STATUS = 'UPDATE_MEETING_STATUS';
const UPDATE_MEETINGS_SEARCH = 'UPDATE_MEETINGS_SEARCH';
const UPDATE_MEETINGS_STATE = 'UPDATE_MEETINGS_STATE';

const initialState = {
    pending: false,
    error: false,
    totalCount: 0,
    meetings: [],
    keywordType: "title",
    search: {
        id: '',
        title: '',
        status: '',
        startDate: null,
        endDate: null,
        offset: 0,
        limit: 20,
    },
};

export default handleActions({
    [RESET_MEETINGS]: () => {
        return {
            ...initialState,
        };
    },
    [INIT_SEARCH_STATE]: (state) => {
        return {
            ...state,
            keywordType: initialState.keywordType,
            search: {
                ...initialState.search,
            },
        };
    },
    [MEETINGS_PENDING]: (state) => {
        return {
            ...state,
            pending: true,
            error: false,
        };
    },
    [MEETINGS_FAILURE]: (state) => {
        return {
            ...state,
            pending: false,
            error: true,
        };
    },
    [GET_MEETINGS_SUCCESS]: (state, action) => {
        const { data } = action.payload;
        const list = data.list.map((o) => {
            o.isOpenMenu = false;
            return o;
        });

        return {
            ...state,
            pending: false,
            error: false,
            list: list,
            totalCount: data.count,
        };
    },
    [DELETE_MEETINGS_SUCCESS]: (state, action) => {
        const { id } = action.payload;

        return {
            ...state,
            pending: false,
            list: state.list.filter((o) => o.id !== id)
        };
    },
    [TOGGLE_DROPDOWN_MENU]: (state, action) => {
        const id = action.payload;

        return {
            ...state,
            list: state.list.map((o) => {
                return {
                    ...o,
                    isOpenMenu: o.Id === id ? !o.isOpenMenu : o.isOpenMenu,
                };
            })
        };
    },
    [UPDATE_MEETING_STATUS]: (state, action) => {
        const { id, status } = action.payload;

        return {
            ...state,
            list: state.list.map((o) => {
                return {
                    ...o,
                    Status: id === o.Id ? status : o.Status,
                };
            })
        };
    },
    [UPDATE_MEETINGS_SEARCH]: (state, action) => {
        return {
            ...state,
            search: {
                ...state.search,
                ...action.payload,
            }
        };
    },
    [UPDATE_MEETINGS_STATE]: (state, action) => {
        return {
            ...state,
            ...action.payload,
        };
    },
}, initialState);

export const initSearchState = createAction(INIT_SEARCH_STATE);
export const toggleDropdownMenu = createAction(TOGGLE_DROPDOWN_MENU);
export const updateMeetingStatus = createAction(UPDATE_MEETING_STATUS);
export const updateMeetingsSearch = createAction(UPDATE_MEETINGS_SEARCH);
export const updateMeetingsState = createAction(UPDATE_MEETINGS_STATE);

export const getMeetings = () => (dispatch, getState) => {
    const { stage, project, meetings } = getState();

    dispatch({ type: MEETINGS_PENDING });

    const params = {
        limit: meetings.search.limit,
        offset: meetings.search.offset,
    };

    const { keywordType } = meetings;
    const {
        title,
        id,
        status,
        startDate,
        endDate,
    } = meetings.search;

    if (keywordType === "title" && title !== "") params.title = title;
    if (keywordType === "id" && id !== "") params.id = id;
    if (status !== "") params.status = status;
    if (startDate) params.startDate = moment(startDate).format('YYYY-MM-DD');
    if (endDate) params.endDate = moment(endDate).format('YYYY-MM-DD');

    return getMeetingsAPI(stage.id, stage.endpoint, project.id, params).then(
        (response) => {
            dispatch({
                type: GET_MEETINGS_SUCCESS,
                payload: response,
            });
        }
    ).catch((error) => {
        dispatch({
            type: MEETINGS_FAILURE,
            payload: error,
        });
    });
};

export const deleteMeeting = (id) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: MEETINGS_PENDING });

    return deleteMeetingAPI(stage.id, stage.endpoint, project.id, id).then(
        (res) => {
            dispatch({
                type: DELETE_MEETINGS_SUCCESS,
                payload: { id },
            });
        }
    ).catch((e) => {
        dispatch({
            type: MEETINGS_FAILURE,
            payload: e,
        });
    });
};
