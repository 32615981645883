import React, { useMemo, useRef, useState } from "react";
import {
    ClickAwayListener,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    PDCAlpha as alpha,
    Popper,
    Stack,
    Tooltip,
    Typography,
} from "@mzc-pdc/ui";
import { useTranslation } from "react-i18next";
import { TabPanel, Tabs } from "@components_v2/tabs/tabs";
import {
    IconAllView,
    IconArchive,
    IconAssetsAdd,
    IconCancel,
    IconClose,
    IconDelete,
    IconDownload,
    IconDragdropUpload,
    IconFileCopyto,
    IconJobs,
    IconMove,
    IconRename,
    IconTranscode,
} from "@mzc-cloudplex/icons";
import theme from "@styles/theme";
import compareVersions, {
    VERSION_CM_4750_UPLOAD_FOLDERS,
    VERSION_CM_7307_DOWNLOAD,
    VERSION_CM_7349_CREATE_ASSETS_BULK,
    VERSION_MY_JOBS_PANEL,
} from "@cores/version";
import { COMMON_JOB_STATUS_OPTIONS, CONSTANTS, JOB_ACTIONS, JOB_PANEL_TABS } from "@constants";
import { inActive } from "@modules/rightSideBar";
import { useDispatch, useSelector } from "react-redux";
import { useToggle } from "@mzc-pdc/hooks";
import FileJobPanel from "@routes/rightsidebar/ui/FileJobList";
import UploadFilesPanel from "@routes/rightsidebar/ui/UploadFilesPanel";
import DownloadJobPanel from "@routes/rightsidebar/ui/DownloadJobList";
import CreateAssetsPanel from "@routes/rightsidebar/ui/CreateAssetsJobList";
import TranscodeJobList from "@routes/rightsidebar/ui/transcode-job-list";
import JobTaskCreateAssets from "@routes/rightsidebar/ui/tasks/job-task-create-assets";
import JobTaskCopyAssets from "@routes/rightsidebar/ui/tasks/job-task-copy-assets";
import JobTaskDownload from "@routes/rightsidebar/ui/tasks/job-task-download";
import JobTaskTranscode from "@routes/rightsidebar/ui/tasks/job-task-transcode";
import JobTaskStorageJobs from "@routes/rightsidebar/ui/tasks/job-task-storage-jobs";
import JobTaskUpload from "@routes/rightsidebar/ui/tasks/job-task-upload";
import JobTaskCreateAsset from "@routes/rightsidebar/ui/tasks/job-task-create-asset";
import useMyJobs from "@hooks/useMyJobs";

export const MyJobListV2 = ({ location }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { active: tabListPopperActive, toggle: tabListPopperToggle } = useToggle();
    const { changeMyJobsActiveTab } = useMyJobs({});

    const [anchorEl, setAnchorEl] = useState(null);
    const anchorElRef = useRef(null);

    const stage = useSelector(({ stage }) => stage);
    const uploadQueue = useSelector(({ uploadQueue }) => uploadQueue);
    const activeTab = useSelector(({ rightSideBar }) => rightSideBar.activeTab);

    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };
    const onHandleChangeTab = (tab) => {
        changeMyJobsActiveTab(tab);
    };

    const panelList = useMemo(() => {
        return [
            {
                label: t(`common::label::${JOB_PANEL_TABS.ASSET_JOBS}`, `${JOB_PANEL_TABS.ASSET_JOBS}`),
                tabValue: JOB_PANEL_TABS.ASSET_JOBS,
                visible: compareVersions(stage.version, VERSION_CM_7349_CREATE_ASSETS_BULK) >= 0,
                component: CreateAssetsPanel,
                props: {
                    filter: {
                        action: [
                            {
                                label: t(`common::label::Asset Bulk Create`, `Asset Bulk Create`),
                                value: JOB_ACTIONS.CREATE_ASSETS,
                            },
                            {
                                label: t(`common::label::Asset Duplicate`, `Asset Duplicate`),
                                value: JOB_ACTIONS.COPY_ASSETS,
                            },
                            {
                                label: t(`common::label::Asset Create`, `Asset Create`),
                                value: JOB_ACTIONS.UPLOAD_ASSET,
                            },
                            {
                                label: t(`common::label::Asset Archive`, `Asset Archive`),
                                value: JOB_ACTIONS.ARCHIVE_ASSETS,
                            },
                        ],
                        status: COMMON_JOB_STATUS_OPTIONS.map((status) => ({
                            label: status.label,
                            value: status.value,
                        })),
                    },
                    jobIcon: {
                        [JOB_ACTIONS.CREATE_ASSETS]: <IconAssetsAdd size={16} />,
                        [JOB_ACTIONS.COPY_ASSETS]: <IconFileCopyto size={16} />,
                        [JOB_ACTIONS.UPLOAD_ASSET]: <IconAssetsAdd size={16} />,
                        [JOB_ACTIONS.ARCHIVE_ASSETS]: <IconArchive size={16} />,
                    },
                    summaries: [
                        { id: "text", label: "Job", key: "action" },
                        { id: "text", label: "Job ID", key: "jobId" },
                        {
                            id: "date",
                            label: "Start date",
                            key: "startAt",
                        },
                        { id: "date", label: "End date", key: "endAt" },
                    ],
                    taskComponent: {
                        [JOB_ACTIONS.CREATE_ASSETS]: JobTaskCreateAssets,
                        [JOB_ACTIONS.COPY_ASSETS]: JobTaskCopyAssets,
                        [JOB_ACTIONS.UPLOAD_ASSET]: JobTaskCreateAsset,
                        [JOB_ACTIONS.ARCHIVE_ASSETS]: JobTaskCreateAssets,
                    },
                },
            },
            {
                label: t(`common::label::${JOB_PANEL_TABS.DOWNLOAD}`, `${JOB_PANEL_TABS.DOWNLOAD}`),
                tabValue: JOB_PANEL_TABS.DOWNLOAD,
                visible: compareVersions(stage.version, VERSION_CM_7307_DOWNLOAD) >= 0,
                component: DownloadJobPanel,
                props: {
                    filter: {
                        action: [
                            {
                                label: t(`common::label::Asset Download`, `Asset Download`),
                                value: JOB_ACTIONS.ASSET_DOWNLOAD,
                            },
                            {
                                label: t(`common::label::Storage Download`, `Storage Download`),
                                value: JOB_ACTIONS.STORAGE_DOWNLOAD,
                            },
                            {
                                label: t(`common::label::Metadata Download`, `Metadata Download`),
                                value: JOB_ACTIONS.METADATA_DOWNLOAD,
                            },
                        ],
                        status: COMMON_JOB_STATUS_OPTIONS.map((status) => ({
                            label: status.label,
                            value: status.value,
                        })),
                    },
                    jobIcon: {
                        [JOB_ACTIONS.ASSET_DOWNLOAD]: <IconDownload size={16} />,
                        [JOB_ACTIONS.STORAGE_DOWNLOAD]: <IconDownload size={16} />,
                        [JOB_ACTIONS.METADATA_DOWNLOAD]: <IconDownload size={16} />,
                    },
                    summaries: [
                        { id: "text", label: "Job", key: "action" },
                        { id: "text", label: "Job ID", key: "jobId" },
                        {
                            id: "date",
                            label: "Start date",
                            key: "startAt",
                        },
                        { id: "date", label: "End date", key: "endAt" },
                    ],
                    taskComponent: {
                        [JOB_ACTIONS.ASSET_DOWNLOAD]: JobTaskDownload,
                        [JOB_ACTIONS.STORAGE_DOWNLOAD]: JobTaskDownload,
                        [JOB_ACTIONS.METADATA_DOWNLOAD]: JobTaskDownload,
                    },
                },
            },
            {
                label: t(`common::label::${JOB_PANEL_TABS.TRANSCODE}`, `${JOB_PANEL_TABS.TRANSCODE}`),
                tabValue: JOB_PANEL_TABS.TRANSCODE,
                visible: true,
                component: TranscodeJobList,
                props: {
                    filter: {
                        status: CONSTANTS("JOB_STATUS").map((status) => ({
                            label: status.label,
                            value: status.value,
                        })),
                    },
                    jobIcon: {
                        [JOB_ACTIONS.TRANSCODE]: <IconTranscode size={16} />,
                    },
                    summaries: [
                        { id: "service", label: "Provider/Service", key: "transcoderJob" },
                        { id: "text", label: "Job", key: "action" },
                        { id: "link", label: "Job ID", key: "id" },
                        {
                            id: "date",
                            label: "Start date",
                            key: "createdAt",
                        },
                        { id: "date", label: "End date", key: "completedAt" },
                    ],
                    taskComponent: {
                        [JOB_ACTIONS.TRANSCODE]: JobTaskTranscode,
                    },
                },
            },
            {
                label: t(`common::label::${JOB_PANEL_TABS.SOURCE_UPLOAD}`, `${JOB_PANEL_TABS.SOURCE_UPLOAD}`),
                tabValue: JOB_PANEL_TABS.SOURCE_UPLOAD,
                visible: compareVersions(stage.version, VERSION_CM_4750_UPLOAD_FOLDERS) >= 0,
                component: UploadFilesPanel,
                props: {
                    filter: {
                        status: CONSTANTS("UPLOAD_STORAGE_JOB_STATUS").map((status) => ({
                            label: status.label,
                            value: status.value,
                        })),
                    },
                    jobIcon: {
                        ["UPLOAD"]: <IconDragdropUpload size={16} />,
                    },
                    summaries: [
                        { id: "text", label: "Job", key: "action" },
                        {
                            id: "date",
                            label: "Start date",
                            key: "created",
                        },
                        { id: "date", label: "End date", key: "uploaded" },
                    ],
                    taskComponent: {
                        [JOB_ACTIONS.UPLOAD]: JobTaskUpload,
                    },
                },
            },
            {
                label: t(`common::label::${JOB_PANEL_TABS.SOURCE_JOBS}`, `${JOB_PANEL_TABS.SOURCE_JOBS}`),
                tabValue: JOB_PANEL_TABS.SOURCE_JOBS,
                visible: compareVersions(stage.version, VERSION_MY_JOBS_PANEL) >= 0,
                component: FileJobPanel,
                props: {
                    filter: {
                        action: [
                            {
                                label: t(`common::label::Move`, `Move`),
                                value: JOB_ACTIONS.MOVE,
                            },
                            {
                                label: t(`common::label::Copy to`, `Copy to`),
                                value: JOB_ACTIONS.COPY,
                            },
                            {
                                label: t(`common::label::Delete`, `Delete`),
                                value: JOB_ACTIONS.DELETE,
                            },
                            {
                                label: t(`common::label::Rename`, `Rename`),
                                value: JOB_ACTIONS.RENAME,
                            },
                        ],
                        status: COMMON_JOB_STATUS_OPTIONS.map((status) => ({
                            label: status.label,
                            value: status.value,
                        })),
                    },
                    jobIcon: {
                        [JOB_ACTIONS.MOVE]: <IconMove size={16} />,
                        [JOB_ACTIONS.COPY]: <IconFileCopyto size={16} />,
                        [JOB_ACTIONS.DELETE]: <IconDelete size={16} />,
                        [JOB_ACTIONS.RENAME]: <IconRename size={16} />,
                    },
                    summaries: [
                        { id: "text", label: "Job", key: "action" },
                        { id: "text", label: "Job ID", key: "jobId" },
                        {
                            id: "date",
                            label: "Start date",
                            key: "startAt",
                        },
                        { id: "date", label: "End date", key: "endAt" },
                    ],
                    taskComponent: {
                        [JOB_ACTIONS.MOVE]: JobTaskStorageJobs,
                        [JOB_ACTIONS.COPY]: JobTaskStorageJobs,
                        [JOB_ACTIONS.DELETE]: JobTaskStorageJobs,
                        [JOB_ACTIONS.RENAME]: JobTaskStorageJobs,
                    },
                },
            },
        ];
    }, [stage]);

    return (
        <Stack height={1}>
            <Grid
                container
                minHeight={theme.mixins.toolbar.minHeight}
                alignItems={"center"}
                justifyContent={"space-between"}
                px={1.5}
                gap={1.5}
            >
                <Grid item>
                    <Typography variant="subtitle1_700">{t("My Jobs", "My Jobs")}</Typography>
                </Grid>
                <Grid item>
                    <Tooltip title={t(`common::label::Close my jobs`, `Close my jobs`)}>
                        <IconButton circled size={"medium"} onClick={() => dispatch(inActive())}>
                            <IconCancel size={20} />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <Divider />
            <Grid
                container
                flexDirection={"row"}
                alignItems={"center"}
                flexWrap={"nowrap"}
                sx={{ borderBottom: `1px solid ${theme.palette.greySecondary.A100}` }}
            >
                <Grid item xs zeroMinWidth>
                    <Tabs
                        value={activeTab}
                        items={panelList.map(({ label, tabValue }) => ({ label, value: tabValue }))}
                        onChangeTab={onHandleChangeTab}
                        indicatorPosition={"bottom"}
                        isBordered={false}
                        textColor={"secondary"}
                        variant={"scrollable"}
                        scrollButtons={"auto"}
                        scrollButtonPosition={"right"}
                        divider={
                            <Divider
                                orientation={"vertical"}
                                color={theme.palette.greySecondary.A100}
                                sx={{ height: "20px", mx: 1 }}
                            />
                        }
                        sx={{
                            ".MuiTabScrollButton-root.MuiTabScrollButton-horizontal": {
                                borderTop: 0,
                                borderBottom: 0,
                            },
                        }}
                    />
                </Grid>
                <Grid
                    item
                    ref={anchorElRef}
                    width={40}
                    height={1}
                    textAlign={"center"}
                    bgcolor={open ? "greySecondary.main" : "inherit"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Tooltip title={t(`common::label::Open tab menu`, `Open tab menu`)}>
                        <IconButton circled size={"small"} onClick={tabListPopperToggle}>
                            <IconAllView size={16} />
                        </IconButton>
                    </Tooltip>
                    <Popper
                        open={tabListPopperActive}
                        anchorEl={anchorElRef.current}
                        onClose={handleClose}
                        placement={"bottom-end"}
                        sx={{
                            marginTop: `-40px !important `,
                            zIndex: theme.zIndex.tooltip,
                            width: 286,
                            bgcolor: "background.paper",
                            boxShadow: `0px 2px 4px 0px rgba(0, 0, 0, 0.12)`,
                        }}
                    >
                        <ClickAwayListener
                            mouseEvent={tabListPopperActive ? "onClick" : false}
                            onClickAway={() => {
                                tabListPopperToggle();
                            }}
                        >
                            <Stack>
                                <Grid container pl={2} alignItems={"center"} justifyContent={"space-between"}>
                                    <Grid item>
                                        <Grid container height={40} gap={0.75} alignItems={"center"}>
                                            <IconJobs size={16} />
                                            <Typography variant={"body3_500"}>{t("Jobs", "Jobs")}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        width={40}
                                        height={40}
                                        textAlign={"center"}
                                        bgcolor={tabListPopperActive ? "greySecondary.main" : "inherit"}
                                        display={"flex"}
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                    >
                                        <Tooltip title={t(`common::label::Close tab menu`, `Close tab menu`)}>
                                            <IconButton
                                                circled
                                                size={"small"}
                                                variant={"contained"}
                                                color={"greySecondary"}
                                                onClick={tabListPopperToggle}
                                            >
                                                <IconClose size={16} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <List sx={{ maxHeight: 260, overflowY: "auto" }}>
                                    {panelList.map((item, index) => (
                                        <ListItem
                                            sx={{
                                                padding: `0`,
                                                height: "48px",
                                                "&:hover": {
                                                    backgroundColor: alpha(theme.palette.greySecondary[50], 0.24),
                                                    textDecoration: "underline",
                                                },
                                            }}
                                            data-id={item.tabValue}
                                            onClick={() => {
                                                onHandleChangeTab(item.tabValue);
                                            }}
                                        >
                                            <ListItemButton
                                                selected={activeTab === item.tabValue}
                                                sx={{
                                                    height: "100%",
                                                    "&.Mui-selected": {
                                                        backgroundColor: alpha(theme.palette.greySecondary.A100, 0.4),
                                                    },
                                                }}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Grid item>
                                                            <Typography
                                                                variant={`body1_${
                                                                    activeTab === item.value ? "500" : "400"
                                                                }`}
                                                            >
                                                                {item.label}
                                                            </Typography>
                                                        </Grid>
                                                    }
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            </Stack>
                        </ClickAwayListener>
                    </Popper>
                </Grid>
            </Grid>
            {panelList?.map((panel, index) => (
                <>
                    {panel.visible && (
                        <TabPanel
                            value={activeTab}
                            index={panel.tabValue}
                            sx={{ height: "100%", display: "flex", flexDirection: "column", overflow: `hidden` }}
                        >
                            <panel.component {...panel.props} />
                        </TabPanel>
                    )}
                </>
            ))}
        </Stack>
    );
};
