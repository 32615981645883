import { createAction, handleActions } from "redux-actions";
import { getCustomContentsAPI } from "@modules/apis/custom-contents";
import { LIST_VIEW_TYPE } from "@constants";

const initialState = {
    data: null,
    pending: false,
    error: false,
    isCardView: true,
    activeView: LIST_VIEW_TYPE.LIST,
    create: {
        data: null,
        nextToken: null,
        pending: false,
        error: false,
    },
};

const GET_CUSTOM_CONTENTS_PENDING = "GET_CUSTOM_CONTENTS_PENDING";
const GET_CUSTOM_CONTENTS_SUCCESS = "GET_CUSTOM_CONTENTS_SUCCESS";
const GET_CUSTOM_CONTENTS_FAILURE = "GET_CUSTOM_CONTENTS_FAILURE";
const UPDATE_CUSTOM_CONTENTS_ACTIVE_VIEW = "UPDATE_CUSTOM_CONTENTS_ACTIVE_VIEW";
const UPDATE_CUSTOM_CONTENTS_CARD_VIEW = "UPDATE_CUSTOM_CONTENTS_CARD_VIEW";
const RESET_CUSTOM_CONTENTS = "RESET_CUSTOM_CONTENTS";
const UPDATE_CUSTOM_CONTENTS_DATA = "UPDATE_CUSTOM_CONTENTS_DATA";

export default handleActions(
    {
        [GET_CUSTOM_CONTENTS_PENDING]: (state) => {
            return { ...state, data: null, pending: true, error: false };
        },
        [GET_CUSTOM_CONTENTS_SUCCESS]: (state, action) => {
            return { ...state, data: action.payload, pending: false };
        },
        [GET_CUSTOM_CONTENTS_FAILURE]: (state, action) => {
            return { ...state, data: null, pending: false, error: action.payload };
        },
        [UPDATE_CUSTOM_CONTENTS_ACTIVE_VIEW]: (state, action) => {
            return { ...state, activeView: action.payload };
        },
        [UPDATE_CUSTOM_CONTENTS_CARD_VIEW]: (state, action) => {
            return { ...state, isCardView: action.payload };
        },
        [RESET_CUSTOM_CONTENTS]: (state) => {
            return { ...state, data: null, pending: false, error: false };
        },
        [UPDATE_CUSTOM_CONTENTS_DATA]: (state, action) => {
            return {
                ...state,
                data: state.data.map((content) => {
                    if (content.id !== action.payload.id) return content;
                    return {
                        ...content,
                        [action.payload.type]: action.payload.value,
                    };
                }),
            };
        },
    },
    initialState,
);

export const updateCustomContentsActiveView = createAction(UPDATE_CUSTOM_CONTENTS_ACTIVE_VIEW);
export const updateCustomContentsCardView = createAction(UPDATE_CUSTOM_CONTENTS_CARD_VIEW);
export const updateCustomContentsData = createAction(UPDATE_CUSTOM_CONTENTS_DATA);

export const getCustomContents = (params) => (dispatch, getState) => {
    const { stage, project } = getState();

    return new Promise(async (resolve, reject) => {
        try {
            dispatch({
                type: GET_CUSTOM_CONTENTS_PENDING,
            });
            const { data } = await getCustomContentsAPI(stage.id, stage.endpoint, project.id, params);

            dispatch({
                type: GET_CUSTOM_CONTENTS_SUCCESS,
                payload: data?.results,
            });

            resolve(data.nextToken);
        } catch (error) {
            dispatch({ type: GET_CUSTOM_CONTENTS_FAILURE, payload: error });
            reject(error);
        }
    });
};
