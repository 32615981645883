// import React, { useReducer } from 'react'
// import { useSelector, useDispatch } from 'react-redux';
import queryString from "../cores/queryString";

import {
    axios,
    axiosWithoutHeaders
} from "../cores/axiosWrapper";

import { 
    getSchedulesAPI
} from "./schedules";

import {handleActions, createAction} from 'redux-actions';

const INTERACTIVE_LIVE_CHANNEL_LIST_RESET = "INTERACTIVE_LIVE_CHANNEL_LIST_RESET";
const INTERACTIVE_LIVE_CHANNEL_LIST_EXPAND = "INTERACTIVE_LIVE_CHANNEL_LIST_EXPAND";

const INTERACTIVE_LIVE_CHANNELS_PENDING = "INTERACTIVE_LIVE_CHANNELS_PENDING";

const INTERACTIVE_LIVE_CHANNEL_LIST_REFRESH_FLAG_RESET = 'INTERACTIVE_LIVE_CHANNEL_LIST_REFRESH_FLAG_RESET';

const INTERACTIVE_LIVE_CHANNEL_DELETE_SUCCESS = "INTERACTIVE_LIVE_CHANNEL_DELETE_SUCCESS";
const INTERACTIVE_LIVE_CHANNEL_DELETE_FAILURE = "INTERACTIVE_LIVE_CHANNEL_DELETE_FAILURE";

const INTERACTIVE_LIVE_CHANNEL_GET_LIST_SUCCESS = "INTERACTIVE_LIVE_CHANNEL_GET_LIST_SUCCESS";
const INTERACTIVE_LIVE_CHANNEL_GET_LIST_FAILURE = "INTERACTIVE_LIVE_CHANNEL_GET_LIST_FAILURE";

const INTERACTIVE_LIVE_CHANNEL_SCHEDULES_GET_LIST_PENDING = "INTERACTIVE_LIVE_CHANNEL_SCHEDULES_GET_LIST_PENDING";

const INTERACTIVE_LIVE_CHANNEL_SCHEDULES_GET_LIST_SUCCESS = "INTERACTIVE_LIVE_CHANNEL_SCHEDULES_GET_LIST_SUCCESS";
const INTERACTIVE_LIVE_CHANNEL_SCHEDULES_GET_LIST_FAILURE = "INTERACTIVE_LIVE_CHANNEL_SCHEDULES_GET_LIST_FAILURE";

const INTERACTIVE_LIVE_CHANNEL_SCHEDULES_DELETE_SUCCESS = "INTERACTIVE_LIVE_CHANNEL_SCHEDULES_DELETE_SUCCESS";
const INTERACTIVE_LIVE_CHANNEL_SCHEDULES_DELETE_FAILURE = "INTERACTIVE_LIVE_CHANNEL_SCHEDULES_DELETE_FAILURE";

//reducer
const initialState = {
    pending: false,
    error: false,
    isCreateSuccess: false,
    refresh: false,
    channels: [],
    search:{
        limit:20,
        offset:0
    },
    totalCount:0
};

export default handleActions({
    [INTERACTIVE_LIVE_CHANNEL_LIST_RESET]: (state, action) => {
        console.log("INTERACTIVE_LIVE_CHANNEL_LIST_RESET");
        return {
            ...initialState
        }
    },

    [INTERACTIVE_LIVE_CHANNEL_LIST_EXPAND]: (state, action) => {
        return {
            ...state,
            channels: state.channels.map(c => {
                return {
                    ...c,
                    expand : c.id === action.payload ? !c.expand : false
                }
            })
        }
    },

    [INTERACTIVE_LIVE_CHANNELS_PENDING]: (state, action) => {
        return {
            ...state,
            pending : true,
            error : false
        }
    },

    [INTERACTIVE_LIVE_CHANNEL_DELETE_SUCCESS]: (state, action) => {
        console.log("INTERACTIVE_LIVE_CHANNEL_DELETE_SUCCESS action: ", action);
        const data = action.payload;

        state.channels = state.channels.filter(c => c.id !== data.channelId);

        return {
            ...state,
            pending : false,
            error : false
        }
    },

    [INTERACTIVE_LIVE_CHANNEL_DELETE_FAILURE]: (state, action) => {
        console.log("INTERACTIVE_LIVE_CHANNEL_DELETE_FAILURE action: ", action);
        const data = action.payload;

        state.channels = state.channels.filter(c => c.id !== data.channelId);

        return {
            ...state,
            pending : false,
            error : true
        }
    },

    [INTERACTIVE_LIVE_CHANNEL_GET_LIST_SUCCESS]: (state, action) => {
        const data = action.payload;
        console.log("-- data : ", data);
        return {
            ...state,
            channels : data.channels,
            totalCount: data.totalCount,
            pending : false
        }
    },

    [INTERACTIVE_LIVE_CHANNEL_GET_LIST_FAILURE]: (state, action) => {
        return {
            ...state,
            pending : false,
            error : true
        }
    },

    [INTERACTIVE_LIVE_CHANNEL_LIST_REFRESH_FLAG_RESET]: (state, action) => {
        console.log("INTERACTIVE_LIVE_CHANNEL_LIST_REFRESH_FLAG_RESET action.payload : ", action.payload);
        
        return {
            ...state,
            refresh:false
        }
    },

    [INTERACTIVE_LIVE_CHANNEL_SCHEDULES_GET_LIST_PENDING]: (state, action) => {
        console.log("INTERACTIVE_LIVE_CHANNEL_SCHEDULES_GET_LIST_PENDING action.payload : ", action.payload);

        const data = action.payload;

        state.channels.map((c) => {
            if (c.id === data.id)
                c.pending = true;
        });
        
        return {
            ...state,
        }
    },

    [INTERACTIVE_LIVE_CHANNEL_SCHEDULES_GET_LIST_SUCCESS]: (state, action) => {
        console.log("INTERACTIVE_LIVE_CHANNEL_SCHEDULES_GET_LIST_SUCCESS action.payload : ", action.payload);

        const data = action.payload;

        state.channels.map((c) => {
            if (c.id === data.channelId) {
                c.pending = false;
                c.schedules = data.schedules;
            }
        });
        
        return {
            ...state,
            pending: false,
            error: false
        }
    },

    [INTERACTIVE_LIVE_CHANNEL_SCHEDULES_GET_LIST_FAILURE]: (state, action) => {
        console.log("INTERACTIVE_LIVE_CHANNEL_SCHEDULES_GET_LIST_FAILURE action.payload : ", action.payload);
        
        return {
            ...state,
            refresh:false
        }
    },
}, initialState);

export const refreshFlagReset = createAction(INTERACTIVE_LIVE_CHANNEL_LIST_REFRESH_FLAG_RESET);

function deleteInteractiveLiveChannelAPI(stageId, apiEndpoint, projectId, id) {    
    console.log("deleteInteractiveLiveChannelAPI start");

    return axios.delete(`${apiEndpoint}/interactive-live/${id}`, {
        headers: {
            stageId,
            projectId
        }
    });
}

export const reset = createAction(INTERACTIVE_LIVE_CHANNEL_LIST_RESET);
export const expand = createAction(INTERACTIVE_LIVE_CHANNEL_LIST_EXPAND);

function getInteractiveLiveChannelsAPI(stageId, apiEndpoint, projectId, queries) {    
    console.log("getInteractiveLiveChannelsAPI start queries:", queries);
    // const queries = {
    //     offset : querys.offset,
    //     limit: querys.limit
    // };

    // queries.name = query.name === undefined ? '' : query.name;
    // queries.id = query.id === undefined ? '' : query.id;
    // queries.description = query.description === undefined ? '' : query.description;

    // queries.creator = query.creator;
    // queries.status = query.status;
    
    return axios.get(`${apiEndpoint}/interactive-live?${queryString.stringify(queries)}`, {
        headers: {
            stageId,
            projectId
        }
    });
};

export const getInteractiveLiveChannels = (queries) => (dispatch, getState) => {
    console.log("in getInteractiveLiveChannels ");
    const {
        stage,
        project
    } = getState();

    dispatch({type: INTERACTIVE_LIVE_CHANNELS_PENDING});

    return getInteractiveLiveChannelsAPI(stage.id, stage.endpoint, project.id, queries)
        .then((response) => {
            dispatch({
                type: INTERACTIVE_LIVE_CHANNEL_GET_LIST_SUCCESS,
                payload: response.data
            });
        })
        .catch(error => {
            dispatch({
                type: INTERACTIVE_LIVE_CHANNEL_GET_LIST_FAILURE,
                payload: error
            });
        });
};

export const deleteInteractiveLiveChannel = (id) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    console.log("in deleteInteractiveLiveChannel id: ",id );

    dispatch({type: INTERACTIVE_LIVE_CHANNELS_PENDING});

    return deleteInteractiveLiveChannelAPI(stage.id, stage.endpoint, project.id, id)
        .then((response) => {
            dispatch({
                type: INTERACTIVE_LIVE_CHANNEL_DELETE_SUCCESS,
                payload: {
                    channelId: id
                }
            });
        })
        .catch(error => {
            dispatch({
                type: INTERACTIVE_LIVE_CHANNEL_DELETE_FAILURE,
                payload: error
            });
        });
}

// export const getInteractiveLiveChannelSchedulesbyChannelId = (channelId, search) => (dispatch, getState) => {
//     const {
//         stage,
//         project
//     } = getState();

//     console.log("in getInteractiveLiveChannelSchedules search: ",search );

//     // const searchParam = {
//     //     relatedId: id
//     // }

//     dispatch({
//         type: INTERACTIVE_LIVE_CHANNEL_SCHEDULES_GET_LIST_PENDING,
//         payload: {
//             channelId
//         }
//     });

//     return getSchedulesAPI(stage.id, stage.endpoint, project.id, {relatedId: channelId, ...search})
//         .then((response) => {
//             dispatch({
//                 type: INTERACTIVE_LIVE_CHANNEL_SCHEDULES_GET_LIST_SUCCESS,
//                 payload: {
//                     channelId: id,
//                     schedules: response.data.schedules
//                 }
//             });
//         })
//         .catch(error => {
//             dispatch({
//                 type: INTERACTIVE_LIVE_CHANNEL_SCHEDULES_GET_LIST_FAILURE,
//                 payload: error
//             });
//         });
// }