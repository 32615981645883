import { handleActions, createAction } from "redux-actions";
import fp from "lodash/fp";

import { UNCATEGORIZED_ID } from "../constants";

import { getCategoriesAPI } from "./apis/category";

import { getCategoryGroupAPI } from "./apis/categoryGroup";

import { getSettingsAPI } from "./apis/setting";

//action type
const CATEGORY_MODAL_TREE_VIEW_PENDING = "CATEGORY_MODAL_TREE_VIEW_PENDING";
const CATEGORY_MODAL_TREE_VIEW_FAILURE = "CATEGORY_MODAL_TREE_VIEW_FAILURE";
const GET_CATEGORY_MODAL_TREE_VIEW_SUCCESS = "GET_CATEGORY_MODAL_TREE_VIEW_SUCCESS";
const CATEGORY_MODAL_TREE_UPDATE_FILTER = "CATEGORY_MODAL_TREE_UPDATE_FILTER";
const CATEGORY_MODAL_TREE_CHANGE_FOLD = "CATEGORY_MODAL_TREE_CHANGE_FOLD";
const CATEGORY_MODAL_TREE_CHANGE_SELECTED_ITEM = "CATEGORY_MODAL_TREE_CHANGE_SELECTED_ITEM";
const CATEGORY_MODAL_TREE_CHANGE_SELECTED_BY_LIST = "CATEGORY_MODAL_TREE_CHANGE_SELECTED_BY_LIST";
const CATEGORY_MODAL_TREE_CLEAR_SELECTED_BY_LIST = "CATEGORY_MODAL_TREE_CLEAR_SELECTED_BY_LIST";
const CATEGORY_MODAL_TREE_CLEAR = "CATEGORY_MODAL_TREE_CLEAR";

//reducer
const initialState = {
    pending: false,
    error: false,
    data: null,
    filter: {
        isFilter: false,
        data: [],
    },
    categoryMap: [
        {
            id: UNCATEGORIZED_ID,
            name: "Uncategorized Assets",
            isActive: false,
            isSelected: false,
            isEmpty: false,
            isGroup: true,
            isNotCategoryIds: true,
            type: "uncategorized",
            isRoot: true,
        },
    ],
    selectedByListTargetId: null,
};

export default handleActions(
    {
        [CATEGORY_MODAL_TREE_VIEW_PENDING]: (state) => {
            return {
                ...state,
                pending: true,
                error: false,
            };
        },
        [CATEGORY_MODAL_TREE_VIEW_FAILURE]: (state) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [GET_CATEGORY_MODAL_TREE_VIEW_SUCCESS]: (state, action) => {
            let { category } = action.payload;

            return {
                ...state,
                ...category,
                pending: false,
                error: false,
            };
        },
        [CATEGORY_MODAL_TREE_CLEAR_SELECTED_BY_LIST]: (state) => {
            return {
                ...state,
                selectedByListTargetId: null,
            };
        },
        [CATEGORY_MODAL_TREE_UPDATE_FILTER]: (state, action) => {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.payload,
                },
            };
        },
        [CATEGORY_MODAL_TREE_CHANGE_FOLD]: (state, action) => {
            const temp = {
                ...state,
                data: [].concat(state.data),
            };
            return temp;
        },
        [CATEGORY_MODAL_TREE_CHANGE_SELECTED_ITEM]: (state, action) => {
            const item = fp.get(`categoryMap[${action.payload.id}]`, state);
            if (fp.isNil(item)) return { ...state, selected: { id: action.payload.id } };

            setParentItemFold(item.parent, false);

            Object.keys(state.categoryMap).map((v) => {
                const category = state.categoryMap[v];
                if (category.isGroup) {
                    const groupId = item.isGroup ? item.id : item.groupId;
                    category.isActive = category.id === groupId ? true : false;
                }
            });

            if (item.type === "categoryGroup" && item.children && item.children.length > 0) {
                item = item.children[0];
            }

            return {
                ...state,
                selected: item,
            };
        },
        [CATEGORY_MODAL_TREE_CHANGE_SELECTED_BY_LIST]: (state, action) => {
            return {
                ...state,
                selectedByListTargetId: action.payload,
            };
        },
        [CATEGORY_MODAL_TREE_CLEAR]: (state, action) => {
            return {
                ...initialState,
            };
        },
    },
    initialState,
);

const setParentItemFold = (item, isFold) => {
    if (!item) return;

    item.isFold = isFold;

    if (item.parent) setParentItemFold(item.parent, isFold);
};

export const updateFilter = createAction(CATEGORY_MODAL_TREE_UPDATE_FILTER);
export const changeFold = createAction(CATEGORY_MODAL_TREE_CHANGE_FOLD);
export const changeSelectedItem = createAction(CATEGORY_MODAL_TREE_CHANGE_SELECTED_ITEM);
export const changeSelectedByList = createAction(CATEGORY_MODAL_TREE_CHANGE_SELECTED_BY_LIST);
export const clearSelectedByList = createAction(CATEGORY_MODAL_TREE_CLEAR_SELECTED_BY_LIST);
export const clear = createAction(CATEGORY_MODAL_TREE_CLEAR);

export const initCategoryData =
    (projectId, resourceType, categoryGroupId = null) =>
    (dispatch, getState) => {
        const { stage, categoryModalTreeView } = getState();

        dispatch({ type: CATEGORY_MODAL_TREE_VIEW_PENDING });

        new Promise(async (resolve, reject) => {
            let newCategoryGroupId = "";
            if (categoryGroupId) {
                newCategoryGroupId = categoryGroupId;
            } else {
                const settingParams = {
                    resource: resourceType,
                    key: "categoryGroupId",
                };
                await getSettingsAPI(stage.id, stage.endpoint, projectId, settingParams)
                    .then((response) => {
                        newCategoryGroupId = response.data[resourceType].categoryGroupId.value;
                    })
                    .catch((error) => {
                        dispatch({
                            type: CATEGORY_MODAL_TREE_VIEW_FAILURE,
                            error,
                        });
                    });
            }
            await getCategoryGroupAPI(stage.id, stage.endpoint, projectId, newCategoryGroupId)
                .then((response) => {
                    const categoryGroupResponse = response.data;
                    getCategoriesAPI(stage.id, stage.endpoint, projectId, newCategoryGroupId)
                        .then((response) => {
                            const categories = response.data;

                            let category = {
                                isFilter: false,
                                data: [],
                                selected: {},
                            };

                            const categoryGroup = initCategoryGroup(
                                categoryGroupResponse,
                                categories,
                                fp.getOr(UNCATEGORIZED_ID, "selected.id", categoryModalTreeView),
                            );

                            category = {
                                ...category,
                                ...categoryGroup,
                            };

                            dispatch({
                                type: GET_CATEGORY_MODAL_TREE_VIEW_SUCCESS,
                                payload: {
                                    category,
                                },
                            });
                            resolve(category);
                        })
                        .catch((error) => {
                            dispatch({
                                type: CATEGORY_MODAL_TREE_VIEW_FAILURE,
                                payload: error,
                            });
                            reject(error);
                        });
                })
                .catch((error) => {
                    dispatch({
                        type: CATEGORY_MODAL_TREE_VIEW_FAILURE,
                        payload: error,
                    });
                    reject(error);
                });
        });
    };

function initCategoryGroup(categoryGroupResponse, categories, selectedCategoryId = UNCATEGORIZED_ID) {
    const category = {
        data: [],
        selected: {},
    };

    let categoryGroup = {
        id: `${categoryGroupResponse.id}`,
        code: `${categoryGroupResponse.id}`,
        children: [],
        name: categoryGroupResponse.name,
        isActive: false,
        isSelected: false,
        isEmpty: false,
        isFold: false,
        isGroup: true,
        type: "categoryGroup",
        isRoot: true,
    };

    let uncategorized = {
        id: UNCATEGORIZED_ID,
        name: "Uncategorized Assets",
        isActive: false,
        isSelected: false,
        isEmpty: false,
        isGroup: true,
        isNotCategoryIds: true,
        type: "uncategorized",
        isRoot: true,
    };

    category.data.push(categoryGroup);
    category.data.push(uncategorized);

    const itemMap = { [categoryGroup.id]: categoryGroup, [uncategorized.id]: uncategorized };
    category.categoryMap = itemMap;
    for (let i = 0; i < categories.categories.length; i++) {
        let item = categories.categories[i];
        const code = item.code.split("_");
        item = {
            ...item,
            parent: code.length >= 3 ? { id: code[code.length - 3] } : categoryGroup,
            id: `${item.id}`,
            code: `${categoryGroup.id}_${item.code.replace(/_$/g, "")}`,
            children: [],
            isGroup: false,
            isEmpty: true,
            isFold: false,
            isSelected: false,
            type: "category",
            categoryGroup: { id: categoryGroupResponse.id, name: categoryGroupResponse.name },
            // root: categoryGroup,
            isRoot: false,
            groupId: categoryGroup.id,
        };
        itemMap[item.id] = item;

        if (selectedCategoryId === item.id) {
            // item.root.isActive = true;
            item.isSelected = true;
            category.selected = item;
        }

        if (item.depth === 1) {
            categoryGroup.children.push(item);
        } else {
            if (item.hasOwnProperty("parent")) {
                const code = item.code.split("_");
                createCategories(code[0], category.data, item);

                function createCategories(ele, category, item) {
                    let node = category.find((obj) => obj.id == ele);

                    if (!node) return;

                    if (ele == item.parent.id) {
                        node.isEmpty = false;
                        node.children.push(item);
                    } else {
                        if (code && code.length > 1) {
                            code.splice(0, 1);
                            createCategories(code[0], node.children, item);
                        }
                    }
                }
            }
        }
    }

    if (selectedCategoryId === categoryGroup.id) {
        categoryGroup.isActive = true;
        if (!fp.isEmpty(categoryGroup.children)) {
            category.selected = categoryGroup.item[0];
        } else {
            category.selected = categoryGroup;
        }
        category.isSelected = true;
    }
    if (selectedCategoryId === uncategorized.id) {
        uncategorized.isSelected = true;
        uncategorized.isActive = true;
        category.selected = uncategorized;
    }

    return category;
}
