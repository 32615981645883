import React, { useCallback, useMemo, useState } from "react";
import Loadable from "@react-loadable/revised";
import { useLocation } from "react-router-dom";
import VisiblitySensor from "react-visibility-sensor";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TreeView } from "@components_v2/tree-view";
import FolderTreeViewSkeleton from "@components/tree/components/folder-tree-view-skeleton";
import FolderTreeViewItem from "@components/tree/components/folder-tree-view-item";
import ReactDOM from "react-dom";
import { archiveAssetAPI } from "@modules/apis/assets";
import DialogArchive from "@features/dialog/dialog-archive";
import DialogArchiveRestore from "@features/dialog/dialog-archive-restore";
import DialogAssetDuplicate from "@routes/asset/dialog-asset-folder-duplicate";
import {
    deleteFolder,
    getFoldersList,
    initFolderData,
    moveFolder,
    updateFolderInfo,
    updateMovedFolder,
} from "@modules/folderTreeView";
import { AUTOCOMPLETE_TABLE_LIST_TYPE, DRIVE_FOLDER_SEARCH_TYPE, FOLDER_VIEW_DOMAINS } from "@constants";
import { setNotification } from "@modules/notification";
import { siteMode } from "@cores/siteMode";
import menuService from "@services/menuService";
import { listJobs } from "@modules/bulkJobs";
import useArchive from "@hooks/useArchive";
import DialogCreateCollection from "@routes/collections/dialog-create";

const LoadableCreateFolderModal = Loadable({
    loader: () => import("../modals/NewFolderModal"),
    loading: () => null,
});

const LoadableDeleteFolderModal = Loadable({
    loader: () => import("../../routes/asset/ui/DeleteFolderConfirm"),
    loading: () => null,
});

const LoadableSelectFolderPathModal = Loadable({
    loader: () => import("../modals/SelectFolderPath"),
    loading: () => null,
});

const LoadableFolderRenameModal = Loadable({
    loader: () => import("../modals/FolderRenameModal"),
    loading: () => null,
});

const FolderTreeView = ({
    open,
    domain,
    isModal,
    driveList,
    folderList,
    onHandleCreateFolder,
    onClickForderTree,
    isSearching,
    selectedFolder,
    onRefresh,
    actionMenus,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const { spaceId, projectId } = menuService.parse(location.pathname);

    const { requestArchive } = useArchive();

    const { project, folderTreeViewModal, stage } = useSelector((store) => ({
        project: store.project,
        folderTreeViewModal: store.folderTreeViewModal,
        stage: store.stage,
    }));

    const [dialog, setDialog] = useState("");
    const [selectedFolderByMenu, setSelectedFolderByMenu] = useState(null);
    const [createdFolderId, setCreatedFolderId] = useState("");
    const [actionPending, setActionPending] = useState({});

    const onClickResetFolderTreeFilter = async (driveId, rootFolder) => {
        onClickForderTree(rootFolder);
    };

    const onHandleClickCloseDialog = () => {
        setDialog("");
        setSelectedFolderByMenu(null);
    };

    const driveInfo = useMemo(() => {
        if (!driveList.data) return {};
        return driveList.data?.find((el) => el.resourceType?.type === domain.toUpperCase());
    }, [driveList]);

    const getTreeActionsMenu = useCallback(
        (folderObj) => {
            if (isModal || siteMode.get() === "CMS") return undefined;

            return actionMenus
                ?.filter((el) => el.visible === undefined || el.visible)
                ?.map((m) => ({
                    label: t(`common::label::${m.label}`),
                    onClick: () => {
                        setDialog(m.id);
                        setSelectedFolderByMenu({
                            ...folderObj,
                            driveInfo,
                            parentInfo: folderObj.parent ? folderList.data?.[folderObj.parent] : {},
                        });
                    },
                    clickClose: m.clickClose,
                    divider: m.divider ?? false,
                    disabled: m.disabled ?? false,
                }));
        },
        [actionMenus, isModal, driveList, folderList],
    );

    const renderTree = useCallback(
        (children = []) => {
            if (folderList.pending || children.length === 0) return;
            return (
                <>
                    {children.map((folder, index) => {
                        const folderObj = folderList.data[folder];

                        return (
                            <>
                                <VisiblitySensor
                                    active={
                                        !isSearching &&
                                        index === children.length - 1 &&
                                        !!folderList.data[folderObj.parent].nextToken
                                    }
                                    onChange={(isVisible, sensorElement) => {
                                        if (isVisible) {
                                            onHandleGetChildFolder(folderObj.parent);
                                        }
                                    }}
                                >
                                    <FolderTreeViewItem
                                        folder={folderObj}
                                        onClickFolder={onClickForderTree}
                                        onClickCreateFolder={onHandleCreateFolder}
                                        menuList={getTreeActionsMenu(folderObj)}
                                        creatingParentFolder={
                                            isModal ? folderTreeViewModal.folderList?.creatingParentFolder : undefined
                                        }
                                        onToggleFolderFold={(folderId) => onClickForderTree(folderId, true)}
                                        renderTree={renderTree}
                                    />
                                </VisiblitySensor>
                            </>
                        );
                    })}
                </>
            );
        },
        [createdFolderId, folderList, folderTreeViewModal, onHandleCreateFolder],
    );

    const onChangeFolderPath = useCallback(async () => {
        const toFolder = folderTreeViewModal.folderList.selectedFolder.folder;
        const fromObj = { id: selectedFolderByMenu?.id, parent: { id: selectedFolderByMenu?.parent } };
        const toObj = { id: toFolder.id, parent: { id: toFolder.parent } };
        const params = { from: fromObj, to: toObj };
        const driveId = { driveId: selectedFolderByMenu?.driveId };

        try {
            await dispatch(moveFolder(driveId, params));

            dispatch(
                setNotification({
                    type: "success",
                    contents: t(`common::msg::${"Move completed from {{item1}} to {{item2}}."}`, {
                        item1: folderList.data[fromObj.parent.id].name,
                        item2: folderTreeViewModal.folderList.data[toObj.id].name,
                    }),
                }),
            );

            await dispatch(updateMovedFolder({ fromPosition: fromObj, toPosition: toObj }));

            onHandleClickCloseDialog();
        } catch (error) {
            const errorData = error && error.response && error.response.data;
            const errorMessage = errorData && errorData.message;
            const errorStatus = (error && error.response && error.response.status) || "Unknown";
            const errorContents = `- ${errorMessage}`;

            dispatch(
                setNotification({
                    type: "error",
                    contents:
                        t(`common::msg::${"Error moving from {{item1}} to {{item2}}."}`, {
                            item1: folderList.data[fromObj.parent.id].name,
                            item2: folderTreeViewModal.folderList.data[toObj.id].name,
                        }) + errorContents,
                }),
            );
        }
    }, [folderList, driveList, folderTreeViewModal]);

    const onClickFolderDelete = useCallback(
        async (item) => {
            try {
                const params = { driveId: item.driveId, folderId: item.id };
                await dispatch(deleteFolder(params));

                const queryParameters = {
                    parentId: item.parent,
                    searchType: DRIVE_FOLDER_SEARCH_TYPE.DIRECT_CHILDREN,
                };
                const headerParams = { projectId };
                if (item.id === folderList.selectedFolder.folder.id) {
                    const rootFolder = await dispatch(initFolderData({ headerParams, domain }));
                    await onClickForderTree(rootFolder, false);
                } else {
                    await dispatch(
                        getFoldersList({
                            driveId: item.driveId,
                            queryParameters,
                            headerParams,
                        }),
                    );
                }
                dispatch(
                    setNotification({
                        type: "success",
                        contents: t(`common::msg::Deletion of folder {{item}} has been completed.`, {
                            item: item.name,
                        }),
                    }),
                );
                onHandleClickCloseDialog();
            } catch (error) {
                const errorData = error && error.response && error.response.data;
                const errorMessage = errorData && errorData.message;
                const errorStatus = (error && error.response && error.response.status) || "Unknown";
                const errorContents =
                    `- ` + errorStatus === 400 ? t(`common::msg::This folder contains assets.`) : `${errorMessage}`;
                console.error(error);

                dispatch(
                    setNotification({
                        type: "error",
                        contents:
                            t(`common::msg::There was an error deleting folder {{item}}.`, {
                                item: item.name,
                            }) + errorContents,
                    }),
                );
            }
        },
        [folderList, driveList, projectId, domain],
    );

    const onClickFolderRename = useCallback(
        async (item, name) => {
            try {
                const params = { name: name };

                await dispatch(updateFolderInfo({ driveId: item.driveId, folderId: item.id }, params));

                const queryParameters = {
                    parentId: item.parent,
                    searchType: DRIVE_FOLDER_SEARCH_TYPE.DIRECT_CHILDREN,
                };
                const headerParams = { projectId };
                await dispatch(
                    getFoldersList({
                        driveId: item.driveId,
                        queryParameters,
                        headerParams,
                    }),
                );
                dispatch(
                    setNotification({
                        type: "success",
                        contents: t(`common::msg::${"Rename completed from {{item1}} to {{item2}}."}`, {
                            item1: item.name,
                            item2: name,
                        }),
                    }),
                );
                onHandleClickCloseDialog();
            } catch (error) {
                const errorData = error && error.response && error.response.data;
                const errorMessage = errorData && errorData.message;
                const errorStatus = (error && error.response && error.response.status) || "Unknown";
                const errorContents = `- ${errorMessage}`;

                dispatch(
                    setNotification({
                        type: "error",
                        contents:
                            t(`common::msg::${"Error renaming from {{item1}} to {{item2}}."}`, {
                                item1: item.name,
                                item2: name,
                            }) + errorContents,
                    }),
                );

                return Promise.reject(error);
            }
        },
        [folderList, driveList, projectId],
    );

    const onHandleGetChildFolder = async (parentId) => {
        const parent = folderList.data[parentId];

        await dispatch(
            getFoldersList({
                driveId: parent.driveId,
                queryParameters: {
                    parentId: parent.id,
                    searchType: DRIVE_FOLDER_SEARCH_TYPE.DIRECT_CHILDREN,
                },
                nextToken: parent.nextToken,
                headerParams: { projectId },
            }),
        );
    };

    const onClickArchiveAssetFolder = async (_, option) => {
        try {
            setActionPending({ ...actionPending, archive: true });

            const id = selectedFolderByMenu?.id;
            if (!id) throw new Error();

            const targets =
                domain === "Asset"
                    ? {
                          virtualFolder: {
                              id,
                              recursive: false,
                          },
                      }
                    : { collection: { virtualFolder: { id, recursive: false } } };

            const data = {
                archiveClass: option,
                targets,
            };

            const isMulti = false;

            await requestArchive(isMulti, data, async () => {
                dispatch(listJobs());
                await onRefresh();
            });
        } catch (e) {
        } finally {
            setActionPending({ ...actionPending, archive: false });
            onHandleClickCloseDialog();
        }
    };

    const rootFolderList = useMemo(() => {
        if (!folderList.data) return null;
        return [Object.values(folderList.data).find((folder) => folder?.depth === 0)];
    }, [folderList.data]);

    const selectedVirtualFolderId = useMemo(
        () => selectedFolder ?? new URLSearchParams(location.search).get("virtualFolderId"),
        [location.search, selectedFolder],
    );

    return (
        <>
            {driveList.pending || folderList.pending ? (
                <FolderTreeViewSkeleton type={"root"} />
            ) : Object.keys(folderList.data ?? {})?.length > 0 ? (
                rootFolderList.length > 0 ? (
                    <TreeView
                        sx={{
                            width: `100%`,
                            minWidth: 318,
                            opacity: 1,
                            overflowY: `auto`,
                            transition: `opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 200ms`,
                            "::-webkit-scrollbar": {
                                width: "14px",
                                height: "14px",
                            },
                            "::-webkit-scrollbar-track": {
                                background: "transparent",
                            },
                            "::-webkit-scrollbar-thumb": {
                                backgroundColor: "#dce1e5",
                                borderRadius: "8px",
                                backgroundClip: "padding-box",
                                border: "4px solid transparent",
                            },
                        }}
                        defaultExpanded={[rootFolderList[0].id]}
                        defaultSelected={[rootFolderList[0].id]}
                        expanded={
                            // 설명: 폴더 닫혀있는 상태에서 하위 폴더 생성시 부모 폴더를 열면서 하위폴더를 보여줘야하는데 스토리북에 expansion에 대한 처리가 되어있어 여기서 제어가 불가능. expanded에서 전체를 다 관리
                            folderList.data &&
                            Object.values(folderList.data)
                                .filter((folder) => !folder.isFold)
                                .map((folder) => folder.id)
                        }
                        selected={[selectedVirtualFolderId]}
                    >
                        {rootFolderList?.map((folder, index) => {
                            const nodeId = String(folder.id);
                            return (
                                <FolderTreeViewItem
                                    folder={folder}
                                    onClickFolder={onClickForderTree}
                                    onRefreshDrive={!isModal ? onClickResetFolderTreeFilter : undefined}
                                    onClickCreateFolder={onHandleCreateFolder}
                                    menuList={
                                        ["Asset", "Collection"].includes(domain) && !isModal && siteMode.get() !== "CMS"
                                            ? [
                                                  {
                                                      label: t(`common::label::New Folder`, `New Folder`),
                                                      onClick: () => {
                                                          setDialog("create");
                                                          setSelectedFolderByMenu(folder);
                                                      },
                                                      clickClose: true,
                                                  },
                                              ]
                                            : undefined
                                    }
                                    creatingParentFolder={
                                        isModal ? folderTreeViewModal.folderList?.creatingParentFolder : undefined
                                    }
                                    onToggleFolderFold={(folder) => onClickForderTree(folder, true)}
                                    renderTree={renderTree}
                                />
                            );
                        })}
                    </TreeView>
                ) : (
                    <div>Please manage your assets by adding the new folder.</div>
                )
            ) : null}
            {/* {!folderList || folderList.pending ? ( //TODO: 처음 로딩 이후에는 로딩 아이콘이 뜨지 않고 API를 호출
                        <div className={"loading-block"}>
                            <span className={"loading-content text-primary"} />
                        </div>
                    ) : !folderList.error && folderList.data && Object.keys(folderList?.data).length > 0 ? (
                        <>
                            <AutoSizer>
                                {({ height, width }) => (
                                    <TreeList
                                        className="tree-scrolled-view"
                                        height={height}
                                        itemData={list}
                                        itemCount={list.length}
                                        itemSize={getSize}
                                        width={width}
                                        overscanCount={4}
                                        ref={folderTreeRef}
                                        //outerElementType={CustomScrollbarsVirtualList}
                                    >
                                        {Row}
                                    </TreeList>
                                )}
                            </AutoSizer>
                        </>
                    ) : (
                        <div className={"empty"}>
                            <i className="sprite sprite-nohierarchy"></i>
                            <p>{t(`common::label::No Assets Folders`)}</p>
                        </div>
                    )} */}
            {dialog === "create" &&
                ReactDOM.createPortal(
                    <LoadableCreateFolderModal
                        folder={selectedFolderByMenu}
                        onClickClose={onHandleClickCloseDialog}
                        onClickSubmit={onHandleCreateFolder}
                    />,
                    document.body,
                )}
            {/* 트리뷰에서 폴더 삭제 모달 */}
            {dialog === "delete" &&
                ReactDOM.createPortal(
                    <LoadableDeleteFolderModal
                        folder={selectedFolderByMenu}
                        onClickClose={onHandleClickCloseDialog}
                        onClickDelete={onClickFolderDelete}
                        onDeleteEnd={() => {}}
                    />,
                    document.body,
                )}
            {/* 트리뷰에서 폴더 이동 모달 */}
            {dialog === "move" &&
                ReactDOM.createPortal(
                    <LoadableSelectFolderPathModal
                        open={true}
                        domain={domain}
                        submitText={`Select`}
                        title={`Move`}
                        onSubmit={onChangeFolderPath}
                        onClose={onHandleClickCloseDialog}
                    />,
                    document.body,
                )}

            {/* 트리뷰에서 폴더 이름변경 모달 */}
            {dialog === "rename" &&
                ReactDOM.createPortal(
                    <LoadableFolderRenameModal
                        folder={selectedFolderByMenu}
                        onClickClose={onHandleClickCloseDialog}
                        onClickSubmit={onClickFolderRename}
                    />,
                    document.body,
                )}
            {/* 트리뷰에서 폴터 아카이브 모달  */}
            {dialog === "archive" && (
                <DialogArchive
                    active={true}
                    onClose={onHandleClickCloseDialog}
                    onSubmit={onClickArchiveAssetFolder}
                    actionPending={actionPending.archive}
                    folder={selectedFolderByMenu}
                />
            )}
            {/* 트리뷰에서 폴터 아카이브 복원 모달  */}
            {dialog === "restore" && (
                <DialogArchiveRestore
                    isMulti={true}
                    actionPending={actionPending}
                    onSubmitCallback={onRefresh}
                    onClose={onHandleClickCloseDialog}
                    folders={selectedFolderByMenu ? [selectedFolderByMenu] : []}
                    dataType={"folder"}
                />
            )}
            {dialog === "duplicate" && (
                <DialogAssetDuplicate folder={selectedFolderByMenu} onClickClose={onHandleClickCloseDialog} />
            )}
            {dialog === "createCollection" && (
                <DialogCreateCollection
                    onClose={onHandleClickCloseDialog}
                    open={dialog === "createCollection"}
                    assetFolder={selectedFolderByMenu}
                />
            )}
        </>
    );
};

export default React.memo(FolderTreeView);
