import React from "react";
import { Grid, IconButton } from "@mzc-pdc/ui";
import { IconClose, IconSave } from "@mzc-cloudplex/icons";

const EditSubmitButtonGroup = ({ onClickClose, onClickSubmit, submitDisabled, submitPending }) => {
    return (
        <Grid item sx={{ display: `flex`, justifyContent: `flex-end`, gap: `4px`, mt: 0.5 }}>
            <IconButton color={`greySecondary`} size={`medium`} variant={"outlined"} onClick={onClickClose}>
                <IconClose size={16} />
            </IconButton>
            <IconButton
                color={submitDisabled ? `greySecondary` : `primary`}
                size={`medium`}
                variant={"outlined"}
                disabled={submitDisabled}
                onClick={onClickSubmit}
                loading={submitPending}
            >
                <IconSave size={16} />
            </IconButton>
        </Grid>
    );
};

export default EditSubmitButtonGroup;
