import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Menu,
    MenuItem,
    Select,
    Switch,
    TextField,
    Tooltip,
    Stack,
    PDCAlpha as alpha,
} from "@mzc-pdc/ui";
import React, { useCallback, useEffect, useState } from "react";
import Typography from "@components_v2/typography/typography";
import { useTranslation } from "react-i18next";
import theme from "@styles/theme";
import { useDispatch, useSelector } from "react-redux";
import convertUtcToLocal from "@cores/convertUtcToLocal";
import useUserDetail from "@hooks/useUserDetail";
import UserDetailPopper, { UserDetailButton } from "src/features/user-detail-popper/user-detail-popper";
import getByteLength from "@cores/getByteLength";
import EditSubmitButtonGroup from "@components_v2/edit-submit-button-group/edit-submit-button-group";
import {
    IconAvata,
    IconEditSmall,
    IconSetting,
    ImageCategoryGroupOn,
    ImageDefaultCustomContentDark,
    ImageFolderSmallOn,
} from "@mzc-cloudplex/icons";
import StatusIcon from "@components_v2/status/status-icon";
import validateInput, { convertValidationMessage } from "@cores/validateInput";
import { getCategoryGroups } from "@modules/categoryGroupsManagement";
import { FOLDER_VIEW_DOMAINS, LIMIT_BYTE, REFERENCE_FIELDS_TYPE } from "@constants";
import DialogAddCustomContentResources from "@routes/custom-contents/dialog-add-custom-content-resources";
import useCustomContent from "@hooks/useCustomContent";
import StatusBadge from "@components_v2/status-badge/status-badge";
import EditButton from "@components_v2/edit-button/edit-button";
import SelectFolderPathModal from "@components/modals/SelectFolderPath";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import menuService from "@services/menuService";
import { UsersInput, UsersInputV2 } from "@features/users-input";
import { capitalize } from "@utils/convert-string";
import compareVersions, { VERSION_CM_11330_USERS_TEAMS_ACCESS_V3 } from "@cores/version";

const PanelOverview = ({ domain, data, afterMutation }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const {
        handleUserProfileClick,
        handleUserProfileClose,
        userProfileAnchorEl,
        openUserProfile,
        userProfileData,
        userProfilePending,
    } = useUserDetail();
    const {
        pending,
        results,
        viewMore,
        onResourceFetch,
        getResourceType,
        getAssetType,
        getContentType,
        getResourceTableColumns,
        resetParams,
    } = useCustomContent({
        pageType: "detail",
    });

    const folderList = useSelector(({ folderTreeView }) => folderTreeView.folderList?.data);
    const project = useSelector(({ project }) => project);
    const stage = useSelector(({ stage }) => stage);
    const categoryGroups = useSelector(({ categoryGroupsManagement }) => categoryGroupsManagement.categoryGroups);

    const [editValue, setEditValue] = useState({});
    const [editValueActive, setEditValueActive] = useState({});
    const [actionPending, setActionPending] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const [modal, setModal] = useState(null);

    const onToggleModal = (key = null, value = false, actionTarget = null) => {
        if (value) {
            setModal(key);
        } else {
            setModal("");
        }
    };

    // 1.0.80 Note: status하고 일반 값의 api가 달라서 props로 두개를 따로 받아야하는 상황이라 타입에 따른 분기처리를 줄이려고 data안에 onSubmitEditValue를 넣게 했습니다.
    // 일반 값 수정 부분이 많아지면 onSubmitEditValue가 같은게 반복적으로 넣어줘야하는 단점이 있긴 할 것 같습니다.
    const onHandleClickSubmitEditValue = async (id, onSubmitEditValue) => {
        try {
            setActionPending({ ...actionPending, [id]: true });
            await onSubmitEditValue({ type: id, editValue: editValue[id] });
            setEditValueActive({ ...actionPending, [id]: undefined });
        } catch (error) {
            console.log(error);
        } finally {
            setActionPending({ ...actionPending, [id]: undefined });
        }
    };

    const onHandleResourceFetchData = async (detailType) => {
        await onResourceFetch({ detailType });
    };

    const onHandleResourceSearchData = async (detailType, searchKeyword) => {
        resetParams();
        await onResourceFetch({ detailType, searchKeyword });
    };

    const onHandleSubmitPreviewAsset = async (assetIds, onSubmitEditValue) => {
        try {
            setActionPending({ ...actionPending, previewAsset: true });
            await onSubmitEditValue({ type: "previewAsset", editValue: { id: assetIds[0] } });
        } catch (error) {
            console.log(error);
        } finally {
            setActionPending({ ...actionPending, previewAsset: undefined });
        }
    };

    const onHandleLoadCategoryGroups = () => {
        dispatch(
            getCategoryGroups(project.id, {
                name: "",
                creator: "",
                status: "ACTIVE",
            }),
        );
    };

    const getFolderLocation = (value) => {
        if (!value || !folderList) return;
        let newFolderLocation = [];
        let currentFolder = folderList[value.virtualFolder.id];

        while (!!currentFolder) {
            newFolderLocation.push(currentFolder);
            currentFolder = folderList[currentFolder.parent];
        }
        return newFolderLocation
            .reverse()
            .map((folder) => folder.name)
            .join("/");
    };

    const getFolderName = (value) => {
        if (!value?.virtualFolder?.id || !folderList) return;
        let newFolderLocation = [];
        let currentFolder = folderList[value.virtualFolder.id];

        return currentFolder?.name ?? "";
    };

    const onHandleClickFolderButton = (value) => {
        if (!stage || !project || !value || !domain) return;

        let endpoint = "";
        switch (domain) {
            case FOLDER_VIEW_DOMAINS.ASSETS:
                endpoint = menuService.paths.assetsAssets(stage.id, project.id);
            case FOLDER_VIEW_DOMAINS.ARCHIVED_ASSETS:
                endpoint = menuService.paths.archiveAssets(stage.id, project.id);
        }

        history.push(`${endpoint}?virtualDriveId=${value.virtualDrive.id}&virtualFolderId=${value.virtualFolder.id}`);
    };

    const getComponentByType = useCallback(
        ({ type, id, subtitle, value, icon, isEditable, validation, onSubmitEditValue }) => {
            if (value === undefined) return null;

            if (type === "image") {
                return (
                    <>
                        {modal === "changePreview" && (
                            <DialogAddCustomContentResources
                                resource={{
                                    type: "PREVIEW",
                                    detailType: REFERENCE_FIELDS_TYPE.IMAGE_ASSETS,
                                }}
                                onClose={() => onToggleModal()}
                                onFetchData={onHandleResourceFetchData}
                                onSearchData={onHandleResourceSearchData}
                                onSubmitCallback={(_, assetIds) =>
                                    onHandleSubmitPreviewAsset(assetIds, onSubmitEditValue)
                                }
                                columns={getResourceTableColumns("PREVIEW_ASSET")}
                                pending={pending}
                                results={results}
                                viewMore={viewMore}
                                hideCheckbox={true}
                            />
                        )}
                        <ListItemText
                            disableTypography
                            primary={
                                <Typography
                                    variant={"body2"}
                                    color={theme.palette.greySecondary[600]}
                                    component={"p"}
                                    gutterBottom
                                >
                                    {t(`common::label::${subtitle}`, subtitle)}
                                </Typography>
                            }
                            secondary={
                                <Grid
                                    container
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    maxWidth={432}
                                    height={242}
                                    sx={{ backgroundColor: theme.palette.secondary[50], position: "relative" }}
                                >
                                    {value ? (
                                        <img
                                            src={value?.previews?.[0]?.accessUrl ?? value?.accessUrls?.[0]}
                                            loading="lazy"
                                            alt={""}
                                            height={242}
                                        />
                                    ) : (
                                        <ImageDefaultCustomContentDark width={46} height={56} htmlColor={"#7f96e3"} />
                                    )}
                                    <Tooltip title={t(`common::label::${"Edit"}`)}>
                                        <IconButton
                                            className={"btn-edit"}
                                            size={"xsmall"}
                                            circled
                                            variant={"dashed"}
                                            onClick={() => {
                                                onToggleModal("changePreview", true);
                                            }}
                                            sx={{
                                                position: `absolute`,
                                                right: 8,
                                                top: 8,
                                            }}
                                        >
                                            <IconEditSmall size={12} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            }
                        />
                    </>
                );
            }
            if (type === "text") {
                return (
                    <>
                        <ListItemText
                            disableTypography
                            primary={
                                <>
                                    <Typography variant={"body2"} color={theme.palette.greySecondary[600]} gutterBottom>
                                        {t(`common::label::${subtitle}`, subtitle)}
                                    </Typography>
                                    {isEditable && !editValueActive[id] && (
                                        <EditButton
                                            onClick={() => {
                                                setEditValueActive({
                                                    ...editValueActive,
                                                    [id]: true,
                                                });
                                                setEditValue({ ...editValue, [id]: value ?? "" });
                                            }}
                                        />
                                    )}
                                </>
                            }
                            secondary={
                                <Grid item sx={{ mt: 1 }}>
                                    {!editValueActive[id] ? (
                                        <Typography
                                            variant={"body1_400"}
                                            component={"span"}
                                            sx={{ color: theme.palette.secondary[800] }}
                                        >
                                            {value}
                                        </Typography>
                                    ) : (
                                        <>
                                            <TextField
                                                fullWidth
                                                type={`text`}
                                                value={editValue[id]}
                                                error={validationErrors[id]}
                                                helperText={
                                                    validationErrors[id]
                                                        ? convertValidationMessage(validationErrors?.[id]?.type)
                                                        : ""
                                                }
                                                maxByte={LIMIT_BYTE.description}
                                                bytes={getByteLength(editValue[id])}
                                                onChange={(e) => {
                                                    if (validation) {
                                                        setValidationErrors({
                                                            ...validationErrors,
                                                            [id]: validateInput(e.target.value, validation),
                                                        });
                                                    }
                                                    setEditValue({
                                                        ...editValue,
                                                        [id]: e.target.value,
                                                    });
                                                }}
                                            />
                                            <EditSubmitButtonGroup
                                                submitDisabled={editValue[id] === value || actionPending[id]}
                                                submitPending={actionPending[id]}
                                                onClickSubmit={() => {
                                                    onHandleClickSubmitEditValue(id, onSubmitEditValue);
                                                }}
                                                onClickClose={() => {
                                                    setEditValueActive({
                                                        ...editValueActive,
                                                        [id]: undefined,
                                                    });
                                                }}
                                            />
                                        </>
                                    )}
                                </Grid>
                            }
                        />
                    </>
                );
            }
            if (type === "textarea") {
                return (
                    <>
                        <ListItemText
                            disableTypography
                            primary={
                                <>
                                    <Typography variant={"body2"} color={theme.palette.greySecondary[600]} gutterBottom>
                                        {t(`common::label::${subtitle}`, subtitle)}
                                    </Typography>
                                    {isEditable && !editValueActive[id] && (
                                        <EditButton
                                            onClick={() => {
                                                setEditValueActive({
                                                    ...editValueActive,
                                                    [id]: true,
                                                });
                                                setEditValue({ ...editValue, [id]: value ?? "" });
                                            }}
                                        />
                                    )}
                                </>
                            }
                            secondary={
                                <Grid item sx={{ mt: 1 }}>
                                    {!editValueActive[id] ? (
                                        <Typography
                                            variant={"body1_400"}
                                            component={"span"}
                                            sx={{ color: theme.palette.secondary[800] }}
                                        >
                                            {value ?? "-"}
                                        </Typography>
                                    ) : (
                                        <>
                                            <TextField
                                                fullWidth
                                                multiline
                                                type={`text`}
                                                value={editValue[id]}
                                                error={validationErrors[id]}
                                                helperText={
                                                    validationErrors[id]
                                                        ? convertValidationMessage(validationErrors?.[id]?.type)
                                                        : ""
                                                }
                                                bytes={getByteLength(editValue[id])}
                                                onChange={(e) => {
                                                    if (validation) {
                                                        setValidationErrors({
                                                            ...validationErrors,
                                                            [id]: validateInput(e.target.value, {
                                                                ...validation,
                                                                maxByte: LIMIT_BYTE.description,
                                                            }),
                                                        });
                                                    }
                                                    setEditValue({
                                                        ...editValue,
                                                        [id]: e.target.value,
                                                    });
                                                }}
                                            />
                                            <EditSubmitButtonGroup
                                                submitDisabled={editValue[id] === value || actionPending[id]}
                                                submitPending={actionPending[id]}
                                                onClickSubmit={() => {
                                                    onHandleClickSubmitEditValue(id, onSubmitEditValue);
                                                }}
                                                onClickClose={() => {
                                                    setEditValueActive({
                                                        ...editValueActive,
                                                        [id]: undefined,
                                                    });
                                                }}
                                            />
                                        </>
                                    )}
                                </Grid>
                            }
                        />
                    </>
                );
            } else if (type === "iconText") {
                return (
                    <ListItemText
                        disableTypography
                        primary={
                            <Typography variant={"body2"} color={theme.palette.greySecondary[600]} gutterBottom>
                                {t(`common::label::${subtitle}`, subtitle)}
                            </Typography>
                        }
                        secondary={
                            <Grid item sx={{ mt: 1, display: "flex", alignItems: "center" }}>
                                {icon}
                                <Typography
                                    variant={"body1_400"}
                                    component={"span"}
                                    sx={{
                                        marginLeft: "8px !important",
                                    }}
                                >
                                    {t(`common::label::${value}`)}
                                </Typography>
                            </Grid>
                        }
                    />
                );
            } else if (type === "status") {
                return (
                    <ListItemText
                        disableTypography
                        primary={
                            <>
                                <Typography variant={"body2"} color={theme.palette.greySecondary[600]} gutterBottom>
                                    {t(`common::label::${subtitle}`, subtitle)}
                                </Typography>
                                {isEditable && !editValueActive[id] && (
                                    <EditButton
                                        onClick={() => {
                                            setEditValueActive({ ...editValueActive, [id]: true });
                                            setEditValue({ ...editValue, [id]: value ?? "" });
                                        }}
                                    />
                                )}
                            </>
                        }
                        secondary={
                            <>
                                <Grid
                                    item
                                    sx={{
                                        mt: 1,
                                        border: editValueActive[id]
                                            ? `1px solid ${theme.palette.primary[400]}`
                                            : undefined,
                                        borderRadius: "2px",
                                        padding: editValueActive[id] ? "15px 20px" : 0,
                                    }}
                                >
                                    {!editValueActive[id] ? (
                                        <>
                                            <StatusBadge status={value} showText={true} />
                                        </>
                                    ) : (
                                        <>
                                            <StatusBadge status={editValue[id]} showText={true} />
                                            <Switch
                                                color={"success"}
                                                checked={editValue[id] === "ACTIVE"}
                                                onChange={(e) => {
                                                    e.stopPropagation();
                                                    setEditValue({
                                                        ...editValue,
                                                        [id]: e.target.checked ? "ACTIVE" : "INACTIVE",
                                                    });
                                                }}
                                            />
                                        </>
                                    )}
                                </Grid>
                                {editValueActive[id] && (
                                    <EditSubmitButtonGroup
                                        submitDisabled={editValue[id] === value || actionPending[id]}
                                        submitPending={actionPending[id]}
                                        onClickSubmit={() => {
                                            onHandleClickSubmitEditValue(id, onSubmitEditValue);
                                        }}
                                        onClickClose={() => {
                                            setEditValueActive({
                                                ...editValueActive,
                                                [id]: undefined,
                                            });
                                        }}
                                    />
                                )}
                            </>
                        }
                    />
                );
            } else if (type === "date") {
                return (
                    <ListItemText
                        disableTypography
                        primary={
                            <Typography variant={"body2"} color={theme.palette.greySecondary[600]} gutterBottom>
                                {t(`common::label::${subtitle}`, subtitle)}
                            </Typography>
                        }
                        secondary={
                            <Grid container alignItems={`center`} mt={1}>
                                <Typography variant={"body1_400"} component={"span"}>
                                    {`${value.at ? convertUtcToLocal(value.at) : "-"} `}
                                    {!value.hideOwner && (
                                        <>
                                            {`by `}
                                            <Button
                                                variant={"text"}
                                                color={`inherit`}
                                                size={"small"}
                                                sx={{
                                                    minWidth: 0,
                                                    textAlign: `left`,
                                                }}
                                                onClick={(e) => handleUserProfileClick(e, value.by?.id)}
                                            >
                                                {value.by?.name ?? value.by?.id}
                                            </Button>
                                        </>
                                    )}
                                </Typography>
                            </Grid>
                        }
                    />
                );
            } else if (type === "owner") {
                return (
                    <ListItemText
                        disableTypography
                        primary={
                            <>
                                <Typography variant={"body2"} color={theme.palette.greySecondary[600]} gutterBottom>
                                    {t(`common::label::${subtitle}`, subtitle)}
                                </Typography>
                                {isEditable && !editValueActive[id] && (
                                    <EditButton
                                        onClick={() => {
                                            setEditValueActive({ ...editValueActive, [id]: true });
                                            setEditValue({ ...editValue, [id]: value ?? "" });
                                        }}
                                    />
                                )}
                            </>
                        }
                        secondary={
                            <>
                                <Grid
                                    item
                                    sx={{
                                        mt: 1,
                                        // border: editValueActive[id]
                                        //     ? `1px solid ${theme.palette.primary[400]}`
                                        //     : undefined,
                                        // borderRadius: "2px",
                                        // padding: editValueActive[id] ? "15px 20px" : 0,
                                    }}
                                >
                                    {!editValueActive[id] ? (
                                        <>
                                            {value?.name ? (
                                                <UserDetailButton
                                                    maxWidth={250}
                                                    onClick={(e) => handleUserProfileClick(e, value.id)}
                                                    userName={value.name}
                                                />
                                            ) : (
                                                "-"
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {compareVersions(stage.version, VERSION_CM_11330_USERS_TEAMS_ACCESS_V3) >=
                                            0 ? (
                                                <UsersInputV2
                                                    user={{ id: editValue[id].id, name: editValue[id].name }}
                                                    onDeleteUser={() => {
                                                        setEditValue({ ...editValue, [id]: undefined });
                                                    }}
                                                    onAddUser={(user) => {
                                                        setEditValue({ ...editValue, [id]: user });
                                                    }}
                                                    isAvatarIconRequired={true}
                                                    disabledSearchUsers={false}
                                                    isEdit={true}
                                                />
                                            ) : (
                                                <UsersInput
                                                    user={{ id: editValue[id].id, name: editValue[id].name }}
                                                    onDeleteUser={() => {
                                                        setEditValue({ ...editValue, [id]: undefined });
                                                    }}
                                                    onAddUser={(user) => {
                                                        setEditValue({ ...editValue, [id]: user });
                                                    }}
                                                    isAvatarIconRequired={true}
                                                    disabledSearchUsers={false}
                                                    isEdit={true}
                                                />
                                            )}
                                        </>
                                    )}
                                </Grid>
                                {editValueActive[id] && (
                                    <EditSubmitButtonGroup
                                        submitDisabled={editValue[id] === value || actionPending[id]}
                                        submitPending={actionPending[id]}
                                        onClickSubmit={() => {
                                            onHandleClickSubmitEditValue(id, onSubmitEditValue);
                                        }}
                                        onClickClose={() => {
                                            setEditValueActive({
                                                ...editValueActive,
                                                [id]: undefined,
                                            });
                                        }}
                                    />
                                )}
                            </>
                        }
                    />
                );
            } else if (type === "categoryGroup") {
                return (
                    <ListItemText
                        disableTypography
                        primary={
                            <>
                                <Typography variant={"body2"} color={theme.palette.greySecondary[600]} gutterBottom>
                                    {t(`common::label::${subtitle}`, subtitle)}
                                </Typography>
                                {isEditable && !editValueActive[id] && (
                                    <Tooltip title={t(`common::label::${"Edit"}`)}>
                                        <IconButton
                                            className={"btn-edit"}
                                            size={"xsmall"}
                                            circled
                                            variant={"dashed"}
                                            onClick={() => {
                                                onHandleLoadCategoryGroups();
                                                setEditValueActive({ ...editValueActive, [id]: true });
                                                setEditValue({ ...editValue, [id]: value ?? "" });
                                            }}
                                        >
                                            <IconEditSmall size={12} />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </>
                        }
                        secondary={
                            <>
                                <Grid container flexDirection={"row"} alignItems={"center"}>
                                    <Grid item>
                                        <ImageCategoryGroupOn width={16} height={16} />
                                    </Grid>
                                    <Grid
                                        item
                                        flex={1}
                                        sx={{
                                            mt: 1,
                                            ml: 1,
                                            borderRadius: "2px",
                                            order: editValueActive[id]
                                                ? `1px solid ${theme.palette.primary[400]}`
                                                : undefined,
                                            // padding: editValueActive[id] ? "15px 20px" : 0,
                                        }}
                                    >
                                        {!editValueActive[id] ? (
                                            <Typography
                                                component={`span`}
                                                variant={"body1_400"}
                                                color={theme.palette.greySecondary[800]}
                                                sx={{ ml: 1 }}
                                            >
                                                {value?.name}
                                            </Typography>
                                        ) : (
                                            <Grid width={"100%"}>
                                                <Select
                                                    fullWidth
                                                    id="demo-simple-select"
                                                    labelId="demo-simple-select-label"
                                                    onChange={(e) => {
                                                        setEditValue({
                                                            ...editValue,
                                                            [id]: { id: e.target.value },
                                                        });
                                                    }}
                                                    value={editValue[id].id}
                                                    defaultValue={value?.id}
                                                    sx={{ ".MuiMenuPaper-root": { width: "350px" } }}
                                                >
                                                    {categoryGroups?.map((category) => (
                                                        <MenuItem
                                                            sx={{
                                                                whiteSpace: "normal",
                                                                wordBreak: "break-all",
                                                            }}
                                                            dense
                                                            value={category.id}
                                                        >
                                                            {category.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                                {editValueActive[id] && (
                                    <EditSubmitButtonGroup
                                        submitDisabled={editValue[id] === value || actionPending[id]}
                                        submitPending={actionPending[id]}
                                        onClickSubmit={() => {
                                            onHandleClickSubmitEditValue(id, onSubmitEditValue);
                                        }}
                                        onClickClose={() => {
                                            setEditValueActive({
                                                ...editValueActive,
                                                [id]: undefined,
                                            });
                                        }}
                                    />
                                )}
                            </>
                        }
                    />
                );
            } else if (type === "folder") {
                const folderLocation = getFolderLocation(value);
                return (
                    <>
                        <ListItemText
                            disableTypography
                            primary={
                                <>
                                    <SelectFolderPathModal
                                        open
                                        domain={capitalize(domain, true).slice(0, -1)}
                                        submitText={`Select`}
                                        width={"600px"}
                                        title={"Select assets in a specific folder?"}
                                        subFoldersOption={false}
                                        onSubmit={async (value) => {
                                            await onSubmitEditValue({ type, editValue: value });
                                            onToggleModal();
                                        }}
                                        onClose={() => onToggleModal()}
                                        open={modal === "selectFolderPath"}
                                    />
                                    <Typography variant={"body2"} color={theme.palette.greySecondary[600]} gutterBottom>
                                        {t(`common::label::${subtitle}`, subtitle)}
                                    </Typography>
                                    {isEditable && !editValueActive[id] && (
                                        <EditButton
                                            onClick={() => {
                                                onToggleModal("selectFolderPath", true);
                                            }}
                                        />
                                    )}
                                </>
                            }
                            secondary={
                                <Grid item sx={{ mt: 1 }}>
                                    <Grid container flexDirection={"row"} alignItems={"center"}>
                                        <Grid item mr={0.5}>
                                            <ImageFolderSmallOn height={16} width={16} />
                                        </Grid>
                                        <Tooltip title={folderLocation}>
                                            <Button
                                                variant={"text"}
                                                size={"small"}
                                                color={"secondary"}
                                                onClick={() => onHandleClickFolderButton(value)}
                                            >
                                                {getFolderName(value)}
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            }
                        />
                    </>
                );
            }
        },
        [
            stage,
            project,
            modal,
            editValueActive,
            editValue,
            actionPending,
            validationErrors,
            categoryGroups,
            folderList,
            results,
            pending,
            onHandleResourceSearchData,
            onHandleResourceFetchData,
        ],
    );

    return (
        <Box pt={2.5} pl={2.5} pr={1} pb={2.5}>
            {data?.map((group, index) => (
                <div key={index}>
                    <Stack>
                        {group.attributions?.length > 0 && (
                            <Grid container>
                                <List
                                    disablePadding
                                    sx={{ p: 0, width: "100%", display: "flex", flexDirection: "column", gap: 1.5 }}
                                >
                                    {group.attributions?.map((attribution, index) => (
                                        <ListItem
                                            key={index}
                                            sx={{
                                                pt: index > 0 ? 1 : 0,
                                                px: 1.5,
                                                py: 0.25,
                                                borderRadius: 0.5,
                                                transition: "background-color 0.3s",

                                                "&:not(:has(.MuiSwitch-root, .MuiAutocomplete-root))": {
                                                    "&:hover": {
                                                        backgroundColor: alpha(theme.palette.greySecondary[50], 0.5),
                                                    },
                                                },
                                            }}
                                        >
                                            {getComponentByType(attribution)}
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                        )}
                    </Stack>
                    {index < data.length - 1 && (
                        <Divider flexItem orientation={"horizontal"} sx={{ mt: 2.5, mb: 2.5 }} />
                    )}
                </div>
            ))}
            {!userProfilePending && (
                <UserDetailPopper
                    id={openUserProfile ? "user-profile-popover" : undefined}
                    handleUserProfileClose={handleUserProfileClose}
                    userProfileAnchorEl={userProfileAnchorEl}
                    openUserProfile={openUserProfile}
                    userProfileData={userProfileData}
                />
            )}
        </Box>
    );
};

export default PanelOverview;
