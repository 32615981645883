import React from "react";
import { Grid } from "@mzc-pdc/ui";

export const PageTools = ({ search, actions }) => {
    return (
        <>
            <Grid container justifyContent={"space-between"} mb={1.5} gap={2} pl={`1px`}>
                <Grid item xs>
                    {search && search}
                </Grid>
                {actions && <Grid item>{actions}</Grid>}
            </Grid>
        </>
    );
};
