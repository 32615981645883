import React, { useState, useRef, useCallback, useEffect, Fragment } from "react";
import { Button, Checkbox, Divider, Grid, Collapse, FormControlLabel, Typography, Box, IconButton } from "@mzc-pdc/ui";
import { useTranslation } from "react-i18next";
import theme from "@styles/theme";
import useFilter from "@hooks/useFilter";
import { IconAvata, IconResetSmall, IconSetting } from "@mzc-cloudplex/icons";
import FilterChipExpand from "../list-search/filter-chip-expand";
import { COMMON_STATUS, CONTENT_SCHEMA_TYPES_OPTIONS, MEDIA_TYPES, SELECT_STATUS, VOCABULARY_STATUS } from "@constants";
import ChipTypesInput from "../chip-popper-components/chip-types-input/chip-types-input";
import { useMemo } from "react";

const RESIZE_MENU_COUNT = 4;
const RESIZE_RESOLUTION = 500;

const ListToolCollapse = ({
    type,
    isCollapsed,
    toolSize,
    numSelected,
    pageCount,
    buttons,
    pagination,
    sort,
    filter,
    viewToggleButtons,
    hideCheckbox,
    handleClearCheckAll,
    handleChangeCheckAll,
    filterDisabled,
    totalCount,
    totalView,
}) => {
    const { t } = useTranslation();
    const [resizedButtons, setResizedButtons] = useState([]);
    const [hideText, setHideText] = useState(false);
    const ref = useRef(null);

    const {
        filterValue,
        editFilterValue,
        updateEditFilter,
        resetEditFilter,
        saveFilter,
        deleteFilter,
        onUpdateFilterParams,
    } = useFilter(type);

    const onHandleDeleteFilter = useCallback(
        (id) => {
            deleteFilter(id, (filterParams) => {
                onUpdateFilterParams(filterParams);
            });
        },
        [onUpdateFilterParams],
    );

    const getSelectComponent = useCallback(
        ({ id, options }) => {
            if (!id) return;
            if (id === "mediaType")
                return (
                    <ChipTypesInput
                        types={editFilterValue.mediaType}
                        placeholder={t("common::label::Select media type", "Select media type")}
                        options={Object.values(MEDIA_TYPES).map((type) => ({ title: type, id: type }))}
                        onDeleteType={(index) => {
                            updateEditFilter(
                                "mediaType",
                                editFilterValue.mediaType.filter((t, idx) => idx !== index),
                            );
                        }}
                        onAddType={(ext) => {
                            updateEditFilter(
                                "mediaType",
                                editFilterValue.mediaType ? [...editFilterValue.mediaType, ext] : [ext],
                            );
                        }}
                    />
                );
            if (id === "status") {
                return (
                    <ChipTypesInput
                        types={editFilterValue.status}
                        placeholder={t("common::label::Select status")}
                        options={Object.values(SELECT_STATUS[type]).map((status) => ({
                            title: t(`common::label::${status}`),
                            id: status,
                        }))}
                        onDeleteType={(index) => {
                            updateEditFilter(
                                "status",
                                editFilterValue.status.filter((t, idx) => idx !== index),
                            );
                        }}
                        onAddType={(ext) => {
                            updateEditFilter(
                                "status",
                                editFilterValue.status ? [...editFilterValue.status, ext] : [ext],
                            );
                        }}
                    />
                );
            }
            if (["scope", "type"].includes(id)) {
                return (
                    <ChipTypesInput
                        types={editFilterValue?.[id]}
                        placeholder={t(`common::label::Select ${id}`)}
                        options={options}
                        onDeleteType={(index) => {
                            updateEditFilter(
                                id,
                                editFilterValue?.[id].filter((t, idx) => idx !== index),
                            );
                        }}
                        onAddType={(ext) => {
                            updateEditFilter(id, editFilterValue?.[id] ? [...editFilterValue?.[id], ext] : [ext]);
                        }}
                    />
                );
            }
        },
        [type, editFilterValue],
    );

    const onHandleSaveFilter = useCallback(
        (id) => {
            saveFilter(id, (filterParams) => {
                onUpdateFilterParams(filterParams);
            });
        },
        [onUpdateFilterParams, saveFilter],
    );

    const filterParams = useMemo(() => {
        if (!filter) return;
        const newFilterList = filter.map((item) => {
            const data = filterValue[item.id];
            if (typeof data === "object" && data?.length > 0)
                return {
                    ...item,
                    label: data?.length > 0 ? data[0].label : null,
                    value: data,
                    length: data?.length > 1 ? data?.length - 1 : null,
                };

            return { ...item, label: data, value: data, length: null };
        });
        return newFilterList;
    }, [filterValue]);

    useEffect(() => {
        if (!buttons) return;

        if (parseInt(getComputedStyle(ref.current).width, 10) < RESIZE_RESOLUTION) {
            setResizedButtons(buttons(true));
            setHideText(true);
        } else {
            if (buttons(true).length > RESIZE_MENU_COUNT) {
                setResizedButtons(buttons(true));
            } else setResizedButtons(buttons(false));
            setHideText(false);
        }

        const resizeListener = () => {
            //사이즈가 변하는 위치에 있는 경우 resize 이벤트로 반복
            if (parseInt(getComputedStyle(ref.current).width, 10) < RESIZE_RESOLUTION) {
                setResizedButtons(buttons(true));
                setHideText(true);
            } else {
                if (buttons(true).length > RESIZE_MENU_COUNT) {
                    setResizedButtons(buttons(true));
                } else {
                    setResizedButtons(buttons(false));
                }
                setHideText(false);
            }
        };

        window.addEventListener("resize", resizeListener);
        return () => window.removeEventListener("resize", resizeListener);
    }, [buttons]);

    return (
        <>
            <Collapse
                in={isCollapsed}
                collapsedSize={1}
                sx={{
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.common.white,
                    borderRadius: `3px`,
                }}
                ref={ref}
            >
                <Grid
                    container
                    flexDirection={toolSize === "sm" ? `column` : `row`}
                    alignItems={`center`}
                    justifyContent={`space-between`}
                    p={1}
                >
                    <Grid item xs>
                        <Grid container alignItems={"center"}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color={"greySecondary"}
                                        checked={numSelected ? numSelected > 0 : false}
                                        indeterminate={numSelected > 0 && numSelected !== pageCount}
                                        hidden={hideCheckbox}
                                    />
                                }
                                label={
                                    <>
                                        <Typography variant={"body3_700"} color={theme.palette.secondary.contrastText}>
                                            {numSelected}
                                        </Typography>
                                        {!hideText && (
                                            <Typography
                                                variant={"body3_400"}
                                                color={theme.palette.secondary.contrastText}
                                            >
                                                item selected
                                            </Typography>
                                        )}
                                    </>
                                }
                                onClick={handleChangeCheckAll}
                                sx={{ ml: 0 }}
                            />
                            <Divider flexItem orientation={"vertical"} sx={{ my: 0.5, mr: 1.5, opacity: 0.12 }} />
                            {hideText ? (
                                <IconButton size={"xsmall"} onClick={handleClearCheckAll}>
                                    <IconResetSmall size={16} htmlColor={theme.palette.secondary.contrastText} />
                                </IconButton>
                            ) : (
                                <Button
                                    size={"small"}
                                    variant={"text"}
                                    endIcon={
                                        <IconResetSmall size={16} htmlColor={theme.palette.secondary.contrastText} />
                                    }
                                    onClick={handleClearCheckAll}
                                >
                                    {!hideText ? (
                                        <Typography
                                            variant={"body1Regular"}
                                            color={theme.palette.secondary.contrastText}
                                        >
                                            Clear
                                        </Typography>
                                    ) : undefined}
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems={"flex-end"} gap={1}>
                            {resizedButtons.map((button, index) => (
                                <Fragment key={`list-tools-buttons-${index}`}>{button}</Fragment>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Collapse>
            <Box>
                <Grid container alignItems={`center`} justifyContent={`space-between`} py={1.5}>
                    {totalView && (
                        <Grid item flex={1}>
                            <Typography variant={"body1_700"}>{t("common::label::Total")}</Typography>
                            <Typography>{` ${totalCount}`}</Typography>
                        </Grid>
                    )}
                    <Grid item>
                        <Grid container alignItems={`center`} gap={1.5}>
                            {handleChangeCheckAll && (
                                <Grid item>
                                    <Collapse in={numSelected === 0} orientation="horizontal">
                                        <Checkbox
                                            color={"greySecondary"}
                                            checked={numSelected > 1}
                                            indeterminate={numSelected > 0 && numSelected !== pageCount}
                                            onChange={handleChangeCheckAll}
                                            hidden={hideCheckbox}
                                        />
                                    </Collapse>
                                </Grid>
                            )}
                            <Grid item>{sort}</Grid>
                            {!filterDisabled && (
                                <Grid item>
                                    <Grid container flexDirection={"row"} alignItems={"center"} gap={1.5}>
                                        {filterParams?.map((item, index) => {
                                            return (
                                                <FilterChipExpand
                                                    key={`filter-chip-expand-${index}`}
                                                    item={item}
                                                    onDelete={() => onHandleDeleteFilter(item.id)}
                                                    isDisabled={editFilterValue[item.id] === filterValue[item.id]}
                                                    onCancel={() => resetEditFilter()}
                                                    onSave={() => onHandleSaveFilter(item.id)}
                                                    component={getSelectComponent(item)}
                                                />
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Grid item>
                            <Grid container flexWrap={"nowrap"} alignItems={"center"}>
                                {pagination}
                            </Grid>
                        </Grid>
                        {viewToggleButtons}
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default ListToolCollapse;
