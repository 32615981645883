import React, { useEffect, useState } from "react";
import TooltipUserInfo from "../../../components/tooltip/userInfo";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PopperMenu from "@components/tooltip/PopperMenu";
import { ButtonTransParent, ButtonOutlineDark } from "@components/buttons";
import SpriteIcon from "@components/icons/SpriteIcon";
import { toggleShareModalOpen } from "@modules/sharedBox";
import fp from "lodash/fp";
import clsx from "clsx";
import ScrollArea from "@components/ScrollArea";
import DialogShare from "@features/dialog/share/dialog-share";
import DialogManageShare from "@features/dialog/share/dialog-manage-share";

const SharedWithTab = ({ onClickClose }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [selectedApproverFilter, setSelectApproverFilter] = useState("By last shared");
    const [approvers, setApprovers] = useState([]);
    const [showDropdownLayer, setShowDropdownLayer] = useState(false);
    const [modal, setModal] = useState(null);

    const { grantees, propertyPanel } = useSelector(({ sharedBox, propertyPanel }) => ({
        grantees: sharedBox.sharedResourcesDetail.results,
        propertyPanel,
    }));

    useEffect(() => {
        if (!grantees) return;

        setApprovers(() => {
            switch (selectedApproverFilter) {
                case "By last shared":
                    return fp.sortBy("granted.at", grantees).reverse();
                case "By name":
                    return fp.sortBy("grantee.name", grantees);
                default:
                    return [];
            }
        });
    }, [selectedApproverFilter, grantees]);

    return (
        <React.Fragment>
            {modal === "share" && (
                <DialogManageShare
                    selectedItem={{
                        id: propertyPanel.data.id,
                        name: propertyPanel.data.name,
                        summary: propertyPanel.data,
                    }}
                    domain={!!propertyPanel.data.bucket ? "STORAGE" : "ASSET"}
                    onClickClose={() => setModal(null)}
                />
            )}
            <div className="control">
                <PopperMenu
                    ButtonComponent={
                        <ButtonTransParent>
                            <SpriteIcon className={"sprite-sort"} />
                            <span>{t(`common::label::${selectedApproverFilter}`, { selectedApproverFilter })}</span>
                        </ButtonTransParent>
                    }
                    injectWrapClassName={clsx("btn-dropdown", {
                        "dropdown-open": showDropdownLayer,
                    })}
                    menuButtons={[
                        {
                            skipTranslationLabel: t(`common::label::By last shared`, "By last shared"),
                            onClick: () => {
                                setSelectApproverFilter("By last shared");
                            },
                            enable: true,
                        },
                        {
                            skipTranslationLabel: t(`common::label::By name`, "By name"),
                            onClick: () => {
                                setSelectApproverFilter("By name");
                            },
                            enable: true,
                        },
                    ]}
                />
                <ButtonOutlineDark
                    className={"btn-sm"}
                    onClick={(e) => {
                        e.stopPropagation();
                        setModal("share");
                    }}
                >
                    <SpriteIcon className="sprite-share-add" />
                    <span>{t(`common::label::Share`, `Share`)}</span>
                </ButtonOutlineDark>
            </div>

            <ScrollArea>
                <div className="list-shared-with">
                    <ul>
                        {grantees &&
                            fp.map(
                                (grantee) => (
                                    <li key={grantee.id}>
                                        <div className="info">
                                            <span>
                                                <i
                                                    className={`sprite sprite-share-${grantee.grantee.type.toLowerCase()}`}
                                                />
                                            </span>
                                            <div>
                                                <p>{grantee.grantee.name}</p>
                                                <div className="d-flex align-items-center">
                                                    <small className="text-secondary">{grantee.grantee.username}</small>
                                                    <TooltipUserInfo
                                                        target={grantee.grantee}
                                                        id={grantee.grantee.externalId}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="status">{grantee.action[0]}</div>
                                    </li>
                                ),
                                approvers,
                            )}
                        {/* 
                                    <li>
                                        <div className="info">
                                            <span>
                                                <i className="sprite sprite-share-team" />
                                            </span>
                                            <div>
                                                <p>name</p>
                                                <div className="d-flex align-items-center">
                                                    <i className="sprite sprite-users-primary" />
                                                    <small className="text-primary2">0</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="status">Downloader</div>
                                    </li> */}
                    </ul>
                </div>
            </ScrollArea>
        </React.Fragment>
    );
};

export default SharedWithTab;
