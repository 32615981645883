import { handleActions, createAction } from "redux-actions";
import getFilesInS3 from "@cores/getFilesInS3";
import { axios } from "@cores/axiosWrapper";
import uniqid from "uniqid";
import moment from "moment";
import fileUpload from "@cores/fileUpload";
import fileToJobInputConverter from "@cores/fileToJobInputConverter";
import { CONSTANTS, VOD_MIMETYPE, UPLOAD_FILE_LOCATION_TYPE, JOB_ID_LCOAL_STORAGE_KEY } from "@/constants";
import addJobStatusPolling from "@cores/addJobStatusPolling";
import * as jobStatus from "@cores/jobStatus";
import compareVersions, {
    VERSION_CM_10226_VIDEO_MULTIPART_UPLOAD,
    VERSION_CM_7510_SAFARI_ENCODING,
} from "@cores/version";
import { addPausedPollingApis } from "./pausedPollingApis";
import upload from "@cores/cpmUploadService";

function getPipelineAPI(stageId, apiEndpoint, projectId, id) {
    return axios.get(`${apiEndpoint}/pipelines/${id}`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function getProfileAPI(stageId, apiEndpoint, projectId, id) {
    return axios.get(`${apiEndpoint}/profiles/${id}`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function createJobAPI(stageId, stageVersion, apiEndpoint, projectId, data) {
    let headers = {
        stageId,
        projectId,
    };

    if (compareVersions(stageVersion, VERSION_CM_7510_SAFARI_ENCODING) >= 0) {
        data = Buffer.from(encodeURIComponent(JSON.stringify(data))).toString("base64");
        headers = {
            ...headers,
            "x-mzc-content-encoding": "base64",
        };
    }

    return axios.post(`${apiEndpoint}/jobs`, data, { headers });
}

function updateJobAPI(stageId, stageVersion, apiEndpoint, projectId, id, cancelToken, data) {
    let headers = {
        stageId,
        projectId,
    };

    if (compareVersions(stageVersion, VERSION_CM_7510_SAFARI_ENCODING) >= 0) {
        data = Buffer.from(encodeURIComponent(JSON.stringify(data))).toString("base64");
        headers = {
            ...headers,
            "x-mzc-content-encoding": "base64",
        };
    }

    return axios.put(`${apiEndpoint}/jobs/${id}`, data, {
        cancelToken: cancelToken,
        headers,
    });
}

function transcodeAPI(stageId, apiEndpoint, projectId, id, params) {
    return axios.put(`${apiEndpoint}/jobs/${id}/transcoding`, null, {
        params,
        headers: {
            stageId,
            projectId,
        },
    });
}

function cancelTranscodeAPI(stageId, apiEndpoint, projectId, id) {
    return axios.put(`${apiEndpoint}/jobs/${id}/cancel`, null, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function getJobsAPI(stageId, apiEndpoint, projectId, params) {
    return axios.get(`${apiEndpoint}/jobs`, {
        params,
        headers: {
            stageId,
            projectId,
        },
    });
}

function getJobAPI(stageId, apiEndpoint, projectId, id) {
    return axios.get(`${apiEndpoint}/jobs/${id}`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function getSettingAPI(stageId, apiEndpoint, projectId) {
    return axios.get(`${apiEndpoint}/settings`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function getUploadUrlAPI(stageId, apiEndpoint, projectId, pipelineId, fileName) {
    return axios.get(`${apiEndpoint}/jobs/upload-url`, {
        headers: {
            stageId,
            projectId,
        },
        params: {
            pipelineId,
            fileName,
        },
    });
}

function getAssetAPI(stageId, apiEndpoint, projectId, id) {
    // TODO: Module쪽을 호출해서 사용하도록 수정
    return axios.get(`${apiEndpoint}/assets/${id}`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

export const UPLOAD_PENDING = "UPLOAD_PENDING";
export const UPLOAD_FAILURE = "UPLOAD_FAILURE";
export const UPLOAD_RESET = "UPLOAD_RESET";
export const UPLOAD_UPDATE_STATE = "UPLOAD_UPDATE_STATE";

export const UPLOAD_PIPELINE_PENDING = "UPLOAD_PIPELINE_PENDING";
export const UPLOAD_PIPELINE_FAILURE = "UPLOAD_PIPELINE_FAILURE";
export const UPLOAD_GET_PIPELINE_SUCCESS = "UPLOAD_GET_PIPELINE_SUCCESS";

export const UPLOAD_PROFILE_PENDING = "UPLOAD_PROFILE_PENDING";
export const UPLOAD_PROFILE_FAILURE = "UPLOAD_PROFILE_FAILURE";
export const UPLOAD_GET_PROFILE_SUCCESS = "UPLOAD_GET_PROFILE_SUCCESS";

export const UPLOAD_CREATE_JOB_SUCCESS = "UPLOAD_CREATE_JOB_SUCCESS";
export const UPLOAD_UPDATE_JOB_SUCCESS = "UPLOAD_UPDATE_JOB_SUCCESS";
export const UPLOAD_GET_COPY_TARGET_JOB_SUCCESS = "UPLOAD_GET_COPY_TARGET_JOB_SUCCESS";
export const UPLOAD_GET_COPY_JOB_BY_ASSET_SUCCESS = "UPLOAD_GET_COPY_JOB_BY_ASSET_SUCCESS";

export const UPDATE_SELECTED_FILES = "UPDATE_SELECTED_FILES";
export const UPLOAD_ADD_SELECTED_FILES = "UPLOAD_ADD_SELECTED_FILES";
export const UPLOAD_REMOVE_SELECTED_FILE = "UPLOAD_REMOVE_SELECTED_FILE";

export const UPLOAD_JOB_LIST_UPDATE_SEARCH = "UPLOAD_JOB_LIST_UPDATE_SEARCH";
export const UPLOAD_JOB_LIST_UPDATE_DATA = "UPLOAD_JOB_LIST_UPDATE_DATA";
export const UPLOAD_JOB_LIST_UPDATE_DATA_BY_POLLING = "UPLOAD_JOB_LIST_UPDATE_DATA_BY_POLLING";
export const UPLOAD_JOB_LIST_UPDATE_INPUT_FILE = "UPLOAD_JOB_LIST_UPDATE_INPUT_FILE";
export const UPLOAD_JOB_LIST_ADD_STORED_JOBS = "UPLOAD_JOB_LIST_ADD_STORED_JOBS";

export const UPLOAD_CANCEL_TRANSCODE_SOURCE = "UPLOAD_CANCEL_TRANSCODE_SOURCE";

export const UPLOAD_METADATA_UPDATE = "UPLOAD_METADATA_UPDATE";

export const UPLOAD_GET_TRANSCODING_SETTING_SUCCESS = "UPLOAD_GET_TRANSCODING_SETTING_SUCCESS";

const pendingTranscodeJobsQueue = [];

const initialState = {
    pending: false,
    error: false,
    selectedFiles: [],
    copyTargetJob: null,
    isCreateOriginAsset: true,
    createOriginAssetSetting: null,
    pollingCancelTokenSource: null,
    jobs: {
        data: [],
        search: {
            status: CONSTANTS("JOB_STATUS").map((v) => v.value),
        },
    },
    pipeline: {
        data: null,
        pending: false,
        error: false,
    },
    profile: {
        data: null,
        pending: false,
        error: false,
    },
};

export default handleActions(
    {
        [UPLOAD_PENDING]: (state, action) => {
            return {
                ...state,
                pending: true,
                copyTargetJob: null,
            };
        },
        [UPLOAD_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [UPLOAD_RESET]: (state, action) => {
            return {
                ...initialState,
                jobs: {
                    ...state.jobs,
                },
            };
        },
        [UPLOAD_UPDATE_STATE]: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
        [UPLOAD_GET_PIPELINE_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            return {
                ...state,
                pipeline: {
                    data,
                    pending: false,
                },
            };
        },
        [UPLOAD_PIPELINE_PENDING]: (state) => {
            return {
                ...state,
                pipeline: {
                    ...state.pipeline,
                    data: null,
                    pending: true,
                },
            };
        },
        [UPLOAD_PIPELINE_FAILURE]: (state) => {
            return {
                ...state,
                pipeline: {
                    ...state.pipeline,
                    pending: false,
                    error: true,
                },
            };
        },
        [UPLOAD_GET_PROFILE_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                profile: {
                    data,
                    pending: false,
                },
            };
        },
        [UPLOAD_PROFILE_PENDING]: (state, action) => {
            return {
                ...state,
                profile: {
                    ...state.profile,
                    pending: true,
                },
            };
        },
        [UPLOAD_PROFILE_FAILURE]: (state) => {
            return {
                ...state,
                profile: {
                    data: null,
                    pending: false,
                    error: true,
                },
            };
        },
        [UPLOAD_CREATE_JOB_SUCCESS]: (state, action) => {
            const { data, cancelTokenSource } = action.payload;

            return {
                ...state,
                jobs: {
                    ...state.jobs,
                    data: [
                        {
                            ...data,
                            cancelTokenSource,
                            isFolded: true,
                            inputFiles: state.selectedFiles,
                        },
                    ].concat(state.jobs.data),
                },
                isCreateOriginAsset: !state.createOriginAssetSetting || state.createOriginAssetSetting.value === "true",
                selectedFiles: [],
            };
        },
        [UPLOAD_UPDATE_JOB_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                jobs: {
                    ...state.jobs,
                    data: state.jobs.data.map((v) => {
                        if (v.id === data.id) {
                            return { ...v, ...data };
                        }

                        return v;
                    }),
                },
            };
        },
        [UPLOAD_GET_TRANSCODING_SETTING_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const createOriginAssetSetting =
                data.Transcoding && data.Transcoding.createOriginAsset && data.Transcoding.createOriginAsset;
            return {
                ...state,
                isCreateOriginAsset: (createOriginAssetSetting && createOriginAssetSetting.value === "true") || false,
                createOriginAssetSetting,
            };
        },
        [UPLOAD_GET_COPY_TARGET_JOB_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                copyTargetJob: {
                    ...data,
                    metadata: {
                        uniqKey: uniqid(),
                        ...data.metadata,
                    },
                },
            };
        },
        [UPLOAD_GET_COPY_JOB_BY_ASSET_SUCCESS]: (state, action) => {
            const { data, selectedFiles } = action.payload;

            return {
                ...state,
                copyTargetJob: {
                    ...data,
                    metadata: {
                        uniqKey: uniqid(),
                        name: data.name,
                        attributions: data.attributions,
                        categories: data.categories,
                        tags: data.tags,
                    },
                },
                selectedFiles,
            };
        },
        [UPLOAD_CANCEL_TRANSCODE_SOURCE]: (state, action) => {
            const { id } = action.payload;

            return {
                ...state,
                jobs: {
                    ...state.jobs,
                    data: state.jobs.data.map((v) => {
                        if (v.id === id) {
                            if (v.cancelTokenSource) {
                                v.cancelTokenSource.cancel();
                            }
                        }

                        return v;
                    }),
                },
            };
        },
        [UPDATE_SELECTED_FILES]: (state, action) => {
            return {
                ...state,
                selectedFiles: action.payload,
            };
        },
        [UPLOAD_ADD_SELECTED_FILES]: (state, action) => {
            return {
                ...state,
                selectedFiles: state.selectedFiles.concat(
                    action.payload.map((f, i) => {
                        f.index = state.selectedFiles.length + i;
                        f.mimeType = f.type;
                        f.isRearrangeTarget = false;
                        f.uploadedPercentage = 0;

                        return f;
                    }),
                ),
            };
        },
        [UPLOAD_REMOVE_SELECTED_FILE]: (state, action) => {
            return {
                ...state,
                selectedFiles: state.selectedFiles.filter((v, i) => i !== action.payload),
            };
        },
        [UPLOAD_JOB_LIST_UPDATE_SEARCH]: (state, action) => {
            return {
                ...state,
                jobs: {
                    ...state.jobs,
                    search: {
                        ...state.jobs.search,
                        ...action.payload,
                    },
                },
            };
        },
        [UPLOAD_JOB_LIST_UPDATE_DATA]: (state, action) => {
            const data = action.payload;

            return {
                ...state,
                jobs: {
                    ...state.jobs,
                    data: state.jobs.data.map((j) => {
                        if (j.id === data.id) {
                            return { ...j, ...data };
                        }

                        return j;
                    }),
                },
            };
        },
        [UPLOAD_JOB_LIST_UPDATE_DATA_BY_POLLING]: (state, action) => {
            const data = state.jobs.data.map((j) => {
                const targets = action.payload.filter((v) => v.id === j.id);

                const target = targets[0];
                if (target && jobStatus.getValuesLowerThan(j.status).indexOf(target.status) < 0) {
                    return { ...j, ...target };
                }

                return j;
            });

            localStorage.setItem(
                JOB_ID_LCOAL_STORAGE_KEY,
                data
                    .filter(
                        (v) =>
                            v.status === jobStatus.values.SUBMITTED ||
                            v.status === jobStatus.values.PROGRESSING ||
                            v.status === jobStatus.values.INGESTING,
                    )
                    .map((v) => v.id)
                    .join(","),
            );

            return {
                ...state,
                jobs: {
                    ...state.jobs,
                    data,
                },
            };
        },
        [UPLOAD_JOB_LIST_UPDATE_INPUT_FILE]: (state, action) => {
            const data = action.payload;

            return {
                ...state,
                jobs: {
                    ...state.jobs,
                    data: state.jobs.data.map((j) => {
                        if (j.id === data.jobId) {
                            j.inputFiles = j.inputFiles.map((f, i) => {
                                if (i == data.index) {
                                    f.uploadedPercentage = data.uploadedPercentage;
                                }

                                return f;
                            });

                            return { ...j, ...data };
                        }

                        return j;
                    }),
                },
            };
        },
        [UPLOAD_JOB_LIST_ADD_STORED_JOBS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                jobs: {
                    ...state.jobs,
                    data: data.jobs.map((v) => {
                        v.cancelTokenSource = axios._cancelToken.source();

                        if (v.inputs) {
                            let inputFiles = [];
                            let index = 0;

                            v.inputs.forEach((input) => {
                                if (input.video) {
                                    inputFiles = inputFiles.concat(convertToInputFiles(index, input.video));
                                    index = index + input.video?.urls?.length;
                                }

                                if (input.audios) {
                                    input.audios.forEach((audio) => {
                                        inputFiles = inputFiles.concat(convertToInputFiles(audio));
                                        index = index + audio?.urls?.length;
                                    });
                                }
                            });

                            v.inputFiles = inputFiles;
                            v.isFolded = true;
                        }

                        return v;
                    }),
                },
            };

            function convertToInputFiles(index, input) {
                if (!input.urls) {
                    return [];
                }

                return input.urls.map((url) => {
                    return {
                        index: index,
                        mimeType: input.mimeType,
                        name: url.substring(url.lastIndexOf("/") + 1, url.length),
                        url: url,
                        size: input.size,
                    };
                });
            }
        },
    },
    initialState,
);

export const reset = createAction(UPLOAD_RESET);
export const updateState = createAction(UPLOAD_UPDATE_STATE);
export const addSelectedFiles = createAction(UPLOAD_ADD_SELECTED_FILES);
export const updateSelectedFiles = createAction(UPDATE_SELECTED_FILES);
export const removeSelectedFile = createAction(UPLOAD_REMOVE_SELECTED_FILE);
export const updateJobListSearch = createAction(UPLOAD_JOB_LIST_UPDATE_SEARCH);
export const updateJobListData = createAction(UPLOAD_JOB_LIST_UPDATE_DATA);
export const updateJobListInputFile = createAction(UPLOAD_JOB_LIST_UPDATE_INPUT_FILE);

export const setIsCreateOriginAssetDefaultValue = () => (dispatch, getState) => {
    const { stage, project } = getState();

    return getSettingAPI(stage.id, stage.endpoint, project.id)
        .then((response) => {
            dispatch({
                type: UPLOAD_GET_TRANSCODING_SETTING_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: UPLOAD_FAILURE,
                payload: error,
            });
        });
};

export const startJobStatusPolling = () => (dispatch, getState) => {
    const { stage, project, upload } = getState();

    if (upload.pollingCancelTokenSource) {
        upload.pollingCancelTokenSource.cancel();
    }

    const cancelTokenSource = axios._cancelToken.source();
    dispatch({
        type: UPLOAD_UPDATE_STATE,
        payload: {
            pollingCancelTokenSource: cancelTokenSource,
        },
    });

    addJobStatusPolling({
        params: {
            apiEndpoint: stage.endpoint,
            stageId: stage.id,
            projectId: project.id,
            jobs: upload.jobs.data,
            cancelToken: cancelTokenSource.token,
            targetStatus: [jobStatus.values.INGESTED, jobStatus.values.SUBMITTED, jobStatus.values.PROGRESSING],
        },
        successCallback: (data) => {
            if (data && data.jobs) {
                dispatch({
                    type: UPLOAD_JOB_LIST_UPDATE_DATA_BY_POLLING,
                    payload: data.jobs,
                });
            }
        },
        errorCallback: (error) => {
            if (!navigator.onLine) {
                dispatch(
                    addPausedPollingApis({
                        type: "TRANSCODE",
                        params: {
                            apiEndpoint: stage.endpoint,
                            stageId: stage.id,
                            projectId: project.id,
                            jobs: upload.jobs.data,
                            targetStatus: [
                                jobStatus.values.INGESTED,
                                jobStatus.values.SUBMITTED,
                                jobStatus.values.PROGRESSING,
                            ],
                        },
                    }),
                );
            }
        },
    });
};

export const setCopyJob = (jobId) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: UPLOAD_PENDING });

    getJobAPI(stage.id, stage.endpoint, project.id, jobId)
        .then((response) => {
            dispatch({
                type: UPLOAD_GET_COPY_TARGET_JOB_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: UPLOAD_FAILURE,
                payload: error,
            });
        });
};

export const setJobByAsset = (assetId) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: UPLOAD_PENDING });

    getAssetAPI(stage.id, stage.endpoint, project.id, assetId)
        .then((assetResponse) => {
            const assetOriginUrl = assetResponse.data.originalUrls[0];

            getFilesInS3(stage.id, stage.endpoint, assetOriginUrl, project.id)
                .then((response) => {
                    // const name = assetOriginUrl.substring(assetOriginUrl.lastIndexOf("/") + 1, assetOriginUrl.length);
                    let displayName = assetOriginUrl.substring(
                        assetOriginUrl.lastIndexOf("/") + 1,
                        assetOriginUrl.length,
                    );
                    let name = assetResponse.data.name;
                    // if (name.lastIndexOf(".")) name = name.substring(0, name.lastIndexOf("."));
                    const selectedFile = {
                        displayName,
                        name,
                        url: assetOriginUrl,
                        locationType: UPLOAD_FILE_LOCATION_TYPE.S3,
                        size: Number(response.headers["s3-object-content-length"]),
                        type: response.headers["s3-object-content-type"],
                        index: 0,
                        mimeType: response.headers["s3-object-content-type"],
                        uploadedPercentage: 0,
                        remoteUrl: assetOriginUrl,
                    };

                    dispatch({
                        type: UPLOAD_GET_COPY_JOB_BY_ASSET_SUCCESS,
                        payload: {
                            data: assetResponse.data,
                            selectedFiles: [selectedFile],
                        },
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: UPLOAD_FAILURE,
                        payload: error,
                    });
                });
        })
        .catch((error) => {
            dispatch({
                type: UPLOAD_FAILURE,
                payload: error,
            });
        });
};

export const updatePipeline = (pipelineId) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: UPLOAD_PIPELINE_PENDING });

    getPipelineAPI(stage.id, stage.endpoint, project.id, pipelineId)
        .then((response) => {
            dispatch({
                type: UPLOAD_GET_PIPELINE_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: UPLOAD_PIPELINE_FAILURE,
                payload: error,
            });
        });
};

export const updateProfile = (profileId) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: UPLOAD_PROFILE_PENDING });

    getProfileAPI(stage.id, stage.endpoint, project.id, profileId)
        .then((response) => {
            dispatch({
                type: UPLOAD_GET_PROFILE_SUCCESS,
                payload: response,
            });
        })
        .catch((error) => {
            dispatch({
                type: UPLOAD_PROFILE_FAILURE,
                payload: error,
            });
        });
};

export const createJob = (data) => (dispatch, getState) => {
    const { stage, project } = getState();

    return new Promise((resolve, reject) => {
        createJobAPI(stage.id, stage.version, stage.endpoint, project.id, data)
            .then((response) => {
                const cancelTokenSource = axios._cancelToken.source();

                dispatch({
                    type: UPLOAD_CREATE_JOB_SUCCESS,
                    payload: {
                        ...response,
                        cancelTokenSource,
                    },
                });

                resolve({
                    ...response.data,
                    cancelTokenSource,
                });
            })
            .catch(() => {
                reject();
            });
    });
};

export const updateJob =
    (stageId, stageVersion, apiEndpoint, projectId, id, cancelToken, data) => (dispatch, getState) => {
        updateJobAPI(stageId, stageVersion, apiEndpoint, projectId, id, cancelToken, data)
            .then((response) => {
                dispatch({
                    type: UPLOAD_UPDATE_JOB_SUCCESS,
                    payload: response,
                });
            })
            .catch(() => {
                // empty
            });
    };

export const transcode = (stageId, apiEndpoint, projectId, jobId, options) => (dispatch, getState) => {
    transcodeAPI(stageId, apiEndpoint, projectId, jobId, options)
        .then(() => {
            // empty
        })
        .catch((error) => {
            // empty
        });
};

export const cancelTranscode = (jobId) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({
        type: UPLOAD_CANCEL_TRANSCODE_SOURCE,
        payload: {
            id: jobId,
        },
    });

    return new Promise((resolve, reject) => {
        cancelTranscodeAPI(stage.id, stage.endpoint, project.id, jobId)
            .then((response) => {
                dispatch({
                    type: UPLOAD_JOB_LIST_UPDATE_DATA,
                    payload: response.data,
                });

                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const cancelTranscodeAllSync = (jobs) => (dispatch, getState) => {
    const { stage, project, token } = getState();

    if (!jobs || jobs.length === 0) {
        return;
    }

    const apiEndpoint = stage.endpoint;
    // unload시 axios가 sync로 동작하지 않아 요아이만 이렇게 처리
    jobs.forEach((v) => {
        try {
            var request = new XMLHttpRequest();
            request.open("PUT", `${apiEndpoint}/jobs/${v.id}/cancel`, false);
            request.setRequestHeader("projectId", project.id);
            request.setRequestHeader("Authorization", token.value);
            request.send(null);
        } catch(e) {
            // ignored
        }
    });
};

export const addStoredJobs = (params) => (dispatch, getState) => {
    const { stage, project } = getState();

    getJobsAPI(stage.id, stage.endpoint, project.id, { ...params, hasAssociations: true })
        .then((response) => {
            dispatch({
                type: UPLOAD_JOB_LIST_ADD_STORED_JOBS,
                payload: response,
            });

            startJobStatusPolling()(dispatch, getState);
        })
        .catch((error) => {
            // ToDO
        });
};

export const getUploadUrl = (pipelineId, fileName) => (dispatch, getState) => {
    const { stage, project } = getState();

    return new Promise((resolve, reject) => {
        getUploadUrlAPI(stage.id, stage.endpoint, project.id, pipelineId, fileName)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const enqueueTranscodeJob = (job, params) => (dispatch, getState) => {
    pendingTranscodeJobsQueue.push({
        job,
        params,
    });
    run(dispatch);
};

let isRunning = false;

async function run(dispatch) {
    if (isRunning) return;
    isRunning = true;
    let transcodeJob = pendingTranscodeJobsQueue.shift();
    while (transcodeJob) {
        try {
            await transcodeAsync(transcodeJob.job, transcodeJob.params, dispatch);
        } catch (err) {
            console.error(`failed to transcode job`, err);
        }
        transcodeJob = pendingTranscodeJobsQueue.shift();
    }
    isRunning = false;
}

async function transcodeAsync(job, params, dispatch) {
    const { stageId, stageVersion, apiEndpoint, projectId, pipelineId, selectedFiles, inputPath, isCreateOriginAsset } =
        params;
    // TODO: S3 이외의 다른 LOCATION_TYPE 고려 필요
    const uploadTargetFiles = [];
    const copyTargetUrls = [];

    // TODO: 에러 메시지 개선 - 단계별로 에러 발생시 errorMessage 넣어서 update job 하도록 개선

    await Promise.all(
        selectedFiles.map((file) => {
            if (file.locationType === UPLOAD_FILE_LOCATION_TYPE.LOCAL) {
                uploadTargetFiles.push(file);
            } else {
                copyTargetUrls.push({
                    locationType: file.locationType,
                    url: file.remoteUrl,
                });
            }

            if (file.audios) {
                file.audios.map((audio) => {
                    if (audio.locationType === UPLOAD_FILE_LOCATION_TYPE.LOCAL) {
                        uploadTargetFiles.push(audio);
                    } else {
                        copyTargetUrls.push({
                            locationType: audio.locationType,
                            url: audio.remoteUrl,
                        });
                    }
                });
            }
        }),
    );

    try {
        // 2. INGESTING(local file upload)
        const uploadFilesLength = uploadTargetFiles.length;
        if (uploadFilesLength > 0) {
            await updateJobAPI(stageId, stageVersion, apiEndpoint, projectId, job.id, job.cancelTokenSource.token, {
                status: jobStatus.values.INGESTING,
            });
        }

        const jobId = job.id;
        const cancelToken = job.cancelTokenSource.token;
        for (let i = 0; i < uploadFilesLength; i++) {
            const file = uploadTargetFiles[i];

            try {
                if (compareVersions(stageVersion, VERSION_CM_10226_VIDEO_MULTIPART_UPLOAD) < 0) {
                    const response = await new Promise((resolve, reject) => {
                        getUploadUrlAPI(stageId, apiEndpoint, projectId, pipelineId, file.displayName)
                            .then((response) => {
                                resolve(response);
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    });
                    file.uploadUrl = response.data && response.data.uploadUrl;
                    file.url = response.data && response.data.url;

                    await new Promise((resolve, reject) => {
                        fileUpload({
                            stageId,
                            projectId,
                            file,
                            cancelToken,
                            onUploadProgress: (event) => {
                                if (event.loaded && event.total) {
                                    dispatch({
                                        type: UPLOAD_JOB_LIST_UPDATE_INPUT_FILE,
                                        payload: {
                                            jobId,
                                            index: i,
                                            uploadedPercentage: Math.round((event.loaded * 100) / event.total),
                                        },
                                    });
                                }
                            },
                            onComplete: () => {
                                resolve();
                            },
                            onCancel: () => {
                                reject("CANCELED");
                            },
                            onError: (err) => {
                                reject(err);
                            },
                        });
                    });
                } else {
                    await new Promise((resolve, reject) => {
                        upload(
                            file,
                            {
                                // params
                                stageVersion,
                                apiEndpoint,
                                resourceId: jobId,
                                resourceType: "JOBS",
                                projectId,
                            },
                            cancelToken,
                            (event) => {
                                if (event.loaded && event.total) {
                                    dispatch({
                                        type: UPLOAD_JOB_LIST_UPDATE_INPUT_FILE,
                                        payload: {
                                            jobId,
                                            index: i,
                                            uploadedPercentage: Math.round((event.loaded * 100) / event.total),
                                        },
                                    });
                                }
                            },
                            ({ destinationUrl }) => {
                                file.url = destinationUrl;
                                resolve();
                            },
                            () => {
                                reject("CANCELED");
                            },
                            (err) => {
                                reject(err);
                            },
                        );
                    });
                }
            } catch (e) {
                if (e === "CANCELED") return;
                console.error(`Upload failed - ${e.message}`);
                throw new Error(`Upload failed-${e.message}`);
            }
        }

        // 3. INGESTING(s3 file copy)
        if (copyTargetUrls.length > 0) {
            const convertedInputs = fileToJobInputConverter.convert(selectedFiles);
            await updateJobAPI(stageId, stageVersion, apiEndpoint, projectId, job.id, job.cancelTokenSource.token, {
                status: jobStatus.values.INGESTING,
                // hash 값 때문에 한번 더 업데이트
                inputs: convertedInputs,
                inputPath: inputPath,
                remoteUrls: copyTargetUrls,
                isCreateOriginAsset: isCreateOriginAsset,
            });
        } else {
            // 3. INGESTED (after local file upload)
            await updateJobAPI(stageId, stageVersion, apiEndpoint, projectId, job.id, job.cancelTokenSource.token, {
                status: jobStatus.values.INGESTED,
                // hash 값 때문에 한번 더 업데이트
                inputs: fileToJobInputConverter.convert(selectedFiles),
            });
            transcodeAPI(stageId, apiEndpoint, projectId, job.id, {
                isCreateOriginAsset: isCreateOriginAsset,
            });
        }
    } catch (err) {
        if (job && job.cancelTokenSource) job.cancelTokenSource.cancel();
        updateJobAPI(stageId, stageVersion, apiEndpoint, projectId, job.id, null, {
            status: "ERROR",
            errorMessage: err.message,
            completedAt: moment().utc(),
        });
    }
}

export const setJobByUrl = (url) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: UPLOAD_PENDING });

    const decodedUrl = decodeURIComponent(url).replace("+", " ");
    let displayName = decodedUrl.substring(decodedUrl.lastIndexOf("/") + 1, decodedUrl.length);
    let name = displayName;
    if (name.lastIndexOf(".")) name = name.substring(0, name.lastIndexOf("."));
    const selectedFile_ = {
        displayName,
        name,
        url: decodedUrl,
        remoteUrl: decodedUrl,
        locationType: UPLOAD_FILE_LOCATION_TYPE.S3,
    };

    const validationError = validateSelectedFile({ url, name: decodedUrl }, null);
    if (validationError !== null) {
        dispatch(updateSelectedFiles([{ ...selectedFile_, validationError }]));
        return;
    }

    getFilesInS3(stage.id, stage.endpoint, decodedUrl, project.id)
        .then((response) => {
            const selectedFile = {
                ...selectedFile_,
                size: Number(response.headers["s3-object-content-length"]),
                type: response.headers["s3-object-content-type"],
                index: 0,
                mimeType: response.headers["s3-object-content-type"],
                uploadedPercentage: 0,
            };

            dispatch(
                updateState({
                    name: selectedFile.url,
                    selectedFiles: [selectedFile],
                    copyTargetJob: {
                        metadata: {
                            uniqKey: uniqid(),
                            name: selectedFile.name,
                        },
                    },
                }),
            );
        })
        .catch((e) => {
            console.error(`error while trying to get content length`, e);
            let error = CONSTANTS("FILE_VALIDATE_ERROR_MESSAGE").INVALID_FILE;
            if (e.message.indexOf("400") > -1) {
                error = CONSTANTS("FILE_VALIDATE_ERROR_MESSAGE").NOT_ALLOWED_URL_PATTERN;
            } else if (e.message.indexOf("403") > -1) {
                error = CONSTANTS("FILE_VALIDATE_ERROR_MESSAGE").UNAUTHORIZED;
            }

            dispatch(
                updateSelectedFiles([
                    {
                        ...selectedFile_,
                        validationError: error,
                    },
                ]),
            );
        });

    function validateSelectedFile(file) {
        if (!file) {
            return null;
        }

        if (file.validationError) return file.validationError;

        const REGEXP_EXTENSION = new RegExp(`\.(${VOD_MIMETYPE.join("|")})$`, "i");
        if (!REGEXP_EXTENSION.test(file.name)) {
            return CONSTANTS("FILE_VALIDATE_ERROR_MESSAGE").NOT_ALLOWED_EXTENSION;
        }

        if (file.size <= 0) {
            return CONSTANTS("FILE_VALIDATE_ERROR_MESSAGE").INVALID_FILE;
        }

        return null;
    }
};
