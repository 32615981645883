const { axios } = require("@cores/axiosWrapper");
import fp from "lodash/fp";
import { handleActions, createAction } from "redux-actions";

const getRequestedResourceListAPI = (endpoint, params) => {
    return axios.get(`${endpoint}/v3/my/shared-resources`, { params });
};

const getDetailRequestedResourceAPI = (endpoint, id) => {
    return axios.get(`${endpoint}/v3/my/shared-resources/${id}`);
};

const getSharedResourceListAPI = (endpoint, params) => {
    return axios.get(`${endpoint}/v3/shared-resources`, { params });
};

const getDetailSharedResourceAPI = (endpoint, id) => {
    return axios.get(`${endpoint}/v3/shared-resources/${id}/grantees`);
};

const deleteSharedResourceAPI = (endpoint, id) => {
    return axios.delete(`${endpoint}/v3/shared-resources/${id}`);
};

const deleteResourceGranteesAPI = (endpoint, id, granteeId) => {
    return axios.delete(`${endpoint}/v3/shared-resources/${id}/grantees/${granteeId}`);
};

const changeResourceGranteesAPI = (endpoint, id, granteeId, data) => {
    return axios.patch(`${endpoint}/v3/shared-resources/${id}/grantees/${granteeId}`, data);
};

const addResourceGranteesAPI = (endpoint, id, data) => {
    return axios.put(`${endpoint}/v3/shared-resources/${id}/grantees`, data);
};

const deleteMyGranteeFromResourceAPI = (endpoint, id) => {
    return axios.delete(`${endpoint}/v3/my/shared-resources/${id}`);
};

const createShareResourceAPI = (endpoint, projectId, data) => {
    return axios.post(`${endpoint}/v3/shared-resources`, data, { headers: { projectId } });
};

const initialState = {
    requestedResources: {
        pending: true,
        error: null,
        results: null,
    },
    sharedResources: { pending: false, error: null, results: null },
    deleteResources: { pending: false, error: null },
    sharedResourcesDetail: { pending: false, error: null, results: null },
    createResource: { pending: false, error: null },
    deleteGrantees: { pending: false, error: null },
    changeGrantees: { pending: false, error: null },
    addGrantees: { pending: false, error: null },
    isShareModalOpen: false,
};

const GET_REQUESTED_RESOURCES_LIST_PENDING = "GET_REQUESTED_RESOURCES_LIST_PENDING";
const GET_REQUESTED_RESOURCES_LIST_SUCCESS = "GET_REQUESTED_RESOURCES_LIST_SUCCESS";
const GET_REQUESTED_RESOURCES_LIST_FAILURE = "GET_REQUESTED_RESOURCES_LIST_FAILURE";
const GET_DETAIL_REQUESTED_RESOURCE_PENDING = "GET_DETAIL_REQUESTED_RESOURCE_PENDING";
const GET_DETAIL_REQUESTED_RESOURCE_SUCCESS = "GET_DETAIL_REQUESTED_RESOURCE_SUCCESS";
const GET_DETAIL_REQUESTED_RESOURCE_FAILURE = "GET_DETAIL_REQUESTED_RESOURCE_FAILURE";
const GET_SHARED_RESOURCES_PENDING = "GET_SHARED_RESOURCES_PENDING";
const GET_SHARED_RESOURCES_SUCCESS = "GET_SHARED_RESOURCES_SUCCESS";
const GET_SHARED_RESOURCES_FAILURE = "GET_SHARED_RESOURCES_FAILURE";
const GET_DETAIL_SHARED_RESOURCES_PENDING = "GET_DETAIL_SHARED_RESOURCES_PENDING";
const GET_DETAIL_SHARED_RESOURCES_SUCCESS = "GET_DETAIL_SHARED_RESOURCES_SUCCESS";
const GET_DETAIL_SHARED_RESOURCES_FAILURE = "GET_DETAIL_SHARED_RESOURCES_FAILURE";
const DELETE_SHARED_RESOURCES_PENDING = "DELETE_SHARED_RESOURCES_PENDING";
const DELETE_SHARED_RESOURCES_SUCCESS = "DELETE_SHARED_RESOURCES_SUCCESS";
const DELETE_SHARED_RESOURCES_FAILURE = "DELETE_SHARED_RESOURCES_FAILURE";
const ADD_RESOURCE_GRANTEES_PENDING = "ADD_RESOURCE_GRANTEES_PENDING";
const ADD_RESOURCE_GRANTEES_SUCCESS = "ADD_RESOURCE_GRANTEES_SUCCESS";
const ADD_RESOURCE_GRANTEES_FAILURE = "ADD_RESOURCE_GRANTEES_FAILURE";
const DELETE_RESOURCES_GRANTEES_PENDING = "DELETE_RESOURCE_GRANTEES_PENDING";
const DELETE_RESOURCES_GRANTEES_SUCCESS = "DELETE_RESOURCE_GRANTEES_SUCCESS";
const DELETE_RESOURCES_GRANTEES_FAILURE = "DELETE_RESOURCE_GRANTEES_FAILURE";
const CHANGE_RESOURCES_GRANTEES_PENDING = "CHANGE_RESOURCE_GRANTEES_PENDING";
const CHANGE_RESOURCES_GRANTEES_SUCCESS = "CHANGE_RESOURCE_GRANTEES_SUCCESS";
const CHANGE_RESOURCES_GRANTEES_FAILURE = "CHANGE_RESOURCE_GRANTEES_FAILURE";
const TOGGLE_SHARE_MODAL_OPEN = "TOGGLE_SHARE_MODAL_OPEN";

const cloneInitialState = fp.cloneDeep(initialState);

export default handleActions(
    {
        [GET_REQUESTED_RESOURCES_LIST_PENDING]: (state, action) => {
            return { ...state, requestedResources: { ...state.requestedResources, pending: true } };
        },
        [GET_REQUESTED_RESOURCES_LIST_SUCCESS]: (state, action) => {
            return {
                ...state,
                requestedResources: { ...state.requestedResources, pending: false, results: action.payload },
            };
        },
        [GET_REQUESTED_RESOURCES_LIST_FAILURE]: (state, action) => {
            return {
                ...state,
                requestedResources: { ...state.requestedResources, pending: false, error: action.payload },
            };
        },
        [GET_DETAIL_REQUESTED_RESOURCE_PENDING]: (state) => {
            return { ...state, requestedResources: { ...state.requestedResources, pending: true } };
        },
        [GET_DETAIL_REQUESTED_RESOURCE_SUCCESS]: (state, action) => {
            const modifiedResources =
                state.requestedResources.result?.map((resource) => {
                    if (resource.id !== action.payload.id) {
                        return resource;
                    } else {
                        return action.payload;
                    }
                }) ?? [];

            return {
                ...state,
                requestedResources: { ...state.requestedResources, pending: false, results: modifiedResources },
            };
        },
        [GET_DETAIL_REQUESTED_RESOURCE_FAILURE]: (state) => {
            return {
                ...state,
                requestedResources: { ...state.requestedResources, pending: false },
            };
        },
        [GET_SHARED_RESOURCES_PENDING]: (state, action) => {
            return {
                ...state,
                sharedResources: { ...state.sharedResources, pending: true },
            };
        },
        [GET_SHARED_RESOURCES_SUCCESS]: (state, action) => {
            return {
                ...state,
                sharedResources: { ...state.sharedResources, pending: false, results: action.payload },
            };
        },
        [GET_SHARED_RESOURCES_FAILURE]: (state, action) => {
            return {
                ...state,
                sharedResources: { ...state.sharedResources, pending: false, error: action.payload },
            };
        },
        [GET_DETAIL_SHARED_RESOURCES_PENDING]: (state, action) => {
            return {
                ...state,
                sharedResourcesDetail: { ...state.sharedResourcesDetail, pending: true },
            };
        },
        [GET_DETAIL_SHARED_RESOURCES_SUCCESS]: (state, action) => {
            return {
                ...state,
                sharedResourcesDetail: {
                    ...state.sharedResourcesDetail,
                    pending: false,
                    results: action.payload,
                },
            };
        },
        [GET_DETAIL_SHARED_RESOURCES_FAILURE]: (state, action) => {
            return {
                ...state,
                sharedResourcesDetail: { ...state.sharedResourcesDetail, pending: false, error: action.payload },
            };
        },
        [DELETE_SHARED_RESOURCES_PENDING]: (state, action) => {
            return {
                ...state,
                deleteResources: { ...state.deleteResources, pending: true },
            };
        },
        [DELETE_SHARED_RESOURCES_SUCCESS]: (state, action) => {
            return {
                ...state,
                deleteResources: { ...state.deleteResources, pending: false },
            };
        },
        [DELETE_SHARED_RESOURCES_FAILURE]: (state, action) => {
            return {
                ...state,
                deleteResources: { ...state.deleteResources, pending: false, error: action.payload },
            };
        },
        [ADD_RESOURCE_GRANTEES_PENDING]: (state, action) => {
            return {
                ...state,
                addGrantees: { ...state.addGrantees, pending: true },
            };
        },
        [ADD_RESOURCE_GRANTEES_SUCCESS]: (state, action) => {
            return {
                ...state,
                addGrantees: { ...state.addGrantees, pending: false },
            };
        },
        [ADD_RESOURCE_GRANTEES_FAILURE]: (state, action) => {
            return {
                ...state,
                addGrantees: { ...state.addGrantees, pending: false, error: action.payload },
            };
        },
        [DELETE_RESOURCES_GRANTEES_PENDING]: (state, action) => {
            return {
                ...state,
                deleteGrantees: { ...state.deleteGrantees, pending: true },
            };
        },
        [DELETE_RESOURCES_GRANTEES_SUCCESS]: (state, action) => {
            return {
                ...state,
                deleteGrantees: { ...state.deleteGrantees, pending: false },
            };
        },
        [DELETE_RESOURCES_GRANTEES_FAILURE]: (state, action) => {
            return {
                ...state,
                deleteGrantees: { ...state.deleteGrantees, pending: false, error: action.payload },
            };
        },
        [CHANGE_RESOURCES_GRANTEES_PENDING]: (state) => {
            return {
                ...state,
                changeGrantees: { ...state.changeGrantees, pending: true },
            };
        },
        [CHANGE_RESOURCES_GRANTEES_SUCCESS]: (state) => {
            return {
                ...state,
                changeGrantees: { ...state.changeGrantees, pending: false },
            };
        },
        [CHANGE_RESOURCES_GRANTEES_FAILURE]: (state, action) => {
            return {
                ...state,
                changeGrantees: { ...state.changeGrantees, pending: false, error: action.payload },
            };
        },
        [TOGGLE_SHARE_MODAL_OPEN]: (state, action) => {
            return {
                ...state,
                isShareModalOpen: action.payload,
            };
        },
    },
    initialState,
);

export const toggleShareModalOpen = createAction(TOGGLE_SHARE_MODAL_OPEN);

export const getRequestedResourceList = (params) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: GET_REQUESTED_RESOURCES_LIST_PENDING });
    return new Promise((resolve, reject) => {
        getRequestedResourceListAPI(stage.endpoint, params)
            .then((response) => {
                const parsedSummaryResponses = response.data.results.map((file) => ({
                    ...file,
                    summary: JSON.parse(file.summary),
                }));

                dispatch({
                    type: GET_REQUESTED_RESOURCES_LIST_SUCCESS,
                    payload: parsedSummaryResponses,
                });
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                dispatch({ type: GET_REQUESTED_RESOURCES_LIST_FAILURE, payload: error });
                reject(error);
            });
    });
};

export const getDetailRequestedResource = (id) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: GET_DETAIL_REQUESTED_RESOURCE_PENDING });
    return new Promise((resolve, reject) => {
        getDetailRequestedResourceAPI(stage.endpoint, id)
            .then((response) => {
                const parseSummary = {
                    ...response.data,
                    summary: JSON.parse(response.data),
                };
                dispatch({ type: GET_DETAIL_REQUESTED_RESOURCE_SUCCESS, payload: parseSummary });
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                dispatch({ type: GET_DETAIL_REQUESTED_RESOURCE_FAILURE, payload: error });
                reject(error);
            });
    });
};

export const getSharedResourceList = (params) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: GET_SHARED_RESOURCES_PENDING });
    return new Promise((resolve, reject) => {
        getSharedResourceListAPI(stage.endpoint, params)
            .then((responses) => {
                const parsedSummaryResources = responses.data.results.map((item) => {
                    return { ...item, summary: JSON.parse(item.summary) };
                });

                dispatch({
                    type: GET_SHARED_RESOURCES_SUCCESS,
                    payload: parsedSummaryResources,
                });
            })
            .catch((error) => {
                dispatch({ type: GET_SHARED_RESOURCES_FAILURE, payload: error });
                reject(error);
            });
    });
};

export const getDetailSharedResource = (id) => async (dispatch, getState) => {
    try {
        const { stage } = getState();

        let results = [];
        let nextToken = null;

        dispatch({ type: GET_DETAIL_SHARED_RESOURCES_PENDING });
        do {
            const response = await getDetailSharedResourceAPI(stage.endpoint, id, nextToken);
            results = results.concat(response.data.results);
            nextToken = response.data.nextToken;
        } while (!!nextToken);

        dispatch({ type: GET_DETAIL_SHARED_RESOURCES_SUCCESS, payload: results });
        return results;
    } catch (e) {
        console.log(e);
        dispatch({ type: GET_DETAIL_SHARED_RESOURCES_FAILURE, payload: e });
    }
};

export const deleteSharedResource = (resourceID) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: DELETE_SHARED_RESOURCES_PENDING });
    return new Promise((resolve, reject) => {
        deleteSharedResourceAPI(stage.endpoint, resourceID)
            .then((response) => {
                dispatch({ type: DELETE_SHARED_RESOURCES_SUCCESS, payload: response });
                resolve(response);
            })
            .catch((error) => {
                dispatch({ type: DELETE_SHARED_RESOURCES_FAILURE, payload: error });
                reject(error);
            });
    });
};

export const deleteResourceGrantees =
    ({ id, granteeId }) =>
    (dispatch, getState) => {
        const { stage } = getState();

        dispatch({ type: DELETE_RESOURCES_GRANTEES_PENDING });
        return new Promise((resolve, reject) => {
            deleteResourceGranteesAPI(stage.endpoint, id, granteeId)
                .then((response) => {
                    dispatch({ type: DELETE_RESOURCES_GRANTEES_SUCCESS, payload: response });
                    resolve(response);
                })
                .catch((error) => {
                    dispatch({ type: DELETE_RESOURCES_GRANTEES_FAILURE, payload: error });
                    reject(error);
                });
        });
    };

export const changeResourceGrantees =
    ({ id, granteeId, ...rest }) =>
    (dispatch, getState) => {
        const { stage } = getState();

        dispatch({ type: CHANGE_RESOURCES_GRANTEES_PENDING });
        return new Promise((resolve, reject) => {
            changeResourceGranteesAPI(stage.endpoint, id, granteeId, rest)
                .then((response) => {
                    dispatch({ type: CHANGE_RESOURCES_GRANTEES_SUCCESS, payload: response });
                    resolve(response);
                })
                .catch((error) => {
                    dispatch({ type: CHANGE_RESOURCES_GRANTEES_FAILURE, payload: error });
                    reject(error);
                });
        });
    };

export const addResourceGrantees =
    ({ id, data }) =>
    (dispatch, getState) => {
        const { stage } = getState();

        dispatch({ type: ADD_RESOURCE_GRANTEES_PENDING });
        return new Promise((resolve, reject) => {
            addResourceGranteesAPI(stage.endpoint, id, data)
                .then((response) => {
                    dispatch({ type: ADD_RESOURCE_GRANTEES_SUCCESS, payload: response });
                    resolve(response);
                })
                .catch((error) => {
                    dispatch({ type: ADD_RESOURCE_GRANTEES_FAILURE, payload: error });
                    reject(error);
                });
        });
    };

export const deleteMyGranteeFromResource =
    ({ id }) =>
    (dispatch, getState) => {
        const { stage } = getState();

        dispatch({ type: DELETE_RESOURCES_GRANTEES_PENDING });
        return new Promise((resolve, reject) => {
            deleteMyGranteeFromResourceAPI(stage.endpoint, id)
                .then((response) => {
                    dispatch({ type: DELETE_RESOURCES_GRANTEES_SUCCESS, payload: response });
                    resolve(response);
                })
                .catch((error) => {
                    dispatch({ type: DELETE_RESOURCES_GRANTEES_FAILURE, payload: error });
                    reject(error);
                });
        });
    };

export const createShareResource = (data) => (dispatch, getState) => {
    const { stage, project } = getState();

    dispatch({ type: DELETE_RESOURCES_GRANTEES_PENDING });
    return new Promise((resolve, reject) => {
        createShareResourceAPI(stage.endpoint, project.id, data)
            .then((response) => {
                dispatch({ type: DELETE_RESOURCES_GRANTEES_SUCCESS, payload: response });
                resolve(response);
            })
            .catch((error) => {
                dispatch({ type: DELETE_RESOURCES_GRANTEES_FAILURE, payload: error });
                reject(error);
            });
    });
};
