import React from "react";
import { HeaderForNoting } from "@components/header";
import { checkIsMobile } from "@/utils/checkmobile";
import { Toolbar } from "@mzc-pdc/ui";

const LayoutForNoting = ({ children }) => {
    const isMobile = checkIsMobile();
    return (
        <div className={"wrap wrap-gate" + (isMobile ? " is-mobile" : "")}>
            <HeaderForNoting />
            <div>
                <Toolbar />
                <div className={"container"}>
                    <div className={"main"}>
                        {/*TODO: MUI로 개선 필요*/}
                        <div className={"contents"} style={{ maxHeight: `calc(100vh - 56px)`, overflowY: "auto" }}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LayoutForNoting;
