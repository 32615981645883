import React, { useMemo, useRef } from "react";
import { Grid, TextField } from "@mzc-pdc/ui";
import { IconErrorCircle, IconWarningTriangle } from "@mzc-cloudplex/icons";
import { isNumber } from "lodash";
import theme from "@styles/theme";

const NumberTextField = ({ value, onChange, onKeyDown, error, helperText, isEdit, sx, ...rest }) => {
    const numberRef = useRef(false);

    const getEndAdornment = useMemo(() => {
        if (error === "warning") return <IconWarningTriangle color={"warning"} size={16} />;
        else if (error === "error") return <IconErrorCircle color={`error`} size={16} />;

        return undefined;
    }, [error]);

    return (
        <TextField
            fullWidth
            type={"number"}
            value={value !== "" && value !== null ? parseInt(value) : ""} //Note: value 사용 이유: defaultValue 사용시 input에 한글이나 일본어가 입력이 됨(값에 들어가진 않음)
            onChange={(e) => {
                if ((numberRef.current === true && e.target.value === "") || isFinite(parseInt(e.target.value))) {
                    onChange(parseInt(e.target.value));
                }
                numberRef.current = false;
            }}
            onKeyDown={(e) => {
                if (e.key === "Backspace" || e.key === "Delete") {
                    numberRef.current = true;
                }
            }}
            error={error === "error"}
            helperText={
                error &&
                helperText && (
                    <Grid container gap={1} alignItems={"center"}>
                        {getEndAdornment}
                        {helperText}
                    </Grid>
                )
            }
            sx={{
                ...sx,
                ...(isEdit && {
                    "& .MuiOutlinedInput-root": {
                        backgroundColor: `#fff`,
                        borderRadius: "2px",
                        "> fieldset": { borderColor: theme.palette.primary[400] },
                    },
                }),
            }}
            {...rest}
        />
    );
};

export default NumberTextField;
