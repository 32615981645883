import React, { Component } from "react";
import { withTranslation, Trans } from "react-i18next";

import { LayoutForNoting } from "../../components/layout";

class InvalidUser extends Component {
    render() {
        const { t, i18n } = this.props;

        return (
            <div class="content-wrapper error-page">
                <div class="error-invalid">
                    <i class="sprite sprite-error-big"></i>
                    <h1>{t(`common::msg::${"There is no project available to access."}`)}</h1>
                    <div class="card">
                        <div class="card-body">
                            <p class="message">
                                <Trans
                                    i18nKey="common::msg::If you have a problem with your account, or would like to use the cloud <0/>platform, please contact our sales team. We'll assist you with more details."
                                    components={[<br />]}
                                />
                            </p>
                            <div class="row contact">
                                <div class="col">
                                    <strong class="title">{t(`common::label::${"Cloud inquiry"}`)}</strong>
                                    <ul>
                                        <li class="dot">
                                            {t(`common::label::${"24 hour operation"}`)} : <strong>1644-2243</strong>
                                        </li>
                                        <li class="dot">
                                            {t(`common::label::${"Email"}`)} :{" "}
                                            <a href="mailto:cloud@megazone.com">cloud@megazone.com</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col">
                                    <strong class="title">{t(`common::label::${"Media service inquiry"}`)}</strong>
                                    <p class="dot">
                                        <a href="https://mz-cloudplex.zendesk.com">https://mz-cloudplex.zendesk.com</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(InvalidUser);
