import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import theme from "@styles/theme";
import { ASSET_PREVIEW_STATUS, MEDIA_TYPES, PUBLIC_URL } from "@constants";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardHeader,
    CardMedia,
    CircularProgress,
    Grid,
    IconButton,
    Stack,
    Typography,
} from "@mzc-pdc/ui";
import Tooltip from "@components_v2/Tooltip/tooltip";
import {
    IconCheckDefaultCircle,
    IconErrorCircle,
    IconRetry,
    ImageDefaultAudioDark,
    ImageDefaultAudioSmallDark,
    ImageDefaultFileDark,
    ImageDefaultFileSmallDark,
    ImageDefaultImageDark,
    ImageDefaultImageSmallDark,
    ImageDefaultTextDark,
    ImageDefaultTextSmallDark,
    ImageDefaultVideoDark,
    ImageDefaultVideoSmallDark,
} from "@mzc-cloudplex/icons";
import { useTranslation } from "react-i18next";
import formatDuration from "@cores/formatDuration";

const MediaTypeImage = ({
    mediaType,
    type,
    errorIconProps = { position: "left", size: 16 },
    thumbnailProps = { p: "2px 4px" },
    progressIconProps = { isTooltip: false },
    refreshButton = false,
    size = "large",
    isPreferred = false,
    thumbnailTime,
    src,
    previewStatus = "COMPLETE",
    draggable,
    variant,
    sx,
    width,
    height,
}) => {
    const { t } = useTranslation();
    const mediaType_ = mediaType && mediaType.toUpperCase();
    const [error, setError] = useState(false);

    const PreviewErrorIcon = () => {
        const errorIconSize = errorIconProps.size >= 24 ? "small" : "xsmall";
        if (errorIconProps.position === "left")
            return (
                <Tooltip title={t(`common::msg::Failed to load preview image. Please again.`)} maxWidth={201}>
                    <IconButton
                        size={"xsmall"}
                        circled
                        sx={{ position: "absolute", top: 0, left: 0 }}
                        onClick={() => {}}
                    >
                        <IconErrorCircle size={errorIconProps.size ?? 16} color={"error"} />
                    </IconButton>
                </Tooltip>
            );
        return (
            <Tooltip title={t(`common::msg::Failed to load preview image. Please again.`)} maxWidth={201}>
                <IconButton
                    size={errorIconSize}
                    circled
                    sx={{
                        position: "absolute",
                        top: `calc((100% - ${height}px)/2 - ${errorIconSize === "small" ? "12px" : "8px"})`,
                        right: `calc((100% - ${width}px)/2 - ${errorIconSize === "small" ? "12px" : "8px"})`,
                    }}
                    onClick={() => {}}
                >
                    <IconErrorCircle size={errorIconProps.size ?? 24} color={"error"} />
                </IconButton>
            </Tooltip>
        );
    };

    const DefaultIcon = ({ children }) => {
        let Component = null;
        if (variant === "table" || size === "small") {
            if (mediaType_ === MEDIA_TYPES.VIDEO) {
                Component = ImageDefaultVideoSmallDark;
            } else if (["MUSIC", MEDIA_TYPES.AUDIO].includes(mediaType_)) {
                Component = ImageDefaultAudioSmallDark;
            } else if ([MEDIA_TYPES.TEXT, MEDIA_TYPES.CAPTION].includes(mediaType_)) {
                Component = ImageDefaultTextSmallDark;
            } else if ([MEDIA_TYPES.IMAGE, "PHOTO", MEDIA_TYPES.PEOPLE].includes(mediaType_)) {
                Component = ImageDefaultImageSmallDark;
            } else if (mediaType_ === MEDIA_TYPES.FILE) {
                Component = ImageDefaultFileSmallDark;
            } else return null;
        } else {
            if (mediaType_ === MEDIA_TYPES.VIDEO) {
                Component = ImageDefaultVideoDark;
            } else if (["MUSIC", MEDIA_TYPES.AUDIO].includes(mediaType_)) {
                Component = ImageDefaultAudioDark;
            } else if ([MEDIA_TYPES.TEXT, MEDIA_TYPES.CAPTION].includes(mediaType_)) {
                Component = ImageDefaultTextDark;
            } else if ([MEDIA_TYPES.IMAGE, "PHOTO", MEDIA_TYPES.PEOPLE].includes(mediaType_)) {
                Component = ImageDefaultImageDark;
            } else if (mediaType_ === MEDIA_TYPES.FILE) {
                Component = ImageDefaultFileDark;
            } else return null;
        }

        return (
            <Box sx={{ position: "static" }}>
                <Component width={width} height={height} />
            </Box>
        );
    };

    const PreviewProgressIcon = () => {
        if (variant === "table" || size === "small" || progressIconProps.isTooltip)
            return (
                <Tooltip title={t(`common::msg::Preview image Creating...`)} maxWidth={110}>
                    <button>
                        <DefaultIcon />
                    </button>
                </Tooltip>
            );
        else
            return (
                <Stack direction={"column"} gap={theme.spacing(1)} justifyContent={"center"} alignItems={"center"}>
                    <DefaultIcon />
                    <Box
                        sx={{ backgroundColor: theme.palette.greySecondary[600] }}
                        px={theme.spacing(1.5)}
                        py={theme.spacing(0.25)}
                    >
                        <Typography color={theme.palette.common.white}>
                            {t(`common::msg::Preview image Creating...`)}
                        </Typography>
                    </Box>
                </Stack>
            );
    };
    const DefaultIconByPreviewStatus = () => {
        switch (previewStatus) {
            case ASSET_PREVIEW_STATUS.IN_PROGRESS:
                return <PreviewProgressIcon />;
            case ASSET_PREVIEW_STATUS.ERROR:
                return (
                    <>
                        <DefaultIcon />
                        <PreviewErrorIcon />
                    </>
                );
            case ASSET_PREVIEW_STATUS.NOT_SUPPORTED:
                return <DefaultIcon />;
            default:
                return <DefaultIcon />;
        }
    };

    return error ||
        src === null ||
        src === undefined ||
        (previewStatus !== undefined && previewStatus !== ASSET_PREVIEW_STATUS.COMPLETE) ? (
        <Box
            sx={{
                width: `100%`,
                height: `100%`,
                display: "flex",
                position: "static",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "2px",
            }}
        >
            {isPreferred && (
                <Grid
                    container
                    justifyContent={"space-between"}
                    sx={{
                        position: `absolute`,
                        left: 0,
                        top: 0,
                    }}
                    p={0.5}
                >
                    <IconCheckDefaultCircle size={16} htmlColor={theme.palette.success.main} />
                </Grid>
            )}
            {/*TODO: 백엔드 개발 후 반영 필요*/}
            {/*{refreshButton && previewStatus === "ERROR" && (*/}
            {/*    <Grid*/}
            {/*        container*/}
            {/*        justifyContent={"space-between"}*/}
            {/*        sx={{*/}
            {/*            position: `absolute`,*/}
            {/*            left: `calc(100% - 20px - ${theme.spacing(1)})`,*/}
            {/*            top: 0,*/}
            {/*        }}*/}
            {/*        p={0.5}*/}
            {/*    >*/}
            {/*        <IconButton*/}
            {/*            circled*/}
            {/*            variant={"outlined"}*/}
            {/*            sx={{ backgroundColor: theme.palette.common.white, width: 20, height: 20 }}*/}
            {/*        >*/}
            {/*            <IconRetry size={12} />*/}
            {/*        </IconButton>*/}
            {/*    </Grid>*/}
            {/*)}*/}
            {["IMAGE", "FILE"].includes(mediaType) ? <DefaultIconByPreviewStatus /> : <DefaultIcon />}
            {type === "THUMBNAILS" && (
                <Box
                    sx={{
                        position: `absolute`,
                        right: 0,
                        bottom: 0,
                        backgroundColor: theme.palette.greySecondary.main,
                        justifyContent: "center",
                        display: `flex`,
                        ...thumbnailProps,
                    }}
                >
                    <Typography
                        variant={size === "small" ? "body4_400" : "body3_400"}
                        component={"span"}
                        color={theme.palette.secondary.contrastText}
                    >
                        {formatDuration(thumbnailTime * 1000)}
                    </Typography>
                </Box>
            )}
        </Box>
    ) : (
        <CardMedia
            src={src}
            component={"img"}
            onError={() => {
                setError(true);
            }}
            draggable={draggable}
            sx={{
                width: `100%`,
                height: `100%`,
                backgroundSize: variant === "table" ? `cover` : `contain`,
                backgroundPosition: `center center`,
                display: `flex`,
                alignItems: `center`,
                justifyContent: `center`,
                objectFit: `contain`,
                ...sx,
            }}
        />
    );
};
MediaTypeImage.propTypes = {
    draggable: PropTypes.bool,
};
MediaTypeImage.defaultProps = {
    draggable: false,
};

export default MediaTypeImage;
