import {handleActions, createAction} from 'redux-actions';
import MusicApi from './apis/music';
import { LIST_VIEW_TYPE } from '../constants';

//action type
const MUSIC_LIST_PENDING = "MUSIC_LIST_PENDING";

const MUSIC_LIST_GET_SUCCESS = "MUSIC_LIST_GET_SUCCESS";
const MUSIC_LIST_GET_FAILURE = "MUSIC_LIST_GET_FAILURE";
const MUSIC_LIST_UPDATE_STATE = "MUSIC_LIST_UPDATE_STATE";
const MUSIC_LIST_STATUS_UPDATE = "MUSIC_LIST_STATUS_UPDATE";
const MUSIC_LIST_DELETE_SUCCESS = 'MUSIC_LIST_DELETE_SUCCESS';
const MUSIC_LIST_DELETE_FAILURE = 'MUSIC_LIST_DELETE_FAILURE';


//reducer
const initialState = {
    pending: false,
    error: false,
    activeView: LIST_VIEW_TYPE.LIST,
    isCardView : true,
    isSearched: false,
    data: [],
    totalCount : 0,
    contextVersion: 0,
    validationErrors : { 
        name : 'required' 
    },
}

let tempState ={
    ...initialState
}

export default handleActions({
    // [CLEAR]: (state, action)=> {
    //     tempState = {
    //         ...initialState,
    //         contextVersion: tempState.contextVersion + 1
    //     };
    //     return tempState;
    // },
    // [VIDEO_LIST_HIERARCHY_CLEAR]: (state, action) => {
    //     tempState = {
    //         ...tempState,
    //     };
    //     return tempState;
    // },
    [MUSIC_LIST_PENDING]: (state) => {
        console.log("MUSIC_LIST_PENDING ");
        
        const temp = {
            ...tempState,
            pending : true,
        };
        tempState = temp;
        return tempState;
    },
    [MUSIC_LIST_GET_SUCCESS]: (state, action) => {
        const { data } = action.payload;
        const temp = {
            ...tempState,
            pending : false,
            error : false,
            data : data.contents,
            totalCount : data.totalCount
        };
        tempState = temp;
        return tempState;
    },
    [MUSIC_LIST_UPDATE_STATE]: (state, action) => {
        console.log('state, action', state, action)
        const temp = {
            ...tempState,
            ...action.payload
        }
        tempState = temp;
        return tempState;
    },
    [MUSIC_LIST_DELETE_SUCCESS]: (state, action) => {
        const temp = {
            ...tempState,
            pending: false
        }
        tempState = temp;
        return tempState;
    },
    [MUSIC_LIST_DELETE_FAILURE]: (state, action) => {
        const temp = {
            ...tempState,
            pending: false,
            error: true
        }
        tempState = temp;
        return tempState;
    }
}, initialState)

export const updateState = createAction(MUSIC_LIST_UPDATE_STATE);

export const getMusicsAsync = (search) => (dispatch, getState) =>{
    const {
        stage,
        project        
    } = getState();

    dispatch({
        type: MUSIC_LIST_PENDING
    })

    return new Promise((resolve, reject) => {
        MusicApi.getMusicsAsync(stage.id, stage.endpoint, project.id, search)
            .then((response) => {
                dispatch({
                    type: MUSIC_LIST_GET_SUCCESS,
                    payload: response
                });

                // const musics = response.data;
                // for (const music of musics) {
                //     const shouldPollStatus = music.status === 'ACTIVATING' || music.status === 'INACTIVATING';
                //     if(!shouldPollStatus)
                //         continue;

                //     const targetStatus = music.status === 'ACTIVATING' ? 'ACTIVE' : 'INACTIVE';
                //     new Polling(async () => {
                //         return await VideoApi.getStatusAsync(stageId, apiEndpoint, targetProjectId, music.id);
                //     }, (response) => {                        
                //         return getState().musics.contextVersion !== contextVersion || response.data.status === targetStatus;
                //     }, (response) => {
                //         dispatch({
                //             type: MUSIC_LIST_STATUS_UPDATE,
                //             payload : response
                //         })
                //     }).run();
                // }

                resolve(response.data);
            })
            .catch((error) => {
                dispatch({
                    type: MUSIC_LIST_GET_FAILURE,
                    payload: error
                });

                reject(error);
            });
    })
}

export const deleteAsync = (id) => (dispatch, getState) =>{
    const {
        stage,
        project        
    } = getState();
    return MusicApi.deleteMusicAsync(stage.id, stage.endpoint, project.id, id)
    .then((response) => {
        dispatch({
            type: MUSIC_LIST_DELETE_SUCCESS,
            payload: {
                id
            }
        });
    })
    .catch((error) => {
        dispatch({
            type: MUSIC_LIST_DELETE_FAILURE,
            payload: error
        });
    });
}

export const updateBulkAsync = (payload) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    return MusicApi.updateBulkMusicAsync(stage.id, stage.endpoint, project.id, payload)
        .then((response) => {
            console.log(response);
        });
}
