import { useMemo, useRef, useState } from "react";
import { Box, Divider, Grid } from "@mzc-pdc/ui";
import { StyledListTools } from "./list-tools.styled";
import { useTranslation } from "react-i18next";
import TreeViewTools from "../tree-view-tools/tree-view-tools";
import { useDispatch, useSelector } from "react-redux";
import { LIST_VIEW_TYPE, TABLE_LIST_TYPE } from "@constants";
import ListToolCollapse from "src/features/list-tools/list-tool-collapse";
import ListToolViewToggleButton from "@features/list-tools/list-tool-view-toggle-button";
import compareVersions, { VERSION_CM_4721_SHARES } from "@cores/version";

const ListTools = ({
    activeView,
    domain,
    isCardView,
    hierarchyType,
    buttons = () => [],
    pagination,
    filter,
    sort,
    viewSelectButton,
    pageCount, //현재 페이지 내 총 항목 갯수
    numSelected,
    breadcrumbMenu,
    onClickFolderTree,
    onClickTreeItem,
    onToggleViewType,
    onChangeNumSelected,
    onToggleModal,
    totalView = false,
    totalCount = 0,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const listToolRef = useRef(null);

    const [toolSize, setToolSize] = useState(null);
    const stage = useSelector(({ stage }) => stage);

    const handleClearCheckAll = () => {
        onChangeNumSelected(false);
    };

    const handleChangeCheckAll = () => {
        onChangeNumSelected(pageCount !== numSelected);
    };

    const isCollapsed = useMemo(() => numSelected > 0, [numSelected]);

    const isUsingTreeView = useMemo(() => {
        return onClickTreeItem || onClickFolderTree;
    }, [onClickFolderTree, onClickTreeItem]);

    //Note: TreeView를 사용하는 경우와 하지 않는 경우 아래 버튼의 위치가 달라져야한다.
    const viewToggleButtons = useMemo(() => {
        if (
            [
                TABLE_LIST_TYPE.CONTENT_SCHEMA,
                TABLE_LIST_TYPE.VOCABULARY,
                TABLE_LIST_TYPE.ROLE_MANAGEMENT,
                TABLE_LIST_TYPE.USER_MANAGEMENT,
                TABLE_LIST_TYPE.TEAM_MANAGEMENT,
                "Shared",
            ].includes(domain)
        )
            return undefined;

        return (
            <>
                <Divider flexItem orientation={"vertical"} sx={{ my: 0.75, mx: 1.25 }} />
                <ListToolViewToggleButton isCardView={isCardView} onChange={onToggleViewType} />
            </>
        );
    }, [domain, isCardView, onToggleViewType]);

    return (
        <StyledListTools id={"list-tools"} ref={listToolRef}>
            {isUsingTreeView && (
                <Box>
                    <Grid container alignItems={"center"} justifyContent={"flex-end"} py={1.5} gap={1.5}>
                        {activeView?.toLowerCase() !== LIST_VIEW_TYPE.HIERARCHY && <Grid item>{viewSelectButton}</Grid>}
                        <Grid item xs>
                            {activeView?.toLowerCase() === LIST_VIEW_TYPE.HIERARCHY && (
                                <TreeViewTools
                                    domain={domain}
                                    hierarchyType={hierarchyType}
                                    breadcrumbMenu={breadcrumbMenu}
                                    onClickFolderTree={onClickFolderTree}
                                    onClickTreeItem={onClickTreeItem}
                                    onToggleModal={onToggleModal}
                                />
                            )}
                        </Grid>
                        {onToggleViewType && viewToggleButtons}
                    </Grid>
                </Box>
            )}

            <ListToolCollapse
                hideCheckbox={compareVersions(stage.version, VERSION_CM_4721_SHARES) < 0}
                type={domain}
                isCollapsed={isCollapsed}
                handleClearCheckAll={handleClearCheckAll}
                handleChangeCheckAll={onChangeNumSelected ? handleChangeCheckAll : undefined}
                toolSize={toolSize}
                numSelected={numSelected}
                pageCount={pageCount}
                buttons={buttons}
                pagination={pagination}
                filter={filter}
                sort={sort}
                viewToggleButtons={isUsingTreeView ? undefined : viewToggleButtons}
                totalCount={totalCount}
                totalView={totalView}
            />
        </StyledListTools>
    );
};

export default ListTools;
