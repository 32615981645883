import {handleActions, createAction} from 'redux-actions';
import fp from 'lodash/fp';
import {axios} from "../cores/axiosWrapper";

const getElementsAPI = (stageId, apiEndpoint, projectId, queryString) => {
    const search = queryString || ``;
    return axios.get(`${apiEndpoint}/asset-elements${search}`, {
        headers: {
            stageId,
            projectId,
        }
    });
};

//action type
const RESET_IMAGE_VIEWER = 'RESET_IMAGE_VIEWER';
const IMAGE_VIEWER_PENDING = 'IMAGE_VIEWER_PENDING';
const IMAGE_VIEWER_LIST_GET_SUCCESS = 'IMAGE_VIEWER_LIST_GET_SUCCESS';
const IMAGE_VIEWER_LIST_GET_FAILURE = 'IMAGE_VIEWER_LIST_GET_FAILURE';

const initialState = {
    pending: false,
    error: false,
    elements: [],
    totalCount : 0,
};

const cloneInitialState = fp.cloneDeep(initialState);

export default handleActions({
    [RESET_IMAGE_VIEWER] : () => {
        return cloneInitialState;
    },
    [IMAGE_VIEWER_PENDING]: (state) => {
        return {
            ...state,
            pending : true,
        };
    },
    [IMAGE_VIEWER_LIST_GET_SUCCESS]: (state, action) => {
        const { elements, totalCount } = action.payload;

        return {
            ...state,
            pending : false,
            error : false,
            elements,
            totalCount,
        };
    },
    [IMAGE_VIEWER_LIST_GET_FAILURE]: (state) => {
        return {
            ...state,
            pending : false,
            error : true
        };
    },
}, initialState)

export const reset = createAction(RESET_IMAGE_VIEWER);

export const getElements = (queryString) => (dispatch, getState) => {
    const {
        stage,
        project
    } = getState();

    dispatch({type: IMAGE_VIEWER_PENDING});

    return new Promise((resolve, reject) => {
        getElementsAPI(stage.id, stage.endpoint, project.id, queryString)
          .then((response) => {
              const payload = fp.getOr({}, `data`, response);
              dispatch({
                  type: IMAGE_VIEWER_LIST_GET_SUCCESS,
                  payload
              });
              resolve(payload);
          })
          .catch(error => {
              dispatch({
                  type: IMAGE_VIEWER_LIST_GET_FAILURE,
                  payload: error
              });
              reject(error);
          });
    })
};