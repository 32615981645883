const downloadLink = (url, fileRename) => {
    const fileName = fileRename || extractFileNameFromUrl(url);

    const linkElement = document.createElement("area");

    linkElement.download = fileName;
    linkElement.href = url;
    linkElement.style.display = "none";

    document.body.appendChild(linkElement);

    setTimeout(function () {
        linkElement.click();
        document.body.removeChild(linkElement);
    }, 2000);
};

const extractFileNameFromUrl = (url) => {
    const split = url.split("?");

    if (split[0]) return split[0].slice(split[0].lastIndexOf("/") + 1);

    return "undefined";
};

export default downloadLink;
