import React from "react";
import { Typography as MuiTypography } from "@mzc-pdc/ui";
import theme from "@styles/theme";
import { IconRequired } from "@mzc-cloudplex/icons";

export const Typography = ({ children, required = false, requiredSize = "small", ...props }) => {
    return (
        <MuiTypography {...props}>
            {children}
            {required && (
                <IconRequired
                    size={requiredSize === "normal" ? 12 : 6}
                    htmlColor={theme.palette.error[700]}
                    sx={{
                        ml: requiredSize === "normal" ? "7px" : "4px",
                        mt: requiredSize === "normal" ? undefined : "-10px",
                    }}
                />
            )}
        </MuiTypography>
    );
};

export const CheckboxTypography = ({ checked, children, required = false, requiredSize, readOnly, ...props }) => {
    return (
        <MuiTypography {...props} color={theme.palette.secondary[!checked && readOnly ? 500 : 800]}>
            {required && (
                <IconRequired
                    size={requiredSize === "normal" ? 12 : 6}
                    htmlColor={!checked && readOnly ? theme.palette.secondary[500] : theme.palette.error[700]}
                    sx={{
                        mr: requiredSize === "normal" ? "6px" : "4px",
                        mt: requiredSize === "normal" ? undefined : "-10px",
                    }}
                />
            )}
            {children}
        </MuiTypography>
    );
};

export default Typography;
