import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "quill-mention";
import EditorToolbar from "./editor-toolbar";
import ReactQuill, { Quill } from "react-quill";
import { Badge, Box, CircularProgress, Grid, IconButton, ImageList, ImageListItem, Typography } from "@mzc-pdc/ui";
import { useTranslation } from "react-i18next";
import { IconCancel, IconClose, IconUp } from "@mzc-cloudplex/icons";
import theme from "@styles/theme";
import "react-quill/dist/quill.snow.css";
import "quill-mention/dist/quill.mention.css";
import { alpha } from "@mui/material";
import EmojiPickerPopper from "@features/comments/emoji-picker-popper";
import { useSelector } from "react-redux";
import { getProjectUsersAPI } from "@modules/apis/user-management-v2";
import useCommentSelector from "@hooks/useCommentSelector";
import "./editor-comment.css";
import Tooltip from "@components_v2/Tooltip/tooltip";
import { MAX_ATTACHMENT_COUNT, MAX_ATTACHMENT_SIZE } from "@routes/admins/settings/constants";
import convertToByte from "@cores/convertToByte";
import useDialog from "@hooks/useDialog";
import DialogWarningAttachments from "@features/dialog/comment/dialog-warning-attachments";
import { getExtensionFromMimeType, getMediaTypeFromMimeType } from "@lib/mime/mime";

const EditorComment = ({
    id,
    domain,
    onChange,
    onClose,
    onSubmit,
    chat,
    attachments,
    isChanged = true,
    onChangeAttachments,
}) => {
    const { t } = useTranslation();
    const USER_LIST_LIMIT = 100;
    const quillRef = useRef();
    const emojiButtonRef = useRef(null);
    const fileInputRef = useRef(null);
    const project = useSelector(({ project }) => project);
    const space = useSelector(({ stage }) => stage);
    const memberRef = useRef();
    const { resourceData, selectedThreadId } = useCommentSelector({ domain });
    const { dialog, openDialog, closeDialog } = useDialog();

    const [isOpenEmojiPicker, setIsOpenEmojiPicker] = useState(false);
    const [emojiPickerAnchorEl, setEmojiPickerAnchorEl] = useState(null);
    const [addingAttachmentsCount, setAddingAttachmentsCount] = useState(0);
    const [isAddingAttachmentsExceedSize, setIsAddingAttachmentsExceedSize] = useState(false);
    const [parsedAttachments, setParsedAttachments] = useState([]);

    const thread = resourceData?.results?.find((el) => el.id === selectedThreadId);

    const isDisabled = useMemo(() => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(chat, "text/html");
        const node = doc.body;
        const children = Array.from(node.childNodes);
        let isEmpty = true;

        children.some((child) => {
            const content = child?.innerHTML?.replace("<br>", "").trim();
            if (content !== "") {
                isEmpty = false;
                return;
            }
        });

        return !chat || !isChanged || isEmpty;
    }, [chat, isChanged]);

    const onClickEmojiPicker = (value) => {
        setIsOpenEmojiPicker(!isOpenEmojiPicker);
        setEmojiPickerAnchorEl(document.getElementById("custom-emoji-button"));
    };

    const onCloseEmojiPicker = () => {
        setIsOpenEmojiPicker(false);
    };

    const onHandleClickEmoji = (emojiObject) => {
        const quill = quillRef.current.getEditor();
        const index = quill.selection.savedRange.index ?? 0;

        quill.insertText(index, emojiObject.emoji, "user");
        quill.setSelection(index + emojiObject.emoji.length);

        onCloseEmojiPicker();
    };
    const onHandleClickMention = () => {
        const quill = quillRef.current.getEditor();
        const mention = quill.getModule("mention");
        mention.openMenu("@");
    };

    const getUserList = useCallback(
        async ({ searchTerm, offset }) => {
            const params = {
                name: searchTerm,
                limit: USER_LIST_LIMIT,
            };
            const response = await getProjectUsersAPI(space.id, space.endpoint, project.id, params);
            const results = memberRef.current
                ? response?.data?.results?.filter((el) => memberRef.current?.includes(el.id))
                : response?.data?.results;
            return results?.map((el) => ({
                id: el.id,
                value: el.name,
                username: el.username,
            }));
        },
        [id],
    );

    const handleFileUpload = () => {
        fileInputRef.current.click();
    };

    const onHandleAddFiles = (event) => {
        const files = [...event.target.files];

        //동일한 파일은 쓰면 안됨
        const allAttachments = [...attachments, ...files];
        if (allAttachments.length > MAX_ATTACHMENT_COUNT) {
            openDialog("warningAttachments");
            setAddingAttachmentsCount(files.length);
        } else if (files.some((file) => convertToByte(file.size) > MAX_ATTACHMENT_SIZE)) {
            openDialog("warningAttachments");
            setIsAddingAttachmentsExceedSize(true);
        } else {
            onChangeAttachments(allAttachments);
        }
    };

    const onHandleRemoveFile = (index) => {
        const newAttachments = attachments.filter((_, i) => i !== index);
        onChangeAttachments(newAttachments);
    };

    const formats = ["bold", "underline", "strike", "emoji", "mention"];

    const getQuillModules = useCallback(
        (id) => {
            return {
                toolbar: {
                    container: `#${id}`,
                },
                mention: {
                    allowedChars: /^[a-zA-Z0-9가-힇ㄱ-ㅎㅏ-ㅣぁ-ゔァ-ヴー々〆〤一-龥]*$/,
                    mentionDenotationChars: ["@"],
                    dataAttributes: ["id", "value", "username"],
                    mentionContainerClass: "cpm-mention-container",
                    defaultMenuOrientation: "top",
                    source: useCallback(
                        async (searchTerm, renderList) => {
                            const response = await getUserList({ searchTerm });
                            renderList(response);
                        },
                        [id],
                    ),
                    renderItem: useCallback(
                        (item, searchTerm) => {
                            let itemBox = document.createElement("div");
                            itemBox.className = "cpm-mention-list-item-box";

                            let nameBox = document.createElement("div");
                            nameBox.className = "cpm-mention-list-item-name-box";

                            let name = document.createElement("p");
                            name.className = "cpm-mention-list-item-name text-ellipsis";
                            name.innerText = item.value;

                            let username = document.createElement("span");
                            username.className = "cpm-mention-list-item-username";
                            username.innerText = item.username;

                            let avatarDiv = document.createElement("div");
                            avatarDiv.className = "MuiAvatar-root MuiAvatar-circular css-1oid25j-MuiAvatar-root";
                            avatarDiv.style.width = "24px";
                            avatarDiv.style.height = "24px";
                            avatarDiv.style.marginTop = "6px";

                            let avatar = document.createElement("img");
                            avatar.src = "https://pops.megazonecdn.com/pops/user/profiles/img_profile_default.png"; //TODO: pops profile 연동
                            avatar.className = "MuiAvatar-img";
                            avatar.style.width = "24px";

                            avatarDiv.appendChild(avatar);
                            itemBox.appendChild(avatarDiv);
                            nameBox.appendChild(name);
                            nameBox.appendChild(username);
                            itemBox.appendChild(nameBox);

                            return itemBox;
                        },
                        [id],
                    ),
                },
                // "emoji-shortname": true,
                history: {
                    delay: 500,
                    maxStack: 100,
                    userOnly: true,
                },
            };
        },
        [id],
    );

    const getEllipsisText = useCallback((name) => {
        if (name.length > 20) {
            const extension = name.slice(name.lastIndexOf("."));
            return name.substring(0, 20 - extension?.length) + "..." + extension;
        }
        return name;
    }, []);

    useEffect(() => {
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();

            quill.focus();
            quill.setSelection(quill.getLength(), 0);
        }
    }, [quillRef.current]);

    useEffect(() => {
        if (!thread?.members?.results) return;
        memberRef.current = thread.members?.results?.map((el) => el.id) ?? [];
    }, [thread]);

    useEffect(() => {
        const newAttachments = attachments.map((attach) => {
            if (attach.accessUrl) {
                return {
                    id: attach.id,
                    type: getMediaTypeFromMimeType(attach.mimeType) === "IMAGE" ? "image" : "file",
                    name: attach.displayName,
                    url: attach.accessUrl,
                };
            }

            if (getMediaTypeFromMimeType(attach.type) === "IMAGE") {
                return {
                    type: "image",
                    name: attach.name,
                    url: URL.createObjectURL(attach),
                };
            } else {
                return {
                    type: "file",
                    name: attach.name,
                    url: undefined,
                };
            }
        });

        setParsedAttachments(newAttachments);
    }, [attachments]);

    return (
        <Grid
            container
            flexDirection={"column"}
            ref={emojiButtonRef}
            sx={{
                boxShadow: `0px 2px 4px 0px rgba(0, 0, 0, 0.16)`,
                borderRadius: "4px",
            }}
        >
            <EditorToolbar
                id={id}
                onClickEmoji={onClickEmojiPicker}
                onClickMention={onHandleClickMention}
                onClickAttachments={handleFileUpload}
            />
            <EmojiPickerPopper
                open={isOpenEmojiPicker}
                disablePortal={false}
                placement={"top-start"}
                anchorEl={emojiPickerAnchorEl}
                onClose={onCloseEmojiPicker}
                onEmojiClick={onHandleClickEmoji}
            />
            <input
                type="file"
                multiple={true}
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={onHandleAddFiles}
            />
            <ReactQuill
                ref={quillRef}
                value={chat}
                modules={getQuillModules(id)}
                formats={formats}
                onChange={onChange}
            />
            <Grid container px={2} py={1.5} gap={2} flexWrap={"nowrap"}>
                {parsedAttachments?.map((item, attachIdx) => (
                    <Grid
                        item
                        xs
                        key={item.img}
                        maxWidth={72}
                        maxHeight={72}
                        position={"relative"}
                        sx={{
                            "&&": {
                                maxWidth: 72,
                            },
                            ".MuiBadge-badge": {
                                display: "none",
                            },
                            "&:hover": {
                                ".MuiBadge-badge": {
                                    display: "block",
                                },
                            },
                        }}
                    >
                        <Badge
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            badgeContent={
                                <IconButton
                                    circled
                                    size={"small"}
                                    variant={"outlined"}
                                    sx={{
                                        p: theme.spacing(0.5),
                                        width: "20px",
                                        height: "20px",
                                        backgroundColor: theme.palette.common.white,
                                        "&:hover": {
                                            backgroundColor: theme.palette.common.white,
                                        },
                                    }}
                                    onClick={() => onHandleRemoveFile(attachIdx)}
                                >
                                    <IconCancel size={12} />
                                </IconButton>
                            }
                            sx={{
                                width: `100%`,
                                height: `100%`,
                                aspectRatio: 1,
                                ".MuiBadge-badge": {
                                    width: 20,
                                    height: 20,
                                    px: 0,
                                    color: "white",
                                },
                                "&:hover": {
                                    outline: `3px solid ${theme.palette.primary[200]}`,
                                },
                                borderRadius: `4px`,
                            }}
                        >
                            <Tooltip title={item.name} followCursor>
                                {item.type === "image" ? (
                                    <img
                                        src={item.url}
                                        alt={item.name}
                                        loading="lazy"
                                        style={{
                                            width: `100%`,
                                            height: `100%`,
                                            objectFit: `cover`,
                                            borderRadius: `4px`,
                                            overflow: "hidden",
                                            aspectRatio: 1,
                                        }}
                                    />
                                ) : (
                                    <Box
                                        width={1}
                                        height={1}
                                        px={0.75}
                                        pt={1}
                                        pb={1.25}
                                        border={1}
                                        borderColor={"divider"}
                                        bgcolor={alpha(theme.palette.greySecondary[50], 0.5)}
                                        borderRadius={`4px`}
                                    >
                                        <Typography
                                            variant={"body3_500"}
                                            sx={{
                                                display: `-webkit-box`,
                                                WebkitBoxOrient: `vertical`,
                                                overflow: `hidden`,
                                                WebkitLineClamp: 3,
                                            }}
                                        >
                                            {getEllipsisText(item.name)}
                                        </Typography>
                                    </Box>
                                )}
                            </Tooltip>
                        </Badge>
                    </Grid>
                ))}
            </Grid>

            <Grid container flexDirection={"row"} justifyContent={"flex-end"} gap={0.5} pr={2} pb={2}>
                <Tooltip title={t("common::label::Close", `Close`)}>
                    <IconButton circled onClick={onClose} sx={{ width: "24px", height: "24px" }}>
                        <IconCancel size={16} />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t("common::label::Send", `Send`)}>
                    <IconButton
                        circled
                        disabled={isDisabled}
                        color={"primary"}
                        variant={"contained"}
                        onClick={(e) => {
                            e.stopPropagation();
                            onSubmit();
                            onClose();
                        }}
                        sx={{
                            width: "24px",
                            height: "24px",
                            bgcolor: isDisabled
                                ? alpha(theme.palette.greySecondary.main, 0.16)
                                : theme.palette.primary.light,
                            "&:disabled": {
                                bgcolor: alpha(theme.palette.greySecondary.main, 0.16),
                            },
                        }}
                    >
                        <IconUp
                            size={16}
                            htmlColor={
                                isDisabled ? alpha(theme.palette.greySecondary.main, 0.48) : theme.palette.common.white
                            }
                        />
                    </IconButton>
                </Tooltip>
            </Grid>
            {dialog === "warningAttachments" && (
                <DialogWarningAttachments
                    attachmentsCount={attachments.length}
                    addingAttachmentsCount={addingAttachmentsCount}
                    isAddingAttachmentsExceedSize={isAddingAttachmentsExceedSize}
                    onClickClose={closeDialog}
                />
            )}
        </Grid>
    );
};

export default EditorComment;
