import React, { Component } from "react";
import { withTranslation, Trans } from "react-i18next";
import { PUBLIC_URL } from "@constants";
import config from "@/config";
import { IconLogoMzc } from "@mzc-cloudplex/icons";
import theme from "@styles/theme";
import { StyledWelcome } from "@routes/welcome/styled";
import { Button, Grid, Box, Typography, Stack } from "@mzc-pdc/ui";
import { Divider, alpha } from "@mui/material";
import { ReactComponent as LogoLxHausys } from "@assets/svg/logo-lx-hausys.svg";
import BgImg from "@assets/img/welcome-lx-bg.png";

class WelcomeLxHausys extends Component {
    constructor(props) {
        super(props);
    }

    onLoginClick = (isPops) => {
        if (isPops) {
            localStorage.setItem("CLIENT_ID", config.mz_pops.clientId);
        }
        window.location.href = this.getLoginLink(isPops);
    };

    getLoginLink = (isPops) => {
        if (isPops) {
            const redirectUrl = encodeURIComponent(`${window.location.origin}${config.host.loginRedirectUri}`);
            return `${config.mz_pops.oauth_host}/oauth/authorize?response_type=code&client_id=${config.mz_pops.clientId}&redirect_uri=${redirectUrl}`;
        } else {
            return "https://sx7bqjcob9.execute-api.ap-northeast-2.amazonaws.com/dev/saml/lx-hausys-saml/login";
        }
    };

    render() {
        const { t, i18n } = this.props;
        return (
            <Grid
                container
                alignItems={"center"}
                justifyContent={"center"}
                width={1}
                height={1}
                sx={{
                    backgroundImage: `url(${BgImg})`,
                    backgroundSize: `cover`,
                    backgroundPosition: `center`,
                    backgroundColor: `#746661`,
                }}
            >
                <Box
                    width={"auto"}
                    minWidth={640}
                    minHeight={500}
                    px={9}
                    py={5}
                    borderRadius={3}
                    bgcolor={alpha(theme.palette.background.paper, 0.92)}
                    sx={{ boxShadow: `0px 3px 6px 0px rgba(0, 0, 0, 0.32)` }}
                >
                    <Stack>
                        <Grid container justifyContent={"center"}>
                            <LogoLxHausys />
                        </Grid>
                        <Divider sx={{ mt: 5, borderColor: theme.palette.greySecondary.main }} />
                        <Stack px={5} py={7.5} gap={6}>
                            <Typography
                                variant={`subtitle1_700`}
                                component={`p`}
                                sx={{ textAlign: `left`, lineHeight: `1.63` }}
                            >
                                <Trans
                                    i18nKey={`common::msg::Hello,<NewLine/>Welcome to LX Hausys Design Library.`}
                                    components={{
                                        NewLine: <br />,
                                    }}
                                />
                            </Typography>
                            <Button
                                size={"large"}
                                variant={"contained"}
                                color={"grey"}
                                fullWidth
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.onLoginClick(true);
                                }}
                                sx={{
                                    backgroundColor: theme.palette.grey.dark,
                                    "&:hover, &:active": {
                                        backgroundColor: theme.palette.grey.dark,
                                    },
                                }}
                            >
                                <strong>{t(`common::label::${"Sign in"}`, `Sign in`)}</strong>
                            </Button>
                        </Stack>
                        <Divider sx={{ mb: 4, borderColor: theme.palette.greySecondary[100] }} />
                        <Typography
                            variant={"body3_400"}
                            component="p"
                            align="center"
                            color={theme.palette.text.secondary}
                        >
                            {t(
                                `common::msg::${"If you don’t have an account yet, please ask your Organization Administrator."}`,
                                `If you don’t have an account yet, please ask your Organization Administrator.`,
                            )}
                        </Typography>
                    </Stack>
                </Box>
            </Grid>
        );
    }
}

export default withTranslation()(WelcomeLxHausys);
