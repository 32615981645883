import convertToByte from "@cores/convertToByte";
import EditLinkedCollectionTable from "@components/view/panel-details-view/edit-linked-collection-table";
import CollectionColumn from "@components/view/panel-details-view/panel-table-columns-collections";

export function getPropertyPanelCollectionData(data) {
    return [
        {
            title: "Collection Details",
            attributions: [
                { type: "text", subtitle: "Collection ID", value: data.id, isEditable: false },
                { type: "text", subtitle: "Size", value: convertToByte(data.totalSize), isEditable: false },
                {
                    type: "date",
                    subtitle: "Created",
                    value: data.created,
                    isEditable: false,
                },
                {
                    type: "date",
                    subtitle: "Updated",
                    value: data.updated,
                    isEditable: false,
                },
            ],
        },
        {
            title: "Metadata",
            attributions: [
                { type: "textarea", subtitle: "Description", value: data.description, isEditable: true },
                { type: "tags", subtitle: "Tags", value: data.tags, isEditable: true },
            ],
        },
        {
            title: "Linked Collections",
            type: "listView",
            value: {
                type: "collection",
                data: data.linkedCollections,
                component: EditLinkedCollectionTable,
                columns: CollectionColumn,
                isEditable: true,
            },
        },
    ];
}
