import {handleActions, createAction} from 'redux-actions';
import {
    axios,
    axiosWithoutHeaders
} from "../cores/axiosWrapper";
import moment from "moment";

import queryString from "../cores/queryString";

function getCategoryGroupsAPI(stageId, apiEndpoint, projectId, params) {
    const queries = {
        offset : params.offset ? params.offset : '',
        limit : params.limit ? params.limit : ''
    };

    queries.name = params.name;    
    queries.creator = params.creator;    
    queries.status = params.status;

    if(params.startAt && params.endAt) {
        const startAt = `${moment(`${params.startAt.format('YYYY-MM-DD')} 00:00:00`).utc().format('YYYY-MM-DD')}T${moment(`${params.startAt.format('YYYY-MM-DD')} 00:00:00`).utc().format('HH:mm:ss')}Z`;
        const endAt = `${moment(`${params.endAt.format('YYYY-MM-DD')} 00:00:00`).utc().format('YYYY-MM-DD')}T${moment(`${params.endAt.format('YYYY-MM-DD')} 00:00:00`).utc().format('HH:mm:ss')}Z`;
        
        queries.createdAt = `${startAt}~${endAt}`;
    }

    return axios.get(`${apiEndpoint}/category-groups?${queryString.stringify(queries)}`, {
        headers: {
            stageId,
            projectId
        }
    });
}

function postCategoryGroupsAPI(stageId, apiEndpoint, projectId, data) {
    return axios.post(`${apiEndpoint}/category-groups`, data, {
        headers: {
            stageId,
            projectId
        }
    });
}

function putCategoryGroupsAPI(stageId, apiEndpoint, projectId, categoryGroupId, data) {
    return axios.patch(`${apiEndpoint}/category-groups/${categoryGroupId}`, data, {
        headers: {
            stageId,
            projectId
        }
    });
}

function deleteCategoryGroupsAPI(stageId, apiEndpoint, projectId, categoryGroupId) {
    return axios.delete(`${apiEndpoint}/category-groups/${categoryGroupId}`, {
        headers: {
            stageId,
            projectId
        }
    });
}

function getCategoryGroupAPI(stageId, apiEndpoint, projectId, categoryGroupId) {
    return axios.get(`${apiEndpoint}/category-groups/${categoryGroupId}`, {
        headers: {
            stageId,
            projectId
        }
    });
}

const CATEGORY_GROUPS_MANAGEMENT_PENDING = 'CATEGORY_GROUPS_MANAGEMENT_PENDING';
const CATEGORY_GROUPS_MANAGEMENT_FAILURE = 'CATEGORY_GROUPS_MANAGEMENT_FAILURE';
const GET_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_GROUPS_SUCCESS = 'GET_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_GROUPS_SUCCESS';
const PUT_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_GROUPS_SUCCESS = 'PUT_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_GROUPS_SUCCESS';
const POST_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_GROUPS_SUCCESS = 'POST_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_GROUPS_SUCCESS';
const DELETE_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_GROUPS_SUCCESS = 'DELETE_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_GROUPS_SUCCESS';

const GET_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_SUCCESS = 'GET_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_SUCCESS';

const UPDATE_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_GROUPS_PARAMS = 'UPDATE_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_GROUPS_PARAMS';
const RESET_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_GROUPS_PARAMS = 'RESET_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_GROUPS_PARAMS';
const TOGGLE_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_GROUPS_ACTIONS = 'TOGGLE_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_GROUPS_ACTIONS';
const CLOSE_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_GROUPS_ACTIONS = 'CLOSE_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_GROUPS_ACTIONS';

const initialState = {
    pending: false,
    error: false,
    totalCount: 0,
    params: {
        offset : 0,    
        limit : 50, 
        name: '',
        status: '',
        creator: ''
    },
    isEnabledOrder : true,
    categoryGroups: [],
    categoryGroup: null
};

export default handleActions({
    [CATEGORY_GROUPS_MANAGEMENT_PENDING]: (state, action) => {
        return {
            ...state,
            pending: true,
            error: false
        }
    },
    [CATEGORY_GROUPS_MANAGEMENT_FAILURE]: (state, action) => {
        return {
            ...state,
            pending: false,
            error: true
        };
    },
    [GET_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_GROUPS_SUCCESS]: (state, action) => {
        const {data} = action.payload;

        return {
            ...state,
            pending: false,
            error: false,
            isEnabledOrder : state.params.name === '' && state.params.status === '',
            totalCount: data.totalCount,
            categoryGroups: data.categoryGroups

        }
    },
    [PUT_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_GROUPS_SUCCESS]: (state, action) => {
        const {data} = action.payload;

        return {
            ...state,
            pending: false,
            error: false,
            categoryGroup: data
        }
    },
    [POST_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_GROUPS_SUCCESS]: (state, action) => {
        return {
            ...state,
            pending: false,
            error: false
        };
    },
    [DELETE_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_GROUPS_SUCCESS]: (state, action) => {
        return {
            ...state,
            pending: false,
            error: false
        }
    },
    [UPDATE_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_GROUPS_PARAMS]: (state, action) => {
        return {
            ...state,
            params : {
                ...state.params,
                ...action.payload
            }
        }
    },
    [RESET_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_GROUPS_PARAMS]:(state) => {
        return {
            ...state,
            params : {
                offset : 0,    
                limit : 50, 
                name: '',
                status: '',
                creator: ''
            }
        }
    },
    [TOGGLE_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_GROUPS_ACTIONS]:(state, action) => {
        return {
            ...state,
            categoryGroups: state.categoryGroups.map(v => {
                return {
                    ...v,
                    isOpen: v.id === action.payload ? !v.isOpen : false
                }
            })
        }
    },
    [CLOSE_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_GROUPS_ACTIONS]:(state) => {
        return {
            ...state,
            categoryGroups: state.categoryGroups.map(v => {
                return {
                    ...v,
                    isOpen: false
                }
            })
        }
    },
    [GET_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_SUCCESS]:(state, action) => {
        const {data} = action.payload;

        return {
            ...state,
            pending : false,
            error : false,
            categoryGroup : data
        }
    }
}, initialState);

export const closeActions = createAction(CLOSE_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_GROUPS_ACTIONS);
export const toggleActions = createAction(TOGGLE_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_GROUPS_ACTIONS);
export const updateParams = createAction(UPDATE_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_GROUPS_PARAMS);
export const resetParams = createAction(RESET_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_GROUPS_PARAMS);
export const getCategoryGroups = (projectId, params) => (dispatch, getState) => {
    const {
        stage
    } = getState();

    dispatch({type: CATEGORY_GROUPS_MANAGEMENT_PENDING});

    return getCategoryGroupsAPI(stage.id, stage.endpoint, projectId, params).then(
        (response) => {
            dispatch({
                type: GET_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_GROUPS_SUCCESS,
                payload: response
            });
        }
    ).catch(error => {
        dispatch({
            type: CATEGORY_GROUPS_MANAGEMENT_FAILURE,
            payload: error
        });
    });
};

export const postCategoryGroups = (projectId, data, callback) => (dispatch, getState) => {
    const {
        stage
    } = getState();

    dispatch({type: CATEGORY_GROUPS_MANAGEMENT_PENDING});

    return postCategoryGroupsAPI(stage.id, stage.endpoint, projectId, data).then(
        (response) => {
            dispatch({
                type: POST_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_GROUPS_SUCCESS,
                payload: response
            });
            callback(response.data);
        }
    ).catch(error => {
        dispatch({
            type: CATEGORY_GROUPS_MANAGEMENT_FAILURE,
            payload: error
        });
    });
};

export const putCategoryGroups = (projectId, categoryGroupId, data) => (dispatch, getState) => {
    const {
        stage
    } = getState();

    dispatch({type: CATEGORY_GROUPS_MANAGEMENT_PENDING});

    return putCategoryGroupsAPI(stage.id, stage.endpoint, projectId, categoryGroupId, data).then(
        (response) => {
            dispatch({
                type: PUT_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_GROUPS_SUCCESS,
                payload: response
            });
        }
    ).catch(error => {
        dispatch({
            type: CATEGORY_GROUPS_MANAGEMENT_FAILURE,
            payload: error
        });
    });
};

export const deleteCategoryGroups = (projectId, categoryGroupId) => (dispatch, getState) => {
    const {
        stage
    } = getState();

    dispatch({type: CATEGORY_GROUPS_MANAGEMENT_PENDING});

    return deleteCategoryGroupsAPI(stage.id, stage.endpoint, projectId, categoryGroupId).then(
        (response) => {
            dispatch({
                type: DELETE_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_GROUPS_SUCCESS,
                payload: response
            });
        }
    ).catch(error => {
        dispatch({
            type: CATEGORY_GROUPS_MANAGEMENT_FAILURE,
            payload: error
        });
    });
};

export const getCategoryGroup = (projectId, categoryGroupId, callback) => (dispatch, getState) => {
    const {
        stage
    } = getState();

    dispatch({type: CATEGORY_GROUPS_MANAGEMENT_PENDING});

    return new Promise((resolve, reject) => {
        getCategoryGroupAPI(stage.id, stage.endpoint, projectId, categoryGroupId).then(
            (response) => {
                dispatch({
                    type: GET_CATEGORY_GROUPS_MANAGEMENT_FOR_CATEGORY_SUCCESS,
                    payload: response
                });
                callback && callback(response);
                resolve(response.data);
            }
        ).catch(error => {
            dispatch({
                type: CATEGORY_GROUPS_MANAGEMENT_FAILURE,
                payload: error
            });
            reject(error);
        });
    })
}