import { handleActions, createAction } from "redux-actions";
import { axios, axiosWithoutHeaders } from "../cores/axiosWrapper";
import {
    getActionGroupsAPI,
    getMyProjectPermissionsAPI,
    getRoleAPI,
    getRolesAPI,
    updateRoleActionsAPI,
    updateRoleAPI,
} from "@modules/apis/rolesManagement_v2";
import { SHOW_MENU_OPTIONS } from "@constants";
import { useCallback } from "react";

const ROLES_MANAGEMENT_GET_ROLES_PENDING = "ROLES_MANAGEMENT_GET_ROLES_PENDING";
const ROLES_MANAGEMENT_GET_ROLES_FAILURE = "ROLES_MANAGEMENT_GET_ROLES_FAILURE";
const ROLES_MANAGEMENT_GET_ROLES_SUCCESS = "ROLES_MANAGEMENT_GET_ROLES_SUCCESS";

const ROLES_MANAGEMENT_GET_ACTION_GROUPS_PENDING = "ROLES_MANAGEMENT_GET_ACTION_GROUPS_PENDING";
const ROLES_MANAGEMENT_GET_ACTION_GROUPS_FAILURE = "ROLES_MANAGEMENT_GET_ACTION_GROUPS_FAILURE";
const ROLES_MANAGEMENT_GET_ACTION_GROUPS_SUCCESS = "ROLES_MANAGEMENT_GET_ACTION_GROUPS_SUCCESS";

const ROLES_MANAGEMENT_GET_ROLE_PENDING = "ROLES_MANAGEMENT_GET_ROLE_PENDING";
const ROLES_MANAGEMENT_GET_ROLE_FAILURE = "ROLES_MANAGEMENT_GET_ROLE_FAILURE";
const ROLES_MANAGEMENT_GET_ROLE_SUCCESS = "ROLES_MANAGEMENT_GET_ROLE_SUCCESS";

const ROLES_MANAGEMENT_UPDATE_PARSED_ROLES = "ROLES_MANAGEMENT_UPDATE_PARSED_ROLES";

const ROLES_MANAGEMENT_UPDATE_ROLE_PENDING = "ROLES_MANAGEMENT_UPDATE_ROLE_PENDING";
const ROLES_MANAGEMENT_UPDATE_ROLE_FAILURE = "ROLES_MANAGEMENT_UPDATE_ROLE_FAILURE";
const ROLES_MANAGEMENT_UPDATE_ROLE_SUCCESS = "ROLES_MANAGEMENT_UPDATE_ROLE_SUCCESS";

const ROLES_MANAGEMENT_GET_MY_PROJECT_PERMISSIONS_PENDING = "ROLES_MANAGEMENT_GET_MY_PROJECT_PERMISSIONS_PENDING";
const ROLES_MANAGEMENT_GET_MY_PROJECT_PERMISSIONS_FAILURE = "ROLES_MANAGEMENT_GET_MY_PROJECT_PERMISSIONS_FAILURE";
const ROLES_MANAGEMENT_GET_MY_PROJECT_PERMISSIONS_SUCCESS = "ROLES_MANAGEMENT_GET_MY_PROJECT_PERMISSIONS_SUCCESS";

const ROLES_MANAGEMENT_RESET_PROJECT_PERMISSIONS = "ROLES_MANAGEMENT_RESET_PROJECT_PERMISSIONS";

const initialState = {
    actionGroups: {
        data: null,
        pending: false,
        error: null,
    },
    roles: {
        data: null,
        pending: false,
        error: null,
    },
    role: {
        data: null,
        parsedData: null, //Note: General/Specific, 도메인 별로 권한을 묶은 데이터
        pending: false,
        error: null,
    },
    projectPermissions: {
        data: null,
        pending: false,
        error: null,
    },
    pending: null, //삭제,
};

export default handleActions(
    {
        [ROLES_MANAGEMENT_GET_ROLES_PENDING]: (state) => ({
            ...state,
            roles: {
                ...initialState.roles,
                pending: true,
            },
        }),
        [ROLES_MANAGEMENT_GET_ROLES_FAILURE]: (state, action) => ({
            ...state,
            roles: {
                ...state.roles,
                error: action.payload,
                pending: false,
            },
        }),
        [ROLES_MANAGEMENT_GET_ROLES_SUCCESS]: (state, action) => ({
            ...state,
            roles: {
                ...state.roles,
                data: action.payload,
                pending: false,
            },
        }),
        [ROLES_MANAGEMENT_GET_ACTION_GROUPS_PENDING]: (state) => ({
            ...state,
            actionGroups: {
                ...initialState.actionGroups,
                pending: true,
            },
        }),
        [ROLES_MANAGEMENT_GET_ACTION_GROUPS_FAILURE]: (state, action) => ({
            ...state,
            actionGroups: {
                ...state.actionGroups,
                pending: false,
                error: action.payload,
            },
        }),
        [ROLES_MANAGEMENT_GET_ACTION_GROUPS_SUCCESS]: (state, action) => ({
            ...state,
            actionGroups: {
                ...state.actionGroups,
                pending: false,
                data: action.payload.data,
                originalData: action.payload.originalData,
            },
        }),
        [ROLES_MANAGEMENT_UPDATE_PARSED_ROLES]: (state, action) => ({
            ...state,
            role: {
                ...state.role,
                parsedData: action.payload,
            },
        }),
        [ROLES_MANAGEMENT_GET_ROLE_PENDING]: (state) => ({
            ...state,
            role: {
                ...initialState.role,
                pending: true,
            },
        }),
        [ROLES_MANAGEMENT_GET_ROLE_FAILURE]: (state, action) => ({
            ...state,
            role: {
                ...state.role,
                error: action.payload,
                pending: false,
            },
        }),
        [ROLES_MANAGEMENT_GET_ROLE_SUCCESS]: (state, action) => ({
            ...state,
            role: {
                ...state.role,
                data: action.payload,
                pending: false,
            },
        }),
        [ROLES_MANAGEMENT_GET_MY_PROJECT_PERMISSIONS_PENDING]: (state) => ({
            ...state,
            projectPermissions: {
                ...state.projectPermissions,
                pending: true,
            },
        }),
        [ROLES_MANAGEMENT_GET_MY_PROJECT_PERMISSIONS_FAILURE]: (state, action) => ({
            ...state,
            projectPermissions: {
                ...state.projectPermissions,
                data: null,
                error: action.payload,
                pending: false,
            },
        }),
        [ROLES_MANAGEMENT_GET_MY_PROJECT_PERMISSIONS_SUCCESS]: (state, action) => ({
            ...state,
            projectPermissions: {
                ...state.projectPermissions,
                data: action.payload,
                pending: false,
            },
        }),
        [ROLES_MANAGEMENT_RESET_PROJECT_PERMISSIONS]: (state) => ({
            ...state,
            projectPermissions: {
                data: null,
                pending: false,
                error: null,
            },
        }),
    },
    initialState,
);

export const updateParsedRoles = createAction(ROLES_MANAGEMENT_UPDATE_PARSED_ROLES);
export const resetProjectPermissions = createAction(ROLES_MANAGEMENT_RESET_PROJECT_PERMISSIONS);

const makeActionGroupByPluggable = (actionGroups) => {
    const actionGroupMap = actionGroups.reduce(
        (acc, actionGroup) => {
            const isPluggable = actionGroup.isPluggable ? "specific" : "general";

            const actionDomain = acc[isPluggable].find((domain) => domain.id === actionGroup.domain);

            if (actionDomain) {
                actionDomain.permissions.push(actionGroup);
            } else {
                acc[isPluggable].push({
                    id: actionGroup.domain,
                    permissions: [actionGroup],
                });
            }

            return acc;
        },
        { general: [], specific: [] },
    );

    actionGroupMap.general = actionGroupMap.general.sort((prev, next) => {
        const prevMenuIndex = SHOW_MENU_OPTIONS.findIndex((menu) => menu.name === prev.id);
        const nextMenuIndex = SHOW_MENU_OPTIONS.findIndex((menu) => menu.name === next.id);

        return prevMenuIndex - nextMenuIndex;
    });
    return actionGroupMap;
};

export const getRoles = (projectId, params) => async (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: ROLES_MANAGEMENT_GET_ROLES_PENDING });
    try {
        const response = await getRolesAPI(stage.endpoint, projectId, params);
        dispatch({ type: ROLES_MANAGEMENT_GET_ROLES_SUCCESS, payload: response.data });
        return response.data?.nextToken;
    } catch (error) {
        dispatch({ type: ROLES_MANAGEMENT_GET_ROLES_FAILURE, payload: error });
    }
};

export const getActionGroups = (projectId, params) => async (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: ROLES_MANAGEMENT_GET_ACTION_GROUPS_PENDING });
    try {
        let nextToken = null;
        const allRoles = [];

        do {
            const response = await getActionGroupsAPI(stage.endpoint, projectId, {
                ...params,
                isAssignable: true,
                nextToken,
            });
            allRoles.push(...response.data?.results);
            nextToken = response.data?.nextToken;
        } while (!!nextToken);

        if (params.scope === "SPACE") {
            do {
                const response = await getActionGroupsAPI(stage.endpoint, projectId, {
                    scope: "PROJECT",
                    isAssignable: true,
                    nextToken,
                });
                allRoles.push(...response.data?.results);
                nextToken = response.data?.nextToken;
            } while (!!nextToken);
        }

        dispatch({
            type: ROLES_MANAGEMENT_GET_ACTION_GROUPS_SUCCESS,
            payload: { data: makeActionGroupByPluggable(allRoles), originalData: allRoles },
        });
    } catch (error) {
        dispatch({ type: ROLES_MANAGEMENT_GET_ACTION_GROUPS_FAILURE, payload: error });
        console.error(error);
    }
};

export const getRole =
    (projectId, id, withoutPending = false) =>
    async (dispatch, getState) => {
        const { stage } = getState();

        if (!withoutPending) {
            dispatch({ type: ROLES_MANAGEMENT_GET_ROLE_PENDING });
        }
        try {
            const response = await getRoleAPI(stage.endpoint, projectId, id);
            dispatch({ type: ROLES_MANAGEMENT_GET_ROLE_SUCCESS, payload: response.data });
        } catch (error) {
            dispatch({ type: ROLES_MANAGEMENT_GET_ROLE_FAILURE, payload: error });
        }
    };

export const updateRole = (projectId, roleId, data) => async (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: ROLES_MANAGEMENT_UPDATE_ROLE_PENDING });
    try {
        await updateRoleAPI(stage.endpoint, projectId, roleId, data);
        await dispatch(getRole(projectId, roleId, true));
        dispatch({ type: ROLES_MANAGEMENT_UPDATE_ROLE_SUCCESS });
    } catch (error) {
        dispatch({ type: ROLES_MANAGEMENT_UPDATE_ROLE_FAILURE, payload: error });
    }
};

export const updateRoleActions = (projectId, roleId, data) => async (dispatch, getState) => {
    const { stage } = getState();

    dispatch({ type: ROLES_MANAGEMENT_UPDATE_ROLE_PENDING });
    try {
        await updateRoleActionsAPI(stage.endpoint, projectId, roleId, data);
        await dispatch(getRole(projectId, roleId, true));
        dispatch({ type: ROLES_MANAGEMENT_UPDATE_ROLE_SUCCESS });
    } catch (error) {
        dispatch({ type: ROLES_MANAGEMENT_UPDATE_ROLE_FAILURE, payload: error });
    }
};

export const getMyProjectPermissions = () => async (dispatch, getState) => {
    const { stage, project, roleManagementV2 } = getState();

    if (!roleManagementV2.projectPermissions?.data)
        dispatch({ type: ROLES_MANAGEMENT_GET_MY_PROJECT_PERMISSIONS_PENDING });
    try {
        let nextToken = null;
        let results = [];

        do {
            const response = await getMyProjectPermissionsAPI(stage.endpoint, project.id, nextToken);
            results = results.concat(response.data.results);
            nextToken = response.data?.nextToken;
        } while (!!nextToken);

        dispatch({
            type: ROLES_MANAGEMENT_GET_MY_PROJECT_PERMISSIONS_SUCCESS,
            payload: results,
        });
    } catch (error) {
        console.error(error);
        dispatch({ type: ROLES_MANAGEMENT_GET_MY_PROJECT_PERMISSIONS_FAILURE, payload: error });
    }
};
