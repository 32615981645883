const production = {
    host: {
        console: "https://console.cloudplex.megazone.io",
        cms: "https://cms.cloudplex.megazone.io",
        api: "https://api.cloudplex.megazone.io",
        analysis: "https://8l37gc13a3.execute-api.ap-northeast-2.amazonaws.com",
        loginRedirectUri: "/megazone/login",
    },
    mz_pops: {
        oauth_host: "https://login.megazone.com",
        api_host: "https://www.megazoneapis.com",
        clientId: "d1J99hKQAdWH1C9lKM70QqeCDJI3zu",
    },
    profileId: "1536304085jekLFQq1",
    PLAYER_URL: "https://d1c0zwuyn5xls7.cloudfront.net", //'https://mzc-cm-prd-players.s3.ap-northeast-2.amazonaws.com'
    cloudplex: {
        startApiEndpoint: "https://1kk99cdimk.execute-api.ap-northeast-2.amazonaws.com/prod",
        clientId: "p77NsOvO70Efsd2Lcmsx8MXg0GoTix",
    },

    // hotjar_tracking_code: {
    //     hjid: "3070899",
    //     hjsv: "6",
    // },
};

export default production;
