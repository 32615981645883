import { axios } from "../../cores/axiosWrapper";
import queryString from "../../cores/queryString";

const SettingApi = {
    getSettingsAPI,
};

export function getSettingsAPI(stageId, apiEndpoint, projectId, params) {
    const headers = {
        stageId,
    };
    if (projectId) {
        headers.projectId = projectId;
    }
    return axios.get(`${apiEndpoint}/settings?${queryString.stringify(params)}`, {
        headers,
    });
}

export default SettingApi;
