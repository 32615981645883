const stage = {
    host: {
        console: "https://console.cloudplex.stg.megazone.io",
        cms: "https://cms.cloudplex.stg.megazone.io",
        //api: "http://localhost:60302"
        //api: "http://mz-cm-api-load-balancer-1319778791.ap-northeast-2.elb.amazonaws.com"
        api: "https://api.cloudplex.stg.megazone.io",
        // api: "https://api.media.stg.continuum.co.kr"
        analysis: "https://6qmtbkh830.execute-api.ap-northeast-2.amazonaws.com",
        loginRedirectUri: "/megazone/login",
    },
    mz_pops: {
        oauth_host: "https://login.megazone.com",
        api_host: "https://www.megazoneapis.com",
        clientId: "bRq7VLC1RdhUAZC9Dc4LRxfttESyjI",
    },
    videojs: {
        mux: {
            envKey: "30a1pj72764tkha4f5i6piol8",
        },
    },
    profileId: "1536304085jekLFQq1",
    PLAYER_URL: "https://d2v01bw13p90d5.cloudfront.net",
    cloudplex: {
        startApiEndpoint: "https://sv6vehudc8.execute-api.ap-northeast-2.amazonaws.com/demo",
        clientId: "bY8QJtuCEL5Xz0gOsDNBCmDLVjoJVs"
    }
    // hotjar_tracking_code: {
    //     hjid: "3055764",
    //     hjsv: "6",
    // },
};

export default stage;
