import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Checkbox, Popper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mzc-pdc/ui";
import ListToolCollapse from "@features/list-tools/list-tool-collapse";
import { Paper } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { NoSearchResultsIcon } from "@features/no-search-results-icon";
import { updateFilter } from "@modules/filter";
import { useDispatch } from "react-redux";
import useFilter from "@hooks/useFilter";

const AutocompleteTablePopper = ({
    domain,
    onFetchData,
    placement,
    open,
    isCollapsed,
    handleClearCheckAll,
    handleChangeCheckAll,
    onHandleCheckClick,
    toolSize,
    checkedList,
    options,
    isPending,
    listToolsButtons,
    filter,
    getValueByColumn,
    columns,
    skeletonColumns,
    anchorEl,
    width,
    disablePortal,
    viewMore,
    hideCheckbox,
    filterDisabled,
}) => {
    const [targetRef, inView] = useInView();
    const DEFAULT_SKELETON_CNT = 20;

    const { filterValue } = useFilter();

    useEffect(() => {
        if (isPending) return;
        if (inView && viewMore) {
            onFetchData();
        }
    }, [inView]);

    useEffect(() => {
        if (!open) return;
    }, [open]);

    const getSkeletonCardLength = useCallback(() => {
        if (!options || options?.length === 0) return DEFAULT_SKELETON_CNT;
        return 3;
    }, [isPending]);

    const isChecked = useCallback(
        (option) => {
            return checkedList.find((x) => x.id === option.id) !== undefined;
        },
        [checkedList],
    );

    const filteredOptions = useMemo(() => {
        return filterDisabled
            ? options?.filter((val) => {
                  return !filter?.some((el) => val[el.id] !== el.value);
              })
            : options?.filter((val) => {
                  return Object.keys(filterValue).every((key) => {
                      if (Array.isArray(filterValue[key])) {
                          return filterValue[key].map((v) => v.value).includes(val[key]);
                      } else {
                          return val[key] === filterValue[key];
                      }
                  });
              });
    }, [filterDisabled, options, filterValue]);

    return (
        <Popper
            id={"autocomplete-table-popper"}
            disablePortal={disablePortal}
            anchorEl={anchorEl}
            placement={placement}
            open={open}
            sx={{ width, maxHeight: "500px", backgroundColor: "#fff", zIndex: 950 }}
        >
            <Paper>
                <Box p={2}>
                    <ListToolCollapse
                        type={domain}
                        isCollapsed={isCollapsed}
                        handleClearCheckAll={handleClearCheckAll}
                        handleChangeCheckAll={handleChangeCheckAll}
                        toolSize={toolSize}
                        numSelected={checkedList?.length}
                        pageCount={options?.length}
                        buttons={listToolsButtons}
                        filter={filter}
                        hideCheckbox={hideCheckbox}
                        filterDisabled={filterDisabled}
                    />

                    <TableContainer
                        sx={{
                            maxHeight: "300px",
                        }}
                    >
                        {!isPending && !options?.length && <NoSearchResultsIcon />}
                        <Table stickyHeader shadowHeader borderHeader>
                            <TableBody>
                                {options?.length > 0 &&
                                    filteredOptions?.map((option, index) => (
                                        <TableRow
                                            key={`autocomplete-table-row-${index}`}
                                            id={option.id}
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onHandleCheckClick(!isChecked(option), option);
                                            }}
                                            selected={checkedList.find((item) => item.id === option.id) !== undefined}
                                        >
                                            <TableCell
                                                style={{ paddingRight: "0px", width: "16px" }}
                                                padding="checkbox"
                                            >
                                                {!hideCheckbox && (
                                                    <Checkbox
                                                        color={"greySecondary"}
                                                        checked={isChecked(option)}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            onHandleCheckClick(!isChecked(option), option);
                                                        }}
                                                    />
                                                )}
                                            </TableCell>
                                            {columns
                                                .filter((item) => item.useInAutocomplete)
                                                .map((column, index) => (
                                                    <TableCell
                                                        key={`autocomplete-table-cell-${index}`}
                                                        style={{
                                                            ...column.style?.custom,
                                                            paddingLeft: column.id === "name" ? "0px" : undefined,
                                                        }}
                                                    >
                                                        {getValueByColumn(column.id, option)}
                                                    </TableCell>
                                                ))}
                                        </TableRow>
                                    ))}
                                {viewMore && <div ref={targetRef} />}
                                {isPending &&
                                    new Array(getSkeletonCardLength())
                                        .fill("")
                                        .map((_, i) => (
                                            <TableRow key={`autocomplete-table-skeleton-${i}`}>
                                                {skeletonColumns}
                                            </TableRow>
                                        ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Paper>
        </Popper>
    );
};

export default AutocompleteTablePopper;
