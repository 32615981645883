import React, { Component, useEffect, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import {Box, Grid, Typography,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mzc-pdc/ui";
import {IconEmptyData} from "@mzc-cloudplex/icons";
import theme from "@styles/theme";

const AudioView = (props) => {
    const { t } = useTranslation();
    const [audioSettings, setAudioSettings] = useState([]);

    useEffect(() => {
        const audioSettings = [];
        const sources = props.sources;

        if (sources && sources.length > 0) {
            const abrSources = sources.filter((s) => s.type === "HLS" || s.type === "DASH");
            if (abrSources && abrSources.length > 0) {
                putIntoAudioSettings(audioSettings, abrSources[0].elements);
            }
        } else {
            // asset으로 바로 들어오는 경우임.
            putIntoAudioSettings(audioSettings, props.elements);
        }
        setAudioSettings(audioSettings);
    }, []);

    const putIntoAudioSettings = (array, elements) => {
        if (elements) {
            const audioElements = elements.filter(
                (e) => !e.videoSetting && e.audioSettings && e.audioSettings.length > 0,
            );
            audioElements.forEach((element) => {
                element.audioSettings.forEach((audioSetting) => {
                    array.push(audioSetting);
                });
            });
        }
    };

    return (
        <React.Fragment>
            {audioSettings?.length === 0 ? (
                <Grid container flexDirection={'column'} alignItems={'center'} justifyContent={'center'} gap={1} style={{ padding: "50px" }}>
                    <IconEmptyData size={40}/>
                    <Typography>{t(`common::label::${"Empty"}`)}</Typography>
                </Grid>
            ) : (
                <>
                    <Grid container justifyContent={'flex-end'} gap={0.5}>
                        <Typography>
                        {t(`common::label::${"Total"}`)}
                        </Typography>
                        <Typography variant={'body1_700'} color={'primary.main'}>
                            {audioSettings?.length ?? 0}
                        </Typography>
                    </Grid>
                    <TableContainer sx={{mt: 1.5}}>
                        <Table borderHeader  size={'small'}>
                            <colgroup>
                                <col width=""/>
                                <col width=""/>
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{backgroundColor: theme.palette.grey[50]}}>{t(`common::label::Label`)}</TableCell>
                                    <TableCell sx={{backgroundColor: theme.palette.grey[50]}}>{t(`common::label::Language`)}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {audioSettings?.map((a, i) => {
                                    return (
                                      <TableRow key={i}>
                                          <TableCell>
                                              <span>{a.streamName || t(`common::label::${"Undefined"}`)}</span>
                                          </TableCell>
                                          <TableCell>{a.languageCode || "UND"}</TableCell>
                                      </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </>
            )}
        </React.Fragment>
    );
};

export default AudioView;
