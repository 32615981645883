import { handleActions, createAction } from "redux-actions";
import Polling from "@cores/polling";
import VideoApi from "./apis/video";
import AssetApi from "./apis/asset";
import { getExternalShareDetailAPI } from "./shareRequest";
import HiddenMetadataApi from "./apis/hiddenMetadata";
import { axios } from "@cores/axiosWrapper";
import {
    ASSET_TYPES,
    PROPERTY_PANEL_TYPE,
    MEDIA_TYPES,
    CONTENT_DETAIL_ACTION_TYPE,
    METADATA_FIELDS_TYPE,
} from "@constants";
import compareVersions, { VERSION_SAFARI_ENCODING_FOR_PRESIGN_AND_UPLOAD } from "@cores/version";
import { getDetailDriveFolder } from "./folderTreeView";
import {
    getCollectionAPI,
    getLinkedCollectionsAPI,
    getLinkedAssetsAPI,
    updateCollectionAPI,
    updateCollectionActionAPI,
} from "./apis/collection";
import { resolve } from "promise-polyfill";
import {
    getCustomContentDetailAPI,
    updateCustomContentActionsAPI,
    updateCustomContentAPI,
} from "@modules/apis/custom-content";
import { parseEnumType, parseTypeToFieldType } from "@modules/content-schema";
import { getCategoryGroup } from "@modules/categoryGroupsManagement";

async function presignObjectAPI(apiEndpoint, stageVersion, projectId, data) {
    let headers = { projectId };
    let requestBody = {
        ...data,
    };
    if (compareVersions(stageVersion, VERSION_SAFARI_ENCODING_FOR_PRESIGN_AND_UPLOAD) >= 0) {
        requestBody = Buffer.from(encodeURIComponent(JSON.stringify(requestBody))).toString("base64");
        headers = {
            ...headers,
            "x-mzc-content-encoding": "base64",
        };
    }
    return await axios.post(`${apiEndpoint}/aws/s3/objects:presign`, requestBody, {
        headers,
    });
}

function getAssetAPI(stageId, apiEndpoint, projectId, id) {
    return axios.get(`${apiEndpoint}/assets/${id}?generateAccessUrl=true`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function getSharedAssetAPI(stageId, apiEndpoint, projectId, id) {
    return axios.get(`${apiEndpoint}/v2/shared-assets/proxy/assets/${id}?generateAccessUrl=true`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function getVideoAPI(stageId, apiEndpoint, projectId, id) {
    return axios.get(`${apiEndpoint}/videos/${id}?generateAccessUrl=true`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function getContentAPI(stageId, apiEndpoint, projectId, type, id) {
    return axios.get(`${apiEndpoint}/contents/${type.toLowerCase()}/${id}?generateAccessUrl=true`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function getPeopleAPI(stageId, apiEndpoint, projectId, id) {
    return axios.get(`${apiEndpoint}/people/${id}?generateAccessUrl=true`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function getArchivedAssetAPI(stageId, apiEndpoint, projectId, id) {
    return axios.get(`${apiEndpoint}/archived-assets/${id}?generateAccessUrl=true`, {
        headers: {
            stageId,
            projectId,
        },
    });
}

function redefineAssetData(data) {
    if (data.type === ASSET_TYPES.THUMBNAILS) {
        data.resolution =
            data.elements && data.elements[0]
                ? {
                      width: data.elements[0].width,
                      height: data.elements[0].height,
                  }
                : null;
    }

    if (data.mediaType === MEDIA_TYPES.VIDEO) {
        data.renditions = data.elements.map((v) => v.videoSetting).filter((v) => v);
    }

    if (data.mediaType === MEDIA_TYPES.AUDIO) {
        data.renditions = data.elements
            .reduce((acc, curr) => {
                return acc.concat(curr.audioSettings);
            }, [])
            .filter((v) => v);
    }

    if (data.mediaType === MEDIA_TYPES.VIDEO || data.mediaType === MEDIA_TYPES.AUDIO) {
        data.elements =
            data.accessUrls && data.accessUrls.length > 0
                ? data.accessUrls
                      .map((v) => {
                          return { accessUrls: [v] };
                      })
                      .concat(data.elements)
                : data.elements;
    }

    return data;
}

//action type
const PROPERTY_PANEL_PENDING = "PROPERTY_PANEL_PENDING";
const PROPERTY_PANEL_FAILURE = "PROPERTY_PANEL_FAILURE";

const PROPERTY_PANEL_UPDATE_STATE = "PROPERTY_PANEL_UPDATE_STATE";
const PROPERTY_PANEL_TOGGLE_PROPERTY_PANEL = "PROPERTY_PANEL_TOGGLE_PROPERTY_PANEL";
const PROPERTY_PANEL_OPEN_PROPERTY_PANEL = "PROPERTY_PANEL_OPEN_PROPERTY_PANEL";
const PROPERTY_PANEL_CLOSE_PROPERTY_PANEL = "PROPERTY_PANEL_CLOSE_PROPERTY_PANEL";

const PROPERTY_PANEL_GET_ASSET_SUCCESS = "PROPERTY_PANEL_GET_ASSET_SUCCESS";
const PROPERTY_PANEL_VIDEO_SUCCESS = "PROPERTY_PANEL_VIDEO_SUCCESS";
const PROPERTY_PANEL_PEOPLE_SUCCESS = "PROPERTY_PANEL_PEOPLE_SUCCESS";
const PROPERTY_PANEL_ARCHIVED_ASSET_SUCCESS = "PROPERTY_PANEL_ARCHIVED_ASSET_SUCCESS";
const PROPERTY_PANEL_CONTENT_SUCCESS = "PROPERTY_PANEL_CONTENT_SUCCESS";
const PROPERTY_PANEL_EXTERNAL_SHARE_SUCCESS = "PROPERTY_PANEL_EXTERNAL_SHARE_SUCCESS";
const PROPERTY_PANEL_CUSTOM_CONTENT_SUCCESS = "PROPERTY_PANEL_CUSTOM_CONTENT_SUCCESS";

const PROPERTY_PANEL_GET_RELATED_ASSET_LIST_PENDING = "PROPERTY_PANEL_GET_RELATED_ASSET_LIST_PENDING";
const PROPERTY_PANEL_GET_RELATED_ASSET_LIST_SUCCESS = "PROPERTY_PANEL_GET_RELATED_ASSET_LIST_SUCCESS";
const PROPERTY_PANEL_GET_RELATED_ASSET_LIST_FAILURE = "PROPERTY_PANEL_GET_RELATED_ASSET_LIST_FAILURE";

const PROPERTY_PANEL_UPDATE_ASSET_STATUS = "PROPERTY_PANEL_UPDATE_ASSET_STATUS";

const PROPERTY_PANEL_GET_HIDDEN_METADATA_VALUE_PENDING = "PROPERTY_PANEL_GET_HIDDEN_METADATA_VALUE_PENDING";
const PROPERTY_PANEL_GET_HIDDEN_METADATA_SET_EMPTY = "PROPERTY_PANEL_GET_HIDDEN_METADATA_SET_EMPTY";
const PROPERTY_PANEL_GET_HIDDEN_METADATA_VALUE_SUCCESS = "PROPERTY_PANEL_GET_HIDDEN_METADATA_VALUE_SUCCESS";
const PROPERTY_PANEL_GET_HIDDEN_METADATA_VALUE_FAILURE = "PROPERTY_PANEL_GET_HIDDEN_METADATA_VALUE_FAILURE";

const PRESIGN_OBJECT_PENDING = "PRESIGN_OBJECT_PENDING";
const PRESIGN_OBJECT_SUCCESS = "PRESIGN_OBJECT_SUCCESSS";
const PRESIGN_OBJECT_FAILURE = "PRESIGN_OBJECT_FAILURE";

const PROPERTY_PANEL_GET_COLLECTIONS_SUCCESS = "PROPERTY_PANEL_GET_COLLECTIONS_SUCCESS";
const PROPERTY_PANEL_GET_LINKED_COLLECTIONS_PENDING = "PROPERTY_PANEL_GET_LINKED_COLLECTIONS_PENDING";
const PROPERTY_PANEL_GET_LINKED_COLLECTIONS_SUCCESS = "PROPERTY_PANEL_GET_LINKED_COLLECTIONS_SUCCESS";
const PROPERTY_PANEL_GET_LINKED_COLLECTIONS_FAILURE = "PROPERTY_PANEL_GET_LINKED_COLLECTIONS_FAILURE";
const PROPERTY_PANEL_GET_LINKED_ASSETS_SUCCESS = "PROPERTY_PANEL_GET_LINKED_COLLECTIONS_SUCCESS";
const PROPERTY_PANEL_CLEAR_GET_LINKED_ASSETS = "PROPERTY_PANEL_CLEAR_GET_LINKED_ASSETS";

const PROPERTY_PANEL_UPDATE_DATA = "PROPERTY_PANEL_UPDATE_DATA";
const PROPERTY_PANEL_UPDATE_ACTION_SUCCESS = "PROPERTY_PANEL_UPDATE_ACTION_SUCCESS";

//reducer
const initialState = {
    targetId: null,
    type: null,
    pending: false,
    accessError: false,
    error: false,
    isShow: false,
    data: null,
    contextVersion: 0,
    contents: {
        id: null,
        type: null,
    },
    relatedAssets: {
        pending: false,
        error: false,
        data: [],
        totalCount: 0,
    },
    sharedAssetResourceToken: null,
    presignedUrl: null,
    shared: null,
    shares: null,
};

export default handleActions(
    {
        [PROPERTY_PANEL_PENDING]: (state) => {
            return {
                ...state,
                contextVersion: state.contextVersion + 1,
                pending: true,
                error: false,
            };
        },

        [PROPERTY_PANEL_FAILURE]: (state) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [PROPERTY_PANEL_GET_RELATED_ASSET_LIST_PENDING]: (state) => {
            return {
                ...state,
                relatedAssets: {
                    ...state.relatedAssets,
                    pending: true,
                },
            };
        },
        [PROPERTY_PANEL_GET_RELATED_ASSET_LIST_FAILURE]: (state) => {
            return {
                ...state,
                relatedAssets: {
                    ...state.relatedAssets,
                    pending: false,
                    error: true,
                },
            };
        },
        [PROPERTY_PANEL_GET_RELATED_ASSET_LIST_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            return {
                ...state,
                relatedAssets: {
                    ...state.relatedAssets,
                    totalCount: data.totalCount,
                    data: data.assets,
                    pending: false,
                    error: false,
                },
            };
        },
        [PROPERTY_PANEL_UPDATE_STATE]: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
        [PROPERTY_PANEL_GET_ASSET_SUCCESS]: (state, action) => {
            const { data, headers } = action.payload;
            const resourceToken = headers["x-mzc-cp-resource-token"] || null;

            let contentsType = data.mediaType === MEDIA_TYPES.AUDIO ? MEDIA_TYPES.VIDEO : data.mediaType;
            if ([ASSET_TYPES.CAPTION, ASSET_TYPES.DESCRIPTION].includes(data.type)) {
                contentsType = data.type;
            }

            return {
                ...state,
                pending: false,
                error: false,
                contents: {
                    type: contentsType,
                    id: data.id,
                },
                data: data && redefineAssetData(data),
                sharedAssetResourceToken: resourceToken,
            };
        },
        [PROPERTY_PANEL_PEOPLE_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            return {
                ...state,
                pending: false,
                error: false,
                contents: {
                    type: MEDIA_TYPES.PEOPLE,
                    id: data.id,
                },
                data: {
                    ...data,
                },
            };
        },
        [PROPERTY_PANEL_ARCHIVED_ASSET_SUCCESS]: (state, action) => {
            const { archivedAsset, asset } = action.payload;

            return {
                ...state,
                pending: false,
                error: false,
                contents: {
                    type: MEDIA_TYPES.ARCHIVED_ASSET,
                    id: archivedAsset.id,
                },
                data: {
                    ...asset,
                    id: null,
                    status: null,
                    folderPath: "",
                    archivedAssetId: archivedAsset.id,
                    assetId: asset.id,
                    archivedAssetStatus: archivedAsset.status,
                    archivedAssetUrl: archivedAsset.url,
                    archiveBy: archivedAsset.archiveBy,
                    archivedAt: archivedAsset.archivedAt,
                },
            };
        },
        [PROPERTY_PANEL_VIDEO_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            const preferredAsset = data.sources && data.sources[0];

            return {
                ...state,
                pending: false,
                error: false,
                contents: {
                    type: MEDIA_TYPES.VIDEO,
                    id: preferredAsset && preferredAsset.id,
                },
                data: {
                    ...data,
                    elements:
                        preferredAsset?.accessUrls && preferredAsset.accessUrls.length > 0
                            ? preferredAsset.accessUrls
                                  .map((v) => {
                                      return { accessUrls: [v] };
                                  })
                                  .concat(preferredAsset.elements)
                            : preferredAsset?.elements,
                    encryptions: preferredAsset?.encryptions,
                },
            };
        },
        [PROPERTY_PANEL_CONTENT_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const { contentType } = action;

            const sources = data.sources;
            const isPreferredAsset = sources && sources.find((v) => v.isPreferred);
            const preferredAsset = isPreferredAsset ? isPreferredAsset.item : sources && sources[0] && sources[0].item;
            let contentsType;

            if (contentType === "Music") contentsType = MEDIA_TYPES.VIDEO;
            else if (contentType === "Photo") contentsType = MEDIA_TYPES.IMAGE;

            return {
                ...state,
                pending: false,
                error: false,
                contents: {
                    type: contentsType,
                    id: preferredAsset && preferredAsset.id,
                },
                data: {
                    ...data,
                    encryptions: preferredAsset && preferredAsset.encryptions,
                },
            };
        },
        [PROPERTY_PANEL_TOGGLE_PROPERTY_PANEL]: (state, action) => {
            const { key, targetId, type, projectId, sharedResourceId, data, error, accessError, ...rest } =
                action.payload;

            const _key = key || targetId;
            const isShow = state.isShow ? state.key !== _key : !state.isShow;

            return {
                ...initialState,
                key: isShow ? _key : -1,
                targetId: isShow ? targetId : null,
                type,
                isShow,
                data,
                projectId: isShow ? projectId : null,
                error: error,
                accessError: accessError,
                sharedResourceId: sharedResourceId,
                ...rest,
            };
        },
        [PROPERTY_PANEL_OPEN_PROPERTY_PANEL]: (state, action) => {
            const { key, targetId, type, projectId } = action.payload;
            return {
                ...state,
                key: key || targetId,
                targetId: targetId,
                type,
                isShow: true,
                projectId: projectId,
            };
        },
        [PROPERTY_PANEL_CLOSE_PROPERTY_PANEL]: (state, action) => {
            return {
                ...state,
                key: null,
                targetId: null,
                type: null,
                isShow: false,
                projectId: null,
            };
        },
        [PROPERTY_PANEL_UPDATE_ASSET_STATUS]: (state, action) => {
            const { data } = action.payload;
            return {
                ...state,
                data: {
                    ...state.data,
                    status: data.status,
                },
            };
        },
        [PROPERTY_PANEL_GET_HIDDEN_METADATA_VALUE_PENDING]: (state, action) => {
            const { metadataSchemaId, metadataFieldId } = action.payload;

            const metadataSchemas = refineMetadata(state.data.metadataSchemas, metadataSchemaId, metadataFieldId, {
                pending: true,
                error: false,
            });

            return {
                ...state,
                data: {
                    ...state.data,
                    metadataSchemas,
                },
            };
        },
        [PROPERTY_PANEL_GET_HIDDEN_METADATA_SET_EMPTY]: (state, action) => {
            const { metadataSchemaId, metadataFieldId } = action.payload;

            const metadataSchemas = refineMetadata(state.data.metadataSchemas, metadataSchemaId, metadataFieldId, {
                pending: false,
                error: false,
                value: "",
                values: [],
                isCheckHiddenValue: true,
            });

            return {
                ...state,
                data: {
                    ...state.data,
                    metadataSchemas,
                },
            };
        },
        [PROPERTY_PANEL_GET_HIDDEN_METADATA_VALUE_SUCCESS]: (state, action) => {
            const { metadataSchemaId, metadataFieldId, response } = action.payload;

            const metadataSchemas = refineMetadata(state.data.metadataSchemas, metadataSchemaId, metadataFieldId, {
                pending: false,
                error: false,
                value: response.data.value,
                values: response.data.values,
                isCheckHiddenValue: true,
            });

            return {
                ...state,
                data: {
                    ...state.data,
                    metadataSchemas,
                },
            };
        },
        [PROPERTY_PANEL_GET_HIDDEN_METADATA_VALUE_FAILURE]: (state, action) => {
            const { metadataSchemaId, metadataFieldId, error } = action.payload;

            const metadataSchemas = refineMetadata(state.data.metadataSchemas, metadataSchemaId, metadataFieldId, {
                pending: false,
                error,
                isCheckHiddenValue: false,
            });

            return {
                ...state,
                data: {
                    ...state.data,
                    metadataSchemas,
                },
            };
        },
        [PRESIGN_OBJECT_PENDING]: (state, action) => {
            const { fileName } = action.payload;
            return {
                ...state,
                pending: true,
                presignedUrl: null,
                error: null,
            };
        },
        [PRESIGN_OBJECT_SUCCESS]: (state, action) => {
            const { presignedUrl } = action.payload.data;
            console.log(`PRESIGN_OBJECT_SUCCESS: ${presignedUrl}`);
            return {
                ...state,
                pending: false,
                presignedUrl: presignedUrl,
            };
        },
        [PRESIGN_OBJECT_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: action.payload,
            };
        },
        [PROPERTY_PANEL_EXTERNAL_SHARE_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
                data: action.payload,
            };
        },
        [PROPERTY_PANEL_GET_COLLECTIONS_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
                data: action.payload,
            };
        },
        [PROPERTY_PANEL_GET_LINKED_COLLECTIONS_SUCCESS]: (state, action) => {
            const { results, nextToken } = action.payload;
            return {
                ...state,
                pending: false,
                data: {
                    ...state.data,
                    linkedCollections: state.data?.linkedCollections
                        ? [...state.data?.linkedCollections, ...results]
                        : results || [],
                    nextToken,
                },
            };
        },
        [PROPERTY_PANEL_GET_LINKED_ASSETS_SUCCESS]: (state, action) => {
            return {
                ...state,
                pending: false,
                data: {
                    ...state.data,
                    linkedAssets: { ...state.data.linkedAssets, pending: false, ...action.payload },
                    assetTotalCount: action.payload.totalCount,
                },
            };
        },
        [PROPERTY_PANEL_CLEAR_GET_LINKED_ASSETS]: (state, action) => {
            return { ...state, data: { ...state.data, linkedAssets: undefined, nextToken: null } };
        },
        [PROPERTY_PANEL_UPDATE_DATA]: (state, action) => {
            return { ...state, data: { ...state.data, ...action.payload } };
        },
        [PROPERTY_PANEL_CUSTOM_CONTENT_SUCCESS]: (state, action) => {
            return { ...state, data: { ...state.date, ...action.payload }, pending: false };
        },
        [PROPERTY_PANEL_UPDATE_ACTION_SUCCESS]: (state, action) => {
            if (action.payload.action === CONTENT_DETAIL_ACTION_TYPE.OVERWRITE_FIELD_VALUES) {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        fields: state.data.fields.map((field) => {
                            if (field?.fieldType?.dataType?.isReference) return field;

                            if (field.id === action.payload.data.fields[0].id) {
                                if (
                                    [METADATA_FIELDS_TYPE.MULTI_SELECT, METADATA_FIELDS_TYPE.SINGLE_SELECT].includes(
                                        field.fieldType?.dataType?.detailType,
                                    )
                                ) {
                                    return {
                                        ...parseEnumType(
                                            { ...field, value: action.payload.data.fields[0].value },
                                            "detail",
                                        ),
                                        value: action.payload.data.fields[0].value,
                                    };
                                } else {
                                    return { ...field, value: action.payload.data.fields[0].value };
                                }
                            } else return field;
                        }),
                    },
                };
            }

            if (action.payload.action === CONTENT_DETAIL_ACTION_TYPE.OVERWRITE_CATEGORIES) {
                return {
                    ...state,
                    data: { ...state.data, categories: action.payload.data?.categories?.map((id) => ({ id })) },
                };
            }
            return {
                ...state,
                data: { ...state.data, ...action.payload.data },
            };
        },
    },
    initialState,
);

// TODO: https://mzdevs.atlassian.net/browse/CM-2583
// Video / Asset status polling 적용

// TODO: PropertyPanel을 사용하는 화면에서 WillUnmount 이벤트시 contextVersion을 증가시켜주는 거 만들기.

export const updateState = createAction(PROPERTY_PANEL_UPDATE_STATE);
export const togglePropertyPanel = createAction(PROPERTY_PANEL_TOGGLE_PROPERTY_PANEL);
export const openPropertyPanel = createAction(PROPERTY_PANEL_OPEN_PROPERTY_PANEL);
export const closePropertyPanel = createAction(PROPERTY_PANEL_CLOSE_PROPERTY_PANEL);
export const clearPropertyPanelLinkedAssets = createAction(PROPERTY_PANEL_CLEAR_GET_LINKED_ASSETS);
export const updatePropertyPanelData = createAction(PROPERTY_PANEL_UPDATE_DATA);

export const presignObject = (data) => async (dispatch, getState) => {
    const { stage, project } = getState();
    dispatch({ type: PRESIGN_OBJECT_PENDING, payload: { name: data.name } });

    try {
        const response = await presignObjectAPI(stage.endpoint, stage.version, project.id, data);
        dispatch({ type: PRESIGN_OBJECT_SUCCESS, payload: response });
        return response.data.presignedUrl;
    } catch (e) {
        console.error(`failed to presign object`, e);
        dispatch({ type: PRESIGN_OBJECT_FAILURE, payload: e.response.data.message });
    }
    return null;
};

function getExeucteApi(type) {
    if (type === PROPERTY_PANEL_TYPE.VIDEO) {
        return getVideo;
    } else if (type === PROPERTY_PANEL_TYPE.PEOPLE) {
        return getPeople;
    } else if (type === PROPERTY_PANEL_TYPE.ARCHIVED_ASSET) {
        return getArchivedAsset;
    } else if (type === PROPERTY_PANEL_TYPE.SHARED_ASSET) {
        return getAsset;
    } else if (type === PROPERTY_PANEL_TYPE.EXTERNAL_SHARED_HISTORY) {
        return getExternalShareDetail;
    } else if (type === PROPERTY_PANEL_TYPE.MUSIC || type === PROPERTY_PANEL_TYPE.PHOTO) {
        return getContent;
    } else if (type === PROPERTY_PANEL_TYPE.COLLECTION) {
        return getCollection;
    } else if (type === PROPERTY_PANEL_TYPE.CUSTOM_CONTENT) {
        return getCustomContentDetail;
    } else {
        return getAsset;
    }
}

export const getData =
    ({ type, id, projectId }) =>
    (dispatch, getState) => {
        const executeAPI = getExeucteApi(type);
        return executeAPI({ id, type, projectId })(dispatch, getState);
    };

export const getAsset =
    ({ id, projectId }) =>
    (dispatch, getState) => {
        const { stage, project, propertyPanel } = getState();

        const contextVersion = propertyPanel.contextVersion + 1;
        dispatch({ type: PROPERTY_PANEL_PENDING });
        const stageId = stage.id;
        const apiEndpoint = stage.endpoint;
        const _projectId = projectId || project.id;

        getAssetAPI(stageId, apiEndpoint, _projectId, id)
            .then(async (response) => {
                let assetData = response;
                if (response.data.virtualFolder !== undefined && response.data.virtualDrive !== undefined) {
                    //virtualFolder 존재시 전체경로 획득
                    const folderPath = await dispatch(
                        getDetailDriveFolder({
                            driveId: response.data.virtualDrive.id,
                            folderId: response.data.virtualFolder.id,
                        }),
                    );

                    assetData = {
                        ...assetData,
                        data: {
                            ...assetData.data,
                            virtualFolderPath: folderPath.parents.reverse().reduce(
                                (acc, cur) => {
                                    if (cur.depth === 0) return `/${acc}`;
                                    return `${cur.name}/${acc}`;
                                },
                                `${folderPath.depth === 0 ? "/" : folderPath.name}`,
                            ),
                        },
                    };
                }

                dispatch({
                    type: PROPERTY_PANEL_GET_ASSET_SUCCESS,
                    payload: assetData,
                });

                const asset = assetData.data;
                const shouldPollStatus = asset.status === "ACTIVATING" || asset.status === "INACTIVATING";
                if (shouldPollStatus) {
                    const targetStatus = asset.status === "ACTIVATING" ? "ACTIVE" : "INACTIVE";
                    Polling(
                        async () => {
                            return await AssetApi.getStatus(stageId, apiEndpoint, projectId, asset.id);
                        },
                        (response) => {
                            return (
                                getState().propertyPanel.contextVersion !== contextVersion ||
                                response.data.status === targetStatus
                            );
                        },
                        (response) => {
                            dispatch({
                                type: PROPERTY_PANEL_UPDATE_ASSET_STATUS,
                                payload: response,
                            });
                        },
                        null,
                        3000,
                    ).run();
                }
            })
            .catch((e) => {
                console.log(e);
                dispatch({ type: PROPERTY_PANEL_FAILURE });
            });
    };

export const getSharedAsset =
    ({ id, projectId }) =>
    (dispatch, getState) => {
        const { stage, propertyPanel } = getState();

        dispatch({ type: PROPERTY_PANEL_PENDING });
        const stageId = stage.id;
        const apiEndpoint = stage.endpoint;

        return getSharedAssetAPI(stageId, apiEndpoint, projectId, id)
            .then((response) => {
                dispatch({
                    type: PROPERTY_PANEL_GET_ASSET_SUCCESS,
                    payload: response,
                });
                return response;
            })
            .catch((e) => {
                dispatch({ type: PROPERTY_PANEL_FAILURE });
            });
    };

export const getVideo =
    ({ id }) =>
    (dispatch, getState) => {
        const { stage, project, propertyPanel } = getState();

        const contextVersion = propertyPanel.contextVersion + 1;
        dispatch({ type: PROPERTY_PANEL_PENDING });
        //dispatch({ type : PROPERTY_PANEL_GET_RELATED_ASSET_LIST_PENDING });

        const stageId = stage.id;
        const apiEndpoint = stage.endpoint;
        const projectId = project.id;

        getVideoAPI(stageId, apiEndpoint, projectId, id)
            .then((response) => {
                dispatch({
                    type: PROPERTY_PANEL_VIDEO_SUCCESS,
                    payload: response,
                });
                const video = response.data;
                const shouldPollStatus = video.status === "ACTIVATING" || video.status === "INACTIVATING";
                if (shouldPollStatus) {
                    const targetStatus = video.status === "ACTIVATING" ? "ACTIVE" : "INACTIVE";
                    Polling(
                        async () => {
                            return await VideoApi.getStatusAsync(stageId, apiEndpoint, projectId, video.id);
                        },
                        (response) => {
                            return (
                                getState().propertyPanel.contextVersion !== contextVersion ||
                                response.data.status === targetStatus
                            );
                        },
                        (response) => {
                            dispatch({
                                type: PROPERTY_PANEL_UPDATE_ASSET_STATUS,
                                payload: response,
                            });
                        },
                        null,
                        3000,
                    ).run();
                }
            })
            .catch((e) => {
                console.trace("catch", e);
                dispatch({ type: PROPERTY_PANEL_FAILURE });
            });
    };

export const getContent =
    ({ id, type }) =>
    (dispatch, getState) => {
        const { stage, project, propertyPanel } = getState();

        dispatch({ type: PROPERTY_PANEL_PENDING });

        const stageId = stage.id;
        const apiEndpoint = stage.endpoint;
        const projectId = project.id;

        getContentAPI(stageId, apiEndpoint, projectId, type, id)
            .then((response) => {
                dispatch({
                    type: PROPERTY_PANEL_CONTENT_SUCCESS,
                    payload: response,
                    contentType: type,
                });
            })
            .catch((e) => {
                dispatch({ type: PROPERTY_PANEL_FAILURE });
            });
    };

export const getPeople =
    ({ id }) =>
    (dispatch, getState) => {
        const { stage, project } = getState();

        dispatch({ type: PROPERTY_PANEL_PENDING });

        getPeopleAPI(stage.id, stage.endpoint, project.id, id)
            .then((response) => {
                dispatch({
                    type: PROPERTY_PANEL_PEOPLE_SUCCESS,
                    payload: response,
                });
            })
            .catch((e) => {
                dispatch({ type: PROPERTY_PANEL_FAILURE });
            });
    };

export const getArchivedAsset =
    ({ id }) =>
    (dispatch, getState) => {
        const { stage, project } = getState();

        dispatch({ type: PROPERTY_PANEL_PENDING });

        const stageId = stage.id;
        const apiEndpoint = stage.endpoint;
        const projectId = project.id;

        getArchivedAssetAPI(stageId, apiEndpoint, projectId, id)
            .then((archivedAssetResult) => {
                getAssetAPI(stageId, apiEndpoint, projectId, archivedAssetResult.data.asset.id)
                    .then((assetResult) => {
                        dispatch({
                            type: PROPERTY_PANEL_ARCHIVED_ASSET_SUCCESS,
                            payload: {
                                archivedAsset: archivedAssetResult.data,
                                asset: assetResult.data,
                            },
                        });
                    })
                    .catch((err) => {
                        dispatch({ type: PROPERTY_PANEL_FAILURE });
                    });
            })
            .catch((e) => {
                dispatch({ type: PROPERTY_PANEL_FAILURE });
            });
    };

export const getHiddenMetadataValue = (id, type, metadataSchemaId, metadataFieldId) => (dispatch, getState) => {
    const { stage, project, video } = getState();

    const stageId = stage.id;
    const apiEndpoint = stage.endpoint;
    const projectId = project.id;

    dispatch({
        type: PROPERTY_PANEL_GET_HIDDEN_METADATA_VALUE_PENDING,
        payload: { metadataSchemaId, metadataFieldId },
    });

    return new Promise((resolve, reject) => {
        HiddenMetadataApi.getHiddenMetadata(stageId, apiEndpoint, projectId, type.toUpperCase(), id, metadataFieldId)
            .then((response) => {
                dispatch({
                    type: PROPERTY_PANEL_GET_HIDDEN_METADATA_VALUE_SUCCESS,
                    payload: {
                        response,
                        metadataSchemaId,
                        metadataFieldId,
                    },
                });
                resolve(response);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    dispatch({
                        type: PROPERTY_PANEL_GET_HIDDEN_METADATA_VALUE_FAILURE,
                        payload: {
                            error,
                            metadataSchemaId,
                            metadataFieldId,
                        },
                    });

                    reject(error);
                } else if (error.response.status === 404) {
                    dispatch({
                        type: PROPERTY_PANEL_GET_HIDDEN_METADATA_SET_EMPTY,
                        payload: { metadataSchemaId, metadataFieldId },
                    });
                    resolve();
                }
            });
    });
};

const refineMetadata = (target, metadataSchemaId, metadataFieldId, data) => {
    return target.map((v) => {
        if (v.id === metadataSchemaId) {
            const fields = v.fields.map((f) => {
                if (f.id === metadataFieldId) {
                    return {
                        ...f,
                        ...data,
                    };
                } else {
                    return f;
                }
            });
            return {
                ...v,
                fields,
            };
        } else {
            return v;
        }
    });
};

export const getExternalShareDetail =
    ({ id }) =>
    (dispatch, getState) => {
        const { stage, project } = getState();
        dispatch({ type: PROPERTY_PANEL_PENDING });

        return new Promise((resolve, reject) => {
            getExternalShareDetailAPI(stage.endpoint, project, id)
                .then(async (response) => {
                    let data = response.data;

                    dispatch({
                        type: PROPERTY_PANEL_EXTERNAL_SHARE_SUCCESS,
                        payload: data,
                    });
                    resolve(data);
                })
                .catch((error) => {
                    dispatch({ type: PROPERTY_PANEL_FAILURE, payload: error });
                    reject(error);
                });
        });
    };

export const getCollection =
    ({ id }) =>
    (dispatch, getState) => {
        const { stage, project } = getState();

        dispatch({ type: PROPERTY_PANEL_PENDING });

        new Promise((resolve, reject) => {
            getCollectionAPI(stage.endpoint, project.id, id)
                .then(async (response) => {
                    let data = response.data;

                    getLinkedCollectionsAPI(stage.endpoint, project.id, id)
                        .then(async (response) => {
                            dispatch({
                                type: PROPERTY_PANEL_GET_COLLECTIONS_SUCCESS,
                                payload: { ...data, linkedCollections: response.data.results },
                            });
                            resolve(data);
                        })
                        .catch((error) => {
                            dispatch({ type: PROPERTY_PANEL_FAILURE, payload: error });
                            reject(error);
                        });
                })
                .catch((error) => {
                    dispatch({ type: PROPERTY_PANEL_FAILURE, payload: error });
                    reject(error);
                });
        });
    };

export const getCustomContentDetail =
    ({ id }) =>
    (dispatch, getState) => {
        const { stage, project } = getState();

        dispatch({ type: PROPERTY_PANEL_PENDING });

        new Promise((resolve, reject) => {
            getCustomContentDetailAPI(stage.endpoint, project.id, id)
                .then(async (response) => {
                    const data = response.data;

                    const fields = data?.fields?.map((v) => {
                        const dataType = v.fieldType?.dataType;
                        const newDataType = parseTypeToFieldType(dataType);
                        const newField = { ...v, fieldType: { ...v.fieldType, dataType: { ...newDataType } } };
                        const parsedField = parseEnumType(newField, "detail");

                        return { ...parsedField };
                    });

                    let categoryGroupId = data.categories?.[0]?.groupId;

                    if (categoryGroupId) {
                        const categoryGroupInfo = await dispatch(getCategoryGroup(project.id, categoryGroupId));

                        if (categoryGroupInfo) {
                            data.categories = data.categories.map((category) => ({
                                ...category,
                                groupName: categoryGroupInfo.name,
                            }));
                        }
                    }
                    dispatch({
                        type: PROPERTY_PANEL_CUSTOM_CONTENT_SUCCESS,
                        payload: { ...data, fields },
                    });
                    resolve({ ...response.data, fields });
                })
                .catch((error) => {
                    dispatch({ type: PROPERTY_PANEL_FAILURE, payload: error });
                    reject(error);
                });
        }).then();
    };

export const getLinkedCollections =
    ({ id, params }) =>
    (dispatch, getState) => {
        const { stage, project } = getState();

        return new Promise((resolve, reject) => {
            getLinkedCollectionsAPI(stage.endpoint, project.id, id, params)
                .then(async (response) => {
                    let data = response.data;
                    dispatch({
                        type: PROPERTY_PANEL_GET_LINKED_COLLECTIONS_SUCCESS,
                        payload: response.data,
                    });
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

export const getLinkedAssets =
    ({ id, params }) =>
    (dispatch, getState) => {
        const { stage, project, propertyPanel } = getState();

        return new Promise((resolve, reject) => {
            getLinkedAssetsAPI(stage.endpoint, project.id, id, params)
                .then(async (response) => {
                    let data = response.data;
                    dispatch({
                        type: PROPERTY_PANEL_GET_LINKED_ASSETS_SUCCESS,
                        payload: {
                            ...response.data,
                            results: (propertyPanel.data.linkedAssets?.results ?? [])?.concat(response.data.results),
                        },
                    });
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

export const updateCollection =
    ({ id, params }) =>
    (dispatch, getState) => {
        const { stage, project, propertyPanel } = getState();
        const { linkedCollections, linkedAssets } = propertyPanel.data;

        return new Promise((resolve, reject) => {
            updateCollectionAPI(stage.endpoint, project.id, id, params)
                .then(async (response) => {
                    let data = response.data;
                    dispatch({
                        type: PROPERTY_PANEL_UPDATE_DATA,
                        payload: { ...data, linkedCollections, linkedAssets },
                    });
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

export const updateCollectionAction =
    ({ contentId, params }) =>
    (dispatch, getState) => {
        const { stage, project, propertyPanel } = getState();
        const data = propertyPanel.data;

        return new Promise((resolve, reject) => {
            updateCollectionActionAPI(stage.endpoint, project.id, contentId, params)
                .then(async (response) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

export const updateCustomContent =
    ({ contentId, params }) =>
    (dispatch, getState) => {
        const { stage, project } = getState();

        return new Promise((resolve, reject) => {
            updateCustomContentAPI(stage.endpoint, project.id, contentId, params)
                .then(async (response) => {
                    let data = response.data;

                    dispatch({ type: PROPERTY_PANEL_UPDATE_DATA, payload: data });
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };

export const updateCustomContentActions =
    ({ contentId, params }) =>
    (dispatch, getState) => {
        const { stage, project } = getState();
        return new Promise(async (resolve, reject) => {
            try {
                const { key, value } = params;
                const action = `OVERWRITE_${key === "fields" ? "FIELD_VALUES" : key.toUpperCase()}`;

                const actionKey = key === "categories" ? "categoryIds" : key;

                const response = await updateCustomContentActionsAPI(stage.endpoint, project.id, contentId, {
                    action,
                    data: { [actionKey]: value },
                });

                dispatch({
                    type: PROPERTY_PANEL_UPDATE_ACTION_SUCCESS,
                    payload: {
                        action,
                        data: { [key]: value },
                    },
                });
                resolve(response);
            } catch (e) {
                reject(e);
            }
        });
    };
