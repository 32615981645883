import { axios } from "../../cores/axiosWrapper";

/**
 * requestDownload
 * @param {string} stageId - stage id
 * @param {string} apiEndpoint - stage end point
 * @param {string} projectId - project id
 * @param {Object} data
 * @param {string} data.resourceType - resource type(ASSET|CONTENT)
 * @param {Object[]} data.resources - resource object
 * @param {number} data.expires - link (second)
 * @returns
 */
export function requestDownload(stageId, apiEndpoint, projectId, data, resourceToken) {
    const response = axios.post(
        `${apiEndpoint}/downloads`,
        {
            resourceType: data.resourceType,
            resources: data.resources,
            expires: data.expires,
        },
        {
            headers: {
                stageId,
                projectId,
                "x-mzc-cp-resource-token": resourceToken,
            },
        },
    );
    return response;
}

export function getDownloadStatus(stageId, apiEndpoint, projectId, downloadId) {
    const response = axios.get(`${apiEndpoint}/downloads/${downloadId}`, {
        headers: {
            stageId,
            projectId,
        },
    });
    return response;
}

export function requestDownloadFromShared(stageId, apiEndpoint, projectId, data, resourceToken) {
    const response = axios.post(
        `${apiEndpoint}/v2/shared-assets/proxy/downloads`,
        {
            resourceType: data.resourceType,
            resources: data.resources,
            expires: data.expires,
        },
        {
            headers: {
                stageId,
                projectId,
                "x-mzc-cp-resource-token": resourceToken,
            },
        },
    );
    return response;
}
