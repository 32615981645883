import { handleActions, createAction } from "redux-actions";

import MetadataFieldItemSetsApi from "./apis/metadataFieldItemSets";

//action type
const METADATA_FIELD_ITEM_SETS_LIST_RESET = "METADATA_FIELD_ITEM_SETS_LIST_RESET";
const METADATA_FIELD_ITEM_SETS_LIST_PENDING = "METADATA_FIELD_ITEM_SETS_LIST_PENDING";
const METADATA_FIELD_ITEM_SETS_ITEM_PENDING = "METADATA_FIELD_ITEM_SETS_ITEM_PENDING";

const METADATA_FIELD_ITEM_SETS_LIST_GET_SUCCESS = "METADATA_FIELD_ITEM_SETS_LIST_GET_SUCCESS";
const METADATA_FIELD_ITEM_SETS_LIST_GET_FAILURE = "METADATA_FIELD_ITEM_SETS_LIST_GET_FAILURE";

const METADATA_FIELD_ITEM_SETS_ITEM_UPDATE_SUCCESS = "METADATA_FIELD_ITEM_SETS_ITEM_UPDATE_SUCCESS";
const METADATA_FIELD_ITEM_SETS_ITEM_UPDATE_FAILURE = "METADATA_FIELD_ITEM_SETS_ITEM_UPDATE_FAILURE";

const METADATA_FIELD_ITEM_SETS_ITEM_CREATE_SUCCESS = "METADATA_FIELD_ITEM_SETS_ITEM_CREATE_SUCCESS";
const METADATA_FIELD_ITEM_SETS_ITEM_CREATE_FAILURE = "METADATA_FIELD_ITEM_SETS_ITEM_CREATE_FAILURE";

const METADATA_FIELD_ITEM_SETS_ITEM_DELETE_SUCCESS = "METADATA_FIELD_ITEM_SETS_ITEM_DELETE_SUCCESS";
const METADATA_FIELD_ITEM_SETS_ITEM_DELETE_FAILURE = "METADATA_FIELD_ITEM_SETS_ITEM_DELETE_FAILURE";

const initialState = {
    data: [],
    totalCount: 0,
    pending: false,
    error: false,
    params: {
        name: "",
        nextToken: "",
        limit: null,
    },
};

let tempState = {
    ...initialState,
};

export default handleActions(
    {
        [METADATA_FIELD_ITEM_SETS_LIST_PENDING]: (state) => {
            const temp = {
                ...tempState,
                data: [],
                totalCount: 0,
                pending: true,
                error: false,
            };

            tempState = temp;

            return tempState;
        },

        [METADATA_FIELD_ITEM_SETS_ITEM_PENDING]: (state, action) => {
            const { targetId } = action.payload;

            const target = tempState.data.find((v) => v.id === targetId);

            target.pending = true;
            target.error = false;

            return tempState;
        },

        [METADATA_FIELD_ITEM_SETS_LIST_RESET]: () => {
            tempState = {
                ...initialState,
            };
            return tempState;
        },

        [METADATA_FIELD_ITEM_SETS_LIST_GET_SUCCESS]: (state, action) => {
            const { data } = action.payload;
            const results = data.results;
            const totalCount = data.totalCount;

            const temp = {
                ...tempState,
                data: results,
                totalCount,
                pending: false,
                error: false,
            };

            tempState = temp;

            return tempState;
        },

        [METADATA_FIELD_ITEM_SETS_LIST_GET_FAILURE]: (state, action) => {
            const { error } = action.payload;

            return {
                ...state,
                pending: false,
                error,
            };
        },

        [METADATA_FIELD_ITEM_SETS_ITEM_DELETE_SUCCESS]: (state, action) => {
            const { id } = action.payload;

            const temp = {
                ...tempState,
                data: tempState.filter((v) => v.id !== id),
            };

            tempState = temp;

            return tempState;

            // const temp = {
            //     ...tempState,
            //     data: data,
            //     contextVersion: tempState.contextVersion + 1
            // };

            // tempState = temp;

            // return tempState;
            return tempState;
        },

        [METADATA_FIELD_ITEM_SETS_ITEM_DELETE_FAILURE]: (state, action) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },

        [METADATA_FIELD_ITEM_SETS_ITEM_UPDATE_SUCCESS]: (state, action) => {
            const { id, data } = action.payload;

            const target = tempState.data.find((v) => v.id === id);

            target.pending = true;
            target.error = false;
            target.name = data.name;
            target.elements = data.elements || [];

            return tempState;
        },
        [METADATA_FIELD_ITEM_SETS_ITEM_UPDATE_FAILURE]: (state, action) => {
            const { id } = action.payload;
            const target = tempState.data.find((v) => v.id === id);

            target.pending = true;
            target.error = false;

            return tempState;
        },
        [METADATA_FIELD_ITEM_SETS_ITEM_CREATE_SUCCESS]: (state, action) => {
            const { data } = action.payload;

            const temp = {
                ...tempState,
                pending: false,
                error: false,
                data: tempState.data.concat(data),
            };

            tempState = temp;

            return tempState;
        },
        [METADATA_FIELD_ITEM_SETS_ITEM_CREATE_FAILURE]: (state, action) => {
            const { error } = action.payload;

            return {
                ...tempState,
                pending: false,
                error,
            };
        },
    },
    initialState,
);

export const reset = createAction(METADATA_FIELD_ITEM_SETS_LIST_RESET);

export const getListAsync = (projectId) => (dispatch, getState) => {
    const { stage, metadataFieldItemSets } = getState();

    dispatch({
        type: METADATA_FIELD_ITEM_SETS_LIST_PENDING,
    });

    return new Promise((resolve, reject) => {
        MetadataFieldItemSetsApi.getListAsync(stage.id, stage.endpoint, projectId, metadataFieldItemSets.params)
            .then((response) => {
                dispatch({
                    type: METADATA_FIELD_ITEM_SETS_LIST_GET_SUCCESS,
                    payload: response,
                });
                resolve(response.data);
            })
            .catch((error) => {
                dispatch({
                    type: METADATA_FIELD_ITEM_SETS_LIST_GET_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const createAsync = (data) => (dispatch, getState) => {
    const { stage } = getState();

    // dispatch({
    //     type: METADATA_FIELD_ITEM_SETS_LIST_PENDING
    // });

    return new Promise((resolve, reject) => {
        return MetadataFieldItemSetsApi.createAsync(stage.id, stage.endpoint, data)
            .then((response) => {
                dispatch({
                    type: METADATA_FIELD_ITEM_SETS_ITEM_CREATE_SUCCESS,
                    payload: {
                        data: {
                            ...data,
                            id: response.data.id,
                        },
                    },
                });
                resolve(response.data);
            })
            .catch((error) => {
                dispatch({
                    type: METADATA_FIELD_ITEM_SETS_ITEM_CREATE_FAILURE,
                    payload: {
                        error,
                    },
                });
                reject(error);
            });
    });
};

export const updateAsync = (id, data) => (dispatch, getState) => {
    const { stage } = getState();

    dispatch({
        type: METADATA_FIELD_ITEM_SETS_ITEM_PENDING,
        payload: {
            targetId: id,
        },
    });

    return MetadataFieldItemSetsApi.updateAsync(stage.id, stage.endpoint, id, data)
        .then((response) => {
            dispatch({
                type: METADATA_FIELD_ITEM_SETS_ITEM_UPDATE_SUCCESS,
                payload: {
                    id,
                    data: response.data,
                },
            });
        })
        .catch((error) => {
            dispatch({
                type: METADATA_FIELD_ITEM_SETS_ITEM_UPDATE_FAILURE,
                payload: {
                    id,
                    error,
                },
            });
        });
};

export const deleteAsync = (id) => (dispatch, getState) => {
    const { stage } = getState();
    return MetadataFieldItemSetsApi.deleteAsync(stage.id, stage.endpoint, id)
        .then((response) => {
            dispatch({
                type: METADATA_FIELD_ITEM_SETS_ITEM_DELETE_SUCCESS,
                payload: {
                    id,
                },
            });
        })
        .catch((error) => {
            dispatch({
                type: METADATA_FIELD_ITEM_SETS_ITEM_DELETE_FAILURE,
                payload: error,
            });
        });
};
