import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

import { LayoutForNoting } from "../../components/layout";
import Loader from "../../components/loader";

import Welcome from "../welcome";

import { axios } from "../../cores/axiosWrapper";
import { deleteToken, setToken, getToken } from "@modules/token";
import { setNotification } from "@modules/notification";
import { resetErrorStatus, setErrorStatus } from "@modules/global";
import { setAuthorizeUser } from "@modules/user";

import Loadable from "@react-loadable/revised";

import { isLocal } from "../../cores/enviromentExtension";
import { sessionKeeper } from "../../cores/sessionKeeper";

import jwtConverter from "../../cores/jwtConverter";

const LoadableTokenExpiredModal = Loadable({
    loader: () => import("../../components/modals/TokenExpired"),
    loading: () => null,
});

const Token = ({ children, ...props }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const global = useSelector(({ global }) => global);
    const token = useSelector(({ token }) => token);

    const init = () => {
        axios._interceptors.response.handlers = [];
        axios._interceptors.response.use(
            (response) => {
                dispatch(resetErrorStatus());
                return response;
            },
            (error) => {
                if (error.response) {
                    console.log(`!!!error.response: ${JSON.stringify(error.response)}`);
                    const configErrorMessage = error.response.config.customErrorMessage;
                    if (configErrorMessage) {
                        return Promise.reject(error);
                    }

                    const responseUrl = error.response.request.responseURL;
                    let message = error.response.data.errorMessage
                        ? JSON.parse(error.response.data.errorMessage).message
                        : error.response.data.Message
                        ? error.response.data.Message
                        : error.response.data.message
                        ? error.response.data.message
                        : "";
                    switch (error.response.status) {
                        case 403:
                            // TODO: ACCESS_TOKEN_EXPIRED -> 401 Unauthorized로 수정 (람다도 수정해야함)
                            if (error.response.data.type === "ACCESS_TOKEN_EXPIRED") {
                                dispatch(deleteToken());
                                history.replace("/welcome");
                                return;
                            }
                            if (responseUrl) {
                                if (responseUrl.indexOf("/downloads") > -1) {
                                    dispatch(
                                        setNotification({
                                            type: "warning",
                                            contents: `[Warning:${
                                                error.response.status
                                            }] => ${"You do not have permission."}`,
                                        }),
                                    );
                                    break;
                                }

                                if (responseUrl.indexOf("/v1/me") !== -1) {
                                    dispatch(setAuthorizeUser(error.response.data));
                                    history.replace("/error/invaliduser");
                                } else if (
                                    responseUrl.indexOf("/s3") === -1 &&
                                    responseUrl.indexOf("/v3/drives") === -1
                                ) {
                                    if (error.response.config.method !== "get") {
                                        dispatch(
                                            setNotification({
                                                type: "warning",
                                                contents: `[Warning:${error.response.status}] => ${
                                                    message ? message : "You do not have permission."
                                                }`,
                                            }),
                                        );
                                    } else {
                                        dispatch(
                                            setErrorStatus({
                                                error,
                                                status: error.response.status,
                                                responseURL: responseUrl,
                                            }),
                                        );
                                    }
                                }
                            }
                            break;
                        case 401:
                            if (responseUrl.indexOf("/v1/me") !== -1) {
                                dispatch(deleteToken());
                                history.replace("/welcome");
                            } else if (location.pathname.indexOf("/assets/assets/") > -1) {
                                if (error.response.status === 401 || error.response.status === 403) {
                                    // asset detail에서 권한이 없어서 권한요청 페이지로 보낼 경우
                                    break;
                                }
                            } else if (responseUrl.indexOf("aws/s3/objects:presign") !== -1) {
                                break;
                            } else if (
                                responseUrl.indexOf("/hidden-metadata") === -1 &&
                                responseUrl.indexOf("/v3/drives") === -1
                            ) {
                                dispatch(
                                    setNotification({
                                        type: "warning",
                                        contents: `[Warning:${error.response.status}] => ${
                                            message ? message : "You do not have permission."
                                        }`,
                                    }),
                                );
                            }
                            break;
                        case 404:
                            //lineup version checked 예외처리
                            if (responseUrl && responseUrl.indexOf("/v2/downloads/jobs/") >= 0) {
                                dispatch(
                                    setNotification({
                                        type: "info",
                                        contents: `The source file has been deleted.`,
                                    }),
                                );
                            } else {
                                const ignoreErrorMessageApis = [
                                    "/settings",
                                    "/lineups",
                                    "/s3",
                                    "/hidden-metadata",
                                    "/users/",
                                    "/v3/drives",
                                    `/projects/${error.response.config?.headers?.projectId}`,
                                ];

                                const checkIgnoreErrorMessage = ignoreErrorMessageApis.every(
                                    (api) => responseUrl?.indexOf(api) === -1,
                                );

                                if (checkIgnoreErrorMessage) {
                                    // console.log(`404!!!!!!!!!!!!!!!!!!!!!!!!!!!!!`);
                                    // history.replace(document.referrer ? document.referrer : '/error/404');
                                    dispatch(
                                        setNotification({
                                            type: "error",
                                            contents: `[Error:${error.response.status}] => ${message}`,
                                        }),
                                    );
                                }
                            }
                            //TODO 예외 처리를 생각해보자 좀 더.
                            // GlobalActions.setErrorStatus({
                            //     status: error.response.status,
                            //     responseURL: responseUrl
                            // });
                            break;
                        case 409:
                            //lineup version checked 예외처리 & cancel / put job status 충돌 예외처리
                            if (
                                responseUrl &&
                                responseUrl.indexOf("/lineups") === -1 &&
                                responseUrl.indexOf("/jobs") === -1 &&
                                responseUrl.indexOf("/v3/drives") === -1 &&
                                responseUrl.indexOf("/v3/storages/buckets") === -1
                            ) {
                                dispatch(
                                    setNotification({
                                        type: "error",
                                        contents: `[Error:${error.response.status}] => ${message}`,
                                    }),
                                );
                            }
                            break;
                        case 400:
                            if (responseUrl && responseUrl.indexOf("/token?code") > 0) {
                                dispatch(deleteToken());
                                history.replace("/welcome");
                            } else {
                                if (
                                    (responseUrl && responseUrl.indexOf("/storages/buckets") > 0) ||
                                    responseUrl.indexOf("/v3/shares") > 0 ||
                                    responseUrl.indexOf("/v3/drives") > 0
                                ) {
                                    // storages action 관련 에러 처리는 /src/routes/sources/List.js에서 처리함
                                    break;
                                } else if (
                                    error.response.config.method === "delete" &&
                                    responseUrl.indexOf("/metadata-schemas") > 0
                                ) {
                                    dispatch(
                                        setNotification({
                                            type: "info",
                                            contents: `${message ?? "Failed to request"}`,
                                        }),
                                    );
                                    break;
                                }

                                dispatch(
                                    setNotification({
                                        type: "error",
                                        contents: `[Error:${error.response.status}] => ${
                                            message ?? "Failed to request"
                                        }`,
                                    }),
                                );
                            }
                            if (responseUrl && responseUrl.indexOf("/v3/shared-resources") > 0) {
                                break;
                            }
                            break;
                        default:
                            if (
                                responseUrl &&
                                responseUrl.indexOf("/s3") === -1 &&
                                responseUrl.indexOf("/v3/drives") === -1
                            ) {
                                dispatch(
                                    setNotification({
                                        type: "error",
                                        contents: `[Error:${error.response.status}] => ${message}`,
                                    }),
                                );
                            }
                            break;
                    }
                } else {
                    // TODO: CORS 에러거나 네트워크 에러인 경우임.
                }
                return Promise.reject(error);
            },
        );
    };
    const redirectToWelcome = (domain) => {
        Cookies.set("beforeLogin", `${location.pathname}${location.search}`, {
            expires: 1,
            path: "/",
            domain,
        });
        // ToDO : 토큰 재발급 되는지 확인 후 재시도
        history.replace("/welcome");
    }

    useEffect(() => {
        init();
        const domain = isLocal ? "localhost" : "megazone.io";
        const tokenData = dispatch(getToken(domain));
        if(!tokenData) {
            redirectToWelcome(domain);
            return;
        }
        if (!tokenData?.accessToken) {
            redirectToWelcome(domain);
            return;
        }

        const jwt = jwtConverter(tokenData.accessToken);
        if (jwt && jwt.exp) {
            if (new Date(jwt.exp * 1000) < new Date()) {
                dispatch(deleteToken());
                sessionKeeper.reset();
                history.push("/welcome");
                return;
            }
        }

        // TODO: 추후에는 code만 쿠키에 담아놓고 code가 없으면 리프레시 되거나 다른 텝에서 열린 상황이므로
        // api 통해서 code로 accessToken 받아오고 .. 서버에서는 만들어놓은 값이랑 validation 처리해서 맞으면 내려주고.
        dispatch(setToken(domain, tokenData));
        const clientId = localStorage.getItem("CLIENT_ID");
        sessionKeeper.set({ clientId });
    }, [token]);

    const checkInvalidUser = () => {
        const error = global.error && global.error.response && global.error.response.data;
        if (
            location.pathname !== "/spaces" &&
            error &&
            (error.type === "INACTIVATED_USER" ||
                error.type === "DELETED_USER" ||
                error.type === "EXPIRED_USER" ||
                error.type === "REVOKED_BY_NEW_LOGIN")
        )
            return true;
        else return false;
    };

    const onClickTokenExpiredConfirm = () => {
        dispatch(deleteToken());
        history.replace("/welcome");
    };

    if (token.pending) {
        return (
            <LayoutForNoting>
                <Loader />
            </LayoutForNoting>
        );
    } else if (!token.pending && !token.error && token.value !== null) {
        // return (
        //     <WrappedComponent
        //         {...this.props}
        //     />
        // );
        // TODO: Token Expired
        return (
            <React.Fragment>
                {checkInvalidUser() && (
                    <LoadableTokenExpiredModal
                        error={global && global.error && global.error.response && global.error.response.data}
                        onConfirm={onClickTokenExpiredConfirm}
                    />
                )}
                {children}
            </React.Fragment>
        );
    } else if (!token.pending && token.error) {
        return <Welcome />;
    } else {
        return null;
    }
};

export default Token;
